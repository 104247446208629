import { FunctionComponent } from 'react';
import * as React from 'react';
import { MemoryRouter, RouteComponentProps, withRouter } from 'react-router';
import View from '../View';
import Text from '../Text';
import TouchableView from '../TouchableView';
import styled, { EmotionStyle } from '../../core/styled';
import { webOnlyStyle } from '../../core/styleHelpers';
import ArrowLeft from '../icons/ArrowLeft';

interface FloatingMenuProps {
  hide?: boolean;
  style?: EmotionStyle;
  onBackdropPress(): void;
  id?: string;
  overlayStyle?: EmotionStyle;
}

const Overlay = styled(TouchableView)({
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1,
  bottom: 0,
  width: '100%',
  height: '100%',
  position: 'absolute',
  backgroundColor: 'transparent',
  ...webOnlyStyle({
    width: '100vw',
    height: '100vh',
    position: 'fixed',
  }),
});

const FloatingWrapper = styled(View)(({ theme: { colors } }) => {
  return {
    left: 18,
    zIndex: 2,
    bottom: 70,
    minWidth: 300,
    borderWidth: 1,
    display: 'flex',
    borderRadius: 10,
    position: 'absolute',
    borderColor: colors.extraLightGrey,
    flexDirection: 'column',
    backgroundColor: 'white',
    ...webOnlyStyle({
      maxHeight: 'calc(100vh/2)',
      right: 'auto',
      overflow: 'hidden',
      border: `1px solid ${colors.extraLightGrey}`,
      boxShadow: '2px 11px 13px 0 rgba(0,0,27,0.15)',
    }),
  };
});

const HeaderWrapper = styled(View)({
  height: 55,
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
  borderBottomWidth: 1,
  flexDirection: 'row',
});

const HeaderArrowWrapper = styled(TouchableView)({
  top: 6,
  left: 0,
  bottom: 5,
  zIndex: 1,
  marginLeft: 16,
  cursor: 'pointer',
  position: 'absolute',
  alignContent: 'center',
  justifyContent: 'center',
});

const HeaderText = styled(Text)(({ theme: { colors } }) => {
  return { fontSize: 15, color: colors.black, fontWeight: 600 };
});

const HeaderLeftArrow: React.FunctionComponent<{
  hide?: boolean;
  goBack(): void;
}> = ({ goBack, hide }) =>
  hide ? null : (
    <HeaderArrowWrapper onPress={goBack}>
      <ArrowLeft />
    </HeaderArrowWrapper>
  );

interface HeaderProps {
  title?: string;
  showBackArrow?: boolean;
  RightComponent?: React.FunctionComponent;
}

const HeaderComponent: FunctionComponent<HeaderProps & RouteComponentProps> = ({
  title,
  history,
  children,
  showBackArrow,
  RightComponent,
}) => (
  <HeaderWrapper>
    {children || (
      <>
        <HeaderLeftArrow hide={!showBackArrow} goBack={history.goBack} />
        <HeaderText>{title}</HeaderText>
        {RightComponent && <RightComponent />}
      </>
    )}
  </HeaderWrapper>
);

const Header = withRouter(HeaderComponent);

const FloatingMenu: FunctionComponent<FloatingMenuProps> = ({
  onBackdropPress,
  children,
  hide,
  id,
  style = {},
  overlayStyle,
}) => {
  if (hide) return null;

  return (
    <MemoryRouter>
      <>
        <Overlay tabIndex={-1} onPress={onBackdropPress} style={overlayStyle} />
        <FloatingWrapper id={id} style={style}>
          {children}
        </FloatingWrapper>
      </>
    </MemoryRouter>
  );
};
export default FloatingMenu;
export {
  Header as FloatingMenuHeader,
  HeaderText as FloatingMenuHeaderText,
  HeaderLeftArrow as FloatingMenuHeaderLeftArrow,
  HeaderWrapper as FloatingMenuHeaderWrapper,
};
