import {
  TouchableView,
  Standard,
  View,
  useWindowWidthState,
  CaretDown,
} from '@talkspace/react-toolkit';
import styled from '../../../core/styled';
import { useHistory } from '@/core/routerLib';

const Container = styled(View)(({ theme: { window, colors } }) => {
  const { isMobile } = window;
  return {
    background: colors.babyBlue,
    borderRadius: 7,
    padding: '18px 13px',
    marginLeft: !isMobile && 10,
    marginTop: isMobile && 10,
    flex: 1,
    flexWrap: 'wrap',
    '&:hover:not(.active)': {
      boxShadow: '0 4px 10px -2px rgba(0,49,45,0.27)',
    },
  };
});

const Text = styled(Standard)(({ theme: { colors } }) => {
  return {
    fontWeight: 400,
    fontSize: 14,
    color: colors.purple600,
  };
});

const Link = styled(TouchableView)(({ theme: { colors } }) => {
  return {
    fontWeight: 700,
    fontSize: 15,
    color: colors.accessibilityGreenDark,
  };
});

const CaseLoadDashboardBar = () => {
  const history = useHistory();
  const { isMobile } = useWindowWidthState();

  return (
    <Container row align="center" justify="space-between">
      <View row align="center" style={{ flex: 'auto' }}>
        <Text>Choose your own clients</Text>
      </View>

      <View row align="center">
        <Link style={{ marginRight: 5 }} onPress={() => history.push('/grow-caseload')}>
          {isMobile ? 'Explore' : 'Explore waitlist'}
        </Link>
        <CaretDown rotation={270} />
      </View>
    </Container>
  );
};

export default CaseLoadDashboardBar;
