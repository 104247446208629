import { FunctionComponent } from 'react';
import { View, Lightbox, TouchableView } from '@talkspace/react-toolkit';
import styled from '@/core/styled/styled';
import { useOpenModal } from '@/utils/ModalsContextProvider';
import { useHistory } from '@/core/routerLib';
import AudioPlayer from '../AudioPlayer';
import FileBubble from '../FileBubble';
import { MessageBodyType } from '../../entities/EMessage';
import { Status } from '../../entities/EMedia';
import { COLORS } from '../../utils/design';
import VideoPlayer from '../VideoPlayer';

interface MediaViewProps {
  messageID: number;
  signedMediaURL: string;
  thumbnailURL?: string;
  bodyType: MessageBodyType;
  duration?: number;
  color?: string;
  containerWidth?: number;
  fileName?: string;
  fileSize?: number;
  senderName?: string;
  createdAt?: Date;
  status?: Status;
}

// TODO: add fancy fuzzy image css here
const MessageImageWrapper = styled(View)({
  backgroundColor: COLORS.black,
});

const MediaView: FunctionComponent<MediaViewProps> = ({
  messageID,
  signedMediaURL,
  thumbnailURL,
  bodyType,
  duration,
  color,
  containerWidth,
  fileName = '',
  fileSize = 0,
  senderName = '',
  createdAt = new Date(),
  status,
}) => {
  const openModal = useOpenModal(true);
  const history = useHistory();

  const handlePDFBubblePress = () => {
    const url = '/pdf-viewer';
    const state = {
      messageID,
      url: signedMediaURL,
      fileName,
      senderName,
      createdAt,
    };
    if (openModal) {
      openModal(url, state);
    } else {
      history.push(url, state);
    }
  };

  function calculateMediaSize(size: number): number {
    if (size > 567) return 436;
    if (size > 375) return 300;
    return 300;
  }
  const maxSize = containerWidth ? calculateMediaSize(containerWidth) : '80vw';

  switch (bodyType) {
    case 'AUDIO': {
      return (
        <AudioPlayer audioUrl={signedMediaURL} duration={duration} load={status === 'pending'} />
      );
    }
    case 'IMAGE': {
      return (
        <MessageImageWrapper>
          <Lightbox
            ariaLabel="picture message"
            a11yText="enlarged image view"
            source={signedMediaURL}
            thumbnailURL={thumbnailURL}
            thumbnailStyle={{ maxWidth: maxSize, maxHeight: maxSize }}
          />
        </MessageImageWrapper>
      );
    }
    case 'VIDEO': {
      return (
        <VideoPlayer
          url={signedMediaURL}
          thumbnailURL={thumbnailURL}
          loading={status === 'pending' || !signedMediaURL}
          style={{ maxWidth: maxSize, maxHeight: maxSize }}
        />
      );
    }
    case 'PDF': {
      return (
        <TouchableView
          onTouchStart={(e) => e.stopPropagation()}
          onPress={handlePDFBubblePress}
          disabled={status === 'pending' || status === 'rejected'}
        >
          <FileBubble
            fileName={fileName}
            fileSize={fileSize}
            fileType="application/pdf"
            isLoading={status === 'pending'}
            isError={status === 'rejected'}
          />
        </TouchableView>
      );
    }
    default:
      return null;
  }
};

export default MediaView;
