import { VoidFunctionComponent } from 'react';
import Svg, { Path, Circle } from 'svgs';
import { useEmotionTheme, EmotionStyle } from '../../core/styled';

interface CirclePencilIconProps {
  width?: number;
  height?: number;
  color?: string;
  style?: EmotionStyle;
}

const CirclePencilIcon: VoidFunctionComponent<CirclePencilIconProps> = ({
  width = 89,
  height = 89,
  color,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();

  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 89 89"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <Circle cx="44.5" cy="44.5" r="44.5" fill={colors.greyBubble} />
      <Path
        d="M23.8813 31.1914C23.7787 28.2705 26.0641 25.8197 28.9851 25.7184L49.4808 25.0071C52.3999 24.9058 54.8487 27.1893 54.9515 30.1082L55.6656 50.3892C55.7684 53.3102 53.483 55.7612 50.5619 55.8626L30.0647 56.5739C27.1455 56.6752 24.6966 54.3916 24.594 51.4724L23.8813 31.1914Z"
        stroke="#EFCE9B"
        strokeWidth="3.52698"
      />
      <rect x="53" y="31" width="4" height="1" fill="#EFF3F9" />
      <g filter="url(#filter0_d_416_14752)">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.9745 37.8665C33.1105 33.9731 36.3769 30.9271 40.2704 31.0631L60.1485 31.7572C64.0417 31.8932 67.0877 35.1593 66.952 39.0526L66.2591 58.9326C66.1234 62.8262 62.8569 65.8725 58.9633 65.7366L39.0838 65.0424C35.1904 64.9064 32.1443 61.6399 32.2803 57.7465L32.9745 37.8665Z"
          fill="url(#paint0_linear_416_14752)"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.9745 37.8665C33.1105 33.9731 36.3769 30.9271 40.2704 31.0631L60.1485 31.7572C64.0417 31.8932 67.0877 35.1593 66.952 39.0526L66.2591 58.9326C66.1234 62.8262 62.8569 65.8725 58.9633 65.7366L39.0838 65.0424C35.1904 64.9064 32.1443 61.6399 32.2803 57.7465L32.9745 37.8665Z"
          fill="url(#paint1_linear_416_14752)"
        />
      </g>
      <Path
        d="M51.0833 33.6011C51.1215 32.5068 50.266 31.5713 49.1717 31.5331L39.1604 31.1835C35.8487 31.0679 33.0703 33.6588 32.9547 36.9705L32.1869 58.9571C32.0712 62.2688 34.6621 65.0472 37.9738 65.1628L59.9604 65.9306C63.2721 66.0462 66.0505 63.4553 66.1662 60.1436L66.5158 50.1324C66.554 49.0381 65.6805 48.1194 64.5862 48.0812C63.4919 48.0429 62.5564 48.8985 62.5182 49.9928L62.1686 60.004C62.1301 61.1079 61.2039 61.9716 60.1 61.933L38.1134 61.1652C37.0095 61.1267 36.1459 60.2006 36.1844 59.0967L36.9522 37.1101C36.9908 36.0062 37.9169 35.1425 39.0208 35.1811L49.0321 35.5307C50.1264 35.5689 51.0451 34.6954 51.0833 33.6011Z"
        fill="#EF970B"
      />
      <Path
        d="M65.2572 30.5608L68.609 34.159C69.2128 34.8071 69.1953 35.8153 68.5697 36.4421L53.3314 51.7082L46.4381 51.4675L46.6818 44.4902L62.9694 30.4362C63.6413 29.8565 64.6525 29.9116 65.2572 30.5608Z"
        fill="#EF970B"
        stroke="#EF970B"
        strokeWidth="3.96522"
      />
      <Circle cx="54.9742" cy="30.775" r="1.775" fill="#EFCE9A" />
      <defs>
        <filter
          id="filter0_d_416_14752"
          x="25.2754"
          y="27.0586"
          width="48.6816"
          height="48.6821"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.657524 0 0 0 0 0.403743 0 0 0 0 0 0 0 0 0.223776 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_416_14752" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_416_14752"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_416_14752"
          x1="63.0589"
          y1="35.878"
          x2="54.2925"
          y2="38.2814"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E8E5E1" />
          <stop offset="1" stopColor="#FFE4B8" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_416_14752"
          x1="53.4272"
          y1="38.5369"
          x2="56.9775"
          y2="33.3307"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EDEFF3" stopOpacity="0.01" />
          <stop offset="1" stopColor="#F0EBE7" />
        </linearGradient>
      </defs>
    </Svg>
  );
};

export default CirclePencilIcon;
