import styled from '../../../core/styled';
import View from '../../../components/View';

const StatusAlertDot = styled(View)(({ theme: { colorRoles } }) => {
  return {
    width: 8,
    height: 8,
    margin: 4,
    borderRadius: 8,
    backgroundColor: colorRoles.icons.iconCriticalDefault,
  };
});

export default StatusAlertDot;
