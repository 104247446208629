import { FunctionComponent } from 'react';
import { SessionModality } from 'ts-frontend/types';
import EnvelopeIcon from './EnvelopeIcon';
import ChatModalityV2 from './ChatModalityV2';
import VideoModalityV2 from './VideoModalityV2';
import AudioModalityV2 from './AudioModalityV2';
import { EmotionStyle } from '../../core/styled';

interface LiveSessionIconProp {
  width?: number;
  height?: number;
  style?: EmotionStyle;
  modality: SessionModality;
  fill?: boolean;
  color?: string;
  dataQa?: string;
}

const LiveSessionIconV2: FunctionComponent<LiveSessionIconProp> = ({
  width = undefined,
  height = undefined,
  style = {},
  modality,
  fill,
  color,
  dataQa,
}) => {
  switch (modality) {
    case 'video':
      return (
        <VideoModalityV2
          dataQa={dataQa}
          aria-hidden="true"
          width={width}
          height={height}
          style={style}
          fill={fill}
          color={color}
        />
      );
    case 'chat':
      return (
        <ChatModalityV2
          dataQa={dataQa}
          aria-hidden="true"
          width={width}
          height={height}
          style={style}
          fill={fill}
          color={color}
        />
      );
    case 'audio':
      return (
        <AudioModalityV2
          dataQa={dataQa}
          aria-hidden="true"
          width={width}
          height={height}
          style={style}
          fill={fill}
          color={color}
        />
      );
    default:
      return (
        <EnvelopeIcon
          aria-hidden="true"
          width={width}
          height={height}
          style={style}
          fill={fill}
          color={color}
        />
      );
  }
};

export default LiveSessionIconV2;
