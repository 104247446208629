import { useQuery, useQueryClient, QueryKey } from 'react-query';

import { Subscription } from '../types';
import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';
import { subscriptionsQueryKey } from './queryKeys';

type SubscriptionIncludeParameter =
  | 'paymentDetails'
  | 'lvsStatus'
  | 'insuranceStatus'
  | 'planDetails';

type SubscriptionsData = Subscription[] | undefined;

const getSubscriptions =
  ({
    clientUserID,
    roomID,
    includePaymentDetails,
    includeInsuranceStatus,
    includePlanDetails,
    includeLvsStatus,
  }: {
    clientUserID: number;
    roomID?: number;
    includePaymentDetails?: boolean;
    includeInsuranceStatus?: boolean;
    includePlanDetails?: boolean;
    includeLvsStatus?: boolean;
  }) =>
  (): Promise<Subscription[]> => {
    const queryString = new URLSearchParams();
    if (roomID) {
      queryString.set('roomID', roomID.toString());
    }
    const includeParams: SubscriptionIncludeParameter[] = [];
    if (includePaymentDetails) {
      includeParams.push('paymentDetails');
    }
    if (includePlanDetails) {
      includeParams.push('planDetails');
    }
    if (includeInsuranceStatus) {
      includeParams.push('insuranceStatus');
    }
    if (includeLvsStatus) {
      includeParams.push('lvsStatus');
    }
    if (includeParams.length) {
      queryString.set('include', includeParams.join(','));
    }

    return apiWrapper
      .get<{ data: Subscription[] }>(
        `${
          apiHelper().apiEndpoint
        }/v2/clients/${clientUserID}/subscriptions?${queryString.toString()}`
      )
      .then((res) => res.data.data);
  };

interface UseQuerySubscriptionsOptions {
  clientUserID: number;
  roomID?: number;
  includePaymentDetails?: boolean;
  includeInsuranceStatus?: boolean;
  includePlanDetails?: boolean;
  includeLvsStatus?: boolean;
  disabled?: boolean;
  initialTestState?: boolean;
}

const getCompatibleQueryKeys = (options: Partial<UseQuerySubscriptionsOptions>) => {
  const { clientUserID, includePaymentDetails, includeInsuranceStatus, includeLvsStatus, roomID } =
    options;
  if (!clientUserID) {
    return [];
  }
  const keys: QueryKey[] = [
    subscriptionsQueryKey({
      clientUserID,
      roomID: undefined,
      includePaymentDetails: true,
      includeInsuranceStatus: true,
      includePlanDetails: true,
      includeLvsStatus: true,
    }),
    subscriptionsQueryKey({
      clientUserID,
      roomID,
      includePaymentDetails: true,
      includeInsuranceStatus: true,
      includePlanDetails: true,
      includeLvsStatus: true,
    }),
  ];
  if (includePaymentDetails && !includeInsuranceStatus && !includeLvsStatus) {
    keys.push(
      subscriptionsQueryKey({
        clientUserID,
        roomID: undefined,
        includePaymentDetails,
        includeInsuranceStatus: undefined,
        includeLvsStatus: undefined,
      })
    );
    keys.push(
      subscriptionsQueryKey({
        clientUserID,
        roomID,
        includePaymentDetails,
        includeInsuranceStatus: undefined,
        includeLvsStatus: undefined,
      })
    );
  }
  if (includePaymentDetails && includeInsuranceStatus && !includeLvsStatus) {
    keys.push(
      subscriptionsQueryKey({
        clientUserID,
        roomID: undefined,
        includePaymentDetails,
        includeInsuranceStatus,
        includeLvsStatus: undefined,
      })
    );
    keys.push(
      subscriptionsQueryKey({
        clientUserID,
        roomID,
        includePaymentDetails,
        includeInsuranceStatus,
        includeLvsStatus: undefined,
      })
    );
  }
  if (includePaymentDetails && !includeInsuranceStatus && includeLvsStatus) {
    keys.push(
      subscriptionsQueryKey({
        clientUserID,
        roomID: undefined,
        includePaymentDetails,
        includeInsuranceStatus: undefined,
        includeLvsStatus,
      })
    );
    keys.push(
      subscriptionsQueryKey({
        clientUserID,
        roomID,
        includePaymentDetails,
        includeInsuranceStatus: undefined,
        includeLvsStatus,
      })
    );
  } else if (includeInsuranceStatus && !includePaymentDetails && !includeLvsStatus) {
    keys.push(
      subscriptionsQueryKey({
        clientUserID,
        roomID: undefined,
        includePaymentDetails: undefined,
        includeInsuranceStatus,
        includeLvsStatus: undefined,
      })
    );
    keys.push(
      subscriptionsQueryKey({
        clientUserID,
        roomID,
        includePaymentDetails: undefined,
        includeInsuranceStatus,
        includeLvsStatus: undefined,
      })
    );
  } else if (includeInsuranceStatus && !includePaymentDetails && includeLvsStatus) {
    keys.push(
      subscriptionsQueryKey({
        clientUserID,
        roomID: undefined,
        includePaymentDetails: undefined,
        includeInsuranceStatus,
        includeLvsStatus,
      })
    );
    keys.push(
      subscriptionsQueryKey({
        clientUserID,
        roomID,
        includePaymentDetails: undefined,
        includeInsuranceStatus,
        includeLvsStatus,
      })
    );
  } else if (includeLvsStatus && !includeInsuranceStatus && !includePaymentDetails) {
    keys.push(
      subscriptionsQueryKey({
        clientUserID,
        roomID: undefined,
        includePaymentDetails: undefined,
        includeInsuranceStatus: undefined,
        includeLvsStatus,
      })
    );
    keys.push(
      subscriptionsQueryKey({
        clientUserID,
        roomID,
        includePaymentDetails: undefined,
        includeInsuranceStatus: undefined,
        includeLvsStatus,
      })
    );
  }
  return keys;
};

const getCacheMatch = ({
  cacheData,
  clientUserID,
  roomID,
}: Pick<UseQuerySubscriptionsOptions, 'clientUserID' | 'roomID'> & {
  cacheData: SubscriptionsData;
}): SubscriptionsData => {
  if (!cacheData) return undefined;
  if (!roomID) {
    return cacheData?.filter((sub) => sub.participantID === clientUserID);
  }
  return cacheData?.filter((sub) => sub.participantID === clientUserID && sub.id === roomID);
};

const useQuerySubscriptions = ({
  clientUserID,
  roomID,
  includePaymentDetails,
  includeInsuranceStatus,
  includePlanDetails,
  includeLvsStatus,
  disabled,
}: UseQuerySubscriptionsOptions) => {
  const queryClient = useQueryClient();
  const query = useQuery<Subscription[], Error>({
    cacheTime: 1 * 60 * 1000,
    staleTime: 10 * 1000,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    queryKey: subscriptionsQueryKey({
      clientUserID,
      roomID,
      includePaymentDetails,
      includeInsuranceStatus,
      includePlanDetails,
      includeLvsStatus,
    }),
    initialData: () => {
      const compatibleKeys = getCompatibleQueryKeys({
        clientUserID,
        roomID,
        includePaymentDetails,
        includeInsuranceStatus,
        includePlanDetails,
        includeLvsStatus,
      });
      const cacheData = compatibleKeys
        .map((oneKey) => queryClient.getQueryData<Subscription[]>(oneKey))
        .find(Boolean);
      return getCacheMatch({ cacheData, clientUserID, roomID });
    },
    initialDataUpdatedAt: () => {
      const compatibleKeys = getCompatibleQueryKeys({
        clientUserID,
        roomID,
        includePaymentDetails,
        includeInsuranceStatus,
        includePlanDetails,
        includeLvsStatus,
      });
      const cacheState = compatibleKeys
        .map((oneKey) => queryClient.getQueryState<Subscription[]>(oneKey))
        .find(Boolean);
      return cacheState?.dataUpdatedAt;
    },
    queryFn: getSubscriptions({
      clientUserID,
      roomID,
      includePaymentDetails,
      includeInsuranceStatus,
      includePlanDetails,
      includeLvsStatus,
    }),
    enabled: !disabled && !!clientUserID,
  });
  return query;
};
export default useQuerySubscriptions;
