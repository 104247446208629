import styled from '../../core/styled';
import View from '../View';

const FILLED_BAR_HEIGHT = 16;

const MainContainer = styled(View)({
  width: '100%',
});

const Bar = styled(View)<{ width: number; color: string }>(({ width, color }) => {
  return {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    width: '100%',
    justifyContent: 'space-between',

    '&:after': {
      borderTopLeftRadius: 7,
      borderBottomLeftRadius: 7,
      borderTopRightRadius: width === 100 ? 7 : 0,
      borderBottomRightRadius: width === 100 ? 7 : 0,
      content: '""',
      position: 'absolute',
      background: width ? color : 'transparent',
      height: FILLED_BAR_HEIGHT,
      width: `${width}%`,
      top: '50%',
      transition: '0.4s ease',
      transform: 'translateY(-50%)',
      left: 0,
    },
  };
});

const MarkedPercentageComponentWrapper = styled(View)<{ percentage: number }>(({ percentage }) => {
  return {
    position: 'absolute',
    left: `${percentage}%` || 0,
    top: -52,
  };
});

const MilestoneContainer = styled(View)(({ theme: { colors } }) => {
  return {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 70,
    position: 'relative',
    '&:before': {
      borderRadius: 15,
      content: '""',
      position: 'absolute',
      background: colors.grey600,
      height: FILLED_BAR_HEIGHT,
      width: '100%',
      top: '50%',
      transform: 'translateY(-50%)',
      left: 0,
    },
  };
});

const RecursiveBarHierarchy = ({
  bars,
  index = 0,
  milestones,
  markedPercentages,
}: {
  bars: BarsArray;
  index?: number;
  milestones: Array<{ milestoneComponent: JSX.Element }>;
  markedPercentages?: Array<{ percentage: number; component: JSX.Element }>;
}) => {
  if (index === bars.length - 1) {
    return (
      <>
        <Bar width={bars[index].widthPercentage} color={bars[index].color}>
          {milestones.map((it) => it.milestoneComponent)}
        </Bar>
        {markedPercentages?.map((it) => (
          <MarkedPercentageComponentWrapper key={it.percentage} percentage={it.percentage}>
            {it.component}
          </MarkedPercentageComponentWrapper>
        ))}
      </>
    );
  }
  return (
    <Bar width={bars[index].widthPercentage} color={bars[index].color}>
      {RecursiveBarHierarchy({
        bars,
        index: index + 1,
        milestones,
        markedPercentages,
      })}
    </Bar>
  );
};

export type BarsArray = Array<{
  widthPercentage: number;
  color: string;
  onMilestoneHover?: boolean;
}>;

interface GenericProgressBarParams {
  bars: BarsArray;
  milestones: Array<{ milestoneComponent: JSX.Element }>;
  width?: number;
  markedPercentages?: Array<{ percentage: number; component: JSX.Element }>;
}

const GenericProgressBar = ({
  bars,
  milestones,
  width = 800,
  markedPercentages,
}: GenericProgressBarParams) => (
  <MainContainer style={{ width }}>
    <MilestoneContainer>
      {RecursiveBarHierarchy({
        bars,
        milestones,
        markedPercentages,
      })}
    </MilestoneContainer>
  </MainContainer>
);

export default GenericProgressBar;
