import Svg from 'svgs';
import { useEmotionTheme } from '../../core/styled';

interface CountdownSpinnerProps {
  secondsRemaining: number;
  totalSeconds: number;
  width?: number;
  height?: number;
  strokeColor?: string;
  radius?: number;
}
const CountdownSpinner = ({
  secondsRemaining,
  totalSeconds,
  width = 12,
  height = 12,
  strokeColor,
  radius = 5,
}: CountdownSpinnerProps) => {
  const { colors } = useEmotionTheme();

  // The formula for calculating the circumference of a circle is 2 * pi * radius
  const circumference = Math.PI * radius * 2;
  const offset = (secondsRemaining / totalSeconds) * circumference;
  return (
    <Svg width={width} height={height} viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
      <circle cx="6" cy="6" r={radius} stroke={colors.paleSilver} strokeWidth="2" fill="none" />
      <circle
        cx="6"
        cy="6"
        r={radius}
        stroke={strokeColor || colors.deepTeal}
        strokeWidth="2"
        fill="none"
        style={{
          strokeDasharray: circumference,
          strokeDashoffset: offset,
          transform: 'rotate(-90deg) translate(-12px)',
        }}
      />
    </Svg>
  );
};

export default CountdownSpinner;
