import {
  DoubleChevrons,
  CheckMark,
  Reload,
  useEmotionTheme,
  View,
  Small,
  ExclamationSquare,
} from '@talkspace/react-toolkit';
import { VoidFunctionComponent } from 'react';
import styled from '../../../../core/styled';

type StatusType =
  | 'EAP'
  | 'reopened'
  | 'sessionOccurred'
  | 'reviewClientNoShow'
  | 'reviewClientLateCancellation';

export enum Status {
  'EAP' = 'EAP',
  'REOPENED' = 'reopened',
  'SESSION_OCCURRED' = 'sessionOccurred',
  'CLIENT_NO_SHOW' = 'reviewClientNoShow',
  'CLIENT_LATE_CANCELLATION' = 'reviewClientLateCancellation',
}

interface TaskStatusProps {
  type: string;
  isEAP?: boolean;
  reopenedAt?: string | null;
}

const getStatus = (type: StatusType, colors: Record<string, string>) =>
  ({
    [Status.EAP]: {
      icon: <DoubleChevrons />,
      text: 'EAP Session occurred',
    },
    [Status.REOPENED]: {
      icon: <Reload />,
      text: 'Reopened',
    },
    [Status.SESSION_OCCURRED]: {
      icon: <CheckMark color={colors.permaAmericanGreen} />,
      text: 'Session occurred',
    },
    [Status.CLIENT_NO_SHOW]: {
      icon: <ExclamationSquare color={colors.permaGrape} />,
      text: 'Client no show',
    },
    [Status.CLIENT_LATE_CANCELLATION]: {
      icon: <ExclamationSquare color={colors.permaGrape} />,
      text: 'Client late cancellation',
    },
  }[type]);

const Wrapper = styled(View)(({ theme: { window } }) => {
  const { isMobile } = window;
  return {
    alignItems: 'flex-start',
    ...(isMobile && { marginLeft: 34, marginTop: 3 }),
  };
});

const TaskStatus: VoidFunctionComponent<TaskStatusProps> = ({ type, isEAP, reopenedAt }) => {
  const { colors } = useEmotionTheme();

  let status: StatusType;

  if (type === Status.CLIENT_NO_SHOW) {
    status = Status.CLIENT_NO_SHOW;
  } else if (type === Status.CLIENT_LATE_CANCELLATION) {
    status = Status.CLIENT_LATE_CANCELLATION;
  } else if (isEAP && reopenedAt === null) {
    status = Status.EAP;
  } else if (isEAP && reopenedAt !== null) {
    status = Status.REOPENED;
  } else {
    status = Status.SESSION_OCCURRED;
  }

  const { icon, text } = getStatus(status, colors);

  return (
    <Wrapper style={{ alignItems: 'center' }} row>
      <View>{icon}</View>
      <Small variant="smallTSBlack" style={{ marginLeft: 7 }}>
        {text}
      </Small>
    </Wrapper>
  );
};

export default TaskStatus;
