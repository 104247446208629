import { useHistory } from 'react-router-dom';

const useToken = (): string => {
  const {
    location: { hash },
  } = useHistory();
  if (hash.startsWith('#token=')) {
    return hash.split('=')[1];
  }
  return '';
};

export default useToken;
