import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const standardPath =
  'M9.99989 2C9.44676 2 8.99989 2.44687 8.99989 3V3.6C6.71864 4.0625 4.99989 6.08125 4.99989 8.5V9.0875C4.99989 10.5562 4.45927 11.975 3.48427 13.075L3.25302 13.3344C2.99052 13.6281 2.92802 14.05 3.08739 14.4094C3.24677 14.7688 3.60614 15 3.99989 15H15.9999C16.3936 15 16.7499 14.7688 16.9124 14.4094C17.0749 14.05 17.0093 13.6281 16.7468 13.3344L16.5155 13.075C15.5405 11.975 14.9999 10.5594 14.9999 9.0875V8.5C14.9999 6.08125 13.2811 4.0625 10.9999 3.6V3C10.9999 2.44687 10.553 2 9.99989 2ZM11.4155 17.4156C11.7905 17.0406 11.9999 16.5312 11.9999 16H9.99989H7.99989C7.99989 16.5312 8.20926 17.0406 8.58426 17.4156C8.95926 17.7906 9.46864 18 9.99989 18C10.5311 18 11.0405 17.7906 11.4155 17.4156Z';

const pathConfig: IconConfig = {
  standard: {
    default: {
      path: standardPath,
    },
  },
};

const BellFilled = withDSIconMaker(pathConfig, 'bell filled');

export default BellFilled;
