import { FunctionComponent } from 'react';
import View from '../View';
import TabRadioGroup from '../TabRadioGroup';
import Tiny from '../Typography/Tiny';
import Input from '../Input';
import Button from '../Button';
import { useUniqueID } from '../../hooks/a11yHelper';

const defaultMaxRating = 10;

interface NumberProps {
  index: number;
  onChange: (index: number) => void;
  selected: boolean;
  dataQa?: string;
}

const Number: FunctionComponent<NumberProps> = ({ index, onChange, selected, dataQa }) => {
  const handleChange = () => {
    onChange(index);
  };
  const onClick = (e) => {
    e.preventDefault();
    handleChange();
  };
  const ratingNumberID = useUniqueID('ratingNumberID');
  return (
    <View>
      <Input
        hidden
        data-qa={dataQa}
        value={String(index)}
        id={ratingNumberID}
        type="radio"
        name="rating"
        onClick={onClick}
        onChange={handleChange}
        checked={selected}
      />

      <Button
        role="radio"
        tabIndex={selected ? 0 : -1}
        size="small"
        style={{ width: 50, height: 49, fontWeight: 'bold' }}
        text={String(index)}
        onPress={onClick}
        isSecondary={!selected}
      />
    </View>
  );
};

export interface RatingsProps {
  initialRating?: number;
  maxRating?: number;
  onChange: (value: number) => void;
  dataQa?: string;
}

const RatingNumbers: FunctionComponent<RatingsProps> = ({
  initialRating,
  maxRating = defaultMaxRating,
  onChange,
  dataQa,
}) => {
  const ratingNumbersID = useUniqueID('ratingNumbersID');

  return (
    <View>
      <TabRadioGroup
        legendText={`Rate your experience from 0 to ${maxRating}, ${maxRating} being the best`}
        initialSelection
        style={{ marginBottom: 40 }}
        dataQa={dataQa}
      >
        <Tiny style={{ textAlign: 'left' }}>Not at all likely</Tiny>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
            margin: '8px -5px',
            gap: 5,
          }}
        >
          {[...new Array(maxRating + 1)].map((item, index) => {
            const key = `${ratingNumbersID}-${index}`;
            return (
              <Number
                key={key}
                index={index}
                onChange={onChange}
                selected={initialRating === index}
                dataQa="ratingNumbersNumber"
              />
            );
          })}
        </View>
        <Tiny style={{ textAlign: 'right' }}>Extremely likely</Tiny>
      </TabRadioGroup>
    </View>
  );
};

export default RatingNumbers;
