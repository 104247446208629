import { useState, forwardRef, VoidFunctionComponent } from 'react';
import 'react-phone-number-input/style.css';
import './A11yPhoneInput.css';
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form';
import ReactPhoneInput, {
  isValidPhoneNumber,
  getCountryCallingCode,
  parsePhoneNumber,
  PhoneNumber as PhoneNumberObject,
  isSupportedCountry,
} from 'react-phone-number-input';
import ArrowDown from '../Svgs/ArrowDownV2';
import View from '../View';
import Label from '../Label';
import Text from '../Text';
import commonStyles from '../../constants/commonStyles';
import styled, { EmotionStyle, EmotionTheme, useEmotionTheme } from '../../core/styled';
import { useUniqueID, useShareForwardedRef } from '../../hooks/a11yHelper';

export interface A11yPhoneInputProps {
  dataQa?: string;
  value?: string;
  handleInputValueChange?: (phoneNumber?: string) => void;
  handleCountryChange?: (phoneNumberCountryCode?: string) => void;
  height?: number;
  width?: number;
  label?: string;
  isError?: boolean;
  errorMessage?: string;
  placeholder?: string;
  name?: string;
  inputId?: string;
  required?: boolean;
  showLabel?: boolean;
  containerStyle?: EmotionStyle;
  style?: EmotionStyle;
  labelStyle?: EmotionStyle;
  errorStyle?: EmotionStyle;
  isReactHookForm?: boolean;
  isInternationalFormat?: boolean;
  countryCode?: string;
}

export type PhoneNumber = PhoneNumberObject;

interface ErrorProps {
  isTherapistCRM: boolean;
  errorId: string;
  errorMessage?: string;
}

const { roundedFocusRing } = commonStyles;

/**
 * Takes a phone number with country code and and returns boolean relating to whether or not the number is valid
 * e.g; '+16034313308' => true
 * @param phoneNumberWithCountryCode
 * @returns {boolean}
 * isValidPhoneNumber relies on an external library for phone number validation
 * It is very possible that new phone numbers could become valid and fail this validation.
 * react-phone-number input has another function isPossiblePhoneNumber that would avoid this scenario
 * but since we use the same isValidPhoneNumber function for backend validation in ts-api we
 * are keeping this for the time being.
 */
export const isPhoneNumberValid = (phoneNumberWithCountryCode?: string): boolean => {
  if (!phoneNumberWithCountryCode) return false;
  return isValidPhoneNumber(phoneNumberWithCountryCode);
};

/**
 * Takes alphabetical country code and returns boolean representing whether or not it is recognized
 * e.g; 'US' => tue
 * @param countryCode
 * @returns {boolean}
 */
export const isCountryCodeValid = (countryCode: string) => isSupportedCountry(countryCode);

/**
 * Takes alphabetical country code and returns a numeric version of it
 * e.g; 'US' => '+1'
 * @param countryCode
 * @returns {string}
 */
export const parseCountryCallingCode = (countryCode: string) =>
  `+${getCountryCallingCode(countryCode)}`;

/**
 * Takes a full phone number with country code and returns a phoneNumber object
 * e.g; "+16034313308" => {number: "+16034987635", countryCallingCode: "1", nationalNumber: "6034987635", country: "US"}
 * @param countryCode
 * @returns {string}
 */
export const parseFullPhoneNumber = (phoneNumber: string): PhoneNumberObject =>
  parsePhoneNumber(phoneNumber);

const StyledWrapper = styled(View)<{
  isKeyboardFocus: boolean;
  isError?: boolean;
  isTherapistCRM?: boolean;
}>(({ isKeyboardFocus, isError, isTherapistCRM, theme: { colors } }) => {
  return {
    ...(!isTherapistCRM && {
      ...roundedFocusRing(colors.permaWaikawaGrey, isKeyboardFocus, isError),
    }),
    borderRadius: 9,
    height: '100%',
    width: '100%',
    // prevents focus effect from flashing momentarily on some random clicks
    '&:hover': {
      boxShadow: 'none',
    },
  };
});

const StyledLabel = styled(Label)<{ isTherapistCRM?: boolean; isLabelOnTop?: boolean }>(
  ({ theme: { colors }, isTherapistCRM, isLabelOnTop }) => {
    return {
      fontSize: 13,
      color: colors.permaWaikawaGrey,
      ...(isTherapistCRM && {
        position: 'relative',
        color: colors.placeholderGrey,
        padding: 0,
        transition: 'all .2s',
        cursor: isLabelOnTop ? 'default' : 'text',
        backgroundColor: isLabelOnTop ? 'transparent' : colors.white,
        fontSize: isLabelOnTop ? 12 : 15,
        top: isLabelOnTop ? 0 : 25,
        marginTop: isLabelOnTop ? 11 : 0,
      }),
    };
  }
);

const ErrorLabel = styled(Text)(({ theme: { colors } }) => {
  return {
    fontSize: 13,
    fontWeight: 400,
    color: colors.permaErrorRed,
    alignSelf: 'self-start',
    marginTop: 0,
    padding: 4,
  };
});

const TherapistCrmErrorBadge = styled(View)(({ theme: { colors } }) => {
  return {
    position: 'relative',
    borderRadius: '50%',
    background: colors.red,
    width: 6,
    height: 6,
    bottom: 16,
    left: 292,
    marginBottom: -6,
  };
});

const phoneInputStyles = (colors: EmotionTheme['colors'], isError?: boolean) => {
  //   NOTE: most of this styling already applied within PhoneInput.css but adding it here so that PhoneInput component can be removed
  //   without effecting styling once it is no longer being used
  return {
    width: '100%',
    height: '100%',
    color: colors.black,
    verticalAlign: 'middle',
    fontSize: 14,
    lineHeight: 1.42857143,
    left: '0 !important',
    borderRadius: '10 !important',
    marginTop: '0 !important',
    border: `1px solid ${isError ? colors.torchRed : colors.permaLividBlueNew} !important`,
    cursor: 'pointer',
    padding: '0 20',
    caretColor: colors.green,
  };
};

const therapistCrmInputStyles = (colors: EmotionTheme['colors'], isLabelOnTop?: boolean) => {
  return {
    width: '100%',
    height: '100%',
    color: colors.black,
    verticalAlign: 'middle',
    borderRadius: '0px !important',
    borderWidth: '0px 0px 1px !important',
    borderColor: `${colors.permaMischka} !important`,
    marginTop: '0px !important',
    cursor: 'text !important',
    paddingLeft: 1,
    caretColor: colors.green,
    transition: 'all .2s ease-in-out',
    'input[disabled]': { backgroundColor: 'transparent', color: colors.placeholderGrey },
    '.PhoneInputInput::placeholder': { color: colors.placeholderGrey },
    '.PhoneInputInput': { opacity: isLabelOnTop ? 1 : 0 },
    '.PhoneInputCountry': { width: isLabelOnTop ? 30 : 0, opacity: isLabelOnTop ? 1 : 0 },
  };
};

const phoneInputShouldForwardProp = (propName: string) =>
  !['isError', 'isLabelOnTop'].includes(propName);

const StyledReactPhoneInput = styled(ReactPhoneInput, {
  shouldForwardProp: phoneInputShouldForwardProp,
})<{ isError?: boolean }>(({ isError, theme: { colors } }) => {
  return { ...phoneInputStyles(colors, isError) };
});

const StyledReactHookFormPhoneInput = styled(PhoneInputWithCountry, {
  shouldForwardProp: phoneInputShouldForwardProp,
})<{ isError?: boolean }>(({ isError, theme: { colors } }) => {
  return { ...phoneInputStyles(colors, isError) };
});

const StyledTherapistPhoneInput = styled(ReactPhoneInput, {
  shouldForwardProp: phoneInputShouldForwardProp,
})<{ isLabelOnTop?: boolean }>(({ isLabelOnTop, theme: { colors } }) => {
  return { ...therapistCrmInputStyles(colors, isLabelOnTop) };
});

const getStyledPhoneInput = (isReactHookForm: boolean, isTherapistCRM: boolean) => {
  if (isReactHookForm) return StyledReactHookFormPhoneInput;
  if (isTherapistCRM) return StyledTherapistPhoneInput;
  return StyledReactPhoneInput;
};

const Error: VoidFunctionComponent<ErrorProps> = ({ errorId, errorMessage, isTherapistCRM }) => {
  if (isTherapistCRM) return <TherapistCrmErrorBadge id={errorId} />;
  return <ErrorLabel id={errorId}>{errorMessage}</ErrorLabel>;
};

const A11yPhoneInput = forwardRef<
  HTMLInputElement,
  A11yPhoneInputProps & React.ComponentPropsWithRef<typeof ReactPhoneInput>
>((props, ref) => {
  const {
    dataQa = 'phoneInput',
    value,
    handleInputValueChange,
    handleCountryChange,
    height = 55,
    width = 335,
    label = 'Phone number',
    isError,
    errorMessage = 'Phone number missing or invalid.',
    name = 'phone',
    inputId,
    required,
    showLabel = true,
    placeholder = '(000) 000-0000',
    containerStyle,
    style,
    labelStyle,
    errorStyle,
    isReactHookForm,
    isTherapistCRM,
    isInternationalFormat,
    countryCode = 'US',
    ...otherProps
  } = props;

  const { colors } = useEmotionTheme();
  const PhoneInput = getStyledPhoneInput(!!isReactHookForm, isTherapistCRM);
  const [country, setCountry] = useState(countryCode);

  // if value is being explicitly passed as with a form that gets pre=filled with existing data, react-phone-number-input needs the phone number with country calling code (ex: "+16034313308") or else a generic international symbol is shown for country dropdown
  const finalValue =
    value && !value.includes('+') && isSupportedCountry(countryCode)
      ? `${parseCountryCallingCode(countryCode)}${value}`
      : value;

  const onCountryChange = (newCountry: string) => {
    setCountry(newCountry);
    handleCountryChange?.(newCountry);
  };

  const onChange = (phoneNumber: string) => handleInputValueChange?.(phoneNumber);

  const inputRef = useShareForwardedRef(ref);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const phoneInputId = inputId || useUniqueID('phoneInputId');
  const errorId = useUniqueID('errorInputId');

  // allows for keyboard specific focus effect
  const [isFocused, setIsFocused] = useState(false);
  const [isMouseUser, setIsMouseUser] = useState(false);

  const onFocus = () => {
    setIsFocused(true);
  };

  const onClick = () => {
    setIsMouseUser(true);
  };

  const onBlur = () => {
    setIsMouseUser(false);
    setIsFocused(false);
  };

  return (
    <View style={containerStyle}>
      {showLabel && (
        <StyledLabel
          htmlFor={phoneInputId}
          isTherapistCRM={isTherapistCRM}
          isLabelOnTop={!!(isFocused || isError || value)}
        >
          {label}
        </StyledLabel>
      )}
      <StyledWrapper
        style={{ height, maxWidth: width, ...style }}
        onFocus={onFocus}
        onBlur={onBlur}
        onClick={onClick}
        isKeyboardFocus={isFocused && !isMouseUser}
        isError={isError}
        isTherapistCRM={isTherapistCRM}
      >
        <PhoneInput
          id={phoneInputId}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore this is valid
          ref={inputRef}
          name={name}
          value={
            (typeof finalValue === 'string' || typeof finalValue === 'undefined') && finalValue
          }
          data-qa={dataQa}
          onChange={onChange}
          // important that otherProps spreads below onChange, onBlur, ref, and name so they can be customized when exposed by react-hook-form's register() function
          {...otherProps}
          countrySelectProps={{
            arrowComponent: () => (
              <ArrowDown
                style={{ marginLeft: 8, marginRight: 4 }}
                color={colors.permaLividBlueNew}
              />
            ),
          }}
          aria-required={required}
          aria-invalid={isError}
          aria-describedby={isError ? errorId : undefined}
          aria-label={label ? undefined : 'phone number'}
          country={country}
          defaultCountry={countryCode || 'US'}
          placeholder={country === 'US' ? placeholder : 'Enter phone number'}
          onCountryChange={onCountryChange}
          isError={isError}
          international={isTherapistCRM ? false : isInternationalFormat}
          isLabelOnTop={!!(isFocused || isError || value)}
        />
      </StyledWrapper>
      {isError && (
        <Error errorId={errorId} isTherapistCRM={isTherapistCRM} errorMessage={errorMessage} />
      )}
    </View>
  );
});

export default A11yPhoneInput;
