const CONDITION_MISSING_REMINDER_TYPE_IDS = [1, 2, 3]; // 1 and 2 is for todos. 3 is for alert
const WORKING_DIAGNOSIS_REMINDER_TYPE_ID = 115;
const SI_RISK_FLAG = 116;
const OUTCOME_MEASSURE_COMPLETED = 111;

export const isJourneyItemToDo = (journeyItem) =>
  CONDITION_MISSING_REMINDER_TYPE_IDS.includes(journeyItem.reminderTypeID || 0);
export const isJourneyItemWorkingDiagnosis = (journeyItem) =>
  journeyItem && journeyItem.reminderTypeID === WORKING_DIAGNOSIS_REMINDER_TYPE_ID;

export const journeyItemsHaveToDo = (journeyItems) => journeyItems?.some(isJourneyItemToDo);

export const isJourneyItemShouldDissmiss = (journeyItem) =>
  journeyItem &&
  [WORKING_DIAGNOSIS_REMINDER_TYPE_ID, SI_RISK_FLAG, OUTCOME_MEASSURE_COMPLETED].includes(
    journeyItem.reminderTypeID
  );
