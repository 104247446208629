import { useState, useEffect, ChangeEvent, FunctionComponent } from 'react';
import {
  AccordionContainer,
  Select,
  OptionType,
  TextInput,
  useObjectState,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import { OptionsType, ValueType, Styles } from 'react-select';
import { connect } from 'react-redux';
import { sortOptionsAlphabetically } from 'ts-frontend/helpers/optionsHelpers';
import * as medicalInformationActions from 'actions/MedicalInformationActions';
import { getOptionsByField } from '../../../../../../utils/tsConfigsValues';
import {
  useProgressNoteFormState,
  useProgressNoteFormActions,
} from '../context/PsychProgressNoteFormContext';
import { ProgressNoteFormState } from '../context/types';
import ConfirmButton from './ConfirmButton';

const NONE_REPORTED_OPTION = { value: null, label: 'None reported' };
const NONE_REPORTED = 'None reported';

const medicalConditionsOptions = getOptionsByField('medicalIssues');
const psychiatricMedicationsOptions =
  getOptionsByField('medications').sort(sortOptionsAlphabetically);

interface StoreProps {
  clientUserID: number;
  medicalIssues: Array<OptionType<number>>;
  medications: Array<OptionType<number>>;
  otherCurrentPrescriptionMedications: string;
  otcMedications: string;
  drugAllergies: string;
  getMedicalInformation: (clientUserID: number) => void;
}

const MedicalInformationSection: FunctionComponent<StoreProps> = ({
  clientUserID,
  medicalIssues,
  medications,
  otherCurrentPrescriptionMedications,
  otcMedications,
  drugAllergies,
  getMedicalInformation,
}) => {
  const { colors } = useEmotionTheme();
  const {
    formSections,
    medicalInformation: globalMedicalInformation,
    medicalInformationConditions: globalMedicalInformationConditions,
    medicalInformationMedications: globalMedicalInformationMedications,
    formMode,
  } = useProgressNoteFormState();
  const { setFormState } = useProgressNoteFormActions();
  const [medicalInformation, setMedicalInformation] =
    useObjectState<ProgressNoteFormState['medicalInformation']>(globalMedicalInformation);
  const [medicalInformationConditions, setMedicalInformationConditions] = useState<
    ProgressNoteFormState['medicalInformationConditions']
  >(globalMedicalInformationConditions);
  const [medicalInformationMedications, setMedicalInformationMedications] = useState<
    ProgressNoteFormState['medicalInformationMedications']
  >(globalMedicalInformationMedications);
  const [currentOTCMedicationsPlaceHolder, setCurrentOTCMedicationsPlaceHolder] = useState<
    string | undefined
  >(NONE_REPORTED);
  const [
    otherCurrentPrescriptionMedicationsPlaceholder,
    setOtherCurrentPrescriptionMedicationsPlaceholder,
  ] = useState<string | undefined>(NONE_REPORTED);
  const [drugAllergiesPlaceholder, setDrugAllergiesPlaceholder] = useState<string | undefined>(
    NONE_REPORTED
  );

  useEffect(() => {
    getMedicalInformation(clientUserID);
  }, [clientUserID, getMedicalInformation]);

  useEffect(() => {
    if (formMode === 'create' && medicalIssues.length > 0) {
      setMedicalInformationConditions(
        medicalIssues.map((it) => {
          return { conditionID: it.value };
        })
      );
    }
  }, [formMode, medicalIssues]);

  useEffect(() => {
    if (formMode === 'create' && medications.length > 0) {
      setMedicalInformationMedications(
        medications
          .map((it) => {
            return { medicationID: it.value };
          })
          .filter((m) => typeof m.medicationID === 'number')
      );
    }
  }, [formMode, medications]);

  useEffect(() => {
    if (formMode === 'create' && otherCurrentPrescriptionMedications) {
      setMedicalInformation({
        otherCurrentPrescriptionMedications,
      });
    }
  }, [formMode, otherCurrentPrescriptionMedications, setMedicalInformation]);

  useEffect(() => {
    if (formMode === 'create' && otcMedications) {
      setMedicalInformation({
        currentOTCMedications: otcMedications,
      });
    }
  }, [formMode, otcMedications, setMedicalInformation]);

  useEffect(() => {
    if (formMode === 'create' && drugAllergies) {
      setMedicalInformation({
        drugAllergies,
      });
    }
  }, [drugAllergies, formMode, setMedicalInformation]);

  const noneReportedSelectStyles: Styles<OptionType, boolean> = {
    singleValue: (provided) => {
      return { ...provided, color: colors.permaGullGray };
    },
  };

  const noneReportedTextInputStyles = {
    color: colors.permaGullGray,
    '-webkit-text-fill-color': colors.permaGullGray,
  };

  const markSectionAsIncomplete = () => {
    if (formSections.medicalInformation.completed) {
      setFormState({
        formSections: {
          ...formSections,
          medicalInformation: {
            open: formSections.medicalInformation.open,
            changed: true,
            completed: false,
          },
        },
      });
    }
  };

  const handleAccordionPress = (value: boolean) => {
    setFormState({
      formSections: {
        ...formSections,
        medicalInformation: {
          open: value,
          changed: formSections.medicalInformation.changed,
          completed: formSections.medicalInformation.completed,
        },
      },
    });
  };

  const handleCurrentMedicalConditionsChange = (
    newValues: Array<OptionType<number>> | OptionType<number> | null
  ) => {
    markSectionAsIncomplete();

    if (!newValues) {
      setMedicalInformationConditions([]);
    } else if (Array.isArray(newValues)) {
      if (newValues.length === 0) {
        setMedicalInformationConditions([]);
      } else {
        const selectedConditionsIDs = newValues.map((it) => it.value);
        setMedicalInformationConditions(
          selectedConditionsIDs.map((it) => {
            return { conditionID: it };
          })
        );
      }
    } else if (newValues.value !== null) {
      setMedicalInformationConditions([{ conditionID: newValues.value }]);
    }
  };

  const handleCurrentPsychiatricMedicationsChange = (
    newValues: Array<OptionType<number>> | OptionType<number> | null
  ) => {
    markSectionAsIncomplete();

    if (!newValues) {
      setMedicalInformationMedications([]);
    } else if (Array.isArray(newValues)) {
      if (newValues.length === 0) {
        setMedicalInformationMedications([]);
      } else {
        const selectedMedicationsIDs = newValues.map((it) => it.value);
        setMedicalInformationMedications(
          selectedMedicationsIDs.map((it) => {
            return { medicationID: it };
          })
        );
      }
    } else if (newValues.value !== null) {
      setMedicalInformationMedications([{ medicationID: newValues.value }]);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>, key: string) => {
    e.preventDefault();
    markSectionAsIncomplete();
    setMedicalInformation({ ...medicalInformation!, [key]: e.target.value });
  };

  const handleFocus = (
    placeholderValue: string | null | undefined,
    stateValue: string | null | undefined,
    setPlaceholderValue
  ) => {
    if (
      placeholderValue === NONE_REPORTED &&
      (stateValue === null ||
        stateValue === '' ||
        stateValue === undefined ||
        stateValue === NONE_REPORTED)
    )
      setPlaceholderValue(undefined);
  };

  const handleBlur = (
    e: ChangeEvent<HTMLInputElement>,
    placeholderValue,
    stateValue: string | null | undefined,
    setPlaceholderValue,
    key: string
  ) => {
    if (placeholderValue === undefined && (stateValue === '' || stateValue === undefined))
      setPlaceholderValue(NONE_REPORTED);
    setMedicalInformation({
      ...medicalInformation!,
      [key]: e.target.value === NONE_REPORTED ? '' : e.target.value,
    });
  };

  const getValue = (
    placeholderValue: string | null | undefined,
    stateValue: string | null | undefined
  ): string | null | undefined => {
    if (stateValue && stateValue !== '') return stateValue;
    return placeholderValue;
  };

  const handleConfirmPress = () => {
    setFormState({
      medicalInformation,
      medicalInformationConditions,
      medicalInformationMedications,
      formSections: {
        ...formSections,
        medicalInformation: {
          open: false,
          changed: true,
          completed: true,
        },
        ...(formSections.mentalStatusExam.completed
          ? {}
          : {
              mentalStatusExam: {
                ...formSections.mentalStatusExam,
                open: true,
              },
            }),
      },
    });
  };

  const medicalInformationConditionsIDs = medicalInformationConditions.map((c) => c.conditionID);

  const medicalInformationMedicationIDs = medicalInformationMedications.map((m) => m.medicationID);

  const medicalConditionsValue = medicalInformationConditions.length
    ? medicalConditionsOptions.filter((opt: OptionType<number>) =>
        medicalInformationConditionsIDs.includes(opt.value)
      )
    : NONE_REPORTED_OPTION;

  const psychiatricMedicationsValue = medicalInformationMedications.length
    ? psychiatricMedicationsOptions.filter((opt: OptionType<number>) =>
        medicalInformationMedicationIDs.includes(opt.value)
      )
    : NONE_REPORTED_OPTION;

  const checkIfShouldBeSearchable = (options: OptionsType<OptionType>) => options.length >= 21;

  return (
    <AccordionContainer
      title="Medical Information"
      open={formSections.medicalInformation.open}
      onPress={handleAccordionPress}
      showCheckMark={formMode !== 'view' && formSections.medicalInformation.completed}
      bodyStyle={{ paddingTop: 0 }}
      dataQa="medicalInformationAccordion"
    >
      <Select
        placeholder="Current medical conditions"
        options={medicalConditionsOptions as OptionsType<any>}
        value={medicalConditionsValue as ValueType<OptionType<any>, boolean>}
        onChange={handleCurrentMedicalConditionsChange as any}
        isMulti={!!medicalInformationConditions.length}
        isClearable={!!medicalInformationConditions.length}
        isDisabled={formMode === 'view'}
        styles={noneReportedSelectStyles}
        isSearchable={checkIfShouldBeSearchable(medicalConditionsOptions)}
        dataQa="currentMedicalConditionsDropdown"
      />
      <Select
        placeholder="Current psychiatric medications"
        options={psychiatricMedicationsOptions as OptionsType<any>}
        value={psychiatricMedicationsValue as ValueType<OptionType<any>, boolean>}
        onChange={handleCurrentPsychiatricMedicationsChange as any}
        isMulti={!!medicalInformationMedications.length}
        isClearable={!!medicalInformationMedications.length}
        isDisabled={formMode === 'view'}
        styles={noneReportedSelectStyles}
        isSearchable={checkIfShouldBeSearchable(psychiatricMedicationsOptions)}
        dataQa="currentPsychiatricMedicationsDropdown"
      />
      <TextInput
        placeholder="Other current prescription medications"
        dataQa="otherCurrentPrescriptionMedicationsInput"
        value={getValue(
          otherCurrentPrescriptionMedicationsPlaceholder,
          medicalInformation?.otherCurrentPrescriptionMedications
        )}
        onChange={(e) => handleChange(e, 'otherCurrentPrescriptionMedications')}
        onFocus={() =>
          handleFocus(
            otherCurrentPrescriptionMedicationsPlaceholder,
            medicalInformation?.otherCurrentPrescriptionMedications,
            setOtherCurrentPrescriptionMedicationsPlaceholder
          )
        }
        onBlur={(e) =>
          handleBlur(
            e,
            otherCurrentPrescriptionMedicationsPlaceholder,
            medicalInformation?.otherCurrentPrescriptionMedications,
            setOtherCurrentPrescriptionMedicationsPlaceholder,
            'otherCurrentPrescriptionMedications'
          )
        }
        disabled={formMode === 'view'}
        style={
          !medicalInformation!.otherCurrentPrescriptionMedications ||
          medicalInformation!.otherCurrentPrescriptionMedications.length === 0
            ? noneReportedTextInputStyles
            : {}
        }
      />
      <TextInput
        placeholder="Current over-the-counter medications"
        dataQa="currentOverTheCounterMedicationsInput"
        value={getValue(
          currentOTCMedicationsPlaceHolder,
          medicalInformation?.currentOTCMedications
        )}
        onChange={(e) => handleChange(e, 'currentOTCMedications')}
        onFocus={() =>
          handleFocus(
            currentOTCMedicationsPlaceHolder,
            medicalInformation?.currentOTCMedications,
            setCurrentOTCMedicationsPlaceHolder
          )
        }
        onBlur={(e) =>
          handleBlur(
            e,
            currentOTCMedicationsPlaceHolder,
            medicalInformation?.currentOTCMedications,
            setCurrentOTCMedicationsPlaceHolder,
            'currentOTCMedications'
          )
        }
        disabled={formMode === 'view'}
        style={
          !medicalInformation!.currentOTCMedications ||
          medicalInformation!.currentOTCMedications.length === 0
            ? noneReportedTextInputStyles
            : {}
        }
      />
      <TextInput
        placeholder="Drug allergies and reactions"
        dataQa="drugAllergiesAndReactionsInput"
        value={getValue(drugAllergiesPlaceholder, medicalInformation?.drugAllergies)}
        onChange={(e) => handleChange(e, 'drugAllergies')}
        onFocus={() =>
          handleFocus(
            drugAllergiesPlaceholder,
            medicalInformation?.drugAllergies,
            setDrugAllergiesPlaceholder
          )
        }
        onBlur={(e) =>
          handleBlur(
            e,
            drugAllergiesPlaceholder,
            medicalInformation?.drugAllergies,
            setDrugAllergiesPlaceholder,
            'drugAllergies'
          )
        }
        disabled={formMode === 'view'}
        style={
          !medicalInformation!.drugAllergies || medicalInformation!.drugAllergies.length === 0
            ? noneReportedTextInputStyles
            : {}
        }
      />
      {formMode !== 'view' && !formSections.medicalInformation.completed && (
        <ConfirmButton onPress={handleConfirmPress} sectionTitle="medicalInformation" />
      )}
    </AccordionContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    clientUserID: state.room.clientUserID,
    medicalIssues: state.medicalInformation.medicalIssues,
    medications: state.medicalInformation.medications,
    otherCurrentPrescriptionMedications:
      state.medicalInformation.otherCurrentPrescriptionMedications,
    otcMedications: state.medicalInformation.otcMedications,
    drugAllergies: state.medicalInformation.drugAllergies,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMedicalInformation: (clientUserID: number) =>
      dispatch(medicalInformationActions.getMedicalInformation(clientUserID)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MedicalInformationSection);
