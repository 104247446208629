import { View, Standard, Small, SmallCircle } from '@talkspace/react-toolkit';

import styled from '@/core/styled';

const TextWrapper = {
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
};

const MessagePreviewWrapper = styled(Small)<{ isHovering?: boolean }>(({ isHovering = false }) => {
  return {
    ...TextWrapper,
  };
});

const WaitingTimeText = styled(Standard)(({ theme: { colors } }) => {
  return {
    fontSize: 14,
    fontWeight: 700,
    color: colors.aqua,
    margin: '0 4px',
    maxWidth: 'fit-content',
  };
});

interface DashboardCellMessagePreviewProps {
  messagePreview?: string;
  styles?: object;
  isHovering?: boolean;
  clientWaitingTime?: string;
}
const DashboardCellMessagePreview = ({
  messagePreview,
  isHovering = false,
  styles,
  clientWaitingTime,
}: DashboardCellMessagePreviewProps) => (
  <View row align="center" style={{ margin: '6px 0' }}>
    {clientWaitingTime && (
      <View row align="center">
        <SmallCircle />
        <WaitingTimeText>{clientWaitingTime}:</WaitingTimeText>
      </View>
    )}
    <MessagePreviewWrapper
      isHovering={isHovering}
      style={{ ...styles, maxWidth: clientWaitingTime ? 230 : 310 }}
    >
      {messagePreview}
    </MessagePreviewWrapper>
  </View>
);

export default DashboardCellMessagePreview;
