import {
  Large,
  Small,
  Standard,
  styled,
  TextArea,
  TouchableView,
  View,
} from '@talkspace/react-toolkit';
import { FunctionComponent, useEffect, useState } from 'react';
import Footer from 'components/Reusable/Footer/Footer';
import useQueryTransferNote from 'hooks/notes/useQueryTransferNote';
import useQueryNotes from 'hooks/notes/useQueryNotes';
import Submenu from 'components/Reusable/Submenu/Submenu';
import { useHistory, useParams } from '@/core/routerLib';
import { FormMode } from './types';
import { getSubmenuTitleAndSubtitle } from './utils';
import AlertContainer from '../../../Reusable/AlertContainer/AlertContainer';

const Wrapper = styled(View)({
  height: '100%',
});

const TextAreaStyled = styled(TextArea)(({ theme: { colors } }) => {
  return {
    fontSize: 16,
    height: '100%',
    border: 'none',
    resize: 'none',
    caretColor: colors.green,
  };
});

const CloseButton = styled(TouchableView)(({ theme: { colors } }) => {
  return {
    color: colors.green,
    fontWeight: 900,
  };
});

const TransferNoteForm: FunctionComponent<{ mode: FormMode }> = ({ mode }) => {
  const history = useHistory();
  const { roomID, noteID } = useParams<{ roomID: string; noteID?: string }>();
  const [text, setText] = useState('');

  useQueryNotes(roomID);

  const {
    isLoading,
    isError,
    data: transferNoteData,
  } = useQueryTransferNote(roomID, noteID, mode === 'view');

  useEffect(() => {
    if (transferNoteData?.noteBody) setText(transferNoteData.noteBody);
  }, [transferNoteData]);

  const handleBackButtonPress = () => history.push(`/room/${roomID}/notes-tab`);

  const renderFooterChildren = () => [
    <>
      {isError ? (
        <AlertContainer
          title="Something went wrong"
          childComponents={[<CloseButton onPress={handleBackButtonPress}>Ok</CloseButton>]}
          body="There was a problem with the request. Please try again or contact customer service."
          containerStyle={{
            width: 355,
            border: 'none',
          }}
        />
      ) : (
        <View row justify="end">
          <TouchableView style={{ marginRight: 28 }} onPress={handleBackButtonPress}>
            <Standard variant="standardBoldGreen">Go back</Standard>
          </TouchableView>
        </View>
      )}
    </>,
  ];

  const { title, subtitle } = getSubmenuTitleAndSubtitle({
    formMode: mode,
    noteType: 'transfer',
    noteDate: transferNoteData?.createdAt,
  });

  return (
    <Wrapper>
      <Submenu
        title={title}
        titleComponent={
          subtitle ? (
            <View>
              <Large variant="largeBoldWide">{title}</Large>
              <Small variant="smallBoldGrey">{subtitle}</Small>
            </View>
          ) : undefined
        }
        childComponents={[
          <TextAreaStyled autoFocus disabled value={text} onChangeText={setText} />,
        ]}
        bodyStyle={{ padding: 0 }}
        childComponentsWrapperStyle={{ height: '100%' }}
        footerComponent={
          <Footer
            childComponents={renderFooterChildren()}
            isError={isError}
            isUpdating={isLoading}
            showSpinner={isLoading}
            spinnerMessage=""
            footerStyle={{ height: isError ? 150 : undefined }}
          />
        }
        onBackAlt={handleBackButtonPress}
      />
    </Wrapper>
  );
};

export default TransferNoteForm;
