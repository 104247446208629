import { FunctionComponent, useEffect, useState, useRef } from 'react';
import {
  Checkbox,
  styled,
  Standard,
  TouchableView,
  OptionType,
  View,
  Select,
  useEmotionTheme,
  CheckV2,
  PencilIcon,
  Small,
  Spinner,
} from '@talkspace/react-toolkit';
import useQueryProgressNoteHelperData from 'hooks/notes/useQueryProgressNoteHelperData';
import { connect } from 'react-redux';
import { clientProgress } from '@talkspace/configs';
import * as treatmentPlannerActions from 'actions/TreatmentPlannerActions';
import useQueryProgressNote from 'hooks/notes/useQueryProgressNote';
import useMutationSaveDraftProgressNote from 'hooks/notes/useMutationSaveDraftProgressNote';
import useMutationUpdateProgressNote from 'hooks/notes/useMutationUpdateProgressNote';
import { useHistory, useParams } from '@/core/routerLib';
import {
  DEFAULT_RISK_OR_BARRIERS_HANDLING,
  getSelectStyleWithoutBorder,
  getCheckBoxStyle,
  buildPostActionURL,
} from '../../../utils';
import {
  useProgressNoteFormActions,
  useProgressNoteFormState,
} from '../../context/TherapistProgressNoteFormContext';
import {
  ClientProgress,
  PresentingProblem,
  TreatmentPlanGoal,
  TreatmentPlanIntervention,
  TreatmentPlanObjective,
} from '../../../types';
import { Section } from '../../context/types';
import useDidUpdateEffect from '../../../../../../../hooks/useDidUpdateEffect';
import { ErrorText } from './NoTreatmentPlanV2';
import { UnsubmittedSessionTask } from '../../../../../../../hooks/dashboard/useQueryTaskListV3';

const DEFAULT_CLIENT_PROGRESS_OPTION = 'no_progress';

const transformConfigToOptions = (configsValue: Record<string, string>): Array<OptionType> =>
  Object.entries(configsValue).map((it) => {
    const [value, label] = it;
    return { value, label };
  });

const clientProgressOptions = transformConfigToOptions(clientProgress);

const SelectContainer = styled(View)(({ theme: { colors } }) => {
  return {
    marginLeft: 26,
    marginBottom: 25,
    paddingLeft: 15,
    borderLeftColor: colors.periwinkleGrey,
    borderLeftWidth: 4,
    borderLeftStyle: 'solid',
  };
});

const Label = styled(Small)(({ theme: { colors } }) => {
  return {
    marginTop: 14,
    marginBottom: 12,
    color: colors.black,
    fontWeight: 700,
    fontSize: 15,
  };
});

const Container = styled(View)<{ isError: boolean }>(({ theme: { colors }, isError }) => {
  return {
    border: isError && `1px solid ${colors.permaFuchsia}`,
    borderRadius: 8,
    padding: isError && 10,
  };
});

const EditButton: FunctionComponent<{
  title: string;
  onPress: () => void;
  isLoading?: boolean;
  dataQa?: string;
}> = ({ title, onPress, isLoading = false, dataQa }) => {
  const { colors } = useEmotionTheme();
  return (
    <TouchableView
      disabled={isLoading}
      style={{ flexDirection: 'row', marginLeft: 40, marginTop: 5 }}
      onPress={onPress}
      dataQa={dataQa}
    >
      {isLoading ? (
        <Spinner style={{ marginBottom: 25 }} />
      ) : (
        <>
          <PencilIcon color={colors.accessibilityGreenDark} />
          <Standard
            variant="standardBoldAccessibilityGreen"
            style={{ marginLeft: 8, marginBottom: 25 }}
          >
            {title}
          </Standard>
        </>
      )}
    </TouchableView>
  );
};

interface StyledCheckboxProps {
  label: string;
  isDisabled: boolean;
  handleCheck: (value: boolean) => void;
  isChecked: boolean;
  dataQa?: string;
}

const StyledCheckbox = ({
  label,
  isDisabled,
  handleCheck,
  isChecked,
  dataQa,
}: StyledCheckboxProps) => {
  const { colors } = useEmotionTheme();
  const checkboxStyle = getCheckBoxStyle(colors, isChecked, isDisabled);
  return (
    <Checkbox
      stretch={false}
      alignCenter={false}
      isLabelOnRight
      isChecked={isChecked}
      setIsChecked={handleCheck}
      label={label}
      isDisabled={isDisabled}
      checkComponent={<CheckV2 />}
      checkedColor={colors.accessibilityGreenDark}
      checkboxStyle={checkboxStyle}
      labelStyle={{ fontSize: 14, fontWeight: 400, marginLeft: 5 }}
      dataQa={dataQa}
    />
  );
};

interface Props {
  currentTreatmentPlanID: number;
  goalsByTreatmentPlan?: Array<TreatmentPlanGoal>;
  objectivesByTreatmentPlan?: Array<TreatmentPlanObjective>;
  interventionsByTreatmentPlan?: Array<TreatmentPlanIntervention>;
  currentPresentingProblems?: Array<PresentingProblem>;
  nextSection: Section;
  getGoalsByTreatmentPlan?(treatmentPlanID: number): void;
  getObjectivesByTreatmentPlan?(treatmentPlanID: number): void;
  getInterventionsByTreatmentPlan?(treatmentPlanID: number): void;
}

const ExistingTreatmentPlanV2: FunctionComponent<Props> = ({
  currentTreatmentPlanID,
  goalsByTreatmentPlan,
  objectivesByTreatmentPlan,
  interventionsByTreatmentPlan,
  currentPresentingProblems,
  nextSection,
  getGoalsByTreatmentPlan,
  getObjectivesByTreatmentPlan,
  getInterventionsByTreatmentPlan,
}) => {
  const formState = useProgressNoteFormState();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [invalidTreatmentPlanGoalIDs, setInvalidTreatmentPlanGoalIDs] = useState<Array<number>>([]);
  const [invalidTreatmentPlanObjectiveIDs, setInvalidTreatmentPlanObjectiveIDs] = useState<
    Array<number>
  >([]);
  const [navigationUrl, setNavigationUrl] = useState<string | null>(null);

  const { setFormState } = useProgressNoteFormActions();
  const history = useHistory();
  const { roomID, noteID } = useParams<{ roomID: string; noteID?: string }>();
  const { colors } = useEmotionTheme();
  const { isTransferred } = (formState?.unsubmittedSession as UnsubmittedSessionTask) || {};
  const { clientUserID, treatmentPlanGoals, treatmentPlanInterventions, treatmentPlanObjectives } =
    formState;
  const { data: progressNoteData } = useQueryProgressNote(
    clientUserID,
    noteID,
    formState.formMode !== 'create'
  );
  const { mutate: saveDraftProgressNote } = useMutationSaveDraftProgressNote();
  const { mutate: updateProgressNote } = useMutationUpdateProgressNote();
  const [isTreatmentPlanError, setIsTreatmentPlanError] = useState<boolean>(false);
  const { data: progressNoteHelperData } = useQueryProgressNoteHelperData(roomID);
  const postAction = history.location.pathname + history.location.search;
  const overallProgressGoalRef = useRef<HTMLDivElement>(null);
  const overallProgressObjectiveRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    getGoalsByTreatmentPlan && getGoalsByTreatmentPlan(currentTreatmentPlanID);
    getObjectivesByTreatmentPlan && getObjectivesByTreatmentPlan(currentTreatmentPlanID);
    getInterventionsByTreatmentPlan && getInterventionsByTreatmentPlan(currentTreatmentPlanID);
  }, [
    currentTreatmentPlanID,
    getGoalsByTreatmentPlan,
    getInterventionsByTreatmentPlan,
    getObjectivesByTreatmentPlan,
  ]);

  useEffect(() => {
    if (!formState.treatmentPlanID && formState.formMode !== 'view') {
      setFormState({ treatmentPlanID: currentTreatmentPlanID });
    }
    setFormState({
      presentingProblems: currentPresentingProblems || [],
    });
  }, [
    currentPresentingProblems,
    progressNoteData,
    formState.treatmentPlanID,
    currentTreatmentPlanID,
    setFormState,
    formState.formMode,
  ]);

  const markSectionAsIncomplete = () => {
    if (formState.formSections.treatmentPlanProgress.completed) {
      setFormState({
        formSections: {
          ...formState.formSections,
          treatmentPlanProgress: {
            open: formState.formSections.treatmentPlanProgress.open,
            changed: true,
            completed: false,
          },
        },
      });
    }
  };

  const handleNavigationToCRM = (url: string) => {
    const { showGlobalError, formMode, ...restFormState } = formState;
    const params = {
      roomID,
      noteID,
      clientUserID,
      isTherapist: true,
      ...restFormState,
    };

    if (formMode !== 'view') {
      if (noteID && progressNoteData?.status === 'submitted') {
        updateProgressNote(
          { ...params, noteID, clientUserID },
          {
            onSuccess: () => {
              history.push(url, { postAction });
            },
            onError: () => {
              setIsLoading(false);
            },
          }
        );
      } else {
        saveDraftProgressNote(params, {
          onSuccess: (data) => {
            const postActionURL = data.noteID
              ? buildPostActionURL({
                  draftNoteID: data.noteID.toString(),
                  search: history.location.search,
                })
              : postAction;
            history.push(url, { postAction: postActionURL });
          },
          onError: () => {
            setIsLoading(false);
          },
        });
      }
    }
  };

  useDidUpdateEffect(() => {
    if (formState.currentSection === 'finished' && navigationUrl) {
      handleNavigationToCRM(navigationUrl);
    }
  }, [formState.currentSection]);

  const handleEditGoalsPress = () => {
    setIsLoading(true);
    setNavigationUrl(`/room/${roomID}/treatment-planner-goals`);
    setFormState({ currentSection: 'sessionDateAndModality', isSaveAsDraftPressed: true });
  };

  const handleEditObjectivesPress = () => {
    setIsLoading(true);
    setNavigationUrl(`/room/${roomID}/treatment-planner-objectives`);
    setFormState({ currentSection: 'sessionDateAndModality', isSaveAsDraftPressed: true });
  };

  const handleGoalCheck = (checked: boolean, goalID: number) => {
    markSectionAsIncomplete();
    if (checked) {
      const overallProgress =
        (formState.formMode === 'create' &&
          progressNoteHelperData?.latestGoalsProgress?.find(
            (it) => it.treatmentPlanGoalID === goalID
          )?.overallProgress) ||
        DEFAULT_CLIENT_PROGRESS_OPTION;

      setFormState({
        treatmentPlanGoals: [
          ...treatmentPlanGoals,
          { treatmentPlanGoalID: goalID, overallProgress },
        ],
      });
    } else {
      setFormState({
        treatmentPlanGoals: treatmentPlanGoals.filter((it) => it.treatmentPlanGoalID !== goalID),
      });
    }
  };

  const handleObjectiveCheck = (checked: boolean, objectiveID: number) => {
    markSectionAsIncomplete();

    if (checked) {
      const overallProgress =
        (formState.formMode === 'create' &&
          progressNoteHelperData?.latestObjectivesProgress?.find(
            (it) => it.treatmentPlanObjectiveID === objectiveID
          )?.overallProgress) ||
        DEFAULT_CLIENT_PROGRESS_OPTION;

      setFormState({
        treatmentPlanObjectives: [
          ...treatmentPlanObjectives,
          { treatmentPlanObjectiveID: objectiveID, overallProgress },
        ],
      });
    } else {
      setFormState({
        treatmentPlanObjectives: treatmentPlanObjectives.filter(
          (it) => it.treatmentPlanObjectiveID !== objectiveID
        ),
      });
    }
  };

  const handleInterventionCheck = (
    checked: boolean,
    objectiveID: number,
    interventionID: number
  ) => {
    markSectionAsIncomplete();
    setIsTreatmentPlanError(false);
    if (checked) {
      setFormState({
        treatmentPlanInterventions: [
          ...treatmentPlanInterventions,
          { treatmentPlanObjectiveID: objectiveID, treatmentPlanInterventionID: interventionID },
        ],
      });
    } else {
      setFormState({
        treatmentPlanInterventions: treatmentPlanInterventions.filter(
          (it) =>
            it.treatmentPlanObjectiveID !== objectiveID ||
            it.treatmentPlanInterventionID !== interventionID
        ),
      });
    }
  };

  const handleGoalProgressSelect = (
    option: { value: string; label: string } | null,
    goalID: number
  ) => {
    markSectionAsIncomplete();

    if (option === null) {
      setFormState({
        treatmentPlanGoals: [
          ...treatmentPlanGoals.filter((it) => it.treatmentPlanGoalID !== goalID),
        ],
      });
    } else {
      setInvalidTreatmentPlanGoalIDs(invalidTreatmentPlanGoalIDs.filter((it) => it !== goalID));
      setFormState({
        treatmentPlanGoals: [
          ...treatmentPlanGoals.filter((it) => it.treatmentPlanGoalID !== goalID),
          { treatmentPlanGoalID: goalID, overallProgress: option.value as ClientProgress },
        ],
      });
    }
  };

  const handleObjectiveProgressSelect = (
    option: { value: string; label: string } | null,
    objectiveID: number
  ) => {
    markSectionAsIncomplete();

    if (option === null) {
      setFormState({
        treatmentPlanObjectives: [
          ...treatmentPlanObjectives.filter((it) => it.treatmentPlanObjectiveID !== objectiveID),
        ],
      });
    } else {
      setInvalidTreatmentPlanObjectiveIDs(
        invalidTreatmentPlanObjectiveIDs.filter((it) => it !== objectiveID)
      );
      setFormState({
        treatmentPlanObjectives: [
          ...treatmentPlanObjectives.filter((it) => it.treatmentPlanObjectiveID !== objectiveID),
          {
            treatmentPlanObjectiveID: objectiveID,
            overallProgress: option.value as ClientProgress,
          },
        ],
      });
    }
  };

  const handleConfirmPress = () => {
    let isError = false;
    const treatmentPlanGoalsWithoutProgress = treatmentPlanGoals
      .filter((it) => !it.overallProgress)
      .map((it) => it.treatmentPlanGoalID);

    if (treatmentPlanGoalsWithoutProgress.length > 0) {
      isError = true;
    }

    setInvalidTreatmentPlanGoalIDs(treatmentPlanGoalsWithoutProgress);

    const treatmentPlanObjectivesWithoutProgress = treatmentPlanObjectives
      .filter((it) => !it.overallProgress)
      .map((it) => it.treatmentPlanObjectiveID);

    if (treatmentPlanObjectivesWithoutProgress.length > 0) {
      isError = true;
    }

    setInvalidTreatmentPlanObjectiveIDs(treatmentPlanObjectivesWithoutProgress);

    if (!formState.isSaveAsDraftPressed && !formState.isFirstNote) {
      if (
        !treatmentPlanGoals.length ||
        !treatmentPlanObjectives.length ||
        !treatmentPlanInterventions.length
      ) {
        isError = true;
        setIsTreatmentPlanError(true);
      }
    }

    if (isError && !formState.isSaveAsDraftPressed) {
      setFormState({ showGlobalError: true });
      return;
    }

    setFormState({
      treatmentPlanProgress: {
        ...formState.treatmentPlanProgress!,
        riskOrBarriersHandling: DEFAULT_RISK_OR_BARRIERS_HANDLING,
      },
      formSections: {
        ...formState.formSections,
        treatmentPlanProgress: {
          open: false,
          changed: true,
          completed: !isError,
        },
        ...(formState.formSections.sessionSummary.completed
          ? {}
          : {
              sessionSummary: {
                ...formState.formSections.sessionSummary,
                open: true,
              },
            }),
      },
    });
  };

  useDidUpdateEffect(() => {
    if (formState.currentSection === 'treatmentPlanProgress') {
      setFormState({ currentSection: nextSection });
      handleConfirmPress();
    }
  }, [formState.currentSection]);

  const { formMode } = formState;

  let goals: Array<TreatmentPlanGoal> = goalsByTreatmentPlan || [];
  let objectives: Array<TreatmentPlanObjective> = objectivesByTreatmentPlan || [];
  let interventions: Array<TreatmentPlanIntervention> = interventionsByTreatmentPlan || [];

  if (progressNoteData?.status === 'submitted' && progressNoteData) {
    goals = [
      ...progressNoteData?.treatmentPlanGoals?.map((it) => {
        return {
          id: it.treatmentPlanGoal?.id,
          description: it.treatmentPlanGoal?.description,
          presentingProblemID: it.treatmentPlanGoal?.presentingProblemID,
        };
      }),
      ...goals.filter(
        (goalFromTreatmentPlan) =>
          !progressNoteData.treatmentPlanGoals.some(
            (goalFromProgressNote) =>
              goalFromProgressNote.treatmentPlanGoalID === goalFromTreatmentPlan.id
          )
      ),
    ];

    objectives = [
      ...progressNoteData.treatmentPlanObjectives.map((it) => {
        return {
          id: it.treatmentPlanObjective?.id,
          description: it.treatmentPlanObjective?.description,
          presentingProblemID: it.treatmentPlanObjective?.presentingProblemID,
        };
      }),
      ...objectives.filter(
        (objectiveFromTreatmentPlan) =>
          !progressNoteData.treatmentPlanObjectives.some(
            (objectiveFromProgressNote) =>
              objectiveFromProgressNote.treatmentPlanObjectiveID === objectiveFromTreatmentPlan.id
          )
      ),
    ];

    interventions = [
      ...progressNoteData.treatmentPlanInterventions.map((it) => {
        return {
          id: it.treatmentPlanIntervention?.id,
          description: it.treatmentPlanIntervention?.description,
          presentingProblemID: it.treatmentPlanIntervention?.presentingProblemID,
          objectiveID: it.treatmentPlanIntervention?.objectiveID,
        };
      }),
      ...interventions.filter(
        (interventionFromTreatmentPlan) =>
          !progressNoteData.treatmentPlanInterventions.some(
            (interventionFromProgressNote) =>
              interventionFromProgressNote.treatmentPlanInterventionID ===
              interventionFromTreatmentPlan.id
          )
      ),
    ];
  }

  const getSelectedProgressValue = (selectedGoal: string | null) =>
    clientProgressOptions.find((it) => it.value === selectedGoal);

  return (
    <>
      <Container isError={isTreatmentPlanError}>
        {formState.presentingProblems.map((problem) => (
          <View key={problem.id}>
            <Standard variant="standardBoldTSBlack" style={{ marginBottom: 10 }}>
              {problem.text} goals
            </Standard>
            {goals
              .filter((it) => it.presentingProblemID === problem.id)
              .map((goal, index) => {
                const selectedGoal = treatmentPlanGoals.find(
                  (it) => it.treatmentPlanGoalID === goal.id
                );
                return (
                  <View key={goal.id}>
                    <StyledCheckbox
                      isChecked={Boolean(selectedGoal)}
                      handleCheck={(value) => handleGoalCheck(value, goal.id)}
                      label={goal.description}
                      isDisabled={formMode === 'view'}
                      dataQa={`goalCheckbox-${index}`}
                    />
                    {selectedGoal && (
                      <SelectContainer style={{ marginLeft: 35 }} ref={overallProgressGoalRef}>
                        <Select
                          value={getSelectedProgressValue(selectedGoal?.overallProgress)}
                          placeholder={
                            <View style={{ color: colors.grey950 }}>
                              Overall progress towards goal
                            </View>
                          }
                          onChange={(value) => handleGoalProgressSelect(value as any, goal.id)}
                          options={clientProgressOptions}
                          isDisabled={formMode === 'view'}
                          styles={getSelectStyleWithoutBorder(
                            colors,
                            invalidTreatmentPlanGoalIDs.includes(goal.id)
                          )}
                          onMenuOpen={() => overallProgressGoalRef?.current?.scrollIntoView()}
                          dataQa={`overallProgressTowardsGoalDropdown-${index}`}
                        />
                      </SelectContainer>
                    )}
                  </View>
                );
              })}
          </View>
        ))}
        {formMode !== 'view' && !isTransferred && (
          <EditButton
            isLoading={isLoading}
            title="Edit goals"
            onPress={handleEditGoalsPress}
            dataQa="editGoalsButton"
          />
        )}

        {formState.presentingProblems?.map((problem) => (
          <View key={problem.id}>
            <Standard variant="standardBoldTSBlack" style={{ marginBottom: 10 }}>
              {problem.text} objectives
            </Standard>
            {objectives
              .filter((it) => it.presentingProblemID === problem.id)
              .map((objective, index) => {
                const selectedObjective = treatmentPlanObjectives.find(
                  (it) => it.treatmentPlanObjectiveID === objective.id
                );
                return (
                  <View key={objective.id}>
                    <StyledCheckbox
                      isChecked={Boolean(selectedObjective)}
                      handleCheck={(value) => handleObjectiveCheck(value, objective.id)}
                      label={objective.description}
                      isDisabled={formMode === 'view'}
                      dataQa={`objectiveCheckbox-${index}`}
                    />
                    {selectedObjective && (
                      <SelectContainer style={{ marginLeft: 35 }} ref={overallProgressObjectiveRef}>
                        <Select
                          value={getSelectedProgressValue(selectedObjective?.overallProgress)}
                          placeholder={
                            <View style={{ color: colors.grey950 }}>
                              Overall progress on objective
                            </View>
                          }
                          onChange={(value) =>
                            handleObjectiveProgressSelect(value as any, objective.id)
                          }
                          options={clientProgressOptions}
                          isDisabled={formMode === 'view'}
                          styles={getSelectStyleWithoutBorder(
                            colors,
                            invalidTreatmentPlanObjectiveIDs.includes(objective.id)
                          )}
                          onMenuOpen={() => overallProgressObjectiveRef?.current?.scrollIntoView()}
                          dataQa={`overallProgressOnObjectiveDropdown-${index}`}
                        />
                        <Label>Intervention(s) used</Label>

                        {interventions
                          .filter((it) => it.objectiveID === objective.id)
                          .map((intervention, interventionIndex) => (
                            <StyledCheckbox
                              key={intervention.id}
                              isChecked={
                                treatmentPlanInterventions.filter(
                                  (it) =>
                                    it.treatmentPlanObjectiveID === objective.id &&
                                    it.treatmentPlanInterventionID === intervention.id
                                ).length > 0
                              }
                              handleCheck={(value) =>
                                handleInterventionCheck(value, objective.id, intervention.id)
                              }
                              label={intervention.description}
                              isDisabled={formMode === 'view'}
                              dataQa={`interventionCheckbox-${interventionIndex}`}
                            />
                          ))}
                      </SelectContainer>
                    )}
                  </View>
                );
              })}
          </View>
        ))}
        {formMode !== 'view' && !isTransferred && (
          <EditButton
            isLoading={isLoading}
            title="Edit objectives"
            onPress={handleEditObjectivesPress}
            dataQa="editObjectivesButton"
          />
        )}
      </Container>
      {isTreatmentPlanError && (
        <ErrorText data-testid="noTreatmentPlanError">
          Required: You need to check the box next to at least one goal, objective, and intervention
          above and indicate client progress within each section
        </ErrorText>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    goalsByTreatmentPlan: state.treatmentPlanner.goals,
    objectivesByTreatmentPlan: state.treatmentPlanner.objectives,
    interventionsByTreatmentPlan: state.treatmentPlanner.interventions,
    currentPresentingProblems: state.treatmentPlanner.currentTreatmentPlan.presentingProblems,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getGoalsByTreatmentPlan: (treatmentPlanID: number) =>
      dispatch(treatmentPlannerActions.getGoals(treatmentPlanID)),
    getObjectivesByTreatmentPlan: (treatmentPlanID: number) =>
      dispatch(treatmentPlannerActions.getObjectives(treatmentPlanID)),
    getInterventionsByTreatmentPlan: (treatmentPlanID: number) =>
      dispatch(treatmentPlannerActions.getInterventions(treatmentPlanID)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExistingTreatmentPlanV2);
