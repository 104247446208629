import Svg, { Path } from 'svgs';
import { FunctionComponent } from 'react';
import { useEmotionTheme } from '../../core/styled';

interface MinusIconProps {
  width?: number;
  height?: number;
  color?: string;
}

const MinusIcon: FunctionComponent<MinusIconProps> = ({
  width = 12,
  height = 10,
  color,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();

  return (
    <Svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      {...otherProps}
    >
      <Path
        d="M1.00012 4.65952L5.65907 4.65742"
        stroke={color || colors.accessibilityGreenDark}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <Path
        d="M10.324 4.66447L5.66503 4.66657"
        stroke={color || colors.accessibilityGreenDark}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </Svg>
  );
};

export default MinusIcon;
