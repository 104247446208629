import { ReactElement } from 'react';
import {
  View,
  Modal,
  Button,
  Massive,
  Huge,
  CloseButton,
  useWindowWidthState,
} from '@talkspace/react-toolkit';
import styled from '@/core/styled';
import ExitIntent from '../../assets/ExitIntent/ExitIntent.png';
import Curve from '../../assets/ExitIntent/Curve.svg';
import CurveBottom from '../../assets/ExitIntent/CurveBottom.svg';

interface ExitIntentModalProps {
  showExitIntentPopUp: boolean;
  closePopUp: () => void;
  exitIntentCouponCode?: string;
  exitIntentCouponAmount: number;
  onSelectCoupon: (coupon?: string) => void;
}

const Content = styled(View)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      paddingLeft: isMobile ? 18 : 70,
      paddingRight: isMobile ? 18 : 70,
      height: '100%',
    };
  }
);

const ContentWrapper = styled(View)(
  ({
    theme: {
      window: { isMobile },
      colors,
    },
  }) => {
    return {
      backgroundColor: colors.permaCatSkillWhite,
      height: isMobile ? '100%' : 435,
      width: isMobile ? '100%' : 720,
      borderRadius: 15,
    };
  }
);

const ImageWrapper = styled(View)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      backgroundImage: `url(${ExitIntent})`,
      width: isMobile ? 'auto' : '300px',
      position: 'relative',
      borderRadius: 15,
      minHeight: isMobile ? '250px' : '400px',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'repeat',
      height: '100%',
      '&::after ': {
        display: 'block',
        content: "''",
        zIndex: 1,
        position: isMobile ? 'relative' : 'absolute',
        top: 0,
        right: 0,
        width: isMobile ? 'auto' : '50%',
        height: isMobile ? '276px' : '100%',
        background: isMobile ? `url(${CurveBottom})` : `url(${Curve}) top right no-repeat`,
        backgroundSize: isMobile ? 'cover' : 'auto 100%',
        backgroundPosition: isMobile ? 'inherit' : '',
      },
    };
  }
);

const OfferDetails = styled(View)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      width: isMobile ? '100%' : 420,
      padding: isMobile ? 40 : 20,
      alignItems: 'center',
      height: '100%',
    };
  }
);

const CloseButtonMobileWrapper = styled(View)(() => {
  return {
    position: 'absolute',
    right: '35px',
    paddingTop: '10px',
    zIndex: 2,
  };
});

const CloseButtonDesktopWrapper = styled(View)(() => {
  return {
    alignSelf: 'flex-end',
    paddingBottom: 50,
    position: 'relative',
    bottom: 10,
    left: 5,
  };
});

const OfferText = styled(Massive)(({ theme: { colors } }) => {
  return {
    fontSize: 30,
    color: colors.permaRhino,
    fontFamily: 'Quarto',
    textAlign: 'center',
    lineHeight: 1.25,
    letterSpacing: '.8px',
    marginBottom: 22,
  };
});
const OfferCode = styled(Huge)(({ theme: { colors } }) => {
  return {
    fontFamily: 'Roboto',
    textAlign: 'center',
    color: colors.permaRhino,
    fontWeight: 400,
    letterSpacing: 0,
    marginBottom: 32,
  };
});

const GetStartedButton = styled(Button)(({ theme: { colors } }) => {
  return {
    fontSize: 16,
    fontFamily: 'Roboto',
    fontWeight: '600',
    width: 'auto',
    height: 'auto',
    lineHeight: 1.4,
    textAlign: 'center',
    padding: '14px 20px',
    marginTop: 0,
    backgroundColor: colors.permaPersianGreen,
  };
});

const ExitIntentModal = ({
  showExitIntentPopUp,
  closePopUp,
  exitIntentCouponCode,
  exitIntentCouponAmount,
  onSelectCoupon,
}: ExitIntentModalProps) => {
  const { isMobile } = useWindowWidthState();

  let offer: ReactElement | null = null;
  let buttonText: string = 'Continue Checkout';
  if (exitIntentCouponCode) {
    offer = (
      <OfferCode>
        Enjoy {exitIntentCouponAmount} off with {exitIntentCouponCode}
      </OfferCode>
    );
    buttonText = 'Apply Coupon';
  }

  return (
    <Modal
      isVisible={showExitIntentPopUp}
      dialogStyle={{ padding: 16 }}
      underlayStyle={{ ...(isMobile ? { zIndex: 2, display: 'grid' } : {}) }}
    >
      {isMobile ? (
        <ContentWrapper>
          <CloseButtonMobileWrapper row justify="end">
            <CloseButton hasHoverStyles={false} onPress={() => closePopUp()} />
          </CloseButtonMobileWrapper>
          <ImageWrapper />
          <Content>
            <OfferDetails>
              <OfferText>Start online therapy from the comfort of your home today!</OfferText>
              {offer}
              <GetStartedButton
                onPress={() => onSelectCoupon(exitIntentCouponCode)}
                text={buttonText}
              />
            </OfferDetails>
          </Content>
        </ContentWrapper>
      ) : (
        <ContentWrapper>
          <Content>
            <ImageWrapper />
            <OfferDetails>
              <CloseButtonDesktopWrapper row justify="end">
                <CloseButton hasHoverStyles={false} onPress={() => closePopUp()} />
              </CloseButtonDesktopWrapper>
              <OfferText>Start online therapy from the comfort of your home today!</OfferText>
              {offer}
              <GetStartedButton
                onPress={() => onSelectCoupon(exitIntentCouponCode)}
                text={buttonText}
              />
            </OfferDetails>
          </Content>
        </ContentWrapper>
      )}
    </Modal>
  );
};

export default ExitIntentModal;
