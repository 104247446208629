import { useEmotionTheme } from '@talkspace/react-toolkit';
import { VoidFunctionComponent } from 'react';
import Svg, { Rect } from 'svgs';

interface TreatmentIntakeIconProps {
  color?: string;
}

const TreatmentIntakeIcon: VoidFunctionComponent<TreatmentIntakeIconProps> = ({
  color,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();

  return (
    <Svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <Rect
        x="0.75"
        y="0.75"
        width="14.5"
        height="14.5"
        rx="3.25"
        stroke={color || colors.slateGrey}
        stroke-width="1.5"
      />
      <Rect x="4" y="4" width="8" height="1.5" rx="0.75" fill={color || colors.slateGrey} />
      <Rect x="4" y="6.5" width="8" height="1.5" rx="0.75" fill={color || colors.slateGrey} />
      <Rect x="4" y="9" width="5" height="1.5" rx="0.75" fill={color || colors.slateGrey} />
    </Svg>
  );
};

export default TreatmentIntakeIcon;
