import Svg, { Path } from 'svgs';

interface CaseViewPlanIconProps {
  width?: number;
  height?: number;
}

const CaseViewPlanIcon = ({ width = 17, height = 17 }: CaseViewPlanIconProps) => (
  <Svg width={width} height={height} viewBox="0 0 17 17" fill="none">
    <Path
      d="M7.6 1.945a1.25 1.25 0 011.768 0l6.193 6.192c.422.422.659.994.659 1.59h.75-.75v5.244c0 .69-.56 1.25-1.25 1.25H9.727a2.25 2.25 0 01-1.591-.66L1.944 9.37a1.25 1.25 0 010-1.768L7.6 1.945z"
      stroke="#68768D"
      strokeWidth={1.5}
    />
  </Svg>
);

export default CaseViewPlanIcon;
