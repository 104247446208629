import Svg, { Path, Ellipse } from 'svgs';
import { useEmotionTheme, EmotionStyle } from '../../core/styled';

interface Props {
  width?: number;
  height?: number;
  bubbleColor?: string;
  dotsColor?: string;
  style?: EmotionStyle;
  dataQa?: string;
}
const ChatBubble3Dots = ({
  width = 66,
  height = 38,
  bubbleColor,
  dotsColor,
  style,
  dataQa,
  ...props
}: Props) => {
  const { colors } = useEmotionTheme();
  const titleText = 'Chat bubble with 3 dots';
  return (
    <Svg
      title={titleText}
      aria-label={titleText}
      data-qa={dataQa}
      width={width}
      height={height}
      viewBox="0 0 66 38"
      fill="none"
      style={style}
      {...props}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.059 17.431C7.059 7.804 14.863 0 24.49 0h24.079C58.196 0 66 7.804 66 17.431s-7.804 17.431-17.431 17.431H24.49a17.35 17.35 0 01-9.05-2.53c-1.1 1.338-2.707 2.182-4.499 2.182-3.314 0-6-2.888-6-6.45 0-2.334 1.154-4.38 2.882-5.511a17.428 17.428 0 01-.764-5.122zM2.824 38c1.559 0 2.823-1.249 2.823-2.789s-1.264-2.789-2.823-2.789C1.264 32.422 0 33.671 0 35.211S1.264 38 2.824 38z"
        fill={bubbleColor || colors.permaLinkWaterGrey}
      />
      <Ellipse
        cx={24}
        cy={17.431}
        rx={4.235}
        ry={4.183}
        fill={dotsColor || colors.permaWildBlueYonder}
      />
      <Ellipse
        cx={36.353}
        cy={17.431}
        rx={4.235}
        ry={4.183}
        fill={dotsColor || colors.permaWildBlueYonder}
      />
      <Ellipse
        cx={48.706}
        cy={17.431}
        rx={4.235}
        ry={4.183}
        fill={dotsColor || colors.permaWildBlueYonder}
      />
    </Svg>
  );
};

export default ChatBubble3Dots;
