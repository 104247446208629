import { FunctionComponent, useState, useEffect, FormEvent, useRef, useCallback } from 'react';
import { useStripeTs } from 'stripe/stripeContext';
import { StripeCardElement } from '@stripe/stripe-js';
import { CreditCardInput, StripeLinkCheckoutForm } from 'checkout';
import { View, Tiny, Button, ExtraHuge, Big, TSInput, Spinner } from '@talkspace/react-toolkit';
import styled from '@/core/styled';
import InRoomSchedulingError from '../inRoomSchedulingError';

const StyledForm = styled.form({ width: 336 });

const HeaderWrapper = styled(View)({
  textAlign: 'left',
  marginBottom: 20,
  paddingLeft: 4,
});

const InputWrapper = styled(View)({
  marginBottom: 10,
});

const Label = styled(Tiny)({
  textAlign: 'left',
  paddingLeft: 4,
});

const SaveButton: FunctionComponent<{}> = () => (
  <Button type="submit" stretch>
    <Big variant="bigWide">Save</Big>
  </Button>
);

interface Props {
  onSuccess: () => void;
  updatePaymentDetails: (cardToken: string) => void;
  email: string;
  isLoading: boolean;
  error?: string | null;
  clearError: () => void;
  isLinkLoaded: boolean;
  onLoadStripeLink: () => void;
  dispatchPaymentMethodError: (error: any) => void;
  useStripeLink: boolean;
}

const UpdatePaymentDetails: FunctionComponent<Props> = ({
  onSuccess,
  updatePaymentDetails,
  email,
  isLoading,
  error,
  clearError,
  isLinkLoaded,
  onLoadStripeLink,
  dispatchPaymentMethodError,
  useStripeLink,
}) => {
  const stripe = useStripeTs();
  const [showError, setShowError] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [linkElementsReady, setLinkElementsReady] = useState(false);
  const cardElementRef = useRef<StripeCardElement | null>(null);

  const stripeElementFinished = useCallback(() => {
    setLinkElementsReady(true);
  }, []);

  useEffect(() => {
    if (isUpdating && !isLoading && !error) {
      setShowError(false);
      setIsUpdating(false);
      clearError();
      onSuccess();
    }
  }, [isUpdating, isLoading, error, setShowError, clearError, onSuccess]);

  if (isLoading) {
    return (
      <View>
        <Spinner />
      </View>
    );
  }

  if (showError || error) {
    return (
      <InRoomSchedulingError
        onClosePress={() => {
          setShowError(false);
          setIsUpdating(false);
          clearError();
        }}
        message="We were unable to process your payment details."
        title="Sorry, something went wrong"
        buttonText="Update Payment"
      />
    );
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (cardElementRef.current) {
      const result = await stripe.createToken(cardElementRef.current);
      if (result.error) {
        setShowError(true);
      } else if (result.token) {
        updatePaymentDetails(result.token.id);
        setIsUpdating(true);
      }
    }
  };

  return useStripeLink ? (
    <View>
      {isLinkLoaded && linkElementsReady && (
        <HeaderWrapper>
          <ExtraHuge>Update payment details</ExtraHuge>
        </HeaderWrapper>
      )}
      <StripeLinkCheckoutForm
        onError={dispatchPaymentMethodError}
        onSubmit={onSuccess}
        onLoadStripeLink={onLoadStripeLink}
        email={email}
        stripe={stripe}
        submitText="Save"
        isLinkLoaded={isLinkLoaded}
        isProcessing={false}
        stripeElementFinished={stripeElementFinished}
      />
    </View>
  ) : (
    <View>
      <StyledForm onSubmit={handleSubmit}>
        <HeaderWrapper>
          <ExtraHuge>Update payment details</ExtraHuge>
        </HeaderWrapper>
        <InputWrapper>
          <Label>Email</Label>
          <TSInput onChange={() => undefined} value={email} disabled />
        </InputWrapper>
        <CreditCardInput
          stripe={stripe}
          cardElementRef={cardElementRef}
          serverError={showError ? 'We were unable to process your payment details.' : undefined}
        />
        <SaveButton />
      </StyledForm>
    </View>
  );
};

export default UpdatePaymentDetails;
