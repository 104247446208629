import { LiveSessionModality } from 'ts-frontend/types';
import { VIDEO_CALL_SESSION_STORAGE_KEY } from '../types/videoCallTypes';

export interface LiveSessionState {
  therapistUserID: number;
  roomID: number;
  therapistFirstName: string;
  therapistLastName: string;
  startTime: string;
  creditMinutes: number;
  videoCreditType: string;
  videoCallID: number;
  modality: LiveSessionModality;
  tokenExpiresAt: string;
}

export default function redirectToLiveSession(roomID: number, sessionState: LiveSessionState) {
  sessionStorage.setItem(VIDEO_CALL_SESSION_STORAGE_KEY, JSON.stringify(sessionState));
  // this is to open the video call screen after closing the in room scheduling container
  // the page must refresh when closing this container, so we need to redirect the web user to the modal
  // as well as send the proper navigateTo to the mobile devices when this is in a webview
  window.location.href = `/room/${roomID}?action=video-call`;
}
