import { ClientProgress } from '../../NotesTab/types';
import NoProgressIcon from '../../../../Icons/NoProgressIcon';
import MinimalProgressIcon from '../../../../Icons/MinimalProgressIcon';
import SomeProgressIcon from '../../../../Icons/SomeProgressIcon';
import SignificantProgressIcon from '../../../../Icons/SignificantProgressIcon';
import AllGoalsAchievedIcon from '../../../../Icons/AllGoalsAchievedIcon';

export interface ProgressIconsData {
  icon: JSX.Element;
  iconTooltipText: string;
}

export const progressIcons: Record<ClientProgress, ProgressIconsData> = {
  no_progress: {
    icon: <NoProgressIcon />,
    iconTooltipText: 'No progress',
  },
  minimal_progress: {
    icon: <MinimalProgressIcon />,
    iconTooltipText: 'Minimal progress',
  },
  some_progress: { icon: <SomeProgressIcon />, iconTooltipText: 'Some progress' },
  significant_progress: {
    icon: <SignificantProgressIcon />,
    iconTooltipText: 'Significant progress',
  },
  all_goals_achieved: {
    icon: <AllGoalsAchievedIcon />,
    iconTooltipText: 'All goals achieved',
  },
};
