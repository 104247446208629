import { FunctionComponent } from 'react';
import {
  View,
  Big,
  TouchableView,
  useEmotionTheme,
  CalendarOutlineIcon,
} from '@talkspace/react-toolkit';
import RadioButton from '@talkspace/react-toolkit/src/designSystems/components/RadioButton';

import styled from '@/core/styled';

const IconWrapper = styled(View)({ width: 24 });

const CancelActionCardWrapper = styled(TouchableView)({
  width: 292,
  height: 75,
  borderRadius: 10,
  alignItems: 'center',
  marginTop: 10,
  paddingLeft: 17,
  border: '1px solid #EFF3FA',
});

export const CancelActionCard = ({
  onPress,
  text,
  Icon,
  isSelected,
  dataQAText,
}: {
  onPress: () => void;
  text: string;
  Icon: FunctionComponent<{ color: string }>;
  isSelected: boolean;
  dataQAText: string;
}) => {
  const { colorRoles } = useEmotionTheme();
  return (
    <CancelActionCardWrapper row onPress={onPress} tabIndex={-1}>
      <IconWrapper>
        <Icon color={colorRoles.icons.iconSubtlestDefault} />
      </IconWrapper>
      <Big style={{ width: 187, marginLeft: 15 }}>{text}</Big>
      <RadioButton
        isActive={isSelected}
        onPress={onPress}
        data-qa={`cancelBooking${dataQAText}Checkbox`}
      />
    </CancelActionCardWrapper>
  );
};

export const AddToCalendarActionCard = ({
  onPress,
  text,
  isSelected,
}: {
  onPress: () => void;
  text: string;
  isSelected: boolean;
}) => {
  const { colorRoles } = useEmotionTheme();
  return (
    <CancelActionCardWrapper row onPress={onPress} tabIndex={-1}>
      <IconWrapper>
        <CalendarOutlineIcon color={colorRoles.icons.iconSubtlestDefault} />
      </IconWrapper>
      <Big style={{ width: 187, marginLeft: 15 }}>{text}</Big>
      <RadioButton
        isActive={isSelected}
        onPress={onPress}
        data-qa="cancelBookingAddToCalendarCheckbox"
      />
    </CancelActionCardWrapper>
  );
};
