import * as actionTypes from '../constants/InviteConstants';

const initialState = {
  invites: [],
  invite: {
    modality: 'Link in room',
    email: undefined,
  },
  spinnerMessage: ' ',
  isUpdating: false,
  isError: false,
};

export default function invite(state = initialState, action) {
  switch (action.type) {
    case actionTypes.REQUEST_GET_INVITE:
      return {
        ...state,
        spinnerMessage: 'Loading invitations',
        isUpdating: true,
        isError: false,
      };
    case actionTypes.REQUEST_GET_INVITE_ERROR:
      return {
        ...state,
        spinnerMessage: 'Something went wrong',
        isUpdating: false,
        isError: true,
      };
    case actionTypes.RECEIVE_GET_INVITE:
      return {
        ...state,
        invites: action.data,
        spinnerMessage: ' ',
        isUpdating: false,
        isError: false,
      };
    case actionTypes.REQUEST_POST_INVITE:
      return {
        ...state,
        spinnerMessage: ' ',
        isUpdating: true,
        isError: false,
      };
    case actionTypes.REQUEST_POST_INVITE_ERROR:
      return {
        ...state,
        spinnerMessage: 'Something went wrong',
        isUpdating: false,
        isError: true,
      };
    case actionTypes.RECEIVE_POST_INVITE:
      return {
        ...state,
        spinnerMessage: 'Invite successfully sent',
        isUpdating: false,
        isError: false,
      };
    case actionTypes.REQUEST_PATCH_INVITE:
      return {
        ...state,
        spinnerMessage: ' ',
        isUpdating: true,
        isError: false,
      };
    case actionTypes.REQUEST_PATCH_INVITE_ERROR:
      return {
        ...state,
        spinnerMessage: 'Something went wrong',
        isUpdating: false,
        isError: true,
      };
    case actionTypes.RECEIVE_PATCH_INVITE:
      return {
        ...state,
        spinnerMessage: 'Invite successfully sent',
        isUpdating: false,
        isError: false,
      };
    case actionTypes.REQUEST_DELETE_INVITE:
      return {
        ...state,
        spinnerMessage: ' ',
        isUpdating: true,
        isError: false,
      };
    case actionTypes.REQUEST_DELETE_INVITE_ERROR:
      return {
        ...state,
        spinnerMessage: 'Something went wrong',
        isUpdating: false,
        isError: true,
      };
    case actionTypes.RECEIVE_DELETE_INVITE:
      const invites = state.invites.filter(
        (invitee) => invitee.privateTalkInvitationID !== action.inviteID
      );
      return {
        ...state,
        invites,
        spinnerMessage: 'Invite deleted',
        isUpdating: false,
        isError: false,
      };

    default:
      return state;
  }
}
