const SomeProgressIcon = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle r="14.4" transform="matrix(-1 0 0 1 18 18)" stroke="#EFF3FA" strokeWidth="7.2" />
    <mask
      id="mask_some_progress"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="17"
      y="0"
      width="20"
      height="36"
    >
      <rect width="18.3051" height="36" transform="matrix(-1 0 0 1 36 0)" fill="white" />
    </mask>
    <g mask="url(#mask_some_progress)">
      <circle r="14.4" transform="matrix(-1 0 0 1 18 18)" stroke="#5C84FB" strokeWidth="7.2" />
    </g>
  </svg>
);

export default SomeProgressIcon;
