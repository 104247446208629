import { forwardRef, ImgHTMLAttributes, useState } from 'react';
import * as React from 'react';
import View from '../View';
import styled, { EmotionStyle } from '../../core/styled';
import Spinner from '../Spinner';

export interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  style?: EmotionStyle;
  source?: string;
  progressiveRenderingEnabled?: boolean;
  initialHeight?: number;
  initialWidth?: number;
  dataQa?: string;
}

const ImageComponent = styled.img<{ hide: boolean }>(({ hide }) => {
  return {
    display: hide ? 'none' : 'block',
  };
});
const LoadingComponent = styled(View)<Partial<ImageProps>>(({ initialHeight, initialWidth }) => {
  return {
    height: initialHeight,
    width: initialWidth,
  };
});

const Image = forwardRef<HTMLImageElement, ImageProps>(
  (
    {
      source,
      onLoad,
      alt = '',
      progressiveRenderingEnabled = false,
      initialHeight = 270,
      initialWidth = 200,
      dataQa,
      onError,
      ...otherProps
    },
    ref
  ) => {
    const [hasLoaded, setHasLoaded] = useState<boolean>(false);
    const handleOnLoad: React.ReactEventHandler<HTMLImageElement> = (e) => {
      if (onLoad) onLoad(e);
      setHasLoaded(true);
    };
    const shouldShowSpinner = progressiveRenderingEnabled && !hasLoaded;
    return (
      <>
        <ImageComponent
          ref={ref}
          data-qa={dataQa}
          alt={alt || ''}
          src={source}
          {...otherProps}
          onError={
            onError ||
            ((e) => {
              // eslint-disable-next-line no-console
              console.error('Image loading error', e);
            })
          }
          onLoad={handleOnLoad}
          hide={shouldShowSpinner}
        />
        {shouldShowSpinner && (
          <LoadingComponent
            align="center"
            justify="center"
            initialHeight={initialHeight}
            initialWidth={initialWidth}
          >
            <Spinner isLoading />
          </LoadingComponent>
        )}
      </>
    );
  }
);

export default Image;
