import { FunctionComponent } from 'react';
import { ClientMatchPresentingProblem } from 'ts-frontend/types';
import styled from '../../core/styled';
import Text from '../Text';

interface TextWithHighlightedMatchesProps {
  highlightTargets: string[] | ClientMatchPresentingProblem[];
  text: string[];
  isCollapsed?: boolean;
  slice?: number;
  targetKey?: string;
}

const HighlightedExpertise = styled.span(({ theme: { colors } }) => {
  return {
    backgroundColor: colors.permaPeriwinkle,
    borderRadius: 5,
    color: colors.permaSmalt,
    display: 'inline-block',
    marginRight: 2,
    paddingBottom: 2,
    paddingLeft: 6,
    paddingRight: 6,
  };
});

const TextWithHighlightedMatches: FunctionComponent<TextWithHighlightedMatchesProps> = ({
  highlightTargets,
  text,
  isCollapsed = false,
  slice = 0,
  targetKey = undefined,
}) => {
  const textArr = isCollapsed && slice ? text.slice(0, slice) : text;
  /*
    https://github.com/microsoft/TypeScript/issues/44373
    .every() .find() .filter() methods fail with "This expression is not callable." 
    error on union of array types
  */
  const highlightMatches = (
    targets: Array<string | ClientMatchPresentingProblem>,
    highlight: string
  ) =>
    targetKey
      ? targets.find((target) => target[targetKey] === highlight)
      : targets.find((target) => target === highlight);

  const matched = textArr.map((word, i) => {
    const match = highlightMatches(highlightTargets, word);
    const separator = i < textArr.length - 1 ? ', ' : '';
    if (match) {
      const highlight = targetKey ? match[targetKey] : match;
      return (
        <HighlightedExpertise key={String(word + i)}>{highlight + separator}</HighlightedExpertise>
      );
    }
    return word + separator;
  });
  return <Text style={{ textAlign: 'left' }}>{matched}</Text>;
};

export default TextWithHighlightedMatches;
