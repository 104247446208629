import { FunctionComponent, useState, useEffect } from 'react';
import {
  Button,
  Big,
  View,
  AnimatedError,
  Spinner,
  useEmotionTheme,
  styled,
  EmotionThemeProvider,
} from '@talkspace/react-toolkit';
import {
  useStripe,
  useElements,
  LinkAuthenticationElement,
  PaymentElement,
  Elements,
} from '@stripe/react-stripe-js';
import { PaymentDetails } from 'ts-frontend/types';
import { Stripe } from '@stripe/stripe-js';
import resourceHelper from 'ts-frontend/utils/resourceHelper';
import { useTranslation } from '@talkspace/i18n';
import StripeInputField from './StripeInputField';

const appearance = {
  variables: {
    fontFamily: 'Roboto, sans-serif',
    fontSizeSm: '13px',
    colorText: 'rgb(34, 47, 45)',
    borderRadius: '10px',
    colorTextPlaceholder: 'rgb(135, 150, 163)',
  },
};

const PaymentElementContainer = styled(View)({});
const PaymentStyledButton = styled(Button)({ width: '100%', margin: 'auto' });

interface StripeLinkCheckoutFormInnerProps {
  onSubmit: (token: string, paymentDetails?: PaymentDetails, email?: string) => void;
  resetError?: () => void;
  onError: (error: any) => void;
  email?: string;
  errorMessage?: string;
  submitText: string;
  isLinkLoaded: boolean;
  isProcessing: boolean;
  stripeElementFinished?: () => void;
  resetStripeLinkElements: () => void;
  aboveButtonDisclaimer?: JSX.Element;
}

const StripeLinkCheckoutFormInner: FunctionComponent<StripeLinkCheckoutFormInnerProps> = ({
  onSubmit,
  resetError = () => {},
  onError,
  email,
  errorMessage = '',
  submitText,
  isLinkLoaded,
  isProcessing,
  stripeElementFinished = () => {},
  resetStripeLinkElements,
  aboveButtonDisclaimer,
}) => {
  const [newEmail, setNewEmail] = useState('');
  const [fullName, setFullName] = useState('');
  const [fullNameError, setFullNameError] = useState('');
  const [paymentElementReady, setPaymentElementReady] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [authenticationElementReady, setAuthenticationElementReady] = useState(false);
  const [inConfirmProcess, setInConfirmProcess] = useState(false);
  const myStripe = useStripe();
  const elements = useElements();
  const showAutomationFields = resourceHelper.isDevelopmentOrStaging();

  useEffect(() => {
    if (submitted && errorMessage?.length) {
      resetStripeLinkElements();
    }
  }, [errorMessage, resetStripeLinkElements, submitted]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    resetError();

    if (!fullName) {
      setFullNameError('Full name is required.');
      return;
    }

    if (!myStripe || !elements) {
      // eslint-disable-next-line no-console
      console.log('failed to init stripe elements');
      return;
    }

    setInConfirmProcess(true);

    const confirmSetupResult = await myStripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: 'https://app.talkspace.com/',
        payment_method_data: {
          billing_details: {
            name: fullName,
          },
        },
      },
      redirect: 'if_required',
    });

    if (confirmSetupResult.error) {
      // eslint-disable-next-line no-console
      console.log('StripeLinkCheckoutFormInner error in confirmSetup', confirmSetupResult.error);
      if (confirmSetupResult.error.type !== 'validation_error') {
        onError(confirmSetupResult.error);
      }
    } else {
      setSubmitted(true);
      await onSubmit(confirmSetupResult.setupIntent.id, undefined, email || newEmail);
    }
    setInConfirmProcess(false);
  };

  const handleSubmitTest = async (event, testToken) => {
    event.preventDefault();
    setSubmitted(true);
    await onSubmit(testToken, undefined, newEmail);
  };

  const handleSubmitTestVisa = async (event) => {
    await handleSubmitTest(event, 'tok_visa');
  };

  const handleSubmitTestMastercard = async (event) => {
    await handleSubmitTest(event, 'tok_mastercard');
  };

  const handleSubmitTestInsufficientFunds = async (event) => {
    await handleSubmitTest(event, 'insufficient_funds');
  };

  const paymentElementReadyAction = () => {
    setPaymentElementReady(true);
    if (authenticationElementReady) {
      stripeElementFinished();
    }
    setSubmitted(false);
  };

  const authenticationElementReadyAction = () => {
    setAuthenticationElementReady(true);
    if (paymentElementReady) {
      stripeElementFinished();
    }
  };

  const onFullNameChange = (name: string) => {
    setFullName(name);
    setFullNameError('');
  };

  const onTestEmailFieldChange = (event) => {
    const newValue = event.target.value;
    setNewEmail(newValue);
  };

  const { t: tBookingScreen } = useTranslation('bookingScreen');
  return (
    <EmotionThemeProvider>
      <View>
        <View style={{ marginBottom: '13px' }}>
          {isLinkLoaded && authenticationElementReady && paymentElementReady && (
            <StripeInputField
              label={tBookingScreen('payment.cardholder', 'Cardholder full name', undefined)}
              placeholder={tBookingScreen('payment.enterFull', 'Enter full name', undefined)}
              dataQa="paymentFullNameInput"
              value={fullName}
              onChange={onFullNameChange}
              error={fullNameError}
            />
          )}
          {showAutomationFields && (
            <View style={{ position: 'relative' }} tabIndex={-1}>
              <View style={{ position: 'absolute', left: 300, width: 20, top: -180, opacity: 0 }}>
                <input
                  data-qa="paymentTestEmailField"
                  value={newEmail}
                  onChange={onTestEmailFieldChange}
                  tabIndex={-1}
                />
              </View>
            </View>
          )}
          <LinkAuthenticationElement
            // The types seem to say the code below is incorrect. Need to confirm with @JonathanGanzi
            // @ts-ignore
            options={{ defaultValues: { email: email || '' } }}
            onChange={(event) => {
              // @ts-ignore
              setNewEmail(event.value.email);
            }}
            onReady={authenticationElementReadyAction}
          />
        </View>
        <PaymentElementContainer>
          <PaymentElement onReady={paymentElementReadyAction} />
        </PaymentElementContainer>
        <AnimatedError
          role="alert"
          shouldAnimate
          style={{ maxHeight: 22 * Math.ceil(errorMessage.length % 50) }}
        >
          {errorMessage}
        </AnimatedError>
        {aboveButtonDisclaimer || null}
        {isLinkLoaded && authenticationElementReady && paymentElementReady && (
          <>
            <PaymentStyledButton
              dataQa="submitNewCCButton"
              type="submit"
              isLoading={inConfirmProcess || isProcessing}
              disabled={inConfirmProcess || isProcessing}
              roundedFocusStyle
              onPress={handleSubmit}
            >
              <Big variant="bigWide">{submitText}</Big>
            </PaymentStyledButton>
            {showAutomationFields && (
              <View style={{ position: 'relative' }}>
                <View style={{ position: 'absolute', opacity: 0 }}>
                  <Button
                    dataQa="handleSubmitTestVisa"
                    onPress={handleSubmitTestVisa}
                    tabIndex={-1}
                    style={{ minHeight: 10, height: 10, width: 30 }}
                  >
                    <Big variant="bigWide">visa</Big>
                  </Button>
                  <Button
                    dataQa="handleSubmitTestMastercard"
                    onPress={handleSubmitTestMastercard}
                    tabIndex={-1}
                    style={{ minHeight: 10, height: 10, width: 30 }}
                  >
                    <Big variant="bigWide">master card</Big>
                  </Button>
                  <Button
                    dataQa="handleSubmitTestInsufficientFunds"
                    onPress={handleSubmitTestInsufficientFunds}
                    tabIndex={-1}
                    style={{ minHeight: 10, height: 10, width: 30 }}
                  >
                    <Big variant="bigWide">insufficient funds</Big>
                  </Button>
                </View>
              </View>
            )}
          </>
        )}
      </View>
    </EmotionThemeProvider>
  );
};

interface StripeLinkCheckoutFormProps
  extends Omit<StripeLinkCheckoutFormInnerProps, 'resetStripeLinkElements'> {
  onLoadStripeLink: () => void;
  stripe: Stripe;
}

const StripeLinkCheckoutForm: FunctionComponent<StripeLinkCheckoutFormProps> = ({
  onSubmit,
  resetError,
  onLoadStripeLink,
  onError,
  errorMessage,
  email,
  stripe,
  submitText,
  isLinkLoaded,
  isProcessing,
  stripeElementFinished,
  aboveButtonDisclaimer,
}) => {
  const { colors } = useEmotionTheme();
  const [clientSecret, setClientSecret] = useState('');
  const [emailToUse, setEmailToUse] = useState('');
  const spinnerStyles = {
    width: 68,
    height: 68,
  };

  const resetStripeLinkElements = async () => {
    setClientSecret('');
    const res: any = await onLoadStripeLink();
    if (res) {
      setEmailToUse(res.email ?? email);
      setClientSecret(res.clientSecret);
    }
  };

  useEffect(() => {
    resetStripeLinkElements();
    // NOTE - DON'T REMOVE disable in the next line, and don't add resetStripeLinkElements to dependencies, to avoid reloading the form
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {clientSecret.length === 0 ? (
        <View
          align="center"
          justify="center"
          flex={1}
          style={{ height: 70, paddingTop: 54, paddingBottom: 54 }}
        >
          <Spinner
            primaryColor={colors.periwinkleGrey}
            style={spinnerStyles}
            containerStyle={spinnerStyles}
          />
        </View>
      ) : (
        <Elements stripe={stripe} options={{ clientSecret, appearance }}>
          <StripeLinkCheckoutFormInner
            email={emailToUse}
            onSubmit={onSubmit}
            resetError={resetError}
            onError={onError}
            errorMessage={errorMessage}
            submitText={submitText}
            isLinkLoaded={isLinkLoaded}
            isProcessing={isProcessing}
            stripeElementFinished={stripeElementFinished}
            resetStripeLinkElements={resetStripeLinkElements}
            aboveButtonDisclaimer={aboveButtonDisclaimer}
          />
        </Elements>
      )}
    </>
  );
};

export default StripeLinkCheckoutForm;
