import Svg, { Defs, Rect, LinearGradient, Stop, G, Use, Path } from 'svgs';

const OutcomeMeasuresIcon = (props) => (
  <Svg width="68px" height="68px" viewBox="0 0 62 62" {...props}>
    <Defs>
      <Rect
        id="outcome_measures_b"
        x={3.32608696}
        y={3.32608696}
        width={44.3478261}
        height={44.3478261}
        rx={22.173913}
      />
      <LinearGradient x1="50%" y1="25.2121114%" x2="50%" y2="100%" id="outcome_measures_c">
        <Stop stopColor="#8EA8FD" offset="0%" />
        <Stop stopColor="#5C84FB" offset="100%" />
      </LinearGradient>
    </Defs>
    <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <G transform="translate(5)">
        <Use fill="#000" filter="url(#outcome_measures_a)" xlinkHref="#outcome_measures_b" />
        <Use fill="#FFF" xlinkHref="#outcome_measures_b" />
      </G>
      <Path
        d="M25.5 45.854c-10.35 0-18.855-7.731-20.155-17.733l9.955.02v-.017l6.163-6.214c6.804 11.666 5.065 8.682 8.37 14.348l8.18-8.208h7.696C44.406 38.25 35.85 45.854 25.5 45.854m0-40.846A20.327 20.327 0 0145.737 22.95H35.7v.122l-4.998 5.088-5.73-9.859.015-.012-2.664-4.57-3.6 3.608.022-.015-5.684 5.638H5.263A20.327 20.327 0 0125.5 5.008M25.5 0C11.416 0 0 11.419 0 25.5 0 39.584 11.416 51 25.5 51S51 39.584 51 25.5C51 11.419 39.584 0 25.5 0"
        fill="url(#outcome_measures_c)"
        transform="translate(5)"
      />
    </G>
  </Svg>
);

export default OutcomeMeasuresIcon;
