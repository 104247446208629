import { WizardGenericActions } from 'stepWizard';
import { OtherWizardGenericActions } from '@/utils/OtherWizardGenericActions';
import { State } from '../reducers/providerLeavingState';

const initState: WizardGenericActions<State>['initState'] = async () => {
  return {
    setSelectedReasonForLeaving: (value, context) => {
      const partialState: Partial<State> = {
        isDifficultyWithPlatformReason: false,
        isReimbursementIssuesReason: false,
        isFewAmountOfClientReason: false,
        isManyAmountOfClientReason: false,
        isOtherReason: false,
      };

      const { setState, wizardContext } = context;
      const { reasonForLeaving } = wizardContext;

      partialState.isDifficultyWithPlatformReason = false;
      partialState.isReimbursementIssuesReason = false;
      partialState.isFewAmountOfClientReason = false;
      partialState.isManyAmountOfClientReason = false;
      partialState.isOtherReason = false;

      switch (reasonForLeaving) {
        case 'difficulty_getting_assistance_when_needed':
        case 'difficulty_navigating_the_platform':
        case 'platform_issues':
          partialState.isDifficultyWithPlatformReason = true;
          break;
        case 'reimbursement_issues':
          partialState.isReimbursementIssuesReason = true;
          break;
        case 'too_few_client_referrals':
          partialState.isFewAmountOfClientReason = true;
          break;
        case 'too_many_client_referrals':
          partialState.isManyAmountOfClientReason = true;
          break;
        default:
          partialState.isOtherReason = true;
      }

      setState(partialState);
    },
  };
};

const providerLeavingActions: OtherWizardGenericActions<State> = {
  initState,
};

export default providerLeavingActions;
