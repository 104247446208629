export enum AdminConfigName {
  NO_AVAILABILITY_PROMPT = 'no_availability_prompt',
  TREATMENT_INTAKE_IN_ONBOARDING = 'treatment_intake_in_onboarding',
}

export enum PublicAdminConfigName {}

export interface AdminConfigApiResponse {
  success: boolean;
  data: number;
}
