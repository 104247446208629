import moment from 'moment';
import { InsuranceEligibility } from '../types/insuranceEligibility';
import {
  PlanData,
  BillingPrice,
  LineItem,
  Currency,
  ValidatedCoupon,
  OfferPrice,
  ChangePlanCheckoutInfo,
  InvoiceLineItem,
  RoomPlanInfo,
} from '../types/payment';
import { calculatePlanDiscount } from './insuranceEligibility';

const TRIAL_DAYS = 7;

export function getRenewDate({ unit, cycleValue }: BillingPrice, isTrial: boolean) {
  if (isTrial) {
    return moment().add(TRIAL_DAYS, 'days');
  }

  switch (unit) {
    case 'month':
    case 'day':
      return moment().add(cycleValue, 'month');
    case 'one-time':
    default:
      return null;
  }
}

export function getAmountAfterTrial(billingPrice: BillingPrice) {
  // Specifically use the renew date to calculate the number of days in the cycle rather than just assuming 1 month = 30 days or something
  const cycleEndDate = getRenewDate(billingPrice, false);
  if (!cycleEndDate) {
    throw new Error("Trial period doesn't apply to one-time purchases");
  }

  const daysInCycle = moment.duration(cycleEndDate.diff(moment())).asDays();
  const remainingDaysAfterTrial = daysInCycle - TRIAL_DAYS;

  return billingPrice.amount * (remainingDaysAfterTrial / daysInCycle);
}

export function calculatePlanSavings(monthlyPlan: PlanData, currPlan: PlanData) {
  // if the currPlan has cycleValue = 1, it is the monthly, and there is no savings
  if (!currPlan.billingPrice.cycleValue || currPlan.billingPrice.cycleValue === 1) return 0;
  const {
    billingPrice: { amount: monthlyBillingAmount },
  } = monthlyPlan;
  const {
    billingPrice: { amount: currPlanBilling, cycleValue },
  } = currPlan;
  const currMonthlyAmount = currPlanBilling / cycleValue;
  return (monthlyBillingAmount - currMonthlyAmount) * cycleValue;
}

export interface FormatCurrencyOptions {
  showDecimal: boolean;
}

const defaultOptions: FormatCurrencyOptions = {
  showDecimal: false,
};

export function formatCurrency(
  amount: number,
  currency: Currency,
  options: FormatCurrencyOptions = defaultOptions
) {
  return amount.toLocaleString(undefined, {
    style: 'currency',
    currency,
    minimumFractionDigits: options.showDecimal || Math.floor(amount) !== amount ? 2 : 0,
  });
}

/**
 * @param offerPrice
 * @returns User-facing price as string. Example: `$109/week`
 */
export function getOfferPriceFormatted(offerPrice?: OfferPrice) {
  if (!offerPrice) return '';
  const { amount, unit, currency } = offerPrice;
  return `${formatCurrency(amount, currency)}${unit}`;
}

export function getBillingFrequencyDescriptor({ cycleValue, unit }: BillingPrice) {
  switch (unit) {
    case 'day':
    case 'month':
      return cycleValue === 1 ? `/${unit}` : `/${cycleValue} ${unit}s`;
    case 'one-time':
      return null;
    default:
      // Shouldn't happen
      return null;
  }
}

export const billingCycleValueDescription = {
  1: 'Monthly',
  3: 'Quarterly',
  6: 'Biannual',
};

export const calculateTotals = (lineItems: LineItem[], planPrice: number) => {
  const { total, savings, creditableAmount } = lineItems.reduce(
    (acc, { amount, includedInSavings, creditable, includedInTotal, shouldTotalBeZero }) => {
      // shouldTotalBeZero :
      // rare cases like "7 days free trial" should show a total amount to pay of 0 although their real savings is only 1 week of savings.
      let newTotal = 0;
      if (!shouldTotalBeZero && !acc.shouldTotalBeZero) {
        newTotal = includedInTotal ? acc.total + amount : acc.total;
      }
      return {
        total: newTotal,
        savings: includedInSavings ? acc.savings + Math.abs(amount) : acc.savings,
        creditableAmount:
          amount < 0 && creditable ? acc.creditableAmount + Math.abs(amount) : acc.creditableAmount,
        shouldTotalBeZero: shouldTotalBeZero || acc.shouldTotalBeZero,
      };
    },
    { total: 0, savings: 0, creditableAmount: 0, shouldTotalBeZero: false }
  );
  return {
    total: Math.max(-creditableAmount, total),
    savings: Math.abs(savings) > planPrice ? planPrice : savings,
  };
};

function transformBillingCycleUnit(unit: string, value: number) {
  if (value === 240) {
    // BH plans have billingCycleValue 240
    return 'one-time';
  }
  switch (unit) {
    case 'month':
    case 'day':
      return unit;
    case '':
      return 'one-time';
    default:
      throw new Error(`Unexpected billing cycle unit: ${unit}`);
  }
}

export function transformPlan(plan: RoomPlanInfo, planName?: string): PlanData {
  // Some really old plans don't have a plan_display
  const display = plan.planDisplayJSON;

  return {
    id: plan.plan_id || plan.id, // StrategyBundle uses plan_id, StrategyRegular uses id
    displayName: display ? display.display_name : planName,
    sessionDetails: display?.session_details,
    description: display
      ? [
          {
            text: display.display_description_1,
            style: display.description_style_1,
          },
          {
            text: display.display_description_2,
            style: display.description_style_2,
          },
          {
            text: display.display_description_3,
            style: display.description_style_3,
          },
        ]
      : [],
    offerPrice: display
      ? {
          amount: +display.display_price[0].amount,
          originalPrice: display.display_price[0].original_price
            ? +display.display_price[0].original_price
            : undefined,
          symbol: display.display_price[0].symbol,
          unit: display.display_term,
          currency: plan.currency,
        }
      : undefined,
    billingPrice: {
      amount: +plan.billingChargePriceUSD,
      cycleValue: plan.billingCycleValue,
      unit: transformBillingCycleUnit(plan.billingCycleUnit, plan.billingCycleValue),
      currency: plan.currency,
    },
    displayPaymentPeriod: display ? display.display_payment_period : undefined,
    icon: display ? display.icon : undefined,
    badge: display ? display.badge : undefined,
    allowCoupons: display ? display.allow_coupons !== false : undefined,
  };
}

function generatePlanNameLine(
  planName: string,
  { unit: billingUnit, cycleValue }: BillingPrice,
  twoPageCheckoutExperiment?: boolean,
  offerPrice?: OfferPrice,
  savings?: number
) {
  if (billingUnit !== 'month' || !offerPrice || offerPrice.unit !== '/week' || !cycleValue) {
    return planName;
  }

  const formattedCurrency = formatCurrency(
    savings ? offerPrice.amount + savings / cycleValue / 4 : offerPrice.amount, // include multi-month prepay savings in displayed plan amount
    offerPrice.currency
  );

  const cycles = cycleValue * 4;

  if (twoPageCheckoutExperiment) {
    if (billingUnit === 'month' && cycleValue === 1) {
      return `Cost per month (${formattedCurrency}/week x ${cycles} weeks)`;
    }
    if (billingUnit === 'month' && cycleValue === 3) {
      return `Cost billed every three months (${formattedCurrency}/week x ${cycles} weeks)`;
    }
    if (billingUnit === 'month' && cycleValue === 6) {
      return `Cost billed every six months (${formattedCurrency}/week x ${cycles} weeks)`;
    }
    return `${planName} (${cycles}x${formattedCurrency})`;
  }

  return `${planName} (${cycles}x${formattedCurrency})`;
}

export function generateConsumerLineItems(
  {
    billingPrice,
    offerPrice,
    savings,
    displayPaymentPeriod,
    discountPercent,
    displayName: planName,
  }: PlanData,
  validatedCoupons?: ValidatedCoupon[],
  trialOfferPrice?: number,
  changePlanCheckoutInfo?: ChangePlanCheckoutInfo,
  voucherInsuranceValue?: number,
  invoiceLineItems?: InvoiceLineItem[],
  isNextPayment?: boolean,
  insuranceEligibility?: InsuranceEligibility,
  twoPageCheckoutExperiment?: boolean
) {
  const { amount: planAmount } = billingPrice;
  const lineItems: LineItem[] = [];
  lineItems.push({
    name: generatePlanNameLine(
      planName,
      billingPrice,
      twoPageCheckoutExperiment,
      offerPrice,
      savings
    ),
    amount: savings ? planAmount + savings : planAmount, // include multi-month prepay savings in displayed plan amount
    currency: billingPrice.currency,
    includedInSavings: false,
    creditable: false,
    includedInTotal: true,
  });

  // if multi-month prepay savings are present
  if (savings) {
    lineItems.push({
      name: `${
        billingPrice && billingPrice.cycleValue
          ? `${
              billingCycleValueDescription[
                billingPrice.cycleValue as keyof typeof billingCycleValueDescription
              ]
            } billing` // "Quarterly billing"
          : displayPaymentPeriod // "Paid Every 3 Months"
      } discount (${discountPercent})`,
      amount: -savings,
      currency: billingPrice.currency,
      includedInSavings: true,
      creditable: false,
      includedInTotal: true,
    });
  }

  if (insuranceEligibility && insuranceEligibility.isEligible) {
    const insuranceEligibilityCoverPrice = calculatePlanDiscount(
      planAmount,
      insuranceEligibility.copayCents || 0,
      insuranceEligibility.coinsurancePercent || 0,
      insuranceEligibility.deductible || 0
    );
    if (insuranceEligibilityCoverPrice > 0) {
      lineItems.push({
        name: 'Your insurance may cover',
        amount: -insuranceEligibilityCoverPrice,
        currency: billingPrice.currency,
        includedInSavings: true,
        creditable: false,
        includedInTotal: true,
      });
    }
  }

  if (voucherInsuranceValue) {
    lineItems.push({
      name: `Insurance eligibility discount`,
      amount: -voucherInsuranceValue,
      currency: billingPrice.currency,
      includedInSavings: true,
      creditable: false,
      includedInTotal: true,
    });
  }

  if (validatedCoupons && validatedCoupons.length > 0) {
    validatedCoupons.forEach((validatedCoupon) => {
      const namePrefix =
        isNextPayment || validatedCoupon.isRecurring ? `Charge promo` : `First charge promo`;
      lineItems.push({
        name: twoPageCheckoutExperiment
          ? `Coupon code ${validatedCoupon.code.toUpperCase()} applied`
          : `${namePrefix} ${validatedCoupon.code.toUpperCase()}`,
        amount: -validatedCoupon.amount,
        currency: billingPrice.currency,
        includedInSavings: true,
        creditable: false,
        includedInTotal: true,
        isRecurringDiscount: validatedCoupon.isRecurring,
      });
    });
  }

  if (trialOfferPrice !== undefined && offerPrice) {
    lineItems.push({
      name: '7 days free trial',
      amount: getAmountAfterTrial(billingPrice) - billingPrice.amount,
      currency: billingPrice.currency,
      includedInSavings: true,
      creditable: false,
      includedInTotal: true,
      shouldTotalBeZero: true,
    });

    if (trialOfferPrice > 0) {
      lineItems.push({
        name: 'One-time initiation fee',
        amount: trialOfferPrice,
        currency: billingPrice.currency,
        includedInSavings: false,
        creditable: false,
        includedInTotal: true,
      });
    }
  }

  if (invoiceLineItems) {
    invoiceLineItems.forEach((item) => {
      lineItems.push({
        name: item.description,
        amount: item.amount / 100,
        currency: billingPrice.currency,
        includedInSavings: true,
        creditable: false,
        includedInTotal: true,
      });
    });
  }
  if (changePlanCheckoutInfo) {
    if (changePlanCheckoutInfo.prorationAmountCents) {
      lineItems.push({
        name:
          changePlanCheckoutInfo.prorationAmountCents > 0 ? 'Prorated charge' : 'Prorated credit',
        amount: changePlanCheckoutInfo.prorationAmountCents / 100,
        currency: billingPrice.currency,
        includedInSavings: false,
        creditable: true,
        includedInTotal: true,
      });
    }

    if (changePlanCheckoutInfo.customerBalanceCents) {
      lineItems.push({
        name:
          changePlanCheckoutInfo.customerBalanceCents > 0 ? 'Existing charge' : 'Existing credit',
        amount: changePlanCheckoutInfo.customerBalanceCents / 100,
        currency: billingPrice.currency,
        includedInSavings: false,
        creditable: false,
        includedInTotal: true,
      });
    }
  }

  return lineItems;
}

export const generateEligibilityLineItems = ({
  maximumCost,
  copayCents,
  currency,
  validatedCoupon,
  customerBalanceCents,
  voucherInsuranceValue,
  invoiceLineItems,
  plural,
}: {
  maximumCost: number;
  copayCents: number;
  currency: Currency;
  validatedCoupon?: ValidatedCoupon;
  customerBalanceCents?: number;
  voucherInsuranceValue?: number;
  invoiceLineItems?: InvoiceLineItem[];
  plural?: boolean;
}): LineItem[] => {
  const lineItems: LineItem[] = [];
  if (copayCents || copayCents === 0) {
    [
      {
        name: plural ? 'Maximum cost of each session' : 'Maximum cost of service',
        amount: maximumCost / 100,
        currency,
        includedInSavings: false,
        creditable: false,
        includedInTotal: true,
      },
      {
        name: 'Your insurance may cover',
        amount: (copayCents - maximumCost) / 100,
        currency,
        includedInSavings: true,
        creditable: false,
        includedInTotal: true,
      },
    ].forEach((item) => lineItems.push(item));
  }
  if (validatedCoupon) {
    lineItems.push({
      name: `First charge promo ${validatedCoupon.code.toUpperCase()}`,
      amount: -validatedCoupon.amount,
      currency,
      includedInSavings: true,
      creditable: false,
      includedInTotal: true,
      isRecurringDiscount: validatedCoupon.isRecurring,
    });
  }
  if (invoiceLineItems) {
    invoiceLineItems.forEach((item) => {
      lineItems.push({
        name: item.description,
        amount: item.amount / 100,
        currency,
        includedInSavings: true,
        creditable: false,
        includedInTotal: true,
      });
    });
  }
  if (customerBalanceCents) {
    lineItems.push({
      name: customerBalanceCents > 0 ? 'Existing charge' : 'Existing credit',
      amount: customerBalanceCents / 100,
      currency,
      includedInSavings: false,
      creditable: false,
      includedInTotal: true,
    });
  }
  return lineItems;
};

const restoreOfferFromInsuranceEligibilityDiscounts = (
  selectedSubscription: PlanData
): PlanData => {
  const { offerPrice, billingPrice, ...priceArrRest } = selectedSubscription;
  const offerBeforeInsurance = offerPrice?.originalPrice || 0;
  const newAmount = offerBeforeInsurance * 4 * (billingPrice.cycleValue || 1);
  const newAmountWeekly = offerBeforeInsurance;
  return {
    ...priceArrRest,
    offerPrice: {
      ...offerPrice!,
      amount: newAmountWeekly,
    },
    billingPrice: {
      ...billingPrice!,
      amount: newAmount,
    },
  };
};

export const calculateBillingTotalsWithInsuranceEligibility = ({
  selectedSubscription,
  validatedCoupons,
  trialOfferPrice,
  changePlanCheckoutInfo,
  voucherInsuranceValue,
  invoiceLineItems,
  isNextPayment,
  insuranceEligibility,
  twoPageCheckoutExperiment,
}: {
  selectedSubscription: PlanData;
  validatedCoupons?: ValidatedCoupon[];
  trialOfferPrice?: number;
  changePlanCheckoutInfo?: ChangePlanCheckoutInfo;
  voucherInsuranceValue?: number;
  invoiceLineItems?: InvoiceLineItem[];
  isNextPayment?: boolean;
  insuranceEligibility?: InsuranceEligibility;
  twoPageCheckoutExperiment?: boolean;
}): { total: number; savings: number; lineItems: LineItem[] } => {
  let originalBillingPriceSubscription = selectedSubscription;
  if (
    insuranceEligibility ||
    // We modified offerPrice to include discount.
    // This undoes that for the Review Plan page to compare original with discount
    // https://github.com/talktala/talkspace-web/blob/e461891c7624aadd6ed2f2765f0295614fa68065/shared/offer/utils/dataTransforms.ts#L164
    (selectedSubscription.oopPromoExperiment && selectedSubscription.offerPrice?.originalPrice)
  ) {
    originalBillingPriceSubscription =
      restoreOfferFromInsuranceEligibilityDiscounts(selectedSubscription);
  }
  const lineItems = generateConsumerLineItems(
    originalBillingPriceSubscription,
    validatedCoupons,
    trialOfferPrice,
    changePlanCheckoutInfo,
    voucherInsuranceValue,
    invoiceLineItems,
    isNextPayment,
    insuranceEligibility,
    twoPageCheckoutExperiment
  );

  const planAmount =
    originalBillingPriceSubscription.billingPrice &&
    originalBillingPriceSubscription.billingPrice.amount;
  const { total, savings } = calculateTotals(lineItems, planAmount);
  return { total, savings, lineItems };
};
