import { VoidFunctionComponent } from 'react';
import moment from 'moment';
import { Standard, ExtraHuge, View, EmotionStyle } from '@talkspace/react-toolkit';
import { TherapistTimeslot, VideoCreditOffer } from 'ts-frontend/types';

interface Props {
  selectedTimeslot?: TherapistTimeslot | null;
  selectedCreditOption?: VideoCreditOffer;
  style?: EmotionStyle;
}

const BookingDatesOnBoarding: VoidFunctionComponent<Props> = ({
  selectedTimeslot,
  selectedCreditOption,
  style,
}) => (
  <View style={style} align="center">
    <ExtraHuge>{moment(selectedTimeslot?.start).format('dddd, MMMM D')}</ExtraHuge>
    <Standard variant="standardDarkGrey" style={{ marginTop: 8, marginBottom: 12 }}>
      <>
        {moment(selectedTimeslot?.start).format('h:mma')} {' – '}
        {moment(selectedTimeslot?.start)
          .add(selectedCreditOption?.creditMinutes, 'minutes')
          .format('h:mma')}
      </>
    </Standard>
  </View>
);

export default BookingDatesOnBoarding;
