import { FunctionComponent, ReactNode } from 'react';
import Lottie from 'react-lottie';
import styled, { EmotionStyle } from '../../../core/styled';
import View from '../../../components/View';
import HiddenText from '../../../components/HiddenText';
import loadingSpinner from '../../../utils/loadingSpinner.json';

interface AnimatedSpinnerProps {
  icon?: (props: { width?: number | string; height?: number | string }) => ReactNode;
  width?: number | string;
  height?: number | string;
  color?: string;
  style?: EmotionStyle;
  loadingText?: string;
}

type SpinnerWrapperProps = Pick<AnimatedSpinnerProps, 'width' | 'height'>;

const SpinnerWrapper = styled(View)<SpinnerWrapperProps>(({ width, height }) => {
  return {
    width,
    height,
    alignSelf: 'center',
  };
});

const LottieWrapper = styled(View)<Pick<AnimatedSpinnerProps, 'color'>>(({ color }) => {
  return {
    position: 'absolute',
    ...(color && {
      '& path': {
        stroke: 'white',
      },
    }),
  };
});

const IconWrapper = styled(View)({
  position: 'absolute',
});

const AnimatedSpinner: FunctionComponent<AnimatedSpinnerProps> = ({
  icon,
  width = 160,
  height = 160,
  loadingText,
  style,
  color,
}) => (
  <SpinnerWrapper
    aria-atomic="true"
    aria-live="polite"
    flex={1}
    align="center"
    justify="center"
    width={width}
    height={height}
    style={style}
  >
    <HiddenText>{loadingText || 'Loading...'}</HiddenText>
    <LottieWrapper color={color}>
      <Lottie
        isClickToPauseDisabled
        options={{ loop: true, autoplay: true, animationData: loadingSpinner }}
        width={width}
        height={height}
      />
    </LottieWrapper>
    {icon && <IconWrapper style={{ width, height }}>{icon({ width, height })}</IconWrapper>}
  </SpinnerWrapper>
);
export default AnimatedSpinner;
