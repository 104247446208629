import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import {
  Button,
  Col,
  FormControl,
  ListGroup,
  ListGroupItem,
  OverlayTrigger,
  Panel,
  Row,
  Tooltip,
} from 'react-bootstrap';
import {
  styled,
  formatSelectOptionsFromConfig,
  View,
  AddressComponent,
} from '@talkspace/react-toolkit';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import { countries, states } from '@talkspace/configs';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import '../Account.css';

import { SetIsSectionLoadedFn } from '../accountTypes';
import { getUserData } from '../../../utils/token';
import { TherapistHomeAddress } from '../../../types/account';
import useQueryTherapistHomeAddress from '../../../hooks/account/useQueryTherapistHomeAddress';
import useMutationUpdateTherapistHomeAddress from '../../../hooks/account/useMutationUpdateTherapistHomeAddress';

const REQUIRED_FIELD_ERROR = 'This field is required.';

const DEFAULT_COUNTRY_CODE = 'US';

const Label = styled('label')({
  fontFamily: "'Roboto', sans-serif",
  fontSize: 12,
  color: '#b8b8b8',
  letterSpacing: -0.15,
  fontWeight: 400,
});

const Error = styled('p')({
  fontSize: 14,
  color: 'rgba(255, 0, 117, 0.8)',
  letterSpacing: -0.15,
  margin: '4px 0 0',
});

const tooltip = (
  <Tooltip id="HomeAddressTooltip" className="tooltip ts-tooltip ts-profile-text-white">
    Your Home Address should represent your permanent residence
  </Tooltip>
);

const parseAddress = (addressComponents: AddressComponent[]): TherapistHomeAddress => {
  let address: string | undefined;
  let addressStreet: string | undefined;

  const result: TherapistHomeAddress = {
    city: '',
    country: '',
    state: '',
    streetAddress: '',
    zipcode: '',
  };

  addressComponents.forEach(({ long_name: longName, short_name: shortName, types }) => {
    // this is the street number portion of the address i.e. 70
    if (types.includes('street_number')) {
      address = longName;
    }

    // street name portion of the address
    if (types.includes('route')) {
      addressStreet = longName;
    }

    // city portion of the address
    if (types.includes('locality') && longName) {
      result.city = longName;
    }

    // city sometimes appears in 'sublocality', like when the address is in Brooklyn, NY (go BK!)
    if (
      (types.includes('sublocality') || types.includes('postal_town')) &&
      !result.city &&
      longName
    ) {
      result.city = longName;
    }

    // state
    if (types.includes('administrative_area_level_1') && shortName) {
      result.state = shortName;
    }

    // zipcode
    if (types.includes('postal_code') && longName) {
      result.zipcode = longName;
    }

    // country
    if (types.includes('country') && shortName) {
      result.country = shortName;
    }
  });

  // addressLine1 needed so the address can be presented like people are used to seeing
  result.streetAddress = `${address ?? ''} ${addressStreet ?? ''}`.trim();

  result.state = result.country === DEFAULT_COUNTRY_CODE ? result.state : '';

  return result;
};

const stateOptions = [{ value: '', label: '' }, ...formatSelectOptionsFromConfig(states)];

const countryOptions = formatSelectOptionsFromConfig(countries);

const defaultFormValues: TherapistHomeAddress = {
  streetAddress: '',
  city: '',
  state: '',
  country: DEFAULT_COUNTRY_CODE,
  zipcode: '',
};

const defaultFormErrors: TherapistHomeAddress = {
  streetAddress: '',
  city: '',
  state: '',
  country: '',
  zipcode: '',
};

type FormMode = 'view' | 'edit';

interface Props {
  setIsSectionLoaded: SetIsSectionLoadedFn;
}

export default function HomeAddress({ setIsSectionLoaded }: Props): JSX.Element {
  const [isSectionReady, setIsSectionReady] = useState(false);

  const [formMode, setFormMode] = useState<FormMode>('view');

  const [formValues, setFormValues] = useState<TherapistHomeAddress>(defaultFormValues);

  const [formErrors, setFormErrors] = useState<TherapistHomeAddress>(defaultFormErrors);

  const { mutate: updateTherapistHomeAddress } = useMutationUpdateTherapistHomeAddress();

  const userID = getUserData().id;

  const {
    data: homeAddressData,
    isSuccess: isHomeAddressLoaded,
    isError: homeAddressError,
  } = useQueryTherapistHomeAddress(userID);

  useEffect(() => {
    if (homeAddressError) {
      toast(
        <div
          className="toaster toaster-error"
          style={{ background: '#FF0000', color: '#FFF', padding: '12px', fontSize: '16px' }}
        >
          Failed to retrieve home address
        </div>,
        { autoClose: 3000 }
      );
    }
  }, [homeAddressError]);

  useEffect(() => {
    if (!isHomeAddressLoaded || !homeAddressData) {
      return;
    }

    setFormValues({
      streetAddress: homeAddressData.streetAddress,
      city: homeAddressData.city,
      state: homeAddressData.state,
      country: homeAddressData.country,
      zipcode: homeAddressData.zipcode,
    });

    setIsSectionLoaded({ homeAddress: true });
    setIsSectionReady(true);
  }, [isHomeAddressLoaded, homeAddressData, setIsSectionLoaded]);

  const handleFormInputChange = (e: FormEvent<FormControl>, field: keyof TherapistHomeAddress) => {
    const newValue = (e as unknown as ChangeEvent<HTMLInputElement>).target.value;

    setFormValues((prev) => {
      return {
        ...prev,
        [field]: newValue,
      };
    });

    setFormErrors((prev) => {
      return {
        ...prev,
        [field]: newValue.length === 0 ? REQUIRED_FIELD_ERROR : '',
      };
    });
  };

  const handleStreetAddressChange = (newValue: string) => {
    setFormValues((prev) => {
      return {
        ...prev,
        streetAddress: newValue,
      };
    });

    setFormErrors((prev) => {
      return {
        ...prev,
        streetAddress: newValue.length === 0 ? REQUIRED_FIELD_ERROR : '',
      };
    });
  };

  const handleCountryChange = (e: FormEvent<FormControl>) => {
    const newValue = (e as unknown as ChangeEvent<HTMLInputElement>).target.value;

    setFormValues((prev) => {
      return {
        ...prev,
        country: newValue,
        state: newValue === DEFAULT_COUNTRY_CODE ? prev.state : '',
      };
    });

    setFormErrors((prev) => {
      return {
        ...prev,
        country: newValue.length === 0 ? REQUIRED_FIELD_ERROR : '',
        state: newValue === DEFAULT_COUNTRY_CODE ? REQUIRED_FIELD_ERROR : '',
      };
    });
  };

  const handleAddressSelect = async (address: string) => {
    const results = await geocodeByAddress(address);

    const [{ address_components: addressComponents = [] } = {}] = results;

    const parsedAddress = parseAddress(addressComponents);

    setFormValues((prev) => {
      return {
        ...prev,
        ...parsedAddress,
      };
    });

    setFormErrors((prev) => {
      return {
        ...prev,
        streetAddress: parsedAddress.streetAddress.length === 0 ? REQUIRED_FIELD_ERROR : '',
        city: parsedAddress.city.length === 0 ? REQUIRED_FIELD_ERROR : '',
        state:
          parsedAddress.country === DEFAULT_COUNTRY_CODE && parsedAddress.state.length === 0
            ? REQUIRED_FIELD_ERROR
            : '',
        country: parsedAddress.country.length === 0 ? REQUIRED_FIELD_ERROR : '',
        zipcode: parsedAddress.zipcode.length === 0 ? REQUIRED_FIELD_ERROR : '',
      };
    });
  };

  const handleEditClick = () => {
    setFormMode('edit');
  };

  const handleCancelClick = () => {
    setFormValues((prev) => {
      return { ...prev, ...homeAddressData };
    });
    setFormMode('view');
  };

  const handleSaveClick = () => {
    const therapistUserID = getUserData().id;
    updateTherapistHomeAddress(
      { therapistUserID, data: formValues },
      {
        onSuccess: () => {
          setFormMode('view');
          toast(<div className="toaster toaster-success">Changes are saved successfully!</div>, {
            autoClose: 3000,
          });
        },
      }
    );
  };

  const canSave = Object.values(formErrors).every((value) => value === '');

  return (
    <div>
      <Col xs={12} className={`ts-panel-title ${isSectionReady ? 'show-panel' : 'hidden-panel'}`}>
        <Col xs={5} className="ts-font-black">
          Home address
          <OverlayTrigger placement="top" trigger={['click', 'focus', 'hover']} overlay={tooltip}>
            <i className="fa fa-fw fa-question-circle fa-lg" />
          </OverlayTrigger>
        </Col>

        <Col xs={6} />

        {formMode === 'view' ? (
          <Button className="ts-edit-button pull-right" onClick={handleEditClick}>
            Edit
          </Button>
        ) : (
          <>
            <Button
              className="btn-ts-default btn-ts-green ts-profile-text-white pull-right save-btn"
              onClick={handleSaveClick}
              disabled={!canSave}
            >
              Save Changes
            </Button>

            <Button
              className="btn-ts-default ts-profile-btn-text-green cancel-btn pull-right"
              onClick={handleCancelClick}
            >
              Cancel
            </Button>
          </>
        )}
      </Col>

      <Col xs={12}>
        <Panel className={`ts-my-account-panel ${isSectionReady ? 'show-panel' : 'hidden-panel'}`}>
          <div className="ts-profile-container">
            <Row style={{ marginBottom: '10px' }}>
              <Col md={10} xs={12}>
                <Row style={{ marginBottom: '10px' }}>
                  <Col md={4}>
                    <Label>Street</Label>

                    <PlacesAutocomplete
                      value={formValues.streetAddress}
                      onChange={handleStreetAddressChange}
                      onSelect={handleAddressSelect}
                    >
                      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <View style={{ position: 'relative' }}>
                          <FormControl
                            {...getInputProps({
                              placeholder: 'Search places...',
                            })}
                            className={formErrors.streetAddress ? 'invalid' : ''}
                            value={formValues.streetAddress}
                            disabled={formMode === 'view'}
                            onChange={(e) => {
                              handleFormInputChange(e, 'streetAddress');
                              getInputProps().onChange(e);
                            }}
                          />

                          <ListGroup
                            style={{ position: 'absolute', zIndex: 1000, top: 40, width: 360 }}
                          >
                            {loading && <ListGroupItem>Loading...</ListGroupItem>}
                            {suggestions.map((suggestion) => (
                              <ListGroupItem
                                {...getSuggestionItemProps(suggestion)}
                                key={suggestion.placeId}
                              >
                                {suggestion.description}
                              </ListGroupItem>
                            ))}
                          </ListGroup>
                        </View>
                      )}
                    </PlacesAutocomplete>

                    <Error hidden={!formErrors.streetAddress}>{formErrors.streetAddress}</Error>
                  </Col>

                  <Col md={4} xs={6}>
                    <Label>City</Label>

                    <FormControl
                      className={formErrors.city ? 'invalid' : ''}
                      value={formValues.city}
                      disabled={formMode === 'view'}
                      onChange={(e) => handleFormInputChange(e, 'city')}
                      placeholder="City"
                    />

                    <Error hidden={!formErrors.city}>{formErrors.city}</Error>
                  </Col>

                  <Col md={4} xs={6}>
                    <Label>State</Label>

                    <FormControl
                      componentClass="select"
                      disabled={formMode === 'view' || formValues.country !== DEFAULT_COUNTRY_CODE}
                      value={formValues.state}
                      onChange={(e) => handleFormInputChange(e, 'state')}
                      placeholder="State"
                    >
                      {stateOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </FormControl>

                    <Error hidden={!formErrors.state}>{formErrors.state}</Error>
                  </Col>
                </Row>

                <Row>
                  <Col md={4} xs={6}>
                    <Label>Country</Label>

                    <FormControl
                      componentClass="select"
                      disabled={formMode === 'view'}
                      value={formValues.country}
                      onChange={handleCountryChange}
                      placeholder="Country"
                    >
                      {countryOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </FormControl>

                    <Error hidden={!formErrors.country}>{formErrors.country}</Error>
                  </Col>

                  <Col md={4} xs={6}>
                    <Label>Zip code</Label>

                    <FormControl
                      className={formErrors.zipcode ? 'invalid' : ''}
                      value={formValues.zipcode}
                      disabled={formMode === 'view'}
                      onChange={(e) => handleFormInputChange(e, 'zipcode')}
                      placeholder="Zip code"
                    />

                    <Error hidden={!formErrors.zipcode}>{formErrors.zipcode}</Error>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Panel>
      </Col>

      <ToastContainer closeButton={false} position={toast.POSITION.BOTTOM_RIGHT} hideProgressBar />
    </div>
  );
}
