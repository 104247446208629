// TEMPORARILY USED IN InRoomScheduling
import { PaymentDetails } from '../../types';

export interface ActionAuth {
  token: string;
  expiration: Date;
}

export type ModalType =
  | 'changeNickname'
  | 'passwordRequired'
  | 'changeEmail'
  | 'changePassword'
  | 'updatePaymentDetails'
  | 'emailVerification';

export interface State {
  paymentDetails?: PaymentDetails;
  successModalText: string;
  isLoading: boolean;
  error: string;
}

export type Action =
  | { type: 'setSuccessModalText'; payload: Pick<State, 'successModalText'> }
  | { type: 'requestGetPaymentDetails' }
  | {
      type: 'receiveGetPaymentDetails';
      payload: Pick<State, 'paymentDetails'>;
    }
  | { type: 'requestUpdatePaymentDetails' }
  | {
      type: 'receiveUpdatePaymentDetails';
      payload: Pick<State, 'paymentDetails'>;
    }
  | { type: 'setError'; error?: string }
  | { type: 'clearError' };

export const initialState: State = {
  paymentDetails: undefined,
  successModalText: '',
  isLoading: false,
  error: '',
};

export const accountDetailsReducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case 'setSuccessModalText':
      return {
        ...state,
        ...action.payload,
      };
    case 'requestGetPaymentDetails':
    case 'requestUpdatePaymentDetails':
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case 'receiveGetPaymentDetails':
    case 'receiveUpdatePaymentDetails':
      return {
        ...state,
        isLoading: false,
        error: '',
        ...action.payload,
      };
    case 'setError':
      return {
        ...state,
        error: action.error || 'Something went wrong. Please try again later',
        isLoading: false,
      };
    case 'clearError':
      return {
        ...state,
        error: '',
      };
    default:
      return state;
  }
};
