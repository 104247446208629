import { VoidFunctionComponent } from 'react';
import Svg, { Path, Rect } from 'svgs';
import { EmotionStyle } from '../../core/styled';
import withHover, { WithHoverProps } from '../../hoc/withHover';
import HoverInfoBox from '../HoverInfoBox';

interface IDBoxProps extends WithHoverProps {
  width?: number;
  height?: number;
  style?: EmotionStyle;
  isMenuShowing?: boolean;
}

const IDBox: VoidFunctionComponent<IDBoxProps> = ({
  width = 24,
  height = 24,
  isHovering,
  isMenuShowing,
  ...otherProps
}) => {
  if (isHovering || isMenuShowing) {
    return (
      <>
        <Svg
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...otherProps}
        >
          <Rect width={width} height={height} rx="6" fill="#E1F0EE" />
          <Path
            d="M15.6429 3.5H8.35714C5.67462 3.5 3.5 5.67462 3.5 8.35714V15.6429C3.5 18.3254 5.67462 20.5 8.35714 20.5H15.6429C18.3254 20.5 20.5 18.3254 20.5 15.6429V8.35714C20.5 5.67462 18.3254 3.5 15.6429 3.5Z"
            stroke="#005C65"
            strokeWidth="1.8"
          />
          <Path
            d="M9.61674 15.6418H7.8584V8.04492H9.61674V15.6418ZM10.9264 15.6418V8.04492H13.6708C14.5648 8.04492 15.3127 8.33624 15.9144 8.91888C16.5162 9.50151 16.8171 10.2485 16.8171 11.1598V12.5269C16.8171 13.4417 16.5162 14.1896 15.9144 14.7705C15.3127 15.3513 14.5648 15.6418 13.6708 15.6418H10.9264ZM12.6951 9.40151V14.2852H13.5508C14.0204 14.2852 14.3874 14.1243 14.6518 13.8026C14.9161 13.4808 15.0483 13.0556 15.0483 12.5269V11.1494C15.0483 10.6242 14.9161 10.2015 14.6518 9.88153C14.3874 9.56151 14.0204 9.40151 13.5508 9.40151H12.6951Z"
            fill="#005C65"
          />
        </Svg>
        {isHovering && (
          <HoverInfoBox
            toolTipText="View client ID"
            isTooltipOpen
            styles={{ width: 120, left: -47, top: -47 }}
          />
        )}
      </>
    );
  }

  return (
    <Svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <Path
        d="M13.6429 1.5H6.35714C3.67462 1.5 1.5 3.67462 1.5 6.35714V13.6429C1.5 16.3254 3.67462 18.5 6.35714 18.5H13.6429C16.3254 18.5 18.5 16.3254 18.5 13.6429V6.35714C18.5 3.67462 16.3254 1.5 13.6429 1.5Z"
        stroke="#005C65"
        strokeWidth="1.8"
      />
      <Path
        d="M7.61674 13.6418H5.8584V6.04492H7.61674V13.6418ZM8.92637 13.6418V6.04492H11.6708C12.5648 6.04492 13.3127 6.33624 13.9144 6.91888C14.5162 7.50151 14.8171 8.2485 14.8171 9.15985V10.5269C14.8171 11.4417 14.5162 12.1896 13.9144 12.7705C13.3127 13.3513 12.5648 13.6418 11.6708 13.6418H8.92637ZM10.6951 7.40151V12.2852H11.5508C12.0204 12.2852 12.3874 12.1243 12.6518 11.8026C12.9161 11.4808 13.0483 11.0556 13.0483 10.5269V9.14941C13.0483 8.62417 12.9161 8.20154 12.6518 7.88153C12.3874 7.56151 12.0204 7.40151 11.5508 7.40151H10.6951Z"
        fill="#005C65"
      />
    </Svg>
  );
};

export default withHover(IDBox);
