const ClientMeditating = (props) => (
  <svg width="231" height="190" viewBox="0 0 231 190" fill="none" {...props}>
    <path
      d="M230.772 168.352C230.772 168.505 179.97 168.628 117.311 168.628C54.6512 168.628 3.83887 168.505 3.83887 168.352C3.83887 168.199 54.6307 168.046 117.305 168.046C179.98 168.046 230.772 168.209 230.772 168.352Z"
      fill="#263238"
    />
    <path
      d="M63.6383 42.457C57.0805 45.8551 52.0589 51.6737 48.4454 58.1324C44.832 64.591 42.5044 71.6257 40.1944 78.6371L38.1694 114.794C45.7979 101.824 51.9193 86.5737 53.5718 71.6141L48.4978 71.166C51.896 70.6424 54.753 68.1811 56.5743 65.2543C58.3956 62.3276 59.3266 58.9644 60.2343 55.6478L54.0431 56.5672C58.938 53.2736 62.3747 48.2198 63.6383 42.457Z"
      fill="#455A64"
    />
    <g opacity="0.3">
      <path
        d="M63.6383 42.457C57.0805 45.8551 52.0589 51.6737 48.4454 58.1324C44.832 64.591 42.5044 71.6257 40.1944 78.6371L38.1694 114.794C45.7979 101.824 51.9193 86.5737 53.5718 71.6141L48.4978 71.166C51.896 70.6424 54.753 68.1811 56.5743 65.2543C58.3956 62.3276 59.3266 58.9644 60.2343 55.6478L54.0431 56.5672C58.938 53.2736 62.3747 48.2198 63.6383 42.457Z"
        fill="black"
      />
    </g>
    <path
      d="M37.0463 123.243C37.0463 123.243 20.4977 103.46 20.713 84.5435H27.3755C27.3755 84.5435 20.1311 79.7722 20.713 77.0957C21.2948 74.4191 21.4752 64.2947 21.4752 64.2947L27.5908 65.825C27.5908 65.825 23.0056 63.5325 22.4295 59.9017C21.8534 56.2709 23.7445 39.4551 23.7445 39.4551C23.7445 39.4551 31.1868 47.2986 33.5143 55.3224C35.8418 63.3463 41.8236 72.7026 40.0605 97.1524C38.2974 121.602 37.0463 123.243 37.0463 123.243Z"
      fill="#455A64"
    />
    <path
      d="M45.4368 125.134C45.4368 125.134 63.3703 113.188 67.3562 97.9434L61.9796 96.4771C61.9796 96.4771 68.8633 94.2253 68.9622 91.9502C69.0611 89.6751 71.1442 81.4767 71.1442 81.4767L65.9073 81.3662C65.9073 81.3662 70.0968 80.5283 71.3537 77.7353C72.6106 74.9424 74.7461 61.0068 74.7461 61.0068C74.7461 61.0068 67.0478 65.6617 63.446 71.6258C59.8441 77.5899 52.9721 83.7809 49.0328 103.826C45.0935 123.871 45.4368 125.134 45.4368 125.134Z"
      fill="#455A64"
    />
    <path
      d="M28.2774 56.7373C28.3023 56.8035 28.3217 56.8716 28.3356 56.941C28.3705 57.0981 28.4229 57.2959 28.481 57.5228C28.6032 58.1047 28.7778 58.8669 29.0047 59.8503C29.2317 60.8336 29.4993 62.1253 29.7903 63.58C30.0812 65.0346 30.4303 66.6929 30.762 68.5316C31.4719 72.209 32.2167 76.5962 32.9906 81.4839C33.7645 86.3715 34.4802 91.7595 35.1436 97.4268C35.8069 103.094 36.3073 108.517 36.6855 113.445C37.0638 118.374 37.3256 122.825 37.4711 126.566C37.5642 128.434 37.6107 130.122 37.6573 131.611C37.7038 133.101 37.7329 134.375 37.7388 135.417C37.7446 136.458 37.7388 137.255 37.7678 137.825V138.454C37.7727 138.526 37.7727 138.597 37.7678 138.669C37.7619 138.598 37.7619 138.526 37.7678 138.454C37.7678 138.297 37.7678 138.093 37.7678 137.825L37.5875 135.463C37.5584 134.416 37.5176 133.136 37.4478 131.658C37.378 130.18 37.3314 128.481 37.2209 126.613C37.0463 122.883 36.7496 118.438 36.3713 113.509C35.9931 108.581 35.4578 103.17 34.8061 97.5025C34.1544 91.8352 33.4096 86.4471 32.6764 81.5653C31.9432 76.6835 31.1984 72.2905 30.5176 68.6131C30.1976 66.7744 29.8659 65.1219 29.5866 63.6556C29.3073 62.1893 29.0687 60.9383 28.8534 59.9143C28.6381 58.8902 28.4985 58.1105 28.3879 57.5519L28.2716 56.941C28.2675 56.873 28.2695 56.8049 28.2774 56.7373Z"
      fill="#263238"
    />
    <path
      d="M34.7538 135.398C34.7538 135.398 19.6074 118.21 14.8942 107.823L18.4437 106.305C15.8718 106.73 13.2067 105.624 11.2342 103.942C9.2616 102.261 7.77781 100.056 6.34056 97.8794L10.9956 97.0881C6.69859 95.8598 3.03824 93.0266 0.771973 89.1748C6.29401 90.0825 11.2865 93.114 15.4004 96.9019C18.6704 100.039 21.6375 103.478 24.2625 107.172C25.5855 108.945 26.7388 110.838 27.7072 112.827C30.2849 118.093 34.7596 129.061 34.7538 135.398Z"
      fill="#455A64"
    />
    <path
      d="M67.595 71.0322L67.5251 71.2126C67.4611 71.3348 67.3855 71.5035 67.2866 71.7072C67.0713 72.161 66.7629 72.8011 66.3672 73.6157C65.5526 75.2682 64.4063 77.6887 63.0097 80.6446C61.6132 83.6004 60.0072 87.1905 58.2791 91.1763C56.5509 95.162 54.747 99.59 52.9316 104.262C51.1161 108.935 49.5043 113.444 48.0845 117.552C46.6647 121.66 45.4661 125.384 44.4943 128.526C43.5226 131.668 42.7487 134.217 42.2483 135.985C41.9922 136.852 41.7944 137.533 41.6664 138.016C41.6024 138.231 41.5442 138.406 41.5035 138.54C41.4891 138.602 41.4677 138.663 41.4395 138.72C41.4429 138.657 41.4546 138.594 41.4744 138.534L41.6082 137.999C41.742 137.51 41.9224 136.835 42.1552 135.95C42.6265 134.205 43.3597 131.616 44.3081 128.468C45.2566 125.32 46.4436 121.584 47.8343 117.459C49.225 113.334 50.8601 108.842 52.6639 104.158C54.4677 99.4736 56.3123 95.0573 58.0405 91.0657C59.7687 87.0741 61.4154 83.5015 62.8352 80.5515C64.255 77.6014 65.4362 75.1984 66.2799 73.5691L67.2516 71.6897L67.5135 71.201C67.5334 71.1415 67.5608 71.0848 67.595 71.0322Z"
      fill="#263238"
    />
    <path
      d="M36.0399 142.759C36.0016 142.658 35.9762 142.552 35.9643 142.445C35.9293 142.2 35.877 141.904 35.8188 141.531C35.7606 141.159 35.685 140.653 35.5802 140.088C35.4755 139.524 35.3824 138.89 35.2311 138.191C34.9576 136.783 34.5678 135.119 34.0674 133.292C33.567 131.465 32.9037 129.487 32.1589 127.439C31.4141 125.391 30.5413 123.494 29.7209 121.789C28.9005 120.084 28.08 118.583 27.3527 117.349C27.0094 116.721 26.6545 116.185 26.3635 115.691C26.0726 115.196 25.7817 114.789 25.5897 114.452C25.3977 114.114 25.2289 113.87 25.0951 113.666C25.0338 113.578 24.9812 113.485 24.938 113.387C24.938 113.387 25.0194 113.462 25.1475 113.637L25.6886 114.388C25.9213 114.713 26.2006 115.115 26.5032 115.598C26.8058 116.081 27.1782 116.616 27.5389 117.239C28.2895 118.466 29.1216 119.962 29.9711 121.661C30.8206 123.36 31.6643 125.28 32.444 127.334C33.2237 129.388 33.8346 131.407 34.335 133.222C34.8354 135.038 35.1904 136.714 35.4348 138.151C35.5744 138.855 35.6559 139.501 35.7431 140.065C35.8304 140.629 35.8886 141.118 35.9293 141.514C35.9701 141.91 35.9992 142.195 36.0224 142.439C36.0386 142.545 36.0444 142.652 36.0399 142.759Z"
      fill="#263238"
    />
    <path d="M56.0213 138.721H22.8716V145.482H56.0213V138.721Z" fill="#9FBC2A" />
    <path d="M26.0317 145.482L29.616 168.227H49.6495L52.9894 145.482H26.0317Z" fill="#9FBC2A" />
    <path
      d="M53.9898 145.482C53.9898 145.563 47.7173 145.633 39.9844 145.633C32.2515 145.633 25.979 145.563 25.979 145.482C25.979 145.4 32.2456 145.354 39.9844 145.354C47.7231 145.354 53.9898 145.394 53.9898 145.482Z"
      fill="#263238"
    />
    <path
      d="M160.51 65.7026C158.782 60.0752 157.351 54.3613 156.221 48.5842C155.709 42.7122 154.807 36.8808 153.521 31.1284C153.48 27.0299 152.609 22.9821 150.961 19.2293C149.215 15.424 147.656 11.2346 144.008 9.16896C141.348 7.66776 137.904 6.25966 134.849 6.12583C128.646 5.86981 123.077 6.93462 118.189 10.7807C111.608 15.9476 110.904 25.1643 109.071 33.0544C108.246 36.5543 108.035 40.1711 108.449 43.7431C108.542 44.5228 108.658 45.2385 108.804 45.9018L105.086 46.5419L102.438 83.4959L162.616 87.2954C162.616 87.2954 168.458 88.2363 172.17 83.273C172.147 83.2905 164.624 78.7654 160.51 65.7026Z"
      fill="#263238"
    />
    <path
      d="M110.544 130.708C110.544 130.708 100.204 141.455 97.9346 156.572L132.615 178.52L167.527 155.536C167.527 155.536 164.758 141.513 156.053 130.737L110.544 130.708Z"
      fill="#263238"
    />
    <path
      d="M121.96 188.552C122.472 188.517 151.252 188.552 151.252 188.552L169.232 189.768C175.505 189.96 177.314 186.858 177.169 184.804C176.977 182.192 174.329 179.23 174.329 179.23C174.329 179.23 159.567 176.064 159.2 176.32C158.834 176.576 123.333 179.271 123.333 179.271L121.96 188.552Z"
      fill="#AE7461"
    />
    <path
      d="M161.639 149.537C161.9 150.09 190.587 157.986 190.587 157.986L132.3 178.683C132.3 178.683 123.939 168.466 124.2 168.466C124.462 168.466 161.639 149.537 161.639 149.537Z"
      fill="#263238"
    />
    <path
      d="M136.135 170.415V188.779C136.135 188.779 173.259 189.646 185.548 186.917C197.837 184.188 204.913 170.834 200.502 163.927C195.335 155.816 182.668 156.66 178.501 157.114C174.335 157.568 136.135 170.415 136.135 170.415Z"
      fill="#263238"
    />
    <path
      d="M107.489 151.353C107.489 151.353 71.2492 153.034 65.8843 156.304L125.37 176.269C125.37 176.269 133.731 166.051 133.47 166.051C133.208 166.051 107.489 151.353 107.489 151.353Z"
      fill="#263238"
    />
    <path
      d="M130.485 177.397C130.485 177.397 90.6607 160.854 86.1221 159.056C75.8461 154.983 60.2226 151.544 59.5651 165.998C58.9075 180.451 77.7895 189.098 90.9866 190H127.284L130.485 177.397Z"
      fill="#263238"
    />
    <path
      d="M136.135 167.709L125.079 168.728C125.079 168.728 123.112 166.039 121.419 165.388C119.726 164.736 116.595 165.388 114.535 164.556C112.476 163.723 107.227 159.528 105.225 158.155C103.467 156.974 101.44 156.256 99.3309 156.066C99.3309 156.066 94.6759 155.135 91.848 157.812C90.5504 159.028 90.4224 161.797 90.4224 161.797C90.4224 161.797 123.118 179.225 123.479 179.039C123.84 178.852 136.129 181.366 136.129 181.366L136.135 167.709Z"
      fill="#AE7461"
    />
    <path
      d="M123.485 179.015C123.317 178.963 123.155 178.893 123.002 178.806C122.653 178.643 122.205 178.422 121.646 178.148L116.689 175.663L109.357 171.928C106.605 170.502 103.538 169.018 100.419 167.273C97.3004 165.527 94.3037 163.898 91.5281 162.536C89.0892 161.309 86.5765 160.234 84.0044 159.318C82.2703 158.716 80.4997 158.225 78.7035 157.846C78.0692 157.707 77.5688 157.637 77.2313 157.567C77.0581 157.548 76.8879 157.507 76.7251 157.445C76.8986 157.441 77.0721 157.455 77.243 157.486C77.5863 157.532 78.0867 157.579 78.7326 157.695C80.5478 158.018 82.337 158.473 84.0859 159.057C86.6928 159.943 89.2374 161.002 91.7027 162.228C94.4957 163.584 97.4808 165.23 100.617 166.947C103.753 168.663 106.797 170.182 109.537 171.602L116.828 175.413L121.734 178.014L123.054 178.736C123.207 178.815 123.351 178.908 123.485 179.015Z"
      fill="#455A64"
    />
    <path
      d="M136.135 181.33C136.135 181.44 133.301 181.01 129.851 180.364C126.4 179.718 123.613 179.118 123.636 179.014C123.66 178.909 126.464 179.34 129.92 179.98C133.377 180.62 136.152 181.225 136.135 181.33Z"
      fill="#6F4439"
    />
    <path
      d="M136.135 184.805C136.112 184.692 136.103 184.576 136.106 184.461C136.106 184.205 136.106 183.879 136.106 183.466C136.106 182.553 136.106 181.29 136.053 179.701C136.053 176.437 136.013 171.858 135.989 166.44V166.301L136.135 166.272L149.25 163.63L170.832 159.382L177.349 158.154L179.13 157.834C179.336 157.785 179.547 157.758 179.758 157.753C179.558 157.82 179.354 157.875 179.147 157.916L177.402 158.306L170.838 159.65L149.309 164.014L136.187 166.615L136.327 166.44C136.298 171.858 136.274 176.437 136.257 179.701C136.257 181.29 136.216 182.553 136.205 183.466C136.205 183.874 136.205 184.205 136.205 184.461C136.194 184.578 136.171 184.693 136.135 184.805Z"
      fill="#455A64"
    />
    <path
      d="M121.436 163.117C121.384 163.17 120.476 162.233 118.87 160.895C116.781 159.161 114.513 157.657 112.103 156.409C109.693 155.192 107.156 154.244 104.539 153.581C102.537 153.075 101.263 152.906 101.274 152.836C101.574 152.819 101.875 152.839 102.171 152.894C102.986 152.991 103.796 153.129 104.597 153.307C107.252 153.899 109.823 154.821 112.248 156.054C114.699 157.307 116.984 158.861 119.05 160.679C119.68 161.229 120.28 161.812 120.848 162.425C121.067 162.635 121.264 162.867 121.436 163.117Z"
      fill="#455A64"
    />
    <path
      d="M171 152.481C170.682 152.52 170.361 152.535 170.04 152.528C169.166 152.593 168.298 152.714 167.439 152.889C164.594 153.464 161.804 154.285 159.101 155.344C156.376 156.371 153.756 157.661 151.281 159.196C149.39 160.4 148.372 161.361 148.313 161.297C148.524 161.045 148.758 160.813 149.012 160.604C149.292 160.337 149.589 160.088 149.902 159.859C150.263 159.597 150.658 159.277 151.136 158.957C153.59 157.342 156.217 156.005 158.967 154.972C161.689 153.902 164.512 153.11 167.393 152.609C168.266 152.461 169.149 152.381 170.034 152.371C170.36 152.361 170.685 152.398 171 152.481Z"
      fill="#455A64"
    />
    <path
      d="M118.754 65.9527C118.754 65.9527 121.914 78.4161 133.958 77.1011C142.686 76.1469 141.523 65.6094 141.523 65.6094L118.754 65.9527Z"
      fill="#AE7461"
    />
    <path
      d="M102.595 71.666C102.467 72.3293 104.201 99.6652 104.201 99.6652L106.785 112.466L110.858 124.528L105.039 137.62C123.421 144.329 144.479 145.859 160.719 137.701L155.558 123.737C155.558 123.737 158.17 103.953 158.683 96.1275C159.195 88.3014 152.078 68.5473 152.078 68.5473L141.552 65.9521H118.94L102.595 71.666Z"
      fill="#CDE7DD"
    />
    <path
      d="M75.7186 130.342C75.7186 130.342 66.6238 127.264 69.4459 119.427C72.2681 111.589 82.6197 97.025 85.692 91.7068C88.7644 86.3886 93.0063 75.4147 100.239 72.5462C107.472 69.6776 109.078 70.1722 109.078 70.1722C109.078 70.1722 113.512 88.9779 104.714 99.2652L102.863 98.6834L87.1525 117.303L111.807 123.476L110.754 135.114L75.7186 130.342Z"
      fill="#AE7461"
    />
    <path
      d="M151.02 85.1774C151.02 85.1774 147.284 78.6138 146.778 77.0311C146.272 75.4484 145.253 71.4043 144.45 71.0494C143.647 70.6944 142.984 72.1084 142.705 73.6329C142.425 75.1574 144.572 78.4392 143.868 78.9688C143.164 79.4983 130.974 76.0593 128.943 75.5066C126.912 74.9538 121.192 73.8657 121.024 76.6238C121.024 76.6238 118.254 76.5074 118.085 77.677C117.967 78.2176 117.967 78.7773 118.085 79.3179C118.085 79.3179 117.579 81.4301 119.33 82.1342C120.294 82.5363 121.299 82.8309 122.327 83.0128C122.327 83.0128 120.913 83.7169 122.158 85.1832C123.404 86.6496 131.09 89.6404 131.09 89.6404C131.09 89.6404 134.582 93.0444 137.648 93.8067C140.715 94.569 145.899 94.569 145.899 94.569L151.02 85.1774Z"
      fill="#AE7461"
    />
    <path
      d="M152.078 68.5479L159.782 70.9917C159.782 70.9917 164.903 72.2078 168.138 75.7687C171.373 79.3297 175.004 87.2663 178.03 89.3028C181.056 91.3394 190.779 100.189 191.565 103.954C192.35 107.719 192.147 116.976 186.956 117.488C186.956 117.488 159.26 90.6529 158.952 90.7634C158.643 90.874 156.338 93.0151 156.338 93.0151C156.338 93.0151 153.329 85.5847 151.956 81.6164C150.583 77.6482 152.078 68.5479 152.078 68.5479Z"
      fill="#AE7461"
    />
    <path
      d="M151.02 85.1777C151.02 85.1777 165.497 93.5158 171.479 94.5632C177.46 95.6105 183.151 95.8142 185.898 98.7642C188.644 101.714 186.98 117.488 186.98 117.488C186.98 117.488 183.838 118.809 177.367 115.859C170.897 112.909 145.87 94.5632 145.87 94.5632L151.02 85.1777Z"
      fill="#AE7461"
    />
    <path
      d="M111.41 124.11C111.41 124.11 114.5 121.172 117.537 120.124C120.575 119.077 122.355 117.587 123.856 116.907C125.683 116.08 127.731 115.627 127.219 118.012C126.928 119.368 125.346 120.34 123.693 121.474C122.041 122.609 120.313 122.952 121.57 123.313C122.826 123.674 128.965 124.942 132.043 124.773C132.043 124.773 139.246 124.738 139.328 126.589C139.409 128.439 131.31 127.846 130.047 128.043C129.321 128.156 128.603 128.321 127.9 128.538C127.9 128.538 136.296 129.201 138.688 129.702C141.079 130.202 140.893 131.686 139.985 132.116C139.524 132.338 139.05 132.532 138.566 132.698C138.566 132.698 139.246 133.984 137.92 134.444C136.593 134.904 125.462 134.531 125.462 134.531C125.462 134.531 133.189 135.259 133.736 135.875C133.847 136.033 133.917 136.217 133.94 136.409C133.962 136.601 133.937 136.796 133.865 136.975C133.793 137.155 133.678 137.314 133.53 137.438C133.381 137.562 133.204 137.647 133.015 137.685C132.165 137.842 124.392 137.248 123.967 137.359C121.193 137.805 118.354 137.605 115.669 136.777C111.387 135.509 110.305 134.205 110.305 134.205L111.41 124.11Z"
      fill="#AE7461"
    />
    <path
      d="M184.943 97.9211C184.822 97.8884 184.705 97.8415 184.594 97.7814L183.634 97.3159C182.461 96.7428 181.254 96.2414 180.02 95.8147C178.197 95.1783 176.297 94.7869 174.37 94.6509C172.212 94.4531 169.715 94.4822 167.312 93.5628C165.084 92.7238 162.908 91.7523 160.795 90.6534C158.834 89.6642 157.094 88.7216 155.645 87.9186C154.197 87.1156 153.033 86.4406 152.236 85.9693L151.322 85.4165C151.213 85.3566 151.112 85.2844 151.02 85.2012C151.136 85.247 151.249 85.3015 151.357 85.3641L152.294 85.8645L155.75 87.7324C157.211 88.5121 158.956 89.4256 160.917 90.4032C163.027 91.4955 165.197 92.4669 167.417 93.3126C169.744 94.2145 172.217 94.197 174.399 94.4065C176.349 94.5636 178.267 94.9864 180.102 95.6634C181.334 96.1143 182.533 96.6489 183.692 97.2636L184.629 97.7756C184.737 97.8169 184.842 97.8655 184.943 97.9211Z"
      fill="#6F4439"
    />
    <path
      d="M134.703 78.9112C134.025 78.7897 133.355 78.6284 132.696 78.4282C131.462 78.114 129.752 77.7242 127.837 77.41C125.923 77.0958 124.172 76.9154 122.903 76.8281C122.213 76.8053 121.526 76.7431 120.843 76.6419C121.035 76.6023 121.23 76.5886 121.425 76.6012C121.78 76.6012 122.298 76.6012 122.938 76.6012C126.272 76.7331 129.577 77.2784 132.777 78.2246C133.359 78.4049 133.883 78.5679 134.22 78.69C134.39 78.7436 134.552 78.8179 134.703 78.9112Z"
      fill="#6F4439"
    />
    <path
      d="M135.971 82.1983C135.971 82.2798 131.898 81.6165 126.952 80.6506C122.007 79.6847 117.974 78.905 117.992 78.8061C118.009 78.7072 122.065 79.417 127.011 80.3538C131.956 81.2906 135.983 82.1169 135.971 82.1983Z"
      fill="#6F4439"
    />
    <path
      d="M133.103 85.0445C132.556 84.9791 132.015 84.8702 131.485 84.7186C130.49 84.4917 129.117 84.1833 127.598 83.8749C126.08 83.5665 124.689 83.2931 123.688 83.1418C123.142 83.072 122.601 82.9612 122.071 82.8101C122.624 82.7913 123.178 82.8322 123.723 82.9323C124.736 83.0545 126.132 83.2698 127.657 83.5782C129.181 83.8865 130.566 84.2299 131.538 84.515C132.076 84.6364 132.601 84.8138 133.103 85.0445Z"
      fill="#6F4439"
    />
    <path
      d="M127.354 131.785C129.211 131.804 131.066 131.925 132.911 132.146C134.766 132.265 136.613 132.485 138.444 132.803C136.587 132.784 134.732 132.664 132.887 132.443C131.033 132.323 129.185 132.103 127.354 131.785Z"
      fill="#6F4439"
    />
    <path
      d="M87.9958 117.151C86.4292 117.356 84.8752 117.647 83.3409 118.024C81.8293 118.5 80.3453 119.06 78.8955 119.7C79.2564 119.399 79.6579 119.15 80.0883 118.961C81.1032 118.45 82.1652 118.039 83.2595 117.733C84.3517 117.433 85.47 117.238 86.5993 117.151C87.0625 117.085 87.5327 117.085 87.9958 117.151Z"
      fill="#6F4439"
    />
    <path
      d="M97.6606 89.5293C98.0217 89.8997 98.3398 90.3096 98.6091 90.7512C99.156 91.5367 99.8717 92.6481 100.593 93.9165C101.315 95.185 101.902 96.372 102.298 97.2448C102.541 97.7026 102.736 98.1842 102.88 98.682C102.584 98.2601 102.331 97.8098 102.124 97.3379C101.681 96.4942 101.065 95.3304 100.378 94.0678C99.6913 92.8052 98.9756 91.6822 98.4752 90.8676C98.1661 90.4455 97.8936 89.9977 97.6606 89.5293Z"
      fill="#6F4439"
    />
    <path
      d="M125.219 3.78666C124.53 2.53035 123.422 1.55611 122.088 1.03446C120.614 0.352971 119.01 0 117.387 0C115.763 0 114.159 0.352971 112.685 1.03446C111.223 1.7406 109.955 2.79465 108.995 4.10426C108.034 5.41388 107.409 6.9391 107.175 8.54629C106.977 10.16 107.224 11.7971 107.888 13.2809C108.553 14.7647 109.61 16.0389 110.945 16.9658C109.216 17.0209 107.526 17.4983 106.023 18.3565C105.79 18.4607 105.58 18.61 105.406 18.7958C105.231 18.9815 105.095 19.2 105.005 19.4387C104.813 20.2242 105.639 20.9574 106.442 20.9923C107.044 20.9889 107.634 20.8198 108.147 20.5035C107.845 20.6527 107.578 20.8625 107.362 21.1198C107.146 21.3772 106.985 21.6765 106.89 21.9989C106.646 23.1626 107.85 24.1867 109.019 24.2333C110.189 24.2798 111.277 23.6514 112.284 23.0695L124.468 7.66186C125.614 6.8007 125.824 5.06675 125.219 3.78666Z"
      fill="#263238"
    />
    <path
      d="M118.533 17.0764L138.573 11.1647C145.032 10.222 149.629 18.0364 150.321 24.5475C151.06 31.7567 151.677 40.5894 151.037 46.0181C149.739 56.9338 141.482 58.6387 141.482 58.6387C141.482 58.6387 141.482 58.8016 141.529 62.0251V68.6642C141.616 70.7763 141.244 71.4862 139.952 73.1619C136.996 76.9906 131.608 77.5026 125.254 75.1519C121.57 73.7904 119.01 71.0498 118.731 67.3201L118.766 23.1452C118.673 21.1436 116.572 17.344 118.533 17.0764Z"
      fill="#AE7461"
    />
    <path
      d="M143.362 34.416C143.543 34.2356 144.578 35.0386 146.074 35.0677C147.569 35.0968 148.686 34.3695 148.844 34.5557C148.925 34.6371 148.739 34.963 148.262 35.3005C147.604 35.7362 146.828 35.9577 146.039 35.9347C145.26 35.9218 144.506 35.6609 143.886 35.1899C143.426 34.8 143.275 34.4917 143.362 34.416Z"
      fill="#263238"
    />
    <path
      d="M130.072 34.7998C130.235 34.6136 131.323 35.3525 132.819 35.2885C134.314 35.2245 135.385 34.4332 135.554 34.6136C135.635 34.6892 135.472 35.0209 135.007 35.3933C134.377 35.866 133.617 36.1322 132.83 36.1555C132.054 36.1997 131.284 35.996 130.631 35.5737C130.16 35.2187 129.991 34.8987 130.072 34.7998Z"
      fill="#263238"
    />
    <path
      d="M140.103 40.945C140.103 40.8636 141.017 40.6657 142.512 40.4097C142.896 40.3573 143.251 40.2643 143.31 40.0024C143.324 39.6062 143.218 39.2148 143.007 38.8794L141.744 36.0167C139.999 31.9436 138.742 28.5688 138.911 28.5048C139.079 28.4408 140.61 31.6876 142.344 35.7665C142.763 36.7789 143.164 37.7332 143.542 38.6758C143.802 39.1283 143.893 39.6581 143.798 40.1712C143.751 40.3056 143.675 40.4277 143.574 40.5285C143.473 40.6292 143.351 40.7059 143.216 40.753C143.007 40.8205 142.79 40.8597 142.571 40.8694C141.052 40.9858 140.109 41.0323 140.103 40.945Z"
      fill="#263238"
    />
    <path
      d="M141.523 58.6155C136.42 58.8933 131.339 57.7491 126.848 55.3105C126.848 55.3105 130.735 62.7584 141.552 61.3212L141.523 58.6155Z"
      fill="#6F4439"
    />
    <path
      d="M134.837 25.7639C134.727 26.1828 133.22 26.1363 131.492 26.5145C129.764 26.8927 128.396 27.5095 128.129 27.172C128.006 27.0091 128.216 26.5901 128.751 26.1537C129.464 25.5969 130.296 25.2109 131.181 25.0254C132.067 24.8398 132.983 24.8595 133.86 25.0831C134.529 25.2751 134.884 25.5661 134.837 25.7639Z"
      fill="#263238"
    />
    <path
      d="M148.872 27.0384C148.529 27.3293 147.441 26.8173 146.068 26.602C144.695 26.3867 143.502 26.4624 143.287 26.0725C143.193 25.8805 143.426 25.5721 143.979 25.3277C144.73 25.0324 145.549 24.9557 146.341 25.1066C147.135 25.2273 147.876 25.5748 148.477 26.1074C148.913 26.5205 149.024 26.8929 148.872 27.0384Z"
      fill="#263238"
    />
    <path d="M138.643 9.99512L138.695 10.2279H138.655L138.643 9.99512Z" fill="#263238" />
    <path
      d="M118.027 66.005C123.444 65.6908 124.189 58.1557 123.846 55.3104C123.464 52.1457 122.854 49.0129 122.019 45.9366C120.739 41.2818 122.496 36.2719 123.142 31.4309C125.911 30.9796 128.419 29.5308 130.194 27.3578C132.37 24.8209 133.685 21.6789 134.971 18.5892C134.886 21.6384 133.853 24.5853 132.015 27.0203C132.625 27.1156 133.249 27.0662 133.836 26.8762C134.423 26.6862 134.958 26.3609 135.396 25.9264C136.267 25.0492 136.943 23.9984 137.38 22.8426C138.556 20.0955 139.209 17.1534 139.306 14.167C139.708 18.4421 139.225 22.7541 137.887 26.8341C138.669 26.691 139.474 26.7228 140.243 26.9272C141.029 27.0378 141.931 27.0145 142.489 26.4501C142.827 26.0356 143.04 25.5328 143.1 25.0013C143.853 21.3641 143.975 17.6245 143.461 13.9459C144.493 15.8965 145.327 17.9451 145.951 20.0613C146.575 22.1906 146.591 24.4517 145.998 26.5898C146.177 26.7671 146.418 26.8666 146.67 26.8666C146.922 26.8666 147.163 26.7671 147.342 26.5898C147.679 26.2114 147.883 25.7333 147.924 25.2282C148.209 23.4012 147.889 21.545 147.563 19.7296C148.651 21.6556 149.012 23.8841 149.611 26.0079C150.211 28.1317 151.165 30.3079 153.01 31.5239C153.323 28.7501 153.232 25.9456 152.742 23.1975C152.375 20.9224 151.293 18.6648 150.298 16.5876C149.241 14.2819 147.748 12.2031 145.899 10.4664C142.705 7.66764 141.372 8.8721 139.33 9.94272L139.085 10.0416C139.088 9.99124 139.088 9.94076 139.085 9.89036L138.998 9.45978C133.653 8.26247 128.085 8.5217 122.874 10.2104C119.028 11.4672 118.673 19.7471 117.736 15.8137C117.154 13.4339 114.076 15.6042 114.041 15.6741C109.502 23.8608 104.69 36.8422 104.469 57.3586L118.027 66.005Z"
      fill="#263238"
    />
    <path
      d="M114.087 15.3604C114.733 15.663 116.566 12.8526 119.848 10.6415C123.13 8.43044 126.353 7.6391 126.295 6.92342C126.295 6.59757 125.346 6.34156 123.851 6.49284C121.906 6.75509 120.05 7.47103 118.433 8.58286C116.816 9.69469 115.483 11.1713 114.541 12.8933C113.872 14.2607 113.814 15.2208 114.087 15.3604Z"
      fill="#9FBC2A"
    />
    <path
      opacity="0.4"
      d="M140.63 46.1275C140.217 45.9453 139.763 45.8724 139.313 45.9158C138.863 45.9592 138.432 46.1174 138.061 46.3752C137.788 46.5546 137.555 46.7878 137.376 47.0606C137.197 47.3334 137.075 47.64 137.018 47.9615C136.961 48.2742 137.001 48.597 137.134 48.8858C137.267 49.1746 137.486 49.4152 137.761 49.5747C138.094 49.6999 138.457 49.7212 138.802 49.6359C139.148 49.5506 139.459 49.3628 139.695 49.097C140.191 48.5479 140.571 47.9046 140.813 47.2055C140.907 47.0232 140.963 46.8235 140.977 46.6187C140.982 46.5207 140.959 46.4232 140.91 46.3382C140.86 46.2531 140.788 46.1842 140.7 46.1396"
      fill="#6F4439"
    />
    <path
      d="M136.157 45.6518C136.361 45.5339 137.137 46.9502 138.885 47.3125C140.634 47.6748 142.055 46.7078 142.176 46.8996C142.297 47.0914 141.988 47.3538 141.36 47.7166C140.536 48.1667 139.58 48.3146 138.658 48.1347C137.77 47.9565 136.985 47.4416 136.467 46.6978C136.074 46.1078 136.062 45.7062 136.157 45.6518Z"
      fill="#263238"
    />
  </svg>
);

export default ClientMeditating;
