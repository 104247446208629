import { FunctionComponent, MouseEventHandler } from 'react';
import Svg, { Path, Circle, G } from 'svgs';
import BaseButton from '../../../components/BaseButton';
import withHover from '../../../hoc/withHover';
import { WithHoverProps } from '../../../hoc';
import { EmotionStyle, useEmotionTheme } from '../../../core/styled';

import XMarkLarge from '../../icons/XMarkLarge';
import { IconColorType } from '../../icons';

export interface CloseButtonProps extends WithHoverProps {
  ariaLabel?: string;
  width?: number;
  height?: number;
  backgroundColor?: string;
  color?: string;
  hoverColor?: string;
  hasHoverStyles?: boolean;
  simpleStyle?: boolean;
  onPress?: MouseEventHandler<HTMLButtonElement>;
  style?: EmotionStyle;
  dataQa?: string;
  roundedFocusStyle?: boolean;
  primaryColor?: string;
  xColorType?: IconColorType;
}

const CloseButtonWithCircle = ({
  width,
  height,
  color,
  backgroundColor,
  hoverColor,
  isHovering,
  hasHoverStyles,
  ...props
}) => {
  const { colors } = useEmotionTheme();
  return (
    <Svg width={width} height={height} viewBox="0 0 35 35" {...props}>
      <G
        transform="translate(-325 -16) translate(325 16)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <Circle
          fill={
            isHovering && hasHoverStyles
              ? hoverColor || colors.periwinkleGrey
              : backgroundColor || colors.permaLinkWaterGrey
          }
          cx={17.5}
          cy={17.5}
          r={17.5}
        />
        <Path
          d="M17.5 16.259l5.013-5.004a.87.87 0 011.233.002.875.875 0 01-.002 1.235L18.726 17.5l5.018 5.008c.34.34.342.893.002 1.235a.87.87 0 01-1.233.002L17.5 18.741l-5.013 5.004a.87.87 0 01-1.233-.002.875.875 0 01.002-1.235l5.018-5.008-5.018-5.008a.875.875 0 01-.002-1.235.87.87 0 011.233-.002l5.013 5.004z"
          fill={isHovering && hasHoverStyles ? colors.cometGrey : color || colors.slateGrey}
          fillRule="nonzero"
        />
      </G>
    </Svg>
  );
};

const CloseButton: FunctionComponent<CloseButtonProps> = ({
  ariaLabel = 'close',
  isHovering,
  onPress,
  style,
  width = 35,
  height = 35,
  backgroundColor,
  color,
  hasHoverStyles = true,
  hoverColor,
  dataQa,
  roundedFocusStyle,
  primaryColor,
  simpleStyle = true,
  xColorType = 'default',
  ...props
}) => (
  <BaseButton
    onPress={onPress}
    aria-label={ariaLabel}
    style={{ width, height, ...style }}
    data-qa={dataQa}
    roundedFocusStyle={roundedFocusStyle}
    primaryColor={primaryColor}
  >
    {simpleStyle ? (
      <XMarkLarge size="major" colorType={xColorType} />
    ) : (
      <CloseButtonWithCircle
        width={width}
        height={height}
        color={color}
        backgroundColor={backgroundColor}
        hoverColor={hoverColor}
        isHovering={isHovering}
        hasHoverStyles={hasHoverStyles}
        {...props}
      />
    )}
  </BaseButton>
);

export default withHover(CloseButton);
