import { FunctionComponent } from 'react';
import styled, { EmotionTheme } from '../../core/styled';
import Text, { TextProps } from '../Text';
import type { VariantFunction } from './types';

type Variant = 'default' | 'extraTinyBoldGreen' | 'extraTinyRed' | 'extraTinyError';

interface ExtraTinyProps extends TextProps {
  variant?: Variant;
}

const extraTinyDefaultStyle = (colors: EmotionTheme['colors']) => {
  return {
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: 0.2,
    fontWeight: 400,
    color: colors.grey,
  };
};

const variants: Record<Variant, VariantFunction> = {
  default: ({ theme: { colors } }) => {
    return {
      ...extraTinyDefaultStyle(colors),
    };
  },
  extraTinyBoldGreen: ({ theme: { colors } }) => {
    return {
      ...extraTinyDefaultStyle(colors),
      color: colors.permaTalkspaceDarkGreen,
      fontWeight: 700,
    };
  },
  extraTinyRed: ({ theme: { colors } }) => {
    return {
      ...extraTinyDefaultStyle(colors),
      color: colors.permaDebianRed,
    };
  },
  extraTinyError: ({ theme: { colors } }) => {
    return {
      ...extraTinyDefaultStyle(colors),
      color: colors.permaFuchsia,
      fontSize: 11,
    };
  },
};

const ExtraTinyDefault = styled(Text)(variants.default);
const ExtraTinyBoldGreen = styled(Text)(variants.extraTinyBoldGreen);
const ExtraTinyRed = styled(Text)(variants.extraTinyRed);
const ExtraTinyError = styled(Text)(variants.extraTinyError);
const selectStyle = (variant: Variant | undefined, props: Omit<ExtraTinyProps, 'variant'>) => {
  switch (variant) {
    case 'extraTinyBoldGreen':
      return <ExtraTinyBoldGreen {...props} />;
    case 'extraTinyRed':
      return <ExtraTinyRed {...props} />;
    case 'extraTinyError':
      return <ExtraTinyError {...props} />;
    default:
      return <ExtraTinyDefault {...props} />;
  }
};

const ExtraTiny: FunctionComponent<ExtraTinyProps> = ({ variant, ...props }) =>
  selectStyle(variant, props);

export default ExtraTiny;
