import { useEmotionTheme } from '@talkspace/react-toolkit';
import { VoidFunctionComponent } from 'react';
import Svg, { Rect, Path } from 'svgs';

interface ChatModalityIconProps {
  backgroundColor?: string;
  foregroundColor?: string;
  width?: number;
  height?: number;
}

const ChatModalityIcon: VoidFunctionComponent<ChatModalityIconProps> = ({
  backgroundColor,
  foregroundColor,
  width = 28,
  height = 28,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'chat icon';
  return (
    <Svg
      title={titleText}
      aria-label={titleText}
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill="none"
      {...otherProps}
    >
      <Rect width={28} height={28} rx={8} fill={backgroundColor || colors.permaMagicMint} />
      <Path
        clipRule="evenodd"
        d="M10.32 21.177a.993.993 0 00.349 1.083c.335.263.805.315 1.196.13 1.096-.518 2.553-1.42 3.438-2.804h.726c3.85 0 6.971-2.93 6.971-6.543C23 9.43 19.879 6.5 16.029 6.5H11.97c-3.566 0-6.557 2.526-6.932 5.855-.375 3.328 1.986 6.385 5.473 7.086a4.51 4.51 0 01-.192 1.736z"
        stroke={foregroundColor || colors.permaSeaGreen}
        strokeWidth={1.5}
      />
    </Svg>
  );
};

export default ChatModalityIcon;
