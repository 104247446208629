import { TouchableView, View, useEmotionTheme } from '@talkspace/react-toolkit';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { BarsArray } from '@talkspace/react-toolkit/src/components/GenericProgressBar/GenericProgressBar';
import BonusMilestoneAchievedIcon from 'components/Icons/BonusMilestoneAchievedIcon';
import BonusMilestonePredictedIcon from 'components/Icons/BonusMilestonePredictedIcon';
import { getUserData } from '../../utils/token';
import useQueryMonthlyBonus, { MonthlyBonusData } from './useQueryMonthlyBonus';
import styled from '../../core/styled';

type ArrowLocation = 'left' | 'right' | 'center' | 'bottom-left' | 'bottom-right';

const CURRENT_HOURS_TOUCHABLE_VIEW_WIDTH = 111;
const HALF_TOUCHABLE_VIEW_WIDTH = CURRENT_HOURS_TOUCHABLE_VIEW_WIDTH / 2;

const Arrow = styled(View)<{ arrowLocation: ArrowLocation }>(
  ({ arrowLocation, theme: { colors } }) => {
    let ArrowMargin = 0;
    let borderLeft = '';
    let borderRight = '';
    let borderTop = '';
    let borderBottom = '';

    switch (arrowLocation) {
      case 'center':
        ArrowMargin = CURRENT_HOURS_TOUCHABLE_VIEW_WIDTH / 2 - 7;
        borderLeft = '7px solid transparent';
        borderRight = '7px solid transparent';
        borderTop = `7px solid ${colors.purple600}`;
        break;
      case 'right':
        borderLeft = `7px solid ${colors.purple600}`;
        borderBottom = `7px solid transparent`;
        borderTop = `7px solid transparent`;
        break;
      case 'left':
        borderRight = `7px solid ${colors.purple600}`;
        borderBottom = `7px solid transparent`;
        borderTop = `7px solid transparent`;
        break;
      case 'bottom-right':
        ArrowMargin = CURRENT_HOURS_TOUCHABLE_VIEW_WIDTH - 7;
        borderLeft = '7px solid transparent';
        borderRight = '';
        borderTop = `7px solid ${colors.purple600}`;
        break;
      case 'bottom-left':
        borderLeft = '';
        borderRight = '7px solid transparent';
        borderTop = `7px solid ${colors.purple600}`;
        break;
    }
    return {
      width: 0,
      height: 0,
      borderRight,
      borderBottom,
      borderTop,
      borderLeft,
      marginLeft: ArrowMargin,
      opacity: 0.85,
      zIndex: 2,
    };
  }
);

const CurrentHoursTouchableView = styled(TouchableView)<{
  arrowLocation: ArrowLocation;
}>(({ arrowLocation, theme: { colors } }) => {
  let borderRadius = '4px 4px 4px 4px';
  if (arrowLocation === 'bottom-right') {
    borderRadius = '4px 4px 0 4px';
  } else if (arrowLocation === 'bottom-left') {
    borderRadius = '4px 4px 4px 0';
  }
  return {
    width: CURRENT_HOURS_TOUCHABLE_VIEW_WIDTH,
    background: colors.purple600,
    borderRadius,
    paddingTop: 8,
    paddingBottom: 8,
    color: '#FFFFFF',
    fontSize: 16,
    alignItems: 'center',
    opacity: 0.85,
    zIndex: 2,
  };
});

const CurrentHoursMark = ({
  hoursAchieved,
  onClick,
  arrowLocation,
}: {
  hoursAchieved: number;
  onClick: () => void;
  arrowLocation: ArrowLocation;
}) =>
  arrowLocation === 'right' || arrowLocation === 'left' ? (
    <View
      style={{
        display: 'flex',
        flexDirection: arrowLocation === 'left' ? 'row' : 'row-reverse',
        alignItems: 'center',
      }}
    >
      <Arrow arrowLocation={arrowLocation} />
      <CurrentHoursTouchableView arrowLocation={arrowLocation} onPress={onClick}>
        {`You: ${hoursAchieved} hrs`}
      </CurrentHoursTouchableView>
    </View>
  ) : (
    <>
      <CurrentHoursTouchableView arrowLocation={arrowLocation} onPress={onClick}>
        {`You: ${hoursAchieved} hrs`}
      </CurrentHoursTouchableView>
      <Arrow arrowLocation={arrowLocation} />
    </>
  );

export interface MarkedMilestone {
  milestoneComponent: ReactElement;
  milestoneIndex: number;
  type: 'achieved' | 'predicted';
}

interface MonthlyBonusGraphData {
  monthlyBonusData: MonthlyBonusData | undefined;
  shouldResetHorizontalScrollOffset: boolean;
  setShouldResetHorizontalScrollOffset: (shouldResetHorisontalScrollOffset: boolean) => void;
  setHorizontalScrollBoxOffset: (offset: number) => void;
  setHorizontalScrollBoxOffsetUpdated: (isOffsetUpdated: boolean) => void;
  updatedBars: BarsArray;
  horizontalScrollStartPercentage: number;
  horizontalScrollEndPercentage: number;
  originalBars: BarsArray;
  setUpdatedBars: (bars: BarsArray) => void;
  milestoneHoverIndex: number | null;
  setMilestoneHoverIndex: (milestoneHoverIndex: number) => void;
  updatedMarkedMilestones: Array<MarkedMilestone>;
  setUpdatedMarkedMilestones: (markedMilestones: Array<MarkedMilestone>) => void;
  originalMarkedMilestones: Array<MarkedMilestone>;
  markedPercentages: Array<{
    percentage: number;
    component: JSX.Element;
  }>;
}

interface UseMonthlyBonusGraphDataSetupProps {
  horizontalScrollBoxWidth?: number;
  graphWidth?: number;
  year: number;
  month: number;
}
const useMonthlyBonusGraphDataSetup = ({
  horizontalScrollBoxWidth = 0,
  graphWidth = 1400,
  year,
  month,
}: UseMonthlyBonusGraphDataSetupProps): MonthlyBonusGraphData => {
  const { colors } = useEmotionTheme();

  const therapistID: number = getUserData().id;
  const { data: monthlyBonusData, refetch: refetchMonthlyBonus } = useQueryMonthlyBonus({
    therapistID,
    year,
    month,
    onSuccess: () => {},
  });
  const [shouldResetHorizontalScrollOffset, setShouldResetHorizontalScrollOffset] =
    useState<boolean>(false);

  useEffect(() => {
    refetchMonthlyBonus();
    setShouldResetHorizontalScrollOffset(true);
  }, [year, month, therapistID, refetchMonthlyBonus, setShouldResetHorizontalScrollOffset]);

  const [milestoneHoverIndex, setMilestoneHoverIndex] = useState<number | null>(null);

  const originalBarsArr = useCallback(
    () => [
      { widthPercentage: 0, color: colors.forestGreen },
      {
        widthPercentage: 0,
        color: colors.pastelGray,
        onMilestoneHover: true,
      },
      { widthPercentage: 0, color: colors.permaLavenderBlue },
    ],
    [colors]
  );

  const [originalBars, setOriginalBars] = useState<BarsArray>(originalBarsArr);
  const [originalMarkedMilestones, setOriginalMarkedMilestones] = useState<Array<MarkedMilestone>>(
    []
  );

  const [updatedMarkedMilestones, setUpdatedMarkedMilestones] = useState<Array<MarkedMilestone>>(
    []
  );

  const [markedPercentages, setMarkedPercentages] = useState<
    Array<{
      percentage: number;
      component: JSX.Element;
    }>
  >([]);

  const createOriginalBars = useCallback(() => {
    if (monthlyBonusData) {
      const {
        monthlyBonus: {
          isCurrentDateInFirstSevenDaysOfTheMonth,
          isFetchedForCurrentMonth,
          hoursAchievedPercentage,
          hoursPredictedPercentage,
        },
      } = monthlyBonusData;
      if (!isCurrentDateInFirstSevenDaysOfTheMonth && isFetchedForCurrentMonth) {
        return [
          {
            widthPercentage: hoursAchievedPercentage,
            color: colors.forestGreen,
          },
          {
            widthPercentage: hoursAchievedPercentage,
            color: colors.pastelGray,
            onMilestoneHover: true,
          },
          {
            widthPercentage: hoursPredictedPercentage,
            color: colors.permaLavenderBlue,
          },
        ];
      }
      if (!isFetchedForCurrentMonth) {
        return [
          {
            widthPercentage: hoursAchievedPercentage,
            color: colors.forestGreen,
          },
        ];
      }
      return [
        {
          widthPercentage: hoursAchievedPercentage,
          color: colors.forestGreen,
        },
        {
          widthPercentage: hoursAchievedPercentage,
          color: colors.pastelGray,
          onMilestoneHover: true,
        },
      ];
    }
    return [];
  }, [colors.forestGreen, colors.pastelGray, colors.permaLavenderBlue, monthlyBonusData]);

  useEffect(() => {
    if (monthlyBonusData) {
      const {
        monthlyBonus: { milestoneAchievedIndex, milestonePredictedIndex },
      } = monthlyBonusData;
      setOriginalBars(createOriginalBars());

      const markedMilestoneArr: Array<MarkedMilestone> = [];
      milestoneAchievedIndex &&
        markedMilestoneArr.push({
          milestoneComponent: <BonusMilestoneAchievedIcon />,
          milestoneIndex: milestoneAchievedIndex,
          type: 'achieved',
        });

      milestonePredictedIndex &&
        markedMilestoneArr.push({
          milestoneComponent: <BonusMilestonePredictedIcon />,
          milestoneIndex: milestonePredictedIndex,
          type: 'predicted',
        });
      setOriginalMarkedMilestones(markedMilestoneArr);
    }
  }, [monthlyBonusData, colors, createOriginalBars]);

  const [updatedBars, setUpdatedBars] = useState<BarsArray>(originalBars);

  useEffect(() => {
    setUpdatedBars(originalBars);
  }, [originalBars]);

  useEffect(() => {
    setUpdatedMarkedMilestones(originalMarkedMilestones);
  }, [originalMarkedMilestones]);

  const getOffsetPercentage = useCallback(
    (offset) => {
      if (offset) {
        return (offset * 100) / graphWidth;
      }
      return 0;
    },
    [graphWidth]
  );

  const [horizontalScrollStartPercentage, setHorizontalScrollStartPercentage] = useState<number>(
    getOffsetPercentage(0)
  );
  const [horizontalScrollEndPercentage, setHorizontalScrollEndPercentage] = useState<number>(
    getOffsetPercentage(horizontalScrollBoxWidth)
  );

  const [horizontalScrollBoxOffsetUpdated, setHorizontalScrollBoxOffsetUpdated] =
    useState<boolean>(false);
  const [horizontalScrollBoxOffset, setHorizontalScrollBoxOffset] = useState<number>(0);

  useEffect(() => {
    if (horizontalScrollBoxOffsetUpdated) {
      const startPercentage = getOffsetPercentage(horizontalScrollBoxOffset);
      const endPercentage = getOffsetPercentage(
        horizontalScrollBoxOffset + horizontalScrollBoxWidth
      );
      setHorizontalScrollStartPercentage(startPercentage);
      setHorizontalScrollEndPercentage(endPercentage);
      setHorizontalScrollBoxOffsetUpdated(false);
    }
  }, [
    graphWidth,
    horizontalScrollBoxOffset,
    horizontalScrollBoxOffsetUpdated,
    getOffsetPercentage,
    horizontalScrollBoxWidth,
  ]);

  interface MarkPercentageAndArrowDirection {
    percentage: number;
    arrowLocation: ArrowLocation;
  }
  const getCurrentHoursMarkPercentageAndArrowDirection =
    useCallback((): MarkPercentageAndArrowDirection => {
      if (monthlyBonusData) {
        const {
          monthlyBonus: { hoursAchievedPercentage },
        } = monthlyBonusData;
        if (
          hoursAchievedPercentage <
          horizontalScrollStartPercentage + getOffsetPercentage(HALF_TOUCHABLE_VIEW_WIDTH)
        ) {
          if (hoursAchievedPercentage > horizontalScrollStartPercentage) {
            return { percentage: hoursAchievedPercentage, arrowLocation: 'bottom-left' };
          }
          return { percentage: horizontalScrollStartPercentage, arrowLocation: 'left' };
        }
        if (
          hoursAchievedPercentage + getOffsetPercentage(HALF_TOUCHABLE_VIEW_WIDTH) >
          horizontalScrollEndPercentage
        ) {
          if (hoursAchievedPercentage > horizontalScrollEndPercentage) {
            return {
              percentage:
                horizontalScrollEndPercentage -
                getOffsetPercentage(CURRENT_HOURS_TOUCHABLE_VIEW_WIDTH) -
                getOffsetPercentage(7),
              arrowLocation: 'right',
            };
          }
          return {
            percentage:
              hoursAchievedPercentage - getOffsetPercentage(CURRENT_HOURS_TOUCHABLE_VIEW_WIDTH),
            arrowLocation: 'bottom-right',
          };
        }
        return {
          percentage: hoursAchievedPercentage - getOffsetPercentage(HALF_TOUCHABLE_VIEW_WIDTH),
          arrowLocation: 'center',
        };
      }
      return { percentage: 0, arrowLocation: 'center' };
    }, [
      getOffsetPercentage,
      horizontalScrollEndPercentage,
      horizontalScrollStartPercentage,
      monthlyBonusData,
    ]);

  useEffect(() => {
    if (monthlyBonusData) {
      const {
        monthlyBonus: { hoursAchieved, hoursAchievedPercentage },
      } = monthlyBonusData;

      const markPercentageAndArrowLocation = horizontalScrollBoxWidth
        ? getCurrentHoursMarkPercentageAndArrowDirection()
        : ({
            percentage: hoursAchievedPercentage - getOffsetPercentage(HALF_TOUCHABLE_VIEW_WIDTH),
            arrowLocation: 'center',
          } as MarkPercentageAndArrowDirection);
      setMarkedPercentages([
        {
          percentage: markPercentageAndArrowLocation.percentage,

          component: (
            <CurrentHoursMark
              hoursAchieved={hoursAchieved}
              onClick={() => {
                setShouldResetHorizontalScrollOffset(true);
              }}
              arrowLocation={markPercentageAndArrowLocation.arrowLocation}
            />
          ),
        },
      ]);
    }
  }, [
    horizontalScrollStartPercentage,
    horizontalScrollEndPercentage,
    monthlyBonusData,
    horizontalScrollBoxWidth,
    getCurrentHoursMarkPercentageAndArrowDirection,
    getOffsetPercentage,
  ]);

  return {
    monthlyBonusData,
    shouldResetHorizontalScrollOffset,
    setShouldResetHorizontalScrollOffset,
    setHorizontalScrollBoxOffset,
    setHorizontalScrollBoxOffsetUpdated,
    updatedBars,
    horizontalScrollStartPercentage,
    horizontalScrollEndPercentage,
    originalBars,
    setUpdatedBars,
    milestoneHoverIndex,
    setMilestoneHoverIndex,
    originalMarkedMilestones,
    updatedMarkedMilestones,
    setUpdatedMarkedMilestones,
    markedPercentages,
  };
};

export default useMonthlyBonusGraphDataSetup;
