import { useQuery } from 'react-query';
import apiWrapper from '../../utils/apiWrapper';
import apiHelper from '../../utils/api';
import liveSessions from './queryKeys';

export interface BookingClient {
  firstName: string;
  lastName: string;
  pseudonym: string;
  userID: number;
  isPrimaryParticipant: boolean;
}

export interface BookingsByIDV4Response {
  bookingID: string;
  roomID: number;
  creditMinutes: number;
  creditType: string;
  modality: string;
  scheduledByUserType: string;
  startTime: string;
  status:
    | 'active'
    | 'completed'
    | 'therapist-no-show'
    | 'client-no-show'
    | 'never-confirmed'
    | 'client-canceled'
    | 'therapist-canceled'
    | 'admin-canceled'
    | 'completed-after-late-start';
  timekitBookingState: 'tentative' | 'declined' | 'confirmed' | 'cancelled' | 'cancel_by_customer';
  hasBreakAfterSession: boolean;
  clients: BookingClient[];
  provider: {
    userID: number;
    firstName: string;
    lastName: string;
  };
  roomProviderID: number;
}

const fetchBookingsByDate =
  (therapistID: number, bookingID: string, fromDate: string, toDate: string) => async () => {
    const { data } = await apiWrapper.get(
      `${
        apiHelper().apiEndpoint
      }/v4/therapists/${therapistID}/bookings/${bookingID}?fromDate=${fromDate}&toDate=${toDate}`
    );

    return data.data;
  };

const useQueryProviderBookingsByID = (
  therapistID: number,
  bookingID: string,
  fromDate: string,
  toDate: string
) =>
  useQuery<BookingsByIDV4Response, Error>({
    queryKey: liveSessions.bookingsByID(therapistID, bookingID, { fromDate, toDate }),
    queryFn: fetchBookingsByDate(therapistID, bookingID, fromDate, toDate),
    cacheTime: 0,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: false,
    enabled: !!bookingID,
  });

export default useQueryProviderBookingsByID;
