export type Status = 'pending' | 'done' | 'error' | 'rejected';

export interface MediaInterface {
  url: string;
  title: string;
  duration: number;
  mediaID: number;
  mediaType: number;
  status?: Status;
  thumbnail?: string;
  fileSize: number;
}

// eslint-disable-next-line import/prefer-default-export
export class EMedia implements MediaInterface {
  url: string;

  title: string;

  duration: number;

  mediaID: number;

  mediaType: number;

  status?: Status;

  thumbnail?: string;

  fileSize: number;

  constructor(media: MediaInterface) {
    this.url = media.url;
    this.title = media.title;
    this.duration = media.duration;
    this.mediaID = media.mediaID;
    this.mediaType = media.mediaType;
    this.status = media.status;
    this.thumbnail = media.thumbnail;
    this.fileSize = media.fileSize;
  }
}
