import { View } from '@talkspace/react-toolkit';
import styled from '@/core/styled';

const Divider = styled(View)({
  borderColor: '#E3E9ED',
  borderStyle: 'solid',
  borderTop: 1,
  borderWidth: 1,
  width: '100%',
  marginTop: 16,
  marginBottom: 7,
});

export default Divider;
