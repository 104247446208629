import { A11Y_COLORS, Link, Small, styled } from '@talkspace/react-toolkit';
import { useHistory, useRouteMatch } from 'react-router';
import useQueryNotes from 'hooks/notes/useQueryNotes';
import { getDischargeView } from './utils';
import NoteDialogV2 from './NoteDialogV2';
import ssoHelper from '@/utils/sso';

const SmallText = styled(Small)({
  marginBottom: 12,
});

const title = 'New discharge note';

const IncompleteNotes = () => {
  const history = useHistory();
  const match = useRouteMatch<{ roomID: string }>();
  const { data: notes } = useQueryNotes(match.params.roomID);

  const base = match.url.replace('/incomplete-notes', '');

  return (
    <NoteDialogV2
      title={title}
      text="Incomplete progress notes"
      subtextContainer={
        <SmallText variant="smallDarkGrey">
          Complete all progress notes for this client to ensure compliance and/or payment. Once
          discharged, they’ll be removed from your caseload, though you will still see outstanding
          tasks for incomplete progress notes. Learn more about{' '}
          <Link
            onClick={() => {
              ssoHelper.openZendesk(
                '/hc/en-us/articles/11048550915227-How-do-I-clinically-discharge-or-terminate-treatment-with-a-client'
              );
            }}
            style={{ textDecoration: 'none', fontWeight: 'bold', color: A11Y_COLORS.greenText }}
            text="discharging clients."
            dataQa="discharging-clients-link"
          />
        </SmallText>
      }
      mainButtonOptions={{
        text: 'Review notes',
        onPress: () => {
          history.push(`${base}/notes-tab`);
        },
        dataQa: 'reviewNotesButton',
      }}
      alternativeButtonOptions={{
        text: 'Continue anyway',
        onPress: () => {
          history.push(`${base}/${getDischargeView(notes?.items)}`);
        },
        dataQa: 'continueAnywayButton',
      }}
    />
  );
};

export default IncompleteNotes;
