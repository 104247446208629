import * as actionTypes from '../constants/RoomConstants';
import apiWrapper from '../utils/apiWrapper';
import apiHelper from '../utils/api';

function getPropertyForRoomID(roomsArray, roomID, propertyKey) {
  const room = roomsArray.find((r) => +r.private_talk_id === +roomID);

  return room ? room[propertyKey] : undefined;
}

export function resetInitialRoomState() {
  return {
    type: actionTypes.RESET_INITIAL_ROOM_STATE,
  };
}

export function setRoomProperties(roomProperties) {
  return {
    type: actionTypes.SET_ROOM_PROPERTIES,
    ...roomProperties,
    isUpdating: false,
    isError: false,
  };
}

export function getRoomsError(error) {
  return {
    type: actionTypes.GET_ROOMS_ERROR,
    error,
  };
}

export function gettingRooms() {
  return {
    type: actionTypes.GETTING_ROOMS,
  };
}

function requestCreateRoom(data) {
  return { type: actionTypes.REQUEST_CREATE_ROOM, ...data };
}

function receiveCreateRoom(data) {
  return {
    type: actionTypes.RECEIVE_CREATE_ROOM,
    ...data,
  };
}

function requestCreateRoomError(error) {
  return {
    type: actionTypes.REQUEST_CREATE_ROOM_ERROR,
    error,
  };
}

function requestDischargeAndTransfer(data) {
  return { type: actionTypes.REQUEST_DISCHARGE_AND_TRANSFER, ...data };
}

function receiveDischargeAndTransfer(data) {
  return { type: actionTypes.RECEIVE_DISCHARGE_AND_TRANSFER, ...data };
}
function requestDischargeAndTransferError(error) {
  return {
    type: actionTypes.REQUEST_DISCHARGE_AND_TRANSFER_ERROR,
    error,
  };
}

export const getRoomProperties = (roomID) => (dispatch) => {
  dispatch(gettingRooms());
  // TODO: move all room information into redux

  return apiWrapper
    .get(`${apiHelper().apiEndpoint}/v2/rooms/${roomID}?detail_level=room`)
    .then((response) => {
      // fetches room information with default values
      const roomsArray = [...response.data.data, ...response.data.newRoomsData];
      const roomProperties = {
        roomID,
        clientUserID: getPropertyForRoomID(roomsArray, roomID, 'client_user_id'),
        status: getPropertyForRoomID(roomsArray, roomID, 'status'),
        createdAt: getPropertyForRoomID(roomsArray, roomID, 'created_at'),
        isEAP: !!getPropertyForRoomID(roomsArray, roomID, 'is_eap_room'),
        // keywords for quickmatch funnel variations, refer to talkspace-api/app/accounts/controllers/rooms/rooms.js
        isQuickmatch: ['Customer Selected', 'Psychiatry QuickMatch'].includes(
          getPropertyForRoomID(roomsArray, roomID, 'Source')
        ),
        roomType: getPropertyForRoomID(roomsArray, roomID, 'room_type'),
        conversionDate: getPropertyForRoomID(roomsArray, roomID, 'conversion_date'),
        isVideoOnly: getPropertyForRoomID(roomsArray, roomID, 'is_video_only') || false,
        isNewIntake: response.data.intakeV3,
      };
      dispatch(setRoomProperties(roomProperties));
    })
    .catch((err) => dispatch(getRoomsError(err)));
};

export const createRoom = (roomData) => (dispatch) => {
  dispatch(requestCreateRoom(roomData));
  return apiWrapper
    .post(`${apiHelper().apiEndpoint}/api/v1/rooms`, roomData)
    .then((response) => dispatch(receiveCreateRoom(response.response)))
    .catch((error) => dispatch(requestCreateRoomError(error)));
};

export const createPsychiatryRoom =
  ({ clientID, therapistID, currentRoomID, referralReason }) =>
  (dispatch) => {
    const roomData = {
      currentRoomID,
      clientID,
      therapistID,
      roomType: 'psychiatryRoom',
    };
    dispatch(requestCreateRoom(roomData));
    return apiWrapper
      .post(`${apiHelper().apiEndpoint}/api/v1/rooms`, roomData)
      .then((response) => {
        apiWrapper.post(
          `${apiHelper().apiEndpoint}/v2/rooms/${currentRoomID}/psychiatry-referral-notes`,
          { noteBody: referralReason, roomID: response.data.data.id }
        );
        return response;
      })
      .then((response) => dispatch(receiveCreateRoom(response.data)))
      .catch((error) => dispatch(requestCreateRoomError(error)));
  };

export const dischargeAndTransfer = (roomID, transferData) => (dispatch) => {
  dispatch(requestDischargeAndTransfer(transferData));
  return apiWrapper
    .post(`${apiHelper().apiEndpoint}/v2/rooms/${roomID}/discharge-and-transfer`, { transferData })
    .then((response) => dispatch(receiveDischargeAndTransfer(response.data)))
    .catch((error) => dispatch(requestDischargeAndTransferError(error)));
};
