import { toast, ToastContainer } from 'react-toastify';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { OptionType, View } from '@talkspace/react-toolkit';
import { useHistory, useLocation, useParams } from '@/core/routerLib';

import { login } from '../../../../reducers/auth';
import {
  deleteConditions as deleteConditionsAction,
  postConditions as postConditionsAction,
} from '../../../../actions/ClinicalInformationActions';
import {
  createJourneyItemFeedback as createJourneyItemFeedbackAction,
  getJourneyItems as getJourneyItemsAction,
  patchJourneyItem as patchJourneyItemAction,
  prepareSessionHighlights as prepareSessionHighlightsAction,
  updateJourneyItemFeedback as updateJourneyItemFeedbackAction,
} from '../../../../actions/JourneyActions';
import TipsAndTools from './TipsAndTools/TipsAndTools';
import AgendaEmptyState from './AgendaEmptyState';
import { CustomerInformation } from '../../../../types/customer';
import { reminderTypeAndTargetMapping } from '../../../../utils/RemindersConfig';

import './JourneyTab.css';
import useQueryParticipants from '../../../../hooks/useQueryParticipants';
import { Participant } from '../../../../types/participant';

const actionButtonStyle = {
  marginTop: '0',
  padding: '0px 18px 5px 0px',
  fontSize: '14px',
  letterSpacing: '0.2px',
  lineHeight: '19px',
  color: '#09B198',
  textAlign: 'right',
  display: 'inline-block',
  cursor: 'pointer',
  fontWeight: '700',
};

interface Props {
  journeyItems: any[];
  presentingProblemsConditionsMapping: any;
  presentingProblems: any[];
  customerInformation: CustomerInformation;
  clinicalInformation: any;
  postConditions: (
    clientID: number,
    option: OptionType,
    conditions: any[],
    participants: Participant[]
  ) => void;
  deleteConditions: (
    clientID: number,
    { id: number },
    otherConditions: any[],
    participants: Participant[]
  ) => void;
  getJourneyItems: () => void;
  patchJourneyItem: ({ roomJourneyInteraction: any }) => void;
  updateJourneyItemFeedback: (itemID: number, { feedback: value }) => void;
  createJourneyItemFeedback: (itemID: number, { feedback: value }) => void;
  prepareSessionHighlights: () => void;
}

const JourneyTab = ({
  journeyItems,
  presentingProblemsConditionsMapping,
  presentingProblems,
  customerInformation,
  clinicalInformation,
  postConditions,
  deleteConditions,
  getJourneyItems,
  patchJourneyItem,
  updateJourneyItemFeedback,
  createJourneyItemFeedback,
  prepareSessionHighlights,
}: Props) => {
  const { roomID } = useParams<{ roomID: string }>();
  const location = useLocation();
  const history = useHistory();

  const { data: participants = [] } = useQueryParticipants(Number(roomID));

  const getSuggestedConditionForPresentingProblem = (presentingProblemID) => {
    const condition = presentingProblemsConditionsMapping[presentingProblemID];
    return {
      conditionID: condition.conditionID,
      conditionText: condition.conditionText,
    };
  };

  const getSuggestionsByReminderTypeID = (reminderTypeID) => {
    const reminderSuggestionsMapping = {
      missing_conditions_day_7: null,
      missing_conditions_day_10: null,
      missing_conditions_day_14_blocking: () => {
        const presentingProblemID = presentingProblems[0] ? presentingProblems[0].value : 17;
        const condition = getSuggestedConditionForPresentingProblem(presentingProblemID);
        return `Based on the information currently available a condition of "${condition.conditionText}" is suggested`;
      },
      missing_first_message_hour_8: null,
      missing_first_media_message_hour_24: null,
      missing_response_to_client_message_hour_8: null,
      inadequate_word_count_day_2: null,
      inadequate_word_count_day_5: null,
    };

    return reminderSuggestionsMapping[reminderTypeAndTargetMapping[reminderTypeID]]
      ? reminderSuggestionsMapping[reminderTypeAndTargetMapping[reminderTypeID]]
      : () => undefined;
  };

  const conditionNavigationHandler = () => {
    const path = location.pathname.split('/');
    history.push(`/${path[1]}/${path[2]}/clinical-tab`);
  };

  const conditionUpdateHandler = () => {
    const clientID = customerInformation.clientUserID;
    const presentingProblemID = presentingProblems[0] ? presentingProblems[0].value : 17;
    const { conditionText, conditionID } =
      getSuggestedConditionForPresentingProblem(presentingProblemID);
    const condition = { value: conditionID, label: conditionText };
    postConditions(clientID, condition, [condition], participants);
  };

  const removeWorkingDiagnosisHandler = () => {
    const workingDiagnosisCondition = clinicalInformation.conditions.find(
      (condition) => condition.isWorkingDiagnosis
    );
    if (workingDiagnosisCondition) {
      // if there are no working diagnosis conditions, this card will not display
      const clientID = customerInformation.clientUserID;
      const otherConditions = clinicalInformation.conditions.filter(
        (condition) => !condition.isWorkingDiagnosis
      );
      deleteConditions(
        clientID,
        { id: workingDiagnosisCondition.id },
        otherConditions,
        participants
      );
    }
  };

  const handleUpdateJourneyItems = (e, item, journeyItemThis) => {
    const { roomJourneyInteraction } = item;
    patchJourneyItem({ roomJourneyInteraction });
    journeyItemThis.setState({ height: e.target.parentElement.parentElement.clientHeight }, () => {
      setTimeout(
        () =>
          journeyItemThis.setState({ dismissing: true }, () =>
            setTimeout(() => {
              journeyItemThis.setState({ dismissed: true });
              getJourneyItems();
            }, 500)
          ),
        0
      );
    });
  };

  const diagnosticProfileNavigationHandler = (e, item, journeyItemThis) => {
    // This card gets dismissed on usage
    handleUpdateJourneyItems(e, item, journeyItemThis);
    const path = location.pathname.split('/');
    const isCouple = participants.length > 1;
    setTimeout(() => {
      if (isCouple) {
        history.push(`/${path[1]}/${path[2]}/diagnostic-profiles`, { title: 'Diagnostic profile' });
      } else {
        history.push(`/${path[1]}/${path[2]}/diagnostic-profile${participants[0].userID}`, {
          title: 'Diagnostic profile',
        });
      }
      getJourneyItems();
    }, 500);
  };

  const handleNavigationToClinicalTab = (query) => {
    const path = location.pathname.split('/');
    const queryParams = `?container=${query}`;
    setTimeout(() => {
      history.push(`/${path[1]}/${path[2]}/clinical-tab${queryParams}`);
      getJourneyItems();
    }, 500);
  };

  const outcomeMeasureNavigationHandler = (e, item, journeyItemThis) => {
    // This card gets dismissed on usage
    handleUpdateJourneyItems(e, item, journeyItemThis);
    handleNavigationToClinicalTab('outcome-measures');
  };

  const clinicalInformationNavigationHandler = (e, item, journeyItemThis) => {
    // This card gets dismissed on usage
    handleUpdateJourneyItems(e, item, journeyItemThis);
    handleNavigationToClinicalTab('clinical-information');
  };

  const journeyItemFeedbackHandler = (feedback, itemID) => (value) =>
    feedback
      ? updateJourneyItemFeedback(itemID, { feedback: value })
      : createJourneyItemFeedback(itemID, { feedback: value });

  const sessionSummaryNavigationHandler = () => {
    prepareSessionHighlights();
    const path = location.pathname.split('/');
    history.push(`/${path[1]}/${path[2]}/session-summary`);
  };

  const getReminderActionsByReminderTypeID = (reminderTypeID) => {
    const reminderActionsMapping = {
      missing_conditions_day_7: [
        {
          style: { ...actionButtonStyle },
          text: 'Choose diagnosis',
          handler: conditionNavigationHandler,
        },
      ],
      missing_conditions_day_10: [
        {
          style: { ...actionButtonStyle },
          text: 'Choose diagnosis',
          handler: conditionNavigationHandler,
        },
      ],
      missing_conditions_day_14_blocking: [
        {
          style: { ...actionButtonStyle, color: '#B8B8B8' },
          text: 'I agree',
          handler: conditionUpdateHandler,
        },
        {
          style: { ...actionButtonStyle },
          text: 'Choose another',
          handler: conditionNavigationHandler,
        },
      ],
      missing_first_message_hour_8: [],
      missing_first_media_message_hour_24: [],
      missing_response_to_client_message_hour_8: [],
      inadequate_word_count_day_2: [],
      inadequate_word_count_day_5: [],
      summarization_session: [
        {
          style: { ...actionButtonStyle },
          text: 'Show more',
          handler: sessionSummaryNavigationHandler,
        },
      ],
      intro_missing: [],
      expectation_setting_missing: [],
      diagnostic_profile: [
        {
          style: { ...actionButtonStyle },
          text: 'Show profile',
          handler: diagnosticProfileNavigationHandler,
        },
      ],
      working_diagnosis: [
        {
          style: { ...actionButtonStyle, color: '#B8B8B8', paddingRight: 12 },
          text: 'I agree',
          handler: handleUpdateJourneyItems,
        },
        {
          style: { ...actionButtonStyle },
          text: 'Premature to diagnose',
          handler: removeWorkingDiagnosisHandler,
        },
      ],
      outcome_measure_completed: [
        {
          style: { ...actionButtonStyle, color: '#005C65' },
          text: 'Review assessment',
          handler: outcomeMeasureNavigationHandler,
        },
      ],
      si_risk_flag: [
        {
          style: { ...actionButtonStyle, color: '#005C65' },
          text: 'Review clinical tab',
          handler: clinicalInformationNavigationHandler,
        },
      ],
    };
    return reminderActionsMapping[reminderTypeAndTargetMapping[reminderTypeID]] || [];
  };

  const processJourneyItems = () =>
    journeyItems?.map((item) => {
      const isReminder = !!item.reminderTypeID;
      let processedItem;

      const defaultValues = {
        ...item,
        feedbackActions: {
          like: {
            handler: journeyItemFeedbackHandler(item.feedback, item.id),
          },
          dislike: {
            handler: journeyItemFeedbackHandler(item.feedback, item.id),
          },
        },
      };

      if (isReminder) {
        processedItem = {
          ...defaultValues,
          actions: getReminderActionsByReminderTypeID(item.reminderTypeID),
          suggestion: getSuggestionsByReminderTypeID(item.reminderTypeID),
        };
        if (!['alert', 'to-do'].includes(item.category)) {
          processedItem.actions.unshift({
            style: { ...actionButtonStyle, color: '#B8B8B8' },
            text: 'Dismiss',
            handler: handleUpdateJourneyItems,
          });
        }
      } else {
        processedItem = {
          ...defaultValues,
          actions: [
            {
              style: { ...actionButtonStyle, color: '#B8B8B8' },
              text: 'Dismiss',
              handler: handleUpdateJourneyItems,
            },
          ],
          suggestion: null,
        };
      }
      return processedItem;
    });

  const processedJourneyItems = processJourneyItems();

  return journeyItems !== undefined ? (
    <View
      flex={1}
      align="start"
      style={{ overflowX: 'hidden', maxWidth: 374, alignItems: 'center' }}
    >
      {processedJourneyItems?.length > 0 ? (
        <View className="room-journey-tab">
          <TipsAndTools roomID={roomID} journeyItems={processedJourneyItems} />
        </View>
      ) : (
        <AgendaEmptyState />
      )}

      <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} hideProgressBar />
    </View>
  ) : null;
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      login,
      createJourneyItemFeedback: createJourneyItemFeedbackAction,
      updateJourneyItemFeedback: updateJourneyItemFeedbackAction,
      getJourneyItems: getJourneyItemsAction,
      postConditions: postConditionsAction,
      deleteConditions: deleteConditionsAction,
      patchJourneyItem: patchJourneyItemAction,
      prepareSessionHighlights: prepareSessionHighlightsAction,
    },
    dispatch
  );

const mapStateToProps = (state) => {
  return {
    journeyItems: state.journey.journeyItems,
    presentingProblemsConditionsMapping: state.journey.presentingProblemsConditionsMapping,
    presentingProblems: state.clinicalInformation.presentingProblems,
    customerInformation: state.customerInformation,
    clinicalInformation: state.clinicalInformation,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JourneyTab);
