import Svg, { Path } from 'svgs';
import { EmotionStyle, useEmotionTheme } from '../../core/styled';

interface UserIconProps {
  color?: string;
  width?: number;
  height?: number;
  style?: EmotionStyle;
}

const UserIcon = ({ color, width = 20, height = 20, style }: UserIconProps) => {
  const { colors } = useEmotionTheme();
  const titleText = 'user icon';
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      title={titleText}
      aria-label={titleText}
      style={style}
      fill="none"
    >
      <Path
        d="M12.5 6.00003C12.5 5.33699 12.2366 4.7011 11.7678 4.23226C11.2989 3.76342 10.663 3.50003 10 3.50003C9.33696 3.50003 8.70107 3.76342 8.23223 4.23226C7.76339 4.7011 7.5 5.33699 7.5 6.00003C7.5 6.66307 7.76339 7.29896 8.23223 7.7678C8.70107 8.23664 9.33696 8.50003 10 8.50003C10.663 8.50003 11.2989 8.23664 11.7678 7.7678C12.2366 7.29896 12.5 6.66307 12.5 6.00003ZM6 6.00003C6 4.93916 6.42143 3.92175 7.17157 3.1716C7.92172 2.42146 8.93913 2.00003 10 2.00003C11.0609 2.00003 12.0783 2.42146 12.8284 3.1716C13.5786 3.92175 14 4.93916 14 6.00003C14 7.0609 13.5786 8.07831 12.8284 8.82846C12.0783 9.5786 11.0609 10 10 10C8.93913 10 7.92172 9.5786 7.17157 8.82846C6.42143 8.07831 6 7.0609 6 6.00003ZM4.54062 16.5H15.4594C15.1813 14.5219 13.4813 13 11.4281 13H8.57188C6.51875 13 4.81875 14.5219 4.54062 16.5ZM3 17.0719C3 13.9938 5.49375 11.5 8.57188 11.5H11.4281C14.5063 11.5 17 13.9938 17 17.0719C17 17.5844 16.5844 18 16.0719 18H3.92813C3.41563 18 3 17.5844 3 17.0719Z"
        fill={color || colors.slateGrey}
      />
    </Svg>
  );
};

export default UserIcon;
