import moment, { Moment } from 'moment-timezone';
import { useQuery } from 'react-query';
import apiHelper from '../../modules/utils/api';
import apiWrapper from '../../utils/apiWrapper';
import availabilityKeys from './queryKeys';

const CONSTRAINT_BLOCK_MINUTES = 60;

export const daysOfTheWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const daysOfWeekToDay = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
};

export type DayOfTheWeek =
  | 'Sunday'
  | 'Monday'
  | 'Tuesday'
  | 'Wednesday'
  | 'Thursday'
  | 'Friday'
  | 'Saturday';

interface ConstraintsObject {
  start: number;
  end: number;
  day: DayOfTheWeek;
}

interface AvailabilityCalendarBlock {
  start: string;
  lengthMinutes: number;
}

export interface AvailabilityAPIResponse {
  availabilities: string[];
  constraints: ConstraintsObject[];
  unavailabilities: string[];
  timezone: string;
}

export function transformAPIResponse(
  response: AvailabilityAPIResponse | undefined,
  startDate: Date,
  endDate: Date
) {
  if (!response) return [];

  const { constraints, unavailabilities, availabilities, timezone } = response;

  const today = moment().isoWeekday();
  const startDateWeekDiff = moment(endDate).diff(moment(), 'weeks');

  const filteredUnavailabilities = unavailabilities.filter((date) =>
    moment(date).isBetween(startDate, endDate)
  );
  const filteredAvailabilities: AvailabilityCalendarBlock[] = availabilities
    .filter((date) => moment(date).isBetween(startDate, endDate))
    .map((date) => {
      return { start: date, lengthMinutes: CONSTRAINT_BLOCK_MINUTES };
    });

  const filteredRecurringAvailabilities = daysOfTheWeek.reduce(
    (acc: AvailabilityCalendarBlock[], day: DayOfTheWeek) => {
      const constraintsForDay = constraints.filter(
        ({ day: constraintDay }) => constraintDay === day
      );

      const isOnDayOfWeekTodayOrGreater = today <= daysOfWeekToDay[day];
      const isOnFutureWeek = startDateWeekDiff > 0;

      const weekToAdd = isOnFutureWeek || isOnDayOfWeekTodayOrGreater ? 0 : 1;

      constraintsForDay.forEach(({ start, end }) => {
        const length = end - start;

        const startToCheck: Moment = moment()
          .tz(timezone)
          .isoWeekday(daysOfWeekToDay[day])
          .hour(start)
          .minute(0)
          .second(0)
          .millisecond(0);

        startToCheck.add(startDateWeekDiff + weekToAdd, 'week');

        if (!filteredUnavailabilities.some((date) => startToCheck.isSame(date))) {
          acc.push({
            start: startToCheck.toISOString(),
            lengthMinutes: length * CONSTRAINT_BLOCK_MINUTES,
          });
        }
      });
      return acc;
    },
    []
  );

  return [...filteredRecurringAvailabilities, ...filteredAvailabilities];
}

const fetchCalendarAvailability = (therapistID: number) => async () => {
  const { data } = await apiWrapper.get(
    `${apiHelper().apiEndpoint}/v2/therapist/${therapistID}/availability`
  );
  return data;
};

const useQueryCalendarAvailability = (therapistID: number) =>
  useQuery<AvailabilityAPIResponse, Error>({
    queryKey: availabilityKeys.therapistAvailabilityV2(therapistID),
    queryFn: fetchCalendarAvailability(therapistID),
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: !!therapistID,
  });

export default useQueryCalendarAvailability;
