import { FC, useEffect } from 'react';

import { TextDS, View } from '@talkspace/react-toolkit';
import styled from '@/core/styled';

import { useVideoCallActions, useVideoCallState } from '../../hooks/videoCallContext';
import { CaptionsLanguage } from '../../types/videoCallTypes';

interface TextProperties {
  direction: 'ltr' | 'rtl';
  textAlign: 'left' | 'right';
}

const defaultTextProperties: TextProperties = { direction: 'ltr', textAlign: 'left' };

const textPropertiesByLanguage: Record<CaptionsLanguage, TextProperties> = {
  '': defaultTextProperties,
  en: defaultTextProperties,
  es: defaultTextProperties,
  he: { direction: 'rtl', textAlign: 'right' },
  zh: defaultTextProperties,
};

const StyledText = styled(TextDS)<{ language: CaptionsLanguage }>(
  ({ language }) => textPropertiesByLanguage[language] || defaultTextProperties
);

const Wrapper = styled(View)(
  ({
    theme: {
      window: { isMobile, isTablet },
      colorRoles,
    },
  }) => {
    let width = 604;

    if (isMobile) {
      width = 343;
    }

    if (isTablet) {
      width = 522;
    }

    return {
      position: 'fixed',
      display: 'flex',
      alignSelf: 'center',
      bottom: 110,
      width: '100%',
      maxWidth: width,
      textAlign: 'left',
      backgroundColor: colorRoles.surfaces.overlayBold,
      padding: 8,
      borderRadius: 5,
    };
  }
);

const ClosedCaptionsView: FC = () => {
  const { closedCaptions } = useVideoCallState();
  const { setClosedCaptions } = useVideoCallActions();
  const { captionsLanguage } = useVideoCallState();

  useEffect(
    () => () => {
      setClosedCaptions('');
    },
    [setClosedCaptions]
  );

  if (!closedCaptions) {
    return null;
  }

  return (
    <Wrapper>
      <StyledText variant="body" colorRole="textInverse" language={captionsLanguage}>
        {closedCaptions}
      </StyledText>
    </Wrapper>
  );
};

export default ClosedCaptionsView;
