import { VFC } from 'react';
import Svg, { Circle, Path } from 'svgs';
import { useEmotionTheme, EmotionStyle } from '../../core/styled';

interface SparklingCalendarProps {
  width?: number;
  height?: number;
  color?: string;
  style?: EmotionStyle;
}

const SparklingCalendar: VFC<SparklingCalendarProps> = ({
  width = 153,
  height = 130,
  ...otherProps
}) => {
  const titleText = 'Sparkling Calendar';
  const { colors } = useEmotionTheme();
  return (
    <Svg
      title={titleText}
      aria-label={titleText}
      width={width}
      height={height}
      fill="none"
      {...otherProps}
    >
      <Circle cx={78} cy={65} r={65} fill={colors.greyBubble} />
      <Path
        d="M34.63 82.192a2.5 2.5 0 0 1 2.113 4.532L27.68 90.95a2.5 2.5 0 0 1-2.113-4.532l9.063-4.226Z"
        fill="#C0E3E0"
      />
      <Path
        d="M35.534 90.046a2.5 2.5 0 1 1-4.531 2.113l-4.227-9.063a2.5 2.5 0 0 1 4.532-2.113l4.226 9.063Z"
        fill="#C0E3E0"
      />
      <Circle cx={145.5} cy={23.5} r={4.5} stroke="#ADE953" strokeWidth={5} />
      <Circle cx={11} cy={42} r={8} stroke="#BCCCFF" strokeWidth={6} />
      <Path
        d="m55.117 38.142 30.164-1.053a7.806 7.806 0 0 1 8.073 7.528l1.055 30.166a7.806 7.806 0 0 1-7.528 8.074l-30.166 1.054a7.806 7.806 0 0 1-8.073-7.529l-1.053-30.166a7.806 7.806 0 0 1 7.528-8.074Z"
        fill="#fff"
        stroke="#BECBE0"
        strokeWidth={5.204}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.007 55.526c.2-5.744 5.02-10.238 10.764-10.038l30.164 1.054c5.744.2 10.238 5.02 10.038 10.763l-1.051 30.167c-.201 5.745-5.02 10.24-10.765 10.039l-30.165-1.054c-5.745-.2-10.24-5.02-10.039-10.764l1.054-30.167Z"
        fill="#CFEAFF"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m101.753 51.742-30.164-1.053a5.204 5.204 0 0 0-5.382 5.019l-1.054 30.166a5.204 5.204 0 0 0 5.02 5.382l30.165 1.054a5.204 5.204 0 0 0 5.382-5.02l1.052-30.166a5.204 5.204 0 0 0-5.019-5.382Zm-29.982-6.254c-5.745-.2-10.564 4.294-10.765 10.038l-1.053 30.166c-.2 5.745 4.293 10.564 10.038 10.765l30.165 1.053c5.745.201 10.565-4.294 10.765-10.038l1.051-30.167c.201-5.744-4.294-10.563-10.038-10.764l-30.163-1.053Z"
        fill="#5C84FB"
      />
      <path
        d="M72.542 69.28a1.5 1.5 0 0 1 1.551-1.447l2.998.105a1.5 1.5 0 0 1 1.447 1.551l-.105 2.998a1.5 1.5 0 0 1-1.551 1.447l-2.998-.105a1.5 1.5 0 0 1-1.447-1.551l.105-2.998ZM79.537 69.524a1.5 1.5 0 0 1 1.552-1.447l2.998.105a1.5 1.5 0 0 1 1.447 1.551l-.105 2.999a1.5 1.5 0 0 1-1.551 1.446l-2.998-.104a1.5 1.5 0 0 1-1.447-1.552l.104-2.998ZM86.533 69.768a1.5 1.5 0 0 1 1.552-1.446l2.998.104a1.5 1.5 0 0 1 1.447 1.552l-.105 2.998a1.5 1.5 0 0 1-1.551 1.447l-2.999-.105a1.5 1.5 0 0 1-1.446-1.552l.104-2.998ZM72.297 76.275a1.5 1.5 0 0 1 1.552-1.446l2.998.104a1.5 1.5 0 0 1 1.447 1.552l-.105 2.998a1.5 1.5 0 0 1-1.551 1.447l-2.999-.105a1.5 1.5 0 0 1-1.446-1.551l.105-2.999ZM79.293 76.52a1.5 1.5 0 0 1 1.552-1.447l2.998.105a1.5 1.5 0 0 1 1.447 1.551l-.105 2.998a1.5 1.5 0 0 1-1.552 1.447l-2.998-.105a1.5 1.5 0 0 1-1.447-1.551l.105-2.998ZM86.289 76.764a1.5 1.5 0 0 1 1.551-1.447l2.999.105a1.5 1.5 0 0 1 1.446 1.552l-.104 2.998a1.5 1.5 0 0 1-1.552 1.446l-2.998-.104a1.5 1.5 0 0 1-1.447-1.552l.105-2.998ZM93.285 77.008a1.5 1.5 0 0 1 1.551-1.446l2.998.104a1.5 1.5 0 0 1 1.447 1.552l-.105 2.998a1.5 1.5 0 0 1-1.551 1.447l-2.998-.105a1.5 1.5 0 0 1-1.447-1.552l.105-2.998ZM79.049 83.516a1.5 1.5 0 0 1 1.551-1.447l2.999.105a1.5 1.5 0 0 1 1.446 1.55l-.104 3a1.5 1.5 0 0 1-1.552 1.446l-2.998-.105a1.5 1.5 0 0 1-1.447-1.551l.105-2.998ZM86.045 83.76a1.5 1.5 0 0 1 1.551-1.447l2.998.105a1.5 1.5 0 0 1 1.447 1.551l-.105 2.998a1.5 1.5 0 0 1-1.551 1.447l-2.998-.105a1.5 1.5 0 0 1-1.447-1.551l.105-2.998ZM93.04 84.004a1.5 1.5 0 0 1 1.552-1.447l2.998.105a1.5 1.5 0 0 1 1.447 1.551l-.105 2.999a1.5 1.5 0 0 1-1.551 1.446l-2.999-.104a1.5 1.5 0 0 1-1.446-1.552l.104-2.998Z"
        fill="#5C84FB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m62.725 59.081 48.171 1.682-.181 5.197-48.17-1.682.18-5.197ZM77.499 41.586a2.6 2.6 0 0 1 2.507 2.689l-.279 7.995a2.6 2.6 0 0 1-5.197-.182l.28-7.995a2.6 2.6 0 0 1 2.689-2.507ZM96.487 42.249a2.6 2.6 0 0 1 2.508 2.689l-.28 7.995a2.6 2.6 0 1 1-5.196-.181l.279-7.996a2.6 2.6 0 0 1 2.69-2.507Z"
        fill="#5C84FB"
      />
    </Svg>
  );
};

export default SparklingCalendar;
