import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const standardPath =
  'M16.2812 3.21797L16.6594 3.59609C16.9531 3.88984 16.9531 4.36484 16.6594 4.65547L15.75 5.56797L14.3094 4.12734L15.2188 3.21797C15.5125 2.92422 15.9875 2.92422 16.2781 3.21797H16.2812ZM9.05625 9.38359L13.25 5.18672L14.6906 6.62734L10.4938 10.8211C10.4031 10.9117 10.2906 10.9773 10.1687 11.0117L8.34062 11.5336L8.8625 9.70547C8.89688 9.58359 8.9625 9.47109 9.05312 9.38047L9.05625 9.38359ZM14.1594 2.15859L7.99375 8.32109C7.72188 8.59297 7.525 8.92734 7.42188 9.29297L6.52812 12.418C6.45312 12.6805 6.525 12.9617 6.71875 13.1555C6.9125 13.3492 7.19375 13.4211 7.45625 13.3461L10.5813 12.4523C10.95 12.3461 11.2844 12.1492 11.5531 11.8805L17.7188 5.71797C18.5969 4.83984 18.5969 3.41484 17.7188 2.53672L17.3406 2.15859C16.4625 1.28047 15.0375 1.28047 14.1594 2.15859ZM5.25 3.37734C3.73125 3.37734 2.5 4.60859 2.5 6.12734V14.6273C2.5 16.1461 3.73125 17.3773 5.25 17.3773H13.75C15.2688 17.3773 16.5 16.1461 16.5 14.6273V11.1273C16.5 10.7117 16.1656 10.3773 15.75 10.3773C15.3344 10.3773 15 10.7117 15 11.1273V14.6273C15 15.318 14.4406 15.8773 13.75 15.8773H5.25C4.55938 15.8773 4 15.318 4 14.6273V6.12734C4 5.43672 4.55938 4.87734 5.25 4.87734H8.75C9.16563 4.87734 9.5 4.54297 9.5 4.12734C9.5 3.71172 9.16563 3.37734 8.75 3.37734H5.25Z';

const majorPath =
  'M19.3596 4.95702L19.8359 5.43333C20.206 5.80335 20.206 6.40169 19.8359 6.76778L18.6904 7.91721L16.8758 6.10252L18.0213 4.95702C18.3913 4.587 18.9896 4.587 19.3557 4.95702H19.3596ZM10.2586 12.7236L15.5413 7.43697L17.356 9.25166L12.0694 14.5343C11.9552 14.6485 11.8135 14.7312 11.66 14.7745L9.35718 15.4319L10.0146 13.129C10.0579 12.9755 10.1405 12.8338 10.2547 12.7197L10.2586 12.7236ZM16.6868 3.62257L8.92024 11.3852C8.57777 11.7277 8.32977 12.1489 8.19987 12.6094L7.07405 16.5459C6.97958 16.8765 7.07012 17.2308 7.31418 17.4749C7.55823 17.7189 7.91251 17.8095 8.24317 17.715L12.1796 16.5892C12.6441 16.4553 13.0653 16.2073 13.4038 15.8688L21.1704 8.10616C22.2765 7.00002 22.2765 5.20501 21.1704 4.09888L20.6941 3.62257C19.588 2.51643 17.7929 2.51643 16.6868 3.62257ZM5.46406 5.15778C3.55095 5.15778 2 6.70873 2 8.62183V19.3289C2 21.242 3.55095 22.793 5.46406 22.793H16.1711C18.0842 22.793 19.6352 21.242 19.6352 19.3289V14.9201C19.6352 14.3966 19.214 13.9754 18.6904 13.9754C18.1669 13.9754 17.7457 14.3966 17.7457 14.9201V19.3289C17.7457 20.1989 17.0411 20.9035 16.1711 20.9035H5.46406C4.59411 20.9035 3.88948 20.1989 3.88948 19.3289V8.62183C3.88948 7.75188 4.59411 7.04726 5.46406 7.04726H9.87285C10.3964 7.04726 10.8176 6.62606 10.8176 6.10252C10.8176 5.57897 10.3964 5.15778 9.87285 5.15778H5.46406Z';

const hugePath =
  'M26.3094 5.12266L26.8766 5.68984C27.4625 6.27578 27.4625 7.22734 26.8766 7.81328L25.625 9.06484L22.9344 6.37422L24.1859 5.12266C24.7719 4.53672 25.7234 4.53672 26.3094 5.12266ZM13.8594 15.4492L21.875 7.43359L24.5656 10.1242L16.55 18.1398C16.3531 18.3367 16.1 18.4773 15.8281 18.543L12.7531 19.2508L13.4609 16.1711C13.5219 15.8992 13.6625 15.6461 13.8641 15.4492H13.8594ZM23.1266 4.05859L12.8 14.3898C12.4016 14.7883 12.125 15.2898 11.9984 15.8383L11.0187 20.0852C10.9625 20.3383 11.0375 20.6008 11.2203 20.7836C11.4031 20.9664 11.6656 21.0414 11.9188 20.9852L16.1656 20.0055C16.7141 19.8789 17.2156 19.6023 17.6141 19.2039L27.9406 8.87266C29.1125 7.70078 29.1125 5.80234 27.9406 4.63047L27.3734 4.05859C26.2016 2.88672 24.3031 2.88672 23.1312 4.05859H23.1266ZM8.75 5.99922C6.67812 5.99922 5 7.67734 5 9.74922V23.2492C5 25.3211 6.67812 26.9992 8.75 26.9992H22.25C24.3219 26.9992 26 25.3211 26 23.2492V17.2492C26 16.8367 25.6625 16.4992 25.25 16.4992C24.8375 16.4992 24.5 16.8367 24.5 17.2492V23.2492C24.5 24.4914 23.4922 25.4992 22.25 25.4992H8.75C7.50781 25.4992 6.5 24.4914 6.5 23.2492V9.74922C6.5 8.50703 7.50781 7.49922 8.75 7.49922H14.75C15.1625 7.49922 15.5 7.16172 15.5 6.74922C15.5 6.33672 15.1625 5.99922 14.75 5.99922H8.75Z';

const pathConfig: IconConfig = {
  standard: {
    default: {
      path: standardPath,
    },
  },
  major: {
    default: {
      path: majorPath,
    },
  },
  huge: {
    default: {
      path: hugePath,
    },
  },
};

const PenToSquare = withDSIconMaker(pathConfig, 'pen to square');

export default PenToSquare;
