import { useEffect } from 'react';

import ReviewsList from '../components/ReviewsList';
import { usePerformanceActions, usePerformanceState } from '../hooks/performanceContext';

const ReviewsContainer = () => {
  const { isLoading, isError, hasMore, reviewsList, total } = usePerformanceState();
  const { dispatchGetReviewsList } = usePerformanceActions();

  useEffect(() => {
    dispatchGetReviewsList();
  }, [dispatchGetReviewsList]);

  const handleLoadMoreClick = () => {
    dispatchGetReviewsList();
  };

  return (
    <ReviewsList
      reviews={reviewsList}
      total={total}
      isLoading={isLoading}
      isError={isError}
      hasMore={hasMore}
      handleLoadMoreClick={handleLoadMoreClick}
    />
  );
};

export default ReviewsContainer;
