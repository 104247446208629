import { FunctionComponent } from 'react';
import { toast, ToastContainerProps, ToastContainer as ToastifyContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import './toast.css';

interface ToastOptions {
  type?: 'error' | 'success';
}

// Add default styles
const overloadedToast = Object.assign(
  (message: string, { type, ...opts }: ToastOptions = { type: 'error' }) =>
    toast(<div className={`chat-toaster chat-toaster-${type}`}>{message}</div>, {
      ...opts,
    }),
  toast
);

const ToastContainer: FunctionComponent<ToastContainerProps> = ({
  closeButton = false,
  autoClose = 3000,
  position = toast.POSITION.BOTTOM_RIGHT,
  hideProgressBar = true,
  ...otherProps
}) => (
  <ToastifyContainer
    closeButton={closeButton}
    autoClose={autoClose}
    position={position}
    hideProgressBar={hideProgressBar}
    {...otherProps}
  />
);

export { overloadedToast as toast, ToastContainer };
