import { Avatar, View } from '@talkspace/react-toolkit';
import { ReactNode, VoidFunctionComponent } from 'react';
import appConfigs from '../../../utils/configs';

interface AvatarWithStatusProps {
  therapistID?: number;
  size?: number;
  icon?: ReactNode;
}

const AvatarWithStatus: VoidFunctionComponent<AvatarWithStatusProps> = ({
  therapistID,
  size = 29,
  icon,
}) => {
  const REACT_APP_CDN_ENDPOINT = appConfigs.endpoints.cdnEndpoint;
  return (
    <View>
      <Avatar
        height={size}
        width={size}
        image={
          therapistID
            ? `${REACT_APP_CDN_ENDPOINT}/images/application/therapist/440/${therapistID}.png`
            : undefined
        }
      />
      {!!icon && <View style={{ position: 'relative', bottom: 9, left: 20 }}>{icon}</View>}
    </View>
  );
};

export default AvatarWithStatus;
