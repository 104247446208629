import { FunctionComponent } from 'react';
import { Standard, View } from '@talkspace/react-toolkit';
import Submenu from 'components/Reusable/Submenu/Submenu';
import useQueryBusinessLine from 'ts-frontend/hooks/useQueryBusinessLine';
import useQueryRoomDetails from 'ts-frontend/hooks/useQueryRoomDetails';
import styled from '@/core/styled';
import { useParams } from '@/core/routerLib/routerLib';
import { useHistory } from '../../../../core/routerLib';

const Section = styled(Standard)(({ theme: { colors } }) => {
  return {
    marginRight: 20,
    marginLeft: 20,
    marginBottom: 30,
    color: colors.permaRiverBed,
    fontFamily: 'Roboto',
    fontWeight: 400,
  };
});

export interface BusinessLine {
  isBH: boolean;
}

const PsychProgressNoteText: FunctionComponent<BusinessLine> = ({ isBH }) => (
  <Section>
    Create a <b>psychiatric progress note</b> to document your clinical contact with the client.{' '}
    {isBH
      ? ' Because this client is on a Behavioral Health (BH) plan, you must submit a progress note ' +
        'for each session to qualify it for provider payment. '
      : ''}
  </Section>
);

const CollateralNoteText: FunctionComponent = () => (
  <Section>
    Create a <b>collateral note</b> to document contact with someone that is not the identified
    client, such as a spouse, family member, or guidance counselor. The note can also be used to
    document attempted engagement with the identified client.
  </Section>
);

const CaseConsultationNoteText: FunctionComponent = () => (
  <Section>
    Create a <b>case consultation note</b> to document communication with another treating provider,
    such as a PCP or pediatrician, outside psychiatrist, therapist, or state agency.
  </Section>
);

const SessionSummaryNoteText: FunctionComponent<BusinessLine> = ({ isBH }) => (
  <Section>
    Create a <b>session summary note</b> to document your clinical contact with the client
    {isBH
      ? '. Because this client is on a Behavioral Health (BH) plan, you must submit a session ' +
        'summary note for each session to mark it as complete and to qualify it for provider ' +
        'payment. '
      : ' for each session.'}
  </Section>
);

const PsychNoteText: FunctionComponent = () => (
  <Section>
    Create a <b>psychotherapy note</b> to write down your notes. They’re kept separate from medical
    records and billing information and aren’t required.
  </Section>
);

const ProgressNoteText: FunctionComponent = () => (
  <Section>
    Create a <b>progress note</b> to document your clinical contact with the client.
  </Section>
);

const NoteTypeExplanation: FunctionComponent = () => {
  const { roomID } = useParams<{ roomID: string }>();
  const history = useHistory();
  const { data: { clientUserID } = {} } = useQueryRoomDetails(roomID);
  const { data: businessLine } = useQueryBusinessLine(roomID, clientUserID);

  const getTypeExplanation = (): JSX.Element => {
    if (!businessLine?.isB2B && businessLine?.isPsychiatry) {
      return (
        <View>
          <PsychProgressNoteText isBH={businessLine?.isBH} />
          <CollateralNoteText />
          <CaseConsultationNoteText />
        </View>
      );
    }
    if (businessLine?.isBH && !businessLine?.isPsychiatry) {
      return (
        <View>
          <SessionSummaryNoteText isBH={businessLine?.isBH} />
          <CollateralNoteText />
          <CaseConsultationNoteText />
          <PsychNoteText />
        </View>
      );
    }
    if (businessLine?.isBH && businessLine?.isPsychiatry) {
      return (
        <View>
          <PsychProgressNoteText isBH={businessLine?.isBH} />
          <CollateralNoteText />
          <CaseConsultationNoteText />
        </View>
      );
    }
    if (businessLine?.isEAP) {
      return (
        <View>
          <SessionSummaryNoteText isBH={businessLine?.isBH} />
          <CollateralNoteText />
          <CaseConsultationNoteText />
          <PsychNoteText />
        </View>
      );
    }
    return (
      <View>
        <ProgressNoteText />
        <CollateralNoteText />
        <CaseConsultationNoteText />
        <PsychNoteText />
      </View>
    );
  };

  const handleBackButtonPress = () => {
    history.push(`/room/${roomID}/notes-tab`);
  };

  return (
    <Submenu
      childComponents={[getTypeExplanation()]}
      title="Which note type should I use?"
      onBackAlt={handleBackButtonPress}
    />
  );
};

export default NoteTypeExplanation;
