import Svg, { Path, Rect } from 'svgs';

const SquareCheckMark = ({
  width = 11,
  height = 11,
  ...props
}: {
  width?: number;
  height?: number;
}) => {
  const titleText = 'check mark';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 11 11"
      fill="none"
      {...props}
    >
      <Rect width={11} height={11} rx={2} fill="#1DB06B" />
      <Path
        d="M2.522 6l2.453 1.944 3.24-4.945"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
      />
    </Svg>
  );
};

export default SquareCheckMark;
