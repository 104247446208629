import Svg, { Ellipse, Path } from 'svgs';
import { EmotionStyle, useEmotionTheme } from '../../core/styled';

interface Props {
  height?: number;
  width?: number;
  style?: EmotionStyle;
}
const SparklingAudioPlus = ({ height = 102, width = 120, style }: Props) => {
  const titleText = 'Sparkling Audio';
  const { colors } = useEmotionTheme();
  return (
    <Svg
      title={titleText}
      width={width}
      height={height}
      viewBox="0 0 120 102"
      fill="none"
      style={style}
    >
      <Ellipse cx="61.1764" cy="51" rx="50.9804" ry="51" fill={colors.a11yLinkWaterGrey} />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M114.118 16.8692C113.252 16.8692 112.549 17.5718 112.549 18.4385C112.549 19.3051 113.252 20.0077 114.118 20.0077C114.984 20.0077 115.687 19.3051 115.687 18.4385C115.687 17.5718 114.984 16.8692 114.118 16.8692ZM108.628 18.4385C108.628 15.4052 111.086 12.9462 114.118 12.9462C117.15 12.9462 119.608 15.4052 119.608 18.4385C119.608 21.4718 117.15 23.9308 114.118 23.9308C111.086 23.9308 108.628 21.4718 108.628 18.4385Z"
        fill={colors.permaMalibu}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.5 80.6738C23.4743 80.6738 22.6429 81.5056 22.6429 82.5317C22.6429 83.5577 23.4743 84.3895 24.5 84.3895C25.5257 84.3895 26.3571 83.5577 26.3571 82.5317C26.3571 81.5056 25.5257 80.6738 24.5 80.6738ZM18 82.5317C18 78.9404 20.9101 76.0292 24.5 76.0292C28.0899 76.0292 31 78.9404 31 82.5317C31 86.1229 28.0899 89.0342 24.5 89.0342C20.9101 89.0342 18 86.1229 18 82.5317Z"
        fill={colors.permaZiggurat}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.62745 29.0308C6.46163 29.0308 4.70588 30.7872 4.70588 32.9539C4.70588 35.1205 6.46163 36.877 8.62745 36.877C10.7933 36.877 12.549 35.1205 12.549 32.9539C12.549 30.7872 10.7933 29.0308 8.62745 29.0308ZM0 32.9539C0 28.1872 3.86264 24.3231 8.62745 24.3231C13.3923 24.3231 17.2549 28.1872 17.2549 32.9539C17.2549 37.7205 13.3923 41.5847 8.62745 41.5847C3.86264 41.5847 0 37.7205 0 32.9539Z"
        fill={colors.permaLavenderBlue}
      />
      <Path
        d="M32.7673 37.5992C32.617 33.2909 35.9876 29.6763 40.296 29.5258L59.4671 28.856C63.7752 28.7055 67.3896 32.0758 67.5403 36.3839L68.2114 55.5724C68.3621 59.8809 64.9914 63.4959 60.6828 63.6464L41.5104 64.3162C37.2021 64.4667 33.5876 61.0961 33.4372 56.7877L32.7673 37.5992Z"
        fill="white"
        stroke={colors.periwinkleGrey}
        strokeWidth="5.2038"
      />
      <Path
        d="M55.5663 37.9667C55.6813 36.4707 56.9868 35.3513 58.4823 35.4665L58.5553 35.4721C60.0508 35.5872 61.1699 36.8933 61.0549 38.3893L60.8385 41.2044L76.7731 42.4312L76.9896 39.616C77.1046 38.1201 78.4101 37.0007 79.9056 37.1158L79.9786 37.1214C81.4741 37.2366 82.5932 38.5426 82.4782 40.0386L82.2577 42.907C82.2527 42.9711 82.2456 43.0346 82.2364 43.0973C85.3353 44.1759 87.4459 47.2371 87.1824 50.6636L85.5991 71.2593C85.2854 75.3392 81.7248 78.3921 77.6462 78.0781L54.5269 76.2982C50.4483 75.9842 47.3963 72.4222 47.7099 68.3423L49.2933 47.7466C49.5567 44.3201 52.1104 41.618 55.3377 41.0264C55.3382 40.963 55.3408 40.8992 55.3457 40.8351L55.5663 37.9667Z"
        fill={colors.a11yRoyalBlue}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.8838 50.69C63.5619 50.2719 63.984 50.128 64.6936 50.065C65.8836 49.8627 67.7291 54.1202 66.7142 55.1003C66.3324 55.4825 65.8699 55.3881 65.0256 55.6758C64.6483 58.2924 67.109 62.95 69.4845 64.1323C70.1132 63.5928 70.3782 63.2017 70.8587 63.0623C72.2868 62.8195 74.6846 66.7668 73.8538 67.6435C73.3463 68.1335 73.032 68.4033 72.3 68.7585C66.6057 71.7888 57.1356 53.6574 62.8838 50.69Z"
        fill="white"
      />
      <Path
        d="M94 76.0684C94 81.5934 89.5228 86.0723 84 86.0723C78.4772 86.0723 74 81.5934 74 76.0684C74 70.5434 78.4772 66.0646 84 66.0646C89.5228 66.0646 94 70.5434 94 76.0684Z"
        fill="white"
      />
      <Path
        d="M84 66.0646C82.0222 66.0646 80.0888 66.6513 78.4443 67.7505C76.7998 68.8498 75.5181 70.4121 74.7612 72.2401C74.0043 74.0681 73.8063 76.0795 74.1922 78.0201C74.578 79.9606 75.5304 81.7431 76.9289 83.1422C78.3275 84.5413 80.1093 85.494 82.0491 85.88C83.9889 86.266 85.9996 86.0679 87.8268 85.3108C89.6541 84.5536 91.2159 83.2714 92.3147 81.6263C93.4135 79.9811 94 78.047 94 76.0684C94 74.7547 93.7413 73.4538 93.2388 72.2401C92.7362 71.0264 91.9997 69.9236 91.0711 68.9946C90.1425 68.0657 89.0401 67.3288 87.8268 66.8261C86.6136 66.3233 85.3132 66.0646 84 66.0646ZM87 77.0688H85V79.0696C85 79.3349 84.8946 79.5893 84.7071 79.7769C84.5196 79.9646 84.2652 80.07 84 80.07C83.7348 80.07 83.4804 79.9646 83.2929 79.7769C83.1054 79.5893 83 79.3349 83 79.0696V77.0688H81C80.7348 77.0688 80.4804 76.9634 80.2929 76.7758C80.1054 76.5882 80 76.3337 80 76.0684C80 75.8031 80.1054 75.5486 80.2929 75.361C80.4804 75.1734 80.7348 75.068 81 75.068H83V73.0673C83 72.8019 83.1054 72.5475 83.2929 72.3599C83.4804 72.1723 83.7348 72.0669 84 72.0669C84.2652 72.0669 84.5196 72.1723 84.7071 72.3599C84.8946 72.5475 85 72.8019 85 73.0673V75.068H87C87.2652 75.068 87.5196 75.1734 87.7071 75.361C87.8946 75.5486 88 75.8031 88 76.0684C88 76.3337 87.8946 76.5882 87.7071 76.7758C87.5196 76.9634 87.2652 77.0688 87 77.0688Z"
        fill={colors.permaHighlightGreen}
      />
    </Svg>
  );
};

export default SparklingAudioPlus;
