import { FunctionComponent } from 'react';
import Svg, { G, Rect, Path } from 'svgs';
import { useEmotionTheme, EmotionStyle } from '../../core/styled';

interface Props {
  size?: number;
  color?: string;
  style?: EmotionStyle;
}

const XCheckbox: FunctionComponent<Props> = ({ size = 21, color, ...otherProps }) => {
  const { colors } = useEmotionTheme();
  const titleText = 'X Checkbox';

  return (
    <Svg
      width={size}
      height={size}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 23 23"
      {...otherProps}
    >
      <G transform="translate(1 1)" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Rect
          stroke={colors.slateGrey}
          fill={colors.slateGrey}
          x={0}
          y={0}
          width={21}
          height={21}
          rx={5}
        />
        <G fill="#FFF">
          <Path
            d="M6.364-1.136a1.5 1.5 0 011.5 1.5v12a1.5 1.5 0 11-3 0v-12a1.5 1.5 0 011.5-1.5z"
            transform="translate(4.136 4.136) rotate(45 6.364 6.364)"
          />
          <Path
            d="M6.364-1.136a1.5 1.5 0 011.5 1.5v12a1.5 1.5 0 11-3 0v-12a1.5 1.5 0 011.5-1.5z"
            transform="translate(4.136 4.136) rotate(-45 6.364 6.364)"
          />
        </G>
      </G>
    </Svg>
  );
};

export default XCheckbox;
