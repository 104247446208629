import { useQuery } from 'react-query';

import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';
import { therapistTimeslotsQueryKey } from '../utils/queryKeys';
import { TherapistTimeslotsAPIResponse, TimeSlotRange } from '../types';

const getTherapistTimeSlots =
  ({
    therapistUserID,
    duration,
    range,
    roomID,
    enforcePreferencesForUserID,
  }: {
    therapistUserID: number;
    duration: number;
    range: TimeSlotRange;
    roomID?: number;
    enforcePreferencesForUserID?: number;
  }) =>
  (): Promise<TherapistTimeslotsAPIResponse> =>
    apiWrapper
      .get<{ data: TherapistTimeslotsAPIResponse }>(
        `${
          apiHelper().apiEndpoint
        }/v2/therapist/${therapistUserID}/timeslots?length=${duration} minutes&from=${
          range.from
        } day&to=${range.to} day&roomID=${roomID}${
          enforcePreferencesForUserID
            ? `&enforcePreferencesForUserID=${enforcePreferencesForUserID}`
            : ''
        }`
      )
      .then((res) => res.data.data);

const useQueryTherapistTimeslots = ({
  therapistUserID,
  duration,
  range,
  roomID = 0,
  enforcePreferencesForUserID,
}: {
  therapistUserID: number;
  duration: number;
  range: TimeSlotRange;
  roomID?: number;
  enforcePreferencesForUserID?: number;
}) =>
  useQuery<TherapistTimeslotsAPIResponse, Error>({
    queryKey: therapistTimeslotsQueryKey({
      therapistUserID,
      duration,
      range,
      roomID,
    }),
    queryFn: getTherapistTimeSlots({
      therapistUserID,
      duration,
      range,
      roomID,
      enforcePreferencesForUserID,
    }),
    enabled: !!roomID && !!duration,
  });

export default useQueryTherapistTimeslots;
