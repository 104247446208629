import moment from 'moment';

export interface RoomQueryString {
  include: string[];
  filterFromDate: string | null;
  filterToDate: string | null;
}

const getRoomsQueryString = (params: RoomQueryString) => {
  const { include, filterFromDate, filterToDate } = params;
  const searchParams = new URLSearchParams({
    include: include.join(','),
    fromDate: filterFromDate ? moment(filterFromDate).toISOString() : '',
    toDate: filterToDate ? moment(filterToDate).toISOString() : '',
  });

  return searchParams.toString();
};

export default getRoomsQueryString;
