import { useEffect } from 'react';
import { getIsIonic } from '../ionicUtils';

// eslint-disable-next-line import/prefer-default-export
export const useIonicEffect: typeof useEffect = (effect, deps) => {
  useEffect(() => {
    if (getIsIonic()) return effect();
    return undefined;
    // These are external dependencies that we cannot statically type
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
