import { styled, TextDS, View } from '@talkspace/react-toolkit';

import { fontWeight } from '@talkspace/react-toolkit/src/designSystems/tokens';
import { AutomaticMessageDataTag } from '../../../../types/account';

const TooltipView = styled(View)<{ visible: boolean }>(({ visible, theme: { spacing } }) => {
  return {
    boxShadow: ' 0px 7px 16px rgba(0, 29, 47, 0.3875)',
    visibility: visible ? 'visible' : 'hidden',
    position: 'absolute',
    bottom: '100%',
    left: '32%',
    transform: 'translateX(-50%)',
    borderRadius: spacing('space100'),
    zIndex: 1,
    marginBottom: spacing('space025'),
    width: 256,
  };
});

const TooltipContent = styled(View)(({ theme: { colors, spacing } }) => {
  return {
    backgroundColor: colors.white,
    padding: spacing('space200'),
    gap: spacing('space050'),
  };
});

interface Props {
  isVisible: boolean;
  dataTag?: AutomaticMessageDataTag;
}

export default function DataTagTooltip({ isVisible, dataTag }: Props) {
  if (!dataTag) {
    return null;
  }

  const { displayName, description, example, notes } = dataTag;

  return (
    <TooltipView visible={isVisible}>
      <TooltipContent>
        <TextDS variant="headingSm">{displayName}</TextDS>
        <TextDS variant="bodyXs">
          {description}{' '}
          {notes && (
            <TextDS
              as="span"
              variant="bodyXs"
              colorRole="textCriticalDefault"
              style={{ fontWeight: fontWeight.bold }}
            >
              {notes}
            </TextDS>
          )}
        </TextDS>
        <TextDS variant="bodyXs" style={{ fontStyle: 'italic' }}>
          Example: "{example}"
        </TextDS>
      </TooltipContent>
    </TooltipView>
  );
}
