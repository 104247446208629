import { FunctionComponent } from 'react';
import Svg, { Path } from 'svgs';
import { useEmotionTheme, EmotionStyle } from '../../core/styled';

interface RoundFilledCheckmarkProps {
  color?: string;
  width?: number;
  height?: number;
  style?: EmotionStyle;
}

const RoundFilledCheckmark: FunctionComponent<RoundFilledCheckmarkProps> = ({
  width = 20,
  height = 20,
  color,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'check mark';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <Path
        d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10Z"
        fill={color || colors.Limeade}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7722 6.02682C14.3556 6.39591 14.5293 7.16806 14.1603 7.75147L9.569 15.0086L5.48197 11.8255C4.93732 11.4013 4.83968 10.6159 5.26388 10.0712C5.68808 9.52656 6.47349 9.42891 7.01814 9.85311L8.9302 11.3423L12.0476 6.41487C12.4166 5.83146 13.1888 5.65773 13.7722 6.02682Z"
        fill="white"
      />
    </Svg>
  );
};

export default RoundFilledCheckmark;
