import { FunctionComponent } from 'react';
import moment from 'moment';

import {
  View,
  Big,
  Large,
  Standard,
  StickyDrawer,
  Button,
  useThemeVersion,
  RotateRightIcon,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import TabRadioGroup from '@talkspace/react-toolkit/src/designSystems/components/TabRadioGroup';
import { Booking, VideoCreditOffer } from 'ts-frontend/types';
import { ERoom } from 'ts-frontend/entities/Room';
import { withRouter, RouteComponentProps } from '@/core/routerLib';
import appConfigs from '@/utils/configs';
import ReactFrameService from '@/auth/reactFrame/ReactFrameService';

import { ErrorXSmallIcon } from '../icons';
import { TherapistInfo } from '../../types';

import { CancelActionCard, AddToCalendarActionCard } from './ActionCards';
import AvatarRow from './CancelBookingAvatarRow';
import { getLateCancellationText } from './cancelBookingUtils';

interface Props extends RouteComponentProps<{ roomID: string; bookingID: string }> {
  therapist: TherapistInfo;
  isTherapist: boolean;
  selectedCancelBooking?: Booking;
  isBookingDeclinedByTherapist?: boolean;
  room?: ERoom;
  isBookingConfirmed?: boolean;
  isBookingInLessThan24hrs: boolean;
  checkedPendingInvoices: boolean;
  canDeclineOrCancel: 'decline' | 'cancel';
  selectedAction: string;
  selectedCreditOption?: VideoCreditOffer;
  hideAddToCalendar?: boolean;
  handleAddToCalendarPress: () => void;
  handleReschedulePress: () => void;
  handleCancelPress: () => void;
  handleContinuePress: () => void;
}

const StickyBottomContainer = ({
  children,
  isSticky,
}: {
  children: React.ReactNode;
  isSticky?: boolean;
}) => {
  if (isSticky) {
    return <StickyDrawer>{children}</StickyDrawer>;
  }
  return <View style={{ marginTop: 40, width: '100%' }}>{children}</View>;
};

const CancelBooking: FunctionComponent<Props> = ({
  therapist,
  isTherapist,
  selectedCancelBooking,
  isBookingDeclinedByTherapist,
  room,
  isBookingConfirmed,
  isBookingInLessThan24hrs,
  checkedPendingInvoices,
  canDeclineOrCancel,
  selectedAction,
  hideAddToCalendar,
  selectedCreditOption,
  handleAddToCalendarPress,
  handleReschedulePress,
  handleCancelPress,
  handleContinuePress,
}) => {
  const isiOS = ReactFrameService.isiOS();
  const isAndroid = ReactFrameService.isAndroid();

  const { major: themeVersion } = useThemeVersion();
  const showStickyCTA = themeVersion >= 1;
  const { colorRoles } = useEmotionTheme();
  return (
    <View flex={1} align="center" justify="space-between">
      <View align="center">
        <AvatarRow therapistUserID={therapist.id} />
        <>
          <Big style={{ marginTop: 16, color: colorRoles.typography.textDefault }}>
            {moment(selectedCancelBooking?.startTime).format('dddd, MMMM D')}
          </Big>
          <Standard
            style={{ marginTop: 4, marginBottom: 14, color: colorRoles.typography.textSubtlest }}
          >
            {moment(selectedCancelBooking?.startTime).format('h:mm A')} {' - '}
            {moment(selectedCancelBooking?.startTime)
              .add(selectedCancelBooking?.creditMinutes, 'minutes')
              .format('h:mm A')}
          </Standard>
        </>

        {isBookingDeclinedByTherapist ? (
          <CancelActionCard
            Icon={ErrorXSmallIcon}
            text="Dismiss session"
            onPress={() => {}}
            isSelected
            dataQAText="Dismiss"
          />
        ) : (
          <>
            {room && isBookingConfirmed && isBookingInLessThan24hrs && (
              <Large
                variant="largeDarkGrey"
                style={{ width: 278, textAlign: 'center', marginBottom: 40 }}
              >
                {getLateCancellationText(room)}
              </Large>
            )}
            <TabRadioGroup legendText="Choose to reschedule or cancel this session.">
              {selectedCreditOption && (
                <CancelActionCard
                  Icon={RotateRightIcon}
                  text="Reschedule session"
                  onPress={handleReschedulePress}
                  isSelected={selectedAction === 'reschedule'}
                  dataQAText="Reschedule"
                />
              )}
              <CancelActionCard
                Icon={ErrorXSmallIcon}
                text={canDeclineOrCancel === 'decline' ? 'Decline session' : 'Cancel session'}
                onPress={handleCancelPress}
                isSelected={selectedAction === 'cancel'}
                dataQAText="Cancel"
              />
            </TabRadioGroup>
            {appConfigs.featureFlags.addToCalendar &&
              !hideAddToCalendar &&
              !isTherapist &&
              !isiOS &&
              !isAndroid &&
              canDeclineOrCancel === 'cancel' && (
                <AddToCalendarActionCard
                  text="Add to calendar"
                  onPress={handleAddToCalendarPress}
                  isSelected={selectedAction === 'add_to_calendar'}
                />
              )}
          </>
        )}
      </View>
      <StickyBottomContainer isSticky={showStickyCTA}>
        <Button
          onPress={handleContinuePress}
          stretch
          text={isBookingDeclinedByTherapist ? 'Dismiss' : 'Continue'}
          dataQa="schedulerChangeBookingContinue"
          disabled={!checkedPendingInvoices}
          isLoading={!checkedPendingInvoices}
        />
      </StickyBottomContainer>
    </View>
  );
};

export default withRouter(CancelBooking);
