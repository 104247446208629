import { FunctionComponent, useEffect, useState } from 'react';
import {
  Small,
  Checkbox,
  styled,
  Standard,
  TouchableView,
  OptionType,
  View,
  Select,
  TextAreaRounded,
  useEmotionTheme,
  AccordionContainer,
  ExtraTiny,
} from '@talkspace/react-toolkit';
import { connect } from 'react-redux';
import { clientProgress } from '@talkspace/configs';
import * as treatmentPlannerActions from 'actions/TreatmentPlannerActions';
import useQueryProgressNote from 'hooks/notes/useQueryProgressNote';
import useMutationSaveDraftProgressNote from 'hooks/notes/useMutationSaveDraftProgressNote';
import useMutationUpdateProgressNote from 'hooks/notes/useMutationUpdateProgressNote';
import useQueryProgressNoteHelperData from 'hooks/notes/useQueryProgressNoteHelperData';
import useQueryRoomDetails from 'ts-frontend/hooks/useQueryRoomDetails';
import { useHistory, useParams } from '@/core/routerLib';
import {
  useProgressNoteFormActions,
  useProgressNoteFormState,
} from '../context/PsychProgressNoteFormContext';
import PencilIcon from './PencilIcon';
import {
  ClientProgress,
  PresentingProblem,
  TreatmentPlanGoal,
  TreatmentPlanIntervention,
  TreatmentPlanObjective,
} from '../../types';
import { ProgressNoteFormState } from '../context/types';
import ConfirmButton from './ConfirmButton';
import { getSelectStyle, getTextAreaContainerBorderStyle, getTextAreaStyle } from '../../utils';

const transformConfigToOptions = (configsValue: Record<string, string>): Array<OptionType> =>
  Object.entries(configsValue).map((it) => {
    const [value, label] = it;
    return { value, label };
  });

const clientProgressOptions = transformConfigToOptions(clientProgress);

const SelectContainer = styled(View)(({ theme: { colors } }) => {
  return {
    marginLeft: 26,
    marginBottom: 25,
    paddingLeft: 15,
    borderLeftColor: colors.permaAntiFlashWhite,
    borderLeftWidth: 4,
    borderLeftStyle: 'solid',
  };
});

const EditButton: FunctionComponent<{ title: string; onPress: () => void; dataQa: string }> = ({
  title,
  onPress,
  dataQa,
}) => (
  <TouchableView style={{ flexDirection: 'row', marginLeft: 26 }} onPress={onPress} dataQa={dataQa}>
    <PencilIcon />
    <Standard variant="standardBoldGreen" style={{ marginLeft: 8, marginBottom: 32 }}>
      {title}
    </Standard>
  </TouchableView>
);

interface Props {
  currentTreatmentPlanID: number;
  goalsByTreatmentPlan?: Array<TreatmentPlanGoal>;
  objectivesByTreatmentPlan?: Array<TreatmentPlanObjective>;
  interventionsByTreatmentPlan?: Array<TreatmentPlanIntervention>;
  currentPresentingProblems?: Array<PresentingProblem>;
  getGoalsByTreatmentPlan?(treatmentPlanID: number): void;
  getObjectivesByTreatmentPlan?(treatmentPlanID: number): void;
  getInterventionsByTreatmentPlan?(treatmentPlanID: number): void;
}

const ExistingTreatmentPlan: FunctionComponent<Props> = ({
  currentTreatmentPlanID,
  goalsByTreatmentPlan,
  objectivesByTreatmentPlan,
  interventionsByTreatmentPlan,
  currentPresentingProblems,
  getGoalsByTreatmentPlan,
  getObjectivesByTreatmentPlan,
  getInterventionsByTreatmentPlan,
}) => {
  const formState = useProgressNoteFormState();

  const [treatmentPlanProgress, setTreatmentPlanProgress] = useState<
    ProgressNoteFormState['treatmentPlanProgress']
  >(formState.treatmentPlanProgress);
  const [treatmentPlanGoals, setTreatmentPlanGoals] = useState<
    ProgressNoteFormState['treatmentPlanGoals']
  >(formState.treatmentPlanGoals);
  const [treatmentPlanObjectives, setTreatmentPlanObjectives] = useState<
    ProgressNoteFormState['treatmentPlanObjectives']
  >(formState.treatmentPlanObjectives);
  const [treatmentPlanInterventions, setTreatmentPlanInterventions] = useState<
    ProgressNoteFormState['treatmentPlanInterventions']
  >(formState.treatmentPlanInterventions);

  const [riskOrBarriersHandlingError, setRiskOrBarriersHandlingError] = useState(false);
  const [invalidTreatmentPlanGoalIDs, setInvalidTreatmentPlanGoalIDs] = useState<Array<number>>([]);
  const [invalidTreatmentPlanObjectiveIDs, setInvalidTreatmentPlanObjectiveIDs] = useState<
    Array<number>
  >([]);

  const { setFormState } = useProgressNoteFormActions();
  const history = useHistory();
  const { roomID, noteID } = useParams<{ roomID: string; noteID?: string }>();
  const { colors } = useEmotionTheme();
  const { data: { clientUserID } = {} } = useQueryRoomDetails(roomID);
  const { data: progressNoteData } = useQueryProgressNote(clientUserID, noteID);
  const { mutate: saveDraftProgressNote } = useMutationSaveDraftProgressNote();
  const { mutate: updateProgressNote } = useMutationUpdateProgressNote();
  const { data: progressNoteHelperData } = useQueryProgressNoteHelperData(roomID);

  useEffect(() => {
    getGoalsByTreatmentPlan && getGoalsByTreatmentPlan(currentTreatmentPlanID);
    getObjectivesByTreatmentPlan && getObjectivesByTreatmentPlan(currentTreatmentPlanID);
    getInterventionsByTreatmentPlan && getInterventionsByTreatmentPlan(currentTreatmentPlanID);
  }, [
    currentTreatmentPlanID,
    getGoalsByTreatmentPlan,
    getInterventionsByTreatmentPlan,
    getObjectivesByTreatmentPlan,
  ]);

  useEffect(() => {
    if (progressNoteData?.status !== 'submitted') {
      setFormState({
        treatmentPlanID: currentTreatmentPlanID,
        presentingProblems: currentPresentingProblems || [],
      });
    }
  }, [currentPresentingProblems, currentTreatmentPlanID, progressNoteData, setFormState]);

  const markSectionAsIncomplete = () => {
    if (formState.formSections.treatmentPlanProgress.completed) {
      setFormState({
        formSections: {
          ...formState.formSections,
          treatmentPlanProgress: {
            open: formState.formSections.treatmentPlanProgress.open,
            changed: true,
            completed: false,
          },
        },
      });
    }
  };

  const handleNavigationToCRM = (url: string) => {
    const { showGlobalError, formMode, ...restFormState } = formState;
    const params = { roomID, noteID, clientUserID, ...restFormState };

    if (formMode === 'create') {
      const saveProgressNoteDialogLocationState = {
        action: {
          type: 'navigate-to-treatment-planner',
          payload: currentTreatmentPlanID,
        },
      };

      history.push(
        `/room/${roomID}/progress-notes/new/save-draft-dialog?postAction=notes`,
        saveProgressNoteDialogLocationState
      );

      return;
    }

    if (formMode === 'edit') {
      if (progressNoteData?.status === 'draft') {
        saveDraftProgressNote(params, {
          onSuccess: () => {
            history.push(url);
          },
        });

        return;
      }

      if (noteID && progressNoteData?.status === 'submitted') {
        updateProgressNote(
          { ...params, noteID, clientUserID },
          {
            onSuccess: () => {
              history.push(url);
            },
          }
        );
      }
    }
  };

  const handleEditGoalsPress = () => {
    handleNavigationToCRM(`/room/${roomID}/treatment-planner-goals?postAction=notes`);
  };

  const handleEditObjectivesPress = () => {
    handleNavigationToCRM(`/room/${roomID}/treatment-planner-objectives?postAction=notes`);
  };

  const handleGoalCheck = (checked: boolean, goalID: number) => {
    markSectionAsIncomplete();

    if (checked) {
      const overallProgress =
        (formState.formMode === 'create' &&
          progressNoteHelperData?.latestGoalsProgress?.find(
            (it) => it.treatmentPlanGoalID === goalID
          )?.overallProgress) ||
        null;

      setTreatmentPlanGoals([
        ...treatmentPlanGoals,
        { treatmentPlanGoalID: goalID, overallProgress },
      ]);
    } else {
      setTreatmentPlanGoals(treatmentPlanGoals.filter((it) => it.treatmentPlanGoalID !== goalID));
    }
  };

  const handleObjectiveCheck = (checked: boolean, objectiveID: number) => {
    markSectionAsIncomplete();

    if (checked) {
      const overallProgress =
        (formState.formMode === 'create' &&
          progressNoteHelperData?.latestObjectivesProgress?.find(
            (it) => it.treatmentPlanObjectiveID === objectiveID
          )?.overallProgress) ||
        null;

      setTreatmentPlanObjectives([
        ...treatmentPlanObjectives,
        { treatmentPlanObjectiveID: objectiveID, overallProgress },
      ]);
    } else {
      setTreatmentPlanObjectives(
        treatmentPlanObjectives.filter((it) => it.treatmentPlanObjectiveID !== objectiveID)
      );
    }
  };

  const handleInterventionCheck = (
    checked: boolean,
    objectiveID: number,
    interventionID: number
  ) => {
    markSectionAsIncomplete();

    if (checked) {
      setTreatmentPlanInterventions([
        ...treatmentPlanInterventions,
        { treatmentPlanObjectiveID: objectiveID, treatmentPlanInterventionID: interventionID },
      ]);
    } else {
      setTreatmentPlanInterventions(
        treatmentPlanInterventions.filter(
          (it) =>
            it.treatmentPlanObjectiveID !== objectiveID ||
            it.treatmentPlanInterventionID !== interventionID
        )
      );
    }
  };

  const handleGoalProgressSelect = (
    option: { value: string; label: string } | null,
    goalID: number
  ) => {
    markSectionAsIncomplete();

    if (option === null) {
      setTreatmentPlanGoals([
        ...treatmentPlanGoals.filter((it) => it.treatmentPlanGoalID !== goalID),
      ]);
    } else {
      setInvalidTreatmentPlanGoalIDs(invalidTreatmentPlanGoalIDs.filter((it) => it !== goalID));
      setTreatmentPlanGoals([
        ...treatmentPlanGoals.filter((it) => it.treatmentPlanGoalID !== goalID),
        { treatmentPlanGoalID: goalID, overallProgress: option.value as ClientProgress },
      ]);
    }
  };

  const handleObjectiveProgressSelect = (
    option: { value: string; label: string } | null,
    objectiveID: number
  ) => {
    markSectionAsIncomplete();

    if (option === null) {
      setTreatmentPlanObjectives([
        ...treatmentPlanObjectives.filter((it) => it.treatmentPlanObjectiveID !== objectiveID),
      ]);
    } else {
      setInvalidTreatmentPlanObjectiveIDs(
        invalidTreatmentPlanObjectiveIDs.filter((it) => it !== objectiveID)
      );
      setTreatmentPlanObjectives([
        ...treatmentPlanObjectives.filter((it) => it.treatmentPlanObjectiveID !== objectiveID),
        {
          treatmentPlanObjectiveID: objectiveID,
          overallProgress: option.value as ClientProgress,
        },
      ]);
    }
  };

  const handleRiskOrBarriersHandlingChange = (value: string) => {
    markSectionAsIncomplete();
    setRiskOrBarriersHandlingError(false);

    if (treatmentPlanProgress) {
      setTreatmentPlanProgress({
        ...treatmentPlanProgress,
        riskOrBarriersHandling: value,
      });
    }
  };

  const handleAccordionPress = (value: boolean) => {
    setFormState({
      formSections: {
        ...formState.formSections,
        treatmentPlanProgress: {
          open: value,
          changed: formState.formSections.treatmentPlanProgress.changed,
          completed: formState.formSections.treatmentPlanProgress.completed,
        },
      },
    });
  };

  const handleConfirmPress = () => {
    let isError = false;

    if (!treatmentPlanProgress?.riskOrBarriersHandling) {
      isError = true;
      setRiskOrBarriersHandlingError(true);
    }

    const treatmentPlanGoalsWithoutProgress = treatmentPlanGoals
      .filter((it) => !it.overallProgress)
      .map((it) => it.treatmentPlanGoalID);

    if (treatmentPlanGoalsWithoutProgress.length > 0) {
      isError = true;
    }

    setInvalidTreatmentPlanGoalIDs(treatmentPlanGoalsWithoutProgress);

    const treatmentPlanObjectivesWithoutProgress = treatmentPlanObjectives
      .filter((it) => !it.overallProgress)
      .map((it) => it.treatmentPlanObjectiveID);

    if (treatmentPlanObjectivesWithoutProgress.length > 0) {
      isError = true;
    }

    setInvalidTreatmentPlanObjectiveIDs(treatmentPlanObjectivesWithoutProgress);

    if (isError) {
      setFormState({ showGlobalError: true });
      return;
    }

    setFormState({
      treatmentPlanProgress,
      treatmentPlanGoals,
      treatmentPlanObjectives,
      treatmentPlanInterventions,
      formSections: {
        ...formState.formSections,
        treatmentPlanProgress: {
          open: false,
          changed: true,
          completed: true,
        },
        ...(formState.formSections.sessionSummary.completed
          ? {}
          : {
              sessionSummary: {
                ...formState.formSections.sessionSummary,
                open: true,
              },
            }),
      },
    });
  };

  const { formMode } = formState;

  const textAreaStyle = getTextAreaStyle(formMode === 'view');

  let goals: Array<TreatmentPlanGoal> = goalsByTreatmentPlan || [];
  let objectives: Array<TreatmentPlanObjective> = objectivesByTreatmentPlan || [];
  let interventions: Array<TreatmentPlanIntervention> = interventionsByTreatmentPlan || [];

  if (progressNoteData?.status === 'submitted' && progressNoteData) {
    goals = [
      ...progressNoteData.treatmentPlanGoals.map((it) => {
        return {
          id: it.treatmentPlanGoal?.id,
          description: it.treatmentPlanGoal?.description,
          presentingProblemID: it.treatmentPlanGoal?.presentingProblemID,
        };
      }),
      ...goals.filter(
        (goalFromTreatmentPlan) =>
          !progressNoteData.treatmentPlanGoals.some(
            (goalFromProgressNote) =>
              goalFromProgressNote.treatmentPlanGoalID === goalFromTreatmentPlan.id
          )
      ),
    ];

    objectives = [
      ...progressNoteData.treatmentPlanObjectives.map((it) => {
        return {
          id: it.treatmentPlanObjective?.id,
          description: it.treatmentPlanObjective?.description,
          presentingProblemID: it.treatmentPlanObjective?.presentingProblemID,
        };
      }),
      ...objectives.filter(
        (objectiveFromTreatmentPlan) =>
          !progressNoteData.treatmentPlanObjectives.some(
            (objectiveFromProgressNote) =>
              objectiveFromProgressNote.treatmentPlanObjectiveID === objectiveFromTreatmentPlan.id
          )
      ),
    ];

    interventions = [
      ...progressNoteData.treatmentPlanInterventions.map((it) => {
        return {
          id: it.treatmentPlanIntervention?.id,
          description: it.treatmentPlanIntervention?.description,
          presentingProblemID: it.treatmentPlanIntervention?.presentingProblemID,
          objectiveID: it.treatmentPlanIntervention?.objectiveID,
        };
      }),
      ...interventions.filter(
        (interventionFromTreatmentPlan) =>
          !progressNoteData.treatmentPlanInterventions.some(
            (interventionFromProgressNote) =>
              interventionFromProgressNote.treatmentPlanInterventionID ===
              interventionFromTreatmentPlan.id
          )
      ),
    ];
  }

  return (
    <AccordionContainer
      title="Treatment plan progress"
      open={formState.formSections.treatmentPlanProgress.open}
      onPress={handleAccordionPress}
      showCheckMark={formMode !== 'view' && formState.formSections.treatmentPlanProgress.completed}
      dataQa="existingTreatmentPlanAccordion"
    >
      <Small style={{ marginBottom: 26 }}>
        Identify the goal(s) and objective(s) from the treatment plan that apply to this session.
      </Small>

      {formState.presentingProblems.map((problem) => (
        <View key={problem.id}>
          <ExtraTiny style={{ marginBottom: 12 }}>{problem.text} goals</ExtraTiny>
          {goals
            .filter((it) => it.presentingProblemID === problem.id)
            .map((goal, index) => {
              const selectedGoal = treatmentPlanGoals.find(
                (it) => it.treatmentPlanGoalID === goal.id
              );
              return (
                <View key={goal.id}>
                  <Checkbox
                    isLabelOnRight
                    stretch={false}
                    alignCenter={false}
                    isChecked={Boolean(selectedGoal)}
                    setIsChecked={(value) => handleGoalCheck(value, goal.id)}
                    label={goal.description}
                    isDisabled={formMode === 'view'}
                    dataQa={`goalCheckbox-${index}`}
                  />
                  {selectedGoal && (
                    <SelectContainer>
                      <Select
                        value={clientProgressOptions.find(
                          (it) => it.value === selectedGoal.overallProgress
                        )}
                        placeholder="Overall progress towards goal"
                        onChange={(value) => handleGoalProgressSelect(value as any, goal.id)}
                        options={clientProgressOptions}
                        isDisabled={formMode === 'view'}
                        styles={getSelectStyle(
                          colors,
                          invalidTreatmentPlanGoalIDs.includes(goal.id)
                        )}
                        dataQa={`overallProgressTowardsGoalDropdown-${index}`}
                      />
                    </SelectContainer>
                  )}
                </View>
              );
            })}
        </View>
      ))}
      {formMode !== 'view' && (
        <EditButton title="Edit goals" onPress={handleEditGoalsPress} dataQa="editGoalsButton" />
      )}

      {formState.presentingProblems?.map((problem) => (
        <View key={problem.id}>
          <ExtraTiny style={{ marginBottom: 12 }}>{problem.text} objectives</ExtraTiny>
          {objectives
            .filter((it) => it.presentingProblemID === problem.id)
            .map((objective, index) => {
              const selectedObjective = treatmentPlanObjectives.find(
                (it) => it.treatmentPlanObjectiveID === objective.id
              );
              return (
                <View key={objective.id}>
                  <Checkbox
                    isLabelOnRight
                    stretch={false}
                    alignCenter={false}
                    isChecked={Boolean(selectedObjective)}
                    setIsChecked={(value) => handleObjectiveCheck(value, objective.id)}
                    label={objective.description}
                    isDisabled={formMode === 'view'}
                    dataQa={`objectiveCheckbox-${index}`}
                  />
                  {selectedObjective && (
                    <SelectContainer>
                      <Select
                        value={clientProgressOptions.find(
                          (it) => it.value === selectedObjective.overallProgress
                        )}
                        placeholder="Overall progress on objective"
                        onChange={(value) =>
                          handleObjectiveProgressSelect(value as any, objective.id)
                        }
                        options={clientProgressOptions}
                        isDisabled={formMode === 'view'}
                        styles={getSelectStyle(
                          colors,
                          invalidTreatmentPlanObjectiveIDs.includes(objective.id)
                        )}
                        dataQa={`overallProgressOnObjectiveDropdown-${index}`}
                      />
                      <ExtraTiny style={{ marginTop: 14, marginBottom: 12 }}>
                        Intervention(s) used
                      </ExtraTiny>

                      {interventions
                        .filter((it) => it.objectiveID === objective.id)
                        .map((intervention, interventionIndex) => (
                          <Checkbox
                            key={intervention.id}
                            isLabelOnRight
                            stretch={false}
                            alignCenter={false}
                            isChecked={
                              treatmentPlanInterventions.filter(
                                (it) =>
                                  it.treatmentPlanObjectiveID === objective.id &&
                                  it.treatmentPlanInterventionID === intervention.id
                              ).length > 0
                            }
                            setIsChecked={(value) =>
                              handleInterventionCheck(value, objective.id, intervention.id)
                            }
                            label={intervention.description}
                            isDisabled={formMode === 'view'}
                            dataQa={`interventionCheckbox-${interventionIndex}`}
                          />
                        ))}
                    </SelectContainer>
                  )}
                </View>
              );
            })}
        </View>
      ))}
      {formMode !== 'view' && (
        <EditButton
          title="Edit objectives"
          onPress={handleEditObjectivesPress}
          dataQa="editObjectivesButton"
        />
      )}

      <Small style={{ marginBottom: 10 }}>
        Are there any risks or barriers to completing treatment?
      </Small>
      <TextAreaRounded
        autoSize
        value={treatmentPlanProgress?.riskOrBarriersHandling || ''}
        onChangeText={handleRiskOrBarriersHandlingChange}
        placeholder="Describe any risks or barriers and how to address them"
        textAreaStyle={textAreaStyle}
        style={getTextAreaContainerBorderStyle(colors, riskOrBarriersHandlingError)}
        disabled={formMode === 'view'}
        hasLimit
        dataQa="risksOrBarriersTextArea"
      />
      {formMode !== 'view' && !formState.formSections.treatmentPlanProgress.completed && (
        <ConfirmButton onPress={handleConfirmPress} sectionTitle="existingTreatmentPlan" />
      )}
    </AccordionContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    goalsByTreatmentPlan: state.treatmentPlanner.goals,
    objectivesByTreatmentPlan: state.treatmentPlanner.objectives,
    interventionsByTreatmentPlan: state.treatmentPlanner.interventions,
    currentPresentingProblems: state.treatmentPlanner.currentTreatmentPlan.presentingProblems,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getGoalsByTreatmentPlan: (treatmentPlanID: number) =>
      dispatch(treatmentPlannerActions.getGoals(treatmentPlanID)),
    getObjectivesByTreatmentPlan: (treatmentPlanID: number) =>
      dispatch(treatmentPlannerActions.getObjectives(treatmentPlanID)),
    getInterventionsByTreatmentPlan: (treatmentPlanID: number) =>
      dispatch(treatmentPlannerActions.getInterventions(treatmentPlanID)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExistingTreatmentPlan);
