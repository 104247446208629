import { RefObject, forwardRef } from 'react';
import styled, { EmotionStyle } from '../../core/styled';
import { webOnlyStyle } from '../../core/styleHelpers';
import TouchableView from '../TouchableView';
import { useShareForwardedRef } from '../../hooks/a11yHelper';

export interface SkipNavProps {
  onPress?: () => void;
  buttonText: string;
  returnID: string | undefined;
  style?: EmotionStyle;
  skipNavRef?: RefObject<HTMLDivElement>;
  dataQa?: string;
}

const SkipNavComponent = styled(TouchableView)({
  overflow: 'hidden',
  height: 1,
  width: 1,
  margin: -1,
  padding: 0,
  border: 0,
  zIndex: -1,
  ...webOnlyStyle({ clip: 'rect(0 0 0 0)' }),
  ':focus': {
    zIndex: 500,
    height: 'auto',
    width: 'auto',
    alignSelf: 'center',
    margin: 0,
    padding: 0,
    backgroundColor: '#fff',
    borderRadius: 3,
    textDecoration: 'none',
    color: 'black',
    textAlign: 'center',
  },
});

const SkipNavButton = forwardRef<
  HTMLDivElement,
  SkipNavProps & React.ComponentProps<typeof SkipNavComponent>
>((props, ref) => {
  const { onPress, buttonText, returnID, style, dataQa } = props;
  const skipNavRef = useShareForwardedRef(ref);
  // in most cases the SkipNav will be rendered in components wrapped in a focus trap. In these cases it is necessary to disable the trap before shifting focus outside
  const handlePress = (e) => {
    // preventDefault necessary to prevent a line from being added when the chat input is focused programmatically
    e.preventDefault();
    const returnElement = document.getElementById(returnID || '');
    if (returnElement && !onPress) {
      returnElement.focus();
    } else if (returnElement && onPress) {
      onPress();
      // slightly hacky but the focus shift doesn't occur without this- there is some sort of hiccup when the focus trap disables
      setTimeout(() => returnElement.focus(), 0);
    } else if (!returnElement && onPress) {
      onPress();
    }
  };
  return (
    <SkipNavComponent dataQa={dataQa} onPress={handlePress} style={style} ref={skipNavRef}>
      {buttonText}
    </SkipNavComponent>
  );
});

export default SkipNavButton;
