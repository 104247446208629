import * as types from '../constants/AvailabilityConstants';
import apiWrapper from '../utils/apiWrapper';
import apiHelper from '../utils/api';

function requestGetAvailability(userID) {
  return { type: types.REQUEST_GET_AVAILABILITY, userID };
}

function receiveGetAvailability(response) {
  return {
    type: types.RECEIVE_GET_AVAILABILITY,
    ...response.data,
  };
}

function requestGetAvailabilityError(error) {
  return {
    type: types.REQUEST_GET_AVAILABILITY_ERROR,
    error,
  };
}

function requestUpdateAvailability(userID, availability) {
  return { type: types.REQUEST_PUT_AVAILABILITY, userID, availability };
}

function receiveUpdateAvailability(availability) {
  return {
    type: types.RECEIVE_PUT_AVAILABILITY,
    availability,
  };
}

function requestUpdateAvailabilityError(error) {
  return {
    type: types.REQUEST_PUT_AVAILABILITY_ERROR,
    error,
  };
}

function requestGetVideoSessionHours() {
  return { type: types.REQUEST_GET_VIDEO_SESSION_HOURS };
}

function receiveGetVideoSessionHours(response) {
  return {
    type: types.RECEIVE_GET_VIDEO_SESSION_HOURS,
    ...response.data,
  };
}

function requestGetVideoSessionHoursError(error) {
  return {
    type: types.REQUEST_GET_VIDEO_SESSION_HOURS_ERROR,
    error,
  };
}

function requestSaveVideoSessionHours() {
  return { type: types.REQUEST_PUT_VIDEO_SESSION_HOURS };
}

function receiveSaveVideoSessionHours(constraints, response) {
  return {
    type: types.RECEIVE_PUT_VIDEO_SESSION_HOURS,
    constraints,
    ...response.data,
  };
}

function requestSaveVideoSessionHoursError(error) {
  return {
    type: types.REQUEST_PUT_VIDEO_SESSION_HOURS_ERROR,
    error,
  };
}

function requestGetClientCapacity() {
  return { type: types.REQUEST_GET_CAPACITY };
}

function receiveGetClientCapacity(response) {
  return {
    type: types.RECEIVE_GET_CAPACITY,
    ...response.data,
  };
}

function requestGetClientCapacityError(error) {
  return {
    type: types.REQUEST_GET_CAPACITY_ERROR,
    error,
  };
}

function requestSaveClientCapacity() {
  return { type: types.REQUEST_PUT_CAPACITY };
}

function receiveSaveClientCapacity(response) {
  return {
    type: types.RECEIVE_PUT_CAPACITY,
    ...response.data,
  };
}

function requestGetBusinessDays() {
  return {
    type: types.REQUEST_GET_BUSINESS_DAYS,
  };
}

function receiveGetBusinessDays(response) {
  return {
    type: types.RECEIVE_GET_BUSINESS_DAYS,
    ...response.data,
  };
}

function requestGetBusinessDaysError(error) {
  return {
    type: types.REQUEST_GET_BUSINESS_DAYS_ERROR,
    error,
  };
}

function requestSaveBusinessDays() {
  return {
    type: types.REQUEST_SAVE_BUSINESS_DAYS,
  };
}

function receiveSaveBusinessDays(response) {
  return {
    type: types.RECEIVE_SAVE_BUSINESS_DAYS,
    ...response.data,
  };
}

function requestSaveBusinessDaysError(error) {
  return { type: types.REQUEST_SAVE_BUSINESS_DAYS, error };
}

function requestSaveClientCapacityError(error) {
  return {
    type: types.REQUEST_PUT_CAPACITY_ERROR,
    error,
  };
}

function requestGetPhase() {
  return { type: types.REQUEST_GET_PHASE };
}

function receiveGetPhase(response) {
  return {
    type: types.RECEIVE_GET_PHASE,
    ...response.data,
  };
}

function requestGetPhaseError(error) {
  return {
    type: types.REQUEST_GET_PHASE_ERROR,
    error,
  };
}

function requestGetDepatureInfo() {
  return { type: types.REQUEST_GET_DEPARTURE_INFO };
}

function receiveGetDepartureInfo(response) {
  return {
    type: types.RECEIVE_GET_DEPARTURE_INFO,
    departureDate: response.data.data.departureDate,
    phaseOutDate: response.data.data.phaseOutDate,
  };
}

function getDepartureInfoError() {
  return { type: types.GET_DEPARTURE_INFO_ERROR };
}

function requestGetProviderDailyCaseloadOptions() {
  return { type: types.REQUEST_GET_PROVIDER_DAILY_CASELOAD_OPTIONS };
}

function receiveGetProviderDailyCaseloadOptions(response) {
  return {
    type: types.RECEIVE_GET_PROVIDER_DAILY_CASELOAD_OPTIONS,
    dailyCaseLoadOptions: response.data.data,
  };
}

function requestGetProviderDailyCaseloadOptionsError() {
  return { type: types.REQUEST_GET_PROVIDER_DAILY_CASELOAD_OPTIONS_ERROR };
}

export const getAvailability = (userID) => (dispatch) => {
  dispatch(requestGetAvailability(userID));
  return apiWrapper
    .get(`${apiHelper().apiEndpoint}/api/v1/therapist/${userID}/availability`)
    .then((response) => dispatch(receiveGetAvailability(response)))
    .catch((error) => dispatch(requestGetAvailabilityError(error)));
};

export const putAvailability = (userID, availability) => (dispatch) => {
  // mock API call until endpoint is finished
  dispatch(requestUpdateAvailability(userID, availability));
  return apiWrapper
    .put(`${apiHelper().apiEndpoint}/api/v1/therapist/${userID}/availability/${availability}`)
    .then(() => dispatch(receiveUpdateAvailability(availability)))
    .catch((error) => dispatch(requestUpdateAvailabilityError(error)));
};

export const getVideoSessionHours = (userID) => (dispatch) => {
  dispatch(requestGetVideoSessionHours());
  return apiWrapper
    .get(`${apiHelper().apiEndpoint}/v2/therapist/${userID}/availability`)
    .then((response) => dispatch(receiveGetVideoSessionHours(response)))
    .catch((error) => dispatch(requestGetVideoSessionHoursError(error)));
};

export const saveVideoSessionHours = (userID, constraints) => (dispatch) => {
  dispatch(requestSaveVideoSessionHours());
  return apiWrapper
    .put(`${apiHelper().apiEndpoint}/v2/therapist/${userID}/availability`, { constraints })
    .then((response) => dispatch(receiveSaveVideoSessionHours(constraints, response)))
    .catch((error) => dispatch(requestSaveVideoSessionHoursError(error)));
};

export const getClientCapacity = (userID) => (dispatch) => {
  dispatch(requestGetClientCapacity());
  return apiWrapper
    .get(`${apiHelper().apiEndpoint}/v2/therapist/${userID}/capacity`)
    .then((response) => dispatch(receiveGetClientCapacity(response)))
    .catch((error) => dispatch(requestGetClientCapacityError(error)));
};

export const getBusinessDays = (userID) => (dispatch) => {
  dispatch(requestGetBusinessDays());
  return apiWrapper
    .get(`${apiHelper().apiEndpoint}/api/v1/therapist/${userID}/business-days`)
    .then((response) => dispatch(receiveGetBusinessDays(response)))
    .catch((error) => dispatch(requestGetBusinessDaysError(error)));
};

export const saveBusinessDays = (userID, payload) => (dispatch) => {
  dispatch(requestSaveBusinessDays());
  return apiWrapper
    .post(`${apiHelper().apiEndpoint}/api/v1/therapist/${userID}/business-days`, payload)
    .then((response) => dispatch(receiveSaveBusinessDays(response)))
    .catch((error) => dispatch(requestSaveBusinessDaysError(error)));
};

export const saveClientCapacity = (userID, payload) => (dispatch) => {
  dispatch(requestSaveClientCapacity());
  return apiWrapper
    .patch(`${apiHelper().apiEndpoint}/v2/therapist/${userID}/capacity`, payload)
    .then((response) => dispatch(receiveSaveClientCapacity(response)))
    .catch((error) => dispatch(requestSaveClientCapacityError(error)));
};

export const getPhase = (userID) => (dispatch) => {
  dispatch(requestGetPhase());
  return apiWrapper
    .get(`${apiHelper().apiEndpoint}/api/v1/therapist/${userID}/phase`)
    .then((response) => dispatch(receiveGetPhase(response)))
    .catch((error) => dispatch(requestGetPhaseError(error)));
};

export const getDepartureInfo = (userID) => (dispatch) => {
  dispatch(requestGetDepatureInfo(userID));
  return apiWrapper
    .get(`${apiHelper().apiEndpoint}/api/v1/therapist/${userID}/departure-info`)
    .then((response) => dispatch(receiveGetDepartureInfo(response)))
    .catch((error) => dispatch(getDepartureInfoError(error)));
};

export const getProviderDailyCaseloadOptions = () => (dispatch) => {
  dispatch(requestGetProviderDailyCaseloadOptions());
  return apiWrapper
    .get(
      `${
        apiHelper().apiEndpoint
      }/v3/users/get-admin-config-value?key=provider_daily_caseload_options&json=true`
    )
    .then((response) => dispatch(receiveGetProviderDailyCaseloadOptions(response)))
    .catch(() => dispatch(requestGetProviderDailyCaseloadOptionsError()));
};
