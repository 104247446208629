import {
  View,
  Button,
  Modal,
  ExtraHuge,
  Large,
  Tiny,
  BaseButton,
  Standard,
} from '@talkspace/react-toolkit';
import moment from 'moment';
import styled from '../../../core/styled';
import { FetchState, Booking } from '../types';

const ScrollView = styled(View)({
  maxHeight: 200,
  overflowY: 'auto',
});

const Col1 = styled(View)({
  flex: 3,
  textAlign: 'left',
});
const Col2 = styled(View)({
  flex: 6,
  textAlign: 'left',
});
const Col3 = styled(View)({
  flex: 5,
  textAlign: 'left',
});
const BookingTitle = () => (
  <View row>
    <Col1>
      <Tiny>Date</Tiny>
    </Col1>
    <Col2>
      <Tiny>Time</Tiny>
    </Col2>
    <Col3>
      <Tiny>Client</Tiny>
    </Col3>
  </View>
);
const BookingRow = ({ booking }: { booking: Booking }) => (
  <View row>
    <Col1>
      <Standard>{moment(booking.startTime).format('MMM D')}</Standard>
    </Col1>
    <Col2>
      <Standard>
        {moment(booking.startTime).format('h:mmA')} -
        {moment(booking.startTime).add(booking.creditMinutes, 'minutes').format('h:mmA')}
      </Standard>
    </Col2>
    <Col3>
      <Standard>{booking.clientPseudonym}</Standard>
    </Col3>
  </View>
);

const TimeOffCancelBookingsModal = ({
  closeModal,
  scheduleTimeOff,
  bookings,
  fetchState,
  isVisible,
}: {
  closeModal: () => void;
  scheduleTimeOff: () => void;
  bookings: Booking[];
  fetchState: FetchState;
  isVisible: boolean;
}) => {
  const handleConfirmPress = () => {
    scheduleTimeOff();
    closeModal();
  };
  const titleText = 'Cancel sessions?';
  return (
    <Modal isVisible={isVisible} onBackdropPress={closeModal} titleText={titleText}>
      <Modal.Panel onBackdropPress={closeModal} contentViewStyle={{ paddingTop: 0 }}>
        <View
          style={{
            alignItems: 'stretch',
            marginTop: 9,
            marginBottom: 14,
            textAlign: 'center',
            width: 335,
          }}
        >
          <ExtraHuge style={{ marginBottom: 12, marginTop: 20 }}>{titleText}</ExtraHuge>
          <Large style={{ marginBottom: 34 }} variant="largeDarkGrey">
            By confirming this time off period, the following sessions will be automatically
            cancelled.
          </Large>
          <ScrollView>
            <BookingTitle />
            {bookings &&
              bookings.map((booking) => (
                <View justify="center" style={{ borderBottom: '1px solid #ccc', height: 58 }}>
                  <BookingRow booking={booking} key={booking.id} />
                </View>
              ))}
          </ScrollView>
          <Button
            text="Confirm and cancel sessions"
            onPress={handleConfirmPress}
            style={{ marginTop: 20, width: 335 }}
            disabled={fetchState === 'fetching'}
          />
          <BaseButton style={{ marginTop: 20 }} onPress={closeModal}>
            <Standard variant="standardDarkGrey">Edit time off period</Standard>
          </BaseButton>
        </View>
      </Modal.Panel>
    </Modal>
  );
};

export default TimeOffCancelBookingsModal;
