import { FunctionComponent } from 'react';
import { View, useEmotionTheme, Large } from '@talkspace/react-toolkit';
import Submenu from 'components/Reusable/Submenu';
import { useHistory, useRouteMatch } from '@/core/routerLib';
import CTAContainer from './CTAContainerV2';

const AllUnsubmittedSessions: FunctionComponent = () => {
  const { colors } = useEmotionTheme();
  const history = useHistory();
  const {
    params: { roomID },
  } = useRouteMatch<{ roomID: string }>();

  const renderMainView = () => (
    <View style={{ paddingLeft: 15, marginTop: 20 }}>
      <CTAContainer />
    </View>
  );

  const handleBackButtonPress = () => {
    history.push(`/room/${roomID}/notes-tab`);
  };

  return (
    <Submenu
      caretColor={colors.accessibilityGreenDark}
      bodyStyle={{ paddingRight: 0, paddingLeft: 0, paddingTop: 5, paddingBottom: 15 }}
      titleComponent={
        <View>
          <Large variant="largeBoldWide" style={{ fontSize: 17 }}>
            All unsubmitted progress notes
          </Large>
        </View>
      }
      childComponents={[renderMainView()]}
      onBackAlt={handleBackButtonPress}
    />
  );
};

export default AllUnsubmittedSessions;
