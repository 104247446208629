import { EmotionThemeProvider, useEmotionTheme } from '@talkspace/react-toolkit';
import * as React from 'react';
import { Provider } from 'react-redux';
import createStore from './redux/chatStore';
import Routes from './Routes';

export { default as VideoCallWrapper, useSetVideoCallScreenProps } from './VideoCallWrapper';

interface ChatProps {
  isTherapistChat: boolean;
  isAcking?: boolean;
  stopInputAutofocus?: boolean;
  shouldPlaySoundNotifications?: boolean;
  style?: {};
  banners?: JSX.Element[];
  priorityBanner?: JSX.Element;
  isFocusedAfterModal?: boolean;
  isOffline?: boolean;
  outsideHeaderHeight?: number;
  outsideFooterHeight?: number;
  isVideoCallOutsideChat?: boolean;
}

const Chat: React.FunctionComponent<ChatProps> = ({
  isTherapistChat,
  banners,
  priorityBanner,
  isFocusedAfterModal = true,
  outsideHeaderHeight = 0,
  outsideFooterHeight = 0,
  ...otherProps
}) => {
  const { themeVersion } = useEmotionTheme();

  return (
    <Provider store={createStore(isTherapistChat)}>
      <EmotionThemeProvider version={themeVersion || '0.1.0'}>
        <Routes
          {...otherProps}
          banners={banners}
          priorityBanner={priorityBanner}
          isTherapistChat={isTherapistChat}
          isFocusedAfterModal={isFocusedAfterModal}
          outsideHeaderHeight={outsideHeaderHeight}
          outsideFooterHeight={outsideFooterHeight}
        />
      </EmotionThemeProvider>
    </Provider>
  );
};

export default Chat;
