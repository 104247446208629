import { VoidFunctionComponent } from 'react';
import Svg, { Path, Mask, G } from 'svgs';
import { EmotionStyle, useEmotionTheme } from '../../core/styled';
import { useUniqueID } from '../../hooks/a11yHelper';

interface Props {
  width?: number;
  height?: number;
  style?: EmotionStyle;
}

const MessagingSession: VoidFunctionComponent<Props> = ({
  width = 160,
  height = 134,
  ...otherProps
}) => {
  const titleText = 'Messaging Session';
  const { colors } = useEmotionTheme();
  const messagingSessionId1 = useUniqueID('messagingSession');
  const messagingSessionId2 = useUniqueID('messagingSession');
  return (
    <Svg
      title={titleText}
      width={width}
      height={height}
      viewBox="0 0 160 134"
      fill="none"
      {...otherProps}
    >
      <Path
        opacity="0.5"
        d="M80 133.418C124.183 133.418 160 132.121 160 130.521C160 128.922 124.183 127.625 80 127.625C35.8172 127.625 0 128.922 0 130.521C0 132.121 35.8172 133.418 80 133.418Z"
        fill={colors.permaCornflowerBlueSolid}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.9807 27.8066L131.602 65.8326L72.3141 93.7173L11.6924 64.4983L70.9807 27.8066Z"
        fill={colors.permaCornflowerBlueSolid}
      />
      <Path d="M120.548 50.0205H22.8701V125.127H120.548V50.0205Z" fill={colors.permaFog} />
      <Path
        d="M22.8701 50.0205H120.548V120.133C120.548 122.891 118.312 125.127 115.554 125.127H22.8701V50.0205Z"
        fill={colors.white}
      />
      <Path
        d="M112.376 58.7012H31.292V59.8986H112.376V58.7012Z"
        fill={colors.permaSlateGrayApprox}
      />
      <Path
        d="M112.376 85.2607H31.292V86.4582H112.376V85.2607Z"
        fill={colors.permaSlateGrayApprox}
      />
      <Path
        d="M112.376 65.3418H31.292V66.5392H112.376V65.3418Z"
        fill={colors.permaSlateGrayApprox}
      />
      <Path
        d="M112.376 91.9014H31.292V93.0988H112.376V91.9014Z"
        fill={colors.permaSlateGrayApprox}
      />
      <Path
        d="M112.376 71.9814H31.292V73.1789H112.376V71.9814Z"
        fill={colors.permaSlateGrayApprox}
      />
      <Path d="M112.376 98.542H31.292V99.7394H112.376V98.542Z" fill={colors.permaSlateGrayApprox} />
      <Path
        d="M112.376 78.6221H31.292V79.8195H112.376V78.6221Z"
        fill={colors.permaSlateGrayApprox}
      />
      <Path
        d="M112.376 105.182H31.292V106.379H112.376V105.182Z"
        fill={colors.permaSlateGrayApprox}
      />
      <Path
        d="M112.375 114.87H74.3193V116.068H112.375V114.87Z"
        fill={colors.permaSlateGrayApprox}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7327 64.502H11.6855V130.02H131.736V64.502H129.435L131.603 65.8608L72.3143 93.7329L11.6926 64.5269L11.7327 64.502Z"
        fill={colors.permaPeriwinkleGray}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6855 130.02L72.2598 93.7329L11.6855 64.502V130.02Z"
        fill={colors.permaCornflowerBlueSolid}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M120.984 121.186L120.497 124.865L123.507 125.473L124.845 121.763L120.984 121.186Z"
        fill={colors.permaContessa}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M138.892 111.486L140.987 111.473L137.129 117.233L135.365 115.226L138.892 111.486Z"
        fill={colors.permaContessa}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M133.096 116.96C132.897 116.96 132.307 116.293 131.449 115.157L130.268 121.945C122.68 123.092 119.504 120.886 119.504 120.886L121.735 100.724C118.319 95.3978 115.23 90.0236 113.912 88.0939C109.906 82.2309 110.762 55.666 110.762 55.666C116.326 55.8178 133.234 55.666 133.234 55.666C133.234 55.666 134.544 83.2979 134.544 85.7408C134.544 88.1843 133.186 98.5908 133.186 98.5908C133.186 98.5908 139.973 109.992 139.52 111.078C139.068 112.164 134.272 116.96 133.096 116.96Z"
        fill={colors.permaBlueStoneApprox}
      />
      <Path
        d="M123.351 65.7822L123.36 65.7826C123.442 65.7884 123.504 65.86 123.499 65.9426L123.489 66.0985C123.485 66.1546 123.481 66.2168 123.478 66.285L123.467 66.4666C123.455 66.7006 123.443 66.9604 123.431 67.2437C123.304 70.2956 123.334 73.6145 123.63 76.7334C123.971 80.3371 124.635 83.2104 125.679 85.077L126.911 87.2828L128.318 89.8069C129.205 91.3998 130.132 93.0675 131.064 94.7469L133.233 98.6545C133.273 98.7269 133.247 98.818 133.174 98.8581C133.105 98.8967 133.017 98.8739 132.975 98.8076L130.912 95.0897C129.972 93.3972 129.036 91.7139 128.14 90.1045L126.791 87.6822C126.301 86.8038 125.842 85.9817 125.418 85.2233C124.348 83.3118 123.676 80.4034 123.331 76.7616C123.035 73.6272 123.005 70.2956 123.131 67.2313L123.139 67.0634C123.151 66.7884 123.163 66.5373 123.176 66.3126L123.19 66.08L123.2 65.9215C123.205 65.842 123.272 65.7813 123.351 65.7822Z"
        fill={colors.permaOuterSpaceNew}
      />
      <Path
        d="M125.076 105.115C125.144 105.072 125.232 105.091 125.278 105.155L125.283 105.162L131.497 115.105C131.541 115.175 131.519 115.268 131.449 115.311C131.382 115.354 131.293 115.335 131.248 115.271L131.243 115.264L125.029 105.321C124.985 105.251 125.006 105.158 125.076 105.115Z"
        fill={colors.permaOuterSpaceNew}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M123.771 124.779C123.771 124.779 124.167 124.699 124.167 125.046C124.167 125.393 123.771 127.554 124.154 129.27C124.537 130.986 126.765 133.213 121.324 133.287C115.883 133.36 117.143 131.242 117.782 130.42C118.421 129.598 119.784 127.647 120.094 126.442C120.405 125.237 120.229 124.231 120.576 124.066C120.576 124.066 122.146 124.742 123.771 124.779Z"
        fill={colors.permaHalfBaked}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M117.368 132.479C118.324 133.273 119.944 133.58 122.553 133.273C125.161 132.965 125.21 131.96 124.805 131.005C124.805 131.005 120.236 131.554 117.781 130.421C117.781 130.421 116.412 131.685 117.368 132.479Z"
        fill={colors.permaJetStream}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M136.594 116.644C136.594 116.644 136.281 116.791 136.612 117.232C136.943 117.674 137.311 118.723 137.923 120.936C138.536 123.149 138.706 124.102 138.877 126.962C139.047 129.822 138.774 133.805 141.056 132.614C143.337 131.422 144.188 127.541 143.779 125.192C143.371 122.843 142.485 120.425 143.234 117.464C143.983 114.502 144.528 112.629 142.826 112.05C141.123 111.472 139.54 110.966 139.532 111.576C139.532 111.576 139.612 115.263 136.594 116.644Z"
        fill={colors.permaHalfBaked}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M143.816 127.988C143.816 127.988 143.171 133.037 140.11 132.903C140.11 132.903 138.981 133.253 138.928 128.982C138.928 128.982 139.089 130.997 143.816 127.988Z"
        fill={colors.permaJetStream}
      />
      <Mask
        id={messagingSessionId1}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        maskUnits="userSpaceOnUse"
        x="141"
        y="111"
        width="3"
        height="6"
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M141.35 111.582H143.999V116.949H141.35V111.582Z"
          fill={colors.white}
        />
      </Mask>
      <G mask={`url(#${messagingSessionId1})`}>
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M141.43 111.582C141.43 111.582 140.742 115.129 143.387 116.949C143.387 116.949 144.305 113.998 143.892 113.019C143.479 112.04 142.867 111.995 141.43 111.582Z"
          fill={colors.permaJetStream}
        />
      </G>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M119.452 23.0039C119.452 23.0039 119.268 27.0565 116.904 28.4871C114.759 29.785 119.303 37.3165 124.191 36.8673C126.867 36.6213 127.494 29.3039 127.494 29.3039C127.494 29.3039 125.334 28.8864 126.132 25.5916L119.452 23.0039Z"
        fill={colors.permaCopper}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M123.367 27.1245C120.198 26.7412 117.911 24.6576 117.59 21.201C116.669 21.3477 116.452 18.9472 116.708 18.176C116.965 17.4038 117.804 17.8553 117.804 17.8553C117.804 17.8553 117.873 17.6723 117.922 17.4721C119.306 11.7936 123.184 12.0188 123.184 12.0188C130.308 12.39 128.639 19.664 128.232 21.5938C127.825 23.5237 126.43 27.4946 123.367 27.1245Z"
        fill={colors.permaCopper}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M111.791 44.9375L109.992 61.7724C109.992 61.7724 122.798 64.0749 133.878 60.4774L134.525 45.6569L111.791 44.9375Z"
        fill={colors.permaOuterSpaceNew}
      />
      <Mask
        id={messagingSessionId2}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        maskUnits="userSpaceOnUse"
        x="104"
        y="28"
        width="36"
        height="32"
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M104.578 28.2939H139.754V59.0797H104.578V28.2939Z"
          fill={colors.permaLinkWaterApprox}
        />
      </Mask>
      <G mask={`url(#${messagingSessionId2})`}>
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M110.082 56.8189C110.082 56.8189 103.93 52.9827 104.634 46.761C105.338 40.5392 107.961 32.0453 112.468 30.2997C116.974 28.5542 117.115 28.2939 117.115 28.2939C117.115 28.2939 118.921 30.6516 122.067 30.8014C125.214 30.9512 127.261 29.2034 127.261 29.2034C127.261 29.2034 133.44 30.6751 135.219 33.4598C136.999 36.2446 141.543 48.6791 138.996 52.6241C136.449 56.5692 131.524 57.7187 131.524 57.7187C131.524 57.7187 116.474 61.3133 110.082 56.8189Z"
          fill={colors.permaWhite}
        />
      </G>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M118.918 45.3756L118.873 41.3806C118.873 41.3806 118.737 40.5317 120.053 40.4817C121.37 40.4318 124.911 40.3819 124.911 40.3819C124.911 40.3819 125.773 40.1821 125.819 41.4305C125.864 42.679 125.91 46.9736 125.91 46.9736L120.462 48.5217L118.419 48.4218L118.918 45.3756Z"
        fill={colors.permaSlateGrayApprox}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M118.45 48.3963L120.461 48.4946L125.886 46.9528C125.883 46.6394 125.839 42.621 125.796 41.4295C125.78 40.9941 125.663 40.6902 125.448 40.5259C125.206 40.3404 124.921 40.4035 124.918 40.4042L124.913 40.4049C124.878 40.4053 121.357 40.4554 120.056 40.5048C119.578 40.5229 119.24 40.6479 119.052 40.8763C118.86 41.1096 118.899 41.3721 118.899 41.3748L118.945 45.3756L118.945 45.3777L118.45 48.3963ZM120.467 48.5448L120.462 48.5446L118.392 48.4434L118.895 45.3718L118.85 41.379C118.848 41.371 118.807 41.0965 119.013 40.8455C119.21 40.605 119.56 40.4735 120.054 40.4548C121.341 40.406 124.797 40.3566 124.91 40.355C124.943 40.3479 125.228 40.2943 125.479 40.4863C125.706 40.66 125.829 40.9768 125.846 41.4276C125.89 42.6622 125.936 46.9284 125.936 46.9714L125.937 46.9905L125.918 46.9956L120.467 48.5448Z"
        fill={colors.permaOuterSpaceNew}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M118.871 41.5283L118.868 40.8404C118.868 40.8404 118.868 40.2911 119.617 40.2411C120.366 40.1912 124.811 40.0913 124.811 40.0913C124.811 40.0913 125.41 40.0414 125.66 40.391C125.909 40.7405 125.83 41.5903 125.83 41.5903C125.83 41.5903 125.802 40.3994 124.807 40.4202C123.812 40.4409 119.941 40.5209 119.941 40.5209C119.941 40.5209 118.924 40.4185 118.871 41.5283Z"
        fill={colors.permaCloudBurst}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M124.834 40.3959C125.513 40.3959 125.741 40.959 125.818 41.3122C125.82 41.0353 125.794 40.6207 125.641 40.4064C125.402 40.0723 124.82 40.1165 124.814 40.1171C124.768 40.1182 120.36 40.2176 119.62 40.267C119.322 40.2868 119.102 40.393 118.984 40.574C118.896 40.7098 118.895 40.84 118.895 40.8413L118.896 41.2063C118.95 41.0093 119.046 40.8503 119.184 40.7313C119.504 40.4561 119.927 40.4952 119.945 40.497C119.942 40.4968 123.813 40.4168 124.808 40.3961C124.817 40.3959 124.826 40.3959 124.834 40.3959ZM125.857 41.5936L125.807 41.5918C125.807 41.5889 125.798 41.2945 125.67 41.0073C125.5 40.6265 125.211 40.4371 124.809 40.4461C123.814 40.4668 119.943 40.5468 119.943 40.5468C119.936 40.5462 119.519 40.5083 119.217 40.7694C119.021 40.9383 118.914 41.1944 118.898 41.5304L118.848 41.5293L118.845 40.8414C118.845 40.8356 118.854 40.268 119.617 40.2171C120.358 40.1678 124.767 40.0683 124.812 40.0673C124.835 40.0653 125.426 40.0203 125.682 40.3773C125.934 40.7304 125.86 41.5585 125.857 41.5936Z"
        fill={colors.permaOuterSpaceNew}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M118.929 43.7764C118.929 43.7764 118.209 44.2394 117.978 44.5223C117.746 44.8053 117.952 44.8568 117.437 45.3969C116.923 45.9372 115.997 47.3005 117.617 49.3841C119.238 51.4677 119.778 51.2875 120.447 50.9274C121.116 50.5673 121.193 50.3101 122.453 50.1815C123.714 50.0529 124.923 48.8181 124.897 48.5095L124.871 48.2008C124.871 48.2008 126.029 47.7635 125.952 47.2747C125.875 46.786 125.54 46.8374 125.54 46.8374C125.54 46.8374 125.772 46.6059 125.592 46.1943C125.412 45.7828 124.563 45.86 123.585 45.9886C122.608 46.1172 120.087 46.6316 119.933 46.5288C119.778 46.4259 119.83 46.2716 120.421 46.1943C121.013 46.1172 123.405 46.0915 123.405 45.4227C123.405 44.7538 122.685 44.6253 121.296 44.651C119.907 44.6767 119.65 44.5995 119.315 44.7282L118.981 44.8568L118.929 43.7764Z"
        fill={colors.permaCopper}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M125.559 46.8342C125.559 46.8342 125.633 46.7808 125.082 46.991C124.882 47.0675 124.599 47.1474 124.201 47.2229C122.709 47.5058 120.368 48.0203 119.648 48.1232C118.928 48.2261 119.519 48.3804 121.166 48.0461C122.812 47.7116 124.772 47.263 124.86 47.2337C125.009 47.1837 125.459 47.0339 125.559 46.8342Z"
        fill={colors.permaOuterSpaceNew}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M124.33 48.3545C124.33 48.3545 123.112 48.7815 122.513 48.9313C121.877 49.0904 121.398 49.3063 121.269 49.1005C121.14 48.8947 120.986 48.8432 120.986 48.8432L120.78 49.0747C120.78 49.0747 120.815 49.3308 120.703 49.3577C120.503 49.4057 120.111 49.4863 120.111 49.4863C120.111 49.4863 120.116 49.6304 121.315 49.3808C121.612 49.3188 122.163 49.2309 122.513 49.1311C122.835 49.0392 123.996 48.7404 124.15 48.6117C124.304 48.4831 124.33 48.3545 124.33 48.3545Z"
        fill={colors.permaOuterSpaceNew}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M120.549 48.2249C120.549 48.2249 120.53 49.2608 120.823 49.2445C121.115 49.2281 121.218 49.2281 121.269 49.1509C121.321 49.0738 121.192 48.8166 121.192 48.5336C121.192 48.2507 121.192 48.122 120.961 48.1477C120.729 48.1735 120.575 48.1735 120.549 48.2249Z"
        fill={colors.aquaSqueeze}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M122.344 23.6018C122.344 23.6018 122.221 24.3888 123.308 24.6856C124.395 24.9824 124.909 24.9106 124.957 24.4011C124.957 24.4011 124.858 23.823 124.602 23.7631C124.347 23.7031 124.056 23.8063 124.056 23.8063C124.056 23.8063 123.845 23.4286 123.569 23.4258C123.293 23.4231 122.707 23.6648 122.344 23.6018Z"
        fill={colors.permaValencia}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M124.23 24.3023C123.439 24.0813 122.494 23.8028 122.212 23.6088C122.007 23.4673 121.922 23.4837 121.921 23.4839C121.921 23.4839 122.028 23.239 122.326 23.4443C122.616 23.6443 123.872 23.9947 124.284 24.1099C125.006 24.3113 125.078 24.2827 125.168 24.2466L125.242 24.4321C125.154 24.4672 125.074 24.4849 124.9 24.4604C124.757 24.4404 124.553 24.3922 124.23 24.3023Z"
        fill={colors.permaOuterSpaceNew}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M123.62 27.3084C122.928 27.3084 122.213 27.1066 121.7 26.9203C121.043 26.6815 120.566 26.4156 120.562 26.4129L120.635 26.2822C120.653 26.2927 122.506 27.319 123.986 27.1371L124.005 27.2858C123.878 27.3013 123.75 27.3084 123.62 27.3084Z"
        fill={colors.permaOuterSpaceNew}
      />
      <Path
        d="M125.492 18.7861L125.682 18.8472L125.664 18.9067C125.649 18.9554 125.632 19.011 125.614 19.0726C125.563 19.249 125.511 19.4383 125.464 19.6332C125.41 19.8499 125.365 20.06 125.329 20.2584L125.319 20.3171C125.232 20.8217 125.22 21.207 125.297 21.4142L125.302 21.4259C125.745 22.5227 125.143 23.0789 123.879 22.6894L123.841 22.6773L123.902 22.4871C125.04 22.8513 125.496 22.4409 125.116 21.5008C125.012 21.242 125.028 20.8028 125.132 20.223C125.169 20.0202 125.215 19.806 125.27 19.5855C125.313 19.4097 125.359 19.2384 125.405 19.0769L125.422 19.0168C125.433 18.981 125.443 18.9472 125.452 18.9155L125.477 18.8354L125.492 18.7861Z"
        fill={colors.permaOuterSpaceNew}
      />
      <Path
        d="M120.561 26.4261L120.658 26.252L120.694 26.2714L120.727 26.2888C120.776 26.3148 120.833 26.3436 120.895 26.3744L120.922 26.3877C121.133 26.4904 121.366 26.5931 121.615 26.689C122.187 26.9094 122.752 27.0599 123.277 27.1087C123.515 27.1308 123.741 27.1314 123.952 27.1087L123.994 27.1038L124.019 27.3021C123.78 27.3315 123.526 27.3325 123.259 27.3076C122.712 27.2568 122.131 27.1019 121.543 26.8754C121.289 26.7773 121.051 26.6724 120.835 26.5673L120.753 26.527C120.719 26.5096 120.686 26.493 120.656 26.4772L120.599 26.4473L120.561 26.4261Z"
        fill={colors.permaOuterSpaceNew}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M124.588 22.8144C124.387 22.8144 124.135 22.7749 123.82 22.6744L123.881 22.4841C124.467 22.6714 124.899 22.6561 125.099 22.441C125.269 22.2578 125.268 21.9229 125.096 21.4978C124.979 21.2095 125.008 20.6625 125.182 19.8721C125.308 19.2937 125.47 18.7882 125.472 18.7832L125.662 18.8442C125.487 19.3902 125.077 20.9179 125.281 21.423C125.541 22.0651 125.405 22.4049 125.245 22.5768C125.131 22.6996 124.933 22.8144 124.588 22.8144Z"
        fill={colors.permaOuterSpaceNew}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M123.23 20.1167C123.194 20.1167 123.158 20.1037 123.129 20.0772C123.127 20.0755 122.88 19.8562 122.523 19.8695C122.154 19.8831 121.909 20.0637 121.907 20.0655C121.841 20.1149 121.747 20.1019 121.697 20.0362C121.648 19.9704 121.66 19.8771 121.726 19.827C121.738 19.8172 122.044 19.5874 122.512 19.5701C122.994 19.5528 123.318 19.8441 123.331 19.8565C123.392 19.9125 123.397 20.0072 123.341 20.0682C123.311 20.1004 123.271 20.1167 123.23 20.1167Z"
        fill={colors.permaOuterSpaceNew}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M120.685 19.3687C120.685 19.3687 121.255 18.6857 121.771 18.3976C122.089 18.2197 124.053 17.8391 124.125 18.2416C124.195 18.6338 123.993 18.6474 123.21 18.7749C122.427 18.9024 122.06 18.9339 121.648 19.1273C121.237 19.3207 120.772 19.5842 120.685 19.3687Z"
        fill={colors.permaOuterSpaceNew}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M128.261 19.8494C128.261 19.8494 128.334 19.4354 128.234 19.1649C128.134 18.8946 126.901 17.7694 126.539 17.8673C125.964 18.023 126.172 18.2984 126.899 18.7879C127.485 19.1815 127.718 19.3331 127.939 19.6104C128.24 19.9893 128.218 19.9105 128.261 19.8494Z"
        fill={colors.permaOuterSpaceNew}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M127.43 20.5718C127.393 20.5718 127.357 20.5587 127.328 20.5323C127.326 20.5306 127.079 20.311 126.723 20.3246C126.355 20.3382 126.109 20.5183 126.107 20.5201C126.041 20.5704 125.947 20.5578 125.897 20.4921C125.847 20.4263 125.859 20.3323 125.925 20.2821C125.938 20.2723 126.244 20.0424 126.712 20.0251C127.193 20.0079 127.517 20.2992 127.531 20.3116C127.592 20.3675 127.596 20.4623 127.54 20.5232C127.51 20.5554 127.47 20.5718 127.43 20.5718Z"
        fill={colors.permaOuterSpaceNew}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M108.502 2.1444C111.548 4.5414 114.495 9.38533 119.688 8.63626C124.882 7.8872 131.685 10.9546 128.608 19.4334C128.608 19.4334 128.727 14.8285 126.13 13.2305C123.533 11.6325 122.684 20.072 117.741 17.9247C117.741 17.9247 116.942 17.4752 116.742 18.1743C116.542 18.8735 116.679 20.7362 117.21 21.1032C117.21 21.1032 117.341 21.2205 117.591 21.1207C117.591 21.1207 117.74 23.4916 119.139 24.9528C119.139 24.9528 118.789 26.1146 118.664 26.2893L118.575 26.3098C118.575 26.3098 113.795 30.0095 108.602 25.8148C103.408 21.62 99.9627 24.816 95.768 20.9209C91.5732 17.0258 101.648 5.27721 99.0639 2.49396C102.16 -0.851857 107.01 0.970515 108.502 2.1444Z"
        fill={colors.permaOuterSpaceNew}
      />
    </Svg>
  );
};

export default MessagingSession;
