import { FunctionComponent } from 'react';
import styled, { EmotionTheme } from '../../core/styled';
import Text, { TextProps } from '../Text';
import type { VariantFunction } from './types';

type Variant = 'massiveDarkGreen';
interface MassiveProps extends TextProps {
  variant?: Variant;
}

const massiveDefaultStyle = (colors: EmotionTheme['colors']) => {
  return {
    fontWeight: 700,
    fontSize: 42,
    lineHeight: '55px',
    letterSpacing: 0,
    color: colors.black,
  };
};

const variants: Record<string, VariantFunction> = {
  default: ({ theme: { colors } }) => {
    return {
      ...massiveDefaultStyle(colors),
    };
  },
  rainforestGreen: ({ theme: { colors } }) => {
    return {
      ...massiveDefaultStyle(colors),
      color: colors.rainforestGreen,
    };
  },
};

const MassiveDefault = styled(Text)(variants.default);
const MassiveDarkGreen = styled(Text)(variants.rainforestGreen);

const selectStyle = (variant: Variant | undefined, props: Omit<MassiveProps, 'variant'>) => {
  switch (variant) {
    case 'massiveDarkGreen':
      return <MassiveDarkGreen {...props} />;
    default:
      return <MassiveDefault {...props} />;
  }
};

const Massive: FunctionComponent<MassiveProps> = ({ variant, ...props }) =>
  selectStyle(variant, props);

export default Massive;
