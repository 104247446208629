import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const pathConfig: IconConfig = {
  standard: {
    default: {
      path: 'M8.54999 2.75049C8.54999 2.33486 8.21561 2.00049 7.79999 2.00049C7.38436 2.00049 7.04999 2.33486 7.04999 2.75049V4.00049H5.79999C4.69686 4.00049 3.79999 4.89736 3.79999 6.00049V6.50049V8.00049V16.0005C3.79999 17.1036 4.69686 18.0005 5.79999 18.0005H15.8C16.9031 18.0005 17.8 17.1036 17.8 16.0005V8.00049V6.50049V6.00049C17.8 4.89736 16.9031 4.00049 15.8 4.00049H14.55V2.75049C14.55 2.33486 14.2156 2.00049 13.8 2.00049C13.3844 2.00049 13.05 2.33486 13.05 2.75049V4.00049H8.54999V2.75049ZM5.29999 8.00049H16.3V16.0005C16.3 16.2755 16.075 16.5005 15.8 16.5005H5.79999C5.52499 16.5005 5.29999 16.2755 5.29999 16.0005V8.00049Z',
    },
    filled: {
      path: 'M6.79999 3.00049V4.00049H5.29999C4.47186 4.00049 3.79999 4.67236 3.79999 5.50049V7.00049H17.8V5.50049C17.8 4.67236 17.1281 4.00049 16.3 4.00049H14.8V3.00049C14.8 2.44736 14.3531 2.00049 13.8 2.00049C13.2469 2.00049 12.8 2.44736 12.8 3.00049V4.00049H8.79999V3.00049C8.79999 2.44736 8.35311 2.00049 7.79999 2.00049C7.24686 2.00049 6.79999 2.44736 6.79999 3.00049ZM17.8 8.00049H3.79999V16.5005C3.79999 17.3286 4.47186 18.0005 5.29999 18.0005H16.3C17.1281 18.0005 17.8 17.3286 17.8 16.5005V8.00049Z',
    },
  },
  major: {
    default: {
      path: 'M9.9874 3.73096C9.9874 3.21143 9.56943 2.79346 9.0499 2.79346C8.53036 2.79346 8.1124 3.21143 8.1124 3.73096V5.29346H6.5499C5.17099 5.29346 4.0499 6.41455 4.0499 7.79346V8.41846V10.2935V20.2935C4.0499 21.6724 5.17099 22.7935 6.5499 22.7935H19.0499C20.4288 22.7935 21.5499 21.6724 21.5499 20.2935V10.2935V8.41846V7.79346C21.5499 6.41455 20.4288 5.29346 19.0499 5.29346H17.4874V3.73096C17.4874 3.21143 17.0694 2.79346 16.5499 2.79346C16.0304 2.79346 15.6124 3.21143 15.6124 3.73096V5.29346H9.9874V3.73096ZM5.9249 10.2935H19.6749V20.2935C19.6749 20.6372 19.3936 20.9185 19.0499 20.9185H6.5499C6.20615 20.9185 5.9249 20.6372 5.9249 20.2935V10.2935Z',
    },
    filled: {
      path: 'M7.7999 4.04346V5.29346H5.9249C4.88974 5.29346 4.0499 6.1333 4.0499 7.16846V9.04346H21.5499V7.16846C21.5499 6.1333 20.7101 5.29346 19.6749 5.29346H17.7999V4.04346C17.7999 3.35205 17.2413 2.79346 16.5499 2.79346C15.8585 2.79346 15.2999 3.35205 15.2999 4.04346V5.29346H10.2999V4.04346C10.2999 3.35205 9.7413 2.79346 9.0499 2.79346C8.35849 2.79346 7.7999 3.35205 7.7999 4.04346ZM21.5499 10.2935H4.0499V20.9185C4.0499 21.9536 4.88974 22.7935 5.9249 22.7935H19.6749C20.7101 22.7935 21.5499 21.9536 21.5499 20.9185V10.2935Z',
    },
  },
};

const Calendar = withDSIconMaker(pathConfig, 'calendar');

export default Calendar;
