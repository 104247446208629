import { FunctionComponent, useEffect, useState, useCallback } from 'react';
import {
  View,
  OptionType,
  BrickSelectorState,
  useScreenActions,
  useWindowWidthState,
} from '@talkspace/react-toolkit';
import { SemanticVersion } from '@talkspace/react-toolkit/src/utils/semanticVersioning';
import { useFlags } from 'launchDarkly/FlagsProvider';
import moment from 'moment';
import { usePostLvsSwitchPrompt } from 'launchDarkly/hooks';
import styled from '@/core/styled';
import { trackWizardEvent } from '@/utils/analytics/events';
import { withRouter, RouteComponentProps } from '@/core/routerLib/routerLib';
import StepWizard from '../../components/StepWizard';
import { useWizardActions, useWizardState } from '../../hooks/wizardContext';
import ActionStatus from '../../components/ActionStatus';
import { WizardPostActionContext, WizardType, WizardModalTitle } from '../../reducers/wizardState';
import { populateAdditionalInfoIntoElement } from '../../utils/wizardUtils';
import { WizardStep } from '../../types';
import { useA11y } from './WizardManager.a11y';
import { DATE_FORMAT_STRING } from '../../utils/constants';

const Container = styled(View)({
  width: '100%',
});

const ContainerInner = styled(View)({
  paddingBottom: 20,
  paddingLeft: 10,
  paddingRight: 10,
  paddingTop: 20,
  width: '100%',
});

export interface WizardScheme {
  steps: WizardStep[];
  welcomeScreen?: (
    onClick: () => void,
    completedAtDate?: string,
    isCompleted?: boolean
  ) => JSX.Element;
  wizardVersion: number;
  wizardType: WizardType;
  wizardModalTitle: WizardModalTitle;
  hideProgressIndicator?: boolean;
  exitModalText?: string;
  disablePersist?: boolean;
  automaticSpacing?: boolean;
  sendStepValues?: boolean;
  themeVersion?: SemanticVersion;
}

interface WizardProps {
  wizardScheme: WizardScheme;
}

const getAnalyticsResponse = (stepResponse: any, options?: OptionType[]) => {
  if (Array.isArray(stepResponse) && options) {
    return stepResponse.map(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      (selectVal) => options.find((option) => option.value === selectVal)!.label // TODO: Please verify this can never be undefined
    );
  }
  if (typeof stepResponse === 'object') {
    return JSON.stringify(stepResponse);
  }
  return stepResponse;
};

const WizardManager: FunctionComponent<
  RouteComponentProps<
    {
      roomID?: number;
      userID: number;
    },
    {},
    { roomID: number; userID?: number; source?: string; contextID?: string }
  > &
    WizardProps
> = ({ location, history, wizardScheme }) => {
  const {
    steps,
    welcomeScreen,
    wizardType,
    wizardModalTitle,
    wizardVersion,
    hideProgressIndicator,
    disablePersist,
    automaticSpacing,
    sendStepValues,
    themeVersion,
  } = wizardScheme;
  const wizardContext = useWizardState() as any;
  const { isError, isLoading, source, contextID, redirectParams, persisted, setStepValueCallback } =
    wizardContext;
  const { setState, onExitClick, closeWizard, setDefaultStepValue } = useWizardActions();
  const { currentDefaultStepValue } = useWizardState() as any;
  const [showActionStatus, setShowActionStatus] = useState(false);
  const [finalSteps, setFinalSteps] = useState<WizardStep[]>([]);
  const [finalStepsLength, setFinalStepsLength] = useState(steps.length);
  const [currentStep, setCurrentStep] = useState<WizardStep>();
  const [stepFinalValue, setStepFinalValue] = useState();
  const [showWelcomeScreen, setShowWelcomeScreen] = useState(false);
  const [currentStepValue, setCurrentStepValue] = useState('');
  const [currentStepOptions, setCurrentStepOptions] = useState<BrickSelectorState[]>([]);
  const [showStep, setShowStep] = useState(false);
  const [disableButtonAction, setDisableButtonAction] = useState(false);
  const [currentStepCounter, setCurrentStepCounter] = useState(0);
  const [currentStepDisplayCounter, setCurrentStepDisplayCounter] = useState(0);
  const [additionalInfo, setAdditionalInfo] = useState<Record<string, unknown>>();
  const [shouldAdvance, setShouldAdvance] = useState(false);
  const { setBackButtonCallback } = useScreenActions();
  const { isMobile } = useWindowWidthState();
  const flags = useFlags();
  const isPostLvsSwitchPrompt = usePostLvsSwitchPrompt();
  const shouldSkipIntro =
    isPostLvsSwitchPrompt && wizardContext.source === 'postLvsCheckinSwitchProvider';

  const showBackButtonInHeader = isMobile;

  const roomID = (location.state && location.state.roomID) || wizardContext.roomID;
  const userID = (location.state && location.state.userID) || wizardContext.clientUserID;
  const [secondaryButtonClicked, setSecondaryButtonClicked] = useState(false);

  const updateCurrentStep = useCallback(
    (stepNumber: number) => {
      setState({ currentStepNumber: stepNumber });
    },
    [setState]
  );

  const getCurrentStepButton = useCallback(
    (step: WizardStep) => {
      if (secondaryButtonClicked && step.secondaryButton) {
        return step.secondaryButton;
      }
      return step.nextButton;
    },
    [secondaryButtonClicked]
  );

  const calculateDisplayCounter = () => {
    let counter = wizardContext.currentStepNumber;
    for (let i = wizardContext.currentStepNumber - 1; i >= 0; i -= 1) {
      if (wizardContext.responses.steps[i]?.skip) {
        counter -= 1;
      }
    }
    return counter;
  };

  const advance = useCallback(
    async (step: WizardStep, skippedOrRestored = false, finalValue = stepFinalValue) => {
      const currentButton = currentStep && getCurrentStepButton(step);
      let doAction = false;
      let buttonAction;
      if (currentButton) {
        buttonAction =
          typeof currentButton.actionDispatch === 'function'
            ? await currentButton.actionDispatch(finalValue, wizardContext, flags, setState)
            : populateAdditionalInfoIntoElement(currentButton.actionDispatch, additionalInfo);
        doAction = buttonAction !== 'next';
      }
      const updatedCounter = currentStepCounter + 1;
      if (skippedOrRestored) {
        setCurrentStepCounter(updatedCounter);
        updateCurrentStep(updatedCounter);
        setCurrentStep(finalSteps[updatedCounter]);
        return;
      }

      const eventProperty = step.inputType
        ? getAnalyticsResponse(finalValue, currentStepOptions)
        : doAction;

      trackWizardEvent('Wizard Step Answer', wizardContext.eventCategory, {
        'User ID': userID || wizardContext.clientUserID,
        Question: step.title || step.name,
        Response: sendStepValues ? eventProperty : undefined,
        'Step ID': currentStepCounter + 1,
        DoAction: doAction,
        label: step.title || step.name,
        Application: wizardContext.eventCategory,
        eventProperty: sendStepValues ? eventProperty : undefined,
        eventPropertyValue: (sendStepValues && wizardContext.therapistInfo?.id) || 0,
      });

      if (doAction && currentButton) {
        const stepSource =
          populateAdditionalInfoIntoElement(currentButton.source, additionalInfo) || source;
        const stepRoomID =
          populateAdditionalInfoIntoElement(currentButton.roomID, additionalInfo) || roomID;
        if (currentButton.persistOnDispatch) {
          setState({ shouldPersist: true });
        }
        const url = currentButton.fullReload
          ? buttonAction
              .replace(':roomID', stepRoomID)
              .replace(':source', stepSource)
              .replace(':contextID', contextID)
          : buttonAction;
        setState({
          redirectParams: {
            redirectURL: url,
            fullReload: currentButton.fullReload,
            actionDispatchWithLocationState: currentButton.actionDispatchWithLocationState,
            persistOnDispatch: currentButton.persistOnDispatch,
          },
          roomID: stepRoomID,
        });
      } else {
        setCurrentStepCounter(updatedCounter);
        setCurrentStepDisplayCounter(currentStepDisplayCounter + 1);
        updateCurrentStep(updatedCounter);
        setCurrentStep(finalSteps[updatedCounter]);
        setDisableButtonAction(true);
      }
    },
    [
      setState,
      additionalInfo,
      contextID,
      currentStep,
      currentStepCounter,
      currentStepDisplayCounter,
      currentStepOptions,
      finalSteps,
      getCurrentStepButton,
      roomID,
      source,
      stepFinalValue,
      updateCurrentStep,
      userID,
      wizardContext,
      sendStepValues,
      flags,
    ]
  );

  const handleBackPress = useCallback(() => {
    if (currentStep && currentStep.backOption) {
      // find last non-skipped step
      let lastNoneSkipped = -1;
      for (let i = currentStepCounter - 1; i >= 0; i -= 1) {
        if (!wizardContext.responses.steps[i]?.skip) {
          lastNoneSkipped = i;
          break;
        }
      }
      if (currentStep.backOption === 'back' && lastNoneSkipped >= 0) {
        setCurrentStepDisplayCounter(currentStepDisplayCounter - 1);
        updateCurrentStep(lastNoneSkipped);
      } else {
        history.push(currentStep.backOption);
      }
    }
  }, [
    currentStep,
    currentStepCounter,
    currentStepDisplayCounter,
    history,
    updateCurrentStep,
    wizardContext.responses.steps,
  ]);

  useEffect(() => {
    if (
      currentStep &&
      currentStep.backOption &&
      showBackButtonInHeader &&
      currentStepDisplayCounter + 1 > 1
    ) {
      setBackButtonCallback(() => handleBackPress);
    } else {
      setBackButtonCallback(undefined);
    }
  }, [
    handleBackPress,
    setBackButtonCallback,
    currentStep,
    showBackButtonInHeader,
    currentStepDisplayCounter,
  ]);

  const updateContext = useCallback(
    async (
      step: WizardStep,
      stepCounter: number,
      value?: any,
      additionalData?: Record<string, unknown>,
      advanceToNextStep = true,
      skipped = false,
      restored = false
    ) => {
      const currentButton = getCurrentStepButton(step);
      if (currentButton.actionDispatch === 'back') {
        handleBackPress();
        return;
      }
      if (currentButton.actionDispatch === 'close') {
        closeWizard();
        return;
      }
      const doAction = currentButton.actionDispatch !== 'next';
      // build current step result
      const result = {
        name: step.name,
        additionalData,
        value,
        skip: skipped,
        restored,
        doAction: !skipped && !restored && doAction,
        createdAt: moment().format(DATE_FORMAT_STRING),
      };
      const currentStepResponses = {
        ...wizardContext.responses,
        steps: [...wizardContext.responses.steps],
      };
      currentStepResponses.lastStepName = skipped ? currentStepResponses.lastStepName : step.name;
      // check if a response already exists for this step if so update it
      let stepAlreadyExists = false;
      // If the response already exists, this checks if the answer is the exact same as before
      let isRepeatedAnswer = false;
      if (currentStepResponses.steps.length > 0) {
        // eslint-disable-next-line no-restricted-syntax
        for (let i = 0; i < currentStepResponses.steps.length; i += 1) {
          const currentResult = currentStepResponses.steps[i];
          if (currentResult.name === step.name) {
            if (currentStepResponses.steps[i].value === result.value) isRepeatedAnswer = true;
            currentStepResponses.steps[i] = result;
            stepAlreadyExists = true;
            break;
          }
        }
      }
      if (!stepAlreadyExists) {
        currentStepResponses.steps.push(result);
      }
      const updatedWizardState = {
        ...wizardContext,
        [step.inputState as string]: value,
        responses: currentStepResponses,
      };
      setState(updatedWizardState);

      if (
        step.postAction &&
        !skipped &&
        !restored &&
        typeof wizardContext[step.postAction] === 'function'
      ) {
        // Use updated (optimistic update) wizardContext on postAction
        const context: WizardPostActionContext<typeof wizardContext> = {
          setState,
          wizardContext: updatedWizardState,
          step,
          isRepeatedAnswer,
        };
        wizardContext[step.postAction](value, context);
      }
      if (advanceToNextStep) await advance(step, skipped || restored, value);
    },
    [advance, getCurrentStepButton, handleBackPress, setState, wizardContext, closeWizard]
  );

  const handleWelcomeScreenNextClick = () => {
    const action = `Begin ${wizardContext.wizardType}`;
    trackWizardEvent('Begin Wizard', wizardContext.eventCategory, {
      'User ID': userID,
      label: action,
      Application: wizardContext.eventCategory,
      eventPropertyValue: wizardContext.therapistInfo ? wizardContext.therapistInfo.id : 0,
    });
    updateCurrentStep(0);
  };

  const calculateProgress = () => (currentStepCounter + 1) / finalStepsLength;

  useEffect(() => {
    if (
      redirectParams &&
      redirectParams.redirectURL &&
      (persisted || disablePersist || !redirectParams.persistOnDispatch)
    ) {
      if (redirectParams.fullReload) window.location.href = redirectParams.redirectURL;
      else
        history.push(redirectParams.redirectURL, {
          ...(redirectParams.actionDispatchWithLocationState ? location.state : {}),
          roomID: Number(wizardContext.roomID),
          source: wizardContext.stepSource,
          contextID,
        });
    }
  }, [
    contextID,
    history,
    persisted,
    location.state,
    redirectParams,
    wizardContext.roomID,
    wizardContext.stepSource,
    disablePersist,
  ]);

  useEffect(() => {
    const initialChecks = () => {
      if (wizardContext && !isError && !isLoading) {
        setShowActionStatus(false);
        setFinalSteps(steps.slice());
      } else {
        setShowActionStatus(true);
      }
      if (wizardContext && wizardContext.wizardVersion === 0 && !wizardContext.wizardType) {
        setState({ wizardVersion, wizardType });
      }
    };
    initialChecks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, isLoading, setState, wizardContext, wizardType, wizardVersion]);

  useEffect(() => {
    setState({ disablePersist });
  }, [disablePersist, setState]);

  useEffect(() => {
    // keep wizardVersion in wizardContext synched with value from wizard scheme
    if (wizardVersion !== wizardContext.wizardVersion) {
      setState({ wizardVersion });
    }
  }, [wizardVersion, wizardContext.wizardVersion, setState]);

  useEffect(() => {
    const applyStepsState = () => {
      setFinalStepsLength(finalSteps.length);
      if (finalSteps.length > 0) {
        if (wizardContext.currentStepNumber >= 0) {
          // set current step
          setCurrentStep(finalSteps[wizardContext.currentStepNumber]);
          setCurrentStepCounter(wizardContext.currentStepNumber);
          setCurrentStepDisplayCounter(calculateDisplayCounter());
          setShowStep(true);
          setDisableButtonAction(false);
          setShouldAdvance(false);
          setShowWelcomeScreen(false);
        } else if (welcomeScreen && !shouldSkipIntro) {
          setShowWelcomeScreen(true);
          setShowStep(false);
          if (wizardContext.responses.lastStepName !== 'Welcome screen') {
            setState({
              responses: {
                steps: [],
                lastStepName: 'Welcome screen',
                completed: false,
              },
            });
          }
        } else {
          updateCurrentStep(0);
        }
      }
    };
    applyStepsState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalSteps]);

  useEffect(() => {
    const callUpdate = async () => {
      if (shouldAdvance) {
        if (currentStep) {
          let finalValue = stepFinalValue;

          // allows default value to be set outside of the wizard
          if (
            !currentStepValue &&
            !stepFinalValue &&
            currentStep.inputType === 'custom' &&
            currentDefaultStepValue
          ) {
            finalValue = currentDefaultStepValue;
            setDefaultStepValue(undefined);
          }

          await updateContext(currentStep, currentStepCounter, finalValue, additionalInfo);
          if (currentStep.inputState) {
            setState({ [currentStep.inputState]: finalValue });
          }
        }
      }
    };
    callUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldAdvance]);

  const isDisplayConditionNotFulfilled = (displayCondition) => {
    if (Array.isArray(displayCondition)) {
      return !displayCondition.every((condition) => wizardContext[condition]);
    }
    const contextValue = wizardContext[displayCondition];

    return (
      (typeof contextValue === 'boolean' && !contextValue) ||
      (typeof contextValue === 'function' && !contextValue()) ||
      contextValue === undefined ||
      contextValue === null
    );
  };

  useEffect(() => {
    if (!wizardContext.isInitialized) return;
    const setStepValues = async () => {
      // check if it should be skipped
      if (currentStep && currentStep.displayCondition) {
        if (isDisplayConditionNotFulfilled(currentStep.displayCondition)) {
          setShowStep(false);
          await updateContext(currentStep, currentStepCounter, null, {}, true, true);
          return;
        }
      }

      setSecondaryButtonClicked(false);
      setStepFinalValue(undefined);
      if (currentStep) {
        // load options and pre-populate input options
        if (currentStep.inputState) {
          setCurrentStepValue(wizardContext[currentStep.inputState]);
        }
        if (currentStep.inputOptions) {
          const newStepOptions = currentStep.indexInputByCounter
            ? wizardContext[currentStep.inputOptions][wizardContext.currentStepNumber]
            : wizardContext[currentStep.inputOptions];

          setCurrentStepOptions(newStepOptions);
        }
        if (currentStep.additionalInfo) {
          const info = {};
          currentStep.additionalInfo.forEach((field) => {
            info[field] = wizardContext[field];
          });
          setAdditionalInfo(info);
        }
      }
    };
    setStepValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, wizardContext.isInitialized]);

  useEffect(() => {
    const callUpdate = async () => {
      if (!wizardContext.isRestoringAnswers || !currentStep) return;

      if (currentStep.inputRestoredAnswer && wizardContext[currentStep.inputRestoredAnswer]) {
        const restoredAnswer = currentStep.indexInputByCounter
          ? wizardContext[currentStep.inputRestoredAnswer][wizardContext.currentStepNumber]
          : wizardContext[currentStep.inputRestoredAnswer];

        if (restoredAnswer) {
          setShowStep(false);
          await updateContext(
            currentStep,
            currentStepCounter,
            restoredAnswer,
            {},
            true,
            false,
            true
          );
        } else {
          // Finished restoring answers
          setState({ isRestoringAnswers: false, isInitialized: true });
        }
      } else {
        setState({ isRestoringAnswers: false, isInitialized: true });
      }
    };
    callUpdate();
  }, [
    additionalInfo,
    currentStep,
    currentStepCounter,
    setState,
    updateContext,
    wizardContext,
    wizardContext.isRestoringAnswers,
  ]);

  const { wrapperRef } = useA11y(wizardModalTitle, currentStep);
  return (
    <Container justify="center" align="center">
      {showActionStatus ? (
        <ContainerInner>
          <ActionStatus
            isLoading={isLoading}
            isError={isError}
            showSuccessState={false}
            errorButtonAction={onExitClick}
          />
        </ContainerInner>
      ) : null}
      {showStep && currentStep && !showActionStatus && (
        <StepWizard
          wrapperRef={wrapperRef}
          step={currentStep}
          stepInputValue={currentStepValue}
          setStepFinalValue={setStepFinalValue}
          setStepValueCallback={setStepValueCallback}
          setDisableButtonAction={setDisableButtonAction}
          setShouldAdvance={setShouldAdvance}
          disableButtonAction={disableButtonAction}
          stepInputOptions={currentStepOptions}
          setCurrentStepOptions={setCurrentStepOptions}
          stepNumber={currentStepDisplayCounter + 1}
          progress={calculateProgress()}
          handleBackPress={
            currentStep.backOption && !showBackButtonInHeader ? handleBackPress : undefined
          }
          stepAdditionalInfo={additionalInfo}
          setSecondaryButtonClicked={setSecondaryButtonClicked}
          stepsLength={finalStepsLength}
          roomID={roomID}
          automaticSpacing={automaticSpacing}
          hideProgressIndicator={
            // Current step has priority
            currentStep.hideProgressIndicator !== undefined
              ? currentStep.hideProgressIndicator
              : hideProgressIndicator
          }
          wizardType={wizardContext.wizardType}
          wizardContext={wizardContext}
          schemeThemeVersion={themeVersion}
        />
      )}
      {showWelcomeScreen && welcomeScreen ? (
        <ContainerInner>
          <View>
            {welcomeScreen(
              handleWelcomeScreenNextClick,
              wizardContext.completedAtDate,
              wizardContext.alreadyCompleted
            )}
          </View>
        </ContainerInner>
      ) : null}
    </Container>
  );
};

export default withRouter(WizardManager);
