import Button from './Button';
import ButtonV1 from './ButtonV1';
import ButtonV2 from './ButtonV2';

import { withVersioning } from '../../utils';
import { VersionMap } from '../../types';

const versionMap: VersionMap<
  React.ComponentProps<typeof Button | typeof ButtonV1 | typeof ButtonV2>
> = {
  '2.0.0': ButtonV2,
  '1.0.0': ButtonV1,
  DefaultComponent: Button,
};

export default withVersioning(versionMap);
