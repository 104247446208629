import moment from 'moment';
import {
  InformedConsent,
  InformedConsentStatus,
  InformedConsentV2StatusAPIPlusClientID,
} from 'ts-frontend/types';
import {
  REQUEST_INFORMED_CONSENTS,
  REQUEST_INFORMED_CONSENTS_ERROR,
  RECEIVE_INFORMED_CONSENTS,
  REQUEST_POST_INFORMED_CONSENT,
  REQUEST_POST_INFORMED_CONSENT_ERROR,
  RECEIVE_POST_INFORMED_CONSENT,
  PATCH_INFORMED_CONSENT_STATUS,
  PATCH_INFORMED_CONSENT_STATUS_SUCCESS,
  PATCH_INFORMED_CONSENT_STATUS_ERROR,
  HIDE_INFORMED_CONSENT_SPINNER,
  InformedConsentState,
  CRMInformedConsentItem,
  InformedConsentActionTypes,
  REQUEST_V2_INFORMED_CONSENT,
  RECEIVE_V2_INFORMED_CONSENT,
  CRMInformedConsentV2Item,
} from '../constants/InformedConsentConstants';

const initialState: InformedConsentState = {
  informedConsents: [],
  isRequesting: false,
  requestError: false,
  isPatching: false,
  patchingConsentID: undefined,
  patchingError: false,
  isPosting: false,
  postError: false,
  resendingForClientID: null,
  informedConsentV2: [],
};

function getConsentsWithSpinnerStatus(
  informedConsents: (CRMInformedConsentItem | InformedConsent)[]
): CRMInformedConsentItem[] {
  return informedConsents.map((informedConsent) => {
    return {
      ...informedConsent,
      isUpdating: !!('isUpdating' in informedConsent && informedConsent.isUpdating),
      isError: !!('isError' in informedConsent && informedConsent.isError),
      showSpinner:
        informedConsent.status === 'completed'
          ? true
          : !!('showSpinner' in informedConsent && informedConsent.showSpinner),
      spinnerMessage:
        informedConsent.status === 'completed'
          ? `Completed on ${moment(informedConsent.clickOkDate).format('MMMM D, YYYY')}`
          : ('spinnerMessage' in informedConsent && informedConsent.spinnerMessage) || undefined,
    };
  });
}

function getV2ConsentsWithSpinnerStatus(
  informedConsentV2: InformedConsentV2StatusAPIPlusClientID[]
): CRMInformedConsentV2Item[] {
  return informedConsentV2.map((informedConsent) => {
    const format = 'MMMM D, YYYY';
    const { agreedAtRegistration, clickOKDate } = informedConsent;
    const momentToReturn =
      informedConsent.agreedAtRegistration && informedConsent.clickOKDate
        ? moment.max([moment(agreedAtRegistration), moment(clickOKDate)])
        : moment(agreedAtRegistration || clickOKDate);

    return {
      ...informedConsent,
      isUpdating: !informedConsent,
      isError: false,
      showSpinner: !informedConsent.shouldSignConsent,
      spinnerMessage: informedConsent.shouldSignConsent
        ? undefined
        : `Completed on ${moment(momentToReturn).format(format)}`,
    };
  });
}

function getUpdatedConsentWithStatus(
  informedConsents: CRMInformedConsentItem[],
  consentID: number,
  status: InformedConsentStatus
) {
  return informedConsents.map((informedConsent) => {
    return {
      ...informedConsent,
      status: informedConsent.id === consentID ? status : informedConsent.status,
      isUpdating: informedConsent.id === consentID ? false : !!informedConsent.isUpdating,
      isError: informedConsent.id === consentID ? false : !!informedConsent.isError,
      spinnerMessage:
        informedConsent.id === consentID && status === 'completed'
          ? `Completed on ${moment(informedConsent.clickOkDate).format('MMMM D, YYYY')}`
          : informedConsent.spinnerMessage,
    };
  });
}

function getUpdatingConsents(informedConsents: CRMInformedConsentItem[], consentID: number) {
  return informedConsents.map((informedConsent) => {
    return {
      ...informedConsent,
      isUpdating: informedConsent.id === consentID ? true : !!informedConsent.isUpdating,
      isError: informedConsent.id === consentID ? false : !!informedConsent.isError,
      showSpinner: informedConsent.id === consentID ? true : !!informedConsent.showSpinner,
    };
  });
}

function getErroredConsents(informedConsents: CRMInformedConsentItem[], consentID: number) {
  return informedConsents.map((informedConsent) => {
    return {
      ...informedConsent,
      isUpdating: informedConsent.id === consentID ? false : !!informedConsent.isUpdating,
      isError: informedConsent.id === consentID ? true : !!informedConsent.isError,
    };
  });
}

function getHiddenSpinnerConsents(informedConsents: CRMInformedConsentItem[], consentID: number) {
  return informedConsents.map((informedConsent) => {
    return {
      ...informedConsent,
      showSpinner:
        informedConsent.id === consentID
          ? informedConsent.status === 'completed'
          : !!informedConsent.showSpinner,
    };
  });
}

export default function informedConsentReducer(
  state = initialState,
  action: InformedConsentActionTypes
): InformedConsentState {
  switch (action.type) {
    case REQUEST_INFORMED_CONSENTS:
      return {
        ...state,
        isRequesting: true,
        requestError: false,
      };
    case REQUEST_INFORMED_CONSENTS_ERROR:
      return {
        ...state,
        isRequesting: false,
        requestError: true,
      };
    case RECEIVE_INFORMED_CONSENTS: {
      const consentsWithSpinnerStatus = getConsentsWithSpinnerStatus(action.informedConsents);
      return {
        ...state,
        informedConsents: consentsWithSpinnerStatus,
        isRequesting: false,
        requestError: false,
      };
    }
    case REQUEST_POST_INFORMED_CONSENT:
      return {
        ...state,
        resendingForClientID: action.resendingForClientID,
        isPosting: true,
        postError: false,
      };
    case REQUEST_POST_INFORMED_CONSENT_ERROR:
      return {
        ...state,
        resendingForClientID: null,
        isPosting: false,
        postError: true,
      };
    case RECEIVE_POST_INFORMED_CONSENT: {
      const consentsWithSpinnerStatus = getConsentsWithSpinnerStatus([
        ...state.informedConsents,
        action.informedConsent,
      ]);
      return {
        ...state,
        informedConsents: consentsWithSpinnerStatus,
        resendingForClientID: null,
        isPosting: false,
        postError: false,
      };
    }
    case PATCH_INFORMED_CONSENT_STATUS: {
      const updatingConsents = getUpdatingConsents(state.informedConsents, action.consentID);
      return {
        ...state,
        informedConsents: updatingConsents,
        isPatching: true,
        patchingError: false,
      };
    }
    case PATCH_INFORMED_CONSENT_STATUS_SUCCESS: {
      const informedConsents = getUpdatedConsentWithStatus(
        state.informedConsents,
        action.consentID,
        action.status
      );
      return {
        ...state,
        informedConsents,
        isPatching: false,
        patchingError: false,
      };
    }
    case PATCH_INFORMED_CONSENT_STATUS_ERROR: {
      const erroredConsents = getErroredConsents(state.informedConsents, action.consentID);
      return {
        ...state,
        informedConsents: erroredConsents,
        isPatching: false,
        patchingError: true,
      };
    }
    case HIDE_INFORMED_CONSENT_SPINNER: {
      const spinnerConsents = getHiddenSpinnerConsents(state.informedConsents, action.consentID);
      return {
        ...state,
        informedConsents: spinnerConsents,
      };
    }
    case REQUEST_V2_INFORMED_CONSENT:
      return {
        ...state,
        isRequesting: true,
        requestError: false,
      };
    case RECEIVE_V2_INFORMED_CONSENT: {
      const consentWithSpinnerStatus = getV2ConsentsWithSpinnerStatus(action.informedConsentV2);
      return {
        ...state,
        informedConsentV2: consentWithSpinnerStatus,
        isRequesting: false,
        requestError: false,
      };
    }
    default:
      return state;
  }
}
