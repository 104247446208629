import { Standard, View, Link, styled } from '@talkspace/react-toolkit';
import { VoidFunctionComponent } from 'react';
import ssoHelper from '../../modules/utils/sso';

const FooterText = styled(Standard)(({ theme: { colors } }) => {
  return {
    color: colors.permaRiverBed,
    fontWeight: 400,
    fontSize: 15,
  };
});

const StyledFooter = styled.footer(({ hidden }) => {
  return {
    padding: '30px 0px 60px 0px',
    ...(hidden && { display: 'none' }),
  };
});

const FooterLink = styled(Link)(({ theme: { colors } }) => {
  return {
    fontFamily: 'Roboto, sans-serif',
    fontSize: 15,
    fontWeight: 700,
    color: colors.accessibilityGreenDark,
    margin: 0,
  };
});

const TextDivider = styled(View)(() => {
  return {
    margin: '0px 10.5px',
    background: '#99A6BB',
    height: 16,
    width: 1,
  };
});

const Footer: VoidFunctionComponent<{ hidden: boolean }> = ({ hidden }) => (
  <StyledFooter hidden={hidden}>
    <View align="center">
      <FooterText inline>Have questions about the Talkspace platform?</FooterText>
      <View row align="center" style={{ marginTop: 2 }}>
        <FooterLink
          onClick={() => ssoHelper.openZendesk('/hc/en-us')}
          text="Visit the Provider Help Center"
          target="_blank"
        />
        <TextDivider />
        <FooterLink
          href="mailto:therapist-support@talkspace.com?Subject=Therapist%20Dashboard"
          text="Contact support"
        />
      </View>
    </View>
  </StyledFooter>
);

export default Footer;
