import { FunctionComponent } from 'react';
import { View, Text, useEmotionTheme } from '@talkspace/react-toolkit';
import styled from '@/core/styled/styled';

interface InputCharWarningProps {
  hide: boolean;
  exceeded: boolean;
  dangerZone: boolean;
  charactersRemaining: number;
}

const Wrapper = styled(View)({
  top: 0,
  left: 0,
  right: 0,
  height: 29,
  width: '100%',
  position: 'absolute',
  alignItems: 'center',
  justifyContent: 'center',
});

const InputCharWarning: FunctionComponent<InputCharWarningProps> = ({
  hide,
  dangerZone,
  exceeded,
  charactersRemaining,
}) => {
  const { colors } = useEmotionTheme();
  if (hide) return null;
  return (
    <Wrapper style={{ backgroundColor: dangerZone ? colors.razzmatazz : colors.fordGrey }}>
      <Text style={{ color: colors.white }}>
        {exceeded ? 'Maximum length exceeded' : `Characters remaining: ${charactersRemaining}`}{' '}
      </Text>
    </Wrapper>
  );
};

export default InputCharWarning;
