import apiHelper from '@/utils/api';

export function therapistImageEndpoint() {
  return `${apiHelper().cdnEndpoint}/images/application/therapist/220`;
}

export function isDevelopment() {
  return (
    window.origin === 'http://localhost:3001' ||
    window.origin === 'https://therapist.dev.talkspace.com' ||
    window.origin === 'http://127.0.0.1:3001'
  );
}
