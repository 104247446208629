import { TouchableView, View, useWindowWidth } from '@talkspace/react-toolkit';
import { useCallback } from 'react';
import { useHistory } from '@/core/routerLib';
import styled from '../../core/styled';
import AddAvailabilityIcon from './Icons/AddAvailabilityIcon';
import ButtonsSeparatorIcon from './Icons/ButtonsSeparatorIcon';
import GrowCaseloadIcon from './Icons/GrowCaseloadIcon';
import Analytics from './Analytics';

const Button = styled(TouchableView)(({ theme: { colors } }) => {
  return {
    color: colors.accessibilityGreenDark,
    fontWeight: 700,
    fontSize: 16,
    marginRight: 12,
  };
});

const IncreaseBonusContentWrapper = styled(View)({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  marginTop: 16,
  fontSize: 16,
  fontFamily: 'Roboto',
});

const IncreaseBonusContentWrapperMobile = styled(View)({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  fontSize: 16,
  fontFamily: 'Roboto',
  alignItems: 'center',
});

const AvailabilityButton = ({
  text,
  year,
  month,
}: {
  text: string;
  year: number;
  month: number;
}) => {
  const history = useHistory();
  const handleCalendarAvailabilityButtonPressed = useCallback(() => {
    Analytics.trackBonusVizAvailabilityButtonClickedEvent({
      selectedMonth: month + 1,
      selectedYear: year,
    });
    history.push('/calendar');
  }, [history, month, year]);

  return (
    <Button
      onPress={handleCalendarAvailabilityButtonPressed}
      style={{ display: 'flex ', flexDirection: 'row' }}
    >
      <AddAvailabilityIcon />
      <View style={{ marginLeft: 3 }}>{text}</View>
    </Button>
  );
};

const GrowCaseloadButton = ({ year, month }: { year: number; month: number }) => {
  const history = useHistory();

  const handleGrowCaseloadButtonPressed = useCallback(() => {
    Analytics.trackBonusVizGrowCaseloadButtonClickedEvent({
      selectedMonth: month + 1,
      selectedYear: year,
    });
    history.push('/grow-caseload');
  }, [history, month, year]);

  return (
    <Button
      onPress={handleGrowCaseloadButtonPressed}
      style={{ display: 'flex ', flexDirection: 'row' }}
    >
      <GrowCaseloadIcon />
      <View style={{ marginLeft: 3 }}>Grow caseload</View>
    </Button>
  );
};

const IncreaseBonusSection = ({ year, month }: { year: number; month: number }) => {
  const { isMobile = false } = useWindowWidth();
  return (
    <>
      {isMobile ? (
        <IncreaseBonusContentWrapperMobile>
          <View style={{ margin: 12 }}>Want to earn a larger bonus?</View>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <AvailabilityButton text="Add availability" year={year} month={month} />
            <View style={{ marginRight: 12 }}>
              <ButtonsSeparatorIcon />
            </View>
            <GrowCaseloadButton year={year} month={month} />
          </View>
        </IncreaseBonusContentWrapperMobile>
      ) : (
        <IncreaseBonusContentWrapper>
          <View style={{ marginRight: 12 }}>Want to earn a larger bonus?</View>
          <AvailabilityButton text="Add live session availability" year={year} month={month} />
          <View style={{ marginRight: 12 }}>
            <ButtonsSeparatorIcon />
          </View>
          <GrowCaseloadButton year={year} month={month} />
        </IncreaseBonusContentWrapper>
      )}
    </>
  );
};

export default IncreaseBonusSection;
