import Svg, { Circle, ClipPath, Defs, G, Path, Rect } from 'svgs';

interface Props {
  width?: string | number;
  height?: string | number;
  color?: string;
}
const HoursAwayFromEarningIcon = ({ width = 24, height = 24, color = '#5C84FB' }: Props) => (
  <Svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <Circle cx="12" cy="12" r="12" fill={color} />
    <G clip-path="url(#clip0_772_33124)">
      <Path
        d="M11.8428 8.91165C11.2964 8.99802 10.7853 8.62642 10.6989 8.08008C10.6125 7.53374 10.9841 7.02256 11.5304 6.93618L16.4691 6.15535C17.0154 6.06897 17.5266 6.44058 17.613 6.98692L18.3938 11.9256C18.4802 12.4719 18.1086 12.9831 17.5623 13.0695C17.0159 13.1558 16.5047 12.7842 16.4184 12.2379L16.0148 9.68524L11.6122 15.7377C11.2874 16.1845 10.6608 16.2836 10.2139 15.9587L6.44979 13.2224L2.87878 18.1309C2.55395 18.5777 1.92735 18.6768 1.48052 18.352C1.03368 18.0271 0.934616 17.4005 1.25945 16.9537L5.41727 11.2342C5.7421 10.7874 6.36869 10.6883 6.81552 11.0131L10.5832 13.7521L14.3954 8.50805L11.8428 8.91165Z"
        fill="white"
      />
      <Rect
        x="-1"
        y="10.9434"
        width="17"
        height="9"
        transform="rotate(-8.98445 -1 10.9434)"
        fill={color}
      />
      <Path
        d="M6.33816 16.374C5.81227 16.5427 5.52271 17.1058 5.69141 17.6317C5.86011 18.1576 6.42318 18.4471 6.94907 18.2784L6.33816 16.374ZM15.7304 6.45222C12.6367 12.8783 9.15224 15.4713 6.33816 16.374L6.94907 18.2784C10.428 17.1625 14.2744 14.0871 17.5324 7.31977L15.7304 6.45222Z"
        fill="white"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_772_33124">
        <Rect width="19" height="16" fill="white" transform="translate(3 4)" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default HoursAwayFromEarningIcon;
