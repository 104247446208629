import {
  BaseButton,
  BookingIconWithX,
  COLORS,
  Huge,
  Large,
  Modal,
  Standard,
  View,
} from '@talkspace/react-toolkit';
import { ProviderDeclineReasonEnum } from 'ts-frontend/types';

interface Props {
  declineReason: ProviderDeclineReasonEnum;
  onClose: () => void;
}

const DeclineBookingSuccessModal = ({ declineReason, onClose }: Props) => {
  const declineConfirmedText =
    declineReason === ProviderDeclineReasonEnum.CLIENT_REQUESTED
      ? 'We’ve let your client know that you’ve declined this session at their request.'
      : 'We’ve let your client know. Please message them to arrange an alternative time for your session.';
  return (
    <Modal isVisible onBackdropPress={onClose} titleText="Your session has been declined">
      <Modal.Panel
        onBackdropPress={onClose}
        contentViewStyle={{ paddingTop: 0, alignItems: 'center' }}
      >
        <View
          style={{
            marginTop: 9,
            marginBottom: 14,
            textAlign: 'center',
            width: 320,
            alignItems: 'center',
          }}
        >
          <BookingIconWithX color={COLORS.purple} />
          <Huge style={{ marginTop: 30, marginBottom: 20 }}>Your session has been declined</Huge>
          <Large>{declineConfirmedText}</Large>
        </View>
        <BaseButton style={{ marginTop: 50 }} onPress={onClose}>
          <Standard variant="standardDarkGrey" style={{ color: COLORS.darkGreen }}>
            Close
          </Standard>
        </BaseButton>
      </Modal.Panel>
    </Modal>
  );
};

export default DeclineBookingSuccessModal;
