import { Dispatch } from 'react';
import { OptionType } from '../Select/types';

export interface SignUpData {
  email?: string;
  nickname: string;
  country?: string;
  state?: string;
  phoneNumber?: string;
  phoneNumberCountryCode?: string;
  referralSource?: OptionType | null;
  isChecked?: boolean;
  isGDPR?: boolean;
  consentGDPRMarketing?: boolean;
  consentGDPRProcessing?: boolean;
  consentGDPRTransferring?: boolean;
}

export enum CreateAccountFormFieldNames {
  email = 'email',
  password = 'password',
  nickname = 'nickname',
  country = 'country',
  state = 'state',
  phone = 'phone',
  referralSource = 'referralSource',
  smsAgreement = 'smsAgreement',
  consentGDPRMarketing = 'consentGDPRMarketing',
  consentGDPRProcessing = 'consentGDPRProcessing',
  consentGDPRTransferring = 'consentGDPRTransferring',
}

export interface CreateAccountFormBaseFields {
  [CreateAccountFormFieldNames.email]?: string;
  [CreateAccountFormFieldNames.password]: string;
  [CreateAccountFormFieldNames.nickname]: string;
  [CreateAccountFormFieldNames.country]?: string;
  [CreateAccountFormFieldNames.state]?: string;
  [CreateAccountFormFieldNames.phone]?: string;
  [CreateAccountFormFieldNames.referralSource]?: OptionType | null;
}
export interface CreateAccountFormFields extends CreateAccountFormBaseFields {
  [CreateAccountFormFieldNames.smsAgreement]: boolean;
}

export interface CreateAccountFormFieldsGDPR extends CreateAccountFormBaseFields {
  [CreateAccountFormFieldNames.consentGDPRMarketing]: boolean;
  [CreateAccountFormFieldNames.consentGDPRProcessing]: boolean;
  [CreateAccountFormFieldNames.consentGDPRTransferring]: boolean;
}

export interface ComponentState {
  signUpAlert: boolean;
  generalErrorMessage: string;
  showGeneralError: boolean;
  clientEmail: string;
  clientNickname: string;
  emailErrorMessage: string;
  nicknameErrorMessage: string;
}

export type MinimumAge = 13 | 18;

export interface CreateAccountYupContext {
  showEmailInput: boolean;
  showCountryStateDropdown: boolean;
  showPhoneNumberInput: boolean;
  phoneNumberOptional: boolean;
  isPasswordInvalid: () => string | undefined;
  onSubmitPassword: (shouldAutoFocus: boolean) => void;
  setComponentState: Dispatch<Partial<ComponentState>>;
}
