import { VoidFunctionComponent } from 'react';
import Svg, { Path } from 'svgs';
import { EmotionStyle, useEmotionTheme } from '../../core/styled';

interface Props {
  color?: string;
  width?: number;
  height?: number;
  hoverColor?: string;
  hasHoverStyles?: boolean;
  style?: EmotionStyle;
}

const FilledEnvelope: VoidFunctionComponent<Props> = ({
  hasHoverStyles,
  hoverColor,
  color,
  width = 24,
  height = 20,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'envelope';
  return (
    <Svg
      width={width}
      height={height}
      fill="none"
      title={titleText}
      aria-label={titleText}
      {...otherProps}
    >
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.84 0.5C1.71923 0.5 0 2.21923 0 4.34V15.66C0 17.7808 1.71923 19.5 3.84 19.5H20.16C22.2808 19.5 24 17.7808 24 15.66V4.34C24 2.21923 22.2808 0.5 20.16 0.5H3.84ZM5.21168 4.83582C4.80202 4.46542 4.16966 4.49726 3.79926 4.90692C3.42886 5.31659 3.4607 5.94895 3.87036 6.31935L12.1632 13.8173L20.1453 6.30583C20.5475 5.92734 20.5667 5.29447 20.1882 4.89227C19.8097 4.49007 19.1768 4.47085 18.7746 4.84934L12.1361 11.0965L5.21168 4.83582Z"
        fill={color || colors.purple}
      />
    </Svg>
  );
};

export default FilledEnvelope;
