import { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { routePromise } from 'ts-frontend/helpers/routePromise';
import { resendOTPKey, initiate2fa, verify2FA, changePassword } from '../../reducers/auth';
import JwtService from '../../utils/jwt/JwtService';
import NavBar from '../Reusable/NavBar/NavBar';
import ChangePasswordView from './ChangePasswordView';

const qs = require('qs');

const ChangePassword = (props) => {
  const {
    location: { hash },
    initiate2fa,
    error,
    userPhone,
    isVerified,
    passwordChangeSuccessfully,
    isLoading,
    otpToken,
    changePassword,
    resendOTPKey,
    verify2FA,
  } = props;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const jwtService = new JwtService();
  const [show2faInput, setShow2faInput] = useState(false);
  const [showChangePasswordForm, setShowChangePasswordForm] = useState(false);
  const [password, setPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isPasswordScoreValid, setIsPasswordScoreValid] = useState(false);
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmedPasswordError, setConfirmedPasswordError] = useState('');

  const isIosDevice = () => /(iPad|iPhone|iPod)/g.test(navigator.userAgent);

  const redirectToLogin = useCallback(() => {
    if (isIosDevice()) {
      // Todo: change this to use deep link
      routePromise('/login');
    } else {
      routePromise('/login');
    }
  }, []);

  useEffect(() => {
    const queryParams = qs.parse(hash);
    if (!queryParams['#token']) {
      redirectToLogin();
    } else {
      const { userID } = jwtService.parseJwt(queryParams['#token']);
      if (!userID) {
        redirectToLogin();
      } else {
        initiate2fa(queryParams['#token'], userID);
      }
    }
  }, [jwtService, hash, initiate2fa, redirectToLogin]);

  useEffect(() => {
    if (userPhone && !isVerified) {
      setShow2faInput(true);
    } else if (isVerified) {
      setTimeout(() => {
        setShow2faInput(false);
        setShowChangePasswordForm(true);
      }, 1000);
    }
  }, [isVerified, userPhone]);

  useEffect(() => {
    if (error && error.type === 'fatalServerError') {
      setShow2faInput(false);
      setShowChangePasswordForm(false);
    }
  }, [error]);

  useEffect(() => {
    if (password && (password.trim().length < 8 || !isPasswordScoreValid)) {
      setIsPasswordValid(false);
    } else setIsPasswordValid(true);
  }, [password, isPasswordScoreValid]);

  useEffect(() => {
    if (passwordChangeSuccessfully) {
      // Do full page reload to prevent persisting 'jwt' sessionContext mode
      routePromise('/login');
    }
  }, [passwordChangeSuccessfully]);

  const resetPasswordErrors = useCallback(() => {
    setPasswordError('');
  }, []);

  const resetConfirmedPasswordErrors = useCallback(() => {
    setConfirmedPasswordError('');
  }, []);

  const onSubmit = () => {
    if (isLoading) return;
    if (!password) {
      setPasswordError('Please enter a password');
      return;
    }
    if (!isPasswordValid) {
      setPasswordError('Invalid Password.');
      return;
    }
    if (isPasswordValid) {
      // Don't show confirmed password errors if there is a password error
      if (confirmedPassword.length === 0) {
        setConfirmedPasswordError('Please confirm new password.');
        return;
      }
      if (password !== confirmedPassword) {
        setConfirmedPasswordError('The passwords you entered don`t match');
        return;
      }
    }
    changePassword(password, confirmedPassword);
  };
  return (
    <div className="room-route">
      <NavBar isLoggedIn={false} />
      <ChangePasswordView
        isLoading={isLoading}
        userPhone={userPhone}
        otpToken={otpToken}
        resendCode={resendOTPKey}
        verify2FA={verify2FA}
        isVerified={isVerified}
        error={error}
        show2faInput={show2faInput}
        showChangePasswordForm={showChangePasswordForm}
        password={password}
        resetPasswordErrors={resetPasswordErrors}
        onPasswordValidationChanged={setIsPasswordScoreValid}
        onPasswordChange={setPassword}
        passwordError={passwordError}
        confirmedPassword={confirmedPassword}
        resetConfirmedPasswordErrors={resetConfirmedPasswordErrors}
        onConfirmedPasswordChange={setConfirmedPassword}
        confirmedPasswordError={confirmedPasswordError}
        handleChangePasswordClick={onSubmit}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userPhone: state.auth.userPhone,
    isLoading: state.auth.isFetching,
    isVerified: state.auth.isVerified,
    error: state.auth.error,
    otpToken: state.auth.otpToken,
    passwordChangeSuccessfully: state.auth.passwordChangeSuccessfully,
  };
};

const mapDispatchToProps = {
  resendOTPKey,
  initiate2fa,
  verify2FA,
  changePassword,
};

const TherapistChangePassword = connect(mapStateToProps, mapDispatchToProps)(ChangePassword);

export default TherapistChangePassword;
