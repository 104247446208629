import { View, Small } from '@talkspace/react-toolkit';
import moment from 'moment';

interface Props {
  renewDate: Date;
  planEnd: Date;
  roomStatus: string;
}

const StatusAndRenewDate = ({ renewDate, planEnd, roomStatus }: Props) => {
  let renewContent = '';

  if (planEnd) {
    renewContent = `Plan end: ${moment(planEnd).format('MMM DD')}`;
  } else if (renewDate) {
    renewContent = `(Renews: ${moment(renewDate).format('MMM DD')})`;
  }

  return (
    <View row>
      <Small variant="smallTSBlack">{`Status: ${roomStatus}`}</Small>
      <Small style={{ marginLeft: 5 }}>{renewContent}</Small>
    </View>
  );
};

export default StatusAndRenewDate;
