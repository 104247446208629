import Svg, { Path } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

const Checkmark2 = ({ width = 12, height = 11, ...otherProps }) => {
  const { colors } = useEmotionTheme();
  const titleText = 'check mark';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 12 11"
      {...otherProps}
    >
      <Path
        transform="translate(-23 -173)"
        stroke={colors.green}
        strokeWidth={2.6}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        d="M25 179.28304L28.4136206 181.946955 32.8 175"
      />
    </Svg>
  );
};

export default Checkmark2;
