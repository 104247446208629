import styled from '@emotion/styled';
import { mergeStyles } from 'react-select';
import Select from '../Select';
import View from '../View';
import ThreeDots from '../icons/ThreeDots';
import { useEmotionTheme } from '../../core/styled';

const Menu = styled(View)({
  backgroundColor: 'white',
  position: 'absolute',
  zIndex: 2,
  right: 30,
  top: 0,
});

const Blanket = styled(View)({
  bottom: 0,
  left: 0,
  top: 0,
  right: 0,
  position: 'fixed',
  zIndex: 1,
});

const DropdownWrapper = ({ children, isOpen, target, menuStyle, containerStyle, onToggle }) => (
  <View row style={{ position: 'relative', ...containerStyle }}>
    {target}
    {isOpen ? <Menu style={menuStyle}>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onToggle} /> : null}
  </View>
);

const EmptyComponent = () => <View hidden />;

const defaultDropdownStyles = {
  container: (provided) => {
    return { ...provided, minWidth: 200, marginTop: 0 };
  },
  menu: (provided) => {
    return {
      ...provided,
      paddingRight: 0,
    };
  },
  option: (provided) => {
    return { ...provided, width: '100%' };
  },
};

function Dropdown({
  isOpen,
  onToggle,
  target,
  containerStyle = {},
  selectStyle = {},
  targetStyle = {},
  components = {},
  styles = {},
  ...otherProps
}) {
  const { colors } = useEmotionTheme();
  return (
    <DropdownWrapper
      isOpen={isOpen}
      onToggle={onToggle}
      target={
        target || (
          <View onClick={onToggle} style={{ padding: `8px 14px`, ...targetStyle }}>
            <ThreeDots color={colors.grey} />
          </View>
        )
      }
      containerStyle={containerStyle}
      menuStyle={selectStyle}
    >
      <Select
        autoFocus
        components={{
          IndicatorSeparator: null,
          Input: EmptyComponent,
          Control: EmptyComponent,
          ...components,
        }}
        controlShouldRenderValue={false}
        isClearable={false}
        isSearchable={false}
        menuIsOpen
        tabSelectsValue={false}
        styles={mergeStyles(defaultDropdownStyles, styles)}
        {...otherProps}
      />
    </DropdownWrapper>
  );
}

export default Dropdown;
