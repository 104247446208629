import { Component } from 'react';
import PropTypes from 'prop-types';
import ReactionButton from './ReactionButton';
import like from './icons/like.svg';
import dislike from './icons/dislike.svg';
import './Reactions.css';

const reactions = {
  like: {
    style: {
      backgroundImage: `url(${like})`,
    },
  },
  dislike: {
    style: {
      backgroundImage: `url(${dislike})`,
    },
  },
};

class Reactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      style: {
        display: 'none',
      },
      selectedReactionClass: '',
      blockShowReactions: false,
    };
  }

  animateDown = () => {
    this.setState({
      style: {
        display: 'none',
      },
      selectedReactionClass: ' animate-init',
      blockShowReactions: true,
    });
    setTimeout(
      () =>
        this.setState({
          selectedReactionClass: ' animate',
        }),
      50
    );
    setTimeout(
      () =>
        this.setState({
          selectedReactionClass: ' animate animate-down',
        }),
      100
    );
    setTimeout(
      () =>
        this.setState({
          selectedReactionClass: '',
          blockShowReactions: false,
        }),
      2000
    );
  };

  hideReactions = () => {
    this.setState({ style: { display: 'none' } });
  };

  mapActionsToReactions = (actions, options) =>
    Object.keys(actions).map((key) => {
      return { ...reactions[key], ...actions[key], ...options[key] };
    });

  showReactions = () => {
    if (!this.state.blockShowReactions) {
      this.setState({ style: { display: 'flex' } });
    }
  };

  render() {
    const { options, actions, selected, defaultValue } = this.props;
    const newReactions = this.mapActionsToReactions(actions, options);
    let selectedReaction;

    switch (selected) {
      case options.like.value:
        selectedReaction = (
          <div className={`like${this.state.selectedReactionClass}`} style={reactions.like.style} />
        );
        break;
      case options.dislike.value:
        selectedReaction = (
          <div
            className={`dislike${this.state.selectedReactionClass}`}
            style={reactions.dislike.style}
          />
        );
        break;
      default:
        selectedReaction = <div>{defaultValue}</div>;
    }

    return (
      <div
        className="reactions-component"
        onMouseOver={this.showReactions}
        onMouseLeave={this.hideReactions}
      >
        {selectedReaction}
        <div className="reactions-container" style={this.state.style}>
          {newReactions.map((reaction, key) => (
            <ReactionButton
              key={key}
              style={reaction.style}
              handler={() => {
                this.animateDown();
                reaction.handler(reaction.value);
              }}
            />
          ))}
        </div>
      </div>
    );
  }
}

Reactions.propTypes = {
  defaultValue: PropTypes.string,
  options: PropTypes.object,
  selected: PropTypes.string,
  actions: PropTypes.array,
};

export default Reactions;
