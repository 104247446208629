import { VoidFunctionComponent } from 'react';
import Svg, { Path, Rect } from 'svgs';
import { useEmotionTheme } from '../../core/styled';

interface PreferencesProps {
  color?: string;
  width?: number;
  height?: number;
}

const Preferences: VoidFunctionComponent<PreferencesProps> = ({
  width = 17,
  height = 13,
  color,
}) => {
  const { colors } = useEmotionTheme();
  const iconColor = color || colors.permaBlueStoneNew;
  return (
    <Svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Rect y="2.55737" width="17" height="2.00173" rx="1.00086" fill={iconColor} />
      <Rect y="8.56262" width="17" height="2.00173" rx="1.00086" fill={iconColor} />
      <Path
        d="M8 9.56314C8 10.67 7.10375 11.5657 6 11.5657C4.89624 11.5657 4 10.67 4 9.56314C4 8.45632 4.89624 7.56055 6 7.56055C7.10375 7.56055 8 8.45632 8 9.56314Z"
        fill={colors.permaPhantomWhite}
        stroke={iconColor}
        stroke-width="2"
      />
      <Path
        d="M13 3.55826C13 4.66507 12.1038 5.56085 11 5.56085C9.89624 5.56085 9 4.66507 9 3.55826C9 2.45144 9.89624 1.55566 11 1.55566C12.1038 1.55566 13 2.45144 13 3.55826Z"
        fill={colors.permaPhantomWhite}
        stroke={iconColor}
        stroke-width="2"
      />
    </Svg>
  );
};

export default Preferences;
