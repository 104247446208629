import { Component } from 'react';
import { Row, Col, Panel, FormControl, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import InputElement from 'react-input-mask';
import 'react-toastify/dist/ReactToastify.min.css';
import { isZipValid } from '@talkspace/react-toolkit';
import { getUserData } from '../../../utils/token';
import apiWrapper from '../../../utils/apiWrapper';
import apiHelper from '../../../utils/api';
import '../FormHeader/FormHeader.css';
import '../Account.css';

class PrivateInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      disableSave: true,
      accountName: '',
      bankName: '',
      accountType: 1,
      achRoutingNumber: '',
      accountNumber: '',
      streetAddress: '',
      state: '',
      zipCode: '',
      city: '',
      checkPayableTo: '',
      originalAccountName: '',
      originalBankName: '',
      originalCity: '',
      originalZipCode: '',
      originalCheckPayableTo: '',
      originalState: '',
      originalStreetAddress: '',
      originalAccountType: 1,
      originalAccountNumber: '',
      originalAchRoutingNumber: '',
      confirmAchRoutingNumber: '',
      confirmAccountNumber: '',
      accountNameValid: true,
      bankNameValid: true,
      achRoutingNumberValid: true,
      accountNumberValid: true,
      validInputAccountNumber: true,
      invalidInputAccountNumberMessage: '',
      validInputAch: true,
      invalidInputAchMessage: '',
      validInput: true,
      validInputCity: true,
      validInputStreetAddress: true,
      validInputCheckPayable: true,
      validInputZipCode: true,
      validAccountType: true,
      isLoaded: false,
    };

    this.checkAccountType = this.checkAccountType.bind(this);
    this.checkState = this.checkState.bind(this);
    this.checkStreetAddress = this.checkStreetAddress.bind(this);
    this.checkZipCode = this.checkZipCode.bind(this);
    this.checkCity = this.checkCity.bind(this);
    this.checkCheckPayableTo = this.checkCheckPayableTo.bind(this);
    this.checkAchRoutingNumber = this.checkAchRoutingNumber.bind(this);
    this.checkAccountNumber = this.checkAccountNumber.bind(this);
    this.checkConfirmAccountNumber = this.checkConfirmAccountNumber.bind(this);
    this.checkConfirmAchRoutingNumber = this.checkConfirmAchRoutingNumber.bind(this);
    this.checkAccountName = this.checkAccountName.bind(this);
    this.checkBankName = this.checkBankName.bind(this);
    this.toggleFormEdit = this.toggleFormEdit.bind(this);
    this.saveForm = this.saveForm.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.getPrivateInfo = this.getPrivateInfo.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.handleZipCodeBlur = this.handleZipCodeBlur.bind(this);
  }

  componentDidMount() {
    this.getPrivateInfo();
  }

  getPrivateInfo() {
    const userId = getUserData().id;

    return apiWrapper
      .get(`${apiHelper().apiEndpoint}/api/v1/therapist/${userId}/payment-info`)
      .then((response) => {
        this.setState(
          {
            accountNumber: response.data.data.accountNumber,
            confirmAccountNumber: response.data.data.accountNumber,
            originalAccountNumber: response.data.data.accountNumber,
            accountType:
              response.data.data.accountType !== 1 && response.data.data.accountType !== 2
                ? ''
                : response.data.data.accountType,
            originalAccountType: response.data.data.accountType,
            achRoutingNumber: response.data.data.achRoutingNumber,
            originalAchRoutingNumber: response.data.data.achRoutingNumber,
            confirmAchRoutingNumber: response.data.data.achRoutingNumber,
            city: response.data.data.checkCity,
            originalCity: response.data.data.checkCity,
            checkPayableTo: response.data.data.checkPayableTo,
            originalCheckPayableTo: response.data.data.checkPayableTo,
            state: response.data.data.checkState,
            originalState: response.data.data.checkState,
            streetAddress: response.data.data.checkStreetAddress,
            originalStreetAddress: response.data.data.checkStreetAddress,
            zipCode: response.data.data.checkZipcode,
            originalZipCode: response.data.data.checkZipcode,
            accountName: response.data.data.nameOnAccount,
            originalAccountName: response.data.data.nameOnAccount,
            bankName: response.data.data.bankName,
            originalBankName: response.data.data.bankName,
            isLoaded: true,
          },
          this.validateInitialInfo
        );
        this.props.setIsSectionLoaded({ paymentInformation: true });
      })
      .catch((err) => {
        toast(
          <div
            className="toaster toaster-error"
            style={{ background: '#FF0000', color: '#FFF', padding: '12px', fontSize: '16px' }}
          >
            Server Error!
          </div>,
          { autoClose: 3000 }
        );
      });
  }

  cancelEdit() {
    this.refreshState();
    this.toggleFormEdit();
  }

  checkAccountName(event) {
    this.setState(
      { accountName: event.target.value, accountNameValid: event.target.value.length !== 0 },
      this.validateForm
    );
  }

  checkAccountNumber(event) {
    let validInputAccountNumber = true;
    let invalidInputAccountNumberMessage = '';

    if (event.target.value.length === 0) {
      validInputAccountNumber = false;
      invalidInputAccountNumberMessage = 'This field is required.';
    } else if (!/^\d+$/.test(event.target.value)) {
      validInputAccountNumber = false;
      invalidInputAccountNumberMessage = 'Must be digits.';
    } else if (event.target.value !== this.state.confirmAccountNumber) {
      validInputAccountNumber = false;
      invalidInputAccountNumberMessage = 'Fields must match.';
    }

    this.setState(
      {
        accountNumber: event.target.value,
        confirmAccountNumber: '',
        validInputAccountNumber,
        invalidInputAccountNumberMessage,
      },
      this.validateForm
    );
  }

  checkAccountType(event) {
    const targetValue = Number(event.target.value);
    this.setState(
      { accountType: targetValue, validAccountType: targetValue === 1 || targetValue === 2 },
      this.validateForm
    );
  }

  checkAchRoutingNumber(event) {
    let validInputAch = true;
    let invalidInputAchMessage = '';

    if (event.target.value.length === 0) {
      validInputAch = false;
      invalidInputAchMessage = 'This field is required.';
    } else if (!/^\d+$/.test(event.target.value)) {
      validInputAch = false;
      invalidInputAchMessage = 'Must be digits.';
    } else if (event.target.value.length !== 9) {
      validInputAch = false;
      invalidInputAchMessage = 'Must be exactly 9 digits.';
    } else if (event.target.value !== this.state.confirmAchRoutingNumber) {
      validInputAch = false;
      invalidInputAchMessage = 'Fields must match.';
    }

    this.setState(
      {
        achRoutingNumber: event.target.value,
        confirmAchRoutingNumber: '',
        validInputAch,
        invalidInputAchMessage,
      },
      this.validateForm
    );
  }

  checkBankName(event) {
    this.setState(
      { bankName: event.target.value, bankNameValid: event.target.value.length !== 0 },
      this.validateForm
    );
  }

  checkCheckPayableTo(event) {
    this.setState(
      {
        checkPayableTo: event.target.value,
        validInputCheckPayable: event.target.value.length !== 0,
      },
      this.validateForm
    );
  }

  checkCity(event) {
    this.setState(
      { city: event.target.value, validInputCity: event.target.value.length !== 0 },
      this.validateForm
    );
  }

  checkConfirmAccountNumber(event) {
    let validInputAccountNumber = true;
    let invalidInputAccountNumberMessage = '';

    if (event.target.value !== this.state.accountNumber) {
      validInputAccountNumber = false;
      invalidInputAccountNumberMessage = 'Fields must match.';
    }

    this.setState(
      {
        confirmAccountNumber: event.target.value,
        validInputAccountNumber,
        invalidInputAccountNumberMessage,
      },
      this.validateForm
    );
  }

  checkConfirmAchRoutingNumber(event) {
    let validInputAch = true;
    let invalidInputAchMessage = '';

    if (event.target.value !== this.state.achRoutingNumber) {
      validInputAch = false;
      invalidInputAchMessage = 'Fields must match.';
    }

    this.setState(
      { confirmAchRoutingNumber: event.target.value, validInputAch, invalidInputAchMessage },
      this.validateForm
    );
  }

  checkState(event) {
    this.setState(
      { state: event.target.value, validInputState: event.target.value.length !== 0 },
      this.validateForm
    );
  }

  checkStreetAddress(event) {
    this.setState(
      {
        streetAddress: event.target.value,
        validInputStreetAddress: event.target.value.length !== 0,
      },
      this.validateForm
    );
  }

  checkZipCode(event) {
    const zipCode = event.target.value;
    this.setState({ zipCode, validInputZipCode: isZipValid(zipCode, 'US') }, this.validateForm);
  }

  handleZipCodeBlur(event) {
    if (event.target.value.length !== 5) {
      this.setState({ zipCode: '', validInputZipCode: false });
    }
  }

  refreshState() {
    this.setState(
      {
        accountName: this.state.originalAccountName,
        bankName: this.state.originalBankName,
        accountType: this.state.originalAccountType,
        achRoutingNumber: this.state.originalAchRoutingNumber,
        accountNumber: this.state.originalAccountNumber,
        streetAddress: this.state.originalStreetAddress,
        state: this.state.originalState,
        zipCode: this.state.originalZipCode,
        city: this.state.originalCity,
        checkPayableTo: this.state.originalCheckPayableTo,
        validInputAch: true,
        invalidInputAchMessage: '',
        validInputAccountNumber: true,
        accountNameValid: true,
        bankNameValid: true,
        validInputZipCode: true,
        validInputStreetAddress: true,
        validInputCity: true,
        validInputCheckPayable: true,
        confirmAchRoutingNumber: this.state.originalAchRoutingNumber,
        confirmAccountNumber: this.state.originalAccountNumber,
      },
      this.validateInitialInfo
    );
  }

  saveForm() {
    this.toggleFormEdit();
    const userId = getUserData().id;

    return apiWrapper
      .put(`${apiHelper().apiEndpoint}/api/v1/therapist/${userId}/payment-info`, {
        nameOnAccount: this.state.accountName,
        bankName: this.state.bankName,
        accountType: this.state.accountType,
        achRoutingNumber: this.state.achRoutingNumber,
        accountNumber: this.state.accountNumber,
        checkStreetAddress: this.state.streetAddress,
        checkPayableTo: this.state.checkPayableTo,
        checkCity: this.state.city,
        checkState: this.state.state,
        checkZipcode: this.state.zipCode,
      })
      .then((response) => {
        if (response.data.success) {
          toast(
            <div className="toaster toaster-success">Private details saved successfully!</div>,
            { autoClose: 3000 }
          );
          this.setState({
            originalAccountName: this.state.accountName,
            originalAccountNumber: this.state.accountNumber,
            originalAccountType: this.state.accountType,
            originalAchRoutingNumber: this.state.achRoutingNumber,
            originalBankName: this.state.bankName,
            originalCheckPayableTo: this.state.checkPayableTo,
            originalCity: this.state.city,
            originalState: this.state.state,
            originalStreetAddress: this.state.streetAddress,
            originalZipCode: this.state.zipCode,
          });
        } else {
          toast(
            <div
              className="toaster toaster-error"
              style={{ background: '#FF0000', color: '#FFF', padding: '12px', fontSize: '16px' }}
            >
              Invalid Parameters!
            </div>,
            { autoClose: 3000 }
          );
        }
      })
      .catch((err) => {
        toast(
          <div
            className="toaster toaster-error"
            style={{ background: '#FF0000', color: '#FFF', padding: '12px', fontSize: '16px' }}
          >
            Server Error!
          </div>,
          { autoClose: 3000 }
        );
      });
  }

  toggleFormEdit() {
    const editBool = !this.state.isEdit;
    this.setState({ isEdit: editBool });

    const actionItems = document.querySelectorAll([
      '#paymentInformation .btn-ts-default',
      '#paymentInformation .ts-edit-button',
    ]);
    [].forEach.call(actionItems, (item) => {
      item.classList.toggle('hidden');
    });
  }

  validateForm() {
    const isFormValid = this.props.hideAddressFields
      ? this.state.accountNameValid &&
        this.state.bankNameValid &&
        this.state.validInputAccountNumber &&
        this.state.validInputAch &&
        this.state.validInput &&
        this.state.validAccountType
      : this.state.accountNameValid &&
        this.state.bankNameValid &&
        this.state.validInputAccountNumber &&
        this.state.validInputAch &&
        this.state.validInput &&
        this.state.validInputCity &&
        this.state.validInputStreetAddress &&
        this.state.validInputCheckPayable &&
        this.state.validInputState &&
        this.state.validInputZipCode;
    this.setState({ disableSave: !isFormValid });
  }

  validateInitialInfo() {
    const originalFields = Object.keys(this.state).filter((key) => key.includes('original'));

    originalFields.forEach((originalField) => {
      const fieldName = originalField.replace('original', '');

      this[`check${fieldName}`]({
        target: { value: this.state[originalField] },
      });
    });
  }

  render() {
    const tooltip = (
      <Tooltip id="PaymentInformationTooltip" className="tooltip ts-tooltip ts-profile-text-white">
        ACH Electronic Transfer information is used for payouts. In cases where this cannot be done,
        your Check Payment information will be used to pay you by check. Both are required.
      </Tooltip>
    );
    const confirmACHRoutingNumberInvalid = !this.state.validInputAch ? 'invalid' : '';
    const confirmACHRoutingNumberClass = `confirm-ach-routing ${confirmACHRoutingNumberInvalid}`;
    const confirmAccountNumberInvalid = !this.state.validInputAccountNumber ? 'invalid' : '';
    const confirmAccountNumberClass = `confirm-account-number ${confirmAccountNumberInvalid}`;

    return (
      <div>
        <Col
          xs={12}
          id="paymentInformation"
          className={`ts-panel-title ${this.state.isLoaded ? 'show-panel' : 'hidden-panel'}`}
        >
          <Col xs={5} className="ts-font-black">
            Payment Information
            <OverlayTrigger placement="top" trigger={['click', 'focus', 'hover']} overlay={tooltip}>
              <i className="fa fa-fw fa-question-circle fa-lg" />
            </OverlayTrigger>
          </Col>
          <Col xs={6} />
          <Button className="ts-edit-button pull-right" onClick={this.toggleFormEdit}>
            Edit
          </Button>
          <Button
            className="btn-ts-default btn-ts-green ts-profile-text-white pull-right hidden save-btn"
            onClick={this.saveForm}
            disabled={this.state.disableSave}
          >
            Save Changes
          </Button>
          <Button
            className="btn-ts-default ts-profile-btn-text-green cancel-btn pull-right hidden"
            onClick={this.cancelEdit}
          >
            Cancel
          </Button>
        </Col>
        <Col xs={12}>
          <Panel
            className={`ts-my-account-panel ${this.state.isLoaded ? 'show-panel' : 'hidden-panel'}`}
          >
            <div className="ts-profile-container">
              <Row style={{ marginBottom: '10px' }}>
                <Col className="ts-profile-label" md={2}>
                  {' '}
                  ACH Electronic Transfer{' '}
                </Col>
                <Col md={10}>
                  <Row style={{ marginBottom: '10px' }}>
                    <Col md={4}>
                      <label className="private-information-input-label">Name on Account</label>
                      <FormControl
                        className={this.state.accountNameValid ? '' : 'invalid'}
                        value={this.state.accountName}
                        disabled={!this.state.isEdit}
                        onChange={this.checkAccountName}
                        placeholder="Name on Account"
                      />
                      <p
                        className="ts-text-invalid ts-text-margin"
                        hidden={this.state.accountNameValid}
                      >
                        This field is required.
                      </p>
                    </Col>
                    <Col md={4}>
                      <label className="private-information-input-label">Bank Name</label>
                      <FormControl
                        className={this.state.bankNameValid ? '' : 'invalid'}
                        value={this.state.bankName}
                        disabled={!this.state.isEdit}
                        onChange={this.checkBankName}
                        placeholder="Bank Name"
                      />
                      <p
                        className="ts-text-invalid ts-text-margin"
                        hidden={this.state.bankNameValid}
                      >
                        {' '}
                        This field is required.
                      </p>
                    </Col>
                    <Col md={4}>
                      <label className="private-information-input-label">Account Type</label>
                      <FormControl
                        componentClass="select"
                        disabled={!this.state.isEdit}
                        value={this.state.accountType}
                        onChange={this.checkAccountType}
                        placeholder="Account Type"
                      >
                        <option value="">Choose Type</option>
                        <option value={1}>Checking</option>
                        <option value={2}>Saving</option>
                      </FormControl>
                      <p
                        className="ts-text-invalid ts-text-margin"
                        hidden={this.state.validAccountType}
                      >
                        This field is required.
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <label className="private-information-input-label">ACH Routing Number</label>
                      <InputElement
                        className="form-control"
                        disabled={!this.state.isEdit}
                        value={this.state.achRoutingNumber}
                        onChange={this.checkAchRoutingNumber}
                        style={{ marginBottom: '6px' }}
                        placeholder="ACH Routing Number"
                      />
                      <div hidden={!this.state.isEdit}>
                        <FormControl
                          className={confirmACHRoutingNumberClass}
                          value={this.state.confirmAchRoutingNumber}
                          onChange={this.checkConfirmAchRoutingNumber}
                          disabled={
                            !this.state.isEdit ||
                            this.state.achRoutingNumber.length === 0 ||
                            this.state.invalidInputAchMessage === 'Must be digits.'
                          }
                          style={{ marginBottom: '6px' }}
                          placeholder="Confirm ACH Routing Number"
                        />
                      </div>
                      <p
                        className="ts-text-invalid ts-text-margin"
                        hidden={this.state.validInputAch}
                      >
                        {this.state.invalidInputAchMessage}
                      </p>
                    </Col>
                    <Col md={4}>
                      <label className="private-information-input-label">Account Number</label>
                      <FormControl
                        value={this.state.accountNumber}
                        disabled={!this.state.isEdit}
                        onChange={this.checkAccountNumber}
                        style={{ marginBottom: '6px' }}
                        placeholder="Account Number"
                      />
                      <div hidden={!this.state.isEdit}>
                        <FormControl
                          className={confirmAccountNumberClass}
                          value={this.state.confirmAccountNumber}
                          onChange={this.checkConfirmAccountNumber}
                          disabled={
                            !this.state.isEdit ||
                            this.state.accountNumber.length === 0 ||
                            this.state.invalidInputAccountNumberMessage === 'Must be digits.'
                          }
                          style={{ marginBottom: '6px' }}
                          placeholder="Confirm Account Number"
                        />
                      </div>
                      <p
                        className="ts-text-invalid ts-text-margin"
                        hidden={this.state.validInputAccountNumber}
                      >
                        {this.state.invalidInputAccountNumberMessage}
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {!this.props.hideAddressFields && (
                <Row>
                  <Col md={2} className="ts-profile-label">
                    Check Payment
                  </Col>
                  <Col md={10}>
                    <Row style={{ marginBottom: '10px' }}>
                      <Col md={4}>
                        <label className="private-information-input-label">Street Address</label>
                        <FormControl
                          className={this.state.validInputStreetAddress ? '' : 'invalid'}
                          value={this.state.streetAddress}
                          onChange={this.checkStreetAddress}
                          disabled={!this.state.isEdit}
                          placeholder="Street Address"
                        />
                        <p
                          className="ts-text-invalid ts-text-margin"
                          hidden={this.state.validInputStreetAddress}
                        >
                          This field is required.
                        </p>
                      </Col>
                      <Col md={4}>
                        <label className="private-information-input-label">State</label>
                        <FormControl
                          componentClass="select"
                          disabled={!this.state.isEdit}
                          onChange={this.checkState}
                          value={this.state.state}
                          placeholder={this.state.state}
                        >
                          <option value="" />
                          <option value="AL">Alabama</option>
                          <option value="AK">Alaska</option>
                          <option value="AZ">Arizona</option>
                          <option value="AR">Arkansas</option>
                          <option value="CA">California</option>
                          <option value="CO">Colorado</option>
                          <option value="CT">Connecticut</option>
                          <option value="DE">Delaware</option>
                          <option value="DC">District Of Columbia</option>
                          <option value="FL">Florida</option>
                          <option value="GA">Georgia</option>
                          <option value="HI">Hawaii</option>
                          <option value="ID">Idaho</option>
                          <option value="IL">Illinois</option>
                          <option value="IN">Indiana</option>
                          <option value="IA">Iowa</option>
                          <option value="KS">Kansas</option>
                          <option value="KY">Kentucky</option>
                          <option value="LA">Louisiana</option>
                          <option value="ME">Maine</option>
                          <option value="MD">Maryland</option>
                          <option value="MA">Massachusetts</option>
                          <option value="MI">Michigan</option>
                          <option value="MN">Minnesota</option>
                          <option value="MS">Mississippi</option>
                          <option value="MO">Missouri</option>
                          <option value="MT">Montana</option>
                          <option value="NE">Nebraska</option>
                          <option value="NV">Nevada</option>
                          <option value="NH">New Hampshire</option>
                          <option value="NJ">New Jersey</option>
                          <option value="NM">New Mexico</option>
                          <option value="NY">New York</option>
                          <option value="NC">North Carolina</option>
                          <option value="ND">North Dakota</option>
                          <option value="OH">Ohio</option>
                          <option value="OK">Oklahoma</option>
                          <option value="OR">Oregon</option>
                          <option value="PA">Pennsylvania</option>
                          <option value="RI">Rhode Island</option>
                          <option value="SC">South Carolina</option>
                          <option value="SD">South Dakota</option>
                          <option value="TN">Tennessee</option>
                          <option value="TX">Texas</option>
                          <option value="UT">Utah</option>
                          <option value="VT">Vermont</option>
                          <option value="VA">Virginia</option>
                          <option value="WA">Washington</option>
                          <option value="WV">West Virginia</option>
                          <option value="WI">Wisconsin</option>
                          <option value="WY">Wyoming</option>
                        </FormControl>
                        <p
                          className="ts-text-invalid ts-text-margin"
                          hidden={this.state.validInputState}
                        >
                          This field is required.
                        </p>
                      </Col>
                      <Col md={4}>
                        <label className="private-information-input-label">Zip Code</label>
                        <FormControl
                          className={this.state.validInputZipCode ? '' : 'invalid'}
                          value={this.state.zipCode}
                          disabled={!this.state.isEdit}
                          onChange={this.checkZipCode}
                          onBlur={this.handleZipCodeBlur}
                          placeholder="Zip Code"
                        />
                        <p
                          className="ts-text-invalid ts-text-margin"
                          hidden={this.state.validInputZipCode}
                        >
                          {' '}
                          This field is required.
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginBottom: '10px' }}>
                      <Col md={4}>
                        <label className="private-information-input-label">City</label>
                        <FormControl
                          className={this.state.validInputCity ? '' : 'invalid'}
                          value={this.state.city}
                          disabled={!this.state.isEdit}
                          onChange={this.checkCity}
                          placeholder="City"
                        />
                        <p
                          className="ts-text-invalid ts-text-margin"
                          hidden={this.state.validInputCity}
                        >
                          This field is required.
                        </p>
                      </Col>
                      <Col md={4}>
                        <label className="private-information-input-label">
                          Make Check Payable to
                        </label>
                        <FormControl
                          className={this.state.validInputCheckPayable ? '' : 'invalid'}
                          value={this.state.checkPayableTo}
                          disabled={!this.state.isEdit}
                          onChange={this.checkCheckPayableTo}
                          placeholder="Make Check Payable To"
                        />
                        <p
                          className="ts-text-invalid ts-text-margin"
                          hidden={this.state.validInputCheckPayable}
                        >
                          This field is required.
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
            </div>
          </Panel>
        </Col>
        <ToastContainer
          closeButton={false}
          position={toast.POSITION.BOTTOM_RIGHT}
          hideProgressBar
        />
      </div>
    );
  }
}

export default PrivateInformation;
