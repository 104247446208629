import { FunctionComponent } from 'react';
import './spinner.css';
import { EmotionStyle } from '../../core/styled';

interface SpinnerViewProps {
  style?: EmotionStyle;
  containerStyle?: EmotionStyle;
  primaryColor?: string;
  secondaryColor?: string;
  thickness?: number;
}

const SpinnerView: FunctionComponent<SpinnerViewProps> = ({
  primaryColor,
  containerStyle,
  secondaryColor,
  thickness = 2,
  style = {},
}) => (
  <div className="spinner-container">
    <div className="spinner" style={containerStyle}>
      <div>
        <div
          style={
            primaryColor
              ? {
                  borderColor: primaryColor,
                  borderTopColor: secondaryColor,
                  borderWidth: thickness,
                  ...style,
                }
              : { ...style }
          }
          className="bt-spinner"
        />
      </div>
    </div>
  </div>
);

export default SpinnerView;
