import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const standardPath = '';
const majorPath =
  'M5.40084 5.79297C5.40084 4.96417 5.71696 4.16931 6.27966 3.58326C6.84236 2.99721 7.60554 2.66797 8.40131 2.66797C9.19709 2.66797 9.96027 2.99721 10.523 3.58326C11.0857 4.16931 11.4018 4.96417 11.4018 5.79297C11.4018 6.62177 11.0857 7.41663 10.523 8.00268C9.96027 8.58873 9.19709 8.91797 8.40131 8.91797C7.60554 8.91797 6.84236 8.58873 6.27966 8.00268C5.71696 7.41663 5.40084 6.62177 5.40084 5.79297ZM13.2021 5.79297C13.2021 4.46689 12.6963 3.19512 11.796 2.25743C10.8956 1.31975 9.67455 0.792969 8.40131 0.792969C7.12807 0.792969 5.90698 1.31975 5.00667 2.25743C4.10635 3.19512 3.60056 4.46689 3.60056 5.79297C3.60056 7.11905 4.10635 8.39082 5.00667 9.3285C5.90698 10.2662 7.12807 10.793 8.40131 10.793C9.67455 10.793 10.8956 10.2662 11.796 9.3285C12.6963 8.39082 13.2021 7.11905 13.2021 5.79297ZM1.84904 18.918C2.18284 16.4453 4.22316 14.543 6.68729 14.543H10.1153C11.4243 14.543 12.6095 15.0781 13.4871 15.9492L14.7586 14.625C13.5546 13.4141 11.9194 12.668 10.1116 12.668H6.68729C2.99297 12.668 0 15.7852 0 19.6328C0 20.2734 0.498828 20.793 1.11392 20.793H12.1069C11.9906 20.4492 11.9681 20.0742 12.0544 19.707L12.2457 18.918H1.84904ZM23.0211 10C22.436 9.39062 21.4871 9.39062 20.8983 10L19.7956 11.1484L22.4585 13.9219L23.5612 12.7734C24.1463 12.1641 24.1463 11.1758 23.5612 10.5625L23.0211 10ZM14.0985 17.082C13.9447 17.2422 13.8359 17.4414 13.7834 17.6641L13.2208 20.0117C13.1683 20.2266 13.2283 20.4492 13.3783 20.6055C13.5284 20.7617 13.7421 20.8242 13.9484 20.7695L16.2025 20.1836C16.4126 20.1289 16.6076 20.0156 16.7614 19.8555L21.6071 14.8047L18.9442 12.0312L14.0985 17.082Z';

const pathConfig: IconConfig = {
  standard: {
    default: {
      path: standardPath,
    },
  },
  major: {
    default: {
      path: majorPath,
    },
  },
};

const UserPen = withDSIconMaker(pathConfig, 'user with pen');

export default UserPen;
