import { FunctionComponent, useMemo } from 'react';
import { Standard, View, Button, useEmotionTheme } from '@talkspace/react-toolkit';
import useQueryNotes from 'hooks/notes/useQueryNotes';
import useQueryBusinessLine from 'ts-frontend/hooks/useQueryBusinessLine';
import AccordionContainer from 'components/Reusable/AccordionContainer/AccordionContainer';
import useQueryRoomDetails from 'ts-frontend/hooks/useQueryRoomDetails';
import useQueryUnsubmittedSessions from 'hooks/notes/useQueryUnsubmittedSessions';
import useIsClientDischargeV3OnForPlan from 'ts-frontend/hooks/useIsClientDischargeV3OnForPlan';

import { useHistory, useRouteMatch } from '@/core/routerLib';
import styled from '@/core/styled';
import { getDischargeView } from './utils';

const ButtonV2 = styled(Button)(({ theme: { colors } }) => {
  return {
    width: '100%',
    marginBottom: 15,
    backgroundColor: colors.accessibilityGreenDark,
    fontWeight: 700,
  };
});

const NoteTypeLabel = styled(Standard)(({ theme: { colors } }) => {
  return {
    marginBottom: 10,
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '20px',
    color: colors.purple600,
  };
});

const NoteTypeDescription = styled(Standard)(({ theme: { colors } }) => {
  return {
    marginBottom: 10,
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '18px',
    color: colors.purple600,
  };
});

const Underline = styled(View)(({ theme: { colors } }) => {
  return {
    borderBottom: `1px solid ${colors.grey600}`,
    paddingBottom: 10,
    width: 316,
    marginLeft: -5,
    marginBottom: 10,
  };
});

interface OtherNoteCreationSectionProps {
  title?: string;
  description?: string;
  buttonText?: string;
  onButtonClickAction?: () => void;
  isLast?: boolean;
  dataQa?: string;
}

const OtherNoteCreationSection: FunctionComponent<OtherNoteCreationSectionProps> = ({
  title,
  description,
  onButtonClickAction,
  buttonText,
  isLast,
  dataQa,
}: OtherNoteCreationSectionProps) => (
  <View style={{ marginTop: '10px' }}>
    <NoteTypeLabel>{title}</NoteTypeLabel>
    <NoteTypeDescription>{description}</NoteTypeDescription>
    <ButtonV2 style={{ width: 306 }} onClick={onButtonClickAction} dataQa={dataQa}>
      {buttonText}
    </ButtonV2>
    {!isLast && <Underline />}
  </View>
);

const CreateOtherNotes: FunctionComponent = () => {
  const match = useRouteMatch<{ roomID: string }>();
  const history = useHistory();
  const { data: { clientUserID, isRoomDischarged } = {} } = useQueryRoomDetails(
    match.params.roomID
  );
  const { data: businessLine } = useQueryBusinessLine(match.params.roomID, clientUserID);
  const { data: notes } = useQueryNotes(match.params.roomID);
  const { colors } = useEmotionTheme();
  const isClientDischargeV3OnForPlan = useIsClientDischargeV3OnForPlan(match.params.roomID);

  const { data: unsubmittedSessionsData } = useQueryUnsubmittedSessions({
    roomID: match.params.roomID,
  });

  const otherNotesCreationSectionsData = useMemo((): Record<
    string,
    OtherNoteCreationSectionProps
  > => {
    const base = match.url.replace('/notes-tab', '');
    return {
      collateralNote: {
        title: 'Collateral note',
        description:
          'Document contact you have with someone other than the identified client (spouse, family member, guardian), interactions impacted by a technical error or ones that don’t meet the billing requirements, and clinically-relevant information that can’t be included in a progress note like a safety plan or risk assessment.',
        buttonText: 'Create a collateral note',
        onButtonClickAction: () => {
          history.push(`${base}/collateral-notes/new`);
        },
        dataQa: 'collateralNoteButton',
      },
      psychotherapyNote: {
        title: 'Psychotherapy note',
        description: `Write down your reflections and experience of the session for your professional use only. These notes are not part of the client's official medical record, are not visible to other providers, and aren't required.`,
        buttonText: 'Create a psychotherapy note',
        onButtonClickAction: () => {
          history.push(`${base}/psychotherapy-notes/new`);
        },
        dataQa: 'psychotherapyNoteButton',
      },
      caseConsultationNote: {
        title: 'Case consultation note',
        description:
          'Document communication with another treating provider, such as a PCP, pediatrician, prescriber, therapist, or state agency.',
        buttonText: 'Create a case consultation note',
        onButtonClickAction: () => {
          history.push(`${base}/case-consultation-notes/new`);
        },
        dataQa: 'caseConsultationNoteButton',
      },
      dischargeNote: {
        title: 'Discharge note',
        description:
          'Document the conclusion of treatment with a client including the reason for discharge such as client moved out of state, lack of client response for over 28 days, or client achieved goals.',
        buttonText: 'Discharge client',
        onButtonClickAction: () => {
          history.push(`${base}/${getDischargeView(notes?.items)}`);
        },
        dataQa: 'dischargeNoteButton',
      },
      dischargeNoteV2: {
        title: 'Discharge note',
        description:
          'Document the conclusion of treatment with a client including the reason for discharge such as client moved out of state, lack of client response for over 28 days, or client achieved goals.',
        buttonText: 'Discharge client',
        onButtonClickAction: () => {
          unsubmittedSessionsData?.unsubmittedSessions.length
            ? history.push(`${base}/incomplete-notes`)
            : history.push(`${base}/${getDischargeView(notes?.items)}`);
        },
        dataQa: 'dischargeNoteButton',
      },
    };
  }, [match.url, history, notes?.items, unsubmittedSessionsData?.unsubmittedSessions.length]);

  return (
    <AccordionContainer
      title="Create other notes"
      containerClass="ts-component-accordion-container-for-notes-tab-v2"
      arrowColor={colors.accessibilityGreenDark}
      dataQa="createOtherNotesButton"
    >
      <View style={{ paddingLeft: 5 }}>
        <OtherNoteCreationSection {...otherNotesCreationSectionsData.collateralNote} />
        <OtherNoteCreationSection {...otherNotesCreationSectionsData.caseConsultationNote} />
        {(businessLine?.isBH || businessLine?.isEAP || businessLine?.isPsychiatry) &&
          !notes?.isClientDischarged &&
          !isClientDischargeV3OnForPlan && (
            <OtherNoteCreationSection
              {...otherNotesCreationSectionsData.dischargeNote}
              isLast={businessLine?.isPsychiatry}
            />
          )}
        {isClientDischargeV3OnForPlan && !isRoomDischarged && (
          <OtherNoteCreationSection
            {...otherNotesCreationSectionsData.dischargeNoteV2}
            isLast={businessLine?.isPsychiatry}
          />
        )}
        {!businessLine?.isPsychiatry && (
          <OtherNoteCreationSection {...otherNotesCreationSectionsData.psychotherapyNote} isLast />
        )}
      </View>
    </AccordionContainer>
  );
};

export default CreateOtherNotes;
