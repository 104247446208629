import { FunctionComponent, Dispatch, SetStateAction } from 'react';

import { EmotionStyle } from '@/core/styled/styled';
import InputSendView from './InputSendView';

interface InputSendProps {
  onSend(): void;
  hide?: boolean;
  isUpdating?: boolean;
  style?: EmotionStyle;
  setIsSendClicked: Dispatch<SetStateAction<boolean>>;
}

const InputSend: FunctionComponent<InputSendProps> = ({
  hide,
  onSend,
  isUpdating,
  style,
  setIsSendClicked,
}) => (
  <InputSendView
    hide={hide}
    onPress={onSend}
    disabled={isUpdating}
    setIsSendClicked={setIsSendClicked}
    text="Send"
    style={style}
  />
);

export default InputSend;
