import React from 'react';
import { Button, ButtonProps, Big } from '@talkspace/react-toolkit';
import styled from '@/core/styled/styled';

const StyledContinueButton = styled(Button)(({ theme: { colors }, disabled }) => {
  return {
    backgroundColor: disabled ? colors.extraLightGrey : colors.permaDarkCyan,
    marginTop: 30,
  };
});

interface Props extends ButtonProps {
  label?: string;
}

const ContinueButton = (props: Props) => {
  const { children, label = 'Continue', ...rest } = props;
  return (
    <StyledContinueButton type="submit" stretch {...rest}>
      <Big variant="bigWide">{label}</Big>
    </StyledContinueButton>
  );
};

export default ContinueButton;
