import Svg, { Path } from 'svgs';

const GreenCircledCheckbox = () => (
  <Svg xmlns="http://www.w3.org/2000/svg" width="68" height="68" fill="none" viewBox="0 0 68 68">
    <Path
      fill="#009B8D"
      d="M20.77 33.743a2.5 2.5 0 00-3.554 3.517l3.554-3.517zm10.104 13.764l-1.777 1.758 2.045 2.067L32.88 49l-2.005-1.493zm20.624-23.512a2.5 2.5 0 00-4.01-2.986l4.01 2.986zm2.663-16.748C39.385-3.887 18.38-.935 7.246 13.841l3.993 3.009C20.712 4.28 38.58 1.768 51.152 11.24l3.009-3.993zM7.246 13.841C-3.89 28.617-.937 49.621 13.839 60.756l3.01-3.993C4.277 47.29 1.765 29.42 11.238 16.85l-3.993-3.009zm6.593 46.915c14.776 11.135 35.78 8.183 46.915-6.593l-3.993-3.01c-9.473 12.571-27.342 15.083-39.913 5.61l-3.009 3.993zm46.915-6.593c11.135-14.776 8.183-35.781-6.593-46.916l-3.01 3.994c12.571 9.472 15.083 27.342 5.61 39.913l3.993 3.009zM17.216 37.26l11.88 12.005 3.555-3.517L20.77 33.743l-3.554 3.517zM32.879 49l18.62-25.005-4.011-2.986-18.62 25.005L32.879 49z"
    />
  </Svg>
);

export default GreenCircledCheckbox;
