import * as React from 'react';

import { TSInputFormik, EmotionStyle } from '@talkspace/react-toolkit';

type Props = React.ComponentProps<typeof TSInputFormik> & { style?: EmotionStyle };

// NOTE: Unfortunately margins and height are not compatible with TSInputFormik.
// Had to create a variant for this flow only
const SignupInput = ({ style, ...otherProps }: Props) => (
  <TSInputFormik
    {...otherProps}
    wrapperStyle={{
      marginTop: 16,
      marginBottom: 0,
      ...style,
    }}
    inputStyle={{ height: 55, fontSize: 16 }}
  />
);

export default SignupInput;
