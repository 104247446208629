import { FunctionComponent } from 'react';
import * as React from 'react';
import styled from '../../core/styled';
import { COLORS } from '../../constants/commonStyles';
import Text, { TextProps } from '../Text';

type Variant = 'extraHuge' | 'extraHugeWhite' | 'extraHugeBranded' | 'extraHugeBrightGreen';

interface ExtraHugeProps extends TextProps {
  variant?: Variant;
}

const extraHugeDefaultStyle = {
  fontWeight: 700,
  fontSize: 29,
  lineHeight: '38px',
  letterSpacing: 0.1,
  color: COLORS.black,
};

const variants = {
  default: {
    ...extraHugeDefaultStyle,
  },
  white: {
    ...extraHugeDefaultStyle,
    color: COLORS.white,
  },
  branded: {
    ...extraHugeDefaultStyle,
    color: COLORS.blueStone,
    fontFamily: 'Quarto, Georgia',
    fontSize: 29,
    lineHeight: '34px',
  },
  brightGreen: {
    ...extraHugeDefaultStyle,
    fontSize: 29,
    color: COLORS.Limeade,
    fontFamily: 'Roboto-Bold',
  },
};

const ExtraHugeDefault = styled(Text)(variants.default);
const ExtraHugeWhite = styled(Text)(variants.white);
const ExtraHugeBranded = styled(Text)(variants.branded);
const ExtraHugeBrightGreen = styled(Text)(variants.brightGreen);

const selectStyle = (variant: Variant | undefined, props: Omit<ExtraHugeProps, 'variant'>) => {
  switch (variant) {
    case 'extraHuge':
      return <ExtraHugeDefault {...props} />;
    case 'extraHugeWhite':
      return <ExtraHugeWhite {...props} />;
    case 'extraHugeBranded':
      return <ExtraHugeBranded {...props} />;
    case 'extraHugeBrightGreen':
      return <ExtraHugeBrightGreen {...props} />;
    default:
      return <ExtraHugeDefault {...props} />;
  }
};

// dangerouslySetInnerHTML needed to allow for premature flow configs in quickmatch-web
const ExtraHuge: FunctionComponent<
  ExtraHugeProps & Pick<React.ComponentProps<typeof Text>, 'dangerouslySetInnerHTML'>
> = ({ variant, ...props }) => selectStyle(variant, props);

export default ExtraHuge;
