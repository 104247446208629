import { Component } from 'react';
import { Checkbox, FormControl } from 'react-bootstrap';
import './CheckListAdder.css';

const getStyle = (disabled, invisible, checked) => {
  let style = 'checkbox-green';
  if (disabled) style = `${style} disabled`;
  if (invisible) style = `${style} invisible`;
  if (checked) style = `${style} checked`;
  return style;
};

class ChecklistAdder extends Component {
  constructor(props) {
    super(props);
    const item = { [props.displayField]: '', checked: false, ...props.itemInit };
    this.state = {
      item,
      style: getStyle(
        props.disabled,
        !(!!item && !!item[props.displayField] && !!item[props.displayField].length),
        item.checked
      ),
    };

    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.textarea = null;
  }

  handleKeyUp(event) {
    const {
      props: { itemInit, displayField, changeHandler, disabled, unCheckEmpty },
      state: { item },
    } = this;

    if (unCheckEmpty && event.target.value.trim() === '') return;
    if (event.keyCode === 13) {
      let { value } = event.target;
      if (unCheckEmpty) value = value.trim();
      changeHandler(event, { ...item, [displayField]: value, checked: true });
      this.setState(
        {
          item: {
            [displayField]: '',
            checked: false,
            ...itemInit,
          },
        },
        () => {
          this.textarea.value = '';
        }
      );
    } else {
      const newItem = {
        ...itemInit,
        [displayField]: event.target.value,
        checked: item.checked,
      };
      this.setState({
        item: newItem,
        style: getStyle(
          disabled,
          !(!!newItem && !!newItem[displayField] && !!newItem[displayField].length),
          newItem.checked
        ),
      });
    }
  }

  render() {
    const {
      props: { placeholder, displayField, changeHandler, disabled, itemInit, textAreaDataQa },
      state: { item, style },
    } = this;
    return (
      <div className="checklist-adder">
        <Checkbox
          className={style}
          checked={item.checked}
          onChange={(event) => {
            this.setState({
              item: { ...item, checked: event.target.checked },
            });
            if (event.target.checked) {
              changeHandler(event, { ...item, checked: true });
              this.textarea.value = '';
              this.setState({
                item: { [displayField]: '', checked: false, ...itemInit },
                style: getStyle(
                  disabled,
                  !(!!item && !!this.textarea.value && !!this.textarea.value.length),
                  item.checked
                ),
              });
            }
          }}
          disabled={disabled}
        />
        <FormControl
          componentClass="textarea"
          inputRef={(ref) => {
            this.textarea = ref;
          }}
          placeholder={placeholder}
          onKeyUp={this.handleKeyUp}
          data-qa={textAreaDataQa}
        />
      </div>
    );
  }
}

export default ChecklistAdder;
