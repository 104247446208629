import { useReducer } from 'react';

export default function useObjectState<T>(initialState: T, reducerName?: string) {
  function reducer(currentState: T, newState: Partial<T>) {
    return { ...currentState, ...newState };
  }
  if (reducerName) {
    Object.defineProperty(reducer, 'name', {
      value: reducerName,
    });
  }
  return useReducer(reducer, initialState);
}
