import Svg, { Path } from 'svgs';
import { useEmotionTheme, EmotionStyle } from '../../core/styled';

interface Props {
  width?: number;
  height?: number;
  color?: string;
  dataQa?: string;
  style?: EmotionStyle;
}

const Calendar = ({ width = 24, height = 25, color, dataQa, style, ...props }: Props) => {
  const { colors } = useEmotionTheme();
  const titleText = 'Calendar';
  return (
    <Svg
      width={width}
      height={height}
      data-qa={dataQa}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 24 25"
      style={style}
      {...props}
    >
      <Path
        d="M5.5 4v-.6A1.4 1.4 0 016.9 2h.089a1.4 1.4 0 011.4 1.4V4h7.222v-.6a1.4 1.4 0 011.4-1.4h.089a1.4 1.4 0 011.4 1.4V4h.5a5 5 0 015 5v13a5 5 0 01-5 5H5a5 5 0 01-5-5V9a5 5 0 015-5h.5zm0 2.8H5A2.2 2.2 0 002.8 9v13A2.2 2.2 0 005 24.2h14a2.2 2.2 0 002.2-2.2V9A2.2 2.2 0 0019 6.8h-.5v.8A1.4 1.4 0 0117.1 9h-.089a1.4 1.4 0 01-1.4-1.4v-.8H8.39v.8a1.4 1.4 0 01-1.4 1.4H6.9a1.4 1.4 0 01-1.4-1.4v-.8zm5.75 9.2h6.5a.25.25 0 01.25.25v4.5a.25.25 0 01-.25.25h-6.5a.25.25 0 01-.25-.25v-4.5a.25.25 0 01.25-.25z"
        transform="translate(-603 -3032) translate(600 2906) translate(3 124)"
        fill={color || colors.permaMediumPurple}
        fillRule="nonzero"
        stroke="none"
        strokeWidth={1}
      />
    </Svg>
  );
};

export default Calendar;
