import { FunctionComponent } from 'react';
import { View, Avatar } from '@talkspace/react-toolkit';
import apiHelper from '@/core/api/apiHelper';
import styled from '@/core/styled';

const CurrentTherapistAvatar = styled(Avatar)({
  zIndex: 1,
  marginRight: -16,
  borderRight: '6px solid white',
  borderRadius: '50%',
  marginLeft: 75,
});

const CancelBookingAvatarRow: FunctionComponent<{
  therapistUserID: number;
}> = ({ therapistUserID }) => (
  <View
    align="center"
    style={{
      width: 300,
      marginTop: 15,
    }}
    row
  >
    <CurrentTherapistAvatar
      image={`${apiHelper().cdnEndpoint}/images/application/therapist/220/${therapistUserID}.jpg`}
      height={76}
      width={76}
    />
    <View
      style={{
        width: 0,
      }}
    />
    <Avatar height={76} width={76} />
  </View>
);

export default CancelBookingAvatarRow;
