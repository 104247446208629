import { useCallback, useReducer } from 'react';
import moment from 'moment-timezone';
import API from '../utils/upcomingBookingsApiHelper';
import { getUserData } from '../../../utils/token';
import {
  AvailabilityWeekdaysSetByHour,
  AvailabilityEnumByDateByHour,
  AvailabilityAPIPayload,
  Availability,
  AvailabilityCalendarState,
  AvailabilityCalendarDataPoints,
  ChangeAvailabilityHourPayload,
  Constraint,
  AvailabilityEnum,
} from '../typesV2';

const initialState: AvailabilityCalendarState = {
  availabilityEnumByDateByHour: undefined,
  availabilityWeekdaysSetByHour: undefined,
  formHasChanged: false,
  isLoading: false,
  isError: false,
};

const availabilityEnumToggleOrder: AvailabilityEnum[] = [
  'recurring-unavailability',
  'one-time-availability',
  'recurring-availability',
  'one-time-unavailability',
];

function getNextState(prevState: Availability): AvailabilityEnum {
  const idx = availabilityEnumToggleOrder.indexOf(prevState.availability);
  return idx > -1 ? availabilityEnumToggleOrder[(idx + 1) % 4] : 'time-off';
}

function addWeekdayToHourSetDict(currentState: any, action: any) {
  const set = currentState.availabilityWeekdaysSetByHour[action.hour] || new Set();
  set.add({ day: action.weekday, partial: false });
  return {
    ...currentState.availabilityWeekdaysSetByHour,
    [action.hour]: set,
  };
}

function removeWeekdayFromHourSetDict(currentState: any, action: any) {
  const set = currentState.availabilityWeekdaysSetByHour[action.hour];
  if (set) {
    const record = Array.from(set).find((it: any) => it.day === action.weekday);
    set.delete(record);
  }

  return {
    ...currentState.availabilityWeekdaysSetByHour,
    [action.hour]: set,
  };
}

function addAvailabilityEnumToDict(currentState: any, action: any, availablilityEnum: any) {
  return {
    ...currentState.availabilityEnumByDateByHour,
    [action.hour]: {
      ...currentState.availabilityEnumByDateByHour[action.hour],
      [action.date]: availablilityEnum
        ? { availability: availablilityEnum, partial: false }
        : undefined,
    },
  };
}

type AvailabilityCalendarReducerActions =
  | ({ type: 'setAvailability' } & AvailabilityCalendarDataPoints)
  | { type: 'getAvailability' }
  | ({ type: 'receiveGetAvailability' } & AvailabilityCalendarDataPoints)
  | { type: 'putAvailability' }
  | ({ type: 'receivePutAvailability' } & AvailabilityCalendarDataPoints)
  | ({ type: 'changeAvailabilityHour' } & ChangeAvailabilityHourPayload)
  | ({ type: 'changeAvailabilityHour' } & ChangeAvailabilityHourPayload)
  | { type: 'setIsError' };

// typically we use dumb reducers, i.e. those that don't have case statement blocks that
// access the previous state; however, since this is a state machine, prev state is key to
// next state. Therefore it is safer and clearer to use a 'smart' reducer
function reducer(
  currentState: AvailabilityCalendarState,
  action: AvailabilityCalendarReducerActions
): AvailabilityCalendarState {
  switch (action.type) {
    case 'getAvailability':
    case 'putAvailability': {
      return {
        ...currentState,
        isLoading: true,
        isError: false,
      };
    }
    case 'setAvailability':
    case 'receiveGetAvailability':
    case 'receivePutAvailability': {
      return {
        ...currentState,
        availabilityEnumByDateByHour: action.availabilityEnumByDateByHour,
        availabilityWeekdaysSetByHour: action.availabilityWeekdaysSetByHour,
        isLoading: false,
        isError: false,
        formHasChanged: false,
      };
    }
    case 'changeAvailabilityHour': {
      switch (getNextState(action.prevState)) {
        case 'one-time-availability': {
          return {
            ...currentState,
            availabilityEnumByDateByHour: addAvailabilityEnumToDict(
              currentState,
              action,
              'one-time-availability'
            ),
            formHasChanged: true,
            isError: false,
          };
        }
        case 'recurring-availability': {
          return {
            ...currentState,
            availabilityWeekdaysSetByHour: addWeekdayToHourSetDict(currentState, action),
            availabilityEnumByDateByHour: addAvailabilityEnumToDict(
              currentState,
              action,
              undefined
            ),
            formHasChanged: true,
            isError: false,
          };
        }
        case 'one-time-unavailability': {
          return {
            ...currentState,
            availabilityEnumByDateByHour: addAvailabilityEnumToDict(
              currentState,
              action,
              'one-time-unavailability'
            ),
            formHasChanged: true,
            isError: false,
          };
        }
        case 'recurring-unavailability': {
          return {
            ...currentState,
            availabilityWeekdaysSetByHour: removeWeekdayFromHourSetDict(currentState, action),
            availabilityEnumByDateByHour: addAvailabilityEnumToDict(
              currentState,
              action,
              undefined
            ),
            formHasChanged: true,
            isError: false,
          };
        }
        default: {
          return currentState;
        }
      }
    }
    case 'setIsError': {
      return {
        ...currentState,
        isLoading: false,
        isError: true,
      };
    }
    default:
      return currentState;
  }
}

function transformAPIResponseToState(
  apiResponse: AvailabilityAPIPayload,
  timezone: string
): AvailabilityCalendarDataPoints {
  const { constraints, availabilities, unavailabilities } = apiResponse;
  const availabilityWeekdaysSetByHour = constraints.reduce(
    (prev, { day, start: hour, partial }) => {
      return {
        ...prev,
        [hour]: prev[hour] ? prev[hour].add({ day, partial }) : new Set([{ day, partial }]),
      };
    },
    {}
  );
  const availabilitiesWithHourAndEnum = availabilities.map((a) => {
    const availabilityMoment = moment.tz(a.start, timezone);
    return {
      hour: availabilityMoment.hour(),
      date: availabilityMoment.format('YYYY-MM-DD'),
      availability: 'one-time-availability',
      partial: a.partial,
    };
  });
  const unavailabilitiesWithHourAndEnum = unavailabilities.map((a) => {
    const unavailabilityMoment = moment.tz(a.start, timezone);
    return {
      hour: unavailabilityMoment.hour(),
      date: unavailabilityMoment.format('YYYY-MM-DD'),
      availability: 'one-time-unavailability',
      partial: a.partial,
    };
  });
  const availabilityEnumByDateByHour = [
    ...availabilitiesWithHourAndEnum,
    ...unavailabilitiesWithHourAndEnum,
  ].reduce((prev, { hour, date, availability, partial }) => {
    return {
      ...prev,
      [hour]: {
        ...prev[hour],
        [date]: { availability, partial },
      },
    };
  }, {});

  return {
    availabilityEnumByDateByHour,
    availabilityWeekdaysSetByHour,
  };
}

function flattenEnumByDateByHourDict(
  dateByHourDict: AvailabilityEnumByDateByHour,
  availabilityEnum: 'one-time-unavailability' | 'one-time-availability',
  timezone: string,
  availabilityWeekdaysSetByHour?: AvailabilityWeekdaysSetByHour
): string[] {
  return Object.entries(dateByHourDict).reduce((prev, [hour, enumByDate]) => {
    const availabilityWeekdaysSet =
      (availabilityWeekdaysSetByHour && availabilityWeekdaysSetByHour[hour]) || new Set();
    let filteredEnumsByDateEntries;
    // These 'invalid' conditions need to be filtered here and NOT in state for a very important reason
    // The local state needs to allow for historical preservation of an 'invalid' state, as state is dependent
    // on two data points, since any click might just be a transitory state in the UI, moving to a 'valid' state
    // First, is this function even called to flatten one-time-availabilities?
    if (availabilityEnum === 'one-time-availability') {
      // should only exist in payload if there IS NOT recurring date already
      filteredEnumsByDateEntries = Object.entries(enumByDate).filter(([date, availability]) => {
        const weekday = moment(date).format('dddd');
        const isAlreadyRecurringDate = Array.from(availabilityWeekdaysSet).find(
          (it: any) => it.day === weekday
        );
        return availability?.availability === 'one-time-availability' && !isAlreadyRecurringDate;
      });
    } else {
      // else we are calling to flatten one-time-unavailabilities
      // these should only exist if there IS also a recurring date
      filteredEnumsByDateEntries = Object.entries(enumByDate).filter(([date, availability]) => {
        const weekday = moment(date).format('dddd');
        const isAlreadyRecurringDate = Array.from(availabilityWeekdaysSet).find(
          (it: any) => it.day === weekday
        );
        return availability?.availability === 'one-time-unavailability' && isAlreadyRecurringDate;
      });
    }

    return [
      ...prev,
      ...filteredEnumsByDateEntries.map(([date, { partial }]) => {
        return {
          start: moment
            .tz(date, timezone)
            .hour(+hour)
            .toISOString(),
          partial,
        };
      }),
    ];
  }, [] as string[]);
}

function transformStateToAPIResponse(
  { availabilityEnumByDateByHour, availabilityWeekdaysSetByHour }: any,
  timezone: string
): Partial<AvailabilityAPIPayload> {
  const constraints =
    availabilityWeekdaysSetByHour &&
    Object.entries(availabilityWeekdaysSetByHour).reduce(
      (prev, [hour, weekdaySet]) => [
        ...prev,
        ...Array.from(weekdaySet as any).map(
          (it: any) =>
            ({ start: +hour, end: +hour + 1, day: it.day, partial: it.partial } as Constraint)
        ),
      ],
      [] as Constraint[]
    );

  const availabilities =
    availabilityEnumByDateByHour &&
    flattenEnumByDateByHourDict(
      availabilityEnumByDateByHour,
      'one-time-availability',
      timezone,
      availabilityWeekdaysSetByHour
    );
  const unavailabilities =
    availabilityEnumByDateByHour &&
    flattenEnumByDateByHourDict(
      availabilityEnumByDateByHour,
      'one-time-unavailability',
      timezone,
      availabilityWeekdaysSetByHour
    );

  return {
    constraints,
    availabilities,
    unavailabilities,
  };
}

interface AvailabilityCalendarActions {
  dispatchSetAvailability: (payload: AvailabilityCalendarDataPoints) => void;
  dispatchGetAvailability: (timezone: string) => Promise<void>;
  dispatchPutAvailability: (
    payload: AvailabilityCalendarDataPoints,
    timezone: string
  ) => Promise<void>;
  dispatchChangeAvailabilityHour: (action: ChangeAvailabilityHourPayload) => void;
}

export default function useAvailabilityCalendar(): [
  AvailabilityCalendarState,
  AvailabilityCalendarActions
] {
  const [state, dispatch] = useReducer(reducer, initialState);

  const dispatchChangeAvailabilityHour = (action: ChangeAvailabilityHourPayload) => {
    dispatch({ type: 'changeAvailabilityHour', ...action });
  };
  const dispatchSetAvailability = (payload: AvailabilityCalendarDataPoints) => {
    dispatch({ type: 'setAvailability', ...payload });
  };
  const dispatchGetAvailability = (timezone: string) => {
    dispatch({ type: 'getAvailability' });
    const therapistUserID = getUserData().id;
    return API.getAvailabilityV2(therapistUserID)
      .then((res) => {
        if (res.data) {
          const statePayload = transformAPIResponseToState(res.data, timezone);
          return dispatch({ type: 'receiveGetAvailability', ...statePayload });
        }
        throw new Error('mal-formed API response');
      })
      .catch(() => {
        dispatch({ type: 'setIsError' });
      });
  };
  const dispatchPutAvailability = (payload: AvailabilityCalendarDataPoints, timezone: string) => {
    dispatch({ type: 'putAvailability' });
    const apiPayload = transformStateToAPIResponse(payload, timezone);
    const therapistUserID = getUserData().id;
    return API.putAvailabilityV2(therapistUserID, apiPayload)
      .then((res) => {
        if (res.data) {
          const statePayload = transformAPIResponseToState(res.data, timezone);
          return dispatch({ type: 'receivePutAvailability', ...statePayload });
        }
        throw new Error('mal-formed API response');
      })
      .catch(() => {
        dispatch({ type: 'setIsError' });
      });
  };

  return [
    state,
    {
      dispatchSetAvailability: useCallback(dispatchSetAvailability, []),
      dispatchGetAvailability: useCallback(dispatchGetAvailability, []),
      dispatchPutAvailability: useCallback(dispatchPutAvailability, []),
      dispatchChangeAvailabilityHour: useCallback(dispatchChangeAvailabilityHour, []),
    },
  ];
}
