import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const standardPath = '';
const majorPath =
  'M9.5 17.168H18.25C18.5938 17.168 18.875 16.8867 18.875 16.543V7.79297H17C16.3086 7.79297 15.75 7.23438 15.75 6.54297V4.66797H9.5C9.15625 4.66797 8.875 4.94922 8.875 5.29297V16.543C8.875 16.8867 9.15625 17.168 9.5 17.168ZM18.25 19.043H9.5C8.12109 19.043 7 17.9219 7 16.543V5.29297C7 3.91406 8.12109 2.79297 9.5 2.79297H15.9648C16.6289 2.79297 17.2656 3.05469 17.7344 3.52344L20.0195 5.80859C20.4883 6.27734 20.75 6.91406 20.75 7.57812V16.543C20.75 17.9219 19.6289 19.043 18.25 19.043ZM4.1875 6.54297C4.70703 6.54297 5.125 6.96094 5.125 7.48047V17.4805C5.125 19.3789 6.66406 20.918 8.5625 20.918H16.0625C16.582 20.918 17 21.3359 17 21.8555C17 22.375 16.582 22.793 16.0625 22.793H8.5625C5.62891 22.793 3.25 20.4141 3.25 17.4805V7.48047C3.25 6.96094 3.66797 6.54297 4.1875 6.54297Z';

const pathConfig: IconConfig = {
  standard: {
    default: {
      path: standardPath,
    },
  },
  major: {
    default: {
      path: majorPath,
    },
  },
};

const Files = withDSIconMaker(pathConfig, 'stack of papers');

export default Files;
