import { COLORS } from '@talkspace/react-toolkit';
import Svg, { Path } from 'svgs';

interface CaseIconProps {
  color?: string;
  width?: number;
  isHovering?: boolean;
  isActive?: boolean;
  height?: number;
  style?: {};
}

const CaseIcon = ({
  isHovering,
  isActive,
  color = isActive ? COLORS.pacificBlue : COLORS.dimGray,
  width = 30,
  height = 24,
  ...otherProps
}: CaseIconProps) => (
  <Svg width={width} height={height} viewBox="0 0 30 24" {...otherProps}>
    <Path
      d={
        isActive
          ? 'M21.44 8.753h-5.39a.92.92 0 01-.701-.325L14.392 7.3A3.68 3.68 0 0011.586 6H7.76A2.76 2.76 0 005 8.76v10.08a2.76 2.76 0 002.76 2.76h13.68a2.76 2.76 0 002.76-2.76v-7.327a2.76 2.76 0 00-2.76-2.76z'
          : 'M11.586 6a3.68 3.68 0 012.662 1.14l.144.16.957 1.128c.15.177.36.29.587.318l.115.007h5.389a2.76 2.76 0 012.755 2.592l.005.168v7.327a2.76 2.76 0 01-2.592 2.755l-.168.005H7.76a2.76 2.76 0 01-2.755-2.592L5 18.84V8.76a2.76 2.76 0 012.592-2.755L7.76 6h3.826zm0 1.84H7.76a.92.92 0 00-.914.813l-.006.107v10.08c0 .472.355.86.813.914l.107.006h13.68a.92.92 0 00.914-.813l.006-.107v-7.327a.92.92 0 00-.813-.914l-.107-.006h-5.39a2.76 2.76 0 01-1.968-.826l-.136-.149-.957-1.128a1.84 1.84 0 00-1.224-.641l-.18-.009z'
      }
      fill={color}
      fillRule="nonzero"
      stroke="none"
      strokeWidth={1}
    />
  </Svg>
);

export default CaseIcon;
