import { useQuery } from 'react-query';
import { LiveSessionModality } from 'ts-frontend/types';
import { AccountType } from 'ts-frontend/entities/Room';
import apiWrapper from '../../utils/apiWrapper';
import apiHelper from '../../utils/api';
import dashboardKeys from './queryKeys';
import {
  SessionSubmissionStatus,
  UnsubmittedLiveSession,
  UnsubmittedMessagingSession,
} from '../notes/useQueryUnsubmittedSessions';

const fiveMinutesInMilliseconds: number = 1000 * 60 * 5;

export type DynamicTaskType = 'unsubmittedSessionTask' | 'reviewableTask';
export type ReviewType = 'noShow' | 'lateCancellation';
export type SessionStatus = SessionSubmissionStatus | ReviewType;
export interface DynamicTask {
  type: DynamicTaskType;
  firstName: string | null;
  lastName: string | null;
  clientNickname: string | null;
  preferredName: string | null;
  accountType: AccountType;
  serviceStartDate: Date;
  serviceEndDate: Date | null;
  roomID: number;
  isDischarged: boolean;
  userID: number;
  sessionStatus: SessionStatus;
  sessionModality: 'live' | 'messaging';
  isTransferred: boolean;
  id: string;
  gender: string;
  age: string;
}

export interface ReviewableLiveTask extends DynamicTask {
  liveSessionType: LiveSessionModality;
  reviewType: ReviewType;
  bookingID: number;
}

export interface UnsubmittedSessionTask
  extends UnsubmittedLiveSession,
    UnsubmittedMessagingSession,
    DynamicTask {
  therapistID: number;
}

const fetchTasks = (therapistID: number) => async () => {
  const { data } = await apiWrapper.get(
    `${apiHelper().apiEndpoint}/v3/therapists/${therapistID}/tasks`
  );
  return data.data;
};

const useQueryTaskList = (therapistID: number, isEnabled: boolean = true) =>
  useQuery<UnsubmittedSessionTask[], Error>({
    queryKey: dashboardKeys.tasklistV3(therapistID),
    queryFn: fetchTasks(therapistID),
    cacheTime: Infinity,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    staleTime: fiveMinutesInMilliseconds,
    retry: false,
    refetchOnMount: true,
    enabled: isEnabled,
  });

export default useQueryTaskList;
