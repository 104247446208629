import {
  useTranslation as useTranslationI18Next,
  UseTranslationOptions,
  UseTranslationResponse,
} from 'react-i18next';

import { TranslationNamespace } from '../types';
import { useTranslationState } from './translationContext';

const useTranslation = <T extends TranslationNamespace>(
  namespace?: T,
  options?: UseTranslationOptions<undefined>
): UseTranslationResponse<T, undefined> => {
  const { isTranslationEnabled } = useTranslationState();
  const i18NextData = useTranslationI18Next<T>(namespace, {
    lng: isTranslationEnabled ? undefined : 'en',
    ...options,
  });
  return i18NextData;
};

export default useTranslation;
