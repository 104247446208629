import * as React from 'react';
import Svg, { G, Path } from 'svgs';
import { useEmotionTheme, EmotionStyle } from '../../core/styled';

interface ChatCalendarProps {
  width?: number;
  height?: number;
  color?: string;
  style?: EmotionStyle;
}

const ChatCalendar: React.VFC<ChatCalendarProps> = ({
  color,
  width = 28,
  height = 30,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'chat calendar';
  return (
    <Svg width={width} height={height} title={titleText} aria-label={titleText} {...otherProps}>
      <G fill="none" fillRule="evenodd">
        <Path
          d="M6.047 0a1.98 1.98 0 011.98 1.98l-.001 2.057h11.652V1.98A1.98 1.98 0 0121.659 0h.054a1.98 1.98 0 011.98 1.98v2.097l-.005.14a5.402 5.402 0 014.018 5.22v15.056a5.4 5.4 0 01-5.4 5.4H5.4a5.4 5.4 0 01-5.4-5.4V9.438a5.402 5.402 0 014.018-5.222 2.164 2.164 0 01-.005-.14V1.98A1.98 1.98 0 015.993 0h.054z"
          fill={colors.purple || color}
        />
        <Path
          d="M11.138 23.259a.865.865 0 00.27.904c.262.22.628.263.932.11.852-.434 1.985-1.187 2.673-2.343h.565c2.994 0 5.422-2.447 5.422-5.465S18.572 11 15.578 11h-3.156c-2.774 0-5.1 2.11-5.392 4.89-.291 2.78 1.545 5.333 4.257 5.919a4.03 4.03 0 01-.15 1.45z"
          fill="#FFF"
          fillRule="nonzero"
        />
      </G>
    </Svg>
  );
};

export default ChatCalendar;
