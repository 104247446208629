import { keyframes } from '@emotion/core';
import React, { useEffect } from 'react';
import Modal from '../../../components/Modal';
import TouchableView from '../../../components/TouchableView';
import View from '../../../components/View';
import styled from '../../../core/styled';
import { spacing } from '../../tokens';

const slideUp = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;

const slideDown = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
`;

const BottomSheetContainer = styled(View)<{ isOpen: boolean }>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  border-radius: ${spacing.space200}px ${spacing.space200}px 0px 0px;
  animation: ${({ isOpen }) => (isOpen ? slideUp : slideDown)} 0.3s forwards;
  padding: ${spacing.space200}px 0 calc(${spacing.space300}px + env(safe-area-inset-bottom)) 0;
  max-height: 80vh;
  overflow: hidden;
`;

const BottomSheetSlot = styled(View)`
  padding: ${spacing.space300}px ${spacing.space900}px ${spacing.space300}px ${spacing.space900}px;
  gap: ${spacing.space0}px;
  border: 0.5px 0px 0px 0px;
  opacity: 0px;
`;

const BottomSheetMenuOption = styled(TouchableView)`
  width: 100%;
  padding: ${spacing.space100}px ${spacing.space400}px ${spacing.space100}px ${spacing.space400}px;
  gap: ${spacing.space100}px;
  opacity: 0px;
`;

interface BottomSheetProps {
  isOpen: boolean;
  onClose: () => void;
}

type MenuOptionProps = Pick<React.ComponentProps<typeof TouchableView>, 'onPress'>;

const BottomSheet: React.FC<BottomSheetProps> & {
  Slot: React.FC;
  MenuOption: React.FC<MenuOptionProps>;
} = ({ isOpen, onClose, children }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  // Create a separate state variable for the modal to allow the BottomSheetContainer to animate out before closing
  useEffect(() => {
    if (isOpen) {
      setIsModalOpen(isOpen);
      return undefined;
    }
    const timeout = setTimeout(() => {
      setIsModalOpen(isOpen);
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, [isOpen]);
  return (
    <Modal isVisible={isModalOpen} onBackdropPress={onClose}>
      <BottomSheetContainer isOpen={isOpen}>{children}</BottomSheetContainer>
    </Modal>
  );
};

BottomSheet.Slot = ({ children }) => <BottomSheetSlot>{children}</BottomSheetSlot>;

BottomSheet.MenuOption = ({ children, ...props }) => (
  <BottomSheetMenuOption {...props}>{children}</BottomSheetMenuOption>
);

export default BottomSheet;
