import Svg, { Path } from 'svgs';
import { VoidFunctionComponent } from 'react';

import { useEmotionTheme } from '../../core/styled';

interface DischargeSuccessIconProps {
  color?: string;
  width?: number;
  height?: number;
}

const DischargeSuccessIcon: VoidFunctionComponent<DischargeSuccessIconProps> = ({
  width = 67,
  height = 66,
  color,
}) => {
  const { colors } = useEmotionTheme();
  const iconColor = color || colors.forestGreen;
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 67 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.1563 8.24407C38.483 -2.0595 19.0459 0.672204 8.74236 14.3455C-1.56121 28.0188 1.1705 47.4559 14.8438 57.7595C28.5171 68.063 47.9542 65.3313 58.2578 51.658C68.5613 37.9847 65.8296 18.5476 52.1563 8.24407V8.24407Z"
        stroke={iconColor}
        strokeWidth="4"
        strokeMiterlimit="16"
        strokeLinecap="round"
      />
      <Path
        d="M18.4934 34.5017L30.3742 46.5067L48.9934 21.5017"
        stroke={iconColor}
        strokeWidth="5"
        strokeLinecap="round"
      />
    </Svg>
  );
};

export default DischargeSuccessIcon;
