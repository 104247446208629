import Brick from './Brick';
import BrickV1 from './BrickV1';

import { withVersioning } from '../../utils';
import { VersionMap } from '../../types';

const versionMap: VersionMap<React.ComponentProps<typeof Brick>> = {
  '1.0.0': BrickV1,
  DefaultComponent: Brick,
};

export default withVersioning(versionMap);
