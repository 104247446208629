import { FunctionComponent } from 'react';
import styled, { EmotionTheme } from '../../core/styled';
import { nativeOnlyStyle } from '../../core/styleHelpers';
import Text, { TextProps } from '../Text';
import type { VariantFunction } from './types';

type Variant =
  | 'big'
  | 'bigDarkGrey'
  | 'bigDarkGreen'
  | 'bigWide'
  | 'bigMedium'
  | 'bigBlueStone'
  | 'bigBlueStoneNew'
  | 'lividBlueMedium'
  | 'bigBold';

interface BigProps extends TextProps {
  variant?: Variant;
}

const bigDefaultStyle = (colors: EmotionTheme['colors']) => {
  return {
    fontSize: 17,
    fontWeight: 700,
    lineHeight: '23px',
    letterSpacing: 0.1,
    color: colors.black,
  };
};

const variants: Record<string, VariantFunction> = {
  default: ({ theme: { colors } }) => {
    return {
      ...bigDefaultStyle(colors),
    };
  },
  grey: ({ theme: { colors } }) => {
    return {
      ...bigDefaultStyle(colors),
      color: colors.cometGrey,
    };
  },
  green: ({ theme: { colors } }) => {
    return {
      ...bigDefaultStyle(colors),
      color: colors.rainforestGreen,
    };
  },
  wideWhite: ({ theme: { colors } }) => {
    return {
      ...bigDefaultStyle(colors),
      letterSpacing: 0.5,
      color: colors.white,
    };
  },
  mediumGray: ({ theme: { colors } }) => {
    return {
      ...bigDefaultStyle(colors),
      fontWeight: 500,
      color: colors.slateGrey,
      ...nativeOnlyStyle({ fontWeight: '500' }),
    };
  },
  blueStone: ({ theme: { colors } }) => {
    return {
      ...bigDefaultStyle(colors),
      color: colors.blueStone,
    };
  },
  blueStoneNew: ({ theme: { colors } }) => {
    return {
      ...bigDefaultStyle(colors),
      color: colors.permaBlueStoneNew,
    };
  },
  lividBlueMedium: ({ theme: { colors } }) => {
    return {
      ...bigDefaultStyle(colors),
      color: colors.permaLividBlueNew,
      fontWeight: 400,
    };
  },
  bold: ({ theme: { colors } }) => {
    return {
      ...bigDefaultStyle(colors),
      fontWeight: 700,
    };
  },
};

const BigDefault = styled(Text)(variants.default);
const BigGrey = styled(Text)(variants.grey);
const BigGreen = styled(Text)(variants.green);
const BigWideWhite = styled(Text)(variants.wideWhite);
const BigMediumGray = styled(Text)(variants.mediumGray);
const BigBlueStone = styled(Text)(variants.blueStone);
const BigBlueStoneNew = styled(Text)(variants.blueStoneNew);
const BigLividBlueMedium = styled(Text)(variants.lividBlueMedium);
const BigBold = styled(Text)(variants.bold);

const selectStyle = (variant: Variant | undefined, props: Omit<BigProps, 'variant'>) => {
  switch (variant) {
    case 'big':
      return <BigDefault {...props} />;
    case 'bigDarkGrey':
      return <BigGrey {...props} />;
    case 'bigDarkGreen':
      return <BigGreen {...props} />;
    case 'bigWide':
      return <BigWideWhite {...props} />;
    case 'bigMedium':
      return <BigMediumGray {...props} />;
    case 'bigBlueStone':
      return <BigBlueStone {...props} />;
    case 'bigBlueStoneNew':
      return <BigBlueStoneNew {...props} />;
    case 'lividBlueMedium':
      return <BigLividBlueMedium {...props} />;
    case 'bigBold':
      return <BigBold {...props} />;
    default:
      return <BigDefault {...props} />;
  }
};

const Big: FunctionComponent<BigProps> = ({ variant, ...props }) => selectStyle(variant, props);

export default Big;
