import { DischargeSuccessIcon, Large, View, styled } from '@talkspace/react-toolkit';
import Submenu from 'components/Reusable/Submenu';

const SuccessText = styled(View)(({ theme: { colors } }) => {
  return {
    marginTop: 16,
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: 26,
    fontweight: 700,
    lineHeight: '34px',
    color: colors.TSBlack,
  };
});

const ContentWrapper = styled(View)({ alignSelf: 'center', width: 334, alignItems: 'center' });

const DischargeSuccess = ({ title }: { title: string }) => (
  <Submenu
    childComponents={[
      <ContentWrapper>
        <DischargeSuccessIcon />
        <SuccessText>Discharge successful!</SuccessText>
      </ContentWrapper>,
    ]}
    title={title}
    titleComponent={
      <View>
        <Large variant="largeBoldWide">{title}</Large>
      </View>
    }
  />
);

export default DischargeSuccess;
