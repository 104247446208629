import { View } from '@talkspace/react-toolkit';
import styled from '../../core/styled';
import BonusBreakdownLiveSessionsIcon from './Icons/BonusBreakdownLiveSessionsIcon';
import BonusBreakdownMessagingSessionsIcon from './Icons/BonusBreakdownMessagingSessionsIcon';

const BreakdownWrapper = styled(View)(({ theme: { colors } }) => {
  return { background: colors.permaGreyed, marginTop: 70 };
});

const BreakdownTitle = styled(View)({
  fontSize: 17,
  marginBottom: 16,
  fontWeight: 700,
  lineHeight: '23px',
  fontFamily: 'roboto',
});

const ModalityBreakdownWrapper = styled(View)({
  fontSize: 16,
  fontWeight: 700,
  display: 'flex',
  flexDirection: 'row',
  marginBottom: 8,
});

const BreakdownRow = styled(View)({
  fontSize: 16,
  fontWeight: 400,
  lineHeight: '20px',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: 8,
  paddingLeft: 28,
});

const BreakdownSection = styled(View)({
  margin: 16,
  marginBottom: 0,
  borderBottom: '1px solid #D1D6E1',
});

const BreakdownSum = styled(View)({
  margin: 16,
  fontSize: 16,
  fontWeight: 700,
  lineHeight: '20px',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

const ParenthesisWrapper = styled(View)(({ theme: { colors } }) => {
  return { color: colors.permaGrayLike, fontWeight: 400 };
});

const RowWithSpacing = styled(View)({ display: 'flex', flexDirection: 'row', gap: 3 });

const BonusBreakdown = ({
  timeInLiveSession,
  wordsSent,
  wordsSentToHours,
  wordsReceived,
  wordsReceivedToHours,
  mediaSent,
  mediaReceived,
  hoursAchieved,
}: {
  timeInLiveSession: number;
  wordsSent: number;
  wordsSentToHours: number;
  wordsReceived: number;
  wordsReceivedToHours: number;
  mediaSent: number;
  mediaReceived: number;
  hoursAchieved: number;
}) => (
  <BreakdownWrapper>
    <BreakdownSection>
      <BreakdownTitle>Your clinical hours to date</BreakdownTitle>
      <ModalityBreakdownWrapper>
        <BonusBreakdownLiveSessionsIcon />
        <View style={{ marginLeft: 8 }}>Live Sessions</View>
      </ModalityBreakdownWrapper>
      <BreakdownRow>
        <View>Time in session with client</View>
        <View>{`${timeInLiveSession} hrs`}</View>
      </BreakdownRow>
      <ModalityBreakdownWrapper>
        <BonusBreakdownMessagingSessionsIcon />
        <View style={{ marginLeft: 8 }}>Messaging Sessions</View>
      </ModalityBreakdownWrapper>
      <BreakdownRow>
        <RowWithSpacing>
          Writing text <ParenthesisWrapper>{`(${wordsSent} words)`}</ParenthesisWrapper>
        </RowWithSpacing>
        <View>{`${wordsSentToHours} hrs`}</View>
      </BreakdownRow>
      <BreakdownRow>
        <RowWithSpacing>
          Reading text <ParenthesisWrapper>{`(${wordsReceived} words)`}</ParenthesisWrapper>
        </RowWithSpacing>
        <View>{`${wordsReceivedToHours} hrs`}</View>
      </BreakdownRow>
      <BreakdownRow>
        <View>Recording audio & video</View>
        <View>{`${mediaSent} hrs`}</View>
      </BreakdownRow>
      <BreakdownRow>
        <View>Listening to audio & video</View>
        <View>{`${mediaReceived} hrs`}</View>
      </BreakdownRow>
    </BreakdownSection>
    <BreakdownSum>
      <View>Total clinical hours</View>
      <View>{`${hoursAchieved} hrs`}</View>
    </BreakdownSum>{' '}
  </BreakdownWrapper>
);

export default BonusBreakdown;
