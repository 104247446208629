import { FunctionComponent } from 'react';
import {
  Button,
  Large,
  Small,
  Spinner,
  Standard,
  styled,
  useEmotionTheme,
  View,
} from '@talkspace/react-toolkit';
import Submenu from 'components/Reusable/Submenu';
import PinkAlert from 'components/Icons/PinkAlert';
import useQueryNotes from 'hooks/notes/useQueryNotes';
import { useHistory, useParams } from '@/core/routerLib';

type DialogType = 'delete' | 'save-draft' | 'save-psychotherapy-note' | 'confirm-save' | 'clear';

const dialogQuestions: Record<DialogType, { mainQuestion: string; secondaryQuestion: string }> = {
  delete: {
    mainQuestion: 'Delete note?',
    secondaryQuestion: 'Are you sure you want to delete this note?',
  },
  'save-draft': {
    mainQuestion: 'Save draft?',
    secondaryQuestion: 'Would you like to save this note as a draft?',
  },
  'save-psychotherapy-note': {
    mainQuestion: 'Save note?',
    secondaryQuestion: 'Would you like to save this note?',
  },
  'confirm-save': {
    mainQuestion: 'Are you sure?',
    secondaryQuestion:
      'Upon discharge, you will be unable to complete any pending documentation and your client will be notified that services are over.',
  },
  clear: {
    mainQuestion: 'Clear progress note?',
    secondaryQuestion: 'Are you sure you want to clear all fields in this progress note?',
  },
};

const MainContainer = styled(View)<{ dialogType: DialogType }>(
  ({ dialogType, theme: { colors } }) => {
    return {
      alignSelf: 'center',
      height: dialogType === 'confirm-save' ? 179 : 150,
      width: 306,
      borderColor: colors.pink,
      borderStyle: 'solid',
      borderWidth: 2,
      borderRadius: 5,
      marginTop: 8,
      paddingTop: 22,
      paddingRight: 20,
      paddingLeft: 20,
      paddingBottom: 30,
    };
  }
);

const SmallText = styled(Small)({
  marginBottom: 12,
});

const TitleContainer = styled(View)({
  flexDirection: 'row',
  marginBottom: 10,
});

const BoldButton = styled(Button)({
  fontWeight: 'bold',
  margin: 5,
});

interface Props {
  dialogType: DialogType;
  title: string;
  subtitle?: string;
  isLoading: boolean;
  noButtonFirst?: boolean;
  onYesPress: () => void;
  onNoPress?: () => void;
  showSpinnerOnYes?: boolean;
}

const NoteDialog: FunctionComponent<Props> = ({
  dialogType,
  title,
  subtitle,
  isLoading,
  noButtonFirst = false,
  onYesPress,
  onNoPress,
  showSpinnerOnYes,
}) => {
  const history = useHistory();
  const { roomID } = useParams<{ roomID: string }>();
  useQueryNotes(roomID);
  const { colors } = useEmotionTheme();

  const handleNoPress = () => {
    if (onNoPress) {
      onNoPress();
      return;
    }

    history.push(`/room/${roomID}/notes-tab`);
  };

  const handleYesPress = () => {
    onYesPress();
  };

  const dialogButtons = [
    <BoldButton
      key={0}
      isSecondary
      size="small"
      removeBorder
      onPress={handleNoPress}
      style={{ display: showSpinnerOnYes ? 'none' : 'flex' }}
      dataQa="noButton"
    >
      No
    </BoldButton>,
    showSpinnerOnYes ? (
      <Spinner primaryColor={colors.green} />
    ) : (
      <BoldButton
        key={1}
        isSecondary
        size="small"
        removeBorder
        onPress={handleYesPress}
        isLoading={isLoading}
        dataQa="yesButton"
      >
        Yes
      </BoldButton>
    ),
  ];

  const handleBackButtonPress = () => {
    history.push(`/room/${roomID}/notes-tab`);
  };

  return (
    <Submenu
      childComponents={[
        <MainContainer dialogType={dialogType}>
          <TitleContainer>
            <PinkAlert style={{ marginRight: 10 }} />
            <Standard variant="standardBoldTSBlack">
              {dialogQuestions[dialogType].mainQuestion}
            </Standard>
          </TitleContainer>
          <SmallText variant="smallDarkGrey">
            {dialogQuestions[dialogType].secondaryQuestion}
          </SmallText>
          <View style={{ flexDirection: 'row-reverse' }}>
            {noButtonFirst ? [...dialogButtons].reverse() : dialogButtons}
          </View>
        </MainContainer>,
      ]}
      title={title}
      titleComponent={
        subtitle ? (
          <View>
            <Large variant="largeBoldWide">{title}</Large>
            <Small variant="smallBoldGrey">{subtitle}</Small>
          </View>
        ) : undefined
      }
      onBackAlt={handleBackButtonPress}
    />
  );
};

export default NoteDialog;
