import Svg, { Path } from 'svgs';
import { useEmotionTheme } from '../../../core/styled';

export interface RatingStarProps {
  width?: number;
  height?: number;
  fill?: string;
  stroke?: string;
}

const RatingStar = ({ width = 30, height = 30, fill, stroke, ...props }: RatingStarProps) => {
  const { colorRoles } = useEmotionTheme();
  const titleText = 'star';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 32 30"
      {...props}
    >
      <Path
        d="M12-1.74l5.068 7.215 8.571 2.776-5.234 7.075.029 9.078-8.304-2.833-8.564 2.833.108-8.833-5.313-7.32 8.361-2.623L12-1.74z"
        transform="translate(4 4)"
        fill={fill || colorRoles.system.interactivePrimaryDefault}
        stroke={stroke || colorRoles.system.interactivePrimaryDefault}
        strokeWidth={2}
        fillRule="evenodd"
      />
    </Svg>
  );
};

export default RatingStar;
