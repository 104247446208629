import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Submenu from '../../../../Reusable/Submenu/Submenu';
import { getArchivedTreatmentPlan } from '../../../../../actions/TreatmentPlannerActions';
import { formatDateLong } from '../../../../../utils/date';
import TreatmentPlanView from './TreatmentPlanView';

import './ArchivedTreatmentPlan.css';

class ArchivedTreatmentPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    const location = props.location.pathname.split('/');
    props.getArchivedTreatmentPlan(location[4]);
  }

  getViewContent() {
    return this.props.archivedTreatmentPlan && this.props.archivedTreatmentPlan.id ? (
      <div className="archived-treatment-plan-container">
        <div className="archived-treatment-plan-title">Treatment planner</div>
        <TreatmentPlanView treatmentPlan={this.props.archivedTreatmentPlan} />
      </div>
    ) : null;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {}

  render() {
    return (
      <Submenu
        childComponents={[this.getViewContent()]}
        title={`Treatment Plan - ${formatDateLong(this.props.archivedTreatmentPlan.updatedAt)}`}
        prevRoute={`/room/${this.props.roomID}/archived-treatment-plans`}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    roomID: state.room.roomID,
    archivedTreatmentPlan: state.treatmentPlanner.archivedTreatmentPlan,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ getArchivedTreatmentPlan }, dispatch);

const ArchivedTreatmentPlanContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ArchivedTreatmentPlan);

export default ArchivedTreatmentPlanContainer;
