const File = ({ width = 17, height = 21, color = '#8F9CB2', style = {} }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${width}`} style={style}>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill={color} transform="translate(-93 -126)">
        <path d="M96 126h5.34a4.2 4.2 0 012.998 1.258l4.46 4.544a4.2 4.2 0 011.202 2.942V144a3 3 0 01-3 3H96a3 3 0 01-3-3v-15a3 3 0 013-3z" />
      </g>
    </g>
  </svg>
);

export default File;
