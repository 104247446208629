import { useQuery } from 'react-query';

import {
  ProgressNoteMentalStatusExamAttributes,
  ProgressNotePsychiatryPlanAttributes,
  ProgressNotePsychiatryPlanMedicationAttributes,
  ProgressNoteRiskAssessmentAttributes,
  ProgressNoteSessionAttendeeAttributes,
  ProgressNoteSessionSummaryAttributes,
  NoteStatus,
  ProgressNoteSubstanceUseAttributes,
  ProgressNoteTreatmentPlanGoalAttributes,
  ProgressNoteTreatmentPlanObjectiveAttributes,
  ProgressNoteTreatmentPlanProgressAttributes,
  VideoCall,
} from 'components/Room/CRMContainer/NotesTab/types';
import { states, countries } from '@talkspace/configs';
import apiHelper from '@/utils/api';
import { progressNoteHelperDataQueryKey } from './queryKeys';
import apiWrapper from '../../utils/apiWrapper';

export interface LastProgressNote {
  id: number;
  roomID: number;
  createdAt: Date;
  createdBy: number;
  updatedAt: Date;
  updatedBy: number;
  status: NoteStatus;
  riskAssessmentID: number;
  substanceUseID: number;
  treatmentPlanProgressID: number | null;
  medicalInformationID: number | null;
  mentalStatusExamID: number | null;
  sessionSummaryID: number;
  psychiatryPlanID: number | null;
  modalityID: number;
  serviceStartDate: Date;
  serviceEndDate: Date;
  referralID: number;
  otherReferral: string | null;
  statementCertified: boolean;
  sessionServiceID: number | null;
  sessionAttendees: ProgressNoteSessionAttendeeAttributes[];
  riskAssessment: ProgressNoteRiskAssessmentAttributes;
  substanceUse: ProgressNoteSubstanceUseAttributes;
  treatmentPlanProgress: ProgressNoteTreatmentPlanProgressAttributes;
  sessionSummary: ProgressNoteSessionSummaryAttributes;
  mentalStatusExam: ProgressNoteMentalStatusExamAttributes;
  psychiatryPlan: ProgressNotePsychiatryPlanAttributes;
  psychiatryPlanMedications: ProgressNotePsychiatryPlanMedicationAttributes[];
}

export interface GetProgressNoteHelperDataResponse {
  lastProgressNote: LastProgressNote;
  unassociatedVideoCalls: VideoCall[];
  roomCreationDate: string;
  latestGoalsProgress: Array<
    Pick<ProgressNoteTreatmentPlanGoalAttributes, 'treatmentPlanGoalID' | 'overallProgress'>
  >;
  latestObjectivesProgress: Array<
    Pick<
      ProgressNoteTreatmentPlanObjectiveAttributes,
      'treatmentPlanObjectiveID' | 'overallProgress'
    >
  >;
  ongoingMessagingSession: {
    startDate: string;
  } | null;
  identifiedClient?: string | null;
  clientState: keyof typeof states | null;
  clientCountry: keyof typeof countries | null;
}

const getProgressNoteHelperData = (roomID: string) => async () => {
  const { data } = await apiWrapper.get(
    `${apiHelper().apiEndpoint}/v2/rooms/${roomID}/progress-notes/helper-data`
  );
  return data.data;
};

const useQueryProgressNoteHelperData = (
  roomID: string,
  enabled: boolean = true,
  refetchInterval: false | number | ((data, query) => number | false) = false
) =>
  useQuery<GetProgressNoteHelperDataResponse, Error>({
    queryKey: progressNoteHelperDataQueryKey(roomID),
    queryFn: getProgressNoteHelperData(roomID),
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
    refetchInterval,
    enabled,
  });

export default useQueryProgressNoteHelperData;
