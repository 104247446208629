import {
  View,
  TouchableView,
  Standard,
  useWindowWidthState,
  TSLogo,
  ArrowWithCircleLeft,
  ArrowWithCircleRight,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import { VoidFunctionComponent, useMemo, useCallback } from 'react';
import { useLocation } from 'react-router';
import { useHistory } from '@/core/routerLib';

import styled, { EmotionStyle } from '../../../core/styled';
import IonicSidebarBanner from '../../Dashboard/IonicOnboarding/IonicSidebarBanner';
import { isIonic } from '../../../modules/auth/reactFrame/helpers';

export const REDIRECT_TO = 'redirectTo';

export interface SideBarItems {
  icon: JSX.Element;
  label: string;
  path: string;
  iconStyle?: EmotionStyle;
  menuNameStyle?: EmotionStyle;
  countBadge?: number;
  lightIndicator?: boolean;
  lightColor?: string;
  dataQa?: string;
}

interface SideBarProps {
  sideBarItems: SideBarItems[];
  handleMenuItemPress?: () => void;
  shouldShowExpandCollapseIcon: boolean;
  handleExpandCollapsePress: () => void;
  isSidebarMinimized: boolean;
}

const SideBarContainer = styled(View)(({ theme: { window } }) => {
  const { isMobile } = window;

  const mobileStyle: EmotionStyle = isMobile
    ? { marginTop: 39, paddingLeft: 15, paddingRight: 15 }
    : {};

  return {
    marginTop: 25,
    paddingLeft: 10,
    paddingRight: 10,
    height: isMobile && '90dvh',
    ...mobileStyle,
  };
});

const SideBarItem = styled(View)<{
  isActive: boolean;
  isMobile: boolean;
}>(({ theme: { colors }, isActive }) => {
  return {
    padding: '13px 15px',
    borderRadius: 7,
    cursor: 'pointer',
    alignItems: 'center',
    marginBottom: 5,
    position: 'relative',

    fontWeight: 500,
    ...(isActive && { background: colors.permaLinkWaterGrey }),
    '&:hover': {
      background: colors.permaLinkWaterGrey,
    },
  };
});

const ExpandCollapseClickable = styled(TouchableView)<{}>(({ theme: { colors } }) => {
  return {
    align: 'center',
    justify: 'center',
    height: 31,
    width: 31,
    borderRadius: '50%',
    boxShadow: '0px 4px 10px -4px rgba(0, 22, 36, 0.4)',
    '&:hover': {
      boxShadow: '0px 4px 10px -4px rgba(0, 22, 36, 0.8)',
    },
  };
});

const MenuName = styled(Standard)<{ isActive: boolean }>(({ theme: { colors }, isActive }) => {
  return {
    marginLeft: 33,
    fontWeight: 500,
    color: colors.permaEden,
    ...(isActive && { fontWeight: 'bold', color: colors.black }),
  };
});

const CountIndicator = styled(Standard)(({ theme: { colors } }) => {
  return {
    color: colors.permaRiverBed,
    marginLeft: 2,
  };
});

const RedDot = styled(View)<{
  hasCountIndicator: boolean;
  hasLightIndicator?: boolean;
  lightColor?: string;
}>(({ theme: { colors }, hasCountIndicator, hasLightIndicator, lightColor = colors.permaRed }) => {
  const style = {
    background: lightColor,
    width: 7,
    height: 7,
    borderRadius: '50%',
    marginTop: 2,
    marginLeft: 3,
  };
  return hasCountIndicator || hasLightIndicator ? style : { ...style };
});

const SideBar: VoidFunctionComponent<SideBarProps> = ({
  sideBarItems,
  handleMenuItemPress,
  shouldShowExpandCollapseIcon,
  handleExpandCollapsePress,
  isSidebarMinimized,
}) => {
  const { pathname } = useLocation();
  const { isMobile } = useWindowWidthState();
  const history = useHistory();

  const handleItemPress = useCallback(
    (path: string) => {
      if (handleMenuItemPress) {
        handleMenuItemPress();
        sessionStorage.setItem(REDIRECT_TO, path);
        history.push(path);
      }
    },
    [handleMenuItemPress, history]
  );

  const isLongMenu = useMemo(() => !isSidebarMinimized || isMobile, [isMobile, isSidebarMinimized]);
  const buildSideBarItems = useCallback(
    () =>
      sideBarItems.map((item) => {
        const iconStyle = isLongMenu ? { position: 'absolute', left: 18, ...item?.iconStyle } : {};

        const hasCountBadge = item.countBadge !== undefined && item.countBadge > 0;
        const hasCountIndicator = hasCountBadge && !isSidebarMinimized;
        const hasLightIndicator = item.lightIndicator;
        return (
          <TouchableView
            key={item.path}
            onTouchStart={() => handleItemPress(item.path)}
            onPress={() => handleItemPress(item.path)}
            dataQa={item.dataQa}
          >
            <SideBarItem row isActive={pathname === item.path} isMobile={isMobile}>
              <View style={iconStyle}>{item.icon}</View>
              {isLongMenu && (
                <>
                  <MenuName isActive={pathname === item.path} style={item?.menuNameStyle || {}}>
                    {item.label}
                  </MenuName>

                  {hasCountIndicator && <CountIndicator>({item.countBadge})</CountIndicator>}
                </>
              )}

              {(hasCountBadge || hasLightIndicator) && (
                <RedDot
                  alignSelf="start"
                  hasCountIndicator={hasCountIndicator}
                  hasLightIndicator={hasLightIndicator}
                  lightColor={item.lightColor}
                />
              )}
            </SideBarItem>
          </TouchableView>
        );
      }),
    [handleItemPress, isLongMenu, isMobile, isSidebarMinimized, pathname, sideBarItems]
  );
  const { colors } = useEmotionTheme();

  const arrowStyle = {
    borderRadius: '50%',
    position: 'absolute',
    left: '-6px',
    top: '-2px',
  };

  return (
    <SideBarContainer justify="space-between">
      {!isMobile && shouldShowExpandCollapseIcon && (
        <ExpandCollapseClickable
          style={{
            position: 'absolute',
            transition: '300ms',
            left: isSidebarMinimized ? 57 : 183,
            top: 'calc(((100vh - 68px) / 2) + 31px)',
            zIndex: 3,
          }}
          onPress={handleExpandCollapsePress}
        >
          {isSidebarMinimized ? (
            <ArrowWithCircleRight hoverColor={colors.permaBlueStone} style={arrowStyle} />
          ) : (
            <ArrowWithCircleLeft hoverColor={colors.permaBlueStone} style={arrowStyle} />
          )}
        </ExpandCollapseClickable>
      )}
      <View>
        {isMobile && <TSLogo style={{ marginLeft: 7, marginBottom: 44 }} variant="2024" />}
        {buildSideBarItems()}
      </View>
      {isMobile && isIonic() && <IonicSidebarBanner />}
    </SideBarContainer>
  );
};

export default SideBar;
