import { useEffect, useRef } from 'react';
import {
  Row,
  Col,
  Panel,
  FormGroup,
  FormControl,
  Button,
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap';
import { withRouter, useLocation } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import moment from 'moment';
import { Typeahead } from 'react-bootstrap-typeahead';
import '../Account.css';
import 'react-toastify/dist/ReactToastify.min.css';
import { View } from '@talkspace/react-toolkit';
import { timezones } from '@talkspace/configs';
import useObjectState from 'chat/hooks/useObjectState';
import { isEqual } from 'lodash';

import { useFlags } from 'launchDarkly/FlagsProvider';
import {
  getClientCapacity,
  saveClientCapacity,
  getPhase,
  getBusinessDays,
  saveBusinessDays,
  getDepartureInfo,
  getProviderDailyCaseloadOptions,
} from '../../../actions/AvailabilityActions';
import XIcon from '../XIcon';
import {
  useAvailabilityCalendar,
  useAvailabilityCalendarV2,
  AvailabilityCalendar,
  AvailabilityCalendarV2,
} from '../../../modules/upcomingBookings';
import useScrollIntoView from '../../../hooks/useScrollIntoView';

const businessDaysOptions = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

const TherapistItemToken = (props) => (
  <div className="therapist-item-token">
    {props.option}
    <span className="therapist-token-close-button" onClick={props.onRemove}>
      <XIcon />
    </span>
  </div>
);

const TherapistAvailability = (props) => {
  const [state, setState] = useObjectState({
    isEdit: false,
    sessionVideoHours: [],
    timezones: ['Select Timezone'].concat(Object.keys(timezones)),
    currentTz: '',
    prevTz: '',
    disableSave: true,
    tzChanged: false,
    businessDays: {},
    selectedBusinessDays: [],
    dataEndPoints: 3,
    endPointsRetrieved: 0,
    dailyCaseLoad: 'slow',
  });

  const { calendarAvailability } = useFlags();
  // const calendarAvailability = false;

  const { accountAvailabilityBackwardsCompatibility } = useFlags();

  const scrollComplete = useRef(false);

  const {
    currentTz,
    businessDays,
    getClientCapacity,
    getBusinessDays,
    getDepartureInfo,
    getPhase,
    user,
    toasterMessage,
    timeOffPeriods,
    isUpdating,
    dailyCaseLoad,
    setIsSectionLoaded,
    allSectionsLoaded,
    dailyCaseLoadOptions,
    getProviderDailyCaseloadOptions,
  } = props;

  const calendarAvailabilityObj = useAvailabilityCalendar();

  const calendarAvailabilityV2Obj = useAvailabilityCalendarV2();

  const [
    { availabilityEnumByDateByHour, availabilityWeekdaysSetByHour, formHasChanged, isLoading },
    {
      dispatchGetAvailability,
      dispatchSetAvailability,
      dispatchPutAvailability,
      dispatchChangeAvailabilityHour,
    },
  ] = accountAvailabilityBackwardsCompatibility
    ? calendarAvailabilityV2Obj
    : calendarAvailabilityObj;

  useEffect(() => {
    if (!dailyCaseLoadOptions && !props.isError) {
      getProviderDailyCaseloadOptions();
    }
  }, [dailyCaseLoadOptions, getProviderDailyCaseloadOptions, props.isError]);

  const isLoaded = !isLoading && !isUpdating;

  useEffect(() => {
    setIsSectionLoaded({ availability: isLoaded });
  }, [setIsSectionLoaded, isLoaded]);

  // initial call for resources
  useEffect(() => {
    if (user.id) {
      getClientCapacity(user.id);
      getDepartureInfo(user.id);
      getBusinessDays(user.id);
      getPhase(user.id);
    }
  }, [getBusinessDays, getClientCapacity, getDepartureInfo, getPhase, user.id]);

  useEffect(() => {
    if (currentTz) {
      dispatchGetAvailability(currentTz);
    }
  }, [currentTz, dispatchGetAvailability]);

  const caseloadHeaderRef = useRef(null);
  const availabilityHeaderRef = useRef(null);

  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const scrollTo = searchParams.get('scrollTo');

  useScrollIntoView({
    ref: caseloadHeaderRef,
    trigger: scrollTo === 'capacity',
    options: { behavior: 'smooth' },
    allSectionsLoaded,
  });

  useScrollIntoView({
    ref: availabilityHeaderRef,
    trigger: scrollTo === 'availability',
    options: { behavior: 'smooth' },
    allSectionsLoaded,
  });

  // keep prevAvailabilityState for reset, cancelEdit
  const beforeEditAvailabilityPayloadRef = useRef({
    availabilityEnumByDateByHour,
    availabilityWeekdaysSetByHour,
  });
  useEffect(() => {
    if (
      !beforeEditAvailabilityPayloadRef.current.availabilityEnumByDateByHour &&
      !beforeEditAvailabilityPayloadRef.current.availabilityWeekdaysSetByHour
    ) {
      beforeEditAvailabilityPayloadRef.current = {
        availabilityEnumByDateByHour,
        availabilityWeekdaysSetByHour,
      };
    }
  }, [availabilityEnumByDateByHour, availabilityWeekdaysSetByHour]);
  // detect if calendar has changed
  useEffect(() => {
    if (formHasChanged) {
      setState({ disableSave: false });
    }
  }, [formHasChanged, setState]);

  // update state data from props
  useEffect(() => {
    const selectedBusinessDays = filterActiveBusinessDays(businessDays);
    setState({
      dailyCaseLoad,
      selectedBusinessDays,
      businessDays: formatBusinessDays(selectedBusinessDays),
      currentTz,
      selectedResponseSchedule: businessDays.preferredShift,
    });
  }, [businessDays, businessDays.preferredShift, dailyCaseLoad, currentTz, setState]);

  // handle toaster updates
  const prevToasterMessage = useRef(toasterMessage);
  useEffect(() => {
    if (toasterMessage !== prevToasterMessage.current) {
      prevToasterMessage.current = toasterMessage;
      if (toasterMessage != '') {
        if (props.isError) {
          toast(<div className="toaster toaster-error">{toasterMessage}</div>, {
            autoClose: 3000,
          });
        } else {
          toast(<div className="toaster toaster-success">{toasterMessage}</div>, {
            autoClose: 3000,
          });
        }
      }
    }
  }, [props.isError, toasterMessage]);

  const handleBusinessDaysChange = (daysArray) => {
    const businessDays = formatBusinessDays(daysArray);
    const selectedBusinessDays = filterActiveBusinessDays(businessDays);

    setState(
      {
        disableSave: daysArray.length < 5 && !isPsychiatrist,
        businessDays,
        selectedBusinessDays,
      },
      () => toggleSaveChanges()
    );
  };

  const formatBusinessDays = (selectedDays) =>
    businessDaysOptions.reduce((accumulator, day) => {
      // eslint-disable-next-line no-param-reassign
      accumulator[day] = selectedDays.includes(day);
      return accumulator;
    }, {});

  const filterActiveBusinessDays = (businessDays) =>
    Object.keys(businessDays).filter(
      (day) => businessDaysOptions.includes(day) && businessDays[day]
    );

  const toggleSaveChanges = () => {
    const disableSave = !(
      state.currentTz != 'Select timezone' &&
      (state.selectedBusinessDays.length >= 5 || isPsychiatrist)
    );

    setState({ disableSave });
  };

  const saveForm = () => {
    const selectedBusinessDays = filterActiveBusinessDays(businessDays);

    const payload = {
      businessDays: state.businessDays,
      tz: state.currentTz,
    };

    toggleFormEdit();
    if (formHasChanged || state.tzChanged) {
      dispatchPutAvailability(
        {
          availabilityEnumByDateByHour,
          availabilityWeekdaysSetByHour,
        },
        state.currentTz
      );
      // this resets the non-editable state
      beforeEditAvailabilityPayloadRef.current = {
        availabilityEnumByDateByHour,
        availabilityWeekdaysSetByHour,
      };
    }
    if (props.dailyCaseLoad !== state.dailyCaseLoad) {
      const payload = {
        dailyCaseLoad: state.dailyCaseLoad,
      };
      props
        .saveClientCapacity(props.user.id, payload)
        .then(() => props.getClientCapacity(props.user.id));
    }

    if (
      !isEqual(formatBusinessDays(selectedBusinessDays), state.businessDays) ||
      props.currentTz !== state.currentTz
    ) {
      props.saveBusinessDays(props.user.id, payload).then(() => {
        props.getBusinessDays(props.user.id);
      });
    }
  };

  const tzChange = (event) => {
    const updated = state.currentTz;
    const current = event.target.value;
    if (current !== 'Select Timezone') {
      setState({
        prevTz: updated,
        currentTz: current,
        tzChanged: true,
      });
    }
    toggleSaveChanges();
  };

  const dailyCaseLoadChange = (event) => {
    setState({
      dailyCaseLoad: event.target.value,
    });
    toggleSaveChanges();
  };

  const toggleFormEdit = () => {
    // check if Capacity is editable
    const isEdit = !state.isEdit;
    setState({ isEdit });
    if (!isEdit) {
      setState({ disableSave: true });
    }

    const actionItems = document.querySelectorAll([
      '#availability .btn-ts-default',
      '#availability .ts-edit-button',
    ]);

    [].forEach.call(actionItems, (item) => {
      item.classList.toggle('hidden');
    });
  };

  const cancelEdit = () => {
    const selectedBusinessDays = filterActiveBusinessDays(businessDays);
    setState({ businessDays: formatBusinessDays(selectedBusinessDays), selectedBusinessDays });
    toggleFormEdit();
    dispatchSetAvailability(beforeEditAvailabilityPayloadRef.current);
    getClientCapacity(props.user.id);
  };

  const showCanNotEditToast = () => {
    toast(
      <div className="toaster toaster-error">To make changes, click the Edit button above.</div>,
      {
        autoClose: 3000,
      }
    );
  };
  const showPastCanNotEditToast = () => {
    toast(<div className="toaster toaster-error">You cannot edit past availability.</div>, {
      autoClose: 3000,
    });
  };

  const renderTherapistItemToken = (selectedItem, onRemove) => (
    <TherapistItemToken
      key={selectedItem}
      disabled={!state.isEdit}
      onRemove={onRemove}
      option={selectedItem}
    />
  );

  const disabledClassName = state.isEdit ? '' : 'typeahead-disabled';
  const isPsychiatrist = props.therapistType && props.therapistType === 'psychiatrist';
  const tooltip = (
    <Tooltip id="AvailabilityTooltip" className="tooltip ts-tooltip ts-profile-text-white">
      Caseload daily growth determines the maximum number of new clients you would like to receive
      daily. Availability refers to the days that you will respond to your current clients and be
      available to take new clients.
    </Tooltip>
  );

  const phaseOutTooltip = (
    <Tooltip id="PhaseOutToolTip" className="tooltip ts-tooltip ts-profile-text-white">
      <p>
        Providers who are phasing out will no longer be able to receive new referrals. You can
        retain your current clients until your departure date or until the clients leave the
        platform.
      </p>
    </Tooltip>
  );
  const departureDateTooltip = (
    <Tooltip id="DepartureDateToolTip" className="tooltip ts-tooltip ts-profile-text-white">
      <p>
        Providers with a departure date are leaving Talkspace effective by this date. Please make
        sure you communicate this to your clients. You will no longer receive new referrals and your
        current clients will be notified that you are leaving Talkspace 7 days prior to this date.
      </p>
    </Tooltip>
  );

  const buildFormOptions = () => {
    const psychDailyCaseLoad = ['minimum', 'none'];

    let options;

    if (isPsychiatrist) {
      options = psychDailyCaseLoad.map((key) => (
        <option key={key} value={key}>
          {key === 'minimum' ? 'Yes' : 'No'}
        </option>
      ));
    } else {
      options =
        dailyCaseLoadOptions &&
        Object.keys(dailyCaseLoadOptions)
          .sort((a, b) => dailyCaseLoadOptions[b] - dailyCaseLoadOptions[a])
          .map((key) => (
            <option key={key} value={key}>
              {+dailyCaseLoadOptions[key] === 0 && key !== state.dailyCaseLoad
                ? `0 None, I don't want new clients`
                : dailyCaseLoadOptions[key]}
            </option>
          ));
    }

    return options;
  };

  return (
    <div id="availability">
      <Col xs={12} className={`ts-panel-title ${isLoaded ? 'show-panel' : 'hidden-panel'}`}>
        <Col xs={6} sm={6} className="ts-font-black">
          <View align="center" row ref={availabilityHeaderRef}>
            Provider Availability and Capacity
            {!isPsychiatrist && (
              <OverlayTrigger
                placement="top"
                trigger={['click', 'focus', 'hover']}
                overlay={tooltip}
              >
                <i className="fa fa-fw fa-question-circle fa-lg" />
              </OverlayTrigger>
            )}
          </View>
        </Col>
        <Button className="ts-edit-button pull-right" onClick={toggleFormEdit}>
          Edit
        </Button>
        <Button
          className="btn-ts-default btn-ts-green ts-profile-text-white pull-right hidden save-btn"
          onClick={saveForm}
          disabled={state.disableSave}
        >
          {' '}
          Save Changes{' '}
        </Button>
        <Button
          className="btn-ts-default ts-profile-btn-text-green cancel-btn pull-right hidden"
          onClick={cancelEdit}
        >
          {' '}
          Cancel{' '}
        </Button>
      </Col>
      <Col xs={12}>
        <Panel
          className={`ts-my-account-panel ${isLoaded ? 'show-panel' : 'hidden-panel'}
            ${disabledClassName}`}
        >
          {!props.isW2 && (
            <Row>
              {props.phaseOutDate && (
                <>
                  <Col md={2} className="ts-profile-label">
                    Phase out date
                    <OverlayTrigger
                      placement="right"
                      trigger={['click', 'focus', 'hover']}
                      overlay={phaseOutTooltip}
                    >
                      <i className="fa fa-fw fa-question-circle fa-lg" />
                    </OverlayTrigger>
                  </Col>
                  <Col md={10}>
                    <Col md={4} style={{ padding: '0px' }}>
                      <FormGroup>
                        <FormControl
                          value={moment(props.phaseOutDate).format('MMMM Do, YYYY')}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Col>
                </>
              )}
              {props.departureDate && (
                <>
                  <Col md={2} className="ts-profile-label">
                    Departure date
                    <OverlayTrigger
                      placement="right"
                      trigger={['click', 'focus', 'hover']}
                      overlay={departureDateTooltip}
                    >
                      <i className="fa fa-fw fa-question-circle fa-lg" />
                    </OverlayTrigger>
                  </Col>
                  <Col md={10}>
                    <Col md={4} style={{ padding: '0px' }}>
                      <FormGroup>
                        <FormControl
                          value={moment(props.departureDate).format('MMMM Do, YYYY')}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Col>
                </>
              )}
            </Row>
          )}
          <Row>
            <Col md={4} className="ts-profile-label">
              <View ref={caseloadHeaderRef}>
                {isPsychiatrist
                  ? `Would you like to take new clients?`
                  : `What is the maximum number of new clients you want to receive per day?`}
              </View>
            </Col>
            <Col md={10}>
              <Col md={4} style={{ padding: '0px' }}>
                <FormGroup controlId="dailyCaseLoad">
                  <FormControl
                    componentClass="select"
                    placeholder="select"
                    onChange={dailyCaseLoadChange}
                    value={state.dailyCaseLoad}
                    disabled={!state.isEdit}
                  >
                    {buildFormOptions()}
                  </FormControl>
                </FormGroup>
              </Col>
            </Col>
          </Row>
          <Row>
            <Col md={2} className="ts-profile-label">
              Timezone
            </Col>
            <Col md={10}>
              <Col md={4} style={{ padding: '0px' }}>
                <FormGroup controlId="formControlsSelectTz">
                  <FormControl
                    componentClass="select"
                    placeholder="select"
                    onChange={tzChange}
                    value={state.currentTz == '' ? 'Select Timezone' : state.currentTz}
                    disabled={!state.isEdit}
                  >
                    {' '}
                    {state.timezones.map((val, index) =>
                      val == 0 ? (
                        <option disabled key={index} value={val}>
                          {val}
                        </option>
                      ) : (
                        <option key={index} value={val}>
                          {val}
                        </option>
                      )
                    )}
                  </FormControl>
                </FormGroup>
              </Col>
            </Col>
          </Row>
          {!isPsychiatrist && (
            <>
              <Row style={{ marginBottom: '10px' }}>
                <Col md={2} className="ts-profile-label">
                  Availability
                </Col>
                <Col md={10} className="typeahead-field availability-days">
                  <Typeahead
                    selected={state.selectedBusinessDays}
                    labelKey={(option) => `${option.text}`}
                    options={businessDaysOptions}
                    multiple
                    renderToken={renderTherapistItemToken}
                    onChange={handleBusinessDaysChange}
                    disabled={!state.isEdit}
                    placeholder="Select availability"
                  />
                  <p
                    className="ts-text-invalid ts-text-margin"
                    hidden={state.selectedBusinessDays.length >= 5 || isPsychiatrist}
                  >
                    {' '}
                    You must select at least 5 days.{' '}
                  </p>
                </Col>
              </Row>
            </>
          )}
          {!calendarAvailability && (
            <>
              <Row>
                <Col md={10} className="ts-profile-label">
                  Live Session Availability Blocks
                </Col>
                <Col md={10} style={{ marginBottom: '1em' }}>
                  <p className="ts-text-light-gray business-hours-responsive-text">
                    Select your availability blocks. You can set and edit weekly availability and
                    one-time availability by clicking through the blocks’ states.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={10}>
                  {accountAvailabilityBackwardsCompatibility ? (
                    <AvailabilityCalendarV2
                      isDisabled={!state.isEdit}
                      availabilityEnumByDateByHour={availabilityEnumByDateByHour}
                      availabilityWeekdaysSetByHour={availabilityWeekdaysSetByHour}
                      changeAvailabilityHour={dispatchChangeAvailabilityHour}
                      showCanNotEditToast={showCanNotEditToast}
                      showPastCanNotEditToast={showPastCanNotEditToast}
                      timeOffPeriods={timeOffPeriods}
                    />
                  ) : (
                    <AvailabilityCalendar
                      isDisabled={!state.isEdit}
                      availabilityEnumByDateByHour={availabilityEnumByDateByHour}
                      availabilityWeekdaysSetByHour={availabilityWeekdaysSetByHour}
                      changeAvailabilityHour={dispatchChangeAvailabilityHour}
                      showCanNotEditToast={showCanNotEditToast}
                      showPastCanNotEditToast={showPastCanNotEditToast}
                      timeOffPeriods={timeOffPeriods}
                    />
                  )}
                </Col>
              </Row>
            </>
          )}
        </Panel>
      </Col>
      <ToastContainer closeButton={false} position={toast.POSITION.BOTTOM_RIGHT} hideProgressBar />
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    isUpdatingAvailability,
    isUpdatingBusinessDays,
    isUpdatingCapacity,
    isUpdatingDepartureInfo,
    isUpdatingPhase,
    isUpdatingVideoSessionHours,
  } = state.availability;
  const isUpdating =
    isUpdatingAvailability ||
    isUpdatingBusinessDays ||
    isUpdatingCapacity ||
    isUpdatingDepartureInfo ||
    isUpdatingPhase ||
    isUpdatingVideoSessionHours;
  return {
    businessDays: state.availability.businessDays,
    currentTz: state.availability.currentTz,
    dailyCaseLoad: state.availability.dailyCaseLoad,
    phase: state.availability.phase,
    videoSessionConstraints: state.availability.videoSessionConstraints,
    isUpdating,
    isUpdatingAvailability,
    isUpdatingBusinessDays,
    isUpdatingCapacity,
    isUpdatingDepartureInfo,
    isUpdatingPhase,
    isUpdatingVideoSessionHours,
    isError: state.availability.isError,
    toasterMessage: state.availability.toasterMessage,
    phaseOutDate: state.availability.phaseOutDate,
    departureDate: state.availability.departureDate,
    therapistType: state.availability.therapistType,
    isW2: state.availability.isW2,
    dailyCaseLoadOptions: state.availability.dailyCaseLoadOptions,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getClientCapacity,
      saveClientCapacity,
      getPhase,
      getBusinessDays,
      saveBusinessDays,
      getDepartureInfo,
      getProviderDailyCaseloadOptions,
    },
    dispatch
  );
const Availability = connect(mapStateToProps, mapDispatchToProps)(TherapistAvailability);

export default withRouter(Availability);
