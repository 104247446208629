import { withDSIconMaker } from './utils/IconMaker';

const hugePath =
  'M19.3913 8.27653V15.6244C19.3913 17.4949 17.8705 19.0157 16 19.0157C14.1295 19.0157 12.6087 17.4949 12.6087 15.6244V8.27653C12.6087 6.40602 14.1295 4.88523 16 4.88523C17.8705 4.88523 19.3913 6.40602 19.3913 8.27653ZM10.913 8.27653V15.6244C10.913 18.4328 13.1916 20.7113 16 20.7113C18.8084 20.7113 21.0869 18.4328 21.0869 15.6244V8.27653C21.0869 5.46811 18.8084 3.18958 16 3.18958C13.1916 3.18958 10.913 5.46811 10.913 8.27653ZM9.21738 13.0809C9.21738 12.6146 8.83586 12.2331 8.36955 12.2331C7.90325 12.2331 7.52173 12.6146 7.52173 13.0809V15.6244C7.52173 20.0225 10.8653 23.6363 15.1522 24.0602V27.4939H11.7609C11.2946 27.4939 10.913 27.8754 10.913 28.3417C10.913 28.8081 11.2946 29.1896 11.7609 29.1896H16H20.2391C20.7054 29.1896 21.0869 28.8081 21.0869 28.3417C21.0869 27.8754 20.7054 27.4939 20.2391 27.4939H16.8478V24.0602C21.1346 23.6363 24.4783 20.0225 24.4783 15.6244V13.0809C24.4783 12.6146 24.0967 12.2331 23.6304 12.2331C23.1641 12.2331 22.7826 12.6146 22.7826 13.0809V15.6244C22.7826 19.3707 19.7463 22.407 16 22.407C12.2537 22.407 9.21738 19.3707 9.21738 15.6244V13.0809Z';
const pathConfig = {
  standard: {
    default: {
      path: '',
    },
  },
  huge: {
    default: {
      path: hugePath,
    },
  },
};

const Microphone = withDSIconMaker(pathConfig, 'Microphone');

export default Microphone;
