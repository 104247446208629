import { useMutation } from 'react-query';
import apiHelper from '../utils/api';
import apiWrapper from '../utils/apiWrapper';

interface UpdateClinicalInformationMutateVariables {
  therapistID: number;
  accessInitiativeParticipation: boolean;
}

const updateClinicalInformation = async (
  params: UpdateClinicalInformationMutateVariables
): Promise<void> => {
  const { therapistID, accessInitiativeParticipation } = params;

  const data = await apiWrapper.put(
    `${apiHelper().apiEndpoint}/api/v1/therapist/${therapistID}/clinical-info`,
    {
      accessInitiativeParticipation,
    }
  );
  return data.data;
};

const useMutationUpdateClinicalInfo = () =>
  useMutation<void, Error, UpdateClinicalInformationMutateVariables>(updateClinicalInformation);

export default useMutationUpdateClinicalInfo;
