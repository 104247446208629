import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { TouchableView, View, Modal, Spinner, CloseButton, COLORS } from '@talkspace/react-toolkit';

import styled, { EmotionStyle } from '../../../../core/styled/styled';

const Content = styled(TouchableView)({
  backgroundColor: COLORS.white,
  height: '100%',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'fixed',
});

interface Props {
  isLoading: boolean;
  src: string;
  title: string;
  containerStyle?: EmotionStyle;
  onClose: (data?: ClosePopupActionBase) => void;
  onTokenRequest?: (refresh: boolean) => Promise<{ token: string; userID: number }>;
  onFinishedLoading?: () => void;
}

type eventTypeNames = 'closePopup' | 'finishLoading' | 'authToken';

type reactFrameEventsNames = 'refreshToken';

interface TSFrameEvent {
  type: eventTypeNames;
  data: any;
}

export interface ClosePopupActionBase {
  navigateTo?: string;
}

const IFrameModal: FunctionComponent<Props> = ({
  isLoading,
  src,
  title,
  onClose,
  containerStyle,
  onTokenRequest,
  onFinishedLoading,
}) => {
  const iFrameURLRef = useRef(new URL(src));
  const iFrameContainerRef = useRef<HTMLIFrameElement>(null);
  const [shouldShowCloseButton, setShouldShowCloseButton] = useState(true);

  useEffect(() => {
    function postMessage(type: reactFrameEventsNames, data: unknown) {
      if (
        iFrameContainerRef.current &&
        iFrameContainerRef.current.contentWindow &&
        iFrameContainerRef.current.contentWindow.postMessage
      ) {
        iFrameContainerRef.current.contentWindow.postMessage(
          {
            type,
            data,
          },
          iFrameURLRef.current.origin
        );
      }
    }

    function receiveMessage(event: MessageEvent) {
      if (onTokenRequest) {
        // check origin
        if (iFrameURLRef.current.origin !== event.origin) {
          return;
        }
        const { data: eventData } = event;

        if (!eventData || !eventData.type) return;

        const { type, data } = eventData as TSFrameEvent;

        switch (type) {
          case 'authToken':
            onTokenRequest(true)
              .then((tokenData) => {
                postMessage('refreshToken', tokenData);
              })
              // eslint-disable-next-line no-console
              .catch(console.error);
            break;
          case 'closePopup':
            onClose(data && data.return);
            break;
          case 'finishLoading':
            onTokenRequest(false)
              .then((tokenData) => {
                postMessage('refreshToken', tokenData);
                setShouldShowCloseButton(false);
                if (onFinishedLoading) onFinishedLoading();
              })
              // eslint-disable-next-line no-console
              .catch(console.error);
            break;
          default:
            // eslint-disable-next-line no-console
            console.error(`event type unknown ${type}`);
            break;
        }
      }
    }

    window.addEventListener('message', receiveMessage, false);
    return () => {
      window.removeEventListener('message', receiveMessage);
    };
  }, [onClose, onFinishedLoading, onTokenRequest, src]);

  return (
    <Modal isVisible>
      <Content style={containerStyle}>
        {shouldShowCloseButton && (
          <CloseButton
            onPress={() => onClose()}
            style={{
              position: 'fixed',
              top: 16,
              right: 16,
            }}
          />
        )}
        {isLoading && (
          <View
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              position: 'absolute',
            }}
          >
            <Spinner />
          </View>
        )}
        <iframe
          src={src}
          frameBorder="0"
          title={title}
          width="100%"
          height="100%"
          ref={iFrameContainerRef}
          style={{
            visibility: isLoading ? 'hidden' : 'visible',
          }}
        />
      </Content>
    </Modal>
  );
};

export default IFrameModal;
