export const spacing = {
  space0: 0,
  space025: 2,
  space050: 4,
  space075: 6,
  space100: 8,
  space150: 12,
  space200: 16,
  space300: 24,
  space400: 32,
  space500: 40,
  space600: 48,
  space700: 56,
  space800: 64,
  space900: 72,
};
export type SpacingToken = keyof typeof spacing;

export function getSpacing(key: SpacingToken, includePx?: true): string;
export function getSpacing(key: SpacingToken, includePx?: false | undefined): number;
export function getSpacing(key: SpacingToken, includePx?: boolean): string | number {
  return includePx ? `${spacing[key]}px` : spacing[key];
}

export const fontWeight = {
  bold: 600,
  semibold: 500,
  regular: 400,
};
