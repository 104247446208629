import Svg, { Path } from 'svgs';

interface CaseIconProps {
  width?: number;
  height?: number;
  style?: {};
}

const DiagnosisIcon = ({ width = 16, height = 16, ...otherProps }: CaseIconProps) => (
  <Svg width={width} height={height} viewBox="0 0 16 16" fill="none" {...otherProps}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 14.386c-3.247 0-5.915-2.426-6.323-5.564L4.8 8.83v-.006l1.934-1.95 2.625 4.502L11.926 8.8h2.414A6.366 6.366 0 018 14.386zM8 1.57A6.377 6.377 0 0114.349 7.2H11.2v.038L9.632 8.834 7.834 5.742l.005-.004-.836-1.434-1.13 1.132.008-.005L4.098 7.2H1.65A6.377 6.377 0 018 1.571zM8 0a8 8 0 100 16A8 8 0 008 0z"
      fill="#68768D"
    />
  </Svg>
);

export default DiagnosisIcon;
