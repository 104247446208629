import s from '@emotion/styled';
import { CreateStyled, ExtendEmotionTheme } from '@talkspace/react-toolkit';

export interface RoomTheme {
  window: {
    isSmall: boolean;
    isMedium: boolean;
    isLarge: boolean;
    isDesktop: boolean;
    isMobile: boolean;
    width: number;
    height: number;
  };
}

export type Theme = ExtendEmotionTheme<RoomTheme>;

// export interface EmotionStyle extends CSS.Properties<string | number> {}
export type EmotionStyle = object;

const styled = s as CreateStyled<Theme>;

export default styled;
