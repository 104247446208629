import { VoidFunctionComponent } from 'react';
import Svg, { Path, Circle, G } from 'svgs';
import { useEmotionTheme, EmotionStyle } from '../../core/styled';

interface SmallLiveIconProps {
  width?: number;
  height?: number;
  style?: EmotionStyle;
}

const SmallLiveIcon: VoidFunctionComponent<SmallLiveIconProps> = ({
  width = 19,
  height = 15,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'live video';

  return (
    <Svg width={width} height={height} title={titleText} aria-label={titleText} {...otherProps}>
      <G
        transform="translate(-2.504 -4.504)"
        stroke={colors.permaRainbowFish}
        strokeWidth={1.5}
        fill="none"
        fillRule="evenodd"
      >
        <Circle fill={colors.permaFlyAKite} cx={12.004} cy={12.004} r={2.75} />
        <Path
          d="M15.993 16.437a6 6 0 1 0-8.03-8.918 6 6 0 0 0 8.03 8.918Z"
          strokeLinecap="round"
          strokeDasharray={9.6}
        />
        <Path
          d="M17.692 18.321A8.5 8.5 0 1 0 6.317 5.688 8.5 8.5 0 0 0 17.692 18.32Z"
          strokeLinecap="round"
          strokeDasharray={13.44}
        />
      </G>
    </Svg>
  );
};

export default SmallLiveIcon;
