import { Small, Standard, styled, View, Link } from '@talkspace/react-toolkit';
import { Theme } from '@/core/styled';

export const Container = styled(View)(({ theme: { colors } }) => {
  return {
    width: '100%',
    border: `1px solid ${colors.paleSilver}`,
    borderRadius: 7,
    background: colors.permaPhantomWhite,
    padding: 15,
    marginBottom: 10,
    marginTop: 10,
  };
});

export const Title = styled(Standard)(({ theme: { colors } }) => {
  return {
    fontSize: 15,
    color: colors.black,
    fontWeight: 500,
  };
});

export const Subtitle = styled(Small)(() => {
  return {
    fontSize: 14,
    fontWeight: 400,
  };
});

export const MinutesText = styled(Small)(() => {
  return {
    fontSize: 14,
    fontWeight: 700,
    marginRight: 3,
  };
});

export const WordCountText = styled(Small)(({ theme: { colors } }) => {
  return {
    fontSize: 14,
    color: colors.forestGreen,
    fontWeight: 700,
    marginLeft: 1,
  };
});

export const DateInputLabel = styled(Small)(({ theme: { colors } }) => {
  return {
    fontSize: 14,
    color: colors.black,
    fontWeight: 400,
  };
});

export const Line = styled(View)(({ theme: { colors } }) => {
  return {
    height: 'auto',
    width: 5,
    background: colors.darkBlue150,
    borderRadius: 10,
    marginRight: 15,
  };
});

export const getDateRangeCalendarStyle = (isStartDate: boolean = false) => {
  return {
    zIndex: 99,
    borderRadius: 8,
    boxShadow: '0px 4px 10px rgba(0, 40, 65, 0.4)',
    top: 25,
    left: isStartDate ? -185 : -350,
    position: 'absolute',
  };
};

export const getDateRangeInputStyle = (
  colors: Theme['colors'],
  isError: boolean,
  isDisabled?: boolean
) => {
  return {
    border: `1px solid ${isError ? colors.permaFuchsia : colors.periwinkleGrey}`,
    borderRadius: '8px',
    height: '36px !important',
    fontSize: 14,
    background: isDisabled ? colors.permaGreyed : colors.permaPhantomWhite,
    color: colors.black,
    paddingLeft: 8,
    paddingTop: 9,
    paddingBottom: 9,
    maxWidth: 127,
  };
};

export const TextLink = styled(Link)(({ theme: { colors } }) => {
  return {
    fontSize: 14,
    fontWeight: 700,
    color: colors.accessibilityGreenDark,
    textDecoration: 'underline',
    marginLeft: 0,
    whiteSpace: 'nowrap',
    '&:hover': {
      color: colors.accessibilityGreenDark,
    },
  };
});
