import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { BackArrow, TouchableView } from '@talkspace/react-toolkit';
import { forgotPassword as forgotPasswordAction } from '../../reducers/auth';
import NavBar from '../Login/Navbar';
import ForgotPasswordView from './ForgotPasswordView';
import { useHistory } from '@/core/routerLib';

function ForgotPassword(props) {
  const { forgotPassword, isSuccessful, isLoading, error } = props;

  const [email, setEmail] = useState<string>('');
  const [showCheckmark, setShowCheckmark] = useState<boolean>(false);
  const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    let timeout;
    if (isSuccessful) {
      setShowCheckmark(true);
      timeout = setTimeout(() => {
        setShowCheckmark(false);
      }, 3000);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [isSuccessful]);

  const onSubmit = () => {
    setShowCheckmark(false);
    if (!isEmailValid) return;
    if (isLoading) return;
    forgotPassword(email);
  };

  return (
    <div className="room-route">
      <NavBar />
      <TouchableView
        style={{ marginTop: 28, marginLeft: 20, width: 'fit-content' }}
        onPress={() => history.push('/login')}
      >
        <BackArrow />
      </TouchableView>
      <ForgotPasswordView
        isLoading={isLoading}
        error={error.message}
        email={email}
        forgotPassword={onSubmit}
        showCheckmark={showCheckmark}
        onEmailChanged={setEmail}
        setIsEmailValid={setIsEmailValid}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.auth.isFetching,
    error: state.auth.error,
    isSuccessful: state.auth.isSuccessful,
  };
};

const mapDispatchToProps = {
  forgotPassword: forgotPasswordAction,
};

const TherapistForgotPassword = connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);

export default TherapistForgotPassword;
