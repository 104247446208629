import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const standardPath =
  'M11.475 12.8906L3.10938 4.525C2.40937 5.50313 2 6.70312 2 8C2 11.3125 4.6875 14 8 14C9.29688 14 10.4969 13.5906 11.475 12.8906ZM12.8906 11.475C13.5906 10.4969 14 9.29688 14 8C14 4.6875 11.3125 2 8 2C6.70312 2 5.50313 2.40937 4.525 3.10938L12.8906 11.475ZM0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8Z';

const pathConfig: IconConfig = {
  standard: {
    default: {
      path: standardPath,
    },
  },
};

const Ban = withDSIconMaker(pathConfig, 'Banned Circle');

export default Ban;
