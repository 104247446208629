import { VoidFunctionComponent } from 'react';
import Svg, { Rect, Path } from 'svgs';

interface QuickCaseIconProps {
  width?: number;
  height?: number;
}

const QuickCaseIcon: VoidFunctionComponent<QuickCaseIconProps> = ({
  width = 28,
  height = 28,
  ...otherProps
}) => {
  const titleText = 'Quick case icon';

  return (
    <Svg
      title={titleText}
      aria-label={titleText}
      width={width}
      height={height}
      fill="none"
      {...otherProps}
    >
      <Rect width={28} height={28} rx={8} fill="#D0EBDE" />
      <Path
        d="M10 7h8a3 3 0 013 3v9a3 3 0 01-3 3h-8a3 3 0 01-3-3v-9a3 3 0 013-3z"
        fill="#D9E9E7"
        stroke="#175C62"
        strokeWidth={2}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.212 16.537c-2.216 2.088-2.506 1.827-4.485-.06-1.858-1.773-1.387-3.894-.036-4.371.853-.303 1.654.074 2.176.754a.17.17 0 00.27-.002c.485-.636 1.21-1.007 2.02-.8 1.493.381 2.043 2.609.055 4.479z"
        fill="#175C62"
      />
      <Path
        d="M13 5h2a2 2 0 110 4h-2a2 2 0 110-4z"
        fill="#D9E9E7"
        stroke="#175C62"
        strokeWidth={2}
      />
    </Svg>
  );
};

export default QuickCaseIcon;
