import { FunctionComponent } from 'react';
import { Large, View } from '@talkspace/react-toolkit';

import { BillingPrice, LineItem } from 'ts-frontend/types';
import {
  formatCurrency,
  getBillingFrequencyDescriptor,
  getAmountAfterTrial,
  getRenewDate,
} from 'ts-frontend/helpers/billingUtils';

const INSURANCE_DISCLAIMER =
  'If your insurance rejects the claim, you might be charged the difference.';

interface RenewalNoticeProps {
  billingPrice: BillingPrice;
  isTrial: boolean;
  total: number;
  lineItems: LineItem[];
  isNoMatches?: boolean;
  showInsuranceDisclaimer?: boolean;
}

function generateMessage(
  isTrial: boolean,
  renewDateFormatted: string,
  billingPrice: BillingPrice,
  total: number,
  lineItems?: LineItem[],
  isNoMatches?: boolean,
  showInsuranceDisclaimer?: boolean
) {
  if (isNoMatches && isTrial) {
    return `Your one week of $0 trial starts once you are matched with a provider. At the end of that week you will be billed ${formatCurrency(
      getAmountAfterTrial(billingPrice),
      billingPrice.currency
    )} for the remainder of the billing period, and ${formatCurrency(
      billingPrice.amount,
      billingPrice.currency
    )}${getBillingFrequencyDescriptor(billingPrice)} thereafter.`;
  }

  if (isNoMatches) {
    return `You will not be charged until you’ve been matched with a provider.${
      showInsuranceDisclaimer ? ` ${INSURANCE_DISCLAIMER}` : ''
    }`;
  }

  if (showInsuranceDisclaimer) {
    return INSURANCE_DISCLAIMER;
  }

  if (isTrial) {
    return `Your trial will end on ${renewDateFormatted} and you will be billed ${formatCurrency(
      getAmountAfterTrial(billingPrice),
      billingPrice.currency
    )} for the remainder of the billing period, and ${formatCurrency(
      billingPrice.amount,
      billingPrice.currency
    )}${getBillingFrequencyDescriptor(billingPrice)} thereafter.`;
  }

  if (total < 0) {
    return `${formatCurrency(
      -total,
      billingPrice.currency
    )} will be credited towards future payments.`;
  }
  // check if there is a recurring line item
  const recurringLineItem = (lineItems || []).find((line) => !!line.isRecurringDiscount);

  return `Your plan will renew on ${renewDateFormatted} and you will be charged ${formatCurrency(
    billingPrice.amount - (recurringLineItem ? -recurringLineItem.amount : 0),
    billingPrice.currency
  )}.`;
}

const getRenewalNoticeHeight = (
  isTrial: boolean,
  isNoMatches: boolean,
  showInsuranceDisclaimer: boolean
) => {
  if (isTrial && isNoMatches) return 120;
  if (isNoMatches && showInsuranceDisclaimer) return 80;
  if (isTrial) return 60;
  return 40;
};

const RenewalNotice: FunctionComponent<RenewalNoticeProps> = ({
  billingPrice,
  isTrial,
  total,
  lineItems,
  isNoMatches = false,
  showInsuranceDisclaimer = false,
}) => {
  const renewDate = getRenewDate(billingPrice, isTrial);
  if (!renewDate && !isNoMatches) {
    return <View style={{ height: 67 }} />;
  }

  const renewDateFormatted = renewDate?.format('MMM DD, YYYY') ?? '';

  const message = generateMessage(
    isTrial,
    renewDateFormatted,
    billingPrice,
    total,
    lineItems,
    isNoMatches,
    showInsuranceDisclaimer
  );

  const height = getRenewalNoticeHeight(isTrial, isNoMatches, showInsuranceDisclaimer);

  return (
    <Large
      variant="largeDarkGrey"
      style={{
        marginTop: 27,
        height,
        textAlign: 'center',
        alignSelf: 'center',
        width: 281,
      }}
    >
      {message}
    </Large>
  );
};

export default RenewalNotice;
