import { OptionType } from '@talkspace/react-toolkit/src/components/Select/types';

export const formatStateOptions = (options: { [key: string]: string }): OptionType[] =>
  Object.entries(options)
    .map(([value, label]) => {
      return { value, label };
    })
    .filter(({ value }) => value !== 'US');

export const formatInsurancePayersOptions = (options: { label: string; id: string | number }[]) =>
  options.map(({ label, id }) => {
    return { value: String(id), label };
  });

export const sortOptionsAlphabetically = (optionA: OptionType, optionB: OptionType) => {
  const optionLabelA = optionA.label.toLowerCase();
  const optionLabelB = optionB.label.toLowerCase();
  if (optionLabelA.startsWith('other') || optionLabelA.startsWith('none')) return 1;
  if (optionLabelA < optionLabelB) return -1;
  if (optionLabelA > optionLabelB) return 1;
  return 0;
};
