import { FunctionComponent } from 'react';
import { useParams, useHistory } from '@/core/routerLib';

import Submenu from '../../../../Reusable/Submenu/Submenu';
import UserActionCard from '../../../../Reusable/UserActionCard/UserActionCard';
import useQueryDiagnosticProfiles from './useQueryDiagnosticProfiles';
import useQueryParticipants from '../../../../../hooks/useQueryParticipants';

const DiagnosticProfilePicker: FunctionComponent = () => {
  const history = useHistory();
  const { roomID } = useParams<{ roomID: string }>();

  const { data: participants } = useQueryParticipants(Number(roomID));

  const {
    data: diagnosticProfiles,
    isError,
    isLoading,
    error,
  } = useQueryDiagnosticProfiles(
    {
      roomID: Number(roomID),
      clientUserIDs: (participants || []).map((it) => Number(it.userID)),
    },
    { enabled: !!participants }
  );

  const pickUser = (clientUserID: number) => {
    history.push(`/room/${roomID}/diagnostic-profile/${clientUserID}`);
  };

  const cards = (participants || [])
    .filter((client) => !!diagnosticProfiles?.[client.userID])
    .map((client) => {
      const buttons = [
        {
          title: 'Show profile',
          clickHandler: () => pickUser(client.userID),
        },
      ];
      return (
        <UserActionCard
          buttons={buttons}
          isError={isError}
          key={client.userID}
          isUpdating={isLoading}
          spinnerMessage={error?.message}
          preferredName={client.displayName}
          content="Review your clients diagnostic profile in conjunction with your clinical expertise to help guide treatment."
        />
      );
    });

  return <Submenu childComponents={cards} title="Diagnostic profile" prevRoute="clinical-tab" />;
};

export default DiagnosticProfilePicker;
