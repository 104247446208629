import { FunctionComponent } from 'react';
import { Large } from '@talkspace/react-toolkit';
import { EmotionStyle } from '@/core/styled/styled';

const Timer: FunctionComponent<{
  secondsElapsed: number;
  areOtherUsersInCall: boolean;
  descriptionString?: string;
  timerStyle?: EmotionStyle;
}> = ({ secondsElapsed = 0, areOtherUsersInCall, descriptionString, timerStyle = {} }) => {
  const seconds = (secondsElapsed % 60).toString();
  const secondsString = seconds.length > 1 ? seconds : `0${seconds}`;
  const minutes = (Math.floor(secondsElapsed / 60) % 60).toString();
  const minutesString = minutes.length > 1 ? minutes : `0${minutes}`;
  const hours = Math.floor(secondsElapsed / 3600);
  const hoursString = hours ? `${hours.toString()}:` : '';
  const finalDescriptionString = descriptionString ? `${descriptionString} - ` : '';
  return (
    <Large
      style={{
        position: 'absolute',
        top: 20,
        width: '100%',
        textAlign: 'center',
        ...timerStyle,
      }}
      variant={areOtherUsersInCall ? 'largeWhite' : 'largeDarkGrey'}
    >
      {`${finalDescriptionString}${hoursString}${minutesString}:${secondsString}`}
    </Large>
  );
};

export default Timer;
