import { VideoCreditOffer, Booking } from 'ts-frontend/types';
import { useHistory } from 'react-router';
import { VideoCalendarOutline, usePanelManagerActions } from '@talkspace/react-toolkit';
import { trackEvent, tsAnalyticsTracker } from '@/utils/analytics/eventTracker';

import storage from '@/core/storage';
import { VideoCreditType } from '../types/videoCallTypes';
import VideoCallPopup from '../components/VideoCall/VideoCallPopup';

const shouldTherapistSeeScheduleNextSessionPopup = (
  sessionLengthMinutes: number,
  timeInCallSeconds: number,
  videoCreditType: VideoCreditType,
  bookings: Booking[],
  creditOptions: VideoCreditOffer[],
  isBH: boolean | undefined
): boolean => {
  // no popup for introduction sessions
  if (videoCreditType === VideoCreditType.introduction) {
    return false;
  }

  const userHasCredits = isBH
    ? true
    : creditOptions.some((credit) => credit.priceText === 'Session credits available');

  const userHasBookedAlready: boolean =
    (bookings?.filter((booking) =>
      ['tentative', 'confirmed'].includes(booking.timekitBookingState || '')
    )).length > 0;

  // TODO: this is for qa purposes, delete after qa done
  const qaMinutesBeforeEnd = Number(storage.getItem('therapistPopupShowMinutesBeforeEnd'));
  const showTimeMinutesBeforeEnd =
    qaMinutesBeforeEnd || (videoCreditType === VideoCreditType.psychiatry ? 20 : 5);

  // NOTE: free sessions have length 0 so we assume their length to be 45
  const totalLengthMinutes = sessionLengthMinutes === 0 ? 45 : sessionLengthMinutes;
  const isTimeToShow = timeInCallSeconds >= (totalLengthMinutes - showTimeMinutesBeforeEnd) * 60;

  return userHasCredits && !userHasBookedAlready && isTimeToShow;
};

interface Props {
  roomID: number;
  handleMinimize: () => void;
}
const TherapistScheduleNextSessionPopupContainer = ({ roomID, handleMinimize }: Props) => {
  const history = useHistory();
  const { setIsLeftPanelOnTop } = usePanelManagerActions();

  const handlePress = () => {
    trackEvent(
      'In-Video Session Click',
      {
        actionName: 'inVideoPromptInteraction',
        roomID,
        timestamp: Date.now(),
      },
      ['tsAnalytics']
    );
    tsAnalyticsTracker.trackMPEventGeneric('Open Therapist Scheduler', {
      targets: [tsAnalyticsTracker.targetsEnum.MIXPANEL_PROVIDER],
      roomID,
      Source: 'In-Video Prompt',
    });
    handleMinimize();
    setIsLeftPanelOnTop(true);
    history.push(`/room/${roomID}/in-room-scheduling`);
  };

  return (
    <VideoCallPopup
      icon={VideoCalendarOutline}
      text="Use the last few minutes to talk about when to meet next."
      linkText="Schedule a session"
      onPress={handlePress}
    />
  );
};

export default TherapistScheduleNextSessionPopupContainer;
export { shouldTherapistSeeScheduleNextSessionPopup };
