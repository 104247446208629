import { BookingsQueryString } from 'utils/getBookingsQueryString';

const liveSessionsKeys = {
  all: ['liveSessions'],
  bookings: (therapistID: number, params: BookingsQueryString) => [
    ...liveSessionsKeys.all,
    therapistID.toString(),
    'bookings',
    params,
  ],
  bookingsByID: (therapistID: number, bookingID: string, params: BookingsQueryString) => [
    ...liveSessionsKeys.all,
    therapistID.toString(),
    'bookingsByID',
    bookingID,
    params,
  ],
};

export default liveSessionsKeys;
