import { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import View from '../View';
import Label from '../Label';
import commonStyles from '../../constants/commonStyles';
import { Tiny } from '../Typography';
import { webOnlyStyle } from '../../core/styleHelpers';
import Input from '../Input';
import { useUniqueID, useShareForwardedRef } from '../../hooks/a11yHelper';
import { useEmotionTheme } from '../../core/styled';

const { crossBrowserFocusRing, roundedFocusRing } = commonStyles;

const TextInputWrapper = styled(View)(
  ({
    style,
    displayError,
    isClicked,
    isAutoFocused,
    borderColor,
    roundedFocusStyle,
    theme: { colors },
  }) => {
    return {
      borderRadius: 9,
      borderColor: displayError ? colors.torchRed : borderColor || colors.periwinkleGrey,
      borderStyle: 'solid',
      borderWidth: 1,
      width: 335,
      height: 50,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: colors.white,
      ...(!roundedFocusStyle && {
        '&:hover:focus-within': {
          outline: 'none',
        },
        ':focus-within': isClicked || isAutoFocused ? { outline: 'none' } : crossBrowserFocusRing,
      }),
      ...style,
    };
  }
);

const TextInput = styled(Input)(({ inputStyle, placeholderStyle, theme: { colors } }) => {
  return {
    fontFamily: 'Roboto, sans-serif',
    color: colors.black,
    fontSize: 16,
    height: 28,
    width: 280,
    marginLeft: 17,
    borderWidth: 0,
    outline: 0,
    boxShadow: 'none',
    outlineColor: 'transparent',
    outlineStyle: 'none',
    WebkitAppearance: 'none',
    WebkitTapHighlightColor: 'rgba(0,0,0,0)',
    caretColor: colors.green,
    ...inputStyle,
    ...webOnlyStyle({
      '::placeholder': {
        color: colors.placeholderGrey,
        ...placeholderStyle,
      },
    }),
  };
});

const ErrorMessage = styled(Tiny)(({ hide, style, theme: { colors } }) => {
  return {
    color: colors.torchRed,
    padding: hide ? 0 : 4,
    height: hide ? 0 : 20,
    opacity: hide ? 0 : 1,
    pointerEvents: hide ? 'none' : 'initial',
    ...webOnlyStyle({ transition: 'all .25s' }),
    ...style,
  };
});

function TextInputAuthView({
  label,
  innerRef,
  containerStyle,
  displayError,
  errorMessage,
  wrapperStyle,
  inputStyle,
  placeholderStyle,
  labelStyle,
  errorStyle,
  onSubmitEditing,
  onBlur,
  isErrorAlert,
  shouldFocus,
  borderColor,
  dataQa,
  roundedFocusStyle,
  ariaDescribedBy,
  primaryColor,
  ariaLabel,
  ...otherProps
}) {
  const { colors } = useEmotionTheme();
  const { autoFocus } = otherProps;
  const inputRef = useShareForwardedRef(innerRef);
  // allows for focus effect only on keyboard focus
  const [isClicked, setIsClicked] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isAutoFocused, setIsAutoFocused] = useState(autoFocus);
  const handleOnClick = () => {
    setIsClicked(true);
    return null;
  };
  const handleOnBlur = (e) => {
    if (onBlur) {
      onBlur(e);
    }
    setIsAutoFocused(false);
    setIsFocused(false);
    setIsClicked(false);
    return null;
  };
  const onFocus = () => {
    setIsFocused(true);
  };

  // conditionally focuses the input i.e. if it was invalid on submit
  useEffect(() => {
    if (shouldFocus && inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, [shouldFocus, inputRef]);

  const inputID = useUniqueID('inputID');
  const inputErrorID = useUniqueID('inputError');
  const keyboardFocus = isFocused && !isClicked;
  return (
    <View style={containerStyle}>
      <Label htmlFor={inputID} style={labelStyle}>
        {label}
      </Label>
      <View
        style={{
          borderRadius: 9,
          ...(roundedFocusStyle && {
            ...roundedFocusRing(primaryColor || colors.whiteSmoke, keyboardFocus, displayError),
          }),
        }}
      >
        <TextInputWrapper
          isClicked={isClicked}
          style={wrapperStyle}
          displayError={displayError}
          borderColor={borderColor}
          isAutoFocused={isAutoFocused}
          onFocus={onFocus}
          roundedFocusStyle={roundedFocusStyle}
        >
          <TextInput
            data-qa={dataQa}
            id={inputID}
            onClick={handleOnClick}
            onBlur={handleOnBlur}
            aria-describedby={displayError ? inputErrorID : ariaDescribedBy}
            aria-required="true"
            aria-invalid={displayError}
            aria-label={ariaLabel}
            role={ariaLabel}
            autoCorrect="off"
            autoCapitalize="off"
            inputStyle={inputStyle}
            onSubmitEditing={onSubmitEditing}
            placeholderStyle={placeholderStyle}
            ref={inputRef}
            {...otherProps}
          />
        </TextInputWrapper>
      </View>
      <ErrorMessage
        id={inputErrorID}
        // when isErrorAlert=true, the error isn't specific to the input so it will be given role="alert" allowing it to be announced by a SR as a general error
        role={isErrorAlert && displayError ? 'alert' : undefined}
        hide={!displayError}
        style={errorStyle}
      >
        {errorMessage}
      </ErrorMessage>
    </View>
  );
}

export default TextInputAuthView;
