type PromiseFunction = () => Promise<void>;

class PromiseDebouncer {
  private currentFun: PromiseFunction | null = null;

  private nextFun: PromiseFunction | null = null;

  debounce = (fun: PromiseFunction) => {
    if (this.currentFun) {
      this.nextFun = fun;
    } else {
      this.currentFun = fun;

      this.run();
    }
  };

  private run = async () => {
    await (this.currentFun as PromiseFunction)();

    this.currentFun = this.nextFun;
    this.nextFun = null;

    if (this.currentFun) {
      this.run();
    }
  };
}

export default PromiseDebouncer;
