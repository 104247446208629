import { useState, useEffect } from 'react';
import useQueryUnsubmittedSessions, {
  UnsubmittedSession,
} from 'hooks/notes/useQueryUnsubmittedSessions';
import useUnsubmittedSessionsSocket from './useUnsubmittedSessionsSocket';

import { useHistory, useLocation, useParams } from '@/core/routerLib';
import { ProgressNoteFormContainerLocationState } from '../types';
import useQueryTaskList, {
  UnsubmittedSessionTask,
} from '../../../../../hooks/dashboard/useQueryTaskListV3';
import { getUserData } from '../../../../../utils/token';

export default function useUnsubmittedSession() {
  const { roomID } = useParams<{ roomID: string }>();
  const location = useLocation<ProgressNoteFormContainerLocationState | undefined>();
  const isTaskListPath = location.pathname.includes('/tasks');
  const {
    data: unsubmittedSessionsData,
    refetch,
    isLoading: isUnsubmittedSessionsLoading,
    isError: isUnsubmittedSessionsError,
  } = useQueryUnsubmittedSessions({
    roomID,
    isEnabled: !isTaskListPath,
  });
  const {
    data: tasks,
    isLoading: isTasksLoading,
    isError: isTasksError,
  } = useQueryTaskList(getUserData()?.id, isTaskListPath);
  const [unsubmittedSession, setUnsubmittedSession] = useState<
    UnsubmittedSession | UnsubmittedSessionTask | null
  >(null);

  const unsubmittedSessionID = new URLSearchParams(location.search).get('id');
  const history = useHistory();

  useUnsubmittedSessionsSocket(refetch, roomID);

  useEffect(() => {
    if (!location?.search) {
      return;
    }
    if (
      isTaskListPath ||
      !unsubmittedSessionID ||
      !unsubmittedSessionsData?.unsubmittedSessions.length
    ) {
      return;
    }

    const sessionData = unsubmittedSessionsData?.unsubmittedSessions.find(
      (item) => item.id.toString() === unsubmittedSessionID
    );

    if (!sessionData) {
      history.push(`/room/${roomID}/notes-tab`);
    } else {
      setUnsubmittedSession(sessionData);
    }
  }, [
    history,
    location?.search,
    roomID,
    unsubmittedSessionID,
    unsubmittedSessionsData?.unsubmittedSessions,
    isTaskListPath,
  ]);

  useEffect(() => {
    if (!location?.search) {
      return;
    }

    if (!isTaskListPath || !unsubmittedSessionID || !tasks?.length) {
      return;
    }

    const sessionData = tasks.find((item) => item.id?.toString() === unsubmittedSessionID);
    if (!sessionData) {
      history.push(`/tasks`);
    } else {
      setUnsubmittedSession(sessionData);
    }
  }, [history, location?.search, roomID, unsubmittedSessionID, isTaskListPath, tasks]);

  return {
    unsubmittedSession,
    unsubmittedSessionID,
    unsubmittedFreeLiveCalls: unsubmittedSessionsData?.unsubmittedFreeLiveCalls,
    isLoading: isUnsubmittedSessionsLoading || isTasksLoading,
    isError: isUnsubmittedSessionsError || isTasksError,
  };
}
