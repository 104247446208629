import { FunctionComponent } from 'react';
import { useEmotionTheme } from '../../core/styled';
import View from '../View';

interface RoundEmptyContainerProps {
  color?: string;
  size: number;
  weight: string;
}

const RoundEmptyContainer: FunctionComponent<RoundEmptyContainerProps> = ({
  color,
  size,
  weight,
}: RoundEmptyContainerProps) => {
  const { colors } = useEmotionTheme();
  return (
    <View
      align="center"
      justify="center"
      style={{
        borderRadius: '50%',
        borderColor: color || colors.permaSwansDown,
        borderStyle: 'solid',
        borderWidth: weight,
        width: size,
        height: size,
      }}
    />
  );
};

export default RoundEmptyContainer;
