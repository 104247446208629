/* eslint-disable camelcase */
// Back-end sends these fields as camel-case

import { LiveCallType, LiveSessionModality } from 'ts-frontend/types';
import { EUser } from './User';
import { EMedia, MediaInterface } from './EMedia';
import { COLORS } from '../utils/design';
import { RoomType } from './RoomInfo';

interface ModalSettings {
  isFullscreen?: boolean;
  isTransparent?: boolean;
}

interface MessageButtonActions {
  [key: number]: {
    path: string;
    secondaryPath?: string;
    modal?: ModalSettings;
  };
}

export type MessageBodyType =
  | 'TEXT_ONLY'
  | 'HAS_BUTTON'
  | 'MATCH_RESULTS'
  | 'QM_NO_RESPONSE'
  | 'LVS'
  | 'VIDEO'
  | 'AUDIO'
  | 'IMAGE'
  | 'HAS_HEADER'
  | 'HAS_FOOTER'
  | 'JSON'
  | 'PDF'
  | 'VIDEO_CALL'
  | 'CONFIRM_PROVIDER_BOOKING'
  | 'CONTINUE_TO_VIDEO_CALL'
  | 'HAS_CLIPBOARD_COPY'
  | 'LIVE_SESSION_STATE'
  | 'GENERIC';

export enum SubMessageType {
  CONFIRM_OR_DECLINE_RECURRING_SESSIONS = 'confirmOrDeclineRecurringSessions',
}

const stripDomainFromURL = (webviewURL: string): string => {
  try {
    const url = new URL(webviewURL);
    if (!url.pathname || !url.origin) return webviewURL;
    return webviewURL.split(url.origin)[1];
  } catch (err) {
    return webviewURL;
  }
};

const trimNewLinesStartAndEnd = (str: string): string =>
  str && str.replace && str.replace(/^\s+|\s+$/, '');

const therapistsButtonPathByMessageType: MessageButtonActions = {
  2: {
    path: 'room-offer',
    modal: { isFullscreen: true, isTransparent: false },
  },
  27: { path: 'matches' },
  41: { path: 'video-call' },
  44: { path: 'video-call' },
  47: { path: 'send-live-session-event' },
  48: { path: 'video-call' },
  50: { path: 'video-call' },
  51: { path: 'send-live-session-event' },
};

const clientsButtonPathByMessageType: MessageButtonActions = {
  2: {
    path: 'room-offer',
    modal: { isFullscreen: true, isTransparent: false },
  },
  18: {
    path: 'survey',
    modal: { isFullscreen: true, isTransparent: false },
  },
  19: {
    path: 'survey',
    modal: { isFullscreen: true, isTransparent: false },
  },
  21: { path: 'informed-consent' },
  27: { path: 'client-matches' },
  32: { path: 'sss-no-matches' },
  33: { path: 'sharing-preferences' },
  36: {
    path: 'scheduler',
    modal: { isFullscreen: false, isTransparent: true },
  },
  37: { path: 'insurance-eligibility', modal: { isFullscreen: true, isTransparent: true } },
  38: { path: 'matching-intake' },
  39: { path: 'intake' },
  41: { path: 'video-call' },
  42: { path: 'eligibility-widget' },
  43: { path: 'confirm-booking', secondaryPath: 'decline-booking' },
  44: { path: 'continue-to-booking' },
  45: { path: 'create-session-report' },
  47: { path: 'send-live-session-event' },
  48: { path: 'video-call' },
  50: { path: 'continue-to-booking' },
  51: { path: 'continue-to-booking' },
  54: { path: 'parental-consent-details-resubmit' },
};

const messageTypeToBodyTypeMap = {
  2: 'HAS_BUTTON', // Offer
  3: 'HAS_HEADER', // Welcome
  7: 'HAS_BUTTON', // Chat offer (deprecated)
  9: 'HAS_FOOTER', // Referral discount code
  13: 'HAS_CLIPBOARD_COPY', // Invitation (join couples)
  15: 'AUDIO', // Audio
  16: 'VIDEO', // Video
  17: 'IMAGE', // Image
  18: 'HAS_BUTTON', // Survey (initial message)
  19: 'HAS_BUTTON', // Survey (after initial)
  21: 'HAS_BUTTON', // Informed consent
  22: 'LVS', // LVS incoming
  23: 'LVS', // LVS started
  24: 'LVS', // LVS missed
  25: 'LVS', // LVS failed
  26: 'LVS', // LVS ended
  27: 'MATCH_RESULTS', // In platform matching
  28: 'HAS_BUTTON', // Emergency contact (deprecated)
  29: 'JSON', // In platform matching confirm (deprecated)
  30: 'QM_NO_RESPONSE', // Pay first not engaged
  31: 'HAS_BUTTON', // Intake wizard (deprecated)
  32: 'JSON', // Self serve switch no matches found
  33: 'JSON', // Sharing preferences
  35: 'JSON', // QM Switch payfirst answers (deprecated)
  36: 'JSON', // Schedule appointment
  37: 'JSON', // Check eligibility
  38: 'JSON', // Matching intake
  39: 'JSON', // Treatment intake,
  40: 'PDF', // PDF
  41: 'VIDEO_CALL', // Live Video V2
  42: 'JSON', // Eligibility widget
  43: 'CONFIRM_PROVIDER_BOOKING',
  44: 'CONTINUE_TO_VIDEO_CALL', // Continue to provider scheduled video call
  45: 'JSON', // Eligibility based on file
  46: 'LIVE_SESSION_STATE', // Live session state notification
  47: 'VIDEO_CALL', // Live video V3
  48: 'VIDEO_CALL', // Join chat session
  50: 'CONTINUE_TO_VIDEO_CALL',
  51: 'CONTINUE_TO_VIDEO_CALL',
  52: 'GENERIC', // Reusable Webview message
  53: 'TEXT_ONLY', // Parental consent granted
  54: 'JSON', // Resubmit parental consent form
  55: 'GENERIC', // Auto welcome with scheduler link
};

export const messageTypeToBodyType = (messageType: number): MessageBodyType =>
  messageTypeToBodyTypeMap[messageType] || 'TEXT_ONLY';

function messageFieldToBody(apiMessage: ApiMessage, isTherapistChat: boolean): unknown {
  const { message, messageType, messageID: id, userRoomSurveyID, offerID, planID } = apiMessage;

  switch (messageTypeToBodyType(messageType)) {
    case 'JSON': {
      const {
        messageContent,
        therapistMessageContent,
        customerMessageContent,
        therapistMessageTitle,
        customerMessageTitle,
        button,
        funnelVariation,
        caseID,
        videoCallID,
        roomType,
      } = message as {
        messageContent: string;
        therapistMessageContent?: string;
        customerMessageContent?: string;
        therapistMessageTitle?: string;
        customerMessageTitle?: string;
        button: string;
        funnelVariation?: string;
        caseID?: string;
        videoCallID?: number;
        roomType?: RoomType;
      };

      let messageBody: MessageBodyTextOnly | MessageBodyHasButton;
      if (therapistMessageTitle && customerMessageTitle) {
        messageBody = {
          text: trimNewLinesStartAndEnd(
            isTherapistChat ? therapistMessageTitle : customerMessageTitle
          ),
        };
      } else {
        messageBody = {
          text: trimNewLinesStartAndEnd(
            isTherapistChat
              ? therapistMessageContent || messageContent
              : customerMessageContent || messageContent
          ),
          buttonText: button,
        };

        if (!isTherapistChat && customerMessageTitle) messageBody.boldText = customerMessageTitle;

        messageBody.buttonParams = {
          messageID: id,
          messageType,
          funnelVariation,
          caseID,
          videoCallID,
          roomType,
        };

        const buttonAction = isTherapistChat
          ? therapistsButtonPathByMessageType[messageType]
          : clientsButtonPathByMessageType[messageType];

        if (buttonAction) {
          messageBody.buttonDestinationPath = buttonAction.path;
          messageBody.buttonActionModal = buttonAction.modal;
        }
      }
      return messageBody;
    }
    case 'GENERIC': {
      const {
        header,
        messageContent,
        therapistMessageContent,
        customerMessageContent,
        customerMessageTitle,
        therapistMessageTitle,
        button,
        webviewURL,
        isFullscreen = true,
        isTransparent = true,
        disableButtonForProvider = false,
        ...webviewParams
      } = message as {
        header?: string;
        messageContent: string;
        therapistMessageContent?: string;
        customerMessageContent?: string;
        therapistMessageTitle?: string;
        customerMessageTitle?: string;
        button?: string;
        webviewURL?: string;
        isFullscreen?: boolean;
        isTransparent?: boolean;
        disableButtonForProvider?: boolean;
      };
      let messageBody: MessageBodyTextOnly | MessageBodyHasButton;
      if (!button) {
        messageBody = {
          text: trimNewLinesStartAndEnd(
            isTherapistChat
              ? therapistMessageContent || messageContent
              : customerMessageContent || messageContent
          ),
        };
      } else {
        messageBody = {
          text: trimNewLinesStartAndEnd(
            isTherapistChat
              ? therapistMessageContent || messageContent
              : customerMessageContent || messageContent
          ),
          buttonText: button,
        };

        messageBody.buttonParams = {
          messageID: id,
          messageType,
          ...webviewParams,
        };
        if (webviewURL) {
          messageBody.buttonDestinationPath =
            isTherapistChat && disableButtonForProvider
              ? undefined
              : stripDomainFromURL(webviewURL);
          messageBody.buttonActionModal = { isFullscreen, isTransparent };
        }
      }

      if (!isTherapistChat && (customerMessageTitle || header))
        messageBody.boldText = customerMessageTitle || header;

      if (isTherapistChat && (therapistMessageTitle || header))
        messageBody.boldText = therapistMessageTitle || header;

      return messageBody;
    }
    case 'TEXT_ONLY': {
      let messageBody: MessageBodyTextOnly;
      if (!message) return { text: '' };
      const regexWelcome = new RegExp(/Welcome.*\n.+Talkspace/);
      const matchWelcome = (message as string).match(regexWelcome);
      if (matchWelcome) {
        messageBody = {
          text: (message as string).replace(regexWelcome, ''),
          boldText: matchWelcome[0],
        };
      } else {
        messageBody = {
          text: trimNewLinesStartAndEnd(message as string),
        };
      }
      return messageBody;
    }
    case 'HAS_BUTTON': {
      let messageBody: MessageBodyHasButton;
      const regexHTML = new RegExp(/<div.+<\/div>/);
      const matchHTML = (message as string).match(regexHTML);
      if (matchHTML) {
        messageBody = {
          text: trimNewLinesStartAndEnd((message as string).replace(regexHTML, '')),
          buttonText: matchHTML[0],
        };
      } else {
        const regexMD = new RegExp(/#button#-(.+)-#button#/);
        const [msg, buttonText] = (message as string).split(regexMD);
        messageBody = {
          text: trimNewLinesStartAndEnd(msg as string),
          buttonText,
        };
      }
      messageBody.buttonParams = {
        messageID: id,
        messageType,
        offerID,
        planID,
      };
      if (userRoomSurveyID) {
        messageBody.buttonParams.userRoomSurveyID = Number(userRoomSurveyID);
      }

      const buttonAction = isTherapistChat
        ? therapistsButtonPathByMessageType[messageType]
        : clientsButtonPathByMessageType[messageType];

      if (buttonAction) {
        messageBody.buttonDestinationPath = buttonAction.path;
        messageBody.buttonActionModal = buttonAction.modal;
      }

      return messageBody;
    }
    case 'HAS_CLIPBOARD_COPY': {
      const urlRegex = /https?:\/\/\S+/;
      const regex = new RegExp(urlRegex);
      const [msg, postLinkText] = (message as string).split(regex);
      const match = (message as string).match(regex);
      const messageBody: MessageBodyHasClipboardCopy = {
        text: trimNewLinesStartAndEnd(msg as string).replace(':', '.') + postLinkText,
        copyText: match ? match[0] : '',
      };
      return messageBody;
    }
    case 'HAS_FOOTER':
    case 'HAS_HEADER': {
      let splitAt = 0;
      if (messageType === 3) {
        // messageType 3 is a welcome message, we have many iterations
        if (
          (message as string).indexOf('Couples room') >= 0 ||
          (message as string).indexOf('psychiatry room') >= 0
        ) {
          const welcomeMessageSections = (message as string).split('\n');
          // couples room or psychiatry room welcome message
          splitAt = welcomeMessageSections[0].concat(welcomeMessageSections[1]).length + 1;
        } else if ((message as string).indexOf('confidential Talkspace') >= 0) {
          // welcome message pre-intake_v3
          splitAt = 39;
        } else if ((message as string).indexOf('Welcome to Talkspace') >= 0) {
          // welcome message post-intake_v3
          splitAt = 20;
        }
      }
      if (messageType === 9) {
        // Must contain "use code <CODE>" at the end of the message string
        const regexOffer = new RegExp(/[U,u]se.+code:?/);
        const [text, bottomBoldText] = (message as string).split(regexOffer);
        return {
          text: trimNewLinesStartAndEnd(text as string),
          bottomBoldText: `Use promo code ${(bottomBoldText as string).trim()}`,
        };
      }
      const boldText = (message as string).slice(0, splitAt);
      const msg = (message as string).slice(splitAt);
      const messageBody: MessageBodyTextOnly = {
        text: trimNewLinesStartAndEnd(msg as string),
        boldText,
      };

      return messageBody;
    }
    case 'LVS': {
      const messageTypeToPhoneColor = {
        22: COLORS.green,
        23: COLORS.green,
        25: COLORS.red,
      };
      const messageBody: MessageBodyHasPhoneIcon = {
        text: trimNewLinesStartAndEnd(message as string),
        phoneIconColor: messageTypeToPhoneColor[messageType] || COLORS.grey,
      };
      return messageBody;
    }
    case 'MATCH_RESULTS': {
      const { messageContent, therapistMessageContent, button, matchId } = message as {
        messageContent: string;
        therapistMessageContent: string;
        button: string;
        matchId: number;
      };

      const messageBody: MessageBodyHasButton = {
        text: isTherapistChat ? therapistMessageContent : messageContent,
        buttonText: button,
        buttonParams: { messageType, matchID: matchId },
      };

      const buttonAction = isTherapistChat
        ? therapistsButtonPathByMessageType[messageType]
        : clientsButtonPathByMessageType[messageType];

      if (buttonAction) {
        messageBody.buttonDestinationPath = buttonAction.path;
        messageBody.buttonActionModal = buttonAction.modal;
      }

      return messageBody;
    }
    case 'QM_NO_RESPONSE': {
      // TODO: remove once we are sure the message type is gone from server response
      const { switchType, customerMessageTitle, customerMessageButtonText, therapistMessageTitle } =
        message as {
          switchType: string;
          customerMessageTitle: string;
          customerMessageButtonText: string;
          therapistMessageTitle: string;
        };

      let messageBody: unknown = {};

      if (isTherapistChat) {
        messageBody = {
          text: therapistMessageTitle,
        };
        return messageBody as MessageBodyTextOnly;
      }
      messageBody = {
        text: customerMessageTitle,
        buttonText: customerMessageButtonText,
        buttonParams: { switchType },
        buttonDestinationPath: clientsButtonPathByMessageType[messageType],
      };
      return messageBody as MessageBodyHasButton;
    }
    case 'VIDEO_CALL': {
      const {
        messageContent,
        button,
        startTime,
        creditMinutes,
        videoCreditType,
        videoCallID,
        tokenExpiresAt,
        therapistFirstName,
        callType,
        modality = 'video',
      } = message as {
        messageContent: string;
        button: string;
      } & VideoCallButtonParams;
      const buttonAction = isTherapistChat
        ? therapistsButtonPathByMessageType[messageType]
        : clientsButtonPathByMessageType[messageType];
      const messageBody: MessageBodyHasButton = {
        buttonText: button,
        text: trimNewLinesStartAndEnd(messageContent),
        buttonParams: {
          startTime,
          creditMinutes,
          messageType,
          videoCreditType,
          videoCallID,
          tokenExpiresAt,
          therapistFirstName,
          modality,
          callType,
        },
        buttonDestinationPath: buttonAction.path,
      };
      return messageBody;
    }
    case 'CONFIRM_PROVIDER_BOOKING': {
      const { messageContent, primaryButton, secondaryButton, bookingID } = message as {
        messageContent: string;
        primaryButton: string;
        secondaryButton: string;
      } & ButtonParams;

      const buttonAction = isTherapistChat
        ? therapistsButtonPathByMessageType[messageType]
        : clientsButtonPathByMessageType[messageType];
      const messageBody: MessageBodyHasButton = {
        buttonText: primaryButton,
        secondaryButtonText: secondaryButton,
        text: trimNewLinesStartAndEnd(messageContent),
        buttonDestinationPath: buttonAction ? buttonAction.path : undefined,
        secondaryButtonDestinationPath: buttonAction ? buttonAction.secondaryPath : undefined,
        buttonParams: {
          bookingID,
          messageType,
        },
      };
      return messageBody;
    }
    case 'CONTINUE_TO_VIDEO_CALL': {
      const {
        messageContent,
        button,
        startTime,
        creditMinutes,
        videoCreditType,
        videoCallID,
        tokenExpiresAt,
        therapistFirstName,
        bookingID,
        modality = 'video',
      } = message as {
        messageContent: string;
        button: string;
      } & ContinueToVideoCallButtonParams;

      const buttonAction = isTherapistChat
        ? therapistsButtonPathByMessageType[messageType]
        : clientsButtonPathByMessageType[messageType];
      const messageBody: MessageBodyHasButton = {
        buttonText: button,
        text: trimNewLinesStartAndEnd(messageContent),
        buttonParams: {
          messageType,
          startTime,
          creditMinutes,
          videoCreditType,
          videoCallID,
          bookingID,
          tokenExpiresAt,
          therapistFirstName,
          modality,
        },
        buttonDestinationPath: buttonAction.path,
      };
      return messageBody;
    }
    case 'IMAGE':
    case 'VIDEO':
    case 'AUDIO':
    case 'PDF': {
      return { text: '' };
    }
    case 'LIVE_SESSION_STATE': {
      const { messageContent, pseudonym, therapistFirstName, userType, button } = message as {
        messageContent: string;
        pseudonym: string;
        therapistFirstName: string;
        userType: string;
        button: string;
      };
      const messageBody: MessageBodyLiveSessionState = {
        text: messageContent,
        therapistFirstName,
        pseudonym,
        userType,
        buttonText: button,
      };
      return messageBody;
    }
    default:
      throw new Error(`parse failed for message type (${messageType})`);
  }
}

const fixMainsiteDateToIsoString = (strDate: string): string => {
  if (typeof strDate !== 'string' || strDate.includes('Z')) return strDate;
  return `${strDate.trim().replace(' ', 'T')}Z`;
};

const isSystem = (messageType: number) => ![1, 15, 16, 17, 40, 49, 55].includes(messageType);

interface MessageInterface {
  createdAt: Date;
  createdFrom: number;
  groupId: number;
  isStarred: boolean;
  localMessageId: number;
  mediaId: number;
  id: number;
  messageType: number;
  bodyType: MessageBodyType;
  replyId: number;
  starredAt?: Date;
  user: EUser;
  messageBody: unknown;
  charCount?: number;
}

export interface MessageBodyTextOnly {
  text: string;
  boldText?: string;
  bottomBoldText?: string;
}

export interface MessageBodyLiveSessionState {
  text: string;
  therapistFirstName?: string;
  pseudonym?: string;
  userType?: string;
  buttonText: string;
}

interface ButtonParams {
  messageID?: number;
  messageType: number;
  subMessageType?: SubMessageType;
  userRoomSurveyID?: number;
  offerID?: number;
  planID?: number;
  matchID?: number;
  therapistUserID?: number;
  messageSentBy?: number;
  funnelVariation?: string;
  startTime?: string;
  creditMinutes?: number;
  videoCreditType?: string;
  videoCallID?: number;
  tokenExpiresAt?: string;
  therapistFirstName?: string;
  bookingID?: string;
  caseID?: string;
  modality?: LiveSessionModality;
  callType?: LiveCallType;
  roomType?: RoomType;
}

export interface VideoCallButtonParams {
  startTime: string;
  creditMinutes: number;
  videoCreditType: string;
  videoCallID: number;
  tokenExpiresAt: string;
  therapistFirstName: string;
  callType: LiveCallType;
  modality: LiveSessionModality;
}

export interface ContinueToVideoCallButtonParams {
  startTime: string;
  creditMinutes: number;
  videoCreditType: string;
  videoCallID: number;
  tokenExpiresAt: string;
  therapistFirstName: string;
  bookingID: string;
  modality?: LiveSessionModality;
}

export interface MessageBodyHasButton extends MessageBodyTextOnly {
  buttonText: string;
  buttonDestinationPath?: string;
  buttonActionModal?: ModalSettings;
  buttonParams?: ButtonParams;
  secondaryButtonParams?: ButtonParams;
  secondaryButtonText?: string;
  secondaryButtonDestinationPath?: string;
  modality?: LiveSessionModality;
}

export interface MessageBodyHasLink extends MessageBodyTextOnly {
  linkText: string;
  postLinkText: string;
}

export interface MessageBodyHasClipboardCopy extends MessageBodyTextOnly {
  copyText: string;
}

export interface MessageBodyHasPhoneIcon extends MessageBodyTextOnly {
  phoneIconColor: string;
}

export enum LiveSessionStateMessageUserType {
  PROVIDER = 'provider',
  CLIENT = 'client',
}

export interface ApiMessage {
  avatarImage: string;
  createdAt: string;
  createdFrom: number;
  groupID: number;
  isStarred: number;
  localMessageID: number;
  mediaID: number;
  message: unknown;
  messageID: number;
  messageType: number;
  isSystem: boolean;
  replyID: number;
  starredAt: string;
  userID: number;
  userSubscriptionType: number;
  userType: number;
  displayName: string;
  userRoomSurveyID?: string;
  offerID?: number;
  planID?: number;
  mediaData?: MediaInterface;
}

// eslint-disable-next-line import/prefer-default-export
export class EMessage implements MessageInterface {
  avatarImage: string;

  createdAt: Date;

  createdFrom: number;

  groupId: number;

  isStarred: boolean;

  localMessageId: number;

  mediaId: number;

  media?: EMedia;

  id: number;

  messageType: number;

  bodyType: MessageBodyType;

  isSystem: boolean;

  replyId: number;

  starredAt?: Date;

  user: EUser;

  messageBody: unknown;

  charCount?: number;

  constructor(apiMessage: ApiMessage, isTherapistChat: boolean) {
    try {
      this.messageBody = messageFieldToBody(apiMessage, isTherapistChat);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      const messageBody: MessageBodyTextOnly = {
        text: `Error displaying message (${apiMessage.messageType})`,
      };
      this.messageBody = messageBody;
    }

    this.avatarImage = apiMessage.avatarImage;
    this.createdAt = new Date(fixMainsiteDateToIsoString(apiMessage.createdAt));
    this.createdFrom = apiMessage.createdFrom;
    this.groupId = apiMessage.groupID;
    this.isStarred = Boolean(apiMessage.isStarred);
    this.localMessageId = apiMessage.localMessageID;
    this.mediaId = apiMessage.mediaID;
    if (this.mediaId && apiMessage.mediaData) {
      this.media = new EMedia(apiMessage.mediaData);
    }
    this.id = apiMessage.messageID;
    this.messageType = apiMessage.messageType;
    this.bodyType = messageTypeToBodyType(apiMessage.messageType);
    this.isSystem = isSystem(apiMessage.messageType);
    this.replyId = apiMessage.replyID;
    if (apiMessage.starredAt)
      this.starredAt = new Date(fixMainsiteDateToIsoString(apiMessage.starredAt));
    if (this.messageType === 1)
      this.charCount = (this.messageBody as MessageBodyTextOnly).text.length;

    this.user = new EUser({
      displayName: this.isSystem ? 'Talkspace' : apiMessage.displayName,
      id: apiMessage.userID,
      subscriptionType: apiMessage.userSubscriptionType,
      userType: apiMessage.userType,
      avatarImage: apiMessage.avatarImage,
    });
  }
}
