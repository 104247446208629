import { FunctionComponent } from 'react';
import Svg, { Path } from 'svgs';
import { useEmotionTheme, EmotionStyle } from '../../core/styled';

interface IconProps {
  width?: number;
  height?: number;
  style?: EmotionStyle;
}

const SmallChatVideoSessionIcon: FunctionComponent<IconProps> = ({
  width = 14,
  height = 18,
  ...otherProps
}) => {
  const {
    colors: { purple },
  } = useEmotionTheme();
  const titleText = 'chat session icon';
  return (
    <Svg width={width} height={height} viewBox="0 0 18 17" title={titleText} {...otherProps}>
      <Path
        d="M51.51 653.75a5.024 5.024 0 01-.19 1.845c-.124.418.013.87.349 1.15a1.1 1.1 0 001.196.139c1.096-.551 2.553-1.509 3.438-2.98h.726c3.85 0 6.971-3.112 6.971-6.952S60.879 640 57.029 640H52.97c-3.566 0-6.557 2.684-6.932 6.22a6.954 6.954 0 005.473 7.53h-.003z"
        transform="translate(-46 -640)"
        fill={purple}
        fillRule="nonzero"
        stroke="none"
        strokeWidth={1}
      />
    </Svg>
  );
};

export default SmallChatVideoSessionIcon;
