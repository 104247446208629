import { FunctionComponent, useEffect } from 'react';
import { View, Huge, Button } from '@talkspace/react-toolkit';
import storage, { StoragePersistanceMode } from '@/core/storage';
import { removeTokens } from '../helpers/token';

const UnauthorizedScreen: FunctionComponent<{}> = () => {
  useEffect(() => {
    // Remove tokens from localStorage and sessionStorage.
    storage.setStoragePersistanceMode(StoragePersistanceMode.LOCAL);
    removeTokens();
    storage.setStoragePersistanceMode(StoragePersistanceMode.SESSION);
    removeTokens();
  }, []);

  return (
    <View style={{ height: '100vh' }}>
      <View flex={1} align="center" justify="center">
        <Huge style={{ fontSize: 40 }}>401</Huge>
        <Huge>There was an error authorizing</Huge>
        <Button text="Retry" onPress={() => window.location.replace('/')} />
      </View>
    </View>
  );
};

export default UnauthorizedScreen;
