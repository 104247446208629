import { VoidFunctionComponent } from 'react';
import Svg, { Path } from 'svgs';
import { EmotionStyle, useEmotionTheme } from '../../core/styled';

interface GivingHeartProps {
  color?: string;
  width?: number;
  height?: number;
  style?: EmotionStyle;
}

const GivingHeart: VoidFunctionComponent<GivingHeartProps> = ({
  width = 23,
  height = 20,
  color = '',
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'Giving heart';

  return (
    <Svg
      title={titleText}
      aria-label={titleText}
      width={width}
      height={height}
      fill="none"
      {...otherProps}
    >
      <Path
        d="M20 15c.573 0 1.057.2 1.45.6.393.4.587.867.58 1.4L14 20l-7-2V9h1.95l7.27 2.69c.52.207.78.58.78 1.12 0 .313-.113.587-.34.82a1.217 1.217 0 0 1-.86.37H13l-1.75-.67-.33.94L13 15h7ZM16 1.23C16.707.41 17.607 0 18.7 0c.907 0 1.673.333 2.3 1 .627.667.96 1.433 1 2.3 0 .687-.333 1.507-1 2.46-.667.953-1.323 1.75-1.97 2.39-.647.64-1.657 1.59-3.03 2.85a122.565 122.565 0 0 1-3.06-2.85c-.653-.64-1.31-1.437-1.97-2.39-.66-.953-.983-1.773-.97-2.46 0-.907.323-1.673.97-2.3a3.471 3.471 0 0 1 2.34-1c1.067 0 1.963.41 2.69 1.23ZM.984 9H5v11H.984V9Z"
        fill={color || colors.purple}
      />
    </Svg>
  );
};

export default GivingHeart;
