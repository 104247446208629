import { VoidFunctionComponent } from 'react';
import Svg, { Path } from 'svgs';
import { useEmotionTheme, EmotionStyle } from '../../core/styled';

interface ReloadProps {
  width?: number;
  height?: number;
  color?: string;
  style?: EmotionStyle;
}

const Reload: VoidFunctionComponent<ReloadProps> = ({
  width = 14,
  height = 19,
  color,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'reopened';

  return (
    <Svg width={width} height={height} title={titleText} aria-label={titleText} {...otherProps}>
      <Path
        d="M9.068.767a1 1 0 0 1-.124 1.41l-2.48 2.08a7.001 7.001 0 1 1-6.42 8.2l-.033-.2a1 1 0 0 1 1.979-.291l.023.144a5 5 0 1 0 4.056-5.792c-.056.01-.11.015-.165.015L8.328 9.22a1 1 0 1 1-1.532 1.286l-4.5-5.362L7.66.644a1 1 0 0 1 1.409.123Z"
        fill={color || colors.permaBlueViolet}
        fillRule="nonzero"
      />
    </Svg>
  );
};

export default Reload;
