import { FunctionComponent } from 'react';
import {
  OptionType,
  Select,
  Small,
  View,
  TextAreaRounded,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import { Styles } from 'react-select';
import { severityLevel } from '@talkspace/configs';
import { SeverityLevel } from '../../types';
import IndentedView from '../../IndentedView';
import { getTextAreaContainerBorderStyle, getTextAreaStyle } from '../../utils';

const mainQuestionOptions = [
  { value: true, label: 'Yes' },
  { value: false, label: 'None reported or observed' },
];

const severityOptions = Object.keys(severityLevel).map((key) => {
  return {
    value: key,
    label: severityLevel[key],
  };
});

export type RiskAssessmentValue = {
  mainQuestion: boolean;
  severity: SeverityLevel | null;
  notes: string | null;
};

export type RiskAssessmentField = 'severity' | 'notes';

export type FieldError = Record<RiskAssessmentField, boolean>;

interface Props {
  value: RiskAssessmentValue;
  setValue: (newValue: RiskAssessmentValue) => void;
  hasSeverity: boolean;
  placeholder: string;
  errors: FieldError;
  resetErrors: (field: RiskAssessmentField) => void;
  disabled: boolean;
}

const RiskAssessmentQuestion: FunctionComponent<Props> = ({
  value,
  setValue,
  hasSeverity,
  placeholder,
  errors,
  resetErrors,
  disabled,
}) => {
  const { colors } = useEmotionTheme();

  const handleMainQuestionChange = (option: OptionType<boolean> | null | undefined) => {
    const newValue = option ? option.value : false;
    setValue({
      ...value,
      mainQuestion: newValue,
      ...(!newValue
        ? {
            severity: null,
            notes: null,
          }
        : {}),
    });
  };

  const handleSeverityChange = (option: OptionType | null | undefined) => {
    resetErrors('severity');
    let newValue: SeverityLevel | null;
    if (option) {
      newValue = option.value as SeverityLevel;
    } else {
      newValue = null;
    }
    setValue({
      ...value,
      severity: newValue,
    });
  };

  const handleNotesChange = (newValue: string) => {
    resetErrors('notes');
    setValue({
      ...value,
      notes: newValue,
    });
  };

  const getSeveritySelectStyles = (isError: boolean): Styles<OptionType, boolean> => {
    return {
      control: (provided) => {
        return {
          ...provided,
          ...(isError ? { borderColor: colors.permaDebianRed } : {}),
        };
      },
    };
  };

  const textAreaStyle = getTextAreaStyle(disabled);

  return (
    <View style={{ marginBottom: 10 }}>
      <Select
        style={{ marginBottom: 10 }}
        placeholder={placeholder}
        value={mainQuestionOptions.find((it) => it.value === value.mainQuestion) as any}
        onChange={handleMainQuestionChange as any}
        options={mainQuestionOptions as any}
        isDisabled={disabled}
        dataQa="mainQuestionDropdown"
      />
      {value.mainQuestion && (
        <IndentedView>
          {hasSeverity && (
            <View>
              <Select
                style={{ marginBottom: 10 }}
                placeholder="Severity"
                value={severityOptions.find((it) => it.value === value.severity)}
                onChange={handleSeverityChange as any}
                options={severityOptions}
                styles={getSeveritySelectStyles(errors.severity)}
                isDisabled={disabled}
                dataQa="severityDropdown"
              />
            </View>
          )}
          <View style={{ marginBottom: 10, marginTop: 10 }}>
            <Small style={{ marginBottom: 4, color: errors.notes && colors.permaDebianRed }}>
              Notes
            </Small>
            <TextAreaRounded
              autoSize
              style={getTextAreaContainerBorderStyle(colors, errors.notes)}
              textAreaStyle={textAreaStyle}
              value={value.notes || undefined}
              onChangeText={handleNotesChange}
              disabled={disabled}
              hasLimit
              dataQa="notesTextArea"
            />
          </View>
        </IndentedView>
      )}
    </View>
  );
};

export default RiskAssessmentQuestion;
