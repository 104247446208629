import { FunctionComponent } from 'react';
import { TouchableView, View, Text } from '@talkspace/react-toolkit';
import { useHistory, useParams } from '@/core/routerLib';

import AccordionContainer from '../../../../Reusable/AccordionContainer/AccordionContainer';
import DiagnosticProfileIcon from './DiagnosticProfileIcon';
import useQueryDiagnosticProfiles from './useQueryDiagnosticProfiles';
import useQueryParticipants from '../../../../../hooks/useQueryParticipants';

const DiagnosticProfileCard: FunctionComponent = () => {
  const history = useHistory();
  const { roomID } = useParams<{ roomID: string }>();

  const { data: participants } = useQueryParticipants(Number(roomID));

  const { data: diagnosticProfiles } = useQueryDiagnosticProfiles(
    {
      roomID: Number(roomID),
      clientUserIDs: (participants || []).map((it) => Number(it.userID)),
    },
    { enabled: !!participants }
  );

  const isCouple = (participants || []).length > 1;
  const hasDiagnosticProfile = Object.keys(diagnosticProfiles || {}).length > 0;

  const showProfile = () => {
    if (isCouple) {
      history.push(`/room/${roomID}/diagnostic-profiles`);
    } else if (participants) {
      history.push(`/room/${roomID}/diagnostic-profile/${participants[0].userID}`);
    }
  };

  return (
    <AccordionContainer
      title={`Diagnostic Profile${isCouple ? 's' : ''}`}
      dataQa="diagnosticProfileAccordion"
    >
      <View style={{ display: 'flex', flexDirection: 'row' }}>
        <DiagnosticProfileIcon style={{ flex: 1 }} />
        <View flex={4} align="stretch" style={{ flexDirection: 'column', padding: 5 }}>
          <Text style={{ fontSize: 15, fontWeight: 'normal', color: '#ccc' }}>
            {hasDiagnosticProfile
              ? // eslint-disable-next-line quotes
                "Your client's diagnostic profile is ready"
              : 'Not enough data to generate diagnostic profile'}
          </Text>
          {hasDiagnosticProfile && (
            <TouchableView
              onPress={showProfile}
              style={{
                flex: 1,
                marginTop: 25,
                outline: 'none',
                flexDirection: 'row',
                justifyContent: 'flex-end',
              }}
            >
              <Text style={{ fontSize: 14, color: '#09B198', fontWeight: 500, textAlign: 'right' }}>
                Show profile
              </Text>
            </TouchableView>
          )}
        </View>
      </View>
    </AccordionContainer>
  );
};

export default DiagnosticProfileCard;
