import useMutationDeleteDischargeNote from 'hooks/notes/useMutationDeleteDischargeNote';
import useQueryDischargeNote from 'hooks/notes/useQueryDischargeNote';
import useQueryRoomDetails from 'ts-frontend/hooks/useQueryRoomDetails';
import { useHistory, useParams } from '@/core/routerLib';
import { getSubmenuTitleAndSubtitle } from './utils';
import NoteDialog from './NoteDialog';

const DeleteDischargeNoteDialog = () => {
  const history = useHistory();
  const { roomID, noteID } = useParams<{ roomID: string; noteID: string }>();

  const { mutate: deleteDischargeNote, isLoading: isDeleteLoading } =
    useMutationDeleteDischargeNote();

  const { data: { clientUserID } = {} } = useQueryRoomDetails(roomID);

  const { isLoading: isDischargeNoteLoading, data: dischargeNoteData } = useQueryDischargeNote(
    clientUserID,
    noteID
  );
  const isDraft = dischargeNoteData?.status === 'draft';

  const handleYesPress = () => {
    deleteDischargeNote(
      { roomID, noteID, isDraft },
      {
        onSettled: () => {
          history.push(`/room/${roomID}/notes-tab`);
        },
      }
    );
  };

  const handleNoPress = () => {
    history.push(`/room/${roomID}/discharge-notes/${noteID}/edit`);
  };

  const { title, subtitle } = getSubmenuTitleAndSubtitle({
    formMode: 'edit',
    noteType: 'discharge',
    noteDate: dischargeNoteData?.dischargeDate,
  });

  return (
    <NoteDialog
      dialogType="delete"
      title={title}
      subtitle={subtitle}
      isLoading={isDeleteLoading || isDischargeNoteLoading}
      onYesPress={handleYesPress}
      onNoPress={handleNoPress}
    />
  );
};

export default DeleteDischargeNoteDialog;
