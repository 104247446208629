import { VoidFunctionComponent, useMemo } from 'react';
import Svg, { Path } from 'svgs';
import { useEmotionTheme, EmotionStyle } from '../../core/styled';

interface SorterIconProp {
  width?: number;
  height?: number;
  style?: EmotionStyle;
  type?: 'up' | 'down';
  color?: string;
}

const SorterIcon: VoidFunctionComponent<SorterIconProp> = ({
  color,
  width = 5,
  height = 10,
  type,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'sorter';

  const upDownWidth = useMemo(() => width + 2, [width]);
  const upDownHeight = useMemo(() => height - 6, [height]);

  switch (type) {
    case 'up':
      return (
        <Svg width={upDownWidth} height={upDownHeight} {...otherProps}>
          <Path d="M3.5 0 7 4H0z" fill={color || colors.slateGrey} fillRule="evenodd" />
        </Svg>
      );
    case 'down':
      return (
        <Svg width={upDownWidth} height={upDownHeight} {...otherProps}>
          <Path d="M3.5 4 7 0H0z" fill={color || colors.slateGrey} fillRule="evenodd" />
        </Svg>
      );
    default:
      return (
        <Svg width={width} height={height} title={titleText} aria-label={titleText} {...otherProps}>
          <Path
            d="m5 6-2.5 4L0 6h5ZM2.5 0 5 4H0l2.5-4Z"
            fill={color || colors.slateGrey}
            fillRule="evenodd"
          />
        </Svg>
      );
  }
};

export default SorterIcon;
