import { FunctionComponent, useState, useEffect, ChangeEvent } from 'react';
import moment from 'moment';
import Submenu from 'components/Reusable/Submenu';
import {
  View,
  Large,
  styled,
  DatePickerInput,
  useEmotionTheme,
  TextInput,
  TextAreaRounded,
  Tiny,
  Small,
  Checkbox,
} from '@talkspace/react-toolkit';
import useMutationSubmitCaseConsultationNote from 'hooks/notes/useMutationSubmitCaseConsultationNote';
import useMutationUpdateCaseConsultationNote from 'hooks/notes/useMutationUpdateCaseConsultationNote';
import useMutationSaveDraftCaseConsultationNote, {
  SaveDraftCaseConsultationNoteMutateVariables,
} from 'hooks/notes/useMutationSaveDraftCaseConsultationNote';
import useQueryCaseConsultationNote from 'hooks/notes/useQueryCaseConsultationNote';
import useQueryRoomDetails from 'ts-frontend/hooks/useQueryRoomDetails';
import useQueryNotes from 'hooks/notes/useQueryNotes';
import { useHistory, useParams } from '@/core/routerLib';
import IndentedView from './IndentedView';
import ErrorScreen from './ErrorScreen';
import { CaseConsultationNoteFormErrors, CaseConsultationNoteFormState, FormMode } from './types';
import {
  ACTION_SETTLED_TIMEOUT,
  getSubmenuTitleAndSubtitle,
  getTextAreaContainerBorderStyle,
  getTextAreaStyle,
} from './utils';
import StatementCertification from './StatementCertification';
import NoteFormFooter from './NoteFormFooter';

interface Props {
  mode: FormMode;
}

const InputSection = styled(View)({
  marginBottom: 15,
});

const CaseConsultationNoteForm: FunctionComponent<Props> = ({ mode }) => {
  const { colors } = useEmotionTheme();

  const {
    mutate: submitCaseConsultationNote,
    isLoading: isSubmitLoading,
    isError: isSubmitError,
  } = useMutationSubmitCaseConsultationNote();
  const {
    mutate: updateCaseConsultationNote,
    isLoading: isSaveLoading,
    isError: isSaveError,
  } = useMutationUpdateCaseConsultationNote();
  const {
    mutate: saveDraftCaseConsultationNote,
    isLoading: isSaveDraftLoading,
    isError: isSaveDraftError,
  } = useMutationSaveDraftCaseConsultationNote();

  const [showSpinner, setShowSpinner] = useState(false);
  const [formErrors, setFormErrors] = useState<CaseConsultationNoteFormErrors>({
    globalValidationError: false,
    personConsultedNameError: false,
    personConsultedCredentialsError: false,
    consultationSummaryError: false,
    statementCertifiedError: false,
    consultationDateError: false,
  });

  const [formState, setFormState] = useState<CaseConsultationNoteFormState>({
    consultationDate: null,
    personConsultedName: null,
    personConsultedCredentials: null,
    consultationSummary: null,
    planOfAction: null,
    followup: null,
    statementCertified: false,
  });

  const { roomID, noteID } = useParams<{ roomID: string; noteID?: string }>();
  const baseURL = `/room/${roomID}/case-consultation-notes`;
  const history = useHistory();

  const { data: { clientUserID } = {} } = useQueryRoomDetails(roomID);
  useQueryNotes(roomID);
  const {
    isLoading: isCaseConsultationNoteLoading,
    isError: isCaseConsultationNoteError,
    data: caseConsultationNoteData,
  } = useQueryCaseConsultationNote(clientUserID, noteID, mode === 'edit' || mode === 'view');

  const isReadOnly = caseConsultationNoteData?.readOnly;

  useEffect(() => {
    if (caseConsultationNoteData) {
      setFormState({
        personConsultedName: caseConsultationNoteData.personConsultedName,
        personConsultedCredentials: caseConsultationNoteData.personConsultedCredentials,
        consultationSummary: caseConsultationNoteData.consultationSummary,
        planOfAction: caseConsultationNoteData.planOfAction,
        followup: caseConsultationNoteData.followup,
        statementCertified: caseConsultationNoteData.statementCertified,
        consultationDate: caseConsultationNoteData.consultationDate
          ? new Date(caseConsultationNoteData.consultationDate)
          : null,
      });
    }
  }, [caseConsultationNoteData]);

  const validatePersonConsultedName = (): boolean => !!formState.personConsultedName;
  const validatePersonConsultedCredentials = (): boolean => !!formState.personConsultedCredentials;
  const validateConsultationSummary = (): boolean => !!formState.consultationSummary;
  const validateConsultationDate = (): boolean => !!formState.consultationDate;
  const validateStatementCertified = (): boolean => formState.statementCertified;

  const validateFormState = () => {
    const personConsultedNameError = !validatePersonConsultedName();
    const personConsultedCredentialsError = !validatePersonConsultedCredentials();
    const consultationSummaryError = !validateConsultationSummary();
    const consultationDateError = !validateConsultationDate();
    const statementCertifiedError = !validateStatementCertified();
    const globalValidationError =
      personConsultedNameError ||
      personConsultedCredentialsError ||
      consultationSummaryError ||
      consultationDateError ||
      statementCertifiedError;
    setFormErrors({
      ...formErrors,
      globalValidationError,
      personConsultedNameError,
      personConsultedCredentialsError,
      consultationSummaryError,
      consultationDateError,
      statementCertifiedError,
    });
    return !globalValidationError;
  };

  const handleActionSettled = () => {
    setTimeout(() => {
      setShowSpinner(false);
      history.push(`/room/${roomID}/notes-tab`);
    }, ACTION_SETTLED_TIMEOUT);
  };

  const handleConsultationDateChange = (date: moment.Moment | null) => {
    if (date) {
      setFormState({ ...formState, consultationDate: date.toDate() });
      setFormErrors({ ...formErrors, consultationDateError: false });
    }
  };

  const handleConsultorNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormState({ ...formState, personConsultedName: e.target.value || null });
    setFormErrors({ ...formErrors, personConsultedNameError: false });
  };

  const handleCredentialsChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormState({ ...formState, personConsultedCredentials: e.target.value || null });
    setFormErrors({ ...formErrors, personConsultedCredentialsError: false });
  };

  const handleConsultationSummaryChange = (consultationSummaryText: string) => {
    setFormState({ ...formState, consultationSummary: consultationSummaryText || null });
    setFormErrors({ ...formErrors, consultationSummaryError: false });
  };

  const handlePlanOfActionChange = (consultationSummaryText: string) => {
    setFormState({ ...formState, planOfAction: consultationSummaryText || null });
  };

  const handleFollowUpChange = (consultationSummaryText: string) => {
    setFormState({ ...formState, followup: consultationSummaryText || null });
  };

  const handleIsCertifiedChange = (isCertified: boolean) => {
    setFormState({ ...formState, statementCertified: isCertified });
    setFormErrors({ ...formErrors, statementCertifiedError: false });
  };

  const handleSubmitButtonClick = () => {
    setShowSpinner(true);
    if (validateFormState()) {
      submitCaseConsultationNote(
        { roomID, noteID, clientUserID, ...formState },
        {
          onSettled: handleActionSettled,
        }
      );
    } else {
      setShowSpinner(false);
    }
  };

  const handleSaveButtonClick = () => {
    setShowSpinner(true);
    if (validateFormState()) {
      updateCaseConsultationNote(
        { roomID, noteID, clientUserID, ...formState },
        {
          onSettled: handleActionSettled,
        }
      );
    } else {
      setShowSpinner(false);
    }
  };

  const handleEditButtonClick = () => {
    history.push(`${baseURL}/${noteID}/edit`);
  };

  const handleCancelButtonClick = () => {
    history.goBack();
  };

  const handleDraftButtonClick = () => {
    setShowSpinner(true);
    saveDraftCaseConsultationNote(
      { roomID, noteID, clientUserID, ...formState },
      {
        onSettled: handleActionSettled,
      }
    );
  };

  const handleDeleteButtonClick = () => {
    history.push(`${baseURL}/${noteID}/delete`);
  };

  const handleCloseErrorClick = () =>
    setFormErrors({ ...formErrors, globalValidationError: false });

  const renderMainView = () => (
    <View style={{ paddingLeft: 10, paddingRight: 10 }}>
      {mode !== 'view' && (
        <View style={{ marginBottom: 30 }}>
          A consultation note documents communication between the primary treating provider and
          another treating provider in order to identify, plan and coordinate treatment. This could
          be a PCP or Pediatrician, outside psychiatrist, therapist, state agency, etc.
        </View>
      )}
      <DatePickerInput
        minDate={moment().add(-1, 'years')}
        maxDate={moment().endOf('day')}
        disabled={mode === 'view'}
        isError={formErrors.consultationDateError}
        placeholder="Date of consultation"
        calendarWrapperStyles={{ zIndex: 99 }}
        inputComponentType="TextInput"
        currentValue={formState.consultationDate ? moment(formState.consultationDate) : null}
        handleInputValueChange={handleConsultationDateChange}
      />
      <InputSection>
        <TextInput
          placeholder="Name of person consulted with"
          style={{
            marginBottom: 10,
            ...(formErrors.personConsultedNameError ? { borderColor: colors.permaDebianRed } : {}),
          }}
          value={formState.personConsultedName || ''}
          onChange={handleConsultorNameChange}
          placeHolderStyle={
            formErrors.personConsultedNameError ? { color: colors.permaDebianRed } : {}
          }
          disabled={mode === 'view'}
        />
      </InputSection>
      <InputSection>
        <IndentedView>
          <TextInput
            placeholder="Credentials"
            style={{
              marginBottom: 10,
              ...(formErrors.personConsultedCredentialsError
                ? { borderColor: colors.permaDebianRed }
                : {}),
            }}
            value={formState.personConsultedCredentials}
            onChange={handleCredentialsChange}
            placeHolderStyle={
              formErrors.personConsultedCredentialsError ? { color: colors.permaDebianRed } : {}
            }
            disabled={mode === 'view'}
          />
        </IndentedView>
      </InputSection>
      <InputSection>
        <Tiny style={{ marginBottom: 10 }}>Summary of consultation</Tiny>
        <TextAreaRounded
          autoSize
          hasLimit
          style={{
            ...getTextAreaContainerBorderStyle(colors, formErrors.consultationSummaryError),
          }}
          placeholder="Summarize the consultation"
          textAreaStyle={getTextAreaStyle(mode === 'view')}
          value={formState.consultationSummary || ''}
          onChangeText={handleConsultationSummaryChange}
          disabled={mode === 'view'}
        />
      </InputSection>
      <InputSection>
        <Tiny style={{ marginBottom: 10 }}>Plan of action (if applicable)</Tiny>
        <TextAreaRounded
          autoSize
          hasLimit
          style={{
            ...getTextAreaContainerBorderStyle(colors, false),
          }}
          placeholder="Describe a plan of action if applicable"
          textAreaStyle={getTextAreaStyle(mode === 'view')}
          value={formState.planOfAction || ''}
          onChangeText={handlePlanOfActionChange}
          disabled={mode === 'view'}
        />
      </InputSection>
      <InputSection>
        <Tiny style={{ marginBottom: 10 }}>Follow-up (if applicable)</Tiny>
        <TextAreaRounded
          autoSize
          hasLimit
          style={{
            ...getTextAreaContainerBorderStyle(colors, false),
          }}
          placeholder="Describe follow-up if applicable"
          textAreaStyle={getTextAreaStyle(mode === 'view')}
          value={formState.followup || ''}
          onChangeText={handleFollowUpChange}
          disabled={mode === 'view'}
        />
      </InputSection>
      <InputSection>
        <Checkbox
          shouldDisplayError={formErrors.statementCertifiedError}
          isDisabled={mode === 'view'}
          isLabelOnRight
          stretch={false}
          alignCenter={false}
          isChecked={formState.statementCertified}
          setIsChecked={handleIsCertifiedChange}
          labelStyle={{ color: colors.black, fontWeight: 400 }}
          label="I certify the above statement to be accurate as of the digital date signed."
        />
      </InputSection>
      {caseConsultationNoteData?.submittedAt && mode === 'view' && (
        <StatementCertification
          user={caseConsultationNoteData.createdByUser}
          submittedAt={new Date(caseConsultationNoteData.submittedAt)}
        />
      )}
    </View>
  );

  const isError = isCaseConsultationNoteError;
  const isLoading =
    isSubmitLoading || isSaveLoading || isCaseConsultationNoteLoading || isSaveDraftLoading;
  const renderFooter = () => (
    <NoteFormFooter
      formMode={mode}
      noteStatus={caseConsultationNoteData?.status}
      canSubmit
      canDeleteDraft={!!noteID}
      canDeleteSubmitted={!!noteID}
      isError={isSubmitError || isSaveError || isSaveDraftError}
      isLoading={isLoading}
      showSpinner={showSpinner}
      editHidden={isReadOnly}
      spinnerMessage="Saved successfully"
      showErrorBanner={formErrors.globalValidationError}
      handleCloseErrorBannerPress={handleCloseErrorClick}
      handleDeleteDraftPress={handleDeleteButtonClick}
      handleSaveDraftPress={handleDraftButtonClick}
      handleSubmitPress={handleSubmitButtonClick}
      handleCancelPress={handleCancelButtonClick}
      handleSavePress={handleSaveButtonClick}
      handleEditPress={handleEditButtonClick}
    />
  );

  if (isError) {
    return <ErrorScreen message="Something went wrong" />;
  }

  const { title, subtitle } = getSubmenuTitleAndSubtitle({
    formMode: mode,
    noteType: 'case-consultation',
    noteDate: caseConsultationNoteData?.consultationDate,
  });

  const handleBackButtonPress = () => {
    if (mode === 'create') {
      const locationState: SaveDraftCaseConsultationNoteMutateVariables = {
        roomID,
        clientUserID,
        ...formState,
      };

      history.push(`/room/${roomID}/case-consultation-notes/save-draft`, locationState);
    } else {
      history.push(`/room/${roomID}/notes-tab`);
    }
  };

  return (
    <Submenu
      title={title}
      titleComponent={
        subtitle ? (
          <View>
            <Large variant="largeBoldWide">{title}</Large>
            <Small variant="smallBoldGrey">{subtitle}</Small>
          </View>
        ) : undefined
      }
      childComponents={[renderMainView()]}
      footerComponent={renderFooter()}
      onBackAlt={handleBackButtonPress}
    />
  );
};

export default CaseConsultationNoteForm;
