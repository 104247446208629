import Svg, { Path } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';
import { EmotionStyle } from '@/core/styled';

const Phone = ({
  width = 20,
  height = 20,
  color,
  style,
  ...otherProps
}: {
  width?: number;
  height?: number;
  color?: string;
  style?: EmotionStyle;
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'phone';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 32 32"
      {...otherProps}
      {...style}
    >
      <Path
        d="M25.35 31.604c-1.988 0-5.072-1.378-8.243-3.671-3.035-2.205-4.386-3.218-7.43-6.358-3.221-3.317-5.247-6.082-7.48-10.186C-.161 7.049.104 5.149.583 3.585.837 2.748 1.6 1.853 2.638 1.203c.049-.03.098-.069.157-.098.519-.335 1.38-.896 2.32-.896.783 0 1.478.394 2.144 1.21l.098.119c2.496 3.07 4.444 5.786 2.975 7.794-1.037 1.407-1.585 2.362-1.497 3.346.078.955.714 1.673 1.674 2.756l.068.079c1.576 1.79 2.027 2.263 2.653 2.903.108.108.215.226.343.354l.176.187c.724.758 1.126 1.171 2.966 2.795l.108.099c.783.689 1.36 1.19 2.202 1.19.872 0 1.831-.55 3.652-1.722.186-.118.45-.187.783-.187 2.124 0 5.785 2.44 7.068 3.77 1.175 1.2 1.762 2.106-.059 4.32-1.958 2.382-3.083 2.382-5.12 2.382z"
        transform="translate(-171 -2286) translate(-1 2268) translate(172 18)"
        fill={color || colors.altoGrey}
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </Svg>
  );
};
export default Phone;
