import { SessionModality } from 'ts-frontend/types';
import VideoIcon from '../../icons/Video';
import PhoneIcon from '../../icons/Phone';
import CommentIcon from '../../icons/Comment';
import Envelope from '../../icons/Envelope';
import { IconProps } from '../../icons/types/types';

interface ModalityIconProps extends IconProps {
  modality: SessionModality;
}

const ModalityIcon = ({ modality, ...rest }: ModalityIconProps) => {
  switch (modality) {
    case 'video':
      return <VideoIcon {...rest} />;
    case 'chat':
      return <CommentIcon {...rest} />;
    case 'audio':
      return <PhoneIcon {...rest} />;
    default:
      return <Envelope {...rest} />;
  }
};

export default ModalityIcon;
