import { FunctionComponent } from 'react';
import Svg, { G, Path, Rect, Circle } from 'svgs';
import { EmotionStyle, useEmotionTheme } from '../../core/styled';

interface AddPartnerProps {
  color?: string;
  width?: number;
  style?: EmotionStyle;
}

const AddPartner: FunctionComponent<AddPartnerProps> = ({ color, width = 32, style }) => {
  const { colors } = useEmotionTheme();
  const titleText = 'Envelope with X';
  return (
    <Svg
      width={width}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 85 66"
      fill={color || colors.lightGray}
      style={style}
    >
      <G fill="none" fill-rule="evenodd">
        <Rect
          stroke="#D0D5DF"
          stroke-width="5"
          fill="#FFF"
          x="2.5"
          y="2.5"
          width="69"
          height="53"
          rx="12"
        />
        <Path
          stroke="#E2E7EA"
          stroke-width="4"
          stroke-linecap="round"
          d="M14 15.5l23.46 21L60 15.5"
        />
        <Circle
          stroke="#FFF"
          stroke-width="2.268"
          fill="#BCC4D3"
          cx="67.122"
          cy="48.122"
          r="16.256"
        />
        <Path
          d="M62.55 53.439a1.097 1.097 0 01-.777-1.875l8.79-8.79a1.097 1.097 0 111.554 1.553l-8.79 8.79a1.096 1.096 0 01-.777.322z"
          fill="#FFF"
          fill-rule="nonzero"
        />
        <Path
          d="M71.34 53.439c-.281 0-.563-.108-.777-.322l-8.79-8.79a1.097 1.097 0 111.554-1.554l8.79 8.79a1.097 1.097 0 01-.777 1.876z"
          fill="#FFF"
          fill-rule="nonzero"
        />
      </G>
    </Svg>
  );
};

export default AddPartner;
