import { useEmotionTheme } from '@talkspace/react-toolkit';
import Svg, { G, Path } from 'svgs';

interface ChartIconProps {
  width?: number;
  height?: number;
  isActive?: boolean;
}

function ChartIcon({ width = 15, height = 17, isActive = false, ...otherProps }: ChartIconProps) {
  const { colors } = useEmotionTheme();
  return (
    <Svg width={width} height={height} viewBox="0 0 15 17" {...otherProps}>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        {isActive ? (
          <>
            <Path
              d="M700.25 107.25H-.25V-.25h700.5v107.5z"
              stroke={colors.heatherGrey}
              strokeWidth={0.5}
              fill={colors.white}
              transform="translate(-206 -79)"
            />
            <Path
              d="M8.896 12.78c-1.9 1.74-2.148 1.523-3.844-.049-1.593-1.478-1.19-3.245-.03-3.643.73-.252 1.416.062 1.864.629a.149.149 0 00.232-.002c.415-.53 1.036-.84 1.731-.667 1.28.318 1.751 2.174.047 3.733zm-2.71-9.13h1.728c.478 0 .865.369.865.825 0 .455-.387.825-.865.825H6.186c-.477 0-.865-.37-.865-.825 0-.456.388-.825.865-.825zm4.321 0c0-.912-.773-1.65-1.728-1.65H5.32c-.954 0-1.728.738-1.728 1.65H1.729C.774 3.65 0 4.388 0 5.3v11.55c0 .91.774 1.65 1.729 1.65H12.37c.955 0 1.729-.74 1.729-1.65V5.3c0-.912-.774-1.65-1.729-1.65h-1.864z"
              transform="translate(-206 -79) translate(206 77)"
              fill={colors.darkGreen}
            />
          </>
        ) : (
          <>
            <Path
              d="M700.25 107.25H-.25V-.25h700.5v107.5z"
              stroke={colors.heatherGrey}
              strokeWidth={0.5}
              fill={colors.white}
              transform="translate(-206 -79)"
            />
            <Path
              d="M5.052 12.731c-1.593-1.478-1.19-3.245-.03-3.643.73-.252 1.416.062 1.864.629a.149.149 0 00.232-.002c.415-.53 1.036-.84 1.731-.667 1.28.318 1.751 2.174.047 3.733-1.9 1.74-2.148 1.522-3.844-.05zm7.32 3.294c0 .455-.387.825-.865.825H2.593c-.477 0-.864-.37-.864-.825V5.713c0-.228.193-.413.432-.413h1.432c0 .91.774 1.65 1.728 1.65H8.78c.955 0 1.728-.74 1.728-1.65h1.432c.239 0 .432.185.432.413v10.312zM6.185 3.65h1.728c.478 0 .865.369.865.825 0 .455-.387.825-.865.825H6.186c-.477 0-.865-.37-.865-.825 0-.456.388-.825.865-.825zm4.321 0c0-.912-.773-1.65-1.728-1.65H5.32c-.954 0-1.728.738-1.728 1.65H1.729C.774 3.65 0 4.388 0 5.3v11.55c0 .91.774 1.65 1.729 1.65H12.37c.955 0 1.729-.74 1.729-1.65V5.3c0-.912-.774-1.65-1.729-1.65h-1.864z"
              transform="translate(-206 -79) translate(206 77)"
              fill="#6C7E9D"
            />
          </>
        )}
      </G>
    </Svg>
  );
}

export default ChartIcon;
