// Prefer named exports

import { OptionGroupType, OptionType } from '@talkspace/react-toolkit/src/components/Select/types';

import { InsuranceEligibilityPayer } from './insuranceEligibility';

export enum QMSessionTypeEnum {
  Eligibility = 'eligibility',
  Reactivation = 'reactivation',
  UpdateCoverage = 'update-coverage',
  MBHIneligibility = 'mbh-ineligibility',
  SignUp = 'signUp',
}

export interface PayerOptionType extends OptionType<string>, InsuranceEligibilityPayer {}

export interface PayerOptionGroupType extends OptionGroupType {}
