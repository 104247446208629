import { FunctionComponent } from 'react';
import Svg, { G, Path } from 'svgs';
import { EmotionStyle, useEmotionTheme } from '../../core/styled';

interface ErrorTooltipIconProps {
  color?: string;
  width?: number;
  height?: number;
  style?: EmotionStyle;
}

const WarningTooltipIcon: FunctionComponent<ErrorTooltipIconProps> = ({
  color,
  width = 33,
  height = 32,
  style,
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'Warning Tooltip Icon';
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      title={titleText}
      style={style}
    >
      <G clipPath="url(#clip0_769_537)">
        <Path
          d="M0.5 4C0.5 1.79375 2.29375 0 4.5 0H28.5C30.7062 0 32.5 1.79375 32.5 4V22C32.5 24.2062 30.7062 26 28.5 26H19.8312L12.1 31.8C11.8 32.025 11.3938 32.0625 11.05 31.8937C10.7063 31.725 10.5 31.3813 10.5 31V26H4.5C2.29375 26 0.5 24.2062 0.5 22V4ZM16.5 5C15.6687 5 15 5.66875 15 6.5V13.5C15 14.3313 15.6687 15 16.5 15C17.3312 15 18 14.3313 18 13.5V6.5C18 5.66875 17.3312 5 16.5 5ZM18.5 19C18.5 18.4696 18.2893 17.9609 17.9142 17.5858C17.5391 17.2107 17.0304 17 16.5 17C15.9696 17 15.4609 17.2107 15.0858 17.5858C14.7107 17.9609 14.5 18.4696 14.5 19C14.5 19.5304 14.7107 20.0391 15.0858 20.4142C15.4609 20.7893 15.9696 21 16.5 21C17.0304 21 17.5391 20.7893 17.9142 20.4142C18.2893 20.0391 18.5 19.5304 18.5 19Z"
          fill={color || colors.deepTeal}
        />
      </G>
      <defs>
        <clipPath id="clip0_769_537">
          <rect width="32" height="32" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default WarningTooltipIcon;
