import apiWrapper from './apiWrapper';
import apiHelper from './api';
import appConfig from './configs';

type SSOProvider = 'zendesk';
const ZendeskPath = {
  KNOWLEDGE_BASE: '/',
  CONTACT_US: '/requests/new',
  MY_TICKETS: '/requests',
  THERAPIST_REVIEWS_ARTICLE: '/hc/en-us/articles/4404611350939',
  THERAPIST_BILLABLE_HOURS_ARTICLE: '/hc/en-us/articles/6408781903387',
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ZendeskPath = keyof typeof ZendeskPath;

const getSsoType = (): SSOProvider | null => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('sso') as SSOProvider;
};

const getSSOToken = (provider) =>
  apiWrapper
    .post(`${apiHelper().authAPIEndpoint}/v2/tokens/jwt/sso`, {
      target: provider,
    })
    .then((res) => res.data.data)
    .then((data) => data.token);

const goToZendesk = (returnTo: string) =>
  getSSOToken('zendesk')
    .then((token) => {
      const dst = `${appConfig.zendesk.ssoDomain}/access/jwt?jwt=${token}&return_to=${returnTo}`;
      window.location.href = dst;
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.error(err);
      throw err;
    });

const openZendesk = (path) => {
  // we must open first the window otherwise we get blocked by popup-blockers.
  const windowReference = window.open();
  return getSSOToken('zendesk').then((token) => {
    const dst = `${appConfig.zendesk.ssoDomain}/access/jwt?jwt=${token}&return_to=${appConfig.zendesk.subDomain}${path}`;
    if (windowReference) windowReference.location.href = dst;
    else window.location.href = dst; // fallback if window.open fails.
  });
};

const handleSSOAfterLogin = () => {
  const queryParams = new URLSearchParams(window.location.search);
  if (queryParams.get('sso')) {
    if (queryParams.get('sso') === 'zendesk' && queryParams.get('return_to')) {
      // strip the domain from the path
      const url = new URL(queryParams.get('return_to') || appConfig.zendesk.subDomain);
      openZendesk(url.pathname);
    }
  }
};

const loginAction = () => {
  switch (getSsoType()) {
    case 'zendesk':
      {
        const urlParams = new URLSearchParams(window.location.search);
        const returnTo = urlParams.get('return_to') || appConfig.zendesk.subDomain;
        goToZendesk(returnTo);
      }
      break;
    default:
      throw new Error('No SSO provider found');
  }
};

export { openZendesk, loginAction, ZendeskPath, handleSSOAfterLogin, getSsoType as isSSO };
