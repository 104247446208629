import Svg, { Path } from 'svgs';
import { EmotionStyle, useEmotionTheme } from '../../core/styled';

interface TimeOffIconProps {
  color?: string;
  width?: number;
  height?: number;
  style?: EmotionStyle;
  isBig?: boolean;
}

const TimeOffIcon = ({ color, width = 12, height = 12, style, isBig }: TimeOffIconProps) => {
  const { colors } = useEmotionTheme();
  return (
    <>
      {isBig ? (
        <Svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.75 3.5V10.5C1.75 11.4665 2.5335 12.25 3.5 12.25H10.5C11.4665 12.25 12.25 11.4665 12.25 10.5V3.5L1.75 3.5ZM0 3.5C0 1.567 1.567 0 3.5 0H10.5C12.433 0 14 1.567 14 3.5V10.5C14 12.433 12.433 14 10.5 14H3.5C1.567 14 0 12.433 0 10.5V3.5ZM5.07931 6.48735C4.8515 6.25954 4.8515 5.8902 5.07931 5.66239C5.30711 5.43459 5.67646 5.43459 5.90427 5.66239L7.14175 6.89988L8.37915 5.66248C8.60696 5.43467 8.97631 5.43467 9.20411 5.66248C9.43192 5.89028 9.43192 6.25963 9.20411 6.48744L7.96671 7.72484L9.2041 8.96222C9.4319 9.19003 9.4319 9.55937 9.2041 9.78718C8.97629 10.015 8.60695 10.015 8.37914 9.78718L7.14175 8.5498L5.90428 9.78727C5.67648 10.0151 5.30713 10.0151 5.07932 9.78727C4.85152 9.55946 4.85152 9.19012 5.07932 8.96231L6.3168 7.72484L5.07931 6.48735Z"
            fill="#220063"
          />
        </Svg>
      ) : (
        <Svg
          width={width}
          height={height}
          style={style}
          viewBox={`0 0 ${width} ${height}`}
          fill="none"
        >
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.5 3V9C1.5 9.82843 2.17157 10.5 3 10.5H9C9.82843 10.5 10.5 9.82843 10.5 9V3L1.5 3ZM0 3C0 1.34315 1.34315 0 3 0H9C10.6569 0 12 1.34315 12 3V9C12 10.6569 10.6569 12 9 12H3C1.34315 12 0 10.6569 0 9V3ZM4.35355 5.56066C4.15829 5.3654 4.15829 5.04882 4.35355 4.85355C4.54882 4.65829 4.8654 4.65829 5.06066 4.85355L6.12141 5.91431L7.18209 4.85363C7.37735 4.65837 7.69394 4.65837 7.8892 4.85363C8.08446 5.04889 8.08446 5.36547 7.8892 5.56074L6.82852 6.62141L7.88909 7.68198C8.08435 7.87724 8.08435 8.19382 7.88909 8.38909C7.69383 8.58435 7.37724 8.58435 7.18198 8.38909L6.12141 7.32852L5.06077 8.38916C4.86551 8.58442 4.54893 8.58442 4.35366 8.38916C4.1584 8.1939 4.1584 7.87732 4.35366 7.68206L5.41431 6.62141L4.35355 5.56066Z"
            fill={color || colors.dune}
          />
        </Svg>
      )}
    </>
  );
};

export default TimeOffIcon;
