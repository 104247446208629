import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const pathConfig: IconConfig = {
  standard: {
    default: {
      path: 'M3.2156 3.46025L4.16247 5.03838C4.03747 5.14775 3.91247 5.26338 3.78747 5.38838C1.56872 7.60713 1.90622 10.1071 2.7656 11.3665C2.92185 11.5946 3.2406 11.5946 3.43435 11.3978L7.76247 7.06963C7.88747 7.40713 8.00622 7.88838 8.0656 8.54463C8.19685 9.99775 8.0156 12.1946 7.04372 15.3071C6.94997 15.6103 7.0031 15.9415 7.19372 16.1978C7.38435 16.454 7.68122 16.604 7.99997 16.604H9.99997C10.5031 16.604 10.9312 16.229 10.9937 15.729C11.3812 12.6353 10.9218 9.254 10.0031 6.604H13.9593C14.0468 6.604 14.1281 6.55713 14.175 6.48213L14.7875 5.46025C14.8843 5.29775 15.1187 5.29775 15.2156 5.46025L15.8281 6.48213C15.8718 6.55713 15.9531 6.604 16.0437 6.604H17.5C17.775 6.604 18.0031 6.379 17.95 6.10713C17.6656 4.61025 16.1375 2.604 13 2.604C11.6343 2.604 10.575 2.98525 9.78747 3.53525C9.2656 2.14775 7.74997 0.604004 4.99997 0.604004C1.86247 0.604004 0.334347 2.61025 0.0499725 4.10713C-0.00315252 4.379 0.224972 4.604 0.499972 4.604H1.95935C2.04685 4.604 2.1281 4.55713 2.17497 4.48213L2.78435 3.46025C2.88122 3.29775 3.1156 3.29775 3.21247 3.46025H3.2156Z',
    },
  },
};

const PalmTree = withDSIconMaker(pathConfig, 'palm tree');

export default PalmTree;
