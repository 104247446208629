import { useEffect, useState, useRef } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useVideoCallState } from 'chat/hooks/videoCallContext';
import { getLogoutLastActivity, updateLogoutLastActivity } from '../../../utils/token';
import './LogoutModal.css';

const secondsToKeepAlive = 30;
const millisecondsToLogout = 1200000;

const LogoutModal = ({ logout }) => {
  const { isJoined } = useVideoCallState();
  const [isCountdownActive, setIsCountdownActive] = useState(false);
  const [countdownNum, setCountdownNum] = useState(secondsToKeepAlive);
  const heartbeatIntervalRef = useRef();

  const handleCloseModal = () => {
    setIsCountdownActive(false);
    setCountdownNum(secondsToKeepAlive);
    updateLogoutLastActivity();
  };

  useEffect(() => {
    const updateActivity = () => {
      if (!isCountdownActive) updateLogoutLastActivity();
    };

    ['keydown', 'mousemove', 'mousedown', 'touchstart'].forEach((event) => {
      window.addEventListener(event, updateActivity);
    });
    return () => {
      ['keydown', 'mousemove', 'mousedown', 'touchstart'].forEach((event) => {
        window.removeEventListener(event, updateActivity);
      });
    };
  }, [isCountdownActive]);

  useEffect(() => {
    heartbeatIntervalRef.current = window.setInterval(() => {
      if (isJoined) {
        updateLogoutLastActivity();
        return;
      }

      const lastTime = Number(getLogoutLastActivity()) || Date.now();
      const isCountdownActiveNow = Date.now() - lastTime > millisecondsToLogout;

      setIsCountdownActive(isCountdownActiveNow);
      if (countdownNum - 1 === 0) logout();
      if (isCountdownActiveNow) setCountdownNum(countdownNum - 1);
      else if (countdownNum !== secondsToKeepAlive) setCountdownNum(secondsToKeepAlive);
    }, 1000);

    return () => {
      if (heartbeatIntervalRef.current) window.clearInterval(heartbeatIntervalRef.current);
    };
  }, [setCountdownNum, setIsCountdownActive, isJoined, countdownNum, logout]);

  return (
    <div className="modal-container timeout-modal">
      <Modal show={isCountdownActive} onHide={handleCloseModal}>
        <Modal.Header>
          <Modal.Title>Your session has been idle for a while</Modal.Title>
          <Modal.Body>{`You will be logged out in ${countdownNum} seconds`}</Modal.Body>
          <Modal.Footer>
            <Button className="ts-btn btn-ts-green keep-me" onClick={handleCloseModal}>
              Keep me logged in
            </Button>
          </Modal.Footer>
        </Modal.Header>
      </Modal>
    </div>
  );
};

export default LogoutModal;
