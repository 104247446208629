import { useQuery } from 'react-query';
import apiWrapper from '../utils/apiWrapper';
import apiHelper from '../utils/api';
import { SessionService } from '../components/Room/CRMContainer/NotesTab/types';
import { sessionServicesV3QueryKey } from './queryKeys';

interface SessionServicesVariables {
  roomID: string;
  clientUserID?: number;
  liveCallID?: number;
  asyncSessionID?: number;
}

const fetchSessionServices = (params: SessionServicesVariables) => async () => {
  const { roomID, clientUserID, asyncSessionID, liveCallID } = params;
  let url = `${
    apiHelper().apiEndpoint
  }/v3/rooms/${roomID}/clients/${clientUserID}/session-services`;

  const queryParams: string[] = [];
  if (asyncSessionID) {
    queryParams.push(`asyncSessionID=${asyncSessionID}`);
  }
  if (liveCallID) {
    queryParams.push(`liveCallID=${liveCallID}`);
  }

  if (queryParams.length) {
    url += `?${queryParams.join('&')}`;
  }

  const { data } = await apiWrapper.get(url);

  return data.data;
};

const useQuerySessionServicesV2 = (params: SessionServicesVariables) =>
  useQuery<SessionService[], Error>({
    queryKey: sessionServicesV3QueryKey(params),
    queryFn: fetchSessionServices(params),
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!params.clientUserID && (!!params.liveCallID || !!params.asyncSessionID),
  });

export default useQuerySessionServicesV2;
