import { FunctionComponent } from 'react';
import styled from '@emotion/styled';
import commonStyles from '../../constants/commonStyles';
import { EmotionStyle, EmotionTheme, useEmotionTheme } from '../../core/styled';

const { tinyDefaultStyle } = commonStyles;

export interface LabelProps {
  id?: string;
  htmlFor?: string;
  style?: EmotionStyle;
  isDefaultStyle?: boolean;
}

const defaultStyles = (colors: EmotionTheme['colors']) => {
  return {
    fontSize: 13,
    color: colors.permaWaikawaGrey,
  };
};

const StyledLabel = styled.label<{ style?: EmotionStyle }>(({ style }) => {
  return {
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 400,
    margin: 0,
    paddingLeft: 4,
    paddingBottom: 4,
    textAlign: 'left',
    ...tinyDefaultStyle,
    ...style,
  };
});

const Label: FunctionComponent<LabelProps> = ({
  id,
  htmlFor,
  style,
  isDefaultStyle,
  children,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  const styles = isDefaultStyle ? { ...defaultStyles(colors), ...style } : style;
  return (
    <StyledLabel id={id} htmlFor={htmlFor} style={styles} {...otherProps}>
      {children}
    </StyledLabel>
  );
};

export default Label;
