import {
  View,
  useEmotionTheme,
  Heading3,
  Small,
  EmotionStyle,
  RadioButton,
  Reminder,
  Collapsible,
} from '@talkspace/react-toolkit';
import { useState } from 'react';
import ModalContainer from './ModalContainer';
import { getRadioButtonStyle } from '../utils';

const StyledRadioButton = ({
  handlePress,
  title,
  isActive,
}: {
  handlePress: () => void;
  title: string;
  isActive: boolean;
}) => {
  const { colors } = useEmotionTheme();
  const { innerCircleStyle, circleStyle } = getRadioButtonStyle(colors);
  return (
    <View align="center" row style={{ marginBottom: 12 }} onClick={handlePress}>
      <RadioButton
        innerCircleStyle={innerCircleStyle}
        circleStyle={circleStyle}
        style={{ marginRight: 7 }}
        isActive={isActive}
        onPress={handlePress}
      />
      <Small variant="smallTSBlack">{title}</Small>
    </View>
  );
};

interface ModifyBlockModalProps {
  isModalOpen: boolean;
  positioningStyle?: EmotionStyle;
  title: string;
  handleCloseModal: () => void;
  handleSubmit: (value: boolean) => void;
}

const ModifyBlockModal = ({
  isModalOpen,
  positioningStyle,
  title,
  handleCloseModal,
  handleSubmit,
}: ModifyBlockModalProps) => {
  const [isReccuring, setIsReccuring] = useState<boolean>(false);

  return (
    <ModalContainer
      isModalOpen={isModalOpen}
      handleCloseModal={handleCloseModal}
      primaryButtonText="OK"
      primaryButtonOnClick={() => handleSubmit(isReccuring)}
      secondaryButtonOnClick={handleCloseModal}
      secondaryButtonText="Cancel"
      positioningStyle={positioningStyle}
    >
      <View style={{ marginLeft: 68 }}>
        <View style={{ marginBottom: 30 }} row>
          <Heading3>{title}</Heading3>
        </View>

        <StyledRadioButton
          isActive={!isReccuring}
          title="This week"
          handlePress={() => setIsReccuring(false)}
        />
        <StyledRadioButton
          isActive={isReccuring}
          title="This and future weeks"
          handlePress={() => setIsReccuring(true)}
        />
        <Collapsible isCollapsed={!isReccuring}>
          <Reminder
            isReminderLabel
            reminderText='Selecting "This and future weeks" will override changes made to future weeks.'
          />
        </Collapsible>
      </View>
    </ModalContainer>
  );
};

export default ModifyBlockModal;
