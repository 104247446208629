import { FunctionComponent, PropsWithChildren } from 'react';
import {
  View,
  Big,
  TSLogo,
  PanelHeader,
  useWindowWidth,
  ContentBackWrapper,
  HeaderBackWrapper,
  ScreenProvider,
} from '@talkspace/react-toolkit';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { useWizardState } from 'stepWizard/hooks/wizardContext';
import styled from '@/core/styled';
import BackButton from '../BackButton';

const Wrapper = styled(View)<{ isLightBox?: boolean }>(({ isLightBox, theme: { colors } }) => {
  return {
    borderRadius: isLightBox ? 10 : 0,
    boxShadow: isLightBox ? '0 5px 10px 0 rgba(0, 0, 0, 0.4)' : 'none',
    backgroundColor: colors.white,
    alignItems: 'stretch',
    height: isLightBox ? '84vh' : '100vh',
    overflowY: 'auto',
    minWidth: isLightBox ? 500 : undefined,
  };
});

interface StepWrapperProps {
  onCloseButtonClick: () => void;
  showBackButton?: boolean;
  title?: string;
  isLightBox?: boolean;
}

const StepWrapper: FunctionComponent<StepWrapperProps> = ({
  children,
  onCloseButtonClick,
  showBackButton = false,
  title,
  isLightBox = false,
}: PropsWithChildren<StepWrapperProps>) => {
  const { isMobile } = useWindowWidth();
  const { brandRefreshChanges } = useFlags();
  const { isClosable, hideHeader } = useWizardState<{ isClosable: boolean; hideHeader: boolean }>();
  return (
    <Wrapper isLightBox={!isMobile && isLightBox}>
      <PanelHeader
        style={hideHeader ? { display: 'none' } : {}}
        renderLeft={() => (
          <>
            {isMobile && (
              <View>
                {showBackButton && (
                  <HeaderBackWrapper>
                    <BackButton />
                  </HeaderBackWrapper>
                )}
              </View>
            )}
            <View flex={1} align={isMobile ? 'center' : 'start'}>
              {title ? (
                <Big>{title}</Big>
              ) : (
                <TSLogo variant={brandRefreshChanges ? '2024' : 'old'} />
              )}
            </View>
          </>
        )}
        onRightPress={isClosable && onCloseButtonClick ? () => onCloseButtonClick() : undefined}
      />
      <View
        style={{
          overflowY: 'auto',
          overflowX: 'hidden',
          height: 'calc(100% - 66px)',
        }}
      >
        {showBackButton && (
          <ContentBackWrapper>
            <BackButton />
          </ContentBackWrapper>
        )}
        {children}
      </View>
    </Wrapper>
  );
};

const StepWrapperWithProvider: FunctionComponent<StepWrapperProps> = (props) => (
  <ScreenProvider>
    <StepWrapper {...props} />
  </ScreenProvider>
);

export default StepWrapperWithProvider;
