import { VoidFunctionComponent } from 'react';
import Svg, { Path, G } from 'svgs';
import { useEmotionTheme } from '../../core/styled';
import { useUniqueID } from '../../hooks/a11yHelper';

interface Props {
  width?: number;
  height?: number;
}

const MessagingConfirmSession: VoidFunctionComponent<Props> = ({
  width = 160,
  height = 154,
  ...otherProps
}) => {
  const titleText = 'Messaging Confirm Session';
  const { colors } = useEmotionTheme();

  const maskId1 = useUniqueID('maskId1');
  const maskId2 = useUniqueID('maskId2');
  const maskId3 = useUniqueID('maskId3');

  return (
    <Svg
      title={titleText}
      width={width}
      height={height}
      viewBox="0 0 160 154"
      fill="none"
      {...otherProps}
    >
      <G opacity="0.5">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.3474 46.77C39.4615 50.4721 40.0912 56.3413 39.3751 61.4611C38.6505 66.5807 36.5806 70.9422 33.4581 74.1477C30.3358 77.3532 26.1697 79.4027 22.1589 79.2494C18.1396 79.0866 14.2754 76.721 10.4975 73.5155C6.71962 70.31 3.0194 66.2646 2.70889 61.9035C2.40697 57.5332 5.49486 52.8559 9.28137 49.1538C13.0593 45.4517 17.5272 42.7248 22.573 42.1288C27.6188 41.5329 33.2252 43.0679 36.3474 46.77Z"
          fill={colors.permaCornflowerBlueApprox}
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M77.6355 59.6945C83.3923 65.3309 87.4542 76.2029 88.1259 88.077C88.813 99.9287 86.1254 112.806 80.3841 120.826C74.6272 128.846 65.8008 132.01 57.2799 131.609C48.7441 131.185 40.4979 127.175 33.1226 119.155C25.7471 111.135 19.2267 99.1047 19.3183 87.2081C19.41 75.3115 26.0983 63.5487 33.4891 57.9123C40.8645 52.2536 48.9274 52.7438 56.6847 53.1893C64.442 53.6349 71.8937 54.0582 77.6355 59.6945Z"
          fill={colors.permaMoonRaker}
        />
      </G>
      <Path
        opacity="0.5"
        d="M80 147.592C124.183 147.592 160 146.295 160 144.695C160 143.096 124.183 141.799 80 141.799C35.8172 141.799 0 143.096 0 144.695C0 146.295 35.8172 147.592 80 147.592Z"
        fill={colors.permaPeriwinkleGray}
      />
      <Path
        d="M115.155 0.725586H38.7009C37.7356 0.725586 36.9531 1.50811 36.9531 2.4734V140.8C36.9531 141.766 37.7356 142.548 38.7009 142.548H115.155C116.121 142.548 116.903 141.766 116.903 140.8V2.4734C116.903 1.50811 116.121 0.725586 115.155 0.725586Z"
        fill={colors.permaSlateGrayApprox}
      />
      <Path
        d="M112.559 3.92188H41.0483C40.5519 3.92188 40.1494 4.32432 40.1494 4.82075V122.274C40.1494 122.77 40.5519 123.173 41.0483 123.173H112.559C113.055 123.173 113.458 122.77 113.458 122.274V4.82075C113.458 4.32432 113.055 3.92188 112.559 3.92188Z"
        fill={colors.permaSelagoApprox}
      />
      <Path
        d="M76.9279 137.904C79.6997 137.904 81.9466 135.635 81.9466 132.835C81.9466 130.036 79.6997 127.767 76.9279 127.767C74.1561 127.767 71.9092 130.036 71.9092 132.835C71.9092 135.635 74.1561 137.904 76.9279 137.904Z"
        fill={colors.permaSelagoApprox}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M108.315 9.62715L102.272 8.76758L102.85 12.6128L108.315 12.3136V9.62715Z"
        fill={colors.permaSlateGrayApprox}
      />
      <Path
        d="M101.952 8.65522L102.54 8.56738L103.151 12.6716L102.717 13.7136H108.615V14.3102H101.823L102.538 12.595L101.952 8.65522Z"
        fill={colors.permaSlateGrayApprox}
      />
      <Path
        d="M101.328 7.81836C102.034 7.81836 102.481 8.11599 102.569 8.67953C102.594 8.84178 102.478 8.99059 102.312 9.01381C102.152 9.03639 102 8.93202 101.97 8.77591L101.969 8.76732C101.93 8.51788 101.751 8.40518 101.328 8.40518C101.162 8.40518 101.024 8.27519 101.024 8.11174C101.024 7.9483 101.162 7.81836 101.328 7.81836Z"
        fill={colors.permaSlateGrayApprox}
      />
      <Path
        d="M107.941 14.9593C107.706 14.9593 107.516 14.7692 107.516 14.5348C107.516 14.3004 107.706 14.1104 107.941 14.1104C108.175 14.1104 108.365 14.3004 108.365 14.5348C108.365 14.7692 108.175 14.9593 107.941 14.9593Z"
        fill={colors.permaSlateGrayApprox}
      />
      <Path
        d="M102.847 14.9593C102.613 14.9593 102.423 14.7692 102.423 14.5348C102.423 14.3004 102.613 14.1104 102.847 14.1104C103.081 14.1104 103.271 14.3004 103.271 14.5348C103.271 14.7692 103.081 14.9593 102.847 14.9593Z"
        fill={colors.permaSlateGrayApprox}
      />
      <Path
        d="M104.769 25.7939H49.1383C48.6143 25.7939 48.1895 26.2187 48.1895 26.7428V60.0511C48.1895 60.5751 48.6143 60.9999 49.1383 60.9999H104.769C105.293 60.9999 105.718 60.5751 105.718 60.0511V26.7428C105.718 26.2187 105.293 25.7939 104.769 25.7939Z"
        fill={colors.permaGreyed}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.443 21L96.6419 33.67L76.8873 42.9611L56.6885 33.2255L76.443 21Z"
        fill={colors.permaComet}
      />
      <Path d="M92.9588 28.4004H60.4131V53.4253H92.9588V28.4004Z" fill={colors.permaFog} />
      <Path
        d="M60.4131 28.4004H92.9588V51.7614C92.9588 52.6804 92.2139 53.4253 91.2949 53.4253H60.4131V28.4004Z"
        fill={colors.white}
      />
      <Path
        d="M90.2356 31.293H63.2188V31.6919H90.2356V31.293Z"
        fill={colors.permaSlateGrayApprox}
      />
      <Path
        d="M90.2356 40.1426H63.2188V40.5416H90.2356V40.1426Z"
        fill={colors.permaSlateGrayApprox}
      />
      <Path
        d="M90.2356 33.5059H63.2188V33.9048H90.2356V33.5059Z"
        fill={colors.permaSlateGrayApprox}
      />
      <Path
        d="M90.2356 42.3555H63.2188V42.7544H90.2356V42.3555Z"
        fill={colors.permaSlateGrayApprox}
      />
      <Path
        d="M90.2356 35.7178H63.2188V36.1167H90.2356V35.7178Z"
        fill={colors.permaSlateGrayApprox}
      />
      <Path
        d="M90.2356 44.5674H63.2188V44.9664H90.2356V44.5674Z"
        fill={colors.permaSlateGrayApprox}
      />
      <Path
        d="M90.2356 37.9307H63.2188V38.3296H90.2356V37.9307Z"
        fill={colors.permaSlateGrayApprox}
      />
      <Path
        d="M90.2356 46.7803H63.2188V47.1792H90.2356V46.7803Z"
        fill={colors.permaSlateGrayApprox}
      />
      <Path
        d="M90.2357 50.0078H77.5557V50.4068H90.2357V50.0078Z"
        fill={colors.permaSlateGrayApprox}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.7022 33.2266H56.6865V55.0568H96.6865V33.2266H95.9201L96.6423 33.6793L76.8877 42.9662L56.6889 33.2349L56.7022 33.2266Z"
        fill={colors.permaBaliHaiApprox}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.6865 55.0568L76.8696 42.9662L56.6865 33.2266V55.0568Z"
        fill={colors.darkBlue}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M95.9707 12.6486L96.6498 12.2705L98.0935 14.5706L97.1477 15.097L95.9707 12.6486Z"
        fill={colors.permaSlateGrayApprox}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M92.6582 10.5631C92.6582 9.11519 93.8322 7.94141 95.2799 7.94141C96.7276 7.94141 97.9016 9.11519 97.9016 10.5631C97.9016 12.0111 96.7276 13.1849 95.2799 13.1849C93.8322 13.1849 92.6582 12.0111 92.6582 10.5631ZM97.1526 10.5631C97.1526 9.52887 96.3141 8.69047 95.2799 8.69047C94.2457 8.69047 93.4073 9.52887 93.4073 10.5631C93.4073 11.5974 94.2457 12.4358 95.2799 12.4358C96.3141 12.4358 97.1526 11.5974 97.1526 10.5631Z"
        fill={colors.permaSlateGrayApprox}
      />
      <Path
        d="M51.8849 8.51562H44.4941V9.51438H51.8849V8.51562Z"
        fill={colors.permaSlateGrayApprox}
      />
      <Path
        d="M51.8849 10.5635H44.4941V11.5622H51.8849V10.5635Z"
        fill={colors.permaSlateGrayApprox}
      />
      <Path
        d="M51.8849 12.7109H44.4941V13.7097H51.8849V12.7109Z"
        fill={colors.permaSlateGrayApprox}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M100.899 106.044H81.6227C78.989 106.044 76.8536 108.179 76.8536 110.813C76.8536 113.447 78.989 115.582 81.6227 115.582H100.899C103.532 115.582 105.668 113.447 105.668 110.813C105.668 108.179 103.532 106.044 100.899 106.044ZM81.6227 105.994C78.961 105.994 76.8037 108.151 76.8037 110.813C76.8037 113.475 78.961 115.632 81.6227 115.632H100.899C103.56 115.632 105.718 113.475 105.718 110.813C105.718 108.151 103.56 105.994 100.899 105.994H81.6227Z"
        fill={colors.permaDustyGray}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.8857 71.9366L49.7134 73.0787L50.1284 70.6597L48.3711 68.9469L50.7996 68.5943L51.8857 66.3936L52.9718 68.5943L55.4003 68.9469L53.643 70.6597L54.058 73.0787L51.8857 71.9366Z"
        fill={colors.permaCadetBlue}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.9746 71.9366L58.8023 73.0787L59.2173 70.6597L57.46 68.9469L59.8884 68.5943L60.9746 66.3936L62.0607 68.5943L64.4892 68.9469L62.7319 70.6597L63.1469 73.0787L60.9746 71.9366Z"
        fill={colors.permaCadetBlue}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.0634 71.9366L67.8912 73.0787L68.3061 70.6597L66.5488 68.9469L68.9773 68.5943L70.0634 66.3936L71.1496 68.5943L73.578 68.9469L71.8207 70.6597L72.2357 73.0787L70.0634 71.9366Z"
        fill={colors.permaCadetBlue}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M79.1513 71.9366L76.979 73.0787L77.394 70.6597L75.6367 68.9469L78.0652 68.5943L79.1513 66.3936L80.2375 68.5943L82.6659 68.9469L80.9086 70.6597L81.3236 73.0787L79.1513 71.9366Z"
        fill={colors.permaCadetBlue}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M88.2402 71.9366L86.0679 73.0787L86.4829 70.6597L84.7256 68.9469L87.154 68.5943L88.2402 66.3936L89.3263 68.5943L91.7548 68.9469L89.9975 70.6597L90.4125 73.0787L88.2402 71.9366Z"
        fill={colors.permaCadetBlue}
      />
      <Path
        d="M103.421 79.1777H50.4876C49.2189 79.1777 48.1904 80.2062 48.1904 81.4749V98.3038C48.1904 99.5725 49.2189 100.601 50.4876 100.601H103.421C104.69 100.601 105.719 99.5725 105.719 98.3038V81.4749C105.719 80.2062 104.69 79.1777 103.421 79.1777Z"
        fill={colors.white}
      />
      <Path
        d="M99.3765 84.6211H54.233C54.1226 84.6211 54.0332 84.7105 54.0332 84.8208V86.5687C54.0332 86.679 54.1226 86.7684 54.233 86.7684H99.3765C99.4868 86.7684 99.5763 86.679 99.5763 86.5687V84.8208C99.5763 84.7105 99.4868 84.6211 99.3765 84.6211Z"
        fill={colors.permaSlateGrayApprox}
      />
      <Path
        d="M70.5625 89.0654H54.233C54.1226 89.0654 54.0332 89.1549 54.0332 89.2652V91.013C54.0332 91.1233 54.1226 91.2127 54.233 91.2127H70.5625C70.6729 91.2127 70.7623 91.1233 70.7623 91.013V89.2652C70.7623 89.1549 70.6729 89.0654 70.5625 89.0654Z"
        fill={colors.permaSlateGrayApprox}
      />
      <Path
        d="M73.8584 93.5596H54.233C54.1226 93.5596 54.0332 93.649 54.0332 93.7593V95.5071C54.0332 95.6175 54.1226 95.7069 54.233 95.7069H73.8584C73.9687 95.7069 74.0582 95.6175 74.0582 95.5071V93.7593C74.0582 93.649 73.9687 93.5596 73.8584 93.5596Z"
        fill={colors.permaSlateGrayApprox}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M104.118 117.33L102.32 134.169C102.32 134.169 119.814 138.376 126.192 132.874L126.84 118.05L104.118 117.33Z"
        fill={colors.athensGrey}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M111.756 95.4072C111.756 95.4072 111.572 99.4598 109.208 100.89C107.063 102.188 111.607 109.72 116.495 109.271C119.171 109.025 119.798 101.707 119.798 101.707C119.798 101.707 117.638 101.29 118.436 97.9949L111.756 95.4072Z"
        fill={colors.permaCopper}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M115.67 99.5278C112.5 99.1445 110.213 97.0609 109.893 93.6043C108.972 93.751 108.755 91.3505 109.011 90.5794C109.268 89.8071 110.106 90.2587 110.106 90.2587C110.106 90.2587 110.176 90.0756 110.225 89.8754C111.609 84.1969 115.486 84.4221 115.486 84.4221C122.611 84.7933 120.942 92.0673 120.535 93.9971C120.128 95.927 118.733 99.8979 115.67 99.5278Z"
        fill={colors.permaCopper}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M126.206 122.729C125.154 122.301 123.8 120.946 122.179 118.702C120.977 117.038 120.069 115.477 120.06 115.462L120.232 115.361C120.268 115.423 123.849 121.553 126.281 122.544L126.206 122.729Z"
        fill={colors.permaCannonPink}
      />
      <mask id={maskId1} maskUnits="userSpaceOnUse" x="97" y="100" width="31" height="32">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M97.8232 100.68H127.982V131.477H97.8232V100.68Z"
          fill={colors.white}
        />
      </mask>
      <G mask={`url(#${maskId1})`}>
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M109.408 100.68C109.408 100.68 100.533 101.52 98.7033 110.738C96.8732 119.956 96.6541 130.828 110.756 131.391C124.858 131.954 126.057 129.582 126.047 129.063C126.037 128.545 126.287 120.006 127.435 113.514C128.584 107.022 128.684 103.526 119.894 101.379C119.894 101.379 115.999 106.572 109.408 100.68Z"
          fill={colors.athensGrey}
        />
      </G>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M116.151 104.476L117.199 99.6816C117.199 99.6816 117.15 98.8826 118.398 98.9326C119.646 98.9825 128.486 98.8327 128.486 98.8327C128.486 98.8327 129.185 98.7328 129.384 99.382C129.584 100.031 126.987 110.918 126.987 110.918C126.987 110.918 127.037 111.667 126.188 111.667C125.339 111.667 114.902 112.066 114.902 112.066L116.151 104.476Z"
        fill={colors.permaCloudBurst}
      />
      <mask id={maskId2} maskUnits="userSpaceOnUse" x="114" y="98" width="16" height="15">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M114.871 98.8018H129.418V112.091H114.871V98.8018Z"
          fill={colors.white}
        />
      </mask>
      <G mask={`url(#${maskId2})`}>
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M116.174 104.479L114.93 112.039C115.557 112.015 125.37 111.641 126.187 111.641C126.447 111.641 126.648 111.569 126.781 111.426C126.978 111.216 126.961 110.921 126.961 110.918L126.96 110.915L126.961 110.911C126.987 110.802 129.554 100.024 129.359 99.3885C129.168 98.7676 128.515 98.8525 128.487 98.8565C128.396 98.8583 119.63 99.0061 118.395 98.9566C117.938 98.9383 117.606 99.0314 117.408 99.2332C117.211 99.4345 117.223 99.6768 117.223 99.6792L117.223 99.6826L117.222 99.686L116.174 104.479ZM114.871 112.091L116.125 104.469L117.173 99.6786C117.171 99.6448 117.17 99.4052 117.372 99.1991C117.58 98.9862 117.926 98.8884 118.397 98.9067C119.631 98.9561 128.395 98.8083 128.483 98.8068C128.487 98.806 129.203 98.7119 129.406 99.3737C129.606 100.02 127.129 110.425 127.011 110.919C127.013 110.958 127.019 111.245 126.818 111.46C126.675 111.613 126.462 111.691 126.187 111.691C125.348 111.691 115.006 112.086 114.902 112.09L114.871 112.091Z"
          fill={colors.trueBlack}
        />
      </G>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M116.5 104.824L117.549 100.03C117.549 100.03 117.499 99.2312 118.748 99.2812C119.996 99.3311 128.835 99.1813 128.835 99.1813C128.835 99.1813 129.534 99.0814 129.734 99.7306C129.934 100.38 127.337 111.266 127.337 111.266C127.337 111.266 127.387 112.015 126.538 112.015C125.689 112.015 115.252 112.415 115.252 112.415L116.5 104.824Z"
        fill={colors.darkBlue}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M111.067 112.342C111.067 112.342 113.971 109.032 114.763 107.613C115.555 106.195 115.555 105.367 115.489 104.717C115.423 104.067 115.423 103.299 115.951 103.181C116.479 103.062 116.545 103.358 116.611 104.067C116.677 104.776 116.611 105.486 117.8 105.072C118.988 104.658 121.892 103.653 122.222 104.481C122.552 105.308 121.826 105.426 121.826 105.426C121.826 105.426 122.354 105.486 122.486 105.899C122.618 106.313 122.486 106.609 122.09 106.786C121.694 106.963 121.496 107.022 121.496 107.022C121.496 107.022 122.288 106.904 122.486 107.495C122.684 108.086 122.354 108.382 122.024 108.5C122.024 108.5 122.448 108.568 122.552 109.032C122.618 109.327 122.222 109.505 121.76 109.682C121.135 109.922 119.252 109.978 117.668 110.982C116.083 111.987 112.255 118.548 110.341 120.557C108.427 122.567 106.513 117.366 106.513 117.366C106.513 117.366 108.889 114.351 111.067 112.342Z"
        fill={colors.permaCopper}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M118.546 106.386L118.507 106.241L121.807 105.354L121.846 105.499L118.546 106.386Z"
        fill={colors.trueBlack}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M118.546 107.922L118.507 107.777L121.807 106.891L121.846 107.035L118.546 107.922Z"
        fill={colors.trueBlack}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M118.941 109.459L118.902 109.314L122.203 108.428L122.241 108.572L118.941 109.459Z"
        fill={colors.trueBlack}
      />
      <mask id={maskId3} maskUnits="userSpaceOnUse" x="124" y="103" width="8" height="20">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M124.808 103.027H131.233V122.751H124.808V103.027Z"
          fill={colors.white}
        />
      </mask>
      <G mask={`url(#${maskId3})`}>
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M126.479 119.934C126.479 119.934 126.941 117.569 127.139 114.614C127.337 111.659 127.271 110.359 127.007 110.122C126.743 109.886 124.895 109.945 124.895 108.999C124.895 108.054 126.215 108.349 126.215 108.349C126.215 108.349 124.829 108.113 124.961 107.404C125.093 106.694 125.885 106.813 125.885 106.813C125.885 106.813 124.631 106.576 124.829 105.808C125.027 105.04 126.215 105.335 126.215 105.335C126.215 105.335 125.093 105.158 125.555 104.389C126.017 103.621 126.809 104.094 127.733 104.33C128.657 104.567 128.723 104.626 128.789 104.271C128.855 103.917 128.723 102.971 129.449 103.03C130.175 103.089 130.307 103.326 130.307 104.626C130.307 105.926 130.505 107.108 130.703 108.29C130.901 109.472 130.967 110.832 130.835 113.019C130.703 115.205 132.551 121.648 129.251 122.593C125.951 123.539 126.479 119.934 126.479 119.934Z"
          fill={colors.permaCopper}
        />
      </G>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M127.874 108.756L125.933 108.339L125.964 108.192L127.905 108.609L127.874 108.756Z"
        fill={colors.trueBlack}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M127.827 107.304L125.886 106.887L125.918 106.74L127.859 107.157L127.827 107.304Z"
        fill={colors.trueBlack}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M127.912 105.742L125.971 105.325L126.002 105.179L127.943 105.596L127.912 105.742Z"
        fill={colors.trueBlack}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M129.699 104.789L128.742 104.582L128.774 104.436L129.731 104.642L129.699 104.789Z"
        fill={colors.trueBlack}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M113.814 142.677C113.814 142.677 115.949 143.603 116.207 143.243C116.464 142.883 114.252 145.121 114.252 145.121L112.657 144.22L113.814 142.677Z"
        fill={colors.permaCopper}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M113.715 142.677L112.471 144.336L114.19 145.306L114.23 145.265L114.177 145.213C114.23 145.265 114.23 145.265 114.23 145.265L114.255 145.24C114.271 145.224 114.295 145.2 114.325 145.17C114.385 145.11 114.47 145.024 114.572 144.922C114.775 144.717 115.043 144.449 115.303 144.192C115.564 143.935 115.816 143.69 115.986 143.531C116.009 143.509 116.031 143.49 116.051 143.472C116.108 143.455 116.159 143.426 116.193 143.378M115.979 143.335C116.02 143.298 116.053 143.27 116.077 143.252C116.087 143.245 116.098 143.237 116.109 143.232C116.113 143.229 116.124 143.224 116.137 143.222C116.148 143.22 116.191 143.216 116.216 143.258C116.226 143.274 116.228 143.29 116.228 143.299C116.227 143.309 116.226 143.317 116.224 143.321C116.222 143.331 116.218 143.339 116.215 143.344C116.209 143.355 116.201 143.366 116.193 143.378M115.979 143.335C115.969 143.337 115.958 143.338 115.947 143.34C115.873 143.348 115.776 143.342 115.661 143.324C115.431 143.289 115.144 143.209 114.863 143.116C114.582 143.023 114.308 142.919 114.105 142.839C114.003 142.798 113.919 142.763 113.861 142.739C113.831 142.727 113.809 142.717 113.793 142.71C113.785 142.707 113.779 142.705 113.775 142.703L113.771 142.701L113.769 142.7C113.769 142.7 113.769 142.7 113.74 142.769L113.769 142.7L113.715 142.677M113.764 142.861C113.776 142.866 113.788 142.871 113.803 142.877C113.862 142.902 113.947 142.937 114.049 142.978C114.254 143.059 114.531 143.164 114.816 143.258C115.1 143.352 115.396 143.435 115.638 143.472C115.698 143.482 115.756 143.488 115.81 143.491C115.643 143.649 115.423 143.863 115.198 144.085C114.938 144.343 114.669 144.612 114.465 144.816C114.364 144.918 114.278 145.004 114.218 145.065C114.197 145.086 114.178 145.105 114.164 145.119L112.693 144.289L113.764 142.861ZM116.083 143.274C116.083 143.274 116.083 143.274 116.083 143.274C116.083 143.273 116.083 143.273 116.083 143.274Z"
        fill={colors.trueBlack}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M130.921 143.809C130.921 143.809 134.059 145.635 131.564 148.49C129.069 151.345 120.245 149.391 120.245 149.391L120.014 148.259C120.014 148.259 128.888 143.963 130.921 143.809Z"
        fill={colors.permaEbb}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M130.919 143.809C130.919 143.809 132.154 144.066 132.54 145.481C132.926 146.895 132.075 148.543 130.829 149.095C130.829 149.095 132.42 145.564 129.625 144.108L130.919 143.809Z"
        fill={colors.permaWafer}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M129.911 149.497C129.911 149.497 128.784 147.204 128.27 147.256C127.755 147.307 122.186 147.596 121.627 147.49L119.781 148.233L120.107 149.411C120.107 149.411 126.974 151.018 129.911 149.497Z"
        fill={colors.permaEbbApprox}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M128.399 145.172C128.399 145.172 128.81 148.156 128.219 148.156C127.627 148.156 127.473 144.812 127.37 145.969C127.267 147.127 127.19 148.593 126.804 148.156C126.418 147.718 127.215 144.477 126.881 145.378C126.547 146.278 126.341 147.976 125.801 147.95C125.261 147.924 126.006 146.201 125.724 146.149C125.441 146.098 124.875 148.387 124.386 148.104C123.897 147.821 124.798 145.944 124.283 146.638C123.769 147.333 123.614 148.156 123.151 148.053C122.688 147.95 123.203 147.05 123.486 146.612C123.769 146.175 128.116 144.503 128.244 144.709C128.373 144.915 128.399 145.172 128.399 145.172Z"
        fill={colors.permaAlabaster}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M121.762 148.104C121.762 148.104 132.155 145.095 136.991 141.262C141.827 137.429 144.63 133.699 141.904 129.507C139.177 125.314 132.772 128.503 129.685 129.867C126.598 131.23 116.476 133.158 111.647 133.146C106.817 133.134 100.335 132.773 96.9653 131.77C93.5954 130.767 88.2196 129.249 86.5732 133.339C84.9267 137.429 86.8563 141.442 89.6858 142.805C92.5153 144.169 101.235 147.101 105.711 146.818L110.187 146.535C110.187 146.535 112.116 144.4 111.602 141.288L114.303 143.243C114.303 143.243 115.846 143.217 116.412 143.011C116.978 142.805 120.116 150.342 121.762 148.104Z"
        fill={colors.permaBlueStoneApprox}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M119.011 148.464C119.011 148.464 114.483 150.136 113.403 150.831C112.323 151.525 110.085 152.117 109.004 151.371C107.924 150.625 108.567 146.921 108.567 146.921C108.567 146.921 112.194 146.097 112.606 145.532C113.017 144.966 116.31 143.088 116.31 143.088L119.011 148.464Z"
        fill={colors.permaCopper}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M108.954 147.214C108.954 147.214 108.72 146.613 108 146.741C107.28 146.87 100.566 146.124 98.6367 145.584C96.7075 145.043 92.6175 148.876 94.3923 151.577C96.1671 154.278 98.5853 152.992 100.772 152.812C102.958 152.632 107.203 153.249 108.154 152.94C109.106 152.632 110.058 152.066 110.084 151.397C110.109 150.728 108.954 147.214 108.954 147.214Z"
        fill={colors.permaEbb}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M99.1379 145.935C99.1379 145.935 96.2962 147.332 95.7818 149.262C95.2675 151.191 95.5062 152.735 95.5062 152.735C95.5062 152.735 92.8096 151.465 94.3471 148.447C95.8847 145.429 98.301 145.181 99.1379 145.626C99.9747 146.072 99.1379 145.935 99.1379 145.935Z"
        fill={colors.permaWafer}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M99.4528 145.87C99.4528 145.87 100.618 147.718 100.772 149.056C100.772 149.056 100.669 149.313 101.956 149.287C103.242 149.262 109.238 147.946 109.238 147.946C109.238 147.946 110.528 151.036 110.062 151.833C109.595 152.631 100.772 151.114 100.772 151.114C100.772 151.114 100.412 150.908 100.181 151.088C99.9491 151.268 98.7538 152.917 97.1136 153.25C97.1136 153.25 99.6662 151.885 99.872 150.085C100.078 148.284 99.4528 145.87 99.4528 145.87Z"
        fill={colors.permaBizarre}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M107.923 147.744C107.923 147.744 107.846 149.493 108.181 150.471C108.515 151.448 108.793 152.301 108.217 152.196C107.64 152.091 107.023 148.207 107.357 147.873C107.692 147.538 107.898 147.435 107.923 147.744Z"
        fill={colors.white}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M106.972 147.95C106.972 147.95 106.895 149.699 107.229 150.677C107.564 151.654 107.842 152.507 107.266 152.402C106.689 152.297 106.072 148.413 106.406 148.079C106.741 147.744 106.947 147.641 106.972 147.95Z"
        fill={colors.white}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M103.602 148.012C103.602 148.012 103.525 149.611 103.859 150.506C104.194 151.4 104.472 152.179 103.895 152.084C103.319 151.988 102.702 148.435 103.036 148.129C103.371 147.823 103.576 147.729 103.602 148.012Z"
        fill={colors.white}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.316 148.205C102.316 148.205 102.239 149.699 102.573 150.533C102.908 151.368 103.185 152.096 102.609 152.006C102.033 151.917 101.416 148.601 101.75 148.315C102.085 148.03 102.29 147.942 102.316 148.205Z"
        fill={colors.white}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M101.392 148.098C101.392 148.098 101.103 149.566 101.316 150.439C101.528 151.313 101.7 152.073 101.142 151.902C100.584 151.732 100.444 148.362 100.816 148.127C101.187 147.892 101.404 147.834 101.392 148.098Z"
        fill={colors.white}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M105.776 148.063C105.776 148.063 105.878 149.81 106.31 150.749C106.743 151.687 107.107 152.506 106.523 152.461C105.939 152.416 104.927 148.615 105.226 148.248C105.524 147.881 105.719 147.758 105.776 148.063Z"
        fill={colors.white}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M105.272 148.03C105.272 148.03 104.924 149.746 105.103 150.763C105.282 151.781 105.424 152.666 104.871 152.473C104.318 152.28 104.311 148.347 104.693 148.069C105.075 147.79 105.294 147.721 105.272 148.03Z"
        fill={colors.white}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M109.128 147.828C109.128 147.828 107.511 148.181 107.846 150.136C108.18 152.091 110.211 151.54 110.211 151.54C110.211 151.54 110.693 148.324 109.128 147.828Z"
        fill={colors.white}
      />
      <Path
        d="M131.883 130.731L117.684 141.931L114.61 143.226L111.356 141.199L100.179 134.128L100.072 134.297L111.249 141.368L114.593 143.45L117.787 142.105L132.007 130.888L131.883 130.731Z"
        fill={colors.trueBlack}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M111.011 140.13C111.011 140.13 113.326 140.773 113.969 141.879C114.612 142.985 114.406 144.889 112.503 145.686C110.599 146.483 109.828 147.152 110.316 145.892C110.805 144.631 111.705 142.316 111.011 140.13Z"
        fill={colors.permaPersianGreenApprox}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M117.749 141.596C117.749 141.596 117.981 144.168 119.395 146.175C120.81 148.181 122.071 148.387 121.608 148.876C121.145 149.364 120.424 149.596 119.961 150.213C119.498 150.831 119.524 150.805 118.444 149.519C117.363 148.232 114.997 145.377 114.945 142.831C114.945 142.831 117.543 140.747 117.749 141.596Z"
        fill={colors.permaPersianGreenApprox}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M114.862 96.1357C114.862 96.1357 115.669 97.3842 116.765 96.6621L114.862 96.1357Z"
        fill={colors.trueBlack}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M119.237 92.9266C119.237 93.092 119.103 93.2262 118.937 93.2262C118.772 93.2262 118.638 93.092 118.638 92.9266C118.638 92.7611 118.772 92.627 118.937 92.627C119.103 92.627 119.237 92.7611 119.237 92.9266Z"
        fill={colors.trueBlack}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M112.858 91.1871C112.858 91.1871 113.62 90.7272 114.202 90.6226C114.56 90.558 116.542 90.8376 116.478 91.2416C116.417 91.6352 116.221 91.5822 115.439 91.4478C114.657 91.3134 114.3 91.2238 113.848 91.2726C113.396 91.3215 112.871 91.4193 112.858 91.1871Z"
        fill={colors.trueBlack}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M121.002 91.9487C121.002 91.9487 120.871 91.5492 120.657 91.3572C120.442 91.1653 118.824 90.7508 118.551 91.0071C118.116 91.4144 118.429 91.5604 119.301 91.6508C120.003 91.7237 120.28 91.7479 120.605 91.8891C121.049 92.0821 120.992 92.0231 121.002 91.9487Z"
        fill={colors.trueBlack}
      />
      <Path
        d="M117.799 91.1885L117.989 91.2495L117.97 91.3091C117.955 91.3577 117.939 91.4133 117.921 91.475C117.869 91.6513 117.818 91.8407 117.77 92.0356C117.717 92.2522 117.671 92.4623 117.636 92.6607L117.625 92.7195C117.538 93.224 117.526 93.6093 117.604 93.8165L117.608 93.8282C118.052 94.925 117.45 95.4812 116.186 95.0918L116.147 95.0796L116.208 94.8894C117.347 95.2537 117.803 94.8433 117.423 93.9031C117.318 93.6443 117.335 93.2051 117.439 92.6253C117.475 92.4225 117.522 92.2084 117.576 91.9879C117.619 91.8121 117.665 91.6408 117.712 91.4792L117.729 91.4191C117.739 91.3833 117.749 91.3495 117.759 91.3179L117.783 91.2378L117.799 91.1885Z"
        fill={colors.trueBlack}
      />
      <Path
        d="M112.867 98.8295L112.965 98.6553L113.001 98.6747L113.033 98.6921C113.083 98.7182 113.139 98.747 113.202 98.7778L113.229 98.7911C113.44 98.8938 113.673 98.9965 113.922 99.0924C114.493 99.3128 115.058 99.4633 115.584 99.5121C115.822 99.5342 116.048 99.5347 116.259 99.5121L116.301 99.5072L116.326 99.7055C116.087 99.7348 115.833 99.7358 115.565 99.711C115.019 99.6602 114.437 99.5053 113.85 99.2787C113.596 99.1807 113.357 99.0757 113.142 98.9707L113.06 98.9304C113.025 98.913 112.993 98.8964 112.963 98.8805L112.906 98.8507L112.867 98.8295Z"
        fill={colors.trueBlack}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M115.538 92.5171C115.502 92.5171 115.465 92.5041 115.437 92.4776C115.435 92.4759 115.188 92.2566 114.831 92.2699C114.462 92.2835 114.217 92.464 114.215 92.4658C114.149 92.5153 114.055 92.5023 114.005 92.4366C113.955 92.3708 113.968 92.2775 114.033 92.2274C114.046 92.2176 114.352 91.9878 114.82 91.9705C115.302 91.9531 115.626 92.2445 115.639 92.2569C115.7 92.3128 115.704 92.4076 115.648 92.4685C115.619 92.5008 115.578 92.5171 115.538 92.5171Z"
        fill={colors.trueBlack}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M100.812 74.5477C103.858 76.9447 106.804 81.7886 111.998 81.0396C117.191 80.2905 123.994 83.3579 120.917 91.8367C120.917 91.8367 121.036 87.2318 118.44 85.6338C115.843 84.0358 114.994 92.4753 110.05 90.328C110.05 90.328 109.251 89.8785 109.051 90.5777C108.852 91.2768 108.988 93.1395 109.519 93.5065C109.519 93.5065 109.651 93.6239 109.9 93.524C109.9 93.524 110.05 95.895 111.448 97.3561C111.448 97.3561 111.099 98.5179 110.974 98.6926L110.884 98.7131C110.884 98.7131 106.105 102.413 100.912 98.2181C95.718 94.0234 92.2723 97.2194 88.0776 93.3242C83.8828 89.4291 93.9579 77.6805 91.3734 74.8973C94.4696 71.5515 99.3198 73.3738 100.812 74.5477Z"
        fill={colors.permaOuterSpaceNew}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M142.313 90.6931C141.379 94.1785 137.796 96.247 134.311 95.3131C130.825 94.3792 128.757 90.7965 129.691 87.311C130.625 83.8256 134.207 81.7571 137.693 82.691C141.178 83.625 143.247 87.2076 142.313 90.6931Z"
        fill={colors.permaBlueStoneApprox}
      />
      <Path
        d="M139.353 87.3999C139.582 87.258 139.883 87.3286 140.024 87.5575C140.164 87.783 140.098 88.0782 139.877 88.2225L139.867 88.2288L134.639 91.4687L132.483 88.6893C132.318 88.4765 132.357 88.1703 132.569 88.0052C132.779 87.8424 133.08 87.8777 133.246 88.0827L133.254 88.0916L134.872 90.1773L139.353 87.3999Z"
        fill={colors.white}
      />
    </Svg>
  );
};

export default MessagingConfirmSession;
