import { forwardRef } from 'react';
import * as React from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import styled, { EmotionStyle } from '../../core/styled';
import { useKeyboardFocusEffect } from '../../hooks/a11yHelper';

export interface TextAreaProps extends TextareaAutosize.RequiredProps {
  onChangeText(value: string): void;
  onSubmitEditing?(e: React.KeyboardEvent<HTMLTextAreaElement>): void;
  numberOfLines?: number;
  multiline?: boolean;
  style?: EmotionStyle;
  dataQa?: string;
}

const TextAreaComponent = styled(TextareaAutosize)({
  fontFamily: "'Roboto', sans-serif",
});

const TextArea = forwardRef<
  HTMLTextAreaElement,
  React.ComponentPropsWithoutRef<'textarea'> & TextAreaProps
>(
  (
    { onChangeText, numberOfLines, onSubmitEditing, multiline, style, dataQa, ...otherProps },
    ref
  ) => {
    const onChange = ({ target: { value } }: React.ChangeEvent<HTMLTextAreaElement>) =>
      onChangeText(value);
    const onKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === 'Enter' && (e.metaKey || e.ctrlKey) && onSubmitEditing) {
        onSubmitEditing(e);
      }
    };

    const { handleFocus, handleBlur, handleMouseDown, focusStyle } = useKeyboardFocusEffect();

    return (
      <TextAreaComponent
        type="text"
        {...otherProps}
        async
        rows={1}
        ref={ref}
        onChange={onChange}
        onKeyDown={onKeyDown}
        maxRows={numberOfLines}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onMouseDown={handleMouseDown}
        style={{ ...focusStyle, ...style }}
        data-qa={dataQa}
      />
    );
  }
);

export default TextArea;
