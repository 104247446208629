import { CSSProperties } from 'react';
import * as React from 'react';
import styled from '@emotion/styled';

export type EmotionStyle = { [key: string]: string | number | {} } | CSSProperties;
export type CustomizableComponentStyleProp<T = unknown> =
  | ((provided: EmotionStyle, props: React.Props<T>) => EmotionStyle)
  | EmotionStyle;

type StyleProps<T> = { styles?: CustomizableComponentStyleProp<T> };

export const getStyles = <T extends any>(
  defaultStyles: EmotionStyle,
  props: React.Props<T> & StyleProps<T>
): EmotionStyle => {
  if (props.styles) {
    if (typeof props.styles === 'function') return props.styles(defaultStyles, props);
    if (typeof props.styles === 'object') return { ...defaultStyles, ...props.styles };
  }
  return defaultStyles as EmotionStyle;
};

export default styled;
