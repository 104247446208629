import apiHelper from './api';
import apiWrapper from './apiWrapper';

function getAdminConfigValue(key, json) {
  return apiWrapper
    .get(
      `${apiHelper().apiEndpoint}/v3/users/get-admin-config-value?key=${key}${
        json ? `&json=true` : ''
      }`
    )
    .then((response) => response.data.data);
}

export default getAdminConfigValue;
