import { useEffect, useRef, useState } from 'react';
import { Spinner, View } from '@talkspace/react-toolkit';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useParams } from 'react-router-dom';
import { StepWizardProvider, WizardScheme } from 'stepWizard';
import Routes from './Routes';
import { getSteps } from './providerLeavingScheme';
import { getInitialState } from './reducers/providerLeavingState';
import providerLeavingActions from './hooks/providerLeavingActions';
import { getUserData } from '../../utils/token';
import { getAvailability } from '../../actions/AvailabilityActions';

const ProviderLeavingWizard = ({
  history,
  initTherapistInfo,
  userId,
  therapistType,
  closeWizardAction,
}: any) => {
  const [initialState, setInitialState] = useState<any | undefined>();
  const stepsRef = useRef<WizardScheme['steps'] | null>(null);

  const { source, contextID } = useParams<{ source: string; contextID: string }>();

  useEffect(() => {
    const UserID = getUserData().id;
    initTherapistInfo(UserID);
    const data = getSteps();
    stepsRef.current = data.steps;
  }, [initTherapistInfo]);

  useEffect(() => {
    if (therapistType && userId) {
      const isPsychiatrist = therapistType === 'psychiatrist';
      setInitialState(getInitialState(source, contextID, userId, isPsychiatrist, !isPsychiatrist));
    }
  }, [therapistType, userId, source, contextID]);

  return (
    <>
      {initialState && stepsRef.current ? (
        <StepWizardProvider
          initialState={initialState}
          genericActions={{ ...providerLeavingActions, closeWizardAction }}
        >
          <Routes steps={stepsRef.current} history={history} />
        </StepWizardProvider>
      ) : (
        <View flex={1}>
          <Spinner isLoading />
        </View>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state.availability.id,
    therapistType: state.availability.therapistType,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ initTherapistInfo: getAvailability }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProviderLeavingWizard);
