import SocketService from '@/utils/socket/SocketService';

export interface ClientDischargePayload {
  roomID: number;
}

export default class ClientDischargeSocketService {
  roomID: number;

  handleClientDischarged: Function;

  io: SocketService;

  constructor(roomID: number, handleClientDischarged: Function) {
    this.roomID = roomID;
    this.handleClientDischarged = handleClientDischarged;
    this.io = SocketService.instance();
    this.socketInit();
  }

  private socketInit = () => {
    this.io.on('clientDischarged', this.clientDischargedHandler);
  };

  public unmount = () => {
    this.io.off('clientDischarged', this.clientDischargedHandler);
  };

  private clientDischargedHandler = (data: ClientDischargePayload) => {
    if (Number(data.roomID) === this.roomID) {
      this.handleClientDischarged(data.roomID);
    }
  };
}
