import { VoidFunctionComponent } from 'react';
import Svg, { Path, Circle } from 'svgs';
import { useEmotionTheme } from '../../core/styled';

interface GrowCaseloadProps {
  color?: string;
  width?: number;
  height?: number;
}

const GrowCaseload: VoidFunctionComponent<GrowCaseloadProps> = ({
  width = 24,
  height = 24,
  color,
}) => {
  const { colors } = useEmotionTheme();
  const iconColor = color || colors.permaBlueStoneNew;
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        d="M11.0197 0C4.91972 0 0 4.91972 0 11.0197C0 14.0107 1.22004 16.7657 3.14848 18.7335C3.62075 19.2057 4.13238 19.6386 4.72272 20.0322C6.49374 21.2916 8.65832 22 11.0197 22C13.381 22 15.5454 21.2522 17.3166 20.0322C17.8676 19.6386 18.3792 19.2057 18.8909 18.7335C20.8587 16.7263 22.0394 14.0107 22.0394 11.0197C22.0394 4.91972 17.1196 0 11.0197 0ZM11.0197 20.4651C8.65832 20.4651 6.53309 19.5993 4.88014 18.1825C5.31306 16.7657 6.61181 15.7424 8.14669 15.7424H13.9318C15.4667 15.7424 16.7654 16.7657 17.1983 18.1825C15.506 19.5993 13.3808 20.4651 11.0192 20.4651H11.0197ZM18.379 16.9231C17.5525 15.2701 15.8602 14.1682 13.9318 14.1682H8.14669C6.17889 14.1682 4.48658 15.3095 3.69946 16.9231C2.36136 15.3095 1.57424 13.263 1.57424 11.0197C1.57424 5.82469 5.82469 1.57424 11.0197 1.57424C16.2147 1.57424 20.4651 5.82469 20.4651 11.0197C20.4651 13.263 19.678 15.3095 18.3792 16.9231H18.379Z"
        fill={iconColor}
      />
      <Circle cx="11" cy="9" r="4" fill={iconColor} />
      <Path
        d="M18 19C16 20.5 14.866 21 11 21C7.13401 21 5 20 4 19C4 16.7909 7.13401 15 11 15C14.866 15 18 16.7909 18 19Z"
        fill={iconColor}
      />
    </Svg>
  );
};

export default GrowCaseload;
