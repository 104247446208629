import Svg, { G, Ellipse, Text, TSpan } from 'svgs';
import { useEmotionTheme, EmotionStyle } from '../../core/styled';

const CircledQuestionMark = ({
  width = 22,
  height = 22,
  showTitle = false,
  color,
  ...props
}: {
  width?: number;
  height?: number;
  color?: string;
  showTitle?: boolean;
  style?: EmotionStyle;
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'question mark';
  return (
    <Svg
      width={width}
      height={height}
      title={showTitle ? titleText : undefined}
      aria-label={showTitle ? titleText : undefined}
      viewBox="0 0 15 15"
      {...props}
    >
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Ellipse
          fill={color || colors.periwinkleGrey}
          cx="7.46251"
          cy="7.26000687"
          rx="7.13942323"
          ry="7.15000015"
        />
        <Text
          fontFamily="Roboto-Black, Roboto"
          fontSize="9.8px"
          fontWeight="700"
          fill={colors.white}
        >
          <TSpan x="5" y="10.7000122">
            ?
          </TSpan>
        </Text>
      </G>
    </Svg>
  );
};

export default CircledQuestionMark;
