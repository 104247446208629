import Svg, { Path } from 'svgs';
import { useEmotionTheme, EmotionStyle } from '../../core/styled';

const ArrowDown = ({
  width = 10,
  height = 16,
  fill,
  ...otherProps
}: {
  width?: number;
  height?: number;
  fill?: string;
  style?: EmotionStyle;
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'down arrow';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox={`0 0 ${width} ${height}`}
      {...otherProps}
    >
      <Path
        d="M317.247 22.24a.796.796 0 00-.002 1.151L322 28l4.754-4.609a.794.794 0 00-.001-1.15.854.854 0 00-1.19.001L322 25.696l-3.563-3.454a.858.858 0 00-1.19-.002z"
        transform="translate(-332 -1444) translate(15 1422) matrix(-1 0 0 1 644 0)"
        fill={fill || colors.green}
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </Svg>
  );
};

export default ArrowDown;
