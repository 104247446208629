import { Moment } from 'moment';
import { NoteItem } from 'hooks/notes/useQueryNotes';

import { Modality } from './utils';
import { ProgressNoteVideoCallCreationAttributes } from './types';
import trackEvent from '../../../../modules/analytics/trackEvent';

interface FormStateProps {
  modalityID?: Modality | null;
  serviceStartDate?: Date | Moment | null;
  serviceEndDate?: Date | Moment | null;
  videoCalls?: Array<ProgressNoteVideoCallCreationAttributes>;
}

interface VideoCallSelectOption {
  value: number;
  label: string;
}

interface ModalityComponentState {
  modalityComponentStateModalityID?: Modality | null;
  modalityComponentStateStartDate?: Date | Moment | null;
  modalityComponentStateEndDate?: Date | Moment | null;
  modalityComponentStateVideoCalls?: Array<VideoCallSelectOption> | null;
}

interface ProgressNoteDebugProps extends ModalityComponentState, FormStateProps {
  debugEventType?: string;
}

interface ValidationDataProps {
  validationNotes?: Array<NoteItem>;
  validationErrorMessage?: string;
}

interface NoteFieldUpdateEventProps {
  updateType?: string;
}

interface ProgressNoteEventProps {
  roomID?: string;
  noteID?: string;
}

interface TrackProgressNoteDebugEventParams
  extends ProgressNoteDebugProps,
    ValidationDataProps,
    ProgressNoteEventProps {}

interface TrackNoteFieldUpdatedEventParams
  extends NoteFieldUpdateEventProps,
    ProgressNoteEventProps {}

interface TrackProgressNoteApiCalledEventParams extends ProgressNoteEventProps, FormStateProps {
  calledEndPointDescription?: string;
}

const Analytics = {
  trackProgressNoteApiCalledEvent: ({
    roomID,
    noteID,
    serviceStartDate,
    serviceEndDate,
    modalityID,
    videoCalls,
    calledEndPointDescription,
  }: TrackProgressNoteApiCalledEventParams) => {
    trackEvent(
      'apiEndPointCalled',
      { actionName: 'progressNotesInteraction' },
      {
        calledEndPointDescription,
        roomID,
        noteID,
        serviceStartDate,
        serviceEndDate,
        modalityID,
        videoCalls: videoCalls?.map((it) => it.videoCallID).toString(),
      }
    );
  },
  trackProgressNoteDebugEvent: ({
    roomID,
    noteID,
    modalityID,
    serviceStartDate,
    serviceEndDate,
    videoCalls,
    debugEventType,
    validationNotes,
    modalityComponentStateModalityID,
    modalityComponentStateStartDate,
    modalityComponentStateEndDate,
    modalityComponentStateVideoCalls,
    validationErrorMessage,
  }: TrackProgressNoteDebugEventParams) => {
    trackEvent(
      'progressNoteDebugEvent',
      { actionName: 'progressNotesInteraction' },
      {
        debugEventType,
        roomID,
        noteID,
        modalityID,
        serviceStartDate,
        serviceEndDate,
        videoCalls: videoCalls?.map((it) => it.videoCallID).toString(),
        modalityComponentStateModalityID,
        modalityComponentStateStartDate,
        modalityComponentStateEndDate,
        modalityComponentStateVideoCalls: modalityComponentStateVideoCalls
          ?.map((it) => JSON.stringify(it))
          .toString(),
        validationNotesRanges: validationNotes
          ?.filter(
            (note) =>
              note.roomID === Number(roomID) &&
              note.noteID !== Number(noteID) &&
              note.type === 'progress'
          )
          .map((it: NoteItem) =>
            JSON.stringify({
              noteID: it.noteID,
              modalityID: it.modalityID,
              startDate: it.startDate,
              endDate: it.endDate,
            })
          )
          .toString(),
        validationErrorMessage,
      }
    );
  },

  trackNoteFieldUpdatedEvent: ({
    roomID,
    noteID,
    updateType,
  }: TrackNoteFieldUpdatedEventParams) => {
    trackEvent(
      'noteFieldUpdated',
      { actionName: 'progressNotesInteraction' },
      {
        updateType,
        roomID,
        noteID,
      }
    );
  },
};

export default Analytics;
