/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { TherapistTimeslot } from 'ts-frontend/types';
import { TimeslotByDay } from '../types';

interface UseTherapistOpenTimesProps {
  prevCalendarDate: moment.Moment | null;
  setPrevCalendarDate: (prevCalendarDate: moment.Moment) => void;
  calendarDate: moment.Moment | null;
  setCalendarDate: (calendarDate: moment.Moment) => void;
  setCalendarKey: (calendarKey: any) => void;
  generateRandomString: () => string;
  setSelectedTimeslot: (therapistTimeslot: TherapistTimeslot) => void;
  timeslotsByDay?: TimeslotByDay[];
}

const useTherapistOpenTimes = ({
  prevCalendarDate,
  setPrevCalendarDate,
  calendarDate,
  setCalendarDate,
  setCalendarKey,
  generateRandomString,
  setSelectedTimeslot,
  timeslotsByDay,
}: UseTherapistOpenTimesProps) => {
  const [hasTherapistAvailableSlot, setHasTherapistAvailableSlot] = useState(false);

  useEffect(() => {
    if (calendarDate && prevCalendarDate && !prevCalendarDate.isSame(calendarDate, 'month')) {
      setCalendarKey(generateRandomString);
      setPrevCalendarDate(calendarDate);
    }
  }, [calendarDate, generateRandomString, prevCalendarDate, setCalendarKey, setPrevCalendarDate]);

  const getFirstTherapistAvailability = useCallback((): TherapistTimeslot | null => {
    let firstTherapistAvailability: TherapistTimeslot | null = null;
    timeslotsByDay?.find((tsbd) => {
      const timeslot = tsbd.timeslots.find((ts) => {
        if (ts.available) {
          firstTherapistAvailability = ts;
        }
        return !!firstTherapistAvailability;
      });
      return !!timeslot;
    });
    return firstTherapistAvailability || null;
  }, [timeslotsByDay]);

  const getFirstTimeslot = useCallback(
    (): TherapistTimeslot | null => timeslotsByDay?.[0]?.timeslots[0] || null,
    [timeslotsByDay]
  );

  const setFirstAvailability = useCallback(
    (ignoreTherapistAvailability: boolean = false) => {
      let timeslot: TherapistTimeslot | null = null;
      timeslot = ignoreTherapistAvailability ? getFirstTimeslot() : getFirstTherapistAvailability();
      if (timeslot) {
        if (calendarDate) {
          setPrevCalendarDate(moment(calendarDate).startOf('day'));
        }
        setCalendarDate(moment(timeslot.start).startOf('day'));
        setSelectedTimeslot(timeslot);
      }
    },
    [
      calendarDate,
      getFirstTherapistAvailability,
      getFirstTimeslot,
      setCalendarDate,
      setPrevCalendarDate,
      setSelectedTimeslot,
    ]
  );

  useEffect(() => {
    const timeslot = getFirstTherapistAvailability();
    setHasTherapistAvailableSlot(!!timeslot);
  }, [getFirstTherapistAvailability]);

  return { setFirstAvailability, hasTherapistAvailableSlot };
};

export default useTherapistOpenTimes;
