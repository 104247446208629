import * as React from 'react';
import View from '../View';
import TouchableView from '../TouchableView';
import Tiny from '../Typography/Tiny';
import CheckMark from '../icons/CheckMark';
import styled, { useEmotionTheme, EmotionStyle } from '../../core/styled';
import { useWindowWidthState } from '../../hooks/windowWidthContext';

type BreadcrumbItem = {
  label: string;
  onPress?: () => void;
  style?: EmotionStyle;
};

type BreadcrumbProps = {
  activeIndex: number;
  items: BreadcrumbItem[];
  style?: EmotionStyle;
};

const Breadcrumb = styled(View)<{
  isMobile: boolean;
  active?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
  style?: EmotionStyle;
}>(({ active, isFirst, isLast, theme: { colors }, isMobile, style = {} }) => {
  return {
    border: '0px solid transparent',
    borderTopLeftRadius: isFirst ? 10 : 0,
    borderBottomLeftRadius: isFirst ? 10 : 0,
    borderTopRightRadius: isLast ? 10 : 0,
    borderBottomRightRadius: isLast ? 10 : 0,
    background: active ? colors.cobaltGrey : colors.permaWhiteSmoke,
    height: 26,
    minWidth: isMobile ? 70 : 160,
    flex: 1,
    ...style,
  };
});

const TriangleRightAfter = styled(View)<{ active?: boolean }>(({ active, theme: { colors } }) => {
  return {
    display: 'block',
    width: 7,
    height: 26,
    borderTop: '13px solid transparent',
    borderBottom: '13px solid transparent',
    borderLeft: `7px solid ${active ? colors.cobaltGrey : colors.permaWhiteSmoke}`,
    backgroundColor: 'transparent',
  };
});

const TriangleRightBehind = styled(View)<{ active?: boolean }>(({ active, theme: { colors } }) => {
  return {
    display: 'block',
    width: 7,
    height: 26,
    borderTop: '13px solid transparent',
    borderBottom: '13px solid transparent',
    borderLeft: '7px solid #FFF',
    backgroundColor: active ? colors.cobaltGrey : colors.permaWhiteSmoke,
    position: 'absolute',
    left: 0,
  };
});

const BreadcrumbsWrapper = styled(View)(({ isMobile }: { isMobile: boolean }) => {
  return {
    width: isMobile ? 354 : 'auto',
    alignSelf: 'center',
  };
});

const Label = styled(Tiny)({
  whiteSpace: 'nowrap',
});

const CheckIcon = (props) => {
  const { colors } = useEmotionTheme();
  const { isMobile, ...otherProps } = props;
  return (
    <CheckMark style={{ marginRight: isMobile ? 3 : 5 }} {...otherProps} color={colors.apple} />
  );
};

const Breadcrumbs: React.FunctionComponent<BreadcrumbProps> = ({
  activeIndex,
  items,
  style = {},
}) => {
  const { isMobile } = useWindowWidthState();
  return (
    <BreadcrumbsWrapper row style={style} isMobile={isMobile} data-qa="breadcrumbs">
      {items.map((item, i, arr) => (
        <TouchableView
          tabIndex={-1}
          row
          style={{
            position: 'relative',
            cursor: item.onPress ? 'pointer' : 'unset',
            flexGrow: 1,
            flexBasis: 'auto',
          }}
          onPress={item.onPress}
          key={item.label}
        >
          {i !== 0 && <TriangleRightBehind active={activeIndex === i} />}
          <Breadcrumb
            data-qa="breadcrumb"
            align="center"
            justify="center"
            isFirst={i === 0}
            isLast={i === arr.length - 1}
            active={activeIndex === i}
            row
            isMobile={isMobile}
            style={item.style}
          >
            {activeIndex > i && <CheckIcon isMobile={isMobile} />}
            {activeIndex === i ? (
              <Label variant="tinyBoldWhite">{item.label}</Label>
            ) : (
              <Label>{item.label}</Label>
            )}
          </Breadcrumb>
          {i !== arr.length - 1 && <TriangleRightAfter active={activeIndex === i} />}
        </TouchableView>
      ))}
    </BreadcrumbsWrapper>
  );
};

export default Breadcrumbs;
