/* eslint-disable camelcase */

import { RoomStatus } from 'ts-frontend/entities/Room';
import { LiveSessionModality } from 'ts-frontend/types';

export type RoomType =
  | 'couples_room'
  | 'group_room'
  | 'peer_support_group_room'
  | 'private_room'
  | 'psychiatry_room';

export type SessionModalities = Record<LiveSessionModality, boolean>;

export interface ApiRoomInfo {
  therapistDisplayName: string;
  expirationDate: string;
  paymentType: number;
  roomType: RoomType;
  roomStatus: number;
  isRoomDischarged: boolean;
  shouldActivateDueToDischarge: boolean;
  isChatDisabled: boolean;
  customerName: string;
  therapistID: number;
  planName: string;
  allowedSessionModalities: SessionModalities;
  clientUserID: number;
}

export class RoomInfo {
  public therapistDisplayName: string;

  public userPlanType: string;

  public userName: string;

  public roomType?: RoomType;

  public therapistUserID: number;

  public expirationDate: Date | null;

  public roomStatus?: RoomStatus;

  public isRoomDischarged: boolean;

  public isChatDisabled: boolean;

  public shouldActivateDueToDischarge: boolean;

  public allowedSessionModalities: SessionModalities;

  public primaryClientID?: number;

  constructor(apiRoomInfo: ApiRoomInfo) {
    this.therapistDisplayName = apiRoomInfo.therapistDisplayName;
    this.userPlanType = apiRoomInfo.planName;
    this.userName = apiRoomInfo.customerName;
    this.roomType = apiRoomInfo.roomType;
    this.therapistUserID = apiRoomInfo.therapistID;
    this.roomStatus = apiRoomInfo.roomStatus;
    this.isRoomDischarged = apiRoomInfo.isRoomDischarged;
    this.isChatDisabled = apiRoomInfo.isChatDisabled;
    this.shouldActivateDueToDischarge = apiRoomInfo.shouldActivateDueToDischarge;
    this.expirationDate = apiRoomInfo.expirationDate ? new Date(apiRoomInfo.expirationDate) : null;
    this.allowedSessionModalities = apiRoomInfo.allowedSessionModalities;
    this.primaryClientID = apiRoomInfo.clientUserID;
  }
}
