import styled from '../../core/styled';
import { RatingStar } from '../RatingStars';
import RatingStarHalfIcon from './RatingStarHalfIcon';
import { Huge } from '../Typography';
import { useUniqueID } from '../../hooks/a11yHelper';

import '../../designSystems/components/RatingStars/ratingstars.css';
// most of this code is taken from accessible-react-rating: https://www.npmjs.com/package/accessible-react-rating
// adjusted the logic and converted from a class component to a functonal component

const DefaultEmptySymbol = ({ color }: { color?: string }) => (
  <RatingStar width={24} height={23} fill="#FFF" stroke={color} />
);
const DefaultFullSymbol = ({ color }: { color?: string }) => (
  <RatingStar width={24} height={23} fill={color} stroke={color} />
);
const DefaultHalfSymbol = ({ color }: { color?: string }) => (
  <RatingStarHalfIcon fill={color} stroke={color} />
);
const StarValueText = styled(Huge)(({ color }: { color?: string }) => {
  return {
    color,
    fontSize: 30,
    marginLeft: 14,
  };
});

export interface StarFieldProps {
  emptySymbol: JSX.Element;
  fullSymbol: JSX.Element;
  halfSymbol: JSX.Element;
  index: number;
  initialRating?: number;
  dataQa?: string;
}

const StarField = ({
  emptySymbol,
  fullSymbol,
  halfSymbol,
  index,
  initialRating,
}: StarFieldProps) => {
  const uniqLabelID = useUniqueID('uniqLabelID');
  let symbol = emptySymbol;
  const rating = initialRating || 0;
  if (index <= rating) {
    symbol = fullSymbol;
  } else if (rating && index - rating <= 0.5) {
    symbol = halfSymbol;
  }
  return (
    <span id={uniqLabelID} style={{ marginRight: 8 }}>
      {symbol}
    </span>
  );
};

export interface RatingsProps {
  legend?: string;
  showLegend?: boolean;
  numberOfStars?: number;
  getRatingValue?: (value: number) => void;
  initialRating?: number;
  dataQa?: string;
  readOnly?: boolean;
  color?: string;
  displayRating?: boolean;
}
const RatingStarsField = ({
  numberOfStars = 5,
  initialRating,
  dataQa,
  color,
  displayRating,
}: RatingsProps) => {
  const roundedRating = initialRating ? Math.round(initialRating * 10) / 10 : 0;
  const uniqStarKey = useUniqueID('starFieldLabelID');
  return (
    <div>
      {[...new Array(numberOfStars)].map((item, index) => {
        const starKey = `${uniqStarKey}-${index}`;
        return (
          <StarField
            emptySymbol={<DefaultEmptySymbol color={color} />}
            fullSymbol={<DefaultFullSymbol color={color} />}
            halfSymbol={<DefaultHalfSymbol color={color} />}
            index={index + 1}
            key={starKey}
            initialRating={initialRating}
            dataQa={dataQa}
          />
        );
      })}
      {displayRating ? (
        <StarValueText inline color={color}>
          {roundedRating || '-'}
        </StarValueText>
      ) : null}
    </div>
  );
};

export default RatingStarsField;
