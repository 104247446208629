import { Styles } from 'react-select';
import { OptionType, EmotionTheme } from '@talkspace/react-toolkit';

export const getSelectStyleV2 = ({
  colors,
  isError,
  small,
  shouldFloatLeft,
}: {
  colors: EmotionTheme['colors'];
  isError?: boolean;
  small?: boolean;
  shouldFloatLeft?: boolean;
}): Styles<OptionType, boolean> => {
  return {
    control: (provided, state) => {
      let borderColor = colors.periwinkleGrey;
      if (state.isFocused) {
        borderColor = colors.darkBlue;
      }
      if (isError) {
        borderColor = colors.permaFuchsia;
      }
      return {
        ...provided,
        minHeight: 30,
        height: 30,
        alignContent: 'center',
        paddingLeft: 0,
        borderRadius: 8,
        borderColor: `${borderColor} !important`,
        '&:hover': {
          background: colors.permaGreyed,
        },
      };
    },
    singleValue: (provided) => {
      return {
        ...provided,
        fontSize: 14,
        fontWeight: 400,
        paddingLeft: 9,
        caretColor: 'transparent !important',
      };
    },

    menu: (provided) => {
      return {
        ...provided,
        ...(shouldFloatLeft ? { left: 0 } : { right: 0 }),
        padding: 0,
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        boxShadow: '0px 7px 16px rgba(0, 29, 47, 0.3875)',
        minWidth: 150,
      };
    },
    menuList: (provided) => {
      return {
        ...provided,
        width: '100%',
        padding: '0 !important',
        maxHeight: 240,
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      };
    },
    valueContainer: (provided) => {
      return {
        ...provided,
        fontSize: 13,
        caretColor: 'transparent !important',
      };
    },
    option: (provided, { isSelected }) => {
      return {
        ...provided,
        padding: isSelected ? '9px !important' : '13px !important',
        '&:hover': {
          backgroundColor: colors.aqua,
          color: colors.white,
        },
        backgroundColor: isSelected ? colors.whiteLilac : colors.white,
        width: isSelected ? 'calc(100% - 10px)' : '100%',
        margin: isSelected && '6px auto 6px auto',
        borderRadius: isSelected && 5,
        color: colors.TSBlack,
        minHeight: 40,
        height: 40,
        boxShadow: 'none !important',
        fontSize: small ? 13 : 14,
      };
    },
    placeholder: (provided) => {
      return {
        ...provided,
        padding: '2px 10px',
        fontSize: 14,
        color: colors.grey950,
      };
    },
    input: () => {
      return {
        caretColor: 'transparent !important',
      };
    },
  };
};

export const getSelectStyleHourRange = (
  colors: EmotionTheme['colors'],
  isCurrent?: boolean
): Styles<OptionType, boolean> => {
  return {
    control: (provided, state) => {
      return {
        ...provided,
        minHeight: 18,
        height: 18,
        alignContent: 'center',
        paddingLeft: 0,
        '&:hover': {
          background: 'transparent',
        },
        border: 0,
        // This line disable the blue border
        boxShadow: 'none !important',
      };
    },
    singleValue: (provided, state) => {
      return {
        ...provided,
        fontSize: 14,
        fontWeight: 400,
        paddingLeft: 9,
        background: 'transparent',
        '&:hover': {
          background: 'transparent',
        },
        ...(isCurrent && {
          textDecoration: 'underline',
          textDecorationThickness: 2,
          textDecorationColor: colors.accessibilityGreenDark,
        }),
      };
    },
    dropdownIndicator: () => {
      return {
        display: 'none',
      };
    },
  };
};

export const getFloatingMenuStyle = (isMobile: boolean) => {
  return {
    width: isMobile ? 355 : 455,
    top: '50%',
    left: '50%',
    height: 'fit-content',
    maxHeight: 'unset',
    zIndex: 21,
    boxShadow: '0px 7px 16px rgba(0, 29, 47, 0.3875)',
    overflow: 'unset',
  };
};

export const getSelectWrapperStyle = () => {
  return {
    marginTop: 0,
    minWidth: 70,
    width: 'fit-content',
    background: 'transparent',
    flex: 1,
  };
};

export const getDatePickerInputStyle = (isMobile: boolean, colors: EmotionTheme['colors']) => {
  return {
    height: 30,
    padding: 0,
    paddingLeft: 10,
    borderRadius: 8,
    marginRight: 8,
    width: isMobile ? 94 : 194,
    cursor: 'pointer',
    ':focus': {
      caretColor: 'transparen',
    },
    ':hover': {
      background: colors.permaGreyed,
    },
  };
};

export const getCalendarContainerStyle = (isMobile: boolean) => {
  return {
    zIndex: 99,
    borderRadius: 8,
    boxShadow: '0px 4px 10px rgba(0, 40, 65, 0.4)',
    left: isMobile ? -190 : -220,
    top: 20,
  };
};
