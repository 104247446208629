import { FunctionComponent } from 'react';
import Svg, { Path, Circle } from 'svgs';
import { EmotionStyle, useEmotionTheme } from '../../core/styled';

export interface ChatNowProps {
  width?: number;
  height?: number;
  style?: EmotionStyle;
}

const ChatNow: FunctionComponent<{
  color?: string;
  width?: string | number;
  height?: string | number;
  style?: EmotionStyle;
  dataQa?: string;
}> = ({ width = 26, height = 20, color, style, dataQa, ...otherProps }) => {
  const titleText = 'Chat now icon';
  const { colors } = useEmotionTheme();
  return (
    <Svg
      title={titleText}
      aria-label={titleText}
      width={width}
      height={height}
      viewBox="0 0 26 20"
      data-qa={dataQa}
      style={style}
      {...otherProps}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.882 2.3A9.633 9.633 0 0010.036 1C5.346 1 1.658 4.21.789 8.224a9.08 9.08 0 00.006 3.974l.087.352c.452 1.668.432 3.544-.058 5.424l-.064.232-.13.462-.056.222-.044.212c-.089.514-.009.898.605.898l.404-.108.534-.133.694-.159.392-.083.628-.12.436-.075.222-.034.445-.062.443-.05a10.893 10.893 0 012.673.05l.814.121c.345.046.72.08 1.216.08 4.852 0 8.772-3.574 9.373-7.916.032-.232.056-.462.07-.69A6.503 6.503 0 0114.883 2.3zm-.14 7.013a1.188 1.188 0 11.002 2.376 1.188 1.188 0 01-.002-2.377zm-4.757 0a1.188 1.188 0 11.002 2.376 1.188 1.188 0 01-.002-2.377zm-4.756 0a1.188 1.188 0 11.001 2.376 1.188 1.188 0 01-.001-2.377z"
        fill={colors.white}
      />
      <Circle cx={21} cy={4.5} r={4.5} fill={color || colors.permaHighlightGreen} />
    </Svg>
  );
};

export default ChatNow;
