import { VoidFunctionComponent } from 'react';
import { Tray, View, Huge, Large, useUniqueID, SparklingEnvelope } from '@talkspace/react-toolkit';

interface Props {
  closeModal: () => void;
  title: string;
  subtitle: string;
}

const MessagingSessionDisabledModal: VoidFunctionComponent<Props> = ({
  closeModal,
  title,
  subtitle,
}) => {
  const ID_TITLE = useUniqueID('titleId');
  const ID_SUB_TITLE = useUniqueID('subTitleId');

  return (
    <Tray handleClose={closeModal}>
      <View align="center">
        <SparklingEnvelope />
        <Huge
          as="h1"
          id={ID_TITLE}
          style={{
            textAlign: 'center',
            marginBottom: 20,
            marginTop: 30,
          }}
        >
          {title}
        </Huge>

        <Large
          id={ID_SUB_TITLE}
          variant="largeDarkGrey"
          style={{ textAlign: 'center', marginBottom: 30 }}
        >
          {subtitle}
        </Large>
      </View>
    </Tray>
  );
};

export default MessagingSessionDisabledModal;
