import moment from 'moment';
import { getAuthSessionContext } from '../modules/auth/auth';
import { getTokens } from './token';
import ReactFrameService from '../modules/auth/reactFrame/ReactFrameService';

// eslint-disable-next-line import/prefer-default-export
export const tokenIsValid = () => {
  if (ReactFrameService.instance().isInFrame()) return true;
  const { refreshToken, accessToken, refreshTTL } = getTokens();

  if (!accessToken && !(refreshToken && getAuthSessionContext() === 'auth')) {
    return false;
  }
  if (refreshTTL && moment(Number(refreshTTL)).isBefore(moment())) {
    return false;
  }
  return true;
};
