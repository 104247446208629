import { Component } from 'react';
import PropTypes from 'prop-types';
import AccordionContainerView from './AccordionContainerView';

class AccordionContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open,
    };
    this.handleOpen = this.handleOpen.bind(this);
  }

  handleOpen(event) {
    this.setState({
      open: !this.state.open,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      this.handleOpen(this.props.open);
    }
  }

  render() {
    const {
      disabled,
      title,
      titleComponent,
      childComponents,
      containerClass,
      open,
      arrowColor,
      dataQa,
      ...otherProps
    } = this.props;
    return (
      <AccordionContainerView
        disabled={disabled}
        open={this.state.open}
        title={title}
        titleComponent={titleComponent}
        handleOpen={this.handleOpen}
        childComponents={childComponents}
        containerClass={containerClass}
        arrowColor={arrowColor}
        dataQa={dataQa}
        {...otherProps}
      />
    );
  }
}

AccordionContainer.propTypes = {
  title: PropTypes.string,
  childComponents: PropTypes.array,
  dataQa: PropTypes.string,
};

export default AccordionContainer;
