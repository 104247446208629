import ToggleSwitch from '../../../components/ToggleSwitch';
import { withVersioning } from '../../utils';
import { VersionMap } from '../../types';
import SwitchV1 from './SwitchV1';

const versionMap: VersionMap<React.ComponentProps<typeof ToggleSwitch>> = {
  '1.0.0': SwitchV1,
  DefaultComponent: ToggleSwitch,
};

export default withVersioning(versionMap);
