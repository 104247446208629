import {
  View,
  Big,
  Small,
  Checkbox,
  LiveSessionCalendarIcon,
  Button,
  EmotionStyle,
} from '@talkspace/react-toolkit';
import moment from 'moment';
import { useFlags } from 'launchDarkly/FlagsProvider';

import { SessionModality, TherapistTimeslot } from 'ts-frontend/types';
import { useCallback, useEffect, useMemo } from 'react';
import { StaticProps } from './types';
import { ConfirmBookingOptions } from '../../../inRoomScheduling/types';

export interface SchedulerConfirmationProps {
  modality: SessionModality;
  hasBreakAfterSession: boolean;
  shouldShowBookingSuccess: boolean;
  selectedTimeslot?: TherapistTimeslot | null;
  handleConfirmSchedule: (params: ConfirmBookingOptions) => void;
  setHasBreakAfterSession: (hasBreakAfterSession: boolean) => void;
}

type Props = SchedulerConfirmationProps & StaticProps;

const SchedulerConfirmation = ({
  colors,
  modality,
  selectedTimeslot,
  isMobile,
  isError,
  isLoading,
  shouldShowBookingSuccess,
  hasBreakAfterSession,
  nextStep,
  handleConfirmSchedule,
  setHasBreakAfterSession,
}: Props) => {
  const { repeatingSessionsFull: isActiveRecurringSessionsFull } = useFlags();

  useEffect(() => {
    if (shouldShowBookingSuccess && !isError && !isLoading) {
      nextStep();
    }
  }, [isError, isLoading, nextStep, shouldShowBookingSuccess]);

  const selectedDateAndModalityStyle: EmotionStyle = useMemo(
    () =>
      isMobile
        ? {}
        : {
            flexDirection: 'row',
            gap: 16,
          },
    [isMobile]
  );

  const handlePress = useCallback(() => {
    const payload: ConfirmBookingOptions = {
      isReschedule: false,
      isPurchase: false, // false for therapists booking
      shouldRedeemCredit: true,
      modality,
    };
    handleConfirmSchedule(payload);
  }, [handleConfirmSchedule, modality]);

  const { start, end } = selectedTimeslot || {};

  if (isActiveRecurringSessionsFull) return null;
  return (
    <View align="center">
      <Big style={{ color: colors.white, marginBottom: 10 }}>You're about to schedule:</Big>
      <View style={{ marginBottom: 20, ...selectedDateAndModalityStyle }} align="center">
        <LiveSessionCalendarIcon modality={modality} />
        <View>
          <Big style={{ color: colors.white, marginTop: 11 }}>
            {moment(start).format('dddd, MMM D')}
          </Big>
          <Small>
            {moment(start).format('h:mmA')} - {moment(end).format('h:mmA')}
          </Small>
        </View>
      </View>
      <View
        style={{
          border: `solid rgba(75, 80,88, 20) 1px`,
          borderRadius: 10,
          paddingTop: 13,
          paddingBottom: 13,
          paddingLeft: 12,
          paddingRight: 12,
        }}
      >
        <Checkbox
          dataQa="addBreakCheckBox"
          checkboxStyle={{ marginRight: 12 }}
          containerStyle={{ marginBottom: 0 }}
          label="Add a 15 min break after session"
          labelStyle={{ color: colors.white, fontSize: 17, fontWeight: 500 }}
          isChecked={hasBreakAfterSession}
          setIsChecked={setHasBreakAfterSession}
          isLabelOnRight
        />
      </View>
      <Button
        dataQa="handleConfirmSchedule"
        isLoading={isLoading}
        disabled={isLoading || !start || !end}
        primaryColor={colors.green}
        onPress={handlePress}
        roundedFocusStyle
        style={{ width: 314 }}
      >
        <Big style={{ color: colors.white }}>Confirm and schedule</Big>
      </Button>
    </View>
  );
};

export default SchedulerConfirmation;
