import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const pathConfig: IconConfig = {
  standard: {
    default: {
      path: 'M11.7503 12.9166L12.917 11.75L9.83366 8.66663V4.83329H8.16699V9.33329L11.7503 12.9166ZM9.00033 17.3333C7.84755 17.3333 6.76421 17.1145 5.75033 16.677C4.73644 16.2395 3.85449 15.6458 3.10449 14.8958C2.35449 14.1458 1.76074 13.2638 1.32324 12.25C0.885742 11.2361 0.666992 10.1527 0.666992 8.99996C0.666992 7.84718 0.885742 6.76385 1.32324 5.74996C1.76074 4.73607 2.35449 3.85413 3.10449 3.10413C3.85449 2.35413 4.73644 1.76038 5.75033 1.32288C6.76421 0.885376 7.84755 0.666626 9.00033 0.666626C10.1531 0.666626 11.2364 0.885376 12.2503 1.32288C13.2642 1.76038 14.1462 2.35413 14.8962 3.10413C15.6462 3.85413 16.2399 4.73607 16.6774 5.74996C17.1149 6.76385 17.3337 7.84718 17.3337 8.99996C17.3337 10.1527 17.1149 11.2361 16.6774 12.25C16.2399 13.2638 15.6462 14.1458 14.8962 14.8958C14.1462 15.6458 13.2642 16.2395 12.2503 16.677C11.2364 17.1145 10.1531 17.3333 9.00033 17.3333ZM9.00033 15.6666C10.8475 15.6666 12.4205 15.0173 13.7191 13.7187C15.0177 12.4201 15.667 10.8472 15.667 8.99996C15.667 7.15274 15.0177 5.57982 13.7191 4.28121C12.4205 2.9826 10.8475 2.33329 9.00033 2.33329C7.1531 2.33329 5.58019 2.9826 4.28158 4.28121C2.98296 5.57982 2.33366 7.15274 2.33366 8.99996C2.33366 10.8472 2.98296 12.4201 4.28158 13.7187C5.58019 15.0173 7.1531 15.6666 9.00033 15.6666Z',
    },
    filled: {
      path: 'M10 2.39648C12.1217 2.39648 14.1566 3.23934 15.6569 4.73963C17.1571 6.23992 18 8.27475 18 10.3965C18 12.5182 17.1571 14.553 15.6569 16.0533C14.1566 17.5536 12.1217 18.3965 10 18.3965C7.87827 18.3965 5.84344 17.5536 4.34315 16.0533C2.84285 14.553 2 12.5182 2 10.3965C2 8.27475 2.84285 6.23992 4.34315 4.73963C5.84344 3.23934 7.87827 2.39648 10 2.39648ZM9.25 6.14648V10.3965C9.25 10.6465 9.375 10.8809 9.58437 11.0215L12.5844 13.0215C12.9281 13.2527 13.3938 13.159 13.625 12.8121C13.8562 12.4652 13.7625 12.0027 13.4156 11.7715L10.75 9.99648V6.14648C10.75 5.73086 10.4156 5.39648 10 5.39648C9.58437 5.39648 9.25 5.73086 9.25 6.14648Z',
    },
  },
};

const Clock = withDSIconMaker(pathConfig, 'clock');

export default Clock;
