import { useSwipeable } from 'react-swipeable';
import * as React from 'react';
import { useEffect, useCallback, useState } from 'react';

import { useA11y } from './Carousel.a11y';
import View from '../View';
import TabRadioGroup from '../TabRadioGroup';
import BaseButton from '../BaseButton';
import BackNextButton from '../BackNextButton';
import useWindowWidth from '../../hooks/useWindowWidth';
import styled, { EmotionStyle, useEmotionTheme } from '../../core/styled';

/* 
  This carousel component is based on the codepen at
  http://stack.formidable.com/react-swipeable/ 
  and has been modified here to be used as a non-continuous
  scroll. The transposition pieces are what allow it
  to be continuous. When this is moved to toolkit it
  should probably allow for an isContinuous prop.
  
  Note there was a bug with the codepen where 
  it would not work with 2 items. An item has 
  been inserted at the beginning of the scroll by
  default to fix this. When continuous is implemented
  this hack will have to be resolved.
*/

type Direction = 'PREV' | 'NEXT' | 'JUMP';

export type Move = 'forward' | 'backward' | 'skip' | null;

const CarouselContainer = styled(View)<{
  isTransposingInitially: boolean;
  dir: Direction;
}>(({ isTransposingInitially, dir }) => {
  let transform = 'translateX(0%)';
  if (!isTransposingInitially) transform = 'translateX(calc(-100%))';
  else if (dir === 'PREV') transform = 'translateX(calc(-2 * (100%)))';

  return {
    flexDirection: 'row',
    transition: isTransposingInitially ? 'none' : 'transform 1s ease',
    transform,
    '&:focus': {
      outline: 'none',
    },
  };
});

const CarouselIndicators = styled.ol({
  display: 'flex',
  justifyContent: 'center',
  paddingLeft: 0,
  listStyle: 'none',
  marginTop: 9,
  marginBottom: 27,
});

const CarouselIndicator = styled(BaseButton)<{ active: boolean }>(
  ({ active, theme: { colors } }) => {
    return {
      width: 8,
      height: 8,
      borderRadius: 4,
      margin: '3px 5px',
      backgroundColor: active ? colors.green : colors.extraLightGrey,
    };
  }
);

const ArrowButton = ({
  onPress,
  arrowHorizontalMargin = 30,
  isRight,
  atStart,
  atEnd,
}: {
  onPress: () => void;
  arrowHorizontalMargin?: number;
  isRight?: boolean;
  atStart?: boolean;
  atEnd?: boolean;
}) => {
  const { colors } = useEmotionTheme();
  const { isMobile } = useWindowWidth();
  let caretColor = colors.green;
  let tabIndex = 0;
  if ((isRight && atEnd) || (!isRight && atStart)) {
    caretColor = colors.extraLightGrey;
    tabIndex = -1;
  }
  return (
    <BackNextButton
      style={{
        display: isMobile ? 'none' : undefined,
        position: 'absolute',
        top: '40%',
        left: isRight ? undefined : arrowHorizontalMargin,
        right: isRight ? arrowHorizontalMargin : undefined,
        padding: 10,
        // something happens when the carousel slides so necessary to elevate the zIndex otherwise unreachable with mouse
        zIndex: 1,
      }}
      onPress={onPress}
      caretColor={caretColor}
      height={13.4}
      width={8}
      tabIndex={tabIndex}
      aria-label={isRight ? 'next' : 'previous'}
      isRight={isRight}
    />
  );
};

export const CarouselSlot = styled(View)<{ order: number }>(({ order }) => {
  return {
    // TODO: This flex results in large slots when many items
    flex: '1 0 100%',
    flexBasis: '100%',
    alignItems: 'center',
    order,
  };
});

const initialState: CarouselState = {
  pos: 0,
  isTransposingInitially: false,
  dir: 'NEXT',
};

interface CarouselState {
  pos: number;
  isTransposingInitially: boolean;
  dir: Direction;
}

interface CarouselAction {
  type: 'reset' | Direction | 'transpositionComplete';
  numItems: number;
  jumpTo?: number;
}

const reducer = (
  state: CarouselState,
  { type, numItems, jumpTo }: CarouselAction
): CarouselState => {
  switch (type) {
    case 'reset':
      return initialState;
    case 'PREV':
      return {
        ...state,
        dir: 'PREV',
        isTransposingInitially: true,
        pos: state.pos === 0 ? numItems - 1 : state.pos - 1,
      };
    case 'NEXT':
      return {
        ...state,
        dir: 'NEXT',
        isTransposingInitially: true,
        pos: state.pos === numItems - 1 ? 0 : state.pos + 1,
      };
    case 'JUMP':
      return {
        ...state,
        pos: jumpTo || 0,
      };
    case 'transpositionComplete':
      return { ...state, isTransposingInitially: false };
    default:
      return state;
  }
};

const getOrder = ({ index, pos, numItems }) =>
  index - pos < 0 ? numItems - Math.abs(index - pos) : index - pos;

const Carousel: React.FunctionComponent<{
  containerStyle?: EmotionStyle;
  carouselIndicatorsStyles?: EmotionStyle;
  arrowHorizontalMargin?: number;
  move?: Move;
  hideArrows?: boolean;
  resetMove?: () => void;
  lastStepOneTimeAction?: () => void;
  reactivateLastStepAction?: boolean;
  onNext?: (fromPos: number) => void;
  onSkip?: (fromPos: number) => void;
  onStepChange?: (step: number) => void;
  jumpTo?: number | null;
}> = ({
  children,
  move,
  resetMove,
  lastStepOneTimeAction,
  reactivateLastStepAction = false,
  hideArrows = false,
  containerStyle,
  carouselIndicatorsStyles,
  arrowHorizontalMargin,
  onNext,
  onSkip,
  onStepChange,
  jumpTo,
}) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [isLastStepActionDisabled, setIsLastStepActionDisabled] = useState(false);

  let numItems = React.Children.count(children);
  const atStart = state.pos === 0;
  const atEnd = state.pos === numItems - 1;
  numItems += 1; // the slider only works with 2+ elements so we add a blank

  const slide = useCallback(
    (dir: Direction) => {
      if ((dir === 'PREV' && atStart) || (dir === 'NEXT' && atEnd)) return;
      if (dir === 'NEXT' && onNext) {
        onNext(state.pos);
      }
      dispatch({ type: dir, numItems });
      setTimeout(() => {
        dispatch({ type: 'transpositionComplete', numItems });
      }, 50);
    },
    [atStart, atEnd, onNext, numItems, state.pos]
  );

  const jump = useCallback(
    (pos: number) => {
      dispatch({ type: 'JUMP', jumpTo: pos, numItems });
    },
    [numItems]
  );

  const runLastStepForwardMove = useCallback(() => {
    if (!isLastStepActionDisabled && lastStepOneTimeAction) {
      // this will not be fired more then once unless reactivateLastStepAction is true
      setIsLastStepActionDisabled(true);
      lastStepOneTimeAction();
    }
  }, [isLastStepActionDisabled, lastStepOneTimeAction]);

  useEffect(() => {
    if (reactivateLastStepAction) {
      setIsLastStepActionDisabled(false);
    }
  }, [reactivateLastStepAction, isLastStepActionDisabled]);

  useEffect(() => {
    if (move && resetMove) {
      if (move === 'forward') {
        if (numItems - 1 === state.pos + 1 && lastStepOneTimeAction) {
          onNext && onNext(state.pos);
          runLastStepForwardMove();
        }
        slide('NEXT');
      } else if (move === 'backward') {
        slide('PREV');
      } else {
        jump(0);
        onSkip && onSkip(state.pos);
        runLastStepForwardMove();
      }
      resetMove();
    }
  }, [
    move,
    numItems,
    resetMove,
    state,
    slide,
    lastStepOneTimeAction,
    jump,
    onSkip,
    onNext,
    runLastStepForwardMove,
  ]);

  useEffect(() => {
    if (onStepChange) {
      onStepChange(state.pos);
    }
  }, [onStepChange, state.pos]);

  useEffect(() => {
    if (jumpTo !== undefined && jumpTo !== null && resetMove) {
      jump(jumpTo);
      resetMove();
    }
  }, [jump, jumpTo, resetMove]);

  const handlers = useSwipeable({
    onSwipedLeft: () => slide('NEXT'),
    onSwipedRight: () => slide('PREV'),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });
  const { containerRef } = useA11y(state.pos);
  return (
    <View style={{ position: 'relative', ...containerStyle }} {...handlers}>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <View style={{ overflowX: 'hidden', overflowY: 'clip' }}>
        <CarouselContainer
          dir={state.dir}
          isTransposingInitially={state.isTransposingInitially}
          tabIndex={-1}
          ref={containerRef}
        >
          {/* extra slot in case to account for when only 2 elements */}
          <CarouselSlot order={getOrder({ index: 0, pos: state.pos, numItems })}>
            <View />
          </CarouselSlot>
          {React.Children.map(children, (child, index) => (
            <CarouselSlot
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              // TODO: This style below makes the size of the container dynamic
              // Helps to move the arrows and dots depending on the currently visible card
              // Possible solution if the carousel item's height vary greatly
              // Not the case for CLINICAL-5357
              // style={{ height: index === state.pos ? undefined : 0 }}
              order={getOrder({ index: index + 1, pos: state.pos, numItems })}
              aria-hidden={index !== state.pos}
            >
              {child}
            </CarouselSlot>
          ))}
        </CarouselContainer>
      </View>
      {!hideArrows && (
        <>
          <ArrowButton
            arrowHorizontalMargin={arrowHorizontalMargin}
            onPress={() => slide('PREV')}
            atStart={atStart}
          />
          <ArrowButton
            isRight
            arrowHorizontalMargin={arrowHorizontalMargin}
            onPress={() => slide('NEXT')}
            atEnd={atEnd}
          />
        </>
      )}
      <CarouselIndicators style={carouselIndicatorsStyles}>
        <TabRadioGroup
          initialSelection
          legendText="Select an evaluation to see your score"
          style={{ flexDirection: 'row' }}
        >
          {React.Children.map(children, (_, index) => {
            const isActive = index === state.pos;
            return (
              <View as="li">
                <CarouselIndicator
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  role="radio"
                  aria-checked={isActive}
                  tabIndex={isActive ? 0 : -1}
                  active={isActive}
                  onPress={() => jump(index)}
                />
              </View>
            );
          })}
        </TabRadioGroup>
      </CarouselIndicators>
    </View>
  );
};

export default Carousel;
