import apiHelper from '../../core/api/apiHelper';
import apiWrapper from '../../core/api/apiWrapper';
import { PlanInformation } from '../types';

export interface ApiResponse<T = unknown> {
  data?: T;
}

/**
 * api call to: GET `/v3/rooms/${roomID}/plan-information`
 * @returns Promise
 */
function getPlanInformation(roomID: string): Promise<ApiResponse<{ data: PlanInformation }>> {
  return apiWrapper.get(`${apiHelper().apiEndpoint}/v3/rooms/${roomID}/plan-information`);
}

const planApiHelper = {
  getPlanInformation,
};

export default planApiHelper;
