import { FunctionComponent } from 'react';
import { useEmotionTheme } from '@talkspace/react-toolkit';
import { EmotionStyle } from '@/core/styled/styled';
import ActionStatus, { TitleSize } from './ActionStatus';

interface WizardActionStatusParams {
  successTitle: JSX.Element | string;
  titleSize?: TitleSize;
  successSubTitleHeadline?: string;
  successSubTitle?: JSX.Element | string;
  successButtonText: string;
  handleAction: () => void;
  actionHookState: actionHookState & Record<string, any>;
  buttonStyles?: {};
  isBaseButton?: boolean;
  successCheckMarkExist?: boolean;
  errorPrimaryButtonAction?: () => void;
  errorPrimaryButtonText?: string;
  titleStyles?: EmotionStyle;
  subTitleStyles?: EmotionStyle;
  style?: EmotionStyle;
  showCustomErrorSubtitle?: boolean;
  errorButtonDataQa?: string;
  successButtonDataQa?: string;
}

type actionHookState = {
  error: string;
  isLoading: boolean;
  isSuccess: boolean;
};

const WizardActionStatus: FunctionComponent<WizardActionStatusParams> = ({
  successTitle,
  titleSize,
  successSubTitleHeadline,
  successSubTitle,
  successButtonText,
  handleAction,
  errorPrimaryButtonAction,
  actionHookState,
  buttonStyles,
  isBaseButton = false,
  successCheckMarkExist,
  errorPrimaryButtonText,
  titleStyles,
  subTitleStyles = {},
  style,
  showCustomErrorSubtitle = false,
  errorButtonDataQa,
  successButtonDataQa,
}) => {
  const { error, isSuccess, isLoading } = actionHookState;
  const { colors } = useEmotionTheme();
  const baseButtonStyles = {
    backgroundColor: colors.permaTalkspaceDarkGreen,
  };
  return (
    <ActionStatus
      isLoading={isLoading}
      isError={!!error}
      showSuccessState={isSuccess}
      errorTitle="Something Went Wrong"
      errorSubTitle={
        showCustomErrorSubtitle ? error : 'Please check your internet connection and try again.'
      }
      errorButtonText="Close"
      errorButtonDataQa={errorButtonDataQa}
      errorPrimaryButtonText={errorPrimaryButtonText}
      errorButtonAction={handleAction}
      errorPrimaryButtonAction={errorPrimaryButtonAction}
      successTitle={successTitle}
      titleSize={titleSize}
      successSubTitleHeadline={successSubTitleHeadline}
      successSubTitle={successSubTitle}
      successButtonText={successButtonText}
      successButtonAction={handleAction}
      successButtonDataQa={successButtonDataQa}
      isBaseButton={isBaseButton || !isSuccess}
      buttonStyles={{ ...baseButtonStyles, ...buttonStyles }}
      successCheckMarkExist={successCheckMarkExist}
      titleStyles={{
        fontWeight: 700,
        fontSize: 17,
        marginTop: 25,
        textAlign: 'center',
        ...titleStyles,
      }}
      subTitleHeadlineStyles={{
        fontWeight: 700,
        color: colors.apple,
        fontSize: 16,
        marginTop: 25,
        width: 280,
        textAlign: 'center',
      }}
      subTitleStyles={{
        fontSize: 16,
        color: colors.darkGray,
        marginTop: 13,
        marginBottom: 42,
        width: 300,
        textAlign: 'center',
        ...subTitleStyles,
      }}
      style={style}
    />
  );
};

export default WizardActionStatus;
