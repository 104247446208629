import { useMemo } from 'react';
import moment from 'moment';

import { Booking, TherapistTimeslot } from 'ts-frontend/types';

interface UseRepeatingBookingParams {
  selectedBooking: Booking | undefined;
  onlyConfirmedOrTentative?: boolean;
}

/*
 * selectedBooking would usually be selectedCancelBooking or selectedConfirmBooking from the InRoomSchedulingState
 * but any booking from the getBooking controller (/v3/rooms/:roomID/bookings/:bookingID) includes the repeating booking data.
 */
const useRepeatingBooking = ({
  selectedBooking,
  onlyConfirmedOrTentative,
}: UseRepeatingBookingParams) => {
  const creditMinutes =
    selectedBooking?.repeatingBookings?.length &&
    selectedBooking?.repeatingBookings[0].creditMinutes;
  const repeatingTimeslots = useMemo<TherapistTimeslot[] | undefined>(
    () =>
      selectedBooking?.repeatingBookings
        ?.filter(
          (booking) =>
            !onlyConfirmedOrTentative ||
            (booking.status === 'active' &&
              (booking.timekitBookingState === 'tentative' ||
                booking.timekitBookingState === 'confirmed'))
        )
        ?.sort((booking1, booking2) => moment(booking1.startTime).diff(booking2.startTime))
        .map((repeatingBooking) => {
          return {
            start: repeatingBooking.startTime,
            end: moment(repeatingBooking.startTime).add(creditMinutes, 'minutes').toISOString(),
          };
        }),
    [selectedBooking?.repeatingBookings, creditMinutes, onlyConfirmedOrTentative]
  );

  const { repeatingMeta } = selectedBooking || {};
  const { sessions, startTime, repeat } = repeatingMeta || {};

  const conflictingTimeslots = useMemo<TherapistTimeslot[] | null>(() => {
    if (!sessions || !startTime || !repeat || !repeatingTimeslots?.length) {
      return null;
    }
    const attemptedBookings: TherapistTimeslot[] = [];
    for (let i = 0; i < sessions; i += 1) {
      const multiplier = repeat === 'every-other-week' ? 2 : 1;
      const weeks = i * multiplier;
      const start = moment(startTime).add(weeks, 'weeks');
      attemptedBookings.push({
        start: moment(start).toISOString(),
        end: moment(start).add(creditMinutes, 'minutes').toISOString(),
      });
    }
    const conflicting = attemptedBookings.filter(
      (attemptedTimeslot) =>
        !repeatingTimeslots?.find((timeslot) => timeslot.start === attemptedTimeslot.start)
    );
    return conflicting;
  }, [sessions, startTime, repeat, repeatingTimeslots, creditMinutes]);

  if (
    !selectedBooking?.repeatingBookingID ||
    !selectedBooking?.repeatingMeta ||
    !selectedBooking?.repeatingBookings?.length
  ) {
    return {
      isRecurringBooking: false,
      repeatingTimeslots: null,
      conflictingTimeslots: null,
      repeatingMeta: null,
      isSomeConfirmed: null,
      isAllConfirmed: null,
    };
  }

  const repeatingBookings = selectedBooking?.repeatingBookings;

  const statusSummary = repeatingBookings?.length
    ? {
        isSomeConfirmed: repeatingBookings.some((b) => b.timekitBookingState === 'confirmed'),
        isAllConfirmed: repeatingBookings.every((b) => b.timekitBookingState === 'confirmed'),
      }
    : {
        isSomeConfirmed: null,
        isAllConfirmed: null,
      };

  return {
    isRecurringBooking: true,
    repeatingTimeslots: repeatingTimeslots || null,
    conflictingTimeslots,
    repeatingMeta: selectedBooking?.repeatingMeta || null,
    ...statusSummary,
  };
};

export default useRepeatingBooking;
