import { ChargeType, LiveSessionModality } from 'ts-frontend/types';

import { TimeSlotRange } from '../types';

export const bookingsQueryKey = ({ roomID }: { roomID: number }) => [
  'scheduler',
  'bookings',
  roomID,
];

export const videoCreditOffersQueryKey = ({
  roomID,
  bookingID,
}: {
  roomID: number;
  bookingID?: string;
}) => ['scheduler', 'video-credit-offers', roomID, bookingID || ''];

export const therapistTimeslotsQueryKey = ({
  therapistUserID,
  duration,
  range,
  roomID,
}: {
  therapistUserID: number;
  duration: number;
  range: TimeSlotRange;
  roomID?: number;
}) => ['scheduler', 'therapist-timeslots', therapistUserID, duration, range, roomID || ''];

export const planInfoQueryKey = ({ planID }: { planID: number }) => [
  'scheduler',
  'plan-info',
  planID,
];

export const bookingQueryKey = ({ roomID, bookingID }: { roomID: number; bookingID: string }) => [
  'scheduler',
  'booking',
  roomID,
  bookingID,
];

export const videoCallQueryKey = ({
  roomID,
  videoCallID,
}: {
  roomID: number;
  videoCallID: number;
}) => ['scheduler', 'video-call', roomID, videoCallID];

export const activeSessionQueryKey = ({
  roomID,
  modality = 'none',
}: {
  roomID: number;
  modality?: LiveSessionModality | 'none';
}) => ['scheduler', 'active-session', roomID, modality];

export const activeSessionAllRoomsQueryKey = ({
  clientUserID,
  modality = 'none',
}: {
  clientUserID: number;
  modality?: LiveSessionModality | 'none';
}) => ['scheduler', 'active-session', clientUserID, modality];

export const adminConfigQueryKey = ({ configName }: { configName: string }) => [
  'admin-config',
  configName,
];

export const clientTransactionsQueryKey = ({
  clientUserID,
  chargeTypes = [],
}: {
  clientUserID: number;
  chargeTypes?: Array<ChargeType>;
}) => ['client-transactions', clientUserID, chargeTypes.sort().join()];
