import debounce from 'lodash/debounce';
import { useEffect } from 'react';

const useScrollTracking = ({
  handleScrollStart,
  handleScrollStop,
  trackedElement,
}: {
  handleScrollStart: () => void;
  handleScrollStop: () => void;
  trackedElement: HTMLDivElement | null;
}) => {
  useEffect(() => {
    if (trackedElement && handleScrollStart && handleScrollStop) {
      trackedElement.addEventListener('scroll', handleScrollStart);

      trackedElement.addEventListener(
        'scroll',
        debounce(() => {
          handleScrollStop();
        }, 100)
      );
    }

    return () => {
      trackedElement?.removeEventListener('scroll', handleScrollStart);

      trackedElement?.removeEventListener(
        'scroll',
        debounce(() => {
          handleScrollStop();
        }, 100)
      );
    };
  }, [handleScrollStart, handleScrollStop, trackedElement]);
};

export default useScrollTracking;
