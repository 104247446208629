import styled from '@emotion/styled-base';
import { EmotionTheme, RepeatingArrowsDarkIcon, Small, View } from '@talkspace/react-toolkit';

const Styled = {
  Container: styled(View)(({ theme: { colors } }) => {
    return {
      backgroundColor: colors.aquaSqueeze,
      borderRadius: 10,
      padding: '12px 10px',
    };
  }),
  Text: styled(Small)<{ theme: EmotionTheme }>(({ theme: { colors } }) => {
    return {
      marginLeft: 8,
      color: colors.TSBlack,
    };
  }),
  EmphasizedText: styled(Small)<{ theme: EmotionTheme }>(({ theme: { colors } }) => {
    return {
      fontWeight: 'bold',
      color: colors.accessibilityDarkGreenBold,
    };
  }),
};

const ScheduleRepeatingSessionsCallout = () => (
  <Styled.Container row data-qa="scheduleRepeatingSessionCallout">
    <View>
      <RepeatingArrowsDarkIcon />
    </View>
    <Styled.Text>
      <Styled.EmphasizedText inline>Recurring sessions:</Styled.EmphasizedText> Schedule multiple
      sessions with this client at once (only available for BH therapy clients).
    </Styled.Text>
  </Styled.Container>
);

export default ScheduleRepeatingSessionsCallout;
