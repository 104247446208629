import { FunctionComponent } from 'react';
import { useWindowWidthState } from '../../hooks/windowWidthContext';
import { Standard, Small, Large } from '../Typography';
import TouchableView from '../TouchableView';
import useSessionDuration from '../../hooks/useSessionDuration';
import styled, { EmotionStyle } from '../../core/styled';
import View from '../View';

export interface ChatSessionBannerProps {
  callStartedAt: string | null;
  isInProviderChat: boolean;
  didClientJoin: boolean;
  didProviderJoin: boolean;
  handleEndSession?: () => void;
  bannerStyle?: EmotionStyle;
}

const Wrapper = styled(View)<{ isMobile: boolean }>(({ isMobile, theme: { colors } }) => {
  const mobileStyle = {
    boxShadow: '0 12px 20px -1px rgba(0,0,50,0.09)',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    padding: '16px 25px',
  };

  return {
    flex: 1,
    flexDirection: 'row',
    position: 'relative',
    background: colors.white,
    paddingTop: 10.9,
    paddingRight: 25,
    paddingBottom: 12.3,
    paddingLeft: 25,
    height: '100%',
    ...(isMobile && mobileStyle),
  };
});

const CenterWrapper = styled(View)({
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
});

const DotIndicator = styled.span<{ isActive: boolean }>(({ isActive, theme: { colors } }) => {
  return {
    fontSize: 30,
    color: isActive ? colors.permaGreenIndicator : colors.slateGrey,
    display: 'inline-block',
    marginRight: 5.5,
  };
});

const OverLayWrapper = styled(View)({
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  right: 0,
  top: 0,
  paddingRight: 'inherit',
  height: '100%',
});

const SessionTime = styled(View)({
  height: 20,
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'center',
});

const ChatSessionBanner: FunctionComponent<ChatSessionBannerProps> = ({
  isInProviderChat,
  callStartedAt,
  handleEndSession,
  bannerStyle,
  didClientJoin,
  didProviderJoin,
}) => {
  const { isMobile } = useWindowWidthState();
  const { getTime } = useSessionDuration(callStartedAt);

  const buildMessage = () => {
    if (callStartedAt) {
      return 'Live chat session';
    }

    if (!didClientJoin && !didProviderJoin) {
      return 'Waiting for you to join';
    }

    if (isInProviderChat && !didClientJoin) {
      return 'Waiting for your client to join';
    }

    if (!isInProviderChat && !didProviderJoin) {
      return 'Waiting for your provider to join';
    }

    return 'Waiting for you to join';
  };

  return (
    <Wrapper isMobile={isMobile} style={bannerStyle}>
      <CenterWrapper>
        <Standard variant="standardDarkGrey">{buildMessage()}</Standard>
        <SessionTime>
          <DotIndicator isActive={!!callStartedAt}>•</DotIndicator>
          <Large>{getTime()}</Large>
        </SessionTime>
      </CenterWrapper>
      {isInProviderChat && callStartedAt && (
        <OverLayWrapper>
          <TouchableView onPress={handleEndSession}>
            <Small variant="smallBolderGreen">End Session</Small>
          </TouchableView>
        </OverLayWrapper>
      )}
    </Wrapper>
  );
};
export default ChatSessionBanner;
