import { Large } from '@talkspace/react-toolkit';
import styled from '@/core/styled';

const ErrorMessage = styled(Large)(({ style, theme: { colors } }) => {
  return {
    color: colors.torchRed,
    ...style,
  };
});

export default ErrorMessage;
