import { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  ExtraHuge,
  Large,
  Spinner,
  styled,
  Tiny,
  usePanelManagerActions,
  View,
  RedX,
  Big,
  TouchableView,
} from '@talkspace/react-toolkit';
import FastClockIcon from 'components/Icons/FastClockIcon';
import useQueryBusinessLine, {
  BusinessLineQueryResponse,
} from 'ts-frontend/hooks/useQueryBusinessLine';
import useMutationCompleteEAPSession from 'hooks/notes/useMutationCompleteEAPSession';
import useQueryRoomDetails from 'ts-frontend/hooks/useQueryRoomDetails';
import { useHistory, useParams } from '@/core/routerLib';
import ReactFrameService from '@/auth/reactFrame/ReactFrameService';

const Wrapper = styled(View)({
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  textAlign: 'center',
});

const InnerWrapper = styled(View)({
  width: 335,
});

const IconWrapper = styled(View)(({ theme: { colors } }) => {
  return {
    justifyContent: 'center',
    alignItems: 'center',
    background: colors.white,
    height: 70,
    width: 70,
    borderRadius: 20,
    boxShadow: '0 3px 10px -3px rgba(16,109,149,0.30)',
  };
});

const BoldButton = styled(Button)({
  fontWeight: 'bold',
  width: '100%',
});

const SecondaryButton = styled(Button)(({ theme: { colors } }) => {
  return {
    fontWeight: 'bold',
    width: '100%',
    backgroundColor: colors.white,
    color: colors.slateGrey,
    marginBottom: 60,
  };
});

const FooterTextView = styled(View)({
  flex: 1,
  justifyContent: 'center',
  textAlign: 'start',
});

const FooterView = styled(View)({
  flexDirection: 'row',
  gap: 12,
});

const Title = styled(ExtraHuge)({
  marginBottom: 25,
});

const SubTitle = styled(Large)({
  marginBottom: 40,
});

const getMessageContent = (
  businessLineData?: BusinessLineQueryResponse
): { title: string; subTitle: string; buttonText: string; footerText: string } => {
  if (businessLineData?.isBH) {
    return {
      title: 'Create a session summary note',
      subTitle:
        'Because this client is on a Behavioral Health (BH) plan, your compensation for this session requires you to submit a session summary note.',
      buttonText: 'Create session summary note',
      footerText:
        'Providers who complete session summary notes immediately after a session can typically submit them in under 5 minutes.',
    };
  }

  if (businessLineData?.isEAP) {
    return {
      title: 'Create a session summary note',
      subTitle:
        'Complete a progress note on this session to document your client’s behavior, progress, and responses. ',
      buttonText: 'Create session summary note',
      footerText:
        'Providers who complete session summary notes immediately after a session can typically submit them in under 5 minutes.',
    };
  }

  return {
    title: 'Create a progress note',
    subTitle:
      'Complete a progress note on this session to document your client’s behavior, progress, and responses.',
    buttonText: 'Create progress note',
    footerText:
      'Providers who complete their progress notes immediately after a session can typically submit them in under 5 minutes.',
  };
};

interface Props {
  completedVideoCallID: string;
  dispatch: Function;
}

const PostLVSPrompt: FunctionComponent<Props> = ({ completedVideoCallID, dispatch }) => {
  const { roomID } = useParams<{ roomID: string }>();
  const history = useHistory();

  const { setIsLeftPanelOnTop, setIsRightPanelOpen } = usePanelManagerActions();

  const { data: { clientUserID } = {} } = useQueryRoomDetails(roomID);
  const { data: businessLineData, isLoading: isBusinessLineLoading } = useQueryBusinessLine(
    roomID,
    clientUserID
  );
  const {
    mutate: completeEAPSession,
    isLoading: isCompleteSessionLoading,
    isError: isCompleteSessionError,
  } = useMutationCompleteEAPSession();

  const closeModal = () => {
    dispatch({ type: 'CLOSE_POST_LVS_PROMPT' });
    if (ReactFrameService.instance().isInFrame()) {
      setIsRightPanelOpen(false);
    } else {
      setIsLeftPanelOnTop(true);
      setIsRightPanelOpen(false);
    }
  };

  const handleMainButtonPress = () => {
    if (businessLineData?.isEAP) {
      completeEAPSession(
        { roomID, videoCallID: completedVideoCallID },
        {
          onSuccess: () => {
            closeModal();
            history.push(`/room/${roomID}/notes-tab`);
          },
        }
      );
    } else {
      closeModal();
      history.push(`/room/${roomID}/notes-tab`);
    }
  };

  const handleDoThisLaterPress = () => {
    closeModal();
  };

  const { title, subTitle, buttonText, footerText } = getMessageContent(businessLineData);

  return isBusinessLineLoading || isCompleteSessionLoading ? (
    <Spinner />
  ) : (
    <Wrapper>
      {isCompleteSessionError ? (
        <>
          <RedX style={{ height: 69, width: 69, marginBottom: 25 }} />
          <Large variant="largeDarkGrey">Progress note failed to load.</Large>
          <Large variant="largeDarkGrey" style={{ marginBottom: 40 }}>
            Try again in one hour.
          </Large>
          <TouchableView onPress={() => closeModal()}>
            <Big variant="bigMedium">Close</Big>
          </TouchableView>
        </>
      ) : (
        <InnerWrapper>
          <Title>{title}</Title>
          <SubTitle>{subTitle}</SubTitle>
          <BoldButton onClick={handleMainButtonPress}>{buttonText}</BoldButton>
          {/* @ts-ignore */}
          <SecondaryButton onClick={handleDoThisLaterPress}>Do this later</SecondaryButton>
          <FooterView>
            <IconWrapper>
              <FastClockIcon />
            </IconWrapper>
            <FooterTextView>
              <Tiny>{footerText}</Tiny>
            </FooterTextView>
          </FooterView>
        </InnerWrapper>
      )}
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    completedVideoCallID: state.room.completedVideoCallID,
  };
};

export default connect(mapStateToProps)(PostLVSPrompt);
