/* @ts-disable */
import { FunctionComponent } from 'react';
import Svg, { Defs, G, Use, Path } from 'svgs';

interface DiagnosticProfileIconProps {
  width?: string | number;
  height?: string | number;
  color?: string;
  style?: object;
}

const DiagnosticProfileIcon: FunctionComponent<DiagnosticProfileIconProps> = ({
  width = 74,
  height = 74,
  color = '#BB58EF',
  ...otherProps
}) => (
  <Svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} {...otherProps}>
    <Defs>
      <Path
        d="M9.9 21.7c-4.584 13.313 2.492 27.82 15.804 32.404 13.313 4.584 27.82-2.492 32.404-15.804 4.584-13.313-2.492-27.82-15.804-32.404C28.99 1.312 14.484 8.388 9.9 21.7z"
        id="diagnostic_profile_b"
      />
    </Defs>
    <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <Use fill="#000" xlinkHref="#diagnostic_profile_b" />
      <Use fill="#FFF" xlinkHref="#diagnostic_profile_b" />
      <Path
        d="M61.926 27.95c1.132 15.423-10.452 28.844-25.874 29.976C20.629 59.06 7.209 47.474 6.076 32.052c-.28-3.82.216-7.595 1.436-11.144a2.5 2.5 0 014.728 1.626 22.916 22.916 0 00-1.177 9.152c.93 12.668 11.954 22.184 24.623 21.253 12.668-.93 22.184-11.954 21.253-24.622-.93-12.669-11.954-22.184-24.622-21.254a2.5 2.5 0 01-.367-4.987C47.373.944 60.793 12.528 61.926 27.951zM49.35 20.64c.287.468.552.948.794 1.437a2.5 2.5 0 01-4.481 2.218 13.197 13.197 0 00-.577-1.043c-3.751-6.122-11.755-8.044-17.876-4.292-6.122 3.751-8.044 11.755-4.292 17.877 3.751 6.121 11.755 8.043 17.877 4.291a2.5 2.5 0 112.612 4.264c-8.476 5.194-19.558 2.533-24.753-5.943-5.194-8.476-2.533-19.558 5.943-24.752 8.476-5.195 19.558-2.534 24.753 5.942zm-15.34 1.379a8 8 0 110 16 8 8 0 010-16zm14.75 10.5a2.5 2.5 0 110 5 2.5 2.5 0 010-5zm-14.75-5.5a3 3 0 100 6 3 3 0 000-6zm-19-16.5a2.5 2.5 0 110 5 2.5 2.5 0 010-5zm7.5-5.75a2.5 2.5 0 110 5 2.5 2.5 0 010-5z"
        fill={color}
        fillRule="nonzero"
      />
    </G>
  </Svg>
);

export default DiagnosticProfileIcon;
