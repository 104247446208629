import { FunctionComponent } from 'react';
import Svg, { Path, Rect } from 'svgs';
import { EmotionStyle, useEmotionTheme } from '../../core/styled';

const ReminderIcon: FunctionComponent<{
  color?: string;
  width?: string | number;
  height?: string | number;
  style?: EmotionStyle;
  isPressed?: boolean;
}> = ({ width = 16, height = 16, color, ...otherProps }) => {
  const { colors } = useEmotionTheme();

  const titleText = 'Reminder exclamation mark icon';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <Rect width={width} height={height} rx="4" fill={color || colors.permaDeepViolet} />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 8.96552H9V4H7V8.96552ZM7 12H9V10.069H7V12Z"
        fill="white"
      />
    </Svg>
  );
};

export default ReminderIcon;
