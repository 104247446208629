import { FunctionComponent, MouseEventHandler } from 'react';
import Svg, { Path, Circle, G } from 'svgs';
import BaseButton from '../../../components/BaseButton';
import withHover from '../../../hoc/withHover';
import { WithHoverProps } from '../../../hoc';
import { EmotionStyle, useEmotionTheme } from '../../../core/styled';
import { IconColorType } from '../../icons';

export interface CloseButtonProps extends WithHoverProps {
  ariaLabel?: string;
  width?: number;
  height?: number;
  backgroundColor?: string;
  color?: string;
  hoverColor?: string;
  hasHoverStyles?: boolean;
  simpleStyle?: boolean;
  onPress?: MouseEventHandler<HTMLButtonElement>;
  style?: EmotionStyle;
  dataQa?: string;
  roundedFocusStyle?: boolean;
  primaryColor?: string;
  xColorType?: IconColorType;
}

const CloseButtonWithCircle = ({
  width,
  height,
  color,
  backgroundColor,
  hoverColor,
  isHovering,
  hasHoverStyles,
  ...props
}) => {
  const { colors } = useEmotionTheme();
  return (
    <Svg width={width} height={height} viewBox="0 0 35 35" {...props}>
      <G
        transform="translate(-325 -16) translate(325 16)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <Circle
          fill={
            isHovering && hasHoverStyles
              ? hoverColor || colors.periwinkleGrey
              : backgroundColor || colors.permaLinkWaterGrey
          }
          cx={17.5}
          cy={17.5}
          r={17.5}
        />
        <Path
          d="M17.5 16.259l5.013-5.004a.87.87 0 011.233.002.875.875 0 01-.002 1.235L18.726 17.5l5.018 5.008c.34.34.342.893.002 1.235a.87.87 0 01-1.233.002L17.5 18.741l-5.013 5.004a.87.87 0 01-1.233-.002.875.875 0 01.002-1.235l5.018-5.008-5.018-5.008a.875.875 0 01-.002-1.235.87.87 0 011.233-.002l5.013 5.004z"
          fill={isHovering && hasHoverStyles ? colors.cometGrey : color || colors.slateGrey}
          fillRule="nonzero"
        />
      </G>
    </Svg>
  );
};

const CloseButtonSimple = ({ height = 24, width = 24, ...props }) => {
  const { colors } = useEmotionTheme();
  return (
    <Svg width={width} height={height} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M12 10.6633L17.3989 5.27421C17.7661 4.90761 18.3605 4.90874 18.7263 5.27674C19.0922 5.64473 19.0911 6.24024 18.7238 6.60684L13.3208 12L18.7238 17.3932C19.0911 17.7598 19.0922 18.3553 18.7263 18.7233C18.3605 19.0913 17.7661 19.0924 17.3989 18.7258L12 13.3367L6.60114 18.7258C6.23387 19.0924 5.63954 19.0913 5.27367 18.7233C4.90779 18.3553 4.90892 17.7598 5.27619 17.3932L10.6792 12L5.27619 6.60684C4.90892 6.24024 4.90779 5.64473 5.27367 5.27674C5.63954 4.90874 6.23387 4.90761 6.60114 5.27421L12 10.6633Z"
        fill={colors.permaOuterSpaceNew}
      />
    </Svg>
  );
};

const CloseButton: FunctionComponent<CloseButtonProps> = ({
  ariaLabel = 'close',
  isHovering,
  onPress,
  style,
  width = 35,
  height = 35,
  backgroundColor,
  color,
  hasHoverStyles = true,
  hoverColor,
  dataQa,
  roundedFocusStyle,
  primaryColor,
  simpleStyle = false,
  ...props
}) => (
  <BaseButton
    onPress={onPress}
    aria-label={ariaLabel}
    style={{ width, height, ...style }}
    data-qa={dataQa}
    roundedFocusStyle={roundedFocusStyle}
    primaryColor={primaryColor}
    {...props}
  >
    {simpleStyle ? (
      <CloseButtonSimple width={width} height={height} />
    ) : (
      <CloseButtonWithCircle
        width={width}
        height={height}
        color={color}
        backgroundColor={backgroundColor}
        hoverColor={hoverColor}
        isHovering={isHovering}
        hasHoverStyles={hasHoverStyles}
      />
    )}
  </BaseButton>
);

export default withHover(CloseButton);
