import { useState, useCallback, useRef, useEffect, FunctionComponent } from 'react';
import styled from '../../../core/styled';
import TouchableView from '../../../components/TouchableView';
import View from '../../../components/View';
import Button from '../Button';
import TextDS from '../typography/TextDS';
import { ButtonIconProps, SVGConfig } from '../Button/types';
import EmotionThemeProvider from '../../../hooks/EmotionThemeProvider';

export interface OverflowMenuOption {
  callback: () => void;
  optionLabel: string;
  selectedLabel?: string;
  optionValue: string;
}

export interface OverflowMenuProps {
  initialValue: string;
  options: OverflowMenuOption[];
  hasCaretDown?: boolean;
  dataQa?: string;
  Icon?: React.ComponentType<ButtonIconProps>;
  iconConfig?: SVGConfig;
}

const FloatingWrapper = styled(View)(({ theme: { colorRoles } }) => {
  return {
    zIndex: 1,
    backgroundColor: colorRoles.surfaces.surfaceInteractiveDefault,
    position: 'absolute',
    top: 54,
    left: '50%',
    transform: 'translateX(-50%)',
    padding: '8px 0px',
    borderRadius: 12,
    overflow: 'hidden',
    textAlign: 'left',
    flexDirection: 'column',
    maxHeight: 'calc(100vh/2)',
    boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.25)',
  };
});

const FloatingMenuOption = styled(TouchableView)(({ theme: { colorRoles } }) => {
  return {
    padding: '8px 16px',
    textAlign: 'center',
    background: colorRoles.surfaces.surfaceInteractiveDefault,
    '&:hover': {
      background: colorRoles.surfaces.surfaceInteractiveHovered,
    },
    '&:pressed': {
      background: colorRoles.surfaces.surfaceInteractivePressed,
    },
  };
});

const FloatingMenuSimple: FunctionComponent<OverflowMenuProps> = (props) => {
  const { options, Icon, iconConfig, hasCaretDown = true, dataQa, initialValue } = props;
  const [selectedIndex, setSelectedIndex] = useState(() => {
    const initialSelectedIndex = options.map((option) => option.optionValue).indexOf(initialValue);
    return initialSelectedIndex > 0 ? initialSelectedIndex : 0;
  });
  const [isOpen, setIsOpen] = useState(false);

  const onMenuClick = useCallback(() => {
    setIsOpen(true);
  }, []);

  const onOptionPress = (idx) => {
    setIsOpen(false);
    setSelectedIndex(idx);
  };

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        isOpen &&
        containerRef.current &&
        !containerRef.current.contains(event.target as Node) &&
        handleClose
      ) {
        handleClose();
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleClose, isOpen]);

  return (
    <View style={{ position: 'relative' }}>
      <EmotionThemeProvider version="2.0.0">
        <Button
          variant="tertiary"
          text={options[selectedIndex].selectedLabel || options[selectedIndex].optionLabel}
          onPress={onMenuClick}
          Icon={Icon}
          hasCaretDown={hasCaretDown}
          isActive={isOpen}
          iconConfig={iconConfig}
          dataQa={`${dataQa}-btn`}
        />
      </EmotionThemeProvider>
      {isOpen && (
        <FloatingWrapper ref={containerRef}>
          {options.map(({ callback: optionCallback, optionLabel }, idx) => {
            if (idx === selectedIndex) return null;
            return (
              <>
                <FloatingMenuOption
                  dataQa={`${dataQa}-option-${idx}`}
                  onPress={() => {
                    onOptionPress(idx);
                    optionCallback();
                  }}
                >
                  <TextDS variant="bodySm">{optionLabel}</TextDS>
                </FloatingMenuOption>
              </>
            );
          })}
        </FloatingWrapper>
      )}
    </View>
  );
};

export default FloatingMenuSimple;
