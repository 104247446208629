const ArrowLeftIcon = () => (
  <svg width="9px" height="15px" viewBox="0 0 9 15" version="1.1">
    <defs />
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="find-a-therapist" transform="translate(-15.000000, -109.000000)" fill="#0FC1A7">
        <path
          d="M12.3698869,112.360724 C11.8776961,112.837858 11.876445,113.610232 12.3676987,114.086458 L19.4994081,121 L26.6311174,114.086458 C27.1220997,113.610495 27.1245514,112.841184 26.6289292,112.360724 L26.6289292,112.360724 C26.1367385,111.88359 25.3475035,111.875094 24.8439384,112.363254 L19.4994081,117.544289 L14.1548777,112.363254 C13.6612452,111.884723 12.8655091,111.880263 12.3698869,112.360724 L12.3698869,112.360724 L12.3698869,112.360724 Z"
          id="Combined-Shape"
          transform="translate(19.500000, 116.500000) scale(-1, 1) rotate(-90.000000) translate(-19.500000, -116.500000) "
        />
      </g>
    </g>
  </svg>
);

export default ArrowLeftIcon;
