import { Booking } from 'ts-frontend/types';
import { useQuery } from 'react-query';

import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';
import { bookingsQueryKey } from '../utils/queryKeys';

const getClientBookings = (roomID: number) => (): Promise<Booking[]> =>
  apiWrapper
    .get<{ data: Booking[] }>(`${apiHelper().apiEndpoint}/v3/rooms/${roomID}/bookings`)
    .then((res) => res.data.data);

const useQueryBookings = ({ roomID }: { roomID: number }) =>
  useQuery<Booking[], Error>({
    queryKey: bookingsQueryKey({ roomID }),
    queryFn: getClientBookings(roomID),
    enabled: !!roomID && !Number.isNaN(roomID),
  });

export default useQueryBookings;
