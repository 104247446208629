import * as actionTypes from '../constants/MedicalInformationConstants';
import apiWrapper from '../utils/apiWrapper';
import apiHelper from '../utils/api';
import {
  transformAPIPayloadToReducerPayload,
  transformReducerPayloadToAPIPayload,
} from '../utils/optionsHelpers';

const handleAction = (action) => (payload) => {
  return {
    type: action,
    payload,
  };
};
const handleErrorAction = (action) => (error) => {
  return {
    type: action,
    error,
  };
};

const requestGetMedicalInformation = handleAction(actionTypes.REQUEST_GET_MEDICAL_INFORMATION);
const receiveGetMedicalInformation = handleAction(actionTypes.RECEIVE_GET_MEDICAL_INFORMATION);
const requestGetMedicalInformationError = handleErrorAction(
  actionTypes.REQUEST_GET_MEDICAL_INFORMATION_ERROR
);
export const getMedicalInformation = (clientUserID) => (dispatch) => {
  dispatch(requestGetMedicalInformation());
  return apiWrapper
    .get(`${apiHelper().apiEndpoint}/v3/clients/${clientUserID}/medical-information`)
    .then((response) => {
      const payload = transformAPIPayloadToReducerPayload(response.data.data);
      return dispatch(receiveGetMedicalInformation(payload));
    })
    .catch((error) => dispatch(requestGetMedicalInformationError(error)));
};

const requestSetMedicalInformation = handleAction(actionTypes.REQUEST_SET_MEDICAL_INFORMATION);
const receiveSetMedicalInformation = handleAction(actionTypes.RECEIVE_SET_MEDICAL_INFORMATION);
const requestSetMedicalInformationError = handleErrorAction(
  actionTypes.REQUEST_SET_MEDICAL_INFORMATION_ERROR
);
export const setMedicalInformation = (clientUserID, payload) => (dispatch) => {
  dispatch(requestSetMedicalInformation());
  const data = transformReducerPayloadToAPIPayload(payload);
  return apiWrapper
    .patch(`${apiHelper().apiEndpoint}/v3/clients/${clientUserID}/medical-information`, { data })
    .then(() => dispatch(receiveSetMedicalInformation(payload)))
    .catch((error) => dispatch(requestSetMedicalInformationError(error)));
};
