import moment from 'moment';
import sessionStorage from '@/core/storage/sessionStorage';
import { EMessage } from './entities/EMessage';
import { CaptionsLanguage } from './types/videoCallTypes';

export function isSameTimeRange(
  newerMessage: EMessage,
  olderMessage: EMessage | null | undefined,
  numSeconds = 1800
) {
  if (!olderMessage || !olderMessage.createdAt) {
    return false;
  }

  const currentCreatedAt = moment(newerMessage.createdAt);
  const diffCreatedAt = moment(olderMessage.createdAt);

  if (!currentCreatedAt.isValid() || !diffCreatedAt.isValid()) {
    return false;
  }

  return currentCreatedAt.diff(diffCreatedAt, 'seconds') < numSeconds;
}

export function isSameUser(newerMessage: EMessage, olderMessage: EMessage | null | undefined) {
  return !!(
    olderMessage &&
    olderMessage.user &&
    newerMessage.user &&
    olderMessage.user.id === newerMessage.user.id
  );
}

export const fixMainsiteDateToIsoString = (strDate: string): string => {
  if (typeof strDate !== 'string' || strDate.includes('Z')) return strDate;
  return `${strDate.trim().replace(' ', 'T')}Z`;
};

const CAPTIONS_LANGUAGE_STORAGE_KEY = 'captionsLanguage';

type VideoCallID = number;

type StoredCaptionsLanguage = Record<VideoCallID, CaptionsLanguage>;

export const getStoredCaptionsLanguage = (): StoredCaptionsLanguage | null => {
  const storedCaptionsLanguage = sessionStorage.getItem(CAPTIONS_LANGUAGE_STORAGE_KEY);

  if (!storedCaptionsLanguage) {
    return null;
  }

  try {
    return JSON.parse(storedCaptionsLanguage) as StoredCaptionsLanguage;
  } catch (e) {
    sessionStorage.removeItem(CAPTIONS_LANGUAGE_STORAGE_KEY);
    return null;
  }
};

export const setStoredCaptionsLanguage = (value: StoredCaptionsLanguage): void => {
  const existingValue = getStoredCaptionsLanguage();
  if (!existingValue) {
    sessionStorage.setItem(CAPTIONS_LANGUAGE_STORAGE_KEY, JSON.stringify(value));
    return;
  }

  sessionStorage.setItem(
    CAPTIONS_LANGUAGE_STORAGE_KEY,
    JSON.stringify({ ...existingValue, ...value })
  );
};
