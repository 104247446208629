import { forwardRef, useMemo } from 'react';
import getGridStyles from './getGridStyles';
import { mediaBreakpointUp } from './breakpoints';
import styled, { EmotionStyle } from '../../core/styled';
import { useWindowWidthState } from '../../hooks/windowWidthContext';
import { WindowWidthValues } from '../../hooks/useWindowWidth';
import { Breakpoint, BreakpointValues } from '../../types';

export interface GridProps {
  fluid?: boolean;
  children?: React.ReactNode;
  columns?: number;
  gutterWidth?: number;
  sideMargin?: number;
  breakpoints: BreakpointValues;
  containerMaxWidths: BreakpointValues;
  className?: string;
  style?: EmotionStyle;
  id?: string;
  role?: string;
}

type ContainerViewProps = {
  window: WindowWidthValues;
} & Required<
  Pick<GridProps, 'fluid' | 'gutterWidth' | 'breakpoints' | 'containerMaxWidths' | 'sideMargin'>
>;

const ContainerView = styled.div<ContainerViewProps>(
  ({ fluid, sideMargin, containerMaxWidths: maxWidths, breakpoints }) => {
    const baseStyles = {
      boxSizing: 'border-box',
      width: '100%',
      paddingRight: sideMargin,
      paddingLeft: sideMargin,
      marginRight: 'auto',
      marginLeft: 'auto',
    };
    if (fluid) return baseStyles;
    const containerStyles = { ...baseStyles };

    Object.keys(maxWidths).forEach((breakpoint: Breakpoint) => {
      const maxWidth = maxWidths[breakpoint];
      const media = mediaBreakpointUp(breakpoint, breakpoints);

      const mediaStyles = {
        maxWidth,
      };

      if (media) {
        Object.assign(containerStyles, {
          [media]: mediaStyles,
        });
      } else {
        Object.assign(containerStyles, mediaStyles);
      }
    });

    return containerStyles;
  }
);

const Grid = forwardRef<HTMLDivElement, GridProps>(
  (
    {
      children,
      fluid = true,
      breakpoints,
      containerMaxWidths,
      columns,
      gutterWidth,
      sideMargin,
      className,
      style,
      id,
      role,
    }: GridProps,
    ref
  ) => {
    const window = useWindowWidthState();

    const props = useMemo(() => {
      if (!gutterWidth || !containerMaxWidths || !breakpoints || !columns || !sideMargin) {
        return getGridStyles(window);
      }

      return {
        gutterWidth,
        containerMaxWidths,
        breakpoints,
        columns,
        sideMargin,
      };
    }, [window, gutterWidth, containerMaxWidths, breakpoints, columns, sideMargin]);

    return (
      <ContainerView
        window={window}
        fluid={fluid}
        data-eg-container="true"
        {...props}
        ref={ref}
        className={className}
        style={style}
        id={id}
        role={role}
      >
        {children}
      </ContainerView>
    );
  }
);

export default Grid;
