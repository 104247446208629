import { useEffect, useState } from 'react';
import {
  BaseButton,
  View,
  Small,
  Large,
  ExclamationSquare,
  useEmotionTheme,
  TextAreaRounded,
  RoundFilledCheckmark,
  Standard,
  EmotionTheme,
  FloatingMenu,
  useWindowWidthState,
} from '@talkspace/react-toolkit';
import { keyframes } from '@emotion/core';
import styled from '@/core/styled';
import { getTextAreaStyle } from '../../../Room/CRMContainer/NotesTab/utils';
import useMutationUpdateTask, {
  UpdateTaskMutateVariables,
} from '../../../../hooks/dashboard/useMutationUpdateTask';
import { TaskType } from '../../types';
import SubmitButton from '../../Cells/TaskActionButtons/SubmitButton';
import trackEvent from '../../../../modules/analytics/trackEvent';

type Content =
  | 'reviewNoShow'
  | 'confirmNoShow'
  | 'grantException'
  | 'submittedNoShowSuccess'
  | 'submittedExceptionSuccess';

const mappedMenuItems = {
  reviewNoShow: 140,
  confirmNoShow: 240,
  grantException: 370,
  submittedNoShowSuccess: 60,
  submittedExceptionSuccess: 76,
};

const fadeIn = keyframes`
 0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const FadeWrapper = styled(View)(() => {
  return {
    animation: `${fadeIn} 500ms cubic-bezier(.65,-0.01,.38,1) both`,
  };
});

const floatingMenuStyle = (content: Content, isMobile: boolean) => {
  return {
    top: 35,
    left: isMobile ? 'auto' : -179,
    right: 0,
    width: 320,
    maxHeight: 378,
    height: mappedMenuItems[content] || 148,
    padding: '20px 12px 20px 20px',
  };
};

const SubmittedExceptionSuccessStandard = styled(Standard)({
  float: 'left',
  marginLeft: 25,
  textAlign: 'left',
});

interface ReviewNoShowProps {
  colors: EmotionTheme['colors'];
  setContent: (value: Content) => void;
  roomID: number;
  therapistID: number;
  type: TaskType;
  bookingID?: number;
  taskID: number;
}

const ReviewNoShow = ({
  colors,
  setContent,
  roomID,
  therapistID,
  type,
  bookingID,
  taskID,
}: ReviewNoShowProps) => {
  const onConfirmNoShow = () => {
    setContent('confirmNoShow');
    trackEvent(
      'noShowActionClick',
      { actionName: 'providerTaskInteraction' },
      {
        roomID,
        providerID: therapistID,
        bookingID,
        action: 'confirm no-show',
        type:
          type === 'reviewClientNoShow' ? 'No-show Action Click' : 'Late-cancellation Action Click',
        taskID,
      }
    );
  };

  const onGrantException = () => {
    setContent('grantException');
    trackEvent(
      'noShowActionClick',
      { actionName: 'providerTaskInteraction' },
      {
        roomID,
        providerID: therapistID,
        bookingID,
        action: 'grant an exception',
        type:
          type === 'reviewClientNoShow' ? 'No-show Action Click' : 'Late-cancellation Action Click',
        taskID,
      }
    );
  };

  return (
    <FadeWrapper align="start">
      <View align="start" style={{ display: 'inline', marginBottom: 16 }}>
        <ExclamationSquare color={colors.permaGrape} width={14} height={14} />
        <Large inline style={{ fontWeight: 700, marginLeft: 5 }}>
          Review no-show session
        </Large>
      </View>
      <View align="start" style={{ marginLeft: 16 }}>
        <BaseButton
          onPress={onConfirmNoShow}
          style={{ marginBottom: 15 }}
          data-qa="confirmNoShowButton"
        >
          <Small variant="smallTSBlack">Submit as a no-show</Small>
        </BaseButton>
        <BaseButton
          style={{ marginBottom: 10 }}
          onPress={onGrantException}
          data-qa="grantExceptionButton"
        >
          <Small variant="smallTSBlack">Grant an exception</Small>
        </BaseButton>
      </View>
    </FadeWrapper>
  );
};

interface ConfirmNoShowProps {
  colors: EmotionTheme['colors'];
  updateTask: (params: UpdateTaskMutateVariables) => void;
  roomID: number;
  therapistID: number;
  taskID: number;
  bookingID?: number;
  type: TaskType;
  setContent: (value: Content) => void;
  onCancel: () => void;
  isMobile: boolean;
}

const ConfirmNoShow = ({
  colors,
  updateTask,
  roomID,
  therapistID,
  taskID,
  bookingID,
  type,
  setContent,
  onCancel,
  isMobile,
}: ConfirmNoShowProps) => {
  const onSubmitNoShow = () => {
    updateTask({
      action: 'confirmNoShow',
      therapistID,
      taskID,
    });
    setContent('submittedNoShowSuccess');
  };

  return (
    <FadeWrapper align="start">
      <View align="start" style={{ display: 'inline', marginBottom: 16 }}>
        <ExclamationSquare color={colors.permaGrape} width={14} height={14} />
        <Large inline style={{ fontWeight: 700, marginLeft: 5 }}>
          Confirm no-show session
        </Large>
      </View>
      <Small variant="smallTSBlack" style={{ marginBottom: 16, textAlign: 'left' }}>
        By submitting this session as a “Client no-show” you are confirming that the client didn’t
        show up for the appointment, and are aware that this will result in a direct charge to the
        member for the cost of the session as per Talkspace’s policy.
      </Small>
      <View align="start" style={{ display: 'inline' }}>
        <BaseButton
          style={{ marginLeft: 5, marginRight: isMobile ? 35 : 50 }}
          onPress={onCancel}
          data-qa="cancelConfirmNoShowButton"
        >
          <Small variant="smallBoldGrey">Cancel</Small>
        </BaseButton>
        <SubmitButton
          style={{ display: 'inline', width: 172 }}
          onPress={onSubmitNoShow}
          data-qa="submitNoShowButton"
        >
          <Standard variant="standardWhite">Submit as a no-show</Standard>
        </SubmitButton>
      </View>
    </FadeWrapper>
  );
};

interface GrantExceptionProps {
  colors: EmotionTheme['colors'];
  updateTask: (params: UpdateTaskMutateVariables) => void;
  roomID: number;
  therapistID: number;
  taskID: number;
  bookingID?: number;
  type: TaskType;
  setContent: (value: Content) => void;
  onCancel: () => void;
  isMobile: boolean;
}

const GrantException = ({
  colors,
  updateTask,
  roomID,
  therapistID,
  taskID,
  bookingID,
  type,
  setContent,
  onCancel,
  isMobile,
}: GrantExceptionProps) => {
  const [exceptionReason, setExceptionReason] = useState('');
  const [exceptionTextError, setExceptionTextError] = useState('');

  const onSubmitException = () => {
    let isValid = true;
    if (!exceptionReason) {
      setExceptionTextError('Please leave your exception reason.');
      isValid = false;
    }
    if (isValid) {
      updateTask({
        action: 'grantNoShowException',
        therapistID,
        taskID,
        exceptionReason,
      });
      setContent('submittedExceptionSuccess');
    }
  };

  const onBlurExceptionTextArea = () => {
    if (!exceptionReason) {
      setExceptionTextError('Please leave your exception reason.');
    }
  };

  const handleGrantExceptionTextChange = (grantExceptionText: string) => {
    if (exceptionTextError) {
      setExceptionTextError('');
    }
    setExceptionReason(grantExceptionText);
  };

  return (
    <FadeWrapper align="start">
      <View align="start" style={{ display: 'inline', marginBottom: 16 }}>
        <ExclamationSquare color={colors.permaGrape} width={14} height={14} />
        <Large inline style={{ fontWeight: 700, marginLeft: 5 }}>
          Grant an exception
        </Large>
      </View>
      <Small variant="smallTSBlack" style={{ marginBottom: 16, textAlign: 'left' }}>
        The client did not show, but you wish to grant the member an exception due to an emergency.
        A fee will not be charged to the member.
      </Small>
      <Small variant="smallTSBlack" style={{ marginBottom: 16, textAlign: 'left' }}>
        Please provide details for the no-show exception below:
      </Small>
      <TextAreaRounded
        numberOfLines={4}
        style={{
          marginBottom: 16,
          width: 266,
          height: 102,
          minHeight: 102,
          borderColor: exceptionTextError ? colors.jellybeanRed : colors.permaWildBlueYonder,
        }}
        textAreaStyle={getTextAreaStyle(false)}
        value={exceptionReason || ''}
        onChangeText={handleGrantExceptionTextChange}
        onBlur={onBlurExceptionTextArea}
        multiline
        dataQa="GrantEcxeptionTextArea"
        placeholder={exceptionTextError}
      />
      <View
        align="start"
        style={{
          display: 'inline',
        }}
      >
        <BaseButton
          style={{ marginLeft: 20, marginRight: isMobile ? 39 : 54 }}
          onPress={onCancel}
          data-qa="cancelConfirmNoShowButton"
        >
          <Small variant="smallBoldGrey">Cancel</Small>
        </BaseButton>

        <SubmitButton
          style={{ display: 'inline', width: 148 }}
          onPress={onSubmitException}
          data-qa="submitNoShowExceptionButton"
        >
          <Standard variant="standardWhite">Grant exception</Standard>
        </SubmitButton>
      </View>
    </FadeWrapper>
  );
};

const SubmittedNoShowSuccess = () => (
  <FadeWrapper align="start">
    <View align="center" row>
      <RoundFilledCheckmark width={20} height={20} style={{ marginTop: -3 }} />
      <Standard variant="standardBlack" style={{ marginLeft: 5 }}>
        Session was submitted as a no-show.
      </Standard>
    </View>
  </FadeWrapper>
);

const SubmittedExceptionSuccess = () => (
  <FadeWrapper align="start">
    <View align="start" row>
      <RoundFilledCheckmark width={20} height={20} />
      <SubmittedExceptionSuccessStandard variant="standardBlack" style={{ marginLeft: 5 }}>
        No-show session was submited as an exception.
      </SubmittedExceptionSuccessStandard>
    </View>
  </FadeWrapper>
);

const ReviewNoShowModal = ({
  roomID,
  therapistID,
  taskID,
  bookingID,
  type,
  onBackdropPress,
  hide,
  handleDisable,
}: {
  roomID: number;
  therapistID: number;
  taskID: number;
  bookingID?: number;
  type: TaskType;
  onBackdropPress: () => void;
  hide: boolean;
  handleDisable: (val: boolean) => void;
}) => {
  const { colors } = useEmotionTheme();
  const { isMobile } = useWindowWidthState();
  const { mutate: updateTask, isLoading, isError } = useMutationUpdateTask();

  useEffect(() => {
    if (isLoading) {
      handleDisable(true);
    }
    if (isError) {
      handleDisable(false);
    }
  }, [isLoading, handleDisable, isError]);

  const [content, setContent] = useState<Content>('reviewNoShow');

  const onCancel = () => {
    setContent('reviewNoShow');
    onBackdropPress();
  };

  return (
    <FloatingMenu
      style={floatingMenuStyle(content, isMobile)}
      hide={hide}
      onBackdropPress={onCancel}
    >
      {(() => {
        switch (content) {
          case 'confirmNoShow': {
            return (
              <ConfirmNoShow
                colors={colors}
                updateTask={updateTask}
                type={type}
                roomID={roomID}
                therapistID={therapistID}
                taskID={taskID}
                bookingID={bookingID}
                setContent={setContent}
                onCancel={onCancel}
                isMobile={isMobile}
              />
            );
          }
          case 'grantException': {
            return (
              <GrantException
                colors={colors}
                updateTask={updateTask}
                type={type}
                roomID={roomID}
                therapistID={therapistID}
                taskID={taskID}
                bookingID={bookingID}
                setContent={setContent}
                onCancel={onCancel}
                isMobile={isMobile}
              />
            );
          }
          case 'submittedNoShowSuccess': {
            return <SubmittedNoShowSuccess />;
          }
          case 'submittedExceptionSuccess': {
            return <SubmittedExceptionSuccess />;
          }
          default: {
            return (
              <ReviewNoShow
                colors={colors}
                setContent={setContent}
                bookingID={bookingID}
                roomID={roomID}
                therapistID={therapistID}
                type={type}
                taskID={taskID}
              />
            );
          }
        }
      })()}
    </FloatingMenu>
  );
};

export default ReviewNoShowModal;
