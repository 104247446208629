import { FunctionComponent, useState } from 'react';
import { AccordionContainer } from '@talkspace/react-toolkit';
import RiskAssessmentQuestion, {
  FieldError,
  RiskAssessmentField,
  RiskAssessmentValue,
} from './RiskAssessmentQuestion';
import {
  useProgressNoteFormActions,
  useProgressNoteFormState,
} from '../context/PsychProgressNoteFormContext';
import { ProgressNoteFormState } from '../context/types';
import ConfirmButton from './ConfirmButton';

const RiskAssessmentSection: FunctionComponent = () => {
  const {
    riskAssessment: globalRiskAssessment,
    formSections,
    formMode,
  } = useProgressNoteFormState();
  const [riskAssessment, setRiskAssessment] =
    useState<ProgressNoteFormState['riskAssessment']>(globalRiskAssessment);
  const { setFormState } = useProgressNoteFormActions();
  const [pastSuicidalIdeationErrors, setPastSuicidalIdeationErrors] = useState<FieldError>({
    severity: false,
    notes: false,
  });
  const [currentSuicidalIdeationErrors, setCurrentSuicidalIdeationErrors] = useState<FieldError>({
    severity: false,
    notes: false,
  });
  const [pastHomicidalIdeationErrors, setPastHomicidalIdeationErrors] = useState<FieldError>({
    severity: false,
    notes: false,
  });
  const [currentHomicidalIdeationErrors, setCurrentHomicidalIdeationErrors] = useState<FieldError>({
    severity: false,
    notes: false,
  });
  const [pastPsychosisErrors, setPastPsychosisErrors] = useState<FieldError>({
    severity: false,
    notes: false,
  });
  const [currentPsychosisErrors, setCurrentPsychosisErrors] = useState<FieldError>({
    severity: false,
    notes: false,
  });
  const [otherAssessementErrors, setOtherAssessementErrors] = useState<FieldError>({
    severity: false,
    notes: false,
  });

  const markSectionAsIncomplete = () => {
    if (formSections.riskAssessment.completed) {
      setFormState({
        formSections: {
          ...formSections,
          riskAssessment: {
            open: formSections.riskAssessment.open,
            changed: true,
            completed: false,
          },
        },
      });
    }
  };

  const setPastSuicidalIdeation = (value: RiskAssessmentValue) => {
    markSectionAsIncomplete();
    setRiskAssessment({
      ...riskAssessment,
      pastSuicidalIdeation: value.mainQuestion,
      pastSuicidalIdeationSeverity: value.severity,
      pastSuicidalIdeationNotes: value.notes,
    });
  };

  const resetPastSuicidalIdeationErrors = (field: RiskAssessmentField) => {
    setPastSuicidalIdeationErrors({
      ...pastSuicidalIdeationErrors,
      [field]: false,
    });
  };

  const setCurrentSuicidalIdeation = (value: RiskAssessmentValue) => {
    markSectionAsIncomplete();
    setRiskAssessment({
      ...riskAssessment,
      currentSuicidalIdeation: value.mainQuestion,
      currentSuicidalIdeationSeverity: value.severity,
      currentSuicidalIdeationNotes: value.notes,
    });
  };

  const resetCurrentSuicidalIdeationErrors = (field: RiskAssessmentField) => {
    setCurrentSuicidalIdeationErrors({
      ...currentSuicidalIdeationErrors,
      [field]: false,
    });
  };

  const setPastHomicidalIdeation = (value: RiskAssessmentValue) => {
    markSectionAsIncomplete();
    setRiskAssessment({
      ...riskAssessment,
      pastHomicidalIdeation: value.mainQuestion,
      pastHomicidalIdeationSeverity: value.severity,
      pastHomicidalIdeationNotes: value.notes,
    });
  };

  const resetPastHomicidalIdeationErrors = (field: RiskAssessmentField) => {
    setPastHomicidalIdeationErrors({
      ...pastHomicidalIdeationErrors,
      [field]: false,
    });
  };

  const setCurrentHomicidalIdeation = (value: RiskAssessmentValue) => {
    markSectionAsIncomplete();
    setRiskAssessment({
      ...riskAssessment,
      currentHomicidalIdeation: value.mainQuestion,
      currentHomicidalIdeationSeverity: value.severity,
      currentHomicidalIdeationNotes: value.notes,
    });
  };

  const resetCurrentHomicidalIdeationErrors = (field: RiskAssessmentField) => {
    setCurrentHomicidalIdeationErrors({
      ...currentHomicidalIdeationErrors,
      [field]: false,
    });
  };

  const setPastPsychosis = (value: RiskAssessmentValue) => {
    markSectionAsIncomplete();
    setRiskAssessment({
      ...riskAssessment,
      pastPsychosis: value.mainQuestion,
      pastPsychosisNotes: value.notes,
    });
  };

  const resetPastPsychosisErrors = (field: RiskAssessmentField) => {
    setPastPsychosisErrors({
      ...pastPsychosisErrors,
      [field]: false,
    });
  };

  const setCurrentPsychosis = (value: RiskAssessmentValue) => {
    markSectionAsIncomplete();
    setRiskAssessment({
      ...riskAssessment,
      currentPsychosis: value.mainQuestion,
      currentPsychosisNotes: value.notes,
    });
  };

  const resetCurrentPsychosisErrors = (field: RiskAssessmentField) => {
    setCurrentPsychosisErrors({
      ...currentPsychosisErrors,
      [field]: false,
    });
  };

  const setOtherAssessment = (value: RiskAssessmentValue) => {
    markSectionAsIncomplete();
    setRiskAssessment({
      ...riskAssessment,
      otherAssessment: value.mainQuestion,
      otherAssessmentNotes: value.notes,
    });
  };

  const resetOtherAssessmentErrors = (field: RiskAssessmentField) => {
    setOtherAssessementErrors({
      ...otherAssessementErrors,
      [field]: false,
    });
  };

  const handleAccordionPress = (value: boolean) => {
    setFormState({
      formSections: {
        ...formSections,
        riskAssessment: {
          open: value,
          changed: formSections.riskAssessment.changed,
          completed: formSections.riskAssessment.completed,
        },
      },
    });
  };

  const handleConfirmPress = () => {
    let isError = false;

    if (
      riskAssessment.pastSuicidalIdeation &&
      (!riskAssessment.pastSuicidalIdeationSeverity || !riskAssessment.pastSuicidalIdeationNotes)
    ) {
      isError = true;
      setPastSuicidalIdeationErrors({
        ...pastSuicidalIdeationErrors,
        severity: !riskAssessment.pastSuicidalIdeationSeverity,
        notes: !riskAssessment.pastSuicidalIdeationNotes,
      });
    }

    if (
      riskAssessment.currentSuicidalIdeation &&
      (!riskAssessment.currentSuicidalIdeationSeverity ||
        !riskAssessment.currentSuicidalIdeationNotes)
    ) {
      isError = true;
      setCurrentSuicidalIdeationErrors({
        ...currentSuicidalIdeationErrors,
        severity: !riskAssessment.currentSuicidalIdeationSeverity,
        notes: !riskAssessment.currentSuicidalIdeationNotes,
      });
    }

    if (
      riskAssessment.pastHomicidalIdeation &&
      (!riskAssessment.pastHomicidalIdeationSeverity || !riskAssessment.pastHomicidalIdeationNotes)
    ) {
      isError = true;
      setPastHomicidalIdeationErrors({
        ...pastHomicidalIdeationErrors,
        severity: !riskAssessment.pastHomicidalIdeationSeverity,
        notes: !riskAssessment.pastHomicidalIdeationNotes,
      });
    }

    if (
      riskAssessment.currentHomicidalIdeation &&
      (!riskAssessment.currentHomicidalIdeationSeverity ||
        !riskAssessment.currentHomicidalIdeationNotes)
    ) {
      isError = true;
      setCurrentHomicidalIdeationErrors({
        ...currentHomicidalIdeationErrors,
        severity: !riskAssessment.currentHomicidalIdeationSeverity,
        notes: !riskAssessment.currentHomicidalIdeationNotes,
      });
    }

    if (riskAssessment.pastPsychosis && !riskAssessment.pastPsychosisNotes) {
      isError = true;
      setPastPsychosisErrors({
        ...pastPsychosisErrors,
        notes: !riskAssessment.pastPsychosisNotes,
      });
    }

    if (riskAssessment.currentPsychosis && !riskAssessment.currentPsychosisNotes) {
      isError = true;
      setCurrentPsychosisErrors({
        ...currentPsychosisErrors,
        notes: !riskAssessment.currentPsychosisNotes,
      });
    }

    if (isError) {
      setFormState({ showGlobalError: true });
      return;
    }

    setFormState({
      riskAssessment,
      formSections: {
        ...formSections,
        riskAssessment: {
          open: false,
          changed: true,
          completed: true,
        },
        ...(formSections.substanceUse.completed
          ? {}
          : {
              substanceUse: {
                ...formSections.substanceUse,
                open: true,
              },
            }),
      },
    });
  };

  return (
    <AccordionContainer
      title="Risk assessment"
      open={formSections.riskAssessment.open}
      onPress={handleAccordionPress}
      showCheckMark={formMode !== 'view' && formSections.riskAssessment.completed}
      bodyStyle={{ paddingTop: 0 }}
      dataQa="riskAssessmentAccordion"
    >
      <RiskAssessmentQuestion
        value={{
          mainQuestion: riskAssessment.pastSuicidalIdeation,
          severity: riskAssessment.pastSuicidalIdeationSeverity,
          notes: riskAssessment.pastSuicidalIdeationNotes,
        }}
        setValue={setPastSuicidalIdeation}
        hasSeverity
        placeholder="Past suicidal ideation"
        errors={pastSuicidalIdeationErrors}
        resetErrors={resetPastSuicidalIdeationErrors}
        disabled={formMode === 'view'}
      />
      <RiskAssessmentQuestion
        value={{
          mainQuestion: riskAssessment.currentSuicidalIdeation,
          severity: riskAssessment.currentSuicidalIdeationSeverity,
          notes: riskAssessment.currentSuicidalIdeationNotes,
        }}
        setValue={setCurrentSuicidalIdeation}
        hasSeverity
        placeholder="Current suicidal ideation"
        errors={currentSuicidalIdeationErrors}
        resetErrors={resetCurrentSuicidalIdeationErrors}
        disabled={formMode === 'view'}
      />
      <RiskAssessmentQuestion
        value={{
          mainQuestion: riskAssessment.pastHomicidalIdeation,
          severity: riskAssessment.pastHomicidalIdeationSeverity,
          notes: riskAssessment.pastHomicidalIdeationNotes,
        }}
        setValue={setPastHomicidalIdeation}
        hasSeverity
        placeholder="Past homicidal ideation"
        errors={pastHomicidalIdeationErrors}
        resetErrors={resetPastHomicidalIdeationErrors}
        disabled={formMode === 'view'}
      />
      <RiskAssessmentQuestion
        value={{
          mainQuestion: riskAssessment.currentHomicidalIdeation,
          severity: riskAssessment.currentHomicidalIdeationSeverity,
          notes: riskAssessment.currentHomicidalIdeationNotes,
        }}
        setValue={setCurrentHomicidalIdeation}
        hasSeverity
        placeholder="Current homicidal ideation"
        errors={currentHomicidalIdeationErrors}
        resetErrors={resetCurrentHomicidalIdeationErrors}
        disabled={formMode === 'view'}
      />
      <RiskAssessmentQuestion
        value={{
          mainQuestion: riskAssessment.pastPsychosis,
          severity: null,
          notes: riskAssessment.pastPsychosisNotes,
        }}
        setValue={setPastPsychosis}
        hasSeverity={false}
        placeholder="Past psychosis"
        errors={pastPsychosisErrors}
        resetErrors={resetPastPsychosisErrors}
        disabled={formMode === 'view'}
      />
      <RiskAssessmentQuestion
        value={{
          mainQuestion: riskAssessment.currentPsychosis,
          severity: null,
          notes: riskAssessment.currentPsychosisNotes,
        }}
        setValue={setCurrentPsychosis}
        hasSeverity={false}
        placeholder="Current psychosis"
        errors={currentPsychosisErrors}
        resetErrors={resetCurrentPsychosisErrors}
        disabled={formMode === 'view'}
      />
      {formMode === 'view' && (
        <RiskAssessmentQuestion
          value={{
            mainQuestion: riskAssessment.otherAssessment,
            severity: null,
            notes: riskAssessment.otherAssessmentNotes,
          }}
          setValue={setOtherAssessment}
          hasSeverity={false}
          placeholder="Other risk assessment"
          errors={otherAssessementErrors}
          resetErrors={resetOtherAssessmentErrors}
          disabled
        />
      )}
      {formMode !== 'view' && !formSections.riskAssessment.completed && (
        <ConfirmButton onPress={handleConfirmPress} sectionTitle="riskAssessment" />
      )}
    </AccordionContainer>
  );
};

export default RiskAssessmentSection;
