import SocketService from '@/utils/socket/SocketService';

const USER_TYPING_DEBOUCE_TIMEOUT = 6000; // 6 seconds
const USER_TYPING_DISPLAY_TIMEOUT = 7000; // 7 seconds

export default class UserTypingSocketService {
  roomID: number;

  userTypingTimeout: number | undefined;

  displayTypingIndicatorTimeout: number | undefined;

  displayTypingIndicatorDispatch?: Function;

  io: SocketService;

  constructor(roomID: number, displayTypingIndicatorDispatch?: Function) {
    this.roomID = roomID;
    this.displayTypingIndicatorDispatch = displayTypingIndicatorDispatch;
    this.io = SocketService.instance();
    this.socketInit();
  }

  private socketInit = () => {
    if (!this.displayTypingIndicatorDispatch) return;
    this.io.on('userTyping', this.userTyping);
  };

  public unmount = () => {
    if (!this.displayTypingIndicatorDispatch) return;
    this.io.off('userTyping', this.userTyping);
  };

  private userTyping = (data) => {
    if (!this.displayTypingIndicatorDispatch) return;
    if (this.displayTypingIndicatorTimeout) clearTimeout(this.displayTypingIndicatorTimeout);

    this.displayTypingIndicatorDispatch(true, data.displayName);

    this.displayTypingIndicatorTimeout = window.setTimeout(() => {
      if (!this.displayTypingIndicatorDispatch) return;
      this.displayTypingIndicatorTimeout = undefined;
      this.displayTypingIndicatorDispatch(false);
    }, USER_TYPING_DISPLAY_TIMEOUT);
  };

  public sendTypingEvent = () => {
    if (!this.userTypingTimeout) {
      this.userTypingTimeout = window.setTimeout(() => {
        this.userTypingTimeout = undefined;
      }, USER_TYPING_DEBOUCE_TIMEOUT);
      this.emitUserTyping();
    }
  };

  private emitUserTyping = () => {
    this.io.emit('userTyping', { roomId: this.roomID }).catch(() => undefined);
  };
}
