import * as React from 'react';
import Svg, { G, Path } from 'svgs';
import { useEmotionTheme, EmotionStyle } from '../../core/styled';

interface AudioCalendarProps {
  width?: number;
  height?: number;
  color?: string;
  style?: EmotionStyle;
}

const AudioCalendar: React.VFC<AudioCalendarProps> = ({
  color,
  width = 28,
  height = 30,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'audio calendar';

  return (
    <Svg width={width} height={height} title={titleText} aria-label={titleText} {...otherProps}>
      <G fill="none" fillRule="evenodd">
        <Path
          d="M6.047 0a1.98 1.98 0 011.98 1.98l-.001 2.057h11.652V1.98A1.98 1.98 0 0121.659 0h.054a1.98 1.98 0 011.98 1.98v2.097l-.005.14a5.402 5.402 0 014.018 5.22v15.056a5.4 5.4 0 01-5.4 5.4H5.4a5.4 5.4 0 01-5.4-5.4V9.438a5.402 5.402 0 014.018-5.222 2.164 2.164 0 01-.005-.14V1.98A1.98 1.98 0 015.993 0h.054z"
          fill={colors.purple || color}
        />
        <Path
          d="M10.13 10.587c.48-.354.786-.487 1.31-.576.872-.221 2.486 2.88 1.788 3.678-.262.31-.61.265-1.221.531-.131 1.994 1.963 5.361 3.795 6.114.436-.443.611-.753.96-.886 1.047-.266 3.054 2.57 2.487 3.279-.35.399-.567.62-1.09.93-4.058 2.614-12.13-10.5-8.028-13.07z"
          fill="#FFF"
        />
      </G>
    </Svg>
  );
};

export default AudioCalendar;
