import { Small, RxCircleTwo, HourGlass, StarCircle, BubbleData } from '@talkspace/react-toolkit';
import { Nullable } from 'ts-frontend/types';
import moment from 'moment-timezone';
import {
  getTherapistReminders,
  TherapistReminders,
  curriedCreditType,
  ReminderStatus,
  PsychiatryReminders,
  getPsychiatryReminders,
} from 'ts-frontend/helpers/lvsReminderStatuses';

export type LVSContent = {
  key: string | number;
  message: string | JSX.Element;
  bubble: Nullable<BubbleData>;
  roomID: number;
  roomType: string;
};

const getTherapyContent = ({
  introSessionCompletedAt,
  introSessionNextStartTime,
  therapySessionLastCompletedAt,
  therapySessionNextStartTime,
  therapySessionScheduledAt,
  videoCredits,
  roomType,
  roomID,
  key,
  onPress = () => {},
}: TherapistReminders & {
  key: string;
  roomID: number;
  roomType: string;
  onPress?: () => void;
}): LVSContent => {
  const { status } = getTherapistReminders({
    introSessionCompletedAt,
    introSessionNextStartTime,
    therapySessionLastCompletedAt,
    therapySessionNextStartTime,
    therapySessionScheduledAt,
    videoCredits,
    onDashboard: true,
  });

  const getCreditType = curriedCreditType(videoCredits);
  const {
    creditMinutes: therapyCreditMinute,
    creditAvailable,
    creditTotal,
    validTo,
  } = getCreditType('therapy') ?? {};

  let bubble: Nullable<BubbleData> = null;
  let message: string | JSX.Element = 'No session scheduled';

  switch (status) {
    case ReminderStatus.INTRO_SESSION:
      bubble = {
        key,
        icon: <StarCircle />,
        content: (
          <>
            <Small>Book your 10min</Small>
            <Small>{roomType === 'couplesRoom' && 'couples '} intro session.</Small>
          </>
        ),
        onPress,
      };
      break;
    case ReminderStatus.NEVER_SCHEDULED_1LVS:
      bubble = {
        key,
        icon: <StarCircle />,
        content: (
          <>
            <Small>Let's book your first</Small>
            <Small>
              {therapyCreditMinute ? `${therapyCreditMinute} min ` : ' '}
              {roomType === 'couplesRoom' && 'couples '} live session.
            </Small>
          </>
        ),
        onPress,
      };
      break;
    case ReminderStatus.HAS_SCHEDULED_NONESCHEDULED_1LVS:
      bubble = {
        key,
        icon: <HourGlass />,
        content: (
          <>
            <Small>Book your monthly</Small>
            <Small>{roomType === 'couplesRoom' && 'couples '}therapy session before</Small>
            <Small>
              your credit{' '}
              {validTo ? `expires on ${moment(validTo).format('dddd, MMM D')}` : 'expires.'}
            </Small>
          </>
        ),
        onPress,
      };
      break;
    case ReminderStatus.NEVER_SCHEDULED_NONESCHEDULED_4LVS:
      bubble = {
        key,
        icon: <StarCircle />,
        content: (
          <Small>
            Let's book your first {therapyCreditMinute ? `${therapyCreditMinute} min ` : ' '}
            {roomType === 'couplesRoom' ? 'couples therapy ' : 'live '} session.
          </Small>
        ),
        onPress,
      };
      break;
    case ReminderStatus.HAS_SCHEDULED_XSCHEDULED_4LVS:
      if (therapySessionScheduledAt) {
        message = (
          <Small>
            Your next session is scheduled <br />
            for{' '}
            <Small variant="smallBoldTSBlack" inline>
              {moment(therapySessionScheduledAt).format('dddd, MMM D')}
            </Small>
          </Small>
        );
      }

      if (roomType === 'couplesRoom') {
        bubble = {
          key: status,
          icon: <HourGlass />,
          content:
            creditAvailable === creditTotal ? (
              <Small>
                Book your {creditTotal} couples therapy sessions for the month before your credits
                expire on {moment(validTo).format('MMM D')}
              </Small>
            ) : (
              <Small>
                Book {creditAvailable} more couples therapy{' '}
                {creditAvailable === 1 ? 'session' : 'sessions'} before your{' '}
                {creditAvailable === 1 ? 'credit expires' : 'credits expire'} on{' '}
                {moment(validTo).format('MMM D')}
              </Small>
            ),
          onPress,
        };
      }
      break;
    case ReminderStatus.HAS_SCHEDULED_1SCHEDULED_1LVS:
      if (therapySessionScheduledAt) {
        message = (
          <Small>
            Your next session is scheduled <br />
            for{' '}
            <Small variant="smallBoldTSBlack" inline>
              {moment(therapySessionScheduledAt).format('dddd, MMM D')}
            </Small>
          </Small>
        );
      }

      bubble = {
        key,
        icon: <StarCircle />,
        content: (
          <>
            <Small>Great work! You've</Small>
            <Small>booked your {roomType === 'couplesRoom' && 'couples '} therapy</Small>
            <Small>session for this month</Small>
          </>
        ),
        onPress,
      };
      break;
    case ReminderStatus.HAS_SCHEDULED_4SCHEDULED_4LVS:
      if (therapySessionScheduledAt) {
        message = (
          <Small>
            Your next session is scheduled <br />
            for{' '}
            <Small variant="smallBoldTSBlack" inline>
              {moment(therapySessionScheduledAt).format('dddd, MMM D')}
            </Small>
          </Small>
        );
      }

      if (roomType === 'couplesRoom') {
        bubble = {
          key,
          icon: <StarCircle />,
          content: (
            <>
              <Small>Great work! You've</Small>
              <Small>booked all {creditTotal} of your</Small>
              <Small>couples therapy sessions for this month</Small>
            </>
          ),
          onPress,
        };
      }
      break;
    default:
  }
  return {
    key,
    message,
    bubble,
    roomID,
    roomType,
  };
};

const getPsychiatryContent = ({
  psychiatricEvaluationNextStartTime,
  psychiatryFollowUpNextStartTime,
  psychiatricEvaluationCompletedAt,
  psychiatryFollowUpLastCompletedAt,
  videoCredits,
  roomID,
  roomType,
  key,
  onPress = () => {},
}: PsychiatryReminders & {
  key: string;
  roomID: number;
  roomType: string;
  onPress: () => void;
}): LVSContent => {
  const { status } = getPsychiatryReminders({
    psychiatricEvaluationNextStartTime,
    psychiatryFollowUpNextStartTime,
    psychiatricEvaluationCompletedAt,
    psychiatryFollowUpLastCompletedAt,
    videoCredits,
  });

  let bubble: Nullable<BubbleData> = null;
  let message: string | JSX.Element = 'No session scheduled';

  switch (status) {
    case ReminderStatus.NO_AVAILABLE_PSYCH_CREDIT:
      break;
    case ReminderStatus.SCHEDULED_INITIAL_EVALUATION:
      message = (
        <>
          <Small>Your initial evaluation is</Small>
          <Small>
            scheduled for{' '}
            <Small variant="smallBoldTSBlack" inline>
              {moment(psychiatricEvaluationNextStartTime).format('dddd, MMM D')}
            </Small>
            .
          </Small>
        </>
      );
      break;
    case ReminderStatus.SCHEDULED_FOLLOW_UP:
      message = (
        <>
          <Small>Your next follow up is</Small>
          <Small>
            scheduled for{' '}
            <Small variant="smallBoldTSBlack" inline>
              {moment(psychiatryFollowUpNextStartTime).format('dddd, MMM D')}
            </Small>
            .
          </Small>
        </>
      );
      break;
    case ReminderStatus.NEVER_SCHEDULE_INITIAL_EVALUATION:
      bubble = {
        key,
        icon: <RxCircleTwo />,
        content: (
          <>
            <Small>Book your initial</Small>
            <Small>evaluation to get started</Small>
            <Small>with psychiatry.</Small>
          </>
        ),
        onPress,
      };
      break;
    case ReminderStatus.NEVER_SCHEDULE_FOLLOW_UP:
      bubble = {
        key,
        icon: <RxCircleTwo />,
        content: (
          <>
            <Small>Schedule your next</Small>
            <Small>follow-up session.</Small>
          </>
        ),
        onPress,
      };
      break;
    case ReminderStatus.NEVER_SCHEDULE_FOLLOW_UP_LONGTIME:
      bubble = {
        key,
        icon: <RxCircleTwo />,
        content: (
          <>
            <Small>
              It's been over {moment().diff(moment(psychiatryFollowUpLastCompletedAt), 'months')}{' '}
              months
            </Small>
            <Small>since your last psychiatry</Small>
            <Small>session. Let's book your</Small>
            <Small>next follow-up.</Small>
          </>
        ),
        onPress,
      };
      break;
    default:
  }
  return { key, roomID, message, bubble, roomType };
};

export { getTherapyContent, getPsychiatryContent };
