import { getClientSideGlobal } from '@talkspace/react-toolkit/src/utils/ssr';
import { TFErrorsCommon } from '@talkspace/i18n/types';
import { translateIfNeeded } from '@talkspace/i18n/utils';
import { PasswordInputMode } from '../types/PasswordInput';

const zxcvbn = getClientSideGlobal((w) => w.zxcvbn, undefined); // this library is imported from a cdn for bundle size optimization

export function checkEmailValidity(email: string) {
  const regEx =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regEx.test(email);
}

export function checkNicknameValidity(nickname: string, tErrorsCommon?: TFErrorsCommon) {
  const alphaNumbericRegex = /^[a-zA-Z0-9]+$/;

  if (!alphaNumbericRegex.test(nickname)) {
    return translateIfNeeded(
      tErrorsCommon,
      "Can't contain special characters or spaces.",
      'nicknameValidation.containsSpecialCharacters'
    );
  }

  if (nickname.length < 2) {
    return translateIfNeeded(
      tErrorsCommon,
      'Must be at least 2 characters.',
      'nicknameValidation.tooShort',
      { replace: { min_length: 2 } }
    );
  }

  if (nickname.length > 10) {
    return translateIfNeeded(
      tErrorsCommon,
      'Must be shorter than 11 characters.',
      'nicknameValidation.tooLong',
      { replace: { max_length: 11 } }
    );
  }

  if (!nickname.charAt(0).match(/[a-z]/i)) {
    return translateIfNeeded(
      tErrorsCommon,
      'Must start with a letter.',
      'nicknameValidation.doesNotStartWithALetter'
    );
  }

  return '';
}

export function checkPasswordValidity(
  password: string,
  email?: string,
  nickname?: string,
  options?: {
    passwordInputMode?: PasswordInputMode;
    passwordToCompare?: string;
    ignoreStrengthCheck?: boolean;
  },
  tErrorsCommon?: TFErrorsCommon
): string {
  const { passwordInputMode, passwordToCompare, ignoreStrengthCheck } = options || {};

  if (passwordInputMode === PasswordInputMode.CONFIRM_PASSWORD) {
    if (!password || password.length === 0)
      return translateIfNeeded(
        tErrorsCommon,
        'Please confirm new password.',
        'passwordValidation.passwordConfirmationMissing'
      );
    if (password !== passwordToCompare)
      return translateIfNeeded(
        tErrorsCommon,
        "The passwords you entered don't match.",
        'passwordValidation.passwordConfirmationMismatch'
      );
    return '';
  }
  if (!password || password.length === 0) {
    return translateIfNeeded(
      tErrorsCommon,
      'Please enter a password.',
      'passwordValidation.passwordMissing'
    );
  }
  if (passwordInputMode === PasswordInputMode.LOGIN) {
    // When logging in, support old password by just checking for length !== 0;
    return '';
  }
  if (password.length < 8) {
    return translateIfNeeded(
      tErrorsCommon,
      'Password must be at least 8 characters.',
      'passwordValidation.passwordTooShort',
      { replace: { min_length: 8 } }
    );
  }
  if (password.length > 80) {
    return translateIfNeeded(
      tErrorsCommon,
      'Password must be less than 81 characters.',
      'passwordValidation.passwordTooLong',
      { replace: { max_length: 81 } }
    );
  }
  if (
    !ignoreStrengthCheck &&
    (!passwordInputMode || passwordInputMode === PasswordInputMode.CHANGE_PASSWORD) &&
    typeof zxcvbn === 'function'
  ) {
    const { score } = zxcvbn(password);
    switch (score) {
      case 0:
      case 1:
        return translateIfNeeded(
          tErrorsCommon,
          'Password strength: Weak.',
          'passwordValidation.weakPassword'
        );
      case 2:
        return translateIfNeeded(
          tErrorsCommon,
          'Password strength: So-so.',
          'passwordValidation.mediumPassword'
        );
      default:
        break;
    }
  }
  if (email && password === email) {
    return translateIfNeeded(
      tErrorsCommon,
      'Password cannot be the same as the email address.',
      'passwordValidation.passwordMatchesEmail'
    );
  }
  if (nickname && password === nickname) {
    return translateIfNeeded(
      tErrorsCommon,
      'Password cannot be the same as the nickname.',
      'passwordValidation.passwordMatchesNickname'
    );
  }
  return '';
}
