import useMutationDeleteProgressNote from 'hooks/notes/useMutationDeleteProgressNote';
import useQueryBusinessLine from 'ts-frontend/hooks/useQueryBusinessLine';
import useQueryProgressNote from 'hooks/notes/useQueryProgressNote';
import useQueryRoomDetails from 'ts-frontend/hooks/useQueryRoomDetails';
import { useHistory, useParams } from '@/core/routerLib';
import { getSubmenuTitleAndSubtitle } from './utils';
import NoteDialog from './NoteDialog';

const DeleteProgressNoteDialog = () => {
  const history = useHistory();
  const { roomID, noteID } = useParams<{ roomID: string; noteID: string }>();
  const { data: { clientUserID } = {} } = useQueryRoomDetails(roomID);
  const { data: progressNoteData } = useQueryProgressNote(clientUserID, noteID);
  const isDraft = progressNoteData?.status === 'draft';

  const { mutate: deleteProgressNote, isLoading: isDeleteLoading } =
    useMutationDeleteProgressNote();

  const { data: businessLineData } = useQueryBusinessLine(roomID, clientUserID);

  const handleYesPress = () => {
    deleteProgressNote(
      { roomID, noteID, isDraft },
      {
        onSettled: () => {
          history.push(`/room/${roomID}/notes-tab`);
        },
      }
    );
  };

  const handleNoPress = () => {
    history.goBack();
  };

  const sessionReportPosition = progressNoteData?.sessionReportPosition || 0;

  const { title, subtitle } = getSubmenuTitleAndSubtitle({
    noteType: 'progress',
    formMode: 'edit',
    businessLine: businessLineData,
    sessionReportPosition,
    serviceStartDate: progressNoteData?.serviceStartDate
      ? new Date(progressNoteData.serviceStartDate)
      : null,
    serviceEndDate: progressNoteData?.serviceEndDate
      ? new Date(progressNoteData.serviceEndDate)
      : null,
    isTherapist: true,
  });

  return (
    <NoteDialog
      dialogType="clear"
      title={title}
      subtitle={subtitle}
      isLoading={isDeleteLoading}
      onYesPress={handleYesPress}
      onNoPress={handleNoPress}
    />
  );
};

export default DeleteProgressNoteDialog;
