type GlobalEventName = 'playMedia';
type GlobalEventData = object;

export class GlobalEvent {
  name: GlobalEventName;

  data?: GlobalEventData;

  constructor(name: GlobalEventName, data?: GlobalEventData) {
    this.name = name;
    this.data = data;
  }
}

type Listener = (event: GlobalEvent) => void;
type ListenersSet = Set<Listener>;
type Unlisten = () => void;
type ListenersMap = Map<GlobalEventName, ListenersSet>;

class GlobalEventService {
  listeners: ListenersMap;

  constructor() {
    this.listeners = new Map();
  }

  addEventListener(eventName: GlobalEventName, listener: Listener): Unlisten {
    const listenersMap = this.listeners.get(eventName);
    if (listenersMap) listenersMap.add(listener);
    else this.listeners.set(eventName, new Set<Listener>().add(listener));
    return () => this.removeEventListener(eventName, listener);
  }

  removeEventListener(eventName: GlobalEventName, listener: Listener) {
    const listenersMap = this.listeners.get(eventName);
    if (listenersMap) listenersMap.delete(listener);
  }

  dispatchEvent(event: GlobalEvent) {
    const listenersMap = this.listeners.get(event.name);
    if (listenersMap) listenersMap.forEach((value) => value(event));
  }
}

const globalEventService = new GlobalEventService();
export default globalEventService;
