import { RoomRecord } from 'components/Dashboard/types';
import '../../Dashboard.css';
import { View } from '@talkspace/react-toolkit';
import IDView from '../../CaseLoadMenu/IDView';
import styled from '@/core/styled';
import QuickCaseView from '../../CaseLoadMenu/QuickCaseView';

export const IconsContainer = styled(View)({
  padding: 5,
  gap: 10,
  borderRadius: 6,
  border: '1px solid #D1D6E1',
  alignItems: 'center',
  justifyContent: 'center',
  paddingBottom: 0,
  background: 'white',
});

export const IconWrapper = styled(View)({
  height: 24,
  width: 24,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: 'auto',
  position: 'relative',
});

const DashboardCellRoomActions = ({
  client,
  clientNickname,
  roomID,
  clientUserID,
}: {
  client?: RoomRecord;
  clientNickname: string;
  roomID: number;
  clientUserID: number;
}) => (
  <IconsContainer row style={{ width: !client && 30 }}>
    <IconWrapper>
      <IDView pseudonym={clientNickname} roomID={roomID} userID={clientUserID} isHovering />
    </IconWrapper>
    {client && (
      <IconWrapper>
        <QuickCaseView client={client} isHovering />
      </IconWrapper>
    )}
  </IconsContainer>
);

export default DashboardCellRoomActions;
