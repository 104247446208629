import {
  ChatState,
  REQUEST_GET_MESSAGES,
  REQUEST_GET_MESSAGES_ERROR,
  RECEIVE_GET_MESSAGES,
  REQUEST_GET_ROOM_INFO,
  REQUEST_GET_ROOM_INFO_ERROR,
  RECEIVE_GET_ROOM_INFO,
  RECEIVE_ACKNOWLEDGEMENTS,
  RECEIVE_USER_TYPING,
  REQUEST_GET_STARRED_MESSAGES,
  REQUEST_GET_STARRED_MESSAGES_ERROR,
  RECEIVE_GET_STARRED_MESSAGES,
  RESET_CHAT,
  REQUEST_GET_SHARED_FILES,
  RECEIVE_GET_SHARED_FILES,
  ERROR_GET_SHARED_FILES,
  REQUEST_GET_HISTORICAL_SHARED_FILES,
  CLEAR_SHARED_FILES,
  REQUEST_TOGGLE_STAR_MESSAGE,
  ERROR_TOGGLE_STAR_MESSAGE,
  ChatActionTypes,
  REQUEST_ACTIVE_SESSION_INFO,
  REQUEST_ACTIVE_SESSION_INFO_ERROR,
  RECEIVE_ACTIVE_SESSION_INFO,
  POST_LIVE_SESSION_EVENT,
  RECEIVE_POST_LIVE_SESSION_EVENT_SUCCESS,
  RECEIVE_POST_LIVE_SESSION_EVENT_ALREADY_JOINED,
  POST_LIVE_SESSION_EVENT_ERROR,
  RECEIVE_POST_LIVE_SESSION_EVENT_ALREADY_ENDED,
  REQUEST_SESSION_STATUS,
  REQUEST_SESSION_STATUS_ERROR,
  RECEIVE_SESSION_STATUS,
  ChatBannerType,
} from '../constants/chatTypes';

export const initialState: ChatState = {
  roomID: 0,
  lastMessageID: 0,
  messages: [],
  isFirstGetMessagesCall: true,
  starredMessages: [],
  starredIsUpdating: false,
  starredInitialLoadComplete: false,
  starredIsError: false,
  starredHasReceivedAll: false,
  isUpdating: false,
  isError: false,
  hasReceivedAllMessages: false,
  isLoadingHistorical: false,
  wasLoadingHistorical: false,
  isLoadingActiveSessionInfo: false,
  roomInfo: {
    primaryClientID: 0,
    therapistDisplayName: '',
    userPlanType: '',
    userName: '',
    roomType: undefined,
    therapistUserID: 0,
    expirationDate: null,
    roomStatus: undefined,
    allowedSessionModalities: {
      video: false,
      chat: false,
      audio: false,
    },
    isRoomDischarged: false,
    isChatDisabled: false,
    shouldActivateDueToDischarge: false,
  },
  activeSession: null,
  acks: {
    maxAckReceivedId: 0,
    maxAckReadId: 0,
  },
  userTyping: {
    show: false,
  },
  sharedFiles: [],
  isLoadingSharedFiles: true,
  receivedAllSharedFiles: false,
  userAlreadyJoinedSession: undefined,
  postLiveSessionIsError: false,
  sessionAlreadyEnded: false,
  sessionStatus: undefined,
  sharedBanners: [],
};

const getBanners = (state: ChatState): ChatBannerType[] => {
  const sharedBanners: ChatBannerType[] = [];

  if (state.sessionStatus) {
    sharedBanners.push('sessionStatus');
  }

  return sharedBanners;
};

const chatReducer = (
  state = {
    ...initialState,
    messages: [...initialState.messages],
    starredMessages: [...initialState.starredMessages],
    sharedFiles: [...initialState.sharedFiles],
  },
  action: ChatActionTypes
): ChatState => {
  switch (action.type) {
    case REQUEST_GET_MESSAGES:
      return {
        ...state,
        isUpdating: true,
        isError: false,
        ...action.payload,
      };
    case REQUEST_GET_MESSAGES_ERROR:
      return {
        ...state,
        isUpdating: false,
        isLoadingHistorical: false,
        isFirstGetMessagesCall: false,
        isError: true,
      };
    case RECEIVE_GET_MESSAGES:
      return {
        ...state,
        isUpdating: false,
        isError: false,
        isFirstGetMessagesCall: false,
        ...action.payload,
        userTyping: {
          show: false,
        },
      };
    case REQUEST_GET_ROOM_INFO:
      return {
        ...state,
        isUpdating: true,
        isError: false,
      };
    case REQUEST_GET_ROOM_INFO_ERROR:
      return {
        ...state,
        isUpdating: false,
        isLoadingHistorical: false,
        isError: true,
      };
    case RECEIVE_GET_ROOM_INFO:
      return {
        ...state,
        isUpdating: false,
        isError: false,
        ...action.payload,
      };
    case REQUEST_ACTIVE_SESSION_INFO:
      return {
        ...state,
        isUpdating: true,
        isError: false,
        isLoadingActiveSessionInfo: true,
      };
    case REQUEST_ACTIVE_SESSION_INFO_ERROR:
      return {
        ...state,
        isUpdating: false,
        isLoadingHistorical: false,
        isLoadingActiveSessionInfo: false,
        isError: true,
      };
    case RECEIVE_ACTIVE_SESSION_INFO:
      return {
        ...state,
        isLoadingActiveSessionInfo: false,
        isUpdating: false,
        isError: false,
        ...action.payload,
      };
    case RECEIVE_ACKNOWLEDGEMENTS:
      return {
        ...state,
        isUpdating: false,
        isError: false,
        ...action.payload,
      };
    case RECEIVE_USER_TYPING:
      return {
        ...state,
        isUpdating: false,
        isError: false,
        userTyping: {
          ...action.payload,
        },
      };
    case REQUEST_GET_STARRED_MESSAGES:
      return {
        ...state,
        starredIsUpdating: true,
        starredIsError: false,
      };
    case REQUEST_GET_STARRED_MESSAGES_ERROR:
      return {
        ...state,
        starredIsUpdating: false,
        starredInitialLoadComplete: true,
        starredIsError: true,
      };
    case RECEIVE_GET_STARRED_MESSAGES:
      return {
        ...state,
        starredIsUpdating: false,
        starredInitialLoadComplete: true,
        starredIsError: false,
        ...action.payload,
      };
    case RESET_CHAT:
      return {
        ...initialState,
        isTherapistChat: state.isTherapistChat,
        messages: [...initialState.messages],
        roomID: action.payload,
      };
    case REQUEST_GET_SHARED_FILES:
      return {
        ...state,
        isLoadingSharedFiles: true,
        isError: false,
      };
    case RECEIVE_GET_SHARED_FILES:
      return {
        ...state,
        isLoadingSharedFiles: false,
        isLoadingHistorical: false,
        isError: false,
        ...action.payload,
      };
    case ERROR_GET_SHARED_FILES:
      return {
        ...state,
        isLoadingSharedFiles: false,
        isLoadingHistorical: false,
        isError: true,
      };
    case REQUEST_GET_HISTORICAL_SHARED_FILES:
      return {
        ...state,
        isLoadingHistorical: true,
        isError: false,
      };
    case CLEAR_SHARED_FILES:
      return {
        ...state,
        sharedFiles: [],
        isLoadingSharedFiles: true,
      };
    case REQUEST_TOGGLE_STAR_MESSAGE:
      return {
        ...state,
        ...action.payload,
      };
    case ERROR_TOGGLE_STAR_MESSAGE:
      return {
        ...state,
        ...action.payload,
        isError: true,
      };
    case POST_LIVE_SESSION_EVENT:
      return {
        ...state,
        isUpdating: true,
        isError: false,
        postLiveSessionIsError: false,
        userAlreadyJoinedSession: undefined,
        sessionAlreadyEnded: false,
      };
    case RECEIVE_POST_LIVE_SESSION_EVENT_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        isError: false,
        postLiveSessionIsError: false,
        userAlreadyJoinedSession: false,
      };
    case RECEIVE_POST_LIVE_SESSION_EVENT_ALREADY_JOINED:
      return {
        ...state,
        isUpdating: false,
        isError: false,
        postLiveSessionIsError: false,
        userAlreadyJoinedSession: true,
      };
    case RECEIVE_POST_LIVE_SESSION_EVENT_ALREADY_ENDED:
      return {
        ...state,
        isUpdating: false,
        isError: false,
        postLiveSessionIsError: false,
        sessionAlreadyEnded: true,
        userAlreadyJoinedSession: false,
      };
    case POST_LIVE_SESSION_EVENT_ERROR:
      return {
        ...state,
        isUpdating: false,
        isError: true,
        postLiveSessionIsError: true,
      };
    case REQUEST_SESSION_STATUS:
      return {
        ...state,
        isUpdating: true,
        isError: false,
      };
    case REQUEST_SESSION_STATUS_ERROR: {
      return {
        ...state,
        isUpdating: false,
        isError: true,
      };
    }
    case RECEIVE_SESSION_STATUS:
      return {
        ...state,
        isUpdating: false,
        isError: false,
        ...action.payload,
        sharedBanners: getBanners({ ...state, ...action.payload }),
      };
    default:
      return state;
  }
};

export default chatReducer;
