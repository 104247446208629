import { useQuery } from 'react-query';
import apiHelper from '@/utils/api';
import apiWrapper from '../../utils/apiWrapper';
import { DischargeNoteQueryResponse } from '../../components/Room/CRMContainer/NotesTab/types';
import { dischargeNoteQueryKey } from './queryKeys';

const fetchNote = (clientUserID?: number, noteID?: string) => async () => {
  const { data } = await apiWrapper.get(
    `${apiHelper().apiEndpoint}/v2/clients/${clientUserID}/discharge-notes/${noteID}`
  );
  return data.data;
};

const useQueryDischargeNote = (clientUserID?: number, noteID?: string, enabled: boolean = true) =>
  useQuery<DischargeNoteQueryResponse, Error>({
    queryKey: dischargeNoteQueryKey(clientUserID, noteID),
    queryFn: fetchNote(clientUserID, noteID),
    cacheTime: Infinity,
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!clientUserID && !!noteID && enabled,
  });

export default useQueryDischargeNote;
