import { useState, useCallback } from 'react';
import { TabContainer, TabContent, View, styled } from '@talkspace/react-toolkit';
import { progressNoteTabs } from '../../utils';
import { ProgressNoteTabs } from '../../types';

interface ProgressNoteTabsNavigatorProps {
  handleSelect: (value: ProgressNoteTabs) => void;
  currentTab: ProgressNoteTabs;
}

const Container = styled(View)(({ theme: { colors } }) => {
  return {
    position: 'fixed',
    width: '100%',
    left: 0,
    background: colors.white,
    top: 60,
    zIndex: 999,
  };
});

const ProgressNoteTabsNavigator = ({
  handleSelect,
  currentTab,
}: ProgressNoteTabsNavigatorProps) => {
  const [selectedTab, setSelectedTab] = useState<ProgressNoteTabs>(currentTab);

  const handleToggleTab = useCallback(
    (event) => {
      setSelectedTab(progressNoteTabs[event] as ProgressNoteTabs);
      handleSelect(progressNoteTabs[event] as ProgressNoteTabs);
    },
    [handleSelect]
  );

  return (
    <Container>
      <TabContainer
        tabStyle={{ textAlign: 'center', marginRight: 0, flex: 1 }}
        handleSelect={handleToggleTab}
        currentTab={progressNoteTabs.indexOf(selectedTab)}
      >
        <TabContent title="Write note" />
        <TabContent title="View chat" />
      </TabContainer>
    </Container>
  );
};

export default ProgressNoteTabsNavigator;
