import { View, ExtraHuge } from '@talkspace/react-toolkit';
import styled from '@/core/styled/styled';
import ReviewEmptyIcon from './icons/ReviewEmptyIcon';

const BigCenterView = styled(View)({
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'stretch',
  paddingTop: 50,
  paddingBottom: 150,
});

const ContentRow = styled(View)({
  width: '100%',
  textAlign: 'center',
});

const ReviewsEmptyPlaceholder = () => (
  <BigCenterView justify="center" align="center">
    <ContentRow justify="center" align="center">
      <ReviewEmptyIcon />
      <ExtraHuge style={{ marginTop: 22 }}>You don't have any reviews yet</ExtraHuge>
    </ContentRow>
  </BigCenterView>
);

export default ReviewsEmptyPlaceholder;
