import * as React from 'react';
import moment from 'moment-timezone';
import { View, Large, X as XIcon, useEmotionTheme } from '@talkspace/react-toolkit';
import { TherapistTimeslot } from 'ts-frontend/types';
import styled from '@/core/styled';

const Wrapper = styled(View)({
  width: '100%',
});

const ListContainer = styled(View)(({ theme: { colors } }) => {
  return {
    paddingTop: 8,
    borderBottom: `0.5px solid ${colors.periwinkleGrey}`,
  };
});

const TimeslotRowContainer = styled(View)(({ theme: { colors } }) => {
  return { borderTop: `0.5px solid ${colors.periwinkleGrey}` };
});

const ContentWrapper = styled(View)({
  paddingTop: 12,
  paddingBottom: 12,
});

const IconColumn = styled(View)({
  padding: 8,
  paddingRight: 24,
});

const TimeText = styled(Large)(({ theme: { colors } }) => {
  return {
    fontWeight: 400,
    marginTop: 4,
    color: colors.permaFiord,
  };
});

interface TimeslotRowProps {
  timeslot: TherapistTimeslot;
  isUnavailable?: boolean;
}

const TimeslotRow = ({ timeslot, isUnavailable }: TimeslotRowProps) => {
  const momentStart = moment(timeslot.start).tz(moment.tz.guess());
  const momentEnd = moment(timeslot.end).tz(moment.tz.guess());
  const { colors } = useEmotionTheme();

  return (
    <TimeslotRowContainer>
      <ContentWrapper row>
        {isUnavailable && (
          <IconColumn>
            <XIcon color={colors.red} size={10} />
          </IconColumn>
        )}
        <View>
          <Large variant="largeBoldTSBlack">{momentStart.format('dddd, MMMM D')}</Large>
          <TimeText>
            {momentStart.format('h:mma')} – {momentEnd.format('h:mma z')}
          </TimeText>
        </View>
      </ContentWrapper>
    </TimeslotRowContainer>
  );
};

interface BookingSummaryListProps {
  timeslots: TherapistTimeslot[] | null;
  isUnavailable?: boolean;
}

const BookingSummaryList = ({ timeslots, isUnavailable }: BookingSummaryListProps) => (
  <Wrapper>
    <ListContainer>
      {timeslots?.map((timeslot) => (
        <TimeslotRow timeslot={timeslot} isUnavailable={isUnavailable} />
      ))}
    </ListContainer>
  </Wrapper>
);

export default BookingSummaryList;
