import moment from 'moment';
import { TimeslotByDay } from '../types';

export default function getFirstAvailableDate(
  timeslotsByDay: TimeslotByDay[],
  maxDate?: moment.Moment,
  minDate?: moment.Moment
): moment.Moment | null {
  for (const timeslot of timeslotsByDay) {
    const timeslotDate = moment(timeslot.date, 'YYYY-MM-DD');
    // Early exit since timeslot dates are sorted
    if (maxDate && timeslotDate.isAfter(maxDate, 'day')) break;
    if (!(minDate && timeslotDate.isBefore(minDate, 'day')) && timeslot.timeslots.length) {
      return timeslotDate.startOf('day');
    }
  }
  return null;
}
