import { useCallback, useRef, useState } from 'react';

interface LongPressOptions {
  shouldPreventDefault?: boolean;
  delay?: number;
}

const RIGHT_CLICK = 2;

const useLongPress = (
  onLongPress: (
    event: React.MouseEvent<Element, MouseEvent> | React.TouchEvent<HTMLElement>
  ) => void,
  onClick?: () => void,
  { shouldPreventDefault = true, delay = 300 }: LongPressOptions = {}
) => {
  const [longPressTriggered, setLongPressTriggered] = useState(false);
  const timeout = useRef<number | undefined>();
  const target = useRef<EventTarget | null>(null);

  const isTouchEvent = (event: Event) => 'touches' in event;

  const preventDefault = useCallback(
    (event: Event) => {
      if (shouldPreventDefault && isTouchEvent(event)) {
        event.preventDefault();
      }
    },
    [shouldPreventDefault]
  );

  const start = useCallback(
    (event: React.MouseEvent<Element, MouseEvent> | React.TouchEvent<HTMLElement>) => {
      if ((event as unknown as MouseEvent).button === RIGHT_CLICK) {
        setLongPressTriggered(false);
        return;
      }
      if (shouldPreventDefault && event.target) {
        const eventName = isTouchEvent(event as unknown as Event) ? 'touchend' : 'mouseup';
        event.target.addEventListener(eventName, preventDefault, {
          passive: false,
        });
        target.current = event.target;
      }
      timeout.current = window.setTimeout(() => {
        onLongPress(event);
        setLongPressTriggered(true);
      }, delay);
    },
    [shouldPreventDefault, delay, preventDefault, onLongPress]
  );

  const clear = useCallback(
    (
      event: React.MouseEvent<Element, MouseEvent> | React.TouchEvent<HTMLElement>,
      shouldTriggerClick = true
    ) => {
      timeout.current && clearTimeout(timeout.current);
      shouldTriggerClick && !longPressTriggered && onClick && onClick();
      setLongPressTriggered(false);
      if (shouldPreventDefault && target.current) {
        const eventName = isTouchEvent(event as unknown as Event) ? 'touchend' : 'mouseup';
        target.current.removeEventListener(eventName, preventDefault);
      }
    },
    [longPressTriggered, onClick, shouldPreventDefault, preventDefault]
  );

  return {
    onMouseDown: (e: React.MouseEvent<Element, MouseEvent>) => start(e),
    onTouchStart: (e: React.TouchEvent<HTMLElement>) => start(e),
    onMouseUp: (e: React.MouseEvent<Element, MouseEvent>) => clear(e),
    onMouseLeave: (e: React.MouseEvent<Element, MouseEvent>) => clear(e, false),
    onTouchEnd: (e: React.TouchEvent<HTMLElement>) => clear(e),
  };
};

export default useLongPress;
