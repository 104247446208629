import { View } from '@talkspace/react-toolkit';
import AccumulatedHoursIcon from './Icons/AccumulatedHoursIcon';
import BonusEarnedIcon from './Icons/BonusEarnedIcon';
import HoursAwayFromEarningIcon from './Icons/HoursAwayFromEarningIcon';
import EstimatedPaymentIcon from './Icons/EstimatedPaymentIcon';

const getIcon = (iconType) => {
  switch (iconType) {
    case 'accumulated':
      return <AccumulatedHoursIcon />;
    case 'earned':
      return <BonusEarnedIcon />;
    case 'awayFromEarning':
      return <HoursAwayFromEarningIcon />;
    case 'estimatedBonus':
    case 'estimatedHours':
      return <EstimatedPaymentIcon />;
    default:
      return <></>;
  }
};

const GraphExplanation = ({ iconType, text }: { iconType: string; text: string }) => (
  <View
    style={{
      marginTop: '16px',
      display: 'flex',
      flexDirection: 'row',
    }}
  >
    <View style={{ paddingRight: 14 }}>{getIcon(iconType)}</View>
    <View
      style={{
        width: '90%',
        fontWeight: 400,
        fontSize: 16,
        lineHeight: '20px',
        font: 'roboto',
      }}
    >
      {text}
    </View>
  </View>
);

export default GraphExplanation;
