import { FunctionComponent } from 'react';
import { View, Spinner } from '@talkspace/react-toolkit';
import { InputField } from '../../types';

interface GetFieldParams {
  titleID?: string;
  customStep?: InputField;
  customValue?: unknown;
  customOnChange?: (newValue: unknown) => void;
}

interface Props {
  value?: Record<string, unknown>;
  onChange: (newValue: Record<string, unknown>) => void;
  getField: (params: GetFieldParams) => JSX.Element | null;
  inputFields?: InputField[];
}

const FormInput: FunctionComponent<Props> = ({ value, onChange, getField, inputFields }) => (
  <View>
    {inputFields && value ? (
      inputFields.map((field) => (
        <View style={{ marginBottom: 8 }}>
          {getField({
            customStep: field,
            customValue: value[field.inputState],
            customOnChange: (newValue) => onChange({ ...value, [field.inputState]: newValue }),
          })}
        </View>
      ))
    ) : (
      <Spinner />
    )}
  </View>
);

export default FormInput;
