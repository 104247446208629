import { FunctionComponent } from 'react';
import { View, Standard, Small, Mini, Tiny, BaseButton, COLORS } from '@talkspace/react-toolkit';
import TriangleIcon from './Icons/TriangleIcon';
import CheckIcon from './Icons/CheckIcon';
import styled from '../../core/styled';

export const NOT_AVAILABLE = '—';

interface StyleObject {
  width: number;
  paddingRight?: number;
}

interface AccordionListItemProps {
  isMobile: boolean;
  isLarge: boolean;
  isExpanded: boolean;
  roomID: number | null;
  userNickname: string;
  clientWordsCapped: string;
  therapistWordsCapped: string;
  longTerm?: number | null;
  netTherapistEarning: string;
  onClick: () => void;
  detailRow: any;
  clientWordcountColumnStyles: StyleObject;
  providerWordcountColumnStyles: StyleObject;
  valueBasedBonusColumnStyles: StyleObject;
  amountEarnedColumnStyles: StyleObject;
  amountEarnedPadding: number;
  clientColumnStyles: any;
  isPsych?: boolean;
  isLongTermBonusDisabled?: boolean;
}

const ItemDataArea = styled(View)(() => {
  return {
    borderRadius: 6,
    backgroundColor: 'rgba(239, 243, 250, .62)',
    width: 'auto',
    minWidth: 410,
    alignSelf: 'auto',
    paddingTop: 24,
    paddingLeft: 0,
    paddingBottom: 24,
    paddingRight: 0,
    marginTop: 24,
    marginRight: 0,
    marginBottom: 15,
  };
});

const AccordionListItem: FunctionComponent<AccordionListItemProps> = ({
  userNickname,
  isExpanded,
  onClick,
  roomID,
  clientWordsCapped,
  therapistWordsCapped,
  longTerm,
  netTherapistEarning,
  detailRow,
  clientWordcountColumnStyles,
  providerWordcountColumnStyles,
  valueBasedBonusColumnStyles,
  amountEarnedColumnStyles,
  clientColumnStyles,
  amountEarnedPadding,
  isMobile,
  isLarge,
  isPsych,
  isLongTermBonusDisabled,
}) => {
  const isPaymentAdjustmentRow = detailRow.every(
    (item) =>
      item.paymentDesc === 'Manual Adjustment' || item.payoutItemType === 'manual_adjustment'
  );
  const { width: providerWordcountColumnWidth } = providerWordcountColumnStyles;
  const { width: valueBasedBonusColumnWidth } = valueBasedBonusColumnStyles;
  const { width: clientWordcountColumnWidth } = clientWordcountColumnStyles;

  const paymentDescriptionColumnStyles = {
    width:
      clientWordcountColumnWidth +
      providerWordcountColumnWidth +
      (isLongTermBonusDisabled ? 0 : valueBasedBonusColumnWidth),
  };

  const paymentDescriptionForAdjustmentsStyles = {
    width:
      providerWordcountColumnWidth + (isLongTermBonusDisabled ? 0 : valueBasedBonusColumnWidth),
  };

  const roomIDColumnStyles = clientWordcountColumnStyles;

  const TextComponent = isMobile ? Tiny : Small;
  return (
    <View
      id={`${isPaymentAdjustmentRow ? 'payment-adjustment' : roomID}-toggle-button`}
      style={{
        paddingBottom: 10,
        paddingTop: 11,
        borderBottom: '1px solid #D8D8D8',
        marginLeft: 6,
        marginRight: 6,
        width: 'fit-content',
      }}
    >
      <View style={{ alignItems: 'center' }} onClick={onClick} row>
        <View row style={{ ...clientColumnStyles, paddingLeft: 7 }}>
          <BaseButton style={{ paddingLeft: 3, paddingRight: 10 }}>
            <TriangleIcon style={{ transform: isExpanded ? 'rotate(90deg)' : null }} />
          </BaseButton>
          {isPaymentAdjustmentRow ? (
            <Standard style={{ paddingLeft: 6, marginTop: 7, marginBottom: 8 }}>
              Payment adjustments
            </Standard>
          ) : (
            <View style={{ paddingLeft: 6 }}>
              {!!roomID &&
                (isMobile ? (
                  <Tiny style={{ color: COLORS.black }}>{userNickname}</Tiny>
                ) : (
                  <Standard style={{ color: COLORS.black }}>{userNickname}</Standard>
                ))}
              <Mini>{roomID ? `Room: ${roomID}` : 'Payment adjustments'}</Mini>
            </View>
          )}
        </View>
        <View style={clientWordcountColumnStyles}>
          {!isPsych && (
            <TextComponent
              style={{
                color: clientWordsCapped === NOT_AVAILABLE ? COLORS.slateGrey : COLORS.black,
              }}
            >
              {isPaymentAdjustmentRow ? '' : clientWordsCapped}
            </TextComponent>
          )}
        </View>
        <View style={providerWordcountColumnStyles}>
          {!isPsych && (
            <TextComponent
              style={{
                color: therapistWordsCapped === NOT_AVAILABLE ? COLORS.slateGrey : COLORS.black,
              }}
            >
              {isPaymentAdjustmentRow ? '' : therapistWordsCapped}
            </TextComponent>
          )}
        </View>
        {!isLongTermBonusDisabled && (
          <View style={valueBasedBonusColumnStyles}>{longTerm ? <CheckIcon /> : null}</View>
        )}
        <View style={amountEarnedColumnStyles}>
          <TextComponent
            style={{
              color: netTherapistEarning === NOT_AVAILABLE ? COLORS.slateGrey : COLORS.black,
              textAlign: 'right',
              paddingRight: amountEarnedPadding,
            }}
          >
            {netTherapistEarning}
          </TextComponent>
        </View>
      </View>
      {isExpanded && (
        <ItemDataArea>
          <View row>
            <View>
              <View row>
                <View style={clientColumnStyles}>
                  <TextComponent
                    style={{
                      fontWeight: 'bold',
                      marginRight: isMobile ? 5 : 0,
                      color: COLORS.black,
                    }}
                  >
                    Date
                  </TextComponent>
                </View>
                {isPaymentAdjustmentRow && (
                  <View style={roomIDColumnStyles}>
                    <TextComponent
                      style={{
                        fontWeight: 'bold',
                        marginRight: isMobile ? 5 : 0,
                        color: COLORS.black,
                      }}
                    >
                      Room ID
                    </TextComponent>
                  </View>
                )}
                <View
                  style={
                    isPaymentAdjustmentRow
                      ? paymentDescriptionForAdjustmentsStyles
                      : paymentDescriptionColumnStyles
                  }
                >
                  <TextComponent style={{ fontWeight: 'bold', color: COLORS.black }}>
                    Payment Description
                  </TextComponent>
                </View>
              </View>
              {detailRow.map((item) => (
                <View
                  row
                  style={{ marginTop: 14 }}
                  key={`${item.roomID}-${item.planName}-${item.startDate}-${item.netTherapistEarning}`}
                >
                  <View style={{ ...clientColumnStyles, whiteSpace: 'pre-wrap' }}>
                    <TextComponent
                      style={{
                        color: COLORS.black,
                      }}
                    >
                      {item.detailDateString || NOT_AVAILABLE}
                    </TextComponent>
                  </View>
                  {isPaymentAdjustmentRow && (
                    <View style={roomIDColumnStyles}>
                      <TextComponent
                        style={{
                          color: COLORS.black,
                        }}
                      >
                        {item.roomID || '—'}
                      </TextComponent>
                    </View>
                  )}
                  <View
                    style={
                      isPaymentAdjustmentRow
                        ? paymentDescriptionForAdjustmentsStyles
                        : paymentDescriptionColumnStyles
                    }
                  >
                    <TextComponent
                      style={{
                        color: COLORS.black,
                      }}
                    >
                      {item.publicDescription
                        ? `${item.paymentDesc}: ${item.publicDescription}`
                        : item.paymentDesc}
                    </TextComponent>
                    <TextComponent
                      style={{
                        color: COLORS.black,
                      }}
                    >
                      {item.planName}
                    </TextComponent>
                  </View>
                  <View style={amountEarnedColumnStyles}>
                    <TextComponent
                      style={{
                        color: COLORS.black,
                        textAlign: 'right',
                        paddingRight: amountEarnedPadding,
                      }}
                    >
                      {item.netTherapistEarning}
                    </TextComponent>
                  </View>
                </View>
              ))}
            </View>
          </View>
        </ItemDataArea>
      )}
    </View>
  );
};

export default AccordionListItem;
