import { MediaTypeField } from '../../types/inputTypes';

type Input = string;

export interface InputState {
  inputs: Input[];
  isUpdating: boolean;
  isError: boolean;
  isVideoError: boolean;
  videoErrorRoom: number;
  hasNoCredits: boolean;
  isCallInProgress: boolean;
  videoCallErrorMessage: string | undefined;
}

export interface GetInputPayload {
  inputs: Input[];
}

export interface InputStore {
  input: InputState;
}

export interface PostMessagePayload {
  message: string;
}

export interface PostMediaMessagePayload {
  blob: Blob;
  durationSeconds?: number;
  type: MediaTypeField;
  size?: number;
  name?: string;
  message?: string;
}

export interface RequestCreateVideoCallErrorPayload {
  hasNoCredits?: boolean;
  isCallInProgress?: boolean;
  videoCallErrorMessage?: string;
}

export const REQUEST_POST_MESSAGE = 'REQUEST_POST_MESSAGE';
export const RECEIVE_POST_MESSAGE = 'RECEIVE_POST_MESSAGE';
export const REQUEST_POST_MESSAGE_ERROR = 'REQUEST_POST_MESSAGE_ERROR';

export const REQUEST_POST_MEDIA_MESSAGE = 'REQUEST_POST_MEDIA_MESSAGE';
export const RECEIVE_POST_MEDIA_MESSAGE = 'RECEIVE_POST_MEDIA_MESSAGE';
export const REQUEST_POST_MEDIA_MESSAGE_ERROR = 'REQUEST_POST_MEDIA_MESSAGE_ERROR';

export const REQUEST_CREATE_VIDEO_CALL = 'REQUEST_CREATE_VIDEO_CALL';
export const RECEIVE_CREATE_VIDEO_CALL = 'RECEIVE_CREATE_VIDEO_CALL';
export const REQUEST_CREATE_VIDEO_CALL_ERROR = 'REQUEST_CREATE_VIDEO_CALL_ERROR';
export const REQUEST_CREATE_VIDEO_CALL_ERROR_ROOM = 'REQUEST_CREATE_VIDEO_CALL_ERROR_ROOM';

interface RequestPostMessage {
  type: typeof REQUEST_POST_MESSAGE;
  payload: PostMessagePayload;
}

interface ReceivePostMessage {
  type: typeof RECEIVE_POST_MESSAGE;
}

interface RequestPostMessageError {
  type: typeof REQUEST_POST_MESSAGE_ERROR;
  error: Error;
}

interface RequestPostMediaMessage {
  type: typeof REQUEST_POST_MEDIA_MESSAGE;
  payload: PostMediaMessagePayload;
}

interface ReceivePostMediaMessage {
  type: typeof RECEIVE_POST_MEDIA_MESSAGE;
}
interface RequestPostMediaMessageError {
  type: typeof REQUEST_POST_MEDIA_MESSAGE_ERROR;
  error: Error;
}

export interface RequestCreateVideoCall {
  type: typeof REQUEST_CREATE_VIDEO_CALL;
}

interface ReceiveCreateVideoCall {
  type: typeof RECEIVE_CREATE_VIDEO_CALL;
  payload: {};
}

interface RequestCreateVideoCallError {
  type: typeof REQUEST_CREATE_VIDEO_CALL_ERROR;
  payload: RequestCreateVideoCallErrorPayload;
}

interface RequestCreateVideoCallErrorRoom {
  type: typeof REQUEST_CREATE_VIDEO_CALL_ERROR_ROOM;
  payload: number;
}

export type InputActionTypes =
  | RequestPostMessage
  | ReceivePostMessage
  | RequestPostMessageError
  | RequestPostMediaMessage
  | ReceivePostMediaMessage
  | RequestPostMediaMessageError
  | RequestCreateVideoCall
  | ReceiveCreateVideoCall
  | RequestCreateVideoCallError
  | RequestCreateVideoCallErrorRoom;
