import { useEffect, useRef } from 'react';
import { RemoteUserTracks } from '../types/videoCallTypes';
import PromiseDebouncer from '../utils/PromiseDebouncer';

const useSpeakers = ({
  remoteStreamList,
  selectedAudioOut,
}: {
  remoteStreamList: RemoteUserTracks[];
  selectedAudioOut: MediaDeviceInfo | undefined;
}) => {
  const debouncer = useRef<PromiseDebouncer | null>(null);

  useEffect(() => {
    if (!debouncer.current) {
      debouncer.current = new PromiseDebouncer();
    }

    debouncer.current.debounce(async () => {
      await remoteStreamList.map(async ({ audioTrack }) => {
        if (!audioTrack) {
          return;
        }

        if (selectedAudioOut?.deviceId) {
          await audioTrack.setPlaybackDevice(selectedAudioOut.deviceId);
        }

        if (!audioTrack.isPlaying) {
          audioTrack.play();
        }
      });
    });
  }, [remoteStreamList, selectedAudioOut]);
};

export default useSpeakers;
