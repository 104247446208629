import CaretLeft from './CaretLeft';
import CaretLeftV1 from './CaretLeftV1';
import { VersionMap } from '../../types';
import { withVersioning } from '../../utils';

const versionMap: VersionMap<React.ComponentProps<typeof CaretLeft>> = {
  '1.0.0': CaretLeftV1,
  DefaultComponent: CaretLeft,
};

export default withVersioning(versionMap);
