import View from '../../../components/View';
import { SpacingToken, spacing } from '../../tokens';

const { space0 } = spacing;

export interface SpacingViewProps {
  itemSpacing?: SpacingToken;
  children: React.ReactNode | React.ReactNode[];
  centerContent?: boolean;
  centerAlign?: boolean;
  row?: boolean;
  flex?: number;
  asList?: boolean;
  inheritJustify?: boolean;
  fullWidth?: boolean;
  flexGrow?: 'unset' | 'inherit' | 'initial';
}
const SpacingView = ({
  itemSpacing = 'space100',
  row = true,
  flex = 1,
  children,
  centerContent,
  centerAlign,
  asList,
  inheritJustify,
  fullWidth,
  flexGrow,
}: SpacingViewProps) => (
  <View
    as={asList ? 'ul' : undefined}
    style={
      asList
        ? {
            paddingLeft: space0,
            margin: space0,
            textAlign: centerAlign ? 'center' : undefined,
            flexGrow,
          }
        : { textAlign: centerAlign ? 'center' : undefined, flexGrow }
    }
    flex={flex}
    align={centerAlign ? 'center' : undefined}
    justify={centerContent ? 'center' : 'start'}
  >
    {(Array.isArray(children) ? children : [children]).filter(Boolean).map((item, index) => (
      <View
        as={asList ? 'li' : undefined}
        key={(typeof item === 'object' && item !== null && 'key' in item && item.key) || index}
        style={
          index > 0
            ? { marginTop: spacing[itemSpacing], width: fullWidth ? '100%' : 'auto' }
            : { width: fullWidth ? '100%' : 'auto' }
        }
        row={row}
        flex={1}
        defaultJustify={inheritJustify ? 'inherit' : undefined}
      >
        {item}
      </View>
    ))}
  </View>
);
export default SpacingView;
