import { FunctionComponent } from 'react';
import View from '../View';
import { useWindowWidthState } from '../../hooks/windowWidthContext';

const HeaderBackWrapper: FunctionComponent = ({ children }) => {
  const { isMobile } = useWindowWidthState();

  return (
    <View
      style={{ display: isMobile ? 'absolute' : 'none', left: 22, padding: '12px 5px 5px 5px' }}
    >
      {children}
    </View>
  );
};

export default HeaderBackWrapper;
