import Svg, { Path } from 'svgs';
import { useEmotionTheme, EmotionStyle } from '../../core/styled';

interface Props {
  size?: number;
  color?: string;
  dataQa?: string;
  style?: EmotionStyle;
}

const CalendarCheckMark = ({ size = 53, dataQa, color, style, ...props }: Props) => {
  const { colors } = useEmotionTheme();
  const titleText = 'Calendar with check mark';
  return (
    <Svg
      title={titleText}
      aria-label={titleText}
      data-qa={dataQa}
      width={size}
      height={size}
      viewBox="0 0 53 53"
      fill="none"
      style={style}
      {...props}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.683 4.015h-3.514V2.008a2.008 2.008 0 00-4.015 0v2.007H12.046V2.008a2.008 2.008 0 00-4.015 0v2.007H4.517A4.523 4.523 0 000 8.533v39.15A4.523 4.523 0 004.517 52.2h43.166a4.523 4.523 0 004.517-4.517V8.533a4.523 4.523 0 00-4.517-4.518zM4.517 8.031h3.514v2.008a2.008 2.008 0 004.015 0V8.03h28.108v2.008a2.008 2.008 0 004.015 0V8.03h3.514c.276 0 .502.225.502.502v5.52H4.015v-5.52c0-.277.225-.502.502-.502zm0 40.154h43.166a.503.503 0 00.502-.502V18.069H4.015v29.614c0 .276.225.502.502.502z"
        fill={color || colors.permaTalkspaceDarkGreen}
      />
      <Path
        d="M35.195 22.39a2.008 2.008 0 00-2.767.638l-8.942 14.308-4.302-2.868a2.007 2.007 0 10-2.227 3.34l6.023 4.016a1.999 1.999 0 001.535.292 2.001 2.001 0 001.28-.9l10.038-16.06a2.007 2.007 0 00-.638-2.766z"
        fill={color || colors.permaTalkspaceDarkGreen}
      />
    </Svg>
  );
};

export default CalendarCheckMark;
