import useQueryBusinessLine from 'ts-frontend/hooks/useQueryBusinessLine';
import useQueryRoomDetails from 'ts-frontend/hooks/useQueryRoomDetails';
import { useParams } from '@/core/routerLib';
import AccordionContainer from '../../../Reusable/AccordionContainer/AccordionContainer';
import AccordionMenuItem from '../../../Reusable/AccordionMenuItem/AccordionMenuItem';
import CustomerInformationContainer from './CustomerInformation/CustomerInformationContainer';
import MarketingContainer from './Marketing/MarketingContainer';
import PlanInformation from './Plan/PlanInformation';

function CaseTab({
  isQuickmatch,
  therapistType,
  openStarredMessagesHandler,
  openSharedFilesHandler,
  isClientDischarged,
}) {
  const { roomID } = useParams();
  const { data: { clientUserID } = {} } = useQueryRoomDetails(roomID);
  const { data: businessLineData } = useQueryBusinessLine(roomID, clientUserID);

  return (
    <div className="ts-crm-panel">
      <PlanInformation />
      <CustomerInformationContainer />
      <MarketingContainer />
      <AccordionContainer
        title="Actions"
        childComponents={[
          <AccordionMenuItem
            key={12}
            title="View starred messages"
            clickHandler={openStarredMessagesHandler}
            dataQa="viewStarredMessagesButton"
          />,
          <AccordionMenuItem
            key={13}
            title="View shared files"
            clickHandler={openSharedFilesHandler}
            dataQa="viewSharedFilesButton"
          />,
          <AccordionMenuItem
            key={11}
            path="case-tab"
            view="intake-questionnaire"
            title="Treatment intake questionnaire"
            dataQa="treatmentIntakeQuestionnaireButton"
          />,
          <AccordionMenuItem
            key={14}
            path="case-tab"
            view="eligibility-questionnaire"
            title="Eligibility questionnaire"
            dataQa="eligibilityQuestionnaireButton"
          />,
          <AccordionMenuItem
            key={1}
            path="case-tab"
            view="informed-consent"
            title="Informed consent"
            dataQa="informedConsentButton"
          />,
          <AccordionMenuItem
            key={2}
            path="case-tab"
            view="emergency-contact-login"
            title="Emergency contact login"
            dataQa="emergencyContactLoginButton"
          />,
          <AccordionMenuItem
            key={4}
            path="case-tab"
            view="invites"
            title="Room invitations"
            dataQa="roomInvitationsButton"
          />,
          <AccordionMenuItem
            key={5}
            path="case-tab"
            view="create-room"
            title="Create new room"
            dataQa="createNewRoomButton"
          />,
          <AccordionMenuItem
            key={8}
            path="case-tab"
            view="video-credits"
            title="Live session credits"
            dataQa="liveSessionCreditsButton"
          />,
          <AccordionMenuItem
            key={9}
            hidden={!isQuickmatch}
            path="case-tab"
            view="quick-match-answers"
            title="View QuickMatch answers"
            dataQa="viewQuickMatchAnswersButton"
          />,
          <AccordionMenuItem
            key={15}
            hidden={therapistType !== 'psychiatrist'}
            path="case-tab"
            view="discharge-and-transfer"
            title="Transfer client"
            dataQa="transferClientButton"
          />,
          !!businessLineData?.isBH && isClientDischarged && (
            <AccordionMenuItem
              key={16}
              path="case-tab"
              view="restart-care"
              title="Restart care"
              dataQa="restartCareButton"
            />
          ),
        ]}
      />
    </div>
  );
}

export default CaseTab;
