import { BaseButton, View, Large } from '@talkspace/react-toolkit';
import PlusIcon from './PlusIcon';

const AddTimeOffButton = ({ onPress }: { onPress: () => void }) => (
  <BaseButton onPress={onPress} style={{ paddingTop: 25 }}>
    <View row>
      <PlusIcon />
      <Large style={{ paddingLeft: 10 }} variant="largeBoldWideGreen">
        Add a time off period
      </Large>
    </View>
  </BaseButton>
);

export default AddTimeOffButton;
