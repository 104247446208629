import Svg, { G, Path } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

const VideoCallSettingsMic = ({ width = 18, height = 28, ...otherProps }) => {
  const { colors } = useEmotionTheme();
  const titleText = 'microphone';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 18 28"
      {...otherProps}
    >
      <G fill={colors.bermudaGray} stroke="none" strokeWidth={1} fillRule="evenodd">
        <Path d="M17 10a1 1 0 01.993.883l.006.095H18v3.863c0 4.47-3.502 8.154-8 8.635V26h3.998c.514 0 .937.383.995.883L15 27c0 .552-.456 1-1.002 1H4.002a.999.999 0 110-2H8v-2.523c-4.504-.48-8-4.158-8-8.636v-3.863a1 1 0 011.993-.095l.006.094H2v3.77c0 3.797 3.13 6.86 7 6.86 3.862 0 7-3.072 7-6.86v-3.77A1 1 0 0117 10z" />
        <Path
          d="M3.579 14.625c0 2.913 2.427 5.255 5.42 5.255 2.987 0 5.42-2.353 5.42-5.255v-9.37C14.419 2.342 11.992 0 8.999 0c-2.987 0-5.42 2.353-5.42 5.255v9.37z"
          fillRule="nonzero"
        />
      </G>
    </Svg>
  );
};

export default VideoCallSettingsMic;
