import moment from 'moment-timezone';

export function formatDateShort(date) {
  return moment(date).format('MMM DD');
}

export function formatDateTimeShort(date) {
  return moment(date).format('hh:mmA, MMM DD');
}

export function formatDateMedium(date) {
  return moment(date).format('MMM DD, YYYY');
}

export function formatDateLong(date) {
  return moment(date).format('MMMM DD, YYYY');
}

export function getUTCTime(momentObject, timezone) {
  const momentFormat = 'HH:mm YYYY-MM-DD';
  const datetimeString = momentObject.format(momentFormat);
  const newMomentObject = moment.tz(datetimeString, [momentFormat], timezone);

  return newMomentObject.toISOString();
}
