import { VoidFunctionComponent } from 'react';
import Svg, { Path, Rect, Defs, G } from 'svgs';
import { useEmotionTheme } from '../../core/styled';
import { useUniqueID } from '../../hooks/a11yHelper';

interface Props {
  width?: number;
  height?: number;
}

const PlanDetails: VoidFunctionComponent<Props> = ({ width = 75, height = 95, ...otherProps }) => {
  const titleText = 'Plan Details';
  const filterId = useUniqueID('filterId');
  const lgId = useUniqueID('lgId');
  const { colors } = useEmotionTheme();
  return (
    <Svg
      title={titleText}
      width={width}
      height={height}
      viewBox="0 0 75 95"
      fill="none"
      {...otherProps}
    >
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <G filter={`url(#${filterId})`}>
        <Path
          d="M11.1 67.283V21.7C11.1 16.6742 15.1742 12.6 20.2 12.6H54.9C59.846 12.6 63.9 16.6572 63.9 21.717V67.283C63.9 72.3428 59.846 76.4 54.9 76.4H20.1C15.154 76.4 11.1 72.3428 11.1 67.283Z"
          fill={colors.white}
          stroke={colors.permaWildBlueYonderAprox}
          strokeWidth="5.2"
        />
        <Rect x="22.5" y="50" width="30" height="4" rx="2" fill={colors.permaPeriwinkleGray} />
        <Rect x="22.5" y="59" width="15" height="4" rx="1.95" fill={colors.permaPeriwinkleGray} />
        <Path
          d="M28.8379 33.482L35.946 40.0017L46.8379 27"
          stroke={`url(#${lgId})`}
          strokeWidth="5"
          strokeLinecap="round"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M44.4811 6H47.5C50.2614 6 52.5 8.23858 52.5 11V13C52.5 15.7614 50.2614 18 47.5 18H27.5C24.7386 18 22.5 15.7614 22.5 13V11C22.5 8.23858 24.7386 6 27.5 6H31.5189C31.7743 2.64378 34.5784 0 38 0C41.4216 0 44.2257 2.64378 44.4811 6Z"
          fill={colors.permaPeriwinkleGray}
        />
      </G>
      <Defs>
        <filter
          id={filterId}
          x="0.5"
          y="0"
          width="74"
          height="95"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.0392157 0 0 0 0 0.298039 0 0 0 0.05 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3798_54995" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3798_54995"
            result="shape"
          />
        </filter>
        <linearGradient
          id={lgId}
          x1="-15.1222"
          y1="23.2746"
          x2="-10.4103"
          y2="100.046"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={colors.permaAtlantis} />
          <stop offset="1" stopColor={colors.permaJapaneseLaurelApprox} />
        </linearGradient>
      </Defs>
    </Svg>
  );
};

export default PlanDetails;
