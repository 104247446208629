import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const standardPath =
  'M15.2938 3.69019C15.6844 3.29956 16.3188 3.29956 16.7094 3.69019C17.1 4.08081 17.1 4.71519 16.7094 5.10581L11.4156 10.3964L16.7063 15.6902C17.0969 16.0808 17.0969 16.7152 16.7063 17.1058C16.3156 17.4964 15.6813 17.4964 15.2906 17.1058L10 11.8121L4.70627 17.1027C4.31565 17.4933 3.68127 17.4933 3.29065 17.1027C2.90002 16.7121 2.90002 16.0777 3.29065 15.6871L8.5844 10.3964L3.29377 5.10269C2.90315 4.71206 2.90315 4.08081 3.29377 3.69019C3.6844 3.29956 4.31877 3.29956 4.7094 3.69019L10 8.98081L15.2938 3.69019Z';
const majorPath =
  'M4.85156 4.31494C4.48438 3.95166 3.89062 3.95166 3.52344 4.31494C3.15625 4.67822 3.16016 5.27588 3.52344 5.64307L10.6719 12.7915L3.52344 19.9399C3.15625 20.3071 3.15625 20.9009 3.52344 21.2642C3.89062 21.6274 4.48438 21.6313 4.84766 21.2642L11.9961 14.1157L19.1484 21.2681C19.5156 21.6353 20.1094 21.6353 20.4727 21.2681C20.8359 20.9009 20.8398 20.3071 20.4727 19.9438L13.3242 12.7954L20.4766 5.64307C20.8438 5.27588 20.8438 4.68213 20.4766 4.31885C20.1094 3.95557 19.5156 3.95166 19.1523 4.31885L12.0039 11.4673L4.85156 4.31494Z';

const pathConfig: IconConfig = {
  standard: { default: { path: standardPath } },
  major: { default: { path: majorPath } },
};

const XMarkLarge = withDSIconMaker(pathConfig, 'x mark large');

export default XMarkLarge;
