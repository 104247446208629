import { VersionMap } from '../../types';
import { withVersioning } from '../../utils';
import TimeslotsCalendar from './TimeslotsCalendar';
import TimeslotsCalendarV1 from './TimeslotsCalendarV1';

export type { TimeslotCalendarProps } from './TimeslotsCalendar';

const versionMap: VersionMap<React.ComponentProps<typeof TimeslotsCalendar>> = {
  '1.0.0': TimeslotsCalendarV1,
  DefaultComponent: TimeslotsCalendar,
};

export default withVersioning(versionMap) as typeof TimeslotsCalendar;
