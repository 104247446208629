import { FunctionComponent } from 'react';
import { View, Standard, Tiny, CreditCard } from '@talkspace/react-toolkit';
import { PaymentDetails } from 'ts-frontend/types';

const CreditCardDetails: FunctionComponent<{
  paymentDetails: PaymentDetails;
  cardColor?: string;
}> = ({
  paymentDetails: { cardType, fourLastDigits, expirationMonth, expirationYear, isLink },
  cardColor,
}) => {
  if (isLink) {
    return <View data-qa="myAccountPaymentLinkWithStripePayment">“Link with Stripe” payment</View>;
  }
  return (
    <View data-qa="myAccountPaymentCreditCardDetails" row>
      <CreditCard cardType={cardType} color={cardColor} style={{ marginTop: 5, marginRight: 20 }} />
      <View>
        <Standard style={{ textTransform: 'capitalize' }}>
          {cardType} ending in {fourLastDigits}
        </Standard>
        <Tiny>
          Expires {String(expirationMonth).padStart(2, '0')}/{expirationYear}
        </Tiny>
      </View>
    </View>
  );
};

export default CreditCardDetails;
