import { reasonForLeavingOptions } from '@talkspace/configs';

import { ValueType } from 'react-select';
import { OptionType } from '@talkspace/react-toolkit';
import moment from 'moment';
import { BaseWizardState, baseWizardInitialState, keyValueToValueType } from 'stepWizard';

export type ReasonForLeaving = keyof typeof reasonForLeavingOptions;

export interface State extends BaseWizardState {
  isPsychiatrist: boolean;
  isTherapist: boolean;

  reasonForLeaving: ReasonForLeaving | null;
  otherReason: string | null;

  leavingDatePsychiatrist: moment.Moment;
  leavingDateTherapist: moment.Moment;

  isDifficultyWithPlatformReason: boolean;
  isReimbursementIssuesReason: boolean;
  isFewAmountOfClientReason: boolean;
  isManyAmountOfClientReason: boolean;
  isOtherReason: boolean;
  isTherapistPhasingOut: boolean;

  reasonForLeavingOptions: ValueType<OptionType, true>;

  setSelectedReasonForLeaving: (value, context) => void;
}

export const getInitialState = (
  source: string,
  contextID: string,
  clientUserID: number,
  isPsychiatrist: boolean,
  isTherapist: boolean
): State => {
  return {
    ...baseWizardInitialState(source, contextID),
    clientUserID,
    isPsychiatrist,
    isTherapist,

    reasonForLeaving: null,
    otherReason: null,
    leavingDatePsychiatrist: moment().add(61, 'days'),
    leavingDateTherapist: moment().add(61, 'days'),

    isDifficultyWithPlatformReason: false,
    isReimbursementIssuesReason: false,
    isFewAmountOfClientReason: false,
    isManyAmountOfClientReason: false,
    isOtherReason: false,
    isTherapistPhasingOut: true,

    reasonForLeavingOptions: keyValueToValueType(reasonForLeavingOptions),

    setSelectedReasonForLeaving: () => {},
  };
};
