/* eslint-disable @typescript-eslint/ban-ts-comment */
import Svg, { Defs, Rect, G, Use, Path, Circle } from 'svgs';
import { useEmotionTheme } from '../../core/styled';

interface TwoFactorAuthenticationIconProps {
  width?: number;
  height?: number;
}

const TwoFactorAuthenticationIcon = ({
  width = 85,
  height = 107,
}: TwoFactorAuthenticationIconProps) => {
  const { colors } = useEmotionTheme();
  const titleText = 'two-factor authentication icon';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 85 107"
    >
      <Defs>
        <Rect id="2FA_rect-c" x={0} y={0} width={51} height={73} rx={13} />
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <G transform="translate(-139 -133) translate(157 144)">
          {/* @ts-ignore */}
          <G filter="url(#2FA_filter-a)">
            {/* @ts-ignore */}
            <Use fill="#000" filter="url(#2FA_filter-b)" xlinkHref="#2FA_c" />
            <Use fill="#FFF" xlinkHref="#2FA_c" />
          </G>
          <G transform="translate(38 45)">
            <Rect
              fill={colors.permaViridianGreen}
              x={0}
              y={9.96428571}
              width={26}
              height={21.0357143}
              rx={4.4}
            />
            <Rect
              stroke={colors.permaViridianGreen}
              strokeWidth={4.4}
              x={7.61666667}
              y={2.2}
              width={10.7666667}
              height={15.5285714}
              rx={5.38333333}
            />
            <Path
              d="M13 16.607c1.496 0 2.708 1.24 2.708 2.768 0 1.135-.668 2.11-1.625 2.538v1.914a1.083 1.083 0 01-2.166 0v-1.914a2.771 2.771 0 01-1.625-2.538c0-1.529 1.212-2.768 2.708-2.768z"
              fill="#FFF"
            />
          </G>
          <Path
            d="M35.22 18.78a8.033 8.033 0 011.799 2.685 8.209 8.209 0 01.014 6.164 7.963 7.963 0 01-1.794 2.69 7.576 7.576 0 01-4.044 2.142c-1.45.272-2.976.158-4.58-.344l-.37-.122-.019 3.496c0 .363-.12.666-.358.91-.204.21-.45.33-.736.36l-.147.008-3.795-.01v2.573a1.21 1.21 0 01-.349.901c-.204.21-.454.33-.75.36l-.151.007h-5.29c-.35 0-.637-.124-.86-.371a1.462 1.462 0 01-.358-.72l-.023-.168-.172-2.618a1.195 1.195 0 01.127-.68 2.24 2.24 0 01.275-.419l.105-.115 8.07-8.07a7.739 7.739 0 01-.547-3.066 8.2 8.2 0 012.414-5.612 7.995 7.995 0 012.7-1.803 8.221 8.221 0 013.079-.597c1.057 0 2.08.202 3.07.606a8.138 8.138 0 012.69 1.812zm-2.744 2.744a2.112 2.112 0 00-1.535-.648 2.08 2.08 0 00-1.535.639 2.09 2.09 0 00-.64 1.544c.004.6.217 1.113.64 1.535.422.423.934.636 1.535.64a2.09 2.09 0 001.544-.64c.423-.422.633-.934.63-1.535a2.107 2.107 0 00-.639-1.535z"
            fill={colors.permaLividBlue}
            fillRule="nonzero"
          />
          <Path
            d="M30.093 73H13C5.82 73 0 67.18 0 60V13C0 5.82 5.82 0 13 0h25c7.18 0 13 5.82 13 13v25.22"
            stroke={colors.permaLividBlue}
            strokeWidth={5}
            strokeLinecap="round"
          />
          <Circle fill={colors.permaLividBlue} cx={25.5} cy={58.5} r={4.5} />
        </G>
      </G>
    </Svg>
  );
};

export default TwoFactorAuthenticationIcon;
