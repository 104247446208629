import { NativeSettings, IOSSettings } from 'capacitor-native-settings';
import { getIonicPlatform } from '../capacitor';
import { safeIonicWrapper } from '../../ionicUtils';
import { ionicLogger } from '../../loggers';

// eslint-disable-next-line import/prefer-default-export
export const openAppSettings = safeIonicWrapper(() => {
  if (getIonicPlatform() === 'ios') {
    NativeSettings.openIOS({
      option: IOSSettings.App,
    });
  } else {
    ionicLogger.warn('openAppSettings not implemented for Platform: ', getIonicPlatform());
  }
}, undefined);

export { ionicLogger };
