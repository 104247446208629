import { ReactNode } from 'react';
import {
  AnimatedSwipeDownModal,
  TooltipV2,
  TouchableView,
  useEmotionTheme,
  useWindowWidth,
} from '@talkspace/react-toolkit';
import { CircleQuestion } from '@talkspace/react-toolkit/src/designSystems/icons';

const tooltipContainerStyles = {
  left: 20,
  width: 354,
  top: 0,
  padding: 0,
};

interface ResponsiveTooltipProps {
  isOpen: boolean;
  onDrawerOpen: () => void;
  onDrawerClose: () => void;
  legendContent: ReactNode;
}

const ResponsiveTooltip = ({
  isOpen,
  onDrawerOpen,
  onDrawerClose,
  legendContent,
}: ResponsiveTooltipProps) => {
  const { isMobile } = useWindowWidth();
  const { spacing } = useEmotionTheme();

  if (isMobile) {
    return (
      <>
        <TouchableView
          style={{ marginLeft: -1 * spacing('space050', false) }}
          onPress={onDrawerOpen}
        >
          <CircleQuestion colorType={isOpen ? 'brand' : 'subtlest'} />
        </TouchableView>

        <AnimatedSwipeDownModal
          isOpen={isOpen}
          onClose={onDrawerClose}
          containerStyles={{ padding: 0 }}
          shouldDisplayCloseButton={false}
        >
          {legendContent}
        </AnimatedSwipeDownModal>
      </>
    );
  }

  return (
    <TooltipV2
      shouldDisplayCloseButton={false}
      containerStyle={tooltipContainerStyles}
      styles={{ marginLeft: -1 * spacing('space050', false) }}
      toolTipText={legendContent}
      isTooltipOpen={isOpen}
    />
  );
};

export default ResponsiveTooltip;
