import Svg, { Circle, Path } from 'svgs';

const BonusMilestonePredictedIcon = () => (
  <Svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Circle cx="15" cy="15" r="13.5" fill="#3C8703" stroke="white" stroke-width="3" />
    <Path
      d="M21.2876 10.5218C21.2855 10.5177 21.2827 10.5138 21.2793 10.5104C20.8955 10.1266 20.2723 10.1266 19.8885 10.5104C19.8885 10.5104 19.8885 10.5104 19.8885 10.5104L13.0824 17.3136L10.0264 14.2604C9.6426 13.8766 9.01938 13.8766 8.6356 14.2604C8.25182 14.6442 8.25182 15.2674 8.6356 15.6512L12.3856 19.4012C12.7694 19.785 13.3926 19.785 13.7764 19.4012L21.2764 11.9012C21.6564 11.5212 21.6601 10.9064 21.2876 10.5218Z"
      fill="white"
      stroke="white"
      stroke-width="0.09"
      stroke-linejoin="round"
    />
  </Svg>
);

export default BonusMilestonePredictedIcon;
