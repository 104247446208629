import { useContext, createContext } from 'react';
import * as React from 'react';
import useWindowWidth from './useWindowWidth';
import type { WindowWidthValues } from './useWindowWidth';
import { SemanticVersion } from '../utils/semanticVersioning';

export const WindowWidthStateContext = createContext<WindowWidthValues | typeof useWindowWidth>(
  useWindowWidth
);

export const WindowWidthContextProvider: React.FC<{ version?: SemanticVersion | string }> = ({
  children,
  version,
}) => {
  const windowWidth = useWindowWidth(version || ('' as SemanticVersion));

  return (
    <WindowWidthStateContext.Provider value={windowWidth}>
      {children}
    </WindowWidthStateContext.Provider>
  );
};

export const useWindowWidthState = (): WindowWidthValues => {
  const context = useContext(WindowWidthStateContext);
  if (typeof context === 'function')
    // Use the `useWindowWidth` hook if there is no context provider being used.
    return context();
  return context;
};
