import { useQuery } from 'react-query';
import apiHelper from '@/utils/api';
import apiWrapper from '../utils/apiWrapper';
import { referralConditionsQueryKey } from './queryKeys';

interface Referral {
  id: number;
  condition: string;
}

const fetchReferralConditions = () => async () => {
  const { data } = await apiWrapper.get(
    `${apiHelper().apiEndpoint}/v2/therapist/referral-conditions`
  );
  return data.data;
};

const useQueryReferralConditions = () =>
  useQuery<Referral[], Error>({
    queryKey: referralConditionsQueryKey,
    queryFn: fetchReferralConditions(),
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

export default useQueryReferralConditions;
