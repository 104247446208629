import { Dispatch } from 'react';
import { connect } from 'react-redux';
import { NavLink, Redirect, Route, RouteComponentProps } from 'react-router-dom';
import {
  Large,
  PanelManager,
  TabRadioGroup,
  useEmotionTheme,
  useWindowWidthState,
  View,
} from '@talkspace/react-toolkit';
import { TherapistType } from 'ts-frontend/entities/Therapist';
import { useFlags } from 'launchDarkly/FlagsProvider';

import NavBar from '../../../components/Reusable/NavBar/NavBar';
import ReviewsIcon from '../../../components/Icons/ReviewsIcon';
import styled from '../../../core/styled';

import { PerformanceProvider } from '../hooks/performanceContext';

import UtilizationMetricsContainer from '../containers/UtilizationMetricsContainer';
import ReviewsView from './ReviewsView';

const TabViewContainer = styled(View)(({ theme: { colors } }) => {
  return {
    height: 'auto',
    overflowY: 'hidden',
    marginBottom: 60,
    backgroundColor: colors.athensGrey,
  };
});

const PanelTopContainer = styled(View)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      width: 'auto',
      padding: isMobile ? '10px 10px 0 10px' : '20px 20px 0 20px',
    };
  }
);

const PanelTop = styled(View)(
  ({
    theme: {
      colors,
      window: { isMobile },
    },
  }) => {
    return {
      width: 'auto',
      backgroundColor: colors.white,
      borderColor: colors.extraLightGrey,
      borderWidth: '1px 1px 0 1px',
      borderStyle: 'solid',
      borderRadius: '5px 5px 0 0',
      paddingTop: isMobile ? 24 : 34,
    };
  }
);

interface TabLinkProps extends RouteComponentProps {
  to: string;
  text: string;
  icon: (props: { color: string; title: string }) => JSX.Element;
}

const TabLinkContainer = styled(View)<{
  textColor: string;
}>(({ textColor }) => {
  return {
    padding: 10,
    color: textColor,
    '& > div > div > p': {
      color: textColor,
    },
  };
});

const TabLinkMenu = styled(View)({
  padding: '0 20px',
});

const TabLink = ({ match, to, text, icon: TabIcon, ...props }: TabLinkProps) => {
  const { colors } = useEmotionTheme();
  const linkTo = `${match.url}${to}`;
  const isActive = window.location.pathname === linkTo;
  const textColor = isActive ? colors.darkGreen : colors.baliHaiGrey;

  return (
    <NavLink {...props} to={linkTo} isActive={() => isActive} style={{ textDecoration: 'none' }}>
      <TabLinkContainer row textColor={textColor}>
        <TabIcon color={textColor} title={text} />
        {isActive ? (
          <Large style={{ marginLeft: 5 }} variant="largeBoldWideGreen">
            {text}
          </Large>
        ) : (
          <Large style={{ marginLeft: 5 }} variant="largeLightGrey">
            {text}
          </Large>
        )}
      </TabLinkContainer>
    </NavLink>
  );
};

const PerformanceContainer = styled(View)(({ theme: { colors } }) => {
  return {
    padding: 0,
    height: 'auto',
    backgroundColor: colors.athensGrey,
  };
});

interface PerformanceProps extends RouteComponentProps {
  dispatch: Dispatch<any>;
  therapistType: TherapistType;
  isW2: boolean;
}

const Performance = ({ therapistType, isW2, history, match, location }: PerformanceProps) => {
  const { isMobile } = useWindowWidthState();
  const { nppClinicalHours: isNPPClinicalHoursActive } = useFlags();
  const isPsych = therapistType === 'psychiatrist';
  const routerProps = { history, match, location };
  const showMetrics = !isPsych;
  const showOldUtilizationMetrics = isW2 && !isNPPClinicalHoursActive;

  return (
    <PerformanceProvider>
      <PerformanceContainer>
        <PanelManager>
          <NavBar />
        </PanelManager>
        {showOldUtilizationMetrics ? <UtilizationMetricsContainer /> : null}
        {!showMetrics ? (
          <View style={{ marginTop: 10 }}>
            <ReviewsView />
          </View>
        ) : (
          <TabViewContainer>
            <PanelTopContainer>
              <PanelTop>
                <TabRadioGroup
                  initialSelection
                  isTabList
                  legendText="Choose performance or metrics view"
                >
                  <TabLinkMenu row justify={isMobile ? 'center' : 'start'}>
                    <TabLink {...routerProps} icon={ReviewsIcon} to="/reviews" text="Reviews" />
                  </TabLinkMenu>
                </TabRadioGroup>
              </PanelTop>
            </PanelTopContainer>
            <View>
              <Route
                exact
                path="/performance"
                render={() => <Redirect push to="/performance/reviews" />}
              />
              <Route path="/performance/reviews" component={ReviewsView} />
            </View>
          </TabViewContainer>
        )}
      </PerformanceContainer>
    </PerformanceProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    therapistType: state.availability.therapistType,
    isW2: state.availability.isW2,
  };
};

const ConnectedPerformance = connect(mapStateToProps)(Performance);

export default ConnectedPerformance;
