import { useState } from 'react';

import SearchGooglePlacesInput from '../SearchGooglePlacesInput';

interface Props {
  onSelectCallback: (value: string) => void;
}
const SchoolSearch = ({ onSelectCallback }: Props) => {
  const [searchText, setSearchText] = useState('');

  const onSelect = (school: string) => {
    setSearchText(school);
    onSelectCallback(school);
  };

  const onChange = (value) => {
    setSearchText(value);
  };

  return (
    <SearchGooglePlacesInput
      label="School"
      placeholder="Search schools..."
      dataQa="schoolSearch"
      value={searchText}
      searchOptions={{
        types: ['school'],
      }}
      onChange={onChange}
      onSelect={onSelect}
      error={undefined}
    />
  );
};

export default SchoolSearch;
