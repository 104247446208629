import { useMutation, useQueryClient } from 'react-query';
import apiHelper from '@/utils/api';
import apiWrapper from '../../utils/apiWrapper';
import { collateralNoteQueryKey, notesListQueryKey } from './queryKeys';

export interface CollateralNoteMutateVariables {
  roomID: string;
  noteID?: string;
  clientUserID?: number;
  contactDate: Date | null;
  contactName: string | null;
  contactRelationship: string | null;
  releaseObtained: boolean | null;
  missingObtainedReleaseReason: string | null;
  contactSummary: string | null;
  statementCertified: boolean;
}

const saveDraftCollateralNote = async (params: CollateralNoteMutateVariables): Promise<void> => {
  const { roomID, noteID, clientUserID, ...requestBody } = params;

  const requestURL = noteID
    ? `/v2/rooms/${roomID}/collateral-notes/${noteID}/draft`
    : `/v2/rooms/${roomID}/collateral-notes/draft`;

  const method: keyof typeof apiWrapper = noteID ? 'patch' : 'post';

  await apiWrapper[method](`${apiHelper().apiEndpoint}${requestURL}`, requestBody);
};

const useMutationSaveDraftCollateralNote = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, CollateralNoteMutateVariables>(saveDraftCollateralNote, {
    onSuccess: (_, { roomID, noteID, clientUserID }) => {
      queryClient.invalidateQueries(notesListQueryKey(roomID));
      if (noteID) {
        queryClient.invalidateQueries(collateralNoteQueryKey(clientUserID, noteID));
      }
    },
  });
};

export default useMutationSaveDraftCollateralNote;
