import getGridStyles from './getGridStyles';
import styled, { EmotionStyle } from '../../core/styled';
import { useWindowWidthState } from '../../hooks/windowWidthContext';
import View, { ViewProps } from '../View';

const rowStyles = {
  noGutters: {
    marginRight: 0,
    marginLeft: 0,
    '& > [data-eg-col]': {
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
};

export interface GridRowProps extends Pick<ViewProps, 'align' | 'justify'> {
  noGutters?: boolean;
  children: React.ReactNode;
  gutterWidth?: number;
  className?: string;
  style?: EmotionStyle;
}

type RowViewProps = {
  gutterWidth: number;
} & Pick<GridRowProps, 'noGutters'>;

const RowView = styled(View)<RowViewProps>(({ noGutters, gutterWidth }) => {
  return {
    boxSizing: 'border-box',
    display: 'flex',
    flexWrap: 'wrap',
    marginRight: gutterWidth / -2,
    marginLeft: gutterWidth / -2,
    ...(noGutters ? rowStyles.noGutters : {}),
  };
});

const GridRow = ({
  noGutters,
  align,
  justify,
  children,
  gutterWidth,
  className,
  style,
}: GridRowProps) => {
  const window = useWindowWidthState();
  const defaults = getGridStyles(window);
  return (
    <RowView
      row
      align={align}
      justify={justify}
      gutterWidth={gutterWidth || defaults.gutterWidth}
      noGutters={noGutters}
      data-eg-row="true"
      className={className}
      style={style}
    >
      {children}
    </RowView>
  );
};

export default GridRow;
