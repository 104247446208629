import { FunctionComponent } from 'react';
import { Standard, styled, TouchableView, View, EmotionTheme } from '@talkspace/react-toolkit';
import Footer from 'components/Reusable/Footer/Footer';
import AlertContainer from 'components/Reusable/AlertContainer/AlertContainer';
import NoteFormTooltip from './NoteFormTooltip';
import trashIcon from '../../../Icons/trash.svg';
import { FormMode, NoteError, NoteStatus } from './types';

interface ButtonProps {
  theme: EmotionTheme;
  disabled?: boolean;
}

const FooterWrapper = styled(View)({
  justifyContent: 'space-between',
  flexDirection: 'row',
});

const TrashIcon = styled(TouchableView)({
  width: 18,
  height: 22,
  background: `url(${trashIcon})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  marginLeft: 15,
});

const SaveDraftButton = styled(TouchableView)({
  paddingTop: 5,
  paddingBottom: 5,
  alignItems: 'center',
  justifyContent: 'center',
  width: 77,
  marginRight: 20,
});

const CancelSaveButton = styled(TouchableView)({
  paddingTop: 5,
  paddingBottom: 5,
  alignItems: 'center',
  justifyContent: 'center',
  width: 49,
  marginRight: 33,
});

const CancelButtonText = styled(Standard)(({ theme: { colors } }) => {
  return {
    paddingTop: 5,
    paddingBottom: 5,
    color: colors.permaSilverSand,
    fontWeight: 700,
  };
});

const SaveButton = styled(TouchableView)({
  paddingTop: 5,
  paddingBottom: 5,
  alignItems: 'center',
  justifyContent: 'center',
  width: 34,
  marginRight: 26,
});

const SubmitButton = styled(TouchableView)({
  paddingTop: 5,
  paddingBottom: 5,
  width: 52,
  marginRight: 28,
});

const CloseButton = styled(TouchableView)(({ theme: { colors } }) => {
  return {
    paddingTop: 5,
    paddingBottom: 5,
    color: colors.green,
    fontWeight: 900,
  };
});

const EditButton = styled(TouchableView)({
  paddingTop: 5,
  paddingBottom: 5,
  alignItems: 'center',
  justifyContent: 'center',
  width: 27,
  marginRight: 26,
});

const SaveButtonText = styled(Standard)(({ theme: { colors }, disabled }: ButtonProps) => {
  return {
    color: disabled ? colors.permaLightSlateGray : colors.greenText,
  };
});

interface Props {
  formMode: FormMode;
  noteStatus?: NoteStatus;
  canSubmit?: boolean;
  cannotSubmitReason?: string | null;
  hasChanged?: boolean;
  canDeleteDraft: boolean;
  canDeleteSubmitted: boolean;
  isError: boolean;
  isLoading: boolean;
  showSpinner: boolean;
  spinnerMessage: string;
  showErrorBanner: boolean;
  editHidden?: boolean;
  handleCloseErrorBannerPress: () => void;
  handleDeleteDraftPress: () => void;
  handleSaveDraftPress: () => void;
  handleSubmitPress: () => void;
  handleCancelPress: () => void;
  handleSavePress: () => void;
  handleEditPress: () => void;
  handleErrorAlertPress?: () => void;
  showErrorAlert?: boolean;
  error?: NoteError;
}

const NoteFormFooter: FunctionComponent<Props> = ({
  formMode,
  noteStatus,
  canSubmit = true,
  cannotSubmitReason,
  hasChanged = true,
  canDeleteDraft,
  canDeleteSubmitted,
  isError,
  isLoading,
  showSpinner,
  spinnerMessage,
  showErrorBanner,
  editHidden,
  handleCloseErrorBannerPress,
  handleDeleteDraftPress,
  handleSaveDraftPress,
  handleSubmitPress,
  handleCancelPress,
  handleSavePress,
  handleEditPress,
  handleErrorAlertPress,
  showErrorAlert,
  error,
}) => {
  const renderFooterChildren = () => [
    <FooterWrapper>
      {(() => {
        if (showErrorAlert) {
          return (
            <AlertContainer
              title={error?.title || 'Missing information'}
              childComponents={[
                <CloseButton onPress={handleErrorAlertPress} dataQa="closeButton">
                  Close
                </CloseButton>,
              ]}
              body={error?.message || 'Review and complete the required fields to continue'}
              containerStyle={{
                width: 355,
                border: 'none',
              }}
            />
          );
        }
        if (showErrorBanner) {
          return (
            <AlertContainer
              title="Missing information"
              childComponents={[
                <CloseButton onPress={handleCloseErrorBannerPress} dataQa="closeButton">
                  Close
                </CloseButton>,
              ]}
              body="Review and complete the required fields to continue"
              containerStyle={{
                width: 355,
                border: 'none',
              }}
            />
          );
        }
        if (formMode === 'create' || noteStatus === 'draft') {
          return (
            <>
              {canDeleteDraft ? (
                <TrashIcon dataQa="deleteButton" key={1} onPress={handleDeleteDraftPress} />
              ) : (
                <View />
              )}
              {hasChanged || canSubmit ? (
                <View style={{ flexDirection: 'row' }}>
                  <SaveDraftButton key={2} onPress={handleSaveDraftPress}>
                    <SaveButtonText variant="standardBoldGreen" dataQa="saveDraftButton">
                      Save draft
                    </SaveButtonText>
                  </SaveDraftButton>
                  <NoteFormTooltip description={cannotSubmitReason}>
                    <SubmitButton
                      dataQa="submitButton"
                      key={3}
                      onPress={handleSubmitPress}
                      disabled={!canSubmit}
                    >
                      <SaveButtonText disabled={!canSubmit} variant="standardBoldGreen">
                        Submit
                      </SaveButtonText>
                    </SubmitButton>
                  </NoteFormTooltip>
                </View>
              ) : (
                <View />
              )}
            </>
          );
        }
        if (noteStatus === 'submitted') {
          if (formMode === 'edit') {
            return (
              <>
                {canDeleteSubmitted ? (
                  <TrashIcon dataQa="deleteButton" key={1} onPress={handleDeleteDraftPress} />
                ) : (
                  <View />
                )}
                <View style={{ flexDirection: 'row' }}>
                  <CancelSaveButton dataQa="cancelButton" key={2} onPress={handleCancelPress}>
                    <CancelButtonText>Cancel</CancelButtonText>
                  </CancelSaveButton>
                  <NoteFormTooltip description={cannotSubmitReason}>
                    <SaveButton
                      dataQa="saveButton"
                      key={3}
                      onPress={handleSavePress}
                      disabled={!canSubmit}
                    >
                      <SaveButtonText disabled={!canSubmit} variant="standardBoldGreen">
                        Save
                      </SaveButtonText>
                    </SaveButton>
                  </NoteFormTooltip>
                </View>
              </>
            );
          }

          if (formMode === 'view') {
            return (
              <>
                <View />
                <View style={{ flexDirection: 'row' }}>
                  <EditButton dataQa="editButton" onPress={handleEditPress} hide={editHidden}>
                    <SaveButtonText variant="standardBoldGreen">Edit</SaveButtonText>
                  </EditButton>
                </View>
              </>
            );
          }
        }
        return null;
      })()}
    </FooterWrapper>,
  ];

  const showFooter =
    showErrorBanner || hasChanged || canDeleteDraft || formMode === 'view' || canSubmit;

  return showFooter ? (
    <Footer
      childComponents={renderFooterChildren()}
      isError={isError}
      isUpdating={isLoading}
      showSpinner={showSpinner}
      spinnerMessage={spinnerMessage}
      footerChildStyle={{ width: '100%' }}
      footerStyle={{ height: showErrorBanner || showErrorAlert ? 'auto' : undefined }}
    />
  ) : (
    <></>
  );
};

export default NoteFormFooter;
