type Configuration = {
  endpoint?: string;
  region: 'local' | 'us-east-1';
  userPoolId: string;
  userPoolWebClientId: string;
};

type Environment = 'canary' | 'local' | 'prod' | 'test';

const configurationByEnvironment: Record<Environment, Configuration> = {
  local: {
    endpoint: 'http://127.0.0.1:9229',
    region: 'local',
    userPoolId: 'local_local',
    userPoolWebClientId: '2qwit54bdmi89ymk4ffujnjmv',
  },
  test: {
    region: 'us-east-1',
    userPoolId: 'us-east-1_Fs8akyFdv',
    userPoolWebClientId: '5d3c7kdp8utnhc15b5aqif1bnt',
    // identityPoolId: 'us-east-1:db33d5f6-5718-4167-9884-d70d5bbeefe4',
  },
  canary: {
    region: 'us-east-1',
    userPoolId: 'us-east-1_RihQq7BLL',
    userPoolWebClientId: '322cqur2o9qi4e5er4i5nou2be',
    // identityPoolId: 'us-east-1:24424135-9965-4fa4-b006-45e8376b4856',
  },
  prod: {
    region: 'us-east-1',
    userPoolId: 'us-east-1_V5w75yjLZ',
    userPoolWebClientId: '56tockos7jmbkqms5tvv6ekjgb',
    // identityPoolId: 'us-east-1:4bcf328f-3b38-4b91-8bd6-39dc69ebf241',
  },
};

const getCurrentEnvironment = (): Environment => {
  if (window.location.host.match(/^(localhost|127[.]0[.]0[.]1)(:[0-9]+)?$/)) {
    return 'local';
  }

  if (window.location.host.match(/dev[.]talkspace[.]com$/)) {
    return 'test';
  }

  if (window.location.host.match(/canary[.]talkspace[.]com$/)) {
    return 'canary';
  }

  return 'prod';
};

const currentEnvironment = getCurrentEnvironment();
const configuration = configurationByEnvironment[currentEnvironment];

export default configuration;
