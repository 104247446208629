import { useState, useEffect, useRef } from 'react';
import debounce from 'lodash/debounce';

export default function useWindowWidth() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(document.documentElement.clientHeight);

  const windowHeightDebounce = useRef(
    debounce(
      () => {
        setWindowHeight(document.documentElement.clientHeight);
      },
      300,
      { trailing: true }
    )
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      windowHeightDebounce.current();
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isSmall = windowWidth <= 375;
  const isMedium = windowWidth > 375 && windowWidth <= 750;
  const isLarge = windowWidth > 750 && windowWidth <= 1125;
  const isDesktop = windowWidth > 1125;

  return {
    isSmall,
    isMedium,
    isLarge,
    isDesktop,
    isMobile: isSmall || isMedium,
    width: windowWidth,
    height: windowHeight,
  };
}
