/* eslint-disable camelcase */

import { useQuery } from 'react-query';
import { Counters, NewRoomsRecord, RoomRecord } from 'components/Dashboard/types';

import getRoomsQueryString, { RoomQueryString } from 'utils/getRoomsQueryString';
import apiWrapper from '../../utils/apiWrapper';
import apiHelper from '../../utils/api';
import dashboardKeys from './queryKeys';

export interface RoomsByIDResponse {
  counts: Counters;
  rooms?: RoomRecord[];
  newRooms?: NewRoomsRecord[];
}

interface RoomsVariables extends RoomQueryString {
  roomID: number;
}

const fetchRooms = (roomID: number, searchParams: Omit<RoomsVariables, 'roomID'>) => async () => {
  const { data } = await apiWrapper.get(
    `${apiHelper().apiEndpoint}/v4/rooms/${roomID}?${getRoomsQueryString(searchParams)}`
  );
  return data.data;
};

const useQueryRoomByID = (params: RoomsVariables) => {
  const { roomID, ...searchParams } = params;

  return useQuery<RoomsByIDResponse, Error>({
    queryKey: dashboardKeys.roomFilter(roomID, searchParams),
    queryFn: fetchRooms(roomID, searchParams),
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: roomID > 0,
  });
};

export default useQueryRoomByID;
