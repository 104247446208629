import { FunctionComponent } from 'react';
import {
  View,
  Checkbox,
  TextAreaRounded,
  useEmotionTheme,
  EmotionStyle,
} from '@talkspace/react-toolkit';
import { getTextAreaContainerBorderStyle, getTextAreaStyle } from '../../utils';

interface Props {
  label: string;
  notes: string | null;
  setNotes: (value: string) => void;
  isChecked: boolean;
  setIsChecked: (value: boolean) => void;
  disabled: boolean;
  isError?: boolean;
  style?: EmotionStyle;
  fieldName?: string;
}

const SubstanceUseQuestion: FunctionComponent<Props> = ({
  label,
  notes,
  setNotes,
  isChecked,
  setIsChecked,
  disabled,
  isError = false,
  style = {},
  fieldName,
}) => {
  const { colors } = useEmotionTheme();

  const handleNotesChange = (newValue: string) => {
    setNotes(newValue);
  };

  const textAreaStyle = getTextAreaStyle(disabled);

  return (
    <View align="start" style={{ marginBottom: 13, ...style }}>
      <Checkbox
        label={label}
        isChecked={isChecked}
        setIsChecked={setIsChecked}
        isLabelOnRight
        containerStyle={style}
        isDisabled={disabled}
        dataQa={`${fieldName}Checkbox`}
      />
      {isChecked && (
        <TextAreaRounded
          autoSize
          style={{ ...getTextAreaContainerBorderStyle(colors, isError), width: '100%' }}
          textAreaStyle={textAreaStyle}
          value={notes || ''}
          onChangeText={handleNotesChange}
          placeholder="Describe the type, frequency, and amount as applicable"
          disabled={disabled}
          hasLimit
          dataQa={`${fieldName}TextArea`}
        />
      )}
    </View>
  );
};

export default SubstanceUseQuestion;
