import { FunctionComponent } from 'react';
import { View, Large } from '@talkspace/react-toolkit';

import { DescriptionLine, DescriptionStyle } from 'ts-frontend/types';
import styled, { EmotionStyle } from '@/core/styled';

const DescriptionWrapper = styled(View)(({ theme: { window } }) => {
  const { isMobile } = window;
  return {
    width: isMobile ? '100%' : '80%',
  };
});

const PlanDescription: FunctionComponent<{
  description: DescriptionLine[];
  style?: EmotionStyle;
}> = ({ description, style: styleOverride }) => (
  <DescriptionWrapper style={styleOverride}>
    {description.map(({ text, style }, i) => {
      const key = i;

      switch (style) {
        case DescriptionStyle.bold:
          return (
            <View row key={key} style={{ marginBottom: 3 }}>
              <Large variant="largeMediumTSBlack">{text}</Large>
            </View>
          );
        default:
          return (
            <Large key={key} variant="largeDarkGrey">
              {text}
            </Large>
          );
      }
    })}
  </DescriptionWrapper>
);

export default PlanDescription;
