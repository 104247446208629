import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const pathConfig: IconConfig = {
  standard: {
    filled: {
      path: 'M18.8 3.00049C18.8 2.45049 18.35 2.00049 17.8 2.00049H17.05C14.42 2.00049 11.19 4.38049 9.95999 6.56049C8.67999 5.00049 6.72999 4.00049 4.54999 4.00049H3.79999C3.24999 4.00049 2.79999 4.45049 2.79999 5.00049V6.00049C2.79999 9.87049 5.92999 13.0005 9.79999 13.0005H10.05V17.2505C10.05 17.6705 10.38 18.0005 10.8 18.0005C11.22 18.0005 11.55 17.6705 11.55 17.2505V11.0005C11.55 11.0005 11.55 10.9605 11.55 10.9305C14.97 10.4605 18.8 6.54049 18.8 3.00049Z',
    },
    default: {
      path: 'M18.8 3.00049C18.8 2.45049 18.35 2.00049 17.8 2.00049H17.05C14.42 2.00049 11.19 4.38049 9.95999 6.56049C8.67999 5.00049 6.72999 4.00049 4.54999 4.00049H3.79999C3.24999 4.00049 2.79999 4.45049 2.79999 5.00049V6.00049C2.79999 9.87049 5.92999 13.0005 9.79999 13.0005H10.05V17.2505C10.05 17.6705 10.38 18.0005 10.8 18.0005C11.22 18.0005 11.55 17.6705 11.55 17.2505V11.0005C11.55 11.0005 11.55 10.9605 11.55 10.9305C14.97 10.4605 18.8 6.54049 18.8 3.00049ZM10.05 11.5005H9.79999C6.75999 11.5005 4.29999 9.04049 4.29999 6.00049V5.50049H4.54999C7.58999 5.50049 10.05 7.96049 10.05 11.0005V11.5005ZM11.38 9.46049C11.27 8.97049 11.11 8.51049 10.91 8.07049C11.72 5.95049 14.7 3.50049 17.06 3.50049H17.29C17.05 6.10049 13.97 9.16049 11.39 9.46049H11.38Z',
    },
  },
  major: {
    filled: {
      path: 'M22.8 4.04346C22.8 3.35596 22.2375 2.79346 21.55 2.79346H20.6125C17.325 2.79346 13.2875 5.76846 11.75 8.49346C10.15 6.54346 7.71255 5.29346 4.98755 5.29346H4.05005C3.36255 5.29346 2.80005 5.85596 2.80005 6.54346V7.79346C2.80005 12.631 6.71255 16.5435 11.55 16.5435H11.8625V21.856C11.8625 22.381 12.275 22.7935 12.8 22.7935C13.325 22.7935 13.7375 22.381 13.7375 21.856V14.0435C13.7375 14.0435 13.7375 13.9935 13.7375 13.956C18.0125 13.3685 22.8 8.46846 22.8 4.04346Z',
    },
    default: {
      path: 'M22.7999 4.04346C22.7999 3.35596 22.2374 2.79346 21.5499 2.79346H20.6124C17.3249 2.79346 13.2874 5.76846 11.7499 8.49346C10.1499 6.54346 7.7124 5.29346 4.9874 5.29346H4.0499C3.3624 5.29346 2.7999 5.85596 2.7999 6.54346V7.79346C2.7999 12.631 6.7124 16.5435 11.5499 16.5435H11.8624V21.856C11.8624 22.381 12.2749 22.7935 12.7999 22.7935C13.3249 22.7935 13.7374 22.381 13.7374 21.856V14.0435C13.7374 14.0435 13.7374 13.9935 13.7374 13.956C18.0124 13.3685 22.7999 8.46846 22.7999 4.04346ZM11.8624 14.6685H11.5499C7.7499 14.6685 4.6749 11.5935 4.6749 7.79346V7.16846H4.9874C8.7874 7.16846 11.8624 10.2435 11.8624 14.0435V14.6685ZM13.5249 12.1185C13.3874 11.506 13.1874 10.931 12.9374 10.381C13.9499 7.73096 17.6749 4.66846 20.6249 4.66846H20.9124C20.6124 7.91846 16.7624 11.7435 13.5374 12.1185H13.5249Z',
    },
  },
};

const Seedling = withDSIconMaker(pathConfig, 'seedling');

export default Seedling;
