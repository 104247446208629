import { useEffect, useState } from 'react';
import {
  View,
  useEmotionTheme,
  styled,
  Tiny,
  Standard,
  TextAreaRounded,
  ExtraTiny,
  OptionType,
} from '@talkspace/react-toolkit';
import { severityLevel } from '@talkspace/configs';
import { useFlags } from 'launchDarkly/FlagsProvider';
import {
  useProgressNoteFormActions,
  useProgressNoteFormState,
} from '../context/TherapistProgressNoteFormContext';
import { Section } from '../context/types';
import SelectContainer from '../containers/SelectContainer';
import {
  BooleanDropdown,
  getSelectStyleV2,
  getTextAreaStyleV2,
  getTextAreaContainerBorderStyleV2,
  RISK_ASSESSMENT_CHARS_LIMIT,
} from '../../utils';
import CheckBoxAndSelectContainer from '../containers/CheckBoxAndSelectContainer';
import { SeverityLevel } from '../../types';
import useDidUpdateEffect from '../../../../../../hooks/useDidUpdateEffect';
import RiskAssessmentLink from '../../../../../Reusable/RiskAssessmentLink';

const Seperator = styled(View)(({ theme: { colors } }) => {
  return {
    width: 5,
    background: colors.periwinkleGrey,
    height: '100%',
    borderRadius: 10,
    marginRight: 15,
  };
});

const Container = styled(View)<{ isError: boolean }>(({ theme: { colors }, isError }) => {
  return {
    border: isError && `1px solid ${colors.permaFuchsia}`,
    borderRadius: 8,
    padding: 5,
  };
});

const Label = styled(Tiny)(({ theme: { colors } }) => {
  return {
    marginTop: 10,
    marginBottom: 10,
    fontSize: 14,
    color: colors.purple600,
  };
});

const severityOptions = Object.keys(severityLevel).map((key) => {
  return {
    value: key,
    label: severityLevel[key],
  };
});

const RiskAssessmentSection = ({ nextSection }: { nextSection: Section }) => {
  const { riskAssessment, formSections, formMode, currentSection, isSaveAsDraftPressed } =
    useProgressNoteFormState();
  const { setFormState } = useProgressNoteFormActions();
  const { nycTeensIntake } = useFlags();
  const { colors } = useEmotionTheme();
  const [isRiskAssessmentObserved, setIsRiskAssessmentObserved] = useState<boolean>(false);
  const [isErrors, setIsErrors] = useState<boolean>(false);

  useEffect(() => {
    setIsRiskAssessmentObserved(Object.values(riskAssessment).some((el) => el === true));
  }, [formMode, riskAssessment]);

  const markSectionAsIncomplete = () => {
    if (formSections.riskAssessment.completed) {
      setFormState({
        formSections: {
          ...formSections,
          riskAssessment: {
            open: formSections.riskAssessment.open,
            changed: true,
            completed: false,
          },
        },
      });
    }
  };

  const handleSetIsRiskAssessmentObserved = (value: boolean) => {
    setIsErrors(false);
    setIsRiskAssessmentObserved(value);
  };

  const setCurrentSuicidalIdeation = (value: boolean) => {
    markSectionAsIncomplete();
    setFormState({
      riskAssessment: {
        ...riskAssessment,
        currentSuicidalIdeation: value,
        ...(!value && { currentSuicidalIdeationSeverity: null }),
      },
    });
  };

  const setCurrentSuicidalSeverity = (option: OptionType | null | undefined) => {
    markSectionAsIncomplete();
    if (option) {
      setFormState({
        riskAssessment: {
          ...riskAssessment,
          currentSuicidalIdeation: true,
          currentSuicidalIdeationSeverity: option.value as SeverityLevel,
        },
      });
    }
  };

  const setCurrentHomicidalIdeation = (value: boolean) => {
    markSectionAsIncomplete();
    setFormState({
      riskAssessment: {
        ...riskAssessment,
        currentHomicidalIdeation: value,
        ...(!value && { currentHomicidalIdeationSeverity: null }),
      },
    });
  };

  const setCurrentHomicidalSeverity = (option: OptionType | null | undefined) => {
    markSectionAsIncomplete();
    if (option) {
      setFormState({
        riskAssessment: {
          ...riskAssessment,
          currentHomicidalIdeation: true,
          currentHomicidalIdeationSeverity: option.value as SeverityLevel,
        },
      });
    }
  };

  const setCurrentPsychosis = (value: boolean) => {
    markSectionAsIncomplete();
    setFormState({
      riskAssessment: {
        ...riskAssessment,
        currentPsychosis: value,
      },
    });
  };

  const setOtherAssessment = (value: string) => {
    markSectionAsIncomplete();
    setFormState({
      riskAssessment: {
        ...riskAssessment,
        otherAssessment: true,
        otherAssessmentNotes: value,
      },
    });
  };

  const validate = (): boolean => {
    let isError = false;

    const {
      currentHomicidalIdeation,
      currentHomicidalIdeationSeverity,
      currentSuicidalIdeation,
      currentSuicidalIdeationSeverity,
    } = riskAssessment;

    if (isRiskAssessmentObserved) {
      if (!currentHomicidalIdeation && !currentSuicidalIdeation) {
        isError = true;
      }
      if (currentHomicidalIdeation && !currentHomicidalIdeationSeverity) {
        isError = true;
      }
      if (currentSuicidalIdeation && !currentSuicidalIdeationSeverity) {
        isError = true;
      }
    }
    return isError;
  };

  const handleConfirmPress = () => {
    const isError = validate();
    const completed = !isError;
    if (isError && !isSaveAsDraftPressed) {
      setIsErrors(isError);
      setFormState({ showGlobalError: true });
      return;
    }

    const dataToSend = { ...riskAssessment };
    if (!isRiskAssessmentObserved) {
      Object.assign(dataToSend, {
        currentHomicidalIdeation: false,
        currentHomicidalIdeationSeverity: null,
        currentSuicidalIdeation: false,
        currentSuicidalIdeationSeverity: null,
        currentPsychosis: false,
        otherAssessment: false,
        otherAssessmentNotes: null,
      });
    }

    setFormState({
      riskAssessment: dataToSend,
      formSections: {
        ...formSections,
        riskAssessment: {
          open: false,
          changed: true,
          completed,
        },
        ...(formSections.substanceUse.completed
          ? {}
          : {
              substanceUse: {
                ...formSections.substanceUse,
                open: true,
              },
            }),
      },
    });
  };

  useDidUpdateEffect(() => {
    if (currentSection === 'riskAssessment') {
      setFormState({ currentSection: nextSection });
      handleConfirmPress();
    }
  }, [currentSection]);

  const textAreaStyle = getTextAreaStyleV2(formMode === 'view', colors);

  return (
    <View>
      <SelectContainer
        value={BooleanDropdown.find((it) => it.value === isRiskAssessmentObserved) as any}
        title="SI/HI observed or reported?"
        handleChange={((option) => handleSetIsRiskAssessmentObserved(option?.value)) as any}
        options={BooleanDropdown as any}
        styles={getSelectStyleV2({ colors })}
        isDisabled={formMode === 'view'}
        dataTestID="riskAssessmentSelectId"
        dataQa="riskAssessmentDropdown"
      />
      {isRiskAssessmentObserved && (
        <View row style={{ marginBottom: 15 }}>
          <Seperator />
          <View flex={1}>
            <Standard>Risk assessment (SI/HI)</Standard>
            <Label>
              Document any potential risk from this session and complete a
              <RiskAssessmentLink
                label="risk assessment"
                nycTeensIntakeLink={Boolean(nycTeensIntake)}
              />
              as appropriate
            </Label>
            <Container isError={isErrors}>
              <CheckBoxAndSelectContainer
                title="Suicidal"
                isChecked={riskAssessment.currentSuicidalIdeation}
                options={severityOptions}
                value={severityOptions.find(
                  (it) => it.value === riskAssessment.currentSuicidalIdeationSeverity
                )}
                handleChecked={setCurrentSuicidalIdeation}
                handleSelect={setCurrentSuicidalSeverity}
                styles={getSelectStyleV2({
                  colors,
                  hasValue: !riskAssessment.currentSuicidalIdeationSeverity,
                })}
                isDisabled={formMode === 'view'}
                dataQa="suicidalCheckbox"
              />
              <CheckBoxAndSelectContainer
                title="Homicidal"
                options={severityOptions}
                isChecked={riskAssessment.currentHomicidalIdeation}
                value={severityOptions.find(
                  (it) => it.value === riskAssessment.currentHomicidalIdeationSeverity
                )}
                handleChecked={setCurrentHomicidalIdeation}
                handleSelect={setCurrentHomicidalSeverity}
                styles={getSelectStyleV2({
                  colors,
                  hasValue: !riskAssessment.currentHomicidalIdeationSeverity,
                })}
                isDisabled={formMode === 'view'}
                dataQa="homicidalCheckbox"
              />
              <SelectContainer
                value={
                  BooleanDropdown.find((it) => it.value === riskAssessment.currentPsychosis) as any
                }
                title="Psychosis observed or reported?"
                handleChange={((option) => setCurrentPsychosis(option?.value)) as any}
                options={BooleanDropdown as any}
                styles={getSelectStyleV2({ colors })}
                isDisabled={formMode === 'view'}
                dataQa="psychosisObservedOrReportedDropdown"
              />
            </Container>
            {isErrors && (
              <ExtraTiny
                variant="extraTinyError"
                style={{ marginBottom: 5 }}
                data-testid="riskAssessmentError"
              >
                Required: You need to select what was observed or reported that made you choose Yes
                for SI/HI
              </ExtraTiny>
            )}
            <TextAreaRounded
              autoSize
              style={{
                ...getTextAreaContainerBorderStyleV2(colors),
                marginTop: 5,
              }}
              textAreaStyle={textAreaStyle}
              value={riskAssessment.otherAssessmentNotes || ''}
              onChangeText={(value) => setOtherAssessment(value)}
              placeholder="Optional. Client reported risk factors..."
              disabled={formMode === 'view'}
              rows={4}
              limit={RISK_ASSESSMENT_CHARS_LIMIT}
              hasLimit
              dataQa="clientReportedRiskFactorsTextArea"
            />
          </View>
        </View>
      )}
    </View>
  );
};

export default RiskAssessmentSection;
