/* eslint-disable camelcase */

import { SortingRule } from '@talkspace/react-toolkit';

// Different than the usual status constants
export type DashboardRoomStatus =
  | 'Paying'
  | 'Expired'
  | 'Expired'
  | 'Asked to Leave'
  | 'Cancelled'
  | 'Past Due'
  | 'Frozen'
  | 'Paying'
  | 'Free (good)'
  | 'Free (missing)'
  | 'Free';

export type TaskType =
  | 'completeLiveSessionProgressNote'
  | 'completeMessagingProgressNote'
  | 'reviewClientNoShow'
  | 'reviewClientLateCancellation';

export type CaseLoadView = 'paying' | 'all';
export type CurrentResponsiveTab = 'live-sessions' | 'new-clients' | 'all-clients';
export type ProgressNoteModality = 'messaging' | 'liveSession';
export type ProgressNoteAction = 'reopen' | 'snooze' | 'confirmNoShow' | 'grantNoShowException';

export const taskActionEnum: { SNOOZE: ProgressNoteAction; REOPEN: ProgressNoteAction } = {
  SNOOZE: 'snooze',
  REOPEN: 'reopen',
};

export const providerSnoozeReasonEnum = {
  NOT_ENOUGH_ENGAGEMENT: 'not_enough_engagement',
  TECHNICAL_ISSUE: 'technical_issue',
  NO_TREATMENT_GOALS: 'no_goals',
  OTHER: 'other',
};

export const progressNoteModalityEnum = {
  LIVE_SESSION: 'liveSession',
  MESSAGING: 'messaging',
};

export interface ActiveBooking {
  id?: number;
  roomID?: number;
  scheduledByUserType: 'client' | 'provider';
  status: string;
  timekitBookingState: 'tentative' | 'declined' | 'confirmed' | 'cancelled' | 'cancel_by_customer';
  startTime: Date;
}
export interface RoomRecord extends Record<string, unknown> {
  clientUserID: number;
  clientFirstName: string;
  clientLastName: string;
  lastActivity: Date;
  clientNickname: string;
  preferredName: string | null;
  offerDate: Date;
  planEnd: Date;
  planName: string;
  roomID: number;
  renewDate: Date;
  roomReminderCount: number;
  roomStatus: DashboardRoomStatus;
  isProviderInteractableRoom: boolean;
  isProviderNonInteractableRoom: boolean;
  weekdays: string;
  clientWaiting: Date;
  activeBookings: ActiveBooking[];
  activeSuicidalIdeation: string | null;
  activeHomicidalIdeation: string | null;
  hasUnreadMessages: boolean;
  presentingProblems: Array<string>;
  acuity: string;
  totalSessions: number;
  planModality: string;
  activeAsyncSession: boolean;
  accountType: 'bh' | 'eap' | 'dte' | 'b2c';
  messagePreview: string;
  diagnoses: Array<string>;
  validVideoCredits: number;
  productLine: string;
  roomActiveStatus: 'active' | 'inactive' | null;
  gender: { id: string; name: string };
}

export interface AllClientState {
  searchString: string;
  currentRooms: number;
  searchType: string;
  searchTypeOpen: boolean;
  caseLoadOpen: boolean;
  sortBy: SortingRule[];
  startDateFocused: boolean;
  endDateFocused: boolean;
  pageIndex: number;
  pageSize: number;
  view: string;
  filterToDate: string | null;
  filterFromDate: string | null;
  isUserSearching: boolean;
}

export interface Counters {
  all: number;
  consultation?: number;
  paying?: number;
}

export interface NewRoomsRecord extends Record<string, unknown> {
  clientUserID: number;
  clientWaiting: string;
  clientFirstName: string;
  clientLastName: string;
  lastActivity: Date;
  clientNickname: string;
  preferredName: string | null;
  planName: string;
  roomID: number;
  roomReminderCount: number;
  roomStatus: DashboardRoomStatus;
  messagePreview: string;
  renewDate: Date;
  planEnd: Date;
}

export type CheckRoomPath = (
  room: number,
  render: string | JSX.Element | null,
  className?: string
) => JSX.Element | string;

export interface SubmitPress {
  progressNoteID?: number | null;
  roomID: number;
  progressNoteModality?: ProgressNoteModality | null;
  videoCallID?: number | null;
  sessionReportID?: number | null;
  isEAP?: boolean;
  taskID: number;
  therapistID: number;
  type: TaskType;
  unsubmittedSessionID?: string;
}

export interface ReviewPress extends SubmitPress {
  bookingID?: number;
}

export interface TaskActionPress {
  action: ProgressNoteAction;
  snoozeReason?: string;
  providerSnoozeDescription?: string;
  therapistID: number;
  caseID?: number | null;
  sessionReportID?: number | null;
  roomID: number;
  taskID: number;
}

export const localStorageTableNames = {
  progressNotes: 'progressNoteTable',
  caseLoad: 'caseLoadTable',
};
