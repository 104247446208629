import * as actionTypes from '../constants/JourneyConstants';
import apiWrapper from '../utils/apiWrapper';
import apiHelper from '../utils/api';
import { isJourneyItemToDo, isJourneyItemShouldDissmiss } from '../utils/journeyHelper';

const formatJourneyItem = (journeyItem) => {
  let parsedBody = journeyItem?.dismissAction?.body && JSON.parse(journeyItem.dismissAction.body);
  const isAlertOrTodo = ['alert', 'to-do'].includes(journeyItem.category);
  if (isAlertOrTodo && !isJourneyItemShouldDissmiss(journeyItem)) {
    parsedBody = null;
  }

  const defaultValues = {
    id: journeyItem.id,
    body: journeyItem.body,
    category: journeyItem.category,
    title: journeyItem.title,
    feedback: journeyItem.feedback,
  };
  if (journeyItem.reminderTypeID) {
    defaultValues.reminderTypeID = journeyItem.reminderTypeID;
  }
  if (parsedBody) {
    Object.assign(defaultValues, parsedBody);
  }
  return defaultValues;
};

function getPresentingProblemsMappingByPPID(mappingArray) {
  return mappingArray.reduce(
    (prev, { presentingProblemID, presentingProblemText, conditionText, conditionID }) => {
      return {
        ...prev,
        [presentingProblemID]: {
          presentingProblemID,
          presentingProblemText,
          conditionText,
          conditionID,
        },
      };
    },
    {}
  );
}

function requestGetJourneyItems() {
  return {
    type: actionTypes.REQUEST_GET_JOURNEY_ITEMS,
  };
}

function requestPatchJourneyItems() {
  return {
    type: actionTypes.REQUEST_PATCH_JOURNEY_ITEM,
  };
}

function receivePatchJourneyItems(data) {
  return {
    type: actionTypes.RECEIVE_PATCH_JOURNEY_ITEM,
    ...data,
  };
}

function requestGetJourneyItemsError(error) {
  return {
    type: actionTypes.REQUEST_GET_JOURNEY_ITEMS_ERROR,
    error,
  };
}

function requestPatchJourneyItemsError(error) {
  return {
    type: actionTypes.REQUEST_PATCH_JOURNEY_ITEM_ERROR,
    error,
  };
}

export const getJourneyItems = () => (dispatch, getState) => {
  dispatch(requestGetJourneyItems());
  const {
    room: { roomID },
    availability: { therapistType },
  } = getState();
  return apiWrapper
    .get(`${apiHelper().apiEndpoint}/api/v1/rooms/${roomID}/journey/items`)
    .then((response) => {
      const processedJourneyItems =
        response.data.roomJourneyItems &&
        response.data.roomJourneyItems.map((item) => formatJourneyItem(item));
      apiWrapper
        .get(`${apiHelper().apiEndpoint}/v3/presenting-problems-mapping`)
        .then((response) => {
          const presentingProblemsConditionsMapping = getPresentingProblemsMappingByPPID(
            response.data.data
          );
          return dispatch({
            type: actionTypes.RECEIVE_GET_JOURNEY_ITEMS,
            roomJourneyItems: processedJourneyItems,
            presentingProblemsConditionsMapping,
          });
        });
    })
    .catch((error) => requestGetJourneyItemsError(error));
};

export const patchJourneyItem = (journeyData) => (dispatch, getState) => {
  const {
    room: { roomID },
  } = getState();
  dispatch(requestPatchJourneyItems());
  if (!journeyData.roomJourneyInteraction) {
    dispatch({
      type: actionTypes.RECEIVE_PATCH_JOURNEY_ITEM,
      success: true,
    });
    return null;
  }
  return apiWrapper
    .patch(`${apiHelper().apiEndpoint}/api/v1/rooms/${roomID}/journey`, { ...journeyData })
    .then((response) => dispatch(receivePatchJourneyItems(response.data)))
    .catch((error) => dispatch(requestPatchJourneyItemsError(error)));
};

function requestCreateJourneyItemFeedback() {
  return { type: actionTypes.REQUEST_POST_FEEDBACK_JOURNEY_ITEM };
}

function receiveCreateJourneyItemFeedback(response, itemID, requestPayload) {
  return {
    type: actionTypes.RECEIVE_POST_FEEDBACK_JOURNEY_ITEM,
    ...response.data,
    itemID,
    requestPayload,
  };
}

function requestCreateJourneyItemFeedbackError(error) {
  return {
    type: actionTypes.REQUEST_POST_FEEDBACK_JOURNEY_ITEM_ERROR,
    error,
  };
}
export const createJourneyItemFeedback = (itemID, payload) => (dispatch, getState) => {
  const {
    room: { roomID },
  } = getState();

  dispatch(requestCreateJourneyItemFeedback());
  return apiWrapper
    .post(
      `${apiHelper().apiEndpoint}/api/v1/rooms/${roomID}/journey/items/${itemID}/feedback`,
      payload
    )
    .then((response) => dispatch(receiveCreateJourneyItemFeedback(response, itemID, payload)))
    .catch((error) => dispatch(requestCreateJourneyItemFeedbackError(error)));
};

function requestUpdateJourneyItemFeedback() {
  return { type: actionTypes.REQUEST_PATCH_FEEDBACK_JOURNEY_ITEM };
}

function receiveUpdateJourneyItemFeedback(response, itemID, requestPayload) {
  return {
    type: actionTypes.RECEIVE_PATCH_FEEDBACK_JOURNEY_ITEM,
    ...response.data,
    itemID,
    requestPayload,
  };
}

function requestUpdateJourneyItemFeedbackError(error) {
  return {
    type: actionTypes.REQUEST_PATCH_FEEDBACK_JOURNEY_ITEM_ERROR,
    error,
  };
}

export const updateJourneyItemFeedback = (itemID, payload) => (dispatch, getState) => {
  const {
    room: { roomID },
  } = getState();

  dispatch(requestUpdateJourneyItemFeedback());
  return apiWrapper
    .patch(
      `${apiHelper().apiEndpoint}/api/v1/rooms/${roomID}/journey/items/${itemID}/feedback`,
      payload
    )
    .then((response) => dispatch(receiveUpdateJourneyItemFeedback(response, itemID, payload)))
    .catch((error) => dispatch(requestUpdateJourneyItemFeedbackError(error)));
};

function prepareSessionHighlightsInMemory(preparedSessionSummarization) {
  return {
    type: actionTypes.PREPARE_SESSION_SUMMARIZATION,
    preparedSessionSummarization,
  };
}

export const prepareSessionHighlights = () => (dispatch, getState) => {
  const {
    journey: { journeyItems },
  } = getState();

  const sessionSummarizationItem = journeyItems.find((item) => item.reminderTypeID === 105);
  sessionSummarizationItem.processedBody = sessionSummarizationItem.body.replace(/[\n]/g, '\n\n');

  return dispatch(prepareSessionHighlightsInMemory(sessionSummarizationItem));
};
