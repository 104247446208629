import { FunctionComponent, useState, useEffect, useMemo, useCallback } from 'react';
import {
  View,
  Button,
  Large,
  TimeslotsCalendar,
  TimeslotCarousel,
  Huge,
  Spinner,
  TouchableView,
  useWindowWidthState,
  EmotionThemeProvider,
} from '@talkspace/react-toolkit';
import moment, { Moment } from 'moment';
import { tz as momentTZ } from 'moment-timezone';

import { TherapistTimeslot, TimeslotByDay } from 'ts-frontend/types';

import useTimeslots from 'inRoomScheduling/hooks/useTimeslots';
import getFirstAvailableDate from 'inRoomScheduling/utils/getFirstAvailableDate';
import BookingTimeZone from 'inRoomScheduling/components/BookingTimeZone';
import KeepUpTheGreatWorkHeading from 'inRoomScheduling/components/KeepUpTheGreatWorkHeading';

import { TimeslotsByBookingDuration, TimeslotsByDayByBookingDuration } from 'inRoomScheduling';
import useQueryTherapistTimeslots from 'inRoomScheduling/hooks/useQueryTherapistTimeslots';
import useTherapistOpenTimes from 'inRoomScheduling/hooks/useTherapistOpenTImes';
import styled from '@/core/styled';
import { ClaimableRoom, SuccessfulClaimClientInfo } from '../types';
import useMutationClaimRoom from '../../../../hooks/growCaseload/useMutationClaimRoom';
import { NUMBER_OF_DAYS_AVAILABLE_IN_SCHEDULER } from '../constants';
import Analytics from '../Analytics';

const MAX_CALENDAR_WIDTH = 375;
const MAX_TIMESLOTS_WIDTH = 338;
const MAX_SEPARATION = 100;
const BLUR_HEIGHT = 35;

const ApprovalButton = styled(Button)(
  ({
    theme: {
      colors,
      window: { isDesktop },
    },
  }) => {
    return {
      marginTop: 40,
      color: 'white',
      backgroundColor: colors.permaBlueStoneNew,
      width: !isDesktop ? 295 : 335,
      borderRadius: 10,
      height: 50,
      textAlign: 'center',
      lineHeight: '50px',
      letterSpacing: '0.5px',
      fontWeight: 700,
      fontSize: 17,
    };
  }
);

const CancelButton = styled(Button)(({ theme: { colors } }) => {
  return {
    marginTop: 25,
    fontSize: 17,
    backgroundColor: colors.white,
    color: colors.permaBlueStoneNew,
    letterSpacing: '0.5px',
    fontWeight: 700,
    lineHeight: '20px',
    paddingBottom: 50,
  };
});

const TimeslotsCalendarWrapper = styled(View)(
  ({
    theme: {
      window: { isDesktop },
    },
  }) => {
    return {
      maxHeight: 280,
      maxWidth: MAX_CALENDAR_WIDTH,
      overflowX: 'hidden',
      alignSelf: !isDesktop ? 'center' : 'start',
    };
  }
);
const TimeslotsCarouselWrapper = styled(View)(
  ({
    theme: {
      window: { isDesktop },
    },
  }) => {
    return {
      // Remove the added padding bottom as negative margin to fix on non-safari browsers
      // This negative margin does not affect Safari, only other browsers
      alignSelf: !isDesktop ? 'center' : 'start',
      maxWidth: !isDesktop ? '90%' : MAX_TIMESLOTS_WIDTH,
      height: !isDesktop ? undefined : 550,
    };
  }
);

const DayAndTimezoneWrapper = styled(View)(({ theme: { colors } }) => {
  return {
    top: 0,
    zIndex: 1,
    width: '100%',
    height: '4em',
    position: 'sticky',
    backgroundColor: colors.white,
  };
});

const NextAvailableTimeslotButton = styled(Button)(({ theme: { colors } }) => {
  return {
    width: 335,
    borderWidth: 1,
    borderColor: colors.periwinkleGrey,
    backgroundColor: colors.white,
  };
});

const DateAndTimePicker = styled(View)<{ bookWithIntroSession: boolean }>(
  ({
    bookWithIntroSession,
    theme: {
      window: { isDesktop },
    },
  }) => {
    return {
      minWidth: !isDesktop ? '100%' : undefined,
      width: !isDesktop ? undefined : '100vw',
      maxWidth: !isDesktop ? undefined : MAX_CALENDAR_WIDTH + MAX_TIMESLOTS_WIDTH + MAX_SEPARATION,
      height: !isDesktop || bookWithIntroSession ? 'unset' : 300,
      paddingBottom: !isDesktop ? undefined : 80,
    };
  }
);

const OpenTimeslotButton = styled(TouchableView)(
  ({
    theme: {
      colors,
      window: { isDesktop },
    },
  }) => {
    return {
      marginTop: !isDesktop ? 20 : -20,
      marginBottom: 40,
      backgroundColor: colors.iceBlue,
      height: 36,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: 5,
    };
  }
);

const ButtonBlueDot = styled(View)(({ theme: { colors } }) => {
  return {
    backgroundColor: colors.purple,
    width: 8,
    height: 8,
    borderRadius: 4,
    margin: 8,
  };
});

const NoOpenTimesText = styled(View)(
  ({
    theme: {
      window: { isDesktop },
    },
  }) => {
    return {
      marginTop: !isDesktop ? 20 : -20,
      marginBottom: 40,
      fontWeight: 400,
      fontSize: 16,
      paddingLeft: !isDesktop ? 20 : 0,
      paddingRight: !isDesktop ? 20 : 0,
    };
  }
);

export const DayAndTimezone = ({ day, timezone }: { day: moment.Moment; timezone: string }) => (
  <DayAndTimezoneWrapper justify="start" align="stretch">
    <Huge>{day.format('dddd, MMM D')}</Huge>
    <BookingTimeZone timezone={timezone || momentTZ.guess()} style={{ margin: '5px 0 0 0' }} />
  </DayAndTimezoneWrapper>
);

const NextAvailableTimeslot = ({
  nextAvailableDate,
  isMobile,
  onPress,
}: {
  nextAvailableDate: moment.Moment | null;
  onPress: () => void;
  isMobile: boolean;
}) => {
  if (!nextAvailableDate) return null;

  return (
    <View style={{ marginTop: 46 }} flex={isMobile ? undefined : 1}>
      <NextAvailableTimeslotButton stretch size="medium" onPress={onPress}>
        <Large>
          Next available:{' '}
          <Large inline variant="largeBoldWideGreen">
            {nextAvailableDate.format('ddd, MMM D')}
          </Large>{' '}
        </Large>
      </NextAvailableTimeslotButton>
    </View>
  );
};

const generateRandomString = () => Math.random().toString(36).substring(7);

interface SelectTimeslotProps {
  clientInformation: ClaimableRoom;
  isTherapist?: boolean;
  isFromCheckInWizard: boolean;
  bookWithIntroSession?: boolean;
  therapistID: number;
  onCancel: () => void;
  confirmationFailure: (status: number) => void;
  confirmationSuccess: (data: SuccessfulClaimClientInfo) => void;
}

const SelectTimeslot: FunctionComponent<SelectTimeslotProps> = ({
  clientInformation,
  therapistID,
  isTherapist = true,
  isFromCheckInWizard = false,
  bookWithIntroSession = false,
  confirmationFailure,
  confirmationSuccess,
  onCancel,
}) => {
  const { mutate: claimRoom, isLoading: isClaimRoomLoading } = useMutationClaimRoom();
  const { isDesktop } = useWindowWidthState();
  const { data: therapistTimeslots } = useQueryTherapistTimeslots({
    therapistUserID: therapistID!,
    duration: clientInformation.maxCreditDuration,
    range: {
      from: 0,
      to: NUMBER_OF_DAYS_AVAILABLE_IN_SCHEDULER - 1,
    },
    roomID: clientInformation?.roomID,
    enforcePreferencesForUserID: clientInformation?.clientUserID,
  });

  const createEmptyTimeslotsByDay = useCallback((minDate: string, maxDate: string) => {
    const daysDifference = moment(maxDate).diff(minDate, 'days') + 1;
    const daysToDisplay = daysDifference + (daysDifference % 3);
    const timeslotsByDay: TimeslotByDay[] = [];

    for (let i = 0; i < daysToDisplay; i += 1) {
      timeslotsByDay.push({
        date: moment(minDate).add(i, 'days').format('YYYY-MM-DD'),
        timeslots: [],
      });
    }
    return timeslotsByDay;
  }, []);

  const [selectedTimeslot, setSelectedTimeslot] = useState<TherapistTimeslot>();
  const getMinAndMaxDates = (timeslots: TherapistTimeslot[]) => {
    return {
      minDate: moment(timeslots[0].start).format('YYYY-MM-DD'),
      maxDate: moment(timeslots[timeslots.length - 1].start).format('YYYY-MM-DD'),
    };
  };

  const createTherapistTimeslotDataByDay = useCallback(
    (timeslotsByBookingDuration: TimeslotsByBookingDuration): TimeslotsByDayByBookingDuration =>
      Object.entries(timeslotsByBookingDuration).reduce((acc, [bookingDuration, { timeslots }]) => {
        if (!timeslots.length) return acc;
        const { minDate, maxDate } = getMinAndMaxDates(timeslots);
        const timeslotsByDay: TimeslotByDay[] = createEmptyTimeslotsByDay(minDate, maxDate);
        timeslots.forEach(({ start, end, therapists, available }) => {
          const startDate = moment(start).format('YYYY-MM-DD');
          const dayObjectWithSameDate = timeslotsByDay.find(
            (dayObject) => dayObject.date === startDate
          );
          if (dayObjectWithSameDate)
            dayObjectWithSameDate.timeslots.push({ start, end, therapists, available });
        });

        acc[bookingDuration] = timeslotsByDay;
        return acc;
      }, {}),
    [createEmptyTimeslotsByDay]
  );

  const providerTimezone = momentTZ.guess();
  const initialTherapistTimeslotsAPIResponse = useMemo(() => {
    return {
      therapistTimezone: providerTimezone,
      timeslots: [],
    };
  }, [providerTimezone]);

  const initialTimeslotsByBookingDuration = useMemo(
    () =>
      [0, 10, 15, 30, 45, 60].reduce((prev, credit) => {
        return { ...prev, [credit]: initialTherapistTimeslotsAPIResponse };
      }, {}),
    [initialTherapistTimeslotsAPIResponse]
  );

  const [therapistTimeslotsByDay, setTherapistTimeslotsByDay] =
    useState<TimeslotsByDayByBookingDuration>(
      createTherapistTimeslotDataByDay(
        initialTimeslotsByBookingDuration as TimeslotsByBookingDuration
      )
    );

  useEffect(() => {
    therapistTimeslots &&
      setTherapistTimeslotsByDay(
        createTherapistTimeslotDataByDay({
          ...(initialTimeslotsByBookingDuration as TimeslotsByBookingDuration),
          [clientInformation.maxCreditDuration]: therapistTimeslots,
        })
      );
  }, [
    clientInformation.maxCreditDuration,
    createTherapistTimeslotDataByDay,
    initialTimeslotsByBookingDuration,
    therapistTimeslots,
  ]);

  const {
    availableTimeslots,
    timeslotDaysStrings,
    firstAvailableDate,
    nextAvailableDate,
    calendarDate,
    setCalendarDate,
    getFirstTimeslotByDate,
    getAllTimeslotsByDate,
    isTimeslotAvailable,
  } = useTimeslots({
    schedulingState: {
      therapistTimeslotsByDay,
      selectedBookingDuration: clientInformation.maxCreditDuration,
      selectedTimeslot,
      repeatingPeriod: 'no-repeat',
      repeatingSessions: 0,
      isInitialTimeSlotsLoaded: !!therapistTimeslots,
    },
    allowRecurringSessions: true,
  });

  const calendarDateMonth = calendarDate?.month();
  const [calendarKey, setCalendarKey] = useState(generateRandomString);
  const changeCalendarKey = () => {
    setCalendarKey(generateRandomString);
  };

  const onNextAvailableTimeslotPress = useCallback(() => {
    // If the date is changed to a different month than displayed on the calendar through JS, the Calendar won't update
    // This makes it remount with the new props so that it shows the correct month when month changes. NYC-6021
    if (nextAvailableDate?.month() !== calendarDateMonth) changeCalendarKey();
    setCalendarDate(nextAvailableDate?.startOf('day') || null);
    const newTimeslot = nextAvailableDate && getFirstTimeslotByDate(nextAvailableDate);
    if (newTimeslot) {
      setSelectedTimeslot(newTimeslot);
    }
  }, [calendarDateMonth, nextAvailableDate, setCalendarDate, getFirstTimeslotByDate]);

  useEffect(() => {
    // Keep calendar date and selected timeslot in sync
    //  - in case one is pre-selected and the other isn't
    // Check that the selected date/timeslot is still available
    if (calendarDate || firstAvailableDate) {
      const date = calendarDate || firstAvailableDate;
      const newTimeslot = date && getFirstTimeslotByDate(date);

      const selectedTimeslotAvailable = selectedTimeslot && isTimeslotAvailable(selectedTimeslot);

      if (!calendarDate) {
        if (selectedTimeslotAvailable) {
          setCalendarDate(moment(selectedTimeslot.start).startOf('day'));
        } else {
          setCalendarDate(firstAvailableDate);
        }
        changeCalendarKey();
      }
      if (newTimeslot && selectedTimeslot && !selectedTimeslotAvailable) {
        // If we show the select timeslot view, but selectedTimeslot is already set
        // it might be leftover state from the previous booking.
        // If so, it won't be available anymore and we should replace it with the next available slot
        setSelectedTimeslot(newTimeslot);
      }
    }
  }, [
    calendarDate,
    setSelectedTimeslot,
    selectedTimeslot,
    getFirstTimeslotByDate,
    firstAvailableDate,
    setCalendarDate,
    isTimeslotAvailable,
  ]);

  useEffect(() => {
    // Update calendar date and month if selected timeslot changes
    if (selectedTimeslot) {
      const newDateMoment = moment(selectedTimeslot.start)?.startOf('day');
      setCalendarDate(newDateMoment || null);
      if (newDateMoment?.month() !== calendarDateMonth) changeCalendarKey();
    }
  }, [selectedTimeslot, setCalendarDate, calendarDateMonth]);

  useEffect(() => {
    // intro sessions should automatically select next available timeslot
    if (bookWithIntroSession && !calendarDate) {
      onNextAvailableTimeslotPress();
    }
  }, [calendarDate, nextAvailableDate, bookWithIntroSession, onNextAvailableTimeslotPress]);

  const paramStartTime = false;

  useEffect(() => {
    // Auto select first timeslot in the current month
    // Allow initializing via query param for timeslot start time
    if (!selectedTimeslot) {
      const firstTimeslot = firstAvailableDate && getFirstTimeslotByDate(firstAvailableDate);
      const paramStartTimeMoment = moment(paramStartTime || moment());
      const timeslotsForParamDay = getAllTimeslotsByDate(paramStartTimeMoment);
      const paramTimeslot = timeslotsForParamDay?.find((timeslot) =>
        paramStartTimeMoment.isSame(timeslot.start)
      );

      if (paramTimeslot) {
        setSelectedTimeslot(paramTimeslot);
        setCalendarDate(paramStartTimeMoment);
        if (paramStartTimeMoment?.month() !== calendarDateMonth) changeCalendarKey();
      } else if (firstTimeslot) {
        setSelectedTimeslot(firstTimeslot);
        setCalendarDate(firstAvailableDate);
        if (firstAvailableDate?.month() !== calendarDateMonth) changeCalendarKey();
      }
    }
  }, [
    selectedTimeslot,
    firstAvailableDate,
    availableTimeslots,
    paramStartTime,
    calendarDateMonth,
    getFirstTimeslotByDate,
    getAllTimeslotsByDate,
    setCalendarDate,
  ]);

  const timeslots = useMemo(
    () => (calendarDate ? getAllTimeslotsByDate(calendarDate) : null),
    [calendarDate, getAllTimeslotsByDate]
  );

  const initialVisibleMonth = useCallback(() => {
    const result =
      calendarDate?.clone() ||
      (selectedTimeslot && moment(selectedTimeslot?.start)) ||
      firstAvailableDate ||
      moment();
    return result;
  }, [calendarDate, firstAvailableDate, selectedTimeslot]);

  const onCalendarMonthChange = (monthMoment: moment.Moment) => {
    if (selectedTimeslot) return;
    const firstTimeslot = getFirstAvailableDate(
      availableTimeslots,
      monthMoment.clone().endOf('month'),
      monthMoment.clone().startOf('month')
    );
    if (firstTimeslot) setCalendarDate(moment(firstTimeslot)?.startOf('day') || null);
  };

  const onDateChange = (d: moment.Moment | null) => {
    const newDate = d && moment(d.toISOString());
    setCalendarDate(newDate?.startOf('day') || null);
    const newTimeslot = newDate && getFirstTimeslotByDate(newDate);
    if (newTimeslot) {
      setSelectedTimeslot(newTimeslot);
    }
  };

  const bookAndclaimRoomCallback = useCallback(() => {
    if (!selectedTimeslot) {
      confirmationFailure(0);
      return;
    }
    Analytics.trackModalButtonPressedEvent({
      modal: 'Book and claim modal',
      button: 'Schedule and add to caseload button',
      roomID: clientInformation.roomID,
      therapistID,
    });
    claimRoom(
      {
        therapistID,
        roomID: clientInformation.roomID,
        bookingData: {
          start: selectedTimeslot!.start,
          creditMinutes: clientInformation.maxCreditDuration,
        },
      },
      {
        onSuccess: (name) => {
          confirmationSuccess({
            name,
            roomID: clientInformation.roomID,
            bookedTimeslot: selectedTimeslot,
          });
          onCancel();
        },
        onError: (error: any) => {
          onCancel();
          confirmationFailure(error.status);
        },
      }
    );
  }, [
    claimRoom,
    clientInformation.maxCreditDuration,
    clientInformation.roomID,
    confirmationFailure,
    confirmationSuccess,
    onCancel,
    selectedTimeslot,
    therapistID,
  ]);

  const monthCalendarDate =
    calendarDate || (selectedTimeslot ? moment(selectedTimeslot.start) : null);

  const getIsDayBlocked = useCallback(
    (it) => {
      if (therapistTimeslotsByDay?.[clientInformation.maxCreditDuration]) {
        return (
          therapistTimeslotsByDay[clientInformation.maxCreditDuration].findIndex(
            (day) => day.timeslots.length > 0 && moment(day.date).isSame(it, 'date')
          ) === -1
        );
      }
      return true;
    },
    [clientInformation.maxCreditDuration, therapistTimeslotsByDay]
  );

  const [prevCalendarDate, setPrevCalendarDate] = useState<Moment | null>(null);

  const { setFirstAvailability, hasTherapistAvailableSlot } = useTherapistOpenTimes({
    prevCalendarDate,
    setPrevCalendarDate,
    calendarDate,
    setCalendarDate,
    setCalendarKey,
    generateRandomString,
    setSelectedTimeslot,
    timeslotsByDay: therapistTimeslotsByDay[clientInformation.maxCreditDuration],
  });

  return (
    <View
      align="center"
      justify="center"
      style={{
        // Centers the content on larger screens
        height: !isDesktop ? undefined : 600,
        overflowX: 'visible',
      }}
    >
      {isFromCheckInWizard && (
        <View style={{ marginBottom: !isDesktop ? 24 : 40 }} align="center">
          <KeepUpTheGreatWorkHeading />
        </View>
      )}
      {hasTherapistAvailableSlot ? (
        <OpenTimeslotButton
          data-qa="growCaseloadSelectTimeslotOpenTimes"
          onPress={() => {
            setFirstAvailability();
          }}
        >
          <ButtonBlueDot />

          <View style={{ marginRight: 8, fontSize: 16 }}>open times on your calendar</View>
        </OpenTimeslotButton>
      ) : (
        <NoOpenTimesText>
          Note: There are no open times on your calendar within the client’s preferred times
        </NoOpenTimesText>
      )}
      <EmotionThemeProvider version="2.0.0">
        <DateAndTimePicker
          align="start"
          row={isDesktop}
          justify="space-between"
          bookWithIntroSession={bookWithIntroSession}
        >
          <TimeslotsCalendarWrapper>
            <TimeslotsCalendar
              timeslots={therapistTimeslotsByDay[clientInformation.maxCreditDuration] || undefined}
              key={calendarKey}
              onNextMonthClick={onCalendarMonthChange}
              onPrevMonthClick={onCalendarMonthChange}
              containerStyle={{
                marginBottom: 34,
                overflowX: 'hidden',
              }}
              isDayBlocked={getIsDayBlocked}
              isDayHighlighted={(day) => timeslotDaysStrings.includes(day.format('MM/DD/YYYY'))}
              date={monthCalendarDate}
              // There is no way to clear date in the TimeslotsCalendar
              onDateChange={onDateChange}
              initialVisibleMonth={initialVisibleMonth}
            />
          </TimeslotsCalendarWrapper>
          <TimeslotsCarouselWrapper flex={1}>
            {calendarDate && (
              <>
                <DayAndTimezone day={calendarDate} timezone={providerTimezone} />
                {timeslots?.length && (
                  <TimeslotCarousel
                    day={calendarDate}
                    isMobile={!isDesktop}
                    timeslots={timeslots}
                    isTherapist={isTherapist}
                    selectedTimeslot={selectedTimeslot}
                    setSelectedTimeslot={setSelectedTimeslot}
                    carouselContainerStyle={{
                      // Set width of carousel same as button to prevent flicker on changes
                      width: !isDesktop ? 335 : undefined,
                      justifyContent: !isDesktop ? 'center' : undefined,
                      paddingBottom: !isDesktop ? 0 : BLUR_HEIGHT,
                      maxHeight: !isDesktop ? undefined : 185,
                    }}
                  />
                )}
              </>
            )}
            {!timeslots?.length && nextAvailableDate && (
              <NextAvailableTimeslot
                isMobile={!isDesktop}
                nextAvailableDate={nextAvailableDate}
                onPress={onNextAvailableTimeslotPress}
              />
            )}
          </TimeslotsCarouselWrapper>
        </DateAndTimePicker>
      </EmotionThemeProvider>

      <ApprovalButton
        data-qa="growCaseloadSelectTimeslotScheduleAndAdd"
        onPress={() => {
          bookAndclaimRoomCallback();
        }}
      >
        {isClaimRoomLoading ? <Spinner /> : 'Schedule and add to caseload'}
      </ApprovalButton>
      <CancelButton data-qa="growCaseloadSelectTimeslotCancel" onPress={onCancel}>
        Cancel
      </CancelButton>
    </View>
  );
};

export default SelectTimeslot;
