import { FunctionComponent } from 'react';
import styled, { EmotionTheme } from '../../core/styled';
import { webOnlyStyle } from '../../core/styleHelpers';
import Text, { TextProps } from '../Text';
import type { VariantFunction } from './types';

export type SmallVariant =
  | 'default'
  | 'smallAllCaps'
  | 'smallAllCapsBrightGreen'
  | 'smallTSBlack'
  | 'smallDarkGrey'
  | 'smallMediumBlueGrey'
  | 'smallBoldTSBlack'
  | 'smallExtraBoldTSBlack'
  | 'smallBoldGreen'
  | 'smallBolderGreen'
  | 'smallBolderDarkGreen'
  | 'smallDarkGreen'
  | 'smallBoldGrey'
  | 'smallBolderGrey'
  | 'smallBoldWhite'
  | 'smallBoldBlack'
  | 'smallGrey950';

interface SmallProps extends TextProps {
  variant?: SmallVariant;
}

const smallDefaultStyle = (colors: EmotionTheme['colors']) => {
  return {
    fontSize: 14,
    lineHeight: '18px',
    letterSpacing: 0.1,
    fontWeight: 400,
    color: colors.slateGrey,
  };
};

const variants: Record<SmallVariant, VariantFunction> = {
  default: ({ theme: { colors } }) => {
    return {
      ...smallDefaultStyle(colors),
    };
  },
  smallTSBlack: ({ theme: { colors } }) => {
    return {
      ...smallDefaultStyle(colors),
      color: colors.black,
    };
  },
  smallDarkGrey: ({ theme: { colors } }) => {
    return {
      ...smallDefaultStyle(colors),
      color: colors.darkGray,
    };
  },
  smallMediumBlueGrey: ({ theme: { colors } }) => {
    return {
      ...smallDefaultStyle(colors),
      color: colors.permaSubtitleBlue,
    };
  },
  smallBoldTSBlack: ({ theme: { colors } }) => {
    return {
      ...smallDefaultStyle(colors),
      color: colors.darkGray,
      fontWeight: 500,
    };
  },
  smallBoldBlack: ({ theme: { colors } }) => {
    return {
      ...smallDefaultStyle(colors),
      color: colors.black,
      fontWeight: 500,
      lineHeight: '16px',
    };
  },
  smallExtraBoldTSBlack: ({ theme: { colors } }) => {
    return {
      ...smallDefaultStyle(colors),
      color: colors.black,
      fontWeight: 900,
    };
  },
  smallBoldGreen: ({ theme: { colors } }) => {
    return {
      ...smallDefaultStyle(colors),
      color: colors.greenText,
      letterSpacing: 0.2,
      fontWeight: 500,
    };
  },
  smallBolderGreen: ({ theme: { colors } }) => {
    return {
      ...smallDefaultStyle(colors),
      color: colors.greenText,
      letterSpacing: 0.2,
      fontWeight: 700,
    };
  },
  smallBolderDarkGreen: ({ theme: { colors } }) => {
    return {
      ...smallDefaultStyle(colors),
      color: colors.accessibilityGreenDark,
      letterSpacing: 0.2,
      fontWeight: 700,
    };
  },
  smallBolderGrey: ({ theme: { colors } }) => {
    return {
      ...smallDefaultStyle(colors),
      color: colors.grey950,
      fontWeight: 700,
    };
  },
  smallDarkGreen: ({ theme: { colors } }) => {
    return {
      ...smallDefaultStyle(colors),
      fontWeight: 500,
      color: colors.rainforestGreen,
    };
  },
  smallBoldGrey: ({ theme: { colors } }) => {
    return {
      ...smallDefaultStyle(colors),
      letterSpacing: 0.2,
      fontWeight: 500,
    };
  },
  smallBoldWhite: ({ theme: { colors } }) => {
    return {
      ...smallDefaultStyle(colors),
      color: colors.white,
      letterSpacing: 0.1,
      fontWeight: 500,
      lineHeight: '16px',
    };
  },
  // no all caps style on mobile
  smallAllCaps: ({ theme: { colors } }) => {
    return {
      ...smallDefaultStyle(colors),
      ...webOnlyStyle({ textTransform: 'uppercase' }),
      fontWeight: 500,
      letterSpacing: 0.4,
    };
  },
  smallAllCapsBrightGreen: ({ theme: { colors } }) => {
    return {
      ...smallDefaultStyle(colors),
      ...webOnlyStyle({ textTransform: 'uppercase' }),
      color: colors.limaGreen,
      letterSpacing: 0.4,
      fontWeight: 700,
    };
  },
  smallGrey950: ({ theme: { colors } }) => {
    return {
      ...smallDefaultStyle(colors),
      color: colors.grey950,
    };
  },
};
const SmallDefault = styled(Text)(variants.default);
const SmallAllCaps = styled(Text)(variants.smallAllCaps);
const SmallAllCapsBrightGreen = styled(Text)(variants.smallAllCapsBrightGreen);
const SmallBoldTSBlack = styled(Text)(variants.smallBoldTSBlack);
const SmallTSBlack = styled(Text)(variants.smallTSBlack);
const SmallExtraBoldTSBlack = styled(Text)(variants.smallExtraBoldTSBlack);
const SmallDarkGrey = styled(Text)(variants.smallDarkGrey);
const SmallMediumBlueGrey = styled(Text)(variants.smallMediumBlueGrey);
const SmallBoldGreen = styled(Text)(variants.smallBoldGreen);
const SmallBolderGreen = styled(Text)(variants.smallBolderGreen);
const SmallBolderDarkGreen = styled(Text)(variants.smallBolderDarkGreen);
const SmallDarkGreen = styled(Text)(variants.smallDarkGreen);
const SmallBoldGrey = styled(Text)(variants.smallBoldGrey);
const SmallBolderGrey = styled(Text)(variants.smallBolderGrey);
const SmallBoldWhite = styled(Text)(variants.smallBoldWhite);
const SmallBoldBlack = styled(Text)(variants.smallBoldBlack);
const SmallGrey950 = styled(Text)(variants.smallGrey950);

const selectStyle = (variant: SmallVariant | undefined, props: Omit<SmallProps, 'variant'>) => {
  switch (variant) {
    case 'smallAllCaps':
      return <SmallAllCaps {...props} />;
    case 'smallAllCapsBrightGreen':
      return <SmallAllCapsBrightGreen {...props} />;
    case 'smallTSBlack':
      return <SmallTSBlack {...props} />;
    case 'smallExtraBoldTSBlack':
      return <SmallExtraBoldTSBlack {...props} />;
    case 'smallDarkGrey':
      return <SmallDarkGrey {...props} />;
    case 'smallMediumBlueGrey':
      return <SmallMediumBlueGrey {...props} />;
    case 'smallBoldTSBlack':
      return <SmallBoldTSBlack {...props} />;
    case 'smallBoldGreen':
      return <SmallBoldGreen {...props} />;
    case 'smallBolderGreen':
      return <SmallBolderGreen {...props} />;
    case 'smallBolderDarkGreen':
      return <SmallBolderDarkGreen {...props} />;
    case 'smallBoldGrey':
      return <SmallBoldGrey {...props} />;
    case 'smallBolderGrey':
      return <SmallBolderGrey {...props} />;
    case 'smallBoldWhite':
      return <SmallBoldWhite {...props} />;
    case 'smallBoldBlack':
      return <SmallBoldBlack {...props} />;
    case 'smallDarkGreen':
      return <SmallDarkGreen {...props} />;
    case 'smallGrey950':
      return <SmallGrey950 {...props} />;
    default:
      return <SmallDefault {...props} />;
  }
};

const Small: FunctionComponent<SmallProps> = ({ variant, ...props }) => selectStyle(variant, props);

export default Small;
