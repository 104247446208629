import { VoidFunctionComponent } from 'react';
import Svg, { G, Circle, Path } from 'svgs';
import { useEmotionTheme, EmotionStyle } from '../../core/styled';
import { withHover, WithHoverProps } from '../../hoc';

interface ArrowWithCircleLeftProps extends WithHoverProps {
  height?: number;
  width?: number;
  color?: string;
  hoverColor?: string;
  style?: EmotionStyle;
}

const ArrowWithCircleLeft: VoidFunctionComponent<ArrowWithCircleLeftProps> = ({
  height = 43,
  width = 43,
  color,
  hoverColor,
  isHovering,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  return (
    <Svg width={width} height={height} viewBox="0 0 43 43" fill="none" {...otherProps}>
      <G>
        <Circle cx={21.5} cy={17.5} r={15.5} fill={colors.white} />
        <Circle cx={21.5} cy={17.5} r={15} stroke="#E4E8ED" />
      </G>
      <Path
        d="M23 13l-4 4.5 4 4.5"
        stroke={isHovering ? hoverColor : color || colors.green}
        strokeWidth={2}
        strokeLinecap="round"
      />
    </Svg>
  );
};

export default withHover(ArrowWithCircleLeft);
