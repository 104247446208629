import { useParams } from '@/core/routerLib';

import Submenu from '../../../../Reusable/Submenu/Submenu';
import ItemList from '../../../../Reusable/ItemList/ItemList';
import useQueryQuickMatchAnswers from './useQueryQuickMatchAnswers';
import './QuickMatchAnswers.css';
import './MultiRowListItem.css';
import PreferredTimes from '../../../../Dashboard/GrowCaseload/PreferredTimes';

const MEMBER_AVAILABILITY_STEP = 135;

const MultiRowListItem = ({ displayData, cssClass }) => (
  <div className={cssClass}>
    {displayData.index && <div className="index"> {displayData.index} </div>}
    <div className="item-data-container">
      {displayData.dataOrder.map((attr: string) =>
        // eslint-disable-next-line react/no-array-index-key
        attr === 'answer' &&
        displayData.stepID === MEMBER_AVAILABILITY_STEP &&
        Array.isArray(displayData[attr]) ? (
          <PreferredTimes
            key={`${attr}-${displayData.question}`}
            availability={displayData[attr]}
            allGray
          />
        ) : (
          <div key={`${attr}-${displayData.question}`}>
            {displayData[attr].replace(/,(\s+)?/g, ', ')}
          </div>
        )
      )}
    </div>
  </div>
);

const QuickMatchAnswers = () => {
  const { roomID } = useParams<{ roomID: string }>();

  const { data: answers } = useQueryQuickMatchAnswers({ roomID: Number(roomID) });

  const renderItems = () =>
    (answers || []).map((item, key) => {
      const displayData = {
        ...item,
        index: key + 1,
        dataOrder: ['question', 'answer'],
      };
      return (
        <MultiRowListItem
          key={item.question}
          displayData={displayData}
          cssClass="multirow-list-item"
        />
      );
    });

  const renderItemList = () => (
    <ItemList
      items={renderItems()}
      noDataMessage="No answers available."
      cssClass="quickmatch-answers"
    />
  );

  return (
    <Submenu childComponents={[renderItemList()]} title="QuickMatch Answers" prevRoute="case-tab" />
  );
};

export default QuickMatchAnswers;
