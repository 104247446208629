import { useQuery } from 'react-query';
import moment from 'moment';
import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';
import { AvailabilityPreferencesItem } from '../../../../Dashboard/GrowCaseload/types';

interface FetchQuickMatchAnswersParams {
  roomID: number;
}

interface QuickMatchAnswer {
  answer: string | AvailabilityPreferencesItem[];
  question: string;
  stepID: number;
}

const fetchQuickMatchAnswers = (params: FetchQuickMatchAnswersParams) => async () => {
  const { roomID } = params;
  const timezone = moment.tz.guess();
  const { data } = await apiWrapper.get(
    `${apiHelper().apiEndpoint}/api/v1/rooms/${roomID}/quickmatch-answers?timezone=${timezone}`
  );

  return data.data;
};

const useQueryQuickMatchAnswers = (params: FetchQuickMatchAnswersParams) =>
  useQuery<QuickMatchAnswer[], Error>({
    queryKey: ['quickMatch-answers', params.roomID.toString()],
    queryFn: fetchQuickMatchAnswers(params),
    cacheTime: Infinity,
    staleTime: Infinity,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
  });

export default useQueryQuickMatchAnswers;
