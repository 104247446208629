import { RequestBody } from 'ts-frontend/apiHelpers/apiTypes';
import apiHelper from '@/core/api/apiHelper';
import apiWrapper from '@/core/api/apiWrapper';

interface ApiResponse {
  data?: any;
}

export interface ContactSupportPayload {
  ticketType: string;
  freeText: string;
  ticketPriority: string;
}

export interface DepartureInfoPayload {
  isPhasingOut: boolean;
  departureDate?: Date;
  reason: string;
}

export const submitToContactSupport = (
  therapistID: number,
  data: ContactSupportPayload
): Promise<ApiResponse> =>
  apiWrapper.post(
    `${apiHelper().apiEndpoint}/v2/therapist/${therapistID}/contact-support`,
    data as RequestBody
  );

export const submitDepartureInfo = (
  therapistID: number,
  data: DepartureInfoPayload
): Promise<ApiResponse> =>
  apiWrapper.patch(
    `${apiHelper().apiEndpoint}/v2/therapist/${therapistID}/departure-info`,
    data as RequestBody
  );
