import { View, SingleDatePickerWithInput } from '@talkspace/react-toolkit';
import { useCallback, VoidFunctionComponent } from 'react';
import moment, { Moment } from 'moment';
import saveLocalStorageData from '../../../../utils/saveLocalStorageData';
import { AllClientState, localStorageTableNames } from '../../types';
import trackEvent from '@/analytics/trackEvent';
import { DashboardInteraction } from '@/analytics/types';
import './AllClientsDateStyles.css';

interface DateFiltersProps {
  filterToDate: Moment | null;
  filterFromDate: Moment | null;
  currentState: AllClientState;
  handleClientState: (allClientState: Partial<AllClientState>) => void;
  updateFilterFromDate: (date: Moment | null) => void;
  updateFilterToDate: (date: Moment | null) => void;
  getEventProperties: () => DashboardInteraction;
}

const DateFilters: VoidFunctionComponent<DateFiltersProps> = ({
  filterToDate,
  filterFromDate,
  currentState,
  handleClientState,
  updateFilterFromDate,
  updateFilterToDate,
  getEventProperties,
}) => {
  const handleFromDateChange = useCallback(
    (newDate?: Moment | null) => {
      if (!newDate) {
        updateFilterFromDate(null);
        saveLocalStorageData(localStorageTableNames.caseLoad, {
          filterFromDate: null,
        });
      } else if (!filterToDate || newDate?.isBefore(filterToDate)) {
        updateFilterFromDate(newDate);
        saveLocalStorageData(localStorageTableNames.caseLoad, {
          filterFromDate: newDate,
        });
        trackEvent(
          'clientListFiltered',
          { actionName: 'activeExperiments' },
          {
            ...getEventProperties(),
            dateRangeStartAt: newDate,
            filterName: 'Date range',
          }
        );
      }
    },
    [filterToDate, getEventProperties, updateFilterFromDate]
  );

  const handleToDateChange = useCallback(
    (newDate: Moment) => {
      if (!newDate) {
        updateFilterToDate(null);
        saveLocalStorageData(localStorageTableNames.caseLoad, {
          filterToDate: null,
        });
      } else if (!filterFromDate || newDate?.isAfter(filterFromDate)) {
        updateFilterToDate(newDate);
        saveLocalStorageData(localStorageTableNames.caseLoad, {
          filterToDate: newDate,
        });
        trackEvent(
          'clientListFiltered',
          { actionName: 'activeExperiments' },
          {
            ...getEventProperties(),
            dateRangeEndAt: newDate,
            filterName: 'Date range',
          }
        );
      }
    },
    [filterFromDate, getEventProperties, updateFilterToDate]
  );

  return (
    <View row style={{ marginRight: 20 }}>
      <div className="date-filter-label-v4">From </div>
      <div className="v4">
        <SingleDatePickerWithInput
          id="from-date"
          date={filterFromDate ? moment(filterFromDate) : null}
          small
          onDateChange={handleFromDateChange}
          focused={currentState.startDateFocused}
          onFocusChange={({ focused }) => handleClientState({ startDateFocused: focused })}
        />
      </div>

      <div className="date-filter-label-v4">To </div>
      <div className="v4">
        <SingleDatePickerWithInput
          id="end-date"
          date={filterToDate ? moment(filterToDate) : null}
          small
          onDateChange={handleToDateChange}
          focused={currentState.endDateFocused}
          onFocusChange={({ focused }) => handleClientState({ endDateFocused: focused })}
        />
      </div>
    </View>
  );
};

export default DateFilters;
