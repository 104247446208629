import { PlanData, Booking, VideoCreditOffer, LiveSessionModality } from 'ts-frontend/types';
import { transformPlan } from 'ts-frontend/helpers/billingUtils';
import { VideoCallAPIResponse, VideoCallEvent } from 'chat/types/videoCallTypes';
import apiHelper from '@/core/api/apiHelper';
import apiWrapper from '@/core/api/apiWrapper';
import {
  CancelBookingParams,
  TherapistTimeslotsAPIResponse,
  CreateBookingParams,
  CreateRecurringBookingParams,
  PurchaseVideoCreditParams,
  PurchaseBHSessionParams,
  TimeSlotRange,
  Subscription,
  ConfirmAsyncSessionParams,
  SuggestTherapistBookingsAPIResponse,
  SuggestTherapistBookingsParams,
} from '../types';
import { InvokeSource, VideoCreditOffersResponse } from '../hooks/useQueryVideoCreditOffers';

export interface ApiResponse<T = unknown> {
  data?: T;
}

export interface BookingResponse {
  data: Booking;
}

/**
 * api call to: `/v2/rooms/${roomID}/plan`
 * @returns Promise
 */
function getPlanInfo(planID: number): Promise<PlanData> {
  return apiWrapper
    .get(`${apiHelper().apiEndpoint}/v2/payments/plans/${planID}`)
    .then((res) => transformPlan(res.data.data));
}

/**
 * api call to: GET `/v3/rooms/{roomID}/bookings`
 * @returns Promise
 */
function getClientBookings(roomID: number): Promise<ApiResponse<Booking[]>> {
  return apiWrapper
    .get(`${apiHelper().apiEndpoint}/v3/rooms/${roomID}/bookings`)
    .then((res) => res.data);
}

/**
 * api call to: GET `/v3/rooms/{roomID}/bookings/{bookingID}`
 * @returns Promise
 */
function getClientBooking(roomID: number, bookingID: string): Promise<BookingResponse> {
  return apiWrapper
    .get(`${apiHelper().apiEndpoint}/v3/rooms/${roomID}/bookings/${bookingID}`)
    .then((res) => res.data);
}

/**
 * api call to: POST `/v2/payments/rooms/${roomID}/purchase-video-credit`
 * @returns Promise
 */
function purchaseVideoCredit(roomID: number, data: PurchaseVideoCreditParams): Promise<void> {
  return apiWrapper
    .post(`${apiHelper().apiEndpoint}/v2/payments/rooms/${roomID}/purchase-video-credit`, data)
    .then((res) => res.data);
}

/**
 * api call to: POST `/v3/rooms/:roomID/purchase-bh-session`
 * @returns Promise
 */
function purchaseBHSession(roomID: number, data: PurchaseBHSessionParams): Promise<void> {
  return apiWrapper
    .post(`${apiHelper().apiEndpoint}/v3/rooms/${roomID}/purchase-bh-session`, data)
    .then((res) => res.data);
}

/**
 * api call to: POST `/v3/rooms/{roomID}/bookings`
 * @returns Promise
 */
function createBooking(roomID: number, data: CreateBookingParams): Promise<boolean> {
  return apiWrapper
    .post(`${apiHelper().apiEndpoint}/v3/rooms/${roomID}/bookings`, data)
    .then((res) => res.status === 201);
}

/**
 * api call to: POST `/v3/rooms/:roomID/repeating-bookings`
 * @returns Promise
 */
function createRecurringBooking(
  roomID: number,
  data: CreateRecurringBookingParams
): Promise<boolean> {
  return apiWrapper
    .post(`${apiHelper().apiEndpoint}/v3/rooms/${roomID}/repeating-bookings`, data)
    .then((res) => res.status === 201);
}

/**
 * api call to: POST `/v2/rooms/{roomID}/async-session`
 * @returns Promise
 */
function createAsyncSession(roomID: number, data: ConfirmAsyncSessionParams): Promise<boolean> {
  return apiWrapper
    .post(`${apiHelper().apiEndpoint}/v2/rooms/${roomID}/async-session`, data)
    .then((res) => res.status === 201);
}

/**
 * api call to: PATCH `/v4/rooms/{roomID}/bookings`
 * @returns Promise
 */
function cancelBooking({
  roomID,
  bookingID,
  data,
  isBatchMode,
}: {
  roomID: string;
  bookingID: string;
  data: CancelBookingParams;
  isBatchMode?: boolean;
}): Promise<boolean> {
  return apiWrapper
    .patch(`${apiHelper().apiEndpoint}/v4/rooms/${roomID}/bookings/${bookingID}`, {
      ...data,
      action: 'cancel',
      isBatchMode,
    })
    .then((res) => res.status === 204);
}

/**
 * api call to: PATCH `/v4/rooms/{roomID}/bookings`
 * @returns Promise
 */
function declineBooking({
  roomID,
  bookingID,
  isBatchMode,
}: {
  roomID: string;
  bookingID: string;
  isBatchMode?: boolean;
}): Promise<boolean> {
  return apiWrapper
    .patch(`${apiHelper().apiEndpoint}/v4/rooms/${roomID}/bookings/${bookingID}`, {
      action: 'decline',
      isBatchMode,
    })
    .then((res) => res.status === 204);
}

/**
 * api call to: PATCH `/v3/rooms/{roomID}/bookings/:bookingID`
 * @returns Promise
 */
function dismissBooking(roomID: string, bookingID: string): Promise<boolean> {
  return apiWrapper
    .patch(`${apiHelper().apiEndpoint}/v3/rooms/${roomID}/bookings/${bookingID}`, {
      action: 'dismiss',
    })
    .then((res) => res.status === 200);
}

function getSubscriptions(
  clientUserID: number,
  roomID?: number,
  includePaymentDetails?: boolean
): Promise<ApiResponse<Subscription[]>> {
  const queryString = new URLSearchParams();
  if (roomID) {
    queryString.set('roomID', roomID.toString());
  }
  if (includePaymentDetails) {
    queryString.set('include', 'paymentDetails');
  }

  return apiWrapper
    .get(
      `${
        apiHelper().apiEndpoint
      }/v2/clients/${clientUserID}/subscriptions?${queryString.toString()}`
    )
    .then((res) => res.data);
}

/**
 * api call to: GET `/v2/therapist/{therapistUserID}/timeslots`
 * @returns Promise
 */
function getTherapistTimeSlots(
  therapistUserID: number,
  duration: number,
  range: TimeSlotRange,
  roomID: number
): Promise<ApiResponse<TherapistTimeslotsAPIResponse>> {
  return apiWrapper
    .get(
      `${
        apiHelper().apiEndpoint
      }/v2/therapist/${therapistUserID}/timeslots?length=${duration} minutes&from=${
        range.from
      } day&to=${range.to} day&roomID=${roomID}`
    )
    .then((res) => res.data);
}

/**
 * api call to: GET ` /v3/rooms/{roomID}/video-credit-offers`
 * @returns Promise
 */
function getVideoCreditOffers(
  roomID: number,
  bookingID?: string,
  ignoreCredits?: boolean,
  source?: InvokeSource
): Promise<VideoCreditOffersResponse> {
  const urlParams = new URLSearchParams({
    ...(bookingID ? { bookingID } : {}),
    ...(ignoreCredits ? { ignoreCredits: 'true' } : {}),
    ...(source ? { source } : {}),
  });
  const queryString = urlParams.toString();

  return apiWrapper
    .get(
      `${apiHelper().apiEndpoint}/v3/rooms/${roomID}/video-credit-offers${
        queryString ? `?${queryString}` : ''
      }`
    )
    .then((res) => res.data);
}

/**
 * api call to: GET ` /v3/rooms/{roomID}/video-calls/:videoCallID
 * @returns Promise
 */
function getVideoCall(roomID: number, videoCallID: number): Promise<VideoCallAPIResponse> {
  return apiWrapper
    .get(`${apiHelper().apiEndpoint}/v3/rooms/${roomID}/video-calls/${videoCallID}`)
    .then((res) => res.data.data);
}

/**
 * api call to: PATCH ` /v4/rooms/{roomID}/bookings/{bookingID}`
 * @returns Promise
 */
function confirmBooking({
  roomID,
  bookingID,
  isPurchase,
  couponCode,
  creditCardToken,
  isBatchMode,
}: {
  roomID: number;
  bookingID: string;
  isPurchase: boolean;
  couponCode?: string;
  creditCardToken?: string;
  isBatchMode?: boolean;
}): Promise<ApiResponse<VideoCreditOffer[]>> {
  return apiWrapper
    .patch(`${apiHelper().apiEndpoint}/v4/rooms/${roomID}/bookings/${bookingID}`, {
      action: 'confirm',
      isPurchase,
      couponCode,
      cardToken: creditCardToken,
      isBatchMode,
    })
    .then((res) => res.data);
}

/**
 * api call to: POST ` /v2/rooms/{roomID}/video-calls/:videoCallID/events
 * @returns Promise
 */
function clientJoinLiveChat(roomID: number, videoCallID: number): Promise<boolean> {
  return apiWrapper
    .post(`${apiHelper().apiEndpoint}/v3/rooms/${roomID}/video-calls/${videoCallID}/events`, {
      eventType: VideoCallEvent.clientJoined,
    })
    .then((res) => res.status === 204);
}

export interface ValidateCouponResponse {
  data: {
    couponCode: string;
    discountAmount: number;
    validCoupon: boolean;
  };
}

/**
 * api call to: POST ` /v2/payments/coupons/{couponCode}/validate`
 * @returns Promise
 */
function validateCoupon(
  couponCode: string,
  planID: number
): Promise<ValidateCouponResponse['data']> {
  return apiWrapper
    .post(`${apiHelper().apiEndpoint}/v2/payments/coupons/${couponCode}/validate`, {
      planID,
    })
    .then((res) => res.data.data);
}

/**
 * api call to: POST ` /v2/rooms/{roomID}/video-calls/:videoCallID/events
 * @returns Promise
 */
function getActiveSession(
  roomID: number,
  modality: LiveSessionModality
): Promise<VideoCallAPIResponse> {
  return apiWrapper
    .get(`${apiHelper().apiEndpoint}/v3/rooms/${roomID}/active-session?modality=${modality}`)
    .then((res) => res.data.data)
    .catch((err) => {
      if (err.status === 404) return null;
      throw err;
    });
}

/**
 * api call to: POST ` /v2/rooms/{roomID}/suggest-therapist-bookings`
 * @returns Promise
 */
function suggestTherapistBookings({
  roomID,
  sessionLength,
  therapistID,
}: SuggestTherapistBookingsParams): Promise<SuggestTherapistBookingsAPIResponse[]> {
  // Since timeslots are multiples of 15, we should not send an intro credit length of 1
  let sessionLengthSuggest = sessionLength;
  if (sessionLength === 10) {
    sessionLengthSuggest = 15;
  }
  return apiWrapper
    .post(`${apiHelper().apiEndpoint}/v2/rooms/${roomID}/suggest-therapist-bookings`, {
      sessionLength: sessionLengthSuggest,
      ...(therapistID && { therapistID }),
    })
    .then((res) => res.data.data);
}

const inRoomSchedulingApiHelper = {
  getPlanInfo,
  getClientBookings,
  getClientBooking,
  getSubscriptions,
  purchaseVideoCredit,
  purchaseBHSession,
  createBooking,
  createRecurringBooking,
  createAsyncSession,
  cancelBooking,
  declineBooking,
  dismissBooking,
  confirmBooking,
  suggestTherapistBookings,
  getTherapistTimeSlots,
  getVideoCreditOffers,
  validateCoupon,
  getVideoCall,
  clientJoinLiveChat,
  getActiveSession,
};

export default inRoomSchedulingApiHelper;
