import useMutationDeletePsychotherapyNote from 'hooks/notes/useMutationDeletePsychotherapyNote';
import useQueryPsychotherapyNote from 'hooks/notes/useQueryPsychotherapyNote';
import { useHistory, useParams } from '@/core/routerLib';
import { getSubmenuTitleAndSubtitle } from './utils';
import NoteDialog from './NoteDialog';

const DeletePsychotherapyNoteDialog = () => {
  const history = useHistory();
  const { roomID, noteID } = useParams<{ roomID: string; noteID: string }>();

  const { data: psychotherapyNoteData } = useQueryPsychotherapyNote(roomID, noteID);

  const { mutate: deletePsychotherapyNote, isLoading: isDeleteLoading } =
    useMutationDeletePsychotherapyNote();

  const handleYesPress = () => {
    deletePsychotherapyNote(
      { roomID, noteID },
      {
        onSettled: () => {
          history.push(`/room/${roomID}/notes-tab`);
        },
      }
    );
  };
  const { title, subtitle } = getSubmenuTitleAndSubtitle({
    formMode: 'edit',
    noteType: 'psychotherapy',
    noteDate: psychotherapyNoteData?.createdAt,
  });

  return (
    <NoteDialog
      dialogType="delete"
      title={title}
      subtitle={subtitle}
      isLoading={isDeleteLoading}
      onYesPress={handleYesPress}
    />
  );
};

export default DeletePsychotherapyNoteDialog;
