import { View, useWindowWidthState, TouchableView } from '@talkspace/react-toolkit';
import { Hamburger } from '@talkspace/react-toolkit/src/components/icons';
import styled from '../../../core/styled';
import './NavBar.css';
import NavBarLogo from '../NavBarLogo/NavBarLogo';
import NavBarAccountDropDownMenu from '../NavBarAccountDropDownMenu/NavBarAccountDropDownMenu';

export const getNavBarHeight = (isMobile: boolean = false) => {
  if (isMobile) return 107;
  return 67;
};

const NavBarWrapper = styled(View)(({ theme: { colors } }) => {
  return {
    height: 67,
    width: '100vw',
    flexDirection: 'row',
    borderBottom: `1px solid ${colors.extraLightGrey}`,
    maxHeight: 64,
  };
});

const RowContainer = styled(View)({
  flex: 1,
  maxHeight: 67,
  flexDirection: 'row',
});

const LogoContainer = styled(View)({
  flex: 1,
  position: 'relative',
  paddingLeft: 23,
  paddingRight: 16,
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

const AccountMenuContainer = styled(View)({
  flex: 1,
  alignItems: 'flex-end',
  justifyContent: 'center',
  paddingRight: 20,
});

interface TherapistNavBarProps {
  isLoggedIn?: boolean;
  isDashboard?: boolean;
  showIndicator?: boolean;
  isHidden?: boolean;
  handleMenuPress?: () => void;
}

const NavBar: React.VoidFunctionComponent<TherapistNavBarProps> = ({
  isLoggedIn = true,
  isDashboard = false,
  showIndicator = false,
  isHidden = false,
  handleMenuPress,
}) => {
  const { isMobile } = useWindowWidthState();

  if (isHidden) return null;

  return (
    <NavBarWrapper>
      <RowContainer>
        {/* Adding the className here makes existing CSS work */}
        <LogoContainer className="therapist-navbar" style={{ paddingLeft: isMobile ? 27 : 8 }}>
          {isMobile && isLoggedIn && isDashboard ? (
            <TouchableView
              style={{ position: 'relative' }}
              aria-label="open menu"
              role="button"
              onPress={handleMenuPress}
            >
              <Hamburger
                {...(showIndicator && { style: { position: 'absolute', top: -14 }, showIndicator })}
              />
            </TouchableView>
          ) : (
            <NavBarLogo />
          )}
        </LogoContainer>
        <AccountMenuContainer style={{ paddingRight: isMobile ? 15 : 25 }}>
          {isLoggedIn && <NavBarAccountDropDownMenu />}
        </AccountMenuContainer>
      </RowContainer>
    </NavBarWrapper>
  );
};

export default NavBar;
