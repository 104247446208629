import { FunctionComponent } from 'react';
import styled, { EmotionStyle } from '../../core/styled';
import View from '../View';

interface InitialsAvatarProps {
  displayName?: string;
  className?: string;
  style?: EmotionStyle;
}

const InitialsAvatarWrapper = styled(View)(({ theme: { colors } }) => {
  return {
    height: 42,
    width: 42,
    borderRadius: '50%',
    backgroundColor: colors.baliHaiGrey,
    fontWeight: 700,
    color: colors.white,
    paddingTop: 6,
    textAlign: 'center',
    fontSize: 16,
    marginRight: 9,
    padding: 0,
    justifyContent: 'center',
  };
});

const InitialsAvatar: FunctionComponent<InitialsAvatarProps> = ({
  displayName = 'Na',
  className,
  style,
}) => {
  const firstLetter = displayName.charAt(0).toUpperCase();
  const secondLetter = displayName.charAt(1).toLowerCase();
  return (
    <InitialsAvatarWrapper aria-hidden="true" className={className} style={style}>
      {firstLetter}
      {secondLetter}
    </InitialsAvatarWrapper>
  );
};

export default InitialsAvatar;
