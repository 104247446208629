import Svg, { Path } from 'svgs';
import { useEmotionTheme } from '../../core/styled';

interface PauseSignProps {
  width?: number;
  height?: number;
  color?: string;
}

const PauseSign = ({ width = 18, height = 24, color, ...otherProps }: PauseSignProps) => {
  const titleText = 'pause sign';
  const { colors } = useEmotionTheme();
  return (
    <Svg
      title={titleText}
      aria-label={titleText}
      width={width}
      height={height}
      fill="none"
      viewBox="-4 -2 24 24"
      {...otherProps}
    >
      <Path
        d="M3.5 0A3.5 3.5 0 0 0 0 3.5v17a3.5 3.5 0 1 0 7 0v-17A3.5 3.5 0 0 0 3.5 0ZM5 20.5a1.5 1.5 0 0 1-3 0v-17a1.5 1.5 0 0 1 3 0v17ZM14.5 0A3.5 3.5 0 0 0 11 3.5v17a3.5 3.5 0 1 0 7 0v-17A3.5 3.5 0 0 0 14.5 0ZM16 20.5a1.5 1.5 0 1 1-3 0v-17a1.5 1.5 0 1 1 3 0v17Z"
        fill={color || colors.darkGreen}
      />
    </Svg>
  );
};

export default PauseSign;
