import { VoidFunctionComponent } from 'react';

import BaseButton from '../BaseButton';
import { Standard, Tiny } from '../Typography';
import { EmotionStyle, useEmotionTheme } from '../../core/styled';

export interface Props {
  dataQa?: string;
  onCancellationPolicyPress: () => void;
  style?: EmotionStyle;
}

const CancellationPolicyMessage: VoidFunctionComponent<Props> = (props) => {
  const { dataQa, onCancellationPolicyPress, style } = props;
  const { colors } = useEmotionTheme();

  return (
    <Tiny style={{ textAlign: 'center', ...style }}>
      By clicking “Confirm sessions” you are agreeing to
      <BaseButton dataQa={dataQa}>
        <Standard
          onPress={onCancellationPolicyPress}
          style={{ color: colors.permaTalkspaceDarkGreen, fontWeight: 700 }}
        >
          Talkspace Cancellation Policy
        </Standard>
      </BaseButton>
      .
    </Tiny>
  );
};

export default CancellationPolicyMessage;
