import {
  REQUEST_EMERGENCY_CONTACT,
  REQUEST_EMERGENCY_CONTACT_ERROR,
  RECEIVE_EMERGENCY_CONTACT,
  REQUEST_SET_EMERGENCY_CONTACT,
  REQUEST_SET_EMERGENCY_CONTACT_ERROR,
  RECEIVE_SET_EMERGENCY_CONTACT,
  REQUEST_CLIENT_LOGINS,
  REQUEST_CLIENT_LOGINS_ERROR,
  RECEIVE_CLIENT_LOGINS,
} from '../constants/EmergencyContactConstants';

const initialState = {
  emergencyContactByClientUserID: {},
  clientLogins: [],
  clientLoginsOffset: 0,
  canRequestMoreClientLogins: false,
  isRequestingByClientUserID: {},
  requestErrorByClientUserID: {},
};

export default function emergencyContact(state = initialState, action) {
  switch (action.type) {
    case REQUEST_EMERGENCY_CONTACT:
    case REQUEST_CLIENT_LOGINS:
    case REQUEST_SET_EMERGENCY_CONTACT:
      return {
        ...state,
        isRequestingByClientUserID: {
          ...state.isRequestingByClientUserID,
          [action.clientUserID]: true,
        },
        requestErrorByClientUserID: {
          ...state.isRequestingByClientUserID,
          [action.clientUserID]: false,
        },
      };
    case REQUEST_EMERGENCY_CONTACT_ERROR:
    case REQUEST_CLIENT_LOGINS_ERROR:
    case REQUEST_SET_EMERGENCY_CONTACT_ERROR:
      return {
        ...state,
        isRequestingByClientUserID: {
          ...state.isRequestingByClientUserID,
          [action.clientUserID]: false,
        },
        requestErrorByClientUserID: {
          ...state.isRequestingByClientUserID,
          [action.clientUserID]: true,
        },
      };
    case RECEIVE_EMERGENCY_CONTACT:
      return {
        ...state,
        emergencyContactByClientUserID: {
          ...state.emergencyContactByClientUserID,
          [action.clientUserID]: action.payload,
        },
        isRequestingByClientUserID: {
          ...state.isRequestingByClientUserID,
          [action.clientUserID]: false,
        },
        requestErrorByClientUserID: {
          ...state.isRequestingByClientUserID,
          [action.clientUserID]: false,
        },
      };
    case RECEIVE_SET_EMERGENCY_CONTACT:
      return {
        ...state,
        emergencyContactByClientUserID: {
          ...state.emergencyContactByClientUserID,
          [action.clientUserID]: {
            ...state.emergencyContactByClientUserID[action.clientUserID],
            ...action.payload,
          },
        },
        isRequestingByClientUserID: {
          ...state.isRequestingByClientUserID,
          [action.clientUserID]: false,
        },
        requestErrorByClientUserID: {
          ...state.isRequestingByClientUserID,
          [action.clientUserID]: false,
        },
      };
    case RECEIVE_CLIENT_LOGINS:
      return {
        ...state,
        clientLogins: action.isRefetch ? [...state.clientLogins, ...action.data] : action.data,
        clientLoginsOffset: action.clientLoginsOffset,
        canRequestMoreClientLogins: action.canRequestMoreClientLogins,
        isRequestingByClientUserID: {
          ...state.isRequestingByClientUserID,
          [action.clientUserID]: false,
        },
        requestErrorByClientUserID: {
          ...state.isRequestingByClientUserID,
          [action.clientUserID]: false,
        },
      };
    default:
      return state;
  }
}
