import { FunctionComponent } from 'react';
import { useField } from 'formik';
import { ValueType } from 'react-select';
import { OptionType } from '../Select/types';
import styled, { EmotionStyle } from '../../core/styled';
import { useUniqueID } from '../../hooks/a11yHelper';
import View from '../View';
import SelectRounded from '../SelectRounded';
import Label from '../Label';
import Mini from '../Typography/Mini';

interface Props {
  options: Array<OptionType>;
  fieldName: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  selectStyle?: EmotionStyle;
  styles?: EmotionStyle;
}

const InputWrapper = styled(View)({
  marginTop: 16,
  flexGrow: 1,
});

const ErrorMessage = styled(Mini)(({ theme: { colors } }) => {
  return {
    color: colors.red,
    paddingLeft: 4,
  };
});

const FormLabel = styled(Label)(({ theme: { colors } }) => {
  return { fontSize: 13, color: colors.greyText };
});

const SelectRoundedFormik: FunctionComponent<Props> = ({
  fieldName,
  label,
  options,
  placeholder,
  required = false,
  selectStyle = {},
  ...otherProps
}) => {
  const [field, meta, helpers] = useField(fieldName);
  const errorId = useUniqueID(`${fieldName}ErrorID`);
  const isError = Boolean(meta.touched && meta.error);
  const selectedOption =
    field.value && options.filter((option: OptionType) => option.value === field.value)[0];
  return (
    <InputWrapper>
      <FormLabel>{label}</FormLabel>
      <SelectRounded
        id={fieldName}
        options={options}
        ariaLabel={label}
        ariaDescribedBy={meta.error ? errorId : undefined}
        ariaRequired={required}
        ariaInvalid={Boolean(meta.error)}
        placeholder={placeholder || 'Select'}
        onBlur={field.onBlur}
        value={field.value && { value: field.value, label: selectedOption?.label }}
        name={field.name}
        onChangeEvent={field.onChange}
        onChange={(e: ValueType<OptionType, boolean>) => helpers.setValue((e as OptionType).value)}
        wrapperStyle={{ marginTop: 0, width: '100%', ...selectStyle }}
        {...otherProps}
      />
      {isError && <ErrorMessage>{meta.error}</ErrorMessage>}
    </InputWrapper>
  );
};

export default SelectRoundedFormik;
