import { useMutation } from 'react-query';

import apiHelper from '@/utils/api';

import { CaptionsLanguage } from '../types/videoCallTypes';
import apiWrapper from '@/core/api/apiWrapper';

export interface ConfigureClosedCaptionsParams {
  roomID: number;
  videoCallID: number;
  captionsLanguage: CaptionsLanguage;
}

const configureClosedCaptions = async (params: ConfigureClosedCaptionsParams): Promise<void> => {
  const { roomID, videoCallID, captionsLanguage } = params;
  await apiWrapper.post(
    `${apiHelper().apiEndpoint}/v2/rooms/${roomID}/video-calls/${videoCallID}/closed-captions`,
    { captionsLanguage }
  );
};

export default function useMutationConfigureClosedCaptions() {
  return useMutation<void, Error, ConfigureClosedCaptionsParams>(configureClosedCaptions);
}
