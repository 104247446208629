import TSConfigs from '@talkspace/configs';

const moment = require('moment');

/*
TSConfigs
 {
     optionsDict: {
        value1: 'label1'
        value2: 'label2'
     }
 }
*/

const {
  presentingProblems,
  suicideIdeationOrPlanning,
  homicidalIdeationOrPlanning,
  angerAggression,
  states,
  countries,
  daysOfWeek,
  likelyToBuy,
  reasonNoPurchase,
  trafficControl,
  contactRelationship,
  mentalHealthIssues,
  gender,
  ethnicity,
  transferReasons,
  conditionsBillable,
  experiencingSchoolCommunity,
  experiencingSocialMedia,
  ...rest
} = TSConfigs;

const conditionsBillableIDs = Object.keys(conditionsBillable).filter(
  (key) => conditionsBillable[key]
);
const conditionsNotBillableIDs = Object.keys(conditionsBillable).filter(
  (key) => !conditionsBillable[key]
);

const tsValidSignUpYears = {
  yearOfBirthMin: moment().subtract(100, 'years').year(),
  yearOfBirthMax: moment().subtract(14, 'years').year(),
};

const currentYear = new Date().getFullYear();

const yearOfBirth = Array.from(
  new Array(tsValidSignUpYears.yearOfBirthMax - tsValidSignUpYears.yearOfBirthMin + 2),
  (val, index) => tsValidSignUpYears.yearOfBirthMin + index
).reduce((prev, year) => {
  return {
    ...prev,
    [year]: `${currentYear - year}`,
  };
}, {});

function makeEachValueTheKey(dict) {
  return Object.entries(dict).reduce((prev, [, value]) => {
    return { ...prev, [value]: value };
  }, {});
}

const configs = {
  ...rest,
  conditionsBillable,
  conditionsBillableIDs,
  conditionsNotBillableIDs,
  gender,
  therapistGenderPreference: gender,
  ethnicity,
  therapistEthnicityPreference: ethnicity,
  presentingProblems,
  previousMentalHealthIssues: mentalHealthIssues,
  previousRelativesMentalHealthIssues: mentalHealthIssues,
  experiencingSchoolCommunity,
  experiencingSocialMedia,
  suicideIdeation: suicideIdeationOrPlanning,
  suicidePlanning: suicideIdeationOrPlanning,
  homicidalIdeation: homicidalIdeationOrPlanning,
  homicidalPlanning: homicidalIdeationOrPlanning,
  angerAggression,
  state: states,
  country: countries,
  weekdays: daysOfWeek,
  relationship: contactRelationship,
  yearOfBirth,
  likelyToBuy: makeEachValueTheKey(likelyToBuy),
  reasonNoPurchase: makeEachValueTheKey(reasonNoPurchase),
  trafficControl: makeEachValueTheKey(trafficControl),
  transferReasons,
};

const configsAsOptionsByField = Object.entries(configs).reduce((prev, [field, optionsDict]) => {
  return {
    ...prev,
    [field]: Object.entries(optionsDict).map(([value, label]) => {
      return {
        value: isNaN(value) ? value : Number(value),
        label,
      };
    }),
  };
}, {});

export function getOptionsByField(field) {
  return configsAsOptionsByField[field] || [];
}

export default configs;
