import View from '../View';

export const TabContent = ({
  children,
  hide = false,
}: {
  children?: React.ReactNode;
  hide?: boolean;
  title?: string; // eslint-disable-line react/no-unused-prop-types
}) => <View style={{ display: hide ? 'none' : 'block' }}>{children}</View>;

export default TabContent;
