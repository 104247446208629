import { FunctionComponent } from 'react';
import Svg, { Path } from 'svgs';
import { EmotionStyle, useEmotionTheme } from '../../core/styled';

export interface ServicesIconProps {
  width?: number;
  height?: number;
  style?: EmotionStyle;
}

const ServicesIcon: FunctionComponent<{
  color?: string;
  width?: string | number;
  height?: string | number;
  style?: EmotionStyle;
  dataQa?: string;
}> = ({ width = 18, height = 18, color, style, dataQa, ...otherProps }) => {
  const titleText = 'Services icon';
  const { colors } = useEmotionTheme();
  return (
    <Svg
      title={titleText}
      aria-label={titleText}
      width={width}
      height={height}
      viewBox="0 0 18 18"
      data-qa={dataQa}
      style={style}
      {...otherProps}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7 11.72a.899.899 0 0 1-.9.897h-.9v.955a.898.898 0 0 1-.9.897.898.898 0 0 1-.9-.897v-.955h-.9a.899.899 0 0 1-.9-.897c0-.495.403-.897.9-.897h.9v-.84c0-.495.403-.897.9-.897s.9.402.9.897v.84h.9c.497 0 .9.402.9.897Zm4.5 3.589a.899.899 0 0 1-.9.897H2.7a.899.899 0 0 1-.9-.897V8.132c0-.496.403-.898.9-.898h12.6c.497 0 .9.402.9.898v7.177ZM8.96 1.786c2.033 0 3.798 1.86 4.331 3.654H4.546c.402-1.794 2.257-3.654 4.413-3.654Zm7.2 3.654h-1.012C14.598 2.75 11.988 0 8.96 0 5.835 0 3.163 2.749 2.728 5.44h-.969C.766 5.44 0 6.301 0 7.292v8.971C0 17.254.765 18 1.76 18h14.4c.993 0 1.84-.745 1.84-1.737V7.292c0-.99-.847-1.852-1.84-1.852Z"
        fill={color ?? colors.permaSanJuan}
      />
    </Svg>
  );
};

export default ServicesIcon;
