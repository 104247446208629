import { useCallback, useMemo } from 'react';
import { TherapistTimeslot, Booking } from 'ts-frontend/types';
import { useHistory, useLocation, useRouteMatch } from '@/core/routerLib';
import { tsAnalyticsTracker } from '@/utils/analytics/eventTracker';
import { InRoomSchedulingLocation, SchedulerMode } from '../types';
import { useInRoomSchedulingState, useInRoomSchedulingActions } from './inRoomSchedulingContext';
import { deleteLastElementInPath } from '../utils/url';
import { useFlags } from '../../launchDarkly/FlagsProvider';

interface GetUrlParamsOptions {
  url: string;
  isReschedule: boolean;
  selectedCancelBooking?: Booking;
  schedulerMode?: SchedulerMode;
  stateRoomID: number;
  recurringAvailableTimeslots?: TherapistTimeslot[] | null;
  recurringConflictingTimeslots?: TherapistTimeslot[] | null;
  hasCompletedSession?: boolean;
  availabilitySwitch?: boolean;
}
const getUrlParams = ({
  url,
  isReschedule,
  selectedCancelBooking,
  schedulerMode,
  stateRoomID,
  recurringAvailableTimeslots,
  recurringConflictingTimeslots,
  hasCompletedSession,
  availabilitySwitch,
}: GetUrlParamsOptions) => {
  const baseURL = deleteLastElementInPath(url);

  if (availabilitySwitch && baseURL.includes('switch-provider')) {
    if (hasCompletedSession) {
      return {
        nextUrl: `/switch-provider/sharing-preferences`,
        urlParams: {
          roomID: stateRoomID,
        },
      };
    }
    return {
      nextUrl: `${baseURL}/confirm-booking`,
      urlParams: {
        roomID: stateRoomID,
      },
    };
  }

  const goToConfirm =
    !isReschedule ||
    (isReschedule &&
      selectedCancelBooking &&
      selectedCancelBooking.timekitBookingState !== 'confirmed') ||
    (isReschedule && schedulerMode === 'providerScheduled');

  if (!goToConfirm) {
    return {
      nextUrl: `${baseURL}/cancel-reason`,
      urlParams: {
        roomID: stateRoomID,
        isReschedule: true, // if going to reason after timeslot you must be rescheduling
      },
    };
  }

  if (
    recurringAvailableTimeslots &&
    recurringConflictingTimeslots &&
    recurringAvailableTimeslots?.length &&
    recurringConflictingTimeslots?.length
  ) {
    return {
      nextUrl: `${baseURL}/recurring-booking-conflicts`,
      urlParams: {
        isReschedule,
        roomID: stateRoomID,
      },
    };
  }

  const confirmURL =
    (schedulerMode === 'providerScheduled' || isReschedule) && selectedCancelBooking
      ? `${baseURL}/confirm-booking/booking/${selectedCancelBooking.id}`
      : `${baseURL}/confirm-booking`;
  return {
    nextUrl: confirmURL,
    urlParams: {
      isReschedule,
      roomID: stateRoomID,
    },
  };
};

interface UseSelectTimeslotContinuePressOptions {
  isTherapist: boolean;
  recurringAvailableTimeslots?: TherapistTimeslot[] | null;
  recurringConflictingTimeslots?: TherapistTimeslot[] | null;
  recurringRequestedTimeslots?: TherapistTimeslot[] | null;
  timeslotCount: number;
  hasCompletedSession?: boolean;
}

const useSelectTimeslotContinuePress = ({
  isTherapist,
  recurringAvailableTimeslots,
  recurringConflictingTimeslots,
  recurringRequestedTimeslots,
  timeslotCount,
  hasCompletedSession = false,
}: UseSelectTimeslotContinuePressOptions) => {
  const { repeatingSessionsFull, availabilitySwitch } = useFlags();
  const { selectedCancelBooking, schedulerMode, repeatingPeriod, repeatingSessions } =
    useInRoomSchedulingState();
  const { dispatchSetHasTimeForBreak, dispatchSetRepeatingTimeslots } =
    useInRoomSchedulingActions();
  const location = useLocation<InRoomSchedulingLocation>();
  const history = useHistory();
  const match = useRouteMatch<{ roomID: string }>();
  const { url } = match;

  const { roomID: stateRoomID } = location.state || {};

  const { isReschedule = false } = location.state ? location.state : { isReschedule: false };

  const { nextUrl, urlParams } = useMemo(
    () =>
      getUrlParams({
        url,
        isReschedule,
        selectedCancelBooking,
        schedulerMode,
        stateRoomID,
        recurringAvailableTimeslots,
        recurringConflictingTimeslots,
        availabilitySwitch,
        hasCompletedSession,
      }),
    [
      url,
      isReschedule,
      selectedCancelBooking,
      schedulerMode,
      stateRoomID,
      recurringAvailableTimeslots,
      recurringConflictingTimeslots,
      availabilitySwitch,
      hasCompletedSession,
    ]
  );

  const handleContinuePress = useCallback(() => {
    if (isTherapist) {
      dispatchSetHasTimeForBreak();
      if (repeatingSessionsFull) {
        dispatchSetRepeatingTimeslots({
          recurringAvailableTimeslots,
          recurringConflictingTimeslots,
          recurringRequestedTimeslots,
        });
      }
      const mixpanelRecurringData =
        repeatingPeriod === 'no-repeat'
          ? {
              'Recurring Cadence': 'does not recur',
              'Recurring Duration': null,
              'Sessions Scheduled': null,
              'Scheduling Conflicts': null,
            }
          : {
              'Recurring Cadence': repeatingPeriod,
              'Recurring Duration': `${repeatingSessions} sessions`,
              'Sessions Scheduled': recurringAvailableTimeslots?.length,
              'Scheduling Conflicts': recurringConflictingTimeslots
                ? recurringConflictingTimeslots.length
                : 0,
            };
      tsAnalyticsTracker.trackMPEventGeneric('Scheduler Session Selected', {
        targets: [tsAnalyticsTracker.targetsEnum.MIXPANEL_PROVIDER],
        roomID: stateRoomID || match.params.roomID,
        'Scheduling timeslots': timeslotCount,
        ...mixpanelRecurringData,
      });
    }

    history.push(nextUrl, urlParams);
  }, [
    isTherapist,
    dispatchSetHasTimeForBreak,
    dispatchSetRepeatingTimeslots,
    history,
    nextUrl,
    urlParams,
    recurringAvailableTimeslots,
    recurringConflictingTimeslots,
    recurringRequestedTimeslots,
    repeatingSessionsFull,
    stateRoomID,
    match.params.roomID,
    repeatingPeriod,
    repeatingSessions,
    timeslotCount,
  ]);

  return {
    handleContinuePress,
  };
};

export default useSelectTimeslotContinuePress;
