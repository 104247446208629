import Svg, { Circle, Path } from 'svgs';

import { useEmotionTheme } from '../../core/styled';

interface Props {
  width?: number;
  height?: number;
}
const MbaMagnifyingGlass = ({ width = 131, height = 130, ...otherProps }: Props) => {
  const titleText = 'Magnifying Glass';
  const { colors } = useEmotionTheme();
  return (
    <Svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 131 130"
      title={titleText}
      aria-label={titleText}
      {...otherProps}
    >
      <Circle cx={65.5} cy={65} r={65} fill={colors.permaLinkWaterGrey} />
      <Path
        d="m47.992 42.592 17.99-.628a7.806 7.806 0 0 1 8.074 7.528l.63 17.992a7.806 7.806 0 0 1-7.529 8.074l-17.992.628a7.806 7.806 0 0 1-8.073-7.528l-.628-17.993a7.806 7.806 0 0 1 7.528-8.073Z"
        fill={colors.white}
        stroke={colors.permaPeriwinkleGray}
        strokeWidth={5.204}
      />
      <Path
        d="m61.16 55.11 17.963 1.16a7.806 7.806 0 0 1 7.287 8.291l-1.157 17.966a7.806 7.806 0 0 1-8.293 7.288l-17.965-1.16a7.806 7.806 0 0 1-7.287-8.291l1.16-17.967a7.806 7.806 0 0 1 8.291-7.286Z"
        fill={colors.permaTalkspaceDarkGreen}
        stroke={colors.permaTalkspaceDarkGreen}
        strokeWidth={5.204}
      />
      <Path
        d="m70.9 76.775 5.311 6.023c.306.37.71.581 1.19.612a1.64 1.64 0 0 0 1.253-.448c.363-.303.575-.7.606-1.172a1.666 1.666 0 0 0-.425-1.239v-.002l-5.371-6.063c.368-.493.664-1.02.888-1.582a7.366 7.366 0 0 0 .49-2.258c.133-2.05-.479-3.862-1.828-5.408-1.35-1.547-3.063-2.393-5.115-2.526-2.067-.134-3.888.482-5.435 1.84-1.546 1.358-2.392 3.076-2.526 5.127-.133 2.05.485 3.864 1.842 5.41 1.36 1.548 3.095 2.394 5.178 2.529a7.437 7.437 0 0 0 2.356-.205 6.313 6.313 0 0 0 1.586-.638Zm-.805-3.553c-.83.744-1.8 1.076-2.943 1.002-1.142-.074-2.072-.53-2.817-1.379-.746-.85-1.078-1.823-1.005-2.946.075-1.143.53-2.067 1.377-2.803.847-.737 1.828-1.066 2.972-.992 1.144.074 2.064.526 2.791 1.363.728.837 1.055 1.813.98 2.959-.073 1.126-.52 2.05-1.355 2.796Z"
        fill={colors.white}
        stroke={colors.white}
      />
    </Svg>
  );
};

export default MbaMagnifyingGlass;
