import {
  View,
  Text,
  TouchableView,
  Spinner,
  Huge,
  Tiny,
  Link,
  CircledQuestionMark,
  useEmotionTheme,
  useWindowWidthState,
} from '@talkspace/react-toolkit';
import styled from '@/core/styled/styled';
import ssoHelper from '@/utils/sso';

import { ReviewsRecord } from '../types';
import ReviewRow from './ReviewRow';
import ReviewsEmptyPlaceholder from './ReviewsEmptyPlaceholder';

const Container = styled(View)(
  ({
    theme: {
      colors,
      window: { isMobile },
    },
  }) => {
    return {
      backgroundColor: colors.athensGrey,
      padding: isMobile ? '0 10px 10px 10px' : '0 20px 20px 20px',
      overflowY: 'visible',
    };
  }
);

const MainContainerStyled = styled(View)(
  ({
    theme: {
      colors,
      window: { isMobile },
    },
  }) => {
    return {
      width: '100%',
      backgroundColor: colors.white,
      borderColor: colors.extraLightGrey,
      borderWidth: 1,
      borderStyle: 'solid',
      borderRadius: '0 0 5px 5px',
      marginTop: 0,
      paddingBottom: isMobile ? 10 : 30,
      paddingTop: isMobile ? 10 : 30,
    };
  }
);

const CenterView = styled(View)({
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'stretch',
});

const LoadMoreButton = styled(TouchableView)({
  border: 'none',
  outline: 'none',
  marginLeft: 'auto',
  paddingLeft: 0,
  paddingRight: 0,
  marginRight: 'auto',
  marginTop: 25,
});

const ButtonText = styled(Text)(({ theme: { colors } }) => {
  return { color: colors.green, fontSize: 16 };
});

const ListHeaderContainer = styled(View)(({ theme: { colors } }) => {
  return {
    padding: '15px 20px',
    borderTopStyle: 'solid',
    borderColor: colors.extraLightGrey,
    borderTopWidth: 0,
    fontSize: 16,
  };
});

const ListHeaderLinkContainer = styled(View)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      paddingTop: isMobile ? 5 : 10,
    };
  }
);

interface ReviewsViewProps {
  reviews: ReviewsRecord[];
  total: number;
  isLoading: boolean;
  isError: boolean;
  hasMore: boolean;
  handleLoadMoreClick: () => void;
}

const ReviewsView = ({
  reviews,
  total,
  isLoading,
  isError,
  hasMore,
  handleLoadMoreClick,
}: ReviewsViewProps) => {
  const { colors } = useEmotionTheme();
  const { isMobile } = useWindowWidthState();
  const renderSwitch = () => {
    const defaultMessage = <CenterView>Something went wrong, please try again later</CenterView>;
    if (isError) {
      return defaultMessage;
    }
    if (reviews) {
      const headerText = total !== 1 ? `${total} reviews` : `${total} review`;
      return (
        <>
          <ListHeaderContainer row={!isMobile} justify="space-between">
            <View>
              <Huge>{headerText}</Huge>
            </View>
            <ListHeaderLinkContainer>
              <Link
                onClick={() => {
                  ssoHelper.openZendesk(ssoHelper.ZendeskPath.THERAPIST_REVIEWS_ARTICLE);
                }}
              >
                <CircledQuestionMark
                  color={colors.green}
                  width={15}
                  height={15}
                  style={{ marginBottom: -2, marginRight: 6 }}
                />
                <Tiny inline variant="tinyBoldGreen">
                  Where do reviews come from?
                </Tiny>
              </Link>
            </ListHeaderLinkContainer>
          </ListHeaderContainer>
          {reviews && reviews.length ? (
            reviews.map((review, idx) => <ReviewRow review={review} key={review.id} index={idx} />)
          ) : (
            <ReviewsEmptyPlaceholder />
          )}
          {!isLoading && hasMore && (
            <LoadMoreButton onPress={handleLoadMoreClick}>
              <ButtonText>Load more</ButtonText>
            </LoadMoreButton>
          )}
          {isLoading && (
            <CenterView>
              <Text>Loading ratings...</Text>
              <Spinner isLoading />
            </CenterView>
          )}
        </>
      );
    }

    return defaultMessage;
  };

  return (
    <Container align="center">
      <MainContainerStyled>{renderSwitch()}</MainContainerStyled>
    </Container>
  );
};

export default ReviewsView;
