import { RefObject } from 'react';
import {
  ID_OLDEST_LOADED_MESSAGE,
  ID_CHAT_INPUT,
  arrowKeyNavigationClass,
} from '@/utils/IDConstants';

let lastFocusedMessage;
export const createEventHandlerA11y = (ref: RefObject<HTMLDivElement>) => (e: KeyboardEvent) => {
  const { current } = ref;
  if (current) {
    const messagesArr: HTMLElement[] = Array.from(
      current.querySelectorAll(`.${arrowKeyNavigationClass}`)
    );
    if (!messagesArr.length) {
      return;
    }
    if (document.activeElement && messagesArr.includes(document.activeElement as HTMLElement)) {
      lastFocusedMessage = document.activeElement;
    } else if ((e.key === 'ArrowUp' || e.key === 'ArrowDown') && lastFocusedMessage) {
      // momentarily shifts focus back to the message itself if a button associated with that message (i.e. star or button inside system message) has focus to allow for the next message to receive focus
      lastFocusedMessage.focus();
    }
    const oldestLoadedMessage = document.getElementById(ID_OLDEST_LOADED_MESSAGE);
    const chatInput = document.getElementById(ID_CHAT_INPUT);
    const focusedMessageIndex =
      document.activeElement instanceof HTMLDivElement
        ? messagesArr.indexOf(document.activeElement)
        : 0;
    if (e.key === 'ArrowUp' && focusedMessageIndex > 0) {
      // preventDefault necessary to maintain focus on the desired message otherwise it gets skipped over
      e.preventDefault();
      e.stopPropagation();
      messagesArr[focusedMessageIndex - 1].focus();
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      e.stopPropagation();
      // focus the chat input on arrow down press if at the bottom of the chat
      if (focusedMessageIndex === messagesArr.length - 1 && chatInput) {
        chatInput.focus();
      } else if (focusedMessageIndex + 1 < messagesArr.length) {
        messagesArr[focusedMessageIndex + 1].focus();
      }
    } else if (e.key === 'Home' && oldestLoadedMessage) {
      e.preventDefault();
      oldestLoadedMessage.focus();
    } else if (e.key === 'End') {
      e.preventDefault();
      messagesArr[messagesArr.length - 1].focus();
    }
  }
};

export default createEventHandlerA11y;
