import Svg, { Circle, Path } from 'svgs';
import { EmotionStyle, useEmotionTheme } from '../../core/styled';

interface NotepadCircleProps {
  width?: number;
  height?: number;
  titleText?: string;
  style?: EmotionStyle;
}

const NotepadCircle = ({
  width = 128,
  height = 128,
  titleText = 'Notepad',
  ...otherProps
}: NotepadCircleProps) => {
  const { colors } = useEmotionTheme();
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 56 56"
      title={titleText}
      aria-label={titleText}
      {...otherProps}
    >
      <Circle cx="28" cy="28" r="28" fill={colors.aquaSqueeze} />
      <Path
        d="M19 40C18.4444 40 17.9722 39.8031 17.5833 39.4094C17.1944 39.0156 17 38.5375 17 37.975V17.725C17 17.1625 17.1944 16.6844 17.5833 16.2906C17.9722 15.8969 18.4444 15.7 19 15.7H25.8333C25.9444 14.9125 26.3 14.2656 26.9 13.7594C27.5 13.2531 28.2 13 29 13C29.8 13 30.5 13.2531 31.1 13.7594C31.7 14.2656 32.0556 14.9125 32.1667 15.7H39C39.5556 15.7 40.0278 15.8969 40.4167 16.2906C40.8056 16.6844 41 17.1625 41 17.725V37.975C41 38.5375 40.8056 39.0156 40.4167 39.4094C40.0278 39.8031 39.5556 40 39 40H19ZM19 37.975H39V17.725H19V37.975ZM22.3333 34.6H31.4333V32.575H22.3333V34.6ZM22.3333 28.8625H35.6667V26.8375H22.3333V28.8625ZM22.3333 23.125H35.6667V21.1H22.3333V23.125ZM29 17.1512C29.3111 17.1512 29.5833 17.0331 29.8167 16.7969C30.05 16.5606 30.1667 16.285 30.1667 15.97C30.1667 15.655 30.05 15.3794 29.8167 15.1431C29.5833 14.9069 29.3111 14.7888 29 14.7888C28.6889 14.7888 28.4167 14.9069 28.1833 15.1431C27.95 15.3794 27.8333 15.655 27.8333 15.97C27.8333 16.285 27.95 16.5606 28.1833 16.7969C28.4167 17.0331 28.6889 17.1512 29 17.1512ZM19 37.975V17.725V37.975Z"
        fill={colors.purple}
      />
    </Svg>
  );
};

export default NotepadCircle;
