import { VoidFunctionComponent } from 'react';
import Svg, { Path, Rect } from 'svgs';
import { useEmotionTheme, EmotionStyle } from '../../core/styled';

interface SmallCalendarProps {
  color?: string;
  width?: number;
  height?: number;
  style?: EmotionStyle;
}

const SmallCalendarV2: VoidFunctionComponent<SmallCalendarProps> = ({
  width = 16,
  height = 27,
  color,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();

  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <Path
        d="M0.75 5C0.75 3.75736 1.75736 2.75 3 2.75H13C14.2426 2.75 15.25 3.75736 15.25 5V14C15.25 15.2426 14.2426 16.25 13 16.25H3C1.75736 16.25 0.75 15.2426 0.75 14V5Z"
        stroke={color || colors.slateGrey}
        strokeWidth="1.5"
      />
      <Rect x="1" y="3" width="14" height="3" fill={color || colors.slateGrey} />
      <Rect x="11" width="2" height="5" rx="1" fill={color || colors.slateGrey} />
      <Rect x="3" width="2" height="5" rx="1" fill={color || colors.slateGrey} />
      <Rect x="9" y="10" width="4" height="4" fill={color || colors.slateGrey} />
    </Svg>
  );
};

export default SmallCalendarV2;
