import { withVersioning } from '../../utils';
import { VersionMap } from '../../types';
import Breadcrumb, { BreadcrumbProps, BreadcrumbRoute } from './Breadcrumb';

const versionMap: VersionMap<React.ComponentProps<typeof Breadcrumb>> = {
  DefaultComponent: Breadcrumb,
};

export type { BreadcrumbProps, BreadcrumbRoute };

export default withVersioning(versionMap);
