import Svg, { Path } from 'svgs';
import { FunctionComponent } from 'react';
import { useEmotionTheme } from '../../core/styled';

interface MessagingModalityProps {
  width?: number;
  height?: number;
  color?: string;
}

const MessagingModality: FunctionComponent<MessagingModalityProps> = ({
  width = 26,
  height = 26,
  color,
  ...otherProps
}) => {
  const titleText = 'Messaging Modality';
  const { colors } = useEmotionTheme();

  return (
    <Svg
      title={titleText}
      width={width}
      height={height}
      viewBox="0 0 26 26"
      fill="none"
      {...otherProps}
    >
      <Path
        d="M1 6.84C1 4.71923 2.71923 3 4.84 3H21.16C23.2808 3 25 4.71923 25 6.84V18.16C25 20.2808 23.2808 22 21.16 22H4.84C2.71923 22 1 20.2808 1 18.16V6.84Z"
        fill={colors.white}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.84 3C2.71923 3 1 4.71923 1 6.84V18.16C1 20.2808 2.71923 22 4.84 22H21.16C23.2808 22 25 20.2808 25 18.16V6.84C25 4.71923 23.2808 3 21.16 3H4.84ZM6.21168 7.33582C5.80202 6.96542 5.16966 6.99726 4.79926 7.40692C4.42886 7.81659 4.4607 8.44895 4.87036 8.81935L13.1632 16.3173L21.1453 8.80583C21.5475 8.42734 21.5667 7.79447 21.1882 7.39227C20.8097 6.99007 20.1768 6.97085 19.7746 7.34934L13.1361 13.5965L6.21168 7.33582Z"
        fill={color || colors.purple}
      />
    </Svg>
  );
};

export default MessagingModality;
