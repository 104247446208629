import { FunctionComponent, MouseEvent, FocusEvent, TouchEvent } from 'react';
import * as React from 'react';
import styled from '@emotion/styled';
import { EmotionStyle } from '@/core/styled/styled';

interface SliderViewProps {
  value: number;
  maxValue: number;
  minValue?: number;
  className?: string;
  style?: EmotionStyle;
  onValueChange?: (value: number) => void;
  step?: number;
  onMouseDown: (e: MouseEvent<HTMLDivElement | MouseEvent> | TouchEvent) => void;
  onMouseUp: (e: MouseEvent<HTMLDivElement | MouseEvent> | TouchEvent) => void;
  onBlur: (e: FocusEvent<HTMLInputElement>) => void;
  disabled: boolean;
}

const Component = styled.input();

const SliderView: FunctionComponent<SliderViewProps> = ({
  step,
  style,
  value,
  minValue,
  maxValue,
  className,
  onValueChange,
  onMouseUp,
  onMouseDown,
  onBlur,
  disabled = false,
}) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onValueChange) onValueChange(Number(e.target.value));
  };
  return (
    <Component
      type="range"
      value={value}
      style={style}
      max={maxValue}
      onChange={onChange}
      onClick={(e) => e.stopPropagation()}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onTouchStart={onMouseDown}
      onTouchEnd={onMouseUp}
      min={minValue || 0}
      className={className}
      disabled={disabled}
      step={step}
      onBlur={onBlur}
    />
  );
};

export default SliderView;
