import { useEffect, useState } from 'react';
import { PlusMultiSelect, View, useEmotionTheme } from '@talkspace/react-toolkit';
import moment from 'moment';
import { OptionsType } from 'react-select';
import { getIcon } from '../../../CTA/CTACard';
import { getSelectStyleV2, BooleanDropdown, generateVideoCallSelectOption } from '../../../utils';
import { Container, Title, Subtitle, MinutesText, Line } from '../styles';
import SelectContainer from '../../containers/SelectContainer';
import { VideoCall } from '../../../types';
import trackEvent from '../../../../../../../modules/analytics/trackEvent';
import {
  useProgressNoteFormActions,
  useProgressNoteFormState,
} from '../../context/TherapistProgressNoteFormContext';
import { useRouteMatch } from '@/core/routerLib';

interface LiveSessionProps {
  duration: number | null;
  liveSessionType: 'chat' | 'audio' | 'video';
  unsubmittedFreeLiveCalls: VideoCall[];
  freeLiveCallIDs: number[];
  unsubmittedSessionID: string;
  scheduledStartDate: Date;
}

const formatOngoingCallAsOption = (videoCall?: any | null) => {
  if (!videoCall) return null;
  const { id, modality, callStartedAt, totalActiveCallDuration } = videoCall;
  if (!callStartedAt) return null;
  const startedDate = new Date(callStartedAt);
  return generateVideoCallSelectOption({
    id,
    roomID: videoCall.roomID,
    callStartedAt: startedDate,
    totalActiveCallDuration,
    modality,
  });
};

const LiveSession = ({
  duration,
  liveSessionType,
  unsubmittedFreeLiveCalls,
  freeLiveCallIDs,
  unsubmittedSessionID,
  scheduledStartDate,
}: LiveSessionProps) => {
  const icon = getIcon('live', liveSessionType);
  const [isAdditionalCalls, setIsAdditionalCalls] = useState<boolean>(false);
  const formattedCalls = unsubmittedFreeLiveCalls.map((item) => formatOngoingCallAsOption(item));
  const [videoCallsState, setVideoCallsState] = useState<OptionsType<any>>(
    formattedCalls.filter((item) => item && freeLiveCallIDs.includes(item.value))
  );
  const match = useRouteMatch<{ roomID: string; noteID?: string }>();

  const { colors } = useEmotionTheme();
  const { formMode } = useProgressNoteFormState();
  const { setFormState } = useProgressNoteFormActions();

  useEffect(() => {
    if (formMode === 'edit' && videoCallsState.length) {
      setIsAdditionalCalls(true);
    }
  }, [formMode, videoCallsState.length]);

  const handleChangeAdditionalCalls = (additionalVideos) => {
    if (additionalVideos) {
      setVideoCallsState(additionalVideos);
      const formattedAdditionalVideos = additionalVideos.map((item) => {
        return { videoCallID: item.value };
      });
      setFormState({ videoCalls: [...formattedAdditionalVideos] });
      trackEvent(
        'addNoteInfo',
        {
          actionName: 'progressNotesInteraction',
        },
        {
          action: 'Provider adds information to progress note',
          freeCalls: [...formattedAdditionalVideos],
          roomID: match.params.roomID,
          unsubmittedSessionID,
        }
      );
    } else {
      setVideoCallsState([]);
      setFormState({ videoCalls: [] });
    }
  };

  const handleIsAdditionalCalls = (value: boolean) => {
    setIsAdditionalCalls(value);
    if (!value) {
      setFormState({ videoCalls: [] });
    }
  };

  return (
    <>
      <Container style={{ height: 96 }}>
        <View row>
          <View style={{ marginRight: 11 }}>{icon}</View>
          <View>
            <Title>{moment(scheduledStartDate).format('dddd, MMMM D')}</Title>
            <Subtitle>
              Live {liveSessionType} session · {moment(scheduledStartDate).format('h:mma')}
            </Subtitle>
            <View row style={{ marginTop: 10 }}>
              {duration && (
                <MinutesText style={{ color: colors.forestGreen }}>
                  {Math.ceil(duration / 60)} min
                </MinutesText>
              )}
            </View>
          </View>
        </View>
      </Container>
      {unsubmittedFreeLiveCalls.length ? (
        <>
          <SelectContainer
            value={BooleanDropdown.find((it) => it.value === isAdditionalCalls) as any}
            title="Additional calls associated with session?"
            handleChange={((option) => handleIsAdditionalCalls(option?.value)) as any}
            options={BooleanDropdown as any}
            styles={getSelectStyleV2({ colors })}
          />
          {isAdditionalCalls && (
            <View style={{ marginBottom: 10 }} row>
              <Line />
              <View flex={1}>
                <Subtitle style={{ color: colors.black, marginBottom: 10 }}>
                  Select the live calls that you consider part of this session:
                </Subtitle>
                <PlusMultiSelect
                  options={formattedCalls as OptionsType<any>}
                  placeholder="Select"
                  isMulti
                  menuPlacement="auto"
                  width={245}
                  onChange={handleChangeAdditionalCalls}
                  value={videoCallsState}
                />
              </View>
            </View>
          )}
        </>
      ) : null}
    </>
  );
};

export default LiveSession;
