import { useMemo } from 'react';
import moment from 'moment';

const useRepeatingTimeslotsCopy = (timeslots) =>
  useMemo(() => {
    if (!timeslots || !timeslots.length) {
      return {
        everyWeek: null,
        dayName: null,
        startTimeString: null,
        endTimeString: null,
        startDateString: null,
        endDateString: null,
      };
    }
    const firstTimeslot = timeslots[0];
    const nextTimeslot = timeslots[1];
    const lastTimeslot = timeslots[timeslots.length - 1];
    const firstTimeslotStart = moment(firstTimeslot.start);
    const firstTimeslotEnd = moment(firstTimeslot.end);
    const nextTimeslotStart = moment(nextTimeslot.start);
    const lastTimeslotStart = moment(lastTimeslot.start);

    const nextWeek = nextTimeslotStart.isSame(moment(firstTimeslotStart).add(1, 'week'));
    const dayName = firstTimeslotStart.format('dddd');
    const startTimeString = firstTimeslotStart.format('h:mma');
    const endTimeString = firstTimeslotEnd.format('h:mma');

    const startDateString =
      firstTimeslotStart.year() === lastTimeslotStart.year()
        ? firstTimeslotStart.format('MMM D')
        : firstTimeslotStart.format('MMM D YYYY');
    const endDateString = lastTimeslotStart.format('MMM D, YYYY');

    return {
      everyWeek: !!nextWeek,
      dayName,
      startTimeString,
      endTimeString,
      startDateString,
      endDateString,
    };
  }, [timeslots]);

export default useRepeatingTimeslotsCopy;
