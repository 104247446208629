import { useMutation, useQueryClient } from 'react-query';
import apiHelper from '@/utils/api';
import apiWrapper from '../../utils/apiWrapper';
import { notesListQueryKey, psychotherapyNoteQueryKey } from './queryKeys';

export interface SavePsychotherapyNoteMutateVariables {
  roomID: string;
  noteID?: string;
  noteBody: string;
}

const savePsychotherapyNote = async (
  params: SavePsychotherapyNoteMutateVariables
): Promise<void> => {
  const { roomID, noteID, ...requestBody } = params;

  const requestURL = noteID
    ? `/v2/rooms/${roomID}/psychotherapy-notes/${noteID}`
    : `/v2/rooms/${roomID}/psychotherapy-notes`;

  const method: keyof typeof apiWrapper = noteID ? 'patch' : 'post';

  await apiWrapper[method](`${apiHelper().apiEndpoint}${requestURL}`, requestBody);
};

const useMutationSavePsychotherapyNote = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, SavePsychotherapyNoteMutateVariables>(savePsychotherapyNote, {
    onSuccess: (_, { roomID, noteID }) => {
      queryClient.invalidateQueries(notesListQueryKey(roomID));
      if (noteID) {
        queryClient.invalidateQueries(psychotherapyNoteQueryKey(roomID, noteID));
      }
    },
  });
};

export default useMutationSavePsychotherapyNote;
