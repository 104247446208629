import Svg, { Defs, Path, G, Mask, Use } from 'svgs';
import { useEmotionTheme } from '../../core/styled';
import { useUniqueID } from '../../hooks/a11yHelper';

const RatingStarHalfIcon = ({
  width = 24,
  height = 23,
  fill,
  stroke,
  ...props
}: {
  width?: number;
  height?: number;
  fill?: string;
  stroke?: string;
}) => {
  const { colors } = useEmotionTheme();
  const pathID = useUniqueID('halfStarPath');
  const maskID = useUniqueID('halfStarMask');
  const titleText = 'half star';
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 24 23"
      title={titleText}
      aria-label={titleText}
      {...props}
    >
      <Defs>
        <Path id={pathID} d="M0 0H145.2V30H0z" />
      </Defs>
      <G transform="translate(-133 -4)" fill="none" stroke="none">
        <Mask id={maskID} fill="#fff">
          <Use xlinkHref={`#${pathID}`} />
        </Mask>
        <Path
          d="M144.95 5.887l3.843 5.454 6.505 2.1-3.973 5.351.022 6.865-6.3-2.142-6.496 2.142.082-6.679-4.033-5.537 6.347-1.985 4.002-5.569z"
          stroke="#59C123"
          strokeWidth={1.51272727}
          fill={fill || colors.green}
          mask={`url(#${maskID})`}
        />
        <Path
          d="M144.95 5.887l3.843 5.454 6.505 2.1-3.973 5.351.022 6.865-6.3-2.142-6.496 2.142.082-6.679-4.033-5.537 6.347-1.985 4.002-5.569z"
          stroke={stroke || colors.green}
          strokeWidth={1.51272727}
        />
      </G>
    </Svg>
  );
};

export default RatingStarHalfIcon;
