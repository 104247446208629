import { VoidFunctionComponent } from 'react';
import Svg, { Path } from 'svgs';
import { useEmotionTheme } from '../../core/styled';

interface CircledPauseProps {
  color?: string;
  width?: number;
  height?: number;
}

const CircledPause: VoidFunctionComponent<CircledPauseProps> = ({
  color,
  width = 19,
  height = 19,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  return (
    <Svg width={width} height={height} viewBox="0 0 19 19" fill="none" {...otherProps}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.883 17.35a8.496 8.496 0 01-3.427.701 8.487 8.487 0 01-3.433-.7 9.166 9.166 0 01-2.83-1.92 9.055 9.055 0 01-1.925-2.83 8.52 8.52 0 01-.7-3.437c0-1.213.232-2.355.696-3.428A9.007 9.007 0 013.189 2.9 9.117 9.117 0 016.023.981 8.513 8.513 0 019.446.285c1.213 0 2.355.232 3.428.696a9.102 9.102 0 014.764 4.755 8.546 8.546 0 01.696 3.428c0 1.218-.23 2.364-.691 3.437a8.889 8.889 0 01-1.92 2.83 9.208 9.208 0 01-2.84 1.92zM6.52 16.127c.913.387 1.891.58 2.937.58a7.446 7.446 0 005.339-2.199 7.624 7.624 0 001.619-2.407c.39-.912.581-1.891.575-2.936 0-1.04-.195-2.015-.585-2.927a7.624 7.624 0 00-1.619-2.408 7.448 7.448 0 00-5.34-2.199 7.414 7.414 0 00-5.329 2.2 7.558 7.558 0 00-1.614 2.407 7.424 7.424 0 00-.58 2.927c0 1.045.194 2.024.58 2.936a7.559 7.559 0 001.614 2.407 7.466 7.466 0 002.403 1.62zm.478-3.558h.854c.216 0 .374-.05.473-.153a.555.555 0 00.148-.403V6.334c0-.173-.05-.31-.148-.413-.1-.102-.257-.153-.473-.153h-.854c-.21 0-.366.051-.468.153a.558.558 0 00-.154.413v5.678c0 .167.052.301.154.403.102.102.258.153.468.153zm4.917 0h-.863c-.21 0-.366-.05-.468-.153a.546.546 0 01-.153-.403V6.334c0-.173.05-.31.153-.413.102-.102.258-.153.468-.153h.863c.204 0 .357.051.46.153.101.102.152.24.152.413v5.678a.546.546 0 01-.153.403c-.102.102-.255.153-.459.153z"
        fill={colors.slateGrey}
      />
    </Svg>
  );
};

export default CircledPause;
