const availabilityKeys = {
  all: ['availabilities'],
  capacity: (therapistID: number): string[] => [
    ...availabilityKeys.all,
    'capacity',
    therapistID.toString(),
  ],
  therapistAvailability: (therapistID: number): string[] => [
    ...availabilityKeys.all,
    'therapistAvailability',
    therapistID.toString(),
  ],
  therapistAvailabilityV2: (therapistID: number): string[] => [
    ...availabilityKeys.all,
    'therapistAvailabilityV2',
    therapistID.toString(),
  ],
  therapistAvailabilityV3: (
    therapistID: number,
    params: { start?: string; end?: string } = { start: '', end: '' }
  ) => [...availabilityKeys.all, 'therapistAvailabilityV3', therapistID.toString(), params],
};

export default availabilityKeys;
