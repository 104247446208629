import { Standard, TouchableView, View } from '@talkspace/react-toolkit';
import { useCallback } from 'react';
import styled from '../../core/styled';
import ssoHelper from '@/utils/sso';
import Analytics from './Analytics';

const Button = styled(TouchableView)(({ theme: { colors } }) => {
  return {
    display: 'inline-block',
    color: colors.accessibilityGreenDark,
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '20px',
    marginLeft: 3,
    textDecoration: 'underline',
  };
});

const AnswerWrapper = styled(Standard)(({ theme: { colors } }) => {
  return {
    paddingRight: 5,
    display: 'inline-block',
    maxWidth: '100%',
    color: colors.purple600,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '20px',
  };
});

const QuestionTextWrapper = styled(View)({
  fontSize: 16,
  fontWeight: 700,
  marginTop: 20,
  marginBottom: 10,
});

const MainTitle = styled(View)({ fontSize: 18, fontWeight: 700, marginTop: 20 });

const QuestionSection = ({ question, answer }: { question: string; answer: JSX.Element }) => (
  <>
    <QuestionTextWrapper>{question}</QuestionTextWrapper>
    <View>{answer}</View>
  </>
);

const FrequentlyAskedQuestions = ({ year, month }: { year: number; month: number }) => {
  const getQuestions = useCallback(
    (): Array<{ question: string; answer: JSX.Element }> => [
      {
        question: 'How can I earn a bigger bonus?',
        answer: (
          <AnswerWrapper inline>
            You can earn a larger bonus by working more each month. Two ways to increase your hours
            are (1){' '}
            <Button
              onPress={() => {
                Analytics.trackBonusVizModalAvailabilityButtonClickedEvent({
                  selectedYear: year,
                  selectedMonth: month + 1,
                });
                window.location.href = '/calendar';
              }}
            >
              increasing your availability for live sessions
            </Button>{' '}
            and (2){' '}
            <Button
              onPress={() => {
                Analytics.trackBonusVizModalGrowCaseloadButtonClickedEvent({
                  selectedYear: year,
                  selectedMonth: month + 1,
                });
                window.location.href = '/grow-caseload';
              }}
            >
              adding clients to your caseload.
            </Button>
          </AnswerWrapper>
        ),
      },
      {
        question: 'What activities count toward my clinical hours?',
        answer: (
          <AnswerWrapper inline>
            Clinical hours for Live Sessions (video, audio, and chat) will be calculated based on
            the duration of the session. Clinical hours for Asynchronous Messaging will be
            calculated at 40 words per minute for texting, 150 words per PDF file, and message
            duration for audio or video messages. Learn more about our{' '}
            <Button
              onPress={() => {
                Analytics.trackBonusVizModalClinicalEngagementBonusButtonClickedEvent({
                  selectedYear: year,
                  selectedMonth: month + 1,
                });
                ssoHelper.openZendesk('/hc/en-us/articles/360056628992');
              }}
            >
              Clinical Engagement Bonus.
            </Button>
          </AnswerWrapper>
        ),
      },
      {
        question: 'When is my bonus paid out?',
        answer: (
          <AnswerWrapper inline>
            If you qualify for a bonus during any month, your bonus will be paid out on the payout
            date for the second payout period for that month. See our{' '}
            <Button
              onPress={() => {
                Analytics.trackBonusVizModalPayoutScheduleButtonClickedEvent({
                  selectedYear: year,
                  selectedMonth: month + 1,
                });
                ssoHelper.openZendesk(
                  '/hc/en-us/articles/360056628992#h_01H2GMFTXV0VM5J9T5GQ9TK79D'
                );
              }}
            >
              Payout Schedule.
            </Button>
          </AnswerWrapper>
        ),
      },
      {
        question: 'Why do the hours needed for each bonus tier shift slightly each month?',
        answer: (
          <AnswerWrapper inline>
            Some months are shorter than others so we adjust the hours needed for bonuses according
            to the number of days in each month. This ensures you have an equal opportunity each
            month to reach a bonus tier.
          </AnswerWrapper>
        ),
      },
    ],
    [year, month]
  );

  return (
    <View style={{ fontFamily: 'Roboto' }}>
      <MainTitle>Frequently Asked Questions</MainTitle>

      {getQuestions()?.map((it) => (
        <QuestionSection key={it.question} question={it.question} answer={it.answer} />
      ))}
    </View>
  );
};

export default FrequentlyAskedQuestions;
