import { View, styled, Standard, TouchableView } from '@talkspace/react-toolkit';
import PinkAlert from 'components/Icons/PinkAlert';
import { TextLink } from './styles';
import ssoHelper from '../../../../../../modules/utils/sso';

const Container = styled(View)(({ theme: { colors } }) => {
  return {
    padding: 20,
    paddingRight: 15,
    width: 335,
    borderRadius: 7,
    border: '2px solid #EB3575',
    margin: '25px auto 0 auto',
  };
});

const Title = styled(Standard)(({ theme: { colors } }) => {
  return {
    fontSize: 15,
    fontWeight: 700,
  };
});

const Subtitle = styled(Standard)(({ theme: { colors } }) => {
  return {
    fontSize: 14,
    fontWeight: 400,
    color: colors.black,
    marginTop: 8,
  };
});

const StyledButton = styled(TouchableView)(({ theme: { colors } }) => {
  return {
    fontSize: 14,
    fontWeight: 600,
    color: colors.accessibilityGreenDark,
    flexDirection: 'row-reverse',
  };
});

const SecondaryButton = styled(TouchableView)(({ theme: { colors } }) => {
  return {
    width: 'fit-content',
    height: 30,
    backgroundColor: colors.accessibilityGreenDark,
    padding: '6px 20px',
    fontSize: 14,
    fontWeight: 600,
    color: colors.white,
    textAlign: 'center',
    borderRadius: 8,
    marginLeft: 20,
  };
});

interface NonPaybleDialogProps {
  primaryButtonAction: () => void;
  primaryButtonText: string;
  secondaryButtonAction?: () => void;
  secondaryButtonText?: string;
  title: string;
  text: string;
  link?: string;
  textLink?: string;
  isLoading?: boolean;
}

const ProgressNoteDialog = ({
  primaryButtonAction,
  primaryButtonText,
  secondaryButtonAction,
  secondaryButtonText,
  isLoading = false,
  title,
  text,
  link,
  textLink,
}: NonPaybleDialogProps) => (
  <View style={{ width: 375, height: '100%', overflow: 'hidden' }}>
    <Container>
      <View row>
        <PinkAlert style={{ marginRight: 8 }} />
        <Title>{title}</Title>
      </View>
      <Subtitle>
        {text}{' '}
        {link && textLink && (
          <TextLink
            style={{ whiteSpace: 'unset' }}
            text={textLink}
            target="_blank"
            onClick={() => ssoHelper.openZendesk(link)}
          />
        )}
      </Subtitle>
      <View row align="center" justify="end" style={{ marginTop: 22 }}>
        <StyledButton
          disabled={isLoading}
          onPress={primaryButtonAction}
          dataQa={`${primaryButtonText}Button`}
        >
          {primaryButtonText}
        </StyledButton>
        {secondaryButtonText && (
          <SecondaryButton
            disabled={isLoading}
            onPress={secondaryButtonAction}
            dataQa={`${secondaryButtonText}Button`}
          >
            {secondaryButtonText}
          </SecondaryButton>
        )}
      </View>
    </Container>
  </View>
);

export default ProgressNoteDialog;
