import { VoidFunctionComponent } from 'react';
import View from '../View';
import styled, { EmotionStyle } from '../../core/styled';

interface FadeOutProps {
  topOffset: number;
  width?: string;
  height?: number;
  style?: EmotionStyle;
}

const FadeOutView = styled(View)<{
  width: string;
  height: number;
  topOffset: number;
}>(({ width, height, topOffset }) => {
  return {
    width,
    height,
    marginTop: topOffset,
    backgroundImage: 'linear-gradient(to top, rgba(255,255,255,1),rgba(255,255,255,0))',
    backgroundRepeat: 'repeat-x',
    pointerEvents: 'none',
    zIndex: 1,
  };
});

const FadeOut: VoidFunctionComponent<FadeOutProps> = ({
  width = '100%',
  height = 100,
  topOffset,
  style = {},
}) => <FadeOutView width={width} height={height} topOffset={topOffset} style={style} />;

export default FadeOut;
