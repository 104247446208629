import { Component } from 'react';
import { Checkbox, FormControl } from 'react-bootstrap';
import './CheckListEditor.css';

const getStyle = (disabled, hidden, checked) => {
  let style = 'checkbox-green';
  if (disabled) style = `${style} disabled`;
  if (hidden) style = `${style} hidden`;
  if (checked) style = `${style} checked`;
  return style;
};

class CheckListEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: { ...props.item },
      style: getStyle(props.disabled, props.hidden, props.item.checked),
    };

    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.textArea = null;
  }

  onBlur = (event) => {
    const { unCheckEmpty, displayField, textAreaChangeHandler } = this.props;
    const { item } = this.state;
    if (unCheckEmpty) {
      const newItem = { ...item, [displayField]: item[displayField].trim() };
      if (newItem[displayField] === item[displayField]) return;
      this.setState({
        item: newItem,
      });
      textAreaChangeHandler(event, newItem);
      this.textArea.value = newItem[displayField];
    }
  };

  handleKeyUp(event) {
    const {
      props: { displayField, textAreaChangeHandler, disabled, hidden, unCheckEmpty },
      state: { item },
    } = this;
    const { value } = event.target;
    const newItem = {
      ...item,
      [displayField]: value,
      checked: unCheckEmpty && value.trim() === '' ? false : item.checked,
    };
    this.setState({
      item: newItem,
      style: getStyle(disabled, hidden, newItem.checked),
    });

    textAreaChangeHandler(event, newItem);
  }

  render() {
    const {
      props: { placeholder, displayField, checkboxChangeHandler, disabled, hidden },
      state: { item, style },
    } = this;
    return (
      <div className="checklist-editor">
        <Checkbox
          className={style}
          checked={item.checked}
          onChange={(event) => {
            const newItem = { ...item, checked: event.target.checked };
            this.setState({
              item: newItem,
              style: getStyle(disabled, hidden, newItem.checked),
            });
            checkboxChangeHandler(event, newItem);
          }}
          disabled={disabled}
          hidden={hidden}
        />
        <FormControl
          inputRef={(ref) => (this.textArea = ref)}
          componentClass="textarea"
          placeholder={placeholder}
          defaultValue={item[displayField]}
          onKeyUp={this.handleKeyUp}
          onBlur={this.onBlur}
        />
      </div>
    );
  }
}

export default CheckListEditor;
