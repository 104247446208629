import { FunctionComponent, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import useQueryBusinessLine from 'ts-frontend/hooks/useQueryBusinessLine';
import useQueryReferralConditions from 'hooks/useQueryReferralConditions';
import useQueryDischargeNote from 'hooks/notes/useQueryDischargeNote';
import Submenu from 'components/Reusable/Submenu';
import moment from 'moment';
import useMutationUpdateDischargeNote from 'hooks/notes/useMutationUpdateDischargeNote';
import useMutationSaveDraftDischargesNote, {
  SaveDraftDischargeNoteMutateVariables,
} from 'hooks/notes/useMutationSaveDraftDischargeNote';
import * as clinicalInformationActions from 'actions/ClinicalInformationActions';
import {
  View,
  Large,
  styled,
  Select,
  TextAreaRounded,
  useEmotionTheme,
  Checkbox,
  OptionType,
  Small,
  DatePickerInput,
  Tiny,
  A11Y_COLORS,
  Link,
} from '@talkspace/react-toolkit';
import {
  clientProgress,
  therapyDischargeReasons,
  psychiatryDischargeReasons,
} from '@talkspace/configs';
import { useLocation } from 'react-router-dom';
import { useHistory, useParams } from '@/core/routerLib';
import {
  ACTION_SETTLED_TIMEOUT,
  getSelectStyle,
  getSubmenuTitleAndSubtitle,
  getTextAreaContainerBorderStyle,
  getTextAreaStyle,
  OTHER_REFERRAL_ID,
} from './utils';
import { Diagnosis, DischargeNoteFormErrors, DischargeNoteFormState, FormMode } from './types';
import ErrorScreen from './ErrorScreen';
import configs, { getOptionsByField } from '../../../../utils/tsConfigsValues';
import NoteFormFooter from './NoteFormFooter';
import IndentedView from './IndentedView';
import StatementCertification from './StatementCertification';
import ssoHelper from '@/utils/sso';

const { conditionsNotBillableIDs, conditionsBillable } = configs;

const DischargeInstructions = styled(View)(({ theme: { colors } }) => {
  return {
    color: colors.grey,
  };
});

const SmallText = styled(Small)({
  marginBottom: 12,
});

const DISCHARGE_PLAN_MIN_LENGTH = 50;

interface Condition {
  id: number;
  isWorkingDiagnosis: boolean;
  label: string;
  value: number;
}

interface LocationState {
  formState?: DischargeNoteFormState;
}

const InputSection = styled(View)({
  marginBottom: 15,
});

const transformConfigToOptions = (configsValue: Record<string, string>): Array<OptionType> =>
  Object.entries(configsValue).map((it) => {
    const [value, label] = it;
    return { value, label };
  });

interface Props {
  mode: FormMode;
  clientUserID: number;
  conditions: Array<Condition>;
  getConditions: (clientUserID: number) => void;
}

const DischargeNoteForm: FunctionComponent<Props> = ({
  mode,
  clientUserID,
  conditions,
  getConditions,
}) => {
  const {
    mutate: updateDischargeNote,
    isLoading: isSaveLoading,
    isError: isSaveError,
  } = useMutationUpdateDischargeNote();
  const {
    mutate: saveDraftDischargeNote,
    isLoading: isSaveDraftLoading,
    isError: isSaveDraftError,
  } = useMutationSaveDraftDischargesNote();

  const [showSpinner, setShowSpinner] = useState(false);
  const [formErrors, setFormErrors] = useState<DischargeNoteFormErrors>({
    globalValidationError: false,
    diagnosesError: false,
    clientProgressError: false,
    referralIDError: false,
    otherReferralError: false,
    dischargePlanError: false,
    dischargeReasonError: false,
    otherDischargeReasonError: false,
    statementCertifiedError: false,
  });

  const [formState, setFormState] = useState<DischargeNoteFormState>({
    dischargeDate: new Date(),
    diagnoses: [],
    dischargeReason: null,
    clientProgress: null,
    referralID: null,
    statementCertified: false,
    dischargePlan: null,
    otherDischargeReason: null,
    otherReferral: null,
  });

  const [dischargeReasonOption, setDischargeReasonOption] = useState<OptionType<
    string | null
  > | null>();

  const [dischargeReasonOptions, setDischargeReasonOptions] = useState<OptionType<string | null>[]>(
    []
  );

  const [clientProgressOptions, setClientProgressOptions] = useState<OptionType<string | null>[]>(
    []
  );

  const [referralOptions, setReferralOptions] = useState<OptionType<number | null>[]>([]);

  const { roomID, noteID } = useParams<{ roomID: string; noteID?: string }>();
  const history = useHistory();
  const { state } = useLocation();
  const { formState: oldFormState } = (state as LocationState) || {};

  const {
    isLoading: isDischargeNoteLoading,
    isError: isDischargeNoteError,
    data: dischargeNoteData,
  } = useQueryDischargeNote(clientUserID, noteID, mode === 'edit' || mode === 'view');
  const isReadOnly = dischargeNoteData?.readOnly;
  const noteRoomID = dischargeNoteData?.roomID?.toString() || roomID;
  const {
    isLoading: isBusinessLineLoading,
    isError: isBusinessLineError,
    data: businessLineData,
  } = useQueryBusinessLine(noteRoomID, clientUserID);

  const {
    isLoading: isReferralLoading,
    isError: isReferralError,
    data: referralData,
  } = useQueryReferralConditions();

  const validateClientProgress = (): boolean => !!formState.clientProgress;
  const validateReferralID = (): boolean => !!formState.referralID;
  const validateDischargePlan = (): boolean =>
    formState.dischargePlan ? formState.dischargePlan.length > DISCHARGE_PLAN_MIN_LENGTH : false;
  const validateStatementCertified = (): boolean => formState.statementCertified;
  const validateDischargeReason = (): boolean => !!formState.dischargeReason;
  const validateDiagnoses = (): boolean => formState.diagnoses.length > 0;
  const validateOtherDischargeReason = (): boolean => {
    if (dischargeReasonOption?.value === 'other') return !!formState.otherDischargeReason;
    return true;
  };
  const validateOtherReferral = (): boolean => {
    if (formState.referralID === OTHER_REFERRAL_ID) return !!formState.otherReferral;
    return true;
  };

  const validateFormState = () => {
    const dischargePlanError = !validateDischargePlan();
    const clientProgressError = !validateClientProgress();
    const referralIDError = !validateReferralID();
    const dischargeReasonError = !validateDischargeReason();
    const otherDischargeReasonError = !validateOtherDischargeReason();
    const otherReferralError = !validateOtherReferral();
    const statementCertifiedError = !validateStatementCertified();
    const diagnosesError = !validateDiagnoses();
    const globalValidationError =
      dischargePlanError ||
      clientProgressError ||
      referralIDError ||
      dischargeReasonError ||
      otherDischargeReasonError ||
      otherReferralError ||
      statementCertifiedError ||
      diagnosesError;
    setFormErrors({
      ...formErrors,
      dischargePlanError,
      clientProgressError,
      referralIDError,
      dischargeReasonError,
      otherDischargeReasonError,
      otherReferralError,
      statementCertifiedError,
      globalValidationError,
      diagnosesError,
    });
    return !globalValidationError;
  };

  useEffect(() => {
    if (clientUserID) {
      getConditions(clientUserID);
    }
  }, [clientUserID, getConditions]);

  useEffect(() => {
    if (conditions?.length && mode === 'create') {
      setFormState((stateParam) => {
        return {
          ...stateParam,
          diagnoses: conditions.map((it): Diagnosis => {
            return { conditionID: it.value };
          }),
        };
      });
    }
  }, [conditions, mode]);

  useEffect(() => {
    if (oldFormState) {
      setFormState({
        ...oldFormState,
        diagnoses: oldFormState.diagnoses.map((diagnosis) => {
          return {
            conditionID: diagnosis.conditionID,
          };
        }),
      });
      if (dischargeReasonOptions.length) {
        const selectedOption = dischargeReasonOptions.find(
          (option) => option.value === oldFormState.dischargeReason
        );
        setDischargeReasonOption(selectedOption);
      }
    }
  }, [dischargeReasonOptions, oldFormState]);

  useEffect(() => {
    if (!oldFormState && dischargeNoteData) {
      setFormState({
        diagnoses: dischargeNoteData.diagnoses.map((diagnosis) => {
          return {
            conditionID: diagnosis.conditionID,
          };
        }),
        dischargeReason: dischargeNoteData.dischargeReason,
        clientProgress: dischargeNoteData.clientProgress,
        referralID: dischargeNoteData.referralID,
        statementCertified: dischargeNoteData.statementCertified,
        dischargePlan: dischargeNoteData.dischargePlan,
        otherDischargeReason: dischargeNoteData.otherDischargeReason,
        otherReferral: dischargeNoteData.otherReferral,
        dischargeDate: new Date(dischargeNoteData.dischargeDate),
      });

      if (dischargeReasonOptions.length) {
        const selectedOption = dischargeReasonOptions.find(
          (option) => option.value === dischargeNoteData.dischargeReason
        );
        setDischargeReasonOption(selectedOption);
      }
    }
  }, [dischargeNoteData, dischargeReasonOptions, oldFormState]);

  useEffect(() => {
    if (referralData) {
      const options = referralData.map((ref) => {
        return { value: ref.id, label: ref.condition };
      });
      setReferralOptions(options);
    }
  }, [referralData]);

  useEffect(() => {
    let reasonOptions;
    if (businessLineData) {
      reasonOptions = businessLineData.isPsychiatry
        ? transformConfigToOptions(psychiatryDischargeReasons)
        : transformConfigToOptions(therapyDischargeReasons);
      setDischargeReasonOptions(reasonOptions);
      setClientProgressOptions(transformConfigToOptions(clientProgress));
    }
  }, [businessLineData]);

  const { colors } = useEmotionTheme();
  const handleDiagnosisChange = (newValues: OptionType<string>[]) => {
    if (newValues === null || newValues.length === 0) {
      setFormState({ ...formState, diagnoses: [] });
    } else {
      const selectedDiagnosesIDs = newValues.map((it) => it.value);
      const hasBillable = selectedDiagnosesIDs?.some((value) => conditionsBillable[value]);
      if (!hasBillable) {
        setFormState({
          ...formState,
          diagnoses: [],
        });
      } else {
        setFormState({
          ...formState,
          diagnoses: selectedDiagnosesIDs.map((it): Diagnosis => {
            return { conditionID: Number(it) };
          }),
        });
      }

      setFormErrors({ ...formErrors, diagnosesError: false });
    }
  };

  const handleDischargeDateChange = (date: moment.Moment | null) => {
    if (date) {
      setFormState({ ...formState, dischargeDate: date.toDate() });
    }
  };

  const handleClientProgressChange = (option: OptionType<string> | null) => {
    setFormState({ ...formState, clientProgress: option?.value || null });
    setFormErrors({ ...formErrors, clientProgressError: false });
  };

  const handleDischargeReason = (option: OptionType<string> | null) => {
    setFormState({ ...formState, dischargeReason: option?.value || null });
    setDischargeReasonOption(option);
    setFormErrors({ ...formErrors, dischargeReasonError: false });
  };

  const handleOtherDischargeReasonChange = (newReason: string) => {
    setFormState({ ...formState, otherDischargeReason: newReason || null });
    setFormErrors({ ...formErrors, otherDischargeReasonError: false });
  };

  const handleDischargePlanDescriptionChange = (description: string) => {
    setFormState({ ...formState, dischargePlan: description || null });
    setFormErrors({ ...formErrors, dischargePlanError: false });
  };

  const handleReferralChange = (referral: OptionType<number | null> | null) => {
    setFormState({ ...formState, referralID: referral?.value || null });
    setFormErrors({ ...formErrors, referralIDError: false });
  };

  const handleOtherReferralChange = (newReferral: string) => {
    setFormState({ ...formState, otherReferral: newReferral || null });
    setFormErrors({ ...formErrors, otherReferralError: false });
  };

  const handleIsCertifiedChange = (isCertified: boolean) => {
    setFormState({ ...formState, statementCertified: isCertified });
    setFormErrors({ ...formErrors, statementCertifiedError: false });
  };

  const handleActionSettled = () => {
    setTimeout(() => {
      setShowSpinner(false);
      history.push(`/room/${roomID}/notes-tab`);
    }, ACTION_SETTLED_TIMEOUT);
  };

  const handleDraftButtonClick = () => {
    setShowSpinner(true);
    saveDraftDischargeNote(
      { roomID, noteID, ...formState },
      {
        onSettled: handleActionSettled,
      }
    );
  };

  const handleDeleteButtonClick = () => {
    if (noteID) {
      history.push(`/room/${roomID}/discharge-notes/${noteID}/delete`);
    }
  };

  const handleSaveButtonClick = () => {
    setShowSpinner(true);
    if (validateFormState()) {
      updateDischargeNote(
        { roomID, noteID, clientUserID, ...formState },
        {
          onSettled: handleActionSettled,
        }
      );
    } else {
      setShowSpinner(false);
    }
  };

  const handleCancelButtonClick = () => {
    history.goBack();
  };

  const handleEditButtonClick = () => {
    const baseURL = `/room/${roomID}/discharge-notes`;
    history.push(`${baseURL}/${noteID}/edit`);
  };

  const handleSubmitButtonClick = () => {
    setShowSpinner(true);
    if (validateFormState()) {
      const confirmDischargeURL = `/room/${roomID}/discharge-notes/confirm-discharge`;
      history.push(`${confirmDischargeURL}`, { formState, noteID, mode });
    } else {
      setShowSpinner(false);
    }
  };

  const handleCloseErrorClick = () =>
    setFormErrors({ ...formErrors, globalValidationError: false });

  const hasSelectedBillable = formState.diagnoses.some(
    ({ conditionID }) => conditionsBillable[conditionID]
  );
  const disabledValues = hasSelectedBillable ? null : conditionsNotBillableIDs;
  const { isEAP } = businessLineData || {};

  const renderMainView = () => (
    <View style={{ paddingLeft: 10, paddingRight: 10 }}>
      {mode !== 'view' && (
        <DischargeInstructions>
          <SmallText style={{ marginBottom: 20 }}>
            Submit this note to formally end services with your client and remove them from your
            caseload.
          </SmallText>

          <SmallText style={{ marginBottom: 20 }}>
            Your client will be notified that services are over and you will no longer be able to
            contact each other through the platform.
          </SmallText>

          <SmallText variant="smallDarkGrey">
            Learn more about
            <Link
              onClick={() => {
                ssoHelper.openZendesk(
                  '/hc/en-us/articles/11048550915227-How-do-I-clinically-discharge-or-terminate-treatment-with-a-client'
                );
              }}
              style={{ textDecoration: 'none', fontWeight: 'bold', color: A11Y_COLORS.greenText }}
              text="discharging clients."
              dataQa="discharging-clients-link"
            />
          </SmallText>

          {businessLineData?.isPsychiatry && (
            <View style={{ marginBottom: 20 }}>
              You can remove the client from your caseload by going to the Case tab &gt; Actions
              &gt; Transfer client.
            </View>
          )}
        </DischargeInstructions>
      )}

      <DatePickerInput
        disabled={mode === 'view'}
        isError={false}
        placeholder="Discharge date"
        calendarWrapperStyles={{ zIndex: 99 }}
        inputComponentType="TextInput"
        currentValue={moment(formState.dischargeDate)}
        handleInputValueChange={handleDischargeDateChange}
      />
      <InputSection>
        <Select
          key={JSON.stringify({ disabledValues })}
          styles={getSelectStyle(colors, formErrors.diagnosesError)}
          placeholder="Diagnosis"
          isDisabled={mode === 'view'}
          options={getOptionsByField('conditions')}
          value={getOptionsByField('conditions').filter((opt: OptionType<number>) =>
            formState.diagnoses.map((diagnosis) => diagnosis.conditionID).includes(opt.value)
          )}
          onChange={handleDiagnosisChange as any}
          isMulti
          isOptionDisabled={(option) => {
            if (!disabledValues?.length) return false;
            return option.value ? disabledValues.includes(`${option.value}`) : false;
          }}
          dataQa="diagnosisDropdown"
        />
        {!hasSelectedBillable && !isEAP && (
          <Tiny>Non-billable diagnoses are disabled until a billable diagnosis is selected.</Tiny>
        )}
        {isEAP && <Tiny>Diagnoses are optional for clients on EAP plans.</Tiny>}
      </InputSection>
      <InputSection>
        <Select
          styles={getSelectStyle(colors, formErrors.clientProgressError)}
          placeholder="Client progress at discharge"
          isDisabled={mode === 'view'}
          options={clientProgressOptions as any}
          value={
            clientProgressOptions.find(
              (clientProgressOption) => clientProgressOption.value === formState.clientProgress
            ) as any
          }
          onChange={handleClientProgressChange as any}
          dataQa="clientProgressDropdown"
        />
      </InputSection>
      <InputSection>
        <Select
          styles={getSelectStyle(colors, formErrors.dischargeReasonError)}
          placeholder="Reason for discharge"
          isDisabled={mode === 'view'}
          options={dischargeReasonOptions as any}
          value={dischargeReasonOption as any}
          onChange={handleDischargeReason as any}
          dataQa="reasonForDischargeDropdown"
        />
      </InputSection>
      {dischargeReasonOption?.value === 'other' && (
        <InputSection>
          <IndentedView>
            <TextAreaRounded
              autoSize
              style={{
                ...getTextAreaContainerBorderStyle(colors, formErrors.otherDischargeReasonError),
                marginBottom: 10,
              }}
              placeholder="Describe the reason for discharge"
              textAreaStyle={getTextAreaStyle(mode === 'view')}
              value={formState.otherDischargeReason || ''}
              onChangeText={handleOtherDischargeReasonChange}
              disabled={mode === 'view'}
              hasLimit
              dataQa="describeTheReasonTextarea"
            />
          </IndentedView>
        </InputSection>
      )}
      <InputSection>
        <TextAreaRounded
          autoSize
          style={{
            ...getTextAreaContainerBorderStyle(colors, formErrors.dischargePlanError),
          }}
          placeholder="Describe the discharge plan"
          textAreaStyle={getTextAreaStyle(mode === 'view')}
          value={formState.dischargePlan || ''}
          onChangeText={handleDischargePlanDescriptionChange}
          disabled={mode === 'view'}
          hasLimit
          dataQa="describeTheDischargePlanTextarea"
        />
        {formErrors.dischargePlanError && (
          <View style={{ color: colors.permaDebianRed }}>at least 50 characters</View>
        )}
      </InputSection>
      <InputSection>
        <Select
          styles={getSelectStyle(colors, formErrors.referralIDError)}
          placeholder="Referral"
          isDisabled={mode === 'view'}
          options={referralOptions as any}
          value={
            (formState.referralID
              ? referralOptions.find((ref) => ref.value === formState.referralID)
              : { value: null, label: 'None' }) as any
          }
          onChange={handleReferralChange as any}
          dataQa="referralDropdown"
        />
      </InputSection>
      {formState.referralID === OTHER_REFERRAL_ID && (
        <InputSection>
          <IndentedView>
            <TextAreaRounded
              autoSize
              disabled={mode === 'view'}
              style={{
                ...getTextAreaContainerBorderStyle(colors, formErrors.otherReferralError),
                marginBottom: 10,
              }}
              placeholder="Describe the referral for discharge"
              textAreaStyle={getTextAreaStyle(mode === 'view')}
              value={formState.otherReferral || ''}
              onChangeText={handleOtherReferralChange}
              hasLimit
              dataQa="describeTheReferralTextarea"
            />
          </IndentedView>
        </InputSection>
      )}
      <InputSection>
        <Checkbox
          shouldDisplayError={formErrors.statementCertifiedError}
          isDisabled={mode === 'view'}
          isLabelOnRight
          stretch={false}
          alignCenter={false}
          isChecked={formState.statementCertified as any}
          setIsChecked={handleIsCertifiedChange}
          labelStyle={{ color: colors.black, fontWeight: 400 }}
          label="I certify the above statement to be accurate as of the digital date signed."
          dataQa="certifyCheckbox"
        />
      </InputSection>

      {dischargeNoteData?.submittedAt && mode === 'view' && (
        <StatementCertification
          user={dischargeNoteData.createdByUser}
          submittedAt={new Date(dischargeNoteData.submittedAt)}
        />
      )}
    </View>
  );

  const isError = isReferralError || isBusinessLineError || isDischargeNoteError;
  const isLoading =
    isSaveLoading ||
    isReferralLoading ||
    isBusinessLineLoading ||
    isDischargeNoteLoading ||
    isSaveDraftLoading;

  const renderFooter = () => (
    <NoteFormFooter
      formMode={mode}
      noteStatus={dischargeNoteData?.status}
      canDeleteDraft={!!noteID}
      canDeleteSubmitted={false}
      isError={isSaveError || isSaveDraftError}
      isLoading={isLoading}
      editHidden={isReadOnly}
      showSpinner={showSpinner}
      spinnerMessage="Saved successfully"
      showErrorBanner={formErrors.globalValidationError}
      handleCloseErrorBannerPress={handleCloseErrorClick}
      handleDeleteDraftPress={handleDeleteButtonClick}
      handleSaveDraftPress={handleDraftButtonClick}
      handleSubmitPress={handleSubmitButtonClick}
      handleCancelPress={handleCancelButtonClick}
      handleSavePress={handleSaveButtonClick}
      handleEditPress={handleEditButtonClick}
    />
  );

  if (isError) {
    return <ErrorScreen message="Something went wrong" />;
  }

  const { title, subtitle } = getSubmenuTitleAndSubtitle({
    formMode: mode,
    noteType: 'discharge',
    noteDate: dischargeNoteData?.dischargeDate,
  });

  const handleBackButtonPress = () => {
    if (mode === 'create') {
      const locationState: SaveDraftDischargeNoteMutateVariables = {
        roomID,
        clientUserID,
        ...formState,
      };

      history.push(`/room/${roomID}/discharge-notes/save-draft`, locationState);
    } else {
      history.push(`/room/${roomID}/notes-tab`);
    }
  };

  return (
    <Submenu
      title={title}
      titleComponent={
        subtitle ? (
          <View>
            <Large variant="largeBoldWide">{title}</Large>
            <Small variant="smallBoldGrey">{subtitle}</Small>
          </View>
        ) : undefined
      }
      childComponents={[renderMainView()]}
      footerComponent={renderFooter()}
      onBackAlt={handleBackButtonPress}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    clientUserID: state.room.clientUserID,
    conditions: state.clinicalInformation.conditions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getConditions: (clientUserID: number) =>
      dispatch(clinicalInformationActions.getConditions(clientUserID)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DischargeNoteForm);
