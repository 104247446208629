import { FunctionComponent } from 'react';
import Svg, { G, Circle, Path } from 'svgs';
import { EmotionStyle } from '../../core/styled';

interface HourGlassProps {
  color?: string;
  width?: number;
  style?: EmotionStyle;
}

const HourGlass: FunctionComponent<HourGlassProps> = ({ color, width = 22, style }) => {
  const titleText = 'Hourglass';
  return (
    <Svg width={width} title={titleText} aria-label={titleText} viewBox="0 0 22 22" style={style}>
      <G fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <G transform="translate(-118 -543)">
          <G transform="translate(104 523)">
            <G>
              <G transform="translate(14 20)">
                <G>
                  <Circle cx="11" cy="11" r="11" fill={color || '#6F63DC'} />
                  <G fill="#FFF" fillRule="nonzero" transform="translate(7 5)">
                    <Path d="M7.423 1.374v-.292C7.423.485 6.938 0 6.341 0h-4.76C.986 0 .502.485.5 1.082v.292c.001.84.35 1.64.962 2.214l2.182 2.037-2.182 2.037A3.04 3.04 0 00.5 9.876v.292c0 .597.485 1.081 1.082 1.082h4.76c.596 0 1.08-.485 1.081-1.082v-.292a3.04 3.04 0 00-.962-2.214L4.279 5.625 6.46 3.588a3.04 3.04 0 00.962-2.214zM5.87 9.592c.145.136.27.291.372.462a.218.218 0 01-.185.33H1.866a.218.218 0 01-.185-.33c.101-.17.227-.326.372-.461L3.962 7.81 5.87 9.592zm.372-7.098c-.102.17-.227.326-.372.462L3.962 4.738l-1.91-1.781a2.126 2.126 0 01-.371-.462.218.218 0 01.185-.332h4.191a.218.218 0 01.185.331z" />
                  </G>
                </G>
              </G>
            </G>
          </G>
        </G>
      </G>
    </Svg>
  );
};

export default HourGlass;
