import * as actionTypes from '../constants/AvailabilityConstants';

const initialState = {
  availabilityNameMapping: {
    1: 'Dedicated',
    2: 'Available',
    4: 'Wrap Up',
  },
  availabilityOptions: [
    {
      name: 'Dedicated',
      value: 1,
    },
    {
      name: 'Available',
      value: 2,
    },
    {
      name: 'Wrap Up',
      value: 4,
    },
  ],
  availability: undefined,
  id: undefined,
  isLocked: undefined,
  therapistType: undefined,
  isW2: undefined,
  dailyCaseLoad: undefined,
  dailyCaseLoadOptions: undefined,
  phase: '',
  businessDays: {},
  videoSessionConstraints: [],
  currentTz: '',
  validCapacities: [],
  capacity: 0,
  daysToWaitToUpdate: 0,
  mayUpdateCapacity: false,
  requestAvailabilityError: false,
  updateAvailabilityError: false,
  isUpdatingAvailability: false,
  isUpdatingAvailabilityPut: false,
  isUpdatingBusinessDays: false,
  isUpdatingCapacity: true,
  isUpdatingDepartureInfo: false,
  isUpdatingPhase: false,
  isUpdatingVideoSessionHours: false,
  isError: false,
  toasterMessage: '',
  departureDate: null,
  phaseOutDate: null,
};

export default function availability(state = initialState, action) {
  switch (action.type) {
    case actionTypes.REQUEST_GET_AVAILABILITY:
      return {
        ...state,
        isUpdatingAvailability: true,
        requestAvailabilityError: false,
      };

    case actionTypes.REQUEST_GET_AVAILABILITY_ERROR:
      return {
        ...state,
        isUpdatingAvailability: false,
        requestAvailabilityError: true,
      };

    case actionTypes.RECEIVE_GET_AVAILABILITY:
      return {
        ...state,
        availability: action.availability,
        id: action.id,
        isLocked: action.locked === 1,
        therapistType: action.therapist_type,
        isUpdatingAvailability: false,
        requestAvailabilityError: false,
        isW2: action.isW2,
      };

    case actionTypes.REQUEST_PUT_AVAILABILITY:
      return {
        ...state,
        isUpdatingAvailabilityPut: true,
        updateAvailabilityError: false,
      };

    case actionTypes.RECEIVE_PUT_AVAILABILITY:
      return {
        ...state,
        availability: action.availability,
        isUpdatingAvailabilityPut: false,
        updateAvailabilityError: false,
      };

    case actionTypes.REQUEST_PUT_AVAILABILITY_ERROR:
      return {
        ...state,
        isUpdatingAvailabilityPut: false,
        updateAvailabilityError: true,
      };

    case actionTypes.REQUEST_GET_VIDEO_SESSION_HOURS:
      return {
        ...state,
        toasterMessage: '',
        isUpdatingVideoSessionHours: true,
        isError: false,
      };

    case actionTypes.REQUEST_PUT_VIDEO_SESSION_HOURS:
      return {
        ...state,
        toasterMessage: '',
        isUpdatingVideoSessionHours: true,
        isError: false,
      };

    case actionTypes.REQUEST_GET_CAPACITY:
      return {
        ...state,
        toasterMessage: '',
        isUpdatingCapacity: true,
        isError: false,
      };

    case actionTypes.REQUEST_PUT_CAPACITY:
      return {
        ...state,
        toasterMessage: '',
        isUpdatingCapacity: true,
        isError: false,
      };

    case actionTypes.REQUEST_GET_PHASE:
      return {
        ...state,
        toasterMessage: '',
        isUpdatingPhase: true,
        isError: false,
      };

    case actionTypes.REQUEST_GET_BUSINESS_DAYS:
      return {
        ...state,
        toasterMessage: '',
        isUpdatingBusinessDays: true,
        isError: false,
      };

    case actionTypes.REQUEST_SAVE_BUSINESS_DAYS:
      return {
        ...state,
        toasterMessage: '',
        isUpdatingBusinessDays: true,
        isError: false,
      };
    case actionTypes.RECEIVE_GET_VIDEO_SESSION_HOURS:
      return {
        ...state,
        toasterMessage: '',
        videoSessionConstraints: action.constraints,
        isUpdatingVideoSessionHours: false,
        isError: false,
      };

    case actionTypes.RECEIVE_PUT_VIDEO_SESSION_HOURS:
      return {
        ...state,
        toasterMessage: 'Video session hours saved.',
        videoSessionConstraints: action.constraints,
        isUpdatingVideoSessionHours: false,
        isError: false,
      };

    case actionTypes.RECEIVE_GET_CAPACITY:
      return {
        ...state,
        toasterMessage: '',
        dailyCaseLoad: action.data.dailyCaseLoad,
        capacityUpdatedAt: action.data.updatedAt,
        isUpdatingCapacity: false,
        isError: false,
      };

    case actionTypes.RECEIVE_GET_BUSINESS_DAYS:
      return {
        ...state,
        toasterMessage: '',
        currentTz: action.data.tz,
        businessDays: action.data.businessDays,
        isUpdatingBusinessDays: false,
        isError: false,
      };

    case actionTypes.RECEIVE_SAVE_BUSINESS_DAYS:
      return {
        ...state,
        toasterMessage: 'Business days and timezone saved.',
        isUpdatingBusinessDays: true,
        isError: false,
      };

    case actionTypes.RECEIVE_PUT_CAPACITY:
      return {
        ...state,
        toasterMessage: 'Capacity updated.',
        isUpdatingCapacity: false,
        isError: false,
      };

    case actionTypes.RECEIVE_GET_PHASE:
      return {
        ...state,
        toasterMessage: '',
        phase: action.data.phase,
        isUpdatingPhase: false,
        isError: false,
      };

    case actionTypes.REQUEST_GET_BUSINESS_DAYS_ERROR:
      return {
        ...state,
        toasterMessage: 'Could not load business days and timezone!',
        isUpdatingBusinessDays: false,
        isError: true,
      };

    case actionTypes.REQUEST_SAVE_BUSINESS_DAYS_ERROR:
      return {
        ...state,
        toasterMessage: 'Could not save business days and timezone!',
        isUpdatingBusinessDays: false,
        isError: true,
      };

    case actionTypes.REQUEST_GET_VIDEO_SESSION_HOURS_ERROR:
      return {
        ...state,
        toasterMessage: 'Missing video session hours!',
        isUpdatingVideoSessionHours: false,
        isError: true,
      };

    case actionTypes.REQUEST_PUT_VIDEO_SESSION_HOURS_ERROR:
      return {
        ...state,
        toasterMessage: 'Could not save video session hours!',
        isUpdatingVideoSessionHours: false,
        isError: true,
      };

    case actionTypes.REQUEST_PUT_VIDEO_SESSION_HOURS_AUTH_ERROR:
      return {
        ...state,
        toasterMessage: 'Please accept an invitation from Timekit in your email, then try again!',
        isUpdatingVideoSessionHours: false,
        isError: true,
      };

    case actionTypes.REQUEST_GET_CAPACITY_ERROR:
      return {
        ...state,
        toasterMessage: '',
        isUpdatingCapacity: false,
        isError: true,
      };

    case actionTypes.REQUEST_PUT_CAPACITY_ERROR:
      return {
        ...state,
        toasterMessage: 'Could not save capacity!',
        isUpdatingCapacity: false,
        isError: true,
      };

    case actionTypes.REQUEST_GET_PHASE_ERROR:
      return {
        ...state,
        toasterMessage: 'Could not load client capacity list!',
        isUpdatingPhase: false,
        isError: true,
      };
    case actionTypes.REQUEST_GET_DEPARTURE_INFO:
      return {
        ...state,
        toasterMessage: '',
        isUpdatingDepartureInfo: true,
        isError: false,
      };
    case actionTypes.RECEIVE_GET_DEPARTURE_INFO:
      return {
        ...state,
        toasterMessage: '',
        isUpdatingDepartureInfo: false,
        isError: false,
        departureDate: action.departureDate,
        phaseOutDate: action.phaseOutDate,
      };
    case actionTypes.GET_DEPARTURE_INFO_ERROR:
      return {
        ...state,
        toasterMessage: 'Could not load departure info!',
        isUpdatingDepartureInfo: false,
        isError: true,
      };
    case actionTypes.REQUEST_GET_PROVIDER_DAILY_CASELOAD_OPTIONS:
      return {
        ...state,
        isUpdatingCapacity: true,
        isError: false,
      };
    case actionTypes.RECEIVE_GET_PROVIDER_DAILY_CASELOAD_OPTIONS:
      return {
        ...state,
        isUpdatingCapacity: false,
        isError: false,
        dailyCaseLoadOptions: action.dailyCaseLoadOptions,
      };
    case actionTypes.REQUEST_GET_PROVIDER_DAILY_CASELOAD_OPTIONS_ERROR:
      return {
        ...state,
        isUpdatingCapacity: false,
        isError: true,
      };

    default:
      return state;
  }
}
