import Svg, { Rect, G, Path, Defs, Stop } from 'svgs';

const NoNotesIcon = () => (
  <Svg width="57" height="65" viewBox="0 0 57 65" fill="none">
    <G>
      <Path
        d="M8 44V9C8 5.13401 11.134 2 15 2H42C45.866 2 49 5.13401 49 9V44C49 47.866 45.866 51 42 51H15C11.134 51 8 47.866 8 44Z"
        fill="white"
        stroke="url(#paint0_linear)"
        strokeWidth="4"
      />
      <Rect x="17" y="31" width="23" height="3" rx="1.5" fill="#AFAFBD" />
      <Rect x="17" y="38" width="23" height="3" rx="1.5" fill="#AFAFBD" />
    </G>
    <Path
      d="M21.6447 18.0838L27.1271 23.099L35.5279 13.0977"
      stroke="url(#paint1_linear)"
      strokeWidth="4"
      strokeLinecap="round"
    />
    <Defs>
      <filter
        id="filter0_d"
        x="0"
        y="0"
        width="57"
        height="65"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="6" />
        <feGaussianBlur stdDeviation="3" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0.0392157 0 0 0 0 0.298039 0 0 0 0.05 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
      <linearGradient
        id="paint0_linear"
        x1="11.8087"
        y1="13.3624"
        x2="11.8087"
        y2="52.6796"
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#C2C2CF" />
        <Stop offset="1" stopColor="#A2A2B0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="11.6295"
        y1="8.89629"
        x2="13.3147"
        y2="33.4336"
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#B3EB50" />
        <Stop offset="1" stopColor="#469723" />
      </linearGradient>
    </Defs>
  </Svg>
);

export default NoNotesIcon;
