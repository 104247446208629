import { FunctionComponent, useState } from 'react';
import { Avatar, View, Big } from '@talkspace/react-toolkit';

import styled, { EmotionStyle } from '@/core/styled/styled';

const commonStyles: EmotionStyle = {
  height: 42,
  width: 42,
};

const ShortNameIcon = styled(View)<{ color: string }>(({ color }) => {
  return {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 50,
    backgroundColor: color,
    ...commonStyles,
  };
});

interface Props {
  url?: string;
  iconColor?: string;
  shortName?: string;
}

const UserAvatar: FunctionComponent<Props> = ({ url, iconColor = '#084BA9', shortName }) => {
  const [isError, setIsError] = useState(false);

  return url && !isError ? (
    <Avatar
      image={url}
      width="100%"
      style={commonStyles}
      onError={() => {
        setIsError(true);
      }}
    />
  ) : (
    <ShortNameIcon color={iconColor}>
      <Big variant="bigWide">{shortName}</Big>
    </ShortNameIcon>
  );
};

export default UserAvatar;
