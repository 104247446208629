import SocketService from '@/utils/socket/SocketService';
import { MessageAcks } from '../constants/chatTypes';
import { EMessage } from '../../entities/EMessage';

const findMaxInArray = (arr, propertyName) =>
  arr.reduce((prev, current) => (prev > current[propertyName] ? prev : current[propertyName]), 0);

const findMinInArray = (arr, propertyName) => {
  if (Array.isArray(arr) && arr.length) {
    return arr.reduce(
      (prev, current) => (prev < current[propertyName] ? prev : current[propertyName]),
      Number.MAX_VALUE
    );
  }
  return 0;
};

export default class AcksSocketService {
  roomID: number;

  userID: number;

  isTherapistChat: boolean;

  lastReadAckedSuccessId = 0;

  updateAcksDispatch: Function;

  io: SocketService;

  cachedRead = 0;

  hiddenKey = '';

  visibilityChangeEvent = '';

  prevMessagesSize = 0;

  isChatVisible = true;

  constructor(
    updateAcksDispatch: Function,
    roomID: number,
    userID: number,
    isTherapistChat: boolean
  ) {
    this.roomID = roomID;
    this.userID = userID;
    this.isTherapistChat = isTherapistChat;
    this.updateAcksDispatch = updateAcksDispatch;
    this.io = SocketService.instance();
    this.socketInit();
    this.visibleInit();
  }

  private socketInit = () => {
    this.io.on('acksCursors', this.roomCursors);
  };

  private visibleInit = () => {
    if (!window.document) return;

    const visibilityEvent = [
      { name: 'hidden', event: 'visibilitychange' },
      { name: 'msHidden', event: 'msvisibilitychange' },
      { name: 'webkitHidden', event: 'webkitvisibilitychange' },
      { name: 'mozHidden', event: 'mozvisibilitychange' },
    ];

    visibilityEvent.forEach((ev) => {
      if (typeof window.document[ev.name] !== 'undefined') {
        this.hiddenKey = ev.name;
        this.visibilityChangeEvent = ev.event;
      }
    });
    if (!this.visibilityChangeEvent) return;
    if (window.document.addEventListener)
      window.document.addEventListener(this.visibilityChangeEvent, this.documentVisibleChange);
  };

  private isVisible = () => {
    if (!this.hiddenKey) return this.isChatVisible; // visibility api isn't supported
    return !window.document[this.hiddenKey] && this.isChatVisible;
  };

  private documentVisibleChange = () => {
    if (this.isVisible() && this.cachedRead) {
      this.readAck(this.cachedRead);
    }
  };

  private roomCursors = (msgAcks: any): void => {
    if (msgAcks.roomId !== this.roomID) return;
    const otherUsers = msgAcks.cursors.filter((x) => x.userId !== this.userID);
    const acks: MessageAcks = {
      maxAckReceivedId: findMaxInArray(otherUsers, 'ackReceivedId'),
      maxAckReadId: findMinInArray(otherUsers, 'ackReadId'),
    };

    // load last acked info from server
    const myAckedHistory = msgAcks.cursors.filter((x) => x.userId === this.userID);

    if (myAckedHistory.length) {
      this.lastReadAckedSuccessId = myAckedHistory[0].ackReadId;
    }

    this.updateAcksDispatch(acks, this.roomID, this.lastReadAckedSuccessId);
  };

  public unmount = () => {
    this.io.off('acksCursors', this.roomCursors);
    if (document.removeEventListener && this.visibilityChangeEvent)
      document.removeEventListener(this.visibilityChangeEvent, this.documentVisibleChange);
  };

  private readAck = (ackId) => {
    if (this.isVisible()) {
      this.cachedRead = 0;
      this.io.emit('readAck', { roomId: this.roomID, ackId }).catch(() => undefined);
    } else {
      this.cachedRead = ackId;

      // emit receive ack if its a client, the therapist emits receive on T-web navbar.
      if (!this.isTherapistChat)
        this.io.emit('receiveAck', { roomId: this.roomID, ackId }).catch(() => undefined);
    }
  };

  public receiveAck = (ackId) => {
    this.io.emit('receiveAck', { roomId: this.roomID, ackId }).catch(() => undefined);
  };

  public updatedMessages = (messages: EMessage[]): void => {
    const deltaMessages = messages.slice(this.prevMessagesSize);
    this.prevMessagesSize = messages.length;

    const maxMessageId = Math.max(
      ...deltaMessages.map((m) => (this.userID !== m.user.id ? m.id : 0)),
      0
    );
    if (maxMessageId > this.lastReadAckedSuccessId) {
      this.readAck(maxMessageId);
    }
  };

  public setChatVisible(isChatVisible: boolean) {
    this.isChatVisible = isChatVisible;
    this.documentVisibleChange();
  }
}
