import Svg, { Defs, G, Path, Rect } from 'svgs';

const HorizontalScrollLeftArrowHoverIcon = () => (
  <Svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
    {/* @ts-ignore */}
    <G filter="url(#filter0_d_900_10807)">
      <Rect
        width="35"
        height="35"
        rx="17.5"
        transform="matrix(1 1.74846e-07 1.74846e-07 -1 8 41)"
        fill="white"
      />
      <Path
        d="M28.2235 14.5758C28.4568 14.8091 28.5735 15.0841 28.5735 15.4008C28.5735 15.7174 28.4568 15.9924 28.2235 16.2258L20.9485 23.5008L28.2485 30.8008C28.4818 31.0341 28.5941 31.3091 28.5855 31.6258C28.5775 31.9424 28.4568 32.2174 28.2235 32.4508C27.9901 32.6841 27.7151 32.8008 27.3985 32.8008C27.0818 32.8008 26.8068 32.6841 26.5735 32.4508L18.2735 24.1508C18.1901 24.0508 18.1275 23.9468 18.0855 23.8388C18.0441 23.7301 18.0235 23.6174 18.0235 23.5008C18.0235 23.3841 18.0441 23.2718 18.0855 23.1638C18.1275 23.0551 18.1901 22.9508 18.2735 22.8508L26.5985 14.5508C26.8151 14.3174 27.0818 14.2048 27.3985 14.2128C27.7151 14.2214 27.9901 14.3424 28.2235 14.5758Z"
        fill="#005C65"
      />
    </G>
    <Defs>
      <filter
        id="filter0_d_900_10807"
        x="0"
        y="0"
        width="51"
        height="51"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="4"
          operator="erode"
          in="SourceAlpha"
          result="effect1_dropShadow_900_10807"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="6" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.788235 0 0 0 0 0.827451 0 0 0 0 0.862745 0 0 0 1 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_900_10807" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_900_10807"
          result="shape"
        />
      </filter>
    </Defs>
  </Svg>
);

export default HorizontalScrollLeftArrowHoverIcon;
