import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const standardPath =
  'M2 1C2 0.446875 1.55313 0 1 0C0.446875 0 0 0.446875 0 1V15C0 15.5531 0.446875 16 1 16C1.55313 16 2 15.5531 2 15V11L13.5312 6.675C13.8125 6.56875 14 6.3 14 6C14 5.7 13.8125 5.43125 13.5312 5.325L2 1Z';

const pathConfig: IconConfig = {
  standard: {
    default: {
      path: standardPath,
    },
  },
};

const FlagPennant = withDSIconMaker(pathConfig, 'Flag Pennant');

export default FlagPennant;
