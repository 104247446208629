import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const standardPath =
  'M7 2.39648C7.55312 2.39648 8 2.84336 8 3.39648V4.39648H12V3.39648C12 2.84336 12.4469 2.39648 13 2.39648C13.5531 2.39648 14 2.84336 14 3.39648V4.39648H15.5C16.3281 4.39648 17 5.06836 17 5.89648V7.39648H3V5.89648C3 5.06836 3.67188 4.39648 4.5 4.39648H6V3.39648C6 2.84336 6.44688 2.39648 7 2.39648ZM3 8.39648H17V16.8965C17 17.7246 16.3281 18.3965 15.5 18.3965H4.5C3.67188 18.3965 3 17.7246 3 16.8965V8.39648ZM13.2812 11.9277C13.575 11.634 13.575 11.159 13.2812 10.8684C12.9875 10.5777 12.5125 10.5746 12.2219 10.8684L9.25313 13.8371L7.78438 12.3684C7.49063 12.0746 7.01562 12.0746 6.725 12.3684C6.43437 12.6621 6.43125 13.1371 6.725 13.4277L8.725 15.4277C9.01875 15.7215 9.49375 15.7215 9.78438 15.4277L13.2812 11.9277Z';

const pathConfig: IconConfig = {
  standard: {
    default: { path: standardPath },
  },
};

const CalendarCheck = withDSIconMaker(pathConfig, 'calendar with checkmark');

export default CalendarCheck;
