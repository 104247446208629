import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

const UserButtons = ({ title, isSecondary, clickHandler }) => (
  <Row>
    <Col
      sm={12}
      md={12}
      lg={12}
      className={`card-button ${isSecondary ? 'secondary' : ''}`}
      onClick={clickHandler}
    >
      {title}
    </Col>
  </Row>
);

UserButtons.propTypes = {
  title: PropTypes.string,
};

export default UserButtons;
