import {
  createContext,
  useMemo,
  useCallback,
  useContext,
  FC,
  PropsWithChildren,
  ComponentProps,
  useRef,
  useState,
  useEffect,
} from 'react';
import { useFlags as useLDFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { AllLaunchDarklyExperimentNames, LDFlags, LDUntypedExperiments } from 'ts-frontend/types';
import { Spinner, useObjectState, View } from '@talkspace/react-toolkit';
import { info, warn } from 'ts-frontend/utils/dev-console';

interface SetFlagFunction {
  <
    T extends
      | keyof LDFlags
      | ((flags: LDFlags, originalFlags: LDFlags, overrides: Partial<LDFlags>) => LDFlags)
  >(
    key: T,
    value?: T extends keyof LDFlags ? Partial<LDFlags[T]> : never
  ): void;
}

type FlagsWithSetFlagFunction<TFlags extends LDUntypedExperiments = LDFlags> = TFlags & {
  original: TFlags;
  setFlag: SetFlagFunction;
};

/**
 * Applications that are interested in consuming LaunchDarkly flags should never call LaunchDarkly's
 * `useFlags()` hook directly. They should instead call our *own* implementation of `useFlags()`
 * that is exported below.
 *
 * We're implementing a layer of abstraction here that allows descendant components to manually
 * override flag values when necessary.
 *
 * @see https://martinfowler.com/articles/feature-toggles.html#ImplementationTechniques - De-coupling decision points from decision logic
 * @see https://www.split.io/blog/dos-and-donts-of-feature-flags/#do-build-a-wrapper-around-your-feature-flagging-framework - The Dos and Don’ts of Feature Flags
 */
export const FlagsContext = createContext<FlagsWithSetFlagFunction<LDFlags> | undefined>(undefined);

interface Props {
  defaultOverrides?: Partial<LDFlags>;
}

/**
 * This function, along with the `useTemporaryFlags` hook, exists to support non-technical stakeholders
 * who need a mechanism for forcibly setting feature flag state into a desired configuration.
 */
(window as any).forceTemporaryFlags = (options) => {
  sessionStorage.setItem('temporaryFlags', JSON.stringify(options));
  window.location.reload();
};

const useTemporaryFlags = () =>
  useMemo(() => {
    try {
      return JSON.parse(sessionStorage.getItem('temporaryFlags') || '') || {};
    } catch (err) {
      return {};
    }
  }, []);

export function FlagsProvider({ children, defaultOverrides = {} }: PropsWithChildren<Props>) {
  const [isClientReady, setIsClientReady] = useState(false);
  const ldClient = useLDClient();

  useEffect(() => {
    const onInitialized = () => {
      info('LaunchDarkly: Client is ready');
      setIsClientReady(true);
    };

    const onFailure = (err: Error) => {
      warn('LaunchDarkly: Client failed startup', err);
      setIsClientReady(true);
    };
    if (!isClientReady && ldClient) {
      ldClient.waitForInitialization().then(onInitialized).catch(onFailure);
    }
  }, [ldClient, isClientReady]);

  const ldFlags = useLDFlags<LDFlags>();
  const [overrides, setOverrides] = useObjectState<Partial<LDFlags>>(defaultOverrides);
  const temporaryFlags = useTemporaryFlags();

  const finalFlags = useMemo(() => {
    const result = {
      ...ldFlags,
      ...temporaryFlags,
      ...overrides,
    };
    (window as any).showFlags = () => {
      console.log('You have been assigned the following feature flags:', result); // eslint-disable-line no-console
    };
    return result;
  }, [ldFlags, overrides, temporaryFlags]);
  const finalFlagsRef = useRef(finalFlags);
  finalFlagsRef.current = finalFlags;

  const ldFlagsRef = useRef({ ...ldFlags, ...temporaryFlags });
  ldFlagsRef.current = { ...ldFlags, ...temporaryFlags };

  const overridesRef = useRef(overrides);
  overridesRef.current = overrides;

  const setFlag = useCallback<SetFlagFunction>(
    (key, value) => {
      if (typeof key === 'function') {
        const newOverrides = key(finalFlagsRef.current, ldFlagsRef.current, overridesRef.current);
        if (Object.keys(newOverrides).length > 0) {
          setOverrides(newOverrides);
        }
      } else if (typeof value === 'object') {
        setOverrides({
          [key as keyof LDFlags]: {
            ...finalFlagsRef.current[key as keyof LDFlags],
            ...value,
          },
        });
      } else {
        setOverrides({
          [key as string]: value,
        });
      }
    },
    [setOverrides]
  );

  const contextValue = useMemo(() => {
    return {
      ...finalFlags,
      original: ldFlags,
      setFlag,
    };
  }, [finalFlags, setFlag, ldFlags]);

  // Prevent rendering content without feature flags
  if (!isClientReady)
    return (
      <View style={{ height: '100vh' }}>
        <View flex={1}>
          <Spinner isLoading />
        </View>
      </View>
    );

  return <FlagsContext.Provider value={contextValue}>{children}</FlagsContext.Provider>;
}

export const withFlagsProvider =
  <T extends {}>(Component: FC<T>) =>
  (props: ComponentProps<typeof Component>) =>
    (
      <FlagsProvider>
        <Component {...props} />
      </FlagsProvider>
    );

export function useFlags<T extends LDUntypedExperiments = LDFlags>(): FlagsWithSetFlagFunction<
  T & LDFlags
> {
  const flags = useContext(FlagsContext);
  if (!flags) throw new Error('Cannot use useFlags outside of FlagsProvider');
  // Context returns all keys. This casting is necessary because context only returns known typed keys for TS purposes
  // If the caller knows better types, we should respect it.
  return flags as FlagsWithSetFlagFunction<T & LDFlags>;
}

export function useFlag<TFlagName extends AllLaunchDarklyExperimentNames>(
  flagName: TFlagName
): LDFlags[TFlagName] {
  const flags = useContext(FlagsContext);
  if (!flags) throw new Error('Cannot use useFlag outside of FlagsProvider');
  return flags[flagName];
}
