import { LDFlags } from 'ts-frontend/types';
import { useFlags } from 'launchdarkly-react-client-sdk';

export default function useFlagsSafe(): Partial<LDFlags> {
  try {
    const flags = useFlags();
    return flags;
  } catch (flagsError) {
    return {};
  }
}
