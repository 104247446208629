import { UntypedTranslationKeys, TranslationNamespace } from '../types';
import useTranslation from './useTranslation';

// NOTE: Using this function cuts out translation key autocompletion. Only use it when a key is programmatically generated
const useStringTranslation = (
  fallbackString: string,
  namespace: TranslationNamespace | undefined,
  translationKey: UntypedTranslationKeys
): string => {
  const { t } = useTranslation(namespace);
  return t(translationKey, fallbackString, undefined); // NOTE: this 'undefined' is intentional, removing will cause type recursion depth limit error. When we upgrade to i18next v23 this might be removed
};

export default useStringTranslation;
