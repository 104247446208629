import { COLORS } from '@talkspace/react-toolkit';
import Svg, { Path } from 'svgs';

const TimeIcon = ({ width = 15, height = 15, color = COLORS.slateGrey, ...otherProps }) => (
  <Svg width={width} height={height} viewBox="0 0 15 15" fill="none" {...otherProps}>
    <Path
      d="M7.49994 14.9999C3.36361 14.9999 0 11.6363 0 7.49994C0 3.36357 3.36357 0 7.49994 0C7.99994 0 8.40894 0.409007 8.40894 0.909002V3.06804C8.40894 3.56803 7.99994 3.97704 7.49994 3.97704C6.99994 3.97704 6.59094 3.56803 6.59094 3.06804V1.88619C3.88637 2.3182 1.81825 4.68174 1.81825 7.49978C1.81825 10.6362 4.36364 13.1816 7.50002 13.1816C10.6364 13.1816 13.1818 10.6362 13.1818 7.49978C13.1818 5.97706 12.5909 4.54521 11.4999 3.47704C11.1363 3.11337 11.1363 2.54519 11.4999 2.18152C11.8636 1.81785 12.4318 1.81785 12.7955 2.18152C14.2273 3.61336 15 5.49975 15 7.49966C15 11.636 11.6364 14.9996 7.50006 14.9996L7.49994 14.9999Z"
      fill={color}
    />
    <Path
      d="M7.49994 8.40901C7.24994 8.40901 6.97726 8.29534 6.79544 8.09084C6.47727 7.7045 6.52277 7.1363 6.90911 6.81816L9.72731 4.45462C10.1137 4.13645 10.6818 4.18195 11 4.56829C11.3182 4.95463 11.2727 5.52283 10.8863 5.84097L8.09077 8.2045C7.90893 8.34084 7.70443 8.40901 7.4999 8.40901L7.49994 8.40901Z"
      fill={color}
    />
  </Svg>
);

export default TimeIcon;
