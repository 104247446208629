import Svg, { Mask, Path } from 'svgs';

interface Props {
  width?: string | number;
  height?: string | number;
  color?: string;
}

const BonusBreakdownLiveSessionsIcon = ({ width = 20, height = 20, color = '#68768D' }: Props) => (
  <Svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <Mask id="path-1-inside-1_1026_4215" fill="white">
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.8224 4C2.26363 4 1 5.26363 1 6.8224V12.546C1 14.1048 2.26363 15.3684 3.8224 15.3684H10.1078C11.6666 15.3684 12.9302 14.1048 12.9302 12.546V6.8224C12.9302 5.26363 11.6666 4 10.1078 4H3.8224ZM14.3571 7.38551C13.9936 7.58309 13.7673 7.96368 13.7673 8.37743V10.5731C13.7673 10.9868 13.9936 11.3674 14.3571 11.565L17.3318 13.1817C18.084 13.5906 18.9998 13.046 18.9998 12.1898V6.76068C18.9998 5.90448 18.084 5.3599 17.3317 5.76876L14.3571 7.38551Z"
      />
    </Mask>
    <Path
      d="M14.3571 7.38551L15.0734 8.70343L15.0734 8.70343L14.3571 7.38551ZM14.3571 11.565L13.6408 12.8829H13.6408L14.3571 11.565ZM17.3318 13.1817L18.0481 11.8638L17.3318 13.1817ZM17.3317 5.76876L18.0481 7.08668V7.08668L17.3317 5.76876ZM2.5 6.8224C2.5 6.09206 3.09206 5.5 3.8224 5.5V2.5C1.4352 2.5 -0.5 4.43521 -0.5 6.8224H2.5ZM2.5 12.546V6.8224H-0.5V12.546H2.5ZM3.8224 13.8684C3.09206 13.8684 2.5 13.2764 2.5 12.546H-0.5C-0.5 14.9332 1.4352 16.8684 3.8224 16.8684V13.8684ZM10.1078 13.8684H3.8224V16.8684H10.1078V13.8684ZM11.4302 12.546C11.4302 13.2764 10.8382 13.8684 10.1078 13.8684V16.8684C12.495 16.8684 14.4302 14.9332 14.4302 12.546H11.4302ZM11.4302 6.8224V12.546H14.4302V6.8224H11.4302ZM10.1078 5.5C10.8382 5.5 11.4302 6.09206 11.4302 6.8224H14.4302C14.4302 4.4352 12.495 2.5 10.1078 2.5V5.5ZM3.8224 5.5H10.1078V2.5H3.8224V5.5ZM15.2673 8.37743C15.2673 8.51341 15.1929 8.63849 15.0734 8.70343L13.6408 6.06759C12.7943 6.52768 12.2673 7.41396 12.2673 8.37743H15.2673ZM15.2673 10.5731V8.37743H12.2673V10.5731H15.2673ZM15.0734 10.2471C15.1929 10.312 15.2673 10.4371 15.2673 10.5731H12.2673C12.2673 11.5365 12.7943 12.4228 13.6408 12.8829L15.0734 10.2471ZM18.0481 11.8638L15.0734 10.2471L13.6408 12.8829L16.6154 14.4997L18.0481 11.8638ZM17.4998 12.1898C17.4998 11.9084 17.8008 11.7295 18.0481 11.8638L16.6154 14.4997C18.3672 15.4518 20.4998 14.1836 20.4998 12.1898H17.4998ZM17.4998 6.76068V12.1898H20.4998V6.76068H17.4998ZM18.0481 7.08668C17.8008 7.22106 17.4998 7.04208 17.4998 6.76068H20.4998C20.4998 4.76688 18.3672 3.49873 16.6154 4.45084L18.0481 7.08668ZM15.0734 8.70343L18.0481 7.08668L16.6154 4.45084L13.6408 6.06759L15.0734 8.70343Z"
      fill={color}
      mask="url(#path-1-inside-1_1026_4215)"
    />
  </Svg>
);

export default BonusBreakdownLiveSessionsIcon;
