import {
  useState,
  useEffect,
  useContext,
  createContext,
  AriaAttributes,
  AllHTMLAttributes,
} from 'react';

import * as React from 'react';
import styled, { EmotionStyle } from '../../core/styled';
import View from '../View';
import { useWindowWidthState } from '../../hooks/windowWidthContext';
import useScrollTracking from '../../hooks/useScrollTracking';

interface PanelManagerContextState {
  isLeftPanelOnTop: boolean;
  isRightPanelOpen: boolean;
  isLargeRightPanel: boolean;
  isPanelManagerScrolling?: boolean;
  enableCompactView?: boolean;
  forceLargeRightPanel?: boolean;
}

interface PanelManagerContextActions {
  setIsLeftPanelOnTop(isLeftPanelOnTop: boolean): void;
  setIsLargeRightPanel(isLargeRightPanel: boolean): void;
  setIsRightPanelOpen(isRightPanelOpen: boolean): void;
  setIsPanelManagerScrolling(isPanelManagerScrolling: boolean): void;
}

/**
 * PanelType conditions:
 * When isMobile === false and isLargeRightPanel === true
 * * PanelType.LEFT is always 375px
 * * PanelType.RIGHT sits on top of the middle panel, occupying all the available space
 * * PanelType.MIDDLE is hidden behind the right panel
 * When isDesktop === false && isMobile === false && isRightPanelOpen === true
 * * PanelType.LEFT is always 375px
 * * PanelType.RIGHT sits on top of the middle panel, occupying all the available space
 * * PanelType.MIDDLE is hidden
 * When isMobile === false
 * * PanelType.LEFT is always 375px
 * * PanelType.RIGHT is hidden unless expliticly shown (isRightPanelOpen), and it's also 375px max
 * * PanelType.MIDDLE is always visible and occupies the remaining space
 * When isMobile === true
 * * PanelType.LEFT is hidden unless leftPanel is explicitly set to show, if it is, then it sits on top of everything
 * * PanelType.RIGHT is hidden unless rightPanel is explicitly set to show, then it sits on top of everything
 * * PanelType.MIDDLE is using the full window width unless leftPanel is explicitly set to show, then this panel is hidden
 * NOTE: isTablet is treated as isMobile when enableCompactView is true
 */
// eslint-disable-next-line no-shadow
export enum PanelType {
  LEFT = 'LEFT',
  MIDDLE = 'MIDDLE',
  RIGHT = 'RIGHT',
}

const PanelContainerView = styled(View)<{
  panelType: PanelType;
  isMobile: boolean;
  isDesktop: boolean;
  isLeftPanelOnTop: boolean;
  isRightPanelOpen?: boolean;
  isLargeRightPanel?: boolean;
  middlePanelStyle?: EmotionStyle;
  forceLargeRightPanel?: boolean;
  hideLeftPanel?: boolean;
}>(
  ({
    panelType,
    isMobile,
    isDesktop,
    isLeftPanelOnTop,
    isLargeRightPanel,
    middlePanelStyle,
    forceLargeRightPanel,
    hideLeftPanel,
    theme: { colors },
  }) => {
    let additionalStyles;
    if (panelType === PanelType.LEFT) {
      const baseStyles = {
        width: 375,
        backgroundColor: colors.white,
        overflowX: 'hidden',
        borderRightWidth: 1,
        borderRightColor: colors.extraLightGrey,
        borderRightStyle: 'solid',
        '&:focus': {
          outline: 'none',
        },
      };

      if (isMobile || hideLeftPanel) {
        if (isLeftPanelOnTop) {
          additionalStyles = {
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            width: '100%',
          };
        } else {
          additionalStyles = {
            position: 'absolute',
            left: -1000,
            visibility: 'hidden',
          };
        }
      }

      return { ...baseStyles, ...additionalStyles };
    }
    if (panelType === PanelType.MIDDLE) {
      const baseStyles = {
        backgroundColor: colors.white,
        overflowX: 'hidden',
        WebkitScrollbar: {
          display: 'none',
        },
        MsOverflowStyle: 'none',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        flex: 1,
        '&:focus': {
          outline: 'none',
        },
      };

      if ((isMobile && isLeftPanelOnTop) || isLargeRightPanel) {
        additionalStyles = {
          flex: 0,
        };
      }
      return { ...baseStyles, ...additionalStyles, ...middlePanelStyle };
    }
    const baseStyles = {
      borderLeftColor: colors.extraLightGrey,
      borderLeftStyle: 'solid',
      borderLeftWidth: isLargeRightPanel || !isDesktop ? 0 : 1,
      backgroundColor: colors.white,
      overflowX: 'hidden',
      '&:focus': {
        outline: 'none',
      },
    };

    if (isLargeRightPanel) {
      additionalStyles = { flex: 1 };
    } else {
      additionalStyles = {
        width: 375,
      };
    }

    if (forceLargeRightPanel && panelType === PanelType.RIGHT) {
      return {
        ...baseStyles,
        flex: 1,
      };
    }

    if (!isDesktop) {
      additionalStyles = {
        ...additionalStyles,
        ...{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          width: 'auto',
          left: isMobile ? 0 : 375,
        },
      };
    }

    return { ...baseStyles, ...additionalStyles };
  }
);

interface PanelAriaAttributes {
  ariaHidden?: AriaAttributes['aria-hidden'];
  tabIndex?: AllHTMLAttributes<HTMLDivElement>['tabIndex'];
  role?: AllHTMLAttributes<HTMLDivElement>['role'];
}

const getPanelAriaProps = (
  panelType: PanelType,
  isMobile: boolean,
  isDesktop: boolean,
  isLeftPanelOnTop: boolean,
  isRightPanelOpen?: boolean,
  isLargeRightPanel?: boolean
): PanelAriaAttributes => {
  const isMiddlePanelHidden =
    (isMobile && isLeftPanelOnTop) || (!isDesktop && isRightPanelOpen) || isLargeRightPanel;
  if (panelType === PanelType.LEFT) {
    if (isMobile)
      return {
        tabIndex: -1,
        role: 'main',
        ariaHidden: isLeftPanelOnTop ? 'true' : undefined,
      };
    return {
      tabIndex: -1,
    };
  }
  if (panelType === PanelType.MIDDLE) {
    return {
      tabIndex: -1,
      ariaHidden: isMiddlePanelHidden ? 'true' : undefined,
      role: isMiddlePanelHidden ? undefined : 'main',
    };
  }
  return {
    tabIndex: -1,
    role: isMiddlePanelHidden && isRightPanelOpen ? 'main' : undefined,
  };
};

const PanelManagerStateContext = createContext<PanelManagerContextState | undefined>(undefined);
const PanelManagerActionsContext = createContext<PanelManagerContextActions | undefined>(undefined);

interface PanelManagerSettings {
  /**
   * Treats Tablet same as Mobile.
   * For example: Left panel will remain hidden in tablet mode
   */
  enableCompactView?: boolean;
  forceLargeRightPanel?: boolean;
}

const PanelManager = ({
  children,
  enableCompactView,
  forceLargeRightPanel,
}: React.PropsWithChildren<PanelManagerSettings>) => {
  const [isLeftPanelOnTop, setIsLeftPanelOnTop] = useState(false);
  const [isRightPanelOpen, setIsRightPanelOpen] = useState(false);
  const [isLargeRightPanel, setIsLargeRightPanel] = useState(false);
  const [isPanelManagerScrolling, setIsPanelManagerScrolling] = useState(false);

  useEffect(() => {
    if (!isRightPanelOpen) setIsLargeRightPanel(false);
  }, [isRightPanelOpen]);

  const { isMobile, isTablet } = useWindowWidthState();
  const isCompactView = enableCompactView ? isMobile || isTablet : isMobile;

  useEffect(() => {
    // If leaving isMobile
    if (!isCompactView) {
      setIsLeftPanelOnTop(false);
    }
  }, [isCompactView]);

  const actions = {
    setIsLeftPanelOnTop,
    setIsLargeRightPanel,
    setIsRightPanelOpen,
    setIsPanelManagerScrolling,
  };

  return (
    <PanelManagerStateContext.Provider
      value={{
        isLeftPanelOnTop,
        isRightPanelOpen,
        isLargeRightPanel,
        isPanelManagerScrolling,
        forceLargeRightPanel,
        enableCompactView,
      }}
    >
      <PanelManagerActionsContext.Provider value={actions}>
        {children}
      </PanelManagerActionsContext.Provider>
    </PanelManagerStateContext.Provider>
  );
};

export const usePanelManagerState = (): PanelManagerContextState => {
  const context = useContext(PanelManagerStateContext);
  if (!context) throw new Error('Cannot use PanelManagerStateContext outside of PanelManager');

  return context;
};

export const usePanelManagerActions = (): PanelManagerContextActions => {
  const context = useContext(PanelManagerActionsContext);
  if (!context) throw new Error('Cannot use PanelManagerActionsContext outside of PanelManager');

  return context;
};

interface ActionProps {
  setIsPanelManagerScrolling?: (isPanelManagerScrolling: boolean) => void;
}

interface PanelContainerProps
  extends Omit<
      React.ComponentProps<typeof PanelContainerView>,
      'isMobile' | 'isDesktop' | 'isLeftPanelOnTop' | 'isRightPanelOpen' | 'isLargeRightPanel'
    >,
    ActionProps {
  hideLeftPanel?: boolean;
}

const PanelContainer = ({
  panelType,
  children,
  ...otherProps
}: React.PropsWithChildren<PanelContainerProps>) => {
  const { isMobile, isTablet, isDesktop } = useWindowWidthState();
  const {
    isLeftPanelOnTop,
    isRightPanelOpen,
    isLargeRightPanel,
    enableCompactView,
    forceLargeRightPanel,
  } = usePanelManagerState();
  const isCompactView = enableCompactView ? isMobile || isTablet : isMobile;
  const { ariaHidden, role, tabIndex } = getPanelAriaProps(
    panelType,
    isCompactView,
    isDesktop,
    isLeftPanelOnTop,
    isRightPanelOpen,
    isLargeRightPanel
  );

  const { setIsPanelManagerScrolling } = usePanelManagerActions();
  const panelRef = React.useRef<HTMLDivElement>(null);

  const trackedElement = panelRef.current;
  const handleScrollStart = () => {
    setIsPanelManagerScrolling(true);
  };

  const handleScrollStop = () => {
    setIsPanelManagerScrolling(false);
  };

  useScrollTracking({ trackedElement, handleScrollStart, handleScrollStop });

  if (panelType === PanelType.RIGHT && !isRightPanelOpen) return null;

  if (panelType !== PanelType.RIGHT && isRightPanelOpen && forceLargeRightPanel) return null;

  return (
    <PanelContainerView
      ref={panelRef}
      isMobile={isCompactView}
      isDesktop={isDesktop}
      aria-hidden={ariaHidden}
      tabIndex={tabIndex}
      role={role}
      panelType={panelType}
      isLeftPanelOnTop={isLeftPanelOnTop}
      isRightPanelOpen={isRightPanelOpen}
      isLargeRightPanel={isLargeRightPanel}
      forceLargeRightPanel={forceLargeRightPanel}
      {...otherProps}
    >
      {children}
    </PanelContainerView>
  );
};

PanelManager.LeftPanel = (
  props: Omit<React.ComponentProps<typeof PanelContainer>, 'panelType'>
) => <PanelContainer panelType={PanelType.LEFT} {...props} />;
PanelManager.MiddlePanel = (
  props: Omit<React.ComponentProps<typeof PanelContainer>, 'panelType'>
) => <PanelContainer panelType={PanelType.MIDDLE} {...props} />;
PanelManager.RightPanel = (
  props: Omit<React.ComponentProps<typeof PanelContainer>, 'panelType'>
) => <PanelContainer panelType={PanelType.RIGHT} {...props} />;

export default PanelManager;
