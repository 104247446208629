import { View, Button, BaseButton, Big } from '@talkspace/react-toolkit';
import { StaticProps } from './types';

export interface RecurringNotAvailableProps {
  handleChooseDifferentDate: () => void;
  handleScheduleLater: () => void;
}

interface Props extends RecurringNotAvailableProps, StaticProps {}

const RecurringNotAvailable = ({
  isMobile,
  colors,
  handleChooseDifferentDate,
  handleScheduleLater,
}: Props) => {
  const buttonContainerStyle = isMobile
    ? { flexDirection: 'column-reverse', gap: 20 }
    : { flexDirection: 'row', justifyContent: 'center', gap: 32 };

  return (
    <View align="center">
      <Big style={{ color: colors.white, marginBottom: 25, textAlign: 'center' }}>
        Use the last few minutes to talk about when to meet next.
      </Big>
      <View style={buttonContainerStyle}>
        <BaseButton onPress={handleScheduleLater} data-qa="scheduleLater">
          <Big style={{ color: colors.baliHaiGrey, fontWeight: 500 }}>I’ll schedule later</Big>
        </BaseButton>
        <Button
          onPress={handleChooseDifferentDate}
          style={{ marginTop: 0 }}
          data-qa="scheduleSession"
        >
          <Big variant="bigDarkGreen" style={{ color: colors.white }}>
            Schedule a session
          </Big>
        </Button>
      </View>
    </View>
  );
};

export default RecurringNotAvailable;
