import { useState, useRef, useEffect } from 'react';

export const useA11y = (isInputReadOnly: boolean, inputId: string) => {
  const [isMouseUser, setIsMouseUser] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [decreaseTopMargin, setDecreaseTopMargin] = useState(false);
  const onKeyDown = () => {
    setIsMouseUser(false);
  };
  const onKeyUp = () => {
    setIsMouseUser(false);
  };
  const onMouseDown = () => {
    setIsMouseUser(true);
  };
  const onMouseEnter = () => {
    setIsMouseUser(true);
  };
  const onMouseMove = () => {
    setIsMouseUser(true);
  };
  const onTouchStart = () => {
    setIsMouseUser(true);
  };
  const onFocus = () => {
    setIsFocused(true);
  };
  const onBlur = () => {
    setIsMouseUser(false);
    setIsFocused(false);
  };

  const wrapperRef = useRef<HTMLDivElement>(null);

  // prevents keyboard from rendering unnecessarily on dropdown focus/press on mobile devices
  useEffect(() => {
    const currentRef = wrapperRef.current;
    if (isInputReadOnly) {
      const elm = currentRef?.querySelector<HTMLElement>(`#${inputId}`);
      elm?.setAttribute('inputmode', 'none');
      if (elm?.style) {
        elm.style.caretColor = 'transparent';
      }
    }
    return () => {
      currentRef?.querySelector(`#${inputId}`)?.removeAttribute('inputmode');
    };
  }, [isInputReadOnly, inputId]);

  return {
    isMouseUser,
    isFocused,
    decreaseTopMargin,
    setDecreaseTopMargin,
    onKeyDown,
    onKeyUp,
    onMouseDown,
    onMouseEnter,
    onMouseMove,
    onTouchStart,
    onFocus,
    onBlur,
    wrapperRef,
  };
};

export default useA11y;
