import { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { Spinner, Text, View, useEmotionTheme } from '@talkspace/react-toolkit';
import BadgedNotesIcon from 'components/Icons/BadgedNotesIcon';
import styled from '@/core/styled';
import { useParams, useRouteMatch } from '@/core/routerLib';
import LVSIcon from '../../../Icons/LVSIcon';
import CaseIcon from '../../../Icons/CaseIcon';
import ClinicalIcon from '../../../Icons/ClinicalIcon';
import JourneyIcon from '../../../Icons/JourneyIcon';
import NotesIcon from '../../../Icons/NotesIcon';
import useQueryNotes from '../../../../hooks/notes/useQueryNotes';

enum CrmTabHorizontalPadding {
  Agenda = 11.5,
  Case = 15,
  Clinical = 15,
  Notes = 15,
  Live = 14,
}

interface RoomProps {
  roomID: string;
}

interface CrmNavBarComponentProps {
  therapistType?: string;
}
interface NavLinkWithIconBaseProps {
  to: string;
  text: string;
  icon: (props: { isActive: boolean }) => JSX.Element;
  dataQa: string;
}

const CrmTabsWrapper = styled(View)(
  ({
    theme: {
      window: { isMobile },
      colors,
    },
  }) => {
    return {
      width: '100%',
      marginTop: isMobile ? 1 : 0,
      borderBottom: isMobile ? 'none' : '0.5px solid #d3d3d3',
      borderTop: isMobile ? '0.5px solid #d3d3d3' : 'none',
      boxShadow: '0px 1px 3px -2px #d3d3d3',
      alignItems: 'center',
      position: 'sticky',
      backgroundColor: colors.white,
      order: isMobile ? 2 : 0,
    };
  }
);

const CrmTabsContainer = styled(View)<{
  isPsych: boolean;
}>(({ isPsych }) => {
  return {
    flexDirection: 'row',
    height: 65,
    paddingTop: 5,
    paddingBottom: 5,
    alignItems: 'center',
    transition: '0.3s',
    maxWidth: isPsych ? {} : 355,
    width: isPsych ? '100%' : {},
    paddingLeft: isPsych ? 15 : {},
    paddingRight: isPsych ? 15 : {},
  };
});

const CrmTabsIconContainer = styled(View)<{
  horizontalPadding: string;
  textColor: string;
  isActive: boolean;
}>(({ horizontalPadding, textColor, isActive, theme: { colors } }) => {
  return {
    height: 55,
    paddingLeft: horizontalPadding,
    paddingRight: horizontalPadding,
    marginLeft: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    borderRadius: 10,
    alignItems: 'center',
    // TODO: R&D a fix for withHover breaking on mobile and replace media/child queries
    '@media (hover: hover)': {
      '&:hover': {
        backgroundColor: colors.a11yLinkWaterGrey,
        '& > div > svg > path:first-child': {
          fill: !isActive && colors.permaBlueBayoux,
        },
        '& > div > div > p': {
          color: !isActive && colors.permaBlueBayoux,
        },
      },
    },
    '& > div > div > p': {
      color: textColor,
    },
  };
});

const NavLinkWithIcon: FunctionComponent<NavLinkWithIconBaseProps> = ({
  to,
  text,
  icon: CrmIcon,
  dataQa,
}) => {
  const { url } = useRouteMatch();
  const baseURL = url.split('/').slice(0, -1).join('/');
  const linkTo = `${baseURL}${to}`;
  const isActive = window.location.pathname === linkTo;
  const { colors } = useEmotionTheme();

  return (
    <NavLink
      to={linkTo}
      isActive={() => isActive}
      style={{ textDecoration: 'none' }}
      data-qa={dataQa}
    >
      <CrmTabsIconContainer
        horizontalPadding={CrmTabHorizontalPadding[text]}
        textColor={isActive ? colors.black : colors.dimGray}
        isActive={isActive}
      >
        <View align="center">
          <CrmIcon isActive={isActive} />
          <View style={{ position: 'relative' }}>
            <Text style={{ marginTop: 2, fontWeight: 'bold', fontSize: 15 }}>{text}</Text>
          </View>
        </View>
      </CrmTabsIconContainer>
    </NavLink>
  );
};

const CrmNavBarComponent = ({ therapistType }: CrmNavBarComponentProps) => {
  const { roomID } = useParams<RoomProps>();

  const IconContainer = ({ isPsych, children }) => (
    <View style={{ flexGrow: isPsych ? 100 : {} }}>{children}</View>
  );

  const { data: useNotesResponse } = useQueryNotes(roomID);
  const isPsych = therapistType === 'psychiatrist';
  return (
    <CrmTabsWrapper>
      <CrmTabsContainer isPsych={isPsych}>
        {therapistType === undefined ? (
          <Spinner />
        ) : (
          <>
            {!isPsych && (
              <IconContainer isPsych={isPsych}>
                <NavLinkWithIcon
                  dataQa="cRMAgendaTab"
                  to="/agenda-tab"
                  text="Agenda"
                  icon={JourneyIcon}
                />
              </IconContainer>
            )}
            <IconContainer isPsych={isPsych}>
              <NavLinkWithIcon to="/case-tab" dataQa="cRMCaseTab" text="Case" icon={CaseIcon} />
            </IconContainer>
            <IconContainer isPsych={isPsych}>
              <NavLinkWithIcon
                to="/clinical-tab"
                dataQa="cRMClinicalTab"
                text="Clinical"
                icon={ClinicalIcon}
              />
            </IconContainer>
            <IconContainer isPsych={isPsych}>
              <NavLinkWithIcon
                to="/notes-tab"
                dataQa="cRMNotesTab"
                text="Notes"
                icon={useNotesResponse?.warningMessage ? BadgedNotesIcon : NotesIcon}
              />
            </IconContainer>
            <IconContainer isPsych={isPsych}>
              <NavLinkWithIcon to="/lvs-tab" dataQa="cRMLiveTab" text="Live" icon={LVSIcon} />
            </IconContainer>
          </>
        )}
      </CrmTabsContainer>
    </CrmTabsWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    therapistType: state.availability.therapistType,
  };
};

const CrmNavBar = connect(mapStateToProps)(CrmNavBarComponent);

export default CrmNavBar;
