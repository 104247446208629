import { useEmotionTheme } from '@talkspace/react-toolkit';
import { VoidFunctionComponent } from 'react';
import Svg, { Defs, G, Path } from 'svgs';

interface PreferredTimesIconProps {
  color?: string;
}

const PreferredTimesIcon: VoidFunctionComponent<PreferredTimesIconProps> = ({
  color,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();

  return (
    <Svg
      width="14"
      height="17"
      viewBox="0 0 14 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <G clipPath="url(#clip0_750_3129)">
        <Path
          d="M4.75 0.794922C4.75 0.379297 4.41563 0.0449219 4 0.0449219C3.58437 0.0449219 3.25 0.379297 3.25 0.794922V2.04492H2C0.896875 2.04492 0 2.9418 0 4.04492V4.54492V6.04492V14.0449C0 15.148 0.896875 16.0449 2 16.0449H12C13.1031 16.0449 14 15.148 14 14.0449V6.04492V4.54492V4.04492C14 2.9418 13.1031 2.04492 12 2.04492H10.75V0.794922C10.75 0.379297 10.4156 0.0449219 10 0.0449219C9.58438 0.0449219 9.25 0.379297 9.25 0.794922V2.04492H4.75V0.794922ZM1.5 6.04492H12.5V14.0449C12.5 14.3199 12.275 14.5449 12 14.5449H2C1.725 14.5449 1.5 14.3199 1.5 14.0449V6.04492Z"
          fill={color || colors.slateGrey}
        />
      </G>
      <Defs>
        <clipPath id="clip0_750_3129">
          <rect width="14" height="16" fill="white" transform="translate(0 0.0449219)" />
        </clipPath>
      </Defs>
    </Svg>
  );
};

export default PreferredTimesIcon;
