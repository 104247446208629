import { FunctionComponent, useState } from 'react';

import { EmotionStyle, useEmotionTheme } from '../../core/styled';
import View from '../View';
import TouchableView from '../TouchableView';
import Collapsible from '../Collapsible';
import CaretDown from '../icons/CaretDown';
import CaretUp from '../icons/CaretUp';
import { Large } from '../Typography';

interface Props {
  title: string;
  ariaControlsID?: string;
  titleStyle?: EmotionStyle;
}

const CollapsibleSection: FunctionComponent<Props> = ({
  title,
  ariaControlsID,
  children,
  titleStyle = {},
}) => {
  const [collapsed, setCollapsed] = useState(true);
  const { colors } = useEmotionTheme();

  return (
    <View style={{ paddingLeft: 9, paddingRight: 17 }}>
      <TouchableView
        aria-expanded={!collapsed}
        aria-controls={ariaControlsID}
        row
        justify="space-between"
        onPress={() => setCollapsed((c) => !c)}
        style={{ paddingBottom: 20, paddingTop: 20, borderRadius: 5 }}
        roundedFocusStyle
        primaryColor={colors.gray}
      >
        <Large variant="largeBoldWide" style={titleStyle}>
          {title}
        </Large>
        {collapsed ? <CaretDown /> : <CaretUp />}
      </TouchableView>
      <Collapsible id={ariaControlsID} isCollapsed={collapsed}>
        {children}
      </Collapsible>
    </View>
  );
};

export default CollapsibleSection;
