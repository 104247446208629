import { Link, Tiny, useEmotionTheme } from '@talkspace/react-toolkit';
import styled from '@/core/styled';
import { webOnlyStyle } from '@/core/styled/styleHelpers';

const StyledLink = styled(Link)({
  marginLeft: 0,
  marginRight: 0,
});

const StyledLinkText = styled(Tiny)(({ theme: { colors } }) => {
  return {
    ...webOnlyStyle({ display: 'inline' }),
    color: colors.permaDarkCyan,
    fontWeight: 700,
  };
});

const TermsOfServiceCheckboxLabel = () => {
  const { colors } = useEmotionTheme();
  return (
    <Tiny>
      I agree to the{' '}
      <StyledLink
        stopPropagation
        target="_blank"
        href="https://www.talkspace.com/public/terms"
        primaryColor={colors.permaDarkCyan}
        roundedFocusStyle
        isNested
      >
        <StyledLinkText inline>Terms of Use</StyledLinkText>
      </StyledLink>{' '}
      and{' '}
      <StyledLink
        stopPropagation
        target="_blank"
        href="https://www.talkspace.com/public/privacy-policy"
        primaryColor={colors.permaDarkCyan}
        roundedFocusStyle
        isNested
      >
        <StyledLinkText inline>Privacy Policy</StyledLinkText>
      </StyledLink>{' '}
    </Tiny>
  );
};

export default TermsOfServiceCheckboxLabel;
