import Svg, { G, Path, Rect } from 'svgs';
import { useEmotionTheme } from '../../core/styled';

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

const Discover = ({ width = 33, height = 21, color, ...props }: Props) => {
  const { colors } = useEmotionTheme();
  const titleText = 'Discover card';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox={`0 0 ${width} ${height}`}
      {...props}
    >
      <G fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <Rect width="33" height="21" x="0" y="0" fill={color || colors.green} rx="3" />
        <G fill={colors.white}>
          <Path
            d="M29 4.097V9.8A2.197 2.197 0 0126.794 12H7.38c9.3-1.885 15.765-4.474 21.621-7.903z"
            transform="translate(3 8)"
          />
          <G transform="translate(3 8) translate(.877 .37)">
            <Path
              d="M.996.524C.996.384.903.306.731.306H.504v.707H.67V.738l.196.275h.21L.842.72C.94.694.996.62.996.524zM.702.619H.671V.437h.033c.082 0 .125.03.125.09S.784.618.702.618z"
              transform="translate(24.807 .187)"
            />
            <Path
              d="M.752.066A.616.616 0 00.137.69c0 .345.275.622.615.622.336 0 .61-.28.61-.622a.618.618 0 00-.61-.623zm0 1.133A.5.5 0 01.258.69.5.5 0 01.752.18a.5.5 0 01.485.51c0 .28-.218.509-.485.509z"
              transform="translate(24.807 .187)"
            />
            <Path d="M24.803 1.382c0-.758-.513-1.194-1.41-1.194h-1.156v4.043h.779V2.607h.102l1.075 1.624h.958l-1.257-1.703c.587-.121.91-.527.91-1.146zm-1.561.667h-.226V.824h.239c.485 0 .749.207.749.6 0 .406-.264.625-.762.625z" />
            <Path d="M19.5172785 4.23129342L21.7240378 4.23129342 21.7240378 3.54657129 20.2947755 3.54657129 20.2947755 2.45473571 21.6696674 2.45473571 21.6696674 1.77070244 20.2947755 1.77070244 20.2947755 0.873124039 21.7240378 0.873124039 21.7240378 0.188401913 19.5172785 0.188401913 19.5172785 4.23129342" />
            <Path d="M17.2435756 2.90421377L16.178595 0.188401913 15.3293971 0.188401913 17.0226958 4.33496614 17.4410082 4.33496614 19.1638708 0.188401913 18.3207895 0.188401913 17.2435756 2.90421377" />
            <Path d="M11.235 2.19c0 1.22.911 2.132 2.127 2.132a2.093 2.093 0 002.121-2.108c0-1.177-.93-2.101-2.121-2.101-1.175 0-2.127.93-2.127 2.077zM7.86 2.212c0 1.19.92 2.11 2.105 2.11.335 0 .622-.066.975-.236v-.928c-.312.316-.587.442-.94.442-.783 0-1.34-.576-1.34-1.394 0-.775.574-1.388 1.305-1.388.37 0 .652.134.975.455V.345a1.964 1.964 0 00-.957-.248c-1.179 0-2.124.94-2.124 2.115M6.466 1.739c-.467-.175-.604-.29-.604-.509 0-.254.244-.448.58-.448.233 0 .425.097.629.327L7.477.57A1.729 1.729 0 006.304.121C5.6.121 5.061.618 5.061 1.28c0 .558.25.843.98 1.11.305.108.46.18.538.23a.486.486 0 01.234.417c0 .329-.257.57-.605.57-.37 0-.67-.187-.849-.539l-.501.492c.358.533.788.77 1.381.77.807 0 1.375-.546 1.375-1.328 0-.642-.262-.934-1.148-1.262" />
            <Path d="M3.78655737 0.188401913L4.56405435 0.188401913 4.56405435 4.23129342 3.78655737 4.23129342z" />
            <Path d="M1.287.188H.143v4.043h1.138c.603 0 1.04-.145 1.423-.466.454-.383.723-.959.723-1.553 0-1.194-.88-2.024-2.14-2.024zm.91 3.037c-.247.225-.563.322-1.066.322H.92V.873h.21c.503 0 .807.091 1.065.327.27.243.43.618.43 1.006 0 .389-.16.777-.43 1.019z" />
          </G>
        </G>
      </G>
    </Svg>
  );
};

export default Discover;
