import Svg, { Path } from 'svgs';
import { FunctionComponent } from 'react';

import { useEmotionTheme } from '../../core/styled';

interface BoxArrowIconProps {
  width?: number;
  height?: number;
  color?: string;
}

const BoxArrowIcon: FunctionComponent<BoxArrowIconProps> = ({
  width = 16,
  height = 16,
  color,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'boxArrow';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      {...otherProps}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1499_21541)">
        <Path
          d="M10 0C9.44687 0 9 0.446875 9 1C9 1.55313 9.44687 2 10 2H12.5844L6.29375 8.29375C5.90312 8.68437 5.90312 9.31875 6.29375 9.70938C6.68437 10.1 7.31875 10.1 7.70937 9.70938L14 3.41563V6C14 6.55313 14.4469 7 15 7C15.5531 7 16 6.55313 16 6V1C16 0.446875 15.5531 0 15 0H10ZM2.5 1C1.11875 1 0 2.11875 0 3.5V13.5C0 14.8813 1.11875 16 2.5 16H12.5C13.8813 16 15 14.8813 15 13.5V10C15 9.44687 14.5531 9 14 9C13.4469 9 13 9.44687 13 10V13.5C13 13.775 12.775 14 12.5 14H2.5C2.225 14 2 13.775 2 13.5V3.5C2 3.225 2.225 3 2.5 3H6C6.55313 3 7 2.55313 7 2C7 1.44687 6.55313 1 6 1H2.5Z"
          fill={color || colors.accessibilityGreenDark}
        />
      </g>
      <defs>
        <clipPath id="clip0_1499_21541">
          <rect width={width} height={height} fill={color || colors.white} />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default BoxArrowIcon;
