import Svg, { Path, Circle, Rect, G } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

interface AgendaCardIconProps {
  width?: number;
  height?: number;
}

const AgendaCardIcon = ({ width = 125, height = 125, ...otherProps }: AgendaCardIconProps) => {
  const { colors } = useEmotionTheme();
  const titleText = 'Notecard with writing';
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 125 125"
      title={titleText}
      aria-label={titleText}
      {...otherProps}
    >
      <G
        transform="translate(-125 -195)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <Path fill="#FFF" d="M0 0L375 0 375 2000 0 2000z" />
        <Path
          d="M356 155.5c2.347 0 4.472.951 6.01 2.49a8.473 8.473 0 012.49 6.01h0v280a8.473 8.473 0 01-2.49 6.01 8.473 8.473 0 01-6.01 2.49h0H19a8.473 8.473 0 01-6.01-2.49A8.473 8.473 0 0110.5 444h0V164c0-2.347.951-4.472 2.49-6.01A8.473 8.473 0 0119 155.5h0z"
          stroke={colors.permaPowderBlue}
          fill={colors.permaGhostWhite}
        />
        <Circle
          cx={62.5}
          cy={62.5}
          r={62.5}
          fill={colors.a11yLinkWaterGrey}
          transform="translate(35 195) translate(90)"
        />
        <Path
          d="M9.982 3.825L38.77 2.83A7.421 7.421 0 0144.1 4.826a7.42 7.42 0 012.362 5.173h0l1.003 28.487a7.396 7.396 0 01-1.985 5.325 7.404 7.404 0 01-5.172 2.36h0l-28.793.994a7.42 7.42 0 01-5.328-1.995 7.418 7.418 0 01-2.362-5.174h0l-1-28.487A7.397 7.397 0 014.81 6.184a7.404 7.404 0 015.172-2.36h0z"
          stroke="#ACBFF1"
          strokeWidth={4.95361728}
          transform="translate(35 195) translate(90) translate(30.718 32.292)"
        />
        <G transform="translate(35 195) translate(90) translate(30.718 32.292) rotate(2 -290.68 462.35)">
          <Path
            d="M38.71 2.477a7.41 7.41 0 015.254 2.177 7.409 7.409 0 012.178 5.253h0l.002 28.502c0 2.014-.801 3.841-2.102 5.18a7.406 7.406 0 01-5.087 2.247h0l-29.048.003a7.407 7.407 0 01-5.254-2.176 7.407 7.407 0 01-2.176-5.254h0V9.907c0-2.052.831-3.91 2.176-5.254a7.407 7.407 0 015.254-2.176h0z"
            stroke={colors.purple}
            strokeWidth={4.95361728}
            fill={colors.patensBlue}
          />
          <Rect fill={colors.purple} x={10.1813091} y={11.4476952} width={28} height={5} rx={2.5} />
          <Rect fill={colors.purple} x={10.1813091} y={19.4476952} width={28} height={5} rx={2.5} />
          <Rect fill={colors.purple} x={10.1813091} y={27.4476952} width={14} height={5} rx={2.5} />
        </G>
      </G>
    </Svg>
  );
};

export default AgendaCardIcon;
