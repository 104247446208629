import Svg, { Defs, Rect, G, Use, Path, Circle } from 'svgs';
import { EmotionStyle } from '../../core/styled';

export interface Props {
  width?: number;
  height?: number;
  style?: EmotionStyle;
}

const MagnifyingGlassTherapists = ({ width = 84, height = 84, ...otherProps }: Props) => {
  const titleText = 'figures with magnifying glass overlaid';
  return (
    <Svg
      width={width}
      title={titleText}
      aria-label={titleText}
      height={height}
      viewBox="0 0 84 84"
      {...otherProps}
    >
      <filter
        x="-17.1%"
        y="-12.9%"
        width="134.3%"
        height="134.3%"
        filterUnits="objectBoundingBox"
        id="MagnifyingGlassTherapists_filter-2"
      >
        <feMorphology radius="1.5" operator="erode" in="SourceAlpha" result="shadowSpreadOuter1" />
        <feOffset dx="0" dy="3" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="5" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix
          values="0 0 0 0 0.062745098   0 0 0 0 0.427620878   0 0 0 0 0.584313725  0 0 0 0.3 0"
          type="matrix"
          in="shadowBlurOuter1"
        />
      </filter>
      <Defs>
        <Rect
          id="MagnifyingGlassTherapists_filter-2_b"
          x={0}
          y={0}
          width={70}
          height={70}
          rx={20}
        />
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <G transform="translate(-13 -454) translate(20 145) translate(0 313)">
          <Use
            fill="#000"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            /* @ts-ignore */
            filter="url(#MagnifyingGlassTherapists_filter-2)"
            xlinkHref="#MagnifyingGlassTherapists_filter-2_b"
          />
          <Use fill="#FFF" xlinkHref="#MagnifyingGlassTherapists_filter-2_b" />
        </G>
        <G
          opacity={0.899999976}
          transform="translate(-13 -454) translate(20 145) translate(0 313) translate(18 20)"
          stroke="#758DD7"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2.20000005}
        >
          <Path d="M.874 30.818a9.653 9.653 0 0119.305 0M5.443 11.557a9.793 9.793 0 007.05 2.994M15.145 18.22a5.899 5.899 0 11-3.76-9.506M29.412 20.65a4.826 4.826 0 01-8.557-1.716" />
          <Path d="M33.049 30.818a7.508 7.508 0 00-7.508-7.508 7.343 7.343 0 00-3.031.65" />
          <Circle cx={22.3437517} cy={7.60002066} r={7.50750058} />
          <Path d="M33.0687525 18.3250215L27.6862321 12.9425011" />
        </G>
      </G>
    </Svg>
  );
};

export default MagnifyingGlassTherapists;
