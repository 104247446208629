import './UserBadge.css';
import { Button } from 'react-bootstrap';

import PropTypes from 'prop-types';

const UserBadge = ({
  preferredName,
  onClick,
  active,
  nameIsHidden = false,
  isHorizontal = false,
  styles = {},
}) => {
  const displayName = preferredName || 'NA';
  const [first, last = ''] = Array.from(displayName);
  const maxNameLength = isHorizontal ? 12 : 4;
  const name = (innerDisplayName) => {
    const spaceIndex = innerDisplayName.indexOf(' ');
    const nameSub =
      spaceIndex !== -1 ? innerDisplayName.substring(0, spaceIndex) : innerDisplayName;
    return nameSub.length > maxNameLength ? `${nameSub.substring(0, maxNameLength)}...` : nameSub;
  };

  const containerStyle = isHorizontal
    ? { flexDirection: 'row', marginRight: 13 }
    : { flexDirection: 'column' };
  const badgeStyle = isHorizontal ? { marginRight: 9 } : {};
  const nameStyle = isHorizontal ? { fontSize: 16, fontWeight: 'bold' } : {};

  return (
    <div
      className={`user-badge-container ${active ? 'active' : ''}`}
      style={{ ...containerStyle, ...styles }}
      onClick={onClick}
    >
      <Button className="userBadge" style={badgeStyle}>
        <div className="initials">{`${first.toUpperCase()}${last.toLowerCase()}`}</div>
      </Button>
      <div hidden={!!nameIsHidden} style={nameStyle} className="user-badge-name">
        {name(displayName)}
      </div>
    </div>
  );
};

UserBadge.propTypes = {
  preferredName: PropTypes.string,
  active: PropTypes.bool,
  onClick: PropTypes.func,
};

export default UserBadge;
