import { FunctionComponent } from 'react';
import {
  Huge,
  Standard,
  Tiny,
  View,
  TouchableView,
  Button,
  CloseButton,
  BaseButton,
  RedX,
  Modal,
} from '@talkspace/react-toolkit';
import { webOnlyStyle } from '@/core/styled/styleHelpers';
import styled from '@/core/styled';
import { COLORS } from '../../utils/design';

// TODO remove this to a config
const getOffer = (roomType, mins, planType, roomStatus) => {
  if (roomType === 'psychiatry') {
    if (mins === 60) {
      return { offerId: 61, planId: 138 };
    }
    return { offerId: 60, planId: 137 };
  }
  if (planType === 'Video Only' || [4, 5, 7].includes(roomStatus)) {
    return { offerId: 71, planId: 305 };
  }
  return { offerId: 69, planId: 29 };
};

const fullScreenStyles = {
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  width: 'initial',
  height: 'initial',
  borderRadius: 0,
};

const Wrapper = styled(TouchableView)<{ height?: number }>(({ theme: { window }, height }) => {
  return {
    borderRadius: 10,
    backgroundColor: COLORS.white,
    position: 'relative',
    cursor: 'initial',
    alignItems: 'stretch',
    width: 500,
    minHeight: 390,
    height,
    maxWidth: 750,
    ...webOnlyStyle({
      boxShadow: '0 4px 10px -4px rgba(0,40,65,0.40)',
      transition: '.2s',
      ...(window.isMobile ? fullScreenStyles : {}),
    }),
  };
});

const SecondaryButton = styled(BaseButton)({
  marginTop: 25,
  marginBottom: 60,
  alignSelf: 'center',
});

interface CreateVideoCallOfferMenuProps {
  onBackdropPress: () => void;
  postOffer(roomID: number, offerId: number, planId: number): void;
  handleCreateVideoCallErrorModalSendFreeCall: () => void;
  handleCreateVideoCallErrorModalOK: () => void;
  didSendIntroCall: boolean;
  doesCallHaveOffer: boolean;
  offerType: string;
  offerMinutes: number;
  roomID: number;
  hasNoCredits: boolean;
  isCallInProgress: boolean;
  userPlanType: string;
  roomStatus?: number;
  videoCallErrorMessage?: string;
}

const CreateVideoCallOfferMenu: FunctionComponent<CreateVideoCallOfferMenuProps> = ({
  onBackdropPress,
  didSendIntroCall,
  doesCallHaveOffer,
  handleCreateVideoCallErrorModalOK,
  handleCreateVideoCallErrorModalSendFreeCall,
  offerType,
  postOffer,
  offerMinutes,
  roomID,
  hasNoCredits,
  isCallInProgress,
  userPlanType,
  roomStatus,
  videoCallErrorMessage,
}) => {
  const { offerId, planId } = getOffer(offerType, offerMinutes, userPlanType, roomStatus);

  const getMessage = () => {
    if (videoCallErrorMessage) {
      return videoCallErrorMessage;
    }
    if (isCallInProgress) {
      return 'Can’t start a call while another one is in progress.';
    }
    if (didSendIntroCall) {
      return "Your client doesn't have an 10 minute introduction credit available for use.";
    }
    if (!doesCallHaveOffer) {
      return `Your client doesn't have a ${offerMinutes} minute ${offerType} credit available for use.`;
    }
    if (hasNoCredits) {
      return `Your client doesn't have a ${offerType} credit available for use. Would you like to send them an offer to purchase one?`;
    }
    return null;
  };

  return (
    <Modal onBackdropPress={onBackdropPress} isVisible>
      <Wrapper onPress={(e) => e.stopPropagation()}>
        <View row justify="end" align="center" style={{ height: 67, paddingRight: 16 }}>
          <CloseButton onPress={onBackdropPress} />
        </View>
        <View
          style={{
            paddingLeft: 53,
            paddingRight: 53,
            paddingBottom: videoCallErrorMessage ? 0 : 20,
          }}
        >
          <View align="center">
            <RedX />
            <Huge style={{ marginTop: 20, textAlign: 'center' }}>
              {isCallInProgress && 'Call in progress'}
              {(hasNoCredits || videoCallErrorMessage) &&
                !isCallInProgress &&
                'No credit available'}
            </Huge>
            <Tiny style={{ marginTop: 20, textAlign: 'center' }}>{getMessage()}</Tiny>
            {!didSendIntroCall && doesCallHaveOffer && !isCallInProgress && !videoCallErrorMessage && (
              <Button
                onPress={() => {
                  postOffer(roomID, offerId, planId);
                  onBackdropPress();
                }}
                style={{ width: 335, marginTop: 40 }}
                text="Send offer"
              />
            )}
            {didSendIntroCall || !doesCallHaveOffer || isCallInProgress || videoCallErrorMessage ? (
              <>
                <Button
                  onPress={handleCreateVideoCallErrorModalOK}
                  style={{ width: 335, marginTop: 40 }}
                  text="OK"
                />
                {didSendIntroCall && !isCallInProgress && (
                  <SecondaryButton onPress={handleCreateVideoCallErrorModalSendFreeCall}>
                    <Standard variant="standardDarkGrey">Send free call</Standard>
                  </SecondaryButton>
                )}
              </>
            ) : (
              <SecondaryButton onPress={onBackdropPress}>
                <Standard variant="standardDarkGrey">Cancel</Standard>
              </SecondaryButton>
            )}
          </View>
        </View>
      </Wrapper>
    </Modal>
  );
};

export default CreateVideoCallOfferMenu;
