import { FunctionComponent } from 'react';
import moment from 'moment';
import { View, Text, useEmotionTheme } from '@talkspace/react-toolkit';
import styled from '@/core/styled/styled';

interface DayProps {
  date: Date;
}

const Wrapper = styled(View)({
  display: 'flex',
  justifyContent: 'center',
});

const Day: FunctionComponent<DayProps> = ({ date }) => {
  const messageMoment = moment(date);
  const format = messageMoment.isSame(moment(), 'd') ? '[Today at] h:mm A' : 'MMM D, h:mm A';
  const { colors } = useEmotionTheme();
  return (
    <Wrapper style={{ alignSelf: 'center', marginTop: 20 }}>
      <Text style={{ color: colors.placeholderGrey, margin: 5, fontSize: 13 }}>
        {messageMoment.format(format)}
      </Text>
    </Wrapper>
  );
};

export default Day;
