import { FunctionComponent, useEffect, useState } from 'react';
import {
  AccordionContainer,
  View,
  Small,
  TextAreaRounded,
  useObjectState,
  styled,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import useQueryProgressNoteHelperData from 'hooks/notes/useQueryProgressNoteHelperData';
import useQueryRoomDetails from 'ts-frontend/hooks/useQueryRoomDetails';
import { useParams } from '@/core/routerLib';
import {
  useProgressNoteFormActions,
  useProgressNoteFormState,
} from '../context/PsychProgressNoteFormContext';
import ConfirmButton from './ConfirmButton';
import { ProgressNoteFormState } from '../context/types';
import { SessionService } from '../../types';
import {
  getTextAreaContainerBorderStyle,
  PSYCH_FOLLOWUP_CPT_CODE_1,
  PSYCH_FOLLOWUP_CPT_CODE_2,
  getTextAreaStyle,
  SESSION_SUMMARY_CHARS_LIMIT,
} from '../../utils';
import useQuerySessionServices from '../../../../../../hooks/useQuerySessionServices';
import useQueryProgressNote from '../../../../../../hooks/notes/useQueryProgressNote';
import useDidUpdateEffect from '../../../../../../hooks/useDidUpdateEffect';

const checkIfFollowUp = (
  sessionServiceID: number | null,
  sessionServices: Array<SessionService> | undefined
): boolean => {
  if (!sessionServiceID || !sessionServices) {
    return false;
  }

  const sessionService = sessionServices.find((it) => it.id === sessionServiceID);

  return sessionService
    ? sessionService.name.includes(PSYCH_FOLLOWUP_CPT_CODE_1) ||
        sessionService.name.includes(PSYCH_FOLLOWUP_CPT_CODE_2)
    : false;
};

const Label = styled(Small)({
  marginBottom: 8,
});

const TextAreaWrapper = styled(View)({
  marginBottom: 16,
});

const PsychSessionSummarySection: FunctionComponent = () => {
  const {
    formSections,
    sessionServiceID,
    sessionSummary: globalSessionSummary,
    formMode,
  } = useProgressNoteFormState();
  const { setFormState } = useProgressNoteFormActions();

  const [sessionSummary, setSessionSummary] =
    useObjectState<ProgressNoteFormState['sessionSummary']>(globalSessionSummary);

  const { roomID, noteID } = useParams<{ roomID: string; noteID?: string }>();
  const { data: helperData } = useQueryProgressNoteHelperData(roomID);
  const { data: { clientUserID } = {} } = useQueryRoomDetails(roomID);

  const { data: progressNoteData } = useQueryProgressNote(clientUserID, noteID);
  const noteRoomID = progressNoteData?.roomID?.toString() || roomID;

  const { data: sessionServices = [] } = useQuerySessionServices(noteRoomID, clientUserID);

  const [patientReportError, setPatientReportError] = useState(false);
  const [presentIllnessHistoryError, setPresentIllnessHistoryError] = useState(false);
  const [assessmentError, setAssessmentError] = useState(false);

  const { colors } = useEmotionTheme();

  const isFollowUp = checkIfFollowUp(sessionServiceID, sessionServices);

  useEffect(() => {
    if (
      formMode === 'create' &&
      isFollowUp &&
      helperData?.lastProgressNote?.sessionSummary?.assessment
    ) {
      setSessionSummary({ assessment: helperData.lastProgressNote.sessionSummary.assessment });
    }
  }, [formMode, helperData, isFollowUp, setSessionSummary]);

  const markSectionAsIncomplete = () => {
    if (formSections.psychSessionSummary.completed) {
      setFormState({
        formSections: {
          ...formSections,
          psychSessionSummary: {
            open: formSections.psychSessionSummary.open,
            changed: true,
            completed: false,
          },
        },
      });
    }
  };

  useDidUpdateEffect(() => {
    markSectionAsIncomplete();
  }, [isFollowUp]);

  const handleAccordionPress = (value: boolean) => {
    setFormState({
      formSections: {
        ...formSections,
        psychSessionSummary: {
          open: value,
          changed: formSections.psychSessionSummary.changed,
          completed: formSections.psychSessionSummary.completed,
        },
      },
    });
  };

  const handlePresentIllnessHistoryChange = (value: string) => {
    markSectionAsIncomplete();
    setPresentIllnessHistoryError(false);
    setSessionSummary({
      presentIllnessHistory: value,
    });
  };

  const handlePatientReportChange = (value: string) => {
    markSectionAsIncomplete();
    setPatientReportError(false);
    setSessionSummary({
      patientReport: value,
    });
  };

  const handleAssessmentChange = (value: string) => {
    markSectionAsIncomplete();
    setAssessmentError(false);
    setSessionSummary({
      assessment: value,
    });
  };

  const handleConfirmPress = () => {
    let isError = false;

    if (isFollowUp && !sessionSummary.patientReport) {
      setPatientReportError(true);
      isError = true;
    }

    if (!isFollowUp && !sessionSummary.presentIllnessHistory) {
      setPresentIllnessHistoryError(true);
      isError = true;
    }

    if (!sessionSummary.assessment) {
      setAssessmentError(true);
      isError = true;
    }

    if (isError) {
      setFormState({ showGlobalError: true });
      return;
    }

    setFormState({
      sessionSummary,
      formSections: {
        ...formSections,
        psychSessionSummary: {
          open: false,
          changed: true,
          completed: true,
        },
        ...(formSections.psychPlan.completed
          ? {}
          : {
              psychPlan: {
                ...formSections.psychPlan,
                open: true,
              },
            }),
      },
    });
  };

  const textAreaStyle = getTextAreaStyle(formMode === 'view');

  const renderTextArea = () => {
    const shouldDisplayPatientReport = isFollowUp && sessionSummary.patientReport;
    if (formMode === 'view') {
      return (
        <TextAreaWrapper>
          <Label>
            {shouldDisplayPatientReport ? 'Patient report' : 'History of present illness'}
          </Label>
          <TextAreaRounded
            autoSize
            value={sessionSummary.patientReport || sessionSummary.presentIllnessHistory || ''}
            onChangeText={handlePresentIllnessHistoryChange}
            placeholder="Client’s report of their illness, subjective response to previous intervention, changes in their illness over time"
            textAreaStyle={textAreaStyle}
            style={getTextAreaContainerBorderStyle(colors, patientReportError)}
            disabled
            hasLimit
            limit={SESSION_SUMMARY_CHARS_LIMIT}
            dataQa="clientsReportTextArea"
          />
        </TextAreaWrapper>
      );
    }

    return (
      <>
        {isFollowUp ? (
          <TextAreaWrapper>
            <Label>Patient report</Label>
            <TextAreaRounded
              autoSize
              value={sessionSummary.patientReport || ''}
              onChangeText={handlePatientReportChange}
              placeholder="Client’s report of their illness, subjective response to previous intervention, changes in their illness over time"
              textAreaStyle={textAreaStyle}
              style={getTextAreaContainerBorderStyle(colors, patientReportError)}
              disabled={false}
              hasLimit
              limit={SESSION_SUMMARY_CHARS_LIMIT}
              dataQa="patientReportTextArea"
            />
          </TextAreaWrapper>
        ) : (
          <TextAreaWrapper>
            <Label>History of present illness</Label>
            <TextAreaRounded
              autoSize
              value={sessionSummary.presentIllnessHistory || ''}
              onChangeText={handlePresentIllnessHistoryChange}
              placeholder="Client’s report of their illness, subjective response to previous intervention, changes in their illness over time"
              textAreaStyle={textAreaStyle}
              style={getTextAreaContainerBorderStyle(colors, presentIllnessHistoryError)}
              disabled={false}
              hasLimit
              limit={SESSION_SUMMARY_CHARS_LIMIT}
              dataQa="presentIllnessHistoryTextArea"
            />
          </TextAreaWrapper>
        )}
      </>
    );
  };

  return (
    <AccordionContainer
      title="Session summary"
      open={formSections.psychSessionSummary.open}
      onPress={handleAccordionPress}
      showCheckMark={formMode !== 'view' && formSections.psychSessionSummary.completed}
      dataQa="psychSessionSummaryAccordion"
    >
      {renderTextArea()}
      <View>
        <Label>Assessment</Label>
        <TextAreaRounded
          autoSize
          value={sessionSummary.assessment || ''}
          onChangeText={handleAssessmentChange}
          placeholder="Brief summary of your diagnosis of the client’s existing condition"
          textAreaStyle={textAreaStyle}
          style={getTextAreaContainerBorderStyle(colors, assessmentError)}
          disabled={formMode === 'view'}
          hasLimit
          limit={SESSION_SUMMARY_CHARS_LIMIT}
          dataQa="assessmentTextArea"
        />
      </View>
      {formMode !== 'view' && !formSections.psychSessionSummary.completed && (
        <ConfirmButton onPress={handleConfirmPress} sectionTitle="psychSessionSummary" />
      )}
    </AccordionContainer>
  );
};

export default PsychSessionSummarySection;
