import moment from 'moment';
import { ID_STARRED_MESSAGE } from '@/utils/IDConstants';

export const getA11yAttributes = (
  currentMessage,
  bubbleTapped,
  boldTextID,
  textID,
  bottomBoldTextID,
  messageDateID,
  isLast
) => {
  const messageMoment = moment(currentMessage.createdAt);
  const format = messageMoment.isSame(moment(), 'd') ? 'h:mm A' : 'MMM D, h:mm A';
  const hiddenTime = messageMoment.format(format);
  const systemDescribedby = `${boldTextID} ${textID} ${bottomBoldTextID} ${messageDateID}`;
  let bubbleDescribedby = '';
  let bubbleExpanded: 'true' | 'false' | undefined;
  if (currentMessage.isStarred) {
    bubbleDescribedby = ID_STARRED_MESSAGE;
    if (bubbleTapped || isLast) {
      bubbleDescribedby += ` ${messageDateID}`;
    }
  } else if (bubbleTapped || isLast) {
    bubbleDescribedby = messageDateID;
  }
  if (!isLast) {
    if (bubbleTapped) {
      bubbleExpanded = 'true';
    } else {
      bubbleExpanded = 'false';
    }
  }
  return { systemDescribedby, bubbleDescribedby, bubbleExpanded, hiddenTime };
};

export default getA11yAttributes;
