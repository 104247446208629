import styled from '../../core/styled';
import TouchableView from '../TouchableView';
import { webOnlyStyle } from '../../core/styleHelpers';

const BusinessDay = styled(TouchableView)<{
  active: boolean;
}>(({ active, theme: { colors } }) => {
  return {
    backgroundColor: active ? colors.green : 'transparent',
    height: 37,
    width: 37,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 0,
    borderRadius: '50%',
    ...webOnlyStyle({
      cursor: 'pointer',
    }),
  };
});

export default BusinessDay;
