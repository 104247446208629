import {
  StarsIcon,
  TouchableView,
  styled,
  View,
  CheckV2,
  COLORS,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import { keyframes } from '@emotion/core';
import { useState } from 'react';
import { SmartNoteState } from './SmartNotePlaceholder';
import { useProgressNoteFormState } from '../../context/TherapistProgressNoteFormContext';
import { getNoteSummaryItemsColor } from '../../../utils';

const Label = styled(View)<{ isDisabled?: boolean }>(
  ({ theme: { colors }, isDisabled = false }) => {
    return {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '20px',
      ...(isDisabled ? { color: colors.softGray } : {}),
      marginLeft: 6,
    };
  }
);

const SmartNoteLabel = styled(View)<{ color: string }>(
  ({ color = COLORS.accessibilityGreenDark }) => {
    return {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '20px',
      color,
      marginLeft: 6,
    };
  }
);

const spin = keyframes({
  '0%': { transform: 'rotate(0deg)' },
  '100%': { transform: 'rotate(360deg)' },
});

export const Spinner = styled(View)(({ theme: { colors } }) => {
  return {
    width: 20,
    height: 20,
    animation: `${spin} 1s linear infinite`,
    borderRadius: '50%',
    border: `1px solid ${colors.softGray}`,
    borderTop: '2px solid transparent',
    justifySelf: 'center',
  };
});

export const StyledSmartNoteView = styled(View)<{ isDisabled?: boolean }>(
  ({ theme: { colors }, isDisabled = false }) => {
    return {
      '&:hover': {
        ...(isDisabled ? {} : { background: colors.grayishBlue }),
      },
      '&:focus': {
        ...(isDisabled ? {} : { background: colors.lightLavender }),
      },
      padding: '6px 8px 6px 6px',
      borderRadius: 8,
      gap: 4,
      alignItems: 'center',
      width: 141,
      height: 32,
    };
  }
);

interface SmartNoteProps {
  handleAddSmartNote: () => void;
  smartNoteDisabled?: boolean;
  smartNoteState: SmartNoteState;
}

const SmartNote = ({ handleAddSmartNote, smartNoteDisabled, smartNoteState }: SmartNoteProps) => {
  const { colors } = useEmotionTheme();

  const formState = useProgressNoteFormState();
  const [isHovering, setIsHovering] = useState<boolean>(false);

  const getButton = () => {
    if (smartNoteState === 'loading') {
      return (
        <>
          <Spinner /> <Label isDisabled>Drafting Smart Note</Label>
        </>
      );
    }

    if (
      smartNoteState === 'success' ||
      (smartNoteDisabled && formState.generatedSessionSummaryID)
    ) {
      return (
        <>
          <CheckV2 color={colors.softGray} /> <Label isDisabled>Smart Note added</Label>
        </>
      );
    }

    const isDisabled = Boolean(smartNoteState === 'error' || smartNoteDisabled);

    const color = getNoteSummaryItemsColor(isDisabled, isHovering);

    return (
      <StyledSmartNoteView isDisabled={isDisabled} row>
        <StarsIcon color={color} />
        <SmartNoteLabel color={color}>Add Smart Note</SmartNoteLabel>
      </StyledSmartNoteView>
    );
  };

  return (
    <TouchableView
      disabled={!!smartNoteState || smartNoteDisabled}
      onPress={handleAddSmartNote}
      row
      align="center"
      style={{ marginRight: 12 }}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      dataQa="addSmartNoteButton"
    >
      {getButton()}
    </TouchableView>
  );
};

export default SmartNote;
