import { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Route } from 'react-router-dom';
import moment from 'moment';
import {
  PanelHeader,
  PanelManager,
  ResponsiveLayoutWithHeader,
  useWindowWidth,
  View,
} from '@talkspace/react-toolkit';
import { getNavBarHeight } from 'components/Reusable/NavBar/NavBar';
import { ERoom, RoomType } from 'ts-frontend/entities/Room';
import { TherapistType } from 'ts-frontend/entities/Therapist';
import { VideoCallProvider } from 'chat/hooks/videoCallContext';
import ChatMessageContextProvider from 'chat/hooks/chatMessageContext';
import { InRoomSchedulingProvider, TherapistInfo } from 'inRoomScheduling';
import ReactFrameService from '@/auth/reactFrame/ReactFrameService';
import { useParams, useRouteMatch } from '@/core/routerLib';
import {
  getRoomProperties as getRoomPropertiesAction,
  resetInitialRoomState as resetInitialRoomStateAction,
} from '../../actions/RoomActions';
import './Room.css';
import { getActiveCustomerName } from '../../utils/customerHelper';
import { getUserData } from '../../utils/token';
import { tokenIsValid } from '../../utils/tokenIsValid';
import {
  getCustomerInformation as getCustomerInformationAction,
  resetInitialCustomerState as resetInitialCustomerStateAction,
} from '../../actions/CustomerInformationActions';
import { getAvailability as getAvailabilityAction } from '../../actions/AvailabilityActions';
import { getEmergencyContact as getEmergencyContactAction } from '../../actions/EmergencyContactActions';
import { getJourneyItems as getJourneyItemsAction } from '../../actions/JourneyActions';
import {
  getPresentingProblems as getPresentingProblemsAction,
  getConditions as getConditionsAction,
} from '../../actions/ClinicalInformationActions';
import IntakeQuestionnaire from './CRMContainer/CaseTab/IntakeQuestionnaire/IntakeQuestionnaire';
import InformedConsent from './CRMContainer/CaseTab/InformedConsent/InformedConsent';
import EmergencyContact from './CRMContainer/CaseTab/EmergencyContact/EmergencyContact';
import VideoCredits from './CRMContainer/CaseTab/VideoCredits/VideoCredits';
import VideoCallHistory from './CRMContainer/CaseTab/VideoCallHistory/VideoCallHistory';
import RoomCreation from './CRMContainer/CaseTab/RoomCreation/RoomCreation';
import Invites from './CRMContainer/CaseTab/Invites/Invites';
import QuickMatchAnswers from './CRMContainer/CaseTab/QuickMatchAnswers/QuickMatchAnswers';
import EligibilityQuestionnaire from './CRMContainer/CaseTab/EligibilityQuestionnaire/EligibilityQuestionnaire';
import OutcomeMeasureDetails from './CRMContainer/ClinicalTab/OutcomeMeasures/OutcomeMeasureDetails';
import DoseSpotPatientForm from './CRMContainer/ClinicalTab/Psychiatry/DoseSpotPatientForm';
import CrmMenu from './CRMContainer/CrmMenu';
import MessageContainer from './MessageContainer/MessageContainer';
import OutcomeMeasureCancel from './CRMContainer/ClinicalTab/OutcomeMeasures/OutcomeMeasureCancel';
import OutcomeMeasureStart from './CRMContainer/ClinicalTab/OutcomeMeasures/OutcomeMeasureStart';
import OutcomeMeasureAnswers from './CRMContainer/ClinicalTab/OutcomeMeasures/OutcomeMeasureAnswers';
import TreatmentPlannerGoals from './CRMContainer/ClinicalTab/TreatmentPlanner/TreatmentPlannerGoals';
import ClientLogins from './CRMContainer/CaseTab/EmergencyContact/ClientLogins';
import TreatmentPlannerObjectives from './CRMContainer/ClinicalTab/TreatmentPlanner/TreatmentPlannerObjectives';
import TreatmentPlannerInterventions from './CRMContainer/ClinicalTab/TreatmentPlanner/TreatmentPlannerInterventions';
import CreateNewTreatmentPlan from './CRMContainer/ClinicalTab/TreatmentPlanner/CreateNewTreatmentPlan';
import ArchivedTreatmentPlans from './CRMContainer/ClinicalTab/TreatmentPlanner/ArchivedTreatmentPlans';
import ArchivedTreatmentPlan from './CRMContainer/ClinicalTab/TreatmentPlanner/ArchivedTreatmentPlan';
import SessionSummarization from './CRMContainer/JourneyTab/SessionSummarization/SessionSummarization';
import DiagnosticProfilePicker from './CRMContainer/ClinicalTab/DiagnosticProfile/DiagnosticProfilePicker';
import DiagnosticProfile from './CRMContainer/ClinicalTab/DiagnosticProfile/DiagnosticProfile';
import InRoomSchedulingWrapper from './CRMContainer/LVSTab/InRoomSchedulingWrapper/InRoomSchedulingWrapper';
import TransferClient from './CRMContainer/CaseTab/TransferClient/TransferClient';
import CRMChatManager from './CRMChatManager/CRMChatManager';
import NavBarLogo from '../Reusable/NavBarLogo/NavBarLogo';
import NavBarUserBadge from '../Reusable/NavBarUserBadge/NavBarUserBadge';
import NavBarAccountDropDownMenu from '../Reusable/NavBarAccountDropDownMenu/NavBarAccountDropDownMenu';
import CRMChatSwitcher from './CRMChatSwitcher/CRMChatSwitcher';
import StarredContainer from './StarredContainer/StarredContainer';
import SharedFilesContainer from './SharedFilesContainer/SharedFilesContainer';
import CrmNavBar from './CRMContainer/CrmNavBar/CrmNavBar';
import NoteTypeExplanation from './CRMContainer/NotesTab/NoteTypeExplanation';
import styled from '../../core/styled';
import ProgressNoteForm from './CRMContainer/NotesTab/ProgressNoteForm';
import DischargeNoteForm from './CRMContainer/NotesTab/DischargeNoteForm';
import DischargeNoteFormNavigator from './CRMContainer/NotesTab/DischargeNoteFormNavigator';
import DeleteDischargeNoteDialog from './CRMContainer/NotesTab/DeleteDischargeNoteDialog';
import PsychotherapyNoteForm from './CRMContainer/NotesTab/PsychotherapyNoteForm';
import PsychiatryReferralNoteForm from './CRMContainer/NotesTab/PsychiatryReferralNoteForm';
import TransferNoteForm from './CRMContainer/NotesTab/TransferNoteForm';
import CollateralNoteForm from './CRMContainer/NotesTab/CollateralNoteForm';
import DeleteCollateralNoteDialog from './CRMContainer/NotesTab/DeleteCollateralNoteDialog';
import DeletePsychotherapyNoteDialog from './CRMContainer/NotesTab/DeletePsychotherapyNoteDialog';
import PostLVSPrompt from './CRMContainer/NotesTab/PostLVSPrompt';
import DeleteCaseConsultationNoteDialog from './CRMContainer/NotesTab/DeleteCaseConsultationNoteDialog';
import CaseConsultationNoteForm from './CRMContainer/NotesTab/CaseConsultationNoteForm';
import SaveCollateralNoteDialog from './CRMContainer/NotesTab/SaveCollateralNoteDialog';
import SaveCaseConsultationNoteDialog from './CRMContainer/NotesTab/SaveCaseConsultationNoteDialog';
import SaveDischargeNoteDialog from './CRMContainer/NotesTab/SaveDischargeNoteDialog';
import RestartCare from './CRMContainer/CaseTab/RestartCare/RestartCare';
import ConfirmDischargeNote from './CRMContainer/NotesTab/ConfirmDischargeNote';
import IncompleteNotes from './CRMContainer/NotesTab/IncompleteNotes';
import trackEvent from '../../modules/analytics/trackEvent';
import AllUnsubmittedSessions from './CRMContainer/NotesTab/CTA/AllUnsubmittedSessions';
import { CustomerInformation } from '../../types/customer';
import useQueryParticipants from '../../hooks/useQueryParticipants';

const LeftPanelHeader = ({ roomID, nickname, description }) => {
  const { isMobile } = useWindowWidth();
  return (
    <PanelHeader
      renderLeft={() =>
        isMobile ? (
          <NavBarUserBadge roomID={roomID} nickname={nickname} description={description} />
        ) : (
          <NavBarLogo />
        )
      }
      style={isMobile ? {} : { paddingLeft: 8 }}
    />
  );
};

const MiddlePanelHeader = ({ roomID, nickname, description }) => {
  const { isMobile } = useWindowWidth();
  return (
    <PanelHeader
      renderLeft={() => (
        <NavBarUserBadge roomID={roomID} nickname={nickname} description={description} />
      )}
      // @ts-expect-error old redux component
      renderRight={() => <NavBarAccountDropDownMenu hidden={isMobile} />}
    />
  );
};

const RoomPanel = ({ panelHeader, children, hideHeader = false }) => {
  const { isMobile } = useWindowWidth();
  return (
    <ResponsiveLayoutWithHeader
      shouldScroll
      headerHeight={hideHeader ? 0 : getNavBarHeight(isMobile)}
      renderHeader={() =>
        hideHeader ? null : (
          <View flex={1}>
            {panelHeader}
            <CRMChatSwitcher />
          </View>
        )
      }
    >
      {children}
    </ResponsiveLayoutWithHeader>
  );
};

const RoomRoute = styled(View)({
  flexDirection: 'row',
});

const RoomWrapper = styled(View)({
  width: '100%',
  backgroundColor: 'white',
  flex: 1,
  overflowY: 'overlay',
});

interface RoomStore {
  roomType: RoomType;
  createdAt: Date;
  isVideoOnly: boolean;
  clientUserID: number;
  isStarredMessagesOpen: boolean;
  isSharedFilesOpen: boolean;
  isPostLVSPromptOpen: boolean;
}

interface Props {
  room: RoomStore;
  customerInformation: CustomerInformation;
  emergencyContactByClientUserID: Record<number, any>;
  therapistType: TherapistType;
  presentingProblems: any[];
  getAvailability: (therapistUserID: number) => void;
  getCustomerInformation: (clientUserID: number) => void;
  getEmergencyContact: (clientUserID: number) => void;
  getRoomProperties: (roomID: number) => void;
  resetInitialCustomerState: () => void;
  resetInitialRoomState: () => void;
  getJourneyItems: () => void;
  getPresentingProblems: (clientUserID: number) => void;
  getConditions: (clientUserID: number) => void;
}

const Room = ({
  room,
  customerInformation,
  emergencyContactByClientUserID,
  therapistType,
  presentingProblems,
  getAvailability,
  getCustomerInformation,
  getEmergencyContact,
  getRoomProperties,
  resetInitialCustomerState,
  resetInitialRoomState,
  getJourneyItems,
  getPresentingProblems,
  getConditions,
}: Props) => {
  const { roomID } = useParams<{ roomID: string }>();
  const match = useRouteMatch();

  const roomRef = useRef<ERoom | null>(null);
  const therapistInfoRef = useRef<TherapistInfo | null>(null);

  const { data: participants = [] } = useQueryParticipants(Number(roomID));

  useEffect(() => {
    if (!tokenIsValid()) {
      window.location.pathname = '/logout';
    }

    return () => {
      resetInitialCustomerState();
      resetInitialRoomState();
    };
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getRoomProperties(Number(roomID));
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [roomID]);

  useEffect(() => {
    getAvailability(getUserData().id);
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const initializeComponent = async () => {
      if (!room?.clientUserID) {
        return;
      }

      getCustomerInformation(room.clientUserID);

      if (room.roomType === 'psychiatry_room' && therapistType === 'psychiatrist') {
        getEmergencyContact(room.clientUserID);
      }

      getPresentingProblems(room.clientUserID);

      if (participants.length > 0) {
        getConditions(participants[0].userID);
        getJourneyItems();
      }
    };

    initializeComponent();
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [participants, room, therapistType]);

  useEffect(() => {
    if (!room) {
      return;
    }

    // @ts-expect-error looks like we need to construct the ERoom instance based on something other than room reducer
    roomRef.current = new ERoom({
      expirationDate: new Date(),
      therapistID: getUserData().id,
      roomType: room.roomType,
      createdAt: room.createdAt,
      roomID: Number(roomID),
      isVideoOnly: room.isVideoOnly,
    });

    therapistInfoRef.current = {
      id: getUserData().id,
      firstName: getUserData().firstName,
      lastName: getUserData().lastName,
    };
  }, [room, roomID]);

  useEffect(() => {
    if (roomID) {
      trackEvent('roomOpened', { actionName: 'providerRoomOpened' }, { roomID });
    }
  }, [roomID]);

  const getChildComponents = () => {
    const meetsPsychiatryRequirements =
      (room.roomType === 'psychiatry_room' &&
        therapistType === 'psychiatrist' &&
        emergencyContactByClientUserID[customerInformation.clientUserID]) ||
      room.roomType !== 'psychiatry_room' ||
      therapistType !== 'psychiatrist';
    const displayName = getActiveCustomerName(customerInformation, participants);

    return roomID &&
      participants.length &&
      customerInformation &&
      customerInformation.clientUserID &&
      meetsPsychiatryRequirements ? (
      <RoomWrapper>
        <Route path={`${match.path}`} component={CrmMenu} />
        <Route path={`${match.path}/invites`} component={Invites} />
        <Route path={`${match.path}/create-room`} component={RoomCreation} />
        <Route
          path={`${match.path}/create-psychiatry-room`}
          render={(props) => <RoomCreation {...props} isPsychiatry />}
        />
        <Route path={`${match.path}/video-credits`} component={VideoCredits} />
        <Route path={`${match.path}/video-call-history`} component={VideoCallHistory} />
        <Route path={`${match.path}/informed-consent`} component={InformedConsent} />
        <Route path={`${match.path}/quick-match-answers`} component={QuickMatchAnswers} />
        <Route path={`${match.path}/outcome-measure`} component={OutcomeMeasureDetails} />
        <Route path={`${match.path}/outcome-measure-cancel`} component={OutcomeMeasureCancel} />
        <Route path={`${match.path}/outcome-measure-start`} component={OutcomeMeasureStart} />
        <Route path={`${match.path}/outcome-measure-answers`} component={OutcomeMeasureAnswers} />
        <Route path={`${match.path}/emergency-contact-login`} component={EmergencyContact} />
        <Route path={`${match.path}/client-logins`} component={ClientLogins} />
        <Route path={`${match.path}/intake-questionnaire`} component={IntakeQuestionnaire} />
        <Route
          path={`${match.path}/eligibility-questionnaire`}
          component={EligibilityQuestionnaire}
        />
        <Route path={`${match.path}/treatment-planner-goals`} component={TreatmentPlannerGoals} />
        <Route
          path={`${match.path}/treatment-planner-objectives`}
          component={TreatmentPlannerObjectives}
        />
        <Route
          path={`${match.path}/treatment-planner-interventions`}
          component={TreatmentPlannerInterventions}
        />
        <Route
          path={`${match.path}/create-new-treatment-plan`}
          component={CreateNewTreatmentPlan}
        />
        <Route path={`${match.path}/archived-treatment-plans`} component={ArchivedTreatmentPlans} />
        <Route
          path={`${match.path}/archived-treatment-plan/:id`}
          component={ArchivedTreatmentPlan}
        />
        <Route path={`${match.path}/dosespot-patient-form`} component={DoseSpotPatientForm} />
        <Route path={`${match.path}/session-summary`} component={SessionSummarization} />
        <Route path={`${match.path}/diagnostic-profiles`} component={DiagnosticProfilePicker} />
        <Route
          path={`${match.path}/diagnostic-profile/:clientUserID`}
          component={DiagnosticProfile}
        />
        <Route path={`${match.path}/discharge-and-transfer`} component={TransferClient} />
        <Route
          path={`${match.path}/in-room-scheduling`}
          render={() => (
            <ChatMessageContextProvider>
              {roomRef.current && therapistInfoRef.current && (
                <InRoomSchedulingWrapper
                  room={roomRef.current}
                  clientDisplayName={displayName}
                  therapist={therapistInfoRef.current}
                  clientUserID={customerInformation.clientUserID}
                />
              )}
            </ChatMessageContextProvider>
          )}
        />
        <Route
          path={`${match.path}/progress-notes/new`}
          render={() => <ProgressNoteForm mode="create" />}
        />
        <Route
          path={`${match.path}/progress-notes/:noteID/edit`}
          render={() => <ProgressNoteForm mode="edit" />}
        />
        <Route
          path={`${match.path}/progress-notes/:noteID/view`}
          render={() => <ProgressNoteForm mode="view" />}
        />
        <Route
          path={`${match.path}/discharge-notes/new`}
          // @ts-expect-error the component still uses redux state
          // TS2739: Type { mode: "create"; } is missing the following properties from type Props: clientUserID, conditions, getConditions
          render={() => <DischargeNoteFormNavigator mode="create" />}
        />
        <Route
          path={`${match.path}/discharge-notes/:noteID/edit`}
          // @ts-expect-error the component still uses redux state
          // TS2739: Type { mode: "edit"; } is missing the following properties from type Props: clientUserID, conditions, getConditions
          render={() => <DischargeNoteFormNavigator mode="edit" />}
        />
        <Route
          path={`${match.path}/discharge-notes/:noteID/delete`}
          render={() => <DeleteDischargeNoteDialog />}
        />
        <Route
          path={`${match.path}/discharge-notes/:noteID/view`}
          render={() => <DischargeNoteForm mode="view" />}
        />
        <Route
          path={`${match.path}/discharge-notes/save-draft`}
          component={SaveDischargeNoteDialog}
        />
        <Route
          path={`${match.path}/discharge-notes/confirm-discharge`}
          component={ConfirmDischargeNote}
        />
        <Route path={`${match.path}/incomplete-notes`} component={IncompleteNotes} />
        <Route
          path={`${match.path}/case-consultation-notes/new`}
          render={() => <CaseConsultationNoteForm mode="create" />}
        />
        <Route
          path={`${match.path}/case-consultation-notes/:noteID/view`}
          render={() => <CaseConsultationNoteForm mode="view" />}
        />
        <Route
          path={`${match.path}/case-consultation-notes/:noteID/edit`}
          render={() => <CaseConsultationNoteForm mode="edit" />}
        />
        <Route
          path={`${match.path}/case-consultation-notes/:noteID/delete`}
          render={() => <DeleteCaseConsultationNoteDialog />}
        />
        <Route
          path={`${match.path}/case-consultation-notes/save-draft`}
          component={SaveCaseConsultationNoteDialog}
        />
        <Route
          path={`${match.path}/collateral-notes/new`}
          render={() => <CollateralNoteForm mode="create" />}
        />
        <Route
          path={`${match.path}/collateral-notes/:noteID/view`}
          render={() => <CollateralNoteForm mode="view" />}
        />
        <Route
          path={`${match.path}/collateral-notes/:noteID/edit`}
          render={() => <CollateralNoteForm mode="edit" />}
        />
        <Route
          path={`${match.path}/collateral-notes/:noteID/delete`}
          component={DeleteCollateralNoteDialog}
        />
        <Route
          path={`${match.path}/collateral-notes/save-draft`}
          component={SaveCollateralNoteDialog}
        />
        <Route
          path={`${match.path}/psychiatry-referral-notes/:noteID/view`}
          render={() => <PsychiatryReferralNoteForm mode="view" />}
        />
        <Route
          path={`${match.path}/psychotherapy-notes/new`}
          render={() => <PsychotherapyNoteForm mode="create" />}
        />
        <Route
          path={`${match.path}/psychotherapy-notes/:noteID/edit`}
          render={() => <PsychotherapyNoteForm mode="edit" />}
        />
        <Route
          path={`${match.path}/psychotherapy-notes/:noteID/delete`}
          component={DeletePsychotherapyNoteDialog}
        />
        <Route
          path={`${match.path}/transfer-notes/:noteID/view`}
          render={() => <TransferNoteForm mode="view" />}
        />
        <Route path={`${match.path}/note-types-explainer`} component={NoteTypeExplanation} />
        <Route path={`${match.path}/restart-care`} component={RestartCare} />
        <Route path={`${match.path}/all-unsubmitted-sessions`} component={AllUnsubmittedSessions} />
      </RoomWrapper>
    ) : null;
  };

  const isInFrame = ReactFrameService.instance().isInFrame();

  const descriptionItems: string[] = [];
  const userBadgeGender = customerInformation.gender && customerInformation.gender.label;
  const nickname = getActiveCustomerName(customerInformation, participants);
  const diagnosis = presentingProblems[0] && presentingProblems[0].label;
  const age =
    customerInformation.dateOfBirth &&
    moment.duration(moment().diff(moment(customerInformation.dateOfBirth))).years();
  if (userBadgeGender) descriptionItems.push(userBadgeGender);
  if (age) descriptionItems.push(age.toString());
  if (diagnosis) descriptionItems.push(diagnosis);
  const description = descriptionItems.join(', ');

  const CRMPanel = (
    <RoomPanel
      hideHeader={isInFrame}
      panelHeader={
        <LeftPanelHeader roomID={roomID} nickname={nickname} description={description} />
      }
    >
      <Route
        path={`${match.path}/(agenda-tab|case-tab|notes-tab|clinical-tab|lvs-tab)`}
        component={CrmNavBar}
      />
      {getChildComponents()}
    </RoomPanel>
  );

  const ChatPanel = (
    <RoomPanel
      panelHeader={
        <MiddlePanelHeader roomID={roomID} nickname={nickname} description={description} />
      }
    >
      <MessageContainer roomID={roomID} />
    </RoomPanel>
  );

  return (
    <VideoCallProvider>
      <InRoomSchedulingProvider>
        <RoomRoute className="room-route">
          <CRMChatManager>
            {!isInFrame && (
              <PanelManager.LeftPanel style={{ overflowY: 'hidden' }}>
                {CRMPanel}
              </PanelManager.LeftPanel>
            )}
            <PanelManager.MiddlePanel style={{ overflowY: 'hidden' }}>
              {isInFrame ? CRMPanel : ChatPanel}
            </PanelManager.MiddlePanel>
            <PanelManager.RightPanel>
              {room.isStarredMessagesOpen && <StarredContainer />}
              {room.isSharedFilesOpen && <SharedFilesContainer />}
              {room.isPostLVSPromptOpen && <PostLVSPrompt />}
            </PanelManager.RightPanel>
          </CRMChatManager>
        </RoomRoute>
      </InRoomSchedulingProvider>
    </VideoCallProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    room: state.room,
    customerInformation: state.customerInformation,
    emergencyContactByClientUserID: state.emergencyContact.emergencyContactByClientUserID,
    therapistType: state.availability.therapistType,
    presentingProblems: state.clinicalInformation.presentingProblems,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAvailability: getAvailabilityAction,
      getCustomerInformation: getCustomerInformationAction,
      getEmergencyContact: getEmergencyContactAction,
      getRoomProperties: getRoomPropertiesAction,
      resetInitialCustomerState: resetInitialCustomerStateAction,
      resetInitialRoomState: resetInitialRoomStateAction,
      getJourneyItems: getJourneyItemsAction,
      getPresentingProblems: getPresentingProblemsAction,
      getConditions: getConditionsAction,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Room);
