import moment from 'moment';
import axios from 'axios';
import { routePromise } from 'ts-frontend/helpers/routePromise';
import { localStorageTableNames } from 'components/Dashboard/types';
import { clearIonicStorage } from 'ts-ionic/plugins/secureStorage';
import { getIonicInfo } from 'ts-ionic/plugins/capacitor';
import apiHelper from '../core/api/apiHelper';
import ReactFrameService from './reactFrame/ReactFrameService';
import storage, { StoragePersistanceMode } from '../core/storage';
import {
  setTokens,
  removeTokens,
  getAccessToken,
  getRefreshToken,
  getTokens,
  getBypassTwoFactorToken,
  removeBypassTwoFactorToken,
  removeLogoutLastActivity,
  getDeviceToken,
  getUserData,
} from './helpers/token';
import appConfig from '../utils/configs';
import {
  THERAPIST_CASELOAD_FILTERS,
  PSYCHIATRIST_CASELOAD_FILTERS,
} from '../../components/Dashboard/DashboardFilter/constants';

interface RefreshTokenResponse {
  userID: number;
  access: string;
  refresh: string;
  accessTTLSeconds: number;
  refreshTTLSeconds: number;
}

let revokeInProcess = false;
let refreshInProcessPromise;

export type AuthSessionContext = 'jwt' | 'auth' | 'frame';

/**
 * Determines who should handle authentication
 * Options: JWT, and frame's PostMessages, Regular access/refresh token
 * The priority should be in the same order
 * Example: If user already logged in but opened email with a JWT token,
 * the JWT token should be used for that session.
 * @returns {string} One of "jwt", "frame", and "auth"
 */
export const getAuthSessionContext = (): AuthSessionContext => {
  // NOTE: Order matters!
  if (ReactFrameService.instance().isUsingJWT()) return 'jwt';
  if (ReactFrameService.instance().isInFrame()) return 'frame';
  // The following two `if` cases and the default are redundant since they all return `"auth"` but leaving them for readability
  // Try sessionStorage first (Happens when reloading the same tab since it doesn't clear sessionStorage)
  storage.setStoragePersistanceMode(StoragePersistanceMode.SESSION);
  if (getRefreshToken()) return 'auth';
  // Try localStorage second
  storage.setStoragePersistanceMode(StoragePersistanceMode.LOCAL);
  if (getRefreshToken()) return 'auth';

  return 'auth';
};

const refreshTokenAPI = async () => {
  const { refreshToken, lastRefreshed } = getTokens();
  const secFromLastRefresh = (Date.now() - (lastRefreshed || 0)) / 1000;
  // Handle slower calls that ended with 401 just after a refresh completed
  if (secFromLastRefresh < 5) return true;
  if (secFromLastRefresh < 2 * 60) throw new Error('Refreshed two minutes ago');
  if (!refreshToken) throw new Error('No refresh token');
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  const res = await fetch(`${apiHelper().authAPIEndpoint}/v2/refresh`, {
    headers,
    method: 'POST',
    body: JSON.stringify({
      refresh: refreshToken,
      platform: appConfig.platform,
      version: appConfig.appVersion,
    }),
  });
  if (res.status !== 200) throw new Error('Could not refresh token');
  const { data } = (await res.json()) as { data: RefreshTokenResponse };
  const { access, refresh, accessTTLSeconds, refreshTTLSeconds } = data;
  setTokens({
    accessTTLSeconds,
    refreshTTLSeconds,
    access,
    refresh,
  });
  return true;
};

const revokeTokenAPI = () => {
  const accessToken = getAccessToken();
  if (!accessToken) return Promise.reject(new Error('No access token'));
  const headers = new Headers();
  headers.append('Authorization', `Bearer ${getAccessToken()}`);
  headers.append('Content-Type', 'application/json');
  return fetch(`${apiHelper().authAPIEndpoint}/v2/revoke`, {
    method: 'POST',
    headers,
  });
};

export const logout = () => {
  storage.removeItem(THERAPIST_CASELOAD_FILTERS);
  storage.removeItem(PSYCHIATRIST_CASELOAD_FILTERS);
  clearIonicStorage();
  removeTokens();
  removeLogoutLastActivity();
  routePromise('/login');
};

const refreshTokenAPIGuard = (
  preventLogout?: boolean,
  forceRefresh?: boolean
): Promise<boolean> => {
  const { accessTTL } = getTokens();
  // If it expires within 30 minutes
  if (forceRefresh || moment(accessTTL).isBefore(moment().add(30, 'minutes'))) {
    // allow only one refresh call
    if (refreshInProcessPromise) return refreshInProcessPromise;

    refreshInProcessPromise = new Promise((resolve) => {
      // make the api call
      refreshTokenAPI()
        .then((success) => {
          refreshInProcessPromise = undefined;
          resolve(success);
        })
        .catch(() => {
          refreshInProcessPromise = undefined;
          if (!preventLogout) logout();
          else removeTokens();
          resolve(false);
        });
    });
    return refreshInProcessPromise;
  }

  // AccessToken still valid, not refreshing
  return Promise.resolve(true);
};

export const haltIfRevokeInProcess = (): Promise<void> => {
  if (revokeInProcess)
    return new Promise((resolve, reject) => {
      setTimeout(reject, 60000);
    });
  return Promise.resolve();
};

export const refreshToken = async (
  preventLogout?: boolean,
  forceRefresh?: boolean
): Promise<boolean> => {
  await haltIfRevokeInProcess();
  const authSessionContext = getAuthSessionContext();
  if (authSessionContext === 'frame')
    return new Promise((resolve) => {
      ReactFrameService.instance().refreshTokenEvent(resolve);
    });
  if (authSessionContext === 'auth') {
    return refreshTokenAPIGuard(preventLogout, forceRefresh);
  }
  // JWT
  if (!preventLogout) logout();
  return Promise.reject(new Error('Cannot refresh with the current token type'));
};

export const revokeToken = (): void => {
  if (revokeInProcess) return;
  revokeInProcess = true;
  const authSessionContext = getAuthSessionContext();
  if (authSessionContext === 'frame') {
    window.location.href = '/unauthorized';
    return;
  }
  if (authSessionContext === 'auth') {
    revokeTokenAPI()
      .catch(() => {
        // do nothing
      })
      .finally(logout);
    return;
  }
  // JWT
  throw new Error('Cannot revoke with current token type');
};

const logoutUser = async () =>
  new Promise<void>((resolve) => {
    const token = storage.getItem('id_token');
    if (token && token.length) {
      revokeToken();
      resolve();
    } else {
      logout();
      resolve();
    }
  });

export const sendDeviceToken = async (deviceToken: string) => {
  const headers = new Headers();
  headers.append('Authorization', `Bearer ${getAccessToken()}`);
  headers.append('Content-Type', 'application/json');
  const userID = getUserData().id;
  const info = await getIonicInfo();
  if (!info) return Promise.resolve(false);
  const { version, osVersion, deviceSubType } = info;

  return fetch(`${apiHelper().liveVideoEndpoint}/v2/deviceTokens`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      userID,
      deviceToken,
      isVoip: false,
      userType: 1, // therapist
      appVersion: version,
      osVersion,
      deviceType: 1, // ios
      deviceSubtype: deviceSubType,
    }),
  });
};

export const deleteDeviceToken = async (): Promise<boolean> => {
  const deviceToken = getDeviceToken();
  if (!deviceToken) return true;

  const headers = new Headers();
  headers.append('Authorization', `Bearer ${getAccessToken()}`);
  headers.append('Content-Type', 'application/json');

  const response = await fetch(`${apiHelper().liveVideoEndpoint}/v2/deviceTokens`, {
    method: 'DELETE',
    headers,
    body: JSON.stringify({ deviceToken }),
  });

  storage.removeItem('device_token');
  return response.status === 200;
};

export const forgetDevice = () => {
  const xhr = axios.create();
  const bypassTwoFactorToken = getBypassTwoFactorToken();
  removeBypassTwoFactorToken();
  return xhr.request({
    baseURL: `${apiHelper().authAPIEndpoint}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${storage.getItem('id_token')}`,
    },
    data: { bypassTwoFactorToken },
    url: '/v3/2fa/forget-device',
    method: 'post',
  });
};

// This promise always succeeds
export const invalidateTokenAndRedirect = ({ shouldForgetDevice }) => {
  storage.removeItem('id_token_expiration_date');
  storage.removeItem('id_refresh_token');
  storage.removeItem('id_refresh_token_expiration_date');
  storage.removeItem(localStorageTableNames.caseLoad);
  storage.removeItem(localStorageTableNames.progressNotes);

  const bypassTwoFactorToken = !!getBypassTwoFactorToken();
  if (shouldForgetDevice && bypassTwoFactorToken) {
    return forgetDevice().then(deleteDeviceToken).then(logoutUser).catch(logoutUser);
  }
  return deleteDeviceToken().then(logoutUser).catch(logoutUser);
};
