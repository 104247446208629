import { FunctionComponent } from 'react';
import { Provider } from 'react-redux';

import { Route } from '@/core/routerLib';
import PDFViewerContainer from './containers/PDFViewerContainer';
import EndLiveChatModal from './components/EndLiveChatModal';
import createStore from './redux/chatStore';
import { VideoCallProvider } from './hooks/videoCallContext';
import LiveChatSessionStartedModalContainer from './containers/LiveChatSessionStartedModalContainer';

interface Props {
  isTherapistChat: boolean;
}

const ModalRoutes: FunctionComponent<Props> = ({ isTherapistChat }) => (
  <Provider store={createStore(isTherapistChat)}>
    <VideoCallProvider>
      <Route path="/pdf-viewer" component={PDFViewerContainer} />
      <Route path="/chat-session-start" component={LiveChatSessionStartedModalContainer} />
      <Route path="/end-live-chat" component={EndLiveChatModal} />
    </VideoCallProvider>
  </Provider>
);

export default ModalRoutes;
