import { Standard, View, useWindowWidth, Tag } from '@talkspace/react-toolkit';

import styled from '../../../../core/styled';

interface ClientCellProps {
  fullName: string;
  nickname?: string | null;
  preferredName: string | null;
  isDischarged: boolean;
  isTransferred?: boolean | null;
}

interface WindowsizeProps {
  isSmall: boolean;
  isMedium: boolean;
  isLarge: boolean;
  isDesktop: boolean;
}

const getMaxContainerWidth = (window: WindowsizeProps) => {
  const { isLarge, isSmall } = window;

  if (isLarge) return 250;
  if (isSmall) return 100;
  return 120;
};

const Container = styled(Standard)(({ theme: { window } }) => {
  return {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: getMaxContainerWidth(window as WindowsizeProps),
  };
});

const Title = styled(Container)(
  ({
    theme: {
      colors,
      window: { isDesktop },
    },
  }) => {
    return {
      color: isDesktop ? colors.black : colors.purple600,
      fontSize: isDesktop ? 15 : 14,
      fontWeight: isDesktop ? 700 : 400,
      marginRight: 4,
    };
  }
);

const Subtitle = styled(Container)(
  ({
    theme: {
      colors,
      window: { isDesktop },
    },
  }) => {
    return {
      color: isDesktop ? colors.slateGrey : colors.purple600,
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 1,
    };
  }
);

const TitleWrapper = styled(View)({
  display: 'flex',
  flexDirection: 'row',
  gap: 5,
});

const ClientCell = ({
  fullName,
  nickname,
  preferredName,
  isDischarged,
  isTransferred,
}: ClientCellProps) => {
  const { isDesktop } = useWindowWidth();

  let title;
  let subtitle;

  if (fullName) {
    title = fullName;
    subtitle = preferredName || nickname;
  } else {
    title = nickname;
    subtitle = preferredName;
  }

  return (
    <View style={{ marginRight: 15 }}>
      <TitleWrapper>
        <Container>
          <Title>{title}</Title>
        </Container>
        {isDischarged && <Tag text="Discharged" color="infoDefault" />}
        {isTransferred && <Tag text="Transferred" color="infoDefault" />}
      </TitleWrapper>

      {isDesktop && (
        <Container>
          <Subtitle>{subtitle || 'Pending client name'}</Subtitle>{' '}
        </Container>
      )}
    </View>
  );
};
export default ClientCell;
