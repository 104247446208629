import Svg, { Path } from 'svgs';
import { useEmotionTheme } from '../../core/styled';
import type { EmotionStyle } from '../../core/styled';

export interface ShieldIconProps {
  width?: number;
  height?: number;
  color?: string;
  style?: EmotionStyle;
}

const ShieldIcon = ({ color, width = 24, height = 28, ...otherProps }: ShieldIconProps) => {
  const { colors } = useEmotionTheme();
  return (
    <Svg
      width={width}
      height={height}
      title="choose a new way to pay"
      aria-label="choose a new way to pay"
      viewBox="0 0 22 28"
      fill="none"
      {...otherProps}
    >
      <Path
        d="M9.66659 18H12.3333V14.6667H15.6666V12H12.3333V8.66666H9.66659V12H6.33325V14.6667H9.66659V18ZM10.9999 27.3C7.88881 26.5222 5.33325 24.7167 3.33325 21.8833C1.33325 19.05 0.333252 15.9444 0.333252 12.5667V4.63333L10.9999 0.633331L21.6666 4.63333V12.5667C21.6666 15.9444 20.6666 19.05 18.6666 21.8833C16.6666 24.7167 14.111 26.5222 10.9999 27.3Z"
        fill={color || colors.a11yRoyalBlue}
      />
    </Svg>
  );
};

export default ShieldIcon;
