import { View, Input, TouchableView, FloatingMenu, useWindowWidth } from '@talkspace/react-toolkit';
import { VoidFunctionComponent, useCallback } from 'react';
import { AllClientState } from '../../types';
import trackEvent from '@/analytics/trackEvent';
import { DashboardInteraction } from '@/analytics/types';
import styled from '../../../../core/styled';

const StyledInput = styled(Input)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      background: 'transparent',
      border: 'none',
      padding: 9,
      flex: isMobile && 1,
    };
  }
);

const Container = styled(View)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      height: 36,
      borderRadius: 6,
      border: '1px solid #B6C8DA',
      marginBottom: 10,
      position: 'relative',
      marginTop: isMobile && 15,
      flex: 1,
    };
  }
);

const StyledButton = styled(TouchableView)(
  ({
    theme: {
      colors,
      window: { isMobile },
    },
  }) => {
    return {
      flex: isMobile && 0.5,
      background: colors.greyBubble,
      textAlign: 'center',
      lineHeight: '36px',
      borderLeft: '1px solid #B6C8DA',
      borderRadius: '0 6px 6px 0px',
      padding: '0 10px',
      width: !isMobile && 102,
      alignItems: 'center',
      gap: 5,
      justifyContent: 'center',
    };
  }
);

const Item = styled(TouchableView)<{ isSelected: boolean }>(({ theme: { colors }, isSelected }) => {
  return {
    padding: 10,
    background: isSelected && colors.whiteLilac,
  };
});

const ArrowDown = styled(View)(({ theme: { colors } }) => {
  return {
    width: 7,
    height: 4,
    borderLeft: '4px solid transparent',
    borderRight: '4px solid transparent',
    borderTop: `6px solid ${colors.black}`,
  };
});

const getfloatingMenyStyle = (isMobile: boolean) => {
  return {
    position: 'absolute',
    left: isMobile ? 220 : 150,
    top: 40,
    height: 'max-content',
    maxHeight: 'unset',
    width: 120,
    zIndex: 300,
    minWidth: 'unset',
  };
};
interface SearchFiltersProps {
  handleSearch: (event) => void;
  toggleSearchTypeOpen: () => void;
  currentState: AllClientState;
  setSearchType: (event: string) => void;
  getEventProperties: () => DashboardInteraction;
}

const MenuItems = [
  { id: 'Name', title: 'Name' },
  { id: 'Nickname', title: 'Nickname' },
  { id: 'User', title: 'User ID' },
  { id: 'Room', title: 'Room ID' },
];

const SearchFilters: VoidFunctionComponent<SearchFiltersProps> = ({
  currentState,
  getEventProperties,
  handleSearch,
  toggleSearchTypeOpen,
  setSearchType,
}) => {
  const { isMobile } = useWindowWidth();
  const handleSearchFocus = useCallback(() => {
    trackEvent('clientListSearched', { actionName: 'activeExperiments' }, getEventProperties());
  }, [getEventProperties]);

  const floatingMenuStyle = getfloatingMenyStyle(isMobile);

  return (
    <Container row justify="space-between" style={{ width: '100%' }}>
      <StyledInput
        onChange={handleSearch}
        onFocus={handleSearchFocus}
        type="text"
        placeholder="Search"
        value={currentState.searchString}
      />

      <StyledButton row onPress={toggleSearchTypeOpen}>
        <View>{MenuItems.find((it) => it.id === currentState.searchType)?.title}</View>
        <ArrowDown />
      </StyledButton>
      <FloatingMenu
        style={floatingMenuStyle}
        hide={!currentState.searchTypeOpen}
        onBackdropPress={toggleSearchTypeOpen}
      >
        {MenuItems.map((it) => (
          <Item
            isSelected={it.title === currentState.searchType}
            onPress={() => setSearchType(it.id)}
            key={it.id}
          >
            {it.title}
          </Item>
        ))}
      </FloatingMenu>
    </Container>
  );
};

export default SearchFilters;
