import { FunctionComponent, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import { getUserData } from '@/auth/helpers/token';
import { AppState } from '../redux/chatStore';
import {
  dispatchGetSharedFiles,
  dispatchGetHistoricalSharedFiles,
  dispatchClearSharedFiles,
  dispatchToggleStarMessage,
} from '../redux/actions/chatActions';
import { EMessage } from '../entities/EMessage';
import { AckLevel } from '../types';
import SharedFiles from '../components/SharedFiles';

interface Props {
  roomID: number;
  containerWidth: number;
  isTherapistChat: boolean;
  isLoading: boolean;
  isLoadingHistorical: boolean;
  receivedAllMessages: boolean;
  sharedFiles: EMessage[];
  maxAckReadId: number;
  maxAckReceivedId: number;
  getSharedFiles: (roomID: number) => void;
  getHistoricalSharedFiles: (roomID: number) => void;
  clearSharedFiles: () => void;
  onClosePress: () => void;
  toggleStarMessage: (messageID: number, isStarred: boolean) => void;
}

const SharedFilesContainer: FunctionComponent<Props> = ({
  roomID,
  containerWidth,
  isTherapistChat,
  onClosePress,
  isLoading,
  isLoadingHistorical,
  receivedAllMessages,
  sharedFiles,
  getSharedFiles,
  getHistoricalSharedFiles,
  clearSharedFiles,
  maxAckReadId,
  maxAckReceivedId,
  toggleStarMessage,
}) => {
  const loggedInUserID = useRef(0);

  useEffect(() => {
    loggedInUserID.current = getUserData().id;
  }, []);

  useEffect(() => {
    getSharedFiles(roomID);
  }, [roomID, getSharedFiles]);

  useEffect(() => () => clearSharedFiles(), [clearSharedFiles]);

  const handleLoadMorePress = () => {
    getHistoricalSharedFiles(roomID);
  };

  const getAckLevel = (messageID: number): AckLevel => {
    let ackLevel: AckLevel = 0;
    if (messageID <= maxAckReadId) {
      ackLevel = 3;
    } else if (messageID <= maxAckReceivedId) {
      ackLevel = 2;
    } else {
      ackLevel = 1;
    }

    return ackLevel;
  };

  return (
    <SharedFiles
      roomID={roomID}
      containerWidth={containerWidth}
      isLoading={isLoading}
      isLoadingHistorical={isLoadingHistorical}
      receivedAllMessages={receivedAllMessages}
      sharedFiles={sharedFiles}
      onClosePress={onClosePress}
      onLoadMorePress={handleLoadMorePress}
      loggedInUserID={loggedInUserID.current}
      getAckLevel={getAckLevel}
      isTherapistChat={isTherapistChat}
    />
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    sharedFiles: state.chat.sharedFiles,
    isLoading: state.chat.isLoadingSharedFiles,
    isLoadingHistorical: state.chat.isLoadingHistorical,
    receivedAllMessages: state.chat.receivedAllSharedFiles,
    maxAckReadId: state.chat.acks.maxAckReadId,
    maxAckReceivedId: state.chat.acks.maxAckReceivedId,
  };
};

const mapDispatchToProps = {
  getSharedFiles: dispatchGetSharedFiles,
  getHistoricalSharedFiles: dispatchGetHistoricalSharedFiles,
  clearSharedFiles: dispatchClearSharedFiles,
  toggleStarMessage: dispatchToggleStarMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(SharedFilesContainer);
