import { addWebAnimation } from '@talkspace/react-toolkit';
import { useCallback, useState, MutableRefObject } from 'react';
import { VideoCallTrayEnum } from '../types';
import {
  TRANSITION_TIME_SECONDS,
  TRANSITION_TIMING_FUNCTION,
} from '../components/VideoCall/VideoCallTrayPopup';

interface Props {
  ref: MutableRefObject<HTMLDivElement | null>;
  type: VideoCallTrayEnum;
}

type ReturnProps = {
  videoTrayOpened: VideoCallTrayEnum | undefined;
  openTray: () => void;
  closeTray: () => void;
  hideTray: () => void;
  videoTrayVisibleHeight: number;
};
const useVideoTrayPopup = ({ type, ref }: Props): ReturnProps => {
  const [videoTrayOpened, setVideoTrayOpened] = useState<VideoCallTrayEnum>();
  const [isDismissed, setIsDismissed] = useState<boolean>(false);

  const openTray = useCallback(() => {
    if (!isDismissed) {
      setVideoTrayOpened(type);
    }
  }, [type, isDismissed]);

  const closeTray = useCallback(() => {
    if (ref.current) {
      addWebAnimation({
        ref,
        transform: 'translateY(100%)',
        timingFunction: TRANSITION_TIMING_FUNCTION,
        durationSeconds: TRANSITION_TIME_SECONDS,
      });

      // unmount when animation is done
      setTimeout(() => {
        setVideoTrayOpened(undefined);
      }, TRANSITION_TIME_SECONDS * 1000);

      setIsDismissed(true);
    }
  }, [ref]);

  const hideTray = useCallback(() => {
    setVideoTrayOpened(undefined);
  }, []);

  const videoTrayVisibleHeight =
    window.innerHeight - (ref.current?.getBoundingClientRect().top || window.innerHeight); // height of visible part of video tray during animation
  return { videoTrayOpened, openTray, closeTray, hideTray, videoTrayVisibleHeight };
};

export default useVideoTrayPopup;
