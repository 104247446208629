import { Component } from 'react';
import { connect } from 'react-redux';
import 'react-toastify/dist/ReactToastify.min.css';
import {
  isJourneyItemToDo,
  isJourneyItemWorkingDiagnosis,
} from '../../../../../utils/journeyHelper';
import JourneyItem from './JourneyItem';
import '../JourneyTab.css';

class TherapistTipsAndTools extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      hideOverThreeWarning: true,
      numSelectedTopSpecialtiesItems: 3,
    };
  }

  render() {
    const { conditions } = this.props;
    return (
      <div className="tips-card-container">
        {this.props.journeyItems.map((item, id) => {
          if (isJourneyItemToDo(item) && conditions.length) {
            return null;
          }
          if (
            isJourneyItemWorkingDiagnosis(item) &&
            conditions.every((condition) => !condition.isWorkingDiagnosis)
          ) {
            return null;
          }
          return <JourneyItem key={item.title + id} item={item} roomId={this.props.roomId} />;
        })}
      </div>
    );
  }
}

const mapStateToProps = ({ clinicalInformation }) => {
  return {
    conditions: clinicalInformation.conditions,
  };
};

const TipsAndTools = connect(mapStateToProps)(TherapistTipsAndTools);

export default TipsAndTools;
