import { FunctionComponent } from 'react';
import './spinner.css';

interface SpinnerViewProps {
  isLoading: boolean;
}

const SpinnerView: FunctionComponent<SpinnerViewProps> = () => (
  <div className="spinner-container">
    <div className="spinner">
      <div>
        <div className="bt-spinner" />
      </div>
    </div>
  </div>
);

export default SpinnerView;
