import { FunctionComponent } from 'react';
import RecorderTimerView from './RecorderTimerView';

interface RecorderTimerProps {
  hide?: boolean;
  time: number;
}

const RecorderTimer: FunctionComponent<RecorderTimerProps> = ({ hide, time }) => {
  if (hide) return null;
  return <RecorderTimerView time={time} />;
};

export default RecorderTimer;
