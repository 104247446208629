import Svg, { Path } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

const VideoCallSettingsCamera = ({ width = 28, height = 18, ...otherProps }) => {
  const { colors } = useEmotionTheme();

  const titleText = 'video camera';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 28 18"
      {...otherProps}
    >
      <Path
        d="M448.422 458a3.136 3.136 0 013.136 3.136v11.728a3.136 3.136 0 01-3.136 3.136h-12.286a3.136 3.136 0 01-3.136-3.136v-11.728a3.136 3.136 0 013.136-3.136h12.286zm12.421 2.886c.103.185.157.394.157.607v10.353a1.254 1.254 0 01-1.862 1.098l-5.63-3.115c-.4-.221-.648-.642-.648-1.098v-4.123c0-.457.248-.877.648-1.098l5.63-3.115a1.254 1.254 0 011.705.49z"
        transform="translate(-433 -458)"
        fill={colors.bermudaGray}
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </Svg>
  );
};

export default VideoCallSettingsCamera;
