import { FunctionComponent } from 'react';
import Svg, { Circle, Path } from 'svgs';
import { useEmotionTheme, EmotionStyle } from '../../core/styled';

interface RoundFilledExclamationProps {
  color?: string;
  width?: number;
  height?: number;
  style?: EmotionStyle;
}

const RoundFilledExclamation: FunctionComponent<RoundFilledExclamationProps> = ({
  width = 16,
  height = 16,
  color,
  ...otherProps
}) => {
  const { colorRoles } = useEmotionTheme();
  const titleText = 'white exclamation point inside red circle';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 16 16"
      fill="none"
      {...otherProps}
    >
      <Circle cx={8} cy={8} r={7} fill={colorRoles.icons.iconInverseDefault} />
      <Path
        d="M8 16A8 8 0 108-.001 8 8 0 008 16zM8 4c.416 0 .75.334.75.75v3.5c0 .416-.334.75-.75.75a.748.748 0 01-.75-.75v-3.5c0-.416.334-.75.75-.75zm-1 7a1 1 0 112 0 1 1 0 01-2 0z"
        fill={colorRoles.typography.textCriticalDefault}
      />
    </Svg>
  );
};

export default RoundFilledExclamation;
