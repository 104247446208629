import Svg, { Defs, G, Circle, Rect, Path } from 'svgs';

const EmptyStateIcon = ({ width = 153, height = 130, ...otherProps }) => {
  const titleText = 'Piece of paper with dollar sign';
  return (
    <Svg
      width={width}
      height={height}
      aria-label={titleText}
      title={titleText}
      viewBox="0 0 153 130"
      {...otherProps}
    >
      <Defs />
      <G
        transform="translate(-436 -179) translate(439 179)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <Circle fill="#EFF3FA" cx={75} cy={65} r={65} />
        <G fill="#C0E3E0">
          <Rect
            x={2.84217094e-13}
            y={0}
            width={5}
            height={15}
            rx={2.5}
            transform="rotate(65 -44.003 63.197) translate(5.178 .178)"
          />
          <Rect
            x={2.84217094e-13}
            y={0}
            width={5}
            height={15}
            rx={2.5}
            transform="rotate(65 -44.003 63.197) rotate(90 5 10.178)"
          />
        </G>
        <Circle stroke="#ADE953" strokeWidth={5} cx={142.5} cy={23.5} r={4.5} />
        <Circle stroke="#BCCCFF" strokeWidth={6} cx={8} cy={42} r={8} />
        <G strokeWidth={5.20379997}>
          <Path
            d="M39.892 3.602c2.156 0 4.107.874 5.52 2.287a7.783 7.783 0 012.287 5.519h0l.002 32.365a7.778 7.778 0 01-2.21 5.443 7.78 7.78 0 01-5.35 2.36h0l-28.733.002a7.781 7.781 0 01-5.52-2.286 7.781 7.781 0 01-2.286-5.52h0V11.409c0-2.156.874-4.107 2.286-5.52a7.781 7.781 0 015.52-2.286h0z"
            stroke="#BECBE0"
            fill="#FFF"
            transform="translate(42 31) rotate(-2 25.652 27.59)"
          />
          <G transform="translate(42 31) translate(15 11)" fill="#E6FDFB" stroke="#15AAAF">
            <Path
              d="M39.892 3.602c2.156 0 4.107.874 5.52 2.287a7.783 7.783 0 012.287 5.519h0l.002 32.365a7.778 7.778 0 01-2.21 5.443 7.78 7.78 0 01-5.35 2.36h0l-28.733.002a7.781 7.781 0 01-5.52-2.286 7.781 7.781 0 01-2.286-5.52h0V11.409c0-2.156.874-4.107 2.286-5.52a7.781 7.781 0 015.52-2.286h0z"
              transform="rotate(2 25.652 27.59)"
            />
          </G>
        </G>
        <G fill="#15AAAF" fillRule="nonzero">
          <Path
            d="M24.794 24.958c1.138 0 1.936-.186 2.395-.559.458-.373.687-.849.687-1.428 0-.425-.1-.792-.3-1.095a2.895 2.895 0 00-.798-.8 6.398 6.398 0 00-1.151-.617 30.946 30.946 0 00-1.342-.526 18.063 18.063 0 01-1.32-.56 4.99 4.99 0 01-1.163-.754 3.544 3.544 0 01-.831-1.074c-.215-.418-.323-.932-.323-1.542 0-1.081.315-1.968.943-2.662.628-.693 1.541-1.13 2.738-1.313V10.32a.92.92 0 111.84 0v1.639h0a9.527 9.527 0 012.29.376.937.937 0 01.656 1.117l-.009.038a.838.838 0 01-1.065.607c-.173-.054-.331-.1-.475-.14-.532-.144-1.197-.217-1.996-.217-.887 0-1.57.175-2.05.526-.481.35-.721.845-.721 1.484 0 .35.07.648.21.892.14.243.344.46.61.65.266.192.58.362.943.515.362.152.765.312 1.208.48.576.228 1.123.464 1.64.708.517.243.972.536 1.364.88.391.342.706.75.942 1.222.237.472.355 1.043.355 1.713 0 1.082-.318 1.953-.953 2.616-.635.662-1.619 1.077-2.948 1.244V28.7a.92.92 0 11-1.84 0v-1.96h0c-1.035-.03-1.878-.148-2.528-.354a10.077 10.077 0 01-.653-.233.937.937 0 01-.548-1.16l.015-.046a.84.84 0 011.097-.528c.293.111.552.2.776.265.62.184 1.389.275 2.305.275z"
            stroke="#15AAAF"
            transform="rotate(2 -1496.079 1952.323)"
          />
          <G transform="rotate(2 -1496.079 1952.323) matrix(1 0 0 -1 .816 27.978)">
            <Rect
              x={1.89530151}
              y={-0.00182751894}
              width={13.0923633}
              height={4.705386}
              rx={2.352693}
            />
            <Rect
              x={1.89530151}
              y={10.9981725}
              width={13.0923633}
              height={4.705386}
              rx={2.352693}
            />
            <Rect
              x={0.860402013}
              y={21.9975633}
              width={27.1728294}
              height={4.705386}
              rx={2.352693}
            />
          </G>
        </G>
      </G>
    </Svg>
  );
};

export default EmptyStateIcon;
