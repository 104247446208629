import { Standard, styled, View } from '@talkspace/react-toolkit';
import UserHeader from './UserHeader';
import UserFooter from './UserFooter';

const Container = styled(View)(({ theme: { colors } }) => {
  return {
    width: 349,
    padding: '20px 17px',
    borderRadius: 8,
    border: `1px solid #DDE3F1`,
    marginBottom: 15,
  };
});

const Content = styled(Standard)(({ theme: { colors } }) => {
  return {
    fontSize: 16,
    color: colors.purple600,
    fontWeight: 400,
    marginTop: 14,
    marginBottom: 14,
  };
});

interface UserActionCardProps {
  preferredName?: string;
  buttons?: Array<any>;
  content: string | JSX.Element;
  isUpdating: boolean;
  isError: boolean;
  showSpinner?: boolean;
  spinnerMessage?: string;
  type?: 'parentConsent' | 'informedConsent';
  header?: () => void;
}

const UserActionCard = ({
  preferredName,
  buttons,
  content,
  isUpdating = false,
  isError = false,
  showSpinner = false,
  spinnerMessage = '',
  type = 'informedConsent',
  header,
}: UserActionCardProps) => (
  <Container>
    {header ? (
      header()
    ) : (
      <UserHeader isParentConsent={type === 'parentConsent'} preferredName={preferredName} />
    )}
    <Content>{content}</Content>
    <View row style={{ justifyContent: 'flex-end' }}>
      <UserFooter
        isUpdating={isUpdating}
        isError={isError}
        showSpinner={showSpinner}
        spinnerMessage={spinnerMessage}
        buttons={buttons}
      />
    </View>
  </Container>
);

export default UserActionCard;
