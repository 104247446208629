import { View, useEmotionTheme } from '../../..';
import { SurfaceColorRole } from '../../types';

interface Props {
  children?: React.ReactElement;
  size?: 64 | 136;
  colorRole?: SurfaceColorRole;
}
const CircleUnderlay = ({ children, size = 64, colorRole }: Props) => {
  const { colorRoles } = useEmotionTheme();
  return (
    <View
      align="center"
      justify="center"
      style={{
        borderRadius: '50%',
        backgroundColor: colorRole
          ? colorRoles.surfaces[colorRole]
          : colorRoles.surfaces.decorativeTealExtralite,
        width: size,
        height: size,
      }}
    >
      {children}
    </View>
  );
};

export default CircleUnderlay;
