import { withVersioning } from '../../utils';
import { VersionMap } from '../../types';
import Checkbox, { CheckboxProps } from './Checkbox';
import CheckboxV1 from './CheckboxV1';

const versionMap: VersionMap<React.ComponentProps<typeof Checkbox>> = {
  '1.0.0': CheckboxV1,
  DefaultComponent: Checkbox,
};

export type { CheckboxProps };

export default withVersioning(versionMap);
