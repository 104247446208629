import { FunctionComponent } from 'react';
import { connect } from 'react-redux';

import { handleCrossPlatformPDFDownload, PDFViewer } from 'ts-pdf';
import { RouteComponentProps } from '@/core/routerLib/routerLib';
import { useCloseModal, useModalFocusTrap } from '@/utils/ModalsContextProvider';
import { EMessage } from '../entities/EMessage';
import { StarredMessage } from '../entities/StarredMessage';
import { AppState } from '../redux/chatStore';
import { dispatchToggleStarMessage } from '../redux/actions/chatActions';

interface OwnProps {
  messages: EMessage[];
  sharedFiles: EMessage[];
  starredMessages: StarredMessage[];
  toggleStarMessage: (messageID: number, isStarred: boolean) => void;
}

interface LocationState {
  messageID: number;
  url: string;
  fileName: string;
  senderName: string;
  createdAt: Date;
}

type Props = OwnProps & RouteComponentProps<{}, {}, LocationState>;

const PDFViewerContainer: FunctionComponent<Props> = ({
  location,
  messages,
  sharedFiles,
  starredMessages,
  toggleStarMessage,
}) => {
  const {
    state: { messageID, url, fileName, senderName, createdAt },
  } = location;

  const closeModal = useCloseModal();
  const { pauseModalFocusTrap, resumeModalFocusTrap } = useModalFocusTrap();

  const currentMessage = messages.find((it) => it.id === messageID);
  const currentSharedFile = sharedFiles.find((it) => it.id === messageID);
  const currentStarredMessage = starredMessages.find((it) => it.messageID === messageID);

  const isStarred =
    (currentMessage && currentMessage.isStarred) ||
    (currentSharedFile && currentSharedFile.isStarred) ||
    (currentStarredMessage && currentStarredMessage.isStarred) ||
    false;

  const handleClosePress = () => {
    closeModal();
  };

  const handleStarPress = () => {
    toggleStarMessage(messageID, isStarred);
  };

  const handleDownloadPress = () => {
    handleCrossPlatformPDFDownload({ url, fileName, pauseModalFocusTrap, resumeModalFocusTrap });
  };

  return (
    <PDFViewer
      url={url}
      fileName={fileName}
      senderName={senderName}
      createdAt={createdAt}
      isStarred={isStarred}
      onClosePress={handleClosePress}
      onDownloadPress={handleDownloadPress}
      onStarPress={handleStarPress}
    />
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    messages: state.chat.messages,
    sharedFiles: state.chat.sharedFiles,
    starredMessages: state.chat.starredMessages,
  };
};

const mapDispatchToProps = {
  toggleStarMessage: dispatchToggleStarMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(PDFViewerContainer);
