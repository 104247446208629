import { FunctionComponent } from 'react';
import styled, { EmotionTheme } from '../../core/styled';
import { webOnlyStyle } from '../../core/styleHelpers';
import Text, { TextProps } from '../Text';
import type { VariantFunction } from './types';

type Variant =
  | 'tinyAllCaps'
  | 'tinyWhite'
  | 'tinyBoldWhite'
  | 'tinyBoldGrey'
  | 'tinyBoldGreen'
  | 'tinyRed'
  | 'tinyBlack'
  | 'tinyDarkGray'
  | 'tinyMediumGreen'
  | 'tinyOptionDescription';

interface TinyProps extends TextProps {
  variant?: Variant;
}

const tinyDefaultStyle = (colors: EmotionTheme['colors']) => {
  return {
    fontSize: 13,
    lineHeight: '18px',
    letterSpacing: 0.2,
    fontWeight: 400,
    color: colors.slateGrey,
  };
};

// no all caps style on mobile
const variants: Record<string, VariantFunction> = {
  default: ({ theme: { colors } }) => {
    return {
      ...tinyDefaultStyle(colors),
    };
  },
  allCaps: ({ theme: { colors } }) => {
    return {
      ...tinyDefaultStyle(colors),
      ...webOnlyStyle({ textTransform: 'uppercase' }),
      fontWeight: 500,
      letterSpacing: 0.6,
    };
  },
  white: ({ theme: { colors } }) => {
    return {
      ...tinyDefaultStyle(colors),
      color: colors.white,
    };
  },
  boldWhite: ({ theme: { colors } }) => {
    return {
      ...tinyDefaultStyle(colors),
      color: colors.white,
      fontWeight: 700,
    };
  },
  tinyBoldGrey: ({ theme: { colors } }) => {
    return {
      ...tinyDefaultStyle(colors),
      fontWeight: 700,
      color: colors.grey,
    };
  },
  tinyBoldGreen: ({ theme: { colors } }) => {
    return {
      ...tinyDefaultStyle(colors),
      fontWeight: 700,
      color: colors.green,
    };
  },
  tinyRed: ({ theme: { colors } }) => {
    return {
      ...tinyDefaultStyle(colors),
      color: colors.permaDebianRed,
    };
  },
  tinyBlack: ({ theme: { colors } }) => {
    return {
      ...tinyDefaultStyle(colors),
      color: colors.trueBlack,
    };
  },
  tinyDarkGray: ({ theme: { colors, colorRoles } }) => {
    return {
      ...tinyDefaultStyle(colors),
      color: colorRoles.typography.textSubtle,
    };
  },
  tinyMediumGreen: ({ theme: { colors } }) => {
    return {
      ...tinyDefaultStyle(colors),
      color: colors.green,
      fontWeight: 500,
    };
  },
  tinyOptionDescription: ({ theme: { colors } }) => {
    return {
      ...tinyDefaultStyle(colors),
      color: 'inherit',
      fontWeight: 400,
      lineHeight: '17px',
    };
  },
};

const TinyDefault = styled(Text)(variants.default);
const AllCaps = styled(Text)(variants.allCaps);
const TinyWhite = styled(Text)(variants.white);
const TinyBoldWhite = styled(Text)(variants.boldWhite);
const TinyBoldGrey = styled(Text)(variants.tinyBoldGrey);
const TinyBoldGreen = styled(Text)(variants.tinyBoldGreen);
const TinyRed = styled(Text)(variants.tinyRed);
const TinyBlack = styled(Text)(variants.tinyBlack);
const TinyDarkGray = styled(Text)(variants.tinyDarkGray);
const TinyMediumGreen = styled(Text)(variants.tinyMediumGreen);
const TinyOptionDescription = styled(Text)(variants.tinyOptionDescription);

const selectStyle = (variant: Variant | undefined, props: Omit<TinyProps, 'variant'>) => {
  switch (variant) {
    case 'tinyAllCaps':
      return <AllCaps {...props} />;
    case 'tinyWhite':
      return <TinyWhite {...props} />;
    case 'tinyBoldWhite':
      return <TinyBoldWhite {...props} />;
    case 'tinyBoldGrey':
      return <TinyBoldGrey {...props} />;
    case 'tinyBoldGreen':
      return <TinyBoldGreen {...props} />;
    case 'tinyRed':
      return <TinyRed {...props} />;
    case 'tinyBlack':
      return <TinyBlack {...props} />;
    case 'tinyDarkGray':
      return <TinyDarkGray {...props} />;
    case 'tinyMediumGreen':
      return <TinyMediumGreen {...props} />;
    case 'tinyOptionDescription':
      return <TinyOptionDescription {...props} />;
    default:
      return <TinyDefault {...props} />;
  }
};

const Tiny: FunctionComponent<TinyProps> = ({ variant, ...props }) => selectStyle(variant, props);

export default Tiny;
