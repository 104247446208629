import { useMutation, useQueryClient } from 'react-query';
import apiHelper from '@/utils/api';
import apiWrapper from '../../utils/apiWrapper';
import { notesListQueryKey, progressNoteHelperDataQueryKey } from './queryKeys';

interface CompleteEAPSessionMutateVariables {
  roomID: string;
  videoCallID: string;
}

const completeEAPSession = async (params: CompleteEAPSessionMutateVariables): Promise<void> => {
  const { roomID, videoCallID } = params;
  await apiWrapper.post(
    `${
      apiHelper().apiEndpoint
    }/v2/rooms/${roomID}/video-calls/${videoCallID}/complete-eap-session-report`,
    {}
  );
};

const useMutationCompleteEAPSession = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, CompleteEAPSessionMutateVariables>(completeEAPSession, {
    onSuccess: (_, { roomID }) => {
      queryClient.invalidateQueries(notesListQueryKey(roomID));
      queryClient.invalidateQueries(progressNoteHelperDataQueryKey(roomID));
    },
  });
};

export default useMutationCompleteEAPSession;
