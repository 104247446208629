import { useEffect, useState } from 'react';

import moment, { Moment } from 'moment';

import { TherapistTimeslot } from 'ts-frontend/types';

import {
  useInRoomSchedulingState,
  useInRoomSchedulingActions,
} from '../../inRoomScheduling/hooks/inRoomSchedulingContext';
import useTimeslots from '../../inRoomScheduling/hooks/useTimeslots';

type TherapistRecurringAvailabilityData = {
  availableSlotInOneWeek: TherapistTimeslot | undefined;
  availableSlotInTwoWeeks: TherapistTimeslot | undefined;
  isLoading: boolean;
  isFetched: boolean;
};
interface Props {
  callStartMoment: Moment;
}

const useTherapistRecurringAvailability = ({
  callStartMoment,
}: Props): TherapistRecurringAvailabilityData => {
  const inRoomSchedulingState = useInRoomSchedulingState();

  const { isInitialTimeSlotsLoading, isInitialTimeSlotsLoaded, selectedCreditOption } =
    inRoomSchedulingState;

  const { dispatchGetInitialTimeSlots } = useInRoomSchedulingActions();

  const callStartTime = callStartMoment.toISOString();

  const [availableSlotInOneWeek, setAvailableSlotInOneWeek] = useState<
    TherapistTimeslot | undefined
  >(undefined);
  const [availableSlotInTwoWeeks, setAvailableSlotInTwoWeeks] = useState<
    TherapistTimeslot | undefined
  >(undefined);

  useEffect(() => {
    if (selectedCreditOption) {
      dispatchGetInitialTimeSlots(true);
    }
  }, [dispatchGetInitialTimeSlots, selectedCreditOption]);

  const { getAllTimeslotsByDate } = useTimeslots({
    allowRecurringSessions: true,
    schedulingState: inRoomSchedulingState,
  });

  useEffect(() => {
    if (!isInitialTimeSlotsLoading && isInitialTimeSlotsLoaded) {
      const slotStartMomentInOneWeek = moment(callStartTime).add(1, 'week');
      const slotStartMomentInTwoWeeks = moment(callStartTime).add(2, 'weeks');
      const availableTimeslots = [
        ...(getAllTimeslotsByDate(slotStartMomentInOneWeek) || []),
        ...(getAllTimeslotsByDate(slotStartMomentInTwoWeeks) || []),
      ];
      const oneWeek = availableTimeslots.find(
        (slot) => slot.start === slotStartMomentInOneWeek.format()
      );
      const twoWeeks = availableTimeslots.find(
        (slot) => slot.start === slotStartMomentInTwoWeeks.format()
      );
      setAvailableSlotInOneWeek(oneWeek);
      setAvailableSlotInTwoWeeks(twoWeeks);
    }
  }, [isInitialTimeSlotsLoading, isInitialTimeSlotsLoaded, getAllTimeslotsByDate, callStartTime]);

  return {
    isLoading: isInitialTimeSlotsLoading,
    isFetched: isInitialTimeSlotsLoaded,
    availableSlotInOneWeek,
    availableSlotInTwoWeeks,
  };
};

export default useTherapistRecurringAvailability;
