import Svg, { Circle, Path } from 'svgs';

interface Props {
  width?: string | number;
  height?: string | number;
  color?: string;
}

const BonusEarnedIcon = ({ width = 24, height = 24, color = '#3C8703' }: Props) => (
  <Svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <Circle cx="12" cy="12" r="12" fill={color} />
    <Path
      d="M18.2876 7.5218C18.2855 7.51767 18.2827 7.51382 18.2793 7.51042C17.8955 7.12663 17.2723 7.12663 16.8885 7.51041C16.8885 7.51041 16.8885 7.51041 16.8885 7.51042L10.0824 14.3136L7.02639 11.2604C6.6426 10.8766 6.01938 10.8766 5.6356 11.2604C5.25182 11.6442 5.25182 12.2674 5.6356 12.6512L9.3856 16.4012C9.76938 16.785 10.3926 16.785 10.7764 16.4012L18.2764 8.9012C18.6564 8.52119 18.6601 7.90643 18.2876 7.5218Z"
      fill="white"
      stroke="white"
      strokeWidth="0.09"
      strokeLinejoin="round"
    />
  </Svg>
);

export default BonusEarnedIcon;
