import Svg, { Path, G } from 'svgs';
import { useEmotionTheme } from '../../core/styled';

const DownloadIcon = ({
  width = 23,
  height = 22,
  color,
  ...otherProps
}: {
  width?: number;
  height?: number;
  color?: string;
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'download';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox={`0 0 ${width} ${height}`}
      {...otherProps}
    >
      <G fill={color || colors.green} fillRule="evenodd" stroke="none" strokeWidth="1">
        <Path
          d="M23 13v4a5 5 0 01-5 5H5a5 5 0 01-5-5v-4a1 1 0 012 0v4a3 3 0 003 3h13a3 3 0 003-3v-4a1 1 0 012 0z"
          transform="translate(-296 -87) translate(296 87)"
        />
        <Path
          d="M9.042 6.462l-3.535-3.4-3.536 3.4a1.029 1.029 0 01-1.414 0 .935.935 0 010-1.36L5.507.343l4.95 4.759c.39.375.39.984 0 1.36a1.029 1.029 0 01-1.415 0z"
          transform="translate(-296 -87) translate(296 87) matrix(1 0 0 -1 6 15)"
        />
        <Path
          d="M5.5 1a1 1 0 011 1v12a1 1 0 01-2 0V2a1 1 0 011-1z"
          transform="translate(-296 -87) translate(296 87) matrix(1 0 0 -1 6 15)"
        />
      </G>
    </Svg>
  );
};

export default DownloadIcon;
