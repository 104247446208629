import { useReducer } from 'react';

const requestActionPayload = {
  isLoading: true,
  isError: false,
};

const receiveActionPayload = {
  isLoading: false,
  isError: false,
};

const errorActionPayload = {
  isLoading: false,
  isError: true,
};

export interface PayloadBooleans {
  isLoading: boolean;
  isError: boolean;
}

export interface ActionType {
  type: string;
  payload?: object;
}

export default function useSimpleReducer<State extends PayloadBooleans, Actions extends ActionType>(
  initialState: State
) {
  function reducer(currentState: State, action: Actions) {
    return { ...currentState, ...action.payload };
  }
  return useReducer(reducer, initialState);
}

export { requestActionPayload, receiveActionPayload, errorActionPayload };
