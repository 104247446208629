import { ChangeEvent, FunctionComponent, useState } from 'react';
import {
  Small,
  AccordionContainer,
  Select,
  TouchableView,
  Checkbox,
  TextAreaRounded,
  Standard,
  View,
  TextInput,
  styled,
  OptionType,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import xImage from 'components/Icons/x.svg';
import useQueryProgressNote from 'hooks/notes/useQueryProgressNote';
import useQueryReferralConditions from 'hooks/useQueryReferralConditions';
import useQueryRoomDetails from 'ts-frontend/hooks/useQueryRoomDetails';
import { sortOptionsAlphabetically } from 'ts-frontend/helpers/optionsHelpers';
import { getOptionsByField } from 'utils/tsConfigsValues';
import { useParams } from '@/core/routerLib';
import {
  useProgressNoteFormActions,
  useProgressNoteFormState,
} from '../context/PsychProgressNoteFormContext';
import { ProgressNoteFormState } from '../context/types';
import {
  NextFollowup,
  ProgressNotePsychiatryPlanMedicationCreationAttributes,
  PsychotropicMedication,
} from '../../types';
import {
  generateReferralOptions,
  getTextAreaContainerBorderStyle,
  updateObjectInArray,
  getTextAreaStyle,
  getSelectStyle,
  OTHER_REFERRAL_ID,
  moveItemToEndOfArrayByIndex,
} from '../../utils';
import StatementCertification from '../../StatementCertification';
import ConfirmButton from './ConfirmButton';
import IndentedView from '../../IndentedView';

const OTHER_MEDICATION_ID = '40';
const NONE_PRESCRIBED = '42';
const OTHER_MEDICATION_INDEX = 39;

const selectStyle = {
  control: (provided) => {
    return { ...provided, marginBottom: 10 };
  },
};

const psychMedicationOptions = getOptionsByField('psychotropicMedications')
  .map((it: OptionType<number>) => {
    return {
      value: it.value.toString(),
      label: it.label,
    };
  })
  .sort(sortOptionsAlphabetically) as Array<OptionType<PsychotropicMedication>>;

moveItemToEndOfArrayByIndex(psychMedicationOptions, OTHER_MEDICATION_INDEX);
const nextFollowupOptions = getOptionsByField('nextFollowup') as Array<OptionType<NextFollowup>>;

const labsRequestedOptions: Array<OptionType<boolean>> = [
  { value: false, label: 'No' },
  { value: true, label: 'Yes' },
];

const XIcon = styled(Standard)({
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  width: 9,
  height: 9,
  backgroundImage: `url(${xImage})`,
});

const RemoveMedicationButton = styled(TouchableView)({
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: 16,
});

const DetailsWrapper = styled(View)({
  marginLeft: 26,
  marginBottom: 32,
});

const DetailsHeadline = styled(Small)({
  marginTop: 6,
  marginBottom: 8,
});

const InputSection = styled(View)({
  marginBottom: 15,
  marginTop: 10,
});

const PsychPlanSection: FunctionComponent = () => {
  const {
    formSections,
    psychiatryPlan: globalPsychiatryPlan,
    psychiatryPlanMedications: globalPsychiatryPlanMedications,
    referralID,
    otherReferral,
    statementCertified,
    formMode,
  } = useProgressNoteFormState();
  const { setFormState } = useProgressNoteFormActions();
  const [psychiatryPlan, setPsychiatryPlan] =
    useState<ProgressNoteFormState['psychiatryPlan']>(globalPsychiatryPlan);
  const [psychiatryPlanMedications, setPsychiatryPlanMedications] = useState<
    ProgressNoteFormState['psychiatryPlanMedications']
  >(globalPsychiatryPlanMedications);

  const { roomID, noteID } = useParams<{ roomID: string; noteID: string }>();

  const { data: referralConditionsData } = useQueryReferralConditions();
  const { data: { clientUserID } = {} } = useQueryRoomDetails(roomID);
  const { data: progressNoteData } = useQueryProgressNote(clientUserID, noteID);

  const [labsRequestedDetailsError, setLabsRequestedDetailsError] = useState(false);
  const [otherNextFollowupError, setOtherNextFollowup] = useState(false);
  const [statementCertifiedError, setStatementCertifiedError] = useState(false);
  const [risksAndBenefitsConsentError, setRisksAndBenefitsConsentError] = useState(false);
  const [medicationsErrors, setMedicationsErrors] = useState<Array<boolean>>([]);
  const [dosagesErrors, setDosagesErrors] = useState<Array<boolean>>([]);
  const [otherReferralError, setOtherReferralError] = useState(false);
  const [otherMedicationsErrors, setOtherMedicationsErrors] = useState<boolean[]>([]);

  const { colors } = useEmotionTheme();

  const markSectionAsIncomplete = () => {
    if (formSections.psychPlan.completed) {
      setFormState({
        formSections: {
          ...formSections,
          psychPlan: {
            open: formSections.psychPlan.open,
            changed: true,
            completed: false,
          },
        },
      });
    }
  };

  const handleAccordionPress = (value: boolean) => {
    setFormState({
      formSections: {
        ...formSections,
        psychPlan: {
          open: value,
          changed: formSections.psychPlan.changed,
          completed: formSections.psychPlan.completed,
        },
      },
    });
  };

  const handleMedicationChange = (
    index: number,
    option: OptionType<PsychotropicMedication> | null
  ) => {
    markSectionAsIncomplete();

    if (medicationsErrors[index]) {
      const copy = [...medicationsErrors];
      copy[index] = false;
      setMedicationsErrors(copy);
    }

    const updatedMedications =
      updateObjectInArray<ProgressNotePsychiatryPlanMedicationCreationAttributes>(
        psychiatryPlanMedications,
        index,
        {
          medication: option ? option.value : null,
          dosage:
            option?.value === NONE_PRESCRIBED ? null : psychiatryPlanMedications[index].dosage,
        }
      );
    setPsychiatryPlanMedications(updatedMedications);
  };

  const handleOtherMedicationChange = (index: number, value: string) => {
    markSectionAsIncomplete();
    const updatedMedications =
      updateObjectInArray<ProgressNotePsychiatryPlanMedicationCreationAttributes>(
        psychiatryPlanMedications,
        index,
        {
          otherMedication: value,
        }
      );
    const otherMedicationsErrorsCopy = [...otherMedicationsErrors];
    otherMedicationsErrorsCopy[index] = false;
    setOtherMedicationsErrors(otherMedicationsErrorsCopy);
    setPsychiatryPlanMedications(updatedMedications);
  };

  const handleDosageChange = (index: number, event: ChangeEvent<HTMLInputElement>) => {
    markSectionAsIncomplete();

    if (dosagesErrors[index]) {
      const copy = [...dosagesErrors];
      copy[index] = false;
      setDosagesErrors(copy);
    }

    const affectedValue = psychiatryPlanMedications[index];
    const updatedValue = {
      ...affectedValue,
      dosage: event.target.value,
    };
    const copy = [...psychiatryPlanMedications];
    copy.splice(index, 1, updatedValue);
    setPsychiatryPlanMedications(copy);
  };

  const handleAddAnotherMedicationPress = () => {
    markSectionAsIncomplete();
    setPsychiatryPlanMedications([
      ...psychiatryPlanMedications,
      { medication: null, dosage: null, otherMedication: null },
    ]);
  };

  const handleRemoveMedicationPress = (index: number) => {
    markSectionAsIncomplete();
    setPsychiatryPlanMedications(psychiatryPlanMedications.filter((_, i) => i !== index));
    setMedicationsErrors(medicationsErrors.filter((_, i) => i !== index));
    setDosagesErrors(dosagesErrors.filter((_, i) => i !== index));
    setOtherMedicationsErrors(otherMedicationsErrors.filter((_, i) => i !== index));
  };

  const handleLabsRequestedChange = (option: OptionType<boolean> | null) => {
    markSectionAsIncomplete();
    setLabsRequestedDetailsError(false);
    setPsychiatryPlan({
      ...psychiatryPlan!,
      labsRequested: option ? option.value : false,
    });
  };

  const handleLabsRequestedDetailsChange = (value: string) => {
    markSectionAsIncomplete();
    setLabsRequestedDetailsError(false);
    setPsychiatryPlan({
      ...psychiatryPlan!,
      labsRequestedDetails: value,
    });
  };

  const handleNextFollowupChange = (option: OptionType<NextFollowup> | null) => {
    markSectionAsIncomplete();
    setPsychiatryPlan({
      ...psychiatryPlan!,
      nextFollowup: option ? option.value : '1_month',
    });
  };

  const handleOtherNextFollowupChange = (value: string) => {
    markSectionAsIncomplete();
    setPsychiatryPlan({
      ...psychiatryPlan!,
      otherNextFollowup: value,
    });
  };

  const handleAdditionalInfoChange = (value: string) => {
    markSectionAsIncomplete();
    setPsychiatryPlan({
      ...psychiatryPlan!,
      additionalInfo: value,
    });
  };

  const validateOtherMedications = (
    medications: ProgressNotePsychiatryPlanMedicationCreationAttributes[]
  ) => {
    let isValid = true;
    const otherMedicsErrors: boolean[] = [];
    for (let i = 0; i < medications.length; i += 1) {
      otherMedicsErrors[i] =
        medications[i].medication === OTHER_MEDICATION_ID && !medications[i]?.otherMedication;
      if (otherMedicsErrors[i]) isValid = false;
    }
    setOtherMedicationsErrors(otherMedicsErrors);
    return isValid;
  };

  const handleReferralChange = (option: OptionType<number> | null) => {
    markSectionAsIncomplete();
    setFormState({ referralID: option ? option.value : null });
  };

  const handleOtherReferralChange = (value: string) => {
    markSectionAsIncomplete();
    setOtherReferralError(false);
    setFormState({ otherReferral: value });
  };

  const handleRisksAndBenefitsConsentChange = (isChecked: boolean) => {
    markSectionAsIncomplete();
    setRisksAndBenefitsConsentError(false);
    setPsychiatryPlan({
      ...psychiatryPlan!,
      risksAndBenefitsConsent: isChecked,
    });
  };

  const handleRisksAndBenefitsConsentDetailsChange = (value: string) => {
    markSectionAsIncomplete();
    setPsychiatryPlan({
      ...psychiatryPlan!,
      risksAndBenefitsConsentDetails: value,
    });
  };

  const handleStatementCertifiedChange = (isChecked: boolean) => {
    markSectionAsIncomplete();
    setStatementCertifiedError(false);
    setFormState({ statementCertified: isChecked });
  };

  const handleConfirmPress = () => {
    let isError = false;

    const newMedicationsErrors = psychiatryPlanMedications.map((it) => !it.medication);
    const newDosagesErrors = psychiatryPlanMedications.map(
      (it) => !it.dosage && it.medication !== NONE_PRESCRIBED
    );

    if (newMedicationsErrors.some((it) => it) || newDosagesErrors.some((it) => it)) {
      setMedicationsErrors(newMedicationsErrors);
      setDosagesErrors(newDosagesErrors);
      isError = true;
    }

    if (psychiatryPlan?.labsRequested && !psychiatryPlan.labsRequestedDetails) {
      setLabsRequestedDetailsError(true);
      isError = true;
    }

    if (psychiatryPlan?.nextFollowup === 'other' && !psychiatryPlan.otherNextFollowup) {
      setOtherNextFollowup(true);
      isError = true;
    }

    if (referralID === OTHER_REFERRAL_ID && !otherReferral) {
      setOtherReferralError(true);
      isError = true;
    }

    if (!validateOtherMedications(psychiatryPlanMedications)) {
      isError = true;
    }

    if (!psychiatryPlan?.risksAndBenefitsConsent) {
      setRisksAndBenefitsConsentError(true);
      isError = true;
    }

    if (!statementCertified) {
      setStatementCertifiedError(true);
      isError = true;
    }

    if (isError) {
      setFormState({ showGlobalError: true });
      return;
    }

    setFormState({
      psychiatryPlan,
      psychiatryPlanMedications,
      formSections: {
        ...formSections,
        psychPlan: {
          open: false,
          changed: true,
          completed: true,
        },
      },
    });
  };

  const referralOptions = referralConditionsData
    ? generateReferralOptions(referralConditionsData)
    : [];

  const textAreaStyle = getTextAreaStyle(formMode === 'view');

  return (
    <AccordionContainer
      title="Plan"
      open={formSections.psychPlan.open}
      onPress={handleAccordionPress}
      showCheckMark={formMode !== 'view' && formSections.psychPlan.completed}
      dataQa="psychPlanAccordion"
    >
      <View>
        {psychiatryPlanMedications.map((medication, index) => (
          <View key={medication.medication + index.toString()}>
            <Select
              value={psychMedicationOptions.find((it) => it.value === medication.medication)}
              onChange={((value) => handleMedicationChange(index, value)) as any}
              placeholder="Medication"
              options={psychMedicationOptions}
              isDisabled={formMode === 'view'}
              styles={{
                ...selectStyle,
                ...getSelectStyle(colors, medicationsErrors[index]),
              }}
              dataQa="medicationDropdown"
            />
            {medication.medication === OTHER_MEDICATION_ID && (
              <InputSection>
                <IndentedView>
                  <TextAreaRounded
                    autoSize
                    style={{
                      ...getTextAreaContainerBorderStyle(colors, otherMedicationsErrors[index]),
                      marginBottom: 10,
                    }}
                    placeholder="Medication"
                    textAreaStyle={getTextAreaStyle(formMode === 'view')}
                    value={medication.otherMedication || ''}
                    onChangeText={(value) => handleOtherMedicationChange(index, value)}
                    disabled={formMode === 'view'}
                    hasLimit
                    dataQa="medicationTextArea"
                  />
                </IndentedView>
              </InputSection>
            )}
            <IndentedView style={{ marginBottom: 10 }}>
              <TextInput
                value={medication.dosage}
                onChange={(e) => handleDosageChange(index, e)}
                placeholder="Dosage"
                disabled={formMode === 'view' || medication.medication === NONE_PRESCRIBED}
                style={{
                  marginBottom: 10,
                  ...(dosagesErrors[index] ? { borderColor: colors.permaDebianRed } : {}),
                }}
                placeHolderStyle={{
                  ...(dosagesErrors[index] ? { color: colors.permaDebianRed } : {}),
                }}
                dataQa="dosageInput"
              />
            </IndentedView>
            {formMode !== 'view' && index !== 0 && psychiatryPlanMedications.length > 1 && (
              <RemoveMedicationButton
                onPress={() => handleRemoveMedicationPress(index)}
                dataQa="removeMedicationButton"
              >
                <XIcon style={{ marginRight: 5 }} />
                <Standard variant="standardBoldGrey">Remove medication</Standard>
              </RemoveMedicationButton>
            )}
          </View>
        ))}
      </View>
      {formMode !== 'view' && (
        <TouchableView
          style={{ marginBottom: 32 }}
          onPress={handleAddAnotherMedicationPress}
          dataQa="addAnotherMedicationButton"
        >
          <Standard variant="standardBoldGreen">+ Add another medication</Standard>
        </TouchableView>
      )}
      <Select
        value={
          labsRequestedOptions.find(
            (option) => option.value === psychiatryPlan!.labsRequested
          ) as any
        }
        onChange={handleLabsRequestedChange as any}
        placeholder="Labs requested"
        options={labsRequestedOptions as any}
        styles={selectStyle}
        isDisabled={formMode === 'view'}
        dataQa="labsRequestedDropdown"
      />
      {psychiatryPlan!.labsRequested && (
        <IndentedView style={{ marginBottom: 20 }}>
          <TextAreaRounded
            autoSize
            value={psychiatryPlan!.labsRequestedDetails || ''}
            onChangeText={handleLabsRequestedDetailsChange}
            placeholder="Provide details about the requested labs"
            textAreaStyle={textAreaStyle}
            style={getTextAreaContainerBorderStyle(colors, labsRequestedDetailsError)}
            disabled={formMode === 'view'}
            hasLimit
            dataQa="requestedLabsTextArea"
          />
        </IndentedView>
      )}
      <Select
        value={nextFollowupOptions.find((option) => option.value === psychiatryPlan!.nextFollowup)}
        onChange={handleNextFollowupChange as any}
        placeholder="Next follow-up"
        options={nextFollowupOptions}
        styles={selectStyle}
        isDisabled={formMode === 'view'}
        dataQa="nextFollowupDropdown"
      />
      {psychiatryPlan!.nextFollowup === 'other' && (
        <IndentedView style={{ marginBottom: 20 }}>
          <TextAreaRounded
            autoSize
            value={psychiatryPlan!.otherNextFollowup || ''}
            onChangeText={handleOtherNextFollowupChange}
            placeholder="When will the next follow-up be?"
            textAreaStyle={textAreaStyle}
            style={getTextAreaContainerBorderStyle(colors, otherNextFollowupError)}
            disabled={formMode === 'view'}
            hasLimit
            data="nextFollowupTextArea"
          />
        </IndentedView>
      )}
      <Small style={{ marginTop: 6, marginBottom: 8 }}>Additional information (optional)</Small>
      <TextAreaRounded
        autoSize
        value={psychiatryPlan!.additionalInfo || ''}
        onChangeText={handleAdditionalInfoChange}
        placeholder="Additional recommendations or future considerations for treatment"
        textAreaStyle={textAreaStyle}
        disabled={formMode === 'view'}
        hasLimit
        dataQa="additionalRecommendationsTextArea"
      />
      <Select
        value={referralOptions.find((it) => it.value === referralID) as any}
        onChange={handleReferralChange as any}
        options={referralOptions as any}
        styles={selectStyle}
        placeholder="Referral"
        isDisabled={formMode === 'view'}
        dataQa="referralDropdown"
      />
      {referralID === OTHER_REFERRAL_ID && (
        <IndentedView style={{ marginBottom: 30 }}>
          <TextAreaRounded
            autoSize
            disabled={formMode === 'view'}
            style={{
              ...getTextAreaContainerBorderStyle(colors, otherReferralError),
              marginBottom: 10,
            }}
            placeholder="Provide information about the referral"
            textAreaStyle={getTextAreaStyle(formMode === 'view')}
            value={otherReferral || ''}
            onChangeText={handleOtherReferralChange}
            hasLimit
            dataQa="referralTextArea"
          />
        </IndentedView>
      )}
      <Checkbox
        alignCenter={false}
        containerStyle={{ alignSelf: 'flex-start' }}
        isLabelOnRight
        isChecked={psychiatryPlan!.risksAndBenefitsConsent}
        setIsChecked={handleRisksAndBenefitsConsentChange}
        label="I have discussed the risks/benefits of the medication(s) being prescribed with the patient and the patient consents to taking the medication(s)."
        labelStyle={{ fontWeight: 400 }}
        shouldDisplayError={risksAndBenefitsConsentError}
        isDisabled={formMode === 'view'}
        dataQa="risksAndBenefitsConsentCheckbox"
      />
      <DetailsWrapper>
        <DetailsHeadline>Details (optional)</DetailsHeadline>
        <TextAreaRounded
          autoSize
          value={psychiatryPlan!.risksAndBenefitsConsentDetails || ''}
          onChangeText={handleRisksAndBenefitsConsentDetailsChange}
          placeholder="Additional information about the risks/benefits discussed"
          textAreaStyle={textAreaStyle}
          disabled={formMode === 'view'}
          hasLimit
          dataQa="risksAndBenefitsConsentDetailsTextArea"
        />
      </DetailsWrapper>

      <Checkbox
        alignCenter={false}
        containerStyle={{ alignSelf: 'flex-start' }}
        label="I certify the above statement to be accurate as of the date submitted."
        labelStyle={{ fontWeight: 400 }}
        shouldDisplayError={statementCertifiedError}
        isChecked={statementCertified}
        setIsChecked={handleStatementCertifiedChange}
        isLabelOnRight
        isDisabled={formMode === 'view'}
        dataQa="statementCertifiedCheckbox"
      />
      {progressNoteData && formMode === 'view' && (
        <StatementCertification
          user={progressNoteData.createdByUser}
          submittedAt={new Date(progressNoteData.submittedAt)}
        />
      )}
      {formMode !== 'view' && !formSections.psychPlan.completed && (
        <ConfirmButton onPress={handleConfirmPress} sectionTitle="psychPlan" />
      )}
    </AccordionContainer>
  );
};

export default PsychPlanSection;
