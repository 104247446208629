import { withDSIconMaker } from './utils/IconMaker';

const hugePath =
  'M5.82878 15.2844V6.74254C5.82878 6.23974 6.24026 5.82836 6.74317 5.82836H15.287C15.7728 5.82836 16.2357 6.02263 16.5786 6.36544L26.6369 16.4214C27.3512 17.1356 27.3512 18.2955 26.6369 19.0097L19.0074 26.6317C18.2931 27.3459 17.1329 27.3459 16.4186 26.6317L6.36027 16.5757C6.01737 16.2329 5.82307 15.7701 5.82307 15.2844H5.82878ZM4 15.2844C4 16.2557 4.3829 17.1871 5.06869 17.8727L15.127 27.9287C16.5557 29.3571 18.8703 29.3571 20.299 27.9287L27.9284 20.301C29.3572 18.8726 29.3572 16.5586 27.9284 15.1302L17.8702 5.07416C17.1844 4.38853 16.2528 4.00571 15.2813 4.00571L6.74317 4C5.22871 4 4 5.22843 4 6.74254V15.2844ZM10.4007 11.7705C10.7645 11.7705 11.1134 11.6261 11.3706 11.3689C11.6278 11.1117 11.7723 10.763 11.7723 10.3993C11.7723 10.0356 11.6278 9.6868 11.3706 9.42963C11.1134 9.17247 10.7645 9.028 10.4007 9.028C10.037 9.028 9.6881 9.17247 9.43087 9.42963C9.17365 9.6868 9.02915 10.0356 9.02915 10.3993C9.02915 10.763 9.17365 11.1117 9.43087 11.3689C9.6881 11.6261 10.037 11.7705 10.4007 11.7705Z';
const pathConfig = {
  standard: {
    default: {
      path: '',
    },
  },
  huge: {
    default: {
      path: hugePath,
    },
  },
};

const Tag = withDSIconMaker(pathConfig, 'Tag');

export default Tag;
