import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const pathConfig: IconConfig = {
  standard: {
    filled: {
      path: 'M15.2687 14.0067C14.5781 12.8067 13.2812 12.0005 11.8 12.0005H9.79999C8.31874 12.0005 7.02186 12.8067 6.33124 14.0067C7.43124 15.2317 9.02499 16.0005 10.8 16.0005C12.575 16.0005 14.1687 15.2286 15.2687 14.0067ZM2.79999 10.0005C2.79999 7.87876 3.64284 5.84392 5.14313 4.34363C6.64342 2.84334 8.67826 2.00049 10.8 2.00049C12.9217 2.00049 14.9566 2.84334 16.4568 4.34363C17.9571 5.84392 18.8 7.87876 18.8 10.0005C18.8 12.1222 17.9571 14.1571 16.4568 15.6573C14.9566 17.1576 12.9217 18.0005 10.8 18.0005C8.67826 18.0005 6.64342 17.1576 5.14313 15.6573C3.64284 14.1571 2.79999 12.1222 2.79999 10.0005ZM10.8 10.5005C11.3967 10.5005 11.969 10.2634 12.391 9.84148C12.8129 9.41952 13.05 8.84723 13.05 8.25049C13.05 7.65375 12.8129 7.08146 12.391 6.6595C11.969 6.23754 11.3967 6.00049 10.8 6.00049C10.2033 6.00049 9.63095 6.23754 9.209 6.6595C8.78704 7.08146 8.54999 7.65375 8.54999 8.25049C8.54999 8.84723 8.78704 9.41952 9.209 9.84148C9.63095 10.2634 10.2033 10.5005 10.8 10.5005Z',
    },
    default: {
      path: 'M15.5031 14.488C14.9062 13.0286 13.4719 12.0005 11.8 12.0005H9.79999C8.12811 12.0005 6.69374 13.0286 6.09686 14.488C4.98436 13.3192 4.29999 11.7411 4.29999 10.0005C4.29999 6.40986 7.20936 3.50049 10.8 3.50049C14.3906 3.50049 17.3 6.40986 17.3 10.0005C17.3 11.7411 16.6156 13.3192 15.5031 14.488ZM10.8 18.0005C12.9217 18.0005 14.9566 17.1576 16.4568 15.6573C17.9571 14.1571 18.8 12.1222 18.8 10.0005C18.8 7.87876 17.9571 5.84392 16.4568 4.34363C14.9566 2.84334 12.9217 2.00049 10.8 2.00049C8.67826 2.00049 6.64342 2.84334 5.14313 4.34363C3.64284 5.84392 2.79999 7.87876 2.79999 10.0005C2.79999 12.1222 3.64284 14.1571 5.14313 15.6573C6.64342 17.1576 8.67826 18.0005 10.8 18.0005ZM8.04999 8.25049C8.04999 8.97983 8.33972 9.67931 8.85544 10.195C9.37117 10.7108 10.0706 11.0005 10.8 11.0005C11.5293 11.0005 12.2288 10.7108 12.7445 10.195C13.2603 9.67931 13.55 8.97983 13.55 8.25049C13.55 7.52114 13.2603 6.82167 12.7445 6.30594C12.2288 5.79022 11.5293 5.50049 10.8 5.50049C10.0706 5.50049 9.37117 5.79022 8.85544 6.30594C8.33972 6.82167 8.04999 7.52114 8.04999 8.25049Z',
    },
  },
  huge: {
    default: {
      path: 'M24.5367 24.7828C23.7711 21.7477 21.0258 19.5 17.75 19.5H14.25C10.9742 19.5 8.22891 21.7477 7.46328 24.7828C5.17187 22.557 3.75 19.4453 3.75 16C3.75 9.23516 9.23516 3.75 16 3.75C22.7648 3.75 28.25 9.23516 28.25 16C28.25 19.4453 26.8281 22.557 24.5367 24.7828ZM16 30C19.713 30 23.274 28.525 25.8995 25.8995C28.525 23.274 30 19.713 30 16C30 12.287 28.525 8.72601 25.8995 6.10051C23.274 3.475 19.713 2 16 2C12.287 2 8.72601 3.475 6.10051 6.10051C3.475 8.72601 2 12.287 2 16C2 19.713 3.475 23.274 6.10051 25.8995C8.72601 28.525 12.287 30 16 30ZM11.625 13.375C11.625 14.5353 12.0859 15.6481 12.9064 16.4686C13.7269 17.2891 14.8397 17.75 16 17.75C17.1603 17.75 18.2731 17.2891 19.0936 16.4686C19.9141 15.6481 20.375 14.5353 20.375 13.375C20.375 12.2147 19.9141 11.1019 19.0936 10.2814C18.2731 9.46094 17.1603 9 16 9C14.8397 9 13.7269 9.46094 12.9064 10.2814C12.0859 11.1019 11.625 12.2147 11.625 13.375Z',
    },
  },
};

const CircleUser = withDSIconMaker(pathConfig, 'person inside circle');

export default CircleUser;
