import { FunctionComponent } from 'react';
import * as React from 'react';
import Svg, { Path, G } from 'svgs';
import { Linecap } from 'react-native-svg';
import { useEmotionTheme, EmotionStyle } from '../../core/styled';
import withHover, { WithHoverProps } from '../../hoc/withHover';

export interface InnerCaretBaseProps extends WithHoverProps {
  width?: number;
  height?: number;
  color?: string;
  disabled?: boolean;
  hoverColor?: string;
  hasHoverStyles?: boolean;
  transform?: string;
  rotation?: number;
  isLeft?: boolean;
  isRight?: boolean;
  strokeWidth?: number;
  strokeLinecap?: Linecap;
  style?: EmotionStyle;
}

const CaretBase: FunctionComponent<InnerCaretBaseProps> = ({
  width = 13,
  height = 21,
  hoverColor,
  color,
  rotation = 0,
  hasHoverStyles,
  isHovering,
  isLeft,
  isRight,
  strokeWidth = 1,
  strokeLinecap,
  style,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();

  let titleText: string;
  if (isRight) {
    titleText = 'right arrow';
  } else if (rotation !== 0) {
    titleText = 'arrow';
  } else {
    titleText = 'left arrow';
  }
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 13 21"
      title={titleText}
      aria-label={titleText}
      style={style}
      fill={
        isHovering && hasHoverStyles ? hoverColor || colors.extraLightGrey : color || colors.green
      }
      {...otherProps}
    >
      {/* SVG elements don't support transform on Safari or IE so the rotation has to be done on a group
        https://stackoverflow.com/a/48248873/1953740 */}
      <G transform={`rotate(${isRight ? 180 : rotation} 6.5 10.5)`}>
        <Path
          d="M12 33.5l9.833-9.455c.6-.577 1.57-.581 2.164-.01l-.074-.07a1.431 1.431 0 01-.007 2.077L16.16 33.5l7.756 7.458a1.43 1.43 0 01.007 2.077l.074-.07a1.57 1.57 0 01-2.164-.01L12 33.5z"
          transform="translate(-12 -23)"
          stroke="none"
          strokeWidth={strokeWidth}
          {...(strokeLinecap && { strokeLinecap })}
        />
      </G>
    </Svg>
  );
};

const CaretBaseWithHover = withHover(CaretBase);

export type CaretBaseProps = React.ComponentProps<typeof CaretBaseWithHover>;

export default CaretBaseWithHover;
