import Svg, { Defs, G, Path, Rect } from 'svgs';
import { useEmotionTheme, EmotionStyle } from '../../core/styled';
import { useUniqueID } from '../../hooks/a11yHelper';

interface Props {
  width?: number;
  height?: number;
  color?: string;
  dataQa?: string;
  style?: EmotionStyle;
}

const RepeatingArrowsCircle = ({
  width = 24,
  height = 24,
  color,
  dataQa,
  style,
  ...props
}: Props) => {
  const { colors } = useEmotionTheme();
  const titleText = 'Repeating arrows';
  const clipPathID = useUniqueID('clipPathID');
  return (
    <Svg
      width={width}
      height={height}
      data-qa={dataQa}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 24 24"
      fill="none"
      style={style}
      {...props}
    >
      <G clipPath={`url(#${clipPathID})`}>
        <Path
          d="M12.0007 3.66694C14.2335 3.67428 16.3705 4.57445 17.9355 6.16686H15.3339C14.8737 6.16686 14.5006 6.53994 14.5006 7.00016C14.5006 7.46038 14.8737 7.83345 15.3339 7.83345H18.7863C19.6407 7.83298 20.3333 7.14043 20.3338 6.286V2.83361C20.3338 2.37339 19.9607 2.00031 19.5005 2.00031C19.0403 2.00031 18.6672 2.37339 18.6672 2.83361V4.56523C14.5699 0.87523 8.25712 1.20538 4.56712 5.30263C3.12306 6.9061 2.23735 8.93428 2.04271 11.0833C1.99989 11.5448 2.3393 11.9536 2.80077 11.9964C2.82577 11.9987 2.85088 11.9999 2.87604 12C3.29841 12.0054 3.65547 11.6884 3.70019 11.2683C4.08463 6.9691 7.68433 3.6726 12.0007 3.66694Z"
          fill={color || colors.permaTalkspaceDarkGreen}
        />
        <Path
          d="M21.1266 12.0001C20.7042 11.9947 20.3471 12.3117 20.3024 12.7317C19.9048 17.3103 15.8708 20.6995 11.2922 20.3018C9.31119 20.1298 7.4573 19.254 6.06621 17.8332H8.66781C9.12803 17.8332 9.50111 17.4601 9.50111 16.9999C9.50111 16.5397 9.12803 16.1666 8.66781 16.1666H5.21542C4.36123 16.1661 3.66844 16.8582 3.66797 17.7124C3.66797 17.7129 3.66797 17.7135 3.66797 17.7141V21.1665C3.66797 21.6267 4.04104 21.9998 4.50126 21.9998C4.96148 21.9998 5.33456 21.6267 5.33456 21.1665V19.4348C9.43181 23.1248 15.7446 22.7947 19.4346 18.6974C20.8787 17.094 21.7644 15.0658 21.959 12.9167C22.0019 12.4552 21.6624 12.0464 21.201 12.0036C21.1763 12.0014 21.1514 12.0001 21.1266 12.0001Z"
          fill={color || colors.permaTalkspaceDarkGreen}
        />
      </G>
      <Defs>
        <clipPath id={clipPathID}>
          <Rect width="20" height="20" fill="white" transform="translate(2 2)" />
        </clipPath>
      </Defs>
    </Svg>
  );
};

export default RepeatingArrowsCircle;
