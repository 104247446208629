import { useMemo } from 'react';

import { useFlags } from 'launchDarkly/FlagsProvider';

import { Booking, VideoCreditOffer } from 'ts-frontend/types';
import { RoomType } from 'ts-frontend/entities/Room';

import storage from '@/core/storage';
import { VideoCreditType } from '../types/videoCallTypes';

const SHOW_MINUTES_BEFORE_END = 5;

interface Props {
  isTherapist: boolean;
  isBH: boolean;
  roomType: RoomType | undefined;
  sessionLengthMinutes: number;
  timeInCallSeconds: number;
  videoCreditOffers: VideoCreditOffer[];
  videoCreditType: VideoCreditType;
  bookings: Booking[];
}
type RecurringSchedulerData = {
  isTimeToShow: boolean;
  isEligible: boolean;
};
const useVideoCallProviderRecurringScheduler = ({
  isTherapist,
  isBH,
  roomType,
  sessionLengthMinutes,
  timeInCallSeconds,
  videoCreditOffers,
  videoCreditType,
  bookings,
}: Props): RecurringSchedulerData => {
  const { repeatingSessionsFull: isActiveRecurringSessionsFull } = useFlags();

  const hasFutureBookings: boolean = useMemo(
    () =>
      (bookings?.filter((booking) =>
        ['tentative', 'confirmed'].includes(booking.timekitBookingState || '')
      )).length > 0,
    [bookings]
  );
  const showMinutesBeforeEnd = useMemo(
    () => Number(storage.getItem('therapistPopupShowMinutesBeforeEnd')) || SHOW_MINUTES_BEFORE_END,
    []
  );

  // NOTE: free sessions have length 0 so we assume their length to be 45
  const totalLengthMinutes = useMemo(
    () => (sessionLengthMinutes === 0 ? 45 : sessionLengthMinutes),
    [sessionLengthMinutes]
  );
  const isTimeToShow = useMemo(
    () => timeInCallSeconds >= (totalLengthMinutes - showMinutesBeforeEnd) * 60,
    [showMinutesBeforeEnd, timeInCallSeconds, totalLengthMinutes]
  );

  const creditOption = videoCreditOffers.find(
    (credit) => credit.creditMinutes === totalLengthMinutes
  );
  const isEligible = useMemo(
    () =>
      isActiveRecurringSessionsFull &&
      isTherapist &&
      isBH &&
      roomType === 'private_room' &&
      !!creditOption &&
      videoCreditType !== VideoCreditType.introduction &&
      !hasFutureBookings,
    [
      creditOption,
      hasFutureBookings,
      isActiveRecurringSessionsFull,
      isBH,
      isTherapist,
      roomType,
      videoCreditType,
    ]
  );

  return {
    isEligible,
    isTimeToShow,
  };
};

export default useVideoCallProviderRecurringScheduler;
