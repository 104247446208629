import { useState } from 'react';
import {
  View,
  Standard,
  Small,
  Tiny,
  TouchableView,
  CaretRight,
  CaretLeft,
  BaseButton,
  useEmotionTheme,
  EmotionTheme,
  useWindowWidthState,
  TooltipV2,
} from '@talkspace/react-toolkit';
import moment, { Moment } from 'moment';
import styled from '../../../core/styled';
import AvailabilityCalendarBlock, {
  CELL_HEIGHT,
  MIN_CELL_WIDTH,
} from './AvailabilityCalendarBlockV2';
import CalendarIcon from '../../../components/Icons/CalendarIcon';
import RecycleIcon from '../../../components/Icons/RecycleIcon';
import PartialIcon from '../../../components/Icons/PartialIcon';

import {
  AvailabilityWeekday,
  ChangeAvailabilityHourPayload,
  Availability,
  AvailabilityEnumByDateByHour,
  AvailabilityWeekdaysSetByHour,
  DatePeriod,
} from '../typesV2';

const getIfBeforeNow = (date: string, hour: number) => {
  const now = new Date();
  const blockDateTime = new Date(`${date} ${hour}:00:00`);
  return blockDateTime < now;
};

const Table = styled.table({
  minWidth: 352,
  tableLayout: 'fixed',
});
const Tr = styled.tr({ position: 'relative', height: CELL_HEIGHT });
const HeaderText = styled(Small)<{ isActive?: boolean; theme: EmotionTheme }>(
  ({ isActive, theme: { colors } }) => {
    return {
      color: isActive ? colors.darkGray : colors.fordGreyText,
      fontWeight: isActive ? 600 : 400,
    };
  }
);

const HeaderTextTiny = styled(Tiny)<{ isActive?: boolean; theme: EmotionTheme }>(
  ({ isActive, theme: { colors } }) => {
    return {
      color: isActive ? colors.darkGray : colors.fordGreyText,
      fontWeight: isActive ? 600 : 400,
      fontSize: '12px',
    };
  }
);

const TodayButton = styled(BaseButton)(
  ({
    theme: {
      colors,
      window: { isMobile },
    },
  }) => {
    return {
      marginRight: 2,
      border: `1px solid ${colors.permaViridianGreen}`,
      borderRadius: 7,
      padding: isMobile ? 4 : '7px 15px',
      width: '90%',
      zIndex: 3,
      '&:hover': {
        backgroundColor: colors.permaViridianGreen,
      },
      '&:hover > p': {
        color: colors.white,
      },
    };
  }
);
const TodayButtonText = styled(Standard)(({ theme: { colors } }) => {
  return {
    fontSize: 14,
    color: colors.permaViridianGreen,
  };
});
const displayHours = [
  '12AM - 1AM',
  '1AM - 2AM',
  '2AM - 3AM',
  '3AM - 4AM',
  '4AM - 5AM',
  '5AM - 6AM',
  '6AM - 7AM',
  '7AM - 8AM',
  '8AM - 9AM',
  '9AM - 10AM',
  '10AM - 11AM',
  '11AM - 12PM',
  '12PM - 1PM',
  '1PM - 2PM',
  '2PM - 3PM',
  '3PM - 4PM',
  '4PM - 5PM',
  '5PM - 6PM',
  '6PM - 7PM',
  '7PM - 8PM',
  '8PM - 9PM',
  '9PM - 10PM',
  '10PM - 11PM',
  '11PM - 12AM',
];

const displayHoursMobile = [
  '12AM',
  '1AM',
  '2AM',
  '3AM',
  '4AM',
  '5AM',
  '6AM',
  '7AM',
  '8AM',
  '9AM',
  '10AM',
  '11AM',
  '12PM',
  '1PM',
  '2PM',
  '3PM',
  '4PM',
  '5PM',
  '6PM',
  '7PM',
  '8PM',
  '9PM',
  '10PM',
  '11PM',
];

const Wrapper = styled(View)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      alignItems: isMobile ? 'center' : 'flex-start',
    };
  }
);
const TableWrapper = styled(View)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      width: '100%',
    };
  }
);
const CalendarLegendWrapper = styled(View)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      marginBottom: 10,
      flexDirection: 'row',
      alignItems: isMobile ? 'start' : 'center',
      width: '100%',
    };
  }
);
const CaretWrapper = styled(TouchableView)({ padding: 5 });

const CalendarLegend = () => {
  const { colors } = useEmotionTheme();
  const { isMobile } = useWindowWidthState();
  const tinyStyle = isMobile ? { marginRight: 5 } : { marginLeft: 7, marginRight: 15 };
  const align = isMobile ? 'start' : 'center';
  const groupStyle = isMobile ? { height: 42 } : { height: 34 };
  return (
    <CalendarLegendWrapper>
      <View style={{ width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
        <View row={!isMobile} align={align} style={groupStyle}>
          <CalendarIcon color={colors.permaViridianGreen} />
          <Tiny className="ts-text-light-gray" style={tinyStyle}>
            One-time availability
          </Tiny>
        </View>
        <View row={!isMobile} align={align} style={groupStyle}>
          <RecycleIcon color={colors.permaViridianGreen} />
          <Tiny className="ts-text-light-gray" style={tinyStyle}>
            {isMobile ? 'Recurring weekly' : 'Recurring weekly availability'}
          </Tiny>
        </View>
        <View row={!isMobile} align={align} style={groupStyle}>
          <RecycleIcon />
          <Tiny className="ts-text-light-gray" style={{ ...tinyStyle }}>
            {isMobile ? 'Unavailable' : 'Unavailable this week'}
          </Tiny>
        </View>
        <View row={!isMobile} align={align} style={{ ...groupStyle, height: isMobile ? 43 : 34 }}>
          <PartialIcon width={25} height={25} />
          <View row style={{ alignItems: 'center' }}>
            <Tiny className="ts-text-light-gray" style={{ ...tinyStyle, marginRight: 5 }}>
              {isMobile ? 'Partial' : 'Partial availability'}
            </Tiny>
            <TooltipV2
              circleColor={colors.accessibilityGreenDark}
              questionMarkColor={colors.white}
              toolTipText={`Partial availability means you've added availability for part of the hour.`}
              containerStyle={{
                fontSize: 14,
                minWidth: 287,
                paddingRight: 27,
                paddingLeft: 20,
                letterSpacing: 0.1,
                fontFamily: 'Roboto',
                fontWeight: 400,
                color: colors.purple600,
              }}
            />
          </View>
        </View>
      </View>
    </CalendarLegendWrapper>
  );
};

const Th = styled.th({ padding: '10px 0', minWidth: MIN_CELL_WIDTH });

const RowTh = styled.th(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      height: isMobile ? 18 : 'initial',
      overflow: 'hidden',
      display: isMobile ? 'inline-block' : 'initial',
      minWidth: MIN_CELL_WIDTH,
      textAlign: 'right',
      width: '100%',
    };
  }
);

const CaretTh = styled.th({ width: 12 });

const TableHeaderRow = styled.tr(({ theme: { colors } }) => {
  return {
    position: 'sticky',
    top: 0,
    backgroundColor: colors.permaOlympusGrey,
    zIndex: 1,
  };
});

const RowLeftHeader = ({ hour }: { hour?: number }) => {
  const { isMobile } = useWindowWidthState();
  return isMobile ? (
    <RowTh>
      <HeaderTextTiny style={{ marginRight: 5 }}>
        {hour !== undefined ? displayHoursMobile[hour] : ''}
      </HeaderTextTiny>
    </RowTh>
  ) : (
    <RowTh>
      <HeaderText>{hour !== undefined ? displayHours[hour] : ''}</HeaderText>
    </RowTh>
  );
};
const isToday = (date: Moment) => moment().isSame(date, 'd');
const TableHeaderCell = ({ date }: { date: Moment }) => {
  const { isMobile } = useWindowWidthState();
  const headerStyle = { textAlign: 'center', lineHeight: '14px' };
  const isActive = isToday(date);
  return isMobile ? (
    <Th style={{ verticalAlign: 'bottom' }}>
      <HeaderTextTiny style={headerStyle} isActive={isActive}>
        {date.format('ddd')}
      </HeaderTextTiny>
      <HeaderTextTiny style={headerStyle} isActive={isActive}>
        {date.format('MMM D')}
      </HeaderTextTiny>
    </Th>
  ) : (
    <Th style={{ verticalAlign: 'bottom' }}>
      <HeaderText style={headerStyle} isActive={isActive}>
        {date.format('ddd')}
      </HeaderText>
      <HeaderText style={headerStyle} isActive={isActive}>
        {date.format('MMM D')}
      </HeaderText>
    </Th>
  );
};

interface TableHeaderProps {
  momentDatesInViewArr: Moment[];
  hour?: number;
  canGoPrevWeek: boolean;
  resetWeek: () => void;
  moveWeekBack: () => void;
  moveWeekAhead: () => void;
}
const TableHeader = ({
  momentDatesInViewArr,
  hour,
  canGoPrevWeek,
  resetWeek,
  moveWeekAhead,
  moveWeekBack,
}: TableHeaderProps) => {
  const { isSmall, isMobile } = useWindowWidthState();
  const { colors } = useEmotionTheme();
  const leftColWidth = isMobile ? 60 : 100;
  const todayButtonHeaderStyle = {
    width: leftColWidth,
    minWidth: leftColWidth,
    textAlign: 'right',
  };
  return isSmall ? (
    <thead>
      <TableHeaderRow>
        <Th style={{ height: 75 }}>
          <View style={{ position: 'absolute', top: 0, width: '100%', padding: 5 }}>
            <View
              justify={canGoPrevWeek ? 'space-between' : 'end'}
              style={{ position: 'relative' }}
              row
            >
              {canGoPrevWeek ? (
                <View row>
                  <TodayButton onPress={resetWeek}>
                    <TodayButtonText>Today</TodayButtonText>
                  </TodayButton>
                  <CaretWrapper onPress={moveWeekBack}>
                    <CaretLeft width={11} height={16} color={colors.permaViridianGreen} />
                  </CaretWrapper>
                </View>
              ) : null}
              <CaretWrapper onPress={moveWeekAhead}>
                <CaretRight width={11} height={16} color={colors.permaViridianGreen} />
              </CaretWrapper>
            </View>
          </View>
        </Th>
        {momentDatesInViewArr.map((d) => (
          <TableHeaderCell date={d} key={d.toISOString()} />
        ))}
      </TableHeaderRow>
    </thead>
  ) : (
    <thead>
      <TableHeaderRow>
        {canGoPrevWeek ? (
          <Th style={todayButtonHeaderStyle}>
            <TodayButton onPress={resetWeek}>
              <TodayButtonText>Today</TodayButtonText>
            </TodayButton>
          </Th>
        ) : (
          <Th style={todayButtonHeaderStyle} />
        )}
        {canGoPrevWeek ? (
          <CaretTh>
            <CaretWrapper onPress={moveWeekBack}>
              <CaretLeft width={11} height={16} color={colors.permaViridianGreen} />
            </CaretWrapper>
          </CaretTh>
        ) : (
          <Th style={{ width: 21, minWidth: 21 }} />
        )}
        {momentDatesInViewArr.map((d) => (
          <TableHeaderCell date={d} key={d.toISOString()} />
        ))}
        <CaretTh>
          <CaretWrapper onPress={moveWeekAhead}>
            <CaretRight width={11} height={16} color={colors.permaViridianGreen} />
          </CaretWrapper>
        </CaretTh>
      </TableHeaderRow>
    </thead>
  );
};

const Row = ({
  availabilityEnumsByDate,
  hour,
  toggleAvailability,
  showPastCanNotEditToast,
  datesInView,
  timeOffDates,
  isDisabled,
}: {
  availabilityEnumsByDate: Record<string, Availability>;
  hour: number;
  toggleAvailability: (payload: ChangeAvailabilityHourPayload) => void;
  showPastCanNotEditToast: () => void;
  datesInView: DatesInView;
  timeOffDates?: string[];
  isDisabled?: boolean;
}) => {
  const { isSmall } = useWindowWidthState();
  return (
    <Tr>
      <RowLeftHeader hour={hour} />
      {!isSmall && <td />}
      {Object.entries(datesInView).map(([weekday, date]) => {
        const isBeforeNow = getIfBeforeNow(date, hour);
        return (
          <AvailabilityCalendarBlock
            availability={
              timeOffDates && timeOffDates.includes(date)
                ? { partial: false, availability: 'time-off' }
                : availabilityEnumsByDate[date]
            }
            onPress={() =>
              isBeforeNow && !isDisabled
                ? showPastCanNotEditToast()
                : toggleAvailability({
                    date,
                    weekday: weekday as AvailabilityWeekday,
                    hour,
                    prevState: availabilityEnumsByDate[date],
                  })
            }
            disableHover={isDisabled || isBeforeNow}
            key={`${date}-${hour}`}
          />
        );
      })}
      {!isSmall && <td />}
    </Tr>
  );
};

const hoursArray = [...Array(24).keys()];

const getMomentDatesInViewArr = (startDate) => [
  moment(startDate),
  moment(startDate).add(1, 'days'),
  moment(startDate).add(2, 'days'),
  moment(startDate).add(3, 'days'),
  moment(startDate).add(4, 'days'),
  moment(startDate).add(5, 'days'),
  moment(startDate).add(6, 'days'),
];

type DatesInView = Record<AvailabilityWeekday, string>;

function determineAvailabilityEnumByDate(
  datesInView: DatesInView,
  availabilityEnumByDate?: Record<string, Availability | undefined>,
  availabilitySet?: Set<AvailabilityWeekday>
): Record<string, Availability> {
  return Object.entries(datesInView).reduce((prev, [weekday, date]) => {
    const enumFromDict = availabilityEnumByDate && availabilityEnumByDate[date];
    let availabilityEnum: Availability = enumFromDict || {
      partial: false,
      availability: 'recurring-unavailability',
    };
    const recurring =
      availabilitySet &&
      [...availabilitySet]
        .map((it: any) => {
          return { day: it.day, partial: it.partial };
        })
        .find((it) => it.day === weekday);

    const hasRecurring = !!recurring;
    const isRecurringPartial = recurring?.partial;
    // in the case of recurring, the state is recurring unless one time unavailability
    if (hasRecurring && enumFromDict?.availability !== 'one-time-unavailability') {
      availabilityEnum = { partial: isRecurringPartial, availability: 'recurring-availability' };
    }
    // if not recurring, the unavailablilty is not really a thing
    if (!hasRecurring && enumFromDict?.availability === 'one-time-unavailability') {
      availabilityEnum = {
        partial: enumFromDict.partial,
        availability: 'recurring-unavailability',
      };
    }
    return {
      ...prev,
      [date]: availabilityEnum,
    };
  }, {} as Record<string, Availability>);
}

function getTimeOffDates(timeOffPeriods: DatePeriod[]): string[] {
  const nestedDateArray = timeOffPeriods.map(({ startDate, endDate }) => {
    const dateArray: string[] = [];
    const stopDate = new Date(endDate);
    let currentDate = new Date(startDate);
    while (currentDate <= stopDate) {
      dateArray.push(new Date(currentDate).toISOString().split('T')[0]);
      currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
    }
    return dateArray;
  });
  return nestedDateArray.flat();
}

interface AvailabilityCalendarProps {
  isDisabled?: boolean;
  availabilityEnumByDateByHour?: AvailabilityEnumByDateByHour;
  availabilityWeekdaysSetByHour?: AvailabilityWeekdaysSetByHour;
  changeAvailabilityHour: (payload: ChangeAvailabilityHourPayload) => void;
  showCanNotEditToast: (payload?: ChangeAvailabilityHourPayload) => void;
  showPastCanNotEditToast: () => void;
  timeOffPeriods: DatePeriod[];
}

const AvailabilityCalendar = (props: AvailabilityCalendarProps) => {
  const {
    isDisabled,
    availabilityEnumByDateByHour,
    availabilityWeekdaysSetByHour,
    changeAvailabilityHour,
    showCanNotEditToast,
    showPastCanNotEditToast,
    timeOffPeriods,
  } = props;

  const [startDate, setStartDate] = useState<string>(moment().startOf('week').format('YYYY-MM-DD'));

  const timeOffDates = getTimeOffDates(timeOffPeriods);

  const moveWeekBack = () => {
    setStartDate(moment(startDate).subtract(1, 'week').format('YYYY-MM-DD'));
  };
  const moveWeekAhead = () => {
    setStartDate(moment(startDate).add(1, 'week').format('YYYY-MM-DD'));
  };
  const resetWeek = () => {
    setStartDate(moment().startOf('week').format('YYYY-MM-DD'));
  };

  const momentDatesInViewArr: Moment[] = getMomentDatesInViewArr(startDate);
  const datesInView: DatesInView = momentDatesInViewArr.reduce((prev, next) => {
    return {
      ...prev,
      [next.format('dddd')]: next.format('YYYY-MM-DD'),
    };
  }, {} as DatesInView);
  const canGoPrevWeek = moment(startDate).isAfter(moment());

  return availabilityEnumByDateByHour && availabilityWeekdaysSetByHour ? (
    <Wrapper>
      <CalendarLegend />
      <TableWrapper>
        <View>
          <Table>
            <TableHeader
              momentDatesInViewArr={momentDatesInViewArr}
              canGoPrevWeek={canGoPrevWeek}
              moveWeekAhead={moveWeekAhead}
              moveWeekBack={moveWeekBack}
              resetWeek={resetWeek}
            />
            {hoursArray.map((hour) => {
              // these both are often undefined
              const availabilityEnumByDate = availabilityEnumByDateByHour[hour];
              const availabilitySet = availabilityWeekdaysSetByHour[hour];
              // this will always be a dictionary
              const availabilitiesEnumsByDate = determineAvailabilityEnumByDate(
                datesInView,
                availabilityEnumByDate,
                availabilitySet
              );
              return (
                <Row
                  toggleAvailability={isDisabled ? showCanNotEditToast : changeAvailabilityHour}
                  showPastCanNotEditToast={showPastCanNotEditToast}
                  availabilityEnumsByDate={availabilitiesEnumsByDate}
                  key={hour}
                  hour={hour}
                  datesInView={datesInView}
                  timeOffDates={timeOffDates}
                  isDisabled={isDisabled}
                />
              );
            })}
          </Table>
        </View>
      </TableWrapper>
    </Wrapper>
  ) : null;
};

export default AvailabilityCalendar;
