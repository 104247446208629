import { useState } from 'react';
import { mergeStyles, Styles, components as defaultComponents } from 'react-select';
import styled, { useEmotionTheme, EmotionTheme, EmotionStyle } from '../../core/styled';
import Select, { OptionType } from '../Select';
import TouchableView from '../TouchableView';
import View from '../View';
import PlusIcon from '../Svgs/PlusIcon';
import { Small } from '../Typography';
import { A11yState } from '../../hooks/a11yContextProvider';
import TooltipV2 from '../TooltipV2';

const EmptyValueContainer = styled(TouchableView)(({ theme: { colors } }) => {
  return {
    flex: 1,
    height: 36,
    borderRadius: 8,
    cursor: 'pointer',
    background: colors.permaGreyed,
    paddingTop: 10,
    paddingBottom: 10,
    position: 'relative',
    paddingRight: 7.5,
  };
});

const EmptyText = styled(Small)(({ theme: { colors } }) => {
  return {
    color: colors.black,
    fontSize: 14,
    flex: 1,
    textAlign: 'center',
  };
});

const DropdownIndicator = (props) => (
  <defaultComponents.DropdownIndicator {...props}>
    <PlusIcon />
  </defaultComponents.DropdownIndicator>
);

const plusMultiSelectStyles = (
  colors: EmotionTheme['colors'],
  isContainerDisabled: boolean,
  isError: boolean
): Styles<OptionType, boolean> => {
  return {
    container: (provided) => {
      return {
        ...provided,
        margin: 0,
        background: isContainerDisabled && colors.permaGreyed,
      };
    },
    control: (provided, state) => {
      let borderColor = colors.periwinkleGrey;
      if (state.isFocused) {
        borderColor = colors.grey950;
      }
      if (isError) {
        borderColor = colors.permaFuchsia;
      }
      return {
        ...provided,
        cursor: 'pointer',
        boxShadow: state.isFocused ? 0 : 0,
        borderColor,
        '&:hover': {
          borderColor,
          background: colors.permaGreyed,
        },
        borderRadius: 8,
        padding: 0,
        borderWidth: 1,
        flex: 1,
        margin: 0,
        background: colors.permaPhantomWhite,
      };
    },
    indicatorSeparator: (provided) => {
      return {
        ...provided,
        marginTop: 0,
        marginBottom: 0,
        marginLeft: 4,
        display: 'block !important',
        background: colors.darkBlue150,
      };
    },
    dropdownIndicator: (provided, state) => {
      return {
        ...provided,
        width: 34,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: state.isFocused ? colors.optimumBlue : colors.permaGreyed,
        height: '100%',
        borderRadius: '0 8px 8px 0',
      };
    },
    clearIndicator: (provided) => {
      return {
        ...provided,
        color: colors.white,
      };
    },
    valueContainer: (provided) => {
      return {
        ...provided,
        overflowY: 'auto',
        paddingLeft: 5,
        paddingRight: 0,
      };
    },
    multiValue: (provided) => {
      return {
        ...provided,
        marginLeft: 2,
        marginRight: 0,
      };
    },
    multiValueLabel: (provided) => {
      return {
        ...provided,
        flex: 1,
        maxWidth: '190px !important',
        marginRight: 5,
      };
    },
    option: (provided, { isDisabled }) => {
      return {
        ...provided,
        '&:hover': {
          backgroundColor: isDisabled ? '' : colors.aqua,
          color: isDisabled ? colors.dustyGrey : colors.white,
        },
        padding: 10,
        height: 'max-content',
        width: '100%',
      };
    },
    menu: (provided) => {
      return {
        ...provided,
        marginTop: 5,
        overflow: 'hidden',
        boxShadow: '0 7px 16px rgba(0,29,47,0.3875)',
        cursor: 'pointer',
        borderRadius: 8,
        padding: 0,
      };
    },
    menuList: (provided) => {
      return {
        ...provided,
        borderRaidus: 8,
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      };
    },
    placeholder: (provided) => {
      return {
        ...provided,
        color: colors.grey950,
      };
    },
  };
};

const getMultiValueStyle = (colors: A11yState['colors']) => {
  return {
    backgroundColor: colors.aqua,
    '&:hover': {
      backgroundColor: colors.aqua,
    },
    height: 24,
    width: 'max-content',
    maxWidth: 355,
    minWidth: 208,
    paddingRight: '0 2px',
  };
};

const labelStyle = {
  fontSize: 14,
  fontWeight: 400,
  margin: 0,
  paddingLeft: 0,
};

const PlusMultiSelect = ({
  styles,
  components,
  width = 260,
  inputId,
  dataQa,
  wrapperStyle,
  emptyValueText,
  toolTipText,
  options,
  isDisabled = false,
  isError,
  dataTestID,
  ...otherProps
}: React.ComponentProps<typeof Select & { wrapperStyle: EmotionStyle }>) => {
  const { colors } = useEmotionTheme();
  const multiValueStyle = getMultiValueStyle(colors);
  const [isHovering, setIsHovering] = useState<boolean>(false);
  return (
    <>
      {options ? (
        <Select
          dataTestID={dataTestID}
          {...otherProps}
          options={options}
          dataQa={dataQa}
          styles={
            styles
              ? mergeStyles(plusMultiSelectStyles(colors, isDisabled, isError), styles)
              : plusMultiSelectStyles(colors, isDisabled, isError)
          }
          displayStaticPlaceholder
          components={{
            ...components,
            DropdownIndicator,
          }}
          showIndicatorBeforeClick
          multiValueStyles={multiValueStyle}
          labelStyle={labelStyle}
          width={width}
          lockStyles={{ display: 'none' }}
          isDisabled={isDisabled}
          wrapperStyle={{ ...(isDisabled && { background: colors.permaGreyed }) }}
        />
      ) : (
        <View style={{ position: 'relative' }}>
          <EmptyValueContainer
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
            row
            align="center"
            justify="space-between"
          >
            <EmptyText>{emptyValueText}</EmptyText>
            <TooltipV2 isTooltipOpen={isHovering} toolTipText={toolTipText} />
          </EmptyValueContainer>
        </View>
      )}
    </>
  );
};

export default PlusMultiSelect;
