import { createContext, useContext, useState } from 'react';
import useFlagsSafe from './useFlagsSafe';

interface ScreenState {
  backButtonCallback?: () => void;
  isNewLogo?: boolean;
}
const screenInitialState: ScreenState = {
  backButtonCallback: undefined,
};

interface ScreenActions {
  setBackButtonCallback: (x: undefined | (() => void)) => void;
}

const StateContext = createContext<ScreenState>(screenInitialState);
export { StateContext as UseScreenStateContext };

const ActionsContext = createContext<ScreenActions | undefined>(undefined);

export function ScreenProvider({ children }) {
  const [backButtonCallback, setBackButtonCallback] = useState<undefined | (() => void)>(undefined);
  const { brandRefreshChanges } = useFlagsSafe();
  const state = {
    backButtonCallback,
    isNewLogo: brandRefreshChanges,
  };
  const actions = {
    setBackButtonCallback,
  };
  return (
    <StateContext.Provider value={state}>
      <ActionsContext.Provider value={actions}>{children}</ActionsContext.Provider>
    </StateContext.Provider>
  );
}

export function useScreenState(): ScreenState {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error('StateContext must be used within a ScreenProvider');
  }
  return context;
}

export function useScreenActions() {
  const context = useContext(ActionsContext);
  if (context === undefined) {
    throw new Error('ActionsContext must be used within a ScreenProvider');
  }
  return context;
}
