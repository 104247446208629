import { VoidFunctionComponent } from 'react';
import Svg, { Path } from 'svgs';
import { useEmotionTheme, EmotionStyle } from '../../core/styled';

interface RecurrenceIconProps {
  color?: string;
  width?: number;
  height?: number;
  style?: EmotionStyle;
}

const RecurrenceIcon: VoidFunctionComponent<RecurrenceIconProps> = ({
  width = 18,
  height = 19,
  color,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();

  return (
    <Svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.0739 0.532094C16.6262 0.532095 17.0739 0.979811 17.0739 1.5321L17.0739 9.68646L8.91952 9.68646C8.36723 9.68646 7.91952 9.23875 7.91952 8.68646L7.91952 8.39787C7.91952 7.84559 8.36723 7.39787 8.91952 7.39787L13.3767 7.39787C13.3376 7.35017 13.3019 7.29866 13.27 7.24351C11.6901 4.50696 8.19086 3.56936 5.45432 5.1493C2.71778 6.72924 1.78017 10.2284 3.36012 12.965C4.94006 15.7015 8.43926 16.6391 11.1758 15.0592L11.3197 14.9733C11.8577 14.6418 12.5627 14.8092 12.8942 15.3472C13.2257 15.8853 13.0583 16.5902 12.5203 16.9217L12.3201 17.0412C8.48894 19.2531 3.59006 17.9404 1.37814 14.1093C-0.833783 10.2781 0.478869 5.37924 4.31003 3.16732C7.89098 1.09985 12.4048 2.11163 14.7853 5.38111L14.7853 1.53209C14.7853 0.97981 15.233 0.532094 15.7853 0.532095L16.0739 0.532094Z"
        fill={color || colors.slateGrey}
      />
    </Svg>
  );
};

export default RecurrenceIcon;
