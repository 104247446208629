import { PeopleIcon, Standard, View, useWindowWidthState } from '@talkspace/react-toolkit';
import styled from '../../../core/styled';

const Container = styled(View)(({ theme: { window, colors } }) => {
  const { isMobile } = window;
  return {
    width: '100%',
    border: `1px solid ${colors.lightSteelBlue}`,
    background: colors.snowDrift,
    borderRadius: 7,
    minHeight: 119,
    padding: isMobile ? '25px 35px 45px 35px' : 15,
    marginTop: 16,
  };
});

const Title = styled(Standard)(({ theme: { window, colors } }) => {
  const { isMobile } = window;
  return {
    fontSize: 17,
    color: colors.independence,
    fontWeight: 700,
    textAlign: isMobile && 'center',
  };
});

const Text = styled(Title)(() => {
  return {
    fontWeight: 400,
    marginTop: 3,
  };
});

const Circle = styled(View)(({ theme: { window, colors } }) => {
  const { isMobile } = window;
  return {
    width: 89,
    height: 89,
    background: colors.a11yLinkWaterGrey,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: !isMobile && 15,
    marginBottom: isMobile && 10,
  };
});

const EmptyState = () => {
  const { isMobile } = useWindowWidthState();

  return (
    <Container>
      <View row={!isMobile} align="center">
        <Circle>
          <PeopleIcon />
        </Circle>

        <View style={{ overflow: 'hidden', flex: 1 }}>
          <Title>No waiting clients found</Title>
          <Text>
            We update the waitlist frequently, so check back later to see if new clients are
            waiting.
          </Text>
        </View>
      </View>
    </Container>
  );
};

export default EmptyState;
