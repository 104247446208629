import { useQuery } from 'react-query';
import apiWrapper from '../utils/apiWrapper';
import apiHelper from '../utils/api';
import { userAdminConfigQueryKey, AdminConfigValueParams } from './queryKeys';

const fetchAdminConfigValue = (params) => async () => {
  const { key, json } = params;
  const { data } = await apiWrapper.get(
    `${apiHelper().apiEndpoint}/v3/users/get-admin-config-value?key=${key}${
      json ? `&json=${true}` : ''
    }`
  );
  return data.data;
};

const useQueryUsersAdminConfigValue = <T extends unknown>(params: AdminConfigValueParams) =>
  useQuery<T, Error>({
    queryKey: userAdminConfigQueryKey(params),
    queryFn: fetchAdminConfigValue(params),
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
  });

export default useQueryUsersAdminConfigValue;
