import { FunctionComponent } from 'react';
import Lottie from 'react-lottie';
import checkmark from '../../utils/checkmark.json';
import styled, { EmotionStyle } from '../../core/styled';
import { webOnlyStyle } from '../../core/styleHelpers';
import View from '../View';
import CircledCheckMark from '../Svgs/GraphicCardCheckMark';

interface GraphicCardProps {
  height?: string | number;
  width?: string | number;
  heightResponsive?: string | number;
  widthResponsive?: string | number;
  imageAlignItems?: string;
  isResponsive?: boolean;
  isActive?: boolean;
  bgImageURL: string;
  bgHeight?: number;
  bgWidth?: number;
  bgX?: number;
  bgY?: number;
  style?: EmotionStyle;
  ariaLabel?: string;
  ariaHidden?: boolean;
  onCheckAnimationEnd?: () => void;
  isAnimatingCheckmark?: boolean;
}

const GraphicCardWrapper = styled(View)<GraphicCardProps>(
  ({
    height = 262,
    width = 375,
    heightResponsive = 300,
    widthResponsive = 260,
    bgImageURL,
    bgHeight = 169,
    bgWidth = 188,
    bgX = 149,
    bgY = 103,
    isResponsive,
    theme: { colors },
  }) => {
    return {
      borderRadius: 14,
      backgroundColor: colors.tropicalGreen,
      width: isResponsive ? widthResponsive : width,
      height: isResponsive ? heightResponsive : height,
      backgroundImage: `url(${bgImageURL})`,
      backgroundRepeat: 'no-repeat',
      backgroundPositionX: bgX,
      backgroundPositionY: bgY,
      backgroundSize: `${bgWidth}px ${bgHeight}px `,
      boxShadow: '0 18px 35px -6px rgba(0,41,13,0.17)',
      position: 'relative',
    };
  }
);

const Check: FunctionComponent<{ onAnimationEnd?: () => void }> = ({ onAnimationEnd }) => (
  <Lottie
    options={{
      loop: false,
      autoplay: true,
      animationData: checkmark,
    }}
    eventListeners={[
      {
        eventName: 'complete',
        callback: () => onAnimationEnd && onAnimationEnd(),
      },
    ]}
  />
);

const CheckmarkWrapper = styled(View)({
  height: 96,
  width: 96,
  pointerEvents: 'none',
  backgroundColor: 'transparent',
  ...webOnlyStyle({
    position: 'absolute',
    transform: 'translateX(50%) translateY(-50%)',
    top: 0,
    right: 0,
  }),
});

const CheckmarkPositioningWrapper = styled(View)({
  padding: 13,
  position: 'relative',
});

const CircledCheckMarkWrapper = styled(CircledCheckMark)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  // transform: 'translateX(-50%) translateY(-50%)',
});

const GraphicCard: FunctionComponent<GraphicCardProps> = ({
  children,
  isActive,
  imageAlignItems,
  ariaLabel,
  ariaHidden,
  onCheckAnimationEnd,
  isAnimatingCheckmark = true,
  ...otherProps
}) => (
  <CheckmarkPositioningWrapper style={{ alignItems: imageAlignItems }}>
    <GraphicCardWrapper
      aria-hidden={ariaHidden ? 'true' : 'false'}
      // the image is provided as background image. typically we would want to add a
      // descriptive aria-label to give context to SR uses but when the image is purely
      // decorative there is no need. It's situational-dependent
      role={ariaLabel ? 'img' : undefined}
      aria-label={ariaLabel}
      {...otherProps}
    >
      {isActive && (
        <CheckmarkWrapper>
          {isAnimatingCheckmark ? (
            <Check onAnimationEnd={onCheckAnimationEnd} />
          ) : (
            <CircledCheckMarkWrapper
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translateX(-50%) translateY(-50%)',
              }}
            />
          )}
        </CheckmarkWrapper>
      )}
      {children}
    </GraphicCardWrapper>
  </CheckmarkPositioningWrapper>
);

export default GraphicCard;
