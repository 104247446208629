import { withVersioning } from '../../utils';
import { VersionMap } from '../../types';
import CardContent from './CardContent';
import { CardContentProps, CardContentVariant } from './types';

const versionMap: VersionMap<React.ComponentProps<typeof CardContent>> = {
  DefaultComponent: CardContent,
};

export type { CardContentProps, CardContentVariant };

export default withVersioning(versionMap);
