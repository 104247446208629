import Svg, { Circle, Path, Rect, Ellipse } from 'svgs';

const SparklingInsuranceCard = () => {
  const titleText = 'Sparkling Calendar';
  return (
    <Svg
      title={titleText}
      width="153"
      height="130"
      viewBox="0 0 153 130"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Circle cx="78" cy="65" r="65" fill="#EFF3FA" />
      <Path
        d="M34.6302 82.1928C35.8815 81.6093 37.369 82.1507 37.9525 83.402C38.536 84.6534 37.9946 86.1409 36.7433 86.7244L27.6802 90.9505C26.4288 91.5341 24.9414 90.9927 24.3579 89.7413C23.7744 88.49 24.3157 87.0025 25.5671 86.419L34.6302 82.1928Z"
        fill="#C0E3E0"
      />
      <Path
        d="M35.534 90.0467C36.1176 91.298 35.5762 92.7855 34.3248 93.369C33.0735 93.9525 31.586 93.4111 31.0025 92.1598L26.7763 83.0967C26.1928 81.8453 26.7342 80.3579 27.9855 79.7744C29.2369 79.1909 30.7243 79.7323 31.3079 80.9836L35.534 90.0467Z"
        fill="#C0E3E0"
      />
      <Circle cx="145.5" cy="23.5" r="4.5" stroke="#ADE953" stroke-width="5" />
      <Circle cx="11" cy="42" r="8" stroke="#BCCCFF" stroke-width="6" />
      <Path
        d="M55.1171 38.1425L85.2807 37.0892C89.5888 36.9387 93.2033 40.309 93.354 44.6171L94.4095 74.7835C94.5603 79.092 91.1896 82.7069 86.881 82.8574L56.7153 83.9108C52.407 84.0612 48.7924 80.6906 48.642 76.3823L47.5885 46.2158C47.4381 41.9075 50.8087 38.2929 55.1171 38.1425Z"
        fill="white"
        stroke="#BECBE0"
        stroke-width="5.2038"
      />
      <Rect
        x="61.2297"
        y="42.8036"
        width="62"
        height="42"
        rx="5"
        transform="rotate(6.22624 61.2297 42.8036)"
        fill="#8EA8FD"
      />
      <Ellipse
        cx="99.9179"
        cy="89.2737"
        rx="13.5"
        ry="13"
        transform="rotate(6.22624 99.9179 89.2737)"
        fill="white"
      />
      <Path
        d="M117.634 48.9573L66.4598 43.3743C65.0403 43.2512 63.6296 43.6967 62.5378 44.6125C61.4456 45.5283 60.7618 46.8397 60.6356 48.2593L57.2033 79.7201C57.0204 81.1336 57.4053 82.5615 58.2744 83.6913C59.143 84.8209 60.4244 85.5602 61.8371 85.7461L82.4739 87.9975C83.0061 88.0556 83.4839 87.6714 83.5419 87.1396C83.5999 86.6078 83.2162 86.1296 82.684 86.0715L62.0472 83.8201C61.1458 83.6887 60.3328 83.2059 59.7859 82.4771C59.2394 81.7484 59.003 80.8326 59.1293 79.9301L62.5617 48.4693C62.6329 47.5609 63.0612 46.7176 63.752 46.1239C64.4432 45.5302 65.3412 45.234 66.2497 45.3001L117.424 50.8831C118.325 51.0145 119.138 51.4973 119.685 52.2261C120.232 52.9548 120.468 53.8706 120.342 54.7731L116.909 86.2339C116.851 86.7661 117.236 87.2439 117.767 87.3019C118.299 87.3599 118.777 86.9762 118.835 86.444L122.268 54.9832C122.451 53.5697 122.066 52.1418 121.197 51.012C120.328 49.8823 119.047 49.143 117.634 48.9572L117.634 48.9573Z"
        fill="#3B54C6"
      />
      <Path
        d="M105.441 57.3717C105.973 57.4297 106.451 57.0456 106.509 56.5138C106.567 55.982 106.183 55.5037 105.651 55.4457L90.2435 53.7647C89.7117 53.7067 89.2335 54.0909 89.1755 54.6227C89.1174 55.1545 89.5016 55.6327 90.0334 55.6907L105.441 57.3717Z"
        fill="#3B54C6"
      />
      <Path
        d="M115.071 58.4223C115.603 58.4803 116.081 58.0961 116.139 57.5643C116.197 57.0326 115.813 56.5543 115.281 56.4963L109.503 55.8659C108.971 55.8079 108.493 56.1921 108.435 56.7239C108.377 57.2557 108.761 57.7339 109.293 57.7919L115.071 58.4223Z"
        fill="#3B54C6"
      />
      <Path
        d="M64.61 74.3551C64.0782 74.297 63.5999 74.6812 63.5419 75.213C63.4839 75.7448 63.8681 76.223 64.3998 76.2811L72.1038 77.1215C72.6356 77.1796 73.1139 76.7954 73.1719 76.2636C73.2299 75.7318 72.8457 75.2536 72.3139 75.1956L64.61 74.3551Z"
        fill="#3B54C6"
      />
      <Path
        d="M85.7959 76.6665L78.0919 75.826C77.5601 75.768 77.0819 76.1521 77.0238 76.6839C76.9658 77.2157 77.35 77.694 77.8818 77.752L85.5857 78.5925C86.1175 78.6505 86.5958 78.2663 86.6538 77.7346C86.7118 77.2028 86.3277 76.7245 85.7959 76.6665Z"
        fill="#3B54C6"
      />
      <Path
        d="M101.624 74.4955C97.7932 74.0775 93.9528 75.1985 90.9483 77.6118C87.944 80.0251 86.0212 83.5335 85.6033 87.3645C85.1853 91.1955 86.3063 95.0358 88.7196 98.0403C91.1329 101.045 94.6413 102.967 98.4723 103.385C102.303 103.803 106.144 102.682 109.148 100.269C112.152 97.8557 114.075 94.3473 114.493 90.5163C114.911 86.6854 113.79 82.845 111.377 79.8405C108.964 76.8362 105.455 74.9134 101.624 74.4955ZM98.6824 101.459C95.9505 101.164 93.3915 99.9773 91.4013 98.0826C91.818 95.487 93.5438 93.2896 95.9672 92.2703C98.3905 91.2514 101.169 91.5545 103.315 93.0719C105.462 94.5899 106.673 97.1077 106.52 99.7321C104.167 101.151 101.414 101.758 98.6824 101.459ZM96.6092 85.5839C96.718 84.5866 97.2184 83.6732 98.0008 83.0447C98.7828 82.4166 99.7826 82.1245 100.78 82.2333C101.777 82.3421 102.691 82.8429 103.319 83.6249C103.947 84.4073 104.239 85.4071 104.13 86.4045C104.021 87.4018 103.521 88.3148 102.739 88.9433C101.957 89.5714 100.957 89.8634 99.9594 89.7546C98.9621 89.6458 98.0487 89.1451 97.4206 88.3631C96.7921 87.5806 96.5004 86.5813 96.6092 85.5839ZM108.432 98.329C108.114 95.1459 106.228 92.3318 103.404 90.8274C104.753 89.9792 105.687 88.6067 105.981 87.0406C106.275 85.4743 105.902 83.8567 104.952 82.5773C104.003 81.2975 102.563 80.4717 100.979 80.2989C99.3949 80.1261 97.8108 80.6219 96.6079 81.6669C95.4047 82.7116 94.6921 84.2108 94.6415 85.8035C94.5908 87.3963 95.2066 88.9377 96.3409 90.0568C93.2589 90.9159 90.8101 93.2577 89.8132 96.2977C87.9026 93.6501 87.0952 90.3641 87.5609 87.1326C88.0266 83.9011 89.729 80.9769 92.3094 78.9762C94.8894 76.976 98.1459 76.0558 101.391 76.4098C104.637 76.7639 107.618 78.3647 109.706 80.8741C111.794 83.3841 112.826 86.6067 112.584 89.8626C112.342 93.1185 110.845 96.1531 108.408 98.3264L108.432 98.329Z"
        fill="#3B54C6"
      />
      <Path
        d="M75.4565 68.7172L71.6045 68.297C71.0989 68.2296 70.6388 67.9697 70.3193 67.5721C69.9999 67.1744 69.8454 66.669 69.8886 66.1609L70.2563 62.7904L66.8858 62.4227C66.3803 62.3553 65.9201 62.0954 65.6007 61.6978C65.2813 61.3001 65.1267 60.7947 65.17 60.2865L65.5902 56.4346C65.6588 55.9296 65.9186 55.4698 66.3162 55.1509C66.7135 54.8314 67.2184 54.6768 67.7263 54.7187L71.0968 55.0864L71.4645 51.7159C71.5331 51.211 71.7929 50.7512 72.1905 50.4323C72.5878 50.1128 73.0927 49.9582 73.6006 50.0001L77.4526 50.4204C77.9575 50.4889 78.4173 50.7488 78.7363 51.1464C79.0557 51.5436 79.2103 52.0486 79.1684 52.5564L78.8007 55.9269L82.1712 56.2946C82.6762 56.3632 83.1359 56.623 83.4549 57.0207C83.7743 57.4179 83.9289 57.9229 83.887 58.4307L83.4668 62.2827C83.3982 62.7877 83.1383 63.2474 82.7407 63.5664C82.3435 63.8859 81.8386 64.0405 81.3307 63.9985L77.9602 63.6308L77.5925 67.0013C77.5252 67.5069 77.2653 67.967 76.8676 68.2865C76.4699 68.6059 75.9646 68.7604 75.4564 68.7172L75.4565 68.7172ZM76.1394 62.4577C76.1974 61.926 76.6756 61.5418 77.2074 61.5998L81.5409 62.0726L81.9612 58.2206L77.6277 57.7478C77.0959 57.6898 76.7117 57.2116 76.7697 56.6798L77.2425 52.3463L73.3905 51.926L72.9178 56.2595C72.8899 56.5149 72.7617 56.7489 72.5614 56.9097C72.3612 57.0706 72.1051 57.1453 71.8497 57.1175L67.5162 56.6447L67.096 60.4967L71.4295 60.9694C71.6848 60.9973 71.9188 61.1255 72.0797 61.3258C72.2405 61.526 72.3152 61.7821 72.2874 62.0375L71.8146 66.371L75.6666 66.7912L76.1394 62.4577Z"
        fill="#3B54C6"
      />
    </Svg>
  );
};

export default SparklingInsuranceCard;
