import { FunctionComponent } from 'react';
import { View, Spinner } from '@talkspace/react-toolkit';

const LoadingScreen: FunctionComponent<{}> = () => (
  <View style={{ height: '100vh' }}>
    <View flex={1}>
      <Spinner isLoading />
    </View>
  </View>
);

export default LoadingScreen;
