import {
  View,
  SparklingOkayHand,
  ExtraHuge,
  Large,
  styled,
  Button,
} from '@talkspace/react-toolkit';
import { useEffect } from 'react';
import { useRouteMatch } from '@/core/routerLib';
import useMutationSendToQueue from '../../hooks/useMutationSendToQueue';

const HeadlineText = styled(ExtraHuge)(({ theme: { colors } }) => {
  return {
    fontSize: 28,
    color: colors.permaEden,
    textAlign: 'center',
    marginTop: 75,
  };
});

const SubTitle = styled(Large)(({ theme: { colors } }) => {
  return {
    textAlign: 'center',
    marginTop: 16,
    color: colors.permaCometGray,
  };
});

interface Props {
  onSuccess: () => void;
}
const OnboardingMatchQueueConfirmation = ({ onSuccess }: Props) => {
  const {
    params: { roomID },
  } = useRouteMatch<{ roomID: string }>();

  const {
    mutate: sendToQueue,
    isLoading: isSendToQueueLoading,
    isSuccess: isSendToQueueSuccess,
  } = useMutationSendToQueue();

  const handleContinuePress = () => {
    sendToQueue({ roomID });
  };

  useEffect(() => {
    if (isSendToQueueSuccess) {
      onSuccess();
    }
  }, [isSendToQueueSuccess, onSuccess]);

  return (
    <View align="center">
      <SparklingOkayHand />
      <View>
        <HeadlineText variant="extraHugeBranded">
          You’re all done! We’re working on finding you a provider.
        </HeadlineText>
        <SubTitle>
          This process typically takes a few days. You’ll receive an email when you’re matched.
        </SubTitle>
      </View>
      <Button
        isLoading={isSendToQueueLoading}
        disabled={isSendToQueueLoading || isSendToQueueSuccess}
        onPress={handleContinuePress}
        style={{ marginTop: 20 }}
        text="Continue"
        dataQa="matchQueueContinuePress"
      />
    </View>
  );
};

export default OnboardingMatchQueueConfirmation;
