import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DayPickerSingleDateController, DayPickerSingleDateControllerShape } from 'react-dates';
import DatePickersCSSWrapper from './DatePickersCSSWrapper';
import useDatePicker, { BaseDatePickerProps, defaultAriaLabelFormat } from './useDatePicker';
import { CaretLeft, CaretRight } from '../../../components/icons';
import { TimeslotByDay } from '../../../../../../shared/inRoomScheduling/types';

// Cannot use `interface` herr because `DayPickerSingleDateControllerShape` is also a `type`
export type TimeslotCalendarProps = Omit<
  DayPickerSingleDateControllerShape,
  'renderMonthText' | 'renderMonthElement'
> &
  BaseDatePickerProps & { timeslots?: TimeslotByDay[] };

type DefaultTimeslotProps = NonNullable<
  Pick<
    TimeslotCalendarProps,
    'isSingle' | 'focused' | 'onFocusChange' | 'noBorder' | 'selectHighlighted' | 'phrases'
  >
>;

const TimeslotsCalendar = (props: Omit<TimeslotCalendarProps, keyof DefaultTimeslotProps>) => {
  const {
    containerStyle,
    isDayHighlighted,
    dayAriaLabelFormat = defaultAriaLabelFormat,
    minDate = moment().startOf('day'),
    maxDate = moment().add(4, 'months').endOf('day'),
    primaryColor,
    secondaryColor,
    timeslots,
  } = props;
  // Adds some default props for the Timeslots Calendar
  const timeslotProps: DefaultTimeslotProps = {
    focused: true,
    noBorder: true,
    isSingle: true,
    selectHighlighted: true,
    phrases: {
      chooseAvailableDate: ({ date }) =>
        isDayHighlighted?.(moment(date, dayAriaLabelFormat))
          ? `Available timeslots: ${date}`
          : `No Available timeslots: ${date}`,
      dateIsSelected: ({ date }) =>
        isDayHighlighted?.(moment(date, dayAriaLabelFormat))
          ? `Available timeslots. Selected: ${date}`
          : `No Available timeslots. Selected: ${date}`,
      dateIsUnavailable: ({ date }) => `Disabled: ${date}`,
    },
    onFocusChange: () => undefined,
  };

  const nonDefaultProps = { minDate, maxDate };

  const finalProps: TimeslotCalendarProps = {
    ...timeslotProps,
    ...props,
    ...nonDefaultProps,
    secondaryColor,
    primaryColor,
  };
  const {
    state: { containerHeight, topOffset },
    dateProps,
  } = useDatePicker({ ...finalProps, timeslots });
  return (
    <DatePickersCSSWrapper
      containerStyle={containerStyle}
      topOffset={topOffset}
      height={containerHeight}
      isCustomNav
    >
      <DayPickerSingleDateController
        {...finalProps}
        {...dateProps}
        navPrev={<CaretLeft width={11} height={19} data-qa="calendarPrevButton" />}
        navNext={<CaretRight width={11} height={19} data-qa="calendarNextButton" />}
      />
    </DatePickersCSSWrapper>
  );
};

export default TimeslotsCalendar;
