import styled from '@emotion/styled';
import commonStyles, { COLORS } from '../../constants/commonStyles';
import Lock from '../Svgs/Lock';
import ErrorIcon from '../Svgs/ErrorIcon';
import './textInputStyles.css';

const { regular } = commonStyles.general.text;

const TextInputWrapper = styled.div(({ placeholderUp }) => {
  return {
    transition: 'margin-top .2s',
    marginTop: placeholderUp ? 11 : 0,
    position: 'relative',
    width: '100%',
    boxSizing: 'content-box',
  };
});

const upStyles = {
  top: 2,
  fontSize: 12,
  color: COLORS.placeholderGrey,
};

const Placeholder = styled.div(({ placeholderUp }) => {
  return {
    ...regular,
    position: 'absolute',
    transition: 'all .25s',
    top: 24,
    color: COLORS.placeholderGrey,
    pointerEvents: 'none',
    ...(placeholderUp ? upStyles : {}),
  };
});

const textInputStyles = {
  ...regular,
  width: '100%',
  height: 34,
  marginTop: 16,
  borderWidth: 0,
  borderBottomWidth: 1,
  paddingLeft: 16,
  borderColor: COLORS.extraLightGrey,
  WebkitTextFillColor: COLORS.black,
  outline: 0,
  boxShadow: 'none',
  outlineColor: 'transparent',
  outlineStyle: 'none',
  backgroundColor: 'transparent',
  padding: 0,
  WebkitAppearance: 'none',
  WebkitTapHighlightColor: 'rgba(0,0,0,0)',
};

const LockIconWrapper = styled.div({
  position: 'absolute',
  right: 4,
  top: 23,
  display: 'none',
});

function TextInputView({
  assignRef,
  placeholderUp,
  placeholder,
  disabled,
  isValid,
  hidden,
  style = {},
  dataQa,
  placeHolderStyle,
  ...otherProps
}) {
  return (
    <TextInputWrapper placeholderUp={placeholderUp} hidden={hidden} className="has-lock-icon">
      <Placeholder style={placeHolderStyle} placeholderUp={placeholderUp}>
        {placeholder}
      </Placeholder>
      <input
        data-qa={dataQa}
        style={{
          ...textInputStyles,
          ...style,
        }}
        type="text"
        ref={assignRef}
        disabled={disabled}
        {...otherProps}
      />
      {disabled && (
        <LockIconWrapper className="lock-icon">
          <Lock color={COLORS.placeholderGrey} />
        </LockIconWrapper>
      )}
      <ErrorIcon isValid={isValid} />
    </TextInputWrapper>
  );
}

export default TextInputView;
