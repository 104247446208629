import { FunctionComponent } from 'react';
import { ExtraBig, View, ExtraHuge, Spinner } from '@talkspace/react-toolkit';

interface Props {
  stepAdditionalInfo?: Record<string, unknown>;
  dataQa?: string;
}

const CreditOffer: FunctionComponent<Props> = ({ stepAdditionalInfo, dataQa }) => (
  <View style={{ marginBottom: 35, alignSelf: 'center', textAlign: 'center' }}>
    {stepAdditionalInfo?.couponDiscountDisplay ? (
      <>
        <ExtraBig>We’d like to offer you a</ExtraBig>
        <ExtraHuge variant="extraHugeBrightGreen" dataQa={dataQa}>
          {stepAdditionalInfo.couponDiscountDisplay} credit
        </ExtraHuge>
        <ExtraBig>towards your next renewal</ExtraBig>
      </>
    ) : (
      <Spinner />
    )}
  </View>
);

export default CreditOffer;
