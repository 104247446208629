/* eslint-disable import/prefer-default-export */
import { useState, useEffect } from 'react';
import { getClientSideGlobal, getIsSSR } from '../utils/ssr';

/**
 * SSR content doesn't have access to the `window` or `document.
 * This hook makes it so that the first render of both the server and the client to be equal
 * by returning the default value on both first server render, and first client render.
 * This is required for Hydration to work properly.
 * @example
 * const innerWidth = useClientSideGlobal((w) => w.innerWidth, 0);
 * const path = useClientSideGlobal((w) => w.location.pathname, '');
 */
export const useClientSideGlobal = <T>(
  globalAccessor: (w: typeof globalThis) => T,
  defaultValue: T
) => {
  const [shouldUseDefaultValue, setShouldUseDefaultValue] = useState(getIsSSR);

  useEffect(() => {
    setShouldUseDefaultValue(false);
  }, []);

  return shouldUseDefaultValue ? defaultValue : getClientSideGlobal(globalAccessor, defaultValue);
};
