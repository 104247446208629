import { ChangeEvent, FunctionComponent, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import {
  AccordionContainer,
  useEmotionTheme,
  styled,
  Standard,
  TouchableView,
  Select,
  View,
  OptionType,
} from '@talkspace/react-toolkit';
import * as emergencyContactActions from 'actions/EmergencyContactActions';
import useQueryBusinessLine from 'ts-frontend/hooks/useQueryBusinessLine';
import { components, OptionProps } from 'react-select';
import { useRouteMatch } from '@/core/routerLib';
import {
  useProgressNoteFormActions,
  useProgressNoteFormState,
} from '../context/PsychProgressNoteFormContext';
import { ProgressNoteSessionAttendeeCreationAttributes, SessionService } from '../../types';
import { ProgressNoteFormState } from '../context/types';
import { getSelectStyle, updateObjectInArray, filterSessionService } from '../../utils';
import ConfirmButton from './ConfirmButton';
import ContactNameWithRelationship from '../../ContactNameWithRelationship';
import useQuerySessionServices from '../../../../../../hooks/useQuerySessionServices';
import useQueryProgressNote from '../../../../../../hooks/notes/useQueryProgressNote';
import useDidUpdateEffect from '../../../../../../hooks/useDidUpdateEffect';

interface SessionServicesOptionType extends OptionType {
  recommended: boolean;
  recommendedText?: string;
  isSelectable: boolean;
}

const generateSessionServiceOptions = (
  sessionServices: Array<SessionService> | undefined
): Array<SessionServicesOptionType> => {
  if (!sessionServices) {
    return [];
  }
  return sessionServices.map((it) => {
    return {
      value: it.id.toString(),
      label: it.name,
      recommended: it.recommended,
      recommendedText: it.recommendedText || undefined,
      isSelectable: it.isSelectable,
    };
  });
};

const sortSessionAttendees = (
  sessionAttendees: ProgressNoteSessionAttendeeCreationAttributes[]
) => {
  const identifiedClients = sessionAttendees.filter(
    (attendee) => attendee.relationshipToClient === 'Identified client'
  );
  const restOfAttendees = sessionAttendees.filter(
    (attendee) => attendee.relationshipToClient !== 'Identified client'
  );
  return [...identifiedClients, ...restOfAttendees];
};

const CPTSelectContainer = styled(View)({
  paddingBottom: 20,
});

const RecommendedText = styled(Standard)(({ theme: { colors } }) => {
  return {
    fontWeight: 700,
    letterSpacing: 0.3,
    paddingBottom: 5,
    color: colors.pantoneBlue,
  };
});

interface StoreProps {
  clientUserID: number;
  emergencyContactByClientUserID: Record<number, any>;
  getEmergencyContact: (clientUserID: number) => void;
}

const SessionInformationSection: FunctionComponent<StoreProps> = ({
  clientUserID,
  emergencyContactByClientUserID,
  getEmergencyContact,
}) => {
  const {
    formMode,
    sessionAttendees: globalSessionAttendees,
    sessionServiceID,
    formSections,
    modalityID,
  } = useProgressNoteFormState();
  const [sessionAttendees, setSessionAttendees] =
    useState<ProgressNoteFormState['sessionAttendees']>(globalSessionAttendees);
  const { setFormState } = useProgressNoteFormActions();
  const [attendeeNameErrors, setAttendeeNameErrors] = useState<Array<boolean>>([]);
  const [relationshipToClientErrors, setRelationshipToClientErrors] = useState<Array<boolean>>([]);
  const [sessionServiceError, setSessionServiceError] = useState(false);
  const [sessionServiceOptions, setSessionServicesOptions] = useState<
    Array<SessionServicesOptionType>
  >([]);
  const { colors } = useEmotionTheme();
  const match = useRouteMatch<{ roomID: string; noteID?: string }>();
  const { data: progressNoteData } = useQueryProgressNote(clientUserID, match.params.noteID);
  const noteRoomID = progressNoteData?.roomID?.toString() || match.params.roomID;
  const sortedSessionAttendees = useMemo(
    () => sortSessionAttendees(sessionAttendees),
    [sessionAttendees]
  );

  const { data: businessLineData } = useQueryBusinessLine(noteRoomID, clientUserID);
  const { data: sessionServices = [] } = useQuerySessionServices(noteRoomID, clientUserID);

  const hasSessionService = businessLineData?.isBH || businessLineData?.isPsychiatry;

  useEffect(() => {
    if (formMode === 'create') {
      const emergencyContactInfo = emergencyContactByClientUserID[clientUserID];
      if (!emergencyContactInfo) {
        getEmergencyContact(clientUserID);
      } else if (emergencyContactInfo.firstName || emergencyContactInfo.lastName) {
        const fullName = `${emergencyContactInfo.firstName || ''} ${
          emergencyContactInfo.lastName || ''
        }`.trim();
        setSessionAttendees([
          { attendeeName: fullName, relationshipToClient: 'Identified client', id: 0 },
        ]);
      }
    }
  }, [clientUserID, emergencyContactByClientUserID, formMode, getEmergencyContact]);

  useEffect(() => {
    if (hasSessionService) {
      const filteredSessions = filterSessionService(modalityID, sessionServices);
      setSessionServicesOptions(generateSessionServiceOptions(filteredSessions));
    }
  }, [hasSessionService, modalityID, sessionServices, sessionServices.length]);

  useEffect(() => {
    if (sessionServiceOptions?.length > 0 && !progressNoteData?.sessionServiceID) {
      const recommendedSession = sessionServiceOptions.find((item) => item.recommended);
      if (recommendedSession) {
        setFormState({ sessionServiceID: Number(recommendedSession.value) });
      }
    }
  }, [
    sessionServiceOptions,
    sessionServiceOptions.length,
    setFormState,
    progressNoteData?.sessionServiceID,
  ]);

  const markSectionAsIncomplete = () => {
    if (formSections.sessionInformation.completed) {
      setFormState({
        formSections: {
          ...formSections,
          sessionInformation: {
            open: true,
            changed: true,
            completed: false,
          },
        },
      });
    }
  };

  useDidUpdateEffect(() => {
    markSectionAsIncomplete();
    setFormState({ sessionServiceID: null });
  }, [modalityID]);

  const SelectOption = (props: OptionProps<OptionType, false>) => {
    const { data } = props;
    return (
      <components.Option {...props}>
        <View style={{ padding: 11 }}>
          {data.recommended && <RecommendedText>{data.recommendedText}</RecommendedText>}
          <View style={{ maxWidth: 250 }}>{data.label}</View>
        </View>
      </components.Option>
    );
  };

  const handleTextFieldChange = (
    index: number,
    field: keyof ProgressNoteSessionAttendeeCreationAttributes,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    markSectionAsIncomplete();

    if (field === 'attendeeName' && attendeeNameErrors[index]) {
      const copy = [...attendeeNameErrors];
      copy[index] = false;
      setAttendeeNameErrors(copy);
    }

    if (field === 'relationshipToClient' && relationshipToClientErrors[index]) {
      const copy = [...relationshipToClientErrors];
      copy[index] = false;
      setRelationshipToClientErrors(copy);
    }

    const updatedSessionAttendees =
      updateObjectInArray<ProgressNoteSessionAttendeeCreationAttributes>(sessionAttendees, index, {
        [field]: event.target.value,
      });

    setSessionAttendees(updatedSessionAttendees);
  };

  const handleAddAnotherPersonPress = () => {
    markSectionAsIncomplete();
    setSessionAttendees([
      ...sessionAttendees,
      { attendeeName: null, relationshipToClient: null, id: Date.now() },
    ]);
  };

  const handleRemovePersonPress = (index: number) => {
    markSectionAsIncomplete();
    setSessionAttendees(sessionAttendees.filter((_, i) => i !== index));
  };

  const handleCPTCodeChange = (option: { value: string; label: string } | null) => {
    markSectionAsIncomplete();
    setSessionServiceError(false);
    const newValue = option ? Number(option.value) : null;
    setFormState({ sessionServiceID: newValue });
  };

  const handleAccordionPress = (value: boolean) => {
    setFormState({
      formSections: {
        ...formSections,
        sessionInformation: {
          open: value,
          changed: formSections.sessionInformation.changed,
          completed: formSections.sessionInformation.completed,
        },
      },
    });
  };

  const handleConfirmPress = () => {
    let isError = false;

    const newAttendeeNameErrors = sessionAttendees.map((it) => !it.attendeeName);
    const newRelationshipToClientErrors = sessionAttendees.map((it) => !it.relationshipToClient);

    if (newAttendeeNameErrors.some((it) => it) || newRelationshipToClientErrors.some((it) => it)) {
      setAttendeeNameErrors(newAttendeeNameErrors);
      setRelationshipToClientErrors(newRelationshipToClientErrors);
      isError = true;
    }

    if (hasSessionService && !sessionServiceID) {
      setSessionServiceError(true);
      isError = true;
    }

    if (isError) {
      setFormState({ showGlobalError: true });
      return;
    }

    setFormState({
      sessionAttendees,
      formSections: {
        ...formSections,
        sessionInformation: {
          open: false,
          changed: true,
          completed: true,
        },
        ...(formSections.diagnosis.completed
          ? {}
          : {
              diagnosis: {
                ...formSections.diagnosis,
                open: true,
              },
            }),
      },
    });
  };

  return (
    <AccordionContainer
      title="Session information"
      open={formSections.sessionInformation.open}
      onPress={handleAccordionPress}
      showCheckMark={formMode !== 'view' && formSections.sessionInformation.completed}
      bodyStyle={{ paddingTop: 0 }}
      dataQa="sessionInformationAccordion"
    >
      {sortedSessionAttendees.map((attendee, index) => (
        <ContactNameWithRelationship
          key={attendee.id}
          namePlaceholder="Name of person attending the session"
          nameError={attendeeNameErrors[index]}
          nameValue={attendee.attendeeName || ''}
          onNameChange={(event) => handleTextFieldChange(index, 'attendeeName', event)}
          disabled={formMode === 'view'}
          relationshipError={relationshipToClientErrors[index]}
          relationshipValue={attendee.relationshipToClient || ''}
          onRelationshipChange={(event) =>
            handleTextFieldChange(index, 'relationshipToClient', event)
          }
          showRemove={formMode !== 'view' && index !== 0 && sessionAttendees.length > 1}
          onRemovePress={() => handleRemovePersonPress(index)}
        />
      ))}
      {!businessLineData?.isPsychiatry && formMode !== 'view' && (
        <TouchableView style={{ marginBottom: 40 }}>
          <Standard
            variant="standardBoldGreen"
            onPress={handleAddAnotherPersonPress}
            dataQa="addAnotherPersonButton"
          >
            + Add another person
          </Standard>
        </TouchableView>
      )}
      {hasSessionService && (
        <CPTSelectContainer>
          <Select
            key={`key__${sessionServiceID}`}
            value={
              sessionServiceOptions.find((it) => it.value === sessionServiceID?.toString()) ||
              undefined
            }
            placeholder="CPT code"
            onChange={handleCPTCodeChange as any}
            components={{ Option: SelectOption }}
            options={sessionServiceOptions.filter((it) => it.isSelectable)}
            styles={getSelectStyle(colors, sessionServiceError)}
            isDisabled={formMode === 'view' || progressNoteData?.status === 'submitted'}
            dataQa="cptDropdown"
          />
        </CPTSelectContainer>
      )}
      {formMode !== 'view' && !formSections.sessionInformation.completed && (
        <ConfirmButton onPress={handleConfirmPress} sectionTitle="sessionInformation" />
      )}
    </AccordionContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    clientUserID: state.room.clientUserID,
    emergencyContactByClientUserID: state.emergencyContact.emergencyContactByClientUserID,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEmergencyContact: (clientUserID: number) =>
      dispatch(emergencyContactActions.getEmergencyContact(clientUserID)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionInformationSection);
