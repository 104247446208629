import { AnimatedSwipeDownModal, useWindowWidthState, View } from '@talkspace/react-toolkit';
import { useMemo } from 'react';
import { ClaimableRoom, SuccessfulClaimClientInfo } from '../types';
import ModalContainer from './ModalContainer';
import SelectTimeslot from './SelectTimeslot';
import styled from '@/core/styled';

const ContentWrapper = styled(View)(
  ({
    theme: {
      window: { isDesktop },
    },
  }) => {
    return {
      overflow: !isDesktop ? 'scroll' : 'hidden',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      height: !isDesktop ? '70vh' : 600,
      width: '100%',
    };
  }
);

const Headline = styled(View)({
  textAlign: 'center',
  fontSize: 21,
  fontWeight: 500,
  paddingLeft: 20,
  paddingRight: 20,
});

interface BookAndClaimModalProps {
  onBackdropPress: () => void;
  hide: boolean;
  claimableRoom: ClaimableRoom;
  therapistID: number;
  confirmationFailure: (status: number) => void;
  confirmationSuccess: (data: SuccessfulClaimClientInfo) => void;
}

const Container = ({
  claimableRoom,
  therapistID,
  onBackdropPress,
  confirmationFailure,
  confirmationSuccess,
}: Omit<BookAndClaimModalProps, 'hide'>) => (
  <ContentWrapper>
    <Headline>Propose a first session based on client's preferred times</Headline>

    <SelectTimeslot
      onCancel={onBackdropPress}
      clientInformation={claimableRoom}
      isFromCheckInWizard={false}
      therapistID={therapistID}
      confirmationFailure={confirmationFailure}
      confirmationSuccess={confirmationSuccess}
    />
  </ContentWrapper>
);

const BookAndClaimModal = (props: BookAndClaimModalProps) => {
  const { onBackdropPress, hide } = props;
  const { isDesktop } = useWindowWidthState();
  const modalContainerStyle = useMemo(() => {
    return {
      width: !isDesktop ? undefined : 900,
      overflow: !isDesktop ? 'scroll' : 'hidden',
      height: 'fit-content',
      zIndex: 21,
    };
  }, [isDesktop]);
  return (
    <View>
      {!isDesktop ? (
        <AnimatedSwipeDownModal
          isOpen={!hide}
          onClose={onBackdropPress}
          shouldDisplayCloseButton
          containerStyles={{
            flexDirection: 'column',
            alignItems: 'flex-start',
            paddingLeft: 0,
            paddingRight: 0,
          }}
          isDraggable={false}
        >
          <Container {...props} />
        </AnimatedSwipeDownModal>
      ) : (
        <ModalContainer onBackdropPress={onBackdropPress} hide={hide} style={modalContainerStyle}>
          <Container {...props} />
        </ModalContainer>
      )}
    </View>
  );
};

export default BookAndClaimModal;
