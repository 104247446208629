import Svg, { Circle, Path } from 'svgs';

const SparklingEnvelope = () => {
  const titleText = 'Sparkling Envelope';
  return (
    <Svg title={titleText} width="153" height="130" viewBox="0 0 153 130" fill="none">
      <Circle cx="78" cy="65" r="65" fill="#EFF3FA" />
      <Path
        d="M34.6302 82.1928C35.8815 81.6093 37.369 82.1507 37.9525 83.402C38.536 84.6534 37.9946 86.1409 36.7433 86.7244L27.6802 90.9505C26.4288 91.5341 24.9414 90.9927 24.3579 89.7413C23.7744 88.49 24.3157 87.0025 25.5671 86.419L34.6302 82.1928Z"
        fill="#C0E3E0"
      />
      <Path
        d="M35.534 90.0467C36.1176 91.298 35.5762 92.7855 34.3248 93.369C33.0735 93.9525 31.586 93.4111 31.0025 92.1598L26.7763 83.0967C26.1928 81.8453 26.7342 80.3579 27.9855 79.7744C29.2369 79.1909 30.7243 79.7323 31.3079 80.9836L35.534 90.0467Z"
        fill="#C0E3E0"
      />
      <Circle cx="145.5" cy="23.5" r="4.5" stroke="#ADE953" strokeWidth="5" />
      <Circle cx="11" cy="42" r="8" stroke="#BCCCFF" strokeWidth="6" />
      <Path
        d="M47.5885 46.2157C47.4381 41.9074 50.8087 38.2928 55.1171 38.1424L85.2807 37.089C89.5888 36.9386 93.2033 40.3089 93.354 44.617L94.4095 74.7834C94.5603 79.0919 91.1896 82.7068 86.881 82.8573L56.7153 83.9107C52.407 84.0611 48.7924 80.6905 48.642 76.3821L47.5885 46.2157Z"
        fill="white"
        stroke="#BECBE0"
        strokeWidth="5.2038"
      />
      <Path
        d="M61.5458 57.1712C61.9266 52.1283 66.3234 48.349 71.3663 48.7298L110.173 51.6603C115.216 52.0411 118.995 56.4379 118.614 61.4807L116.581 88.3979C116.201 93.4408 111.804 97.2202 106.761 96.8393L67.9546 93.9089C62.9117 93.5281 59.1324 89.1313 59.5132 84.0884L61.5458 57.1712Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M71.2098 48.718C66.2533 48.3437 61.9319 52.0583 61.5576 57.0147L59.5013 84.2449C59.1271 89.2013 62.8416 93.5228 67.7981 93.8971L106.917 96.8512C111.874 97.2255 116.195 93.5109 116.57 88.5544L118.626 61.3243C119 56.3678 115.286 52.0464 110.329 51.6721L71.2098 48.718ZM73.8494 59.286C72.9418 58.3317 71.4324 58.2939 70.4781 59.2015C69.5238 60.1091 69.4859 61.6185 70.3935 62.5728L88.7663 81.8908L109.095 65.463C110.119 64.6352 110.279 63.1338 109.451 62.1095C108.623 61.0852 107.122 60.9258 106.098 61.7536L89.1904 75.4163L73.8494 59.286Z"
        fill="#5C84FB"
      />
    </Svg>
  );
};

export default SparklingEnvelope;
