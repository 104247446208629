import { View, useEmotionTheme, PlayVideoIcon, CloseRoundIcon } from '@talkspace/react-toolkit';
import { useState } from 'react';

import OnBoardingBanner from '../../Reusable/TutorialBanner/OnBoardingBanner';
import thumbnail from './assets/ionicOnBoarding.png';

const IONIC_ONBOARDING = 'ionic_onboarding';
export const IONIC_ONBOARDING_VIDEO_URL = 'https://vimeo.com/916863184/9048b7e3fa?share=copy';

const IonicOnboardingBar = () => {
  const { colors } = useEmotionTheme();
  const [isVideoModalOpen, setIsVideoModalOpen] = useState<boolean>(false);

  return (
    <View style={{ background: colors.permaGreyed }}>
      <OnBoardingBanner
        primaryButton={{
          title: 'Watch tutorial',
          callback: () => setIsVideoModalOpen(true),
          icon: <PlayVideoIcon color={colors.white} />,
        }}
        secondaryButton={{
          title: 'Dismiss',
          icon: <CloseRoundIcon />,
        }}
        placeholderImage={thumbnail}
        storageKey={IONIC_ONBOARDING}
        videoUrl={IONIC_ONBOARDING_VIDEO_URL}
        title="Welcome to the provider mobile app!"
        subtitle="A unified experience across your web browser and mobile app make it easier to manage your workload anytime, anywhere."
        isVideoModalOpen={isVideoModalOpen}
        onOpenVideoModal={() => setIsVideoModalOpen(true)}
        onCloseVideoModal={() => setIsVideoModalOpen(false)}
      />
    </View>
  );
};

export default IonicOnboardingBar;
