import Svg, { Path, Defs, G, ClipPath, Rect } from 'svgs';
import { useEmotionTheme } from '../../core/styled';
import type { EmotionStyle } from '../../core/styled';
import { useUniqueID } from '../../hooks/a11yHelper';

interface Props {
  width?: number;
  height?: number;
  color?: string;
  style?: EmotionStyle;
}

const ShieldOutlineIcon = ({ color, width = 24, height = 28, ...otherProps }: Props) => {
  const { colors } = useEmotionTheme();
  const title = 'Shield outline icon';
  const clipID0 = useUniqueID('clipID-0');
  const clipID1 = useUniqueID('clipID-1');
  return (
    <Svg
      width={width}
      height={height}
      title={title}
      aria-label={title}
      viewBox="0 0 24 24"
      fill="none"
      {...otherProps}
    >
      <G clipPath={`url(#${clipID0})`}>
        <G clipPath={`url(#${clipID1})`}>
          <Path
            d="M4.13674 6.45703L12 3.12266L19.8633 6.45703C20.1168 6.56445 20.2543 6.79219 20.25 7.00703C20.2328 10.9344 18.6 17.7191 12.2449 20.7613C12.0903 20.8344 11.9098 20.8344 11.7594 20.7613C5.40002 17.7148 3.7715 10.9344 3.75002 7.00273C3.75002 6.78789 3.88322 6.56445 4.13674 6.45273V6.45703ZM20.6668 4.55781L12.5758 1.12461C12.3953 1.04297 12.202 1 12 1C11.7981 1 11.6047 1.04297 11.4242 1.12461L3.33322 4.55781C2.38791 4.95742 1.68322 5.88984 1.68752 7.01562C1.709 11.2781 3.46213 19.077 10.8656 22.6219C11.5832 22.9656 12.4168 22.9656 13.1344 22.6219C20.5422 19.077 22.291 11.2781 22.3125 7.01562C22.3168 5.88984 21.6121 4.95742 20.6668 4.55781ZM10.9688 15.7812C10.9688 16.3527 11.4285 16.8125 12 16.8125C12.5715 16.8125 13.0313 16.3527 13.0313 15.7812V13.0312H15.7813C16.3528 13.0312 16.8125 12.5715 16.8125 12C16.8125 11.4285 16.3528 10.9687 15.7813 10.9687H13.0313V8.21875C13.0313 7.64727 12.5715 7.1875 12 7.1875C11.4285 7.1875 10.9688 7.64727 10.9688 8.21875V10.9687H8.21877C7.64729 10.9687 7.18752 11.4285 7.18752 12C7.18752 12.5715 7.64729 13.0312 8.21877 13.0312H10.9688V15.7812Z"
            fill={color || colors.permaFiord}
          />
        </G>
      </G>
      <Defs>
        <ClipPath id={clipID0}>
          <Rect width="24" height="24" fill="white" />
        </ClipPath>
        <ClipPath id={clipID1}>
          <Rect width="22" height="22" fill="white" transform="translate(1 1)" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export default ShieldOutlineIcon;
