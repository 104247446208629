/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FocusEventHandler, forwardRef, useEffect, MouseEventHandler } from 'react';
import styled, { getStyles, EmotionStyle, CustomizableComponentStyleProp } from '../../core/styled';
import View, { ViewProps } from '../View';
import { useShareForwardedRef } from '../../hooks/a11yHelper';

export interface TouchableViewProps extends ViewProps {
  hide?: boolean;
  disabled?: boolean;
  allowOnKeyDownPropagation?: boolean;
  /** activeOpacity is a react-native only prop. */
  activeOpacity?: number;
  tabIndex?: number;
  styles?: CustomizableComponentStyleProp<TouchableViewProps>;
  elementType?: string;
  onPress?: MouseEventHandler<HTMLDivElement>;
  onBlur?: FocusEventHandler<HTMLDivElement>;
  onFocus?: FocusEventHandler<HTMLDivElement>;
  shouldFocus?: boolean;
  role?: string;
  primaryColor?: string;
  roundedFocusStyle?: boolean;
  dataQa?: string;
  defaultStyles?: EmotionStyle;
}

const touchableViewDefaultStyles: EmotionStyle = {
  margin: 0,
  padding: 0,
  border: 'none',
  cursor: 'pointer',
  display: 'inherit',
  MozAppearance: 'none',
  textDecoration: 'none',
  WebkitAppearance: 'none',
  backgroundColor: 'transparent',
  // Ensures that momentary focus styling does not render after click
  '&:hover:focus': {
    boxShadow: 'none',
    border: 'none',
    outline: 'none',
  },
};

const TouchableViewComponent = styled(View)<{
  defaultStyles?: EmotionStyle;
}>(({ defaultStyles }) => {
  return {
    ...defaultStyles,
  };
});

const TouchableView = forwardRef<HTMLDivElement, TouchableViewProps>((props, ref) => {
  const touchableRef = useShareForwardedRef(ref);

  const {
    hide,
    role,
    style,
    onBlur,
    onPress,
    onFocus,
    disabled,
    children,
    tabIndex,
    className,
    elementType,
    shouldFocus,
    allowOnKeyDownPropagation,
    primaryColor,
    roundedFocusStyle,
    dataQa,
    ...otherProps
  } = props;

  // focuses TouchableView if specified by parent i.e. required checkbox was not checked on submit
  useEffect(() => {
    if (shouldFocus && touchableRef.current) {
      touchableRef.current.focus();
    }
  }, [shouldFocus, touchableRef]);

  if (hide) return null;

  // blurs the focus after mouse click when visible focus effect is not needed
  const handleClick: MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (touchableRef && touchableRef.current) {
      touchableRef.current.blur();
    }
    if (onPress && !disabled && typeof onPress === 'function') onPress(event);
  };

  // binds the enter and space keys to select the touchable element
  const onKeyDown = (e) => {
    if (props.onKeyDown) {
      props.onKeyDown(e);
      return;
    }
    if (!allowOnKeyDownPropagation) e.stopPropagation();
    if (
      (e.key === 'Enter' || e.key === ' ') &&
      touchableRef &&
      onPress &&
      !disabled &&
      typeof onPress === 'function'
    ) {
      onPress(e);
    }
  };
  let finalRole: string | undefined;
  if (role) {
    finalRole = role;
  } else if (elementType) {
    finalRole = undefined;
  } else {
    finalRole = 'button';
  }
  return (
    <TouchableViewComponent
      data-qa={dataQa}
      // @ts-ignore: `as` is a valid prop
      as={elementType}
      tabIndex={tabIndex || 0}
      role={finalRole}
      onFocus={onFocus}
      onBlur={onBlur}
      {...otherProps}
      style={style}
      className={className}
      onKeyDown={onKeyDown}
      ref={touchableRef}
      defaultStyles={getStyles<TouchableViewProps>(touchableViewDefaultStyles, props)}
      onClick={handleClick}
      primaryColor={primaryColor}
      roundedFocusStyle={roundedFocusStyle}
    >
      {children}
    </TouchableViewComponent>
  );
});

export default TouchableView;
