import { FunctionComponent, VoidFunctionComponent } from 'react';
import moment from 'moment';

import { styled, Tiny, useEmotionTheme } from '@talkspace/react-toolkit';
import { ChargeType, ClientTransaction, Currency } from 'ts-frontend/types';
import { formatCurrency } from 'ts-frontend/helpers/billingUtils';
import { chargeTypeNames } from '../../utils/constants';

interface PendingInvoicesTableProps {
  pendingInvoices: ClientTransaction[];
}

const StyledTable = styled.table({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px 0px',
  gap: 10,
  marginTop: 15,
});

const StyledTableRow = styled.tr({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '5px 0px',
  gap: 6,
  width: '100%',
});

const StyledHeading = styled(Tiny)(({ theme: { colors } }) => {
  return {
    color: colors.permaGrayLike,
    width: 80,
  };
});

const StyledTiny = styled(Tiny)(({ theme: { colors } }) => {
  return {
    color: colors.black,
    width: 80,
  };
});

const TableHeader = styled.th();
const TableData = styled.td();

interface TableRowItemProps {
  dateOfService?: string | null;
  chargeType: ChargeType;
  therapistFullName?: string | null;
  amount: number;
  currency: Currency;
}

const TableRowItem: VoidFunctionComponent<TableRowItemProps> = ({
  dateOfService,
  chargeType,
  therapistFullName,
  amount,
  currency,
}) => (
  <StyledTableRow>
    <TableData>
      <StyledTiny>{dateOfService ? moment(dateOfService).format('MM/DD/YY') : ''}</StyledTiny>
    </TableData>
    <TableData>
      <StyledTiny>{chargeTypeNames[chargeType]}</StyledTiny>
    </TableData>
    <TableData>
      <StyledTiny>{therapistFullName || ''}</StyledTiny>
    </TableData>
    <TableData>
      <StyledTiny style={{ width: 50, textAlign: 'right' }}>
        {formatCurrency(amount / 100, currency)}
      </StyledTiny>
    </TableData>
  </StyledTableRow>
);

interface PendingInvoiceProps {
  pendingInvoice: ClientTransaction;
}

const PendingInvoice: VoidFunctionComponent<PendingInvoiceProps> = ({
  pendingInvoice: {
    dateOfService,
    chargeType,
    therapistFullName,
    amount,
    currency,
    isAggregated,
    invoiceLineItems,
  },
}) => {
  if (isAggregated) {
    return (
      <>
        {invoiceLineItems.map((lineItem) => (
          <TableRowItem
            dateOfService={lineItem.dateOfService}
            chargeType={chargeType}
            therapistFullName={lineItem.therapistFullName}
            amount={lineItem.amount}
            currency={currency}
          />
        ))}
      </>
    );
  }

  return (
    <TableRowItem
      dateOfService={dateOfService}
      chargeType={chargeType}
      therapistFullName={therapistFullName}
      amount={amount}
      currency={currency}
    />
  );
};

const PendingInvoicesTable: FunctionComponent<PendingInvoicesTableProps> = ({
  pendingInvoices,
}) => {
  const { colors } = useEmotionTheme();

  return (
    <StyledTable>
      <StyledTableRow style={{ borderBottom: `0.5px solid ${colors.permaLondonGray}` }}>
        <TableHeader>
          <StyledHeading>Session date</StyledHeading>
        </TableHeader>
        <TableHeader>
          <StyledHeading>Charge type</StyledHeading>
        </TableHeader>
        <TableHeader>
          <StyledHeading>Provider</StyledHeading>
        </TableHeader>
        <TableHeader>
          <StyledHeading style={{ width: 50, textAlign: 'right' }}>Amount</StyledHeading>
        </TableHeader>
      </StyledTableRow>
      {pendingInvoices.map((pendingInvoice) => (
        <PendingInvoice pendingInvoice={pendingInvoice} />
      ))}
    </StyledTable>
  );
};

export default PendingInvoicesTable;
