import { FunctionComponent } from 'react';
import Svg, { Path, Mask } from 'svgs';

import { useEmotionTheme, EmotionStyle } from '../../core/styled';

interface Props {
  width?: number;
  height?: number;
  color?: string;
  style?: EmotionStyle;
  variant?: 'filled' | 'default';
}

const Clock: FunctionComponent<Props> = ({
  color,
  width = 24,
  height = 24,
  variant = 'default',
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'clock';

  return (
    <Svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      title={titleText}
      aria-label={titleText}
      {...otherProps}
    >
      {variant === 'filled' ? (
        <>
          <Mask id="path-1-inside-1_4388_776" fill="white">
            <Path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23ZM12.75 6C12.75 5.58579 12.4142 5.25 12 5.25C11.5858 5.25 11.25 5.58579 11.25 6V12.9014L16.084 16.124C16.4286 16.3538 16.8943 16.2607 17.124 15.916C17.3538 15.5714 17.2607 15.1057 16.916 14.876L12.75 12.0986V6Z"
            />
          </Mask>
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23ZM12.75 6C12.75 5.58579 12.4142 5.25 12 5.25C11.5858 5.25 11.25 5.58579 11.25 6V12.9014L16.084 16.124C16.4286 16.3538 16.8943 16.2607 17.124 15.916C17.3538 15.5714 17.2607 15.1057 16.916 14.876L12.75 12.0986V6Z"
            fill={color || colors.accessibilityGreenDark}
          />
          <Path
            d="M11.25 12.9014H9.75V13.7042L10.4179 14.1495L11.25 12.9014ZM16.084 16.124L15.2519 17.3721L15.2519 17.3721L16.084 16.124ZM17.124 15.916L18.3721 16.7481L18.3721 16.7481L17.124 15.916ZM16.916 14.876L16.084 16.124L16.084 16.124L16.916 14.876ZM12.75 12.0986H11.25V12.9014L11.9179 13.3467L12.75 12.0986ZM21.5 12C21.5 17.2467 17.2467 21.5 12 21.5V24.5C18.9036 24.5 24.5 18.9036 24.5 12H21.5ZM12 2.5C17.2467 2.5 21.5 6.75329 21.5 12H24.5C24.5 5.09644 18.9036 -0.5 12 -0.5V2.5ZM2.5 12C2.5 6.75329 6.75329 2.5 12 2.5V-0.5C5.09644 -0.5 -0.5 5.09644 -0.5 12H2.5ZM12 21.5C6.75329 21.5 2.5 17.2467 2.5 12H-0.5C-0.5 18.9036 5.09644 24.5 12 24.5V21.5ZM12 6.75C11.5858 6.75 11.25 6.41421 11.25 6H14.25C14.25 4.75736 13.2426 3.75 12 3.75V6.75ZM12.75 6C12.75 6.41421 12.4142 6.75 12 6.75V3.75C10.7574 3.75 9.75 4.75736 9.75 6H12.75ZM12.75 12.9014V6H9.75V12.9014H12.75ZM16.916 14.876L12.0821 11.6533L10.4179 14.1495L15.2519 17.3721L16.916 14.876ZM15.876 15.084C16.1057 14.7393 16.5714 14.6462 16.916 14.876L15.2519 17.3721C16.2859 18.0614 17.6828 17.782 18.3721 16.7481L15.876 15.084ZM16.084 16.124C15.7393 15.8943 15.6462 15.4286 15.876 15.084L18.3721 16.7481C19.0614 15.7141 18.782 14.3172 17.7481 13.6279L16.084 16.124ZM11.9179 13.3467L16.084 16.124L17.7481 13.6279L13.5821 10.8505L11.9179 13.3467ZM11.25 6V12.0986H14.25V6H11.25Z"
            fill={color || colors.accessibilityGreenDark}
            mask="url(#path-1-inside-1_4388_776)"
          />
        </>
      ) : (
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.5 12C21.5 17.2467 17.2467 21.5 12 21.5C6.75329 21.5 2.5 17.2467 2.5 12C2.5 6.75329 6.75329 2.5 12 2.5C17.2467 2.5 21.5 6.75329 21.5 12ZM23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12ZM12.75 6C12.75 5.58579 12.4142 5.25 12 5.25C11.5858 5.25 11.25 5.58579 11.25 6V12.9014L16.084 16.124C16.4286 16.3538 16.8943 16.2607 17.124 15.916C17.3538 15.5714 17.2607 15.1057 16.916 14.876L12.75 12.0986V6Z"
          fill={color || colors.accessibilityGreenDark}
        />
      )}
    </Svg>
  );
};

export default Clock;
