import { forwardRef } from 'react';
import { getColorsV0, getColorsV1, getColorsV2, getColorsV1p1 } from './colors/colors';
import { ColorRolesV0, ColorRolesV1, ColorRolesV2 } from './colors/colorRoles';
import {
  meetsVersion,
  parseVersionKey,
  SemanticVersion,
  sortSemanticVersions,
} from '../utils/semanticVersioning';
import useThemeVersion from '../hooks/useThemeVersion';
import { VersionMap } from './types';
import useShareForwardedRef from '../hooks/useShareForwardedRef';

export const getColorNames = (
  version: SemanticVersion | string,
  isHighContrastMode: boolean
): Record<string, string> => {
  const parseKey = parseVersionKey(version);
  switch (parseKey.major) {
    case 2:
      return getColorsV2(isHighContrastMode);
    case 1:
      // 1.1 is v1 components with v2 colors
      return parseKey.minor === 1
        ? getColorsV1p1(isHighContrastMode)
        : getColorsV1(isHighContrastMode);
    default:
      // 0.1 is v0 components with v2 colors
      return parseKey.minor === 1
        ? getColorsV1p1(isHighContrastMode)
        : getColorsV0(isHighContrastMode);
  }
};

export const getColorRoles = (version: SemanticVersion | string) => {
  const parseKey = parseVersionKey(version);
  switch (parseKey.major) {
    case 2:
      return ColorRolesV2;
    case 1:
      // 1.1 is v1 components with v2 colors
      return parseKey.minor === 1 ? ColorRolesV2 : ColorRolesV1;
    default:
      // 0.1 is v0 components with v2 colors
      return parseKey.minor === 1 ? ColorRolesV2 : ColorRolesV0;
  }
};

interface WithVersioningProps extends Object {
  versionOverride?: SemanticVersion;
}

/**
 * Uses the current theme version to determine which component to render
 * It will use the current version or any version lower than the current version
 * If no version is found, it will render the default component
 */
// eslint-disable-next-line @typescript-eslint/ban-types -- Using Record<string, unknown> breaks usages of this function
export const withVersioning = <Props extends WithVersioningProps = {}>(
  versionMap: VersionMap<Props>
) => {
  const { DefaultComponent, ...versions } = versionMap;

  return forwardRef((props: Props, ref: React.RefObject<React.ReactNode>) => {
    const { versionOverride } = props;
    const forwardedRef = useShareForwardedRef(ref);
    const parsedOverride = parseVersionKey(versionOverride as SemanticVersion);
    const version = useThemeVersion();
    const sortedVersions = sortSemanticVersions(Object.keys(versions) as SemanticVersion[]);

    const versionToUse = sortedVersions.find((v) =>
      meetsVersion(v, versionOverride ? parsedOverride.versionKey : version.versionKey)
    );
    const Component = versionToUse ? versions[versionToUse] : DefaultComponent;
    return <Component {...props} ref={forwardedRef} />;
  });
};
