import { Standard, styled, TextInput, TouchableView, View } from '@talkspace/react-toolkit';
import { ChangeEvent, FunctionComponent } from 'react';
import { useTheme } from 'emotion-theming';
import IndentedView from './IndentedView';
import xImage from '../../../Icons/x.svg';
import { EmotionStyle } from '../../../../core/styled';

const RemovePersonButton = styled(TouchableView)({
  flexDirection: 'row',
  alignItems: 'center',
});

const XIcon = styled(Standard)({
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  width: 9,
  height: 9,
  backgroundImage: `url(${xImage})`,
});

interface Props {
  style?: EmotionStyle;
  key?: number;
  namePlaceholder: string;
  nameValue?: string | null;
  onNameChange: (newName: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  relationshipValue?: string | null;
  onRelationshipChange: (newName: ChangeEvent<HTMLInputElement>) => void;
  showRemove?: boolean;
  onRemovePress?: () => void;
  nameError?: boolean;
  relationshipError?: boolean;
}

const ContactNameWithRelationship: FunctionComponent<Props> = ({
  style,
  key,
  namePlaceholder,
  nameValue,
  onNameChange,
  disabled = false,
  relationshipValue,
  onRelationshipChange,
  showRemove = false,
  onRemovePress,
  nameError,
  relationshipError,
}) => {
  const { colors } = useTheme();
  return (
    <View style={{ marginBottom: 10, ...style }} key={key}>
      <TextInput
        placeholder={namePlaceholder}
        style={{
          marginBottom: 10,
          ...(nameError ? { borderColor: colors.permaDebianRed } : {}),
        }}
        value={nameValue}
        onChange={onNameChange}
        placeHolderStyle={nameError ? { color: colors.permaDebianRed } : {}}
        disabled={disabled}
      />
      <IndentedView>
        <TextInput
          placeholder="Relationship to client"
          style={{
            marginBottom: 10,
            ...(relationshipError ? { borderColor: colors.permaDebianRed } : {}),
          }}
          value={relationshipValue}
          onChange={onRelationshipChange}
          placeHolderStyle={relationshipError ? { color: colors.permaDebianRed } : {}}
          disabled={disabled}
        />
        {showRemove && (
          <RemovePersonButton onPress={onRemovePress}>
            <XIcon style={{ marginRight: 5 }} />
            <Standard variant="standardBoldGrey">Remove person</Standard>
          </RemovePersonButton>
        )}
      </IndentedView>
    </View>
  );
};

export default ContactNameWithRelationship;
