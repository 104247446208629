import { View, Standard, styled } from '@talkspace/react-toolkit';
import { BusinessLineQueryResponse } from 'ts-frontend/hooks/useQueryBusinessLine';
import { useFlags } from 'launchDarkly/FlagsProvider';
import {
  getBHTooltipText,
  getBHTooltipTextV2,
  getDTEOrB2CTooltipText,
  getEAPTooltipText,
} from '../utils';
import { SessionSubmissionStatus } from '../../../../../hooks/notes/useQueryUnsubmittedSessions';
import { TextLink } from '../ProgressNoteForm/componentsV2/styles';
import useQuerySessionServices from '../../../../../hooks/useQuerySessionServicesV2';
import ssoHelper from '../../../../../modules/utils/sso';

const TooltipTitle = styled(Standard)(({ theme: { colors } }) => {
  return {
    fontSize: 15,
    fontWeight: 700,
    color: colors.black,
    marginBottom: 5,
  };
});

const BulletPoint = styled(View)(({ theme: { colors } }) => {
  return {
    height: 5,
    width: 5,
    borderRadius: '50%',
    background: colors.black,
    marginRight: 7,
    marginTop: 7,
  };
});

const BulletSubtitle = styled(View)(({ theme: { colors } }) => {
  return {
    color: colors.black,
    marginTop: -5,
    fontSize: 14,
  };
});

const TooltipText = styled(TooltipTitle)(() => {
  return {
    fontSize: 14,
    fontWeight: 400,
  };
});

interface TooltipProps {
  submissionStatus: SessionSubmissionStatus | null;
  businessLine?: BusinessLineQueryResponse;
  minSessionWords?: number | null;
  maxSessionWords?: number | null;
  clientUserID?: number;
  roomID: string;
  accountType?: string;
  asyncSessionID?: number;
  liveCallID?: number;
}

const Tooltip = ({
  submissionStatus,
  businessLine,
  minSessionWords,
  maxSessionWords,
  clientUserID,
  roomID,
  accountType,
  asyncSessionID,
  liveCallID,
}: TooltipProps) => {
  const {
    accessUnsubmittedSessions: { minWordsPerSession, maxWordsPerSession, wordsInMinute } = {},
  } = useFlags();
  const { data: sessionServices = [] } = useQuerySessionServices({
    roomID,
    clientUserID,
    asyncSessionID,
    liveCallID,
  });
  let text = getDTEOrB2CTooltipText(submissionStatus, businessLine?.isDTE || accountType === 'dte');

  const {
    calculatingAndCommunicatingAsyncEngagement: { featureOn: shouldShowMinutesOnly },
  } = useFlags();

  const minWords = minSessionWords || minWordsPerSession;
  const maxWords = maxSessionWords || maxWordsPerSession;
  const minDurationPerSession = minWords && wordsInMinute ? minWords / wordsInMinute : undefined;
  const maxDurationPerSession = maxWords && wordsInMinute ? maxWords / wordsInMinute : undefined;

  if (businessLine?.isBH || accountType === 'bh') {
    text = shouldShowMinutesOnly
      ? getBHTooltipTextV2({
          submissionStatus,
          minDurationPerSession,
          maxDurationPerSession,
          sessionServices: sessionServices.filter((it) => it.allowAsync),
        })
      : getBHTooltipText({
          submissionStatus,
          minWordsPerSession: minWords,
          maxWordsPerSession: maxWords,
          sessionServices: sessionServices.filter((it) => it.allowAsync),
        });
  } else if (businessLine?.isEAP || accountType === 'eap') {
    text = getEAPTooltipText(submissionStatus);
  }

  return (
    <>
      {text?.map((item, index) => (
        <View key={item.title} style={{ marginBottom: index !== text.length - 1 && 15 }}>
          <TooltipTitle>{item.title}</TooltipTitle>
          <TooltipText>
            {item.text}{' '}
            {item.link && (
              <TextLink onClick={() => ssoHelper.openZendesk(item.link)}>Learn more</TextLink>
            )}
          </TooltipText>

          {item.bulletPoints && (
            <>
              {item.bulletPoints.map((bulletPoint) => (
                <View key={bulletPoint.title} row style={{ marginBottom: 5, marginLeft: 5 }}>
                  <BulletPoint />
                  <View style={{ flex: 1, flexWrap: 'wrap' }}>
                    <TooltipText style={{ fontWeight: 700 }}>{bulletPoint.title}</TooltipText>
                    <BulletSubtitle>{bulletPoint.subtitle}</BulletSubtitle>
                  </View>
                </View>
              ))}
            </>
          )}
        </View>
      ))}
    </>
  );
};

export default Tooltip;
