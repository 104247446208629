import { useQuery, UseQueryResult, UseQueryOptions } from 'react-query';
import apiHelper from '@/core/api/apiHelper';
import apiWrapper from '@/core/api/apiWrapper';

import { ClientDiagnosticProfiles, DiagnosticProfileAPIResponse } from './types';
import { diagnosticProfileQueryKeys } from './queryKeys';

interface FetchDiagnosticProfilesParams {
  roomID: number;
  clientUserIDs: number[];
}

const getClientDiagnosticProfile = (
  roomID: number,
  userID: number
): Promise<DiagnosticProfileAPIResponse & { userID: number }> =>
  apiWrapper
    .get(`${apiHelper().apiEndpoint}/v3/rooms/${roomID}/client-diagnostic-profiles/${userID}`)
    .catch((err) => err)
    .then((res) => {
      return {
        ...(res as DiagnosticProfileAPIResponse),
        userID,
      };
    });

const fetchDiagnosticProfiles =
  ({ roomID, clientUserIDs }: FetchDiagnosticProfilesParams) =>
  async (): Promise<ClientDiagnosticProfiles> => {
    const responses = await Promise.all(
      clientUserIDs.map((clientUserID) => getClientDiagnosticProfile(roomID, clientUserID))
    );

    return responses
      .filter((res) => res.status === 200)
      .reduce<ClientDiagnosticProfiles>((acc, { userID, data }) => {
        acc[userID] = data.data;
        return acc;
      }, {});
  };

const useQueryDiagnosticProfiles = (
  params: FetchDiagnosticProfilesParams,
  options: UseQueryOptions<ClientDiagnosticProfiles, Error> = { enabled: true }
): UseQueryResult<ClientDiagnosticProfiles, Error> =>
  useQuery<ClientDiagnosticProfiles, Error>({
    queryKey: diagnosticProfileQueryKeys(params.roomID),
    queryFn: fetchDiagnosticProfiles(params),
    staleTime: 0,
    cacheTime: 0,
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
    ...options,
  });

export default useQueryDiagnosticProfiles;
