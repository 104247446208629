import { FunctionComponent } from 'react';
import Svg, { Path, Rect } from 'svgs';
import { EmotionStyle, useEmotionTheme } from '../../core/styled';

interface EnvelopeIconProps {
  color?: string;
  width?: number;
  style?: EmotionStyle;
  height?: number;
  fill?: boolean;
}

const EnvelopeIcon: FunctionComponent<EnvelopeIconProps> = ({
  color,
  width = 18,
  height = 15,
  style,
  fill,
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'envelope';
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 18 15"
      fill="none"
      style={style}
      aria-label={titleText}
      title={titleText}
    >
      <Rect
        x="0.75"
        y="0.75"
        width="16.5"
        height="12.7105"
        rx="2.322"
        fill={fill ? color || colors.slateGrey : colors.white}
        stroke={color || colors.slateGrey}
        strokeWidth="1.5"
      />
      <Path
        d="M3.4043 3.79688L9.11078 8.94207L14.5935 3.79688"
        stroke={color || colors.slateGrey}
        strokeWidth="1.6"
        strokeLinecap="round"
      />
    </Svg>
  );
};

export default EnvelopeIcon;
