import apiHelper from '@/core/api/apiHelper';
import apiWrapper from '@/core/api/apiWrapper';
import { getUserData } from '../../../utils/token';
import { ReviewsRecord, MetricsRecord, ReviewMetricsRecord, UtilizationMetric } from '../types';

export interface ApiResponse<T = unknown> {
  data?: T;
}

export interface ReviewsResponseData {
  reviews: ReviewsRecord[];
  total: number;
}

export interface ReviewsListResponse {
  data: ReviewsResponseData;
}

const RESULTS_PER_PAGE = 20;

/**
 * api call to: `/v2/therapist/:id/reviews`
 */
async function getReviewsList(page: number = 1): Promise<ReviewsResponseData> {
  const userID = getUserData().id;
  const offset = (page - 1) * RESULTS_PER_PAGE;
  const search = `offset=${offset}&limit=${RESULTS_PER_PAGE}`;
  const response = await apiWrapper.get(
    `${apiHelper().apiEndpoint}/v3/therapists/${userID}/reviews?${search}`
  );
  const {
    data: { data },
  } = response;
  return data;
}

const formatUtilizationAdditionalData = (
  additionalData: UtilizationMetric['additionalData']
): UtilizationMetric['additionalData'] => {
  const { updatedAt, ...rest } = additionalData;
  return {
    ...rest,
    updatedAt: updatedAt ? new Date(updatedAt) : null,
  };
};

const formatMetrics = (data: MetricsRecord): MetricsRecord => {
  const { utilization, ...rest } = data;
  return {
    ...rest,
    utilization: utilization && {
      ...utilization,
      additionalData: formatUtilizationAdditionalData(utilization?.additionalData),
    },
  };
};

/**
 * api call to: `/v3/therapists/:id/metrics`
 */
async function getMetrics(): Promise<MetricsRecord> {
  const userID = getUserData().id;
  const response = await apiWrapper.get(
    `${apiHelper().apiEndpoint}/v3/therapists/${userID}/metrics`
  );
  const {
    data: { data },
  } = response;
  return formatMetrics(data);
}

/**
 * api call to: `/v3/therapists/:id/review-metrics`
 */
async function getReviewMetrics(): Promise<ReviewMetricsRecord> {
  const userID = getUserData().id;
  const response = await apiWrapper.get(
    `${apiHelper().apiEndpoint}/v3/therapists/${userID}/review-metrics`
  );
  const {
    data: { data },
  } = response;
  return data;
}

const API = {
  getReviewsList,
  getMetrics,
  getReviewMetrics,
};
export default API;
