import { FunctionComponent, RefObject, useLayoutEffect, useRef } from 'react';
import { createPopper } from '@popperjs/core';
import { View } from '@talkspace/react-toolkit';
import useClickOutside from 'ts-frontend/hooks/useClickOutside';
import styled, { EmotionStyle } from '@/core/styled';
import { webOnlyStyle } from '@/core/styled/styleHelpers';

const FloatingWrapper = styled(View)(({ theme: { colorRoles } }) => {
  return {
    left: 67,
    top: 58,
    height: 538,
    width: 293,
    borderWidth: 1,
    display: 'flex',
    borderRadius: 12,
    position: 'absolute',
    flexDirection: 'column',
    backgroundColor: colorRoles.surfaces.defaultSubtleDefault,
    overflow: 'hidden',
    ...webOnlyStyle({
      boxShadow: '0px 8px 32px 0px rgba(43, 42, 46, 0.25)',
      right: 'auto',
    }),
  };
});

interface Props {
  isOpen?: boolean;
  style?: EmotionStyle;
  onClose(): void;
  anchorRef?: RefObject<HTMLElement>;
}

const DropdownMenuModal: FunctionComponent<Props> = ({
  onClose,
  children,
  isOpen,
  style = {},
  anchorRef,
  ...otherProps
}) => {
  const dropdownRef = useRef<HTMLDivElement>(null);

  useClickOutside(dropdownRef, () => {
    if (isOpen) {
      onClose();
    }
  });

  useLayoutEffect(() => {
    const getDropdownPosition = () => {
      if (anchorRef?.current && dropdownRef.current) {
        createPopper(anchorRef.current, dropdownRef.current, {
          placement: 'auto-end',
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [-4, -8],
              },
            },
          ],
        });
      }
    };

    if (isOpen) {
      getDropdownPosition();
    }
  }, [anchorRef, isOpen]);

  return isOpen ? (
    <FloatingWrapper ref={dropdownRef} style={style} {...otherProps}>
      {children}
    </FloatingWrapper>
  ) : null;
};

export default DropdownMenuModal;
