import { createContext, useContext, useEffect, Dispatch, FC, useCallback } from 'react';
import { RoomStatus } from 'ts-frontend/entities/Room';
import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';
import useObjectState from './useObjectState';
import { TherapistType, SessionStatus, ChatBannerType } from '../redux/constants/chatTypes';

interface SharedChatState {
  isTherapistChat: boolean;
  roomExpirationDate?: Date | null;
  therapistID?: number;
  therapistFirstName?: string;
  therapistType?: TherapistType;
  isChatHidden?: boolean;
  roomStatus?: RoomStatus;
  isLiveChatBannerOpen?: boolean;
  adminConfigs: {
    isPDFUploadEnabled?: boolean;
    isClinicalProgressEnabled?: boolean;
    isEmailVerificationEnabled?: boolean;
    isOnboardingV2Enabled?: boolean;
  };
  sessionStatus?: SessionStatus;
  sharedBanners: ChatBannerType[];
  primaryClientID?: number;
  hasSeenWelcomeBackInactivityTray?: boolean;
  hasSeenWelcomeBackActivityTray?: boolean;
  showInactivityBanner?: boolean;
  isMobileApp?: boolean;
  hideBanners: boolean;
}

interface SharedChatActions {
  updateState: Dispatch<Partial<SharedChatState>>;
  applyReactivationTray?: () => void;
  setHideBanners: (hide: boolean) => void;
}

export const SharedChatStateContext = createContext<SharedChatState | undefined>(undefined);

export const SharedChatActionsContext = createContext<SharedChatActions | undefined>(undefined);

export const SharedChatContextProvider: FC<{
  isTherapistChat: boolean;
  therapistFirstName?: string;
  therapistType?: TherapistType;
  isChatHidden?: boolean;
  roomStatus?: RoomStatus;
  isLiveChatBannerOpen?: boolean;
  hasSeenWelcomeBackInactivityTray?: boolean;
  hasSeenWelcomeBackActivityTray?: boolean;
  showInactivityBanner?: boolean;
  isMobileApp?: boolean;
  applyReactivationTray?: () => void;
}> = ({
  children,
  isTherapistChat,
  therapistFirstName,
  therapistType,
  isChatHidden,
  roomStatus,
  isLiveChatBannerOpen,
  hasSeenWelcomeBackInactivityTray,
  hasSeenWelcomeBackActivityTray,
  showInactivityBanner,
  isMobileApp,
  applyReactivationTray,
}) => {
  const [state, dispatch] = useObjectState<SharedChatState>({
    isTherapistChat,
    therapistFirstName,
    therapistType,
    isChatHidden,
    roomStatus,
    isLiveChatBannerOpen,
    hasSeenWelcomeBackInactivityTray,
    hasSeenWelcomeBackActivityTray,
    showInactivityBanner,
    adminConfigs: {},
    sharedBanners: [],
    isMobileApp,
    hideBanners: false,
  });

  useEffect(() => {
    const initializeStore = async () => {
      const getAdminConfigValues = () => {
        const queryString = ['pdf_upload', 'clinical_progress_web', 'email_verification']
          .map((key) => `key=${key}`)
          .join('&');
        return apiWrapper
          .get(`${apiHelper().apiEndpoint}/v3/users/get-admin-config-values?${queryString}`)
          .then((res) =>
            res.data.data.reduce((acc, { name, value }) => {
              return {
                ...acc,
                [name]: Boolean(value),
              };
            }, {})
          );
      };

      const adminConfigs = await getAdminConfigValues();

      dispatch({
        adminConfigs: {
          isPDFUploadEnabled: adminConfigs.pdf_upload,
          isClinicalProgressEnabled: adminConfigs.clinical_progress_web,
          isEmailVerificationEnabled: adminConfigs.email_verification,
        },
        therapistType,
        isChatHidden,
        therapistFirstName,
        roomStatus,
        isLiveChatBannerOpen,
        hasSeenWelcomeBackInactivityTray,
        hasSeenWelcomeBackActivityTray,
        isMobileApp,
      });
    };
    initializeStore();
  }, [
    dispatch,
    therapistFirstName,
    therapistType,
    isChatHidden,
    roomStatus,
    isLiveChatBannerOpen,
    hasSeenWelcomeBackInactivityTray,
    hasSeenWelcomeBackActivityTray,
    isMobileApp,
  ]);

  const setHideBanners = useCallback(
    (hide: boolean) => {
      dispatch({ hideBanners: hide });
    },
    [dispatch]
  );

  return (
    <SharedChatStateContext.Provider value={state}>
      <SharedChatActionsContext.Provider
        value={{ updateState: dispatch, applyReactivationTray, setHideBanners }}
      >
        {children}
      </SharedChatActionsContext.Provider>
    </SharedChatStateContext.Provider>
  );
};

export function useSharedChatState() {
  const context = useContext(SharedChatStateContext);
  if (context === undefined)
    throw new Error('SharedChatStateContext must be used within a ContextProvider');
  return context;
}

export function useSharedChatActions() {
  const context = useContext(SharedChatActionsContext);
  if (context === undefined)
    throw new Error('SharedChatActionsContext must be used within a ContextProvider');
  return context;
}
