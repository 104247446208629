import thunkMiddleware from 'redux-thunk';
import { createStore, applyMiddleware, combineReducers } from 'redux';

// eslint-disable-next-line import/no-extraneous-dependencies
import { composeWithDevTools } from 'redux-devtools-extension';
import apiHelper from '@/core/api/apiHelper';
import chatReducer, { initialState } from './reducers/chatReducer';
import inputReducer from './reducers/inputReducer';
import scriptsReducer from './reducers/scriptsReducer';
import offersReducer from './reducers/offersReducer';

import ChatApiHelpers from './helpers/ChatApiHelpersClass';

const reducer = combineReducers({
  chat: chatReducer,
  input: inputReducer,
  offers: offersReducer,
  scripts: scriptsReducer,
});

const middleware = [thunkMiddleware.withExtraArgument(new ChatApiHelpers())];

export type AppState = ReturnType<typeof reducer>;

let store;

const storeEnhancer = apiHelper().showDevTools
  ? composeWithDevTools(applyMiddleware(...middleware))
  : applyMiddleware(...middleware);

const chatStore = (isTherapistChat) => {
  if (store) return store;
  store = createStore(
    reducer,
    {
      chat: {
        ...initialState,
        isTherapistChat,
        messages: [...initialState.messages],
      },
    },
    storeEnhancer
  );
  return store;
};

export default chatStore;
