import { useEffect, useState } from 'react';
import {
  View,
  Large,
  Small,
  Mini,
  Big,
  COLORS,
  TouchableView,
  LiveSessionIcon,
  Checkbox,
  Spinner,
  Tiny,
  Standard,
  XCheckbox,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import moment from 'moment';
import styled from '../../core/styled';
import { Booking } from '../types';
import XIcon from '../../../components/Account/XIcon';
import extractBookingUIContext, { BookingUIContext } from '../utils/extractBookingUIContext';
import TooltipComponent from '../../../components/Reusable/Tooltip/Tooltip';
import QuestionMark from '../../../components/Icons/QuestionMark';

import { webOnlyStyle } from '../../core/styled/styleHelpers';

interface UpcomingBookingCardProps {
  isDesktop?: boolean;
  shouldHideActions?: boolean;
  shouldShowRoomDetails?: boolean;
  handleJoinCall: () => void;
  handleConfirmBooking: (hasBreakAfterSession: boolean | undefined) => void;
  handleDeclineBooking: () => void;
  handleCancelBooking: () => void;
  handleRemoveBooking: () => void;
  booking: Booking;
  isUpdating?: boolean;
}

function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const ResponsiveWrapper = styled(View)<{ isDesktop?: boolean }>(({ isDesktop }) =>
  isDesktop
    ? {
        flexDirection: 'row',
        minHeight: 106,
        alignItems: 'center',
        paddingTop: 19,
        paddingBottom: 19,
      }
    : {
        flexDirection: 'column',
        minHeight: 110,
        justifyContent: 'center',
      }
);

const NegativeActionText = styled(Small)(({ theme: { colors } }) => {
  return {
    fontWeight: 700,
    color: colors.permaLividBlueNew,
  };
});

const ProviderTakeABreakContainer = styled(View)(({ theme: { colors } }) => {
  return {
    background: colors.permaSolicitudeGrey,
    paddingLeft: 9,
    paddingRight: 9,
    paddingTop: 11,
    paddingBottom: 11,
    borderRadius: 8,
    marginTop: 10,
    alignItems: 'start',
  };
});

const dateAndTimeDesktopStyle = {
  marginLeft: 14,
  justifyContent: 'space-between',
  maxWidth: 300,
};
const DatetimeText = styled(Large)<{ shouldStrikeThrough: boolean }>(
  ({ theme: { colors }, shouldStrikeThrough }) => {
    return {
      marginBottom: 2,
      color: shouldStrikeThrough ? colors.slateGrey : undefined,
      ...webOnlyStyle({ textDecoration: shouldStrikeThrough && 'line-through' }),
    };
  }
);

const DateAndTime = ({
  isDesktop,
  startTime,
  creditMinutes,
  shouldStrikeThrough,
  hasBreakAfterSession,
  isTentativeBooking,
  isClientBooked,
}: {
  isDesktop?: boolean;
  shouldStrikeThrough: boolean;
  isTentativeBooking: boolean;
  isClientBooked: boolean;
} & Partial<Booking>) => {
  const date = moment(startTime).format(`ddd${isDesktop ? '' : 'd'}, MMM D`);
  const start = moment(startTime).format('h:mma');
  const end = moment(startTime).add(creditMinutes, 'minutes').format('h:mma z');
  return (
    <View row={isDesktop} flex={1} style={isDesktop ? dateAndTimeDesktopStyle : undefined}>
      <DatetimeText
        variant="largeMediumTSBlack"
        shouldStrikeThrough={shouldStrikeThrough}
        style={{ fontWeight: 700 }}
      >
        {date}
      </DatetimeText>
      <DatetimeText
        variant="largeMediumGrey"
        shouldStrikeThrough={shouldStrikeThrough}
        style={{ fontWeight: 400 }}
      >
        {start} – {end}
      </DatetimeText>
      {(!isTentativeBooking || (isTentativeBooking && !isClientBooked)) && hasBreakAfterSession ? (
        <Standard variant="standardBoldTSBlack" inline>
          (+15min break)
        </Standard>
      ) : null}
    </View>
  );
};
const PositiveAction = ({ text, onPress }: { text: string; onPress: () => void }) => (
  <TouchableView style={{ width: 130 }} onPress={onPress}>
    <Large variant="largeBoldWide" style={{ color: COLORS.accessibilityGreenDark }}>
      {text}
    </Large>
  </TouchableView>
);
const NegativeAction = ({ text, onPress }: { text: string; onPress: () => void }) => (
  <TouchableView onPress={onPress}>
    <NegativeActionText>{text}</NegativeActionText>
  </TouchableView>
);
const RoomDetails = ({ clientPseudonym, clientUserID, roomID }: Partial<Booking>) => (
  <View>
    <Big style={{ marginBottom: 9 }}>{clientPseudonym}</Big>
    <Mini style={{ marginBottom: 7, color: COLORS.black }}>Room: {roomID}</Mini>
    <Mini style={{ color: COLORS.black }}>User: {clientUserID}</Mini>
  </View>
);
const ExpiredTooltip = ({ tip }: { tip: string }) => (
  <TooltipComponent tip={tip}>
    <QuestionMark color="#8D9DB7" size={15} />
  </TooltipComponent>
);
const TinySpinner = styled(Spinner)({ width: 13, height: 13, borderWidth: 1 });

const SessionStatus = ({
  isDesktop,
  text,
  Icon,
  expiredTip,
  shouldShowSpinner,
}: {
  isDesktop?: boolean;
  text: string | JSX.Element;
  Icon: typeof NegativeActionText | typeof XIcon;
  expiredTip?: string;
  shouldShowSpinner?: boolean;
}) => {
  const { colors } = useEmotionTheme();
  return (
    <View row align="center">
      {shouldShowSpinner ? <TinySpinner primaryColor={colors.darkBlue} isLoading /> : <Icon />}
      <Small style={{ color: colors.darkBlue, marginLeft: 10, marginRight: 5 }}>{text}</Small>
      {expiredTip && <ExpiredTooltip tip={expiredTip} />}
    </View>
  );
};

const UpcomingBookingCard = ({
  isDesktop,
  shouldHideActions,
  shouldShowRoomDetails,
  handleCancelBooking,
  handleRemoveBooking,
  handleDeclineBooking,
  handleConfirmBooking,
  handleJoinCall,
  booking,
  isUpdating,
}: UpcomingBookingCardProps) => {
  const [hasBreakForTentativeBooking, setHasBreakForTentativeBooking] = useState<
    boolean | undefined
  >(false);

  const handleConfirmBookingWithState = () => handleConfirmBooking(hasBreakForTentativeBooking);

  const {
    clientPseudonym,
    clientUserID,
    roomID,
    startTime,
    creditMinutes,
    hasBreakAfterSession,
    hasTimeForBreak,
  } = booking;

  const {
    text: sessionStatusText,
    Icon,
    positiveAction,
    negativeAction,
    expiredTip,
  }: BookingUIContext = extractBookingUIContext(booking);

  let positiveActionText = '';

  if (positiveAction) {
    positiveActionText = positiveAction === 'join' ? '' : 'Confirm session';
  }
  const positiveActionOnPress =
    positiveAction === 'join' ? handleJoinCall : handleConfirmBookingWithState;
  const showPositiveAction =
    positiveAction === 'join' ? false : booking.scheduledByUserType === 'client';
  const negativeActionText = capitalize(negativeAction);
  let negativeActionOnPress = handleRemoveBooking;
  if (negativeAction === 'decline') negativeActionOnPress = handleDeclineBooking;
  if (negativeAction === 'cancel') negativeActionOnPress = handleCancelBooking;
  const shouldStrikeThrough = negativeAction === 'remove';
  const isTentativeClientScheduledBooking =
    booking.timekitBookingState === 'tentative' && booking.scheduledByUserType === 'client';
  const isTentativeBooking = booking.timekitBookingState === 'tentative';

  const allowBreak = isTentativeClientScheduledBooking;
  const { colors } = useEmotionTheme();

  useEffect(() => {
    if (!allowBreak) {
      setHasBreakForTentativeBooking(undefined);
    }
  }, [allowBreak, setHasBreakForTentativeBooking]);

  return (
    <ResponsiveWrapper isDesktop={isDesktop}>
      <View row>
        <LiveSessionIcon style={{ marginRight: 14 }} modality={booking.modality} />
        <View style={{ width: 218 }}>
          <View row justify="space-between" align="center" flex={isDesktop ? 3 : 0}>
            <DateAndTime
              isDesktop={isDesktop}
              startTime={startTime}
              creditMinutes={creditMinutes}
              shouldStrikeThrough={shouldStrikeThrough}
              hasBreakAfterSession={hasBreakAfterSession}
              isTentativeBooking={isTentativeBooking}
              isClientBooked={booking.scheduledByUserType === 'client'}
            />
          </View>
          {shouldShowRoomDetails && (
            <View row flex={2}>
              <RoomDetails
                clientPseudonym={clientPseudonym}
                clientUserID={clientUserID}
                roomID={roomID}
              />
            </View>
          )}
          <View
            row
            justify="space-between"
            align="center"
            style={{ marginTop: 10 }}
            flex={isDesktop ? 4 : 0}
          >
            <SessionStatus
              isDesktop={isDesktop}
              text={sessionStatusText}
              Icon={Icon}
              expiredTip={expiredTip}
              shouldShowSpinner={isUpdating}
            />
          </View>
          {isTentativeClientScheduledBooking && allowBreak && !shouldHideActions && (
            <ProviderTakeABreakContainer row>
              {hasTimeForBreak ? (
                <Checkbox
                  label="Add 15 minute break"
                  labelStyle={{ display: 'none' }}
                  containerStyle={{ marginBottom: 0 }}
                  checkboxStyle={{ margin: 0 }}
                  isChecked={!!hasBreakForTentativeBooking}
                  setIsChecked={(checked) => {
                    setHasBreakForTentativeBooking(checked);
                  }}
                />
              ) : (
                <XCheckbox />
              )}
              <View>
                <Large
                  style={{
                    marginLeft: 8,
                  }}
                  variant={hasTimeForBreak ? 'largeMediumTSBlack' : 'largeSlateGreyStrikethrough'}
                >
                  Add 15 minute break
                </Large>
                {!hasTimeForBreak && (
                  <Tiny style={{ marginLeft: 8, color: colors.black }}>
                    Not enough time available
                  </Tiny>
                )}
              </View>
            </ProviderTakeABreakContainer>
          )}
          <View row style={{ marginTop: 10 }} align="center" justify="space-between">
            {!shouldHideActions && (
              <NegativeAction text={negativeActionText} onPress={negativeActionOnPress} />
            )}
            {!shouldHideActions && showPositiveAction && (
              <PositiveAction onPress={positiveActionOnPress} text={positiveActionText} />
            )}
          </View>
        </View>
      </View>
    </ResponsiveWrapper>
  );
};

export default UpcomingBookingCard;
