import {
  REQUEST_MARKETING,
  RECEIVE_MARKETING,
  REQUEST_MARKETING_ERROR,
  PATCH_MARKETING,
  PATCH_MARKETING_SUCCESS,
  PATCH_MARKETING_ERROR,
} from '../constants/MarketingConstants';

const initialState = {
  trafficControl: undefined,
  reasonNoPurchase: undefined,
  firstTimeInTherapy: undefined,
  likelyToBuy: undefined,
  isRequestingMarketing: false,
  requestMarketingError: false,
};

export default function marketing(state = initialState, action) {
  switch (action.type) {
    case REQUEST_MARKETING:
    case PATCH_MARKETING:
      return {
        ...state,
        clientUserID: action.clientUserID || state.clientUserID,
        isRequestingMarketing: true,
        requestMarketingError: false,
      };
    case REQUEST_MARKETING_ERROR:
    case PATCH_MARKETING_ERROR:
      return {
        ...state,
        isRequestingMarketing: false,
        requestMarketingError: true,
      };
    case RECEIVE_MARKETING:
    case PATCH_MARKETING_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isRequestingMarketing: false,
        requestMarketingError: false,
      };
    default:
      return state;
  }
}
