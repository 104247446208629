import { useMutation } from 'react-query';

import apiHelper from '@/core/api/apiHelper';
import { getAccessToken } from '@/auth/helpers/token';

interface PayInvoicesVariables {
  clientUserID: number;
  invoiceIDs: Array<string>;
  cardToken: string;
}

const payInvoices = async ({
  clientUserID,
  invoiceIDs,
  cardToken,
}: PayInvoicesVariables): Promise<void> => {
  const apiURL = `v2/payments/users/${clientUserID}/invoices/pay`;

  const accessToken = getAccessToken();

  const headers = new Headers();

  headers.append('Authorization', `Bearer ${accessToken}`);
  headers.append('Content-Type', 'application/json');

  const response = await fetch(`${apiHelper().apiEndpoint}/${apiURL}`, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      invoiceIDs,
      cardToken,
    }),
  });

  if (!response.ok) {
    const parsedResponse = await response.json();
    throw new Error(parsedResponse.message || 'Something went wrong.');
  }
};

const useMutationPayInvoices = () => useMutation<void, Error, PayInvoicesVariables>(payInvoices);

export default useMutationPayInvoices;
