import { ReactNode } from 'react';
import { Standard, View } from '@talkspace/react-toolkit';
import styled from '@/core/styled';

const Container = styled(View)(({ theme: { colors } }) => {
  return {
    width: 345,
    height: 210,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 7,
    border: `1px solid ${colors.paleSilver}`,
    textAlign: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    marginBottom: 20,
    backgroundColor: colors.permaPhantomWhite,
  };
});

const Title = styled(Standard)(({ theme: { colors } }) => {
  return {
    fontSize: 17,
    fontWeight: 700,
    color: colors.permaCharcoal,
  };
});

const Subtitle = styled(Title)(() => {
  return {
    fontWeight: 400,
  };
});

interface CTAWrapperProps {
  title: string;
  text: string;
  icon?: ReactNode;
}

const EmptyErrorState = ({ title, text, icon }: CTAWrapperProps) => (
  <Container>
    <View style={{ alignItems: 'center' }}>
      {icon}
      <Title>{title}</Title>
      <Subtitle>{text}</Subtitle>
    </View>
  </Container>
);

export default EmptyErrorState;
