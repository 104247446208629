import { FunctionComponent } from 'react';
import Svg, { G, Path, Circle } from 'svgs';
import { EmotionStyle, useEmotionTheme } from '../../core/styled';

interface OpenBookProps {
  color?: string;
  height?: number;
  width?: number;
  style?: EmotionStyle;
}

const OpenBook: FunctionComponent<OpenBookProps> = ({ color, height = 23, width = 24, style }) => {
  const { colors } = useEmotionTheme();
  const titleText = 'open book';
  return (
    <Svg
      height={height}
      width={width}
      title={titleText}
      aria-label={titleText}
      style={style}
      viewBox="0 0 24 23"
    >
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Path
          d="M385.42 304.406c178.914 15.28 319.825 32.7 430.951 32.7 109.23 0 198.948-3.922 269.156-11.765-69.172 14.551-137.654 22.345-205.445 23.381 118.072 2.968 224.71-7.47 319.918-31.315L1199.998 871H0V304.406c110.093-12.541 238.566-12.541 385.42 0z"
          fill={colors.permaCatSkillWhite}
          transform="translate(-169 -519) matrix(-1 0 0 1 1200 0)"
        />
        <G transform="translate(-169 -519) translate(170 520)">
          <Path
            d="M0 2.007v13.009c2.613-.007 4.596.31 5.947.952C7.3 16.609 8.984 18.03 11 20.233c2.048-1.92 3.71-3.184 4.986-3.79 1.276-.604 3.28-.747 6.014-.427V3.006c-2.271-.163-4.276.001-6.014.495-1.739.493-3.4 1.473-4.986 2.938C9.451 4.91 7.767 3.77 5.947 3.025 4.128 2.28 2.146 1.94 0 2.007z"
            stroke={color || colors.permaViridianGreen}
            strokeWidth={2}
            strokeLinejoin="round"
          />
          <Path
            d="M14.901 13.456A7.602 7.602 0 0119 12.016V.006c-1.724-.067-3.26.367-4.608 1.303-1.349.935-2.48 2.645-3.392 5.13v7.021c2.402.14 3.702.139 3.901-.004z"
            stroke={color || colors.permaViridianGreen}
            strokeWidth={2}
            fill={colors.permaCatSkillWhite}
            strokeLinejoin="round"
          />
          <Path
            fill={colors.permaCatSkillWhite}
            d="M8 11.9599522L14.2457219 12.0261892 15.4764945 14.8271696 8 14.9599522z"
          />
          <Circle fill={color || colors.permaViridianGreen} cx={14.9} cy={13.4599522} r={1} />
          <Path d="M10 10.96h2v7a1 1 0 11-2 0v-7z" fill={color || colors.permaViridianGreen} />
        </G>
      </G>
    </Svg>
  );
};

export default OpenBook;
