import { FunctionComponent } from 'react';
import Svg, { G, Path, Defs, Ellipse, Mask, Use } from 'svgs';
import { EmotionStyle, useEmotionTheme } from '../../core/styled';

interface AddPartnerProps {
  color?: string;
  width?: number;
  style?: EmotionStyle;
}

const CouplesCircle: FunctionComponent<AddPartnerProps> = ({ color, width = 32, style }) => {
  const { colors } = useEmotionTheme();
  const titleText = 'Imperfect circle with two people';
  return (
    <Svg width={width} title={titleText} aria-label={titleText} style={style} viewBox="0 0 47 47">
      <Defs>
        <Path
          d="M8.301 5.176c10.338-7.453 26-7.06 33.82 2.504 4.236 5.181 5.318 11.693 4.066 18.985-1.104 6.425-4.899 11.8-9.21 15.513-9.409 8.102-23.732 4.551-31.552-5.013-7.82-9.565-7.461-24.536 2.876-31.989z"
          id="a"
        />
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <G transform="translate(-302 -682) translate(302 682)">
          <Mask fill="#fff">
            <Use xlinkHref="#a" />
          </Mask>
          <Use fill={color || colors.lightGray} xlinkHref="#a" />
        </G>
        <G
          transform="translate(-302 -682) translate(312 695)"
          stroke="#FFF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2.1599999}
        >
          <Path d="M16.062 18.264v-2.006c0-2.216-1.816-4.013-4.055-4.013H4.909c-2.24 0-4.055 1.797-4.055 4.013v2.006" />
          <Ellipse cx={8.45806746} cy={4.84278265} rx={4.05555267} ry={4.01259134} />
          <Path d="M25.413 16.396v-1.59c0-1.759-1.453-3.183-3.245-3.183H16.49c-1.385 0-2.005.415-2.005 1.037" />
          <Ellipse cx={19.3292017} cy={5.25787831} rx={3.24444214} ry={3.18240003} />
        </G>
      </G>
    </Svg>
  );
};

export default CouplesCircle;
