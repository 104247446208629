import { useState, useEffect } from 'react';
import { Row, Col, Panel, FormControl, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import InputElement from 'react-input-mask';
import apiWrapper from '../../../utils/apiWrapper';
import apiHelper from '../../../utils/api';
import { getUserData } from '../../../utils/token';
import '../FormHeader/FormHeader.css';
import '../Account.css';
import 'react-toastify/dist/ReactToastify.min.css';
import { SetIsSectionLoadedFn } from '../accountTypes';

const toastMessage = (
  <div
    className="toaster toaster-error"
    style={{ background: '#FF0000', color: '#FFF', padding: '12px', fontSize: '16px' }}
  >
    Server Error!
  </div>
);

const validateEmail = (email: string) =>
  !!email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);

const tooltip = (
  <Tooltip id="PrivateInformationTooltip" className="tooltip ts-tooltip ts-profile-text-white">
    Your slack name, phone number, and email are used to communicate important things to you. Make
    sure the information is correct.
  </Tooltip>
);

const defaultState = {
  email: '',
  phoneNumber: '',
  slackName: '',
  phoneNumberValid: true,
  emailValid: true,
  isLoaded: false,
};

interface Props {
  setIsSectionLoaded: SetIsSectionLoadedFn;
}

const PrivateInformation = ({ setIsSectionLoaded }: Props) => {
  const [state, setState] = useState(defaultState);
  const { email, phoneNumber, slackName, phoneNumberValid, emailValid, isLoaded } = state;

  useEffect(() => {
    const userId = getUserData().id;
    const getPrivateInfo = async () => {
      try {
        const response = await apiWrapper.get(
          `${apiHelper().apiEndpoint}/api/v1/therapist/${userId}/private-info`
        );
        const {
          data: { data },
        } = response;
        setState({
          email: data.email,
          emailValid: validateEmail(data.email),
          phoneNumberValid: true,
          phoneNumber: data.phoneNumber,
          slackName: data.slackName,
          isLoaded: true,
        });
        setIsSectionLoaded({ privateInformation: true });
      } catch (error) {
        toast(toastMessage, { autoClose: 3000 });
      }
    };
    getPrivateInfo();
  }, [setIsSectionLoaded]);

  return (
    <div>
      <Col
        xs={12}
        id="privateInformation"
        className={`ts-panel-title ${isLoaded ? 'show-panel' : 'hidden-panel'}`}
      >
        <Col xs={5} className="ts-font-black">
          Private Information
          <OverlayTrigger placement="top" trigger={['click', 'focus', 'hover']} overlay={tooltip}>
            <i className="fa fa-fw fa-question-circle fa-lg" />
          </OverlayTrigger>
        </Col>
        <Col xs={6} />
      </Col>
      <Col xs={12}>
        <Panel className={`ts-my-account-panel ${isLoaded ? 'show-panel' : 'hidden-panel'}`}>
          <Row style={{ marginBottom: '10px' }}>
            <Col className="ts-profile-label" md={2}>
              {' '}
              Mobile Phone Number{' '}
            </Col>
            <Col md={10}>
              <Col sm={4} style={{ padding: '0px' }}>
                <InputElement
                  mask="(999)-999-9999"
                  type="tel"
                  className="form-control"
                  disabled
                  value={phoneNumber}
                />
                <p className="ts-text-invalid ts-text-margin" hidden={phoneNumberValid}>
                  {' '}
                  Phone Number is invalid.{' '}
                </p>
              </Col>
            </Col>
          </Row>
          <Row style={{ marginBottom: '10px' }}>
            <Col md={2} className="ts-profile-label">
              Email
            </Col>
            <Col md={10}>
              <Col sm={4} style={{ padding: '0px' }}>
                <FormControl type="email" value={email} disabled />
                <p className="ts-text-invalid ts-text-margin" hidden={emailValid}>
                  Email is invalid.
                </p>
              </Col>
            </Col>
          </Row>
          <Row style={{ marginBottom: '10px' }}>
            <Col md={2} className="ts-profile-label">
              Slack
            </Col>
            <Col md={10}>
              <Col sm={4} style={{ padding: '0px' }}>
                <p className="clinical-information-text"> {slackName}</p>
              </Col>
            </Col>
          </Row>
        </Panel>
      </Col>
      <ToastContainer closeButton={false} position={toast.POSITION.BOTTOM_RIGHT} hideProgressBar />
    </div>
  );
};

export default PrivateInformation;
