import { useMutation, useQueryClient } from 'react-query';
import apiHelper from '@/utils/api';
import apiWrapper from '../../utils/apiWrapper';
import { CaseConsultationNoteFormState } from '../../components/Room/CRMContainer/NotesTab/types';
import { caseConsultationNoteQueryKey, notesListQueryKey } from './queryKeys';

interface UpdateCaseConsultationNoteMutateVariables extends CaseConsultationNoteFormState {
  roomID: string;
  noteID?: string;
  clientUserID?: number;
}

const updateCaseConsultationNote = async (
  params: UpdateCaseConsultationNoteMutateVariables
): Promise<void> => {
  const { roomID, noteID, clientUserID, ...requestBody } = params;
  await apiWrapper.patch(
    `${apiHelper().apiEndpoint}/v2/rooms/${roomID}/case-consultation-notes/${noteID}`,
    requestBody
  );
};

const useMutationUpdateCaseConsultationNote = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, UpdateCaseConsultationNoteMutateVariables>(
    updateCaseConsultationNote,
    {
      onSuccess: (_, { roomID, noteID, clientUserID }) => {
        queryClient.invalidateQueries(notesListQueryKey(roomID));
        if (noteID) {
          queryClient.invalidateQueries(caseConsultationNoteQueryKey(clientUserID, noteID));
        }
      },
    }
  );
};

export default useMutationUpdateCaseConsultationNote;
