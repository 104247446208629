import * as actionTypes from '../constants/InviteConstants';
import apiWrapper from '../utils/apiWrapper';
import apiHelper from '../utils/api';

function requestGetInvites() {
  return { type: actionTypes.REQUEST_GET_INVITE };
}

function receiveGetInvites(response) {
  return {
    type: actionTypes.RECEIVE_GET_INVITE,
    ...response.data,
  };
}

function requestGetInvitesError(error) {
  return {
    type: actionTypes.REQUEST_GET_INVITE_ERROR,
    error,
  };
}

function requestPostInvite() {
  return { type: actionTypes.REQUEST_POST_INVITE };
}

function receivePostInvite() {
  return {
    type: actionTypes.RECEIVE_POST_INVITE,
  };
}

function requestPostInviteError(error) {
  return {
    type: actionTypes.REQUEST_POST_INVITE_ERROR,
    error,
  };
}

function requestResendInvite() {
  return { type: actionTypes.REQUEST_PATCH_INVITE };
}

function receiveResendInvite() {
  return {
    type: actionTypes.RECEIVE_PATCH_INVITE,
  };
}

function requestResendInviteError(error) {
  return {
    type: actionTypes.REQUEST_PATCH_INVITE_ERROR,
    error,
  };
}

function requestRevokeInvite() {
  return { type: actionTypes.REQUEST_DELETE_INVITE };
}

function receiveRevokeInvite(inviteID) {
  return {
    type: actionTypes.RECEIVE_DELETE_INVITE,
    inviteID,
  };
}

function requestRevokeInviteError(error) {
  return {
    type: actionTypes.REQUEST_DELETE_INVITE_ERROR,
    error,
  };
}

export const getInvites = () => (dispatch, getState) => {
  const {
    room: { roomID },
  } = getState();
  dispatch(requestGetInvites());
  return apiWrapper
    .get(`${apiHelper().apiEndpoint}/api/v1/rooms/${roomID}/invites`)
    .then((response) => dispatch(receiveGetInvites(response)))
    .catch((error) => dispatch(requestGetInvitesError(error)));
};

export const postInvite = () => (dispatch, getState) => {
  const {
    room: { roomID },
  } = getState();
  dispatch(requestPostInvite());
  return apiWrapper
    .post(`${apiHelper().apiEndpoint}/api/v1/rooms/${roomID}/invites`, {})
    .then(() => dispatch(receivePostInvite()))
    .then(() => dispatch(getInvites()))
    .catch((error) => dispatch(requestPostInviteError(error)));
};

export const resendInvite = (inviteID) => (dispatch, getState) => {
  const {
    room: { roomID },
  } = getState();
  dispatch(requestResendInvite());
  return apiWrapper
    .patch(`${apiHelper().apiEndpoint}/api/v1/rooms/${roomID}/invites/${inviteID}`)
    .then(() => dispatch(receiveResendInvite()))
    .catch((error) => dispatch(requestResendInviteError(error)));
};

export const revokeInvite = (inviteID) => (dispatch, getState) => {
  const {
    room: { roomID },
  } = getState();
  dispatch(requestRevokeInvite(inviteID));
  return apiWrapper
    .delete(`${apiHelper().apiEndpoint}/api/v1/rooms/${roomID}/invites/${inviteID}`)
    .then(() => dispatch(receiveRevokeInvite(inviteID)))
    .catch((error) => dispatch(requestRevokeInviteError(error)));
};
