import { SingleDatePicker, SingleDatePickerShape } from 'react-dates';
import DatePickersCSSWrapper from '../../designSystems/components/TimeslotsCalendar/DatePickersCSSWrapper';
import useDatePicker, {
  BaseDatePickerProps,
} from '../../designSystems/components/TimeslotsCalendar/useDatePicker';

// Cannot use `interface` herr because `SingleDatePickerShape` is also a `type`
export type SingleDatePickerWithInputProps = Omit<
  SingleDatePickerShape,
  'initialVisibleMonth' | 'renderMonthText' | 'renderMonthElement'
> &
  Omit<BaseDatePickerProps, 'isSingle'>;

const SingleDatePickerWithInput = ({
  containerStyle,
  topOffset = 15,
  ...props
}: SingleDatePickerWithInputProps) => {
  const {
    state: { containerHeight },
    dateProps,
  } = useDatePicker({ topOffset, ...props, isSingle: true });

  return (
    <DatePickersCSSWrapper
      containerStyle={containerStyle}
      topOffset={topOffset}
      height={containerHeight}
    >
      <SingleDatePicker {...props} {...dateProps} />
    </DatePickersCSSWrapper>
  );
};

export default SingleDatePickerWithInput;
