import { FunctionComponent, useState } from 'react';
import { AccordionContainer, OptionType, Select, Tiny, View } from '@talkspace/react-toolkit';
import { Styles } from 'react-select';
import useQueryBusinessLine from 'ts-frontend/hooks/useQueryBusinessLine';
import useQueryRoomDetails from 'ts-frontend/hooks/useQueryRoomDetails';
import { useParams } from '@/core/routerLib';
import { ProgressNoteSubstanceUseCreationAttributes } from '../../types';
import {
  useProgressNoteFormActions,
  useProgressNoteFormState,
} from '../context/PsychProgressNoteFormContext';
import { FormSection, ProgressNoteFormState } from '../context/types';
import SubstanceUseQuestion from './SubstanceUseQuestion';
import ConfirmButton from './ConfirmButton';
import IndentedView from '../../IndentedView';

const substanceUseSelectStyle: Styles<OptionType, boolean> = {
  container: (provided) => {
    return { ...provided, marginBottom: 10 };
  },
};

const substanceUseQuestionOptions = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
  { value: null, label: 'Unknown' },
];

type SubstanceUseNoteFieldName = keyof Omit<
  ProgressNoteSubstanceUseCreationAttributes,
  'pastSubstanceUse' | 'currentSubstanceUse'
>;

interface SubstanceUseNoteField {
  label: string;
  fieldName: SubstanceUseNoteFieldName;
}

const pastSubstanceUseNotes: Array<SubstanceUseNoteField> = [
  { label: 'Cigarettes', fieldName: 'pastCigarettesUseNotes' },
  { label: 'Vaping', fieldName: 'pastVapingUseNotes' },
  { label: 'Alcohol', fieldName: 'pastAlcoholUseNotes' },
  { label: 'Marijuana', fieldName: 'pastMarijuanaUseNotes' },
  { label: 'Stimulants', fieldName: 'pastStimulantsUseNotes' },
  { label: 'Cocaine', fieldName: 'pastCocaineUseNotes' },
  { label: 'Heroin', fieldName: 'pastHeroinUseNotes' },
  {
    label: 'Benzodiazepines (Xanax, Ativan, Valium, etc)',
    fieldName: 'pastBenzodiazepinesUseNotes',
  },
  { label: 'Opioids/painkillers', fieldName: 'pastOpioidsUseNotes' },
  { label: 'Other substances', fieldName: 'pastOtherSubstanceUseNotes' },
];

const currentSubstanceUseNotes: Array<SubstanceUseNoteField> = [
  { label: 'Cigarettes', fieldName: 'currentCigarettesUseNotes' },
  { label: 'Vaping', fieldName: 'currentVapingUseNotes' },
  { label: 'Alcohol', fieldName: 'currentAlcoholUseNotes' },
  { label: 'Marijuana', fieldName: 'currentMarijuanaUseNotes' },
  { label: 'Stimulants', fieldName: 'currentStimulantsUseNotes' },
  { label: 'Cocaine', fieldName: 'currentCocaineUseNotes' },
  { label: 'Heroin', fieldName: 'currentHeroinUseNotes' },
  {
    label: 'Benzodiazepines (Xanax, Ativan, Valium, etc)',
    fieldName: 'currentBenzodiazepinesUseNotes',
  },
  { label: 'Opioids/painkillers', fieldName: 'currentOpioidsUseNotes' },
  { label: 'Other substances', fieldName: 'currentOtherSubstanceUseNotes' },
];

const SubstanceUseSection: FunctionComponent = () => {
  const { substanceUse: globalSubstanceUse, formSections, formMode } = useProgressNoteFormState();
  const [substanceUse, setSubstanceUse] =
    useState<ProgressNoteFormState['substanceUse']>(globalSubstanceUse);
  const { setFormState } = useProgressNoteFormActions();

  const [pastSubstanceUseError, setPastSubstanceUseError] = useState(false);
  const [currentSubstanceUseError, setCurrentSubstanceUseError] = useState(false);
  const [notesErrors, setNotesErrors] = useState<Array<SubstanceUseNoteFieldName>>([]);

  const { roomID } = useParams<{ roomID: string }>();
  const { data: { clientUserID } = {} } = useQueryRoomDetails(roomID);
  const { data: businessLineData } = useQueryBusinessLine(roomID, clientUserID);

  const markSectionAsIncomplete = () => {
    if (formSections.substanceUse.completed) {
      setFormState({
        formSections: {
          ...formSections,
          substanceUse: {
            open: formSections.substanceUse.open,
            changed: true,
            completed: false,
          },
        },
      });
    }
  };

  const handlePastSubstanceUseChange = (option: OptionType<boolean> | null | undefined) => {
    markSectionAsIncomplete();

    setPastSubstanceUseError(false);

    const newValue = option ? option.value : false;

    setSubstanceUse({
      ...substanceUse,
      pastSubstanceUse: newValue,
      pastCigarettesUseNotes: null,
      pastVapingUseNotes: null,
      pastAlcoholUseNotes: null,
      pastMarijuanaUseNotes: null,
      pastStimulantsUseNotes: null,
      pastCocaineUseNotes: null,
      pastHeroinUseNotes: null,
      pastBenzodiazepinesUseNotes: null,
      pastOpioidsUseNotes: null,
      pastOtherSubstanceUseNotes: null,
    });
  };

  const handleCurrentSubstanceUseChange = (option: OptionType<boolean> | null | undefined) => {
    markSectionAsIncomplete();

    setCurrentSubstanceUseError(false);

    const newValue = option ? option.value : false;

    setSubstanceUse({
      ...substanceUse,
      currentSubstanceUse: newValue,
      currentCigarettesUseNotes: null,
      currentVapingUseNotes: null,
      currentAlcoholUseNotes: null,
      currentMarijuanaUseNotes: null,
      currentStimulantsUseNotes: null,
      currentCocaineUseNotes: null,
      currentHeroinUseNotes: null,
      currentBenzodiazepinesUseNotes: null,
      currentOpioidsUseNotes: null,
      currentOtherSubstanceUseNotes: null,
    });
  };

  const setNotes = (
    field: keyof ProgressNoteSubstanceUseCreationAttributes,
    value: string | null
  ) => {
    markSectionAsIncomplete();

    setPastSubstanceUseError(false);
    setCurrentSubstanceUseError(false);
    setNotesErrors(notesErrors.filter((it) => it !== field));

    setSubstanceUse({
      ...substanceUse,
      [field]: value,
    });
  };

  const handleAccordionPress = (value: boolean) => {
    setFormState({
      formSections: {
        ...formSections,
        substanceUse: {
          open: value,
          changed: formSections.substanceUse.changed,
          completed: formSections.substanceUse.completed,
        },
      },
    });
  };

  const handleConfirmPress = () => {
    let isError = false;

    if (
      substanceUse.pastSubstanceUse &&
      substanceUse.pastCigarettesUseNotes === null &&
      substanceUse.pastVapingUseNotes === null &&
      substanceUse.pastAlcoholUseNotes === null &&
      substanceUse.pastMarijuanaUseNotes === null &&
      substanceUse.pastStimulantsUseNotes === null &&
      substanceUse.pastCocaineUseNotes === null &&
      substanceUse.pastHeroinUseNotes === null &&
      substanceUse.pastBenzodiazepinesUseNotes === null &&
      substanceUse.pastOpioidsUseNotes === null &&
      substanceUse.pastOtherSubstanceUseNotes === null
    ) {
      setPastSubstanceUseError(true);
      isError = true;
    }

    if (substanceUse.pastSubstanceUse) {
      const missingNotes: Array<SubstanceUseNoteFieldName> = [];

      if (substanceUse.pastCigarettesUseNotes === '') {
        missingNotes.push('pastCigarettesUseNotes');
      }
      if (substanceUse.pastVapingUseNotes === '') {
        missingNotes.push('pastVapingUseNotes');
      }
      if (substanceUse.pastAlcoholUseNotes === '') {
        missingNotes.push('pastAlcoholUseNotes');
      }
      if (substanceUse.pastMarijuanaUseNotes === '') {
        missingNotes.push('pastMarijuanaUseNotes');
      }
      if (substanceUse.pastStimulantsUseNotes === '') {
        missingNotes.push('pastStimulantsUseNotes');
      }
      if (substanceUse.pastCocaineUseNotes === '') {
        missingNotes.push('pastCocaineUseNotes');
      }
      if (substanceUse.pastHeroinUseNotes === '') {
        missingNotes.push('pastHeroinUseNotes');
      }
      if (substanceUse.pastBenzodiazepinesUseNotes === '') {
        missingNotes.push('pastBenzodiazepinesUseNotes');
      }
      if (substanceUse.pastOpioidsUseNotes === '') {
        missingNotes.push('pastOpioidsUseNotes');
      }
      if (substanceUse.pastOtherSubstanceUseNotes === '') {
        missingNotes.push('pastOtherSubstanceUseNotes');
      }

      if (missingNotes.length > 0) {
        isError = true;
        setNotesErrors(missingNotes);
      }
    }

    if (
      substanceUse.currentSubstanceUse &&
      substanceUse.currentCigarettesUseNotes === null &&
      substanceUse.currentVapingUseNotes === null &&
      substanceUse.currentAlcoholUseNotes === null &&
      substanceUse.currentMarijuanaUseNotes === null &&
      substanceUse.currentStimulantsUseNotes === null &&
      substanceUse.currentCocaineUseNotes === null &&
      substanceUse.currentHeroinUseNotes === null &&
      substanceUse.currentBenzodiazepinesUseNotes === null &&
      substanceUse.currentOpioidsUseNotes === null &&
      substanceUse.currentOtherSubstanceUseNotes === null
    ) {
      setCurrentSubstanceUseError(true);
      isError = true;
    }

    if (substanceUse.currentSubstanceUse) {
      const missingNotes: Array<SubstanceUseNoteFieldName> = [];

      if (substanceUse.currentCigarettesUseNotes === '') {
        missingNotes.push('currentCigarettesUseNotes');
      }
      if (substanceUse.currentVapingUseNotes === '') {
        missingNotes.push('currentVapingUseNotes');
      }
      if (substanceUse.currentAlcoholUseNotes === '') {
        missingNotes.push('currentAlcoholUseNotes');
      }
      if (substanceUse.currentMarijuanaUseNotes === '') {
        missingNotes.push('currentMarijuanaUseNotes');
      }
      if (substanceUse.currentStimulantsUseNotes === '') {
        missingNotes.push('currentStimulantsUseNotes');
      }
      if (substanceUse.currentCocaineUseNotes === '') {
        missingNotes.push('currentCocaineUseNotes');
      }
      if (substanceUse.currentHeroinUseNotes === '') {
        missingNotes.push('currentHeroinUseNotes');
      }
      if (substanceUse.currentBenzodiazepinesUseNotes === '') {
        missingNotes.push('currentBenzodiazepinesUseNotes');
      }
      if (substanceUse.currentOpioidsUseNotes === '') {
        missingNotes.push('currentOpioidsUseNotes');
      }
      if (substanceUse.currentOtherSubstanceUseNotes === '') {
        missingNotes.push('currentOtherSubstanceUseNotes');
      }

      if (missingNotes.length > 0) {
        isError = true;
        setNotesErrors(missingNotes);
      }
    }

    if (isError) {
      setFormState({ showGlobalError: true });
      return;
    }

    const nextSection: FormSection = businessLineData?.isPsychiatry
      ? 'medicalInformation'
      : 'treatmentPlanProgress';

    setFormState({
      substanceUse,
      formSections: {
        ...formSections,
        substanceUse: {
          open: false,
          changed: true,
          completed: true,
        },
        ...(formSections[nextSection].completed
          ? {}
          : {
              [nextSection]: {
                ...formSections[nextSection],
                open: true,
              },
            }),
      },
    });
  };

  return (
    <AccordionContainer
      title="Substance use"
      open={formSections.substanceUse.open}
      onPress={handleAccordionPress}
      showCheckMark={formMode !== 'view' && formSections.substanceUse.completed}
      bodyStyle={{ paddingTop: 0 }}
      dataQa="substanceUseAccordion"
    >
      <View style={{ marginBottom: 20 }}>
        <Select
          styles={substanceUseSelectStyle}
          placeholder="Past substance use"
          value={
            substanceUseQuestionOptions.find(
              (it) => it.value === substanceUse.pastSubstanceUse
            ) as any
          }
          onChange={handlePastSubstanceUseChange as any}
          options={substanceUseQuestionOptions as any}
          isDisabled={formMode === 'view'}
          dataQa="pastSubstanceUseDropdown"
        />
        {pastSubstanceUseError && (
          <Tiny variant="tinyRed" style={{ marginBottom: 7 }}>
            Select at least one substance
          </Tiny>
        )}
        {substanceUse.pastSubstanceUse && (
          <IndentedView>
            {pastSubstanceUseNotes.map(({ label, fieldName }, i) => (
              <SubstanceUseQuestion
                key={fieldName}
                label={label}
                isChecked={substanceUse[fieldName] !== null}
                setIsChecked={(value: boolean) => setNotes(fieldName, value ? '' : null)}
                notes={substanceUse[fieldName]}
                setNotes={(value: string) => setNotes(fieldName, value)}
                isError={notesErrors.includes(fieldName)}
                style={{ ...(i === pastSubstanceUseNotes.length - 1 ? { marginBottom: 0 } : {}) }}
                disabled={formMode === 'view'}
                fieldName={fieldName}
              />
            ))}
          </IndentedView>
        )}
      </View>
      <Select
        styles={substanceUseSelectStyle}
        placeholder="Current substance use"
        value={
          substanceUseQuestionOptions.find(
            (it) => it.value === substanceUse.currentSubstanceUse
          ) as any
        }
        onChange={handleCurrentSubstanceUseChange as any}
        options={substanceUseQuestionOptions as any}
        isDisabled={formMode === 'view'}
        dataQa="currentSubstanceUseDropdown"
      />
      {currentSubstanceUseError && (
        <Tiny variant="tinyRed" style={{ marginBottom: 7 }}>
          Select at least one substance
        </Tiny>
      )}
      {substanceUse.currentSubstanceUse && (
        <IndentedView>
          {currentSubstanceUseNotes.map(({ label, fieldName }, i) => (
            <SubstanceUseQuestion
              key={fieldName}
              label={label}
              notes={substanceUse[fieldName]}
              setNotes={(value: string | null) => setNotes(fieldName, value)}
              isChecked={substanceUse[fieldName] !== null}
              setIsChecked={(value: boolean) => setNotes(fieldName, value ? '' : null)}
              isError={notesErrors.includes(fieldName)}
              style={{ ...(i === pastSubstanceUseNotes.length - 1 ? { marginBottom: 0 } : {}) }}
              disabled={formMode === 'view' || fieldName === 'currentOtherSubstanceUseNotes'}
              fieldName={fieldName}
            />
          ))}
        </IndentedView>
      )}
      {formMode !== 'view' && !formSections.substanceUse.completed && (
        <ConfirmButton onPress={handleConfirmPress} sectionTitle="substanceUse" />
      )}
    </AccordionContainer>
  );
};

export default SubstanceUseSection;
