export interface SafeAreaInsets {
  top: number;
  bottom: number;
  left: number;
  right: number;
}

const getRootStyleVar = (varName: string): number => {
  // catching here because nextJS doesn't support document functions like getComputedStyle
  try {
    return Number(
      getComputedStyle(document.documentElement).getPropertyValue(varName).replace('px', '')
    );
  } catch (e) {
    console.error(e); // eslint-disable-line no-console
    return 0;
  }
};
export const getScreenSafeAreaInsets = (): SafeAreaInsets => {
  const insets = {
    top: getRootStyleVar('--sat'),
    bottom: getRootStyleVar('--sab'),
    left: getRootStyleVar('--sal'),
    right: getRootStyleVar('--sar'),
  };
  return insets;
};
