import { BaseApiHelper } from 'ts-frontend/apiHelpers';
import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/utils/api';

export default class StepWizardApiHelper extends BaseApiHelper {
  /**
   * api call to: POST `/v3/users/:UserID/wizard`
   * Might want to move out of this API call to shared folder
   * wrapWithCancel
   * @returns Promise
   */
  // todo: fix any
  public saveWizardStepsResults = (clientUserID: number, payload: any): Promise<any> =>
    apiWrapper.post(`${apiHelper().apiEndpoint}/v3/users/${clientUserID}/wizard`, payload);
}
