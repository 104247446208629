import { useState, useEffect } from 'react';
import { TherapistInfo } from 'ts-frontend/entities/Therapist';
import { ERoom } from 'ts-frontend/entities/Room';
import { InRoomSchedulingContainer } from 'inRoomScheduling';
import ReactFrameService from '@/auth/reactFrame/ReactFrameService';

import { useCloseModal } from '../../../../../modules/utils/ModalsContextProvider';
import Submenu from '../../../../Reusable/Submenu/Submenu';
import { useHistory, useLocation, useRouteMatch } from '@/core/routerLib';

const DEFAULT_TITLE = 'Schedule a session';

const InRoomSchedulingWrapper = (props: {
  room: ERoom;
  clientDisplayName: string;
  therapist: Pick<TherapistInfo, 'id' | 'firstName' | 'lastName'>;
  clientUserID: number;
}) => {
  const { pathname, key, search } = useLocation();
  const closeModal = useCloseModal();
  const history = useHistory<{ backButtonRoute?: string } | undefined>();
  const match = useRouteMatch<{ roomID: string }>();
  const isBookingSuccess = pathname.endsWith('/booking-success');
  const backButtonRoute = history.location.state?.backButtonRoute;
  const queryParams = new URLSearchParams(search);
  const closePopupWhenDone = queryParams.get('closePopupWhenDone') === 'true';

  const [wrapperTitle, setWrapperTitle] = useState<string | null>(DEFAULT_TITLE);
  const [showCloseBtn, setShowCloseBtn] = useState<boolean>(false);

  useEffect(() => {
    if (closePopupWhenDone) {
      setShowCloseBtn(true);
    }
  }, [closePopupWhenDone]);

  const handleBackPress = () => {
    if (isBookingSuccess) {
      history.push(`/room/${match.params.roomID}/lvs-tab`);
    } else if (backButtonRoute) {
      history.push(backButtonRoute);
    } else {
      history.goBack();
    }
  };

  const handleClosePress = () => {
    if (ReactFrameService.instance().isInFrame()) {
      closeModal();
    } else {
      history.push(`/room/${match.params.roomID}/lvs-tab`);
    }
  };

  useEffect(() => {
    setWrapperTitle(DEFAULT_TITLE);
  }, [setWrapperTitle]);

  return (
    <Submenu
      // @ts-ignore Not sure why it's failing to recognize the props of this JS component
      bodyStyle={{ padding: 0 }}
      childComponents={[
        <InRoomSchedulingContainer
          isInModal={false}
          isTherapist
          {...props}
          setWrapperTitle={setWrapperTitle}
        />,
      ]}
      title={wrapperTitle || DEFAULT_TITLE}
      onBack={handleBackPress}
      onClose={handleClosePress}
      showBackButton={!isBookingSuccess && ((key && key !== 'default') || !!backButtonRoute)}
      showCloseButton={showCloseBtn}
    />
  );
};

export default InRoomSchedulingWrapper;
