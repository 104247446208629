import Svg, { G, Path, Circle } from 'svgs';
import { EmotionStyle, useEmotionTheme } from '../../core/styled';

interface TherapistSearchProps {
  color?: string;
  width?: number;
  height?: number;
  style?: EmotionStyle;
}

const TherapistSearch = ({ color, width = 36, height = 35, style }: TherapistSearchProps) => {
  const { colors } = useEmotionTheme();
  const titleText = 'therapist search icon';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      style={style}
      viewBox="0 0 36 33"
    >
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <G
          opacity={0.899999976}
          transform="translate(-37 -190) translate(38 191)"
          stroke={color || colors.permaCornflowerBlue}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2.20000005}
        >
          <Path d="M.874 30.818a9.653 9.653 0 0119.305 0M5.443 11.557a9.793 9.793 0 007.05 2.994M15.145 18.22a5.899 5.899 0 11-3.76-9.506M29.412 20.65a4.826 4.826 0 01-8.557-1.716" />
          <Path d="M33.049 30.818a7.508 7.508 0 00-7.508-7.508 7.343 7.343 0 00-3.031.65" />
          <Circle cx={22.3437517} cy={7.60002066} r={7.50750058} />
          <Path d="M33.0687525 18.3250215L27.6862321 12.9425011" />
        </G>
      </G>
    </Svg>
  );
};

export default TherapistSearch;
