import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createCookie } from 'ts-frontend/utils';
import { resendOTPKey, revokeOTPToken, verifyLogin, clearVerifyError } from '../../reducers/auth';
import Navbar from '../Login/Navbar';
import TwoFactorView from './TwoFactorView';
import { handleSSOAfterLogin } from '../../utils/ssoHelper';
import storage from '../../core/storage';
import trackEvent from '../../modules/analytics/trackEvent';
import { isIonic } from '@/auth/reactFrame/helpers';

function TwoFactor(props) {
  const [otpKey, setOTPKey] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const [rememberBrowser, setRememberBrowser] = useState(false);

  useEffect(() => {
    if (props.loginSuccess) {
      trackEvent('login', { actionName: 'activeExperiments' });
      handleSSOAfterLogin();
      createCookie('_tsIsProvider', true);

      if (isIonic()) {
        props.history.push('/passcode');
      } else if (storage.getItem('redirectUrl')) {
        props.history.push(storage.getItem('redirectUrl'));
        storage.removeItem('redirectUrl');
      } else {
        props.history.push('/rooms');
      }
    }
  }, [props.loginSuccess, props.history]);

  useEffect(() => {
    if (!props.otpToken) {
      props.history.push('login');
    }
  }, [props.otpToken, props.history]);
  useEffect(() => {
    if (props.redirectToLogin) {
      const timeoutID = setTimeout(() => props.history.push('login'), 3000);
      return () => clearTimeout(timeoutID);
    }
    return undefined;
  }, [props.redirectToLogin, props.history]);

  const { revokeOTPToken: revokeOTP } = props;

  useEffect(
    () => () => {
      if (props.otpToken) revokeOTP();
    },
    [!!props.otpToken] //eslint-disable-line
  );
  useEffect(() => {
    if (props.verifyError || props.redirectToLogin) {
      setOTPKey('');
      setIsDisabled(true);
    }
  }, [props.verifyError, props.redirectToLogin]);

  const handleOtpKeyInput = (otpKeyInput) => {
    setOTPKey(otpKeyInput);
    setIsDisabled(otpKeyInput.length !== 6);
  };

  const handleVerifyLogin = () => {
    if (!isDisabled) {
      props.verifyLogin(otpKey, rememberBrowser);
    }
  };

  const handleResendOTPKey = () => {
    setOTPKey('');
    props.resendOTPKey();
  };

  const handleBypassTwoFactor = () => {
    setRememberBrowser(!rememberBrowser);
  };

  return (
    <div className="room-route">
      <Navbar />
      <TwoFactorView
        isResendLoading={props.isResendLoading}
        handleVerifyLogin={handleVerifyLogin}
        handleOtpKeyInput={handleOtpKeyInput}
        handleResendOTPKey={handleResendOTPKey}
        handleBypassTwoFactor={handleBypassTwoFactor}
        rememberBrowser={rememberBrowser}
        isDisabled={isDisabled}
        userPhone={props.userPhone}
        verifyError={props.verifyError}
        redirectToLogin={props.redirectToLogin}
        clearVerifyError={props.clearVerifyError}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loginSuccess: !!state.auth.user.id,
    userPhone: state.auth.userPhone,
    otpToken: state.auth.otpToken,
    verifyError: state.auth.verifyError,
    isFetching: state.auth.isFetching,
    redirectToLogin: state.auth.redirectToLogin,
    isResendLoading: state.auth.isResendLoading,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resendOTPKey,
      verifyLogin,
      revokeOTPToken,
      clearVerifyError,
    },
    dispatch
  );
const TherapistTwoFactor = connect(mapStateToProps, mapDispatchToProps)(TwoFactor);

export default TherapistTwoFactor;
