import { FunctionComponent } from 'react';
import { TouchableView, Standard } from '@talkspace/react-toolkit';

interface Props {
  onPress: () => void;
  sectionTitle?: string;
}

const ConfirmButton: FunctionComponent<Props> = ({ onPress, sectionTitle }) => (
  <TouchableView
    style={{ alignSelf: 'flex-end', paddingTop: 20, paddingBottom: 10 }}
    onPress={onPress}
    dataQa={`${sectionTitle}ConfirmButton`}
  >
    <Standard variant="standardBoldGreen">Confirm</Standard>
  </TouchableView>
);

export default ConfirmButton;
