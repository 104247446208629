import { useState } from 'react';
import { connect } from 'react-redux';

import { useParams } from '@/core/routerLib';
import ReactFrameService from '../../../../../modules/auth/reactFrame/ReactFrameService';
import useQueryNotes from '../../../../../hooks/notes/useQueryNotes';
import Submenu from '../../../../Reusable/Submenu';
import UserActionCard from '../../../../Reusable/UserActionCard/UserActionCard';
import { getActiveCustomerName } from '../../../../../utils/customerHelper';
import { CustomerInformation } from '../../../../../types/customer';
import useMutationCreateRoomCase from './useMutationCreateRoomCase';
import useQueryParticipants from '../../../../../hooks/useQueryParticipants';

interface Props {
  customerInformation: CustomerInformation;
}

const RestartCare = ({ customerInformation }: Props) => {
  const { roomID } = useParams<{ roomID: string }>();

  const { data: participants } = useQueryParticipants(Number(roomID));

  const [showSpinner, setShowSpinner] = useState(false);

  const { mutate: createRoomCase, isLoading, isError, isSuccess } = useMutationCreateRoomCase();

  useQueryNotes(roomID, isSuccess);

  const actionCardButtons = [
    {
      title: 'Send another session',
      clickHandler: () => {
        setShowSpinner(true);
        setTimeout(() => {
          setShowSpinner(false);

          if (ReactFrameService.instance().isInFrame()) {
            ReactFrameService.instance().closePopup({
              navigateTo: 'room',
              metadata: { roomID: Number(roomID) },
            });
          }
        }, 3000);

        createRoomCase({ roomID: Number(roomID) });
      },
    },
  ];

  const cardText =
    'If your client has a copay they will be asked to accept another session so you can re-engage in care. Once the client accepts the session you will notified and you can resume services. If your client doesn’t have a copay services can resume immediately.';

  return (
    <Submenu
      title="Restart care"
      childComponents={[
        <UserActionCard
          key={1}
          preferredName={getActiveCustomerName(customerInformation, participants || [])}
          content={cardText}
          buttons={actionCardButtons}
          isUpdating={isLoading}
          isError={isError}
          showSpinner={showSpinner}
        />,
      ]}
      prevRoute="case-tab"
    />
  );
};

const mapStateToProps = (state) => {
  return {
    customerInformation: state.customerInformation,
  };
};

export default connect(mapStateToProps)(RestartCare);
