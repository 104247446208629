import { VoidFunctionComponent, useState } from 'react';
import { Standard, View, Button } from '@talkspace/react-toolkit';
import { TasksResponse } from 'hooks/dashboard/useQueryTaskList';
import { SubmitPress, ReviewPress } from '../../types';
import styled, { EmotionStyle } from '../../../../core/styled';
import { Status } from '../TaskStatus/TaskStatus';
import ReviewNoShowModal from '../../Tasks/ReviewNoShowModal';

interface TaskActionButtonsProps {
  note: TasksResponse;
  onSubmitPress: (value: SubmitPress | ReviewPress) => void;
  onReviewPress: (value: ReviewPress) => void;
}

export const SubmitButton = styled(Button)<{ disabled: boolean }>(
  ({ theme: { colors, window }, disabled }) => {
    const { isMobile, isLarge } = window;

    const mobileStyle: EmotionStyle = isMobile ? { width: 95, marginLeft: 15 } : {};
    const tabletStyle: EmotionStyle = isLarge ? { width: 95 } : {};

    return {
      width: 135,
      minHeight: 30,
      height: 30,
      borderRadius: 5,
      margin: 0,
      background: disabled && colors.extraLightGrey,
      ...tabletStyle,
      ...mobileStyle,
    };
  }
);

const Wrapper = styled(View)(({ theme: { window } }) => {
  const { isMobile } = window;
  const mobileStyle: EmotionStyle = isMobile
    ? { flexDirection: 'row-reverse', marginTop: 15, marginBottom: 5 }
    : {};
  return {
    flexDirection: 'row',
    position: 'relative',
    ...mobileStyle,
  };
});

const TaskActionButtons: VoidFunctionComponent<TaskActionButtonsProps> = ({
  note,
  onSubmitPress,
  onReviewPress,
}) => {
  const [reviewHideMenu, setReviewHideMenu] = useState<boolean>(true);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const {
    taskID,
    roomID,
    therapistID,
    progressNoteID,
    task: { isEAP, progressNoteModality, videoCallID, sessionReportID },
    id: unsubmittedSessionID,
  } = note;

  const isReviewAction =
    note.type === Status.CLIENT_NO_SHOW || note.type === Status.CLIENT_LATE_CANCELLATION;

  const actionButtonDataQA = isReviewAction
    ? `reviewTask-roomID-${roomID}-liveCallID-${videoCallID}`
    : `submitTask-roomID-${roomID}-liveCallID-${videoCallID}`;

  return (
    <Wrapper>
      <SubmitButton
        disabled={isDisabled}
        dataQa={actionButtonDataQA}
        onPress={() => {
          const taskData = {
            roomID,
            taskID,
            therapistID,
            type: note.type,
          };
          if (['reviewClientNoShow', 'reviewClientLateCancellation'].includes(note.type)) {
            setReviewHideMenu(false);
            onReviewPress({
              ...taskData,
              bookingID: note.task.bookingID,
            });
          } else {
            onSubmitPress({
              ...taskData,
              progressNoteID,
              progressNoteModality,
              videoCallID,
              isEAP,
              sessionReportID,
              unsubmittedSessionID,
            });
          }
        }}
      >
        <Standard variant="standardWhite" style={{ fontWeight: 700 }}>
          {isReviewAction ? 'Review' : 'Submit'}
        </Standard>
      </SubmitButton>
      <ReviewNoShowModal
        handleDisable={(value: boolean) => setIsDisabled(value)}
        roomID={roomID}
        therapistID={therapistID}
        taskID={taskID}
        bookingID={note.task.bookingID}
        type={note.type}
        onBackdropPress={() => {
          setReviewHideMenu(true);
        }}
        hide={reviewHideMenu}
      />
    </Wrapper>
  );
};

export default TaskActionButtons;
