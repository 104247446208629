import Svg, { Path } from 'svgs';
import { EmotionStyle, useEmotionTheme } from '../../core/styled';

interface ExclamationCircleProps {
  width?: number;
  height?: number;
  color?: string;
  titleText?: string;
  style?: EmotionStyle;
}

const ExclamationCircle = ({
  width = 20,
  height = 20,
  titleText = 'Exclamation',
  color,
  ...otherProps
}: ExclamationCircleProps) => {
  const { colors } = useEmotionTheme();
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      title={titleText}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      {...otherProps}
    >
      <Path
        d="M10 18C12.1217 18 14.1566 17.1572 15.6569 15.6569C17.1571 14.1566 18 12.1218 18 10C18 7.8783 17.1571 5.84347 15.6569 4.34318C14.1566 2.84289 12.1217 2.00003 10 2.00003C7.87827 2.00003 5.84344 2.84289 4.34315 4.34318C2.84285 5.84347 2 7.8783 2 10C2 12.1218 2.84285 14.1566 4.34315 15.6569C5.84344 17.1572 7.87827 18 10 18ZM8.75 12.5H9.5V10.5H8.75C8.33437 10.5 8 10.1657 8 9.75003C8 9.33441 8.33437 9.00003 8.75 9.00003H10.25C10.6656 9.00003 11 9.33441 11 9.75003V12.5H11.25C11.6656 12.5 12 12.8344 12 13.25C12 13.6657 11.6656 14 11.25 14H8.75C8.33437 14 8 13.6657 8 13.25C8 12.8344 8.33437 12.5 8.75 12.5ZM10 6.00003C10.2652 6.00003 10.5196 6.10539 10.7071 6.29292C10.8946 6.48046 11 6.73481 11 7.00003C11 7.26525 10.8946 7.5196 10.7071 7.70714C10.5196 7.89467 10.2652 8.00003 10 8.00003C9.73478 8.00003 9.48043 7.89467 9.29289 7.70714C9.10536 7.5196 9 7.26525 9 7.00003C9 6.73481 9.10536 6.48046 9.29289 6.29292C9.48043 6.10539 9.73478 6.00003 10 6.00003Z"
        fill={color || colors.aqua}
      />
    </Svg>
  );
};

export default ExclamationCircle;
