export enum ClientCancellationReasonEnum {
  CLIENT_NOT_AVAILABLE = 'clientNotAvailable',
  PROVIDER_NOT_AVAILABLE = 'providerNotAvailable',
  PROVIDER_NOT_RIGHT_FIT = 'providerNotRightFit',
  RESCHEDULE = 'reschedule',
  OTHER = 'other',
}
export type ClientCancellationReasonOption = { value: ClientCancellationReasonEnum; label: string };

export enum ProviderCancellationReasonEnum {
  CLIENT_REQUESTED = 'clientRequestedProvider',
  PROVIDER_NOT_AVAILABLE = 'providerNotAvailableProvider',
  AGREED_TO_RESCHEDULE = 'agreedToRescheduleProvider',
  RESCHEDULE = 'rescheduleProvider',
  OTHER = 'otherProvider',
}
export type ProviderCancellationReasonOption = {
  value: ProviderCancellationReasonEnum;
  label: string;
};
