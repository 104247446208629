import { Component } from 'react';
import {
  Grid,
  Row,
  Col,
  Panel,
  FormGroup,
  FormControl,
  Button,
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { toast, ToastContainer } from 'react-toastify';
import '../FormHeader/FormHeader.css';
import 'react-toastify/dist/ReactToastify.min.css';
import { connect } from 'react-redux';
import { getUserData } from '../../../utils/token';
import apiWrapper from '../../../utils/apiWrapper';
import apiHelper from '../../../utils/api';
import XIcon from '../XIcon';

const TherapistItemToken = (props) => (
  <div className="therapist-item-token">
    {props.option.text}
    <span className="therapist-token-close-button" onClick={props.onRemove}>
      <XIcon />
    </span>
  </div>
);

class TherapistTreatmentApproach extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedModalitiesItems: [],
      selectedOrientationItems: [],
      selectedModalitiesValues: [],
      selectedOrientationValues: [],
      addedItems: [],
      deletedItems: [],
      data: {
        modality: [],
        orientation: [],
      },
      isEdit: false,
      isLoaded: false,
    };
    this.getTreatmentApproachInfo = this.getTreatmentApproachInfo.bind(this);
    this.toggleFormEdit = this.toggleFormEdit.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.saveForm = this.saveForm.bind(this);
    this.filterSelectedItems = this.filterSelectedItems.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.initializeItemValues = this.initializeItemValues.bind(this);
    this.renderTherapistItemToken = this.renderTherapistItemToken.bind(this);
    this.addTreatmentApproachItems = this.addTreatmentApproachItems.bind(this);
    this.deleteTreatmentApproachItems = this.deleteTreatmentApproachItems.bind(this);
    this.updateOriginalData = this.updateOriginalData.bind(this);
    this.getUpdatedItems = this.getUpdatedItems.bind(this);
    this.areSaveResponsesValid = this.areSaveResponsesValid.bind(this);
  }

  componentDidMount() {
    this.getTreatmentApproachInfo();
  }

  getTreatmentApproachInfo() {
    const userId = getUserData().id;

    return apiWrapper
      .get(`${apiHelper().apiEndpoint}/api/v1/therapist/${userId}/treatment-preferences-info`)
      .then((response) => {
        const { data } = response.data;
        Object.entries(data).forEach(
          ([key, value]) => (data[key] = value.sort((a, b) => (a.text > b.text ? 1 : -1)))
        );
        const selectedModalitiesItems = this.filterSelectedItems(data.modality);
        const selectedOrientationItems = this.filterSelectedItems(data.orientation);
        const selectedModalitiesValues = this.initializeItemValues(data.modality);
        const selectedOrientationValues = this.initializeItemValues(data.orientation);
        this.setState({
          data,
          selectedModalitiesItems,
          selectedOrientationItems,
          selectedModalitiesValues,
          selectedOrientationValues,
          isLoaded: true,
        });
      })
      .catch((err) => {
        toast(
          <div
            className="toaster toaster-error"
            style={{ background: '#FF0000', color: '#FFF', padding: '12px', fontSize: '16px' }}
          >
            Server Error!
          </div>,
          { autoClose: 3000 }
        );
      });
  }

  getUpdatedItems(items) {
    const updatedItems = items.map((item) => {
      let { selected } = item;

      if (this.state.addedItems.indexOf(item.value) > -1) selected = true;
      if (this.state.deletedItems.indexOf(item.value) > -1) selected = false;

      const updatedItem = {
        text: item.text,
        value: item.value,
        selected,
      };

      return updatedItem;
    });

    return updatedItems;
  }

  addTreatmentApproachItems(userId) {
    const { addedItems } = this.state;
    if (addedItems.length > 0) {
      return apiWrapper.post(
        `${apiHelper().apiEndpoint}/api/v1/therapist/${userId}/add-fields-of-expertise`,
        {
          items: addedItems,
        }
      );
    }
    return undefined;
  }

  areSaveResponsesValid(addResponse, deleteResponse) {
    const isAddResponseValid =
      addResponse === undefined || (addResponse.data && addResponse.data.success);
    const isDeleteResponseValid =
      deleteResponse === undefined || (deleteResponse.data && deleteResponse.data.success);
    const responsesAreValid = isAddResponseValid && isDeleteResponseValid;

    return responsesAreValid;
  }

  cancelEdit() {
    const selectedModalitiesItems = this.filterSelectedItems(this.state.data.modality);
    const selectedOrientationItems = this.filterSelectedItems(this.state.data.orientation);
    const selectedModalitiesValues = this.initializeItemValues(this.state.data.modality);
    const selectedOrientationValues = this.initializeItemValues(this.state.data.orientation);
    this.refs.orientationTypeahead.getInstance().setState({ selected: selectedOrientationItems });
    this.refs.modalityTypeahead.getInstance().setState({ selected: selectedModalitiesItems });
    const editBool = !this.state.isEdit;
    const actionItems = document.querySelectorAll([
      '#treatment-preferences .btn-ts-default',
      '#treatment-preferences .ts-edit-button',
    ]);
    [].forEach.call(actionItems, (item) => {
      item.classList.toggle('hidden');
    });
    this.setState({
      addedItems: [],
      deletedItems: [],
      isEdit: editBool,
      selectedOrientationItems,
      selectedModalitiesItems,
      selectedOrientationValues,
      selectedModalitiesValues,
    });
  }

  deleteTreatmentApproachItems(userId) {
    const { deletedItems } = this.state;
    if (deletedItems.length > 0) {
      return apiWrapper.post(
        `${apiHelper().apiEndpoint}/api/v1/therapist/${userId}/delete-fields-of-expertise`,
        {
          items: deletedItems,
        }
      );
    }
    return undefined;
  }

  filterSelectedItems(items) {
    return items.filter((item) => item.selected);
  }

  handleChange(event, field) {
    const { addedItems } = this.state;
    const { deletedItems } = this.state;
    const selectedValues = this.state[field];
    const eventItemValues = this.initializeItemValues(event);
    if (event.length > selectedValues.length) {
      event.forEach((item) => {
        if (deletedItems.indexOf(item.value) > -1) {
          deletedItems.splice(deletedItems.indexOf(item.value), 1);
          selectedValues.push(item.value);
        } else if (selectedValues.indexOf(item.value) < 0 && addedItems.indexOf(item.value) < 0) {
          addedItems.push(item.value);
          selectedValues.push(item.value);
        }
      });
    } else {
      selectedValues.forEach((value) => {
        if (addedItems.indexOf(value) > -1) {
          addedItems.splice(addedItems.indexOf(value), 1);
          selectedValues.splice(selectedValues.indexOf(value), 1);
        } else if (eventItemValues.indexOf(value) < 0 && deletedItems.indexOf(value) < 0) {
          deletedItems.push(value);
          selectedValues.splice(selectedValues.indexOf(value), 1);
        }
      });
    }
    this.setState({ addedItems, deletedItems, field });
  }

  initializeItemValues(items) {
    return items.reduce((allItems, item) => {
      if (allItems.indexOf(item.value) < 0 && item.selected) {
        allItems.push(item.value);
      }
      return allItems;
    }, []);
  }

  saveForm() {
    this.toggleFormEdit();
    const userId = getUserData().id;
    Promise.all([this.addTreatmentApproachItems(userId), this.deleteTreatmentApproachItems(userId)])
      .then(([addResponse, deleteResponse]) => {
        if (this.areSaveResponsesValid(addResponse, deleteResponse)) {
          this.updateOriginalData(() => {
            this.setState({
              addedItems: [],
              deletedItems: [],
            });
          });
          toast(<div className="toaster toaster-success">Changes are saved successfully!</div>, {
            autoClose: 3000,
          });
        } else {
          toast(
            <div
              className="toaster toaster-error"
              style={{ background: '#e8006b', color: '#FFF', padding: '12px', fontSize: '16px' }}
            >
              Something went wrong
            </div>,
            { autoClose: 3000 }
          );
        }
      })
      .catch((error) => {
        toast(
          <div
            className="toaster toaster-error"
            style={{ background: '#e8006b', color: '#FFF', padding: '12px', fontSize: '16px' }}
          >
            Something went wrong
          </div>,
          { autoClose: 3000 }
        );
      });
  }

  toggleFormEdit() {
    const editBool = !this.state.isEdit;
    this.setState({ isEdit: editBool });
    const actionItems = document.querySelectorAll([
      '#treatment-preferences .btn-ts-default',
      '#treatment-preferences .ts-edit-button',
    ]);
    [].forEach.call(actionItems, (item) => {
      item.classList.toggle('hidden');
    });
  }

  updateOriginalData(resetItemsArraysInState) {
    const { data } = this.state;
    const newModality = this.getUpdatedItems(data.modality);
    const newOrientation = this.getUpdatedItems(data.orientation);

    this.setState(
      {
        data: {
          modality: newModality,
          orientation: newOrientation,
        },
      },
      resetItemsArraysInState
    );
  }

  renderTherapistItemToken(selectedItem, onRemove) {
    return (
      <TherapistItemToken
        key={selectedItem.value}
        disabled={!this.state.isEdit}
        onRemove={onRemove}
        option={selectedItem}
      />
    );
  }

  render() {
    const title = 'Treatment Approach';
    const tooltip = (
      <Tooltip id="TreatmentApproachTooltip" className="tooltip ts-tooltip ts-profile-text-white">
        Your clinical expertise helps better match you with relevant clients. Please pick up to 3
        issues under Modality.
      </Tooltip>
    );
    const disabledClassName = this.state.isEdit ? '' : 'typeahead-disabled';
    const isSaveDisabled =
      this.state.addedItems.length === 0 && this.state.deletedItems.length === 0;
    return (
      <div>
        <Col
          xs={12}
          id="treatment-preferences"
          className={`ts-panel-title ${this.state.isLoaded ? 'show-panel' : 'hidden-panel'}`}
        >
          <Col xs={5} className="ts-font-black">
            {title}
            <OverlayTrigger placement="top" trigger={['click', 'focus', 'hover']} overlay={tooltip}>
              <i className="fa fa-fw fa-question-circle fa-lg" />
            </OverlayTrigger>
          </Col>
          <Button className="ts-edit-button pull-right" onClick={this.toggleFormEdit}>
            Edit
          </Button>
          <Button
            className="btn-ts-default btn-ts-green ts-profile-text-white pull-right hidden save-btn"
            onClick={this.saveForm}
            disabled={isSaveDisabled}
          >
            {' '}
            Save Changes{' '}
          </Button>
          <Button
            className="btn-ts-default ts-profile-btn-text-green cancel-btn pull-right hidden"
            onClick={this.cancelEdit}
          >
            {' '}
            Cancel{' '}
          </Button>
        </Col>
        <Col xs={12}>
          <Panel
            className={`ts-my-account-panel ${
              this.state.isLoaded ? 'show-panel' : 'hidden-panel'
            } ${disabledClassName}`}
          >
            <Row style={{ marginBottom: '10px' }}>
              <Col className="ts-profile-label" md={2}>
                Orientation
              </Col>
              <Col md={10} className="typeahead-field">
                <Typeahead
                  ref="orientationTypeahead"
                  selected={this.state.selectedOrientationItems}
                  labelKey={(option) => `${option.text}`}
                  options={this.state.data.orientation}
                  multiple
                  renderToken={this.renderTherapistItemToken}
                  onChange={(event) => this.handleChange(event, 'selectedOrientationValues')}
                  disabled={!this.state.isEdit}
                  placeholder="Select orientation"
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: '10px' }}>
              <Col className="ts-profile-label" md={2}>
                Modality
              </Col>
              <Col md={10} className="typeahead-field">
                <Typeahead
                  ref="modalityTypeahead"
                  selected={this.state.selectedModalitiesItems}
                  labelKey={(option) => `${option.text}`}
                  options={this.state.data.modality}
                  multiple
                  renderToken={this.renderTherapistItemToken}
                  onChange={(event) => this.handleChange(event, 'selectedModalitiesValues')}
                  disabled={!this.state.isEdit}
                  placeholder="Select modality"
                />
              </Col>
            </Row>
          </Panel>
        </Col>
      </div>
    );
  }
}

export default TherapistTreatmentApproach;
