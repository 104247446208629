import { connect } from 'react-redux';
import moment from 'moment';
import { UserVideoCredit } from 'ts-frontend/types';
import { useHistory, useParams } from '@/core/routerLib';
import UserActionCard from '../../../../Reusable/UserActionCard/UserActionCard';
import Submenu from '../../../../Reusable/Submenu/Submenu';
import ListItem from '../../../../Reusable/ListItems/ListItem';
import ItemList from '../../../../Reusable/ItemList/ItemList';
import { formatDateShort, formatDateTimeShort } from '../../../../../utils/date';
import { getActiveCustomerName } from '../../../../../utils/customerHelper';
import useQueryVideoCredits from '../../../../../hooks/useQueryVideoCredits';
import useQueryParticipants from '../../../../../hooks/useQueryParticipants';

const formatRedeemed = (redeemedAt: string) => {
  if (redeemedAt) return formatDateTimeShort(redeemedAt);
  return 'No';
};

const formatVideoCredit = (videoCredit: UserVideoCredit) => {
  return {
    validBetween:
      videoCredit.purchasedInRoomID === 0
        ? 'Always valid'
        : `${formatDateShort(videoCredit.validFrom)} - ${moment(videoCredit.validTo)
            .subtract(1, 'd')
            .format('MMM DD')}`,
    duration:
      videoCredit.creditMinutesOptions && !videoCredit.redeemedAt
        ? `[${videoCredit.creditMinutesOptions}]min`
        : `${videoCredit.creditMinutes} min`,
    redeemed: formatRedeemed(videoCredit.redeemedAt),
  };
};

interface Props {
  customerInformation: Record<string, unknown>;
}

const VideoCredits = ({ customerInformation }: Props) => {
  const history = useHistory();

  const { roomID } = useParams<{ roomID: string }>();

  const { data: participants } = useQueryParticipants(Number(roomID));

  const { data: videoCredits, isLoading, isError } = useQueryVideoCredits(Number(roomID));

  const renderItems = () =>
    (videoCredits || []).map((videoCredit) => (
      <ListItem
        key={`${videoCredit.userID}-${videoCredit.validFrom}`}
        itemDataOrder={['validBetween', 'duration', 'redeemed']}
        itemDisplayData={formatVideoCredit(videoCredit)}
        status={videoCredit.redeemedAt}
        widths={[105, 92, 107]}
      />
    ));

  const renderItemList = () => (
    <ItemList
      items={renderItems()}
      titles={['Valid between', 'Duration', 'Redeemed']}
      noDataMessage="No video credits found"
      widths={[105, 92, 107]}
      cssClass={undefined}
    />
  );

  const getVideoCallHistoryButtons = () => [
    {
      isSecondary: false,
      title: 'View live session history',
      clickHandler: () => {
        history.push(`/room/${roomID}/video-call-history`);
      },
    },
  ];

  const renderUserActionCard = () => (
    <UserActionCard
      preferredName={getActiveCustomerName(customerInformation, participants || [])}
      content={renderItemList()}
      buttons={getVideoCallHistoryButtons()}
      isError={isError}
      showSpinner={isLoading}
      spinnerMessage="Loading live session credits"
    />
  );

  return (
    <Submenu
      childComponents={[renderUserActionCard()]}
      title="Live session credits"
      prevRoute="case-tab"
    />
  );
};

const mapStateToProps = (state) => {
  return {
    customerInformation: state.customerInformation,
  };
};

export default connect(mapStateToProps)(VideoCredits);
