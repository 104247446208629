import { FunctionComponent } from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { routePromise } from 'ts-frontend/helpers';
import PasswordFormik from '@talkspace/react-toolkit/src/components/Password/PasswordFormik';
import ErrorMessage from '../components/ErrorMessage';
import { patchTherapistCredentials } from '../utils/apiHelper';
import useCredentials from '../hooks/useCredentials';
import useToken from '../hooks/useToken';
import SignupInput from '../components/SignupInput';
import ContinueButton from '../components/ContinueButton';

const validationSchema = yup.object().shape({
  email: yup.string().required('Required'),
  password: yup.string().required('Required'),
  confirmedPassword: yup.string().required('Required'),
});

const CredentialsForm: FunctionComponent<{}> = () => {
  const token = useToken();
  const { data: initialValues } = useCredentials(token);

  const onSubmit = async (values, formikHelpers) => {
    try {
      await patchTherapistCredentials(values, token);
      routePromise('/login');
    } catch (err) {
      formikHelpers.setStatus('Something went wrong. Please try again.');
      formikHelpers.setSubmitting(false);
    }
  };
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, isSubmitting, isValid, status: apiError }) => (
        <Form>
          <SignupInput fieldName="email" label="Email" placeholder="Email" required />
          <PasswordFormik
            fieldName="password"
            label="Password"
            minValidScore={3}
            placeholder="Enter password"
            containerStyle={{ marginTop: 18 }}
            infoMessageContent="At least 8 characters, no special characters."
            scoreStyle={{ flexDirection: 'row' }}
            passwordEyeStyle={{ top: 64 }}
          />
          <PasswordFormik
            fieldName="confirmedPassword"
            isConfirmationInput
            passwordToCompare={values.password}
            label="Confirm password"
            placeholder="Confirm password"
            containerStyle={{ marginTop: 18 }}
            passwordEyeStyle={{ top: 64 }}
          />
          <ContinueButton
            disabled={isSubmitting || !isValid}
            isLoading={isSubmitting}
            label="Sign up"
          />
          {apiError && <ErrorMessage style={{ marginTop: 12 }}>{apiError}</ErrorMessage>}
        </Form>
      )}
    </Formik>
  );
};
export default CredentialsForm;
