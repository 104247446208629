import React, { useCallback, useEffect, useState, forwardRef } from 'react';
import { EmotionStyle } from '../../core/styled';
import View from '../View';
import useShareForwardedRef from '../../hooks/useShareForwardedRef';

interface Props {
  children: React.ReactNode;
  topRef?: React.RefObject<HTMLDivElement>;
  style?: EmotionStyle;
  headerTopClipping?: number;
  usingPortal?: boolean;
  className?: string;
}
const StickyContainer = forwardRef<HTMLDivElement, Props>(
  ({ children, topRef, style, headerTopClipping = 0, usingPortal, className }, ref) => {
    const containerRef = useShareForwardedRef(ref);

    const [top, setTop] = useState<number | undefined>();

    useEffect(() => {
      const topRefOffset = usingPortal
        ? topRef?.current?.getBoundingClientRect().height
        : topRef?.current?.getBoundingClientRect().bottom;
      // set initial position
      setTop(topRef ? (topRefOffset || 0) + headerTopClipping : undefined);
    }, [topRef, headerTopClipping, usingPortal]);

    const handleScroll = useCallback(() => {
      const bottom = topRef?.current?.getBoundingClientRect().bottom;
      setTop(bottom ? bottom + headerTopClipping : undefined);
    }, [topRef, headerTopClipping]);

    useEffect(() => {
      // Anchored to existing fixed/absolute div, no need to change based on scroll
      if (usingPortal) return () => undefined;
      // update position on scroll (the topRef component might move on scroll)
      window.addEventListener('scroll', handleScroll, {
        capture: true,
        passive: true,
      });
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, [usingPortal, handleScroll]);

    return (
      <View
        ref={containerRef}
        style={{
          ...style,
          ...(topRef ? { position: 'absolute', top, zIndex: 100 } : {}),
        }}
        className={className}
      >
        {children}
      </View>
    );
  }
);

export default StickyContainer;
