/* eslint-disable jsx-a11y/label-has-for */
import React, { useEffect, useState } from 'react';
import './StripeCSS.css';

const StripeInputField = ({ label, placeholder, dataQa, onChange, value, error }) => {
  const [inputClasses, setInputClasses] = useState([
    'p-HeightObserver',
    'p-LinkAuth',
    'p-LinkAuth--input',
    'Input',
    'Input--empty',
    'p-HeightObserver--delayIncrease',
  ]);

  useEffect(() => {
    if (error) {
      if (!inputClasses.includes('Input--invalid')) {
        const copyClasses = [...inputClasses];
        copyClasses.push('Input--invalid');
        setInputClasses(copyClasses);
      }
    } else if (inputClasses.includes('Input--invalid')) {
      const copyClasses = inputClasses.filter((cls) => cls !== 'Input--invalid');
      setInputClasses(copyClasses);
    }
  }, [error, inputClasses]);

  const onChangeInput = (event) => {
    const newValue = event.target.value;

    if (typeof onChange === 'function') onChange(newValue);
  };

  const focusInput = () => {
    const copyClasses = inputClasses.filter((cls) => cls !== 'Input--empty');
    copyClasses.push('p-Input--focused');
    setInputClasses(copyClasses);
  };

  const blurInput = () => {
    const copyClasses = inputClasses.filter((cls) => cls !== 'p-Input--focused');
    copyClasses.push('Input--empty');
    setInputClasses(copyClasses);
  };

  const id = `stripe-${label?.replaceAll(' ', '-')}`;
  const errorAriaAttributes = error
    ? { 'aria-describedby': `${id}-error`, 'aria-invalid': true }
    : {};
  return (
    <div style={{ marginLeft: '-4px', marginRight: '-4px', marginBottom: 13, textAlign: 'left' }}>
      <div className="p-HeightObserverProvider">
        <div className="p-HeightObserverProvider-container">
          <div className="p-Locale-en p-Animatable p-LTR">
            <div className="p-FadeWrapper">
              <div className="p-Fade">
                <div className="p-Fade-item">
                  <div className="p-Field">
                    <label
                      className="p-FieldLabel Label Label--empty"
                      style={{ fontWeight: 400, color: 'rgb(34, 47, 45)' }}
                      htmlFor={id}
                    >
                      {label}
                    </label>
                    <div>
                      <div
                        className={inputClasses.join(' ')}
                        style={{ height: '42.3984px' }}
                        onFocus={focusInput}
                        onBlur={blurInput}
                      >
                        <div style={{ height: 'inherit' }}>
                          <div style={{ position: 'relative', height: 'inherit' }}>
                            <div className="p-LinkInputWrapper" style={{ height: 'inherit' }}>
                              <input
                                {...errorAriaAttributes}
                                id={id}
                                className="p-Input-input Input p-LinkInput p-Input--delayAutofillStyles Input--empty p-Input-input--textRight"
                                style={{
                                  height: 'inherit',
                                  fontSize: 16,
                                  color: 'rgb(34, 47, 45)',
                                  padding: 13,
                                }}
                                data-qa={dataQa}
                                placeholder={placeholder}
                                onChange={onChangeInput}
                                value={value}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {error ? (
                      <p className="p-FieldError Error" id={`${id}-error`}>
                        {error}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StripeInputField;
