import Svg, { G, Path } from 'svgs';
import { useEmotionTheme } from '../../core/styled';

const RedX = ({ size = 66, ...otherProps }) => {
  const { colors } = useEmotionTheme();
  const titleText = 'Red X in circle';
  return (
    <Svg
      width={size}
      height={size}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 66 66"
      {...otherProps}
    >
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <G transform="translate(-154 -78)">
          <Path
            d="M49.658 6.244C35.985-4.06 16.548-1.328 6.244 12.346c-10.304 13.673-7.572 33.11 6.102 43.413 13.673 10.304 33.11 7.572 43.413-6.101 10.304-13.673 7.572-33.11-6.101-43.414h0z"
            stroke={colors.heartRed}
            strokeWidth={4}
            transform="translate(36 80) translate(120)"
          />
          <Path
            d="M40.932 17.698a2.383 2.383 0 013.37 3.37L34.37 31l9.932 9.932a2.383 2.383 0 01-3.37 3.37L31 34.37l-9.932 9.932a2.383 2.383 0 01-3.24.121l-.13-.12c-.93-.931-.93-2.44 0-3.37L27.63 31l-9.932-9.932a2.383 2.383 0 013.37-3.37L31 27.63z"
            fill={colors.heartRed}
            fillRule="nonzero"
            transform="translate(36 80) translate(120)"
          />
        </G>
        <Path
          d="M19.14-1148.5v42.75c0 11.755-9.434 21.307-21.144 21.497l-.356.003H-208c-16.676 0-30.227 13.384-30.496 29.996l-.004.504V157c0 16.676 13.384 30.227 29.996 30.496l.504.004h13.5v-4l9 4.5-9 4.5v-4H-208c-17.223 0-31.218-13.822-31.496-30.98l-.004-.52v-1210.75c0-17.223 13.822-31.218 30.98-31.496l.52-.004H-2.36c11.207 0 20.313-8.992 20.497-20.154l.003-.346v-42.75h1z"
          fill={colors.purple}
          fillRule="nonzero"
        />
        <Path
          d="M19.14-1148.5v42.75c0 11.755-9.434 21.307-21.144 21.497l-.356.003H-208c-16.676 0-30.227 13.384-30.496 29.996l-.004.504V882.193c0 16.676 13.384 30.227 29.996 30.496l.504.004h13.5v-4l9 4.5-9 4.5v-4H-208c-17.223 0-31.218-13.822-31.496-30.979l-.004-.52V-1053.75c0-17.223 13.822-31.218 30.98-31.496l.52-.004H-2.36c11.207 0 20.313-8.992 20.497-20.154l.003-.346v-42.75h1z"
          fill={colors.purple}
          fillRule="nonzero"
        />
      </G>
    </Svg>
  );
};

export default RedX;
