// Importing toolkit directly will cause static-components to have a heart attack (fail builds)
import { OptionGroupType } from '@talkspace/react-toolkit/src/components/Select/types';
import { filterSelectOptions } from '@talkspace/react-toolkit/src/utils/asyncSelectUtils';
import { PayerOptionType } from '../types';

export const ORGANIZATION_OPTION: PayerOptionType = {
  id: -1,
  label: 'I have Talkspace through an organization',
  value: '-1',
  partnerID: null,
  isOutOfNetwork: false,
  flowID: null,
  serviceTypes: null,
};

export const OUT_OF_POCKET_OPTION: PayerOptionType = {
  id: 0,
  label: 'I’ll pay out of pocket',
  value: '0',
  partnerID: null,
  isOutOfNetwork: false,
  flowID: null,
  serviceTypes: null,
};

const getSearchResults = (options, input, experimentalSearchResults = false) => {
  const results = options?.filter(filterSelectOptions(input)) || [];
  results.sort(
    (a, b) =>
      a.label.toLowerCase().indexOf(input.toLowerCase()) -
      b.label.toLowerCase().indexOf(input.toLowerCase())
  );
  if (experimentalSearchResults && results.length === 0) {
    results.push(
      {
        label: `No results for "${input}"`,
        isDisabled: true,
      },
      {
        id: 0,
        label: 'Continue with out of pocket care',
        value: '0',
      }
    );
  }
  return results;
};

export const getInsurancePayersOptions =
  (options: PayerOptionType[] | undefined, experimentalSearchResults?: boolean) =>
  (input: string, callback: (options: PayerOptionType[]) => void) => {
    callback(getSearchResults(options, input, experimentalSearchResults));
  };

export const getGroupPayersOptions =
  (options: OptionGroupType<string>[] | undefined) =>
  (input: string, callback: (options: PayerOptionType[]) => void) => {
    const flattenedOptions = options
      ? ([
          // ungrouped options
          ...options.filter((el) => !el.options),
          // grouped options, flatten
          ...options
            .filter((el) => el.options)
            .map((el) => el.options)
            .flat(),
        ] as PayerOptionType[])
      : [];
    callback(flattenedOptions.filter(filterSelectOptions(input)));
  };
