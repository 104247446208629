import Svg, { Path, Defs, Rect, Circle } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

const NoStarredMessages = ({ width = 148, height = 132, ...otherProps }) => {
  const titleText = 'sparkling star';
  const { colors } = useEmotionTheme();
  return (
    <Svg width={width} height={height} title={titleText} aria-label={titleText} {...otherProps}>
      <Defs>
        <Rect id="b" width="1014" height="460" x="93" y="178" rx="10" />
        <filter
          id="a"
          width="100.8%"
          height="101.7%"
          x="-.4%"
          y="-.4%"
          filterUnits="objectBoundingBox"
        >
          <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1" />
          <feComposite
            in="shadowBlurOuter1"
            in2="SourceAlpha"
            operator="out"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
          />
        </filter>
        <Path
          id="d"
          d="M70.072 83.27L56.785 88l.175-14.879a2.768 2.768 0 00-.51-1.634L48 59.57l13.425-4.395a2.768 2.768 0 001.435-1.084L71 42l8.14 12.09c.344.512.849.894 1.435 1.085L94 59.57l-8.45 11.917a2.768 2.768 0 00-.51 1.634L85.215 88l-13.287-4.73a2.768 2.768 0 00-1.856 0z"
        />
        <filter
          id="c"
          width="251%"
          height="251.2%"
          x="-75.5%"
          y="-56.4%"
          filterUnits="objectBoundingBox"
        >
          <feMorphology
            in="SourceAlpha"
            operator="dilate"
            radius="6.388"
            result="shadowSpreadOuter1"
          />
          <feOffset dy="10" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="6.5" />
          <feComposite
            in="shadowBlurOuter1"
            in2="SourceAlpha"
            operator="out"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0.538876488 0 0 0 0 0.2136924 0 0 0 0 0 0 0 0 0.130887681 0"
          />
        </filter>
      </Defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-526 -250)">
          <use fill={colors.black} filter="url(#a)" xlinkHref="#b" />
          <Rect
            width="1013"
            height="459"
            x="93.5"
            y="178.5"
            fill={colors.white}
            stroke={colors.a11yLinkWaterGrey}
            strokeLinejoin="round"
            rx="10"
          />
        </g>
        <g transform="translate(-526 -250) translate(529 249)">
          <circle
            cx="71"
            cy="67"
            r="65"
            fill={colors.white}
            stroke={colors.a11yLinkWaterGrey}
            strokeWidth="2"
          />
          <use fill={colors.black} filter="url(#c)" xlinkHref="#d" />
          <path
            fill={colors.gold}
            stroke={colors.goldenTanoi}
            strokeWidth="6.388"
            d="M71 86.33l-17.462 6.217.226-19.325-10.876-15.339 17.404-5.698L71 36.281l10.708 15.904 17.404 5.698-10.876 15.339.226 19.325L71 86.33z"
          />
          <g fill="#C0E3E0">
            <Rect
              width="5"
              height="15"
              x="0"
              y="0"
              rx="2.5"
              transform="rotate(65 10.25 16.09) translate(5.178 .178)"
            />
            <Rect
              width="5"
              height="15"
              x="0"
              y="0"
              rx="2.5"
              transform="rotate(65 10.25 16.09) rotate(90 5 10.178)"
            />
          </g>
          <Circle cx="4.5" cy="106.5" r="4.5" stroke={colors.conifer} strokeWidth="5" />
          <Circle cx="134" cy="100" r="8" stroke={colors.a11yLavendarBlue} strokeWidth="6" />
        </g>
      </g>
    </Svg>
  );
};

export default NoStarredMessages;
