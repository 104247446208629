import { FunctionComponent } from 'react';
import Svg, { G, Path, Defs, Polygon, Use } from 'svgs';

interface Props {
  color?: string;
  width?: number;
  height?: number;
  style?: object;
}

const PencilIcon: FunctionComponent<Props> = ({
  color,
  width = 15,
  height = 15,
  ...otherProps
}) => (
  <Svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    title="Pencil"
    {...otherProps}
  >
    <Defs>
      <Path
        d="M15,190.002267 C15,187.239591 17.241966,185 19.995496,185 L354.004504,185 C356.76344,185 359,187.24675 359,190.002267 L359,987.997733 C359,990.760409 356.758034,993 354.004504,993 L19.995496,993 C17.2365597,993 15,990.75325 15,987.997733 L15,190.002267 Z"
        id="path-1"
      />
      <filter
        x="-1.6%"
        y="-0.6%"
        width="103.2%"
        height="101.4%"
        filterUnits="objectBoundingBox"
        id="filter-2"
      >
        <feMorphology radius="0.5" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1" />
        <feOffset dx="0" dy="1" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="1.5" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.0621320199 0"
          type="matrix"
          in="shadowBlurOuter1"
        />
      </filter>
    </Defs>
    <G id="Progress-notes" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <G id="clinical-tab-copy-13" transform="translate(-70.000000, -483.000000)">
        <Polygon id="Rectangle-10" fill="#FFFFFF" points="0 0 375 0 375 2000 0 2000" />
        <G id="Rectangle-20">
          <Use fill="black" fillOpacity="1" />
          <Path
            stroke="#C4C4CE"
            strokeWidth="0.5"
            d="M354.004504,184.75 C355.452118,184.75 356.762835,185.338953 357.71199,186.289395 C358.662244,187.240937 359.25,188.554764 359.25,190.002267 L359.25,190.002267 L359.25,987.997733 C359.25,989.447365 358.662115,990.759846 357.713053,991.710195 C356.762944,992.661591 355.450908,993.25 354.004504,993.25 L354.004504,993.25 L19.995496,993.25 C18.5478816,993.25 17.237165,992.661047 16.2880101,991.710605 C15.3377565,990.759063 14.75,989.445236 14.75,987.997733 L14.75,987.997733 L14.75,190.002267 C14.75,188.552635 15.3378846,187.240154 16.2869472,186.289805 C17.2370556,185.338409 18.5490919,184.75 19.995496,184.75 L19.995496,184.75 Z"
            fill="#FFFFFF"
            fillRule="evenodd"
          />
        </G>
        <G
          id="Group-7"
          transform="translate(70.000000, 481.000000)"
          fill="#09B198"
          fillRule="nonzero"
        >
          <Path
            d="M3.873949,2.0006078 C3.62451056,2.0006078 3.37202647,2.09633697 3.18319639,2.28475627 C3.18319639,2.28445237 3.18289183,2.28506017 3.18258726,2.28536407 L3.18197813,2.28597188 L0.280999903,5.21102962 C0.27886795,5.21315694 0.276735998,5.21498035 0.27490861,5.21710766 L0.27490861,5.21710766 C-0.0905689626,5.60427894 -0.0951374323,6.22241582 0.282218161,6.59925443 L10.4059469,16.7009603 C10.5895994,16.8842133 10.8426926,16.9851088 11.0948721,16.9851088 L14.0244794,17 C14.024784,17 14.0250886,17 14.0253931,17 C14.0256977,17 14.0260023,17 14.0263068,17 L14.0263068,17 C14.2781818,17 14.531275,16.8988006 14.7149275,16.7158515 C14.9086306,16.5225698 15,16.2651647 15,16.0135338 C15,16.0132299 15,16.012926 15,16.0126221 C15,16.0123182 15,16.0120143 15,16.0117104 L15,16.0117104 L14.9850763,13.0732809 C14.9850763,12.8000729 14.8629459,12.5709308 14.7158412,12.4034807 C14.710359,12.3970987 14.7051814,12.3913246 14.6996993,12.3858544 L14.6996993,12.3858544 L6.60132539,4.31998865 L4.57718877,2.28506017 C4.57444768,2.28232505 4.5717066,2.27958993 4.56896552,2.27715872 L4.56866096,2.27685482 C4.37465328,2.09451355 4.12369201,2 3.87455813,2 L3.873949,2.0006078 Z M3.87181705,4.36162324 L4.51901692,5.00741521 L2.98035634,6.54272864 L2.33315647,5.89693667 L3.87181705,4.36162324 Z M5.91179103,6.39715953 L13.0230709,13.4929697 L13.0346444,15.0249402 L11.4841058,15.0130881 L4.37343502,7.93247295 L5.91179103,6.39715953 Z"
            id="Shape"
            transform="translate(7.500000, 9.500000) scale(-1, 1) translate(-7.500000, -9.500000) "
          />
        </G>
      </G>
    </G>
  </Svg>
);

export default PencilIcon;
