import { FunctionComponent } from 'react';
import Svg, { G, Path, Rect } from 'svgs';
import { useEmotionTheme, EmotionStyle } from '../../core/styled';

interface CalendarIconProps {
  color?: string;
  iconColor?: string;
  width?: number;
  height?: number;
  style?: EmotionStyle;
}

const CalendarIcon: FunctionComponent<CalendarIconProps> = ({
  color,
  iconColor,
  width = 70,
  height = 70,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'calendar';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 72 72"
      {...otherProps}
    >
      <defs>
        <filter
          x="-23.6%"
          y="-18.1%"
          width="147.2%"
          height="147.2%"
          filterUnits="objectBoundingBox"
          id="CalendarIcon__a"
        >
          <feMorphology radius={2} in="SourceAlpha" result="shadowSpreadOuter1" />
          <feOffset dy={4} in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
          <feGaussianBlur stdDeviation={7} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
          <feColorMatrix
            values="0 0 0 0 0.360784314 0 0 0 0 0.517647059 0 0 0 0 0.984313725 0 0 0 0.3 0"
            in="shadowBlurOuter1"
          />
        </filter>
        <filter
          x="-15%"
          y="-12.2%"
          width="130%"
          height="130.6%"
          filterUnits="objectBoundingBox"
          id="CalendarIcon__c"
        >
          <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur stdDeviation={1.5} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
          <feColorMatrix
            values="0 0 0 0 0.196078431 0 0 0 0 0.337254902 0 0 0 0 0.764705882 0 0 0 0.45 0"
            in="shadowBlurOuter1"
          />
        </filter>
        <Rect id="CalendarIcon__b" x={0} y={0} width={72} height={72} rx={36} />
        <Path
          d="M8.02 17.148v-2.07c0-.406.332-.739.739-.739h2.07c.406 0 .738.333.738.74v2.069c0 .406-.332.739-.739.739h-2.07a.741.741 0 01-.738-.74zm5.543.739h2.07c.406 0 .739-.333.739-.74v-2.069a.741.741 0 00-.74-.739h-2.069a.741.741 0 00-.74.74v2.069c0 .406.334.739.74.739zm4.804 0h2.07c.406 0 .74-.333.74-.74v-2.069a.741.741 0 00-.74-.739h-2.07a.741.741 0 00-.739.74v2.069c0 .406.333.739.74.739zm-4.804 4.693h2.07c.406 0 .739-.332.739-.739v-2.07a.741.741 0 00-.74-.738h-2.069a.741.741 0 00-.74.739v2.07c0 .406.334.738.74.738zm4.804 0h2.07c.406 0 .74-.332.74-.739v-2.07a.741.741 0 00-.74-.738h-2.07a.741.741 0 00-.739.739v2.07c0 .406.333.738.74.738zm4.805 0h2.07c.406 0 .738-.332.738-.739v-2.07a.741.741 0 00-.739-.738h-2.07a.741.741 0 00-.738.739v2.07c0 .406.332.738.739.738zm0 4.657h2.07c.406 0 .738-.333.738-.74v-2.069a.741.741 0 00-.739-.739h-2.07a.741.741 0 00-.738.74v2.069c0 .443.332.739.739.739zM8.759 22.58h2.07c.406 0 .738-.332.738-.739v-2.07a.741.741 0 00-.739-.738h-2.07a.741.741 0 00-.738.739v2.07c0 .406.332.738.739.738zm9.608 4.657h2.07c.406 0 .74-.333.74-.74v-2.069a.741.741 0 00-.74-.739h-2.07a.741.741 0 00-.739.74v2.069c0 .443.333.739.74.739zm-4.804 0h2.07c.406 0 .739-.333.739-.74v-2.069a.741.741 0 00-.74-.739h-2.069a.741.741 0 00-.74.74v2.069c0 .443.334.739.74.739zm20.104-21.25v24.465a2.516 2.516 0 01-2.513 2.513H2.846a2.516 2.516 0 01-2.513-2.513V5.987a2.516 2.516 0 012.513-2.513h3.51v1.515A2.634 2.634 0 008.98 7.613h1.627a2.634 2.634 0 002.623-2.624V3.474h7.613v1.515a2.634 2.634 0 002.624 2.624h1.626a2.634 2.634 0 002.624-2.624V3.474h3.511c1.33 0 2.44 1.109 2.44 2.513zm-2.882 5.063H3.215v19.033h27.57V11.05zM8.943 6.135h1.627c.665 0 1.182-.518 1.182-1.183v-3.51c0-.666-.517-1.183-1.182-1.183H8.943c-.665 0-1.182.517-1.182 1.182V4.99c.037.628.554 1.146 1.182 1.146zm14.487 0h1.627c.665 0 1.182-.518 1.182-1.183v-3.51c0-.666-.517-1.183-1.182-1.183H23.43c-.665 0-1.182.517-1.182 1.182V4.99c0 .628.517 1.146 1.182 1.146z"
          id="CalendarIcon__d"
        />
      </defs>
      <G fill="none" fillRule="evenodd">
        <use fill="#000" filter="url(#CalendarIcon__a)" xlinkHref="#CalendarIcon__b" />
        <use fill={color || colors.purple} xlinkHref="#CalendarIcon__b" />
        <G fillRule="nonzero">
          <G transform="translate(19 18)">
            <use fill="#000" filter="url(#CalendarIcon__c)" xlinkHref="#CalendarIcon__d" />
            <use fill={iconColor || colors.patensBlue} xlinkHref="#CalendarIcon__d" />
          </G>
        </G>
      </G>
    </Svg>
  );
};

export default CalendarIcon;
