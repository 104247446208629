import { FunctionComponent, useState, useEffect, MouseEvent } from 'react';
import { isApple } from 'ts-frontend/utils/device';
import { EmotionThemeProvider, useEmotionTheme } from '@talkspace/react-toolkit';
import AudioPlayerView from './AudioPlayerView';
import globalEventService, { GlobalEvent } from '../../utils/GlobalEvents';

interface AudioPlayerProps {
  style?: {};
  time?: number;
  play?: boolean;
  load?: boolean;
  audioUrl?: string;
  duration?: number;
  onCancel?: () => void;
}

const AudioPlayer: FunctionComponent<AudioPlayerProps> = ({
  time,
  play,
  load,
  style,
  onCancel,
  duration,
  audioUrl,
}) => {
  const [playing, setPlaying] = useState(play || false);
  const [loading, setLoading] = useState(load || false);
  const [sliderDisabled, setSliderDisabled] = useState(isApple);

  useEffect(() => {
    setPlaying(play || false);
  }, [play]);

  useEffect(() => {
    const onOtherMediaPlaying = () => {
      setPlaying(false);
    };

    return globalEventService.addEventListener('playMedia', onOtherMediaPlaying);
  }, []);

  const [currentTime, setCurrentTime] = useState(time || 0);
  useEffect(() => {
    setCurrentTime(time || 0);
  }, [time]);

  const [maxDuration, setMaxDuration] = useState(duration); // If the player can calculate the duration for us, update this
  useEffect(() => {
    setMaxDuration(duration);
  }, [duration]);

  useEffect(() => {
    setPlaying(false);
    setCurrentTime(0);
  }, [audioUrl]);

  useEffect(() => {
    setLoading(Boolean(load));
  }, [load]);

  const onPlay = (e: MouseEvent<HTMLDivElement | MouseEvent> | KeyboardEvent) => {
    e.stopPropagation();
    globalEventService.dispatchEvent(new GlobalEvent('playMedia'));
    if (audioUrl) {
      setPlaying(true);
      setSliderDisabled(false);
    } else {
      setLoading(true);
    }
  };

  const onPause = (e: MouseEvent<HTMLDivElement | MouseEvent> | KeyboardEvent) => {
    e.stopPropagation();
    setPlaying(false);
  };

  const updateCurrentTime = (newTime: number) => {
    setCurrentTime(newTime);
  };

  const { themeVersion } = useEmotionTheme();

  return (
    <EmotionThemeProvider version={themeVersion || '0.1.0'}>
      <AudioPlayerView
        style={style}
        onPlay={onPlay}
        loading={loading}
        playing={playing}
        onPause={onPause}
        audioUrl={audioUrl}
        onCancel={onCancel}
        setPlaying={setPlaying}
        currentTime={currentTime}
        maxDuration={maxDuration}
        setMaxDuration={setMaxDuration}
        updateCurrentTime={updateCurrentTime}
        sliderDisabled={sliderDisabled}
      />
    </EmotionThemeProvider>
  );
};

export default AudioPlayer;
