import { Nullable, LiveSessionModality } from 'ts-frontend/types';

export interface ParticipantUsers {
  userID: number;
  pseudonym: string;
  joinedAt: Nullable<string>;
}

export interface Agora {
  encryptionKey: Nullable<string>;
  channelName: Nullable<string>;
  userToken: Nullable<string>;
}

export interface LiveSessionTherapist {
  userID: number;
  therapistFirstName: string;
  joinedAt: Nullable<string>;
}

export interface Booking {
  type: string;
  creditMinutes: number;
  startTime: string;
}

export interface ActiveSessionInterface {
  agora: Agora;
  clients: ParticipantUsers[];
  therapist: LiveSessionTherapist;
  booking: Booking;
  videoCallID: number;
  callStartedAt: Nullable<string>;
  callEndedAt: Nullable<string>;
  modality: LiveSessionModality;
}

export class EActiveSession implements ActiveSessionInterface {
  public agora: Agora;

  public clients: ParticipantUsers[];

  public therapist: LiveSessionTherapist;

  public booking: Booking;

  public videoCallID: number;

  public callStartedAt: Nullable<string>;

  public callEndedAt: Nullable<string>;

  public modality: LiveSessionModality;

  constructor(activeSessionInfo: ActiveSessionInterface) {
    this.agora = activeSessionInfo.agora;
    this.clients = activeSessionInfo.clients;
    this.therapist = activeSessionInfo.therapist;
    this.booking = activeSessionInfo.booking;
    this.videoCallID = activeSessionInfo.videoCallID;
    this.callStartedAt = activeSessionInfo.callStartedAt;
    this.callEndedAt = activeSessionInfo.callEndedAt;
    this.modality = activeSessionInfo.modality;
  }
}
