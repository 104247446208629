import { ImplicitBusinessHour, ImplicitBusinessHoursByDay } from '../types';

function shiftArrayToRight(arr: ImplicitBusinessHour[], places: number) {
  for (let i = 0; i < places; i += 1) {
    arr.unshift(arr.pop() as ImplicitBusinessHour);
  }
}

function shiftArrayToLeft(arr: ImplicitBusinessHour[], places: number) {
  for (let i = 0; i < places; i += 1) {
    arr.push(arr.shift() as ImplicitBusinessHour);
  }
}

export function prepareDataByTimezone(
  businessHours: ImplicitBusinessHour[]
): ImplicitBusinessHoursByDay {
  const offset = new Date().getTimezoneOffset() / -60;
  if (offset > 0) {
    shiftArrayToRight(businessHours, offset);
  } else {
    shiftArrayToLeft(businessHours, Math.abs(offset));
  }
  const daysOfWeek = {
    0: 'sun',
    1: 'mon',
    2: 'tue',
    3: 'wed',
    4: 'thu',
    5: 'fri',
    6: 'sat',
  };
  const dataPointsByDay: Partial<ImplicitBusinessHoursByDay> = {};
  let runningIndex = 0;
  for (let i = 0; i < businessHours.length; i += 2) {
    const dayNumber = Math.floor(runningIndex / 12) as keyof typeof daysOfWeek;
    const dayName = daysOfWeek[dayNumber] as keyof ImplicitBusinessHoursByDay;

    if (!dataPointsByDay[dayName]) {
      dataPointsByDay[dayName] = [];
    }
    dataPointsByDay[dayName]?.push(
      (businessHours[i]?.hourValue + businessHours[i + 1]?.hourValue) / 2.0
    );
    runningIndex += 1;
  }
  return dataPointsByDay as ImplicitBusinessHoursByDay;
}

export default prepareDataByTimezone;
