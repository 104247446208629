import { VoidFunctionComponent } from 'react';
import Svg, { Path } from 'svgs';
import { EmotionStyle, useEmotionTheme } from '../../core/styled';
import withHover, { WithHoverProps } from '../../hoc/withHover';

interface LightningIconProps extends WithHoverProps {
  width?: number;
  height?: number;
  style?: EmotionStyle;
  isPressed?: boolean;
}

const LightningIcon: VoidFunctionComponent<LightningIconProps> = ({
  width = 14,
  height = 18,
  isHovering,
  isPressed,
  style,
  ...otherProps
}) => {
  const titleText = 'Insurance card icon';
  const { colors } = useEmotionTheme();
  return (
    <Svg
      width={width}
      height={height}
      fill="none"
      viewBox={`0 0 ${width} ${height}`}
      title={titleText}
      aria-label={titleText}
      style={style}
      {...otherProps}
    >
      <Path
        d="M1.09905 8.34592L5.47048 1.54592C5.48888 1.5173 5.52057 1.5 5.55459 1.5H12.8091C12.8898 1.5 12.9373 1.5906 12.8914 1.65692L8.60864 7.84308C8.56272 7.9094 8.61019 8 8.69086 8H11.7297C11.8225 8 11.8652 8.11551 11.7948 8.17593L1.89988 16.6572C1.81606 16.7291 1.69477 16.6321 1.74643 16.5345L5.92229 8.64679C5.95755 8.58019 5.90927 8.5 5.83391 8.5H1.18317C1.10405 8.5 1.05627 8.41248 1.09905 8.34592Z"
        stroke={
          isHovering || isPressed
            ? colors.accessibilityDarkGreenBold
            : colors.accessibilityGreenDark
        }
        strokeWidth="1.5"
      />
    </Svg>
  );
};

export default withHover(LightningIcon);
