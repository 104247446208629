import CloseButton, { CloseButtonProps } from './CloseButton';
import CloseButtonV1 from './CloseButtonV1';
import CloseButtonV2 from './CloseButtonV2';
import { VersionMap } from '../../types';
import { withVersioning } from '../../utils';

export type { CloseButtonProps };

const versionMap: VersionMap<React.ComponentProps<typeof CloseButton>> = {
  '2.0.0': CloseButtonV2,
  '1.0.0': CloseButtonV1,
  DefaultComponent: CloseButton,
};

export default withVersioning(versionMap);
