import {
  useEffect,
  useState,
  useRef,
  useCallback,
  RefObject,
  Dispatch,
  SetStateAction,
} from 'react';
import { EmotionStyle } from '@/core/styled/styled';
import { ID_NEWEST_MESSAGE } from '@/utils/IDConstants';

export const useA11y = (
  inputRef: RefObject<HTMLTextAreaElement> | undefined,
  text: string,
  isInputAutoFocused: boolean,
  isInputSendFocused: boolean,
  setIsInputAutoFocused: Dispatch<SetStateAction<boolean>>,
  setIsInputSendFocused: Dispatch<SetStateAction<boolean>>,
  setIsSendClicked: Dispatch<SetStateAction<boolean>>,
  isFocusedAfterModal: boolean,
  inputStyle?: EmotionStyle
) => {
  // focuses the most recent (bottom) message in the chat if the up arrow key is pressed while the chat input has focus and the input is empty
  useEffect(() => {
    const eventHandler = (e: KeyboardEvent) => {
      const newestMessage = document.getElementById(ID_NEWEST_MESSAGE);
      if (e.key === 'ArrowUp') {
        e.preventDefault();
        if (newestMessage) {
          newestMessage.focus();
        }
      }
    };
    let currentRef;
    if (inputRef) currentRef = inputRef.current;
    if (currentRef) {
      if (!text) {
        currentRef.addEventListener('keydown', eventHandler);
      } else {
        currentRef.removeEventListener('keydown', eventHandler);
      }
    }
    return () => {
      if (inputRef && currentRef) currentRef.removeEventListener('keydown', eventHandler);
    };
  }, [inputRef, text]);

  const [isClicked, setIsClicked] = useState<boolean>(true);
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);
  const [isSkipNavFocused, setIsSkipNavFocused] = useState<boolean>(false);

  // ensures that a visible focus effect is seen only on keyboard focus and that an informative message regarding keyboard navigation renders only on keyboard focus whent the input is empty
  useEffect(() => {
    const mouseDownHandler = () => {
      setIsClicked(true);
    };
    const focusHandler = () => {
      setIsInputFocused(true);
    };
    const blurHandler = () => {
      setIsInputFocused(false);
      setIsClicked(false);
      setIsInputAutoFocused(false);
      setIsInputSendFocused(false);
      setIsSendClicked(false);
    };
    const keyDownHandler = (e: KeyboardEvent) => {
      // isInitialFocus is a unique condition on the initial render. Needs to be accounted for to prevent the keyboard nav floating menu from rendering when he pdf uploader receives focus
      const isInitialFocus = isInputFocused && isInputAutoFocused && isClicked;
      if (!isInitialFocus && e.shiftKey && e.key === 'Tab') {
        setIsSkipNavFocused(true);
      }
    };
    // prevents the the keyboard nav info message and input focus outline from rendering when the user leaves and then re-enters the page
    const leaveEnterPageHandler = () => {
      if (inputRef && inputRef.current && document.activeElement === inputRef.current)
        (inputRef.current as HTMLElement).blur();
    };
    let currentRef;
    if (inputRef) currentRef = inputRef.current;
    if (currentRef) {
      currentRef.addEventListener('mousedown', mouseDownHandler);
      currentRef.addEventListener('focus', focusHandler);
      currentRef.addEventListener('blur', blurHandler);
      currentRef.addEventListener('keydown', keyDownHandler);
      window.addEventListener('blur', leaveEnterPageHandler);
      window.addEventListener('focus', leaveEnterPageHandler);
    }

    return () => {
      if (inputRef && currentRef) {
        currentRef.removeEventListener('mousedown', mouseDownHandler);
        currentRef.removeEventListener('focus', focusHandler);
        currentRef.removeEventListener('blur', blurHandler);
        currentRef.removeEventListener('keydown', keyDownHandler);
        window.removeEventListener('blur', leaveEnterPageHandler);
        window.removeEventListener('focus', leaveEnterPageHandler);
      }
    };
  }, [
    inputRef,
    isInputFocused,
    isInputAutoFocused,
    isClicked,
    setIsInputAutoFocused,
    setIsInputSendFocused,
    setIsSendClicked,
  ]);

  // ensures that the floating menu containing the the keyboard nav info stays rendered if the skipnav has focus
  const skipNavRef = useRef<HTMLDivElement>(null);
  const setSkipNavRef = useCallback((node) => {
    const focusHandler = () => {
      setIsSkipNavFocused(true);
    };
    const blurHandler = () => {
      setIsSkipNavFocused(false);
    };
    // Check if a node is actually passed. Otherwise node would be null.
    if (node) {
      // Add event listeners. The node is removed from the dom whenever the FloatinMenu's hide prop evalautes to true, which will remove the event listeners automatically
      node.addEventListener('focus', focusHandler);
      node.addEventListener('blur', blurHandler);
    }
    // Save a reference to the node
    // @ts-ignore
    // by default skipNavRef.current is readonly. Let's ignore it
    skipNavRef.current = node;
  }, []);

  const showNavInfo =
    !isFocusedAfterModal &&
    !isInputSendFocused &&
    !isInputAutoFocused &&
    !isClicked &&
    !text &&
    (isInputFocused || isSkipNavFocused);

  // outline: 'none' necessary to override the keyboard focus effect for our TextArea component because we are rendering
  // a keyboard-specific focus effect on the TextArea wrapper
  const finalInputStyle = inputStyle ? { ...inputStyle, outline: 'none' } : { outline: 'none' };

  return { isClicked, isInputFocused, showNavInfo, setSkipNavRef, finalInputStyle };
};

export default useA11y;
