import Svg, { G, Path, Rect, Defs, LinearGradient, Stop } from 'svgs';
import { useEmotionTheme } from '../../core/styled';
import { useUniqueID } from '../../hooks/a11yHelper';

const Notepad = ({
  width = 74,
  height = 95,
  padInnerColor,
  padEdgeColor,
  textColor,
  claspColor,
  checkMarkGradientStop1,
  checkMarkGradientStop2,
  ...props
}: {
  width?: number;
  height?: number;
  padInnerColor?: string;
  padEdgeColor?: string;
  textColor?: string;
  claspColor?: string;
  checkMarkGradientStop1?: string;
  checkMarkGradientStop2?: string;
}) => {
  const { colors } = useEmotionTheme();
  const paintLinearID = useUniqueID('paintLinear');
  return (
    <Svg width={width} height={height} viewBox="0 0 74 95" fill="none" {...props}>
      <G>
        <Path
          d="M10.6 67.283V21.7a9.1 9.1 0 019.1-9.1h34.7c4.946 0 9 4.057 9 9.117v45.566c0 5.06-4.054 9.117-9 9.117H19.6c-4.946 0-9-4.057-9-9.117z"
          fill={padInnerColor || colors.white}
          stroke={padEdgeColor || colors.baliHaiGrey}
          strokeWidth={5.2}
        />
        <Rect x={22} y={50} width={30} height={4} rx={2} fill={textColor || colors.lightGray} />
        <Rect x={22} y={59} width={15} height={4} rx={1.95} fill={textColor || colors.lightGray} />
        <Path
          d="M28.338 33.482l7.108 6.52L46.338 27"
          stroke={`url(#${paintLinearID})`}
          strokeWidth={5}
          strokeLinecap="round"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.981 6A6.5 6.5 0 0031.02 6H27a5 5 0 00-5 5v2a5 5 0 005 5h20a5 5 0 005-5v-2a5 5 0 00-5-5h-3.019z"
          fill={claspColor || colors.lightGray}
        />
      </G>
      <Defs>
        <LinearGradient
          id={paintLinearID}
          x1={-15.6219}
          y1={23.2746}
          x2={-10.9101}
          y2={100.046}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor={checkMarkGradientStop1 || colors.conifer} />
          <Stop offset={1} stopColor={checkMarkGradientStop2 || colors.laPalma} />
        </LinearGradient>
      </Defs>
    </Svg>
  );
};

export default Notepad;
