/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as React from 'react';
import Svg, { Defs, G, Path } from 'svgs';
import { EmotionStyle } from '../../core/styled';

interface VideoCalendarProps {
  width?: number;
  height?: number;
  style?: EmotionStyle;
}

const VideoCalendarOutline: React.VFC<VideoCalendarProps> = ({
  width = 36,
  height = 36,
  style,
}) => {
  const titleText = 'video calendar';
  return (
    <Svg
      width={width}
      height={height}
      viewBox="7 2 22 28"
      fill="none"
      title={titleText}
      aria-label={titleText}
      style={style}
    >
      {/* @ts-ignore */}
      <G filter="url(#VideoCalendarOutline-filter)">
        <Path
          d="M6.76094 9.43492C6.76094 7.26219 8.52229 5.50084 10.695 5.50084H25.3037C27.4763 5.50084 29.2376 7.26202 29.2378 9.43465L29.2388 24.3252C29.239 26.498 27.4776 28.2596 25.3047 28.2596H10.695C8.52228 28.2596 6.76094 26.4982 6.76094 24.3255V9.43492Z"
          stroke="white"
          strokeWidth="2.62272"
        />
        <Path
          d="M13.3224 3.44298L13.3224 7.42438"
          stroke="white"
          strokeWidth="2.6208"
          strokeLinecap="round"
        />
        <Path
          d="M22.6737 3.44298L22.6737 7.42438"
          stroke="white"
          strokeWidth="2.6208"
          strokeLinecap="round"
        />
      </G>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5769 14.3251C13.9618 14.3251 13.4631 14.8237 13.4631 15.4389V19.681C13.4631 20.2962 13.9618 20.7948 14.5769 20.7948H18.8735C19.4886 20.7948 19.9873 20.2962 19.9873 19.681V15.4389C19.9873 14.8237 19.4887 14.3251 18.8735 14.3251H14.5769ZM20.6713 16.3064C20.5316 16.3854 20.4452 16.5336 20.4452 16.6941V18.1881C20.4452 18.3487 20.5316 18.4968 20.6713 18.5759L22.6418 19.6904C22.9388 19.8584 23.3066 19.6438 23.3066 19.3026V15.5796C23.3066 15.2384 22.9388 15.0239 22.6418 15.1918L20.6713 16.3064Z"
        fill="white"
      />
      <Defs>
        <filter
          id="VideoCalendarOutline-filter"
          x="0.449585"
          y="0.132584"
          width="35.1006"
          height="37.4383"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="2.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0231266 0 0 0 0 0.37894 0 0 0 0 0.39088 0 0 0 0.0930398 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_10_242" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_10_242"
            result="shape"
          />
        </filter>
      </Defs>
    </Svg>
  );
};

export default VideoCalendarOutline;
