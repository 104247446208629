import * as actionTypes from '../constants/MarketingConstants';
import apiWrapper from '../utils/apiWrapper';
import apiHelper from '../utils/api';
import {
  transformAPIPayloadToReducerPayload,
  transformReducerPayloadToAPIPayload,
} from '../utils/optionsHelpers';

const handleAction = (action) => (payload) => {
  return {
    type: action,
    payload,
  };
};
const handleErrorAction = (action) => (error) => {
  return {
    type: action,
    error,
  };
};

const requestMarketing = handleAction(actionTypes.REQUEST_MARKETING);
const requestMarketingError = handleErrorAction(actionTypes.REQUEST_MARKETING_ERROR);
const receiveMarketing = handleAction(actionTypes.RECEIVE_MARKETING);
export const getMarketing = (clientUserID) => (dispatch) => {
  dispatch(requestMarketing({ clientUserID }));
  return apiWrapper
    .get(`${apiHelper().apiEndpoint}/api/v1/clients/${clientUserID}/marketing`)
    .then((response) => {
      const payload = transformAPIPayloadToReducerPayload(response.data.data);
      return dispatch(receiveMarketing({ clientUserID, ...payload }));
    })
    .catch((error) => dispatch(requestMarketingError(error)));
};

const patchMarketing = handleAction(actionTypes.PATCH_MARKETING);
const patchMarketingSuccess = handleAction(actionTypes.PATCH_MARKETING_SUCCESS);
const patchMarketingError = handleErrorAction(actionTypes.PATCH_MARKETING_ERROR);
export const updateMarketing = (clientUserID, value) => (dispatch) => {
  dispatch(patchMarketing({ clientUserID, value }));
  const payload = transformReducerPayloadToAPIPayload(value);
  return apiWrapper
    .patch(`${apiHelper().apiEndpoint}/api/v1/clients/${clientUserID}/marketing`, payload)
    .then(() => dispatch(patchMarketingSuccess(value)))
    .catch((error) => dispatch(patchMarketingError(error)));
};
