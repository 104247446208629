import Svg, { Path } from 'svgs';
import { FunctionComponent } from 'react';
import { useEmotionTheme } from '../../core/styled';

interface CloseRoundIconProps {
  width?: number;
  height?: number;
  color?: string;
}

const CloseRoundIcon: FunctionComponent<CloseRoundIconProps> = ({
  width = 16,
  height = 16,
  color,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();

  return (
    <Svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      {...otherProps}
    >
      <g clipPath="url(#clip0_425_14832)">
        <Path
          d="M8 1.5C9.72391 1.5 11.3772 2.18482 12.5962 3.40381C13.8152 4.62279 14.5 6.27609 14.5 8C14.5 9.72391 13.8152 11.3772 12.5962 12.5962C11.3772 13.8152 9.72391 14.5 8 14.5C6.27609 14.5 4.62279 13.8152 3.40381 12.5962C2.18482 11.3772 1.5 9.72391 1.5 8C1.5 6.27609 2.18482 4.62279 3.40381 3.40381C4.62279 2.18482 6.27609 1.5 8 1.5ZM8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16ZM5.46875 5.46875C5.175 5.7625 5.175 6.2375 5.46875 6.52812L6.9375 7.99687L5.46875 9.46562C5.175 9.75937 5.175 10.2344 5.46875 10.525C5.7625 10.8156 6.2375 10.8187 6.52812 10.525L7.99687 9.05625L9.46562 10.525C9.75937 10.8187 10.2344 10.8187 10.525 10.525C10.8156 10.2312 10.8187 9.75625 10.525 9.46562L9.05625 7.99687L10.525 6.52812C10.8187 6.23438 10.8187 5.75938 10.525 5.46875C10.2312 5.17812 9.75625 5.175 9.46562 5.46875L7.99687 6.9375L6.52812 5.46875C6.23438 5.175 5.75938 5.175 5.46875 5.46875Z"
          fill={color || colors.accessibilityGreenDark}
        />
      </g>
      <defs>
        <clipPath id="clip0_425_14832">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default CloseRoundIcon;
