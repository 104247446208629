import { FunctionComponent, useState, useEffect } from 'react';
import ToggleSwitchView from './ToggleSwitchView';
import './toggleSwitch.css';

interface SwitchProps {
  isOn: boolean;
  onValueChange: (value: boolean) => void;
  name: string;
  onBackgroundColor?: string;
  offBackgroundColor?: string;
  switchWidth?: number;
  switchHeight?: number;
  dataQa?: string;
}

const ToggleSwitch: FunctionComponent<SwitchProps> = ({
  dataQa,
  isOn,
  onValueChange,
  ...otherProps
}) => {
  const [currentIsOn, setCurrentIsOn] = useState(isOn);
  useEffect(() => {
    setCurrentIsOn(isOn);
  }, [isOn]);

  return (
    <ToggleSwitchView
      dataQa={dataQa}
      isOn={currentIsOn}
      onValueChange={(value) => {
        onValueChange(value);
        setCurrentIsOn(value);
      }}
      {...otherProps}
    />
  );
};

export default ToggleSwitch;
