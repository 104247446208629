import Svg, { Path } from 'svgs';
import { FunctionComponent } from 'react';
import { useEmotionTheme } from '../../core/styled';

interface PlayVideoIconProps {
  width?: number;
  height?: number;
  color?: string;
}

const PlayVideoIcon: FunctionComponent<PlayVideoIconProps> = ({
  width = 16,
  height = 16,
  color,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();

  return (
    <Svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      {...otherProps}
    >
      <Path
        d="M8 0C3.59114 0 0 3.59114 0 8C0 12.4089 3.59114 16 8 16C12.4089 16 16 12.4089 16 8C16 3.59114 12.4089 0 8 0ZM8 1.6001C11.5441 1.6001 14.4 4.45614 14.4 8.0001C14.4 11.5442 11.5441 14.4001 8 14.4001C4.45586 14.4001 1.6 11.5442 1.6 8.0001C1.6 4.45595 4.45586 1.6001 8 1.6001ZM6.625 4.53352C6.22619 4.5432 5.87686 4.88267 5.86662 5.36686V10.6335C5.8802 11.2792 6.48881 11.6659 7.02491 11.3586L11.3415 8.71686C11.5764 8.57251 11.7332 8.30949 11.7332 7.99995C11.7332 7.69041 11.5764 7.42743 11.3415 7.28324L7.02491 4.64148C6.89098 4.56465 6.75779 4.52987 6.62498 4.53322L6.625 4.53352Z"
        fill={color || colors.accessibilityGreenDark}
      />
    </Svg>
  );
};

export default PlayVideoIcon;
