import { FunctionComponent } from 'react';
import Svg, { Path } from 'svgs';
import { EmotionStyle, useEmotionTheme } from '../../core/styled';

const PlusIcon: FunctionComponent<{
  color?: string;
  width?: string | number;
  height?: string | number;
  style?: EmotionStyle;
}> = ({ width = 14, height = 14, color, style, ...otherProps }) => {
  const titleText = 'Plus icon';
  const { colors } = useEmotionTheme();
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <Path
        d="M1.00012 6.99994L6.65907 6.99785L6.65965 1.34041"
        stroke={colors.permaBlueStoneNew}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <Path
        d="M12.324 7.00488L6.66503 7.00698L6.66445 12.6644"
        stroke={colors.permaBlueStoneNew}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </Svg>
  );
};

export default PlusIcon;
