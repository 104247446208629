import { AccountType, ERoom, RoomStatus, RoomType } from 'ts-frontend/entities/Room';
import { bindActionCreators } from 'redux';
import { useRouteMatch } from 'react-router';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { InRoomSchedulingContainer } from 'inRoomScheduling';

import { getUserData } from '../../utils/token';
import { getRoomProperties } from '../../actions/RoomActions';

interface InRoomSchedulingFramedContainerProps {
  roomType: RoomType;
  createdAt: Date;
  isVideoOnly: boolean;
  isSessionBased: boolean;
  isEAP: boolean;
  status: RoomStatus;
  accountType: AccountType;
  planID: number | null;
  dispatchedGetRoomProperties(roomID: string): void;
}

const InRoomSchedulingFramedContainer = ({
  roomType,
  createdAt,
  isVideoOnly,
  isSessionBased,
  isEAP,
  status,
  accountType,
  planID,
  dispatchedGetRoomProperties,
}: InRoomSchedulingFramedContainerProps) => {
  const match = useRouteMatch<{ roomID: string }>();
  const therapistInfo = {
    id: getUserData().id,
    firstName: getUserData().firstName,
    lastName: getUserData().lastName,
  };
  const [room, setRoom] = useState<ERoom | null>(null);
  useEffect(() => {
    setRoom(
      new ERoom({
        expirationDate: new Date(),
        therapistID: getUserData().id,
        roomType,
        createdAt,
        roomID: Number(match.params.roomID),
        isVideoOnly,
        isSessionBased,
        isEAP,
        status,
        accountType,
        planID,
      })
    );
  }, [
    createdAt,
    isEAP,
    isSessionBased,
    isVideoOnly,
    match.params.roomID,
    roomType,
    status,
    accountType,
    planID,
  ]);
  useEffect(() => {
    if (match.params.roomID) dispatchedGetRoomProperties(match.params.roomID);
  }, [dispatchedGetRoomProperties, match.params.roomID]);

  if (!room) return null;
  return <InRoomSchedulingContainer isTherapist room={room} therapist={therapistInfo} />;
};

const mapStateToProps = (state) => {
  return {
    availability: state.availability.availability,
    id: state.availability.id,
    isLocked: state.availability.isLocked,
    therapistType: state.availability.therapistType,
    availabilityNameMapping: state.availability.availabilityNameMapping,
    availabilityOptions: state.availability.availabilityOptions,
    requestAvailabilityError: state.availability.requestAvailabilityError,
    updateAvailabilityError: state.availability.updateAvailabilityError,
    isUpdatingAvailabilityPut: state.availability.isUpdatingAvailabilityPut,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ dispatchedGetRoomProperties: getRoomProperties }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InRoomSchedulingFramedContainer);
