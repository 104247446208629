import moment from 'moment';
import {
  haltIfRevokeInProcess,
  invalidateTokenAndRedirect,
  refreshToken,
} from '../modules/auth/auth';
import ReactFrameService from '@/auth/reactFrame/ReactFrameService';
import {
  getLogoutLastActivity,
  updateLogoutLastActivity,
  getAccessToken,
  getTokens,
} from './token';
import { tokenIsValid } from './tokenIsValid';
import { isIonic } from '../modules/auth/reactFrame/helpers';

interface RequestOptions {
  headers?: HeadersInit;
  shouldIgnore401?: boolean;
}

type RequestBody =
  | {
      [key: string]: any; // TODO: Extend this
    }
  | BodyInit;

interface ResponseWithStatus {
  data?: any;
  status?: number;
}

class ErrorWithPayload extends Error {
  data: any;

  status: number;

  constructor(message: string, data: any, status: number) {
    super(message);
    this.data = data;
    this.status = status;

    Object.setPrototypeOf(this, ErrorWithPayload.prototype);
  }
}

const isString = (value: unknown) => typeof value === 'string' || value instanceof String;

function parseError(data: any, responseStatus: number) {
  let errorMessage = String(responseStatus);

  if (data && data.error) {
    if (isString(data.error)) {
      errorMessage = data.error;
    } else if (data.error.message) {
      errorMessage = data.error.message;
    }
  }

  return new ErrorWithPayload(errorMessage, data, responseStatus);
}

function parseJSON(response: Response): Promise<ResponseWithStatus> {
  if (response.status > 299) {
    return response.json().then((data) => {
      const parsedError = parseError(data, response.status);
      return Promise.reject(parsedError);
    });
  }
  return response
    .text()
    .then((text) =>
      text ? { data: JSON.parse(text), status: response.status } : { status: response.status }
    );
}

const tokenDoesNotNeedRefreshing = () => {
  // check that the token isn't expired and that it's good for at least another day
  // if not refresh the token
  const { accessTTL } = getTokens();
  // Don't refresh if no TTL is found
  if (!accessTTL) return true;
  const tokenExpirationDate = moment(Number(accessTTL));
  const momentAfterAnHour = moment().add(1, 'hours');
  return tokenIsValid() && tokenExpirationDate.isAfter(momentAfterAnHour);
};

const MAX_MINS_SINCE_LAST_ACTIVITY = 20;
let hasCheckedWindowCloseTime = false;

const shouldLogoutDueToInactivity = () => {
  if (hasCheckedWindowCloseTime) return false;
  // Evaluates to moment() if there is a window open
  const windowLastClosedAt = moment(Number(getLogoutLastActivity()) || undefined);
  const lastAllowedActivity = moment().subtract(MAX_MINS_SINCE_LAST_ACTIVITY, 'minutes');
  hasCheckedWindowCloseTime = true;
  updateLogoutLastActivity(); // Update as it's being used on LogoutModal
  return windowLastClosedAt.isBefore(lastAllowedActivity);
};

const logoutIfNoActivity = () =>
  new Promise<void>((resolve, reject) => {
    invalidateTokenAndRedirect({ shouldForgetDevice: false });
    reject(new Error('Logging out due to inactivity')); // Throws to avoid making requests with invalid tokens
  });

type TokenListener = (success: boolean) => void;

class TokenRefreshedEventEmitter {
  eventListeners: TokenListener[];

  constructor() {
    this.eventListeners = [];
  }

  callAllListeners = (success: boolean) => {
    this.eventListeners.forEach((fn) => typeof fn === 'function' && fn(success));
    this.eventListeners = [];
  };

  addEventListener = (fn: TokenListener) => {
    this.eventListeners.push(fn);
  };
}

class Client {
  eventEmitter: TokenRefreshedEventEmitter;

  runningMiddlewareTokenRefresh: boolean;

  authHeader: string;

  constructor() {
    this.eventEmitter = new TokenRefreshedEventEmitter();
    this.runningMiddlewareTokenRefresh = false;
    this.authHeader = `Bearer ${getAccessToken()}`;
    return this;
  }

  middlewares = async () => {
    await haltIfRevokeInProcess();
    await this.logoutIfNoActivityMiddleware();
    await this.validateRequestToken();
  };

  // Do not catch to prevent making any more requests
  logoutIfNoActivityMiddleware = () => {
    if (!this.runningMiddlewareTokenRefresh && shouldLogoutDueToInactivity() && !isIonic()) {
      this.runningMiddlewareTokenRefresh = true;
      return logoutIfNoActivity().catch((err: Error) => {
        throw err;
      });
    }
    return Promise.resolve();
  };

  tokenRefreshed = (success: boolean) => {
    this.eventEmitter.callAllListeners(success);
    this.runningMiddlewareTokenRefresh = false;
  };

  validateRequestToken = () => {
    if (!this.runningMiddlewareTokenRefresh) {
      if (tokenDoesNotNeedRefreshing()) {
        return Promise.resolve();
      }
      this.runningMiddlewareTokenRefresh = true;
      return refreshToken(false, true)
        .then((success) => {
          this.tokenRefreshed(success);
        })
        .catch((err: Error) => {
          this.eventEmitter.callAllListeners(false);
          invalidateTokenAndRedirect({ shouldForgetDevice: true });
          throw err; // Throws to avoid making requests with invalid tokens
        });
    }
    return new Promise<void>((resolve, reject) => {
      this.eventEmitter.addEventListener((refreshed) => {
        if (refreshed) {
          resolve();
        }
        reject(); // Rejects to avoid making requests with invalid tokens
      });
    });
  };

  request = async (
    method: 'GET' | 'PATCH' | 'POST' | 'PUT' | 'DELETE',
    url: RequestInfo,
    headers: HeadersInit,
    body?: RequestBody,
    shouldIgnore401 = false
  ): Promise<ResponseWithStatus> => {
    const requestOptions: RequestInit = {
      method,
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
    };

    if (body) {
      requestOptions.body = JSON.stringify(body);
    }

    await this.middlewares();

    const accessToken = getAccessToken();

    if (
      accessToken &&
      requestOptions.headers &&
      // @ts-ignore
      !requestOptions.headers.Authorization
    ) {
      // @ts-ignore
      requestOptions.headers.Authorization = `Bearer ${accessToken}`;
    }

    return fetch(url, requestOptions)
      .then(parseJSON)
      .catch((error) => {
        // Only attempt to refresh token when doing authenticated requests
        if (error.message === '401' && !shouldIgnore401) {
          this.runningMiddlewareTokenRefresh = true;
          if (ReactFrameService.instance().isInFrame()) {
            refreshToken(false, true).then((refreshed: boolean) => {
              this.tokenRefreshed(refreshed);
            });
            return this.request(method, url, headers, body);
          }
          return invalidateTokenAndRedirect({ shouldForgetDevice: true }).then(() =>
            Promise.reject(error.response)
          );
        }
        throw error;
      });
  };

  get = (url, options) =>
    this.request('GET', url, options.headers, undefined, options.shouldIgnore401);

  patch = (url, body, options) =>
    this.request('PATCH', url, options.headers, body, options.shouldIgnore401);

  post = (url, body, options) =>
    this.request('POST', url, options.headers, body, options.shouldIgnore401);

  put = (url, body, options) =>
    this.request('PUT', url, options.headers, body, options.shouldIgnore401);

  delete = (url, body, options) =>
    this.request('DELETE', url, options.headers, body, options.shouldIgnore401);
}

const client = new Client();

const apiWrapper = {
  get: (url: RequestInfo, options: RequestOptions = {}) => client.get(url, options),
  patch: (url: RequestInfo, data?: RequestBody, options: RequestOptions = {}) =>
    client.patch(url, data, options),
  post: (url: RequestInfo, data: RequestBody, options: RequestOptions = {}) =>
    client.post(url, data, options),
  put: (url: RequestInfo, data?: RequestBody, options: RequestOptions = {}) =>
    client.put(url, data, options),
  delete: (url: RequestInfo, data?: RequestBody, options: RequestOptions = {}) =>
    client.delete(url, data, options),
  postMainsite: <T>(url: RequestInfo) => Promise.resolve() as unknown as Promise<T>, // Temporary placeholder
};

export default apiWrapper;
