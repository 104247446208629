import Svg, { Path, Rect } from 'svgs';
import { useEmotionTheme } from '../../core/styled';

const RepeatingArrowsDarkIcon = ({ size = 16, ...otherProps }) => {
  const { colors } = useEmotionTheme();
  const titleText = 'repeating arrows';
  return (
    <Svg
      title={titleText}
      aria-label={titleText}
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      {...otherProps}
    >
      <Rect width="16" height="16" rx="4" fill={colors.accessibilityDarkGreenBold} />
      <Path
        d="M10 2.5L12 4.5L10 6.5"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M4 7.5V6.5C4 5.96957 4.16389 5.46086 4.45561 5.08579C4.74733 4.71071 5.143 4.5 5.55556 4.5H11"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M6 13.5L4 11.5L6 9.5"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M12 8.5V9.5C12 10.0304 11.8127 10.5391 11.4793 10.9142C11.1459 11.2893 10.6937 11.5 10.2222 11.5H4"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default RepeatingArrowsDarkIcon;
