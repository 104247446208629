import * as actionTypes from '../constants/OutcomeMeasureConstants';
import apiWrapper from '../utils/apiWrapper';
import apiHelper from '../utils/api';

function requestGetOutMeasures() {
  return { type: actionTypes.REQUEST_ROOM_OUTCOME_MEASURES };
}

function receiveGetOutcomeMeasures(response) {
  return {
    type: actionTypes.RECEIVE_ROOM_OUTCOME_MEASURES,
    outcomeMeasures: [...response.data.results],
    isScheduled: response.data.results
      .filter((result) => result.isOMSurvey)
      .some((result) => !result.canceledAt && !result.completedAt),
  };
}

function requestGetOutMeasuresError(error) {
  return {
    type: actionTypes.REQUEST_ROOM_OUTCOME_MEASURES_ERROR,
    error,
  };
}

function requestGetOutcomeMeasuresOptions() {
  return { type: actionTypes.REQUEST_OUTCOME_MEASURE_OPTIONS };
}

function receiveGetOutcomeMeasuresOptions(response) {
  return {
    type: actionTypes.RECEIVE_OUTCOME_MEASURE_OPTIONS,
    outcomeMeasureOptions: response.data,
  };
}

function requestGetOutcomeMeasuresOptionsError(error) {
  return {
    type: actionTypes.REQUEST_OUTCOME_MEASURE_OPTIONS_ERROR,
    error,
  };
}

function requestGetOutMeasureAnswers() {
  return { type: actionTypes.REQUEST_OUTCOME_MEASURE_ANSWERS };
}

function receiveGetOutcomeMeasureAnswers(response) {
  return {
    type: actionTypes.RECEIVE_OUTCOME_MEASURE_ANSWERS,
    answers: response.data,
  };
}

function requestGetOutMeasureAnswersError(error) {
  return {
    type: actionTypes.REQUEST_OUTCOME_MEASURE_ANSWERS_ERROR,
    error,
  };
}

function requestStartOutcomeMeasures() {
  return { type: actionTypes.REQUEST_START_OUTCOME_MEASURES };
}

function receiveStartOutcomeMeasures() {
  return {
    type: actionTypes.RECEIVE_START_OUTCOME_MEASURES,
    isScheduled: true,
  };
}

function requestStartOutcomeMeasuresError(error) {
  return {
    type: actionTypes.REQUEST_START_OUTCOME_MEASURES_ERROR,
    error,
  };
}

function requestCancelOutcomeMeasures() {
  return { type: actionTypes.REQUEST_CANCEL_OUTCOME_MEASURES };
}

function receiveCancelOutcomeMeasures() {
  return {
    type: actionTypes.RECEIVE_CANCEL_OUTCOME_MEASURES,
    isScheduled: false,
  };
}

function requestCancelOutcomeMeasuresError(error) {
  return {
    type: actionTypes.REQUEST_CANCEL_OUTCOME_MEASURES_ERROR,
    error,
  };
}

export const getOutcomeMeasures = () => (dispatch, getState) => {
  const {
    customerInformation: { clientUserID },
  } = getState();
  dispatch(requestGetOutMeasures());
  return apiWrapper
    .get(`${apiHelper().surveyAPIEndpoint}/v2/user/${clientUserID}/om-surveys`)
    .then((response) => dispatch(receiveGetOutcomeMeasures(response)))
    .catch((error) => dispatch(requestGetOutMeasuresError(error)));
};

export const getOutcomeMeasureOptions = () => (dispatch) => {
  dispatch(requestGetOutcomeMeasuresOptions());
  return apiWrapper
    .get(`${apiHelper().surveyAPIEndpoint}/v2/om-surveys`)
    .then((response) => dispatch(receiveGetOutcomeMeasuresOptions(response)))
    .catch((error) => dispatch(requestGetOutcomeMeasuresOptionsError(error)));
};

export const getOutcomeMeasureAnswers = (userRoomSurveyID) => (dispatch, getState) => {
  const {
    room: { roomID },
  } = getState();
  dispatch(requestGetOutMeasureAnswers());
  return apiWrapper
    .get(`${apiHelper().surveyAPIEndpoint}/v2/room/${roomID}/survey-answers/${userRoomSurveyID}`)
    .then((response) => dispatch(receiveGetOutcomeMeasureAnswers(response)))
    .catch((error) => dispatch(requestGetOutMeasureAnswersError(error)));
};

export const startOutcomeMeasures = (startData) => (dispatch) => {
  dispatch(requestStartOutcomeMeasures());
  return apiWrapper
    .post(`${apiHelper().surveyAPIEndpoint}/v2/room/${startData.roomID}/survey`, startData)
    .then(() => dispatch(receiveStartOutcomeMeasures()))
    .catch((error) => dispatch(requestStartOutcomeMeasuresError(error)));
};

export const cancelOutcomeMeasures = (roomID, cancelData) => (dispatch) => {
  dispatch(requestCancelOutcomeMeasures());
  return apiWrapper
    .put(`${apiHelper().surveyAPIEndpoint}/v2/room/${roomID}/survey`, cancelData)
    .then(() => dispatch(receiveCancelOutcomeMeasures()))
    .catch((error) => dispatch(requestCancelOutcomeMeasuresError(error)));
};
