import { FunctionComponent } from 'react';
import { View } from '@talkspace/react-toolkit';
import { STREAM_ID_PREFIX } from '../../types/videoCallTypes';
import './videoCall.css';

interface VideoCallProps {
  userID?: number;
}

const VideoCall: FunctionComponent<VideoCallProps> = ({ userID }) => {
  const videoPlayerID = `${STREAM_ID_PREFIX}${userID}`;
  return userID ? (
    <View
      className="TSVideoCall"
      style={{
        flexGrow: 1,
      }}
      id={videoPlayerID}
    />
  ) : null;
};

export default VideoCall;
