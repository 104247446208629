import * as React from 'react';
import Svg, { Path } from 'svgs';
import { useEmotionTheme } from '../../core/styled';

interface RecurringArrowsProps extends Record<string, unknown> {
  width?: number;
  height?: number;
  color?: string;
}

const RepeatingArrowsLightIcon = ({
  width = 14,
  height = 16,
  color,
  ...otherProps
}: RecurringArrowsProps) => {
  const titleText = 'Repeating';
  const { colors } = useEmotionTheme();
  const strokeColor = color || colors.permaLividBlueNew;
  return (
    <Svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 14 16"
      title={titleText}
      aria-label={titleText}
      {...otherProps}
    >
      <Path
        d="M10.333.667 13 3.333 10.333 6"
        stroke={strokeColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M1 7.333V6a2.667 2.667 0 0 1 2.667-2.667H13M3.667 15.333 1 12.667 3.667 10"
        stroke={strokeColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M13 8.666V10a2.667 2.667 0 0 1-2.667 2.666H1"
        stroke={strokeColor}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default RepeatingArrowsLightIcon;
