import { FunctionComponent } from 'react';
import { View, Modal, TouchableView, Large, CloseButton, RedX } from '@talkspace/react-toolkit';

import styled from '@/core/styled';

const Container = styled(TouchableView)(({ theme: { window, colors } }) => {
  const isResponsive = window.isSmall || window.isMedium;
  return {
    backgroundColor: colors.white,
    height: isResponsive ? '100%' : 363,
    width: isResponsive ? '100%' : 500,
    borderRadius: isResponsive ? 0 : 10,
  };
});

const ErrorMessage = styled(Large)(({ theme: { window } }) => {
  const isResponsive = window.isSmall || window.isMedium;
  return {
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    paddingTop: 20,
    paddingLeft: isResponsive ? 25 : 85,
    paddingRight: isResponsive ? 25 : 85,
  };
});

interface Props {
  error: string;
  onClosePress: () => void;
}

const ErrorModal: FunctionComponent<Props> = ({ error, onClosePress }) => (
  <Modal isVisible>
    <Container>
      <View row justify="end" style={{ padding: 16 }}>
        <CloseButton onPress={onClosePress} />
      </View>
      <View align="center" justify="center">
        <RedX size={74} />
        <ErrorMessage variant="largeDarkGrey">
          {error || 'Something went wrong. Please try again later.'}
        </ErrorMessage>
        <TouchableView onPress={onClosePress} style={{ padding: 30 }}>
          <Large variant="largeBoldWideGreen">Close</Large>
        </TouchableView>
      </View>
    </Container>
  </Modal>
);

export default ErrorModal;
