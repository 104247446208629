import { Standard, View, styled, ParentConsentAvatar } from '@talkspace/react-toolkit';

const Badge = styled(View)(({ theme: { colors } }) => {
  return {
    width: 43,
    height: 43,
    borderRadius: '50%',
    background: colors.permaWildBlueYonder,
    color: colors.white,
    textAlign: 'center',
    lineHeight: '43px',
    fontSize: 18,
    fontWeight: 700,
    textTransform: 'capitalize',
  };
});

const Title = styled(Standard)(({ theme: { colors } }) => {
  return {
    fontSize: 17,
    fontWeight: 700,
    color: colors.black,
    marginLeft: 7,
  };
});

const UserHeader = ({ preferredName, isParentConsent }) => (
  <View>
    <View row align="center">
      {isParentConsent ? <ParentConsentAvatar /> : <Badge>{preferredName?.slice(0, 2)}</Badge>}
      <Title>{preferredName}</Title>
    </View>
  </View>
);

export default UserHeader;
