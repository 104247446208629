import { useEffect, useState } from 'react';
import * as React from 'react';
import PublicRoutes from '../../PublicRoutes';
import JwtService from './JwtService';
import Spinner from './Spinner/Spinner';

let jwtService: JwtService;

const JwtContainer: React.FunctionComponent<{}> = () => {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    jwtService = JwtService.instance();
    jwtService.addTokenListeners((token) => {
      if (token && token.length) setReady(true);
      else setReady(false);
    });
  }, []);

  return ready ? (
    <PublicRoutes />
  ) : (
    <div style={{ height: '100vh' }}>
      <Spinner isLoading />
    </div>
  );
};

export default JwtContainer;
