import { useEmotionTheme } from '@talkspace/react-toolkit';
import { VoidFunctionComponent } from 'react';
import Svg, { Rect, Path } from 'svgs';

interface Props {
  width?: number;
  height?: number;
  fillColor?: string;
}

const LightBulb: VoidFunctionComponent<Props> = ({ width = 30, height = 30, fillColor = null }) => {
  const { colors } = useEmotionTheme();
  return (
    <Svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Rect width={width} height={height} rx={15} fill={colors.aquaSqueeze} />
      <Path
        d="M15 8.476a.433.433 0 00.433-.433V7.17a.433.433 0 10-.865 0v.872c0 .239.193.433.432.433zM21.538 12.65h-.871a.432.432 0 100 .863h.871a.433.433 0 100-.864zM9.333 12.65h-.871a.432.432 0 100 .863h.871a.434.434 0 100-.864zM10.822 9.964a.435.435 0 00.305-.74l-.616-.615a.433.433 0 00-.611.612l.615.616a.432.432 0 00.307.127zM19.235 8.61l-.617.615a.432.432 0 10.611.612l.616-.616a.433.433 0 00-.61-.612zM14.922 9.296a4.738 4.738 0 00-3.38 1.52 4.954 4.954 0 00-1.357 3.574 4.878 4.878 0 001.574 3.413c.472.428.745 1.034.753 1.672v.466c0 .177.095.34.25.425v1.79a1.105 1.105 0 001.105 1.105h2.266a1.106 1.106 0 001.106-1.105v-1.79a.486.486 0 00.25-.425v-.462a2.345 2.345 0 01.786-1.706 4.937 4.937 0 00.079-7.1 4.782 4.782 0 00-3.432-1.377zm1.333 12.86a.122.122 0 01-.122.122h-2.266a.122.122 0 01-.122-.122v-1.723h2.51v1.723zm1.346-5.098a3.324 3.324 0 00-1.096 2.392h-3.01a3.28 3.28 0 00-1.07-2.361 3.9 3.9 0 01-1.257-2.738 3.964 3.964 0 011.088-2.86 3.749 3.749 0 015.41-.116 3.958 3.958 0 01-.065 5.683z"
        fill={fillColor || colors.permaBlueStoneNew}
      />
    </Svg>
  );
};

export default LightBulb;
