import RatingStar, { RatingStarProps } from './RatingStar';
import { useEmotionTheme } from '../../../index.next';

type RatingStarV1Props = Omit<RatingStarProps, 'stroke'>;
const RatingStarV1 = (props: RatingStarV1Props) => {
  const { colorRoles } = useEmotionTheme();
  return <RatingStar {...props} stroke={colorRoles.system.interactivePrimaryDefault} />;
};

export default RatingStarV1;
