import { useEffect, useState, useCallback } from 'react';
import moment from 'moment';

import UtilizationMetrics, { UtilizationMetricsData } from '../components/UtilizationMetrics';
import { usePerformanceActions, usePerformanceState } from '../hooks/performanceContext';
import { MetricsRecord } from '../types';

const formatMetricsData = (
  showPreviousWeek: boolean,
  metrics: MetricsRecord
): UtilizationMetricsData | null => {
  if (!metrics) {
    return null;
  }
  const { utilization } = metrics;
  if (!utilization) {
    return null;
  }
  const { metricValue, additionalData } = utilization;
  const {
    liveSessions,
    updatedAt,
    chatTherapistHours,
    chatClientHours,
    chatTherapistWords,
    chatClientWords,
    mediaMessageRecordHours,
    mediaMessageListenHours,
    prevWeekChatTherapistHours,
    prevWeekChatClientHours,
    prevWeekChatTherapistWords,
    prevWeekChatClientWords,
    prevWeekMediaMessageRecordHours,
    prevWeekMediaMessageListenHours,
    prevWeekLiveSessions,
    prevWeekMetricValue,
  } = additionalData;
  const weekStart = moment().startOf('week');
  const weekEnd = moment().endOf('week');
  const prevWeekStart = moment().subtract(1, 'week').startOf('week');
  const prevWeekEnd = moment().subtract(1, 'week').endOf('week');

  if (showPreviousWeek) {
    return {
      weekStart: prevWeekStart,
      weekEnd: prevWeekEnd,
      totalHours: prevWeekMetricValue,
      totalHoursPreviousWeek: undefined,
      updatedAt,
      liveSessions: prevWeekLiveSessions,
      chatTherapistHours: prevWeekChatTherapistHours,
      chatClientHours: prevWeekChatClientHours,
      chatTherapistWords: prevWeekChatTherapistWords,
      chatClientWords: prevWeekChatClientWords,
      mediaMessageRecordHours: prevWeekMediaMessageRecordHours,
      mediaMessageListenHours: prevWeekMediaMessageListenHours,
    };
  }

  return {
    weekStart,
    weekEnd,
    totalHours: metricValue,
    totalHoursPreviousWeek: prevWeekMetricValue,
    updatedAt: updatedAt ? new Date(updatedAt) : null,
    liveSessions,
    chatTherapistHours,
    chatClientHours,
    chatTherapistWords,
    chatClientWords,
    mediaMessageRecordHours,
    mediaMessageListenHours,
  };
};

const UtilizationMetricsContainer = () => {
  const { metrics, isError, isLoading } = usePerformanceState();
  const { dispatchGetMetrics } = usePerformanceActions();
  const [showPreviousWeek, setShowPreviousWeek] = useState(false);

  const toggleShowPreviousWeek = useCallback(() => {
    setShowPreviousWeek(!showPreviousWeek);
  }, [showPreviousWeek]);

  useEffect(() => {
    dispatchGetMetrics();
  }, [dispatchGetMetrics]);

  const metricsData = formatMetricsData(showPreviousWeek, metrics);

  if (!metricsData && (isLoading || isError)) {
    return <UtilizationMetrics isLoading={isLoading} isError={isError} />;
  }

  if (!metricsData) {
    return <UtilizationMetrics isEmpty />;
  }

  const { updatedAt } = metricsData;

  return (
    <UtilizationMetrics
      toggleShowPreviousWeek={toggleShowPreviousWeek}
      showPreviousWeek={showPreviousWeek}
      updatedAt={updatedAt}
      metricsData={metricsData}
    />
  );
};

export default UtilizationMetricsContainer;
