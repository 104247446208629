import { FunctionComponent, useState, useEffect } from 'react';
import { routePromise } from 'ts-frontend/helpers';
import { FirebaseCrashlytics } from '@capacitor-firebase/crashlytics';
import { getAuthSessionContext, refreshToken } from '../auth';
import ReactFrameService from '../reactFrame/ReactFrameService';
import { withRouter, RouteComponentProps } from '../../core/routerLib';
import ssoHelper from '../../utils/sso';
import LoadingScreen from '../screens/LoadingScreen';
import { setTrackerUserID } from '../../utils/analytics/eventTracker';
import { getUserData } from '../helpers/token';
import sessionStorage from '../../core/storage/sessionStorage';
import storage from '../../core/storage';

import useIonicEffect from '../../../hooks/useIonicEffect';

const AuthContainer: FunctionComponent<
  RouteComponentProps & {
    successRoute: string;
  }
> = ({ children, history, successRoute }) => {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const { pathname, search, hash } = document.location;
    const isLoginScreen = pathname === '/' || !!pathname.match(/\/(login)$/g);
    const isEmailVerification = pathname.startsWith('/email-verification');
    const isUnauthorizedScreen =
      pathname === '/' ||
      !!pathname.match(
        /\/(login|unauthorized|two-factor|forgot-password|change-password|logout|public)$/g
      );
    const reactFrameService = ReactFrameService.instance();
    const sessionContext = getAuthSessionContext();
    const urlHashParams = new URLSearchParams(hash.replace('#', '?'));
    // Remove from the `urlHashParams.toString()`
    urlHashParams.delete('analytics');

    if (sessionContext === 'auth') {
      refreshToken(true).then(async (success) => {
        if (success) {
          // set trackers userID
          setTrackerUserID(getUserData().id);

          if (isUnauthorizedScreen) {
            if (isLoginScreen && ssoHelper.isSSO()) {
              ssoHelper.loginAction();
            } else if (isEmailVerification) {
              // we get to this case when clicking on email verification link on the email.
              setReady(true);
            } else {
              await routePromise(successRoute);
            }
          } else {
            /** Final route the user will land on */
            const goTo = `${pathname}${search}${hash}`;
            /** replaceRoute is a variation of the dashboard */
            let replaceRoute = '';
            const roomID = pathname.replace(/\/room\/(\d+)\/?.*/g, '$1');
            if (!roomID) replaceRoute = successRoute;
            else replaceRoute = `/room/${roomID}`;
            // In order for the back button in some screens to redirect back to rooms
            // We need to push rooms into the stack at least once
            if (replaceRoute !== pathname) {
              /** Whether or not the url is inside rooms (dashboard) */
              const isNestedDashboardPath = goTo.match(/\/room\/\d+\/(?!\?).+/g);
              if (isNestedDashboardPath) {
                history.replace(replaceRoute);
                history.push(goTo);
              } else {
                history.replace(goTo);
              }
            }
            setReady(true);
          }
        } else if (!isUnauthorizedScreen) {
          sessionStorage.setItem('redirectTo', `${pathname}${search}`);
          storage.setItem('redirectUrl', `${pathname}${search}`);
          await routePromise(`/login${search}`);
        } else {
          if (isEmailVerification) {
            const urlHashParamsString = urlHashParams.toString() && `#${urlHashParams.toString()}`;

            history.replace(`${pathname}${search}${urlHashParamsString}`);
          }
          setReady(true);
        }
      });
    }

    // JWT Tokens are immediate authentication and don't need refreshing
    if (sessionContext === 'jwt') {
      if (reactFrameService.isJWTTokenExpired()) {
        history.replace('/unauthorized');
      }
      setReady(true);
    }

    if (sessionContext === 'frame') {
      const timeout = setTimeout(() => {
        if (ReactFrameService.isMobile()) {
          reactFrameService.closePopup();
        } else {
          history.replace('/unauthorized');
          setReady(true);
        }
      }, 12 * 1000);
      reactFrameService.addTokenListeners((token) => {
        if (token && token.length) {
          setReady(true);
          clearTimeout(timeout);
        }
      });
    }
  }, [history, successRoute]);

  useIonicEffect(() => {
    if (ready && getUserData().id) {
      FirebaseCrashlytics.setUserId({ userId: String(getUserData().id) });
    }
  }, [ready]);

  // Show spinner while we wait
  if (!ready) return <LoadingScreen />;
  return <>{children}</>;
};

export default withRouter(AuthContainer);
