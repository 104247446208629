import { useEffect, useRef, useState, Ref, useCallback, RefObject } from 'react';
import * as React from 'react';
import { FieldErrors } from 'react-hook-form';
import commonStyles from '../constants/commonStyles';
import { useA11yActions, useA11yState } from './a11yContextProvider';

const { crossBrowserFocusRing } = commonStyles;

// Use this hook in forms to programmatically focus the first invalid input on submit. It's effectiveness relies on aria-required being set properly
// Import the ref and set state action. The ref should be passed to the form container and the set state
// action should be used in the handleSubmit function ex:

// const { formContainerRef, setShouldFocusFirstInvalidField } = useErrorFocus();
// const handleSubmit = () => {
//     ...
//     ...
//     setShouldFocusFirstInvalidField(true);
//     return;
// }
export const useErrorFocus = () => {
  const [shouldFocusFirstInvalidField, setShouldFocusFirstInvalidField] = useState(false);
  const formContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const currentRef = formContainerRef.current;
    if (currentRef && shouldFocusFirstInvalidField) {
      const invalidInputsArray = Array.from(currentRef.querySelectorAll('[aria-invalid="true"]'));
      if (invalidInputsArray.length > 0) {
        (invalidInputsArray[0] as HTMLInputElement)?.focus();
      }
      setShouldFocusFirstInvalidField(false);
    }
  }, [shouldFocusFirstInvalidField]);

  return { formContainerRef, setShouldFocusFirstInvalidField };
};

let uniqueId = 0;

export const useUniqueID = (prefix: string): string => {
  // disable no-return-assign so we can return a string of the specified prefix combined with the stringified id
  // eslint-disable-next-line no-return-assign
  const [id] = useState(() => (uniqueId += 1));
  return `${prefix}${id}`;
};

export function useShareForwardedRef<T>(forwardedRef?: Ref<T>) {
  // final ref that will share value with forward ref. this is the one we will attach to components
  const innerRef = useRef<T>(null);

  useEffect(() => {
    // after every render - try to share current ref value with forwarded ref
    if (!forwardedRef) {
      return;
    }
    if (typeof forwardedRef === 'function') {
      forwardedRef(innerRef.current);
      return;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: by default forwardedRef.current is readonly. Let's ignore it
    // eslint-disable-next-line no-param-reassign
    forwardedRef.current = innerRef.current;
  });

  return innerRef;
}

/**
 * Programmatically focuses a container on render, allowing new contents heading to be announced
 * @param dependency
 * @param providedRef
 * @param shouldNotFocus
 * @returns {void}
 * The container should be assigned ref={containerRef} and aria-labelledby the id of the most relevant heading/title on the page
 * If a dependency argument is provided, the container will be programmatically focused when the dependency changes
 * By default, the container will be focused on mount
 * If a ref is provided as the second argument, the container that this ref is assigned to will be focused
 */
export const useContainerFocus = (
  dependency?: string | number | boolean,
  // assumes that the container will be a div
  providedRef?: RefObject<HTMLDivElement>,
  shouldNotFocus?: boolean
) => {
  // this assumes the container is one of our View components, which renders as a semantic div element
  const containerRef = useShareForwardedRef<HTMLDivElement>(providedRef);

  // default behavior focuses the container on mount
  useEffect(() => {
    if (shouldNotFocus) {
      return;
    }
    containerRef.current?.focus({ preventScroll: true });
  }, [containerRef, shouldNotFocus]);

  // focus the container on dependency change
  useEffect(() => {
    if (shouldNotFocus) {
      return;
    }
    if (dependency) {
      containerRef.current?.focus({ preventScroll: true });
    }
  }, [dependency, containerRef, shouldNotFocus]);

  return { containerRef };
};

// onClick could be added to this interface but I've found that onMouseDown generally works better, maybe because it fires before onClick
export interface EventFunctions {
  onFocus?: (e?: React.FocusEvent) => void;
  onBlur?: (e?: React.FocusEvent) => void;
  onMouseDown?: (e?: React.MouseEvent) => void;
}

// Accepts focus and keyboard events as optional arguments and integrates them into event handler functions if they exist.
// The functions should be imported and used within the component and the focus styling applied simply as style
// ex:
// const SomeThing = (props) => {
//   const { onFocus, onBlur, onMouseDown, style } = props;

//   const { handleFocus, handleBlur, handleMouseDown, focusStyle } = useKeyboardFocusEffect({
//     onFocus,
//     onBlur,
//     onMouseDown,
//   });

//   return (
//     <SomethingComponent
//       onFocus={handleFocus}
//       onBlur={handleBlur}
//       onMouseDown={handleMouseDown}
//       style={{ ...style, ...focusStyle }}
//     />
//   );
// };
export function useKeyboardFocusEffect(eventFunctions: EventFunctions = {}) {
  const [isFocused, setIsFocused] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const handleFocus = () => {
    eventFunctions?.onFocus?.();
    setIsFocused(true);
  };

  const handleBlur = () => {
    eventFunctions?.onBlur?.();
    setIsFocused(false);
    setIsClicked(false);
  };

  const handleMouseDown = () => {
    eventFunctions?.onMouseDown?.();
    setIsClicked(true);
  };

  const focusStyle = isFocused && !isClicked ? crossBrowserFocusRing : { outline: 'none' };

  return { isFocused, isClicked, handleFocus, handleBlur, handleMouseDown, focusStyle };
}

// Detects when focus moves in and out of an iframe, allowing for keyboard focus effect on the container.
// iFrameRef or getIframe() function argument specifies which iframe
export function useIFrameFocus(args: {
  iFrameRef?: RefObject<Element | null>;
  // necessary in certain cases like with StripeCardElement where ref.current does not suffice
  getIframe?: () => Element | null;
}) {
  if (!Object.keys(args).length) {
    // eslint-disable-next-line no-console
    console.warn(
      'No argument provided. You must provide a way to compare the iframe in question to document.activeElement'
    );
  }

  const [isIframeFocused, setIsIframeFocused] = useState(false);
  // helps distinguish between keyboard focus and mouse focus, allowing for keyboard-specific focus effect on iFrame container
  // ex: shouldShowContainerFocusEffect = isIframeFocused && !isMouseUser
  const [isMouseUser, setIsMouseUser] = useState(false);

  const windowBlurEventHandler = useCallback(() => {
    if (
      document.activeElement === args?.iFrameRef?.current ||
      document.activeElement === args?.getIframe?.()
    ) {
      setIsIframeFocused(true);
    }
  }, [args]);

  const windowFocusEventHandler = () => {
    setIsIframeFocused(false);
  };

  useEffect(() => {
    window.addEventListener('blur', windowBlurEventHandler, true);
    window.addEventListener('focus', windowFocusEventHandler, true);

    return () => {
      window.removeEventListener('blur', windowBlurEventHandler, true);
      window.removeEventListener('focus', windowFocusEventHandler, true);
    };
  }, [windowBlurEventHandler]);

  return { isIframeFocused, isMouseUser, setIsMouseUser };
}

/**
 * Scrolls to an invalid field in a form and centers that field. Does not affect invalid field focus logic. This prevents bad UX in FireFox with longer forms
 * @param errors
 */
export function useScrollToFirstInvalidField(errors: FieldErrors) {
  useEffect(() => {
    if (Object.keys(errors).length) {
      const invalidFields = Object.keys(errors)
        .map((name) => document.getElementsByName(name)[0])
        .filter((el) => !!el);
      invalidFields.sort((a, b) => b.scrollHeight - a.scrollHeight);
      invalidFields[0]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [errors]);
}

/**
 * Sets the color contrast theme to high while the specific functional component that calls it is mounted. Most common use case is a design that didn't account for low contrast mode.
 */
export const useTemporaryHighContrast = () => {
  const { setColorContrast } = useA11yActions();
  const { isHighContrast } = useA11yState();
  const contrastSettingRef = useRef<boolean>(isHighContrast);

  useEffect(() => {
    const currentRef = contrastSettingRef.current;
    setColorContrast(true);
    return () => {
      setColorContrast(currentRef);
    };
  }, [setColorContrast]);
};
