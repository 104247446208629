import styled from '@emotion/styled';
import { View } from '@talkspace/react-toolkit';

const ScrollViewComponent = styled(View)({
  marginRight: 4,
  overflowY: 'scroll',
  '::-webkit-scrollbar': {
    WebkitAppearance: 'none',
    width: 10,
    visibility: 'hidden',
  },
  '::-webkit-scrollbar-thumb': {
    margin: 5,
    backgroundColor: '#D8D8E0',
    WebkitBorderRadius: 5,
    visibility: 'hidden',
  },
  '::-webkit-scrollbar-thumb:hover': {
    backgroundColor: '#BECBE0',
  },
  '::-webkit-scrollbar-track': {
    margin: 4,
  },
  ':hover': {
    '::-webkit-scrollbar': {
      visibility: 'visible',
    },
    '::-webkit-scrollbar-thumb': {
      visibility: 'visible',
    },
  },
});

export default ScrollViewComponent;
