const PinkAlert = ({ style = {} }) => (
  <span style={style}>
    <svg width="18px" height="17px" viewBox="0 0 18 17" version="1.1">
      <g id="Page-1" stroke="none" fill="none">
        <g id="Emergency-contact-information" transform="translate(-34.000000, -195.000000)">
          <g id="Group-15" transform="translate(34.000000, 193.000000)">
            <g id="Group-2" transform="translate(0.721127, 0.000000)">
              <g id="Group-13">
                <g id="Group-12">
                  <rect
                    id="Rectangle-13"
                    fill="#FF0075"
                    x="1.77635684e-15"
                    y="2"
                    width="16.4732394"
                    height="17.0028818"
                    rx="4.25"
                  />
                  <text
                    id="!"
                    fontFamily="Roboto-Bold, Roboto"
                    fontSize="14"
                    fontWeight="bold"
                    fill="#FFFFFF"
                  >
                    <tspan x="6.27887324" y="15">
                      !
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </span>
);

export default PinkAlert;
