import React, { forwardRef } from 'react';
import styled from '../../../core/styled';
import View from '../../../components/View';
import SpacingView from '../SpacingView';
import { useWindowWidthState } from '../../../hooks/windowWidthContext';
import { spacing } from '../../tokens';
import { CardContentProps, CardContentVariant } from './types';
import SkeletonLoader from '../SkeletonLoader';

const { space200 } = spacing;

const LeftColumn = styled(View)<{ variant: CardContentVariant }>(({ variant }) => {
  return {
    width: variant === 'regular' ? 60 : 'fit-content',
    marginRight: space200,
  };
});

const CardContentSkeleton = forwardRef<HTMLDivElement, CardContentProps>(
  (props: CardContentProps, ref) => {
    const {
      centerContent,
      centerIcon,
      variant = 'regular',
      enableBottomRowHover,
      enableRightColHover,
      hideCaret,
      Icon,
      titleText,
      bodyText,
      TopRow,
      BottomRow,
      RightCol,
      Menu,
      onChildMouseEnter,
      onChildMouseLeave,
    } = props;
    const { isSmall } = useWindowWidthState();

    return (
      <>
        <View row flex={1} ref={ref}>
          {!!Icon && (
            <LeftColumn align="center" justify={centerIcon ? 'center' : 'start'} variant={variant}>
              <SkeletonLoader variant="Avatar" />
            </LeftColumn>
          )}
          <View row align={centerContent ? 'center' : 'start'} justify="space-between" flex={1}>
            <SpacingView
              itemSpacing={variant === 'slim' ? 'space0' : undefined}
              centerContent={centerContent}
            >
              {TopRow && (
                <View wrap="wrap" row flex={1} rowGap={6}>
                  <SkeletonLoader variant="StatusTag" />
                </View>
              )}
              {titleText && <SkeletonLoader style={{ width: 160 }} variant="headingMd" />}
              {bodyText && <SkeletonLoader variant="bodySm" />}
              {bodyText && <SkeletonLoader variant="bodySm" />}
            </SpacingView>
            {!hideCaret && !Menu && !RightCol && (
              <View justify={isSmall ? 'start' : 'center'}>
                <SkeletonLoader variant="Icon20" />
              </View>
            )}
            {!!RightCol && (
              <View
                justify="center"
                onMouseEnter={enableRightColHover ? onChildMouseEnter : undefined}
                onMouseLeave={enableRightColHover ? onChildMouseLeave : undefined}
              >
                <SkeletonLoader variant="Button" />
              </View>
            )}
          </View>
        </View>
        {BottomRow && (
          <View
            wrap="wrap"
            row
            flex={1}
            style={{ marginTop: space200 }}
            onMouseEnter={enableBottomRowHover ? onChildMouseEnter : undefined}
            onMouseLeave={enableBottomRowHover ? onChildMouseLeave : undefined}
          >
            <SkeletonLoader variant="ButtonWide" />
          </View>
        )}
      </>
    );
  }
);

export default CardContentSkeleton;
