import { Moment } from 'moment';
import camelCase from 'lodash/camelCase';

import { useFlags } from 'launchDarkly/FlagsProvider';
import {
  BaseButton,
  Big,
  Button,
  EmotionTheme,
  Small,
  useWindowWidthState,
  View,
} from '@talkspace/react-toolkit';
import { TherapistTimeslot } from 'ts-frontend/types';
import styled from '@/core/styled';

import { StaticProps } from './types';

export interface RecurringAvailableProps {
  callStartMoment: Moment;
  handleChooseSlot: (slot: TherapistTimeslot) => () => void;
  handleChooseDifferentDate: () => void;
  handleScheduleLater: () => void;
  availableSlotInOneWeek: TherapistTimeslot | undefined;
  availableSlotInTwoWeeks: TherapistTimeslot | undefined;
}

interface Props extends RecurringAvailableProps, StaticProps {}

const Styled = {
  MainContainer: styled(View)(({ isMobile }: { isMobile: boolean }) => {
    return {
      alignItems: 'center',
      marginBottom: isMobile ? 0 : 36,
    };
  }),
  MainTitle: styled(Big)(({ theme: { colors } }) => {
    return { color: colors.white, textAlign: 'center', padding: '0 16px' };
  }),
  MainSubTitle: styled(Small)(({ theme: { colors } }) => {
    return { color: colors.periwinkleGreyText, marginTop: 4 };
  }),
  SlotsContainer: styled(View)({ marginTop: 16, marginBottom: 24, flexDirection: 'row', gap: 8 }),
  SlotContainer: styled(View)({ flexDirection: 'column' }),
  SlotTitle: styled(Small)(({ theme: { colors } }) => {
    return { color: colors.white, textAlign: 'center' };
  }),
  SlotButton: styled(Button)(
    ({
      isMobile,
      disabled,
      theme: { colors },
    }: {
      isMobile: boolean;
      disabled: boolean;
      theme: EmotionTheme;
    }) => {
      return {
        marginTop: 2,
        width: isMobile ? 165 : 175,
        backgroundColor: disabled ? colors.permaFiord : colors.permaTalkspaceDarkGreen,
      };
    }
  ),
  SlotButtonContent: styled(View)(),
  SlotTime: styled(Small)(({ theme: { colors } }) => {
    return { color: colors.white };
  }),
  SlotDate: styled(Big)(({ theme: { colors } }) => {
    return { color: colors.white };
  }),
  LinkButtonsContainer: styled(View)(({ isMobile }: { isMobile: boolean }) =>
    isMobile
      ? { flexDirection: 'column-reverse', gap: 20 }
      : { flexDirection: 'row', justifyContent: 'center', gap: 32 }
  ),
  PrimaryLinkText: styled(Big)(({ theme: { colors } }) => {
    return { color: colors.permaPersianGreenApprox, fontWeight: 500 };
  }),
  SecondaryLinkText: styled(Big)(({ theme: { colors } }) => {
    return { color: colors.periwinkleGreyText, fontWeight: 500 };
  }),
};

const SlotContainer = ({
  title,
  slotStart,
  availableSlot,
  handleChooseSlot,
  nextStep,
}: {
  title: string;
  slotStart: Moment;
  availableSlot?: TherapistTimeslot;
  handleChooseSlot: (slot: TherapistTimeslot) => () => void;
  nextStep: () => void;
}) => {
  const slotTime = slotStart.format('ddd, h:mmA');
  const slotDate = slotStart.format('MMM D');
  const { isMobile } = useWindowWidthState();
  return (
    <Styled.SlotContainer>
      <Styled.SlotTitle>{availableSlot ? title : 'Unavailable'}</Styled.SlotTitle>
      <Styled.SlotButton
        dataQa={camelCase(`${title} Button`)}
        onPress={() => {
          if (availableSlot) {
            handleChooseSlot(availableSlot)();
            nextStep();
          }
        }}
        disabled={!availableSlot}
        isMobile={isMobile}
      >
        <Styled.SlotButtonContent>
          <Styled.SlotTime>{slotTime}</Styled.SlotTime>
          <Styled.SlotDate>{slotDate}</Styled.SlotDate>
        </Styled.SlotButtonContent>
      </Styled.SlotButton>
    </Styled.SlotContainer>
  );
};

const RecurringAvailable = ({
  nextStep,
  callStartMoment,
  availableSlotInOneWeek,
  availableSlotInTwoWeeks,
  handleChooseSlot,
  handleChooseDifferentDate,
  handleScheduleLater,
  isMobile,
}: Props) => {
  const dateInOneWeek = callStartMoment.clone().add(1, 'week');
  const dateInTwoWeeks = callStartMoment.clone().add(2, 'weeks');

  const slotTimeTitleString = callStartMoment.format('dddd[s at] h:mmA');

  const { repeatingBookingPopupCopyExperiment: isActiveRepeatingPopupCopyExperiment } = useFlags();

  const title = isActiveRepeatingPopupCopyExperiment
    ? 'Use the last few minutes to talk about when to meet next'
    : `Are ${slotTimeTitleString} a good time to meet?`;
  const subtitle = isActiveRepeatingPopupCopyExperiment
    ? `These times work for you. Do they work for your client?`
    : 'Your upcoming available dates:';
  return (
    <Styled.MainContainer isMobile={isMobile}>
      <Styled.MainTitle>{title}</Styled.MainTitle>
      <Styled.MainSubTitle>{subtitle}</Styled.MainSubTitle>
      <Styled.SlotsContainer>
        <SlotContainer
          title="In 1 week"
          availableSlot={availableSlotInOneWeek}
          slotStart={dateInOneWeek}
          handleChooseSlot={handleChooseSlot}
          nextStep={nextStep}
        />
        <SlotContainer
          title="In 2 weeks"
          availableSlot={availableSlotInTwoWeeks}
          slotStart={dateInTwoWeeks}
          handleChooseSlot={handleChooseSlot}
          nextStep={nextStep}
        />
      </Styled.SlotsContainer>
      <Styled.LinkButtonsContainer isMobile={isMobile}>
        <BaseButton onPress={handleScheduleLater} data-qa="scheduleLater">
          <Styled.SecondaryLinkText>I’ll schedule later</Styled.SecondaryLinkText>
        </BaseButton>
        <BaseButton onPress={handleChooseDifferentDate} data-qa="scheduleSession">
          <Styled.PrimaryLinkText variant="bigDarkGreen">
            Choose a different date
          </Styled.PrimaryLinkText>
        </BaseButton>
      </Styled.LinkButtonsContainer>
    </Styled.MainContainer>
  );
};

export default RecurringAvailable;
