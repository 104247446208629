import { useQuery, UseQueryResult } from 'react-query';

import apiHelper from '@/core/api/apiHelper';
import apiWrapper from '@/core/api/apiWrapper';
import { DoseSpotSSO } from './types';
import { ONE_MINUTE_IN_MS } from './utils';
import { doseSpotSSOQueryKey } from './queryKeys';

const fetchDoseSpotSSO = () => async (): Promise<DoseSpotSSO> => {
  const response = await apiWrapper.get(`${apiHelper().apiEndpoint}/v2/dosespot-clinicians/access`);
  return response.data.data;
};

const useQueryDoseSpotSSO = (enabled: boolean = true): UseQueryResult<DoseSpotSSO, Error> =>
  useQuery<DoseSpotSSO, Error>({
    queryKey: doseSpotSSOQueryKey(),
    queryFn: fetchDoseSpotSSO(),
    staleTime: 5 * ONE_MINUTE_IN_MS,
    cacheTime: 10 * ONE_MINUTE_IN_MS,
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled,
  });

export default useQueryDoseSpotSSO;
