import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const pathConfig: IconConfig = {
  standard: {
    default: {
      path: 'M3.33333 10H10V8.33333H3.33333V10ZM3.33333 7.5H13.3333V5.83333H3.33333V7.5ZM3.33333 5H13.3333V3.33333H3.33333V5ZM0 16.6667V1.66667C0 1.20833 0.163194 0.815972 0.489583 0.489583C0.815972 0.163194 1.20833 0 1.66667 0H15C15.4583 0 15.8507 0.163194 16.1771 0.489583C16.5035 0.815972 16.6667 1.20833 16.6667 1.66667V11.6667C16.6667 12.125 16.5035 12.5174 16.1771 12.8437C15.8507 13.1701 15.4583 13.3333 15 13.3333H3.33333L0 16.6667ZM2.625 11.6667H15V1.66667H1.66667V12.6042L2.625 11.6667Z',
    },
  },
};

const ChatMessageSquare = withDSIconMaker(pathConfig, 'chat-message-square');

export default ChatMessageSquare;
