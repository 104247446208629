import { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import {
  Big,
  PanelHeader,
  ResponsiveLayoutWithHeader,
  View,
  usePanelManagerActions,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import SharedFilesScreen from 'chat/screens/SharedFilesScreen';
import ReactFrameService from '@/auth/reactFrame/ReactFrameService';
import FileIcon from '../../Icons/File';
import { getNavBarHeight } from '../../Reusable/NavBar/NavBar';

interface Props {
  dispatch: Function;
  roomID: string;
}

const SharedFilesContainer: FunctionComponent<Props> = ({ dispatch, roomID }) => {
  const { setIsLeftPanelOnTop, setIsRightPanelOpen } = usePanelManagerActions();
  const { colors } = useEmotionTheme();
  const closeSharedFiles = () => {
    dispatch({ type: 'CLOSE_SHARED_FILES' });
    if (ReactFrameService.instance().isInFrame()) {
      setIsRightPanelOpen(false);
    } else {
      setIsLeftPanelOnTop(true);
      setIsRightPanelOpen(false);
    }
  };

  return (
    <ResponsiveLayoutWithHeader
      headerHeight={getNavBarHeight()}
      renderHeader={() => (
        <PanelHeader
          renderLeft={() => (
            <>
              <View row>
                <View style={{ marginRight: 9 }}>
                  <FileIcon />
                </View>
                <Big>Shared files</Big>
              </View>
            </>
          )}
          onRightPress={closeSharedFiles}
        />
      )}
    >
      <View flex={1} style={{ backgroundColor: colors.athensGrey }}>
        <SharedFilesScreen
          isTherapistChat
          roomID={Number(roomID)}
          onClosePress={closeSharedFiles}
        />
      </View>
    </ResponsiveLayoutWithHeader>
  );
};

const mapStateToProps = (state) => {
  return {
    roomID: state.room.roomID,
  };
};

export default connect(mapStateToProps)(SharedFilesContainer);
