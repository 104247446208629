import { LiveSessionModality } from 'ts-frontend/types';
import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';
import { getUserData } from '@/auth/helpers/token';
import cancelPromiseHelper from '@/core/api/cancelPromiseHelper';
import { EMessage } from '../../entities/EMessage';
import { StarredMessage } from '../../entities/StarredMessage';
import { OfferCategory } from '../../entities/Offers';
import { ApiScriptMessage, ScriptCategory, ScriptMessage } from '../../entities/Scripts';
import { RoomInfo, ApiRoomInfo } from '../../entities/RoomInfo';
import { EActiveSession } from '../../entities/ActiveSession';
import { PostMediaMessagePayload } from '../constants/inputTypes';
import { VideoCallEvent } from '../../types/videoCallTypes';
import { SessionStatus } from '../constants/chatTypes';

export const MESSAGES_PER_REQUEST = 20;

const { Blob, FormData } = window;

interface ApiResponse {
  data: any;
  status?: number;
}

interface GetStarredMessagesResponse {
  data: StarredMessage[];
}

interface SessionAlreadyEndedResponse {
  status: number;
}

interface ClientSessionStatusAPIResponse {
  data: SessionStatus | undefined;
}

const chatWrapperTsAPI = {
  get: (url, options = {}) => apiWrapper.get(`${apiHelper().apiEndpoint}${url}`, options),
  patch: (url, data?, options = {}) =>
    apiWrapper.patch(`${apiHelper().apiEndpoint}${url}`, data, options),
  post: (url, data, options = {}) =>
    apiWrapper.post(`${apiHelper().apiEndpoint}${url}`, data, options),
  put: (url, data?, options = {}) =>
    apiWrapper.put(`${apiHelper().apiEndpoint}${url}`, data, options),
  delete: (url, options = {}) => apiWrapper.delete(`${apiHelper().apiEndpoint}${url}`, options),
};

function prepareApiChatRequest(data: object) {
  return {
    appVersion: '1',
    deviceType: 'pc',
    ...data,
  };
}

function postApiChat(url: string, data?: object): Promise<ApiResponse> {
  return chatWrapperTsAPI.post(url, prepareApiChatRequest(data || {}));
}

const getApiChat = (url: string, data?: Record<string, any>): Promise<ApiResponse> => {
  const params = {};
  if (data) {
    Object.keys(data).forEach((key: string) => {
      if (data[key] !== undefined) {
        params[key] = data[key];
      }
    });
  }
  const query = new URLSearchParams(params).toString();
  const urlWithQuery = `${url}?${query}`;
  return chatWrapperTsAPI.get(urlWithQuery);
};

interface GetMessagesParams {
  roomID: number;
  isTherapistChat: boolean;
  lastMessageID?: number;
  maxMessageID?: number;
  mediaOnly?: boolean;
}

interface PostMessageParams {
  roomID: number;
  message: string;
  moreData?: any;
}

interface MediaPreUploadParams {
  roomID: number;
  mediaType: number;
  fileName: string;
  duration: number;
  fileSize?: number;
}

interface UploadFileProcessParams {
  roomID: number;
  mediaType: number;
  fileName: string;
  blob: Blob;
  duration?: number;
  fileSize?: number;
}

export default class ChatApiHelpers {
  private wrapWithCancel: (req: Promise<any>) => Promise<any>;

  public reset: () => void;

  public dismissIfCancelled: (err: Error) => any;

  public cancelAll: () => void;

  constructor() {
    const { cancelAll, reset, dismissIfCancelled, wrapWithCancel } = cancelPromiseHelper();
    this.wrapWithCancel = wrapWithCancel;
    this.reset = reset;
    this.dismissIfCancelled = dismissIfCancelled;
    this.cancelAll = cancelAll;
  }

  // #region Messages

  /**
   * api call to: `/v2/rooms/${roomID}/messages`
   * @param  {number} roomID
   * @param  {lastMessageId} optional last message id lo load partial
   * @returns Promise
   */
  public getMessages = ({
    roomID,
    isTherapistChat,
    lastMessageID,
    maxMessageID,
    mediaOnly,
  }: GetMessagesParams): Promise<EMessage[]> =>
    this.wrapWithCancel(
      getApiChat(`/v2/rooms/${roomID}/messages`, {
        lastMessageID,
        maxMessageID,
        limit: MESSAGES_PER_REQUEST,
        loadMediaURLs: true,
        mediaOnly,
      })
    ).then((res: ApiResponse) =>
      res.data.data?.messages.map((m) => new EMessage(m, isTherapistChat))
    );

  public processPostMessageResponse = (res: ApiResponse) => {
    if (res.data.switchTherapist) {
      // eslint-disable-next-line
      const suggestSwitchTherapistEvent = new CustomEvent('suggestSwitchTherapist', {
        detail: { text: res.data.switchTherapistText },
      });
      document.dispatchEvent(suggestSwitchTherapistEvent);
    }
    if (res.data.showSelectSessionPrompt) {
      const keepMessagingModalEvent = new CustomEvent('keepMessagingModal', {
        detail: {},
      });
      document.dispatchEvent(keepMessagingModalEvent);
    }
    return res.data;
  };

  private translatePostMessageDataForCompatibility = (data: any) => {
    const res = { ...data };

    res.mediaId = res.mediaID;
    delete res.mediaID;

    res.offer_id = res.offerID;
    res.plan_id = res.planID;
    delete res.offerID;
    delete res.planID;

    return res;
  };

  /**
   * api call to: `/rest/ios/method/postMessage`
   * @param  {number} roomID
   * @param  {string} message
   * @returns Promise
   */
  public postMessage = ({
    roomID,
    message,
    moreData = {},
  }: PostMessageParams): Promise<ApiResponse['data']> =>
    postApiChat(`/v2/rooms/${roomID}/messages`, {
      message,
      ...moreData,
    }).then((res: ApiResponse) => this.processPostMessageResponse(res.data));

  // #endregion

  // #region Media message upload

  private mediaPreUpload = ({
    roomID,
    mediaType,
    fileName,
    duration = 0,
    fileSize,
  }: MediaPreUploadParams): Promise<ApiResponse['data']> =>
    postApiChat(`/v2/rooms/${roomID}/messages/media-pre-upload`, {
      mediaType,
      fileName,
      duration,
      fileSize,
    }).then((res: ApiResponse) => res.data.data);

  private postFileAws = async (awsRequest, fileName: string, blob: Blob): Promise<boolean> => {
    const formData = new FormData();

    Object.keys(awsRequest.postData).forEach((key) =>
      formData.append(key, awsRequest.postData[key])
    );

    formData.append('file', blob, fileName);

    const res = await fetch(awsRequest.url, {
      method: 'post',
      body: formData,
    });

    return +res.status === +awsRequest.postData.success_action_status;
  };

  private uploadFileProcess = async ({
    roomID,
    mediaType,
    fileName,
    blob,
    duration = 0,
    fileSize,
  }: UploadFileProcessParams) => {
    const awsPostData = await this.mediaPreUpload({
      roomID,
      mediaType,
      fileName,
      duration,
      fileSize,
    });
    const awsUploadSuccess = await this.postFileAws(awsPostData, fileName, blob);
    if (!awsUploadSuccess) throw new Error('Aws upload failed');

    return awsPostData.mediaId || awsPostData.mediaID;
  };

  /**
   * This method uploads a media message to aws and posts to the room.
   * @param  {number} roomID
   * @param  {PostMediaMessagePayload} data
   */
  public postMediaMessage = async (roomID: number, data: PostMediaMessagePayload) => {
    const { durationSeconds, blob, type, name, size } = data;
    let fileName: string;
    let mediaID: number;
    let mediaType: number;
    switch (type) {
      case 'audio':
        mediaType = 1;
        fileName = name || `audio${Date.now()}.mp3`;
        mediaID = await this.uploadFileProcess({
          roomID,
          mediaType,
          fileName,
          blob: blob || new Blob(),
          duration: durationSeconds,
          fileSize: size,
        });
        break;
      case 'video':
        mediaType = 2;
        fileName = name || '';
        mediaID = await this.uploadFileProcess({
          roomID,
          mediaType,
          fileName,
          blob: blob || new Blob(),
          duration: durationSeconds,
          fileSize: size,
        });
        break;
      case 'photo':
        mediaType = 3;
        fileName = name || '';
        mediaID = await this.uploadFileProcess({
          roomID,
          mediaType,
          fileName,
          blob: blob || new Blob(),
          duration: 0,
          fileSize: size,
        });
        break;

      case 'pdf':
        mediaType = 4;
        fileName = name || '';
        mediaID = await this.uploadFileProcess({
          roomID,
          mediaType,
          fileName,
          blob: blob || new Blob(),
          duration: durationSeconds,
          fileSize: size,
        });
        break;
      default:
        throw new Error('Unknown media type');
    }

    return this.postMessage({
      roomID,
      message: '',
      moreData: {
        mediaID,
        mediaType,
      },
    });
  };

  // #endregion

  // #region Messages Actions

  /**
   * api call to: `/v2/rooms/{roomId}/matches/{matchId}`
   * wrapWithCancel
   * @param  {number} roomID
   * @param  {lastMessageId} optional last message id lo load partial
   * @returns Promise
   */
  public getMatches = (roomID: number, matchID: number): Promise<any> =>
    this.wrapWithCancel(
      apiWrapper.get(`${apiHelper().apiEndpoint}/v2/rooms/${roomID}/matches/${matchID}`)
    ).then((res) => {
      if (res.data && res.data.error) throw new Error(res.data.error);
      return res.data;
    });

  // #endregion

  // #region Scripts

  /**
   * api call to: `/v2/therapist/{therapistID}/canned-messages`
   * wrapWithCancel
   * @returns Promise
   */
  public getScripts = (): Promise<ScriptCategory[]> =>
    this.wrapWithCancel(
      apiWrapper
        .get(`${apiHelper().apiEndpoint}/v2/therapist/${getUserData().id}/canned-messages`)
        .then((res) => res.data.data.map((m) => new ScriptCategory(m)))
    );

  /**
   * api call to: `/v2/rooms/{roomID}/room-details`
   * wrapWithCancel
   * @param  {number} roomID
   * @returns Promise
   */
  public getRoomData = (roomID: number): Promise<RoomInfo> =>
    this.wrapWithCancel(
      apiWrapper
        .get(`${apiHelper().apiEndpoint}/v2/rooms/${roomID}/room-details`)
        .then((res) => new RoomInfo(res.data.data as ApiRoomInfo))
    );

  /**
   * api call to: GET `/v3/rooms/{roomID}/active-session`
   * wrapWithCancel
   * @param  {number} roomID
   * @param {number} modality
   * @returns Promise
   */
  public getActiveSession = (
    roomID: number,
    modality: LiveSessionModality
  ): Promise<EActiveSession> => {
    let endpointURL = `${apiHelper().apiEndpoint}/v3/rooms/${roomID}/active-session`;
    if (modality) {
      endpointURL += `?modality=${modality}`;
    }
    return this.wrapWithCancel(
      apiWrapper
        .get(endpointURL)
        .then((res) => new EActiveSession(res.data.data))
        .catch(() => null)
    );
  };

  /**
   * api call to: POST `/v3/rooms/{roomID}/active-session`
   * wrapWithCancel
   * @param  {number} roomID
   * @param {number} modality
   * @returns Promise
   */
  public postActiveSession = (roomID: number): Promise<void> => {
    const endpointURL = `${apiHelper().apiEndpoint}/v3/rooms/${roomID}/active-session`;
    return this.wrapWithCancel(apiWrapper.post(endpointURL).catch(() => null));
  };

  /**
   * api call to: `/v2/therapist/{therapistID}/edit-canned-message`
   * @param  {ScriptMessage} scriptMessage
   * @returns Promise
   */
  public editScriptMessage = (scriptMessage: ScriptMessage): Promise<ScriptMessage> => {
    const { id, title, text } = scriptMessage;
    return apiWrapper
      .post(`${apiHelper().apiEndpoint}/v2/therapist/${getUserData().id}/edit-canned-message`, {
        messageID: id,
        messageTitle: title,
        messageText: text,
      })
      .then((res) => new ScriptMessage(res.data.data as ApiScriptMessage));
  };

  /**
   * api call to: `/v2/therapist/{therapistID}/edit-canned-message`
   * @param  {ScriptMessage} scriptMessage
   * @returns Promise
   */
  public restoreScriptMessage = (scriptMessage: ScriptMessage): Promise<ScriptMessage> => {
    const { id, originatedFrom } = scriptMessage;
    return apiWrapper
      .post(`${apiHelper().apiEndpoint}/v2/therapist/${getUserData().id}/edit-canned-message`, {
        messageID: id,
        originatedFrom,
        shouldResetToOriginalState: true,
      })
      .then((res) => new ScriptMessage(res.data.data as ApiScriptMessage));
  };

  /**
   * api call to: `/v2/therapist/{therapistID}/canned-message/{messageID}`
   * @param  {ScriptMessage} scriptMessage
   * @returns Promise
   */
  public deleteScriptMessage = (scriptMessage: ScriptMessage): Promise<boolean> => {
    const { id } = scriptMessage;
    return apiWrapper
      .delete(`${apiHelper().apiEndpoint}/v2/therapist/${getUserData().id}/canned-message/${id}`)
      .then((res) => res.data as boolean);
  };

  /**
   * api call to: `/v2/therapist/{therapistID}/edit-canned-message`
   * @param  {string} title
   * @param  {string} message
   * @param  {number} categoryId
   * @returns Promise
   */
  public createScriptMessage = (
    title: string,
    message: string,
    categoryId: number
  ): Promise<ScriptMessage> =>
    apiWrapper
      .post(`${apiHelper().apiEndpoint}/v2/therapist/${getUserData().id}/edit-canned-message`, {
        messageTitle: title,
        messageText: message,
        messageCategoryID: categoryId,
      })
      .then((res) => new ScriptMessage(res.data.data as ApiScriptMessage));

  // #endregion

  // #region Offers

  /**
   * api call to: `/v2/payments/offer-categories`
   * wrapWithCancel
   * @returns Promise
   */
  public getOffers = (roomID: number): Promise<OfferCategory[]> =>
    this.wrapWithCancel(
      apiWrapper.get(`${apiHelper().apiEndpoint}/v2/payments/offer-categories?roomID=${roomID}`)
    ).then((res) => res.data.data);

  /**
   * api call to: `/rest/ios/method/postMessage`
   * @param  {number} roomID
   * @param  {number} offerId
   * @param  {number} planId
   * @returns Promise
   */
  public postOffer = (roomID: number, offerId: number, planId: number): Promise<EMessage> =>
    this.postMessage({
      roomID,
      message: '',
      moreData: {
        offerID: offerId,
        planID: planId,
      },
    });
  // #endregion

  // #region JWT

  /**
   * api call to: `/v3/users/redirect-token`
   * wrapWithCancel
   * @returns Promise
   */
  public getRedirectToken = (): Promise<any> =>
    this.wrapWithCancel(apiWrapper.get(`${apiHelper().apiEndpoint}/v3/users/redirect-token`)).then(
      (res) => {
        const {
          data: {
            data: { redirectToken },
          },
        } = res;
        return redirectToken;
      }
    );
  // #endregion

  /**
   * api call to: POST `/v2/stars`
   * @param  {number} messageID
   * @returns Promise
   */
  public starMessage = (messageID: number): Promise<ApiResponse['data']> =>
    this.wrapWithCancel(apiWrapper.post(`${apiHelper().apiEndpoint}/v2/stars`, { messageID })).then(
      (res: ApiResponse) => res.data
    );

  /**
   * api call to: DELETE `/v2/stars`
   * @param  {number} messageID
   * @returns Promise
   */
  public unstarMessage = (messageID: number): Promise<ApiResponse['data']> =>
    this.wrapWithCancel(apiWrapper.delete(`${apiHelper().apiEndpoint}/v2/stars/${messageID}`)).then(
      (res: ApiResponse) => res.data
    );

  /**
   * api call to: GET `/v3/users/{userID}/stars`
   * @param  {number} userID
   * @param  {number} lastMessageID
   * @returns Promise
   */
  public getStarredMessagesByUser = (
    userID: number,
    lastMessageID?: number
  ): Promise<GetStarredMessagesResponse> => {
    let endpointURL = `${apiHelper().apiEndpoint}/v3/users/${userID}/stars`;
    if (lastMessageID) {
      endpointURL += `?lastMessageID=${lastMessageID}`;
    }
    return this.wrapWithCancel(apiWrapper.get(endpointURL)).then((res: ApiResponse) => res.data);
  };

  /**
   * api call to: GET `/v3/rooms/{roomID}/stars`
   * @param  {number} roomID
   * @param  {number} lastMessageID
   * @returns Promise
   */
  public getStarredMessagesInRoom = (
    roomID: number,
    lastMessageID?: number
  ): Promise<GetStarredMessagesResponse> => {
    let endpointURL = `${apiHelper().apiEndpoint}/v2/rooms/${roomID}/stars`;
    if (lastMessageID) {
      endpointURL += `?lastMessageID=${lastMessageID}`;
    }
    return this.wrapWithCancel(apiWrapper.get(endpointURL)).then((res: ApiResponse) => res.data);
  };

  /**
   * api call to: POST `/v3/rooms/{roomID}/video-calls/{videoCallID}/events`
   * @param  {number} roomID
   * @param  {number} videoCallID
   * @param  {string} eventType
   * @returns Promise
   */
  public postLiveSessionEvent = (
    roomID: number,
    videoCallID: number,
    eventType: VideoCallEvent
  ): Promise<SessionAlreadyEndedResponse> =>
    this.wrapWithCancel(
      apiWrapper.post(
        `${apiHelper().apiEndpoint}/v3/rooms/${roomID}/video-calls/${videoCallID}/events`,
        { eventType }
      )
    );

  /**
   * api GET call to: `/v2/rooms/:roomID/session-status`
   * @param  {number} roomID
   * @returns Promise
   */
  public getSessionStatus = (roomID?: number): Promise<ClientSessionStatusAPIResponse> =>
    this.wrapWithCancel(
      apiWrapper.get(`${apiHelper().apiEndpoint}/v2/rooms/${roomID}/session-status`)
    ).then((res) => res.data);
}
