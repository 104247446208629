import { getWidthBreakpoints } from '@talkspace/react-toolkit';
import ReactFrameService from '@/auth/reactFrame/ReactFrameService';

export interface HandleDownloadParams {
  url: string;
  fileName: string;
  pauseModalFocusTrap?: () => void;
  resumeModalFocusTrap?: () => void;
}

export interface HandleMobileDownloadAndShareParams extends HandleDownloadParams {
  title?: string;
}

export type HandleDownload = (HandleDownloadParams) => void;
export type HandleMobileDownload = (HandleDownloadParams) => void;
export type HandleDownloadBlob = (url: string) => Promise<Blob>;
export type HandleCrossPlatformDownload = (HandleDownloadParams) => void;

export function createDownloadLink(href: string, fullFileName: string) {
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = href;
  a.download = fullFileName;
  document.body.appendChild(a);
  return a;
}

export const handleDownloadBlob: HandleDownloadBlob = (url) =>
  fetch(url).then((resp) => resp.blob());

export const handlePDFDownload: HandleDownload = ({
  url,
  fileName,
  pauseModalFocusTrap,
  resumeModalFocusTrap,
}) => {
  pauseModalFocusTrap && pauseModalFocusTrap();
  handleDownloadBlob(url).then((blob) => {
    const urlObject = URL.createObjectURL(blob);
    const downloadLink = createDownloadLink(urlObject, `${fileName}.pdf`);
    downloadLink.onclick = () => {
      if (downloadLink.parentNode) {
        downloadLink.parentNode.removeChild(downloadLink);
      }
      resumeModalFocusTrap && resumeModalFocusTrap();
    };
    downloadLink.click();
    URL.revokeObjectURL(urlObject);
  });
};

const handlePDFMobileDownload: HandleMobileDownload = ({
  url,
  fileName,
  pauseModalFocusTrap,
  resumeModalFocusTrap,
}) => {
  pauseModalFocusTrap && pauseModalFocusTrap();
  const downloadLink = createDownloadLink(url, `${fileName}.pdf`);
  downloadLink.onclick = () => {
    if (downloadLink.parentNode) {
      downloadLink.parentNode.removeChild(downloadLink);
    }
    resumeModalFocusTrap && resumeModalFocusTrap();
  };
  downloadLink.click();
};

export const handleCrossPlatformPDFDownload: HandleCrossPlatformDownload = ({
  url,
  fileName,
  pauseModalFocusTrap,
  resumeModalFocusTrap,
}) => {
  const reactFrameService = ReactFrameService.instance();
  const { isMobile } = getWidthBreakpoints(window.innerWidth);
  if (reactFrameService.isInFrame()) {
    reactFrameService.openShareSheet(url);
  } else if (isMobile) {
    handlePDFMobileDownload({ url, fileName, pauseModalFocusTrap, resumeModalFocusTrap });
  } else {
    handlePDFDownload({ url, fileName, pauseModalFocusTrap, resumeModalFocusTrap });
  }
};
