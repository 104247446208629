import { useEffect, useRef } from 'react';
import * as React from 'react';
import { CalendarDayShape } from 'react-dates';
import getCalendarDaySettings from 'react-dates/lib/utils/getCalendarDaySettings';
import { styled } from '../../../hooks/EmotionThemeProvider';
import TouchableView from '../../../components/TouchableView';
import { Standard, StandardVariant } from '../../../components/Typography';
import View from '../../../components/View';

export interface DayColors {
  background: string;
  text: StandardVariant;
}

const CustomView = styled(TouchableView)({
  justifyContent: 'center',
});

const CalendarDayTextWrapper = styled(View)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const BlueDot = styled(View)(({ theme: { colors } }) => {
  return { height: 4, width: 4, backgroundColor: colors.purple, borderRadius: 2 };
});

const CalendarDay = (
  props: CalendarDayShape & {
    available?: boolean;
    calendarDayHeight: number;
    getDayColors: (
      day: moment.Moment,
      modifiers: NonNullable<CalendarDayShape['modifiers']>
    ) => DayColors;
  }
) => {
  const {
    day,
    tabIndex,
    isFocused,
    getDayColors,
    ariaLabelFormat,
    calendarDayHeight,
    daySize,
    modifiers,
    onDayMouseEnter,
    onDayMouseLeave,
    onDayClick,
    phrases,
    available,
  } = props;

  const buttonRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (tabIndex === 0) {
      requestAnimationFrame(() => {
        if (buttonRef.current) {
          buttonRef.current.focus();
        }
      });
    }
  }, [tabIndex, isFocused]);

  if (!day) return <View as="td" style={{ display: 'table-cell' }} />;

  const newModifiers = modifiers || new Set();

  const { background, text } = getDayColors(day, newModifiers);

  const { useDefaultCursor, daySizeStyles, ariaLabel } = getCalendarDaySettings(
    day,
    ariaLabelFormat,
    daySize,
    newModifiers,
    phrases
  );

  return (
    <View
      as="td"
      align="center"
      justify="center"
      style={{
        display: 'table-cell',
        width: daySizeStyles.width,
        height: calendarDayHeight,
      }}
    >
      <CustomView
        role="button"
        ref={buttonRef}
        tabIndex={tabIndex}
        allowOnKeyDownPropagation
        aria-disabled={newModifiers.has('blocked')}
        data-qa={`calendarDay${day.format('DDMMYYYY')}`}
        onMouseEnter={(e) =>
          onDayMouseEnter?.(day, e as React.MouseEvent<HTMLTableDataCellElement>)
        }
        onMouseLeave={(e) =>
          onDayMouseLeave?.(day, e as React.MouseEvent<HTMLTableDataCellElement>)
        }
        onPress={(e) => onDayClick?.(day, e as React.MouseEvent<HTMLTableDataCellElement>)}
        aria-label={ariaLabel}
        style={{
          display: 'flex',
          width: daySizeStyles.width - 1,
          backgroundColor: background,
          height: calendarDayHeight - 1,
          cursor: useDefaultCursor ? 'default' : undefined,
        }}
      >
        <CalendarDayTextWrapper>
          <Standard variant={text}>{day.format('D')}</Standard>
          {available && <BlueDot />}
        </CalendarDayTextWrapper>
      </CustomView>
    </View>
  );
};

export default CalendarDay;
