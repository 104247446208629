import { useEffect, useState } from 'react';
import useMutationSaveDraftDischargeNote, {
  SaveDraftDischargeNoteMutateVariables,
} from 'hooks/notes/useMutationSaveDraftDischargeNote';
import useLocationStateOnMount from 'hooks/useLocationStateOnMount';
import { connect } from 'react-redux';
import useMutationSubmitDischargeNote from 'hooks/notes/useMutationSubmitDischargeNote';
import useIsClientDischargeV3OnForPlan from 'ts-frontend/hooks/useIsClientDischargeV3OnForPlan';

import { useHistory } from '@/core/routerLib';
import { getSubmenuTitleAndSubtitle, ACTION_SETTLED_TIMEOUT } from './utils';
import NoteDialog from './NoteDialog';
import DischargeSubmissionConfirmation from './DischargeSubmissionConfirmation';

const ConfirmDischargeNote = ({ clientUserID, location, match }) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const history = useHistory();
  const { roomID } = match.params;
  const locationStateOnMount = useLocationStateOnMount<SaveDraftDischargeNoteMutateVariables>();
  const { mutate: submitDischargeNote } = useMutationSubmitDischargeNote();

  useEffect(() => {
    if (locationStateOnMount === null) {
      history.push(`/room/${roomID}/notes-tab`);
    }
  }, [locationStateOnMount, history, roomID]);

  const handleActionSettled = () => {
    setTimeout(() => {
      history.push(`/room/${roomID}/notes-tab`);
    }, ACTION_SETTLED_TIMEOUT);
  };

  const { state } = location;
  const { formState, noteID } = state || { formState: undefined, noteID: undefined };

  const { mutate: saveDraftDischargeNote, isLoading: isSaveDraftLoading } =
    useMutationSaveDraftDischargeNote();

  const handleYesPress = () => {
    setShowSpinner(true);
    if (locationStateOnMount) {
      submitDischargeNote(
        { roomID, noteID, clientUserID, ...formState },
        {
          onSettled: handleActionSettled,
        }
      );
    }
  };

  const handleSuccess = () => {
    setTimeout(() => {
      setShowSpinner(false);
      history.push(`${noteID}/edit`);
    }, ACTION_SETTLED_TIMEOUT);
  };

  const handleNoPress = () => {
    setShowSpinner(true);
    if (locationStateOnMount) {
      noteID
        ? saveDraftDischargeNote(
            { ...formState, roomID, noteID },
            {
              onSuccess: handleSuccess,
            }
          )
        : history.push(`new`, { formState });
    } else {
      history.push(`/room/${roomID}/notes-tab`);
    }
  };

  const { title, subtitle } = getSubmenuTitleAndSubtitle({
    noteType: 'discharge',
    formMode: 'create',
  });

  const isClientDischargeV3OnForPlan = useIsClientDischargeV3OnForPlan(roomID);

  return isClientDischargeV3OnForPlan ? (
    <DischargeSubmissionConfirmation
      clientUserID={clientUserID}
      location={location}
      match={match}
    />
  ) : (
    <NoteDialog
      dialogType="confirm-save"
      title={title}
      subtitle={subtitle}
      isLoading={isSaveDraftLoading}
      onYesPress={handleYesPress}
      onNoPress={handleNoPress}
      showSpinnerOnYes={showSpinner}
      noButtonFirst
    />
  );
};

const mapStateToProps = (state) => {
  return {
    clientUserID: state.room.clientUserID,
  };
};

export default connect(mapStateToProps)(ConfirmDischargeNote);
