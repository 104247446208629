import AgoraRTC, { IAgoraRTCClient, ICameraVideoTrack } from 'agora-rtc-sdk-ng';
import { useEffect, useRef, useState } from 'react';
import { getUserData } from '@/auth/helpers/token';
import PromiseDebouncer from '../utils/PromiseDebouncer';
import { STREAM_ID_PREFIX } from '../types/videoCallTypes';

const useCamera = ({
  agoraClient,
  isJoined,
  isVideoOn,
  onError,
  selectedCamera,
}: {
  agoraClient: IAgoraRTCClient | undefined;
  isJoined: boolean;
  isVideoOn: boolean;
  onError: Function;
  selectedCamera: MediaDeviceInfo | undefined;
}) => {
  const debouncer = useRef<PromiseDebouncer | null>(null);
  const streamingDeviceId = useRef<string | null>(null);
  const [videoTrack, setVideoTrack] = useState<ICameraVideoTrack>();

  useEffect(() => {
    if (!debouncer.current) {
      debouncer.current = new PromiseDebouncer();
    }

    debouncer.current.debounce(async () => {
      if (!agoraClient || !isJoined) {
        return;
      }

      try {
        const existingTrack = agoraClient?.localTracks.find((x) => x.trackMediaType === 'video');
        const isMuting = !isVideoOn && existingTrack;
        const isUnmuting = isVideoOn && !existingTrack;
        const isChangingDevice =
          isVideoOn &&
          streamingDeviceId.current &&
          streamingDeviceId.current !== selectedCamera?.deviceId;

        if ((isMuting || isChangingDevice) && existingTrack) {
          await agoraClient.unpublish(existingTrack);
          existingTrack.close();
        }

        if (isUnmuting || isChangingDevice) {
          const newTrack = await AgoraRTC.createCameraVideoTrack({
            cameraId: selectedCamera?.deviceId,
          });

          await newTrack.setEncoderConfiguration('720p_1');

          streamingDeviceId.current = selectedCamera?.deviceId || null;
          await agoraClient.publish(newTrack);
          newTrack.play(`${STREAM_ID_PREFIX}${getUserData().id}`);

          setVideoTrack(newTrack);
        }
      } catch (error) {
        onError(error);
      }
    });
  }, [agoraClient, isJoined, isVideoOn, onError, selectedCamera]);

  return videoTrack;
};

export default useCamera;
