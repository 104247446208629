/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, ReactElement, useState, useCallback } from 'react';
import {
  useTable,
  useSortBy,
  usePagination,
  useColumnOrder,
  Column as ColumnObject,
  Row as RowObject,
  Cell as CellObject,
  ColumnInstance as ColumnInstanceObject,
  HeaderGroup as HeaderGroupObject,
  SortingRule as SortingRuleObject,
} from 'react-table';
import styled, { EmotionStyle, useEmotionTheme } from '../../core/styled';
import { useWindowWidthState } from '../../hooks/windowWidthContext';
import { SorterIcon } from '../icons';
import View from '../View';
import Spinner from '../Spinner';
import Pagination from './Pagination';

// https://github.com/TanStack/react-table/issues/1591 - Typescript Discussion
export type TableColumn = ColumnObject;
export type TableRow = RowObject<Record<string, any>>;
export type Cell<T extends Record<any, any>> = CellObject<T>;
export type ColumnInstance = ColumnInstanceObject;
export type HeaderGroup = HeaderGroupObject;
export type SortingRule = SortingRuleObject<Record<string, any>>;

interface TableProps<T> {
  columns: TableColumn[];
  data: T[];
  CustomFilter?: ReactElement;
  Empty?: ReactElement;
  ControlledEmpty?: ReactElement;
  tableStyle?: EmotionStyle;
  initialState?: Record<string, unknown>;
  enablePagination?: boolean;
  pageSizes?: number[];
  hiddenColumns?: string[];
  columnOrder?: string[];
  autoResetSortBy?: boolean;
  autoResetPage?: boolean;
  isLoading: boolean;
  disableSortRemove?: boolean;
  hideHeader?: boolean;
  onStateChange?: (callback: {
    pageIndex: number;
    pageSize: number;
    sortBy: SortingRule[];
  }) => void;
  getHeaderProps?: (props?: HeaderGroup) => Record<string, unknown>;
  getColumnProps?: (props?: HeaderGroup | ColumnInstance) => Record<string, unknown>;
  getRowProps?: (props?: TableRow) => Record<string, unknown>;
  getCellProps?: (props?: Cell<any>) => Record<string, unknown>;
  compareInsensitiveCase?: (field1: string, field2: string, desc?: boolean) => number;
}

const defaultPropGetter = () => {
  return {};
};

const StyledTableHeader = styled.th(({ onClick }) => {
  return {
    paddingBottom: 8,
    paddingTop: 12,
    paddingLeft: 16,
    paddingRight: 16,
    textAlign: 'left',
    ...(onClick && { cursor: 'pointer' }),
  };
});

const StyledRow = styled.tr(({ theme: { colors } }) => {
  return {
    background: colors.white,
    '&:hover': {
      background: colors.permaGreyed,
    },
  };
});

const TABLE_BORDER_RADIUS = 7;

const StyledTable = styled.table<{ hideHeader: boolean }>(({ hideHeader }) => {
  return {
    width: '100%',
    margin: '1em 0',
    border: '1px solid rgb(221, 227, 234)',
    boxShadow: 'none',
    borderRadius: TABLE_BORDER_RADIUS,
    textAlign: 'left',
    color: 'rgba(0,0,0,.87)',
    borderCollapse: 'separate',
    borderSpacing: '0',
    '& tr:first-child td': {
      ...(hideHeader && {
        borderTop: 'none',
        '&:first-child': {
          borderTopLeftRadius: TABLE_BORDER_RADIUS,
        },
        '&:last-child': {
          borderTopRightRadius: TABLE_BORDER_RADIUS,
        },
      }),
    },
    '& tr:last-child td': {
      '&:first-child': {
        borderBottomLeftRadius: TABLE_BORDER_RADIUS,
      },
      '&:last-child': {
        borderBottomRightRadius: TABLE_BORDER_RADIUS,
      },
    },
  };
});

const StyledThead = styled.thead(() => {
  return {
    '& th:first-child': {
      borderTopLeftRadius: TABLE_BORDER_RADIUS,
    },
    '& th:last-child': {
      borderTopRightRadius: TABLE_BORDER_RADIUS,
    },
  };
});

const StyledCell = styled.td<{ isMobile?: boolean }>(({ theme: { colors }, isMobile = false }) => {
  return {
    borderTop: `solid ${colors.permaPowderBlue} 1px`,
    verticalAlign: 'top',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: isMobile ? 14 : 16,
    paddingRight: isMobile ? 14 : 16,
    cursor: 'pointer',
  };
});

const HeaderContainer = styled(View)(() => {
  return {
    whiteSpace: 'nowrap',
  };
});

const GreyBox = styled(View)<{ isMobile: boolean }>(({ theme: { colors }, isMobile }) => {
  return {
    padding: 15,
    borderWidth: 1,
    borderRadius: 8,
    borderStyle: 'solid',
    borderColor: colors.permaPowderBlue,
    backgroundColor: colors.permaGhostWhite,
    minHeight: 119,
    width: '100%',
    textAlign: isMobile ? 'center' : 'left',
    alignItems: 'center',
  };
});

const StyledSorterIcon = styled(SorterIcon)(() => {
  return {
    marginLeft: 5,
  };
});

// eslint-disable-next-line @typescript-eslint/ban-types
const Table = <DataItem extends object>({
  columns,
  data,
  CustomFilter,
  Empty,
  ControlledEmpty,
  initialState = { pageIndex: 0, pageSize: 10 },
  pageSizes = [10, 25, 50],
  tableStyle,
  hiddenColumns,
  columnOrder,
  hideHeader = false,
  enablePagination = false,
  isLoading = false,
  autoResetSortBy = false,
  autoResetPage = false,
  disableSortRemove = false,
  onStateChange,
  getHeaderProps = defaultPropGetter,
  getColumnProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
  compareInsensitiveCase,
}: TableProps<DataItem>) => {
  const { isMobile } = useWindowWidthState();
  const { colors } = useEmotionTheme();
  const [hoverRowID, setHoverRowID] = useState<string | null>(null);

  const sortTypes = compareInsensitiveCase
    ? {
        alphanumeric: (row1: TableRow, row2: TableRow, columnName: string) =>
          compareInsensitiveCase(row1.values[columnName], row2.values[columnName]),
      }
    : undefined;

  const {
    rows,
    headerGroups,
    footerGroups,
    page,
    pageCount,
    canPreviousPage,
    canNextPage,
    setHiddenColumns,
    setColumnOrder,
    getTableProps,
    getTableBodyProps,
    prepareRow,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState,
      /*
        any prefix with auto that is handling external data that keeps changing should be false, 
        otherwise the table will keep resetting for example the sorting is not working the pagination keeps on resetting to first page
        https://react-table.tanstack.com/docs/faq#how-do-i-stop-my-table-state-from-automatically-resetting-when-my-data-changes
      */
      autoResetSortBy,
      autoResetPage,
      disableSortRemove,
      sortTypes,
    },
    useSortBy,
    useColumnOrder,
    usePagination
  );

  useEffect(() => {
    if (onStateChange) {
      onStateChange({ pageIndex, pageSize, sortBy });
    }
  }, [onStateChange, pageIndex, pageSize, sortBy]);

  useEffect(() => {
    if (hiddenColumns) {
      setHiddenColumns(hiddenColumns);
    }
  }, [hiddenColumns, setHiddenColumns]);

  useEffect(() => {
    if (columnOrder) {
      setColumnOrder(columnOrder);
    }
  }, [columnOrder, setColumnOrder]);

  const buildSorterIcons = (column: ColumnInstance) => {
    // when setting up the columns make sure to have an accessor key so it can sort correctly
    if (!column.canSort) {
      return null;
    }
    if (column.isSorted) {
      return <StyledSorterIcon type={column.isSortedDesc ? 'down' : 'up'} />;
    }
    return <StyledSorterIcon />;
  };

  const onSortKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.currentTarget.click();
    }
  };

  const buildColumns = useCallback(() => {
    let ariaTabIndex = 0;

    const tableColumns = headerGroups.map((headerGroup) => (
      <tr {...headerGroup.getHeaderGroupProps()}>
        {headerGroup.headers.map((column) => {
          const sorterIcons = buildSorterIcons(column);

          const styledHeaderProps: EmotionStyle = {
            'aria-sort': 'no-sort',
            ...column.getHeaderProps([
              { className: column.className, style: column.style },
              getColumnProps(column),
              getHeaderProps(column),
              column.getSortByToggleProps(),
            ]),
            ...(sorterIcons && {
              tabIndex: ariaTabIndex,
              onKeyDown: onSortKeyDown,
              'aria-sort': 'descending/ascending',
            }),
          };

          if (sorterIcons) {
            ariaTabIndex += 1;
          }

          return (
            <StyledTableHeader {...styledHeaderProps}>
              <HeaderContainer row align="center">
                {column.render('Header')}
                {sorterIcons}
              </HeaderContainer>
            </StyledTableHeader>
          );
        })}
      </tr>
    ));
    return <StyledThead>{tableColumns}</StyledThead>;
  }, [getColumnProps, getHeaderProps, headerGroups]);

  const buildRows = useCallback(() => {
    const tableRows = (enablePagination ? page : rows).map((row) => {
      prepareRow(row);
      return (
        <StyledRow
          style={{ background: isMobile && colors.lavendarBlush }}
          {...row.getRowProps(getRowProps(row))}
          onMouseEnter={() => setHoverRowID(row.id)}
          onMouseLeave={() => setHoverRowID(null)}
        >
          {row.cells.map((cell) => (
            <StyledCell
              {...cell.getCellProps([
                { className: cell.column.className, style: cell.column.style },
                getColumnProps(cell.column),
                getCellProps(cell),
              ])}
              isMobile={isMobile}
            >
              {cell.render('Cell', { isHovering: row.id === hoverRowID })}
            </StyledCell>
          ))}
        </StyledRow>
      );
    });

    return <tbody {...getTableBodyProps()}>{tableRows}</tbody>;
  }, [
    colors.lavendarBlush,
    enablePagination,
    getCellProps,
    getColumnProps,
    getRowProps,
    getTableBodyProps,
    hoverRowID,
    isMobile,
    page,
    prepareRow,
    rows,
  ]);

  const buildFooter = useCallback(() => {
    const hasFooter: boolean = columns.some((column) => 'Footer' in column);
    if (!hasFooter) return null;

    const footerRows = footerGroups.map((group) => (
      <StyledRow {...group.getFooterGroupProps()}>
        {group.headers.map((column) => (
          <StyledCell {...column.getFooterProps()} isMobile={isMobile}>
            {column.render('Footer')}
          </StyledCell>
        ))}
      </StyledRow>
    ));

    return <tfoot>{footerRows}</tfoot>;
  }, [columns, footerGroups, isMobile]);

  if (isLoading) {
    return (
      <GreyBox row justify="center" isMobile={isMobile}>
        <Spinner
          primaryColor={colors.permaBaliHai}
          secondaryColor={colors.permaGhostWhite}
          style={{ width: 26, height: 26 }}
        />
      </GreyBox>
    );
  }

  if (data.length === 0 && Empty) {
    return Empty;
  }

  if (ControlledEmpty) {
    return ControlledEmpty;
  }

  return (
    <>
      {CustomFilter}
      <StyledTable {...getTableProps()} style={tableStyle} hideHeader={hideHeader}>
        {!hideHeader && buildColumns()}
        {buildRows()}
        {buildFooter()}
      </StyledTable>
      {enablePagination && (
        <Pagination
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          pageSizes={pageSizes}
          setPageSize={setPageSize}
          pageSize={pageSize}
          nextPage={nextPage}
          previousPage={previousPage}
          gotoPage={gotoPage}
          pageCount={pageCount}
          pageIndex={pageIndex}
          isMobile={isMobile}
        />
      )}
    </>
  );
};

export default Table;
