import { FunctionComponent } from 'react';
import FocusTrapReact from 'focus-trap-react';
import styled from '../../core/styled';
import HiddenText from '../HiddenText';
import { useUniqueID } from '../../hooks/a11yHelper';

export interface FocusTrapProps {
  wrapper?: string;
  escapeCloses?: boolean;
  onDeactivate?: () => void;
  role?: string;
  active?: boolean;
  // text for visually hidden title that the contents wrapper of the focus trap can be aria-labelledby
  titleText: string;
  panelId?: string;
}

const ContentsWrapper = styled.div({
  ':focus': {
    outline: 'none',
  },
});
// TODO: allow for panel containers to receive focus onDeactivate (specific to account menu). Currently, focus returns to the original button that rendered FocusTrap when it deactivates

// Wrapping component that traps keyboard focus when active
// Default behavior: inner div programmatically receives focus on mount, click outside deactivates the trap, escape press does not deactivate, focus returns to original button on unmmount
const FocusTrap: FunctionComponent<FocusTrapProps> = ({
  children,
  wrapper,
  escapeCloses,
  onDeactivate,
  role,
  active,
  titleText,
  panelId,
  ...otherProps
}) => {
  const defaultFocusTrapWrapperID = useUniqueID('defaultFocusTrapWrapperID');
  const hiddenTitleID = useUniqueID('hiddenTitleID');
  let finalWrapperId = '#';
  if (panelId) {
    finalWrapperId += panelId;
  } else if (wrapper) {
    finalWrapperId += wrapper;
  } else {
    finalWrapperId += defaultFocusTrapWrapperID;
  }
  return (
    <FocusTrapReact
      active={active}
      focusTrapOptions={{
        clickOutsideDeactivates: true,
        initialFocus: finalWrapperId,
        escapeDeactivates: !!escapeCloses,
        onDeactivate,
        ...otherProps,
      }}
    >
      <ContentsWrapper
        tabIndex={-1}
        id={panelId || defaultFocusTrapWrapperID}
        aria-labelledby={hiddenTitleID}
        role={panelId ? 'region' : role}
      >
        <HiddenText id={hiddenTitleID}>{titleText}</HiddenText>
        {children}
      </ContentsWrapper>
    </FocusTrapReact>
  );
};

export default FocusTrap;
