import styled from '../../core/styled';
import View from '../View';

const ErrorIcon = styled(View)<{ isValid?: boolean }>(({ isValid, theme: { colors } }) => {
  return {
    display: isValid ? 'none' : 'block',
    position: 'relative',
    float: 'right',
    borderRadius: '50%',
    background: colors.red,
    width: 6,
    height: 6,
    right: 4,
    bottom: 16,
  };
});

export default ErrorIcon;
