import {
  ChargeType,
  FlowVariant,
  LiveSessionModality,
  SessionModality,
  VideoCreditOffer,
} from 'ts-frontend/types';
import { RoomType } from 'ts-frontend/entities/Room';
import { useEffect, useState, useMemo, useLayoutEffect } from 'react';
import * as React from 'react';
import { useFlags } from 'launchDarkly/FlagsProvider';
import {
  OptionBlockProps,
  View,
  Large,
  Button,
  BaseButton,
  Standard,
  VideoModality,
  ChatModality,
  AudioModality,
  MessagingModality,
  OptionBlock,
  Spinner,
  useEmotionTheme,
  Big,
  Small,
  ExtraHuge,
  useWindowWidthState,
} from '@talkspace/react-toolkit';
import TabRadioGroup from '@talkspace/react-toolkit/src/designSystems/components/TabRadioGroup';
import { Video, Phone, Comment, Envelope } from '@talkspace/react-toolkit/src/designSystems/icons';

import { useTranslation, Trans } from '@talkspace/i18n';
import styled from '@/core/styled';
import { useHistory, useRouteMatch } from '@/core/routerLib';
import InRoomSchedulingError from '../inRoomSchedulingError';
import MessagingSessionDisabledModal from '../MessagingSessionDisabledModal';
import LiveSessionsNotCoveredModal from '../LiveSessionsNotCoveredModal';
import KeepUpTheGreatWorkHeading from '../KeepUpTheGreatWorkHeading';
import PostLVSCheckInHeading from '../PostLVSCheckInHeading';
import {
  useInRoomSchedulingActions,
  useInRoomSchedulingState,
} from '../../hooks/inRoomSchedulingContext';
import { trackSelectScheduleModality } from '@/utils/analytics/events';
import useQueryClientTransactions from '../../hooks/useQueryClientTransactions';
import BottomButtonContainer from '../BottomButtonContainer';

const CanNotPurchaseCardWrapper = styled(View)(({ theme: { colors } }) => {
  return {
    flex: 1,
    maxWidth: 360,
    minWidth: 335,
    height: 100,
    backgroundColor: colors.permaLinkWaterGrey,
    borderRadius: 10,
    paddingLeft: 17,
    paddingRight: 16,
    paddingTop: 15,
    paddingBottom: 15,
    justifyContent: 'center',
    alignItems: 'center',
    '&:focus': {
      outlineOffset: -1,
    },
  };
});

const SkipButton = styled(BaseButton)(({ theme: { colors } }) => {
  return {
    color: colors.slateGrey,
    backgroundColor: colors.white,
    marginBottom: 16,
    marginTop: 20,
  };
});

const ScheduleSessionTitle = styled(Big)(({ theme: { colors } }) => {
  return {
    alignSelf: 'start',
    marginBottom: 8,
    color: colors.permaGrayLike,
  };
});

const ModalityDescription = styled(Small)(({ theme: { colors } }) => {
  return {
    width: 228,
    color: colors.grey950,
  };
});

const SelectModality: React.VFC<{
  handleChooseModality: (type: SessionModality) => void;
  isFromCheckInWizard: boolean;
  isFromPostLVSCheckInWizard: boolean;
  isOnboarding: boolean;
  isTherapist: boolean;
  onSkipBookNextSession: () => void;
  onClose: () => void;
  roomID: number;
  bookingIntroSession: boolean;
  roomType: RoomType;
  flowVariant?: FlowVariant;
  shouldSkipModalitySelection?: boolean;
  clientUserID?: number;
  shouldHideTitle?: boolean;
  shouldHideMessagingSessionTitle?: boolean;
  shouldHideButtonContainer?: boolean;
  shouldUseDsIcons?: boolean;
  shouldIgnoreActiveMessagingSession?: boolean;
}> = ({
  handleChooseModality,
  isFromCheckInWizard,
  isFromPostLVSCheckInWizard,
  isOnboarding,
  isTherapist,
  onSkipBookNextSession,
  onClose,
  roomID,
  bookingIntroSession,
  roomType,
  shouldSkipModalitySelection,
  flowVariant,
  clientUserID,
  shouldHideTitle = false,
  shouldHideMessagingSessionTitle = false,
  shouldHideButtonContainer = false,
  shouldUseDsIcons = false,
  shouldIgnoreActiveMessagingSession = false,
}) => {
  const { isMobile } = useWindowWidthState();
  const {
    creditOptions,
    isError,
    modality,
    isLoading,
    messagingSession,
    room,
    therapistInfo,
    liveModalitiesUnavailabilityMessage,
    errorMessage,
    videoCredits,
    clientUsageStats,
  } = useInRoomSchedulingState();
  const { dispatchResetShouldShowBookingSuccess } = useInRoomSchedulingActions();
  const history = useHistory();
  const { colors, colorRoles } = useEmotionTheme();
  const match = useRouteMatch();
  const isSwitchWizard = flowVariant === 'switchWizard';

  const {
    copayAtTimeOfService: copayAtTimeOfServiceActive,
    postLvsCheckInNoBookings,
    availabilitySwitch,
  } = useFlags();
  const { pendingInvoicesChargeTypes } =
    useFlags<{ pendingInvoicesChargeTypes?: Array<ChargeType> }>();
  const isClientTransactionsEnabled =
    !!pendingInvoicesChargeTypes &&
    pendingInvoicesChargeTypes.length > 0 &&
    !isTherapist &&
    !!room?.isSessionBased &&
    !!clientUserID;
  const {
    data: pendingInvoices,
    isLoading: isPendingInvoicesLoading,
    isError: isPendingInvoicesError,
  } = useQueryClientTransactions(
    {
      chargeTypes: pendingInvoicesChargeTypes,
      clientUserID: clientUserID!,
    },
    {
      enabled: isClientTransactionsEnabled,
    }
  );

  const [liveModalityUnavailableForModal, setLiveModalityUnavailableForModal] =
    useState<LiveSessionModality | null>(null);
  const [isMessagingSessionDisabledModalActive, setIsMessagingSessionDisabledModalActive] =
    useState(false);
  const [allowedSessionModalities, setAllowedSessionModalities] = useState<
    VideoCreditOffer['allowedSessionModalities']
  >({ video: false, audio: false, chat: false });
  const [isContinueButtonDisabled, setIsContinueButtonDisabled] = useState(true);

  const checkedPendingInvoices =
    (!!pendingInvoicesChargeTypes &&
      (!isClientTransactionsEnabled || (!!pendingInvoices && pendingInvoices.length === 0))) ||
    isPendingInvoicesError;

  const isIneligibleError = isError && errorMessage === 'user_ineligible';

  const shouldShowIneligiblePage =
    copayAtTimeOfServiceActive && isIneligibleError && !isTherapist && !isSwitchWizard;

  const { t: tBookingScreen } = useTranslation('bookingScreen');

  const handleDisablePress = (type: SessionModality) => {
    if (type !== 'messaging') {
      setLiveModalityUnavailableForModal(type);
    } else if (messagingSession?.unavailabilityMessage && !shouldIgnoreActiveMessagingSession) {
      setIsMessagingSessionDisabledModalActive(true);
    }
  };

  const closeLiveSessionsNotCovered = () => {
    setLiveModalityUnavailableForModal(null);
  };

  const closeMessagingSessionDisabledModal = () => {
    setIsMessagingSessionDisabledModalActive(false);
  };

  useEffect(() => {
    if (
      pendingInvoices &&
      pendingInvoices.length > 0 &&
      !isPendingInvoicesLoading &&
      !isSwitchWizard
    ) {
      history.replace(`${match.url}/pending-invoices`);
    }
  }, [history, isPendingInvoicesLoading, match.url, pendingInvoices, isSwitchWizard]);

  useEffect(() => {
    if (shouldShowIneligiblePage) {
      history.replace(`${match.url}/ineligible-member`);
    }
  }, [history, match.url, shouldShowIneligiblePage]);

  useEffect(() => {
    const isAModalityAvailable =
      Object.keys(allowedSessionModalities).find((key) => allowedSessionModalities[key]) ||
      messagingSession?.availabilityStatus === 'available';
    if (modality && isAModalityAvailable && (!isTherapist || modality !== 'messaging')) {
      setIsContinueButtonDisabled(false);
    }
  }, [modality, allowedSessionModalities, messagingSession, isTherapist]);

  useEffect(() => {
    if (creditOptions && creditOptions.length) {
      const allowedModalities = creditOptions[0].allowedSessionModalities;
      if (allowedModalities) {
        setAllowedSessionModalities(allowedModalities);
      }
    }
  }, [creditOptions]);

  useEffect(() => {
    if (roomType === 'psychiatry_room' && checkedPendingInvoices && !isSwitchWizard) {
      history.replace(`${match.url}/select-duration`);
    }
  }, [checkedPendingInvoices, history, match.url, roomType, isSwitchWizard]);

  useEffect(() => {
    dispatchResetShouldShowBookingSuccess();
  }, [dispatchResetShouldShowBookingSuccess]);

  useLayoutEffect(() => {
    if (
      flowVariant === 'bookAndActivate' &&
      shouldSkipModalitySelection &&
      checkedPendingInvoices
    ) {
      history.replace(`${match.url}/select-duration`);
    }
  }, [checkedPendingInvoices, flowVariant, history, match.url, shouldSkipModalitySelection]);

  const labelTextBottomMargin = useMemo(() => {
    const onboardingExtraMargin = isOnboarding ? 8 : 0;
    if (!postLvsCheckInNoBookings) return 24 + onboardingExtraMargin;
    if (isMobile) return 24 + onboardingExtraMargin;
    return 50 + onboardingExtraMargin;
  }, [isMobile, isOnboarding, postLvsCheckInNoBookings]);

  const handleContinuePress = () => {
    trackSelectScheduleModality(therapistInfo?.id, room?.roomID);
    if (modality === 'messaging') {
      history.push(
        isOnboarding
          ? `${match.url}/messaging-session?isOnboarding=true`
          : `${match.url}/messaging-session`
      );
    } else {
      history.push(`${match.url}/select-duration`);
    }
  };

  if (isIneligibleError && isTherapist) {
    return (
      <InRoomSchedulingError
        onClosePress={onClose}
        title="Member's insurance plan has expired"
        message="It appears that this member's insurance plan is not updated, please ask them to update their insurance information."
      />
    );
  }

  if (isError && !shouldShowIneligiblePage) {
    return <InRoomSchedulingError userID={clientUserID} roomID={roomID} onClosePress={onClose} />;
  }

  const getOptionBlocksProps = (
    isDisabled: boolean
  ): Partial<OptionBlockProps<SessionModality>> => {
    return {
      radioButtonContainerStyle: {
        marginLeft: 0,
        marginRight: 28.5,
      },
      iconContainerStyle: {
        marginLeft: 19,
        width: 24,
      },
      textContainerStyle: {
        marginRight: 0,
      },
      style: {
        height: 'auto',
        opacity: 1,
        backgroundColor: isDisabled ? colors.permaGreyed : undefined,
        border: isDisabled
          ? `0.5px solid ${colors.catskillWhite}`
          : `0.5px solid ${colors.periwinkleGrey}`,
        borderRadius: 8,
      },
      titleStyle: isDisabled ? { color: colors.darkGray } : {},
    };
  };

  const labelTextVariant = tBookingScreen(
    'whatType.title',
    'What type of session would you like to have?',
    undefined
  );

  if (
    isFromCheckInWizard &&
    room?.accountType === 'dte' &&
    videoCredits &&
    videoCredits.length === 0
  ) {
    history.replace(`${match.url}/checkin-done`);
  }

  if (isFromCheckInWizard && videoCredits === undefined) {
    <Spinner isLoading primaryColor={colors.chineseSilver} />;
  }

  if (!checkedPendingInvoices) {
    return <Spinner isLoading primaryColor={colors.chineseSilver} />;
  }

  const shouldShowPostLVSCheckInHeading =
    isFromPostLVSCheckInWizard && (clientUsageStats?.completedSessionsWithTherapist || 0) > 3;

  const shouldShowKeepUpTheGreatWorkHeading = isFromCheckInWizard;

  const messagingSessionAvailable =
    messagingSession?.availabilityStatus === 'available' ||
    (messagingSession?.availabilityStatus === 'session_in_progress' &&
      shouldIgnoreActiveMessagingSession);
  const messagingSessionDisabled =
    messagingSession?.availabilityStatus !== 'available' &&
    !(
      messagingSession?.availabilityStatus === 'session_in_progress' &&
      shouldIgnoreActiveMessagingSession
    );

  return (
    <View flex={1} align="center" justify="center">
      {shouldShowKeepUpTheGreatWorkHeading && (
        <View style={{ marginBottom: isMobile ? 24 : 40 }} align="center">
          <KeepUpTheGreatWorkHeading />
        </View>
      )}
      {shouldShowPostLVSCheckInHeading && <PostLVSCheckInHeading />}
      <View align="stretch" style={isMobile ? { paddingLeft: 20, paddingRight: 20 } : undefined}>
        {allowedSessionModalities.video ||
        allowedSessionModalities.audio ||
        allowedSessionModalities.chat ||
        messagingSessionAvailable ? (
          <>
            <View align="center" justify="center" style={{ width: '100%' }}>
              {!shouldShowPostLVSCheckInHeading &&
                !shouldShowKeepUpTheGreatWorkHeading &&
                !shouldHideTitle && (
                  <ExtraHuge style={{ textAlign: 'center', marginBottom: labelTextBottomMargin }}>
                    {labelTextVariant}
                  </ExtraHuge>
                )}
            </View>
            <TabRadioGroup
              style={{ marginBottom: 10 }}
              legendText={labelTextVariant}
              dataQa="selectModalityTabRadioGroup"
            >
              {!isOnboarding && !shouldHideTitle && (
                <ScheduleSessionTitle variant="bigDarkGrey">
                  {tBookingScreen('whatType.schedule', 'Schedule a session', undefined)}
                </ScheduleSessionTitle>
              )}
              <OptionBlock<SessionModality>
                isActive={modality === 'video'}
                displayTitle={tBookingScreen('whatType.liveVideo', 'Live video', undefined)}
                description={
                  <ModalityDescription>
                    {tBookingScreen(
                      'whatType.connect',
                      'Connect face-to-face via video',
                      undefined
                    )}
                  </ModalityDescription>
                }
                icon={
                  shouldUseDsIcons ? (
                    <Video colorType="brand" size="standard" />
                  ) : (
                    <VideoModality
                      color={allowedSessionModalities.video ? colors.purple : colors.baliHaiGrey}
                    />
                  )
                }
                value="video"
                onHandlePress={handleChooseModality}
                disabled={!allowedSessionModalities.video}
                onDisablePress={(type) => handleDisablePress(type as SessionModality)}
                {...getOptionBlocksProps(!allowedSessionModalities.video)}
                dataQa="liveVideoModalityRadioButton"
                questionMarkWhenDisabled
              />
              <OptionBlock<SessionModality>
                isActive={modality === 'audio'}
                displayTitle={tBookingScreen('whatType.liveAudio', 'Live audio', undefined)}
                description={
                  <ModalityDescription>
                    {tBookingScreen('whatType.speak', 'Speak through voice only', undefined)}
                  </ModalityDescription>
                }
                icon={
                  shouldUseDsIcons ? (
                    <Phone colorType="brand" size="standard" />
                  ) : (
                    <AudioModality
                      color={allowedSessionModalities.audio ? colors.purple : colors.baliHaiGrey}
                    />
                  )
                }
                value="audio"
                onHandlePress={handleChooseModality}
                disabled={!allowedSessionModalities.audio}
                onDisablePress={(type) => handleDisablePress(type as SessionModality)}
                {...getOptionBlocksProps(!allowedSessionModalities.audio)}
                dataQa="liveAudioModalityRadioButton"
                questionMarkWhenDisabled
              />
              <OptionBlock<SessionModality>
                isActive={modality === 'chat'}
                displayTitle={tBookingScreen('whatType.liveChat', 'Live chat', undefined)}
                description={
                  !postLvsCheckInNoBookings || availabilitySwitch ? (
                    <ModalityDescription>
                      <Trans>
                        Chat in real time, receive
                        <br /> instant responses
                      </Trans>
                    </ModalityDescription>
                  ) : (
                    tBookingScreen(
                      'whatType.chat',
                      'Chat in real time, receive instant responses',
                      undefined
                    )
                  )
                }
                icon={
                  shouldUseDsIcons ? (
                    <Comment colorType="brand" size="standard" />
                  ) : (
                    <ChatModality
                      color={allowedSessionModalities.chat ? colors.purple : colors.baliHaiGrey}
                    />
                  )
                }
                value="chat"
                onHandlePress={handleChooseModality}
                disabled={!allowedSessionModalities.chat}
                onDisablePress={(type) => handleDisablePress(type as SessionModality)}
                {...getOptionBlocksProps(!allowedSessionModalities.chat)}
                dataQa="liveChatModalityRadioButton"
                questionMarkWhenDisabled
              />
              {!!messagingSession && !isTherapist && shouldHideMessagingSessionTitle && (
                <OptionBlock<SessionModality>
                  isActive={modality === 'messaging'}
                  displayTitle="Messaging session"
                  description={
                    <ModalityDescription>
                      Write at your own pace, receive <br />
                      responses daily, 5 days per week
                    </ModalityDescription>
                  }
                  icon={
                    shouldUseDsIcons ? (
                      <Envelope colorType="brand" size="standard" />
                    ) : (
                      <MessagingModality
                        color={messagingSessionAvailable ? colors.purple : colors.baliHaiGrey}
                      />
                    )
                  }
                  value="messaging"
                  onHandlePress={handleChooseModality}
                  disabled={messagingSessionDisabled}
                  onDisablePress={(type) => handleDisablePress(type as SessionModality)}
                  {...getOptionBlocksProps(messagingSessionDisabled)}
                  dataQa="messagingModalityRadioButton"
                  questionMarkWhenDisabled
                />
              )}
            </TabRadioGroup>
            {!!messagingSession && !isTherapist && !shouldHideMessagingSessionTitle && (
              <View>
                <ScheduleSessionTitle variant="bigDarkGrey">
                  {tBookingScreen('whatType.start', 'Start a session now', undefined)}
                </ScheduleSessionTitle>
                <OptionBlock<SessionModality>
                  isActive={modality === 'messaging'}
                  displayTitle={tBookingScreen(
                    'whatType.messaging',
                    'Messaging session',
                    undefined
                  )}
                  description={
                    <ModalityDescription>
                      {tBookingScreen(
                        'whatType.write',
                        'Write at your own pace, receive responses daily, 5 days per week',
                        undefined
                      )}
                    </ModalityDescription>
                  }
                  icon={
                    <MessagingModality
                      color={messagingSessionAvailable ? colors.purple : colors.baliHaiGrey}
                    />
                  }
                  value="messaging"
                  onHandlePress={handleChooseModality}
                  disabled={messagingSessionDisabled}
                  onDisablePress={(type) => handleDisablePress(type as SessionModality)}
                  {...getOptionBlocksProps(messagingSessionDisabled)}
                  dataQa="messagingModalityRadioButton"
                  questionMarkWhenDisabled
                />
              </View>
            )}
          </>
        ) : (
          <CanNotPurchaseCardWrapper>
            {isLoading ? (
              <Spinner isLoading primaryColor={colors.chineseSilver} />
            ) : (
              <Large variant="largeDarkGrey">
                {tBookingScreen('whatType.no', 'No session credits available', undefined)}
              </Large>
            )}
          </CanNotPurchaseCardWrapper>
        )}
      </View>
      {!shouldHideButtonContainer && (
        <BottomButtonContainer>
          <View>
            <View>
              <Button
                dataQa="inRoomSchedulerSelectModalityContinue"
                style={{ width: 335, backgroundColor: colorRoles.system.actionPrimaryDefault }}
                onPress={handleContinuePress}
                text={tBookingScreen('whatType.continue', 'Continue', undefined)}
                disabled={isContinueButtonDisabled}
              />
            </View>
          </View>
          {isFromCheckInWizard && (
            <SkipButton onPress={onSkipBookNextSession}>
              <Standard variant="standardDarkGrey">
                {tBookingScreen('whatType.skip', 'Skip', undefined)}
              </Standard>
            </SkipButton>
          )}
        </BottomButtonContainer>
      )}

      {!!liveModalityUnavailableForModal && liveModalitiesUnavailabilityMessage && (
        <LiveSessionsNotCoveredModal
          closeModal={closeLiveSessionsNotCovered}
          unavailabilityMessage={
            liveModalitiesUnavailabilityMessage[liveModalityUnavailableForModal]
          }
        />
      )}
      {isMessagingSessionDisabledModalActive && messagingSession?.unavailabilityMessage && (
        <MessagingSessionDisabledModal
          closeModal={closeMessagingSessionDisabledModal}
          title={messagingSession.unavailabilityMessage?.title}
          subtitle={messagingSession.unavailabilityMessage?.subtitle}
        />
      )}
    </View>
  );
};

export default SelectModality;
