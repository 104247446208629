import { useEffect, useCallback, useState } from 'react';

const getSecondDifferences = (callStartedAt: string | null | undefined) => {
  if (callStartedAt) {
    return Math.floor((new Date().getTime() - new Date(callStartedAt).getTime()) / 1000);
  }
  return 0;
};

const useSessionDuration = (callStartedAt: string | null | undefined) => {
  const [secondsElapsed, setSecondsElapsed] = useState<number>(() =>
    getSecondDifferences(callStartedAt)
  );

  useEffect(() => {
    let intervalID: number | undefined;

    if (callStartedAt) {
      intervalID = window.setInterval(() => {
        const secondsDifferences: number = getSecondDifferences(callStartedAt);
        setSecondsElapsed(secondsDifferences);
      }, 1000);
    } else {
      setSecondsElapsed(0);
    }

    return () => {
      if (intervalID) {
        clearInterval(intervalID);
      }
    };
  }, [callStartedAt]);

  const getTime = () => {
    const seconds = (secondsElapsed % 60).toString();
    const secondsString = seconds.length > 1 ? seconds : `0${seconds}`;
    const minutes = (Math.floor(secondsElapsed / 60) % 60).toString();
    const minutesString = minutes.length > 1 ? minutes : `0${minutes}`;
    const hours = Math.floor(secondsElapsed / 3600);
    const hoursString = hours ? `${hours.toString()}:` : '';
    return `${hoursString}${minutesString}:${secondsString}`;
  };

  return { secondsElapsed, getTime: useCallback(getTime, [secondsElapsed]) };
};

export default useSessionDuration;
