import { useMutation, useQueryClient } from 'react-query';
import apiHelper from '@/utils/api';
import apiWrapper from '../../utils/apiWrapper';
import { notesListQueryKey } from './queryKeys';

interface DeleteCaseConsultationNoteMutateVariables {
  roomID: string;
  noteID: string;
  isDraft: boolean;
}

const deleteCaseConsultationNote = async (
  params: DeleteCaseConsultationNoteMutateVariables
): Promise<void> => {
  const { roomID, noteID, isDraft } = params;
  const url = isDraft
    ? `${apiHelper().apiEndpoint}/v2/rooms/${roomID}/case-consultation-notes/${noteID}/draft`
    : `${apiHelper().apiEndpoint}/v2/rooms/${roomID}/case-consultation-notes/${noteID}`;
  await apiWrapper.delete(url);
};

const useMutationDeleteCaseConsultationNote = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, DeleteCaseConsultationNoteMutateVariables>(
    deleteCaseConsultationNote,
    {
      onSuccess: (_, { roomID }) => {
        queryClient.invalidateQueries(notesListQueryKey(roomID));
      },
    }
  );
};

export default useMutationDeleteCaseConsultationNote;
