import { useMutation } from 'react-query';
import { CreditMinutes, CreditType, SessionModality } from 'ts-frontend/types';
import apiWrapper, { ErrorWithPayload } from '@/core/api/apiWrapper';
import apiHelper from '@/utils/api';
import { FunnelName } from '@/utils/analytics/trackerTypes';

export interface CreateBookingActivationMutateVariables {
  roomID: number;
  creditMinutes: CreditMinutes;
  start: string;
  therapistUserID: number;
  type: CreditType;
  isVideoOnly?: boolean;
  planID?: number | null;
  withinAllowRefundHours?: boolean;
  modality?: SessionModality;
  funnelName?: FunnelName;
}

const createBookingActivation = async (
  params: CreateBookingActivationMutateVariables
): Promise<void> => {
  const { roomID, ...restOfData } = params;
  const data = await apiWrapper.post(
    `${apiHelper().apiEndpoint}/v3/rooms/${roomID}/booking-activation`,
    restOfData
  );
  return data.data;
};

const useMutationCreateBookingActivation = () =>
  useMutation<void, ErrorWithPayload, CreateBookingActivationMutateVariables>(
    createBookingActivation
  );

export default useMutationCreateBookingActivation;
