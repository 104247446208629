import { FunctionComponent } from 'react';
import { LiveSessionModality } from 'ts-frontend/types';

import SmallVideoSessionIcon from './SmallVideoSessionIcon';
import SmallChatSessionIcon from './SmallChatSessionIcon';
import SmallAudioSessionIcon from './SmallAudioSessionIcon';
import { EmotionStyle } from '../../core/styled';

interface LiveSessionIconProp {
  width?: number;
  height?: number;
  style?: EmotionStyle;
  modality?: LiveSessionModality;
}

const LiveSessionIcon: FunctionComponent<LiveSessionIconProp> = ({
  width = undefined,
  height = undefined,
  style = {},
  modality = 'video',
}) => {
  switch (modality) {
    case 'video':
      return (
        <SmallVideoSessionIcon aria-hidden="true" width={width} height={height} style={style} />
      );
    case 'chat':
      return (
        <SmallChatSessionIcon aria-hidden="true" width={width} height={height} style={style} />
      );
    case 'audio':
      return (
        <SmallAudioSessionIcon aria-hidden="true" width={width} height={height} style={style} />
      );
    default:
      return (
        <SmallVideoSessionIcon aria-hidden="true" width={width} height={height} style={style} />
      );
  }
};

export default LiveSessionIcon;
