/* eslint-disable camelcase */

import { useQuery } from 'react-query';
import apiWrapper from '../../utils/apiWrapper';
import apiHelper from '../../utils/api';
import dashboardKeys from './queryKeys';

export interface MetricsResponse {
  engagingRooms: string;
  notEngaging: number;
  payingRooms: number;
}

const fetchMetrics = (therapistID: number) => async () => {
  const { data } = await apiWrapper.get(
    `${apiHelper().apiEndpoint}/api/v1/therapist/${therapistID}/metrics`
  );

  return {
    payingRooms: data.paying_rooms,
    engagingRooms: data.engaging_rooms,
    notEngaging: data.not_engaging,
  };
};

const useQueryMetrics = (therapistID: number) =>
  useQuery<MetricsResponse, Error>({
    queryKey: dashboardKeys.metric(therapistID),
    queryFn: fetchMetrics(therapistID),
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
  });

export default useQueryMetrics;
