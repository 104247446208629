import { FunctionComponent } from 'react';
import Tiny from '../Typography/Tiny';
import View from '../View';
import Link from '../Link';
import { ReminderIcon } from '../icons';
import { COLORS } from '../../constants/commonStyles';
import styled from '../../core/styled';

const Container = styled(View)(({ theme: { colors } }) => {
  return {
    background: colors.blueMagneta,
    borderRadius: 5,
    paddingTop: 12,
    paddingBottom: 12,
    paddingRight: 10,
    paddingLeft: 10,
    marginTop: 10,
    marginBottom: 5,
  };
});

const StyledLink = styled(Link)(({ theme: { colors } }) => {
  return {
    color: colors.permaDeepViolet,
    fontWeight: 'bold',
    textDecoration: 'underline',
  };
});

interface ReminderProps {
  reminderText: string;
  linkText?: string;
  isReminderLabel?: boolean;
  handleClickLink?: () => void;
}
const Reminder: FunctionComponent<ReminderProps> = ({
  reminderText,
  linkText,
  isReminderLabel = false,
  handleClickLink,
}: ReminderProps) => (
  <Container row>
    <View style={{ marginRight: 7 }}>
      <ReminderIcon />
    </View>
    <Tiny style={{ color: COLORS.black, fontSize: 14 }}>
      {isReminderLabel && <strong>Reminder: </strong>}
      {reminderText}
      {linkText && <StyledLink onClick={handleClickLink}>{linkText}</StyledLink>}
    </Tiny>
  </Container>
);

export default Reminder;
