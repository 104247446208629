import { useQuery } from 'react-query';

import apiHelper from '@/core/api/apiHelper';
import apiWrapper from '@/core/api/apiWrapper';

import { TherapistAutomaticMessage } from '../../types/account';
import { therapistAutomaticMessagesQueryKey } from './queryKeys';

const fetchAutomaticMessages = (therapistUserID: number) => async () => {
  const { data } = await apiWrapper.get(
    `${apiHelper().apiEndpoint}/v3/therapists/${therapistUserID}/automatic-messages`
  );

  return data.data;
};

export default function useQueryAutomaticMessages(therapistUserID: number, enabled = true) {
  return useQuery<TherapistAutomaticMessage[], Error>({
    queryKey: therapistAutomaticMessagesQueryKey(therapistUserID),
    queryFn: fetchAutomaticMessages(therapistUserID),
    cacheTime: Infinity,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
    retry: false,
    enabled,
  });
}
