import { FunctionComponent } from 'react';
import { Big, ExtraBig, ExtraHuge, Large, Small, View } from '@talkspace/react-toolkit';

const WaitingForOthers: FunctionComponent<{
  startDayString?: string;
  callTimeScheduleString?: string;
  isMinimized?: boolean;
  bottomOffset: number;
}> = ({ startDayString, callTimeScheduleString, isMinimized, bottomOffset }) => {
  const adjustedMarginTop = `calc((100vh - ${bottomOffset}px)/2 - 50px)`;
  return (
    <View
      style={{
        textAlign: 'center',
        marginTop: isMinimized ? 36 : adjustedMarginTop,
        marginBottom: isMinimized ? 5 : 0,
      }}
    >
      {isMinimized && <ExtraBig>Waiting for others...</ExtraBig>}
      {!isMinimized && <ExtraHuge>Waiting for others to join</ExtraHuge>}
      {!isMinimized && (
        <Large variant="largeDarkGrey">
          <Big variant="bigDarkGreen" inline>
            {startDayString}
          </Big>{' '}
          from {callTimeScheduleString}
        </Large>
      )}
      {isMinimized && <Small variant="smallDarkGreen">{startDayString}</Small>}
      {isMinimized && <Small variant="smallDarkGrey">{callTimeScheduleString}</Small>}
    </View>
  );
};
export default WaitingForOthers;
