import { FunctionComponent } from 'react';
import {
  View,
  Modal,
  TouchableView,
  Big,
  Tiny,
  CloseButton,
  DownloadIcon,
  MessageStar,
} from '@talkspace/react-toolkit';
import moment from 'moment';

import styled from '@/core/styled';
import { handlePDFDownload } from '../utils/downloadUtils';
import PDFViewer from '../PDFViewer/PDFViewer';

const Content = styled(TouchableView)(({ theme: { colors } }) => {
  return {
    backgroundColor: colors.athensGrey,
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    WebkitOverflowScrolling: 'touch',
  };
});

const Header = styled(View)(({ theme: { colors } }) => {
  return {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 12,
    paddingLeft: 20,
    backgroundColor: colors.white,
    borderStyle: 'solid',
    borderColor: colors.extraLightGrey,
    borderWidth: 1,
  };
});

const ActionIconsWrapper = styled(View)({
  flexDirection: 'row',
  justifyContent: 'flex-end',
  padding: 20,
});

const ActionIcons = styled(View)({
  flexDirection: 'row',
  width: 59,
  justifyContent: 'space-between',
});

interface Props {
  url: string;
  fileName: string;
  senderName: string;
  createdAt: Date;
  isStarred: boolean;
  onClosePress: () => void;
  onStarPress: () => void;
}

const PDFModal: FunctionComponent<Props> = ({
  url,
  fileName,
  senderName,
  createdAt,
  isStarred,
  onClosePress,
  onStarPress,
}) => {
  const handleDownloadPress = () => {
    handlePDFDownload({ url, fileName });
  };

  return (
    <Modal isVisible>
      <Content>
        <Header>
          <View>
            <Big>{fileName}</Big>
            <Tiny>
              {senderName}, {moment(createdAt).format('MMM DD, hh:mm A')}
            </Tiny>
          </View>
          <CloseButton onPress={onClosePress} style={{ paddingTop: 4 }} />
        </Header>
        <ActionIconsWrapper>
          <ActionIcons>
            <TouchableView onPress={handleDownloadPress}>
              <DownloadIcon />
            </TouchableView>
            <MessageStar isActive={isStarred} onPress={onStarPress} />
          </ActionIcons>
        </ActionIconsWrapper>
        <PDFViewer
          url={url}
          fileName={fileName}
          senderName={senderName}
          createdAt={createdAt}
          isStarred={isStarred}
          onClosePress={onClosePress}
          onDownloadPress={handleDownloadPress}
          onStarPress={onStarPress}
        />
      </Content>
    </Modal>
  );
};

export default PDFModal;
