import { FunctionComponent, useEffect, useState } from 'react';
import {
  OptionType,
  useEmotionTheme,
  View,
  PlusMultiSelect,
  Standard,
  ExtraTiny,
  styled,
} from '@talkspace/react-toolkit';
import { OptionsType, ValueType } from 'react-select';
import { connect } from 'react-redux';
import moment from 'moment';
import * as clinicalInformationActions from 'actions/ClinicalInformationActions';
import useQueryProgressNoteHelperData from 'hooks/notes/useQueryProgressNoteHelperData';
import { useParams } from '@/core/routerLib';
import {
  useProgressNoteFormState,
  useProgressNoteFormActions,
} from '../context/TherapistProgressNoteFormContext';
import { Section } from '../context/types';
import { BooleanDropdown, getSelectStyleV2 } from '../../utils';
import SelectContainer from '../containers/SelectContainer';
import useDidUpdateEffect from '../../../../../../hooks/useDidUpdateEffect';
import useQueryConditions from '../../../../../../hooks/notes/useQueryConditions';

interface Condition {
  id: number;
  isWorkingDiagnosis: boolean;
  label: string;
  value: number;
  isBillable: boolean;
}

const dateInTheLastTwoWeeks = (date?: Date) => {
  if (!date) {
    return false;
  }
  return moment().diff(moment(date), 'day') <= 14;
};

const Label = styled(Standard)<{ isMargin: boolean }>(({ theme: { colors }, isMargin }) => {
  return {
    fontWeight: 400,
    marginRight: 8,
    marginTop: isMargin && '-15px !important',
    fontSize: 14,
  };
});

interface StoreProps {
  clientUserID: number;
  conditions: Array<Condition>;
  getConditions: (clientUserID: number) => void;
  nextSection: Section;
}

const DiagnosisSection: FunctionComponent<StoreProps> = ({
  clientUserID,
  conditions,
  getConditions,
  nextSection,
}) => {
  const { roomID } = useParams<{ roomID: string }>();
  const formState = useProgressNoteFormState();
  const {
    formMode,
    diagnoses,
    formSections,
    currentSection,
    isSaveAsDraftPressed,
    unsubmittedSession,
  } = formState;
  const [isProvisionalDiagnosis, setIsProvisionalDiagnosis] = useState(
    diagnoses.some((it) => it.isProvisionalDiagnosis)
  );
  const { setFormState } = useProgressNoteFormActions();
  const [diagnosisError, setDiagnosisError] = useState('');
  const { colors } = useEmotionTheme();
  const { data: helperData } = useQueryProgressNoteHelperData(roomID);
  const { data: conditionList } = useQueryConditions();

  const hasSelectedBillable = diagnoses.some((diag) => {
    const condition = conditionList?.find((cond) => cond.id === diag.conditionID);
    return condition && condition.isBillable;
  });

  const isEAP = unsubmittedSession?.accountType === 'eap';

  useEffect(() => {
    const userID = formState.clientUserID || clientUserID;
    if (formMode === 'create') {
      getConditions(userID);
    }
  }, [clientUserID, getConditions, formMode, formState.clientUserID]);

  useEffect(() => {
    if (formMode === 'create' && conditions?.length > 0) {
      setFormState({
        diagnoses: [
          ...diagnoses.filter(
            (diag) => !conditions.some((cond) => cond.value === diag.conditionID)
          ),
          ...conditions.map((it) => {
            return {
              conditionID: it.value,
              isProvisionalDiagnosis: false,
              isBillable: it.isBillable,
            };
          }),
        ],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conditions, formMode, setFormState]);

  const markSectionAsIncomplete = () => {
    if (formSections.diagnosis.completed) {
      setFormState({
        formSections: {
          ...formSections,
          diagnosis: {
            open: formSections.diagnosis.open,
            changed: true,
            completed: false,
          },
        },
      });
    }
  };

  const handleDiagnosisChange = (newValues: Array<Condition> | null) => {
    markSectionAsIncomplete();
    setDiagnosisError('');

    const hasBillable = newValues?.some(({ isBillable }) => isBillable);

    if (newValues === null || newValues.length === 0 || !hasBillable) {
      setFormState({ diagnoses: [] });
    } else {
      const selectedDiagnosesIDs = newValues.map((it) => {
        return { value: it.value, isBillable: it.isBillable };
      });
      setFormState({
        diagnoses: selectedDiagnosesIDs.map((it) => {
          return { conditionID: it.value, isProvisionalDiagnosis, isBillable: it.isBillable };
        }),
      });
    }
  };

  const handleProvisionalDiagnosisToggle = (newValue: boolean) => {
    markSectionAsIncomplete();
    setIsProvisionalDiagnosis(newValue);
    setFormState({
      diagnoses: diagnoses.map((it) => {
        return {
          conditionID: it.conditionID,
          isProvisionalDiagnosis: newValue,
          isBillable: it.isBillable,
        };
      }),
    });
  };

  const validate = (): boolean =>
    (!diagnoses || diagnoses.length === 0 || !hasSelectedBillable) && !isEAP;

  const handleConfirmPress = () => {
    const isError = validate();
    const completed = !isError;

    if (isError && !isSaveAsDraftPressed) {
      setDiagnosisError('Required billable diagnoses');
      setFormState({ showGlobalError: true });
      return;
    }

    const newDiagnoses = diagnoses.map((it) => {
      return { conditionID: it.conditionID, isProvisionalDiagnosis: it.isProvisionalDiagnosis };
    });

    setFormState({
      diagnoses: newDiagnoses,
      formSections: {
        ...formSections,
        diagnosis: {
          open: false,
          changed: true,
          completed,
        },
        ...(formSections.riskAssessment.completed
          ? {}
          : {
              riskAssessment: {
                ...formSections.riskAssessment,
                open: true,
              },
            }),
      },
    });
  };

  useDidUpdateEffect(() => {
    if (currentSection === 'diagnosis') {
      setFormState({ currentSection: nextSection });
      handleConfirmPress();
    }
  }, [currentSection]);

  const showProvisionalDiagnosisSelect = dateInTheLastTwoWeeks(
    helperData && new Date(helperData.roomCreationDate)
  );

  const disabledValues = hasSelectedBillable
    ? null
    : conditionList?.filter((it) => !it.isBillable)?.map((it) => it.id.toString());

  const shouldShowBillableText =
    (formMode === 'create' && !hasSelectedBillable && !isEAP && !diagnosisError) ||
    diagnoses.length === 0;

  return (
    <View style={{ marginTop: 11 }}>
      <View align="center" row style={{ marginBottom: 10 }}>
        <Label isMargin={Boolean(diagnosisError) || isEAP || shouldShowBillableText}>
          Diagnosis:
        </Label>
        <View flex={1}>
          <PlusMultiSelect
            key={JSON.stringify({ disabledValues })}
            placeholder="Select"
            options={(conditionList as OptionsType<any>) || []}
            value={
              conditionList?.filter((opt: any) =>
                diagnoses.map((diagnosis) => diagnosis.conditionID).includes(opt.value)
              ) as ValueType<OptionType<any>, boolean>
            }
            onChange={handleDiagnosisChange as (value: ValueType<OptionType<any>, boolean>) => void}
            isMulti
            isDisabled={formMode === 'view'}
            isOptionDisabled={(option) => {
              if (!disabledValues?.length) return false;
              return option.value ? disabledValues.includes(`${option.value}`) : false;
            }}
            isError={diagnosisError}
            width={187}
            dataTestID="diagnosisSelectId"
            menuPlacement="auto"
            dataQa="diagnosisDropdown"
          />
          {diagnosisError && (
            <ExtraTiny data-testid="diagnosisError" variant="extraTinyError">
              {diagnosisError}
            </ExtraTiny>
          )}
          <View style={{ marginBottom: 4 }}>
            {shouldShowBillableText && (
              <ExtraTiny style={{ fontSize: 11 }}>
                Only billable diagnoses can be selected
              </ExtraTiny>
            )}
            {isEAP && <ExtraTiny>Selecting a diagnosis is optional for EAP plans</ExtraTiny>}
          </View>
        </View>
      </View>

      {showProvisionalDiagnosisSelect && (
        <SelectContainer
          value={BooleanDropdown.find((it) => it.value === isProvisionalDiagnosis) as any}
          title="Is this diagnosis provisional?"
          handleChange={((option) => handleProvisionalDiagnosisToggle(option?.value)) as any}
          options={BooleanDropdown as any}
          styles={getSelectStyleV2({ colors })}
          isDisabled={diagnoses.length === 0 || formMode === 'view'}
          tooltipText="Select Yes if more information is needed to make a formal diagnosis."
          dataQa="provisionalDiagnosisDropdown"
        />
      )}
    </View>
  );
};

const mapStateToProps = (state) => {
  return {
    clientUserID: state.room.clientUserID,
    conditions: state.clinicalInformation.conditions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getConditions: (clientUserID: number) =>
      dispatch(clinicalInformationActions.getConditions(clientUserID)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DiagnosisSection);
