import { useTheme } from 'emotion-theming';
import { styled } from '../hooks/EmotionThemeProvider';
import type { CreateStyled, EmotionTheme } from '../hooks/EmotionThemeProvider';

/** Conflicting keys with @emotion's CSSObject interface */

// eslint-disable-next-line @typescript-eslint/ban-types
export type EmotionStyle = object;

export type { EmotionTheme };

export type CustomizableComponentStyleProp<T = unknown> =
  | ((provided: EmotionStyle, props: T) => EmotionStyle)
  | EmotionStyle;

export const getStyles = <T = unknown>(
  defaultStyles: EmotionStyle,
  props: T & { styles?: CustomizableComponentStyleProp<T> }
): EmotionStyle => {
  if (props.styles) {
    if (typeof props.styles === 'function') return props.styles(defaultStyles, props);
    if (typeof props.styles === 'object') return { ...defaultStyles, ...props.styles };
  }
  return defaultStyles as EmotionStyle;
};

export type { CreateStyled };

export function useEmotionTheme<Theme extends EmotionTheme = EmotionTheme>() {
  return useTheme<Theme>();
}

export default styled;
