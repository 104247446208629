import { FunctionComponent } from 'react';
import { useField } from 'formik';
import 'react-phone-number-input/style.css';
import './PhoneInput.css';
import ReactPhoneInput, { parsePhoneNumber, getCountryCallingCode } from 'react-phone-number-input';
import styled, { EmotionStyle } from '../../core/styled';
import Label from '../Label';
import Mini from '../Typography/Mini';
import View from '../View';

const InputWrapper = styled(View)({
  marginTop: 16,
});

const StyledLabel = styled(Label)(({ theme: { colors } }) => {
  return { fontSize: 13, color: colors.greyText };
});

const StyledReactPhoneInput = styled(ReactPhoneInput)(({ theme: { colors } }) => {
  // NOTE: unfortunately have to !important margin and border here
  // since these props are hardcoded with !important in PhoneInput.css
  // and it is risky to remove !important from there.
  return {
    marginTop: '0 !important',
    border: `1px solid ${colors.periwinkleGrey} !important`,
  };
});

const ErrorMessage = styled(Mini)(({ theme: { colors } }) => {
  return {
    color: colors.red,
    paddingLeft: 4,
  };
});

// TODO: replace with A11yPhoneInput
const PhoneInputFormik: FunctionComponent<{
  label?: string;
  fieldName?: string;
  dataQa?: string;
  inputStyle?: EmotionStyle;
  wrapperStyle?: EmotionStyle;
}> = ({ label = 'Phone number', dataQa, inputStyle, wrapperStyle }) => {
  const [field, meta, helpers] = useField('phoneNumber');
  const [countryField, countryMeta, countryHelpers] = useField('phoneNumberCountryCode');
  const isError = Boolean(meta.touched && meta.error && countryMeta.error);
  return (
    <InputWrapper style={wrapperStyle}>
      <StyledLabel>{label}</StyledLabel>
      <StyledReactPhoneInput
        data-qa={dataQa}
        aria-required="true"
        aria-label="phone number"
        id="phone"
        placeholder="Enter phone number"
        value={`+${getCountryCallingCode(countryField.value || 'US')}${field.value}`}
        onChange={(value) => {
          if (typeof value === 'string') {
            const phone = parsePhoneNumber(value);
            helpers.setValue(phone?.nationalNumber);
            countryHelpers.setValue(phone?.country);
          }
        }}
        country={countryField.value}
        defaultCountry="US"
        onCountryChange={(newCountry) => {
          if (newCountry) {
            // set country for formik
            countryHelpers.setValue(newCountry);
          }
        }}
        onBlur={() => helpers.setTouched(true)}
        style={inputStyle}
      />
      {isError && <ErrorMessage>{meta.error || countryMeta.error}</ErrorMessage>}
    </InputWrapper>
  );
};

export default PhoneInputFormik;
