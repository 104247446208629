import { DateRangePicker, DateRangePickerShape } from 'react-dates';
import DatePickersCSSWrapper from '../../designSystems/components/TimeslotsCalendar/DatePickersCSSWrapper';
import useDatePicker, {
  BaseDatePickerProps,
} from '../../designSystems/components/TimeslotsCalendar/useDatePicker';

// Cannot use `interface` here because `DateRangePickerShape` is also a `type`
export type DateRangePickerWithInputProps = Omit<
  DateRangePickerShape,
  'renderMonthText' | 'renderMonthElement'
> &
  BaseDatePickerProps;

const DateRangePickerWithInput = ({
  containerStyle,
  topOffset = 15,
  isCentered = false,
  shouldRemoveArrow,
  inputStyles,
  focusStyle,
  disabledStyle,
  primaryColor,
  secondaryColor,
  inputLabelStyle,
  arrowStyle,
  ...props
}: DateRangePickerWithInputProps) => {
  const {
    state: { containerHeight },
    dateProps,
  } = useDatePicker({ topOffset, ...props, isSingle: false, primaryColor, secondaryColor });

  return (
    <DatePickersCSSWrapper
      containerStyle={containerStyle}
      topOffset={topOffset}
      height={containerHeight}
      isCentered={isCentered}
      shouldRemoveArrow={shouldRemoveArrow}
      inputStyles={inputStyles}
      focusStyle={focusStyle}
      disabledStyle={disabledStyle}
      inputLabelStyle={inputLabelStyle}
      arrowStyle={arrowStyle}
    >
      <DateRangePicker {...dateProps} {...props} />
    </DatePickersCSSWrapper>
  );
};

export default DateRangePickerWithInput;
