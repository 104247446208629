import { ComponentType, useCallback, useMemo, useState, useEffect } from 'react';
import moment from 'moment';
import { useFlags } from 'launchDarkly/FlagsProvider';

import { ERoom } from 'ts-frontend/entities/Room';
import { TherapistTimeslot } from 'ts-frontend/types';
import useQueryBusinessLine from 'ts-frontend/hooks/useQueryBusinessLine';
import {
  useEmotionTheme,
  usePanelManagerActions,
  useWindowWidthState,
} from '@talkspace/react-toolkit';

import useQueryVideoCreditOffers from 'inRoomScheduling/hooks/useQueryVideoCreditOffers';
import {
  useInRoomSchedulingState,
  useInRoomSchedulingActions,
} from '../../inRoomScheduling/hooks/inRoomSchedulingContext';
import { ConfirmBookingOptions, CreateBookingParams } from '../../inRoomScheduling/types';
import { CANCELLATION_WINDOW_HOURS } from '../../inRoomScheduling/utils/constants';
import { useVideoCallState } from '../hooks/videoCallContext';
import RecurringAvailable, {
  RecurringAvailableProps,
} from '../components/VideoCallTrayTherapistScheduleNextRecurring/RecurringAvailable';
import RecurringNotAvailable, {
  RecurringNotAvailableProps,
} from '../components/VideoCallTrayTherapistScheduleNextRecurring/RecurringNotAvailable';
import SchedulerConfirmation, {
  SchedulerConfirmationProps,
} from '../components/VideoCallTrayTherapistScheduleNextRecurring/SchedulerConfirmation';
import { useHistory, useRouteMatch } from '@/core/routerLib';
import { trackEvent, tsAnalyticsTracker } from '@/utils/analytics/eventTracker';
import SessionScheduled, {
  SessionScheduledProps,
} from '../components/VideoCallTrayTherapistScheduleNextRecurring/SessionScheduled';
import useTherapistRecurringAvailability from '../hooks/useTherapistRecurringAvailability';
import BaseView from '../components/VideoCallTrayTherapistScheduleNextRecurring/BaseView';
import roundDateDownToNearestSlot from '../utils/roundDateDownToNearestSlot';

interface Props {
  closeTray: () => void;
  handleMinimize: () => void;
}
enum RecurringAvailabilityEnum {
  IS_AVAILABLE = 'isAvailable',
  IS_NOT_AVAILABLE = 'isNotAvailable',
}

const getSelectedSlotInfo = ({
  availableSlotInOneWeek,
  availableSlotInTwoWeeks,
  selectedSlot,
}: {
  availableSlotInOneWeek?: TherapistTimeslot;
  availableSlotInTwoWeeks?: TherapistTimeslot;
  selectedSlot?: TherapistTimeslot;
}) => {
  let numberSlots: number = 0;
  let daysAhead: number | null = null;
  if (availableSlotInOneWeek && availableSlotInTwoWeeks) {
    numberSlots = 2;
  } else if (availableSlotInOneWeek || availableSlotInTwoWeeks) {
    numberSlots = 1;
  }

  if (selectedSlot?.start === availableSlotInOneWeek?.start) {
    daysAhead = 7;
  } else if (selectedSlot?.start === availableSlotInTwoWeeks?.start) {
    daysAhead = 14;
  }
  return { numberSlots, daysAhead };
};

const VideoCallTrayTherapistScheduleNextRecurringContainer = ({
  closeTray,
  handleMinimize,
}: Props) => {
  const history = useHistory();
  const match = useRouteMatch<{ roomID: string }>();
  const { isMobile } = useWindowWidthState();
  const { colors } = useEmotionTheme();

  const { repeatingSessionsFull: isActiveRecurringSessionsFull } = useFlags();

  const {
    therapistUserID,
    roomID,
    creditMinutes,
    startTime = '',
    videoCall: { clients: [{ userID: clientUserID }] } = { clients: [] },
  } = useVideoCallState();

  const {
    selectedBookingDuration,
    selectedCreditOption,
    selectedTimeslot,
    shouldShowBookingSuccess,
    isError,
    isLoading: isLoadingRoomScheduling,
    hasBreakAfterSession = false,
    progressNoteDetails: { isInCreateMode: isProgressNoteActive },
  } = useInRoomSchedulingState();

  const {
    dispatchSetRoomAndTherapistInfo,
    dispatchCreateBooking,
    dispatchSetHasBreakAfterSession,
    dispatchSetSelectedTimeslot,
    dispatchSetRepeatingPeriod,
    dispatchSetSelectedBookingDuration,
    dispatchSetSelectedCreditOption,
    dispatchSetNavigateToScheduler,
  } = useInRoomSchedulingActions();

  useEffect(() => {
    document.addEventListener('closeVideoTray', closeTray);
    return () => {
      document.addEventListener('closeVideoTray', closeTray);
    };
  }, [closeTray]);

  useEffect(() => {
    if (roomID && therapistUserID) {
      dispatchSetRoomAndTherapistInfo({ roomID, therapistID: therapistUserID } as ERoom, {
        id: therapistUserID,
        firstName: '',
        lastName: '',
      });
    }
  }, [dispatchSetRoomAndTherapistInfo, roomID, therapistUserID]);

  // NOTE: need to round down because of ad hoc calls: they can start at any time
  const callStartMoment = roundDateDownToNearestSlot(moment(startTime));
  const isAdHocCall = !creditMinutes;
  // NOTE: for free sessions callDuration is 0 and we assume 45 minutes
  const callDuration = isAdHocCall ? 45 : creditMinutes;
  const {
    availableSlotInOneWeek,
    availableSlotInTwoWeeks,
    isLoading: isLoadingAvailability,
    isFetched: isAvailabilityFetched,
  } = useTherapistRecurringAvailability({
    callStartMoment,
  });

  const {
    data: videoCreditOffers,
    isLoading: isLoadingVideoCreditOffers,
    isError: isErrorVideoCreditOffers,
  } = useQueryVideoCreditOffers({ roomID });
  const { data: businessLine, isLoading: isLoadingBusinessLine } = useQueryBusinessLine(
    roomID,
    clientUserID
  );

  const isLoading =
    isLoadingRoomScheduling ||
    isLoadingBusinessLine ||
    isLoadingAvailability ||
    isLoadingVideoCreditOffers;

  const isWithin24Hours = useMemo(
    () =>
      moment(selectedTimeslot?.start).isBefore(moment().add(CANCELLATION_WINDOW_HOURS, 'hours')),
    [selectedTimeslot]
  );

  useEffect(() => {
    if (videoCreditOffers && callDuration) {
      dispatchSetSelectedBookingDuration(callDuration);
      const creditOption = videoCreditOffers?.liveSessions.find(
        (credit) => credit.creditMinutes === callDuration
      );
      creditOption && dispatchSetSelectedCreditOption(creditOption);
    }
  }, [
    videoCreditOffers,
    callDuration,
    dispatchSetSelectedBookingDuration,
    dispatchSetSelectedCreditOption,
  ]);

  const handleConfirmSchedule = useCallback(
    ({
      isPurchase = false,
      creditCardToken,
      modality: appointmentModality,
    }: ConfirmBookingOptions) => {
      const { isVideoOnly } = businessLine || {};
      if (
        !selectedTimeslot ||
        !selectedBookingDuration ||
        !selectedCreditOption ||
        isVideoOnly === undefined ||
        !therapistUserID
      ) {
        return;
      }

      const newBookingPayload: CreateBookingParams = {
        type: selectedCreditOption.type,
        start: moment(selectedTimeslot.start).toISOString(),
        creditMinutes: selectedCreditOption.creditMinutes || 30,
        therapistUserID,
        isPurchase,
        isVideoOnly,
        withinAllowRefundHours: isWithin24Hours,
        planID: selectedCreditOption.planID,
        modality: appointmentModality,
        funnelName: 'In-Video Prompt',
        hasBreakAfterSession,
      };
      dispatchCreateBooking(newBookingPayload, {
        creditCardToken,
        planID: selectedCreditOption.planID!,
        couponCode: undefined,
      });
    },
    [
      dispatchCreateBooking,
      hasBreakAfterSession,
      businessLine,
      isWithin24Hours,
      selectedBookingDuration,
      selectedCreditOption,
      selectedTimeslot,
      therapistUserID,
    ]
  );

  const stepType = useMemo(
    () =>
      availableSlotInOneWeek || availableSlotInTwoWeeks
        ? RecurringAvailabilityEnum.IS_AVAILABLE
        : RecurringAvailabilityEnum.IS_NOT_AVAILABLE,
    [availableSlotInOneWeek, availableSlotInTwoWeeks]
  );

  useEffect(() => {
    if (isAvailabilityFetched) {
      const { numberSlots } = getSelectedSlotInfo({
        availableSlotInOneWeek,
        availableSlotInTwoWeeks,
      });
      trackEvent(
        'In-Video Session Prompt',
        {
          actionName: 'inVideoPromptInteraction',
          roomID,
          numberSlots,
        },
        ['tsAnalytics']
      );
    }
  }, [availableSlotInOneWeek, availableSlotInTwoWeeks, roomID, isAvailabilityFetched]);

  const { setIsLeftPanelOnTop } = usePanelManagerActions();

  const focusCRM = useCallback(() => {
    if (isMobile) {
      handleMinimize();
      setIsLeftPanelOnTop(true);
    }
  }, [handleMinimize, isMobile, setIsLeftPanelOnTop]);

  const handleScheduleLater = useCallback(() => {
    const { numberSlots } = getSelectedSlotInfo({
      availableSlotInOneWeek,
      availableSlotInTwoWeeks,
    });
    trackEvent(
      'In-Video Schedule Later',
      {
        actionName: 'inVideoPromptInteraction',
        roomID,
        numberSlots,
      },
      ['tsAnalytics']
    );
    closeTray();
  }, [closeTray, roomID, availableSlotInOneWeek, availableSlotInTwoWeeks]);

  const handleChooseDifferentDate = useCallback(() => {
    const { numberSlots } = getSelectedSlotInfo({
      availableSlotInOneWeek,
      availableSlotInTwoWeeks,
    });
    tsAnalyticsTracker.trackMPEventGeneric('Open Therapist Scheduler', {
      targets: [tsAnalyticsTracker.targetsEnum.MIXPANEL_PROVIDER],
      roomID: match.params.roomID,
      Source: 'In-Video Prompt',
      numberSlots,
    });

    dispatchSetSelectedTimeslot(undefined);
    dispatchSetRepeatingPeriod('every-week');

    if (isProgressNoteActive) {
      dispatchSetNavigateToScheduler(new Date());
    } else {
      closeTray();
      focusCRM();
      history.push(`/room/${match.params.roomID}/in-room-scheduling`);
    }
  }, [
    availableSlotInOneWeek,
    availableSlotInTwoWeeks,
    match.params.roomID,
    dispatchSetSelectedTimeslot,
    dispatchSetRepeatingPeriod,
    isProgressNoteActive,
    dispatchSetNavigateToScheduler,
    closeTray,
    focusCRM,
    history,
  ]);

  const handleChooseSlot = useCallback(
    (slot: TherapistTimeslot) => () => {
      dispatchSetSelectedTimeslot(slot);

      const { daysAhead, numberSlots } = getSelectedSlotInfo({
        availableSlotInOneWeek,
        availableSlotInTwoWeeks,
        selectedSlot: slot,
      });
      trackEvent(
        'In-Video Session Selected',
        {
          actionName: 'inVideoPromptInteraction',
          roomID,
          daysAhead,
          numberSlots,
        },
        ['tsAnalytics']
      );
      if (isActiveRecurringSessionsFull) {
        const repeatingPeriod =
          moment(slot.start).diff(moment(), 'days') > 7 ? 'every-other-week' : 'every-week';
        dispatchSetRepeatingPeriod(repeatingPeriod);
        focusCRM();
        history.push(`/room/${match.params.roomID}/in-room-scheduling`);
        closeTray();
      }
    },
    [
      dispatchSetSelectedTimeslot,
      availableSlotInOneWeek,
      availableSlotInTwoWeeks,
      roomID,
      isActiveRecurringSessionsFull,
      dispatchSetRepeatingPeriod,
      history,
      match.params.roomID,
      closeTray,
      focusCRM,
    ]
  );

  useEffect(() => {
    if (shouldShowBookingSuccess && selectedTimeslot) {
      const { daysAhead, numberSlots } = getSelectedSlotInfo({
        availableSlotInOneWeek,
        availableSlotInTwoWeeks,
        selectedSlot: selectedTimeslot,
      });
      trackEvent(
        'In-Video Session Scheduled',
        {
          actionName: 'inVideoPromptInteraction',
          roomID,
          daysAhead,
          numberSlots,
          hasBreakAfterSession,
        },
        ['tsAnalytics']
      );
    }
  }, [
    shouldShowBookingSuccess,
    selectedTimeslot,
    availableSlotInOneWeek,
    availableSlotInTwoWeeks,
    hasBreakAfterSession,
    roomID,
  ]);

  const stepsData: Record<
    RecurringAvailabilityEnum,
    {
      content: ComponentType<any>;
      props?:
        | SessionScheduledProps
        | SchedulerConfirmationProps
        | RecurringAvailableProps
        | RecurringNotAvailableProps;
      showBackButton?: boolean;
    }[]
  > = useMemo(() => {
    const schedulerConfirmationProps: SchedulerConfirmationProps = {
      modality: 'video',
      selectedTimeslot,
      handleConfirmSchedule,
      shouldShowBookingSuccess,
      hasBreakAfterSession,
      setHasBreakAfterSession: dispatchSetHasBreakAfterSession,
    };
    const recurringAvailableProps: RecurringAvailableProps = {
      callStartMoment,
      handleChooseSlot,
      handleChooseDifferentDate,
      handleScheduleLater,
      availableSlotInOneWeek,
      availableSlotInTwoWeeks,
    };

    const recurringNotAvailableProps: RecurringNotAvailableProps = {
      handleChooseDifferentDate,
      handleScheduleLater,
    };

    const sessionScheduledProps: SessionScheduledProps = {
      modality: 'video',
      selectedTimeslot,
    };

    return {
      [RecurringAvailabilityEnum.IS_NOT_AVAILABLE]: [
        {
          content: RecurringNotAvailable,
          props: recurringNotAvailableProps,
        },
      ],
      [RecurringAvailabilityEnum.IS_AVAILABLE]: [
        {
          content: RecurringAvailable,
          props: recurringAvailableProps,
        },
        {
          content: SchedulerConfirmation,
          props: schedulerConfirmationProps,
          showBackButton: true,
        },
        {
          content: SessionScheduled,
          props: sessionScheduledProps,
        },
      ],
    };
  }, [
    selectedTimeslot,
    handleConfirmSchedule,
    shouldShowBookingSuccess,
    hasBreakAfterSession,
    dispatchSetHasBreakAfterSession,
    callStartMoment,
    handleChooseSlot,
    handleChooseDifferentDate,
    handleScheduleLater,
    availableSlotInOneWeek,
    availableSlotInTwoWeeks,
  ]);

  const [stepIndex, setStepIndex] = useState(0);
  const previousStep = useCallback(() => {
    if (stepIndex > 0) {
      setStepIndex((step) => step - 1);
    }
  }, [stepIndex]);

  const nextStep = useCallback(() => {
    if (stepsData[stepType].length > stepIndex + 1) {
      setStepIndex((step) => step + 1);
    }
  }, [stepIndex, stepType, stepsData]);

  const step = stepsData[stepType][stepIndex];
  if (!step) {
    return null;
  }

  const { content: Component, props: componentProps, showBackButton } = step;

  const staticProps = {
    history,
    match,
    isMobile,
    colors,
    therapistUserID,
    isError: isError || isErrorVideoCreditOffers,
    isLoading,
    previousStep,
    nextStep,
    closeTray,
  };

  return therapistUserID ? (
    <BaseView
      showBackButton={showBackButton && !staticProps.isError}
      onPressBack={previousStep}
      closeTray={closeTray}
      isError={staticProps.isError}
      isLoading={isLoading}
    >
      <Component {...staticProps} {...componentProps} />
    </BaseView>
  ) : null;
};

export default VideoCallTrayTherapistScheduleNextRecurringContainer;
