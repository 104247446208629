import { createElement, VoidFunctionComponent } from 'react';
import {
  ArrowRight,
  EmotionStyle,
  Small,
  TouchableView,
  View,
  useEmotionTheme,
  useWindowWidthState,
} from '@talkspace/react-toolkit';

interface VideoCallPopupProps {
  text: string;
  linkText?: string;
  icon?: VoidFunctionComponent<{
    height?: number;
    width?: number;
    style?: EmotionStyle;
  }>;
  onPress?: () => void;
}

const VideoCallPopup: VoidFunctionComponent<VideoCallPopupProps> = ({
  text,
  linkText,
  icon,
  onPress,
}) => {
  const { colors } = useEmotionTheme();
  const { isMobile, isDesktop } = useWindowWidthState();
  const iconSize = isMobile ? 32 : 36;
  return (
    <TouchableView
      row
      align="center"
      style={{
        position: 'relative',
        margin: `${isDesktop ? '50px auto' : '60px 10px'}`,
        padding: isMobile ? '14px 18px' : '10px 12px',
        borderRadius: 10,
        backgroundColor: colors.permaPrussianBlue,
        zIndex: 1,
        animation: 'fadeInDown 1s',
      }}
      onPress={onPress}
    >
      {icon &&
        createElement(icon, {
          height: iconSize,
          width: iconSize,
          style: { minWidth: iconSize, marginRight: 14 },
        })}
      <View row align="center" style={{ maxWidth: '92%' }}>
        <Small inline style={{ color: colors.white }}>
          {text}{' '}
          {linkText && (
            <>
              <Small inline style={{ color: colors.greenText, marginRight: 4 }}>
                {linkText}
              </Small>
              {linkText && <ArrowRight style={{ paddingTop: 5 }} />}
            </>
          )}
        </Small>
      </View>
    </TouchableView>
  );
};

export default VideoCallPopup;
