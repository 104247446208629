import {
  View,
  Table,
  Large,
  useWindowWidthState,
  useEmotionTheme,
  Standard,
  Link,
  BoxArrowIcon,
} from '@talkspace/react-toolkit';
import { Cell } from '@talkspace/react-toolkit/src/components/Table';
import {
  DashboardCellRoomDetailV2,
  TaskDateTime,
  TaskStatus,
  TaskActionButtons,
} from 'components/Dashboard/Cells';
import { dashboardGetHeaderProps } from 'components/Dashboard/DashboardTable';
import DashboardTablePlaceholder from 'components/Dashboard/Placeholders/DashboardTablePlaceholder';
import IconSubmitNotes from 'components/Dashboard/Placeholders/IconSubmitNotes';
import { TasksResponse } from 'hooks/dashboard/useQueryTaskList';
import { VoidFunctionComponent, useCallback, useMemo } from 'react';
import saveLocalStorageData from 'utils/saveLocalStorageData';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { Link as ReactLink } from 'react-router-dom';
import styled from '../../../core/styled';
import Footer from '../../Footer/Footer';
import { localStorageTableNames, ReviewPress } from '../types';
import storage from '../../../core/storage';
import ssoHelper from '../../../modules/utils/sso';
import { SUPPORT_ARTICLE_LINK } from '../../Room/CRMContainer/NotesTab/utils';

interface TasksTableProps {
  tasks: TasksResponse[];
  isLoading: boolean;
  onReviewPress: ({ therapistID, taskID, type, roomID, bookingID }: ReviewPress) => void;
  onSubmitPress: ({
    progressNoteID,
    roomID,
    taskID,
    therapistID,
    unsubmittedSessionID,
  }: {
    progressNoteID?: number | null;
    roomID?: number;
    taskID?: number | null;
    therapistID?: number | null;
    unsubmittedSessionID?: string;
  }) => void;
}

const TableTitle = styled(Large)(({ theme: { colors } }) => {
  return {
    fontWeight: 400,
    fontSize: 18,
    color: colors.black,
    marginBottom: 10,
  };
});

const TableSubTitle = styled(Standard)(({ theme: { colors } }) => {
  return {
    fontWeight: 400,
    fontSize: 14,
    color: colors.black,
    marginBottom: 24,
  };
});

const StyledLink = styled(Link)(({ theme: { colors } }) => {
  return {
    fontSize: 14,
    fontWeight: 700,
    color: colors.accessibilityGreenDark,
    marginLeft: 5,
    textDecoration: 'underline',
    '&:hover': {
      color: colors.accessibilityGreenDark,
    },
  };
});

const DEFAULT_INITIAL_TABLE_STATE = { sortBy: [{ id: 'eventDate', desc: true }] };

const TasksTable: VoidFunctionComponent<TasksTableProps> = ({
  tasks,
  isLoading,
  onReviewPress,
  onSubmitPress,
}) => {
  const { isMobile, isLarge } = useWindowWidthState();
  const { colors } = useEmotionTheme();
  const { accessUnsubmittedSessions: { dynamicProgressNoteTasks } = {} } = useFlags();
  let tableInitialState: Record<string, unknown>;

  try {
    const stringifyData = storage.getItem(localStorageTableNames.progressNotes);
    tableInitialState = stringifyData ? JSON.parse(stringifyData) : DEFAULT_INITIAL_TABLE_STATE;
  } catch (err) {
    tableInitialState = DEFAULT_INITIAL_TABLE_STATE;
  }

  const handleCellPress = useCallback(
    (roomID: number, component: JSX.Element) => (
      <ReactLink to={`/room/${roomID}/notes-tab`}>{component}</ReactLink>
    ),
    []
  );

  const desktopColumns = useMemo(
    () => [
      {
        Header: 'Date and Time',
        accessor: 'eventDate',
        id: 'eventDate',
        Cell: ({ row: { original: note } }: Cell<TasksResponse>) =>
          handleCellPress(
            note.roomID,
            <TaskDateTime modality={note.task.progressNoteModality} date={note.eventDate} />
          ),
      },
      {
        Header: 'ID',
        accessor: ({ task }) => task.clientNickname,
        id: 'nickName',
        Cell: ({
          row: { original: note },
          isHovering,
        }: Cell<TasksResponse> & { isHovering: boolean }) =>
          handleCellPress(
            note.roomID,
            <DashboardCellRoomDetailV2
              fullName={[note.firstName, note.lastName].join(' ').trim()}
              pseudonym={note.task.clientNickname || ''}
              userID={note.clientUserID}
              roomID={note.roomID}
              isHovering={isHovering}
            />
          ),
      },
      {
        Header: 'Status',
        accessor: 'status',
        id: 'status',
        disableSortBy: true,
        Cell: ({ row: { original: note } }: Cell<TasksResponse>) =>
          handleCellPress(
            note.roomID,
            <TaskStatus
              type={note?.type}
              isEAP={note?.task?.isEAP}
              reopenedAt={note?.task?.reopenedAt}
            />
          ),
      },
      {
        Header: 'Action',
        id: 'actions',
        Cell: ({ row: { original: note } }: Cell<TasksResponse>) => (
          <TaskActionButtons
            note={note}
            onSubmitPress={onSubmitPress}
            onReviewPress={onReviewPress}
          />
        ),
      },
    ],
    [handleCellPress, onSubmitPress, onReviewPress]
  );

  const responsiveColumns = useMemo(
    () => [
      {
        id: 'note',
        accessor: 'eventDate',
        Cell: ({ row: { original: note } }: Cell<TasksResponse>) =>
          handleCellPress(
            note.roomID,
            <View>
              <View row>
                <View style={{ flexBasis: 200 }}>
                  <TaskDateTime modality={note.task.progressNoteModality} date={note.eventDate} />
                  <TaskStatus
                    type={note?.type}
                    isEAP={note?.task?.isEAP}
                    reopenedAt={note?.task?.reopenedAt}
                  />
                </View>
                <View style={{ marginLeft: 15 }}>
                  <DashboardCellRoomDetailV2
                    fullName={[note.firstName, note.lastName].join(' ').trim()}
                    pseudonym={note.task.clientNickname || ''}
                    userID={note.clientUserID}
                    roomID={note.roomID}
                    floatingMenuStyle={{ left: -184 }}
                    isHovering
                  />
                </View>
              </View>
              <View onClick={(e) => e.preventDefault()}>
                <TaskActionButtons
                  note={note}
                  onSubmitPress={onSubmitPress}
                  onReviewPress={onReviewPress}
                />
              </View>
            </View>
          ),
      },
    ],
    [handleCellPress, onSubmitPress, onReviewPress]
  );

  const columns = !isMobile ? desktopColumns : responsiveColumns;

  const totalNotes = useMemo(() => tasks.length, [tasks.length]);

  return (
    <View>
      <TableTitle>Submit progress notes {totalNotes > 0 && <>({totalNotes})</>}</TableTitle>
      {dynamicProgressNoteTasks && (
        <TableSubTitle>
          Submit progress notes in a timely manner to ensure that clinical records stay up-to-date.
          <View row style={{ marginTop: 16 }}>
            <BoxArrowIcon />
            <StyledLink
              style={{ textDecoration: 'none', marginLeft: 5 }}
              target="_blank"
              onClick={() => ssoHelper.openZendesk(SUPPORT_ARTICLE_LINK)}
              text="Documentation FAQs"
            />
          </View>
        </TableSubTitle>
      )}
      <Table
        disableSortRemove
        columns={columns}
        data={tasks}
        isLoading={isLoading}
        hideHeader={isMobile}
        tableStyle={{ margin: 0 }}
        onStateChange={(tableState) =>
          saveLocalStorageData(localStorageTableNames.progressNotes, tableState)
        }
        initialState={tableInitialState}
        Empty={
          <DashboardTablePlaceholder
            icon={<IconSubmitNotes />}
            title="You have no pending progress note submissions."
            subtitle="This is where you'll see all progress notes that are ready for submission."
          />
        }
        getHeaderProps={dashboardGetHeaderProps(colors)}
        getCellProps={(props) => {
          return {
            style: {
              cursor: 'default',
              verticalAlign: 'middle',
              ...(isMobile && {
                verticalAlign: 'top',
                ...(props?.row?.index === 0 && { borderTop: 'none' }),
              }),
              ...(isLarge && { verticalAlign: 'top' }),
            },
          };
        }}
      />
      <Footer hidden={false} />
    </View>
  );
};

export default TasksTable;
