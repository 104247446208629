import { withDSIconMaker } from './utils/IconMaker';

const hugePath =
  'M5.5 28.25H8.125V30H5.5C3.56953 30 2 28.4305 2 26.5V5.5C2 3.56953 3.56953 2 5.5 2H14.0367C14.7313 2 15.3984 2.27891 15.8906 2.77109L22.2289 9.10391C22.7211 9.59609 23 10.2633 23 10.9578V18.625H21.25V12.5H15.125C13.6758 12.5 12.5 11.3242 12.5 9.875V3.75H5.5C4.53203 3.75 3.75 4.53203 3.75 5.5V26.5C3.75 27.468 4.53203 28.25 5.5 28.25ZM21.2227 10.75C21.1844 10.5969 21.1078 10.4547 20.993 10.3453L14.6547 4.00703C14.5398 3.89219 14.4031 3.81562 14.25 3.77734V9.875C14.25 10.3562 14.6438 10.75 15.125 10.75H21.2227ZM11.625 21.25H13.375C15.0648 21.25 16.4375 22.6227 16.4375 24.3125C16.4375 26.0023 15.0648 27.375 13.375 27.375H12.5V29.125C12.5 29.6063 12.1062 30 11.625 30C11.1438 30 10.75 29.6063 10.75 29.125V26.5V22.125C10.75 21.6437 11.1438 21.25 11.625 21.25ZM13.375 25.625C14.1023 25.625 14.6875 25.0398 14.6875 24.3125C14.6875 23.5852 14.1023 23 13.375 23H12.5V25.625H13.375ZM18.625 21.25H20.375C21.8242 21.25 23 22.4258 23 23.875V27.375C23 28.8242 21.8242 30 20.375 30H18.625C18.1437 30 17.75 29.6063 17.75 29.125V22.125C17.75 21.6437 18.1437 21.25 18.625 21.25ZM20.375 28.25C20.8563 28.25 21.25 27.8563 21.25 27.375V23.875C21.25 23.3937 20.8563 23 20.375 23H19.5V28.25H20.375ZM24.75 22.125C24.75 21.6437 25.1437 21.25 25.625 21.25H28.25C28.7313 21.25 29.125 21.6437 29.125 22.125C29.125 22.6063 28.7313 23 28.25 23H26.5V24.75H28.25C28.7313 24.75 29.125 25.1437 29.125 25.625C29.125 26.1063 28.7313 26.5 28.25 26.5H26.5V29.125C26.5 29.6063 26.1063 30 25.625 30C25.1437 30 24.75 29.6063 24.75 29.125V25.625V22.125Z';
const pathConfig = {
  standard: {
    default: {
      path: '',
    },
  },
  huge: {
    default: {
      path: hugePath,
    },
  },
};

const FilePDF = withDSIconMaker(pathConfig, 'File PDF');

export default FilePDF;
