import { useMutation, useQueryClient } from 'react-query';

import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';

import { parseCreateFormValues } from './utils';
import { DoseSpotFormValues } from './types';
import { doseSpotPatientQueryKey } from './queryKeys';

interface CreateDoseSpotPatientParams {
  clientUserID: number;
  formValues: DoseSpotFormValues;
}

const createDoseSpotPatient = async ({
  clientUserID,
  formValues,
}: CreateDoseSpotPatientParams): Promise<void> => {
  await apiWrapper.post(
    `${apiHelper().apiEndpoint}/v2/clients/${clientUserID}/dosespot-patient`,
    parseCreateFormValues(formValues)
  );
};

const useMutationCreateDoseSpotPatient = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, CreateDoseSpotPatientParams>(createDoseSpotPatient, {
    onSuccess: (_, { clientUserID }) => {
      queryClient.invalidateQueries(doseSpotPatientQueryKey(clientUserID));
    },
  });
};

export default useMutationCreateDoseSpotPatient;
