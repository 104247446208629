import * as React from 'react';
import styled from '@/core/styled';

export type ButtonSize = 'small' | 'medium' | 'large';

export interface ButtonProps {
  text: string;
  onPress?(): void;
  disabled?: boolean;
  isSecondary?: boolean;
  stretch?: boolean;
  size?: ButtonSize;
  style?: {};
}

const ButtonComponent = styled.button<Partial<ButtonProps>>(
  ({ disabled, isSecondary, size = 'medium' as ButtonSize, theme: { colors } }) => {
    const stylesBySize: { [k: string]: {} } = {
      small: {
        fontSize: 14,
        fontWeight: '400',
        padding: 4,
        paddingLeft: 14,
        paddingRight: 14,
        display: 'flex',
        color: isSecondary ? colors.green : colors.white,
        borderRadius: 6,
        border: isSecondary ? `1px solid ${colors.ironGrey}` : 'none',
        background: isSecondary ? colors.white : colors.green,
      },
      medium: {
        fontSize: 19,
        fontWeight: '500',
        width: 320,
        minHeight: 55,
        borderRadius: 10,
      },
      large: {
        fontSize: 19,
        fontWeight: '500',
        width: 320,
        minHeight: 55,
        borderRadius: 10,
      },
    };
    return {
      backgroundColor: disabled ? colors.extraLightGrey : colors.green,
      fontFamily: 'Roboto, sans-serif',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: colors.white,
      letterSpacing: 0.74,
      textAlign: 'center',
      '&:hover': {
        cursor: 'pointer',
      },
      ...(stylesBySize[size] || stylesBySize.medium),
    };
  }
);

const Button: React.FunctionComponent<ButtonProps> = ({
  onPress,
  text,
  disabled = false,
  ...otherProps
}) =>
  (
    <ButtonComponent onClick={disabled ? () => null : onPress} disabled={disabled} {...otherProps}>
      {text}
    </ButtonComponent>
  ) || null;

export default Button;
