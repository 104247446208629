import Svg, { Path } from 'svgs';
import { COLORS } from '../../../utils/design';

const CollapseAllIcon = ({ width = 13, height = 27, ...otherProps }) => (
  <Svg width={width} height={height} viewBox="0 0 13 27" fill="none" {...otherProps}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.733 20.96a1.098 1.098 0 000-1.552L6.816 14.49l-4.918 4.918a1.098 1.098 0 101.553 1.553l3.365-3.365 3.365 3.365a1.098 1.098 0 001.552 0zM11.733 6.04a1.098 1.098 0 010 1.552L6.816 12.51 1.898 7.592A1.098 1.098 0 113.451 6.04l3.365 3.365 3.365-3.365a1.098 1.098 0 011.552 0z"
      fill={COLORS.darkGreen}
    />
  </Svg>
);

export default CollapseAllIcon;
