import { FunctionComponent } from 'react';

import Svg, { Path, G } from 'svgs';
import { useEmotionTheme } from '../../core/styled';
import { TSLogoCollapsedProps } from './types';

const TSLogoCollapsed: FunctionComponent<TSLogoCollapsedProps> = ({
  height = 28,
  width = 48,
  color,
  ...otherProps
}) => {
  const titleText = 'Talkspace';
  const { colors } = useEmotionTheme();
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 48 28"
      title={titleText}
      aria-label={titleText}
      {...otherProps}
    >
      <G fill={color || colors.green} fillRule="nonzero" stroke="none" strokeWidth={1}>
        <Path
          d="M25.063 20.01v-1.33l-.076-.078-.874-.886-3.628-3.679 3.686-3.93.82-.875.072-.077V7.873l.001-4.277 19.121-.002.001 20.719H25.063V20.01zm-3.725 5.099c0 1.546 1.278 2.8 2.855 2.8h20.863c1.577 0 2.855-1.253 2.855-2.8V2.8C47.911 1.254 46.632 0 45.056 0H24.193c-1.577 0-2.855 1.253-2.855 2.8v4.969L15.4 14.097l5.936 6.02.002 4.992zM7.293 12.054H12.023V8.498H7.33V2.133h-4.23v6.365H0v3.556h3.1v6.813c0 3.769 2.157 5.857 5.905 5.758 1.17 0 2.214-.221 3.382-.712l.362-.152-.132-.364-.98-2.703-.157-.432-.42.2c-.641.302-1.323.481-1.875.481-1.288 0-1.892-.666-1.892-2.076v-6.813z"
          transform="translate(-20 -20) translate(20 20)"
        />
      </G>
    </Svg>
  );
};

export default TSLogoCollapsed;
