import { CaseLoadInputs } from './types';

export const THERAPIST_CASELOAD_FILTERS = 'therapistCaseLoadFilters';
export const PSYCHIATRIST_CASELOAD_FILTERS = 'psychiatristCaseLoadFilters';

export const TIME_RANGE = [
  { value: 'today', label: 'Today' },
  { value: 'inOver3Days', label: 'In over 3 days' },
  { value: 'inOver5Days', label: 'In over 5 days' },
  { value: 'inOverAWeek', label: 'In over a week' },
  { value: 'inOver2Weeks', label: 'In over 2 weeks' },
  { value: 'inOverAMonth', label: 'In over a month' },
  { value: 'inOver2Months', label: 'In over 2 months' },
  { value: 'inOver3Months', label: 'In over 3 months' },
];

export const STATUS_OF_PROGRESS = [
  { value: 'inProgress', label: 'In Progress' },
  { value: 'notInProgress', label: 'Not in progress' },
];

export const USER_TYPE = [
  { value: 'client', label: 'Client' },
  { value: 'provider', label: 'Provider' },
];

export const SEVERITY_LEVEL = [
  { value: 'high', label: 'High' },
  { value: 'medium', label: 'Medium' },
  { value: 'low', label: 'Low' },
];

export const IDEATION_SEVERITY = [
  { value: 'null', label: 'No' },
  { value: 'ideation', label: 'Ideation' },
  { value: 'plan', label: 'Plan' },
  { value: 'intent', label: 'Intent' },
  { value: 'attempt', label: 'Attempt' },
];

export const ROOM_STATUSES = [
  { value: 'active', label: 'Paying' },
  { value: 'inactive', label: 'Not paying' },
];

export const PLAN_MODALITIES = [
  { value: 'messagingOnly', label: 'Messaging only' },
  { value: 'liveOnly', label: 'Live only' },
  { value: 'messagingAndLive', label: 'Messaging & live' },
];

export const ACCOUNT_TYPES = [
  { value: 'eap', label: 'EAP' },
  { value: 'bh', label: 'BH' },
  { value: 'dte', label: 'DTE' },
  { value: 'b2c', label: 'Self-pay' },
];

export const PRODUCT_LINES = [
  { value: 'individual', label: 'Individual' },
  { value: 'couples', label: 'Couples' },
  { value: 'teen', label: 'Teens' },
  { value: 'maintenance', label: 'Maintenance' },
];

export const THERAPIST_MESSAGING_TYPES: CaseLoadInputs[] = [
  { label: 'My unread and waiting messages', key: 'hasUnreadMessagesAndWaiting' },
  {
    label: 'Clients I haven’t messaged:',
    key: 'clientWaiting',
    dropDown: {
      default: 'today',
      options: TIME_RANGE,
      dropDownPropertyKey: 'clientWaitingDropDownList',
    },
  },
  {
    label: 'Clients I haven’t heard from:',
    key: 'lastActivity',
    dropDown: {
      default: 'inOverAWeek',
      options: TIME_RANGE,
      dropDownPropertyKey: 'lastActivityDropDownList',
    },
  },
  {
    label: 'EAP/BH messaging session:',
    key: 'activeAsyncSession',
    dropDown: {
      default: 'notInProgress',
      options: STATUS_OF_PROGRESS,
      dropDownPropertyKey: 'activeAsyncSessionDropDownList',
    },
  },
];

export const THERAPIST_LIVE_SESSIONS_TYPES: CaseLoadInputs[] = [
  { label: 'Unused live sessions', key: 'unusedLiveSessions' },
  { label: 'No live sessions scheduled', key: 'noLiveSessionsSchedule' },
  {
    label: 'Awaiting confirmation from:',
    key: 'awaitingConfirmation',
    dropDown: {
      default: 'client',
      options: USER_TYPE,
      dropDownPropertyKey: 'awaitingConfirmationDropDownList',
    },
  },
];

export const THERAPIST_CLINICAL_TYPES: CaseLoadInputs[] = [
  {
    label: 'Acuity:',
    key: 'acuity',
    dropDown: {
      default: 'high',
      options: SEVERITY_LEVEL,
      dropDownPropertyKey: 'acuityDropDownList',
    },
  },
  {
    label: 'Active suicidal ideation:',
    key: 'activeSuicidalIdeation',
    dropDown: {
      default: 'ideation',
      options: IDEATION_SEVERITY,
      dropDownPropertyKey: 'activeSuicidalIdeationDropDownList',
    },
  },
  {
    label: 'Active homicidal ideation:',
    key: 'activeHomicidalIdeation',
    dropDown: {
      default: 'ideation',
      options: IDEATION_SEVERITY,
      dropDownPropertyKey: 'activeHomicidalIdeationDropDownList',
    },
  },
];

export const THERAPIST_ROOM_DETAILS: CaseLoadInputs[] = [
  {
    label: 'Room status:',
    key: 'roomActiveStatus',
    isChecked: true,
    dropDown: {
      default: 'active',
      options: ROOM_STATUSES,
      dropDownPropertyKey: 'roomActiveStatusDropDownList',
    },
  },
  {
    label: 'Modality:',
    key: 'planModality',
    dropDown: {
      default: 'messagingOnly',
      options: PLAN_MODALITIES,
      dropDownPropertyKey: 'planModalityDropDownList',
    },
  },
  {
    label: 'Plan type:',
    key: 'accountType',
    dropDown: {
      default: 'eap',
      options: ACCOUNT_TYPES,
      dropDownPropertyKey: 'accountTypeDropDownList',
    },
  },
  {
    label: 'Room type:',
    key: 'productLine',
    dropDown: {
      default: 'individual',
      options: PRODUCT_LINES,
      dropDownPropertyKey: 'productLineDropDownList',
    },
  },
];

export const PSYCHIATRIST_MESSAGING_TYPES: CaseLoadInputs[] = [
  { label: 'My unread and waiting', key: 'hasUnreadMessagesAndWaiting' },
  {
    label: 'Clients I haven’t messaged:',
    key: 'clientWaiting',
    dropDown: {
      default: 'inOverAMonth',
      options: TIME_RANGE,
      dropDownPropertyKey: 'clientWaitingDropDownList',
    },
  },
  {
    label: 'Clients I haven’t heard from:',
    key: 'lastActivity',
    dropDown: {
      default: 'inOver3months',
      options: TIME_RANGE,
      dropDownPropertyKey: 'lastActivityDropDownList',
    },
  },
];

export const PSYCHIATRIST_LIVE_SESSIONS_TYPES: CaseLoadInputs[] = [
  { label: 'Unused live sessions', key: 'unusedLiveSessions' },
  { label: 'No live sessions scheduled', key: 'noLiveSessionsSchedule' },
  {
    label: 'Awaiting confirmation from:',
    key: 'awaitingConfirmation',
    dropDown: {
      default: 'client',
      options: USER_TYPE,
      dropDownPropertyKey: 'awaitingConfirmationDropDownList',
    },
  },
];

export const PSYCHIATRIST_CLINICAL_TYPES: CaseLoadInputs[] = [
  {
    label: 'Acuity:',
    key: 'acuity',
    dropDown: {
      default: 'high',
      options: SEVERITY_LEVEL,
      dropDownPropertyKey: 'acuityDropDownList',
    },
  },
  {
    label: 'Active suicidal ideation:',
    key: 'activeSuicidalIdeation',
    dropDown: {
      default: 'ideation',
      options: IDEATION_SEVERITY,
      dropDownPropertyKey: 'activeSuicidalIdeationDropDownList',
    },
  },
  {
    label: 'Active homicidal ideation:',
    key: 'activeHomicidalIdeation',
    dropDown: {
      default: 'ideation',
      options: IDEATION_SEVERITY,
      dropDownPropertyKey: 'activeHomicidalIdeationDropDownList',
    },
  },
];

export const PSYCHIATRIST_ROOM_DETAILS: CaseLoadInputs[] = [
  {
    label: 'Plan type:',
    key: 'accountType',
    dropDown: {
      default: 'eap',
      options: ACCOUNT_TYPES,
      dropDownPropertyKey: 'accountTypeDropDownList',
    },
  },
];

export const DROPDOWN_POSTFIX = 'DropDownList';

export const LINKS = {
  MESSAGING: {
    text: 'What is an EAP/BH Messaging Session?',
    link: '/hc/en-us/articles/8017213960347',
  },
  CLINICAL: {
    text: 'Where is this information coming from?',
    link: '/hc/en-us/articles/360050009751',
  },
  ROOM_DETAILS: {
    text: 'How do I work with the different plan types?',
    link: 'https://providers-help.talkspace.com/hc/en-us/sections/360009535711',
  },
};

export const therapistTypes = [
  THERAPIST_MESSAGING_TYPES,
  THERAPIST_LIVE_SESSIONS_TYPES,
  THERAPIST_CLINICAL_TYPES,
  THERAPIST_ROOM_DETAILS,
];

export const psychiatryTypes = [
  PSYCHIATRIST_MESSAGING_TYPES,
  PSYCHIATRIST_LIVE_SESSIONS_TYPES,
  PSYCHIATRIST_CLINICAL_TYPES,
  PSYCHIATRIST_ROOM_DETAILS,
];
