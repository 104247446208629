import { useEffect } from 'react';

import { Big, ExclamationCircle, useEmotionTheme, View } from '@talkspace/react-toolkit';

interface Props {
  closeTray: () => void;
}

const ErrorScreen = ({ closeTray }: Props) => {
  const { colors } = useEmotionTheme();
  useEffect(() => {
    const timeout = setTimeout(() => {
      closeTray();
    }, 5000);
    return () => clearTimeout(timeout);
  }, [closeTray]);
  return (
    <View align="center">
      <ExclamationCircle height={48} width={48} />
      <Big style={{ color: colors.white, marginTop: 24, textAlign: 'center' }}>
        Something went wrong. Please try again after the session has ended.
      </Big>
    </View>
  );
};

export default ErrorScreen;
