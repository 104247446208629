/* eslint-disable camelcase */

import { useQuery } from 'react-query';
import { Counters, NewRoomsRecord, RoomRecord } from 'components/Dashboard/types';

import getRoomsQueryString, { RoomQueryString } from 'utils/getRoomsQueryString';
import apiWrapper from '../../utils/apiWrapper';
import apiHelper from '../../utils/api';
import dashboardKeys from './queryKeys';

export interface RoomsResponse {
  counts: Counters;
  rooms?: RoomRecord[];
  newRooms?: NewRoomsRecord[];
}

const fiveMinutesInMilliseconds: number = 1000 * 60 * 5;

const fetchRooms = (params: RoomQueryString) => async () => {
  const { data } = await apiWrapper.get(
    `${apiHelper().apiEndpoint}/v4/rooms?${getRoomsQueryString(params)}`
  );
  return data.data;
};

const useQueryRooms = (params: RoomQueryString) =>
  useQuery<RoomsResponse, Error>({
    queryKey: dashboardKeys.roomsFilter(params),
    queryFn: fetchRooms(params),
    cacheTime: Infinity,
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: fiveMinutesInMilliseconds,
    refetchInterval: fiveMinutesInMilliseconds,
  });

export default useQueryRooms;
