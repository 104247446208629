import storage from '../core/storage';

const saveLocalStorageData = (name: string, data: Record<string, unknown>) => {
  const localStorage = storage.getItem(name);

  const settings = {
    ...(localStorage && JSON.parse(localStorage)),
    ...data,
  };

  storage.setItem(name, JSON.stringify(settings));
};

export default saveLocalStorageData;
