import { useMutation, useQueryClient } from 'react-query';
import apiHelper from '@/utils/api';
import apiWrapper from '../../utils/apiWrapper';
import { CollateralNoteMutateVariables } from './useMutationSaveDraftCollateralNote';
import { collateralNoteQueryKey, notesListQueryKey } from './queryKeys';

const updateCollateralNote = async (params: CollateralNoteMutateVariables): Promise<void> => {
  const { roomID, noteID, clientUserID, ...requestBody } = params;
  await apiWrapper.patch(
    `${apiHelper().apiEndpoint}/v2/rooms/${roomID}/collateral-notes/${noteID}`,
    requestBody
  );
};

const useMutationUpdateCollateralNote = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, CollateralNoteMutateVariables>(updateCollateralNote, {
    onSuccess: (_, { roomID, noteID, clientUserID }) => {
      queryClient.invalidateQueries(notesListQueryKey(roomID));
      if (noteID) {
        queryClient.invalidateQueries(collateralNoteQueryKey(clientUserID, noteID));
      }
    },
  });
};

export default useMutationUpdateCollateralNote;
