import { Component } from 'react';
import { Row, Col, Panel, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { toast } from 'react-toastify';
import '../FormHeader/FormHeader.css';
import 'react-toastify/dist/ReactToastify.min.css';
import { getUserData } from '../../../utils/token';
import apiWrapper from '../../../utils/apiWrapper';
import apiHelper from '../../../utils/api';
import XIcon from '../XIcon';

const TherapistItemToken = (props) => (
  <div className="therapist-item-token">
    {props.option.text}
    <span className="therapist-token-close-button" onClick={props.onRemove}>
      <XIcon />
    </span>
  </div>
);

const TherapistIssueItemToken = (props) => {
  const handleRemove = () => {
    if (props.beforeRemove(props.option)) {
      return props.onRemove();
    }
    return undefined;
  };

  const isDepressionOrAnxiety = [25, 38].includes(props.option.value);
  const styleProp = { style: { backgroundColor: '#d8d8d8' } };

  return (
    <div className="therapist-item-token" {...(isDepressionOrAnxiety && styleProp)}>
      {props.option.text}
      <span className="therapist-token-close-button" onClick={handleRemove}>
        <XIcon />
      </span>
    </div>
  );
};

class TherapistClinicalFocus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIssuesItems: [],
      selectedMentalHealthItems: [],
      selectedTopSpecialtiesItems: [],
      isTopSpecialtiesValid: true,
      addedItems: [],
      addedTopSpecialtiesValues: [],
      deletedItems: [],
      deletedTopSpecialtiesValues: [],
      data: {
        issues: [],
        mentalHealth: [],
        topSpecialties: [],
      },
      isEdit: false,
      hideOverThreeWarning: true,
      numSelectedTopSpecialtiesItems: 0,
      isLoaded: false,
    };
    this.getClinicalExpertiseInfo = this.getClinicalExpertiseInfo.bind(this);
    this.toggleFormEdit = this.toggleFormEdit.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.saveForm = this.saveForm.bind(this);
    this.filterSelectedItems = this.filterSelectedItems.bind(this);
    this.handleIssuesChange = this.handleIssuesChange.bind(this);
    this.handleMentalHealthChange = this.handleMentalHealthChange.bind(this);
    this.handleTopSpecialtiesItemChange = this.handleTopSpecialtiesItemChange.bind(this);
    this.initializeItemValues = this.initializeItemValues.bind(this);
    this.findIssuesItemByValue = this.findIssuesItemByValue.bind(this);
    this.beforeRemove = this.beforeRemove.bind(this);
    this.renderTherapistIssueItemToken = this.renderTherapistIssueItemToken.bind(this);
    this.renderTherapistItemToken = this.renderTherapistItemToken.bind(this);
    this.removeIssueItemFromSelected = this.removeIssueItemFromSelected.bind(this);
    this.addIssueItemToSelected = this.addIssueItemToSelected.bind(this);
    this.wasIssueOriginallySelected = this.wasIssueOriginallySelected.bind(this);
    this.wasTopSpecialtyOriginallySelected = this.wasTopSpecialtyOriginallySelected.bind(this);
    this.removeTopSpecialtiesItemFromSelected =
      this.removeTopSpecialtiesItemFromSelected.bind(this);
    this.addTopSpecialtiesItemToSelected = this.addTopSpecialtiesItemToSelected.bind(this);
    this.initializeIssuesItemValues = this.initializeIssuesItemValues.bind(this);
    this.addClinicalFocusItems = this.addClinicalFocusItems.bind(this);
    this.deleteClinicalFocusItems = this.deleteClinicalFocusItems.bind(this);
    this.updateOriginalData = this.updateOriginalData.bind(this);
    this.getUpdatedItems = this.getUpdatedItems.bind(this);
    this.areSaveResponsesValid = this.areSaveResponsesValid.bind(this);
  }

  componentDidMount() {
    this.getClinicalExpertiseInfo();
  }

  getClinicalExpertiseInfo() {
    const userId = getUserData().id;
    return apiWrapper
      .get(`${apiHelper().apiEndpoint}/api/v1/therapist/${userId}/clinical-expertise-info`)
      .then((response) => {
        const { data } = response.data;
        Object.entries(data).forEach(
          ([key, value]) => (data[key] = value.sort((a, b) => (a.text > b.text ? 1 : -1)))
        );
        const selectedTopSpecialtiesItems = this.filterSelectedItems(data.topSpecialties);
        const selectedIssuesItems = this.filterSelectedItems(data.issues);
        const selectedMentalHealthItems = this.filterSelectedItems(data.mentalHealth);
        const selectedTopSpecialtiesValues = this.initializeItemValues(data.topSpecialties);
        const selectedIssuesValues = this.initializeItemValues(data.issues);
        const selectedMentalHealthValues = this.initializeItemValues(data.mentalHealth);
        const numSelectedTopSpecialtiesItems = selectedTopSpecialtiesItems.length;
        this.setState({
          data,
          selectedTopSpecialtiesItems,
          selectedIssuesItems,
          selectedMentalHealthItems,
          selectedTopSpecialtiesValues,
          selectedIssuesValues,
          selectedMentalHealthValues,
          numSelectedTopSpecialtiesItems,
          isLoaded: true,
        });
        this.props.setIsSectionLoaded({ clinicalFocus: true });
      })
      .catch((err) => {
        this.setState({ error: err });
      });
  }

  getUpdatedItems(items, isTopSpecialties) {
    const updatedItems = items.map((item) => {
      let { selected } = item;
      if (isTopSpecialties) {
        if (this.state.addedTopSpecialtiesValues.indexOf(item.value) > -1) selected = true;
        if (this.state.deletedTopSpecialtiesValues.indexOf(item.value) > -1) selected = false;
      } else {
        if (this.state.addedItems.indexOf(item.value) > -1) selected = true;
        if (this.state.deletedItems.indexOf(item.value) > -1) selected = false;
      }

      const updatedItem = {
        text: item.text,
        value: item.value,
        selected,
      };

      return updatedItem;
    });

    return updatedItems;
  }

  addClinicalFocusItems(userId) {
    const { addedItems } = this.state;
    const uniqueAddedItems = addedItems.filter((value, pos) => addedItems.indexOf(value) == pos);
    const { addedTopSpecialtiesValues } = this.state;

    if (addedItems.length > 0 || addedTopSpecialtiesValues.length > 0) {
      return apiWrapper.post(
        `${apiHelper().apiEndpoint}/api/v1/therapist/${userId}/add-fields-of-expertise`,
        {
          items: uniqueAddedItems,
          topSpecialitiesItems: addedTopSpecialtiesValues,
        }
      );
    }
    return undefined;
  }

  addIssueItemToSelected(issueValue) {
    if (this.findSelectedIssuesItemByValue(issueValue)) return;
    const { selectedIssuesItems } = this.state;
    const { issuesTypeahead } = this.refs;
    const { issues } = this.state.data;
    const issueArray = [];
    issues.forEach((issueItem, id) => {
      if (issueValue === issueItem.value) {
        issueArray.push(issueItem);
      }
    });
    if (issueArray.length > 0) selectedIssuesItems.push(issueArray[0]);

    this.setState({ selectedIssuesItems }, () => {
      issuesTypeahead.getInstance().setState({ selected: selectedIssuesItems });
    });
  }

  addTopSpecialtiesItemToSelected(issueValue) {
    if (this.findSelectedTopSpecialtyItemByValue(issueValue)) return;
    const { selectedTopSpecialtiesItems } = this.state;
    const { topSpecialtiesTypeahead } = this.refs;
    const { topSpecialties } = this.state.data;
    const issueArray = [];
    topSpecialties.forEach((issueItem, id) => {
      if (issueValue === issueItem.value) {
        issueArray.push(issueItem);
      }
    });
    if (issueArray.length > 0) selectedTopSpecialtiesItems.push(issueArray[0]);

    this.setState({ selectedTopSpecialtiesItems }, () => {
      topSpecialtiesTypeahead.getInstance().setState({ selected: selectedTopSpecialtiesItems });
    });
  }

  areSaveResponsesValid(addResponse, deleteResponse) {
    const isAddResponseValid =
      addResponse === undefined || (addResponse.data && addResponse.data.success);
    const isDeleteResponseValid =
      deleteResponse === undefined || (deleteResponse.data && deleteResponse.data.success);
    const responsesAreValid = isAddResponseValid && isDeleteResponseValid;

    return responsesAreValid;
  }

  beforeRemove(item) {
    const isDepressionOrAnxiety = [25, 38].includes(item.value);
    return (
      this.state.selectedTopSpecialtiesValues.indexOf(item.value) < 0 && !isDepressionOrAnxiety
    );
  }

  cancelEdit() {
    const selectedTopSpecialtiesItems = this.filterSelectedItems(this.state.data.topSpecialties);
    const selectedIssuesItems = this.filterSelectedItems(this.state.data.issues);
    const selectedMentalHealthItems = this.filterSelectedItems(this.state.data.mentalHealth);
    const selectedTopSpecialtiesValues = this.initializeItemValues(this.state.data.topSpecialties);
    const selectedIssuesValues = this.initializeItemValues(this.state.data.issues);
    const selectedMentalHealthValues = this.initializeItemValues(this.state.data.mentalHealth);
    const numSelectedTopSpecialtiesItems = selectedTopSpecialtiesItems.length;
    this.refs.topSpecialtiesTypeahead
      .getInstance()
      .setState({ selected: this.state.selectedTopSpecialtiesItems });
    this.refs.issuesTypeahead.getInstance().setState({ selected: this.state.selectedIssuesItems });
    this.refs.mentalHealthTypeahead
      .getInstance()
      .setState({ selected: this.state.selectedMentalHealthItems });
    const editBool = !this.state.isEdit;
    const actionItems = document.querySelectorAll([
      '#clinical-focus .btn-ts-default',
      '#clinical-focus .ts-edit-button',
    ]);
    [].forEach.call(actionItems, (item) => {
      item.classList.toggle('hidden');
    });
    this.setState({
      addedItems: [],
      addedTopSpecialtiesValues: [],
      deletedItems: [],
      deletedTopSpecialtiesValues: [],
      isEdit: editBool,
      selectedTopSpecialtiesItems,
      selectedIssuesItems,
      selectedMentalHealthItems,
      selectedTopSpecialtiesValues,
      selectedIssuesValues,
      selectedMentalHealthValues,
      numSelectedTopSpecialtiesItems,
      hideOverThreeWarning: true,
    });
  }

  deleteClinicalFocusItems(userId) {
    const { deletedItems } = this.state;
    const { deletedTopSpecialtiesValues } = this.state;

    if (deletedItems.length > 0 || deletedTopSpecialtiesValues.length > 0) {
      return apiWrapper.post(
        `${apiHelper().apiEndpoint}/api/v1/therapist/${userId}/delete-fields-of-expertise`,
        {
          items: deletedItems,
          topSpecialitiesItems: deletedTopSpecialtiesValues,
        }
      );
    }
    return undefined;
  }

  filterSelectedItems(items) {
    return items.filter((item) => item.selected);
  }

  findIssuesItemByValue(value) {
    let selectedItem;
    this.state.data.issues.forEach((issue) => {
      if (issue.value === value) {
        selectedItem = issue;
      }
    });
    return selectedItem;
  }

  findSelectedIssuesItemByValue(value) {
    let selectedIssuesItem;
    this.state.selectedIssuesItems.forEach((issue) => {
      if (issue.value === value) {
        selectedIssuesItem = issue;
      }
    });
    return selectedIssuesItem;
  }

  findSelectedTopSpecialtyItemByValue(value) {
    let selectedTopSpecialtiesItems;
    this.state.selectedTopSpecialtiesItems.forEach((issue) => {
      if (issue.value === value) {
        selectedTopSpecialtiesItems = issue;
      }
    });
    return selectedTopSpecialtiesItems;
  }

  handleIssuesChange(event) {
    const { addedItems } = this.state;
    const { deletedItems } = this.state;
    const { selectedIssuesValues } = this.state;
    const eventItemValues = this.initializeIssuesItemValues(event);

    if (event.length > selectedIssuesValues.length) {
      event.forEach((item) => {
        if (deletedItems.indexOf(item.value) > -1) {
          deletedItems.splice(deletedItems.indexOf(item.value), 1);
          selectedIssuesValues.push(item.value);
          this.addIssueItemToSelected(item.value);
        } else if (
          this.state.selectedIssuesValues.indexOf(item.value) < 0 &&
          addedItems.indexOf(item.value) < 0
        ) {
          addedItems.push(item.value);
          selectedIssuesValues.push(item.value);
          this.addIssueItemToSelected(item.value);
        }
      });
    } else {
      selectedIssuesValues.forEach((value) => {
        if (
          eventItemValues.indexOf(value) < 0 &&
          addedItems.indexOf(value) > -1 &&
          !this.wasIssueOriginallySelected(value)
        ) {
          addedItems.splice(addedItems.indexOf(value), 1);
        }

        if (
          eventItemValues.indexOf(value) < 0 &&
          deletedItems.indexOf(value) < 0 &&
          this.wasIssueOriginallySelected(value)
        ) {
          deletedItems.push(value);
        }

        if (eventItemValues.indexOf(value) < 0) {
          this.removeIssueItemFromSelected(value);
        }
      });
    }

    this.setState({ addedItems, deletedItems, selectedIssuesValues: eventItemValues });
  }

  handleMentalHealthChange(event) {
    const { addedItems } = this.state;
    const { deletedItems } = this.state;
    const { selectedMentalHealthValues } = this.state;
    const eventItemValues = this.initializeItemValues(event);

    if (event.length > selectedMentalHealthValues.length) {
      event.forEach((item) => {
        if (deletedItems.indexOf(item.value) > -1) {
          deletedItems.splice(deletedItems.indexOf(item.value), 1);
          selectedMentalHealthValues.push(item.value);
        } else if (
          this.state.selectedMentalHealthValues.indexOf(item.value) < 0 &&
          addedItems.indexOf(item.value) < 0
        ) {
          addedItems.push(item.value);
          selectedMentalHealthValues.push(item.value);
        }
      });
    } else {
      selectedMentalHealthValues.forEach((value) => {
        if (addedItems.indexOf(value) > -1) {
          addedItems.splice(addedItems.indexOf(value), 1);
          selectedMentalHealthValues.splice(selectedMentalHealthValues.indexOf(value), 1);
        } else if (eventItemValues.indexOf(value) < 0 && deletedItems.indexOf(value) < 0) {
          deletedItems.push(value);
          selectedMentalHealthValues.splice(selectedMentalHealthValues.indexOf(value), 1);
        }
      });
    }

    this.setState({ addedItems, deletedItems, selectedMentalHealthValues });
  }

  handleTopSpecialtiesItemChange(event) {
    const { addedTopSpecialtiesValues } = this.state;
    const { deletedTopSpecialtiesValues } = this.state;
    const { selectedTopSpecialtiesValues } = this.state;
    const { selectedTopSpecialtiesItems } = this.state;
    const { selectedIssuesValues } = this.state;
    const eventItemValues = this.initializeIssuesItemValues(event);
    let numSelectedTopSpecialtiesItems;

    if (event.length > 3) {
      const addedItemsArray = [];
      selectedTopSpecialtiesValues.forEach((issueItem) => {
        addedItemsArray.push(this.findIssuesItemByValue(issueItem));
      });
      this.refs.topSpecialtiesTypeahead.getInstance().setState({ selected: addedItemsArray });
      this.setState({ hideOverThreeWarning: false });
      return;
    }

    if (event.length > selectedTopSpecialtiesValues.length) {
      event.forEach((item) => {
        const isTopSpecialtyDeleted = deletedTopSpecialtiesValues.indexOf(item.value) > -1;
        const isTopSpecialtySelected = selectedTopSpecialtiesValues.indexOf(item.value) > -1;
        const isTopSpecialtyAdded = addedTopSpecialtiesValues.indexOf(item.value) > -1;
        const isTopSpecialtyInIssues = selectedIssuesValues.indexOf(item.value) > -1;
        const { addedItems } = this.state;
        const { deletedItems } = this.state;
        const { selectedIssuesItems } = this.state;
        const isItemDeleted = deletedItems.indexOf(item.value) > -1;
        const isItemAdded = addedItems.indexOf(item.value) > -1;

        // handle issues
        if (!isTopSpecialtyInIssues) {
          selectedIssuesValues.push(item.value);
          this.addIssueItemToSelected(item.value);
        }

        if (isItemDeleted) {
          deletedItems.splice(deletedItems.indexOf(item.value), 1);
        }

        if (!isItemAdded && !this.wasIssueOriginallySelected(item.value)) {
          addedItems.push(item.value);
        }

        // handle top specialties
        if (isTopSpecialtyDeleted) {
          deletedTopSpecialtiesValues.splice(deletedTopSpecialtiesValues.indexOf(item.value), 1);
          selectedTopSpecialtiesValues.push(item.value);
          numSelectedTopSpecialtiesItems = event.length;
        }

        if (!isTopSpecialtyAdded && !this.wasTopSpecialtyOriginallySelected(item.value)) {
          addedTopSpecialtiesValues.push(item.value);
          selectedTopSpecialtiesValues.push(item.value);
          numSelectedTopSpecialtiesItems = event.length;
        }

        if (!isTopSpecialtySelected) {
          this.addTopSpecialtiesItemToSelected(item.value);
        }
      });
      this.setState({
        selectedIssuesValues,
        addedTopSpecialtiesValues,
        deletedTopSpecialtiesValues,
        selectedTopSpecialtiesValues,
        numSelectedTopSpecialtiesItems,
        hideOverThreeWarning: true,
      });
    } else {
      selectedTopSpecialtiesValues.forEach((value) => {
        const isTopSpecialtyDeleted = deletedTopSpecialtiesValues.indexOf(value) > -1;
        const isTopSpecialtyAdded = addedTopSpecialtiesValues.indexOf(value) > -1;

        if (
          eventItemValues.indexOf(value) < 0 &&
          isTopSpecialtyAdded &&
          !this.wasTopSpecialtyOriginallySelected(value)
        ) {
          addedTopSpecialtiesValues.splice(addedTopSpecialtiesValues.indexOf(value), 1);
        }

        if (
          eventItemValues.indexOf(value) < 0 &&
          !isTopSpecialtyDeleted &&
          this.wasTopSpecialtyOriginallySelected(value)
        ) {
          deletedTopSpecialtiesValues.push(value);
        }

        if (eventItemValues.indexOf(value) < 0) {
          this.removeTopSpecialtiesItemFromSelected(value);
        }
      });
    }

    this.setState({
      selectedIssuesValues,
      addedTopSpecialtiesValues,
      deletedTopSpecialtiesValues,
      selectedTopSpecialtiesValues: eventItemValues,
      numSelectedTopSpecialtiesItems,
      hideOverThreeWarning: true,
    });
  }

  initializeIssuesItemValues(items) {
    return items.reduce((allItems, item) => {
      if (allItems.indexOf(item.value) < 0) {
        allItems.push(item.value);
      }
      return allItems;
    }, []);
  }

  initializeItemValues(items) {
    return items.reduce((allItems, item) => {
      if (allItems.indexOf(item.value) < 0 && item.selected) {
        allItems.push(item.value);
      }
      return allItems;
    }, []);
  }

  removeIssueItemFromSelected(issueValue) {
    const { selectedIssuesItems } = this.state;
    const { issuesTypeahead } = this.refs;
    const issueIndex = [];
    selectedIssuesItems.forEach((issueItem, id) => {
      if (issueValue === issueItem.value) {
        issueIndex.push(id);
      }
    });
    if (issueIndex.length > 0) selectedIssuesItems.splice(issueIndex[0], 1);
    this.setState({ selectedIssuesItems }, () => {
      issuesTypeahead.getInstance().setState({ selected: selectedIssuesItems });
    });
  }

  removeTopSpecialtiesItemFromSelected(issueValue) {
    const { selectedTopSpecialtiesItems } = this.state;
    const { topSpecialtiesTypeahead } = this.refs;
    const issueIndex = [];
    selectedTopSpecialtiesItems.forEach((issueItem, id) => {
      if (issueValue === issueItem.value) {
        issueIndex.push(id);
      }
    });
    if (issueIndex.length > 0) selectedTopSpecialtiesItems.splice(issueIndex[0], 1);
    this.setState({ selectedTopSpecialtiesItems }, () => {
      topSpecialtiesTypeahead.getInstance().setState({ selected: selectedTopSpecialtiesItems });
    });
  }

  saveForm() {
    this.toggleFormEdit();
    const userId = getUserData().id;
    Promise.all([this.addClinicalFocusItems(userId), this.deleteClinicalFocusItems(userId)])
      .then(([addResponse, deleteResponse]) => {
        if (this.areSaveResponsesValid(addResponse, deleteResponse)) {
          this.updateOriginalData(() => {
            this.setState({
              addedItems: [],
              addedTopSpecialtiesValues: [],
              deletedItems: [],
              deletedTopSpecialtiesValues: [],
            });
          });
          toast(<div className="toaster toaster-success">Changes are saved successfully!</div>, {
            autoClose: 3000,
          });
        } else {
          toast(
            <div
              className="toaster toaster-error"
              style={{ background: '#e8006b', color: '#FFF', padding: '12px', fontSize: '16px' }}
            >
              Something went wrong
            </div>,
            { autoClose: 3000 }
          );
        }
      })
      .catch((error) => {
        toast(
          <div
            className="toaster toaster-error"
            style={{ background: '#e8006b', color: '#FFF', padding: '12px', fontSize: '16px' }}
          >
            Something went wrong
          </div>,
          { autoClose: 3000 }
        );
      });
  }

  toggleFormEdit() {
    const editBool = !this.state.isEdit;
    this.setState({ isEdit: editBool, hideOverThreeWarning: true });
    const actionItems = document.querySelectorAll([
      '#clinical-focus .btn-ts-default',
      '#clinical-focus .ts-edit-button',
    ]);
    [].forEach.call(actionItems, (item) => {
      item.classList.toggle('hidden');
    });
  }

  updateOriginalData(resetItemsArraysInState) {
    const { data } = this.state;
    const newTopSpecialties = this.getUpdatedItems(data.topSpecialties, true);
    const newIssues = this.getUpdatedItems(data.issues, false);
    const newMentalHealth = this.getUpdatedItems(data.mentalHealth, false);

    this.setState(
      {
        data: {
          issues: newIssues,
          mentalHealth: newMentalHealth,
          topSpecialties: newTopSpecialties,
        },
      },
      resetItemsArraysInState
    );
  }

  wasIssueOriginallySelected(value) {
    const { issues } = this.state.data;
    let wasOriginallySelected = false;
    issues.forEach((item) => {
      if (item.value === value) {
        wasOriginallySelected = item.selected;
      }
    });
    return wasOriginallySelected;
  }

  wasTopSpecialtyOriginallySelected(value) {
    const { topSpecialties } = this.state.data;
    let wasOriginallySelected = false;
    topSpecialties.forEach((item) => {
      if (item.value === value) {
        wasOriginallySelected = item.selected;
      }
    });
    return wasOriginallySelected;
  }

  renderTherapistIssueItemToken(selectedItem, onRemove) {
    return (
      <TherapistIssueItemToken
        key={selectedItem.value}
        disabled={!this.state.isEdit}
        onRemove={onRemove}
        option={selectedItem}
        beforeRemove={this.beforeRemove}
      />
    );
  }

  renderTherapistItemToken(selectedItem, onRemove) {
    return (
      <TherapistItemToken
        key={selectedItem.value}
        disabled={!this.state.isEdit}
        onRemove={onRemove}
        option={selectedItem}
      />
    );
  }

  render() {
    const title = 'Clinical Focus';
    const disabledClassName = this.state.isEdit ? '' : 'typeahead-disabled';
    const isSaveDisabled =
      this.state.numSelectedTopSpecialtiesItems !== 3 ||
      (this.state.addedItems.length === 0 &&
        this.state.deletedItems.length === 0 &&
        this.state.addedTopSpecialtiesValues.length === 0 &&
        this.state.deletedTopSpecialtiesValues.length === 0);
    const tooltip = (
      <Tooltip id="ClinicalFocusTooltip" className="tooltip ts-tooltip ts-profile-text-white">
        Your clinical expertise helps better match you with relevant clients. Make sure you pick
        between 3 and 5 Issues.
      </Tooltip>
    );

    return (
      <div>
        <Col
          xs={12}
          id="clinical-focus"
          className={`ts-panel-title ${this.state.isLoaded ? 'show-panel' : 'hidden-panel'}`}
        >
          <Col sm={5} xs={5} className="ts-font-black">
            {title}
            <OverlayTrigger placement="top" trigger={['click', 'focus', 'hover']} overlay={tooltip}>
              <i className="fa fa-fw fa-question-circle" />
            </OverlayTrigger>
          </Col>
          <Button className="ts-edit-button pull-right" onClick={this.toggleFormEdit}>
            {' '}
            Edit{' '}
          </Button>
          <Button
            className="btn-ts-default btn-ts-green ts-profile-text-white pull-right hidden save-btn"
            onClick={this.saveForm}
            disabled={isSaveDisabled}
          >
            {' '}
            Save Changes{' '}
          </Button>
          <Button
            className="btn-ts-default ts-profile-btn-text-green cancel-btn pull-right hidden"
            onClick={this.cancelEdit}
          >
            {' '}
            Cancel{' '}
          </Button>
        </Col>
        <Col xs={12}>
          <Panel
            className={`ts-my-account-panel ${
              this.state.isLoaded ? 'show-panel' : 'hidden-panel'
            } ${disabledClassName}`}
          >
            <Row style={{ marginBottom: '10px' }}>
              <Col className="ts-profile-label" md={2}>
                Top Specialties
              </Col>
              <Col md={10} className="typeahead-field">
                <Typeahead
                  ref="topSpecialtiesTypeahead"
                  selected={this.state.selectedTopSpecialtiesItems}
                  labelKey={(option) => `${option.text}`}
                  options={this.state.data.topSpecialties}
                  multiple
                  renderToken={this.renderTherapistItemToken}
                  onChange={this.handleTopSpecialtiesItemChange}
                  disabled={!this.state.isEdit}
                  placeholder="Select issue"
                />
                <p
                  className="ts-text-invalid ts-text-margin"
                  hidden={this.state.numSelectedTopSpecialtiesItems === 3}
                >
                  {' '}
                  You must select 3 top specialties.{' '}
                </p>
                <p
                  className="ts-text-invalid ts-text-margin"
                  hidden={this.state.hideOverThreeWarning}
                >
                  {' '}
                  You cannot select more than 3 top specialties.{' '}
                </p>
              </Col>
            </Row>
            <Row style={{ marginBottom: '10px' }}>
              <Col className="ts-profile-label" md={2}>
                Issues
              </Col>
              <Col md={10} className="typeahead-field">
                <Typeahead
                  ref="issuesTypeahead"
                  selected={this.state.selectedIssuesItems}
                  labelKey={(option) => `${option.text}`}
                  options={this.state.data.issues}
                  multiple
                  renderToken={this.renderTherapistIssueItemToken}
                  onChange={this.handleIssuesChange}
                  disabled={!this.state.isEdit}
                  placeholder="Select issue"
                />
                <p
                  className="ts-text-invalid ts-text-margin"
                  hidden={this.state.selectedIssuesItems.length >= 3}
                >
                  {' '}
                  You must select minimum 3 issues.{' '}
                </p>
              </Col>
            </Row>
            <Row style={{ marginBottom: '10px' }}>
              <Col className="ts-profile-label" md={2}>
                Mental Health
              </Col>
              <Col md={10} className="typeahead-field">
                <Typeahead
                  ref="mentalHealthTypeahead"
                  selected={this.state.selectedMentalHealthItems}
                  labelKey={(option) => `${option.text}`}
                  options={this.state.data.mentalHealth}
                  multiple
                  onChange={this.handleMentalHealthChange}
                  renderToken={this.renderTherapistItemToken}
                  disabled={!this.state.isEdit}
                  placeholder="Select issue"
                />
              </Col>
            </Row>
          </Panel>
        </Col>
      </div>
    );
  }
}

export default TherapistClinicalFocus;
