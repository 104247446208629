import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const standardPath = '';
const majorPath =
  'M20.125 12C20.125 12.4973 19.9275 12.9742 19.5758 13.3258C19.2242 13.6775 18.7473 13.875 18.25 13.875C17.7527 13.875 17.2758 13.6775 16.9242 13.3258C16.5725 12.9742 16.375 12.4973 16.375 12C16.375 11.5027 16.5725 11.0258 16.9242 10.6742C17.2758 10.3225 17.7527 10.125 18.25 10.125C18.7473 10.125 19.2242 10.3225 19.5758 10.6742C19.9275 11.0258 20.125 11.5027 20.125 12ZM13.875 12C13.875 12.2462 13.8265 12.49 13.7323 12.7175C13.638 12.945 13.4999 13.1517 13.3258 13.3258C13.1517 13.4999 12.945 13.638 12.7175 13.7323C12.49 13.8265 12.2462 13.875 12 13.875C11.7538 13.875 11.51 13.8265 11.2825 13.7323C11.055 13.638 10.8483 13.4999 10.6742 13.3258C10.5001 13.1517 10.362 12.945 10.2677 12.7175C10.1735 12.49 10.125 12.2462 10.125 12C10.125 11.7538 10.1735 11.51 10.2677 11.2825C10.362 11.055 10.5001 10.8483 10.6742 10.6742C10.8483 10.5001 11.055 10.362 11.2825 10.2677C11.51 10.1735 11.7538 10.125 12 10.125C12.2462 10.125 12.49 10.1735 12.7175 10.2677C12.945 10.362 13.1517 10.5001 13.3258 10.6742C13.4999 10.8483 13.638 11.055 13.7323 11.2825C13.8265 11.51 13.875 11.7538 13.875 12ZM5.75 13.875C5.25272 13.875 4.77581 13.6775 4.42417 13.3258C4.07254 12.9742 3.875 12.4973 3.875 12C3.875 11.5027 4.07254 11.0258 4.42417 10.6742C4.77581 10.3225 5.25272 10.125 5.75 10.125C6.24728 10.125 6.72419 10.3225 7.07583 10.6742C7.42746 11.0258 7.625 11.5027 7.625 12C7.625 12.4973 7.42746 12.9742 7.07583 13.3258C6.72419 13.6775 6.24728 13.875 5.75 13.875Z';

const pathConfig: IconConfig = {
  standard: {
    default: { path: standardPath },
  },
  major: {
    default: { path: majorPath },
  },
};

const EllipsisHorizontal = withDSIconMaker(pathConfig, 'three dots horizontal dropdown');

export default EllipsisHorizontal;
