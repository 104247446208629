import {
  Standard,
  SelectRounded,
  View,
  useEmotionTheme,
  styled,
  EmotionTheme,
} from '@talkspace/react-toolkit';

import { SelectViewProps } from '@talkspace/react-toolkit/src/components/Select/SelectView';
import TooltipV2 from '@talkspace/react-toolkit/src/components/TooltipV2';
import { ReactNode } from 'react';

const Title = styled(Standard)(({ theme: { colors } }) => {
  return {
    width: 'max-content',
    marginRight: 8,
    fontWeight: 400,
    fontSize: 14,
  };
});

const Container = styled(View)(() => {
  return {
    marginBottom: 11,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  };
});

const getWrapperStyle = (colors: EmotionTheme['colors'], isDisabled?: boolean, flex?: boolean) => {
  return {
    marginTop: 0,
    minWidth: 70,
    width: 'fit-content',
    background: isDisabled ? colors.permaGreyed : colors.permaPhantomWhite,
    flex: flex && 1,
  };
};

const SelectContainer = ({
  handleChange,
  isDisabled,
  styles,
  title,
  value,
  options,
  components,
  children,
  flex = false,
  dataTestID,
  tooltipText = undefined,
  tooltipComponent = undefined,
  dataQa,
}: SelectViewProps & { tooltipText?: string; tooltipComponent?: ReactNode }) => {
  const { colors } = useEmotionTheme();
  const wrapperStyle = getWrapperStyle(colors, isDisabled, flex);
  return (
    <Container>
      <View row align="center">
        <Title>{title}</Title>
        {tooltipText && <TooltipV2 containerStyle={{ left: -210 }} toolTipText={tooltipText} />}
        {tooltipComponent && tooltipComponent}
      </View>
      <SelectRounded
        dataTestID={dataTestID}
        value={value}
        onChange={handleChange}
        options={options}
        isDisabled={isDisabled}
        wrapperStyle={wrapperStyle}
        styles={styles}
        components={{ ...components }}
        shouldDisplayLock={false}
        menuPlacement="auto"
        dataQa={dataQa}
      />

      {children}
    </Container>
  );
};

export default SelectContainer;
