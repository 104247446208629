import { useEmotionTheme } from '@talkspace/react-toolkit';
import { VoidFunctionComponent } from 'react';
import Svg, { Path } from 'svgs';

const HourglassIcon: VoidFunctionComponent = ({ ...otherProps }) => {
  const { colors } = useEmotionTheme();

  return (
    <Svg
      width="63"
      height="67"
      viewBox="0 0 63 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <Path
        d="M37.2966 33.9341L36.1312 33.3848L37.2966 32.8356C42.1161 30.5644 45.1965 25.6802 45.1965 20.31L45.1965 14.0135C45.1965 12.9334 44.3209 12.0579 43.2409 12.0579C42.1608 12.0579 41.2852 12.9334 41.2852 14.0135L41.2852 20.31C41.2852 24.1459 39.0849 27.6346 35.6425 29.2568L32.1216 30.9161C31.8526 31.0429 31.5541 31.082 31.2668 31.0335L31.2668 26.5758L23.9502 26.5758C23.8926 26.505 23.836 26.4334 23.7805 26.3611C22.476 24.6623 21.7289 22.5451 21.7289 20.31L21.7289 14.0135C21.7289 12.9334 20.8534 12.0579 19.7733 12.0579C18.6932 12.0579 17.8177 12.9334 17.8177 14.0135L17.8177 20.31C17.8177 24.8358 20.0056 29.0165 23.5784 31.5787L23.5784 31.6213L23.6381 31.6213C24.2876 32.0818 24.9823 32.4891 25.7175 32.8356L26.883 33.3848L25.7175 33.9341C20.8981 36.2052 17.8177 41.0894 17.8177 46.4597L17.8177 52.7549C17.8177 53.835 18.6932 54.7106 19.7733 54.7106C20.8534 54.7106 21.7289 53.835 21.7289 52.7549L21.7289 46.4597C21.7289 42.6238 23.9292 39.1351 27.3717 37.5128L30.8333 35.8815C31.26 35.6804 31.7542 35.6804 32.1808 35.8815L35.6425 37.5128C39.0849 39.1351 41.2852 42.6238 41.2852 46.4597L41.2852 52.7549C41.2852 53.835 42.1608 54.7106 43.2409 54.7106C44.3209 54.7106 45.1965 53.835 45.1965 52.7549L45.1965 46.4597C45.1965 41.0894 42.1161 36.2052 37.2966 33.9341Z"
        fill={colors.permaPeriwinkleGray}
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.6887 54.3156C14.6887 53.2251 15.5728 52.341 16.6633 52.341L46.3508 52.341C47.4414 52.341 48.3255 53.2251 48.3255 54.3156C48.3255 55.4062 47.4414 56.2903 46.3508 56.2903L16.6633 56.2903C15.5728 56.2903 14.6887 55.4062 14.6887 54.3156Z"
        fill={colors.permaPeriwinkleGray}
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.6887 12.4528C14.6887 11.3622 15.5728 10.4782 16.6633 10.4782L46.3508 10.4782C47.4414 10.4782 48.3255 11.3622 48.3255 12.4528C48.3255 13.5434 47.4414 14.4275 46.3508 14.4275L16.6633 14.4275C15.5728 14.4275 14.6887 13.5434 14.6887 12.4528Z"
        fill={colors.permaPeriwinkleGray}
      />
      <Path
        d="M30.3664 20.7519C31.4734 18.8824 33.8749 18.2769 35.7304 19.3995C37.5858 20.5221 38.1814 23.1358 37.0744 25.0053C36.4778 26.0128 35.9522 26.6234 35.439 27.244C34.9342 27.8543 34.24 28.5484 33.3255 29.3747L31.359 31.0548L29.0541 30.0015C27.9128 29.4595 27.0578 29.0047 26.4297 28.5904C25.7911 28.1692 25.1116 27.7642 24.2195 26.9607C22.6053 25.5066 22.4041 22.8175 23.8402 21.1923C25.2764 19.5672 27.7491 19.4285 29.3634 20.8826C29.5843 21.0817 29.7852 21.2575 29.9634 21.4087C30.0864 21.2164 30.2213 20.997 30.3664 20.7519Z"
        fill={colors.permaBlueStoneNew}
      />
      <Path
        d="M30.9382 44.0715C33.0281 43.4774 35.1869 44.6913 35.76 46.7828C36.3332 48.8744 35.0138 51.1774 32.9239 51.7715C31.7977 52.0916 30.9482 52.1756 30.1488 52.2724C29.3626 52.3677 28.3812 52.3933 27.1491 52.3632L24.5538 52.2999L23.6193 49.8777C23.1645 48.6989 22.8574 47.7805 22.6869 47.0476C22.5136 46.3024 22.2713 45.4563 22.1774 44.2593C22.0074 42.0934 23.7407 40.089 25.9011 39.8987C28.0615 39.7084 29.9506 41.31 30.1206 43.4759C30.1439 43.7724 30.1686 44.0382 30.1938 44.2706C30.4153 44.2157 30.6642 44.1494 30.9382 44.0715Z"
        fill={colors.ceruleanBlue}
      />
    </Svg>
  );
};

export default HourglassIcon;
