import Svg, { Path, Rect } from 'svgs';

interface Props {
  width?: string | number;
  height?: string | number;
  color?: string;
}

const BonusBreakdownMessagingSessionsIcon = ({
  width = 20,
  height = 20,
  color = '#68768D',
}: Props) => (
  <Svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <Rect
      x="1.75"
      y="3.75"
      width="16.5"
      height="12.7105"
      rx="2.322"
      stroke={color}
      stroke-width="1.5"
    />
    <Path
      d="M4.40454 6.79688L10.111 11.9421L15.5937 6.79688"
      stroke={color}
      stroke-width="1.6"
      stroke-linecap="round"
    />
  </Svg>
);

export default BonusBreakdownMessagingSessionsIcon;
