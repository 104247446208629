export const createCookie = (name: string, value: string, expires?: number) => {
  const cookieDomain = window.location.host.includes('localhost') ? 'localhost' : '.talkspace.com';
  let expiresStr = '';
  if (expires) {
    const date = new Date();
    date.setTime(date.getTime() + expires * 1000);
    expiresStr = `; expires=${date.toUTCString()}`;
  }

  document.cookie = `${name}=${value}${expiresStr}; domain=${cookieDomain}; path=/`;
};

export const readCookie = (name: string): string => {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0, len = ca.length; i < len; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return decodeURIComponent(c.substring(nameEQ.length, c.length));
  }
  return '';
};
