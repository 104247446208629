import { useQuery } from 'react-query';

import apiHelper from '@/core/api/apiHelper';
import apiWrapper from '@/core/api/apiWrapper';

import { TherapistHomeAddress } from '../../types/account';
import { therapistHomeAddressQueryKey } from './queryKeys';

const fetchTherapistHomeAddress = async (
  therapistUserID: number
): Promise<TherapistHomeAddress> => {
  const {
    data: { data },
  } = await apiWrapper.get(
    `${apiHelper().apiEndpoint}/v3/therapists/${therapistUserID}/home-address`
  );

  return data;
};

export default function useQueryTherapistHomeAddress(therapistUserID: number) {
  return useQuery<TherapistHomeAddress, Error>({
    queryKey: therapistHomeAddressQueryKey(therapistUserID),
    queryFn: () => fetchTherapistHomeAddress(therapistUserID),
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
  });
}
