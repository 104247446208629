// GET
export const REQUEST_GET_CLINICAL_INFORMATION = 'REQUEST_GET_CLINICAL_INFORMATION';
export const REQUEST_GET_CLINICAL_INFORMATION_ERROR = 'REQUEST_GET_CLINICAL_INFORMATION_ERROR';
export const RECEIVE_GET_CLINICAL_INFORMATION = 'RECEIVE_GET_CLINICAL_INFORMATION';

// SET
export const REQUEST_SET_CLINICAL_INFORMATION = 'REQUEST_SET_CLINICAL_INFORMATION';
export const REQUEST_SET_CLINICAL_INFORMATION_ERROR = 'REQUEST_SET_CLINICAL_INFORMATION_ERROR';
export const RECEIVE_SET_CLINICAL_INFORMATION = 'RECEIVE_SET_CLINICAL_INFORMATION';

export const REQUEST_GET_CONDITIONS = 'REQUEST_GET_CONDITIONS';
export const REQUEST_GET_CONDITIONS_ERROR = 'REQUEST_GET_CONDITIONS_ERROR';
export const RECEIVE_GET_CONDITIONS = 'RECEIVE_GET_CONDITIONS';
export const REQUEST_POST_CONDITIONS = 'REQUEST_POST_CONDITIONS';
export const REQUEST_POST_CONDITIONS_ERROR = 'REQUEST_POST_CONDITIONS_ERROR';
export const RECEIVE_POST_CONDITIONS = 'RECEIVE_POST_CONDITIONS';
export const REQUEST_DELETE_CONDITIONS = 'REQUEST_DELETE_CONDITIONS';
export const REQUEST_DELETE_CONDITIONS_ERROR = 'REQUEST_DELETE_CONDITIONS_ERROR';
export const RECEIVE_DELETE_CONDITIONS = 'RECEIVE_DELETE_CONDITIONS';

export const REQUEST_GET_PHYSICAL_CONDITIONS = 'REQUEST_GET_PHYSICAL_CONDITIONS';
export const REQUEST_GET_PHYSICAL_CONDITIONS_ERROR = 'REQUEST_GET_PHYSICAL_CONDITIONS_ERROR';
export const RECEIVE_GET_PHYSICAL_CONDITIONS = 'RECEIVE_GET_PHYSICAL_CONDITIONS';
export const REQUEST_POST_PHYSICAL_CONDITIONS = 'REQUEST_POST_PHYSICAL_CONDITIONS';
export const REQUEST_POST_PHYSICAL_CONDITIONS_ERROR = 'REQUEST_POST_PHYSICAL_CONDITIONS_ERROR';
export const RECEIVE_POST_PHYSICAL_CONDITIONS = 'RECEIVE_POST_PHYSICAL_CONDITIONS';
export const REQUEST_DELETE_PHYSICAL_CONDITIONS = 'REQUEST_DELETE_PHYSICAL_CONDITIONS';
export const REQUEST_DELETE_PHYSICAL_CONDITIONS_ERROR = 'REQUEST_DELETE_PHYSICAL_CONDITIONS_ERROR';
export const RECEIVE_DELETE_PHYSICAL_CONDITIONS = 'RECEIVE_DELETE_PHYSICAL_CONDITIONS';

export const REQUEST_ROOM_PRESENTING_PROBLEM = 'REQUEST_ROOM_PRESENTING_PROBLEM';
export const RECEIVE_ROOM_PRESENTING_PROBLEM = 'RECEIVE_ROOM_PRESENTING_PROBLEM';
export const REQUEST_ROOM_PRESENTING_PROBLEM_ERROR = 'REQUEST_ROOM_PRESENTING_PROBLEM_ERROR';

export const REQUEST_POST_ROOM_PRESENTING_PROBLEM = 'REQUEST_POST_ROOM_PRESENTING_PROBLEM';
export const POST_ROOM_PRESENTING_PROBLEM_SUCCESS = 'POST_ROOM_PRESENTING_PROBLEM_SUCCESS';
export const POST_ROOM_PRESENTING_PROBLEM_ERROR = 'POST_ROOM_PRESENTING_PROBLEM_ERROR';

export const REQUEST_DELETE_ROOM_PRESENTING_PROBLEM = 'REQUEST_DELETE_ROOM_PRESENTING_PROBLEM';
export const DELETE_ROOM_PRESENTING_PROBLEM_SUCCESS = 'DELETE_ROOM_PRESENTING_PROBLEM_SUCCESS';
export const DELETE_ROOM_PRESENTING_PROBLEM_ERROR = 'DELETE_ROOM_PRESENTING_PROBLEM_ERROR';

export const REQUEST_CONDITION_SURVEY = 'REQUEST_CONDITION_SURVEY';
export const RECEIVE_CONDITION_SURVEY = 'RECEIVE_CONDITION_SURVEY';
export const REQUEST_CONDITION_SURVEY_ERROR = 'REQUEST_CONDITION_SURVEY_ERROR';

export const REQUEST_CURRENT_SURVEY = 'REQUEST_CURRENT_SURVEY';
export const RECEIVE_CURRENT_SURVEY = 'RECEIVE_CURRENT_SURVEY';
export const REQUEST_CURRENT_SURVEY_ERROR = 'REQUEST_CURRENT_SURVEY_ERROR';

export const REQUEST_SWITCH_SURVEY = 'REQUEST_SWITCH_SURVEY';
export const SWITCH_SURVEY_SUCCESS = 'SWITCH_SURVEY_SUCCESS';
export const SWITCH_SURVEY_ERROR = 'SWITCH_SURVEY_ERROR';

export const CLOSE_SURVEY_ALERT = 'CLOSE_SURVEY_ALERT';
