import { useQuery } from 'react-query';
import apiWrapper from '../../utils/apiWrapper';
import apiHelper from '../../utils/api';
import liveSessions from './queryKeys';

export interface BookingClient {
  firstName: string;
  lastName: string;
  pseudonym: string;
  userID: number;
  isPrimaryParticipant: boolean;
}

export interface BookingsV4Response {
  bookingID: string;
  roomID: number;
  creditMinutes: number;
  creditType: string;
  modality: string;
  scheduledByUserType: string;
  startTime: string;
  status:
    | 'active'
    | 'completed'
    | 'therapist-no-show'
    | 'client-no-show'
    | 'never-confirmed'
    | 'client-canceled'
    | 'therapist-canceled'
    | 'admin-canceled'
    | 'completed-after-late-start';
  timekitBookingState:
    | 'tentative'
    | 'declined'
    | 'confirmed'
    | 'cancelled'
    | 'cancel_by_customer'
    | 'completed'
    | 'new_client_hold';
  hasBreakAfterSession: boolean;
  clients: BookingClient[];
  provider: {
    userID: number;
    firstName: string;
    lastName: string;
  };
  roomProviderID: number;
}

const fetchBookingsByDate = (therapistID: number, fromDate: string, toDate: string) => async () => {
  const { data } = await apiWrapper.get(
    `${
      apiHelper().apiEndpoint
    }/v4/therapists/${therapistID}/bookings?fromDate=${fromDate}&toDate=${toDate}`
  );
  return data.data;
};

const useQueryProviderBookings = (therapistID: number, fromDate: string, toDate: string) =>
  useQuery<BookingsV4Response[], Error>({
    queryKey: liveSessions.bookings(therapistID, { fromDate, toDate }),
    queryFn: fetchBookingsByDate(therapistID, fromDate, toDate),
    cacheTime: 0,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    retry: true,
    staleTime: 0,
  });

export default useQueryProviderBookings;
