import { useQuery } from 'react-query';

import apiWrapper from '../../../utils/apiWrapper';
import apiHelper from '../../../utils/api';

const paymentReportDatesQueryKey = (therapistID: number) => [therapistID];

const fetchPaymentReportDates = (therapistID: number) => async (): Promise<Date[]> => {
  const { data } = await apiWrapper.get(
    `${apiHelper().apiEndpoint}/v3/therapists/${therapistID}/payment-report-dates`
  );
  return data.data;
};

const useQueryPaymentReportDates = (therapistID: number, enabled: boolean = true) =>
  useQuery<Date[], Error>({
    queryKey: paymentReportDatesQueryKey(therapistID),
    queryFn: fetchPaymentReportDates(therapistID),
    cacheTime: 0,
    staleTime: 0,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
    enabled,
  });

export default useQueryPaymentReportDates;
