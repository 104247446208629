import { FunctionComponent } from 'react';
import Svg, { Path } from 'svgs';
import { EmotionStyle, useEmotionTheme } from '../../core/styled';

export interface PinIconProps {
  width?: number;
  height?: number;
  style?: EmotionStyle;
}

const PinIcon: FunctionComponent<{
  color?: string;
  width?: string | number;
  height?: string | number;
  style?: EmotionStyle;
  dataQa?: string;
}> = ({ width = 16, height = 21, color, style, dataQa, ...otherProps }) => {
  const titleText = 'Pin icon';
  const { colors } = useEmotionTheme();
  return (
    <Svg
      title={titleText}
      aria-label={titleText}
      width={width}
      height={height}
      viewBox="0 0 16 21"
      data-qa={dataQa}
      style={style}
      {...otherProps}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 7.495c0 1.212 1.027 2.195 2.295 2.195s2.295-.983 2.295-2.195c0-1.212-1.027-2.195-2.295-2.195S5.5 6.283 5.5 7.495ZM2.195 7.35c0-2.895 2.463-5.25 5.49-5.25 3.026 0 5.489 2.355 5.489 5.25 0 3.15-5.49 10.5-5.49 10.5S2.196 10.5 2.196 7.35ZM0 7.35C0 11.41 7.685 21 7.685 21s7.685-9.59 7.685-13.65C15.37 3.29 11.93 0 7.685 0 3.441 0 0 3.29 0 7.35Z"
        fill={color ?? colors.permaSanJuan}
      />
    </Svg>
  );
};

export default PinIcon;
