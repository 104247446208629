import styled, { EmotionTheme } from '../../core/styled';
import Text from '../Text';
import type { VariantFunction } from './types';

const miniDefaultStyle = (colors: EmotionTheme['colors']) => {
  return {
    fontSize: 11,
    lineHeight: '15px',
    letterSpacing: 0.2,
    fontWeight: 400,
    color: colors.slateGrey,
  };
};

const variants: Record<string, VariantFunction> = {
  default: ({ theme: { colors } }) => {
    return {
      ...miniDefaultStyle(colors),
    };
  },
};

const Mini = styled(Text)(variants.default);

export default Mini;
