import { ThunkAction } from 'redux-thunk';

import { toast } from '@talkspace/react-toolkit';
import {
  REQUEST_GET_SCRIPTS,
  REQUEST_GET_SCRIPTS_ERROR,
  RECEIVE_GET_SCRIPTS,
  REQUEST_POST_EDIT_SCRIPT,
  REQUEST_POST_EDIT_SCRIPT_ERROR,
  RECEIVE_POST_EDIT_SCRIPT,
  REQUEST_POST_RESTORE_SCRIPT,
  REQUEST_POST_RESTORE_SCRIPT_ERROR,
  RECEIVE_POST_RESTORE_SCRIPT,
  REQUEST_POST_DELETE_SCRIPT,
  REQUEST_POST_DELETE_SCRIPT_ERROR,
  RECEIVE_POST_DELETE_SCRIPT,
  REQUEST_POST_CREATE_SCRIPT,
  REQUEST_POST_CREATE_SCRIPT_ERROR,
  RECEIVE_POST_CREATE_SCRIPT,
  ScriptsActionTypes,
  GetScriptsPayload,
  PostCreateScriptPayload,
  PostDeleteScriptPayload,
  PostRestoreScriptPayload,
  PostEditScriptPayload,
} from '../constants/scriptsTypes';
import ChatApiHelpers from '../helpers/ChatApiHelpersClass';
import { AppState } from '../chatStore';
import {
  ScriptMessage,
  ScriptCategory,
  ScriptMessageStatus,
  ScriptMessageType,
} from '../../entities/Scripts';

type ScriptThunk = ThunkAction<
  Promise<ScriptsActionTypes>,
  AppState,
  ChatApiHelpers,
  ScriptsActionTypes
>;

export const isSameUpdatedMessage = (
  oldMessage: ScriptMessage,
  newMessage: ScriptMessage
): boolean => {
  // Deleting
  if (oldMessage.id === newMessage.id) return true;
  // Old was systemMessage
  if (
    oldMessage.type === ScriptMessageType.SYSTEM &&
    newMessage.type === ScriptMessageType.SYSTEM_MODIFIED
  ) {
    return oldMessage.id === newMessage.originatedFrom;
  }
  // New message is restored
  if (
    oldMessage.type === ScriptMessageType.SYSTEM_MODIFIED &&
    newMessage.type === ScriptMessageType.SYSTEM
  )
    return oldMessage.originatedFrom === newMessage.id;
  // Editing therapist messages
  if (
    newMessage.type === ScriptMessageType.THERAPIST ||
    oldMessage.type === ScriptMessageType.THERAPIST
  ) {
    return oldMessage.id === newMessage.originatedFrom;
  }
  // Editing system messages
  return (
    oldMessage.originatedFrom === newMessage.originatedFrom && oldMessage.title === newMessage.title
  );
};

const updateCategories = (
  categories: ScriptCategory[],
  newMessage: ScriptMessage,
  categoryId?: number
): ScriptCategory[] =>
  categories.map((category) => {
    // Insert
    if (categoryId) {
      if (category.id === categoryId)
        return { ...category, messages: [...category.messages, newMessage] };
      return category;
    }
    // Update
    const messages = category.messages.map((message) => {
      if (isSameUpdatedMessage(message, newMessage)) return newMessage;
      return message;
    });
    return { ...category, messages };
  });

function requestScripts(): ScriptsActionTypes {
  return { type: REQUEST_GET_SCRIPTS };
}
function requestScriptsError(error: Error): ScriptsActionTypes {
  return { type: REQUEST_GET_SCRIPTS_ERROR, error };
}
function receiveScripts(payload: GetScriptsPayload): ScriptsActionTypes {
  return { type: RECEIVE_GET_SCRIPTS, payload };
}

export const dispatchRequestScripts = (): ScriptThunk => (dispatch, getState, api) => {
  dispatch(requestScripts());
  return api
    .getScripts()
    .then((scriptCategories) => dispatch(receiveScripts({ scriptCategories })))
    .catch(api.dismissIfCancelled)
    .catch((error) => dispatch(requestScriptsError(error)));
};

function requestEditScript(): ScriptsActionTypes {
  return { type: REQUEST_POST_EDIT_SCRIPT };
}
function requestEditScriptError(error: Error): ScriptsActionTypes {
  return { type: REQUEST_POST_EDIT_SCRIPT_ERROR, error };
}
function receiveEditScript(payload: PostEditScriptPayload): ScriptsActionTypes {
  return { type: RECEIVE_POST_EDIT_SCRIPT, payload };
}

export const dispatchRequestEditScript =
  (scriptMessage: ScriptMessage): ScriptThunk =>
  (dispatch, getState, api) => {
    const { scriptCategories } = getState().scripts;
    dispatch(requestEditScript());
    return api
      .editScriptMessage(scriptMessage)
      .then((newScriptMessage) =>
        dispatch(
          receiveEditScript({
            scriptCategories: updateCategories(scriptCategories, newScriptMessage),
          })
        )
      )
      .catch((error) => {
        const errorMessage = error.message || 'There was an error. Please try again.';
        toast(errorMessage);
        return dispatch(requestEditScriptError(error));
      });
  };

function requestRestoreScript(): ScriptsActionTypes {
  return { type: REQUEST_POST_RESTORE_SCRIPT };
}
function requestRestoreScriptError(error: Error): ScriptsActionTypes {
  return { type: REQUEST_POST_RESTORE_SCRIPT_ERROR, error };
}
function receiveRestoreScript(payload: PostRestoreScriptPayload): ScriptsActionTypes {
  return { type: RECEIVE_POST_RESTORE_SCRIPT, payload };
}

export const dispatchRequestRestoreScript =
  (scriptMessage: ScriptMessage): ScriptThunk =>
  (dispatch, getState, api) => {
    const { scriptCategories } = getState().scripts;
    dispatch(requestRestoreScript());
    return api
      .restoreScriptMessage(scriptMessage)
      .then((newScriptMessage) =>
        dispatch(
          receiveRestoreScript({
            scriptCategories: updateCategories(scriptCategories, newScriptMessage),
          })
        )
      )
      .catch((error) => dispatch(requestRestoreScriptError(error)));
  };

function requestDeleteScript(): ScriptsActionTypes {
  return { type: REQUEST_POST_DELETE_SCRIPT };
}
function requestDeleteScriptError(error: Error): ScriptsActionTypes {
  return { type: REQUEST_POST_DELETE_SCRIPT_ERROR, error };
}
function receiveDeleteScript(payload: PostDeleteScriptPayload): ScriptsActionTypes {
  return { type: RECEIVE_POST_DELETE_SCRIPT, payload };
}

export const dispatchRequestDeleteScript =
  (scriptMessage: ScriptMessage): ScriptThunk =>
  (dispatch, getState, api) => {
    const { scriptCategories } = getState().scripts;
    dispatch(requestDeleteScript());
    return api
      .deleteScriptMessage(scriptMessage)
      .then(() =>
        dispatch(
          receiveDeleteScript({
            scriptCategories: updateCategories(scriptCategories, {
              ...scriptMessage,
              status: ScriptMessageStatus.DELETED,
            }),
          })
        )
      )
      .catch((error) => dispatch(requestDeleteScriptError(error)));
  };

function requestCreateScript(): ScriptsActionTypes {
  return { type: REQUEST_POST_CREATE_SCRIPT };
}
function requestCreateScriptError(error: Error): ScriptsActionTypes {
  return { type: REQUEST_POST_CREATE_SCRIPT_ERROR, error };
}
function receiveCreateScript(payload: PostCreateScriptPayload): ScriptsActionTypes {
  return { type: RECEIVE_POST_CREATE_SCRIPT, payload };
}

export const dispatchRequestCreateScript =
  (title: string, message: string, categoryId: number): ScriptThunk =>
  (dispatch, getState, api) => {
    const { scriptCategories } = getState().scripts;
    dispatch(requestCreateScript());
    return api
      .createScriptMessage(title, message, categoryId)
      .then((newScriptMessage) =>
        dispatch(
          receiveCreateScript({
            scriptCategories: updateCategories(scriptCategories, newScriptMessage, categoryId),
          })
        )
      )
      .catch((error) => {
        const errorMessage = error.message || 'There was an error. Please try again.';
        toast(errorMessage);
        return dispatch(requestCreateScriptError(error));
      });
  };
