import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import UserBadge from '../UserBadge/UserBadge';

const UserHeader = ({ preferredName, isHorizontal }) => (
  <Row>
    <Col sm={2} md={2} lg={2}>
      <UserBadge
        className="pull-left"
        preferredName={preferredName}
        nameIsHidden
        isHorizontal={isHorizontal}
      />
    </Col>
  </Row>
);

UserHeader.propTypes = {
  userInitials: PropTypes.string,
  userName: PropTypes.string,
};

export default UserHeader;
