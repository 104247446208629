import { VoidFunctionComponent } from 'react';
import SmallMessagingIcon from './SmallMessagingIcon';
import SmallLiveIcon from './SmallLiveIcon';
import { EmotionStyle } from '../../core/styled';

export type ProgressNoteModality = 'messaging' | 'liveSession';
interface TaskIconSmallProp {
  width?: number;
  height?: number;
  style?: EmotionStyle;
  modality?: ProgressNoteModality;
}

const TaskIconSmall: VoidFunctionComponent<TaskIconSmallProp> = ({
  width = 19,
  height = 15,
  style = {},
  modality = 'liveSession',
}) => {
  switch (modality) {
    case 'messaging':
      return <SmallMessagingIcon aria-hidden="true" width={width} height={height} style={style} />;
    case 'liveSession':
      return <SmallLiveIcon aria-hidden="true" width={width} height={height} style={style} />;
    default:
      return <SmallLiveIcon aria-hidden="true" width={width} height={height} style={style} />;
  }
};

export default TaskIconSmall;
