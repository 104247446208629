import StackTrace from 'stacktrace-js';
import { FirebaseCrashlytics } from '@capacitor-firebase/crashlytics';
import { isIonic } from '@/auth/reactFrame/helpers';

const getErrorMessage = (error: Error, errorInfo?: React.ErrorInfo) => {
  if (errorInfo?.componentStack) {
    return `[${error.name}] ${error.message} - @${errorInfo.componentStack?.split('@')[0]}`;
  }
  return `[${error.name}] ${error.message}`;
};

export const crashlyticsErrorHandler = (error: Error, errorInfo?: React.ErrorInfo) => {
  if (!isIonic()) return undefined;
  if (errorInfo) FirebaseCrashlytics.log({ message: errorInfo.componentStack });
  return StackTrace.fromError(error, { offline: true })
    .then((stackFrame) => {
      FirebaseCrashlytics.recordException({
        message: getErrorMessage(error, errorInfo),
        stacktrace: stackFrame,
      });
    })
    .catch(() => {
      FirebaseCrashlytics.recordException({ message: getErrorMessage(error, errorInfo) });
    });
};

export default crashlyticsErrorHandler;
