import { VoidFunctionComponent } from 'react';
import moment from 'moment';
import { Big, Large, useEmotionTheme, Huge } from '@talkspace/react-toolkit';
import {
  SessionModality,
  TherapistTimeslot,
  VideoCreditOffer,
  RepeatingMeta,
} from 'ts-frontend/types';
import { useTranslation } from '@talkspace/i18n';
import styled from '@/core/styled';

import useRepeatingTimeslotsCopy from '../../hooks/useRepeatingTimeslotsCopy';

const LargeLight = styled(Large)({
  fontWeight: 400,
});

interface Props {
  modality: SessionModality;
  selectedTimeslot?: TherapistTimeslot | null;
  selectedCreditOption?: VideoCreditOffer;
  isRecurringBooking?: boolean;
  repeatingMeta?: RepeatingMeta | null;
  repeatingTimeslots?: TherapistTimeslot[] | null;
  isTherapist: boolean;
}

const BookingDates: VoidFunctionComponent<Props> = ({
  modality,
  selectedTimeslot,
  selectedCreditOption,
  isRecurringBooking,
  repeatingMeta,
  repeatingTimeslots,
  isTherapist,
}) => {
  const { colors } = useEmotionTheme();
  const { everyWeek, dayName, startTimeString, endTimeString, startDateString, endDateString } =
    useRepeatingTimeslotsCopy(repeatingTimeslots);
  const DateComponent = isTherapist ? Big : Huge;
  const { t: tBookingScreen } = useTranslation('bookingScreen');
  return isRecurringBooking && repeatingMeta && repeatingTimeslots ? (
    <>
      <LargeLight variant="largeMediumGrey">
        {!everyWeek ? 'Every other' : 'Every'} {dayName}
      </LargeLight>
      <LargeLight variant="largeMediumGrey">
        {startTimeString} {' – '}
        {endTimeString}
      </LargeLight>
      <LargeLight variant="largeMediumGrey">
        {startDateString} {' – '}
        {endDateString}
      </LargeLight>
    </>
  ) : (
    <>
      <DateComponent style={{ marginTop: 16 }}>
        {modality === 'messaging'
          ? tBookingScreen('cost.messaging', 'Messaging session', undefined)
          : moment(selectedTimeslot?.start).format('dddd, MMMM D')}
      </DateComponent>
      <Big
        variant="bigMedium"
        style={{ color: colors.permaSlateGrayApprox, marginTop: 4, marginBottom: 4 }}
      >
        {modality === 'messaging' ? (
          `Starting ${moment().format('MMMM D, YYYY')}`
        ) : (
          <>
            {moment(selectedTimeslot?.start).format('h:mma')} {' – '}
            {moment(selectedTimeslot?.start)
              .add(selectedCreditOption?.creditMinutes, 'minutes')
              .format('h:mma')}
          </>
        )}
      </Big>
    </>
  );
};

export default BookingDates;
