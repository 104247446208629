import { useEffect, useState, useCallback, FunctionComponent } from 'react';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { View, Big, useEmotionTheme } from '@talkspace/react-toolkit';
import moment from 'moment';
import { ProviderCancellationReasonEnum } from 'ts-frontend/types';
import useQueryBusinessLine from 'ts-frontend/hooks/useQueryBusinessLine';
import useMutationCancelBooking from 'inRoomScheduling/hooks/useMutationCancelBooking';
import useQueryRoomDetails from 'ts-frontend/hooks/useQueryRoomDetails';
import { requestAppReview } from 'ts-ionic/plugins/appReview';
import { withRouter, RouteComponentProps, useHistory } from '@/core/routerLib/routerLib';
import { tsAnalyticsTracker } from '@/utils/analytics/eventTracker';
import AccordionContainer from '../../../Reusable/AccordionContainer/AccordionContainer';
import AccordionMenuItem from '../../../Reusable/AccordionMenuItem/AccordionMenuItem';
import {
  useUpcomingBookings,
  DeclineBookingModal,
  CancelBookingModal,
  CancelRecurringBookingModal,
  ErrorBookingModal,
} from '../../../../modules/upcomingBookings';
import styled from '../../../../core/styled';
import { PatchBookingPayload } from '../../../../modules/upcomingBookings/types';
import BookingsList from './BookingsList';
import ScheduleRepeatingSessionsCallout from './ScheduleRepeatingSessionsCallout';

const accordionMenuItemStyle = {
  paddingTop: 9,
  paddingBottom: 9,
  margin: 0,
  fontWeight: 500,
};

const BookingsErrorWrapper = styled(View)({
  background: '#EFF3FA',
  height: 81,
  borderRadius: 12,
  justifyContent: 'center',
  alignItems: 'center',
});

const BookingsError = () => (
  <BookingsErrorWrapper>
    <Big variant="bigMedium">Unable to load content</Big>
  </BookingsErrorWrapper>
);

interface TabProps {
  isProviderScheduleBookingFlagOn: boolean;
  shouldAllowBookings: boolean;
  hasHadFirstSession: boolean;
}

const LVSTab: FunctionComponent<TabProps & RouteComponentProps<{ roomID: string }>> = ({
  match,
  isProviderScheduleBookingFlagOn,
  shouldAllowBookings,
  hasHadFirstSession,
}) => {
  const roomID = Number(match.params.roomID);
  const { repeatingSessionsFull: isActiveRepeatingSessionsFull, repeatingSessionsFull2 } =
    useFlags();

  const history = useHistory();
  const { colors } = useEmotionTheme();
  const [
    {
      bookings,
      isError,
      isLoading,
      bookingUpdateFetchState,
      whichModalIsOpen,
      bookingUpdateFetchErrorMessage,
    },
    {
      dispatchGetRoomBookings,
      dispatchPatchBooking,
      dispatchSetWhichModalIsOpen,
      dispatchResetUpcomingBookingsState,
    },
  ] = useUpcomingBookings();
  const { mutate: cancelBooking, error: cancellationError } = useMutationCancelBooking();

  const { data: { clientUserID } = {} } = useQueryRoomDetails(roomID);
  const { data: businessLineData } = useQueryBusinessLine(roomID, clientUserID);
  const { isBH, isPsychiatry } = businessLineData || {};
  const [selectedBookingID, setSelectedBookingID] = useState<string | undefined>();
  const [isCancelRecurring, setIsCancelRecurring] = useState<boolean>(false);

  useEffect(() => {
    dispatchGetRoomBookings(roomID);
  }, [dispatchGetRoomBookings, roomID]);

  const patchBooking = useCallback(
    (bookingID, payload: PatchBookingPayload) => {
      dispatchPatchBooking(bookingID, payload, Number(roomID));
    },
    [dispatchPatchBooking, roomID]
  );

  const handleCancelRecurringBooking = (bookingID) => {
    dispatchResetUpcomingBookingsState();
    setSelectedBookingID(bookingID);
    dispatchSetWhichModalIsOpen(repeatingSessionsFull2 ? 'cancelRecurring' : 'cancel');
  };

  const handleCancelBooking = (bookingID) => {
    const clickedBooking = bookings?.find((b) => b.id === bookingID);

    const isRecurringBooking = clickedBooking?.repeatingBookingID;
    if (isRecurringBooking) {
      handleCancelRecurringBooking(bookingID);
    } else {
      dispatchResetUpcomingBookingsState();
      setSelectedBookingID(bookingID);
      dispatchSetWhichModalIsOpen('cancel');
    }
  };

  const handleCancelRecurringContinue = () => {
    dispatchSetWhichModalIsOpen('cancel');
  };

  const handleDeclineBooking = (bookingID) => {
    dispatchResetUpcomingBookingsState();
    setSelectedBookingID(bookingID);
    dispatchSetWhichModalIsOpen('decline');
  };
  const handleCloseModal = () => {
    // important to reset patch success state as well as which modal to undefined
    // this call resets everything but the bookings array
    setIsCancelRecurring(false);
    dispatchResetUpcomingBookingsState();
  };
  const selectedBooking = bookings && bookings.find((b) => b.id === selectedBookingID);
  const bookingDatetimeString = selectedBooking
    ? moment(selectedBooking.startTime).format(' dddd, MMM D [at] h:mm A')
    : '';

  const showScheduleRepeatingCallout = isActiveRepeatingSessionsFull && isBH && !isPsychiatry;
  const isOpenLVSActions = showScheduleRepeatingCallout;
  return (
    <div className="ts-crm-panel">
      <AccordionContainer title="Upcoming" dataQa="upcomingLiveAccordion" open>
        {isError && !whichModalIsOpen ? (
          <BookingsError />
        ) : (
          <BookingsList
            bookings={bookings}
            isLoading={isLoading}
            fetchState={bookingUpdateFetchState}
            handleRemoveBooking={(bookingID) => patchBooking(bookingID, { action: 'dismiss' })}
            handleConfirmBooking={(bookingID, hasBreakAfterSession) => {
              patchBooking(bookingID, { action: 'confirm', hasBreakAfterSession });
              requestAppReview();
            }}
            handleCancelBooking={handleCancelBooking}
            handleDeclineBooking={handleDeclineBooking}
          />
        )}
      </AccordionContainer>
      <AccordionContainer
        title="Live session actions"
        dataQa="liveSessionsLiveAccordion"
        open={isOpenLVSActions}
      >
        {isProviderScheduleBookingFlagOn && shouldAllowBookings && (
          <AccordionMenuItem
            path="lvs-tab"
            title="Schedule a live session"
            clickHandler={() => {
              tsAnalyticsTracker.trackMPEventGeneric('Open Therapist Scheduler', {
                targets: [tsAnalyticsTracker.targetsEnum.MIXPANEL_PROVIDER],
                roomID: match.params.roomID,
                Source: 'From CRM',
              });
              history.replace('in-room-scheduling');
            }}
            style={accordionMenuItemStyle}
            arrowColor={colors.darkBlue}
            arrowWidth={6}
            arrowHeight={10.5}
            dataQa="scheduleLiveSessionButton"
          >
            {showScheduleRepeatingCallout && <ScheduleRepeatingSessionsCallout />}
          </AccordionMenuItem>
        )}
        <AccordionMenuItem
          path="lvs-tab"
          view="video-call-history"
          title="View session history"
          style={{ ...accordionMenuItemStyle, marginBottom: 17 }}
          arrowColor={colors.darkBlue}
          arrowWidth={6}
          arrowHeight={10.5}
          dataQa="viewSessionHistoryButton"
        />
      </AccordionContainer>
      {whichModalIsOpen === 'cancel' && (
        <CancelBookingModal
          closeModal={handleCloseModal}
          bookingDatetimeString={bookingDatetimeString}
          error={cancellationError}
          cancelBooking={(cancelReason: string, otherReasonText?: string) => {
            if (selectedBookingID) {
              cancelBooking(
                {
                  roomID: match.params.roomID,
                  bookingID: selectedBookingID,
                  data: {
                    reason: cancelReason,
                    ...(cancelReason === ProviderCancellationReasonEnum.OTHER && otherReasonText
                      ? { metadata: { cancellationReason: otherReasonText } }
                      : {}),
                  },
                  isBatchMode: isCancelRecurring,
                },
                {
                  onSuccess: () => {
                    handleCloseModal();
                  },
                  onSettled: () => {
                    dispatchGetRoomBookings(Number(match.params.roomID));
                  },
                }
              );
            }
          }}
          fetchState={bookingUpdateFetchState}
        />
      )}
      {whichModalIsOpen === 'cancelRecurring' && (
        <CancelRecurringBookingModal
          closeModal={handleCloseModal}
          bookings={bookings}
          selectedBookingID={selectedBookingID}
          isCancelRecurring={isCancelRecurring}
          setIsCancelRecurring={setIsCancelRecurring}
          cancelBooking={handleCancelRecurringContinue}
        />
      )}
      {whichModalIsOpen === 'decline' && selectedBookingID && (
        <DeclineBookingModal
          selectedBookingID={selectedBookingID}
          onSuccess={() => dispatchGetRoomBookings(Number(match.params.roomID))}
          closeModal={handleCloseModal}
          bookingDatetimeString={bookingDatetimeString}
          hasHadFirstSession={hasHadFirstSession}
        />
      )}
      {whichModalIsOpen === 'error' && (
        <ErrorBookingModal
          textContent={bookingUpdateFetchErrorMessage}
          closeModal={handleCloseModal}
        />
      )}
    </div>
  );
};

export default withRouter(LVSTab);
