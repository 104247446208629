import { FunctionComponent, useEffect, useState } from 'react';
import {
  AccordionContainer,
  Checkbox,
  OptionType,
  Select,
  Tooltip,
  useEmotionTheme,
  View,
  Tiny,
} from '@talkspace/react-toolkit';
import { OptionsType, ValueType } from 'react-select';
import { connect } from 'react-redux';
import moment from 'moment';
import * as clinicalInformationActions from 'actions/ClinicalInformationActions';
import useQueryProgressNoteHelperData from 'hooks/notes/useQueryProgressNoteHelperData';
import useQueryBusinessLine from 'ts-frontend/hooks/useQueryBusinessLine';
import { useParams } from '@/core/routerLib';
import {
  useProgressNoteFormState,
  useProgressNoteFormActions,
} from '../context/PsychProgressNoteFormContext';
import { ProgressNoteFormState } from '../context/types';
import ConfirmButton from './ConfirmButton';
import { getSelectStyle } from '../../utils';
import useQueryConditions from '../../../../../../hooks/notes/useQueryConditions';

interface Condition {
  id: number;
  isWorkingDiagnosis: boolean;
  label: string;
  value: number;
  isBillable: boolean;
}

const dateInTheLastTwoWeeks = (date?: Date) => {
  if (!date) {
    return false;
  }
  return moment().diff(moment(date), 'day') <= 14;
};

interface StoreProps {
  clientUserID: number;
  conditions: Array<Condition>;
  getConditions: (clientUserID: number) => void;
}

const DiagnosisSection: FunctionComponent<StoreProps> = ({
  clientUserID,
  conditions,
  getConditions,
}) => {
  const { roomID } = useParams<{ roomID: string }>();
  const { formMode, diagnoses: globalDiagnoses, formSections } = useProgressNoteFormState();
  const [diagnoses, setDiagnoses] = useState<ProgressNoteFormState['diagnoses']>(globalDiagnoses);
  const [isProvisionalDiagnosis, setIsProvisionalDiagnosis] = useState(
    globalDiagnoses.some((it) => it.isProvisionalDiagnosis)
  );
  const { setFormState } = useProgressNoteFormActions();
  const [diagnosisError, setDiagnosisError] = useState(false);
  const { colors } = useEmotionTheme();
  const { data: helperData } = useQueryProgressNoteHelperData(roomID);
  const { data: businessLineData } = useQueryBusinessLine(roomID, clientUserID);
  const { data: conditionList } = useQueryConditions();
  const hasSelectedBillable = diagnoses.some(({ isBillable }) => isBillable);

  useEffect(() => {
    if (formMode === 'create') {
      getConditions(clientUserID);
    }
  }, [clientUserID, getConditions, formMode]);

  useEffect(() => {
    if (formMode === 'create' && conditions?.length > 0) {
      setDiagnoses(
        conditions.map((it) => {
          return {
            conditionID: it.value,
            isProvisionalDiagnosis: false,
            isBillable: it.isBillable,
          };
        })
      );
    }
  }, [conditions, formMode]);

  const markSectionAsIncomplete = () => {
    if (formSections.diagnosis.completed) {
      setFormState({
        formSections: {
          ...formSections,
          diagnosis: {
            open: formSections.diagnosis.open,
            changed: true,
            completed: false,
          },
        },
      });
    }
  };

  const handleDiagnosisChange = (newValues: Array<Condition> | null) => {
    markSectionAsIncomplete();
    setDiagnosisError(false);

    const hasBillable = newValues?.some(({ isBillable }) => isBillable);

    if (newValues === null || newValues.length === 0 || !hasBillable) {
      setDiagnoses([]);
    } else {
      const selectedDiagnosesIDs = newValues.map((it) => it.value);
      setDiagnoses(
        selectedDiagnosesIDs.map((it) => {
          return { conditionID: it, isProvisionalDiagnosis, isBillable: true };
        })
      );
    }
  };

  const handleProvisionalDiagnosisToggle = (newValue: boolean) => {
    markSectionAsIncomplete();
    setIsProvisionalDiagnosis(newValue);
    setDiagnoses(
      diagnoses.map((it) => {
        return {
          conditionID: it.conditionID,
          isProvisionalDiagnosis: newValue,
          isBillable: it.isBillable,
        };
      })
    );
  };

  const handleAccordionPress = (value: boolean) => {
    setFormState({
      formSections: {
        ...formSections,
        diagnosis: {
          open: value,
          changed: formSections.diagnosis.changed,
          completed: formSections.diagnosis.completed,
        },
      },
    });
  };

  const handleConfirmPress = () => {
    let isError = false;

    if (
      (!diagnoses || diagnoses.length === 0 || !hasSelectedBillable) &&
      !businessLineData?.isEAP
    ) {
      setDiagnosisError(true);
      isError = true;
    }

    if (isError) {
      setFormState({ showGlobalError: true });
      return;
    }

    const newDiagnoses = diagnoses.map((it) => {
      return { conditionID: it.conditionID, isProvisionalDiagnosis: it.isProvisionalDiagnosis };
    });

    setFormState({
      diagnoses: newDiagnoses,
      formSections: {
        ...formSections,
        diagnosis: {
          open: false,
          changed: true,
          completed: true,
        },
        ...(formSections.riskAssessment.completed
          ? {}
          : {
              riskAssessment: {
                ...formSections.riskAssessment,
                open: true,
              },
            }),
      },
    });
  };

  const showProvisionalDiagnosisCheckbox =
    dateInTheLastTwoWeeks(helperData && new Date(helperData.roomCreationDate)) &&
    !businessLineData?.isPsychiatry;

  const disabledValues = hasSelectedBillable
    ? null
    : conditionList?.filter((it) => !it.isBillable).map((it) => it.id.toString());
  const { isEAP } = businessLineData || {};

  return (
    <AccordionContainer
      title="Diagnosis"
      open={formSections.diagnosis.open}
      onPress={handleAccordionPress}
      showCheckMark={formMode !== 'view' && formSections.diagnosis.completed}
      dataQa="diagnosisAccordion"
    >
      <View style={{ marginBottom: 10 }}>
        <Select
          dataQa="diagnosisDropdown"
          key={JSON.stringify({ disabledValues })}
          placeholder="Diagnosis"
          options={(conditionList as OptionsType<any>) || []}
          value={
            conditionList?.filter((opt) =>
              diagnoses.map((diagnosis) => diagnosis.conditionID).includes(opt.value as any)
            ) as ValueType<OptionType<any>, boolean>
          }
          onChange={handleDiagnosisChange as (value: ValueType<OptionType<any>, boolean>) => void}
          isMulti
          styles={getSelectStyle(colors, diagnosisError)}
          isDisabled={formMode === 'view'}
          isOptionDisabled={(option) => {
            if (!disabledValues?.length) return false;
            return option.value ? disabledValues.includes(`${option.value}`) : false;
          }}
        />
        {!hasSelectedBillable && !isEAP && (
          <Tiny>Non-billable diagnoses are disabled until a billable diagnosis is selected.</Tiny>
        )}
        {isEAP && <Tiny>Diagnoses are optional for clients on EAP plans.</Tiny>}
      </View>

      {showProvisionalDiagnosisCheckbox && (
        <View style={{ flexDirection: 'row' }}>
          <Checkbox
            containerStyle={{ alignSelf: 'flex-start', marginRight: 9 }}
            isLabelOnRight
            isChecked={isProvisionalDiagnosis}
            setIsChecked={handleProvisionalDiagnosisToggle}
            label="This is a provisional diagnosis"
            isDisabled={diagnoses.length === 0 || formMode === 'view'}
            dataQa="provisionalDiagnosisCheckbox"
          />
          <Tooltip
            buttonStyle={{ opacity: 1, pointerEvents: 'auto' }}
            questionMarkSize={16}
            tip="Check this box during the first two weeks of treatment if more information is needed to make a formal diagnosis."
            label="Provisional diagnosis tooltip"
            roundedFocusStyle
            dataQa="provisionalDiagnosisTooltip"
          />
        </View>
      )}
      {formMode !== 'view' && !formSections.diagnosis.completed && (
        <ConfirmButton onPress={handleConfirmPress} sectionTitle="diagnosis" />
      )}
    </AccordionContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    clientUserID: state.room.clientUserID,
    conditions: state.clinicalInformation.conditions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getConditions: (clientUserID: number) =>
      dispatch(clinicalInformationActions.getConditions(clientUserID)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DiagnosisSection);
