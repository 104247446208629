import { AdminConfigName, PublicAdminConfigName } from '../types/AdminConfig';

const adminConfigKeys = {
  all: ['adminConfigs'],
  config: (configName: AdminConfigName) => [...adminConfigKeys.all, 'configs', configName],
  publicConfig: (configName: PublicAdminConfigName) => [
    ...adminConfigKeys.all,
    'publicConfigs',
    configName,
  ],
};

export const roomDetailsQueryKey = (roomID?: string | number): Array<string> => [
  'rooms',
  `${roomID}`,
  'room-details',
];

export const therapistDetailsQueryKey = (therapistID?: string | number): Array<string> => [
  'therapists',
  `${therapistID}`,
  'therapist-details',
];

export const sessionStatusQueryKey = (roomID: number): Array<string> => [
  'sessions',
  `${roomID}`,
  'session-status',
];

export const clientDetailsQueryKey = (userID: string | number): Array<string> => [
  'users',
  `${userID}`,
  'client-details',
];

export const therapistImplicitBusinessHoursQueryKey = (
  therapistID?: string | number
): Array<string> => ['therapists', `${therapistID}`, 'implicit-business-hours'];

export const subscriptionsQueryKey = ({
  clientUserID,
  roomID,
  includePaymentDetails,
  includeInsuranceStatus,
  includePlanDetails,
  includeLvsStatus,
}: {
  clientUserID: number;
  roomID?: number;
  includePaymentDetails?: boolean;
  includeInsuranceStatus?: boolean;
  includePlanDetails?: boolean;
  includeLvsStatus?: boolean;
}) => [
  'scheduler',
  'subscriptions',
  clientUserID,
  roomID || '',
  includePaymentDetails !== undefined ? includePaymentDetails : '',
  includeInsuranceStatus !== undefined ? includeInsuranceStatus : '',
  includePlanDetails !== undefined ? includePlanDetails : '',
  includeLvsStatus !== undefined ? includeLvsStatus : '',
];

export const businessLineQueryKey = (
  roomID?: string | number,
  clientUserID?: string | number
): Array<string> => [
  'rooms',
  `${roomID}`,
  'clients',
  clientUserID?.toString() || '',
  'business-line',
];

export const clientUsageStatsQueryKey = ({
  clientID,
  therapistID,
}: {
  clientID?: number;
  therapistID?: number;
}) => [clientID, therapistID, 'clientUsageStats'];

export default adminConfigKeys;
