import { FunctionComponent, FocusEvent, RefObject, MouseEvent } from 'react';
import * as React from 'react';
import TouchableView from '../TouchableView';
import { EmotionStyle, useEmotionTheme } from '../../core/styled';

import Star from '../icons/Star';

interface MessageStarComponentProps {
  isActive: boolean;
  onPress: () => void;
  styles?: EmotionStyle;
  onBlur?: ((e: FocusEvent<HTMLButtonElement> | MouseEvent) => void) &
    ((event: React.FocusEvent<HTMLDivElement>) => void);
  onFocus?: ((e: FocusEvent<HTMLButtonElement> | MouseEvent) => void) &
    ((event: React.FocusEvent<HTMLDivElement>) => void);
  firstStarRef?: RefObject<HTMLDivElement>;
  ariaDescribedBy?: string;
  ariaLabel?: string;
}

const MessageStar: FunctionComponent<MessageStarComponentProps> = (props) => {
  const { isActive, onPress, styles, onBlur, onFocus, firstStarRef, ariaDescribedBy, ariaLabel } =
    props;

  const { colors } = useEmotionTheme();
  const fillColor = isActive ? colors.gold : 'none';
  const strokeColor = isActive ? colors.gold : colors.periwinkleGrey;
  const finalAriaLabel = ariaLabel ? `mark as starred ${ariaLabel}` : 'mark as starred';
  return (
    <TouchableView
      ref={firstStarRef}
      onPress={onPress}
      onFocus={onFocus}
      onBlur={onBlur}
      role="checkbox"
      aria-checked={isActive}
      aria-label={finalAriaLabel}
      aria-describedby={ariaDescribedBy}
    >
      <Star fillColor={fillColor} strokeColor={strokeColor} style={styles} />
    </TouchableView>
  );
};

export default MessageStar;
