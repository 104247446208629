import { forwardRef } from 'react';
import { VideoCallTrayEnum } from '../types';
import VideoCallTrayTherapistScheduleNextRecurring from './VideoCallTrayTherapistScheduleNextRecurringContainer';

import VideoCallTrayPopup from '../components/VideoCall/VideoCallTrayPopup';

interface Props {
  type: VideoCallTrayEnum;
  closeTray: () => void;
  handleMinimize: () => void;
}
const VideoCallTrayPopupContainer = forwardRef<HTMLDivElement, Props>(
  ({ type, closeTray, handleMinimize }, ref) => {
    let content: React.ReactNode = null;
    switch (type) {
      case VideoCallTrayEnum.THERAPIST_SCHEDULE_NEXT_RECURRING:
        content = (
          <VideoCallTrayTherapistScheduleNextRecurring
            closeTray={closeTray}
            handleMinimize={handleMinimize}
          />
        );
        break;
      default:
        break;
    }
    return <VideoCallTrayPopup ref={ref}>{content}</VideoCallTrayPopup>;
  }
);

export default VideoCallTrayPopupContainer;
