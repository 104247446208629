import { FunctionComponent } from 'react';
import Svg, { Path, Stop, Defs, Ellipse, G, LinearGradient, Use } from 'svgs';
import { useEmotionTheme } from '../../core/styled';

interface SparklingModalitiesProps {
  width?: number;
  height?: number;
  color?: string;
}

const SparklingModalities: FunctionComponent<SparklingModalitiesProps> = ({
  width = 162,
  height = 71,
  ...otherProps
}) => {
  const titleText = 'Video, Audio, and Chat';
  const { colors } = useEmotionTheme();
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 162 71"
      {...otherProps}
    >
      <Defs>
        <LinearGradient x1="90.459%" y1="50%" x2="-147.507%" y2="50%" id="SparklingModalities__a">
          <Stop stopColor={colors.green} offset="0%" />
          <Stop stopColor={colors.purple} offset="100%" />
        </LinearGradient>
        <LinearGradient x1="168.397%" y1="50%" x2="-69.194%" y2="50%" id="SparklingModalities__d">
          <Stop stopColor={colors.green} offset="0%" />
          <Stop stopColor={colors.purple} offset="100%" />
        </LinearGradient>
        <LinearGradient x1="248%" y1="50%" x2="8.234%" y2="50%" id="SparklingModalities__g">
          <Stop stopColor={colors.green} offset="0%" />
          <Stop stopColor={colors.purple} offset="100%" />
        </LinearGradient>
        <filter
          x="-70.1%"
          y="-45.3%"
          width="240.2%"
          height="245.9%"
          filterUnits="objectBoundingBox"
          id="SparklingModalities__b"
        >
          <feOffset dy={7} in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur stdDeviation={5} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
          <feColorMatrix
            values="0 0 0 0 0 0 0 0 0 0.437655622 0 0 0 0 0.539741848 0 0 0 0.366859703 0"
            in="shadowBlurOuter1"
          />
        </filter>
        <filter
          x="-90.7%"
          y="-41.4%"
          width="281.4%"
          height="233.2%"
          filterUnits="objectBoundingBox"
          id="SparklingModalities__e"
        >
          <feOffset dy={7} in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur stdDeviation={5} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
          <feColorMatrix
            values="0 0 0 0 0 0 0 0 0 0.437655622 0 0 0 0 0.539741848 0 0 0 0.366859703 0"
            in="shadowBlurOuter1"
          />
        </filter>
        <filter
          x="-65.3%"
          y="-64.1%"
          width="230.5%"
          height="306.3%"
          filterUnits="objectBoundingBox"
          id="SparklingModalities__h"
        >
          <feOffset dy={7} in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur stdDeviation={5} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
          <feColorMatrix
            values="0 0 0 0 0 0 0 0 0 0.437655622 0 0 0 0 0.539741848 0 0 0 0.366859703 0"
            in="shadowBlurOuter1"
          />
        </filter>
        <Path
          d="M125.68 46.277a7.616 7.616 0 01-.278 2.752c-.181.625.02 1.298.512 1.716a1.593 1.593 0 001.755.207c1.607-.822 3.744-2.25 5.042-4.445h1.065c5.646 0 10.224-4.643 10.224-10.37 0-5.727-4.578-10.37-10.224-10.37h-5.952c-5.23 0-9.616 4.004-10.167 9.279-.55 5.275 2.913 10.12 8.027 11.231h-.003z"
          id="SparklingModalities__c"
        />
        <Path
          d="M73.029 24.517c.946-.703 1.548-.967 2.58-1.143 1.72-.44 4.903 5.713 3.527 7.295-.517.616-1.205.528-2.409 1.055-.258 3.955 3.87 10.635 7.483 12.13.86-.88 1.204-1.495 1.892-1.758 2.064-.528 6.02 5.097 4.903 6.504-.688.79-1.118 1.23-2.15 1.846-8 5.185-23.91-20.831-15.826-25.93z"
          id="SparklingModalities__f"
        />
        <Path
          d="M32.557 28.182a4.234 4.234 0 014.233 4.234v9.465a4.234 4.234 0 01-4.233 4.233H22.234A4.234 4.234 0 0118 41.881v-9.465a4.234 4.234 0 014.234-4.234h10.323zm13.587 3.4c.135.249.206.527.206.81v8.853a1.693 1.693 0 01-2.503 1.488l-4.854-2.643a1.693 1.693 0 01-.884-1.487v-3.569c0-.62.339-1.19.884-1.487l4.854-2.642a1.693 1.693 0 012.297.677z"
          id="SparklingModalities__i"
        />
      </Defs>
      <G fill="none" fillRule="evenodd">
        <Ellipse
          stroke={colors.a11yConiferGreen}
          strokeWidth={2.797}
          cx={54.517}
          cy={67.108}
          rx={2.517}
          ry={2.496}
        />
        <Ellipse
          stroke={colors.white}
          strokeWidth={4.2}
          fill="url(#SparklingModalities__a)"
          cx={130.2}
          cy={37.239}
          rx={29.7}
          ry={29.9}
        />
        <circle stroke="#9EDFFF" strokeWidth={4} cx={106} cy={6} r={4} />
        <Use fill={colors.black} xlinkHref="#SparklingModalities__c" />
        <Use fill={colors.white} xlinkHref="#SparklingModalities__c" />
        <Ellipse
          stroke={colors.white}
          strokeWidth={4.2}
          fill="url(#SparklingModalities__d)"
          cx={81}
          cy={37.239}
          rx={29.7}
          ry={29.9}
        />
        <Use fill={colors.black} xlinkHref="#SparklingModalities__f" />
        <Use fill={colors.white} xlinkHref="#SparklingModalities__f" />
        <Ellipse
          stroke={colors.white}
          strokeWidth={4.2}
          fill="url(#SparklingModalities__g)"
          cx={31.8}
          cy={37.239}
          rx={29.7}
          ry={29.9}
        />
        <G>
          <Use fill={colors.black} xlinkHref="#SparklingModalities__i" />
          <Use fill={colors.white} xlinkHref="#SparklingModalities__i" />
        </G>
        <G fill="#C0E3E0">
          <rect width={2.797} height={8.32} rx={1.399} transform="rotate(65 4.122 14.553)" />
          <rect width={2.797} height={8.32} rx={1.399} transform="rotate(155 6.503 7.145)" />
        </G>
      </G>
    </Svg>
  );
};

export default SparklingModalities;
