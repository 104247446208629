import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import styled, { EmotionStyle, useEmotionTheme } from '../../core/styled';
import { TimeslotsCalendar, Input, View, TextInput } from '../index';
import { webOnlyStyle } from '../../core/styleHelpers';
import { A11yState } from '../../hooks/a11yContextProvider';

const CalendarWrapper = styled(View)<{ colors: A11yState['colors']; arrowPosition?: number }>`
  ${({ colors, arrowPosition = 73 }) => `
  top: 10px;
  left: -31px;
  padding: 23px 0;
  border-style: solid;
  border-width: 0.5px;
  border-color: ${colors.periwinkleGrey};
  border-radius: 5px;
  position: absolute;
  background: ${colors.white};
  &:before {
    content: '';
    position: absolute;
    top: -11px;
    left: ${arrowPosition}px;
    border-style: solid;
    border-width: 5px 5px;
    border-color: transparent transparent ${colors.periwinkleGrey} transparent;
    display: block;
    width: 0;
    z-index: 0;
  }
  &:after {
    content: '';
    position: absolute;
    top: -10px;
    left: ${arrowPosition}px;
    border-style: solid;
    border-width: 5px 5px;
    border-color: transparent transparent ${colors.white} transparent;
    display: block;
    width: 0;
    z-index: 0;
  }`}
`;

type InputComponentType = 'Input' | 'TextInput';

const StyledInput = (Component: typeof Input | typeof TextInput) =>
  styled(Component)<{
    disabled?: boolean;
    isError?: boolean;
    inputComponentType?: InputComponentType;
    inputStyles?: EmotionStyle;
  }>(({ disabled, isError, inputComponentType, inputStyles, theme: { colors } }) => {
    return {
      ...(isError ? { borderColor: colors.permaDebianRed } : {}),
      height: 50,
      backgroundColor: disabled ? colors.permaLinkWaterGrey : undefined,
      borderRadius: inputComponentType === 'Input' ? 10 : 0,
      borderStyle: 'solid',
      borderColor: isError ? colors.red : colors.periwinkleGrey,
      borderWidth: 0.5,
      color: disabled ? colors.periwinkleGreyText : undefined,
      padding: 20,
      ...webOnlyStyle({
        boxSizing: 'border-box',
        ':focus': {
          outline: 'none',
          caretColor: colors.green,
        },
        '::placeholder': {
          color: colors.placeholderGrey,
        },
      }),
      ...inputStyles,
    };
  });

export interface Props {
  inputComponentType?: InputComponentType;
  minDate?: moment.Moment;
  maxDate?: moment.Moment;
  currentValue: moment.Moment | null;
  handleInputValueChange: (date: moment.Moment | null) => void;
  calendarWrapperStyles?: EmotionStyle;
  placeholder?: string;
  format?: string;
  isError?: boolean;
  disabled?: boolean;
  inputStyles?: EmotionStyle;
  primaryColor?: string;
  secondaryColor?: string;
  calendarDayWidth?: number;
  arrowPosition?: number;
}

const DatePickerInput: FunctionComponent<Props> = ({
  inputComponentType = 'Input',
  minDate,
  maxDate,
  currentValue,
  handleInputValueChange,
  calendarWrapperStyles = {},
  placeholder,
  format = 'MM/DD/YYYY',
  isError = false,
  disabled = false,
  inputStyles,
  primaryColor,
  secondaryColor,
  calendarDayWidth,
  arrowPosition,
}) => {
  const [showCalendar, setShowCalendar] = useState(false);

  const calendarWrapperRef = useRef<HTMLInputElement>(null);
  const onDateChange = (value: moment.Moment | null) => {
    setShowCalendar(false);
    handleInputValueChange(value);
  };
  const { colors } = useEmotionTheme();

  const StyledInputWithComponentType = StyledInput(
    inputComponentType === 'Input' ? Input : TextInput
  );

  useEffect(() => {
    function handleClickOutsideOfCalendar(event) {
      if (calendarWrapperRef?.current && !calendarWrapperRef.current.contains(event.target)) {
        setShowCalendar(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutsideOfCalendar);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideOfCalendar);
    };
  }, []);

  return (
    <>
      <View onClick={() => setShowCalendar(true)}>
        <StyledInputWithComponentType
          placeHolderStyle={{ ...(isError ? { color: colors.permaDebianRed } : {}) }}
          isError={isError}
          placeholder={placeholder}
          inputComponentType={inputComponentType}
          value={moment.isMoment(currentValue) ? currentValue.format(format) : ''}
          disabled={disabled}
          inputStyles={inputStyles}
        />
      </View>

      {showCalendar && !disabled && (
        <View
          style={{
            position: 'relative',
          }}
        >
          <CalendarWrapper
            arrowPosition={arrowPosition}
            style={calendarWrapperStyles}
            colors={colors}
            ref={calendarWrapperRef}
          >
            <TimeslotsCalendar
              containerStyle={{
                overflowX: 'hidden',
                alignSelf: 'center',
              }}
              minDate={minDate}
              maxDate={maxDate}
              date={moment.isMoment(currentValue) ? currentValue : moment()}
              onDateChange={onDateChange}
              initialVisibleMonth={currentValue ? () => currentValue : null}
              primaryColor={primaryColor}
              secondaryColor={secondaryColor}
              calendarDayWidth={calendarDayWidth}
            />
          </CalendarWrapper>
        </View>
      )}
    </>
  );
};

export default DatePickerInput;
