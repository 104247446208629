// TEMPORARILY USED IN InRoomScheduling
import { useReducer, useCallback } from 'react';
import { accountDetailsReducer, State, initialState } from '../reducers/accountDetails';

export default function useAccountDetails(): [
  State,
  {
    dispatchClearError: () => void;
    getPaymentDetails: () => void;
    updatePaymentDetails: (cardToken: string, isDelayed?: boolean) => void;
  }
] {
  const [state, dispatch] = useReducer(accountDetailsReducer, initialState);

  function dispatchClearError() {
    dispatch({
      type: 'clearError',
    });
  }

  function getPaymentDetails(): void {
    throw new Error('useAccountDetails.getPaymentDetails Should not be called on therapist-web');
  }

  function updatePaymentDetails(cardToken: string, isDelayed = false): void {
    throw new Error('useAccountDetails.updatePaymentDetails Should not be called on therapist-web');
  }

  return [
    state,
    {
      dispatchClearError: useCallback(dispatchClearError, []),
      getPaymentDetails: useCallback(getPaymentDetails, []),
      updatePaymentDetails: useCallback(updatePaymentDetails, []),
    },
  ];
}
