import Svg, { Path, G, Circle } from 'svgs';
import { useEmotionTheme } from '../../core/styled';

const SchedulingCancellationIcon = ({
  color,
  width = 74,
  height = 79,
}: {
  color?: string;
  width?: number;
  height?: number;
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'scheduling cancellation';
  return (
    <Svg width={width} height={height} title={titleText} aria-label={titleText} viewBox="0 0 74 79">
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd" transform="translate(10,5)">
        <Path
          strokeLinecap="round"
          d="M51.898 15.953v37.362a5.724 5.724 0 01-1.682 4.06 5.724 5.724 0 01-4.06 1.682H7.71a5.724 5.724 0 01-4.06-1.682 5.724 5.724 0 01-1.682-4.06V15.953c0-1.586.643-3.021 1.682-4.06 1.446-1.447 3.66-2.125 5.98-1.37l.141-6.604a1.928 1.928 0 01.566-1.385c.35-.35.834-.566 1.368-.566.534 0 1.017.216 1.367.566.35.35.566.834.566 1.367v6.31h26.59v-6.31c0-.533.216-1.017.566-1.367.35-.35.834-.566 1.368-.566.534 0 1.017.216 1.367.566.35.35.566.834.566 1.367v6.617c2.39-.745 4.642-.076 6.108 1.373a5.687 5.687 0 011.695 4.062z"
          stroke={color || colors.loblollyGrey}
          strokeWidth={4}
        />
        <Path fill={colors.white} d="M7 12.2H46V17.2H7z" />
        <G transform="translate(31.756 33.268)">
          <Circle fill={colors.trueBlack} cx={15.1219512} cy={15.1219512} r={15.1219512} />
          <Circle
            stroke={colors.white}
            strokeWidth={2.26829268}
            fill={color || colors.loblollyGrey}
            cx={15.1219512}
            cy={15.1219512}
            r={16.2560976}
          />
        </G>
        <G fill={colors.white} fillRule="nonzero">
          <Path
            d="M1.099 10.987a1.097 1.097 0 01-.777-1.875l8.79-8.79a1.097 1.097 0 111.553 1.554l-8.79 8.79a1.096 1.096 0 01-.776.321z"
            transform="translate(41.207 42.72)"
          />
          <Path
            d="M9.889 10.987c-.282 0-.563-.107-.777-.322l-8.79-8.79A1.097 1.097 0 111.876.323l8.79 8.79a1.097 1.097 0 01-.777 1.875z"
            transform="translate(41.207 42.72)"
          />
        </G>
      </G>
    </Svg>
  );
};

export default SchedulingCancellationIcon;
