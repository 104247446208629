import { useLayoutEffect, useState, useRef, useMemo, RefObject } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import { getWidthBreakpoints, WindowWidthValues } from './useWindowWidth';

interface Size extends Omit<WindowWidthValues, 'width' | 'height'> {
  width: number | undefined;
  height: number | undefined;
}

export default function useComponentSize<T extends HTMLElement = HTMLElement>(
  ...deps: unknown[]
): [RefObject<T>, Size] {
  // `defaultRef` Has to be non-conditionally declared here whether or not it'll
  // be used as that's how hooks work.
  // @see https://reactjs.org/docs/hooks-rules.html#explanation
  const ref = useRef<T>(null);
  const [size, setSize] = useState<Size>({
    width: undefined,
    height: undefined,
    ...getWidthBreakpoints(0),
  });

  // Using a ref to track the previous width / height to avoid unnecessary renders
  const previousSizeRef = useRef<Size>({
    width: undefined,
    height: undefined,
    ...getWidthBreakpoints(0),
  });

  function measure(entry: ResizeObserverEntry, prevSize?: Size): void {
    // `Math.round` is in line with how CSS resolves sub-pixel values
    if (entry.contentRect) {
      const width = Math.round(entry.contentRect.width);
      const height = Math.round(entry.contentRect.height);
      if (!prevSize) setSize({ ...getWidthBreakpoints(width), width, height });
      else if (prevSize.width !== width || prevSize.height !== height) {
        // TODO: @Luis - Figure out if this is necessary
        // eslint-disable-next-line no-param-reassign
        prevSize.width = width;
        // eslint-disable-next-line no-param-reassign
        prevSize.height = height;
        setSize({ ...getWidthBreakpoints(width), width, height });
      }
    } else {
      setSize({ ...getWidthBreakpoints(0), width: 0, height: 0 });
    }
  }

  useLayoutEffect(() => {
    if (!ref.current) {
      return undefined;
    }

    const element = ref.current;

    const resizeObserver = new ResizeObserver((entries) => {
      if (!Array.isArray(entries)) {
        return;
      }
      // Since we only observe the one element, we don't need to loop over the
      // array
      if (!entries.length) {
        return;
      }
      const [entry] = entries;
      measure(entry, previousSizeRef.current);
    });

    const timerID = window.requestAnimationFrame(() => {
      resizeObserver.observe(element);
    });

    return () => {
      window.cancelAnimationFrame(timerID);
      resizeObserver.unobserve(element);
    };

    // These are external dependencies that we cannot statically type
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return useMemo(() => [ref, size], [size]);
}
