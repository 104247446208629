import * as React from 'react';

import { SelectRoundedFormik } from '@talkspace/react-toolkit';

const SignupSelect = (props: React.ComponentProps<typeof SelectRoundedFormik>) => (
  <SelectRoundedFormik
    {...props}
    styles={{
      singleValue: (provided) => {
        return {
          ...provided,
          fontWeight: 400,
          fontSize: 16,
        };
      },
    }}
  />
);

export default SignupSelect;
