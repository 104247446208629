import { Breadcrumbs } from '@talkspace/react-toolkit';
import { SignupStep, SignupStepEnum } from '../types';

export const SignupSteps: SignupStep[] = [
  {
    id: SignupStepEnum.personalDetails,
    name: 'Personal',
    title: 'Personal details',
  },
  {
    id: SignupStepEnum.clinicalInfo,
    name: 'Clinical',
    title: 'Clinical information',
  },
  {
    id: SignupStepEnum.credentials,
    name: 'Create account',
    title: 'Create your account',
  },
];

const items = SignupSteps.map((step) => {
  return { label: step.name };
});

const SignupBreadcrumbs = ({ activeStep }: { activeStep: SignupStepEnum }) => (
  <Breadcrumbs activeIndex={activeStep} items={items} />
);

export default SignupBreadcrumbs;
