import Svg, { G, Path, Rect } from 'svgs';
import { useEmotionTheme } from '../../core/styled';

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

const Visa = ({ width = 33, height = 21, color, ...props }: Props) => {
  const { colors } = useEmotionTheme();
  const titleText = 'Visa card';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox={`0 0 ${width} ${height}`}
      {...props}
    >
      <G fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <Path
          fill={colors.white}
          stroke={colors.periwinkleGrey}
          strokeWidth="0.5"
          d="M638.75 409.003c0-5.661 4.595-10.253 10.258-10.253h480.984c5.664 0 10.258 4.593 10.258 10.253v92.994c0 5.661-4.595 10.253-10.258 10.253H649.008c-5.664 0-10.258-4.593-10.258-10.253v-92.994z"
          transform="translate(-664 -456)"
        />
        <G transform="translate(-664 -456) translate(664 456)">
          <Rect width="33" height="21" x="0" y="0.5" fill={color || colors.green} rx="2.92" />
          <G fill="#FCFCFC" transform="translate(5 7.667)">
            <Path d="M21.378 6.162c0-.19.16-.346.356-.346a.35.35 0 01.355.346.35.35 0 01-.355.346.35.35 0 01-.356-.346zm.356.263c.15 0 .27-.118.27-.263a.266.266 0 00-.27-.263.266.266 0 00-.27.263c0 .145.12.263.27.263zm-.048-.111h-.073V6.01h.132c.028 0 .055 0 .08.015.025.018.04.046.04.076 0 .035-.021.067-.055.079l.058.134h-.081l-.048-.12h-.053v.12zm0-.173h.04c.015 0 .031.002.044-.005a.042.042 0 00.019-.035.038.038 0 00-.018-.031c-.013-.007-.033-.006-.047-.006h-.038v.077zM19.825.151l1.376 6.378h-1.578l-.202-.947h-2.185l-.358.947h-1.792L17.649.627s.156-.476.794-.476h1.382zm-1.19 1.735l-.904 2.387h1.412l-.509-2.387zM15.22 1.685l.245-1.37S14.708.037 13.92.037c-.853 0-2.879.36-2.879 2.112 0 1.648 2.378 1.668 2.378 2.533s-2.132.711-2.837.165l-.255 1.432s.767.36 1.94.36 2.944-.587 2.944-2.183c0-1.658-2.4-1.813-2.4-2.534 0-.721 1.675-.628 2.41-.237" />
            <Path d="M9.26667216 6.52934872L7.54772271 6.52934872 8.62196091 0.151492381 10.3414715 0.151492381 9.26667216 6.52934872" />
            <Path d="M6.101.151L4.463 4.538l-.194-.944L3.69.726S3.62.15 2.875.15H.165L.135.26s.829.167 1.798.73l1.494 5.54h1.79L7.953.151h-1.85" />
            <Path d="M4.269 3.594L3.69.726S3.62.15 2.875.15H.165L.135.26S1.436.52 2.685 1.496C3.88 2.43 4.27 3.594 4.27 3.594" />
          </G>
        </G>
      </G>
    </Svg>
  );
};

export default Visa;
