import { FunctionComponent, useState, useEffect, forwardRef } from 'react';
import { TherapistReviewData } from 'ts-frontend/types';
import { webOnlyStyle } from '../../core/styleHelpers';
import styled from '../../core/styled';
import useA11y from './TherapistReviewForm.a11y';
import { useShareForwardedRef } from '../../hooks/a11yHelper';
import View from '../View';
import TextArea from '../TextArea';
import { Tiny } from '../Typography';
import RatingStars from '../RatingStars';
import commonStyles from '../../constants/commonStyles';
import { Button, spacing } from '../..';

const { space150, space300 } = spacing;

const { crossBrowserFocusRing } = commonStyles;

const Wrapper = styled(View)<{ isShown: boolean }>(({ isShown }) => {
  return {
    width: 320,
    marginTop: 25,
    display: isShown ? undefined : 'none',
    ...webOnlyStyle({
      opacity: isShown ? 1 : 0,
      transitionProperty: 'opacity',
      transitionDuration: '0.5s',
    }),
  };
});

interface ReviewTextAreaProps {
  isError?: boolean;
}

const ReviewTextAreaComponent = styled(TextArea)<{
  isError?: boolean;
  showFocusRing?: boolean;
}>(({ isError, showFocusRing, theme: { colors } }) => {
  return {
    height: 130,
    maxHeight: 130,
    width: '100%',
    borderRadius: 10,
    borderStyle: 'solid',
    borderColor: isError ? colors.red : colors.periwinkleGrey,
    borderWidth: 1,
    padding: 20,
    resize: 'none',
    ...webOnlyStyle({
      boxSizing: 'border-box',
      ':focus': showFocusRing
        ? {
            ...crossBrowserFocusRing,
            caretColor: colors.green,
          }
        : {
            outline: 'none',
            caretColor: colors.green,
          },
      '&:hover:focus': {
        outline: 'none',
      },
      '::placeholder': {
        color: colors.placeholderGrey,
        fontSize: 16,
      },
    }),
  };
});

const ReviewTextArea = forwardRef<
  HTMLTextAreaElement,
  ReviewTextAreaProps & React.ComponentProps<typeof ReviewTextAreaComponent>
>((props, ref) => {
  const { isError, ...otherProps } = props;
  const textAreaRef = useShareForwardedRef(ref);
  return <ReviewTextAreaComponent ref={textAreaRef} isError={isError} {...otherProps} />;
});

const Label = styled(Tiny)(({ theme: { colors } }) => {
  return { textAlign: 'left', padding: 4, color: colors.slateGrey };
});

const Error = styled(Label)(({ theme: { colors } }) => {
  return { color: colors.red };
});

interface Props {
  therapistId: number;
  submitButtonText: string;
  handleOnSubmit: (reviewPayload: TherapistReviewData) => void;
  ratingSource?: string;
  isReviewTextRequired?: boolean;
  formerRating?: number;
  formerReview?: string;
  roomID?: number;
  disabled?: boolean;
  isLoading?: boolean;
  dataQa?: string;
}

const TherapistReviewForm: FunctionComponent<Props> = ({
  therapistId,
  submitButtonText,
  handleOnSubmit,
  ratingSource = 'review therapist',
  isReviewTextRequired = true,
  formerRating = 0,
  formerReview = '',
  roomID,
  disabled,
  isLoading,
  dataQa,
}) => {
  const [reviewTextError, setReviewTextError] = useState('');
  const [showReviewInput, setShowReviewInput] = useState(false);
  const [rating, setRating] = useState(0);
  const [reviewText, setReviewText] = useState('');

  const {
    reviewTextErrorID,
    reviewTextRef,
    onTextAreaClick,
    onTextAreaFocus,
    onTextAreaBlur,
    isTextAreaClicked,
    isTextAreaFocused,
    setShouldFocusReviewText,
  } = useA11y();

  const handleOnRatingStarClick = (value: number) => {
    setShowReviewInput(true);
    setRating(value);
  };

  const handleReviewTextChange = (value: string) => {
    setReviewTextError('');
    setReviewText(value);
  };

  const handleSubmitPress = () => {
    let isValid = true;
    if (!reviewText && isReviewTextRequired) {
      setReviewTextError('Please leave your review.');
      isValid = false;
      setShouldFocusReviewText(true);
    }
    if (isValid) {
      handleOnSubmit({
        therapistId,
        reviewText,
        ratingValue: rating,
        roomId: roomID,
        ratingSource,
      });
    }
  };

  useEffect(() => {
    setRating(formerRating);
  }, [formerRating]);

  useEffect(() => {
    setReviewText(formerReview);
  }, [formerReview]);

  useEffect(() => {
    if (formerRating > 0) {
      setShowReviewInput(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formerRating]);

  return (
    <View style={{ textAlign: 'center' }}>
      <RatingStars
        dataQa={dataQa}
        initialRating={rating}
        getRatingValue={handleOnRatingStarClick}
      />
      <Wrapper align="center" isShown={showReviewInput}>
        <ReviewTextArea
          ref={reviewTextRef}
          value={reviewText}
          aria-label="care provider review"
          placeholder="Leave your review"
          onChangeText={handleReviewTextChange}
          onClick={onTextAreaClick}
          onBlur={onTextAreaBlur}
          onFocus={onTextAreaFocus}
          showFocusRing={isTextAreaFocused && !isTextAreaClicked}
          multiline
          isError={!!reviewTextError}
          aria-describedby={reviewTextError ? reviewTextErrorID : undefined}
          aria-required="true"
          aria-invalid={(reviewTextError && 'true') || 'false'}
          dataQa={`${dataQa}TextArea`}
        />
        {reviewTextError ? <Error id={reviewTextErrorID}>{reviewTextError}</Error> : null}
        <Button
          text={submitButtonText}
          disabled={disabled}
          isLoading={isLoading}
          onPress={handleSubmitPress}
          style={{ marginTop: space300, marginBottom: space150 }}
          dataQa={`${dataQa}SubmitButton`}
        />
      </Wrapper>
    </View>
  );
};

export default TherapistReviewForm;
