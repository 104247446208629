import throttle from 'lodash/throttle';
import { MouseEvent, useCallback, useEffect, useMemo, useState, TouchEvent } from 'react';
import { useWindowWidthState } from './windowWidthContext';

interface EdgesObject {
  top: boolean;
  right: boolean;
  bottom: boolean;
  left: boolean;
}

interface OptionsObject {
  edges: Partial<EdgesObject>;
  threshold: number;
  maxTimesToDisplay: number;
  eventThrottle: number;
}

const defaultOptions = {
  edges: { top: true, right: false, bottom: false, left: false },
  threshold: 20,
  maxTimesToDisplay: 1,
  eventThrottle: 200,
};

export default function useDetectExitIntent(options?: Partial<OptionsObject>) {
  const [isExiting, setIsExiting] = useState<boolean>(false);
  const [timesDisplayed, setTimesDisplayed] = useState<number>(0);
  const { isMobile } = useWindowWidthState();

  const config = useMemo(() => {
    return {
      ...defaultOptions,
      ...options,
      edges: { ...defaultOptions.edges, ...(options && options.edges) },
    };
  }, [options]);

  const viewportWidth = useCallback(
    () => Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0),
    []
  );

  const viewportHeight = useCallback(
    () => Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0),
    []
  );

  const shouldDisplay = useCallback(
    (x: number, y: number) => {
      const incrementTimesDisplayed = () => setTimesDisplayed(timesDisplayed + 1);
      if (config.edges.top && y <= config.threshold && timesDisplayed < config.maxTimesToDisplay) {
        incrementTimesDisplayed();
        return 'top';
      }

      if (
        config.edges.right &&
        x >= viewportWidth() - config.threshold &&
        timesDisplayed < config.maxTimesToDisplay
      ) {
        incrementTimesDisplayed();
        return 'right';
      }

      if (
        config.edges.bottom &&
        y >= viewportHeight() - config.threshold &&
        timesDisplayed < config.maxTimesToDisplay
      ) {
        incrementTimesDisplayed();
        return 'bottom';
      }

      if (config.edges.left && x <= config.threshold && timesDisplayed < config.maxTimesToDisplay) {
        incrementTimesDisplayed();
        return 'left';
      }

      return false;
    },
    [
      config.edges.bottom,
      config.edges.left,
      config.edges.right,
      config.edges.top,
      config.maxTimesToDisplay,
      config.threshold,
      timesDisplayed,
      viewportHeight,
      viewportWidth,
    ]
  );

  const eventListeners = useMemo(() => new Map(), []);

  const removeEvent = useCallback(
    (key: string) => {
      const { eventName, callback } = eventListeners.get(key);
      document.removeEventListener(eventName, callback);
      eventListeners.delete(key);
    },
    [eventListeners]
  );

  const removeEvents = useCallback(() => {
    eventListeners.forEach((_, key) => removeEvent(key));
  }, [eventListeners, removeEvent]);

  const mouseDidMove = useCallback(
    (event: MouseEvent<Element> | TouchEvent<Element>) => {
      let { clientX, clientY } = event as MouseEvent;
      if (isMobile) {
        const { touches, changedTouches } = event as TouchEvent;
        ({ pageX: clientX, pageY: clientY } = touches[0] || changedTouches[0]);
      }
      const side = shouldDisplay(clientX, clientY);
      if (side) {
        setIsExiting(true);
        if (timesDisplayed >= config.maxTimesToDisplay) {
          removeEvents();
        }
      }
    },
    [config, removeEvents, shouldDisplay, timesDisplayed, isMobile]
  );

  const addEvent = useCallback(
    (eventName: keyof DocumentEventMap, callback) => {
      document.addEventListener(eventName, callback, false);
      eventListeners.set(`document:${eventName}`, {
        eventName,
        callback,
      });
    },
    [eventListeners]
  );

  const detectExitIntent = useCallback(() => {
    if (isMobile) {
      addEvent('touchmove', throttle(mouseDidMove, config.eventThrottle));
    } else {
      addEvent('mousemove', throttle(mouseDidMove, config.eventThrottle));
    }
    return removeEvents;
  }, [addEvent, config.eventThrottle, mouseDidMove, removeEvents, isMobile]);

  const resetExitIntent = useCallback(() => setIsExiting(false), []);

  useEffect(() => {
    const removeExitIntent = detectExitIntent();

    return () => {
      removeExitIntent();
    };
  }, [detectExitIntent]);

  return useMemo(() => {
    return {
      isExiting,
      resetExitIntent,
    };
  }, [isExiting, resetExitIntent]);
}
