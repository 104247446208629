import { useQuery } from 'react-query';
import Analytics from 'components/PaymentReports/Analytics';
import apiWrapper from '../../utils/apiWrapper';
import apiHelper from '../../utils/api';

export type monthlyBonusParams = {
  therapistID: number;
  year: number;
  month: number;
  onSuccess: () => void;
};

export interface MonthlyBonusData {
  monthlyBonus: MonthlyBonus;
  monthlyBonusMapping: Array<MonthlyBonusMappingItem>;
}

export interface MonthlyBonusMappingItem {
  bonusAmount: number;
  bonusTotalHours: number;
  percentage: number;
  tooltipText?: string;
  milestoneComponent?: Element;
}

export interface MonthlyBonus {
  updatedAt?: Date;
  basePay: number;
  bonus: number;
  earningsBreakdown: EarningsBreakdown;
  explanations: Explanations;
  graphBoxDefaultOffsetPercentage: number;
  hoursAchieved: number;
  hoursAchievedPercentage: number;
  hoursAwayFromNextMilestone: number;
  hoursPredicted: number;
  hoursPredictedPercentage: number;
  isCurrentDateInFirstSevenDaysOfTheMonth: boolean;
  isFetchedForCurrentMonth: boolean;
  milestoneAchievedIndex: number;
  milestoneAchievedPercentage: number;
  milestonePredictedIndex: number;
  milestonePredictedPercentage: number;
}

export interface EarningsBreakdown {
  messagingSession: MessagingSession;
  timeInLiveSession: number;
}

export interface Explanations {
  accumulated?: string;
  earned?: string;
  awayFromEarning?: string;
  estimatedBonus?: string;
  estimatedHours?: string;
}

export interface MessagingSession {
  wordsSent: number;
  wordsSentToHours: number;
  wordsReceived: number;
  wordsReceivedToHours: number;
  mediaSent: number;
  mediaReceived: number;
}

const fetchMonthlyBonus = (params: monthlyBonusParams) => async () => {
  const { therapistID, year, month } = params;
  const { data } = await apiWrapper.post(
    `${apiHelper().apiEndpoint}/v2/therapists/${therapistID}/monthly-bonus`,
    { year, month }
  );
  Analytics.trackBonusVizMonthlyBonusFetchedEvent({
    selectedYear: year,
    selectedMonth: month + 1,
  });

  return data;
};

const useQueryMonthlyBonus = (params: monthlyBonusParams) =>
  useQuery<MonthlyBonusData, Error>({
    queryKey: ['monthlyBonus', params.year, params.month],
    queryFn: fetchMonthlyBonus(params),
    onSuccess: () => params.onSuccess(),
    cacheTime: Infinity,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
  });

export default useQueryMonthlyBonus;
