import { FunctionComponent, useEffect } from 'react';
import {
  View,
  Button,
  Large,
  Standard,
  BaseButton,
  Modal,
  ExtraHuge,
} from '@talkspace/react-toolkit';
import { connect } from 'react-redux';

import { useCloseModal } from '@/utils/ModalsContextProvider';
import { useVideoCallActions, useVideoCallState } from '../../hooks/videoCallContext';
import { AppState } from '../../redux/chatStore';
import { VideoCallEvent } from '../../types/videoCallTypes';

interface Props {
  roomID: number;
  videoCallID?: number;
}

const EndLiveChatModal: FunctionComponent<Props> = ({ roomID, videoCallID }) => {
  const closeModal = useCloseModal();
  const { createVideoCallEventAction } = useVideoCallActions();
  const { videoCallEnded, isLoading } = useVideoCallState();

  const handleEndSession = () => {
    if (roomID && videoCallID) {
      createVideoCallEventAction(roomID, videoCallID, VideoCallEvent.sessionEnded);
    }
  };

  useEffect(() => {
    if (videoCallEnded) closeModal();
  }, [videoCallEnded, closeModal]);

  return (
    <Modal.Panel onBackdropPress={() => closeModal()}>
      <View align="center">
        <ExtraHuge>End live chat session</ExtraHuge>
        <Large variant="largeDarkGrey" style={{ marginTop: 11, marginBottom: 45 }}>
          Are you sure you’re ready to <br />
          end this live chat session?
        </Large>

        <View>
          <Button isLoading={isLoading} onPress={handleEndSession}>
            End Session
          </Button>
          <BaseButton style={{ height: 50 }} onPress={() => closeModal()}>
            <Standard variant="standardDarkGrey">Close</Standard>
          </BaseButton>
        </View>
      </View>
    </Modal.Panel>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    roomID: state.chat.roomID,
    videoCallID: state?.chat?.activeSession?.videoCallID,
  };
};

export default connect(mapStateToProps)(EndLiveChatModal);
