import Svg, { Path } from 'svgs';
import { useEmotionTheme } from '../../core/styled';
import type { EmotionStyle } from '../../core/styled';

export interface BackArrowProps {
  width?: number;
  height?: number;
  color?: string;
  style?: EmotionStyle;
}

const BackArrow = ({ color, width = 24, height = 24, ...otherProps }: BackArrowProps) => {
  const { colors } = useEmotionTheme();
  const titleText = 'Back arrow';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 24 24"
      {...otherProps}
    >
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.46155 11.538L14.8439 2.80644C15.347 2.2823 16.1853 2.2823 16.6885 2.80644C17.1634 3.30118 17.1634 4.08253 16.6885 4.57727L10.0062 11.538L16.6885 18.4987C17.1634 18.9935 17.1634 19.7748 16.6885 20.2696C16.1853 20.7937 15.347 20.7937 14.8439 20.2696L6.46155 11.538Z"
        fill={color || colors.accessibilityGreenDark}
      />
    </Svg>
  );
};

export default BackArrow;
