import { SessionModality } from 'ts-frontend/types';
import * as React from 'react';

import VideoCalendar from './VideoCalendar';
import MessagingCalendar from './MessagingCalendar';
import ChatCalendar from './ChatCalendar';
import AudioCalendar from './AudioCalendar';
import { useEmotionTheme, EmotionStyle } from '../../core/styled';

interface LiveSessionCalendarIconProp {
  color?: string;
  iconColor?: string;
  width?: number;
  height?: number;
  style?: EmotionStyle;
  modality?: SessionModality;
}

const LiveSessionCalendarIcon: React.VFC<LiveSessionCalendarIconProp> = ({
  color = undefined,
  width = 28,
  height = 30,
  style = {},
  modality = 'video',
}) => {
  const {
    colors: { purple },
  } = useEmotionTheme();
  const backgroundColor = color || purple;

  switch (modality) {
    case 'video':
      return (
        <VideoCalendar
          aria-hidden="true"
          width={width}
          height={height}
          color={backgroundColor}
          style={style}
        />
      );
    case 'chat':
      return (
        <ChatCalendar
          aria-hidden="true"
          width={width}
          height={height}
          style={style}
          color={backgroundColor}
        />
      );
    case 'audio':
      return (
        <AudioCalendar
          aria-hidden="true"
          width={width}
          height={height}
          color={backgroundColor}
          style={style}
        />
      );
    case 'messaging':
      return (
        <MessagingCalendar
          aria-hidden="true"
          width={width}
          height={height}
          color={backgroundColor}
          style={style}
        />
      );

    default:
      return (
        <VideoCalendar
          aria-hidden="true"
          width={width}
          height={height}
          color={backgroundColor}
          style={style}
        />
      );
  }
};

export default LiveSessionCalendarIcon;
