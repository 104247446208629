import Svg, { G, Ellipse, Text, TSpan } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

const QuestionMark = ({
  isHover,
  color,
  size = 22,
  ...otherProps
}: {
  isHover?: boolean;
  color?: string;
  size?: number;
}) => {
  const { colors } = useEmotionTheme();
  const fillColor = isHover ? colors.permaViridianGreen : colors.green;
  return (
    <Svg width={size} height={size} viewBox="0 0 15 15" {...otherProps}>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Ellipse
          fill={color || fillColor}
          cx={7.46251}
          cy={7.26000687}
          rx={7.13942323}
          ry={7.15000015}
        />
        <Text fontFamily="Roboto-Black, Roboto" fontSize={9.80000019} fontWeight={700} fill="#FFF">
          <TSpan x={5} y={10.7000122}>
            ?
          </TSpan>
        </Text>
      </G>
    </Svg>
  );
};

export default QuestionMark;
