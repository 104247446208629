import { useEffect, useCallback, useRef } from 'react';
import {
  PanelGroup,
  Row,
  Col,
  Panel,
  Button,
  Table,
  Tooltip,
  OverlayTrigger,
  FormGroup,
  FormControl,
} from 'react-bootstrap';
import { useLocation } from 'react-router';
import { toast, ToastContainer } from 'react-toastify';
import moment from 'moment-timezone';
import {
  View,
  SingleDatePickerWithInput,
  useObjectState,
  Tiny,
  COLORS,
  Small,
  Link,
  Large,
} from '@talkspace/react-toolkit';
import { yesNoOptions } from 'utils/optionsHelpers';
import apiWrapper from '../../../utils/apiWrapper';
import apiHelper from '../../../utils/api';
import { getUserData } from '../../../utils/token';
import 'react-toastify/dist/ReactToastify.min.css';
import '../Account.css';

const ClinicalInfo = (props) => {
  const { setIsSectionLoaded, allSectionsLoaded, therapistType } = props;
  const [state, setState] = useObjectState({
    title: 'Clinical Information',
    isEdit: false,
    isSaveDisabled: true,
    isDatePickerDisabled: true,
    isEditVisible: true,
    isSaveVisible: false,
    previousDate: '',
    firstClinicalLicenseDate: '',
    dateFocused: false,
    age: '',
    dateOfBirth: '',
    mentor: '',
    phase: '',
    professionalDegree: '',
    professionalLevel: '',
    npiNumber: '',
    caqhNumber: '',
    institutionAssociatedProfessionalDegree: '',
    licenseInfo: [],
    isLoaded: false,
  });

  const scrollCompleteRef = useRef(false);
  const sectionHeaderRef = useRef(null);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const scrollTo = searchParams.get('scrollTo');

  useEffect(() => {
    if (scrollTo === 'clinicalInformation' && allSectionsLoaded && !scrollCompleteRef.current) {
      sectionHeaderRef.current.scrollIntoView({ behavior: 'smooth' });
      scrollCompleteRef.current = true;
    }
  }, [scrollTo, allSectionsLoaded]);

  const onDateChange = (event) => {
    if (event) {
      setState({
        firstClinicalLicenseDate: event,
        previousDate: state.firstClinicalLicenseDate,
        isSaveDisabled: false,
      });
    }
  };

  const onAccessParticipationChange = (event) => {
    if (event.target.value !== 'Select')
      setState({
        accessInitiativeParticipation: event.target.value,
        isSaveDisabled: false,
      });
  };

  const onDateFocusChange = (event) => {
    setState({ dateFocused: event.focused });
  };

  const getClinicalInfo = useCallback(() => {
    const userId = getUserData().id;
    return apiWrapper
      .get(`${apiHelper().apiEndpoint}/api/v1/therapist/${userId}/clinical-info`)
      .then((response) => {
        const firstClinicalLicenseDate = response.data.data.firstClinicalLicenseDate
          ? moment.utc(response.data.data.firstClinicalLicenseDate)
          : '';
        const dateOfBirth = response.data.data.dateOfBirth
          ? moment.utc(response.data.data.dateOfBirth).format('YYYY-MM-DD')
          : '';
        setState({
          firstClinicalLicenseDate,
          dateOfBirth,
          phase: response.data.data.phase,
          age: response.data.data.age,
          mentor: response.data.data.mentor,
          professionalLevel: response.data.data.professionalLevel,
          professionalDegree: response.data.data.professionalDegree,
          npiNumber: response.data.data.npiNumber,
          caqhNumber: response.data.data.caqhNumber,
          institutionAssociatedProfessionalDegree:
            response.data.data.institutionAssociatedProfessionalDegree,
          accessInitiativeParticipation: response.data.data.accessInitiativeParticipation,
        });
      })
      .catch(() => {
        toast(<div className="toaster toaster-error">Could not load clinical info!</div>, {
          autoClose: 3000,
        });
      });
  }, [setState]);

  const getLicenseInfo = useCallback(() => {
    const userId = getUserData().id;
    return apiWrapper
      .get(`${apiHelper().apiEndpoint}/api/v1/therapist/${userId}/license-info`)
      .then((response) => {
        setState({
          licenseInfo: response.data.data,
        });
      })
      .catch(() => {
        toast(<div className="toaster toaster-error">Could not load license info!</div>, {
          autoClose: 3000,
        });
      });
  }, [setState]);

  useEffect(() => {
    const fetchInitialData = async () => {
      await Promise.all([getLicenseInfo(), getClinicalInfo()]);
      setState({ isLoaded: true });
      setIsSectionLoaded({ clinicalInfo: true });
    };
    fetchInitialData();
  }, [setIsSectionLoaded, setState, getLicenseInfo, getClinicalInfo]);

  const saveForm = () => {
    const userId = getUserData().id;
    const payload = {
      firstClinicalLicenseDate: state.firstClinicalLicenseDate.format('YYYY-MM-DD'),
      accessInitiativeParticipation: state.accessInitiativeParticipation,
    };
    return apiWrapper
      .put(`${apiHelper().apiEndpoint}/api/v1/therapist/${userId}/clinical-info`, payload)
      .then((response) => {
        // send toast based on response.status
        toggleFormEdit();
      })
      .catch(() => {
        toast(<div className="toaster toaster-error">Could not load clinical info!</div>, {
          autoClose: 3000,
        });
      });
  };

  const toggleFormEdit = () => {
    const editBool = !state.isEdit;
    if (editBool) {
      // edit mode is on
      setState({
        isEdit: true,
        isDatePickerDisabled: false,
        isEditVisible: false,
        isCancelVisible: true,
        isSaveVisible: true,
      });
    } else {
      // edit mode is off
      getClinicalInfo();
      setState({
        isEdit: false,
        isDatePickerDisabled: true,
        isEditVisible: true,
        isCancelVisible: false,
        isSaveVisible: false,
        isSaveDisabled: true,
      });
    }
  };

  const today = moment();
  const tooltip = (
    <Tooltip id="NotAvailableTooltip" className="tooltip ts-tooltip ts-profile-text-white">
      The license information should always be relevant and correct. Contact customer support if any
      of the below information needs to be updated/corrected.
    </Tooltip>
  );

  return (
    <div id="clinical-info" className="clinical-info">
      <Col xs={12} className={`ts-panel-title ${state.isLoaded ? 'show-panel' : 'hidden-panel'}`}>
        <Col xs={5} sm={5} className="ts-font-black">
          <View ref={sectionHeaderRef}>{state.title} </View>
          <OverlayTrigger placement="top" trigger={['click', 'focus', 'hover']} overlay={tooltip}>
            <i className="fa fa-fw fa-question-circle fa-lg" />
          </OverlayTrigger>
        </Col>
        <Button
          className={`ts-edit-button pull-right ${state.isEditVisible ? '' : 'hidden'}`}
          onClick={toggleFormEdit}
        >
          Edit
        </Button>
        <Button
          className={`btn-ts-default btn-ts-green ts-profile-text-white pull-right save-btn ${
            state.isSaveVisible ? '' : 'hidden'
          }`}
          onClick={saveForm}
          disabled={state.isSaveDisabled}
        >
          Save Changes
        </Button>
        <Button
          className={`btn-ts-default ts-profile-btn-text-green pull-right cancel-btn ${
            state.isCancelVisible ? '' : 'hidden'
          }`}
          onClick={toggleFormEdit}
        >
          Cancel
        </Button>
      </Col>
      <Col xs={12}>
        <Panel className={`ts-my-account-panel ${state.isLoaded ? 'show-panel' : 'hidden-panel'}`}>
          <Row>
            <Col md={2} className="ts-profile-label">
              License
            </Col>
            <Col md={10} className="license-container">
              <PanelGroup className="visible-xs visible-sm hidden-md hidden-lg">
                {state.licenseInfo !== null
                  ? state.licenseInfo.map(function (row, index) {
                      return (
                        <Panel
                          className="clinical-info-panel"
                          key={index}
                          header={[
                            `Number ${row.number}`,
                            <i key={index} className="fa fa-caret-down pull-right" />,
                          ]}
                          eventKey={index}
                          collapsible
                          onClick={(e) => {
                            const list = e.target.querySelector('i').classList;
                            list.toggle('fa-caret-down');
                            list.toggle('fa-caret-up');
                          }}
                        >
                          {Object.keys(row).map(function (key, itemIndex) {
                            return (
                              <Row key={itemIndex}>
                                <Col xs={4} sm={4} className="ts-profile-sm-muted-label">
                                  {key}:
                                </Col>
                                <Col xs={6} sm={6} className="ts-profile-bio">
                                  {row[key]}
                                </Col>
                              </Row>
                            );
                          })}
                        </Panel>
                      );
                    })
                  : ''}
              </PanelGroup>
              <Table disabled className="clinical-info-table hidden-sm hidden-xs">
                <thead className="clinical-information-text">
                  <tr>
                    <th>Number</th>
                    <th>State</th>
                    <th>Country</th>
                    <th>Type</th>
                  </tr>
                </thead>
                <tbody className="clinical-information-text">
                  {state.licenseInfo !== null
                    ? state.licenseInfo.map(function (row, index) {
                        return (
                          <tr key={index}>
                            <td>{row.number}</td>
                            <td>{row.state}</td>
                            <td>{row.country}</td>
                            <td>{row.type}</td>
                          </tr>
                        );
                      })
                    : ''}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row className="form-row-container">
            <Col md={2} />
            <Col md={4}>
              <div className="ts-profile-label">First Clinical License Date</div>
              <SingleDatePickerWithInput
                onFocusChange={onDateFocusChange}
                focused={state.dateFocused}
                onDateChange={onDateChange}
                date={state.firstClinicalLicenseDate}
                placeholder="Select Date"
                disabled={state.isDatePickerDisabled}
                maxDate={today}
              />
              <p
                className="ts-text-invalid ts-text-margin"
                hidden={!!state.firstClinicalLicenseDate}
              >
                First Clinical License Date is required.
              </p>
            </Col>
            <Col md={3}>
              <div className="ts-profile-label">Date of Birth</div>
              <div className="clinical-information-text">{state.dateOfBirth}</div>
            </Col>
            <Col md={3}>
              <div className="ts-profile-label ">Professional Level</div>
              <div className="clinical-information-text">{state.professionalLevel}</div>
            </Col>
          </Row>
          <Row className="form-row-container">
            <Col md={2} />
            <Col md={4}>
              <div className="ts-profile-label">Professional Degree</div>
              <div className="clinical-information-text">{state.professionalDegree}</div>
              <div className="clinical-information-text">
                {state.institutionAssociatedProfessionalDegree}
              </div>
            </Col>
            <Col md={3}>
              <div className="ts-profile-label ">NPI Number Level</div>
              <div className="clinical-information-text">{state.npiNumber}</div>
            </Col>
          </Row>
          {therapistType === 'primary' && (
            <Row className="form-row-container" style={{ padding: '0px 15px' }}>
              <Large style={{ marginBottom: 10, marginTop: 30 }}>
                Do you want to participate in our Access Initiative?
              </Large>
              <Small style={{ marginBottom: 10 }}>
                As the demand for Behavioral Health increases, most states are enacting legislation
                that makes it easier to support patients across state lines. Select Yes to join us
                in helping with our
                <Link
                  target="_blank"
                  href="https://providers-help.talkspace.com/hc/en-us/articles/4430917286555"
                  style={{
                    color: COLORS.greenText,
                    fontWeight: 'bold',
                    marginRight: 0,
                  }}
                >
                  Access Initiative
                </Link>
                . Please note that if you opt out, it is your responsibility to ensure continuity of
                care for your clients.
              </Small>
              <Col md={4} style={{ padding: '0px' }}>
                <FormGroup controlId="accessInitiativeParticipation">
                  <FormControl
                    componentClass="select"
                    placeholder="Select"
                    onChange={onAccessParticipationChange}
                    value={state.accessInitiativeParticipation}
                    disabled={!state.isEdit}
                  >
                    <option>Select</option>
                    {yesNoOptions.map((option) => (
                      <option value={option.value} key={option.label}>
                        {option.label}
                      </option>
                    ))}
                  </FormControl>
                  <Tiny
                    style={{ color: COLORS.red, marginTop: 5 }}
                    className="ts-text-invalid ts-text-margin"
                    hidden={state.accessInitiativeParticipation !== null}
                  >
                    This field is required.
                  </Tiny>
                </FormGroup>
              </Col>
            </Row>
          )}
        </Panel>
      </Col>
      <ToastContainer closeButton={false} position={toast.POSITION.BOTTOM_RIGHT} hideProgressBar />
    </div>
  );
};

export default ClinicalInfo;
