import { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import InputMask from 'react-input-mask';
import commonStyles, { COLORS } from '../../constants/commonStyles';
import Lock from '../Svgs/Lock';
import ErrorIcon from '../Svgs/ErrorIcon';
import './textInputStyles.css';

const { regular } = commonStyles.general.text;

const TextInputWrapper = styled.div(({ placeholderUp }) => {
  return {
    transition: 'margin-top .2s',
    marginTop: placeholderUp ? 11 : 0,
    position: 'relative',
    width: '100%',
    boxSizing: 'content-box',
  };
});

const upStyles = {
  top: 2,
  fontSize: 12,
  color: COLORS.placeholderGrey,
};

const Placeholder = styled.div(({ placeholderUp, shouldHidePlaceHolder }) => {
  return {
    ...regular,
    position: 'absolute',
    transition: 'all .25s',
    fontFamily: 'Roboto, sans-serif',
    top: 24,
    color: COLORS.placeholderGrey,
    pointerEvents: 'none',
    ...(placeholderUp ? upStyles : {}),
    display: shouldHidePlaceHolder ? 'none' : undefined,
  };
});

const textInputStyles = {
  ...regular,
  width: '100%',
  height: 34,
  marginTop: 16,
  borderWidth: 0,
  borderBottomWidth: 1,
  borderColor: COLORS.extraLightGrey,
  cursor: 'pointer',
  'webkit-text-fill-color': COLORS.black,
  outline: 0,
  boxShadow: 'none',
  outlineColor: 'transparent',
  outlineStyle: 'none',
  backgroundColor: 'transparent',
  padding: 0,
  WebkitAppearance: 'none',
  WebkitTapHighlightColor: 'rgba(0,0,0,0)',
};

const LockIconWrapper = styled.div({
  position: 'absolute',
  right: 4,
  top: 23,
  display: 'none',
});

const formatDateChars = {
  n: '[0-1]',
  m: '[0-9]',
  e: '[0-3]',
  d: '[0-9]',
  z: '[1-2]',
  y: '[0-9]',
};

const formatTimeChars = {
  1: '[0-1]', // First hour digit can be 0 or 1
  2: '[0-9]', // Second hour digit can be 0-9
  5: '[0-5]', // First minute digit can be 0-5
  9: '[0-9]', // Second minute digit can be 0-9
  a: '[APM]', // a is for AM/PM
};

const maskPropsDict = {
  phone: {
    mask: '(999) 999-9999',
    alwaysShowMask: false,
    maskChar: null,
  },
  date: {
    mask: 'nm/ed/zyyy',
    alwaysShowMask: false,
    formatChars: formatDateChars,
    maskChar: null,
    focusedPlaceholder: 'mm/dd/yyyy',
  },
  zipcode: {
    mask: '99999',
    alwaysShowMask: false,
    maskChar: null,
  },
  time: {
    mask: '12:59 aa',
    alwaysShowMask: false,
    formatChars: formatTimeChars,
    maskChar: null,
    focusedPlaceholder: 'hh:mm AM/PM',
  },
};

function MaskedTextInputView(props) {
  const {
    placeholderUp,
    placeholder,
    maskPlaceholder,
    hasFocus,
    disabled,
    isValid,
    hidden,
    maskType,
    style = {},
    placeHolderStyle,
    autoFocus,
    alwaysShowPlaceholder,
    value,
    ...otherProps
  } = props;
  const maskProps = maskPropsDict[maskType] || {};
  const placeholderForMask = maskPlaceholder || maskProps.focusedPlaceholder;
  const focusedPlaceholder = placeholderForMask && hasFocus ? placeholderForMask : '';
  const inputRef = useRef(null);
  const inputHasText =
    value || (inputRef && inputRef.current && inputRef.current.value) || undefined;

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.getInputDOMNode().focus();
    }
  }, [autoFocus]);

  return (
    <TextInputWrapper placeholderUp={placeholderUp} hidden={hidden} className="has-lock-icon">
      <Placeholder
        placeholderUp={placeholderUp}
        style={placeHolderStyle}
        shouldHidePlaceHolder={(hasFocus || inputHasText) && !alwaysShowPlaceholder}
      >
        {placeholder}
      </Placeholder>
      <InputMask
        {...otherProps}
        value={value}
        placeholder={focusedPlaceholder}
        style={{
          ...textInputStyles,
          cursor: disabled ? 'default' : 'pointer',
          ...style,
        }}
        ref={inputRef}
        disabled={disabled}
        {...maskProps}
      />
      {disabled && (
        <LockIconWrapper className="lock-icon">
          <Lock color={COLORS.placeholderGrey} />
        </LockIconWrapper>
      )}
      <ErrorIcon isValid={isValid} />
    </TextInputWrapper>
  );
}

export default MaskedTextInputView;
