import { VoidFunctionComponent } from 'react';
import Svg, { Rect, Defs, Pattern } from 'svgs';
import { EmotionStyle } from '../../core/styled';
import { useUniqueID } from '../../hooks/a11yHelper';
import TakeTheLeapImage from '../../assets/taketheleap.png';

interface Props {
  width?: number;
  height?: number;
  style?: EmotionStyle;
}

const TakeTheLeap: VoidFunctionComponent<Props> = ({ width = 291, height = 130, style }) => {
  const imageId = useUniqueID('takeTheLeapImage');
  const patternId = useUniqueID('patternId');
  const titleText = 'Take The Leap';

  return (
    <Svg
      title={titleText}
      width={width}
      height={height}
      viewBox="0 0 291 130"
      fill="none"
      style={style}
    >
      <Rect x="0.5" y="0.9375" width="290" height="128.124" fill={`url(#${patternId})`} />
      <Defs>
        <Pattern id={patternId} patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlinkHref={`#${imageId}`} transform="scale(0.000244228 0.000552792)" />
        </Pattern>
        <image id={imageId} width="4096" height="1809" href={TakeTheLeapImage} />
      </Defs>
    </Svg>
  );
};

export default TakeTheLeap;
