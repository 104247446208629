import { Component } from 'react';
import {
  Grid,
  Row,
  Col,
  Panel,
  FormGroup,
  FormControl,
  Button,
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { toast, ToastContainer } from 'react-toastify';
import '../FormHeader/FormHeader.css';
import 'react-toastify/dist/ReactToastify.min.css';
import { connect } from 'react-redux';
import { getUserData } from '../../../utils/token';
import apiWrapper from '../../../utils/apiWrapper';
import apiHelper from '../../../utils/api';
import XIcon from '../XIcon';
import storage from '../../../core/storage';

const TherapistItemToken = (props) => (
  <div className="therapist-item-token">
    {props.option.text}
    <span className="therapist-token-close-button" onClick={props.onRemove}>
      <XIcon />
    </span>
  </div>
);

const TherapistLanguageItemToken = (props) => (
  <div className="therapist-item-token">
    {props.option.text}
    <span
      className="therapist-token-close-button"
      onClick={props.option.value === 15 ? null : props.onRemove}
    >
      <XIcon />
    </span>
  </div>
);

class TherapistClientFocus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAgeSpecialityItems: [],
      selectedCategoriesItems: [],
      selectedEthnicityItems: [],
      selectedReligionItems: [],
      selectedAgeSpecialityValues: [],
      selectedCategoriesValues: [],
      selectedEthnicityValues: [],
      selectedReligionValues: [],
      addedItems: [],
      deletedItems: [],
      addedLanguageItems: [],
      deletedLanguageItems: [],
      data: {
        ageSpeciality: [],
        categories: [],
        ethnicity: [],
        religion: [],
      },
      languageData: {
        language: [],
      },
      isEdit: false,
      isLoaded: false,
      dataEndPoints: 2,
      endPointsRetrieved: 0,
    };
    this.getClientFocusInfo = this.getClientFocusInfo.bind(this);
    this.getLanguageInfo = this.getLanguageInfo.bind(this);
    this.toggleFormEdit = this.toggleFormEdit.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.saveForm = this.saveForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
    this.filterSelectedItems = this.filterSelectedItems.bind(this);
    this.initializeItemValues = this.initializeItemValues.bind(this);
    this.renderTherapistItemToken = this.renderTherapistItemToken.bind(this);
    this.renderTherapistLanguageItemToken = this.renderTherapistLanguageItemToken.bind(this);
    this.getInitData = this.getInitData.bind(this);
    this.addExpertiseItems = this.addExpertiseItems.bind(this);
    this.deleteExpertiseItems = this.deleteExpertiseItems.bind(this);
    this.addedLanguageItems = this.addedLanguageItems.bind(this);
    this.deletedLanguageItems = this.deletedLanguageItems.bind(this);
    this.updateOriginalData = this.updateOriginalData.bind(this);
    this.getUpdatedItems = this.getUpdatedItems.bind(this);
    this.areSaveResponsesValid = this.areSaveResponsesValid.bind(this);
  }

  componentDidMount() {
    this.getClientFocusInfo();
    this.getLanguageInfo();
  }

  getClientFocusInfo() {
    const userId = getUserData().id;
    return apiWrapper
      .get(`${apiHelper().apiEndpoint}/api/v1/therapist/${userId}/client-focus-info`)
      .then((response) => {
        const { data } = response.data;
        Object.entries(data).forEach(
          ([key, value]) => (data[key] = value.sort((a, b) => (a.text > b.text ? 1 : -1)))
        );
        const selectedAgeSpecialityItems = this.filterSelectedItems(data.ageSpeciality);
        const selectedCategoriesItems = this.filterSelectedItems(data.categories);
        const selectedEthnicityItems = this.filterSelectedItems(data.ethnicity);
        const selectedReligionItems = this.filterSelectedItems(data.religion);
        const selectedAgeSpecialityValues = this.initializeItemValues(data.ageSpeciality);
        const selectedCategoriesValues = this.initializeItemValues(data.categories);
        const selectedEthnicityValues = this.initializeItemValues(data.ethnicity);
        const selectedReligionValues = this.initializeItemValues(data.religion);
        // Sort ethnicity drop down alphabetically with "Other racial or ethnic background" preceeded by "Biracial or multiracial"
        data.ethnicity.sort((a, b) =>
          a.text === 'Other racial or ethnic background' ||
          (a.text === 'Biracial or multiracial' &&
            b.text !== 'Other racial or ethnic background') ||
          (a.text > b.text &&
            b.text !== 'Other racial or ethnic background' &&
            b.text !== 'Biracial or multiracial')
            ? 1
            : -1
        );
        this.setState(
          {
            data,
            selectedAgeSpecialityItems,
            selectedCategoriesItems,
            selectedEthnicityItems,
            selectedReligionItems,
            selectedAgeSpecialityValues,
            selectedCategoriesValues,
            selectedEthnicityValues,
            selectedReligionValues,
          },
          this.getInitData
        );
      })
      .catch((err) => {
        this.setState({ error: err });
      });
  }

  getInitData() {
    if (this.state.dataEndPoints !== this.state.endPointsRetrieved) {
      this.setState(
        {
          endPointsRetrieved: this.state.endPointsRetrieved + 1,
        },
        () => {
          if (this.state.endPointsRetrieved == this.state.dataEndPoints) {
            this.setState({ isLoaded: true });
            this.props.setIsSectionLoaded({ clientFocus: true });
          } else {
            this.setState({ isLoaded: false });
          }
        }
      );
    }
  }

  getLanguageInfo() {
    const userId = getUserData().id;
    return apiWrapper
      .get(`${apiHelper().apiEndpoint}/api/v1/therapist/${userId}/language-info`)
      .then((response) => {
        response.data.data.language.sort((a, b) => (a.text > b.text ? 1 : -1));
        const selectedLanguageItems = this.filterSelectedItems(response.data.data.language);
        const selectedLanguageValues = this.initializeItemValues(response.data.data.language);
        this.setState(
          {
            languageData: response.data.data,
            selectedLanguageItems,
            selectedLanguageValues,
          },
          this.getInitData
        );
      })
      .catch((err) => {
        this.setState({ error: err });
      });
  }

  getUpdatedItems(items, isLanguage) {
    const updatedItems = items.map((item) => {
      let { selected } = item;
      if (isLanguage) {
        if (this.state.addedLanguageItems.indexOf(item.value) > -1) selected = true;
        if (this.state.deletedLanguageItems.indexOf(item.value) > -1) selected = false;
      } else {
        if (this.state.addedItems.indexOf(item.value) > -1) selected = true;
        if (this.state.deletedItems.indexOf(item.value) > -1) selected = false;
      }

      const updatedItem = {
        text: item.text,
        value: item.value,
        selected,
      };

      return updatedItem;
    });

    return updatedItems;
  }

  addExpertiseItems(userId) {
    const { addedItems } = this.state;
    if (addedItems.length > 0) {
      return apiWrapper.post(
        `${apiHelper().apiEndpoint}/api/v1/therapist/${userId}/add-fields-of-expertise`,
        {
          items: addedItems,
        }
      );
    }
    return undefined;
  }

  addedLanguageItems(userId) {
    const { addedLanguageItems } = this.state;
    if (addedLanguageItems.length > 0) {
      return apiWrapper.post(`${apiHelper().apiEndpoint}/api/v1/therapist/${userId}/add-language`, {
        items: addedLanguageItems,
      });
    }
    return undefined;
  }

  areSaveResponsesValid(
    addIssueResponse,
    deleteIssueResponse,
    addLanguangeResponse,
    deleteLanguageResponse
  ) {
    const isAddIssueResponseValid =
      addIssueResponse === undefined || (addIssueResponse.data && addIssueResponse.data.success);
    const isDeleteIssueResponseValid =
      deleteIssueResponse === undefined ||
      (deleteIssueResponse.data && deleteIssueResponse.data.success);
    const isAddLanguageResponseValid =
      addLanguangeResponse === undefined ||
      (addLanguangeResponse.data && addLanguangeResponse.data.success);
    const isDeleteLanguageResponseValid =
      deleteLanguageResponse === undefined ||
      (deleteLanguageResponse.data && deleteLanguageResponse.data.success);
    const responsesAreValid =
      isAddIssueResponseValid &&
      isDeleteIssueResponseValid &&
      isAddLanguageResponseValid &&
      isDeleteLanguageResponseValid;

    return responsesAreValid;
  }

  cancelEdit() {
    const selectedAgeSpecialityItems = this.filterSelectedItems(this.state.data.ageSpeciality);
    const selectedCategoriesItems = this.filterSelectedItems(this.state.data.categories);
    const selectedEthnicityItems = this.filterSelectedItems(this.state.data.ethnicity);
    const selectedReligionItems = this.filterSelectedItems(this.state.data.religion);
    const selectedLanguageItems = this.filterSelectedItems(this.state.languageData.language);
    const selectedAgeSpecialityValues = this.initializeItemValues(this.state.data.ageSpeciality);
    const selectedCategoriesValues = this.initializeItemValues(this.state.data.categories);
    const selectedEthnicityValues = this.initializeItemValues(this.state.data.ethnicity);
    const selectedReligionValues = this.initializeItemValues(this.state.data.religion);
    const selectedLanguageValues = this.initializeItemValues(this.state.languageData.language);

    this.refs.ageSpecialityTypeahead
      .getInstance()
      .setState({ selected: selectedAgeSpecialityItems });
    this.refs.categoriesTypeahead.getInstance().setState({ selected: selectedCategoriesItems });
    this.refs.ethnicityTypeahead.getInstance().setState({ selected: selectedEthnicityItems });
    this.refs.religionTypeahead.getInstance().setState({ selected: selectedReligionItems });
    this.refs.languageTypeahead.getInstance().setState({ selected: selectedLanguageItems });
    const editBool = !this.state.isEdit;
    const actionItems = document.querySelectorAll([
      '#client-focus .btn-ts-default',
      '#client-focus .ts-edit-button',
    ]);
    [].forEach.call(actionItems, (item) => {
      item.classList.toggle('hidden');
    });
    this.setState({
      addedItems: [],
      deletedItems: [],
      isEdit: editBool,
      selectedAgeSpecialityItems,
      selectedCategoriesItems,
      selectedEthnicityItems,
      selectedReligionItems,
      selectedLanguageItems,
      selectedAgeSpecialityValues,
      selectedCategoriesValues,
      selectedEthnicityValues,
      selectedReligionValues,
      selectedLanguageValues,
    });
  }

  deleteExpertiseItems(userId) {
    const { deletedItems } = this.state;
    if (deletedItems.length > 0) {
      return apiWrapper.post(
        `${apiHelper().apiEndpoint}/api/v1/therapist/${userId}/delete-fields-of-expertise`,
        {
          items: deletedItems,
        }
      );
    }
    return undefined;
  }

  deletedLanguageItems(userId) {
    const { deletedLanguageItems } = this.state;
    if (deletedLanguageItems.length > 0) {
      return apiWrapper.post(
        `${apiHelper().apiEndpoint}/api/v1/therapist/${userId}/delete-language`,
        {
          items: deletedLanguageItems,
        }
      );
    }
    return undefined;
  }

  filterSelectedItems(items) {
    return items.filter((item) => item.selected);
  }

  handleChange(event, field) {
    const { addedItems } = this.state;
    const { deletedItems } = this.state;
    const selectedValues = this.state[field];
    const eventItemValues = this.initializeItemValues(event);
    if (event.length > selectedValues.length) {
      event.forEach((item) => {
        if (deletedItems.indexOf(item.value) > -1) {
          deletedItems.splice(deletedItems.indexOf(item.value), 1);
          selectedValues.push(item.value);
        } else if (selectedValues.indexOf(item.value) < 0 && addedItems.indexOf(item.value) < 0) {
          addedItems.push(item.value);
          selectedValues.push(item.value);
        }
      });
    } else {
      selectedValues.forEach((value) => {
        if (addedItems.indexOf(value) > -1) {
          addedItems.splice(addedItems.indexOf(value), 1);
          selectedValues.splice(selectedValues.indexOf(value), 1);
        } else if (eventItemValues.indexOf(value) < 0 && deletedItems.indexOf(value) < 0) {
          deletedItems.push(value);
          selectedValues.splice(selectedValues.indexOf(value), 1);
        }
      });
    }

    this.setState({ addedItems, deletedItems, field });
  }

  handleLanguageChange(event) {
    const { addedLanguageItems } = this.state;
    const { deletedLanguageItems } = this.state;
    const { selectedLanguageValues } = this.state;
    const eventItemValues = this.initializeItemValues(event);

    if (event.length > selectedLanguageValues.length) {
      event.forEach((item) => {
        if (deletedLanguageItems.indexOf(item.value) > -1) {
          deletedLanguageItems.splice(deletedLanguageItems.indexOf(item.value), 1);
          selectedLanguageValues.push(item.value);
        } else if (
          selectedLanguageValues.indexOf(item.value) < 0 &&
          addedLanguageItems.indexOf(item.value) < 0
        ) {
          addedLanguageItems.push(item.value);
          selectedLanguageValues.push(item.value);
        }
      });
    } else {
      selectedLanguageValues.forEach((value) => {
        if (addedLanguageItems.indexOf(value) > -1) {
          addedLanguageItems.splice(addedLanguageItems.indexOf(value), 1);
          selectedLanguageValues.splice(selectedLanguageValues.indexOf(value), 1);
        } else if (
          eventItemValues.indexOf(value) < 0 &&
          deletedLanguageItems.indexOf(value) < 0 &&
          value !== 15
        ) {
          deletedLanguageItems.push(value);
          selectedLanguageValues.splice(selectedLanguageValues.indexOf(value), 1);
        }
      });
    }

    this.setState({ addedLanguageItems, deletedLanguageItems, selectedLanguageValues });
  }

  initializeItemValues(items) {
    return items.reduce((allItems, item) => {
      if (allItems.indexOf(item.value) < 0 && item.selected) {
        allItems.push(item.value);
      }
      return allItems;
    }, []);
  }

  saveForm() {
    this.toggleFormEdit();
    const token = storage.getItem('id_token');
    const userId = getUserData().id;
    Promise.all([
      this.addExpertiseItems(userId),
      this.deleteExpertiseItems(userId),
      this.addedLanguageItems(userId),
      this.deletedLanguageItems(userId),
    ])
      .then(
        ([addIssueResponse, deleteIssueResponse, addLanguangeResponse, deleteLanguageResponse]) => {
          if (
            this.areSaveResponsesValid(
              addIssueResponse,
              deleteIssueResponse,
              addLanguangeResponse,
              deleteLanguageResponse
            )
          ) {
            this.updateOriginalData(() => {
              this.setState({
                addedItems: [],
                deletedItems: [],
                addedLanguageItems: [],
                deletedLanguageItems: [],
              });
            });
            toast(<div className="toaster toaster-success">Changes are saved successfully!</div>, {
              autoClose: 3000,
            });
          } else {
            toast(
              <div
                className="toaster toaster-error"
                style={{ background: '#e8006b', color: '#FFF', padding: '12px', fontSize: '16px' }}
              >
                Something went wrong
              </div>,
              { autoClose: 3000 }
            );
          }
        }
      )
      .catch((error) => {
        toast(
          <div
            className="toaster toaster-error"
            style={{ background: '#e8006b', color: '#FFF', padding: '12px', fontSize: '16px' }}
          >
            Something went wrong
          </div>,
          { autoClose: 3000 }
        );
      });
  }

  toggleFormEdit() {
    const isEdit = !this.state.isEdit;
    this.setState({ isEdit });
    const actionItems = document.querySelectorAll([
      '#client-focus .btn-ts-default',
      '#client-focus .ts-edit-button',
    ]);
    [].forEach.call(actionItems, (item) => {
      item.classList.toggle('hidden');
    });
  }

  updateOriginalData(resetItemsArraysInState) {
    const { data } = this.state;
    const { languageData } = this.state;
    const newAgeSpeciality = this.getUpdatedItems(data.ageSpeciality, false);
    const newCategories = this.getUpdatedItems(data.categories, false);
    const newEthnicity = this.getUpdatedItems(data.ethnicity, false);
    const newReligion = this.getUpdatedItems(data.religion, false);
    const newLanguage = this.getUpdatedItems(languageData.language, true);

    this.setState(
      {
        data: {
          ageSpeciality: newAgeSpeciality,
          categories: newCategories,
          ethnicity: newEthnicity,
          religion: newReligion,
        },
        languageData: {
          language: newLanguage,
        },
      },
      resetItemsArraysInState
    );
  }

  renderTherapistItemToken(selectedItem, onRemove) {
    return (
      <TherapistItemToken
        key={selectedItem.value}
        disabled={!this.state.isEdit}
        onRemove={onRemove}
        option={selectedItem}
      />
    );
  }

  renderTherapistLanguageItemToken(selectedItem, onRemove) {
    return (
      <TherapistLanguageItemToken
        key={selectedItem.value}
        disabled={!this.state.isEdit}
        onRemove={onRemove}
        option={selectedItem}
      />
    );
  }

  render() {
    const title = 'Client Focus';
    const tooltip = (
      <Tooltip id="ClientFocusTooltip" className="tooltip ts-tooltip ts-profile-text-white">
        {`Your clinical expertise helps better match you with relevant clients. 'English' is the
        default language and cannot be removed.`}
      </Tooltip>
    );
    const disabledClassName = this.state.isEdit ? '' : 'typeahead-disabled';
    const isSaveDisabled =
      this.state.addedItems.length === 0 &&
      this.state.deletedItems.length === 0 &&
      this.state.addedLanguageItems.length === 0 &&
      this.state.deletedLanguageItems.length === 0;
    return (
      <div>
        <Col
          xs={12}
          id="client-focus"
          className={`ts-panel-title ${this.state.isLoaded ? 'show-panel' : 'hidden-panel'}`}
        >
          <Col xs={3} className="ts-font-black">
            {title}
            <OverlayTrigger placement="top" overlay={tooltip} trigger={['click', 'focus', 'hover']}>
              <i className="fa fa-fw fa-question-circle fa-lg" />
            </OverlayTrigger>
          </Col>
          <Button className="ts-edit-button pull-right" onClick={this.toggleFormEdit}>
            Edit
          </Button>
          <Button
            className="btn-ts-default btn-ts-green ts-profile-text-white pull-right hidden save-btn"
            onClick={this.saveForm}
            disabled={isSaveDisabled}
          >
            {' '}
            Save Changes{' '}
          </Button>
          <Button
            className="btn-ts-default ts-profile-btn-text-green cancel-btn pull-right hidden"
            onClick={this.cancelEdit}
          >
            {' '}
            Cancel{' '}
          </Button>
        </Col>
        <Col xs={12}>
          <Panel
            className={`ts-my-account-panel ${
              this.state.isLoaded ? 'show-panel' : 'hidden-panel'
            } ${disabledClassName}`}
          >
            <Row style={{ marginBottom: '10px' }}>
              <Col className="ts-profile-label" md={2}>
                Age Speciality
              </Col>
              <Col md={10} className="typeahead-field">
                <Typeahead
                  ref="ageSpecialityTypeahead"
                  selected={this.state.selectedAgeSpecialityItems}
                  labelKey={(option) => `${option.text}`}
                  options={this.state.data.ageSpeciality}
                  multiple
                  renderToken={this.renderTherapistItemToken}
                  onChange={(event) => this.handleChange(event, 'selectedAgeSpecialityValues')}
                  disabled={!this.state.isEdit}
                  placeholder="Select age speciality"
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: '10px' }}>
              <Col className="ts-profile-label" md={2}>
                Categories
              </Col>
              <Col md={10} className="typeahead-field">
                <Typeahead
                  ref="categoriesTypeahead"
                  selected={this.state.selectedCategoriesItems}
                  labelKey={(option) => `${option.text}`}
                  options={this.state.data.categories}
                  multiple
                  renderToken={this.renderTherapistItemToken}
                  onChange={(event) => this.handleChange(event, 'selectedCategoriesValues')}
                  disabled={!this.state.isEdit}
                  placeholder="Select categories"
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: '10px' }}>
              <Col className="ts-profile-label" md={2}>
                Ethnicity
              </Col>
              <Col md={10} className="typeahead-field">
                <Typeahead
                  ref="ethnicityTypeahead"
                  selected={this.state.selectedEthnicityItems}
                  labelKey={(option) => `${option.text}`}
                  options={this.state.data.ethnicity}
                  multiple
                  renderToken={this.renderTherapistItemToken}
                  onChange={(event) => this.handleChange(event, 'selectedEthnicityValues')}
                  disabled={!this.state.isEdit}
                  placeholder="Select ethnicities"
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: '10px' }}>
              <Col className="ts-profile-label" md={2}>
                Religion
              </Col>
              <Col md={10} className="typeahead-field">
                <Typeahead
                  ref="religionTypeahead"
                  selected={this.state.selectedReligionItems}
                  labelKey={(option) => `${option.text}`}
                  options={this.state.data.religion}
                  multiple
                  renderToken={this.renderTherapistItemToken}
                  onChange={(event) => this.handleChange(event, 'selectedReligionValues')}
                  disabled={!this.state.isEdit}
                  placeholder="Select religions"
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: '10px' }}>
              <Col className="ts-profile-label" md={2}>
                Language
              </Col>
              <Col md={10} className="typeahead-field">
                <Typeahead
                  ref="languageTypeahead"
                  selected={this.state.selectedLanguageItems}
                  labelKey={(option) => `${option.text}`}
                  options={this.state.languageData.language}
                  multiple
                  renderToken={this.renderTherapistLanguageItemToken}
                  onChange={this.handleLanguageChange}
                  disabled={!this.state.isEdit}
                  placeholder="Select languages"
                />
              </Col>
            </Row>
          </Panel>
        </Col>
      </div>
    );
  }
}

export default TherapistClientFocus;
