import { useState, useEffect } from 'react';
import { useLocation } from '@/core/routerLib';

function useLocationStateOnMount<T>() {
  const [locationState, setLocationState] = useState<T | null>();

  const location = useLocation<T>();

  useEffect(() => {
    if (!locationState) {
      if (location?.state) {
        setLocationState(location.state);
      } else {
        setLocationState(null);
      }
    }
  }, [location, location.state, locationState]);

  return locationState;
}

export default useLocationStateOnMount;
