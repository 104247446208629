import { VoidFunctionComponent, useState, useMemo, useEffect } from 'react';
import Lottie from 'react-lottie';
import {
  TouchableView,
  View,
  Small,
  useEmotionTheme,
  FloatingMenu,
  Standard,
  CheckMark,
  useWindowWidthState,
  CaretDown,
  useUniqueID,
  EmotionStyle,
} from '@talkspace/react-toolkit';
import { TherapistType } from 'ts-frontend/entities/Therapist';
import styled from '../../../core/styled';
import newClientAvailabilityBarCheckmark from '../../../assets/animations/newClientAvailabilityBarCheckmark.json';

interface NewClientAvailabilityBarProps {
  dailyCaseLoadOptions?: Record<string, number>;
  providerCapacity: number;
  onSelectionPress: (dailyCaseLoad: string) => void;
  onSuccess?: () => void;
  isCapacityUpdating: boolean;
  isError: boolean;
  handleOpen: (isOpen?: boolean) => void;
  isOpen: boolean;
  handleShowAnimatedCheck: (shouldShow: boolean) => void;
  shouldShowAnimatedCheck: boolean;
  isLoading: boolean;
  therapistType: TherapistType;
}

const Wrapper = styled(TouchableView)<{ isError?: boolean; isOpen: boolean }>(
  ({
    isError,
    isOpen,
    theme: {
      colors,
      window: { isMobile },
    },
  }) => {
    return {
      position: 'relative',
      flex: 1,
      display: 'flex',
      flexDirection: 'row',
      alignItems: isMobile ? 'start' : 'center',
      borderRadius: 7,
      backgroundColor: isError ? colors.permaGhostWhite : colors.permaAquaHaze,
      justifyContent: 'space-between',
      padding: '13px 15px',
      '&:hover:not(.active)': {
        boxShadow: isOpen ? undefined : '0 4px 10px -2px rgba(0,49,45,0.27)',
      },
    };
  }
);

const TextWrapper = styled(View)({
  maxWidth: 'calc(100% - 100px)',
  width: 'calc(100% - 100px)',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
});

const UpdatedAvailabilityStateWrapper = styled(View)({
  maxWidth: '100%',
  alignItems: 'center',
});

const MenuItemWrapper = styled(TouchableView)(({ theme: { colors } }) => {
  return {
    width: '100%',
    height: 34,
    borderRadius: 5,
    alignItems: 'center',
    '&:hover': {
      backgroundColor: colors.a11yLinkWaterGrey,
    },
  };
});

const UpdateNewClientAvailabilityText = styled(Small)<{ isMobile?: boolean; isOpen: boolean }>(
  ({ isMobile, isOpen, theme: { colors } }) => {
    return {
      marginRight: 4,
      fontWeight: 800,
      marginTop: isMobile ? 4 : undefined,
      color: isOpen ? colors.permaBlueStone : colors.accessibilityGreenDark,
      '&:hover': {
        color: colors.permaBlueStone,
      },
      fontSize: 15,
    };
  }
);

const removeLottieFromTabOrder = (container: Document | HTMLDivElement, title: string) => {
  const lottieElement = container.querySelector<HTMLDivElement>(`[title="${title}"]`);
  if (lottieElement) lottieElement.tabIndex = -1;
};

const AnimatedCheckmark: VoidFunctionComponent<{ show: boolean; style?: EmotionStyle }> = ({
  show,
  style,
}) => {
  const lottieTitle = useUniqueID('lottie-newClientAvailabilityBar');
  useEffect(() => {
    if (show) removeLottieFromTabOrder(document, lottieTitle);
  }, [lottieTitle, show]);
  return show ? (
    <View style={style}>
      <Lottie
        ariaRole="presentation"
        ariaLabel="check mark"
        title={lottieTitle}
        options={{ loop: false, autoplay: true, animationData: newClientAvailabilityBarCheckmark }}
        height={21}
        width={21}
        speed={1.4}
      />
    </View>
  ) : null;
};

const NewClientAvailabilityBar: VoidFunctionComponent<NewClientAvailabilityBarProps> = ({
  dailyCaseLoadOptions,
  providerCapacity,
  therapistType,
  onSelectionPress,
  onSuccess,
  isCapacityUpdating,
  isError,
  handleOpen,
  isOpen,
  handleShowAnimatedCheck,
  shouldShowAnimatedCheck,
  isLoading,
}) => {
  const { colors } = useEmotionTheme();
  const { isMobile } = useWindowWidthState();

  const [shouldRunOnSuccess, setShouldRunOnSuccess] = useState(false);

  useEffect(() => {
    if (shouldRunOnSuccess && !isCapacityUpdating && onSuccess) {
      onSuccess();
      handleShowAnimatedCheck(true);
      setShouldRunOnSuccess(false);
    }
  }, [handleShowAnimatedCheck, isCapacityUpdating, onSuccess, shouldRunOnSuccess]);

  useEffect(() => {
    if (shouldShowAnimatedCheck) {
      setTimeout(() => {
        handleShowAnimatedCheck(false);
      }, 5000);
    }
  }, [shouldShowAnimatedCheck, handleShowAnimatedCheck]);

  const availabilityText = useMemo(() => {
    const noCapacity = providerCapacity === 0;
    if (isError) return 'There was an error loading your current settings';
    if (noCapacity) return 'Unavailable to new clients';
    if (therapistType === 'psychiatrist') return `I'm available to receive new clients`;

    return isMobile
      ? `Up to ${providerCapacity} clients per day`
      : `I’m available to receive up to ${providerCapacity} clients per day`;
  }, [isError, isMobile, providerCapacity, therapistType]);

  const formattedDailyCaseLoadOptions = useMemo(() => {
    if (dailyCaseLoadOptions && therapistType) {
      if (therapistType !== 'psychiatrist') {
        const zeroClientsMessage = `None, I don't want new clients`;
        return Object.keys(dailyCaseLoadOptions)
          .sort((a, b) => dailyCaseLoadOptions[b] - dailyCaseLoadOptions[a])
          .map((key) => {
            const numberOfClients = dailyCaseLoadOptions[key];
            return {
              label: numberOfClients,
              value: key,
              note: numberOfClients === 0 ? zeroClientsMessage : undefined,
            };
          });
      }

      return [
        { label: 'Yes', value: 'minimum', note: undefined },
        { label: 'No', value: 'none', note: undefined },
      ];
    }
    return [];
  }, [dailyCaseLoadOptions, therapistType]);

  const mobileMenuBottom = therapistType === 'psychiatrist' ? -147 : -267;
  const webMenuBottom = therapistType === 'psychiatrist' ? -135 : -255;

  return (
    <Wrapper
      onPress={() => handleOpen(!isOpen)}
      isError={isError}
      disabled={isLoading}
      isOpen={isOpen}
    >
      <TextWrapper row={!isMobile}>
        <Small style={{ marginRight: 3, color: colors.purple600 }}>New client availability: </Small>
        <UpdatedAvailabilityStateWrapper row>
          <Small
            variant="smallTSBlack"
            style={{
              fontWeight: 700,
              paddingRight: shouldShowAnimatedCheck ? 3 : 24,
            }}
          >
            {availabilityText}
          </Small>
          <AnimatedCheckmark show={shouldShowAnimatedCheck} />
        </UpdatedAvailabilityStateWrapper>
      </TextWrapper>

      {!isError && (
        <View row style={{ alignItems: 'center' }}>
          <UpdateNewClientAvailabilityText
            variant="smallBoldTSBlack"
            isMobile={isMobile}
            isOpen={isOpen}
          >
            Update
          </UpdateNewClientAvailabilityText>
          <CaretDown style={{ marginTop: 1 }} />
        </View>
      )}
      <FloatingMenu
        onBackdropPress={() => handleOpen(false)}
        hide={!isOpen}
        style={{
          right: isMobile ? 0 : -10,
          left: 'auto',
          bottom: isMobile ? mobileMenuBottom : webMenuBottom,
          width: isMobile ? '100%' : 306,
          padding: '18px 12px',
          zIndex: 3,
          '&:hover': {
            boxShadow: '0 4px 10px -2px rgba(0,49,45,0.27)',
          },
        }}
      >
        <Standard style={{ marginBottom: 15, marginLeft: 8, marginRight: 8 }}>
          {therapistType === 'psychiatrist'
            ? 'Would you like to take new clients?'
            : 'What is the maximum number of new clients you want to receive per day?'}
        </Standard>
        {formattedDailyCaseLoadOptions?.map((option) => {
          let isSelected = Number(option.label) === providerCapacity;
          if (therapistType === 'psychiatrist') {
            isSelected = providerCapacity === 0 ? option.label === 'No' : option.label === 'Yes';
          }

          return (
            <MenuItemWrapper
              key={option.label}
              row
              onPress={() => {
                handleOpen(false);
                handleShowAnimatedCheck(false);
                onSelectionPress(option.value);
                setShouldRunOnSuccess(true);
              }}
            >
              {isSelected && <CheckMark color={colors.permaFunGreen} style={{ marginLeft: 11 }} />}
              <Standard style={{ marginLeft: (isSelected ? 2 : 28) + 7 }}>{option.label}</Standard>
              <Standard variant="standardBoldTSBlack" style={{ fontWeight: 1000, marginLeft: 10 }}>
                {option.note}
              </Standard>
            </MenuItemWrapper>
          );
        })}
      </FloatingMenu>
    </Wrapper>
  );
};

export default NewClientAvailabilityBar;
