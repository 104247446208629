import Svg, { Path } from 'svgs';
import { COLORS } from '@talkspace/react-toolkit';

interface ClinicalIconProps {
  color?: string;
  width?: number;
  isHovering?: boolean;
  isActive?: boolean;
  height?: number;
  style?: {};
}

const ClinicalIcon = ({
  isHovering,
  isActive,
  color = isActive ? COLORS.blueberry : COLORS.dimGray,
  width = 30,
  height = 24,
  ...otherProps
}: ClinicalIconProps) => (
  <Svg width={width} height={height} viewBox="0 0 30 24" {...otherProps}>
    <Path
      d="M14.5 4a9.5 9.5 0 110 19 9.5 9.5 0 010-19zm-2.131 8.074l-1.695 1.708a3.08 3.08 0 01-1.981.903l-.204.007H6.932a7.662 7.662 0 0015.136-.001h-2.896c-.37 0-.718.165-.952.445l-.082.11-2.397 3.622-3.372-6.794zM14.5 5.84a7.66 7.66 0 00-7.633 7.012h1.621c.284 0 .557-.097.776-.272l.104-.094 3.506-3.532 3.095 6.235.635-.958a3.08 3.08 0 012.362-1.373l.206-.007h2.96A7.66 7.66 0 0014.5 5.84z"
      fill={color}
      fillRule="nonzero"
      stroke="none"
      strokeWidth={1}
    />
  </Svg>
);

export default ClinicalIcon;
