import { VoidFunctionComponent } from 'react';
import { Trans } from '@talkspace/i18n';

import useTranslation from '@talkspace/i18n/hooks/useTranslation';
import View from '../View';
import { Big, Large, Huge } from '../Typography';
import { SchedulingCancellationIcon } from '../icons';
import styled, { useEmotionTheme } from '../../core/styled';
import { useWindowWidthState } from '../../hooks/windowWidthContext';

const SectionName = styled(Big)({
  marginBottom: 8,
  alignSelf: 'start',
});

const Paragraph = styled(Large)<{
  isMobile: boolean;
}>(({ isMobile }) => {
  return {
    maxWidth: isMobile ? 270 : 340,
    marginBottom: 20,
  };
});

const PolicyWithFee: VoidFunctionComponent<{
  isMobile: boolean;
}> = ({ isMobile }) => {
  const { t: tReactToolkit } = useTranslation('react-toolkit');
  return (
    <>
      <SectionName>
        <Trans t={tReactToolkit} i18nKey="CancellationPolicy.policyWithFeeDemandSectionTitle">
          Demand for providers is high
        </Trans>
      </SectionName>
      <Paragraph isMobile={isMobile} variant="largeDarkGrey" style={{ marginBottom: 40 }}>
        <Trans t={tReactToolkit} i18nKey="CancellationPolicy.policyWithFeeDemandSectionText">
          With demand for mental healthcare at an all-time high, there is a nationwide shortage of
          providers. When you book a time slot, please ensure that you can attend at that time. This
          will help maximize provider availability for other members.
        </Trans>
      </Paragraph>
      <SectionName>
        <Trans t={tReactToolkit} i18nKey="CancellationPolicy.policyWithFeePolicySectionTitle">
          Policy
        </Trans>
      </SectionName>
      <Paragraph isMobile={isMobile} variant="largeDarkGrey">
        <Trans t={tReactToolkit} i18nKey="CancellationPolicy.policyWithFeePolicySectionTextP1">
          If you need to reschedule or cancel a live session, you may do so up to 24 hours before
          your appointment’s start time.
        </Trans>
      </Paragraph>
      <Paragraph isMobile={isMobile}>
        <Trans t={tReactToolkit} i18nKey="CancellationPolicy.policyWithFeePolicySectionTextP2">
          Late cancellations or no-shows will result in a fee for the cost of service,
          <Large variant="largeBoldWide" inline>
            {' '}
            which can be up to $175.
          </Large>
        </Trans>
      </Paragraph>
      <Paragraph isMobile={isMobile}>
        <Trans t={tReactToolkit} i18nKey="CancellationPolicy.policyWithFeePolicySectionTextP3">
          It is your responsibility to ensure that your insurance coverage is active at the time of
          your appointment. Should your coverage not be active at the time of the appointment, you
          will be responsible for the full cost.
        </Trans>
      </Paragraph>
    </>
  );
};

const PolicyForAsync: VoidFunctionComponent<{
  isMobile: boolean;
}> = ({ isMobile }) => {
  const { t: tReactToolkit } = useTranslation('react-toolkit');

  return (
    <>
      <SectionName>
        <Trans t={tReactToolkit} i18nKey="CancellationPolicy.policyForAsyncDemandSectionTitle">
          Demand for providers is high
        </Trans>
      </SectionName>

      <Paragraph isMobile={isMobile} variant="largeDarkGrey" style={{ marginBottom: 40 }}>
        <Trans t={tReactToolkit} i18nKey="CancellationPolicy.policyForAsyncDemandSectionText">
          The demand for mental healthcare is extremely high across the country, and there is
          currently a nationwide shortage of providers.
        </Trans>
      </Paragraph>
      <SectionName>
        <Trans t={tReactToolkit} i18nKey="CancellationPolicy.policyForAsyncPolicySectionTitle">
          Policy
        </Trans>
      </SectionName>
      <Paragraph isMobile={isMobile} variant="largeDarkGrey">
        <Trans t={tReactToolkit} i18nKey="CancellationPolicy.policyForAsyncPolicySectionTextP1">
          It is not possible to cancel a messaging session once started. You will have 7 days of
          access to back-and-forth messaging with your provider.
        </Trans>
      </Paragraph>
      <Paragraph isMobile={isMobile}>
        <Trans t={tReactToolkit} i18nKey="CancellationPolicy.policyForAsyncPolicySectionTextP2">
          After 7 days, your provider will submit this session as complete even if you do not
          message or engage with your provider. If your provider is non-responsive, please contact
          customer support.
        </Trans>
      </Paragraph>
    </>
  );
};

const PolicyWithoutFee = () => {
  const { t: tReactToolkit } = useTranslation('react-toolkit');
  return (
    <>
      <Large variant="largeDarkGrey" style={{ textAlign: 'center', marginTop: 20, maxWidth: 340 }}>
        <Trans t={tReactToolkit} i18nKey="CancellationPolicy.policyWithoutFeePolicySectionTextP1">
          If you need to reschedule or cancel a session, you may do so until 24 hours before your
          appointment’s start time.
        </Trans>
      </Large>
      <Large variant="largeDarkGrey" style={{ textAlign: 'center', marginTop: 10, maxWidth: 340 }}>
        <Trans t={tReactToolkit} i18nKey="CancellationPolicy.policyWithoutFeePolicySectionTextP2">
          If you cancel this session less than 24 hours before your appointment or are a no show,
          you’ll lose the full cost of the session.
        </Trans>
      </Large>
    </>
  );
};

const CancellationPolicy: VoidFunctionComponent<{
  variant: 'liveWithoutFee' | 'liveWithFee' | 'async';
}> = ({ variant }) => {
  const { colors } = useEmotionTheme();
  const { isMobile } = useWindowWidthState();

  const { t: tReactToolkit } = useTranslation('react-toolkit');
  const getPolicy = () => {
    if (variant === 'async') {
      return <PolicyForAsync isMobile={isMobile} />;
    }
    if (variant === 'liveWithFee') {
      return <PolicyWithFee isMobile={isMobile} />;
    }
    return <PolicyWithoutFee />;
  };

  return (
    <View
      style={{ display: 'flex', paddingLeft: 3, paddingRight: 3, paddingTop: 25 }}
      justify="center"
      align="center"
    >
      <View style={{ marginBottom: 20 }}>
        <SchedulingCancellationIcon color={colors.loblollyGrey} />
      </View>
      <Huge style={{ marginBottom: 40 }}>
        <Trans t={tReactToolkit} i18nKey="CancellationPolicy.titleText">
          Cancellation policy
        </Trans>
      </Huge>
      {getPolicy()}
    </View>
  );
};

export default CancellationPolicy;
