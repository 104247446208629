import { useMutation, useQueryClient } from 'react-query';

import apiHelper from '@/core/api/apiHelper';
import apiWrapper from '@/core/api/apiWrapper';

import { therapistAutomaticMessagesQueryKey } from './queryKeys';

interface UpdateAutomaticMessageParams {
  therapistUserID: number;
  messageID: number;
  data: {
    customMessage: string;
    enabled: boolean;
  };
}

const updateAutomaticMessage = async (params: UpdateAutomaticMessageParams): Promise<void> => {
  const { therapistUserID, messageID, data } = params;

  await apiWrapper.patch(
    `${apiHelper().apiEndpoint}/v3/therapists/${therapistUserID}/automatic-messages/${messageID}`,
    data
  );
};

export default function useMutationUpdateAutomaticMessage() {
  const queryClient = useQueryClient();

  return useMutation<void, Error, UpdateAutomaticMessageParams>(updateAutomaticMessage, {
    onSuccess: async (_, { therapistUserID }) => {
      await queryClient.invalidateQueries({
        queryKey: therapistAutomaticMessagesQueryKey(therapistUserID),
      });
    },
  });
}
