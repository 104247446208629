import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const standardPath = '';
const majorPath =
  'M18.125 10C18.125 7.84512 17.269 5.77849 15.7452 4.25476C14.2215 2.73102 12.1549 1.875 10 1.875C7.84512 1.875 5.77849 2.73102 4.25476 4.25476C2.73102 5.77849 1.875 7.84512 1.875 10C1.875 12.1549 2.73102 14.2215 4.25476 15.7452C5.77849 17.269 7.84512 18.125 10 18.125C12.1549 18.125 14.2215 17.269 15.7452 15.7452C17.269 14.2215 18.125 12.1549 18.125 10ZM0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34784 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C7.34784 20 4.8043 18.9464 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10ZM6.30859 6.63672L6.77344 6.83594C7.79297 7.27344 8.89062 7.5 10.0039 7.5C11.1172 7.5 12.2109 7.27344 13.2344 6.83594L13.6992 6.63672C14.1758 6.43359 14.7266 6.65234 14.9297 7.12891C15.1328 7.60547 14.9141 8.15625 14.4375 8.35938L13.9727 8.55859C13.2969 8.84766 12.5977 9.0625 11.8789 9.19531V11.1523C11.8789 11.3203 11.9063 11.4883 11.9609 11.6445L13.082 15.0078C13.2461 15.5 12.9805 16.0312 12.4883 16.1953C11.9961 16.3594 11.4648 16.0938 11.3008 15.6016L10.3477 12.7422C10.2969 12.5938 10.1602 12.4922 10.0039 12.4922C9.84766 12.4922 9.70703 12.5938 9.66016 12.7422L8.70703 15.6016C8.54297 16.0938 8.01172 16.3594 7.51953 16.1953C7.02734 16.0312 6.76172 15.5 6.92578 15.0078L8.04688 11.6445C8.10156 11.4844 8.12891 11.3203 8.12891 11.1523V9.19922C7.41016 9.0625 6.71094 8.85156 6.03516 8.5625L5.57031 8.36328C5.09375 8.16016 4.875 7.60938 5.07812 7.13281C5.28125 6.65625 5.83203 6.4375 6.30859 6.64062V6.63672ZM10 3.125C10.4144 3.125 10.8118 3.28962 11.1049 3.58265C11.3979 3.87567 11.5625 4.2731 11.5625 4.6875C11.5625 5.1019 11.3979 5.49933 11.1049 5.79235C10.8118 6.08538 10.4144 6.25 10 6.25C9.5856 6.25 9.18817 6.08538 8.89515 5.79235C8.60212 5.49933 8.4375 5.1019 8.4375 4.6875C8.4375 4.2731 8.60212 3.87567 8.89515 3.58265C9.18817 3.28962 9.5856 3.125 10 3.125Z';

const pathConfig: IconConfig = {
  standard: {
    default: {
      path: standardPath,
    },
  },
  major: {
    default: {
      path: majorPath,
    },
  },
};

const UniversalAccess = withDSIconMaker(pathConfig, 'person inside circle');

export default UniversalAccess;
