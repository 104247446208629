import { useState } from 'react';
import {
  Tiny,
  View,
  useEmotionTheme,
  Standard,
  TextAreaRounded,
  styled,
  ExtraTiny,
} from '@talkspace/react-toolkit';
import { ProgressNoteSubstanceUseCreationAttributes } from '../../types';
import {
  useProgressNoteFormActions,
  useProgressNoteFormState,
} from '../context/TherapistProgressNoteFormContext';
import { FormSection, Section } from '../context/types';
import SelectContainer from '../containers/SelectContainer';
import {
  BooleanDropdown,
  getSelectStyleV2,
  getTextAreaContainerBorderStyleV2,
  getTextAreaStyleV2,
  SUBSTANCE_ABUSE_CHARS_LIMIT,
} from '../../utils';
import useDidUpdateEffect from '../../../../../../hooks/useDidUpdateEffect';

type SubstanceUseNoteFieldName = keyof Omit<
  ProgressNoteSubstanceUseCreationAttributes,
  'pastSubstanceUse' | 'currentSubstanceUse'
>;

const Seperator = styled(View)(({ theme: { colors } }) => {
  return {
    width: 5,
    background: colors.periwinkleGrey,
    height: '100%',
    borderRadius: 10,
    marginRight: 15,
  };
});

const Title = styled(Standard)(({ theme: { colors } }) => {
  return {
    fontSize: 15,
    fontWeight: 700,
    marginBottom: 5,
  };
});

const SubstanceUseSection = ({ nextSection }: { nextSection: Section }) => {
  const { substanceUse, formSections, formMode, currentSection, isSaveAsDraftPressed } =
    useProgressNoteFormState();
  const { setFormState } = useProgressNoteFormActions();

  const [currentSubstanceUseError, setCurrentSubstanceUseError] = useState(false);
  const [notesErrors, setNotesErrors] = useState<Array<SubstanceUseNoteFieldName>>([]);

  const { colors } = useEmotionTheme();

  const markSectionAsIncomplete = () => {
    if (formSections.substanceUse.completed) {
      setFormState({
        formSections: {
          ...formSections,
          substanceUse: {
            open: formSections.substanceUse.open,
            changed: true,
            completed: false,
          },
        },
      });
    }
  };

  const handleCurrentSubstanceUseChange = (option: boolean) => {
    markSectionAsIncomplete();
    setCurrentSubstanceUseError(false);
    setFormState({ substanceUse: { ...substanceUse, currentSubstanceUse: option } });
  };

  const setNotes = (
    field: keyof ProgressNoteSubstanceUseCreationAttributes,
    value: string | null
  ) => {
    markSectionAsIncomplete();

    setCurrentSubstanceUseError(false);
    setNotesErrors(notesErrors.filter((it) => it !== field));
    setFormState({ substanceUse: { ...substanceUse, [field]: value } });
  };

  const handleConfirmPress = () => {
    let isError = false;

    if (substanceUse.pastSubstanceUse) {
      const missingNotes: Array<SubstanceUseNoteFieldName> = [];

      if (substanceUse.pastCigarettesUseNotes === '') {
        missingNotes.push('pastCigarettesUseNotes');
      }
      if (substanceUse.pastVapingUseNotes === '') {
        missingNotes.push('pastVapingUseNotes');
      }
      if (substanceUse.pastAlcoholUseNotes === '') {
        missingNotes.push('pastAlcoholUseNotes');
      }
      if (substanceUse.pastMarijuanaUseNotes === '') {
        missingNotes.push('pastMarijuanaUseNotes');
      }
      if (substanceUse.pastStimulantsUseNotes === '') {
        missingNotes.push('pastStimulantsUseNotes');
      }
      if (substanceUse.pastCocaineUseNotes === '') {
        missingNotes.push('pastCocaineUseNotes');
      }
      if (substanceUse.pastHeroinUseNotes === '') {
        missingNotes.push('pastHeroinUseNotes');
      }
      if (substanceUse.pastBenzodiazepinesUseNotes === '') {
        missingNotes.push('pastBenzodiazepinesUseNotes');
      }
      if (substanceUse.pastOpioidsUseNotes === '') {
        missingNotes.push('pastOpioidsUseNotes');
      }
      if (substanceUse.pastOtherSubstanceUseNotes === '') {
        missingNotes.push('pastOtherSubstanceUseNotes');
      }
    }

    if (substanceUse.currentSubstanceUse) {
      const missingNotes: Array<SubstanceUseNoteFieldName> = [];

      if (substanceUse.currentCigarettesUseNotes === '') {
        missingNotes.push('currentCigarettesUseNotes');
      }
      if (substanceUse.currentVapingUseNotes === '') {
        missingNotes.push('currentVapingUseNotes');
      }
      if (substanceUse.currentAlcoholUseNotes === '') {
        missingNotes.push('currentAlcoholUseNotes');
      }
      if (substanceUse.currentMarijuanaUseNotes === '') {
        missingNotes.push('currentMarijuanaUseNotes');
      }
      if (substanceUse.currentStimulantsUseNotes === '') {
        missingNotes.push('currentStimulantsUseNotes');
      }
      if (substanceUse.currentCocaineUseNotes === '') {
        missingNotes.push('currentCocaineUseNotes');
      }
      if (substanceUse.currentHeroinUseNotes === '') {
        missingNotes.push('currentHeroinUseNotes');
      }
      if (substanceUse.currentBenzodiazepinesUseNotes === '') {
        missingNotes.push('currentBenzodiazepinesUseNotes');
      }
      if (substanceUse.currentOpioidsUseNotes === '') {
        missingNotes.push('currentOpioidsUseNotes');
      }
      if (substanceUse.currentOtherSubstanceUseNotes === '') {
        missingNotes.push('currentOtherSubstanceUseNotes');
      }
    }

    if (substanceUse.currentSubstanceUse && !substanceUse.currentOtherSubstanceUseNotes) {
      isError = true;
    }

    if (isError && !isSaveAsDraftPressed) {
      setCurrentSubstanceUseError(true);
      setFormState({ showGlobalError: true });
      return;
    }

    const nextSectionInline: FormSection = 'treatmentPlanProgress';

    let dataToSend = { ...substanceUse };
    if (!substanceUse.currentSubstanceUse) {
      dataToSend = {
        ...dataToSend,
        currentCigarettesUseNotes: null,
        currentVapingUseNotes: null,
        currentAlcoholUseNotes: null,
        currentMarijuanaUseNotes: null,
        currentStimulantsUseNotes: null,
        currentCocaineUseNotes: null,
        currentHeroinUseNotes: null,
        currentBenzodiazepinesUseNotes: null,
        currentOpioidsUseNotes: null,
        currentOtherSubstanceUseNotes: null,
      };
    }

    setFormState({
      substanceUse: dataToSend,
      formSections: {
        ...formSections,
        substanceUse: {
          open: false,
          changed: true,
          completed: !(isError && !isSaveAsDraftPressed),
        },
        ...(formSections[nextSectionInline].completed
          ? {}
          : {
              [nextSectionInline]: {
                ...formSections[nextSectionInline],
                open: true,
              },
            }),
      },
    });
  };
  useDidUpdateEffect(() => {
    if (currentSection === 'substanceUse') {
      setFormState({ currentSection: nextSection });
      handleConfirmPress();
    }
  }, [currentSection]);

  const textAreaStyle = getTextAreaStyleV2(formMode === 'view', colors);

  return (
    <View>
      <SelectContainer
        value={BooleanDropdown.find((it) => it.value === substanceUse.currentSubstanceUse) as any}
        title="Substance abuse observed or reported?"
        handleChange={((option) => handleCurrentSubstanceUseChange(option?.value)) as any}
        options={BooleanDropdown as any}
        styles={getSelectStyleV2({ colors })}
        isDisabled={formMode === 'view'}
        dataTestID="substanceAbuseSelectId"
        dataQa="substanceAbuseDropdown"
      />
      {substanceUse.currentSubstanceUse && (
        <View row style={{ marginBottom: 15 }}>
          <Seperator />
          <View flex={1}>
            <Title>Substance abuse</Title>
            <Tiny style={{ fontSize: 14, color: colors.purple600 }}>
              Summarize what you observed and/or what client reported in this session
            </Tiny>
            <TextAreaRounded
              autoSize
              style={{
                ...getTextAreaContainerBorderStyleV2(colors, currentSubstanceUseError),
                marginTop: 10,
              }}
              textAreaStyle={{ ...textAreaStyle, fontSize: 14 }}
              value={substanceUse.currentOtherSubstanceUseNotes || ''}
              onChangeText={(value) => setNotes('currentOtherSubstanceUseNotes', value)}
              placeholder="Required. Client reported abuse of substance..."
              disabled={formMode === 'view'}
              rows={4}
              hasLimit
              limit={SUBSTANCE_ABUSE_CHARS_LIMIT}
              dataQa="clientReportedAbuseOfSubstanceTextArea"
            />
            {currentSubstanceUseError && (
              <ExtraTiny variant="extraTinyError" data-testid="substanceAbuseError">
                Required: You need to describe what was observed or reported that made you select
                Yes for substance abuse
              </ExtraTiny>
            )}
          </View>
        </View>
      )}
    </View>
  );
};

export default SubstanceUseSection;
