import Svg, { Path } from 'svgs';
import { useEmotionTheme, EmotionStyle } from '../../core/styled';

interface Props {
  color?: string;
  width?: number;
  height?: number;
  style?: EmotionStyle;
}

const RoundRectExclaimationMark = ({ width = 20, height = 20, color, ...otherProps }: Props) => {
  const titleText = 'exclaimation mark';
  const { colors } = useEmotionTheme();

  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      fill="none"
      viewBox="0 0 20 20"
      {...otherProps}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 4.762A4.762 4.762 0 0 1 4.762 0h10.476A4.762 4.762 0 0 1 20 4.762v10.476A4.762 4.762 0 0 1 15.238 20H4.762A4.762 4.762 0 0 1 0 15.238V4.762Z"
        fill={color || colors.permaFuchsia}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.409 5.741a.952.952 0 0 1 .952-.98h1.278c.537 0 .967.444.952.98l-.13 4.531a1.19 1.19 0 0 1-1.19 1.157h-.543a1.19 1.19 0 0 1-1.19-1.157l-.13-4.53ZM8.571 14.048c0-.658.533-1.19 1.19-1.19h.477a1.19 1.19 0 1 1 0 2.38h-.476a1.19 1.19 0 0 1-1.19-1.19Z"
        fill="#fff"
      />
    </Svg>
  );
};

export default RoundRectExclaimationMark;
