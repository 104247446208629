import { FunctionComponent } from 'react';
import { View, ExtraHuge, Button, Large } from '@talkspace/react-toolkit';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import styled from '@/core/styled';

import ChatClouds from '../icons/ChatClouds';

const Wrapper = styled(View)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      alignSelf: 'center',
      alignItems: 'center',
      textAlign: 'center',
      marginTop: isMobile ? '15%' : '8%',
      marginBottom: 'auto',
      width: 340,
    };
  }
);

const Title = styled(ExtraHuge)({
  marginBottom: 20,
  marginTop: 20,
});

const DoneButton = styled(Button)(({ theme: { colors } }) => {
  return { border: 'none', color: colors.slateGrey, backgroundColor: 'transparent' };
});

const CheckInDone: FunctionComponent<{
  therapistFirstName?: string;
  therapistRating?: number;
  roomID: number;
}> = ({ therapistFirstName, therapistRating, roomID }) => {
  const closeModal = useCloseModal();
  const MAX_CHECK_IN_RATING = 5;

  const handleDone = () => {
    const shouldOpenAppReview = therapistRating === MAX_CHECK_IN_RATING;
    closeModal({
      navigateTo: 'room',
      metadata: {
        roomID,
        additionalMetaData: {
          actionType: shouldOpenAppReview ? 'openAppReview' : undefined,
        },
      },
    });
  };

  return (
    <Wrapper>
      <ChatClouds />
      <Title>Keep up the great work!</Title>
      <Large>
        Be sure to give {therapistFirstName} any feedback you have so that you can get the most out
        of your experience.
      </Large>
      <DoneButton text="Done" onPress={handleDone} />
    </Wrapper>
  );
};

export default CheckInDone;
