import Svg, { G, Circle, Path } from 'svgs';

const SmallGear = ({
  width = undefined,
  height = undefined,
  isActive = false,
  isBackgroundDark = false,
  ...otherProps
}) => {
  const titleText = 'gear';
  if (!isActive && isBackgroundDark)
    return (
      <Svg
        width={width || 35}
        height={height || 35}
        title={titleText}
        aria-label={titleText}
        viewBox="0 0 35 35"
        {...otherProps}
      >
        <Path
          d="M26.082 19.945l-.702-.48c-.641-.442-.982-1.183-.982-1.945v-.02-.02c0-.782.34-1.503.982-1.944l.702-.481a.932.932 0 00.28-1.263l-1.222-2.104c-.24-.441-.782-.602-1.244-.381l-.761.36c-.682.321-1.464.301-2.106-.08-.04-.02-.1-.06-.14-.08a2.235 2.235 0 01-1.143-1.783l-.06-.842A.957.957 0 0018.743 8h-2.466a.93.93 0 00-.943.882l-.06.842a2.235 2.235 0 01-1.143 1.783c-.04.02-.1.06-.14.08a2.24 2.24 0 01-2.105.08l-.782-.38c-.442-.22-.983-.04-1.244.38l-1.223 2.125c-.24.441-.12.982.28 1.263l.703.48c.641.442.982 1.183.982 1.945v.04c0 .782-.34 1.503-.982 1.944l-.702.481a.932.932 0 00-.28 1.263l1.222 2.104c.24.441.782.602 1.244.381l.761-.36c.682-.321 1.464-.301 2.106.08.04.02.1.06.14.08a2.235 2.235 0 011.143 1.783l.06.842c.04.501.462.882.943.882h2.446a.93.93 0 00.943-.882l.06-.842a2.235 2.235 0 011.143-1.783c.04-.02.1-.06.14-.08a2.24 2.24 0 012.105-.08l.762.36c.442.22.983.04 1.244-.38l1.223-2.125a.898.898 0 00-.24-1.243zM17.5 21.068a3.576 3.576 0 01-3.57-3.568 3.576 3.576 0 013.57-3.568 3.576 3.576 0 013.57 3.568 3.576 3.576 0 01-3.57 3.568z"
          fill="#F1F6FF"
          fillRule="nonzero"
          stroke="none"
          strokeWidth={1}
        />
      </Svg>
    );
  if (isActive && isBackgroundDark)
    return (
      <Svg
        width={width || 36}
        height={height || 35}
        title={titleText}
        aria-label={titleText}
        viewBox="0 0 36 35"
        {...otherProps}
      >
        <G transform="translate(.5 0)" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
          <Circle fill="#F1F6FF" cx={17.5} cy={17.5} r={17.5} />
          <Path
            d="M26.082 19.945l-.702-.48c-.641-.442-.982-1.183-.982-1.945v-.02-.02c0-.782.34-1.503.982-1.944l.702-.481a.932.932 0 00.28-1.263l-1.222-2.104c-.24-.441-.782-.602-1.244-.381l-.761.36c-.682.321-1.464.301-2.106-.08-.04-.02-.1-.06-.14-.08a2.235 2.235 0 01-1.143-1.783l-.06-.842A.957.957 0 0018.743 8h-2.466a.93.93 0 00-.943.882l-.06.842a2.235 2.235 0 01-1.143 1.783c-.04.02-.1.06-.14.08a2.24 2.24 0 01-2.105.08l-.782-.38c-.442-.22-.983-.04-1.244.38l-1.223 2.125c-.24.441-.12.982.28 1.263l.703.48c.641.442.982 1.183.982 1.945v.04c0 .782-.34 1.503-.982 1.944l-.702.481a.932.932 0 00-.28 1.263l1.222 2.104c.24.441.782.602 1.244.381l.761-.36c.682-.321 1.464-.301 2.106.08.04.02.1.06.14.08a2.235 2.235 0 011.143 1.783l.06.842c.04.501.462.882.943.882h2.446a.93.93 0 00.943-.882l.06-.842a2.235 2.235 0 011.143-1.783c.04-.02.1-.06.14-.08a2.24 2.24 0 012.105-.08l.762.36c.442.22.983.04 1.244-.38l1.223-2.125a.898.898 0 00-.24-1.243zM17.5 21.068a3.576 3.576 0 01-3.57-3.568 3.576 3.576 0 013.57-3.568 3.576 3.576 0 013.57 3.568 3.576 3.576 0 01-3.57 3.568z"
            fill="#41506A"
            fillRule="nonzero"
          />
        </G>
      </Svg>
    );

  if (!isActive && !isBackgroundDark)
    return (
      <Svg
        width={width || 35}
        height={height || 35}
        title={titleText}
        aria-label={titleText}
        viewBox="0 0 35 35"
        {...otherProps}
      >
        <Path
          d="M26.082 19.945l-.702-.48c-.641-.442-.982-1.183-.982-1.945v-.02-.02c0-.782.34-1.503.982-1.944l.702-.481a.932.932 0 00.28-1.263l-1.222-2.104c-.24-.441-.782-.602-1.244-.381l-.761.36c-.682.321-1.464.301-2.106-.08-.04-.02-.1-.06-.14-.08a2.235 2.235 0 01-1.143-1.783l-.06-.842A.957.957 0 0018.743 8h-2.466a.93.93 0 00-.943.882l-.06.842a2.235 2.235 0 01-1.143 1.783c-.04.02-.1.06-.14.08a2.24 2.24 0 01-2.105.08l-.782-.38c-.442-.22-.983-.04-1.244.38l-1.223 2.125c-.24.441-.12.982.28 1.263l.703.48c.641.442.982 1.183.982 1.945v.04c0 .782-.34 1.503-.982 1.944l-.702.481a.932.932 0 00-.28 1.263l1.222 2.104c.24.441.782.602 1.244.381l.761-.36c.682-.321 1.464-.301 2.106.08.04.02.1.06.14.08a2.235 2.235 0 011.143 1.783l.06.842c.04.501.462.882.943.882h2.446a.93.93 0 00.943-.882l.06-.842a2.235 2.235 0 011.143-1.783c.04-.02.1-.06.14-.08a2.24 2.24 0 012.105-.08l.762.36c.442.22.983.04 1.244-.38l1.223-2.125a.898.898 0 00-.24-1.243zM17.5 21.068a3.576 3.576 0 01-3.57-3.568 3.576 3.576 0 013.57-3.568 3.576 3.576 0 013.57 3.568 3.576 3.576 0 01-3.57 3.568z"
          fill="#41506A"
          fillRule="nonzero"
          stroke="none"
          strokeWidth={1}
        />
      </Svg>
    );
  return (
    <Svg
      width={width || 35}
      height={height || 35}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 35 35"
      {...otherProps}
    >
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Circle fillOpacity={0.2} fill="#000516" cx={17.5} cy={17.5} r={17.5} />
        <Path
          d="M26.082 19.945l-.702-.48c-.641-.442-.982-1.183-.982-1.945v-.02-.02c0-.782.34-1.503.982-1.944l.702-.481a.932.932 0 00.28-1.263l-1.222-2.104c-.24-.441-.782-.602-1.244-.381l-.761.36c-.682.321-1.464.301-2.106-.08-.04-.02-.1-.06-.14-.08a2.235 2.235 0 01-1.143-1.783l-.06-.842A.957.957 0 0018.743 8h-2.466a.93.93 0 00-.943.882l-.06.842a2.235 2.235 0 01-1.143 1.783c-.04.02-.1.06-.14.08a2.24 2.24 0 01-2.105.08l-.782-.38c-.442-.22-.983-.04-1.244.38l-1.223 2.125c-.24.441-.12.982.28 1.263l.703.48c.641.442.982 1.183.982 1.945v.04c0 .782-.34 1.503-.982 1.944l-.702.481a.932.932 0 00-.28 1.263l1.222 2.104c.24.441.782.602 1.244.381l.761-.36c.682-.321 1.464-.301 2.106.08.04.02.1.06.14.08a2.235 2.235 0 011.143 1.783l.06.842c.04.501.462.882.943.882h2.446a.93.93 0 00.943-.882l.06-.842a2.235 2.235 0 011.143-1.783c.04-.02.1-.06.14-.08a2.24 2.24 0 012.105-.08l.762.36c.442.22.983.04 1.244-.38l1.223-2.125a.898.898 0 00-.24-1.243zM17.5 21.068a3.576 3.576 0 01-3.57-3.568 3.576 3.576 0 013.57-3.568 3.576 3.576 0 013.57 3.568 3.576 3.576 0 01-3.57 3.568z"
          fill="#F1F6FF"
          fillRule="nonzero"
        />
      </G>
    </Svg>
  );
};

export default SmallGear;
