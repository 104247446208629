import Svg, { Path } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

const VideoMuted = ({ width = 40, height = 41, ...otherProps }) => {
  const titleText = 'muted';
  const { colors } = useEmotionTheme();
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 40 41"
      {...otherProps}
    >
      <Path
        d="M1117.887 52.169a1.23 1.23 0 011.67.415l23.711 38.61a1.174 1.174 0 01-.409 1.637 1.23 1.23 0 01-1.669-.415l-23.712-38.61a1.174 1.174 0 01.41-1.637zm2.623 8.876l14.566 23.72h-18.283a4.795 4.795 0 01-4.793-4.8v-14.12c0-2.65 2.141-4.8 4.793-4.8h3.717zm14.576 0a4.795 4.795 0 014.793 4.8v14.12c0 .867-.23 1.68-.63 2.382l-13.083-21.302h8.92zM1152 67.01V78.8c0 1.984-1.369 2.755-3.068 1.716l-5.61-3.43c-.563-.345-1.019-1.159-1.019-1.831v-4.702c0-.666.455-1.485 1.02-1.83l5.609-3.43c1.694-1.036 3.068-.259 3.068 1.715z"
        transform="translate(-1112 -52)"
        fill={colors.white}
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </Svg>
  );
};

export default VideoMuted;
