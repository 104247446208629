import * as React from 'react';
import {
  View,
  ResponsiveLayoutWithHeader,
  PanelHeader,
  TSLogo,
  ExtraHuge,
} from '@talkspace/react-toolkit';
import styled from '@/core/styled';
import Breadcrumbs, { SignupSteps } from './SignupBreadcrumbs';
import { SignupStepEnum } from '../types';

const ContentWrapper = styled(View)({
  maxWidth: 343,
});

type Props = {
  step: SignupStepEnum;
  children: React.ReactNode;
};
const BaseScreen: React.FC<Props> = ({ step, children }) => (
  <ResponsiveLayoutWithHeader
    renderHeader={() => (
      <PanelHeader
        renderLeft={() => (
          <View justify="center">
            <TSLogo style={{ marginLeft: -12 }} variant="2024" />
          </View>
        )}
      />
    )}
  >
    <View align="center" style={{ padding: '40px 16px' }}>
      <Breadcrumbs activeStep={step} />
      <ExtraHuge style={{ marginTop: 30 }}>{SignupSteps[step].title}</ExtraHuge>
      <ContentWrapper>{children}</ContentWrapper>
    </View>
  </ResponsiveLayoutWithHeader>
);

export default BaseScreen;
