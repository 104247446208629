import { FunctionComponent } from 'react';

import Svg, { Path, Defs, G, Circle } from 'svgs';
import { EmotionStyle, useEmotionTheme } from '../../core/styled';

interface CheckmarkProps {
  color?: string;
  width?: number;
  height?: number;
  style?: EmotionStyle;
}

const CircleClockPlaceholderIcon: FunctionComponent<CheckmarkProps> = ({
  color,
  width = 89,
  height = 89,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'check mark';
  return (
    <Svg
      {...otherProps}
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 89 89"
    >
      <Path
        d="M44.5 89C69.077 89 89 69.077 89 44.5S69.077 0 44.5 0 0 19.923 0 44.5 19.923 89 44.5 89z"
        fill={colors.permaLinkWaterGrey}
      />
      <G>
        <Circle cx={44.5} cy={44.5} r={17} fill={colors.patensBlue} />
        <Path
          d="M64.455 44.47c0 11.023-9.007 20.03-20.088 20.03-9.956 0-18.311-7.052-19.852-16.83-.119-.888.474-1.777 1.363-1.896.889-.178 1.778.474 1.896 1.363 1.304 8.118 8.296 13.985 16.534 13.985 9.244 0 16.77-7.466 16.77-16.652 0-9.185-7.526-16.651-16.77-16.651-7.704 0-14.4 5.155-16.237 12.562l-.119.534c-.177.888-1.066 1.481-2.014 1.303a1.677 1.677 0 01-1.304-1.955c.06-.237.119-.415.119-.652C27.005 30.722 35.004 24.5 44.249 24.5c11.14 0 20.207 8.948 20.207 19.97zM44.307 33.39c-.948 0-1.659.77-1.659 1.658v9.422c0 .237.06.416.119.652.059.178.178.415.355.534l8.296 8.236c.356.297.77.475 1.185.475.415 0 .83-.178 1.185-.474a1.68 1.68 0 000-2.37l-7.821-7.763v-8.711c0-.949-.711-1.66-1.66-1.66z"
          fill={colors.a11yRoyalBlue}
        />
      </G>
      <Defs />
    </Svg>
  );
};

export default CircleClockPlaceholderIcon;
