import * as actionTypes from '../constants/JourneyConstants';

const initialState = {
  spinnerMessage: ' ',
  hasAlert: false,
  isUpdating: false,
  isError: false,
  presentingProblemsConditionsMapping: {},
  sessionHighlights: '',
};

export default function journey(state = initialState, action) {
  switch (action.type) {
    case actionTypes.REQUEST_GET_JOURNEY_ITEMS:
      return {
        ...state,
        spinnerMessage: 'Loading journey items',
        isUpdating: true,
        isError: false,
      };

    case actionTypes.REQUEST_GET_JOURNEY_ITEMS_ERROR:
      return {
        ...state,
        spinnerMessage: 'Something went wrong',
        isUpdating: false,
        isError: true,
      };

    case actionTypes.RECEIVE_GET_JOURNEY_ITEMS:
      return {
        ...state,
        journeyItems: action.roomJourneyItems,
        presentingProblemsConditionsMapping: action.presentingProblemsConditionsMapping,
        hasAlert: action.hasAlert,
        spinnerMessage: ' ',
        isUpdating: false,
        isError: false,
      };

    case actionTypes.REQUEST_PATCH_JOURNEY_ITEM:
      return {
        ...state,
        isUpdating: true,
        isError: false,
      };

    case actionTypes.RECEIVE_PATCH_JOURNEY_ITEM:
      return {
        ...state,
        isUpdating: false,
        isError: false,
      };

    case actionTypes.REQUEST_PATCH_JOURNEY_ITEM_ERROR:
      return {
        ...state,
        isUpdating: false,
        isError: true,
      };

    case actionTypes.REQUEST_POST_FEEDBACK_JOURNEY_ITEM:
    case actionTypes.REQUEST_POST_FEEDBACK_JOURNEY_ITEM_ERROR:
      return { ...state };

    case actionTypes.REQUEST_PATCH_FEEDBACK_JOURNEY_ITEM:
    case actionTypes.REQUEST_PATCH_FEEDBACK_JOURNEY_ITEM_ERROR:
      return { ...state };

    case actionTypes.RECEIVE_POST_FEEDBACK_JOURNEY_ITEM:
    case actionTypes.RECEIVE_PATCH_FEEDBACK_JOURNEY_ITEM:
      const journeyItem = state.journeyItems.find((item) => item.id && +item.id === +action.itemID);
      const journeyItems = state.journeyItems.map((item) =>
        +item.id === +journeyItem.id ? { ...item, ...action.requestPayload } : item
      );

      return {
        ...state,
        journeyItems,
      };

    case actionTypes.PREPARE_SESSION_SUMMARIZATION:
      return { ...state, sessionHighlights: action.preparedSessionSummarization };
    case actionTypes.CLEAR_SESSION_SUMMARIZATION:
      return { ...state, sessionHighlights: '' };

    default:
      return state;
  }
}
