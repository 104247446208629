import { ThunkAction } from 'redux-thunk';
import {
  InformedConsent,
  InformedConsentStatus,
  InformedConsentV2StatusAPIPlusClientID,
} from 'ts-frontend/types';
import {
  REQUEST_INFORMED_CONSENTS,
  REQUEST_INFORMED_CONSENTS_ERROR,
  RECEIVE_INFORMED_CONSENTS,
  REQUEST_V2_INFORMED_CONSENT,
  RECEIVE_V2_INFORMED_CONSENT,
  REQUEST_POST_INFORMED_CONSENT,
  REQUEST_POST_INFORMED_CONSENT_ERROR,
  RECEIVE_POST_INFORMED_CONSENT,
  PATCH_INFORMED_CONSENT_STATUS,
  PATCH_INFORMED_CONSENT_STATUS_SUCCESS,
  PATCH_INFORMED_CONSENT_STATUS_ERROR,
  HIDE_INFORMED_CONSENT_SPINNER,
  InformedConsentActionTypes,
} from '../constants/InformedConsentConstants';
import apiWrapper from '../utils/apiWrapper';
import apiHelper from '../utils/api';

type ActionsThunk = ThunkAction<
  Promise<any> | void,
  any, // TODO: Remove any once we use typescript on the whole store
  undefined,
  InformedConsentActionTypes
>;

export function requestInformedConsents(roomID: number): InformedConsentActionTypes {
  return {
    type: REQUEST_INFORMED_CONSENTS,
    roomID,
  };
}

export function requestInformedConsentV2(userID: number): InformedConsentActionTypes {
  return {
    type: REQUEST_V2_INFORMED_CONSENT,
    userID,
  };
}

export function receiveInformedConsentV2(
  informedConsentV2: InformedConsentV2StatusAPIPlusClientID[]
): InformedConsentActionTypes {
  return {
    type: RECEIVE_V2_INFORMED_CONSENT,
    informedConsentV2,
  };
}

export function requestInformedConsentsError(error: Error): InformedConsentActionTypes {
  return {
    type: REQUEST_INFORMED_CONSENTS_ERROR,
    error,
  };
}

export function receiveInformedConsents(
  informedConsents: InformedConsent[]
): InformedConsentActionTypes {
  return {
    type: RECEIVE_INFORMED_CONSENTS,
    informedConsents,
  };
}

export function requestResendInformedConsent(clientID: number): InformedConsentActionTypes {
  return {
    type: REQUEST_POST_INFORMED_CONSENT,
    resendingForClientID: clientID,
  };
}

export function requestResendInformedConsentError(error: Error): InformedConsentActionTypes {
  return {
    type: REQUEST_POST_INFORMED_CONSENT_ERROR,
    error,
  };
}

export function receiveResendInformedConsent(
  informedConsent: InformedConsent
): InformedConsentActionTypes {
  return {
    type: RECEIVE_POST_INFORMED_CONSENT,
    informedConsent,
  };
}

export function patchInformedConsentStatus(
  consentID: number,
  status: InformedConsentStatus
): InformedConsentActionTypes {
  return {
    type: PATCH_INFORMED_CONSENT_STATUS,
    status,
    consentID,
  };
}

export function patchInformedConsentSuccess(
  consentID: number,
  status: InformedConsentStatus
): InformedConsentActionTypes {
  return {
    type: PATCH_INFORMED_CONSENT_STATUS_SUCCESS,
    status,
    consentID,
  };
}

export function patchInformedConsentError(
  consentID: number,
  error: Error
): InformedConsentActionTypes {
  return {
    type: PATCH_INFORMED_CONSENT_STATUS_ERROR,
    consentID,
    error,
  };
}

export function hideSpinner(consentID: number): InformedConsentActionTypes {
  return {
    type: HIDE_INFORMED_CONSENT_SPINNER,
    consentID,
  };
}

export const getInformedConsents = (): ActionsThunk => (dispatch, getState) => {
  const {
    room: { roomID },
  } = getState();
  dispatch(requestInformedConsents(roomID));
  apiWrapper
    .get(`${apiHelper().apiEndpoint}/api/v1/rooms/${roomID}/informed-consents`)
    .then((response) => dispatch(receiveInformedConsents(response.data.data)))
    .catch((error) => dispatch(requestInformedConsentsError(error)));
};

export const getInformedConsentV2 =
  (userIDs: number[]): ActionsThunk =>
  async (dispatch) => {
    const finalResponse: InformedConsentV2StatusAPIPlusClientID[] = [];
    const getConsent = async (userID: number) => {
      dispatch(requestInformedConsentV2(userID));
      try {
        const response = await apiWrapper.get(
          `${apiHelper().apiEndpoint}/v2/clients/${userID}/signed-informed-consent`
        );
        finalResponse.push({
          ...response.data.data,
          clientUserID: userID,
        });
      } catch (error) {
        dispatch(error);
      }
    };

    await Promise.all(userIDs.map((id) => getConsent(id)));
    dispatch(receiveInformedConsentV2(finalResponse));
  };

export const resendInformedConsent =
  (clientID: number): ActionsThunk =>
  (dispatch, getState) => {
    const {
      room: { roomID },
    } = getState();

    dispatch(requestResendInformedConsent(clientID));
    apiWrapper
      .post(`${apiHelper().apiEndpoint}/api/v1/rooms/${roomID}/informed-consents`, {
        clientID,
      })
      .then((response) => dispatch(receiveResendInformedConsent(response.data.data)))
      .catch((error) => dispatch(requestResendInformedConsentError(error)));
  };

export const updateInformedConsentStatus =
  (consentID: number, status: InformedConsentStatus): ActionsThunk =>
  (dispatch, getState) => {
    const {
      room: { roomID },
    } = getState();
    // show spinner for 5 seconds before displaying the buttons again
    const fiveSecondsInMilliseconds = 5000;
    dispatch(patchInformedConsentStatus(consentID, status));
    apiWrapper
      .patch(`${apiHelper().apiEndpoint}/api/v1/rooms/${roomID}/informed-consents/${consentID}`, {
        status,
      })
      .then(() => {
        setTimeout(() => {
          dispatch(hideSpinner(consentID));
        }, fiveSecondsInMilliseconds);
        return dispatch(patchInformedConsentSuccess(consentID, status));
      })
      .catch((error) => {
        setTimeout(() => {
          dispatch(hideSpinner(consentID));
        }, fiveSecondsInMilliseconds);
        return dispatch(patchInformedConsentError(consentID, error));
      });
  };
