import { useEffect, VoidFunctionComponent } from 'react';
import {
  View,
  Huge,
  Large,
  useUniqueID,
  Link,
  SparklingInsuranceCard,
  useEmotionTheme,
  styled,
} from '@talkspace/react-toolkit';
import { trackNotEligibleToBookSession } from 'ts-analytics/mixpanel/events';
import { getUserData } from '@/auth/helpers/token';
import { useRouteMatch } from '@/core/routerLib';
import { TherapistInfo } from '../../types';

interface Props {
  therapist?: TherapistInfo;
  isPermanentIneligible?: boolean;
}

const TitleStyle = styled(Huge)({
  textAlign: 'center',
  marginBottom: 20,
  marginTop: 30,
  marginLeft: 15.5,
  marginRight: 15.5,
});

const LargeStyle = styled(Large)({
  textAlign: 'center',
  marginBottom: 50,
  marginLeft: 15.5,
  marginRight: 15.5,
});

const IneligibleMembers: VoidFunctionComponent<Props> = ({
  therapist,
  isPermanentIneligible = false,
}) => {
  const { colors } = useEmotionTheme();
  const ID_TITLE = useUniqueID('titleId');
  const ID_SUB_TITLE = useUniqueID('subTitleId');
  const { id: userID } = getUserData();
  const match = useRouteMatch<{ roomID: string }>();

  useEffect(() => {
    if (therapist?.id && match.params.roomID && userID) {
      trackNotEligibleToBookSession({
        userID,
        roomID: Number(match.params.roomID),
        providerID: therapist?.id,
        isPermanentIneligible,
      });
    }
  }, [match.params.roomID, therapist?.id, userID, isPermanentIneligible]);

  return (
    <View flex={1} justify="space-between">
      <View align="center">
        <SparklingInsuranceCard />
        <TitleStyle as="h1" id={ID_TITLE}>
          We weren’t able to locate your insurance coverage
        </TitleStyle>

        <LargeStyle id={ID_SUB_TITLE} variant="largeDarkGrey">
          Please try your request again. If you’ve tried and are still having trouble, please
          contact your health plan and ensure that your information or coverage hasn’t changed.{' '}
          <br />
          <br /> If your insurance has changed, then please
          <Link
            href="/eligibility-widget"
            style={{ color: colors.permaTalkspaceDarkGreen, fontWeight: 700 }}
          >
            check your eligibility
          </Link>
          for Talkspace using the new insurance details.
        </LargeStyle>
      </View>
    </View>
  );
};

export default IneligibleMembers;
