import Svg, { Path } from 'svgs';

function XIcon({ style = {}, color = '#bababa', width = 9, height = 9 }) {
  return (
    <Svg
      width={width}
      height={height}
      style={style}
      viewBox="0 0 13 13"
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <Path
        d="M6.25 4.129L9.94.439a1.5 1.5 0 1 1 2.12 2.122L8.372 6.25l3.69 3.69a1.5 1.5 0 1 1-2.122 2.12L6.25 8.372l-3.69 3.69A1.5 1.5 0 1 1 .44 9.939L4.128 6.25.439 2.56A1.5 1.5 0 1 1 2.561.44L6.25 4.128z"
        fill={color || 'FF0050'}
        fillRule="nonzero"
      />
    </Svg>
  );
}

XIcon.displayName = 'XIcon';

export default XIcon;
