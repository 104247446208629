import Svg, { Path, Rect } from 'svgs';

interface Props {
  width?: string | number;
  height?: string | number;
  color?: string;
}

const AddAvailabilityIcon = ({ width = 20, height = 20, color = '#005E63' }: Props) => (
  <Svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <Path
      d="M2.75 5C2.75 3.75736 3.75736 2.75 5 2.75H16C17.2426 2.75 18.25 3.75736 18.25 5V15C18.25 16.2426 17.2426 17.25 16 17.25H5C3.75736 17.25 2.75 16.2426 2.75 15V5Z"
      stroke={color}
      stroke-width="1.5"
    />
    <Rect x="9.5" y="7.5" width="2" height="7" rx="1" fill={color} />
    <Rect x="7" y="10" width="7" height="2" rx="1" fill={color} />
    <Path d="M3 3H18V6H3V3Z" fill={color} />
  </Svg>
);

export default AddAvailabilityIcon;
