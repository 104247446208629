import { FunctionComponent } from 'react';
import styled from '../../core/styled';
import useWindowWidth from '../../hooks/useWindowWidth';
import CloseButton from '../CloseButton';
import Modal from '../Modal';
import TouchableView from '../TouchableView';
import View from '../View';

const VideoCloseButton = styled(CloseButton)<{ isMobile: boolean }>(({ isMobile }) => {
  return {
    position: 'absolute',
    top: isMobile ? 5 : 15,
    right: isMobile ? 5 : 15,
  };
});

const VideoWrapper = styled.video`
  max-width: 84vw;
  max-height: 84vh;
`;

const TherapistVideoModal: FunctionComponent<{
  therapistVideoUrl?: string;
  isVisible: boolean;
  onBackdropPress: () => void;
}> = ({ therapistVideoUrl, isVisible, onBackdropPress }) => {
  const { isMobile } = useWindowWidth();
  return (
    <Modal underlayClick={false} isSecondModal isVisible={isVisible}>
      <View style={{ position: 'relative' }}>
        <TouchableView>
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <VideoWrapper src={therapistVideoUrl} controls autoPlay />
        </TouchableView>
        <VideoCloseButton onPress={onBackdropPress} isMobile={isMobile} />
      </View>
    </Modal>
  );
};

export default TherapistVideoModal;
