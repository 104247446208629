import Svg, { Path } from 'svgs';
import { useEmotionTheme } from '../../core/styled';

interface StarsIconProps {
  width?: number;
  height?: number;
  color?: string;
}

const StarsIcon = ({ width = 20, height = 20, color, ...otherProps }: StarsIconProps) => {
  const titleText = 'star sign';
  const { colors } = useEmotionTheme();
  return (
    <Svg
      title={titleText}
      aria-label={titleText}
      width={width}
      height={height}
      fill="none"
      {...otherProps}
    >
      <Path
        d="M12.2344 4.6625C12.0938 4.71562 12 4.85 12 5C12 5.15 12.0938 5.28438 12.2344 5.3375L14 6L14.6625 7.76562C14.7156 7.90625 14.85 8 15 8C15.15 8 15.2844 7.90625 15.3375 7.76562L16 6L17.7656 5.3375C17.9062 5.28438 18 5.15 18 5C18 4.85 17.9062 4.71562 17.7656 4.6625L16 4L15.3375 2.23438C15.2844 2.09375 15.15 2 15 2C14.85 2 14.7156 2.09375 14.6625 2.23438L14 4L12.2344 4.6625ZM8.40938 4.29063C8.32812 4.1125 8.15 4 7.95625 4C7.7625 4 7.58438 4.1125 7.50313 4.29063L5.85313 7.85313L2.29063 9.5C2.1125 9.58125 2 9.75938 2 9.95625C2 10.1531 2.1125 10.3281 2.29063 10.4094L5.85625 12.0562L7.5 15.6187C7.58125 15.7969 7.75938 15.9094 7.95312 15.9094C8.14687 15.9094 8.325 15.7969 8.40625 15.6187L10.0531 12.0531L13.6187 10.4062C13.7969 10.325 13.9094 10.1469 13.9094 9.95312C13.9094 9.75938 13.7969 9.58125 13.6187 9.5L10.0562 7.85625L8.40938 4.29063ZM14 14L12.2344 14.6625C12.0938 14.7156 12 14.85 12 15C12 15.15 12.0938 15.2844 12.2344 15.3375L14 16L14.6625 17.7656C14.7156 17.9062 14.85 18 15 18C15.15 18 15.2844 17.9062 15.3375 17.7656L16 16L17.7656 15.3375C17.9062 15.2844 18 15.15 18 15C18 14.85 17.9062 14.7156 17.7656 14.6625L16 14L15.3375 12.2344C15.2844 12.0938 15.15 12 15 12C14.85 12 14.7156 12.0938 14.6625 12.2344L14 14Z"
        fill={color || colors.accessibilityGreenDark}
      />
    </Svg>
  );
};

export default StarsIcon;
