import Svg, { Defs, G, Path } from 'svgs';
import { EmotionStyle, useEmotionTheme } from '../../core/styled';
import { useUniqueID } from '../../hooks/a11yHelper';

interface DidYouKnowShieldProps {
  width?: number;
  height?: number;
  style?: EmotionStyle;
}

const titleText = 'White shield with a plus in the center';

const DidYouKnowShield = ({ width = 90, height = 90, ...otherProps }: DidYouKnowShieldProps) => {
  const { colors } = useEmotionTheme();
  const shieldFilterID = useUniqueID('didYouKnowShieldFilterID');
  const effectBackgroundImageId = useUniqueID('didYouKnowShieldEffectBackgroundImageID');
  const effectDropShadowId = useUniqueID('didYouKnowShieldEffectDropShadowID');
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 90 90"
      fill="none"
      title={titleText}
      aria-label={titleText}
      {...otherProps}
    >
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <G filter={`url(#${shieldFilterID})`}>
        <Path
          d="M60 7H30c-11.046 0-20 8.954-20 20v30c0 11.046 8.954 20 20 20h30c11.046 0 20-8.954 20-20V27c0-11.046-8.954-20-20-20z"
          fill={colors.white}
        />
      </G>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.43 27.645c.13.062.253.143.363.244a21.58 21.58 0 0015.272 5.383 1.5 1.5 0 011.508 1.075 24.58 24.58 0 01-17.411 30.725 1.501 1.501 0 01-.753 0 24.578 24.578 0 01-17.411-30.725 1.5 1.5 0 011.508-1.075 21.58 21.58 0 0015.271-5.383 1.488 1.488 0 011.008-.389c.22 0 .44.048.645.145z"
        fill={colors.permaSolitudeNew}
      />
      <Path
        d="M45.785 24a23.08 23.08 0 0016.348 5.77 23.08 23.08 0 01-16.348 28.85 23.08 23.08 0 01-16.348-28.85A23.08 23.08 0 0045.785 24"
        stroke={colors.purple}
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.5 33a2 2 0 00-2 2v5h-5a2 2 0 100 4h5v5a2 2 0 104 0v-5h5a2 2 0 100-4h-5v-5a2 2 0 00-2-2z"
        fill={colors.purple}
      />
      <Defs>
        <filter
          id={shieldFilterID}
          x="0"
          y="0"
          width={width}
          height={height}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result={effectBackgroundImageId} />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.427621 0 0 0 0 0.584314 0 0 0 0.3 0"
          />
          <feBlend mode="normal" in2={effectBackgroundImageId} result={effectDropShadowId} />
          <feBlend mode="normal" in="SourceGraphic" in2={effectDropShadowId} result="shape" />
        </filter>
      </Defs>
    </Svg>
  );
};

export default DidYouKnowShield;
