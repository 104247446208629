import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getArchivedTreatmentPlans } from '../../../../actions/TreatmentPlannerActions';
import AccordionContainer from '../../../Reusable/AccordionContainer/AccordionContainer';
import AccordionMenuItem from '../../../Reusable/AccordionMenuItem/AccordionMenuItem';
import Dialog from '../../../Reusable/Dialog/Dialog';
import { closeSurveyAlert, switchSurvey } from '../../../../actions/ClinicalInformationActions';
import OutcomeMeasures from './OutcomeMeasures/OutcomeMeasures';
import DiagnosticProfileCard from './DiagnosticProfile/DiagnosticProfileCard';
import ClinicalInformation from './ClinicalInformation/ClinicalInformation';
import MedicalInformation from './MedicalInformation/MedicalInformation';
import TreatmentPlanner from './TreatmentPlanner/TreatmentPlanner';
import Psychiatry from './Psychiatry/Psychiatry';

class tsClinicalTab extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    props.getArchivedTreatmentPlans();
  }

  switchSurvey = () => {
    this.props.switchSurvey(
      this.props.clientUserID,
      this.props.roomID,
      this.props.therapistID,
      this.props.clinicalInformation.conditionSurvey.Survey.surveyID,
      this.props.clinicalInformation.conditionSurvey.mentalConditionID
    );
  };

  render() {
    return (
      <div className="ts-crm-panel">
        {this.props.clinicalInformation.surveyAlert ? (
          <Dialog
            title="Mental condition alert"
            text={`The selected mental conditions are associated with ${this.props.clinicalInformation.conditionSurvey.Survey.surveyLabel} assessment which is different than ${this.props.clinicalInformation.currentSurvey.surveyLabel} currently scheduled for this room. Do you want to change the assessment to ${this.props.clinicalInformation.conditionSurvey.Survey.surveyLabel}?`}
            buttons={[
              { title: 'No', action: this.props.closeSurveyAlert },
              { title: 'Yes', action: this.switchSurvey },
            ]}
          />
        ) : null}
        <ClinicalInformation nycTeensIntake={this.props.nycTeensIntake} />
        <MedicalInformation />
        <DiagnosticProfileCard />
        <Psychiatry />
        <OutcomeMeasures />
        <TreatmentPlanner />
        {!!this.props.archivedTreatmentPlans && !!this.props.archivedTreatmentPlans.length && (
          <AccordionContainer
            title="Clinical Actions"
            dataQa="clinicalActionsAccordion"
            childComponents={[
              <AccordionMenuItem
                key={4}
                path="clinical-tab"
                view="archived-treatment-plans"
                title="Archived treatment plans"
              />,
            ]}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clinicalInformation: state.clinicalInformation,
    roomID: state.room.roomID,
    clientUserID: state.customerInformation.clientUserID,
    therapistID: state.availability.id,
    archivedTreatmentPlans: state.treatmentPlanner.archivedTreatmentPlans,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      closeSurveyAlert,
      switchSurvey,
      getArchivedTreatmentPlans,
    },
    dispatch
  );

const ClinicalTab = connect(mapStateToProps, mapDispatchToProps)(tsClinicalTab);
export default ClinicalTab;
