import Svg, { Path } from 'svgs';
import { IconProps, IconConfig } from '../types/types';
import useIconVariants from './useIconVariants';

type IconMakerProps = IconProps & { titleText: string; iconConfig: IconConfig };

// Icon parameters:
// * `size` (small: 16x16, standard: 20x20, major: 24x24, huge: 32x32) - defines the size of the icon and icon config to use
// * `variant` ('default', 'filled') - defines the svg path to render for the specific size
// * `colorType` and `state` - defining the corresponding icon color role `icon${colorType}${state}`
const IconMaker = ({ colorType, variant, state, size, titleText, iconConfig }: IconMakerProps) => {
  const { width, height, path, pathProps } = useIconVariants({
    variant,
    colorType,
    iconConfig,
    size,
    state,
  });

  return (
    <Svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      title={titleText}
      aria-label={titleText}
    >
      <Path d={path} {...pathProps} />
    </Svg>
  );
};

// Use this HOC to define new icons in a standardized way by passing appropriate iconConfig
// * must have at least 'standard' size path defined
// * can optionally define 'filled' path variant for each size
// * can optionally pass 'evenOddFilling' parameter to add `fillRule: 'evenodd'` for a specific path
export const withDSIconMaker = (iconConfig: IconConfig, titleText: string) => (props: IconProps) =>
  <IconMaker iconConfig={iconConfig} titleText={titleText} {...props} />;

export default IconMaker;
