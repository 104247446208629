import styled from '../../../core/styled';
import Button from '../Button';

const Brick = styled(Button)<{ isSelected: boolean }>(
  ({ isSelected = false, theme: { colors } }) => {
    return {
      height: 49,
      color: isSelected ? colors.white : colors.green,
      backgroundColor: isSelected ? colors.green : colors.white,
      borderRadius: 7,
      borderColor: isSelected ? colors.green : colors.blueHaze,
      borderStyle: 'solid',
      fontWeight: 700,
      letterSpacing: 0.3,
      fontSize: 16,
      borderWidth: 1,
      padding: 13,
      display: 'inline',
      width: 'fit-content',
      margin: 5,
    };
  }
);

export default Brick;
