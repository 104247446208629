import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import {
  isBiometricLoginAvailable,
  nativeBiometricVerifyIdentity,
  LoginBiometryType,
} from 'ts-ionic/plugins/biometrics';
import { addIonicAppListener } from 'ts-ionic/plugins/capacitor';
import { useOpenModal } from '../modules/utils/ModalsContextProvider';
import { getOptedInBiometricLock, getUserData } from '../utils/token';
import useIonicEffect from './useIonicEffect';

type BiometricLoginReturn = {
  shouldRegisterBiometricLock: boolean;
  shouldPromptBiometricLock: boolean;
  biometryType: LoginBiometryType;
  promptBiometricLock(): Promise<boolean>;
};

type BiometricLockContextType = {
  unlockedDevice: boolean;
  setUnlockedDevice(value: boolean): void;
};

export const BiometricLockContext = createContext<BiometricLockContextType>({
  unlockedDevice: false,
  setUnlockedDevice: () => {},
});

export const BiometricLockProvider: React.FC = ({ children }) => {
  const [unlockedDevice, setUnlockedDevice] = useState(false);

  useIonicEffect(() => {
    const listener = addIonicAppListener('resume', () => {
      setUnlockedDevice(false);
    });

    return () => {
      listener.remove();
    };
  }, []);

  return (
    <BiometricLockContext.Provider value={{ unlockedDevice, setUnlockedDevice }}>
      {children}
    </BiometricLockContext.Provider>
  );
};

const useBiometricLock = (
  { shouldVerify }: { shouldVerify: boolean } = { shouldVerify: true }
): BiometricLoginReturn => {
  const [shouldRegisterBiometricLock, setShouldRegisterBiometricLock] = useState(false);
  const [shouldPromptBiometricLock, setShouldPromptBiometricLock] = useState(false);
  const [biometryType, setBiometryType] = useState<LoginBiometryType>(null);
  const { unlockedDevice, setUnlockedDevice } = useContext(BiometricLockContext);

  useIonicEffect(() => {
    isBiometricLoginAvailable().then(({ isAvailable, biometryType: type }) => {
      if (isAvailable) {
        // If already configured, return false
        if (getOptedInBiometricLock() === false) setShouldRegisterBiometricLock(true);
        else if (getOptedInBiometricLock() === true && !unlockedDevice)
          setShouldPromptBiometricLock(true);

        setBiometryType(type);
      }
    });
  }, [shouldVerify, unlockedDevice]);

  useEffect(() => {
    if (unlockedDevice) {
      setShouldPromptBiometricLock(false);
      setShouldRegisterBiometricLock(false);
    }
  }, [unlockedDevice]);

  const promptBiometricLock = useCallback(async () => {
    const verified = await nativeBiometricVerifyIdentity({
      maxAttempts: 5,
      reason: 'Biometric Lock',
      title: 'Unlock App',
      subtitle: '',
      description: '',
    });
    // Registering and unlocking both require face ID. Mark the device as unlocked if verified.
    if (verified) {
      setUnlockedDevice(true);
    }
    return verified;
  }, [setUnlockedDevice]);

  return {
    shouldRegisterBiometricLock,
    shouldPromptBiometricLock,
    biometryType,
    promptBiometricLock,
  };
};

export const BiometricLockTrigger = () => {
  const registrationRoute = '/register-biometric-lock';
  const promptRoute = '/biometric-lock';
  const routePathStartBlacklist = ['/2fa', '/email-verification', '/sso/logout'];

  const location = useLocation();
  const { pathname } = location;

  const { shouldRegisterBiometricLock, shouldPromptBiometricLock } = useBiometricLock();
  const openModal = useOpenModal();

  const isLoggedIn = getUserData()?.id;
  const isPathAllowed =
    routePathStartBlacklist.find((path) => pathname.startsWith(path)) === undefined;

  useIonicEffect(() => {
    if (isLoggedIn && isPathAllowed) {
      if (shouldRegisterBiometricLock) openModal(registrationRoute, {}, false, true);
      if (shouldPromptBiometricLock) openModal(promptRoute, {}, false, true);
    }
  }, [
    isLoggedIn,
    isPathAllowed,
    openModal,
    shouldPromptBiometricLock,
    shouldRegisterBiometricLock,
  ]);

  return null;
};

export default useBiometricLock;
