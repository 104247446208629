import { useQuery } from 'react-query';
import { NoteStatus, User } from 'components/Room/CRMContainer/NotesTab/types';
import apiHelper from '@/utils/api';
import apiWrapper from '../../utils/apiWrapper';
import { caseConsultationNoteQueryKey } from './queryKeys';

export interface CaseConsultationNoteQueryResponse {
  submittedAt: string;
  roomID: number;
  createdByUser: User;
  status: NoteStatus;
  consultationDate: string;
  personConsultedName: string | null;
  personConsultedCredentials: string | null;
  consultationSummary: string | null;
  planOfAction: string | null;
  followup: string | null;
  statementCertified: boolean;
  readOnly: boolean;
}

const fetchNote = (clientUserID?: number, noteID?: string) => async () => {
  const { data } = await apiWrapper.get(
    `${apiHelper().apiEndpoint}/v2/clients/${clientUserID}/case-consultation-notes/${noteID}`
  );
  return data.data;
};

const useQueryCaseConsultationNote = (
  clientUserID?: number,
  noteID?: string,
  enabled: boolean = true
) =>
  useQuery<CaseConsultationNoteQueryResponse, Error>({
    queryKey: caseConsultationNoteQueryKey(clientUserID, noteID),
    queryFn: fetchNote(clientUserID, noteID),
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!clientUserID && !!noteID && enabled,
  });

export default useQueryCaseConsultationNote;
