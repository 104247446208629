import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const standardPath =
  'M2.84281 4.25C2.84281 3.25544 3.24215 2.30161 3.95299 1.59835C4.66383 0.895088 5.62794 0.5 6.63322 0.5C7.63849 0.5 8.6026 0.895088 9.31344 1.59835C10.0243 2.30161 10.4236 3.25544 10.4236 4.25C10.4236 5.24456 10.0243 6.19839 9.31344 6.90165C8.6026 7.60491 7.63849 8 6.63322 8C5.62794 8 4.66383 7.60491 3.95299 6.90165C3.24215 6.19839 2.84281 5.24456 2.84281 4.25ZM0 14.6299C0 11.7441 2.36308 9.40625 5.27992 9.40625H7.98651C10.9033 9.40625 13.2664 11.7441 13.2664 14.6299C13.2664 15.1104 12.8726 15.5 12.3869 15.5H0.879493C0.393847 15.5 0 15.1104 0 14.6299ZM12.9999 4.68945C13.2783 4.41406 13.7284 4.41406 14.0038 4.68945L15.3956 6.06641L16.7874 4.68945C17.0657 4.41406 17.5158 4.41406 17.7912 4.68945C18.0666 4.96484 18.0696 5.41016 17.7912 5.68262L16.3994 7.05957L17.7912 8.43652C18.0696 8.71191 18.0696 9.15723 17.7912 9.42969C17.5129 9.70215 17.0628 9.70508 16.7874 9.42969L15.3956 8.05273L14.0038 9.42969C13.7254 9.70508 13.2753 9.70508 12.9999 9.42969C12.7245 9.1543 12.7216 8.70898 12.9999 8.43652L14.3917 7.05957L12.9999 5.68262C12.7216 5.40723 12.7216 4.96191 12.9999 4.68945Z';

const pathConfig: IconConfig = {
  standard: {
    default: {
      path: standardPath,
    },
  },
};

const UserXMark = withDSIconMaker(pathConfig, 'User With X Mark');

export default UserXMark;
