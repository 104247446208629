import { spacing } from '../../tokens';
import styled from '../../../core/styled';
import View from '../../../components/View';
import { TextDS } from '../../../components';
import { ColorRolesVersion } from '../../types';
import { FillContainerBannerVariant, FillContainerBannerProps } from './types';

const { space150, space100 } = spacing;

const getBackgroundColor = (
  colorRoles: ColorRolesVersion,
  variant?: FillContainerBannerVariant
) => {
  switch (variant) {
    case 'success':
      return colorRoles.surfaces.successDefault;
    case 'warning':
      return colorRoles.surfaces.warningDefault;
    case 'critical':
      return colorRoles.surfaces.criticalBoldDefault;
    case 'disabled':
      return colorRoles.surfaces.neutralDisabled;
    case 'info':
      return colorRoles.surfaces.neutralDisabled;
    default:
      return '';
  }
};

const getIconColorType = (variant?: FillContainerBannerVariant) => {
  if (variant === 'disabled') return 'subtlest';
  return variant;
};

const FillContainer = styled(View)<{
  variant?: FillContainerBannerVariant;
  alignCenter?: boolean;
}>(({ variant, alignCenter, theme: { colorRoles } }) => {
  return {
    flex: 1,
    flexDirection: 'row',
    justifyContent: alignCenter ? 'center' : '',
    gap: space100,
    padding: space150,
    background: getBackgroundColor(colorRoles, variant),
  };
});

const FillContainerBanner = ({
  variant,
  text,
  alignCenter = true,
  Icon,
}: FillContainerBannerProps) => (
  <FillContainer variant={variant} alignCenter={alignCenter}>
    <View>{Icon ? <Icon colorType={getIconColorType(variant)} size="standard" /> : null}</View>
    <TextDS variant="bodySm" colorRole="textDefault">
      {text}
    </TextDS>
  </FillContainer>
);

export default FillContainerBanner;
