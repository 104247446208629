import { useWindowWidthState } from '@talkspace/react-toolkit';

export default function useDashboardSize() {
  const { isDesktop, width } = useWindowWidthState();

  const isDashboardMobile = width <= 991;

  return {
    isDashboardMobile,
    isDesktop,
  };
}
