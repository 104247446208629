export const REQUEST_GET_AVAILABILITY = 'REQUEST_GET_AVAILABILITY';
export const RECEIVE_GET_AVAILABILITY = 'RECEIVE_GET_AVAILABILITY';
export const REQUEST_GET_AVAILABILITY_ERROR = 'REQUEST_GET_AVAILABILITY_ERROR';

export const REQUEST_PUT_AVAILABILITY = 'REQUEST_PUT_AVAILABILITY';
export const RECEIVE_PUT_AVAILABILITY = 'RECEIVE_PUT_AVAILABILITY';
export const REQUEST_PUT_AVAILABILITY_ERROR = 'REQUEST_PUT_AVAILABILITY_ERROR';

export const REQUEST_GET_VIDEO_SESSION_HOURS = 'REQUEST_GET_VIDEO_SESSION_HOURS';
export const RECEIVE_GET_VIDEO_SESSION_HOURS = 'RECEIVE_GET_VIDEO_SESSION_HOURS';
export const REQUEST_GET_VIDEO_SESSION_HOURS_ERROR = 'REQUEST_GET_VIDEO_SESSION_HOURS_ERROR';

export const REQUEST_PUT_VIDEO_SESSION_HOURS = 'REQUEST_PUT_VIDEO_SESSION_HOURS';
export const RECEIVE_PUT_VIDEO_SESSION_HOURS = 'RECEIVE_PUT_VIDEO_SESSION_HOURS';
export const REQUEST_PUT_VIDEO_SESSION_HOURS_ERROR = 'REQUEST_PUT_VIDEO_SESSION_HOURS_ERROR';
export const REQUEST_PUT_VIDEO_SESSION_HOURS_AUTH_ERROR =
  'REQUEST_PUT_VIDEO_SESSION_HOURS_AUTH_ERROR';

export const REQUEST_GET_CAPACITY = 'REQUEST_GET_CAPACITY';
export const RECEIVE_GET_CAPACITY = 'RECEIVE_GET_CAPACITY';
export const REQUEST_GET_CAPACITY_ERROR = 'REQUEST_GET_CAPACITY_ERROR';

export const REQUEST_PUT_CAPACITY = 'REQUEST_PUT_CAPACITY';
export const RECEIVE_PUT_CAPACITY = 'RECEIVE_PUT_CAPACITY';
export const REQUEST_PUT_CAPACITY_ERROR = 'REQUEST_PUT_CAPACITY_ERROR';

export const REQUEST_GET_PHASE = 'REQUEST_GET_PHASE';
export const RECEIVE_GET_PHASE = 'RECEIVE_GET_PHASE';
export const REQUEST_GET_PHASE_ERROR = 'REQUEST_GET_PHASE_ERROR';

export const REQUEST_GET_BUSINESS_DAYS = 'REQUEST_GET_BUSINESS_DAYS';
export const RECEIVE_GET_BUSINESS_DAYS = 'RECEIVE_GET_BUSINESS_DAYS';
export const REQUEST_GET_BUSINESS_DAYS_ERROR = 'REQUEST_GET_BUSINESS_DAYS_ERROR';

export const REQUEST_SAVE_BUSINESS_DAYS = 'REQUEST_SAVE_BUSINESS_DAYS';
export const RECEIVE_SAVE_BUSINESS_DAYS = 'RECEIVE_SAVE_BUSINESS_DAYS';
export const REQUEST_SAVE_BUSINESS_DAYS_ERROR = 'REQUEST_SAVE_BUSINESS_DAYS_ERROR';

export const REQUEST_GET_DEPARTURE_INFO = 'REQUEST_GET_DEPARTURE_INFO';
export const RECEIVE_GET_DEPARTURE_INFO = 'RECEIVE_GET_DEPARTURE_INFO';
export const GET_DEPARTURE_INFO_ERROR = 'GET_DEPARTURE_INFO_ERROR';

export const REQUEST_GET_PROVIDER_DAILY_CASELOAD_OPTIONS =
  'REQUEST_GET_PROVIDER_DAILY_CASELOAD_OPTIONS';
export const RECEIVE_GET_PROVIDER_DAILY_CASELOAD_OPTIONS =
  'RECEIVE_GET_PROVIDER_DAILY_CASELOAD_OPTIONS';
export const REQUEST_GET_PROVIDER_DAILY_CASELOAD_OPTIONS_ERROR =
  'REQUEST_GET_PROVIDER_DAILY_CASELOAD_OPTIONS_ERROR';
