import { FunctionComponent } from 'react';
import * as React from 'react';
import TherapistStatus from '../TherapistStatus';
import { Heading3 } from '../Typography';
import View from '../View';
import Text from '../Text';
import Avatar from '../Avatar';
import styled from '../../core/styled';

interface Props {
  image: string;
  name: string;
  licenses: string[];
  status: string;
  wrapperProps?: React.ComponentProps<typeof Wrapper>;
  rightController?: React.ReactNode;
}

const Wrapper = styled(View)({
  width: '100%',
  marginTop: 5,
  marginBottom: 5,
});

const Content = styled(View)(({ theme: { colors } }) => {
  return {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 15,
    paddingBottom: 15,
    borderColor: colors.extraLightGrey,
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 10,
  };
});

const Name = styled(Heading3)({
  textAlign: 'left',
});

const License = styled(Text)(({ theme: { colors } }) => {
  return { color: colors.placeholderGrey, fontSize: 15, textAlign: 'left', marginTop: 5 };
});

const TherapistCardSmall: FunctionComponent<Props> = ({
  image,
  name,
  licenses,
  wrapperProps,
  status,
  rightController,
}) => (
  <Wrapper {...wrapperProps}>
    <Content row>
      <View
        alignSelf="center"
        flex={2}
        style={{
          position: 'relative',
        }}
      >
        <Avatar width={60} height={60} image={image} />
        <TherapistStatus
          status={status}
          style={{
            position: 'absolute',
            bottom: 1,
            right: 7,
          }}
        />
      </View>
      <View
        flex={5}
        align="start"
        alignSelf="center"
        style={{
          marginLeft: 10,
          marginRight: 10,
        }}
      >
        <Name>{name}</Name>
        <License>{licenses.join(', ')}</License>
      </View>
      <View align="end" justify="center" flex={1}>
        {!!rightController && rightController}
      </View>
    </Content>
  </Wrapper>
);

export default TherapistCardSmall;
