import { createContext, useContext, FunctionComponent, useEffect, useState } from 'react';
import { Stripe, loadStripe } from '@stripe/stripe-js';

import { Spinner, View } from '@talkspace/react-toolkit';
import appConfig from '@/utils/configs';
import { useOpenModal, getMemoryHistory } from '@/utils/ModalsContextProvider';
import loadStripeTs from './loadStripe';

const LoadingScreen = () => (
  <View style={{ height: '100vh' }}>
    <View flex={1}>
      <Spinner isLoading />
    </View>
  </View>
);

const StripeContext = createContext<Stripe | null | undefined>(undefined);

async function getStripeFromWindow(useStripeLink: boolean): Promise<Stripe> {
  let stripe;

  if (useStripeLink) {
    stripe = await loadStripe(appConfig.stripe.publicKey, {
      betas: ['link_beta_3'],
      apiVersion: '2019-11-05;link_beta=v1',
    });
  } else {
    stripe = await loadStripeTs(appConfig.stripe.publicKey);
  }

  if (!stripe) throw new Error('stripe object was empty');
  return stripe;
}

export const StripeProvider: FunctionComponent<{
  getUseStripeLinkFlag: () => Promise<boolean>;
}> = ({ children, getUseStripeLinkFlag }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [stripe, setStripe] = useState<Stripe | null | undefined>(null);

  const loadStripeWithRetry = (useStripeLink, max, retry = 1) => {
    if (retry > max) {
      setIsLoading(false);
      return;
    }

    getStripeFromWindow(useStripeLink)
      .then((maybeStripe) => {
        setStripe(() => maybeStripe);
        setIsLoading(false);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(`Error initializing stripe #${retry}`, err.message);
        loadStripeWithRetry(useStripeLink, max, retry + 1);
      });
  };

  useEffect(() => {
    const loadStripeAsync = async () => {
      const useStripeLink = await getUseStripeLinkFlag();
      loadStripeWithRetry(useStripeLink, 2);
    };
    loadStripeAsync();
    // TMP: Fixing many bugs, don't want to break stripe by attempting to fix this one
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) return <LoadingScreen />;
  return <StripeContext.Provider value={stripe}>{children}</StripeContext.Provider>;
};

let errorModalIsOpen = false;

export function useStripeTs() {
  const NOOP = () => {
    // eslint-disable-next-line no-console
    console.error('Stripe initialization failed');
  };

  let openModal;
  const modalHistory = getMemoryHistory();

  try {
    const openModalFunction = useOpenModal();
    openModal = openModalFunction;
  } catch (error) {
    // We are in a modal
  }

  const stripe = useContext(StripeContext);

  if (stripe === undefined) {
    throw new Error('StripeContext must be used within a ContextProvider');
  }

  if (!stripe) {
    if (!errorModalIsOpen) {
      if (openModal) {
        openModal('/stripe-error', { inModal: false });
      } else if (modalHistory) {
        modalHistory.push('/stripe-error', { inModal: true });
      }
      errorModalIsOpen = true;
    }
    return {
      elements: () => {
        return {
          create: () => {
            return {
              mount: NOOP,
              on: NOOP,
            };
          },
        };
      },
    } as any as Stripe;
  }
  return stripe;
}

export function isStripeUsingLink(myStripe: any) {
  return (
    myStripe?._controller?._apiVersion && // eslint-disable-line no-underscore-dangle
    myStripe?._controller?._apiVersion.indexOf('link_beta') > 0 // eslint-disable-line no-underscore-dangle
  );
}
