import UnionBy from 'lodash/unionBy';
import CloneDeep from 'lodash/cloneDeep';
import Difference from 'lodash/difference';
import DifferenceWith from 'lodash/differenceWith';
import IntersectionWith from 'lodash/intersectionWith';

export const difference = (_selectedItems, _standardItems) => {
  if (
    !Array.isArray(_selectedItems) ||
    !Array.isArray(_standardItems) ||
    _selectedItems.length !== _standardItems.length
  )
    return true;

  const selectedItems = _selectedItems.sort();
  const standardItems = _standardItems.sort();

  for (let i = 0; i < selectedItems.length; i++) {
    if (selectedItems[i] !== standardItems[i]) return true;
  }
  return false;
};

export const differenceWith = (standardItems, selectedItems, onField) =>
  DifferenceWith(standardItems, selectedItems, onField);

export const getCombinedItems = (selectedItems, standardItems, onField) =>
  UnionBy(selectedItems, standardItems, onField);

export const getIntersection = (selectedItems, standardItems, onField) =>
  IntersectionWith(selectedItems, standardItems, onField);

export const getPreviousItems = (combinedItems) =>
  CloneDeep(combinedItems.filter((item) => item.checked));

export const doActions = (
  treatmentPlanID,
  mixedItems,
  previousItems,
  addAction,
  removeAction,
  editAction
) => {
  const currentItems = mixedItems
    .map(({ itemIdx, ...item }) => item)
    .filter((item) => item.checked);

  const removeIDs = Difference(
    previousItems.map((item) => item.id),
    currentItems.map((item) => item.id)
  );
  const itemsToRemove = previousItems.filter((item) => removeIDs.includes(item.id));

  const addIDs = Difference(
    currentItems.map((item) => item.id),
    previousItems.map((item) => item.id)
  );
  const itemsToAdd = currentItems.filter((item) => addIDs.includes(item.id));

  const itemsToEdit = DifferenceWith(
    currentItems.filter((item) => item.id !== undefined && !item.standard),
    previousItems.filter((item) => item.id !== undefined && !item.standard),
    (currentItem, previousItem) =>
      previousItem.id === currentItem.id && previousItem.description === currentItem.description
  );

  const operationsArray = [];

  if (itemsToRemove && itemsToRemove.length)
    operationsArray.concat(itemsToRemove.map((item) => removeAction(treatmentPlanID, item.id)));

  if (itemsToAdd && itemsToAdd.length) operationsArray.push(addAction(treatmentPlanID, itemsToAdd));

  if (itemsToEdit && itemsToEdit.length)
    operationsArray.concat(
      itemsToEdit.map(({ id, description }) => editAction(treatmentPlanID, id, { description }))
    );

  return Promise.all(operationsArray);
};
