import { useUniqueID } from '@talkspace/react-toolkit';
import { VoidFunctionComponent } from 'react';
import Svg, { Defs, Circle, Path, G, LinearGradient, Use } from 'svgs';
import { EmotionStyle } from '../../../core/styled';

interface IconSubmitNotesProps {
  width?: number;
  height?: number;
  style?: EmotionStyle;
}

const IconSubmitNotes: VoidFunctionComponent<IconSubmitNotesProps> = ({
  width = 89,
  height = 89,
  ...otherProps
}) => {
  const pathID = useUniqueID('submitNotesPath');
  const titleText = 'calendar with x';

  return (
    <Svg width={width} height={height} title={titleText} aria-label={titleText}>
      <Defs>
        <LinearGradient x1="92.208%" y1="24.575%" x2="66.686%" y2="32.539%" id={pathID}>
          <stop stopColor="#E8E5E1" offset="0%" />
          <stop stopColor="#FFE4B8" offset="100%" />
        </LinearGradient>
        <LinearGradient x1="52.354%" y1="15.667%" x2="62.255%" y2="0%" id={pathID}>
          <stop stopColor="#EDEFF3" stopOpacity={0} offset="0%" />
          <stop stopColor="#F0EBE7" offset="100%" />
        </LinearGradient>
        <filter
          x="-35.3%"
          y="-26.5%"
          width="170.6%"
          height="170.6%"
          filterUnits="objectBoundingBox"
          id={pathID}
        >
          <feOffset dy={3} in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur stdDeviation={3.5} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
          <feColorMatrix
            values="0 0 0 0 0.657523777 0 0 0 0 0.40374267 0 0 0 0 0 0 0 0 0.223776224 0"
            in="shadowBlurOuter1"
          />
        </filter>
        <Path
          d="M7.106 3.841h19.89a7.055 7.055 0 0 1 7.054 7.054l.001 19.892a7.053 7.053 0 0 1-7.053 7.054H7.106a7.054 7.054 0 0 1-7.054-7.054V10.895a7.054 7.054 0 0 1 7.054-7.054Z"
          id={pathID}
        />
      </Defs>
      <G fill="none" fillRule="evenodd">
        <Circle cx={44.5} cy={44.5} r={44.5} fill="#EFF3FA" />
        <Path
          d="M28.826 25.726c.046 0 .093-.004.154-.01a5.392 5.392 0 0 0-.14.006Zm.014-.004a5.286 5.286 0 0 0-4.959 5.466l.713 20.28a5.3 5.3 0 0 0 5.474 5.106l21.768-.756.443 1.191c-.115-.307-.115-.307-.642-.828-.62-.261-.767-.323-.915-.326 2.839-.17 5.048-2.589 4.944-5.462l-.714-20.28a5.3 5.3 0 0 0-5.475-5.106l-21.765.755-.444-1.19c.116.307.116.307.643.828.62.261.767.323.915.326Z"
          stroke="#EFCE9B"
          strokeWidth={3.527}
        />
        <G transform="rotate(2 -756.078 967.43)">
          <Use fill="#000" xlinkHref={`#${pathID}`} />
          <Use xlinkHref={`#${pathID}`} />
          <Use xlinkHref={`#${pathID}`} />
        </G>
        <G fill="#EF970B">
          <Path
            d="m49.17 31.533-10.011-.35a6 6 0 0 0-6.206 5.787l-.768 21.987a6 6 0 0 0 5.787 6.205l21.987.768a6 6 0 0 0 6.206-5.787l.35-10.011a1.983 1.983 0 0 0-1.913-2.05l-.035-.002a1.983 1.983 0 0 0-2.05 1.912l-.35 10.012a2 2 0 0 1-2.068 1.929l-21.987-.768a2 2 0 0 1-1.929-2.069l.768-21.986a2 2 0 0 1 2.068-1.93l10.012.35a1.983 1.983 0 0 0 2.05-1.912l.001-.035a1.983 1.983 0 0 0-1.912-2.05Z"
            fillRule="nonzero"
          />
          <Path
            d="m46.682 44.49-.244 6.977 6.893.24L68.57 36.442a1.644 1.644 0 0 0 .039-2.283l-3.352-3.598a1.653 1.653 0 0 0-2.288-.124L46.682 44.49Z"
            stroke="#EF970B"
            strokeWidth={3.965}
          />
        </G>
      </G>
    </Svg>
  );
};

export default IconSubmitNotes;
