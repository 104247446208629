import { FunctionComponent } from 'react';
import { View, Text, styled } from '@talkspace/react-toolkit';
import { useParams, useHistory } from '@/core/routerLib';

import Submenu from '../../../../Reusable/Submenu/Submenu';
import Footer from '../../../../Reusable/Footer/Footer';
import Button from '../../../../Reusable/Buttons/Button';
import useQueryDiagnosticProfiles from './useQueryDiagnosticProfiles';
import { ClientDiagnosticProfile } from './types';
import useQueryParticipants from '../../../../../hooks/useQueryParticipants';

interface PercentageProp {
  percentage: number;
}

const DiagnosticDescription = () => (
  <View style={{ marginBottom: 20 }}>
    <Text style={{ fontSize: 16, color: '#546074', textAlign: 'center' }}>
      An analysis suggests the following profile based on: themes, language markers, clinical
      research, and comparative text analysis. Review in conjunction with your clinical expertise to
      help guide treatment.
    </Text>
  </View>
);

const DiagnosticBarContainer = styled(View)({
  position: 'relative',
  height: 20,
  width: '100%',
  borderRadius: 10,
  overflow: 'hidden',
  backgroundColor: '#EFF3FA',
});

const DiagnosticBar = styled(View)<PercentageProp>(({ percentage }) => {
  return {
    height: '100%',
    width: `${percentage}%`,
    backgroundColor: '#5C84FB',
  };
});

const DiagnosticBarText = styled(Text)({
  left: 0,
  right: 0,
  width: 40,
  fontSize: 11,
  color: '#546074',
  textAlign: 'center',
});

const DiagnosticBarPercentage: FunctionComponent<PercentageProp> = ({ percentage }) => (
  <View row style={{ marginTop: 5, alignItems: 'stretch' }}>
    <View style={{ flex: percentage }} />
    <View
      style={{
        width: 1,
        overflow: 'visible',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <DiagnosticBarText>{percentage}%</DiagnosticBarText>
    </View>
    <View style={{ flex: 100 - percentage }} />
  </View>
);

const DiagnosticRow: FunctionComponent<{ diagnostic: ClientDiagnosticProfile }> = ({
  diagnostic,
}) => (
  <View align="stretch" style={{ marginLeft: 3, marginRight: 3, marginTop: 5, marginBottom: 5 }}>
    <Text style={{ marginLeft: 4, color: '#93A1BA', fontWeight: 'bold', fontSize: 14 }}>
      {diagnostic.label}
    </Text>
    <DiagnosticBarContainer>
      <DiagnosticBar percentage={diagnostic.value} />
    </DiagnosticBarContainer>
    <DiagnosticBarPercentage percentage={diagnostic.value} />
  </View>
);

const DiagnosticProfile: FunctionComponent = () => {
  const history = useHistory();
  const { roomID, clientUserID } = useParams<{ roomID: string; clientUserID: string }>();

  const { data: participants } = useQueryParticipants(Number(roomID));

  const {
    data: diagnosticProfiles,
    isError,
    isLoading,
    error,
  } = useQueryDiagnosticProfiles(
    {
      roomID: Number(roomID),
      clientUserIDs: (participants || []).map((it) => Number(it.userID)),
    },
    { enabled: !!participants }
  );

  const isCouple = (participants || []).length > 1;

  const getFooterComponent = () => (
    <Footer
      childComponents={[
        <Button key={1} title="Cancel" isSecondary clickHandler={() => history.goBack()} />,
        <Button
          key={2}
          title="Update diagnosis"
          isSecondary={false}
          clickHandler={() => {
            history.push(`/room/${roomID}/clinical-tab`, {
              focusElement: 'diagnosis',
              selectedClientID: isCouple ? clientUserID : undefined,
            });
          }}
        />,
      ]}
      isError={isError}
      isUpdating={isLoading}
      showSpinner={isLoading}
      spinnerMessage={error?.message || ''}
    />
  );

  const content = [
    <DiagnosticDescription key={1} />,
    ...(diagnosticProfiles?.[clientUserID] || []).map((diagnostic) => (
      <DiagnosticRow key={diagnostic.label} diagnostic={diagnostic} />
    )),
  ];

  return (
    <Submenu
      onBack={() => history.goBack()}
      childComponents={content}
      title="Diagnostic profile"
      footerComponent={getFooterComponent()}
      bodyStyle={{ paddingLeft: 22, paddingRight: 22 }}
    />
  );
};

export default DiagnosticProfile;
