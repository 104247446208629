import { useMutation } from 'react-query';

import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';
import { CancelBookingParams } from '../types';

interface CancelBookingMutateVariables {
  roomID: string;
  bookingID: string;
  data: CancelBookingParams;
  isBatchMode?: boolean;
}

const cancelBooking = async ({
  roomID,
  bookingID,
  data,
  isBatchMode,
}: CancelBookingMutateVariables): Promise<void> => {
  await apiWrapper.patch(`${apiHelper().apiEndpoint}/v4/rooms/${roomID}/bookings/${bookingID}`, {
    ...data,
    action: 'cancel',
    isBatchMode,
  });
};

const useMutationCancelBooking = () =>
  useMutation<void, Error, CancelBookingMutateVariables>(cancelBooking);

export default useMutationCancelBooking;
