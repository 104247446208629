import { useState, useRef, ReactNode } from 'react';
import {
  Standard,
  BaseButton,
  View,
  useWindowWidth,
  useEmotionTheme,
  Heading3,
  Small,
  CloseButton,
  TouchableView,
  Modal,
  VimeoPlayer,
  Image,
} from '@talkspace/react-toolkit';
import styled from '@/core/styled';
import storage from '@/core/storage';
import ReactFrameService from '@/auth/reactFrame/ReactFrameService';
import trackEvent from '../../../modules/analytics/trackEvent';

const Container = styled(View)(
  ({
    theme: {
      colors,
      window: { isMobile },
    },
  }) => {
    return {
      paddingLeft: 30,
      paddingTop: isMobile ? 20 : 30,
      paddingRight: 30,
      paddingBottom: 20,
      width: '100%',
      position: 'relative',
      height: 'auto',
      borderBottom: `1px solid ${colors.shayCandela}`,
    };
  }
);

const TextWrapper = styled(View)(
  ({
    theme: {
      window: { isMobile, isDesktop, isLarge },
    },
  }) => {
    return {
      marginRight: (isDesktop || isLarge) && 60,
      marginBottom: isMobile && 20,
      flex: isDesktop ? 0.5 : 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      ...(!isMobile && { minWidth: 400 }),
    };
  }
);

const InnerWrapper = styled(View)(({ theme: { window } }) => {
  const { isMobile } = window;

  return {
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    width: '100%',
    justifyContent: 'space-between',
  };
});

const Text = styled(Small)(
  ({
    theme: {
      colors,
      window: { isMobile },
    },
  }) => {
    return {
      fontSize: 14,
      color: colors.purple600,
      marginTop: !isMobile && 10,
      paddingRight: 20,
    };
  }
);

const StyledButton = styled(BaseButton)(
  ({
    theme: {
      colors,
      window: { isMobile },
    },
  }) => {
    return {
      borderRadius: 8,
      border: `1px solid ${colors.accessibilityGreenDark}`,
      height: 34,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 5,
      flex: isMobile && 1,
      width: !isMobile && 156,
    };
  }
);

const ImageContainer = styled(TouchableView)(({ theme: { window } }) => {
  return {
    maxWidth: window.isMobile ? 'unset' : 337,
    minWidth: 119,
    flex: 'auto',
    height: '100%',
    marginRight: !window.isMobile && 10,
    ...(window.isLarge && { width: 119, height: 119 }),
  };
});

const ButtonsContainer = styled(View)(() => {
  return {
    marginTop: 25,
    width: '100%',
    gap: 10,
  };
});

interface ButtonProps {
  title: string;
  callback?: () => void;
  icon: ReactNode;
}

interface OnBoardingBannerProps {
  isVideoModalOpen: boolean;
  title: string;
  subtitle: string;
  storageKey: string;
  videoUrl: string;
  primaryButton: ButtonProps;
  secondaryButton: ButtonProps;
  placeholderImage: string;
  onOpenVideoModal: () => void;
  onCloseVideoModal: () => void;
}

const OnBoardingBanner = ({
  isVideoModalOpen,
  title,
  subtitle,
  storageKey,
  videoUrl,
  primaryButton,
  secondaryButton,
  placeholderImage,
  onOpenVideoModal,
  onCloseVideoModal,
}: OnBoardingBannerProps) => {
  const { isDesktop, isLarge, isMobile } = useWindowWidth();
  const { colors } = useEmotionTheme();
  const videoProgress = useRef('');
  const isWebview = ReactFrameService.instance().isInFrame();
  const shouldNotDisplayOnBoarding = storage.getItem(storageKey);
  const [isVisible, setIsVisible] = useState<boolean>(!shouldNotDisplayOnBoarding && !isWebview);
  const ariaLabel = useRef(' ');

  const setModalAriaLabel = (videoTitle: string) => {
    ariaLabel.current = videoTitle;
  };

  const handleCloseButton = () => {
    setIsVisible(false);
    storage.setItem(storageKey, 'true');
    window.dispatchEvent(new Event('storage'));
    trackEvent('featureOnboardingModalClosed', {
      actionName: 'activeExperiments',
    });
  };

  const setVideoProgress = (duration) => {
    videoProgress.current = duration;
  };

  const handleCloseVideoModal = () => {
    setVideoProgress('');
    onCloseVideoModal();
  };

  const getButtons = () => (
    <ButtonsContainer row>
      <StyledButton onPress={secondaryButton.callback || handleCloseButton}>
        {secondaryButton.icon}
        <Standard style={{ color: colors.accessibilityGreenDark }}>
          {secondaryButton.title}
        </Standard>
      </StyledButton>
      <StyledButton onPress={primaryButton.callback} style={{ background: colors.permaEden }}>
        {primaryButton.icon}
        <Standard style={{ color: colors.white }}>{primaryButton.title}</Standard>
      </StyledButton>
    </ButtonsContainer>
  );

  const isDesktopOrTablet = isDesktop || isLarge;

  return (
    <>
      {isVisible && (
        <Container>
          <InnerWrapper>
            <TextWrapper>
              <View>
                <View row={isMobile} justify="space-between">
                  <Heading3
                    style={{ paddingRight: !isMobile && 50, fontSize: (isLarge || isMobile) && 16 }}
                  >
                    {title}
                  </Heading3>
                  {isMobile && (
                    <CloseButton style={{ marginTop: -5 }} onPress={handleCloseButton} />
                  )}
                </View>
                <Text>{subtitle}</Text>
              </View>

              {isDesktopOrTablet && getButtons()}
            </TextWrapper>

            <View row>
              <ImageContainer onPress={onOpenVideoModal}>
                <Image
                  progressiveRenderingEnabled
                  alt="onboarding"
                  src={placeholderImage}
                  width="100%"
                  height="100%"
                />
              </ImageContainer>

              {isDesktopOrTablet && (
                <CloseButton style={{ marginTop: -10 }} onPress={handleCloseButton} />
              )}
            </View>

            {isMobile && getButtons()}
          </InnerWrapper>
        </Container>
      )}

      <Modal
        isVisible={isVideoModalOpen}
        onBackdropPress={handleCloseVideoModal}
        dialogStyle={{ width: '100%' }}
        titleText={ariaLabel?.current || ''}
      >
        {!!isVideoModalOpen && (
          <VimeoPlayer
            autoPlay
            title
            url={videoUrl}
            onProgress={setVideoProgress}
            onClose={handleCloseVideoModal}
            setModalTitle={setModalAriaLabel}
          />
        )}
      </Modal>
    </>
  );
};

export default OnBoardingBanner;
