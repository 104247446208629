import { useQuery } from 'react-query';
import apiHelper from '@/core/api/apiHelper';
import apiWrapper from '@/core/api/apiWrapper';
import { trackEvent } from '@/utils/analytics/eventTracker';

type ExperimentSession =
  | {
      isUserInExperiment: false;
    }
  | {
      isUserInExperiment: true;
      experimentID: number;
      variantID: number;
      variantName: string;
    };

type ExperimentLoading = {
  isLoading: true;
};

type ExperimentNotActive = {
  isLoading: false;
  isUserInExperiment: false;
};

type ExperimentActive = {
  isLoading: false;
  isUserInExperiment: true;
  experimentID: number;
  variantID: number;
  variantName: string;
  conversion: () => void;
};

type UseTSExperimentsResult = ExperimentLoading | ExperimentNotActive | ExperimentActive;

const useTSExperiments = (experimentName: string): UseTSExperimentsResult => {
  const ignoreExperiments = JSON.parse(sessionStorage.getItem('ignore-experiments') || 'false');
  const { data: experimentSession, isLoading } = useQuery<ExperimentSession, Error>({
    enabled: !ignoreExperiments,
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const response = await apiWrapper.get(
        `${apiHelper().apiEndpoint}/v1/experiments/${experimentName}`
      );
      const result = response.data.data;

      return result;
    },
    onSuccess: (loadedExperimentSession) => {
      if (!loadedExperimentSession?.isUserInExperiment) {
        return;
      }

      trackEvent(
        'TS Experiment Session',
        {
          experimentName,
          experimentID: loadedExperimentSession.experimentID,
          variantID: loadedExperimentSession.variantID,
          variantName: loadedExperimentSession.variantName,
        },
        ['mixpanel']
      );
    },
  });

  if (ignoreExperiments) {
    return {
      isUserInExperiment: false,
      isLoading: false,
    };
  }

  if (isLoading) {
    return { isLoading: true };
  }

  if (!experimentSession?.isUserInExperiment) {
    return {
      isUserInExperiment: false,
      isLoading: false,
    };
  }

  const conversion = () => {
    trackEvent(
      'TS Experiment Conversion',
      {
        experimentName,
        experimentID: experimentSession.experimentID,
        variantID: experimentSession.variantID,
        variantName: experimentSession.variantName,
      },
      ['mixpanel']
    );
  };

  return {
    experimentID: experimentSession.experimentID,
    isUserInExperiment: experimentSession.isUserInExperiment,
    isLoading,
    variantID: experimentSession.variantID,
    variantName: experimentSession.variantName,
    conversion,
  };
};

export default useTSExperiments;
