import { SetStateAction, Dispatch, useCallback } from 'react';
import {
  Button,
  COLORS,
  Modal,
  View,
  RadioButton,
  TabRadioGroup,
  TouchableView,
  styled,
  Small,
  ExtraTiny,
  BaseButton,
  Heading3,
} from '@talkspace/react-toolkit';
import moment from 'moment';
import { Booking } from '../types';

const CancelRecurringOptionWrapper = styled(TouchableView)({
  alignItems: 'start',
  flexDirection: 'row',
  marginTop: 16,
});

const CancelRecurringOption = ({
  isActive,
  onPress,
  cancelHeading,
  cancelDescription,
}: {
  onPress: () => void;
  isActive: boolean;
  cancelHeading: string;
  cancelDescription: string;
}) => (
  <CancelRecurringOptionWrapper onPress={onPress}>
    <RadioButton isActive={isActive} onPress={onPress} />
    <View align="start" style={{ marginLeft: 8, maxWidth: '100%', textAlign: 'left' }}>
      <Small style={{ color: COLORS.black }}>{cancelHeading}</Small>
      <ExtraTiny>{cancelDescription}</ExtraTiny>
    </View>
  </CancelRecurringOptionWrapper>
);

const CancelRecurringBookingModal = ({
  closeModal,
  isCancelRecurring,
  setIsCancelRecurring,
  cancelBooking,
  bookings,
  selectedBookingID,
}: {
  closeModal: () => void;
  isCancelRecurring: boolean;
  setIsCancelRecurring: Dispatch<SetStateAction<boolean>>;
  cancelBooking: () => void;
  bookings?: Booking[];
  selectedBookingID?: string;
}) => {
  const cancelSingleRecurring = useCallback(() => {
    setIsCancelRecurring(false);
  }, [setIsCancelRecurring]);

  const cancelAllRecurring = useCallback(() => {
    setIsCancelRecurring(true);
  }, [setIsCancelRecurring]);

  const startingBooking = bookings?.find((b) => b.id === selectedBookingID);
  const recurringBookings = bookings
    ?.filter((b) => b.repeatingBookingID === startingBooking?.repeatingBookingID)
    .sort((a, b) => (moment(a.startTime).isAfter(moment(b.startTime)) ? 1 : -1));

  const endingBooking = recurringBookings && recurringBookings[recurringBookings.length - 1];

  const startDate = moment(startingBooking?.startTime).tz(moment.tz.guess());
  const endDate = moment(endingBooking?.startTime).tz(moment.tz.guess());

  const cadence =
    recurringBookings &&
    recurringBookings?.length > 1 &&
    moment(recurringBookings[1].startTime).diff(moment(recurringBookings[0].startTime), 'days') > 7
      ? 'every other'
      : 'every';

  return (
    <Modal
      isVisible
      onBackdropPress={closeModal}
      titleText="Cancel sessions"
      isMobileFullscreen={false}
    >
      <Modal.Panel
        onBackdropPress={closeModal}
        contentViewStyle={{ padding: 0, alignItems: 'center', width: '100%' }}
        wrapperStyle={{ minHeight: 0 }}
        isMobileFullscreen={false}
      >
        <View
          style={{
            marginTop: 9,
            marginBottom: 14,
            textAlign: 'center',
            alignItems: 'start',
            padding: '0 40px',
          }}
        >
          <Heading3
            style={{
              marginTop: 0,
              marginBottom: 8,
              fontWeight: 500,
              color: COLORS.black,
            }}
          >
            Cancel Session(s)?
          </Heading3>
          <Small style={{ color: COLORS.black, textAlign: 'left' }}>
            {`This session recurs ${cadence} week until ${moment(endingBooking?.startTime).format(
              'MMM D, YYYY'
            )}`}
          </Small>
          <TabRadioGroup>
            <CancelRecurringOption
              onPress={cancelSingleRecurring}
              isActive={!isCancelRecurring}
              cancelHeading="Cancel only this session"
              cancelDescription={`${startDate.format('dddd, MMM D, YYYY')} at ${startDate.format(
                'h:mma z'
              )}`}
            />
            <CancelRecurringOption
              onPress={cancelAllRecurring}
              isActive={isCancelRecurring}
              cancelHeading="Cancel this and all future recurring sessions"
              cancelDescription={`${startDate.format('dddd')}s at ${startDate.format(
                'h:mma z'
              )} from  ${startDate.format('MMM D')} – ${endDate.format('MMM D, YYYY')}`}
            />
          </TabRadioGroup>
        </View>
        <View
          row
          justify="space-between"
          style={{
            width: '100%',
            borderTop: `1px solid ${COLORS.grey600}`,
            padding: '0 16px 16px 16px',
          }}
        >
          <BaseButton
            style={{ marginTop: 14 }}
            onPress={closeModal}
            dataQa="inRoomSchedulingBookingCheckoutDecline"
          >
            <Small style={{ color: COLORS.permaTalkspaceDarkGreen, fontWeight: 'bold' }}>
              Nevermind
            </Small>
          </BaseButton>

          <Button
            text="Continue"
            onPress={cancelBooking}
            style={{ marginTop: 20, width: 156 }}
            size="small"
          />
        </View>
      </Modal.Panel>
    </Modal>
  );
};

export default CancelRecurringBookingModal;
