import { VoidFunctionComponent } from 'react';
import Svg, { Path } from 'svgs';
import { useEmotionTheme, EmotionStyle } from '../../core/styled';

interface TaskListProps {
  color?: string;
  width?: number;
  height?: number;
  style?: EmotionStyle;
}

const TaskList: VoidFunctionComponent<TaskListProps> = ({
  width = 24,
  height = 24,
  color,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'tasks list';
  return (
    <Svg title={titleText} aria-label={titleText} width={width} height={height} {...otherProps}>
      <Path
        d="M21 7.657h-7a1 1 0 0 0 0 2h7a1 1 0 0 0 0-2Zm0 8h-7a1 1 0 1 0 0 2h7a1 1 0 0 0 0-2Zm-15.46-4L2.705 8.822a.997.997 0 1 1 1.41-1.41L5.53 8.827l3.535-3.535a.997.997 0 0 1 1.411 1.411L5.54 11.657Zm0 8-2.835-2.835a.997.997 0 1 1 1.41-1.41l1.415 1.415 3.535-3.535a.997.997 0 0 1 1.411 1.411L5.54 19.657Z"
        fill={color || colors.permaEden}
        fillRule="nonzero"
      />
    </Svg>
  );
};

export default TaskList;
