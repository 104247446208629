import { FunctionComponent } from 'react';
import { View, ExtraHuge, Large, Button, RedX } from '@talkspace/react-toolkit';
import { withRouter, RouteComponentProps } from '@/core/routerLib';
import BlueBookingIconWithX from '../BlueBookingIconWithX';

interface OwnProps {
  onClosePress: () => void;
  wasDeclined?: boolean;
  wasConfirmed?: boolean;
  plural?: boolean;
}

type Props = OwnProps & RouteComponentProps<{ roomID: string }, {}, { isReschedule?: boolean }>;

const getHeaderText = (wasDeclined?: boolean, wasConfirmed?: boolean, plural?: boolean) => {
  switch (true) {
    case wasConfirmed:
      return `You've already confimed ${plural ? 'these sessions' : 'this session'}`;
    case wasDeclined:
      return `${plural ? 'Sessions have' : 'Session has'} already been declined`;
    default:
      return plural ? 'Sessions Declined' : 'Session Declined';
  }
};
const getBodyText = (wasConfirmed?: boolean, plural?: boolean) => {
  switch (true) {
    case wasConfirmed:
      return `If you need to cancel or reschedule ${
        plural ? 'a session' : 'this session'
      }, you can do so in the app.`;
    default:
      return `We've let your provider know.`;
  }
};

const ConfirmDecline: FunctionComponent<Props> = ({
  onClosePress,
  wasDeclined,
  wasConfirmed,
  plural,
}) => (
  <View align="center" flex={1} style={{ marginTop: 81, width: '100%' }}>
    {wasConfirmed ? <RedX style={{ marginBottom: 20 }} /> : <BlueBookingIconWithX />}
    <ExtraHuge style={{ marginBottom: 20, maxWidth: 338, textAlign: 'center' }}>
      {getHeaderText(wasDeclined, wasConfirmed, plural)}
    </ExtraHuge>
    <Large variant="largeDarkGrey" style={{ textAlign: 'center', maxWidth: 330 }}>
      {getBodyText(wasConfirmed, plural)}
    </Large>
    <Button onPress={onClosePress} style={{ width: 375, marginTop: 'auto' }} text="Done" />
  </View>
);

export default withRouter(ConfirmDecline);
