import { View } from '@talkspace/react-toolkit';
import moment from 'moment';
import styled from '../../../../core/styled';

interface DateOfSessionCellProps {
  startDate: Date;
  endDate?: Date | null;
  shouldDisplayRange: boolean;
}

const Container = styled(View)(({ theme: { colors, window } }) => {
  const { isDesktop } = window;
  return {
    fontSize: isDesktop ? 14 : 15,
    fontWeight: isDesktop ? 400 : 500,
    color: isDesktop ? colors.purple600 : colors.black,
    height: isDesktop && 30,
    display: 'flex',
    justifyContent: 'center',
  };
});

const DateOfSessionCell = ({ startDate, endDate, shouldDisplayRange }: DateOfSessionCellProps) => {
  const getContent = () => {
    if (shouldDisplayRange) {
      if (endDate) {
        return `${moment(startDate).format('MMM D')} - ${moment(endDate).format('MMM D')}`;
      }
      return `${moment(startDate).format('MMM D')} - Today`;
    }

    return `${moment(startDate).format('MMM D')}`;
  };

  return <Container>{getContent()}</Container>;
};

export default DateOfSessionCell;
