import { forwardRef } from 'react';
import styled, { useEmotionTheme, EmotionStyle } from '../../../core/styled';
import BaseButton, { BaseButtonProps } from './BaseButton';
import { useShareForwardedRef } from '../../../hooks/a11yHelper';
import AnimatedSpinner from '../AnimatedSpinner';

export type ButtonSize = 'small' | 'medium' | 'large';

export interface ButtonWithAnimatedSpinnerProps extends BaseButtonProps {
  text?: string | Element;
  isSecondary?: boolean;
  stretch?: boolean;
  size?: ButtonSize;
  style?: EmotionStyle;
  isLoading?: boolean;
  removeBorder?: boolean;
  dataQa?: string;
  dataCta?: string;
}

const ButtonComponent = styled(BaseButton)<Partial<ButtonWithAnimatedSpinnerProps>>(
  ({
    disabled,
    isSecondary,
    style = {},
    size = 'medium' as ButtonSize,
    theme: { colors, isHighContrast },
    stretch,
    removeBorder,
  }) => {
    const stylesBySize: { [k: string]: EmotionStyle } = {
      small: {
        fontSize: 14,
        fontWeight: '400',
        fontFamily: "'Roboto', sans-serif",
        padding: 4,
        paddingLeft: 14,
        paddingRight: 14,
        display: 'flex',
        borderRadius: 6,
        border: isSecondary ? `1px solid ${colors.ironGrey}` : 'none',
        opacity: disabled && !isHighContrast ? 0.6 : 1,
      },
      medium: {
        backgroundColor: disabled ? colors.extraLightGrey : colors.green,
        height: 50,
        minHeight: 50,
        width: stretch ? '100%' : 300,
        fontWeight: '700',
        fontFamily: "'Roboto', sans-serif",
        borderRadius: 10,
        alignItems: 'center',
        padding: '0 5px',
        justifyContent: 'center',
        color: colors.white,
        fontSize: 17,
        marginTop: 12,
      },
      large: {
        backgroundColor: disabled ? colors.extraLightGrey : colors.green,
        height: 50,
        minHeight: 50,
        width: stretch ? '100%' : 300,
        fontWeight: '700',
        fontFamily: "'Roboto', sans-serif",
        borderRadius: 10,
        alignItems: 'center',
        padding: '0 20px',
        justifyContent: 'center',
        color: colors.white,
        fontSize: 17,
        marginTop: 12,
      },
    };
    return {
      color: isSecondary ? colors.green : colors.white,
      backgroundColor: isSecondary ? colors.white : colors.green,
      ...(stylesBySize[size] || stylesBySize.medium),
      ...(removeBorder ? { border: 'none' } : {}),
      ...style,
    };
  }
);

const Button = forwardRef<
  HTMLButtonElement,
  ButtonWithAnimatedSpinnerProps & React.ComponentPropsWithoutRef<typeof ButtonComponent>
>((props, ref) => {
  const {
    onPress,
    text,
    children,
    style,
    isLoading = false,
    disabled = false,
    size,
    isSecondary = false,
    removeBorder = false,
    dataQa,
    dataCta,
    ...otherProps
  } = props;
  const buttonRef = useShareForwardedRef(ref);
  const { colors } = useEmotionTheme();
  return (
    <ButtonComponent
      ref={buttonRef}
      data-qa={dataQa}
      onPress={disabled ? () => null : onPress}
      disabled={disabled}
      style={style}
      size={size}
      isSecondary={isSecondary}
      removeBorder={removeBorder}
      data-cta={dataCta}
      {...otherProps}
    >
      {(isLoading && (
        <AnimatedSpinner
          width={24}
          height={24}
          color={size === 'small' && isSecondary ? colors.ironGrey : colors.white}
          style={{ margin: 'auto' }}
        />
      )) ||
        text ||
        children}
    </ButtonComponent>
  );
});
export default Button;
