import { useQuery } from 'react-query';

import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';

import { Participant } from '../types/participant';

const fetchParticipants = (roomID: number) => async () => {
  const { data } = await apiWrapper.get(
    `${apiHelper().apiEndpoint}/api/v1/rooms/${roomID}/participants`
  );

  return data.data;
};

const useQueryParticipants = (roomID: number) =>
  useQuery<Participant[], Error>({
    queryKey: ['participants', roomID.toString()],
    queryFn: fetchParticipants(roomID),
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

export default useQueryParticipants;
