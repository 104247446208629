import Svg, { G, Circle, Path } from 'svgs';

const GraphicCardCheckMark = ({ style }) => (
  <Svg style={style} title="GraphicCardCheckMark" width="29" height="29" viewBox="0 0 29 29">
    <G fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <G>
        <G>
          <Circle cx="14" cy="14" r="14" fill="#A3DF71" />
          <Path
            fill="#FFF"
            fillRule="nonzero"
            d="M19.087 9.509a1.6 1.6 0 012.405 2.106l-.102.116-7.24 7.502a2 2 0 01-2.702.102l-.127-.118-3.459-3.497a1.6 1.6 0 012.162-2.355l.114.105 2.594 2.623 6.355-6.584z"
          />
        </G>
      </G>
    </G>
  </Svg>
);

export default GraphicCardCheckMark;
