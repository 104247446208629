import Svg, { Circle, Path } from 'svgs';
import { EmotionStyle, useEmotionTheme } from '../../core/styled';

interface ParentConsentAvatarProps {
  width?: number;
  height?: number;
  style?: EmotionStyle;
}

const ParentConsentAvatar = ({
  width = 42,
  height = 42,
  ...otherProps
}: ParentConsentAvatarProps) => {
  const { colors } = useEmotionTheme();
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask id="mask0_1826_40358" maskUnits="userSpaceOnUse" x="0" y="0" width="42" height="42">
        <Circle cx="21" cy="21" r="21" fill={colors.permaSilverSand} />
      </mask>
      <g mask="url(#mask0_1826_40358)">
        <rect width="43" height="43" fill="#758AAE" />
        <Circle cx="16" cy="14.3008" r="5.5" fill="white" />
        <ellipse cx="16" cy="31" rx="10" ry="8" fill="white" />
        <Path
          d="M39 37.5C39 43.299 34.0751 48 28 48C21.9249 48 17 43.299 17 37.5C17 31.701 21.9249 27 28 27C34.0751 27 39 31.701 39 37.5Z"
          fill="white"
          stroke="#758AAE"
          strokeWidth="3"
        />
        <Circle cx="21" cy="21" r="18.5" stroke="#758AAE" strokeWidth="5" />
        <Circle cx="28" cy="20" r="5" fill={colors.white} />
      </g>
    </Svg>
  );
};

export default ParentConsentAvatar;
