import { FunctionComponent, useState } from 'react';
import Svg, { G, Path } from 'svgs';
import { COLORS } from '../../constants/commonStyles';
import { EmotionStyle } from '../../core/styled';
import TouchableView from '../TouchableView';
import withHover, { WithHoverProps } from '../../hoc/withHover';

export interface PasswordEyeProps extends WithHoverProps {
  width?: number;
  height?: number;
  color?: string;
  hoverColor?: string;
  hasHoverStyles?: boolean;
  transform?: string;
  style?: EmotionStyle;
  strikethrough?: boolean;
  passwordInputHasFocus?: boolean;
  onPress?: () => void;
  containerStyle?: EmotionStyle;
  className?: string;
  primaryColor?: string;
  roundedFocusStyle?: boolean;
}

const PasswordEye: FunctionComponent<PasswordEyeProps> = ({
  strikethrough,
  passwordInputHasFocus,
  onPress,
  containerStyle,
  width = 27,
  height = 23,
  hoverColor = COLORS.baliHaiGrey,
  color = COLORS.periwinkleGrey,
  style,
  className,
  isHovering,
  hasHoverStyles,
  primaryColor,
  roundedFocusStyle,
}) => {
  // prevents the eye from disappearing on keyboard foccus
  const [eyeHasFocus, setEyeHasFocus] = useState(false);
  const onFocus = () => {
    setEyeHasFocus(true);
  };
  const onBlur = () => {
    setEyeHasFocus(false);
  };

  // prevents onPress from bubbling up to fire onSubmit on the login page
  const onPressHandler = (e) => {
    e.preventDefault();
    if (onPress) {
      onPress();
    }
  };
  const titleText = strikethrough ? 'eye with strike' : 'eye';
  return (
    <TouchableView
      aria-label={strikethrough ? 'hide password' : 'see password'}
      onFocus={onFocus}
      onBlur={onBlur}
      style={{
        height,
        transition: 'opacity .25s',
        borderRadius: roundedFocusStyle ? 5 : 0,
        display: 'flex',
        justifyContent: 'center',
        ...containerStyle,
        opacity: passwordInputHasFocus || eyeHasFocus ? 1 : 0,
        ...style,
      }}
      onPress={onPressHandler}
      role="button"
      className={className}
      primaryColor={primaryColor}
      roundedFocusStyle={roundedFocusStyle}
    >
      {strikethrough ? (
        <Svg
          width={width}
          height={height}
          title={titleText}
          aria-label={titleText}
          viewBox="0 0 27 23"
          fill={color}
        >
          <G
            fill={isHovering && hasHoverStyles ? hoverColor : color}
            stroke="none"
            strokeWidth={1}
            fillRule="evenodd"
          >
            <Path d="M3.798 5.834l3.69 3.69a6.5 6.5 0 0 0 8.488 8.488l2.882 2.882c-1.657.701-3.474 1.125-5.358 1.105C6.27 21.925.016 15.621 0 12c-.008-1.752 1.46-4.154 3.798-6.165zM8.157 3.12C9.812 2.423 11.624 1.995 13.5 2c7.23.018 13.527 6.379 13.5 10-.013 1.747-1.474 4.154-3.795 6.17l-3.693-3.694a6.5 6.5 0 0 0-8.488-8.488L8.157 3.121zm.512 7.584l6.126 6.126a5 5 0 0 1-6.126-6.126zm3.536-3.536a5 5 0 0 1 6.126 6.126L12.205 7.17z" />
            <Path
              d="M23.425 23.34a1.002 1.002 0 0 0 1.41-.005.994.994 0 0 0 .005-1.41L3.616.702a1.002 1.002 0 0 0-1.409.005.994.994 0 0 0-.005 1.41L23.425 23.34z"
              fillRule="nonzero"
            />
          </G>
        </Svg>
      ) : (
        <Svg
          style={{ marginTop: 2 }} // Needed because strikethrough svg is pushed down about this amount of pixels
          width={width}
          height={height}
          viewBox={`0 0 ${width} ${height}`}
          fill={color}
          title={titleText}
          aria-label={titleText}
        >
          <Path
            d="M13.5 20C6.27 19.924.016 13.62 0 10-.016 6.379 6.27-.018 13.5 0 20.73.018 27.027 6.379 27 10c-.027 3.62-6.27 10.074-13.5 10zm0-3.5a6.5 6.5 0 1 0 0-13 6.5 6.5 0 0 0 0 13zm0-1.5a5 5 0 1 1 0-10 5 5 0 0 1 0 10z"
            fill={isHovering && hasHoverStyles ? hoverColor : color}
            fillRule="evenodd"
            opacity="0.8"
          />
        </Svg>
      )}
    </TouchableView>
  );
};

export default withHover(PasswordEye);
