export type CardType =
  | 'American Express'
  | 'Discover'
  | 'MasterCard'
  | 'Visa'
  | 'Diners Club'
  | 'JCB'
  | 'Unknown';

export interface SkipNavigation {
  buttonText: string;
  returnID?: string;
  onPress?: () => void;
}

export type JustifyType =
  | 'start'
  | 'center'
  | 'end'
  | 'space-between'
  | 'space-around'
  | 'initial'
  | 'inherit';
export type AlignType = 'start' | 'center' | 'end' | 'stretch' | 'baseline' | 'inherit';
export type DirectionType = 'column' | 'row' | 'row-reverse' | 'column-reverse';

export enum Breakpoint {
  // mobile
  xs = 'xs', // 4 col
  // tablet
  sm = 'sm', // 8 col
  md = 'md', // 8 col
  // desktop
  lg = 'lg', // 12 col
  xl = 'xl', // 12 col
}

export type BreakpointValues = Record<Breakpoint, number>;
