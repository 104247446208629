import { FunctionComponent } from 'react';
import Svg, { Path } from 'svgs';
import { useEmotionTheme, EmotionStyle } from '../../core/styled';

interface SmallCircleProps {
  width?: number;
  height?: number;
  style?: EmotionStyle;
}

const SmallCircle: FunctionComponent<SmallCircleProps> = ({
  width = 16,
  height = 16,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'chat session icon';
  return (
    <Svg width={width} height={height} viewBox="0 0 17 17" title={titleText} {...otherProps}>
      <Path
        d="M4.03027 8.5C4.03027 7.43913 4.4517 6.42172 5.20185 5.67157C5.95199 4.92143 6.96941 4.5 8.03027 4.5C9.09114 4.5 10.1086 4.92143 10.8587 5.67157C11.6088 6.42172 12.0303 7.43913 12.0303 8.5C12.0303 9.56087 11.6088 10.5783 10.8587 11.3284C10.1086 12.0786 9.09114 12.5 8.03027 12.5C6.96941 12.5 5.95199 12.0786 5.20185 11.3284C4.4517 10.5783 4.03027 9.56087 4.03027 8.5Z"
        fill={colors.aqua}
      />
    </Svg>
  );
};

export default SmallCircle;
