import { VoidFunctionComponent } from 'react';
import { Tray, View, Huge, Large, useUniqueID, SparklingVideo } from '@talkspace/react-toolkit';
import { UnavailabilityMessage } from '../../types';

interface Props {
  closeModal: () => void;
  unavailabilityMessage: UnavailabilityMessage;
}

const LiveSessionsNotCoveredModal: VoidFunctionComponent<Props> = (props) => {
  const { closeModal, unavailabilityMessage } = props;
  const ID_TITLE = useUniqueID('titleId');
  const ID_SUB_TITLE = useUniqueID('subTitleId');

  return (
    <Tray handleClose={closeModal}>
      <View align="center">
        <SparklingVideo />
        <Huge
          as="h1"
          id={ID_TITLE}
          style={{
            textAlign: 'center',
            marginBottom: 20,
            marginTop: 30,
          }}
        >
          {unavailabilityMessage.title}
        </Huge>

        <Large
          id={ID_SUB_TITLE}
          variant="largeDarkGrey"
          style={{ textAlign: 'center', marginBottom: 30 }}
        >
          {unavailabilityMessage.subtitle}
        </Large>
      </View>
    </Tray>
  );
};

export default LiveSessionsNotCoveredModal;
