import moment, { Moment } from 'moment';
import { forwardRef, FunctionComponent } from 'react';
import { View, useEmotionTheme, TextInput } from '@talkspace/react-toolkit';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './CrmDateTimePicker.css';

interface Props {
  isError: boolean;
  selectedDate: Moment | null;
  onDateChange: (newValue: Moment) => void;
  isDisabled: boolean;
  startDate: Moment;
  maxDate: Moment;
  minDate: Moment;
  placeholderText: string;
}

const CrmDateTimePicker: FunctionComponent<Props> = ({
  isError,
  selectedDate,
  onDateChange,
  isDisabled,
  startDate,
  maxDate,
  minDate,
  placeholderText,
}) => {
  const { colors } = useEmotionTheme();

  interface CustomInputProps {
    value?: string;
    onClick?: () => void;
    placeholder?: string;
  }
  const CustomInput = forwardRef<HTMLInputElement, CustomInputProps>(
    ({ value, onClick, placeholder }, ref) => (
      <TextInput
        placeholder={placeholder}
        onClick={onClick}
        style={{
          border: 'none',
          width: 344,
        }}
        assignRef={ref}
        value={value}
        placeHolderStyle={isError ? { color: colors.permaDebianRed } : {}}
        onChange={() => {
          // Prevent typing in this field, only use the Datepicker
          if (selectedDate) {
            onDateChange(moment(selectedDate));
          }
        }}
        isReadOnly
      />
    )
  );
  return (
    <View
      className="date-time-picker"
      style={{
        marginBottom: 10,
        width: '100%',
        borderBottom: `1px solid ${colors.placeholderGrey}`,
        borderColor: isError ? colors.permaDebianRed : colors.permaLightGrey,
      }}
    >
      <DatePicker
        customInput={<CustomInput />}
        disabled={isDisabled}
        placeholderText={placeholderText}
        startDate={startDate}
        minDate={minDate}
        maxDate={maxDate}
        selected={selectedDate}
        onChange={onDateChange}
        showTimeSelect
        timeCaption="Time"
        timeFormat="hh:mm a"
        dateFormat="LLL"
      />
    </View>
  );
};

export default CrmDateTimePicker;
