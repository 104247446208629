import { FunctionComponent } from 'react';
import Avatar from '../Avatar';
import View from '../View';
import { RoundFilledCheckmark, ExclamationSquare } from '../icons';
import { useEmotionTheme } from '../../core/styled';

const AvatarUpdateCoverage: FunctionComponent<{
  imageURL: string;
  defaultImageURL: string;
  providerAvailable: boolean;
}> = ({ imageURL, defaultImageURL, providerAvailable }) => {
  const { colors } = useEmotionTheme();
  return (
    <View align="center" justify="center" style={{ position: 'relative', height: 120, width: 150 }}>
      <Avatar
        image={defaultImageURL}
        height={85}
        width={85}
        style={{ position: 'absolute', right: 0 }}
      />
      <Avatar image={imageURL} height={85} width={85} style={{ position: 'absolute', left: 0 }} />
      {providerAvailable ? (
        <RoundFilledCheckmark style={{ position: 'absolute', bottom: 18, left: 62 }} />
      ) : (
        <ExclamationSquare
          color={colors.red}
          height={18}
          width={18}
          style={{ position: 'absolute', bottom: 18, left: 64 }}
        />
      )}
    </View>
  );
};

export default AvatarUpdateCoverage;
