import { FunctionComponent } from 'react';
import Svg, { G, Path } from 'svgs';
import { useEmotionTheme, EmotionStyle } from '../../core/styled';

interface VideoIconProps {
  color?: string;
  iconColor?: string;
  width?: number;
  height?: number;
  style?: EmotionStyle;
}

const VideoIcon: FunctionComponent<VideoIconProps> = ({
  color,
  iconColor,
  width = 70,
  height = 70,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'video camera';
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 70 70"
      title={titleText}
      aria-label={titleText}
      {...otherProps}
    >
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Path
          d="M35 0c19.33 0 35 15.67 35 35S54.33 70 35 70 0 54.33 0 35 15.67 0 35 0z"
          fill={color || colors.periwinkleGrey}
        />
        <Path
          d="M37.278 24a3.92 3.92 0 013.92 3.92v14.16a3.92 3.92 0 01-3.92 3.92H21.92A3.92 3.92 0 0118 42.08V27.92A3.92 3.92 0 0121.92 24h15.358zm15.533 3.553c.124.23.189.486.189.747v12.592a1.568 1.568 0 01-2.314 1.38l-7.038-3.808a1.568 1.568 0 01-.822-1.379v-4.978c0-.576.315-1.105.822-1.38l7.038-3.807a1.568 1.568 0 012.125.633z"
          fill={iconColor || colors.a11yLinkWaterGrey}
        />
      </G>
    </Svg>
  );
};

export default VideoIcon;
