import Svg, { Path } from 'svgs';
import { useEmotionTheme } from '../../core/styled';

const ArrowUp = ({
  width = 10,
  height = 16,
  fill,
  ...otherProps
}: {
  width?: number;
  height?: number;
  fill?: string;
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'up arrow';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox={`0 0 ${width} ${height}`}
      {...otherProps}
    >
      <Path
        d="M331.247 265.24a.796.796 0 00-.002 1.151L336 271l4.754-4.609a.794.794 0 00-.001-1.15.854.854 0 00-1.19.001L336 268.696l-3.563-3.454a.858.858 0 00-1.19-.002z"
        transform="translate(-331 -265) rotate(180 336 268)"
        fill={fill || colors.green}
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </Svg>
  );
};

export default ArrowUp;
