import { FunctionComponent } from 'react';
import { View } from '@talkspace/react-toolkit';
import styled from '@/core/styled';
import { COLORS } from '../../utils/design';
import VideoMuted from '../Icons/VideoMuted';
import AudioMuted from '../Icons/AudioMuted';

const SmallVideoMutedWrapper = styled(View)<{ isTherapistLVSBreakpoint: boolean }>(
  ({
    isTherapistLVSBreakpoint,
    theme: {
      window: { width },
    },
  }) => {
    const isSmallerScreen = width < 550;
    return {
      width: isSmallerScreen || isTherapistLVSBreakpoint ? 85 : 100,
      height: isSmallerScreen || isTherapistLVSBreakpoint ? 114 : 100,
      borderRadius: 5,
      overflow: 'hidden',
      position: 'absolute',
      top: 0,
      left: 0,
      backgroundColor: COLORS.slateGrey,
      paddingLeft: isSmallerScreen || isTherapistLVSBreakpoint ? 26 : 32,
      paddingTop: isSmallerScreen || isTherapistLVSBreakpoint ? 35 : 28,
    };
  }
);

const SmallSpeakerVideoSettings: FunctionComponent<{
  isTherapistLVSBreakpoint?: boolean;
  isAudioOn: boolean;
  isVideoOn: boolean;
}> = ({ isAudioOn, isVideoOn, isTherapistLVSBreakpoint = false }) => (
  <>
    {!isVideoOn && (
      <SmallVideoMutedWrapper isTherapistLVSBreakpoint={isTherapistLVSBreakpoint}>
        <VideoMuted height={41} width={40} />
      </SmallVideoMutedWrapper>
    )}
    {!isAudioOn && (
      <View style={{ position: 'absolute', bottom: 7, left: 8 }}>
        <AudioMuted height={18} width={11} />
      </View>
    )}
  </>
);

export default SmallSpeakerVideoSettings;
