/* eslint-disable @typescript-eslint/no-empty-function */
import { FunctionComponent, useEffect, useRef, SetStateAction, Dispatch } from 'react';
import Player from '@vimeo/player';
import getResponsiveRatioStyles from '../../utils/getResponsiveRatioStyles';
import View from '../View';
import CloseButton from '../CloseButton';
import styled from '../../core/styled';
import './vimeoPlayer.css';
import { useWindowWidthState } from '../../index.next';

interface VimeoPlayerProps {
  url: string;
  onProgress?: Dispatch<SetStateAction<string>>;
  onClose?: () => void;
  autoPlay?: boolean;
  autoPause?: boolean;
  byline?: boolean;
  color?: boolean;
  controls?: boolean;
  muted?: boolean;
  playsInline?: boolean;
  portrait?: boolean;
  speed?: boolean;
  transparent?: boolean;
  title?: boolean;
  setModalTitle: (title: string) => void;
}

// Videos are 1080p but we scaled them down to fit most screens
const VIDEO_WIDTH = 1280;
const VIDEO_HEIGHT = 720;

// accessibility
// trivial wrapper needed to keep focus in the modal due to the vimeo player iframe
const FocusWrapper = styled(View)<{ width: number; height: number }>(({ width, height }) => {
  return {
    width: VIDEO_WIDTH,
    height: VIDEO_HEIGHT,
    position: 'relative',
    ...getResponsiveRatioStyles({ width: VIDEO_WIDTH, height: VIDEO_HEIGHT }, { width, height }),
  };
});

const Container = styled(View)<{ width: number; height: number }>(({ width, height }) => {
  return {
    width: VIDEO_WIDTH,
    height: VIDEO_HEIGHT,
    position: 'relative',
    background: 'black',
    ...getResponsiveRatioStyles({ width: VIDEO_WIDTH, height: VIDEO_HEIGHT }, { width, height }),
  };
});

const VimeoPlayer: FunctionComponent<VimeoPlayerProps> = ({
  url,
  autoPlay,
  autoPause,
  byline,
  color,
  controls,
  muted,
  playsInline,
  portrait,
  speed,
  transparent,
  onProgress,
  title,
  onClose,
  setModalTitle,
}) => {
  const refContainer = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!window.navigator.onLine) {
      // eslint-disable-next-line no-alert
      window.alert('Unable to load content. Please check your Internet connection.');
      if (typeof onClose === 'function') onClose();
      return () => {}; // Empty cleanup function
    }
    const player = new Player(refContainer.current, {
      url,
      autoplay: autoPlay,
      autopause: autoPause,
      byline,
      color,
      controls,
      muted,
      transparent,
      playsinline: playsInline,
      portrait,
      speed,
      title,
    });

    const refreshProgress = (progress) => {
      if (onProgress) {
        onProgress(progress);
      }
    };

    player.setVolume(1);
    player.on('timeupdate', (progress) => refreshProgress(progress.percent));

    return () => {
      if (player) {
        player.destroy();
      }
    };
  }, [
    url,
    onProgress,
    autoPlay,
    byline,
    color,
    controls,
    muted,
    transparent,
    autoPause,
    playsInline,
    portrait,
    speed,
    title,
    onClose,
  ]);

  useEffect(() => {
    if (!refContainer.current) return;

    const iframe = refContainer.current.getElementsByTagName('iframe')[0];
    if (!iframe) return;
    // necessary to allow focus to enter iframe
    iframe.setAttribute('tabindex', '0');
    const modalTitle = iframe.getAttribute('title') as string; // TODO: @Eric - Please check this is correct
    setModalTitle(modalTitle);
  });

  const { width, height } = useWindowWidthState();

  return (
    <FocusWrapper width={width} height={height}>
      <Container width={width} height={height} ref={refContainer} className="vimeoPlayer" />
      <View
        align="end"
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          pointerEvents: 'none',
        }}
      >
        <CloseButton
          style={{
            marginTop: 15,
            marginRight: 15,
            pointerEvents: 'auto',
          }}
          onPress={onClose}
        />
      </View>
    </FocusWrapper>
  );
};

export default VimeoPlayer;
