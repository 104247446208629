import { useEffect, useCallback } from 'react';
import { Col, Panel, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import moment from 'moment-timezone';
import { useObjectState, View } from '@talkspace/react-toolkit';
import {
  TimeOffCancelBookingsModal,
  TimeOffPicker,
  AddTimeOffButton,
} from '../../../modules/upcomingBookings';
import apiWrapper from '../../../utils/apiWrapper';
import apiHelper from '../../../utils/api';
import { getUserData } from '../../../utils/token';
import 'react-toastify/dist/ReactToastify.min.css';
import '../Account.css';

const getMomentTimeOffsAndFocusInputs = (timeOffs) => {
  const momentTimeOffs = timeOffs.length
    ? timeOffs.map(({ startDate, endDate, ...rest }) => {
        return {
          ...rest,
          startDate: moment(startDate),
          endDate: moment(endDate),
        };
      })
    : [
        {
          startDate: null,
          endDate: null,
          id: null,
        },
      ];
  const focusedInputArray = momentTimeOffs.map(() => null);
  return { momentTimeOffs, focusedInputArray };
};

const getIsDayBlocked = (timeOffs, focusedInputArray, index) => (day) => {
  const filteredTimeOffs = timeOffs.filter((timeOff, idx) => index !== idx && !timeOff.delete);
  const dayMoment = moment(day);
  if (!filteredTimeOffs.length) return false;
  if (focusedInputArray[index] === 'startDate') {
    const isStartDateBlocked = filteredTimeOffs.some(({ startDate, endDate }) =>
      dayMoment.isBetween(moment(startDate), moment(endDate), 'day', '[]')
    );
    return isStartDateBlocked;
  }

  if (focusedInputArray[index] === 'endDate') {
    const startDateMoment = moment(timeOffs[index].startDate);
    const nextClosestStartDate = filteredTimeOffs.reduce((prev, timeOff) => {
      const nextStartDateMoment = moment(timeOff.startDate);
      if (!prev) return nextStartDateMoment.isAfter(startDateMoment) ? nextStartDateMoment : null;

      return nextStartDateMoment.isBefore(prev) && nextStartDateMoment.isAfter(startDateMoment)
        ? nextStartDateMoment
        : prev;
    }, null);

    return nextClosestStartDate
      ? !dayMoment.isBefore(nextClosestStartDate) || dayMoment.isBefore(startDateMoment)
      : dayMoment.isBefore(startDateMoment);
  }

  return false;
};

const TherapistTimeOff = ({
  getTimeOffs,
  timeOffs,
  getBookings,
  bookings,
  isLoadingBookings,
  setIsSectionLoaded,
  headerRef,
}) => {
  const [state, setState] = useObjectState({
    title: 'Time Offs',
    isEdit: false,
    timeOffs: [],
    prevTimeOffs: [],
    focusedInputArray: [],
    contentId: undefined,
    startDate: undefined,
    endDate: undefined,
    DatePickerDisabled: true,
    disableSave: true,
    timeOffState: undefined,
    isEditVisible: true,
    isSaveVisible: false,
    isLoaded: false,
    isModalVisible: false,
    isAttemptingSave: false,
    showAddTimeOffButton: false,
    bypassGetBookings: false,
  });

  const toggleFormEdit = useCallback(() => {
    const editBool = !state.isEdit;
    if (editBool) {
      // edit mode is on
      setState({
        isEdit: true,
        DatePickerDisabled: false,
        isEditVisible: false,
        isCancelVisible: true,
        isSaveVisible: true,
      });
    }
  }, [setState, state.isEdit]);

  useEffect(() => {
    if (timeOffs) {
      const { momentTimeOffs, focusedInputArray } = getMomentTimeOffsAndFocusInputs(timeOffs);
      setState({
        timeOffs: momentTimeOffs,
        prevTimeOffs: momentTimeOffs,
        focusedInputArray,
        isLoaded: true,
      });
      setIsSectionLoaded({ multipleTimeOffs: true });
    }
  }, [timeOffs, setState, setIsSectionLoaded]);

  useEffect(() => {
    setState({
      showAddTimeOffButton: !state.timeOffs.some(
        (timeOff) => !timeOff.startDate || !timeOff.endDate
      ),
    });
  }, [setState, state.timeOffs]);

  const showCancelBookingsOrSave = () => {
    // disable saving so people cant hammer the button
    const shouldCheckBookings = !!state.timeOffs.filter(
      (timeOff) => !timeOff.delete && timeOff.startDate && timeOff.endDate
    ).length;
    setState({
      disableSave: true,
      isAttemptingSave: true,
      bypassGetBookings: !shouldCheckBookings,
    });
    if (shouldCheckBookings) {
      const timeOffsArray = state.timeOffs
        .filter((timeOff) => !timeOff.delete && timeOff.startDate && timeOff.endDate)
        .map((timeOff) => {
          return {
            startTime: timeOff.startDate.startOf('day').toISOString(),
            endTime: timeOff.endDate.endOf('day').toISOString(),
          };
        });
      getBookings(timeOffsArray);
    }
  };

  const saveForm = useCallback(() => {
    // get payload from state
    const payload = state.timeOffs
      .filter((timeOff) => timeOff.startDate && timeOff.endDate)
      .map((timeOff) => {
        return {
          id: timeOff.id,
          startDate: timeOff.startDate.format('YYYY-MM-DD'),
          endDate: timeOff.endDate.format('YYYY-MM-DD'),
          delete: timeOff.delete,
        };
      });

    // get user token and id for API call
    const userID = getUserData().id;
    // toggle the form to close it.
    toggleFormEdit();
    // if time off state is not initial state(0) or canceled(2) or ended(4) we ca use update time off endpoint otherwise use create new time off endpoint
    return apiWrapper
      .patch(`${apiHelper().apiEndpoint}/v2/therapist/${userID}/time-offs`, payload)
      .then(() => {
        toast(<div className="toaster toaster-success">Time off updated.</div>, {
          autoClose: 3000,
        });

        getTimeOffs();
        setState({
          isEditVisible: true,
          isEdit: false,
          isSaveVisible: false,
          isCancelVisible: false,
          DatePickerDisabled: true,
          disableSave: true,
          bypassGetBookings: false,
        });
      })
      .catch((err) => {
        setState({ disableSave: false, isAttemptingSave: false, bypassGetBookings: false });
        toast(<div className="toaster toaster-error">{err}</div>, {
          autoClose: 3000,
        });
      });
  }, [setState, state.timeOffs, getTimeOffs, toggleFormEdit]);

  useEffect(() => {
    // this either saves the form or launches the modal to cancel bookings
    if (!isLoadingBookings && state.isAttemptingSave) {
      setState({ isAttemptingSave: false });
      if (bookings && bookings.length) {
        setState({ isModalVisible: true });
      } else if (state.bypassGetBookings || !bookings || !bookings.length) {
        saveForm();
      }
    }
  }, [
    bookings,
    saveForm,
    setState,
    isLoadingBookings,
    state.isAttemptingSave,
    state.bypassGetBookings,
  ]);

  const getRemoveTimeOff = (timeOffs, index) => () => {
    const updatedTimeOffs = timeOffs.map((timeOff, idx) => {
      if (idx === index)
        return {
          ...timeOff,
          delete: true,
        };
      return timeOff;
    });
    setState({ timeOffs: updatedTimeOffs, disableSave: false });
  };

  function getDateFocusChanged(focusedInputArray, index) {
    return (event) => {
      const updatedInputsArray = focusedInputArray.map((focusedInput, idx) => {
        if (idx === index) return event;
        return focusedInput;
      });

      setState({ focusedInputArray: updatedInputsArray });
    };
  }

  const getOnDatesChange = (timeOffs, index) => (event) => {
    const filteredTimeOffs = timeOffs.filter((timeOff, idx) => index !== idx && !timeOff.delete);
    const overlapExists =
      event.startDate &&
      event.endDate &&
      !!filteredTimeOffs.find(
        (timeOff) =>
          event.startDate.isBefore(timeOff.startDate) && event.endDate.isAfter(timeOff.endDate)
      );
    const updatedTimeOffs = timeOffs.map((timeOff, idx) => {
      if (idx === index)
        return {
          ...timeOff,
          startDate: event.startDate,
          endDate: overlapExists ? null : event.endDate,
        };
      return timeOff;
    });
    const allDatesAreFilled = !updatedTimeOffs.find(
      (timeOff) => !timeOff.endDate || !timeOff.startDate
    );

    setState({ timeOffs: updatedTimeOffs, disableSave: !allDatesAreFilled });
  };

  const addNewTimeOffHandler = () => {
    const newTimeOffObject = {
      id: null,
      startDate: null,
      endDate: null,
    };
    setState({
      timeOffs: [...state.timeOffs, newTimeOffObject],
      focusedInputArray: [...state.focusedInputArray, null],
    });
  };

  const handleCloseModal = () => {
    setState({ isModalVisible: false, disableSave: false });
  };

  const cancelEdit = () => {
    setState({
      isEditVisible: true,
      isEdit: false,
      isCancelVisible: false,
      isSaveVisible: false,
      bypassGetBookings: false,
      DatePickerDisabled: true,
      timeOffs: state.prevTimeOffs,
      focusedInputArray: state.prevTimeOffs.map(() => null),
    });
  };

  const tooltip = (
    <Tooltip id="TimeOffTooltip" className="tooltip ts-tooltip ts-profile-text-white">
      Time Off Periods will be used to inform your clients ahead of time. During these times, new
      clients will not be able to select you as their therapist.
    </Tooltip>
  );

  return (
    <div id="time-off">
      <Col xs={12} className={`ts-panel-title ${state.isLoaded ? 'show-panel' : 'hidden-panel'}`}>
        <Col xs={3} sm={3} className="ts-font-black">
          <View ref={headerRef} row align="center">
            {state.title}{' '}
            <OverlayTrigger placement="top" trigger={['click', 'focus', 'hover']} overlay={tooltip}>
              <i className="fa fa-fw fa-question-circle fa-lg" />
            </OverlayTrigger>
          </View>
        </Col>
        <Button
          className={`ts-edit-button pull-right ${state.isEditVisible ? '' : 'hidden'}`}
          onClick={toggleFormEdit}
        >
          Edit
        </Button>

        <Button
          className={`btn-ts-default btn-ts-green ts-profile-text-white pull-right save-btn ${
            state.isSaveVisible ? '' : 'hidden'
          }`}
          onClick={showCancelBookingsOrSave}
          disabled={state.disableSave}
        >
          Save Changes
        </Button>
        <Button
          className={`btn-ts-default ts-profile-btn-text-green pull-right cancel-btn ${
            state.isCancelVisible ? '' : 'hidden'
          }`}
          onClick={cancelEdit}
        >
          Cancel
        </Button>
      </Col>
      <Col xs={12}>
        <Panel className={`ts-my-account-panel ${state.isLoaded ? 'show-panel' : 'hidden-panel'}`}>
          {state.timeOffs.map((timeOff, idx) =>
            timeOff.delete ? null : (
              <TimeOffPicker
                startDate={timeOff.startDate}
                endDate={timeOff.endDate}
                focusedInput={state.focusedInputArray[idx]}
                onDatesChange={getOnDatesChange(state.timeOffs, idx)}
                onFocusChange={getDateFocusChanged(state.focusedInputArray, idx)}
                onRemoveTimeOff={getRemoveTimeOff(state.timeOffs, idx)}
                isDayBlocked={getIsDayBlocked(state.timeOffs, state.focusedInputArray, idx)}
                isDisabled={state.DatePickerDisabled}
                isLocked={timeOff.state === 3}
                arrayIndex={idx}
                key={timeOff.startDate}
              />
            )
          )}
          {!state.DatePickerDisabled && state.showAddTimeOffButton && (
            <AddTimeOffButton onPress={addNewTimeOffHandler} />
          )}
        </Panel>
        <TimeOffCancelBookingsModal
          isVisible={state.isModalVisible}
          closeModal={handleCloseModal}
          bookings={bookings}
          scheduleTimeOff={saveForm}
        />
      </Col>

      <ToastContainer closeButton={false} position={toast.POSITION.BOTTOM_RIGHT} hideProgressBar />
    </div>
  );
};

export default TherapistTimeOff;
