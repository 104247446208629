import { useMemo } from 'react';

interface PaginationPageProps {
  currentPage: number;
  pageCount: number;
}

export const DOTS = '…';

const SIDE_ITEM_COUNT = 5;
const SIBILING_COUNT = 1;
const TOTAL_PAGE_NUMBER = SIBILING_COUNT + SIDE_ITEM_COUNT;

const usePaginationRange = ({ currentPage, pageCount }: PaginationPageProps) => {
  const range = (start, end) => {
    const length = end - start + 1;

    return Array.from({ length }, (_, index) => index + start);
  };

  const paginationRange = useMemo(() => {
    if (TOTAL_PAGE_NUMBER >= pageCount) {
      return range(0, pageCount - 1);
    }

    const lastPageIndex = pageCount - 1;

    const leftSiblingIndex = Math.max(currentPage - SIBILING_COUNT - 1, 1);
    const rightSiblingIndex = Math.min(currentPage + 1, lastPageIndex);

    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < pageCount - 2;

    const firstPageIndex = 0;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftRange = range(0, SIDE_ITEM_COUNT);
      return [...leftRange, DOTS, lastPageIndex];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightRange = range(pageCount - SIDE_ITEM_COUNT + 1, lastPageIndex);
      return [firstPageIndex, DOTS, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = range(leftSiblingIndex + 1, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }

    return [];
  }, [currentPage, pageCount]);
  return paginationRange;
};

export default usePaginationRange;
