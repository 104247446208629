import * as React from 'react';

import { Large } from '@talkspace/react-toolkit';

import BaseScreen from '../components/BaseScreen';
import ClinicalInfoForm from '../containers/ClinicalInfoForm';
import { SignupStepEnum } from '../types';

const ClinicalInfoScreen: React.FC = () => (
  <BaseScreen step={SignupStepEnum.clinicalInfo}>
    <Large style={{ maxWidth: 450, marginTop: 16, textAlign: 'center' }}>
      At Talkspace, security is a top priority. To protect your sensitive information, Talkspace
      enables Two-Factor Authentication for your account using your phone number. Message and data
      rates may apply.
    </Large>
    <ClinicalInfoForm />
  </BaseScreen>
);

export default ClinicalInfoScreen;
