import { VoidFunctionComponent } from 'react';
import Svg, { Path } from 'svgs';
import { EmotionStyle, useEmotionTheme } from '../../core/styled';

interface Props {
  width?: number;
  height?: number;
  style?: EmotionStyle;
}

const Check: VoidFunctionComponent<Props> = ({ width = 11, height = 9, style }) => {
  const titleText = 'Check';
  const { colors } = useEmotionTheme();
  return (
    <Svg
      title={titleText}
      width={width}
      height={height}
      viewBox="0 0 11 9"
      style={style}
      fill="none"
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3158 0.190102C11.0018 0.552129 11.2061 1.3095 10.7721 1.88174L5.37296 9L0.566782 5.87776C-0.0737064 5.46168 -0.188533 4.6913 0.31031 4.15707C0.809153 3.62285 1.73276 3.52707 2.37325 3.94315L4.62176 5.40385L8.28764 0.570723C8.72167 -0.00151587 9.62969 -0.171926 10.3158 0.190102Z"
        fill={colors.permaTalkspaceDarkGreen}
      />
    </Svg>
  );
};
export default Check;
