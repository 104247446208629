import View from '../../../components/View';
import styled, { EmotionStyle } from '../../../core/styled';

const DatePickersCSSWrapper = styled(View, {
  shouldForwardProp: (prop) =>
    ![
      'height',
      'topOffset',
      'containerStyle',
      'isCentered',
      'shouldRemoveArrow',
      'inputStyles',
      'focusStyle',
      'disabledStyle',
      'inputLabelStyle',
      'arrowStyle',
    ].includes(prop),
})<{
  height: number;
  topOffset?: number;
  containerStyle?: EmotionStyle;
  isCentered?: boolean;
  shouldRemoveArrow?: boolean;
  inputStyles?: EmotionStyle;
  focusStyle?: EmotionStyle;
  labelBeforeInput?: string;
  disabledStyle?: EmotionStyle;
  inputLabelStyle?: EmotionStyle;
  arrowStyle?: EmotionStyle;
  isCustomNav?: boolean;
}>(
  ({
    topOffset = 0,
    height,
    containerStyle,
    theme: { colors },
    isCentered = false,
    shouldRemoveArrow,
    inputStyles,
    focusStyle,
    disabledStyle,
    inputLabelStyle,
    arrowStyle,
    isCustomNav,
  }) => {
    const customNavStyles = isCustomNav
      ? {
          '.DayPickerNavigation_button:first-of-type': {
            outline: 'none',
            position: 'absolute',
            top: 2,
            left: 65,
          },
          '.DayPickerNavigation_button:last-of-type': {
            outline: 'none',
            position: 'absolute',
            top: 2,
            right: 65,
          },
        }
      : {};
    return {
      display: 'contents',
      '.CalendarMonth_caption': {
        // Copied from Big.tsx default variant
        fontSize: 17,
        paddingTop: topOffset,
        fontWeight: 700,
        paddingBottom: 34,
        lineHeight: '23px',
        letterSpacing: 0.1,
        color: colors.black,
      },
      '.DayPicker': {
        ...containerStyle,
      },
      '.DayPicker_weekHeader': {
        top: topOffset + 36,
      },
      '.DayPicker_weekHeader_li': {
        color: colors.black,
        small: {
          fontSize: 14,
        },
      },
      '.DayPicker_transitionContainer': {
        height: `${height}px !important`,
      },
      // Date pickers with inputs
      '.DateInput_input': {
        color: colors.black,
        fontSize: 18,
        fontWeight: 400,
        fontFamily: "'Roboto', sans-serif",
        ...inputStyles,
      },

      '.DateInput_input__disabled': {
        color: colors.emperor,
        fontStyle: 'normal', // Remove italics if disabled
      },
      '.DateInput_input::placeholder': {
        color: colors.grey950,
      },
      '.DateInput_input__focused': {
        borderBottom: `2px solid ${colors.green}`,
        ...focusStyle,
      },
      '.DateRangePicker_picker': {
        zIndex: 10,
        left: isCentered ? '-35px !important' : undefined,
      },
      '.DateRangePickerInput_arrow_svg': {
        display: shouldRemoveArrow && 'none',
      },
      '.DateRangePickerInput_arrow::before': {
        ...inputLabelStyle,
      },
      '.DateRangePickerInput__disabled': {
        ...disabledStyle,
      },
      '.DateInput_fang_1': {
        ...arrowStyle,
      },
      ...customNavStyles,
    };
  }
);

export default DatePickersCSSWrapper;
