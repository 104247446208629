import {
  Credentials,
  NativeBiometric,
  BiometricOptions,
  SetCredentialOptions,
  BiometryType,
} from 'capacitor-native-biometric';
import { safeIonicWrapper } from '../../ionicUtils';
import { nativeBiometricLogger } from '../../loggers';

type OptionalServer = { server?: string };

export type LoginBiometryType = 'Face ID' | 'Touch ID' | 'Passcode' | null;

const BIOMETRIC_LOGIN_SERVER = 'talkspace.com';

export const isBiometricLoginAvailable = safeIonicWrapper(
  () =>
    NativeBiometric.isAvailable().then(({ isAvailable, biometryType }) => {
      let type: LoginBiometryType = null;
      if (biometryType === BiometryType.FACE_ID) {
        type = 'Face ID';
      } else if (biometryType === BiometryType.TOUCH_ID) {
        type = 'Touch ID';
      } else {
        type = 'Passcode';
      }

      return {
        isAvailable,
        biometryType: type as LoginBiometryType,
      };
    }),
  Promise.resolve({ isAvailable: false, biometryType: null })
);
export const nativeBiometricVerifyIdentity = safeIonicWrapper<
  (options: BiometricOptions) => Promise<boolean>
>(
  (options) =>
    NativeBiometric.verifyIdentity(options)
      .then(() => true)
      .catch(() => false),
  Promise.resolve(false)
);
export const nativeBiometricGetCredentials = safeIonicWrapper<
  (options?: OptionalServer) => Promise<Credentials>
>(
  (options) =>
    NativeBiometric.getCredentials({ server: BIOMETRIC_LOGIN_SERVER, ...options }).catch(
      (error) => {
        nativeBiometricLogger.error(error);
        return {
          username: '',
          password: '',
        };
      }
    ),
  Promise.resolve({
    username: '',
    password: '',
  })
);
export const nativeBiometricSetCredentials = safeIonicWrapper<
  (options: Omit<SetCredentialOptions, 'server'> & OptionalServer) => Promise<boolean>
>(
  (options) =>
    NativeBiometric.setCredentials({ server: BIOMETRIC_LOGIN_SERVER, ...options })
      .then(() => true)
      .catch(() => false),
  Promise.resolve(false)
);
export const nativeBiometricDeleteCredentials = safeIonicWrapper<
  (options: OptionalServer) => Promise<boolean>
>(
  (options) =>
    NativeBiometric.deleteCredentials({ server: BIOMETRIC_LOGIN_SERVER, ...options })
      .then(() => true)
      .catch(() => false),
  Promise.resolve(false)
);

export { nativeBiometricLogger };
