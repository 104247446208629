import { View, Standard, Mini, Small } from '@talkspace/react-toolkit';
import { useCallback } from 'react';
import styled from '@/core/styled';

const StyledMini = styled(Mini)(() => {
  return {
    fontWeight: 500,
  };
});

const TextWrapper = {
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
};

const StyledHeading = styled(Standard)({
  ...TextWrapper,
  width: 150,
});

const MessagePreviewWrapper = styled(Small)<{ isHovering?: boolean }>(({ isHovering = false }) => {
  return {
    ...TextWrapper,
    maxWidth: isHovering ? 'calc(100% - 230px)' : '100%',
  };
});

interface Props {
  pseudonym: string;
  preferredName?: string | null;
  fullName: string;
  messagePreview?: string;
  isMobileView?: boolean;
  styles?: object;
  isHovering?: boolean;
  shouldDisplayDescription?: boolean;
}
const DashboardCellRoomDetail = ({
  pseudonym,
  preferredName,
  fullName,
  messagePreview,
  isMobileView = false,
  isHovering = false,
  styles,
  shouldDisplayDescription = true,
}: Props) => {
  const getDisplayText = useCallback(() => {
    const displayText: {
      heading: JSX.Element | null;
      body: JSX.Element | null;
      description: JSX.Element | null;
    } = {
      heading: null,
      body: null,
      description: null,
    };

    if (preferredName) {
      displayText.heading = <StyledHeading variant="standardBlack">{preferredName}</StyledHeading>;
      displayText.body = <StyledMini>{pseudonym}</StyledMini>;
    } else if (fullName) {
      displayText.heading = <StyledHeading variant="standardBlack">{fullName}</StyledHeading>;
      displayText.body = <StyledMini>{pseudonym}</StyledMini>;
    } else {
      displayText.heading = <StyledHeading variant="standardBlack">{pseudonym}</StyledHeading>;
      displayText.body = <StyledMini>Pending client name</StyledMini>;
    }
    if (messagePreview && shouldDisplayDescription) {
      displayText.description = (
        <MessagePreviewWrapper isHovering={isHovering} style={{ ...styles }}>
          {messagePreview}
        </MessagePreviewWrapper>
      );
    }
    return displayText;
  }, [
    preferredName,
    fullName,
    messagePreview,
    shouldDisplayDescription,
    pseudonym,
    isHovering,
    styles,
  ]);

  const { heading, body, description } = getDisplayText();

  return (
    <View row={!isMobileView} style={{ width: isMobileView ? 200 : '100%' }}>
      <View>
        {heading}
        {body}
      </View>
      {description}
    </View>
  );
};

export default DashboardCellRoomDetail;
