import { RoomType } from './types';

const TEEN_AGE = 18;

export const isTeen = (age: number) => age < TEEN_AGE;

export const clientInformationServiceFormatting = (roomType?: RoomType, age?: number) => {
  if (!roomType || !age) {
    return 'Individual therapy';
  }
  switch (roomType) {
    case 'couples_room':
      return 'Couples therapy';
    case 'private_room':
      if (isTeen(age)) {
        return 'Teens therapy';
      }
      return 'Individual therapy';
    default:
      return 'Individual therapy';
  }
};
