import Svg, { Path } from 'svgs';

interface Props {
  width?: string | number;
  height?: string | number;
  color?: string;
}

const AccumulatedHoursIcon = ({ width = 24, height = 24, color = '#3C8703' }: Props) => (
  <Svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <Path
      d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM12 22.2857C17.6806 22.2857 22.2857 17.6806 22.2857 12C22.2857 6.31936 17.6806 1.71429 12 1.71429C6.31936 1.71429 1.71429 6.31936 1.71429 12C1.71429 17.6806 6.31936 22.2857 12 22.2857Z"
      fill={color}
    />
    <Path
      d="M19.3809 12.0684C19.3809 14.0078 18.6104 15.8677 17.2391 17.2391C15.8677 18.6104 14.0078 19.3809 12.0684 19.3809C10.129 19.3809 8.269 18.6104 6.89764 17.2391C5.52628 15.8677 4.75586 14.0078 4.75586 12.0684C4.75586 10.129 5.52628 8.269 6.89764 6.89764C8.269 5.52628 10.129 4.75586 12.0684 4.75586C14.0078 4.75586 15.8677 5.52628 17.2391 6.89764C18.6104 8.269 19.3809 10.129 19.3809 12.0684ZM11.2246 7.28711V12.0684C11.2246 12.3496 11.3652 12.6133 11.6008 12.7715L14.9758 15.0215C15.3625 15.2816 15.8863 15.1762 16.1465 14.7859C16.4066 14.3957 16.3012 13.8754 15.9109 13.6152L12.9121 11.6184V7.28711C12.9121 6.81953 12.5359 6.44336 12.0684 6.44336C11.6008 6.44336 11.2246 6.81953 11.2246 7.28711Z"
      fill="white"
    />
  </Svg>
);

export default AccumulatedHoursIcon;
