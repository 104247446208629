import storage from '@/core/storage';
import { FunnelName } from '@/utils/analytics/trackerTypes';

const STORAGE_KEY_FUNNEL_NAME = 'funnelName';

// TODO: Change usages to receive `FunnelName | null`
const getFunnelName = (): FunnelName => storage.getItem(STORAGE_KEY_FUNNEL_NAME) as FunnelName;

const setFunnelName = (name: FunnelName): void => {
  storage.setItem(STORAGE_KEY_FUNNEL_NAME, name);
};

export { getFunnelName, setFunnelName };
