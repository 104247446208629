import { useMutation } from 'react-query';
import { BookingConfirmationStatus, BookingUpdateAction } from 'ts-frontend/types';

import apiHelper from '../../utils/api';
import apiWrapper from '../../utils/apiWrapper';
import { BookingConfirmationStatusExtended } from './types';

const getBookingState = (
  previousBookingState: BookingConfirmationStatus,
  action: BookingUpdateAction
): BookingConfirmationStatusExtended => {
  let newState: BookingConfirmationStatusExtended = previousBookingState;
  if (newState === 'ALREADY_CONFIRMED' && action === 'decline') {
    newState = 'PREVIOUSLY_CONFIRMED';
  } else if (newState === 'ALREADY_DECLINED' && action === 'confirm-no-break') {
    newState = 'PREVIOUSLY_DECLINED';
  }
  return newState;
};

interface UpdateBookingParams {
  roomID: number;
  bookingID: string;
  action: BookingUpdateAction;
  token: string;
}

interface UpdateBookingResponse {
  state: BookingConfirmationStatusExtended;
  start: string;
  end: string;
  therapistTimezone: string;
  hasBreakAfterSession: boolean;
}

const updateBooking = async (params: UpdateBookingParams): Promise<UpdateBookingResponse> => {
  const { roomID, bookingID, action, token } = params;

  const { data } = await apiWrapper.patch(
    `${apiHelper().apiEndpoint}/v3/rooms/${roomID}/bookings/${bookingID}/${action}?token=${token}`,
    {}
  );

  return { ...data.data, state: getBookingState(data.data.state, action) };
};

const useMutationUpdateBooking = () =>
  useMutation<UpdateBookingResponse, Error, UpdateBookingParams>(updateBooking);

export default useMutationUpdateBooking;
