import { useMemo } from 'react';
import { TouchableView, Tiny, useEmotionTheme } from '@talkspace/react-toolkit';
import { AvailabilityEnum } from '../types';
import styled from '../../../core/styled';
import XIcon from '../../../components/Account/XIcon';
import RecycleIcon from '../../../components/Icons/RecycleIcon';
import CalendarIcon from '../../../components/Icons/CalendarIcon';

interface BlockProps {
  availability: AvailabilityEnum;
  disableHover?: boolean;
  onPress: () => void;
}

export const CELL_HEIGHT = 25;
export const MIN_CELL_WIDTH = 40;
const CELL_WIDTH_LG = '12%';
const CELL_WIDTH_SM = '12%';

const CalendarTableCell = styled.td(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      width: isMobile ? CELL_WIDTH_SM : CELL_WIDTH_LG,
      minWidth: isMobile ? MIN_CELL_WIDTH : undefined,
      position: 'relative',
    };
  }
);

const EmptyCell = styled(TouchableView)<{
  availability: AvailabilityEnum;
  disableHover?: boolean;
  cellHeight: number;
}>(({ availability, disableHover, theme: { colors }, cellHeight }) => {
  let hoverBorder: string | undefined =
    availability === 'time-off' ? undefined : `1px solid ${colors.darkGreen}`;
  let backgroundColor = colors.white;
  let hoverBackgroundColor;
  if (['one-time-availability', 'recurring-availability'].includes(availability)) {
    // green blocks
    hoverBorder = undefined;
    backgroundColor = colors.permaViridianGreen;
    hoverBackgroundColor = colors.darkGreen;
  }
  if (availability === 'one-time-unavailability') {
    hoverBorder = `1px solid ${colors.loblollyGrey}`;
  }
  return {
    backgroundColor,
    display: 'flex !important', // override table cell styles
    borderRadius: 5,
    height: cellHeight,
    marginBottom: 3,
    marginRight: 3,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: disableHover ? 'default' : 'pointer',
    boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.22)',
    '&:hover': disableHover
      ? {}
      : {
          backgroundColor: hoverBackgroundColor,
          border: hoverBorder,
        },
    '&:hover + .block-tip': {
      visibility: !disableHover ? 'visible' : 'hidden',
      height: 'auto',
      opacity: 1,
    },
  };
});

const getAvailabilityTooltipText = (availability: AvailabilityEnum): string => {
  switch (availability) {
    case 'time-off':
      return 'Time off period';
    case 'recurring-unavailability':
      return '';
    case 'one-time-unavailability':
      return 'Unavailable time for this week only';
    case 'recurring-availability':
      return 'Weekly availability';
    case 'one-time-availability':
      return 'One-time availability';
    default:
      return '';
  }
};

const Tip = styled(Tiny)(({ theme: { colors } }) => {
  return {
    visibility: 'hidden',
    height: 0,
    position: 'absolute',
    top: 28,
    right: -20,
    borderRadius: 10,
    zIndex: 2,
    padding: 19,
    backgroundColor: colors.white,
    border: `1px solid ${colors.permaLinkWaterGrey}`,
    boxShadow: '0 4px 10px -4px rgba(0,40,65,0.40)',
    whiteSpace: 'nowrap',
    opacity: 0,
    transition: 'opacity .25s ease-in-out',
    transitionDelay: '.5s',
  };
});

const AvailabilityIcon = ({ availability }: { availability: AvailabilityEnum }) => {
  const { colors } = useEmotionTheme();
  switch (availability) {
    case 'time-off':
      return <XIcon style={{}} width={12} height={12} color={colors.slateGrey} />;
    case 'recurring-unavailability':
      return null;
    case 'one-time-unavailability':
      return <RecycleIcon width={14} height={18} />;
    case 'recurring-availability':
      return <RecycleIcon width={14} height={18} color={colors.white} />;
    case 'one-time-availability':
      return <CalendarIcon width={15} height={16} />;
    default:
      return null;
  }
};

const AvailabilityCalendarBlock = ({ availability, onPress, disableHover }: BlockProps) => {
  const tip = useMemo(() => getAvailabilityTooltipText(availability), [availability]);
  return (
    <CalendarTableCell>
      <EmptyCell
        onPress={onPress}
        availability={availability}
        disableHover={disableHover}
        cellHeight={CELL_HEIGHT}
      >
        <AvailabilityIcon availability={availability} />
      </EmptyCell>
      {tip && <Tip className="block-tip">{tip}</Tip>}
    </CalendarTableCell>
  );
};

export default AvailabilityCalendarBlock;
