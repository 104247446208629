/* eslint-disable import/prefer-default-export */
import moment from 'moment';

const valueBasedBonusTooltipText =
  'Value based bonus rewards you for long term active clients, defined as B2C active clients with engagement plans that remain with you for at least 60 days or B2B attestation clients (BH/EAP) for whom you have completed at least a third session attestation for the specific billing month.';

const tooltipTextsMap = {
  preOctober2024: {
    wordCount:
      'Word count is displayed for engagement plans only. Non-engagement based plans do not include word count.',
    amountEarned:
      'Amount shown is the cumulative payment for each client. There are no payouts for B2B clients that did not have a session report submitted by the last day of the month by 11:59:59pm UTC.',
    valueBasedBonus: valueBasedBonusTooltipText,
  },
  postOctober2024: {
    wordCount:
      'Word count is only displayed for rooms on the Engagement-Based Reimbursement model (Self-Pay and DTE). For these rooms, a 3:1 word count cap will go into effect after you send 750 words. For example, after you send 750 words, for every word a client sends, the system will count up to 3 of your words for reimbursement. This applies to all words exchanged, including transcribed words from audio and video messages.',
    amountEarned:
      'Amount shown is the cumulative payment for each client. There are no payouts for BH clients that did not have a session report submitted by the last day of the month by 11:59:59pm UTC. The maximum asynchronous messaging payout per room is capped at $150 per payout period.',
    valueBasedBonus: valueBasedBonusTooltipText,
  },
};

const newTooltipTextsDate = '2024-10-01';

export const getTooltipTexts = (payoutDate?: Date) =>
  moment(payoutDate).isAfter(newTooltipTextsDate)
    ? tooltipTextsMap.postOctober2024
    : tooltipTextsMap.preOctober2024;
