import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const standardPath =
  'M18 9.89648C18 13.4871 14.4187 16.3965 9.99995 16.3965C8.84058 16.3965 7.74057 16.1965 6.74682 15.8371C6.37495 16.109 5.7687 16.4809 5.04995 16.7934C4.29995 17.1184 3.39683 17.3965 2.49995 17.3965C2.29683 17.3965 2.11558 17.2746 2.03745 17.0871C1.95933 16.8996 2.00308 16.6871 2.1437 16.5434L2.15308 16.534C2.16245 16.5246 2.17495 16.5121 2.1937 16.4902C2.22808 16.4527 2.2812 16.3934 2.34683 16.3121C2.47495 16.1559 2.64683 15.9246 2.82183 15.6371C3.13433 15.1184 3.4312 14.4371 3.49058 13.6715C2.55308 12.609 1.99995 11.3059 1.99995 9.89648C1.99995 6.30586 5.5812 3.39648 9.99995 3.39648C14.4187 3.39648 18 6.30586 18 9.89648Z';
const smallPath =
  'M14.5003 7.625C14.5003 10.318 11.8144 12.5 8.50033 12.5C7.6308 12.5 6.8058 12.35 6.06048 12.0805C5.78158 12.2844 5.32689 12.5633 4.78783 12.7977C4.22533 13.0414 3.54799 13.25 2.87533 13.25C2.72299 13.25 2.58705 13.1586 2.52845 13.018C2.46986 12.8773 2.50267 12.718 2.60814 12.6102L2.61517 12.6031C2.6222 12.5961 2.63158 12.5867 2.64564 12.5703C2.67142 12.5422 2.71127 12.4977 2.76049 12.4367C2.85658 12.3195 2.98549 12.1461 3.11674 11.9305C3.35111 11.5414 3.57377 11.0305 3.6183 10.4562C2.91517 9.65937 2.50033 8.68203 2.50033 7.625C2.50033 4.93203 5.18627 2.75 8.50033 2.75C11.8144 2.75 14.5003 4.93203 14.5003 7.625Z';

const pathConfig: IconConfig = {
  standard: {
    default: { path: standardPath },
  },
  small: {
    default: { path: smallPath },
  },
};

const Comment = withDSIconMaker(pathConfig, 'comment');

export default Comment;
