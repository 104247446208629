import { LDFlags } from 'ts-frontend/types';

// eslint-disable-next-line import/prefer-default-export
export const LDFlagsFormatter = (flags: LDFlags) =>
  Object.assign(
    {},
    ...Object.keys(flags).map((key) => {
      return { [`LD_${key}`]: flags[key] };
    })
  );
