import { FunctionComponent, MouseEventHandler } from 'react';
import Svg, { Circle, Path, G } from 'svgs';
import BaseButton from '../BaseButton';
import withHover from '../../hoc/withHover';
import { WithHoverProps } from '../../hoc';
import { EmotionStyle } from '../../core/styled';

interface CollapseButtonProps extends WithHoverProps {
  ariaLabel?: string;
  width?: number;
  height?: number;
  backgroundColor?: string;
  color?: string;
  hoverColor?: string;
  onPress?: MouseEventHandler<HTMLButtonElement>;
  style?: EmotionStyle;
  dataQa?: string;
  roundedFocusStyle?: boolean;
  isCollapsed: boolean;
}

const CollapseButton: FunctionComponent<CollapseButtonProps> = ({
  ariaLabel = 'collapse',
  isHovering,
  onPress,
  style,
  width = 31,
  height = 31,
  backgroundColor,
  color,
  hoverColor,
  dataQa,
  roundedFocusStyle,
  isCollapsed = false,
  ...props
}) => (
  <BaseButton
    onPress={onPress}
    aria-label={ariaLabel}
    style={style}
    data-qa={dataQa}
    roundedFocusStyle={roundedFocusStyle}
  >
    <Svg
      width={width}
      height={height}
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {isHovering && <Circle cx={15.5} cy={15.5} r={15.5} fill="#EFF3FA" />}
      {isCollapsed ? (
        <G transform="translate(11,11)">
          <Path d="M9.004 6L5 2 1 6" stroke="#007E73" strokeWidth={2} strokeLinecap="round" />
        </G>
      ) : (
        <Path
          d="M11.5 14L15.503 18L19.5038 14"
          stroke="#007E73"
          strokeWidth={2}
          strokeLinecap="round"
        />
      )}
    </Svg>
  </BaseButton>
);

export default withHover(CollapseButton);
