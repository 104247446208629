import { Dialog } from '@capacitor/dialog';
import { safeIonicWrapper } from '../../ionicUtils';
import { openAppSettings } from '../settings';

export const dialogAlert = safeIonicWrapper(Dialog.alert, Promise.resolve());
export const dialogConfirm = safeIonicWrapper(Dialog.confirm, Promise.resolve({ value: false }));

export const showAudioVideoPermDialog = async ({
  isAudioOn,
  isVideoOn,
  purpose = 'join the call',
}: {
  isAudioOn: boolean;
  isVideoOn: boolean;
  purpose?: string;
}) => {
  const titleString =
    (isVideoOn ? 'Video' : '') + (isAudioOn && isVideoOn ? '/' : '') + (isAudioOn ? 'Audio' : '');
  const messageString =
    (isVideoOn ? 'camera' : '') +
    (isAudioOn && isVideoOn ? '/' : '') +
    (isAudioOn ? 'microphone' : '');

  const { value } = await dialogConfirm({
    title: `${titleString || 'Video/Audio'} Access Denied`,
    message: `Please allow ${messageString || 'camera/microphone'} permissions to ${purpose}`,
    okButtonTitle: 'Settings',
    cancelButtonTitle: 'OK',
  });

  if (value) {
    openAppSettings();
  }
};
