import PropTypes from 'prop-types';
import './ReactionButton.css';

const ReactionButton = (props) => {
  const { style, handler } = props;
  return (
    <button
      className="reaction-button"
      style={style}
      onClick={() => {
        handler();
      }}
    />
  );
};

ReactionButton.propTypes = {
  style: PropTypes.object,
  handler: PropTypes.func,
};

export default ReactionButton;
