import Svg, { G, Rect, Path, Defs } from 'svgs';

const StonksIcon = ({ width = 58, height = 58, ...otherProps }) => (
  <Svg width={width} height={width} viewBox="0 0 58 58" fill="none" {...otherProps}>
    <G>
      <Rect width={58} height={58} rx={29} fill="#fff" />
    </G>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      transform="translate(-8, -5)"
      d="M39.248 37.307l7.296-7.768c.43-.455 1.113-.133 1.113.51v3.339c0 .797.664 2.166 1.418 2.166h-.026c.755 0 1.34-1.369 1.34-2.166v-7.222c0-1.596-1.12-2.166-2.628-2.166h-6.829c-.753 0-1.469.286-1.469 1.083v.18c0 .798.716 1.625 1.47 1.625h3.339c.609 0 .913.418.483.873l-6.47 6.66c-.533.566-1.398.475-1.932-.089l-.563-.64c-1.066-1.129-2.795-1.15-3.863-.023l-8.6 8.82c-.534.562-.253 1.47-.253 2.036v-.003c1.366.564 1.65.563 2.184-.002l7.509-7.808a1.387 1.387 0 011.995 0l.59.593a2.663 2.663 0 003.897.002z"
      fill="#4CD964"
    />
    <Defs />
  </Svg>
);

export default StonksIcon;
