import cancelPromiseHelper from './cancelPromiseHelper';

export default class BaseApiHelper {
  /**
   * Use wrapWithCancel on an API call that will be used to determine
   * what a component will render. wrapWithCancel will stop the API from
   * updating an unmounted component. The component when making the API call must use
   * `.catch(dismissIfCancelled)`
   */
  protected wrapWithCancel: <T = any>(req: Promise<T>) => Promise<T>;

  public reset: () => void;

  public dismissIfCancelled: (err: Error) => any;

  public cancelAll: () => void;

  constructor() {
    const { cancelAll, reset, dismissIfCancelled, wrapWithCancel } = cancelPromiseHelper();
    this.wrapWithCancel = wrapWithCancel;
    this.reset = reset;
    this.dismissIfCancelled = dismissIfCancelled;
    this.cancelAll = cancelAll;
  }
}
