import { FunctionComponent, useEffect } from 'react';
import {
  View,
  BaseButton,
  ExtraHuge,
  Large,
  Standard,
  Spinner,
  HiddenText,
  Tray,
  RedX,
} from '@talkspace/react-toolkit';
import ChatSuccessIcon from '../../assets/ChatSuccessIcon';

interface LiveChatSessionStartedModalProps {
  handleClose: () => void;
  userAlreadyJoinedSession?: boolean;
  sessionAlreadyEnded: boolean;
  isTherapistChat?: boolean;
  postLiveSessionIsError: boolean;
}

const getModalContent = (
  userAlreadyJoinedSession,
  sessionAlreadyEnded,
  isTherapistChat,
  postLiveSessionIsError,
  handleClose
) => {
  if (userAlreadyJoinedSession === undefined && !postLiveSessionIsError) {
    return (
      <View aria-atomic="true" aria-live="polite">
        <HiddenText>Loading...</HiddenText>
        <Spinner isLoading />
      </View>
    );
  }
  switch (true) {
    case postLiveSessionIsError:
      return (
        <>
          <RedX size={74} />
          <Large
            variant="largeDarkGrey"
            style={{ marginTop: 11, marginBottom: 45, textAlign: 'center' }}
          >
            Something went wrong.
            <br />
            Please try joining the session again.
          </Large>

          <BaseButton
            style={{ height: 50 }}
            onPress={(e) => {
              e.stopPropagation();
              handleClose();
            }}
          >
            <Standard variant="standardDarkGrey">Close</Standard>
          </BaseButton>
        </>
      );
    case sessionAlreadyEnded === true:
      return (
        <>
          <Large
            variant="largeDarkGrey"
            style={{ marginTop: 11, marginBottom: 45, textAlign: 'center' }}
          >
            {isTherapistChat ? 'You have ended' : 'Your provider has ended'}
            <br />
            this live chat session.
          </Large>

          <BaseButton
            style={{ height: 50 }}
            onPress={(e) => {
              e.stopPropagation();
              handleClose();
            }}
          >
            <Standard variant="standardDarkGrey">Close</Standard>
          </BaseButton>
        </>
      );
    case userAlreadyJoinedSession === true:
      return (
        <>
          <Large
            variant="largeDarkGrey"
            style={{ marginTop: 11, marginBottom: 45, textAlign: 'center' }}
          >
            You've already joined
            <br />
            this live chat session.
          </Large>

          <BaseButton
            style={{ height: 50 }}
            onPress={(e) => {
              e.stopPropagation();
              handleClose();
            }}
          >
            <Standard variant="standardDarkGrey">Close</Standard>
          </BaseButton>
        </>
      );
    default:
      return (
        <>
          <ChatSuccessIcon />
          <ExtraHuge style={{ marginBottom: 20 }}>Have a great session!</ExtraHuge>
          <BaseButton
            style={{ height: 50 }}
            onPress={(e) => {
              e.stopPropagation();
              handleClose();
            }}
          >
            <Standard variant="standardDarkGrey">Close</Standard>
          </BaseButton>
        </>
      );
  }
};

const LiveChatSessionStartedModal: FunctionComponent<LiveChatSessionStartedModalProps> = ({
  handleClose,
  userAlreadyJoinedSession,
  sessionAlreadyEnded,
  isTherapistChat,
  postLiveSessionIsError,
}) => {
  useEffect(() => {
    if (
      userAlreadyJoinedSession === false &&
      sessionAlreadyEnded === false &&
      isTherapistChat &&
      !postLiveSessionIsError
    ) {
      handleClose();
    }
  }, [
    userAlreadyJoinedSession,
    isTherapistChat,
    sessionAlreadyEnded,
    handleClose,
    postLiveSessionIsError,
  ]);

  const isLoadingForProvider =
    isTherapistChat &&
    !userAlreadyJoinedSession &&
    sessionAlreadyEnded === false &&
    !postLiveSessionIsError;

  return (
    <Tray
      handleClose={handleClose}
      backgroundStyle={{
        backgroundColor: 'transparent',
      }}
    >
      {isLoadingForProvider ? (
        <Spinner isLoading />
      ) : (
        getModalContent(
          userAlreadyJoinedSession,
          sessionAlreadyEnded,
          isTherapistChat,
          postLiveSessionIsError,
          handleClose
        )
      )}
    </Tray>
  );
};

export default LiveChatSessionStartedModal;
