import { useEffect, RefObject } from 'react';

export const useA11y = (
  isEditMode: boolean,
  isEditKeyboardPressed: boolean,
  firstStarRef: RefObject<HTMLDivElement>
) => {
  // Shifts focus to the first message star if the edit button is selected via keyboard press. Keeping focus on the Edit button after it turns into Done would be confusing to SR users
  useEffect(() => {
    if (isEditMode && isEditKeyboardPressed && firstStarRef && firstStarRef.current) {
      firstStarRef.current.focus();
    }
  }, [firstStarRef, isEditKeyboardPressed, isEditMode]);
};

export default useA11y;
