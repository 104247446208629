import { useMutation, useQueryClient } from 'react-query';
import apiHelper from '../../utils/api';
import apiWrapper from '../../utils/apiWrapper';
import availabilityKeys from './queryKeys';
import bookingKeys from '../dashboard/queryKeys';

export interface DateRange {
  start: Date;
  end: Date;
}

export interface AvailabilityBlock {
  start: Date;
  end: Date;
  calendarEntityType: string;
  isRecurring: boolean;
}

interface UpdateAvailabilityMutateVariables {
  therapistID: number;
  addedBlocks?: AvailabilityBlock[];
  removedBlocks?: AvailabilityBlock[];
  type?: string;
  timzone?: string;
  dateRange?: DateRange;
}

const updateAvailability = async (params: UpdateAvailabilityMutateVariables) => {
  const {
    therapistID,
    addedBlocks = [],
    removedBlocks = [],
    type = 'availability',
    timzone,
  } = params;
  await apiWrapper.put(`${apiHelper().apiEndpoint}/v4/therapist/${therapistID}/availability`, {
    addedBlocks,
    removedBlocks,
    type,
    timzone,
  });
};

const useMutationUpdateAvailability = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, UpdateAvailabilityMutateVariables>(updateAvailability, {
    onSuccess: (_, { dateRange, therapistID }) => {
      if (dateRange) {
        const params = {
          start: dateRange.start.toISOString(),
          end: dateRange.end.toISOString(),
        };
        const queryKeyAvailability = availabilityKeys.therapistAvailabilityV3(therapistID, params);
        const queryKeyBooking = bookingKeys.bookings(therapistID, params);
        queryClient.invalidateQueries(queryKeyAvailability);
        queryClient.invalidateQueries(queryKeyBooking);
      }
    },
  });
};

export default useMutationUpdateAvailability;
