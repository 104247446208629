import { useState, useEffect, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import {
  ExtraTiny,
  OptionType,
  TextAreaRounded,
  useEmotionTheme,
  View,
  Standard,
  Small,
  TooltipV2,
  styled,
  ReminderIcon,
} from '@talkspace/react-toolkit';
import { keyframes } from '@emotion/core';
import * as outcomeMeasureActions from 'actions/OutcomeMeasureActions';
import { ValueType } from 'react-select';
import { useLocation, useRouteMatch } from '@/core/routerLib';
import SelectContainer from '../containers/SelectContainer';
import {
  getTextAreaContainerBorderStyleV2,
  getTextAreaStyleV2,
  SESSION_SUMMARY_WORD_LIMIT,
  BooleanDropdown,
  getSelectStyleV2,
  SESSION_SAMMARY_MIN_WORD_COUNT,
  getWordCount,
  LOW_ENGAGEMENT_ARTICLE,
  Modality,
} from '../../utils';
import { TextLink } from './styles';
import {
  useProgressNoteFormActions,
  useProgressNoteFormState,
} from '../context/TherapistProgressNoteFormContext';
import trackEvent from '../../../../../../modules/analytics/trackEvent';
import { Section } from '../context/types';
import NoteTemplate from './NoteTemplate';
import ssoHelper from '../../../../../../modules/utils/sso';
import useDidUpdateEffect from '../../../../../../hooks/useDidUpdateEffect';
import SmartNote from './SmartNote/SmartNote';
import SmartNotePlaceholder, { SmartNoteState } from './SmartNote/SmartNotePlaceholder';
import useGenerateSessionSummary from './SmartNote/hooks/useGenerateSessionSummary';
import SmartNoteRating from './SmartNote/SmartNoteRating';
import SmartNoteNotAvailableBanner from './SmartNote/SmartNoteNotAvailableBanner';
import SessionSummaryBanner, { BannerType } from './SessionSummaryBanner';

const LOW_ENGAGEMENT_TEMPLATE =
  'This contracted EAP session has completed a seven-day cycle during which the member had unlimited access to their therapist. During this session, the therapist attempted to engage the member by utilizing proven therapeutic interventions. The therapist will continue to reach out to the member in order to further encourage increased engagement in future sessions to assist the therapeutic process.';

const fadeIn = keyframes`
 0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const TooltipContent = () => (
  <View>
    <Standard>
      Should I submit a note even if the client did not engage during the session?
    </Standard>
    <Small variant="smallTSBlack" style={{ marginTop: 4 }}>
      Yes. Clients understand that one session equals 7 days of access to therapy. If the client did
      not engage, it is still important to document that no engagement occurred. You can document
      the session using the low engagement note template or your own words.{' '}
      <TextLink onClick={() => ssoHelper.openZendesk(LOW_ENGAGEMENT_ARTICLE)}>Learn more</TextLink>
    </Small>
  </View>
);

const WordCount = styled(Standard)<{ isLimit: boolean }>(({ theme: { colors }, isLimit }) => {
  return {
    fontSize: 12,
    color: isLimit ? colors.red : colors.grey950,
    fontWeight: 400,
  };
});

const BannerWrapper = styled(View)(({ theme: { colors } }) => {
  return {
    animation: `${fadeIn} 500ms cubic-bezier(.65,-0.01,.38,1) both`,
    height: 50,
    padding: '9px 10px',
    background: colors.blueMagneta,
    alignItems: 'center',
    borderRadius: '0 0 8px 8px',
  };
});

const BannerText = styled(Small)(({ theme: { colors } }) => {
  return {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 'normal',
    color: colors.purple600,
  };
});

const LowEngagementBanner = () => (
  <BannerWrapper row>
    <View style={{ marginRight: 10 }}>
      <ReminderIcon />
    </View>
    <BannerText>
      Initial session summary is less than 20 words and could result in a rejected claim or failed
      audit
    </BannerText>
  </BannerWrapper>
);

interface SessionSummarySectionProps {
  nextSection: Section;
  isEap?: boolean;
  isSmartNoteDisabled?: boolean;
  isError?: boolean;
  handleSmartNoteError: (isError: boolean) => void;
}

const SessionSummarySection = ({
  nextSection,
  isEap,
  isSmartNoteDisabled,
  isError,
  handleSmartNoteError,
}: SessionSummarySectionProps) => {
  const location = useLocation<{ outcomeMeasure: { id: number } }>();
  const match = useRouteMatch<{ roomID: string; noteID?: string }>();
  const { colors } = useEmotionTheme();
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);
  const [shouldDisplayBanner, setShouldDisplayBanner] = useState<boolean>(false);
  const [smartNoteState, setSmartNoteState] = useState<SmartNoteState>(null);
  const [bannerType, setBannerType] = useState<BannerType>(null);
  const generatedSessionSummary = useRef<string | null>(null);
  const formState = useProgressNoteFormState();

  const {
    sessionSummary,
    formSections,
    submissionStatus,
    lowEngagement,
    formMode,
    currentSection,
    isFirstNote,
    modalityID,
    unsubmittedSession: session,
  } = formState;

  const [generatedSessionSummaryUnavailable, setGeneratedSessionSummaryUnavailable] =
    useState<boolean>(Boolean(session && !session.generatedSessionSummaryAvailable));

  const textAreaWordCount = sessionSummary.summary ? getWordCount(sessionSummary.summary) : 0;

  const { setFormState } = useProgressNoteFormActions();

  const [summaryError, setSummaryError] = useState(false);

  const unsubmittedSessionID = new URLSearchParams(location.search).get('id');

  const sessionSummaryRef = useRef<HTMLDivElement>(null);

  const setSessionSummary = useCallback(
    (summary: string) => {
      setFormState({
        sessionSummary: {
          ...sessionSummary,
          summary,
        },
      });
    },
    [setFormState, sessionSummary]
  );

  const handleSessionSummary = (value: string) => {
    setSummaryError(false);
    generatedSessionSummary.current = value;
    const newSummary = sessionSummary.summary ? `${sessionSummary.summary}\n${value}` : value;
    setSessionSummary(newSummary);
  };

  const { handleAddSmartNote, feedback } = useGenerateSessionSummary({
    handleSessionSummary,
    handleSetSmartNoteState: (val: SmartNoteState) => setSmartNoteState(val),
  });

  useDidUpdateEffect(() => {
    if (smartNoteState === 'success') {
      setBannerType('smartNoteWarning');
      handleSmartNoteError(false);
    }
  }, [smartNoteState]);

  useEffect(() => {
    if (isError) {
      setBannerType('smartNoteError');
      setSummaryError(true);
      trackEvent(
        'missingSmartNoteEditError',
        {
          actionName: `progressNotesInteraction`,
        },
        {
          action: `User tries to submit note without editing and it triggers the "Missing info" error message`,
          roomID: match?.params?.roomID,
          unsubmittedSessionID,
        }
      );
    } else {
      setBannerType(null);
    }
  }, [isError, match?.params?.roomID, unsubmittedSessionID]);

  useEffect(() => {
    if (isError) {
      sessionSummaryRef?.current?.scrollIntoView();
    }
  }, [isError]);

  useDidUpdateEffect(() => {
    const currentSummary = sessionSummary.summary;
    const generatedSummary = generatedSessionSummary.current;
    if (!currentSummary || !generatedSummary || !currentSummary.includes(generatedSummary)) {
      setBannerType(null);
    } else {
      setBannerType('smartNoteWarning');
      handleSmartNoteError(false);
    }
  }, [sessionSummary.summary]);

  useDidUpdateEffect(() => {
    if (!sessionSummary.summary) {
      setSmartNoteState(null);
    }
  }, [sessionSummary.summary]);

  useEffect(() => {
    if (isFirstNote && textAreaWordCount < SESSION_SAMMARY_MIN_WORD_COUNT) {
      setFormState({ isSessionSummaryTooShort: true });
    } else {
      setFormState({ isSessionSummaryTooShort: false });
      setShouldDisplayBanner(false);
    }
  }, [isFirstNote, setFormState, textAreaWordCount, formMode]);

  useDidUpdateEffect(() => {
    setGeneratedSessionSummaryUnavailable(
      Boolean(session && !session.generatedSessionSummaryAvailable)
    );
  }, [session?.generatedSessionSummaryAvailable]);

  const markSectionAsIncomplete = () => {
    if (formSections.sessionSummary.completed) {
      setFormState({
        formSections: {
          ...formSections,
          sessionSummary: {
            open: formSections.sessionSummary.open,
            changed: true,
            completed: false,
          },
        },
      });
    }
  };

  const handleSummaryChange = (value: string) => {
    setShouldDisplayBanner(false);
    const currentWordCount = getWordCount(value);
    if (currentWordCount > SESSION_SUMMARY_WORD_LIMIT) {
      return;
    }

    markSectionAsIncomplete();
    setSummaryError(false);
    const valueHasOnlyWhitespaces = value?.trim()?.length === 0;
    const summary = valueHasOnlyWhitespaces ? '' : value;
    setSessionSummary(summary);
  };

  const handleConfirmPress = useCallback(() => {
    let error = false;
    if (!formState.isSaveAsDraftPressed) {
      if (!sessionSummary.summary) {
        setSummaryError(true);
        error = true;
      }
      if (error) {
        setFormState({ showGlobalError: true });
        return;
      }
    }

    setFormState({
      sessionSummary,
      formSections: {
        ...formSections,
        sessionSummary: {
          open: false,
          changed: true,
          completed: !!sessionSummary.summary,
        },
      },
    });
  }, [formSections, formState.isSaveAsDraftPressed, sessionSummary, setFormState]);

  useDidUpdateEffect(() => {
    if (currentSection === 'sessionSummary') {
      handleConfirmPress();
      setFormState({ currentSection: nextSection });
    }
  }, [currentSection]);

  const textAreaStyle = getTextAreaStyleV2(formMode === 'view', colors);

  const addNoteTemplate = (title: string, template: string) => {
    setSummaryError(false);
    if (sessionSummary.summary) {
      const value = sessionSummary.summary.concat(template);
      if (getWordCount(value) > SESSION_SUMMARY_WORD_LIMIT) {
        return;
      }
      setSessionSummary(value);
    } else {
      setSessionSummary(template);
    }

    trackEvent(
      'addNoteInfo',
      {
        actionName: 'progressNotesInteraction',
      },
      {
        action: 'Provider adds information to progress note',
        noteStatus: submissionStatus,
        noteTemplate: title,
        roomID: match.params.roomID,
        unsubmittedSessionID,
      }
    );
  };

  const handleLowEngagementDropdown = useCallback(
    (value: boolean) => {
      if (value && !sessionSummary.summary?.includes(LOW_ENGAGEMENT_TEMPLATE)) {
        setSummaryError(false);
        setSessionSummary(
          sessionSummary.summary
            ? sessionSummary.summary.concat(LOW_ENGAGEMENT_TEMPLATE)
            : LOW_ENGAGEMENT_TEMPLATE
        );
      }
      if (!value) {
        const newSessionSummary = sessionSummary?.summary?.replace(LOW_ENGAGEMENT_TEMPLATE, '');
        if (newSessionSummary !== undefined) {
          setSessionSummary(newSessionSummary);
        }
      }
      setFormState({ lowEngagement: value });
    },
    [sessionSummary, setFormState, setSessionSummary]
  );

  const handleTextAreaBlur = () => {
    if (isFirstNote && textAreaWordCount < SESSION_SAMMARY_MIN_WORD_COUNT) {
      setShouldDisplayBanner(true);
    } else {
      setShouldDisplayBanner(false);
    }
  };

  const handlePaste = (event) => {
    const pastedText = event.clipboardData.getData('text');
    const pastedTextWordCount = getWordCount(pastedText);

    if (textAreaWordCount + pastedTextWordCount > SESSION_SUMMARY_WORD_LIMIT) {
      event.preventDefault();
    }
  };

  const isLimitReached = textAreaWordCount === SESSION_SUMMARY_WORD_LIMIT;
  const countText = isLimitReached ? 'word limit reached' : 'words';

  const shouldShowRating = smartNoteState === 'success' && !feedback;

  return (
    <View ref={sessionSummaryRef}>
      {isEap && modalityID === Modality.TEXT && (
        <SelectContainer
          value={
            BooleanDropdown.find((it) => it.value === lowEngagement) as ValueType<
              OptionType<string>,
              boolean
            >
          }
          title="Low client engagement?"
          handleChange={(option) =>
            handleLowEngagementDropdown(option.value) as ValueType<OptionType<string>, boolean>
          }
          options={BooleanDropdown as any}
          styles={getSelectStyleV2({ colors })}
          isDisabled={formMode === 'view'}
          dataQa="lowClientEngagementDropdown"
          tooltipComponent={
            <View
              row
              align="center"
              style={{ height: 40 }}
              onMouseEnter={() => setIsTooltipOpen(true)}
              onMouseLeave={() => setIsTooltipOpen(false)}
            >
              <TooltipV2
                shouldDisplayCloseButton={false}
                containerStyle={{ left: -170, width: 355, top: 25 }}
                toolTipText={<TooltipContent />}
                isTooltipOpen={isTooltipOpen}
              />
            </View>
          }
        />
      )}
      {generatedSessionSummaryUnavailable && (
        <SmartNoteNotAvailableBanner modality={session!.sessionModality} />
      )}
      <View row align="center" style={{ marginBottom: 6 }}>
        <SmartNote
          smartNoteState={smartNoteState}
          smartNoteDisabled={isSmartNoteDisabled || generatedSessionSummaryUnavailable}
          handleAddSmartNote={handleAddSmartNote}
        />

        <NoteTemplate handleAppendNote={addNoteTemplate} isDisabled={formMode === 'view'} />
      </View>

      {smartNoteState !== 'success' && !!smartNoteState ? (
        <SmartNotePlaceholder
          text={sessionSummary.summary}
          smartNoteState={smartNoteState}
          handleAddSmartNote={handleAddSmartNote}
          handleSetSmartNoteState={(val: SmartNoteState) => setSmartNoteState(val)}
        />
      ) : (
        <TextAreaRounded
          onBlur={handleTextAreaBlur}
          data-testid="summaryTextArea"
          autoSize
          style={{ ...getTextAreaContainerBorderStyleV2(colors, summaryError) }}
          textAreaStyle={textAreaStyle}
          value={sessionSummary.summary || ''}
          onChangeText={handleSummaryChange}
          placeholder="Required. Client presented..."
          disabled={formMode === 'view'}
          rows={4}
          shouldDisplayBanner={shouldDisplayBanner}
          shouldDisplayTopBanner={!!bannerType}
          topBanner={<SessionSummaryBanner bannerType={bannerType} />}
          banner={<LowEngagementBanner />}
          onPaste={handlePaste}
          dataQa="sessionSummaryTextArea"
        />
      )}

      <View
        row
        justify={shouldShowRating ? 'space-between' : 'end'}
        style={{ marginTop: 4, marginBottom: shouldShowRating && 12 }}
      >
        {shouldShowRating && <SmartNoteRating />}

        <WordCount isLimit={isLimitReached}>
          {textAreaWordCount.toLocaleString()} {countText}
        </WordCount>
      </View>

      {summaryError && (
        <ExtraTiny
          data-testid="sessionSummaryError"
          style={{ marginTop: -20 }}
          variant="extraTinyError"
        >
          Required
        </ExtraTiny>
      )}
    </View>
  );
};

const mapStateToProps = (state: any) => {
  return {
    outcomeMeasures: state.outcomeMeasures.outcomeMeasures,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getOutcomeMeasures: () => dispatch(outcomeMeasureActions.getOutcomeMeasures()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionSummarySection);
