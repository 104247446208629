import {
  TouchableView,
  View,
  usePanelManagerState,
  useWindowWidth,
} from '@talkspace/react-toolkit';
import moment from 'moment';
import GenericProgressBar from '@talkspace/react-toolkit/src/components/GenericProgressBar/GenericProgressBar';
import HorizontalScroll from '@talkspace/react-toolkit/src/components/HorizontalScroll/HorizontalScroll';
import useMonthlyBonusGraphDataSetup from 'hooks/monthlyBonus/useMonthlyBonusGraphDataSetup';
import { useCallback, useState } from 'react';
import GraphExplanation from './GraphExplanation';
import styled from '../../core/styled';
import BasePayAndBonusPartitionSection from './BasePayAndBonusPartitionSection';
import BonusBreakdownArrowIcon from './Icons/BonusBreakdownArrowIcon';
import MilestoneContainer from './Milestone';
import Analytics from './Analytics';

const DEFAULT_HORIZONTAL_SCROLL_BOX_WIDTH = 417;
const MOBILE_HORIZONTAL_SCROLL_BOX_WIDTH = 260;
const TABLET_HORIZONTAL_SCROLL_BOX_WIDTH = 530;
const DEFAULT_GRAPH_WIDTH = 1145;
const MOBILE_GRAPH_WIDTH = 715;

const Button = styled(TouchableView)(({ theme: { colors } }) => {
  return {
    color: colors.accessibilityGreenDark,
    fontWeight: 700,
    fontSize: 16,
    marginTop: 30,
  };
});

const BonusBreakdownButton = styled(Button)(
  ({
    theme: {
      colors,
      window: { isMobile },
    },
  }) => {
    return {
      width: isMobile ? 'calc(100% - 20px)' : 224,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: isMobile ? 'center' : 'right',
      alignItems: isMobile ? 'center' : 'end',
      border: isMobile ? `solid 1px ${colors.permaBlueStoneNew}` : '',
      borderRadius: isMobile ? 7 : 0,
      height: 36,
      marginRight: isMobile ? 20 : 24,
      marginLeft: isMobile ? 20 : 0,
      marginTop: isMobile ? 20 : 0,
    };
  }
);

const GraphWidgetTitle = styled(View)(({ theme: { colors } }) => {
  return {
    width: '100%',
    font: 'Roboto',
    fontWeight: 700,
    color: colors.permaOuterSpaceNew,
    fontSize: 18,
    marginBottom: 16,
    marginTop: 30,
  };
});

const GraphWidgetContentWrapper = styled(View)(({ theme: { colors } }) => {
  return {
    border: `1px solid ${colors.permaLondonGray}`,
    width: '100%',
    paddingTop: 20,
    paddingBottom: 20,
    borderRadius: 7,
    textAlign: 'right',
  };
});

const GraphAndExplanations = styled(View)(
  ({
    theme: {
      window: { isSmall, isMedium, isLarge },
    },
  }) => {
    const isTabletAndBelow = isSmall || isMedium || isLarge;
    return {
      width: '100%',
      display: 'flex',
      flexDirection: isTabletAndBelow ? 'column' : 'row',
      flexWrap: 'wrap',
    };
  }
);

const ExplanationsWrapper = styled(View)(
  ({
    theme: {
      window: { isSmall, isMedium, isLarge, isMobile },
    },
  }) => {
    const isTabletAndBelow = isSmall || isMedium || isLarge;

    let marginTop = 0;
    if (isMobile) {
      marginTop = 4;
    } else if (isTabletAndBelow) {
      marginTop = 40;
    }

    return {
      height: isTabletAndBelow ? 'auto' : '100%',
      marginTop,
      display: 'flex',
      justifyContent: 'center',
      width: isTabletAndBelow ? '100% ' : 390,
      flexWrap: 'wrap',
      textAlign: 'left',
      paddingLeft: 10,
      paddingRight: 10,
    };
  }
);

interface TabGraphWidgetProps {
  year: number;
  month: number;
  setIsBonusModalVisible: (isVisible: boolean) => void;
}
const TabGraphWidget = ({ year, month, setIsBonusModalVisible }: TabGraphWidgetProps) => {
  const { isMobile = false, isSmall = false, isMedium = false, isLarge = false } = useWindowWidth();
  const isTabletAndBelow = isSmall || isMedium || isLarge;

  const getHorizontalScrollBoxWidth = useCallback(() => {
    if (isMobile) {
      return MOBILE_HORIZONTAL_SCROLL_BOX_WIDTH;
    }
    if (isTabletAndBelow) {
      return TABLET_HORIZONTAL_SCROLL_BOX_WIDTH;
    }
    return DEFAULT_HORIZONTAL_SCROLL_BOX_WIDTH;
  }, [isMobile, isTabletAndBelow]);
  const horizontalScrollBoxWidth = getHorizontalScrollBoxWidth();

  const graphWidth = isMobile ? MOBILE_GRAPH_WIDTH : DEFAULT_GRAPH_WIDTH;

  const [showMarkedPercentage, setShowMarkedPercentage] = useState<boolean>(true);
  const {
    monthlyBonusData,
    shouldResetHorizontalScrollOffset,
    setShouldResetHorizontalScrollOffset,
    setHorizontalScrollBoxOffset,
    setHorizontalScrollBoxOffsetUpdated,
    updatedBars,
    horizontalScrollStartPercentage,
    horizontalScrollEndPercentage,
    markedPercentages,
    ...otherProps
  } = useMonthlyBonusGraphDataSetup({
    horizontalScrollBoxWidth,
    graphWidth,
    year,
    month,
  });

  const { isPanelManagerScrolling } = usePanelManagerState();

  return (
    <>
      <BasePayAndBonusPartitionSection
        year={year}
        month={month}
        bonus={monthlyBonusData?.monthlyBonus.bonus || 0}
        basePay={monthlyBonusData?.monthlyBonus.basePay || 0}
        updatedAt={monthlyBonusData?.monthlyBonus.updatedAt}
        isFetchedForCurrentMonth={monthlyBonusData?.monthlyBonus.isFetchedForCurrentMonth}
      />

      <GraphWidgetTitle>{`Your ${moment().month(month).format('MMMM')}`} Bonus</GraphWidgetTitle>
      {monthlyBonusData?.monthlyBonusMapping && (
        <GraphWidgetContentWrapper>
          <GraphAndExplanations>
            <View style={{ marginRight: 40, alignSelf: isTabletAndBelow ? 'center' : '' }}>
              {horizontalScrollBoxWidth > 0 && horizontalScrollBoxWidth < graphWidth && (
                <HorizontalScroll
                  shouldResetHorizontalScrollOffset={shouldResetHorizontalScrollOffset}
                  shutDownReset={() => {
                    setShouldResetHorizontalScrollOffset(false);
                  }}
                  scrollBoxWidth={horizontalScrollBoxWidth}
                  numberOfSteps={
                    (monthlyBonusData?.monthlyBonusMapping?.length &&
                      monthlyBonusData?.monthlyBonusMapping.length - 1) ||
                    10
                  }
                  graphBoxDefaultOffsetPercentage={
                    monthlyBonusData?.monthlyBonus.graphBoxDefaultOffsetPercentage || 0
                  }
                  contentWidth={graphWidth}
                  handleOnScroll={(offset) => {
                    setHorizontalScrollBoxOffset(offset);
                    setHorizontalScrollBoxOffsetUpdated(true);
                  }}
                >
                  <GenericProgressBar
                    width={graphWidth}
                    bars={updatedBars}
                    markedPercentages={showMarkedPercentage ? markedPercentages : []}
                    milestones={
                      monthlyBonusData?.monthlyBonusMapping.map((it, index) => {
                        const milestoneProps = {
                          horizontalScrollPercentage: {
                            start: horizontalScrollStartPercentage || 0,
                            end: horizontalScrollEndPercentage || 100,
                          },
                          setShowMarkedPercentage,
                          percentage: it.percentage,
                          bonusAmount: it.bonusAmount,
                          bonusTotalHours: it.bonusTotalHours,
                          index,
                          isLargeMilestoneInformationStyle: !isMobile,
                          ...otherProps,
                        };
                        return {
                          ...it,
                          milestoneComponent: (
                            <MilestoneContainer
                              isParentScrolling={isPanelManagerScrolling}
                              tooltipText={it.tooltipText}
                              milestoneProps={milestoneProps}
                            />
                          ),
                        };
                      }) || []
                    }
                  />
                </HorizontalScroll>
              )}
            </View>
            <ExplanationsWrapper>
              {monthlyBonusData?.monthlyBonus?.explanations &&
                Object.entries<string>(monthlyBonusData.monthlyBonus.explanations as any).map(
                  ([key, value]) => <GraphExplanation iconType={key} text={value} />
                )}
            </ExplanationsWrapper>
          </GraphAndExplanations>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: isMobile ? 'center' : 'end',
            }}
          >
            <BonusBreakdownButton
              onPress={() => {
                Analytics.trackBonusVizBreakdownModalOpenedEvent({
                  selectedYear: year,
                  selectedMonth: month + 1,
                });
                setIsBonusModalVisible(true);
              }}
            >
              <View style={{ marginRight: 10 }}>Your bonus breakdown</View>
              {!isMobile && <BonusBreakdownArrowIcon />}
            </BonusBreakdownButton>
          </View>
        </GraphWidgetContentWrapper>
      )}
    </>
  );
};
export default TabGraphWidget;
