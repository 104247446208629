import * as actionTypes from '../constants/TreatmentPlannerConstants';
import apiWrapper from '../utils/apiWrapper';
import apiHelper from '../utils/api';

const getRoomIDFromRoute = () => {
  const regex = /\/room\/([^/]+)/;
  const match = window.location.pathname.match(regex);
  let routeRoomID = null;
  if (match && match[1]) {
    routeRoomID = match[1];
  }
  return routeRoomID;
};

export const getCurrentTreatmentPlan =
  ({ otherRoomID, otherClientID, type = 'current', treatmentPlanID }) =>
  (dispatch, getState) => {
    const {
      room: { roomID, clientUserID },
    } = getState();

    const routeRoomID = otherRoomID || roomID || null;
    const routeClientID = otherClientID || clientUserID || null;

    dispatch({ type: actionTypes.REQUEST_GET_TREATMENT_PLAN });
    return apiWrapper
      .get(
        `${
          apiHelper().apiEndpoint
        }/api/v1/rooms/${routeRoomID}/clients/${routeClientID}/treatment-plans/snapshot?type=${type}${
          treatmentPlanID ? `&treatmentPlanID=${treatmentPlanID}` : ''
        }`
      )
      .then((response) =>
        dispatch({
          type: actionTypes.RECEIVE_GET_TREATMENT_PLAN,
          ...response.data,
        })
      )
      .catch((error) =>
        dispatch({
          type: actionTypes.REQUEST_GET_TREATMENT_PLAN_ERROR,
          error,
        })
      );
  };

export const createNewTreatmentPlan = (presentingProblems) => (dispatch, getState) => {
  const {
    room: { roomID },
  } = getState();

  dispatch({ type: actionTypes.REQUEST_POST_TREATMENT_PLAN, presentingProblems });
  return apiWrapper
    .post(`${apiHelper().apiEndpoint}/api/v1/rooms/${roomID}/treatment-plans`, {
      presentingProblems,
    })
    .then((response) =>
      dispatch({
        type: actionTypes.RECEIVE_POST_TREATMENT_PLAN,
        ...response.data,
      })
    )
    .catch((error) =>
      dispatch({
        type: actionTypes.REQUEST_POST_TREATMENT_PLAN_ERROR,
        error,
      })
    );
};

export const editTreatmentPlan = (treatmentPlanID, updateBody) => (dispatch, getState) => {
  const {
    room: { roomID },
  } = getState();

  dispatch({ type: actionTypes.REQUEST_PATCH_TREATMENT_PLAN, ...updateBody });
  return apiWrapper
    .patch(`${apiHelper().apiEndpoint}/api/v1/rooms/${roomID}/treatment-plans/${treatmentPlanID}`, {
      ...updateBody,
    })
    .then((response) =>
      dispatch({
        type: actionTypes.RECEIVE_PATCH_TREATMENT_PLAN,
        ...response.data,
      })
    )
    .catch((error) =>
      dispatch({
        type: actionTypes.REQUEST_PATCH_TREATMENT_PLAN_ERROR,
        error,
      })
    );
};

export const getStandardGoals = (presentingProblemID) => (dispatch) => {
  dispatch({ type: actionTypes.REQUEST_GET_STANDARD_GOALS });
  return apiWrapper
    .get(
      `${apiHelper().apiEndpoint}/api/v1/clinical/presenting-problems/${presentingProblemID}/goals`
    )
    .then((response) =>
      dispatch({
        type: actionTypes.RECEIVE_GET_STANDARD_GOALS,
        standardGoals: response.data.data,
      })
    )
    .catch((error) =>
      dispatch({
        type: actionTypes.REQUEST_GET_STANDARD_GOALS_ERROR,
        error,
      })
    );
};

export const getGoals = (treatmentPlanID) => (dispatch, getState) => {
  const {
    room: { roomID },
  } = getState();

  const routeRoomID = getRoomIDFromRoute() || roomID;
  dispatch({ type: actionTypes.REQUEST_GET_GOALS });
  return apiWrapper
    .get(
      `${
        apiHelper().apiEndpoint
      }/api/v1/rooms/${routeRoomID}/treatment-plans/${treatmentPlanID}/goals`
    )
    .then((response) =>
      dispatch({
        type: actionTypes.RECEIVE_GET_GOALS,
        ...response.data,
      })
    )
    .catch((error) =>
      dispatch({
        type: actionTypes.REQUEST_GET_GOALS_ERROR,
        error,
      })
    );
};

export const saveGoals = (treatmentPlanID, goals) => (dispatch, getState) => {
  const {
    room: { roomID },
  } = getState();

  const strippedGoals = goals.map(({ id, description, presentingProblemID, standard }) => {
    return {
      description,
      presentingProblemID,
      presentingProblemGoalID: id,
      standard,
    };
  });
  dispatch({ type: actionTypes.REQUEST_POST_GOALS });
  return apiWrapper
    .post(
      `${apiHelper().apiEndpoint}/api/v1/rooms/${roomID}/treatment-plans/${treatmentPlanID}/goals`,
      [...strippedGoals]
    )
    .then((response) =>
      dispatch({
        type: actionTypes.RECEIVE_POST_GOALS,
        ...response.data,
      })
    )
    .catch((error) =>
      dispatch({
        type: actionTypes.REQUEST_POST_GOALS_ERROR,
        error,
      })
    );
};

export const editGoal = (treatmentPlanID, goalID, updatedGoal) => (dispatch, getState) => {
  const {
    room: { roomID },
  } = getState();
  dispatch({ type: actionTypes.REQUEST_PATCH_GOAL });
  return apiWrapper
    .patch(
      `${
        apiHelper().apiEndpoint
      }/api/v1/rooms/${roomID}/treatment-plans/${treatmentPlanID}/goals/${goalID}`,
      updatedGoal
    )
    .then((response) =>
      dispatch({
        type: actionTypes.RECEIVE_PATCH_GOAL,
        ...response.data,
      })
    )
    .catch((error) =>
      dispatch({
        type: actionTypes.REQUEST_PATCH_GOAL_ERROR,
        error,
      })
    );
};

export const removeGoal = (treatmentPlanID, goalID) => (dispatch, getState) => {
  const {
    room: { roomID },
  } = getState();

  dispatch({ type: actionTypes.REQUEST_DELETE_GOAL });
  return apiWrapper
    .delete(
      `${
        apiHelper().apiEndpoint
      }/api/v1/rooms/${roomID}/treatment-plans/${treatmentPlanID}/goals/${goalID}`
    )
    .then((response) =>
      dispatch({
        type: actionTypes.RECEIVE_DELETE_GOAL,
        ...response.data,
        goalID,
      })
    )
    .catch((error) =>
      dispatch({
        type: actionTypes.REQUEST_DELETE_GOAL_ERROR,
        error,
      })
    );
};

export const getStandardObjectives = (presentiongProblemID) => (dispatch) => {
  dispatch({ type: actionTypes.REQUEST_GET_STANDARD_OBJECTIVES });
  return apiWrapper
    .get(
      `${
        apiHelper().apiEndpoint
      }/api/v1/clinical/presenting-problems/${presentiongProblemID}/objectives`
    )
    .then((response) =>
      dispatch({
        type: actionTypes.RECEIVE_GET_STANDARD_OBJECTIVES,
        standardObjectives: response.data.data,
      })
    )
    .catch((error) =>
      dispatch({
        type: actionTypes.REQUEST_GET_STANDARD_OBJECTIVES_ERROR,
        error,
      })
    );
};

export const getObjectives = (treatmentPlanID) => (dispatch, getState) => {
  const {
    room: { roomID },
  } = getState();

  const routeRoomID = getRoomIDFromRoute() || roomID;

  dispatch({ type: actionTypes.REQUEST_GET_OBJECTIVES });
  return apiWrapper
    .get(
      `${
        apiHelper().apiEndpoint
      }/api/v1/rooms/${routeRoomID}/treatment-plans/${treatmentPlanID}/objectives`
    )
    .then((response) =>
      dispatch({
        type: actionTypes.RECEIVE_GET_OBJECTIVES,
        ...response.data,
      })
    )
    .catch((error) =>
      dispatch({
        type: actionTypes.REQUEST_GET_OBJECTIVES_ERROR,
        error,
      })
    );
};

export const saveObjectives = (treatmentPlanID, objectives) => (dispatch, getState) => {
  const {
    room: { roomID },
  } = getState();

  const strippedObjectives = objectives.map(
    ({ id, description, presentingProblemID, standard }) => {
      return {
        description,
        presentingProblemID,
        presentingProblemObjectiveID: id,
        standard,
      };
    }
  );
  dispatch({ type: actionTypes.REQUEST_POST_OBJECTIVES });
  return apiWrapper
    .post(
      `${
        apiHelper().apiEndpoint
      }/api/v1/rooms/${roomID}/treatment-plans/${treatmentPlanID}/objectives`,
      [...strippedObjectives]
    )
    .then((response) =>
      dispatch({
        type: actionTypes.RECEIVE_POST_OBJECTIVES,
        ...response.data,
      })
    )
    .catch((error) =>
      dispatch({
        type: actionTypes.REQUEST_POST_OBJECTIVES_ERROR,
        error,
      })
    );
};

export const editObjective =
  (treatmentPlanID, objectiveID, updatedObjective) => (dispatch, getState) => {
    const {
      room: { roomID },
    } = getState();

    dispatch({ type: actionTypes.REQUEST_PATCH_OBJECTIVE });
    return apiWrapper
      .patch(
        `${
          apiHelper().apiEndpoint
        }/api/v1/rooms/${roomID}/treatment-plans/${treatmentPlanID}/objectives/${objectiveID}`,
        updatedObjective
      )
      .then((response) =>
        dispatch({
          type: actionTypes.RECEIVE_PATCH_OBJECTIVE,
          ...response.data,
          objectiveID,
          ...updatedObjective,
        })
      )
      .catch((error) =>
        dispatch({
          type: actionTypes.REQUEST_PATCH_OBJECTIVE_ERROR,
          error,
        })
      );
  };

export const removeObjective = (treatmentPlanID, objectiveID) => (dispatch, getState) => {
  const {
    room: { roomID },
  } = getState();

  dispatch({ type: actionTypes.REQUEST_DELETE_OBJECTIVE });
  return apiWrapper
    .delete(
      `${
        apiHelper().apiEndpoint
      }/api/v1/rooms/${roomID}/treatment-plans/${treatmentPlanID}/objectives/${objectiveID}`
    )
    .then((response) =>
      dispatch({
        type: actionTypes.RECEIVE_DELETE_OBJECTIVE,
        ...response.data,
        objectiveID,
      })
    )
    .catch((error) =>
      dispatch({
        type: actionTypes.REQUEST_DELETE_OBJECTIVE_ERROR,
        error,
      })
    );
};

export const getStandardInterventions = (presentingProblemID, objectiveIDs) => (dispatch) => {
  dispatch({
    type: actionTypes.REQUEST_GET_STANDARD_INTERVENTIONS,
    presentingProblemID,
    objectiveIDs,
  });
  return apiWrapper
    .get(
      `${
        apiHelper().apiEndpoint
      }/api/v1/clinical/presenting-problems/${presentingProblemID}/interventions?objectiveIDs=${objectiveIDs}`
    )
    .then((response) =>
      dispatch({
        type: actionTypes.RECEIVE_GET_STANDARD_INTERVENTIONS,
        standardInterventions: response.data.data,
      })
    )
    .catch((error) =>
      dispatch({
        type: actionTypes.REQUEST_GET_STANDARD_INTERVENTIONS_ERROR,
        error,
      })
    );
};

export const getInterventions = (treatmentPlanID) => (dispatch, getState) => {
  const {
    room: { roomID },
  } = getState();

  const routeRoomID = getRoomIDFromRoute() || roomID;

  dispatch({ type: actionTypes.REQUEST_GET_INTERVENTIONS });
  return apiWrapper
    .get(
      `${
        apiHelper().apiEndpoint
      }/api/v1/rooms/${routeRoomID}/treatment-plans/${treatmentPlanID}/interventions`
    )
    .then((response) =>
      dispatch({
        type: actionTypes.RECEIVE_GET_INTERVENTIONS,
        ...response.data,
      })
    )
    .catch((error) =>
      dispatch({
        type: actionTypes.REQUEST_GET_INTERVENTIONS_ERROR,
        error,
      })
    );
};

export const saveInterventions =
  (treatmentPlanID, interventions, objectives) => (dispatch, getState) => {
    const {
      room: { roomID },
    } = getState();

    const strippedInterventions = interventions.map(
      ({
        id,
        description,
        presentingProblemID,
        standard,
        objectiveID,
        treatmentPlanObjectiveID,
      }) => {
        const treatentPlanObjectiveID = objectives.filter((objective) =>
          objective.presentingProblemObjectiveID
            ? objectiveID === objective.presentingProblemObjectiveID
            : treatmentPlanObjectiveID === objective.id
        )[0].id;
        return {
          description,
          presentingProblemID,
          presentingProblemInterventionID: id,
          standard,
          objectiveID: treatentPlanObjectiveID,
        };
      }
    );
    dispatch({ type: actionTypes.REQUEST_POST_INTERVENTIONS });
    return apiWrapper
      .post(
        `${
          apiHelper().apiEndpoint
        }/api/v1/rooms/${roomID}/treatment-plans/${treatmentPlanID}/interventions`,
        [...strippedInterventions]
      )
      .then((response) =>
        dispatch({
          type: actionTypes.RECEIVE_POST_INTERVENTIONS,
          ...response.data,
        })
      )
      .catch((error) =>
        dispatch({
          type: actionTypes.REQUEST_POST_INTERVENTIONS_ERROR,
          error,
        })
      );
  };

export const editIntervention =
  (treatmentPlanID, interventionID, updatedIntervention) => (dispatch, getState) => {
    const {
      room: { roomID },
    } = getState();

    dispatch({ type: actionTypes.REQUEST_PATCH_INTERVENTION });
    return apiWrapper
      .patch(
        `${
          apiHelper().apiEndpoint
        }/api/v1/rooms/${roomID}/treatment-plans/${treatmentPlanID}/interventions/${interventionID}`,
        updatedIntervention
      )
      .then((response) =>
        dispatch({
          type: actionTypes.RECEIVE_PATCH_INTERVENTION,
          ...response.data,
          interventionID,
          ...updatedIntervention,
        })
      )
      .catch((error) =>
        dispatch({
          type: actionTypes.REQUEST_PATCH_INTERVENTION_ERROR,
          error,
        })
      );
  };

export const removeIntervention = (treatmentPlanID, interventionID) => (dispatch, getState) => {
  const {
    room: { roomID },
  } = getState();

  dispatch({ type: actionTypes.REQUEST_DELETE_INTERVENTION });
  return apiWrapper
    .delete(
      `${
        apiHelper().apiEndpoint
      }/api/v1/rooms/${roomID}/treatment-plans/${treatmentPlanID}/interventions/${interventionID}`
    )
    .then((response) =>
      dispatch({
        type: actionTypes.RECEIVE_DELETE_INTERVENTION,
        ...response.data,
        interventionID,
      })
    )
    .catch((error) =>
      dispatch({
        type: actionTypes.REQUEST_DELETE_INTERVENTION_ERROR,
        error,
      })
    );
};

export const getArchivedTreatmentPlans = () => (dispatch, getState) => {
  const {
    room: { roomID },
  } = getState();

  dispatch({ type: actionTypes.REQUEST_GET_ARCHIVED_TREATMENT_PLANS });
  return apiWrapper
    .get(`${apiHelper().apiEndpoint}/api/v1/rooms/${roomID}/treatment-plans?type=archived`)
    .then((response) =>
      dispatch({
        type: actionTypes.RECEIVE_GET_ARCHIVED_TREATMENT_PLANS,
        ...response.data,
      })
    )
    .catch((error) =>
      dispatch({
        type: actionTypes.REQUEST_GET_ARCHIVED_TREATMENT_PLANS_ERROR,
        error,
      })
    );
};

export const getArchivedTreatmentPlan = (treatmentPlanID) => (dispatch, getState) => {
  const {
    room: { roomID },
  } = getState();

  dispatch({ type: actionTypes.REQUEST_GET_ARCHIVED_TREATMENT_PLAN });
  return apiWrapper
    .get(
      `${
        apiHelper().apiEndpoint
      }/api/v1/rooms/${roomID}/treatment-plans/${treatmentPlanID}/snapshot?type=archived`
    )
    .then((response) =>
      dispatch({
        type: actionTypes.RECEIVE_GET_ARCHIVED_TREATMENT_PLAN,
        ...response.data,
      })
    )
    .catch((error) =>
      dispatch({
        type: actionTypes.REQUEST_GET_ARCHIVED_TREATMENT_PLAN_ERROR,
        error,
      })
    );
};

export const cleanCurrentTreatmentPlan = () => (dispatch, getState) => {
  dispatch({ type: actionTypes.CLEAN_CURRENT_TREATMENT_PLAN });
};
