import {
  Button,
  ChevronRight,
  EmotionThemeProvider,
  styled,
  Tag,
  TextDS,
  View,
} from '@talkspace/react-toolkit';
import IconButton from '@talkspace/react-toolkit/src/designSystems/components/IconButton';
import { Check } from '@talkspace/react-toolkit/src/designSystems/icons';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { useHistory } from '@/core/routerLib';
import { getUserData } from '../../../utils/token';
import useQueryAutomaticMessages from '../../../hooks/account/useQueryAutomaticMessages';

const MainView = styled(View)(({ theme: { colorRoles, spacing } }) => {
  return {
    height: 48,
    width: '100%',
    backgroundColor: colorRoles.surfaces.successSubtleDefault,
    borderRadius: 6,
    paddingTop: spacing('space050'),
    paddingRight: spacing('space100'),
    paddingBottom: spacing('space050'),
    paddingLeft: spacing('space200'),
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: spacing('space200'),
  };
});

const BannerTextView = styled(View)({
  flex: 1,
  flexDirection: 'row',
  alignItems: 'center',
  gap: 8,
});

export default function AutomaticMessagesBanner() {
  const history = useHistory();

  const { id: therapistUserID } = getUserData();

  const { automaticWelcomeMessage: isAutomaticWelcomeMessageActive } = useFlags();

  const { data: automaticMessages = [], isLoading } = useQueryAutomaticMessages(
    therapistUserID,
    isAutomaticWelcomeMessageActive
  );

  const handleBannerButtonPress = () => {
    history.push('/my-account?scrollTo=automaticMessages');
  };

  const isAutomationOn = automaticMessages.some(
    (it) => it.type === 'Personalized welcome' && it.enabled
  );

  if (!isAutomaticWelcomeMessageActive || isLoading) {
    return null;
  }

  return (
    <EmotionThemeProvider version="2.0.0">
      <MainView>
        <BannerTextView>
          {isAutomationOn ? (
            <>
              <Check colorType="success" />
              <TextDS variant="bodySm">
                Your automated welcome message is sent to all new clients on match
              </TextDS>
            </>
          ) : (
            <>
              <Tag text="New!" color="successBoldDefault" />
              <TextDS variant="bodySm">Save time by setting up automated welcome messages</TextDS>
            </>
          )}
        </BannerTextView>

        {isAutomationOn ? (
          <IconButton
            dataQa="manageAutomaticMessagesButton"
            Icon={<ChevronRight colorType="brand" />}
            onPress={handleBannerButtonPress}
          />
        ) : (
          <Button
            dataQa="manageAutomaticMessagesButton"
            variant="tertiary"
            text="Get started"
            onPress={handleBannerButtonPress}
          />
        )}
      </MainView>
    </EmotionThemeProvider>
  );
}
