import { useEffect, useState, useMemo, FunctionComponent, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { View } from '@talkspace/react-toolkit';
import moment from 'moment';
import { History } from 'history';
import { useWizardActions, useWizardState, WizardActionStatus, persistData } from 'stepWizard';
import styled from '@/core/styled';
import {
  submitToContactSupport,
  submitDepartureInfo,
} from '../utils/providerLeavingWizardApiHelper';

const Wrapper = styled(View)({
  height: '100%',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
});

type Props = { history: History };

const FinalStep: FunctionComponent<Props> = ({ history }) => {
  const [error, setError] = useState<string>('');
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const {
    clientUserID,
    reasonForLeaving,
    isTherapist,
    leavingDatePsychiatrist,
    leavingDateTherapist,
    responses,
  } = useWizardState();
  const { setState } = useWizardActions();

  const departureDate = isTherapist ? leavingDateTherapist : leavingDatePsychiatrist;

  const finalStepProps = useMemo(() => {
    return {
      requestSent: {
        successTitle: 'Your request has been sent to the Quality Support Team.',
        successSubTitle: 'A team member will reach out to you soon to discuss your concerns.',
        finalStepAction: () => {
          submitToContactSupport(clientUserID, {
            ticketType: 'difficultyGettingAssistanceWhenNeeded',
            freeText: reasonForLeaving,
            ticketPriority: 'urgent',
          })
            .then(() => {
              setIsLoading(false);
              setIsSuccess(true);
            })
            .catch((err) => {
              setIsLoading(false);
              setError(err);
            });
        },
      },
      staying: {
        successTitle: 'We’re glad you’re staying!',
        successSubTitle:
          'Please reach out to the Quality Support Team if you need additional assistance.',
        finalStepAction: () => {
          setIsLoading(false);
          setIsSuccess(true);
        },
      },
      phasingOut: {
        successTitle: `You are phasing out of the Talkspace network effective immediately, ${moment().format(
          'MMMM D, YYYY.'
        )}`,
        successSubTitle: '',
        finalStepAction: () => {
          submitDepartureInfo(clientUserID, {
            isPhasingOut: true,
            departureDate: undefined,
            reason: reasonForLeaving,
          })
            .then(() => {
              setIsLoading(false);
              setIsSuccess(true);
            })
            .catch((err) => {
              setIsLoading(false);
              setError(err);
            });
        },
      },
      leaving: {
        successTitle: `You are leaving the Talkspace network effective ${departureDate.format(
          'MMMM D, YYYY.'
        )}`,
        successSubTitle: '',
        finalStepAction: () => {
          submitDepartureInfo(clientUserID, {
            isPhasingOut: false,
            departureDate: departureDate.toDate(),
            reason: reasonForLeaving,
          })
            .then(() => {
              setIsLoading(false);
              setIsSuccess(true);
            })
            .catch((err) => {
              setIsLoading(false);
              setError(err);
            });
        },
      },
    };
  }, [departureDate, reasonForLeaving, clientUserID]);
  const { screen } = useParams<{ screen: keyof typeof finalStepProps }>();

  const handlePersist = useCallback(() => {
    persistData(responses, setState, screen, error, {}, isSuccess);
  }, [error, isSuccess, responses, setState, screen]);

  const onMountEffect = useCallback(() => {
    finalStepProps[screen].finalStepAction?.();
  }, [finalStepProps, screen]);

  useEffect(() => {
    if (isSuccess || error) {
      handlePersist();
    }
  }, [isSuccess, error]); // eslint-disable-line

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(onMountEffect, []);

  return (
    <Wrapper>
      {screen && (
        <WizardActionStatus
          successTitle={finalStepProps[screen].successTitle}
          successSubTitle={finalStepProps[screen].successSubTitle}
          successButtonText="Done"
          handleAction={() => history.push('/')}
          actionHookState={{ error, isSuccess, isLoading }}
          titleStyles={{
            fontWeight: undefined,
            fontSize: undefined,
          }}
        />
      )}
    </Wrapper>
  );
};

export default FinalStep;
