import { FunctionComponent, useState } from 'react';
import {
  View,
  TouchableView,
  Mini,
  useEmotionTheme,
  MessageStar,
  AnimatedSwipeDownModal,
  Clipboard,
} from '@talkspace/react-toolkit';
import { webOnlyStyle, nativeOnlyStyle } from '@/core/styled/styleHelpers';
import styled from '@/core/styled/styled';
import {
  ID_OLDEST_LOADED_MESSAGE,
  ID_NEWEST_MESSAGE,
  ID_MESSAGE_DATE,
  ID_BOLD_MESSAGE_TEXT,
  ID_MESSAGE_TEXT,
  ID_BOTTOM_BOLD_MESSAGE_TEXT,
  arrowKeyNavigationClass,
} from '@/utils/IDConstants';
import { EMessage, MessageBodyTextOnly, messageTypeToBodyType } from '../../entities/EMessage';
import Bubble from '../Bubble';
import { MessageProps, position as positionType } from '../../types';
import SystemMessage from '../SystemMessage/SystemMessage';
import Name from '../Name';
import Time from '../Time';
import Day from '../Day';
import CircledExclamationMark from '../Icons/CircledExclamationMark';
import { getA11yAttributes } from './Message.a11y';
import { useSharedChatState } from '../../hooks/sharedChatContext';

interface MessageComponentProps extends MessageProps {
  onActionPress?: () => void;
  onSecondaryActionPress?: () => void;
  onStarPress: (message: EMessage) => void;
  containerWidth: number;
  isOldestLoadedMessage?: boolean;
}

const BubbleStarAlignmentWrapper = styled(View)<{ position: positionType }>(({ position }) => {
  return {
    flexDirection: position === 'left' ? 'row' : 'row-reverse',
    alignItems: 'center',
  };
});

const ModalActionText = styled(TouchableView)(({ theme: { colors } }) => {
  return {
    padding: '8px 32px',
    fontSize: 16,
    lineHeight: '35px',
    color: colors.black,
    height: 56,
    WebkitTouchCallout: 'none',
    useSelect: 'none',
  };
});

const FileUploadError = styled(View)<{ position: positionType }>(({ position }) =>
  position === 'left'
    ? {
        paddingRight: 8,
      }
    : {
        paddingLeft: 8,
      }
);

const MessageStarWrapper = styled(View)<{
  position: positionType;
}>(({ position }) => {
  return {
    marginLeft: position === 'left' ? 8 : 0,
    marginRight: position === 'right' ? 8 : 0,
    // opacity: 0 as opposed to display: none. Otherwise the star will disappear and not be tabbed when the message bubble blurs
    // technically this allows for an invisible star to be clicked but this is trivial because we set opacity: 1 on hover
    opacity: 0,
    '.ts-message.show-star &, .ts-message:hover &': {
      opacity: 1,
    },
  };
});

const Hr = styled(View)(({ theme: { colors } }) => {
  return {
    height: 1,
    ...webOnlyStyle({
      background:
        'linear-gradient(to right, rgba(0,0,0,0) 0%,rgba(229,231,239,1) 50%,rgba(0,0,0,0) 100%)',
      content: '""',
    }),
    ...nativeOnlyStyle({ backgroundColor: colors.grey }),
  };
});

const Message: FunctionComponent<MessageComponentProps> = (props) => {
  const {
    currentMessage,
    position,
    isLast,
    isPrevSameUser,
    isPrevSystem,
    isAroundSameTime,
    squareCorners,
    onStarPress,
    isLastReceivedMessage,
    isMedia,
    isOldestLoadedMessage,
    onPress,
  } = props;
  // an un-checked star should render and be selectable via the keyboard whenever focus enters the message row in which it's contained
  // in other words, when the message bubble with which the star is associated receives focus, the star should render
  // the moment that neither the unchecked star nor the message bubble have focus the star should dissapear
  const [isFocusInMessage, setIsFocusInMessage] = useState(false);
  const onFocusInMessage = () => {
    setIsFocusInMessage(true);
  };
  const onBlurMessage = () => {
    setIsFocusInMessage(false);
  };

  const [isMessageActionsModalOpen, setIsMessageActionsModalOpen] = useState<boolean>(false);

  const messageDateID = `${ID_MESSAGE_DATE}-${currentMessage.id}`;
  // specific to system messages
  const boldTextID = `${ID_BOLD_MESSAGE_TEXT}-${currentMessage.id}`;
  const textID = `${ID_MESSAGE_TEXT}-${currentMessage.id}`;
  const bottomBoldTextID = `${ID_BOTTOM_BOLD_MESSAGE_TEXT}-${currentMessage.id}`;

  const { isMobileApp } = useSharedChatState();

  const [bubbleTapped, setBubbleTapped] = useState(false);
  const { isSystem } = currentMessage;
  const timeSideStyles = position === 'right' ? { right: 0 } : { left: 0, marginLeft: 7 };
  const squareTop = squareCorners === 'TOP' || squareCorners === 'BOTH';
  const squareBottom = squareCorners === 'BOTTOM' || squareCorners === 'BOTH';
  const bubbleOnlyStyles = {
    marginTop: squareTop ? 0 : 10,
    marginBottom: squareBottom ? 0 : 10,
    alignItems: position === 'right' ? 'flex-end' : 'flex-start',
  };
  const messageWrapperStyles = isSystem
    ? {
        alignItems: 'stretch',
        padding: 12,
        paddingTop: 20,
        paddingBottom: 20,
      }
    : bubbleOnlyStyles;
  const { colors } = useEmotionTheme();
  const onBubblePress = () => {
    if (onPress) {
      onPress();
    }
    if (!isLast) setBubbleTapped(!bubbleTapped);
  };

  let alignment;
  if (isSystem) {
    alignment = 'stretch';
  } else {
    alignment = position === 'left' ? 'start' : 'end';
  }

  let messageID;
  if (isOldestLoadedMessage) {
    messageID = ID_OLDEST_LOADED_MESSAGE;
  } else if (isLast) {
    messageID = ID_NEWEST_MESSAGE;
  }

  if (currentMessage) {
    const isMediaRejected = currentMessage.media && currentMessage.media.status === 'rejected';
    const shouldShowStar =
      isFocusInMessage ||
      ((currentMessage.isStarred || isLastReceivedMessage || isMedia) &&
        !isSystem &&
        !isMediaRejected);

    const { systemDescribedby, bubbleDescribedby, bubbleExpanded, hiddenTime } = getA11yAttributes(
      currentMessage,
      bubbleTapped,
      boldTextID,
      textID,
      bottomBoldTextID,
      messageDateID,
      isLast
    );

    const handleMessageActionModal = (val: boolean) => {
      isMobileApp && setIsMessageActionsModalOpen(val);
    };

    const handleCopyMessage = () => {
      Clipboard.setString((currentMessage.messageBody as MessageBodyTextOnly).text);
      handleMessageActionModal(false);
    };

    const getActionsModalItem = () => {
      switch (messageTypeToBodyType(currentMessage.messageType)) {
        case 'TEXT_ONLY':
          return <ModalActionText onPress={handleCopyMessage}>Copy message</ModalActionText>;
        default:
          return null;
      }
    };

    return (
      // Using TouchableView as a wrapper for system messages ensures desired keybooard only focus effect for arrow key navigation
      <>
        {' '}
        <TouchableView
          tabIndex={-1}
          id={isSystem && messageID ? messageID : undefined}
          aria-describedby={systemDescribedby}
          align={alignment}
          className={`ts-message ${isSystem ? arrowKeyNavigationClass : undefined} ${
            shouldShowStar ? 'show-star' : ''
          }`}
          // overrides role="button" which is the default attribute for TouchableView
          elementType="div"
          styles={{ cursor: 'default' }}
        >
          {isSystem && !isPrevSystem && <Hr style={{ marginTop: 10 }} />}
          {!isAroundSameTime && !isSystem && <Day date={currentMessage.createdAt} />}
          <View style={messageWrapperStyles}>
            {!isSystem && position === 'left' && (!isPrevSameUser || !isAroundSameTime) && (
              <Name
                user={currentMessage.user}
                style={{
                  fontSize: 13,
                  color: colors.placeholderGrey,
                  margin: 2,
                  marginLeft: 7,
                }}
              />
            )}
            {currentMessage.messageType === 46 && (
              <Time
                id={messageDateID}
                {...props}
                style={{
                  margin: 2,
                  marginBottom: 8,
                  ...timeSideStyles,
                }}
              />
            )}
            {isSystem ? (
              <SystemMessage {...props} />
            ) : (
              <BubbleStarAlignmentWrapper position={position}>
                {isMediaRejected && (
                  <FileUploadError position={position}>
                    <CircledExclamationMark />
                  </FileUploadError>
                )}

                <Bubble
                  id={!isSystem && messageID ? messageID : undefined}
                  className={!isSystem ? arrowKeyNavigationClass : undefined}
                  onPress={onBubblePress}
                  onFocus={onFocusInMessage}
                  onBlur={onBlurMessage}
                  bubbleTapped={bubbleTapped}
                  hasPadding={!isMedia}
                  ariaExpanded={bubbleExpanded}
                  ariaControls={messageDateID}
                  ariaDescribedBy={bubbleDescribedby}
                  handleMessageActionModal={handleMessageActionModal}
                  isMobileApp={isMobileApp}
                  {...props}
                />
                {!isMediaRejected && (
                  <MessageStarWrapper position={position}>
                    <MessageStar
                      onFocus={onFocusInMessage}
                      onBlur={onBlurMessage}
                      isActive={currentMessage.isStarred}
                      onPress={() => onStarPress(currentMessage)}
                      ariaLabel={`for message sent ${hiddenTime}`}
                    />
                  </MessageStarWrapper>
                )}
              </BubbleStarAlignmentWrapper>
            )}
            {isMediaRejected && <Mini style={{ paddingTop: 4 }}>File format not supported</Mini>}
            {(isSystem || isLast || bubbleTapped) && currentMessage.messageType !== 46 && (
              <Time
                id={messageDateID}
                {...props}
                style={{
                  margin: 2,
                  marginBottom: 8,
                  ...timeSideStyles,
                }}
              />
            )}
          </View>
          {isSystem && <Hr />}
        </TouchableView>
        {isMobileApp && (
          <AnimatedSwipeDownModal
            shouldDisplayCloseButton={false}
            isOpen={isMessageActionsModalOpen}
            onClose={() => setIsMessageActionsModalOpen(false)}
            containerStyles={{ padding: '16px 5px' }}
          >
            <ModalActionText
              onPress={() => {
                onStarPress(currentMessage);
                handleMessageActionModal(false);
              }}
            >
              {currentMessage.isStarred ? 'Unstar message' : 'Star message'}
            </ModalActionText>
            {getActionsModalItem()}
          </AnimatedSwipeDownModal>
        )}
      </>
    );
  }
  return null;
};

export default Message;
