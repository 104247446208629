import * as React from 'react';
import View from '../View';
import styled, { EmotionStyle } from '../../core/styled';
import Image from '../Image';
import avatarDefaultIcon from './avatar_default.png';

interface Props {
  image?: string;
  imageStyle?: EmotionStyle;
  defaultIconSource?: string;
  onError?: (e: Record<string, unknown>) => void;
  width?: number | string;
  height?: number | string;
  alt?: string;
  style?: EmotionStyle;
}

const Wrapper = styled(View)<{
  width: number | string;
  height: number | string;
}>(({ width, height }) => {
  return {
    borderRadius: '50%',
    overflow: 'hidden',
    width,
    height,
  };
});

const AvatarImage = styled(Image)({
  width: '100%',
  height: '100%',
  filter: 'brightness(95%)',
});

const handleOnError = (e, defaultImageSource) => {
  // eslint-disable-next-line no-param-reassign
  e.target.onerror = null;
  // eslint-disable-next-line no-param-reassign
  e.target.src = defaultImageSource;
};

const Avatar: React.FunctionComponent<Props> = ({
  image,
  alt = 'avatar',
  imageStyle = {},
  defaultIconSource = avatarDefaultIcon,
  onError = handleOnError,
  width = 'auto',
  height = 'auto',
  ...otherProps
}) => (
  <Wrapper width={width} height={height} {...otherProps}>
    {image ? (
      <AvatarImage
        source={image}
        onError={(e) => onError(e, defaultIconSource)}
        style={{ height, width, ...imageStyle }}
        alt={alt}
        title={alt}
      />
    ) : (
      <AvatarImage source={defaultIconSource as string} style={imageStyle} alt={alt} title={alt} />
    )}
  </Wrapper>
);

export default Avatar;
