import Svg, { Defs, G, Circle, Rect, Path } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

const NoAvailabilitiesIcon = ({ width = 153, height = 130, ...otherProps }) => {
  const titleText = 'Calendar';
  const { colors } = useEmotionTheme();
  return (
    <Svg
      title={titleText}
      aria-label={titleText}
      width={width}
      height={height}
      viewBox="0 0 153 130"
      fill="none"
      {...otherProps}
    >
      <Circle cx="78.25" cy="65" r="65" fill={colors.greyBubble} />
      <Rect
        x="37.1466"
        y="81.1362"
        width="5"
        height="15"
        rx="2.5"
        transform="rotate(65 37.1466 81.1362)"
        fill={colors.permaZiggurat}
      />
      <Rect
        x="36.8413"
        y="92.3124"
        width="5"
        height="15"
        rx="2.5"
        transform="rotate(155 36.8413 92.3124)"
        fill={colors.permaZiggurat}
      />
      <Circle cx="145.75" cy="23.5" r="4.5" stroke={colors.permaConiferGreen} strokeWidth="5" />
      <Circle cx="11.25" cy="42" r="8" stroke={colors.permaLavenderBlue} strokeWidth="6" />
      <Path
        d="M47.8391 46.2159C47.6887 41.9076 51.0593 38.293 55.3677 38.1426L85.5313 37.0892C89.8394 36.9388 93.4539 40.3091 93.6046 44.6172L94.6601 74.7836C94.8109 79.0921 91.4402 82.707 87.1316 82.8575L56.9659 83.9109C52.6576 84.0613 49.043 80.6907 48.8926 76.3824L47.8391 46.2159Z"
        fill={colors.white}
        stroke={colors.permaPigeonPost}
        strokeWidth="5.2038"
      />
      {/* @ts-ignore */}
      <G filter="url(#NoAvailabilitiesIcon_filter0_d_5_345)">
        <Path
          d="M63.8577 55.6171C64.0082 51.3087 67.6227 47.9381 71.9311 48.0885L102.095 49.1419C106.403 49.2923 109.773 52.9066 109.623 57.2147L108.572 87.3812C108.422 91.6897 104.807 95.0607 100.498 94.9102L70.3328 93.8568C66.0245 93.7064 62.6538 90.0918 62.8043 85.7835L63.8577 55.6171Z"
          fill={colors.patensBlue}
          stroke={colors.purple}
          strokeWidth="5.2038"
        />
        <Rect
          x="72.8452"
          y="67.7807"
          width="6"
          height="6"
          rx="1.5"
          transform="rotate(2 72.8452 67.7807)"
          fill={colors.purple}
        />
        <Rect
          x="79.8409"
          y="68.025"
          width="6"
          height="6"
          rx="1.5"
          transform="rotate(2 79.8409 68.025)"
          fill={colors.purple}
        />
        <Rect
          x="86.8367"
          y="68.2693"
          width="6"
          height="6"
          rx="1.5"
          transform="rotate(2 86.8367 68.2693)"
          fill={colors.purple}
        />
        <Rect
          x="72.601"
          y="74.7764"
          width="6"
          height="6"
          rx="1.5"
          transform="rotate(2 72.601 74.7764)"
          fill={colors.purple}
        />
        <Rect
          x="79.5967"
          y="75.0207"
          width="6"
          height="6"
          rx="1.5"
          transform="rotate(2 79.5967 75.0207)"
          fill={colors.purple}
        />
        <Rect
          x="86.5924"
          y="75.265"
          width="6"
          height="6"
          rx="1.5"
          transform="rotate(2 86.5924 75.265)"
          fill={colors.purple}
        />
        <Rect
          x="93.5881"
          y="75.5093"
          width="6"
          height="6"
          rx="1.5"
          transform="rotate(2 93.5881 75.5093)"
          fill={colors.purple}
        />
        <Rect
          x="79.3524"
          y="82.0165"
          width="6"
          height="6"
          rx="1.5"
          transform="rotate(2 79.3524 82.0165)"
          fill={colors.purple}
        />
        <Rect
          x="86.3481"
          y="82.2608"
          width="6"
          height="6"
          rx="1.5"
          transform="rotate(2 86.3481 82.2608)"
          fill={colors.purple}
        />
        <Rect
          x="93.3439"
          y="82.5051"
          width="6"
          height="6"
          rx="1.5"
          transform="rotate(2 93.3439 82.5051)"
          fill={colors.purple}
        />
        <Path
          d="M65.4844 61.7706L108.458 63.2713"
          fill={colors.purple}
          strokeWidth="5.2"
          strokeLinecap="square"
        />
        <Path
          d="M77.6591 44.1842L77.3799 52.1793"
          fill={colors.purple}
          strokeWidth="5.2"
          strokeLinecap="round"
        />
        <Path
          d="M96.6475 44.8473L96.3683 52.8424"
          fill={colors.purple}
          strokeWidth="5.2"
          strokeLinecap="round"
        />
      </G>
      <Defs>
        <filter
          id="NoAvailabilitiesIcon_filter0_d_5_345"
          x="50.1975"
          y="36.5841"
          width="72.0325"
          height="75.9329"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="5" />
          <feGaussianBlur stdDeviation="5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0231266 0 0 0 0 0.37894 0 0 0 0 0.39088 0 0 0 0.0930398 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5_345" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5_345" result="shape" />
        </filter>
      </Defs>
    </Svg>
  );
};

export default NoAvailabilitiesIcon;
