import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import Submenu from '../../../../Reusable/Submenu/Submenu';
import Button from '../../../../Reusable/Buttons/Button';
import {
  editTreatmentPlan,
  createNewTreatmentPlan,
} from '../../../../../actions/TreatmentPlannerActions';

import './CreateNewTreatmentPlan.css';

class CreateNewTreatmentPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getViewContent() {
    const { roomID, currentTreatmentPlan, history, createNewTreatmentPlan, editTreatmentPlan } =
      this.props;
    const actions = [
      {
        title: 'Archive current plan',
        isSecondary: false,
        clickHandler: () => {
          editTreatmentPlan(currentTreatmentPlan.id, { archived: true })
            .then(() => createNewTreatmentPlan([]))
            .then((data) => {
              history.push(`/room/${this.props.roomID}/treatment-planner-goals`);
            });
        },
        dataQa: 'archiveCurrentPlanButton',
      },
      {
        title: 'Cancel',
        isSecondary: true,
        clickHandler: () => {
          history.push(`/room/${this.props.roomID}/clinical-tab`);
        },
        dataQa: 'cancelButton',
      },
    ];

    return (
      <div className="create-new-treatment-plan treatment-plan-card-container">
        <div className="treatment-plan-card-title">
          <i className="fa fa-fw fa-exclamation" />
          Sure you want to create a new plan?
        </div>
        <div className="treatment-plan-card-content">
          Creating a new treatment plan will automatically archive your current plan. You can always
          view archived plans by going to Clinical Actions and clicking on Archived treatment plans.
        </div>
        {actions.map((action, key) => (
          <Button
            key={key}
            title={action.title}
            isSecondary={action.isSecondary}
            clickHandler={action.clickHandler}
            dataQa={action.dataQa}
          />
        ))}
      </div>
    );
  }

  render() {
    return (
      <Submenu
        childComponents={[this.getViewContent()]}
        title="Create new treatment plan"
        prevRoute="clinical-tab"
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentTreatmentPlan: state.treatmentPlanner.currentTreatmentPlan,
    roomID: state.room.roomID,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ createNewTreatmentPlan, editTreatmentPlan }, dispatch);

const CreateNewTreatmentPlanContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateNewTreatmentPlan);

export default withRouter(CreateNewTreatmentPlanContainer);
