import { useEffect, useState, VoidFunctionComponent } from 'react';

import { Move } from '@talkspace/react-toolkit/src/components/Carousel';
import { Button, View, Carousel, Tiny } from '@talkspace/react-toolkit';
import { PaymentDetails } from 'ts-frontend/types';
import useQueryBusinessLine from 'ts-frontend/hooks/useQueryBusinessLine';
import { trackCancellationPolicyClick, trackMessagingCTAClick } from 'ts-analytics/mixpanel/events';
import { useTranslation, Trans } from '@talkspace/i18n';
import { useHistory, useParams } from '@/core/routerLib';

import { getUserData } from '@/auth/helpers/token';
import MessagingInformationStep from '../MessagingInformationStep';
import {
  useInRoomSchedulingActions,
  useInRoomSchedulingState,
} from '../../hooks/inRoomSchedulingContext';
import { getStepData } from '../MessagingInformationStep/MessagingInformationStep';
import BottomButtonContainer from '../BottomButtonContainer';

interface CancellationPolicyMessageParams {
  onLinkClick: () => void;
}

const CancellationPolicyMessage: VoidFunctionComponent<CancellationPolicyMessageParams> = ({
  onLinkClick,
}) => (
  <Trans>
    <Tiny style={{ width: 260, margin: 'auto', textAlign: 'center', marginTop: 8 }} inline>
      By clicking “I agree” you are agreeing to{' '}
      <Tiny
        dataQa="schedulingMessageSessionPolicyLink"
        inline
        onPress={onLinkClick}
        variant="tinyBoldGreen"
        style={{ cursor: 'pointer', color: '#006A6D' }}
      >
        Talkspace Cancellation Policy
      </Tiny>
      .
    </Tiny>
  </Trans>
);

const steps = [
  <MessagingInformationStep step={1} />,
  <MessagingInformationStep step={2} />,
  <MessagingInformationStep step={3} />,
];

interface Props {
  paymentDetails?: PaymentDetails;
  isOnboarding?: boolean;
}

const MessagingInformationFlow: VoidFunctionComponent<Props> = ({
  paymentDetails,
  isOnboarding,
}) => {
  const { roomID } = useParams<{ roomID: string }>();
  const history = useHistory();
  const [move, setMove] = useState<Move>(null);
  const [isLastStep, setIsLastStep] = useState(false);
  const [step, setStep] = useState(0);
  const [jumpTo, setJumpTo] = useState<number | null>(null);
  const {
    selectedCreditOption,
    creditOptions,
    isLoading: isLoadingSchedulingState,
    shouldShowBookingSuccess,
    room,
    modality,
    therapistInfo,
    isCreateAsyncSessionError,
    skipCreditCard,
  } = useInRoomSchedulingState();

  const { dispatchCreateAsyncSession, dispatchSetSelectedCreditOption } =
    useInRoomSchedulingActions();
  const { id: userID } = getUserData();

  const { data: businessLineData, isLoading: isLoadingBusinessLine } = useQueryBusinessLine(
    roomID,
    userID
  );

  useEffect(() => {
    if (selectedCreditOption?.type === 'introduction') {
      const therapyCreditOption = creditOptions?.find(
        (creditOption) => creditOption.type === 'therapy'
      );

      therapyCreditOption && dispatchSetSelectedCreditOption(therapyCreditOption);
    }
  }, [creditOptions, dispatchSetSelectedCreditOption, selectedCreditOption, modality]);

  useEffect(() => {
    if (shouldShowBookingSuccess) {
      if (isOnboarding) {
        history.push(`/room/${roomID}/onboarding/book-async-session/booking-success`);
      } else {
        history.push(`/in-room-scheduling/room/${roomID}/booking-success`);
      }
    }
  }, [shouldShowBookingSuccess, history, roomID, isOnboarding]);

  const handleOnPolicyClick = () => {
    if (isOnboarding) {
      history.push(`/room/${roomID}/onboarding/book-async-session/cancellation-policy`);
    } else {
      history.push(`/in-room-scheduling/room/${roomID}/cancellation-policy`);
    }
    trackCancellationPolicyClick({
      userID,
      roomID: Number(roomID),
      providerID: therapistInfo?.id!,
      planID: selectedCreditOption?.planID,
      sessionModality: modality,
      flow: 'Messaging flow',
    });
  };

  useEffect(() => {
    if (history.action === 'POP') {
      setJumpTo(2);
      setStep(2);
      setIsLastStep(true);
    }
  }, [history]);

  const handleStepChange = (changedStep: number) => {
    setIsLastStep(changedStep === steps.length - 1);
    setStep(changedStep + 1);
  };

  const handleLastStepForwardMove = () => {
    const availableNonIntroCredits =
      creditOptions?.filter((it) => it.type !== 'introduction' && it.availableCredits) || [];

    const isPurchase = availableNonIntroCredits.length === 0 && !!room?.isSessionBased;
    if (isPurchase && !paymentDetails && !skipCreditCard) {
      history.push(`/in-room-scheduling/room/${roomID}/payment-details`);
      return;
    }

    if (isPurchase) {
      const params = new URLSearchParams(history.location.search);
      if (isOnboarding || params.get('isOnboarding') === 'true') {
        history.push(`/room/${roomID}/onboarding/book-async-session/booking-checkout`);
      } else {
        history.push(`/in-room-scheduling/room/${roomID}/booking-checkout`);
      }
      return;
    }

    dispatchCreateAsyncSession({ isPurchase });
  };

  const isLoading = isLoadingBusinessLine || isLoadingSchedulingState;

  const { isBH } = businessLineData || { isBH: false };
  const { t: tBookingScreen } = useTranslation('bookingScreen');
  return (
    <View style={{ display: 'flex', alignItems: 'center' }}>
      <Carousel
        hideArrows
        containerStyle={{ maxWidth: '100%' }}
        move={move}
        jumpTo={jumpTo}
        resetMove={() => {
          setIsLastStep(false);
          setMove(null);
          setJumpTo(null);
        }}
        lastStepOneTimeAction={handleLastStepForwardMove}
        reactivateLastStepAction={shouldShowBookingSuccess || !!isCreateAsyncSessionError}
        onStepChange={handleStepChange}
      >
        {steps}
      </Carousel>
      <BottomButtonContainer>
        <Button
          style={{ width: 335 }}
          onPress={() => {
            trackMessagingCTAClick({
              userID,
              providerID: therapistInfo?.id!,
              roomID: Number(roomID),
              planID: selectedCreditOption?.planID,
              confirmationScreen: getStepData(step, isBH, tBookingScreen).text.title,
              action: isLastStep ? 'I agree' : 'Next',
            });
            setMove('forward');
          }}
          text={
            isLastStep
              ? tBookingScreen('available.agree', 'I agree', undefined)
              : tBookingScreen('available.next', 'Next', undefined)
          }
          isLoading={isLoading}
          dataQa={isLastStep ? 'messagingInformationAgreeButton' : 'messagingInformationNextButton'}
        />
        {isLastStep && <CancellationPolicyMessage onLinkClick={handleOnPolicyClick} />}
      </BottomButtonContainer>
    </View>
  );
};

export default MessagingInformationFlow;
