import moment from 'moment';
import storage from '../modules/core/storage';
import { setTrackerUserID } from '../modules/utils/analytics/eventTracker';

const LAST_ACTIVITY_KEY = 'logoutLastActivity';
const DEVICE_TOKEN_KEY = 'device_token';
const BIOMETRIC_LOCK = 'registered_for_biometric_lock';

export const removeLogoutLastActivity = () => storage.removeItem(LAST_ACTIVITY_KEY);

export const updateLogoutLastActivity = () =>
  storage.setItem(LAST_ACTIVITY_KEY, Date.now().toString());

export const getLogoutLastActivity = () => storage.getItem(LAST_ACTIVITY_KEY);

export const getBypassTwoFactorToken = () => storage.getItem('bypass_two_factor_token');

export const storeBypassTwoFactorToken = (bypassTwoFactorToken) =>
  storage.setItem('bypass_two_factor_token', bypassTwoFactorToken);

export const removeBypassTwoFactorToken = () => storage.removeItem('bypass_two_factor_token');

export const getAccessToken = () => storage.getItem('id_token');

export const getRefreshToken = () => storage.getItem('id_refresh_token');

export const getTokens = () => {
  const accessToken = storage.getItem('id_token');
  const refreshToken = storage.getItem('id_refresh_token');
  const accessTTL = Number(storage.getItem('id_token_expiration_date')) || 0;
  const refreshTTL = Number(storage.getItem('id_refresh_token_expiration_date')) || 0;
  return {
    accessToken,
    refreshToken,
    accessTTL,
    refreshTTL,
  };
};

export const setTokenAndUserID = (token, userID) => {
  const finalUserID = isNaN(userID) ? userID : parseInt(userID, 10);
  storage.setItem('id_token', token);
  storage.setItem('user', JSON.stringify({ id: finalUserID, firstName: '', lastName: '' }));
  setTrackerUserID(userID);
};

export const removeTokenFromLocalStore = () => {
  storage.removeItem('id_token');
  storage.removeItem('id_token_expiration_date');
  storage.removeItem('id_refresh_token');
  storage.removeItem('id_refresh_token_expiration_date');
  storage.removeItem('user');
  storage.removeItem(DEVICE_TOKEN_KEY);
};

export const getUserData = () => JSON.parse(storage.getItem('user')) || {};

export const storeLocalTokens = (
  accessToken,
  accessTokenExpirationDate,
  refreshToken,
  refreshTokenExpirationDate
) => {
  storage.setItem('id_token', accessToken);
  storage.setItem('id_token_expiration_date', accessTokenExpirationDate);
  storage.setItem('id_refresh_token', refreshToken);
  storage.setItem('id_refresh_token_expiration_date', refreshTokenExpirationDate);
};

export const receivedTokens = (data) => {
  const {
    userID,
    access: accessToken,
    refresh: refreshToken,
    accessTTLSeconds,
    refreshTTLSeconds,
  } = data;

  const accessTokenExpirationDate = moment().add(accessTTLSeconds, 'seconds').valueOf();

  const refreshTokenExpirationDate = moment().add(refreshTTLSeconds, 'seconds').valueOf();

  storeLocalTokens(
    accessToken,
    accessTokenExpirationDate,
    refreshToken,
    refreshTokenExpirationDate
  );

  return {
    userID,
    accessToken,
    accessTokenExpirationDate,
    refreshToken,
    refreshTokenExpirationDate,
  };
};

export const setOptedInBiometricLock = (optedIn) => {
  storage.setItem(BIOMETRIC_LOCK, String(optedIn));
};

export const getOptedInBiometricLock = () => {
  const optedIn = storage.getItem(BIOMETRIC_LOCK);
  return optedIn === 'true';
};

export const saveDeviceToken = (token = '') => {
  storage.setItem(DEVICE_TOKEN_KEY, Buffer.from(token).toString('base64'));
};

export const getDeviceToken = () => {
  const token64 = storage.getItem(DEVICE_TOKEN_KEY);
  if (!token64) return null;
  return Buffer.from(token64, 'base64').toString();
};
