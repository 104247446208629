import { FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import * as treatmentPlannerActions from 'actions/TreatmentPlannerActions';
import { BannerCard } from '@talkspace/react-toolkit';
import useQueryProgressNote from 'hooks/notes/useQueryProgressNote';
import { useHistory, useParams } from '@/core/routerLib';
import ExistingTreatmentPlan from './TreatmenPlan/ExistingTreatmentPlanV2';
import NoTreatmentPlan from './TreatmenPlan/NoTreatmentPlanV2';
import SectionContainer from '../containers/SectionContainer';
import { Section } from '../context/types';
import { TextLink } from './styles';
import { useProgressNoteFormState } from '../context/TherapistProgressNoteFormContext';
import { UnsubmittedSessionTask } from '../../../../../../hooks/dashboard/useQueryTaskListV3';

const TREATMENT_PLAN_HELP_CENTER =
  'https://providers-help.talkspace.com/hc/en-us/articles/360049538472-How-do-I-access-the-treatment-planner-on-the-app-and-how-does-it-work-';

type TreatmentPlanType = 'archived' | 'current';

export interface GetCurrentTreatmentPlanProps {
  otherRoomID?: number;
  otherClientID?: number;
  type?: TreatmentPlanType;
  treatmentPlanID?: number | null;
}
interface StoreProps {
  currentTreatmentPlan: Record<string, any> | undefined;
  getCurrentTreatmentPlan({
    otherRoomID,
    otherClientID,
    type,
    treatmentPlanID,
  }: GetCurrentTreatmentPlanProps): void;
  nextSection: Section;
  cleanCurrentTreatmentPlan: () => void;
}

const TreatmentPlanProgressSection: FunctionComponent<StoreProps> = ({
  currentTreatmentPlan,
  getCurrentTreatmentPlan,
  nextSection,
  cleanCurrentTreatmentPlan,
}) => {
  const { roomID, noteID } = useParams<{ roomID: string; noteID?: string }>();
  const formState = useProgressNoteFormState();
  const history = useHistory();

  const { lowEngagement, isFirstNote, unsubmittedSession, clientUserID, treatmentPlanID } =
    formState;
  const { data: progressNoteData } = useQueryProgressNote(
    clientUserID,
    noteID,
    formState.formMode !== 'create'
  );

  const { isTransferred } = (unsubmittedSession as UnsubmittedSessionTask) || {};

  useEffect(
    () => () => {
      if (
        history.location.pathname.includes('progress-notes') ||
        history.location.pathname.includes('tasks')
      ) {
        cleanCurrentTreatmentPlan();
      }
    },
    [cleanCurrentTreatmentPlan, history.location.pathname]
  );

  const noTreatmentPlanV2Subtitle = (
    <>
      {isTransferred && (
        <BannerCard
          variant="info"
          subtitle="The treatment planner tool is not available for transferred clients. Please write a treatment plan manually for this client."
          style={{ marginBottom: 16 }}
        />
      )}
      {lowEngagement ? (
        <>A treatment plan is not required for EAP sessions without client engagement.</>
      ) : (
        <>
          {isFirstNote
            ? 'If you are ready, then you can create your treatment plan for this client. Treatment plans are not required until your second progress note for each client. Learn more about'
            : 'Treatment plans are required on your second progress note for each client. You can update it anytime as your client makes progress. Learn more about'}{' '}
          <TextLink
            stopPropagation
            target="_blank"
            href={TREATMENT_PLAN_HELP_CENTER}
            style={{ margin: 0 }}
          >
            treatment plans
          </TextLink>
          .
        </>
      )}
    </>
  );

  useEffect(() => {
    if (progressNoteData?.status === 'submitted' && !treatmentPlanID) {
      cleanCurrentTreatmentPlan();
    } else {
      getCurrentTreatmentPlan({
        otherRoomID: Number(roomID),
        otherClientID: clientUserID,
        type: 'current',
        treatmentPlanID,
      });
    }
  }, [
    getCurrentTreatmentPlan,
    roomID,
    clientUserID,
    isTransferred,
    treatmentPlanID,
    progressNoteData?.status,
    cleanCurrentTreatmentPlan,
  ]);

  return currentTreatmentPlan?.id &&
    (currentTreatmentPlan.goals?.length || currentTreatmentPlan.objectives?.length) ? (
    <SectionContainer
      title="Treatment plan"
      subtitle="Select the goals, objectives, and interventions that you addressed this session and update client progress"
    >
      <ExistingTreatmentPlan
        currentTreatmentPlanID={currentTreatmentPlan.id}
        nextSection={nextSection}
      />
    </SectionContainer>
  ) : (
    <SectionContainer
      containerStyle={{ padding: '0 16px' }}
      title="Treatment plan"
      subtitle={noTreatmentPlanV2Subtitle}
    >
      <NoTreatmentPlan
        currentTreatmentPlanID={currentTreatmentPlan?.id}
        nextSection={nextSection}
      />
    </SectionContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    currentTreatmentPlan: state.treatmentPlanner.currentTreatmentPlan,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    cleanCurrentTreatmentPlan: () => dispatch(treatmentPlannerActions.cleanCurrentTreatmentPlan()),
    getCurrentTreatmentPlan: ({
      otherRoomID,
      otherClientID,
      type,
      treatmentPlanID,
    }: GetCurrentTreatmentPlanProps) =>
      dispatch(
        treatmentPlannerActions.getCurrentTreatmentPlan({
          otherRoomID,
          otherClientID,
          type,
          treatmentPlanID,
        })
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TreatmentPlanProgressSection);
