import { View, Standard, CircleError, RoundFilledCheckmark } from '@talkspace/react-toolkit';
import { useEffect } from 'react';
import { keyframes } from '@emotion/core';
import styled from '../../../core/styled';

const fadeIn = keyframes`
0% {
   opacity: 0;
 }
 100% {
   opacity: 1;
 }
`;

const FadeWrapper = styled(View)(() => {
  return {
    animation: `${fadeIn} 500ms cubic-bezier(.65,-0.01,.38,1) both`,
  };
});

const ToastMessage = ({ isError = false, message, onDismiss }) => {
  const duration = 3000;

  useEffect(() => {
    const timer = setTimeout(() => {
      onDismiss();
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, isError, onDismiss]);

  return (
    <FadeWrapper row style={{ gap: 8 }}>
      {isError ? <CircleError /> : <RoundFilledCheckmark />}
      <Standard>{message}</Standard>
    </FadeWrapper>
  );
};

export default ToastMessage;
