import { Tooltip, View, useEmotionTheme, useWindowWidth } from '@talkspace/react-toolkit';
import { BarsArray } from '@talkspace/react-toolkit/src/components/GenericProgressBar/GenericProgressBar';
import { RefObject, useCallback, useEffect, useRef } from 'react';
import { MarkedMilestone } from 'hooks/monthlyBonus/useMonthlyBonusGraphDataSetup';
import styled from '@/core/styled';

const MILESTONES_INVISIBILITY_PERCENTAGE_FROM_SCROLL_BOX_EDGES = 4;

const MilestoneWrapper = styled(View)(({ theme: { colors } }) => {
  return {
    position: 'relative',
    zIndex: 1,
    backgroundColor: '#FFFFFF',
    borderRadius: '50%',
    width: 10,
    height: 10,
    justifyContent: 'center',
    alignItems: 'center',
    transition: '0.4s ease',
    ':hover': {
      backgroundColor: colors.forestGreen,
    },
  };
});

const BonusAmountInformation = styled(View)<{ isLargeMilestoneInformationStyle: boolean }>(
  ({ isLargeMilestoneInformationStyle }) => {
    return {
      fontSize: isLargeMilestoneInformationStyle ? 28 : 16,
      fontWeight: 600,
      lineHeight: isLargeMilestoneInformationStyle ? '33px' : '20px',
      textAlign: 'center',
    };
  }
);

const BonusHoursInformation = styled(View)<{ isLargeMilestoneInformationStyle: boolean }>(
  ({ isLargeMilestoneInformationStyle }) => {
    return {
      width: 100,
      fontSize: isLargeMilestoneInformationStyle ? 16 : 14,
      fontWeight: 400,
      lineHeight: isLargeMilestoneInformationStyle ? '20px' : '18px',
      textAlign: 'center',
    };
  }
);

interface MilestoneInformationContainerProps {
  milestoneHoverIndex: number | null;
  milestoneIndex: number;
  milestoneAchievedIndex: number;
  isLargeMilestoneInformationStyle: boolean;
}
const MilestonesInformationContainer = styled(View)<MilestoneInformationContainerProps>(
  ({
    milestoneHoverIndex,
    milestoneIndex,
    milestoneAchievedIndex,
    isLargeMilestoneInformationStyle,
    theme: { colors },
  }) => {
    return {
      position: 'absolute',
      top: isLargeMilestoneInformationStyle ? 50 : 40,
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: isLargeMilestoneInformationStyle ? 104 : 66,
      height: isLargeMilestoneInformationStyle ? 63 : 48,
      color:
        milestoneHoverIndex === milestoneIndex ||
        (milestoneIndex === milestoneAchievedIndex && !milestoneHoverIndex)
          ? colors.forestGreen
          : colors.permaLividBlueNew,
      backgroundColor: milestoneHoverIndex === milestoneIndex ? colors.alabaster : ' white',
      borderRadius: 10,
      paddingTop: isLargeMilestoneInformationStyle ? 4 : 4,
      paddingBottom: isLargeMilestoneInformationStyle ? 4 : 2,
      fontFamily: 'Roboto',
      alignItems: 'center',
    };
  }
);

interface MilestoneProps {
  percentage: number;
  bonusAmount: number;
  bonusTotalHours: number;
  index: number;
  originalBars: BarsArray;
  setUpdatedBars: (arr: BarsArray) => void;
  milestoneHoverIndex: number | null;
  setMilestoneHoverIndex: (index: number | null) => void;
  originalMarkedMilestones: Array<MarkedMilestone>;
  updatedMarkedMilestones: Array<MarkedMilestone>;
  setUpdatedMarkedMilestones: (markedMilestones: Array<MarkedMilestone>) => void;
  horizontalScrollPercentage?: {
    start: number;
    end: number;
  };
  isLargeMilestoneInformationStyle: boolean;
  setShowMarkedPercentage: (shouldShow: boolean) => void;
  milestoneRef?: RefObject<HTMLDivElement>;
  isParentScrolling?: boolean;
}

const Milestone = ({
  percentage,
  bonusAmount,
  bonusTotalHours,
  index,
  originalBars,
  setUpdatedBars,
  milestoneHoverIndex,
  setMilestoneHoverIndex,
  originalMarkedMilestones,
  updatedMarkedMilestones,
  setUpdatedMarkedMilestones,
  horizontalScrollPercentage,
  isLargeMilestoneInformationStyle,
  setShowMarkedPercentage,
  milestoneRef,
  isParentScrolling,
}: MilestoneProps) => {
  const getMarkedMilestoneComponent = useCallback(
    (milestoneIndex: number) => {
      const markedMilestone = updatedMarkedMilestones.find(
        (it) => it.milestoneIndex === milestoneIndex
      );
      if (markedMilestone) {
        return markedMilestone.milestoneComponent;
      }
      return null;
    },
    [updatedMarkedMilestones]
  );

  const milestoneMouseEnter = () => {
    const milestoneHoverUpdatedArr = [...originalBars];
    const milestoneHoverUpdatedMarkedMilestones = [...originalMarkedMilestones];
    const onHoverRecordIndex = milestoneHoverUpdatedArr.findIndex((it) => it.onMilestoneHover);
    if (onHoverRecordIndex !== -1) {
      const onHoverRecord = milestoneHoverUpdatedArr[onHoverRecordIndex];
      milestoneHoverUpdatedArr.splice(
        onHoverRecordIndex,
        2,
        {
          ...onHoverRecord,
          widthPercentage: percentage,
        },
        { ...originalBars[originalBars.length], widthPercentage: 0 }
      );
      milestoneHoverUpdatedMarkedMilestones.splice(
        milestoneHoverUpdatedMarkedMilestones.findIndex((it) => it.type === 'predicted'),
        1
      );

      setUpdatedMarkedMilestones(milestoneHoverUpdatedMarkedMilestones);
      setUpdatedBars(milestoneHoverUpdatedArr);
      setShowMarkedPercentage(false);
    }

    setMilestoneHoverIndex(index);
  };

  interface HorizontalScrollPercentage {
    start: number;
    end: number;
  }
  const shouldShowMilestoneCheck = useCallback(
    (scrollPercentage?: HorizontalScrollPercentage) =>
      !(
        (
          percentage === 100 ||
          index === 0 ||
          (scrollPercentage &&
            scrollPercentage.start + MILESTONES_INVISIBILITY_PERCENTAGE_FROM_SCROLL_BOX_EDGES >
              percentage) || // milestone before box scope
          (scrollPercentage &&
            scrollPercentage.end - MILESTONES_INVISIBILITY_PERCENTAGE_FROM_SCROLL_BOX_EDGES <
              percentage)
        ) // milestone after box scope
      ),
    [percentage, index]
  );

  const shouldShowMilestone = shouldShowMilestoneCheck(horizontalScrollPercentage);
  const { isSmall = false, isMedium = false, isLarge = false } = useWindowWidth();
  const isTabletAndBelow = isSmall || isMedium || isLarge;
  useEffect(() => {
    // only for mobile , standard web will trigger the on mouse leave event
    if (isTabletAndBelow && isParentScrolling && milestoneHoverIndex) {
      setUpdatedBars(originalBars);
      setUpdatedMarkedMilestones(originalMarkedMilestones);
      setShowMarkedPercentage(true);
      setMilestoneHoverIndex(null);
    }
  }, [
    isParentScrolling,
    isTabletAndBelow,
    milestoneHoverIndex,
    originalBars,
    originalMarkedMilestones,
    setMilestoneHoverIndex,
    setShowMarkedPercentage,
    setUpdatedBars,
    setUpdatedMarkedMilestones,
  ]);

  return (
    <MilestoneWrapper
      ref={milestoneRef}
      key={percentage}
      style={{
        visibility: shouldShowMilestone ? 'visible' : 'hidden',
        opacity: shouldShowMilestone ? 1 : 0,
      }}
      onMouseEnter={milestoneMouseEnter}
      onClick={isTabletAndBelow ? milestoneMouseEnter : () => {}}
      onMouseLeave={() => {
        setUpdatedBars(originalBars);
        setUpdatedMarkedMilestones(originalMarkedMilestones);
        setShowMarkedPercentage(true);
        setMilestoneHoverIndex(null);
      }}
    >
      <View style={{ zIndex: 1 }}>{getMarkedMilestoneComponent(index)}</View>

      <MilestonesInformationContainer
        isLargeMilestoneInformationStyle={isLargeMilestoneInformationStyle}
        milestoneHoverIndex={milestoneHoverIndex}
        milestoneIndex={index}
        milestoneAchievedIndex={
          updatedMarkedMilestones.find((it) => it.type === 'achieved')?.milestoneIndex || -1
        }
      >
        <BonusAmountInformation isLargeMilestoneInformationStyle={isLargeMilestoneInformationStyle}>
          ${bonusAmount}
        </BonusAmountInformation>
        <BonusHoursInformation isLargeMilestoneInformationStyle={isLargeMilestoneInformationStyle}>
          {bonusTotalHours} hrs
        </BonusHoursInformation>
      </MilestonesInformationContainer>
    </MilestoneWrapper>
  );
};

const TooltipMessageWrapper = styled(View)(({ theme: { colors } }) => {
  return { background: colors.purple600, fontSize: 16 };
});

export interface Offset {
  top: number;
  left: number;
}

const MilestoneContainer = ({
  milestoneProps,
  tooltipText,
  modalOffset,
  isParentScrolling,
}: {
  milestoneProps: MilestoneProps;
  tooltipText?: string;
  modalOffset?: Offset;
  isParentScrolling?: boolean;
}) => {
  const milestoneRef = useRef<HTMLDivElement>(null);
  const tooltipTextRef = useRef<HTMLDivElement>(null);

  const { colors } = useEmotionTheme();

  const overrideTooltipPosition = useCallback(() => {
    const { top: modalOffsetTop, left: modalOffsetLeft } = modalOffset || { left: 0, top: 0 };

    const { top: milestoneTop, left: milestoneLeft } =
      milestoneRef?.current?.getBoundingClientRect() || {
        top: 0,
        left: 0,
      };

    const {
      top: tooltipTop,
      left: tooltipLeft,
      bottom: tooltipBottom,
      right: tooltipRight,
    } = tooltipTextRef?.current?.getBoundingClientRect() || {
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    };

    const tooltipWidth = tooltipRight - tooltipLeft;
    const tooltipHeight = tooltipBottom - tooltipTop;
    return {
      top: milestoneTop - modalOffsetTop - tooltipHeight - 24,
      left: milestoneLeft - modalOffsetLeft - tooltipWidth / 2 - 16,
    };
  }, [modalOffset]);

  const { isMobile } = useWindowWidth();

  return (
    <>
      {tooltipText ? (
        <Tooltip
          hide={isParentScrolling}
          overridePosition={!isMobile ? overrideTooltipPosition : undefined}
          messageComponent={
            <TooltipMessageWrapper ref={tooltipTextRef}>{tooltipText}</TooltipMessageWrapper>
          }
          backgroundColor={colors.purple600}
          place="top"
        >
          <Milestone
            isParentScrolling={isParentScrolling}
            milestoneRef={milestoneRef}
            {...milestoneProps}
          />
        </Tooltip>
      ) : (
        <Milestone {...milestoneProps} />
      )}
    </>
  );
};

export default MilestoneContainer;
