const AllGoalsAchievedIcon = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="18" cy="18" r="14.4" stroke="#EFF3FA" strokeWidth="7.2" />
    <circle cx="18" cy="18" r="18" fill="#4CD964" />
    <path
      d="M12.5996 18.7037L17.3262 22.5L23.3996 12.6"
      stroke="white"
      strokeWidth="3.6"
      strokeLinecap="round"
    />
  </svg>
);

export default AllGoalsAchievedIcon;
