import { useMutation } from 'react-query';
import { SessionModality } from 'ts-frontend/types';
import { TreatmentPlan } from 'components/Room/CRMContainer/NotesTab/types';
import apiHelper from '@/utils/api';
import apiWrapper from '../../utils/apiWrapper';
import { GeneratedSessionSummaryFeedback } from './useMutationRateSmartNote';

export const HTTP_STATUS_OK = 200;

export const HTTP_STATUS_ACCEPTED = 202;

export const DEFAULT_POLLING_TIME = 10;

export const MAX_NUMBER_OF_RETRIES = 2;

export interface GenerateSessionSummaryVariables {
  liveCallID?: number | null;
  freeLiveCallID?: number | null;
  asyncSessionID?: number | null;
  serviceStartDate: Date;
  serviceEndDate: Date;
  sessionModality: SessionModality;
  treatmentPlan?: TreatmentPlan;
  roomID: string;
}

export interface GenerateSessionSummaryResponse {
  generatedSessionSummaryID: number;
  generatedSessionSummary?: string;
  status: typeof HTTP_STATUS_OK | typeof HTTP_STATUS_ACCEPTED;
  feedback: GeneratedSessionSummaryFeedback | null;
}

const generateSessionSummary = async (
  params: GenerateSessionSummaryVariables
): Promise<GenerateSessionSummaryResponse> => {
  const { roomID, ...requestBody } = params;
  const response = await apiWrapper.post(
    `${apiHelper().apiEndpoint}/v2/rooms/${roomID}/progress-notes/generated-session-summaries`,
    requestBody
  );
  return { ...response.data.data, status: response.status };
};

const useMutationGenerateSessionSummary = () =>
  useMutation<GenerateSessionSummaryResponse, Error, GenerateSessionSummaryVariables>(
    generateSessionSummary
  );

export default useMutationGenerateSessionSummary;
