import { Nullable, ProgressNoteModalityType } from 'ts-frontend/types';
import { OptionsType, ValueType } from 'react-select';
import { useState, useEffect, useCallback, useMemo } from 'react';
import moment, { Moment } from 'moment';
import { VideoCallAPIResponse } from 'chat/types/videoCallTypes';
import { useVideoCallState } from 'chat/hooks/videoCallContext';
import {
  Small,
  View,
  RadioButton,
  DateRangePickerWithInput,
  OptionType,
  useEmotionTheme,
  Spinner,
  PlusMultiSelect,
  ExtraTiny,
  TooltipV2,
  styled,
} from '@talkspace/react-toolkit';

import useQueryRoomDetails from 'ts-frontend/hooks/useQueryRoomDetails';
import useQueryBusinessLine from 'ts-frontend/hooks/useQueryBusinessLine';
import useQueryProgressNoteHelperData from 'hooks/notes/useQueryProgressNoteHelperData';
import useQueryProgressNote from 'hooks/notes/useQueryProgressNote';
import useQueryNotes, { NoteItem, NoteSessionReportData } from 'hooks/notes/useQueryNotes';
import { useLocation, useParams } from '@/core/routerLib';
import { Theme } from '@/core/styled';
import { ProgressNoteFormState, Section } from '../context/types';
import {
  useProgressNoteFormActions,
  useProgressNoteFormState,
} from '../context/TherapistProgressNoteFormContext';
import {
  generateVideoCallSelectOption,
  getModalityIDFromParam,
  getServiceDatesFromVideoCall,
  isSameDate,
  Modality,
} from '../../utils';
import ErrorScreen from '../../ErrorScreen';
import analytics from '../../Analytics';
import useNotesRelevantVideoCalls from '../../hooks/useNotesRelevantVideoCalls';
import useMessagingDate from '../../hooks/useMessagingDate';
import useDidUpdateEffect from '../../../../../../hooks/useDidUpdateEffect';

interface VideoCallSelectOption {
  value: number;
  label: string;
}

type InputFocus = 'startDate' | 'endDate' | null;

const TooltipContainer = styled(View)(({ theme: { colors } }) => {
  return {
    height: 36,
    width: 335,
    background: colors.aliceBlue,
    borderRadius: 8,
    padding: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  };
});

const formatOngoingCallAsOption = (roomID: number, videoCall?: VideoCallAPIResponse | null) => {
  if (!videoCall) return null;
  const { videoCallID, modality, callStartedAt } = videoCall;
  if (!callStartedAt) return null;
  const startedDate = new Date(callStartedAt);
  const now = new Date();
  return generateVideoCallSelectOption({
    id: videoCallID,
    roomID,
    callStartedAt: startedDate,
    totalActiveCallDuration: (now.getTime() - startedDate.getTime()) / 1000,
    modality,
  });
};

const refetchInterval = (ongoingVideoCall) => (data) => {
  const { unassociatedVideoCalls } = data;
  if (unassociatedVideoCalls && unassociatedVideoCalls.length) {
    return false;
  }
  if (!ongoingVideoCall) {
    return false;
  }
  return 5000;
};

interface DateRange {
  startDate?: Date | null;
  endDate?: Date | null;
}

const getDateRangeStyles = (colors: Theme['colors'], isError: boolean, isDisabled?: boolean) => {
  return {
    dateRangeInputStyles: {
      border: `1px solid ${isError ? colors.permaFuchsia : colors.periwinkleGrey}`,
      borderRadius: '8px',
      flex: 1,
      height: '36px !important',
      fontSize: 14,
      background: isDisabled ? colors.permaGreyed : colors.permaPhantomWhite,
      paddingLeft: 8,
      paddingTop: 9,
      paddingBottom: 9,
    },
    dateRangeContainerStyles: {
      borderRadius: 8,
      boxShadow: '0px 4px 10px rgba(0, 40, 65, 0.4)',
      right: 19,
      zIndex: 10,
      marginTop: -5,
      top: -14,
    },
    inputLabelStyle: {
      content: "'To:'",
      fontSize: 14,
      marginRight: 6,
      marginLeft: 7,
    },
  };
};

const getRadioButtonStyle = (colors: Theme['colors'], disabled?: boolean) => {
  return {
    innerCircleStyle: { background: colors.permaBlueStoneNew, width: 13, height: 13 },
    circleStyle: {
      width: 21,
      height: 21,
      borderColor: colors.permaCometGray,
      backgroundColor: disabled && colors.permaGreyed,
    },
  };
};

const getDateRangeForNote = (note: NoteItem): DateRange => {
  return {
    startDate: new Date(note.startDate),
    endDate: note.endDate ? new Date(note.endDate) : null,
  };
};

const getIsSessionReopened = (
  currentSessionReport: NoteSessionReportData | undefined | null
): boolean => {
  if (!currentSessionReport) return false;
  const { progressNoteID: sessionReportProgressNoteID, reopenedAt } = currentSessionReport;
  return !!(reopenedAt && !sessionReportProgressNoteID);
};

const textDateOverlapError =
  'A text session progress note already exists for the selected date range';
const videoDateOverlapError =
  'A video session progress note already exists for the selected start date';
const endDateInFutureError =
  'Your selected end date is a future date: A progress note cannot be submitted until the date entered occurs';
const dateRangeError =
  'Required: Your selected range must be at least seven days for a messaging session';

const validateDateInput = (
  newDateRange: DateRange,
  noteItems?: NoteItem[],
  noteID?: number,
  roomID?: number
): string | null => {
  const { startDate: newStart, endDate: newEnd } = newDateRange;

  if (newStart && newEnd && noteItems?.length) {
    const progressNotes = noteItems.filter(
      (note) => note.roomID === roomID && note.noteID !== noteID && note.type === 'progress'
    );

    const videoNotes = progressNotes.filter((note) => note.modalityID === Modality.VIDEO);
    const sameDayAsVideoNote = videoNotes.some((videoNote) =>
      isSameDate(new Date(videoNote.startDate), newStart)
    );
    if (sameDayAsVideoNote) {
      return videoDateOverlapError;
    }

    const textNotes = progressNotes.filter((note) => note.modalityID === Modality.TEXT);
    const existingDateRanges = textNotes.map(getDateRangeForNote);
    const textNoteOverlap = existingDateRanges.some((existing) =>
      existing.startDate && existing.endDate
        ? newStart <= existing.endDate && newEnd >= existing.startDate
        : false
    );
    if (textNoteOverlap) {
      return textDateOverlapError;
    }
  }

  if (moment().isBefore(newEnd, 'day')) {
    return endDateInFutureError;
  }

  return null;
};

const StyledRadioButton = ({
  handlePress,
  title,
  isActive,
  disabled,
}: {
  handlePress: () => void;
  title: string;
  isActive: boolean;
  disabled?: boolean;
}) => {
  const { colors } = useEmotionTheme();
  const { innerCircleStyle, circleStyle } = getRadioButtonStyle(colors, disabled);
  return (
    <View align="center" row style={{ marginRight: 15 }}>
      <RadioButton
        innerCircleStyle={innerCircleStyle}
        circleStyle={circleStyle}
        style={{ marginRight: 6, cursor: disabled && 'default' }}
        isActive={isActive}
        onPress={handlePress}
        disabled={disabled}
      />
      <Small variant="smallTSBlack">{title}</Small>
    </View>
  );
};

const MissingSessionSection = ({
  toolTipText,
  containerText,
}: {
  toolTipText: string;
  containerText: string;
}) => {
  const [isHovering, setIsHovering] = useState<boolean>(false);
  return (
    <TooltipContainer
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      row
      align="center"
      justify="space-between"
    >
      <Small variant="smallTSBlack" style={{ flex: 1, textAlign: 'center' }}>
        {containerText}
      </Small>
      <TooltipV2
        toolTipText={toolTipText}
        isTooltipOpen={isHovering}
        containerStyle={{ right: -6 }}
      />
    </TooltipContainer>
  );
};

const SessionDateAndModalitySection = ({
  disabled,
  nextSection,
}: {
  disabled: boolean;
  nextSection: Section;
}) => {
  const { noteID, roomID } = useParams<{ roomID: string; noteID: string }>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dateAndModalityError, setDateAndModalityError] = useState<boolean>(false);
  const [dateError, setDateError] = useState<string | null>(null);
  const [isVideoCallIDParamValid, setIsVideoCallIDParamValid] = useState<Nullable<boolean>>(null);
  const { data: { clientUserID } = {} } = useQueryRoomDetails(roomID);
  const { videoCall: ongoingVideoCall } = useVideoCallState();

  const { data: progressNoteData } = useQueryProgressNote(clientUserID, noteID);
  const noteRoomID = progressNoteData?.roomID?.toString() || roomID;
  const {
    formMode,
    modalityID,
    serviceStartDate,
    serviceEndDate,
    videoCalls,
    sessionSummary,
    formSections,
    currentSection,
    isSaveAsDraftPressed,
  } = useProgressNoteFormState();

  const draftOrCreateMode = progressNoteData?.status === 'draft' || formMode === 'create';

  const { data: notesData } = useQueryNotes(roomID);

  const {
    isLoading: helperDataIsLoading,
    error: helperDataError,
    data: helperData,
  } = useQueryProgressNoteHelperData(roomID, true, refetchInterval(ongoingVideoCall));

  const { setFormState } = useProgressNoteFormActions();

  const [videoCallSelectOptions, setVideoCallSelectOptions] = useState<OptionType<number>[]>([]);
  const [selectedVideoCalls, setSelectedVideoCalls] = useState<VideoCallSelectOption[] | null>(
    null
  );
  const [isPrefilled, setIsPrefilled] = useState<boolean>(false);
  const [ongoingCallCheckInterval, setOngoingCallCheckInterval] = useState<number>(0);
  const [formModalityID, setFormModalityID] = useState<Modality | null>(modalityID);
  const [inputFocused, setInputFocused] = useState<InputFocus>(null);

  const {
    isLoading: businessLineIsLoading,
    error: businessLineError,
    data: businessLine,
  } = useQueryBusinessLine(noteRoomID, clientUserID);

  const handleSetServiceStartDate = useCallback(
    (startDate: Date) => {
      setFormState({ serviceStartDate: startDate });
    },
    [setFormState]
  );

  const {
    messagingStartDate,
    messagingEndDate,
    isMissingMessagingSession,
    handleSetMessagingStartDate,
    handleSetMessagingEndDate,
  } = useMessagingDate({
    formMode,
    businessLine,
    serviceEndDate,
    serviceStartDate,
    helperData,
    modalityID,
    handleSetServiceStartDate,
  });

  const { notesRelevantVideoCalls, disableLVSComponents } = useNotesRelevantVideoCalls({
    formMode,
    progressNoteData,
    helperData,
  });

  const { colors } = useEmotionTheme();
  const { search } = useLocation();
  const memoizedSearch = useMemo(() => new URLSearchParams(search), [search]);
  const videoCallIDParam = Number(memoizedSearch.get('videoCallID') as Nullable<string>);
  const eapSessionReportID = Number(memoizedSearch.get('eapSessionReportID'));
  const eapModality = notesData?.items.find(
    (it) => it?.sessionReportData?.id === eapSessionReportID
  )?.sessionReportData?.modalityID;
  const modalityParam = memoizedSearch.get('modality') as Nullable<ProgressNoteModalityType>;
  const { currentSessionReport } = notesData || {};
  const isSessionReopened = getIsSessionReopened(currentSessionReport);
  const messagingDisabled = isMissingMessagingSession && !isSessionReopened;
  const location = useLocation<{ sessionHighlights?: string }>();

  const setServiceDates = useCallback(
    (selectedCallIDs: Array<number>, state?: Partial<ProgressNoteFormState>) => {
      notesRelevantVideoCalls &&
        notesRelevantVideoCalls
          .filter((it) => selectedCallIDs.includes(it?.id || 0))
          .forEach((videoCall) => {
            if (videoCall) {
              setFormState({
                ...getServiceDatesFromVideoCall(videoCall),
                ...(state || {}),
              });
            }
          });
    },
    [setFormState, notesRelevantVideoCalls]
  );

  const validate = () => {
    let isError = false;

    if (!formModalityID) {
      return true;
    }

    if (formModalityID === Modality.TEXT) {
      if (!messagingStartDate || !messagingEndDate) {
        setDateError(dateRangeError);
        isError = true;
      }
    } else if (formModalityID === Modality.VIDEO) {
      isError = selectedVideoCalls?.length === 0;
    }

    return isError;
  };

  const handleConfirmPress = () => {
    const isError = !isSaveAsDraftPressed ? validate() : false;
    const completed = !!(
      (messagingEndDate && messagingStartDate) ||
      (selectedVideoCalls && selectedVideoCalls?.length > 0)
    );

    if (isError) {
      setDateAndModalityError(true);
      setFormState({ showGlobalError: true });
      return;
    }

    if (formModalityID === Modality.TEXT) {
      setFormState({
        modalityID: Modality.TEXT,
        serviceStartDate: messagingStartDate?.toDate() || null,
        serviceEndDate: messagingEndDate?.toDate() || null,
      });
    } else if (formModalityID === Modality.VIDEO) {
      if (selectedVideoCalls === null || selectedVideoCalls.length === 0) {
        setFormState({
          modalityID: Modality.VIDEO,
          videoCalls: [],
          serviceStartDate: null,
          serviceEndDate: null,
        });
      } else {
        const selectedCallIDs = selectedVideoCalls.map((it) => it.value);
        setFormState({
          modalityID: Modality.VIDEO,
        });
        setServiceDates(selectedCallIDs, {
          videoCalls: selectedVideoCalls.map((it) => {
            return { videoCallID: it.value };
          }),
        });
      }
    }

    setFormState({
      formSections: {
        ...formSections,
        sessionDateAndModality: {
          open: false,
          changed: true,
          completed,
        },
        ...(formSections.sessionInformation.completed
          ? {}
          : {
              sessionInformation: {
                ...formSections.sessionInformation,
                open: true,
              },
            }),
      },
    });

    analytics.trackProgressNoteDebugEvent({
      debugEventType: 'DatesAndModalitySectionConfirmed',
      noteID,
      roomID,
      modalityComponentStateModalityID: formModalityID,
      modalityComponentStateStartDate: messagingStartDate,
      modalityComponentStateEndDate: messagingEndDate,
      modalityComponentStateVideoCalls: selectedVideoCalls,
    });
  };

  useDidUpdateEffect(() => {
    if (currentSection === 'sessionDateAndModality') {
      setFormState({ currentSection: nextSection });
      handleConfirmPress();
    }
  }, [currentSection]);

  useEffect(() => {
    // push events for component state changes
    analytics.trackProgressNoteDebugEvent({
      debugEventType: 'modalityComponentStateChanged',
      roomID,
      noteID,
      modalityComponentStateModalityID: formModalityID,
      modalityComponentStateStartDate: messagingStartDate,
      modalityComponentStateEndDate: messagingEndDate,
      modalityComponentStateVideoCalls: selectedVideoCalls,
    });
  }, [formModalityID, messagingStartDate, messagingEndDate, selectedVideoCalls, noteID, roomID]);

  useEffect(() => {
    // push events for relevant formState changes
    analytics.trackProgressNoteDebugEvent({
      debugEventType: 'modalityFormStateChanged',
      roomID,
      noteID,
      modalityID,
      serviceStartDate,
      serviceEndDate,
      videoCalls,
    });
  }, [modalityID, serviceStartDate, serviceEndDate, videoCalls, roomID, noteID]);

  useEffect(() => {
    if (formMode === 'create' && videoCallIDParam) {
      const unassociatedCalls = helperData?.unassociatedVideoCalls?.map((call) => call.id);
      const isVideoCallIDParamInUnassociatedCalls =
        unassociatedCalls?.includes(videoCallIDParam) || false;
      setIsVideoCallIDParamValid(isVideoCallIDParamInUnassociatedCalls);
    }
  }, [formMode, helperData?.unassociatedVideoCalls, roomID, videoCallIDParam]);

  useEffect(() => {
    if (selectedVideoCalls === null && videoCalls && notesRelevantVideoCalls?.length) {
      const initialVideoCallIDs = videoCalls.map((it) => it.videoCallID);
      const videoCallIDs =
        (videoCallIDParam && initialVideoCallIDs.includes(videoCallIDParam)) || !videoCallIDParam
          ? initialVideoCallIDs
          : [videoCallIDParam];
      const selectedOnMount = videoCallIDs.length
        ? notesRelevantVideoCalls.filter((it) => videoCallIDs.includes(it?.id || 0))
        : [notesRelevantVideoCalls[0]];
      setSelectedVideoCalls(
        selectedOnMount.map((videoCall) => generateVideoCallSelectOption(videoCall))
      );
    }
    if (notesRelevantVideoCalls) {
      const selectOptions = notesRelevantVideoCalls
        .filter(Boolean)
        .map(generateVideoCallSelectOption);
      if (selectedVideoCalls?.length) {
        const startedAt = notesRelevantVideoCalls.find(
          (opt) => selectedVideoCalls[0].value === opt?.id
        )?.callStartedAt;
        const disabledOptions = notesRelevantVideoCalls.filter(
          (opt) => opt && startedAt && !isSameDate(new Date(opt.callStartedAt), new Date(startedAt))
        );
        const selectOptionsWithDisabled = selectOptions.map((opt) => {
          return {
            ...opt,
            isDisabled: disabledOptions.findIndex((dis) => dis?.id === opt.value) !== -1,
          };
        });
        setVideoCallSelectOptions(selectOptionsWithDisabled);
      } else {
        setVideoCallSelectOptions(selectOptions);
      }
    }
  }, [notesRelevantVideoCalls, selectedVideoCalls, videoCallIDParam, videoCalls]);

  useEffect(() => {
    const interval = setInterval(() => {
      setOngoingCallCheckInterval(Date.now);
    }, 60 * 1000);
    return () => clearInterval(interval);
  }, [setOngoingCallCheckInterval]);

  useEffect(() => {
    const ongoingCallSelectOption = formatOngoingCallAsOption(+roomID, ongoingVideoCall);
    if (ongoingCallSelectOption && videoCallSelectOptions?.length) {
      const foundOption = videoCallSelectOptions.find(
        (selectOption) => selectOption.label === ongoingCallSelectOption.label
      );
      if (!foundOption) {
        const videoCallSelectOptionsFixed = videoCallSelectOptions.map((selectOption) =>
          ongoingCallSelectOption && selectOption.value === ongoingCallSelectOption.value
            ? ongoingCallSelectOption
            : selectOption
        );
        setVideoCallSelectOptions(videoCallSelectOptionsFixed);
      }
    }
  }, [ongoingCallCheckInterval, roomID, ongoingVideoCall, videoCallSelectOptions]);

  const onMountPasteSessionHighlights = useCallback(() => {
    if (location?.state?.sessionHighlights) {
      setFormState({
        sessionSummary: {
          ...sessionSummary,
          summary: location.state.sessionHighlights,
        },
        modalityID: Modality.TEXT,
      });

      analytics.trackProgressNoteDebugEvent({
        debugEventType: 'modalitySectionComponentMounted',
        roomID,
        noteID,
      });
    }
  }, [location, sessionSummary, setFormState, roomID, noteID]);

  useEffect(() => {
    if (formMode === 'create') {
      const selectedVideoCallsCondition =
        selectedVideoCalls && (businessLine?.isPsychiatry || businessLine?.isVideoOnly);

      if (selectedVideoCallsCondition) {
        const selectedCallIDs = selectedVideoCalls?.map((it) => it.value);
        if (selectedCallIDs) setServiceDates(selectedCallIDs);
      }
      if (!isPrefilled) {
        const shouldPrefillIfVideo =
          modalityParam !== ProgressNoteModalityType.liveSession ||
          (modalityParam === ProgressNoteModalityType.liveSession &&
            disableLVSComponents === false);
        if (modalityParam && shouldPrefillIfVideo) {
          setFormModalityID(getModalityIDFromParam(modalityParam));
          setFormState({ modalityID: getModalityIDFromParam(modalityParam) });
          setIsPrefilled(true);
        } else if (selectedVideoCallsCondition || isVideoCallIDParamValid) {
          setFormModalityID(Modality.VIDEO);
          setFormState({ modalityID: Modality.VIDEO });
          setIsPrefilled(true);
        } else if (eapModality) {
          setFormModalityID(eapModality);
          setFormState({ modalityID: eapModality });
          setIsPrefilled(true);
        }
      }
    }
  }, [
    businessLine,
    formMode,
    setServiceDates,
    selectedVideoCalls,
    disableLVSComponents,
    isVideoCallIDParamValid,
    modalityParam,
    isPrefilled,
    setFormState,
    eapModality,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(onMountPasteSessionHighlights, []);

  useEffect(() => {
    if (!eapModality && draftOrCreateMode) {
      if (!messagingDisabled && disableLVSComponents) {
        setFormModalityID(Modality.TEXT);
        setFormState({ modalityID: Modality.TEXT });
      } else if (!disableLVSComponents) {
        setFormModalityID(Modality.VIDEO);
        setFormState({ modalityID: Modality.VIDEO });
      }
    }
  }, [disableLVSComponents, messagingDisabled, setFormState, eapModality, draftOrCreateMode]);

  const roomCreationDateMemo = useMemo(
    () => moment(helperData?.roomCreationDate || '01-01-2012').subtract(1, 'days'),
    [helperData?.roomCreationDate]
  );

  const today = useMemo(() => moment(), []);

  const checkIsDayBlocked = (day: Moment) =>
    inputFocused !== 'startDate' || !notesData
      ? false
      : notesData.items
          .filter((it) => it.roomID === Number(roomID))
          .filter((it) => it.type === 'progress')
          .some((it) => isSameDate(new Date(it.startDate), day.toDate()));

  if (helperDataIsLoading || businessLineIsLoading) {
    return <Spinner />;
  }

  if (helperDataError || businessLineError) {
    return <ErrorScreen message={helperDataError?.message || businessLineError?.message} />;
  }

  const markSectionAsIncomplete = () => {
    if (formSections.sessionDateAndModality.completed) {
      setFormState({
        formSections: {
          ...formSections,
          sessionDateAndModality: {
            open: formSections.sessionDateAndModality.open,
            changed: true,
            completed: false,
          },
        },
      });
    }
  };

  const handleTextRadioButtonPress = () => {
    if (formModalityID !== Modality.TEXT) {
      setDateAndModalityError(false);
      markSectionAsIncomplete();
      setFormModalityID(Modality.TEXT);
      setFormState({ modalityID: Modality.TEXT, sessionServiceID: null });
    }
    analytics.trackNoteFieldUpdatedEvent({
      updateType: 'textModalityRadioButtonPressed',
      roomID,
      noteID,
    });
  };

  const handleDatesChange = ({
    startDate,
    endDate,
  }: {
    startDate: Moment | null;
    endDate: Moment | null;
  }) => {
    const { items } = notesData || {};
    const clonedStartDate = startDate?.clone().startOf('day').toDate();
    const clonedEndDate = endDate?.clone().endOf('day').toDate();
    const errorMessage = validateDateInput(
      {
        startDate: clonedStartDate,
        endDate: clonedEndDate,
      },
      items,
      noteID ? +noteID : undefined,
      Number(roomID)
    );
    if (errorMessage) {
      setDateAndModalityError(true);
      setDateError(errorMessage);

      analytics.trackProgressNoteDebugEvent({
        debugEventType: 'datesAndModalityValidationFailure',
        roomID,
        noteID,
        validationNotes: items,
        validationErrorMessage: errorMessage,
        modalityComponentStateStartDate: clonedStartDate,
        modalityComponentStateEndDate: clonedEndDate,
      });
    } else {
      setDateAndModalityError(false);
      setDateError(null);
      if (startDate) {
        handleSetMessagingStartDate(startDate);
      }

      analytics.trackProgressNoteDebugEvent({
        debugEventType: 'datesAndModalityValidationSuccess',
        roomID,
        noteID,
        validationNotes: items,
        modalityComponentStateStartDate: clonedStartDate,
        modalityComponentStateEndDate: clonedEndDate,
      });
    }
    handleSetMessagingEndDate(endDate);
    markSectionAsIncomplete();
    setInputFocused(null);
    handleSetMessagingStartDate(startDate);
    handleSetMessagingEndDate(endDate);

    analytics.trackNoteFieldUpdatedEvent({
      updateType: 'formDatesChanged',
      roomID,
      noteID,
    });
  };

  const handleLVSRadioButtonPress = () => {
    if (formModalityID !== Modality.VIDEO) {
      setDateAndModalityError(false);
      markSectionAsIncomplete();
      setFormModalityID(Modality.VIDEO);
      setFormState({ modalityID: Modality.VIDEO, sessionServiceID: null });
    }
    analytics.trackNoteFieldUpdatedEvent({
      updateType: 'LVSModalityRadioButtonPressed',
      roomID,
      noteID,
    });
  };

  const handleLVSChange = (newValues: Array<VideoCallSelectOption> | null) => {
    setDateAndModalityError(false);
    markSectionAsIncomplete();
    setSelectedVideoCalls(newValues || []);

    analytics.trackNoteFieldUpdatedEvent({
      updateType: 'videoCallsListChanged',
      roomID,
      noteID,
    });
  };

  const isMessagingDisabled = !draftOrCreateMode || disabled || messagingDisabled;

  const { dateRangeContainerStyles, dateRangeInputStyles, inputLabelStyle } = getDateRangeStyles(
    colors,
    !!dateError,
    isMessagingDisabled
  );

  return (
    <View>
      <View>
        <View row style={{ marginBottom: 10 }}>
          <StyledRadioButton
            isActive={formModalityID === Modality.VIDEO}
            handlePress={handleLVSRadioButtonPress}
            title="Live session"
            disabled={!draftOrCreateMode}
          />

          <StyledRadioButton
            isActive={formModalityID === Modality.TEXT}
            handlePress={handleTextRadioButtonPress}
            title="Messaging"
            disabled={!draftOrCreateMode}
          />
        </View>

        {formModalityID === Modality.TEXT && (
          <>
            <View row align="center" style={{ marginBottom: 11 }}>
              {messagingDisabled && draftOrCreateMode ? (
                <MissingSessionSection
                  toolTipText="No messaging session occurred that are missing a progress note."
                  containerText="No messaging sessions were found"
                />
              ) : (
                <>
                  <Small
                    variant="smallTSBlack"
                    style={{
                      marginRight: 6,
                      marginTop: (dateError || dateAndModalityError) && -35,
                    }}
                  >
                    From:
                  </Small>
                  <View flex={1}>
                    <DateRangePickerWithInput
                      calendarDayWidth={48}
                      disabled={isMessagingDisabled}
                      minimumNights={6}
                      startDate={messagingStartDate}
                      endDate={messagingEndDate}
                      focusedInput={inputFocused}
                      onDatesChange={handleDatesChange}
                      onFocusChange={(newInputFocused) => {
                        setInputFocused(newInputFocused);
                      }}
                      startDatePlaceholderText="Select start date"
                      endDatePlaceholderText="Select end date"
                      startDateId="messagingStartDate"
                      endDateId="messagingEndDate"
                      isSingle={false}
                      isCentered
                      minDate={roomCreationDateMemo}
                      maxDate={inputFocused === 'startDate' ? today : undefined}
                      isDayBlocked={checkIsDayBlocked}
                      noBorder
                      shouldRemoveArrow
                      inputStyles={dateRangeInputStyles}
                      focusStyle={{ border: `1px solid ${colors.permaCometGray}` }}
                      containerStyle={dateRangeContainerStyles}
                      disabledStyle={{ background: 'transparent' }}
                      primaryColor={colors.accessibilityGreenDark}
                      secondaryColor={colors.aquaSqueeze}
                      inputLabelStyle={inputLabelStyle}
                      arrowStyle={{ top: '38px !important' }}
                    />
                    {dateError && (
                      <ExtraTiny style={{ marginBottom: 4 }} variant="extraTinyError">
                        {dateError}
                      </ExtraTiny>
                    )}
                    {dateAndModalityError && (
                      <ExtraTiny variant="extraTinyError" data-testid="messagingError">
                        {dateAndModalityError}
                      </ExtraTiny>
                    )}
                  </View>
                </>
              )}
            </View>
          </>
        )}

        {formModalityID === Modality.VIDEO && (
          <View style={{ marginBottom: 11 }}>
            {disableLVSComponents ? (
              <MissingSessionSection
                toolTipText="No live sessions occurred that are missing a progress note."
                containerText="No live sessions were found"
              />
            ) : (
              <PlusMultiSelect
                width={260}
                placeholder="Session date and time"
                isDisabled={disableLVSComponents || !draftOrCreateMode || disabled}
                options={videoCallSelectOptions as OptionsType<any>}
                value={
                  videoCallSelectOptions.filter((opt) =>
                    selectedVideoCalls?.map((vc) => vc.value).includes(opt.value)
                  ) as ValueType<OptionType<any>, boolean>
                }
                onChange={handleLVSChange as (value: ValueType<OptionType, boolean>) => void}
                isMulti
                isError={!disableLVSComponents && !selectedVideoCalls?.length}
              />
            )}

            {!disableLVSComponents && !selectedVideoCalls?.length && (
              <ExtraTiny variant="extraTinyError">Required</ExtraTiny>
            )}
          </View>
        )}
      </View>
    </View>
  );
};

export default SessionDateAndModalitySection;
