import * as React from 'react';
import { startCase } from 'lodash';
import styled from '../../core/styled';
import View from '../View';
import Tiny from '../Typography/Tiny';

const Brick = styled(View)<{ borderColor: string }>(({ theme: { colors }, borderColor }) => {
  return {
    backgroundColor: colors.white,
    borderRadius: 7,
    borderColor: borderColor || colors.green,
    borderStyle: 'solid',
    fontSize: 13,
    borderWidth: 1,
    padding: 7,
    display: 'inline',
    width: 'fit-content',
    margin: 3,
  };
});

const BricksWrapper = styled(View)({
  flexFlow: 'wrap',
  width: '100%',
});

interface BrickData {
  name: string;
  frequency: number;
}
interface Props {
  bricks: Array<string | BrickData>;
  brickColor: string;
}

const BricksField: React.VFC<Props> = ({ bricks = [], brickColor }) =>
  Array.isArray(bricks) && bricks.length ? (
    <BricksWrapper>
      {bricks.map((brick: string | BrickData) =>
        typeof brick === 'string' ? (
          <Brick borderColor={brickColor} key={brick}>
            <Tiny variant="tinyBlack">{startCase(brick)}</Tiny>
          </Brick>
        ) : (
          <Brick borderColor={brickColor} key={brick.name}>
            <Tiny variant="tinyBlack" inline>
              {startCase(brick.name)}
            </Tiny>
            <Tiny variant="tinyBlack" inline>
              {' '}
              ({brick.frequency})
            </Tiny>
          </Brick>
        )
      )}
    </BricksWrapper>
  ) : null;

export default BricksField;
