interface Size {
  width: number;
  height: number;
}

const getResponsiveRatioStyles = (mediaSize: Size, currentSize: Size, defaultMaxSize = '100%') => {
  if (currentSize.width < mediaSize.width || currentSize.height < mediaSize.height) {
    const ratioWidth = (mediaSize.width / mediaSize.height) * currentSize.height;
    const ratioHeight = (mediaSize.height / mediaSize.width) * currentSize.width;
    if (ratioHeight > currentSize.height) {
      return {
        height: defaultMaxSize,
        width: ratioWidth, // Maintain aspect ratio
      };
    }
    return {
      width: defaultMaxSize,
      height: ratioHeight, // Maintain aspect ratio
    };
  }
  return {};
};

export default getResponsiveRatioStyles;
