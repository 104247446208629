import { FunctionComponent } from 'react';
import View from '../View';
import { Tiny, Large } from '../Typography';
import styled, { EmotionStyle } from '../../core/styled';

export interface DetailsRowProps {
  title?: string;
  text?: string;
  textItems?: string[];
  style?: EmotionStyle;
  shouldRenderTextItemsInline?: boolean;
  renderLeft?: () => JSX.Element;
  isCollapsed?: boolean;
  renderLeftContainerStyle?: EmotionStyle;
}

const Wrapper = styled(View)({
  marginLeft: 15,
  marginRight: 20,
  marginBottom: 8,
  marginTop: 11,
  alignSelf: 'stretch',
});

const TextItems: FunctionComponent<{
  textItems: string[];
  title?: string;
  shouldRenderTextItemsInline?: boolean;
  isCollapsed?: boolean;
}> = ({ textItems, shouldRenderTextItemsInline, title, isCollapsed }) =>
  shouldRenderTextItemsInline ? (
    <Large style={{ textAlign: 'left' }}>
      {isCollapsed ? textItems.slice(0, 2).join(', ') : textItems.join(', ')}
    </Large>
  ) : (
    <View>
      {textItems.map((t, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Large key={`t ${i}`} style={{ marginBottom: i === 0 && !title ? 3 : 0 }}>
          {t}
        </Large>
      ))}
    </View>
  );

const DetailsRow: FunctionComponent<DetailsRowProps> = ({
  title,
  text,
  textItems,
  renderLeft,
  isCollapsed,
  shouldRenderTextItemsInline = false,
  renderLeftContainerStyle = {},
  ...otherProps
}) => {
  if (textItems && textItems.length === 0) return null;
  if (!text && !textItems) return null;
  return (
    <Wrapper {...otherProps}>
      <View row>
        {renderLeft && (
          <View style={{ marginTop: 3, minWidth: 38, ...renderLeftContainerStyle }}>
            {renderLeft()}
          </View>
        )}
        <View flex={1}>
          {title && <Tiny variant="tinyAllCaps">{title}</Tiny>}
          {text ? (
            <Large style={{ textAlign: 'left' }}>{text}</Large>
          ) : (
            textItems && (
              <TextItems
                title={title}
                textItems={textItems}
                shouldRenderTextItemsInline={shouldRenderTextItemsInline}
                isCollapsed={isCollapsed}
              />
            )
          )}
        </View>
      </View>
    </Wrapper>
  );
};

export default DetailsRow;
