import TouchableView from '../../../components/TouchableView';
import TextDS from '../typography/TextDS';
import { spacing } from '../../tokens';
import { EmotionStyle, useEmotionTheme } from '../../..';

const { space150 } = spacing;

export interface TabProps {
  label: string;
  onPress: () => void;
  isActive: boolean;
  variant: 'hug' | 'fill';
  dataQa: string;
  style?: EmotionStyle;
}
const Tab = ({ label, onPress, dataQa, isActive, variant, style }: TabProps) => {
  const { colorRoles } = useEmotionTheme();
  const activeBorder = `2px solid ${colorRoles.borders.borderInteractiveSelectedBold}`;
  const defaultBorder =
    variant === 'hug' ? undefined : `1px solid ${colorRoles.borders.borderInteractiveDefault}`;

  return (
    <TouchableView
      onPress={onPress}
      dataQa={dataQa}
      style={{
        padding: `${space150}px 0`,
        borderBottom: isActive ? activeBorder : defaultBorder,
        ...style,
      }}
      align="center"
    >
      <TextDS
        variant={isActive ? 'headingMd' : 'body'}
        colorRole={isActive ? 'textInteractiveDefault' : 'textSubtlest'}
      >
        {label}
      </TextDS>
    </TouchableView>
  );
};

export default Tab;
