/**
 * This function triggers browser navigate and return a promise that never resolves
 * to ensure no unsafe code is being executed
 * @param  {string} url
 * @returns Promise
 */
const routePromise = async (url: string): Promise<void> => {
  window.location.href = url;
  return new Promise(() => {});
};

// eslint-disable-next-line import/prefer-default-export
export { routePromise };
