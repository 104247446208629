import { Component } from 'react';
import './OutcomeMeasures.css';
import moment from 'moment-timezone';
import { withRouter } from 'react-router';
import ItemList from '../../../../Reusable/ItemList/ItemList';
import ListItem from '../../../../Reusable/ListItems/ListItem';
import Submenu from '../../../../Reusable/Submenu/Submenu';
import UserActionCard from '../../../../Reusable/UserActionCard/UserActionCard';

class OutcomeMeasureDetails extends Component {
  getOutcomeMeasureButtons() {
    const { history, match } = this.props;
    const outcomeMeasure = this.props.location.state && this.props.location.state.outcomeMeasure;
    return [
      {
        isSecondary: false,
        title: 'Read Assessment Answers',
        clickHandler: () => {
          history.push(`${match.url}-answers`, { outcomeMeasure });
        },
      },
    ];
  }

  getUserActionCardContent() {
    const outcomeMeasure = this.props.location.state && this.props.location.state.outcomeMeasure;
    return (
      <UserActionCard
        header={() => <p>{outcomeMeasure.label}</p>}
        content={this.renderArrayOfConent()}
        buttons={this.getOutcomeMeasureButtons()}
      />
    );
  }

  processItems(scales) {
    return scales.map((item, key) => {
      const { label: scale, score, maximum, scoreLabel: label } = item;
      const itemDisplayData = {
        scale,
        score: `${score}/${maximum}`,
        label,
      };
      return (
        <ListItem
          key={key}
          itemDataOrder={['scale', 'score', 'label']}
          itemDisplayData={itemDisplayData}
          item={item}
          status={label}
          widths={[140, 70, 90]}
        />
      );
    });
  }

  renderArrayOfConent() {
    const outcomeMeasure = this.props.location.state && this.props.location.state.outcomeMeasure;
    return [
      <ItemList
        items={this.processItems(outcomeMeasure.scales)}
        titles={['Scale', 'Score', 'Label']}
        noDataMessage="No scale information for this survey."
        widths={[140, 70, 90]}
        key={1}
      />,
    ];
  }

  render() {
    const outcomeMeasure = this.props.location.state && this.props.location.state.outcomeMeasure;
    const title = `${outcomeMeasure.label} - ${moment(outcomeMeasure.completedAt).format('LL')}`;
    return (
      <Submenu
        childComponents={[this.getUserActionCardContent()]}
        title={title || 'Outcome measures'}
        prevRoute="clinical-tab"
      />
    );
  }
}

export default withRouter(OutcomeMeasureDetails);
