import { FunctionComponent } from 'react';
import { View, useEmotionTheme } from '@talkspace/react-toolkit';
import { MessageProps } from '../../types';
import Body from '../Body';
import BodyWithClipboardCopy from '../BodyWithClipboardCopy';
import Name from '../Name';
import BodyWithPhoneIcon from '../BodyWithPhoneIcon';
import LiveSessionStateMessage from '../LiveSessionStateMessage';

const SystemMessage: FunctionComponent<MessageProps> = (props) => {
  const { colors } = useEmotionTheme();
  const { currentMessage } = props;
  let BodyComponent: FunctionComponent<MessageProps & { hasMargin: boolean }> | null;
  switch (currentMessage.bodyType) {
    case 'HAS_CLIPBOARD_COPY': {
      BodyComponent = BodyWithClipboardCopy;
      break;
    }
    case 'LVS': {
      BodyComponent = BodyWithPhoneIcon;
      break;
    }
    case 'LIVE_SESSION_STATE': {
      BodyComponent = LiveSessionStateMessage;
      break;
    }
    default: {
      BodyComponent = null;
    }
  }
  return (
    <View align="center">
      {currentMessage.messageType !== 46 && (
        <Name
          user={currentMessage.user}
          style={{
            fontSize: 13,
            color: colors.placeholderGrey,
            marginTop: 6,
            position: 'relative',
            textAlign: 'center',
          }}
        />
      )}
      <View style={{ maxWidth: 580 }}>
        {BodyComponent ? <BodyComponent {...props} hasMargin /> : <Body {...props} hasMargin />}
      </View>
    </View>
  );
};

export default SystemMessage;
