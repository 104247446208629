import { useState, useEffect } from 'react';

import { getTherapistProfile } from '../utils/apiHelper';
import { License, ClinicalInfoData } from '../types';

const emptyLicense: License = {
  country: 'US',
  licenseTypeID: '',
  number: '',
  state: '',
};

const emptyState: ClinicalInfoData = {
  phoneNumber: '',
  phoneNumberCountryCode: 'US',
  npiNumber: '',
  professionalDegree: '',
  institutionAssociatedProfessionalDegree: '',
  professionalLevel: '',
  licenses: [emptyLicense],
  caqhNumber: '',
  accessInitiativeParticipation: null,
};

const sanitizePhoneNumber = (phone: string) =>
  ['(', ')', ' ', '-'].reduce((string, character) => string.replaceAll(character, ''), phone);

const useClinicalInfo = (token: string) => {
  const [data, setData] = useState<ClinicalInfoData>(emptyState);
  const [error, setError] = useState<Error>();
  useEffect(() => {
    getTherapistProfile(token)
      .then((res) => {
        const {
          phoneNumber,
          phoneNumberCountryCode,
          professionalDegree,
          institutionAssociatedProfessionalDegree,
          professionalLevel,
          licenses,
        } = res;
        let { npiNumber, caqhNumber } = res;
        // If no licenses returned, add empty license stub to licenses state
        if (licenses.length === 0) {
          licenses.push(emptyLicense);
        }

        // NOTE: following is a workaround for use case when during therapist creation
        // there is a "fake" npi/caqh number automatically inserted (e.g. "0000000001"/"00000001").
        // For such cases, we will not pre-fill npi and/or caqh number and user will be able to send empty string to backend
        // wiping the "fake" value from the database. For details see NYC-6872

        // If NPI number returned is less than 10 digits, do not pre-fill
        if (npiNumber.length < 10) {
          npiNumber = '';
        }
        // If CAQH number returned is less than 8 digits, do not pre-fill
        if (caqhNumber.length < 8) {
          caqhNumber = '';
        }
        setData({
          // NOTE: 5% of therapist phones on prod were saved in a format other than just 10 numbers
          phoneNumber: sanitizePhoneNumber(phoneNumber),
          phoneNumberCountryCode,
          professionalDegree,
          institutionAssociatedProfessionalDegree,
          professionalLevel,
          licenses,
          npiNumber,
          caqhNumber,
          accessInitiativeParticipation: null,
        });
      })
      .catch((err) => setError(err));
  }, [token]);
  return { data, error };
};

export default useClinicalInfo;
