import { useState, useEffect, useRef, useCallback, ChangeEvent, useMemo } from 'react';
import * as React from 'react';
import { connect } from 'react-redux';
import {
  View,
  useUniqueID,
  FloatingMenu,
  spacing,
  useEmotionTheme,
  EmotionThemeProvider,
} from '@talkspace/react-toolkit';
import { keyframes } from '@emotion/core';
import { RoomStatus } from 'ts-frontend/entities/Room';
import { roomHasRealProvider } from 'ts-frontend/helpers';
import { isDummyCTReactivationProvider } from 'ts-frontend/entities/Therapist';
import useIsClientDischargeV3OnForPlan from 'ts-frontend/hooks/useIsClientDischargeV3OnForPlan';
import { getIonicStorage, setIonicStorage } from 'ts-ionic/plugins/secureStorage';
import { getIsIonic } from 'ts-ionic';
import {
  Bars,
  CirclePlus,
  CircleStop,
  Microphone,
  Tag,
} from '@talkspace/react-toolkit/src/designSystems/icons';
import { preventNextSplashScreenShow } from 'ts-ionic/plugins/splashScreen';
import { ID_CHAT_INPUT } from '@/utils/IDConstants';
import styled from '@/core/styled';
import { withRouter, RouteComponentProps } from '@/core/routerLib';
import RoomActivationSocketService from '../redux/helpers/RoomActivationSocketService';
import ClientDischargeSocketService from '../redux/helpers/ClientDischargeSocketService';
import { AppState } from '../redux/chatStore';
import InputComposer from '../components/InputComposer/InputComposer';
import {
  dispatchSendMessage,
  dispatchSendMediaMessage,
  dispatchCreateVideoCall,
} from '../redux/actions/inputActions';
import { dispatchRequestPostOffer } from '../redux/actions/offersActions';
import InputSend from '../components/InputSend';
import InputIcon from '../components/InputIcon';
import { InputState, PostMediaMessagePayload } from '../redux/constants/inputTypes';
import {
  dispatchGetActiveSessionInfo,
  dispatchGetRoomInfo,
  dispatchPostActiveSessionInfo,
} from '../redux/actions/chatActions';
import { MIN_COMPOSER_HEIGHT, MIN_COMPOSER_HEIGHT_V2 } from '../constants';
import UserTypingSocketService from '../redux/helpers/UserTypingSocketService';
import InputAudio, { InputAudioRef } from '../components/InputAudio';
import InputAudioIonic from '../components/InputAudio/InputAudioIonic';
import ScriptsContainer from './ScriptsContainer';
import OffersContainer from './OffersContainer';
import RecorderTimer from '../components/RecorderTimer';
import InputCharWarning from '../components/InputCharWarning';
import WordCount from '../components/WordCount';
import AddMediaMenu from '../components/AddMediaMenu';
import InputFile from '../components/InputFile';
import { RoomInfo } from '../entities/RoomInfo';
import { useSharedChatActions, useSharedChatState } from '../hooks/sharedChatContext';
import { useChatMessageActions, useChatMessageState } from '../hooks/chatMessageContext';
import CreateVideoCallErrorModal from '../components/CreateVideoCallErrorModal';
import ErrorModal from '../components/ErrorModal';
import useWindowWidth from '../hooks/useWindowWidth';
import { EActiveSession } from '../entities/ActiveSession';
import { useVideoCallActions, useVideoCallState } from '../hooks/videoCallContext';
import { useQueryVideoCreditOffers } from '../../inRoomScheduling';
import { AudioFile, HandleShareFile, MediaType, MediaTypeField } from '../types/inputTypes';
import ResponsiveMenu from '../components/ResponsiveMenu/ResponsiveMenu';
import FileUploader from '../components/FileUploader';

const MAX_CHAR_LENGTH = 7000;
const SHOW_CHAR_LENGTH = 100;
const DANGER_CHAR_LENGTH = 20;
const MAX_RECORDING_LENGTH = 5 * 60; // 5 minutes
const DRAFTED_MESSAGES = 'drafted_messages';
// This was disabled as the popup gives the user info about what file types we accept.
// Once we accept more file types, this can be turned back on or removed altogether.
const AUTO_OPEN_FILE_UPLOAD = false;

interface InputContainerProps extends Partial<InputState> {
  sendMessage(roomID: number, message: string): Promise<void>;
  sendMediaMessage(roomID: number, data: PostMediaMessagePayload): Promise<void>;
  roomID: number;
  isTherapistChat: boolean;
  roomInfo: RoomInfo;
  hasNoCredits: boolean;
  isCallInProgress: boolean;
  stopInputAutofocus?: boolean;
  getRoomInfo: (roomID: number) => Promise<void>;
  createVideoCall: (
    roomID: number,
    creditMinutes: number,
    type: string,
    modality: string
  ) => Promise<any>;
  postOffer(roomID: number, offerId: number, planId: number): void;
  activeSession: EActiveSession | null;
  getActiveSessionInfo(roomID: number, modality?: string): void;
  postActiveSession(roomID: number): void;
  isLoadingActiveSessionInfo?: boolean;
  isFocusedAfterModal: boolean;
  videoCallErrorMessage?: string;
  shouldMatchNewProvider: boolean;
  isOffline?: boolean;
}

const mobileSlideUpAnimation = keyframes({
  '0%': {
    maxHeight: 78,
  },
  '100%': {
    maxHeight: 345,
  },
});

const mobileSlideDownAnimation = keyframes({
  '0%': {
    marginTop: 20,
  },
  '100%': {
    marginTop: 0,
  },
});

const InputWrapper = styled(View)<{ isCompactInput?: boolean }>(
  ({
    isCompactInput,
    theme: {
      colors,
      window: { isMobile },
    },
  }) => {
    return {
      paddingTop: isCompactInput ? 16 : 0,
      paddingBottom: isCompactInput ? 16 : 0,
      paddingLeft: isCompactInput ? 16 : 15,
      paddingRight: isCompactInput ? 16 : 15,
      flexDirection: 'column',
      position: 'relative',
      minHeight: MIN_COMPOSER_HEIGHT,
      backgroundColor: colors.white,
      borderTop: `1px solid ${colors.extraLightGrey}`,
      borderRadius: isMobile ? `16px 16px 0px 0px` : 0,
    };
  }
);

const InputAnimWrapper = styled(View)<{ isMobileInput: boolean; isCompactInput: boolean }>(
  ({ isCompactInput, isMobileInput }) => {
    return {
      width: '100%',
      minHeight: 'inherit',
      maxHeight: 345,
      alignItems: 'center',
      gap: isCompactInput ? spacing.space150 : spacing.space200,
      paddingBottom: isMobileInput ? spacing.space100 : 0,
      flexWrap: isMobileInput ? 'wrap' : 'nowrap',
      animation: isMobileInput ? `none` : `0.25s ${mobileSlideDownAnimation}`,
    };
  }
);

const InputComposerAnimWrapper = styled(View)<{ isMobileInput: boolean; hide?: boolean }>(
  ({ isMobileInput, hide }) => {
    return {
      flex: 1,
      maxHeight: 345,
      flexWrap: 'wrap',
      gap: isMobileInput ? 16 : 0,
      order: isMobileInput ? -1 : 0,
      flexBasis: isMobileInput ? '100%' : 0,
      animation: isMobileInput ? `0.25s ${mobileSlideUpAnimation}` : 'none',
      display: hide ? 'none' : 'flex',
    };
  }
);

const StyledWordCount = styled(WordCount)({
  marginLeft: 10,
  marginRight: 10,
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
});

let userTypingSocket: UserTypingSocketService;

interface getInputPlaceholderProps {
  roomStatus: RoomStatus | undefined;
  therapistFirstName: string | undefined;
  hasCTProvider?: boolean | undefined;
  isChatDisabled: boolean | undefined;
  isTherapistChat: boolean | undefined;
}

const getInputPlaceholder = ({
  roomStatus,
  therapistFirstName,
  hasCTProvider,
  isChatDisabled,
  isTherapistChat,
}: getInputPlaceholderProps): string | undefined => {
  if (isChatDisabled) {
    return isTherapistChat ? 'Chat disabled' : 'Restart therapy to chat...';
  }
  if (hasCTProvider) {
    return 'Resubscribe to continue';
  }
  if (
    !roomHasRealProvider({
      room: { status: roomStatus },
      ...(therapistFirstName && { provider: { firstName: therapistFirstName } }),
    })
  ) {
    return 'Waiting for provider to join...';
  }
  if (therapistFirstName) {
    return `Message ${therapistFirstName}...`;
  }
  return undefined;
};

const getNumberOfLines = (hasMedia: boolean) => {
  if (getIsIonic()) return hasMedia ? 3 : 5;
  return hasMedia ? 5 : 10;
};

const InputContainer: React.FunctionComponent<InputContainerProps & RouteComponentProps> = (
  props
) => {
  const {
    roomID,
    isError,
    roomInfo,
    isUpdating,
    sendMessage,
    getRoomInfo,
    stopInputAutofocus,
    sendMediaMessage,
    isTherapistChat,
    createVideoCall,
    isVideoError,
    postOffer,
    videoErrorRoom,
    hasNoCredits,
    isCallInProgress,
    activeSession,
    isLoadingActiveSessionInfo,
    getActiveSessionInfo,
    postActiveSession,
    history,
    location,
    isFocusedAfterModal,
    videoCallErrorMessage,
    shouldMatchNewProvider,
    isOffline,
  } = props;

  const [isInputAutoFocused, setIsInputAutoFocused] = useState(false);
  const [isInputSendFocused, setIsInputSendFocused] = useState(false);
  const [isSendClicked, setIsSendClicked] = useState(false);
  const [message, updateMessage] = useState('');
  const messageRef = useRef<string>('');
  const [isCTProvider, setIsCTProvider] = useState(false);
  const [recording, updateRecording] = useState(false);
  const [showScriptsMenu, setShowScriptsMenu] = useState(false);
  const [showOffersMenu, setShowOffersMenu] = useState(false);
  const [showCreateVideoCallOfferMenu, setShowCreateVideoCallOfferMenu] = useState(false);
  const [didSendIntroCall, setDidSendIntroCall] = useState(false);
  const [doesCallHaveOffer, setDoesCallHaveOffer] = useState(true);
  const [shouldShowModalityCallOptions, setShouldShowModalityCallOptions] = useState(false);
  const [modality, setModality] = useState('');
  const [recordingTime, setRecordingTime] = useState(0);
  const [showAddMediaMenu, setShowAddMediaMenu] = useState(false);
  const { updateState, setHideBanners } = useSharedChatActions();
  const { isDesktop } = useWindowWidth();
  const [videoCallOfferType, setVideoCallOfferType] = useState('');
  const [videoCallOfferMinutes, setVideoCallOfferMinutes] = useState(0);
  const {
    roomStatus,
    therapistFirstName,
    therapistType,
    isLiveChatBannerOpen,
    isMobileApp,
    adminConfigs: { isPDFUploadEnabled },
  } = useSharedChatState();
  const { prefilledMessage } = useChatMessageState();
  const { clearPrefilledChatMessage } = useChatMessageActions();
  const { isMinimized } = useVideoCallState();
  const { toggleMinimizedAction } = useVideoCallActions();
  const { isMobile } = useWindowWidth();
  const { themeVersion } = useEmotionTheme();

  const fileUploaderRef = useRef<HTMLInputElement>(null);

  const useBottomSheet = isMobile && !isTherapistChat;
  const hideRecordVoiceMessage = !isTherapistChat;
  const showPhotoVideoUpload = isMobileApp;

  const {
    error: videoCreditOffersError,
    isLoading: isVideoCreditOffersLoading,
    data: videoCreditOffers,
  } = useQueryVideoCreditOffers(
    { roomID, source: isTherapistChat ? 'providerOpenChat' : 'memberOpenChat' },
    { retry: false, enabled: isTherapistChat }
  );

  const charactersRemaining = MAX_CHAR_LENGTH - message.length;
  const exceededLimit = charactersRemaining < 0;
  const showCharactersRemaining = charactersRemaining <= SHOW_CHAR_LENGTH;
  const dangerZone = charactersRemaining <= DANGER_CHAR_LENGTH;

  const [media, updateMedia] = useState<MediaType>({});
  const [validationError, setValidationError] = useState('');
  const inputRef = useRef<InputAudioRef>();
  const messageTextAreaRef = useRef<HTMLTextAreaElement>(null);
  const isClientDischargeV3OnForPlan = useIsClientDischargeV3OnForPlan(roomID);

  const isChatDisabled = isClientDischargeV3OnForPlan && roomInfo.isChatDisabled;

  const placeholder = getInputPlaceholder({
    roomStatus,
    therapistFirstName,
    hasCTProvider: isCTProvider,
    isChatDisabled,
    isTherapistChat,
  });
  const inputPlaceholder = recording ? 'Recording...' : placeholder;

  const hasProvider = roomHasRealProvider({
    ...(roomInfo.roomStatus && { room: { status: roomInfo.roomStatus } }),
    provider: { firstName: therapistFirstName || '' },
  });

  const onChangeText = useCallback(
    (text: string) => {
      if (isLiveChatBannerOpen && activeSession?.callStartedAt) userTypingSocket.sendTypingEvent();
      updateMessage(text);
    },
    [isLiveChatBannerOpen, activeSession, updateMessage]
  );

  useEffect(() => {
    const roomActivationSocketService = new RoomActivationSocketService(roomID, getRoomInfo);
    const clientDischargeSocketService = new ClientDischargeSocketService(roomID, getRoomInfo);

    return () => {
      roomActivationSocketService.unmount();
      clientDischargeSocketService.unmount();
    };
  }, [getRoomInfo, roomID]);

  useEffect(() => {
    updateState({
      roomExpirationDate: roomInfo.expirationDate,
      therapistID: roomInfo.therapistUserID,
    });
  }, [roomInfo.expirationDate, roomInfo.therapistUserID, updateState]);

  useEffect(() => {
    if (therapistFirstName) {
      setIsCTProvider(
        isDummyCTReactivationProvider({
          therapistName: therapistFirstName,
        })
      );
    }
  }, [therapistFirstName]);

  useEffect(() => {
    if (
      isVideoError &&
      (hasNoCredits || isCallInProgress || videoCallErrorMessage) &&
      roomID === videoErrorRoom
    )
      setShowCreateVideoCallOfferMenu(true);
  }, [hasNoCredits, isCallInProgress, isVideoError, roomID, videoErrorRoom, videoCallErrorMessage]);

  useEffect(() => {
    getRoomInfo(roomID);
    userTypingSocket = new UserTypingSocketService(roomID);
    return () => {
      userTypingSocket.unmount();
    };
  }, [roomID, getRoomInfo]);

  const getDraftedMessages = useCallback(async () => {
    const draftedMessages = await getIonicStorage(DRAFTED_MESSAGES, `${roomID}`);
    if (draftedMessages && draftedMessages.value) {
      return JSON.parse(draftedMessages.value);
    }
    return {};
  }, [roomID]);

  useEffect(() => {
    // TODO: @Luis - CAREFUL!!! Any other action in inputActions that updates the isUpdating or isError will MOST LIKELY clear the message/media because of this
    // To fix that, either make a new isUpdating variable on redux specifically for sending messages or do something in the component level that replaces this useEffect
    if (!isUpdating && !isError) {
      updateMedia({});
      updateMessage('');
    }
  }, [isUpdating, isError, roomID]);

  useEffect(() => {
    messageRef.current = message;
  }, [message]);

  useEffect(() => {
    const handleDraftMessageOnUnmount = async () => {
      if (roomID) {
        const draftedMessages = await getDraftedMessages();
        if (messageRef.current) {
          draftedMessages[roomID] = messageRef.current;
        } else {
          delete draftedMessages[roomID];
        }
        setIonicStorage({
          key: DRAFTED_MESSAGES,
          value: JSON.stringify(draftedMessages),
          keySuffix: `${roomID}`,
        });
      }
    };

    const handleUpdateMessageOnMount = async () => {
      const draftedMessages = await getDraftedMessages();
      if (draftedMessages && draftedMessages[roomID]) {
        updateMessage(draftedMessages[roomID]);
      }
    };

    isMobileApp && handleUpdateMessageOnMount();
    return () => {
      isMobileApp && handleDraftMessageOnUnmount();
    };
  }, [roomID, getDraftedMessages, isMobileApp]);

  // pre-fill chat message
  useEffect(() => {
    if (prefilledMessage) {
      updateMessage(prefilledMessage);
      clearPrefilledChatMessage();
      // hack for focusing the chat text area after a message is pre-filled
      // no need for clearTimeout since this hook is intended to run once and will, itself, make the conditional on which it hinges falsy
      if (!isMobile) {
        setTimeout(() => {
          messageTextAreaRef?.current?.focus();
        }, 1000);
      }
    }
  }, [prefilledMessage, onChangeText, clearPrefilledChatMessage, messageTextAreaRef, isMobile]);

  useEffect(() => {
    if (!isMobile) {
      return () => {};
    }
    const hasMedia = !!media.type;
    setHideBanners(hasMedia);
    const onFocus = () => {
      setHideBanners(true);
    };
    const onBlur = () => {
      setHideBanners(hasMedia);
    };
    const innerRef = messageTextAreaRef.current;
    innerRef?.addEventListener('focus', onFocus);
    innerRef?.addEventListener('blur', onBlur);

    return () => {
      innerRef?.removeEventListener('focus', onFocus);
      innerRef?.removeEventListener('blur', onBlur);
    };
  }, [media, setHideBanners, isMobile]);

  useEffect(() => {
    if (!stopInputAutofocus && messageTextAreaRef.current && !isMobile) {
      setIsInputAutoFocused(true);
      messageTextAreaRef.current.focus();
    } else {
      setIsInputAutoFocused(false);
      if (stopInputAutofocus && messageTextAreaRef.current) messageTextAreaRef.current.blur();
    }
  }, [stopInputAutofocus, isMobile]);

  const onSubmit = (mediaParam?: MediaType) => {
    if (!isUpdating && !exceededLimit) {
      const finalMedia = Object.keys(media).length === 0 && mediaParam?.type ? mediaParam : media;
      if (finalMedia.type) {
        let payload: PostMediaMessagePayload;
        switch (finalMedia.type) {
          case 'audio':
            payload = {
              blob: finalMedia.blob,
              type: 'audio' as MediaTypeField,
              name: finalMedia.fileName,
              size: finalMedia.blob.size,
              durationSeconds: finalMedia.duration || 5,
            };
            break;
          case 'video':
            payload = {
              blob: finalMedia.blob,
              type: 'video' as MediaTypeField,
              name: finalMedia.fileName,
              size: finalMedia.blob.size,
              durationSeconds: finalMedia.duration || 5,
            };
            break;
          case 'photo':
            payload = {
              blob: finalMedia.blob,
              type: 'photo' as MediaTypeField,
              name: finalMedia.fileName,
              size: finalMedia.blob.size,
            };
            break;
          case 'pdf':
            payload = {
              blob: finalMedia.blob,
              type: 'pdf' as MediaTypeField,
              name: finalMedia.fileName,
              size: finalMedia.blob.size,
            };
            break;
          default:
            return;
        }
        if (message.trim() !== '') {
          sendMessage(roomID, message).then(() => {
            sendMediaMessage(roomID, payload);
          });
        } else {
          sendMediaMessage(roomID, payload);
        }
      } else if (message.trim() !== '') {
        sendMessage(roomID, message);
        messageTextAreaRef.current?.focus();
        setIsInputSendFocused(true);
      }
    }
  };

  const onCancelRecording = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.stopRecording().then(() => {
        updateRecording(false);
        updateMedia({});
      });
    }
  }, []);

  useEffect(() => {
    if (location.pathname.includes('/modal/video-call')) {
      onCancelRecording();
    }
  }, [onCancelRecording, location.pathname]);

  const onFinishRecording = useCallback((audio: AudioFile) => {
    updateRecording(false);
    updateMedia(audio);
  }, []);

  const toggleRecording = () => {
    updateMedia({});
    if (!recording) {
      if (inputRef.current) {
        inputRef.current.startRecording();
        updateRecording(true);
      }
      return;
    }
    if (inputRef.current) {
      inputRef.current.stopRecording().then(() => updateRecording(false));
    }
  };

  const toggleShowOffer = () => setShowOffersMenu(!showOffersMenu);
  const toggleShowScript = () => setShowScriptsMenu(!showScriptsMenu);
  const toggleShowModalityTypeOptions = (type: string) => {
    setModality(type);
    setShouldShowModalityCallOptions(!shouldShowModalityCallOptions);
  };
  const toggleShowModalityOptions = () => {
    setShouldShowModalityCallOptions(!shouldShowModalityCallOptions);
  };
  const toggleShowAddMedia = () => {
    getActiveSessionInfo(roomID);
    if (!hasProvider) return;
    if (showAddMediaMenu && shouldShowModalityCallOptions) toggleShowModalityOptions();

    setShowAddMediaMenu(!showAddMediaMenu);
  };
  const toggleShowVideoCallOffersMenu = () =>
    setShowCreateVideoCallOfferMenu(!showCreateVideoCallOfferMenu);

  const onUseScript = (text: string) => {
    onChangeText(text);
    toggleShowScript();
  };

  const onUseOffer = () => {
    toggleShowOffer();
  };

  const handleShareFile: HandleShareFile = ({ file, name, type, localSrc, durationSeconds }) => {
    if (file.type === 'application/pdf' && 'name' in file) {
      updateMedia({ blob: file, type: 'pdf', fileName: file.name });
      setShowAddMediaMenu(false);
      // Use MediaTypeField for image + video
    } else if ((type === 'video' || type === 'photo') && name) {
      updateMedia({
        blob: file,
        type,
        localSrc: localSrc || URL.createObjectURL(file),
        fileName: name,
        duration: durationSeconds,
      });
      setShowAddMediaMenu(false);
    } else {
      setShowAddMediaMenu(false);
      setValidationError(
        "Your file couldn't be uploaded because it was not a supported file type. Please try again with a supported file type."
      );
    }
  };

  const handleShareFilePress = () => {
    preventNextSplashScreenShow(15);
    if (fileUploaderRef && fileUploaderRef.current && document.createEvent) {
      const inputClick = document.createEvent('MouseEvents');
      inputClick.initEvent('click', true, false);
      // stopPropagation necessary to avoid callstack error and allow for file to upload
      inputClick.stopPropagation();
      fileUploaderRef.current.dispatchEvent(inputClick);
    }
  };

  const handleShareFileSubmitted = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0 && e.target.files[0]) {
      const chosenFile = e.target.files[0];
      handleShareFile({ file: chosenFile });
    }
  };

  const onPlusButtonPress = () => {
    // If no options except file share left, open it directly
    if (!showPhotoVideoUpload && hideRecordVoiceMessage && AUTO_OPEN_FILE_UPLOAD) {
      handleShareFilePress();
    } else {
      toggleShowAddMedia();
    }
  };

  const handleJoinActiveSession = () => {
    if (isMinimized) {
      toggleMinimizedAction();
    } else {
      postActiveSession(roomID);
      history.push(`${location?.pathname}/modal/video-call${location.state}`, {
        creditMinutes: activeSession?.booking.creditMinutes,
        roomID,
        startTime: activeSession?.booking.startTime,
        therapistFirstName: activeSession?.therapist.therapistFirstName,
        therapistUserID: activeSession?.therapist.userID,
        videoCallID: activeSession?.videoCallID,
        videoCreditType: activeSession?.booking?.type,
        modality: activeSession?.modality,
      });
    }
  };

  const handleLiveModalitySessionSelect = (
    roomIDparam: number,
    creditMinutes: number,
    type: string
  ) => {
    if (type !== 'free' && videoCreditOffersError?.message === 'user_ineligible') {
      history.push(`in-room-scheduling`);
    } else {
      createVideoCall(roomIDparam, creditMinutes, type, modality).then(() => {
        setVideoCallOfferType(type);
        setVideoCallOfferMinutes(creditMinutes);
        if (type === 'introduction') {
          setDidSendIntroCall(true);
        }
        if (
          creditMinutes === 45 ||
          type === 'introduction' ||
          (creditMinutes === 60 && type !== 'psychiatry')
        ) {
          setDoesCallHaveOffer(false);
        }
      });
    }
  };

  const handleRecordVoiceMessagePress = () => {
    toggleRecording();
    toggleShowAddMedia();
  };

  const handleCreateVideoCallErrorModalBackdropPress = () => {
    toggleShowVideoCallOffersMenu();
    setDidSendIntroCall(false);
  };

  const handleCreateVideoCallErrorModalSendFreeCall = () => {
    handleLiveModalitySessionSelect(roomID, 0, 'free');
    setDidSendIntroCall(false);
    toggleShowVideoCallOffersMenu();
  };

  const handleCreateVideoCallErrorModalOK = () => {
    setDidSendIntroCall(false);
    toggleShowVideoCallOffersMenu();
  };

  const isPsychInPsychRoom =
    therapistType === 'psychiatrist' && roomInfo.roomType === 'psychiatry_room';
  const isNonPsychInPsychRoom =
    therapistType !== 'psychiatrist' && roomInfo.roomType === 'psychiatry_room';
  const isCompactInput = message.length === 0 && !media?.type && !isDesktop;
  const isMobileInput = !isCompactInput;

  const handleFocusTrapDeactivate = () => {
    setIsInputAutoFocused(true);
    toggleShowAddMedia();
  };

  const mediaMenuWrapperID = useUniqueID('mediaMenuWrapperID');
  const addMediaMenuButtonID = useUniqueID('addMediaMenuButtonID');

  const menu = (
    <>
      <ResponsiveMenu
        isOpen={showAddMediaMenu}
        onClose={toggleShowAddMedia}
        handleFocusTrapDeactivate={handleFocusTrapDeactivate}
        wrapperID={mediaMenuWrapperID}
        useBottomSheet={useBottomSheet}
      >
        <AddMediaMenu
          menuId={mediaMenuWrapperID}
          ariaLabelledBy={addMediaMenuButtonID}
          handleShareFilePress={handleShareFilePress}
          handleShareFile={handleShareFile}
          onRecordVoiceMessagePress={handleRecordVoiceMessagePress}
          onLiveModalitySessionPress={toggleShowModalityTypeOptions}
          isPDFUploadEnabled={isPDFUploadEnabled}
          isTherapistChat={isTherapistChat}
          allowedSessionModalities={roomInfo.allowedSessionModalities}
          handleLiveModalitySessionSelect={handleLiveModalitySessionSelect}
          toggleShowAddMedia={toggleShowAddMedia}
          shouldShowModalityOptions={shouldShowModalityCallOptions}
          onBackArrowPress={toggleShowModalityOptions}
          roomID={roomID}
          isPsychInPsychRoom={isPsychInPsychRoom}
          isNonPsychInPsychRoom={isNonPsychInPsychRoom}
          modality={modality}
          isLoadingActiveSessionInfo={isLoadingActiveSessionInfo}
          activeSession={activeSession}
          handleJoinActiveSession={handleJoinActiveSession}
          isVideoCreditOffersLoading={isVideoCreditOffersLoading}
          videoCreditOffers={videoCreditOffers?.liveSessions}
          showPhotoVideoUpload={showPhotoVideoUpload}
          useBottomSheet={useBottomSheet}
          hideRecordVoiceMessage={hideRecordVoiceMessage}
        />
      </ResponsiveMenu>
      {/* Change key to remount it when media is cleared - Fixes MEMBER-49465 */}
      <FileUploader ref={fileUploaderRef} key={media?.type} onChange={handleShareFileSubmitted} />
      <InputIcon
        id={addMediaMenuButtonID}
        ariaControls={mediaMenuWrapperID}
        ariaExpanded={showAddMediaMenu}
        Component={CirclePlus}
        hide={!!media.type || recording}
        alt=""
        dataQa="uploadFilePress"
        ariaDescribedByText="Upload file"
        onPress={onPlusButtonPress}
        disabled={
          !hasProvider || isCTProvider || shouldMatchNewProvider || isChatDisabled || isOffline
        }
      />
    </>
  );
  const offersAndScripts = (
    <>
      <FloatingMenu onBackdropPress={toggleShowOffer} hide={!isTherapistChat || !showOffersMenu}>
        <OffersContainer roomID={roomID} onUseOffer={onUseOffer} />
      </FloatingMenu>
      <InputIcon
        hide={!isTherapistChat || !!media.type}
        Component={Tag}
        alt="offers menu"
        dataQa="offersMenuPress"
        onPress={toggleShowOffer}
        disabled={isChatDisabled}
      />
      <FloatingMenu
        onBackdropPress={toggleShowScript}
        hide={!isTherapistChat || !showScriptsMenu}
        style={{ minHeight: 400, left: 50 }}
      >
        <ScriptsContainer
          roomID={roomID}
          onUseScript={onUseScript}
          userName={roomInfo.userName}
          userPlanType={roomInfo.userPlanType}
          therapistDisplayName={roomInfo.therapistDisplayName}
        />
      </FloatingMenu>
      <InputIcon
        hide={!isTherapistChat || !!media.type}
        Component={Bars}
        alt="scripts menu"
        dataQa="scriptsMenuPress"
        onPress={toggleShowScript}
        disabled={isChatDisabled}
      />
    </>
  );
  const mediaContent = useMemo(() => {
    if (media?.type === 'pdf' && isPDFUploadEnabled) {
      return (
        <InputFile
          blob={media.blob}
          fileType={media.type}
          fileName={media.fileName}
          onClosePress={() => updateMedia({})}
        />
      );
    }
    if (media?.type === 'photo' || media?.type === 'video') {
      return (
        <InputFile
          blob={media.blob}
          fileType={media.type}
          localSrc={media.localSrc}
          fileName={media.fileName}
          onClosePress={() => updateMedia({})}
        />
      );
    }
    return null;
  }, [isPDFUploadEnabled, media]);

  const audio = useMemo(() => {
    if (isMobileApp)
      return (
        <InputAudioIonic
          refCallback={(ref: InputAudioRef) => {
            inputRef.current = ref;
          }}
          onCancel={onCancelRecording}
          hide={media.type !== 'audio'}
          onDurationChanged={setRecordingTime}
          onFinishRecording={onFinishRecording}
          recordingLimitInSeconds={MAX_RECORDING_LENGTH}
        />
      );
    return (
      <InputAudio
        refCallback={(ref: InputAudioRef) => {
          inputRef.current = ref;
        }}
        onCancel={onCancelRecording}
        hide={media.type !== 'audio'}
        onDurationChanged={setRecordingTime}
        onFinishRecording={onFinishRecording}
        recordingLimitInSeconds={MAX_RECORDING_LENGTH}
      />
    );
  }, [isMobileApp, media.type, onCancelRecording, onFinishRecording]);

  const actionButtons = (
    <>
      <View row align="center" style={{ minHeight: 28 }}>
        <StyledWordCount text={message} hide={recording || !message || !isTherapistChat} />
        <RecorderTimer hide={!recording} time={recordingTime} />
        <InputIcon
          height={30}
          alt="record audio"
          hide={(!!message.length || !!media.type) && !recording}
          Component={recording ? CircleStop : Microphone}
          colorType={recording ? 'critical' : undefined}
          ariaDescribedByText="Press to record audio message. Press again to stop recording"
          onPress={toggleRecording}
          disabled={
            !hasProvider ||
            isCTProvider ||
            isMinimized ||
            shouldMatchNewProvider ||
            isChatDisabled ||
            isOffline
          }
        />
        <InputSend
          onSend={onSubmit}
          setIsSendClicked={setIsSendClicked}
          isUpdating={isUpdating || !hasProvider || isCTProvider}
          hide={(message.length === 0 && !media.type) || recording}
        />
      </View>
    </>
  );

  return (
    <EmotionThemeProvider version={themeVersion || '0.1.0'}>
      {showCreateVideoCallOfferMenu && (
        <CreateVideoCallErrorModal
          onBackdropPress={handleCreateVideoCallErrorModalBackdropPress}
          didSendIntroCall={didSendIntroCall}
          doesCallHaveOffer={doesCallHaveOffer}
          handleCreateVideoCallErrorModalSendFreeCall={handleCreateVideoCallErrorModalSendFreeCall}
          handleCreateVideoCallErrorModalOK={handleCreateVideoCallErrorModalOK}
          hasNoCredits={hasNoCredits}
          isCallInProgress={isCallInProgress}
          offerType={videoCallOfferType}
          offerMinutes={videoCallOfferMinutes}
          postOffer={postOffer}
          roomID={roomID}
          roomStatus={roomInfo.roomStatus}
          userPlanType={roomInfo.userPlanType}
          videoCallErrorMessage={videoCallErrorMessage}
        />
      )}
      {validationError && (
        <ErrorModal error={validationError} onClosePress={() => setValidationError('')} />
      )}
      <InputWrapper isCompactInput={isCompactInput} style={{ minHeight: MIN_COMPOSER_HEIGHT_V2 }}>
        <InputAnimWrapper row isCompactInput={isCompactInput} isMobileInput={isMobileInput}>
          <View row align="center" justify="space-between" flex={isMobileInput ? 1 : 0}>
            <InputCharWarning
              exceeded={exceededLimit}
              dangerZone={dangerZone}
              hide={!showCharactersRemaining}
              charactersRemaining={charactersRemaining}
            />
            <View row>
              {menu}
              {offersAndScripts}
            </View>
            {!isCompactInput && actionButtons}
          </View>
          <InputComposerAnimWrapper row isMobileInput={isMobileInput}>
            <InputComposer
              id={ID_CHAT_INPUT}
              tabIndex={0}
              isInputAutoFocused={isInputAutoFocused}
              isInputSendFocused={isInputSendFocused}
              isSendClicked={isSendClicked}
              setIsInputAutoFocused={setIsInputAutoFocused}
              setIsInputSendFocused={setIsInputSendFocused}
              setIsSendClicked={setIsSendClicked}
              text={message}
              onChangeText={onChangeText}
              onSubmitEditing={() => onSubmit()}
              placeholder={inputPlaceholder}
              disabled={
                recording || !hasProvider || shouldMatchNewProvider || isChatDisabled || isOffline
              }
              wrapperStyle={{ marginRight: 0 }}
              inputRef={messageTextAreaRef}
              inputStyle={{
                marginBottom: 0,
                marginTop: isMobileInput ? 21 : 0,
              }}
              isFocusedAfterModal={isFocusedAfterModal}
              numberOfLines={getNumberOfLines(!!media?.type)}
            />
            <View
              row
              style={{ flexBasis: '100%', display: media?.type ? undefined : 'none' }}
              flex={isMobileInput ? 1 : 0}
            >
              {mediaContent}
              {audio}
            </View>
          </InputComposerAnimWrapper>
          {isCompactInput && actionButtons}
        </InputAnimWrapper>
      </InputWrapper>
    </EmotionThemeProvider>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    activeSession: state.chat.activeSession,
    isError: state.input.isError,
    isUpdating: state.input.isUpdating,
    roomInfo: state.chat.roomInfo,
    isTherapistChat: state.chat.isTherapistChat || false,
    hasNoCredits: state.input.hasNoCredits,
    isCallInProgress: state.input.isCallInProgress,
    isVideoError: state.input.isVideoError,
    isLoadingActiveSessionInfo: state.chat.isLoadingActiveSessionInfo,
    videoErrorRoom: state.input.videoErrorRoom,
    videoCallErrorMessage: state.input.videoCallErrorMessage,
  };
};
const mapDispatchToProps = {
  getRoomInfo: dispatchGetRoomInfo,
  sendMessage: dispatchSendMessage,
  sendMediaMessage: dispatchSendMediaMessage,
  createVideoCall: dispatchCreateVideoCall,
  postOffer: dispatchRequestPostOffer,
  getActiveSessionInfo: dispatchGetActiveSessionInfo,
  postActiveSession: dispatchPostActiveSessionInfo,
};
const InputContainerWithRedux = connect(mapStateToProps, mapDispatchToProps)(InputContainer);

export default withRouter(InputContainerWithRedux);
