import { RefObject, useEffect } from 'react';

export default function useClickOutside<T extends HTMLElement>(ref: RefObject<T>, cb: () => void) {
  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      const currentRef = ref.current;
      if (currentRef && !currentRef.contains(e.target as Node)) {
        cb();
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [cb, ref]);
}
