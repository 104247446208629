import * as actionTypes from '../constants/ClinicalInformationConstants';

const initialState = {
  presentingProblems: [],
  conditions: [],
  stageOfChange: null,
  physicalConditions: [],
  previousMentalHealthIssues: [],
  previousRelativesMentalHealthIssues: [],
  acuity: null,
  sleepQuality: null,
  sleepAidUse: null,
  socialSupportSystem: null,
  experiencingSchoolCommunity: [],
  experiencingSocialMedia: [],
  previousMentalHealthTreatment: null,
  hospitalizationHistory: null,
  traumaticExperience: null,
  traumaticFlashbacks: null,
  guilt: null,
  isolation: null,
  suicideIdeation: null,
  suicidePlanning: null,
  currentSurvey: undefined,
  conditionSurvey: undefined,
  surveyAlert: false,
  spinnerMessage: ' ',
  isUpdating: false,
  isError: false,
};

export default function clinicalInformation(state = initialState, action) {
  switch (action.type) {
    case actionTypes.REQUEST_GET_CLINICAL_INFORMATION:
    case actionTypes.REQUEST_SET_CLINICAL_INFORMATION:
    case actionTypes.REQUEST_ROOM_PRESENTING_PROBLEM:
    case actionTypes.REQUEST_GET_PHYSICAL_CONDITIONS:
    case actionTypes.REQUEST_GET_CONDITIONS:
    case actionTypes.REQUEST_POST_PHYSICAL_CONDITIONS:
    case actionTypes.REQUEST_POST_CONDITIONS:
    case actionTypes.REQUEST_DELETE_ROOM_PRESENTING_PROBLEM:
    case actionTypes.REQUEST_DELETE_PHYSICAL_CONDITIONS:
    case actionTypes.REQUEST_DELETE_CONDITIONS:
      return {
        ...state,
        spinnerMessage: 'Loading client information',
        isUpdating: true,
        isError: false,
      };
    case actionTypes.RECEIVE_GET_CLINICAL_INFORMATION:
    case actionTypes.RECEIVE_SET_CLINICAL_INFORMATION:
    case actionTypes.RECEIVE_ROOM_PRESENTING_PROBLEM:
    case actionTypes.RECEIVE_GET_PHYSICAL_CONDITIONS:
    case actionTypes.RECEIVE_POST_PHYSICAL_CONDITIONS:
    case actionTypes.RECEIVE_DELETE_PHYSICAL_CONDITIONS:
    case actionTypes.RECEIVE_GET_CONDITIONS:
    case actionTypes.RECEIVE_POST_CONDITIONS:
    case actionTypes.RECEIVE_DELETE_CONDITIONS:
    case actionTypes.POST_ROOM_PRESENTING_PROBLEM_SUCCESS:
    case actionTypes.DELETE_ROOM_PRESENTING_PROBLEM_SUCCESS:
      return { ...state, ...action.payload, isUpdating: false, isError: false };
    case actionTypes.REQUEST_POST_ROOM_PRESENTING_PROBLEM:
      return {
        ...state,
        spinnerMessage: 'Saving presenting problems',
        isUpdating: true,
        isError: false,
      };
    case actionTypes.REQUEST_CURRENT_SURVEY:
      return { ...state, isUpdating: true, isError: false };
    case actionTypes.RECEIVE_CURRENT_SURVEY:
      return { ...state, currentSurvey: action.currentSurvey };
    case actionTypes.REQUEST_CURRENT_SURVEY_ERROR:
      return {
        ...state,
        isUpdating: false,
        isError: true,
        spinnerMessage: 'Could not find current survey',
      };
    case actionTypes.REQUEST_CONDITION_SURVEY:
      return { ...state, isUpdating: true, isError: false };
    case actionTypes.RECEIVE_CONDITION_SURVEY:
      return { ...state, conditionSurvey: action.conditionSurvey, surveyAlert: action.surveyAlert };
    case actionTypes.REQUEST_CONDITION_SURVEY_ERROR:
      return {
        ...state,
        isUpdating: false,
        isError: true,
        spinnerMessage: 'Could not find condtion survey',
      };
    // case actionTypes.REQUEST_GET_CLINICAL_INFORMATION_ERROR: // TODO: Handle more errors
    // case actionTypes.REQUEST_GET_CLINICAL_INFORMATION_ERROR:
    // case actionTypes.REQUEST_GET_CLINICAL_INFORMATION_ERROR:
    case actionTypes.REQUEST_GET_CLINICAL_INFORMATION_ERROR:
      return { ...state, isUpdating: false, isError: true, spinnerMessage: '' };
    case actionTypes.CLOSE_SURVEY_ALERT:
      return { ...state, surveyAlert: action.surveyAlert };
    case actionTypes.REQUEST_SWITCH_SURVEY:
      return { ...state, isUpdating: true, isError: false };
    case actionTypes.SWITCH_SURVEY_SUCCESS:
      return { ...state, currentSurvey: null, surveyAlert: false };
    case actionTypes.SWITCH_SURVEY_ERROR:
      return {
        ...state,
        isUpdating: false,
        isError: true,
        spinnerMessage: 'Could not switch survey',
      };
    default:
      return state;
  }
}
