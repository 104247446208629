import { FunctionComponent, Children } from 'react';
import { isArray } from 'lodash';
import styled from '../../../core/styled';
import UnorderedList from '../../../components/UnorderedList';
import ListItem from '../../../components/ListItem';

const UnorderedListStyled = styled(UnorderedList)({ paddingLeft: 24 });
const ListItemStyled = styled(ListItem)({
  listStyleType: `'⦁'`,
  paddingInlineStart: '1ch',
});

interface UnorderedListDSProps {
  ListItemComponent?: FunctionComponent;
}
const UnorderedListDS: FunctionComponent<UnorderedListDSProps> = ({
  children,
  ListItemComponent,
}) => {
  const LIComponent = ListItemComponent || ListItemStyled;
  if (children && isArray(children)) {
    return (
      <UnorderedListStyled>
        {Children.map(children, (childNode) => (
          <LIComponent>{childNode}</LIComponent>
        ))}
      </UnorderedListStyled>
    );
  }
  return <UnorderedListStyled>{children}</UnorderedListStyled>;
};

export default UnorderedListDS;
