import { FunctionComponent, useState, useEffect } from 'react';
import {
  TextInput,
  Text,
  View,
  TouchableView,
  TextArea,
  Spinner,
  FloatingMenuHeader,
  useEmotionTheme,
} from '@talkspace/react-toolkit';

import styled from '@/core/styled/styled';
import { webOnlyStyle } from '@/core/styled/styleHelpers';
import { RouteComponentProps } from '@/core/routerLib/routerLib';
import { ScriptMessage, ScriptMessageType, ScriptsReplacementData } from '../../entities/Scripts';

interface ScriptsEditProps extends RouteComponentProps {
  loading: boolean;
  newScript?: boolean;
  scriptMessage: ScriptMessage;
  // eslint-disable-next-line react/no-unused-prop-types
  scriptsReplacementData: ScriptsReplacementData;

  onEdit(newMessage: ScriptMessage): Promise<{}>;
  // eslint-disable-next-line react/no-unused-prop-types
  selectScript(message: ScriptMessage): void;
  onRestore(scriptMessage: ScriptMessage): void;
  onDelete(scriptMessage: ScriptMessage): Promise<{}>;
  onCreate(name: string, message: string): Promise<void>;
}

interface EditScriptsError {
  title?: string;
  message?: string;
}

const SectionTitle = styled(Text)(({ theme: { colors } }) => {
  return { color: colors.placeholderGrey, marginTop: 5, fontSize: 12, marginBottom: 10 };
});

const StyledTextArea = styled(TextArea)(({ theme: { colors }, disabled }) => {
  return {
    height: 150,
    padding: 10,
    borderWidth: 1,
    borderColor: disabled ? '#f9f9f7' : colors.grey,
    backgroundColor: disabled ? '#f9f9f7' : 'white',
    ...webOnlyStyle({
      resize: 'none',
      WebkitAppearance: 'none',
      outline: '0px none',
      '::-webkit-scrollbar': {
        width: 5,
      },
      '::-webkit-scrollbar-thumb:vertical': {
        margin: 5,
        backgroundColor: '#EBEBEB',
        WebkitBorderRadius: 5,
      },
    }),
  };
});

const StyledTextInput = styled(TextInput)<{ disabled?: boolean }>(
  ({ theme: { colors }, disabled }) => {
    return {
      height: 150,
      borderWidth: 1,
      borderColor: disabled ? '#f9f9f7' : colors.grey,
      backgroundColor: disabled ? '#f9f9f7' : colors.white,
      ...webOnlyStyle({
        resize: 'none',
        WebkitAppearance: 'none',
        outline: '0px none',
        '::-webkit-scrollbar': {
          width: 5,
        },
        '::-webkit-scrollbar-thumb:vertical': {
          margin: 5,
          backgroundColor: '#EBEBEB',
          WebkitBorderRadius: 5,
        },
      }),
    };
  }
);

// const ErrorsWrapper = styled(View)<{ hide?: boolean }>(({ hide }) => {
const ErrorsWrapper = styled(View)<{ hide?: boolean }>({
  height: 20,
  justifyContent: 'center',
});

const ErrorText = styled(Text)(({ theme: { colors } }) => {
  return { color: colors.red, fontSize: 12 };
});

const ScriptsWrapper = styled(View)({
  height: '100%',
  justifyContent: 'flex-start',
  paddingLeft: 30,
  paddingRight: 30,
  minWidth: 300,
  maxWidth: 500,
  ...webOnlyStyle({
    width: 'calc(100vw / 2.5)',
    '@media (max-width: 750px)': {
      paddingLeft: 10,
      paddingRight: 10,
    },
  }),
});

const BottomButtonsWrapper = styled(View)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
});

const Button: FunctionComponent<{
  text: string;
  bold?: boolean;
  hide?: boolean;
  color?: string;
  right?: boolean;
  onPress?(): void;
  disabled?: boolean;
}> = ({ text, disabled, onPress, bold, hide, right, color }) => {
  const { colors } = useEmotionTheme();
  if (hide) return null;
  return (
    <TouchableView
      styles={right ? { marginRight: 20, alignSelf: 'flex-end' } : { marginRight: 20 }}
      onPress={onPress}
      disabled={disabled}
    >
      <Text
        style={{
          color: disabled ? colors.grey : color || colors.green,
          ...(bold ? { fontWeight: 'bold' } : {}),
        }}
      >
        {text}
      </Text>
    </TouchableView>
  );
};

const ScriptsEdit: FunctionComponent<ScriptsEditProps> = ({
  onEdit,
  history,
  loading,
  onDelete,
  onCreate,
  onRestore,
  newScript,
  scriptMessage,
}) => {
  const { colors } = useEmotionTheme();
  const [name, setName] = useState(scriptMessage.title || '');
  const [message, setMessage] = useState(scriptMessage.text || '');
  const [errors, setErrors] = useState<EditScriptsError>({});

  useEffect(() => {
    setName(scriptMessage.title || '');
    setMessage(scriptMessage.text || '');
    setErrors({});
  }, [scriptMessage, newScript]);

  const onSave = () => {
    const newErrors: EditScriptsError = {};
    if (!name.trim()) newErrors.title = 'Name is required';
    if (!message.trim()) newErrors.message = 'Message is required';
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      if (newScript) {
        onCreate(name, message).then(history.goBack);
      } else {
        onEdit({ ...scriptMessage, text: message, title: name }).then(history.goBack);
      }
    }
  };

  const restoreMessage = () => {
    onRestore(scriptMessage);
  };

  const deleteMessage = () => {
    onDelete(scriptMessage).then(history.goBack);
  };

  const onCancel = () => {
    history.goBack();
  };
  const isSystemModifiedMessage = scriptMessage.type === ScriptMessageType.SYSTEM_MODIFIED;

  const isTherapistMessage = scriptMessage.type === ScriptMessageType.THERAPIST;

  return (
    <>
      <FloatingMenuHeader title={newScript ? 'Create script' : 'Edit script'} showBackArrow />
      <ScriptsWrapper>
        <StyledTextInput
          value={name}
          placeholder="Script name"
          onBlur={(e) => setName(e.target.value)} // TODO: Change this to be onChangeText when react-toolkit is updated with the change
          disabled={!isTherapistMessage && !newScript}
        />
        <ErrorsWrapper>{errors.title ? <ErrorText>{errors.title}</ErrorText> : null}</ErrorsWrapper>
        <SectionTitle>Message</SectionTitle>
        <StyledTextArea multiline value={message} numberOfLines={7} onChangeText={setMessage} />
        <ErrorsWrapper>
          {errors.message ? <ErrorText>{errors.message}</ErrorText> : null}
        </ErrorsWrapper>
        <BottomButtonsWrapper>
          <Button text="Cancel" onPress={onCancel} hide={loading} />
          <Button
            text="Reset"
            disabled={loading}
            onPress={restoreMessage}
            hide={!isSystemModifiedMessage || loading}
          />
          <Button
            text="Delete"
            color={colors.razzmatazz}
            disabled={loading}
            onPress={deleteMessage}
            hide={!isTherapistMessage || loading}
          />
          {loading && <Spinner isLoading />}
          <Button right text="Save" hide={loading} onPress={onSave} disabled={loading} />
        </BottomButtonsWrapper>
      </ScriptsWrapper>
    </>
  );
};

export default ScriptsEdit;
