import { FunctionComponent } from 'react';
import styled from '../../core/styled';
import View from '../View';
import Text from '../Text';

const Marker = styled(View)<{ isEven: boolean }>(({ isEven, theme: { colors } }) => {
  return {
    marginBottom: isEven ? -4 : -6,
    height: isEven ? 3 : 5,
    width: 1,
    borderRadius: '0.5px',
    backgroundColor: isEven ? colors.loblollyGrey : colors.grey,
    position: 'relative',
    display: 'flex',
  };
});

const LabelContainer = styled(View)(({ theme: { colors } }) => {
  return {
    fontFamily: 'Roboto, sans-serif',
    fontSize: 12,
    color: colors.black,
    letterSpacing: '0',
    textAlign: 'center',
    position: 'relative',
  };
});

const Label = styled(Text)({
  marginTop: 5,
  marginLeft: '-100%',
});

const MarkerElement: FunctionComponent<{ index: number }> = ({ index }) => {
  const labels = ['2a', '4a', '6a', '8a', '10a', '12p', '2p', '4p', '6p', '8p', '10p'];
  const isEven = index % 2 === 0;
  return (
    <Marker isEven={isEven}>
      <LabelContainer>
        <Label>{labels[index]}</Label>
      </LabelContainer>
    </Marker>
  );
};

export default MarkerElement;
