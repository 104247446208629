import { FunctionComponent } from 'react';
import { EmotionStyle } from '../../core/styled';

const UnorderedList: FunctionComponent<{
  style?: EmotionStyle;
  className?: string;
}> = ({ children, className, style }) => (
  <ul className={className} style={style}>
    {children}
  </ul>
);

export default UnorderedList;
