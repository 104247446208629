import { Big, useEmotionTheme, View } from '@talkspace/react-toolkit';
import AgendaCardIcon from '../../../Icons/AgendaCardIcon';

const AgendaEmptyState = () => {
  const { colors } = useEmotionTheme();
  return (
    <View
      style={{
        width: 355,
        height: 298,
        backgroundColor: colors.permaGhostWhite,
        border: `1px solid ${colors.permaPowderBlue}`,
        borderRadius: 9,
        marginTop: 20,
        alignItems: 'center',
      }}
    >
      <View style={{ margin: '40px 0px 10px' }}>
        <AgendaCardIcon />
      </View>
      <Big variant="bigDarkGrey">You currently have no agenda cards</Big>
      <Big
        variant="bigMedium"
        style={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}
      >{`This is where you’ll see important\ntasks for this specific client.`}</Big>
    </View>
  );
};

export default AgendaEmptyState;
