import { useEffect, useState } from 'react';
import * as React from 'react';
import Modal from '../Modal';
import TextInputAuth from '../TextInputAuth';
import Button from '../Button';
import BaseButton from '../BaseButton';
import View from '../View';
import { ExtraHuge, Tiny, Large, Standard } from '../Typography';
import { useWindowWidthState } from '../../hooks/windowWidthContext';
import { useUniqueID, useErrorFocus } from '../../hooks/a11yHelper';
import styled, { useEmotionTheme, EmotionStyle } from '../../core/styled';

const defaultTitle = 'Enter your email to access this screen';
const defaultButtonText = 'Submit';
const defaultDismissButtonText = 'Dismiss';
const defaultTinyText =
  'You can opt out anytime through email preferences or by contacting customer support.';

const StyledSkipOptionButton = styled(Standard)<{ buttonStyles?: EmotionStyle }>(
  ({ buttonStyles, theme: { colors } }) => {
    return {
      color: colors.green,
      backgroundColor: 'transparent',
      border: 'none',
      width: 'auto',
      alignSelf: 'center',
      fontWeight: 700,
      transition: 'all 200ms ease-in-out',
      fontSize: 17,
      '&:hover': {
        color: colors.permaTropicalRainForest,
      },
      ...buttonStyles,
    };
  }
);

const Wrapper = styled(View)({
  width: '100%',
  margin: '0 auto',
});

interface EmailWallProps {
  onSubmit: (email: string) => void;
  title?: string;
  subTitle?: string | JSX.Element;
  isVisible: boolean;
  buttonText?: string;
  buttonDataQa?: string;
  tinyText?: string | JSX.Element;
  isLoading?: boolean;
  errorMessage?: string;
  shouldDisplayError?: boolean;
  dismissButtonText?: string;
  dismissButtonDataQa?: string;
  onDismiss?: () => void;
  setBlurParentContent(bool: boolean): void;
  emailFieldDataQa?: string;
  emailFieldAriaLabel?: string;
  defaultValue?: string;
}

const EmailWall: React.FunctionComponent<EmailWallProps> = ({
  onSubmit,
  title = defaultTitle,
  subTitle,
  isVisible,
  buttonText = defaultButtonText,
  buttonDataQa,
  dismissButtonText = defaultDismissButtonText,
  dismissButtonDataQa,
  onDismiss,
  tinyText = defaultTinyText,
  isLoading,
  errorMessage,
  shouldDisplayError: shouldDisplayErrorProp,
  setBlurParentContent,
  emailFieldDataQa,
  emailFieldAriaLabel,
  defaultValue = '',
  ...otherProps
}) => {
  const [shouldDisplayError, setShouldDisplayError] = useState(false);
  useEffect(() => {
    if (shouldDisplayErrorProp !== undefined) setShouldDisplayError(shouldDisplayErrorProp);
  }, [shouldDisplayErrorProp]);

  const [emailValidated, setEmailValidated] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const { formContainerRef, setShouldFocusFirstInvalidField } = useErrorFocus();
  const { colors } = useEmotionTheme();

  const handleValidationChange = (validated: boolean) => {
    setEmailValidated(validated);
  };

  const [emailAddress, setEmailAddress] = useState(defaultValue);
  const onChangeHandler = (event) => {
    setShouldDisplayError(false);
    setEmailAddress(event.target.value);
  };

  const { isMobile } = useWindowWidthState();

  const handleSubmit = () => {
    setHasSubmitted(true);
    onSubmit(emailAddress);
  };

  const ID_TITLE = useUniqueID('titleId');
  const ID_SUB_TITLE = useUniqueID('subTitleId');

  useEffect(() => {
    setBlurParentContent(isVisible);
  }, [setBlurParentContent, isVisible]);

  useEffect(
    () => () => {
      setBlurParentContent(false);
    },
    [setBlurParentContent]
  );

  useEffect(() => {
    if (emailValidated && hasSubmitted && shouldDisplayError) {
      setShouldFocusFirstInvalidField(true);
    }
  }, [emailValidated, hasSubmitted, setShouldFocusFirstInvalidField, shouldDisplayError]);

  return (
    <Modal
      titleID={ID_TITLE}
      isVisible={isVisible}
      underlayStyle={{
        alignItems: 'flex-end',
        overflow: 'hidden',
      }}
      dialogStyle={
        isMobile ? { width: '100%' } : { position: 'absolute', width: 500, height: '100%' }
      }
    >
      <View
        style={{
          backgroundColor: '#FFF',
          width: '100%',
          ...(isMobile
            ? { borderTopLeftRadius: 10, borderTopRightRadius: 10, padding: '50px 20px' }
            : { borderRadius: 10, padding: 80 }),
          ...otherProps,
        }}
        ref={formContainerRef}
      >
        <Wrapper style={{ maxWidth: isMobile ? 320 : 340 }}>
          <ExtraHuge
            as="h1"
            id={ID_TITLE}
            style={{
              textAlign: 'center',
              marginBottom: subTitle ? 20 : 35,
              marginLeft: isMobile ? 20 : 30,
              marginRight: isMobile ? 20 : 30,
              ...(isMobile ? { marginTop: 0 } : { marginTop: -10 }),
              lineHeight: '34px',
            }}
          >
            {title}
          </ExtraHuge>

          {subTitle && (
            <Large
              id={ID_SUB_TITLE}
              variant="largeDarkGrey"
              style={{ textAlign: 'center', marginBottom: 30 }}
            >
              {subTitle}
            </Large>
          )}

          <TextInputAuth
            type="email"
            name="email"
            label="Email"
            placeholder="Enter email address"
            value={emailAddress}
            onChange={onChangeHandler}
            errorMessage={errorMessage}
            shouldDisplayError={shouldDisplayError}
            onValidationChanged={handleValidationChange}
            wrapperStyle={{ width: '100%' }}
            style={{ width: '100%' }}
            roundedFocusStyle
            primaryColor={colors.periwinkleGrey}
            dataQa={emailFieldDataQa}
            ariaLabel={emailFieldAriaLabel}
            {...(defaultValue && { validateOnMount: true })}
          />

          <Button
            style={{ marginTop: 12, width: '100%', fontWeight: 'bold' }}
            disabled={!emailValidated || shouldDisplayError}
            onPress={handleSubmit}
            isLoading={isLoading}
            dataQa={buttonDataQa}
            roundedFocusStyle
            aria-describedby={ID_SUB_TITLE}
            primaryColor={colors.green}
          >
            {buttonText}
          </Button>

          <Tiny style={{ textAlign: 'center', margin: '20px auto' }}>{tinyText}</Tiny>

          {onDismiss && (
            <BaseButton
              roundedFocusStyle
              primaryColor={colors.permaWaikawaGrey}
              style={{ borderRadius: 5 }}
              onPress={onDismiss}
              data-qa={dismissButtonDataQa}
            >
              <StyledSkipOptionButton>{dismissButtonText}</StyledSkipOptionButton>
            </BaseButton>
          )}
        </Wrapper>
      </View>
    </Modal>
  );
};
export default EmailWall;
