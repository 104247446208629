import { useMutation, useQueryClient } from 'react-query';
import apiHelper from '@/utils/api';
import apiWrapper from '../../utils/apiWrapper';
import { notesListQueryKey, progressNoteHelperDataQueryKey } from './queryKeys';

interface DeleteProgressNoteMutateVariables {
  roomID: string;
  noteID: string;
  isDraft: boolean;
}

const deleteProgressNote = async (params: DeleteProgressNoteMutateVariables): Promise<void> => {
  const { roomID, noteID, isDraft } = params;
  const url = isDraft
    ? `${apiHelper().apiEndpoint}/v2/rooms/${roomID}/progress-notes/${noteID}/draft`
    : `${apiHelper().apiEndpoint}/v2/rooms/${roomID}/progress-notes/${noteID}`;
  await apiWrapper.delete(url);
};

const useMutationDeleteProgressNote = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, DeleteProgressNoteMutateVariables>(deleteProgressNote, {
    onSuccess: (_, { roomID }) => {
      queryClient.invalidateQueries(notesListQueryKey(roomID));
      queryClient.invalidateQueries(progressNoteHelperDataQueryKey(roomID));
    },
  });
};

export default useMutationDeleteProgressNote;
