import { useState, useEffect, VoidFunctionComponent } from 'react';
import {
  Modal,
  View,
  Button,
  ExtraHuge,
  Large,
  BaseButton,
  Big,
  Small,
  useEmotionTheme,
  useUniqueID,
  TherapistSelect,
  TherapistOptionType,
  CheckMark,
} from '@talkspace/react-toolkit';
import { TherapistType } from 'ts-frontend/entities/Therapist';
import styled from '@/core/styled';

interface DailyCaseLoadModalProps {
  showModal: boolean;
  dailyCaseLoad: string;
  therapistType: TherapistType;
  dailyCaseLoadOptions: Record<string, number>;
  onAvailabilityPress: (maxClient: string) => void;
  onKeepSettingPress: (previousMaxClient: string) => void;
}

const ModalContainer = styled(View)(({ theme: { colors } }) => {
  return {
    background: colors.white,
    textAlign: 'center',
    width: 500,
    height: 423,
    paddingLeft: 70,
    paddingRight: 70,
    paddingTop: 48,
    paddingBottom: 69,
  };
});

const ButtonContainer = styled(View)(() => {
  return {
    marginTop: 15,
  };
});

const ElementOption = ({
  caseLoad,
  previousSelected,
  isPsych,
  optionReference,
}: {
  caseLoad: string;
  previousSelected?: string;
  isPsych: boolean;
  optionReference: Record<string, number>;
}) => {
  const { colors } = useEmotionTheme();

  let optionItem: JSX.Element;
  let checkItem: JSX.Element | null = null;

  if (isPsych) {
    checkItem =
      (previousSelected === 'slow' && caseLoad === 'minimum') || previousSelected === caseLoad ? (
        <CheckMark
          color={colors.permaHedgeGreen}
          style={{ position: 'absolute', width: 12, left: -20, top: 3.6 }}
        />
      ) : null;

    optionItem = <Small variant="smallTSBlack">{caseLoad === 'minimum' ? 'Yes' : 'No'}</Small>;
  } else {
    checkItem =
      previousSelected === caseLoad ? (
        <CheckMark
          color={colors.permaHedgeGreen}
          style={{ position: 'absolute', width: 12, left: -20, top: 3.6 }}
        />
      ) : null;

    optionItem =
      caseLoad === 'none' ? (
        <>
          <Small inline variant="smallTSBlack" style={{ marginRight: 10 }}>
            {optionReference[caseLoad]}
          </Small>
          <Small
            inline
            variant="smallBoldTSBlack"
            style={{ color: colors.black, fontWeight: 'bold' }}
          >
            None, I don’t want new clients
          </Small>
        </>
      ) : (
        <Small variant="smallTSBlack">{optionReference[caseLoad]}</Small>
      );
  }

  return (
    <View row style={{ position: 'relative' }}>
      {checkItem}
      {optionItem}
    </View>
  );
};

const getElementOption = ({
  value,
  previousSelected,
  isPsych = false,
  optionReference,
}: {
  value: string;
  previousSelected?: string;
  isPsych: boolean;
  optionReference: Record<string, number>;
}): TherapistOptionType => {
  return {
    value,
    label: '',
    breakRow: true,
    jsxElement: () => (
      <ElementOption
        caseLoad={value}
        previousSelected={previousSelected}
        isPsych={isPsych}
        optionReference={optionReference}
      />
    ),
  };
};

const DailyCaseLoadModal: VoidFunctionComponent<DailyCaseLoadModalProps> = ({
  showModal,
  therapistType,
  dailyCaseLoad,
  dailyCaseLoadOptions,
  onAvailabilityPress,
  onKeepSettingPress,
}) => {
  const isPsych = therapistType === 'psychiatrist';

  const prevSettings = dailyCaseLoad;
  const [maxClient, setMaxClient] = useState<string | undefined>(dailyCaseLoad);
  const [selected, setSelected] = useState({ label: 'Select', value: '' });
  const updateClientAvailabilityID = useUniqueID('updateClientAvailability');
  const keepCurrentSettingsID = useUniqueID('keepCurrentSettings');
  const caseLoad: string[] = Object.keys(dailyCaseLoadOptions).sort(
    (a, b) => dailyCaseLoadOptions[b] - dailyCaseLoadOptions[a]
  );
  const { colors } = useEmotionTheme();

  useEffect(() => {
    localStorage.setItem('capacityModalSeenAt', new Date().toISOString());
    localStorage.setItem('oneTimeCapacityModalSeenAt', new Date().toISOString());
  }, []);

  const optionItems = isPsych ? ['minimum', 'none'] : caseLoad;

  return (
    <Modal isVisible={showModal} underlayStyle={{ background: `rgba(0, 29, 77, 0.35)` }}>
      <Modal.Panel showCloseButton={false}>
        <ModalContainer>
          <ExtraHuge style={{ marginBottom: 12 }}>
            {isPsych
              ? 'Would you like to take new clients?'
              : 'What is the maximum amount of new clients you want to receive per day'}
          </ExtraHuge>
          <Large>You haven’t updated your new client</Large>
          <Large>availability in a while. Please make a selection.</Large>
          <View style={{ width: 335, marginLeft: 14 }}>
            <TherapistSelect
              dataQa="maxClientSelect"
              style={{ marginTop: 14 }}
              value={selected}
              options={optionItems.map((value) =>
                getElementOption({
                  optionReference: dailyCaseLoadOptions,
                  previousSelected: maxClient,
                  isPsych,
                  value,
                })
              )}
              onChange={(value) => {
                setMaxClient((value as TherapistOptionType).value);
                if (maxClient !== undefined) {
                  setSelected(
                    getElementOption({
                      value: (value as TherapistOptionType).value,
                      isPsych,
                      optionReference: dailyCaseLoadOptions,
                    })
                  );
                }
              }}
              {...(isPsych && { maxMenuHeight: 120 })}
            />
          </View>
          <ButtonContainer align="center">
            <Button
              data-qa="updateClientAvailability"
              style={{
                background:
                  selected.label !== 'Select' ? colors.permaTalkspaceDarkGreen : colors.slateGrey,
                marginBottom: 25,
                marginTop: 0,
                width: 334,
              }}
              disabled={selected.label === 'Select'}
              onPress={() => maxClient && onAvailabilityPress(maxClient)}
              aria-describedby={updateClientAvailabilityID}
              primaryColor={
                selected.label !== 'Select' ? colors.permaTalkspaceDarkGreen : colors.slateGrey
              }
              roundedFocusStyle
            >
              <Big style={{ color: colors.white }}>Update new client availability</Big>
            </Button>
            <BaseButton
              data-qa="keepCurrentSettings"
              primaryColor={colors.permaTalkspaceDarkGreen}
              roundedFocusStyle
              onPress={() => onKeepSettingPress(prevSettings)}
              aria-describedby={keepCurrentSettingsID}
            >
              <Large variant="largeBoldWideGreen" style={{ color: colors.permaTalkspaceDarkGreen }}>
                Keep my current settings
              </Large>
            </BaseButton>
          </ButtonContainer>
        </ModalContainer>
      </Modal.Panel>
    </Modal>
  );
};

export default DailyCaseLoadModal;
