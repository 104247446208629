import {
  View,
  Text,
  Small,
  Large,
  RatingStarsField,
  BricksField,
  useWindowWidthState,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import moment from 'moment';

import styled from '@/core/styled/styled';

import { ReviewsRecord } from '../types';

const RatingContainer = styled(View)<{ index: number }>(({ index, theme: { colors } }) => {
  return {
    padding: '25px 20px',
    borderTopStyle: 'solid',
    borderColor: colors.extraLightGrey,
    borderTopWidth: index > 0 ? 1 : 0,
    fontSize: 16,
  };
});

const BricksRow = styled(View)({
  width: 'auto',
  flexWrap: 'wrap',
});

const BricksContainer = styled(View)({
  width: 'auto',
  flex: 'auto',
});

const HeaderContainer = styled(View)({
  paddingTop: 12,
  marginRight: 10,
});

const OtherTextContainer = styled(View)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      marginTop: isMobile ? 15 : 0,
      maxWidth: 800,
    };
  }
);

const OtherTextContainerNoBricks = styled(View)({
  display: 'block',
  width: '100%',
  paddingTop: 20,
});

const SourceDateContainer = styled(View)({
  textAlign: 'right',
});

interface ReviewsRowProps {
  review: ReviewsRecord;
  index: number;
}

const ReviewsRow = ({ review, index }: ReviewsRowProps) => {
  const { isMobile } = useWindowWidthState();
  const { colors } = useEmotionTheme();
  const { bricks, comment, createdAt, otherText, rating, source, id: reviewID } = review;
  const fullRating = Number(rating) === 5;
  const feedbackHeader = fullRating
    ? "What's going well:"
    : 'What could be improved to get 5 stars:';
  const brickColor = fullRating ? colors.limaGreen : colors.jellybeanRed;

  const bricksAndText = () => {
    if (bricks && bricks.length)
      return (
        <BricksContainer>
          <View row={!isMobile}>
            <BricksField brickColor={brickColor} bricks={bricks} />
          </View>
          {otherText && otherText.length ? (
            <OtherTextContainer row={!isMobile}>
              <Large>
                <Large inline variant="largeBoldWide">
                  Other:{' '}
                </Large>
                {otherText}
              </Large>
            </OtherTextContainer>
          ) : null}
        </BricksContainer>
      );

    return (
      <>
        <View>
          <HeaderContainer>
            <Large variant="largeLightGrey">N/A</Large>
          </HeaderContainer>
        </View>
        {otherText && otherText.length ? (
          <OtherTextContainerNoBricks>
            <Large>{otherText}</Large>
          </OtherTextContainerNoBricks>
        ) : null}
      </>
    );
  };
  return (
    <RatingContainer key={reviewID} index={index}>
      <View row justify="space-between" aria-label={`rating: ${rating} out of 5.`}>
        <View aria-hidden="true">
          <RatingStarsField color={colors.blazeOrange} initialRating={rating} />
        </View>
        <SourceDateContainer>
          <Small>{moment(createdAt).format('MMMM Do, YYYY')}</Small>
          <Small>{source}</Small>
        </SourceDateContainer>
      </View>
      <BricksRow row={!isMobile}>
        <View>
          <HeaderContainer>
            <Large variant="largeBoldWide">{feedbackHeader}</Large>
          </HeaderContainer>
        </View>
        {bricksAndText()}
      </BricksRow>
      <Text style={{ marginTop: 20, whiteSpace: 'break-spaces' }}>{comment}</Text>
    </RatingContainer>
  );
};

export default ReviewsRow;
