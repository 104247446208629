import { FunctionComponent } from 'react';
import { Large, ExtraHuge } from '@talkspace/react-toolkit';

const KeepUpTheGreatWorkHeading: FunctionComponent = () => (
  <>
    <ExtraHuge>Keep up the great work!</ExtraHuge>
    <Large variant="largeDarkGrey" style={{ marginTop: 12 }}>
      Let's schedule your next session.
    </Large>
  </>
);

export default KeepUpTheGreatWorkHeading;
