import { useMutation, useQueryClient } from 'react-query';
import apiHelper from '@/utils/api';
import apiWrapper from '../../utils/apiWrapper';
import { notesListQueryKey } from './queryKeys';

interface DeleteDischargeNoteMutateVariables {
  roomID: string;
  noteID: string;
  isDraft: boolean;
}

const deleteDischargeNote = async (params: DeleteDischargeNoteMutateVariables): Promise<void> => {
  const { roomID, noteID, isDraft } = params;
  const url = isDraft
    ? `${apiHelper().apiEndpoint}/v2/rooms/${roomID}/discharge-notes/${noteID}/draft`
    : `${apiHelper().apiEndpoint}/v2/rooms/${roomID}/discharge-notes/${noteID}`;
  await apiWrapper.delete(url);
};

const useMutationDeleteDischargeNote = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, DeleteDischargeNoteMutateVariables>(deleteDischargeNote, {
    onSuccess: (_, { roomID }) => {
      queryClient.invalidateQueries(notesListQueryKey(roomID));
    },
  });
};

export default useMutationDeleteDischargeNote;
