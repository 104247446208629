import { VoidFunctionComponent, useEffect } from 'react';
import {
  View,
  Button,
  Small,
  styled,
  Huge,
  useEmotionTheme,
  BaseButton,
  Big,
  CancellationPolicyMessage,
  useComponentSize,
} from '@talkspace/react-toolkit';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { TherapistTimeslot } from 'ts-frontend/types';
import { trackConfirmOrDeclineRecurringSessionInteraction } from 'ts-analytics/mixpanel/events';
import { getUserData } from '@/auth/helpers/token';
import BottomButtonContainer from '../BottomButtonContainer';
import { useInRoomSchedulingState } from '../../hooks/inRoomSchedulingContext';
import BookingSummaryList from '../BookingSummaryList';
import BookingSummaryDropdown from '../BookingSummaryDropdown';
import BookingSummaryDatetimes from '../BookingSummaryDatetimes';
import BookingTimeZone from '../BookingTimeZone';

const Subtitle = styled(Huge)({
  textAlign: 'center',
  maxWidth: 375,
  marginTop: 16,
  marginBottom: 8,
});

const Row = styled(View)({
  marginBottom: 16,
});

interface Props {
  timezone: string;
  isReschedule: boolean;
  repeatingTimeslots: TherapistTimeslot[];
  conflictingTimeslots?: TherapistTimeslot[];
  handleReserveClick: () => void;
  handleDeclineClick: () => void;
  onClickCancellationPolicy: () => void;
}

const ConfirmBookingRecurringClient: VoidFunctionComponent<Props> = ({
  timezone,
  isReschedule,
  repeatingTimeslots,
  conflictingTimeslots,
  handleReserveClick,
  handleDeclineClick,
  onClickCancellationPolicy,
}) => {
  const { id: userID } = getUserData();
  const { repeatingSessionsFull2 } = useFlags();
  const { isLoading, selectedCreditOption, room, selectedConfirmBooking } =
    useInRoomSchedulingState();
  const { colors } = useEmotionTheme();
  const allTimeslots = [...(repeatingTimeslots || []), ...(conflictingTimeslots || [])].sort(
    (a, b) => new Date(a.start).valueOf() - new Date(b.start).valueOf()
  );
  const [stickyFooterRef, { height: stickyFooterHeight }] = useComponentSize<HTMLDivElement>();
  const marginBottom = stickyFooterHeight ? stickyFooterHeight - 50 : 50;

  useEffect(() => {
    if (repeatingSessionsFull2 && selectedConfirmBooking) {
      trackConfirmOrDeclineRecurringSessionInteraction({
        mode: 'view',
        bookingID: selectedConfirmBooking.id,
        userID,
      });
    }
  }, [repeatingSessionsFull2, selectedConfirmBooking, userID]);

  return repeatingSessionsFull2 ? (
    <>
      <View flex={1} align="center" style={{ width: 375, marginBottom }}>
        <Subtitle>Here’s your recurring schedule</Subtitle>
        <Row>
          <BookingSummaryDatetimes timeslots={repeatingTimeslots} />
        </Row>
        <Row>
          <BookingTimeZone timezone={timezone} />
        </Row>
        <BookingSummaryDropdown
          timeslots={allTimeslots}
          recurringConflicting={conflictingTimeslots || null}
          recurringAvailable={repeatingTimeslots}
        />
        <Small variant="smallDarkGrey" style={{ textAlign: 'center', maxWidth: 335 }}>
          Not sure you can attend all of them? Reschedule for free up until 24 hours before each
          session starts.
        </Small>
      </View>
      <BottomButtonContainer
        propsV0={{
          style: {
            borderTop: `0.5px solid ${colors.permaLondonGray}`,
            paddingTop: 0,
            paddingBottom: 34,
          },
          ref: stickyFooterRef,
        }}
      >
        <Button
          disabled={isLoading}
          isLoading={isLoading}
          onPress={handleReserveClick}
          text="Continue"
          dataQa={`inRoomSchedulingConfirmBooking${isReschedule ? 'Reschedule' : 'Schedule'}`}
          stretch
          style={{ marginTop: 16, backgroundColor: colors.permaTalkspaceDarkGreen }}
        />
        <BaseButton
          style={{ marginTop: 14 }}
          onPress={handleDeclineClick}
          dataQa="confirmBookingRecurringClientBookingDecline"
        >
          <Big variant="bigMedium" style={{ color: colors.permaTalkspaceDarkGreen }}>
            Decline sessions
          </Big>
        </BaseButton>
        <CancellationPolicyMessage
          dataQa="confirmBookingRecurringClientConfirmBooking"
          onCancellationPolicyPress={onClickCancellationPolicy}
          style={{ marginTop: 16 }}
        />
      </BottomButtonContainer>
    </>
  ) : (
    <>
      <View flex={1} align="center" style={{ width: 335, marginBottom: 50 }}>
        {selectedCreditOption && <Subtitle>Confirm recurring sessions</Subtitle>}
        {repeatingTimeslots && <BookingSummaryList timeslots={repeatingTimeslots} />}
        <Small variant="smallDarkGrey" style={{ marginTop: 24, textAlign: 'center' }}>
          Not sure if you can attend all of them? You can reschedule for free up until 24 hours
          before each session starts.
          <br />
          <br />
          Late cancellations or no-shows will result in a fee for the cost of service,{' '}
          <b>which can be up to $175.</b>
        </Small>
      </View>
      <BottomButtonContainer
        propsV0={{
          style: {
            borderTop: `0.5px solid ${colors.permaLondonGray}`,
            paddingTop: 0,
            paddingBottom: 34,
          },
        }}
      >
        <Button
          disabled={isLoading}
          isLoading={isLoading}
          onPress={handleReserveClick}
          text="I agree"
          dataQa={`inRoomSchedulingConfirmBooking${isReschedule ? 'Reschedule' : 'Schedule'}`}
          stretch
          style={{ marginTop: 16 }}
        />
        {!room?.isEAP && (
          <CancellationPolicyMessage
            dataQa="confirmBookingRecurringClientCancellationPolicy"
            onCancellationPolicyPress={onClickCancellationPolicy}
            style={{ marginTop: 16 }}
          />
        )}
      </BottomButtonContainer>
    </>
  );
};

export default ConfirmBookingRecurringClient;
