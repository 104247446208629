import { FunctionComponent } from 'react';
import { useEmotionTheme } from '@talkspace/react-toolkit';
import Checkmark from '../Icons/Checkmark';

interface Props {
  ackLevel: number;
}

const ReadReceiptCheckmarks: FunctionComponent<Props> = ({ ackLevel }) => {
  const { colors } = useEmotionTheme();
  switch (ackLevel) {
    case 3:
      return (
        <>
          <Checkmark style={{ marginRight: -6 }} />
          <Checkmark style={{ zIndex: -1 }} />
        </>
      );
    case 2:
      return (
        <>
          <Checkmark color={colors.fordGrey} style={{ marginRight: -6 }} />
          <Checkmark color={colors.fordGrey} style={{ zIndex: -1 }} />
        </>
      );
    default:
      return <Checkmark color={colors.fordGrey} />;
  }
};

export default ReadReceiptCheckmarks;
