import { useShareForwardedRef } from '@talkspace/react-toolkit';
import { ChangeEvent, forwardRef } from 'react';
import styled from '@/core/styled';

interface FileUploaderProps {
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

const Input = styled.input({
  display: 'none !important', // hack to solve bootstrap global input[type=file] { display: block }
});

const FileUploader = forwardRef<HTMLInputElement, FileUploaderProps>(({ onChange }, ref) => {
  const inputRef = useShareForwardedRef(ref);
  return (
    <Input
      ref={inputRef}
      type="file"
      id="media"
      name="media"
      accept="application/pdf"
      onChange={onChange}
    />
  );
});

export default FileUploader;
