import { useQuery } from 'react-query';
import { ProgressNoteModality, TaskType } from 'components/Dashboard/types';
import apiWrapper from '../../utils/apiWrapper';
import apiHelper from '../../utils/api';
import dashboardKeys from './queryKeys';

export interface TasksResponse {
  taskID: number;
  roomID: number;
  clientUserID: number;
  therapistID: number;
  progressNoteID: number | null;
  type: TaskType;
  firstName: string;
  lastName: string;
  task: {
    isEAP?: boolean;
    isBH?: boolean;
    isB2B?: boolean;
    reopenedAt?: string | null;
    canReopenUntil?: string | null;
    lockedAt?: string | null;
    progressNoteModality?: ProgressNoteModality | null;
    clientNickname?: string;
    clientFirstName?: string;
    clientLastName?: string;
    sessionReportID?: number;
    caseID?: number;
    videoCallStartedAt?: string | null;
    videoCallID?: number | null;
    bookingID?: number;
    canReopen?: boolean;
    draftProgressNoteID?: number;
  };
  eventDate: string;
  createdAt: string;
  id?: string;
}

const fetchTasks = (therapistID: number) => async () => {
  const { data } = await apiWrapper.get(
    `${apiHelper().apiEndpoint}/v2/therapists/${therapistID}/tasks`
  );
  return data.data;
};

const useQueryTaskList = (therapistID: number) =>
  useQuery<TasksResponse[], Error>({
    queryKey: dashboardKeys.tasklist(therapistID),
    queryFn: fetchTasks(therapistID),
    cacheTime: Infinity,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: true,
    refetchOnMount: true,
  });

export default useQueryTaskList;
