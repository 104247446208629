export function getString(): Promise<string> {
  if (navigator.clipboard) {
    return navigator.clipboard.readText();
  }
  return Promise.resolve('');
}

export function setString(str: string, useOldAPI = false): Promise<boolean> {
  if (navigator.clipboard && !useOldAPI) {
    // NOTE: To get the user's permission to use the new API, this method must be called
    // inside a click event listener. If done asynchronously or due to a render change,
    // this will throw a permissions error.
    return navigator.clipboard
      .writeText(str)
      .then(() => true)
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Could not copy text to Clipboard', error);
        return false;
      });
  }
  // NOTE: If this method fails, make sure the FocusTrap is disabled before using it.
  const tempTextArea = document.createElement('textarea');
  document.body.appendChild(tempTextArea);
  tempTextArea.value = str;
  tempTextArea.select();
  const success = document.execCommand('copy');
  document.body.removeChild(tempTextArea);
  return Promise.resolve(success);
}

export default {
  getString,
  setString,
};
