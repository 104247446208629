import View from '../View';
import styled, { EmotionStyle } from '../../core/styled';

const TooltipContainer = styled(View)<{ containerStyle?: EmotionStyle }>(
  ({ theme: { colors }, containerStyle }) => {
    return {
      borderRadius: 6,
      background: colors.purple600,
      position: 'absolute',
      height: 40,
      top: -43,
      zIndex: 10,
      right: 5,
      left: -6,
      width: 'fit-content',
      textAlign: 'center',
      ...containerStyle,
    };
  }
);

const Content = styled(View)(({ theme: { colors } }) => {
  return {
    position: 'relative',
    width: '100%',
    height: '100%',
    padding: '10px 14px',
    color: colors.white,
    fontSize: 15,
    fontWeight: 500,
    '&:after': {
      content: "''",
      position: 'absolute',
      top: '100%',
      left: '50%',
      marginLeft: -5,
      borderWidth: 5,
      borderStyle: 'solid',
      borderColor: `${colors.purple600} transparent transparent transparent`,
    },
  };
});

interface HoverInfoBoxProps {
  toolTipText: string | React.ReactNode;
  isTooltipOpen?: boolean;
  styles?: EmotionStyle;
}

const HoverInfoBox = ({ toolTipText, isTooltipOpen, styles, ...otherProps }: HoverInfoBoxProps) => {
  if (!isTooltipOpen) {
    return null;
  }
  return (
    <TooltipContainer containerStyle={{ ...styles }}>
      <Content {...otherProps}>{toolTipText}</Content>
    </TooltipContainer>
  );
};

export default HoverInfoBox;
