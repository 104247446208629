import {
  ChatModality,
  AudioModality,
  VideoModality,
  ExclamationSquare,
  SquareCheckMark,
} from '../icons';
import View from '../View';
import TouchableView from '../TouchableView';
import { ExtraTiny, Mini } from '../Typography';
import styled, { useEmotionTheme, EmotionTheme } from '../../core/styled';
import { LiveSessionModality } from '../../../../../shared/ts-frontend/types';

type BookingState =
  | 'tentative'
  | 'declined'
  | 'confirmed'
  | 'cancelled'
  | 'cancel_by_customer'
  | 'completed'
  | 'new_client_hold';

type BookingStatus =
  | 'active'
  | 'completed'
  | 'therapist-no-show'
  | 'client-no-show'
  | 'never-confirmed'
  | 'client-canceled'
  | 'therapist-canceled'
  | 'admin-canceled'
  | 'completed-after-late-start';
type ScheduledByUserType = 'client' | 'provider';

const IconWrapper = styled(View)({
  marginTop: 2,
  marginRight: 2,
  width: 11,
  height: 11,
});

const IconWrapperMini = styled(View)({
  marginTop: 1,
  marginRight: 2,
  width: 11,
  height: 7,
});

const ExtraTinyInherit = styled(ExtraTiny)({
  color: 'inherit',
  fontWeight: 400,
  lineHeight: '14px',
});

const ExtraTinyInheritBold = styled(ExtraTiny)({
  color: 'inherit',
  fontWeight: 700,
  lineHeight: '14px',
});

const ExtraMiniInheritBold = styled(Mini)({
  fontSize: 10,
  color: 'inherit',
  fontWeight: 700,
  lineHeight: '9px',
});

const ExtraMini = styled(Mini)(({ theme: { colors } }) => {
  return {
    fontSize: 10,
    fontWeight: 400,
    color: colors.permaOuterSpace,
    lineHeight: '9px',
  };
});

const BaseBooking = styled(View)({
  borderStyle: 'solid',
  borderWidth: 1,
  boxSizing: 'border-box',
  position: 'relative',
  paddingLeft: 5,
  borderRadius: 5,
  zIndex: 5,
});

const ConfirmedBooking = styled(BaseBooking)(({ theme: { colors } }) => {
  return {
    borderColor: colors.permaEucalyptus,
    backgroundColor: colors.permaEucalyptus,
    color: colors.black,
    boxShadow: '0 4px 14px 0 rgba(11, 0, 81, 0.13)',
  };
});

const ConfirmedPastBooking = styled(BaseBooking)(({ theme: { colors } }) => {
  return {
    borderColor: colors.permaMagicMint,
    backgroundColor: colors.permaMagicMint,
    color: colors.permaBotanicalGreen,
  };
});

const NoShowBooking = styled(BaseBooking)(({ theme: { colors } }) => {
  return {
    borderColor: colors.permaGrape,
    backgroundColor: colors.permaMagnolia,
    color: colors.permaDeepViolet,
  };
});

const NoShowPastBooking = styled(BaseBooking)(({ theme: { colors } }) => {
  return {
    borderColor: colors.permaPastelPurple,
    backgroundColor: colors.permaMagnolia,
    color: colors.permaLavender,
  };
});

const TentativeBooking = styled(BaseBooking)(({ theme: { colors } }) => {
  return {
    borderColor: colors.permaOceanGreen,
    backgroundColor: colors.permaClearDay,
    color: colors.permaSeaGreen,
    boxShadow: '0 4px 14px 0 rgba(11, 0, 81, 0.13)',
  };
});

const BreakSpaceFiller = styled(View)(({ theme: { colors } }) => {
  return {
    backgroundColor: colors.permaSolitude,
    color: colors.permaOuterSpace,
    height: 10,
    marginTop: -5,
    position: 'relative',
    marginBottom: -5,
  };
});

const BreakWrapper = styled(View)(({ theme: { colors } }) => {
  return {
    backgroundColor: colors.permaSolitude,
    color: colors.permaOuterSpace,
    paddingLeft: 5,
    borderRadius: 5,
    zIndex: 10,
    position: 'relative',
  };
});

const BreakWrapperMini = styled(View)(({ theme: { colors } }) => {
  return {
    backgroundColor: colors.permaSolitude,
    color: colors.permaOuterSpace,
    paddingLeft: 5,
    height: 10,
    borderRadius: 5,
    zIndex: 10,
    position: 'relative',
  };
});
interface BookingGroupWrapperProps {
  isActive?: boolean;
}
const BookingGroupWrapper = styled(TouchableView)(({ isActive }: BookingGroupWrapperProps) => {
  return {
    width: '95%',
    cursor: 'pointer',
    marginTop: -2,
    marginLeft: -4,
    zIndex: 20,
    boxShadow: isActive ? '0px 5px 15px rgba(0, 10, 45, 0.55)' : undefined,
    position: 'relative',
    borderRadius: 5,
  };
});

const getIsNoShow = (bookingStatus) =>
  bookingStatus === 'client-no-show' || bookingStatus === 'therapist-no-show';

const getIsCompleted = (bookingState, bookingStatus) =>
  bookingState === 'completed' && bookingStatus === 'completed';

const getIconColor = (
  bookingState: BookingState,
  bookingStatus: BookingStatus,
  isPastBooking: boolean,
  colors: EmotionTheme['colors']
): string => {
  if (getIsNoShow(bookingStatus) && isPastBooking) {
    return colors.permaRhythmPurple;
  }
  if (getIsNoShow(bookingStatus)) {
    return colors.permaDeepViolet;
  }
  if (bookingState === 'confirmed' && isPastBooking) {
    return colors.permaBotanicalGreen;
  }
  if (bookingState === 'confirmed') {
    return colors.black; // Should be at 70% opacity
  }
  if (bookingState === 'tentative') {
    return colors.permaKelpGreen;
  }
  if (bookingState === 'new_client_hold') {
    return colors.permaKelpGreen;
  }
  return colors.permaKelpGreen;
};

const getWrapperComponent = (
  bookingState: BookingState,
  bookingStatus: BookingStatus,
  isPastBooking: boolean
) => {
  if (getIsNoShow(bookingStatus) && isPastBooking) {
    return NoShowPastBooking;
  }
  if (getIsNoShow(bookingStatus)) {
    return NoShowBooking;
  }
  if (
    (bookingState === 'confirmed' || getIsCompleted(bookingState, bookingStatus)) &&
    isPastBooking
  ) {
    return ConfirmedPastBooking;
  }
  if (
    bookingState === 'confirmed' ||
    // a credit may be redeemed before the booking start time
    // resulting in a completed future booking. Keep it as the confirmed style
    (getIsCompleted(bookingState, bookingStatus) && !isPastBooking)
  ) {
    return ConfirmedBooking;
  }
  if (bookingState === 'tentative' || bookingState === 'new_client_hold') {
    return TentativeBooking;
  }
  return TentativeBooking;
};

const getBookingHeight = (duration) => {
  if (duration <= 10) return 11;
  if (duration <= 30) return 21;
  if (duration <= 45) return 31;
  return 42;
};

const getBreakHeight = (duration) => {
  if (duration <= 30) return 10;
  if (duration <= 45) return 10;
  return 10;
};

const getModalityIcon = (
  modality: LiveSessionModality,
  bookingStatus: BookingStatus,
  showStatus: boolean
) => {
  if (getIsNoShow(bookingStatus) && !showStatus) return ExclamationSquare;
  if (modality === 'chat') return ChatModality;
  if (modality === 'video') return VideoModality;
  if (modality === 'audio') return AudioModality;
  return VideoModality;
};
interface BookingStatusDisplayProps {
  bookingState: BookingState;
  bookingStatus: BookingStatus;
  duration: number;
  iconColor: string;
  isPastBooking: boolean;
}

const BookingStatusDisplay = (props: BookingStatusDisplayProps) => {
  const { bookingState, bookingStatus, duration, iconColor, isPastBooking } = props;
  if (getIsNoShow(bookingStatus)) {
    return (
      <View row>
        <IconWrapper>
          <ExclamationSquare color={iconColor} />
        </IconWrapper>
        <ExtraTinyInherit style={{ overflow: 'hidden', whiteSpace: 'nowrap' }} inline>
          No-show
        </ExtraTinyInherit>
      </View>
    );
  }
  if (getIsCompleted(bookingState, bookingStatus) && isPastBooking) {
    return (
      <View row>
        <IconWrapper>
          <SquareCheckMark />
        </IconWrapper>
        <ExtraTinyInherit style={{ overflow: 'hidden', whiteSpace: 'nowrap' }} inline>
          Occurred
        </ExtraTinyInherit>
      </View>
    );
  }
  if (bookingState === 'new_client_hold' && !isPastBooking) {
    return (
      <ExtraTinyInherit style={{ overflow: 'hidden', whiteSpace: 'nowrap' }} inline>
        New client hold
      </ExtraTinyInherit>
    );
  }
  if (bookingState === 'tentative' && !isPastBooking) {
    return (
      <ExtraTinyInherit style={{ overflow: 'hidden', whiteSpace: 'nowrap' }} inline>
        Unconfirmed
      </ExtraTinyInherit>
    );
  }
  return (
    <View row>
      <ExtraTinyInherit style={{ overflow: 'hidden', whiteSpace: 'nowrap' }} inline>
        {duration}min
      </ExtraTinyInherit>
    </View>
  );
};

interface BookingClient {
  firstName: string;
  lastName: string;
  pseudonym: string;
  userID: number;
  isPrimaryParticipant: boolean;
}

interface CalendarBookingProps {
  bookingState: BookingState;
  bookingStatus: BookingStatus;
  endDate: Date;
  duration: number;
  hasBreakAfterSession?: boolean;
  isActive?: boolean;
  onClick?: () => void;
  scheduledByUserType: ScheduledByUserType;
  modality: LiveSessionModality;
  primaryClient: BookingClient;
}

const CalendarBooking = (props: CalendarBookingProps) => {
  const {
    bookingState,
    bookingStatus,
    endDate,
    duration,
    modality,
    hasBreakAfterSession,
    isActive,
    onClick,
    scheduledByUserType,
    primaryClient: { firstName, lastName, pseudonym },
  } = props;
  const { colors } = useEmotionTheme();
  const isPastBooking = endDate < new Date();
  const iconColor = getIconColor(bookingState, bookingStatus, isPastBooking, colors);
  const BookingWrapper = getWrapperComponent(bookingState, bookingStatus, isPastBooking);
  const bookingHeight = getBookingHeight(duration);
  const showStatus = duration >= 45;
  const ModalityIcon = getModalityIcon(modality, bookingStatus, showStatus);
  const nameText = [firstName, lastName].filter(Boolean).join(' ') || pseudonym;

  if (duration <= 10) {
    return (
      <BookingGroupWrapper isActive={isActive} onPress={onClick}>
        <BookingWrapper style={{ height: bookingHeight }}>
          <View row>
            <IconWrapperMini>
              <ModalityIcon color={iconColor} height={9} width={9} />
            </IconWrapperMini>
            <ExtraMiniInheritBold style={{ overflow: 'hidden', whiteSpace: 'nowrap' }} inline>
              {nameText}
            </ExtraMiniInheritBold>
          </View>
        </BookingWrapper>
        {hasBreakAfterSession ? (
          <>
            <BreakSpaceFiller />
            <BreakWrapperMini>
              <ExtraMini style={{ overflow: 'hidden', whiteSpace: 'nowrap' }} inline>
                {bookingState === 'tentative' && scheduledByUserType === 'client'
                  ? 'Break (optional)'
                  : 'Break'}
              </ExtraMini>
            </BreakWrapperMini>
          </>
        ) : null}
      </BookingGroupWrapper>
    );
  }

  const breakHeight = hasBreakAfterSession ? getBreakHeight(duration) : 0;

  return (
    <BookingGroupWrapper isActive={isActive} onPress={onClick}>
      <BookingWrapper style={{ height: bookingHeight }}>
        <View row>
          <IconWrapper>
            <ModalityIcon color={iconColor} height={11} width={11} />
          </IconWrapper>
          <ExtraTinyInheritBold style={{ overflow: 'hidden', whiteSpace: 'nowrap' }} inline>
            {nameText}
          </ExtraTinyInheritBold>
        </View>
        {showStatus && (
          <BookingStatusDisplay
            bookingState={bookingState}
            bookingStatus={bookingStatus}
            duration={duration}
            isPastBooking={isPastBooking}
            iconColor={iconColor}
          />
        )}
      </BookingWrapper>
      {hasBreakAfterSession ? (
        <>
          <BreakSpaceFiller />
          <BreakWrapper style={{ height: breakHeight }}>
            <ExtraMini inline>
              {bookingState === 'tentative' && scheduledByUserType === 'client'
                ? 'Break (optional)'
                : 'Break'}
            </ExtraMini>
          </BreakWrapper>
        </>
      ) : null}
    </BookingGroupWrapper>
  );
};

export default CalendarBooking;
