import { FunctionComponent, useEffect } from 'react';
import { keyframes } from '@emotion/core';

import TouchableView from '../TouchableView';
import CloseButton from '../CloseButton';
import { useWindowWidthState } from '../../hooks/windowWidthContext';
import styled, { EmotionStyle, useEmotionTheme } from '../../core/styled';

export type AnimationSize = 'small' | 'medium' | 'large';
export interface ModalProps {
  handleClose: () => void;
  backgroundStyle?: EmotionStyle;
  style?: EmotionStyle;
  escapeCloses?: boolean;
  dataQa?: string;
  mobileContainerStyle?: EmotionStyle;
  animationSize?: AnimationSize;
  isReactivationTray?: boolean;
}

const slideInSm = keyframes`
0% {
  bottom: -30%;
 }
 100% {
  bottom: 0;
 }
`;

const slideInMd = keyframes`
0% {
  bottom: -50%;
 }
 100% {
  bottom: 0;
 }
`;

const slideInLg = keyframes`
0% {
  bottom: -100%;
 }
 100% {
  bottom: 0;
 }
`;

const getKeyframes = (animationSize?: AnimationSize) => {
  if (animationSize === 'small') return slideInSm;
  if (animationSize === 'medium') return slideInMd;
  if (animationSize === 'large') return slideInLg;
  return null;
};

const BackgroundWrapper = styled(TouchableView)(() => {
  return {
    backgroundColor: `rgba(0, 0, 0, 0.5)`,
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 999,
    textAlign: 'center',
    cursor: 'default',
  };
});

const ContentView = styled(TouchableView)<{
  isMobile?: boolean;
  mobileContainerStyle?: EmotionStyle;
  animationSize?: AnimationSize;
  isReactivationTray?: boolean;
}>(({ isMobile, mobileContainerStyle, animationSize, isReactivationTray, theme: { colors } }) => {
  const keyFrame = getKeyframes(animationSize);
  const mobileStyle = {
    borderTopLeftRadius: isReactivationTray ? 24 : 10,
    borderTopRightRadius: isReactivationTray ? 24 : 10,
    padding: 20,
    width: '100%',
    position: 'absolute',
    bottom: 0,
    paddingTop: isReactivationTray ? 35 : 72,
    paddingBottom: isReactivationTray ? 48 : 81,
    paddingLeft: 23,
    paddingRight: 23,
    animation: keyFrame && `${keyFrame} 300ms ease-out both`,
    zIndex: 1000,
    ...mobileContainerStyle,
  };
  const reactivationOrRegularTrayStyle = isReactivationTray
    ? {
        borderRadius: 12,
        paddingTop: 24,
        paddingBottom: 48,
        paddingLeft: 16,
        paddingRight: 16,
        maxWidth: 375,
        alignSelf: 'center',
        margin: 'auto',
      }
    : { borderRadius: 10, padding: 80, width: 500, alignSelf: 'center', margin: 'auto' };
  return {
    backgroundColor: colors.white,
    cursor: 'default',
    ...(isMobile ? mobileStyle : reactivationOrRegularTrayStyle),
  };
});

const ReactivationTrayCloseButton = ({ handleClose, dataQa, isMobile, colorRoles }) => (
  <CloseButton
    onPress={handleClose}
    style={{ marginTop: 5, borderRadius: 5 }}
    dataQa={dataQa}
    wrapperStyle={{
      position: isMobile ? 'absolute' : 'relative',
      top: isMobile ? 10 : -10,
      right: isMobile ? 10 : -150,
    }}
    backgroundColor={colorRoles.icons.iconInverseDefault}
    color={colorRoles.icons.iconBrandDefault}
    height={42}
    width={42}
  />
);

const Tray: FunctionComponent<ModalProps> = ({
  children,
  handleClose,
  style,
  backgroundStyle,
  escapeCloses = false,
  mobileContainerStyle,
  dataQa,
  animationSize,
  isReactivationTray,
}) => {
  const { isMobile } = useWindowWidthState();
  const { colorRoles } = useEmotionTheme();

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (escapeCloses) {
      const close = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
          handleClose();
        }
      };
      window.addEventListener('keydown', close);
      return () => window.removeEventListener('keydown', close);
    }
  }, [handleClose, escapeCloses]);

  return (
    <BackgroundWrapper onPress={handleClose} style={backgroundStyle}>
      <ContentView
        onPress={(e) => e.stopPropagation()}
        isMobile={isMobile}
        align="center"
        style={style}
        data-qa={dataQa}
        mobileContainerStyle={mobileContainerStyle}
        animationSize={animationSize}
        isReactivationTray={isReactivationTray}
      >
        {isReactivationTray && (
          <ReactivationTrayCloseButton {...{ handleClose, dataQa, isMobile, colorRoles }} />
        )}
        {children}
      </ContentView>
    </BackgroundWrapper>
  );
};

export default Tray;
