import { useQuery } from 'react-query';
import { NoteStatus, NoteType } from 'components/Room/CRMContainer/NotesTab/types';
import apiHelper from '@/utils/api';
import apiWrapper from '../../utils/apiWrapper';
import { notesListQueryKey } from './queryKeys';

export type SessionReportStatus = 'awaiting' | 'completed' | 'open' | 'cancelled' | 'scheduled';

export interface NoteSessionReportData {
  id: number;
  status: SessionReportStatus;
  caseID: number;
  position: number;
  modalityID: number | null;
  startDate: string | null;
  endDate: string | null;
  videoCallID: number | null;
  reopenedAt: string | null;
  progressNoteID: number | null;
}

export interface NotesQueryResponse {
  items: NoteItem[];
  currentSessionReport: NoteSessionReportData | null;
  warningMessage: string | null;
  isClientDischarged: boolean;
}

export interface NoteItem {
  id: string;
  noteID: number;
  roomID: number;
  startDate: string;
  endDate: string | null;
  previewText: string | null;
  status: NoteStatus;
  authorName: string;
  authorAvatar: string;
  type: NoteType;
  sessionReportData: NoteSessionReportData | null;
  canReopen: boolean;
  modalityID?: number;
  isEAPNewNoteItem?: boolean;
}

const fetchNotes = (roomID: string) => async () => {
  const { data } = await apiWrapper.get(`${apiHelper().apiEndpoint}/v2/rooms/${roomID}/notes`);
  return data.data;
};

const useQueryNotes = (roomID: string, enabled: boolean = true) =>
  useQuery<NotesQueryResponse, Error>({
    queryKey: notesListQueryKey(roomID),
    queryFn: fetchNotes(roomID),
    cacheTime: Infinity,
    refetchOnMount: 'always',
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
    enabled,
  });

export default useQueryNotes;
