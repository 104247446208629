// @TODO: @jkredo - Why are we modifying the `formerValues` and `brickOptions` props of CheckIn?
/* eslint-disable no-param-reassign */
import { FunctionComponent, useState, useEffect, useRef } from 'react';

import {
  View,
  Big,
  ExtraHuge,
  Large,
  Standard,
  StickyDrawer,
  Tiny,
  BaseButton,
  TextArea,
  Button,
  RatingStars,
  BricksSelector,
  BrickSelectorState,
  useThemeVersion,
  useWindowWidthState,
  StickyDrawerParentContainer,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import styled from '@/core/styled';
import { webOnlyStyle } from '@/core/styled/styleHelpers';
import { useWizardState } from '../../hooks/wizardContext';

const TitlesView = styled(View)({
  minHeight: 156,
  textAlign: 'center',
  width: 'auto',
});

const SecondaryButton = styled(BaseButton)<{ isContinueButtonShown?: boolean }>(
  ({ isContinueButtonShown = false, theme: { colors } }) => {
    return {
      marginTop: isContinueButtonShown ? 60 : 20,
      marginBottom: 20,
      padding: 0,
      backgroundColor: colors.white,
    };
  }
);

const ButtonsContainer = ({
  children,
  isSticky,
}: {
  children: React.ReactNode;
  isSticky: boolean;
}) => {
  if (isSticky) {
    return <StickyDrawer noBorder>{children}</StickyDrawer>;
  }
  return <View style={{ marginTop: 40 }}>{children}</View>;
};

const FreeTextInput = styled(TextArea)(({ theme: { colors } }) => {
  return {
    height: 130,
    width: '100%',
    marginTop: 8,
    borderRadius: 10,
    borderStyle: 'solid',
    borderColor: colors.periwinkleGrey,
    borderWidth: 1,
    padding: 20,
    resize: 'none',
    ...webOnlyStyle({
      boxSizing: 'border-box',
      outline: 'none',
      caretColor: colors.green,
      '&:hover:focus': {
        outline: 'none',
      },
      '::placeholder': {
        color: colors.placeholderGrey,
        fontSize: 16,
      },
    }),
  };
});

function getTitle(rating: number) {
  const result = {
    titleFirstRow: '',
    titleSecondRow: '',
    subtitle: 'Your rating is anonymous and contributes to your provider’s overall rating',
  };
  switch (rating) {
    case 1:
    case 2:
    case 3:
      result.titleFirstRow = 'Sorry to hear it. Which areas could be better?';
      break;
    case 4:
      result.titleFirstRow = 'Which areas could be better?';
      break;
    case 5:
      result.titleFirstRow = 'Glad to hear it!';
      result.titleSecondRow = "What's been going well?";
      break;
  }
  return result;
}

interface CheckInData {
  rating: number;
  bricks: string[];
  otherText: string;
}

interface Props {
  formerValues?: CheckInData;
  brickOptions?: BrickSelectorState[];
  handleOnSubmit: (checkInPayload: CheckInData) => void;
}

const CheckIn: FunctionComponent<Props> = ({ formerValues, brickOptions = [], handleOnSubmit }) => {
  const { isMobile } = useWindowWidthState();
  const { major: themeVersion } = useThemeVersion();
  const { colorRoles } = useEmotionTheme();
  const [rating, setRating] = useState(1);
  const [currentTitleFirstRow, setCurrentTitleFirstRow] = useState('');
  const [currentTitleSecondRow, setCurrentTitleSecondRow] = useState<String | undefined>('');
  const [currentSubtitle, setCurrentSubtitle] = useState('');
  const [currentSelectedBricks, setCurrentSelectedBricks] = useState<BrickSelectorState[]>([]);
  const [bricks, setBricks] = useState<BrickSelectorState[]>([]);
  const [freeText, setFreeText] = useState('');
  const [showFreeTextInput, setShowFreeTextInput] = useState(false);
  const [showContinueButton, setShowContinueButton] = useState(false);
  const { nextBookingStartTime } =
    useWizardState<{ nextBookingStartTime?: string; wizardVersion: number }>();

  const freeTextInputRef = useRef<HTMLTextAreaElement>(null);

  const formatBrickOptionsToState = () =>
    brickOptions.map((brick) => {
      return { ...brick, isSelected: false };
    });

  const resetBricksState = () => {
    setCurrentSelectedBricks([]);
    setBricks(formatBrickOptionsToState());
    setShowFreeTextInput(false);
    setShowContinueButton(false);
    if (formerValues) {
      formerValues.bricks = [];
    }
  };

  const handleOnRatingStarClick = (value: number) => {
    setRating(value);
    if (brickOptions.length && value !== rating) {
      resetBricksState();
    }
  };

  const createCheckInPayload = () => {
    return {
      rating,
      bricks: currentSelectedBricks.map((brick) => brick.value),
      otherText: freeText,
      nextBookingStartTime,
    };
  };

  const handleContinueButtonClicked = () => {
    const payload = createCheckInPayload();
    handleOnSubmit(payload);
  };

  const handleBricksChange = (updatedBricks: BrickSelectorState[], isOtherSelected: boolean) => {
    const selectedBricks = updatedBricks.filter((brick) => brick.isSelected);
    setCurrentSelectedBricks(selectedBricks);
    if (selectedBricks.length) {
      setShowContinueButton(true);
    } else setShowContinueButton(false);
    if (isOtherSelected) {
      setShowFreeTextInput(true);
    } else {
      setShowFreeTextInput(false);
    }
  };

  useEffect(() => {
    if (freeText) {
      setShowContinueButton(true);
    }
  }, [freeText]);

  useEffect(() => {
    const { titleFirstRow, titleSecondRow, subtitle } = getTitle(rating);
    setCurrentTitleFirstRow(titleFirstRow);
    setCurrentTitleSecondRow(titleSecondRow);
    setCurrentSubtitle(subtitle);
  }, [rating]);

  useEffect(() => {
    if (freeTextInputRef && freeTextInputRef.current && showFreeTextInput) {
      freeTextInputRef.current.focus();
    }
    if (!showFreeTextInput) {
      setFreeText('');
    }
  }, [freeTextInputRef, showFreeTextInput]);

  useEffect(() => {
    const setInitialState = () => {
      if (brickOptions.length) {
        setShowFreeTextInput(false);
        if (formerValues) {
          if (formerValues.bricks && formerValues.bricks.length) {
            const tempSelectedBricks: BrickSelectorState[] = [];
            brickOptions.forEach((brickOption) => {
              if (formerValues.bricks.includes(brickOption.value)) {
                brickOption.isSelected = true;
                tempSelectedBricks.push(brickOption);
                if (brickOption.value === 'other') {
                  setShowFreeTextInput(true);
                }
              } else brickOption.isSelected = false;
            });
            setCurrentSelectedBricks(tempSelectedBricks);
            setBricks(brickOptions);
            setShowContinueButton(true);
          }
          if (formerValues.otherText) {
            setShowContinueButton(true);
            setFreeText(formerValues.otherText);
          }
        } else setBricks(formatBrickOptionsToState());
      } else {
        setShowFreeTextInput(true);
        setShowContinueButton(true);
      }
      setRating(formerValues ? formerValues.rating : rating);
    };
    setInitialState();
  }, [brickOptions, formerValues]); // eslint-disable-line react-hooks/exhaustive-deps

  const showButtonsInStickyDrawer = isMobile && themeVersion >= 1;
  const ctaCopy = rating > 3 ? 'Done' : 'Continue';
  return (
    <StickyDrawerParentContainer style={{ textAlign: 'center' }}>
      <RatingStars initialRating={rating} getRatingValue={handleOnRatingStarClick} />
      <TitlesView>
        <ExtraHuge style={{ marginTop: 24 }}>{currentTitleFirstRow}</ExtraHuge>
        <ExtraHuge>{currentTitleSecondRow}</ExtraHuge>
        <Large style={{ marginTop: 20, marginBottom: 20, color: colorRoles.typography.textSubtle }}>
          {currentSubtitle}
        </Large>
      </TitlesView>
      {bricks.length > 0 && <BricksSelector onChange={handleBricksChange} bricks={bricks} />}
      {showFreeTextInput && (
        <View style={{ marginTop: 16 }} justify="start">
          <Tiny style={{ width: 36, color: colorRoles.typography.textSubtle }}>Other</Tiny>
          <FreeTextInput ref={freeTextInputRef} value={freeText} onChangeText={setFreeText} />
        </View>
      )}
      <ButtonsContainer isSticky={showButtonsInStickyDrawer}>
        {showContinueButton && (
          <Button onPress={handleContinueButtonClicked} style={{ width: 320 }}>
            <Big variant="bigWide">{ctaCopy}</Big>
          </Button>
        )}
        {!showContinueButton && (
          <SecondaryButton onPress={handleContinueButtonClicked}>
            <Standard variant="standardDarkGrey">Skip</Standard>
          </SecondaryButton>
        )}
      </ButtonsContainer>
    </StickyDrawerParentContainer>
  );
};

export default CheckIn;
