import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const standardPath =
  'M10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18ZM8.75 12.5H9.5V10.5H8.75C8.33437 10.5 8 10.1656 8 9.75C8 9.33437 8.33437 9 8.75 9H10.25C10.6656 9 11 9.33437 11 9.75V12.5H11.25C11.6656 12.5 12 12.8344 12 13.25C12 13.6656 11.6656 14 11.25 14H8.75C8.33437 14 8 13.6656 8 13.25C8 12.8344 8.33437 12.5 8.75 12.5ZM10 6C10.2652 6 10.5196 6.10536 10.7071 6.29289C10.8946 6.48043 11 6.73478 11 7C11 7.26522 10.8946 7.51957 10.7071 7.70711C10.5196 7.89464 10.2652 8 10 8C9.73478 8 9.48043 7.89464 9.29289 7.70711C9.10536 7.51957 9 7.26522 9 7C9 6.73478 9.10536 6.48043 9.29289 6.29289C9.48043 6.10536 9.73478 6 10 6Z';

const pathConfig: IconConfig = {
  standard: {
    default: {
      path: standardPath,
    },
  },
};

const CircleInfo = withDSIconMaker(pathConfig, 'letter i inside circle');

export default CircleInfo;
