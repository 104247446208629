import apiHelper from '@/core/api/apiHelper';
import apiWrapper from '@/core/api/apiWrapper';

interface UserDataForLD {
  key: string;
  custom: Record<string, any>;
}

/**
 * api call to: GET `/v3/users/${clientUserID}/client-user-data-for-ld`
 * @returns Promise
 */
function getClientUserForLD(clientUserID: number): Promise<UserDataForLD> {
  return apiWrapper
    .get(`${apiHelper().apiEndpoint}/v3/users/${clientUserID}/client-user-data-for-ld`, {
      shouldIgnore401: true,
    })
    .then((res) => res.data.data);
}

/**
 * api call to: GET `/v3/users/${therapistUserID}/therapist-user-data-for-ld`
 * @returns Promise
 */
function getTherapistUserForLD(therapistUserID: number): Promise<UserDataForLD> {
  return apiWrapper
    .get(`${apiHelper().apiEndpoint}/v3/users/${therapistUserID}/therapist-user-data-for-ld`, {
      shouldIgnore401: true,
    })
    .then((res) => res.data.data);
}

const launchDarklyApiHelper = {
  getClientUserForLD,
  getTherapistUserForLD,
};

export default launchDarklyApiHelper;
