export const REQUEST_GET_JOURNEY_ITEMS = 'REQUEST_GET_JOURNEY_ITEMS';
export const REQUEST_GET_JOURNEY_ITEMS_ERROR = 'REQUEST_GET_JOURNEY_ITEMS_ERROR';
export const RECEIVE_GET_JOURNEY_ITEMS = 'RECEIVE_GET_JOURNEY_ITEMS';

export const REQUEST_PATCH_JOURNEY_ITEM = 'REQUEST_PATCH_JOURNEY_ITEM';
export const REQUEST_PATCH_JOURNEY_ITEM_ERROR = 'REQUEST_PATCH_JOURNEY_ITEM_ERROR';
export const RECEIVE_PATCH_JOURNEY_ITEM = 'RECEIVE_PATCH_JOURNEY_ITEM';

export const REQUEST_PATCH_FEEDBACK_JOURNEY_ITEM = 'REQUEST_PATCH_FEEDBACK_JOURNEY_ITEM';
export const REQUEST_PATCH_FEEDBACK_JOURNEY_ITEM_ERROR =
  'REQUEST_PATCH_FEEDBACK_JOURNEY_ITEM_ERROR';
export const RECEIVE_PATCH_FEEDBACK_JOURNEY_ITEM = 'RECEIVE_PATCH_FEEDBACK_JOURNEY_ITEM';

export const REQUEST_POST_FEEDBACK_JOURNEY_ITEM = 'REQUEST_POST_FEEDBACK_JOURNEY_ITEM';
export const REQUEST_POST_FEEDBACK_JOURNEY_ITEM_ERROR = 'REQUEST_POST_FEEDBACK_JOURNEY_ITEM_ERROR';
export const RECEIVE_POST_FEEDBACK_JOURNEY_ITEM = 'RECEIVE_POST_FEEDBACK_JOURNEY_ITEM';

export const PREPARE_SESSION_SUMMARIZATION = 'PREPARE_SESSION_SUMMARIZATION';
export const CLEAR_SESSION_SUMMARIZATION = 'CLEAR_SESSION_SUMMARIZATION';

export const CLEAR_GUARD = 'CLEAR_GUARD';
