import {
  FunctionComponent,
  MouseEvent,
  FocusEvent,
  useEffect,
  useRef,
  useState,
  TouchEvent,
  VFC,
} from 'react';
import { PropsOf } from '@emotion/styled-base/types/helper';
import {
  View,
  TouchableView,
  Spinner,
  useEmotionTheme,
  TextDS,
  spacing,
} from '@talkspace/react-toolkit';
import { CirclePause, CirclePlay } from '@talkspace/react-toolkit/src/designSystems/icons';
import styled from '@/core/styled';
import Slider from '../Slider';
import X from '../Icons/X';

interface AudioPlayerViewProps {
  style?: {};
  audioUrl?: string;
  playing: boolean;
  loading: boolean;
  onPlay?: (event: MouseEvent<HTMLDivElement | MouseEvent> | KeyboardEvent) => void;
  maxDuration?: number;
  currentTime?: number;
  onPause?: (event: MouseEvent<HTMLDivElement | MouseEvent> | KeyboardEvent) => void;
  onCancel?: () => void | Promise<void>;
  setPlaying: (playing: boolean) => void;
  setMaxDuration: (newTime: number) => void;
  updateCurrentTime: (newTime: number) => void;
  sliderDisabled: boolean;
}

const getGradientBackgroundImage = (
  progress: number,
  filledColor: string,
  backgroundColor: string
) => {
  const backgroundImage = `-webkit-gradient(linear,0% 0%, 100% 0%,color-stop(${progress.toFixed(
    2
  )},${filledColor}),color-stop(${progress.toFixed(2)},${backgroundColor}))`;
  return backgroundImage;
};

const Wrapper = styled(View)<{ wrapperColor: string }>(({ wrapperColor }) => {
  return {
    position: 'relative',
    alignSelf: 'center',
    height: 56,
    borderRadius: spacing.space150,
    padding: spacing.space100,
    backgroundColor: wrapperColor,
    gap: spacing.space100,
    flexDirection: 'row',
    alignItems: 'center',
  };
});

const PlayToggleWrapper = styled(TouchableView)({
  width: 30,
  paddingLeft: 0,
  paddingRight: 0,
  marginRight: 10,
});

const PlayToggle: FunctionComponent<Partial<AudioPlayerViewProps> & { hide: boolean }> = ({
  hide,
  onPlay,
  onPause,
  playing,
}) =>
  hide ? null : (
    <PlayToggleWrapper
      role="button"
      aria-label={playing ? 'pause' : 'play'}
      onPress={playing ? onPause : onPlay}
      onTouchStart={(e) => e.stopPropagation()}
    >
      {playing ? (
        <CirclePause size="huge" colorType="brand" />
      ) : (
        <CirclePlay size="huge" colorType="brand" />
      )}
    </PlayToggleWrapper>
  );

type LoadingProps = {
  secondaryColor: string;
  primaryColor: string;
  hide: boolean;
};

const Loading: VFC<LoadingProps> = ({ hide, secondaryColor, primaryColor }) =>
  hide ? null : (
    <PlayToggleWrapper>
      <Spinner
        isLoading
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
        containerStyle={{ width: 35, height: 30 }}
      />
    </PlayToggleWrapper>
  );

const StyledSlider = styled(Slider)<
  PropsOf<typeof Slider> & {
    filledColor: string;
    backgroundColor: string;
    thumbSize: number;
    clicked: boolean;
    thumbColor: string;
  }
>(({ filledColor, thumbColor, backgroundColor, value, maxValue, thumbSize, clicked }) => {
  return {
    backgroundImage: getGradientBackgroundImage(value / maxValue, filledColor, backgroundColor),
    WebkitAppearance: 'none',
    height: 6,
    padding: 0,
    verticalAlign: 'middle',
    borderRadius: 6,
    display: 'inline-block',
    color: 'white',
    fontSize: '1.143rem',
    outline: clicked ? 'none' : undefined,
    '::-webkit-slider-thumb': {
      WebkitAppearance: 'none',
      background: thumbColor,
      width: thumbSize,
      height: thumbSize,
      borderRadius: '50%',
    },
    '::-moz-range-thumb': {
      width: thumbSize,
      height: thumbSize,
      background: 'white',
      borderRadius: '50%',
      borderColor: 'white',
    },
    '::-webkit-slider-runnable-track, ::-moz-range-track': {
      height: thumbSize,
    },
  };
});

const parseDuration = (durationSeconds?: number): string => {
  const dur = Math.round(durationSeconds || 0);
  if (!dur || dur < 0) return '0:00';
  const minutes = Math.floor(dur / 60);
  const seconds = dur - minutes * 60;
  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};

const Time: FunctionComponent<Partial<AudioPlayerViewProps>> = ({ currentTime }) => (
  <TextDS variant="bodyXs" colorRole="textSubtle" style={{ wordBreak: 'normal' }}>
    {parseDuration(currentTime)}
  </TextDS>
);

const CancelButtonWrapper = styled(TouchableView)(({ theme: { colorRoles } }) => {
  return {
    top: -8,
    right: -8,
    width: 20,
    height: 20,
    padding: 0,
    display: 'flex',
    borderRadius: 12,
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colorRoles.icons.iconBrandDefault,
    boxShadow: '0px 2px 3px 0px rgba(0,0,0,0.13)',
  };
});

const CancelButton = ({ onPress, hide }) => {
  const { colorRoles } = useEmotionTheme();
  return (
    <CancelButtonWrapper onPress={onPress} hide={hide} aria-label="cancel">
      <X color={colorRoles.surfaces.defaultSubtleDefault} width={7} height={7} />
    </CancelButtonWrapper>
  );
};

const AudioPlayerView: FunctionComponent<AudioPlayerViewProps> = ({
  style,
  onPlay,
  loading,
  playing,
  onPause,
  audioUrl,
  onCancel,
  setPlaying,
  setMaxDuration,
  updateCurrentTime,
  currentTime = 0,
  maxDuration = 0,
  sliderDisabled = false,
}) => {
  const SMALL_SLIDER_THUMB_SIZE = 12;
  const LARGE_SLIDER_THUMB_SIZE = 15;
  const { colorRoles } = useEmotionTheme();
  const wrapperColor = colorRoles.surfaces.brandSubtleDefault;
  const finalPrimaryColor = colorRoles.surfaces.brandDefault;
  const audioRef = useRef<HTMLAudioElement>(null);
  const [thumbSize, setThumbSize] = useState(SMALL_SLIDER_THUMB_SIZE);
  // controls the outline surrounding the slider for when a user is a keyboard user or not
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        audioRef.current?.pause();
        setPlaying(false);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [setPlaying]);

  useEffect(() => {
    const handleDeviceChange = () => {
      audioRef.current?.pause();
      setPlaying(false);
    };

    navigator.mediaDevices?.addEventListener('devicechange', handleDeviceChange);

    return () => {
      navigator.mediaDevices?.removeEventListener('devicechange', handleDeviceChange);
    };
  }, [setPlaying]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.ondurationchange = () => {
        if (
          audioRef.current &&
          audioRef.current.duration &&
          audioRef.current.duration !== Infinity &&
          setMaxDuration
        )
          setMaxDuration(audioRef.current.duration);
      };
    }
  }, [setMaxDuration]);

  useEffect(() => {
    // Play/pause audio if playing prop changed
    if (playing && audioRef.current) {
      audioRef.current.play();
    } else if (!playing && audioRef.current) {
      audioRef.current.pause();
    }
  }, [playing]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.ontimeupdate = () => {
        if (audioRef.current) updateCurrentTime(audioRef.current.currentTime);
      };
      audioRef.current.onended = () => setPlaying(false);
    }
  }, [setPlaying, updateCurrentTime]);

  const onSliderChange = (value: number) => {
    if (audioRef.current) audioRef.current.currentTime = value;
    if (updateCurrentTime) updateCurrentTime(value);
  };
  const onSliderMouseUp = (e: MouseEvent<HTMLDivElement | MouseEvent> | TouchEvent) => {
    setThumbSize(SMALL_SLIDER_THUMB_SIZE);
    e.stopPropagation();
  };
  const onSliderMouseDown = (e: MouseEvent<HTMLDivElement | MouseEvent> | TouchEvent) => {
    setThumbSize(LARGE_SLIDER_THUMB_SIZE);
    setClicked(true);
    e.stopPropagation();
  };
  const onBlur = (e: FocusEvent<HTMLInputElement>) => {
    setClicked(false);
    e.stopPropagation();
  };

  return (
    <Wrapper wrapperColor={wrapperColor} style={style}>
      {/*  This audio is generated by the user, cannot add captions */}
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio ref={audioRef} id="player" src={audioUrl} />
      <Loading
        hide={!loading}
        primaryColor={finalPrimaryColor}
        secondaryColor={colorRoles.surfaces.defaultDisabled}
      />
      <PlayToggle
        hide={loading}
        onPlay={(e) => {
          // Note: We need the play here to prevent the "Permission denied" error
          // on browsers that prevent programmatic media playing
          // even though it's already on a useEffect
          if (audioRef.current) audioRef.current.play();
          if (onPlay) onPlay(e);
        }}
        onPause={(e) => {
          if (audioRef.current) audioRef.current.pause();
          if (onPause) onPause(e);
        }}
        playing={playing}
      />
      <StyledSlider
        step={0.01}
        value={currentTime}
        maxValue={maxDuration}
        filledColor={colorRoles.surfaces.brandBoldDefault}
        thumbColor={colorRoles.surfaces.brandBoldDefault}
        onValueChange={onSliderChange}
        backgroundColor={colorRoles.surfaces.surfaceInteractiveDisabled}
        thumbSize={thumbSize}
        onMouseUp={onSliderMouseUp}
        onMouseDown={onSliderMouseDown}
        onBlur={onBlur}
        clicked={clicked}
        disabled={sliderDisabled}
      />
      <Time currentTime={currentTime > 0 ? currentTime : maxDuration} />
      <CancelButton hide={!onCancel} onPress={onCancel} />
    </Wrapper>
  );
};

export default AudioPlayerView;
