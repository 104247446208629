import { VoidFunctionComponent } from 'react';
import Svg, { Path } from 'svgs';
import { EmotionStyle } from '../../core/styled';

interface Props {
  width?: number;
  height?: number;
  style?: EmotionStyle;
}

const SortIcon: VoidFunctionComponent<Props> = ({ width = 20, height = 20, style }) => {
  const titleText = 'Sort';

  return (
    <Svg
      title={titleText}
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      style={style}
    >
      <Path
        d="M7.37709 3.29375C7.01397 2.90313 6.42426 2.90313 6.06113 3.29375L3.27234 6.29375C2.90922 6.68437 2.90922 7.31875 3.27234 7.70937C3.63547 8.1 4.22518 8.1 4.5883 7.70937L5.79097 6.41563V16C5.79097 16.5531 6.20638 17 6.72057 17C7.23475 17 7.65016 16.5531 7.65016 16V6.41563L8.85283 7.70937C9.21595 8.1 9.80567 8.1 10.1688 7.70937C10.5319 7.31875 10.5319 6.68437 10.1688 6.29375L7.38 3.29375H7.37709ZM17.6027 13.7094C17.9658 13.3188 17.9658 12.6844 17.6027 12.2937C17.2395 11.9031 16.6498 11.9031 16.2867 12.2937L15.0869 13.5844V4C15.0869 3.44687 14.6715 3 14.1573 3C13.6432 3 13.2277 3.44687 13.2277 4V13.5844L12.0251 12.2906C11.662 11.9 11.0722 11.9 10.7091 12.2906C10.346 12.6812 10.346 13.3156 10.7091 13.7063L13.4979 16.7063C13.861 17.0969 14.4507 17.0969 14.8139 16.7063L17.6027 13.7063V13.7094Z"
        fill="#005C65"
      />
    </Svg>
  );
};

export default SortIcon;
