import { useEffect, useRef } from 'react';
import View from '../View';
import CloseButton from '../CloseButton';
import styled, { EmotionStyle } from '../../core/styled';
import QuestionRound from '../Svgs/QuestionRound';
import withHover, { WithHoverProps } from '../../hoc/withHover';
import { useWindowWidth } from '../../index.next';

const TooltipContainer = styled(View)<{ containerStyle?: EmotionStyle }>(
  ({ theme: { colors }, containerStyle }) => {
    return {
      borderRadius: 8,
      background: colors.white,
      boxShadow: ' 0px 7px 16px rgba(0, 29, 47, 0.3875)',
      paddingLeft: 13,
      paddingRight: 15,
      paddingTop: 16,
      paddingBottom: 16,
      position: 'absolute',
      top: 30,
      zIndex: 10,
      right: 0,
      minWidth: 335,
      ...containerStyle,
    };
  }
);

const StyledCloseButton = {
  position: 'absolute',
  left: 'auto',
  right: 10,
  top: 10,
};

interface TooltipV2Props extends WithHoverProps {
  circleColor?: string;
  questionMarkColor?: string;
  toolTipText: string | React.ReactNode;
  isTooltipOpen?: boolean;
  containerStyle?: EmotionStyle;
  circleStyle?: EmotionStyle;
  styles?: EmotionStyle;
  hoverColor?: string;
  shouldDisplayCloseButton?: boolean;
  handleCloseButton?: () => void;
  handlePressIcon?: () => void;
}

const TooltipV2 = ({
  circleColor,
  questionMarkColor,
  toolTipText,
  isTooltipOpen,
  isHovering,
  containerStyle,
  circleStyle,
  styles = {},
  hoverColor,
  handleCloseButton,
  handlePressIcon,
  shouldDisplayCloseButton = true,
  ...otherProps
}: TooltipV2Props) => {
  const { isMobile } = useWindowWidth();
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node) &&
        handleCloseButton
      ) {
        handleCloseButton();
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleCloseButton]);

  return (
    <View
      onClick={(e) => e.preventDefault()}
      {...otherProps}
      style={{ position: 'relative', ...styles }}
      ref={containerRef}
    >
      <View style={{ height: 15, width: 15, ...circleStyle }} onClick={handlePressIcon}>
        <QuestionRound
          isPressed={isTooltipOpen}
          circleColor={circleColor}
          questionMarkColor={questionMarkColor}
          isHovering={isHovering}
          hoverColor={hoverColor}
        />
      </View>
      {(isTooltipOpen || isHovering) && (
        <TooltipContainer containerStyle={containerStyle}>
          {isMobile && shouldDisplayCloseButton && (
            <CloseButton
              onPress={handleCloseButton}
              height={31}
              width={31}
              style={StyledCloseButton}
            />
          )}
          {toolTipText}
        </TooltipContainer>
      )}
    </View>
  );
};

export default withHover(TooltipV2);
