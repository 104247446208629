import { forwardRef } from 'react';

import View from '../../../components/View';
import TouchableView, { TouchableViewProps } from '../../../components/TouchableView';
import styled, { EmotionStyle } from '../../../core/styled';
import { getSurfaceDefaultStyles } from '../../styles/interactiveStateStyles';

type ButtonSize = 'slim' | 'medium' | 'large';

const IconButtonWrapper = styled(View)<{ isActive?: boolean; size?: ButtonSize }>(
  ({ isActive, size, theme: { colorRoles } }) => {
    const padding = {
      slim: 2,
      medium: 4,
      large: 4,
    };
    return {
      padding: size ? padding[size] : '4px 4px',
      borderRadius: 8,
      ...getSurfaceDefaultStyles({ colorRoles, active: isActive }),
    };
  }
);

interface IconButtonProps extends TouchableViewProps {
  isActive?: boolean;
  Icon: React.ReactNode;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  hide?: boolean;
  iconWrapperStyle?: EmotionStyle;
  size?: ButtonSize;
}

const IconButton = forwardRef<HTMLDivElement, IconButtonProps>((props: IconButtonProps, ref) => {
  const {
    isActive,
    Icon,
    onMouseEnter,
    hide,
    onMouseLeave,
    iconWrapperStyle,
    size,
    ...touchableViewProps
  } = props;
  if (hide) return null;
  return (
    <TouchableView
      {...touchableViewProps}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      ref={ref}
    >
      <IconButtonWrapper isActive={isActive} style={iconWrapperStyle} size={size}>
        {Icon}
      </IconButtonWrapper>
    </TouchableView>
  );
});

export default IconButton;
