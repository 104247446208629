import * as actionTypes from '../constants/RoomConstants';

const initialState = {
  roomID: undefined,
  clientUserID: undefined,
  roomUrl: undefined,
  status: undefined,
  isEAP: undefined,
  isQuickmatch: undefined,
  roomType: undefined,
  isVideoOnly: false,
  spinnerMessage: '',
  isUpdating: false,
  isError: false,
  isStarredMessagesOpen: false,
  isSharedFilesOpen: false,
  isPostLVSPromptOpen: false,
  completedVideoCallID: undefined,
};

export default function room(state = initialState, { type, ...action }) {
  switch (type) {
    case actionTypes.RESET_INITIAL_ROOM_STATE:
      return initialState;
    case actionTypes.SET_ROOM_PROPERTIES:
      return {
        ...state,
        ...action,
      };
    case actionTypes.GET_ROOMS_ERROR:
      return {
        ...state,
        isError: true,
        isUpdating: false,
      };
    case actionTypes.GETTING_ROOMS:
      return {
        ...state,
        isError: false,
        isUpdating: true,
      };
    case actionTypes.REQUEST_CREATE_ROOM:
      return {
        ...state,
        isUpdating: true,
        isError: false,
        spinnerMessage: ' ',
      };
    case actionTypes.REQUEST_CREATE_ROOM_ERROR:
      return {
        ...state,
        isUpdating: false,
        isError: true,
        spinnerMessage: 'Something went wrong',
      };
    case actionTypes.RECEIVE_CREATE_ROOM:
      return {
        ...state,
        isUpdating: false,
        isError: false,
        spinnerMessage: 'Room successfully created',
      };
    case actionTypes.REQUEST_DISCHARGE_AND_TRANSFER:
      return {
        ...state,
        isUpdating: true,
        isError: false,
        spinnerMessage: '',
      };
    case actionTypes.REQUEST_DISCHARGE_AND_TRANSFER_ERROR:
      return {
        ...state,
        isUpdating: false,
        isError: true,
        spinnerMessage: 'Something went wrong',
      };
    case actionTypes.RECEIVE_DISCHARGE_AND_TRANSFER:
      return {
        ...state,
        isUpdating: false,
        isError: false,
        spinnerMessage: 'Room successfully transferred',
      };
    case actionTypes.OPEN_STARRED_MESSAGES:
      return {
        ...state,
        isStarredMessagesOpen: true,
      };
    case actionTypes.CLOSE_STARRED_MESSAGES:
      return {
        ...state,
        isStarredMessagesOpen: false,
      };
    case actionTypes.OPEN_SHARED_FILES:
      return {
        ...state,
        isSharedFilesOpen: true,
      };
    case actionTypes.CLOSE_SHARED_FILES:
      return {
        ...state,
        isSharedFilesOpen: false,
      };
    case actionTypes.OPEN_POST_LVS_PROMPT:
      return {
        ...state,
        isPostLVSPromptOpen: true,
        completedVideoCallID: action.completedVideoCallID,
      };
    case actionTypes.CLOSE_POST_LVS_PROMPT:
      return {
        ...state,
        isPostLVSPromptOpen: false,
      };
    default:
      return state;
  }
}
