import { FunctionComponent } from 'react';
import { View } from '@talkspace/react-toolkit';
import WizardManager from '../containers/WizardManager';
import { WizardScheme } from '../containers/WizardManager/WizardManager';

interface Props {
  wizardScheme: WizardScheme;
}

const WizardScreen: FunctionComponent<Props> = (props) => {
  const { wizardScheme } = props;
  return (
    <View justify="center" align="center">
      <WizardManager wizardScheme={wizardScheme} />
    </View>
  );
};

export default WizardScreen;
