import moment, { Moment } from 'moment';

export type ValueType = boolean | string | Record<string, any>;

export type ItemWithExpiration = {
  value: ValueType;
  expiration: number;
};

/**
 * Add expiration using Moment library e.g. stringifyWithExpiration('hello', moment().add(1, 'week'))
 */
export const stringifyWithExpiration = (value: ValueType, expiration: Moment | Date) => {
  const item = JSON.stringify({
    value,
    expiration: expiration.valueOf(),
  });

  return item;
};

export const isItemExpired = (item: ItemWithExpiration) => {
  let isExpired = false;
  if (item.expiration) {
    const now = moment().valueOf();
    isExpired = now > item.expiration;
  }
  return isExpired;
};
