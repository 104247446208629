import {
  Checkbox,
  Large,
  OptionType,
  Select,
  Small,
  styled,
  TextAreaRounded,
  TouchableView,
  useEmotionTheme,
  View,
} from '@talkspace/react-toolkit';
import { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import useQueryCollateralNote from 'hooks/notes/useQueryCollateralNote';
import Submenu from 'components/Reusable/Submenu/Submenu';
import moment, { Moment } from 'moment';
import useMutationSubmitCollateralNote from 'hooks/notes/useMutationSubmitCollateralNote';
import useMutationSaveDraftCollateralNote, {
  CollateralNoteMutateVariables,
} from 'hooks/notes/useMutationSaveDraftCollateralNote';
import useMutationUpdateCollateralNote from 'hooks/notes/useMutationUpdateCollateralNote';
import CrmDateTimePicker from 'components/Reusable/CrmDateTimePicker/CrmDateTimePicker';
import useQueryRoomDetails from 'ts-frontend/hooks/useQueryRoomDetails';
import useQueryNotes from 'hooks/notes/useQueryNotes';
import { useHistory, useParams } from '@/core/routerLib';
import IndentedView from './IndentedView';
import {
  ACTION_SETTLED_TIMEOUT,
  getSubmenuTitleAndSubtitle,
  getTextAreaContainerBorderStyle,
  getTextAreaStyle,
} from './utils';
import ContactNameWithRelationship from './ContactNameWithRelationship';
import { FormMode } from './types';
import StatementCertification from './StatementCertification';
import NoteFormFooter from './NoteFormFooter';

const CONTACT_SUMMARY_CHAR_LIMIT = 25000;

const SmallStyled = styled(Small)({
  marginBottom: 5,
});

const Wrapper = styled(View)({
  height: '100%',
});

const ViewMargin = styled(TouchableView)({
  marginBottom: 15,
});

const releaseObtainedOptions: OptionType<boolean>[] = [
  {
    value: true,
    label: 'Yes',
  },
  {
    value: false,
    label: 'No',
  },
];

const SmallTitleStyled = styled(Small)<{ error: boolean }>(({ theme: { colors }, error }) => {
  return {
    color: error ? colors.permaDebianRed : undefined,
    marginBottom: 10,
  };
});

interface CollateralNoteFormState {
  contactDate: null | Date;
  contactName: string | null;
  contactRelationship: string | null;
  releaseObtained: OptionType<boolean> | null;
  missingObtainedReleaseReason: string | null;
  contactSummary: string | null;
  statementCertified: boolean;

  contactDateError: boolean;
  contactNameError: boolean;
  contactRelationshipError: boolean;
  releaseObtainedError: boolean;
  missingObtainedReleaseReasonError: boolean;
  contactSummaryError: boolean;
  statementCertifiedError: boolean;

  isErrorValidation: boolean;
  showSpinner: boolean;
  actionMade: 'Saved' | 'Saved draft' | 'Submitted';
}

const initialState: CollateralNoteFormState = {
  contactDate: null,
  contactName: '',
  contactRelationship: '',
  releaseObtained: null,
  missingObtainedReleaseReason: '',
  contactSummary: '',
  statementCertified: false,

  contactDateError: false,
  contactNameError: false,
  contactRelationshipError: false,
  releaseObtainedError: false,
  missingObtainedReleaseReasonError: false,
  contactSummaryError: false,
  statementCertifiedError: false,

  isErrorValidation: false,
  showSpinner: false,
  actionMade: 'Saved',
};

const CollateralNoteForm: FunctionComponent<{ mode: FormMode }> = ({ mode }) => {
  const history = useHistory();
  const { roomID, noteID } = useParams<{ roomID: string; noteID?: string }>();
  const { colors } = useEmotionTheme();

  const [state, setState] = useState<CollateralNoteFormState>(initialState);

  const shouldFetchTheNote = mode === 'edit' || mode === 'view';
  const { data: { clientUserID } = {} } = useQueryRoomDetails(roomID);
  useQueryNotes(roomID);
  const {
    isLoading: isCollateralNoteQueryLoading,
    isError: isCollateralNoteQueryError,
    data: collateralNoteData,
  } = useQueryCollateralNote(clientUserID, noteID, shouldFetchTheNote);

  const isReadOnly = collateralNoteData?.readOnly;

  const {
    mutate: saveDraftCollateralNote,
    isLoading: isSaveDraftLoading,
    isError: isSaveDraftError,
  } = useMutationSaveDraftCollateralNote();

  const {
    mutate: submitCollateralNote,
    isLoading: isSubmitLoading,
    isError: isSubmitError,
  } = useMutationSubmitCollateralNote();

  const {
    mutate: updateCollateralNote,
    isLoading: isUpdateLoading,
    isError: isUpdateError,
  } = useMutationUpdateCollateralNote();

  useEffect(() => {
    if (collateralNoteData) {
      let releaseObtainedOption: null | OptionType<boolean> = null;
      if (collateralNoteData.releaseObtained !== null) {
        releaseObtainedOption = collateralNoteData.releaseObtained
          ? releaseObtainedOptions[0]
          : releaseObtainedOptions[1];
      }

      setState((previousState) => {
        return {
          ...previousState,
          contactDate: collateralNoteData.contactDate
            ? new Date(collateralNoteData.contactDate)
            : null,
          contactName: collateralNoteData.contactName,
          contactRelationship: collateralNoteData.contactRelationship,
          releaseObtained: releaseObtainedOption,
          missingObtainedReleaseReason: collateralNoteData.missingObtainedReleaseReason,
          contactSummary: collateralNoteData.contactSummary,
          statementCertified: collateralNoteData.statementCertified,
        };
      });
    }
  }, [collateralNoteData, setState]);

  const validateCollateralFormSubmission = () => {
    const newState = { ...state };

    newState.isErrorValidation = false;

    if (!state.contactDate) {
      newState.contactDateError = true;
      newState.isErrorValidation = true;
    }

    if (!state.contactName) {
      newState.contactNameError = true;
      newState.isErrorValidation = true;
    }

    if (!state.contactRelationship) {
      newState.contactRelationshipError = true;
      newState.isErrorValidation = true;
    }

    if (!state.releaseObtained) {
      newState.releaseObtainedError = true;
      newState.isErrorValidation = true;
    } else if (
      state.releaseObtained &&
      !state.releaseObtained.value &&
      !state.missingObtainedReleaseReason
    ) {
      newState.missingObtainedReleaseReasonError = true;
      newState.isErrorValidation = true;
    }

    if (!state.contactSummary) {
      newState.contactSummaryError = true;
      newState.isErrorValidation = true;
    }

    if (!state.statementCertified) {
      newState.statementCertifiedError = true;
      newState.isErrorValidation = true;
    }

    if (newState.isErrorValidation) {
      setState(newState);
    }

    return !newState.isErrorValidation;
  };

  const isLoading =
    isSaveDraftLoading || isSubmitLoading || isCollateralNoteQueryLoading || isUpdateLoading;
  const isError = isSaveDraftError || isSubmitError || isCollateralNoteQueryError || isUpdateError;

  const handleEditPress = () => {
    history.push(`/room/${roomID}/collateral-notes/${noteID}/edit`);
  };

  const handleCancelPress = () => {
    history.goBack();
  };

  const handleActionSettled = () => {
    setTimeout(() => {
      history.push(`/room/${roomID}/notes-tab`);
    }, ACTION_SETTLED_TIMEOUT);
  };

  const handleSubmitPress = () => {
    if (validateCollateralFormSubmission()) {
      setState({ ...state, showSpinner: true, actionMade: 'Submitted' });

      submitCollateralNote(
        {
          roomID,
          noteID,
          clientUserID,
          contactDate: state.contactDate,
          contactName: state.contactName,
          contactRelationship: state.contactRelationship,
          releaseObtained: state.releaseObtained?.value || false,
          missingObtainedReleaseReason: state.missingObtainedReleaseReason || null,
          contactSummary: state.contactSummary,
          statementCertified: state.statementCertified,
        },
        { onSettled: handleActionSettled }
      );
    }
  };

  const handleSavePress = () => {
    if (noteID && validateCollateralFormSubmission()) {
      setState({ ...state, showSpinner: true, actionMade: 'Saved' });
      updateCollateralNote(
        {
          roomID,
          noteID,
          clientUserID,
          contactDate: state.contactDate ? state.contactDate : new Date(),
          contactName: state.contactName,
          contactRelationship: state.contactRelationship,
          releaseObtained: state.releaseObtained?.value || false,
          missingObtainedReleaseReason: state.missingObtainedReleaseReason || null,
          contactSummary: state.contactSummary,
          statementCertified: state.statementCertified,
        },
        { onSettled: handleActionSettled }
      );
    }
  };

  const handleSaveDraftPress = () => {
    setState({ ...state, showSpinner: true, actionMade: 'Saved draft' });
    saveDraftCollateralNote(
      {
        roomID,
        noteID,
        clientUserID,
        contactDate: state.contactDate,
        contactName: state.contactName || null,
        contactRelationship: state.contactRelationship || null,
        releaseObtained: state.releaseObtained !== null ? state.releaseObtained.value : null,
        missingObtainedReleaseReason: state.missingObtainedReleaseReason || null,
        contactSummary: state.contactSummary || null,
        statementCertified: state.statementCertified || false,
      },
      { onSettled: handleActionSettled }
    );
  };

  const handleDeleteDraftPress = () => {
    if (noteID) {
      history.push(`/room/${roomID}/collateral-notes/${noteID}/delete`);
    }
  };

  const handleContactDateChange = (newValue: Moment) => {
    setState({
      ...state,
      contactDate: moment.utc(newValue).toDate(),
      contactDateError: false,
    });
  };

  const handleContactNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, contactName: event.target.value, contactNameError: false });
  };

  const handleContactRelationshipChange = (event: ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      contactRelationship: event.target.value,
      contactRelationshipError: false,
    });
  };

  const onReleaseObtainedChange = (newValue?: OptionType<boolean> | null) => {
    setState({
      ...state,
      releaseObtained: newValue || null,
      missingObtainedReleaseReason: newValue?.value ? '' : state.missingObtainedReleaseReason,
      releaseObtainedError: false,
    });
  };

  const onContactSummaryChange = (newValue: string) => {
    setState({ ...state, contactSummary: newValue, contactSummaryError: false });
  };

  const handleStatementCertifiedChange = (newValue: boolean) => {
    setState({ ...state, statementCertified: newValue, statementCertifiedError: false });
  };

  const onMissingObtainedReleaseChange = (newValue: string) => {
    setState({
      ...state,
      missingObtainedReleaseReason: newValue,
      missingObtainedReleaseReasonError: false,
    });
  };

  const renderFooter = () => (
    <NoteFormFooter
      formMode={mode}
      noteStatus={collateralNoteData?.status}
      canDeleteDraft={!!noteID}
      canDeleteSubmitted={!!noteID}
      isError={isError}
      isLoading={isLoading}
      editHidden={isReadOnly}
      showSpinner={state.showSpinner}
      spinnerMessage="Saved successfully"
      showErrorBanner={state.isErrorValidation}
      handleCloseErrorBannerPress={() => setState({ ...state, isErrorValidation: false })}
      handleDeleteDraftPress={handleDeleteDraftPress}
      handleSaveDraftPress={handleSaveDraftPress}
      handleSubmitPress={handleSubmitPress}
      handleCancelPress={handleCancelPress}
      handleSavePress={handleSavePress}
      handleEditPress={handleEditPress}
    />
  );

  const renderMainView = () => (
    <View style={{ paddingLeft: 10, paddingRight: 10 }}>
      {mode !== 'view' && (
        <Small style={{ marginBottom: 15 }}>
          A collateral note documents contact with someone that is not the identified client that is
          meant to further the course of treatment. This person could be a spouse, family member,
          guidance counselor, etc. The note can also be used to document attempted engagement with
          the identified client.{' '}
        </Small>
      )}

      <CrmDateTimePicker
        isError={state.contactDateError}
        selectedDate={state.contactDate ? moment.parseZone(state.contactDate) : null}
        onDateChange={handleContactDateChange}
        startDate={moment.parseZone()}
        minDate={moment.parseZone().subtract(1, 'year')}
        maxDate={moment.parseZone()}
        isDisabled={mode === 'view'}
        placeholderText="Date & Time of contact"
      />

      <ContactNameWithRelationship
        namePlaceholder="Collateral contact name(s)"
        nameError={state.contactNameError}
        nameValue={state.contactName}
        onNameChange={handleContactNameChange}
        relationshipError={state.contactRelationshipError}
        relationshipValue={state.contactRelationship}
        onRelationshipChange={handleContactRelationshipChange}
        showRemove={false}
        disabled={mode === 'view'}
      />

      <ViewMargin>
        <ViewMargin>
          <Select
            isDisabled={mode === 'view'}
            placeholder={
              <View
                style={{
                  ...(state.releaseObtainedError ? { color: colors.permaDebianRed } : {}),
                }}
              >
                HIPAA release(s) obtained
              </View>
            }
            value={state.releaseObtained as any}
            styles={{
              ...(state.releaseObtainedError
                ? {
                    control: () => {
                      return { borderBottom: `${colors.permaDebianRed} solid 1px` };
                    },
                  }
                : {}),
            }}
            onChange={onReleaseObtainedChange as any}
            options={releaseObtainedOptions as any}
          />
        </ViewMargin>
        {state.releaseObtained && !state.releaseObtained.value && (
          <IndentedView>
            <TextAreaRounded
              autoSize
              hasLimit
              disabled={mode === 'view'}
              style={{
                ...getTextAreaContainerBorderStyle(colors, state.missingObtainedReleaseReasonError),
              }}
              value={state.missingObtainedReleaseReason || ''}
              onChangeText={onMissingObtainedReleaseChange}
              placeholder="Explain why no release was obtained"
              textAreaStyle={getTextAreaStyle(mode === 'view')}
            />
          </IndentedView>
        )}
      </ViewMargin>

      <View style={{ marginBottom: 50 }}>
        <SmallTitleStyled error={state.contactSummaryError}>Summary of contact</SmallTitleStyled>
        <TextAreaRounded
          autoSize
          hasLimit
          limit={CONTACT_SUMMARY_CHAR_LIMIT}
          style={{ ...getTextAreaContainerBorderStyle(colors, state.contactSummaryError) }}
          placeholder="Summarize contact"
          value={state.contactSummary || ''}
          onChangeText={onContactSummaryChange}
          disabled={mode === 'view'}
          textAreaStyle={getTextAreaStyle(mode === 'view')}
        />
      </View>

      <ViewMargin>
        <Checkbox
          labelStyle={{
            color: state.statementCertifiedError ? colors.permaDebianRed : colors.black,
          }}
          borderColor={state.statementCertifiedError ? colors.permaDebianRed : undefined}
          label={
            <SmallStyled variant="smallTSBlack">
              I certify the above statement to be accurate as of the digital date signed.
            </SmallStyled>
          }
          isChecked={state.statementCertified}
          setIsChecked={handleStatementCertifiedChange}
          isDisabled={mode === 'view'}
          isLabelOnRight
          alignCenter={false}
        />

        {mode === 'view' && collateralNoteData && collateralNoteData.submittedAt && (
          <StatementCertification
            user={collateralNoteData.createdByUser}
            submittedAt={new Date(collateralNoteData.submittedAt)}
          />
        )}
      </ViewMargin>
    </View>
  );

  const { title, subtitle } = getSubmenuTitleAndSubtitle({
    formMode: mode,
    noteType: 'collateral',
    noteDate: collateralNoteData?.contactDate,
  });

  const handleBackButtonPress = () => {
    if (mode === 'create') {
      const locationState: CollateralNoteMutateVariables = {
        roomID,
        clientUserID,
        contactDate: state.contactDate,
        contactName: state.contactName || null,
        contactRelationship: state.contactRelationship || null,
        releaseObtained: state.releaseObtained !== null ? state.releaseObtained.value : null,
        missingObtainedReleaseReason: state.missingObtainedReleaseReason || null,
        contactSummary: state.contactSummary || null,
        statementCertified: state.statementCertified || false,
      };

      history.push(`/room/${roomID}/collateral-notes/save-draft`, locationState);
    } else {
      history.push(`/room/${roomID}/notes-tab`);
    }
  };

  return (
    <Wrapper>
      <Submenu
        title={title}
        titleComponent={
          subtitle ? (
            <View>
              <Large variant="largeBoldWide">{title}</Large>
              <Small variant="smallBoldGrey">{subtitle}</Small>
            </View>
          ) : undefined
        }
        childComponents={[renderMainView()]}
        footerComponent={renderFooter()}
        onBackAlt={handleBackButtonPress}
      />
    </Wrapper>
  );
};

export default CollateralNoteForm;
