import moment from 'moment';

export const formatWeekPeriod = (startDate: Date, endDate: Date): string => {
  const options: Intl.DateTimeFormatOptions = { month: 'long', day: 'numeric', timeZone: 'UTC' };

  const start = startDate.toLocaleDateString('en-US', options);
  const end = endDate.toLocaleDateString('en-US', options);

  const [startMonth, startDay] = start.split(' ');
  const [endMonth, endDay] = end.split(' ');

  return startMonth === endMonth
    ? `(${startMonth} ${startDay} – ${endDay})`
    : `(${startMonth} ${startDay} – ${endMonth} ${endDay})`;
};

export const formatLastUpdatedAt = (date: Date): string => {
  const options: Intl.DateTimeFormatOptions = {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZoneName: 'short',
  };

  return date.toLocaleString('en-US', options);
};

export const formatQuarterEndDate = (date: Date): string => {
  const options: Intl.DateTimeFormatOptions = { month: 'long', day: 'numeric', timeZone: 'UTC' };
  return date.toLocaleDateString('en-US', options);
};

export const isFirstWeekOfQuarter = (date: Date): boolean => {
  const dateMoment = moment(date);
  const startOfQuarter = dateMoment.clone().startOf('quarter');

  return dateMoment.isoWeek() === startOfQuarter.isoWeek();
};
