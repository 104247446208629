import * as React from 'react';
import PanelManager from '../components/PanelManager';

const withPanelManager =
  <P extends Record<string, unknown>>(Component: React.ComponentType<P>): React.ComponentType<P> =>
  (props: P) =>
    (
      <PanelManager>
        <Component {...props} />
      </PanelManager>
    );

export default withPanelManager;
