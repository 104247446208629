/* eslint-disable no-console */

import { showAudioVideoPermDialog } from 'ts-ionic/plugins/dialog';
import { preventNextSplashScreenShow } from 'ts-ionic/plugins/splashScreen';
import { keepAwake } from '../../ts-ionic/plugins/keepAwake';

const DEFAULT_OPTIONS: MediaRecorderOptions = {
  mimeType: 'audio/mp4',
};

export interface AudioPayload {
  blob: Blob;
  type: 'audio';
  duration: number;
  audioUrl: string;
  fileName: string;
}

const { navigator } = window;

export type AudioRecorderState = 'inactive' | 'recording' | 'paused';

export default class AudioRecorder {
  private stream: MediaStream;

  private mediaRecorder: MediaRecorder | null = null;

  private audioChunks: Blob[];

  public state: AudioRecorderState;

  private options: MediaRecorderOptions;

  private allowSleep: (() => Promise<void>) | null = null;

  constructor(stream: MediaStream, options: MediaRecorderOptions = DEFAULT_OPTIONS) {
    this.stream = stream;
    this.options = options;

    this.audioChunks = [];
    this.state = 'inactive';
  }

  static async init(
    onWaitingPermission?: () => void,
    options?: MediaRecorderOptions
  ): Promise<AudioRecorder | null> {
    try {
      const userMedia = await AudioRecorder.getUserMedia(onWaitingPermission);
      return new AudioRecorder(userMedia, options);
    } catch (error) {
      if (error.message === 'Browser not supported') {
        // eslint-disable-next-line no-alert
        alert(
          'This browser is not supported. To access this feature, use the Talkspace mobile app.'
        );
      } else {
        showAudioVideoPermDialog({ isAudioOn: true, isVideoOn: false, purpose: 'record audio' });
      }
      console.warn('Could not start audio recording', error);
      return null;
    }
  }

  public start = () => {
    preventNextSplashScreenShow();
    this.mediaRecorder = new MediaRecorder(this.stream, this.options);
    this.audioChunks = [];
    this.mediaRecorder?.addEventListener('dataavailable', this.onNewChunk);
    this.mediaRecorder?.start();
    this.state = 'recording';
    keepAwake().then((allowSleep) => {
      this.allowSleep = allowSleep;
      if (this.state === 'paused') this.allowSleep();
    });
  };

  public stop = (): Promise<AudioPayload> =>
    new Promise((resolve) => {
      if (this.mediaRecorder) {
        this.mediaRecorder.addEventListener('stop', () => {
          this.state = 'paused';
          // TODO: @LuisRizo Use dynamic file extension for Ionic Android
          const fileName = `audio-${Date.now()}.m4a`;
          const audioBlob = new Blob(this.audioChunks, {
            type: this.options.mimeType,
          });
          const audioUrl = URL.createObjectURL(audioBlob);
          const audio = new Audio(audioUrl);

          this.stream.getTracks().forEach((track) => track.stop());
          audio.onloadedmetadata = () => {
            const { duration } = audio;
            resolve({ blob: audioBlob, fileName, type: 'audio', duration, audioUrl });
          };
          this.mediaRecorder = null;
          if (this.allowSleep) this.allowSleep();
        });
        this.mediaRecorder.stop();
      }
    });

  private onNewChunk = (event: BlobEvent): void => {
    // console.log(`Recorded chunk of size ${event.data.size}B`);
    if (Array.isArray(this.audioChunks)) {
      this.audioChunks.push(event.data);
    } else {
      this.audioChunks = [event.data];
    }
  };

  private onRecorderError = (error: MediaRecorderErrorEvent): void => {
    console.warn('[Audio error] - ', error);
    this.stop();
  };

  static async getUserMedia(onWaitingPermission?: () => void): Promise<MediaStream> {
    const timeout = window.setTimeout(() => {
      if (onWaitingPermission) onWaitingPermission();
    }, 500);
    const removeTimeout = () => window.clearTimeout(timeout);
    if (!navigator.mediaDevices) {
      removeTimeout();
      throw new Error('Browser not supported');
    }

    try {
      const userMedia = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
      removeTimeout();
      return userMedia;
    } catch (err) {
      console.error('Error getting user media', err);
      removeTimeout();
      throw err;
    }
  }
}
