import { formatCurrency } from 'ts-frontend/helpers/billingUtils';
import {
  BonusInfoDTO,
  ClinicalHoursDTO,
  PotentialBonusDTO,
  QuarterOverviewDTO,
  WeeklyStatsDTO,
} from '../api/clinicalHours';

export interface WeeklyStats {
  weekStart: Date;
  weekEnd: Date;

  totalLiveCallHours: string;
  liveCallHours: string;
  noShowsAndLateCancellationsHours: string;

  totalMessagingHours: string;
  providerTotalMessagingHoursCapped: string;
  providerTotalMessagingWordsCapped: string;
  providerTotalMessagingWordsUncapped: string;
  clientTotalMessagingHours: string;
  clientTotalMessagingWords: string;
  providerAVHours: string;
  clientAVHours: string;

  paidTimeOffHours: string;

  totalHours: string;

  upcomingLive: string;
  expectedMessaging: string;
  upcomingPaidTimeOff: string;
}

interface QuarterOverview {
  weeksLeft: string;
  lastDay: Date;
  average: string;
}

interface PotentialBonus {
  amount: string;
  threshold: string;
  requiredAverage: string;
}

export interface BonusInfo {
  currentBonus: number;
  potentialBonus: PotentialBonus | null;
}

interface BonusThreshold {
  amount: number;
  lowerThreshold: number;
  upperThreshold: number;
}

export interface IClinicalHours {
  lastUpdatedAt: Date | null;
  weeklyStats: WeeklyStats[];
  quarterOverview?: QuarterOverview;
  bonusInfo?: BonusInfo;
  weeklyHoursGoal: number;
  bonusThresholds: BonusThreshold[];
}

const formatWeeklyStatsDTO = (dto: WeeklyStatsDTO): WeeklyStats => {
  return {
    weekStart: new Date(dto.weekStart),
    weekEnd: new Date(dto.weekEnd),

    totalLiveCallHours: Number(dto.totalLiveCallHours).toFixed(1),
    liveCallHours: Number(dto.liveCallHours).toFixed(1),
    noShowsAndLateCancellationsHours: Number(dto.noShowsAndLateCancellationsHours).toFixed(1),

    totalMessagingHours: Number(dto.totalMessagingHours).toFixed(1),
    providerTotalMessagingHoursCapped: Number(dto.providerTotalMessagingHoursCapped).toFixed(1),
    providerTotalMessagingWordsCapped: Number(
      dto.providerTotalMessagingWordsCapped
    ).toLocaleString(),
    providerTotalMessagingWordsUncapped: Number(
      dto.providerTotalMessagingWordsUncapped
    ).toLocaleString(),
    clientTotalMessagingHours: Number(dto.clientTotalMessagingHours).toFixed(1),
    clientTotalMessagingWords: Number(dto.clientTotalMessagingWords).toLocaleString(),
    providerAVHours: Number(dto.providerAVHours).toFixed(1),
    clientAVHours: Number(dto.clientAVHours).toFixed(1),

    paidTimeOffHours: Number(dto.paidTimeOffHours).toFixed(1),

    totalHours: Number(dto.totalHours).toFixed(1),

    upcomingLive: Number(dto.upcomingLive).toFixed(1),
    expectedMessaging: Number(dto.expectedMessaging).toFixed(1),
    upcomingPaidTimeOff: Number(dto.upcomingPaidTimeOff).toFixed(1),
  };
};

const formatPotentialBonusDTO = (dto: PotentialBonusDTO): PotentialBonus => {
  return {
    amount: formatCurrency(dto.amount, 'USD'),
    threshold: Number(dto.threshold).toFixed(1),
    requiredAverage: Number(dto.requiredAverage).toFixed(1),
  };
};

const formatBonusInfoDTO = (dto: BonusInfoDTO): BonusInfo => {
  return {
    currentBonus: dto.currentBonus,
    potentialBonus: dto.potentialBonus ? formatPotentialBonusDTO(dto.potentialBonus) : null,
  };
};

const formatQuarterOverviewDTO = (dto: QuarterOverviewDTO): QuarterOverview => {
  return {
    ...dto,
    average: Number(dto.average).toFixed(1),
    lastDay: new Date(dto.lastDay),
    weeksLeft: Number(dto.weeksLeft).toFixed(1),
  };
};

export const formatClinicalHoursDTO = (dto: ClinicalHoursDTO): IClinicalHours => {
  return {
    ...dto,
    quarterOverview: dto.quarterOverview
      ? formatQuarterOverviewDTO(dto.quarterOverview)
      : undefined,
    lastUpdatedAt: dto.lastUpdatedAt ? new Date(dto.lastUpdatedAt) : null,
    weeklyStats: dto.weeklyStats.map(formatWeeklyStatsDTO),
    bonusInfo: dto.bonusInfo && formatBonusInfoDTO(dto.bonusInfo),
  };
};
