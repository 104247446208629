import { useState, useCallback, useRef, useEffect } from 'react';
import IconButton from '../IconButton';
import ThreeDots from '../../icons/EllipsisVertical';
import EllipsisHorizontal from '../../icons/EllipsisHorizontal';
import View from '../../../components/View';
import TouchableView from '../../../components/TouchableView';
import Tray, { AnimationSize } from '../../../components/Tray';
import styled, { EmotionStyle, useEmotionTheme } from '../../../core/styled';
import { useWindowWidthState } from '../../../hooks/windowWidthContext';
import { spacing } from '../../tokens';
import { getSurfaceDefaultStyles } from '../../styles/interactiveStateStyles';
import { elevation2Style } from '../../styles/elevation';
import TextDS from '../typography/TextDS';

import OverflowMenuPopupOption from './OverflowMenuPopupOption';

const { space100, space200, space400 } = spacing;

const FloatingWrapper = styled(View)({
  paddingTop: space100,
  paddingBottom: space100,
  right: 0,
  top: 40,
  borderRadius: 12,
  position: 'absolute',
  backgroundColor: 'white',
  overflow: 'hidden',
  minWidth: 168,
  textAlign: 'left',
  zIndex: 2,
  ...elevation2Style,
});

const MenuBackdrop = styled(TouchableView)({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 1,
  backgroundColor: 'transparent',
});

const TrayMenuOption = styled(TouchableView)(({ theme: { colorRoles } }) => {
  return {
    paddingLeft: space400,
    paddingRight: space400,
    paddingBottom: space200,
    paddingTop: space200,

    ...getSurfaceDefaultStyles({ colorRoles }),
  };
});

const TrayInnerContainer = styled(TouchableView)(() => {
  return {
    paddingTop: space200,
    paddingBottom: space200,
    width: '100%',
    textAlign: 'left',
  };
});

const trayContainerStyles = {
  borderTopLeftRadius: 16,
  borderTopRightRadius: 16,
  padding: 0,
  bottom: 0,
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 0,
  paddingRight: 0,
  ...elevation2Style,
};

export interface OverflowMenuOption {
  callback: (event: React.MouseEvent<HTMLElement>) => void;
  label: string;
  dataQa?: string;
}

export interface OverflowMenuProps {
  options: OverflowMenuOption[];
  dataQa: string;
  hasTrayBackdrop?: boolean;
  a11yLabel?: string;
  noWrap?: boolean;
  vertical?: boolean;
  optionsLabelStyle?: EmotionStyle;
}

const getAnimationSize = (options: OverflowMenuOption[]): AnimationSize => {
  if (options.length > 3) return 'medium';
  if (options.length > 6) return 'large';
  return 'small';
};

const CUSTOM_EVENT_MENU_OPEN = 'overflowMenuOpen';

const OverflowMenu = (props: OverflowMenuProps) => {
  const {
    options,
    hasTrayBackdrop,
    a11yLabel,
    dataQa: buttonDataQa,
    noWrap,
    vertical = true,
    optionsLabelStyle,
  } = props;
  const { isMobile } = useWindowWidthState();
  const { colorRoles } = useEmotionTheme();
  const [isOpen, setIsOpen] = useState(false);
  const onMenuPress = useCallback(() => {
    if (!isOpen) {
      const openEvent = new CustomEvent(CUSTOM_EVENT_MENU_OPEN);
      document.dispatchEvent(openEvent);
    }
    setIsOpen(!isOpen);
  }, [isOpen]);
  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);
  const containerRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);

  const handleKeydown = useCallback(
    (event: KeyboardEvent) => {
      if (isOpen && !isMobile && handleClose && event.key === 'Escape') {
        handleClose();
      }
    },
    [isOpen, isMobile, handleClose]
  );

  useEffect(() => {
    const buttonRefCopy = buttonRef.current;
    const handleClickOutside = (event: MouseEvent) => {
      if (
        isOpen &&
        !isMobile &&
        containerRef.current &&
        !containerRef.current.contains(event.target as Node) &&
        handleClose
      ) {
        handleClose();
      }
    };

    document.addEventListener('click', handleClickOutside);
    document.addEventListener('keydown', handleKeydown);
    document.addEventListener(CUSTOM_EVENT_MENU_OPEN, handleClose);
    if (buttonRefCopy) {
      buttonRef.current.addEventListener('keydown', handleKeydown);
    }
    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('keydown', handleKeydown);
      document.removeEventListener(CUSTOM_EVENT_MENU_OPEN, handleClose);
      if (buttonRefCopy) {
        buttonRefCopy.removeEventListener('keydown', handleKeydown);
      }
    };
  }, [handleKeydown, handleClose, isMobile, isOpen]);

  const animationSize = getAnimationSize(options);

  const backgroundStyle = hasTrayBackdrop
    ? { backgroundColor: colorRoles.surfaces.overlayDefault }
    : { backgroundColor: 'transparent' };
  return (
    <View style={{ position: 'relative' }}>
      <IconButton
        ref={buttonRef}
        dataQa={buttonDataQa}
        aria-label={a11yLabel}
        Icon={vertical ? <ThreeDots /> : <EllipsisHorizontal colorType="brand" size="major" />}
        onPress={onMenuPress}
        isActive={isOpen}
      />
      {isOpen && !isMobile && (
        <FloatingWrapper ref={containerRef}>
          {options.map(({ callback: optionCallback, label, dataQa }) => (
            <OverflowMenuPopupOption
              noWrap={noWrap}
              label={label}
              dataQa={dataQa}
              optionCallback={(e: React.MouseEvent<HTMLElement>) => {
                optionCallback(e);
                handleClose();
              }}
              keydownCallback={handleKeydown}
            />
          ))}
        </FloatingWrapper>
      )}
      {isOpen && isMobile && (
        <Tray
          handleClose={handleClose}
          mobileContainerStyle={trayContainerStyles}
          backgroundStyle={backgroundStyle}
          animationSize={animationSize}
        >
          <TrayInnerContainer>
            {options.map(({ callback: optionCallback, label, dataQa }) => (
              <TrayMenuOption
                align="start"
                onPress={(e: React.MouseEvent<HTMLElement>) => {
                  optionCallback(e);
                  handleClose();
                }}
                dataQa={dataQa || label}
              >
                <TextDS style={optionsLabelStyle} variant="body">
                  {label}
                </TextDS>
              </TrayMenuOption>
            ))}
          </TrayInnerContainer>
        </Tray>
      )}
      {isOpen && !isMobile && <MenuBackdrop aria-label="close menu" onPress={handleClose} />}
    </View>
  );
};

export default OverflowMenu;
