import { useEffect } from 'react';
import * as React from 'react';
import { withPanelManager, usePanelManagerActions, useWindowWidth } from '@talkspace/react-toolkit';
import { useHistory, useLocation } from '@/core/routerLib';
import ReactFrameService from '@/auth/reactFrame/ReactFrameService';

interface LocationState {
  postActionRoute?: string;
}

const CRMChatManager: React.FC = ({ children }) => {
  const location = useLocation();
  const history = useHistory();

  const { setIsLeftPanelOnTop } = usePanelManagerActions();

  const { isMobile } = useWindowWidth();

  useEffect(() => {
    if (
      !ReactFrameService.instance().isInFrame() &&
      isMobile &&
      location.pathname.split('/').length > 2
    ) {
      // If switching to mobile on a nested route, keep CRM on top
      const isRoutedToChat = (location.state as LocationState)?.postActionRoute === 'chat';
      const isRoutedToCrm =
        location.pathname.split('/')[3]?.match(/\w+-tab/) ||
        location.pathname.includes('progress-notes');
      if (isRoutedToCrm && !location.pathname.includes('video-call') && !isRoutedToChat) {
        setIsLeftPanelOnTop(true);
      }
    }
  }, [history, isMobile, location.pathname, location.state, setIsLeftPanelOnTop]);

  return <>{children}</>;
};

export default withPanelManager(CRMChatManager);
