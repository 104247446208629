import { createRef, Component } from 'react';
import PropTypes from 'prop-types';
import TextInputAuthView from './TextInputAuthView';

function validateEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
  return re.test(String(email).toLowerCase());
}

function validateNickname(nickname) {
  return nickname && nickname.trim().length !== 0;
}

const formToolsByType = {
  email: {
    title: 'Email',
    validator: validateEmail,
    errorMessage: 'Invalid email.',
  },
  nickname: {
    title: 'Nickname',
    validator: validateNickname,
    errorMessage: 'Please enter a nickname.',
  },
};
class TextInputAuth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isValid: false,
      displayError: false,
    };
    this.assignRef = createRef();
  }

  componentDidMount() {
    const { validateOnMount, value, type, onValidationChanged } = this.props;
    if (validateOnMount && value && value.length > 0) {
      const { validator } = formToolsByType[type] || {};
      const currentIsValid = validator && validator(value);
      if (onValidationChanged) onValidationChanged(currentIsValid);
      if (currentIsValid) this.setState({ isValid: currentIsValid });
    }
  }

  onChange = (e) => {
    this.setState({ displayError: false });
    const { isValid } = this.state;
    const { onChange, onValidationChanged, type } = this.props;
    const { validator } = formToolsByType[type] || {};
    const currentIsValid = validator && validator(e.target.value);
    if (validator && isValid !== currentIsValid) {
      if (onValidationChanged) onValidationChanged(currentIsValid);
      this.setState({ isValid: currentIsValid });
    }
    if (onChange) onChange(e);
  };

  onFocus = (e) => {
    const { onFocus } = this.props;
    if (onFocus) onFocus(e);
  };

  onBlur = (e) => {
    const { onBlur } = this.props;
    const { isValid } = this.state;
    if (!isValid && e.target.value) this.setState({ displayError: true });
    if (onBlur) onBlur(e);
  };

  render() {
    const {
      type,
      innerRef,
      onChangeText,
      onValidationChanged,
      shouldDisplayError,
      errorMessage: propErrorMessage,
      shouldFocus,
      isErrorAlert,
      borderColor,
      dataQa,
      ariaLabel,
      roundedFocusStyle,
      ariaDescribedBy,
      primaryColor,
      ...otherProps
    } = this.props;
    const { isValid, displayError } = this.state;
    const { errorMessage } = formToolsByType[type] || {};
    return (
      <TextInputAuthView
        {...otherProps}
        dataQa={dataQa}
        type={type}
        shouldFocus={shouldFocus}
        assignRef={this.assignRef}
        isErrorAlert={isErrorAlert}
        isValid={isValid}
        innerRef={innerRef}
        onChange={this.onChange}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        errorMessage={propErrorMessage || errorMessage}
        displayError={shouldDisplayError || (type in formToolsByType && displayError)}
        borderColor={borderColor}
        primaryColor={primaryColor}
        roundedFocusStyle={roundedFocusStyle}
        ariaDescribedBy={ariaDescribedBy}
        ariaLabel={ariaLabel}
      />
    );
  }
}

TextInputAuth.propTypes = {
  dataQa: PropTypes.string,
  value: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChangeText: PropTypes.func,
  onChange: PropTypes.func,
  onValidationChanged: PropTypes.func,
  onSubmitEditing: PropTypes.func,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  containerStyle: PropTypes.object, // eslint-disable-line
  innerRef: PropTypes.object, // eslint-disable-line
  validateOnMount: PropTypes.bool,
  borderColor: PropTypes.string,
};

TextInputAuth.defaultProps = {
  dataQa: undefined,
  value: '',
  onFocus: () => null,
  onBlur: () => null,
  type: undefined,
  onChangeText: undefined,
  onChange: undefined,
  onValidationChanged: () => null,
  onSubmitEditing: () => null,
  placeholder: '',
  label: '',
  containerStyle: {},
  validateOnMount: false,
  borderColor: undefined,
};

export default TextInputAuth;
