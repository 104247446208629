import { FunctionComponent } from 'react';

import Svg, { Path, G, Circle, Defs } from 'svgs';
import { useEmotionTheme, EmotionStyle } from '../../core/styled';

interface InsuranceIconProps {
  size?: number;
  primaryColor?: string;
  fillColor?: string;
  dataQa?: string;
  style?: EmotionStyle;
}

const InsuranceIcon: FunctionComponent<InsuranceIconProps> = ({
  size = 147,
  primaryColor,
  fillColor,
  dataQa,
  style,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'hand holding shield, insurance';
  return (
    <Svg
      data-qa={dataQa}
      width={size}
      height={size}
      fill="none"
      viewBox={`0 0 ${size} ${size}`}
      title={titleText}
      aria-label={titleText}
      style={style}
      {...otherProps}
    >
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <G filter="url(#InsuranceIconFilter0_d)">
        <Circle cx="73.5" cy="65.5" r="51.5" fill={fillColor || colors.white} />
      </G>
      <Path
        fill={primaryColor || colors.green}
        fillRule="evenodd"
        d="M44 86.85V65.01a1.404 1.404 0 011.265-1.409c.043-.002.087-.002.13 0 5.75 0 10.279.14 13.954 2.29.54.315.806.953.654 1.563l-.153.573.078-.015.162-.03a10.86 10.86 0 012.068-.154c.14 0 .28.023.414.066l18.754 4.91c1.298.345 2.67.857 3.815 1.717 1.118.84 2.072 2.157 2.115 3.765 3.156-1.077 6.447-2.035 9.79-2.003h.065s1.578.077 3.226.748c.825.336 1.7.817 2.42 1.607A4.82 4.82 0 01104 81.875a1.409 1.409 0 01-.85 1.299 527.98 527.98 0 00-6.762 2.982 650.883 650.883 0 01-7.41 3.27c-2.383 1.027-4.618 1.948-6.453 2.62l-.175.064c-1.743.639-2.982 1.093-4.186 1.08a1.382 1.382 0 01-.392-.065l-22.435-6.87-.218.925a1.398 1.398 0 01-1.351 1.08h-8.373c-.77-.001-1.395-.632-1.395-1.41zm18.038-16.205c-.214-.004-.825-.001-1.468.112-.57.1-1.042.346-1.254.456-.085.044-.128.067-.12.05a1.412 1.412 0 01-.24.396l-2.92 11.845 22.303 6.825c.256-.023 1.57-.31 3.248-.925 1.746-.64 3.941-1.558 6.301-2.576a659.263 659.263 0 007.262-3.201c2.012-.897 3.97-1.77 5.754-2.545a.941.941 0 01-.067-.215c-.025-.113-.052-.228-.129-.313-.326-.358-.846-.67-1.417-.903a8.597 8.597 0 00-2.275-.549l-.014-.001c-3.334-.032-6.986 1.243-10.709 2.543-1.634.57-3.283 1.146-4.924 1.618-.22.063-.452.07-.675.022L66.74 80.466a1.399 1.399 0 01-1.13-.924 1.42 1.42 0 01.296-1.437 1.386 1.386 0 011.4-.391l13.562 2.73a72.942 72.942 0 003.597-1.19v-.858c0-.6-.295-1.076-1.003-1.607-.707-.532-1.772-.995-2.834-1.277l-18.59-4.867zm-5.022-2.706c-2.479-1.12-5.897-1.404-10.225-1.453V85.44h5.887l4.338-17.502z"
        clipRule="evenodd"
      />
      <Path
        fill={primaryColor || colors.green}
        fillRule="evenodd"
        d="M89.715 43.517a3.037 3.037 0 012.284 2.992l-.252 12.813c-.108 6.968-4.609 11.225-9.938 14.366-2.055 1.21-15.616-1.247-18.84-4.875-2.215-2.492-3.678-5.56-3.76-9.491L59 46.504a3.032 3.032 0 012.287-2.981c2.068-.53 4.93-1.294 6.028-1.742 2.27-.923 5.193-3.041 6.855-4.321a2.137 2.137 0 012.612.01c1.57 1.228 4.255 3.206 6.317 4.091 1.22.524 4.397 1.384 6.617 1.956zm-23.569 13.15a2.603 2.603 0 012.604-2.603h4.183V49.76a2.57 2.57 0 115.14 0v4.305h4.184a2.604 2.604 0 110 5.207h-4.183v4.306a2.57 2.57 0 01-5.14 0V59.27H68.75a2.604 2.604 0 01-2.603-2.603z"
        clipRule="evenodd"
      />
      <Defs>
        <filter
          id="InsuranceIconFilter0_d"
          width="147"
          height="147"
          x="0"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feMorphology in="SourceAlpha" radius="2" result="effect1_dropShadow" />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="12" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.117465 0 0 0 0 0.264296 0 0 0 0.201738 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </Defs>
    </Svg>
  );
};

export default InsuranceIcon;
