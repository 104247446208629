import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const standardPath =
  'M10 18.3965C12.1217 18.3965 14.1566 17.5536 15.6569 16.0533C17.1571 14.553 18 12.5182 18 10.3965C18 8.27475 17.1571 6.23992 15.6569 4.73963C14.1566 3.23934 12.1217 2.39648 10 2.39648C7.87827 2.39648 5.84344 3.23934 4.34315 4.73963C2.84285 6.23992 2 8.27475 2 10.3965C2 12.5182 2.84285 14.553 4.34315 16.0533C5.84344 17.5536 7.87827 18.3965 10 18.3965ZM10 6.39648C10.4156 6.39648 10.75 6.73086 10.75 7.14648V10.6465C10.75 11.0621 10.4156 11.3965 10 11.3965C9.58437 11.3965 9.25 11.0621 9.25 10.6465V7.14648C9.25 6.73086 9.58437 6.39648 10 6.39648ZM9 13.3965C9 13.1313 9.10536 12.8769 9.29289 12.6894C9.48043 12.5018 9.73478 12.3965 10 12.3965C10.2652 12.3965 10.5196 12.5018 10.7071 12.6894C10.8946 12.8769 11 13.1313 11 13.3965C11 13.6617 10.8946 13.9161 10.7071 14.1036C10.5196 14.2911 10.2652 14.3965 10 14.3965C9.73478 14.3965 9.48043 14.2911 9.29289 14.1036C9.10536 13.9161 9 13.6617 9 13.3965Z';

const majorPath =
  'M12 3.875C14.1549 3.875 16.2215 4.73102 17.7452 6.25476C19.269 7.77849 20.125 9.84512 20.125 12C20.125 14.1549 19.269 16.2215 17.7452 17.7452C16.2215 19.269 14.1549 20.125 12 20.125C9.84512 20.125 7.77849 19.269 6.25476 17.7452C4.73102 16.2215 3.875 14.1549 3.875 12C3.875 9.84512 4.73102 7.77849 6.25476 6.25476C7.77849 4.73102 9.84512 3.875 12 3.875ZM12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 9.34784 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2C9.34784 2 6.8043 3.05357 4.92893 4.92893C3.05357 6.8043 2 9.34784 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C6.8043 20.9464 9.34784 22 12 22ZM12 7C11.4805 7 11.0625 7.41797 11.0625 7.9375V12.3125C11.0625 12.832 11.4805 13.25 12 13.25C12.5195 13.25 12.9375 12.832 12.9375 12.3125V7.9375C12.9375 7.41797 12.5195 7 12 7ZM13.25 15.75C13.25 15.4185 13.1183 15.1005 12.8839 14.8661C12.6495 14.6317 12.3315 14.5 12 14.5C11.6685 14.5 11.3505 14.6317 11.1161 14.8661C10.8817 15.1005 10.75 15.4185 10.75 15.75C10.75 16.0815 10.8817 16.3995 11.1161 16.6339C11.3505 16.8683 11.6685 17 12 17C12.3315 17 12.6495 16.8683 12.8839 16.6339C13.1183 16.3995 13.25 16.0815 13.25 15.75Z';

const pathConfig: IconConfig = {
  standard: {
    default: {
      path: standardPath,
    },
  },
  major: {
    default: {
      path: majorPath,
    },
  },
};

const CircleExclamation = withDSIconMaker(pathConfig, 'exclamation point inside circle');

export default CircleExclamation;
