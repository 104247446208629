import * as React from 'react';
import { keyframes } from '@emotion/core';
import Svg, { Path, G, Circle } from 'svgs';
import styled, { EmotionStyle } from '../../core/styled';
import { Mini, Big, Massive, Large } from '../Typography';
import View from '../View';
import ErrorBoundary from '../ErrorBoundary';

import { COLORS } from '../../constants/commonStyles';

interface ScoreRange {
  start: number;
  end: number;
  label: string;
}

interface GaugeChartProps {
  score: number;
  prevScore?: number;
  scoreRanges: ScoreRange[];
  max: number;
  shouldRenderLabels?: boolean;
  isScoreReversed?: boolean;
  surveyLabel?: string;
  ariaHidden?: boolean;
  style?: EmotionStyle;
}

// curved path
const PATH = 'M 6.0,101.0 C 6.0,49.085 47.638,7.0 99.0,7.0 C 150.362,7.0 192.0,49.085 192.0,101.0';
const RADIUS = 100;
const CHART_WIDTH = 340;
const CHART_HEIGHT = 180;
const SVG_HEIGHT = 155;
const TOTAL_ARC_LENGTH = 180;
const CHART_PADDING = 20;
const SAFARI_UNSUPPORTED_CSS = `(offset-path: path("${PATH}"))`;

const getDonutKeyFrames = (decimal: number, prevDecimal = 0) =>
  keyframes({
    from: {
      offsetDistance: `${prevDecimal * 100}%`,
      motionOffset: `${prevDecimal * 100}%`,
    },
    to: {
      offsetDistance: `${decimal * 100}%`,
      motionOffset: `${decimal * 100}%`,
    },
  });

const getDonutPathKeyFrames = (decimal: number, prevDecimal: number, shouldAnimate = true) => {
  // 2000 is total arc start, 1700 is end, don't ask me why
  const total = 2000;
  const end = total - decimal * 300;
  const start = total - prevDecimal * 300;
  return keyframes({
    from: {
      strokeDashoffset: start,
    },
    to: {
      strokeDashoffset: shouldAnimate ? end : start,
    },
  });
};

const shouldForwardProp = (prop) => !prop.toLowerCase().includes('progress');
interface ProgressProp {
  progress: number;
  prevProgress: number;
  easing?: string;
}

// on counter-clockwise this moves
const RootPath = styled(Path, { shouldForwardProp })<ProgressProp>(
  ({ progress, prevProgress, easing = '1s ease-in-out forwards' }) => {
    return {
      strokeDasharray: 2000,
      animation: `${getDonutPathKeyFrames(
        progress,
        prevProgress,
        progress < prevProgress
      )} ${easing}`,
    };
  }
);

// on clockwise this moves
const DonutPath = styled(Path, { shouldForwardProp })<ProgressProp>(
  ({ progress, prevProgress, easing = '1s ease-in-out forwards' }) => {
    return {
      strokeDasharray: 2000,
      animation: `${getDonutPathKeyFrames(
        progress,
        prevProgress,
        progress > prevProgress
      )} ${easing}`,
    };
  }
);

const Donut = styled(G, { shouldForwardProp })<ProgressProp>(
  ({ progress, prevProgress, easing = '1s ease-in-out forwards' }) => {
    /* 
      motionPath and offsetPath not supported on Safari. 
      These numbers just work to position the point, not animate
      The next three lines are for Safari only
    */
    const radians = Math.PI + progress * Math.PI;
    const x = Math.cos(radians) * (RADIUS - 6);
    const y = 103 + Math.sin(radians) * (RADIUS - 6);

    return {
      [`@supports ${SAFARI_UNSUPPORTED_CSS}`]: {
        motionPath: `path("${PATH}")`,
        offsetPath: `path("${PATH}")`,
        animation: `${getDonutKeyFrames(progress, prevProgress)} ${easing}`,
      },
      [`@supports not ${SAFARI_UNSUPPORTED_CSS}`]: {
        transform: `translate(${97 + x}px, ${y}px)`,
      },
    };
  }
);

const Tick = ({ angle }) => (
  <G
    stroke="#fff"
    strokeLinecap="square"
    strokeWidth={2}
    transform={`rotate(${angle} ${RADIUS} ${RADIUS})`}
  >
    <Path d={`M0 ${RADIUS} L${RADIUS} ${RADIUS}`} />
  </G>
);

const Label: React.FunctionComponent<{
  start: number;
  end: number;
  label: string;
  angle: number;
}> = ({ start, end, label, angle }) => {
  const radians = Math.PI + (angle * Math.PI) / 180;
  const width = Math.round(60 - Math.sin(radians) * 40); // label gets extra width based on height
  const x = Math.cos(radians) * (RADIUS + 60) + CHART_WIDTH / 2;
  const y = Math.sin(radians) * (RADIUS + 50) + CHART_HEIGHT / 2;
  let textAlign: AlignSetting = 'left';
  if (angle < 140) textAlign = 'center';
  if (angle < 40) textAlign = 'right';
  return (
    <View
      aria-hidden="true"
      style={{ position: 'absolute', left: Math.round(x), top: Math.round(y + 70) }}
    >
      <View style={{ marginLeft: -width / 2, textAlign, width }}>
        <Mini>
          {start} - {end}
        </Mini>
        <Mini>{label}</Mini>
      </View>
    </View>
  );
};

const GaugeChart: React.FunctionComponent<GaugeChartProps> = ({
  score: currScore,
  max,
  prevScore = 0,
  scoreRanges,
  isScoreReversed,
  shouldRenderLabels = true,
  surveyLabel,
  ariaHidden,
  style,
}) => {
  const numRanges = scoreRanges.length || 1; // prevent division my 0
  const labelArcLength = TOTAL_ARC_LENGTH / numRanges; // total arcLength is 180
  const angles = [...Array(numRanges).keys()].map((i) => i * labelArcLength); // 0, 60, 120
  const labelAngles = angles.map((a) => a + labelArcLength / 2); // 30, 90, 150
  const tickAngles = angles.filter(Boolean); // no tick at 0 -> 60, 120

  let scoreLabel = '';
  // function to calculate how far along the arc as a decimal (0-1) to define a progress mark based on a score
  function calculateProgress(score: number): number {
    let progress = 0;
    const targetScoreRangeIndex = scoreRanges.findIndex(
      ({ start, end }) => score >= start && score <= end
    );
    scoreLabel =
      scoreRanges[targetScoreRangeIndex > -1 ? targetScoreRangeIndex : scoreRanges.length - 1]
        .label;
    // add the progress up until the target index
    progress += targetScoreRangeIndex / numRanges;
    // add the progress within the target index
    const targetScoreRange = scoreRanges[targetScoreRangeIndex];
    const progressWithinScore =
      (score - targetScoreRange.start) / (targetScoreRange.end - targetScoreRange.start);
    progress += progressWithinScore / numRanges;
    return progress;
  }

  const prevProgress = prevScore && calculateProgress(prevScore);
  const progress = calculateProgress(currScore);
  let donutColor = COLORS.purple;
  if (prevProgress) {
    if (progress > prevProgress) donutColor = isScoreReversed ? '#76DB70' : '#BB58EF';
    else if (progress < prevProgress) donutColor = isScoreReversed ? '#BB58EF' : '#76DB70';
  }

  const adjustedSVGHeight = shouldRenderLabels ? SVG_HEIGHT : SVG_HEIGHT - CHART_PADDING;
  const titleText = `${surveyLabel || 'Current'} score: ${currScore} out of ${max}, ${scoreLabel}`;
  return (
    <View
      align="center"
      justify="center"
      style={{
        width: shouldRenderLabels ? CHART_WIDTH : 'initial',
        height: shouldRenderLabels ? CHART_HEIGHT : 'initial',
        position: 'relative',
        ...style,
      }}
      aria-hidden={ariaHidden ? 'true' : 'false'}
    >
      <Svg
        width="280px"
        title={titleText}
        aria-label={titleText}
        height={adjustedSVGHeight}
        viewBox={`0 0 280 ${adjustedSVGHeight}`}
      >
        <G
          stroke="none"
          transform={`translate(${CHART_PADDING * 2} ${
            shouldRenderLabels ? CHART_PADDING * 2 : CHART_PADDING
          })`}
          fill="none"
          fillRule="evenodd"
        >
          <Path d={PATH} stroke="#EFF3FA" strokeWidth={5} strokeLinecap="round" />
          <DonutPath
            d={PATH}
            strokeLinecap="round"
            stroke={donutColor}
            strokeWidth={7.12}
            progress={progress}
            prevProgress={prevProgress}
          />
          <RootPath
            strokeLinecap="round"
            d={PATH}
            stroke={COLORS.purple}
            strokeWidth={7.12}
            progress={progress}
            prevProgress={prevProgress}
          />
          {tickAngles.map((a) => (
            <Tick angle={a} key={a} />
          ))}
          <Donut
            stroke={donutColor}
            strokeWidth={6.88}
            progress={progress}
            prevProgress={prevProgress}
          >
            <Circle fill="#FFF" r={9} />
          </Donut>
        </G>
      </Svg>
      {shouldRenderLabels &&
        scoreRanges.map(({ start, end, label }, i) => (
          <Label start={start} end={end} label={label} angle={labelAngles[i]} key={start} />
        ))}
      <View style={{ position: 'relative', top: -70, height: 0 }}>
        <Massive style={{ textAlign: 'center', lineHeight: '42px' }}>{currScore}</Massive>
        <Big variant="bigDarkGrey" style={{ textAlign: 'center', maxWidth: 180 }}>
          {scoreLabel}
        </Big>
      </View>
    </View>
  );
};

const ErrorComponent = () => (
  <View align="center" justify="center" style={{ width: CHART_WIDTH, height: CHART_HEIGHT }}>
    <Large style={{ color: COLORS.red }}>Chart data is invalid</Large>
  </View>
);

const ClinicalProgressLineChart: React.FunctionComponent<GaugeChartProps> = (props) => (
  <ErrorBoundary errorComponent={ErrorComponent}>
    <GaugeChart {...props} />
  </ErrorBoundary>
);

export default ClinicalProgressLineChart;
