import React from 'react';

// This is just an enhanced JSX.Element type, which extends React.Element<any, any>. Enhanced to force children as a prop
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type StringOrJSXElement = string | React.ReactElement<{ children: React.ReactNode }, any>;

export default function getStringOrJSXElementKey(item: StringOrJSXElement, index: number) {
  if (typeof item === 'string') return item;
  return item.props.children?.toString?.() || index.toString();
}
