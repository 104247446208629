import { VoidFunctionComponent } from 'react';
import { Tray, View, Huge, Large, useUniqueID } from '@talkspace/react-toolkit';
import { useCloseModal } from '../../../utils/ModalsContextProvider';
import SparklingCalendarV2 from '../../../../assets/SparklingCalendarV2';

const KeepMessagingModal: VoidFunctionComponent = () => {
  const ID_TITLE = useUniqueID('keepMessagingModalTitle');
  const ID_SUB_TITLE = useUniqueID('keepMessagingModalSubTitle');

  const closeModal = useCloseModal();

  return (
    <Tray handleClose={closeModal}>
      <View align="center">
        <SparklingCalendarV2 />
        <Huge
          as="h1"
          id={ID_TITLE}
          style={{
            textAlign: 'center',
            marginBottom: 20,
            marginTop: 30,
          }}
        >
          Your client has not started a billable messaging session.
        </Huge>

        <Large id={ID_SUB_TITLE} variant="largeDarkGrey" style={{ textAlign: 'center' }}>
          If you believe you are in a messaging session, please ask them to start a messaging
          session.
        </Large>
      </View>
    </Tray>
  );
};

export default KeepMessagingModal;
