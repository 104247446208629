import { useHistory } from 'react-router-dom';
import { View } from '@talkspace/react-toolkit';
import { useEffect, VoidFunctionComponent } from 'react';
import { useQueryClient } from 'react-query';
import useQueryTaskList from 'hooks/dashboard/useQueryTaskList';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { getUserData } from '../../../utils/token';
import dashboardKeys from '../../../hooks/dashboard/queryKeys';
import { SubmitPress, ReviewPress } from '../types';
import TasksTable from './TasksTable';
import styled from '../../../core/styled';
import SocketService from '../../../utils/socket/SocketService';
import trackEvent from '../../../modules/analytics/trackEvent';

const SCROLL_Y_WIDTH = 10;

const Wrapper = styled(View)(({ theme: { window } }) => {
  const { isMobile, isLarge, isDesktop } = window;

  return {
    overflowY: 'scroll',
    overflowX: 'hidden',
    height: `calc(100vh - 68px)`,
    ...(isMobile && {
      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 30,
      paddingBottom: 30,
    }),
    ...((isLarge || isDesktop) && {
      paddingLeft: 30,
      paddingRight: 30 - SCROLL_Y_WIDTH,
      paddingTop: 40,
      paddingBottom: 40,
    }),
  };
});

const Tasks: VoidFunctionComponent = () => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { accessUnsubmittedSessions: { dynamicProgressNoteTasks } = {} } = useFlags();

  const taskList = useQueryTaskList(getUserData().id);

  useEffect(() => {
    const handleTaskRefetch = () =>
      queryClient.invalidateQueries(dashboardKeys.tasklist(getUserData().id));

    SocketService.instance().on('taskUpdate', handleTaskRefetch);

    return () => {
      SocketService.instance().off('taskUpdate', handleTaskRefetch);
    };
  }, [queryClient]);

  const handleSubmitPress = ({
    progressNoteID,
    roomID,
    progressNoteModality,
    videoCallID,
    sessionReportID,
    isEAP,
    taskID,
  }: SubmitPress) => {
    const newQueryString = new URLSearchParams();

    if (progressNoteModality) newQueryString.set('modality', progressNoteModality);
    if (videoCallID) newQueryString.set('videoCallID', videoCallID.toString());
    trackEvent(
      'taskClick',
      { actionName: 'providerTaskInteraction' },
      {
        eventDate: new Date(),
        type: 'progressNote',
        taskID: Number(taskID),
        action: 'submit',
      }
    );
    if (progressNoteID) {
      history.push(`/room/${roomID}/progress-notes/${progressNoteID}/edit`);
    } else if (isEAP) {
      history.push(`/room/${roomID}/progress-notes/new?eapSessionReportID=${sessionReportID}`);
    } else {
      history.push(
        `/room/${roomID}/progress-notes/new${
          newQueryString.toString() ? `?${newQueryString.toString()}` : ''
        }`
      );
    }
  };

  const handleSubmitPressV2 = ({
    progressNoteID,
    roomID,
    unsubmittedSessionID,
  }: {
    progressNoteID: number;
    roomID: number;
    unsubmittedSessionID: string;
  }) => {
    trackEvent(
      'taskClick',
      { actionName: 'providerTaskInteraction' },
      {
        eventDate: new Date(),
        type: 'progressNote',
        unsubmittedSessionID,
        action: 'submit',
      }
    );
    if (progressNoteID) {
      history.push(
        `/room/${roomID}/progress-notes/${progressNoteID}/edit?id=${unsubmittedSessionID}`
      );
    } else {
      history.push(`/room/${roomID}/progress-notes/new?id=${unsubmittedSessionID}`);
    }
  };

  const handleReviewPress = async ({
    roomID,
    therapistID,
    taskID,
    bookingID,
    type,
  }: ReviewPress) => {
    trackEvent(
      'noShowReviewClick',
      {
        actionName: 'providerTaskInteraction',
      },
      {
        roomID,
        providerID: therapistID,
        bookingID,
        type:
          type === 'reviewClientNoShow'
            ? 'No-show Task Review Click'
            : 'Late-cancellation Task Review Click',
        taskID,
      }
    );
  };

  return (
    <Wrapper>
      <TasksTable
        tasks={taskList.data || []}
        isLoading={taskList.isLoading}
        onReviewPress={handleReviewPress}
        onSubmitPress={dynamicProgressNoteTasks ? handleSubmitPressV2 : handleSubmitPress}
      />
    </Wrapper>
  );
};

export default Tasks;
