import Svg, { Circle, Path } from 'svgs';
import { EmotionStyle, useEmotionTheme } from '../../core/styled';

interface ProfileCircleProps {
  width?: number;
  height?: number;
  titleText?: string;
  style?: EmotionStyle;
}

const ProfileCircle = ({
  width = 128,
  height = 128,
  titleText = 'Profile',
  ...otherProps
}: ProfileCircleProps) => {
  const { colors } = useEmotionTheme();
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 56 56"
      title={titleText}
      aria-label={titleText}
      fill="none"
      {...otherProps}
    >
      <Circle cx="28" cy="28" r="28" fill={colors.aquaSqueeze} />
      <Path
        d="M36 37.3333H20V35.4667C20 32.8 25.3333 31.3333 28 31.3333C30.6667 31.3333 36 32.8 36 35.4667M28 21.3333C29.0609 21.3333 30.0783 21.7548 30.8284 22.5049C31.5786 23.2551 32 24.2725 32 25.3333C32 26.3942 31.5786 27.4116 30.8284 28.1618C30.0783 28.9119 29.0609 29.3333 28 29.3333C26.9391 29.3333 25.9217 28.9119 25.1716 28.1618C24.4214 27.4116 24 26.3942 24 25.3333C24 24.2725 24.4214 23.2551 25.1716 22.5049C25.9217 21.7548 26.9391 21.3333 28 21.3333M28 16C28.3536 16 28.6928 16.1405 28.9428 16.3905C29.1929 16.6406 29.3333 16.9797 29.3333 17.3333C29.3333 17.687 29.1929 18.0261 28.9428 18.2762C28.6928 18.5262 28.3536 18.6667 28 18.6667C27.6464 18.6667 27.3072 18.5262 27.0572 18.2762C26.8071 18.0261 26.6667 17.687 26.6667 17.3333C26.6667 16.9797 26.8071 16.6406 27.0572 16.3905C27.3072 16.1405 27.6464 16 28 16M37.3333 16H31.76C31.2 14.4533 29.7333 13.3333 28 13.3333C26.2667 13.3333 24.8 14.4533 24.24 16H18.6667C17.9594 16 17.2811 16.281 16.781 16.7811C16.281 17.2812 16 17.9594 16 18.6667V37.3333C16 38.0406 16.281 38.7189 16.781 39.219C17.2811 39.7191 17.9594 40 18.6667 40H37.3333C38.0406 40 38.7189 39.7191 39.219 39.219C39.719 38.7189 40 38.0406 40 37.3333V18.6667C40 17.9594 39.719 17.2812 39.219 16.7811C38.7189 16.281 38.0406 16 37.3333 16Z"
        fill={colors.permaTalkspaceDarkGreen}
      />
    </Svg>
  );
};

export default ProfileCircle;
