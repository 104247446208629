import Svg, { Path } from 'svgs';
import { FunctionComponent } from 'react';

import { useEmotionTheme } from '../../core/styled';

interface VideoModalityProps {
  width?: number;
  height?: number;
  color?: string;
}

const VideoModality: FunctionComponent<VideoModalityProps> = ({
  width = 24,
  height = 15,
  color,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'video';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 24 15"
      {...otherProps}
    >
      <Path
        d="M12.13 0a3.528 3.528 0 013.528 3.528v7.794a3.528 3.528 0 01-3.528 3.528H3.528A3.528 3.528 0 010 11.322V3.528A3.528 3.528 0 013.528 0h8.602zm11.325 2.823c.112.206.17.437.17.671v7.316a1.411 1.411 0 01-2.083 1.242l-4.045-2.189a1.411 1.411 0 01-.74-1.24v-2.94c0-.519.284-.995.74-1.242l4.045-2.188a1.411 1.411 0 011.913.57z"
        fill={color || colors.green}
        fillRule="evenodd"
      />
    </Svg>
  );
};

export default VideoModality;
