import moment from 'moment';

import { SessionModality, Subscription, VideoCreditOffer } from 'ts-frontend/types';
import {
  Huge,
  SparklingAudioPlus,
  SparklingChatPlus,
  SparklingVideoPlus,
  Small,
  View,
} from '@talkspace/react-toolkit';

interface Props {
  modality: SessionModality;
  creditOptions: VideoCreditOffer[] | undefined;
  subscription: Subscription | undefined;
}
const NoCreditsDTEHeading = ({ modality, creditOptions, subscription }: Props) => {
  const introCredit = creditOptions?.find((credit) => credit.type === 'introduction');
  const priceDollars = creditOptions?.find((credit) => credit.type === 'therapy')?.price || 0;
  const creditRenewalDate = subscription?.subscription.creditRenewalDate;
  const creditMonthlyCount = subscription?.subscription.creditMonthlyCount;

  let IllustrationComponent;
  switch (modality) {
    case 'video':
      IllustrationComponent = SparklingVideoPlus;
      break;
    case 'audio':
      IllustrationComponent = SparklingAudioPlus;
      break;
    case 'chat':
      IllustrationComponent = SparklingChatPlus;
      break;
    case 'messaging':
      IllustrationComponent = SparklingChatPlus;
      break;
  }

  if (!creditMonthlyCount || !creditRenewalDate) return null;
  return (
    <View align="center" style={{ width: 314 }}>
      <IllustrationComponent height={120} width={120} style={{ marginTop: 30 }} />
      <Huge style={{ textAlign: 'center', marginTop: 16 }}>
        {introCredit
          ? 'Heads up! You might be charged for this session.'
          : "Heads up! You'll be charged for this session."}
      </Huge>
      <Small style={{ textAlign: 'center', margin: '12px 0 30px' }}>
        You've already booked your {creditMonthlyCount} monthly{' '}
        {creditMonthlyCount === 1 ? 'session' : 'sessions'}, renewing on{' '}
        {moment(creditRenewalDate).format('MMMM Do')}. Don't want to wait until then?{' '}
        {introCredit
          ? `Use your ${introCredit.creditMinutes} minute free introduction call or book additional full-length sessions for $${priceDollars} per session.`
          : `Additional sessions can be booked for $${priceDollars} per session.`}
      </Small>
    </View>
  );
};

export default NoCreditsDTEHeading;
