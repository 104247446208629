import { useCallback, useEffect, useState } from 'react';
import { PasswordInputMode } from 'ts-frontend/types';

import useTranslation from '@talkspace/i18n/hooks/useTranslation';
import { checkPasswordValidity } from 'ts-frontend/helpers';
import useStringTranslation from '@talkspace/i18n/hooks/useStringTranslation';
import { PasswordProps } from '../components/Password';

export interface PasswordHookStateAndActions {
  password: string;
  passwordError: string | undefined;
  showPasswordError: boolean;
  /**
   * Returns error message if invalid, otherwise returns empty string (`''`)
   * If errors are found, it forces the error message to show up
   */
  isPasswordInvalid: () => string | undefined;
  setPasswordError: (error: string | undefined, shouldFocus?: boolean) => void;
  setShowPasswordError: (showPasswordError: boolean) => void;
  /**
   * Performs onSubmit validations, shows errors if any are found, and focuses passwords if invalid, unless
   * `ignoreAutoFocus` is true. Invokes isPasswordInvalid.
   * Returns true if password was validated properly. Returns false if password validation failed.
   */
  onSubmitPassword: (ignoreAutoFocus?: boolean) => boolean;
}

/**
 * Ensure password follows these rules:
 * 1 - Password validation and errors are displayed while typing (onChange)
 * 2 - Password validation and errors are displayed again if attempting to submit
 * 3 - Field is autofocused if there are errors when submitting
 * 4 - Password validation changes depending on PasswordInputMode (LOGIN | CREATE_PASSWORD | CONFIRM_PASSWORD)
 */
export const usePasswordValidation = (options?: {
  /**
   * Password should not be equal to the email
   */
  email?: string;
  /**
   * Password should not be or similar to the nickname
   */
  nickname?: string;
  /**
   * Validation changes depending on the passwordInputMode
   */
  passwordInputMode: PasswordInputMode;
  /**
   * In the case where the passwordInputMode === PasswordInputMode.CONFIRM_PASSWORD,
   * `passwordToCompare` would be the previously entered password to use for comparison.
   */
  passwordToCompare?: string;
}): [
  PasswordHookStateAndActions,
  Pick<
    PasswordProps,
    | 'name'
    | 'value'
    | 'onFocus'
    | 'userInputs'
    | 'placeholder'
    | 'shouldFocus'
    | 'onChangeText'
    | 'errorMessage'
    | 'isNewPassword'
    | 'shouldDisplayError'
    | 'shouldCheckStrength'
    | 'isConfirmationInput'
  >
] => {
  const { passwordInputMode, email, nickname, passwordToCompare } = options || {};
  const [password, setPassword] = useState<string | null>(null);
  const [passwordError, setPasswordError] = useState<string | undefined>(undefined);
  const [showPasswordError, setShowPasswordError] = useState(false);
  const [userInputs, setUserInputs] = useState<Array<string>>([]);
  const [shouldFocus, setShouldFocus] = useState(false);
  const [zxcvbnIsValid, setZxcvbnIsValid] = useState(true);
  const [zxcvbnErrorMessage, setZxcvbnErrorMessage] = useState('');
  const { t: tErrorsCommon } = useTranslation('errors.common');

  useEffect(() => {
    setShowPasswordError(!!passwordError);
  }, [passwordError]);

  useEffect(() => {
    if (password === null) return;

    let passwordErrorMessage = checkPasswordValidity(
      password,
      email,
      nickname,
      {
        passwordToCompare,
        passwordInputMode,
        ignoreStrengthCheck: true,
      },
      tErrorsCommon
    );

    // zxcvbn validation is done in the Password component
    if (!passwordErrorMessage && !zxcvbnIsValid) {
      passwordErrorMessage = zxcvbnErrorMessage;
    }
    setPasswordError(passwordErrorMessage || undefined);
  }, [
    email,
    nickname,
    password,
    passwordInputMode,
    passwordToCompare,
    tErrorsCommon,
    zxcvbnErrorMessage,
    zxcvbnIsValid,
  ]);

  useEffect(() => {
    const newUserInputs: Array<string> = [];
    if (email) newUserInputs.push(email);
    if (nickname) newUserInputs.push(nickname);
    setUserInputs(newUserInputs);
  }, [email, nickname]);

  const onFocus = () => {
    setShouldFocus(false);
  };

  const isPasswordInvalid = useCallback(() => {
    if (passwordError) {
      if (!showPasswordError) setShowPasswordError(true);
      return passwordError;
    }
    if (!password) {
      // Handles the case where the user tried to submit without triggering the onChange handler.
      const newPasswordError = checkPasswordValidity(
        password || '',
        email,
        nickname,
        {
          passwordToCompare,
          passwordInputMode,
          ignoreStrengthCheck: true,
        },
        tErrorsCommon
      );
      setPasswordError(newPasswordError);
      return newPasswordError;
    }
    return '';
  }, [
    email,
    nickname,
    password,
    passwordError,
    passwordInputMode,
    passwordToCompare,
    showPasswordError,
    tErrorsCommon,
  ]);

  const onChangeText: PasswordProps['onChangeText'] = (newPassword, isValid, { errorMessage }) => {
    setShouldFocus(false);
    setPassword(newPassword);
    setZxcvbnIsValid(isValid);
    setZxcvbnErrorMessage(errorMessage);
  };

  const onSubmitPassword = useCallback(
    (ignoreAutoFocus?: boolean) => {
      if (isPasswordInvalid()) {
        if (ignoreAutoFocus !== true) setShouldFocus(true);
        return false;
      }
      return true;
    },
    [isPasswordInvalid]
  );

  const stateAndActions: PasswordHookStateAndActions = {
    password: password || '',
    passwordError,
    showPasswordError,
    isPasswordInvalid,
    setPasswordError: useCallback((newPasswordError, newShouldFocus) => {
      setPasswordError(newPasswordError);
      if (newShouldFocus !== undefined) setShouldFocus(newShouldFocus);
    }, []),
    setShowPasswordError,
    onSubmitPassword,
  };

  let name: string | undefined;
  let placeholder: string;

  const { t: tReactToolkit } = useTranslation('react-toolkit');
  const newPasswordInputPlaceholder = useStringTranslation(
    'Enter new password',
    'react-toolkit',
    'RHFPassword.newPasswordInputPlaceholder'
  );

  if (passwordInputMode === PasswordInputMode.CHANGE_PASSWORD) {
    name = 'new-password';
    placeholder = newPasswordInputPlaceholder;
  } else if (passwordInputMode === PasswordInputMode.CONFIRM_PASSWORD) {
    name = 'new-password';
    placeholder = tReactToolkit(
      'RHFPassword.confirmPasswordInputPlaceholder',
      'Confirm new password',
      undefined
    );
  } else {
    name = 'password';
    placeholder = tReactToolkit(
      'RHFPassword.passwordInputPlaceholder',
      'Enter password',
      undefined
    );
  }

  return [
    stateAndActions,
    {
      name,
      userInputs,
      shouldFocus,
      placeholder,
      value: password || '',
      errorMessage: passwordError,
      shouldDisplayError: showPasswordError,
      isNewPassword:
        passwordInputMode === PasswordInputMode.CREATE_ACCOUNT ||
        passwordInputMode === PasswordInputMode.CHANGE_PASSWORD ||
        passwordInputMode === PasswordInputMode.CONFIRM_PASSWORD,
      shouldCheckStrength:
        passwordInputMode === PasswordInputMode.CREATE_ACCOUNT ||
        passwordInputMode === PasswordInputMode.CHANGE_PASSWORD,
      isConfirmationInput: passwordInputMode === PasswordInputMode.CONFIRM_PASSWORD,
      onFocus,
      onChangeText,
    },
  ];
};
