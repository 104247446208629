import Svg, { Rect, G, Path } from 'svgs';
import { EmotionStyle, useEmotionTheme } from '../../core/styled';

const Lock = ({
  width = 11,
  height = 13,
  color,
  ...otherProps
}: {
  width?: number;
  height?: number;
  color?: string;
  style?: EmotionStyle;
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'lock';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 11 13"
      {...otherProps}
    >
      <G
        transform="translate(-1500.000000, -282.000000) translate(1450.000000, 275.000000) translate(50.000000, 7.000000)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <Path
          d="M9 5H8V3.5a2.5 2.5 0 0 0-5 0V5H2a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1z"
          stroke={color || colors.white}
          strokeWidth={2}
        />
        <Rect fill={color} x={2} y={4} width={7} height={2} />
      </G>
    </Svg>
  );
};

export default Lock;
