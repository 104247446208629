import { Small, styled } from '@talkspace/react-toolkit';
import { useHistory } from 'react-router';
import { useCallback, useEffect, useState } from 'react';
import useLocationStateOnMount from 'hooks/useLocationStateOnMount';
import useMutationSubmitDischargeNote, {
  SubmitDischargeNoteMutateVariables,
} from 'hooks/notes/useMutationSubmitDischargeNoteV3';
import { ACTION_SETTLED_TIMEOUT } from './utils';
import NoteDialogV2 from './NoteDialogV2';
import DischargeSuccess from './DischargeSuccess';
import DischargeFailure from './DischargeFailure';

const SmallText = styled(Small)({
  marginBottom: 12,
});

const title = 'New discharge note';

interface DischargeSubmissionConfirmationLocationStateProps
  extends SubmitDischargeNoteMutateVariables {
  mode?: 'create' | 'edit';
}

const DischargeSubmissionConfirmation = ({ clientUserID, location, match }) => {
  const history = useHistory();

  const { roomID } = match.params;
  const locationStateOnMount =
    useLocationStateOnMount<DischargeSubmissionConfirmationLocationStateProps>();

  useEffect(() => {
    if (locationStateOnMount === null) {
      history.push(`/room/${roomID}/notes-tab`);
    }
  }, [locationStateOnMount, history, roomID]);

  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
  const [showFailureMessage, setShowFailureMessage] = useState<boolean>(false);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);

  const { formState, noteID } = location?.state || { formState: undefined, noteID: undefined };

  const { mutate: submitDischargeNote } = useMutationSubmitDischargeNote();

  const handleActionSettled = (data, err) => {
    if (err) {
      setShowFailureMessage(true);
    } else {
      setShowSuccessMessage(true);
    }
    setTimeout(() => {
      history.push(`/rooms`);
    }, ACTION_SETTLED_TIMEOUT);
  };

  const handleConfirm = () => {
    if (locationStateOnMount) {
      setShowSpinner(true);
      submitDischargeNote(
        { roomID, noteID, clientUserID, ...formState! },
        {
          onSettled: handleActionSettled,
        }
      );
    }
  };

  const getPostSubmissionComponent = useCallback(
    () =>
      showSuccessMessage ? <DischargeSuccess title={title} /> : <DischargeFailure title={title} />,
    [showSuccessMessage]
  );

  const onGoBackPress = useCallback(() => {
    const prevPath = location.pathname.replace(
      'confirm-discharge',
      locationStateOnMount?.mode === 'create' ? 'new' : `${locationStateOnMount?.noteID}/edit`
    );
    history.replace(prevPath, { ...location.state, ...locationStateOnMount });
  }, [history, location, locationStateOnMount]);

  return (
    <>
      {showSuccessMessage || showFailureMessage ? (
        getPostSubmissionComponent()
      ) : (
        <NoteDialogV2
          showSpinner={showSpinner}
          title={title}
          text="Are you sure you want to discharge this client?"
          subtextContainer={
            <SmallText variant="smallDarkGrey">
              Once you discharge a client, you will:
              <ul>
                <li>remove the client from your caseload</li>
                <li>lose the ability to contact this client through the platform</li>
              </ul>
            </SmallText>
          }
          mainButtonOptions={{
            text: 'Discharge client',
            onPress: () => {
              handleConfirm();
            },
            dataQa: 'dischargeClientButton',
          }}
          alternativeButtonOptions={{
            text: 'Go back',
            onPress: onGoBackPress,
            dataQa: 'goBackButton',
          }}
        />
      )}
    </>
  );
};

export default DischargeSubmissionConfirmation;
