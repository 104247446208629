export const COLORS = {
  black: '#222F2D',
  grey: '#65727D',
  lightGrey: '#BAC2CF',
  a11yLinkWaterGrey: '#EFF3FA',
  extraLightGrey: '#D8D8E0',
  placeholderGrey: '#98A6B2',
  white: '#fff',
  green: '#0FC1A7',
  darkGreen: '#09B198',
  pink: '#E8006B',
  red: '#FF0050',
  orange: '#EA9436',
  lightPurple: '#8EA8FD',
  purple: '#5C84FB',
  darkPurple: '#426FF9',
  oxfordBlue: '#2D3849',
  mirage: '#131C29',
  slateGrey: '#68768D',
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {};
