import { withVersioning } from '../../utils';
import { VersionMap } from '../../types';
import Card from './Card';
import { CardProps, CardVariant } from './types';

const versionMap: VersionMap<React.ComponentProps<typeof Card>> = {
  DefaultComponent: Card,
};

export type { CardProps, CardVariant };

export default withVersioning(versionMap);
