import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const standardPath =
  'M14.0476 8.31554C11.1355 13.2077 7.89571 15.5905 4.92414 16.5438C4.39825 16.7125 3.83517 16.4229 3.66647 15.897C3.49777 15.3711 3.78733 14.8081 4.31322 14.6394C6.81528 13.8367 9.84728 11.6979 12.6634 6.71665L9.6737 7.18934C9.18426 7.19609 8.75271 6.84331 8.67398 6.3454C8.5876 5.79906 8.95921 5.28788 9.50555 5.2015L14.4442 4.42067C14.9905 4.33429 15.5017 4.7059 15.5881 5.25224L16.3689 10.1909C16.4553 10.7372 16.0837 11.2484 15.5374 11.3348C14.991 11.4212 14.4798 11.0496 14.3935 10.5032L14.0476 8.31554Z';

const pathConfig: IconConfig = {
  standard: {
    default: {
      path: standardPath,
    },
  },
};

const TrendArrow = withDSIconMaker(pathConfig, 'trend arrow');

export default TrendArrow;
