import { View } from '@talkspace/react-toolkit';
import { useEffect, useState } from 'react';
import { InRoomSchedulingProvider } from 'inRoomScheduling';
import styled from '@/core/styled';
import ProgressNoteForm from '../../../Room/CRMContainer/NotesTab/ProgressNoteForm';
import { useHistory } from '@/core/routerLib/routerLib';

const Wrapper = styled(View)(({ theme: { colors } }) => {
  return {
    width: '100%',
    height: '100%',
    position: 'fixed',
    background: 'rgba(104, 118, 141, 0.5)',
    top: 0,
    left: 0,
  };
});

const InnerContainer = styled(View)(
  ({
    theme: {
      colors,
      window: { isMobile },
    },
  }) => {
    return {
      width: isMobile ? '100%' : 375,
      height: '100dvh',
      background: colors.white,
    };
  }
);

const ProgressNoteModal = () => {
  const history = useHistory();
  const [isProgressNoteModalOpen, setIsProgressNoteModalOpen] = useState<boolean>(false);

  useEffect(() => {
    const updateModalState = (location) => {
      const isInModalPath = location.pathname.includes('/progress-notes');
      setIsProgressNoteModalOpen(isInModalPath);
    };

    updateModalState(history.location);

    const unlisten = history.listen(updateModalState);

    return () => unlisten();
  }, [history]);

  const mode = history.location.pathname.includes('/edit') ? 'edit' : 'create';

  if (!isProgressNoteModalOpen) {
    return null;
  }

  return (
    <Wrapper>
      <InnerContainer>
        <InRoomSchedulingProvider>
          <ProgressNoteForm mode={mode} />
        </InRoomSchedulingProvider>
      </InnerContainer>
    </Wrapper>
  );
};

export default ProgressNoteModal;
