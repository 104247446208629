import { View, ExtraHuge, Spinner } from '@talkspace/react-toolkit';
import { PaymentForm } from 'checkout';
import { useEffect, useMemo, VoidFunctionComponent } from 'react';
import { useQueryClient } from 'react-query';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { ChargeType } from 'ts-frontend/types';
import {
  trackFailedToPayPendingPayments,
  trackPaidPendingPayments,
} from 'ts-analytics/mixpanel/events';
import { ETherapistInfo } from 'ts-frontend/entities/Therapist';
import { useHistory, useLocation, useParams, useRouteMatch } from '@/core/routerLib';

import { useStripeTs } from '../../../stripe/stripeContext';
import useMutationPayInvoices from '../../hooks/useMutationPayInvoices';
import useQueryClientTransactions from '../../hooks/useQueryClientTransactions';
import { clientTransactionsQueryKey } from '../../utils/queryKeys';
import TotalPendingInvoices from '../TotalPendingInvoices';

type TherapistInfo = Pick<ETherapistInfo, 'id' | 'firstName'>;

interface Props {
  clientUserID: number;
  onLoadStripeLink: () => void;
  onStripeLinkError: (error: unknown) => void;
  therapist?: TherapistInfo;
}

const PendingInvoicesForm: VoidFunctionComponent<Props> = ({
  clientUserID,
  onLoadStripeLink,
  onStripeLinkError,
  therapist,
}) => {
  const stripe = useStripeTs();
  const history = useHistory();
  const match = useRouteMatch();
  const { roomID } = useParams<{ roomID: string }>();
  const location = useLocation();
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const source = params.get('source');
  const { pendingInvoicesChargeTypes } =
    useFlags<{ pendingInvoicesChargeTypes: Array<ChargeType> }>();

  const {
    isLoading: isPendingInvoicesLoading,
    data: pendingInvoices,
    isError: isPendingInvoicesError,
  } = useQueryClientTransactions(
    {
      clientUserID,
      chargeTypes: pendingInvoicesChargeTypes,
    },
    { enabled: pendingInvoicesChargeTypes && pendingInvoicesChargeTypes.length > 0 }
  );

  const resetError = () => {};

  const {
    mutate: payInvoices,
    isLoading: isPayInvoicesLoading,
    error: payInvoicesError,
    isSuccess: isPayInvoicesSuccess,
  } = useMutationPayInvoices();

  const queryClient = useQueryClient();

  useEffect(() => {
    if (
      (!source ||
        !['updatePaymentMethodWithPendingInvoices', 'updatePaymentMethod'].includes(source)) &&
      !isPayInvoicesSuccess &&
      (pendingInvoicesChargeTypes?.length === 0 ||
        pendingInvoices?.length === 0 ||
        isPendingInvoicesError)
    ) {
      history.push(`/in-room-scheduling/room/${roomID}`);
    }
  }, [
    history,
    roomID,
    pendingInvoicesChargeTypes?.length,
    pendingInvoices?.length,
    isPayInvoicesSuccess,
    isPendingInvoicesError,
    source,
  ]);

  const handleSubmit = async (cardToken: string) => {
    if (pendingInvoices) {
      payInvoices(
        {
          clientUserID,
          invoiceIDs: pendingInvoices.map((it) => it.invoiceID!),
          cardToken,
        },
        {
          onSettled: async () => {
            await queryClient.invalidateQueries({
              queryKey: clientTransactionsQueryKey({
                clientUserID,
                chargeTypes: pendingInvoicesChargeTypes,
              }),
            });
          },
          onError: () => {
            if (therapist?.id) {
              trackFailedToPayPendingPayments({
                userID: clientUserID,
                roomID: Number(roomID),
                providerID: therapist.id,
              });
            }
          },
          onSuccess: () => {
            queryClient.setQueryData(
              clientTransactionsQueryKey({
                clientUserID,
                chargeTypes: pendingInvoicesChargeTypes,
              }),
              []
            );
            if (therapist?.id) {
              trackPaidPendingPayments({
                userID: clientUserID,
                roomID: Number(roomID),
                providerID: therapist.id,
              });
            }
            const url = match.url.replace(/pay$/, 'success');
            history.push(`${url}?${params.toString()}`);
          },
        }
      );
    }
  };

  if (isPendingInvoicesLoading || !pendingInvoices) {
    return <Spinner />;
  }

  return (
    <View>
      <ExtraHuge style={{ marginBottom: 20 }}>Update payment details</ExtraHuge>
      {pendingInvoices?.length > 0 && (
        <TotalPendingInvoices pendingInvoices={pendingInvoices} style={{ marginBottom: 30 }} />
      )}
      <PaymentForm
        onSubmit={handleSubmit}
        onLoadStripeLink={onLoadStripeLink}
        onStripeLinkError={onStripeLinkError}
        resetError={resetError}
        isEmailDisabled
        isEmailRequired={false}
        provider="Stripe"
        submitText="Save"
        errorMessage={payInvoicesError?.message}
        isProcessing={isPayInvoicesLoading}
        stripe={stripe}
        useStripeLink
      />
    </View>
  );
};

export default PendingInvoicesForm;
