import { useMutation } from 'react-query';

import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';

interface DeclineBookingParams {
  reason: string;
}

interface DeclineBookingMutateVariables {
  roomID: string;
  bookingID: string;
  data: DeclineBookingParams;
}

const declineBooking = async ({
  roomID,
  bookingID,
  data,
}: DeclineBookingMutateVariables): Promise<void> => {
  await apiWrapper.patch(`${apiHelper().apiEndpoint}/v4/rooms/${roomID}/bookings/${bookingID}`, {
    ...data,
    action: 'decline',
  });
};

const useMutationDeclineBooking = () =>
  useMutation<void, Error, DeclineBookingMutateVariables>(declineBooking);

export default useMutationDeclineBooking;
