import Svg, { Circle, Path } from 'svgs';

interface Props {
  width?: string | number;
  height?: string | number;
  color?: string;
}
const HoursAwayFromEarningIcon = ({ width = 24, height = 24, color = '#3C8703' }: Props) => (
  <Svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <Circle cx="12" cy="12" r="12" fill={color} />
    <Path
      d="M12.5 4.26529L12.5044 4.26091L12.4352 4.19021C12.2553 4.00641 12.0118 3.9 11.752 3.9C11.4922 3.9 11.2487 4.00641 11.0688 4.19021L11.068 4.19111L5.16184 10.3785C5.16183 10.3785 5.16181 10.3785 5.1618 10.3785C4.79999 10.7561 4.81571 11.354 5.19337 11.712C5.57031 12.0693 6.1679 12.0583 6.5264 11.6803C6.52644 11.6802 6.52649 11.6802 6.52654 11.6801L10.8083 7.19902V18.9062C10.8083 19.4291 11.2292 19.85 11.752 19.85C12.2748 19.85 12.6958 19.4291 12.6958 18.9062V7.1993L16.9742 11.6839L16.9743 11.684C17.336 12.0615 17.9299 12.0731 18.3071 11.7155C18.6853 11.3571 18.6964 10.7594 18.339 10.3824L18.3388 10.3821L12.5 4.26529Z"
      fill="white"
      stroke="white"
      stroke-width="0.2"
    />
  </Svg>
);

export default HoursAwayFromEarningIcon;
