import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { states, countries } from '@talkspace/configs';
import { Styles, ValueType } from 'react-select';
import { OptionType } from '../Select';
import styled, { EmotionTheme, useEmotionTheme } from '../../core/styled';
import { webOnlyStyle } from '../../core/styleHelpers';
import { useUniqueID } from '../../hooks/a11yHelper';
import { Tiny } from '../Typography';
import View from '../View';
import Label from '../Label';
import SelectRounded from '../SelectRounded';

const SpaceBetween = styled(View)({
  width: 10,
});

const ErrorLabel = styled(Tiny)<{ showError: boolean }>(({ theme: { colors }, showError }) => {
  return {
    color: colors.torchRed,
    padding: showError ? 4 : 0,
    height: showError ? 24 : 0,
    opacity: showError ? 1 : 0,
    textAlign: 'left',
    // omit width transition
    ...webOnlyStyle({ transition: 'padding .25s, height .25s, opacity .25s' }),
  };
});

const CountryStateDropDownContainer = styled(View)<{ maxWidth: number }>(({ maxWidth }) => {
  return {
    flexDirection: 'row',
    alignItems: 'baseline',
    marginBottom: 18,
    maxWidth,
  };
});

export interface CountryStateDropDownProps {
  maxWidth: number;
  onChangeCountry: (value: string | null) => void;
  onChangeState: (value: string | null) => void;
  dataQa?: string;
  countryValue?: string;
  stateValue?: string;
  showCountryError?: boolean;
  showStateError?: boolean;
  isDisabled?: boolean;
}

const topCountries: OptionType[] = [
  { label: 'United States', value: 'US' },
  { label: 'United Kingdom', value: 'GB' },
  { label: 'Canada', value: 'CA' },
  { label: 'Australia', value: 'AU' },
  { label: '', value: '', isDisabled: true },
];

const findOptionValue = (optionsArr: OptionType[], targetValue: string | undefined) =>
  optionsArr.find((option) => option.value === targetValue);

const getCountryDropdownStyles = (
  colors: EmotionTheme['colors'],
  maxWidth: number
): Styles<OptionType, boolean> => {
  return {
    option: (provided, { data }) => {
      return {
        ...provided,
        ...(data.isDisabled && {
          cursor: 'default',
          backgroundColor: colors.permaLinkWaterGrey,
          boxShadow: 'none',
          marginTop: 2,
          padding: 0,
          '&:hover': {
            backgroundColor: colors.permaLinkWaterGrey,
          },
        }),
      };
    },
    menu: (provided) => {
      return {
        ...provided,
        width: maxWidth,
      };
    },
  };
};

const CountryStateDropDown: FunctionComponent<CountryStateDropDownProps> = ({
  countryValue = undefined,
  stateValue = undefined,
  onChangeCountry,
  onChangeState,
  maxWidth,
  dataQa,
  showCountryError = false,
  showStateError = false,
  isDisabled = false,
}) => {
  const countryOptions = useMemo(() => {
    const memoCountryOptions = Object.entries(countries)
      .map(([value, label]) => {
        return { value, label };
      })
      .filter(({ value }) => !topCountries.map((option) => option.value).includes(value));
    return topCountries.concat(memoCountryOptions);
  }, []);

  const stateOptions = useMemo(
    () =>
      Object.entries(states).map(([value, label]) => {
        return { value, label };
      }),
    []
  );

  const [country, setCountry] = useState<OptionType<string>>(
    () =>
      findOptionValue(countryOptions, countryValue) || {
        label: 'United States',
        value: 'US',
      }
  );
  const [state, setState] = useState<OptionType<string> | null>(
    () => findOptionValue(stateOptions, stateValue) || null
  );
  const [showStates, setShowStates] = useState(true);
  const { colors } = useEmotionTheme();
  const statesWidth = Math.round(maxWidth / 2) - 5;
  const countryInputId = useUniqueID('countryInputId');
  const stateInputId = useUniqueID('stateInputId');
  const countryInputErrorId = useUniqueID('countryInputErrorId');
  const stateInputErrorId = useUniqueID('stateInputErrorId');

  useEffect(() => {
    if (country?.value === 'US') {
      setShowStates(true);
    } else {
      setShowStates(false);
    }
  }, [country]);

  const onChangeCountryHandler = (option: ValueType<OptionType, boolean>) => {
    if ((option as OptionType)?.value !== 'US') {
      onChangeState(null);
    }
    setCountry(option as OptionType);
    onChangeCountry((option as OptionType)?.value ?? null);
  };

  const onChangeStateHandler = (option: ValueType<OptionType, boolean>) => {
    setState(option as OptionType);
    onChangeState((option as OptionType)?.value ?? null);
  };

  return (
    <CountryStateDropDownContainer maxWidth={maxWidth}>
      <View>
        <Label htmlFor={countryInputId} style={{ marginBottom: -16 }}>
          Country
        </Label>
        <SelectRounded
          aria-labelledby={showCountryError ? countryInputErrorId : undefined}
          inputId={countryInputId}
          placeholder="Country"
          onChange={onChangeCountryHandler}
          value={country}
          options={countryOptions}
          isOptionDisabled={(option) => option.isDisabled || false}
          styles={getCountryDropdownStyles(colors, maxWidth)}
          width={showStates ? statesWidth : maxWidth}
          autosize={false}
          isError={showCountryError}
          dataQa={`${dataQa}1`}
          isDisabled={isDisabled}
        />
        <ErrorLabel
          id={countryInputErrorId}
          showError={showCountryError}
          style={{ maxWidth: showStates ? statesWidth : maxWidth }}
        >
          {showCountryError ? 'Please enter a country.' : ''}
        </ErrorLabel>
      </View>
      {showStates && (
        <>
          <SpaceBetween />
          <View>
            <Label htmlFor={stateInputId} style={{ marginBottom: -16 }}>
              State
            </Label>
            <SelectRounded
              aria-labelledby={showStateError ? stateInputErrorId : undefined}
              inputId={stateInputId}
              placeholder="State"
              onChange={onChangeStateHandler}
              value={state}
              options={stateOptions}
              styles={{
                menu: (provided) => {
                  return {
                    ...provided,
                    width: maxWidth,
                    position: 'absolute',
                    right: 1,
                  };
                },
              }}
              width={statesWidth}
              autosize={false}
              isError={showStateError}
              dataQa={`${dataQa}2`}
              isDisabled={isDisabled}
            />
            <ErrorLabel
              id={stateInputErrorId}
              showError={showStateError}
              style={{ maxWidth: statesWidth }}
            >
              {showStateError ? 'Please enter a state.' : ''}
            </ErrorLabel>
          </View>
        </>
      )}
    </CountryStateDropDownContainer>
  );
};

export default CountryStateDropDown;
