import { VoidFunctionComponent } from 'react';
import Svg, { Path } from 'svgs';

import { useEmotionTheme, EmotionStyle } from '../../core/styled';

interface Props {
  style?: EmotionStyle;
}

const CaretUp: VoidFunctionComponent<Props> = ({ style = {} }) => {
  const { colors } = useEmotionTheme();
  return (
    <Svg width="12px" height="8px" viewBox="0 0 12 8" style={style}>
      <Path
        d="M767.781 260.281a1.102 1.102 0 01-.005-1.557l-.052.052a1.098 1.098 0 011.547-.005L775 264.5l-5.729 5.729a1.095 1.095 0 01-1.547-.005l.052.052a1.096 1.096 0 01.005-1.557L772 264.5l-4.219-4.219z"
        transform="translate(-765 -260) scale(1 -1) rotate(90 1035.5 0)"
        fill={colors.periwinkleGrey}
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </Svg>
  );
};

export default CaretUp;
