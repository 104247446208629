import { FunctionComponent, useState, useCallback } from 'react';
import { RouteComponentProps } from '../../../core/routerLib/routerLib';
import IFrameModal from './IFrameModal';
import configs from '../../../../utils/configs';
import { getAccessToken, getUserData } from '../../../../utils/token';
import { useCloseModal } from '../../../utils/ModalsContextProvider';
import { refreshToken } from '../../../auth/auth';

const Offers: FunctionComponent<
  RouteComponentProps<undefined, undefined, { offerID: number; roomID: number }>
> = ({
  location: {
    state: { offerID, roomID },
  },
}) => {
  const closeModal = useCloseModal();
  const [isLoading, setIsLoading] = useState(true);
  const onFinishedLoading = useCallback(() => setIsLoading(false), []);

  return (
    <IFrameModal
      isLoading={isLoading}
      onFinishedLoading={onFinishedLoading}
      onClose={() => {
        closeModal();
      }}
      src={`${configs.endpoints.clientWebEndpoint}/room-offer/${roomID}/offer/${offerID}?source=therapist-web#origin=${window.location.origin}`}
      title="Offers page"
      onTokenRequest={async (refresh) => {
        if (refresh) {
          const success = await refreshToken(false, true).catch((err) => {
            // eslint-disable-next-line no-console
            console.error(err);
            return false;
          });
          if (!success) {
            closeModal();
            throw new Error('Token is missing');
          }
        }

        const userID = getUserData().id;
        const token = getAccessToken();

        if (!token) throw new Error('Token is missing');
        return {
          token,
          userID,
        };
      }}
    />
  );
};

export default Offers;
