import { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import {
  FloatingMenu,
  useWindowWidth,
  View,
  CloseButton,
  styled,
  Small,
  BaseButton,
  Button,
  Heading3,
  useEmotionTheme,
  SelectRounded,
  SmallCalendarV2,
  DatePickerInput,
  RecurrenceIcon,
  Spinner,
} from '@talkspace/react-toolkit';
import moment, { Moment } from 'moment';
import FullCalendar from '@fullcalendar/react';
import { components } from 'react-select';
import { getUserData } from '../../../../utils/token';
import FilterIcon from '../../../Icons/FilterIcon';
import trackEvent from '../../../../modules/analytics/trackEvent';
import {
  getHoursOptions,
  getRecurrenceOptions,
  MOBILE_DATE_FORMAT,
  DESKTOP_DATE_FORMAT,
  getInitialValue,
  getMenuPlacement,
  getCurrentTimeZoneString,
} from './helpers';
import {
  getFloatingMenuStyle,
  getSelectStyleV2,
  getSelectWrapperStyle,
  getSelectStyleHourRange,
  getDatePickerInputStyle,
  getCalendarContainerStyle,
} from './style.helpers';
import useDidUpdateEffect from '../../../../hooks/useDidUpdateEffect';
import { ModalActions, WeekAvailability, DateRange } from '../types';
import useMutationUpdateAvailability from '../../../../hooks/availability/useMutationUpdateAvailability';
import ErrorModal from './ErrorModal';
import ModifyBlockModal from './ModifyBlockModal';

const Footer = styled(View)(({ theme: { colors } }) => {
  return {
    borderTop: `solid ${colors.permaPowderBlue} 1px`,
    paddingTop: 13,
    paddingBottom: 13,
    paddingLeft: 25,
    paddingRight: 15,
    width: '100%',
  };
});

const SaveButton = styled(Button)(({ theme: { colors } }) => {
  return {
    height: 34,
    minHeight: 34,
    width: 156,
    padding: 0,
    color: colors.white,
    fontSize: 14,
    fontWeight: 700,
    backgroundColor: colors.permaEden,
  };
});

const Text = styled(Small)(({ theme: { colors } }) => {
  return {
    fonstSize: 15,
    color: colors.permaDarkForest,
    fontWeight: 700,
  };
});

const TimeZoneText = styled(Small)(({ theme: { colors } }) => {
  return {
    fontSize: 12,
    color: colors.permaEastBay,
    fontWeight: 400,
    marginTop: -21,
    marginBottom: 25,
    marginLeft: 67,
  };
});

const ContentWrapper = styled(View)(({ theme: { colors } }) => {
  return {
    width: '100%',
    paddingRight: 25,
    paddingLeft: 25,
    marginBottom: 25,
  };
});

const SelectContainer = styled(View)<{ isFocused: boolean }>(({ theme: { colors }, isFocused }) => {
  return {
    height: 30,
    boxSizing: 'border-box',
    borderRadius: 8,
    border: `1px solid ${colors.darkBlue150}`,
    minWidth: 150,
    cursor: 'pointer',
    marginLeft: 8,
    borderColor: isFocused && colors.grey950,
    '&:hover': {
      background: colors.permaGreyed,
    },
  };
});

interface AddAvailabilityProps {
  handleClose: () => void;
  action?: ModalActions;
  previewBlock: WeekAvailability | null;
  handlePreviewStartDate: (start: Date) => void;
  handlePreviewEndDate: (end: Date) => void;
  positioningStyle?: any;
  dateRange: DateRange;
  calendarRef: FullCalendar | null;
  handleDateRange: (start: Date, end: Date) => void;
  handlePreviewIsReccuring: (value: boolean) => void;
  source: string;
}

const Actions = ({
  primaryLabel,
  primaryOnClick,
  secondaryLabel,
  secondaryOnClick,
  isLoading = false,
}) => {
  const { colors } = useEmotionTheme();
  return (
    <>
      <BaseButton style={{ marginTop: 10 }} onPress={secondaryOnClick}>
        <Text>{secondaryLabel}</Text>
      </BaseButton>
      <SaveButton
        size="small"
        roundedFocusStyle
        primaryColor={colors.permaEden}
        disabled={isLoading}
        onPress={primaryOnClick}
      >
        {isLoading ? <Spinner /> : primaryLabel}
      </SaveButton>
    </>
  );
};

const SingleValue = (props) => {
  const { data } = props;
  return <components.SingleValue {...props}>{data.label.split(' ')[0]}</components.SingleValue>;
};

type HourRangeType = 'fromHour' | 'toHour';

const AddAvailability = ({
  handleClose,
  action = 'create',
  previewBlock,
  handlePreviewStartDate,
  handlePreviewEndDate,
  positioningStyle,
  dateRange,
  calendarRef,
  handleDateRange,
  handlePreviewIsReccuring,
  source,
}: AddAvailabilityProps) => {
  const { isMobile } = useWindowWidth();
  const { colors } = useEmotionTheme();

  const [startDate, setStartDate] = useState<Moment | null>(getInitialValue(previewBlock?.start));
  const [endDate, setEndDate] = useState<Moment | null>(getInitialValue(previewBlock?.end, true));
  const [hasMadeChanges, setHasMadeChanges] = useState<boolean>(false);
  const [currentSelectedRange, setCurrentSelectedRange] = useState<HourRangeType | null>(null);
  const [isRecurring, setIsRecurring] = useState<boolean>(previewBlock?.isRecurring ?? true);
  const [deleteModalOpen, setDeletaModalOpen] = useState<boolean>(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);

  const therapistID: number = getUserData().id;
  const previewsState = useRef(previewBlock);

  const {
    mutate: updateAvailability,
    isLoading: isUpdateLoading,
    isError,
    isSuccess,
  } = useMutationUpdateAvailability();

  const toHoursOptions = useMemo(() => getHoursOptions(startDate?.toDate(), true), [startDate]);
  const hoursOptions = useMemo(
    () => getHoursOptions(moment(startDate).startOf('day').toDate()),
    [startDate]
  );

  const recurrenceOptions = getRecurrenceOptions(startDate || moment());

  useEffect(() => {
    if (isSuccess) {
      handleClose();
    }
  }, [handleClose, isSuccess]);

  const changeDateRangeBySelectedDate = useCallback(
    (date) => {
      calendarRef?.getApi().gotoDate(date.toDate());
      const start = moment(date).startOf('week');
      const end = moment(date).endOf('week');
      handleDateRange(start.toDate(), end.toDate());
    },
    [calendarRef, handleDateRange]
  );

  const handleSendModalClickedEvent = ({
    modalAction,
    startTime = startDate,
    endTime = endDate,
  }) => {
    trackEvent(
      'calendarAvailabilityModalClicked',
      { actionName: 'activeExperiments' },
      {
        action: modalAction,
        availabilityBlockStatus:
          action === 'create' ? 'adding new block' : 'editing existing block',
        availabilityBlockStartAt: startTime,
        availabilityBlockEndAt: endTime,
      }
    );
  };

  const handleSendModalUpdatedEvent = (modalAction?: string) => {
    trackEvent(
      'calendarAvailabilityUpdated',
      { actionName: 'activeExperiments' },
      {
        action: modalAction || (action === 'create' ? 'added' : 'edited'),
        availabilityBlockStartAt: startDate,
        availabilityBlockEndAt: endDate,
        recurrence: isRecurring ? 'weekly' : '1-time',
        ...(action !== 'create' && {
          prevAvailabilityBlockStartAt: previewsState.current?.start,
          prevAvailabilityBlockEndAt: previewsState.current?.end,
        }),
        source,
      }
    );
  };

  useEffect(() => {
    if (startDate) {
      changeDateRangeBySelectedDate(startDate);
    }
  }, [changeDateRangeBySelectedDate, startDate]);

  useDidUpdateEffect(() => {
    if (startDate) {
      const convertedDate = moment(startDate);
      if (convertedDate.isSameOrAfter(endDate) && convertedDate.isSame(endDate, 'day')) {
        convertedDate.add(1, 'hours');
      } else {
        convertedDate.set({
          hour: endDate?.hour(),
          minute: endDate?.minute(),
          second: endDate?.second(),
          millisecond: endDate?.millisecond(),
        });
      }
      handleSendModalClickedEvent({
        modalAction: 'Adjust Time',
        startTime: startDate,
        endTime: convertedDate,
      });
      setEndDate(convertedDate);
      handlePreviewEndDate(convertedDate.toDate());
    }
  }, [startDate]);

  const handleChangeToHour = (value: Moment) => {
    if (endDate) {
      setEndDate(value);
      handlePreviewEndDate(value.toDate());
    }
    if (action !== 'create') {
      setHasMadeChanges(true);
    }
    handleSendModalClickedEvent({ modalAction: 'Adjust Time', endTime: value });
  };

  const handleChangeFromHour = (value: Moment) => {
    if (startDate) {
      setStartDate(value);
      handlePreviewStartDate(value.toDate());
    }
    if (action !== 'create') {
      setHasMadeChanges(true);
    }
  };

  const handleDelete = (isReccuringDelete: boolean) => {
    if (!startDate || !endDate || !previewsState.current) {
      return;
    }
    const removedBlocks = [
      {
        start: previewsState.current.start,
        end: previewsState.current.end,
        isRecurring: isReccuringDelete,
        calendarEntityType: 'availability',
      },
    ];
    handleSendModalUpdatedEvent('removed');
    updateAvailability({
      therapistID,
      addedBlocks: [],
      removedBlocks: removedBlocks || [],
      dateRange,
    });
  };

  const handleDeletePress = () => {
    if (previewsState.current?.isRecurring) {
      setDeletaModalOpen(true);
    } else {
      handleDelete(false);
    }
  };

  const handleSave = (isReccuringEdit: boolean) => {
    if (!startDate || !endDate) {
      return;
    }

    const addedBlocks = [
      {
        start: startDate.toDate(),
        end: endDate.toDate(),
        isRecurring: isReccuringEdit,
        calendarEntityType: 'availability',
      },
    ];

    let removedBlocks;
    if (action !== 'create' && previewsState.current) {
      removedBlocks = [
        {
          start: previewsState.current.start,
          end: previewsState.current.end,
          isRecurring: previewsState.current.isRecurring ? isReccuringEdit : false,
          calendarEntityType: 'availability',
        },
      ];
    }

    updateAvailability({
      therapistID,
      addedBlocks,
      removedBlocks: removedBlocks || [],
      dateRange,
    });
    handleSendModalUpdatedEvent();
    setIsEditModalOpen(false);
  };

  const handleSavePress = () => {
    if (!previewsState.current?.isRecurring || action === 'create') {
      handleSave(isRecurring);
    } else {
      setIsEditModalOpen(true);
    }
  };

  const handleChangeDate = (date: Moment) => {
    setStartDate(
      date.set({
        hour: startDate?.hour(),
        minute: startDate?.minute(),
        second: startDate?.second(),
        millisecond: startDate?.millisecond(),
      })
    );
    handlePreviewStartDate(date.toDate());
    changeDateRangeBySelectedDate(date);
    setHasMadeChanges(true);
  };

  const handleChangeReccuring = (value: boolean) => {
    setIsRecurring(value);
    handlePreviewIsReccuring(value);
    setHasMadeChanges(true);
    handleSendModalClickedEvent({ modalAction: 'Toggle Recurrence' });
  };

  const handleCloseModal = () => {
    handleSendModalClickedEvent({ modalAction: 'Discard changes' });
    handleClose();
  };

  const shouldRenderSaveActions = action === 'create' || hasMadeChanges;
  const menuPlacement = getMenuPlacement(positioningStyle?.top);

  return (
    <FloatingMenu
      style={{ ...getFloatingMenuStyle(isMobile), ...positioningStyle }}
      onBackdropPress={handleCloseModal}
    >
      <View row justify="end" style={{ marginTop: 10, marginRight: 10 }}>
        <CloseButton onPress={handleCloseModal} width={31} height={31} />
      </View>

      <ContentWrapper row>
        <View style={{ marginRight: 15 }}>
          <FilterIcon />
        </View>
        <View style={{ flex: 1 }}>
          <Heading3>Live session availability</Heading3>
          <Small style={{ color: colors.purple600, marginTop: 4 }}>
            Set availability so your clients can send live session requests. It’s up to you to
            confirm or reschedule.
          </Small>
        </View>
      </ContentWrapper>

      <ContentWrapper row align="center">
        <View style={{ marginRight: 20, marginLeft: 5 }}>
          <SmallCalendarV2 />
        </View>
        <DatePickerInput
          inputStyles={getDatePickerInputStyle(isMobile, colors)}
          format={isMobile ? MOBILE_DATE_FORMAT : DESKTOP_DATE_FORMAT}
          calendarWrapperStyles={getCalendarContainerStyle(isMobile)}
          currentValue={startDate}
          handleInputValueChange={handleChangeDate}
          primaryColor={colors.accessibilityGreenDark}
          secondaryColor={colors.aquaSqueeze}
        />
        -
        <SelectContainer row isFocused={!!currentSelectedRange}>
          <SelectRounded
            isInputReadOnly
            value={hoursOptions.find((it) => moment(it.value).isSame(moment(startDate))) as any}
            options={hoursOptions}
            styles={{
              ...getSelectStyleV2({ colors, small: true, shouldFloatLeft: true }),
              ...getSelectStyleHourRange(colors, currentSelectedRange === 'fromHour'),
            }}
            wrapperStyle={getSelectWrapperStyle()}
            onMenuOpen={() => setCurrentSelectedRange('fromHour')}
            onMenuClose={() => setCurrentSelectedRange(null)}
            onChange={(option: any) => handleChangeFromHour(option?.value)}
            shouldDisplayFocus={false}
            menuPlacement={menuPlacement}
          />
          <SelectRounded
            isInputReadOnly
            value={toHoursOptions.find((it) => moment(it.value).isSame(moment(endDate))) as any}
            options={toHoursOptions}
            wrapperStyle={getSelectWrapperStyle()}
            onMenuOpen={() => setCurrentSelectedRange('toHour')}
            onMenuClose={() => setCurrentSelectedRange(null)}
            onChange={(option: any) => handleChangeToHour(option?.value)}
            shouldDisplayFocus={false}
            components={{
              SingleValue,
            }}
            styles={{
              ...getSelectStyleV2({ colors, small: true }),
              ...getSelectStyleHourRange(colors, currentSelectedRange === 'toHour'),
            }}
            menuPlacement={menuPlacement}
          />
        </SelectContainer>
      </ContentWrapper>

      <TimeZoneText>{getCurrentTimeZoneString()}</TimeZoneText>

      <ContentWrapper row align="center">
        <View style={{ marginRight: 20, marginLeft: 5 }}>
          <RecurrenceIcon />
        </View>
        <Small variant="smallTSBlack" style={{ marginRight: 6 }}>
          Recurrence:
        </Small>
        {previewsState.current?.isRecurring && action !== 'create' ? (
          <Small variant="smallTSBlack">
            {recurrenceOptions.find((it) => it.value === previewsState.current?.isRecurring)?.label}
          </Small>
        ) : (
          <SelectRounded
            value={recurrenceOptions.find((it) => it.value === isRecurring) as any}
            styles={getSelectStyleV2({ colors })}
            options={recurrenceOptions as any}
            wrapperStyle={getSelectWrapperStyle()}
            onChange={(option: any) => handleChangeReccuring(option.value)}
          />
        )}
      </ContentWrapper>
      <Footer row align="center" justify="space-between">
        {shouldRenderSaveActions ? (
          <Actions
            isLoading={isUpdateLoading}
            secondaryLabel="Cancel"
            secondaryOnClick={handleCloseModal}
            primaryLabel="Save"
            primaryOnClick={handleSavePress}
          />
        ) : (
          <Actions
            secondaryLabel="Delete"
            secondaryOnClick={handleDeletePress}
            primaryLabel="Close"
            primaryOnClick={handleCloseModal}
          />
        )}
      </Footer>
      <ErrorModal isModalOpen={isError} positioningStyle={positioningStyle} />
      <ModifyBlockModal
        title="Remove recurring availability?"
        handleCloseModal={() => setDeletaModalOpen(false)}
        isModalOpen={deleteModalOpen}
        positioningStyle={positioningStyle}
        handleSubmit={handleDelete}
      />
      <ModifyBlockModal
        title="Change recurring availability?"
        handleCloseModal={() => setIsEditModalOpen(false)}
        isModalOpen={isEditModalOpen}
        positioningStyle={positioningStyle}
        handleSubmit={(reccuring: boolean) => handleSave(reccuring)}
      />
    </FloatingMenu>
  );
};

export default AddAvailability;
