import { useQuery } from 'react-query';
import apiHelper from '@/utils/api';
import apiWrapper from '../../utils/apiWrapper';
import { psychotherapyNoteQueryKey } from './queryKeys';

interface PsychotherapyNoteQueryResponse {
  noteBody: string | null;
  createdAt: string | null;
}

const fetchNote = (roomID: string, noteID?: string) => async () => {
  const { data } = await apiWrapper.get(
    `${apiHelper().apiEndpoint}/v2/rooms/${roomID}/psychotherapy-notes/${noteID}`
  );
  return data.data;
};

const useQueryPsychotherapyNote = (roomID: string, noteID?: string, enabled: boolean = true) =>
  useQuery<PsychotherapyNoteQueryResponse, Error>({
    queryKey: psychotherapyNoteQueryKey(roomID, noteID),
    queryFn: fetchNote(roomID, noteID),
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!noteID && enabled,
  });

export default useQueryPsychotherapyNote;
