import { Component } from 'react';
import { routePromise } from 'ts-frontend/helpers/routePromise';
import { removeTokenFromLocalStore } from '../../utils/token';
import { tokenIsValid } from '../../utils/tokenIsValid';
import apiHelper from '../../utils/api';
import { invalidateTokenAndRedirect } from '../../modules/auth/auth';

class TherapistLogout extends Component {
  componentDidMount() {
    if (tokenIsValid()) {
      invalidateTokenAndRedirect({ shouldForgetDevice: true });
    } else {
      removeTokenFromLocalStore();
      routePromise('/login');
    }
  }

  render() {
    return <div />;
  }
}

export default TherapistLogout;
