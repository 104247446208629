import Svg, { G, Path } from 'svgs';
import { useEmotionTheme } from '../../core/styled';

const BookingIcon = ({ width = 28, height = 30, ...otherProps }) => {
  const { colors } = useEmotionTheme();
  const titleText = 'booking';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox={`0 0 ${width} ${height}`}
      {...otherProps}
    >
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Path
          d="M6.047 0a1.98 1.98 0 011.98 1.98l-.001 2.057h11.652V1.98A1.98 1.98 0 0121.659 0h.054a1.98 1.98 0 011.98 1.98v2.097l-.005.14a5.402 5.402 0 014.018 5.22v15.056a5.4 5.4 0 01-5.4 5.4H5.4a5.4 5.4 0 01-5.4-5.4V9.438a5.402 5.402 0 014.018-5.222 2.164 2.164 0 01-.005-.14V1.98A1.98 1.98 0 015.993 0h.054z"
          transform="translate(-588 -325) translate(475 304) translate(113 21)"
          fill={colors.purple}
        />
        <Path
          d="M14.442 12c1.015 0 1.837.822 1.837 1.837v6.326A1.837 1.837 0 0114.442 22H8.837A1.837 1.837 0 017 20.163v-6.326C7 12.822 7.822 12 8.837 12h5.605zm6.45 1.688a.735.735 0 01.108.385v5.487a.735.735 0 01-1.12.626l-2.6-1.598a.735.735 0 01-.35-.626V15.67c0-.255.133-.492.35-.626l2.6-1.598a.735.735 0 011.011.24z"
          fill={colors.white}
          transform="translate(-588 -325) translate(475 304) translate(113 21)"
        />
      </G>
    </Svg>
  );
};

export default BookingIcon;
