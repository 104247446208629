import { useQuery } from 'react-query';
import apiHelper from '@/utils/api';
import apiWrapper from '../../utils/apiWrapper';
import { sessionMessagesQueryKey } from './queryKeys';

interface SessionMessagesParams {
  roomID: string;
  asyncSessionID: number;
  enabled?: boolean;
}

export type SessionMessageType = 'video' | 'audio' | 'chat' | 'pdf' | 'photo';

export interface SessionMessagesQueryResponse {
  id: number;
  isRecipient: boolean;
  message: string;
  sentAt: Date;
  messageType: SessionMessageType;
  displayName: string;
}

const fetchSessionMessages = (roomID: string, asyncSessionID?: number) => async () => {
  const { data } = await apiWrapper.get(
    `${apiHelper().apiEndpoint}/v2/rooms/${roomID}/messages/async-sessions/${asyncSessionID}`
  );
  return data.data;
};

const useQuerySessionMessages = ({
  roomID,
  asyncSessionID,
  enabled = true,
}: SessionMessagesParams) =>
  useQuery<SessionMessagesQueryResponse[], Error>({
    queryKey: sessionMessagesQueryKey(roomID, asyncSessionID),
    queryFn: fetchSessionMessages(roomID, asyncSessionID),
    cacheTime: Infinity,
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!asyncSessionID && enabled,
  });

export default useQuerySessionMessages;
