function isDevelopmentOrStaging() {
  if (
    window.origin.startsWith('http://localhost') ||
    window.origin.startsWith('https://localhost') ||
    window.origin.startsWith('http://127.0.0.1') ||
    window.origin.startsWith('https://127.0.0.1') ||
    window.origin.includes('.dev.talkspace.com') ||
    window.origin.includes('.canary.talkspace.com') ||
    window.origin.includes('.mime.talkspace.com') ||
    window.origin.includes('.qovery.cloud')
  ) {
    return true;
  }
  return false;
}

const resourceHelper = { isDevelopmentOrStaging };

export default resourceHelper;
