import Svg, { Path } from 'svgs';
import { useEmotionTheme } from '../../core/styled';
import type { EmotionStyle } from '../../core/styled';

export interface QuestionnaireIconProps {
  width?: number;
  height?: number;
  color?: string;
  style?: EmotionStyle;
}

const QuestionnaireIcon = ({
  color,
  width = 24,
  height = 28,
  ...otherProps
}: QuestionnaireIconProps) => {
  const { colors } = useEmotionTheme();
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 28 28"
      title="questionnaire"
      aria-label="questionnaire"
      fill="none"
      {...otherProps}
    >
      <Path
        d="M16.1001 18.6667C16.4779 18.6667 16.8001 18.5333 17.0667 18.2667C17.3334 18 17.4667 17.6778 17.4667 17.3C17.4667 16.9222 17.3334 16.6 17.0667 16.3333C16.8001 16.0667 16.4779 15.9333 16.1001 15.9333C15.7223 15.9333 15.4001 16.0667 15.1334 16.3333C14.8667 16.6 14.7334 16.9222 14.7334 17.3C14.7334 17.6778 14.8667 18 15.1334 18.2667C15.4001 18.5333 15.7223 18.6667 16.1001 18.6667ZM15.2667 14.4667H16.8334C16.8779 13.8222 16.9723 13.3444 17.1167 13.0333C17.2612 12.7222 17.6223 12.2889 18.2001 11.7333C18.8001 11.1556 19.2167 10.65 19.4501 10.2167C19.6834 9.78334 19.8001 9.27778 19.8001 8.7C19.8001 7.67778 19.4501 6.84445 18.7501 6.20001C18.0501 5.55556 17.1334 5.23334 16.0001 5.23334C15.1556 5.23334 14.4001 5.46112 13.7334 5.91667C13.0667 6.37223 12.5779 7.01112 12.2667 7.83334L13.7667 8.46667C14.0112 7.91112 14.3167 7.48889 14.6834 7.20001C15.0501 6.91112 15.489 6.76667 16.0001 6.76667C16.6667 6.76667 17.2056 6.95556 17.6167 7.33334C18.0279 7.71112 18.2334 8.18889 18.2334 8.76667C18.2334 9.21112 18.1334 9.6 17.9334 9.93334C17.7334 10.2667 17.3779 10.6222 16.8667 11C16.1556 11.6444 15.7112 12.1611 15.5334 12.55C15.3556 12.9389 15.2667 13.5778 15.2667 14.4667ZM6.66675 23.3333C6.13341 23.3333 5.66675 23.1333 5.26675 22.7333C4.86675 22.3333 4.66675 21.8667 4.66675 21.3333V2.66667C4.66675 2.13334 4.86675 1.66667 5.26675 1.26667C5.66675 0.866672 6.13341 0.666672 6.66675 0.666672H25.3334C25.8667 0.666672 26.3334 0.866672 26.7334 1.26667C27.1334 1.66667 27.3334 2.13334 27.3334 2.66667V21.3333C27.3334 21.8667 27.1334 22.3333 26.7334 22.7333C26.3334 23.1333 25.8667 23.3333 25.3334 23.3333H6.66675ZM2.66675 27.3333C2.13341 27.3333 1.66675 27.1333 1.26675 26.7333C0.866748 26.3333 0.666748 25.8667 0.666748 25.3333V4.66667H2.66675V25.3333H23.3334V27.3333H2.66675Z"
        fill={color || colors.permaPersianGreenApprox}
      />
    </Svg>
  );
};

export default QuestionnaireIcon;
