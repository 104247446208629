import { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory, useParams } from '@/core/routerLib';
import { getEmergencyContact as getEmergencyContactAction } from '../../../../../actions/EmergencyContactActions';
import SocketService from '../../../../../utils/socket/SocketService';
import UserActionCard from '../../../../Reusable/UserActionCard/UserActionCard';
import Submenu from '../../../../Reusable/Submenu/Submenu';
import useQueryParticipants from '../../../../../hooks/useQueryParticipants';
import './EmergencyContact.css';

interface Props {
  getEmergencyContact: (clientUserID: number) => void;
}

const EmergencyContact = ({ getEmergencyContact }: Props) => {
  const history = useHistory();
  const { roomID } = useParams<{ roomID: string }>();

  const { data: participants = [] } = useQueryParticipants(Number(roomID));

  useEffect(() => {
    const clientUserIDs = participants.map((client) => {
      getEmergencyContact(client.userID);
      return Number(client.userID);
    });

    SocketService.instance().on('emergency_contact', (data) => {
      if (clientUserIDs.includes(Number(data.userID))) getEmergencyContact(data.userID);
    });

    return () => {
      SocketService.instance().off('emergency_contact');
    };
  }, [participants, getEmergencyContact]);

  const renderClientLoginCards = () =>
    participants.map((client) => {
      const buttons = [
        {
          title: 'View client login information',
          clickHandler: () => history.push('client-logins', { clientUserID: client.userID }),
        },
      ];
      return (
        <UserActionCard
          key={client.userID}
          preferredName={client.displayName || 'Name Missing'}
          content={`When using Talkspace's IP locational tools, please always prioritize and respect Client privacy. The locational tool was built into the emergency contact system to aide you in guiding local authorities during an emergent crisis. The use of this tool should be restricted to only those situations.`}
          buttons={buttons}
        />
      );
    });

  return (
    <Submenu
      childComponents={renderClientLoginCards()}
      title="Emergency contact login"
      prevRoute="case-tab"
    />
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getEmergencyContact: getEmergencyContactAction }, dispatch);

export default connect(null, mapDispatchToProps)(EmergencyContact);
