import { useContext, createContext, useState } from 'react';

type ExperimentVariants = 'control' | string;

interface Props<T extends ExperimentVariants = ExperimentVariants> {
  experimentVariant: T;
}

interface ABTestContextState {
  experimentVariant: string;
  hasRenderedVariant: boolean;
}

interface ABTestContextActions {
  setHasRenderedVariant(value: boolean): void;
}

const ABTestStateContext = createContext<ABTestContextState | undefined>(undefined);
const ABTestActionsContext = createContext<ABTestContextActions | undefined>(undefined);

const ABTest = <T extends ExperimentVariants>({
  children,
  ...props
}: React.PropsWithChildren<Props<T>>) => {
  const [hasRenderedVariant, setHasRenderedVariant] = useState(false);

  return (
    <ABTestStateContext.Provider value={{ ...props, hasRenderedVariant }}>
      <ABTestActionsContext.Provider value={{ setHasRenderedVariant }}>
        {children}
      </ABTestActionsContext.Provider>
    </ABTestStateContext.Provider>
  );
};

interface WhenProps<T extends ExperimentVariants = ExperimentVariants> {
  variant: T;
}

const useABTestStateContext = (): ABTestContextState => {
  const context = useContext(ABTestStateContext);
  if (!context) throw new Error('Cannot use ABTestStateContext outside of ABTest');

  return context;
};
const useABTestActionsContext = (): ABTestContextActions => {
  const context = useContext(ABTestActionsContext);
  if (!context) throw new Error('Cannot use ABTestActionsContext outside of ABTest');

  return context;
};

const When = <T extends ExperimentVariants>({
  variant,
  children,
}: React.PropsWithChildren<WhenProps<T>>) => {
  const { experimentVariant } = useABTestStateContext();
  const { setHasRenderedVariant } = useABTestActionsContext();
  if (experimentVariant === variant) {
    setHasRenderedVariant(true);
    return <>{children}</>;
  }
  return null;
};

const Default: React.FunctionComponent = ({ children }) => {
  const { hasRenderedVariant } = useABTestStateContext();
  if (!hasRenderedVariant) {
    return <>{children}</>;
  }
  return null;
};

ABTest.When = When;
ABTest.Default = Default;

export default ABTest;
