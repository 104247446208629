import { ReactElement } from 'react';
import { useEmotionTheme } from '../../core/styled';
import View from '../View';

interface RoundContainerProps {
  color?: string;
  size: number;
  icon: ReactElement;
}

function RoundContainer({ color, size, icon }: RoundContainerProps) {
  const { colors } = useEmotionTheme();
  return (
    <View
      align="center"
      justify="center"
      style={{
        borderRadius: '50%',
        backgroundColor: color || colors.permaSwansDown,
        width: size,
        height: size,
      }}
    >
      {icon}
    </View>
  );
}

export default RoundContainer;
