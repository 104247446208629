import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const standardPath =
  'M10 18.3965C12.1217 18.3965 14.1566 17.5536 15.6569 16.0533C17.1571 14.553 18 12.5182 18 10.3965C18 8.27475 17.1571 6.23992 15.6569 4.73963C14.1566 3.23934 12.1217 2.39648 10 2.39648C7.87827 2.39648 5.84344 3.23934 4.34315 4.73963C2.84285 6.23992 2 8.27475 2 10.3965C2 12.5182 2.84285 14.553 4.34315 16.0533C5.84344 17.5536 7.87827 18.3965 10 18.3965ZM9.25 13.1465V11.1465H7.25C6.83437 11.1465 6.5 10.8121 6.5 10.3965C6.5 9.98086 6.83437 9.64648 7.25 9.64648H9.25V7.64648C9.25 7.23086 9.58437 6.89648 10 6.89648C10.4156 6.89648 10.75 7.23086 10.75 7.64648V9.64648H12.75C13.1656 9.64648 13.5 9.98086 13.5 10.3965C13.5 10.8121 13.1656 11.1465 12.75 11.1465H10.75V13.1465C10.75 13.5621 10.4156 13.8965 10 13.8965C9.58437 13.8965 9.25 13.5621 9.25 13.1465Z';
const majorPath =
  'M10 2.66797C12.1549 2.66797 14.2215 3.52399 15.7452 5.04773C17.269 6.57146 18.125 8.63808 18.125 10.793C18.125 12.9479 17.269 15.0145 15.7452 16.5382C14.2215 18.0619 12.1549 18.918 10 18.918C7.84512 18.918 5.77849 18.0619 4.25476 16.5382C2.73102 15.0145 1.875 12.9479 1.875 10.793C1.875 8.63808 2.73102 6.57146 4.25476 5.04773C5.77849 3.52399 7.84512 2.66797 10 2.66797ZM10 20.793C12.6522 20.793 15.1957 19.7394 17.0711 17.864C18.9464 15.9887 20 13.4451 20 10.793C20 8.1408 18.9464 5.59726 17.0711 3.7219C15.1957 1.84654 12.6522 0.792969 10 0.792969C7.34784 0.792969 4.8043 1.84654 2.92893 3.7219C1.05357 5.59726 0 8.1408 0 10.793C0 13.4451 1.05357 15.9887 2.92893 17.864C4.8043 19.7394 7.34784 20.793 10 20.793ZM9.0625 14.2305C9.0625 14.75 9.48047 15.168 10 15.168C10.5195 15.168 10.9375 14.75 10.9375 14.2305V11.7305H13.4375C13.957 11.7305 14.375 11.3125 14.375 10.793C14.375 10.2734 13.957 9.85547 13.4375 9.85547H10.9375V7.35547C10.9375 6.83594 10.5195 6.41797 10 6.41797C9.48047 6.41797 9.0625 6.83594 9.0625 7.35547V9.85547H6.5625C6.04297 9.85547 5.625 10.2734 5.625 10.793C5.625 11.3125 6.04297 11.7305 6.5625 11.7305H9.0625V14.2305Z';
const hugePath =
  'M16 3.93958C19.2489 3.93958 22.3647 5.2302 24.6621 7.52752C26.9594 9.82484 28.25 12.9407 28.25 16.1896C28.25 19.4385 26.9594 22.5543 24.6621 24.8516C22.3647 27.149 19.2489 28.4396 16 28.4396C12.7511 28.4396 9.63526 27.149 7.33794 24.8516C5.04062 22.5543 3.75 19.4385 3.75 16.1896C3.75 12.9407 5.04062 9.82484 7.33794 7.52752C9.63526 5.2302 12.7511 3.93958 16 3.93958ZM16 30.1896C19.713 30.1896 23.274 28.7146 25.8995 26.0891C28.525 23.4636 30 19.9026 30 16.1896C30 12.4765 28.525 8.91559 25.8995 6.29008C23.274 3.66457 19.713 2.18958 16 2.18958C12.287 2.18958 8.72601 3.66457 6.10051 6.29008C3.475 8.91559 2 12.4765 2 16.1896C2 19.9026 3.475 23.4636 6.10051 26.0891C8.72601 28.7146 12.287 30.1896 16 30.1896ZM15.125 21.4396C15.125 21.9208 15.5188 22.3146 16 22.3146C16.4812 22.3146 16.875 21.9208 16.875 21.4396V17.0646H21.25C21.7313 17.0646 22.125 16.6708 22.125 16.1896C22.125 15.7083 21.7313 15.3146 21.25 15.3146H16.875V10.9396C16.875 10.4583 16.4812 10.0646 16 10.0646C15.5188 10.0646 15.125 10.4583 15.125 10.9396V15.3146H10.75C10.2688 15.3146 9.875 15.7083 9.875 16.1896C9.875 16.6708 10.2688 17.0646 10.75 17.0646H15.125V21.4396Z';

const pathConfig: IconConfig = {
  standard: {
    default: {
      path: standardPath,
    },
  },
  major: {
    default: {
      path: majorPath,
    },
  },
  huge: {
    default: {
      path: hugePath,
    },
  },
};

const CirclePlus = withDSIconMaker(pathConfig, 'plus inside circle');

export default CirclePlus;
