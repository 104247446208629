/* eslint-disable react/destructuring-assignment */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TextInputView from './TextInputView';
import MaskedTextInputView from './MaskedTextInputView';
import MultilineTextInput from './MultilineTextInputView';
import TextInputMultilineDisabled from './TextInputMultilineDisabled';

function TextInput(props) {
  const {
    onFocus,
    onBlur,
    onChange,
    value,
    disabled,
    disabledNumberOfLines,
    multiline,
    mask,
    maskType,
    dataQa,
    isPlaceholderAboveInput,
    isReadOnly,
    ...otherProps
  } = props;

  const [hasFocus, setFocus] = useState(false);
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleOnFocus = (e) => {
    setFocus(true);
    if (onFocus) onFocus(e);
  };

  const handleOnBlur = (e) => {
    setFocus(false);
    if (onBlur) onBlur(e);
  };

  const handleOnChange = (e) => {
    setInputValue(e.target.value);
    if (onChange) onChange(e);
  };

  let TextInputComponent;
  if (disabled && multiline && !!disabledNumberOfLines) {
    TextInputComponent = TextInputMultilineDisabled;
  } else if (multiline) {
    TextInputComponent = MultilineTextInput;
  } else if (mask || maskType) {
    TextInputComponent = MaskedTextInputView;
  } else {
    TextInputComponent = TextInputView;
  }

  return (
    <TextInputComponent
      {...otherProps}
      data-qa={dataQa || 'input'}
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
      onChange={handleOnChange}
      placeholderUp={isPlaceholderAboveInput && (hasFocus || inputValue)}
      hasFocus={hasFocus}
      value={inputValue}
      maskType={maskType}
      disabled={disabled}
      readOnly={isReadOnly}
    />
  );
}

TextInput.propTypes = {
  dataQa: PropTypes.string,
  value: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChangeText: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  mask: PropTypes.string,
  isValid: PropTypes.bool,
  disabled: PropTypes.bool,
  multiline: PropTypes.bool,
  disabledNumberOfLines: PropTypes.number,
  style: PropTypes.object,
  maskType: PropTypes.string,
  placeHolderStyle: PropTypes.object,
  isPlaceholderAboveInput: PropTypes.bool,
  onClick: PropTypes.func,
  assignRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  isReadOnly: PropTypes.bool,
  alwaysShowPlaceholder: PropTypes.bool,
};

TextInput.defaultProps = {
  dataQa: undefined,
  value: '',
  onFocus: () => null,
  onBlur: () => null,
  onClick: () => null,
  onChangeText: undefined,
  onChange: undefined,
  placeholder: '',
  mask: undefined,
  isValid: true,
  disabled: false,
  multiline: false,
  disabledNumberOfLines: undefined,
  style: {},
  placeHolderStyle: {},
  isPlaceholderAboveInput: true,
  assignRef: undefined,
  isReadOnly: false,
};

export default TextInput;
