import { useMutation, useQueryClient } from 'react-query';
import apiHelper from '@/utils/api';
import apiWrapper from '../../utils/apiWrapper';
import { CaseConsultationNoteFormState } from '../../components/Room/CRMContainer/NotesTab/types';
import { caseConsultationNoteQueryKey, notesListQueryKey } from './queryKeys';

interface SubmitCaseConsultationNoteMutateVariables extends CaseConsultationNoteFormState {
  roomID: string;
  clientUserID?: number;
  noteID?: string;
}

const submitCaseConsultationNote = async (
  params: SubmitCaseConsultationNoteMutateVariables
): Promise<void> => {
  const { roomID, noteID, clientUserID, ...requestBody } = params;

  const requestURL = noteID
    ? `/v2/rooms/${roomID}/case-consultation-notes/${noteID}/submit`
    : `/v2/rooms/${roomID}/case-consultation-notes/submit`;

  const method: keyof typeof apiWrapper = noteID ? 'patch' : 'post';

  await apiWrapper[method](`${apiHelper().apiEndpoint}${requestURL}`, requestBody);
};

const useMutationSubmitCaseConsultationNote = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, SubmitCaseConsultationNoteMutateVariables>(
    submitCaseConsultationNote,
    {
      onSuccess: (_, { roomID, noteID, clientUserID }) => {
        queryClient.invalidateQueries(notesListQueryKey(roomID));
        if (noteID) {
          queryClient.invalidateQueries(caseConsultationNoteQueryKey(clientUserID, noteID));
        }
      },
    }
  );
};

export default useMutationSubmitCaseConsultationNote;
