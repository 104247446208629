export const calculateNetPrice = (
  planPrice: number,
  copayCents = 0,
  coinsurancePercent = 100,
  deductible = 0
): number => {
  // CAP OON discount to $100 (PLATFORM-507)
  const maxDiscount = 100;
  const minPrice = planPrice - maxDiscount;
  if (copayCents) {
    return Math.max(minPrice, copayCents / 100);
  }

  return Math.floor(
    Math.max(
      minPrice,
      Math.min(deductible + (coinsurancePercent / 100) * (planPrice - deductible), planPrice)
    )
  );
};

export const calculatePlanDiscount = (
  planPrice: number,
  copayCents = 0,
  coinsurancePercent = 100,
  deductible = 0
): number => planPrice - calculateNetPrice(planPrice, copayCents, coinsurancePercent, deductible);
