import TextDS from '../../designSystems/components/typography/TextDS';
import { EmotionStyle, useEmotionTheme } from '../../core/styled';
import RHFCheckbox from '../RHFCheckbox';

enum GDPRConsentCheckboxText {
  marketing = 'Optional: I consent to Talkspace processing my personal and special category data for marketing purposes. I agree to receive marketing SMS (up to 5/mo). Message and data rates may apply. I can withdraw my consent at any time.',
  processing = 'I consent to Talkspace processing my personal data, including special category personal data. I can withdraw my consent at any time.',
  transferring = 'I consent to Talkspace transferring my personal data to the US. I am aware that the US may have data protection rules that are less protective than the UK/EEA.',
}
export const GDPRConsentFieldNames: { [K in keyof typeof GDPRConsentCheckboxText]: string } = {
  marketing: 'consentGDPRMarketing',
  processing: 'consentGDPRProcessing',
  transferring: 'consentGDPRTransferring',
};

const GDPRConsentCheckbox = ({
  type,
  style,
}: {
  type: keyof typeof GDPRConsentCheckboxText;
  style?: EmotionStyle;
}) => {
  const { spacing } = useEmotionTheme();
  return (
    <RHFCheckbox
      alignCenter={false}
      fieldName={GDPRConsentFieldNames[type]}
      label={
        <TextDS variant="bodyXs" colorRole="textSubtle">
          {GDPRConsentCheckboxText[type]}
        </TextDS>
      }
      containerStyle={{
        marginTop: spacing('space050'),
        marginBottom: spacing('space150'),
        textAlign: 'start',
        ...style,
      }}
    />
  );
};

export default GDPRConsentCheckbox;
