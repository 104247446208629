import { FunctionComponent } from 'react';
import ReactTooltip from 'react-tooltip';
import { View } from '@talkspace/react-toolkit';
import './styles.css';

const TooltipComponent: FunctionComponent<
  {
    tip?: string;
    isClickable?: boolean;
  } & ReactTooltip.Props
> = ({ tip, children, isClickable, ...otherProps }) =>
  isClickable ? (
    <>
      <View data-tip={tip} data-for="clickable-tooltip" data-event="click">
        {children}
      </View>
      <ReactTooltip
        place="bottom"
        id="clickable-tooltip"
        className="ts-tooltip"
        globalEventOff="click"
        multiline
        {...otherProps}
      />
    </>
  ) : (
    <>
      <View data-tip={tip}>{children}</View>
      <ReactTooltip place="bottom" className="ts-tooltip" multiline {...otherProps} />
    </>
  );

export default TooltipComponent;
