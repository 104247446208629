import * as React from 'react';

import BaseScreen from '../components/BaseScreen';
import PersonalDetailsForm from '../containers/PersonalDetailsForm';

import { SignupStepEnum } from '../types';

const PersonalDetailsScreen: React.FC = () => (
  <BaseScreen step={SignupStepEnum.personalDetails}>
    <PersonalDetailsForm />
  </BaseScreen>
);

export default PersonalDetailsScreen;
