import Svg, { G, Rect, Path } from 'svgs';
import { useEmotionTheme } from '../../core/styled';

const path =
  'M9.478 0c3.503-.001 6.7 2.665 6.7 6.523 0 3.528-3.197 6.428-6.7 6.411H6.463C2.918 12.951 0 10.052 0 6.524 0 2.665 2.918-.002 6.463 0zM6.476.539C3.236.54.61 3.189.61 6.46c.001 3.27 2.626 5.92 5.866 5.92 3.24 0 5.866-2.65 5.866-5.92S9.716.54 6.476.539zm1.33 2.418a3.756 3.756 0 012.389 3.503 3.759 3.759 0 01-2.21 3.428l-.179.075V2.957zm-2.661 0v7.004a3.755 3.755 0 01-2.381-3.294l-.006-.207a3.758 3.758 0 012.387-3.502z';

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

const DinersClub = ({ width = 33, height = 21, color, ...props }: Props) => {
  const { colors } = useEmotionTheme();
  const titleText = 'Diners Club';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox={`0 0 ${width} ${height}`}
      {...props}
    >
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Rect x={0} y={0} width={33} height={21} rx={3} fill={color || colors.green} />
        <Path d={path} transform="translate(8 4)" fill={colors.white} />
      </G>
    </Svg>
  );
};

export default DinersClub;
