import { useEmotionTheme } from '@talkspace/react-toolkit';
import { VoidFunctionComponent } from 'react';
import Svg, { Rect, Path } from 'svgs';

interface VideoModalityIconProps {
  backgroundColor?: string;
  foregroundColor?: string;
  width?: number;
  height?: number;
}

const VideoModalityIcon: VoidFunctionComponent<VideoModalityIconProps> = ({
  backgroundColor,
  foregroundColor,
  width = 28,
  height = 28,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'video icon';

  return (
    <Svg
      title={titleText}
      aria-label={titleText}
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill="none"
      {...otherProps}
    >
      <Rect width={width} height={height} rx={8} fill={backgroundColor || colors.permaMagicMint} />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.14496 9C6.23647 9 5.5 9.73647 5.5 10.645V17.355C5.5 18.2635 6.23647 19 7.14496 19H14.3949C15.3034 19 16.0399 18.2635 16.0399 17.355V10.645C16.0399 9.73647 15.3034 9 14.3949 9H7.14496ZM17.5399 10.645C17.5399 8.90805 16.1318 7.5 14.3949 7.5H7.14496C5.40805 7.5 4 8.90805 4 10.645V17.355C4 19.092 5.40805 20.5 7.14496 20.5H14.3949C16.1318 20.5 17.5399 19.092 17.5399 17.355V10.645ZM19.0399 11.4437C18.6981 11.6764 18.4901 12.0648 18.4901 12.4834V15.0388C18.4901 15.4575 18.6981 15.8458 19.0399 16.0785C19.0733 16.1013 19.108 16.1225 19.1439 16.1422L22.5664 18.0165C23.4048 18.4756 24.4287 17.8689 24.4287 16.9131V10.6091C24.4287 9.65331 23.4048 9.04666 22.5664 9.50576L19.1439 11.3801C19.108 11.3997 19.0733 11.421 19.0399 11.4437ZM19.9901 12.6268V14.8954L22.9287 16.5047V11.0176L19.9901 12.6268Z"
        fill="#2A7258"
      />
    </Svg>
  );
};

export default VideoModalityIcon;
