import { useState } from 'react';
import { withRouter } from 'react-router-dom';
import {
  View,
  ThreeDots,
  Standard,
  TouchableView,
  FloatingMenu,
  BaseButton,
  useEmotionTheme,
  useWindowWidthState,
} from '@talkspace/react-toolkit';
import styled from '@/core/styled/styled';

const MenuContainer = styled(View)({
  fontSize: 20,
  marginLeft: 'auto',
  position: 'relative',
});

const MenuButton = styled(BaseButton)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      padding: `10px ${isMobile ? 10 : 20}px`,
    };
  }
);

const MenuItemWrapper = styled(View)({
  width: 126,
  height: 40,
  justifyContent: 'center',
  paddingLeft: 20,
});

const ThreeDotDropDownMenu = ({ history }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { colors } = useEmotionTheme();
  const { isMobile } = useWindowWidthState();
  const onClick = () => {
    setIsOpen(!isOpen);
  };
  return (
    <MenuContainer>
      <MenuButton onClick={onClick}>
        <ThreeDots color={colors.permaDarkCyan} />
      </MenuButton>
      <TouchableView>
        <FloatingMenu
          id="metrics-item-menu"
          onBackdropPress={onClick}
          hide={!isOpen}
          style={{ right: isMobile ? 28 : 50, left: 'auto', bottom: 'auto', top: -2, minWidth: 0 }}
        >
          <MenuItemWrapper>
            <Standard
              variant="standardBoldTSBlack"
              onPress={() => history.push('/performance/metrics')}
            >
              View Metrics
            </Standard>
          </MenuItemWrapper>
        </FloatingMenu>
      </TouchableView>
    </MenuContainer>
  );
};

export default withRouter(ThreeDotDropDownMenu);
