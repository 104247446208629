import moment, { Moment } from 'moment';

const QUARTER_HOUR_SPLIT = 15;
const NUM_OF_HOURS = 24;
const QUARTERS_IN_HOUR = 4;

const getDuration = (totalMinutes: number): string => {
  const hoursDiff = Math.floor(totalMinutes / 60);
  const minutesDiff = totalMinutes % 60;
  if (hoursDiff === 0) {
    return ` (${minutesDiff}min)`;
  }
  return ` (${hoursDiff + minutesDiff / 60}hrs)`;
};

// Function to generate an array of hour options in 15-minute increments
export const getHoursOptions = (startTime?: Date, shouldDisplayDuration = false) => {
  let time = new Date();
  time.setHours(0, 0, 0, 0);
  if (startTime) {
    time = new Date(startTime);
  }

  const options: any = [];
  // Calculate the start minutes rounded down to the nearest quarter hour
  const startMinutes = Math.floor(time.getMinutes() / QUARTER_HOUR_SPLIT) * QUARTER_HOUR_SPLIT;

  time.setMinutes(startMinutes);
  let totalMinutes = 0;
  for (let h = shouldDisplayDuration ? -1 : 0; h < NUM_OF_HOURS * QUARTERS_IN_HOUR; h += 1) {
    let hours = time.getHours();
    const minutes = time.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12 || 12; // the hour '0' should be '12'

    // Format the minutes string with a leading zero if necessary
    const minuteStr = minutes < 10 ? `0${minutes}` : minutes;

    const timeStr = `${hours}:${minuteStr}${ampm}`;

    if (shouldDisplayDuration) {
      const duration = getDuration(totalMinutes);
      const isNotSameDate = !(moment(startTime).day() === moment(time).day());

      if (totalMinutes !== 0) {
        options.push({ value: moment(time), label: `${timeStr}${duration}` });
        if (isNotSameDate) {
          break;
        }
      }
    } else {
      options.push({ value: moment(time), label: `${timeStr}` });
    }
    time.setMinutes(time.getMinutes() + QUARTER_HOUR_SPLIT);
    totalMinutes += QUARTER_HOUR_SPLIT;
  }

  return options;
};

export const getRecurrenceOptions = (date: Moment) => {
  const day = moment(date).format('dddd');
  return [
    {
      label: 'Does not repeat',
      value: false,
    },
    {
      label: `Weekly on ${day}`,
      value: true,
    },
  ];
};

export const getInitialValue = (date: Date | undefined, isEndDate: boolean = false): Moment => {
  const convertedDate = date ? moment(date) : moment();
  const roundedMinutes =
    Math.ceil(moment(convertedDate).minute() / QUARTER_HOUR_SPLIT) * QUARTER_HOUR_SPLIT;
  return moment(convertedDate).minute(roundedMinutes).second(0).millisecond(0);
};

export const MOBILE_DATE_FORMAT = 'ddd, MMM DD';
export const DESKTOP_DATE_FORMAT = 'dddd, MMMM DD';

export const getMenuPlacement = (top: number) => {
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  const spaceBelow = window.innerHeight - (top - scrollTop);

  return spaceBelow < 350 ? 'top' : 'bottom';
};

export const getCurrentTimeZoneString = () => {
  const date = new Date();
  const timeZoneOffset = date.getTimezoneOffset() / 60;
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  return `Time zone: (GMT${timeZoneOffset >= 0 ? '-' : '+'}${Math.abs(timeZoneOffset)
    .toString()
    .padStart(2, '0')}:00) ${timeZone.split('/')[1].replace('_', ' ')}`;
};
