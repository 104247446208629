import { useState } from 'react';
import { AccordionContainer, useObjectState } from '@talkspace/react-toolkit';
import {
  appearance as appearanceOptions,
  attitude as attitudeOptions,
  behavior as behaviorOptions,
  speech as speechOptions,
  affect as affectOptions,
  mood as moodOptions,
  thoughtProcesses as thoughtProcessesOptions,
  perception as perceptionOptions,
  insightOrJudgement as insightOrJudgementOptions,
} from '@talkspace/configs';
import ConfirmButton from './ConfirmButton';
import {
  useProgressNoteFormState,
  useProgressNoteFormActions,
} from '../context/PsychProgressNoteFormContext';
import { ProgressNoteFormState } from '../context/types';
import {
  mentalStatusExamDict,
  MentalStatusExam,
  ProgressNoteMentalStatusExamCreationAttributes,
} from '../../types';
import MentalStatusExamQuestion from './MentalStatusExamQuestion';

type MentalStatusExamCategory = keyof Pick<
  ProgressNoteMentalStatusExamCreationAttributes,
  keyof typeof mentalStatusExamDict
>;

type MentalStatusExamCategoryDescription = keyof Pick<
  ProgressNoteMentalStatusExamCreationAttributes,
  | 'appearanceDescription'
  | 'attitudeDescription'
  | 'behaviorDescription'
  | 'speechDescription'
  | 'affectDescription'
  | 'moodDescription'
  | 'thoughtProcessesDescription'
  | 'perceptionDescription'
  | 'insightOrJudgement'
>;

interface MentalExamDescriptionErrors {
  appearanceDescriptionError: boolean;
  attitudeDescriptionError: boolean;
  behaviorDescriptionError: boolean;
  speechDescriptionError: boolean;
  affectDescriptionError: boolean;
  moodDescriptionError: boolean;
  thoughtProcessesDescriptionError: boolean;
  perceptionDescriptionError: boolean;
}

const MentalStatusExamSection = () => {
  const {
    formSections,
    mentalStatusExam: globalMentalStatusExam,
    formMode,
  } = useProgressNoteFormState();
  const { setFormState } = useProgressNoteFormActions();
  const [mentalStatusExam, setMentalStatusExam] =
    useObjectState<ProgressNoteFormState['mentalStatusExam']>(globalMentalStatusExam);
  const [mentalStatusExamDescriptionErrors, setMentalStatusExamDescriptionErrors] =
    useState<MentalExamDescriptionErrors>({
      appearanceDescriptionError: false,
      attitudeDescriptionError: false,
      behaviorDescriptionError: false,
      speechDescriptionError: false,
      affectDescriptionError: false,
      moodDescriptionError: false,
      thoughtProcessesDescriptionError: false,
      perceptionDescriptionError: false,
    });
  const markSectionAsIncomplete = () => {
    if (formSections.mentalStatusExam.completed) {
      setFormState({
        formSections: {
          ...formSections,
          mentalStatusExam: {
            open: formSections.mentalStatusExam.open,
            changed: true,
            completed: false,
          },
        },
      });
    }
  };

  const handleAccordionPress = (value: boolean) => {
    setFormState({
      formSections: {
        ...formSections,
        mentalStatusExam: {
          open: value,
          changed: formSections.mentalStatusExam.changed,
          completed: formSections.mentalStatusExam.completed,
        },
      },
    });
  };

  const setIsChecked = <T extends string>(
    field: MentalStatusExamCategory,
    option: T,
    isChecked: boolean
  ) => {
    markSectionAsIncomplete();
    setMentalStatusExam({
      [field]: isChecked ? option : null,
    });
  };

  const setDescription = (field: MentalStatusExamCategoryDescription, newValue: string) => {
    markSectionAsIncomplete();
    setMentalStatusExam({
      ...mentalStatusExam,
      [field]: newValue,
    });
  };

  const setAppearanceDescription = (value: string) => {
    setDescription('appearanceDescription', value);
    setMentalStatusExamDescriptionErrors({
      ...mentalStatusExamDescriptionErrors,
      appearanceDescriptionError: false,
    });
  };

  const setAttributeDescription = (value: string) => {
    setDescription('attitudeDescription', value);
    setMentalStatusExamDescriptionErrors({
      ...mentalStatusExamDescriptionErrors,
      attitudeDescriptionError: false,
    });
  };

  const setBehaviorDescription = (value: string) => {
    setDescription('behaviorDescription', value);
    setMentalStatusExamDescriptionErrors({
      ...mentalStatusExamDescriptionErrors,
      behaviorDescriptionError: false,
    });
  };

  const setSpeechDescription = (value: string) => {
    setDescription('speechDescription', value);
    setMentalStatusExamDescriptionErrors({
      ...mentalStatusExamDescriptionErrors,
      speechDescriptionError: false,
    });
  };

  const setAffectDescription = (value: string) => {
    setDescription('affectDescription', value);
    setMentalStatusExamDescriptionErrors({
      ...mentalStatusExamDescriptionErrors,
      affectDescriptionError: false,
    });
  };

  const setMoodDescription = (value: string) => {
    setDescription('moodDescription', value);
    setMentalStatusExamDescriptionErrors({
      ...mentalStatusExamDescriptionErrors,
      moodDescriptionError: false,
    });
  };

  const setThoughtProcessesDescription = (value: string) => {
    setDescription('thoughtProcessesDescription', value);
    setMentalStatusExamDescriptionErrors({
      ...mentalStatusExamDescriptionErrors,
      thoughtProcessesDescriptionError: false,
    });
  };

  const setPerceptionDescription = (value: string) => {
    setDescription('perceptionDescription', value);
    setMentalStatusExamDescriptionErrors({
      ...mentalStatusExamDescriptionErrors,
      perceptionDescriptionError: false,
    });
  };

  const handleConfirmPress = () => {
    const otherAppearanceError =
      mentalStatusExam?.appearance === 'other' && !mentalStatusExam?.appearanceDescription;
    const otherAttitudeError =
      mentalStatusExam?.attitude === 'other' && !mentalStatusExam?.attitudeDescription;
    const otherBehaviorError =
      mentalStatusExam?.behavior === 'other' && !mentalStatusExam?.behaviorDescription;
    const otherSpeechError =
      mentalStatusExam?.speech === 'other' && !mentalStatusExam?.speechDescription;
    const otherAffectError =
      mentalStatusExam?.affect === 'other' && !mentalStatusExam?.affectDescription;
    const otherMoodError = mentalStatusExam?.mood === 'other' && !mentalStatusExam?.moodDescription;
    const otherThoughtProcessesError =
      mentalStatusExam?.thoughtProcesses === 'other' &&
      !mentalStatusExam?.thoughtProcessesDescription;
    const otherPerceptionError =
      mentalStatusExam?.perception === 'other' && !mentalStatusExam?.perceptionDescription;

    const isError =
      otherAppearanceError ||
      otherAttitudeError ||
      otherBehaviorError ||
      otherSpeechError ||
      otherAffectError ||
      otherMoodError ||
      otherThoughtProcessesError ||
      otherPerceptionError;
    setMentalStatusExamDescriptionErrors({
      appearanceDescriptionError: otherAppearanceError,
      attitudeDescriptionError: otherAttitudeError,
      behaviorDescriptionError: otherBehaviorError,
      speechDescriptionError: otherSpeechError,
      affectDescriptionError: otherAffectError,
      moodDescriptionError: otherMoodError,
      thoughtProcessesDescriptionError: otherThoughtProcessesError,
      perceptionDescriptionError: otherPerceptionError,
    });

    if (isError) {
      setFormState({ showGlobalError: isError });
    } else {
      setFormState({
        mentalStatusExam,
        formSections: {
          ...formSections,
          mentalStatusExam: {
            open: false,
            changed: true,
            completed: true,
          },
          ...(formSections.psychSessionSummary.completed
            ? {}
            : {
                psychSessionSummary: {
                  ...formSections.psychSessionSummary,
                  open: true,
                },
              }),
        },
      });
    }
  };

  return (
    <AccordionContainer
      title="Mental status exam"
      open={formSections.mentalStatusExam.open}
      onPress={handleAccordionPress}
      showCheckMark={formMode !== 'view' && formSections.mentalStatusExam.completed}
      dataQa="mentalStatusAccordion"
    >
      <MentalStatusExamQuestion<MentalStatusExam.Appearance>
        isDescriptionError={mentalStatusExamDescriptionErrors.appearanceDescriptionError}
        title="Appearance"
        options={appearanceOptions}
        value={mentalStatusExam!.appearance}
        description={mentalStatusExam!.appearanceDescription}
        setIsChecked={(option: MentalStatusExam.Appearance, isChecked: boolean) =>
          setIsChecked<MentalStatusExam.Appearance>('appearance', option, isChecked)
        }
        setDescription={setAppearanceDescription}
        disabled={formMode === 'view'}
      />
      <MentalStatusExamQuestion<MentalStatusExam.Attitude>
        isDescriptionError={mentalStatusExamDescriptionErrors.attitudeDescriptionError}
        title="Attitude"
        options={attitudeOptions}
        value={mentalStatusExam!.attitude}
        description={mentalStatusExam!.attitudeDescription}
        setIsChecked={(option: MentalStatusExam.Attitude, isChecked: boolean) =>
          setIsChecked<MentalStatusExam.Attitude>('attitude', option, isChecked)
        }
        setDescription={setAttributeDescription}
        disabled={formMode === 'view'}
      />
      <MentalStatusExamQuestion<MentalStatusExam.Behavior>
        isDescriptionError={mentalStatusExamDescriptionErrors.behaviorDescriptionError}
        title="Behavior"
        options={behaviorOptions}
        value={mentalStatusExam!.behavior}
        description={mentalStatusExam!.behaviorDescription}
        setIsChecked={(option: MentalStatusExam.Behavior, isChecked: boolean) =>
          setIsChecked<MentalStatusExam.Behavior>('behavior', option, isChecked)
        }
        setDescription={setBehaviorDescription}
        disabled={formMode === 'view'}
      />
      <MentalStatusExamQuestion<MentalStatusExam.Speech>
        isDescriptionError={mentalStatusExamDescriptionErrors.speechDescriptionError}
        title="Speech"
        options={speechOptions}
        value={mentalStatusExam!.speech}
        description={mentalStatusExam!.speechDescription}
        setIsChecked={(option: MentalStatusExam.Speech, isChecked: boolean) =>
          setIsChecked<MentalStatusExam.Speech>('speech', option, isChecked)
        }
        setDescription={setSpeechDescription}
        disabled={formMode === 'view'}
      />
      <MentalStatusExamQuestion<MentalStatusExam.Affect>
        isDescriptionError={mentalStatusExamDescriptionErrors.affectDescriptionError}
        title="Affect"
        options={affectOptions}
        value={mentalStatusExam!.affect}
        description={mentalStatusExam!.affectDescription}
        setIsChecked={(option: MentalStatusExam.Affect, isChecked: boolean) =>
          setIsChecked<MentalStatusExam.Affect>('affect', option, isChecked)
        }
        setDescription={setAffectDescription}
        disabled={formMode === 'view'}
      />
      <MentalStatusExamQuestion<MentalStatusExam.Mood>
        isDescriptionError={mentalStatusExamDescriptionErrors.moodDescriptionError}
        title="Mood"
        options={moodOptions}
        value={mentalStatusExam!.mood}
        description={mentalStatusExam!.moodDescription}
        setIsChecked={(option: MentalStatusExam.Mood, isChecked: boolean) =>
          setIsChecked<MentalStatusExam.Mood>('mood', option, isChecked)
        }
        setDescription={setMoodDescription}
        disabled={formMode === 'view'}
      />
      <MentalStatusExamQuestion<MentalStatusExam.ThoughtProcesses>
        isDescriptionError={mentalStatusExamDescriptionErrors.thoughtProcessesDescriptionError}
        title="Thought processes"
        options={thoughtProcessesOptions}
        value={mentalStatusExam!.thoughtProcesses}
        description={mentalStatusExam!.thoughtProcessesDescription}
        setIsChecked={(option: MentalStatusExam.ThoughtProcesses, isChecked: boolean) =>
          setIsChecked<MentalStatusExam.ThoughtProcesses>('thoughtProcesses', option, isChecked)
        }
        setDescription={setThoughtProcessesDescription}
        disabled={formMode === 'view'}
      />
      <MentalStatusExamQuestion<MentalStatusExam.Perception>
        isDescriptionError={mentalStatusExamDescriptionErrors.perceptionDescriptionError}
        title="Perception"
        options={perceptionOptions}
        value={mentalStatusExam!.perception}
        description={mentalStatusExam!.perceptionDescription}
        setIsChecked={(option: MentalStatusExam.Perception, isChecked: boolean) =>
          setIsChecked<MentalStatusExam.Perception>('perception', option, isChecked)
        }
        setDescription={setPerceptionDescription}
        disabled={formMode === 'view'}
      />
      <MentalStatusExamQuestion<MentalStatusExam.InsightOrJudgement>
        title="Insight/judgement"
        options={insightOrJudgementOptions}
        value={mentalStatusExam!.insightOrJudgement}
        description={null}
        setIsChecked={(option: MentalStatusExam.InsightOrJudgement, isChecked: boolean) =>
          setIsChecked<MentalStatusExam.InsightOrJudgement>('insightOrJudgement', option, isChecked)
        }
        setDescription={() => {}}
        style={{ marginBottom: 0 }}
        disabled={formMode === 'view'}
      />
      {formMode !== 'view' && !formSections.mentalStatusExam.completed && (
        <ConfirmButton onPress={handleConfirmPress} sectionTitle="mentalStatusExam" />
      )}
    </AccordionContainer>
  );
};

export default MentalStatusExamSection;
