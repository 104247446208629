import Svg, { Rect, Path } from 'svgs';
import { EmotionStyle, useEmotionTheme } from '../../core/styled';

interface ExclamationSquareProps {
  width?: number;
  height?: number;
  color?: string;
  titleText?: string;
  style?: EmotionStyle;
}

const ExclamationSquare = ({
  width = 11,
  height = 11,
  titleText = 'Exclamation',
  color,
  ...otherProps
}: ExclamationSquareProps) => {
  const { colors } = useEmotionTheme();
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 11 11"
      title={titleText}
      aria-label={titleText}
      {...otherProps}
    >
      <Rect width={11} height={11} rx={2} fill={color || colors.permaDeepViolet} />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 6.5h2V2h-2v4.5zm0 2.75h2V7.5h-2v1.75z"
        fill="#fff"
      />
    </Svg>
  );
};

export default ExclamationSquare;
