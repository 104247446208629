import { FunctionComponent, RefObject, Dispatch, SetStateAction } from 'react';
import {
  useUniqueID,
  SkipNavButton,
  View,
  TextArea,
  FloatingMenu,
  commonStyles,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import { fontWeight } from '@talkspace/react-toolkit/src/designSystems/tokens';
import styled, { EmotionStyle } from '@/core/styled/styled';
import { ID_ROOM_DETAILS_BUTTON } from '@/utils/IDConstants';
import { useA11y } from './InputComposerView.a11y';

const { crossBrowserFocusRing } = commonStyles;

export interface InputComposerViewProps {
  text: string;
  placeholder?: string;
  disabled?: boolean;
  wrapperStyle?: EmotionStyle;
  inputStyle?: EmotionStyle;
  inputRef?: RefObject<HTMLTextAreaElement>;
  onChangeText(text: string): void;
  onSubmitEditing(): void;
  tabIndex?: number;
  id?: string;
  isInputAutoFocused: boolean;
  isInputSendFocused: boolean;
  isSendClicked: boolean;
  setIsInputAutoFocused: Dispatch<SetStateAction<boolean>>;
  setIsInputSendFocused: Dispatch<SetStateAction<boolean>>;
  setIsSendClicked: Dispatch<SetStateAction<boolean>>;
  isFocusedAfterModal: boolean;
  numberOfLines?: number;
}

// @ts-ignore
const Wrapper = styled(View)<{ shouldShowFocusOutline: boolean }>(({ shouldShowFocusOutline }) => {
  return {
    flex: 1,
    marginRight: 20,
    alignItems: 'stretch',
    justifyContent: 'center',
    backgroundColor: 'white',
    '&:hover:focus-within': {
      outline: 'none',
    },
    ':focus-within': shouldShowFocusOutline ? crossBrowserFocusRing : { outline: 'none' },
  };
});

const StyledInput = styled(TextArea)(
  ({
    theme: {
      colorRoles,
      window: { isMobile },
    },
  }) => {
    return {
      // TextDS Body styles
      fontSize: 16,
      lineHeight: '20px',
      fontWeight: fontWeight.regular,
      letterSpacing: 0.16,

      padding: 0,
      color: colorRoles.typography.textDefault,
      marginTop: 21,
      borderWidth: 0,
      marginBottom: 21,
      alignSelf: 'stretch',
      resize: 'none',
      WebkitAppearance: 'none',
      outline: '0px none',
      '::placeholder': {
        color: colorRoles.typography.textSubtlest /* Firefox */,
      },
      ':hover': {
        outline: 0,
        border: 0,
      },
      '::-webkit-scrollbar': {
        display: isMobile && 'none',
        width: !isMobile && 5,
      },
      '::-webkit-scrollbar-thumb:vertical': {
        margin: 5,
        backgroundColor: '#EBEBEB',
        WebkitBorderRadius: 5,
      },
      ':disabled': {
        backgroundColor: 'transparent' /* Windows Chrome */,
      },
    };
  }
);

const InputComposerView: FunctionComponent<InputComposerViewProps> = ({
  text,
  onChangeText,
  onSubmitEditing,
  placeholder,
  disabled,
  wrapperStyle,
  inputStyle,
  inputRef,
  tabIndex,
  id,
  isInputAutoFocused,
  isInputSendFocused,
  isSendClicked,
  setIsInputAutoFocused,
  setIsInputSendFocused,
  setIsSendClicked,
  isFocusedAfterModal,
  numberOfLines = 10,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  const navigationInfoID = useUniqueID('navigationInfoID');
  const { isClicked, isInputFocused, showNavInfo, setSkipNavRef, finalInputStyle } = useA11y(
    inputRef,
    text,
    isInputAutoFocused,
    isInputSendFocused,
    setIsInputAutoFocused,
    setIsInputSendFocused,
    setIsSendClicked,
    isFocusedAfterModal,
    inputStyle
  );

  return (
    <Wrapper
      style={wrapperStyle}
      shouldShowFocusOutline={
        !isFocusedAfterModal &&
        !isClicked &&
        isInputFocused &&
        !isInputAutoFocused &&
        !isSendClicked
      }
    >
      <FloatingMenu
        id={navigationInfoID}
        hide={!showNavInfo}
        onBackdropPress={() => null}
        style={{
          boxSizing: 'content-box',
          height: 'auto',
          maxWidth: 50,
          padding: 10,
        }}
      >
        Press up arrow to access the chat. Use up/down arrows to navigate between messages.
        <SkipNavButton
          ref={setSkipNavRef}
          buttonText="Go to top"
          returnID={ID_ROOM_DETAILS_BUTTON}
          style={{
            height: 'auto',
            width: 'auto',
            display: 'inline-block',
            marginTop: 5,
            marginBottom: 5,
            color: colors.green,
            alignSelf: 'start',
          }}
        />
      </FloatingMenu>
      <StyledInput
        aria-label={placeholder || 'Message your care provider'}
        {...otherProps}
        id={id}
        tabIndex={tabIndex}
        aria-describedby={showNavInfo ? navigationInfoID : undefined}
        multiline
        value={text}
        onChangeText={onChangeText}
        onSubmit={onSubmitEditing}
        numberOfLines={numberOfLines}
        placeholder={placeholder || 'Write a message...'}
        disabled={disabled}
        onSubmitEditing={onSubmitEditing}
        style={finalInputStyle}
        ref={inputRef}
      />
    </Wrapper>
  );
};

export default InputComposerView;
