import Svg, { Circle, Path } from 'svgs';

import { useEmotionTheme } from '../../core/styled';

interface Props {
  width?: number;
  height?: number;
}
const MbaPerson = ({ width = 131, height = 130, ...otherProps }: Props) => {
  const titleText = 'Person';
  const { colors } = useEmotionTheme();
  return (
    <Svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 131 130"
      title={titleText}
      aria-label={titleText}
      {...otherProps}
    >
      <Circle cx={65.5} cy={65} r={65} fill={colors.permaLinkWaterGrey} />
      <Path
        d="m47.992 42.592 17.99-.628a7.806 7.806 0 0 1 8.074 7.528l.63 17.992a7.806 7.806 0 0 1-7.529 8.074l-17.992.628a7.806 7.806 0 0 1-8.073-7.528l-.628-17.993a7.806 7.806 0 0 1 7.528-8.073Z"
        fill={colors.white}
        stroke={colors.permaPeriwinkleGray}
        strokeWidth={5.204}
      />
      <Path
        d="m61.16 55.11 17.964 1.16a7.806 7.806 0 0 1 7.287 8.291l-1.158 17.966a7.806 7.806 0 0 1-8.292 7.288l-17.966-1.16a7.806 7.806 0 0 1-7.287-8.291l1.16-17.967a7.806 7.806 0 0 1 8.291-7.286Z"
        fill={colors.permaTalkspaceDarkGreen}
        stroke={colors.permaTalkspaceDarkGreen}
        strokeWidth={5.204}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m77.688 83.286-17.94-1.108c-.966-.06-1.62-1.057-1.202-1.91 1.94-3.948 6.054-6.024 10.678-5.738 4.626.286 8.452 2.852 9.891 7.008.31.899-.461 1.807-1.427 1.748ZM64.149 66.745c.186-3.016 2.842-5.313 5.919-5.123 3.078.19 5.43 2.797 5.244 5.812-.187 3.016-2.841 5.314-5.92 5.123-3.076-.19-5.43-2.797-5.243-5.812Z"
        fill={colors.white}
      />
    </Svg>
  );
};

export default MbaPerson;
