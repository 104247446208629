import PlacesAutocomplete from 'react-places-autocomplete';

import { Input, View, TextDS } from '../index';
import styled, { useEmotionTheme } from '../../core/styled';
import { elevation2Style } from '../../designSystems/styles/elevation';
import { webOnlyStyle } from '../../core/styleHelpers';
import commonStyles from '../../constants/commonStyles';

const { crossBrowserFocusRing } = commonStyles;

const Styled = {
  Container: styled(View)({ width: '100%', maxWidth: 335 }),
  InputContainer: styled(View)({
    position: 'relative',
  }),
  Input: styled(Input)(({ theme: { spacing } }) => {
    return {
      height: 55,
      borderRadius: 10,
      borderStyle: 'solid',
      borderWidth: 1,
      padding: spacing('space200'),
      ...webOnlyStyle({
        '&:hover:focus-within': {
          outline: 'none',
        },
        ':focus-within': {
          ...crossBrowserFocusRing,
        },
      }),
    };
  }),
  OptionsContainer: styled(View)(({ theme: { colorRoles } }) => {
    return {
      position: 'absolute',
      top: '100%',
      width: '100%',
      borderRadius: 9,
      overflow: 'hidden',
      backgroundColor: colorRoles.surfaces.surfaceInteractiveDefault,
      zIndex: 21,
      ...elevation2Style,
    };
  }),
  Option: styled(View)<{ isActive: boolean }>(({ theme: { colorRoles }, isActive }) => {
    return {
      cursor: 'pointer',
      padding: 10,
      textAlign: 'left',
      backgroundColor: isActive
        ? colorRoles.surfaces.surfaceInteractiveHovered
        : colorRoles.surfaces.surfaceInteractiveDefault,
    };
  }),
};

type GooglePlacesPlaceType = 'address' | 'school';
interface Props {
  dataQa: string;
  label?: string;
  placeholder?: string;
  value: string;
  error: { message: string } | undefined;
  onChange: (value: string) => void;
  onSelect: (value: string) => void;
  searchOptions?: { types: GooglePlacesPlaceType[] };
}
const SearchGooglePlacesInput = ({
  dataQa,
  label,
  placeholder,
  value,
  error,
  onChange,
  onSelect,
  searchOptions,
}: Props) => {
  const { spacing } = useEmotionTheme();
  return (
    <Styled.Container>
      <PlacesAutocomplete
        value={value}
        onChange={onChange}
        onSelect={onSelect}
        searchOptions={searchOptions}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
          <Styled.InputContainer>
            <Styled.Input
              dataQa={dataQa}
              wrappedInputProps={{
                inputStyle: { width: '100%' },
                labelStyle: { marginBottom: spacing('space050'), padding: 0 },
                label,
                isError: !!error,
                errorMessage: error?.message,
              }}
              {...getInputProps({
                placeholder,
              })}
            />
            {suggestions && suggestions.length > 0 && (
              <Styled.OptionsContainer>
                {suggestions.map((suggestion) => (
                  <Styled.Option
                    {...getSuggestionItemProps(suggestion)}
                    isActive={suggestion.active}
                    key={suggestion.placeId}
                  >
                    <TextDS>{suggestion.description}</TextDS>
                  </Styled.Option>
                ))}
              </Styled.OptionsContainer>
            )}
          </Styled.InputContainer>
        )}
      </PlacesAutocomplete>
    </Styled.Container>
  );
};

export default SearchGooglePlacesInput;
