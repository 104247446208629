import { useQuery, UseQueryOptions } from 'react-query';

import { ChargeType, ClientTransaction } from 'ts-frontend/types';
import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';
import { clientTransactionsQueryKey } from '../utils/queryKeys';

interface GetClientTransactionsParams {
  clientUserID: number;
  chargeTypes?: Array<ChargeType>;
}

const getClientTransactions =
  ({ clientUserID, chargeTypes = [] }: GetClientTransactionsParams) =>
  async (): Promise<Array<ClientTransaction>> => {
    const params = new URLSearchParams({});

    if (chargeTypes) {
      chargeTypes.forEach((it) => {
        params.append('chargeType', it);
      });
    }

    const apiURL = `v2/payments/users/${clientUserID}/client-transactions`;

    const res = await apiWrapper.get<{ data: Array<ClientTransaction> }>(
      `${apiHelper().apiEndpoint}/${apiURL}?${params.toString()}`
    );

    return res.data.data;
  };

const useQueryClientTransactions = (
  params: GetClientTransactionsParams,
  options: UseQueryOptions<Array<ClientTransaction>, Error, Array<ClientTransaction>> = {}
) =>
  useQuery<Array<ClientTransaction>, Error>({
    queryKey: clientTransactionsQueryKey(params),
    queryFn: getClientTransactions(params),
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
    ...options,
  });

export default useQueryClientTransactions;
