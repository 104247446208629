import moment from 'moment-timezone';
import { isZipValid } from '@talkspace/react-toolkit';
import { DoseSpotFormErrors, DoseSpotFormValues } from './types';

const GENERIC_ERROR = 'You must fill out the missing information to add the client to DoseSpot';
const MIN_AGE_FOR_DOSESPOT = 18;
const MAX_ADDRESS_LENGTH = 35;
const PHONE_NUMBER_LENGTH_RANGE = [10, 15];

export const isOfAgeForDoseSpot = (dob: string) =>
  moment().diff(moment(dob, 'MM/DD/YYYY'), 'years') >= MIN_AGE_FOR_DOSESPOT;

export const isPhoneNumberValid = (primaryPhone: string) => {
  if (!primaryPhone) {
    return false;
  }
  const phoneNumberDigitString = (primaryPhone.match(/\d+/g) || []).join('');
  return (
    phoneNumberDigitString.length >= PHONE_NUMBER_LENGTH_RANGE[0] &&
    phoneNumberDigitString.length <= PHONE_NUMBER_LENGTH_RANGE[1]
  );
};

export const isZipcodeValid = (zipcode: string) => {
  if (!zipcode) {
    return false;
  }
  return isZipValid(zipcode, 'US');
};

export const validatePatientForm = (values: DoseSpotFormValues): DoseSpotFormErrors => {
  const errors: DoseSpotFormErrors = {};

  if (!values.firstName) {
    errors.firstName = GENERIC_ERROR;
  }

  if (!values.lastName) {
    errors.lastName = GENERIC_ERROR;
  }

  if (
    !moment(values.dateOfBirth, 'MM/DD/YYYY').isValid() ||
    !isOfAgeForDoseSpot(values.dateOfBirth)
  ) {
    errors.dateOfBirth = `User needs to be ${MIN_AGE_FOR_DOSESPOT} or older`;
  }

  if (!values.gender) {
    errors.gender = GENERIC_ERROR;
  }

  if (!values.address || values.address.length > MAX_ADDRESS_LENGTH) {
    errors.address = `Address must be ${MAX_ADDRESS_LENGTH} or fewer characters.`;
  }

  if (!values.city) {
    errors.city = GENERIC_ERROR;
  }

  if (!values.state) {
    errors.state = GENERIC_ERROR;
  }

  if (!isZipcodeValid(values.zipcode)) {
    errors.zipcode = 'ZIP code must be valid';
  }

  if (!isPhoneNumberValid(values.primaryPhone)) {
    errors.primaryPhone = 'Phone number must be valid';
  }

  return errors;
};
