import { useQuery } from 'react-query';
import apiWrapper from '../../utils/apiWrapper';
import apiHelper from '../../utils/api';
import dashboardKeys from './queryKeys';

export interface ClientUnavailabilityPeriodResponse {
  contentId: number;
  endDate: Date;
  startDate: Date;
  state: number;
  timezone: string;
}

const fetchClientUnavailabilityPeriod = (therapistID: number) => async () => {
  const { data } = await apiWrapper.get(
    `${apiHelper().apiEndpoint}/api/v1/therapist/${therapistID}/new-client-unavailability-period`
  );
  return data.data;
};

const useQueryClientUnavailabilityPeriod = (therapistID: number) =>
  useQuery<ClientUnavailabilityPeriodResponse, Error>({
    queryKey: dashboardKeys.clientUnavailabilityPeriodQueryKey(therapistID),
    queryFn: fetchClientUnavailabilityPeriod(therapistID),
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
  });

export default useQueryClientUnavailabilityPeriod;
