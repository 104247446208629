import { KeepAwake } from '@capacitor-community/keep-awake';
import { NativeFeaturesEnum, safeIonicWrapper, shouldShowNativeFeature } from '../../ionicUtils';

export const keepAwake = safeIonicWrapper(
  async () => {
    if (!shouldShowNativeFeature(NativeFeaturesEnum.KEEP_AWAKE)) {
      return () => Promise.resolve();
    }

    await KeepAwake.keepAwake();
    return () => KeepAwake.allowSleep();
  },
  Promise.resolve(async () => {})
);

export const allowSleep = safeIonicWrapper(() => {
  if (!shouldShowNativeFeature(NativeFeaturesEnum.KEEP_AWAKE)) {
    return Promise.resolve();
  }
  return KeepAwake.allowSleep();
}, Promise.resolve());
