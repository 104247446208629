import Svg, { Path } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

const Back = ({ width = 13, height = 23, ...props }) => {
  const { colors } = useEmotionTheme();
  const titleText = 'left arrow';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 13 23"
      fill="none"
      {...props}
    >
      <Path
        d="M10.54 22.558c.564.59 1.477.589 2.04-.004a1.57 1.57 0 00-.004-2.143L4.06 11.5l8.516-8.911a1.57 1.57 0 00.003-2.143 1.394 1.394 0 00-2.04-.004L0 11.5l10.54 11.058z"
        fill={colors.green}
      />
    </Svg>
  );
};

export default Back;
