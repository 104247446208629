import Svg, { Defs, G, Path, Rect } from 'svgs';

const HorizontalScrollRightArrowHoverIcon = () => (
  <Svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
    {/* @ts-ignore */}
    <G filter="url(#filter0_d_900_10809)">
      <Rect width="35" height="35" rx="17.5" transform="matrix(-1 0 0 1 43 6)" fill="white" />
      <Path
        d="M22.7765 32.4242C22.5432 32.1909 22.4265 31.9159 22.4265 31.5992C22.4265 31.2826 22.5432 31.0076 22.7765 30.7742L30.0515 23.4992L22.7515 16.1992C22.5182 15.9659 22.4059 15.6909 22.4145 15.3742C22.4225 15.0576 22.5432 14.7826 22.7765 14.5492C23.0099 14.3159 23.2849 14.1992 23.6015 14.1992C23.9182 14.1992 24.1932 14.3159 24.4265 14.5492L32.7265 22.8492C32.8099 22.9492 32.8725 23.0532 32.9145 23.1612C32.9559 23.2699 32.9765 23.3826 32.9765 23.4992C32.9765 23.6159 32.9559 23.7282 32.9145 23.8362C32.8725 23.9449 32.8099 24.0492 32.7265 24.1492L24.4015 32.4492C24.1849 32.6826 23.9182 32.7952 23.6015 32.7872C23.2849 32.7786 23.0099 32.6576 22.7765 32.4242Z"
        fill="#005C65"
      />
    </G>
    <Defs>
      <filter
        id="filter0_d_900_10809"
        x="0"
        y="0"
        width="51"
        height="51"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="4"
          operator="erode"
          in="SourceAlpha"
          result="effect1_dropShadow_900_10809"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="6" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.788235 0 0 0 0 0.827451 0 0 0 0 0.862745 0 0 0 1 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_900_10809" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_900_10809"
          result="shape"
        />
      </filter>
    </Defs>
  </Svg>
);

export default HorizontalScrollRightArrowHoverIcon;
