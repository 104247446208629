import { useRef } from 'react';
import { SessionModality } from 'ts-frontend/types';
import { useFlags } from 'launchDarkly/FlagsProvider';
import useMutationGenerateSessionSummary, {
  HTTP_STATUS_ACCEPTED,
  HTTP_STATUS_OK,
  DEFAULT_POLLING_TIME,
  MAX_NUMBER_OF_RETRIES,
} from '../../../../../../../../hooks/notes/useMutationGenerateSessionSummary';
import { useRouteMatch } from '@/core/routerLib';
import {
  useProgressNoteFormActions,
  useProgressNoteFormState,
} from '../../../context/TherapistProgressNoteFormContext';
import useGenerateSessionSummarySocket from './useGenerateSessionSummarySocket';
import {
  UnsubmittedLiveSession,
  UnsubmittedMessagingSession,
} from '../../../../../../../../hooks/notes/useQueryUnsubmittedSessions';
import useDidUpdateEffect from '../../../../../../../../hooks/useDidUpdateEffect';
import { GeneratedSessionSummaryFeedback } from '../../../../../../../../hooks/notes/useMutationRateSmartNote';
import trackEvent from '@/analytics/trackEvent';

interface UseGenerateSessionSummaryProps {
  handleSessionSummary: (value: string) => void;
  handleSetSmartNoteState: (val: string | null) => void;
}

const useGenerateSessionSummary = ({
  handleSessionSummary,
  handleSetSmartNoteState,
}: UseGenerateSessionSummaryProps) => {
  const match = useRouteMatch<{ roomID: string; noteID?: string }>();

  const {
    serviceStartDate,
    serviceEndDate,
    generatedSessionSummaryID,
    unsubmittedSessionID,
    unsubmittedSession,
    treatmentPlanID,
    treatmentPlanGoals,
    treatmentPlanInterventions,
    treatmentPlanObjectives,
  } = useProgressNoteFormState();
  const { setFormState } = useProgressNoteFormActions();
  const responseStatusRef = useRef<number | null>(null);
  const { mutate: generateSessionSummary, isLoading } = useMutationGenerateSessionSummary();
  const {
    aiDocumentation: { loadingTimeoutInSeconds, treatmentPlanBasedDocumentation } = {
      loadingTimeoutInSeconds: DEFAULT_POLLING_TIME,
      treatmentPlanBasedDocumentation: false,
    },
  } = useFlags();
  const numberOfRetriesRef = useRef<number>(0);
  const feedbackRef = useRef<string | null>(null);

  useDidUpdateEffect(() => {
    if (isLoading) {
      handleSetSmartNoteState('loading');
    }
  }, [isLoading]);

  const handleSmartNoteError = () => {
    handleSetSmartNoteState(
      numberOfRetriesRef.current === MAX_NUMBER_OF_RETRIES ? 'error' : 'timeout'
    );
    if (numberOfRetriesRef.current === MAX_NUMBER_OF_RETRIES) {
      numberOfRetriesRef.current = 0;
    }
    responseStatusRef.current = null;
  };

  const handleSocketResponse = (
    status: 'error' | 'success',
    generatedSessionSummary: string,
    feedback: GeneratedSessionSummaryFeedback | null
  ) => {
    if (status === 'error') {
      handleSmartNoteError();
    } else if (generatedSessionSummary) {
      responseStatusRef.current = HTTP_STATUS_OK;
      handleSetSmartNoteState('success');
      handleSessionSummary(generatedSessionSummary);
      feedbackRef.current = feedback;
    }
  };

  useGenerateSessionSummarySocket({
    callback: handleSocketResponse,
    roomID: match.params.roomID,
    unsubmittedSessionID,
    generatedSessionSummaryID,
  });

  const handleAddSmartNote = () => {
    if (unsubmittedSession && serviceStartDate && serviceEndDate) {
      const sessionModality: SessionModality =
        unsubmittedSession.sessionModality === 'live'
          ? (unsubmittedSession as UnsubmittedLiveSession).liveSessionType
          : 'messaging';

      numberOfRetriesRef.current += 1;

      const treatmentPlan =
        treatmentPlanID && treatmentPlanBasedDocumentation
          ? {
              treatmentPlanID,
              treatmentPlanGoals,
              treatmentPlanInterventions,
              treatmentPlanObjectives,
            }
          : undefined;

      generateSessionSummary(
        {
          roomID: match.params.roomID,
          sessionModality,
          serviceStartDate,
          serviceEndDate,
          asyncSessionID: (unsubmittedSession as UnsubmittedMessagingSession)?.asyncSessionID,
          liveCallID: (unsubmittedSession as UnsubmittedLiveSession)?.liveCallID,
          freeLiveCallID: (unsubmittedSession as UnsubmittedLiveSession)?.freeLiveCallIDs?.[0],
          treatmentPlan,
        },
        {
          onSuccess: (data) => {
            if (data.status === HTTP_STATUS_OK) {
              handleSetSmartNoteState('success');
            }
            feedbackRef.current = data.feedback;
            responseStatusRef.current = data.status;
            setFormState({ generatedSessionSummaryID: data.generatedSessionSummaryID });
            if (data.generatedSessionSummary) {
              handleSessionSummary(data.generatedSessionSummary);
            }
            trackEvent(
              'addNoteInfo',
              {
                actionName: 'progressNotesInteraction',
              },
              {
                action: `Click "Add Smart Note"`,
                roomID: match.params.roomID,
                unsubmittedSessionID,
              }
            );
          },
          onError: () => {
            handleSmartNoteError();
            trackEvent(
              'addNoteInfo',
              {
                actionName: 'progressNotesInteraction',
              },
              {
                action: `Click "Add Smart Note Failed"`,
                roomID: match.params.roomID,
                unsubmittedSessionID,
              }
            );
          },
        }
      );
    }
  };
  useDidUpdateEffect(() => {
    if (responseStatusRef.current === HTTP_STATUS_ACCEPTED) {
      setTimeout(() => {
        if (responseStatusRef.current !== HTTP_STATUS_OK) {
          handleSmartNoteError();
        }
      }, loadingTimeoutInSeconds * 1000);
    }
  }, [responseStatusRef.current]);

  return { handleAddSmartNote, feedback: feedbackRef.current };
};

export default useGenerateSessionSummary;
