import PanelHeader from './PanelHeader';
import PanelHeaderV1 from './PanelHeaderV1';
import { VersionMap } from '../../types';
import { withVersioning } from '../../utils';

const versionMap: VersionMap<React.ComponentProps<typeof PanelHeader>> = {
  '1.0.0': PanelHeaderV1,
  DefaultComponent: PanelHeader,
};

export default withVersioning(versionMap);
