import Svg, { Path, Defs, G } from 'svgs';
import { useEmotionTheme } from '../../core/styled';

const SuccessCheckmarkV2 = ({ width = 145, height = 145, ...props }) => {
  const { colors } = useEmotionTheme();
  const titleText = 'check mark';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 145 145"
      {...props}
    >
      <Defs>
        <circle id="b" cx={51.5} cy={51.5} r={51.5} />
        <filter
          x="-35.9%"
          y="-28.2%"
          width="171.8%"
          height="171.8%"
          filterUnits="objectBoundingBox"
          id="a"
        >
          <feMorphology radius={1} in="SourceAlpha" result="shadowSpreadOuter1" />
          <feOffset dy={8} in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
          <feGaussianBlur stdDeviation={12} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
          <feColorMatrix
            values="0 0 0 0 0 0 0 0 0 0.117465042 0 0 0 0 0.264296344 0 0 0 0.201737998 0"
            in="shadowBlurOuter1"
          />
        </filter>
      </Defs>
      <G
        transform="translate(-527 -150) translate(440 163) translate(108)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <use fill={colors.trueBlack} filter="url(#a)" xlinkHref="#b" />
        <use fill={colors.white} xlinkHref="#b" />
        <Path
          stroke={colors.green}
          strokeWidth={8}
          strokeLinecap="round"
          d="M33 51.9762995L46.2055945 65.952599 69.4641766 38"
        />
      </G>
    </Svg>
  );
};

export default SuccessCheckmarkV2;
