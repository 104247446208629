import { Path, Svg, Rect } from 'svgs';

import { useEmotionTheme } from '../..';

const MessageSparkles = ({ width = 336, height = 176 }) => {
  const { colorRoles } = useEmotionTheme();
  const titleText = 'Message sparkles';
  return (
    <Svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      title={titleText}
      aria-label={titleText}
    >
      <Path
        d="M201.9 163.061L233.249 139.445L195.493 139.445L195.493 159.866C195.493 163.166 199.264 165.047 201.9 163.061Z"
        fill="white"
      />
      <Rect x="51.1252" y="41.7932" width="243.135" height="103.34" rx="20" fill="white" />
      <Rect
        x="66.6057"
        y="64.5466"
        width="212.174"
        height="11.3769"
        rx="5.68845"
        fill="url(#paint0_linear_483_260)"
      />
      <Rect
        x="66.6057"
        y="87.3003"
        width="183.945"
        height="11.3769"
        rx="5.68845"
        fill="url(#paint1_linear_483_260)"
      />
      <Rect
        x="66.6057"
        y="110.054"
        width="196.693"
        height="11.3769"
        rx="5.68844"
        fill="url(#paint2_linear_483_260)"
      />
      <Path
        opacity="0.2"
        d="M311.757 119.074C311.757 118.748 311.953 118.456 312.246 118.341L315.931 116.901L317.314 113.065C317.425 112.759 317.705 112.556 318.018 112.556C318.331 112.556 318.612 112.759 318.723 113.065L320.105 116.901L323.79 118.341C324.084 118.456 324.279 118.748 324.279 119.074C324.279 119.4 324.084 119.692 323.79 119.808L320.105 121.247L318.723 125.083C318.612 125.389 318.331 125.593 318.018 125.593C317.705 125.593 317.425 125.389 317.314 125.083L315.931 121.247L312.246 119.808C311.953 119.692 311.757 119.4 311.757 119.074Z"
        fill={colorRoles.icons.iconBrandDefault}
      />
      <Path
        opacity="0.5"
        d="M310.953 136.94C310.754 136.485 310.317 136.198 309.842 136.198C309.366 136.198 308.929 136.485 308.73 136.94L304.682 146.04L295.942 150.246C295.505 150.454 295.229 150.909 295.229 151.412C295.229 151.914 295.505 152.361 295.942 152.569L304.69 156.776L308.722 165.875C308.922 166.33 309.359 166.618 309.834 166.618C310.309 166.618 310.746 166.33 310.946 165.875L314.986 156.768L323.734 152.561C324.171 152.354 324.447 151.899 324.447 151.404C324.447 150.909 324.171 150.454 323.734 150.246L314.994 146.048L310.953 136.94Z"
        fill={colorRoles.icons.iconBrandDefault}
      />
      <Path
        opacity="0.2"
        d="M22.5196 67.9489L27.7398 65.9096L29.6985 60.4746C29.8556 60.0418 30.2529 59.7532 30.6964 59.7532C31.1399 59.7532 31.5372 60.0418 31.6942 60.4746L33.653 65.9096L38.8732 67.9489C39.2889 68.1124 39.5661 68.526 39.5661 68.9877C39.5661 69.4495 39.2889 69.8631 38.8732 70.0266L33.653 72.0659L31.6942 77.5009C31.5372 77.9337 31.1399 78.2223 30.6964 78.2223C30.2529 78.2223 29.8556 77.9337 29.6985 77.5009L27.7398 72.0659L22.5196 70.0266C22.1038 69.8631 21.8267 69.4495 21.8267 68.9877C21.8267 68.526 22.1038 68.1124 22.5196 67.9489Z"
        fill={colorRoles.icons.iconBrandDefault}
      />
      <Path
        opacity="0.7"
        d="M10.5903 48.8889C10.5903 48.563 10.786 48.271 11.0795 48.1555L14.7643 46.716L16.147 42.8796C16.2578 42.5741 16.5383 42.3704 16.8513 42.3704C17.1644 42.3704 17.4448 42.5741 17.5557 42.8796L18.9383 46.716L22.6232 48.1555C22.9166 48.271 23.1123 48.563 23.1123 48.8889C23.1123 49.2148 22.9166 49.5068 22.6232 49.6222L18.9383 51.0617L17.5557 54.8981C17.4448 55.2037 17.1644 55.4074 16.8513 55.4074C16.5383 55.4074 16.2578 55.2037 16.147 54.8981L14.7643 51.0617L11.0795 49.6222C10.786 49.5068 10.5903 49.2148 10.5903 48.8889Z"
        fill={colorRoles.icons.iconBrandDefault}
      />
      <Path
        opacity="0.3"
        d="M41.2408 5.85411C41.0109 5.33062 40.507 5 39.9588 5C39.4106 5 38.9066 5.33062 38.6767 5.85411L34.0082 16.3238L23.9285 21.1637C23.4245 21.4025 23.1062 21.926 23.1062 22.5046C23.1062 23.0832 23.4245 23.5975 23.9285 23.8363L34.017 28.6762L38.6678 39.1459C38.8977 39.6694 39.4017 40 39.9499 40C40.4981 40 41.0021 39.6694 41.232 39.1459L45.8916 28.667L55.9802 23.8271C56.4842 23.5883 56.8025 23.0648 56.8025 22.4954C56.8025 21.926 56.4842 21.4025 55.9802 21.1637L45.9005 16.333L41.2408 5.85411Z"
        fill={colorRoles.icons.iconBrandDefault}
      />
      <defs>
        <linearGradient
          id="paint0_linear_483_260"
          x1="66.6057"
          y1="70.2351"
          x2="278.78"
          y2="70.2351"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={colorRoles.surfaces.decorativeBalanceBlue} />
          <stop
            offset="0.28"
            stopColor={colorRoles.surfaces.decorativeBalanceBlue}
            stopOpacity="0.2"
          />
          <stop offset="1" stopColor={colorRoles.surfaces.decorativeBalanceBlue} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_483_260"
          x1="66.6057"
          y1="92.9887"
          x2="250.55"
          y2="92.9887"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={colorRoles.surfaces.decorativeBalanceBlue} />
          <stop
            offset="0.315"
            stopColor={colorRoles.surfaces.decorativeBalanceBlue}
            stopOpacity="0.2"
          />
          <stop offset="1" stopColor={colorRoles.surfaces.decorativeBalanceBlue} />
        </linearGradient>
        <linearGradient
          id="paint2_linear_483_260"
          x1="66.6057"
          y1="115.743"
          x2="263.299"
          y2="115.743"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={colorRoles.surfaces.decorativeBalanceBlue} />
          <stop
            offset="0.28"
            stopColor={colorRoles.surfaces.decorativeBalanceBlue}
            stopOpacity="0.2"
          />
          <stop offset="1" stopColor={colorRoles.surfaces.decorativeBalanceBlue} />
        </linearGradient>
      </defs>
    </Svg>
  );
};

export default MessageSparkles;
