import { useState, FunctionComponent, useCallback, useEffect } from 'react';
import * as React from 'react';
import styled, { EmotionStyle } from '../core/styled';

type HoverType = 'mouse' | 'touch';

export interface WithHoverProps {
  isHovering: boolean;
  hoverType?: HoverType;
  inline?: boolean;
  /**
   * If true, the wrapper will inherit the display property from the parent.
   */
  inherit?: boolean;
  /**
   * Most components don't need to know hoverType. It is not passed down by default unless this boolean is true.
   * This prevents components spreading the props throwing an error if the underlying HTML component doesn't recognize the prop.
   */
  forwardHoverType?: boolean;
}

const Wrapper = styled.div<Partial<WithHoverProps>>(({ inherit }) => {
  return {
    lineHeight: 'unset !important', // TODO: bootstrap remove on global style reset
    // TODO: This wrapper caused a bug on the arrow container. This div with display: block (default) caused it to not be centered.
    //       Proper would be to add a display: flex or display: inherit to the wrapper.
    ...(inherit ? { display: 'inherit' } : {}),
  };
});

const withHover =
  <P extends WithHoverProps>(
    Component: React.ComponentType<P>
  ): FunctionComponent<
    Omit<P, 'isHovering' | 'hoverType'> & {
      wrapperStyle?: EmotionStyle;
      removeWrapper?: boolean;
      onMouseEnter?: React.MouseEventHandler;
      onTouchStart?: React.MouseEventHandler;
      onMouseLeave?: React.MouseEventHandler;
      onTouchEnd?: React.MouseEventHandler;
      onTouchCancel?: React.MouseEventHandler;
      onHoverChange?: (hovered: boolean) => void;
      wrapperClassName?: string;
    }
  > =>
  (props) => {
    const {
      wrapperStyle,
      removeWrapper,
      onMouseEnter,
      onTouchStart,
      onMouseLeave,
      onTouchEnd,
      onTouchCancel,
      onHoverChange,
      wrapperClassName,
      inline,
      inherit,
      forwardHoverType,
      ...otherProps
    } = props;
    const [isHovering, setIsHovering] = useState(false);
    const [hoverType, setHoverType] = useState<HoverType>('touch');

    useEffect(() => {
      onHoverChange?.(isHovering);
    }, [isHovering, onHoverChange]);

    const handleMouseEnter = useCallback(
      (e) => {
        onMouseEnter?.(e);
        setHoverType('mouse');
        setIsHovering(true);
      },
      [onMouseEnter]
    );
    const handleTouchStart = useCallback(
      (e) => {
        onTouchStart?.(e);
        setHoverType('touch');
        setIsHovering(true);
      },
      [onTouchStart]
    );
    const handleMouseLeave = useCallback(
      (e) => {
        onMouseLeave?.(e);
        setHoverType('mouse');
        setIsHovering(false);
      },
      [onMouseLeave]
    );
    const handleTouchEnd = useCallback(
      (e) => {
        onTouchEnd?.(e);
        setHoverType('touch');
        setIsHovering(false);
      },
      [onTouchEnd]
    );
    const handleTouchCancel = useCallback(
      (e) => {
        onTouchCancel?.(e);
        setHoverType('touch');
        setIsHovering(false);
      },
      [onTouchCancel]
    );

    if (removeWrapper)
      return (
        <Component
          onMouseEnter={handleMouseEnter}
          onTouchStart={handleTouchStart}
          onMouseLeave={handleMouseLeave}
          onTouchEnd={handleTouchEnd}
          onTouchCancel={handleTouchCancel}
          {...(otherProps as P)}
          isHovering={isHovering}
          {...(forwardHoverType ? { hoverType } : {})}
        />
      );
    return (
      <Wrapper
        onMouseEnter={handleMouseEnter}
        onTouchStart={handleTouchStart}
        onMouseLeave={handleMouseLeave}
        onTouchEnd={handleTouchEnd}
        onTouchCancel={handleTouchCancel}
        style={inline ? { display: 'inline-block', ...wrapperStyle } : wrapperStyle}
        className={wrapperClassName}
        inherit={inherit}
      >
        <Component
          {...(otherProps as P)}
          isHovering={isHovering}
          {...(forwardHoverType ? { hoverType } : {})}
        />
      </Wrapper>
    );
  };

export default withHover;
