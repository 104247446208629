import { FunctionComponent, useRef, useEffect, useCallback } from 'react';
import styled, { EmotionStyle } from '../../core/styled';
import { webOnlyStyle } from '../../core/styleHelpers';

export interface TabRadioGroupProps {
  legendText?: string;
  /** If set to false, the first option will be set to tabbable with tabindex = 0 */
  initialSelection?: boolean;
  isTabList?: boolean;
  idSelector?: string;
  style?: EmotionStyle;
  dataQa?: string;
}

const TabListComponent = styled.div();

// sets radio buttons to render vertically by default. if horizontal arrangement is desired specify in style prop with flex-direction: row
const FieldSetComponent = styled.fieldset({
  border: 'none',
  display: 'flex',
  flexDirection: 'column',
});

const Legend = styled.legend({
  position: 'absolute',
  overflow: 'hidden',
  height: 1,
  width: 1,
  margin: -1,
  padding: 0,
  border: 0,
  display: 'flex',
  ...webOnlyStyle({ clip: 'rect(0 0 0 0)' }),
});

// TabRadioGroup should wrap groups of radio buttons and tabs
// legendText is invisible informative text to give context to vision impaired screen reader users when a fieldset and thus radio buttons are present
// Arrow keys navigate between the options and arrow key focus is trapped
const TabRadioGroup: FunctionComponent<TabRadioGroupProps> = ({
  legendText,
  initialSelection,
  isTabList,
  idSelector,
  style,
  dataQa,
  children,
}) => {
  const fieldSetRef = useRef<HTMLFieldSetElement>(null);
  const tabListRef = useRef<HTMLDivElement>(null);

  // the first option should be tabbable if there is no default selection
  const setFirstTab = useCallback((ref) => {
    if (ref && ref.current) {
      const firstOption = ref.current.querySelector('[role="radio"],[role="tab"]');
      if (firstOption) {
        firstOption.setAttribute('tabindex', '0');
      }
    }
  }, []);

  useEffect(() => {
    let currentRef;
    let eventHandler;
    if (fieldSetRef.current) currentRef = fieldSetRef.current;
    if (tabListRef.current) currentRef = tabListRef.current;
    if (currentRef) {
      const buttonGroup = idSelector
        ? Array.from(currentRef.querySelectorAll(`#${idSelector}`))
        : Array.from(currentRef.querySelectorAll('[role="radio"],[role="tab"]'));
      // allows for arrow keys to navigate between the options and traps arrow key focus
      eventHandler = (e: KeyboardEvent) => {
        const index =
          document.activeElement instanceof HTMLElement
            ? buttonGroup.indexOf(document.activeElement)
            : 0;
        let nextIndex = 0;
        if (e.key === 'ArrowUp' || e.key === 'ArrowLeft') {
          e.preventDefault();
          e.stopPropagation();
          nextIndex = index > 0 ? index - 1 : buttonGroup.length - 1;
          (buttonGroup[nextIndex] as HTMLElement)?.focus?.();
        } else if (e.key === 'ArrowDown' || e.key === 'ArrowRight') {
          e.preventDefault();
          e.stopPropagation();
          nextIndex = index + 1 < buttonGroup.length ? index + 1 : 0;
          (buttonGroup[nextIndex] as HTMLElement)?.focus?.();
        }
      };
      currentRef.addEventListener('keydown', eventHandler);
    }
    return () => {
      if ((fieldSetRef || tabListRef) && currentRef && eventHandler)
        currentRef.removeEventListener('keydown', eventHandler);
    };
  });

  useEffect(() => {
    if (fieldSetRef && fieldSetRef.current) {
      if (!initialSelection) {
        setFirstTab(fieldSetRef);
      }
    } else if (tabListRef && tabListRef.current) {
      if (!initialSelection) {
        setFirstTab(tabListRef);
      }
    }
    // Added children to reset the firstTab when the children radio buttons change
  }, [initialSelection, setFirstTab, children]);

  return isTabList ? (
    <TabListComponent data-qa={dataQa} ref={tabListRef} role="tablist" style={style}>
      {children}
    </TabListComponent>
  ) : (
    <FieldSetComponent data-qa={dataQa} ref={fieldSetRef} style={style}>
      <Legend>{legendText}</Legend>
      {children}
    </FieldSetComponent>
  );
};

export default TabRadioGroup;
