import UserHeader from './UserHeader';
import UserFooter from './UserFooter';
import './UserActionCard.css';

const UserActionCard = ({
  preferredName,
  buttons,
  content,
  isUpdating = false,
  isError = false,
  showSpinner = false,
  spinnerMessage = '',
  status = '',
  nextStatus = '',
  header = null,
}) => (
  <div className="user-action-card">
    <div className="inner">
      {header ? (
        header()
      ) : (
        <UserHeader className="userHeader" preferredName={preferredName} isHorizontal />
      )}
      <div className="content">{content}</div>
      <UserFooter
        isUpdating={isUpdating}
        isError={isError}
        showSpinner={showSpinner}
        status={status}
        nextStatus={nextStatus}
        spinnerMessage={spinnerMessage}
        buttons={buttons}
      />
    </div>
  </div>
);

export default UserActionCard;
