import { withDSIconMaker } from './utils/IconMaker';

const hugePath =
  'M16 3.75C19.2489 3.75 22.3647 5.04062 24.6621 7.33794C26.9594 9.63526 28.25 12.7511 28.25 16C28.25 19.2489 26.9594 22.3647 24.6621 24.6621C22.3647 26.9594 19.2489 28.25 16 28.25C12.7511 28.25 9.63526 26.9594 7.33794 24.6621C5.04062 22.3647 3.75 19.2489 3.75 16C3.75 12.7511 5.04062 9.63526 7.33794 7.33794C9.63526 5.04062 12.7511 3.75 16 3.75ZM16 30C19.713 30 23.274 28.525 25.8995 25.8995C28.525 23.274 30 19.713 30 16C30 12.287 28.525 8.72601 25.8995 6.10051C23.274 3.475 19.713 2 16 2C12.287 2 8.72601 3.475 6.10051 6.10051C3.475 8.72601 2 12.287 2 16C2 19.713 3.475 23.274 6.10051 25.8995C8.72601 28.525 12.287 30 16 30ZM13.6211 10.0664C13.2164 9.82031 12.7078 9.80937 12.2922 10.0391C11.8766 10.2688 11.6195 10.7117 11.6195 11.182V20.8125C11.6195 21.2883 11.8766 21.7258 12.2922 21.9555C12.7078 22.1852 13.2109 22.1797 13.6211 21.9281L21.4961 17.1156C21.8844 16.875 22.125 16.4539 22.125 15.9945C22.125 15.5352 21.8844 15.1141 21.4961 14.8734L13.6211 10.0609V10.0664Z';
const pathConfig = {
  standard: {
    default: {
      path: '',
    },
  },
  huge: {
    default: {
      path: hugePath,
    },
  },
};

const CirclePlay = withDSIconMaker(pathConfig, 'Circle Play');

export default CirclePlay;
