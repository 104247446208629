import { VFC } from 'react';
import Svg, { Circle, Path, Rect, Defs, LinearGradient, Ellipse, Stop } from 'svgs';
import { useUniqueID } from '../../hooks/a11yHelper';

interface SparklingOkHandProps {
  width?: number;
  height?: number;
}

const SparklingOkayHand: VFC<SparklingOkHandProps> = ({
  width = 240,
  height = 287,
  ...otherProps
}) => {
  const titleText = 'Sparkling Ok Hand';
  const sparklingOkayHandId = useUniqueID('sparklingOkayHand');

  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 240 287"
      fill="none"
      {...otherProps}
    >
      <Circle cx="120" cy="148" r="120" fill="url(#paint0_linear_992_17257)" />
      <Path
        d="M70.3554 190.06C65.3239 208.814 59.0755 233.797 52 257.5L122 269.5C123.572 254.913 125.858 238.509 127.745 229.132C130.104 217.41 130.89 204.907 138.751 186.934C146.613 168.961 161.55 158.803 170.198 154.114C178.846 149.425 182.776 143.955 185.135 138.485C187.493 133.015 187.493 128.327 187.493 124.42C187.493 120.512 188.28 113.479 184.349 112.698C180.418 111.917 178.06 118.168 175.701 123.638L175.652 123.752C173.256 129.311 166.951 143.939 152.116 141.611C137.179 139.267 133.248 114.261 142.682 93.1622C152.116 72.0634 164.695 75.1891 172.556 79.8778C180.418 84.5664 177.273 90.8178 182.777 92.3807C187.179 93.631 187.231 89.255 186.707 86.9107C185.135 71.282 177.273 58.779 158.405 58.779C143.311 58.779 133.248 75.4496 130.104 83.7849C130.104 55.6533 143.468 36.1174 149.758 27.5216C154.789 20.645 156.5 11 153 9.54859L150.544 8.76716C136.078 15.6438 120.932 47.0575 115.167 61.9047C115.167 50.9647 123.814 16.5815 123.028 9.54859C122.399 3.92226 120.67 3.55759 119.884 4.07855L115.953 9.54859C106.519 25.8024 100.492 52.788 98.6572 64.2491C95.5126 47.8389 93.9402 40.0244 89.2233 30.6472C85.4497 23.1454 82.934 26.4796 82.1478 29.0844C93.9402 107.228 76.6447 166.617 70.3554 190.06Z"
        fill="#C46F31"
      />
      <Path
        d="M81.5004 27C82.3007 31.0001 82.9764 34.8535 83.5005 38.5002C87.4313 38.5002 87.6514 34.3833 86.8652 29.6946C86.2363 25.9438 81.0003 24.5001 81.5004 27Z"
        fill="#FFC6A7"
      />
      <Path
        d="M119.883 12.5029C117.367 16.8789 114.118 16.41 112.808 15.6286L115.5 9.5L119.5 3.5C120.83 2.50011 123.028 7.03281 119.883 12.5029Z"
        fill="#FFC6A7"
      />
      <Path
        d="M142.5 14.8471L150 7.99976C151.525 6.0927 155.26 10.9399 151.33 14.8471C147.399 18.7543 145.826 20.3171 143.468 18.7543C141.581 17.504 141.976 15.6285 142.5 14.8471Z"
        fill="#FFC6A7"
      />
      <Path
        d="M182.776 115.652C182.776 111.276 185.5 111.5 187.493 113C187.493 117.5 188.017 122.946 187.493 125.03C183.563 124.248 182.776 121.122 182.776 115.652Z"
        fill="#FFC6A7"
      />
      <Path
        d="M179.703 87.8265C179.023 83.2602 183.385 81.0314 185.65 80.4878C187.689 85.0541 187.8 91.7578 185.65 93C183.3 93 180.383 92.3928 179.703 87.8265Z"
        fill="#FFC6A7"
      />
      <Path
        d="M68.9729 187.046L73.3465 177.398L138.398 189.586L136.256 199.545L68.9729 187.046Z"
        fill="#CF005F"
      />
      <Rect
        x="103.57"
        y="173.705"
        width="6.2893"
        height="6.25148"
        transform="rotate(7.90546 103.57 173.705)"
        fill="#FFC200"
      />
      <Ellipse
        cx="102.978"
        cy="189.402"
        rx="11.0063"
        ry="10.9401"
        transform="rotate(7.90546 102.978 189.402)"
        fill="#F4F6FA"
      />
      <Path
        d="M49.4527 197.835C50.5009 200.961 51.1823 213.308 45.5219 237.689C42.3266 251.452 36 264.5 36 264.5C42.5 276.5 92.4275 293.609 134.465 282.5C130.558 245.286 146.298 236.419 152.44 229.093C160.302 219.716 158.729 204.868 146.151 204.868C133.572 204.868 132 216.59 121.78 215.808C111.559 215.027 112.346 204.868 102.126 204.087C91.9054 203.305 86.4023 216.59 73.0376 216.59C62.3458 216.59 52.8594 204.087 49.4527 197.835Z"
        fill="url(#paint1_linear_992_17257)"
      />
      <Ellipse cx="61.1446" cy="235.126" rx="3.14465" ry="3.12575" fill="#FF0075" />
      <Ellipse cx="101.339" cy="226.749" rx="3.14465" ry="3.12575" fill="#FF0075" />
      <Ellipse cx="143.792" cy="220.497" rx="3.14465" ry="3.12574" fill="#FF0075" />
      <Ellipse cx="120.207" cy="256.443" rx="3.14465" ry="3.12575" fill="#FF0075" />
      <Ellipse cx="91.1446" cy="269.126" rx="3.14465" ry="3.12574" fill="#FF0075" />
      <Ellipse cx="55.1446" cy="263.126" rx="3.14465" ry="3.12575" fill="#FF0075" />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M98.824 64.1554C99.4164 64.1963 99.8635 64.7096 99.8227 65.302L98.3891 86.0891C98.3482 86.6815 97.8349 87.1286 97.2425 87.0877C96.6501 87.0469 96.2029 86.5335 96.2438 85.9411L97.6774 65.1541C97.7182 64.5617 98.2316 64.1146 98.824 64.1554Z"
        fill="#A95519"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M115.497 62.0348C116.073 62.1788 116.423 62.7626 116.279 63.3387C115.343 67.0838 113.593 77.1997 114.159 87.3893C114.192 87.9822 113.738 88.4895 113.145 88.5224C112.552 88.5554 112.045 88.1014 112.012 87.5085C111.431 77.0544 113.218 66.7178 114.193 62.8171C114.337 62.241 114.921 61.8908 115.497 62.0348Z"
        fill="#A95519"
      />
      <Path
        d="M31 138.316C43.5632 134.807 47.7148 123.977 48.2202 119C53.1588 137.961 58.7978 142.213 61 141.97C48.6101 144.893 45.4404 157.875 45.4043 164C43.1516 147.295 34.8628 139.916 31 138.316Z"
        fill="#BCCCFF"
      />
      <Path
        d="M187 52.8053C200.392 46.731 203.74 32.4041 203.74 26C204.595 43.9469 210.269 51.3481 213 52.8053C203.787 57.131 200.772 71.4041 200.416 78C198.421 59.3168 190.641 53.4189 187 52.8053Z"
        fill="#BCCCFF"
      />
      <Defs>
        <LinearGradient
          id={sparklingOkayHandId}
          x1="240"
          y1="148"
          x2="-4.9537e-06"
          y2="148"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#E3E8FB" />
          <Stop offset="1" stopColor="#E4F2F5" />
        </LinearGradient>
        <linearGradient
          id={sparklingOkayHandId}
          x1="96.6226"
          y1="197.835"
          x2="96.6226"
          y2="286.093"
          gradientUnits="userSpaceOnUse"
        >
          <Stop offset="0.460938" stopColor="#F5A623" />
          <Stop offset="1" stopColor="#C46F31" />
        </linearGradient>
      </Defs>
    </Svg>
  );
};

export default SparklingOkayHand;
