import { FunctionComponent } from 'react';
import Svg, { G, Circle, Path } from 'svgs';
import { EmotionStyle } from '../../core/styled';

interface RxCircleProp {
  color?: string;
  width?: number;
  style?: EmotionStyle;
}

const RxCircleTwo: FunctionComponent<RxCircleProp> = ({ width = 22, color, style }) => {
  const titleText = 'Rx icon';
  return (
    <Svg width={width} viewBox="0 0 22 22" title={titleText} aria-label={titleText} style={style}>
      <G fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <G transform="translate(-118 -640)">
          <G transform="translate(104 620)">
            <G transform="translate(14 20)">
              <Circle cx="11" cy="11" r="11" fill={color || '#D46953'} />
              <Path
                fill="#FFF"
                fillRule="nonzero"
                d="M9.668 6c.735 0 1.316.172 1.744.515.59.47.885 1.073.885 1.81 0 .738-.295 1.34-.885 1.81a2.19 2.19 0 01-.92.44l-.132.025 1.842 2.063 1.124-1.373a.194.194 0 01.15-.072h.868a.324.324 0 01.243.539l-1.59 1.799 1.804 2.085a.324.324 0 01-.252.536l-.82-.02a.194.194 0 01-.144-.07l-1.383-1.64-1.385 1.641a.194.194 0 01-.144.07l-.816.019a.324.324 0 01-.252-.537l1.756-2.022-2.527-2.968h-.551v2.589a.635.635 0 01-.642.628.637.637 0 01-.638-.568L7 13.24V6.93a.94.94 0 01.88-.928L7.95 6h1.718zm-.065 1.194h-1.32v2.262h1.32c.496 0 .856-.109 1.078-.326.222-.218.333-.486.333-.805 0-.318-.11-.586-.333-.804-.204-.2-.523-.308-.956-.325l-.122-.002z"
              />
            </G>
          </G>
        </G>
      </G>
    </Svg>
  );
};

export default RxCircleTwo;
