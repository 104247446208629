import * as React from 'react';
import {
  BaseButton,
  Standard,
  View,
  usePanelManagerState,
  usePanelManagerActions,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import styled, { Theme } from '@/core/styled';
import ChatIcon from '../../Icons/ChatIcon';
import ChartIcon from '../../Icons/ChartIcon';

const CRMChatSwitchContainer = styled(View)<{ isInRoom: boolean }>(
  ({
    isInRoom,
    theme: {
      window: { isMobile },
    },
  }) => {
    if (!isInRoom || !isMobile)
      return {
        display: 'none',
      };
    return {
      flex: 1,
      height: 42,
      maxHeight: 42,
      alignItems: 'center',
      justifyContent: 'center',
    };
  }
);

const CRMChatButtonsContainer = styled(View)<{ isInRoom: boolean }>(
  ({
    isInRoom,
    theme: {
      window: { isMobile, isSmall },
    },
  }) => {
    if (!isInRoom || !isMobile)
      return {
        display: 'none',
      };
    if (isSmall) {
      return {
        width: 355,
        height: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      };
    }
    return {
      width: 355,
      height: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    };
  }
);

const CRMChatButtonSpacing = styled(View)(
  ({
    theme: {
      window: { isSmall },
    },
  }) => {
    if (!isSmall) return { display: 'none' };
    return {
      width: 8,
    };
  }
);

const CRMChatButton = styled(BaseButton)({
  width: 120,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'center',
});

const getIsInRoom = (location = window.location) => {
  const { pathname } = location;
  return pathname.includes('room/');
};

export const getNavBarHeight = (isMobile: boolean) => {
  if (getIsInRoom() && isMobile) return 107;
  return 67;
};

const CRMChatText: React.FC<{ isActive: boolean }> = ({ isActive, children }) => (
  <Standard variant={isActive ? 'standardBoldGreen' : 'standardBoldGrey'} style={{ marginLeft: 5 }}>
    {children}
  </Standard>
);

const CRMChatSwitcher = () => {
  const isInRoom = getIsInRoom();
  const { setIsLeftPanelOnTop } = usePanelManagerActions();
  const { isLeftPanelOnTop } = usePanelManagerState();
  const isChartActive = isLeftPanelOnTop;
  const isChatActive = !isLeftPanelOnTop;
  const {
    window: { isMobile },
  } = useEmotionTheme<Theme>();
  return (
    <CRMChatSwitchContainer isInRoom={isInRoom}>
      <CRMChatButtonsContainer isInRoom={isInRoom}>
        {isInRoom && isMobile && (
          <>
            <CRMChatButton onPress={() => setIsLeftPanelOnTop(true)}>
              <ChartIcon isActive={isChartActive} />
              <CRMChatText isActive={isChartActive}>Chart</CRMChatText>
            </CRMChatButton>
            <CRMChatButtonSpacing />
            <CRMChatButton onPress={() => setIsLeftPanelOnTop(false)}>
              <ChatIcon isActive={isChatActive} />
              <CRMChatText isActive={isChatActive}>Chat</CRMChatText>
            </CRMChatButton>
          </>
        )}
      </CRMChatButtonsContainer>
    </CRMChatSwitchContainer>
  );
};

export default CRMChatSwitcher;
