const shuffleArray = <T>(array: T[]): T[] => {
  let currentIndex = array.length;
  let randomIndex;
  const returnArray = array;

  // While there remain elements to shuffle
  while (currentIndex !== 0) {
    // Pick a remaining element
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element
    [returnArray[currentIndex], returnArray[randomIndex]] = [
      returnArray[randomIndex],
      returnArray[currentIndex],
    ];
  }
  return returnArray;
};

export default shuffleArray;
