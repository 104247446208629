import { FunctionComponent } from 'react';
import { Trans } from '@talkspace/i18n';

import styled, { EmotionStyle } from '../../core/styled';
import View from '../View';
import { Standard, Tiny } from '../Typography';
import {
  MagnifyingGlassTherapists,
  ArrowHeadRight,
  UpwardTrend,
  GlobeWithLocationPointer,
  DidYouKnowShield,
} from '../icons';

export type StepTipName =
  | 'findTheRightMatch'
  | 'therapyEffectiveness'
  | 'decreaseInSymptons'
  | 'switchExplanation'
  | 'noPsychOutsideUS'
  | 'noteControlledSubstances'
  | 'didYouKnow'
  | 'custom';

export interface StepTipConfig {
  image?: JSX.Element; // an enum of a supported icons component
  title?: string | JSX.Element;
  content: string | JSX.Element;
}

const Title: FunctionComponent<{ style?: EmotionStyle }> = ({ style, children }) => (
  <Standard variant="standardBoldDarkGreen" style={{ marginBottom: 5, ...style }}>
    {children}
  </Standard>
);

const BlueTitle = styled(Title)(({ theme: { colors } }) => {
  return {
    color: colors.permaDarkRoyalBlue,
    marginBottom: 5,
  };
});

interface StepTipProps {
  tip: StepTipName;
  style?: EmotionStyle;
  tipImageStyle?: EmotionStyle;
  tipTextContainerStyle?: EmotionStyle;
  customImage?: StepTipConfig['image'];
  customTitle?: StepTipConfig['title'];
  customContent?: StepTipConfig['content'];
}

const StepTip = ({
  tip,
  style,
  tipImageStyle,
  tipTextContainerStyle,
  customImage,
  customTitle,
  customContent,
}: StepTipProps) => {
  const isCustomTip = tip === 'custom';

  let image;
  let title;
  let content;

  if (isCustomTip) {
    if (!customContent) return null;

    image = customImage;
    title = customTitle;
    content = customContent;
  }

  switch (tip) {
    case 'findTheRightMatch':
      image = <MagnifyingGlassTherapists width={100} height={100} />;
      title = (
        <BlueTitle>
          <Trans i18nKey="react-toolkit:StepTip.findTheRightMatchTitle">
            Finding the right match...
          </Trans>
        </BlueTitle>
      );
      content = (
        <Tiny>
          <Trans i18nKey="react-toolkit:StepTip.findTheRightMatchContent">
            We’ll match you with a dedicated provider who will meet your specific needs. Feeling
            comfortable and confident right from the beginning is important.
          </Trans>
        </Tiny>
      );
      break;
    case 'therapyEffectiveness':
      image = <ArrowHeadRight width={100} height={100} />;
      title = 'Did you know...';
      content =
        '80% of participants find Talkspace to be as effective or more effective than traditional therapy.';
      break;
    case 'decreaseInSymptons':
      image = <UpwardTrend width={100} height={100} />;
      title = 'Studies show...';
      content =
        'Talkspace conducted a study with Columbia University, participants experienced a 50% decrease in symptoms of anxiety over the course of 3 months.';
      break;
    case 'switchExplanation':
      image = <MagnifyingGlassTherapists width={80} height={80} />;
      title = <Title>What if I’m not happy with my provider?</Title>;
      content =
        'You can switch providers any time at the tap of a button! It’s common to try a few providers before finding the right fit.';
      break;
    case 'noPsychOutsideUS':
      image = <GlobeWithLocationPointer width={80} height={80} />;
      title = (
        <Title>
          <Trans i18nKey="react-toolkit:StepTip.noPsychOutsideUSTitle">Outside the US?</Trans>
        </Title>
      );
      content = (
        <Tiny>
          <Trans i18nKey="react-toolkit:StepTip.noPsychOutsideUSContent">
            We do not offer psychiatry outside of the US at this time.
          </Trans>
        </Tiny>
      );
      break;
    case 'noteControlledSubstances':
      content = (
        <Tiny>
          <strong>Note:</strong> Talkspace prescribers are unable to prescribe controlled substances
          and stimulants. These include but are not limited to: Adderall, Ativan, Concerta,
          Klonopin, Librium, Tranxene, Ritalin, Vyvanse, Valium, Xanax.
        </Tiny>
      );
      break;
    case 'didYouKnow':
      image = <DidYouKnowShield width={90} height={90} />;
      title = 'Did you know...';
      content = '86 million people have Talkspace covered by their insurance';
      break;
    default:
      break;
  }

  return (
    <View
      align="start"
      style={{
        flexDirection: 'row',
        alignItems: image ? 'center' : 'flex-start',
        ...style,
      }}
    >
      {image && <View style={tipImageStyle}>{image}</View>}

      <View
        style={{
          flex: 1,
          textAlign: image ? 'left' : undefined,
          marginLeft: image ? 12 : undefined,
          marginBottom: image ? 4 : undefined,
          ...tipTextContainerStyle,
        }}
      >
        {title && typeof title === 'string' ? <BlueTitle>{title}</BlueTitle> : title}
        {typeof content === 'string' ? <Tiny>{content}</Tiny> : content}
      </View>
    </View>
  );
};

export default StepTip;
