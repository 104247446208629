const SignificantProgressIcon = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle r="14.4" transform="matrix(-1 0 0 1 18 18)" stroke="#EFF3FA" strokeWidth="7.2" />
    <mask
      id="mask_significant_progress"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="36"
      height="36"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M36 0H17.6949L18 18H0V36H36V0Z" fill="white" />
    </mask>
    <g mask="url(#mask_significant_progress)">
      <circle r="14.4" transform="matrix(-1 0 0 1 18 18)" stroke="#09B198" strokeWidth="7.2" />
    </g>
  </svg>
);

export default SignificantProgressIcon;
