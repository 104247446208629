import { useEffect, useRef, useState } from 'react';
import AgoraRTC, { ICameraVideoTrack } from 'agora-rtc-sdk-ng';

import { VirtualBackground } from '../types/videoCallTypes';

interface IVirtualBackgroundExtension {
  checkCompatibility: () => boolean;
  createProcessor: () => IVirtualBackgroundProcessor;
}

interface VirtualBackgroundEffectOptions {
  type: string;
  color?: string;
  source?: HTMLImageElement | HTMLVideoElement;
  blurDegree?: Number;
  fit?: 'contain' | 'cover' | 'fill';
}

interface EventEmitter {
  on(event: string, listener: Function): void;
  off(event: string, listener: Function): void;
}

interface IBaseProcessor extends EventEmitter {
  readonly name: string;
  readonly ID: string;
  kind: 'video' | 'audio';
  enabled: boolean;
  pipe(processor: IBaseProcessor): IBaseProcessor;
  unpipe(): void;
  disable(): void | Promise<void>;
  enable(): void | Promise<void>;
  updateInput(inputOptions: { track?: MediaStreamTrack; node?: AudioNode; context: any }): void;
  reset(): void;
}

interface IVirtualBackgroundProcessor extends IBaseProcessor {
  init(wasmDir?: string): Promise<void>;
  release(): Promise<void>;
  setOptions(options: VirtualBackgroundEffectOptions): void;
  onoverload?: () => void;
  getProcessedTrack(): Promise<MediaStreamTrack | null>;
}

type NonEmptyVirtualBackground = Exclude<VirtualBackground, ''>;

const loadImage = (src: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.src = src;
    image.onload = () => resolve(image);
    image.onerror = (error) => reject(error);
  });

export default function useVirtualBackground(videoTrack: ICameraVideoTrack | undefined) {
  const extension = useRef<IVirtualBackgroundExtension | null>(null);
  const processor = useRef<IVirtualBackgroundProcessor | null>(null);
  const [isSupported, setIsSupported] = useState(true);

  useEffect(() => {
    const initExtension = async () => {
      try {
        const { default: VirtualBackgroundExtension } = await import(
          'agora-extension-virtual-background'
        );

        extension.current = new VirtualBackgroundExtension();

        if (!extension.current.checkCompatibility()) {
          setIsSupported(false);
          return;
        }

        AgoraRTC.registerExtensions([extension.current]);
        processor.current = extension.current.createProcessor();
        await processor.current?.init();
      } catch (e) {
        setIsSupported(false);
      }
    };

    initExtension();
  }, []);

  const enableBackground = async (virtualBackground: NonEmptyVirtualBackground): Promise<void> => {
    if (!processor.current || !videoTrack) {
      return;
    }

    const backgroundOption: VirtualBackgroundEffectOptions = { type: 'blur', blurDegree: 1 };

    if (virtualBackground === 'branded') {
      backgroundOption.type = 'img';
      backgroundOption.source = await loadImage('/images/branded-background.png');
    }

    videoTrack.pipe(processor.current).pipe(videoTrack.processorDestination);
    processor.current.setOptions(backgroundOption);
    await processor.current.enable();
  };

  const disableBackground = async (): Promise<void> => {
    if (!processor.current || !videoTrack) {
      return;
    }

    videoTrack.unpipe();
    await processor.current.disable();
  };

  return {
    isSupported,
    enableBackground,
    disableBackground,
  };
}
