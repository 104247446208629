import { useEffect } from 'react';
import useMutationSaveDraftCollateralNote, {
  CollateralNoteMutateVariables,
} from 'hooks/notes/useMutationSaveDraftCollateralNote';
import useLocationStateOnMount from 'hooks/useLocationStateOnMount';
import { useHistory, useParams } from '@/core/routerLib';
import { getSubmenuTitleAndSubtitle } from './utils';
import NoteDialog from './NoteDialog';

const SaveCollateralNoteDialog = () => {
  const history = useHistory();
  const { roomID } = useParams<{ roomID: string }>();
  const locationStateOnMount = useLocationStateOnMount<CollateralNoteMutateVariables>();

  const { mutate: saveDraftCollateralNote, isLoading: isSaveDraftLoading } =
    useMutationSaveDraftCollateralNote();

  useEffect(() => {
    if (locationStateOnMount === null) {
      history.push(`/room/${roomID}/notes-tab`);
    }
  }, [locationStateOnMount, history, roomID]);

  const handleYesPress = () => {
    if (locationStateOnMount) {
      saveDraftCollateralNote(
        { ...locationStateOnMount },
        {
          onSuccess: () => {
            history.push(`/room/${roomID}/notes-tab`);
          },
        }
      );
    }
  };

  const { title, subtitle } = getSubmenuTitleAndSubtitle({
    noteType: 'collateral',
    formMode: 'create',
  });

  return (
    <NoteDialog
      dialogType="save-draft"
      title={title}
      subtitle={subtitle}
      isLoading={isSaveDraftLoading}
      noButtonFirst
      onYesPress={handleYesPress}
    />
  );
};

export default SaveCollateralNoteDialog;
