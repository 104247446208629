import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const smallPath =
  'M5.21924 7.47036C4.92627 7.76333 4.92627 8.23911 5.21924 8.53208L9.71924 13.0321C10.0122 13.325 10.488 13.325 10.781 13.0321C11.0739 12.7391 11.0739 12.2633 10.781 11.9704L6.81064 8.00005L10.7786 4.02974C11.0716 3.73677 11.0716 3.26099 10.7786 2.96802C10.4856 2.67505 10.0099 2.67505 9.71689 2.96802L5.21689 7.46802L5.21924 7.47036Z';
const standardPath =
  'M5.2937 9.69014C4.90308 10.0808 4.90308 10.7151 5.2937 11.1058L11.2937 17.1058C11.6843 17.4964 12.3187 17.4964 12.7093 17.1058C13.1 16.7151 13.1 16.0808 12.7093 15.6901L7.41558 10.3964L12.7062 5.10264C13.0968 4.71201 13.0968 4.07764 12.7062 3.68701C12.3156 3.29639 11.6812 3.29639 11.2906 3.68701L5.29058 9.68701L5.2937 9.69014Z';
const majorPath =
  'M6.76807 12.0027C6.33284 12.4379 6.33284 13.1417 6.76807 13.5723L15.6577 22.4666C16.0929 22.9018 16.7967 22.9018 17.2273 22.4666C17.6579 22.0313 17.6625 21.3276 17.2273 20.897L9.12475 12.7898L17.2319 4.68723C17.6672 4.25201 17.6672 3.54824 17.2319 3.11765C16.7967 2.68706 16.093 2.68243 15.6624 3.11765L6.76807 12.0027Z';

const pathConfig: IconConfig = {
  small: {
    default: { path: smallPath },
  },
  standard: {
    default: { path: standardPath },
  },
  major: {
    default: { path: majorPath },
  },
};

const ChevronLeft = withDSIconMaker(pathConfig, 'chevron left');

export default ChevronLeft;
