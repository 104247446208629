import { styled, View, useWindowWidthState } from '@talkspace/react-toolkit';

import ResponseTimeMainMetric from './ResponseTimeMainMetric';
import RetentionMainMetric from './RetentionMainMetric';
import ProgressReportsMainMetric from './ProgressReportsMainMetric';
import B2BSessionsMainMetric from './B2BSessionsMainMetric';
import RatingMainMetric from './RatingMainMetric';
import ThreeDotDropDownMenu from './ThreeDotDropDownMenu';
import { MetricsRecord, ReviewMetricsRecord } from '../types';

const bootstrapLgCol = {
  marginLeft: '8.33333333%',
  width: '83.33333333%',
};

const BOOTSTRAP_LG_BREAKPOINT = 1200;

interface WidthProps {
  isDesktop?: boolean;
  isLarge?: boolean;
  isMobile?: boolean;
}

const getWrapperPadding = ({ isDesktop, isLarge }: WidthProps) => {
  if (isDesktop) return 35;
  if (isLarge) return 20;
  return 10;
};

const Container = styled(View)<{ wrapperPadding: number }>(
  ({ theme: { colors }, wrapperPadding }) => {
    const innerPadding = 15;
    return {
      padding: `16px ${innerPadding + wrapperPadding}px`,
      borderBottom: `0.5px solid ${colors.heatherGrey}`,
    };
  }
);

const CenterWrapper = styled(View)(({ isLarge }: WidthProps) =>
  isLarge ? bootstrapLgCol : { width: '100%' }
);

interface Props {
  metrics: MetricsRecord;
  reviewMetrics: ReviewMetricsRecord;
}
const MainMetricsPanel = ({ metrics, reviewMetrics }: Props) => {
  const { responseTime, retention, progressReportsAvg, b2BSessions } = metrics;
  const { isMobile, isDesktop, width } = useWindowWidthState();
  const isLarge = width > BOOTSTRAP_LG_BREAKPOINT;
  const wrapperPadding = getWrapperPadding({ isDesktop, isLarge });
  return (
    <Container row wrapperPadding={wrapperPadding}>
      <CenterWrapper row isLarge={isLarge}>
        <View row>
          <RatingMainMetric metric={reviewMetrics} />
          <ResponseTimeMainMetric metric={responseTime} />
          <RetentionMainMetric metric={retention} />
          {!isMobile && (
            <>
              <ProgressReportsMainMetric metric={progressReportsAvg} />
              <B2BSessionsMainMetric metric={b2BSessions} />
            </>
          )}
        </View>
        <ThreeDotDropDownMenu />
      </CenterWrapper>
    </Container>
  );
};

export default MainMetricsPanel;
