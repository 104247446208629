import { View } from '@talkspace/react-toolkit';
import styled from '@/core/styled';

const RowColumnToggle = styled(View)<{ reverse?: boolean }>(({ theme: { window }, reverse }) => {
  const { isMobile } = window;
  const column = reverse ? 'column-reverse' : 'column';
  return {
    flexDirection: isMobile ? column : 'row',
  };
});

export default RowColumnToggle;
