import Svg, { Path, Circle, Stop, Defs, LinearGradient } from 'svgs';
import { FunctionComponent } from 'react';
import { useUniqueID } from '../../hooks/a11yHelper';

interface InGoodHandsProps {
  color?: string;
  iconColor?: string;
  width?: number;
  height?: number;
}

const InGoodHands: FunctionComponent<InGoodHandsProps> = ({
  width = 247,
  height = 298,
  ...otherProps
}) => {
  const titleText = 'In Good Hands';
  const gradientID = useUniqueID('paint0_linear_2253_48502');
  const gradientID1 = useUniqueID('paint1_linear_2253_48502');
  const gradientID2 = useUniqueID('paint2_linear_2253_48502');

  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 247 298"
      fill="none"
      {...otherProps}
    >
      <Circle cx="127" cy="168" r="120" fill={`url(#${gradientID})`} />
      <Path
        d="M85.7933 240.138C58.7035 249 40.6437 237.56 35 230.732C40.8173 222.629 60.6766 192.637 70.9742 177.086L71.1615 176.803C81.4037 161.335 85.7933 155.273 101.47 148.794C117.147 142.314 137.423 132.699 145.366 129.145C153.309 125.592 157.698 112.423 160.834 106.988C163.969 101.554 166.895 98.8364 170.24 98.4183C172.915 98.0839 174.281 98.9757 174.629 99.4634C176.719 95.0739 179.437 84.2045 184.662 82.3233C188.843 80.8184 191.978 81.6963 193.023 82.3233C195.741 73.7533 198.458 71.454 203.475 71.0359C208.424 70.6235 207.88 73.8733 207.664 75.1649L207.655 75.2164C207.446 76.4706 205.983 85.2497 205.565 88.385C205.231 90.8934 203.196 103.923 202.221 110.124C203.057 108.033 206.192 101.888 212.045 94.0287C219.361 84.2045 223.541 83.1594 226.886 84.2045C230.23 85.2496 228.349 89.0121 226.259 92.9836C224.586 96.1608 216.086 120.227 212.045 131.862C219.152 129.981 224.586 130.19 229.185 131.444C233.784 132.699 232.32 136.879 229.603 138.342C226.886 139.805 220.406 147.748 216.434 152.138C212.463 156.527 208.909 162.171 204.729 164.679C200.548 167.188 190.724 172.413 180.691 179.938C170.658 187.463 160.415 191.435 151.636 192.271C142.857 193.107 127.389 197.706 112.34 208.157C100.3 216.518 89.6254 232.961 85.7933 240.138Z"
        fill="#FF856A"
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M173.743 99.2337C174.197 99.3216 174.493 99.7603 174.405 100.214C173.062 107.142 167.387 123.631 155.327 134.333L155.107 134.528L154.814 134.543C153.452 134.611 151.361 134.826 149.523 135.244C148.601 135.454 147.778 135.707 147.151 136.001C146.488 136.312 146.207 136.595 146.127 136.771C145.936 137.191 145.44 137.377 145.02 137.186C144.599 136.995 144.413 136.499 144.604 136.079C144.942 135.335 145.697 134.836 146.441 134.487C147.221 134.121 148.174 133.836 149.152 133.614C150.98 133.198 153.011 132.975 154.433 132.889C165.97 122.515 171.467 106.581 172.763 99.8954C172.851 99.4421 173.29 99.1458 173.743 99.2337Z"
        fill="#DC6046"
      />
      <Path
        d="M219.361 140.606C218.859 142.445 220.684 144.995 221.66 146.04C224.586 143.393 230.732 137.888 231.902 137.052C233.365 136.007 234.41 134.544 233.156 133.499C231.902 132.454 230.439 132.245 228.558 132.872C226.676 133.499 219.988 138.306 219.361 140.606Z"
        fill="#FFC6A7"
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M212.97 131.186C213.208 131.581 213.081 132.095 212.685 132.333C207.947 135.19 196.742 141.833 189.865 145.523C189.165 145.898 188.481 146.272 187.806 146.64C184.388 148.502 181.218 150.23 177.611 151.432C173.256 152.884 168.278 153.571 161.384 152.935C154.598 152.308 144.671 151.53 134.497 152.179C124.305 152.829 113.975 154.906 106.317 159.909C105.93 160.161 105.412 160.053 105.16 159.666C104.907 159.28 105.016 158.761 105.402 158.509C113.421 153.27 124.098 151.166 134.391 150.51C144.702 149.852 154.737 150.642 161.537 151.269C168.229 151.887 172.972 151.216 177.082 149.846C180.536 148.694 183.556 147.05 186.971 145.189C187.654 144.817 188.353 144.437 189.075 144.049C195.91 140.381 207.084 133.758 211.822 130.901C212.217 130.663 212.731 130.79 212.97 131.186Z"
        fill="#DC6046"
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M202.074 110.031C202.452 110.297 202.543 110.818 202.277 111.196C197.817 117.539 187.708 132.321 182.881 140.81L182.633 141.245L182.133 141.232C179.453 141.165 172.792 141.971 167.811 145.667C167.441 145.942 166.917 145.864 166.642 145.493C166.367 145.122 166.444 144.599 166.815 144.324C171.911 140.542 178.522 139.588 181.671 139.557C186.63 130.932 196.525 116.468 200.91 110.234C201.175 109.856 201.697 109.765 202.074 110.031Z"
        fill="#DC6046"
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M192.83 82.1091C193.275 82.2331 193.535 82.6942 193.411 83.139C192.397 86.7745 190.657 92.5905 188.73 98.2345C186.812 103.852 184.679 109.386 182.871 112.399C179.374 118.227 172.376 131.374 169.308 137.231L169.044 137.735L168.478 137.674C166.688 137.483 161.625 137.788 156.436 140.707C156.033 140.934 155.523 140.791 155.297 140.388C155.071 139.986 155.213 139.476 155.616 139.25C160.683 136.399 165.668 135.862 168.081 135.97C171.264 129.906 178.005 117.259 181.437 111.539C183.141 108.699 185.223 103.329 187.147 97.6941C189.063 92.0855 190.793 86.3006 191.8 82.6899C191.924 82.2451 192.385 81.985 192.83 82.1091Z"
        fill="#DC6046"
      />
      <Path
        d="M86.4829 200.337C72.4052 190.818 68.4763 182.95 68.2716 180.207C66.7227 177.823 54.8111 194.343 49.0489 202.901C48.486 201.796 46.2801 199.932 41.9589 201.32C37.6378 202.708 32.7604 211.674 30.8618 215.984C30.4095 213.577 27.569 205.783 18.0413 214.858C10.4191 222.118 7.13546 231.565 6.44642 235.381C30.6382 266.028 68.176 286.119 83.9209 292.333C86.327 289.78 91.7338 282.828 94.1122 275.448C96.4906 268.067 92.7983 265.146 90.6548 264.607C95.81 260.617 104.02 249.017 102.045 241.341C100.465 235.201 94.8743 236.416 92.2764 237.791L111.336 211.864C113.316 209.17 113.234 208.418 112.945 208.379C109.99 209.664 100.561 209.856 86.4829 200.337Z"
        fill={`url(#${gradientID1})`}
      />
      <Path
        d="M112.579 27.0425C120.765 30.3007 125.524 39.2901 126.881 43.3775C131.455 31.7066 138.233 27.9476 147.665 27.0107C157.098 26.0738 171.124 34.0409 169.784 48.349C168.712 59.7956 155.487 78.3871 149.008 86.252L123.322 117.266C117.725 111.554 103.712 96.6904 92.4328 82.9322C78.3341 65.7345 76.8957 50.7178 83.5155 36.9579C90.1354 23.198 102.346 22.9697 112.579 27.0425Z"
        fill={`url(#${gradientID2})`}
      />
      <Path
        d="M47 23.4563C58.8254 18.1835 61.1865 5.62175 60.8889 0C62.9524 17.0591 69.1561 23.1978 72 24.1348C62 25.6856 58.7063 36.0244 58.3095 41C57.119 25.0265 50.2738 22.6485 47 23.4563Z"
        fill="#BCCCFF"
      />
      <Path
        d="M184 44.4563C195.825 39.1835 198.187 26.6217 197.889 21C199.952 38.0591 206.156 44.1978 209 45.1348C199 46.6856 195.706 57.0244 195.31 62C194.119 46.0265 187.274 43.6485 184 44.4563Z"
        fill="#BCCCFF"
      />
      <Defs>
        <LinearGradient
          id={gradientID}
          x1="247"
          y1="168"
          x2="7"
          y2="168"
          gradientUnits="userSpaceOnUse"
        >
          <Stop stop-color="#E3E8FB" />
          <Stop offset="1" stop-color="#E4F2F5" />
        </LinearGradient>
        <LinearGradient
          id={gradientID1}
          x1="94.1833"
          y1="194.563"
          x2="40.5349"
          y2="267.542"
          gradientUnits="userSpaceOnUse"
        >
          <Stop offset="0.536458" stop-color="#0FC1A7" />
          <Stop offset="1" stop-color="#009B8E" />
        </LinearGradient>
        <LinearGradient
          id={gradientID2}
          x1="139.758"
          y1="19.5579"
          x2="96.4724"
          y2="103.405"
          gradientUnits="userSpaceOnUse"
        >
          <Stop offset="0.479167" stop-color="#FF0075" />
          <Stop offset="1" stop-color="#CF005F" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
};

export default InGoodHands;
