import { FunctionComponent, useRef, useEffect } from 'react';
import { AnimatedSpinner } from '@talkspace/react-toolkit';
import { PropsOf } from '@emotion/styled-base/types/helper';
import { webOnlyStyle } from '@/core/styled/styleHelpers';
import styled, { EmotionStyle } from '@/core/styled';

const Video = styled.video({
  maxWidth: 480,
  maxHeight: 480,
  ...webOnlyStyle({
    '@media(max-width: 1125px)': {
      maxWidth: 340,
      maxHeight: 340,
    },
    '@media(max-width: 750px)': {
      maxWidth: 315,
      maxHeight: 315,
    },
  }),
});

export interface VideoPlayerViewProps extends PropsOf<typeof Video> {
  source?: string;
  playing: boolean;
  style?: EmotionStyle;
  captionsUrl?: string;
  thumbnailURL?: string;
  loading?: boolean;
  primaryColor?: string;
  videoMimeType?: string;
  previewFrame?: number;
  initialHeight?: number;
  initialWidth?: number;
}

const VideoPlayerView: FunctionComponent<VideoPlayerViewProps> = ({
  style,
  onPlay,
  source,
  onPause,
  playing,
  loading,
  preload,
  captionsUrl,
  thumbnailURL,
  primaryColor,
  videoMimeType = 'video/mp4',
  previewFrame = !thumbnailURL ? 0.1 : undefined,
  initialHeight = 270,
  initialWidth = 200,
  ...otherProps
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    if (videoRef.current) {
      if (playing) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  }, [playing]);

  if (loading) {
    return (
      <AnimatedSpinner
        width={35}
        height={30}
        style={{ minWidth: initialWidth, minHeight: initialHeight }}
      />
    );
  }

  const videoSource = previewFrame ? undefined : source;
  const preloadProp = preload || (previewFrame && 'metadata') || undefined;

  return (
    <Video
      aria-label="video message"
      controls
      src={videoSource}
      ref={videoRef}
      preload={preloadProp}
      onPlay={(e) => {
        // Note: We need the play here to prevent the "Permission denied" error
        // on browsers that prevent programmatic media playing
        // even though it's already on a useEffect
        if (videoRef.current) videoRef.current.play();
        if (onPlay) onPlay(e);
      }}
      onPause={(e) => {
        if (videoRef.current) videoRef.current.pause();
        if (onPause) onPause(e);
      }}
      poster={thumbnailURL}
      style={{
        ...style,
      }}
      {...otherProps}
    >
      {captionsUrl && <track kind="captions" src={captionsUrl} />}
      {!!previewFrame && <source src={`${source}#t=${previewFrame}`} type={videoMimeType} />}
    </Video>
  );
};

export default VideoPlayerView;
