import { useEmotionTheme, useWindowWidthState, View } from '@talkspace/react-toolkit';
import { AvailabilityPreferencesItem } from './types';

interface PreferredTimesProps {
  isMessagingOnly?: boolean;
  availability: AvailabilityPreferencesItem[];
  allGray?: boolean;
}

const PreferredTimesTextContent = ({ text, color }: { text: string; color: string }) => {
  const { isMobile } = useWindowWidthState();
  return (
    <View style={{ minWidth: isMobile ? 150 : 216 }}>
      <View row>
        <View style={{ color }}>{text}</View>
      </View>
    </View>
  );
};

const PreferredTimes = ({
  isMessagingOnly = false,
  availability,
  allGray = false,
}: PreferredTimesProps) => {
  const { colors } = useEmotionTheme();
  const { isMobile } = useWindowWidthState();

  if (isMessagingOnly) {
    return (
      <PreferredTimesTextContent text="Not applicable for messaging" color={colors.softGray} />
    );
  }
  if (availability.length === 0) {
    return (
      <PreferredTimesTextContent
        text="Client didn’t provide availability"
        color={colors.softGray}
      />
    );
  }
  if (availability.length === 7 && availability.every((it) => it.alwaysAvailable)) {
    return (
      <PreferredTimesTextContent
        text="Client is available anytime"
        color={allGray ? colors.softGray : colors.TSBlack}
      />
    );
  }
  return (
    <View
      style={{ minWidth: isMobile ? 150 : 216, color: allGray ? colors.softGray : colors.TSBlack }}
    >
      {availability.map((item) => (
        <View key={item.dayOfWeek} row>
          <View style={{ marginRight: 4, width: 30 }}>{item.dayOfWeek.slice(0, 3)}</View>
          {item.availability.map((range, i) => (
            <View key={range.startTime} style={{ marginRight: 3 }}>{`${range.startTime} - ${
              range.endTime
            }${i + 1 < item.availability.length ? ',' : ''}`}</View>
          ))}
        </View>
      ))}
    </View>
  );
};

export default PreferredTimes;
