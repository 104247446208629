import { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '../Buttons/Button';
import './Dialog.css';
import PinkAlert from '../../Icons/PinkAlert';

const tsDialog = ({ title, text, buttons }) => {
  const buttonItems = buttons.map((i, index) => (
    <Button key={index} title={i.title} clickHandler={i.action} isSecondary={false} />
  ));
  return (
    <div className="ts-component-dialog-container">
      <span className="ts-component-dialog-title">
        <PinkAlert style={{ marginRight: 6 }} />
        {title}
      </span>
      <div className="ts-component-dialog-text">{text}</div>
      <div className="ts-component-dialog-button-container">{buttonItems}</div>
    </div>
  );
};

tsDialog.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  buttons: PropTypes.shape,
};

export default tsDialog;
