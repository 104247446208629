import Svg, { Path } from 'svgs';
import { EmotionStyle, useEmotionTheme } from '../../core/styled';

interface Props {
  width?: number;
  height?: number;
  color?: string;
  style?: EmotionStyle;
}
const RightArrowIcon = ({ width = 13, height = 13, color, ...otherProps }: Props) => {
  const { colors } = useEmotionTheme();
  const titleText = 'right arrow';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 13 13"
      {...otherProps}
    >
      <Path
        d="M959.696 300.564a.897.897 0 01-.69-.263l-5.744-5.766a.9.9 0 011.275-1.27l5.159 5.178 5.158-5.178a.9.9 0 011.275 1.27l-5.744 5.766a.897.897 0 01-.69.263z"
        transform="translate(-955 -290) matrix(0 1 1 0 662.913 -662.913)"
        fill={color || colors.heatherGrey}
        fillRule="nonzero"
        stroke="none"
        strokeWidth={1}
      />
    </Svg>
  );
};

export default RightArrowIcon;
