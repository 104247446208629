import { AlignType, JustifyType } from '@talkspace/react-toolkit';
import styled from '../styled/styled';

interface ViewProps {
  row?: boolean;
  flex?: number;
  justify?: JustifyType;
  align?: AlignType;
}

const prependFlex = (str: JustifyType | AlignType): string =>
  str === 'start' || str === 'end' ? `flex-${str}` : str;

const View = styled.div<ViewProps>(({ flex, row, justify, align }) => {
  return {
    fontFamily: 'Roboto, sans-serif',
    display: 'flex',
    flex: flex || 'none',
    flexDirection: row ? 'row' : 'column',
    justifyContent: justify ? prependFlex(justify) : 'inherit',
    alignItems: align ? prependFlex(align) : 'inherit',
  };
});

export default View;
