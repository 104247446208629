import { SkipNavigation } from '@talkspace/react-toolkit';
import { ID_ROOM_DETAILS_BUTTON, ID_LVS_DASHBOARD_BUTTON } from '@/utils/IDConstants';

export const useA11y = (from: string | undefined) => {
  const cameFromRoomDashBoard = from ? from === 'dashboard' : false;
  const cameFromChat = from ? from === 'chat' : false;

  let skipNavs: SkipNavigation[] | undefined;
  if (cameFromRoomDashBoard) {
    skipNavs = [
      {
        buttonText: 'Return to dashboard',
        returnID: ID_LVS_DASHBOARD_BUTTON,
      },
    ];
  } else if (cameFromChat) {
    skipNavs = [
      {
        buttonText: 'Return to chat room',
        returnID: ID_ROOM_DETAILS_BUTTON,
      },
    ];
  }

  return { skipNavs };
};

export default useA11y;
