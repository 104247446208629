import * as React from 'react';
import * as H from 'history';
import { Text, TouchableView, Standard, FloatingMenuHeader } from '@talkspace/react-toolkit';
import styled, { EmotionStyle } from '@/core/styled/styled';
import { webOnlyStyle } from '@/core/styled/styleHelpers';
import { RouteComponentProps } from '@/core/routerLib/routerLib';
import ScrollViewComponent from '../ScrollViewComponent/ScrollViewComponent';

interface FloatingMenuListProps<T extends {} = {}> extends RouteComponentProps {
  items: T[];
  title: string;
  idKey?: string;
  textKey?: string;
  nextRoute?: string;
  showBackArrow?: boolean;
  RightComponent?: React.FunctionComponent;
  containerStyles?: EmotionStyle;

  renderRow?(item: T): JSX.Element;
  getKey?(item: T): number | string;
  onPress: (item: T, history: H.History) => void;
}

export const FloatingMenuRowWrapper = styled(TouchableView)(({ theme: { colors } }) => {
  return {
    flexShrink: 0,
    lineHeight: 20,
    textAlign: 'left',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    ...webOnlyStyle({
      cursor: 'pointer',
      lineHeight: '20px',
      '&:hover': {
        backgroundColor: colors.green,
      },
    }),
  };
});

export const FloatingMenuRowText = styled(Text)(({ theme: { colors } }) => {
  return {
    padding: 16,
    paddingRight: 0,
    width: '100%',
    ...webOnlyStyle({
      '&:hover': {
        color: colors.white,
      },
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    }),
  };
});

export const FloatingMenuRow = ({ onPress, text }) => (
  <FloatingMenuRowWrapper onPress={onPress}>
    <FloatingMenuRowText>{text}</FloatingMenuRowText>
  </FloatingMenuRowWrapper>
);

const ContainerWrapper = styled(ScrollViewComponent)({
  flex: 1,
  width: 300,
  justifyContent: 'flex-start',
  ...webOnlyStyle({
    overflowX: 'hidden',
  }),
});

const FloatingMenuList = <T extends object>({
  items,
  title,
  getKey,
  onPress,
  history,
  nextRoute,
  renderRow,
  showBackArrow,
  RightComponent,
  containerStyles,
  idKey = 'id',
  textKey = 'text',
}: FloatingMenuListProps<T>) => {
  const onItemPress = (item: T) => {
    if (nextRoute) history.push(nextRoute);
    onPress(item, history);
  };

  return (
    <>
      <FloatingMenuHeader
        title={title}
        showBackArrow={showBackArrow}
        RightComponent={RightComponent}
      />
      <ContainerWrapper style={containerStyles}>
        {items.length === 0 && title === 'Offer Categories' ? (
          <Standard style={{ padding: 16 }}>Offers cannot be sent to b2b clients</Standard>
        ) : (
          items.map((item) => {
            if (renderRow) return renderRow(item);
            return (
              <FloatingMenuRow
                text={item[textKey]}
                onPress={() => onItemPress(item)}
                key={getKey ? getKey(item) : item[idKey]}
              />
            );
          })
        )}
      </ContainerWrapper>
    </>
  );
};

export default FloatingMenuList;
