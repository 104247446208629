import Svg, { Path } from 'svgs';
import { useEmotionTheme, EmotionStyle } from '../../core/styled';

interface Props {
  color?: string;
  width?: number;
  height?: number;
  style?: EmotionStyle;
}

const RotateRightIcon = ({ width = 22, height = 21, color, style }: Props) => {
  const { colorRoles } = useEmotionTheme();
  const titleText = 'clockwise rotation arrow';
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 22 21"
      fill="none"
      style={style}
      title={titleText}
      aria-label={titleText}
    >
      <Path
        d="M19.5118 9.18908H19.8604C20.4059 9.18908 20.8448 8.75021 20.8448 8.2047V2.9547C20.8448 2.55685 20.6069 2.19591 20.2377 2.04415C19.8686 1.8924 19.4461 1.97443 19.1631 2.25743L17.4569 3.96368C13.8639 0.415833 8.07661 0.428138 4.50415 4.0047C0.915283 7.59357 0.915283 13.4096 4.50415 16.9985C8.09302 20.5873 13.909 20.5873 17.4979 16.9985C18.0106 16.4858 18.0106 15.6531 17.4979 15.1404C16.9852 14.6277 16.1526 14.6277 15.6399 15.1404C13.0764 17.7039 8.92153 17.7039 6.35806 15.1404C3.79458 12.577 3.79458 8.42208 6.35806 5.85861C8.90923 3.30744 13.0313 3.29513 15.5989 5.81759L13.9131 7.50743C13.6301 7.79044 13.5481 8.2129 13.6999 8.58204C13.8516 8.95118 14.2125 9.18908 14.6104 9.18908H19.5118Z"
        fill={color || colorRoles.icons.iconSubtlestDefault}
      />
    </Svg>
  );
};

export default RotateRightIcon;
