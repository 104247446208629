import { Route, Switch } from 'react-router-dom';
import BookingActivity from './components/Public/BookingActivity';

export default function PublicRoutes() {
  return (
    <Switch>
      <Route path="/public/booking/:bookingID/room/:roomID/:action" component={BookingActivity} />
      <Route render={() => <div>No path found</div>} />
    </Switch>
  );
}
