import { FunctionComponent } from 'react';
import Svg, { G, Path } from 'svgs';
import { EmotionStyle, useEmotionTheme } from '../../core/styled';

interface AddUserProps {
  color?: string;
  width?: number;
  height?: number;
  style?: EmotionStyle;
}

const AddUser: FunctionComponent<AddUserProps> = ({ color, width = 32, height = 32, style }) => {
  const { colors } = useEmotionTheme();
  const titleText = 'person silhouette add symbol';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 34 20"
      fill={color || colors.lightGray}
      style={style}
    >
      <G fill={colors.green} stroke="none" strokeWidth={1} fillRule="evenodd">
        <Path
          d="M17.038 0l-.007.002L17 0a1 1 0 011 1v1.999L20 3a1 1 0 010 2h-2v2a1 1 0 01-2 0V5h-2a1 1 0 010-2h2V1a1 1 0 011-1l.026.001L17.021 0h.017zM9 14c-1.995 0-3.617-1.615-3.617-3.6S7.005 6.8 9 6.8c1.995 0 3.617 1.615 3.617 3.6S10.995 14 9 14m3.398.606a5.38 5.38 0 001.964-5.043c-.357-2.36-2.33-4.25-4.712-4.525-3.287-.38-6.076 2.166-6.076 5.362 0 1.701.792 3.217 2.028 4.206-3.035 1.135-5.25 3.8-5.598 7.396A.91.91 0 00.908 23c.46 0 .852-.346.893-.802C2.164 18.181 5.254 15.8 9 15.8s6.836 2.381 7.199 6.398c.04.456.433.802.893.802a.91.91 0 00.904-.998c-.347-3.597-2.563-6.261-5.598-7.396"
          transform="translate(-121 -59) translate(127 62)"
        />
      </G>
    </Svg>
  );
};

export default AddUser;
