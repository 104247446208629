import { FunctionComponent } from 'react';
import Svg, { G, Path, Defs } from 'svgs';
import { EmotionStyle, useEmotionTheme } from '../../core/styled';

interface AddPartnerProps {
  color?: string;
  width?: number;
  style?: EmotionStyle;
}

const ChatCircle: FunctionComponent<AddPartnerProps> = ({ color, width = 32, style }) => {
  const { colors } = useEmotionTheme();
  const titleText = 'Imperfect circle with a chat icon';
  return (
    <Svg width={width} title={titleText} aria-label={titleText} style={style} viewBox="0 0 47 47">
      <Defs>
        <Path
          d="M8.301 5.176c10.338-7.453 26-7.06 33.82 2.504 4.236 5.181 5.318 11.693 4.066 18.985-1.104 6.425-4.899 11.8-9.21 15.513-9.409 8.102-23.732 4.551-31.552-5.013-7.82-9.565-7.461-24.536 2.876-31.989z"
          id="a"
        />
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <G transform="translate(-302 -334) translate(302 334)">
          <mask fill="#fff">
            <use xlinkHref="#a" />
          </mask>
          <use fill={color || colors.lightGray} xlinkHref="#a" />
        </G>
        <Path
          d="M8.494 20.367a7.238 7.238 0 01-.28 2.692c-.183.61.02 1.27.516 1.679a1.646 1.646 0 001.77.202c1.62-.804 3.775-2.201 5.084-4.348h1.074c5.694 0 10.31-4.542 10.31-10.145 0-5.602-4.616-10.144-10.31-10.144h-6.002C5.382.303.959 4.22.404 9.38c-.555 5.16 2.937 9.9 8.094 10.987h-.004zm2.158-18.36h6.02c4.738 0 8.578 3.779 8.578 8.44 0 4.662-3.84 8.441-8.578 8.441h-1.58a.868.868 0 00-.765.457c-1.022 1.912-2.899 3.183-4.385 3.963.333-1.237.4-2.529.198-3.793a.86.86 0 00-.731-.715c-4.44-.65-7.62-4.56-7.293-8.964.327-4.406 4.05-7.818 8.54-7.826l-.004-.004z"
          transform="translate(-302 -334) translate(302 334) translate(9.724 10.805)"
          fill="#FFF"
          fillRule="nonzero"
          stroke="#FFF"
          strokeWidth={0.5}
        />
      </G>
    </Svg>
  );
};

export default ChatCircle;
