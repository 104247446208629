import { FunctionComponent } from 'react';
import * as React from 'react';
import { View } from '@talkspace/react-toolkit';
import styled, { EmotionStyle } from '@/core/styled';

interface Props {
  children: React.ReactNode;
  style?: EmotionStyle;
}

const Wrapper = styled(View)((props) => {
  return {
    padding: '20px 16px',
    borderRadius: 15,
    boxShadow: '0 5px 18px rgba(35, 60, 81, 0.1)',
  };
});

const ContentCard: FunctionComponent<Props> = ({ children, style }) => (
  <Wrapper style={style}>{children}</Wrapper>
);

export default ContentCard;
