import { useMutation, useQueryClient } from 'react-query';
import apiHelper from '@/utils/api';
import apiWrapper from '../../utils/apiWrapper';
import { DischargeNoteFormState } from '../../components/Room/CRMContainer/NotesTab/types';
import { dischargeNoteQueryKey, notesListQueryKey } from './queryKeys';

interface UpdateDischargeNoteMutateVariables extends DischargeNoteFormState {
  roomID: string;
  clientUserID?: number;
  noteID?: string;
}

const updateDischargeNote = async (params: UpdateDischargeNoteMutateVariables): Promise<void> => {
  const { roomID, noteID, clientUserID, ...requestBody } = params;
  await apiWrapper.patch(
    `${apiHelper().apiEndpoint}/v2/rooms/${roomID}/discharge-notes/${noteID}`,
    requestBody
  );
};

const useMutationUpdateDischargeNote = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, UpdateDischargeNoteMutateVariables>(updateDischargeNote, {
    onSuccess: (_, { roomID, noteID, clientUserID }) => {
      queryClient.invalidateQueries(notesListQueryKey(roomID));
      if (noteID) {
        queryClient.invalidateQueries(dischargeNoteQueryKey(clientUserID, noteID));
      }
    },
  });
};

export default useMutationUpdateDischargeNote;
