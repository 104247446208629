import { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { Select, WindowWidthStateContext } from '@talkspace/react-toolkit';
import {
  startOutcomeMeasures,
  getOutcomeMeasureOptions,
} from '../../../../../actions/OutcomeMeasureActions';
import './OutcomeMeasures.css';
import Submenu from '../../../../Reusable/Submenu/Submenu';
import Footer from '../../../../Reusable/Footer/Footer';
import Button from '../../../../Reusable/Buttons/Button';
import { getUserData } from '../../../../../utils/token';

class OutcomeMeasureStart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      outcomeMeasure: null,
      formError: false,
      menuPlacement: 'auto',
    };
    this.outcomeMeasureRef = createRef();

    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.handleStartScheduling = this.handleStartScheduling.bind(this);
  }

  componentDidMount() {
    this.props.getOutcomeMeasureOptions();
  }

  getContent() {
    const showIndicatorBeforeClick = this.context.isMobile;
    const options = this.props.outcomeMeasureOptions.map(({ id, label }) => {
      return {
        value: id,
        label,
      };
    });
    return (
      <div ref={this.outcomeMeasureRef} style={{ padding: 20 }}>
        <div>
          Outcome measures help therapists continually improve the quality of client care by serving
          as a clinically proven tool to better assess and plan treatment.
          <br />
          <br />
          They are standardized instruments developed to monitor the effectiveness of treatment,
          gathering information for both the client and clinician throughout treatment, indicating
          if progress is being made with regard to their identified mental health condition.
          <br />
          <br />
          Start the assessment schedule for your client in the room by choosing the correct
          assessment. This should reflect their current mental health condition, noted in the Mental
          Condition Field. The first assessment will drop in the room soon after you schedule and
          any subsequent one will arrive every 21 days. The client will have 7 days to answer the
          survey. Results will be posted to you after the client completes each assessment.
          <br />
          <br />
        </div>
        <Select
          value={this.state.outcomeMeasure}
          options={options}
          placeholder="Assessment name"
          onChange={this.handleDropdownChange}
          isValid={!this.state.formError}
          showIndicatorBeforeClick={showIndicatorBeforeClick}
          menuPlacement={this.state.menuPlacement}
          onFocus={() => {
            this.updateMenuPlacement();
          }}
        />
      </div>
    );
  }

  updateMenuPlacement() {
    const optionDefaultHeight = 50;
    const windowHeight = window.innerHeight;
    const footerHeight = 80;
    const bottomPadding =
      (this.props.outcomeMeasureOptions.length || 1) * optionDefaultHeight + footerHeight; // if there are no options its the same padding as for one option

    const { bottom: outcomeMeasureBottom } =
      this.outcomeMeasureRef?.current?.getBoundingClientRect() || {
        bottom: 0,
      };
    this.setState({
      menuPlacement: outcomeMeasureBottom > windowHeight - bottomPadding ? 'top' : 'auto',
    });
  }

  handleDropdownChange(value) {
    this.setState({ outcomeMeasure: value, formError: false });
  }

  handleStartScheduling() {
    if (!this.state.outcomeMeasure) {
      this.setState({ formError: true });
      return;
    }
    const { userID, conditionID, match, history } = this.props;
    const userData = getUserData();
    const surveyID = this.state.outcomeMeasure.value;
    const startData = {
      userID,
      roomID: Number(match.params.roomID),
      surveyID,
      therapistID: userData.id,
      conditionID,
    };
    this.setState({ showSpinner: true });
    this.props.startOutcomeMeasures(startData).then((res) => {
      setTimeout(history.goBack, 1000);
    });
  }

  renderFooter() {
    const { isUpdating, isError, spinnerMessage } = this.props;
    return (
      <Footer
        showSpinner={this.state.showSpinner}
        isUpdating={isUpdating}
        isError={isError}
        spinnerMessage={spinnerMessage}
        childComponents={[
          <Button
            title="Cancel"
            isSecondary
            key={1}
            clickHandler={() => this.props.history.goBack()}
          />,
          <Button title="Start scheduling" key={2} clickHandler={this.handleStartScheduling} />,
        ]}
      />
    );
  }

  render() {
    return (
      <Submenu
        childComponents={[this.getContent()]}
        title="Start assessment scheduling"
        key={2}
        footerComponent={this.renderFooter()}
        prevRoute="clinical-tab"
      />
    );
  }
}

OutcomeMeasureStart.contextType = WindowWidthStateContext;
const mapStateToProps = (state) => {
  return {
    outcomeMeasureOptions: state.outcomeMeasures.outcomeMeasureOptions,
    userID: state.customerInformation.clientUserID,
    conditionID:
      state.clinicalInformation.conditions[0] &&
      state.clinicalInformation.conditions[0].conditionID,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      startOutcomeMeasures,
      getOutcomeMeasureOptions,
    },
    dispatch
  );
const OutcomeMeasureStartContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(OutcomeMeasureStart);

export default withRouter(OutcomeMeasureStartContainer);
