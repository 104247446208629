export const ID_TAB_PANEL = 'ID_TAB_PANEL';
export const ID_JOURNEY_BUTTON = 'ID_JOURNEY_BUTTON';
export const ID_JOURNEY_PANEL = 'ID_JOURNEY_PANEL';
export const ID_CHAT_PANEL = 'ID_CHAT_PANEL';
export const ID_CHAT_INPUT = 'ID_CHAT_INPUT';
export const ID_CHAT_PROVIDER_DETAILS_BUTTON = 'ID_CHAT_PROVIDER_DETAILS_BUTTON';
export const ID_PROVIDER_DETAILS_PANEL = 'ID_PROVIDER_DETAILS_PANEL';
export const ID_ROOM_DETAILS_BUTTON = 'ID_ROOM_DETAILS_BUTTON';
export const ID_ROOM_DETAILS_PANEL = 'ID_ROOM_DETAILS_PANEL';
export const ID_CUSTOMER_SUPPORT_PANEL = 'ID_CUSTOMER_SUPPORT_PANEL';
export const ID_PAYMENT_CARD_WRAPPER = 'ID_PAYMENT_CARD_WRAPPER';
export const ID_SENT_BY_THERAPIST = 'ID_SENT_BY_THERAPIST';
export const ID_SENT_BY_USER = 'ID_SENT_BY_USER';
export const ID_STARRED_MESSAGE = 'ID_STARRED_MESSAGE';
export const ID_OLDEST_LOADED_MESSAGE = 'ID_OLDEST_LOADED_MESSAGE';
export const ID_NEWEST_MESSAGE = 'ID_NEWEST_MESSAGE';
export const ID_MESSAGE_DATE = 'ID_MESSAGE_DATE';
export const ID_BOLD_MESSAGE_TEXT = 'ID_BOLD_MESSAGE_TEXT';
export const ID_MESSAGE_TEXT = 'ID_MESSAGE_TEXT';
export const ID_BOTTOM_BOLD_MESSAGE_TEXT = 'ID_BOTTOM_BOLD_MESSAGE_TEXT';
export const ID_ACCOUNT_MENU_DROPDOWN_BUTTON = 'ID_ACCOUNT_MENU_DROPDOWN_BUTTON';
export const ID_ACCOUNT_MENU_DROPDOWN = 'ID_ACCOUNT_MENU_DROPDOWN';
export const ID_LVS_PANEL = 'ID_LVS_PANEL';
export const ID_CLINICAL_PROGRESS_PANEL = 'ID_CLINICAL_PROGRESS_PANEL';
export const ID_ACCOUNT_DETAILS_PANEL = 'ID_ACCOUNT_DETAILS_PANEL';
export const ID_MANAGE_SUBSCRIPTION_PANEL = 'ID_MANAGE_SUBSCRIPTION_PANEL';
export const ID_NOTIFICATIONS_PANEL = 'ID_NOTIFICATIONS_PANEL';
export const ID_STARRED_MESSAGES_PANEL = 'ID_STARRED_MESSAGES_PANEL';
export const ID_SHARED_FILES_PANEL = 'ID_SHARED_FILES_PANEL';
export const arrowKeyNavigationClass = 'arrow-nav';

// Used on client-web only for inRoomScheduling
export const ID_MAIN_PANEL = 'ID_MAIN_PANEL';
export const ID_LVS_DASHBOARD_BUTTON = 'ID_LVS_DASHBOARD_BUTTON';
