import React, { useEffect, useState } from 'react';
import {
  Standard,
  TouchableView,
  View,
  CheckV2,
  useEmotionTheme,
  Small,
  ThumbsUp,
  ThumbsDown,
  Tiny,
  FloatingMenu,
  EmotionStyle,
  CloseButton,
  Checkbox,
  TextAreaRounded,
  useWindowWidth,
  AnimatedSwipeDownModal,
} from '@talkspace/react-toolkit';
import styled from '@/core/styled';
import useMutationRateSmartNote, {
  RateNoteVariables,
} from '../../../../../../../hooks/notes/useMutationRateSmartNote';
import { useProgressNoteFormState } from '../../context/TherapistProgressNoteFormContext';
import { useLocation, useRouteMatch } from '@/core/routerLib';
import { Spinner } from './SmartNote';
import trackEvent from '@/analytics/trackEvent';

const REMOVE_LABEL_TIMEOUT = 3000;

const Label = styled(Tiny)(({ theme: { colors } }) => {
  return {
    fontSize: 12,
    fontWeight: 400,
    color: colors.purple600,
  };
});

const SecondaryButton = styled(TouchableView)(({ theme: { colors } }) => {
  return {
    fontSize: 15,
    fontWeight: 600,
    color: colors.accessibilityGreenDark,
    marginRight: 20,
  };
});

const PrimaryButton = styled(TouchableView)<{ disabled?: boolean }>(
  ({
    theme: {
      colors,
      window: { isMobile },
    },
    disabled,
  }) => {
    return {
      fontSize: isMobile ? 16 : 14,
      fontWeight: 700,
      color: colors.white,
      textAlign: 'center',
      width: isMobile ? '100%' : 135,
      height: isMobile ? 48 : 30,
      background: disabled ? colors.paleBluGray : colors.accessibilityGreenDark,
      borderRadius: 7,
      lineHeight: isMobile ? '48px' : '30px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    };
  }
);

type Feedback = 'positive' | 'negative' | null;

const getFloatingMenuStyle = (): EmotionStyle => {
  return {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: 'fit-content',
    maxHeight: 'unset',
    zIndex: 20,
    borderRadius: 10,
    width: 407,
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  };
};

const SmartNoteRating = () => {
  const match = useRouteMatch<{ roomID: string; noteID?: string }>();
  const location = useLocation<{}>();
  const { mutate: rateSmartNote, isLoading } = useMutationRateSmartNote();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { colors } = useEmotionTheme();
  const { isMobile } = useWindowWidth();
  const [hideRating, setHideRating] = useState<boolean | null>(false);
  const [formState, setFormState] = useState<RateNoteVariables>({
    feedback: null,
    inaccurateSummary: false,
    nonFormattedSummary: false,
    tooManyEdits: false,
    negativeFeedbackDescription: undefined,
  });
  const unsubmittedSessionID = new URLSearchParams(location.search).get('id');

  useEffect(() => {
    if (hideRating) {
      setTimeout(() => {
        setHideRating(null);
      }, REMOVE_LABEL_TIMEOUT);
    }
  }, [hideRating]);

  const {
    feedback,
    inaccurateSummary,
    nonFormattedSummary,
    tooManyEdits,
    negativeFeedbackDescription,
  } = formState;

  const { generatedSessionSummaryID } = useProgressNoteFormState();

  const handleFormState = (fieldName: string, value: boolean | string) => {
    setFormState({
      ...formState,
      [fieldName]: value,
    });
  };

  const handleRateSmartNote = (newFeedback: Feedback) => {
    if (generatedSessionSummaryID) {
      rateSmartNote({
        roomID: match.params.roomID,
        generatedSessionSummaryID,
        feedback: newFeedback,
      });
    }
  };

  const handleSubmitFeedback = () => {
    if (generatedSessionSummaryID) {
      rateSmartNote(
        {
          roomID: match.params.roomID,
          generatedSessionSummaryID,
          ...formState,
        },
        {
          onSuccess: () => {
            setIsModalOpen(false);
            setHideRating(true);
            trackEvent(
              'submitNegativeFeedback',
              {
                actionName: `progressNotesInteraction`,
              },
              {
                action: `User clicks "Submit negative feedback CTA" on feedback modal`,
                roomID: match.params.roomID,
                unsubmittedSessionID,
                ...formState,
              }
            );
          },
        }
      );
    }
  };

  const handleDismissFeedback = () => {
    setIsModalOpen(false);
    trackEvent(
      'dismissNegativeFeedback',
      {
        actionName: `progressNotesInteraction`,
      },
      {
        action: `Clicks "dismiss" CTA on feedback modal`,
        roomID: match.params.roomID,
        unsubmittedSessionID,
        ...formState,
      }
    );
  };

  const handleThumbsUp = () => {
    const newFeedback = feedback === 'positive' ? null : 'positive';
    setFormState({ feedback: newFeedback });
    handleRateSmartNote(newFeedback);
    trackEvent(
      'rateSmartNote',
      {
        actionName: `progressNotesInteraction`,
      },
      {
        action: `Clicks thumbs up on smart note`,
        feedback: newFeedback,
        roomID: match.params.roomID,
        unsubmittedSessionID,
      }
    );
  };

  const handleThumbsDown = () => {
    const newFeedback = feedback === 'negative' ? null : 'negative';
    setFormState({ feedback: newFeedback });
    handleRateSmartNote(newFeedback);
    setIsModalOpen(newFeedback === 'negative');
    trackEvent(
      'rateSmartNote',
      {
        actionName: `progressNotesInteraction`,
      },
      {
        action: `Clicks thumbs down on smart note`,
        feedback: newFeedback,
        roomID: match.params.roomID,
        unsubmittedSessionID,
      }
    );
  };

  const checkboxes = [
    {
      id: 'inaccurateSummary',
      label: 'Smart Note was inaccurate',
      stateKey: 'inaccurateSummary',
      isChecked: !!inaccurateSummary,
    },
    {
      id: 'nonFormattedSummary',
      label: 'Smart Note did not follow SOAP format',
      stateKey: 'nonFormattedSummary',
      isChecked: !!nonFormattedSummary,
    },
    {
      id: 'tooManyEdits',
      label: 'Smart Note required too many edits',
      stateKey: 'tooManyEdits',
      isChecked: !!tooManyEdits,
    },
  ];

  const getContent = () => (
    <>
      <View row align="center" justify="space-between" style={{ width: '100%', marginBottom: 24 }}>
        <Standard>Provide additional feedback</Standard>
        {!isMobile && <CloseButton onPress={() => setIsModalOpen(false)} />}
      </View>

      <View style={{ marginBottom: 24 }}>
        {checkboxes.map((checkbox) => (
          <Checkbox
            labelStyle={{ fontWeight: 400 }}
            key={checkbox.id}
            stretch={false}
            isLabelOnRight
            label={checkbox.label}
            isChecked={checkbox.isChecked}
            setIsChecked={(bool) => handleFormState(checkbox.stateKey, bool)}
            checkComponent={<CheckV2 />}
          />
        ))}
      </View>

      <View style={{ marginBottom: 24 }}>
        <Small style={{ color: colors.gunPowder, marginBottom: 5 }}>
          What was the issue with the Smart Note? Please describe it without including PII or PHI.
        </Small>
        <TextAreaRounded
          value={negativeFeedbackDescription}
          onChangeText={(value) => handleFormState('negativeFeedbackDescription', value)}
        />
      </View>

      <View row={!isMobile} justify="end" style={{ width: '100%' }}>
        <View row={!isMobile} align="center">
          {!isMobile && <SecondaryButton onPress={handleDismissFeedback}>Dismiss</SecondaryButton>}
          <PrimaryButton disabled={isLoading} onPress={handleSubmitFeedback}>
            {isLoading ? <Spinner /> : 'Submit feedback'}
          </PrimaryButton>
        </View>
      </View>
    </>
  );

  if (hideRating || hideRating === null) {
    return (
      <Label style={{ opacity: hideRating === null && 0 }}>Thank you for your feedback!</Label>
    );
  }

  return (
    <>
      <View row align="center" style={{ gap: 8 }}>
        <Label>Rate this Smart Note</Label>
        <TouchableView onPress={handleThumbsUp}>
          <ThumbsUp isFilled={feedback === 'positive'} />
        </TouchableView>
        <TouchableView onPress={handleThumbsDown}>
          <ThumbsDown isFilled={feedback === 'negative'} />
        </TouchableView>
      </View>

      {isMobile ? (
        <AnimatedSwipeDownModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          closeModalText="dismiss"
          closeTextStyle={{ marginTop: 24 }}
        >
          {getContent()}
        </AnimatedSwipeDownModal>
      ) : (
        <FloatingMenu
          style={getFloatingMenuStyle()}
          hide={!isModalOpen}
          onBackdropPress={() => setIsModalOpen(false)}
          overlayStyle={{ background: 'rgba(104, 118, 141, 0.5)', zIndex: 10 }}
        >
          {getContent()}
        </FloatingMenu>
      )}
    </>
  );
};

export default SmartNoteRating;
