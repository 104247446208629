import { useRef, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import TextareaAutosize from 'react-textarea-autosize';
import commonStyles, { COLORS } from '../../constants/commonStyles';
import Lock from '../Svgs/Lock';
import ErrorIcon from '../Svgs/ErrorIcon';
import './textInputStyles.css';

const { regular } = commonStyles.general.text;

const TextInputWrapper = styled.div(({ placeholderUp, hasFocus }) => {
  return {
    transition: 'margin-top .2s',
    marginTop: placeholderUp ? 14 : 6,
    position: 'relative',
    width: '100%',
    boxSizing: 'content-box',
    height: hasFocus ? 'auto' : 51,
    overflowY: hasFocus ? 'auto' : 'hidden',
    overflowX: 'hidden',
  };
});

const upStyles = {
  top: 0,
  fontSize: 12,
  color: COLORS.placeholderGrey,
};

const Placeholder = styled.div(({ placeholderUp }) => {
  return {
    ...regular,
    position: 'absolute',
    transition: 'all .25s',
    top: 24,
    color: COLORS.placeholderGrey,
    pointerEvents: 'none',
    ...(placeholderUp ? upStyles : {}),
  };
});

const textareaStyles = {
  ...regular,
  resize: 'none',
  outline: 'none',
  width: '100%',
  borderWidth: 0,
  minHeight: 26,
  borderBottomWidth: 1,
  borderColor: COLORS.extraLightGrey,
  cursor: 'pointer',
  'webkit-text-fill-color': COLORS.black,
  boxShadow: 'none',
  outlineColor: 'transparent',
  outlineStyle: 'none',
  backgroundColor: 'transparent',
  padding: '3px 3px 0px 0px',
  WebkitAppearance: 'none',
  WebkitTapHighlightColor: 'rgba(0,0,0,0)',
  marginTop: 20,
};

const textInputStyles = {
  ...regular,
  overflowX: 'hidden',
  width: '100%',
  borderWidth: 0,
  height: 33,
  borderBottomWidth: 1,
  borderColor: COLORS.extraLightGrey,
  cursor: 'pointer',
  'webkit-text-fill-color': COLORS.black,
  padding: '0px 3px 0px 0px',
  boxShadow: 'none',
  marginTop: 15,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

const LockIconWrapper = styled.div({
  position: 'absolute',
  right: 4,
  top: 23,
  display: 'none',
});

function MultilineTextInputView(props) {
  const {
    placeholderUp,
    placeholder,
    disabled,
    hasFocus,
    isValid,
    hidden,
    onFocus,
    onBlur,
    placeHolderStyle,
    style = {},
    ...otherProps
  } = props;
  const txtArea = useRef(null);
  const [textareaHasFocus, setTextareaHasFocus] = useState(false);
  useEffect(() => {
    txtArea.current.focus();
  }, [textareaHasFocus]);
  const onFocusInput = e => {
    setTextareaHasFocus(true);
    onFocus(e);
  };
  const onBlurTextArea = e => {
    setTextareaHasFocus(false);
    onBlur(e);
  };
  const componentIsFocused = hasFocus || textareaHasFocus;
  return (
    <TextInputWrapper
      placeholderUp={placeholderUp}
      hidden={hidden}
      hasFocus={componentIsFocused}
      className="has-lock-icon"
    >
      <Placeholder style={placeHolderStyle} placeholderUp={placeholderUp}>
        {placeholder}
      </Placeholder>
      <TextareaAutosize
        style={{ ...textareaStyles, ...style }}
        disabled={disabled}
        inputRef={txtArea}
        {...otherProps}
        hidden={!textareaHasFocus}
        onBlur={onBlurTextArea}
      />
      <input
        style={{
          ...textInputStyles,
          cursor: disabled ? 'default' : 'pointer',
          ...style,
        }}
        disabled={disabled}
        {...otherProps}
        hidden={textareaHasFocus}
        onFocus={onFocusInput}
      />
      {disabled && (
        <LockIconWrapper className="lock-icon">
          <Lock color={COLORS.placeholderGrey} />
        </LockIconWrapper>
      )}
      <ErrorIcon isValid={isValid} />
    </TextInputWrapper>
  );
}

export default MultilineTextInputView;
