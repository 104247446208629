import { FunctionComponent } from 'react';
import Svg, { Path } from 'svgs';
import { useEmotionTheme, EmotionStyle } from '../../core/styled';

interface IconProps {
  width?: number;
  height?: number;
  style?: EmotionStyle;
}

const SmallAudioSessionIcon: FunctionComponent<IconProps> = ({
  width = 14,
  height = 18,
  ...otherProps
}) => {
  const {
    colors: { purple },
  } = useEmotionTheme();
  const titleText = 'audio session icon';
  return (
    <Svg width={width} height={height} viewBox="0 0 14 18" title={titleText} {...otherProps}>
      <Path
        d="M49.53 469.755c.649-.456 1.062-.627 1.77-.74 1.18-.285 3.365 3.702 2.42 4.728-.354.398-.826.341-1.652.683-.177 2.563 2.656 6.893 5.135 7.861.59-.57.826-.968 1.299-1.14 1.416-.341 4.131 3.305 3.364 4.216-.472.513-.767.798-1.476 1.196-5.489 3.361-16.409-13.5-10.86-16.804z"
        transform="translate(-48 -469)"
        fill={purple}
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </Svg>
  );
};

export default SmallAudioSessionIcon;
