import { FunctionComponent } from 'react';
import { View, Text, Spinner, TouchableView, Large } from '@talkspace/react-toolkit';
import moment from 'moment';

import styled from '@/core/styled/styled';
import { EMessage } from '../../entities/EMessage';
import { AckLevel } from '../../types';
import NoSharedFilesBanner from '../NoSharedFilesBanner';
import SharedFileItem from '../SharedFileItem';

const LoadingBanner = styled(View)({
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'stretch',
  height: '10vh',
});

const LoadMoreButton = styled(TouchableView)({
  alignItems: 'center',
  padding: 30,
});

const SharedFilesWrapper = styled(View)({
  backgroundColor: '#fff',
  borderStyle: 'solid',
  borderColor: '#E8E8EA',
  borderWidth: 1,
  borderRadius: 10,
  paddingTop: 29,
  paddingRight: 29,
  paddingLeft: 29,
  marginBottom: 30,
});

interface Props {
  roomID: number;
  isLoading: boolean;
  isLoadingHistorical: boolean;
  receivedAllMessages: boolean;
  sharedFiles: EMessage[];
  containerWidth: number;
  loggedInUserID: number;
  isTherapistChat: boolean;
  onClosePress: () => void;
  onLoadMorePress: () => void;
  getAckLevel: (messageID: number) => AckLevel;
}

const SharedFiles: FunctionComponent<Props> = ({
  roomID,
  isLoading,
  isLoadingHistorical,
  receivedAllMessages,
  sharedFiles,
  onClosePress,
  onLoadMorePress,
  containerWidth,
  loggedInUserID,
  getAckLevel,
  isTherapistChat,
}) => (
  <SharedFilesWrapper>
    {isLoading ? (
      <LoadingBanner>
        <Text>Loading...</Text>
        <Spinner isLoading />
      </LoadingBanner>
    ) : (
      <View style={{ paddingTop: 14 }}>
        {sharedFiles.length === 0 ? (
          <NoSharedFilesBanner onClosePress={onClosePress} />
        ) : (
          sharedFiles.map((msg) => {
            const isSelf = loggedInUserID === msg.user.id;
            const isPeer = !isSelf && msg.user.userType !== 3 && !isTherapistChat;

            return (
              <SharedFileItem
                key={msg.id}
                currentMessage={msg}
                containerWidth={containerWidth}
                roomID={roomID}
                senderName={isSelf ? 'You' : msg.user.displayName}
                date={moment(msg.createdAt).format('MMM DD, YYYY')}
                ackLevel={getAckLevel(msg.id)}
                isPeer={isPeer}
                isSelf={isSelf}
              />
            );
          })
        )}
        {!receivedAllMessages && (
          <LoadMoreButton onPress={onLoadMorePress}>
            {isLoadingHistorical ? (
              <Spinner />
            ) : (
              <Large variant="largeBoldWideGreen">Load more</Large>
            )}
          </LoadMoreButton>
        )}
      </View>
    )}
  </SharedFilesWrapper>
);

export default SharedFiles;
