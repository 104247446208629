import RadioButton, { RadioButtonProps } from './RadioButton';
import { useEmotionTheme } from '../../../index.next';

interface RadioButtonV1Props
  extends Omit<RadioButtonProps, 'style' | 'circleStyle' | 'innerCircleStyle'> {
  variation: 'primary' | 'secondary';
}
const RadioButtonV1 = (props: RadioButtonV1Props) => {
  const { colorRoles } = useEmotionTheme();
  return (
    <RadioButton
      {...props}
      circleStyle={{
        borderColor: colorRoles.system.borderDefault,
      }}
      innerCircleStyle={{
        width: 14,
        height: 14,
        backgroundColor: colorRoles.system.interactivePrimaryDefault,
      }}
    />
  );
};

export default RadioButtonV1;
