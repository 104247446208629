import { useMutation } from 'react-query';
import { Name } from 'components/Dashboard/GrowCaseload/types';
import Analytics from 'components/Dashboard/GrowCaseload/Analytics';

import { CreditMinutes } from 'ts-frontend/types';
import apiHelper from '../../utils/api';
import apiWrapper from '../../utils/apiWrapper';

interface ClaimRoomMutateParams {
  therapistID: number;
  roomID: number;
  bookingData?: BookingData;
}

interface BookingData {
  start: string;
  creditMinutes: CreditMinutes;
}

const claimRoom = async (params: ClaimRoomMutateParams): Promise<Name> => {
  const { therapistID, roomID, bookingData } = params;

  const data = await apiWrapper.post(
    `${apiHelper().apiEndpoint}/v3/therapists/${therapistID}/rooms/${roomID}/claim`,
    { bookingData }
  );

  Analytics.trackRoomClaimedEvent({ therapistID, roomID });

  return data.data;
};

const useMutationClaimRoom = () => useMutation<Name, Error, ClaimRoomMutateParams>(claimRoom);

export default useMutationClaimRoom;
