import { FunctionComponent, useEffect, useState, MutableRefObject } from 'react';
import { Stripe, StripeCardElement, StripeCardElementChangeEvent } from '@stripe/stripe-js';
import {
  View,
  Tiny,
  Tooltip,
  useEmotionTheme,
  AnimatedError,
  CircledQuestionMark,
  Label,
  commonStyles,
  useUniqueID,
  useIFrameFocus,
} from '@talkspace/react-toolkit';

import styled, { EmotionStyle } from '@/core/styled';

const { roundedFocusRing } = commonStyles;

const TextWithLabelStyles = styled(Tiny)({
  textAlign: 'left',
  paddingLeft: 4,
});

const CardElementContainer = styled(View)<{
  isError: boolean;
  shouldShowFocusStyle: boolean;
}>(
  ({
    isError,
    shouldShowFocusStyle,
    theme: {
      window: { isSmall },
      colors,
    },
  }) => {
    const focusStyle = shouldShowFocusStyle
      ? roundedFocusRing(colors.permaWildBlueYonder, true, isError)
      : {};
    return {
      // we have to use !important here to override lastpass and other browser extensions ;)
      backgroundPositionX: isSmall ? '70% !important' : '80% !important',
      borderColor: isError ? colors.red : colors.permaWildBlueYonder,
      borderRadius: 10,
      borderStyle: 'solid',
      borderWidth: 1,
      height: 61,
      ...focusStyle,
    };
  }
);

const StyledStripeAnchor = styled(View)<{ isError: boolean; isKeyboardFocus: boolean }>(
  ({ isError, isKeyboardFocus, theme: { colors } }) => {
    let borderStyle;
    if (isKeyboardFocus) {
      if (isError) {
        borderStyle = `1px solid ${colors.red}`;
      } else {
        borderStyle = `1px solid ${colors.permaWildBlueYonder}`;
      }
    }
    return {
      border: borderStyle,
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      paddingLeft: 20,
      paddingRight: 20,
      borderRadius: 10,
    };
  }
);

const cardElementOptions = (baseColor: string, invalidColor: string) => {
  return {
    style: {
      base: {
        iconColor: baseColor,
        fontSmoothing: 'antialiased',
        '::placeholder': {
          color: baseColor,
        },
      },
      invalid: {
        color: invalidColor,
      },
    },
  };
};

interface Props {
  stripe: Stripe;
  cardElementRef: MutableRefObject<StripeCardElement | null>;
  serverError?: string;
  containerStyle?: EmotionStyle;
  label?: string;
  tooltip?: string;
  tooltipLabel?: string;
  tooltipButtonText?: string;
  labelStyle?: EmotionStyle;
  tooltipStyle?: EmotionStyle;
  errorStyle?: EmotionStyle;
  dataQa?: string;
}

const CreditCardInput: FunctionComponent<Props> = ({
  stripe,
  cardElementRef,
  serverError,
  containerStyle,
  label = 'Credit Card Number',
  tooltip = 'Health savings accounts (HSA) and flexible spending accounts (FSA) are programs that let you set aside money (pre-tax!) for medical expenses — including Talkspace. Whatever you contribute is deducted from your taxable income, which is how you can save up to 25% or more on therapy. Unsure whether you have HSA/FSA cards? Ask your employer or insurance provider directly.',
  tooltipLabel = 'HSAFSA tooltip',
  tooltipButtonText = '(HSA/FSA accepted)',
  labelStyle,
  tooltipStyle,
  errorStyle,
  dataQa = 'stripeCreditCardElement',
}) => {
  const stripeCreditCardElementId = useUniqueID('stripeCreditCardElementId');
  const [validationError, setValidationError] = useState('');
  const { colors } = useEmotionTheme();
  const handleChange = (e: StripeCardElementChangeEvent) => {
    if (e.error) {
      setValidationError(e.error.message);
    } else {
      setValidationError('');
    }
  };

  const getIframe = () => document.querySelector('[allow="payment *"]');
  const { isIframeFocused, isMouseUser, setIsMouseUser } = useIFrameFocus({ getIframe });

  useEffect(() => {
    const elements = stripe.elements();
    const cardElement = elements.create(
      'card',
      cardElementOptions(colors.a11yWildBlueYonder, colors.red)
    );
    cardElement.mount(`#${stripeCreditCardElementId}`);
    cardElement.on('change', handleChange);
    // Allow mutating ref (not sure if this should be allowed)
    // eslint-disable-next-line no-param-reassign
    cardElementRef.current = cardElement;
  }, [stripe, cardElementRef, colors.a11yWildBlueYonder, colors.red, stripeCreditCardElementId]);

  return (
    <>
      <View style={{ marginBottom: 2, ...containerStyle }} row data-qa={dataQa}>
        <Label isDefaultStyle htmlFor={stripeCreditCardElementId} style={labelStyle}>
          {label}
        </Label>
        <Tooltip
          tip={tooltip}
          roundedFocusStyle
          buttonStyle={{ borderRadius: 5, marginBottom: 4, ...tooltipStyle }}
          label={tooltipLabel}
        >
          <View row align="end">
            <TextWithLabelStyles style={{ color: colors.rainforestGreen, marginRight: 2 }}>
              {tooltipButtonText}
            </TextWithLabelStyles>
            <CircledQuestionMark width={15} height={15} />
          </View>
        </Tooltip>
      </View>
      <CardElementContainer
        onMouseEnter={() => setIsMouseUser(true)}
        onTouchStart={() => setIsMouseUser(true)}
        onBlur={() => setIsMouseUser(false)}
        isError={Boolean(validationError)}
        shouldShowFocusStyle={isIframeFocused && !isMouseUser}
      >
        <StyledStripeAnchor
          id={stripeCreditCardElementId}
          isKeyboardFocus={isIframeFocused && !isMouseUser}
          isError={Boolean(validationError)}
        />
      </CardElementContainer>
      <AnimatedError
        shouldAnimate={Boolean(validationError)}
        style={{ maxHeight: 'fit-content', ...errorStyle }}
      >
        {validationError || serverError}
      </AnimatedError>
    </>
  );
};

export default CreditCardInput;
