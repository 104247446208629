import Svg, { Defs, G, Path, Rect, Circle } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

const NoStarredMessages = ({ width = 150, height = 130, ...otherProps }) => {
  const { colors } = useEmotionTheme();
  const titleText = 'files';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox={`0 0 ${height} ${width}`}
      {...otherProps}
    >
      <Defs>
        <filter
          width="213.5%"
          height="205.4%"
          x="-56.7%"
          y="-52.7%"
          filterUnits="objectBoundingBox"
        >
          <feOffset dy="5" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="5" />
          <feColorMatrix
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
            values="0 0 0 0 0.0231265557 0 0 0 0 0.378939716 0 0 0 0 0.390879755 0 0 0 0.0930397727 0"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </Defs>
      <G fill="none" fillRule="evenodd" stroke="none" strokeWidth="1" transform="translate(-141 0)">
        <Circle cx="206.5" cy="65" r="65" fill={colors.permaLinkWaterGrey} />
        <G fill="#C0E3E0">
          <Rect
            width="5"
            height="15"
            x="0"
            y="0"
            rx="2.5"
            transform="rotate(65 21.747 166.404) translate(5.178 .178)"
          />
          <Rect
            width="5"
            height="15"
            x="0"
            y="0"
            rx="2.5"
            transform="rotate(65 21.747 166.404) rotate(90 5 10.178)"
          />
        </G>
        <Circle cx="274" cy="23.5" r="4.5" stroke={colors.conifer} strokeWidth="5" />
        <Circle cx="139.5" cy="42" r="8" stroke={colors.a11yLavendarBlue} strokeWidth="6" />
        <Path
          fill={colors.white}
          stroke={colors.periwinkleGrey}
          strokeWidth="5.204"
          d="M46.16 48.59a7.78 7.78 0 01-5.435 2.477l-28.472.994a7.781 7.781 0 01-5.595-2.092 7.781 7.781 0 01-2.478-5.437L3.05 12.187a7.781 7.781 0 012.093-5.596 7.781 7.781 0 015.436-2.477l28.467-.994a7.783 7.783 0 015.596 2.092 7.783 7.783 0 012.479 5.436l1.131 32.346a7.78 7.78 0 01-2.091 5.595z"
          transform="translate(173.5 31)"
        />
        <G transform="translate(188.5 42)">
          <path
            fill="#E6FDFB"
            stroke={colors.persianGreen}
            strokeWidth="5.204"
            d="M44.646 49.969a7.78 7.78 0 01-5.595 2.092l-28.472-.994a7.781 7.781 0 01-5.436-2.478 7.781 7.781 0 01-2.092-5.596l1.13-32.345a7.781 7.781 0 012.477-5.436 7.781 7.781 0 015.595-2.093l28.468.994a7.783 7.783 0 015.436 2.478 7.783 7.783 0 012.093 5.596l-1.127 32.346a7.78 7.78 0 01-2.477 5.436z"
          />
          <Path
            fill={colors.persianGreen}
            fillRule="nonzero"
            d="M12.489 10.079l5.46 7.672 4.828-3.009c.739-.46 1.929-.438 2.67.06l5.05 3.426a3.2 3.2 0 011.401 2.759l-.062 1.79a3.991 3.991 0 01-4.123 3.862l-23.866-.837A3.995 3.995 0 01.002 21.66l.14-4.033a3.2 3.2 0 011.316-2.476l7.725-5.623a2.353 2.353 0 013.306.551zM23.055.464l.217.003a5.002 5.002 0 014.823 5.173 4.998 4.998 0 01-5.17 4.821 5.002 5.002 0 01-4.823-5.172 4.998 4.998 0 015.17-4.822z"
            transform="translate(9.4 19)"
          />
        </G>
      </G>
    </Svg>
  );
};

export default NoStarredMessages;
