import { FunctionComponent, useEffect, useRef, useCallback } from 'react';
import { setFunnelName } from 'ts-frontend/hooks/funnelName';
import {
  Route,
  useHistory,
  useLocation,
  useParams,
  RouteComponentProps,
} from '@/core/routerLib/routerLib';
import ChatScreen from './screens/ChatScreen';
import VideoCallScreen from './screens/VideoCallScreen';
import sessionStorage from '@/core/storage/sessionStorage';
import { useSetVideoCallScreenProps } from './VideoCallWrapper';
import { VideoCallScreenProps } from './types/videoCallTypes';
import { useVideoCallActions, useVideoCallState } from './hooks/videoCallContext';

const useShouldShowLVSModal = ({ isTherapistChat, isVideoCallOutsideChat }) => {
  const { isLVSModalOpen } = useVideoCallState();
  const { setIsLVSModalOpen } = useVideoCallActions();

  const location = useLocation();

  useEffect(() => {
    const videoSessionInfo = JSON.parse(sessionStorage.getItem('videoSessionInfo') || '{}');
    const isInModalPath = location.pathname.includes('/modal/video-call');
    if (videoSessionInfo?.isInSession || isInModalPath) {
      setIsLVSModalOpen(true);
    } else if (!isTherapistChat) {
      setIsLVSModalOpen(false);
    }
    setFunnelName('In Room');
  }, [isTherapistChat, isVideoCallOutsideChat, setIsLVSModalOpen, location.pathname]);

  return { isLVSModalOpen, setIsLVSModalOpen };
};

const VideoCallScreenRoute = ({
  match: { params },
  closeLVSModal,
  isTherapistChat,
  outsideHeaderHeight,
  outsideFooterHeight,
  isOutsideChat,
}: RouteComponentProps<{ roomID: string }, {}, unknown> & Omit<VideoCallScreenProps, 'roomID'>) => {
  const { isLVSModalOpen } = useVideoCallState();
  useSetVideoCallScreenProps({
    roomID: Number(params.roomID),
    closeLVSModal,
    isTherapistChat,
    outsideHeaderHeight,
    outsideFooterHeight,
    isOutsideChat,
  });

  if (isOutsideChat || !isLVSModalOpen) return null;

  return (
    <VideoCallScreen
      roomID={Number(params.roomID)}
      closeLVSModal={closeLVSModal}
      isTherapistChat={isTherapistChat}
      outsideHeaderHeight={outsideHeaderHeight}
      outsideFooterHeight={outsideFooterHeight}
      isOutsideChat={false}
    />
  );
};

const Routes: FunctionComponent<{
  isAcking?: boolean;
  shouldPlaySoundNotifications?: boolean;
  style?: {};
  banners?: JSX.Element[];
  priorityBanner?: JSX.Element;
  isTherapistChat?: boolean;
  stopInputAutofocus?: boolean;
  isFocusedAfterModal: boolean;
  isOffline?: boolean;
  outsideHeaderHeight: number;
  outsideFooterHeight: number;
  isVideoCallOutsideChat?: boolean;
}> = ({
  isAcking,
  shouldPlaySoundNotifications,
  style,
  banners,
  priorityBanner,
  isTherapistChat,
  stopInputAutofocus,
  isFocusedAfterModal,
  isOffline,
  outsideHeaderHeight,
  outsideFooterHeight,
  isVideoCallOutsideChat = false,
}) => {
  const history = useHistory();
  const params = useParams<{ roomID: string }>();
  const replacePath = useRef(history.location.pathname + history.location.search);
  const { isLVSModalOpen, setIsLVSModalOpen } = useShouldShowLVSModal({
    isTherapistChat,
    isVideoCallOutsideChat,
  });

  useEffect(() => {
    const unlisten = history.listen((location) => {
      const videoSessionInfo = JSON.parse(sessionStorage.getItem('videoSessionInfo') || '{}');
      const isInModalPath = location.pathname.includes('/modal/video-call');

      if (location.pathname.includes('/progress-notes') && !isInModalPath) {
        replacePath.current = location.pathname + location.search;
      }

      if (!videoSessionInfo?.isInSession && !isInModalPath) {
        replacePath.current = location.pathname + location.search;
      }
    });
    return () => unlisten();
  }, [history, isLVSModalOpen, isTherapistChat]);

  const closeLVSModal = useCallback(() => {
    setIsLVSModalOpen(false);
    sessionStorage.removeItem('videoSessionInfo');
    if (replacePath.current.includes('modal/video-call') && params.roomID) {
      history.replace(`/room/${params.roomID}`);
    } else {
      history.replace(replacePath.current);
    }
  }, [history, params.roomID, setIsLVSModalOpen]);

  return (
    <Route
      path={[
        '/room/:roomID',
        '/room/:roomID/modal/video-call',
        '/room/:roomID/*/modal/video-call',
        '/room/:roomID/modal/video-call/:videoCallID',
      ]}
      render={(routerProps) => (
        <>
          <VideoCallScreenRoute
            {...routerProps}
            closeLVSModal={closeLVSModal}
            isTherapistChat={isTherapistChat}
            outsideHeaderHeight={outsideHeaderHeight}
            outsideFooterHeight={outsideFooterHeight}
            isOutsideChat={isVideoCallOutsideChat}
          />
          <ChatScreen
            {...routerProps}
            isAcking={isAcking}
            shouldPlaySoundNotifications={shouldPlaySoundNotifications}
            style={style}
            banners={banners}
            priorityBanner={priorityBanner}
            stopInputAutofocus={stopInputAutofocus}
            roomID={Number(routerProps.match.params.roomID)}
            isFocusedAfterModal={isFocusedAfterModal}
            isOffline={!!isOffline}
          />
        </>
      )}
    />
  );
};
export default Routes;
