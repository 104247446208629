import {
  View,
  styled,
  useEmotionTheme,
  Heading3,
  Small,
  TimeOffIcon,
  EmotionStyle,
} from '@talkspace/react-toolkit';
import { useEffect, useState } from 'react';
import ModalContainer from './ModalContainer';

const IconContainer = styled(View)(({ theme: { colors } }) => {
  return {
    height: 28,
    width: 28,
    borderRadius: 8,
    background: colors.permaSelago,
    marginRight: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };
});

const ErrorModal = ({
  isModalOpen,
  positioningStyle,
}: {
  isModalOpen: boolean;
  positioningStyle?: EmotionStyle;
}) => {
  const { colors } = useEmotionTheme();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    setIsOpen(isModalOpen);
  }, [isModalOpen]);

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  return (
    <ModalContainer
      isModalOpen={isOpen}
      handleCloseModal={handleCloseModal}
      primaryButtonText="Close"
      primaryButtonOnClick={handleCloseModal}
      positioningStyle={positioningStyle}
    >
      <View style={{ marginLeft: 25 }}>
        <View style={{ marginBottom: 30 }} row>
          <IconContainer>
            <TimeOffIcon isBig />
          </IconContainer>
          <Heading3>Oops, something went wrong</Heading3>
        </View>
        <Small style={{ marginLeft: 46, color: colors.purple600 }}>
          Your changes could not be saved. Please try again.
        </Small>
      </View>
    </ModalContainer>
  );
};

export default ErrorModal;
