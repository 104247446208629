import { useCallback, useEffect, useState, memo } from 'react';
import { View } from '@talkspace/react-toolkit';
import {
  setIonicStorage,
  PASSCODE_KEY,
  PASSCODE_ATTEMPTS,
  getIonicPasscodeAttempts,
  setIonicPasscodeEnabled,
  removeIonicStorage,
} from '../../../plugins/secureStorage';
import { getUserData } from '@/auth/helpers/token';
import { useHistory } from '@/core/routerLib';
import { getIsIonic } from '../../../ionicUtils';
import { logout } from '@/auth/auth';

import PasscodeView from './PasscodeView';
import { PASSCODE_LENGTH, MAX_PASSCODE_ATTEMPTS } from '../constants';

interface GetSubtitleParams {
  isClient: boolean;
  isEdit: boolean;
}
const getSubtitle = ({ isClient, isEdit }: GetSubtitleParams): string => {
  if (isEdit) {
    return '';
  }
  if (isClient) {
    return `To protect your privacy, please set up a ${PASSCODE_LENGTH}-digit passcode`;
  }
  return `To protect your clients’ privacy, please set up a ${PASSCODE_LENGTH}-digit passcode`;
};

interface CreatePasscodeProps {
  successRoute?: string;
  isClient: boolean;
}
const CreatePasscode = memo(({ successRoute = '/rooms', isClient }: CreatePasscodeProps) => {
  const [passcode, setPasscode] = useState<string | null>(null);
  const [reEnterPasscode, setReEnterPasscode] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isPasscodeCreated, setIsPasscodeCreated] = useState<boolean>(false);
  const history = useHistory();
  const { id: userID } = getUserData();
  const keySuffix = isClient && userID ? `${userID}` : undefined;

  const isEdit = history.location.pathname.includes('edit');

  if (!getIsIonic()) {
    history.push(successRoute);
  }

  useEffect(() => {
    if (isPasscodeCreated) {
      setTimeout(() => {
        history.push(successRoute);
      }, 2000);
    }
  }, [isPasscodeCreated, history, successRoute]);

  const savePasscodeOnSuccess = useCallback(() => {
    setIsPasscodeCreated(true);
  }, [setIsPasscodeCreated]);

  const handleSetPasscodeInStorage = useCallback(async () => {
    if (passcode) {
      const res = await setIonicStorage({
        key: PASSCODE_KEY,
        value: passcode,
        callback: savePasscodeOnSuccess,
        keySuffix,
      });
      if (isClient) {
        await setIonicPasscodeEnabled(true, keySuffix);
      }
      if (res.errorMessage) {
        setErrorMessage(res.errorMessage);
      }
    }
  }, [passcode, setErrorMessage, savePasscodeOnSuccess, keySuffix, isClient]);

  const handleWrongCode = useCallback(async () => {
    const currentNumberOfAttempts = await getIonicPasscodeAttempts(keySuffix);

    if (currentNumberOfAttempts === MAX_PASSCODE_ATTEMPTS) {
      await removeIonicStorage(PASSCODE_ATTEMPTS, keySuffix);
      logout();
    } else {
      await setIonicStorage({
        key: PASSCODE_ATTEMPTS,
        value: (currentNumberOfAttempts + 1).toString(),
        keySuffix,
      });
    }
    setErrorMessage(
      isEdit
        ? "New passcode didn't match.\nPlease try again."
        : "Re-entered passcode didn't match.\nPlease try again."
    );
  }, [isEdit, keySuffix]);

  useEffect(() => {
    if (passcode?.length === PASSCODE_LENGTH && reEnterPasscode?.length === PASSCODE_LENGTH) {
      if (passcode !== reEnterPasscode) {
        handleWrongCode();
      } else {
        handleSetPasscodeInStorage();
      }
    }
  }, [passcode, reEnterPasscode, handleSetPasscodeInStorage, handleWrongCode]);

  const handleSetPasscode = (otpKey: string) => {
    setPasscode(otpKey);
    setErrorMessage('');
  };

  const handleReEnterPasscode = (otpKey: string) => {
    setReEnterPasscode(otpKey);
    setErrorMessage('');
  };

  const handleBackArrow = () => {
    setPasscode(null);
    setReEnterPasscode(null);
    setErrorMessage('');
  };

  return (
    <View>
      {passcode?.length === PASSCODE_LENGTH ? (
        <PasscodeView
          key="re-enter-passcode"
          title={isEdit ? 'Re-enter new passcode' : 'Re-enter your passcode'}
          handleChange={handleReEnterPasscode}
          errorMessage={errorMessage}
          shouldShowBackArrow={!isPasscodeCreated}
          handleBackArrow={handleBackArrow}
          isPasscodeCreated={isPasscodeCreated}
          subtitle={getSubtitle({ isClient, isEdit })}
          inCreateMode
          shouldDisplayCloseButton={isEdit}
          closePath={isClient ? successRoute : undefined}
        />
      ) : (
        <PasscodeView
          key="enter-passcode"
          title={isEdit ? 'Enter new passcode' : 'Create your passcode'}
          handleChange={handleSetPasscode}
          subtitle={getSubtitle({ isClient, isEdit })}
          inCreateMode
          shouldDisplayCloseButton={isEdit}
          closePath={isClient ? successRoute : undefined}
        />
      )}
    </View>
  );
});

export default CreatePasscode;
