import * as actionTypes from '../constants/MedicalInformationConstants';

const initialState = {
  spinnerMessage: '',
  isUpdating: false,
  isError: false,
  isPregnant: null,
  medicalIssues: [],
  medicalIssuesOptions: [],
  hasChronicPain: null,
  medications: [],
  otherCurrentPrescriptionMedications: '',
  medicationsOptions: [],
  otcMedications: '',
  drugAllergies: '',
};

export default function medicalInformation(state = initialState, action) {
  switch (action.type) {
    case actionTypes.REQUEST_GET_MEDICAL_INFORMATION:
    case actionTypes.REQUEST_SET_MEDICAL_INFORMATION:
      return {
        ...state,
        spinnerMessage: 'Loading client information',
        isUpdating: true,
        isError: false,
      };
    case actionTypes.RECEIVE_GET_MEDICAL_INFORMATION:
    case actionTypes.RECEIVE_SET_MEDICAL_INFORMATION:
      return {
        ...state,
        ...action.payload,
        isUpdating: false,
        isError: false,
      };
    case actionTypes.REQUEST_GET_MEDICAL_INFORMATION_ERROR:
    case actionTypes.REQUEST_SET_MEDICAL_INFORMATION_ERROR:
      return {
        ...state,
        isUpdating: false,
        isError: true,
        spinnerMessage: '',
      };
    default:
      return state;
  }
}
