import { FunctionComponent } from 'react';
import Svg, { Path, Circle } from 'svgs';
import { EmotionStyle, useEmotionTheme } from '../../core/styled';

const QuestionRound: FunctionComponent<{
  circleColor?: string;
  questionMarkColor?: string;
  width?: string | number;
  height?: string | number;
  style?: EmotionStyle;
  isPressed?: boolean;
  isHovering?: boolean;
  hoverColor?: string;
}> = ({
  width = 21,
  height = 21,
  circleColor,
  questionMarkColor = 'white',
  style,
  isPressed = false,
  isHovering = false,
  hoverColor,
  ...otherProps
}) => {
  const titleText = 'Question icon';
  const { colors } = useEmotionTheme();
  let color = circleColor || colors.darkBlue;
  if (isHovering || isPressed) {
    color = hoverColor || colors.accessibilityGreenDark;
  }
  return (
    <Svg
      width={width}
      height={height}
      aria-label={titleText}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <Circle cx="10.5" cy="10.5" r="10.5" fill={color} />
      <Path
        d="M10.9951 11.8486H9.0332C9.0332 11.4476 9.06055 11.0944 9.11523 10.7891C9.17448 10.4792 9.27474 10.2012 9.41602 9.95508C9.55729 9.70898 9.7487 9.48112 9.99023 9.27148C10.2044 9.09375 10.389 8.92285 10.5439 8.75879C10.6989 8.59473 10.8174 8.42839 10.8994 8.25977C10.9814 8.09115 11.0225 7.91113 11.0225 7.71973C11.0225 7.48275 10.9883 7.28906 10.9199 7.13867C10.8561 6.98828 10.7581 6.87663 10.626 6.80371C10.4984 6.72624 10.3366 6.6875 10.1406 6.6875C9.98112 6.6875 9.83073 6.72624 9.68945 6.80371C9.55273 6.88118 9.44108 7.00195 9.35449 7.16602C9.2679 7.32552 9.22005 7.53743 9.21094 7.80176H6.89355C6.90723 7.12272 7.05762 6.57129 7.34473 6.14746C7.63639 5.71908 8.02376 5.4069 8.50684 5.21094C8.99447 5.01042 9.53906 4.91016 10.1406 4.91016C10.806 4.91016 11.3779 5.0127 11.8564 5.21777C12.335 5.42285 12.7018 5.72819 12.957 6.13379C13.2122 6.53483 13.3398 7.0293 13.3398 7.61719C13.3398 8.00911 13.2646 8.35091 13.1143 8.64258C12.9684 8.92969 12.7702 9.19857 12.5195 9.44922C12.2734 9.69531 11.9977 9.95964 11.6924 10.2422C11.4281 10.4746 11.248 10.7116 11.1523 10.9531C11.0566 11.1901 11.0042 11.4886 10.9951 11.8486ZM8.75293 13.9268C8.75293 13.5986 8.87142 13.3252 9.1084 13.1064C9.34538 12.8831 9.65299 12.7715 10.0313 12.7715C10.4095 12.7715 10.7171 12.8831 10.9541 13.1064C11.1911 13.3252 11.3096 13.5986 11.3096 13.9268C11.3096 14.2549 11.1911 14.5306 10.9541 14.7539C10.7171 14.9727 10.4095 15.082 10.0313 15.082C9.65299 15.082 9.34538 14.9727 9.1084 14.7539C8.87142 14.5306 8.75293 14.2549 8.75293 13.9268Z"
        fill={questionMarkColor}
      />
    </Svg>
  );
};

export default QuestionRound;
