import { FunctionComponent } from 'react';
import { View, RedX, ExtraHuge, styled, CloseButton } from '@talkspace/react-toolkit';

const Wrapper = styled(View)({
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
});

const ErrorMessage = styled(ExtraHuge)({
  width: 284,
  marginTop: 20,
  textAlign: 'center',
});

const CloseButtonWrapper = styled(View)({
  alignSelf: 'end',
  paddingRight: 10,
  paddingTop: 10,
});

interface Props {
  message?: string;
  displayCloseButton?: boolean;
  handleClose?: () => void;
}

const ErrorScreen: FunctionComponent<Props> = ({ message, displayCloseButton, handleClose }) => {
  const formattedMessage = message && Number.isNaN(Number(message)) ? message : undefined;
  return (
    <>
      {displayCloseButton && (
        <CloseButtonWrapper>
          <CloseButton onPress={handleClose} backgroundColor="transparent" />
        </CloseButtonWrapper>
      )}
      <Wrapper>
        <RedX />
        <ErrorMessage>{formattedMessage || 'Sorry, something went wrong'}</ErrorMessage>
      </Wrapper>
    </>
  );
};

export default ErrorScreen;
