import omit from 'lodash/omit';
import cloneDeep from 'lodash/cloneDeep';
import { ProgressNoteQueryResponse } from 'hooks/notes/useQueryProgressNote';
import { GetProgressNoteHelperDataResponse } from 'hooks/notes/useQueryProgressNoteHelperData';
import { ProgressNoteFormState } from './types';
import {
  DEFAULT_RISK_OR_BARRIERS_HANDLING,
  getFormattedOtherAssessmentNotesFromV1,
  getFormattedOtherSubstanceUseNotesFromV1,
  DEFAULT_RELATIONSHIP_TO_CLIENT,
} from '../../utils';
import { FormMode, VideoCall } from '../../types';
import { UnsubmittedSession } from '../../../../../../hooks/notes/useQueryUnsubmittedSessions';
import { UnsubmittedSessionTask } from '../../../../../../hooks/dashboard/useQueryTaskListV3';

const VERSION = 2;

const getDefaultInitialState = (params: GetInitialStateParams): ProgressNoteFormState => {
  const { sessionSummary } = params.helperData.lastProgressNote || {};
  const clientInitialState = sessionSummary?.clientState || params.helperData.clientState;
  const clientInitialCountry = sessionSummary?.clientCountry || params.helperData.clientCountry;
  const clientState = !['US', null].includes(clientInitialCountry)
    ? null
    : clientInitialState || undefined;
  const clientCountry = clientState ? null : clientInitialCountry || undefined;

  return {
    formMode: params.formMode,
    showGlobalError: false,
    formSections: {
      sessionDateAndModality: {
        open: true,
        changed: false,
        completed: false,
      },
      sessionInformation: {
        open: false,
        changed: false,
        completed: false,
      },
      diagnosis: {
        open: false,
        changed: false,
        completed: false,
      },
      riskAssessment: {
        open: false,
        changed: false,
        completed: false,
      },
      substanceUse: {
        open: false,
        changed: false,
        completed: false,
      },
      treatmentPlanProgress: {
        open: false,
        changed: false,
        completed: false,
      },
      sessionSummary: {
        open: false,
        changed: false,
        completed: false,
      },
      medicalInformation: {
        open: false,
        changed: false,
        completed: false,
      },
      mentalStatusExam: {
        open: false,
        changed: false,
        completed: false,
      },
      psychSessionSummary: {
        open: false,
        changed: false,
        completed: false,
      },
      psychPlan: {
        open: false,
        changed: false,
        completed: false,
      },
    },
    caseID: null,
    sessionReportID: null,
    modalityID: null,
    serviceStartDate: null,
    serviceEndDate: null,
    videoCalls: [],
    sessionAttendees: [],
    sessionServiceID: null,
    diagnoses: [],
    riskAssessment: {
      pastSuicidalIdeation: false,
      pastSuicidalIdeationSeverity: null,
      pastSuicidalIdeationNotes: null,
      currentSuicidalIdeation: false,
      currentSuicidalIdeationSeverity: null,
      currentSuicidalIdeationNotes: null,
      pastHomicidalIdeation: false,
      pastHomicidalIdeationSeverity: null,
      pastHomicidalIdeationNotes: null,
      currentHomicidalIdeation: false,
      currentHomicidalIdeationSeverity: null,
      currentHomicidalIdeationNotes: null,
      pastPsychosis: false,
      pastPsychosisNotes: null,
      currentPsychosis: false,
      currentPsychosisNotes: null,
      otherAssessment: false,
      otherAssessmentNotes: null,
    },
    substanceUse: {
      pastSubstanceUse: null,
      pastCigarettesUseNotes: null,
      pastVapingUseNotes: null,
      pastAlcoholUseNotes: null,
      pastMarijuanaUseNotes: null,
      pastStimulantsUseNotes: null,
      pastCocaineUseNotes: null,
      pastHeroinUseNotes: null,
      pastBenzodiazepinesUseNotes: null,
      pastOpioidsUseNotes: null,
      pastOtherSubstanceUseNotes: null,
      currentSubstanceUse: false,
      currentCigarettesUseNotes: null,
      currentVapingUseNotes: null,
      currentAlcoholUseNotes: null,
      currentMarijuanaUseNotes: null,
      currentStimulantsUseNotes: null,
      currentCocaineUseNotes: null,
      currentHeroinUseNotes: null,
      currentBenzodiazepinesUseNotes: null,
      currentOpioidsUseNotes: null,
      currentOtherSubstanceUseNotes: null,
    },
    medicalInformation: null,
    medicalInformationConditions: [],
    medicalInformationMedications: [],
    mentalStatusExam: null,
    treatmentPlanID: null,
    presentingProblems: [],
    treatmentPlanProgress: {
      buildRapport: false,
      shortTermTreatmentObjective: null,
      riskOrBarriersHandling: DEFAULT_RISK_OR_BARRIERS_HANDLING,
    },
    treatmentPlanGoals: [],
    treatmentPlanObjectives: [],
    treatmentPlanInterventions: [],
    sessionSummary: {
      summary: null,
      userRoomSurveyID: null,
      followupPlan: null,
      recommendations: null,
      presentIllnessHistory: null,
      assessment: null,
      patientReport: null,
      clientState,
      clientCountry,
      clientAppropriateForTelehealth:
        sessionSummary && sessionSummary.clientAppropriateForTelehealth !== null
          ? sessionSummary.clientAppropriateForTelehealth
          : true,
      clientRequestedLanguageAssistance:
        sessionSummary && sessionSummary.clientRequestedLanguageAssistance !== null
          ? sessionSummary.clientRequestedLanguageAssistance
          : false,
      languageAssistanceProvided:
        sessionSummary && sessionSummary.languageAssistanceProvided !== null
          ? sessionSummary.languageAssistanceProvided
          : null,
    },
    psychiatryPlan: null,
    psychiatryPlanMedications: [],
    referralID: null,
    otherReferral: null,
    statementCertified: true,
    currentSection: null,
    version: VERSION,
    isSaveAsDraftPressed: false,
    lowEngagement: false,
    isSessionSummaryTooShort: false,
    isFirstNote: false,
    generatedSessionSummaryID: null,
    unsubmittedSession: null,
    unsubmittedSessionID: null,
    unsubmittedFreeLiveCalls: null,
  };
};

const handleCreateMode = (initialState: ProgressNoteFormState, params: GetInitialStateParams) => {
  const updatedState = cloneDeep(initialState);

  const {
    helperData,
    unsubmittedSession,
    unsubmittedFreeLiveCalls,
    unsubmittedSessionID,
    clientUserID,
  } = params;

  updatedState.unsubmittedSession = unsubmittedSession;
  updatedState.unsubmittedSessionID = unsubmittedSessionID;
  updatedState.unsubmittedFreeLiveCalls = unsubmittedFreeLiveCalls ?? [];
  updatedState.clientUserID = clientUserID;

  if (helperData.lastProgressNote) {
    const { lastProgressNote } = helperData;

    updatedState.sessionAttendees = lastProgressNote.sessionAttendees
      .filter((it) => it.relationshipToClient !== DEFAULT_RELATIONSHIP_TO_CLIENT)
      .map((it) => {
        return {
          attendeeName: it.attendeeName,
          relationshipToClient: it.relationshipToClient,
          id: it.id,
        };
      });

    updatedState.substanceUse = omit(lastProgressNote.substanceUse, ['id', 'noteID', 'createdAt']);
    if (lastProgressNote.substanceUse.currentSubstanceUse === null) {
      updatedState.substanceUse.currentSubstanceUse = false;
    }

    updatedState.treatmentPlanProgress = omit(lastProgressNote.treatmentPlanProgress, [
      'id',
      'noteID',
      'createdAt',
    ]);
  }

  if (helperData.identifiedClient) {
    updatedState.sessionAttendees = [
      ...updatedState.sessionAttendees,
      {
        attendeeName: helperData.identifiedClient,
        relationshipToClient: DEFAULT_RELATIONSHIP_TO_CLIENT,
        id: 0,
      },
    ];
  }

  const defaultVideoCall =
    helperData.unassociatedVideoCalls.length > 0
      ? helperData.unassociatedVideoCalls[helperData.unassociatedVideoCalls.length - 1]
      : null;

  if (defaultVideoCall) {
    updatedState.videoCalls = [{ videoCallID: defaultVideoCall.id }];
  }

  let defaultSessionServiceID: number | undefined;

  const isFirstNote = helperData.lastProgressNote === null;
  updatedState.isFirstNote = isFirstNote;

  if (updatedState.treatmentPlanProgress) {
    updatedState.treatmentPlanProgress.riskOrBarriersHandling = DEFAULT_RISK_OR_BARRIERS_HANDLING;
  }

  if (defaultSessionServiceID) {
    updatedState.sessionServiceID = defaultSessionServiceID;
  }
  updatedState.version = VERSION;
  return updatedState;
};

const handleEditMode = (initialState: ProgressNoteFormState, params: GetInitialStateParams) => {
  const { selectedNote, helperData } = params;
  if (!selectedNote) {
    return {
      ...initialState,
      unsubmittedSession: params.unsubmittedSession,
      unsubmittedSessionID: params.unsubmittedSessionID,
      unsubmittedFreeLiveCalls: params.unsubmittedFreeLiveCalls ?? [],
      clientUserID: params.clientUserID,
    };
  }

  const {
    sessionReportPosition,
    formSections,
    status,
    createdByUser,
    submittedAt,
    readOnly,
    videoCalls,
    lowEngagement,
    sessionSummary,
    serviceType,
    ...formState
  } = selectedNote;

  const updatedState: ProgressNoteFormState = {
    formMode: params.formMode,
    showGlobalError: false,
    lowEngagement,
    sessionSummary: {
      ...sessionSummary,
      clientState:
        !sessionSummary.clientState && !sessionSummary.clientCountry
          ? undefined
          : sessionSummary.clientState,
      clientCountry:
        !sessionSummary.clientState && !sessionSummary.clientCountry
          ? undefined
          : sessionSummary.clientCountry,
    },
    videoCalls: videoCalls.map((it) => {
      return { videoCallID: it.videoCallID };
    }),
    ...formState,
    psychiatryPlanMedications: [...formState.psychiatryPlanMedications],
    treatmentPlanGoals: formState.treatmentPlanGoals.map((it) => {
      return {
        overallProgress: it.overallProgress,
        treatmentPlanGoalID: it.treatmentPlanGoalID,
      };
    }),
    treatmentPlanObjectives: formState.treatmentPlanObjectives.map((it) => {
      return {
        overallProgress: it.overallProgress,
        treatmentPlanObjectiveID: it.treatmentPlanObjectiveID,
      };
    }),
    treatmentPlanInterventions: formState.treatmentPlanInterventions.map((it) => {
      return {
        treatmentPlanObjectiveID: it.treatmentPlanObjectiveID,
        treatmentPlanInterventionID: it.treatmentPlanInterventionID,
      };
    }),
    formSections: formSections || initialState.formSections,
    serviceStartDate: selectedNote.serviceStartDate
      ? new Date(selectedNote.serviceStartDate)
      : null,
    serviceEndDate: selectedNote.serviceEndDate ? new Date(selectedNote.serviceEndDate) : null,
    statementCertified: true,
  };
  if (selectedNote.substanceUse.currentSubstanceUse === null) {
    updatedState.substanceUse.currentSubstanceUse = false;
  }
  if (selectedNote.treatmentPlanProgress?.riskOrBarriersHandling === null) {
    if (!updatedState.treatmentPlanProgress) {
      updatedState.treatmentPlanProgress = {
        buildRapport: false,
        shortTermTreatmentObjective: null,
        riskOrBarriersHandling: DEFAULT_RISK_OR_BARRIERS_HANDLING,
      };
    } else {
      updatedState.treatmentPlanProgress.riskOrBarriersHandling = DEFAULT_RISK_OR_BARRIERS_HANDLING;
    }
  }

  const formattedOtherAssessmentNotesFromV1 = getFormattedOtherAssessmentNotesFromV1(
    selectedNote.riskAssessment
  );
  if (formattedOtherAssessmentNotesFromV1) {
    updatedState.riskAssessment = {
      ...updatedState.riskAssessment,
      otherAssessment: true,
      otherAssessmentNotes: updatedState.riskAssessment.otherAssessmentNotes
        ? `${formattedOtherAssessmentNotesFromV1}Other assessment: ${updatedState.riskAssessment.otherAssessmentNotes}`
        : formattedOtherAssessmentNotesFromV1,
    };
  }

  const formattedOtherSubstanceUseNotesFromV1 = getFormattedOtherSubstanceUseNotesFromV1(
    selectedNote.substanceUse
  );
  if (formattedOtherSubstanceUseNotesFromV1 && params.formMode === 'view') {
    updatedState.substanceUse = {
      ...updatedState.substanceUse,
      currentOtherSubstanceUseNotes: formattedOtherSubstanceUseNotesFromV1,
    };
  }

  updatedState.sessionAttendees = selectedNote.sessionAttendees
    .filter((it) => it.relationshipToClient !== DEFAULT_RELATIONSHIP_TO_CLIENT)
    .map((it) => {
      return {
        attendeeName: it.attendeeName,
        relationshipToClient: it.relationshipToClient,
        id: it.id,
      };
    });

  if (helperData.identifiedClient) {
    updatedState.sessionAttendees = [
      ...updatedState.sessionAttendees,
      {
        attendeeName: helperData.identifiedClient,
        relationshipToClient: DEFAULT_RELATIONSHIP_TO_CLIENT,
        id: 0,
      },
    ];
  }

  updatedState.isFirstNote = params.helperData.lastProgressNote === null;

  updatedState.version = VERSION;

  updatedState.unsubmittedSession = params.unsubmittedSession;
  updatedState.unsubmittedSessionID = params.unsubmittedSessionID;
  updatedState.unsubmittedFreeLiveCalls = params.unsubmittedFreeLiveCalls ?? [];
  updatedState.clientUserID = params.clientUserID;

  return updatedState;
};

interface GetInitialStateParams {
  formMode: FormMode;
  helperData: GetProgressNoteHelperDataResponse;
  selectedNote: ProgressNoteQueryResponse | null;
  unsubmittedSession?: UnsubmittedSession | UnsubmittedSessionTask | null;
  unsubmittedSessionID?: string | null;
  unsubmittedFreeLiveCalls?: VideoCall[] | null;
  clientUserID?: number;
}

const getInitialState = (params: GetInitialStateParams): ProgressNoteFormState => {
  const defaultInitialState = getDefaultInitialState(params);

  switch (params.formMode) {
    case 'create':
      return handleCreateMode(defaultInitialState, params);
    case 'edit':
    case 'view':
      return handleEditMode(defaultInitialState, params);
    default:
      throw new Error(`getInitialState got an invalid formMode param: ${params.formMode}`);
  }
};

export default getInitialState;
