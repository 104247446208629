import { FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  View,
  Small,
  ExtraTiny,
  Checkbox,
  Standard,
  useEmotionTheme,
  TextAreaRounded,
  AccordionContainer,
  styled,
} from '@talkspace/react-toolkit';
import * as treatmentPlannerActions from 'actions/TreatmentPlannerActions';
import useQueryProgressNote from 'hooks/notes/useQueryProgressNote';
import useQueryNotes, { NoteSessionReportData } from 'hooks/notes/useQueryNotes';
import useMutationSaveDraftProgressNote from 'hooks/notes/useMutationSaveDraftProgressNote';
import useMutationUpdateProgressNote from 'hooks/notes/useMutationUpdateProgressNote';
import useQueryRoomDetails from 'ts-frontend/hooks/useQueryRoomDetails';
import { useHistory, useLocation, useParams } from '@/core/routerLib';
import { getTextAreaContainerBorderStyle, getTextAreaStyle } from '../../utils';
import {
  useProgressNoteFormActions,
  useProgressNoteFormState,
} from '../context/PsychProgressNoteFormContext';
import { ProgressNoteFormState } from '../context/types';
import ConfirmButton from './ConfirmButton';
import { SaveProgressNoteDialogLocationState } from '../../SaveProgressNoteDialog';

const TextAreaWrapper = styled(View)({
  alignItems: 'flex-end',
  marginBottom: 15,
  marginTop: 15,
  marginRight: 13,
});

const textAreaContainerStyle = {
  width: 264,
};

interface StoreProps {
  createNewTreatmentPlan(presentingProblems: Array<any>): Promise<void>;
}
interface OwnProps {
  currentTreatmentPlanID: number | undefined;
}

type Props = StoreProps & OwnProps;

const NoTreatmentPlan: FunctionComponent<Props> = ({
  currentTreatmentPlanID,
  createNewTreatmentPlan,
}) => {
  const history = useHistory();
  const location = useLocation();
  const { roomID, noteID } = useParams<{ roomID: string; noteID?: string }>();
  const { data: notesData } = useQueryNotes(roomID);
  const formState = useProgressNoteFormState();
  const [treatmentPlanProgress, setTreatmentPlanProgress] = useState<
    ProgressNoteFormState['treatmentPlanProgress']
  >(formState.treatmentPlanProgress);

  const { colors } = useEmotionTheme();

  const [shortTermTreatmentObjectiveError, setShortTermTreatmentObjectiveError] = useState(false);
  const [riskOrBarriersHandlingError, setRiskOrBarriersHandlingError] = useState(false);
  const [shortTermTreatmentBuildRapportError, setShortTermTreatmentBuildRapportError] =
    useState(false);

  const { setFormState } = useProgressNoteFormActions();
  const [eapSessionReportData, setEapSessionReportData] = useState<
    NoteSessionReportData | null | undefined
  >(null);
  const { data: { clientUserID } = {} } = useQueryRoomDetails(roomID);
  const { data: progressNoteData } = useQueryProgressNote(clientUserID, noteID);
  const { mutate: saveDraftProgressNote } = useMutationSaveDraftProgressNote();
  const { mutate: updateProgressNote } = useMutationUpdateProgressNote();

  const markSectionAsIncomplete = () => {
    if (formState.formSections.treatmentPlanProgress.completed) {
      setFormState({
        formSections: {
          ...formState.formSections,
          treatmentPlanProgress: {
            open: formState.formSections.treatmentPlanProgress.open,
            changed: true,
            completed: false,
          },
        },
      });
    }
  };

  const navigateToTreatmentPlanner = () => {
    if (currentTreatmentPlanID) {
      history.push(`/room/${roomID}/treatment-planner-goals`);
    } else {
      createNewTreatmentPlan([]).then(() => {
        history.push(`/room/${roomID}/treatment-planner-goals`);
      });
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const eapSessionReportID = queryParams.get('eapSessionReportID');
    if (eapSessionReportID) {
      const eapNoteItem = notesData?.items?.find(
        (it) => it.sessionReportData?.id === Number(eapSessionReportID)
      );
      const eapSessionData = eapNoteItem?.sessionReportData;
      setEapSessionReportData(eapSessionData);
    }
  }, [location, notesData]);

  const handleCreateTreatmentPlanPress = () => {
    const { showGlobalError, formMode, ...restFormState } = formState;
    const params = { roomID, noteID, clientUserID, ...restFormState };

    if (formMode === 'create') {
      const saveProgressNoteDialogLocationState: SaveProgressNoteDialogLocationState = {
        eapSessionReport: eapSessionReportData,
        action: {
          type: 'navigate-to-treatment-planner',
          payload: currentTreatmentPlanID,
        },
      };

      history.push(
        `/room/${roomID}/progress-notes/new/save-draft-dialog?postAction=notes`,
        saveProgressNoteDialogLocationState
      );

      return;
    }

    if (formMode === 'edit') {
      if (progressNoteData?.status === 'draft') {
        saveDraftProgressNote(params, {
          onSuccess: () => {
            navigateToTreatmentPlanner();
          },
        });

        return;
      }

      if (noteID && progressNoteData?.status === 'submitted') {
        updateProgressNote(
          { ...params, noteID, clientUserID },
          {
            onSuccess: () => {
              navigateToTreatmentPlanner();
            },
          }
        );
      }
    }
  };

  const handleBuildRapportCheckboxClick = () => {
    markSectionAsIncomplete();
    setShortTermTreatmentObjectiveError(false);
    setShortTermTreatmentBuildRapportError(false);
    setTreatmentPlanProgress({
      ...treatmentPlanProgress!,
      buildRapport: !treatmentPlanProgress!.buildRapport,
    });
  };

  const handleShortTermTreatmentObjectiveChange = (value: string) => {
    markSectionAsIncomplete();
    setShortTermTreatmentObjectiveError(false);
    setShortTermTreatmentBuildRapportError(false);
    setTreatmentPlanProgress({
      ...treatmentPlanProgress!,
      shortTermTreatmentObjective: value,
    });
  };

  const handleRiskOrBarriersHandling = (value: string) => {
    markSectionAsIncomplete();
    setRiskOrBarriersHandlingError(false);
    setTreatmentPlanProgress({
      ...treatmentPlanProgress!,
      riskOrBarriersHandling: value,
    });
  };

  const handleAccordionPress = (value: boolean) => {
    setFormState({
      formSections: {
        ...formState.formSections,
        treatmentPlanProgress: {
          open: value,
          changed: formState.formSections.treatmentPlanProgress.changed,
          completed: formState.formSections.treatmentPlanProgress.completed,
        },
      },
    });
  };

  const handleConfirmPress = () => {
    let isError = false;

    if (
      !treatmentPlanProgress?.shortTermTreatmentObjective &&
      !treatmentPlanProgress?.buildRapport
    ) {
      setShortTermTreatmentObjectiveError(true);
      setShortTermTreatmentBuildRapportError(true);
      isError = true;
    }

    if (!treatmentPlanProgress?.riskOrBarriersHandling) {
      setRiskOrBarriersHandlingError(true);
      isError = true;
    }

    if (isError) {
      setFormState({ showGlobalError: true });
      return;
    }

    setFormState({
      treatmentPlanProgress,
      formSections: {
        ...formState.formSections,
        treatmentPlanProgress: {
          open: false,
          changed: true,
          completed: true,
        },
        ...(formState.formSections.sessionSummary.completed
          ? {}
          : {
              sessionSummary: {
                ...formState.formSections.sessionSummary,
                open: true,
              },
            }),
      },
    });
  };

  const { formMode } = formState;

  const textAreaStyle = getTextAreaStyle(formMode === 'view');

  return (
    <AccordionContainer
      title="Treatment plan progress"
      open={formState.formSections.treatmentPlanProgress.open}
      onPress={handleAccordionPress}
      showCheckMark={formMode !== 'view' && formState.formSections.treatmentPlanProgress.completed}
      dataQa="noTreatmentPlanAccordion"
    >
      {formMode !== 'view' && (
        <View style={{ marginBottom: 34 }}>
          <Small>Identify a short term treatment objective or </Small>
          <Standard
            variant="standardBoldGreen"
            onPress={handleCreateTreatmentPlanPress}
            style={{ cursor: 'pointer' }}
            dataQa="createTreatmentPlanButton"
          >
            create a treatment plan
          </Standard>
        </View>
      )}
      <ExtraTiny style={{ marginBottom: 12 }}>Short term treatment objectives</ExtraTiny>
      <Checkbox
        isLabelOnRight
        stretch={false}
        alignCenter={false}
        isChecked={!!treatmentPlanProgress?.buildRapport}
        setIsChecked={handleBuildRapportCheckboxClick}
        label="Build rapport and explore presenting concerns"
        isDisabled={formMode === 'view'}
        shouldDisplayError={shortTermTreatmentBuildRapportError}
        checkboxStyle={
          shortTermTreatmentBuildRapportError ? { borderColor: colors.permaDebianRed } : {}
        }
        dataQa="buildRapportCheckbox"
      />
      <TextAreaWrapper>
        <TextAreaRounded
          autoSize
          textAreaStyle={textAreaStyle}
          style={{
            ...getTextAreaContainerBorderStyle(colors, shortTermTreatmentObjectiveError),
            ...textAreaContainerStyle,
          }}
          value={treatmentPlanProgress?.shortTermTreatmentObjective || ''}
          onChangeText={handleShortTermTreatmentObjectiveChange}
          placeholder="Add a short term objective"
          disabled={formMode === 'view'}
          hasLimit
          dataQa="shortTermObjectiveTextArea"
        />
      </TextAreaWrapper>
      <ExtraTiny style={{ marginBottom: 14 }}>
        Are there any risks or barriers to completing treatment?
      </ExtraTiny>
      <TextAreaRounded
        autoSize
        textAreaStyle={textAreaStyle}
        style={{
          ...getTextAreaContainerBorderStyle(colors, riskOrBarriersHandlingError),
          width: 304,
        }}
        value={treatmentPlanProgress?.riskOrBarriersHandling || ''}
        onChangeText={handleRiskOrBarriersHandling}
        placeholder="Describe any risks or barriers and how to address them"
        disabled={formMode === 'view'}
        hasLimit
        dataQa="riskOrBarriersTextArea"
      />
      {formMode !== 'view' && !formState.formSections.treatmentPlanProgress.completed && (
        <ConfirmButton onPress={handleConfirmPress} sectionTitle="noTreatmentPlan" />
      )}
    </AccordionContainer>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    createNewTreatmentPlan: (presentingProblems: Array<any>) =>
      dispatch(treatmentPlannerActions.createNewTreatmentPlan(presentingProblems)),
  };
};

export default connect(null, mapDispatchToProps)(NoTreatmentPlan);
