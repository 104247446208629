import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { getOutcomeMeasureAnswers } from '../../../../../actions/OutcomeMeasureActions';
import './OutcomeMeasures.css';
import Submenu from '../../../../Reusable/Submenu/Submenu';

const AnswerRow = ({ order, question, answer }) => (
  <div className="om-answer-row">
    <div className="order-number" style={{ flex: 1 }}>
      {order}
    </div>
    <div className="right-content" style={{ flex: 5 }}>
      <div className="question">{question}</div>
      <div className="answer">{answer}</div>
    </div>
  </div>
);

class OutcomeMeasureAnswers extends Component {
  constructor(props) {
    super(props);
    this.getContent = this.getContent.bind(this);
  }

  componentDidMount() {
    const outcomeMeasure = this.props.location.state && this.props.location.state.outcomeMeasure;
    this.props.getOutcomeMeasureAnswers(outcomeMeasure.id);
  }

  getContent() {
    return this.props.outcomeMeasureAnswers.sections
      ? this.props.outcomeMeasureAnswers.sections
          .filter((item) => !!item.responseOptionSetID) // 0 or null is a prompt
          .map((item, i) => {
            const { order, prompt: question, currentAnswer, responseOptions } = item;
            const choiceID = currentAnswer ? currentAnswer.responseOptionID : -1;
            const answer =
              choiceID > -1
                ? responseOptions.find((a) => a.id === choiceID).prompt
                : 'Not answered';
            return <AnswerRow order={order - 1} question={question} answer={answer} key={i} />;
          })
      : [];
  }

  render() {
    const { prevRoute, ...restOfState } = this.props.location.state;
    return (
      <Submenu
        childComponents={this.getContent()}
        title="Assessment answers"
        bodyStyle={{ paddingTop: 0 }}
        prevRoute={prevRoute}
        prevState={restOfState}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    outcomeMeasureAnswers: state.outcomeMeasures.outcomeMeasureAnswers,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getOutcomeMeasureAnswers,
    },
    dispatch
  );
const OutcomeMeasureAnswersContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(OutcomeMeasureAnswers);

export default withRouter(OutcomeMeasureAnswersContainer);
