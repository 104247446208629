import { useState, useEffect } from 'react';
import { View, TextDS, StickyDrawer, Button, BaseButton, spacing } from '@talkspace/react-toolkit';
import { PhoneNotifications } from '@talkspace/react-toolkit/src/designSystems/illustrations';
import CircleUnderlay from '@talkspace/react-toolkit/src/designSystems/components/CircleUnderlay';
import styled from '@/core/styled';

const { space050, space150, space300 } = spacing;

const Container = styled(View)({ padding: '32px 20px', alignItems: 'center', textAlign: 'center' });
const TextContainer = styled(View)({ marginTop: space300 });

interface Props {
  handlePrimaryOnPress: () => void;
  handleSecondaryOnPress: () => void;
  enableSessionReminders?: (options: { onSuccess: () => void; onError: () => void }) => void;
}

const BookingUpdateNotifications = ({
  handlePrimaryOnPress,
  handleSecondaryOnPress,
  enableSessionReminders,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!enableSessionReminders) {
      handleSecondaryOnPress();
    }
  }, [enableSessionReminders, handleSecondaryOnPress]);

  if (!enableSessionReminders) {
    return <></>;
  }
  return (
    <Container>
      <CircleUnderlay size={136} colorRole="brandSubtleDefault">
        <PhoneNotifications />
      </CircleUnderlay>
      <TextContainer rowGap={space150} style={{ marginTop: space300 }}>
        <TextDS variant="headingXl">Don’t miss out on session updates</TextDS>
        <TextDS variant="body">
          Get text updates for sessions and reminders. You can unsubscribe at any time.
        </TextDS>
      </TextContainer>
      <StickyDrawer contentWrapperStyle={{ gap: space050 }} noBorder>
        <Button
          disabled={isLoading}
          onPress={() => {
            setIsLoading(true);
            enableSessionReminders({
              onSuccess: handlePrimaryOnPress,
              onError: handleSecondaryOnPress,
            });
          }}
          data-qa="bookingUpdateNotificationsConfirm"
        >
          <TextDS colorRole="buttonBrandPrimary" variant="headingMd">
            Notify me
          </TextDS>
        </Button>
        <BaseButton
          disabled={isLoading}
          onPress={handleSecondaryOnPress}
          data-qa="bookingUpdateNotificationsNotNow"
          style={{ padding: 18 }}
        >
          <TextDS colorRole="buttonBrandSecondary" variant="headingMd">
            Not now
          </TextDS>
        </BaseButton>
      </StickyDrawer>
    </Container>
  );
};

export default BookingUpdateNotifications;
