import { EMessagePreview } from './MessagePreview';

export enum RoomStatus {
  ACTIVE = 1,
  PENDING_INACTIVE = 2,
  INACTIVE = 3,
  CLOSED = 4,
  FREE_TRIAL = 5,
  NOT_RENEWING = 6,
  CANCELED = 7,
  DISABLED = 8,
  CC_PENDING = 9,
  CC_SUCCESS = 10,
  PAST_DUE = 11,
  PENDING_CC_LOCKED = 12,
  FROZEN = 13,
  FREE_TRIAL_CHATBOT = 14,
  PENDING_SUBSCRIPTION = 15,
  MBA = 16,
}

export type RoomType =
  | 'private_room'
  | 'couples_room'
  | 'peer_support_group_room'
  | 'psychiatry_room';
export type RoomTypeCamelCase = 'privateRoom' | 'couplesRoom' | 'psychiatryRoom';

export type AccountType = 'channel/broker/tpa' | 'eap' | 'bh' | 'dte' | 'b2c' | 'smb' | 'edu';

export interface Room {
  roomID: number;
  roomType: RoomType;
  therapistID: number;
  lastTherapistChange?: Date;
  expirationDate: Date;
  createdAt: Date;
  isVideoOnly: boolean;
  isSessionBased: boolean;
  isEAP: boolean;
  status: RoomStatus;
  isDischarged?: boolean;
  accountType: AccountType;
  planID: number | null;
  updatedFromRoomID?: number | null;
}

// eslint-disable-next-line import/prefer-default-export
export class ERoom implements ERoom {
  roomID: number;

  roomType: RoomType;

  therapistID: number;

  lastTherapistChange: Date | undefined;

  expirationDate: Date;

  lastMessage: EMessagePreview | null;

  lastReadAckedMessageID: number;

  createdAt: Date;

  isVideoOnly: boolean;

  isSessionBased: boolean;

  isEAP: boolean;

  status: RoomStatus;

  isDischarged?: boolean;

  accountType: AccountType;

  planID: number | null;

  updatedFromRoomID?: number | null;

  hasCompletedASession?: boolean | null;

  constructor(room: Room) {
    this.roomID = room.roomID;
    this.roomType = room.roomType;
    this.therapistID = room.therapistID;
    this.lastTherapistChange = room.lastTherapistChange && new Date(room.lastTherapistChange);
    this.expirationDate = new Date(room.expirationDate);
    this.lastMessage = null;
    this.lastReadAckedMessageID = 0;
    this.createdAt = new Date(room.createdAt);
    this.isVideoOnly = room.isVideoOnly;
    this.isSessionBased = room.isSessionBased;
    this.isEAP = room.isEAP;
    this.status = room.status;
    this.isDischarged = room.isDischarged;
    this.accountType = room.accountType;
    this.planID = room.planID;
    this.updatedFromRoomID = room.updatedFromRoomID;
    this.hasCompletedASession = null;
  }

  setHasCompletedASession(completed: boolean) {
    this.hasCompletedASession = completed;
  }
}

export function isActive(status: RoomStatus) {
  switch (status) {
    case RoomStatus.ACTIVE:
    case RoomStatus.PENDING_INACTIVE:
    case RoomStatus.NOT_RENEWING:
    case RoomStatus.CC_SUCCESS:
    case RoomStatus.PAST_DUE:
    case RoomStatus.FROZEN:
    case RoomStatus.FREE_TRIAL_CHATBOT:
    case RoomStatus.PENDING_SUBSCRIPTION:
      return true;
    case RoomStatus.INACTIVE:
    case RoomStatus.CLOSED:
    case RoomStatus.FREE_TRIAL:
    case RoomStatus.CANCELED:
    case RoomStatus.DISABLED:
    case RoomStatus.CC_PENDING:
    case RoomStatus.PENDING_CC_LOCKED:
      return false;
    default:
      // eslint-disable-next-line no-console
      console.error(`Unexpected status: ${status}`);
      return false;
  }
}
