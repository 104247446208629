import Svg, { Circle, Path } from 'svgs';

import { useEmotionTheme } from '../../core/styled';

interface Props {
  width?: number;
  height?: number;
}
const MbaCheckMark = ({ width = 131, height = 130, ...otherProps }: Props) => {
  const titleText = 'Check Mark';
  const { colors } = useEmotionTheme();
  return (
    <Svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 131 130"
      title={titleText}
      aria-label={titleText}
      {...otherProps}
    >
      <Circle cx={65.5} cy={65} r={65} fill={colors.permaLinkWaterGrey} />
      <Path
        d="M40.464 50.665a7.806 7.806 0 0 1 7.528-8.073l17.99-.628a7.806 7.806 0 0 1 8.074 7.528l.63 17.992a7.806 7.806 0 0 1-7.529 8.074l-17.992.628a7.806 7.806 0 0 1-8.073-7.528l-.628-17.993Z"
        fill={colors.white}
        stroke={colors.permaPeriwinkleGray}
        strokeWidth={5.204}
      />
      <Path
        d="M52.867 62.398a7.806 7.806 0 0 1 8.292-7.287l17.965 1.159a7.806 7.806 0 0 1 7.287 8.291l-1.158 17.966a7.806 7.806 0 0 1-8.292 7.288l-17.966-1.16a7.806 7.806 0 0 1-7.287-8.291l1.16-17.967Z"
        fill={colors.permaTalkspaceDarkGreen}
        stroke={colors.permaTalkspaceDarkGreen}
        strokeWidth={5.204}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M78.103 63.292a2.471 2.471 0 0 1 .546 3.452l-9.983 13.73-7.656-6.8a2.471 2.471 0 1 1 3.282-3.695l3.582 3.181 6.778-9.322a2.47 2.47 0 0 1 3.451-.546Z"
        fill={colors.white}
      />
    </Svg>
  );
};

export default MbaCheckMark;
