import { useQuery } from 'react-query';

import { PaymentReportsPayload } from '../types';
import apiWrapper from '../../../utils/apiWrapper';
import apiHelper from '../../../utils/api';

const paymentReportQueryKey = (therapistID: number, billingStart?: Date) => [
  therapistID,
  billingStart?.toString() || '',
];

const fetchPaymentReport =
  (therapistID: number, billingStart?: Date) => async (): Promise<PaymentReportsPayload> => {
    const { data } = await apiWrapper.get(
      `${
        apiHelper().apiEndpoint
      }/v3/therapists/${therapistID}/payment-report?billingStart=${billingStart}`
    );
    return data.data;
  };

const useQueryPaymentReports = (
  therapistID: number,
  billingStart?: Date,
  enabled: boolean = true
) =>
  useQuery<PaymentReportsPayload, Error>({
    queryKey: paymentReportQueryKey(therapistID, billingStart),
    queryFn: fetchPaymentReport(therapistID, billingStart),
    cacheTime: 0,
    staleTime: 0,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
    enabled,
  });

export default useQueryPaymentReports;
