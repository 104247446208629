import Svg, { Defs, RadialGradient, Rect, Stop } from 'svgs';

interface CalendarIconProps {
  width?: number;
  height?: number;
}

const CalendarIcon = ({ width = 21, height = 24, ...otherProps }: CalendarIconProps) => (
  <Svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <Rect
      x="1.25"
      y="1.25"
      width="21.5"
      height="21.5"
      rx="10.75"
      fill="url(#paint0_angular_1673_3334)"
      stroke="#007E73"
      stroke-width="2.5"
    />
    <Defs>
      <RadialGradient
        id="paint0_angular_1673_3334"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(12 12) rotate(-90) scale(18.5)"
      >
        <Stop stop-color="#007E73" />
        <Stop offset="0.833333" stop-color="#007E73" stop-opacity="0" />
        <Stop offset="1" stop-color="#007E73" stop-opacity="0" />
      </RadialGradient>
    </Defs>
  </Svg>
);

export default CalendarIcon;
