import styled from '../../core/styled';
import commonStyles from '../../constants/commonStyles';

import Text from '../Text';

const { visiblyHidden } = commonStyles;

const HiddenText = styled(Text)({
  ...visiblyHidden,
});

export default HiddenText;
