import { EmotionStyle } from '../../../core/styled';
import { ColorRolesVersion } from '../../types';
import {
  GetColorRoleStyle,
  GetVariantIconStyles,
  IconStyleFactory,
  GenerateActiveButtonIconStyle,
  GenerateButtonIconStyle,
  ButtonVariant,
} from './types';

export const getActiveStyles: GetColorRoleStyle = (colorRoles) => {
  return {
    backgroundColor: colorRoles.button.brandSecondarySurfacePressed,
  };
};

export const getDisabledStyles = (
  variant: ButtonVariant,
  colorRoles: ColorRolesVersion
): EmotionStyle => {
  switch (variant) {
    case 'primary':
      return {
        backgroundColor: colorRoles.button.brandPrimarySurfaceDisabled,
      };
    case 'secondary':
      return {
        backgroundColor: colorRoles.button.brandSecondarySurfaceDisabled,
        borderColor: colorRoles.button.brandSecondaryBorderDisabled,
      };
    case 'tertiary':
    default:
      return {};
  }
};

export const getButtonTextColor = ({
  variant,
  disabled,
  isActive,
  colorRoles,
}: {
  variant: ButtonVariant;
  disabled: boolean | undefined;
  isActive: boolean | undefined;
  colorRoles: ColorRolesVersion;
}): string => {
  let colorRole: string = colorRoles.button.brandPrimaryTextDefault;
  if (variant === 'primary') {
    colorRole = colorRoles.button.brandPrimaryTextDefault;
  }

  if (variant === 'secondary') {
    colorRole = colorRoles.button.brandSecondaryTextDefault;
    if (isActive) {
      colorRole = colorRoles.button.brandSecondaryTextPressed;
    }
    if (disabled) {
      colorRole = colorRoles.button.brandSecondaryTextDisabled;
    }
  }

  if (variant === 'tertiary') {
    colorRole = colorRoles.button.brandTertiaryTextDefault;
    if (isActive) {
      colorRole = colorRoles.button.brandTertiaryTextPressed;
    }
    if (disabled) {
      colorRole = colorRoles.button.brandTertiaryTextDisabled;
    }
  }

  return colorRole;
};

const getActiveIconSelector = (iconTarget) => {
  const target = iconTarget === 'left' ? 'first-of-type' : 'last-of-type';
  return `svg:${target}`;
};

const getIconSelector = (iconTarget, state) => {
  const childTarget = iconTarget === 'left' ? 'first-of-type' : 'last-of-type';
  return state === 'hover' ? `&:hover svg:${childTarget}` : `&:active svg:${childTarget}`;
};

const getTextSelector = (state) => (state === 'hover' ? '&:hover p' : '&:active p');

const getActiveTextSelector = () => '& p';

const generateButtonIconStyle: GenerateButtonIconStyle = (
  colorRoles,
  iconConfig,
  iconTarget,
  state,
  variant = 'primary'
) => {
  const baseIconSelector = getIconSelector(iconTarget, state);

  const iconStyle = Object.keys(iconConfig).reduce((acc, key) => {
    const IconSelector = `${baseIconSelector} ${key}`;
    const curStyle = iconConfig[key].reduce((obj, property) => {
      const value =
        colorRoles.button[
          `brand${variant.charAt(0).toUpperCase() + variant.slice(1)}Icon${
            state === 'hover' ? 'Hovered' : 'Pressed'
          }`
        ];

      return {
        [IconSelector]: {
          ...obj[IconSelector],
          [property]: value,
        },
      };
    }, {});

    return {
      ...acc,
      ...curStyle,
    };
  }, {});

  // TODO: rework Text and ActiveText when new nav is merged
  const baseTextSelector = getTextSelector(state);
  const textStyle = {
    [baseTextSelector]: {
      color: `${
        colorRoles.button[
          `brand${variant.charAt(0).toUpperCase() + variant.slice(1)}Text${
            state === 'hover' ? 'Hovered' : 'Pressed'
          }`
        ]
      } !important`,
    },
  };

  return { ...iconStyle, ...textStyle };
};

const generateActiveButtonIconStyle: GenerateActiveButtonIconStyle = (
  colorRoles,
  iconConfig,
  iconTarget,
  variant = 'primary'
) => {
  const baseSelector = getActiveIconSelector(iconTarget);

  const activeIconStyle = Object.keys(iconConfig).reduce((acc, key) => {
    const target = `${baseSelector} ${key}`;
    const curStyle = iconConfig[key].reduce((obj, property) => {
      const value =
        colorRoles.button[`brand${variant.charAt(0).toUpperCase() + variant.slice(1)}IconPressed`];

      return {
        [target]: {
          ...obj[target],
          [property]: value,
        },
      };
    }, {});

    return {
      ...acc,
      ...curStyle,
    };
  }, {});

  const baseActiveTextSelector = getActiveTextSelector();
  const activeTextStyles = {
    [baseActiveTextSelector]: {
      color: `${
        colorRoles.button[`brand${variant.charAt(0).toUpperCase() + variant.slice(1)}TextPressed`]
      } !important`,
    },
  };

  return { ...activeIconStyle, ...activeTextStyles };
};

export const buttonIconStyleFactory: IconStyleFactory = {
  primary: (colorRoles, iconConfig, iconTarget) => {
    return {
      ...generateButtonIconStyle(colorRoles, iconConfig, iconTarget, 'hover', 'primary'),
      ...generateButtonIconStyle(colorRoles, iconConfig, iconTarget, 'active', 'primary'),
    };
  },
  secondary: (colorRoles, iconConfig, iconTarget) => {
    return {
      ...generateButtonIconStyle(colorRoles, iconConfig, iconTarget, 'hover', 'secondary'),
      ...generateButtonIconStyle(colorRoles, iconConfig, iconTarget, 'active', 'secondary'),
    };
  },
  tertiary: (colorRoles, iconConfig, iconTarget, isActive) => {
    if (isActive) {
      return generateActiveButtonIconStyle(colorRoles, iconConfig, iconTarget, 'tertiary');
    }
    return {
      ...generateButtonIconStyle(colorRoles, iconConfig, iconTarget, 'hover', 'tertiary'),
      ...generateButtonIconStyle(colorRoles, iconConfig, iconTarget, 'active', 'tertiary'),
    };
  },
};

const getButtonIconStyles: GetVariantIconStyles = (
  variant,
  colorRoles,
  iconConfig,
  iconTarget,
  isActive
): EmotionStyle => {
  switch (variant) {
    case 'primary':
      return buttonIconStyleFactory.primary(colorRoles, iconConfig, iconTarget);
    case 'secondary':
      return buttonIconStyleFactory.secondary(colorRoles, iconConfig, iconTarget);
    case 'tertiary':
      return buttonIconStyleFactory.tertiary(colorRoles, iconConfig, iconTarget, isActive);
    default:
      return buttonIconStyleFactory.primary(colorRoles, iconConfig, iconTarget);
  }
};

export const getLeftIconStyles = (variant, colorRoles, iconConfig, isActive) =>
  getButtonIconStyles(variant, colorRoles, iconConfig, 'left', isActive);

export const getRightIconStyles = (variant, colorRoles, iconConfig, isActive) =>
  getButtonIconStyles(variant, colorRoles, iconConfig, 'right', isActive);
