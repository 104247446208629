import { FunctionComponent } from 'react';
import * as React from 'react';
import styled, { EmotionTheme } from '../../core/styled';
import { webOnlyStyle, nativeOnlyStyle } from '../../core/styleHelpers';
import Text, { TextProps } from '../Text';
import type { VariantFunction } from './types';

type Variant =
  | 'large'
  | 'largeDarkGrey'
  | 'largeLightGrey'
  | 'largeWhite'
  | 'largeMediumTSBlack'
  | 'largeMediumDarkGreen'
  | 'largeSlateGreyStrikethrough'
  | 'largeMediumDarkGreyStrikethrough'
  | 'largeMediumGrey'
  | 'largeBoldWhite'
  | 'largeBoldWide'
  | 'largeBoldWideLightGrey'
  | 'largeBoldWideGreen'
  | 'largeBoldBrightGreen'
  | 'largeBoldTSBlack'
  | 'largeBoldTSGreen'
  | 'largeGrey950';

interface LargeProps extends TextProps {
  variant?: Variant;
}

const largeDefaultStyle = (colors: EmotionTheme['colors']) => {
  return {
    fontSize: 16,
    lineHeight: '20px',
    letterSpacing: 0.1,
    fontWeight: 400,
    color: colors.black,
  };
};

const variants: Record<string, VariantFunction> = {
  default: ({ theme: { colors } }) => {
    return {
      ...largeDefaultStyle(colors),
    };
  },
  grey: ({ theme: { colors, colorRoles } }) => {
    return {
      ...largeDefaultStyle(colors),
      color: colorRoles.typography.textSubtle,
    };
  },
  lightGrey: ({ theme: { colors } }) => {
    return {
      ...largeDefaultStyle(colors),
      color: colors.placeholderGrey,
    };
  },
  white: ({ theme: { colors } }) => {
    return {
      ...largeDefaultStyle(colors),
      color: colors.white,
    };
  },
  medium: ({ theme: { colors } }) => {
    return {
      ...largeDefaultStyle(colors),
      fontWeight: 500,
      ...nativeOnlyStyle({ fontWeight: '500' }),
    };
  },
  mediumDarkGreen: ({ theme: { colors } }) => {
    return {
      ...largeDefaultStyle(colors),
      fontWeight: 500,
    };
  },
  mediumGrey: ({ theme: { colors } }) => {
    return {
      ...largeDefaultStyle(colors),
      color: colors.permaRiverBed,
      fontWeight: 500,
      ...nativeOnlyStyle({ fontWeight: '500' }),
    };
  },
  mediumStrikethrough: ({ theme: { colors } }) => {
    return {
      ...largeDefaultStyle(colors),
      color: colors.periwinkleGrey,
      fontWeight: 500,
      ...webOnlyStyle({ textDecoration: 'line-through' }),
      ...nativeOnlyStyle({ fontWeight: '500' }),
    };
  },
  mediumGreyStrikethrough: ({ theme: { colors } }) => {
    return {
      ...largeDefaultStyle(colors),
      color: colors.slateGrey,
      fontWeight: 500,
      ...webOnlyStyle({ textDecoration: 'line-through' }),
      ...nativeOnlyStyle({ fontWeight: '500' }),
    };
  },
  boldWhite: ({ theme: { colors } }) => {
    return {
      ...largeDefaultStyle(colors),
      fontWeight: 700,
      color: colors.white,
    };
  },
  boldWideBlack: ({ theme: { colors } }) => {
    return {
      ...largeDefaultStyle(colors),
      fontWeight: 700,
      letterSpacing: 0.3,
      color: colors.black,
    };
  },
  boldWideLightGrey: ({ theme: { colors } }) => {
    return {
      ...largeDefaultStyle(colors),
      fontWeight: 700,
      letterSpacing: 0.3,
      color: colors.placeholderGrey,
    };
  },
  boldWideGreen: ({ theme: { colors } }) => {
    return {
      ...largeDefaultStyle(colors),
      fontWeight: 700,
      letterSpacing: 0.3,
      color: colors.greenText,
    };
  },
  boldBrightGreen: ({ theme: { colors } }) => {
    return {
      ...largeDefaultStyle(colors),
      fontWeight: 700,
      letterSpacing: 0.3,
      color: colors.limaGreen,
    };
  },
  largeBoldTSBlack: ({ theme: { colors } }) => {
    return {
      ...largeDefaultStyle(colors),
      fontWeight: 700,
    };
  },
  largeBoldTSGreen: ({ theme: { colors } }) => {
    return {
      ...largeDefaultStyle(colors),
      fontWeight: 700,
      color: colors.permaBlueStoneNew,
    };
  },
  grey950: ({ theme: { colors } }) => {
    return {
      ...largeDefaultStyle(colors),
      color: colors.grey950,
    };
  },
};

const LargeDefault = styled(Text)(variants.default);
const LargeGrey = styled(Text)(variants.grey);
const LargeLightGrey = styled(Text)(variants.lightGrey);
const LargeWhite = styled(Text)(variants.white);
const LargeMedium = styled(Text)(variants.medium);
const LargeMediumDarkGreen = styled(Text)(variants.mediumDarkGreen);
const LargeMediumGrey = styled(Text)(variants.mediumGrey);
const LargeMediumStrike = styled(Text)(variants.mediumStrikethrough);
const LargeBoldWhite = styled(Text)(variants.boldWhite);
const LargeBoldWide = styled(Text)(variants.boldWideBlack);
const LargeBoldWideLightGrey = styled(Text)(variants.boldWideLightGrey);
const LargeBoldWideGreen = styled(Text)(variants.boldWideGreen);
const LargeBoldBrightGreen = styled(Text)(variants.boldBrightGreen);
const LargeMediumGreyStrikethrough = styled(Text)(variants.mediumGreyStrikethrough);
const LargeBoldTSBlack = styled(Text)(variants.largeBoldTSBlack);
const LargeBoldTSGreen = styled(Text)(variants.largeBoldTSGreen);
const LargeGrey950 = styled(Text)(variants.grey950);

const selectStyle = (variant: Variant | undefined, props: Omit<LargeProps, 'variant'>) => {
  switch (variant) {
    case 'large':
      return <LargeDefault {...props} />;
    case 'largeDarkGrey':
      return <LargeGrey {...props} />;
    case 'largeLightGrey':
      return <LargeLightGrey {...props} />;
    case 'largeWhite':
      return <LargeWhite {...props} />;
    case 'largeMediumTSBlack':
      return <LargeMedium {...props} />;
    case 'largeMediumDarkGreen':
      return <LargeMediumDarkGreen {...props} />;
    case 'largeMediumGrey':
      return <LargeMediumGrey {...props} />;
    case 'largeMediumDarkGreyStrikethrough':
      return <LargeMediumStrike {...props} />;
    case 'largeSlateGreyStrikethrough':
      return <LargeMediumGreyStrikethrough {...props} />;
    case 'largeBoldWhite':
      return <LargeBoldWhite {...props} />;
    case 'largeBoldWide':
      return <LargeBoldWide {...props} />;
    case 'largeBoldWideLightGrey':
      return <LargeBoldWideLightGrey {...props} />;
    case 'largeBoldWideGreen':
      return <LargeBoldWideGreen {...props} />;
    case 'largeBoldBrightGreen':
      return <LargeBoldBrightGreen {...props} />;
    case 'largeBoldTSBlack':
      return <LargeBoldTSBlack {...props} />;
    case 'largeBoldTSGreen':
      return <LargeBoldTSGreen {...props} />;
    case 'largeGrey950':
      return <LargeGrey950 {...props} />;
    default:
      return <LargeDefault {...props} />;
  }
};

// dangerouslySetInnerHTML needed to allow for premature flow configs in quickmatch-web
const Large: FunctionComponent<
  LargeProps & Pick<React.ComponentProps<typeof Text>, 'dangerouslySetInnerHTML'>
> = ({ variant, ...props }) => selectStyle(variant, props);

export default Large;
