import {
  isDummyCanceledNoMatchesProvider,
  isDummyNoMatchesProvider,
  isDummyCTReactivationProvider,
} from '../entities/Therapist';
import { RoomStatus } from '../entities/Room';

import { RequireAtLeastOne } from '../types';

interface RoomParam {
  status: RoomStatus | undefined;
}

type ProviderParam = RequireAtLeastOne<{ fullName?: string; firstName?: string }>;

interface PsychDischargedParams extends RoomParam {
  therapistFirstName?: string;
  therapistFullName?: string;
}

// This weird type ensures that using `excludeCancelledStatus` requires to pass room.
type HasRealProviderParams = {
  room?: RoomParam;
  excludeCancelledStatus?: boolean;
  provider?: ProviderParam;
} & (
  | {
      excludeCancelledStatus?: false;
      provider: ProviderParam;
    }
  | {
      excludeCancelledStatus?: true;
      room: RoomParam;
    }
);

export const isPsychDischarged = (params: PsychDischargedParams): boolean => {
  const { status, therapistFirstName, therapistFullName } = params;
  if (status) {
    const providerData = {
      // At least one of them is required, so fullName must be set
      therapistName: therapistFirstName || therapistFullName!,
      licenseText: therapistFullName,
    };
    const isDischarged =
      isDummyCanceledNoMatchesProvider(providerData) &&
      (status === RoomStatus.ACTIVE ||
        status === RoomStatus.FREE_TRIAL ||
        status === RoomStatus.CLOSED);
    return isDischarged;
  }
  return false;
};

/**
 * Returns true if the room is a Pending Subscription room.
 * This includes canceled rooms with the PLACEHOLDER PROVIDER.
 * To filter out canceled rooms, use `params.excludeCancelledStatus`
 *
 * **MUST** include either a `room` or a `provider`.
 *
 * If you are using `excludeCancelledStatus: true`,
 * then you must also include the `room`.
 */
export const isPendingSubscriptionRoom = (params: HasRealProviderParams): boolean => {
  const { room, provider, excludeCancelledStatus } = params;
  if (excludeCancelledStatus && room) {
    return room.status === RoomStatus.PENDING_SUBSCRIPTION;
  }
  if (room && room.status === RoomStatus.PENDING_SUBSCRIPTION) {
    return true;
  }
  // This handles unknown room status, or (the main reason) RoomStatus.CANCELED
  if (provider) {
    const providerData = {
      // At least one of them is required, so fullName must be set
      therapistName: provider.firstName || provider.fullName!,
      licenseText: provider.fullName,
    };
    const isRoomCanceled =
      isDummyCanceledNoMatchesProvider(providerData) &&
      (!room?.status || room.status === RoomStatus.CANCELED);
    const isNoMatchesDummyProvider =
      isDummyCTReactivationProvider(providerData) || isDummyNoMatchesProvider(providerData);
    const psychDischargedParams: PsychDischargedParams = {
      status: room?.status,
      therapistFirstName: provider.firstName,
      therapistFullName: provider.fullName,
    };
    return (
      isRoomCanceled ||
      isNoMatchesDummyProvider ||
      isPsychDischarged(psychDischargedParams) ||
      isDummyCTReactivationProvider(providerData)
    );
  }
  return false;
};

export const roomHasRealProvider = (params: HasRealProviderParams) =>
  !isPendingSubscriptionRoom(params);
