import { useMutation } from 'react-query';
import apiHelper from '@/utils/api';
import apiWrapper from '../../utils/apiWrapper';

export const generatedSessionSummaryFeedbacks = ['positive', 'negative'] as const;
export type GeneratedSessionSummaryFeedback = typeof generatedSessionSummaryFeedbacks[number];
export interface RateNoteVariables {
  feedback: GeneratedSessionSummaryFeedback | null;
  inaccurateSummary?: boolean;
  nonFormattedSummary?: boolean;
  tooManyEdits?: boolean;
  negativeFeedbackDescription?: string;
}

export interface RateNoteProps extends RateNoteVariables {
  roomID: string;
  generatedSessionSummaryID: number;
}

const rateSmartNote = async (params: RateNoteProps): Promise<void> => {
  const { roomID, generatedSessionSummaryID, ...requestBody } = params;
  await apiWrapper.patch(
    `${
      apiHelper().apiEndpoint
    }/v2/rooms/${roomID}/progress-notes/generated-session-summaries/${generatedSessionSummaryID}/feedback`,
    requestBody
  );
};

const useMutationRateSmartNote = () => useMutation<void, Error, RateNoteProps>(rateSmartNote);

export default useMutationRateSmartNote;
