import { useEffect, VoidFunctionComponent } from 'react';
import { useFlags } from 'launchDarkly/FlagsProvider';

import { ChargeType } from 'ts-frontend/types';
import { styled, View, Text, Spinner, Button, Big, Small, Link } from '@talkspace/react-toolkit';
import { trackHasPendingPayments } from 'ts-analytics/mixpanel/events';
import { ETherapistInfo } from 'ts-frontend/entities/Therapist';
import { useHistory, useLocation, useParams, useRouteMatch } from '@/core/routerLib';
import useQueryClientTransactions from '../../hooks/useQueryClientTransactions';
import TotalPendingInvoices from '../TotalPendingInvoices';
import PendingInvoicesTable from './PendingInvoicesTable';
import RemainingBalancesInfo from './RemainingBalancesInfo';

type TherapistInfo = Pick<ETherapistInfo, 'id' | 'firstName'>;

interface Props {
  clientUserID: number;
  therapist?: TherapistInfo;
}

const PendingInvoicesTitle = styled(Text)(({ theme: { colors } }) => {
  return {
    color: colors.black,
    marginTop: 30,
    fontSize: 29,
    lineHeight: '34px',
    letterSpacing: 0.1,
    fontWeight: 700,
  };
});

const Subtitle = styled(Text)(({ theme: { colors } }) => {
  return {
    color: colors.darkGray,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '20px',
    letterSpacing: 0.1,
    marginTop: 10,
  };
});

const UpdatePaymentDetailsBig = styled(Big)(({ theme: { colors } }) => {
  return {
    color: colors.white,
    fontWeight: 700,
    letterSpacing: 0.5,
  };
});

const PendingInvoicesList: VoidFunctionComponent<Props> = ({ clientUserID, therapist }) => {
  const { pendingInvoicesChargeTypes } =
    useFlags<{ pendingInvoicesChargeTypes?: Array<ChargeType> }>();
  const {
    data: pendingInvoices,
    isLoading: isPendingInvoicesLoading,
    isError: isPendingInvoicesError,
  } = useQueryClientTransactions(
    {
      chargeTypes: pendingInvoicesChargeTypes,
      clientUserID,
    },
    {
      enabled: pendingInvoicesChargeTypes && pendingInvoicesChargeTypes.length > 0,
    }
  );
  const history = useHistory();
  const match = useRouteMatch();
  const { roomID } = useParams<{ roomID: string }>();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const source = params.get('source');

  useEffect(() => {
    if (
      pendingInvoicesChargeTypes?.length === 0 ||
      pendingInvoices?.length === 0 ||
      isPendingInvoicesError
    ) {
      history.push(`/in-room-scheduling/room/${roomID}`);
    }
  }, [
    history,
    roomID,
    pendingInvoicesChargeTypes?.length,
    pendingInvoices?.length,
    isPendingInvoicesError,
  ]);

  useEffect(() => {
    if (roomID && therapist?.id && pendingInvoices && pendingInvoicesChargeTypes) {
      trackHasPendingPayments({
        userID: clientUserID,
        roomID: Number(roomID),
        providerID: therapist.id,
        enabledChargeTypes: pendingInvoicesChargeTypes,
        shownChargeTypes: pendingInvoices?.map((invoice) => invoice.chargeType),
      });
    }
  }, [clientUserID, pendingInvoices, pendingInvoicesChargeTypes, roomID, therapist?.id]);

  if (isPendingInvoicesLoading || !pendingInvoices) {
    return <Spinner />;
  }

  const subtitleText =
    source === 'updatePaymentMethodWithPendingInvoices'
      ? 'You have unpaid invoices in your account that were not processed due to a declined card on file. Update your payment details and pay these invoices today.'
      : 'We tried processing your unpaid invoices and your card was declined. Update payment details to continue booking your session.';

  return (
    <View flex={1} align="center" style={{ width: 335 }}>
      <View style={{ width: 315 }}>
        <PendingInvoicesTitle>Pending invoices</PendingInvoicesTitle>
        <Subtitle>{subtitleText}</Subtitle>
        <TotalPendingInvoices pendingInvoices={pendingInvoices} style={{ marginTop: 35 }} />
        <PendingInvoicesTable pendingInvoices={pendingInvoices} />
        {pendingInvoices.some((invoice) => invoice.chargeType === 'postSession') && (
          <RemainingBalancesInfo />
        )}
      </View>
      <Button
        dataQa="pendingInvoicesUpdatePaymentDetailsButton"
        roundedFocusStyle
        stretch
        style={{ marginTop: 64 }}
        onPress={() =>
          history.push(source ? `${match.url}/pay?${params.toString()}` : `${match.url}/pay`)
        }
      >
        <UpdatePaymentDetailsBig>Update payment details</UpdatePaymentDetailsBig>
      </Button>
      <Small style={{ marginTop: 20 }}>
        Need help?{' '}
        <Link href="https://help.talkspace.com/hc/en-us/requests/new" target="_blank">
          <Small variant="smallBolderGreen" inline>
            Contact support
          </Small>
        </Link>
      </Small>
    </View>
  );
};

export default PendingInvoicesList;
