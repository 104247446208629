import Svg, { Circle, Path } from 'svgs';

import { useEmotionTheme } from '../../core/styled';

interface Props {
  width?: number;
  height?: number;
}
const SparklingCheckMark = ({ width = 153, height = 130, ...otherProps }: Props) => {
  const titleText = 'Sparkling Check Mark';
  const { colors } = useEmotionTheme();
  return (
    <Svg
      title={titleText}
      aria-label={titleText}
      width={width}
      height={height}
      viewBox="0 0 153 130"
      fill="none"
      {...otherProps}
    >
      <Circle cx="78" cy="65" r="65" fill={colors.permaLinkWaterGrey} />
      <Path
        d="M34.6302 82.1923C35.8815 81.6088 37.369 82.1502 37.9525 83.4016C38.536 84.6529 37.9946 86.1404 36.7433 86.7239L27.6802 90.9501C26.4288 91.5336 24.9414 90.9922 24.3579 89.7408C23.7744 88.4895 24.3157 87.002 25.5671 86.4185L34.6302 82.1923Z"
        fill={colors.permaZiggurat}
      />
      <Path
        d="M35.534 90.0462C36.1176 91.2975 35.5762 92.785 34.3248 93.3685C33.0735 93.952 31.586 93.4106 31.0025 92.1593L26.7763 83.0962C26.1928 81.8449 26.7342 80.3574 27.9855 79.7739C29.2369 79.1904 30.7243 79.7318 31.3079 80.9831L35.534 90.0462Z"
        fill={colors.permaZiggurat}
      />
      <Circle cx="145.5" cy="23.5" r="4.5" stroke={colors.permaConiferGreen} strokeWidth="5" />
      <Circle cx="11" cy="42" r="8" stroke={colors.permaLavenderBlue} strokeWidth="6" />
      <Path
        d="M55.1171 38.1433L85.2807 37.09C89.5888 36.9396 93.2033 40.3099 93.354 44.618L94.4095 74.7843C94.5603 79.0929 91.1896 82.7078 86.881 82.8582L56.7153 83.9116C52.407 84.0621 48.7924 80.6915 48.642 76.3831L47.5885 46.2167C47.4381 41.9084 50.8087 38.2938 55.1171 38.1433Z"
        fill="white"
        stroke={colors.permaPeriwinkleGray}
        strokeWidth="5.2038"
      />
      <Path
        d="M72.5019 54.2665L102.621 56.2097C106.923 56.4872 110.186 60.1993 109.908 64.5012L107.967 94.6235C107.69 98.9257 103.977 102.189 99.6751 101.911L69.5536 99.9677C65.2516 99.6902 61.9891 95.9777 62.2666 91.6757L64.2099 61.5535C64.4874 57.2515 68.1999 53.989 72.5019 54.2665Z"
        fill={colors.permaTalkspaceDarkGreen}
        stroke={colors.permaTalkspaceDarkGreen}
        strokeWidth="5.2038"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M97.4572 65.9214C98.9157 66.9818 99.2384 69.0239 98.178 70.4824L84.9868 88.6255L74.8699 79.6391C73.5217 78.4415 73.3995 76.3778 74.5971 75.0295C75.7946 73.6813 77.8584 73.5592 79.2066 74.7568L83.9397 78.9609L92.8962 66.6422C93.9566 65.1837 95.9987 64.861 97.4572 65.9214Z"
        fill="white"
      />
    </Svg>
  );
};

export default SparklingCheckMark;
