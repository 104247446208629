import Svg, { Path } from 'svgs';
/* SVGR has dropped some elements not supported by react-native-svg: filter */
import { useEmotionTheme } from '../../core/styled';

const Check = (props) => {
  const { colorName = 'white' } = props;
  const { colors } = useEmotionTheme();
  const titleText = 'check';
  return (
    <Svg
      width="14px"
      height="11px"
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 14 11"
      {...props}
    >
      <Path
        d="M962.952 1338.843a1.299 1.299 0 10-1.904 1.766l4.95 5.339 6.985-8.1a1.299 1.299 0 00-1.966-1.696l-5.09 5.9-2.975-3.209z"
        transform="translate(-960 -1335)"
        fill={colors[colorName]}
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </Svg>
  );
};

export default Check;
