import { useRef, useEffect } from 'react';
import styled, { EmotionStyle } from '../../../core/styled';
import View from '../../../components/View';
import { TextDSVariant } from '../typography/TextDS';

const SHIMMER_LENGTH = 1000;
const SHIMMER_MS = 4000;

const SHIMMER_KEYFRAME_ARRAY = [
  {
    backgroundPosition: `-${SHIMMER_LENGTH}px 0`,
  },
  {
    backgroundPosition: `${SHIMMER_LENGTH}px 0`,
  },
];

const SHIMMER_ANIMATION_TIMING = {
  duration: SHIMMER_MS,
  iterations: Infinity,
};

const Placeholder = styled(View)(({ theme: { colorRoles } }) => {
  const c1 = colorRoles.surfaces.defaultSubtleDisabled;
  const c2 = '#d2d2d2';
  const p1 = 4;
  const p2 = 25;
  const p3 = 36;
  return {
    background: `linear-gradient(to right, ${c1} ${p1}%, ${c2} ${p2}%, ${c1} ${p3}%)`,
    backgroundSize: `${SHIMMER_LENGTH}px 100%`,
    borderRadius: 10,
    height: 16,
    width: 100,
  };
});

type SkeletonVariant =
  | 'IconButton'
  | 'Icon16'
  | 'Icon20'
  | 'Icon32'
  | 'Icon60'
  | 'Icon64'
  | 'ButtonSlim'
  | 'Button'
  | 'ButtonWide'
  | 'StatusTag'
  | 'Avatar';

interface SkeletonLoaderProps {
  variant?: SkeletonVariant | TextDSVariant;
  style?: EmotionStyle;
  wrapperStyle?: EmotionStyle;
}

const getStyle = (variant?: SkeletonVariant | TextDSVariant): EmotionStyle => {
  switch (variant) {
    case 'Icon16':
      return { width: 16, height: 16 };
    case 'Icon20':
      return { width: 20, height: 20 };
    case 'Icon32':
      return { width: 32, height: 32 };
    case 'Icon60':
      return { width: 60, height: 60 };
    case 'Icon64':
      return { width: 64, height: 64 };
    case 'IconButton':
      return { width: 32, height: 32 };
    case 'Button':
      return { width: 100, height: 40 };
    case 'ButtonSlim':
      return { width: 130, height: 32 };
    case 'ButtonWide':
      return { width: '100%', height: 40 };
    case 'StatusTag':
      return { width: 60, height: 20, borderRadius: 30 };
    case 'Avatar':
      return { width: 60, height: 60, borderRadius: 30 };
    case 'heading3xl':
      return { width: '100%', height: 44, borderRadius: 12 };
    case 'heading2xl':
      return { width: '100%', height: 21, borderRadius: 12 };
    case 'headingXl':
      return { width: '100%', height: 18, borderRadius: 12 };
    case 'headingLg':
      return { width: '100%', height: 14, borderRadius: 12 };
    case 'headingMd':
      return { width: '100%', height: 12, borderRadius: 12 };
    case 'headingSm':
      return { width: '100%', height: 10, borderRadius: 12 };
    case 'headingXs':
      return { width: '100%', height: 9, borderRadius: 12 };
    case 'body':
      return { width: '100%', height: 12, borderRadius: 12 };
    case 'bodySm':
      return { width: '100%', height: 11, borderRadius: 12 };
    case 'bodyXs':
      return { width: '100%', height: 9, borderRadius: 12 };
    default:
      return {};
  }
};

const SkeletonLoader = ({ variant, style, wrapperStyle }: SkeletonLoaderProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const placeholderStyle = getStyle(variant);
  useEffect(() => {
    if (ref.current) {
      const box = ref.current.getBoundingClientRect();
      const percentOffset = Math.round((box.x / SHIMMER_LENGTH) * 100);
      ref.current.animate(SHIMMER_KEYFRAME_ARRAY, SHIMMER_ANIMATION_TIMING);
      const animation = ref.current.getAnimations()[0];
      animation.currentTime = ((SHIMMER_MS * 2) / 100) * percentOffset;
    }
  }, []);

  return (
    <View style={wrapperStyle}>
      <Placeholder ref={ref} style={{ ...placeholderStyle, ...style }} />
    </View>
  );
};

export default SkeletonLoader;
