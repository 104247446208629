import { TherapistTimeslot } from 'ts-frontend/types';

export type TIMESLOTS_ARROW_DIR = 'LEFT' | 'RIGHT' | 'UP' | 'DOWN';

const getNewColumnIndex = (
  dir: TIMESLOTS_ARROW_DIR,
  currentColumnIndex: number,
  maxColumns: number
): number => {
  switch (dir) {
    case 'LEFT':
      return currentColumnIndex === 0 ? currentColumnIndex : currentColumnIndex - 1;
    case 'RIGHT':
      return currentColumnIndex === maxColumns ? currentColumnIndex : currentColumnIndex + 1;
    case 'UP':
    case 'DOWN':
    default:
      return currentColumnIndex;
  }
};
const getNewRowIndex = (
  dir: TIMESLOTS_ARROW_DIR,
  currentRowIndex: number,
  maxRows: number
): number => {
  switch (dir) {
    case 'UP':
      return currentRowIndex === 0 ? currentRowIndex : currentRowIndex - 1;
    case 'DOWN':
      return currentRowIndex === maxRows ? currentRowIndex : currentRowIndex + 1;
    case 'LEFT':
    case 'RIGHT':
    default:
      return currentRowIndex;
  }
};

export const useA11y = (
  timeslotColumns: {
    timeslots: TherapistTimeslot[];
    title: string;
  }[],
  arrayOfTimeslotColumnsLenghts: number[],
  timeslotIDPrefix: string,
  getTimeslotID: (columnIndex: number, rowIndex: number, timeslotPrefixID: string) => string
) => {
  const getNextFocusableElement = (
    dir: TIMESLOTS_ARROW_DIR,
    columnIndex: number,
    rowIndex: number
  ): HTMLElement | null => {
    const newColumnIndex = getNewColumnIndex(dir, columnIndex, timeslotColumns.length - 1);
    const newRowIndex = getNewRowIndex(
      dir,
      rowIndex,
      timeslotColumns[newColumnIndex].timeslots.length - 1
    );
    if (timeslotColumns[newColumnIndex].timeslots[newRowIndex]) {
      return document.getElementById(getTimeslotID(newColumnIndex, newRowIndex, timeslotIDPrefix));
    }
    return document.getElementById(getTimeslotID(columnIndex, rowIndex, timeslotIDPrefix));
  };

  const arrowHandlers = (dir: TIMESLOTS_ARROW_DIR, columnIndex: number, rowIndex: number) => {
    const nextFocusableElement = getNextFocusableElement(dir, columnIndex, rowIndex);
    if (nextFocusableElement) nextFocusableElement.focus();
  };

  const firstSelectableElementColumnIndex = arrayOfTimeslotColumnsLenghts.findIndex(
    (length) => length > 0
  );

  return { arrowHandlers, firstSelectableElementColumnIndex };
};

export default useA11y;
