import { ReactNode, VoidFunctionComponent } from 'react';

import styled, { EmotionStyle, useEmotionTheme } from '../../core/styled';
import { useUniqueID } from '../../hooks/a11yHelper';
import CollapsibleSection from './CollapsibleSection';
import View from '../View';
import { Large } from '../Typography';

export interface FAQQuestion {
  answer: ReactNode;
  question: string;
}

interface Props {
  questions: FAQQuestion[];
  style?: EmotionStyle;
  questionsDivider?: boolean;
  questionsTitleStyle?: EmotionStyle;
}

const Divider = styled(View)(({ theme: { colors } }) => {
  return {
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.permaMystic,
    borderTop: 1,
    marginTop: 5,
  };
});

const FrequentlyAskedQuestions: VoidFunctionComponent<Props> = ({
  questions,
  style,
  questionsDivider = false,
  questionsTitleStyle = {},
}) => {
  const freqAnswerID = useUniqueID('freqAnswerID');
  const { colors } = useEmotionTheme();
  return (
    <View
      style={{
        ...style,
        paddingLeft: 20,
        paddingRight: 20,
      }}
    >
      <Large style={{ color: colors.slateGrey }}>Frequently asked questions</Large>
      {questions.map((question, i) => (
        <View key={question.question}>
          <CollapsibleSection
            title={question.question}
            ariaControlsID={`${freqAnswerID}-${i}`}
            titleStyle={questionsTitleStyle}
          >
            {question.answer}
          </CollapsibleSection>
          {questionsDivider && i !== questions.length - 1 && <Divider />}
        </View>
      ))}
    </View>
  );
};

export default FrequentlyAskedQuestions;
