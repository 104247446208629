import Svg, { Path } from 'svgs';
import { EmotionStyle } from '../../core/styled';

interface ExclamationSquareProps {
  width?: number;
  height?: number;
  color?: string;
  titleText?: string;
  style?: EmotionStyle;
}

const CircleError = ({
  width = 20,
  height = 20,
  titleText = 'CircleError',
  color,
  ...otherProps
}: ExclamationSquareProps) => (
  <Svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    title={titleText}
    aria-label={titleText}
    {...otherProps}
  >
    <g clipPath="url(#clip0_702_17374)">
      <Path
        d="M10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20ZM7.27734 5.33203L10 8.54688L12.7227 5.33203C13.0586 4.9375 13.6484 4.88672 14.043 5.22266C14.4375 5.55859 14.4883 6.14844 14.1523 6.54297L11.2266 10L14.1523 13.457C14.4883 13.8516 14.4375 14.4453 14.043 14.7773C13.6484 15.1094 13.0547 15.0625 12.7227 14.668L10 11.4531L7.27734 14.668C6.94141 15.0625 6.35156 15.1133 5.95703 14.7773C5.5625 14.4414 5.51172 13.8516 5.84766 13.457L8.77344 10L5.84766 6.54297C5.51172 6.14844 5.5625 5.55469 5.95703 5.22266C6.35156 4.89062 6.94531 4.9375 7.27734 5.33203Z"
        fill="#CF005F"
      />
    </g>
    <defs>
      <clipPath id="clip0_702_17374">
        <rect width={width} height={height} fill="white" />
      </clipPath>
    </defs>
  </Svg>
);

export default CircleError;
