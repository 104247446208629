import apiWrapper from '../../../../utils/apiWrapper';
import apiHelper from '../../../../utils/api';

export interface WeeklyStatsDTO {
  weekStart: string;
  weekEnd: string;

  totalLiveCallHours: string;
  liveCallHours: string;
  noShowsAndLateCancellationsHours: string;

  totalMessagingHours: string;
  providerTotalMessagingHoursCapped: string;
  providerTotalMessagingWordsCapped: string;
  providerTotalMessagingWordsUncapped: string;
  clientTotalMessagingHours: string;
  clientTotalMessagingWords: string;
  providerAVHours: string;
  clientAVHours: string;

  paidTimeOffHours: string;

  totalHours: string;

  upcomingLive: string;
  expectedMessaging: string;
  upcomingPaidTimeOff: string;
}

export interface QuarterOverviewDTO {
  weeksLeft: number;
  lastDay: string;
  average: string;
}

export interface PotentialBonusDTO {
  amount: number;
  threshold: number;
  requiredAverage: number;
}

export interface BonusInfoDTO {
  currentBonus: number;
  potentialBonus: PotentialBonusDTO | null;
}

interface BonusThresholdDTO {
  amount: number;
  lowerThreshold: number;
  upperThreshold: number;
}

export interface ClinicalHoursDTO {
  lastUpdatedAt: string | null;
  weeklyStats: WeeklyStatsDTO[];
  quarterOverview?: QuarterOverviewDTO;
  bonusInfo?: BonusInfoDTO;
  weeklyHoursGoal: number;
  bonusThresholds: BonusThresholdDTO[];
}

export interface GetClinicalHoursOptions {
  anchorDate: string;
  numberOfWeeks: number;
}

export const getClinicalHours = async (
  therapistID: number,
  { anchorDate, numberOfWeeks }: GetClinicalHoursOptions
): Promise<ClinicalHoursDTO> => {
  const { data } = (await apiWrapper.get(
    `${
      apiHelper().apiEndpoint
    }/v3/therapists/${therapistID}/clinical-hours?anchorDate=${anchorDate}&numberOfWeeks=${numberOfWeeks}`
  )) as { data: { data: ClinicalHoursDTO } };

  return data.data;
};
