import { useMutation, useQueryClient } from 'react-query';
import apiHelper from '@/utils/api';
import apiWrapper from '../../utils/apiWrapper';
import { notesListQueryKey } from './queryKeys';

interface DeleteCollateralNoteMutateVariables {
  roomID: string;
  noteID: string;
  isDraft: boolean;
}

const deleteCollateralNote = async (params: DeleteCollateralNoteMutateVariables): Promise<void> => {
  const { roomID, noteID, isDraft } = params;
  const url = isDraft
    ? `${apiHelper().apiEndpoint}/v2/rooms/${roomID}/collateral-notes/${noteID}/draft`
    : `${apiHelper().apiEndpoint}/v2/rooms/${roomID}/collateral-notes/${noteID}`;
  await apiWrapper.delete(url);
};

const useMutationDeleteCollateralNote = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, DeleteCollateralNoteMutateVariables>(deleteCollateralNote, {
    onSuccess: (_, { roomID }) => {
      queryClient.invalidateQueries(notesListQueryKey(roomID));
    },
  });
};

export default useMutationDeleteCollateralNote;
