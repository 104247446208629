import { useEffect } from 'react';
import useFlagsSafe from '../../hooks/useFlagsSafe';

interface HtmlMetaManagerProps {
  faviconLinkID?: string;
}

const HtmlMetaManager = ({ faviconLinkID = 'app-favicon-link' }: HtmlMetaManagerProps) => {
  const { brandRefreshChanges } = useFlagsSafe();
  useEffect(() => {
    if (brandRefreshChanges) {
      const faviconLink = document.getElementById(faviconLinkID) as HTMLAnchorElement | null;
      if (faviconLink) {
        faviconLink.href = '/favicon-2024-32.ico';
      }
    }
  });
  return null;
};

export default HtmlMetaManager;
