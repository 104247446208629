import { useState, useEffect, ChangeEventHandler, forwardRef } from 'react';
import * as React from 'react';
import commonStyles from '../../constants/commonStyles';
import styled, { EmotionStyle } from '../../core/styled';
import { webOnlyStyle } from '../../core/styleHelpers';
import useShareForwardedRef from '../../hooks/useShareForwardedRef';
import Input from '../Input';
import View from '../View';

const { crossBrowserFocusRing } = commonStyles;

const StyledInput = styled(Input)<{ disabled?: boolean; isError?: boolean }>(
  ({ disabled, isError, theme: { colors } }) => {
    return {
      height: 50,
      backgroundColor: disabled ? colors.permaLinkWaterGrey : undefined,
      borderRadius: 10,
      borderStyle: 'solid',
      borderColor: isError ? colors.red : colors.permaLividBlueNew,
      borderWidth: 1,
      color: disabled ? colors.periwinkleGreyText : undefined,
      padding: 20,
      WebkitAppearance: 'none',
      ...webOnlyStyle({
        boxSizing: 'border-box',
        ':focus': {
          outline: 'none',
          caretColor: colors.green,
        },
        '::placeholder': {
          color: colors.permaWaikawaGreyNew,
        },
      }),
    };
  }
);

const InputWrapper = styled(View)<{
  clicked?: boolean;
  outlineOffset?: number;
  style?: EmotionStyle;
}>(({ style, clicked, outlineOffset }) => {
  return {
    ...webOnlyStyle({
      '&:hover:focus-within': {
        outline: 'none',
      },
      ':focus-within': clicked
        ? { outline: 'none' }
        : {
            ...crossBrowserFocusRing,
            outlineOffset: outlineOffset || 0,
          },
    }),
    ...style,
  };
});

interface Props extends Omit<React.ComponentPropsWithoutRef<typeof StyledInput>, 'onChange'> {
  id?: string;
  type?: string;
  onChange?: (value: string) => void;
  onChangeEvent?: ChangeEventHandler;
  name?: string;
  value: string;
  placeholder?: string;
  style?: EmotionStyle;
  wrapperStyle?: EmotionStyle;
  maxLength?: number;
  size?: number;
  isError?: boolean;
  pattern?: string;
  outlineOffset?: number;
  ariaLabel?: string;
  ariaDescribedBy?: string;
  ariaRequired?: boolean;
  ariaInvalid?: boolean;
  shouldFocus?: boolean;
  max?: string;
  min?: string;
  dataQa?: string;
}

const TSInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      id,
      type = 'text',
      onChange,
      onChangeEvent,
      onBlur,
      value,
      placeholder,
      outlineOffset,
      disabled = false,
      ariaLabel,
      ariaDescribedBy,
      ariaRequired,
      ariaInvalid,
      onFocus,
      shouldFocus,
      style = {},
      wrapperStyle,
      dataQa,
      ...otherProps
    },
    ref
  ) => {
    const [clicked, setClicked] = useState(false);
    const onClick = () => {
      setClicked(true);
      return null;
    };

    const handleBlur = (e) => {
      if (onBlur) {
        onBlur(e);
      }
      setClicked(false);
    };

    const inputRef = useShareForwardedRef(ref);
    useEffect(() => {
      if (shouldFocus && inputRef.current) {
        inputRef.current.focus();
      }
    }, [inputRef, shouldFocus]);

    return (
      <InputWrapper
        style={{
          ...style,
          ...wrapperStyle,
        }}
        clicked={clicked}
        outlineOffset={outlineOffset}
      >
        <StyledInput
          data-qa={dataQa}
          id={id}
          ref={inputRef}
          aria-label={ariaLabel}
          aria-describedby={ariaDescribedBy}
          aria-required={ariaRequired}
          aria-invalid={ariaInvalid}
          type={type}
          onClick={onClick}
          onChangeValue={onChange}
          onChange={onChangeEvent}
          onBlur={handleBlur}
          onFocus={onFocus}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          style={style}
          {...otherProps}
        />
      </InputWrapper>
    );
  }
);

export default TSInput;
