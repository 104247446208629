import Svg, { G, Path } from 'svgs';
import { useEmotionTheme } from '../../core/styled';

function GreenCircleCheckMark({ width = 66, height = 66, ...otherProps }) {
  const { colors } = useEmotionTheme();
  const titleText = 'success';

  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 66 66"
      {...otherProps}
    >
      <G
        transform="translate(2 1)"
        stroke={colors.green}
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
      >
        <Path
          d="M49.663 7.242C35.99-3.06 16.553-.33 6.249 13.344c-10.304 13.673-7.572 33.11 6.101 43.414 13.674 10.303 33.11 7.572 43.414-6.102 10.304-13.673 7.572-33.11-6.101-43.414h0z"
          strokeWidth={4}
          strokeDasharray={177}
        />
        <Path
          strokeWidth={5}
          d="M13.2163737 28.917432L27.8804066 45.5049888 56.1831711 13.1403152"
        />
      </G>
    </Svg>
  );
}

export default GreenCircleCheckMark;
