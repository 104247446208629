import Svg, { Path, Rect } from 'svgs';

interface CaseViewCalendarIconProps {
  width?: number;
  height?: number;
}

const CaseViewCalendarIcon = ({ width = 16, height = 17 }: CaseViewCalendarIconProps) => (
  <Svg width={width} height={height} viewBox="0 0 16 17" fill="none">
    <Path
      d="M.75 5A2.25 2.25 0 013 2.75h10A2.25 2.25 0 0115.25 5v9A2.25 2.25 0 0113 16.25H3A2.25 2.25 0 01.75 14V5z"
      stroke="#68768D"
      strokeWidth={1.5}
    />
    <Path fill="#68768D" d="M1 3H15V6H1z" />
    <Rect x={11} width={2} height={5} rx={1} fill="#68768D" />
    <Rect x={3} width={2} height={5} rx={1} fill="#68768D" />
    <Path fill="#68768D" d="M9 10H13V14H9z" />
  </Svg>
);

export default CaseViewCalendarIcon;
