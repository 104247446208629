import moment from 'moment';
import { Nullable, VideoCredit } from '../types';

// * * This might expand to more plans
export enum ReminderStatus {
  INTRO_SESSION = 'INTRO_SESSION',
  NO_SESSION = 'NO_SESSION',
  NEVER_SCHEDULED_1LVS = 'NEVER_SCHEDULED_1LVS',
  HAS_SCHEDULED_NONESCHEDULED_1LVS = 'HAS_SCHEDULED_NONESCHEDULED_1LVS',
  HAS_SCHEDULED_1SCHEDULED_1LVS = 'HAS_SCHEDULED_1SCHEDULED_1LVS',
  NEVER_SCHEDULED_NONESCHEDULED_4LVS = 'NEVER_SCHEDULED_NONESCHEDULED_4LVS ',
  HAS_SCHEDULED_NONESCHEDULED_4LVS = 'HAS_SCHEDULED_NONESCHEDULED_4LVS',
  HAS_SCHEDULED_XSCHEDULED_4LVS = 'HAS_SCHEDULED_XSCHEDULED_4LVS',
  HAS_SCHEDULED_3SCHEDULED_4LVS = 'HASSCHEDULED_3SCHEDULED_4LVS',
  HAS_SCHEDULED_4SCHEDULED_4LVS = ' HAS_SCHEDULED_4SCHEDULED_4LVS',
  SCHEDULED_INITIAL_EVALUATION = 'SCHEDULED_INITIAL_EVALUATION',
  SCHEDULED_FOLLOW_UP = 'SCHEDULED_FOLLOW_UP',
  NEVER_SCHEDULE_INITIAL_EVALUATION = 'NEVER_SCHEDULE_INITIAL_EVALUATION',
  NEVER_SCHEDULE_FOLLOW_UP = 'NEVER_SCHEDULE_FOLLOW_UP', // in between x amount of days
  NEVER_SCHEDULE_FOLLOW_UP_LONGTIME = 'NEVER_SCHEDULE_FOLLOW_UP_LONGTIME', // exceed x amount of days
  NO_AVAILABLE_PSYCH_CREDIT = 'NO_AVAILABLE_PSYCH_CREDIT',
}

export interface TherapistReminders {
  introSessionCompletedAt: Nullable<string>;
  introSessionNextStartTime: Nullable<string>;
  therapySessionLastCompletedAt: Nullable<string>;
  therapySessionNextStartTime: Nullable<string>;
  therapySessionScheduledAt: Nullable<string>;
  onDashboard?: boolean;
  videoCredits?: VideoCredit[];
}

export interface PsychiatryReminders {
  psychiatricEvaluationNextStartTime: Nullable<string>;
  psychiatryFollowUpNextStartTime: Nullable<string>;
  psychiatricEvaluationCompletedAt: Nullable<string>;
  psychiatryFollowUpLastCompletedAt: Nullable<string>;
  videoCredits?: VideoCredit[];
}

const curriedCreditType = (data?: VideoCredit[]) => (type: string) =>
  data ? data.find((credit) => credit.type === type) : undefined;

const getTherapistReminders = ({
  introSessionCompletedAt,
  introSessionNextStartTime,
  therapySessionLastCompletedAt,
  therapySessionNextStartTime,
  therapySessionScheduledAt,
  videoCredits,
  onDashboard = false,
}: TherapistReminders): { status: ReminderStatus; date?: string; remaining?: number } => {
  let status: ReminderStatus;
  let date: string | undefined;
  let remaining: number | undefined;

  const getCreditType = (type: string) =>
    videoCredits &&
    videoCredits.find(
      (credit) =>
        credit.type === type && (credit.type === 'introduction' || credit.creditTotal !== null)
    );
  const getExpirationDate = (type: string, total: number, available?: number) => {
    const creditObj = available
      ? videoCredits?.find(
          (credit) =>
            credit.type === type &&
            credit.creditTotal === total &&
            credit.creditAvailable === available
        )
      : videoCredits?.find((credit) => credit.type === type && credit.creditTotal === total);
    return creditObj ? moment(creditObj.validTo).format('MMM D') : undefined;
  };

  const getCreditsAvailable = (type: string): undefined | number =>
    getCreditType(type)?.creditAvailable;
  const getCreditTotal = (type: string): undefined | number => getCreditType(type)?.creditTotal;
  const hasNoIntroCredit =
    !getCreditType('introduction') &&
    introSessionCompletedAt === null &&
    introSessionNextStartTime === null;

  if (onDashboard) {
    switch (true) {
      // DASHBOARD ONLY CONDITIONS
      case introSessionCompletedAt === null &&
        introSessionNextStartTime === null &&
        therapySessionLastCompletedAt === null &&
        therapySessionNextStartTime === null &&
        !!getCreditType('introduction'): {
        status = ReminderStatus.INTRO_SESSION;
        date = getExpirationDate('therapy', 1);
        break;
      }

      case getCreditTotal('therapy') === 1 &&
        getCreditsAvailable('therapy') === 0 &&
        moment().isBefore(moment(therapySessionScheduledAt).add(72, 'hours')): {
        status = ReminderStatus.HAS_SCHEDULED_1SCHEDULED_1LVS;
        break;
      }

      case (introSessionCompletedAt || introSessionNextStartTime || hasNoIntroCredit) &&
        therapySessionLastCompletedAt === null &&
        therapySessionNextStartTime === null &&
        getCreditTotal('therapy') === 1 &&
        getCreditsAvailable('therapy') === 1: {
        status = ReminderStatus.NEVER_SCHEDULED_1LVS;
        break;
      }

      case (introSessionCompletedAt || hasNoIntroCredit) &&
        getCreditTotal('therapy') === 1 &&
        getCreditsAvailable('therapy') === 1: {
        status = ReminderStatus.HAS_SCHEDULED_NONESCHEDULED_1LVS;
        break;
      }

      case (introSessionCompletedAt || introSessionNextStartTime || hasNoIntroCredit) &&
        therapySessionLastCompletedAt === null &&
        therapySessionNextStartTime === null &&
        getCreditTotal('therapy') === 4 &&
        getCreditsAvailable('therapy') === 4: {
        status = ReminderStatus.NEVER_SCHEDULED_NONESCHEDULED_4LVS;
        break;
      }

      case (introSessionCompletedAt || hasNoIntroCredit) &&
        getCreditTotal('therapy') === 4 &&
        [1, 2, 3, 4].includes(getCreditsAvailable('therapy') ?? -1): {
        status = ReminderStatus.HAS_SCHEDULED_XSCHEDULED_4LVS;
        break;
      }

      case getCreditTotal('therapy') === 4 &&
        getCreditsAvailable('therapy') === 0 &&
        moment().isBefore(moment(therapySessionScheduledAt).add(72, 'hours')): {
        status = ReminderStatus.HAS_SCHEDULED_4SCHEDULED_4LVS;
        break;
      }

      default: {
        status = ReminderStatus.NO_SESSION;
        date = getExpirationDate('therapy', 1);
        break;
      }
      // END OF DASHBOARD ONLY CONDITIONS
    }
  } else {
    // HANDLES IN-ROOM-DETAIL CONDITIONS
    switch (true) {
      case introSessionCompletedAt === null &&
        introSessionNextStartTime === null &&
        therapySessionLastCompletedAt === null &&
        therapySessionNextStartTime === null &&
        !!getCreditType('introduction'): {
        status = ReminderStatus.INTRO_SESSION;
        date = getExpirationDate('therapy', 1);
        break;
      }

      case (introSessionCompletedAt || introSessionNextStartTime || hasNoIntroCredit) &&
        therapySessionLastCompletedAt === null &&
        therapySessionNextStartTime === null &&
        getCreditsAvailable('therapy') === 1 &&
        getCreditTotal('therapy') === 1: {
        status = ReminderStatus.NEVER_SCHEDULED_1LVS;
        date = getExpirationDate('therapy', 1);
        break;
      }

      case therapySessionLastCompletedAt !== null &&
        therapySessionNextStartTime === null &&
        getCreditTotal('therapy') === 1 &&
        getCreditsAvailable('therapy') === 1: {
        status = ReminderStatus.HAS_SCHEDULED_NONESCHEDULED_1LVS;
        date = getExpirationDate('therapy', 1);
        break;
      }

      case therapySessionLastCompletedAt !== null &&
        therapySessionNextStartTime === null &&
        getCreditTotal('therapy') === 1 &&
        getCreditsAvailable('therapy') === 0: {
        status = ReminderStatus.HAS_SCHEDULED_1SCHEDULED_1LVS;
        date = getExpirationDate('therapy', 1);
        break;
      }

      case (introSessionCompletedAt || introSessionNextStartTime || hasNoIntroCredit) &&
        therapySessionLastCompletedAt === null &&
        therapySessionNextStartTime === null &&
        getCreditTotal('therapy') === 4 &&
        getCreditsAvailable('therapy') === 4: {
        status = ReminderStatus.NEVER_SCHEDULED_NONESCHEDULED_4LVS;
        date = getExpirationDate('therapy', 4, 4);
        break;
      }

      case therapySessionLastCompletedAt !== null &&
        therapySessionNextStartTime === null &&
        getCreditTotal('therapy') === 4 &&
        getCreditsAvailable('therapy') === 4: {
        status = ReminderStatus.HAS_SCHEDULED_NONESCHEDULED_4LVS;
        date = getExpirationDate('therapy', 4);
        break;
      }

      case therapySessionLastCompletedAt !== null &&
        therapySessionNextStartTime === null &&
        getCreditTotal('therapy') === 4 &&
        (getCreditsAvailable('therapy') === 2 || getCreditsAvailable('therapy') === 3): {
        status = ReminderStatus.HAS_SCHEDULED_XSCHEDULED_4LVS;
        date = getExpirationDate('therapy', 4);
        remaining = getCreditsAvailable('therapy');
        break;
      }

      case therapySessionLastCompletedAt !== null &&
        therapySessionNextStartTime === null &&
        getCreditTotal('therapy') === 4 &&
        getCreditsAvailable('therapy') === 1: {
        status = ReminderStatus.HAS_SCHEDULED_3SCHEDULED_4LVS;
        date = getExpirationDate('therapy', 4);
        break;
      }

      case therapySessionLastCompletedAt !== null &&
        therapySessionNextStartTime === null &&
        getCreditTotal('therapy') === 4 &&
        getCreditsAvailable('therapy') === 0: {
        status = ReminderStatus.HAS_SCHEDULED_4SCHEDULED_4LVS;
        date = getExpirationDate('therapy', 4);
        break;
      }

      default: {
        status = ReminderStatus.NO_SESSION;
        date = getExpirationDate('therapy', 1);
        break;
      }
    }
  }

  return { status, date, remaining };
};

const getPsychiatryReminders = ({
  psychiatricEvaluationNextStartTime,
  psychiatryFollowUpNextStartTime,
  psychiatricEvaluationCompletedAt,
  psychiatryFollowUpLastCompletedAt,
  videoCredits,
}: PsychiatryReminders): { status: ReminderStatus; remaining?: number } => {
  let status: ReminderStatus;
  let remaining: number | undefined;

  switch (true) {
    case !videoCredits?.length && psychiatricEvaluationCompletedAt: {
      status = ReminderStatus.NO_AVAILABLE_PSYCH_CREDIT;
      break;
    }
    case moment(psychiatricEvaluationNextStartTime).isBefore(
      moment(psychiatryFollowUpNextStartTime)
    ) ||
      (psychiatricEvaluationNextStartTime && psychiatryFollowUpNextStartTime === null): {
      status = ReminderStatus.SCHEDULED_INITIAL_EVALUATION;
      break;
    }

    case psychiatricEvaluationNextStartTime === null && psychiatryFollowUpNextStartTime: {
      status = ReminderStatus.SCHEDULED_FOLLOW_UP;
      break;
    }

    case psychiatricEvaluationCompletedAt === null && psychiatricEvaluationNextStartTime === null: {
      status = ReminderStatus.NEVER_SCHEDULE_INITIAL_EVALUATION;
      break;
    }

    case psychiatricEvaluationCompletedAt &&
      psychiatricEvaluationNextStartTime === null &&
      (moment().isBetween(
        psychiatryFollowUpLastCompletedAt,
        moment(psychiatryFollowUpLastCompletedAt).add(90, 'days')
      ) ||
        psychiatryFollowUpLastCompletedAt === null) &&
      psychiatryFollowUpNextStartTime === null: {
      status = ReminderStatus.NEVER_SCHEDULE_FOLLOW_UP;
      break;
    }

    case psychiatricEvaluationCompletedAt &&
      psychiatricEvaluationNextStartTime === null &&
      moment().isAfter(moment(psychiatryFollowUpLastCompletedAt).add(90, 'days'), 'days') &&
      psychiatryFollowUpNextStartTime === null: {
      status = ReminderStatus.NEVER_SCHEDULE_FOLLOW_UP_LONGTIME;
      remaining = moment().diff(moment(psychiatryFollowUpLastCompletedAt), 'months');
      break;
    }

    default: {
      status = ReminderStatus.SCHEDULED_INITIAL_EVALUATION;
      break;
    }
  }

  return { status, remaining };
};

export { getTherapistReminders, getPsychiatryReminders, curriedCreditType };
