import * as React from 'react';
import { View, Tiny, useEmotionTheme } from '@talkspace/react-toolkit';

interface TypingIndicatorProps {
  displayName?: string;
}

const TypingIndicator: React.VFC<TypingIndicatorProps> = ({ displayName }) => {
  const { colors } = useEmotionTheme();

  return (
    <View style={{ marginBottom: 15 }}>
      <Tiny style={{ color: colors.black }}>{displayName} is typing...</Tiny>
    </View>
  );
};

export default TypingIndicator;
