import omit from 'lodash/omit';
import cloneDeep from 'lodash/cloneDeep';
import { ProgressNoteQueryResponse } from 'hooks/notes/useQueryProgressNote';
import { NoteSessionReportData } from 'hooks/notes/useQueryNotes';
import { GetProgressNoteHelperDataResponse } from 'hooks/notes/useQueryProgressNoteHelperData';
import { BusinessLineQueryResponse } from 'ts-frontend/hooks/useQueryBusinessLine';
import { ProgressNoteFormState } from './types';
import {
  BH_AND_EAP_INITIAL_CPT_CODE,
  PSYCH_FOLLOWUP_CPT_CODE_1,
  PSYCH_FOLLOWUP_CPT_CODE_2,
  PSYCH_INITIAL_CPT_CODE,
} from '../../utils';
import {
  FormMode,
  SessionService,
  ProgressNotePsychiatryPlanMedicationCreationAttributes,
} from '../../types';

const VERSION = 1;

const defaultPsychPlanMedication: ProgressNotePsychiatryPlanMedicationCreationAttributes = {
  medication: null,
  dosage: null,
  otherMedication: null,
};

const getDefaultInitialState = (params: GetInitialStateParams): ProgressNoteFormState => {
  return {
    formMode: params.formMode,
    showGlobalError: false,
    formSections: {
      sessionDateAndModality: {
        open: true,
        changed: false,
        completed: false,
      },
      sessionInformation: {
        open: false,
        changed: false,
        completed: false,
      },
      diagnosis: {
        open: false,
        changed: false,
        completed: false,
      },
      riskAssessment: {
        open: false,
        changed: false,
        completed: false,
      },
      substanceUse: {
        open: false,
        changed: false,
        completed: false,
      },
      treatmentPlanProgress: {
        open: false,
        changed: false,
        completed: false,
      },
      sessionSummary: {
        open: false,
        changed: false,
        completed: false,
      },
      medicalInformation: {
        open: false,
        changed: false,
        completed: false,
      },
      mentalStatusExam: {
        open: false,
        changed: false,
        completed: false,
      },
      psychSessionSummary: {
        open: false,
        changed: false,
        completed: false,
      },
      psychPlan: {
        open: false,
        changed: false,
        completed: false,
      },
    },
    caseID: null,
    sessionReportID: null,
    modalityID: null,
    serviceStartDate: null,
    serviceEndDate: null,
    videoCalls: [],
    sessionAttendees: [{ attendeeName: null, relationshipToClient: 'Identified client', id: 0 }],
    sessionServiceID: null,
    diagnoses: [],
    riskAssessment: {
      pastSuicidalIdeation: false,
      pastSuicidalIdeationSeverity: null,
      pastSuicidalIdeationNotes: null,
      currentSuicidalIdeation: false,
      currentSuicidalIdeationSeverity: null,
      currentSuicidalIdeationNotes: null,
      pastHomicidalIdeation: false,
      pastHomicidalIdeationSeverity: null,
      pastHomicidalIdeationNotes: null,
      currentHomicidalIdeation: false,
      currentHomicidalIdeationSeverity: null,
      currentHomicidalIdeationNotes: null,
      pastPsychosis: false,
      pastPsychosisNotes: null,
      currentPsychosis: false,
      currentPsychosisNotes: null,
      otherAssessment: false,
      otherAssessmentNotes: null,
    },
    substanceUse: {
      pastSubstanceUse: null,
      pastCigarettesUseNotes: null,
      pastVapingUseNotes: null,
      pastAlcoholUseNotes: null,
      pastMarijuanaUseNotes: null,
      pastStimulantsUseNotes: null,
      pastCocaineUseNotes: null,
      pastHeroinUseNotes: null,
      pastBenzodiazepinesUseNotes: null,
      pastOpioidsUseNotes: null,
      pastOtherSubstanceUseNotes: null,
      currentSubstanceUse: null,
      currentCigarettesUseNotes: null,
      currentVapingUseNotes: null,
      currentAlcoholUseNotes: null,
      currentMarijuanaUseNotes: null,
      currentStimulantsUseNotes: null,
      currentCocaineUseNotes: null,
      currentHeroinUseNotes: null,
      currentBenzodiazepinesUseNotes: null,
      currentOpioidsUseNotes: null,
      currentOtherSubstanceUseNotes: null,
    },
    medicalInformation: params.businessLine.isPsychiatry
      ? {
          otherCurrentPrescriptionMedications: null,
          currentOTCMedications: null,
          drugAllergies: null,
        }
      : null,
    medicalInformationConditions: [],
    medicalInformationMedications: [],
    mentalStatusExam: params.businessLine.isPsychiatry
      ? {
          appearance: null,
          appearanceDescription: null,
          attitude: null,
          attitudeDescription: null,
          behavior: null,
          behaviorDescription: null,
          speech: null,
          speechDescription: null,
          affect: null,
          affectDescription: null,
          mood: null,
          moodDescription: null,
          thoughtProcesses: null,
          thoughtProcessesDescription: null,
          perception: null,
          perceptionDescription: null,
          insightOrJudgement: null,
        }
      : null,
    treatmentPlanID: null,
    presentingProblems: [],
    treatmentPlanProgress: params.businessLine.isPsychiatry
      ? null
      : {
          buildRapport: false,
          shortTermTreatmentObjective: null,
          riskOrBarriersHandling: null,
        },
    treatmentPlanGoals: [],
    treatmentPlanObjectives: [],
    treatmentPlanInterventions: [],
    sessionSummary: {
      summary: null,
      userRoomSurveyID: null,
      followupPlan: null,
      recommendations: null,
      presentIllnessHistory: null,
      assessment: null,
      patientReport: null,
      clientState: null,
      clientCountry: null,
      clientAppropriateForTelehealth: null,
      clientRequestedLanguageAssistance: null,
      languageAssistanceProvided: null,
    },
    psychiatryPlan: params.businessLine.isPsychiatry
      ? {
          labsRequested: false,
          labsRequestedDetails: null,
          nextFollowup: '1_month',
          otherNextFollowup: null,
          additionalInfo: null,
          risksAndBenefitsConsent: false,
          risksAndBenefitsConsentDetails: null,
        }
      : null,
    psychiatryPlanMedications: params.businessLine.isPsychiatry ? [defaultPsychPlanMedication] : [],
    referralID: null,
    otherReferral: null,
    statementCertified: false,
    version: VERSION,
  };
};

const handleCreateMode = (initialState: ProgressNoteFormState, params: GetInitialStateParams) => {
  const updatedState = cloneDeep(initialState);

  const { businessLine, helperData, sessionServices, currentSessionReport } = params;

  if (helperData.lastProgressNote) {
    const { lastProgressNote } = helperData;

    updatedState.sessionAttendees = lastProgressNote.sessionAttendees.map((it) => {
      return {
        attendeeName: it.attendeeName,
        relationshipToClient: it.relationshipToClient,
        id: it.id,
      };
    });

    updatedState.riskAssessment = omit(lastProgressNote.riskAssessment, [
      'id',
      'noteID',
      'createdAt',
    ]);

    updatedState.substanceUse = omit(lastProgressNote.substanceUse, ['id', 'noteID', 'createdAt']);

    if (businessLine.isPsychiatry) {
      updatedState.psychiatryPlan!.nextFollowup = lastProgressNote.psychiatryPlan.nextFollowup;
      updatedState.psychiatryPlan!.otherNextFollowup =
        lastProgressNote.psychiatryPlan.otherNextFollowup;
      updatedState.psychiatryPlan!.additionalInfo = lastProgressNote.psychiatryPlan.additionalInfo;
      updatedState.psychiatryPlanMedications! = lastProgressNote.psychiatryPlanMedications.map(
        (it) => {
          return {
            medication: it.medication,
            dosage: it.dosage,
            otherMedication: it.otherMedication,
          };
        }
      );
    } else {
      updatedState.treatmentPlanProgress = omit(lastProgressNote.treatmentPlanProgress, [
        'id',
        'noteID',
        'createdAt',
      ]);
    }
  }

  const defaultVideoCall =
    helperData.unassociatedVideoCalls.length > 0
      ? helperData.unassociatedVideoCalls[helperData.unassociatedVideoCalls.length - 1]
      : null;

  if (defaultVideoCall) {
    updatedState.videoCalls = [{ videoCallID: defaultVideoCall.id }];
  }

  if (currentSessionReport) {
    updatedState.sessionReportID = currentSessionReport.id;
    updatedState.caseID = currentSessionReport.caseID;
  }

  let defaultSessionServiceID: number | undefined;

  if (businessLine.isBH || businessLine.isEAP) {
    defaultSessionServiceID = sessionServices.find((it) =>
      it.name.includes(BH_AND_EAP_INITIAL_CPT_CODE)
    )?.id;
  } else if (businessLine.isPsychiatry) {
    const isFirstNote = helperData.lastProgressNote === null;
    if (isFirstNote) {
      defaultSessionServiceID = sessionServices.find((it) =>
        it.name.includes(PSYCH_INITIAL_CPT_CODE)
      )?.id;
    } else {
      defaultSessionServiceID = sessionServices.find(
        (it) =>
          it.name.includes(PSYCH_FOLLOWUP_CPT_CODE_1) || it.name.includes(PSYCH_FOLLOWUP_CPT_CODE_2)
      )?.id;
    }
  }

  if (defaultSessionServiceID) {
    updatedState.sessionServiceID = defaultSessionServiceID;
  }

  updatedState.version = VERSION;

  return updatedState;
};

const handleEditMode = (initialState: ProgressNoteFormState, params: GetInitialStateParams) => {
  const { selectedNote } = params;
  if (!selectedNote) {
    return initialState;
  }

  const {
    sessionReportPosition,
    formSections,
    status,
    createdByUser,
    submittedAt,
    readOnly,
    videoCalls,
    serviceType,
    ...formState
  } = selectedNote;

  const updatedState: ProgressNoteFormState = {
    formMode: params.formMode,
    showGlobalError: false,
    videoCalls: videoCalls.map((it) => {
      return { videoCallID: it.videoCallID };
    }),
    ...formState,
    psychiatryPlanMedications:
      params.businessLine.isPsychiatry && formState.psychiatryPlanMedications.length === 0
        ? [defaultPsychPlanMedication]
        : [...formState.psychiatryPlanMedications],
    treatmentPlanGoals: formState.treatmentPlanGoals.map((it) => {
      return {
        overallProgress: it.overallProgress,
        treatmentPlanGoalID: it.treatmentPlanGoalID,
      };
    }),
    treatmentPlanObjectives: formState.treatmentPlanObjectives.map((it) => {
      return {
        overallProgress: it.overallProgress,
        treatmentPlanObjectiveID: it.treatmentPlanObjectiveID,
      };
    }),
    treatmentPlanInterventions: formState.treatmentPlanInterventions.map((it) => {
      return {
        treatmentPlanObjectiveID: it.treatmentPlanObjectiveID,
        treatmentPlanInterventionID: it.treatmentPlanInterventionID,
      };
    }),
    formSections: formSections || initialState.formSections,
    serviceStartDate: selectedNote.serviceStartDate
      ? new Date(selectedNote.serviceStartDate)
      : null,
    serviceEndDate: selectedNote.serviceEndDate ? new Date(selectedNote.serviceEndDate) : null,
  };

  updatedState.version = VERSION;

  return updatedState;
};
interface GetInitialStateParams {
  formMode: FormMode;
  businessLine: BusinessLineQueryResponse;
  helperData: GetProgressNoteHelperDataResponse;
  sessionServices: SessionService[];
  currentSessionReport: NoteSessionReportData | null;
  selectedNote: ProgressNoteQueryResponse | null;
}

const getInitialState = (params: GetInitialStateParams): ProgressNoteFormState => {
  const defaultInitialState = getDefaultInitialState(params);

  switch (params.formMode) {
    case 'create':
      return handleCreateMode(defaultInitialState, params);
    case 'edit':
    case 'view':
      return handleEditMode(defaultInitialState, params);
    default:
      throw new Error(`getInitialState got an invalid formMode param: ${params.formMode}`);
  }
};

export default getInitialState;
