import { FunctionComponent } from 'react';
import { SessionModality } from 'ts-frontend/types';

import AudioIcon from './AudioIcon';
import ChatIcon from './ChatIcon';
import VideoIcon from './VideoIcon';
import MessagingIcon from './MessagingIcon';
import { useEmotionTheme, EmotionStyle } from '../../core/styled';

interface LiveSessionIconProp {
  color?: string;
  iconColor?: string;
  width?: number;
  height?: number;
  style?: EmotionStyle;
  modality?: SessionModality;
}

const LiveSessionIcon: FunctionComponent<LiveSessionIconProp> = ({
  color = undefined,
  iconColor = undefined,
  width = 70,
  height = 70,
  style = {},
  modality = 'video',
}) => {
  const {
    colors: { purple, patensBlue },
  } = useEmotionTheme();
  const backgroundColor = color || purple;
  const innerIconColer = iconColor || patensBlue;

  switch (modality) {
    case 'video':
      return (
        <VideoIcon
          aria-hidden="true"
          width={width}
          height={height}
          color={backgroundColor}
          iconColor={innerIconColer}
          style={style}
        />
      );
    case 'chat':
      return (
        <ChatIcon
          aria-hidden="true"
          width={width}
          height={height}
          style={style}
          color={backgroundColor}
          iconColor={innerIconColer}
        />
      );
    case 'audio':
      return (
        <AudioIcon
          aria-hidden="true"
          width={width}
          height={height}
          color={backgroundColor}
          iconColor={innerIconColer}
          style={style}
        />
      );
    case 'messaging':
      return (
        <MessagingIcon
          aria-hidden="true"
          width={width}
          height={height}
          color={backgroundColor}
          iconColor={innerIconColer}
          style={style}
        />
      );
    default:
      return (
        <VideoIcon
          aria-hidden="true"
          width={width}
          height={height}
          color={backgroundColor}
          iconColor={innerIconColer}
          style={style}
        />
      );
  }
};

export default LiveSessionIcon;
