import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend, { HttpBackendOptions } from 'i18next-http-backend';

const i18nInit = (initReactI18next, staticPath) => {
  i18n
    .use(Backend)
    .use(LanguageDetector) // detect user language
    .use(initReactI18next) // pass the i18n instance to react-i18next.
    .init<HttpBackendOptions>({
      // i18next Options
      debug: false,
      fallbackLng: 'en',
      defaultNS: false,
      ns: [],
      load: 'currentOnly',
      returnEmptyString: false,
      returnNull: false,
      preload: false,

      // i18next-http-backend Options
      backend: {
        loadPath: (lng, ns) => `${staticPath}/locales/${lng}/${ns}.json`,
        crossDomain: true,
      },
      // react-i18next options
      react: {
        useSuspense: false,
        // QM heading1 uses string html
        transSupportBasicHtmlNodes: true,
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'sup'],
      },
    });
};

export default i18nInit;
