import { useCallback, useState } from 'react';
import { Modal, useEmotionTheme, View, TSLogoCollapsed } from '@talkspace/react-toolkit';
import { addIonicAppListener } from '../../../plugins/capacitor';
import { getIonicPasscode, getIonicPasscodeEnabled } from '../../../plugins/secureStorage';
import { useHistory } from '@/core/routerLib';
import styled from '@/core/styled';
import { getUserData } from '@/auth/helpers/token';
import EnterPasscode from '../components/EnterPasscode';
import { useIonicEffect } from '../../../hooks/genericHooks';
import { CHECK_PASSCODE, APP_LOADED } from '../constants';
import { updateWebVersion } from '../../../plugins/liveUpdates';
import { shouldShowSplashScreen } from '../../../plugins/splashScreen';

const routePathStartBlacklist = ['/two-factor', '/forgot-password', '/login', '/login-success'];

const Wrapper = styled(Modal)(({ theme: { colors } }) => {
  return {
    width: '100%',
    height: '100dvh',
    position: 'absolute',
    background: colors.white,
    zIndex: 99999,
  };
});

interface Tokens {
  lastRefreshed?: number;
  accessToken: string | null;
  refreshToken: string | null;
  /** Unix Timestamp for the expiration of the access token */
  accessTTL: number;
  /** Unix Timestamp for the expiration of the refresh token */
  refreshTTL: number;
}

interface IonicPasscodeProps {
  getTokens: () => Tokens;
  isClient: boolean;
}

const IonicPasscode = ({ getTokens, isClient }: IonicPasscodeProps) => {
  const history = useHistory();

  const isLoggedIn = !routePathStartBlacklist.includes(history.location.pathname);
  const [showPasscodeView, setShowPasscodeView] = useState<boolean>(false);
  const [isScreenBlocked, setIsScreenBlocked] = useState<boolean>(false);
  const [hasCheckedAfterResume, setHasCheckedAfterResume] = useState<boolean>(true);
  const { id: userID } = getUserData();
  const keySuffix = isClient && userID ? `${userID}` : undefined;
  const { colors } = useEmotionTheme();

  const checkIfPasscodeExists = useCallback(async () => {
    setHasCheckedAfterResume(true);
    const { refreshToken } = getTokens();
    if (!isLoggedIn || !refreshToken) {
      setShowPasscodeView(false);
      setIsScreenBlocked(false);
      return;
    }
    sessionStorage.setItem(APP_LOADED, 'true');

    const { value } = await getIonicPasscode(keySuffix);
    const { value: isEnabledValue } = isClient
      ? await getIonicPasscodeEnabled(keySuffix)
      : { value: 'true' };

    if (!value && isEnabledValue === 'true') {
      setShowPasscodeView(false);
      setIsScreenBlocked(false);
      const createPasscodeRoute = '/passcode';
      if (!history.location.pathname.includes(createPasscodeRoute)) {
        history.push(createPasscodeRoute);
      }
    } else if (isEnabledValue === 'true') {
      // in some cases this container will mount, but be redirected again before the EnterPasscode mounts
      // save a session variable so we can redirect to EnterPasscode even if APP_LOADED is true
      sessionStorage.setItem(CHECK_PASSCODE, 'true');
      setShowPasscodeView(true);
      setIsScreenBlocked(true);
    } else if (isEnabledValue !== 'true') {
      setIsScreenBlocked(false);
    }
  }, [history, isLoggedIn, getTokens, isClient, keySuffix]);

  useIonicEffect(() => {
    if (sessionStorage.getItem(CHECK_PASSCODE) === 'true') {
      setShowPasscodeView(true);
      setIsScreenBlocked(true);
    }
  }, [history.location.pathname]);

  useIonicEffect(() => {
    if (!sessionStorage.getItem(APP_LOADED)) {
      checkIfPasscodeExists();
    }
  }, [checkIfPasscodeExists]);

  useIonicEffect(() => {
    const listener = addIonicAppListener('resume', () => {
      if (shouldShowSplashScreen() === false) return;
      setHasCheckedAfterResume(false);
      setIsScreenBlocked(true);
      const handleSplashScreen = async () => {
        await updateWebVersion({ showSplash: true, splashDuration: 2000 });
      };
      handleSplashScreen().then(() => checkIfPasscodeExists());
    });

    return () => {
      listener.remove();
    };
  }, [checkIfPasscodeExists]);

  useIonicEffect(() => {
    const listener = addIonicAppListener('appStateChange', (change) => {
      const { isActive } = change;
      setIsScreenBlocked(!hasCheckedAfterResume || !isActive);
    });
    return () => {
      listener.remove();
    };
  }, [checkIfPasscodeExists, hasCheckedAfterResume]);

  if (showPasscodeView) {
    return (
      <Wrapper isVisible underlayStyle={{ background: colors.white, display: 'block' }}>
        <EnterPasscode
          isClient={isClient}
          handleAppResumed={(val: boolean) => {
            sessionStorage.removeItem(CHECK_PASSCODE);
            setShowPasscodeView(val);
            setIsScreenBlocked(val);
          }}
        />
      </Wrapper>
    );
  }
  if (isScreenBlocked) {
    return (
      <Wrapper
        isVisible
        underlayStyle={{ backgroundColor: '#E1F0EE', display: 'block' }}
        dialogStyle={{ height: '100dvh', alignItems: 'center' }}
      >
        <View>
          <TSLogoCollapsed variant="2024" />
        </View>
      </Wrapper>
    );
  }

  return null;
};

export default IonicPasscode;
