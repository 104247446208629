import { FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import useQueryProgressNote from 'hooks/notes/useQueryProgressNote';
import * as treatmentPlannerActions from 'actions/TreatmentPlannerActions';
import useQueryRoomDetails from 'ts-frontend/hooks/useQueryRoomDetails';
import { useParams } from '@/core/routerLib';
import ExistingTreatmentPlan from './ExistingTreatmentPlan';
import NoTreatmentPlan from './NoTreatmentPlan';
import { GetCurrentTreatmentPlanProps } from '../componentsV2/TreatmentPlanProgressSection';

interface StoreProps {
  currentTreatmentPlan: Record<string, any> | undefined;
  getCurrentTreatmentPlan({ otherRoomID, otherClientID, type }: GetCurrentTreatmentPlanProps): void;
}

const TreatmentPlanProgressSection: FunctionComponent<StoreProps> = ({
  currentTreatmentPlan,
  getCurrentTreatmentPlan,
}) => {
  const { roomID, noteID } = useParams<{ roomID: string; noteID?: string }>();

  const { data: { clientUserID } = {} } = useQueryRoomDetails(roomID);
  const { data: progressNoteData } = useQueryProgressNote(clientUserID, noteID);

  useEffect(() => {
    getCurrentTreatmentPlan({ otherRoomID: progressNoteData?.roomID });
  }, [getCurrentTreatmentPlan, progressNoteData]);

  return currentTreatmentPlan?.id &&
    ((progressNoteData?.status !== 'submitted' &&
      (currentTreatmentPlan.goals?.length || currentTreatmentPlan.objectives?.length)) ||
      progressNoteData?.status === 'submitted') ? (
    <ExistingTreatmentPlan currentTreatmentPlanID={currentTreatmentPlan.id} />
  ) : (
    <NoTreatmentPlan currentTreatmentPlanID={currentTreatmentPlan?.id} />
  );
};

const mapStateToProps = (state) => {
  return {
    currentTreatmentPlan: state.treatmentPlanner.currentTreatmentPlan,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCurrentTreatmentPlan: ({
      otherRoomID,
      otherClientID,
      type,
      treatmentPlanID,
    }: GetCurrentTreatmentPlanProps) =>
      dispatch(
        treatmentPlannerActions.getCurrentTreatmentPlan({
          otherRoomID,
          otherClientID,
          type,
          treatmentPlanID,
        })
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TreatmentPlanProgressSection);
