import {
  CalendarBooking,
  Huge,
  Mini,
  CalendarToolBar,
  View,
  useWindowWidthState,
} from '@talkspace/react-toolkit';
import { useCallback, useEffect, useRef, useState } from 'react';
import FullCalendar, {
  DayHeaderContentArg,
  EventClickArg,
  EventContentArg,
} from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import moment from 'moment-timezone';
import useQueryProviderBookingsByID, {
  BookingsByIDV4Response,
} from '../../../../hooks/liveSessions/useQueryProviderBookingsByID';
import useQueryCalendarAvailability, {
  transformAPIResponse,
} from '../../../../hooks/availability/useQueryCalendarAvailability';
import useQueryProviderBookings, {
  BookingsV4Response,
} from '../../../../hooks/liveSessions/useQueryProviderBookings';
import styled, { EmotionStyle } from '../../../../core/styled';
import SocketService from '../../../../utils/socket/SocketService';
import CalendarModal from '../../Modals/CalendarModal';
import trackEvent from '../../../../modules/analytics/trackEvent';
import { getUserData } from '../../../../utils/token';

interface FormattedBookingsV4Response extends Omit<BookingsV4Response, 'startTime'> {
  beginsAt: Date;
  endsAt: Date;
  start: Date;
  end: Date;
}

const SCROLL_Y_WIDTH = 10;

const updateAllBookingState = (
  bookingID: string,
  bookingInState: BookingsV4Response[],
  bookingFromResponse: BookingsByIDV4Response
) => {
  const updatedBooking = bookingFromResponse || null;

  const updatedAllBookingData = bookingInState.filter((booking) => booking.bookingID !== bookingID);

  if (updatedBooking) {
    updatedAllBookingData.push(updatedBooking);
  }

  return updatedAllBookingData;
};

const formatBookingsObjects = (bookings) =>
  bookings.map((booking) => {
    const start = new Date(booking.startTime);
    const end = new Date(start.getTime() + booking.creditMinutes * 60 * 1000);
    return {
      start,
      end,
      beginsAt: booking.startTime,
      endsAt: end.toISOString(),
      title: 'Booking',
      ...booking,
    };
  });

const formatAvailabilities = (availabilities) =>
  availabilities.map((availability) => {
    const start = new Date(availability.start);
    const end = new Date(start.getTime() + availability.lengthMinutes * 60 * 1000);
    return {
      start,
      end,
      display: 'inverse-background',
      backgroundColor: '#E1E8F2',
      groupId: 'provider-available',
      tsEventType: 'availability',
    };
  });

const DayOfWeek = styled(Mini)<{ isToday?: boolean }>(({ theme: { colors }, isToday }) => {
  const isTodayStyle: EmotionStyle = isToday ? { color: colors.permaEden } : {};

  return {
    fontSize: 12,
    textTransform: 'uppercase',
    ...isTodayStyle,
  };
});

const DayOfMonth = styled(Huge)<{ isPast?: boolean; isToday?: boolean }>(
  ({ theme: { colors }, isPast, isToday }) => {
    const pastDayStyle: EmotionStyle = isPast ? { color: colors.slateGrey } : {};
    const isTodayStyle: EmotionStyle = isToday
      ? { color: colors.white }
      : { color: colors.permaEden };

    return {
      color: isToday ? colors.white : colors.black,
      ...isTodayStyle,
      ...pastDayStyle,
      backgroundColor: isToday ? colors.permaEden : 'transparent',
      justifyContent: 'center',
      paddingTop: 3,
      width: 37,
      height: 37,
      borderRadius: '50%',
    };
  }
);

const editAvailabilityPress = () => {
  trackEvent('calendarEditAvailabilityButtonClicked', {
    actionName: 'activeExperiments',
  });
  return window.open('/my-account?scrollTo=availability');
};

interface Slot {
  date: Date;
}

const getSlotLabelComponent = (slot: Slot) => <Mini>{moment(slot.date).format('h A')}</Mini>;

const CalendarToolBarWrapper = styled(View)(({ theme: { window } }) => {
  const { isMobile, isLarge, isDesktop } = window;

  return {
    ...(isMobile && {
      marginTop: 30,
      paddingLeft: 12,
      paddingRight: 12,
    }),
    ...((isLarge || isDesktop) && {
      marginTop: 30,
      paddingLeft: 20,
      paddingRight: 20,
    }),
  };
});

const Wrapper = styled(View)(({ theme: { window } }) => {
  const { isMobile, isLarge, isDesktop, width } = window;

  return {
    overflowY: 'scroll',
    overflowX: width <= 414 ? 'scroll' : 'hidden', // allow scrolling up to iphone xr screen width
    height: `calc(100vh - 168px)`,
    ...(isMobile && {
      paddingLeft: 12,
      paddingRight: 12 - SCROLL_Y_WIDTH,
      paddingBottom: 30,
    }),
    ...((isLarge || isDesktop) && {
      paddingLeft: 20,
      paddingRight: 20 - SCROLL_Y_WIDTH,
      paddingBottom: 40,
    }),
  };
});

interface DateRange {
  start: Date;
  end: Date;
}

interface WeekAvailability extends DateRange {
  display: string;
  backgroundColor: string;
  groupId: string;
  tsEventType: string;
}

const ProviderCalendar = () => {
  const calendarRef = useRef<FullCalendar | null>(null);
  const startOfWeekRef = useRef<Date>(moment().startOf('isoWeek').subtract(1, 'day').toDate());
  const endOfWeekRef = useRef<Date>(moment().endOf('isoWeek').subtract(1, 'day').toDate());
  const browserTimezone = useRef<string>(moment.tz.guess());
  const calendarContainerRef = useRef<HTMLDivElement | null>(null);
  const bookingChipPositionRef = useRef<DOMRect | null>(null);

  const [dateRange, setDateRange] = useState<DateRange>({
    start: startOfWeekRef.current,
    end: endOfWeekRef.current,
  });
  const [bookingID, setBookingID] = useState<string>('');
  const [bookings, setBookings] = useState<BookingsV4Response[]>([]);
  const { isMobile } = useWindowWidthState();

  const [showCalendarModal, setShowCalendarModal] = useState<boolean>(false);
  const [bookingContent, setBookingContent] = useState<FormattedBookingsV4Response | null>(null);
  const [weekAvailabilities, setWeekAvailabilities] = useState<WeekAvailability[]>([]);
  const therapistID: number = getUserData().id;
  const { data: availabilities } = useQueryCalendarAvailability(therapistID);

  const { data: providerBookings = [], isFetching: isProviderBookingsFetching } =
    useQueryProviderBookings(
      therapistID,
      dateRange.start.toISOString(),
      dateRange.end.toISOString()
    );

  const { data: providerBookingsByID, isFetching: isProviderBookingByIdFetching } =
    useQueryProviderBookingsByID(
      therapistID,
      bookingID,
      dateRange.start.toISOString(),
      dateRange.end.toISOString()
    );

  useEffect(() => {
    if (!isProviderBookingsFetching && providerBookings.length > 0) {
      setBookings(providerBookings);
    }
  }, [isProviderBookingsFetching, providerBookings]);

  useEffect(() => {
    if (!isProviderBookingByIdFetching && providerBookingsByID) {
      const updatedAllBookingData = updateAllBookingState(
        bookingID.toString(),
        providerBookings,
        providerBookingsByID
      );

      setBookings(updatedAllBookingData);
      setBookingID('');
    }
  }, [bookingID, isProviderBookingByIdFetching, providerBookings, providerBookingsByID]);

  useEffect(() => {
    const handleBookingChange = (data: { message: { bookingID: string } }) => {
      setBookingID(data.message.bookingID);
    };
    SocketService.instance().on('bookingUpdate', handleBookingChange);
    return () => {
      SocketService.instance().off('bookingUpdate', handleBookingChange);
    };
  });

  const getDayHeaderComponent = useCallback(
    (dayDate: DayHeaderContentArg) => {
      const { isPast, isToday, date } = dayDate;
      const dayOfWeek = date
        .toLocaleDateString('en-US', {
          weekday: 'short',
        })
        .toUpperCase();

      const dayOfMonth = date.getDate();

      return (
        <View style={{ textAlign: 'center', width: '100%' }}>
          {date.getDay() === 0 && (
            <Mini style={{ position: 'absolute', left: 12, bottom: 14 }}>
              {moment().tz(browserTimezone.current).format('zz')}
            </Mini>
          )}
          <DayOfWeek isToday={isToday}>{dayOfWeek}</DayOfWeek>
          <DayOfMonth isPast={isPast} isToday={isToday}>
            {dayOfMonth}
          </DayOfMonth>
        </View>
      );
    },
    [browserTimezone]
  );

  const getDateRange = useCallback(() => {
    const endDate = calendarRef?.current?.getApi().getCurrentData().dateProfile.activeRange?.end;
    // the fullcalendar api is returning the UTC 00:00:00 timestamp and THEN converting to local time
    // this is to determine the actual week of the year with that information
    const isoWeek = moment(endDate).subtract(2, 'day').isoWeek();
    const newStart = moment(endDate)
      .isoWeek(isoWeek)
      .startOf('isoWeek')
      .subtract(1, 'day')
      .toDate();
    const newEnd = moment(endDate).isoWeek(isoWeek).endOf('isoWeek').subtract(1, 'day').toDate();

    return endDate
      ? { start: newStart, end: newEnd }
      : { start: startOfWeekRef.current, end: endOfWeekRef.current };
  }, [calendarRef, startOfWeekRef, endOfWeekRef]);

  useEffect(() => {
    if (!dateRange.start || !dateRange.end) {
      setDateRange(getDateRange());
    }
  }, [dateRange, getDateRange]);

  useEffect(() => {
    setWeekAvailabilities(
      formatAvailabilities(transformAPIResponse(availabilities, dateRange.start, dateRange.end))
    );
  }, [dateRange.start, dateRange.end, availabilities]);

  const handlePrevClick = useCallback(() => {
    trackEvent(
      'calendarDateRangeChanged',
      { actionName: 'activeExperiments' },
      { userAction: 'back' }
    );

    calendarRef?.current?.getApi().prev();
    setDateRange(getDateRange());
  }, [setDateRange, getDateRange, calendarRef]);

  const handleNextClick = useCallback(() => {
    trackEvent(
      'calendarDateRangeChanged',
      { actionName: 'activeExperiments' },
      { userAction: 'forward' }
    );

    calendarRef?.current?.getApi().next();
    setDateRange(getDateRange());
  }, [setDateRange, getDateRange, calendarRef]);

  const handleTodayClick = useCallback(() => {
    trackEvent(
      'calendarDateRangeChanged',
      { actionName: 'activeExperiments' },
      { userAction: 'today' }
    );

    calendarRef?.current?.getApi().today();
    setDateRange(getDateRange());
  }, [setDateRange, getDateRange, calendarRef]);

  const getModalPositionStyle = useCallback(() => {
    const modalWidth = isMobile ? 355 : 428;
    let positioningStyle: EmotionStyle = {};

    if (!bookingContent || !bookingChipPositionRef.current || !calendarContainerRef.current) {
      return positioningStyle;
    }

    const calendarContainerElem: HTMLDivElement = calendarContainerRef.current;

    const { x: containerLeftBound, width: containerwidth } =
      (calendarContainerElem as HTMLDivElement)?.getBoundingClientRect() || {};

    const { width: bookingContentWidth, x: bookingLeftBound } = bookingChipPositionRef.current;
    const containerRightBound = containerwidth + containerLeftBound;

    const modalPositionRight = bookingLeftBound + bookingContentWidth + modalWidth;
    const modalPositionLeft = bookingLeftBound - modalWidth;

    if (modalPositionLeft > containerLeftBound) {
      positioningStyle = {
        left: bookingLeftBound - modalWidth,
        top: 300,
        position: 'absolute',
        transform: 'none',
      };
    } else if (modalPositionLeft < containerLeftBound && modalPositionRight < containerRightBound) {
      positioningStyle = {
        left: bookingLeftBound + bookingContentWidth,
        top: 300,
        position: 'absolute',
        transform: 'none',
      };
    }

    return positioningStyle;
  }, [bookingContent, isMobile]);

  const handleModalClosePress = () => {
    setShowCalendarModal(false);
    setBookingContent(null);
  };

  const getBookingComponent = (event: EventContentArg) => {
    if (event.event.extendedProps.tsEventType === 'availability') return <></>;

    return (
      <>
        <CalendarBooking
          bookingState={event.event.extendedProps.timekitBookingState}
          isActive={
            bookingContent !== null &&
            event.event.extendedProps.bookingID === bookingContent.bookingID
          }
          bookingStatus={event.event.extendedProps.status}
          endDate={new Date(event.event.extendedProps.endsAt)}
          duration={event.event.extendedProps.creditMinutes}
          modality={event.event.extendedProps.modality}
          hasBreakAfterSession={event.event.extendedProps.hasBreakAfterSession}
          scheduledByUserType={event.event.extendedProps.scheduledByUserType}
          primaryClient={event.event.extendedProps.clients[0]}
        />
      </>
    );
  };

  return (
    <>
      <CalendarToolBarWrapper>
        <CalendarToolBar
          handlePreviousPress={handlePrevClick}
          handleNextPress={handleNextClick}
          handleTodayPress={handleTodayClick}
          handleEditAvailabilityPress={editAvailabilityPress}
          dateStart={dateRange.start}
          dateEnd={dateRange.end}
        />
      </CalendarToolBarWrapper>
      <Wrapper ref={calendarContainerRef}>
        <FullCalendar
          allDaySlot={false}
          plugins={[timeGridPlugin]}
          nowIndicator
          initialView="timeGridWeek"
          events={weekAvailabilities.concat(formatBookingsObjects(bookings))}
          eventContent={getBookingComponent}
          dayHeaderContent={getDayHeaderComponent}
          slotLabelContent={getSlotLabelComponent}
          eventClick={(info: EventClickArg) => {
            if (info.event.extendedProps.tsEventType !== 'availability') {
              bookingChipPositionRef.current = { ...info.el.getBoundingClientRect().toJSON() };

              trackEvent(
                'calendarEventModalOpened',
                {
                  actionName: 'liveSessionInteraction',
                },
                {
                  timekitUUID: info.event.extendedProps.bookingID,
                  updatedBy: 'provider',
                }
              );

              setBookingContent(info.event.extendedProps as FormattedBookingsV4Response);
              setShowCalendarModal(true);
            }
          }}
          eventColor="transparent"
          rerenderDelay={500}
          eventBackgroundColor="transparent"
          eventBorderColor="transparent"
          eventDisplay="auto"
          height="auto"
          headerToolbar={{ end: '', center: '', start: '' }}
          snapDuration="00:15:00"
          ref={calendarRef}
        />
      </Wrapper>
      <CalendarModal
        showModal={showCalendarModal}
        booking={bookingContent}
        timezone={moment().tz(browserTimezone.current).format('zz')}
        positioningStyle={getModalPositionStyle()}
        handleModalClosePress={handleModalClosePress}
      />
    </>
  );
};

export default ProviderCalendar;
