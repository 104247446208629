import { FunctionComponent, useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { getIsIonic, useIonicEffect } from 'ts-ionic';
import {
  BaseButton,
  ExtraHuge,
  Standard,
  View,
  Spinner,
  RedX,
  useWindowWidthState,
  useDoubleTap,
} from '@talkspace/react-toolkit';
import { useNewMemberNav } from 'launchDarkly/hooks';
import { showAudioVideoPermDialog } from 'ts-ionic/plugins/dialog';
import { allowSleep, keepAwake } from 'ts-ionic/plugins/keepAwake';
import { VideoCallScreenProps, VIDEO_CALL_SESSION_STORAGE_KEY } from '../types/videoCallTypes';
import styled from '@/core/styled';
import { useHistory, useLocation } from '@/core/routerLib/routerLib';
import sessionStorage from '@/core/storage/sessionStorage';
import { useVideoCallActions, useVideoCallState } from '../hooks/videoCallContext';
import VideoCallContainer from '../containers/VideoCallContainer';
import JoinVideoCallContainer from '../containers/JoinVideoCallContainer';
import useMediaStream from '../hooks/useMediaStream';
import { COLORS } from '../utils/design';
import { VideoCallButtonParams } from '../entities/EMessage';
import { preventNextSplashScreenShow } from '../../ts-ionic/plugins/splashScreen';

const MINIMAL_CALL_DURATION_IN_SECONDS = 240;

const MINIMIZED_WIDTH_WEB = 206;
const MINIMIZED_WIDTH_MOBILE = 201;
const MINIMIZED_HEIGHT_WEB = 240;
const MINIMIZED_HEIGHT_MOBILE = 265;
const MINIMIZED_PADDING = 15;

const DoubleClickableAndTappableArea: FunctionComponent<{
  show: boolean;
  height: number;
  onDoubleClick: () => void;
  onDoubleTap: () => void;
}> = ({ show, height, onDoubleClick, onDoubleTap, children, ...props }) => {
  const style = show ? { height } : { height: 0 };
  const doubleTapBind = useDoubleTap(show ? () => onDoubleTap() : null);

  return (
    <View
      onDoubleClick={show ? onDoubleClick : () => {}}
      style={style}
      {...doubleTapBind}
      {...props}
    >
      {children}
    </View>
  );
};

const FullScreenWrapper = styled(View)<{
  isTherapistChat: boolean;
  isMinimized?: boolean;
  isJoined?: boolean;
  useNewNav?: boolean;
}>(({ isTherapistChat, isJoined = false, theme, isMinimized, useNewNav }) => {
  const { window, safeAreaInsets } = theme;
  const { height: windowHeight, isMobile } = window;
  const fullScreenWidth = isTherapistChat && !isMobile ? 'calc(100vw - 375px)' : '100%';

  const minimizedStyle = {
    borderRadius: 25,
    boxShadow: 'rgba(57, 66, 74, 0.5) 0 5px 15px -4px',
    justifyContent: 'center',
    top: MINIMIZED_PADDING + safeAreaInsets.top,
    right: MINIMIZED_PADDING,
    height: isMobile ? MINIMIZED_HEIGHT_MOBILE : MINIMIZED_HEIGHT_WEB,
    width: isMobile ? MINIMIZED_WIDTH_MOBILE : MINIMIZED_WIDTH_WEB,
    transition: 'transform .15s',
    overflow: 'hidden',
  };
  const style = {
    position: !isMobile && isMinimized ? 'fixed' : 'absolute',
    backgroundColor: !isJoined && isMobile ? `rgb(157 170 175 / 50%)` : COLORS.white,
    top: 0,
    right: 0,
    height: windowHeight,
    width: fullScreenWidth,
    zIndex: 1000,
  };

  return isMinimized ? { ...style, ...minimizedStyle } : style;
});

const TrayWrapper = styled(View)(({ theme: { window } }) => {
  const { isMobile } = window;

  const mobileStyle = isMobile && {
    position: 'absolute',
    width: '100%',
    bottom: 0,
    paddingTop: 66,
    paddingBottom: 66,
    backgroundColor: COLORS.white,
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
  };

  return {
    ...mobileStyle,
  };
});

export type CheckInSource = 'intro-lvs' | 'psychiatry-lvs' | 'lvs';

const getCheckInSource = (lvsType: string): CheckInSource => {
  switch (lvsType) {
    case 'introduction':
      return 'intro-lvs';
    case 'psychiatry':
      return 'psychiatry-lvs';
    default:
      return 'lvs';
  }
};

const VideoCallScreen: FunctionComponent<VideoCallScreenProps> = ({
  roomID,
  closeLVSModal,
  isTherapistChat = false,
  outsideHeaderHeight,
  outsideFooterHeight,
  isOutsideChat,
}) => {
  const {
    getVideoCallAction,
    setVideoCallInitialStateAction,
    leaveCallAction,
    resetVideoCallStateAction,
    toggleMinimizedAction,
    getLiveSessionStartedAtAction,
  } = useVideoCallActions();
  const {
    isAudioOn,
    isVideoOn,
    videoCall,
    isJoined,
    isLoadingVideoCall,
    error,
    clientConnectionTime,
    videoCallID,
    videoCallEnded,
    isMinimized,
    agoraClient,
  } = useVideoCallState();
  const history = useHistory();
  const useNewNav = useNewMemberNav();

  const mediaStreamState = useMediaStream(agoraClient);

  useIonicEffect(() => {
    // Agora client will request native permissions. Prevent splash screen from showing
    preventNextSplashScreenShow();
  }, []);

  const location = useLocation();
  const locationState = location.state as VideoCallButtonParams | undefined;

  const { isMobile, width, height } = useWindowWidthState();

  const lvsMetadata = sessionStorage.getItem(VIDEO_CALL_SESSION_STORAGE_KEY);
  const sessionState = lvsMetadata && JSON.parse(lvsMetadata);
  const loadedState = useRef(locationState || sessionState);
  const videoCallChanged = !videoCallID || videoCallID !== loadedState.current?.videoCallID;
  const [isTop, setIsTop] = useState<boolean>(true);
  const [isLeft, setIsLeft] = useState<boolean>(false);
  const actualMinimizedWidth = isMobile ? MINIMIZED_WIDTH_MOBILE : MINIMIZED_WIDTH_WEB;
  const actualMinimizedHeight = isMobile ? MINIMIZED_HEIGHT_MOBILE : MINIMIZED_HEIGHT_WEB;

  useEffect(() => {
    const videoCallMinimizedEvent = new CustomEvent('videoCallMinimized', {
      detail: {
        isMinimized,
      },
    });
    if (!isMinimized) {
      setIsTop(true);
      setIsLeft(false);
    }
    // dispatch event on state of isMinimized video call
    document.dispatchEvent(videoCallMinimizedEvent);
  }, [setIsTop, setIsLeft, isMinimized]);

  useEffect(() => {
    if (!isJoined && videoCallChanged && loadedState.current) {
      setVideoCallInitialStateAction(loadedState.current);
    }
    if (roomID && videoCallID && !videoCall) {
      getVideoCallAction(roomID, videoCallID);
    }
  }, [
    getVideoCallAction,
    roomID,
    setVideoCallInitialStateAction,
    isJoined,
    videoCall,
    videoCallID,
    videoCallChanged,
  ]);

  useEffect(() => {
    const getStartedAt = () => {
      if (videoCallID) {
        getLiveSessionStartedAtAction(roomID, videoCallID);
      }
    };
    document.addEventListener('liveSessionStarted', getStartedAt);

    return () => {
      document.removeEventListener('liveSessionStarted', getStartedAt);
    };
  }, [getLiveSessionStartedAtAction, roomID, videoCallID]);

  useEffect(() => leaveCallAction, [leaveCallAction]);

  useEffect(() => {
    if (getIsIonic() && !isTherapistChat && isJoined) {
      keepAwake();
    }
    return () => {
      allowSleep();
    };
  }, [isTherapistChat, isJoined]);

  useEffect(() => {
    if (videoCallEnded) {
      allowSleep();
    }
  }, [videoCallEnded]);

  useEffect(() => {
    if (videoCallEnded && clientConnectionTime && videoCallID) {
      resetVideoCallStateAction();

      const currentTime = new Date();
      const callDurationInSeconds = Math.round(
        (currentTime.getTime() - new Date(clientConnectionTime).getTime()) / 1000
      );
      const minimalCallDurationReached = callDurationInSeconds > MINIMAL_CALL_DURATION_IN_SECONDS;
      const videoCreditType = loadedState.current?.videoCreditType || sessionState?.videoCreditType;
      if (isTherapistChat && minimalCallDurationReached && videoCreditType !== 'introduction') {
        const videoCallEndedEvent = new CustomEvent('videoCallEnded', {
          detail: {
            videoCallID,
            shouldOpenPostLVSPrompt:
              !location.pathname.includes('/progress-notes') &&
              !location.pathname.includes('/collateral-notes') &&
              !location.pathname.includes('/case-consultation-notes') &&
              !location.pathname.includes('/discharge-notes') &&
              !location.pathname.includes('/psychotherapy-notes') &&
              !location.pathname.includes('/notes-tab'),
          },
        });
        document.dispatchEvent(videoCallEndedEvent);
        closeLVSModal();
      } else if (!isTherapistChat && minimalCallDurationReached) {
        history.replace(
          `/room/${roomID}?action=check-in&source=post-lvs-web&check-in-source=${getCheckInSource(
            videoCreditType
          )}&video-call=${videoCallID}`
        );
      } else {
        closeLVSModal();
      }
    }
  }, [
    clientConnectionTime,
    closeLVSModal,
    history,
    isTherapistChat,
    location.pathname,
    resetVideoCallStateAction,
    loadedState.current?.videoCreditType,
    roomID,
    sessionState,
    videoCallEnded,
    videoCallID,
  ]);

  const containerHeight = height - outsideHeaderHeight - outsideFooterHeight;

  const onStopHandler = (event, data) => {
    const isAtStartPosition = data.x === 0 && data.y === 0;
    const newIsTop = containerHeight - data.y - actualMinimizedHeight / 2 > containerHeight / 2;
    const newIsLeft = isAtStartPosition ? false : data.x + width - actualMinimizedWidth < width / 2;
    setIsTop(newIsTop);
    setIsLeft(newIsLeft);
  };

  const isLoadingState =
    isLoadingVideoCall || !videoCall || !roomID || !videoCallID || videoCallChanged;

  if (error) {
    // For Ionic Audio/Video Permissions Error
    if (error === 'permissionsDenied') {
      closeLVSModal();
      showAudioVideoPermDialog({ isAudioOn, isVideoOn });
    }

    return (
      <FullScreenWrapper isTherapistChat={isTherapistChat} useNewNav={useNewNav}>
        <View
          align="center"
          style={{
            textAlign: 'center',
            marginTop: 'calc(50vh - 100px)',
          }}
        >
          <RedX />
          <ExtraHuge
            style={{
              marginLeft: 50,
              marginRight: 50,
              marginTop: 40,
            }}
          >
            {error === 'browserNotSupported'
              ? 'This browser is not supported. To access this feature, use the Talkspace mobile app.'
              : 'An error occurred, please try joining the call again'}
          </ExtraHuge>
          <BaseButton style={{ marginTop: 30 }} onPress={closeLVSModal}>
            <Standard variant="standardDarkGrey">Go back</Standard>
          </BaseButton>
        </View>
      </FullScreenWrapper>
    );
  }

  if (isLoadingState) {
    return (
      <FullScreenWrapper isTherapistChat={isTherapistChat} useNewNav={useNewNav}>
        <View flex={1}>
          <Spinner isLoading />
        </View>
      </FullScreenWrapper>
    );
  }

  const leftCoord = actualMinimizedWidth + MINIMIZED_PADDING * 2 - width;
  const bottomCoord = containerHeight - (actualMinimizedHeight + MINIMIZED_PADDING * 2);

  return (
    <View
      style={{
        position: isTherapistChat ? 'static' : 'fixed',
        top: 0,
        left: 0,
        height: isMinimized ? 0 : '100vh',
        width: '100%',
        zIndex: 10,
      }}
    >
      <Draggable
        position={{
          x: isLeft ? leftCoord : 0,
          y: isTop ? 0 : bottomCoord,
        }}
        cancel="button, svg"
        onStop={onStopHandler}
        disabled={!isMinimized}
        bounds={{ bottom: containerHeight - 270, top: 0, right: 0, left: -Math.abs(width - 226) }}
      >
        <FullScreenWrapper
          isMinimized={isMinimized}
          isTherapistChat={isTherapistChat}
          isJoined={isJoined}
          style={{ visibility: 'initial' }}
          useNewNav={useNewNav}
        >
          <DoubleClickableAndTappableArea
            show={isMinimized}
            height={187}
            onDoubleClick={toggleMinimizedAction}
            onDoubleTap={toggleMinimizedAction}
          >
            {isJoined ? (
              <VideoCallContainer
                roomID={roomID}
                videoCallID={videoCallID}
                returnToChat={closeLVSModal}
                mediaStreamState={mediaStreamState}
              />
            ) : (
              <TrayWrapper>
                <JoinVideoCallContainer onClosePress={closeLVSModal} isMobile={isMobile} />
              </TrayWrapper>
            )}
          </DoubleClickableAndTappableArea>
        </FullScreenWrapper>
      </Draggable>
    </View>
  );
};

export default VideoCallScreen;
