import { useEffect, useRef } from 'react';
import { useModalActions } from '@talkspace/react-toolkit';
import { WizardStep } from '../../types';
import { WizardModalTitle } from '../../reducers/wizardState';

// This hook does two things. Step flows are technically rendered in panel-like modals. Modal content containers (aka the dialog) should be aria-labelledby descriptive titles.
// Here we set a simple modal title provided by the wizardSchema, which allows for screen readers to inform users of the topic of flow (i.e. "survey"). This really only comes
// into play on step flow open. The useEffect programmatically focuses the container of the main contents of the panel, thereby allowing a visually hidden descriptive text
// (usually the step number and prompt) to be announced by a screen reader with each new step
export const useA11y = (wizardModalTitle: WizardModalTitle, currentStep?: WizardStep) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { setModalTitle } = useModalActions();

  useEffect(() => {
    setModalTitle(wizardModalTitle);
  }, [setModalTitle, wizardModalTitle]);
  useEffect(() => {
    if (wrapperRef.current && currentStep) {
      wrapperRef.current.focus();
    }
  }, [currentStep]);

  return { wrapperRef };
};

export default useA11y;
