import Svg, { G, Path } from 'svgs';

function ReviewsIcon({ color, title, ...rest }) {
  return (
    <Svg width="20px" height="19px" viewBox="0 0 20 19" title={title} aria-label={title} {...rest}>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Path
          d="M979-.25c2.002 0 3.815.811 5.127 2.123A7.227 7.227 0 01986.25 7h0v1586a7.227 7.227 0 01-2.123 5.127 7.227 7.227 0 01-5.127 2.123h0H7a7.227 7.227 0 01-5.127-2.123A7.227 7.227 0 01-.25 1593h0V7c0-2.002.811-3.815 2.123-5.127A7.227 7.227 0 017-.25h0z"
          stroke="#BEC7D7"
          strokeWidth={0.5}
          fill="#FFF"
          transform="translate(-42 -304) translate(19 268)"
        />
        <Path
          d="M18 2a2 2 0 012 2v11.294a2 2 0 01-2 2h-7.436l-3.729 3.45a1 1 0 01-1.564-.27l-1.668-3.18H2a2 2 0 01-2-2V4a2 2 0 012-2h16zm-8 2.941L8.34 7.363l-2.816.83 1.79 2.327-.08 2.934L10 12.471l2.766.983-.08-2.934 1.79-2.327-2.816-.83L10 4.94z"
          transform="translate(-42 -304) translate(19 268) translate(23 34)"
          fill={color}
        />
      </G>
    </Svg>
  );
}

export default ReviewsIcon;
