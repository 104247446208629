import Svg, { Path, G, Circle, Defs, Use } from 'svgs';

const BlueBookingIconWithX = ({ width = 72, height = 80, color = '#BCC4D3', ...otherProps }) => {
  const titleText = 'Calendar with X';
  return (
    <Svg width={width} height={height} {...otherProps} title={titleText} aria-label={titleText}>
      <Defs>
        <Circle id="BlueBookingIconWithX-b" cx={15.1219512} cy={15.1219512} r={15.1219512} />
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Path
          d="M42.162 0a3.902 3.902 0 013.901 3.902v4.341h.093a7.71 7.71 0 017.71 7.71v37.362a7.71 7.71 0 01-7.71 7.71H7.71A7.71 7.71 0 010 53.315V15.953a7.71 7.71 0 017.71-7.71h.093V3.902a3.902 3.902 0 117.803 0v4.341H38.26V3.902A3.902 3.902 0 0142.162 0z"
          transform="translate(-167 -147) translate(167 147)"
          fill="#5C84FB"
        />
        <G transform="translate(-167 -147) translate(167 147) translate(31.756 33.268)">
          <Use fill="#000" xlinkHref="#b" />
          <Circle
            stroke="#FFF"
            strokeWidth={2.26829268}
            fill="#BCC4D3"
            cx={15.1219512}
            cy={15.1219512}
            r={16.2560976}
          />
        </G>
        <G fill="#FFF" fillRule="nonzero">
          <Path
            d="M1.099 10.987a1.097 1.097 0 01-.777-1.875l8.79-8.79a1.097 1.097 0 111.553 1.554l-8.79 8.79a1.096 1.096 0 01-.776.321z"
            transform="translate(-167 -147) translate(167 147) translate(41.207 42.72)"
          />
          <Path
            d="M9.889 10.987c-.282 0-.563-.107-.777-.322l-8.79-8.79A1.097 1.097 0 111.876.323l8.79 8.79a1.097 1.097 0 01-.777 1.875z"
            transform="translate(-167 -147) translate(167 147) translate(41.207 42.72)"
          />
        </G>
      </G>
    </Svg>
  );
};

export default BlueBookingIconWithX;
