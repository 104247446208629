import { Component } from 'react';
import { Row, Col, Panel, FormControl, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import { states, countries } from '@talkspace/configs';
import { formatSelectOptionsFromConfig, isZipValid } from '@talkspace/react-toolkit';
import { getUserData } from '../../../utils/token';
import apiWrapper from '../../../utils/apiWrapper';
import apiHelper from '../../../utils/api';
import '../FormHeader/FormHeader.css';
import '../Account.css';
import './W9Information.css';
import 'react-toastify/dist/ReactToastify.min.css';
import storage from '../../../core/storage';

const stateOptions = formatSelectOptionsFromConfig(states);
const countriesOptions = formatSelectOptionsFromConfig(countries);
const DEFAULT_COUNTRY_CODE = 'US';

class W9Information extends Component {
  constructor(props) {
    super(props);
    const { forceAllowAddressEdit } = props;
    this.state = {
      isEdit: false,
      disableSave: true,
      tin: '',
      rawTin: '',
      tinType: '',
      name: '',
      sameAsCheckAddress: !forceAllowAddressEdit,
      streetAddress: '',
      zipcode: '',
      state: '',
      city: '',
      country: DEFAULT_COUNTRY_CODE,
      originalData: {},
      validInputCity: false,
      validInputName: false,
      validInputStreetAddress: false,
      validInputState: false,
      validInputZipCode: false,
      validInputTin: false,
      validInputTinType: false,
      isLoaded: false,
      validInputCountry: false,
    };

    this.toggleFormEdit = this.toggleFormEdit.bind(this);
    this.changeTinType = this.changeTinType.bind(this);
    this.changeName = this.changeName.bind(this);
    this.checkStreetAddress = this.checkStreetAddress.bind(this);
    this.changeState = this.changeState.bind(this);
    this.checkZipCode = this.checkZipCode.bind(this);
    this.checkCity = this.checkCity.bind(this);
    this.changeTin = this.changeTin.bind(this);
    this.changeCheckPaymentAddress = this.changeCheckPaymentAddress.bind(this);
    this.saveForm = this.saveForm.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.formatTin = this.formatTin.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.changeCountry = this.changeCountry.bind(this);
  }

  componentDidMount() {
    this.getW9Info();
  }

  getW9Info() {
    const userId = getUserData().id;

    return apiWrapper
      .get(`${apiHelper().apiEndpoint}/api/v1/therapist/${userId}/therapist-w9`)
      .then((response) => {
        this.enterInitialData(response.data.data);
      })
      .catch((err) => {
        toast(
          <div
            className="toaster toaster-error"
            style={{ background: '#FF0000', color: '#FFF', padding: '12px', fontSize: '16px' }}
          >
            Server Error!
          </div>,
          { autoClose: 3000 }
        );
      })
      .then(() => {
        this.validateForm();
      });
  }

  cancelEdit() {
    this.toggleFormEdit();

    this.setState({
      disableSave: true,
      tin: '',
      rawTin: '',
    });

    this.enterInitialData(this.state.originalData);
  }

  changeCheckPaymentAddress(event) {
    this.setState({ sameAsCheckAddress: event.target.checked }, this.validateForm);
  }

  changeName(event) {
    this.setState(
      { name: event.target.value, validInputName: event.target.value.length > 0 },
      this.validateForm
    );
  }

  changeState(event) {
    const { value } = event.target;
    let validInputState = true;
    if (this.state.country === DEFAULT_COUNTRY_CODE) {
      validInputState = value.length > 0;
    }
    this.setState({ state: value, validInputState }, this.validateForm);
  }

  changeCountry(event) {
    const { value } = event.target;
    const callback = () => {
      this.validateForm();
      if (value !== DEFAULT_COUNTRY_CODE) {
        this.changeState({
          target: {
            value: '',
          },
        });
      }
    };
    this.setState({ country: value, validInputCountry: value.length > 0 }, callback);
  }

  changeTin(event) {
    // if (/^\d+$/.test(event.target.value) && event.target.value.length < 10) {
    if (
      (this.state.tinType === 'ssn' && event.target.value.length < 12) ||
      (this.state.tinType === 'ein' && event.target.value.length < 11)
    ) {
      const tinString = this.formatTin(event.target.value);
      const rawTin = tinString.split('-').join('');
      this.setState(
        {
          tin: tinString,
          rawTin,
          validInputTin:
            rawTin.length === 9 ||
            (this.state.originalData.tin && this.state.originalData.tin.length > 0),
        },
        this.validateForm
      );
    }
  }

  changeTinType(event) {
    const tinString = this.formatTin(this.state.tin);
    this.setState(
      {
        tinType: event.target.value,
        tin: tinString,
        validInputTinType: event.target.value.length > 0,
      },
      () => {
        this.setState({ tin: this.formatTin(this.state.tin) }, this.validateForm);
      }
    );
  }

  checkCity(event) {
    this.setState(
      { city: event.target.value, validInputCity: event.target.value.length > 0 },
      this.validateForm
    );
  }

  checkStreetAddress(event) {
    this.setState(
      { streetAddress: event.target.value, validInputStreetAddress: event.target.value.length > 0 },
      this.validateForm
    );
  }

  checkZipCode(event) {
    const zipcode = event.target.value;
    this.setState({ zipcode, validInputZipCode: isZipValid(zipcode, 'US') }, this.validateForm);
  }

  enterInitialData(data) {
    this.setState({
      contentId: data.contentId,
      sameAsCheckAddress: this.props.forceAllowAddressEdit
        ? false
        : data.sameAsCheckAddress || false,
      originalData: data.originalData || data,
      isLoaded: true,
      validInputTin: (data.tinType || '').length > 0,
    });
    this.props.setIsSectionLoaded({ w9Information: true });

    this.changeTinType({
      target: {
        value: data.tinType || '',
      },
    });

    this.changeName({
      target: {
        value: data.name || '',
      },
    });

    this.checkStreetAddress({
      target: {
        value: data.streetAddress || '',
      },
    });

    this.checkZipCode({
      target: {
        value: data.zipcode || '',
      },
    });

    this.checkCity({
      target: {
        value: data.city || '',
      },
    });

    this.changeState({
      target: {
        value: data.state || '',
      },
    });

    this.changeCountry({
      target: {
        value: data.country || DEFAULT_COUNTRY_CODE,
      },
    });
  }

  formatTin(tinStringParam) {
    let tinString = tinStringParam.split('-').join('');
    if (tinString.length > 5 && this.state.tinType === 'ssn') {
      tinString = `${tinString.slice(0, 5).trim()}-${tinString.slice(5).trim()}`;
    }
    if (tinString.length > 2 && this.state.tinType === 'ein') {
      tinString = `${tinString.slice(0, 2).trim()}-${tinString.slice(2).trim()}`;
    } else if (tinString.length > 3 && this.state.tinType !== 'ein') {
      tinString = `${tinString.slice(0, 3).trim()}-${tinString.slice(3).trim()}`;
    }
    return tinString.trim();
  }

  saveForm() {
    this.toggleFormEdit();
    const { tinType } = this.state;
    const rawTin = this.state.rawTin.length === 9 ? this.state.rawTin : 0;
    const { name } = this.state;
    const { sameAsCheckAddress } = this.state;
    const { streetAddress } = this.state;
    const { city } = this.state;
    const { state } = this.state;
    const { zipcode } = this.state;
    const { forceAllowAddressEdit } = this.props;
    const { country } = this.state;
    const userId = getUserData().id;
    const token = storage.getItem('id_token');

    const params = {
      tinType,
      tin: rawTin,
      name,
      sameAsCheckAddress,
    };
    if (!sameAsCheckAddress || forceAllowAddressEdit) {
      params.streetAddress = streetAddress;
      params.city = city;
      params.state = state;
      params.zipcode = zipcode;
      params.country = country;
    }

    if (this.state.originalData.contentId) {
      params.contentId = this.state.originalData.contentId;
      return apiWrapper
        .put(`${apiHelper().apiEndpoint}/api/v1/therapist/${userId}/therapist-w9`, params)
        .then((response) => {
          if (response.data.success) {
            toast(<div className="toaster toaster-success">Changes are saved successfully!</div>, {
              autoClose: 3000,
            });
            const origData = this.state.originalData;
            origData.tin = params.tin?.slice?.(-4);
            origData.tinType = params.tinType;
            this.setState({
              originalData: origData,
              tin: '',
            });
          } else {
            toast(
              <div
                className="toaster toaster-error"
                style={{ background: '#FF0000', color: '#FFF', padding: '12px', fontSize: '16px' }}
              >
                Invalid Parameters!
              </div>,
              { autoClose: 3000 }
            );
          }
        });
    }
    return apiWrapper
      .post(`${apiHelper().apiEndpoint}/api/v1/therapist/${userId}/therapist-w9`, params)
      .then((response) => {
        if (response.data.success) {
          toast(<div className="toaster toaster-success">Changes are saved successfully!</div>, {
            autoClose: 3000,
          });
          const origData = this.state.originalData;
          origData.tin = params.tin.slice(-4);
          origData.contentId = response.data.contentId;
          origData.tinType = params.tinType;
          this.setState({
            originalData: origData,
            tin: '',
          });
        } else {
          toast(
            <div
              className="toaster toaster-error"
              style={{ background: '#FF0000', color: '#FFF', padding: '12px', fontSize: '16px' }}
            >
              Invalid Parameters!
            </div>,
            { autoClose: 3000 }
          );
        }
      })
      .catch((err) => {
        toast(
          <div
            className="toaster toaster-error"
            style={{ background: '#FF0000', color: '#FFF', padding: '12px', fontSize: '16px' }}
          >
            Server Error!
          </div>,
          { autoClose: 3000 }
        );
      });
  }

  toggleFormEdit() {
    const editBool = !this.state.isEdit;
    this.setState({ isEdit: editBool });

    const actionItems = document.querySelectorAll([
      '#w9Information .btn-ts-default',
      '#w9Information .ts-edit-button',
    ]);
    [].forEach.call(actionItems, (item) => {
      item.classList.toggle('hidden');
    });
  }

  validateForm() {
    const rawTinValid =
      (this.state.rawTin && /^\d{9}$/.test(this.state.rawTin)) ||
      (this.state.contentId && this.state.rawTin.length === 0);

    const tinTypeValid =
      this.state.tinType.length > 0 &&
      (this.state.tinType === this.state.originalData.tinType ||
        (this.state.tinType !== this.state.originalData.tinType && this.state.rawTin.length === 9));

    let addressValid =
      this.state.city.length > 0 &&
      this.state.streetAddress.length > 0 &&
      this.state.zipcode.length > 0;

    if (this.state.country === DEFAULT_COUNTRY_CODE) {
      addressValid = addressValid && this.state?.state?.length > 0;
    }

    const isFormValid = rawTinValid && this.state.name.length > 0 && tinTypeValid && addressValid;

    this.setState({ disableSave: !isFormValid });
  }

  render() {
    const tooltip = (
      <Tooltip id="W9InformationTooltip" className="tooltip ts-tooltip ts-profile-text-white">
        The TIN provided must match the Name to avoid backup withholding. For individuals, this is
        generally your social security number (SSN). For other entities, it is your employer
        identification number (EIN).
      </Tooltip>
    );

    const ssnDash = this.state.tinType === 'ssn' ? '-' : '';
    const tinPlaceholderEnd =
      this.state.originalData.tin && this.state.tinType === this.state.originalData.tinType
        ? `${ssnDash}${this.state.originalData.tin}`
        : `${ssnDash}####`;
    return (
      <div>
        <Col
          xs={12}
          id="w9Information"
          className={`ts-panel-title ${this.state.isLoaded ? 'show-panel' : 'hidden-panel'}`}
        >
          <Col xs={4} sm={2} className="ts-font-black">
            W-9 Information
            <OverlayTrigger placement="top" trigger={['click', 'focus', 'hover']} overlay={tooltip}>
              <i className="fa fa-fw fa-question-circle fa-lg" />
            </OverlayTrigger>
          </Col>
          <Col xs={5} />
          <Button className="ts-edit-button pull-right" onClick={this.toggleFormEdit}>
            Edit
          </Button>
          <Button
            className="btn-ts-default btn-ts-green ts-profile-text-white pull-right hidden save-btn"
            onClick={this.saveForm}
            disabled={this.state.disableSave}
          >
            Save Changes
          </Button>
          <Button
            className="btn-ts-default ts-profile-btn-text-green cancel-btn pull-right hidden"
            onClick={this.cancelEdit}
          >
            Cancel
          </Button>
        </Col>
        <Col xs={12}>
          <Panel
            className={`ts-my-account-panel ${this.state.isLoaded ? 'show-panel' : 'hidden-panel'}`}
          >
            <div className="ts-profile-container">
              <Row style={{ marginBottom: '10px' }}>
                <Col md={2} className="ts-profile-label">
                  Tax Identification Number (TIN)
                </Col>
                <Col md={10}>
                  <Row style={{ marginBottom: '10px' }}>
                    <Col md={4}>
                      <select
                        name="tinType"
                        disabled={!this.state.isEdit}
                        value={this.state.tinType}
                        onChange={this.changeTinType}
                        className="form-control tin-hidden"
                        required
                      >
                        <option value="">Choose Type</option>
                        <option value="ssn">SSN</option>
                        <option value="ein">EIN</option>
                      </select>
                      <p
                        className="ts-text-invalid ts-text-margin"
                        hidden={this.state.validInputTinType}
                      >
                        This field is required.
                      </p>
                    </Col>
                    <Col hidden={this.state.tinType === 'ein' || !this.state.tinType} md={4}>
                      <input
                        disabled={!this.state.isEdit}
                        name="ssn"
                        className="tin-hidden form-control w9-tin"
                        value={this.state.tin}
                        onChange={this.changeTin}
                        placeholder={`###-##${tinPlaceholderEnd}`}
                      />
                      <p
                        className="ts-text-invalid ts-text-margin"
                        hidden={this.state.validInputTin}
                      >
                        This field is required.
                      </p>
                    </Col>
                    <Col hidden={this.state.tinType === 'ssn' || !this.state.tinType} md={4}>
                      <input
                        disabled={!this.state.isEdit}
                        name="ein"
                        className="tin-hidden form-control w9-tin"
                        value={this.state.tin}
                        onChange={this.changeTin}
                        placeholder={`##-###${tinPlaceholderEnd}`}
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: '10px' }}>
                    <Col md={8}>
                      <label className="private-information-input-label">
                        Name as shown on Income Tax Return
                      </label>
                      <input
                        disabled={!this.state.isEdit}
                        value={this.state.name}
                        onChange={this.changeName}
                        name="name"
                        className="form-control"
                        placeholder="Name as shown on Income Tax Return"
                      />
                      <p
                        className="ts-text-invalid ts-text-margin"
                        hidden={this.state.validInputName}
                      >
                        This field is required.
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <div className="row">
                <Col md={2} className="ts-profile-label">
                  {' '}
                  W-9 Address
                </Col>
                <Col md={10}>
                  {!this.props.forceAllowAddressEdit && (
                    <div className="checkbox checkbox-green" style={{ marginTop: '3px' }}>
                      <input
                        disabled={!this.state.isEdit}
                        type="checkbox"
                        checked={this.state.sameAsCheckAddress}
                        onChange={this.changeCheckPaymentAddress}
                        id="w9Checkbox"
                      />
                      <label htmlFor="w9Checkbox" className="w9-text">
                        Same address as Check Payment address?
                      </label>
                    </div>
                  )}
                  <div className="w9address" hidden={this.state.sameAsCheckAddress}>
                    <Row>
                      <Col md={4}>
                        <label className="private-information-input-label">Street Address</label>
                        <FormControl
                          value={this.state.streetAddress}
                          onChange={this.checkStreetAddress}
                          disabled={!this.state.isEdit}
                        />
                        <p
                          className="ts-text-invalid ts-text-margin"
                          hidden={this.state.validInputStreetAddress}
                        >
                          This field is required.
                        </p>
                      </Col>
                      <Col md={4}>
                        <label className="private-information-input-label">Country</label>
                        <FormControl
                          componentClass="select"
                          disabled={!this.state.isEdit}
                          onChange={this.changeCountry}
                          value={this.state.country}
                        >
                          {countriesOptions.map((country) => (
                            <option value={country.value} key={country.value}>
                              {country.label}
                            </option>
                          ))}
                        </FormControl>
                        <p
                          className="ts-text-invalid ts-text-margin"
                          hidden={this.state.validInputCountry}
                        >
                          This field is required.
                        </p>
                      </Col>
                      <Col md={4}>
                        <label className="private-information-input-label">State</label>
                        <FormControl
                          componentClass="select"
                          disabled={
                            !this.state.isEdit || this.state.country !== DEFAULT_COUNTRY_CODE
                          }
                          onChange={this.changeState}
                          value={this.state.state}
                          placeholder={this.state.state}
                        >
                          <option value="" />
                          {stateOptions.map((state) => (
                            <option value={state.value} key={state.value}>
                              {state.label}
                            </option>
                          ))}
                        </FormControl>
                        <p
                          className="ts-text-invalid ts-text-margin"
                          hidden={this.state.validInputState}
                        >
                          This field is required.
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginBottom: '10px' }}>
                      <Col md={4}>
                        <label className="private-information-input-label">Zip Code</label>
                        <FormControl
                          value={this.state.zipcode}
                          disabled={!this.state.isEdit}
                          onChange={this.checkZipCode}
                          placeholder="Zip Code"
                        />
                        <p
                          className="ts-text-invalid ts-text-margin"
                          hidden={this.state.validInputZipCode}
                        >
                          {' '}
                          This field is required.
                        </p>
                      </Col>
                      <Col md={4}>
                        <label className="private-information-input-label">City</label>
                        <FormControl
                          value={this.state.city}
                          disabled={!this.state.isEdit}
                          onChange={this.checkCity}
                          placeholder="City"
                        />
                        <p
                          className="ts-text-invalid ts-text-margin"
                          hidden={this.state.validInputCity}
                        >
                          This field is required.
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </div>
            </div>
          </Panel>
        </Col>
        <ToastContainer
          closeButton={false}
          position={toast.POSITION.BOTTOM_RIGHT}
          hideProgressBar
        />
      </div>
    );
  }
}

export default W9Information;
