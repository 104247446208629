import { components, SingleValueProps, Props as ReactSelectProps, OptionProps } from 'react-select';
import React, { useState, VoidFunctionComponent } from 'react';

import View from '../View';
import { Large } from '../Typography';
import SelectRounded from '../SelectRounded';
import { OptionType } from '../Select/types';
import { EmotionStyle, useEmotionTheme, EmotionTheme } from '../../core/styled';

interface TherapistSelectProps {
  style?: EmotionStyle;
  options: TherapistOptionType[];
  value: TherapistOptionType;
  onChange?: ReactSelectProps['onChange'];
  dataQa?: string;
  unlimitedListHeight?: boolean;
  maxMenuHeight?: number;
}

interface JSXElementProps {
  isFocused?: boolean;
  isMouseUser?: boolean;
  isHover?: boolean;
}

interface OptionAndValueProps extends JSXElementProps {
  data: TherapistOptionType;
}

interface CustomSelectProps extends React.ComponentProps<typeof SelectRounded> {
  unlimitedListHeight: TherapistSelectProps['unlimitedListHeight'];
}

interface ElementPropsWithColor extends JSXElementProps {
  colors?: EmotionTheme['colors'];
}

export interface TherapistOptionType extends OptionType<string> {
  jsxElement?: (props: ElementPropsWithColor) => JSX.Element;
  breakRow?: boolean;
}

const OptionAndValue = ({ data, isFocused, isMouseUser, isHover }: OptionAndValueProps) => {
  const { colors } = useEmotionTheme();
  const { jsxElement, label } = data;
  return (
    <View>
      <Large
        variant="large"
        style={{
          textOverflow: 'ellipsis',
          letterSpacing: '-0.1px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          display: 'unset',
          color: colors.slateGrey,
        }}
      >
        {label}
      </Large>
      {jsxElement && <View>{jsxElement({ colors, isFocused, isMouseUser, isHover })}</View>}
    </View>
  );
};

const SelectOption = (props: OptionProps<TherapistOptionType, false>) => {
  const {
    data,
    isFocused,
    selectProps: {
      selectProps: { isMouseUser },
    },
  } = props;
  const [isHover, setIsHover] = useState(false);
  const onMouseEnter = () => {
    setIsHover(true);
  };
  const onMouseLeave = () => {
    setIsHover(false);
  };
  return (
    <View onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <components.Option {...props}>
        <OptionAndValue
          data={data}
          isFocused={isFocused}
          isMouseUser={isMouseUser}
          isHover={isHover}
        />
      </components.Option>
    </View>
  );
};

const SelectValue = (props: SingleValueProps<TherapistOptionType>) => {
  const { data } = props;
  return (
    <components.SingleValue {...props}>
      <OptionAndValue data={data} />
    </components.SingleValue>
  );
};

const CustomSelect = (props: CustomSelectProps) => {
  const { unlimitedListHeight, maxMenuHeight } = props;
  const { colors } = useEmotionTheme();

  return (
    <SelectRounded
      width="100%"
      maxMenuHeight={maxMenuHeight}
      styles={{
        valueContainer: (provided) => {
          return {
            ...provided,
            overflowY: 'hidden',
            position: 'initial',
            padding: 0,
          };
        },
        option: (provided, { isFocused, selectProps }) => {
          const focusRingStyles =
            isFocused && selectProps.isMouseUser ? { border: 'none', boxShadow: 'none' } : {};
          return {
            ...provided,
            // prevents an options focus ring from being obscured by the menu container
            margin: isFocused && 1,
            ...focusRingStyles,
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 36,
            minHeight: 34,
            '&:hover': {
              background: colors.permaLinkWaterGrey,
            },
          };
        },
        singleValue: (provided) => {
          return {
            ...provided,
            position: 'initial',
            transform: 'initial',
          };
        },
        control: (provided) => {
          return {
            ...provided,
            minHeight: 47,
            paddingTop: 13,
            paddingLeft: 23,
            paddingBottom: 13,
            border: `solid 1px ${colors.permaWildBlueYonder}`,
            '&:hover': {
              border: `solid 1px ${colors.permaWildBlueYonder}`,
            },
          };
        },
        dropdownIndicator: (provided) => {
          return {
            ...provided,
            padding: '0 8px',
            color: colors.permaTalkspaceDarkGreen,
            '&:hover': { color: colors.permaTalkspaceDarkGreen },
          };
        },
        menu: (provided) => {
          return {
            ...provided,
            paddingRight: 0,
            paddingTop: 13,
            paddingBottom: 0,
            paddingLeft: 0,
            height: maxMenuHeight,
            left: -1,
            boxShadow: `0px 4px 10px -4px rgb(0 40 65 / 40%)`,
          };
        },
        menuList: (provided) => {
          const additionalStyle = unlimitedListHeight ? { maxHeight: 'none' } : {};
          return {
            ...provided,
            paddingRight: 10,
            ...additionalStyle,
          };
        },
        input: (provided) => {
          return { ...provided, margin: 0 };
        },
      }}
      components={{ Option: SelectOption, SingleValue: SelectValue }}
      {...props}
    />
  );
};

const TherapistSelect: VoidFunctionComponent<TherapistSelectProps> = ({
  options,
  value,
  style,
  dataQa = 'elementSelect',
  onChange,
  unlimitedListHeight = false,
  maxMenuHeight = 210,
}) => (
  <View style={style}>
    <CustomSelect
      maxMenuHeight={maxMenuHeight}
      unlimitedListHeight={unlimitedListHeight}
      options={options}
      value={value}
      onChange={onChange}
      dataQa={dataQa}
      placeholder="Select"
    />
  </View>
);

export default TherapistSelect;
