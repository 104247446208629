import styled from '../../core/styled';
import { webOnlyStyle } from '../../core/styleHelpers';
import View from '../View';

const BarElement = styled(View)<{ value: number; isCurrentHour: boolean }>(
  ({ value, isCurrentHour, theme: { colors } }) => {
    const minPixels = 7;
    let height = value * 64;
    if (height < minPixels) {
      height = minPixels;
    }

    const isInactive = value === 0;
    let backgroundColor;
    if (isCurrentHour) {
      backgroundColor = colors.rebeccaPurple;
    } else if (isInactive) {
      backgroundColor = colors.a11yLinkWaterGrey;
    } else {
      backgroundColor = colors.valerianPurple;
    }

    return {
      flex: 1,
      height: isInactive ? 6 : height,
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      borderBottomRightRadius: 2,
      borderBottomLeftRadius: 2,
      backgroundColor,
      ...webOnlyStyle({
        transition: 'height .3s',
      }),
    };
  }
);

export default BarElement;
