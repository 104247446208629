import { combineReducers } from 'redux';
import { RESET_INITIAL_ROOM_STATE } from '../constants/RoomConstants';
import availability from './availability';
import auth from './auth';
import customerInformation from './customerInformation';
import invite from './invites';
import informedConsent from './informedConsent';
import emergencyContact from './emergencyContact';
import matching from './matching';
import marketing from './marketing';
import clinicalInformation from './clinicalInformation';
import medicalInformation from './medicalInformation';
import outcomeMeasures from './outcomeMeasures';
import treatmentPlanner from './treatmentPlanner';
import room from './room';
import journey from './journey';

const appReducer = combineReducers({
  availability,
  auth,
  customerInformation,
  medicalInformation,
  informedConsent,
  emergencyContact,
  invite,
  matching,
  marketing,
  clinicalInformation,
  outcomeMeasures,
  treatmentPlanner,
  room,
  journey,
});

const rootReducer = (state, action) => {
  // https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
  if (action.type === RESET_INITIAL_ROOM_STATE) return appReducer(undefined, action);
  return appReducer(state, action);
};

export default rootReducer;
