import { FunctionComponent } from 'react';
import * as React from 'react';
import styled from '@emotion/styled';
import { EmotionStyle } from '../../core/styled';

interface SliderViewProps {
  value: number;
  maxValue: number;
  minValue?: number;
  className?: string;
  style?: EmotionStyle;
  onValueChange?: (value: number) => void;
  step?: number;
}

const Component = styled.input({});

const SliderView: FunctionComponent<SliderViewProps> = ({
  step,
  style,
  value,
  minValue,
  maxValue,
  className,
  onValueChange,
}) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onValueChange) onValueChange(Number(e.target.value));
  };
  return (
    <Component
      type="range"
      value={value}
      style={style}
      max={maxValue}
      onChange={onChange}
      onClick={(e) => e.stopPropagation()}
      min={minValue || 0}
      className={className}
      step={step}
    />
  );
};

export default SliderView;
