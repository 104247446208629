import storage from '@/core/storage/sessionStorage';

export const SKIP_ONBOARDING_ALL_DONE_STORAGE_KEY = 'skipOnboardingAllDone';

export const getSkipOnboardingAllDone = () =>
  JSON.parse(storage.getItem(SKIP_ONBOARDING_ALL_DONE_STORAGE_KEY) || 'false');
export const setSkipOnboardingAllDone = () =>
  storage.setItem(SKIP_ONBOARDING_ALL_DONE_STORAGE_KEY, 'true');
export const removeSkipOnboardingAllDone = () =>
  storage.removeItem(SKIP_ONBOARDING_ALL_DONE_STORAGE_KEY);
