import { useQuery } from 'react-query';
import apiHelper from '@/core/api/apiHelper';
import apiWrapper from '@/core/api/apiWrapper';
import adminConfigKeys from './queryKeys';
import { AdminConfigName, PublicAdminConfigName } from '../types';

const fetchAdminConfig =
  (configName: AdminConfigName, isJSON: boolean) => async (): Promise<boolean> => {
    const jsonString = isJSON ? '&json=true' : '';
    const response = await apiWrapper.get(
      `${apiHelper().apiEndpoint}/v3/users/get-admin-config-value?key=${configName}${jsonString}`
    );
    return Boolean(response.data.data);
  };

export const useTSAdminConfig = (configName: AdminConfigName, isJSON: boolean = false) =>
  useQuery({
    queryKey: adminConfigKeys.config(configName),
    queryFn: fetchAdminConfig(configName, isJSON),
    staleTime: Infinity,
  });

const fetchPublicAdminConfig =
  (configName: PublicAdminConfigName) => async (): Promise<boolean> => {
    const response = await apiWrapper.get(
      `${apiHelper().publicAPIEndpoint}/public/v1/get-admin-config-value?key=${configName}`
    );
    return Boolean(response.data.data);
  };

export const useTSPublicAdminConfig = (configName: PublicAdminConfigName) =>
  useQuery({
    queryKey: adminConfigKeys.publicConfig(configName),
    queryFn: fetchPublicAdminConfig(configName),
    staleTime: Infinity,
  });
