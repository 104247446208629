import styled from '../../core/styled';
import commonStyles from '../../constants/commonStyles';

const { visiblyHidden } = commonStyles;

const HiddenLabel = styled.label({
  ...visiblyHidden,
});

export default HiddenLabel;
