import View from '../View';
import styled from '../../core/styled';
// A simple view on web

interface KeyboardAvoidingViewProps {
  enabled?: boolean;
  behavior?: 'height' | 'position' | 'padding';
}

const KeyboardAvoidingView = styled(View)<KeyboardAvoidingViewProps>();

export default KeyboardAvoidingView;
