import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const standardPath =
  'M7.00001 0C6.44689 0 6.00001 0.446875 6.00001 1V1.6C3.71876 2.0625 2.00001 4.08125 2.00001 6.5V7.0875C2.00001 8.55625 1.45939 9.975 0.484387 11.075L0.253137 11.3344C-0.00936282 11.6281 -0.0718628 12.05 0.0875122 12.4094C0.246887 12.7688 0.606262 13 1.00001 13H13C13.3938 13 13.75 12.7688 13.9125 12.4094C14.075 12.05 14.0094 11.6281 13.7469 11.3344L13.5156 11.075C12.5406 9.975 12 8.55937 12 7.0875V6.5C12 4.08125 10.2813 2.0625 8.00001 1.6V1C8.00001 0.446875 7.55314 0 7.00001 0ZM8.41564 15.4156C8.79064 15.0406 9.00001 14.5312 9.00001 14H7.00001H5.00001C5.00001 14.5312 5.20939 15.0406 5.58439 15.4156C5.95939 15.7906 6.46876 16 7.00001 16C7.53126 16 8.04064 15.7906 8.41564 15.4156Z';
const majorPath =
  'M11.9999 2.79297C11.3085 2.79297 10.7499 3.35156 10.7499 4.04297V4.79297C7.8983 5.37109 5.74986 7.89453 5.74986 10.918V11.9102C5.74986 13.6836 5.14439 15.4062 4.03892 16.7891L3.45689 17.5195C3.23033 17.8008 3.18736 18.1875 3.34361 18.5117C3.49986 18.8359 3.82798 19.043 4.18736 19.043H19.8124C20.1717 19.043 20.4999 18.8359 20.6561 18.5117C20.8124 18.1875 20.7694 17.8008 20.5428 17.5195L19.9608 16.793C18.8553 15.4062 18.2499 13.6836 18.2499 11.9102V10.918C18.2499 7.89453 16.1014 5.37109 13.2499 4.79297V4.04297C13.2499 3.35156 12.6913 2.79297 11.9999 2.79297ZM11.9999 6.54297C14.4178 6.54297 16.3749 8.5 16.3749 10.918V11.9102C16.3749 13.7812 16.9178 15.6055 17.9256 17.168H6.07408C7.08189 15.6055 7.62486 13.7812 7.62486 11.9102V10.918C7.62486 8.5 9.58189 6.54297 11.9999 6.54297ZM14.4999 20.293H11.9999H9.49986C9.49986 20.957 9.76158 21.5938 10.2303 22.0625C10.6991 22.5312 11.3358 22.793 11.9999 22.793C12.6639 22.793 13.3006 22.5312 13.7694 22.0625C14.2381 21.5938 14.4999 20.957 14.4999 20.293Z';

const pathConfig: IconConfig = {
  standard: {
    default: {
      path: standardPath,
    },
  },
  major: {
    default: {
      path: majorPath,
    },
  },
};

const BellOutline = withDSIconMaker(pathConfig, 'bell outline');

export default BellOutline;
