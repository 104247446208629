const referralOptions = [
  {
    key: 'referralSource',
    value: 'search-engines',
    label: 'Search Engine',
  },
  {
    key: 'referralSource',
    value: 'facebook',
    label: 'Facebook',
  },
  {
    key: 'referralSource',
    value: 'instagram',
    label: 'Instagram',
  },
  {
    key: 'referralSource',
    value: 'twitter',
    label: 'Twitter',
  },
  {
    key: 'referralSource',
    value: 'tiktok',
    label: 'Tik Tok',
  },
  {
    key: 'referralSource',
    value: 'online-article',
    label: 'Online article',
  },
  {
    key: 'referralSource',
    value: 'tv-cable',
    label: 'Cable TV',
  },
  {
    key: 'referralSource',
    value: 'tv-streaming',
    label: 'Digital/Streaming TV',
  },
  {
    key: 'referralSource',
    value: 'podcast',
    label: 'Podcast',
  },
  {
    key: 'referralSource',
    value: 'insurance',
    label: 'My Insurance',
  },
  {
    key: 'referralSource',
    value: 'employer',
    label: 'My Employer/Work',
  },
  {
    key: 'referralSource',
    value: 'school',
    label: 'School/university',
  },
  {
    key: 'referralSource',
    value: 'email',
    label: 'Email',
  },
  {
    key: 'referralSource',
    value: 'radio',
    label: 'Radio',
  },
  {
    key: 'referralSource',
    value: 'friend',
    label: 'Friend',
  },
  {
    key: 'referralSource',
    value: 'online-ad',
    label: 'Online ad',
  },
  {
    key: 'referralSource',
    value: 'app-store',
    label: 'App Store',
  },
  {
    key: 'referralSource',
    value: 'zocdoc',
    label: 'Zocdoc',
  },
  {
    key: 'referralSource',
    value: 'amazonhcp',
    label: 'Amazon Health Condition Programs',
  },
  {
    key: 'referralSource',
    value: 'anthem',
    label: 'Anthem',
  },
  {
    key: 'referralSource',
    value: 'other',
    label: 'Other',
  },
];

export default referralOptions;
