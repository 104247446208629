import { useState } from 'react';
import {
  BaseButton,
  Big,
  SparklingCalendar,
  Huge,
  useEmotionTheme,
  Tray,
} from '@talkspace/react-toolkit';
import { useHistory } from '@/core/routerLib';

interface Props {
  onBookLaterPress: () => void;
}
const BookLaterModal = ({ onBookLaterPress }: Props) => {
  const [show, setShow] = useState<boolean>(true);
  const { colors } = useEmotionTheme();
  const history = useHistory();

  const handleClose = () => {
    setShow(false);
    history.goBack();
  };

  if (!show) return null;

  return (
    <Tray handleClose={handleClose} style={{ paddingTop: 48 }}>
      <SparklingCalendar />
      <Huge style={{ marginTop: 16, marginBottom: 30 }}>
        Are you sure? We’ll be able to find your therapist sooner if you book an available time.
      </Huge>
      <BaseButton
        dataQa="goBackToBooking"
        roundedFocusStyle
        primaryColor={colors.green}
        style={{ color: colors.green }}
        onPress={handleClose}
      >
        <Big variant="bigBlueStoneNew">Go back to booking</Big>
      </BaseButton>
      <BaseButton
        dataQa="bookLaterPress"
        roundedFocusStyle
        primaryColor={colors.grey}
        onPress={onBookLaterPress}
        style={{ paddingTop: 44 }}
      >
        <Big variant="bigDarkGrey">I’ll book a session later</Big>
      </BaseButton>
    </Tray>
  );
};

export default BookLaterModal;
