import Svg, { Path } from 'svgs';
import { useEmotionTheme } from '../../core/styled';

export interface ThreeDotsProps {
  width?: number;
  height?: number;
  color?: string;
}

function ThreeDots({ width = 3, height = 13, color, ...otherProps }: ThreeDotsProps) {
  const { colors } = useEmotionTheme();
  const titleText = '3 dots';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 3 13"
      {...otherProps}
    >
      <Path
        d="M-3.5 8a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm5 0a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm5 0a1.5 1.5 0 110-3 1.5 1.5 0 010 3z"
        fill={color || colors.fordGrey}
        transform="rotate(90 1.5 6.5)"
        stroke="none"
      />
    </Svg>
  );
}

export default ThreeDots;
