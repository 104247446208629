export const REQUEST_GET_INVITE = 'REQUEST_GET_INVITE';
export const REQUEST_GET_INVITE_ERROR = 'REQUEST_GET_INVITE_ERROR';
export const RECEIVE_GET_INVITE = 'RECEIVE_GET_INVITE';

export const REQUEST_POST_INVITE = 'REQUEST_POST_INVITE';
export const REQUEST_POST_INVITE_ERROR = 'REQUEST_POST_INVITE_ERROR';
export const RECEIVE_POST_INVITE = 'RECEIVE_POST_INVITE';

export const REQUEST_PATCH_INVITE = 'REQUEST_PATCH_INVITE';
export const REQUEST_PATCH_INVITE_ERROR = 'REQUEST_PATCH_INVITE_ERROR';
export const RECEIVE_PATCH_INVITE = 'RECEIVE_PATCH_INVITE';

export const REQUEST_DELETE_INVITE = 'REQUEST_DELETE_INVITE';
export const REQUEST_DELETE_INVITE_ERROR = 'REQUEST_DELETE_INVITE_ERROR';
export const RECEIVE_DELETE_INVITE = 'RECEIVE_DELETE_INVITE';
