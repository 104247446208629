import { VoidFunctionComponent } from 'react';
import Svg, { Path } from 'svgs';
import { useEmotionTheme, EmotionStyle } from '../../core/styled';

interface DoubleChevronsProps {
  width?: number;
  height?: number;
  color?: string;
  style?: EmotionStyle;
}

const DoubleChevrons: VoidFunctionComponent<DoubleChevronsProps> = ({
  width = 15,
  height = 11,
  color,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'double chevrons';
  return (
    <Svg width={width} height={height} title={titleText} aria-label={titleText} {...otherProps}>
      <Path
        d="m1.707.293 4.95 4.95-4.95 4.95A1 1 0 0 1 .293 8.777l3.535-3.535L.293 1.707A1 1 0 0 1 1.707.293Zm6 0 4.95 4.95-4.95 4.95a1 1 0 0 1-1.414-1.415l3.535-3.535-3.535-3.536A1 1 0 0 1 7.707.293Z"
        fill={color || colors.permaMoonStone}
        fillRule="evenodd"
      />
    </Svg>
  );
};

export default DoubleChevrons;
