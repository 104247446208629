import * as React from 'react';
import {
  Spinner,
  View,
  Massive,
  Password,
  Button,
  Small,
  Link,
  Text,
  RedX,
} from '@talkspace/react-toolkit';
import TwoFactorAuth from '../TwoFactorAuth';
import { COLORS } from '../../utils/design';
import { webOnlyStyle } from '../../core/styled/styleHelpers';
import styled from '../../core/styled';

const SpinnerStyles = {
  width: 63,
  height: 63,
  marginTop: 50,
};

const SubmitButton = styled(Button)({
  fontSize: 19,
  fontWeight: 'bold',
  width: 335,
  marginTop: 20,
  color: COLORS.white,
});

const ResultWrapper = styled(View)({
  alignSelf: 'center',
  alignItems: 'center',
  marginTop: 50,
});

const ErrorTitle = styled(Text)({
  fontWeight: 800,
  fontSize: 29,
  marginTop: 25,
});

const ErrorSubtitle = styled(Text)({
  fontSize: 16,
  color: '#454A5E',
  marginTop: 13,
  marginBottom: 42,
});

// TODO: @ERIC implement react-hook-form to make this form accessible
const ChangePassword = ({
  otpToken,
  isLoading,
  isVerified,
  userPhone,
  verify2FA,
  resendCode,
  show2faInput,
  error,
  showChangePasswordForm,
  password,
  resetPasswordErrors,
  onPasswordValidationChanged,
  onPasswordChange,
  onConfirmedPasswordChange,
  passwordError,
  confirmedPassword,
  resetConfirmedPasswordErrors,
  confirmedPasswordError,
  handleChangePasswordClick,
}) => {
  const onChange =
    (changeHandler: React.Dispatch<React.SetStateAction<string>>) =>
    (e: React.ChangeEvent<HTMLInputElement>) =>
      changeHandler(e.target.value);
  return (
    <View>
      {isLoading ? (
        <Spinner primaryColor={COLORS.loblollyGrey} style={SpinnerStyles} />
      ) : (
        <View>
          {show2faInput && (
            <TwoFactorAuth
              title="Change password"
              userPhone={userPhone}
              otpToken={otpToken}
              resendCode={resendCode}
              verify2FA={verify2FA}
              isRememberMeCheckboxVisible={false}
              isLoading={isLoading}
              isVerified={isVerified}
              error={error}
            />
          )}
          {error && error.type === 'fatalServerError' && (
            <ResultWrapper>
              <RedX size={74} />
              <ErrorTitle>Something went wrong</ErrorTitle>
              <ErrorSubtitle>Please try again later</ErrorSubtitle>
            </ResultWrapper>
          )}
          {showChangePasswordForm && (
            <View align="center">
              <View
                align="center"
                style={{
                  marginTop: 60,
                  marginBottom: 29,
                }}
              >
                <Massive style={{ marginBottom: 10, textAlign: 'center' }}>Change password</Massive>
              </View>
              <View flex={1} align="center">
                <Password
                  showInfoMessage
                  shouldCheckStrength={password.length > 0}
                  label="New password"
                  value={password}
                  minValidScore={3}
                  onFocus={resetPasswordErrors}
                  onValidationChanged={onPasswordValidationChanged}
                  placeholder="Enter new password"
                  onChange={onChange(onPasswordChange)}
                  errorMessage={passwordError}
                  shouldDisplayError={!!passwordError}
                  containerStyle={{ marginBottom: 18 }}
                  infoMessageContent="At least 8 characters, no special characters."
                  scoreStyle={{ flexDirection: 'row' }}
                  dataQa="forgotPasswordNewPassword"
                />
                <Password
                  isConfirmationInput
                  label="Confirm new password"
                  value={confirmedPassword}
                  onFocus={resetConfirmedPasswordErrors}
                  placeholder="Confirm new password"
                  onChange={onChange(onConfirmedPasswordChange)}
                  errorMessage={confirmedPasswordError}
                  shouldDisplayError={!!confirmedPasswordError}
                  containerStyle={{ marginBottom: 0 }}
                  dataQa="forgotPasswordConfirmPassword"
                />
                <SubmitButton
                  onPress={handleChangePasswordClick}
                  text="Save"
                  disabled={isLoading}
                  data-qa="forgotPasswordSubmit"
                />
                <View style={{ marginTop: 20 }}>
                  <Small>
                    Need help?{' '}
                    <Link
                      target="_blank"
                      href="https://help.talkspace.com"
                      style={{ marginLeft: 0, marginRight: 0 }}
                    >
                      <Small
                        inline
                        style={{
                          ...webOnlyStyle({ display: 'inline' }),
                          color: COLORS.green,
                          fontWeight: 700,
                        }}
                      >
                        Customer support
                      </Small>
                    </Link>
                  </Small>
                </View>
              </View>
            </View>
          )}
        </View>
      )}
    </View>
  );
};

export default ChangePassword;
