import { Link, Standard, View, styled } from '@talkspace/react-toolkit';
import moment from 'moment';
import useQueryParentalConsent, {
  ConsentStatusEnum,
} from 'ts-frontend/hooks/parentalConsent/useQueryParentalConsent';
import useMutationSubmitExemption from 'ts-frontend/hooks/parentalConsent/useMutationSubmitExemption';
import useMutationSubmitManualConsent from 'ts-frontend/hooks/parentalConsent/useMutationSubmitManualConsent';
import useMutationResubmitParentalConsent from 'ts-frontend/hooks/parentalConsent/useMutationResubmitParentalConsent';
import { useState } from 'react';
import UserActionCardV2 from '../../../../Reusable/UserActionCardV2/UserActionCard';
import ssoHelper from '../../../../../modules/utils/sso';
import ToastMessage from '../../../../Reusable/ToastMessage/ToastMessage';

const StyledLink = styled(Link)(({ theme: { colors } }) => {
  return {
    color: colors.accessibilityGreenDark,
    textDecoration: 'underline',
    fontWeight: 700,
    margin: 0,
    '&:hover': {
      color: colors.accessibilityGreenDark,
    },
  };
});

const Text = styled(Standard)(({ theme: { colors } }) => {
  return {
    fontWeight: 400,
    fontSize: 16,
    color: colors.purple600,
  };
});

const ToastContainer = styled(View)(({ theme: { colors } }) => {
  return {
    width: 314,
    boxShadow: '0px 4px 10px 0px #00284166',
    position: 'fixed',
    background: 'white',
    borderRadius: 10,
    padding: 20,
    bottom: 20,
    left: 30,
  };
});

const getParentalConsentContent = (
  status: ConsentStatusEnum,
  emailSentAt: Date,
  isResent: boolean
) => {
  switch (status) {
    case 'consentSkippedByTeen':
      return (
        <Text>
          The teen indicated that they may be exempt from parent or guardian consent. To verify,
          follow up using the{' '}
          <StyledLink onClick={() => ssoHelper.openZendesk('/hc/en-us/articles/360056630292')}>
            parent consent exemption process.
          </StyledLink>
        </Text>
      );
    case 'emailSent':
      return (
        <Text>
          The consent form was {isResent ? 'resent' : 'sent'} to their parent or guardian on{' '}
          {moment(emailSentAt).format('MMMM D, YYYY')}. Follow up with the teen to make sure this is
          completed. If needed, you can gather
          <br />
          <StyledLink
            onClick={() =>
              ssoHelper.openZendesk('hc/en-us/articles/360056630292#h_01H8FJHYPRA6X5KEDD53FVR8MT')
            }
          >
            manual parent or guardian consent.
          </StyledLink>
        </Text>
      );
    case 'providerApprovedExemption':
      return <View>Teen is exempt from parent or guardian consent</View>;
    case 'providerApprovedManualConsent':
      return <View>Consent received manually</View>;
    default:
      return <View>Consent has been completed</View>;
  }
};

const getMessage = (status: ConsentStatusEnum, displayedDate: Date | null) => {
  if (['consentSkippedByTeen', 'emailSent'].includes(status) || !displayedDate) {
    return undefined;
  }
  if (status === 'providerApprovedExemption') {
    return `Verified on ${moment(displayedDate).format('MMMM D, yyyy')}`;
  }
  return `Completed on ${moment(displayedDate).format('MMMM D, yyyy')}`;
};

function ParentalConsent({ clientUserID }: { clientUserID: number }) {
  const { data: parentalConsentData, isFetching, isError } = useQueryParentalConsent(clientUserID);

  const { mutate: submitExemption, isLoading: isSubmitExemptionLoading } =
    useMutationSubmitExemption();
  const { mutate: submitManualConsent, isLoading: isSubmitManualConsentLoading } =
    useMutationSubmitManualConsent();
  const { mutate: resubmitParentalConsent, isLoading: isResubmitParentalConsentLoading } =
    useMutationResubmitParentalConsent();

  const [isToastHidden, setIsToastHidden] = useState<boolean>(true);

  const getButtons = (status: ConsentStatusEnum) => {
    switch (status) {
      case 'emailSent':
        return [
          {
            id: 'primary',
            title: 'Ask teen to resubmit consent form',
            clickHandler: () => {
              resubmitParentalConsent(clientUserID, { onSuccess: () => setIsToastHidden(false) });
            },
          },
          {
            id: 'secondary',
            isSecondary: true,
            title: 'I received consent manually',
            clickHandler: () => submitManualConsent(clientUserID),
          },
        ];
      case 'consentSkippedByTeen':
        return [
          {
            id: 'primary',
            title: 'I verify that this teen is exempt',
            clickHandler: () => submitExemption(clientUserID),
          },
          {
            id: 'secondary',
            isSecondary: true,
            title: 'Ask teen to resubmit consent form',
            clickHandler: () => {
              resubmitParentalConsent(clientUserID, { onSuccess: () => setIsToastHidden(false) });
            },
          },
        ];
      default:
        return [];
    }
  };

  if (parentalConsentData) {
    const {
      id,
      consentStatus,
      emailSentAt,
      consentGrantedAt,
      providerApprovedExemptionAt,
      providerApprovedManualConsentAt,
      previouslySentTimes,
    } = parentalConsentData;

    const displayedDate =
      consentGrantedAt || providerApprovedExemptionAt || providerApprovedManualConsentAt;

    const isResent = previouslySentTimes > 1;

    return (
      <>
        <UserActionCardV2
          key={id}
          buttons={getButtons(consentStatus)}
          content={getParentalConsentContent(consentStatus, emailSentAt, isResent)}
          isUpdating={false}
          isError={isError}
          showSpinner={
            isFetching ||
            isSubmitExemptionLoading ||
            isSubmitManualConsentLoading ||
            isResubmitParentalConsentLoading
          }
          preferredName="Parent or guardian"
          spinnerMessage={getMessage(consentStatus, displayedDate)}
          type="parentConsent"
        />
        {!isToastHidden && (
          <ToastContainer>
            <ToastMessage
              message="We asked the client to resubmit the consent form"
              onDismiss={() => setIsToastHidden(true)}
            />
          </ToastContainer>
        )}
      </>
    );
  }
  return <></>;
}

export default ParentalConsent;
