import { ScriptCategory } from '../../entities/Scripts';

export interface ScriptsState {
  scriptCategories: ScriptCategory[];
  isUpdating: boolean;
  isError: boolean;
}

export interface ScriptsStore {
  scripts: ScriptsState;
}

export interface GetScriptsPayload {
  scriptCategories: ScriptCategory[];
}
export interface PostEditScriptPayload {
  scriptCategories: ScriptCategory[];
}
export interface PostRestoreScriptPayload {
  scriptCategories: ScriptCategory[];
}
export interface PostDeleteScriptPayload {
  scriptCategories: ScriptCategory[];
}
export interface PostCreateScriptPayload {
  scriptCategories: ScriptCategory[];
}

export const REQUEST_GET_SCRIPTS = 'REQUEST_GET_SCRIPTS';
export const REQUEST_GET_SCRIPTS_ERROR = 'REQUEST_GET_SCRIPTS_ERROR';
export const RECEIVE_GET_SCRIPTS = 'RECEIVE_GET_SCRIPTS';

export const REQUEST_POST_EDIT_SCRIPT = 'REQUEST_POST_EDIT_SCRIPT';
export const REQUEST_POST_EDIT_SCRIPT_ERROR = 'REQUEST_POST_EDIT_SCRIPT_ERROR';
export const RECEIVE_POST_EDIT_SCRIPT = 'RECEIVE_POST_EDIT_SCRIPT';

export const REQUEST_POST_RESTORE_SCRIPT = 'REQUEST_POST_RESTORE_SCRIPT';
export const REQUEST_POST_RESTORE_SCRIPT_ERROR = 'REQUEST_POST_RESTORE_SCRIPT_ERROR';
export const RECEIVE_POST_RESTORE_SCRIPT = 'RECEIVE_POST_RESTORE_SCRIPT';

export const REQUEST_POST_DELETE_SCRIPT = 'REQUEST_POST_DELETE_SCRIPT';
export const REQUEST_POST_DELETE_SCRIPT_ERROR = 'REQUEST_POST_DELETE_SCRIPT_ERROR';
export const RECEIVE_POST_DELETE_SCRIPT = 'RECEIVE_POST_DELETE_SCRIPT';

export const REQUEST_POST_CREATE_SCRIPT = 'REQUEST_POST_CREATE_SCRIPT';
export const REQUEST_POST_CREATE_SCRIPT_ERROR = 'REQUEST_POST_CREATE_SCRIPT_ERROR';
export const RECEIVE_POST_CREATE_SCRIPT = 'RECEIVE_POST_CREATE_SCRIPT';

interface RequestGetScriptsAction {
  type: typeof REQUEST_GET_SCRIPTS;
}
interface RequestScriptsErrorAction {
  type: typeof REQUEST_GET_SCRIPTS_ERROR;
  error: Error;
}
interface ReceiveGetScriptsAction {
  type: typeof RECEIVE_GET_SCRIPTS;
  payload: GetScriptsPayload;
}

interface RequestPostEditScript {
  type: typeof REQUEST_POST_EDIT_SCRIPT;
}
interface RequestPostEditScriptError {
  type: typeof REQUEST_POST_EDIT_SCRIPT_ERROR;
  error: Error;
}
interface ReceivePostEditScript {
  type: typeof RECEIVE_POST_EDIT_SCRIPT;
  payload: PostEditScriptPayload;
}

interface RequestPostRestoreScript {
  type: typeof REQUEST_POST_RESTORE_SCRIPT;
}
interface RequestPostRestoreScriptError {
  type: typeof REQUEST_POST_RESTORE_SCRIPT_ERROR;
  error: Error;
}
interface ReceivePostRestoreScript {
  type: typeof RECEIVE_POST_RESTORE_SCRIPT;
  payload: PostRestoreScriptPayload;
}

interface RequestPostDeleteScript {
  type: typeof REQUEST_POST_DELETE_SCRIPT;
}
interface RequestPostDeleteScriptError {
  type: typeof REQUEST_POST_DELETE_SCRIPT_ERROR;
  error: Error;
}
interface ReceivePostDeleteScript {
  type: typeof RECEIVE_POST_DELETE_SCRIPT;
  payload: PostDeleteScriptPayload;
}

interface RequestPostCreateScript {
  type: typeof REQUEST_POST_CREATE_SCRIPT;
}
interface RequestPostCreateScriptError {
  type: typeof REQUEST_POST_CREATE_SCRIPT_ERROR;
  error: Error;
}
interface ReceivePostCreateScript {
  type: typeof RECEIVE_POST_CREATE_SCRIPT;
  payload: PostCreateScriptPayload;
}

export type ScriptsActionTypes =
  | RequestGetScriptsAction
  | RequestScriptsErrorAction
  | ReceiveGetScriptsAction
  | RequestPostEditScript
  | RequestPostEditScriptError
  | ReceivePostEditScript
  | RequestPostRestoreScript
  | RequestPostRestoreScriptError
  | ReceivePostRestoreScript
  | RequestPostDeleteScript
  | RequestPostDeleteScriptError
  | ReceivePostDeleteScript
  | RequestPostCreateScript
  | RequestPostCreateScriptError
  | ReceivePostCreateScript;
