import Svg, { Circle, Path } from 'svgs';

interface ProfilePlaceholderProps {
  backgroundColor: string;
  iconColor: string;
}

function ProfilePlaceholder(props: ProfilePlaceholderProps) {
  const { backgroundColor, iconColor } = props;
  const title = 'placeholder';
  return (
    <Svg width={64} height={64} viewBox="0 0 64 64" title={title} aria-label={title} fill="none">
      <Circle cx="32" cy="32" r="32" fill={backgroundColor} />
      <Path
        d="M32 32C33.5913 32 35.1174 31.3679 36.2426 30.2426C37.3679 29.1174 38 27.5913 38 26C38 24.4087 37.3679 22.8826 36.2426 21.7574C35.1174 20.6321 33.5913 20 32 20C30.4087 20 28.8826 20.6321 27.7574 21.7574C26.6321 22.8826 26 24.4087 26 26C26 27.5913 26.6321 29.1174 27.7574 30.2426C28.8826 31.3679 30.4087 32 32 32ZM29.8578 34.25C25.2406 34.25 21.5 37.9906 21.5 42.6078C21.5 43.3766 22.1234 44 22.8922 44H41.1078C41.8766 44 42.5 43.3766 42.5 42.6078C42.5 37.9906 38.7594 34.25 34.1422 34.25H29.8578Z"
        fill={iconColor}
      />
    </Svg>
  );
}

export default ProfilePlaceholder;
