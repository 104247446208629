import { styled, TextDS, View } from '@talkspace/react-toolkit';
import { Check } from '@talkspace/react-toolkit/src/designSystems/icons';

const BannerText = styled(TextDS)(({ theme: { colorRoles } }) => {
  return {
    color: `${colorRoles.typography.textSubtle} !important`,
  };
});

const LowerBannerView = styled(View)(({ theme: { colorRoles, spacing } }) => {
  return {
    backgroundColor: colorRoles.surfaces.neutralDefault,
    padding: spacing('space200'),
    gap: 12,
  };
});

const TipView = styled(View)(({ theme: { spacing } }) => {
  return {
    flexDirection: 'row',
    gap: spacing('space050'),
    alignItems: 'center',
  };
});

const tips = [
  'Add your working hours',
  'Write more about your therapeutic approach',
  'Make sure it sounds like you',
];

export default function LowerFormBanner() {
  return (
    <LowerBannerView>
      <View style={{ gap: 8 }}>
        <TextDS variant="bodySm">Quick tips for editing this message:</TextDS>

        <View style={{ gap: 8 }}>
          {tips.map((tip) => (
            <TipView key={tip}>
              <Check colorType="success" />
              <BannerText variant="bodyXs">{tip}</BannerText>
            </TipView>
          ))}
        </View>
      </View>

      <BannerText variant="bodyXs">
        Note: If their first session isn’t booked, we’ll automatically add a booking link.
      </BannerText>
    </LowerBannerView>
  );
}
