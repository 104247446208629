import { TouchableView, View, styled } from '@talkspace/react-toolkit';

const Button = styled(TouchableView)<{ isSecondary: boolean }>(
  ({ theme: { colors }, isSecondary }) => {
    return {
      width: 315,
      padding: '13px 20px',
      borderRadius: 8,
      background: !isSecondary && colors.accessibilityGreenDark,
      color: isSecondary ? colors.accessibilityGreenDark : colors.white,
      fontWeight: 700,
      fontSize: isSecondary ? 14 : 16,
      textAlign: 'center',
      marginTop: !isSecondary && 14,
      cursor: 'pointer',
    };
  }
);

const UserButtons = ({ title, isSecondary, clickHandler }) => (
  <View>
    <Button isSecondary={isSecondary} onPress={clickHandler}>
      {title}
    </Button>
  </View>
);

export default UserButtons;
