/** Helps determine if this code is being hydrated by React.hydrate (SSR only) or rendered normally through React.render */
export const getIsSSR = () => !!globalThis.document?.getElementById('__next')?.childNodes?.length;

export const getClientSideGlobal = <T>(
  callback: (w: typeof globalThis) => T,
  defaultValue: T
): T => {
  try {
    return callback(globalThis) || defaultValue;
  } catch (_err) {
    return defaultValue;
  }
};
