import Svg, { Path } from 'svgs';

interface Props {
  width?: string | number;
  height?: string | number;
  color?: string;
}

const BonusBreakdownArrowIcon = ({ width = 12, height = 20, color = '#005C65' }: Props) => (
  <Svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <Path d="M4 14L8 9.99704L4 5.99622" stroke={color} stroke-width="2" stroke-linecap="round" />
  </Svg>
);

export default BonusBreakdownArrowIcon;
