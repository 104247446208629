import Svg, { Path } from 'svgs';
import { useEmotionTheme } from '../../core/styled';
import type { EmotionStyle } from '../../core/styled';

export interface ArrowRightProps {
  width?: number;
  height?: number;
  color?: string;
  style?: EmotionStyle;
  isLeft?: boolean;
}

const ArrowRight = ({ color, width = 8, height = 14, isLeft, ...otherProps }: ArrowRightProps) => {
  const { colors } = useEmotionTheme();
  const titleText = isLeft ? 'left arrow' : 'right arrow';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 8 14"
      {...otherProps}
    >
      <Path
        d="M338.266 20.417l5.174-5.155a.9.9 0 011.27 1.275l-5.178 5.159 5.178 5.158a.9.9 0 01-1.27 1.275l-5.174-5.155L337 21.696l1.266-1.279z"
        transform="translate(-765 -293) translate(428 278) matrix(-1 0 0 1 681.975 0)"
        fill={color || colors.accessibilityGreenDark}
        fillRule="nonzero"
        stroke={color || colors.accessibilityGreenDark}
        strokeWidth={1}
        strokeLinecap="round"
      />
    </Svg>
  );
};

export default ArrowRight;
