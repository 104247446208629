import Svg, { Path } from 'svgs';
import { useEmotionTheme } from '../../core/styled';
import type { EmotionStyle } from '../../core/styled';

interface Props {
  width?: number;
  height?: number;
  color?: string;
  style?: EmotionStyle;
}

const HumanCheckmarkOutlineIcon = ({ color, width = 22, height = 18, style }: Props) => {
  const { colors } = useEmotionTheme();
  const title = 'Human checkmark outline icon';
  return (
    <Svg
      width={width}
      height={height}
      title={title}
      aria-label={title}
      viewBox="0 0 22 18"
      fill="none"
      style={style}
    >
      <Path
        d="M5.01464 4.53486C5.01464 5.54475 5.537 6.45017 6.4076 6.97253C7.24337 7.46006 8.32291 7.46006 9.19351 6.97253C10.0293 6.45017 10.5865 5.54475 10.5865 4.53486C10.5865 3.55979 10.0293 2.65437 9.19351 2.13201C8.32291 1.64447 7.24337 1.64447 6.4076 2.13201C5.537 2.65437 5.01464 3.55979 5.01464 4.53486ZM12.258 4.53486C12.258 6.13676 11.3874 7.59936 10.0293 8.40031C8.63633 9.20126 6.92996 9.20126 5.57182 8.40031C4.17887 7.59936 3.34309 6.13676 3.34309 4.53486C3.34309 2.96778 4.17887 1.50518 5.57182 0.704228C6.92996 -0.0967218 8.63633 -0.0967218 10.0293 0.704228C11.3874 1.50518 12.258 2.96778 12.258 4.53486ZM9.36763 12.3354H6.19865C3.90028 12.3354 2.01979 14.0418 1.70637 16.2357H13.8599C13.5465 14.0418 11.666 12.3354 9.36763 12.3354ZM6.19865 10.6639H7.80055H9.36763C12.8152 10.6639 15.6011 13.4498 15.6011 16.8973C15.6011 17.4545 15.1136 17.9072 14.5564 17.9072H1.00989C0.452711 17.9072 0 17.4545 0 16.8973C0 13.4498 2.75109 10.6639 6.19865 10.6639ZM21.7649 6.24123L17.3075 10.6987C16.9592 11.0469 16.4369 11.0469 16.1235 10.6987L13.8947 8.46996C13.5465 8.15654 13.5465 7.63418 13.8947 7.32077C14.2082 6.97253 14.7305 6.97253 15.0787 7.32077L16.7155 8.95749L20.5809 5.09204C20.8943 4.7438 21.4167 4.7438 21.7649 5.09204C22.0784 5.40545 22.0784 5.92781 21.7649 6.24123Z"
        fill={color || colors.grey950}
      />
    </Svg>
  );
};

export default HumanCheckmarkOutlineIcon;
