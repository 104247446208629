import { useMutation, useQueryClient } from 'react-query';
import apiHelper from '../../utils/api';
import apiWrapper from '../../utils/apiWrapper';
import dashboardKeys from './queryKeys';
import { ReviewType } from './useQueryTaskListV3';

export type Action =
  | 'confirmNoShow'
  | 'grantNoShowException'
  | 'confirmLateCancellation'
  | 'grantLateCancellationException';

export interface ReviewTaskMutateVariables {
  taskType: ReviewType;
  action: Action;
  therapistID: number;
  exceptionReason?: string;
  bookingID: number;
}

const reviewTask = async (params: ReviewTaskMutateVariables): Promise<void> => {
  const { action, therapistID: therapistUserID, bookingID, exceptionReason, taskType } = params;

  const data = await apiWrapper.post(
    `${apiHelper().apiEndpoint}/v2/therapists/${therapistUserID}/bookings/${bookingID}/review-task`,
    {
      action,
      taskType,
      exceptionReason,
    }
  );
  return data.data;
};

const useMutationUpdateTask = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, ReviewTaskMutateVariables>(reviewTask, {
    onSuccess: (_, { therapistID }) => {
      queryClient.invalidateQueries(dashboardKeys.tasklistV3(therapistID));
    },
  });
};

export default useMutationUpdateTask;
