import { forwardRef } from 'react';
import * as React from 'react';
import styled from '@emotion/styled';
import { COLORS } from '../../constants/commonStyles';
import { EmotionStyle } from '../../core/styled';

export interface TextProps {
  onPress?: () => void;
  inline?: boolean;
  className?: string;
  style?: EmotionStyle;
  as?: string; // TODO: @Luis - Figure out why these need to be hard-coded
  id?: string;
  dataQa?: string;
}

const baseSupStyle = {
  fontSize: '34%',
  top: -20,
  fontWeight: 500,
  marginLeft: -10,
};

export const baseTextStyle = {
  fontFamily: "'Roboto', sans-serif",
  lineHeight: '1.4285em',
  margin: 0,
  letterSpacing: 0.2,
  color: COLORS.black,
  sup: {
    ...baseSupStyle,
  },
};

const P = styled.p(baseTextStyle);
const Span = styled.span(baseTextStyle);

type Ref = HTMLSpanElement | HTMLParagraphElement;

const TextComponent = forwardRef<
  Ref,
  React.ComponentProps<typeof P | typeof Span> & Pick<TextProps, 'inline' | 'dataQa'>
>(({ inline, dataQa, ...otherProps }, ref) =>
  inline ? (
    <Span {...otherProps} data-qa={dataQa} ref={ref as React.Ref<HTMLSpanElement>} />
  ) : (
    <P {...otherProps} data-qa={dataQa} ref={ref as React.Ref<HTMLParagraphElement>} />
  )
);

const Text = React.forwardRef<Ref, TextProps & React.ComponentProps<typeof TextComponent>>(
  ({ onPress, dataQa, children, ...otherProps }, ref) => (
    <TextComponent dataQa={dataQa} onClick={onPress} ref={ref} {...otherProps}>
      {children}
    </TextComponent>
  )
);

export default Text;
