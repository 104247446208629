import { VoidFunctionComponent } from 'react';
import Svg, { Path, G, Circle } from 'svgs';
import { EmotionStyle, useEmotionTheme } from '../../core/styled';

interface HamburgerProp {
  color?: string;
  width?: number;
  height?: number;
  style?: EmotionStyle;
  showIndicator?: boolean;
}

const Hamburger: VoidFunctionComponent<HamburgerProp> = ({
  color,
  showIndicator = false,
  width = 18,
  height = 14,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'menu';

  return showIndicator ? (
    <Svg
      title={titleText}
      aria-label={titleText}
      width={width + 8}
      height={height + 7}
      {...otherProps}
    >
      <G transform="translate(0 3)" fill="none" fillRule="evenodd">
        <Path
          d="M17 16a1 1 0 0 1 0 2H1a1 1 0 0 1 0-2h16Zm0-6a1 1 0 0 1 0 2H1a1 1 0 0 1 0-2h16Zm0-6a1 1 0 0 1 0 2H1a1 1 0 1 1 0-2h16Z"
          fill={color || colors.permaTalkspaceDarkGreen}
        />
        <Circle stroke="#FFF" strokeWidth={3} fill="red" cx={19.5} cy={3.5} r={5} />
      </G>
    </Svg>
  ) : (
    <Svg title={titleText} aria-label={titleText} width={width} height={height} {...otherProps}>
      <Path
        d="M17 12a1 1 0 0 1 0 2H1a1 1 0 0 1 0-2h16Zm0-6a1 1 0 0 1 0 2H1a1 1 0 1 1 0-2h16Zm0-6a1 1 0 0 1 0 2H1a1 1 0 1 1 0-2h16Z"
        fill={color || colors.permaTalkspaceDarkGreen}
        fillRule="evenodd"
      />
    </Svg>
  );
};

export default Hamburger;
