import {
  InformedConsent,
  InformedConsentStatus,
  InformedConsentV2StatusAPIPlusClientID,
} from 'ts-frontend/types';

export interface CRMInformedConsentItem extends InformedConsent {
  isUpdating: boolean;
  isError: boolean;
  showSpinner: boolean;
  spinnerMessage: string | undefined;
}

export interface CRMInformedConsentV2Item extends InformedConsentV2StatusAPIPlusClientID {
  isUpdating: boolean;
  isError: boolean;
  showSpinner: boolean;
  spinnerMessage: string | undefined;
}
export interface InformedConsentState {
  informedConsents: CRMInformedConsentItem[];
  isRequesting: boolean;
  requestError: boolean;
  isPatching: boolean;
  patchingConsentID: number | undefined;
  patchingError: boolean;
  isPosting: boolean;
  postError: boolean;
  resendingForClientID: number | null;
  informedConsentV2: CRMInformedConsentV2Item[];
}

export const REQUEST_INFORMED_CONSENTS = 'REQUEST_INFORMED_CONSENTS';
export const REQUEST_INFORMED_CONSENTS_ERROR = 'REQUEST_INFORMED_CONSENTS_ERROR';
export const RECEIVE_INFORMED_CONSENTS = 'RECEIVE_INFORMED_CONSENTS';
export const REQUEST_V2_INFORMED_CONSENT = 'REQUEST_V2_INFORMED_CONSENT';
export const RECEIVE_V2_INFORMED_CONSENT = 'RECEIVE_V2_INFORMED_CONSENT';
export const REQUEST_POST_INFORMED_CONSENT = 'REQUEST_POST_INFORMED_CONSENT';
export const REQUEST_POST_INFORMED_CONSENT_ERROR = 'REQUEST_POST_INFORMED_CONSENT_ERROR';
export const RECEIVE_POST_INFORMED_CONSENT = 'RECEIVE_POST_INFORMED_CONSENT';
export const PATCH_INFORMED_CONSENT_STATUS = 'PATCH_INFORMED_CONSENT_STATUS';
export const PATCH_INFORMED_CONSENT_STATUS_SUCCESS = 'PATCH_INFORMED_CONSENT_STATUS_SUCCESS';
export const PATCH_INFORMED_CONSENT_STATUS_ERROR = 'PATCH_INFORMED_CONSENT_STATUS_ERROR';

export const HIDE_INFORMED_CONSENT_SPINNER = 'HIDE_INFORMED_CONSENT_SPINNER';

interface InformedConsentAction {
  type:
    | typeof REQUEST_INFORMED_CONSENTS
    | typeof REQUEST_INFORMED_CONSENTS_ERROR
    | typeof RECEIVE_INFORMED_CONSENTS
    | typeof REQUEST_POST_INFORMED_CONSENT
    | typeof REQUEST_POST_INFORMED_CONSENT_ERROR
    | typeof RECEIVE_POST_INFORMED_CONSENT
    | typeof PATCH_INFORMED_CONSENT_STATUS
    | typeof PATCH_INFORMED_CONSENT_STATUS_SUCCESS
    | typeof PATCH_INFORMED_CONSENT_STATUS_ERROR
    | typeof HIDE_INFORMED_CONSENT_SPINNER
    | typeof REQUEST_V2_INFORMED_CONSENT
    | typeof RECEIVE_V2_INFORMED_CONSENT;
  error?: Error;
}

interface RequestInformedConsents extends InformedConsentAction {
  type: typeof REQUEST_INFORMED_CONSENTS;
  roomID: number;
}

interface ReceiveInformedConsents extends InformedConsentAction {
  type: typeof RECEIVE_INFORMED_CONSENTS;
  informedConsents: InformedConsent[];
}

interface RequestInformedConsentsError extends InformedConsentAction {
  type: typeof REQUEST_INFORMED_CONSENTS_ERROR;
  error: Error;
}

interface RequestPostInformedConsent extends InformedConsentAction {
  type: typeof REQUEST_POST_INFORMED_CONSENT;
  resendingForClientID: number;
}

interface ReceivePostInformedConsent extends InformedConsentAction {
  type: typeof RECEIVE_POST_INFORMED_CONSENT;
  informedConsent: InformedConsent;
}

interface RequestPostInformedConsentError extends InformedConsentAction {
  type: typeof REQUEST_POST_INFORMED_CONSENT_ERROR;
  error: Error;
}

interface PatchInformedConsentStatus extends InformedConsentAction {
  type: typeof PATCH_INFORMED_CONSENT_STATUS;
  status: InformedConsentStatus;
  consentID: number;
}

interface PatchInformedConsentStatusSuccess extends InformedConsentAction {
  type: typeof PATCH_INFORMED_CONSENT_STATUS_SUCCESS;
  status: InformedConsentStatus;
  consentID: number;
}

interface PatchInformedConsentStatusError extends InformedConsentAction {
  type: typeof PATCH_INFORMED_CONSENT_STATUS_ERROR;
  consentID: number;
  error: Error;
}

interface HideInformedConsent extends InformedConsentAction {
  type: typeof HIDE_INFORMED_CONSENT_SPINNER;
  consentID: number;
}

interface RequestV2InformedConsent extends InformedConsentAction {
  type: typeof REQUEST_V2_INFORMED_CONSENT;
  userID: number;
}

interface ReceiveV2InformedConsent extends InformedConsentAction {
  type: typeof RECEIVE_V2_INFORMED_CONSENT;
  informedConsentV2: InformedConsentV2StatusAPIPlusClientID[];
}

export type InformedConsentActionTypes =
  | RequestInformedConsents
  | ReceiveInformedConsents
  | RequestInformedConsentsError
  | RequestPostInformedConsent
  | ReceivePostInformedConsent
  | RequestPostInformedConsentError
  | PatchInformedConsentStatus
  | PatchInformedConsentStatusSuccess
  | PatchInformedConsentStatusError
  | HideInformedConsent
  | RequestV2InformedConsent
  | ReceiveV2InformedConsent;
