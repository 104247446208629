import {
  AnimatedSwipeDownModal,
  TouchableView,
  useWindowWidth,
  View,
} from '@talkspace/react-toolkit';

import HourglassIcon from 'components/Icons/HourglassIcon';
import styled from '../../../../core/styled';
import ModalContainer from './ModalContainer';

const Button = styled(TouchableView)(({ theme: { colors } }) => {
  return {
    color: colors.accessibilityGreenDark,
    fontWeight: 700,
    fontSize: 15,
  };
});

const Title = styled(View)(({ theme: { colors } }) => {
  return {
    marginTop: 14,
    fontSize: 21,
    lineHeight: '28px',
    fontWeight: 500,
    color: colors.permaOuterSpaceNew,
    textAlign: 'center',
  };
});

const Subtitle = styled(View)(({ theme: { colors } }) => {
  return {
    marginTop: 15,
    fontWeight: 400,
    fontSize: 14,
    textAlign: 'center',
    color: colors.permaRiverBed,
    lineHeight: '18px',
  };
});

const CloseButton = styled(Button)(({ theme: { colors } }) => {
  return {
    marginTop: 50,
    fontSize: 17,
    backgroundColor: colors.white,
    color: colors.permaBlueStoneNew,
    letterSpacing: '0.5px',
    fontWeight: 700,
    lineHeight: '20px',
  };
});

const ContentWrapper = styled(View)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingLeft: isMobile ? 30 : 60,
      paddingRight: isMobile ? 30 : 60,
      paddingBottom: 50,
    };
  }
);

interface AlreadyClaimedModalProps {
  onBackdropPress: () => void;
  hide: boolean;
  isFullyBooked?: boolean;
}

const AlreadyClaimedModal = ({
  onBackdropPress,
  isFullyBooked,
  hide,
}: AlreadyClaimedModalProps) => {
  const { isMobile } = useWindowWidth();

  const getContent = () => {
    if (isFullyBooked) {
      return {
        title:
          "Looks like you are fully booked during this client's preferred times for the next two weeks",
        subtitle:
          "Don't worry, our waitlist is continually updated. Refresh now and explore more potential clients.",
        actionTitle: 'Go back to waitlist',
        actionPress: onBackdropPress,
      };
    }

    return {
      title: 'Oops, this client was matched with another therapist',
      subtitle:
        "Don't worry, our waitlist is continually updated. Refresh now and explore more potential clients.",
      actionTitle: 'Refresh waitlist',
      actionPress: onBackdropPress,
    };
  };

  const { title, subtitle, actionTitle, actionPress } = getContent();

  const renderContent = () => (
    <ContentWrapper>
      <HourglassIcon />
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
      <CloseButton data-qa="growCaseloadAlreadyClaimedClose" onPress={actionPress}>
        {actionTitle}
      </CloseButton>
    </ContentWrapper>
  );

  if (isMobile) {
    return (
      <AnimatedSwipeDownModal isOpen={!hide} onClose={onBackdropPress}>
        {renderContent()}
      </AnimatedSwipeDownModal>
    );
  }
  return (
    <ModalContainer onBackdropPress={onBackdropPress} hide={hide}>
      {renderContent()}
    </ModalContainer>
  );
};

export default AlreadyClaimedModal;
