export const COLORS = {
  black: '#222F2D',
  grey: '#65727D',
  extraLightGrey: '#D8D8E0',
  placeholderGrey: '#98A6B2',
  white: '#fff',
  green: '#0FC1A7',
  darkGreen: '#09B198',
  pink: '#E8006B',
  red: '#FF0050',
  orange: '#EA9436',
  lightPurple: '#8EA8FD',
  purple: '#5C84FB',
  darkPurple: '#426FF9',
  a11yLinkWaterGrey: '#EFF3FA',
  gossamerGreen: '#099586',
  mintGreen: '#B4F4CE',
  heatherGrey: '#BEC7D7',
  periwinkleGrey: '#BECBE0',
  amber: '#FDBF00',
  emerald: '#4CD964',
  bayouxBlue: '#4D5D76',
  slateGrey: '#68768D',
  cometGrey: '#576074',
  loblollyGrey: '#BCC4CE',
  pippinPink: '#FFE6DF',
  cobalt: '#084BA9',
  athensGrey: '#FCFCFD',
  candleLight: '#FFD418',
  cornflowerBlue: '#579DF4',
  surfieGreen: '#107B81',
  persianGreen: '#009CA3',
  zigguratGreen: '#B8DBD7',
  neptuneGreen: '#79B4BA',
  osloGrey: '#7D8993',
  blueHaze: '#C7D1DE',
  gold: '#FF9F00',
  fordGrey: '#979797',
  lightFordGrey: '#9797A2',
  boulder: '#747474',
};

// green: system message buttons, user chat bubbles, green svgs/icons, input caret
// darkGreen: green text, hover color for green icons
// slateGrey: grey text, placeholders
// periwinkleGrey: grey icons, disabled/seconday buttons, input outlines
export const A11Y_COLORS = {
  green: '#007E73',
  darkGreen: '#00574F',
  slateGrey: '#596E92',
  periwinkleGrey: '#758AAE',
  black: '#222F2D',
  grey: '#65727D',
  extraLightGrey: '#EBEEF2',
  placeholderGrey: '#98A6B2',
  white: '#fff',
  pink: '#E8006B',
  red: '#FF0050',
  orange: '#EA9436',
  lightPurple: '#8EA8FD',
  purple: '#5C84FB',
  darkPurple: '#426FF9',
  a11yLinkWaterGrey: '#EFF3FA',
  gossamerGreen: '#099586',
  mintGreen: '#B4F4CE',
  heatherGrey: '#BEC7D7',
  amber: '#FDBF00',
  emerald: '#4CD964',
  bayouxBlue: '#4D5D76',
  cometGrey: '#576074',
  loblollyGrey: '#BCC4CE',
  pippinPink: '#FFE6DF',
  cobalt: '#084BA9',
  athensGrey: '#FCFCFD',
  candleLight: '#FFD418',
  cornflowerBlue: '#579DF4',
  surfieGreen: '#107B81',
  persianGreen: '#009CA3',
  zigguratGreen: '#B8DBD7',
  neptuneGreen: '#79B4BA',
  osloGrey: '#7D8993',
  blueHaze: '#C7D1DE',
  gold: '#EA6F00',
  fordGrey: '#758AAE',
  lightFordGrey: '#758AAE',
};
