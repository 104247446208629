// User defined types should be stored here.
export type Nullable<T> = T | null;

export const liveSessionModalities = ['video', 'audio', 'chat'] as const;
export type LiveSessionModality = typeof liveSessionModalities[number];

export const sessionModalities = [...liveSessionModalities, 'messaging'] as const;
export type SessionModality = typeof sessionModalities[number];

export enum ProgressNoteModalityType {
  liveSession = 'liveSession',
  messaging = 'messaging',
}

export enum SessionModalityToBookingName {
  video = 'Live video call session',
  audio = 'Live audio call session',
  chat = 'Live chat session',
  messaging = 'Messaging session',
}
