import { useEffect } from 'react';
import { RateApp } from 'capacitor-rate-app';
import { safeIonicWrapper, shouldShowNativeFeature, NativeFeaturesEnum } from '../../ionicUtils';
import { getIonicStorage, removeIonicStorage, OPEN_APP_REVIEW } from '../secureStorage';

// eslint-disable-next-line import/prefer-default-export
export const requestAppReview = safeIonicWrapper(() => RateApp.requestReview(), Promise.resolve());

export const useAppReviewCheckStorage = (successCallback: () => void) => {
  useEffect(() => {
    (async () => {
      if (shouldShowNativeFeature(NativeFeaturesEnum.APP_REVIEW)) {
        const response = await getIonicStorage(OPEN_APP_REVIEW);
        if (response?.value === 'true') {
          await removeIonicStorage(OPEN_APP_REVIEW);
          requestAppReview();
          successCallback?.();
        }
      }
    })();
  }, [successCallback]);
};
