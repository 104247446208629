import { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import {
  ExtraTiny,
  OptionType,
  TextAreaRounded,
  useEmotionTheme,
  View,
  Standard,
  Small,
  TooltipV2,
  styled,
  ReminderIcon,
} from '@talkspace/react-toolkit';
import { keyframes } from '@emotion/core';
import * as outcomeMeasureActions from 'actions/OutcomeMeasureActions';
import { ValueType } from 'react-select';
import { useLocation, useRouteMatch } from '@/core/routerLib';
import SelectContainer from '../containers/SelectContainer';
import {
  getTextAreaContainerBorderStyleV2,
  getTextAreaStyleV2,
  SESSION_SUMMARY_WORD_LIMIT,
  BooleanDropdown,
  getSelectStyleV2,
  SESSION_SAMMARY_MIN_WORD_COUNT,
  getWordCount,
  LOW_ENGAGEMENT_ARTICLE,
  Modality,
} from '../../utils';
import { TextLink } from './styles';
import {
  useProgressNoteFormActions,
  useProgressNoteFormState,
} from '../context/TherapistProgressNoteFormContext';
import trackEvent from '../../../../../../modules/analytics/trackEvent';
import { ProgressNoteFormState, Section } from '../context/types';
import NoteTemplate from './NoteTemplate';
import ssoHelper from '../../../../../../modules/utils/sso';
import useDidUpdateEffect from '../../../../../../hooks/useDidUpdateEffect';

const LOW_ENGAGEMENT_TEMPLATE =
  'This contracted EAP session has completed a seven-day cycle during which the member had unlimited access to their therapist. During this session, the therapist attempted to engage the member by utilizing proven therapeutic interventions. The therapist will continue to reach out to the member in order to further encourage increased engagement in future sessions to assist the therapeutic process.';

const fadeIn = keyframes`
 0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const TooltipContent = () => (
  <View>
    <Standard>
      Should I submit a note even if the client did not engage during the session?
    </Standard>
    <Small variant="smallTSBlack" style={{ marginTop: 4 }}>
      Yes. Clients understand that one session equals 7 days of access to therapy. If the client did
      not engage, it is still important to document that no engagement occurred. You can document
      the session using the low engagement note template or your own words.{' '}
      <TextLink onClick={() => ssoHelper.openZendesk(LOW_ENGAGEMENT_ARTICLE)}>Learn more</TextLink>
    </Small>
  </View>
);

const WordCount = styled(Standard)<{ isLimit: boolean }>(({ theme: { colors }, isLimit }) => {
  return {
    fontSize: 12,
    color: isLimit ? colors.red : colors.grey950,
    fontWeight: 400,
  };
});

const BannerWrapper = styled(View)(({ theme: { colors } }) => {
  return {
    animation: `${fadeIn} 500ms cubic-bezier(.65,-0.01,.38,1) both`,
    height: 50,
    padding: '9px 10px',
    background: colors.blueMagneta,
    alignItems: 'center',
    borderRadius: '0 0 8px 8px',
  };
});

const BannerText = styled(Small)(({ theme: { colors } }) => {
  return {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 'normal',
    color: colors.purple600,
  };
});

const LowEngagementBanner = () => (
  <BannerWrapper row>
    <View style={{ marginRight: 10 }}>
      <ReminderIcon />
    </View>
    <BannerText>
      Initial session summary is less than 20 words and could result in a rejected claim or failed
      audit
    </BannerText>
  </BannerWrapper>
);

const SessionSummarySection = ({
  nextSection,
  isEap,
}: {
  nextSection: Section;
  isEap?: boolean;
}) => {
  const location = useLocation<{ outcomeMeasure: { id: number } }>();
  const match = useRouteMatch<{ roomID: string; noteID?: string }>();
  const { colors } = useEmotionTheme();
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);
  const [shouldDisplayBanner, setShouldDisplayBanner] = useState<boolean>(false);
  const formState = useProgressNoteFormState();

  const {
    sessionSummary: globalSessionSummary,
    formSections,
    submissionStatus,
    lowEngagement,
    formMode,
    currentSection,
    isFirstNote,
    modalityID,
  } = formState;

  const [sessionSummary, setSessionSummary] =
    useState<ProgressNoteFormState['sessionSummary']>(globalSessionSummary);

  const textAreaWordCount = sessionSummary.summary ? getWordCount(sessionSummary.summary) : 0;

  const { setFormState } = useProgressNoteFormActions();

  const [summaryError, setSummaryError] = useState(false);

  const userRoomSurveyID: number | undefined = location?.state?.outcomeMeasure?.id;
  const unsubmittedSessionID = new URLSearchParams(location.search).get('id');

  useEffect(() => {
    if (isFirstNote && textAreaWordCount < SESSION_SAMMARY_MIN_WORD_COUNT) {
      setFormState({ isSessionSummaryTooShort: true });
    } else {
      setFormState({ isSessionSummaryTooShort: false });
      setShouldDisplayBanner(false);
    }
  }, [isFirstNote, setFormState, textAreaWordCount, formMode]);

  useEffect(() => {
    setSessionSummary({
      ...globalSessionSummary,
      ...(userRoomSurveyID && {
        userRoomSurveyID,
      }),
    });
  }, [globalSessionSummary, userRoomSurveyID]);

  const markSectionAsIncomplete = () => {
    if (formSections.sessionSummary.completed) {
      setFormState({
        formSections: {
          ...formSections,
          sessionSummary: {
            open: formSections.sessionSummary.open,
            changed: true,
            completed: false,
          },
        },
      });
    }
  };

  const handleSummaryChange = (value: string) => {
    setShouldDisplayBanner(false);
    const currentWordCount = getWordCount(value);
    if (currentWordCount > SESSION_SUMMARY_WORD_LIMIT) {
      return;
    }

    markSectionAsIncomplete();
    setSummaryError(false);
    setSessionSummary({ ...sessionSummary, summary: value });
  };

  const handleConfirmPress = useCallback(() => {
    let error = false;
    if (!formState.isSaveAsDraftPressed) {
      if (!sessionSummary.summary) {
        setSummaryError(true);
        error = true;
      }

      if (error) {
        setFormState({ showGlobalError: true });
        return;
      }
    }

    setFormState({
      sessionSummary,
      formSections: {
        ...formSections,
        sessionSummary: {
          open: false,
          changed: true,
          completed: !!sessionSummary.summary,
        },
      },
    });
  }, [formSections, formState.isSaveAsDraftPressed, sessionSummary, setFormState]);

  useDidUpdateEffect(() => {
    if (currentSection === 'sessionSummary') {
      handleConfirmPress();
      setFormState({ currentSection: nextSection });
    }
  }, [currentSection]);

  const textAreaStyle = getTextAreaStyleV2(formMode === 'view', colors);

  const addNoteTemplate = (title: string, template: string) => {
    if (sessionSummary.summary) {
      const value = sessionSummary.summary.concat(template);
      if (getWordCount(value) > SESSION_SUMMARY_WORD_LIMIT) {
        return;
      }

      setSessionSummary({
        ...sessionSummary,
        summary: value,
      });
    } else {
      setSessionSummary({
        ...sessionSummary,
        summary: template,
      });
    }

    trackEvent(
      'addNoteInfo',
      {
        actionName: 'progressNotesInteraction',
      },
      {
        action: 'Provider adds information to progress note',
        noteStatus: submissionStatus,
        noteTemplate: title,
        roomID: match.params.roomID,
        unsubmittedSessionID,
      }
    );
  };

  const handleLowEngagementDropdown = useCallback(
    (value: boolean) => {
      if (value && !sessionSummary.summary?.includes(LOW_ENGAGEMENT_TEMPLATE)) {
        setSummaryError(false);
        setSessionSummary((prevSessionSummary) => {
          return {
            ...prevSessionSummary,
            summary: prevSessionSummary.summary
              ? prevSessionSummary.summary.concat(LOW_ENGAGEMENT_TEMPLATE)
              : LOW_ENGAGEMENT_TEMPLATE,
          };
        });
      }
      if (!value) {
        const newSessionSummary = sessionSummary?.summary?.replace(LOW_ENGAGEMENT_TEMPLATE, '');
        if (newSessionSummary !== undefined) {
          setSessionSummary((prevSessionSummary) => {
            return {
              ...prevSessionSummary,
              summary: newSessionSummary,
            };
          });
        }
      }
      setFormState({ lowEngagement: value });
    },
    [sessionSummary, setSessionSummary, setFormState]
  );

  const handleTextAreaBlur = () => {
    if (isFirstNote && textAreaWordCount < SESSION_SAMMARY_MIN_WORD_COUNT) {
      setShouldDisplayBanner(true);
    } else {
      setShouldDisplayBanner(false);
    }
  };

  const handlePaste = (event) => {
    const pastedText = event.clipboardData.getData('text');
    const pastedTextWordCount = getWordCount(pastedText);

    if (textAreaWordCount + pastedTextWordCount > SESSION_SUMMARY_WORD_LIMIT) {
      event.preventDefault();
    }
  };

  const isLimitReached = textAreaWordCount === SESSION_SUMMARY_WORD_LIMIT;
  const countText = isLimitReached ? 'word limit reached' : 'words';

  return (
    <View>
      {isEap && modalityID === Modality.TEXT && (
        <SelectContainer
          value={
            BooleanDropdown.find((it) => it.value === lowEngagement) as ValueType<
              OptionType<string>,
              boolean
            >
          }
          title="Low client engagement?"
          handleChange={(option) =>
            handleLowEngagementDropdown(option.value) as ValueType<OptionType<string>, boolean>
          }
          options={BooleanDropdown as any}
          styles={getSelectStyleV2({ colors })}
          isDisabled={formMode === 'view'}
        >
          <View
            row
            align="center"
            style={{ height: 40 }}
            onMouseEnter={() => setIsTooltipOpen(true)}
            onMouseLeave={() => setIsTooltipOpen(false)}
          >
            <TooltipV2
              styles={{ marginLeft: 9 }}
              shouldDisplayCloseButton={false}
              containerStyle={{ left: -250, width: 355, top: 25 }}
              toolTipText={<TooltipContent />}
              isTooltipOpen={isTooltipOpen}
            />
          </View>
        </SelectContainer>
      )}
      <View style={{ marginLeft: 6, marginBottom: 12 }}>
        <NoteTemplate handleAppendNote={addNoteTemplate} isDisabled={formMode === 'view'} />
      </View>

      <TextAreaRounded
        onBlur={handleTextAreaBlur}
        data-testid="summaryTextArea"
        autoSize
        style={{ ...getTextAreaContainerBorderStyleV2(colors, summaryError) }}
        textAreaStyle={textAreaStyle}
        value={sessionSummary.summary || ''}
        onChangeText={handleSummaryChange}
        placeholder="Required. Client presented..."
        disabled={formMode === 'view'}
        rows={4}
        shouldDisplayBanner={shouldDisplayBanner}
        banner={<LowEngagementBanner />}
        onPaste={handlePaste}
      />

      <View row justify="end">
        <WordCount isLimit={isLimitReached}>
          {textAreaWordCount.toLocaleString()} {countText}
        </WordCount>
      </View>

      {summaryError && (
        <ExtraTiny
          data-testid="sessionSummaryError"
          style={{ marginTop: -15 }}
          variant="extraTinyError"
        >
          Required
        </ExtraTiny>
      )}
    </View>
  );
};

const mapStateToProps = (state: any) => {
  return {
    outcomeMeasures: state.outcomeMeasures.outcomeMeasures,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getOutcomeMeasures: () => dispatch(outcomeMeasureActions.getOutcomeMeasures()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionSummarySection);
