/* eslint-disable camelcase */

import { useQuery } from 'react-query';
import apiWrapper from '../../utils/apiWrapper';
import apiHelper from '../../utils/api';
import dashboardKeys from './queryKeys';

export interface AccountInfoStatusResponse {
  fields: string[];
  missingInformation: boolean;
}

const fetchAccountInfoStatus = (therapistID: number) => async () => {
  const { data } = await apiWrapper.get(
    `${apiHelper().apiEndpoint}/api/v1/therapist/${therapistID}/accountInformationStatus`
  );
  return data;
};

const useQueryAccountInformationStatus = (therapistID: number) =>
  useQuery<AccountInfoStatusResponse, Error>({
    queryKey: dashboardKeys.accountInformationStatus(therapistID),
    queryFn: fetchAccountInfoStatus(therapistID),
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
  });

export default useQueryAccountInformationStatus;
