import { FunctionComponent } from 'react';

import Svg, { Path } from 'svgs';
import { EmotionStyle, useEmotionTheme } from '../../core/styled';

interface CheckmarkProps {
  color?: string;
  width?: number;
  height?: number;
  style?: EmotionStyle;
  strokeWidth?: number;
}

const Checkmark: FunctionComponent<CheckmarkProps> = ({
  color,
  width = 15,
  height = 15,
  strokeWidth,
  style,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'check mark';
  return (
    <Svg
      {...otherProps}
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 15 15"
      style={{ flexShrink: 0, verticalAlign: 'middle', ...style }}
    >
      <Path
        transform="translate(-390 -450)"
        stroke={color || colors.white}
        strokeWidth={strokeWidth || 3}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        d="M403 452L396.490121 461 392 457"
      />
    </Svg>
  );
};

export default Checkmark;
