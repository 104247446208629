import { Component, ComponentType } from 'react';
import UnhandledErrorMessage from '../UnhandledErrorMessage';

type ErrorBoundaryProps = {
  errorComponent?: ComponentType;
  removeErrorScreen?: boolean;
  onError?(error: Error, errorInfo: React.ErrorInfo): void;
};
export default class ErrorBoundary extends Component<ErrorBoundaryProps, { hasError: boolean }> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    const { onError } = this.props;
    onError?.(error, errorInfo);
  }

  render() {
    const { errorComponent, removeErrorScreen, children } = this.props;
    const { hasError } = this.state;

    if (hasError && !removeErrorScreen) {
      const ErrorComponent = errorComponent || UnhandledErrorMessage;
      return <ErrorComponent />;
    }

    return children;
  }
}
