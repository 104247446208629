import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const standardPath =
  'M5 2.5C4.44687 2.5 4 2.94687 4 3.5C4 4.05313 4.44687 4.5 5 4.5V4.84375C5 6.16875 5.52813 7.44063 6.46563 8.37813L8.58437 10.5L6.46563 12.6219C5.52813 13.5594 5 14.8313 5 16.1562V16.5C4.44687 16.5 4 16.9469 4 17.5C4 18.0531 4.44687 18.5 5 18.5H6H14H15C15.5531 18.5 16 18.0531 16 17.5C16 16.9469 15.5531 16.5 15 16.5V16.1562C15 14.8313 14.4719 13.5594 13.5344 12.6219L11.4156 10.5L13.5375 8.37813C14.475 7.44063 15.0031 6.16875 15.0031 4.84375V4.5C15.5563 4.5 16.0031 4.05313 16.0031 3.5C16.0031 2.94687 15.5563 2.5 15.0031 2.5H14H6H5ZM7 4.84375V4.5H13V4.84375C13 5.4375 12.825 6.0125 12.5 6.5H7.5C7.17812 6.0125 7 5.4375 7 4.84375ZM7.5 14.5C7.60938 14.3344 7.7375 14.1781 7.87812 14.0344L10 11.9156L12.1219 14.0375C12.2656 14.1813 12.3906 14.3375 12.5 14.5031H7.5V14.5Z';

const pathConfig: IconConfig = {
  standard: {
    default: {
      path: standardPath,
    },
  },
};

const HourglassHalf = withDSIconMaker(pathConfig, 'hourglass half');

export default HourglassHalf;
