import { View, useEmotionTheme, Small, Tiny, BaseButton } from '@talkspace/react-toolkit';
import { VoidFunctionComponent } from 'react';
import FilterIcon from '../../../Icons/FilterIcon';
import styled from '@/core/styled';

interface CaseLoadFilterButtonProps {
  filteredCount: number;
  setIsFilterModalOpen: (isOpen: boolean) => void;
}

const FilterButton = styled(BaseButton)(({ theme: { colors } }) => {
  return {
    border: `solid ${colors.permaEden} 1px`,
    width: 'fit-content',
    minWidth: 91,
    height: 36,
    borderRadius: 5,
    background: colors.permaPhantomWhite,
    boxSizing: 'border-box',
    paddingLeft: 5,
    '&:hover': {
      background: colors.permaGhostWhite,
    },
    '&:active': {
      background: colors.permaSolitude,
    },
  };
});

const FilterBadge = styled(View)(({ theme: { colors } }) => {
  return {
    background: colors.permaSchiavaBlue,
    borderRadius: 45,
    paddingLeft: 7,
    paddingRight: 7,
    paddingTop: 4,
    paddingBottom: 4,
    marginLeft: 4,
    marginRight: 10,
  };
});

const CaseLoadFilterButton: VoidFunctionComponent<CaseLoadFilterButtonProps> = ({
  filteredCount,
  setIsFilterModalOpen,
}: CaseLoadFilterButtonProps) => {
  const { colors } = useEmotionTheme();
  return (
    <FilterButton
      roundedFocusStyle
      primaryColor={colors.permaEden}
      onPress={() => setIsFilterModalOpen(true)}
    >
      <View row align="center">
        <FilterIcon showBackground={false} />
        <Small variant="smallDarkGreen">Filters</Small>
        {filteredCount > 0 && (
          <FilterBadge>
            <Tiny variant="tinyWhite">{filteredCount}</Tiny>
          </FilterBadge>
        )}
      </View>
    </FilterButton>
  );
};

export default CaseLoadFilterButton;
