const planDesriptionText = {
  b2c: {
    messagingOnly: [
      'This plan is an ongoing out-of-pocket subscription and includes messaging only. With this plan, clients receive daily messaging responses from you during your working days.',
      'Clients also have the ability to purchase adhoc video credits.',
      'Please complete a progress note for every completed session, which is typically one week of ongoing messaging. Use your clinical discretion to determine if a session has been met.',
    ],
    messagingAndLive: [
      'This plan is an ongoing out-of-pocket subscription. With this plan, clients receive daily messaging responses and have live video session(s). For the live session, the client chooses between a live video, audio or chat session.',
      'Please complete a progress note for every completed live session or messaging session. A messaging session is considered at least one week of service. Use your clinical discretion to determine if a session has been met.',
      'Clients also have the ability to purchase adhoc video credits. ',
    ],
    liveOnly: [
      'This plan is an ongoing out-of-pocket subscription. With this plan, clients have 4 live sessions per month and choose between live video, audio, or chat sessions.',
      'Please complete a progress note for each live session.',
    ],
  },
  bhUnlimited: {
    messagingAndLive: [
      'This plan is a behavioral health plan and offers unlimited sessions. Clients have the ability to schedule both asynchronous messaging and live sessions with you. For each live session, the client can choose between video, audio, and chat.',
      'The client must accept the messaging session before the session begins. Once a session begins, clients receive daily messaging responses from you. The length of each session is determined by you and needs to be at least 7 days.',
      'Check the banner in the room to see if you are in a session with a client. You must submit a progress note for reimbursement.',
    ],
  },
  bh30: {
    messagingOnly: [
      'This plan is a behavioral health plan and the client receives 30 asynchronous messaging sessions each year. The client must accept the messaging session before the session begins. Once a session begins, clients receive daily messaging responses from you. The length of each session is determined by you and needs to be at least 7 days.',
      'Check the banner in the room to see if you are in a session with a client. You must submit a progress note for reimbursement.',
    ],
    messagingAndLive: [
      'This plan is a behavioral health plan and the client receives 30 sessions each year. Clients have the ability to schedule both asynchronous messaging and live sessions with you. For each live session, the client can choose between video, audio, and chat.',
      'The client must accept the messaging session before the session begins. Once a session begins, clients receive daily messaging responses from you. The length of each session is determined by your clinical judgment. Asynchronous messaging sessions need to be at least 7 days and match the time duration of your chosen billing code.',
      'Check the banner in the room to see if you are in a session with a client. You must submit a progress note for reimbursement.',
    ],
  },
  bh10: {
    messagingOnly: [
      'The client must accept the messaging session before the session begins. Once a session begins, clients receive daily messaging responses from you. The length of each session is determined by your clinical judgment. Asynchronous messaging sessions need to be at least 7 days and match the time duration of your chosen billing code.',
      'Check the banner in the room to see if you are in a session with a client. You must submit a progress note for reimbursement.',
    ],
    messagingAndLive: [
      'Clients have the ability to schedule both asynchronous messaging and live sessions with you. For each live session, the client can choose between video, audio, and chat.',
      'The client must accept the messaging session before the session begins. Once a session begins, clients receive daily messaging responses from you. The length of each session is determined by your clinical judgment. Asynchronous messaging sessions need to be at least 7 days and match the time duration of your chosen billing code.',
      'Check the banner in the room to see if you are in a session with a client. You must submit a progress note for reimbursement.',
    ],
  },
  eap: {
    messagingOnly: [
      'This plan offers a set number of sessions. The client must accept the messaging session before it begins and each session lasts 7 calendar days. For asynchronous messaging, you are required to send daily responses for clients who are engaging with you during your working days.',
      'An EAP messaging session is defined as 7 days of access to the platform. If a client did not engage after they accepted the messaging session, you still need to complete a progress note to document your engagement attempts.',
    ],
    messagingAndLive: [
      'This plan offers a set number of sessions. The client must choose between live video, audio, chat, and an asynchronous messaging session before beginning.',
      'This plan offers a set number of sessions. The client must accept the messaging session before it begins and each session lasts 7 days. For asynchronous messaging, you are required to send daily responses for clients who are engaging with you during your working days.',
      'An EAP messaging session is defined as 7 days of access to the platform. If a client did not engage after they accepted the messaging session, you still need to complete a progress note to document your engagement attempts.',
    ],
  },
  dte: {
    messagingOnly: [
      'This plan is an ongoing direct-to-employer or institution-based subscription. This subscription gives clients unlimited asynchronous messaging sessions with you.',
      'With this plan, clients receive daily messaging responses from you during your working days. The length of each session is determined by your clinical judgment.  Messaging sessions are at least 7 calendar days of engagement.',
      'Clients also have the ability to purchase adhoc video credits.',
    ],
    messagingAndLive: [
      'This plan is an ongoing direct-to-employer or institution-based subscription. Clients have the ability to schedule both asynchronous messaging and live sessions with you. For each live session, the client can choose between video, audio, and chat.',
      'With this plan, clients receive daily messaging responses from you during your working days. The length of each session is determined by your clinical judgment.  Messaging sessions are at least 7 calendar days of engagement.',
    ],
  },
};

export default planDesriptionText;
