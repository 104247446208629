import {
  OffersState,
  REQUEST_GET_OFFERS,
  REQUEST_GET_OFFERS_ERROR,
  RECEIVE_GET_OFFERS,
  REQUEST_POST_OFFER,
  REQUEST_POST_OFFER_ERROR,
  RECEIVE_POST_OFFER,
  OffersActionTypes,
} from '../constants/offersTypes';

const initialState: OffersState = {
  offerCategories: [],
  isUpdating: false,
  isError: false,
};

const offersReducer = (state = initialState, action: OffersActionTypes): OffersState => {
  switch (action.type) {
    case REQUEST_GET_OFFERS:
    case REQUEST_POST_OFFER:
      return {
        ...state,
        isUpdating: true,
        isError: false,
      };
    case REQUEST_GET_OFFERS_ERROR:
    case REQUEST_POST_OFFER_ERROR:
      return {
        ...state,
        isUpdating: false,
        isError: true,
      };
    case RECEIVE_GET_OFFERS:
    case RECEIVE_POST_OFFER:
      return {
        ...state,
        isUpdating: false,
        isError: false,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default offersReducer;
