import Svg, { Path, G, Rect, Defs } from 'svgs';
import { useEmotionTheme } from '../../core/styled';
import type { EmotionStyle } from '../../core/styled';

export interface AlertIconProps {
  width?: number;
  height?: number;
  color?: string;
  style?: EmotionStyle;
}

const AlertIcon = ({ color, width = 18, height = 20, style, ...otherProps }: AlertIconProps) => {
  const { colors } = useEmotionTheme();

  return (
    <Svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      style={style}
      {...otherProps}
    >
      <G clipPath="url(#clip0_504_4583)">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.721191 6.25C0.721191 3.90279 2.62398 2 4.97119 2H12.9444C15.2916 2 17.1944 3.90279 17.1944 6.25V14.7529C17.1944 17.1001 15.2916 19.0029 12.9444 19.0029H4.97119C2.62398 19.0029 0.721191 17.1001 0.721191 14.7529V6.25Z"
          fill={color || colors.permaFuchsia}
        />
        <Path
          d="M9.91211 12.0684H7.91602V5.54688H9.91211V12.0684ZM9.91211 15.5H7.91602V13.75H9.91211V15.5Z"
          fill="white"
        />
      </G>
      <Defs>
        <clipPath id="clip0_504_4583">
          <Rect width="17" height="20" fill="white" transform="translate(0.721191)" />
        </clipPath>
      </Defs>
    </Svg>
  );
};

export default AlertIcon;
