import { styled, Link } from '@talkspace/react-toolkit';

const StyledLinkText = styled(Link)(({ theme: { colors } }) => {
  return {
    color: colors.accessibilityGreenDark,
    textDecoration: 'underline',
    fontWeight: 700,
    '&:hover': {
      color: colors.accessibilityGreenDark,
      textDecoration: 'underline',
    },
  };
});

const RiskAssessmentLink = ({
  label,
  nycTeensIntakeLink,
}: {
  label: string;
  nycTeensIntakeLink: boolean;
}) => (
  <StyledLinkText
    stopPropagation
    target="_blank"
    href={
      nycTeensIntakeLink
        ? 'https://providers-help.talkspace.com/hc/en-us/articles/5602507430555'
        : 'https://providers-help.talkspace.com/hc/en-us/articles/5602507430555-#h_01GA9V26S3M6FVQQGWT9NCPW0D'
    }
  >
    {label}
  </StyledLinkText>
);

export default RiskAssessmentLink;
