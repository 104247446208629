import Svg, { G, Circle, Path } from 'svgs';
import { useEmotionTheme, EmotionStyle } from '../../core/styled';

interface Props {
  width?: number;
  height?: number;
  color?: string;
  dataQa?: string;
  style?: EmotionStyle;
}

const DollarSign = ({ width = 28, height = 28, color, dataQa, style, ...props }: Props) => {
  const { colors } = useEmotionTheme();
  const titleText = 'Dollar sign';
  return (
    <Svg
      width={width}
      height={height}
      data-qa={dataQa}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 28 28"
      style={style}
      {...props}
    >
      <G
        transform="translate(-602 -2047) translate(600 1792) translate(4 253) translate(0 4)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <Circle
          stroke={color || colors.permaCeruleanBlue}
          strokeWidth={2.8}
          strokeLinecap="round"
          strokeLinejoin="round"
          cx={12}
          cy={12}
          r={12}
        />
        <Path
          d="M13.338 14.382c0-.37-.106-.67-.318-.903-.212-.232-.575-.448-1.09-.649-1.012-.37-1.763-.78-2.252-1.234-.49-.453-.735-1.092-.735-1.917 0-.766.245-1.394.735-1.884s1.15-.778 1.979-.864V5.46h1.086v1.483c.803.119 1.433.454 1.89 1.005.459.552.683 1.267.674 2.147l-.02.034h-1.949c0-.538-.11-.953-.331-1.244-.221-.292-.523-.438-.906-.438-.392 0-.684.113-.875.339-.192.225-.287.525-.287.899 0 .355.101.643.304.864.203.221.575.439 1.118.653 1.007.397 1.753.82 2.238 1.272.486.45.728 1.082.728 1.893 0 .793-.243 1.427-.731 1.9-.488.475-1.15.755-1.99.841v1.354H11.52v-1.347c-.852-.086-1.556-.388-2.112-.906-.556-.517-.825-1.277-.807-2.28l.014-.034h1.948c0 .611.14 1.049.42 1.313.28.264.637.396 1.07.396.415 0 .733-.11.954-.331.22-.221.331-.518.331-.892z"
          fill={color || colors.permaCeruleanBlue}
          fillRule="nonzero"
        />
      </G>
    </Svg>
  );
};

export default DollarSign;
