import { BubbleData } from '@talkspace/react-toolkit';
import { LiveVideoSessionStatus, VideoCredit, Subscription } from 'ts-frontend/types';
import { useCallback } from 'react';
import {
  LVSContent,
  getPsychiatryContent,
  getTherapyContent,
} from '../components/InRoomSchedulingCard/helpers';

interface LVSContentState {
  subscriptions?: Subscription[];
  onBubblePress?: (roomID: number) => void;
  isEAP?: boolean;
  hasAnyCredit?: boolean;
  currentRoomID?: number;
  roomType?: string;
}

const ROOM_CASE = {
  private_room: 'privateRoom',
  couples_room: 'couplesRoom',
  psychiatry_room: 'psychiatryRoom',
};

const useLVSContent = ({
  subscriptions,
  onBubblePress,
  isEAP,
  hasAnyCredit,
  currentRoomID = -1,
  roomType,
}: LVSContentState) => {
  const generateMessaging = () => {
    let message: string | JSX.Element;
    const reminders: BubbleData[] = [];
    // we do this because there is a variable mismatch
    const currentRoomType: string = roomType ? ROOM_CASE[roomType] : '';

    // make sure it will only be these rooms that will display the bubbles
    const rooms: string[] = ['psychiatryRoom', 'privateRoom', 'couplesRoom'];
    const filteredSubscriptions: Subscription[] | undefined =
      subscriptions && subscriptions.filter(({ roomType: type }) => rooms.includes(type));

    if (filteredSubscriptions) {
      const roomsByType = filteredSubscriptions.reduce((prev, next, index) => {
        const {
          id: roomID,
          liveVideoSessionStatus,
          videoCredits,
          roomType: type,
          therapist: { type: therapistType },
        } = next;

        let result: LVSContent = {
          key: `${index}`,
          message: 'No session scheduled',
          bubble: null,
          roomID,
          roomType: type,
        };

        switch (type) {
          case 'psychiatryRoom':
            // handle case where there must be a psychiatrist in the room
            if (therapistType === 'psychiatrist') {
              result = getPsychiatryContent({
                key: `${index}`,
                roomID,
                roomType: type,
                ...(liveVideoSessionStatus as LiveVideoSessionStatus),
                videoCredits: videoCredits as VideoCredit[],
                onPress: () => onBubblePress && onBubblePress(roomID),
              });
            }
            break;
          case 'privateRoom':
          case 'couplesRoom':
            if (therapistType === 'primary') {
              result = getTherapyContent({
                key: `${index}`,
                roomID,
                roomType: type,
                ...(liveVideoSessionStatus as LiveVideoSessionStatus),
                videoCredits: videoCredits as VideoCredit[],
                onPress: () => onBubblePress && onBubblePress(roomID),
              });
            }
            break;
          default:
        }

        if (isEAP && !hasAnyCredit) {
          result.message = 'Your plan does not include live sessions';
        }

        if (prev[type]) {
          return {
            ...prev,
            [type]: { ...prev[type], [roomID]: result },
          };
        }

        return {
          ...prev,
          [type]: { [roomID]: result },
        };
      }, {} as { [roomType: string]: { [roomNumber: number]: LVSContent } });

      // bubble will always display in this order
      const bubbleSortOrder = {
        privateRoom: 0,
        couplesRoom: 1,
        psychiatryRoom: 2,
      };

      message = roomsByType?.[currentRoomType]?.[currentRoomID]?.message ?? '';

      // main logic that handle the bubble visual for single or multiple rooms
      Object.keys(roomsByType)
        .sort((a, b) => bubbleSortOrder[a] - bubbleSortOrder[b])
        .forEach((type) => {
          if (roomsByType?.[type]?.[currentRoomID]?.bubble) {
            reminders.push(roomsByType[type][currentRoomID].bubble as BubbleData);
          } else {
            // find the first room that contains a bubble
            const { bubble: foundBubble } =
              Object.values(roomsByType[type]).find(({ bubble }) => bubble) || {};
            if (foundBubble) {
              reminders.push(foundBubble);
            }
          }
        });
    } else {
      message = 'No session scheduled';
    }
    return { message, reminders };
  };

  return {
    generateMessaging: useCallback(generateMessaging, [
      subscriptions,
      onBubblePress,
      isEAP,
      hasAnyCredit,
      currentRoomID,
      roomType,
    ]),
  };
};

export default useLVSContent;
