import * as types from '../constants/CustomerInformationConstants';
import apiWrapper from '../utils/apiWrapper';
import apiHelper from '../utils/api';
import {
  transformAPIPayloadToReducerPayload,
  transformReducerPayloadToAPIPayload,
} from '../utils/optionsHelpers';

const handleAction = (action) => (payload) => {
  return {
    type: action,
    payload,
  };
};
const handleErrorAction = (action) => (error) => {
  return {
    type: action,
    error,
  };
};

export const resetInitialCustomerState = handleAction(types.RESET_CUSTOMER_INFORMATION);

const requestGetCustomerInformation = handleAction(types.REQUEST_GET_CUSTOMER_INFORMATION);
const requestGetCustomerInformationError = handleErrorAction(
  types.REQUEST_GET_CUSTOMER_INFORMATION_ERROR
);
const receiveGetCustomerInformation = handleAction(types.RECEIVE_GET_CUSTOMER_INFORMATION);
export const getCustomerInformation = (clientUserID) => (dispatch) => {
  dispatch(requestGetCustomerInformation({ clientUserID }));
  return apiWrapper
    .get(`${apiHelper().apiEndpoint}/v3/clients/${clientUserID}/customer-information`)
    .then((response) => {
      const { data } = response.data;
      const customerInfo = transformAPIPayloadToReducerPayload(data);
      dispatch(receiveGetCustomerInformation({ clientUserID, ...customerInfo }));
    })
    .catch((error) => dispatch(requestGetCustomerInformationError(error)));
};

const requestSetCustomerInformation = handleAction(types.REQUEST_SET_CUSTOMER_INFORMATION);
const receiveSetCustomerInformation = handleAction(types.RECEIVE_SET_CUSTOMER_INFORMATION);
const requestSetCustomerInformationError = handleErrorAction(
  types.REQUEST_SET_CUSTOMER_INFORMATION_ERROR
);
export const setCustomerInformation = (clientUserID, payload) => (dispatch) => {
  dispatch(requestSetCustomerInformation());
  const data = transformReducerPayloadToAPIPayload(payload);
  return apiWrapper
    .patch(`${apiHelper().apiEndpoint}/v3/clients/${clientUserID}/customer-information`, { data })
    .then(() => dispatch(receiveSetCustomerInformation(payload)))
    .catch((error) => dispatch(requestSetCustomerInformationError(error)));
};
