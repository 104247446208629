import useTranslation from '@talkspace/i18n/hooks/useTranslation';
import { TFNewMemberNav } from '@talkspace/i18n/types';
import View from '../../../components/View';
import { useEmotionTheme } from '../../../core/styled';
import TextDS from '../typography/TextDS';
import { ColorRolesVersion } from '../../types';
import { spacing, getSpacing } from '../../tokens';

const { space075 } = spacing;

export type StatusTagVariant =
  | 'psychiatry'
  | 'psychotherapy'
  | 'therapyCouples'
  | 'coverageExpired'
  | 'roomClosed'
  | 'liveSessionStarting'
  | 'messagingSessionActive'
  | 'recommendedMatch';

const getText = (variant: StatusTagVariant, tnewMemberNav: TFNewMemberNav) => {
  switch (variant) {
    case 'psychiatry':
      return tnewMemberNav('roomTags.psychiatry', 'Psychiatry', undefined);
    case 'psychotherapy':
      return tnewMemberNav('roomTags.therapy', 'Therapy', undefined);
    case 'therapyCouples':
      return tnewMemberNav('roomTags.couples', 'Couples Therapy', undefined);
    case 'roomClosed':
      return tnewMemberNav('roomTags.room', 'Room closed', undefined);
    case 'coverageExpired':
      return tnewMemberNav('roomTags.insurance', 'Insurance coverage expired', undefined);
    case 'liveSessionStarting':
      return tnewMemberNav('roomTags.live', '"Live session starting', undefined);
    case 'messagingSessionActive':
      return tnewMemberNav('roomTags.messaging', 'Messaging session active', undefined);
    case 'recommendedMatch':
      return tnewMemberNav('roomTags.recommendedMatch', 'Recommended match', undefined);
    default:
      return tnewMemberNav('roomTags.therapy', 'Therapy', undefined);
  }
};

const getColor = (variant: StatusTagVariant, colorRoles: ColorRolesVersion) => {
  switch (variant) {
    case 'psychiatry':
      return colorRoles.surfaces.decorativePsych;
    case 'psychotherapy':
      return colorRoles.surfaces.brandDefault;
    case 'therapyCouples':
      return colorRoles.surfaces.decorativeCouples;
    case 'roomClosed':
      return colorRoles.snowflake.roomClosed;
    case 'coverageExpired':
      return colorRoles.surfaces.criticalBoldDefault;
    case 'liveSessionStarting':
      return colorRoles.surfaces.successDefault;
    case 'messagingSessionActive':
      return colorRoles.surfaces.successDefault;
    case 'recommendedMatch':
      return colorRoles.surfaces.decorativeGratitudeGreen;
    default:
      return colorRoles.surfaces.brandDefault;
  }
};

interface Props {
  variant: StatusTagVariant;
}

const StatusTag = ({ variant }: Props) => {
  const { colorRoles } = useEmotionTheme();
  const { t: tnewMemberNav } = useTranslation('newMemberNav');
  return (
    <View
      align="center"
      style={{
        width: 'fit-content',
        padding: `1px ${getSpacing('space100', true)} 2px`,
        marginRight: space075,
        borderRadius: 100,
        backgroundColor: getColor(variant, colorRoles),
      }}
    >
      <TextDS
        variant="bodyXs"
        colorRole={variant === 'coverageExpired' ? 'textInverse' : 'textDefault'}
      >
        {getText(variant, tnewMemberNav)}
      </TextDS>
    </View>
  );
};

export default StatusTag;
