import Svg, { Defs, Path, G, Use } from 'svgs';

const ChatSuccessIcon = ({
  width = 81,
  height = 71,
  ...otherProps
}: {
  width?: number;
  height?: number;
}) => {
  const titleText = 'chat bubble';
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 81 81"
      aria-label={titleText}
      title={titleText}
      {...otherProps}
    >
      <Defs>
        <Path
          d="M28.713 46.691a9.737 9.737 0 01-.369 3.581 2.12 2.12 0 00.678 2.233c.653.543 1.567.649 2.327.27 2.131-1.07 4.963-2.929 6.684-5.785h1.412C46.93 46.99 53 40.948 53 33.495 53 26.042 46.931 20 39.445 20h-7.89c-6.934 0-12.75 5.21-13.479 12.075-.73 6.865 3.862 13.17 10.641 14.616h-.004z"
          id="b"
        />
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Path
          d="M35 0c19.33 0 35 15.67 35 35S54.33 70 35 70 0 54.33 0 35 15.67 0 35 0z"
          fill="#5C84FB"
          transform="translate(-148 -499) translate(0 437) translate(46 62) translate(107)"
        />
        <G transform="translate(-148 -499) translate(0 437) translate(46 62) translate(107)">
          <Use fill="#000" xlinkHref="#b" />
          <Use fill="#CFEAFF" xlinkHref="#b" />
        </G>
      </G>
    </Svg>
  );
};

export default ChatSuccessIcon;
