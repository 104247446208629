import React, { FunctionComponent } from 'react';
import styled, { EmotionTheme } from '../../core/styled';
import Text, { TextProps } from '../Text';
import type { VariantFunction } from './types';

export type StandardVariant =
  | 'standardBlack'
  | 'standardDarkGrey'
  | 'standardLightGrey'
  | 'standardWhite'
  | 'standardGreen'
  | 'standardDarkCyan'
  | 'standardBoldBrightGreen'
  | 'standardBoldGrey'
  | 'standardBoldTSBlack'
  | 'standardBoldGreen'
  | 'standardBoldDarkCyan'
  | 'standardBoldDarkGreen'
  | 'standardOptionName'
  | 'standardBoldAccessibilityGreen'
  | 'standardInteractiveGreen';

export interface StandardProps extends TextProps {
  variant?: StandardVariant;
}

const standardDefaultStyle = (colors: EmotionTheme['colors']) => {
  return {
    fontWeight: 500,
    fontSize: 15,
    lineHeight: '20px',
    letterSpacing: 0.1,
    color: colors.black,
  };
};

const variants: Record<StandardVariant, VariantFunction> = {
  standardBlack: ({ theme: { colors } }) => {
    return {
      ...standardDefaultStyle(colors),
    };
  },
  standardDarkGrey: ({ theme: { colors } }) => {
    return {
      ...standardDefaultStyle(colors),
      color: colors.slateGrey,
    };
  },
  standardLightGrey: ({ theme: { colors } }) => {
    return {
      ...standardDefaultStyle(colors),
      color: colors.periwinkleGrey,
    };
  },
  standardWhite: ({ theme: { colors } }) => {
    return {
      ...standardDefaultStyle(colors),
      color: colors.white,
    };
  },
  standardGreen: ({ theme: { colors } }) => {
    return {
      ...standardDefaultStyle(colors),
      color: colors.greenText,
    };
  },
  standardDarkCyan: ({ theme: { colors } }) => {
    return {
      ...standardDefaultStyle(colors),
      color: colors.permaDarkCyan,
    };
  },
  standardBoldBrightGreen: ({ theme: { colors } }) => {
    return {
      ...standardDefaultStyle(colors),
      color: colors.limaGreen,
      fontWeight: 700,
    };
  },
  standardBoldGrey: ({ theme: { colors } }) => {
    return {
      ...standardDefaultStyle(colors),
      fontWeight: 700,
      color: colors.baliHaiGrey,
    };
  },
  standardBoldTSBlack: ({ theme: { colors } }) => {
    return {
      ...standardDefaultStyle(colors),
      fontWeight: 700,
      color: colors.black,
    };
  },
  standardBoldGreen: ({ theme: { colors } }) => {
    return {
      ...standardDefaultStyle(colors),
      fontWeight: 700,
      color: colors.greenText,
    };
  },
  standardBoldDarkCyan: ({ theme: { colors } }) => {
    return {
      ...standardDefaultStyle(colors),
      fontWeight: 700,
      color: colors.permaDarkCyan,
    };
  },
  standardBoldDarkGreen: ({ theme: { colors } }) => {
    return {
      ...standardDefaultStyle(colors),
      color: colors.permaPineGreen,
      fontWeight: 700,
    };
  },
  standardOptionName: ({ theme: { colors } }) => {
    return {
      ...standardDefaultStyle(colors),
      color: 'inherit',
      fontWeight: 500,
      lineHeight: '17.58px',
      marginBottom: 3,
    };
  },
  standardBoldAccessibilityGreen: ({ theme: { colors } }) => {
    return {
      ...standardDefaultStyle(colors),
      color: colors.accessibilityGreenDark,
      fontWeight: 700,
    };
  },
  standardInteractiveGreen: ({ theme: { colors } }) => {
    return {
      ...standardDefaultStyle(colors),
      color: colors.accessibilityGreenDark,
      fontWeight: 700,
    };
  },
};

const StandardDefault = styled(Text)(variants.standardBlack);
const StandardWhite = styled(Text)(variants.standardWhite);
const StandardGreen = styled(Text)(variants.standardGreen);
const StandardDarkCyan = styled(Text)(variants.standardDarkCyan);
const StandardDarkGrey = styled(Text)(variants.standardDarkGrey);
const StandardLightGrey = styled(Text)(variants.standardLightGrey);
const StandardBoldBrightGreen = styled(Text)(variants.standardBoldBrightGreen);
const StandardBoldGrey = styled(Text)(variants.standardBoldGrey);
const StandardBoldTsBlack = styled(Text)(variants.standardBoldTSBlack);
const StandardBoldGreen = styled(Text)(variants.standardBoldGreen);
const StandardBoldDarkCyan = styled(Text)(variants.standardBoldDarkCyan);
const StandardBoldDarkGreen = styled(Text)(variants.standardBoldDarkGreen);
const StandardOptionName = styled(Text)(variants.standardOptionName);
const StandardBoldAccessibilityGreen = styled(Text)(variants.standardBoldAccessibilityGreen);
const StandardInteractiveGreen = styled(Text)(variants.standardInteractiveGreen);

const selectStyle = (
  variant: StandardVariant | undefined,
  props: Omit<StandardProps, 'variant'>
) => {
  switch (variant) {
    case 'standardBlack':
      return <StandardDefault {...props} />;
    case 'standardWhite':
      return <StandardWhite {...props} />;
    case 'standardGreen':
      return <StandardGreen {...props} />;
    case 'standardDarkCyan':
      return <StandardDarkCyan {...props} />;
    case 'standardLightGrey':
      return <StandardLightGrey {...props} />;
    case 'standardDarkGrey':
      return <StandardDarkGrey {...props} />;
    case 'standardBoldBrightGreen':
      return <StandardBoldBrightGreen {...props} />;
    case 'standardBoldGrey':
      return <StandardBoldGrey {...props} />;
    case 'standardBoldTSBlack':
      return <StandardBoldTsBlack {...props} />;
    case 'standardBoldGreen':
      return <StandardBoldGreen {...props} />;
    case 'standardBoldDarkCyan':
      return <StandardBoldDarkCyan {...props} />;
    case 'standardBoldDarkGreen':
      return <StandardBoldDarkGreen {...props} />;
    case 'standardBoldAccessibilityGreen':
      return <StandardBoldAccessibilityGreen {...props} />;
    case 'standardInteractiveGreen':
      return <StandardInteractiveGreen {...props} />;
    case 'standardOptionName':
      return <StandardOptionName {...props} />;
    default:
      return <StandardDefault {...props} />;
  }
};

const Standard: FunctionComponent<StandardProps> = ({ variant, ...props }) =>
  selectStyle(variant, props);

export default Standard;
