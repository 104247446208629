import View from '../View';
import styled from '../../core/styled';
import commonStyles from '../../constants/commonStyles';

const { crossBrowserFocusRing, roundedFocusRing } = commonStyles;

const RouterLinkWrapper = styled(View)<{
  primaryColor?: string;
  roundedFocusStyle?: boolean;
}>(({ primaryColor, roundedFocusStyle }) => {
  let keyboardFocusStyles = {};
  if (primaryColor && roundedFocusStyle) {
    keyboardFocusStyles = {
      ...roundedFocusRing(primaryColor, true),
      borderRadius: 5,
    };
  } else {
    keyboardFocusStyles = crossBrowserFocusRing;
  }

  return {
    display: 'inline-block',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: ts doesn't accept outline: '-webkit-focus-ring-color auto 7px' but that is valid styling
    '&:focus-within': {
      ...keyboardFocusStyles,
      a: {
        outline: 'none',
      },
    },
  };
});

export default RouterLinkWrapper;
