import { PanelHeader, TSLogo, View, ScreenProvider } from '@talkspace/react-toolkit';
import { History } from 'history';
import { WizardScreen, WizardScheme, useWizardActions } from 'stepWizard';
import { Route, Switch } from '@/core/routerLib';
import providerLeavingScheme from './providerLeavingScheme';
import FinalStep from './containers/FinalStep';

const HEADER_HEIGHT = 67;

const panelHeaderStyle = {
  height: HEADER_HEIGHT,
  flex: 'none',
  borderBottom: '1px solid #D8D8E0',
};

const Routes = ({ steps, history }: { steps: WizardScheme['steps']; history: History }) => {
  const { onExitClick } = useWizardActions();

  return (
    <View>
      <PanelHeader
        style={panelHeaderStyle}
        renderLeft={() => <TSLogo style={{ marginLeft: -12 }} variant="2024" />}
        onRightPress={() => {
          onExitClick();
          history.push('/');
        }}
      />
      <Switch>
        <ScreenProvider>
          <Route exact path="/provider-leaving/final-step/screen/:screen" component={FinalStep} />
          <Route
            exact
            path={[
              '/provider-leaving/source/:source/context-id/:contextID',
              '/provider-leaving/source/:source',
              '/provider-leaving',
            ]}
            render={() => <WizardScreen wizardScheme={{ ...providerLeavingScheme, steps }} />}
          />
        </ScreenProvider>
      </Switch>
    </View>
  );
};

export default Routes;
