import { ChangeEventHandler, FocusEventHandler, useRef, useState } from 'react';
import zxcvbn from 'zxcvbn';

import useTranslation from '@talkspace/i18n/hooks/useTranslation';

import PasswordView, { PasswordViewProps } from './PasswordView';
import RHFPasswordView from './RHFPasswordView';

export interface ValidationData {
  score: number;
  errorMessage: string;
}

export interface PasswordProps
  extends Omit<
    PasswordViewProps,
    'displayError' | 'canViewPassword' | 'toggleCanViewPassword' | 'score' | 'showScore' | 'masking'
  > {
  autoFocus?: boolean;
  maskEnabled?: boolean;
  minValidScore?: number;
  /**
   * Extra strings that zxcvbn will penalize against if they are used
   * in the password field. Example: email, nickname, 'talkspace', etc...
   */
  userInputs?: Array<string>;
  shouldDisplayError?: boolean;
  onChangeText?: (text: string, isValid: boolean, validationData: ValidationData) => void;
  onValidationChanged?: (isValid: boolean, validationData: ValidationData) => void;
  dataQa?: string;
  fieldName?: string;
}

const Password = ({
  autoFocus,
  userInputs,
  passwordsMatch,
  shouldDisplayError,
  isConfirmationInput,
  shouldCheckStrength,
  onBlur,
  onFocus,
  onChange,
  onChangeText,
  onValidationChanged,
  minValidScore = 3,
  maskEnabled = true,
  dataQa,
  fieldName,
  ...otherProps
}: PasswordProps) => {
  const PasswordViewComponent = fieldName ? RHFPasswordView : PasswordView;
  const [displayError, setDisplayError] = useState(false);
  const [isMasking, setIsMasking] = useState(false);
  const [hasFocus, setHasFocus] = useState(autoFocus);
  const [canViewPassword, setCanViewPassword] = useState(false);
  const [score, setScore] = useState(-1);
  const eyeClickedRef = useRef(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { t: tErrorsCommon } = useTranslation('errors.common');

  const onChangeHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
    const text = e.target.value;
    const hasLength = text.length > 0;

    if (hasLength && maskEnabled) {
      setIsMasking(true);
    } else if (!hasLength) {
      setIsMasking(false);
    }

    if (shouldCheckStrength && hasLength) {
      const { score: newScore } = zxcvbn(text, userInputs);
      const isValid = newScore >= minValidScore;
      const errorMessage = !isValid
        ? tErrorsCommon(
            'passwordValidation.passwordNotStrongEnough',
            'Please select a stronger password.'
          )
        : '';
      onValidationChanged?.(isValid, {
        score: newScore,
        errorMessage,
      });
      setScore(newScore);
      onChangeText?.(text, isValid, {
        score: newScore,
        errorMessage,
      });
    } else {
      setScore(-1);
      onChangeText?.(text, true, {
        score: -1,
        errorMessage: '',
      });
    }
    onChange?.(e);
  };

  const onFocusHandler: FocusEventHandler<HTMLInputElement> = (e) => {
    setDisplayError(false);
    setHasFocus(true);
    onFocus?.(e);
  };

  const onBlurHandler: FocusEventHandler<HTMLInputElement> = (e) => {
    eyeClickedRef.current = false;
    // Adding a small timeout in case the user loses focus to click on the eye
    // which focuses back the input anyways. So this prevents that small flicker.
    const timeout = setTimeout(() => {
      if (!eyeClickedRef.current) {
        setDisplayError(!!isConfirmationInput && !passwordsMatch);
        setHasFocus(false);
      }
    }, 250);

    onBlur?.(e);
    return () => {
      clearTimeout(timeout);
    };
  };

  const toggleCanViewPassword = () => {
    inputRef.current?.focus();
    eyeClickedRef.current = true;
    setCanViewPassword((canView) => !canView);
  };

  return (
    <PasswordViewComponent
      {...otherProps}
      fieldName={fieldName}
      dataQa={dataQa}
      ref={inputRef}
      score={score}
      masking={isMasking}
      hasFocus={hasFocus}
      showScore={!!minValidScore}
      passwordsMatch={passwordsMatch}
      canViewPassword={canViewPassword}
      shouldCheckStrength={shouldCheckStrength}
      displayError={shouldDisplayError === undefined ? displayError : shouldDisplayError}
      onBlur={onBlurHandler}
      onFocus={onFocusHandler}
      onChange={onChangeHandler}
      toggleCanViewPassword={toggleCanViewPassword}
    />
  );
};

export default Password;
