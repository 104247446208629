import { createContext, useReducer, FunctionComponent, useContext, useCallback } from 'react';
import { BusinessLineQueryResponse } from 'ts-frontend/hooks/useQueryBusinessLine';
import { GetProgressNoteHelperDataResponse } from 'hooks/notes/useQueryProgressNoteHelperData';
import { NoteSessionReportData } from 'hooks/notes/useQueryNotes';
import { ProgressNoteQueryResponse } from 'hooks/notes/useQueryProgressNote';
import { ProgressNoteFormState, ProgressNoteFormActions } from './types';
import getInitialPsychState from './getInitialPsychState';
import { FormMode, SessionService } from '../../types';

const ProgressNoteFormStateContext = createContext<ProgressNoteFormState | undefined>(undefined);
const ProgressNoteFormActionsContext = createContext<ProgressNoteFormActions | undefined>(
  undefined
);

const progressNoteFormReducer = (
  currentState: ProgressNoteFormState,
  action: { payload?: Partial<ProgressNoteFormState> }
): ProgressNoteFormState => {
  return { ...currentState, ...action.payload };
};

interface Props {
  formMode: FormMode;
  businessLine: BusinessLineQueryResponse;
  helperData: GetProgressNoteHelperDataResponse;
  sessionServices: SessionService[];
  currentSessionReport: NoteSessionReportData | null;
  selectedNote: ProgressNoteQueryResponse | null;
}

export const PsychProgressNoteFormProvider: FunctionComponent<Props> = ({
  children,
  formMode,
  businessLine,
  helperData,
  sessionServices,
  currentSessionReport,
  selectedNote,
}) => {
  const [state, dispatch] = useReducer(
    progressNoteFormReducer,
    getInitialPsychState({
      formMode,
      businessLine,
      helperData,
      sessionServices,
      currentSessionReport,
      selectedNote,
    })
  );

  const setFormState = (newState: Partial<ProgressNoteFormState>): void => {
    dispatch({ payload: newState });
  };

  const resetFormState = (): void => {
    const initialState = getInitialPsychState({
      formMode,
      businessLine,
      helperData,
      sessionServices,
      currentSessionReport,
      selectedNote,
    });
    dispatch({ payload: initialState });
  };

  const actions: ProgressNoteFormActions = {
    setFormState: useCallback(setFormState, []),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    resetFormState: useCallback(resetFormState, []),
  };

  return (
    <ProgressNoteFormStateContext.Provider value={state}>
      <ProgressNoteFormActionsContext.Provider value={actions}>
        {children}
      </ProgressNoteFormActionsContext.Provider>
    </ProgressNoteFormStateContext.Provider>
  );
};

export const useProgressNoteFormState = (): ProgressNoteFormState => {
  const context = useContext(ProgressNoteFormStateContext);
  if (context === undefined) {
    throw new Error('useProgressNoteFormState must be used within a ProgressNoteFormProvider');
  }
  return context;
};

export const useProgressNoteFormActions = (): ProgressNoteFormActions => {
  const context = useContext(ProgressNoteFormActionsContext);
  if (context === undefined) {
    throw new Error('useProgressNoteFormActions must be used within a ProgressNoteFormProvider');
  }
  return context;
};
