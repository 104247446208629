export const REQUEST_GET_TREATMENT_PLAN = 'REQUEST_GET_TREATMENT_PLAN';
export const REQUEST_GET_TREATMENT_PLAN_ERROR = 'REQUEST_GET_TREATMENT_PLAN_ERROR';
export const RECEIVE_GET_TREATMENT_PLAN = 'RECEIVE_GET_TREATMENT_PLAN';

export const REQUEST_POST_TREATMENT_PLAN = 'REQUEST_POST_TREATMENT_PLAN';
export const REQUEST_POST_TREATMENT_PLAN_ERROR = 'REQUEST_POST_TREATMENT_PLAN_ERROR';
export const RECEIVE_POST_TREATMENT_PLAN = 'RECEIVE_POST_TREATMENT_PLAN';

export const REQUEST_PATCH_TREATMENT_PLAN = 'REQUEST_PATCH_TREATMENT_PLAN';
export const REQUEST_PATCH_TREATMENT_PLAN_ERROR = 'REQUEST_PATCH_TREATMENT_PLAN_ERROR';
export const RECEIVE_PATCH_TREATMENT_PLAN = 'RECEIVE_PATCH_TREATMENT_PLAN';

export const REQUEST_GET_STANDARD_GOALS = 'REQUEST_GET_STANDARD_GOALS';
export const REQUEST_GET_STANDARD_GOALS_ERROR = 'REQUEST_GET_STANDARD_GOALS_ERROR';
export const RECEIVE_GET_STANDARD_GOALS = 'RECEIVE_GET_STANDARD_GOALS';

export const REQUEST_GET_GOALS = 'REQUEST_GET_GOALS';
export const REQUEST_GET_GOALS_ERROR = 'REQUEST_GET_GOALS_ERROR';
export const RECEIVE_GET_GOALS = 'RECEIVE_GET_GOALS';

export const REQUEST_POST_GOALS = 'REQUEST_POST_GOALS';
export const REQUEST_POST_GOALS_ERROR = 'REQUEST_POST_GOALS_ERROR';
export const RECEIVE_POST_GOALS = 'RECEIVE_POST_GOALS';

export const REQUEST_PATCH_GOAL = 'REQUEST_PATCH_GOAL';
export const REQUEST_PATCH_GOAL_ERROR = 'REQUEST_PATCH_GOAL_ERROR';
export const RECEIVE_PATCH_GOAL = 'RECEIVE_PATCH_GOAL';

export const REQUEST_DELETE_GOAL = 'REQUEST_DELETE_GOAL';
export const REQUEST_DELETE_GOAL_ERROR = 'REQUEST_DELETE_GOAL_ERROR';
export const RECEIVE_DELETE_GOAL = 'RECEIVE_DELETE_GOAL';

export const REQUEST_GET_STANDARD_OBJECTIVES = 'REQUEST_GET_STANDARD_OBJECTIVES';
export const REQUEST_GET_STANDARD_OBJECTIVES_ERROR = 'REQUEST_GET_STANDARD_OBJECTIVES_ERROR';
export const RECEIVE_GET_STANDARD_OBJECTIVES = 'RECEIVE_GET_STANDARD_OBJECTIVES';

export const REQUEST_GET_OBJECTIVES = 'REQUEST_GET_OBJECTIVES';
export const REQUEST_GET_OBJECTIVES_ERROR = 'REQUEST_GET_OBJECTIVES_ERROR';
export const RECEIVE_GET_OBJECTIVES = 'RECEIVE_GET_OBJECTIVES';

export const REQUEST_POST_OBJECTIVES = 'REQUEST_POST_OBJECTIVES';
export const REQUEST_POST_OBJECTIVES_ERROR = 'REQUEST_POST_OBJECTIVES_ERROR';
export const RECEIVE_POST_OBJECTIVES = 'RECEIVE_POST_OBJECTIVES';

export const REQUEST_PATCH_OBJECTIVE = 'REQUEST_PATCH_OBJECTIVE';
export const REQUEST_PATCH_OBJECTIVE_ERROR = 'REQUEST_PATCH_OBJECTIVE_ERROR';
export const RECEIVE_PATCH_OBJECTIVE = 'RECEIVE_PATCH_OBJECTIVE';

export const REQUEST_DELETE_OBJECTIVE = 'REQUEST_DELETE_OBJECTIVE';
export const REQUEST_DELETE_OBJECTIVE_ERROR = 'REQUEST_DELETE_OBJECTIVE_ERROR';
export const RECEIVE_DELETE_OBJECTIVE = 'RECEIVE_DELETE_OBJECTIVE';

export const REQUEST_GET_STANDARD_INTERVENTIONS = 'REQUEST_GET_STANDARD_INTERVENTIONS';
export const REQUEST_GET_STANDARD_INTERVENTIONS_ERROR = 'REQUEST_GET_STANDARD_INTERVENTIONS_ERROR';
export const RECEIVE_GET_STANDARD_INTERVENTIONS = 'RECEIVE_GET_STANDARD_INTERVENTIONS';

export const REQUEST_GET_INTERVENTIONS = 'REQUEST_GET_INTERVENTIONS';
export const REQUEST_GET_INTERVENTIONS_ERROR = 'REQUEST_GET_INTERVENTIONS_ERROR';
export const RECEIVE_GET_INTERVENTIONS = 'RECEIVE_GET_INTERVENTIONS';

export const REQUEST_POST_INTERVENTIONS = 'REQUEST_POST_INTERVENTIONS';
export const REQUEST_POST_INTERVENTIONS_ERROR = 'REQUEST_POST_INTERVENTIONS_ERROR';
export const RECEIVE_POST_INTERVENTIONS = 'RECEIVE_POST_INTERVENTIONS';

export const REQUEST_PATCH_INTERVENTION = 'REQUEST_PATCH_INTERVENTION';
export const REQUEST_PATCH_INTERVENTION_ERROR = 'REQUEST_PATCH_INTERVENTION_ERROR';
export const RECEIVE_PATCH_INTERVENTION = 'RECEIVE_PATCH_INTERVENTION';

export const REQUEST_DELETE_INTERVENTION = 'REQUEST_DELETE_INTERVENTION';
export const REQUEST_DELETE_INTERVENTION_ERROR = 'REQUEST_DELETE_INTERVENTION_ERROR';
export const RECEIVE_DELETE_INTERVENTION = 'RECEIVE_DELETE_INTERVENTION';

export const REQUEST_GET_ARCHIVED_TREATMENT_PLANS = 'REQUEST_GET_ARCHIVED_TREATMENT_PLANS';
export const REQUEST_GET_ARCHIVED_TREATMENT_PLANS_ERROR =
  'REQUEST_GET_ARCHIVED_TREATMENT_PLANS_ERROR';
export const RECEIVE_GET_ARCHIVED_TREATMENT_PLANS = 'RECEIVE_GET_ARCHIVED_TREATMENT_PLANS';

export const REQUEST_GET_ARCHIVED_TREATMENT_PLAN = 'REQUEST_GET_ARCHIVED_TREATMENT_PLAN';
export const REQUEST_GET_ARCHIVED_TREATMENT_PLAN_ERROR =
  'REQUEST_GET_ARCHIVED_TREATMENT_PLAN_ERROR';
export const RECEIVE_GET_ARCHIVED_TREATMENT_PLAN = 'RECEIVE_GET_ARCHIVED_TREATMENT_PLAN';
export const CLEAN_CURRENT_TREATMENT_PLAN = 'CLEAN_CURRENT_TREATMENT_PLAN';
