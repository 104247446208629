import * as React from 'react';
import BaseScreen from '../components/BaseScreen';
import CredentialsForm from '../containers/CredentialsForm';

import { SignupStepEnum } from '../types';

const CredentialsScreen: React.FC = () => (
  <BaseScreen step={SignupStepEnum.credentials}>
    <CredentialsForm />
  </BaseScreen>
);

export default CredentialsScreen;
