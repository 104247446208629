import RatingStar from './RatingStar';
import RatingStarV1 from './RatingStarV1';
import { withVersioning } from '../../utils';
import { VersionMap } from '../../types';

const versionMap: VersionMap<React.ComponentProps<typeof RatingStar>> = {
  '1.0.0': RatingStarV1,
  DefaultComponent: RatingStar,
};

export default withVersioning(versionMap);
