export enum SignupStepEnum {
  personalDetails,
  clinicalInfo,
  credentials,
}

export type SignupStep = {
  id: SignupStepEnum;
  name: string;
  title: string;
};

export type License = {
  id?: string;
  licenseTypeID: string;
  number: string;
  country: string;
  state: string;
};
export type PersonalDetailsData = {
  title: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  city: string;
  state: string;
  zipCode: string;
  gender: string;
  firstClinicalLicenseDate: string;
};
export type ClinicalInfoData = {
  phoneNumber: string;
  phoneNumberCountryCode: string;
  npiNumber: string;
  professionalDegree: string;
  institutionAssociatedProfessionalDegree: string;
  professionalLevel: string;
  licenses: License[];
  caqhNumber: string;
  accessInitiativeParticipation: null | string | boolean;
};
export type CredentialsData = {
  email: string;
  password: string;
  confirmedPassword: string;
};
export type TherapistProfileData = PersonalDetailsData & ClinicalInfoData & CredentialsData;

export type LicenseTypesData = {
  id: number;
  name: string;
  description: string;
}[];
