import { FunctionComponent } from 'react';
import { Text, View } from '@talkspace/react-toolkit';
import styled, { EmotionStyle } from '@/core/styled';

interface WordCountProps {
  text: string;
  hide?: boolean;
  color?: string;
  className?: string;
  style?: EmotionStyle;
  textStyle?: EmotionStyle;
}

const Wrapper = styled(View)({});

const WordCountText = styled(Text)(({ color }) => {
  return {
    fontSize: 14,
    color,
  };
});

const WordCount: FunctionComponent<WordCountProps> = ({
  hide,
  text,
  style,
  className,
  textStyle,
  color = '#65727D',
  ...otherProps
}) => {
  if (hide) return null;
  const match = text.match(/\S+/g);
  const wordCount = (match && match.length) || 0;

  return (
    <Wrapper style={style} className={className}>
      <WordCountText color={color} style={textStyle} {...otherProps}>
        {wordCount} word{wordCount === 1 ? '' : 's'}
      </WordCountText>
    </Wrapper>
  );
};

export default WordCount;
