import { DynamicTask, UnsubmittedSessionTask } from '../../../hooks/dashboard/useQueryTaskListV3';
import { isIonic } from '../../../modules/auth/reactFrame/helpers';

export const TASK_LIST_TUTORIAL_COMPLETE = 'taskListTutorialComplete';

export const TASK_LIST_TUTORIAL_VIDEO_URL = 'https://vimeo.com/874173284/36cea4f33b';

export const DOCUMENTATION_URL = '/hc/en-us/categories/360004281672-Documentation-Billing-Payment';

export type FieldType =
  | 'oldest'
  | 'newest'
  | 'accountType'
  | 'submissionStatus'
  | 'sessionModality';

const statusOrder = {
  readyToSubmit: 0,
  reopenedAndReadyForReview: 1,
  readyForReview: 2,
  lateCancellation: 3,
  noShow: 4,
};

const accountTypeOrder = {
  bh: 0,
  eap: 1,
  dte: 2,
  b2c: 3,
};

const modalityOrder = {
  live: 0,
  messaging: 1,
};

const compareDatesOldestToNewest = (a: DynamicTask, b: DynamicTask) =>
  new Date(a.serviceStartDate).getTime() - new Date(b.serviceStartDate).getTime();

const compareDatesNewestToOldest = (a: DynamicTask, b: DynamicTask) =>
  new Date(b.serviceStartDate).getTime() - new Date(a.serviceStartDate).getTime();

const compareAccountType = (a: DynamicTask, b: DynamicTask) => {
  const accountTypeA =
    accountTypeOrder[a.accountType] === undefined ? Infinity : accountTypeOrder[a.accountType];
  const accountTypeB =
    accountTypeOrder[b.accountType] === undefined ? Infinity : accountTypeOrder[b.accountType];
  if (accountTypeA === accountTypeB) {
    return compareDatesOldestToNewest(a, b);
  }
  return accountTypeA - accountTypeB;
};

const compareSessionModality = (a: DynamicTask, b: DynamicTask) => {
  const modalityA =
    modalityOrder[a.sessionModality] === undefined ? Infinity : modalityOrder[a.sessionModality];
  const modalityB =
    modalityOrder[b.sessionModality] === undefined ? Infinity : modalityOrder[b.sessionModality];
  if (modalityA === modalityB) {
    return compareDatesOldestToNewest(a, b);
  }
  return modalityA - modalityB;
};

const compareSubmissionStatus = (a: UnsubmittedSessionTask, b: UnsubmittedSessionTask) => {
  const statusA =
    statusOrder[a.sessionStatus] === undefined ? Infinity : statusOrder[a.sessionStatus];
  const statusB =
    statusOrder[b.sessionStatus] === undefined ? Infinity : statusOrder[b.sessionStatus];

  if (statusA !== statusB) {
    return statusA - statusB;
  }

  const wordCountA = a.wordCount ?? -Infinity;
  const wordCountB = b.wordCount ?? -Infinity;

  return wordCountA - wordCountB || compareDatesOldestToNewest(a, b);
};

export const getSortedArray = (field: FieldType, array?: Array<DynamicTask>) => {
  if (array && array.length) {
    switch (field) {
      case 'oldest':
        return array.sort(compareDatesOldestToNewest);
      case 'newest':
        return array.sort(compareDatesNewestToOldest);
      case 'accountType':
        return array.sort(compareAccountType);
      case 'sessionModality':
        return array.sort(compareSessionModality);
      case 'submissionStatus':
        return array.sort(compareSubmissionStatus);
      default:
        return array;
    }
  }
  return [];
};

export const getSortDropdownTitles = () => [
  { title: 'Oldest', subtitle: 'See oldest tasks first', id: 'oldest' },
  { title: 'Newest', subtitle: 'See most recent tasks first', id: 'newest' },
  { title: 'Plan type', subtitle: 'See BH and EAP tasks first', id: 'accountType' },
  { title: 'Modality', subtitle: 'See live session tasks first', id: 'sessionModality' },
  { title: 'Status', subtitle: 'See “Ready to submit” tasks first', id: 'submissionStatus' },
];

export const getTaskListTableColumns = () => [
  { name: 'serviceStartDate', label: 'Date of session', width: 130 },
  { name: 'nickName', label: 'Client', width: '50%' },
  { name: 'sessionModality', label: 'Modality', width: '30%' },
  { name: 'accountType', label: 'Plan type', width: '20%' },
  { name: 'submissionStatus', label: 'Status of session', width: 290 },
  { name: 'actions', label: '', width: 130 },
];

export const getShouldDisplayTooltip = ({ sessionModality, accountType, submissionStatus }) => {
  if (['noShow', 'lateCancellation'].includes(submissionStatus)) {
    return false;
  }

  if (sessionModality === 'messaging') {
    if (accountType === 'b2c' || accountType === 'dte') {
      return submissionStatus === 'readyToSubmit';
    }
    if (accountType === 'eap') {
      return (
        submissionStatus === 'readyToSubmit' || submissionStatus === 'reopenedAndReadyForReview'
      );
    }
    if (accountType === 'bh') {
      return true;
    }
  }

  return false;
};

interface ConstructURLParams {
  isTransferred?: boolean;
  roomID: number;
  draftProgressNoteID?: number;
  unsubmittedSessionID: string;
}

export const constructURL = ({
  isTransferred,
  roomID,
  draftProgressNoteID,
  unsubmittedSessionID,
}: ConstructURLParams) => {
  const base = isTransferred
    ? `tasks/room/${roomID}/progress-notes`
    : `/room/${roomID}/progress-notes`;
  const path = draftProgressNoteID ? `${draftProgressNoteID}/edit` : 'new';

  const queryString = isTransferred && isIonic() ? '&noHeader=true' : '';
  return `${base}/${path}?id=${unsubmittedSessionID}${queryString}`;
};
