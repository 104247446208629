import { FunctionComponent } from 'react';
import {
  BaseButton,
  Big,
  Button,
  CloseButton,
  ExtraHuge,
  Large,
  Standard,
  View,
} from '@talkspace/react-toolkit';
import styled from '@/core/styled';
import { useVideoCallActions, useVideoCallState } from '../hooks/videoCallContext';
import VideoCallSettingsMic from '../components/Icons/VideoCallSettingsMic';
import VideoCallSettingsCamera from '../components/Icons/VideoCallSettingsCamera';
import ToggleSwitch from '../components/ToggleSwitch/ToggleSwitch';

interface ToggleProps {
  isOn: boolean;
  onChange: (value: boolean) => void;
  name: string;
}

const VideoCallToggle: FunctionComponent<ToggleProps> = ({ isOn, onChange, name }) => (
  <ToggleSwitch isOn={isOn} onValueChange={onChange} name={name} />
);

const Wrapper = styled(View)(({ theme: { window } }) => {
  const { isMobile } = window;
  const mobileStyle = isMobile && { marginTop: 0 };
  return { textAlign: 'center', marginTop: 'calc(50vh - 300px)', ...mobileStyle };
});

const JoinVideoCall: FunctionComponent<{ onClosePress: () => void; isMobile: boolean }> = ({
  onClosePress,
  isMobile,
}) => {
  const { isAudioOn, isVideoOn, startDayString, callTimeScheduleString, videoCall, modality } =
    useVideoCallState();

  const { joinCallAction, toggleAudioVideoAction } = useVideoCallActions();

  const joinCall = () => {
    if (videoCall)
      joinCallAction({
        userToken: videoCall.agora.userToken,
        channelName: videoCall.agora.channelName,
        encryptionKey: videoCall.agora.encryptionKey,
      });
  };

  return (
    <>
      {!isMobile && (
        <View justify="end" row>
          <CloseButton style={{ margin: 16 }} onPress={onClosePress} />
        </View>
      )}
      <Wrapper align="center">
        <ExtraHuge>Join live {modality} session</ExtraHuge>
        <Large style={{ marginTop: 10 }} variant="largeDarkGrey">
          <Big inline variant="bigDarkGreen">
            {startDayString}
          </Big>{' '}
          from {callTimeScheduleString}
        </Large>
        <View row justify="space-between" style={{ width: 335, marginTop: 45 }}>
          <View row style={{ width: 200, paddingLeft: 5 }}>
            <VideoCallSettingsMic />
            <Large style={{ marginLeft: 25, marginTop: 5 }}>Join with audio</Large>
          </View>
          <VideoCallToggle
            isOn={isAudioOn}
            onChange={() => toggleAudioVideoAction(!isAudioOn, isVideoOn)}
            name="Join with audio"
          />
        </View>
        {modality === 'video' && (
          <View row justify="space-between" style={{ width: 335, marginTop: 25 }}>
            <View row style={{ width: 160 }}>
              <VideoCallSettingsCamera style={{ marginTop: 7 }} />
              <Large style={{ marginLeft: 20, marginTop: 5 }}>Join with video</Large>
            </View>
            <VideoCallToggle
              isOn={isVideoOn}
              onChange={() => toggleAudioVideoAction(isAudioOn, !isVideoOn)}
              name="Join with video"
            />
          </View>
        )}

        <Button style={{ width: 335, marginTop: 50, marginBottom: 15 }} onPress={joinCall}>
          Join
        </Button>
        <BaseButton onPress={onClosePress}>
          <Standard variant="standardDarkGrey">Cancel</Standard>
        </BaseButton>
      </Wrapper>
    </>
  );
};

export default JoinVideoCall;
