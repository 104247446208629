import { useEmotionTheme } from '@talkspace/react-toolkit';
import { VoidFunctionComponent } from 'react';
import Svg, { Rect, Circle } from 'svgs';

interface FilterIconProps {
  width?: number;
  height?: number;
  showBackground?: boolean;
}

const FilterIcon: VoidFunctionComponent<FilterIconProps> = ({
  width = 28,
  height = 28,
  showBackground = true,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'Filter icon';

  return (
    <Svg
      title={titleText}
      aria-label={titleText}
      width={width}
      height={height}
      fill="none"
      {...otherProps}
    >
      {showBackground && (
        <Rect width={width} height={height} rx={8} fill={colors.permaFlowerBlue} />
      )}
      <Rect x={5.5} y={10.5} width={17} height={2} rx={1} fill={colors.permaEden} />
      <Rect x={5.5} y={16.5} width={17} height={2} rx={1} fill={colors.permaEden} />
      <Circle
        cx={11.5}
        cy={17.5}
        r={2}
        fill={colors.permaFlowerBlue}
        stroke={colors.permaEden}
        strokeWidth={2}
      />
      <Circle
        cx={16.5}
        cy={11.5}
        r={2}
        fill={colors.permaFlowerBlue}
        stroke={colors.permaEden}
        strokeWidth={2}
      />
    </Svg>
  );
};

export default FilterIcon;
