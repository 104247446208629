import React, { useEffect, useCallback, useRef } from 'react';
import { useLDClient, ProviderConfig } from 'launchdarkly-react-client-sdk';
import { warn, debug } from 'ts-frontend/utils/dev-console';
import { LDFlags } from 'ts-frontend/types';
import { sleep } from 'ts-frontend/helpers';
import isEqual from 'lodash/isEqual';
import { useFlags } from './FlagsProvider';
import { isMobile } from '@/auth/reactFrame/helpers';

/**
 * Wraps the specified component in a conditional wrapper that will only render it when
 * flags have been loaded from LaunchDarkly. If they've not yet loaded, it will render nothing.
 */
function withFlags<P extends {}>(Component: React.VoidFunctionComponent<P>) {
  return (props: P) => {
    const flags = useFlags();
    if (Object.keys(flags).length === 0) {
      return null;
    }
    return <Component {...props} />;
  };
}

export type SetCustomAttributes = (
  value: NonNullable<ProviderConfig['user']>['custom']
) => Promise<LDFlags | undefined>;

/**
 * Returns a callback function that allows you to update the `user.custom` property
 * in LaunchDarkly. Returns the updated flags after the custom attribute changes, or
 * existing flags if no changes were detected after 50 milliseconds.
 */
export function useSetCustomAttributes(useOriginalFlags?: boolean) {
  const ldClient = useLDClient();
  const allFlags = useFlags();
  const flags = useOriginalFlags ? allFlags.original : allFlags;
  const flagsRef = useRef(flags);
  flagsRef.current = flags;
  const waitingFlagsUpdatePromiseRef = useRef<((f: typeof flags) => void) | undefined>();

  useEffect(() => {
    // Callback of function should return updated flags
    waitingFlagsUpdatePromiseRef.current?.(flags);
  }, [flags]);

  return useCallback<SetCustomAttributes>(
    async function setCustomAttributes(value) {
      if (!ldClient) {
        warn('LaunchDarkly: Could not Identify because client was not defined');
        return undefined;
      }
      const custom = {
        ...ldClient.getContext(),
        ...value,
      };
      if (isEqual(ldClient.getContext(), custom)) {
        warn('LaunchDarkly: Custom Attribute skipped - Same attributes used');
        return flagsRef.current;
      }
      debug('QuickMatch: Re-identifying with LaunchDarkly', {
        value,
      });
      const promise = new Promise<typeof flags>((resolve) => {
        waitingFlagsUpdatePromiseRef.current = resolve;
      });
      await ldClient.identify({
        ...custom,
        kind: 'user',
        key: ldClient.getContext()?.key as string,
      });

      // Default to existing flags if timeout. Small timeout just for re-render
      return Promise.race([promise, sleep(50, flagsRef.current)]) as Promise<typeof flags>;
    },
    [ldClient]
  );
}

export interface SetCustomAttributesProp {
  setCustomAttributes: SetCustomAttributes;
}

export const withSetCustomAttributes =
  <P extends SetCustomAttributesProp>(
    Component: React.ComponentType<P>
  ): React.ComponentType<Omit<P, keyof SetCustomAttributesProp>> =>
  (props: P) => {
    const setCustomAttributes = useSetCustomAttributes();
    return <Component {...props} setCustomAttributes={setCustomAttributes} />;
  };

export const useNewMemberNav = (): boolean => {
  const { memberNewNav } = useFlags();
  return !isMobile() && memberNewNav === 'treatment';
};

export const useIsTeenspaceActive = (): boolean => {
  const { teenspaceCommunity } = useFlags();
  return teenspaceCommunity;
};

export const usePostLvsSwitchPrompt = (): boolean => {
  const { postLvsSwitchPrompt } = useFlags();
  return postLvsSwitchPrompt === 'treatment';
};

export default withFlags;
