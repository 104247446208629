import { FunctionComponent } from 'react';
import { CardType } from 'ts-frontend/types';
import View from '../View';

import { EmotionStyle } from '../../core/styled';
import Mastercard from './Mastercard';
import Amex from './Amex';
import Discover from './Discover';
import Visa from './Visa';
import DinersClub from './DinersClub';
import PlainCreditCard from './PlainCreditCard';

interface Props {
  cardType?: CardType;
  color?: string;
  style?: EmotionStyle;
}

function getCardComponent(cardType?: CardType): FunctionComponent<{ color?: string }> {
  if (cardType) {
    switch (cardType) {
      case 'MasterCard':
        return Mastercard;
      case 'American Express':
        return Amex;
      case 'Discover':
        return Discover;
      case 'Diners Club':
        return DinersClub;
      default:
        return Visa;
    }
  } else {
    return PlainCreditCard;
  }
}

const CreditCard: FunctionComponent<Props> = ({ cardType, color, style = {} }) => {
  const CardComponent = getCardComponent(cardType);

  return (
    <View style={style}>
      <CardComponent color={color} />
    </View>
  );
};

export default CreditCard;
