import View from '../../../components/View';
import { Big, Small } from '../../../components/Typography';
import RadioButton from '../../../components/RadioButton';
import TouchableView from '../../../components/TouchableView';
import styled, { useEmotionTheme } from '../../../core/styled';
import { OptionBlockProps } from './OptionBlock';

const OptionWrapper = styled(TouchableView)<{
  index: number;
}>(({ index, theme: { colorRoles } }) => {
  return {
    minWidth: 335,
    maxWidth: 430,
    padding: 16,
    borderRadius: 8,
    marginTop: index !== 0 ? 10 : 0,
    justifyContent: 'space-between',
    gap: 12,
    border: `1px solid ${colorRoles.system.borderSubtle}`,
    '&:hover:focus': {
      border: `1px solid ${colorRoles.system.borderSubtle}`,
    },
  };
});

const OptionBlockV1 = <OptionValue extends unknown = string>({
  isActive,
  icon,
  displayTitle,
  description,
  value,
  onHandlePress,
  disabled = false,
  onDisablePress = () => null,
  dataQa,
  questionMarkWhenDisabled = false,
}: OptionBlockProps<OptionValue>) => {
  const { colors } = useEmotionTheme();

  return (
    <OptionWrapper
      row
      index={0}
      tabIndex={-1}
      style={{
        marginBottom: 10,
        opacity: disabled ? 0.5 : 1,
        backgroundColor: disabled ? colors.permaGreyed : undefined,
      }}
      onPress={() => (disabled ? onDisablePress(value) : onHandlePress(value))}
      dataQa={`${dataQa}-${value}`}
    >
      {icon && (
        <View align="center" justify="center" style={{ width: 26, height: 26, paddingTop: 2 }}>
          {icon}
        </View>
      )}
      <View flex={1} align="start" justify="start">
        <Big style={{}}>{displayTitle}</Big>
        {typeof description === 'string' ? (
          <Small style={{ color: colors.grey950 }}>{description}</Small>
        ) : (
          description
        )}
      </View>

      <View align="end" justify="center">
        <RadioButton
          isActive={isActive}
          onPress={() => !disabled && onHandlePress(value)}
          disabled={disabled}
          onDisablePress={() => onDisablePress(value)}
          dataQa={`${dataQa}-radio`}
          questionMarkWhenDisabled={questionMarkWhenDisabled}
        />
      </View>
    </OptionWrapper>
  );
};

export default OptionBlockV1;
