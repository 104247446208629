import { useCallback } from 'react';
import API from '../utils/planApiHelper';
import { PlanState, PlanAction } from '../types';
import useSimpleReducer, {
  receiveActionPayload,
  errorActionPayload,
  requestActionPayload,
} from '../../../hooks/useSimpleReducer';

const initialState: PlanState = {
  planInformation: undefined,
  isLoading: false,
  isError: false,
};

export default function usePlan(): [
  PlanState,
  {
    dispatchGetPlanInformation: (roomID: string) => void;
  }
] {
  const [state, dispatch] = useSimpleReducer<PlanState, PlanAction>(initialState);

  function dispatchGetPlanInformation(roomID) {
    dispatch({ type: 'requestGetPlanInformation', payload: requestActionPayload });
    API.getPlanInformation(roomID)
      .then((response) => {
        if (response.data && response.data.data) {
          return dispatch({
            type: 'receiveGetPlanInformation',
            payload: {
              planInformation: response.data.data,
              ...receiveActionPayload,
            },
          });
        }
        throw new Error('No plan information');
      })
      .catch(() =>
        dispatch({
          type: 'setIsError',
          payload: errorActionPayload,
        })
      );
  }

  return [
    state,
    {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      dispatchGetPlanInformation: useCallback(dispatchGetPlanInformation, []),
    },
  ];
}
