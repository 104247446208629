import { useQuery } from 'react-query';
import { Booking } from 'ts-frontend/types';
import apiWrapper from '../../utils/apiWrapper';
import apiHelper from '../../utils/api';
import dashboardKeys from './queryKeys';

export type BookingParams = { start?: string; end?: string };

const fetchBookings = (therapistID: number, params?: BookingParams) => async () => {
  const { start, end } = params || {};
  const queryParams = start && end ? `?startTime=${start}&endTime=${end}` : '';
  const { data } = await apiWrapper.get(
    `${apiHelper().apiEndpoint}/v3/therapists/${therapistID}/bookings${queryParams}`
  );

  return data.data;
};

const useQueryBookings = (therapistID: number, params?: BookingParams) =>
  useQuery<Booking[], Error>({
    queryKey: dashboardKeys.bookings(therapistID, params),
    queryFn: fetchBookings(therapistID, params),
    cacheTime: Infinity,
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

export default useQueryBookings;
