import { FunctionComponent } from 'react';
import Svg, { G, Path, Circle } from 'svgs';
import { EmotionStyle } from '../../core/styled';

interface AddPartnerProps {
  color?: string;
  width?: number;
  style?: EmotionStyle;
}

const ChatBubbleWithBadge: FunctionComponent<AddPartnerProps> = ({ width = 32, style }) => {
  const titleText = 'Chat bubble with a badge';
  return (
    <Svg width={width} title={titleText} aria-label={titleText} style={style} viewBox="0 0 28 22">
      <G
        transform="translate(-328 -2821) translate(20 2823) translate(308.5)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <Path
          d="M9.464 1c4.69 0 8.378 3.21 9.247 7.224a9.056 9.056 0 01-.093 4.326c-.452 1.668-.432 3.544.058 5.424.02.077.042.155.064.232l.066.232c.23.81.419 1.562-.441 1.562 0 0-3.112-.882-5.26-.882-1.753 0-2.153.31-3.64.31-4.853 0-8.773-3.575-9.374-7.917C-.71 5.746 3.888.999 9.464 1zM4.758 9.313a1.188 1.188 0 10-.002 2.376 1.188 1.188 0 00.002-2.376zm4.757 0a1.188 1.188 0 10-.002 2.376 1.188 1.188 0 00.002-2.376zm4.756 0a1.188 1.188 0 10-.001 2.376 1.188 1.188 0 00.001-2.376z"
          fill="#007C71"
          transform="matrix(-1 0 0 1 19 0)"
        />
        <Circle stroke="#FFF" strokeWidth={2} fill="#79D94C" cx={20.5} cy={4.5} r={5.5} />
      </G>
    </Svg>
  );
};

export default ChatBubbleWithBadge;
