import { VoidFunctionComponent } from 'react';
import Svg, { Rect, Path } from 'svgs';
import { EmotionStyle, useEmotionTheme } from '../../core/styled';

interface RectangleExclamationPointProps {
  width?: number;
  height?: number;
  color?: string;
  style?: EmotionStyle;
}

const RectangleExclamationPoint: VoidFunctionComponent<RectangleExclamationPointProps> = ({
  width = 11,
  height = 11,
  color,
  style = {},
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 15 15"
      fill="none"
      style={style}
      {...otherProps}
    >
      <Rect
        x={1}
        y={1}
        width={13}
        height={13}
        rx={3}
        fill={color || colors.permaGrape}
        stroke="#fff"
        strokeWidth={2}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 8.5h2V4h-2v4.5zm0 2.75h2V9.5h-2v1.75z"
        fill="#fff"
      />
    </Svg>
  );
};

export default RectangleExclamationPoint;
