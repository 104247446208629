import Svg, { Ellipse, Path } from 'svgs';
import { EmotionStyle, useEmotionTheme } from '../../core/styled';

interface Props {
  height?: number;
  width?: number;
  style?: EmotionStyle;
}
const SparklingVideoPlus = ({ height = 102, width = 120, style }: Props) => {
  const titleText = 'Sparkling Video';
  const { colors } = useEmotionTheme();
  return (
    <Svg
      title={titleText}
      width={width}
      height={height}
      viewBox="0 0 120 102"
      fill="none"
      style={style}
    >
      <Ellipse cx="61.1764" cy="51" rx="50.9804" ry="51" fill={colors.a11yLinkWaterGrey} />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M114.118 16.8692C113.252 16.8692 112.549 17.5718 112.549 18.4385C112.549 19.3051 113.252 20.0077 114.118 20.0077C114.984 20.0077 115.687 19.3051 115.687 18.4385C115.687 17.5718 114.984 16.8692 114.118 16.8692ZM108.628 18.4385C108.628 15.4052 111.086 12.9462 114.118 12.9462C117.15 12.9462 119.608 15.4052 119.608 18.4385C119.608 21.4718 117.15 23.9308 114.118 23.9308C111.086 23.9308 108.628 21.4718 108.628 18.4385Z"
        fill={colors.permaMalibu}
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24.5 80.6738C23.4743 80.6738 22.6429 81.5056 22.6429 82.5317C22.6429 83.5577 23.4743 84.3895 24.5 84.3895C25.5257 84.3895 26.3571 83.5577 26.3571 82.5317C26.3571 81.5056 25.5257 80.6738 24.5 80.6738ZM18 82.5317C18 78.9404 20.9101 76.0292 24.5 76.0292C28.0899 76.0292 31 78.9404 31 82.5317C31 86.1229 28.0899 89.0342 24.5 89.0342C20.9101 89.0342 18 86.1229 18 82.5317Z"
        fill={colors.permaZiggurat}
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.62745 29.0308C6.46163 29.0308 4.70588 30.7872 4.70588 32.9539C4.70588 35.1205 6.46163 36.877 8.62745 36.877C10.7933 36.877 12.549 35.1205 12.549 32.9539C12.549 30.7872 10.7933 29.0308 8.62745 29.0308ZM0 32.9539C0 28.1872 3.86264 24.3231 8.62745 24.3231C13.3923 24.3231 17.2549 28.1872 17.2549 32.9539C17.2549 37.7205 13.3923 41.5847 8.62745 41.5847C3.86264 41.5847 0 37.7205 0 32.9539Z"
        fill={colors.permaLavenderBlue}
      />
      <Path
        d="M40.296 29.5258L59.4671 28.856C63.7752 28.7055 67.3896 32.0758 67.5403 36.3839L68.2114 55.5724C68.3621 59.8809 64.9914 63.4959 60.6828 63.6464L41.5104 64.3162C37.2021 64.4667 33.5876 61.0961 33.4372 56.7877L32.7673 37.5992C32.617 33.2909 35.9876 29.6763 40.296 29.5258Z"
        fill="white"
        stroke={colors.permaPeriwinkleGray}
        stroke-width="5.2038"
      />
      <Path
        d="M50.4439 35.9806C50.5619 34.4176 51.9241 33.2462 53.4866 33.3643L53.5628 33.37C55.1252 33.4881 56.2962 34.8508 56.1782 36.4138L55.9562 39.3551L72.6041 40.6128L72.8261 37.6715C72.9441 36.1085 74.3063 34.9371 75.8687 35.0551L75.945 35.0609C77.5074 35.1789 78.6784 36.5417 78.5604 38.1047L78.3342 41.1016C78.3291 41.1686 78.3218 41.2349 78.3122 41.3005C81.551 42.4226 83.7603 45.6175 83.49 49.1976L81.8656 70.7163C81.5439 74.9791 77.8287 78.1738 73.5676 77.8519L49.4134 76.0271C45.1523 75.7052 41.9588 71.9886 42.2806 67.7259L43.905 46.2072C44.1752 42.6271 46.839 39.8003 50.2096 39.1775C50.2099 39.1112 50.2126 39.0446 50.2177 38.9776L50.4439 35.9806Z"
        fill={colors.a11yRoyalBlue}
      />
      <Path
        d="M55.0154 50.7296C53.0666 50.5824 51.3676 52.0434 51.2204 53.9929L50.6744 61.2265C50.5272 63.176 51.9877 64.8757 53.9364 65.023L62.9781 65.706C64.9269 65.8532 66.626 64.3922 66.7731 62.4427L67.3192 55.2091C67.4663 53.2596 66.0059 51.5599 64.0571 51.4126L55.0154 50.7296Z"
        fill="white"
      />
      <Path
        d="M69.1672 56.0909C68.6743 56.2954 68.3383 56.7598 68.2981 57.2921L68.0933 60.0052C68.0531 60.5375 68.3156 61.0472 68.7722 61.3234L72.8482 63.7897C73.7512 64.3361 74.9127 63.7375 74.9922 62.6848L75.521 55.6794C75.6004 54.6267 74.5419 53.8603 73.5672 54.2649L69.1672 56.0909Z"
        fill="white"
      />
      <Path
        d="M94 76.0684C94 81.5934 89.5228 86.0723 84 86.0723C78.4772 86.0723 74 81.5934 74 76.0684C74 70.5434 78.4772 66.0646 84 66.0646C89.5228 66.0646 94 70.5434 94 76.0684Z"
        fill="white"
      />
      <Path
        d="M84 66.0646C82.0222 66.0646 80.0888 66.6513 78.4443 67.7505C76.7998 68.8498 75.5181 70.4122 74.7612 72.2401C74.0043 74.0681 73.8063 76.0795 74.1922 78.0201C74.578 79.9606 75.5304 81.7431 76.9289 83.1422C78.3275 84.5413 80.1093 85.494 82.0491 85.88C83.9889 86.266 85.9996 86.0679 87.8268 85.3108C89.6541 84.5536 91.2159 83.2714 92.3147 81.6263C93.4135 79.9811 94 78.047 94 76.0684C94 74.7547 93.7413 73.4538 93.2388 72.2401C92.7362 71.0264 91.9997 69.9236 91.0711 68.9946C90.1425 68.0657 89.0401 67.3288 87.8268 66.8261C86.6136 66.3233 85.3132 66.0646 84 66.0646ZM87 77.0688H85V79.0696C85 79.3349 84.8946 79.5893 84.7071 79.777C84.5196 79.9646 84.2652 80.07 84 80.07C83.7348 80.07 83.4804 79.9646 83.2929 79.777C83.1054 79.5893 83 79.3349 83 79.0696V77.0688H81C80.7348 77.0688 80.4804 76.9634 80.2929 76.7758C80.1054 76.5882 80 76.3337 80 76.0684C80 75.8031 80.1054 75.5486 80.2929 75.361C80.4804 75.1734 80.7348 75.068 81 75.068H83V73.0673C83 72.8019 83.1054 72.5475 83.2929 72.3599C83.4804 72.1723 83.7348 72.0669 84 72.0669C84.2652 72.0669 84.5196 72.1723 84.7071 72.3599C84.8946 72.5475 85 72.8019 85 73.0673V75.068H87C87.2652 75.068 87.5196 75.1734 87.7071 75.361C87.8946 75.5486 88 75.8031 88 76.0684C88 76.3337 87.8946 76.5882 87.7071 76.7758C87.5196 76.9634 87.2652 77.0688 87 77.0688Z"
        fill={colors.permaHighlightGreen}
      />
    </Svg>
  );
};

export default SparklingVideoPlus;
