import { createContext, useCallback, useContext, useState } from 'react';

import storage from '@/core/storage';

export const PREFILLED_MESSAGE_KEY = 'prefilled-message';

interface ChatMessageState {
  prefilledMessage: string;
}
interface ChatMessageActions {
  setPrefilledChatMessage: (message: string) => void;
  clearPrefilledChatMessage: () => void;
}
const initialState: ChatMessageState = {
  prefilledMessage: storage.getItem(PREFILLED_MESSAGE_KEY) || '',
};

const ChatMessageStateContext = createContext<ChatMessageState>(initialState);
const ChatMessageActionsContext = createContext<ChatMessageActions | undefined>(undefined);

const ChatMessageContextProvider: React.FC = ({ children }) => {
  const [state, setState] = useState(initialState);

  const setPrefilledChatMessage = useCallback(
    (message: string): void => {
      setState({ ...state, prefilledMessage: message });
      storage.setItem(PREFILLED_MESSAGE_KEY, message);
    },
    [state]
  );

  const clearPrefilledChatMessage = useCallback((): void => {
    setState({ ...state, prefilledMessage: '' });
    storage.removeItem(PREFILLED_MESSAGE_KEY);
  }, [state]);

  const actions: ChatMessageActions = {
    setPrefilledChatMessage,
    clearPrefilledChatMessage,
  };
  return (
    <ChatMessageStateContext.Provider value={state}>
      <ChatMessageActionsContext.Provider value={actions}>
        {children}
      </ChatMessageActionsContext.Provider>
    </ChatMessageStateContext.Provider>
  );
};

export function useChatMessageState() {
  const context = useContext(ChatMessageStateContext);
  if (context === undefined)
    throw new Error(`ChatMessageStateContext must be used within its provider`);
  return context;
}

export function useChatMessageActions() {
  const context = useContext(ChatMessageActionsContext);
  if (context === undefined)
    throw new Error(`ChatMessageActionsContext must be used within its provider`);
  return context;
}

export default ChatMessageContextProvider;
