import { FunctionComponent } from 'react';
import { Tiny } from '@talkspace/react-toolkit';
import moment from 'moment';

import { User } from './types';

interface Props {
  user: User;
  submittedAt: Date;
}

const StatementCertification: FunctionComponent<Props> = ({ user, submittedAt }) => {
  const momentObj = moment(submittedAt);
  return (
    <Tiny style={{ marginLeft: 25 }}>
      Digitally signed by {user.firstName} {user.lastName} on {momentObj.format('MMMM DD, YYYY')} at{' '}
      {momentObj.format('LT')}
    </Tiny>
  );
};

export default StatementCertification;
