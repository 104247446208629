import LanguageSelector from './LanguageSelector';

import { withVersioning } from '../../utils';
import { VersionMap } from '../../types';

const versionMap: VersionMap<React.ComponentProps<typeof LanguageSelector>> = {
  DefaultComponent: LanguageSelector,
};

export default withVersioning(versionMap);
