import { FunctionComponent, useMemo, useCallback } from 'react';
import {
  OptionType,
  RepeatingArrowsLightIcon,
  SelectRounded,
  styled,
  Standard,
  View,
  useEmotionTheme,
  A11Y_COLORS,
} from '@talkspace/react-toolkit';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { RepeatingPeriodValue } from 'ts-frontend/types';

const Container = styled(View)({
  display: 'flex',
  width: '100%',
  maxWidth: 335,
  marginBottom: 21,
  flexDirection: 'column',
  rowGap: '14px',
});

const IconContainer = styled(View)({
  display: 'flex',
  alignItems: 'center',
  flex: '0 0 auto',
});

const IconPlaceholder = styled(View)({
  width: 14,
  height: 16,
});

const REPEATING_PERIOD_OPTIONS: OptionType<RepeatingPeriodValue>[] = [
  { value: 'no-repeat', label: 'Does not recur' },
  { value: 'every-week', label: 'Weekly' },
  { value: 'every-other-week', label: 'Every other week' },
];

type RepeatingSessionsValue = '1' | '2' | '3' | '4' | '5' | '6';

const REPEATING_MONTHS_OPTIONS: OptionType<RepeatingSessionsValue>[] = [
  { value: '1', label: '1 month' },
  { value: '2', label: '2 months' },
  { value: '3', label: '3 months' },
  { value: '4', label: '4 months' },
  { value: '5', label: '5 months' },
  { value: '6', label: '6 months' },
];

const REPEATING_SESSIONS_OPTIONS: OptionType<RepeatingSessionsValue>[] = [
  { value: '2', label: '2 sessions' },
  { value: '3', label: '3 sessions' },
  { value: '4', label: '4 sessions' },
];

function getSelectRoundedStyles(colors: typeof A11Y_COLORS) {
  return {
    control: (baseStyles, { isFocused }) => {
      return {
        ...baseStyles,
        alignContent: 'center',
        borderColor: `${isFocused ? colors.darkBlue : colors.periwinkleGrey} !important`,
        borderRadius: 8,
        fontSize: 14,
        height: 30,
        minHeight: 30,
        paddingLeft: 8,
        '&:hover': {
          background: colors.permaGreyed,
        },
      };
    },
    singleValue: (baseStyles) => {
      return {
        ...baseStyles,
        fontSize: '14px',
      };
    },
    option: (baseStyles) => {
      return {
        ...baseStyles,
        fontSize: 14,
        color: colors.black,
        fontWeight: 400,
        height: 40,
        minHeight: 40,
        margin: 0,
        '&:hover': {
          backgroundColor: colors.aqua,
          color: colors.white,
        },
      };
    },
    menu: (baseStyles) => {
      return { ...baseStyles, padding: 0 };
    },
    dropdownIndicator: (baseStyles) => {
      return {
        ...baseStyles,
        margin: 0,
        padding: 0,
        color: colors.permaFiord,
        '&:hover': {
          color: colors.permaFiord,
        },
      };
    },
  };
}

interface RecurringBookingSelectProps<T extends string> {
  label: string;
  options: OptionType<T>[];
  icon?: JSX.Element;
  value: string;
  onChange: (newValue: T) => void;
  dataQa?: string;
}

const RecurringBookingSelect = <M extends string>({
  label,
  options,
  icon,
  value,
  onChange,
  dataQa,
}: RecurringBookingSelectProps<M>): JSX.Element => {
  const { colors } = useEmotionTheme();

  const selectRoundedStyles = useMemo(() => getSelectRoundedStyles(colors), [colors]);

  const onChangeCallback = useCallback(
    (arg: OptionType<M>) => {
      if (arg && arg.value) {
        onChange(arg.value);
      }
    },
    [onChange]
  );

  const selectedOption = useMemo(
    () => options.find((option) => option.value === value),
    [options, value]
  );

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        columnGap: '16px',
      }}
    >
      <IconContainer>{icon || <IconPlaceholder />}</IconContainer>
      <Standard variant="standardBoldTSBlack" style={{ flex: '0 0 auto' }}>
        {label}
      </Standard>
      <SelectRounded
        menuPlacement="top"
        wrapperStyle={{ flex: '1 1 auto', marginTop: 0 }}
        styles={selectRoundedStyles}
        options={options}
        value={selectedOption}
        onChange={onChangeCallback}
        dataQa={dataQa}
      />
    </View>
  );
};

type RecurringBookingInputProps = {
  repeatingPeriod: RepeatingPeriodValue;
  setRepeatingPeriod: (x: RepeatingPeriodValue) => void;
  repeatingMonths: number;
  setRepeatingMonths: (x: number) => void;
  repeatingSessions: number;
  setRepeatingSessions: (x: number) => void;
};

const RecurringBookingInput: FunctionComponent<RecurringBookingInputProps> = ({
  repeatingPeriod,
  setRepeatingPeriod,
  repeatingMonths,
  setRepeatingMonths,
  repeatingSessions,
  setRepeatingSessions,
}) => {
  const { repeatingSessionsFull2 } = useFlags();
  return (
    <Container>
      <RecurringBookingSelect<RepeatingPeriodValue>
        label="Recurs:"
        icon={<RepeatingArrowsLightIcon />}
        options={REPEATING_PERIOD_OPTIONS}
        value={repeatingPeriod}
        onChange={(newCadence: RepeatingPeriodValue) => {
          setRepeatingPeriod(newCadence);
        }}
        dataQa="recursDropdown"
      />
      {repeatingPeriod !== 'no-repeat' && (
        <RecurringBookingSelect<RepeatingSessionsValue>
          label="For the next:"
          options={repeatingSessionsFull2 ? REPEATING_MONTHS_OPTIONS : REPEATING_SESSIONS_OPTIONS}
          value={
            repeatingSessionsFull2
              ? repeatingMonths?.toString() || '4'
              : repeatingSessions?.toString() || '4'
          }
          onChange={(newValue: string) => {
            if (repeatingSessionsFull2) {
              setRepeatingMonths(+newValue);
            } else {
              setRepeatingSessions(+newValue);
            }
          }}
          dataQa="forTheNextDropdown"
        />
      )}
    </Container>
  );
};

export default RecurringBookingInput;
