import { useRef, useEffect } from 'react';
import { useWindowWidthState } from './windowWidthContext';

export default function useScrollIntoInputs() {
  const { height } = useWindowWidthState();
  const prevHeightRef = useRef<number>(0);

  useEffect(() => {
    if (prevHeightRef.current !== height) {
      if (
        prevHeightRef.current > height &&
        document.activeElement &&
        ['textarea', 'select', 'input'].includes(document.activeElement.tagName.toLowerCase())
      ) {
        // detected height decreased raised on input, possibly keyboard opened
        setTimeout(() => {
          if (document.activeElement) document.activeElement.scrollIntoView({ block: 'end' });
        }, 0);
      }

      prevHeightRef.current = height;
    }
  }, [height]);
}
