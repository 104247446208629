import { FunctionComponent, useState } from 'react';
import {
  BusinessDays,
  ClientMatchPresentingProblem,
  ImplicitBusinessHoursByDay,
  License,
  TimeOff,
} from 'ts-frontend/types';
import moment from 'moment';
import { Availability } from 'ts-frontend/entities/Therapist';
import View from '../View';
import BaseButton from '../BaseButton';
import styled, { EmotionStyle } from '../../core/styled';
import { Big, ExtraHuge } from '../Typography';
import { useWindowWidthState } from '../../hooks/windowWidthContext';
import TherapistProfile from './TherapistProfile';
import Button from '../Button';
import Avatar from '../Avatar';
import TherapistAvailability from './TherapistAvailability';
import LicenseAndAvailability from './LicenseAndAvailability';
import TherapistVideoModal from './TherapistVideoModal';
import TouchableView from '../TouchableView';
import Image from '../Image';
import PlayButtonIcon from './PlayButtonIcon.png';

export interface TherapistCardProps {
  availability: Availability;
  businessDays: BusinessDays;
  buttonText: string;
  firstName: string;
  imageURL: string;
  implicitBusinessHoursByDay: ImplicitBusinessHoursByDay | undefined;
  joinedYearsAgo: number;
  lastName: string;
  licenses: License[];
  focus: string[];
  publicDetails: string;
  showAvailability: boolean;
  therapistTimezone: string;
  videoURL: string;
  yearsInPractice: number;
  onMainPress: () => void;
  timeOff?: TimeOff;
  hideMainButton?: boolean;
  presentingProblems?: ClientMatchPresentingProblem[];
}

const TherapistCardContainer = styled(View)<{ isMobile: boolean }>(
  ({ theme: { colors }, isMobile }) => {
    return {
      flexDirection: isMobile ? 'column' : 'row',
      marginTop: 20,
      marginBottom: 20,
      paddingTop: isMobile ? 22 : 0,
      paddingBottom: isMobile ? 22 : 0,
      justifyContent: 'center',
      alignItems: 'center',
      borderWidth: isMobile ? 1 : 0,
      borderColor: colors.permaMysticTwo,
      borderStyle: 'solid',
      borderRadius: 9,
    };
  }
);

const Wrapper = styled(View)<{
  isLarge: boolean;
  isMedium: boolean;
  isMobile: boolean;
  isSmall: boolean;
}>(({ theme: { colors }, isLarge, isMedium, isMobile, isSmall }) => {
  // the Card must adjust only at the breakpoints, not flow
  // these numbers were visually obtained to keep the content in view
  let width = 640;
  if (isLarge) width = 500;
  if (isMedium) width = 365;
  if (isSmall) width = 300;
  return {
    borderWidth: isMobile ? 0 : 1,
    borderColor: colors.extraLightGrey,
    borderStyle: 'solid',
    borderRadius: 8,
    padding: isMobile ? 5 : 25,
    paddingBottom: isMobile ? 5 : 30,
    marginLeft: isMobile ? 19 : 35,
    marginRight: 14,
    width,
    maxWidth: isMobile ? 327 : 'auto',
  };
});

const TabsWrapper = styled(View)<{
  isMobile: boolean;
}>(({ isMobile }) => {
  return {
    marginBottom: isMobile ? 22 : 15,
    marginTop: isMobile ? 13 : 0,
    marginRight: 11,
  };
});

const TabButton = styled(BaseButton)<{ isMobile: boolean; isSecondary?: boolean }>(
  ({ isMobile, isSecondary, theme: { colors } }) => {
    const responsiveStyles = {
      borderWidth: 1,
      borderColor: colors.extraLightGrey,
      borderStyle: 'solid',
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1,
      padding: 12,
      marginLeft: isSecondary ? 5 : 0,
    };
    return {
      borderRadius: 44,
      marginLeft: isSecondary ? 25 : 0,
      ...(isMobile && responsiveStyles),
    };
  }
);

const TabButtonText = styled(Big)<{ isActive: boolean }>(({ theme: { colors }, isActive }) => {
  return {
    color: isActive ? colors.black : colors.ironGrey,
  };
});

const AvatarAndMore = styled(View)<{ isMobile: boolean }>(({ isMobile }) => {
  return {
    position: 'relative',
    alignItems: 'center',
    alignSelf: isMobile ? 'center' : 'flex-start',
    paddingTop: isMobile ? 0 : 24,
  };
});

const PlayButton: FunctionComponent<{
  openVideoModal: () => void;
}> = ({ openVideoModal }) => (
  <View
    style={{
      position: 'absolute',
      bottom: 56,
      right: -5,
    }}
  >
    <TouchableView aria-label="play provider intro video" onPress={openVideoModal}>
      <Image
        source={PlayButtonIcon as string}
        alt="play"
        width={68}
        height={68}
        dataQa="therapistCardPlayButton"
      />
    </TouchableView>
  </View>
);

const MainButton: FunctionComponent<{
  text: string;
  onMainPress: () => void;
  hideMainButton?: boolean;
  style?: EmotionStyle;
}> = ({ text, onMainPress, hideMainButton = false, style = {} }) => {
  if (hideMainButton) {
    return null;
  }
  return (
    <Button text={text} onPress={onMainPress} dataQa="therapistCardMainButtonPress" style={style} />
  );
};

const TherapistCard: FunctionComponent<TherapistCardProps> = ({
  availability,
  businessDays,
  buttonText,
  firstName,
  imageURL,
  implicitBusinessHoursByDay,
  joinedYearsAgo,
  lastName,
  licenses,
  publicDetails,
  showAvailability,
  timeOff,
  therapistTimezone,
  videoURL,
  yearsInPractice,
  onMainPress,
  hideMainButton = false,
}) => {
  const [isViewingAvailability, setIsViewingAvailability] = useState<boolean>(false);
  const { isLarge, isMedium, isMobile, isSmall } = useWindowWidthState();
  const [isVideoModalVisible, setIsVideoModalVisible] = useState(false);
  // TODO: should use moment.tz with therapist timezone here
  const todayAsDayString = moment().format('dddd');
  const availableNow = availability === 'available' && businessDays[todayAsDayString];

  return (
    <TherapistCardContainer isMobile={isMobile}>
      <AvatarAndMore isMobile={isMobile}>
        <Avatar image={imageURL} height={195} width={195} />
        {videoURL && <PlayButton openVideoModal={() => setIsVideoModalVisible(true)} />}
        {isMobile ? (
          <>
            <ExtraHuge>
              {firstName} {lastName}
            </ExtraHuge>
            <LicenseAndAvailability licenses={licenses} availableNow={availableNow} />
          </>
        ) : (
          <MainButton
            hideMainButton={hideMainButton}
            text={buttonText}
            onMainPress={onMainPress}
            style={{ width: 195 }}
          />
        )}
      </AvatarAndMore>
      <Wrapper isLarge={isLarge} isMedium={isMedium} isMobile={isMobile} isSmall={isSmall}>
        <TabsWrapper isMobile={isMobile} row>
          <TabButton isMobile={isMobile} onPress={() => setIsViewingAvailability(false)}>
            <TabButtonText variant="bigMedium" isActive={!isViewingAvailability}>
              Profile
            </TabButtonText>
          </TabButton>
          {/* Dynamically show availability tab, for non-UK users */}
          {showAvailability && (
            <TabButton
              isMobile={isMobile}
              onPress={() => setIsViewingAvailability(true)}
              isSecondary
            >
              <TabButtonText variant="bigMedium" isActive={isViewingAvailability}>
                Availability
              </TabButtonText>
            </TabButton>
          )}
        </TabsWrapper>
        {isViewingAvailability ? (
          <TherapistAvailability
            timeOff={timeOff}
            implicitBusinessHoursByDay={implicitBusinessHoursByDay}
            businessDays={businessDays}
            therapistTimezone={therapistTimezone}
          />
        ) : (
          <TherapistProfile
            firstName={firstName}
            joinedYearsAgo={joinedYearsAgo}
            lastName={lastName}
            licenses={licenses}
            publicDetails={publicDetails}
            yearsInPractice={yearsInPractice}
            availableNow={availableNow}
          />
        )}
      </Wrapper>
      {isMobile && (
        <MainButton
          hideMainButton={hideMainButton}
          text={buttonText}
          onMainPress={onMainPress}
          style={{ marginBottom: 18 }}
        />
      )}
      <TherapistVideoModal
        therapistVideoUrl={videoURL}
        isVisible={isVideoModalVisible}
        onBackdropPress={() => setIsVideoModalVisible(false)}
      />
    </TherapistCardContainer>
  );
};

export default TherapistCard;
