import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import Spinner from '../Spinner/Spinner';
import UserButtons from './UserButtons';

const UserFooter = ({
  buttons,
  isUpdating,
  isError,
  frame,
  status,
  nextStatus,
  showSpinner,
  spinnerMessage,
}) => {
  const displayButtons = showSpinner ? (
    <Spinner
      spinnerMessage={spinnerMessage}
      isError={isError}
      isUpdating={isUpdating}
      frame={frame}
      status={status}
      nextStatus={nextStatus}
    />
  ) : (
    buttons.map((button, index) => (
      <UserButtons
        key={index}
        isSecondary={button.isSecondary}
        title={button.title}
        clickHandler={button.clickHandler}
      />
    ))
  );
  return <div className="userFooter">{displayButtons}</div>;
};

export default UserFooter;
