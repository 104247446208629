import Svg, { Path } from 'svgs';
import { useEmotionTheme } from '../../core/styled';

const CloseIcon = ({
  width = 13,
  height = 13,
  color,
  ...props
}: {
  width?: number;
  height?: number;
  color?: string;
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'close';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <Path
        d="M8 6.472L14.17.313a1.071 1.071 0 011.517.003c.418.42.417 1.101-.003 1.52L9.51 8l6.174 6.164c.42.419.421 1.1.003 1.52a1.071 1.071 0 01-1.517.003L8 9.527l-6.17 6.16a1.071 1.071 0 01-1.517-.003 1.076 1.076 0 01.003-1.52L6.49 8 .316 1.836A1.076 1.076 0 01.313.316 1.071 1.071 0 011.83.313L8 6.473z"
        fill={color || colors.lightGray}
      />
    </Svg>
  );
};

export default CloseIcon;
