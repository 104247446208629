import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Select, TextInput, WindowWidthStateContext } from '@talkspace/react-toolkit';
import { sortOptionsAlphabetically } from 'ts-frontend/helpers/optionsHelpers';
import {
  getMedicalInformation,
  setMedicalInformation,
} from '../../../../../actions/MedicalInformationActions';
import AccordionContainer from '../../../../Reusable/AccordionContainer/AccordionContainer';
import { checkIfShouldBeSearchable, yesNoOptions } from '../../../../../utils/optionsHelpers';
import SocketService from '../../../../../utils/socket/SocketService';
import { getOptionsByField } from '../../../../../utils/tsConfigsValues';

class MedicalInformation extends Component {
  UNSAFE_componentWillMount() {
    if (this.props.clientUserID && this.props.roomID) {
      this.props.getMedicalInformation(this.props.clientUserID);

      SocketService.instance().on('submittedIntakeSurvey', this.handleSubmittedIntakeSurveyEvent);
    }
  }

  componentWillUnmount() {
    SocketService.instance().off('submittedIntakeSurvey', this.handleSubmittedIntakeSurveyEvent);
  }

  createHandleSelectChange =
    (field, isMulti = false) =>
    (value) => {
      const { clientUserID } = this.props;
      this.props.setMedicalInformation(clientUserID, { [field]: isMulti ? value || [] : value });
    };

  createHandleTextInputOnBlur = (field) => (e) => {
    const { clientUserID } = this.props;
    this.props.setMedicalInformation(clientUserID, { [field]: e.target.value });
  };

  handleSubmittedIntakeSurveyEvent = (data) => {
    const { roomID, clientUserID } = this.props;

    if (data.type !== 'treatment') {
      return;
    }

    if (Number(data.roomID) !== Number(roomID)) {
      return;
    }

    this.props.getMedicalInformation(clientUserID);
  };

  render() {
    const {
      isPregnant,
      medicalIssues,
      hasChronicPain,
      medications,
      otherCurrentPrescriptionMedications,
      pharmacyAddress,
      otcMedications,
      drugAllergies,
      controlledSubstances,
    } = this.props.medicalInformation;
    const showIndicatorBeforeClick = this.context.isMobile;
    return (
      <AccordionContainer
        title="Medical Information"
        open={false}
        dataQa="medicaInformationAccordion"
      >
        <Select
          placeholder="Currently treating for the following medical issues"
          options={getOptionsByField('medicalIssues')}
          value={medicalIssues}
          onChange={this.createHandleSelectChange('medicalIssues', true)}
          isSearchable={checkIfShouldBeSearchable('medicalIssues')}
          isMulti
          placeholderIsTwoLines
        />
        <Select
          placeholder="Currently experiencing chronic pain"
          options={yesNoOptions}
          value={hasChronicPain}
          onChange={this.createHandleSelectChange('hasChronicPain')}
          isSearchable={false}
          showIndicatorBeforeClick={showIndicatorBeforeClick}
        />
        <Select
          placeholder="Currently pregnant"
          options={yesNoOptions}
          value={isPregnant}
          onChange={this.createHandleSelectChange('isPregnant')}
          isSearchable={false}
          showIndicatorBeforeClick={showIndicatorBeforeClick}
        />
        <Select
          placeholder="Current psychiatric medications"
          options={getOptionsByField('medications').sort(sortOptionsAlphabetically)}
          value={medications}
          onChange={this.createHandleSelectChange('medications', true)}
          isSearchable={checkIfShouldBeSearchable('medications')}
          isMulti
        />
        <TextInput
          placeholder="Other current prescription medications"
          value={otherCurrentPrescriptionMedications}
          onBlur={this.createHandleTextInputOnBlur('otherCurrentPrescriptionMedications')}
          multiline
        />
        <TextInput
          placeholder="Current over-the-counter medications"
          value={otcMedications}
          onBlur={this.createHandleTextInputOnBlur('otcMedications')}
          multiline
        />
        <TextInput
          placeholder="Preferred pharmacy address"
          value={pharmacyAddress}
          onBlur={this.createHandleTextInputOnBlur('pharmacyAddress')}
          multiline
        />
        <TextInput
          placeholder="Drug allergies and reactions"
          value={drugAllergies}
          onBlur={this.createHandleTextInputOnBlur('drugAllergies')}
          multiline
        />
        <Select
          placeholder="Current or previously used controlled substances"
          options={getOptionsByField('controlledSubstances')}
          value={controlledSubstances}
          onChange={this.createHandleSelectChange('controlledSubstances', true)}
          isSearchable={checkIfShouldBeSearchable('controlledsubstances')}
          isMulti
          placeholderIsTwoLines
        />
      </AccordionContainer>
    );
  }
}

MedicalInformation.contextType = WindowWidthStateContext;
const mapStateToProps = ({ medicalInformation, customerInformation, room }) => {
  return {
    clientUserID: customerInformation.clientUserID,
    roomID: room.roomID,
    medicalInformation,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getMedicalInformation,
      setMedicalInformation,
    },
    dispatch
  );
const MedicalInformationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MedicalInformation);

export default MedicalInformationContainer;
