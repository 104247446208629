import { Component } from 'react';
import 'react-toastify/dist/ReactToastify.min.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Collapsible } from '@talkspace/react-toolkit';
import HyperText from '../../../../Reusable/HyperText/HyperText';
import Reactions from '../../../../Reusable/Reactions/Reactions';
import {
  notExpandbleReminders,
  remindersWithoutFeedback,
} from '../../../../../utils/RemindersConfig';

class TherapistJourneyItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      dismissed: false,
      dismissing: false,
      height: undefined,
    };
    this.handleCollapse = this.handleCollapse.bind(this);
  }

  handleCollapse(e) {
    e.preventDefault();
    this.setState({ open: !this.state.open });
    const cardAction = this.state.open ? 'collapse_card' : 'expand_card';
  }

  render() {
    const {
      props: { item },
      state: { open, dismissed, height, dismissing },
    } = this;
    const tipClass = `tip-icon ${item.category}`;
    const tipExpandClass = open ? 'tip-expand-down' : 'tip-expand-up';
    const dismissedClass = dismissed ? 'dismissed' : '';
    const dismissingClass = !dismissed && dismissing ? 'dismissing' : '';
    const heightStyle = height === undefined ? {} : { height };
    const expandIcon =
      !notExpandbleReminders.includes(item.reminderTypeID) && item.body.length > 145 ? (
        <div className={tipExpandClass} onClick={this.handleCollapse} />
      ) : null;
    const bodyText =
      item.body.length > 145 &&
      !notExpandbleReminders.includes(item.reminderTypeID) &&
      !this.state.open
        ? `${item.body.substring(0, 110)}...`
        : item.body;

    return (
      <div
        className={`tip-container card ${dismissedClass} ${dismissingClass}`}
        style={heightStyle}
      >
        <div className="tip-container hidden-elements">
          <div className="tip-heading">
            <div className={tipClass} />
            <div className="tip-title">{item.title}</div>
            {expandIcon}
          </div>

          <Collapsible
            collapsedHeight={54}
            className="tip-description"
            isCollapsed={!this.state.open && expandIcon}
          >
            <HyperText>{bodyText}</HyperText>
          </Collapsible>

          {this.props.item.suggestion && this.state.open && (
            <span>
              <br />
              <HyperText>{this.props.item.suggestion(this)}</HyperText>
            </span>
          )}

          <div className="action-container">
            {!remindersWithoutFeedback.includes(item.reminderTypeID) && (
              <Reactions
                defaultValue="Feedback"
                options={{ like: { value: 'helpful' }, dislike: { value: 'not helpful' } }}
                selected={this.props.item.feedback}
                actions={this.props.item.feedbackActions}
              />
            )}
            {this.props.item.actions.map((action, index) => (
              <div
                key={index}
                onClick={(event) => {
                  event.persist();
                  event.preventDefault();
                  action.handler(event, this.props.item, this);
                }}
                style={action.style}
              >
                {action.text}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    roomID: state.room.roomID,
  };
};
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const JourneyItem = connect(mapStateToProps, mapDispatchToProps)(TherapistJourneyItem);
export default withRouter(JourneyItem);
