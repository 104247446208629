import { useEffect, useRef } from 'react';

// for more information on this tricky hook: https://blog.logrocket.com/how-to-get-previous-props-state-with-react-hooks/
// it's possible that undesired effects and result from this, use sparingly

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export default usePrevious;
