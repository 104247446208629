import { ConditionalResponse } from 'ts-frontend/types';
import type { PromiseMessageTypes } from '@/utils/promiseMessage/promiseMessageEvents';
import { PROMISE_MESSAGE_TYPE } from './promiseMessageTypes';
import type {
  PromiseMessageCallback,
  PostMessageFuncType,
  PostPromiseMessage,
  PromiseMessageEvent,
  PromiseMessageHandler,
} from './promiseMessageTypes';
import { PromiseMessageTypeNames } from './promiseMessageEventTypes';

type Listener = (data: PromiseMessageEvent) => void;
type ListenersMap = Map<string, Listener>;

const listeners: ListenersMap = new Map();

const PROMISE_TIMEOUT_SECONDS = 45;

export const receiveMessagePromiseHandler = async <T extends typeof PROMISE_MESSAGE_TYPE>(
  postMessageFunc: PostMessageFuncType<T>,
  event: PromiseMessageEvent,
  handlers: PromiseMessageHandler
): Promise<boolean> => {
  if (event.data.type === PROMISE_MESSAGE_TYPE) {
    const listener = listeners.get(event.data.data.ID);
    if (listener) {
      listener(event);
      return true;
    }

    // This would represent an event initiated by us with `createPromise === false`
    if (event.data.data.initiatorOrigin === window.location.origin) return false;

    const callback: PromiseMessageCallback<typeof event.data.data.innerType> = (responseData) => {
      const body: PromiseMessageEvent<T, typeof event.data.data.innerType>['data']['data'] = {
        ...event.data.data,
        innerData: responseData,
      };
      postMessageFunc(PROMISE_MESSAGE_TYPE as T, body);
    };

    return handlers(event, callback);
  }
  return false;
};

export const postPromiseMessage = <
  T extends typeof PROMISE_MESSAGE_TYPE,
  K extends PromiseMessageTypeNames,
  B extends boolean
>(
  postMessageFunc: PostMessageFuncType<T>,
  name: K,
  data: PromiseMessageTypes[K]['postData'],
  createPromise = true
): Promise<ConditionalResponse<B, PromiseMessageTypes[K]['responseData']>> => {
  const ID = (Math.random() * 36).toString(36);
  const body: PromiseMessageEvent<T, K>['data']['data'] = {
    ID,
    innerType: name,
    innerData: data,
    initiatorOrigin: window.location.origin,
  };
  postMessageFunc(PROMISE_MESSAGE_TYPE as T, body);
  if (!createPromise)
    return Promise.resolve(undefined) as Promise<
      ConditionalResponse<B, PromiseMessageTypes[K]['responseData']>
    >;
  const promise: Promise<ConditionalResponse<B, PromiseMessageTypes[K]['responseData']>> =
    new Promise((resolve, reject) => {
      const timeout = setTimeout(() => {
        reject(new Error(`Promise Message timed out${timeout}`));
      }, PROMISE_TIMEOUT_SECONDS * 1000);

      const listener: Listener = (event: PromiseMessageEvent) => {
        clearTimeout(timeout);
        resolve(
          event.data.data.innerData as ConditionalResponse<
            B,
            PromiseMessageTypes[K]['responseData']
          >
        );
        listeners.delete(ID);
      };
      listeners.set(ID, listener);
    });
  return promise;
};

export const postPromiseMessageFactory =
  <T extends typeof PROMISE_MESSAGE_TYPE>(
    postMessageFunc: PostMessageFuncType<T>
  ): PostPromiseMessage =>
  (name, data, createPromise) =>
    postPromiseMessage(postMessageFunc, name, data, createPromise);

export const getPromiseMessageTypedCallbackAndData = <K extends PromiseMessageTypeNames>(
  event: PromiseMessageEvent,
  callback: PromiseMessageCallback
): [
  PromiseMessageEvent<typeof PROMISE_MESSAGE_TYPE, K>['data']['data'],
  PromiseMessageCallback<K>
] => [
  event.data.data as PromiseMessageEvent<typeof PROMISE_MESSAGE_TYPE, K>['data']['data'],
  callback,
];
