import Svg, { G, Path, Rect } from 'svgs';
import { useEmotionTheme } from '../../core/styled';

const PlainCreditCard = ({
  width = 33,
  height = 21,
  color,
  ...props
}: {
  width?: number;
  height?: number;
  color?: string;
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'credit card';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox={`0 0 ${width} ${height}`}
      {...props}
    >
      <G fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <Path
          fill={color}
          stroke={colors.periwinkleGrey}
          strokeWidth="0.5"
          d="M-25.25-46.997c0-5.661 4.595-10.253 10.258-10.253h480.984c5.664 0 10.258 4.593 10.258 10.253v92.994c0 5.661-4.595 10.253-10.258 10.253H-14.992c-5.664 0-10.258-4.593-10.258-10.253v-92.994z"
        />
        <Rect width="33" height="22" x="0" y="0" fill={colors.periwinkleGrey} rx="2.92" />
        <Path fill={colors.white} d="M0 5H33V9H0z" />
        <Rect width="12" height="3" x="3" y="15" fill={colors.white} rx="1.5" />
      </G>
    </Svg>
  );
};
export default PlainCreditCard;
