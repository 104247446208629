import { useMutation, useQueryClient } from 'react-query';
import { ProgressNoteFormState } from 'components/Room/CRMContainer/NotesTab/ProgressNoteForm/context/types';
import { createProgressNoteRequest } from 'components/Room/CRMContainer/NotesTab/utils';
import apiHelper from '@/utils/api';
import apiWrapper from '../../utils/apiWrapper';
import {
  notesListQueryKey,
  progressNoteHelperDataQueryKey,
  progressNoteQueryKey,
} from './queryKeys';

import analytics from '../../components/Room/CRMContainer/NotesTab/Analytics';

interface ProgressNoteMutateVariables
  extends Omit<ProgressNoteFormState, 'showGlobalError' | 'formMode'> {
  roomID: string;
  clientUserID?: number;
  noteID?: string;
  isTherapist?: boolean;
}

interface SaveDraftProgressNoteResponse {
  noteID: number;
}

const saveDraftProgressNote = async (
  params: ProgressNoteMutateVariables
): Promise<SaveDraftProgressNoteResponse> => {
  const { roomID, noteID, clientUserID, isTherapist, ...progressNoteState } = params;

  const requestURL = noteID
    ? `/v2/rooms/${roomID}/progress-notes/${noteID}/draft`
    : `/v2/rooms/${roomID}/progress-notes/draft`;

  const method: keyof typeof apiWrapper = noteID ? 'patch' : 'post';

  const requestBody = createProgressNoteRequest(progressNoteState, isTherapist);
  analytics.trackProgressNoteApiCalledEvent({
    calledEndPointDescription: 'saveDraftProgressNote',
    roomID,
    noteID,
    serviceStartDate: progressNoteState.serviceStartDate,
    serviceEndDate: progressNoteState.serviceEndDate,
    modalityID: progressNoteState.modalityID,
    videoCalls: progressNoteState.videoCalls,
  });

  const res = await apiWrapper[method](`${apiHelper().apiEndpoint}${requestURL}`, requestBody);
  return res.data;
};

const useMutationSaveDraftProgressNote = () => {
  const queryClient = useQueryClient();
  return useMutation<SaveDraftProgressNoteResponse, Error, ProgressNoteMutateVariables>(
    saveDraftProgressNote,
    {
      onSuccess: (_, { roomID, noteID, clientUserID }) => {
        queryClient.invalidateQueries(notesListQueryKey(roomID));
        queryClient.invalidateQueries(progressNoteHelperDataQueryKey(roomID));
        if (noteID) {
          queryClient.invalidateQueries(progressNoteQueryKey(clientUserID, noteID, roomID));
        }
      },
    }
  );
};

export default useMutationSaveDraftProgressNote;
