import { Tiny } from '../Typography';

import styled from '../../core/styled';
import { webOnlyStyle } from '../../core/styleHelpers';

const AnimatedError = styled(Tiny)<{
  role?: string;
  shouldAnimate: boolean;
}>(({ shouldAnimate, theme: { colors } }) => {
  return {
    maxHeight: shouldAnimate ? 44 : 32,
    textAlign: 'left',
    paddingLeft: 4,
    paddingTop: 4,
    color: colors.red,
    ...webOnlyStyle({
      transitionProperty: 'max-height',
      transitionDuration: '0.2s',
      transitionTimingFunction: 'linear',
    }),
  };
});

export default AnimatedError;
