import { FloatingMenu, FocusTrap } from '@talkspace/react-toolkit';
import BottomSheet from '@talkspace/react-toolkit/src/designSystems/components/BottomSheet';
import { FC } from 'react';

type ResponsiveMenuProps = {
  isOpen: boolean;
  onClose: () => void;
  handleFocusTrapDeactivate: () => void;
  wrapperID: string;
  useBottomSheet?: boolean;
};

const ResponsiveMenu: FC<ResponsiveMenuProps> = ({
  children,
  isOpen,
  onClose,
  handleFocusTrapDeactivate,
  wrapperID,
  useBottomSheet,
}) => {
  if (!useBottomSheet)
    return (
      <FloatingMenu
        onBackdropPress={onClose}
        hide={!isOpen}
        style={{ padding: 10, height: 'auto', boxSizing: 'content-box' }} // hack to solve bootstrap global * { box-sizing: border-box }
      >
        <FocusTrap
          escapeCloses
          onDeactivate={handleFocusTrapDeactivate}
          titleText="Choose media to share"
          wrapper={wrapperID}
        >
          {children}
        </FocusTrap>
      </FloatingMenu>
    );

  return (
    <BottomSheet isOpen={isOpen} onClose={onClose}>
      {children}
    </BottomSheet>
  );
};

export default ResponsiveMenu;
