import { FunctionComponent } from 'react';
import { Input, useEmotionTheme } from '@talkspace/react-toolkit';
import styled from '@/core/styled';

interface ToggleSwitchViewProps {
  isOn: boolean;
  name: string;
  onValueChange?: (value: boolean) => void;
  onBackgroundColor?: string;
  offBackgroundColor?: string;
  switchWidth?: number;
  switchHeight?: number;
  dataQa?: string;
}
const Label = styled.label({});
const Span = styled.span({});

const ToggleSwitchView: FunctionComponent<ToggleSwitchViewProps> = ({
  isOn,
  name,
  onValueChange,
  onBackgroundColor,
  offBackgroundColor,
  switchWidth = 50,
  switchHeight = 30,
  dataQa,
}: {
  isOn?: boolean;
  name?: string;
  onValueChange?: (isOn: boolean) => void;
  onBackgroundColor?: string;
  offBackgroundColor?: string;
  switchWidth?: number;
  switchHeight?: number;
  dataQa?: string;
}) => {
  const { colors } = useEmotionTheme();
  const onChange = () => {
    if (onValueChange) {
      onValueChange(!isOn);
    }
  };
  return (
    <>
      <Input
        data-qa={dataQa}
        type="checkbox"
        id={`react-switch-new-${name}`}
        className="react-switch-checkbox"
        checked={isOn}
        onChangeValue={onChange}
      />
      <Label
        className="react-switch-label"
        htmlFor={`react-switch-new-${name}`}
        style={{
          background: isOn
            ? onBackgroundColor || colors.green
            : offBackgroundColor || colors.heatherGrey,
          height: switchHeight,
          width: switchWidth,
        }}
      >
        <Span
          className="react-switch-button"
          style={{ width: switchHeight - 2, height: switchHeight - 2 }}
        />
      </Label>
    </>
  );
};

export default ToggleSwitchView;
