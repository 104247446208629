import SocketService from '@/utils/socket/SocketService';
import { Status } from '../../entities/EMedia';

export interface MediaUpdatedPayload {
  messageId: number;
  roomId: string;
  mediaId: number;
  status: Status;
  url: string;
  thumbnail: string;
}

export default class MediaSocketService {
  roomID: number;

  updateMedia: Function;

  io: SocketService;

  constructor(roomID: number, updateMedia: Function) {
    this.roomID = roomID;
    this.updateMedia = updateMedia;
    this.io = SocketService.instance();
    this.socketInit();
  }

  private socketInit = () => {
    this.io.on('mediaUpdated', this.mediaUpdatedHandler);
  };

  public unmount = () => {
    this.io.off('mediaUpdated', this.mediaUpdatedHandler);
  };

  private mediaUpdatedHandler = (data: MediaUpdatedPayload) => {
    if (Number(data.roomId) === this.roomID) {
      this.updateMedia(data.messageId, data.status, data.url, data.thumbnail);
    }
  };
}
