import { FunctionComponent } from 'react';
import Svg, { G, Path } from 'svgs';

import styled from '@/core/styled/styled';

const DEFAULT_COLOR = '#0FC1A7';

interface EditProps {
  color?: string;
  inverted?: boolean;
}

const Edit: FunctionComponent<EditProps> = (props) => {
  const titleText = 'edit';
  return (
    <Svg
      width="20px"
      height="20px"
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 20 20"
      {...props}
    >
      <G
        transform="translate(-26 -803) translate(15 793) rotate(45 10.743 25.935)"
        strokeWidth={1.5}
        fillRule="evenodd"
      >
        <Path
          d="M6.974 17.052l-1.738 6.25 6.154-1.824 11.04-11.04c.689-.69.69-1.795.009-2.477l-1.953-1.952a1.747 1.747 0 00-2.477.008L6.974 17.052z"
          transform="rotate(-45 14.04 14.455)"
        />
        <Path d="M10.9757666 8.04809704H16.9757666V9.04809704H10.9757666z" />
        <Path d="M11.4757666 20.7760191H16.4757666V21.7760191H11.4757666z" />
      </G>
    </Svg>
  );
};

export default styled(Edit)<EditProps>`
  stroke: ${({ color = DEFAULT_COLOR }) => color};
  fill: transparent;
`;
// TODO: Add the commented out code below once we are able to add `babel-plugin-emotion`
// &:hover {
//   stroke: ${({ inverted = false, color = DEFAULT_COLOR }) =>
//     inverted ? "white" : color};
//   fill: ${({ inverted = false, color = DEFAULT_COLOR }) =>
//     inverted ? color : "white"};
// }
// ${({ FocusedParent }) => FocusedParent}:hover {
//   fill: black;
//   &:hover {
//     stroke: ${({ inverted = false, color = DEFAULT_COLOR }) =>
//       inverted ? "white" : color};
//     fill: ${({ inverted = false, color = DEFAULT_COLOR }) =>
//       inverted ? color : "white"};
//   }
