import { forwardRef } from 'react';
import View from '../View';
import HiddenText from '../HiddenText';
import styled, { EmotionStyle } from '../../core/styled';
import useScrollIntoInputs from '../../hooks/useScrollIntoInputs';
import { useWindowWidthState } from '../../hooks/windowWidthContext';
import { useContainerFocus, useUniqueID } from '../../hooks/a11yHelper';
import useFlagsSafe from '../../hooks/useFlagsSafe';
import { getScreenSafeAreaInsets } from '../../utils/safeAreaInsets';

export const DEFAULT_HEADER_HEIGHT = 67;
/**
 * SafeAreaVariant compensates for the type of outer frame the ResponsiveLayoutWithHeader is rendered within
 *   - see https://developer.mozilla.org/en-US/docs/Web/CSS/env#values
 *   - and the getScreenSafeAreaInsets() function in packages/react-toolkit/src/utils/safeAreaInsets.ts
 * 'none': safe area may not be necessary if the ClientNavShell and the useNavShellWindowHeight hook already add these values
 * 'setHeight': <default> if the ClientNavShell already adjusts the top and bottom safe area padding, this will adjust the height of
 *     the scrollable area to fit into that shell using "100vh - headerHeight - outerHeaderHeight - outerFooterHeight - safeAreaInsetTop - safeAreaInsetbottom"
 * 'addTop': some modals are displayed outside of the nav shell, and may need a top margin added for the top safe area
 * 'setHeightAndAddTop': some modals are position:fixed fullscreen, and need to add top marging and adjust their scrollable height
 */
export type SafeAreaVariant = 'none' | 'setHeight' | 'addTop' | 'setHeightAndAddTop';

interface Props {
  hideHeader?: boolean;
  headerHeight?: number;
  outsideHeaderHeight?: number;
  outsideFooterHeight?: number;
  safeAreaVariant?: SafeAreaVariant;
  shouldScroll?: boolean;
  shouldNotFocus?: boolean;
  renderHeader: null | (() => JSX.Element | null);
  title?: string;
  titleId?: string;
  roomID?: number;
  panelId?: string;
  containerStyle?: EmotionStyle;
  contentWrapperStyle?: EmotionStyle;
  noHeaderLine?: boolean;
  dataQa?: string;
}

const StyledContainer = styled(View)({
  '&:focus': {
    outline: 'none',
  },
});

const HeaderWrapper = styled(View)<{
  isMobile: boolean;
  headerHeight: number;
  noHeaderLine?: boolean;
}>(({ isMobile, headerHeight, noHeaderLine, theme: { colors } }) => {
  return {
    flexDirection: 'row',
    height: headerHeight,
    width: '100%',
    borderBottomWidth: noHeaderLine ? 0 : 1,
    borderBottomColor: colors.permaLightGrey,
    backgroundColor: colors.white,
    borderBottomStyle: 'solid',
    ...(isMobile
      ? {
          top: 0,
        }
      : {
          position: 'revert',
        }),
  };
});

const ContentWrapper = styled(View)<{
  headerHeight: number;
  windowHeight: number;
  shouldScroll: boolean;
  memberNewNav?: boolean;
  outsideFooterHeight: number;
  outsideHeaderHeight: number;
}>(
  ({
    memberNewNav,
    shouldScroll,
    windowHeight,
    headerHeight,
    outsideFooterHeight,
    outsideHeaderHeight,
  }) => {
    return {
      height: memberNewNav
        ? `calc(100dvh - ${headerHeight + outsideHeaderHeight + outsideFooterHeight}px)`
        : windowHeight - headerHeight,
      overflowY: shouldScroll ? 'auto' : 'unset',
      marginTop: 0,
      paddingBottom: 'env(safe-area-inset-bottom)',
    };
  }
);

const ResponsiveLayoutWithHeader = forwardRef<HTMLDivElement, React.PropsWithChildren<Props>>(
  (
    {
      renderHeader,
      children,
      outsideHeaderHeight = 0,
      outsideFooterHeight = 0,
      safeAreaVariant = 'setHeight',
      shouldScroll = true,
      shouldNotFocus = false,
      title,
      titleId,
      roomID,
      panelId,
      containerStyle = {},
      contentWrapperStyle = {},
      noHeaderLine,
      dataQa,
      hideHeader,
      headerHeight = hideHeader ? 0 : DEFAULT_HEADER_HEIGHT,
    },
    ref
  ) => {
    useScrollIntoInputs();
    const { isMobile, height } = useWindowWidthState();
    const { containerRef } = useContainerFocus(roomID, undefined, shouldNotFocus);
    const hiddenTitleId = useUniqueID('hiddenTitleId');
    const { memberNewNav } = useFlagsSafe();
    let finalAriaLabelledBy;
    if (titleId) {
      finalAriaLabelledBy = titleId;
    } else if (title) {
      finalAriaLabelledBy = hiddenTitleId;
    }
    const safeAreaInsets = getScreenSafeAreaInsets();

    let insetTop = 0;
    let insetBottom = 0;
    let safeAreaContainerStyle = {};
    if (safeAreaVariant === 'setHeight' || safeAreaVariant === 'setHeightAndAddTop') {
      insetTop = safeAreaInsets.top;
      insetBottom = safeAreaInsets.bottom;
    }
    if (safeAreaVariant === 'addTop' || safeAreaVariant === 'setHeightAndAddTop') {
      safeAreaContainerStyle = { marginTop: safeAreaInsets.top };
    }

    return (
      <StyledContainer
        tabIndex={-1}
        ref={containerRef}
        aria-labelledby={finalAriaLabelledBy}
        id={panelId}
        role={panelId ? 'region' : undefined}
        style={{
          ...safeAreaContainerStyle,
          ...containerStyle,
        }}
        data-qa={dataQa}
      >
        {title && <HiddenText id={hiddenTitleId}>{title}</HiddenText>}
        {renderHeader && !hideHeader && (
          <HeaderWrapper
            headerHeight={headerHeight}
            isMobile={isMobile}
            noHeaderLine={noHeaderLine}
          >
            {renderHeader()}
          </HeaderWrapper>
        )}
        <ContentWrapper
          memberNewNav={memberNewNav === 'treatment'}
          ref={ref}
          windowHeight={height}
          headerHeight={headerHeight}
          outsideHeaderHeight={outsideHeaderHeight + insetTop}
          outsideFooterHeight={outsideFooterHeight + insetBottom}
          shouldScroll={shouldScroll}
          style={contentWrapperStyle}
        >
          {children}
        </ContentWrapper>
      </StyledContainer>
    );
  }
);

export default ResponsiveLayoutWithHeader;
