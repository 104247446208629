interface UserInterface {
  id: number;
  subscriptionType: number;
  userType: number;
  displayName: string;
  avatarImage: string;
}

export class EUser implements UserInterface {
  id: number;

  subscriptionType: number;

  userType: number;

  displayName: string;

  avatarImage: string;

  constructor(user: UserInterface) {
    this.id = user.id;
    this.subscriptionType = user.subscriptionType;
    this.userType = user.userType;
    this.displayName = user.displayName;
    this.avatarImage = user.avatarImage;
  }
}

export const Hello = 'World';
