import { FunctionComponent, useRef } from 'react';
import * as React from 'react';
import { useUniqueID, TouchableView, HiddenText } from '@talkspace/react-toolkit';
import { webOnlyStyle } from '@/core/styled/styleHelpers';
import styled from '@/core/styled';
import { MessageProps, position as positionType, squareCornersType } from '../../types';
import Body from '../Body';
import { MessageBodyType } from '../../entities/EMessage';
import { getAriaDescribedby } from './Bubble.a11y';
import useLongPress from '../../hooks/useLongPress';
import { ID_CHAT_INPUT } from '../../../../apps/client-web/src/utils/IDConstants';
import { useNewMemberNav } from '../../../launchDarkly/hooks';

type BubbleProps = {
  bubbleTapped?: boolean;
  hasPadding?: boolean;
  containerWidth: number;
  id?: string;
  ariaExpanded?: boolean | 'true' | 'false' | undefined;
  ariaControls?: string;
  ariaDescribedBy?: string;
  onFocus?: React.FocusEventHandler<HTMLDivElement>;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
  className?: string;
  handleMessageActionModal?: (val: boolean) => void;
  isMobileApp?: boolean;
  useNewNav?: boolean;
} & MessageProps;

function calcStyles(position: positionType, squareCorners: squareCornersType): {} {
  const square = 4;
  const round = 20;
  const squareTop = squareCorners === 'TOP' || squareCorners === 'BOTH';
  const squareBottom = squareCorners === 'BOTTOM' || squareCorners === 'BOTH';
  return position === 'right'
    ? {
        borderTopLeftRadius: round,
        borderBottomLeftRadius: round,
        borderTopRightRadius: squareTop ? square : round,
        borderBottomRightRadius: squareBottom ? square : round,
      }
    : {
        borderTopLeftRadius: squareTop ? square : round,
        borderBottomLeftRadius: squareBottom ? square : round,
        borderTopRightRadius: round,
        borderBottomRightRadius: round,
      };
}

const getBackgroundColor = (
  colors: { [key: string]: string },
  position?: positionType,
  tapped?: boolean,
  bodyType?: MessageBodyType,
  useNewNav?: boolean
) => {
  const dynamicGreen = useNewNav ? colors.accessibilityGreenDark : colors.green;

  if (bodyType === 'PDF') {
    return colors.white;
  }
  if (bodyType === 'AUDIO') {
    return undefined;
  }
  if (position === 'left') {
    return tapped ? colors.greyBubbleTapped : colors.greyBubble;
  }
  return tapped ? colors.darkGreen : dynamicGreen;
};

// these word counts came from the android app and the widths
// were determined by sitting with Kris
function calcBaseWidthByCharCount(count?: number): number {
  if (count && count <= 110) return 130;
  if (count && count <= 100) return 150;
  return 170;
}

// these container width breakpoints were decided by Kris
// the multipliers for each breakpoint were determined by sitting with Kris
function calcBubbleMaxWidth(baseWidth: number, containerWidth: number): number {
  if (containerWidth > 567) return baseWidth * 2.4;
  if (containerWidth > 375) return baseWidth * 1.6;
  return baseWidth * 1.4;
}

const Wrapper = styled(TouchableView)<
  Pick<
    BubbleProps,
    | 'position'
    | 'isPeer'
    | 'squareCorners'
    | 'bubbleTapped'
    | 'currentMessage'
    | 'hasPadding'
    | 'isMedia'
    | 'containerWidth'
    | 'useNewNav'
  >
>(
  ({
    theme: { colors },
    position,
    isPeer,
    squareCorners,
    bubbleTapped,
    currentMessage,
    isMedia,
    containerWidth,
    hasPadding = true,
    useNewNav,
  }) => {
    const baseWidth = calcBaseWidthByCharCount(currentMessage.charCount);

    return {
      position: 'relative',
      margin: 1,
      paddingTop: hasPadding ? 12 : 0,
      paddingBottom: hasPadding ? 14 : 0,
      paddingRight: hasPadding ? 14 : 0,
      paddingLeft: hasPadding ? 14 : 0,
      maxWidth: isMedia ? 'initial' : calcBubbleMaxWidth(baseWidth, containerWidth),
      ...webOnlyStyle({
        overflow: 'auto',
        wordBreak: 'break-word',
      }),
      backgroundColor: getBackgroundColor(
        colors,
        position,
        bubbleTapped,
        currentMessage.bodyType,
        useNewNav
      ),
      ...calcStyles(position, squareCorners),
    };
  }
);

const Bubble: FunctionComponent<BubbleProps> = ({
  onPress,
  onFocus,
  onBlur,
  squareCorners,
  bubbleTapped,
  hasPadding,
  isMedia,
  containerWidth,
  id,
  ariaControls,
  ariaExpanded,
  ariaDescribedBy,
  className,
  handleMessageActionModal,
  isMobileApp,
  ...otherProps
}) => {
  // allows for screen readers to inform users what type of media message they are interacting with
  const mediaTypeID = useUniqueID('mediaTypeID');
  const { position } = otherProps;
  // informs screen reader users of the author of a given message in addition to whether or not the message is starred, the date sent etc.
  const bubbleDescribedBy = getAriaDescribedby(position, ariaDescribedBy);

  const handleLongPress = () => {
    const chatInput = document.getElementById(ID_CHAT_INPUT);
    chatInput?.blur();
    handleMessageActionModal && handleMessageActionModal(true);
  };

  const longPressEvent = useLongPress(handleLongPress, onPress);

  // fixes safari specific bug where bubble of audio message would render and retain focus outline if audio slider was clicked
  const wrapperRef = useRef<HTMLDivElement>(null);
  const onMouseLeave = () => {
    wrapperRef.current?.blur();
  };

  const shouldHaveLongPressEvent = isMobileApp && !isMedia;
  const useNewNav = useNewMemberNav();

  return (
    <Wrapper
      {...(shouldHaveLongPressEvent ? { ...longPressEvent } : {})}
      id={id}
      ref={wrapperRef}
      className={className}
      aria-controls={ariaControls}
      aria-expanded={ariaExpanded}
      aria-describedby={bubbleDescribedBy}
      onPress={onPress}
      onBlur={onBlur}
      onFocus={onFocus}
      onMouseLeave={onMouseLeave}
      position={otherProps.position}
      currentMessage={otherProps.currentMessage}
      squareCorners={squareCorners}
      bubbleTapped={bubbleTapped}
      hasPadding={hasPadding}
      isMedia={isMedia}
      isPeer={otherProps.isPeer}
      containerWidth={containerWidth}
      useNewNav={useNewNav}
    >
      <Body containerWidth={containerWidth} {...otherProps} />

      {isMedia && <HiddenText id={mediaTypeID}>{otherProps.currentMessage.bodyType}</HiddenText>}
    </Wrapper>
  );
};
export default Bubble;
