import Svg, { Path } from 'svgs';
import { useEmotionTheme } from '../../core/styled';
import type { EmotionStyle } from '../../core/styled';

export interface ContinueServiceIconProps {
  width?: number;
  height?: number;
  color?: string;
  style?: EmotionStyle;
}

const ContinueServiceIcon = ({
  color,
  width = 24,
  height = 28,
  ...otherProps
}: ContinueServiceIconProps) => {
  const { colors } = useEmotionTheme();
  return (
    <Svg
      width={width}
      height={height}
      title="continue service"
      aria-label="continue service"
      viewBox="0 0 24 28"
      fill="none"
      {...otherProps}
    >
      <Path
        d="M0 27.3V0.7L2 2.7L4 0.7L6 2.7L8 0.7L10 2.7L12 0.7L14 2.7L16 0.7L18 2.7L20 0.7L22 2.7L24 0.7V27.3L22 25.3L20 27.3L18 25.3L16 27.3L14 25.3L12 27.3L10 25.3L8 27.3L6 25.3L4 27.3L2 25.3L0 27.3ZM3.9 20.1333H20.2333V18.1333H3.9V20.1333ZM3.9 15H20.2333V13H3.9V15ZM3.9 9.83333H20.2333V7.83333H3.9V9.83333Z"
        fill={color || colors.permaAmethyst}
      />
    </Svg>
  );
};

export default ContinueServiceIcon;
