import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import rootReducer from './reducers/rootReducer';
import { isDevelopment } from './utils/resourceHelper';

const logger = createLogger();
const middlewares = isDevelopment() ? [thunkMiddleware, logger] : [thunkMiddleware];
const store = createStore(rootReducer, applyMiddleware(...middlewares));

export default store;
