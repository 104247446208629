import Svg, { Circle, Defs, G, Mask, Path, Pattern, Use } from 'svgs';
import { useEmotionTheme } from '../../core/styled';

const RoundBorderedBookingIcon = ({ width = 38, height = 40, ...otherProps }) => {
  const { colors } = useEmotionTheme();
  const titleText = 'booking';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox={`0 0 ${width} ${height}`}
      {...otherProps}
    >
      <Defs>
        <Circle id="RoundBorderedBookingIconB" cx={42.5} cy={42.5} r={42.5} />
        <Pattern
          id="RoundBorderedBookingIconC"
          patternUnits="objectBoundingBox"
          x="0%"
          width={width}
          height={height}
        >
          <Use xlinkHref="#RoundBorderedBookingIconA" transform="scale(.19318)" />
        </Pattern>
        <Path
          d="M14.442 12c1.015 0 1.837.822 1.837 1.837v6.326A1.837 1.837 0 0114.442 22H8.837A1.837 1.837 0 017 20.163v-6.326C7 12.822 7.822 12 8.837 12h5.605zm6.45 1.688a.735.735 0 01.108.385v5.487a.735.735 0 01-1.12.626l-2.6-1.598a.735.735 0 01-.35-.626V15.67c0-.255.133-.492.35-.626l2.6-1.598a.735.735 0 011.011.24z"
          id="RoundBorderedBookingIconE"
        />
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <G transform="translate(-203 -195) translate(145 145)">
          <Mask fill={colors.white}>
            <use xlinkHref="#RoundBorderedBookingIconB" />
          </Mask>
          <Use fill="url(#RoundBorderedBookingIconC)" xlinkHref="#RoundBorderedBookingIconB" />
          <use
            fillOpacity={0.246667395}
            style={{
              mixBlendMode: 'multiply',
            }}
            xlinkHref="#RoundBorderedBookingIconB"
          />
        </G>
        <Path
          d="M10.505 1.537H17.2A4.48 4.48 0 0121.66-2.5h.053a4.48 4.48 0 014.48 4.48v.578a7.903 7.903 0 014.013 6.88v15.055a7.9 7.9 0 01-7.9 7.9H5.4a7.9 7.9 0 01-7.9-7.9V9.438c0-2.913 1.59-5.51 4.013-6.88V1.98a4.48 4.48 0 014.48-4.48h.054a4.48 4.48 0 014.458 4.037z"
          transform="translate(-203 -195) translate(208 200)"
          fill={colors.purple}
          stroke={colors.white}
          strokeWidth={5}
        />
        <G transform="translate(-203 -195) translate(208 200)">
          <use
            fill={colors.black}
            filter="url(#RoundBorderedBookingIconD)"
            xlinkHref="#RoundBorderedBookingIconE"
          />
          <use fill={colors.patensBlue} xlinkHref="#RoundBorderedBookingIconE" />
        </G>
      </G>
    </Svg>
  );
};

export default RoundBorderedBookingIcon;
