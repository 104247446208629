import { ChargeType } from 'ts-frontend/types';

// eslint-disable-next-line import/prefer-default-export
export const clientTransactionsQueryKey = ({
  clientUserID,
  chargeTypes = [],
}: {
  clientUserID: number;
  chargeTypes?: Array<ChargeType>;
}) => ['client-transactions', clientUserID, chargeTypes.sort().join()];
