import { Component } from 'react';
import { Col } from 'react-bootstrap';
import './Spinner.css';

class Spinner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentFrame: 0,
      stopFrame: undefined,
      imageClass: 'ts-spinner',
      intervalID: undefined,
      message: null,
    };
    this.animateSpinner = this.animateSpinner.bind(this);
    this.getBackgroundPosition = this.getBackgroundPosition.bind(this);
    this.incrementFrame = this.incrementFrame.bind(this);
    this.isFullAnimationCycleAndNoStopSet = this.isFullAnimationCycleAndNoStopSet.bind(this);
    this.showSpinnerAnimation = this.showSpinnerAnimation.bind(this);
    this.showErrorAnimation = this.showErrorAnimation.bind(this);
    this.showSuccessAnimation = this.showSuccessAnimation.bind(this);
    this.getDisplayMessage = this.getDisplayMessage.bind(this);
  }

  componentDidMount() {
    this.animateSpinner();
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalID);
  }

  getBackgroundPosition() {
    const pixelsPerFrame = 32;
    return this.state.currentFrame * pixelsPerFrame;
  }

  getDisplayMessage() {
    if (this.props.isUpdating) return '';
    return this.props.isError
      ? this.props.errorMessage || this.state.message
      : this.props.spinnerMessage || this.state.message;
  }

  animateSpinner() {
    const intervalID = setInterval(() => {
      this.incrementFrame();
      // spinner animations are 24 frames per second
    }, 1000 / 24);
    this.setState({ intervalID });
  }

  incrementFrame() {
    if (this.state.currentFrame === this.state.stopFrame)
      return clearInterval(this.state.intervalID);

    if (this.isFullAnimationCycleAndNoStopSet() && this.props.isError) {
      this.showErrorAnimation();
    } else if (
      this.isFullAnimationCycleAndNoStopSet() &&
      !this.props.isUpdating &&
      !this.props.isError
    ) {
      this.showSuccessAnimation();
    } else if (this.isFullAnimationCycleAndNoStopSet()) {
      this.showSpinnerAnimation();
    } else {
      this.setState({ currentFrame: this.state.currentFrame - 1 });
    }
    return undefined;
  }

  isFullAnimationCycleAndNoStopSet() {
    const framesInSpinningAnimation = -22;
    return (
      this.state.currentFrame % framesInSpinningAnimation === 0 &&
      this.state.stopFrame === undefined
    );
  }

  showErrorAnimation() {
    const framesInErrorAnimation = -33;
    this.setState({
      currentFrame: -1,
      imageClass: 'ts-spinner error',
      message: 'Something went wrong',
      stopFrame: framesInErrorAnimation,
    });
  }

  showSpinnerAnimation() {
    this.setState({ currentFrame: -1 });
  }

  showSuccessAnimation() {
    const framesInSuccessAnimation = -26;
    this.setState({
      currentFrame: -1,
      imageClass: 'ts-spinner success',
      message: 'Successfully posted in room',
      stopFrame: framesInSuccessAnimation,
    });
  }

  render() {
    return (
      <div className="ts-spinner-container" style={this.props.style} data-testid="spinner">
        <div className="message">{this.getDisplayMessage()}</div>
        <div
          className={`${this.state.imageClass}`}
          style={{ backgroundPositionX: `${this.getBackgroundPosition()}px` }}
        />
      </div>
    );
  }
}

export default Spinner;
