import { FunctionComponent } from 'react';
import { EmotionStyle } from '../../core/styled';
import SpinnerView from './SpinnerView';

interface SpinnerProps {
  style?: EmotionStyle;
  isLoading?: boolean;
  containerStyle?: EmotionStyle;
  primaryColor?: string;
  secondaryColor?: string;
  thickness?: number;
}

const Spinner: FunctionComponent<SpinnerProps> = (props) => <SpinnerView {...props} />;

export default Spinner;
