import * as actionTypes from '../constants/MatchingConstants';

const initialState = {
  isUpdating: false,
  isError: false,
  treatmentIntakeStatus: undefined,
};

export default function matching(state = initialState, action) {
  switch (action.type) {
    case actionTypes.REQUEST_SEND_INTAKE_MESSAGE:
    case actionTypes.REQUEST_INTAKE_SURVEY_STATUS:
    case actionTypes.REQUEST_SEND_ELIGIBILITY_MESSAGE:
      return {
        ...state,
        isUpdating: true,
        isError: false,
      };
    case actionTypes.REQUEST_SEND_INTAKE_MESSAGE_ERROR:
    case actionTypes.REQUEST_INTAKE_SURVEY_STATUS_ERROR:
    case actionTypes.REQUEST_SEND_ELIGIBILITY_MESSAGE_ERROR:
      return {
        ...state,
        isUpdating: false,
        isError: true,
      };
    case actionTypes.RECEIVE_SEND_ELIGIBILITY_MESSAGE:
      return {
        ...state,
        isUpdating: false,
        isError: false,
      };
    case actionTypes.RECEIVE_SEND_TREATMENT_INTAKE_MESSAGE:
      return {
        ...state,
        isUpdating: false,
        isError: false,
        treatmentIntakeStatus: 'requested',
      };
    case actionTypes.RECEIVE_TREATMENT_INTAKE_SURVEY_STATUS:
      return {
        ...state,
        isUpdating: false,
        isError: false,
        treatmentIntakeStatus: action.treatmentIntakeStatus,
      };
    default:
      return state;
  }
}
