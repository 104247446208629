import { FunctionComponent, RefObject, Dispatch, SetStateAction } from 'react';

import { EmotionStyle } from '@/core/styled/styled';
import InputComposerView from './InputComposerView';

interface InputComposerProps {
  text: string;
  hide?: boolean;
  placeholder?: string;
  disabled?: boolean;
  wrapperStyle?: EmotionStyle;
  inputStyle?: EmotionStyle;
  inputRef?: RefObject<HTMLTextAreaElement>;
  onChangeText(text: string): void;
  onSubmitEditing(): void;
  tabIndex?: number;
  id?: string;
  ariaHidden?: boolean;
  isInputAutoFocused: boolean;
  setIsInputAutoFocused: Dispatch<SetStateAction<boolean>>;
  isInputSendFocused: boolean;
  setIsInputSendFocused: Dispatch<SetStateAction<boolean>>;
  isSendClicked: boolean;
  setIsSendClicked: Dispatch<SetStateAction<boolean>>;
  isFocusedAfterModal: boolean;
  numberOfLines?: number;
}

const InputComposer: FunctionComponent<InputComposerProps> = ({
  text,
  onChangeText,
  onSubmitEditing,
  hide,
  placeholder,
  disabled,
  wrapperStyle,
  inputStyle,
  inputRef,
  tabIndex,
  id,
  ariaHidden,
  isInputAutoFocused,
  setIsInputAutoFocused,
  isInputSendFocused,
  setIsInputSendFocused,
  isSendClicked,
  setIsSendClicked,
  isFocusedAfterModal,
  numberOfLines,
}) =>
  hide ? null : (
    <InputComposerView
      id={id}
      text={text}
      isInputAutoFocused={isInputAutoFocused}
      isInputSendFocused={isInputSendFocused}
      isSendClicked={isSendClicked}
      setIsInputAutoFocused={setIsInputAutoFocused}
      setIsInputSendFocused={setIsInputSendFocused}
      setIsSendClicked={setIsSendClicked}
      aria-hidden={ariaHidden ? 'true' : 'false'}
      onChangeText={onChangeText}
      onSubmitEditing={onSubmitEditing}
      placeholder={placeholder}
      disabled={disabled}
      wrapperStyle={wrapperStyle}
      inputStyle={inputStyle}
      inputRef={inputRef}
      tabIndex={tabIndex}
      isFocusedAfterModal={isFocusedAfterModal}
      numberOfLines={numberOfLines}
    />
  );

export default InputComposer;
