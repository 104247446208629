import Svg, { Defs, G, Path, Rect } from 'svgs';

const HorizontalScrollRightArrowDefaultIcon = () => (
  <Svg width="41" height="42" viewBox="0 0 41 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
    {/* @ts-ignore */}
    <G filter="url(#filter0_d_900_10810)">
      <Rect
        width="33"
        height="33"
        rx="16.5"
        transform="matrix(-1 -8.74228e-08 -8.74228e-08 1 37 4)"
        fill="white"
        shape-rendering="crispEdges"
      />
      <Path
        d="M17.89 29.0525C17.6664 28.8289 17.5546 28.5654 17.5546 28.2619C17.5546 27.9584 17.6664 27.6949 17.89 27.4713L24.8619 20.4994L17.8661 13.5036C17.6425 13.28 17.5348 13.0164 17.5431 12.713C17.5508 12.4095 17.6664 12.1459 17.89 11.9223C18.1136 11.6987 18.3772 11.5869 18.6807 11.5869C18.9841 11.5869 19.2477 11.6987 19.4713 11.9223L27.4254 19.8765C27.5053 19.9723 27.5654 20.072 27.6056 20.1755C27.6452 20.2796 27.665 20.3876 27.665 20.4994C27.665 20.6112 27.6452 20.7189 27.6056 20.8224C27.5654 20.9265 27.5053 21.0265 27.4254 21.1223L19.4473 29.0765C19.2397 29.3001 18.9841 29.4081 18.6807 29.4004C18.3772 29.3921 18.1136 29.2761 17.89 29.0525Z"
        fill="#005C65"
      />
    </G>
    <Defs>
      <filter
        id="filter0_d_900_10810"
        x="0"
        y="1"
        width={41}
        height={41}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.788235 0 0 0 0 0.827451 0 0 0 0 0.862745 0 0 0 1 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_900_10810" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_900_10810"
          result="shape"
        />
      </filter>
    </Defs>
  </Svg>
);

export default HorizontalScrollRightArrowDefaultIcon;
