import {
  View,
  Standard,
  TouchableView,
  FloatingMenu,
  CloseButton,
  Small,
  Button,
  Huge,
  CollapseButton,
  Collapsible,
  QuickCaseMenuBox,
  EmotionStyle,
} from '@talkspace/react-toolkit';
import { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router';
import moment from 'moment';
import { RoomRecord } from 'components/Dashboard/types';
import styled from '@/core/styled';
import QuickCaseIcon from '../../Icons/QuickCaseIcon';
import DiagnosisIcon from '../../Icons/DiagnosisIcon';
import CaseViewCalendarIcon from '../../Icons/CaseViewCalendarIcon';
import CaseViewPlanIcon from '../../Icons/CaseViewPlanIcon';
import planDesriptionText from './planDescriptionText';
import useDashboardSize from '../hooks/useDashboardSize';

const QuickCaseContainer = styled(TouchableView)<{ isHovering: boolean }>(
  ({ isHovering = false }) => {
    return {
      display: 'flex',
      visibility: isHovering ? 'visible' : 'hidden',
    };
  }
);

const ContentWrapper = styled(View)(() => {
  return {
    paddingLeft: 25,
    paddingRight: 14,
    flex: 1,
  };
});

const Footer = styled(View)(({ theme: { colors } }) => {
  return {
    borderTop: `solid ${colors.permaPowderBlue} 1px`,
    padding: 15,
  };
});

const SubmitButton = styled(Button)(({ theme: { colors } }) => {
  return {
    background: colors.permaEden,
    width: 156,
    height: 30,
  };
});

const LongTextWrapper = styled(TouchableView)<{ isMobile: boolean; variant: string }>(
  ({ isMobile = false, variant }) => {
    return {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      width: isMobile ? 250 : 320,
      display: 'inline-block',
    };
  }
);

const PlanTextWrapper = styled(View)(() => {
  return {
    maxHeight: 120,
    overflow: 'scroll',
    paddingRight: 5,
  };
});

const getFloatingMenuStyle = (isMobile: boolean): EmotionStyle => {
  return {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: 'fit-content',
    maxHeight: 'unset',
    width: 455,
    zIndex: 20,
    ...(isMobile && {
      width: 355,
    }),
  };
};

interface QuickCaseViewSectionProps {
  Icon: JSX.Element;
  Text: JSX.Element;
  textStyle?: EmotionStyle;
  iconStyle?: EmotionStyle;
  conatinerStyle?: EmotionStyle;
}

const QuickCaseViewSection = ({
  Icon,
  Text,
  textStyle,
  iconStyle,
  conatinerStyle,
}: QuickCaseViewSectionProps) => (
  <View row style={{ marginBottom: 20, ...conatinerStyle }}>
    <View style={{ flexBasis: 36, marginLeft: 7, ...iconStyle }}>{Icon}</View>
    <View flex={1} style={textStyle}>
      {Text}
    </View>
  </View>
);

interface QuickCaseViewProps {
  isHovering?: boolean;
  client: RoomRecord;
}

const QuickCaseView = ({ isHovering = false, client }: QuickCaseViewProps) => {
  const { isDashboardMobile } = useDashboardSize();
  const [hideMenu, setHideMenu] = useState<boolean>(true);
  const [isMenuCollapsed, setIsMenuCollapsed] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => () => setIsMenuCollapsed(false), [hideMenu]);

  const redirectToRoom = () => {
    const { roomID } = client;
    history.push(`/room/${roomID}`);
  };

  const getClientName = useCallback(
    () => (
      <Huge inline>
        {[client.clientFirstName, client.clientLastName].join(' ').trim() || client.clientNickname}
      </Huge>
    ),
    [client.clientFirstName, client.clientLastName, client.clientNickname]
  );

  const getClientBasicInformation = useCallback(() => {
    const clientName = getClientName();
    let clientBasicInformation: JSX.Element | null = null;
    const clientInformationArray = [client?.gender?.name, client?.age, client?.state];
    clientBasicInformation = (
      <Small>
        {clientInformationArray.map((value, index, elements) => (
          <>
            {value && `${value}`}
            {value && index !== clientInformationArray.length - 1 && elements[index + 1] && ', '}
          </>
        ))}
      </Small>
    );

    return (
      <>
        {clientName}
        {clientBasicInformation}
      </>
    );
  }, [client.age, client.gender, client.state, getClientName]);

  const buildDiagnosisElement = useCallback(
    (array: Array<string>) => (
      <LongTextWrapper
        isMobile={isDashboardMobile}
        style={{ marginBottom: 15 }}
        variant="smallTSBlack"
      >
        Diagnosis:{' '}
        {array.map((item, index) => (
          <>
            {item}
            {index !== array.length - 1 && ','}{' '}
          </>
        ))}
      </LongTextWrapper>
    ),
    [isDashboardMobile]
  );

  const getDiagnosis = useCallback(() => {
    let diagnosis: JSX.Element | null = null;

    if (client.diagnoses?.length > 0) {
      diagnosis = buildDiagnosisElement(client.diagnoses);
    } else if (client.presentingProblems?.length > 0) {
      diagnosis = buildDiagnosisElement(client.presentingProblems);
    } else {
      diagnosis = (
        <LongTextWrapper
          isMobile={isDashboardMobile}
          style={{ marginBottom: 15 }}
          variant="smallTSBlack"
        >
          Diagnosis: None
        </LongTextWrapper>
      );
    }
    return (
      <>
        {diagnosis}
        <Small style={{ marginBottom: 15 }} variant="smallTSBlack">
          Acuity: {client.acuity || 'None'}
        </Small>
        <Small variant="smallTSBlack">
          Active suicidal ideation: {client.activeSuicidalIdeation || 'None'}
        </Small>
      </>
    );
  }, [
    client.diagnoses,
    isDashboardMobile,
    client.acuity,
    client.activeSuicidalIdeation,
    client.presentingProblems,
    buildDiagnosisElement,
  ]);

  const getUnusedLiveSessions = useCallback(() => {
    let renewContent = '';
    if (client.renewDate) {
      renewContent = `(Renews on ${moment(client.renewDate).format('MMM DD')})`;
    }
    if (client.planEnd) {
      renewContent = `(Expires on ${moment(client.planEnd).format('MMM DD')})`;
    }
    return (
      <>
        <Small variant="smallTSBlack" style={{ marginBottom: 15 }}>
          Unused live sessions: {client.validVideoCredits} of {client.totalSessions} {renewContent}
        </Small>
        <Small variant="smallTSBlack">
          Next live session:{' '}
          {client.activeBookings
            ? moment(client.activeBookings[0].startTime).format('ddd, MMM D h:mmA')
            : 'Not scheduled'}
        </Small>
      </>
    );
  }, [
    client.validVideoCredits,
    client.totalSessions,
    client.renewDate,
    client.planEnd,
    client.activeBookings,
  ]);

  const getPlanInfo = useCallback(() => {
    let accountTypeKey = client.accountType as string;
    if (accountTypeKey === 'bh') {
      accountTypeKey = [10, 30].includes(client.totalSessions)
        ? accountTypeKey.concat(client.totalSessions.toString())
        : accountTypeKey.concat('Unlimited');
    }
    const planDescription: Array<string> =
      planDesriptionText[accountTypeKey] && planDesriptionText[accountTypeKey][client.planModality];

    return (
      <>
        <View row justify="space-between">
          <Standard variant="standardBoldTSBlack" style={{ marginBottom: 15 }}>
            Plan: {client.planName || 'None'}
          </Standard>
          {client.planName && planDescription && (
            <CollapseButton
              style={{ marginBottom: -5 }}
              isCollapsed={isMenuCollapsed}
              onPress={() => setIsMenuCollapsed((prev) => !prev)}
            />
          )}
        </View>
        {planDescription && (
          <Collapsible isCollapsed={!isMenuCollapsed}>
            <PlanTextWrapper>
              {planDescription.map((line) => (
                <Small variant="smallTSBlack" style={{ marginBottom: 15 }}>
                  {line}
                </Small>
              ))}
            </PlanTextWrapper>
          </Collapsible>
        )}
      </>
    );
  }, [
    client.planModality,
    client.accountType,
    client.totalSessions,
    client.planName,
    isMenuCollapsed,
  ]);

  const clientBasicInformation = getClientBasicInformation();
  const diagnosis = getDiagnosis();
  const unusedLiveSessions = getUnusedLiveSessions();
  const floatingMenuStyle = getFloatingMenuStyle(isDashboardMobile);
  const planInfo = getPlanInfo();

  return (
    <>
      <QuickCaseContainer isHovering={isHovering} onPress={() => setHideMenu(false)}>
        <QuickCaseMenuBox isMenuShowing={!hideMenu} />
      </QuickCaseContainer>
      <FloatingMenu
        hide={hideMenu}
        onBackdropPress={() => setHideMenu(true)}
        style={floatingMenuStyle}
      >
        <View row justify="end" style={{ marginTop: 10, marginRight: 10 }}>
          <CloseButton onPress={() => setHideMenu(true)} width={31} height={31} />
        </View>

        <ContentWrapper>
          <QuickCaseViewSection
            Icon={<QuickCaseIcon />}
            Text={clientBasicInformation}
            textStyle={{ marginTop: -7 }}
            iconStyle={{ flexBasis: 43, marginLeft: 0 }}
          />
          <QuickCaseViewSection Icon={<DiagnosisIcon />} Text={diagnosis} />
          <QuickCaseViewSection Icon={<CaseViewCalendarIcon />} Text={unusedLiveSessions} />
          <QuickCaseViewSection Icon={<CaseViewPlanIcon />} Text={planInfo} />
        </ContentWrapper>

        <Footer row justify="end">
          <SubmitButton size="small" onPress={redirectToRoom}>
            <Small style={{ color: 'white', fontWeight: 700 }}>Open room</Small>
          </SubmitButton>
        </Footer>
      </FloatingMenu>
    </>
  );
};

export default QuickCaseView;
