import { PluginListenerHandle } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { addIonicAppListener, DEFAULT_LISTENER_RETURN } from '../capacitor';
import { safeIonicWrapper } from '../../ionicUtils';
import { pushNotificationLogger } from '../../loggers';
import { openAppSettings } from '../settings';
import { preventNextSplashScreenShow } from '../splashScreen';

export const addPushNotificationListener = safeIonicWrapper(
  PushNotifications.addListener,
  DEFAULT_LISTENER_RETURN
);

export const checkPushNotificationPermission = safeIonicWrapper(
  PushNotifications.checkPermissions,
  Promise.resolve({ receive: 'denied' as const })
);

export const requestPushNotificationPermission = safeIonicWrapper(() => {
  preventNextSplashScreenShow();
  return PushNotifications.requestPermissions();
}, Promise.resolve({ receive: 'denied' as const }));

export const removePushNotificationListeners = safeIonicWrapper(
  PushNotifications.removeAllListeners,
  Promise.resolve()
);

export const registerPushNotifications = safeIonicWrapper(
  PushNotifications.register,
  Promise.resolve()
);

/**
 * Unlike the default listener, this invokes immediately, and also checks permission after resuming from background
 * To make sure we have an updated value after prompting for permissions or opening the settings.
 */
export const addPushNotificationPermissionListener = safeIonicWrapper(
  (
    callback: (permissionGranted: boolean) => void
  ): Promise<PluginListenerHandle> & PluginListenerHandle => {
    const checkPermission = async () => {
      const permission = await checkPushNotificationPermission();
      callback(permission.receive === 'granted');
    };

    // Invoke callback immediately
    checkPermission();

    // Add listener for changes
    const listeners = [
      addIonicAppListener('resume', checkPermission),
      addPushNotificationListener('registration', checkPermission),
    ];

    const removeListeners = () => listeners.forEach((listener) => listener.remove());

    return Object.assign(
      Promise.all(listeners).then(() => {
        return { remove: removeListeners };
      }) as any,
      {
        remove: removeListeners,
      }
    );
  },
  DEFAULT_LISTENER_RETURN
);

/** Custom Push Notification Methods */
export const promptPushNotificationPermissionOrSettings = async () => {
  let permStatus = await checkPushNotificationPermission();
  pushNotificationLogger.info('Prompting for Push Notification permission', permStatus);
  // Check denied first, open settings if so
  if (permStatus.receive === 'denied') {
    permStatus = await new Promise((resolve) => {
      addIonicAppListener('resume', () => {
        resolve(checkPushNotificationPermission());
      });
      openAppSettings();
    });
  }
  if (permStatus.receive === 'prompt') {
    permStatus = await requestPushNotificationPermission();
  }
  if (permStatus.receive === 'granted') {
    pushNotificationLogger.info('User granted permission');
    await registerPushNotifications();
  } else {
    pushNotificationLogger.warn('User denied permission', permStatus);
  }
  return permStatus.receive === 'granted';
};

export const promptPushNotificationAndRegister = async () => {
  let permStatus = await checkPushNotificationPermission();
  if (permStatus.receive === 'prompt') {
    permStatus = await requestPushNotificationPermission();
  }
  if (permStatus.receive === 'granted') {
    pushNotificationLogger.info('User granted permission');
    await registerPushNotifications();
  } else {
    pushNotificationLogger.warn('User denied permission', permStatus);
  }
  return permStatus.receive === 'granted';
};

export const pushNotificationSettings = async () => {
  await new Promise((resolve) => {
    addIonicAppListener('resume', () => {
      resolve(checkPushNotificationPermission());
    });
    openAppSettings();
  });
};

export const checkPushNotificationAndRegister = async () => {
  const permStatus = await checkPushNotificationPermission();
  if (permStatus.receive === 'granted') {
    pushNotificationLogger.info('User granted permission');
    await registerPushNotifications();
  } else if (permStatus.receive === 'denied') {
    pushNotificationLogger.warn('User denied permission', permStatus);
  }
  return permStatus.receive === 'prompt';
};

export { pushNotificationLogger };
