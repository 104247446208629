import { forwardRef, useEffect } from 'react';
import InputMask from 'react-input-mask';
import View from '../View';
import Text from '../Text';
import styled, { EmotionStyle } from '../../core/styled';
import { useShareForwardedRef } from '../../hooks/a11yHelper';

interface MaskedInputProps {
  placeholder?: string;
  maskPlaceholder?: string;
  disabled?: boolean;
  isValid?: boolean;
  hidden?: boolean;
  maskType: string;
  inputStyle?: EmotionStyle;
  placeHolderStyle?: EmotionStyle;
  autoFocus?: boolean;
  hasFocus?: boolean;
  value?: string;
}

const MaskedInputWrapper = styled(View)({
  marginTop: 0,
  position: 'relative',
  width: '100%',
  boxSizing: 'content-box',
});

const Placeholder = styled(Text)<{ shouldHidePlaceHolder?: boolean }>(({ theme: { colors } }) => {
  return {
    position: 'absolute',
    top: 18,
    marginLeft: 17,
    fontSize: 16,
    fontFamily: 'Roboto, sans-serif',
    color: colors.permaWaikawaGrey,
    pointerEvents: 'none',
  };
});

const formatDateChars = {
  n: '[0-1]',
  m: '[0-9]',
  e: '[0-3]',
  d: '[0-9]',
  z: '[1-2]',
  y: '[0-9]',
};

const maskPropsDict = {
  phone: {
    mask: '(999) 999-9999',
    alwaysShowMask: false,
    maskChar: null,
  },
  date: {
    mask: 'nm/ed/zyyy',
    alwaysShowMask: false,
    formatChars: formatDateChars,
    maskChar: null,
  },
  europeDate: {
    mask: 'ed/nm/zyyy',
    alwaysShowMask: false,
    formatChars: formatDateChars,
    maskChar: null,
  },
  zipcode: {
    mask: '99999',
    alwaysShowMask: false,
    maskChar: null,
  },
};

const MaskedInput = forwardRef<
  HTMLInputElement,
  MaskedInputProps & React.ComponentPropsWithoutRef<typeof InputMask>
>((props, ref) => {
  const {
    placeholder,
    maskPlaceholder,
    disabled,
    isValid,
    hidden,
    maskType,
    inputStyle,
    placeHolderStyle,
    autoFocus,
    value,
    ...otherProps
  } = props;

  const { inputRef } = otherProps;
  const innerRef = useShareForwardedRef(inputRef || ref);
  const maskProps = maskPropsDict[maskType] || {};
  useEffect(() => {
    if (autoFocus) {
      innerRef?.current?.focus();
    }
  }, [autoFocus, innerRef]);
  return (
    <MaskedInputWrapper>
      {/* react-input-mask doesn't seem to allow for placeholder styling so we use a pseudo-placeholder */}
      {!value && <Placeholder style={placeHolderStyle}>{placeholder}</Placeholder>}
      <InputMask
        {...otherProps}
        style={{
          outline: 'none',
          cursor: disabled ? 'default' : 'pointer',
          ...inputStyle,
        }}
        value={value}
        inputRef={inputRef}
        {...maskProps}
      />
    </MaskedInputWrapper>
  );
});

export default MaskedInput;
