import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone';
import { withRouter } from 'react-router';
import { getOutcomeMeasures } from '../../../../../actions/OutcomeMeasureActions';
import AccordionContainer from '../../../../Reusable/AccordionContainer/AccordionContainer';
import './OutcomeMeasures.css';
import ItemList from '../../../../Reusable/ItemList/ItemList';
import ListItem from '../../../../Reusable/ListItems/ListItem';
import Button from '../../../../Reusable/Buttons/Button';
import PinkAlert from '../../../../Icons/PinkAlert';
import OutcomeMeasuresIcon from './OutcomeMeasuresIcon';

class OutcomeMeasures extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.renderOutcomeMeasuresTable = this.renderOutcomeMeasuresTable.bind(this);
    this.getItemActions = this.getItemActions.bind(this);
    this.roomPath = `/room/${props.match.params.roomID}`;
  }

  componentDidMount() {
    this.props.getOutcomeMeasures();
  }

  getItemActions(item) {
    const { history, match } = this.props;
    if (!item.canceledAt && !item.completedAt) {
      return [
        {
          text: 'Stop Scheduling',
          method: (item) => {
            history.push(`${this.roomPath}/outcome-measure-cancel`, { outcomeMeasure: item });
          },
          cssClass: 'danger',
        },
      ];
    }
    if (item.completedAt) {
      return [
        {
          text: 'View',
          method: (item) => {
            history.push(`${this.roomPath}/outcome-measure`, { outcomeMeasure: item });
          },
          cssClass: 'default',
        },
      ];
    }
    return null;
  }

  processItems(items) {
    return items.map((item, key) => {
      const { overall, maximum, scoreChangePercent, completedAt, deliverAt, canceledAt, label } =
        item;
      const percent = scoreChangePercent || 0;
      const isZero = percent === 0;
      const isPositive = percent > 0;
      const incompleteSurveyText = canceledAt ? 'Cancelled' : 'Scheduled';
      const color = isZero ? '#B1B1B1' : isPositive ? '#E8006B' : '#007e73';
      const itemDisplayData = {};
      itemDisplayData.date = moment(completedAt || deliverAt).format('MMM D, YYYY');
      itemDisplayData.name = String(label).replace(/assessment/gi, '');
      itemDisplayData.overallScore = completedAt
        ? () => (
            <div className="score-container">
              <span style={{ flex: 2, display: 'flex' }}>
                {overall}/{maximum}
              </span>
              {!isZero && (
                <span
                  style={{ flex: 1 }}
                  className={isPositive ? 'arrow-right-up-red' : 'arrow-right-down-green'}
                />
              )}
              {isZero && <span style={{ flex: 1 }} />}
              <span style={{ color, flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                {' '}
                {percent}%
              </span>
              {/* <ThreeDots style={{flex: 1, display: 'flex', justifyContent: 'flex-end'}} color='#B1B1B1'/> */}
            </div>
          )
        : incompleteSurveyText;

      return (
        <ListItem
          key={key}
          itemDataOrder={['name', 'date', 'overallScore']}
          itemDisplayData={itemDisplayData}
          item={item}
          status={label}
          actions={this.getItemActions(item)}
          widths={[100, 90, 105]}
          itemStyle={{
            color: item.canceledAt || !item.completedAt ? '#979797' : '#222F2D',
          }}
          dropdownStyle={{
            position: 'relative',
            width: 0,
            right: 17,
            overflow: 'unset',
          }}
        />
      );
    });
  }

  renderEmptyOutcomeMeasures() {
    return (
      <div style={{ display: 'flex' }}>
        <OutcomeMeasuresIcon
          style={{ display: 'flex', flex: 1, height: 70, width: 68, outline: 'none' }}
        />
        <div
          style={{
            flex: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: 5,
          }}
        >
          <div className="outcome-measure-text">No outcome measure</div>
          <div className="outcome-measure-text">assessments scheduled</div>
          <Button
            title="Start assessment scheduling"
            clickHandler={() => this.props.history.push(`${this.roomPath}/outcome-measure-start`)}
            style={{
              textAlign: 'left',
              paddingRight: 0,
              marginTop: 25,
              display: 'flex',
              alignSelf: 'flex-end',
            }}
          />
        </div>
      </div>
    );
  }

  renderOutcomeMeasuresTable() {
    if (this.props.outcomeMeasures.length === 0) {
      return [this.renderEmptyOutcomeMeasures()];
    }
    return [
      <ItemList
        items={this.processItems(this.props.outcomeMeasures)}
        titles={['Name', 'Date', 'Overall score']}
        noDataMessage="No outcome measures."
        widths={[100, 90, 105]}
        key={1}
      />,
      !this.props.isScheduled && this.props.outcomeMeasures.length > 0 ? (
        <Button
          title="Start assessment scheduling"
          clickHandler={() => this.props.history.push(`${this.roomPath}/outcome-measure-start`)}
          style={{ textAlign: 'left', paddingRight: 0, display: 'flex' }}
        />
      ) : null,
    ];
  }

  render() {
    const showCustomTitle = !this.props.isScheduled && this.props.outcomeMeasures.length > 0;
    const searchParams = new URLSearchParams(window.location.search);
    const containerValue = searchParams.get('container');
    const isOpen = containerValue === 'outcome-measures';
    const titleComponent = () => (
      <div style={{ display: 'inline-block' }}>
        <PinkAlert style={{ position: 'relative', top: 2 }} />
        <span style={{ marginLeft: 6 }}>Outcome measures is inactive</span>
      </div>
    );
    const title = 'Outcome measures';
    return (
      <AccordionContainer
        title={title}
        titleComponent={showCustomTitle && titleComponent}
        open={isOpen}
        childComponents={this.renderOutcomeMeasuresTable()}
        containerClass="outcome-measures-container"
        dataQa="outcomeMeasuresAccordion"
      />
    );
  }
}

OutcomeMeasures.propTypes = {};

const mapStateToProps = (state) => {
  return {
    outcomeMeasures: state.outcomeMeasures.outcomeMeasures,
    isScheduled: state.outcomeMeasures.isScheduled,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getOutcomeMeasures,
    },
    dispatch
  );
const OutcomeMeasuresContainer = connect(mapStateToProps, mapDispatchToProps)(OutcomeMeasures);

export default withRouter(OutcomeMeasuresContainer);
