export const SET_ROOM_PROPERTIES = 'SET_ROOM_PROPERTIES';
export const GET_ROOMS_ERROR = 'GET_ROOMS_ERROR';
export const GETTING_ROOMS = 'GETTING_ROOMS';

export const REQUEST_CREATE_ROOM = 'REQUEST_CREATE_ROOM';
export const REQUEST_CREATE_ROOM_ERROR = 'REQUEST_CREATE_ROOM_ERROR';
export const RECEIVE_CREATE_ROOM = 'RECEIVE_CREATE_ROOM';
export const RESET_INITIAL_ROOM_STATE = 'RESET_INITIAL_ROOM_STATE';
export const REQUEST_DISCHARGE_AND_TRANSFER = 'REQUEST_DISCHARGE_AND_TRANSFER';
export const RECEIVE_DISCHARGE_AND_TRANSFER = 'RECEIVE_DISCHARGE_AND_TRANSFER';
export const REQUEST_DISCHARGE_AND_TRANSFER_ERROR = 'REQUEST_DISCHARGE_AND_TRANSFER_ERROR';

export const OPEN_STARRED_MESSAGES = 'OPEN_STARRED_MESSAGES';
export const CLOSE_STARRED_MESSAGES = 'CLOSE_STARRED_MESSAGES';

export const OPEN_SHARED_FILES = 'OPEN_SHARED_FILES';
export const CLOSE_SHARED_FILES = 'CLOSE_SHARED_FILES';

export const OPEN_POST_LVS_PROMPT = 'OPEN_POST_LVS_PROMPT';
export const CLOSE_POST_LVS_PROMPT = 'CLOSE_POST_LVS_PROMPT';
