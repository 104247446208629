import { FunctionComponent } from 'react';
import { View, Text, Button, useEmotionTheme, Clipboard, toast } from '@talkspace/react-toolkit';
import { ID_MESSAGE_DATE, ID_MESSAGE_TEXT } from '@/utils/IDConstants';
import { MessageProps } from '../../types';
import { MessageBodyHasClipboardCopy } from '../../entities/EMessage';

const BodyWithClipboardCopy: FunctionComponent<MessageProps> = (props) => {
  const { currentMessage } = props;
  const { text, copyText } = currentMessage.messageBody as MessageBodyHasClipboardCopy;

  const textID = `${ID_MESSAGE_TEXT}-${currentMessage.id}`;
  const messageDateID = `${ID_MESSAGE_DATE}-${currentMessage.id}`;
  const { colors } = useEmotionTheme();
  const handleCopy = () => {
    Clipboard.setString(copyText)
      .then(() => {
        toast('Link Copied', { autoClose: 2000, type: toast.TYPE.SUCCESS });
      })
      .catch(() => {
        toast('Unable to copy link', { autoClose: 2000, type: toast.TYPE.ERROR });
      });
  };
  return (
    <View align="center" style={{ marginTop: 14, marginBottom: 14, maxWidth: 1000 }}>
      <Text
        id={textID}
        style={{
          color: colors.black,
          textAlign: 'center',
          fontSize: 16,
          display: 'inline',
          lineHeight: '22px',
          wordBreak: 'break-word',
          whiteSpace: 'pre-line',
        }}
      >
        {text}
      </Text>
      {copyText && (
        <Button onClick={handleCopy} aria-describedby={`${textID} ${messageDateID}`}>
          Copy link
        </Button>
      )}
    </View>
  );
};

export default BodyWithClipboardCopy;
