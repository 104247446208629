const style = {
  width: '100%',
  color: '#5fc9f8',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textDecoration: 'none',
  display: 'inline-block',
  textOverflow: 'ellipsis',
};

const HyperText = (props) => {
  if (typeof props.children === 'string') {
    const tokens = props.children.split(/\s/);
    return tokens.map((token, i) => {
      const hasSpace = i !== tokens.length - 1;
      const maybeSpace = hasSpace ? ' ' : '';
      if (token.match(/^https?:\/\//)) {
        return (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a key={i} style={style} href={token}>
            {`${token}${maybeSpace}`}
          </a>
        );
      }
      return token + maybeSpace;
    });
  }
  return props.children || null;
};

export default HyperText;
