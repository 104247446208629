import Svg, { Path } from 'svgs';
import { useEmotionTheme } from '../../core/styled';

interface CaringProviderProps {
  width?: number;
  height?: number;
  color?: string;
}

const CaringProvider = ({ width = 24, height = 24, color, ...otherProps }: CaringProviderProps) => {
  const titleText = 'provider with heart';
  const { colors } = useEmotionTheme();
  return (
    <Svg
      title={titleText}
      aria-label={titleText}
      width={width}
      height={height}
      fill="none"
      viewBox="-4 -2 28 28"
      {...otherProps}
    >
      <Path
        d="M9 12A6 6 0 1 0 9 0a6 6 0 0 0 0 12ZM9 2a4 4 0 1 1 0 8 4 4 0 0 1 0-8ZM9 14a9.011 9.011 0 0 0-9 9 1 1 0 1 0 2 0 7 7 0 1 1 14 0 1 1 0 0 0 2 0 9.01 9.01 0 0 0-9-9ZM22 7.875a2.107 2.107 0 0 0-2 2.2 2.107 2.107 0 0 0-2-2.2 2.107 2.107 0 0 0-2 2.2c0 1.73 2.256 3.757 3.38 4.659a.992.992 0 0 0 1.24 0c1.124-.9 3.38-2.929 3.38-4.659a2.107 2.107 0 0 0-2-2.2Z"
        fill={color || colors.darkGreen}
      />
    </Svg>
  );
};

export default CaringProvider;
