import Svg, { Path, G } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

const Checkmark = ({ color = '', ...otherProps }) => {
  const { colors } = useEmotionTheme();
  const titleText = 'check mark';
  return (
    <Svg
      width="15px"
      height="15px"
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 15 15"
      {...otherProps}
    >
      <G
        transform="translate(-333 -520)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
      >
        <Path
          stroke={colors.athensGrey}
          strokeWidth={6}
          d="M336 527.23651L339.748067 530 345 523"
        />
        <Path
          stroke={color || colors.green}
          strokeWidth={2}
          d="M336 527.23651L339.748067 530 345 523"
        />
      </G>
    </Svg>
  );
};

export default Checkmark;
