import { FunctionComponent } from 'react';
import Svg, { G, Path, Rect, Circle } from 'svgs';
import { EmotionStyle, useEmotionTheme } from '../../core/styled';

const TimeOff: FunctionComponent<{
  color?: string;
  width?: string | number;
  height?: string | number;
  style?: EmotionStyle;
  dataQa?: string;
}> = ({ color, width = 18, height = 19, style, dataQa, ...otherProps }) => {
  const { colors } = useEmotionTheme();
  const titleText = 'time off calendar';
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 18 19"
      data-qa={dataQa}
      title={titleText}
      aria-label={titleText}
      style={style}
      {...otherProps}
    >
      <G
        transform="translate(-430 -623) translate(430 621) translate(0 2)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <Rect
          stroke={color || colors.pink}
          strokeWidth={2}
          x={1}
          y={3}
          width={13}
          height={12}
          rx={3.5}
        />
        <Circle
          stroke={colors.white}
          strokeWidth={1.5}
          fill={color || colors.pink}
          cx={12}
          cy={13}
          r={5}
        />
        <Path
          d="M4 1a.5.5 0 00-.5.5v3a.5.5 0 001 0v-3A.5.5 0 004 1zm7 0a.5.5 0 00-.5.5v3a.5.5 0 101 0v-3A.5.5 0 0011 1z"
          stroke={color || colors.pink}
        />
        <Path
          d="M13.297 13l.684.684a.917.917 0 11-1.297 1.297L12 14.297l-.684.684a.917.917 0 11-1.297-1.297l.684-.684-.684-.684a.917.917 0 111.297-1.297l.684.684.684-.684a.917.917 0 111.297 1.297l-.684.684z"
          fill={colors.white}
        />
      </G>
    </Svg>
  );
};

export default TimeOff;
