import { FunctionComponent } from 'react';
import SpinnerView from './SpinnerView';

interface SpinnerProps {
  isLoading: boolean;
}

const Spinner: FunctionComponent<SpinnerProps> = (props) => <SpinnerView {...props} />;

export default Spinner;
