import { View } from '@talkspace/react-toolkit';
import moment from 'moment';
import Checkmark2 from 'chat/components/Icons/Checkmark2';
import styled from '../../core/styled';
import { Booking } from '../types';
import XIcon from '../../../components/Account/XIcon';
import ClientHoldTooltip from '../../../components/common/ClientHoldTooltip';

export type PositiveAction = 'join' | 'confirm';
export type NegativeAction = 'cancel' | 'decline' | 'remove';

const Checkmark = styled(Checkmark2)(({ theme: { colors } }) => {
  return {
    '& path': {
      stroke: colors.forestGreen,
    },
  };
});
const Circle = styled(View)(({ theme: { colors } }) => {
  return {
    width: 10,
    height: 10,
    borderRadius: 5,
    borderStyle: 'solid',
    borderColor: colors.slateGrey,
    borderWidth: 2,
  };
});
const CircleGreen = styled(Circle)(({ theme: { colors } }) => {
  return {
    width: 10,
    height: 10,
    borderRadius: 5,
    borderStyle: 'solid',
    borderColor: colors.a11yVidaLocaGreen,
    borderWidth: 2,
  };
});

export interface BookingUIContext {
  text: string | JSX.Element;
  tooltipText?: JSX.Element;
  Icon: typeof XIcon | typeof Circle;
  positiveAction?: PositiveAction;
  negativeAction?: NegativeAction;
  expiredTip?: string;
}

const extractBookingUIContext = (booking: Booking): BookingUIContext => {
  const { status, timekitBookingState, startTime, scheduledByUserType } = booking;
  const defaultObj: BookingUIContext = {
    text: 'Client cancelled',
    Icon: XIcon,
    positiveAction: undefined,
    negativeAction: 'remove',
    expiredTip: '',
  };
  switch (status) {
    case 'late-cancellation-exception-granted':
    case 'late-cancellation-confirmed':
    case 'client-canceled': {
      return defaultObj;
    }
    case 'admin-canceled': {
      return {
        ...defaultObj,
        text: 'Admin cancelled',
      };
    }
    case 'therapist-canceled': {
      return {
        ...defaultObj,
        text: 'You canceled',
      };
    }
    case 'never-confirmed': {
      return {
        ...defaultObj,
        text: 'Booking expired',
        expiredTip:
          'This session was automatically cancelled because you did not confirm it at least 24 hours before its start time.',
      };
    }
    case 'client-no-show-exception-granted':
    case 'client-no-show': {
      return {
        ...defaultObj,
        text: 'Booking expired',
        expiredTip:
          'You did not attend this session within 5 minutes of the session start time and were considered a no-show. The client attended and retained their session credit.',
      };
    }
    case 'therapist-no-show': {
      return {
        ...defaultObj,
        text: 'Booking expired',
        expiredTip:
          'The client did not attend the live session within 5 minutes of the session start time and was considered a no-show. The client lost this session credit.',
      };
    }
    case 'payer-consolidation': {
      return {
        ...defaultObj,
        text: 'Payer consolidation',
      };
    }
    default: {
      // active
      switch (timekitBookingState) {
        case 'tentative': {
          return {
            ...defaultObj,
            Icon: Circle,
            text:
              scheduledByUserType === 'provider'
                ? 'Waiting for client to confirm'
                : 'Session unconfirmed',
            positiveAction: 'confirm',
            negativeAction: scheduledByUserType === 'client' ? 'decline' : 'cancel',
          };
        }
        case 'declined': {
          return {
            ...defaultObj,
            text: 'You declined',
          };
        }
        case 'new_client_hold': {
          return {
            ...defaultObj,
            Icon: Circle,
            text: 'New client hold',
            tooltipText: ClientHoldTooltip,
          };
        }
        default: {
          // confirmed
          const now = moment();
          const minToStart = moment(startTime).diff(now, 'minutes');

          if (minToStart > 10) {
            return {
              ...defaultObj,
              Icon: Checkmark,
              text: 'Session confirmed',
              negativeAction: 'cancel',
            };
          }
          const text =
            minToStart > 0 ? `Session starts in ${minToStart} min` : 'Session has started';
          return {
            ...defaultObj,
            Icon: CircleGreen,
            text,
            positiveAction: 'join',
            negativeAction: 'cancel',
          };
        }
      }
    }
  }
};

export default extractBookingUIContext;
