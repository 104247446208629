import SwitchCard from './SwitchCard';
import { withVersioning } from '../../utils';
import { VersionMap } from '../../types';

import type { SwitchCardProps } from './types';

const versionMap: VersionMap<React.ComponentProps<typeof SwitchCard>> = {
  DefaultComponent: SwitchCard,
};

export type { SwitchCardProps };

export default withVersioning(versionMap);
