import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const smallPath =
  'M6.3646 2.57685C6.18413 2.14091 5.70835 1.90888 5.25366 2.0331L3.19116 2.5956C2.78335 2.7081 2.49976 3.07841 2.49976 3.50028C2.49976 9.29872 7.20132 14.0003 12.9998 14.0003C13.4216 14.0003 13.7919 13.7167 13.9044 13.3089L14.4669 11.2464C14.5912 10.7917 14.3591 10.3159 13.9232 10.1354L11.6732 9.19794C11.2912 9.03857 10.8482 9.14872 10.588 9.46982L9.64116 10.6253C7.99116 9.84482 6.65522 8.50888 5.87476 6.85888L7.03022 5.91435C7.35132 5.65185 7.46147 5.21122 7.3021 4.82919L6.3646 2.57919V2.57685Z';
const standardPath =
  'M7.15312 3.16521C6.9125 2.58396 6.27812 2.27458 5.67188 2.44021L2.92188 3.19021C2.37813 3.34021 2 3.83396 2 4.39646C2 12.1277 8.26875 18.3965 16 18.3965C16.5625 18.3965 17.0563 18.0183 17.2063 17.4746L17.9563 14.7246C18.1219 14.1183 17.8125 13.484 17.2312 13.2433L14.2312 11.9933C13.7219 11.7808 13.1313 11.9277 12.7844 12.3558L11.5219 13.8965C9.32188 12.8558 7.54063 11.0746 6.5 8.87458L8.04063 7.61521C8.46875 7.26521 8.61562 6.67771 8.40312 6.16833L7.15312 3.16833V3.16521Z';
const majorPath =
  'M16.6797 13.5408C16.0391 13.2674 15.2969 13.4471 14.8555 13.9861L13.5586 15.5721C11.7617 14.5291 10.2617 13.0291 9.21875 11.2322L10.8008 9.93926C11.3398 9.49785 11.5234 8.75566 11.2461 8.11504L9.37109 3.74004C9.07812 3.05254 8.33984 2.66973 7.60938 2.82598L3.23438 3.76348C2.51562 3.91582 2 4.55254 2 5.29082C2 14.3221 8.84375 21.7596 17.6289 22.6932C18.0117 22.7322 18.3945 22.7635 18.7852 22.7791H18.7891C19.0273 22.7869 19.2617 22.7947 19.5 22.7947C20.2383 22.7947 20.875 22.2791 21.0273 21.5604L21.9648 17.1854C22.1211 16.4549 21.7383 15.7166 21.0508 15.4236L16.6758 13.5486L16.6797 13.5408ZM19.2461 20.9158C10.8203 20.7791 4.01172 13.9705 3.87891 5.54473L7.75391 4.7127L9.43359 8.63457L8.03125 9.78301C7.32031 10.365 7.13672 11.3768 7.59766 12.1736C8.80469 14.2557 10.5391 15.99 12.6211 17.1971C13.418 17.658 14.4297 17.4744 15.0117 16.7635L16.1602 15.3611L20.082 17.0408L19.2461 20.9158Z';

const pathConfig: IconConfig = {
  small: {
    default: { path: smallPath },
  },
  standard: {
    default: { path: standardPath },
  },
  major: {
    default: { path: majorPath },
  },
};

const Phone = withDSIconMaker(pathConfig, 'phone');

export default Phone;
