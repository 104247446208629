import { FC } from 'react';
import { connect } from 'react-redux';
import { useParams } from '@/core/routerLib';

import UserActionCard from '../../../../Reusable/UserActionCard/UserActionCard';
import Submenu from '../../../../Reusable/Submenu/Submenu';
import ListItem from '../../../../Reusable/ListItems/ListItem';
import ItemList from '../../../../Reusable/ItemList/ItemList';
import { formatDateTimeShort } from '../../../../../utils/date';
import { getActiveCustomerName } from '../../../../../utils/customerHelper';
import { CustomerInformation } from '../../../../../types/customer';
import useQueryVideoCallHistory from './useQueryVideoCallHistory';
import { VideoCallHistoryItem } from './types';
import useQueryParticipants from '../../../../../hooks/useQueryParticipants';

const formatItem = (item: VideoCallHistoryItem) => {
  return {
    date: formatDateTimeShort(item.date),
    duration: `${Math.max(Math.round(item.duration / 60), 1)} min`,
    type: item.type.charAt(0).toUpperCase() + item.type.slice(1),
  };
};

const getItemKey = (item: VideoCallHistoryItem) => `${item.date}-${item.type}-${item.duration}`;

interface Props {
  customerInformation: CustomerInformation;
}

const VideoCallHistory: FC<Props> = ({ customerInformation }) => {
  const { roomID } = useParams<{ roomID: string }>();

  const { data: participants } = useQueryParticipants(Number(roomID));

  const {
    data: videoCallHistory,
    isError,
    isLoading,
  } = useQueryVideoCallHistory({
    roomID: Number(roomID),
    clientUserID: customerInformation.clientUserID,
  });

  const renderListItem = (item: VideoCallHistoryItem) => (
    <ListItem
      key={getItemKey(item)}
      itemDataOrder={['date', 'duration', 'type']}
      itemDisplayData={formatItem(item)}
      widths={[140, 85, 60]}
    />
  );

  const renderItemList = () => (
    <ItemList
      items={(videoCallHistory || []).map(renderListItem)}
      titles={['Date', 'Duration', 'Type']}
      noDataMessage="No live sessions found"
      widths={[140, 85, 60]}
      cssClass={undefined}
    />
  );

  const renderUserActionCard = () => (
    <UserActionCard
      preferredName={getActiveCustomerName(customerInformation, participants || [])}
      content={renderItemList()}
      buttons={[]}
      isError={isError}
      showSpinner={isLoading}
      spinnerMessage="Loading live session history"
    />
  );

  return (
    <Submenu
      childComponents={[renderUserActionCard()]}
      title="Live session history"
      prevRoute="video-credits"
    />
  );
};

const mapStateToProps = (state) => {
  return {
    customerInformation: state.customerInformation,
  };
};

export default connect(mapStateToProps)(VideoCallHistory);
