import Svg, { Defs, G, Path, Rect } from 'svgs';
import { useEmotionTheme, EmotionStyle } from '../../core/styled';
import { useUniqueID } from '../../hooks/a11yHelper';

interface Props {
  width?: number;
  height?: number;
  color?: string;
  dataQa?: string;
  style?: EmotionStyle;
}

const CalendarThreeDots = ({ width = 24, height = 24, color, dataQa, style, ...props }: Props) => {
  const { colors } = useEmotionTheme();
  const titleText = 'Calendar';
  const clipPathID = useUniqueID('clipPathID');
  return (
    <Svg
      width={width}
      height={height}
      data-qa={dataQa}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 24 24"
      fill="none"
      style={style}
      {...props}
    >
      <G clip-path={`url(#${clipPathID})`}>
        <Path
          d="M17.8333 3.66667H17V2.83333C17 2.61232 16.9122 2.40036 16.7559 2.24408C16.5996 2.0878 16.3877 2 16.1667 2C15.9457 2 15.7337 2.0878 15.5774 2.24408C15.4211 2.40036 15.3333 2.61232 15.3333 2.83333V3.66667H8.66667V2.83333C8.66667 2.61232 8.57887 2.40036 8.42259 2.24408C8.26631 2.0878 8.05435 2 7.83333 2C7.61232 2 7.40036 2.0878 7.24408 2.24408C7.0878 2.40036 7 2.61232 7 2.83333V3.66667H6.16667C5.062 3.66799 4.00296 4.1074 3.22185 4.88852C2.44073 5.66963 2.00132 6.72867 2 7.83333L2 17.8333C2.00132 18.938 2.44073 19.997 3.22185 20.7782C4.00296 21.5593 5.062 21.9987 6.16667 22H17.8333C18.938 21.9987 19.997 21.5593 20.7782 20.7782C21.5593 19.997 21.9987 18.938 22 17.8333V7.83333C21.9987 6.72867 21.5593 5.66963 20.7782 4.88852C19.997 4.1074 18.938 3.66799 17.8333 3.66667ZM3.66667 7.83333C3.66667 7.17029 3.93006 6.53441 4.3989 6.06557C4.86774 5.59673 5.50363 5.33333 6.16667 5.33333H17.8333C18.4964 5.33333 19.1323 5.59673 19.6011 6.06557C20.0699 6.53441 20.3333 7.17029 20.3333 7.83333V8.66667H3.66667V7.83333ZM17.8333 20.3333H6.16667C5.50363 20.3333 4.86774 20.0699 4.3989 19.6011C3.93006 19.1323 3.66667 18.4964 3.66667 17.8333V10.3333H20.3333V17.8333C20.3333 18.4964 20.0699 19.1323 19.6011 19.6011C19.1323 20.0699 18.4964 20.3333 17.8333 20.3333Z"
          fill={color || colors.permaTalkspaceDarkGreen}
        />
        <Path
          d="M12 15.75C12.6904 15.75 13.25 15.1904 13.25 14.5C13.25 13.8096 12.6904 13.25 12 13.25C11.3096 13.25 10.75 13.8096 10.75 14.5C10.75 15.1904 11.3096 15.75 12 15.75Z"
          fill={color || colors.permaTalkspaceDarkGreen}
        />
        <Path
          d="M7.83398 15.75C8.52434 15.75 9.08398 15.1904 9.08398 14.5C9.08398 13.8096 8.52434 13.25 7.83398 13.25C7.14363 13.25 6.58398 13.8096 6.58398 14.5C6.58398 15.1904 7.14363 15.75 7.83398 15.75Z"
          fill={color || colors.permaTalkspaceDarkGreen}
        />
        <Path
          d="M16.166 15.75C16.8564 15.75 17.416 15.1904 17.416 14.5C17.416 13.8096 16.8564 13.25 16.166 13.25C15.4757 13.25 14.916 13.8096 14.916 14.5C14.916 15.1904 15.4757 15.75 16.166 15.75Z"
          fill={color || colors.permaTalkspaceDarkGreen}
        />
      </G>
      <Defs>
        <clipPath id={`${clipPathID}`}>
          <Rect width="20" height="20" fill="white" transform="translate(2 2)" />
        </clipPath>
      </Defs>
    </Svg>
  );
};

export default CalendarThreeDots;
