import Globe from './Globe';
import { GlobeV1 } from './GlobeV1';
import { VersionMap } from '../../types';
import { withVersioning } from '../../utils';

const versionMap: VersionMap<React.ComponentProps<typeof Globe>> = {
  '1.0.0': GlobeV1,
  DefaultComponent: Globe,
};

export default withVersioning(versionMap);
