import ModalityIcon from './ModalityIcon';

import { withVersioning } from '../../utils';
import { VersionMap } from '../../types';

/**
 * TODO consider using LiveSessionIconSmallV2 as the v0 component for compatibility
 */

const versionMap: VersionMap<React.ComponentProps<typeof ModalityIcon>> = {
  '2.0.0': ModalityIcon,
  '1.0.0': ModalityIcon,
  DefaultComponent: ModalityIcon,
};

export default withVersioning(versionMap);
