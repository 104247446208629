import View from '../View';
import RedX from '../icons/RedX';
import { ExtraHuge, Large } from '../Typography';

export default function UnhandledErrorMessage() {
  return (
    <View align="center">
      <RedX style={{ marginBottom: 30 }} />
      <ExtraHuge style={{ marginBottom: 10 }}>Something went wrong</ExtraHuge>
      <Large>Please try again later.</Large>
    </View>
  );
}
