import { FunctionComponent } from 'react';
import * as React from 'react';
import {
  useUniqueID,
  Image,
  ImageProps,
  TouchableViewProps,
  HiddenText,
  IconProps,
} from '@talkspace/react-toolkit';
import IconButton from '@talkspace/react-toolkit/src/designSystems/components/IconButton';
import styled from '@/core/styled/styled';

interface InputIconProps {
  hide?: boolean;
  height?: number;
  Component?: React.VFC<IconProps>;
  ariaDescribedByText?: string;
  ariaControls?: string;
  ariaExpanded?: boolean;
  disabled?: boolean;
  colorType?: IconProps['colorType'];
}

const StyledImage = styled(Image)<Partial<InputIconProps>>(({ height }) => {
  return {
    width: '100%',
    height: height || 40,
    objectFit: 'contain',
  };
});

const InputIcon: FunctionComponent<InputIconProps & ImageProps & TouchableViewProps> = ({
  onPress,
  Component,
  ariaDescribedByText,
  hide = false,
  ariaControls,
  ariaExpanded,
  disabled,
  dataQa,
  alt,
  colorType,
  ...otherProps
}) => {
  const ariaDescribedByID = useUniqueID('ariaDescribedByID');
  return (
    <>
      <IconButton
        Icon={
          Component ? (
            <Component
              aria-hidden="true"
              colorType={colorType || 'brand'}
              state={disabled ? 'disabled' : 'default'}
              size="huge"
            />
          ) : (
            <StyledImage aria-hidden="true" {...otherProps} />
          )
        }
        size="large"
        onPress={(e) => {
          if (!disabled && onPress) {
            onPress(e);
          }
        }}
        hide={hide}
        aria-describedby={ariaDescribedByText ? ariaDescribedByID : undefined}
        aria-controls={ariaControls}
        aria-expanded={ariaExpanded}
        aria-haspopup
        dataQa={dataQa}
      />
      <HiddenText id={ariaDescribedByID}>{ariaDescribedByText}</HiddenText>
    </>
  );
};
export default InputIcon;
