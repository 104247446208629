import { useEmotionTheme } from '@talkspace/react-toolkit';
import { VoidFunctionComponent } from 'react';
import Svg, { Path, Circle } from 'svgs';

interface ClientInformationIconProps {
  color?: string;
}

const ClientInformationIcon: VoidFunctionComponent<ClientInformationIconProps> = ({
  color,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();

  return (
    <Svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <Circle cx="8" cy="8" r="7.25" stroke={color || colors.slateGrey} stroke-width="1.5" />
      <Circle cx="8" cy="7" r="3" fill={color || colors.slateGrey} />
      <Path
        d="M13 14C10.5 15 10.7614 15 8 15C5.23858 15 4.5 14.5 3 14C3 12.3431 5.23858 11 8 11C10.7614 11 13 12.3431 13 14Z"
        fill={color || colors.slateGrey}
      />
    </Svg>
  );
};

export default ClientInformationIcon;
