import { VoidFunctionComponent } from 'react';
import Svg, { G, Path } from 'svgs';
import { useEmotionTheme, EmotionStyle } from '../../core/styled';

interface BaseTileProp {
  width?: number;
  height?: number;
  style?: EmotionStyle;
  color?: string;
  titleText?: string;
}

interface TilesProp extends BaseTileProp {
  isActive?: boolean;
}

const Active: VoidFunctionComponent<BaseTileProp> = ({
  width,
  height,
  style,
  color,
  titleText,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();

  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      style={style}
      {...otherProps}
    >
      <G fill="none" fillRule="evenodd">
        <Path fill="#FFF" d="M-27-107h1214v996H-27z" />
        <Path
          d="M0 11.07h8v-11H2.27A2.27 2.27 0 0 0 0 2.34v8.73Zm2.27 8H8v-6H0v3.73a2.27 2.27 0 0 0 2.27 2.27Zm7.73 0h5.73A2.27 2.27 0 0 0 18 16.8V9.07h-8v10Zm0-19v7h8V2.34A2.27 2.27 0 0 0 15.73.07H10Z"
          fill={color || colors.permaEden}
          fillRule="nonzero"
        />
      </G>
    </Svg>
  );
};

const InActive: VoidFunctionComponent<BaseTileProp> = ({
  width,
  height,
  style,
  color,
  titleText,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  return (
    <Svg width={width} height={height} aria-label={titleText} style={style} {...otherProps}>
      <G fill="none" fillRule="evenodd">
        <Path fill="#FFF" d="M-27-102h1214v996H-27z" />
        <Path
          d="M.75 10.25h6.5V.75H2.27C1.43.75.75 1.43.75 2.27v7.98Zm6.5 8v-4.5H.75v2.98c0 .84.68 1.52 1.52 1.52h4.98Zm3.5 0h4.98c.84 0 1.52-.68 1.52-1.52V9.75h-6.5v8.5Zm0-17.5v5.5h6.5V2.27c0-.84-.68-1.52-1.52-1.52h-4.98Z"
          fill={color || colors.permaOysterBay}
          fillRule="nonzero"
          stroke={colors.permaEden}
          strokeWidth={1.5}
        />
      </G>
    </Svg>
  );
};

const Tiles: VoidFunctionComponent<TilesProp> = ({
  color,
  isActive = false,
  width = 18,
  height = 20,
  style = {},
  titleText = 'dashboard',
  ...otherProps
}) =>
  isActive ? (
    <Active
      width={width}
      height={height}
      titleText={titleText}
      color={color}
      style={style}
      {...otherProps}
    />
  ) : (
    <InActive
      width={width}
      height={height}
      titleText={titleText}
      color={color}
      style={style}
      {...otherProps}
    />
  );

export default Tiles;
