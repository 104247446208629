import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const standardPath =
  'M16.1686 2.50049H5.44142C4.26063 2.50049 3.29999 3.46113 3.29999 4.64192V15.3591C3.29999 16.5398 4.26063 17.5005 5.44142 17.5005H16.1586C17.3393 17.5005 18.3 16.5398 18.3 15.3591V4.64192C18.3 3.46113 17.3393 2.50049 16.1586 2.50049H16.1686ZM16.6989 15.3691C16.6989 15.6593 16.4588 15.9094 16.1586 15.9094H5.44142C5.15122 15.9094 4.90106 15.6693 4.90106 15.3691V4.64192C4.90106 4.35172 5.14122 4.10156 5.44142 4.10156H16.1586C16.4488 4.10156 16.6989 4.34172 16.6989 4.64192V15.3591V15.3691ZM14.5575 9.55519V10.3857C14.5575 10.796 14.2273 11.1362 13.807 11.1362H11.9858V13.0075C11.9858 13.4278 11.6456 13.758 11.2353 13.758H10.3947C9.98444 13.758 9.64422 13.4178 9.64422 13.0075V11.1362H7.80299C7.39272 11.1362 7.05249 10.796 7.05249 10.3857V9.55519C7.05249 9.14492 7.39272 8.80469 7.80299 8.80469H9.64422V7.0135C9.64422 6.59322 9.98444 6.263 10.3947 6.263H11.2353C11.6456 6.263 11.9858 6.60322 11.9858 7.0135V8.8147H13.797C14.2173 8.80469 14.5475 9.14492 14.5475 9.55519H14.5575Z';
const standardFilledPath =
  'M16.1686 2.50049H5.44142C4.26063 2.50049 3.29999 3.46113 3.29999 4.64192V15.3591C3.29999 16.5398 4.26063 17.5005 5.44142 17.5005H16.1586C17.3393 17.5005 18.3 16.5398 18.3 15.3591V4.64192C18.3 3.46113 17.3393 2.50049 16.1586 2.50049H16.1686ZM14.5575 10.3857C14.5575 10.796 14.2273 11.1362 13.807 11.1362H11.9858V13.0075C11.9858 13.4278 11.6456 13.758 11.2353 13.758H10.3947C9.98444 13.758 9.64422 13.4178 9.64422 13.0075V11.1362H7.80299C7.39272 11.1362 7.05249 10.796 7.05249 10.3857V9.55519C7.05249 9.14492 7.39272 8.80469 7.80299 8.80469H9.64422V7.0135C9.64422 6.59322 9.98444 6.263 10.3947 6.263H11.2353C11.6456 6.263 11.9858 6.60322 11.9858 7.0135V8.8147H13.797C14.2173 8.80469 14.5475 9.14492 14.5475 9.55519V10.3857H14.5575Z';
const majorPath =
  'M18.4423 3.79297H5.56971C4.15277 3.79297 3 4.94574 3 6.36268V19.2233C3 20.6402 4.15277 21.793 5.56971 21.793H18.4303C19.8472 21.793 21 20.6402 21 19.2233V6.36268C21 4.94574 19.8472 3.79297 18.4303 3.79297H18.4423ZM19.0787 19.2353C19.0787 19.5835 18.7905 19.8837 18.4303 19.8837H5.56971C5.22148 19.8837 4.92128 19.5955 4.92128 19.2353V6.36268C4.92128 6.01445 5.20947 5.71425 5.56971 5.71425H18.4303C18.7785 5.71425 19.0787 6.00244 19.0787 6.36268V19.2233V19.2353ZM16.509 12.2586V13.2553C16.509 13.7476 16.1127 14.1559 15.6084 14.1559H13.4229V16.4014C13.4229 16.9057 13.0147 17.302 12.5223 17.302H11.5137C11.0213 17.302 10.6131 16.8937 10.6131 16.4014V14.1559H8.4036C7.91127 14.1559 7.503 13.7476 7.503 13.2553V12.2586C7.503 11.7663 7.91127 11.358 8.4036 11.358H10.6131V9.20858C10.6131 8.70424 11.0213 8.30798 11.5137 8.30798H12.5223C13.0147 8.30798 13.4229 8.71625 13.4229 9.20858V11.37H15.5964C16.1007 11.358 16.497 11.7663 16.497 12.2586H16.509Z';
const hugePath =
  'M6.715 4.865C5.685 4.865 4.855 5.695 4.855 6.725V25.295C4.855 26.325 5.685 27.155 6.715 27.155H25.285C26.315 27.155 27.145 26.325 27.145 25.295V6.715C27.145 5.685 26.315 4.855 25.285 4.855H6.715V4.865ZM3.005 6.715C3.005 4.675 4.675 3.005 6.715 3.005H25.285C27.335 3.005 28.995 4.675 28.995 6.715V25.285C28.995 27.335 27.325 28.995 25.285 28.995H6.715C4.665 28.995 3.005 27.325 3.005 25.285V6.715ZM10.005 14.825V17.055C10.005 17.465 10.345 17.805 10.755 17.805H14.145V21.245C14.145 21.655 14.475 21.995 14.895 21.995H17.145C17.565 22.005 17.895 21.675 17.895 21.255V17.825L21.255 17.805C21.665 17.805 22.005 17.465 22.005 17.055V14.825C22.005 14.405 21.665 14.075 21.255 14.075L17.905 14.095V10.765C17.905 10.355 17.575 10.015 17.155 10.015H14.905C14.485 10.005 14.155 10.335 14.155 10.755V14.075H10.765C10.355 14.075 10.015 14.415 10.015 14.825H10.005Z';

const pathConfig: IconConfig = {
  standard: {
    filled: {
      path: standardFilledPath,
    },
    default: {
      path: standardPath,
    },
  },
  major: {
    default: {
      path: majorPath,
    },
  },
  huge: {
    default: {
      path: hugePath,
    },
  },
};

const SquarePlus = withDSIconMaker(pathConfig, 'plus inside square');

export default SquarePlus;
