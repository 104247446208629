import trackEvent from '../../modules/analytics/trackEvent';

interface BonusVizEventProps {
  selectedYear: number;
  selectedMonth: number;
}

const Analytics = {
  trackBonusVizPageLoadEvent: () => {
    trackEvent('BonusVizPageLoad', { actionName: 'earningsInteraction' });
  },
  trackBonusVizDropdownMonthChangedEvent: ({ selectedYear, selectedMonth }: BonusVizEventProps) => {
    trackEvent(
      'BonusVizDropdownMonthChanged',
      { actionName: 'earningsInteraction' },
      {
        selectedYear,
        selectedMonth,
      }
    );
  },
  trackBonusVizMonthlyBonusFetchedEvent: ({ selectedYear, selectedMonth }: BonusVizEventProps) => {
    trackEvent(
      'BonusVizMonthlyBonusFetched',
      { actionName: 'earningsInteraction' },
      {
        selectedYear,
        selectedMonth,
      }
    );
  },
  trackBonusVizAvailabilityButtonClickedEvent: ({
    selectedYear,
    selectedMonth,
  }: BonusVizEventProps) => {
    trackEvent(
      'BonusVizAvailabilityButtonClicked',
      { actionName: 'earningsInteraction' },
      {
        selectedYear,
        selectedMonth,
      }
    );
  },
  trackBonusVizModalAvailabilityButtonClickedEvent: ({
    selectedYear,
    selectedMonth,
  }: BonusVizEventProps) => {
    trackEvent(
      'BonusVizModalAvailabilityButtonClicked',
      { actionName: 'earningsInteraction' },
      {
        selectedYear,
        selectedMonth,
      }
    );
  },
  trackBonusVizGrowCaseloadButtonClickedEvent: ({
    selectedYear,
    selectedMonth,
  }: BonusVizEventProps) => {
    trackEvent(
      'BonusVizGrowCaseloadButtonClicked',
      { actionName: 'earningsInteraction' },
      {
        selectedYear,
        selectedMonth,
      }
    );
  },
  trackBonusVizModalGrowCaseloadButtonClickedEvent: ({
    selectedYear,
    selectedMonth,
  }: BonusVizEventProps) => {
    trackEvent(
      'BonusVizModalGrowCaseloadButtonClicked',
      { actionName: 'earningsInteraction' },
      {
        selectedYear,
        selectedMonth,
      }
    );
  },
  trackBonusVizModalClinicalEngagementBonusButtonClickedEvent: ({
    selectedYear,
    selectedMonth,
  }: BonusVizEventProps) => {
    trackEvent(
      'BonusVizModalClinicalEngagementBonusButtonClicked',
      { actionName: 'earningsInteraction' },
      {
        selectedYear,
        selectedMonth,
      }
    );
  },
  trackBonusVizModalPayoutScheduleButtonClickedEvent: ({
    selectedYear,
    selectedMonth,
  }: BonusVizEventProps) => {
    trackEvent(
      'BonusVizModalPayoutScheduleButtonClicked',
      { actionName: 'earningsInteraction' },
      {
        selectedYear,
        selectedMonth,
      }
    );
  },
  trackBonusVizBreakdownModalOpenedEvent: ({ selectedYear, selectedMonth }: BonusVizEventProps) => {
    trackEvent(
      'BonusVizBreakdownModalOpened',
      { actionName: 'earningsInteraction' },
      {
        selectedYear,
        selectedMonth,
      }
    );
  },
};

export default Analytics;
