import Card from '../Card';
import CardContent from '../CardContent';
import Switch from '../Switch';
import { SwitchCardProps } from './types';

const SwitchCard = (props: SwitchCardProps) => {
  const { cardVariant, contentVariant, isOn, handleOnPress, disabled, forceIdleState, dataQa } =
    props;

  return (
    <Card
      variant={cardVariant}
      padding="slim"
      disabled={disabled}
      forceIdleState={forceIdleState}
      dataQa={dataQa}
    >
      <CardContent
        variant={contentVariant}
        RightCol={<Switch isOn={isOn} handleOnPress={handleOnPress} dataQa={`${dataQa}Switch`} />}
        {...props}
      />
    </Card>
  );
};

export default SwitchCard;
