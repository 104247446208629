import { Standard, styled, View, useEmotionTheme } from '@talkspace/react-toolkit';

import { useFlags } from 'launchDarkly/FlagsProvider';
import TimeIcon from '../../../../../../PaymentReports/Icons/TimeIcon';

const Label = styled(Standard)(({ theme: { colors } }) => {
  return {
    color: colors.purple600,
    fontSize: 14,
    fontWeight: 400,
  };
});

const Container = styled(View)(({ theme: { colors } }) => {
  return {
    display: 'flex',
    padding: 12,
    width: 347,
    height: 60,
    background: colors.greyBubble,
    borderRadius: 7,
    marginTop: 11,
    marginBottom: 16,
    alignItems: 'center',
  };
});

const SmartNoteNotAvailableBanner = ({ modality }: { modality: 'live' | 'messaging' }) => {
  const { colors } = useEmotionTheme();
  const { accessUnsubmittedSessions: { minWordsPerSession } = {} } = useFlags();
  return (
    <Container row>
      <View style={{ marginTop: 3, marginRight: 12 }}>
        <TimeIcon color={colors.darkBlue} />
      </View>

      {modality === 'messaging' ? (
        <Label>
          In order to use the Smart Note tool, this session must have at least {minWordsPerSession}{' '}
          words.
        </Label>
      ) : (
        <Label>Smart Note will be available after this live session is over</Label>
      )}
    </Container>
  );
};

export default SmartNoteNotAvailableBanner;
