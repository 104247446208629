import { useQuery } from 'react-query';
import apiWrapper from '../utils/apiWrapper';
import apiHelper from '../utils/api';
import { presentingProblemsQueryKey } from './queryKeys';

interface PresentingProblemsResponse {
  id: number;
  value: number;
  label: string;
}

const fetchPresentingProblems = (roomID: string, clientUserID?: number) => async () => {
  const { data } = await apiWrapper.get(
    `${apiHelper().apiEndpoint}/api/v1/rooms/${roomID}/clients/${clientUserID}/presenting-problems`
  );
  return data.data.map(({ id, value, presentingProblemID }) => {
    return {
      id,
      value: presentingProblemID,
      label: value,
    };
  });
};

const useQueryPresentingProblems = (roomID: string, clientUserID?: number) =>
  useQuery<PresentingProblemsResponse[], Error>({
    queryKey: presentingProblemsQueryKey(roomID, clientUserID),
    queryFn: fetchPresentingProblems(roomID, clientUserID),
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!clientUserID,
  });

export default useQueryPresentingProblems;
