import { Component } from 'react';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import './ListItem.css';
import ThreeDots from '../../Icons/ThreeDots';

const renderStringOrComponent = (item) => (typeof item === 'function' ? item() : item || '');

class ListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHover: false,
      isOpen: false,
    };
    this.mouseEnterHandler = this.mouseEnterHandler.bind(this);
    this.mouseLeaveHandler = this.mouseLeaveHandler.bind(this);
  }

  mouseEnterHandler(e) {
    this.setState({ isHover: true });
  }

  mouseLeaveHandler(e) {
    this.setState({ isHover: false });
  }

  toggleSearchOpen = (event) => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  render() {
    const {
      itemDataOrder,
      itemDisplayData,
      item,
      actions,
      widths,
      status,
      icon,
      itemStyle,
      dropdownStyle,
    } = this.props;
    return (
      <div className={status ? 'list-item' : 'list-item grayed'} style={itemStyle}>
        {itemDataOrder.map((attr, key) => (
          <div
            style={{
              width: (widths && widths[key]) || 100,
            }}
            key={key}
          >
            {renderStringOrComponent(itemDisplayData[attr])}
          </div>
        ))}
        {actions && actions.length && (
          <div
            className="list-item-dropdown"
            style={{
              paddingTop: 0,
              height: 19,
              ...dropdownStyle,
            }}
          >
            <DropdownButton
              bsStyle="link"
              title={<ThreeDots active={this.state.isHover || this.state.isOpen} />}
              noCaret
              id="list-item-dropdown"
              onMouseEnter={this.mouseEnterHandler}
              onMouseLeave={this.mouseLeaveHandler}
              onClick={this.toggleSearchOpen}
              open={this.state.isOpen}
            >
              {actions.map((action, key) => (
                <MenuItem
                  key={key}
                  eventKey={key}
                  onSelect={() => action.method(item)}
                  className={action.cssClass}
                >
                  {action.text}
                </MenuItem>
              ))}
            </DropdownButton>
          </div>
        )}
        {icon && (
          <div className={`list-item-icon ${icon.type}`}>
            <i className={`fa ${icon.className}`} />
          </div>
        )}
      </div>
    );
  }
}

export default ListItem;
