import { Breakpoint, BreakpointValues } from '../../../types';
import { GridConfig, HybridType } from './gridTypes';

export const standardBreakpoints: BreakpointValues = {
  xs: 0,
  sm: 480,
  md: 768,
  lg: 1024,
  xl: 1280,
};

export function getBreakpoints(hybridType?: HybridType): BreakpointValues {
  if (hybridType) {
    // No breakpoints for hybrid
    return {
      xs: 360,
      sm: 0,
      md: 0,
      lg: 0,
      xl: 0,
    } as BreakpointValues;
  }
  return standardBreakpoints;
}

export function getContainerMaxWidth(hybridType?: HybridType): BreakpointValues {
  if (hybridType) {
    if (hybridType === 'left')
      return {
        xs: 360,
        sm: 360,
        md: 360,
        lg: 360,
        xl: 360,
      } as BreakpointValues;

    return {
      xs: 1280,
      sm: 1280,
      md: 1280,
      lg: 1280,
      xl: 1280,
    } as BreakpointValues;
  }
  return standardBreakpoints;
}

export function getBreakpoint(
  width: number,
  hybridType?: HybridType,
  breakpoints = standardBreakpoints
): Breakpoint {
  switch (true) {
    case hybridType === 'left':
      return Breakpoint.xs;
    case hybridType === 'right':
      return Breakpoint.xs;
    case width < breakpoints[Breakpoint.sm]:
      return Breakpoint.xs;
    case width < breakpoints[Breakpoint.md]:
      return Breakpoint.sm;
    case width < breakpoints[Breakpoint.lg]:
      return Breakpoint.md;
    case width < breakpoints[Breakpoint.xl]:
      return Breakpoint.lg;
    default:
      return Breakpoint.xl;
  }
}

export function getGridConfig(breakpoint: Breakpoint, hybrid?: HybridType): GridConfig {
  if (hybrid) {
    if (hybrid === 'left') {
      return { columns: 4, sideMargin: 16, gutter: 16 };
    }
    return { columns: 8, sideMargin: 16, gutter: 16 };
  }
  switch (breakpoint) {
    case Breakpoint.xs:
      return { columns: 4, sideMargin: 16, gutter: 8 };
    case Breakpoint.sm:
      return { columns: 8, sideMargin: 24, gutter: 16 };
    case Breakpoint.md:
      return { columns: 8, sideMargin: 32, gutter: 24 };
    case Breakpoint.lg:
      return { columns: 12, sideMargin: 32, gutter: 24 };
    case Breakpoint.xl:
    default:
      return { columns: 12, sideMargin: 40, gutter: 24 };
  }
}
