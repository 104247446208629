import { useEffect, useRef } from 'react';

import { useVideoCallActions } from './videoCallContext';

const TEN_SECONDS_IN_MS = 10_000;

export interface ClosedCaptionsReceivedPayload {
  captionsText: string;
  isPartial: boolean;
  userID: number;
}

function isClosedCaptionsReceivedPayload(obj: unknown): obj is ClosedCaptionsReceivedPayload {
  if (typeof obj === 'object' && obj !== null) {
    return (
      'captionsText' in obj &&
      typeof (obj as { captionsText?: unknown }).captionsText === 'string' &&
      'isPartial' in obj &&
      typeof (obj as { isPartial?: unknown }).isPartial === 'boolean' &&
      'userID' in obj &&
      typeof (obj as { userID?: unknown }).userID === 'number'
    );
  }
  return false;
}

interface ISocketService {
  on: (ev: string, cb: (data: unknown) => void) => void;
  off: (ev: string, cb: (data: unknown) => void) => void;
}

export default function useClosedCaptionsReceivedSocket(
  socketService: ISocketService,
  currentUserID?: number
) {
  const timeoutIDRef = useRef(0);

  const { setClosedCaptions } = useVideoCallActions();

  useEffect(() => {
    const handleClosedCaptionsReceived = (params: unknown) => {
      if (!isClosedCaptionsReceivedPayload(params)) {
        return;
      }

      // Ignore the captions of the self
      if (!currentUserID || params.userID === currentUserID) {
        return;
      }

      setClosedCaptions(params.captionsText);

      if (timeoutIDRef.current) {
        clearTimeout(timeoutIDRef.current);
      }

      timeoutIDRef.current = window.setTimeout(() => {
        setClosedCaptions('');
        timeoutIDRef.current = 0;
      }, TEN_SECONDS_IN_MS);
    };

    socketService.on('closedCaptionsReceived', handleClosedCaptionsReceived);

    return () => {
      socketService.off('closedCaptionsReceived', handleClosedCaptionsReceived);
      setClosedCaptions('');
      if (timeoutIDRef.current) {
        clearTimeout(timeoutIDRef.current);
        timeoutIDRef.current = 0;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserID]);
}
