import { useState } from 'react';

interface EventHandlers {
  onMouseEnter(): void;
  onMouseLeave(): void;
}

export default function useHover(): [boolean, EventHandlers] {
  const [isHovered, setIsHovered] = useState(false);

  return [
    isHovered,
    {
      onMouseEnter: () => {
        setIsHovered(true);
      },
      onMouseLeave: () => {
        setIsHovered(false);
      },
    },
  ];
}
