import { useMutation, useQueryClient } from 'react-query';

import apiHelper from '@/core/api/apiHelper';
import apiWrapper from '@/core/api/apiWrapper';

import { TherapistHomeAddress } from '../../types/account';
import { therapistHomeAddressQueryKey } from './queryKeys';

interface UpdateTherapistHomeAddressParams {
  therapistUserID: number;
  data: TherapistHomeAddress;
}

const updateTherapistHomeAddress = async (
  params: UpdateTherapistHomeAddressParams
): Promise<void> => {
  const { therapistUserID, data } = params;

  await apiWrapper.post(
    `${apiHelper().apiEndpoint}/v3/therapists/${therapistUserID}/home-address`,
    data
  );
};

export default function useMutationUpdateTherapistHomeAddress() {
  const queryClient = useQueryClient();

  return useMutation<void, Error, UpdateTherapistHomeAddressParams>(updateTherapistHomeAddress, {
    onSuccess: async (_, { therapistUserID }) => {
      await queryClient.invalidateQueries({
        queryKey: therapistHomeAddressQueryKey(therapistUserID),
      });
    },
  });
}
