import { VoidFunctionComponent } from 'react';
import Svg, { Path, G } from 'svgs';

import { useEmotionTheme, EmotionStyle } from '../../core/styled';

interface Props {
  style?: EmotionStyle;
  color?: string;
  width?: number;
  height?: number;
  rotation?: number;
}

const CaretDown: VoidFunctionComponent<Props> = ({
  style = {},
  color,
  width = 16,
  height = 16,
  rotation = 0,
}) => {
  const { colors } = useEmotionTheme();
  return (
    <Svg
      style={style}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <G transform={`rotate(${rotation} 6.5 10.5)`}>
        <Path
          d="M4 6L8.00296 10L12.0038 6"
          stroke={color || colors.accessibilityGreenDark}
          strokeWidth="2"
          strokeLinecap="round"
        />
      </G>
    </Svg>
  );
};

export default CaretDown;
