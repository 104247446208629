import { forwardRef, useEffect } from 'react';

import {
  useEmotionTheme,
  View,
  useShareForwardedRef,
  addWebAnimation,
  webOnlyStyle,
} from '@talkspace/react-toolkit';

export const TRANSITION_TIME_SECONDS = 0.7;
export const TRANSITION_TIMING_FUNCTION = 'cubic-bezier(.57,0,.32,1)';

interface Props {
  children: React.ReactNode;
}
const VideoCallTrayPopup = forwardRef<HTMLDivElement, Props>(({ children }, ref) => {
  const { colors } = useEmotionTheme();
  const trayRef = useShareForwardedRef(ref);

  useEffect(() => {
    addWebAnimation({
      ref: trayRef,
      transform: 'translateY(0)',
      timingFunction: TRANSITION_TIMING_FUNCTION,
      durationSeconds: TRANSITION_TIME_SECONDS,
    });
  }, [trayRef]);

  return (
    <View
      ref={trayRef}
      style={{
        width: '100%',
        color: colors.white,
        backgroundColor: colors.mirage,
        borderRadius: '10px 10px 0 0',
        padding: '25px 20px',
        ...webOnlyStyle({
          transform: 'translateY(100%)',
        }),
      }}
    >
      {children}
    </View>
  );
});

export default VideoCallTrayPopup;
