import React from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Big, View, useWindowWidthState, Spinner, useEmotionTheme } from '@talkspace/react-toolkit';

import styled from '../../../modules/core/styled';

const GreyBox = styled(View)(
  ({
    theme: {
      colors,
      window: { isMobile },
    },
  }) => {
    return {
      padding: 15,
      borderWidth: 1,
      borderRadius: 8,
      borderStyle: 'solid',
      borderColor: colors.permaPowderBlue,
      backgroundColor: colors.permaGhostWhite,
      minHeight: 119,
      width: '100%',
      textAlign: isMobile ? 'center' : 'left',
      alignItems: 'center',
    };
  }
);

const TextCol = styled(View)(() => {
  return {
    marginLeft: 15,
    justifyContent: 'center',
    flexShrink: 'initial',
  };
});

const BigNormal = styled(Big)(() => {
  return {
    fontWeight: 'normal',
  };
});

const LinkText = styled(Big)(({ theme: { colors } }) => {
  return {
    color: colors.permaDarkCyan,
    cursor: 'pointer',
  };
});

interface Props {
  title: string;
  subtitle: string;
  icon?: JSX.Element;
  linkText?: string;
  linkPath?: string;
  isLoading?: boolean;
  handleOnPress?: (value?: boolean) => void;
  isW2: 1 | 0;
}

const DashboardTablePlaceholderComponent = ({
  title,
  subtitle,
  icon,
  linkText,
  linkPath,
  isLoading = false,
  handleOnPress,
  isW2,
}: Props) => {
  const history = useHistory();
  const { colors } = useEmotionTheme();
  const { isMobile } = useWindowWidthState();
  const isNotW2 = isW2 === 0;
  const onPress = linkPath
    ? () => {
        history.push(linkPath);
      }
    : handleOnPress;
  if (isLoading) {
    return (
      <GreyBox row justify="center">
        <Spinner
          primaryColor={colors.permaBaliHai}
          secondaryColor={colors.permaGhostWhite}
          style={{ width: 26, height: 26 }}
        />
      </GreyBox>
    );
  }
  if (isMobile) {
    return (
      <GreyBox>
        <View row>
          <View>{icon}</View>
        </View>
        <View row>
          <TextCol>
            <Big variant="bigDarkGrey">{title}</Big>
            <BigNormal style={{ fontWeight: 'normal' }} variant="bigDarkGrey">
              {subtitle}
              {isNotW2 && linkText && (
                <LinkText inline onPress={onPress}>
                  {linkText}
                </LinkText>
              )}
            </BigNormal>
          </TextCol>
        </View>
      </GreyBox>
    );
  }
  return (
    <GreyBox row>
      <View>{icon}</View>
      <TextCol>
        <Big variant="bigDarkGrey">{title}</Big>

        <BigNormal style={{ fontWeight: 'normal' }} variant="bigDarkGrey">
          {subtitle}
          {isNotW2 && linkText && (
            <LinkText inline onPress={onPress}>
              {linkText}
            </LinkText>
          )}
        </BigNormal>
      </TextCol>
    </GreyBox>
  );
};

const mapStateToProps = (state) => {
  return {
    isW2: state.availability.isW2,
  };
};

const DashboardTablePlaceholder = connect(mapStateToProps)(DashboardTablePlaceholderComponent);

export default DashboardTablePlaceholder;
