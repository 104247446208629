import { FunctionComponent } from 'react';
import uniqBy from 'lodash/uniqBy';
import { License } from 'ts-frontend/types';
import styled from '../../core/styled';
import View from '../View';
import { Large, Tiny } from '../Typography';

const LicenseText = styled(Large)(({ theme: { colors } }) => {
  return { fontSize: 15, color: colors.placeholderGrey, marginRight: 6 };
});

const GreenDot = styled(View)(({ theme: { colors } }) => {
  return { height: 10, width: 10, borderRadius: 5, marginRight: 5, backgroundColor: colors.green };
});

// TODO: should be an actual tooltip at some point
const LicenseTooltip: FunctionComponent<{
  license: License;
  hasComma: boolean;
}> = ({ license, hasComma }) => (
  <LicenseText key={license.typeId}>
    {license.type}
    {hasComma ? ', ' : ''}
  </LicenseText>
);

const LicenseAndAvailability: FunctionComponent<{
  licenses: License[];
  availableNow: boolean;
}> = ({ licenses, availableNow }) => (
  <View row align="center" style={{ marginBottom: 6, marginTop: 3 }}>
    {uniqBy(licenses, 'type')
      .slice(0, 3)
      .map((l, i, arr) => (
        <LicenseTooltip
          license={l}
          key={l.number}
          hasComma={i !== arr.length - 1 && arr.length !== 1}
        />
      ))}
    {availableNow && (
      <>
        <GreenDot />
        <Tiny>Online now</Tiny>
      </>
    )}
  </View>
);

export default LicenseAndAvailability;
