import { useEffect } from 'react';
import SocketService from '../../../../../../../../utils/socket/SocketService';
import { GeneratedSessionSummaryFeedback } from '../../../../../../../../hooks/notes/useMutationRateSmartNote';

interface MessageProps {
  roomID: number;
  unsubmittedSessionID: string;
  status: 'error' | 'success';
  generatedSessionSummaryID: number;
  generatedSessionSummary?: string;
  feedback: GeneratedSessionSummaryFeedback;
}

interface useGenerateSessionSummarySocketProps {
  message: MessageProps;
}

interface UseGenerateSessionSummarySocketProps {
  callback: (
    status: string,
    generatedSessionSummary?: string,
    feedback?: GeneratedSessionSummaryFeedback | null
  ) => void;
  roomID: string;
  unsubmittedSessionID?: string | null;
  generatedSessionSummaryID?: number | null;
}

const useGenerateSessionSummarySocket = ({
  callback,
  roomID,
  unsubmittedSessionID,
  generatedSessionSummaryID,
}: UseGenerateSessionSummarySocketProps) => {
  useEffect(() => {
    const handleGenerateSessionSummarySocket = (data: useGenerateSessionSummarySocketProps) => {
      const { message } = data;
      const {
        roomID: socketRoomID,
        unsubmittedSessionID: socketUnsubmittedSessionID,
        generatedSessionSummaryID: socketGeneratedSessionSummaryID,
      } = message;
      if (
        socketRoomID?.toString() === roomID &&
        socketUnsubmittedSessionID === unsubmittedSessionID &&
        socketGeneratedSessionSummaryID === generatedSessionSummaryID
      ) {
        callback(message.status, message.generatedSessionSummary, message.feedback);
      }
    };

    const socketInstance = SocketService.instance();
    socketInstance.on('updateGeneratedSessionSummary', handleGenerateSessionSummarySocket);

    return () => {
      socketInstance.off('updateGeneratedSessionSummary', handleGenerateSessionSummarySocket);
    };
  }, [callback, roomID, unsubmittedSessionID, generatedSessionSummaryID]);

  return null;
};

export default useGenerateSessionSummarySocket;
