import { useEffect } from 'react';
import SocketService from '../../../../../utils/socket/SocketService';

interface UnsubmittedSessionsRefetchProps {
  message: { roomID: number };
}

export default function useUnsubmittedSessionsSocket(refetch: () => void, roomID: string) {
  useEffect(() => {
    const handleUnsubmittedSessionsRefetch = (data: UnsubmittedSessionsRefetchProps) => {
      if (data?.message?.roomID?.toString() === roomID) {
        refetch();
      }
    };
    SocketService.instance().on('updateUnsubmittedSessions', handleUnsubmittedSessionsRefetch);
    return () => {
      SocketService.instance().off('updateUnsubmittedSessions', handleUnsubmittedSessionsRefetch);
    };
  }, [refetch, roomID]);

  return null;
}
