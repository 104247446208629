import Svg, { G, Path, Circle } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

function ErrorXSmallIcon(props) {
  const { color } = props;
  const { colors } = useEmotionTheme();
  return (
    <Svg width="24px" height="24px" viewBox="0 0 24 24" {...props}>
      <G
        transform="translate(-59 -436) translate(59 436)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <G fill={color || colors.heatherGrey}>
          <Path
            d="M6 0a1.2 1.2 0 011.2 1.2v9.6a1.2 1.2 0 01-2.4 0V1.2A1.2 1.2 0 016 0z"
            transform="rotate(45 1.757 16.243)"
          />
          <Path
            d="M6 0a1.2 1.2 0 011.2 1.2v9.6a1.2 1.2 0 01-2.4 0V1.2A1.2 1.2 0 016 0z"
            transform="rotate(45 1.757 16.243) rotate(90 6 6)"
          />
        </G>
        <Circle
          stroke={color || colors.heatherGrey}
          strokeWidth={2.4000001}
          cx={12.0000005}
          cy={12.0000005}
          r={10.8000004}
        />
      </G>
    </Svg>
  );
}

export default ErrorXSmallIcon;
