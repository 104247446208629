import useQueryRoomDetails from 'ts-frontend/hooks/useQueryRoomDetails';
import useQueryBusinessLine from 'ts-frontend/hooks/useQueryBusinessLine';
import { FunctionComponent, useRef, useEffect, useState } from 'react';
import moment from 'moment';
import { useQueryBookings, useQueryVideoCreditOffers } from 'inRoomScheduling';
import {
  Small,
  Large,
  TouchableView,
  PictureInPicture,
  View,
  EmotionThemeProvider,
  useWindowWidthState,
  getScreenSafeAreaInsets,
} from '@talkspace/react-toolkit';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { getUserData } from '@/auth/helpers/token';
import TherapistScheduleNextSessionPopupContainer, {
  shouldTherapistSeeScheduleNextSessionPopup,
} from './TherapistScheduleNextSessionPopupContainer';
import VideoCallTrayPopup from './VideoCallTrayPopup';
import { VideoCallTrayEnum } from '../types';
import {
  RemoteUserTracks,
  VideoCallEvent,
  VideoCallAPIResponse,
  VideoCreditType,
} from '../types/videoCallTypes';
import { useVideoCallActions, useVideoCallState } from '../hooks/videoCallContext';
import useSpeakers from '../hooks/useSpeakers';
import { MediaStreamState } from '../hooks/useMediaStream';
import useDisplay from '../hooks/useDisplay';
import useVideoCallProviderRecurringScheduler from '../hooks/useVideoCallProviderRecurringScheduler';
import {
  CallControls,
  LocalVideoCall,
  RemoteVideoCall,
  Timer,
  CallSettings,
  WaitingForOthers,
} from '../components/VideoCall';
import useTSExperiments from '../../experiments/hooks/useTSExperiments';
import sessionStorage from '@/core/storage/sessionStorage';
import { trackEvent } from '@/utils/analytics/eventTracker';
import useVideoTrayPopup from '../hooks/useVideoTrayPopup';
import ClosedCaptionsView from '../components/ClosedCaptionsView';
import useMutationConfigureClosedCaptions from '../hooks/useMutationConfigureClosedCaptions';

const getIsCallActive = (
  isCurrentUserTherapist: boolean,
  therapistUserID: number,
  remoteStreamList: RemoteUserTracks[]
) =>
  remoteStreamList.some(({ userID }) =>
    isCurrentUserTherapist
      ? userID && userID !== therapistUserID
      : userID && userID === therapistUserID
  );

const getNameForUser = (
  userID: number | undefined,
  videoCall: VideoCallAPIResponse | undefined
) => {
  if (!videoCall || !userID) return '';
  if (userID === videoCall.therapist.userID)
    return `${videoCall.therapist.therapistFirstName} ${
      videoCall.therapist.therapistLastName || ''
    }`;
  const potentialClient = videoCall.clients.find(
    ({ userID: clientUserID }) => userID === clientUserID
  );
  return potentialClient ? potentialClient.pseudonym : '';
};

const VideoCallContainer: FunctionComponent<{
  roomID: number;
  videoCallID: number;
  returnToChat(): void;
  mediaStreamState: MediaStreamState;
}> = ({ roomID, videoCallID, returnToChat, mediaStreamState }) => {
  const {
    isAudioOn,
    isVideoOn,
    startDayString,
    callTimeScheduleString,
    creditMinutes,
    videoCall,
    videoCallDescription,
    videoCreditType,
    selectedAudioOut,
    wakeLockSentinel,
    isMinimized,
    showTherapistScheduleNextSessionPopup,
    captionsLanguage,
  } = useVideoCallState();

  const safeAreaInsets = getScreenSafeAreaInsets();

  const { data: { clientUserID, roomType } = {} } = useQueryRoomDetails(roomID);
  const { data: businessLine } = useQueryBusinessLine(roomID, clientUserID);
  const { isBH } = businessLine || {};
  const videoTrayRef = useRef<HTMLDivElement | null>(null);

  const {
    toggleAudioVideoAction,
    requestWakeLockSentinelAction,
    removeWakeLockSentinelAction,
    createVideoCallEventAction,
    leaveCallAction,
    endCallAction,
    setMediaListsAction,
    toggleMinimizedAction,
    setTherapistScheduleNextSessionPopupAction,
  } = useVideoCallActions();

  const experiment = useTSExperiments('in-video-prompt-schedule-next-session');
  const isUserInExperiment =
    experiment.isLoading || !experiment.isUserInExperiment
      ? false
      : experiment.variantName !== 'control';

  const { data: bookings, refetch: refetchBookings } = useQueryBookings({ roomID });
  const { data: videoCreditOffers, refetch: refetchVideoCreditOffers } = useQueryVideoCreditOffers({
    roomID,
    source: 'inCall',
  });
  const isPsych = videoCreditType === 'psychiatry';
  const currentUserID = Number(getUserData().id);
  const [callStartedAt, setCallStartedAt] = useState<string | undefined>(undefined);
  const [therapistUserID, setTherapistUserID] = useState<number | undefined>(undefined);
  const [therapistFirstName, setTherapistFirstName] = useState<string>('');
  const [isCurrentUserTherapist, setIsCurrentUserTherapist] = useState<boolean | undefined>(
    undefined
  );
  const [isCallActive, setIsCallActive] = useState<boolean | undefined>(undefined);
  const [secondsElapsed, setSecondsElapsed] = useState<number>(0);
  const [timerDescriptionString, setTimerDescriptionString] = useState<string>('');
  const pingEventIntervalRef = useRef<number>();

  const {
    remoteStreamList,
    activeSpeakerUserID,
    audioMutedByUserID,
    videoMutedByUserID,
    isDisconnected,
    mediaLists: { cameras: updatedCameras, audioOuts: updatedAudioOuts, audioIns: updatedAudioIns },
  } = mediaStreamState;

  const { mutateAsync: configureClosedCaptions } = useMutationConfigureClosedCaptions();

  useEffect(() => {
    if (!captionsLanguage) {
      return;
    }

    configureClosedCaptions({ roomID, videoCallID, captionsLanguage }).then();
  }, [captionsLanguage, configureClosedCaptions, roomID, videoCallID]);

  useEffect(() => {
    if (!wakeLockSentinel) requestWakeLockSentinelAction();
    return () => {
      if (wakeLockSentinel) {
        removeWakeLockSentinelAction();
      }
    };
  }, [wakeLockSentinel, requestWakeLockSentinelAction, removeWakeLockSentinelAction]);

  useEffect(() => {
    if (videoCall && videoCall.callStartedAt) setCallStartedAt(videoCall.callStartedAt);
    if (videoCall) {
      setTherapistUserID(videoCall.therapist.userID);
      setIsCurrentUserTherapist(videoCall.therapist.userID === currentUserID);
      setTherapistFirstName(videoCall.therapist.therapistFirstName);
    }
  }, [currentUserID, videoCall]);

  useEffect(
    () => () => {
      leaveCallAction();
    },
    [leaveCallAction]
  );

  useEffect(() => {
    if (!callStartedAt && isCallActive) setCallStartedAt(moment().toISOString());
  }, [callStartedAt, isCallActive]);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    // the call is active if both the therapist and at least 1 client is in the call
    if (therapistUserID && isCurrentUserTherapist !== undefined) {
      setIsCallActive(getIsCallActive(isCurrentUserTherapist, therapistUserID, remoteStreamList));

      if (isCurrentUserTherapist) {
        // get client available credits
        refetchVideoCreditOffers();
        // get client upcoming bookings
        refetchBookings();

        // NOTE: also fetching 5 minutes into the call because credit gets redeemed at minute 4
        timeout = setTimeout(() => {
          refetchVideoCreditOffers();
          refetchBookings();
        }, 5 * 60 * 1000);
      }
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [
    isCurrentUserTherapist,
    refetchBookings,
    refetchVideoCreditOffers,
    remoteStreamList,
    roomID,
    therapistUserID,
  ]);

  useEffect(() => {
    // a therapist user needs to ping events every 30 seconds based on whether a client is in the room or not
    if (isCurrentUserTherapist && isCallActive !== undefined) {
      if (isCallActive) {
        createVideoCallEventAction(roomID, videoCallID, VideoCallEvent.callStarted);
      }
      if (pingEventIntervalRef.current) window.clearInterval(pingEventIntervalRef.current);
      pingEventIntervalRef.current = window.setInterval(() => {
        createVideoCallEventAction(
          roomID,
          videoCallID,
          isCallActive ? VideoCallEvent.callIsActive : VideoCallEvent.therapistWaiting
        );
      }, 30000);
    }
    // set the proper timer description for the user
    if (isCallActive) {
      const descriptionString =
        !isCurrentUserTherapist && therapistFirstName
          ? `${videoCallDescription} with ${therapistFirstName}`
          : videoCallDescription || '';
      setTimerDescriptionString(descriptionString);
    }

    return () => {
      if (pingEventIntervalRef.current) window.clearInterval(pingEventIntervalRef.current);
    };
  }, [
    createVideoCallEventAction,
    isCurrentUserTherapist,
    roomID,
    isCallActive,
    therapistUserID,
    videoCallID,
    therapistFirstName,
    videoCallDescription,
  ]);

  useEffect(() => {
    setMediaListsAction(updatedCameras, updatedAudioOuts, updatedAudioIns);
  }, [updatedCameras, updatedAudioOuts, updatedAudioIns, setMediaListsAction]);

  useEffect(() => {
    // a one time event is created when the client or therapist successfully joins a call
    if (isCurrentUserTherapist !== undefined) {
      createVideoCallEventAction(
        roomID,
        videoCallID,
        isCurrentUserTherapist ? VideoCallEvent.therapistJoined : VideoCallEvent.clientJoined
      );
    }
  }, [createVideoCallEventAction, isCurrentUserTherapist, roomID, videoCallID]);

  useEffect(() => {
    // this starts the timer from the 'callStartedAt' time, which can be updated mid-call
    let intervalID: number | undefined;
    if (callStartedAt) {
      intervalID = window.setInterval(() => {
        const secondsDifferences: number = Math.floor(
          (new Date().getTime() - new Date(callStartedAt).getTime()) / 1000
        );
        setSecondsElapsed(secondsDifferences);
      }, 1000);
    }
    return () => {
      if (intervalID) window.clearInterval(intervalID);
    };
  }, [callStartedAt]);

  useDisplay({
    remoteStreamList,
  });

  useSpeakers({
    remoteStreamList,
    selectedAudioOut,
  });

  useEffect(() => {
    if (isDisconnected) returnToChat();
  }, [isDisconnected, returnToChat]);

  const remoteUserCount = remoteStreamList.filter(({ userID }) => !!userID).length;
  const areOtherUsersInCall = !!remoteUserCount;
  const streamOneUserID = remoteStreamList[0]?.userID;
  const streamTwoUserID = remoteStreamList[1]?.userID;
  const isStreamOneSpeaking = activeSpeakerUserID === streamOneUserID;
  const isStreamTwoSpeaking = activeSpeakerUserID === streamTwoUserID;
  const isStreamOneAudioOn = !!streamOneUserID && audioMutedByUserID[streamOneUserID] === false;
  const isStreamTwoAudioOn = !!streamTwoUserID && audioMutedByUserID[streamTwoUserID] === false;
  const isStreamOneVideoOn = !!streamOneUserID && videoMutedByUserID[streamOneUserID] === false;
  const isStreamTwoVideoOn = !!streamTwoUserID && videoMutedByUserID[streamTwoUserID] === false;
  const { videoTrayOpened, openTray, closeTray, hideTray, videoTrayVisibleHeight } =
    useVideoTrayPopup({
      ref: videoTrayRef,
      type: VideoCallTrayEnum.THERAPIST_SCHEDULE_NEXT_RECURRING,
    });

  const { repeatingSessionsFull: isActiveRecurringSessionsFull } = useFlags();
  const {
    isEligible: isEligibleForRecurringSchedulerTray,
    isTimeToShow: isTimeToShowRecurringSchedulerTray,
  } = useVideoCallProviderRecurringScheduler({
    isBH: isBH || false,
    roomType,
    isTherapist: isCurrentUserTherapist || false,
    sessionLengthMinutes: creditMinutes || 0,
    timeInCallSeconds: secondsElapsed,
    bookings: bookings || [],
    videoCreditOffers: videoCreditOffers?.liveSessions || [],
    videoCreditType: videoCreditType || VideoCreditType.free, // NOTE: this default is random, not meaningful
  });

  useEffect(() => {
    if (isEligibleForRecurringSchedulerTray) {
      // open recurring scheduler tray
      if (isMinimized) {
        hideTray();
      } else if (isTimeToShowRecurringSchedulerTray) {
        openTray();
      }
    } else if (
      // open schedule next session popup for therapist.
      videoCreditOffers?.liveSessions &&
      isUserInExperiment &&
      isCurrentUserTherapist &&
      areOtherUsersInCall &&
      !showTherapistScheduleNextSessionPopup &&
      !isMinimized &&
      videoCreditType &&
      typeof creditMinutes === 'number' && // NOTE: 0 for free rooms, valid case
      shouldTherapistSeeScheduleNextSessionPopup(
        creditMinutes,
        secondsElapsed,
        videoCreditType,
        bookings || [],
        videoCreditOffers.liveSessions || [],
        isBH
      )
    ) {
      trackEvent(
        'In-Video Session Prompt',
        {
          actionName: 'inVideoPromptInteraction',
          roomID,
        },
        ['tsAnalytics']
      );
      setTherapistScheduleNextSessionPopupAction(true);
    }
  }, [
    isEligibleForRecurringSchedulerTray,
    isTimeToShowRecurringSchedulerTray,
    bookings,
    creditMinutes,
    secondsElapsed,
    isPsych,
    isBH,
    showTherapistScheduleNextSessionPopup,
    isMinimized,
    areOtherUsersInCall,
    isCurrentUserTherapist,
    isUserInExperiment,
    videoCreditType,
    setTherapistScheduleNextSessionPopupAction,
    videoCreditOffers,
    roomID,
    closeTray,
    openTray,
    hideTray,
  ]);

  useEffect(() => {
    // close the popup when anyone leaves or if minimized
    if (
      isCurrentUserTherapist &&
      showTherapistScheduleNextSessionPopup &&
      (!areOtherUsersInCall || isMinimized)
    ) {
      setTherapistScheduleNextSessionPopupAction(false);
    }
  }, [
    isMinimized,
    areOtherUsersInCall,
    isCurrentUserTherapist,
    setTherapistScheduleNextSessionPopupAction,
    showTherapistScheduleNextSessionPopup,
  ]);

  useEffect(() => {
    if (!isCurrentUserTherapist && isMinimized) {
      sessionStorage.setItem('videoSessionInfo', JSON.stringify({ roomID, isInSession: true }));
    } else {
      sessionStorage.setItem('videoSessionInfo', JSON.stringify({ roomID, isInSession: false }));
    }
  }, [isCurrentUserTherapist, isMinimized, roomID]);

  const { isMobile } = useWindowWidthState();

  return (
    <>
      <TouchableView
        onPress={toggleMinimizedAction}
        style={{
          top: isMinimized ? 13 : 25 + safeAreaInsets.top,
          right: isMinimized ? 13 : 20,
          zIndex: 1001,
          position: 'absolute',
        }}
      >
        <PictureInPicture isBackgroundDark={areOtherUsersInCall} />
      </TouchableView>

      {showTherapistScheduleNextSessionPopup && (
        <TherapistScheduleNextSessionPopupContainer
          roomID={roomID}
          handleMinimize={!isMinimized ? toggleMinimizedAction : () => {}}
        />
      )}
      {!areOtherUsersInCall && (
        <WaitingForOthers
          startDayString={startDayString}
          callTimeScheduleString={callTimeScheduleString}
          isMinimized={isMinimized}
          bottomOffset={videoTrayVisibleHeight}
        />
      )}

      <EmotionThemeProvider version="2.0.0">
        <CallSettings
          areOtherUsersInCall={areOtherUsersInCall}
          isCurrentUserTherapist={!!isCurrentUserTherapist}
        />
      </EmotionThemeProvider>

      <RemoteVideoCall
        isActiveSpeaker={isStreamOneSpeaking}
        remoteUserCount={remoteUserCount}
        isAudioOn={isStreamOneAudioOn}
        isVideoOn={isStreamOneVideoOn}
        isOtherAudioOn={isStreamTwoAudioOn}
        isOtherVideoOn={isStreamTwoVideoOn}
        userDisplayName={getNameForUser(streamOneUserID, videoCall)}
        userID={streamOneUserID}
        therapistUserID={therapistUserID}
        isMinimized={isMinimized}
        bottomOffset={videoTrayVisibleHeight}
      />
      <RemoteVideoCall
        isActiveSpeaker={isStreamTwoSpeaking}
        remoteUserCount={remoteUserCount}
        isAudioOn={isStreamTwoAudioOn}
        isVideoOn={isStreamTwoVideoOn}
        isOtherAudioOn={isStreamOneAudioOn}
        isOtherVideoOn={isStreamOneVideoOn}
        userDisplayName={getNameForUser(streamTwoUserID, videoCall)}
        userID={streamTwoUserID}
        therapistUserID={therapistUserID}
        isMinimized={isMinimized}
        bottomOffset={videoTrayVisibleHeight}
      />

      {!isMinimized && (
        <Timer
          secondsElapsed={secondsElapsed}
          areOtherUsersInCall={areOtherUsersInCall}
          descriptionString={timerDescriptionString}
          // Padding added to avoid collision with absolutely positioned buttons
          timerStyle={{
            top: 26 + safeAreaInsets.top,
            paddingLeft: isMobile ? 60 : 80,
            paddingRight: isMobile ? 60 : 80,
          }}
        />
      )}

      {!isMinimized && (
        <Large style={{ marginTop: 30, textAlign: 'center' }} variant="largeDarkGrey">
          {videoCallDescription}
        </Large>
      )}
      {isMinimized && (
        <Small style={{ textAlign: 'center', paddingBottom: 35 }} variant="smallDarkGrey">
          {videoCallDescription}
        </Small>
      )}
      <View
        style={{
          position: 'absolute',
          bottom: isMinimized ? 0 : safeAreaInsets.bottom,
          left: 0,
          width: '100%',
        }}
        align={isMinimized ? undefined : 'center'}
      >
        <LocalVideoCall
          bottomOffset={videoTrayVisibleHeight}
          currentUserID={currentUserID}
          isAudioOn={isAudioOn}
          isVideoOn={isVideoOn}
          isMinimized={isMinimized}
          isCurrentUserTherapist={isCurrentUserTherapist}
        />
        <CallControls
          isAudioOn={isAudioOn}
          isVideoOn={isVideoOn}
          isMinimized={isMinimized}
          areOtherUsersInCall={areOtherUsersInCall}
          toggleAudioVideoAction={toggleAudioVideoAction}
          endCallAction={() => {
            if (!isCurrentUserTherapist) {
              const videoSessionInfo = JSON.parse(
                sessionStorage.getItem('videoSessionInfo') || '{}'
              );

              sessionStorage.setItem(
                'videoSessionInfo',
                JSON.stringify({ ...videoSessionInfo, isInSession: false })
              );
            }
            endCallAction(callStartedAt);
          }}
        />

        <EmotionThemeProvider version="2.0.0">
          <ClosedCaptionsView />
        </EmotionThemeProvider>

        {isActiveRecurringSessionsFull && videoTrayOpened && (
          <VideoCallTrayPopup
            ref={videoTrayRef}
            type={videoTrayOpened}
            closeTray={closeTray}
            handleMinimize={!isMinimized ? toggleMinimizedAction : () => {}}
          />
        )}
      </View>
    </>
  );
};

export default VideoCallContainer;
