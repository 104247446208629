import { useState } from 'react';
import {
  TouchableView,
  Standard,
  styled,
  View,
  FloatingMenu,
  useEmotionTheme,
  CaretDown,
} from '@talkspace/react-toolkit';
import { Theme } from '@/core/styled';
import { getNoteSummaryItemsColor } from '../../utils';

const Container = styled(TouchableView)<{
  disabled: boolean;
  isDropdownOpen: boolean;
  isHovering: boolean;
}>(({ theme: { colors }, disabled, isDropdownOpen = false }) => {
  const hoverBackground = isDropdownOpen ? colors.lightLavender : colors.grayishBlue;
  return {
    position: 'relative',
    flexDirection: 'row',
    cursor: disabled && 'default',
    alignItems: 'center',
    '&:hover': {
      ...(disabled ? {} : { background: hoverBackground }),
    },
    ...(isDropdownOpen && !disabled ? { background: colors.lightLavender } : {}),
    padding: '6px 6px 6px 8px',
    borderRadius: 8,
    gap: 4,
    width: 124,
    height: 32,
  };
});

const Label = styled(Standard)<{ isPressed: boolean }>(({ theme: { colors }, isPressed }) => {
  return {
    lineHeight: '20px',
    color: isPressed && colors.accessibilityDarkGreenBold,
    marginTop: -2,
    marginRight: 4,
    fontSize: 14,
    fontWeight: 600,
  };
});

const DropdownItem = styled(View)<{ last: boolean; first: boolean }>(
  ({ theme: { colors }, last, first }) => {
    let borderRadius = '8px';
    if (last) {
      borderRadius = '0 0 8px 8px';
    } else if (first) {
      borderRadius = '8px 8px 0 0';
    }
    return {
      height: 49,
      padding: 12,
      '&:hover': {
        background: colors.aqua,
        color: colors.white,
      },
      color: colors.black,
      fontSize: 14,
      borderRadius,
    };
  }
);

const getFloatingMenuStyles = (colors: Theme['colors']) => {
  return {
    minHeight: 'fit-content',
    borderRadius: 8,
    boxShadow: '0px 7px 16px rgba(0, 29, 47, 0.3875)',
    border: `1px solid ${colors.a11yLinkWaterGrey}`,
    top: 35,
    minWidth: 129,
    left: 0,
  };
};

const templates = [
  {
    id: 1,
    title: 'SOAP note',
    text: "Subjective - Relevant information reported by the client:\n\n\nObjective - Relevant observations about the client:\n\n\nAssessment - Client's response to therapeutic interventions:\n\n\nPlan - Next steps:\n\n",
  },
  {
    id: 2,
    title: 'DAP note',
    text: 'Data - Clinical interventions and client engagement:\n\n\nAssessment - Progress toward goals:\n\n\nPlan - Changes or updates to treatment plan:\n\n',
  },
];

const NoteTemplate = ({
  handleAppendNote,
  isDisabled,
}: {
  handleAppendNote: (title: string, text: string) => void;
  isDisabled: boolean;
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const { colors } = useEmotionTheme();
  const floatingMenuStyle = getFloatingMenuStyles(colors);

  const color = getNoteSummaryItemsColor(isDisabled, isHovering);
  return (
    <Container
      onPress={() => {
        setIsDropdownOpen((prev) => !prev);
        setIsHovering(false);
      }}
      disabled={isDisabled}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      isDropdownOpen={isDropdownOpen}
      isHovering={isHovering}
      dataQa="addTemplateDropdown"
    >
      <Label isPressed={isDropdownOpen} variant="standardBoldAccessibilityGreen" style={{ color }}>
        Add template
      </Label>
      <CaretDown color={color} />
      <FloatingMenu
        hide={!isDropdownOpen}
        style={floatingMenuStyle}
        onBackdropPress={() => setIsDropdownOpen(false)}
      >
        {templates.map((template, index) => (
          <DropdownItem
            onClick={() => handleAppendNote(template.title, template.text)}
            first={index === 0}
            last={index === templates.length - 1}
            key={template.id}
          >
            {template.title}
          </DropdownItem>
        ))}
      </FloatingMenu>
    </Container>
  );
};

export default NoteTemplate;
