/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from 'react';
import API from '../utils/upcomingBookingsApiHelper';
import {
  UpcomingBookingsState,
  UpcomingBookingsAction,
  PatchBookingPayload,
  ModalOpen,
  TimePeriod,
} from '../types';
import { getUserData } from '../../../utils/token';
import useSimpleReducer, {
  receiveActionPayload,
  errorActionPayload,
  requestActionPayload,
} from '../../../hooks/useSimpleReducer';

const initialState: UpcomingBookingsState = {
  bookings: [],
  timeOffs: undefined,
  isMultipleTimeOffs: undefined,
  cancelBookings: undefined,
  bookingUpdateFetchState: 'initial',
  bookingUpdateFetchError: undefined,
  cancelReason: undefined,
  whichModalIsOpen: null,
  bookingUpdateFetchErrorMessage: undefined,
  isLoading: false,
  isError: false,
};

export default function useUpcomingBookings(): [
  UpcomingBookingsState,
  {
    dispatchGetBookings: (start?: string, end?: string) => void;
    dispatchGetTimeOff: () => void;
    dispatchGetRoomBookings: (roomID: number) => void;
    dispatchPatchBooking: (bookingID: string, payload: PatchBookingPayload, roomID: number) => void;
    dispatchSetCancelReason: (reason: string, otherText?: string) => void;
    dispatchSetWhichModalIsOpen: (modal: ModalOpen) => void;
    dispatchResetError: () => void;
    dispatchResetUpcomingBookingsState: () => void;
    dispatchGetMultipleTimeOffsFeatureFlag: () => void;
    dispatchGetMultipleTimeOffs: () => void;
    dispatchGetMultipleBookings: (periods: TimePeriod[]) => void;
  }
] {
  const [state, dispatch] = useSimpleReducer<UpcomingBookingsState, UpcomingBookingsAction>(
    initialState
  );

  function dispatchGetMultipleTimeOffsFeatureFlag() {
    dispatch({ type: 'requestMultipleTimeOffsFeatureFlag', payload: requestActionPayload });
    API.getMultipleTimeOffsFeatureFlag().then((response) =>
      dispatch({
        type: 'receiveMultipleTimeOffsFeatureFlag',
        payload: {
          ...receiveActionPayload,
          isMultipleTimeOffs: response.data ? !!response.data.data : false,
        },
      })
    );
  }

  function dispatchGetTimeOff() {
    dispatch({ type: 'requestGetTimeOff', payload: requestActionPayload });
    const therapistUserID = getUserData().id;
    API.getTimeOff(therapistUserID)
      .then((response) =>
        dispatch({
          type: 'receiveGetTimeOff',
          payload: {
            ...receiveActionPayload,
            timeOff: response.data ? response.data.data : undefined,
          },
        })
      )
      .catch(() =>
        dispatch({
          type: 'setIsError',
          payload: errorActionPayload,
        })
      );
  }

  function dispatchGetMultipleTimeOffs() {
    dispatch({ type: 'requestGetMultipleTimeOffs', payload: requestActionPayload });
    const therapistUserID = getUserData().id;
    API.getMultipleTimeOffs(therapistUserID)
      .then((response) =>
        dispatch({
          type: 'receiveGetMultipleTimeOffs',
          payload: {
            ...receiveActionPayload,
            timeOffs: response.data ? response.data.data : [],
          },
        })
      )
      .catch(() =>
        dispatch({
          type: 'setIsError',
          payload: errorActionPayload,
        })
      );
  }

  function dispatchGetMultipleBookings(periods: TimePeriod[]) {
    dispatch({ type: 'requestGetBookings', payload: requestActionPayload });
    const therapistUserID = getUserData().id;
    API.getMultipleBookings(therapistUserID, periods)
      .then((response) =>
        dispatch({
          type: 'receiveGetBookings',
          payload: {
            ...receiveActionPayload,
            cancelBookings: response.data ? response.data.data : [],
          },
        })
      )
      .catch(() =>
        dispatch({
          type: 'setIsError',
          payload: errorActionPayload,
        })
      );
  }

  function dispatchGetBookings(start?: string, end?: string) {
    dispatch({ type: 'requestGetBookings', payload: requestActionPayload });
    const therapistUserID = getUserData().id;
    const bookingsKey = start && end ? 'cancelBookings' : 'bookings';
    API.getBookings(therapistUserID, start, end)
      .then((response) =>
        dispatch({
          type: 'receiveGetBookings',
          payload: {
            ...receiveActionPayload,
            [bookingsKey]: response.data ? response.data.data : [],
          },
        })
      )
      .catch(() =>
        dispatch({
          type: 'setIsError',
          payload: errorActionPayload,
        })
      );
  }

  function dispatchGetRoomBookings(roomID: number) {
    dispatch({ type: 'requestGetRoomBookings', payload: requestActionPayload });
    API.getRoomBookings(roomID)
      .then((response) => {
        dispatch({
          type: 'receiveGetRoomBookings',
          payload: {
            ...receiveActionPayload,
            bookings: response.data ? response.data.data : [],
          },
        });
      })
      .catch(() =>
        dispatch({
          type: 'setIsError',
          payload: errorActionPayload,
        })
      );
  }

  function dispatchPatchBooking(bookingID: string, payload: PatchBookingPayload, roomID: number) {
    dispatch({ type: 'requestPatchBooking', payload: { bookingUpdateFetchState: 'fetching' } });
    const therapistUserID = getUserData().id;
    const apiRequest =
      payload.action === 'dismiss'
        ? API.dismissBooking(therapistUserID, bookingID, payload)
        : API.patchBooking(roomID, bookingID, payload);

    apiRequest
      .then(() =>
        dispatch({
          type: 'receivePatchBooking',
          payload: {
            bookingUpdateFetchState: 'fetchComplete',
          },
        })
      )
      .then(() => {
        if (roomID) {
          dispatchGetRoomBookings(roomID);
        } else {
          dispatchGetBookings(therapistUserID);
        }
      })
      .catch((error) => {
        dispatch({
          type: 'setIsError',
          payload: {
            isError: true,
            bookingUpdateFetchError: error,
            bookingUpdateFetchErrorMessage: error.message,
            bookingUpdateFetchState: 'fetchComplete',
            whichModalIsOpen: 'error',
          },
        });
      });
  }

  function dispatchResetError() {
    dispatch({
      type: 'resetError',
      payload: { isError: false, isLoading: false },
    });
  }

  function dispatchSetCancelReason(cancelReason) {
    dispatch({
      type: 'setCancelReason',
      payload: { cancelReason },
    });
  }

  function dispatchSetWhichModalIsOpen(whichModalIsOpen: ModalOpen) {
    dispatch({
      type: 'setWhichModalIsOpen',
      payload: { whichModalIsOpen },
    });
  }

  function dispatchResetUpcomingBookingsState() {
    dispatch({
      type: 'resetUpcomingBookingsState',
      payload: {
        bookingUpdateFetchState: 'initial',
        bookingUpdateFetchError: undefined,
        cancelReason: undefined,
        whichModalIsOpen: undefined,
        isLoading: false,
        isError: false,
      },
    });
  }

  return [
    state,
    {
      dispatchGetBookings: useCallback(dispatchGetBookings, []),
      dispatchGetTimeOff: useCallback(dispatchGetTimeOff, []),
      dispatchGetRoomBookings: useCallback(dispatchGetRoomBookings, []),
      dispatchPatchBooking: useCallback(dispatchPatchBooking, []),
      dispatchSetCancelReason: useCallback(dispatchSetCancelReason, []),
      dispatchSetWhichModalIsOpen: useCallback(dispatchSetWhichModalIsOpen, []),
      dispatchResetError: useCallback(dispatchResetError, []),
      dispatchResetUpcomingBookingsState: useCallback(dispatchResetUpcomingBookingsState, []),
      dispatchGetMultipleTimeOffsFeatureFlag: useCallback(
        dispatchGetMultipleTimeOffsFeatureFlag,
        []
      ),
      dispatchGetMultipleTimeOffs: useCallback(dispatchGetMultipleTimeOffs, []),
      dispatchGetMultipleBookings: useCallback(dispatchGetMultipleBookings, []),
    },
  ];
}
