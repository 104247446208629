import { useMutation, useQueryClient } from 'react-query';
import apiHelper from '@/utils/api';
import apiWrapper from '../../utils/apiWrapper';
import { notesListQueryKey } from './queryKeys';

interface DeletePsychotherapyNoteMutateVariables {
  roomID: string;
  noteID: string;
}

const deletePsychotherapyNote = async (
  params: DeletePsychotherapyNoteMutateVariables
): Promise<void> => {
  const { roomID, noteID } = params;
  const requestURL = `/v2/rooms/${roomID}/psychotherapy-notes/${noteID}`;

  await apiWrapper.delete(`${apiHelper().apiEndpoint}${requestURL}`);
};

const useMutationDeletePsychotherapyNote = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, DeletePsychotherapyNoteMutateVariables>(deletePsychotherapyNote, {
    onSuccess: (_, { roomID }) => {
      queryClient.invalidateQueries(notesListQueryKey(roomID));
    },
  });
};

export default useMutationDeletePsychotherapyNote;
