import TSLogoCollapsed from './TSLogoCollapsed';
import TSLogoCollapsed2024 from './TSLogoCollapsed2024';
import { TSLogoCollapsedProps } from './types';

interface TSLogoCollapsedVariantsProps extends TSLogoCollapsedProps {
  variant?: 'old' | '2024';
}

const TSLogoCollapsedVariants = (props: TSLogoCollapsedVariantsProps) => {
  const { variant, ...logoProps } = props;
  if (variant === '2024') {
    return <TSLogoCollapsed2024 {...logoProps} />;
  }
  return <TSLogoCollapsed {...logoProps} />;
};

export default TSLogoCollapsedVariants;
