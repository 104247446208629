import { FunctionComponent } from 'react';
import { useErrorFocus, View } from '@talkspace/react-toolkit';

import { Stripe } from '@stripe/stripe-js';
import { Provider, PaymentDetails } from 'ts-frontend/types';
import StripeLinkCheckoutForm from '../StripeLinkCheckoutForm';

interface NewCardProps {
  stripe: Stripe;
  email?: string;
  isEmailRequired?: boolean;
  isEmailDisabled?: boolean;
  provider: Provider;
  onSubmit: (token: string, paymentDetails?: PaymentDetails, email?: string) => void;
  resetError: () => void;
  onLoadStripeLink: () => void;
  onError: (error: any) => void;
  submitText: string;
  errorMessage?: string;
  isProcessing: boolean;
  aboveButtonDisclaimer?: JSX.Element;
}

const NewCardWithLink: FunctionComponent<NewCardProps> = ({
  stripe,
  email,
  isEmailRequired,
  isEmailDisabled,
  provider,
  onSubmit,
  resetError,
  onLoadStripeLink,
  onError,
  submitText,
  errorMessage,
  isProcessing,
  aboveButtonDisclaimer,
}) => {
  const { formContainerRef } = useErrorFocus();

  return (
    <View ref={formContainerRef}>
      <StripeLinkCheckoutForm
        onSubmit={onSubmit}
        resetError={resetError}
        onError={onError}
        onLoadStripeLink={onLoadStripeLink}
        email={email}
        stripe={stripe}
        errorMessage={errorMessage}
        submitText={submitText}
        isLinkLoaded
        isProcessing={isProcessing}
        aboveButtonDisclaimer={aboveButtonDisclaimer}
      />
    </View>
  );
};

export default NewCardWithLink;
