import { SessionModality, TherapistTimeslot } from 'ts-frontend/types';
import { FunctionComponent } from 'react';
import moment from 'moment';
import {
  View,
  Big,
  COLORS,
  Small,
  LiveSessionCalendarIcon,
  CouplesDefaultAvatar,
  TSAvatar,
  useWindowWidth,
  useEmotionTheme,
} from '@talkspace/react-toolkit';

import styled from '@/core/styled';
import apiHelper from '@/core/api/apiHelper';

const CurrentTherapistAvatar = styled(TSAvatar)<{
  shouldShowBookingSuccess: boolean;
}>(({ shouldShowBookingSuccess }) => {
  const avatarBorder = !shouldShowBookingSuccess
    ? {
        border: '0px solid white',
        borderRight: 2,
        borderColor: 'white',
        borderStyle: 'solid',
      }
    : {};
  return {
    zIndex: 1,
    marginRight: -16,
    borderRadius: '50%',
    ...avatarBorder,
    transition:
      '.5s cubic-bezier(0.305, 0.03, 0.515, 0.955), margin-left .5s cubic-bezier(0.55, 0.055, 0.675, 0.19) ',
  };
});
const getCurrentAvatarStyle = ({
  shouldShowBookingSuccess,
  bookingSuccessSmall,
}: {
  shouldShowBookingSuccess: boolean;
  bookingSuccessSmall?: boolean;
}) => {
  if (bookingSuccessSmall) {
    return {
      marginLeft: shouldShowBookingSuccess ? 5 : 35,
    };
  }
  return {
    marginLeft: shouldShowBookingSuccess ? 35 : 86,
  };
};

const BookingCircleContainer = styled(View)<{ autoHeight?: boolean }>(({ autoHeight }) => {
  return {
    position: 'absolute',
    width: 120,
    height: autoHeight ? 'auto' : 120,
  };
});
const getBookingCircleContainerStyle = ({
  bookingSuccessSmall,
}: {
  bookingSuccessSmall?: boolean;
}) => {
  return {
    marginLeft: bookingSuccessSmall ? 39 : 96,
    marginTop: bookingSuccessSmall ? -50 : -20,
  };
};

const AvatarSpacer = styled(View)({
  transition: 'width .7s cubic-bezier(0.68, -0.55, 0.265, 1.55)',
});
const getAvatarSpacerStyle = ({
  bookingSuccessSmall,
  shouldShowBookingSuccess,
}: {
  bookingSuccessSmall?: boolean;
  shouldShowBookingSuccess: boolean;
}) => {
  if (!shouldShowBookingSuccess) return { width: 0 };
  return {
    width: bookingSuccessSmall ? 52 : 100,
  };
};
const BookingCircle = styled(View)({
  zIndex: 2,
  borderRadius: '50%',
  backgroundColor: COLORS.white,
  boxShadow: '0 7px 12px rgba(84, 97, 136, 0.15)',
  transition: 'all .7s cubic-bezier(0.68, -0.55, 0.265, 1.55)',
});
const getBookingCircleStyle = ({
  bookingSuccessSmall,
  shouldShowBookingSuccess,
}: {
  bookingSuccessSmall?: boolean;
  shouldShowBookingSuccess: boolean;
}) => {
  if (!shouldShowBookingSuccess) {
    return {
      height: 0,
      width: 0,
      opacity: 0,
    };
  }
  if (bookingSuccessSmall) {
    return {
      height: 65,
      width: 65,
      opacity: 1,
    };
  }
  return {
    height: 120,
    width: 120,
    opacity: 1,
  };
};

const BookingAvatars: FunctionComponent<{
  therapistUserID: number;
  shouldShowBookingSuccess: boolean;
  bookingSuccessSmall?: boolean;
  modality?: SessionModality;
  selectedTimeslot?: TherapistTimeslot | null;
  isCouples?: boolean;
  isSmallAvatars?: boolean;
  marginTop?: number;
  autoHeight?: boolean;
}> = ({
  therapistUserID,
  shouldShowBookingSuccess,
  selectedTimeslot,
  isCouples,
  modality,
  bookingSuccessSmall,
  isSmallAvatars,
  marginTop,
  autoHeight,
}) => {
  const { isMobile } = useWindowWidth();
  const { colorRoles } = useEmotionTheme();
  const containerStyle = {
    width: bookingSuccessSmall ? 196 : 300,
    marginTop: shouldShowBookingSuccess ? 48 : marginTop || 4,
    transition: '.4s',
    position: 'relative',
  };
  const mobileContainerStyle = {
    ...containerStyle,
    ...(isSmallAvatars ? { width: 290 } : {}),
    marginTop: marginTop === undefined ? 35 : marginTop,
  };
  return (
    <View align="center" style={isMobile ? mobileContainerStyle : containerStyle} row>
      <CurrentTherapistAvatar
        shouldShowBookingSuccess={shouldShowBookingSuccess}
        image={`${apiHelper().cdnEndpoint}/images/application/therapist/220/${therapistUserID}.jpg`}
        size={isSmallAvatars ? 64 : 76}
        style={getCurrentAvatarStyle({ shouldShowBookingSuccess, bookingSuccessSmall })}
        avatarColor={colorRoles.element.iconNonInteractiveDefault}
      />
      <AvatarSpacer
        style={getAvatarSpacerStyle({ shouldShowBookingSuccess, bookingSuccessSmall })}
      />
      <BookingCircleContainer
        align="center"
        justify="center"
        style={getBookingCircleContainerStyle({ bookingSuccessSmall })}
        autoHeight={autoHeight}
      >
        <BookingCircle
          align="center"
          style={getBookingCircleStyle({ bookingSuccessSmall, shouldShowBookingSuccess })}
        >
          <LiveSessionCalendarIcon
            modality={modality}
            style={{ marginTop: bookingSuccessSmall ? 16 : 21 }}
          />
          {shouldShowBookingSuccess &&
            (selectedTimeslot || modality === 'messaging') &&
            !bookingSuccessSmall && (
              <>
                <Big style={{ marginTop: 6 }}>
                  {moment(selectedTimeslot?.start).format('MMM D')}
                </Big>
                {modality !== 'messaging' && (
                  <Small>{moment(selectedTimeslot?.start).format('h:mma')}</Small>
                )}
              </>
            )}
        </BookingCircle>
      </BookingCircleContainer>
      {isCouples ? (
        <CouplesDefaultAvatar size={isSmallAvatars ? 64 : 76} />
      ) : (
        <TSAvatar
          size={isSmallAvatars ? 64 : 76}
          avatarColor={colorRoles.element.iconNonInteractiveDefault}
        />
      )}
    </View>
  );
};

export default BookingAvatars;
