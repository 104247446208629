import { useQuery } from 'react-query';
import apiHelper from '../../modules/utils/api';
import apiWrapper from '../../utils/apiWrapper';

export interface TimeOff {
  start: Date;
  end: Date;
}

const fetchProviderTimeOffs = (therapistID: number) => async () => {
  const { data } = await apiWrapper.get(
    `${apiHelper().apiEndpoint}/v2/therapist/${therapistID}/time-offs`
  );

  return data.data;
};

const useQueryTimeOffs = (therapistID: number) =>
  useQuery<TimeOff[], Error>({
    queryFn: fetchProviderTimeOffs(therapistID),
    cacheTime: 0,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    retry: true,
    staleTime: 0,
  });

export default useQueryTimeOffs;
