import { FunctionComponent, useEffect, useState } from 'react';
import { View, Avatar, Standard } from '@talkspace/react-toolkit';
import { LiveSessionStateMessageUserType } from '../../entities/EMessage';
import { getAvatarImage } from '../../redux/actions/chatActions';
import { MessageProps } from '../../types';

interface LiveSessionStateMessageBody {
  therapistFirstName?: string;
  pseudonym?: string;
  text: string;
  userType: LiveSessionStateMessageUserType;
}

const LiveSessionStateMessage: FunctionComponent<Pick<MessageProps, 'currentMessage'>> = ({
  currentMessage,
}) => {
  const [avatarImage, setAvatarImage] = useState<string | undefined>();
  const { messageBody } = currentMessage;
  const { therapistFirstName, pseudonym, text, userType } =
    messageBody as LiveSessionStateMessageBody;
  useEffect(() => {
    if (userType === LiveSessionStateMessageUserType.PROVIDER) {
      const fetchedAvatarImage = getAvatarImage(currentMessage);
      setAvatarImage(fetchedAvatarImage);
    }
  }, [currentMessage, userType]);
  return (
    <View row align="center" style={{ margin: '0 auto', marginBottom: 20 }}>
      <Avatar
        width={27}
        height={27}
        style={{ marginRight: 7 }}
        image={userType === LiveSessionStateMessageUserType.PROVIDER ? avatarImage : ''}
      />
      <Standard style={{ fontWeight: 400, alignItems: 'center' }}>
        <Standard variant="standardBoldTSBlack" inline>
          {userType === LiveSessionStateMessageUserType.PROVIDER ? therapistFirstName : pseudonym}
        </Standard>{' '}
        {text}
      </Standard>
    </View>
  );
};

export default LiveSessionStateMessage;
