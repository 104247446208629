import Svg, { Path } from 'svgs';

interface CalendarIconProps {
  color?: string;
  width?: number;
  height?: number;
  style?: {};
}

const CalendarIcon = ({
  color = '#fff',
  width = 21,
  height = 24,
  ...otherProps
}: CalendarIconProps) => (
  <Svg width={width} height={height} viewBox="0 0 21 24" {...otherProps}>
    <Path
      d="M44.897 10.658c0-.64.519-1.158 1.158-1.158h.85c.639 0 1.157.518 1.157 1.158v.849h2.78c.64 0 1.158.518 1.158 1.157v18.678c0 .64-.518 1.158-1.158 1.158H32.158c-.64 0-1.158-.518-1.158-1.158V12.664c0-.639.518-1.157 1.158-1.157h2.857v-.849c0-.64.518-1.158 1.158-1.158h.85c.639 0 1.157.518 1.157 1.158v.849h6.717v-.849zm5.018 6.205h-16.83v13.553h16.83V16.863z"
      transform="translate(-31 -9)"
      fill={color}
      fillRule="nonzero"
      stroke="none"
      strokeWidth={1}
    />
  </Svg>
);

export default CalendarIcon;
