import { useMutation, useQueryClient } from 'react-query';
import availabilityKeys from './queryKeys';
import apiHelper from '../../utils/api';
import apiWrapper from '../../utils/apiWrapper';

interface UpdateClientCapacityMutateVariables {
  therapistID: number;
  dailyCaseLoad: string;
}

const updateClientCapacity = async (params: UpdateClientCapacityMutateVariables) => {
  const { therapistID, dailyCaseLoad } = params;
  await apiWrapper.patch(`${apiHelper().apiEndpoint}/v2/therapist/${therapistID}/capacity`, {
    dailyCaseLoad,
  });
};

const useMutationUpdateClientCapacity = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, UpdateClientCapacityMutateVariables>(updateClientCapacity, {
    onSuccess: (_, { therapistID }) => {
      queryClient.invalidateQueries(availabilityKeys.capacity(therapistID));
    },
  });
};

export default useMutationUpdateClientCapacity;
