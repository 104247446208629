import {
  View,
  ExtraHuge,
  Large,
  Button,
  Small,
  Link,
  Tiny,
  useEmotionTheme,
  CheckV2,
  Standard,
  Input,
} from '@talkspace/react-toolkit';
import { useState } from 'react';
import validateEmail from 'ts-frontend/utils/validateEmail';
import styled from '../../core/styled';
import useWindowWidth from '../../core/hooks/useWindowWidth';
import { ErrorBanner, Spinner } from '../Login/Login';

const SubmitButton = styled(Button)<{ disabled: boolean }>(
  ({
    theme: {
      colors,
      window: { isMobile },
    },
    disabled,
  }) => {
    return {
      fontSize: 16,
      fontWeight: 'bold',
      width: isMobile ? '100%' : 430,
      marginTop: 20,
      color: colors.white,
      background: disabled ? colors.paleBluGray : colors.accessibilityGreenDark,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    };
  }
);

const Label = styled(Tiny)(({ theme: { colors } }) => {
  return {
    color: colors.grey950,
    fontSize: 12,
    fontWeight: 400,
    textAlign: 'left !important',
    marginBottom: 4,
  };
});

const TextWrapper = styled(View)<{ isMobile: boolean }>(({ isMobile }) => {
  return {
    display: isMobile ? 'inline-block' : 'flex',
  };
});

const StyledInput = styled(Input)(
  ({
    theme: {
      colors,
      window: { isMobile },
    },
  }) => {
    return {
      width: isMobile ? '100%' : 430,
      backgroundColor: colors.permaPhantomWhite,
      borderRadius: 8,
      padding: 12,
      fontSize: 16,
      border: `1px solid ${colors.periwinkleGrey}`,
      height: 48,
    };
  }
);

const ForgotPassword = ({
  isLoading,
  showCheckmark,
  error,
  forgotPassword,
  email,
  setIsEmailValid,
  onEmailChanged,
}) => {
  const { isMobile } = useWindowWidth();
  const { colors } = useEmotionTheme();
  const [emailError, setEmailError] = useState<string>('');

  const handleBlur = () => {
    if (!validateEmail(email)) {
      setEmailError('Invalid email. Please enter a valid email.');
      setIsEmailValid(false);
    } else {
      setEmailError('');
      setIsEmailValid(true);
    }
  };

  const onChange = (changeHandler) => (e) => {
    changeHandler(e.target.value);
  };

  const renderButtonText = () => {
    if (isLoading) {
      return <Spinner />;
    }
    if (showCheckmark) {
      return (
        <View row align="center">
          <CheckV2 color={colors.white} style={{ marginRight: 6 }} />
          <Standard style={{ color: colors.white }}>Password reset sent</Standard>
        </View>
      );
    }
    return 'Request password reset';
  };

  const errorText = error || emailError;

  return (
    <View align="center">
      <View
        align="center"
        style={{
          marginTop: isMobile ? 30 : 60,
          marginBottom: 29,
        }}
      >
        <ExtraHuge style={{ marginBottom: 16, fontWeight: 700 }}>Forgot password?</ExtraHuge>
        <View
          style={{
            textAlign: 'center',
            width: isMobile ? 335 : 425,
          }}
        >
          <TextWrapper isMobile={isMobile}>
            <Large inline={isMobile} style={{ color: colors.purple600 }}>
              Please enter the email address you used to register. We'll send a link with
              instructions to reset your password.
            </Large>
          </TextWrapper>
        </View>
      </View>
      <View align="center">
        <View style={{ width: '100%' }}>
          <Label>Email</Label>
          <StyledInput
            type="email"
            value={email}
            placeholder="Email"
            onChange={onChange(onEmailChanged)}
            onSubmitEditing={forgotPassword}
            onBlur={handleBlur}
            dataQa="forgotPasswordEmailInput"
          />
        </View>

        {errorText && (
          <ErrorBanner style={{ marginTop: 24 }} data-qa="forgotPasswordErrorBanner">
            {errorText}
          </ErrorBanner>
        )}
        <SubmitButton
          onPress={forgotPassword}
          disabled={isLoading || showCheckmark}
          dataQa="requestPasswordResetButton"
        >
          {renderButtonText()}
        </SubmitButton>
        <View style={{ marginTop: 24 }}>
          <Small style={{ fontSize: 16, color: colors.purple600 }}>
            Need help?{' '}
            <Link
              target="_blank"
              href="https://providers-help.talkspace.com/hc/en-us/articles/360032255551"
              style={{ marginLeft: 0, marginRight: 0 }}
              dataQa="contactCustomerSupportLink"
            >
              <Small
                inline
                style={{
                  textDecoration: 'underline',
                  color: colors.accessibilityGreenDark,
                  fontWeight: 700,
                  fontSize: 16,
                }}
              >
                Contact provider support
              </Small>
            </Link>
          </Small>
        </View>
      </View>
    </View>
  );
};

export default ForgotPassword;
