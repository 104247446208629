import { FunctionComponent } from 'react';
import Svg, { Path } from 'svgs';
import { useEmotionTheme, EmotionStyle } from '../../core/styled';

interface IconProps {
  width?: number;
  height?: number;
  style?: EmotionStyle;
}

const SmallVideoSessionIcon: FunctionComponent<IconProps> = ({
  width = 19,
  height = 12,
  ...otherProps
}) => {
  const {
    colors: { purple },
  } = useEmotionTheme();
  const titleText = 'video session icon';
  return (
    <Svg width={width} height={height} viewBox="0 0 19 12" title={titleText} {...otherProps}>
      <Path
        d="M55.77 723a2.822 2.822 0 012.823 2.822v6.356A2.822 2.822 0 0155.771 735h-6.949A2.822 2.822 0 0146 732.178v-6.356A2.822 2.822 0 0148.822 723h6.949zm9.093 2.27c.09.165.137.35.137.539v5.941a1.129 1.129 0 01-1.668.992l-3.265-1.774a1.129 1.129 0 01-.59-.992v-2.393c0-.413.226-.794.59-.991l3.265-1.775a1.129 1.129 0 011.531.453z"
        transform="translate(-46 -723)"
        fill={purple}
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </Svg>
  );
};

export default SmallVideoSessionIcon;
