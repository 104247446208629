import { useTheme } from 'emotion-theming';
import type { EmotionTheme } from './EmotionThemeProvider';
import { parseVersionKey } from '../utils/semanticVersioning';

function useThemeVersion() {
  const key = useTheme<EmotionTheme>().themeVersion || '';
  return parseVersionKey(key);
}

export default useThemeVersion;
