import Svg, { Path } from 'svgs';
import { FunctionComponent } from 'react';

import { useEmotionTheme, EmotionStyle } from '../../core/styled';

interface AudioModalityProps {
  width?: number;
  height?: number;
  color?: string;
  style?: EmotionStyle;
  fill?: boolean;
  dataQa?: string;
}

const AudioModality: FunctionComponent<AudioModalityProps> = ({
  width = 20,
  height = 20,
  color,
  style,
  fill,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'audio';
  return (
    <Svg
      width={width}
      height={height}
      aria-label={titleText}
      title={titleText}
      style={style}
      viewBox="0 0 20 20"
      fill="none"
      {...otherProps}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.52968 1.75505C5.17896 1.29934 5.59214 1.12845 6.30044 1.01452C7.48094 0.729702 9.66487 4.71716 8.72047 5.74251C8.36632 6.14125 7.89412 6.08429 7.06777 6.42607C6.89069 8.98944 9.7239 13.3187 12.203 14.2871C12.7932 13.7174 13.0293 13.3187 13.5015 13.1478C14.9181 12.806 17.6333 16.4517 16.8659 17.3631C16.3937 17.8758 16.0986 18.1606 15.3903 18.5593C9.90097 21.9202 -1.01868 5.05894 4.52968 1.75505Z"
        fill={fill ? color || colors.slateGrey : colors.white}
        stroke={color || colors.slateGrey}
        strokeWidth="1.5"
      />
    </Svg>
  );
};

export default AudioModality;
