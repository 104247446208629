import {
  ScriptsState,
  REQUEST_GET_SCRIPTS,
  REQUEST_GET_SCRIPTS_ERROR,
  RECEIVE_GET_SCRIPTS,
  REQUEST_POST_EDIT_SCRIPT,
  REQUEST_POST_EDIT_SCRIPT_ERROR,
  RECEIVE_POST_EDIT_SCRIPT,
  REQUEST_POST_RESTORE_SCRIPT,
  REQUEST_POST_RESTORE_SCRIPT_ERROR,
  RECEIVE_POST_RESTORE_SCRIPT,
  REQUEST_POST_DELETE_SCRIPT,
  REQUEST_POST_DELETE_SCRIPT_ERROR,
  RECEIVE_POST_DELETE_SCRIPT,
  REQUEST_POST_CREATE_SCRIPT,
  REQUEST_POST_CREATE_SCRIPT_ERROR,
  RECEIVE_POST_CREATE_SCRIPT,
  ScriptsActionTypes,
} from '../constants/scriptsTypes';

const initialState: ScriptsState = {
  scriptCategories: [],
  isUpdating: false,
  isError: false,
};

const scriptsReducer = (state = initialState, action: ScriptsActionTypes): ScriptsState => {
  switch (action.type) {
    case REQUEST_GET_SCRIPTS:
    case REQUEST_POST_EDIT_SCRIPT:
    case REQUEST_POST_RESTORE_SCRIPT:
    case REQUEST_POST_DELETE_SCRIPT:
    case REQUEST_POST_CREATE_SCRIPT:
      return {
        ...state,
        isUpdating: true,
        isError: false,
      };

    case REQUEST_GET_SCRIPTS_ERROR:
    case REQUEST_POST_EDIT_SCRIPT_ERROR:
    case REQUEST_POST_RESTORE_SCRIPT_ERROR:
    case REQUEST_POST_DELETE_SCRIPT_ERROR:
    case REQUEST_POST_CREATE_SCRIPT_ERROR:
      return {
        ...state,
        isUpdating: false,
        isError: true,
      };
    case RECEIVE_GET_SCRIPTS:
    case RECEIVE_POST_EDIT_SCRIPT:
    case RECEIVE_POST_RESTORE_SCRIPT:
    case RECEIVE_POST_DELETE_SCRIPT:
    case RECEIVE_POST_CREATE_SCRIPT:
      return {
        ...state,
        isUpdating: false,
        isError: false,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default scriptsReducer;
