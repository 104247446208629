import { useReducer } from 'react';

const requestActionPayload = {
  isLoading: true,
  isError: false,
};

const receiveActionPayload = {
  isLoading: false,
  isError: false,
};

const errorActionPayload = {
  isLoading: false,
  isError: true,
};

// TOOD: Use template literal types when we have Babel 7.12+
// https://devblogs.microsoft.com/typescript/announcing-typescript-4-1/#template-literal-types
const loadingActionPayloadPrefixed = (prefix: string) => {
  return {
    [`is${prefix}Loading`]: true,
    [`is${prefix}Error`]: false,
  };
};

const errorActionPayloadPrefixed = (prefix: string) => {
  return {
    [`is${prefix}Loading`]: false,
    [`is${prefix}Error`]: true,
  };
};

export type PayloadBooleans = {
  isLoading: boolean;
  isError: boolean;
};

export type ActionType = {
  type: string;
  payload?: Record<string, unknown>;
};

export default function useSimpleReducer<State extends PayloadBooleans, Actions extends ActionType>(
  initialState: State
) {
  function reducer(currentState: State, action: Actions) {
    return { ...currentState, ...action.payload };
  }
  return useReducer(reducer, initialState);
}

export {
  requestActionPayload,
  receiveActionPayload,
  errorActionPayload,
  loadingActionPayloadPrefixed,
  errorActionPayloadPrefixed,
};
