/* eslint-disable react-hooks/exhaustive-deps */
import { FunctionComponent, useRef, useState, useEffect } from 'react';
import * as React from 'react';
import { keyframes } from '@emotion/core';
import styled, { EmotionStyle } from '../../core/styled';
import View from '../View';
import Input from '../Input';

const DEFAULT_LENGTH = 4;

const shrink = keyframes({
  '0%, 100%': { transform: 'scale(1) translateY(0)' },
  '50%': { transform: 'scale(0.8) translateY(10px)' },
});

const shake = keyframes({
  '0%, 100%': {
    transform: 'translateX(0)',
  },
  '25%, 75%': {
    transform: 'translateX(-10px)',
  },
  '50%': {
    transform: 'translateX(10px)',
  },
});

const StyledInput = styled(Input)<{ isNotEmpty: boolean; isError: boolean }>(
  ({ theme: { colors }, isNotEmpty, isError }) => {
    return {
      borderRadius: '50%',
      height: 46,
      width: 46,
      caretColor: 'transparent',
      paddingLeft: 0,
      textAlign: 'center',
      border: `2px solid ${isError ? 'rgba(207, 0, 95, 0.6);' : colors.slateGrey}`,
      boxShadow: 'none',
      color: 'transparent',
      outline: 'none !important',
      '&:focus': {
        boxShadow: 'none !important',
      },
      ...(isNotEmpty && {
        border: 'none',
        background: colors.accessibilityGreenDark,
        animation: `${shrink} 0.1s ease-in-out alternate`,
      }),
    };
  }
);

const PasscodeInputContainer = styled(View)<{ isError: boolean }>(({ isError }) => {
  return {
    flexDirection: 'row',
    justifyContent: 'center',
    columnGap: 16,
    ...(isError && { animation: `${shake} 0.5s ease-in-out` }),
  };
});

interface PasscodeProps {
  isError: boolean;
  ariaRequired?: boolean;
  ariaDescribedBy?: string;
  onChange: (verificationCode: string) => void;
  allowLetters?: boolean;
  inputLength?: number;
  style?: EmotionStyle;
  autoFocus?: boolean;
  isDisabled?: boolean;
}

const Passcode: FunctionComponent<PasscodeProps> = ({
  isError = false,
  ariaRequired = false,
  ariaDescribedBy,
  onChange,
  allowLetters = false,
  inputLength = DEFAULT_LENGTH,
  style = {},
  autoFocus = true,
  isDisabled = false,
}) => {
  const initialValue = [...Array(inputLength)].map(() => '');
  const initialInputRefsArr = [...Array(inputLength)].map(() => null);
  const [verificationCode, setVerificationCode] = useState<Array<string>>(initialValue);
  const inputRefs = useRef<Array<HTMLInputElement | null>>(initialInputRefsArr);
  const initialFocus = useRef(false);
  const alphaNumericRegex = /^[a-zA-Z0-9]+$/;

  useEffect(() => {
    if (isError) {
      setTimeout(() => {
        setVerificationCode(initialValue);
        inputRefs.current[inputLength - 1]?.blur();
        inputRefs.current[0]?.focus({ preventScroll: true });
      }, 500);
    }
  }, [isError]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (autoFocus && inputRefs.current && !initialFocus.current) {
        inputRefs.current[0]?.focus({ preventScroll: true });
        initialFocus.current = true;
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [autoFocus]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    let backspacePressed = false;
    let userInput = e.key;

    if (isDisabled) {
      return;
    }

    if (userInput === 'ArrowLeft') {
      inputRefs.current[index - 1]?.focus();
      return;
    }
    if (userInput === 'ArrowRight') {
      inputRefs.current[index + 1]?.focus();
      return;
    }

    if (userInput === 'Backspace') {
      userInput = '';
      backspacePressed = true;
    } else if (!Number.isFinite(Number(userInput)) && !allowLetters) {
      return;
    } else if (allowLetters && (!alphaNumericRegex.test(userInput) || userInput.length > 1)) {
      return;
    }

    if (!backspacePressed && index < inputLength - 1) {
      inputRefs.current[index + 1]?.focus({ preventScroll: true });
    } else if (backspacePressed && index - 1 >= 0) {
      inputRefs.current[index - 1]?.focus({ preventScroll: true });
    }

    const verificationCodeCopy = [...verificationCode];
    const hasNonEmptyValue = verificationCode.some((item) => item !== '');

    if (!hasNonEmptyValue && index !== 0) {
      inputRefs.current[1]?.focus();
      verificationCodeCopy[0] = userInput;
    } else {
      verificationCodeCopy[index] = userInput;
    }

    setVerificationCode(verificationCodeCopy);
    if (onChange) {
      onChange(verificationCodeCopy.join(''));
    }
  };

  return (
    <>
      <PasscodeInputContainer align="center" style={{ ...style }} isError={isError}>
        {verificationCode.map((code, i) => (
          <StyledInput
            isError={isError}
            isNotEmpty={!!code}
            dataQa={`verificationCodeInput${i}`}
            inputMode="tel"
            type="tel"
            pattern="[0-9]"
            aria-invalid={!!isError}
            aria-required={ariaRequired}
            aria-describedby={ariaDescribedBy && isError ? ariaDescribedBy : undefined}
            ref={(ref) => {
              if (ref) {
                inputRefs.current[i] = ref;
                if (isDisabled) {
                  ref.blur();
                }
              }
            }}
            aria-label={`Input verification code ${i + 1}`}
            maxLength={1}
            max={9}
            min={0}
            value={code}
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            onKeyDown={(e) => handleKeyDown(e, i)}
          />
        ))}
      </PasscodeInputContainer>
    </>
  );
};

export default Passcode;
