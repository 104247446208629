import SocketService from '@/utils/socket/SocketService';

export interface RoomActivationPayload {
  roomID: number;
}

export default class RoomActivationSocketService {
  roomID: number;

  handleRoomActivated: Function;

  io: SocketService;

  constructor(roomID: number, handleRoomActivated: Function) {
    this.roomID = roomID;
    this.handleRoomActivated = handleRoomActivated;
    this.io = SocketService.instance();
    this.socketInit();
  }

  private socketInit = () => {
    this.io.on('roomActivated', this.roomActivatedHandler);
  };

  public unmount = () => {
    this.io.off('roomActivated', this.roomActivatedHandler);
  };

  private roomActivatedHandler = (data: RoomActivationPayload) => {
    if (Number(data.roomID) === this.roomID) {
      this.handleRoomActivated(data.roomID);
    }
  };
}
