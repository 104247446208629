import { FunctionComponent, useRef, useEffect } from 'react';
import styled, { EmotionStyle } from '../../core/styled';
import View from '../View';

export interface A11yMenuWrapperProps {
  id?: string;
  ariaLabelledBy?: string;
  style?: EmotionStyle;
}

const StyledView = styled(View)<{ style?: EmotionStyle }>(({ style }) => {
  return {
    ...style,
    '&:focus': {
      outline: 'none',
    },
  };
});

// TODO: build a more robust reusable menu component
//      - container/wrapping element should be semantic <ul>
//      - Each option should be wrapped in <li role="none">{option [role="menuitem"]}<li>
//      - only the first option should be in the tab order (tabindex="0"), all others should be programmaticallly focusable (tabindex="-1") via arrow keys
//      - tabbing out of the menu should close the menu

// Wraps the contents of any sort of pop up/dropdown menu. Selectable options within the menu must be given role="menuitem"
const A11yMenuWrapper: FunctionComponent<A11yMenuWrapperProps> = ({
  id,
  ariaLabelledBy,
  style,
  children,
}) => {
  const menuContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    let currentRef;
    let eventHandler;
    if (menuContainerRef.current) currentRef = menuContainerRef.current;
    if (currentRef) {
      const menuItemGroup = Array.from(currentRef.querySelectorAll('[role="menuitem"]'));
      // allows for arrow keys to navigate between the menu items and traps arrow key focus
      eventHandler = (e: KeyboardEvent) => {
        const index =
          document.activeElement instanceof HTMLElement
            ? menuItemGroup.indexOf(document.activeElement)
            : 0;
        let nextIndex = 0;
        if (e.key === 'ArrowUp') {
          e.preventDefault();
          e.stopPropagation();
          nextIndex = index > 0 ? index - 1 : menuItemGroup.length - 1;
          (menuItemGroup[nextIndex] as HTMLElement)?.focus?.();
        } else if (e.key === 'ArrowDown') {
          e.preventDefault();
          e.stopPropagation();
          nextIndex = index + 1 < menuItemGroup.length ? index + 1 : 0;
          (menuItemGroup[nextIndex] as HTMLElement)?.focus?.();
        }
      };
      currentRef.addEventListener('keydown', eventHandler);
    }
    return () => {
      if (menuContainerRef && currentRef && eventHandler)
        currentRef.removeEventListener('keydown', eventHandler);
    };
  }, []);

  return (
    <StyledView
      role="menu"
      id={id}
      aria-labelledby={ariaLabelledBy}
      tabIndex={-1}
      style={style}
      ref={menuContainerRef}
    >
      {children}
    </StyledView>
  );
};

export default A11yMenuWrapper;
