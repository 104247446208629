import { VoidFunctionComponent } from 'react';
import usePaginationRange, { DOTS } from './usePaginationRange';
import styled, { EmotionStyle } from '../../core/styled';
import TouchableView from '../TouchableView';
import { Small } from '../Typography';
import View from '../View';
import TabRadioGroup from '../TabRadioGroup';

interface PaginationProps {
  canNextPage: boolean;
  canPreviousPage: boolean;
  pageSizes: number[];
  pageSize: number;
  pageCount: number;
  pageIndex: number;
  isMobile: boolean;
  setPageSize: (pageSize: number) => void;
  nextPage: () => void;
  previousPage: () => void;
  gotoPage: (updater: number | ((pageIndex: number) => number)) => void;
}

const PaginationButton = styled(TouchableView)<{ isActive?: boolean; disabled?: boolean }>(
  ({ theme: { colors }, isActive, disabled }) => {
    const activeStyle = isActive && {
      background: colors.cyanBlue,
      '& > p': { color: colors.white },
    };
    const disabledStyle = disabled && { '& > p': { color: colors.gray } };
    const hoverStyle = !isActive && { background: colors.whisperGray, color: colors.bahamaBlue };

    return {
      padding: '6px 12px',
      border: `solid ${colors.lightShadeGray} 1px`,
      marginLeft: -1,
      '& > p': {
        color: colors.cyanBlue,
      },
      '&:first-of-type': {
        borderTopLeftRadius: '4px',
        borderBottomLeftRadius: '4px',
      },
      '&:last-child': {
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '4px',
      },
      '&:hover': {
        ...hoverStyle,
      },
      ...activeStyle,
      ...disabledStyle,
    };
  }
);

const EllipseButton = styled(View)(({ theme: { colors } }) => {
  return {
    color: colors.cyanBlue,
    padding: '6px 12px',
    border: `solid ${colors.lightShadeGray} 1px`,
    marginLeft: -1,
    pointerEvents: 'none',
    userSelect: 'none',
  };
});

const PaginationText = styled(Small)(() => {
  return {
    fontWeight: 400,
  };
});

const PageSizeButton = styled(TouchableView)<{ isActive: boolean }>(
  ({ theme: { colors }, isActive }) => {
    const activeButtonStyle: EmotionStyle = isActive
      ? {
          background: colors.whisperGray,
          boxShadow: 'inset 0 3px 5px rgb(0 0 0 / 13%)',
          borderColor: colors.permaFauxSilver,
          zIndex: 1,
        }
      : {};

    return {
      padding: '6px 12px',
      border: `solid ${colors.lightShadeGray} 1px`,
      marginLeft: -1,
      '& > p': {
        color: colors.black,
      },
      '&:first-of-type': {
        borderTopLeftRadius: '4px',
        borderBottomLeftRadius: '4px',
      },
      '&:last-child': {
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '4px',
      },
      ...activeButtonStyle,
    };
  }
);

const PaginationContainer = styled(View)<{ isMobile: boolean; hasPaginationItems: boolean }>(
  ({ isMobile, hasPaginationItems }) => {
    const desktopStyle: EmotionStyle = !isMobile
      ? {
          flexDirection: 'row',
          justifyContent: hasPaginationItems ? 'space-between' : 'flex-end',
        }
      : {};

    return {
      paddingTop: 18,
      paddingBottom: 18,
      ...desktopStyle,
    };
  }
);

const Pagination: VoidFunctionComponent<PaginationProps> = ({
  canPreviousPage,
  canNextPage,
  pageSizes,
  pageSize,
  pageCount,
  pageIndex,
  isMobile = false,
  setPageSize,
  nextPage,
  previousPage,
  gotoPage,
}) => {
  const paginationRange: (string | number)[] = usePaginationRange({
    currentPage: pageIndex,
    pageCount,
  });

  const buildPaginationItems = () => {
    const paginationRangeItems = paginationRange.map((page, index) => {
      if (page !== DOTS) {
        const isActivePage: boolean = pageIndex === page;

        const ariaLabelText =
          pageCount - 1 !== page ? `Page ${+page + 1}` : `last page, page ${page}`;

        return (
          <PaginationButton key={page} onPress={() => gotoPage(+page)} isActive={isActivePage}>
            <PaginationText
              aria-label={ariaLabelText}
              {...(isActivePage && { 'aria-current': 'page' })}
            >
              {+page + 1}
            </PaginationText>
          </PaginationButton>
        );
      }

      // eslint-disable-next-line react/no-array-index-key
      return <EllipseButton key={`ellipses${index}`}>{page}</EllipseButton>;
    });
    return paginationRangeItems;
  };

  const paginationItems = buildPaginationItems();
  const hasPaginationItems = paginationItems.length > 0;

  return (
    <PaginationContainer isMobile={isMobile} hasPaginationItems={hasPaginationItems}>
      {hasPaginationItems && (
        <TabRadioGroup style={{ flexDirection: 'row', flexWrap: 'wrap', margin: 0, padding: 0 }}>
          <PaginationButton disabled={!canPreviousPage} onPress={() => gotoPage(0)}>
            <PaginationText aria-label="First">«</PaginationText>
          </PaginationButton>
          <PaginationButton disabled={!canPreviousPage} onPress={previousPage}>
            <PaginationText aria-label="Previous">‹</PaginationText>
          </PaginationButton>
          {paginationItems}
          <PaginationButton disabled={!canNextPage} onPress={nextPage}>
            <PaginationText aria-label="Next">›</PaginationText>
          </PaginationButton>
          <PaginationButton disabled={!canNextPage} onPress={() => gotoPage(pageCount - 1)}>
            <PaginationText aria-label="Last">»</PaginationText>
          </PaginationButton>
        </TabRadioGroup>
      )}
      <TabRadioGroup
        style={{
          flexDirection: 'row',
          flexWrap: 'wrap',
          margin: 0,
          padding: 0,
          ...(isMobile && { marginTop: 10, justifyContent: 'flex-end' }),
        }}
      >
        {pageSizes.map((size) => (
          <PageSizeButton
            key={size}
            aria-label={`Page size ${size}`}
            onPress={() => setPageSize(size)}
            isActive={size === pageSize}
          >
            <PaginationText>{size}</PaginationText>
          </PageSizeButton>
        ))}
      </TabRadioGroup>
    </PaginationContainer>
  );
};

export default Pagination;
