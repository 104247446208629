import Svg, { Path } from 'svgs';
import { FunctionComponent } from 'react';
import { useEmotionTheme } from '../../core/styled';

interface PlusIconProps {
  width?: number;
  height?: number;
  color?: string;
}

const PlusIcon: FunctionComponent<PlusIconProps> = ({
  width = 12,
  height = 12,
  color,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();

  return (
    <Svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      {...otherProps}
    >
      <Path
        d="M1.00012 6.29577L5.65907 6.29368L5.65965 1.63625"
        stroke={color || colors.accessibilityGreenDark}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <Path
        d="M10.324 6.30074L5.66503 6.30283L5.66445 10.9603"
        stroke={color || colors.accessibilityGreenDark}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </Svg>
  );
};

export default PlusIcon;
