import Svg, { Path } from 'svgs';
import { useEmotionTheme } from '../../core/styled';

const RoundClock = ({
  width = 21,
  height = 21,
  backgroundColor,
  strokeWidth = 0,
  strokeColor,
  handsColor,
  handsWidth = 2,
  ...props
}: {
  width?: number;
  height?: number;
  backgroundColor?: string;
  strokeWidth?: number;
  strokeColor?: string;
  handsColor?: string;
  handsWidth?: number;
}) => {
  const { colors } = useEmotionTheme();
  return (
    <Svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" {...props}>
      <Path
        d="M11 20.5a9.5 9.5 0 100-19 9.5 9.5 0 000 19z"
        fill={backgroundColor || colors.goldenTanoi}
        stroke={strokeColor || colors.white}
        strokeWidth={strokeWidth}
      />
      <Path
        d="M10.5 7v5l3.399 2"
        stroke={handsColor || colors.white}
        strokeWidth={handsWidth}
        strokeLinecap="round"
      />
    </Svg>
  );
};

export default RoundClock;
