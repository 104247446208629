import {
  InputState,
  REQUEST_POST_MESSAGE,
  RECEIVE_POST_MESSAGE,
  REQUEST_POST_MESSAGE_ERROR,
  REQUEST_POST_MEDIA_MESSAGE,
  RECEIVE_POST_MEDIA_MESSAGE,
  REQUEST_POST_MEDIA_MESSAGE_ERROR,
  REQUEST_CREATE_VIDEO_CALL,
  RECEIVE_CREATE_VIDEO_CALL,
  REQUEST_CREATE_VIDEO_CALL_ERROR,
  REQUEST_CREATE_VIDEO_CALL_ERROR_ROOM,
  InputActionTypes,
} from '../constants/inputTypes';

const initialState: InputState = {
  inputs: [],
  isUpdating: false,
  isError: false,
  isVideoError: false,
  videoErrorRoom: 0,
  hasNoCredits: false,
  isCallInProgress: false,
  videoCallErrorMessage: undefined,
};

const inputReducer = (state = initialState, action: InputActionTypes): InputState => {
  switch (action.type) {
    case REQUEST_POST_MESSAGE:
    case REQUEST_POST_MEDIA_MESSAGE:
      return {
        ...state,
        isUpdating: true,
        isError: false,
      };
    case REQUEST_CREATE_VIDEO_CALL:
      return {
        ...state,
        isUpdating: true,
        isVideoError: false,
      };
    case RECEIVE_POST_MESSAGE:
    case RECEIVE_POST_MEDIA_MESSAGE:
      return {
        ...state,
        isUpdating: false,
        isError: false,
      };
    case RECEIVE_CREATE_VIDEO_CALL:
      return {
        ...state,
        isVideoError: false,
        isUpdating: false,
        ...action.payload,
      };

    case REQUEST_POST_MESSAGE_ERROR:
    case REQUEST_POST_MEDIA_MESSAGE_ERROR:
      return {
        ...state,
        isUpdating: false,
        isError: true,
      };
    case REQUEST_CREATE_VIDEO_CALL_ERROR:
      return {
        ...state,
        isUpdating: false,
        isVideoError: true,
        ...action.payload,
      };
    case REQUEST_CREATE_VIDEO_CALL_ERROR_ROOM:
      return {
        ...state,
        videoErrorRoom: action.payload,
      };
    default:
      return state;
  }
};

export default inputReducer;
