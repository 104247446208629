import { useMutation } from 'react-query';

import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';

interface CreateRoomCaseParams {
  roomID: number;
}

const createRoomCase = async (params: CreateRoomCaseParams): Promise<void> => {
  const { roomID } = params;

  await apiWrapper.post(`${apiHelper().apiEndpoint}/v3/rooms/${roomID}/cases`, {});
};

const useMutationCreateRoomCase = () =>
  useMutation<void, Error, CreateRoomCaseParams>(createRoomCase);

export default useMutationCreateRoomCase;
