import { useEmotionTheme } from '@talkspace/react-toolkit';
import Svg, { G, Path } from 'svgs';

interface ChatIconProps {
  width?: number;
  height?: number;
  isActive?: boolean;
}

function ChatIcon({ width = 17, height = 14, isActive = false, ...otherProps }: ChatIconProps) {
  const { colors } = useEmotionTheme();
  return (
    <Svg width={width} height={height} viewBox="0 0 17 14" {...otherProps}>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        {isActive ? (
          <>
            <Path
              d="M700.25 107.25H-.25V-.25h700.5v107.5z"
              stroke={colors.heatherGrey}
              strokeWidth={0.5}
              fill={colors.white}
              transform="translate(-440 -81)"
            />
            <Path
              d="M2.991 12.333L0 9.17l2.991-3.297V4.42A2.416 2.416 0 015.415 2h9.16A2.417 2.417 0 0117 4.423v9.154A2.417 2.417 0 0114.576 16h-9.16a2.421 2.421 0 01-2.425-2.425v-1.242z"
              transform="translate(0 -2)"
              fillRule="nonzero"
              fill={colors.darkGreen}
            />
          </>
        ) : (
          <>
            <Path
              d="M700.25 107.25H-.25V-.25h700.5v107.5z"
              stroke={colors.heatherGrey}
              strokeWidth={0.5}
              fill={colors.white}
              transform="translate(-440 -81)"
            />
            <Path
              d="M14.576 17h-9.16a1.42 1.42 0 01-1.425-1.425v-1.64l-2.628-2.779L3.991 8.26V6.42A1.416 1.416 0 015.415 5h9.16A1.417 1.417 0 0116 6.423l.001 9.154A1.42 1.42 0 0114.576 17z"
              transform="translate(-440 -81) translate(440 77)"
              fillRule="nonzero"
              stroke="#7F8DA4"
              strokeWidth={2}
            />
          </>
        )}
      </G>
    </Svg>
  );
}

export default ChatIcon;
