import {
  FloatingMenu,
  View,
  useWindowWidth,
  CloseButton,
  EmotionStyle,
} from '@talkspace/react-toolkit';

const getFloatingMenuStyle = (isMobile: boolean) => {
  return {
    marginRight: isMobile ? 10 : 0,
    width: isMobile ? 355 : 450,
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: 'fit-content',
    maxHeight: 'unset',
    boxShadow: '0px 7px 16px rgba(0, 29, 47, 0.38)',
  };
};

const ModalContainer = ({
  onBackdropPress,
  hide,
  children,
  style,
}: {
  onBackdropPress: () => void;
  hide: boolean;
  children: any;
  style?: EmotionStyle;
}) => {
  const { isMobile } = useWindowWidth();

  return (
    <FloatingMenu
      style={{ ...getFloatingMenuStyle(isMobile), ...style }}
      hide={hide}
      onBackdropPress={onBackdropPress}
    >
      <View row justify="end" style={{ marginTop: 12, marginRight: 12 }}>
        <CloseButton onPress={onBackdropPress} width={31} height={31} />
      </View>
      {children}
    </FloatingMenu>
  );
};

export default ModalContainer;
