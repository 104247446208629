import Svg, { Path } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';
import { EmotionStyle } from '@/core/styled';

const Star = ({
  fillColor,
  strokeColor,
  width = 19,
  height = 20,
  style,
  ...otherProps
}: {
  fillColor?: string;
  strokeColor?: string;
  width?: number;
  height?: number;
  style?: EmotionStyle;
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'star';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      {...otherProps}
      {...style}
    >
      <Path
        d="M926.5 475.525l-4.932 1.756.064-5.458-3.072-4.333 4.916-1.61 3.024-4.492 3.024 4.493 4.916 1.61-3.072 4.332.064 5.458-4.932-1.756zm-4.868-3.702v-.04c0 .026.008.05.022.072l-.022-.032zm1.844-5.942l.039-.013a.12.12 0 00-.062.047l.023-.034z"
        fill={fillColor || colors.gold}
        fillRule="evenodd"
        stroke={strokeColor || colors.gold}
        strokeWidth="1.8"
        transform="translate(-917 -459)"
      />
    </Svg>
  );
};

export default Star;
