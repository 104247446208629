/* eslint-disable camelcase */
// Back-end sends these fields as camel-case
export enum ScriptMessageType {
  SYSTEM = 'system',
  SYSTEM_MODIFIED = 'system_modified',
  THERAPIST = 'therapist',
}
export enum ScriptMessageStatus {
  ACTIVE = 'active',
  DELETED = 'deleted',
}

export interface ApiScriptMessage {
  id: number;
  message: string;
  itemPosition: number;
  messageTitle: string;
  messageType: ScriptMessageType;
  originatedFrom: number | null;
  status: ScriptMessageStatus;
}

interface ApiScriptCategory {
  categoryID: number;
  categoryName: string;
  messages: ApiScriptMessage[];
}

export type ApiCannedMessages = ApiScriptCategory[];

export class ScriptMessage {
  public status: ScriptMessageStatus;

  public position: number;

  public id: number;

  public text: string;

  public title: string;

  public type: ScriptMessageType;

  public originatedFrom: number | null;

  constructor(apiScriptMessage: ApiScriptMessage) {
    this.status = apiScriptMessage.status;
    this.position = apiScriptMessage.itemPosition;
    this.id = apiScriptMessage.id;
    this.text = apiScriptMessage.message;
    this.title = apiScriptMessage.messageTitle;
    this.type = apiScriptMessage.messageType;
    this.originatedFrom = apiScriptMessage.originatedFrom;
  }
}

export class ScriptCategory {
  public id: number;

  public name: string;

  public messages: ScriptMessage[];

  constructor(apiScriptCategory: ApiScriptCategory) {
    this.id = apiScriptCategory.categoryID;
    this.name = apiScriptCategory.categoryName;
    this.messages = apiScriptCategory.messages.map((m) => new ScriptMessage(m));
  }
}

export class ScriptsReplacementData {
  public therapistName: string;

  public userPlanType: string;

  public userName: string;

  constructor(therapistDisplayName: string, userPlanType: string, userName: string) {
    [this.therapistName] = therapistDisplayName.split(' ');
    this.userPlanType = userPlanType;
    this.userName = userName;
  }
}
