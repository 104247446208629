import { OfferCategory } from '../../entities/Offers';

export interface OffersState {
  offerCategories: OfferCategory[];
  isUpdating: boolean;
  isError: boolean;
}

export interface OffersStore {
  offers: OffersState;
}
export interface GetOffersPayload {
  offerCategories: OfferCategory[];
}

export const REQUEST_GET_OFFERS = 'REQUEST_GET_OFFERS';
export const REQUEST_GET_OFFERS_ERROR = 'REQUEST_GET_OFFERS_ERROR';
export const RECEIVE_GET_OFFERS = 'RECEIVE_GET_OFFERS';
export const REQUEST_POST_OFFER = 'REQUEST_POST_OFFER';
export const REQUEST_POST_OFFER_ERROR = 'REQUEST_POST_OFFER_ERROR';
export const RECEIVE_POST_OFFER = 'RECEIVE_POST_OFFER';

interface RequestGetOffersAction {
  type: typeof REQUEST_GET_OFFERS;
}

interface RequestGetOffersErrorAction {
  type: typeof REQUEST_GET_OFFERS_ERROR;
  error: Error;
}
interface ReceiveGetOffersAction {
  type: typeof RECEIVE_GET_OFFERS;
  payload: GetOffersPayload;
}

interface RequestPostOfferAction {
  type: typeof REQUEST_POST_OFFER;
}

interface RequestPostOfferErrorAction {
  type: typeof REQUEST_POST_OFFER_ERROR;
  error: Error;
}
interface ReceivePostOfferAction {
  type: typeof RECEIVE_POST_OFFER;
  payload: {};
}

export type OffersActionTypes =
  | RequestGetOffersAction
  | RequestGetOffersErrorAction
  | ReceiveGetOffersAction
  | RequestPostOfferAction
  | RequestPostOfferErrorAction
  | ReceivePostOfferAction;
