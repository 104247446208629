import Svg, { Path, G, Circle } from 'svgs';

const BookingIconWithX = ({ width = 69, height = 66, color = '#BCC4D3', ...otherProps }) => {
  const titleText = 'Calendar with X';
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 69 66"
      title={titleText}
      aria-label={titleText}
      {...otherProps}
    >
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Path
          d="M90.156 70.211c1.586 0 3.021.643 4.06 1.682a5.724 5.724 0 011.682 4.06h0v37.362a5.724 5.724 0 01-1.682 4.06 5.724 5.724 0 01-4.06 1.682h0H51.71a5.724 5.724 0 01-4.06-1.682 5.724 5.724 0 01-1.682-4.06h0V75.953c0-1.586.643-3.021 1.682-4.06a5.724 5.724 0 014.06-1.682h0zM55.705 61.968c.534 0 1.017.216 1.367.566.35.35.566.834.566 1.368h0v4.495h-3.867v-4.495c0-.534.216-1.018.566-1.368.35-.35.834-.566 1.368-.566zM86.162 61.968c.534 0 1.017.216 1.367.566.35.35.566.834.566 1.368h0v4.495h-3.867v-4.495c0-.534.216-1.018.566-1.368.35-.35.834-.566 1.368-.566z"
          stroke={color}
          strokeWidth={3.93590331}
          transform="translate(-40 -60)"
        />
        <G transform="translate(-40 -60)">
          <Circle cx={90.8780488} cy={108.390244} r={15.1219512} fill="#000" />

          <Circle
            stroke="#FFF"
            strokeWidth={2.26829268}
            fill={color}
            cx={90.8780488}
            cy={108.390244}
            r={16.2560976}
          />
        </G>
        <Path
          d="M86.306 113.707a1.097 1.097 0 01-.777-1.875l8.79-8.79a1.097 1.097 0 111.554 1.553l-8.79 8.79a1.096 1.096 0 01-.777.322z"
          fill="#FFF"
          fillRule="nonzero"
          transform="translate(-40 -60)"
        />
        <Path
          d="M95.096 113.707c-.281 0-.563-.108-.777-.322l-8.79-8.79a1.097 1.097 0 111.554-1.553l8.79 8.79a1.097 1.097 0 01-.777 1.875z"
          fill="#FFF"
          fillRule="nonzero"
          transform="translate(-40 -60)"
        />
      </G>
    </Svg>
  );
};

export default BookingIconWithX;
