import {
  REQUEST_GET_CUSTOMER_INFORMATION,
  RECEIVE_GET_CUSTOMER_INFORMATION,
  REQUEST_GET_CUSTOMER_INFORMATION_ERROR,
  REQUEST_SET_CUSTOMER_INFORMATION,
  RECEIVE_SET_CUSTOMER_INFORMATION,
  REQUEST_SET_CUSTOMER_INFORMATION_ERROR,
  RESET_CUSTOMER_INFORMATION,
} from '../constants/CustomerInformationConstants';

const initialState = {
  preferredName: undefined,
  gender: undefined,
  country: undefined,
  state: undefined,
  maritalStatus: undefined,
  ethnicity: undefined,
  clientUserID: undefined,
  city: undefined,
  dateOfBirth: undefined,
  educationLevel: undefined,
  firstName: undefined,
  lastName: undefined,
  middleName: undefined,
  phoneNumber: undefined,
  streetAddress: undefined,
  therapistGender: undefined,
  workStatus: undefined,
  zipcode: undefined,
  isRequestingCustomer: false,
  isPatchingCustomer: false,
  requestCustomerError: false,
  disabledComponents: [],
  invalidComponents: [],
  treatmentGoal: undefined,
};

export default function customerInformation(state = initialState, action) {
  switch (action.type) {
    case RESET_CUSTOMER_INFORMATION:
      return initialState;
    case REQUEST_GET_CUSTOMER_INFORMATION:
    case REQUEST_SET_CUSTOMER_INFORMATION:
      return {
        ...state,
        isRequestingCustomer: true,
        requestCustomerError: false,
      };
    case REQUEST_GET_CUSTOMER_INFORMATION_ERROR:
    case REQUEST_SET_CUSTOMER_INFORMATION_ERROR:
      return {
        ...state,
        isRequestingCustomer: false,
        requestCustomerError: true,
      };
    case RECEIVE_GET_CUSTOMER_INFORMATION:
    case RECEIVE_SET_CUSTOMER_INFORMATION:
      return {
        ...state,
        ...action.payload,
        isRequestingCustomer: false,
        requestCustomerError: false,
      };
    default:
      return state;
  }
}
