import * as actionTypes from '../constants/OutcomeMeasureConstants';

const initialState = {
  spinnerMessage: '',
  isUpdating: false,
  isError: false,
  outcomeMeasures: [],
  outcomeMeasureAnswers: {},
  outcomeMeasureOptions: [],
  isScheduled: true,
};

export default function outcomeMeasures(state = initialState, action) {
  switch (action.type) {
    case actionTypes.REQUEST_ROOM_OUTCOME_MEASURES:
    case actionTypes.REQUEST_OUTCOME_MEASURE_OPTIONS:
    case actionTypes.REQUEST_OUTCOME_MEASURE_ANSWERS:
    case actionTypes.REQUEST_CANCEL_OUTCOME_MEASURES:
    case actionTypes.REQUEST_START_OUTCOME_MEASURES:
      return {
        ...state,
        spinnerMessage: 'Loading',
        isUpdating: true,
        isError: false,
      };
    case actionTypes.RECEIVE_ROOM_OUTCOME_MEASURES:
      return {
        ...state,
        outcomeMeasures: action.outcomeMeasures,
        isScheduled: action.isScheduled,
        isUpdating: false,
        isError: false,
      };
    case actionTypes.RECEIVE_OUTCOME_MEASURE_OPTIONS:
      return {
        ...state,
        isUpdating: false,
        isError: false,
        outcomeMeasureOptions: action.outcomeMeasureOptions,
        initialLoadComplete: true,
      };
    case actionTypes.RECEIVE_OUTCOME_MEASURE_ANSWERS:
      return {
        ...state,
        isUpdating: false,
        isError: false,
        outcomeMeasureAnswers: action.answers,
      };
    case actionTypes.RECEIVE_CANCEL_OUTCOME_MEASURES:
      return {
        ...state,
        isUpdating: false,
        isError: false,
        isScheduled: action.isScheduled,
        spinnerMessage: 'Scheduling successfully stopped',
      };
    case actionTypes.RECEIVE_START_OUTCOME_MEASURES:
      return {
        ...state,
        isUpdating: false,
        isError: false,
        isScheduled: action.isScheduled,
        spinnerMessage: 'Scheduling successfully started',
      };
    case actionTypes.REQUEST_CANCEL_OUTCOME_MEASURES_ERROR:
    case actionTypes.REQUEST_OUTCOME_MEASURE_OPTIONS_ERROR:
    case actionTypes.REQUEST_START_OUTCOME_MEASURES_ERROR:
    case actionTypes.REQUEST_ROOM_OUTCOME_MEASURES_ERROR:
    case actionTypes.REQUEST_OUTCOME_MEASURE_ANSWERS_ERROR:
      return {
        ...state,
        isUpdating: false,
        isError: true,
        spinnerMessage: 'Something went wrong',
      };
    default:
      return state;
  }
}
