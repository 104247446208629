import { FunctionComponent } from 'react';
import Svg, { G, Path, Rect } from 'svgs';
import { EmotionStyle, useEmotionTheme } from '../../core/styled';

interface AddPartnerProps {
  color?: string;
  width?: number;
  style?: EmotionStyle;
}

const AddPartner: FunctionComponent<AddPartnerProps> = ({ color, width = 32, style }) => {
  const { colors } = useEmotionTheme();

  const titleText = 'Envelope';
  return (
    <Svg
      width={width}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 74 58"
      fill={color || colors.lightGray}
      style={style}
    >
      <G fill="none" fillRule="evenodd">
        <Rect
          stroke="#8F9CB2"
          strokeWidth="5"
          fill="#FFF"
          x="2.5"
          y="2.5"
          width="69"
          height="53"
          rx="12"
        />
        <Path
          stroke="#BCC7D0"
          strokeWidth="4"
          strokeLinecap="round"
          d="M14 15.5l23.46 21L60 15.5"
        />
      </G>
    </Svg>
  );
};

export default AddPartner;
