import { useQuery, UseQueryResult, UseQueryOptions } from 'react-query';
import moment from 'moment/moment';

import apiHelper from '@/core/api/apiHelper';
import apiWrapper from '@/core/api/apiWrapper';
import { DoseSpotPatient } from './types';
import { ONE_MINUTE_IN_MS } from './utils';
import { doseSpotPatientQueryKey } from './queryKeys';

interface FetchDoseSpotPatientParams {
  clientUserID: number;
}

const fetchDoseSpotPatient =
  ({ clientUserID }: FetchDoseSpotPatientParams) =>
  async (): Promise<DoseSpotPatient> => {
    const response = await apiWrapper.get(
      `${apiHelper().apiEndpoint}/v2/clients/${clientUserID}/dosespot-patient`
    );

    const dateOfBirthString = moment.utc(response.data.data.dateOfBirth).format('MM/DD/YYYY');
    return {
      ...response.data.data,
      dateOfBirth: dateOfBirthString,
    };
  };

const useQueryDoseSpotPatient = (
  params: FetchDoseSpotPatientParams,
  options: UseQueryOptions<DoseSpotPatient, Error, DoseSpotPatient> = { enabled: true }
): UseQueryResult<DoseSpotPatient, Error> =>
  useQuery<DoseSpotPatient, Error>({
    queryKey: doseSpotPatientQueryKey(params.clientUserID),
    queryFn: fetchDoseSpotPatient(params),
    staleTime: 5 * ONE_MINUTE_IN_MS,
    cacheTime: 10 * ONE_MINUTE_IN_MS,
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
    ...options,
  });

export default useQueryDoseSpotPatient;
