import { useQuery } from 'react-query';
import { UserVideoCredit } from 'ts-frontend/types';

import apiWrapper from '../utils/apiWrapper';
import apiHelper from '../utils/api';
import { videoCreditsQueryKey } from './queryKeys';

const fetchVideoCredits = (roomID: number) => async (): Promise<UserVideoCredit[]> => {
  const { data } = await apiWrapper.get(
    `${apiHelper().apiEndpoint}/api/v1/rooms/${roomID}/video-credits`
  );
  return data.data;
};

const useQueryVideoCredits = (roomID: number) =>
  useQuery<UserVideoCredit[], Error>({
    queryKey: videoCreditsQueryKey(roomID),
    queryFn: fetchVideoCredits(roomID),
    cacheTime: 0,
    staleTime: 0,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
  });

export default useQueryVideoCredits;
