import Svg, { Defs, Path, G, Use } from 'svgs';
import { useUniqueID } from '../../hooks/a11yHelper';
import { EmotionStyle } from '../../core/styled';

interface Props {
  width?: number;
  height?: number;
  isBackgroundDark?: boolean;
  style?: EmotionStyle;
}

const PictureInPicture = ({ width = 31, height, isBackgroundDark = false, style }: Props) => {
  const titleText = 'picture in picture';
  const defaultHeight = isBackgroundDark ? 27 : 25;

  const id1 = useUniqueID('pictureInPictureIcon');
  const id2 = useUniqueID('pictureInPictureIcon');

  return isBackgroundDark ? (
    <Svg
      width={width}
      height={height || defaultHeight}
      viewBox="0 0 33 27"
      title={titleText}
      aria-label={titleText}
      style={style}
    >
      <Defs>
        <Path
          d="M3.819 19.462h8.851v-2.14H3.906c-.559 0-.99-.147-1.296-.442-.304-.294-.457-.736-.457-1.325V3.907c0-.589.153-1.03.457-1.325.305-.294.737-.442 1.296-.442h17.14c.56 0 .994.148 1.303.442.308.295.463.736.463 1.325v6.745h2.14V3.746c0-1.236-.32-2.17-.963-2.8C23.346.316 22.394 0 21.134 0H3.82C2.549 0 1.596.315.958.946.319 1.576 0 2.51 0 3.746v11.97c0 1.237.32 2.17.958 2.8.638.631 1.592.946 2.86.946zM15.437 25h11.757c1.26 0 2.21-.315 2.848-.946.639-.63.958-1.564.958-2.8v-7.69c0-1.236-.32-2.172-.958-2.806-.638-.635-1.588-.952-2.848-.952H15.437c-1.26 0-2.212.315-2.854.946-.643.63-.964 1.568-.964 2.812v7.69c0 1.236.321 2.17.964 2.8.642.63 1.594.946 2.854.946zM5.997 9.06l3.368-3.348c.192-.191.288-.392.288-.604 0-.211-.08-.4-.238-.566-.158-.166-.38-.27-.663-.311l-3.894-.572c-.292-.034-.53.039-.714.217-.183.179-.254.413-.213.703l.564 3.87c.041.3.146.525.313.679.167.153.359.23.576.23.217 0 .421-.1.613-.299zm3.719 1.157a.842.842 0 00.6-.224.737.737 0 00.239-.56.847.847 0 00-.263-.623L7.55 6.098 6.373 7.255 9.115 9.98a.817.817 0 00.6.237z"
          id={id2}
        />
      </Defs>
      <G transform="translate(1 1)" fillRule="nonzero" stroke="none" strokeWidth={1} fill="none">
        <Use fill="#000" filter={`url(#${id1})`} xlinkHref={`#${id2}`} />
        <Use fill="#F1F6FF" xlinkHref={`#${id2}`} />
      </G>
    </Svg>
  ) : (
    <Svg
      width={width}
      height={height || defaultHeight}
      viewBox="0 0 31 25"
      title={titleText}
      aria-label={titleText}
      style={style}
    >
      <Path
        d="M3.819 19.462h8.851v-2.14H3.906c-.559 0-.99-.147-1.296-.442-.304-.294-.457-.736-.457-1.325V3.907c0-.589.153-1.03.457-1.325.305-.294.737-.442 1.296-.442h17.14c.56 0 .994.148 1.303.442.308.295.463.736.463 1.325v6.745h2.14V3.746c0-1.236-.32-2.17-.963-2.8C23.346.316 22.394 0 21.134 0H3.82C2.549 0 1.596.315.958.946.319 1.576 0 2.51 0 3.746v11.97c0 1.237.32 2.17.958 2.8.638.631 1.592.946 2.86.946zM15.437 25h11.757c1.26 0 2.21-.315 2.848-.946.639-.63.958-1.564.958-2.8v-7.69c0-1.236-.32-2.172-.958-2.806-.638-.635-1.588-.952-2.848-.952H15.437c-1.26 0-2.212.315-2.854.946-.643.63-.964 1.568-.964 2.812v7.69c0 1.236.321 2.17.964 2.8.642.63 1.594.946 2.854.946zM5.997 9.06l3.368-3.348c.192-.191.288-.392.288-.604 0-.211-.08-.4-.238-.566-.158-.166-.38-.27-.663-.311l-3.894-.572c-.292-.034-.53.039-.714.217-.183.179-.254.413-.213.703l.564 3.87c.041.3.146.525.313.679.167.153.359.23.576.23.217 0 .421-.1.613-.299zm3.719 1.157a.842.842 0 00.6-.224.737.737 0 00.239-.56.847.847 0 00-.263-.623L7.55 6.098 6.373 7.255 9.115 9.98a.817.817 0 00.6.237z"
        transform="translate(-157 -14) translate(157 14)"
        fill="#41506A"
        fillRule="nonzero"
        stroke="none"
        strokeWidth={1}
      />
    </Svg>
  );
};

export default PictureInPicture;
