import { useEmotionTheme } from '@talkspace/react-toolkit';
import { VoidFunctionComponent } from 'react';
import Svg, { Path } from 'svgs';

interface PresentingProblemsIconProps {
  color?: string;
}

const PresentingProblemsIcon: VoidFunctionComponent<PresentingProblemsIconProps> = ({
  color,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();

  return (
    <Svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 14.3856C4.7528 14.3856 2.08481 11.96 1.67681 8.8224L4.8 8.8288V8.8232L6.73359 6.8736C8.86799 10.5336 8.3224 9.5976 9.3592 11.3752L11.9256 8.8H14.34C13.9312 12 11.2472 14.3856 8 14.3856ZM8 1.5712C11.268 1.5712 13.9664 4 14.3488 7.2H11.2V7.2384L9.632 8.8344L7.83441 5.7416L7.83919 5.7376L7.00321 4.304L5.8736 5.436L5.88079 5.4312L4.09761 7.2H1.6512C2.0336 4 4.732 1.5712 8 1.5712ZM8 0C3.5816 0 0 3.5824 0 8C0 12.4184 3.5816 16 8 16C12.4184 16 16 12.4184 16 8C16 3.5824 12.4184 0 8 0Z"
        fill={color || colors.slateGrey}
      />
    </Svg>
  );
};

export default PresentingProblemsIcon;
