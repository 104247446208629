import { VoidFunctionComponent } from 'react';
import Svg, { Circle, G, Rect } from 'svgs';
import { EmotionStyle, useEmotionTheme } from '../../core/styled';

interface FilterProps {
  color?: string;
  width?: number;
  height?: number;
  style?: EmotionStyle;
}

const Filter: VoidFunctionComponent<FilterProps> = ({
  width = 20,
  height = 20,
  color,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'filter';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 20 20"
      {...otherProps}
    >
      <G transform="translate(-1100 -27) translate(1100 25) translate(0 2)">
        <Rect fill={color || colors.permaViridianGreen} x={0} y={3} width={20} height={2} rx={1} />
        <Rect fill={color || colors.permaViridianGreen} x={0} y={9} width={20} height={2} rx={1} />
        <Rect fill={color || colors.permaViridianGreen} x={0} y={15} width={20} height={2} rx={1} />
        <Circle
          stroke={color || colors.permaViridianGreen}
          strokeWidth={2}
          fill={colors.white}
          cx={14.5}
          cy={16}
          r={2.5}
        />
        <Circle
          stroke={color || colors.permaViridianGreen}
          strokeWidth={2}
          fill={colors.white}
          cx={14.5}
          cy={4}
          r={2.5}
        />
        <Circle
          stroke={color || colors.permaViridianGreen}
          strokeWidth={2}
          fill={colors.white}
          cx={5.5}
          cy={10}
          r={2.5}
        />
      </G>
    </Svg>
  );
};

export default Filter;
