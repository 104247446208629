import { FunctionComponent, useEffect, useState } from 'react';

import View from '../../../components/View';
import TouchableView, { TouchableViewProps } from '../../../components/TouchableView';
import Text from '../../../components/Text';
import CircledQuestionMark from '../../../components/Svgs/CircledQuestionMark';
import styled, { EmotionStyle } from '../../../core/styled';

const Label = styled(Text)(({ theme: { colors } }) => {
  return {
    textAlign: 'right',
    fontSize: 14,
    fontWeight: 300,
    marginRight: 30,
    color: colors.black,
  };
});

const Circle = styled(View)(({ theme: { colors } }) => {
  return {
    borderRadius: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.altoGrey,
    width: 20,
    height: 20,
    boxSizing: 'border-box',
  };
});

const InnerCircle = styled(View)<{ disabled: boolean }>(({ disabled, theme: { colors } }) => {
  return {
    borderRadius: 8,
    width: 16,
    height: 16,
    backgroundColor: disabled ? colors.altoGrey : colors.green,
  };
});

export interface RadioButtonProps extends TouchableViewProps {
  isActive: boolean;
  label?: string;
  labelStyle?: EmotionStyle;
  onPress?(): void;
  tabFirstOption?: boolean;
  ariaLabelledBy?: string;
  ariaDescribedBy?: string;
  style?: EmotionStyle;
  dataQa?: string;
  questionMarkWhenDisabled?: boolean;
  onDisablePress?(): void;
  circleStyle?: EmotionStyle;
  innerCircleStyle?: EmotionStyle;
}

const RadioButton: FunctionComponent<RadioButtonProps> = ({
  label,
  isActive,
  disabled,
  onPress,
  tabFirstOption,
  labelStyle = {},
  ariaLabelledBy,
  ariaDescribedBy,
  dataQa,
  questionMarkWhenDisabled = false,
  onDisablePress = () => null,
  circleStyle,
  innerCircleStyle,
  ...otherProps
}) => {
  const [tabbable, setTabbable] = useState(false);
  useEffect(() => {
    if (isActive && !disabled) {
      setTabbable(true);
    } else {
      setTabbable(false);
    }
  }, [isActive, disabled]);

  return (
    <TouchableView
      // selection should occur on focus via the arrow keys
      // when there is no default selection the first option should be tabbable and the initial tab should not select
      onFocus={disabled || tabFirstOption ? () => null : onPress}
      onPress={disabled ? onDisablePress : onPress}
      role="radio"
      aria-checked={isActive}
      tabIndex={tabbable ? 0 : -1}
      row
      align="center"
      aria-labelledby={ariaLabelledBy}
      aria-describedby={ariaDescribedBy}
      dataQa={dataQa}
      {...otherProps}
    >
      {!!label && <Label style={labelStyle}>{label}</Label>}
      {disabled && questionMarkWhenDisabled ? (
        <CircledQuestionMark />
      ) : (
        <Circle style={circleStyle} aria-hidden="true" justify="center" align="center">
          {isActive && <InnerCircle style={innerCircleStyle} disabled={!!disabled} />}
        </Circle>
      )}
    </TouchableView>
  );
};
export default RadioButton;
