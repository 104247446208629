import { FunctionComponent } from 'react';
import { styled, useEmotionTheme, EmotionStyle } from '../..';
import GraphicCard from '../GraphicCard/GraphicCard';
import { ExtraHuge, Large, Tiny } from '../Typography';
import View from '../View';

interface Props {
  cardHeight?: string | number;
  cardWidth?: string | number;
  cardHeightResponsive?: string | number;
  cardWidthResponsive?: string | number;
  firstHeadlineRowText?: string;
  secondHeadlineRowText?: string;
  subHeadlineText?: string;
  headlineStyle?: EmotionStyle;
  subHeadlineStyle?: EmotionStyle;
  graphicCardStyles?: { backgroundColor?: string };
  description?: string;
  imageURL: string;
  imageWidth?: number;
  imageHeight?: number;
  imageXPos?: number;
  imageYPos?: number;
  imageMobileXPos?: number;
  imageMobileYPos?: number;
  imageMobileWidth?: number;
  imageMobileHeight?: number;
  // eslint-disable-next-line react/no-unused-prop-types
  backgroundColor?: string;
  isCompleted?: boolean;
  isMobile?: boolean;
  flowDescriptionId?: string;
  isDisabled?: boolean;
  isLowOpacity?: boolean;
  onCheckAnimationEnd?: () => void;
  isAnimatingCheckmark?: boolean;
}

const Wrapper = styled(View)<{ isLowOpacity: boolean }>(({ isLowOpacity }) => {
  return {
    width: '100%',
    transition: 'all .5s',
    opacity: isLowOpacity ? 0.4 : 1,
  };
});

const GraphicCardWrapper = styled(GraphicCard)({
  marginBottom: 17,
  opacity: 1,
  transition: 'opacity .2s',
});

const TextWrapper = styled(View)<{
  isLowOpacity: boolean;
}>(({ isLowOpacity = true }) => {
  return {
    transition: 'opacity .5s',
    opacity: isLowOpacity ? 0 : 1,
    marginLeft: 20,
    marginRight: 20,
  };
});

const TextLarge = styled(Large)({
  textAlign: 'center',
  marginBottom: 20,
});

const GraphicCardWithText: FunctionComponent<Props> = (props) => {
  const { colors } = useEmotionTheme();
  const {
    cardHeight = 375,
    cardWidth = 262,
    cardHeightResponsive = 300,
    cardWidthResponsive = 260,
    firstHeadlineRowText,
    secondHeadlineRowText,
    subHeadlineText,
    headlineStyle = { color: 'white' },
    subHeadlineStyle = { color: 'white', fontWeight: 700, marginTop: 7 },
    graphicCardStyles = {},
    description,
    imageURL,
    imageWidth = 0,
    imageHeight = 0,
    imageXPos = 0,
    imageYPos = 0,
    imageMobileXPos = 0,
    imageMobileYPos = 0,
    imageMobileWidth,
    imageMobileHeight,
    isCompleted = false,
    isMobile = false,
    flowDescriptionId,
    isDisabled = false,
    isLowOpacity = false,
    onCheckAnimationEnd,
    isAnimatingCheckmark,
  } = props;

  if (isDisabled) {
    graphicCardStyles.backgroundColor = colors.slateGrey;
  }
  return (
    <Wrapper isLowOpacity={isLowOpacity}>
      <GraphicCardWrapper
        height={cardHeight}
        width={cardWidth}
        heightResponsive={cardHeightResponsive}
        widthResponsive={cardWidthResponsive}
        imageAlignItems="center"
        isResponsive={isMobile}
        bgImageURL={imageURL}
        bgHeight={isMobile && imageMobileHeight ? imageMobileHeight : imageHeight}
        bgWidth={isMobile && imageMobileWidth ? imageMobileWidth : imageWidth}
        bgX={isMobile ? imageMobileXPos : imageXPos}
        bgY={isMobile ? imageMobileYPos : imageYPos}
        isActive={isCompleted}
        style={graphicCardStyles}
        onCheckAnimationEnd={onCheckAnimationEnd}
        isAnimatingCheckmark={isAnimatingCheckmark}
      >
        <View flex={1} style={{ padding: 28, textAlign: 'left' }}>
          <ExtraHuge as="h1" style={{ ...headlineStyle }}>
            {firstHeadlineRowText}
            <br />
            {secondHeadlineRowText}
          </ExtraHuge>
          <Tiny as="h2" style={{ ...subHeadlineStyle }}>
            {subHeadlineText}
          </Tiny>
        </View>
      </GraphicCardWrapper>
      <TextWrapper isLowOpacity={isLowOpacity}>
        <TextLarge id={flowDescriptionId} variant="largeDarkGrey">
          {description}
        </TextLarge>
      </TextWrapper>
    </Wrapper>
  );
};

export default GraphicCardWithText;
