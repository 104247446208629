import { Huge, Button, Modal, Standard, useWindowWidthState, View } from '@talkspace/react-toolkit';
import { useEffect, useCallback, useState, useMemo } from 'react';
import styled from '../../core/styled';
import useBiometricLock from '../../hooks/useBiometricLock';
import { useCloseModal } from '../../modules/utils/ModalsContextProvider';
import { setOptedInBiometricLock } from '../../utils/token';

const ModalContainer = styled(View)(({ theme: { colors } }) => {
  return {
    background: colors.white,
    textAlign: 'center',
    alignItems: 'center',
    width: 335,
  };
});

enum ScreenStatus {
  INTRODUCTION,
  ERROR,
  SUCCESS,
}

const BiometricLock = () => {
  const [status, setStatus] = useState(ScreenStatus.INTRODUCTION);
  const { promptBiometricLock, biometryType } = useBiometricLock();
  const enableBiometricLock = useCallback(async () => {
    const success = await promptBiometricLock();

    if (success) {
      setStatus(ScreenStatus.SUCCESS);
      setOptedInBiometricLock(true);
    } else {
      setStatus(ScreenStatus.ERROR);
    }
  }, [promptBiometricLock]);
  const closeModal = useCloseModal();

  useEffect(() => {
    let biometricLockTimeout: ReturnType<typeof setTimeout> | undefined;
    if (status === ScreenStatus.SUCCESS) {
      biometricLockTimeout = setTimeout(() => {
        closeModal();
      }, 2000);
    }
    return () => {
      if (biometricLockTimeout) clearTimeout(biometricLockTimeout);
    };
  }, [status, closeModal]);

  const { isMobile } = useWindowWidthState();
  const screenContent = useMemo(() => {
    switch (status) {
      case ScreenStatus.INTRODUCTION: {
        return (
          <>
            <Huge style={{ marginBottom: 15 }}>Would you like to setup a Biometric Lock?</Huge>
            <Standard>
              You will be prompted with your Face ID or Touch ID every time you open the app.
            </Standard>
            <Standard>
              This enhances the security of your clients and can give you peace of mind just in case
              you accidentally leave your phone unlocked.
            </Standard>
            <Button style={{ marginTop: 45 }} onPress={enableBiometricLock}>
              Enable Biometric Lock
            </Button>
          </>
        );
      }
      case ScreenStatus.SUCCESS:
        return (
          <>
            <Huge style={{ marginBottom: 15 }}>Success!</Huge>
            <Standard>{biometryType} has been setup successfully</Standard>
            <Button style={{ marginTop: 45 }} onPress={() => closeModal()}>
              Close
            </Button>
          </>
        );
      case ScreenStatus.ERROR:
        return (
          <>
            <Huge style={{ marginBottom: 15 }}>Something went wrong</Huge>
            <Standard>Could not setup {biometryType}</Standard>
            <Standard>You will be asked to try again later</Standard>
            <Button style={{ marginTop: 45 }} onPress={() => closeModal()}>
              Close
            </Button>
          </>
        );
      default:
        return null;
    }
  }, [biometryType, closeModal, enableBiometricLock, status]);
  return (
    <Modal.Panel onBackdropPress={closeModal} contentViewStyle={{ marginTop: isMobile ? 120 : 48 }}>
      <ModalContainer>{screenContent}</ModalContainer>
    </Modal.Panel>
  );
};

export default BiometricLock;
