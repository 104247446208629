import { useState, useEffect } from 'react';
import * as React from 'react';
import Svg, { Path, G, Circle, Text as SVGText, Rect } from 'svgs';
import moment from 'moment';
import styled from '../../core/styled';
import { Mini, Large } from '../Typography';
import View from '../View';
import ErrorBoundary from '../ErrorBoundary';
import { COLORS } from '../../constants/commonStyles';

interface SurveyScore {
  score: number;
  date: string;
}

interface LineChartProps {
  surveyScores: SurveyScore[];
  max: number;
  maxNumDateLabels?: number;
  chartWidth?: number;
  chartHeight?: number;
  chartPaddingX?: number;
  chartPaddingTopY?: number;
  chartPaddingBottomY?: number;
  onChangeSelectedPoint?: (idx: number) => void;
  disabled?: boolean;
  disableHoverTransition?: boolean;
}

interface Point {
  x: number;
  y: number;
}

const BouncyCircle = styled(Circle)({
  transform: 'perspective(1px) translateZ(0)',
  transitionDuration: '0.5s',
  '&:hover': {
    transform: 'scale(1.2)',
    transitionTimingFunction: 'cubic-bezier(0.47, 2.02, 0.31, -0.36)',
  },
});

const DateLabels: React.FunctionComponent<{
  surveyScores: SurveyScore[];
  maxNumDateLabels: number;
}> = ({ surveyScores, maxNumDateLabels }) => {
  const shouldOnlyRenderFirstLast = surveyScores.length > maxNumDateLabels;
  const labelsToRender = shouldOnlyRenderFirstLast
    ? [surveyScores[0], surveyScores[surveyScores.length - 1]]
    : surveyScores;
  const format = shouldOnlyRenderFirstLast ? 'MMM D, YYYY' : 'MMM D';
  return (
    <>
      {labelsToRender.map(({ date }) => (
        <View key={date}>
          <Mini>{moment(date).format(format)}</Mini>
        </View>
      ))}
    </>
  );
};

const LabelText = styled(SVGText)({
  fontSize: 12,
});

const ScoreLabel = ({ text }) => (
  <G>
    <Rect
      x="-30"
      y="-50"
      rx="17"
      ry="17"
      width="60"
      height="34"
      fill={COLORS.periwinkleGrey}
      strokeWidth={0}
    />
    <LabelText x="0" y="-28.5" fill="#fff" strokeWidth={0} textAnchor="middle">
      {text}
    </LabelText>
  </G>
);

const LineChart: React.FunctionComponent<LineChartProps> = ({
  surveyScores,
  max,
  onChangeSelectedPoint,
  disableHoverTransition,
  maxNumDateLabels = 4,
  chartWidth = 220,
  chartHeight = 100,
  chartPaddingX = 20,
  chartPaddingTopY = 50,
  chartPaddingBottomY = 15,
  disabled = false,
}) => {
  const svgWidth = chartWidth + chartPaddingX + chartPaddingX;
  const svgHeight = chartHeight + chartPaddingTopY + chartPaddingBottomY;
  const numScores = surveyScores.length;
  const stepLengthX = chartWidth / (numScores - 1);
  const scores = surveyScores.map((s) => s.score);

  // construct path and path points
  const points: Point[] = [];
  const scoreUnitHeight = chartHeight / max;
  const { path: linePath } = scores.reduce(
    (acc, score, idx) => {
      const svgLetter = idx === 0 ? 'M' : 'L';
      const nextXPosition = acc.x + stepLengthX;
      const scoreHeight = chartHeight - score * scoreUnitHeight + chartPaddingTopY;
      points.push({
        x: acc.x,
        y: scoreHeight,
      });
      return {
        x: nextXPosition,
        path: `${acc.path} ${svgLetter}${acc.x}, ${scoreHeight}`,
      };
    },
    { x: chartPaddingX, path: '' }
  );

  const [selectedScoreIdx, setSelectedScoreIdx] = useState<number>(numScores - 1);
  // the number of scores given to the chart can be dynamic
  useEffect(() => {
    setSelectedScoreIdx(numScores - 1);
  }, [numScores]);
  const point = points[selectedScoreIdx] || points[0];
  const effectiveLineWidth = chartWidth - chartPaddingX;
  // for 3 data points this math yields [50,150] where 200 is the line length;
  // w * (2i + 1) / 2(n-1)
  const clickAreaDividerPointsX = surveyScores.map(
    (s, i) => (effectiveLineWidth * (2 * i + 1)) / (numScores - 1) / 2
  );

  const donutColor = COLORS.purple;

  const moveToClosestScorePoint = (evt: React.MouseEvent<Element, MouseEvent>) => {
    evt.preventDefault();
    const { target } = evt;
    // only if we click on the line path or the background svg do we care to move
    if (!['path', 'svg'].includes((target as HTMLDivElement).tagName)) return;
    // the path is for some reason offset by 31, don't ask me why
    const containerOffset = (target as HTMLDivElement).tagName === 'path' ? 0 : 31;
    const dim = (target as HTMLDivElement).getBoundingClientRect();
    const x = evt.clientX - dim.left - containerOffset;
    const idx = clickAreaDividerPointsX.findIndex((p) => x <= p);
    const index = idx > -1 ? idx : numScores - 1;
    if (index !== selectedScoreIdx && !disabled) {
      setSelectedScoreIdx(index);
      if (onChangeSelectedPoint) onChangeSelectedPoint(index);
    }
  };

  const handleOnMouseMove = (evt: React.MouseEvent<Element, MouseEvent>) => {
    if (disableHoverTransition) return;
    evt.preventDefault();
    moveToClosestScorePoint(evt);
  };

  const currScore = surveyScores[selectedScoreIdx];

  return currScore ? (
    <View
      align="center"
      justify="center"
      style={{ width: svgWidth, height: svgHeight, position: 'relative' }}
      onClick={moveToClosestScorePoint}
      onMouseMove={handleOnMouseMove}
    >
      <Svg width={svgWidth} height={svgHeight} viewBox={`0 0 ${svgWidth} ${svgHeight}`}>
        <Path d={linePath} fill="none" stroke={donutColor} strokeWidth={5} strokeLinecap="round" />
        <G stroke={donutColor} strokeWidth={6.88} transform={`translate(${point.x}, ${point.y})`}>
          <BouncyCircle fill="#FFF" r={9} />
          <ScoreLabel text={`${currScore.score} / ${max}`} />
        </G>
      </Svg>
      <View row justify="space-between" style={{ width: chartWidth, paddingLeft: 5 }}>
        <DateLabels surveyScores={surveyScores} maxNumDateLabels={maxNumDateLabels} />
      </View>
    </View>
  ) : null;
};

const ErrorComponent = () => (
  <View align="center" justify="center" style={{ width: 220, height: 100 }}>
    <Large style={{ color: COLORS.red }}>Chart data is invalid</Large>
  </View>
);

const ClinicalProgressLineChart: React.FunctionComponent<LineChartProps> = (props) => (
  <ErrorBoundary errorComponent={ErrorComponent}>
    <LineChart {...props} />
  </ErrorBoundary>
);

export default ClinicalProgressLineChart;
