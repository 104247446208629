import { FunctionComponent } from 'react';
import { useField } from 'formik';
import styled, { EmotionStyle } from '../../core/styled';
import { useUniqueID } from '../../hooks/a11yHelper';
import View from '../View';
import TSInput from '../TSInput';
import Label from '../Label';
import Mini from '../Typography/Mini';

interface Props {
  fieldName: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  inputStyle?: EmotionStyle;
  wrapperStyle?: EmotionStyle;
  maskType?: string;
  placeHolderStyle?: EmotionStyle;
}

const InputWrapper = styled(View)({
  marginBottom: 28,
});

const ErrorMessage = styled(Mini)(({ theme: { colors } }) => {
  return {
    color: colors.red,
    paddingLeft: 4,
  };
});

const FormLabel = styled(Label)(({ theme: { colors } }) => {
  return { fontSize: 13, color: colors.greyText };
});

const TSInputFormik: FunctionComponent<Props> = ({
  fieldName,
  label,
  placeholder,
  required = false,
  inputStyle = {},
  wrapperStyle = {},
  maskType,
  placeHolderStyle,
}) => {
  const [field, meta] = useField(fieldName);
  const errorId = useUniqueID(`${fieldName}ErrorID`);
  return (
    <InputWrapper style={wrapperStyle}>
      <FormLabel>{label}</FormLabel>
      <TSInput
        id={fieldName}
        ariaLabel={label}
        ariaDescribedBy={meta.error ? errorId : undefined}
        ariaRequired={required}
        ariaInvalid={Boolean(meta.error)}
        placeholder={placeholder}
        onBlur={field.onBlur}
        value={field.value}
        name={field.name}
        onChangeEvent={field.onChange}
        isError={Boolean(meta.touched && meta.error)}
        style={inputStyle}
        maskType={maskType}
        placeHolderStyle={placeHolderStyle}
      />
      {meta.touched && meta.error && <ErrorMessage>{meta.error}</ErrorMessage>}
    </InputWrapper>
  );
};

export default TSInputFormik;
