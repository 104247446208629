import { styled, TextDS, useEmotionTheme, View } from '@talkspace/react-toolkit';
import { Check } from '@talkspace/react-toolkit/src/designSystems/icons';
import { AutomaticMessageType } from '../../../types/account';

const ToastView = styled(View)(({ theme: { spacing } }) => {
  return {
    flexDirection: 'row',
    justifyContent: 'center',
    width: 360,
    gap: spacing('space100'),
    borderRadius: spacing('space100'),
    paddingTop: spacing('space150'),
    paddingRight: spacing('space200'),
    paddingBottom: spacing('space150'),
    paddingLeft: spacing('space200'),
  };
});

interface Props {
  variant: 'ON' | 'OFF';
  messageType: AutomaticMessageType;
}

const tooltipMessageTitle: Record<AutomaticMessageType, string> = {
  'Personalized welcome': 'Automatic welcome message',
};

export default function ActionToast({ variant, messageType }: Props) {
  const { colorRoles } = useEmotionTheme();

  const backgroundColor =
    variant === 'ON' ? colorRoles.surfaces.successBoldDefault : colorRoles.surfaces.overlayBold;

  return (
    <ToastView style={{ backgroundColor }}>
      {variant === 'ON' ? <Check colorType="inverse" /> : null}

      <TextDS variant="body" colorRole="textInverse">
        {tooltipMessageTitle[messageType]} is {variant}
      </TextDS>
    </ToastView>
  );
}
