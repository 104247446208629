import Svg, { Path, Mask, Rect } from 'svgs';
import { useEmotionTheme, EmotionStyle } from '../../core/styled';
import { useUniqueID } from '../../hooks/a11yHelper';

interface Props {
  size?: number;
  color?: string;
  dataQa?: string;
  style?: EmotionStyle;
}

const GlobeWithClock = ({ size = 25, color, dataQa, style, ...props }: Props) => {
  const { colors } = useEmotionTheme();
  const titleText = 'globe';
  const maskID = useUniqueID('globeWithClockMask');
  const height = (24 / 25) * size;

  const fill = color || colors.permaSlateGrayApprox;
  return (
    <Svg
      width={size}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      data-qa={dataQa}
      title={titleText}
      aria-label={titleText}
      style={{ marginTop: -4, marginLeft: -2, ...style }}
      {...props}
    >
      <Path
        d="M18.2183 11.8334C18.2982 11.4298 18.254 11.2832 18.254 10.8796C18.254 9.74953 18.0142 8.61949 17.5348 7.65089C15.9366 6.763 15.2174 7.2473 14.4982 7.81232C14.0187 8.21591 13.2995 8.70021 12.4205 8.37734C11.5415 8.05447 10.9022 7.32802 10.5027 6.44013C10.1831 5.63296 10.1031 4.58364 10.263 3.61503C8.66477 3.77647 7.22639 4.50292 6.18756 5.55224C6.74693 6.763 6.74693 7.97376 6.02774 9.02308C5.86792 9.34595 5.62819 9.5881 5.38846 9.83025C4.98891 10.2338 5.14873 10.9603 5.62819 11.2024C6.26747 11.5253 6.98666 11.8482 7.46612 12.4939C8.42504 13.9468 7.3063 16.0455 7.06657 16.4491C6.98666 16.5298 6.90675 16.6105 6.82684 16.7719C7.62594 17.3369 8.50495 17.7405 9.46387 17.9827C9.54378 18.6284 9.7036 19.1934 9.94333 19.6777C5.62819 19.1127 2.27197 15.3997 2.27197 10.8796C2.27197 5.95583 6.18756 2.00069 11.0621 2.00069C15.9366 2.00069 19.8522 5.95583 19.8522 10.8796C19.8522 11.8482 19.8165 12.4791 19.4969 13.4477C19.0973 12.8827 18.6978 12.3177 18.2183 11.8334Z"
        fill={fill}
      />
      <Mask
        id={maskID}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        maskUnits={'userSpaceOnUse' as any} // Expected enum type is not exported by svgs
        x={10.9673}
        y={10.2881}
        width={12}
        height={12}
        fill={colors.trueBlack}
      >
        <Rect fill={colors.white} x="10.9673" y="10.2881" width={12} height={12} />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.2657 16.1389C22.2657 13.2983 19.9571 10.9896 17.1165 10.9896C14.2759 10.9896 11.9673 13.2983 11.9673 16.1389C11.9673 18.9794 14.2759 21.2881 17.1165 21.2881C19.9571 21.2881 22.2657 18.9794 22.2657 16.1389ZM21.134 16.1389C21.134 18.357 19.3346 20.1564 17.1165 20.1564C14.8984 20.1564 13.099 18.357 13.099 16.1389C13.099 13.9207 14.8984 12.1213 17.1165 12.1213C19.3346 12.1213 21.134 13.9207 21.134 16.1389Z"
        />
      </Mask>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.2657 16.1389C22.2657 13.2983 19.9571 10.9896 17.1165 10.9896C14.2759 10.9896 11.9673 13.2983 11.9673 16.1389C11.9673 18.9794 14.2759 21.2881 17.1165 21.2881C19.9571 21.2881 22.2657 18.9794 22.2657 16.1389ZM21.134 16.1389C21.134 18.357 19.3346 20.1564 17.1165 20.1564C14.8984 20.1564 13.099 18.357 13.099 16.1389C13.099 13.9207 14.8984 12.1213 17.1165 12.1213C19.3346 12.1213 21.134 13.9207 21.134 16.1389Z"
        fill={fill}
      />
      <Path
        d="M17.1165 10.4896C20.2332 10.4896 22.7657 13.0222 22.7657 16.1389H21.7657C21.7657 13.5744 19.6809 11.4896 17.1165 11.4896V10.4896ZM11.4673 16.1389C11.4673 13.0222 13.9998 10.4896 17.1165 10.4896V11.4896C14.5521 11.4896 12.4673 13.5744 12.4673 16.1389H11.4673ZM17.1165 21.7881C13.9998 21.7881 11.4673 19.2556 11.4673 16.1389H12.4673C12.4673 18.7033 14.5521 20.7881 17.1165 20.7881V21.7881ZM22.7657 16.1389C22.7657 19.2556 20.2332 21.7881 17.1165 21.7881V20.7881C19.6809 20.7881 21.7657 18.7033 21.7657 16.1389H22.7657ZM17.1165 19.6564C19.0585 19.6564 20.634 18.0808 20.634 16.1389H21.634C21.634 18.6331 19.6108 20.6564 17.1165 20.6564V19.6564ZM13.599 16.1389C13.599 18.0808 15.1745 19.6564 17.1165 19.6564V20.6564C14.6222 20.6564 12.599 18.6331 12.599 16.1389H13.599ZM17.1165 12.6213C15.1745 12.6213 13.599 14.1969 13.599 16.1389H12.599C12.599 13.6446 14.6222 11.6213 17.1165 11.6213V12.6213ZM20.634 16.1389C20.634 14.1969 19.0585 12.6213 17.1165 12.6213V11.6213C19.6108 11.6213 21.634 13.6446 21.634 16.1389H20.634Z"
        fill={fill}
        mask={`url(#${maskID})`}
      />
      <Path
        d="M17.042 16.9568H18.7071C18.9813 16.9568 19.1968 16.7413 19.1968 16.467C19.1968 16.1928 18.9813 15.9773 18.7071 15.9773H17.5317V13.9204C17.5317 13.6461 17.3162 13.4307 17.042 13.4307C16.7677 13.4307 16.5522 13.6461 16.5522 13.9204V16.467C16.5522 16.7413 16.7677 16.9568 17.042 16.9568Z"
        fill={fill}
        stroke={fill}
        strokeWidth={0.526316}
      />
    </Svg>
  );
};

export default GlobeWithClock;
