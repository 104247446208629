import Svg, { Path } from 'svgs';
import { FunctionComponent } from 'react';

import { useEmotionTheme } from '../../core/styled';

interface AudioModalityProps {
  width?: number;
  height?: number;
  color?: string;
}

const AudioModality: FunctionComponent<AudioModalityProps> = ({
  width = 17,
  height = 23,
  color,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'audio';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 17 23"
      {...otherProps}
    >
      <Path
        d="M1.857.965c.789-.583 1.29-.8 2.15-.946 1.434-.364 4.086 4.73 2.94 6.04-.43.51-1.004.438-2.008.874-.215 3.276 3.226 8.808 6.236 10.045.717-.728 1.003-1.237 1.577-1.456 1.72-.437 5.017 4.222 4.085 5.386-.573.655-.932 1.02-1.792 1.529C8.38 26.73-4.88 5.187 1.857.965z"
        fill={color || colors.green}
        fillRule="evenodd"
      />
    </Svg>
  );
};

export default AudioModality;
