import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { safeIonicWrapper } from '../../ionicUtils';

interface GetIonicStorageResponse {
  value?: string | null;
  errorMessage?: string;
  isError?: boolean;
}

interface SetIonicStorageProps {
  key: string;
  keySuffix?: string;
  value: string;
  callback?: () => void;
}

export const PASSCODE_KEY = 'ionic_passcode';

export const PASSCODE_ATTEMPTS = 'ionic_passcode_attempts';

export const PASSCODE_ENABLED = 'ionic_passcode_enabled';

export const DENIED_PERMISSION = 'denied_permission';

export const OPEN_APP_REVIEW = 'open_app_review';

const getKeyWithSuffix = (key: string, suffix?: string | number) =>
  suffix ? `${key}_${suffix}` : key;

export const setIonicStorage = safeIonicWrapper(
  async ({
    key,
    value,
    keySuffix,
    callback,
  }: SetIonicStorageProps): Promise<GetIonicStorageResponse> => {
    await SecureStoragePlugin.set({ key: getKeyWithSuffix(key, keySuffix), value }).catch((err) => {
      return { isError: true, errorMessage: 'Error setting ionic storage' };
    });
    if (callback) {
      callback();
    }
    return {};
  },
  Promise.resolve({})
);

export const getIonicStorage = safeIonicWrapper(
  async (key: string, keySuffix?: string | number): Promise<GetIonicStorageResponse | null> => {
    try {
      const storage = await SecureStoragePlugin.get({ key: getKeyWithSuffix(key, keySuffix) });
      if (storage) {
        return { value: storage.value };
      }
      return null;
    } catch (error) {
      return { isError: true, errorMessage: `Error getting value for ${key}` };
    }
  },
  Promise.resolve(null)
);

export const removeIonicStorage = async (
  key: string,
  keySuffix?: string | number
): Promise<GetIonicStorageResponse> => {
  await SecureStoragePlugin.remove({ key: getKeyWithSuffix(key, keySuffix) }).catch(() => {
    return { isError: true, errorMessage: 'Error removing storage' };
  });
  return {};
};

export const clearIonicStorage = async (): Promise<GetIonicStorageResponse> => {
  await SecureStoragePlugin.clear().catch(() => {
    return { isError: true, errorMessage: 'Error clearing storage' };
  });
  return {};
};

// TODO: Consider moving to their own module
export const getIsIonicPasscodeExist = async (userID?: number | string): Promise<boolean> => {
  try {
    const savedPasscode = await SecureStoragePlugin.get({
      key: getKeyWithSuffix(PASSCODE_KEY, userID),
    });
    return !!savedPasscode.value;
  } catch (error) {
    return false;
  }
};

export const removeIonicPasscode = async (
  userID?: number | string
): Promise<GetIonicStorageResponse> => {
  await SecureStoragePlugin.remove({ key: getKeyWithSuffix(PASSCODE_KEY, userID) }).catch(() => {
    return { isError: true, errorMessage: 'Error removing storage' };
  });
  return {};
};

export const getIonicPasscode = async (
  userID?: number | string
): Promise<GetIonicStorageResponse> => {
  try {
    const savedPasscode = await SecureStoragePlugin.get({
      key: getKeyWithSuffix(PASSCODE_KEY, userID),
    });
    return { value: savedPasscode.value || null };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return { errorMessage: error };
  }
};

export const getIonicPasscodeAttempts = async (userID?: number | string): Promise<number> => {
  try {
    const passcodeAttempts = await SecureStoragePlugin.get({
      key: getKeyWithSuffix(PASSCODE_ATTEMPTS, userID),
    });
    return Number(passcodeAttempts.value) || 0;
  } catch (error) {
    return 0;
  }
};

export const getIonicPasscodeEnabled = async (
  userID?: number | string
): Promise<GetIonicStorageResponse> => {
  try {
    const savedPasscode = await SecureStoragePlugin.get({
      key: getKeyWithSuffix(PASSCODE_ENABLED, userID),
    });
    return { value: savedPasscode.value === 'true' ? 'true' : null };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return { errorMessage: error };
  }
};

export const setIonicPasscodeEnabled = (
  value: boolean,
  userID?: number | string
): Promise<GetIonicStorageResponse> =>
  setIonicStorage({
    key: PASSCODE_ENABLED,
    value: value ? 'true' : 'false',
    keySuffix: userID ? `${userID}` : undefined,
  });
