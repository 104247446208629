import { EMessage } from '../entities/EMessage';

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type Merge<M, N> = Omit<M, Extract<keyof M, keyof N>> & N;

export type position = 'left' | 'center' | 'right';
export type squareCornersType = 'NONE' | 'TOP' | 'BOTTOM' | 'BOTH';

export type AckLevel = 0 | 1 | 2 | 3;

// eslint-disable-next-line import/prefer-default-export
export interface MessageProps {
  currentMessage: EMessage;
  isLast: boolean;
  isPeer: boolean;
  ackLevel: AckLevel;
  squareCorners: 'NONE' | 'TOP' | 'BOTTOM' | 'BOTH';
  position: position;
  onPress?: () => void;
  style?: {};
  isAroundSameTime: boolean;
  isPrevSameUser: boolean;
  isPrevSystem: boolean;
  roomID: number;
  isLastReceivedMessage?: boolean;
  isMedia?: boolean;
}

export enum VideoCallTrayEnum {
  THERAPIST_SCHEDULE_NEXT_RECURRING = 'therapistScheduleNextRecurring',
}
