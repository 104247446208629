import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const pathConfig: IconConfig = {
  standard: {
    filled: {
      path: 'M4.79999 2.00073C3.69686 2.00073 2.79999 2.89761 2.79999 4.00073V13.0007C2.79999 14.1039 3.69686 15.0007 4.79999 15.0007H7.79999V17.5007C7.79999 17.6914 7.90624 17.8632 8.07499 17.9476C8.24374 18.032 8.44686 18.0132 8.59999 17.9007L12.4656 15.0007H16.8C17.9031 15.0007 18.8 14.1039 18.8 13.0007V4.00073C18.8 2.89761 17.9031 2.00073 16.8 2.00073H4.79999Z',
    },
    default: {
      path: 'M7.79999 13.5007C8.62811 13.5007 9.29999 14.1726 9.29999 15.0007V15.5007L11.5656 13.8007C11.825 13.607 12.1406 13.5007 12.4656 13.5007H16.8C17.075 13.5007 17.3 13.2757 17.3 13.0007V4.00073C17.3 3.72573 17.075 3.50073 16.8 3.50073H4.79999C4.52499 3.50073 4.29999 3.72573 4.29999 4.00073V13.0007C4.29999 13.2757 4.52499 13.5007 4.79999 13.5007H7.79999ZM9.29999 17.3757L9.29374 17.382L9.13436 17.5007L8.59999 17.9007C8.44999 18.0132 8.24686 18.032 8.07499 17.9476C7.90311 17.8632 7.79999 17.6914 7.79999 17.5007V16.8351V16.6351V16.6257V16.5007V15.0007H6.29999H4.79999C3.69686 15.0007 2.79999 14.1039 2.79999 13.0007V4.00073C2.79999 2.89761 3.69686 2.00073 4.79999 2.00073H16.8C17.9031 2.00073 18.8 2.89761 18.8 4.00073V13.0007C18.8 14.1039 17.9031 15.0007 16.8 15.0007H12.4656L9.29999 17.3757Z',
    },
  },
  major: {
    filled: {
      path: 'M5.30008 2.79346C3.92117 2.79346 2.80008 3.9146 2.80008 5.29356V16.5441C2.80008 17.923 3.92117 19.0442 5.30008 19.0442H9.05008V22.1693C9.05008 22.4076 9.18289 22.6224 9.39383 22.7279C9.60477 22.8334 9.85867 22.8099 10.0501 22.6693L14.8821 19.0442H20.3001C21.679 19.0442 22.8001 17.923 22.8001 16.5441V5.29356C22.8001 3.9146 21.679 2.79346 20.3001 2.79346H5.30008Z',
    },
    default: {
      path: 'M9.05008 17.1691C10.0852 17.1691 10.9251 18.009 10.9251 19.0442V19.6692L13.7571 17.5441C14.0813 17.3019 14.4759 17.1691 14.8821 17.1691H20.3001C20.6438 17.1691 20.9251 16.8878 20.9251 16.5441V5.29356C20.9251 4.9498 20.6438 4.66854 20.3001 4.66854H5.30008C4.95633 4.66854 4.67508 4.9498 4.67508 5.29356V16.5441C4.67508 16.8878 4.95633 17.1691 5.30008 17.1691H9.05008ZM10.9251 22.013L10.9173 22.0208L10.718 22.1693L10.0501 22.6693C9.86258 22.8099 9.60867 22.8334 9.39383 22.7279C9.17899 22.6224 9.05008 22.4076 9.05008 22.1693V21.3372V21.0872V21.0755V20.9192V19.0442H7.17508H5.30008C3.92117 19.0442 2.80008 17.923 2.80008 16.5441V5.29356C2.80008 3.9146 3.92117 2.79346 5.30008 2.79346H20.3001C21.679 2.79346 22.8001 3.9146 22.8001 5.29356V16.5441C22.8001 17.923 21.679 19.0442 20.3001 19.0442H14.8821L10.9251 22.013Z',
    },
  },
};

const MessageSmall = withDSIconMaker(pathConfig, 'message');

export default MessageSmall;
