export type SemanticVersion = `${number}.${number}.${number}`;

type ParsedVersion = {
  versionKey: SemanticVersion;
  major: number;
  minor: number;
  patch: number;
};

export const validateVersionKey = (version: string): version is SemanticVersion => {
  const regex = /^\d+(\.\d+){2}$/;
  return regex.test(version);
};

export const parseVersionKey = (key: SemanticVersion | string): ParsedVersion => {
  const isValidKey = validateVersionKey(key);
  const parseKey = (isValidKey ? key : '0.0.0').split('.');

  return {
    versionKey: isValidKey ? key : '0.0.0',
    major: Number(parseKey[0]),
    minor: Number(parseKey[1]),
    patch: Number(parseKey[2]),
  };
};

export const meetsVersion = (requiredVersion: SemanticVersion, versionToCheck: SemanticVersion) => {
  const {
    major: requiredMajor,
    minor: requiredMinor,
    patch: requiredPatch,
  } = parseVersionKey(requiredVersion);
  const {
    major: checkMajor,
    minor: checkMinor,
    patch: checkPatch,
  } = parseVersionKey(versionToCheck);

  if (checkMajor > requiredMajor) return true;
  if (checkMajor < requiredMajor) return false;

  // If major versions are equal, check minor versions
  if (checkMinor > requiredMinor) return true;
  if (checkMinor < requiredMinor) return false;

  // If minor versions are equal, check patch versions
  if (checkPatch >= requiredPatch) return true;
  return false;
};

// Sorts from higher to lower versions
export const sortSemanticVersions = (versions: SemanticVersion[]): SemanticVersion[] =>
  versions.sort((a, b) => {
    const [aMajor, aMinor, aPatch] = a.split('.').map(Number);
    const [bMajor, bMinor, bPatch] = b.split('.').map(Number);

    if (aMajor !== bMajor) return bMajor - aMajor;
    if (aMinor !== bMinor) return bMinor - aMinor;
    return bPatch - aPatch;
  });
