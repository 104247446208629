export const REQUEST_ROOM_OUTCOME_MEASURES = 'REQUEST_ROOM_OUTCOME_MEASURES';
export const RECEIVE_ROOM_OUTCOME_MEASURES = 'RECEIVE_ROOM_OUTCOME_MEASURES';
export const REQUEST_ROOM_OUTCOME_MEASURES_ERROR = 'REQUEST_ROOM_OUTCOME_MEASURES_ERROR';

export const REQUEST_OUTCOME_MEASURE_ANSWERS = 'REQUEST_OUTCOME_MEASURE_ANSWERS';
export const RECEIVE_OUTCOME_MEASURE_ANSWERS = 'RECEIVE_OUTCOME_MEASURE_ANSWERS';
export const REQUEST_OUTCOME_MEASURE_ANSWERS_ERROR = 'REQUEST_OUTCOME_MEASURE_ANSWERS_ERROR';

export const REQUEST_CANCEL_OUTCOME_MEASURES = 'REQUEST_CANCEL_OUTCOME_MEASURES';
export const RECEIVE_CANCEL_OUTCOME_MEASURES = 'RECEIVE_CANCEL_OUTCOME_MEASURES';
export const REQUEST_CANCEL_OUTCOME_MEASURES_ERROR = 'REQUEST_CANCEL_OUTCOME_MEASURES_ERROR';

export const REQUEST_START_OUTCOME_MEASURES = 'REQUEST_START_OUTCOME_MEASURES';
export const RECEIVE_START_OUTCOME_MEASURES = 'RECEIVE_START_OUTCOME_MEASURES';
export const REQUEST_START_OUTCOME_MEASURES_ERROR = 'REQUEST_START_OUTCOME_MEASURES_ERROR';

export const REQUEST_OUTCOME_MEASURE_OPTIONS = 'REQUEST_OUTCOME_MEASURE_OPTIONS';
export const RECEIVE_OUTCOME_MEASURE_OPTIONS = 'RECEIVE_OUTCOME_MEASURE_OPTIONS';
export const REQUEST_OUTCOME_MEASURE_OPTIONS_ERROR = 'REQUEST_OUTCOME_MEASURE_OPTIONS_ERROR';
