import { useRef, useEffect } from 'react';

const useDidUpdateEffect = (fn, inputs) => {
  const didMountRef = useRef(false);
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (didMountRef.current) {
      return fn();
    }
    didMountRef.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, inputs);
};

export default useDidUpdateEffect;
