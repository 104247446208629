import { FunctionComponent, useState } from 'react';
import { License } from 'ts-frontend/types';
import styled, { useEmotionTheme } from '../../core/styled';
import View from '../View';
import Text from '../Text';
import { Large } from '../Typography';
import BaseButton from '../BaseButton';
import { Briefcase, CalendarCheck } from '../icons';
import { useWindowWidthState } from '../../hooks/windowWidthContext';
import LicenseAndAvailability from './LicenseAndAvailability';

interface TherapistProfileProps {
  availableNow: boolean;
  firstName: string;
  joinedYearsAgo: number;
  lastName: string;
  licenses: License[];
  publicDetails: string;
  yearsInPractice: number;
}

const QualificationsWrapper = styled(View)<{ isDesktop: boolean }>(({ isDesktop }) => {
  return {
    marginRight: isDesktop ? 35 : 5,
    marginBottom: 9,
    maxWidth: isDesktop ? 290 : 270,
  };
});

const Qualifications = styled(View)<{ isMobile: boolean }>(({ isMobile }) => {
  return {
    flex: 1,
    marginTop: isMobile ? 25 : 0,
  };
});

const BioSection = styled(View)<{ isMobile: boolean; isLarge: boolean }>(
  ({ isMobile, isLarge }) => {
    return {
      marginRight: isMobile || isLarge ? 0 : 35,
    };
  }
);

const RowOrColumnIfResponsive = styled(View)<{ isMobile: boolean }>(({ isMobile }) => {
  return {
    flexDirection: isMobile ? 'column' : 'row',
  };
});

const HideIfResponsive = styled(View)<{ isMobile: boolean }>(({ isMobile }) => {
  return {
    display: isMobile ? 'none' : 'flex',
  };
});

const Name = styled(Text)({
  fontSize: 25,
  fontWeight: 600,
  textAlign: 'left',
});

const ReadMoreButtonText = styled(Large)(({ theme: { colors } }) => {
  return { color: colors.greenText, textAlign: 'left', marginTop: 5 };
});

const BioExcerpt = styled(Text)({
  textAlign: 'left',
  WebkitLineClamp: 8,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  overflowWrap: 'break-word',
  display: '-webkit-box',
  maxHeight: '12.8565em',
  WebkitBoxOrient: 'vertical',
});

const PublicDetails: FunctionComponent<{
  text: string;
  isCollapsed: boolean;
  isMobile: boolean;
}> = ({ text, isCollapsed, isMobile }) => (
  <View style={{ width: isMobile ? 'inherit' : 290 }}>
    {isCollapsed ? <BioExcerpt>{text}</BioExcerpt> : <Text>{text}</Text>}
  </View>
);

const QualificationsRow: FunctionComponent<{
  isDesktop: boolean;
  title?: string;
  text?: string;
  textItems?: string[];
  iconComponent?: JSX.Element;
  isCollapsed?: boolean;
}> | null = ({ title, text, textItems, iconComponent, isCollapsed, isDesktop }) => {
  const { colors } = useEmotionTheme();
  if (textItems && textItems.length === 0) return null;
  if (!text && !textItems) return null;
  return (
    <QualificationsWrapper isDesktop={isDesktop}>
      <View row>
        <View style={{ width: 22, paddingTop: 3 }}>{iconComponent}</View>
        <View flex={1} style={{ marginLeft: 10 }}>
          <Text style={{ color: colors.grey, fontSize: 14, textAlign: 'left' }}>{title}</Text>
          {text && <Text style={{ textAlign: 'left' }}>{text}</Text>}
          {textItems && (
            <Text style={{ textAlign: 'left' }}>
              {isCollapsed ? textItems.slice(0, 2).join(', ') : textItems.join(', ')}
            </Text>
          )}
        </View>
      </View>
    </QualificationsWrapper>
  );
};

const TherapistProfile: FunctionComponent<TherapistProfileProps> = ({
  firstName,
  joinedYearsAgo,
  lastName,
  licenses,
  publicDetails,
  yearsInPractice,
  availableNow = false,
}) => {
  const { isLarge, isMobile, isDesktop } = useWindowWidthState();
  const yearsInPracticeText =
    yearsInPractice === 0
      ? 'less than 1 year in practice'
      : `${yearsInPractice} year${yearsInPractice !== 1 ? 's' : ''} in practice`;

  const yearsAtTalkspaceText =
    joinedYearsAgo === 0
      ? 'less than a year ago'
      : `${joinedYearsAgo} year${joinedYearsAgo !== 1 ? 's' : ''} ago`;

  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

  return (
    <View>
      <HideIfResponsive isMobile={isMobile}>
        <Name>
          {firstName} {lastName}
        </Name>
        <View row style={{ marginBottom: 6, marginTop: 3 }}>
          <LicenseAndAvailability licenses={licenses} availableNow={availableNow} />
        </View>
      </HideIfResponsive>
      <RowOrColumnIfResponsive isMobile={isMobile}>
        <BioSection isMobile={isMobile} isLarge={isLarge}>
          <PublicDetails text={publicDetails} isCollapsed={isCollapsed} isMobile={isMobile} />
          <BaseButton onPress={() => setIsCollapsed(!isCollapsed)}>
            <ReadMoreButtonText variant="largeBoldWide">
              Read {isCollapsed ? 'more' : 'less'}
            </ReadMoreButtonText>
          </BaseButton>
        </BioSection>
        <Qualifications isMobile={isMobile}>
          <QualificationsRow
            text={yearsInPracticeText}
            iconComponent={<CalendarCheck />}
            isDesktop={isDesktop}
          />
          <QualificationsRow
            title="Joined Talkspace"
            text={yearsAtTalkspaceText}
            iconComponent={<Briefcase />}
            isDesktop={isDesktop}
          />
        </Qualifications>
      </RowOrColumnIfResponsive>
    </View>
  );
};

export default TherapistProfile;
