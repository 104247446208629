import Svg, { Path, Circle, G } from 'svgs';
import styled from '../../core/styled';
import TouchableView from '../TouchableView';

export interface ModalCloseButtonProps {
  onPress: (any) => void;
  width?: number;
  height?: number;
  backgroundColor?: string;
  color?: string;
}

const CloseButtonSVG = styled(Svg)({
  display: 'block',
  '&:hover circle': {
    fill: '#BECBE0',
  },
  '&:hover path': {
    fill: '#546074',
  },
});

const CloseButton = ({
  onPress,
  width = 35,
  height = 35,
  backgroundColor = '#EFF3FA',
  color = '#68768D',
  ...props
}: ModalCloseButtonProps) => (
  <TouchableView onPress={onPress} {...props}>
    <CloseButtonSVG width={width} height={height} viewBox="0 0 35 35">
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Circle fill={backgroundColor} cx={17.5} cy={17.5} r={17.5} />
        <Path
          d="M17.5 16.259l5.013-5.004a.87.87 0 011.233.002.875.875 0 01-.002 1.235L18.726 17.5l5.018 5.008c.34.34.342.893.002 1.235a.87.87 0 01-1.233.002L17.5 18.741l-5.013 5.004a.87.87 0 01-1.233-.002.875.875 0 01.002-1.235l5.018-5.008-5.018-5.008a.875.875 0 01-.002-1.235.87.87 0 011.233-.002l5.013 5.004z"
          fill={color}
          fillRule="nonzero"
        />
      </G>
    </CloseButtonSVG>
  </TouchableView>
);

export default CloseButton;
