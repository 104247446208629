import Svg, { Path } from 'svgs';
import { EmotionStyle, useEmotionTheme } from '../../core/styled';

interface Props {
  width?: number;
  height?: number;
  style?: EmotionStyle;
  color?: string;
}

const CheckV2 = ({ color, ...props }: Props) => {
  const titleText = 'Check mark';
  const { colors } = useEmotionTheme();
  return (
    <Svg
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      title={titleText}
      aria-label={titleText}
      {...props}
    >
      <Path
        d="M1 6.625L5.08571 10L12 1"
        stroke={color || colors.white}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </Svg>
  );
};

export default CheckV2;
