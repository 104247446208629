import { TherapistType } from 'ts-frontend/entities/Therapist';
import { Currency, InsuranceEligibility } from 'ts-frontend/types';
import { TrackerTypes } from '@/utils/analytics/trackerTypes';

// Made an enum as it's easier to find PromiseMessageTypeNames.analytics than 'analytics'
export enum PromiseMessageTypeNames {
  // Therapist-web & native
  'loadStarredMessagesVC' = 'loadStarredMessagesVC',
  'loadSharedFilesVC' = 'loadSharedFilesVC',
  'openBrowserWithURL' = 'openBrowserWithURL',
  'shouldBlockRoom' = 'shouldBlockRoom',
  'popToDashboard' = 'popToDashboard',
  // QM & client-web
  'quickmatchSubscribe' = 'quickmatchSubscribe',
  'geOffersInfo' = 'geOffersInfo',
  'getQMInitData' = 'getQMInitData',
  'ionicNotifyKeyboardStatus' = 'ionicNotifyKeyboardStatus',
  // Shared
  'analytics' = 'analytics',
}

export interface PromiseMessageQMInitDataReceiver {
  /** (Mostly) Copied from client-web/src/types.ts TherapistInfo */
  currentProvidersInfo?: {
    firstName: string;
    lastName: string;
    therapistID: number;
    therapistLicenses: string[];
    therapistImage: string;
    therapistType?: TherapistType;
    title: string;
    status: string;
    isAvailable?: boolean;
  }[];
  hasOnlyPlaceholderProviders: boolean;
}

interface TherapistInfo {
  firstName: string;
  lastName: string;
  therapistID: number;
  therapistLicenses: string[];
  therapistImage: string;
  therapistType?: TherapistType;
}

export interface PromoFromBanner {
  promoCode?: string;
  showOnOffersPage?: 'displayed' | 'hidden';
  billingCycleExperimentActive?: boolean;
  shownAdditionalBillingCycles?: 'show_additional' | 'not_show_additional';
}

export interface HandleQuickmatchSubscribeResponse {
  success: boolean;
  errors: string;
}

export interface PromiseMessageOffersInfo {
  registrationInfo: {
    email: string | null;
    isLoggedInUser: boolean;
  };
  therapistInfo: TherapistInfo;
  insuranceEligibility?: InsuranceEligibility;
  promoFromBanner?: PromoFromBanner;
}

export interface PromiseMessageQuickmatchSubscribe {
  sender: {
    email: string;
    planId: number;
    promoCode?: string;
    paymentToken?: string;
    attribution: {
      discountPercent: number;
      promoValue: number;
      planName: string;
      billingFrequency: number;
      price: number;
      requestedOfferID: number;
      currency: Currency;
    };
  };
  receiver: {
    success: boolean;
    errors: null | {
      // TODO: If monorepo, this type could be imported from QM's registrationHelpers.ts
      serverError: string | null;
      stripeErrorMessage: string | null;
      errorsCodesExists: boolean;
    };
  };
}

export interface PromiseMessageAnalyticsSender {
  eventData?: object;
  trackerType?: TrackerTypes;
}

export interface PromiseMessageIonicNotifyKeyboardStatus {
  keyboardHeight: number;
  isKeyboardOpen: boolean;
}
