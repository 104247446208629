import { useState } from 'react';
import { connect } from 'react-redux';
import { Spinner, Standard, View } from '@talkspace/react-toolkit';
import { PromiseMessageTypeNames } from 'ts-promise-message';
import ReactFrameService from '@/auth/reactFrame/ReactFrameService';
import { isIonic } from '@/auth/reactFrame/helpers';
import { useHistory, useParams } from '@/core/routerLib';

import AccordionContainer from '../../../../Reusable/AccordionContainer/AccordionContainer';
import PsychiatryIcon from '../../../../Icons/PsychiatryIcon';
import Button from '../../../../Reusable/Buttons/Button';
import useQueryDoseSpotPatient from './useQueryDoseSpotPatient';
import useQueryDoseSpotSSO from './useQueryDoseSpotSSO';
import { generateDoseSpotPatientURL } from './utils';

interface Props {
  roomType: string;
  therapistType: string;
  clientUserID: number;
}

const Psychiatry = ({ roomType, therapistType, clientUserID }: Props) => {
  const history = useHistory();

  const { roomID } = useParams<{ roomID: string }>();

  const [allowDoseSpotRetry, setAllowDoseSpotRetry] = useState(false);

  const {
    data: doseSpotPatient,
    isLoading: isLoadingPatient,
    refetch: refetchDoseSpotPatient,
  } = useQueryDoseSpotPatient(
    {
      clientUserID,
    },
    {
      enabled: roomType === 'psychiatry_room' && therapistType === 'psychiatrist',
      onError: (e) => {
        if (e.message === '404') {
          return;
        }
        setAllowDoseSpotRetry(true);
      },
    }
  );

  const { refetch: refetchDoseSpotSSO, isLoading: isLoadingDoseSpotSSO } =
    useQueryDoseSpotSSO(false);

  const handleEditRXClick = async () => {
    if (!doseSpotPatient) {
      return;
    }

    const { data } = await refetchDoseSpotSSO();
    const doseSpotPatientURL = generateDoseSpotPatientURL(doseSpotPatient.doseSpotPatientID, data!);

    if (isIonic()) {
      window.location.href = doseSpotPatientURL;
    } else if (ReactFrameService.instance().isInFrame()) {
      await ReactFrameService.instance().sendAsyncMessage(
        PromiseMessageTypeNames.openBrowserWithURL,
        {
          url: doseSpotPatientURL,
          roomID: Number(roomID),
        }
      );
    } else {
      window.open(doseSpotPatientURL);
    }
  };

  const getButton = () => {
    if (roomType === 'psychiatry_room' && therapistType !== 'psychiatrist') {
      return null;
    }

    if (roomType !== 'psychiatry_room') {
      return (
        <Button
          title="Refer to psychiatry"
          clickHandler={() => history.push(`/room/${roomID}/create-psychiatry-room`)}
          isSecondary={false}
        />
      );
    }

    if (isLoadingPatient) {
      return (
        <View align="end">
          <Spinner />
        </View>
      );
    }

    if (therapistType === 'psychiatrist' && !doseSpotPatient?.doseSpotPatientID) {
      return (
        <Button
          title={allowDoseSpotRetry ? 'Retry' : 'Add client to DoseSpot'}
          clickHandler={
            allowDoseSpotRetry
              ? refetchDoseSpotPatient
              : () => history.push(`/room/${roomID}/dosespot-patient-form`)
          }
          isSecondary={false}
        />
      );
    }

    if (therapistType === 'psychiatrist' && doseSpotPatient?.doseSpotPatientID) {
      return (
        <View justify="space-between" style={{ height: 50 }}>
          <Button
            title="Edit Rx"
            isActive={!isLoadingDoseSpotSSO}
            isSecondary={false}
            clickHandler={handleEditRXClick}
          />
          <Button
            title="Edit client information"
            clickHandler={() => history.push(`/room/${roomID}/dosespot-patient-form`)}
            isSecondary={false}
          />
        </View>
      );
    }

    return null;
  };

  const getCardText = () => {
    if (roomType !== 'psychiatry_room') {
      return 'Your client is not signed up for psychiatry';
    }

    if (
      roomType === 'psychiatry_room' &&
      !doseSpotPatient?.doseSpotPatientID &&
      allowDoseSpotRetry
    ) {
      return 'There was an issue connecting to DoseSpot.';
    }

    if (roomType === 'psychiatry_room' && !doseSpotPatient?.doseSpotPatientID) {
      return 'Your client has not been added to DoseSpot yet';
    }

    if (roomType === 'psychiatry_room' && doseSpotPatient?.doseSpotPatientID) {
      return 'Access DoseSpot to prescribe medication';
    }

    return '';
  };

  const getCard = () => (
    <View>
      <View row justify="space-between">
        <PsychiatryIcon style={{ flex: '1 1 50%' }} />
        <Standard style={{ color: '#ccc' }}>{getCardText()}</Standard>
      </View>
      {getButton()}
    </View>
  );

  return (
    <AccordionContainer
      title="Psychiatry"
      childComponents={[getCard()]}
      dataQa="psychiatryAccordion"
    />
  );
};

const mapStateToProps = (state) => {
  return {
    roomType: state.room.roomType,
    therapistType: state.availability.therapistType,
    clientUserID: state.customerInformation.clientUserID,
  };
};

export default connect(mapStateToProps)(Psychiatry);
