import { useEffect } from 'react';
import moment from 'moment';
import { TextInput, AccordionContainer } from '@talkspace/react-toolkit';
import { usePlan, SessionReport } from '../../../../../modules/plan';
import { withRouter, RouteComponentProps } from '../../../../../core/routerLib';

function generateSessionsText(sessionReports: SessionReport[], isEAP: boolean): string {
  const remaining = sessionReports.filter((s) => !s.completedAt).length;
  const completed = sessionReports.filter((s) => s.completedAt).length;
  return isEAP
    ? `${remaining} of ${sessionReports.length} EAP sessions remaining`
    : `${completed} completed`;
}

function generateAdditionalText(textLines: string[]): string {
  return textLines.reduce((prev, next) => `${prev} \n ${next.trim()}`, '');
}

const PlanInformation = ({ match }: RouteComponentProps<{ roomID: string }>) => {
  const [{ planInformation }, { dispatchGetPlanInformation }] = usePlan();

  useEffect(() => {
    dispatchGetPlanInformation(match.params.roomID);
  }, [dispatchGetPlanInformation, match.params.roomID]);

  if (!planInformation) return null;

  const {
    planName,
    isEAP,
    planStartDate,
    planExpires,
    planRenews,
    sessionReports,
    liveVideoSessionsSummary,
    partnerName,
    employerName,
    includesPsychiatry,
    includesDependents,
    minimumAge,
    crisisProtocol,
    additionalPlanInformation,
  } = planInformation;

  const { therapistCRMItems = [] } = additionalPlanInformation || {};

  return (
    <AccordionContainer title="Plan information">
      <TextInput
        placeholder="Plan name"
        disabled
        value={planName}
        multiline
        disabledNumberOfLines={5}
      />
      <TextInput
        placeholder="Plan start date"
        disabled
        value={planStartDate ? moment(planStartDate).format('MMMM D, YYYY') : 'Offer not accepted'}
      />
      {planExpires && (
        <TextInput
          placeholder="Plan expires"
          disabled
          value={moment(planExpires).format('MMMM D, YYYY')}
        />
      )}
      {planRenews && (
        <TextInput
          placeholder="Plan renews"
          disabled
          value={moment(planRenews).format('MMMM D, YYYY')}
        />
      )}
      {sessionReports && (
        <TextInput
          placeholder={isEAP ? 'EAP sessions' : 'BH sessions'}
          disabled
          value={generateSessionsText(sessionReports, isEAP)}
        />
      )}
      {liveVideoSessionsSummary && (
        <TextInput
          placeholder="Live sessions"
          disabled
          value={liveVideoSessionsSummary}
          multiline
          disabledNumberOfLines={5}
          style={{ whiteSpace: 'pre-line' }} // summary contains line breaks
        />
      )}
      {partnerName && (
        <TextInput
          placeholder="Partner name"
          disabled
          value={partnerName}
          multiline
          disabledNumberOfLines={5}
        />
      )}
      {employerName && (
        <TextInput
          placeholder="Employer name"
          disabled
          value={employerName.replace(/_/g, ' ')}
          multiline
          disabledNumberOfLines={5}
        />
      )}
      {includesPsychiatry !== undefined && (
        <TextInput
          placeholder="Psychiatry"
          disabled
          value={includesPsychiatry ? 'Covered' : 'Not covered'}
        />
      )}
      {includesDependents !== undefined && (
        <TextInput
          placeholder="Dependents"
          disabled
          value={includesDependents ? 'Covered' : 'Not covered'}
        />
      )}
      {planName && minimumAge !== undefined && (
        <TextInput
          placeholder="Clients under age 18"
          disabled
          value={minimumAge >= 18 ? 'Not covered' : 'Covered'}
        />
      )}
      {crisisProtocol && <TextInput placeholder="Dependents" disabled value={crisisProtocol} />}
      {therapistCRMItems && therapistCRMItems.length > 0 && (
        <TextInput
          placeholder="Additional plan information"
          disabled
          value={generateAdditionalText(therapistCRMItems)}
          multiline
          disabledNumberOfLines={5}
        />
      )}
    </AccordionContainer>
  );
};

export default withRouter(PlanInformation);
