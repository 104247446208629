import Svg, { Path } from 'svgs';
import { useEmotionTheme } from '../../core/styled';

const ArrowUpV2 = ({
  width = 13,
  height = 8,
  fill,
  ...otherProps
}: {
  width?: number;
  height?: number;
  fill?: string;
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'up arrow';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 13 8"
      {...otherProps}
    >
      <Path
        d="M2.25 7.25C1.83579 7.66421 1.16421 7.66421 0.75 7.25V7.25C0.335787 6.83579 0.335786 6.16421 0.75 5.75L6.5 4.76837e-07L12.25 5.75C12.6642 6.16421 12.6642 6.83579 12.25 7.25V7.25C11.8358 7.66421 11.1642 7.66421 10.75 7.25L6.5 3L2.25 7.25Z"
        fill={colors.accessibilityGreenDark}
        clipRule="evenodd"
        fillRule="evenodd"
        stroke={colors.accessibilityGreenDark}
        strokeWidth={0.5}
        strokeLinecap="round"
      />
    </Svg>
  );
};

export default ArrowUpV2;
