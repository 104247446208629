import { VoidFunctionComponent } from 'react';
import Svg, { Path } from 'svgs';
import { useEmotionTheme, EmotionStyle } from '../../core/styled';

interface ClipBoardProps {
  width?: number;
  height?: number;
  color?: string;
  style?: EmotionStyle;
}

const ClipBoardIcon: VoidFunctionComponent<ClipBoardProps> = ({
  width = 20,
  height = 23,
  color,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'clipboard';
  return (
    <Svg width={width} height={height} title={titleText} aria-label={titleText} {...otherProps}>
      <Path
        d="M7.594 22.86h8.86c.878 0 1.543-.224 1.994-.672.451-.448.677-1.112.677-1.99v-8.36c0-.878-.226-1.539-.677-1.981-.45-.442-1.116-.664-1.995-.664H14.45v1.512h1.934c.398 0 .704.104.918.312.214.208.321.52.321.936v8.139c0 .416-.107.728-.32.936-.215.208-.52.312-.92.312H7.656c-.392 0-.697-.104-.914-.312-.217-.208-.325-.52-.325-.936v-1.89H4.913v1.995c0 .88.224 1.543.672 1.991.449.448 1.118.672 2.009.672ZM2.68 18.923h9.553c.891 0 1.56-.223 2.009-.668.448-.445.672-1.108.672-1.986V8.042c0-.873-.224-1.532-.672-1.978-.449-.445-1.118-.668-2.009-.668H9.958V6.9h2.215c.392 0 .697.104.914.312.217.208.325.523.325.945v8.007c0 .41-.108.72-.325.927-.217.208-.522.312-.914.312h-9.43c-.393 0-.697-.104-.91-.312-.214-.208-.321-.517-.321-.927V8.156c0-.422.107-.737.32-.945.214-.208.518-.312.91-.312h2.224V5.396H2.68c-.885 0-1.553.222-2.004.664C.226 6.502 0 7.163 0 8.042v8.227c0 .878.226 1.54.677 1.986.45.445 1.119.668 2.004.668Zm4.78-6.583c.2 0 .37-.072.51-.215.141-.144.212-.31.212-.497V3.182l-.053-1.23.536.597L9.87 3.823a.61.61 0 0 0 .492.22.657.657 0 0 0 .457-.176.579.579 0 0 0 .194-.448.628.628 0 0 0-.211-.466L8.007.246A.956.956 0 0 0 7.73.053a.694.694 0 0 0-.536 0 .956.956 0 0 0-.277.193L4.122 2.953a.628.628 0 0 0-.21.466c0 .182.062.331.188.448.126.117.28.176.462.176.199 0 .363-.073.492-.22L6.258 2.55l.545-.607-.062 1.24v8.446c0 .187.07.353.211.497.14.143.31.215.51.215Z"
        fill={color || colors.accessibilityGreenDark}
        fillRule="nonzero"
      />
    </Svg>
  );
};

export default ClipBoardIcon;
