import Svg, { Path, Rect } from 'svgs';
import { FunctionComponent } from 'react';
import { useEmotionTheme } from '../../core/styled';

interface CalendarPlusIconProps {
  width?: number;
  height?: number;
  color?: string;
}

const CalendarPlusIcon: FunctionComponent<CalendarPlusIconProps> = ({
  width = 17,
  height = 16,
  color,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();

  return (
    <Svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      {...otherProps}
    >
      <Path
        d="M0.75 3C0.75 1.75736 1.75736 0.75 3 0.75H14C15.2426 0.75 16.25 1.75736 16.25 3V13C16.25 14.2426 15.2426 15.25 14 15.25H3C1.75736 15.25 0.75 14.2426 0.75 13V3Z"
        stroke={color || colors.permaDarkForest}
        stroke-width="1.5"
      />
      <Rect x="7.5" y="5.5" width="2" height="7" rx="1" fill={color || colors.permaDarkForest} />
      <Rect x="5" y="8" width="7" height="2" rx="1" fill={color || colors.permaDarkForest} />
      <Path d="M1 1H16V4H1V1Z" fill={color || colors.permaDarkForest} />
    </Svg>
  );
};

export default CalendarPlusIcon;
