import TabRadioGroup, { TabRadioGroupProps } from '../../../components/TabRadioGroup';
import TabRadioGroupV1 from './TabRadioGroupV1';
import { withVersioning } from '../../utils';
import { VersionMap } from '../../types';

export type { TabRadioGroupProps };

const versionMap: VersionMap<React.ComponentProps<typeof TabRadioGroup>> = {
  '1.0.0': TabRadioGroupV1,
  DefaultComponent: TabRadioGroup,
};

export default withVersioning(versionMap);
