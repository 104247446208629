import { forwardRef } from 'react';
import { useHistory } from 'react-router';
import TouchableView, { TouchableViewProps } from '../TouchableView';
import LeftCaret from '../icons/CaretBase';
import { EmotionStyle } from '../../core/styled';

interface BackNextButtonProps extends TouchableViewProps {
  width?: number;
  height?: number;
  onPress?: () => void;
  isRight?: boolean;
  style?: EmotionStyle;
  caretColor?: string;
}

// Can be turned into a "next" button simply by passing isRight prop

const BackNextButton = forwardRef<HTMLDivElement, BackNextButtonProps>(
  ({ width, height, onPress, isRight, style, caretColor, ...otherProps }, ref) => {
    const history = useHistory();

    const handleBackButtonClick = () => {
      if (onPress) {
        onPress();
      } else history.goBack();
    };

    return (
      <TouchableView
        ref={ref}
        onPress={handleBackButtonClick}
        aria-label={isRight ? 'next' : 'go back'}
        style={{ display: 'inline', ...style }}
        {...otherProps}
      >
        <LeftCaret
          isRight={isRight}
          width={width}
          height={height}
          color={caretColor}
          data-qa="back-next-button"
        />
      </TouchableView>
    );
  }
);

export default BackNextButton;
