import { FunctionComponent } from 'react';
import { View, Text, useEmotionTheme } from '@talkspace/react-toolkit';
import { ID_MESSAGE_TEXT } from '@/utils/IDConstants';
import { MessageProps } from '../../types';
import Phone from '../Icons/Phone';
import { MessageBodyHasPhoneIcon } from '../../entities/EMessage';

const BodyWithPhoneIcon: FunctionComponent<MessageProps> = (props) => {
  const { currentMessage } = props;
  const { colors } = useEmotionTheme();
  const { text, phoneIconColor = colors.grey } =
    currentMessage.messageBody as MessageBodyHasPhoneIcon;
  return (
    <View align="center" style={{ marginTop: 14, marginBottom: 14, maxWidth: 1000 }}>
      <Phone color={phoneIconColor} width={34} height={34} style={{ marginBottom: 18 }} />
      <Text id={`${ID_MESSAGE_TEXT}-${currentMessage.id}`}>{text}</Text>
    </View>
  );
};

export default BodyWithPhoneIcon;
