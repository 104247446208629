import Svg, { G, Circle, Text, TSpan } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

const CircledExclamationMark = ({
  width = 22,
  height = 22,
  color,
  ...otherProps
}: {
  width?: number;
  height?: number;
  color?: string;
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'exclamation';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox={`0 0 ${width} ${height}`}
      {...otherProps}
    >
      <G fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <Circle cx="11" cy="11" r="11" fill={color || colors.pink} />
        <Text fill={colors.white} fontFamily="Helvetica" fontSize="17" fontWeight="normal">
          <TSpan x="9.277" y="16">
            !
          </TSpan>
        </Text>
      </G>
    </Svg>
  );
};

export default CircledExclamationMark;
