import { useQuery } from 'react-query';
import { NoteStatus, User } from 'components/Room/CRMContainer/NotesTab/types';
import apiHelper from '@/utils/api';
import apiWrapper from '../../utils/apiWrapper';
import { collateralNoteQueryKey } from './queryKeys';

export interface CollateralNoteQueryResponse {
  contactDate: string | null;
  contactName: string | null;
  contactRelationship: string | null;
  contactSummary: string | null;
  createdByUser: User;
  missingObtainedReleaseReason: string | null;
  releaseObtained: boolean;
  readOnly: boolean;
  roomID: number;
  statementCertified: boolean;
  status: NoteStatus;
  submittedAt: string | null;
}

const fetchNote = (clientUserID?: number, noteID?: string) => async () => {
  const { data } = await apiWrapper.get(
    `${apiHelper().apiEndpoint}/v2/clients/${clientUserID}/collateral-notes/${noteID}`
  );
  return data.data;
};

const useQueryCollateralNote = (clientUserID?: number, noteID?: string, enabled: boolean = true) =>
  useQuery<CollateralNoteQueryResponse, Error>({
    queryKey: collateralNoteQueryKey(clientUserID, noteID),
    queryFn: fetchNote(clientUserID, noteID),
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!clientUserID && !!noteID && enabled,
  });

export default useQueryCollateralNote;
