import { Standard, Huge, View, styled, EmotionStyle } from '@talkspace/react-toolkit';
import { ReactNode } from 'react';

interface SectionContainerProps {
  title?: string;
  subtitle?: string | ReactNode;
  children: ReactNode;
  containerStyle?: EmotionStyle;
  tooltip?: ReactNode;
}

const Container = styled(View)<{ containerStyle?: EmotionStyle }>(({ containerStyle }) => {
  return {
    paddingRight: 13,
    paddingLeft: 13,
    width: '100%',
    ...containerStyle,
  };
});
const Title = styled(Huge)(() => {
  return {
    fontWeight: 400,
    fontSize: 21,
  };
});

const Subtitle = styled(Standard)(({ theme: { colors } }) => {
  return {
    marginTop: 5,
    color: colors.permaRiverBed,
    fontWeight: 400,
    marginBottom: 10,
    fontSize: 14,
    position: 'relative',
  };
});

const SectionContainer = ({
  children,
  title,
  subtitle,
  containerStyle,
  tooltip,
}: SectionContainerProps) => (
  <Container containerStyle={containerStyle}>
    {title && <Title style={{ fontWeight: 400 }}>{title}</Title>}
    {subtitle && (
      <Subtitle inline>
        {subtitle} {tooltip}
      </Subtitle>
    )}
    {children}
  </Container>
);

export default SectionContainer;
