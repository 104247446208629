import { LiveSessionModality, SessionModality, VideoCreditOffer } from 'ts-frontend/types';
import { useQuery, UseQueryOptions } from 'react-query';

import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';
import { videoCreditOffersQueryKey } from '../utils/queryKeys';
import { MessagingSessionOffer, UnavailabilityMessage } from '../types';

export interface VideoCreditOffersResponse {
  data: {
    liveSessions: VideoCreditOffer[];
    liveModalitiesUnavailabilityMessage: Record<LiveSessionModality, UnavailabilityMessage>;
    messagingSession: MessagingSessionOffer;
    defaultModality?: SessionModality;
    skipCreditCard?: boolean;
  };
}

export type InvokeSource = 'memberOpenChat' | 'providerOpenChat' | 'inCall' | 'bookSession';

const getVideoCreditOffers =
  (roomID: number, bookingID?: string, source?: InvokeSource) =>
  async (): Promise<VideoCreditOffersResponse['data']> => {
    const urlParams = new URLSearchParams({
      ...(bookingID ? { bookingID } : {}),
      ...(source ? { source } : {}),
    });
    const queryString = urlParams.toString();

    return apiWrapper
      .get<VideoCreditOffersResponse>(
        `${apiHelper().apiEndpoint}/v3/rooms/${roomID}/video-credit-offers${
          queryString ? `?${queryString}` : ''
        }`
      )
      .then((res) => res.data.data);
  };

const useQueryVideoCreditOffers = (
  { roomID, bookingID, source }: { roomID: number; bookingID?: string; source?: InvokeSource },
  options: UseQueryOptions<
    VideoCreditOffersResponse['data'],
    Error,
    VideoCreditOffersResponse['data']
  > = {}
) =>
  useQuery<VideoCreditOffersResponse['data'], Error>({
    queryKey: videoCreditOffersQueryKey({ roomID, bookingID }),
    queryFn: getVideoCreditOffers(roomID, bookingID, source),
    enabled: !Number.isNaN(roomID),
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
    ...options,
  });

export default useQueryVideoCreditOffers;
