import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const standardPath =
  'M0 2C0 0.896875 0.896875 0 2 0H14C15.1031 0 16 0.896875 16 2V11C16 12.1031 15.1031 13 14 13H9.66562L5.8 15.9C5.65 16.0125 5.44688 16.0312 5.275 15.9469C5.10313 15.8625 5 15.6906 5 15.5V13H2C0.896875 13 0 12.1031 0 11L0 2ZM6.41563 3.5C5.35938 3.5 4.5 4.35625 4.5 5.41563C4.5 5.92188 4.70312 6.40938 5.05937 6.76875L7.64375 9.35312C7.8375 9.54688 8.15625 9.54688 8.35 9.35312L10.9344 6.76875C11.2937 6.40938 11.4937 5.92188 11.4937 5.41563C11.4937 4.35938 10.6375 3.5 9.57812 3.5C9.07187 3.5 8.58438 3.70312 8.225 4.05937L7.99375 4.29063L7.7625 4.05937C7.40312 3.7 6.91563 3.5 6.40938 3.5H6.41563Z';

const pathConfig: IconConfig = {
  standard: {
    default: {
      path: standardPath,
    },
  },
};

const MessageHeart = withDSIconMaker(pathConfig, 'Message Heart');

export default MessageHeart;
