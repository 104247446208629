import { useEffect, useState } from 'react';

const useCountdownTimer = ({
  shouldRepeat = false,
  timeInSeconds,
  onTimeIsUp,
  shouldResetTimer = false,
  handleResetTimer,
}: {
  timeInSeconds: number;
  onTimeIsUp: () => void;
  shouldRepeat?: boolean;
  shouldResetTimer: boolean;
  handleResetTimer: (value: boolean) => void;
}) => {
  const [timeLeft, setTimeLeft] = useState<number>(timeInSeconds);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (shouldResetTimer) {
      setTimeLeft(timeInSeconds);
      handleResetTimer(false);
    } else if (timeInSeconds > 0) {
      intervalId = setInterval(() => {
        setTimeLeft((prevTimeLeft) => {
          if (prevTimeLeft === 1) {
            if (shouldRepeat) {
              onTimeIsUp();
              return timeInSeconds;
            }
            onTimeIsUp();
            clearInterval(intervalId);
            return 0;
          }
          return prevTimeLeft - 1;
        });
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [onTimeIsUp, shouldRepeat, timeInSeconds, shouldResetTimer, handleResetTimer]);

  return timeLeft;
};

export default useCountdownTimer;
