import { FunctionComponent, useState } from 'react';
import {
  View,
  Small,
  Big,
  Huge,
  ExtraHuge,
  Massive,
  TooltipV2,
  Link,
} from '@talkspace/react-toolkit';
import LongTermBonus from './LongTermBonus';
import styled from '../../core/styled';
import ssoHelper from '../../modules/utils/sso';

interface PaymentReportsHeaderProps {
  grandTotal?: string;
  headingText?: string;
  longTermBonusText?: string;
  isMobile?: boolean;
  isDesktop?: boolean;
  isLongTermBonusDisabled: boolean;
  isProvisional: boolean;
  lastUpdated?: string;
  zendeskLink: string;
}

const TooltipContainer = styled(View)(({ theme: { colors } }) => {
  return {
    height: 36,
    width: 335,
  };
});

const PaymentReportsHeader: FunctionComponent<PaymentReportsHeaderProps> = ({
  grandTotal,
  longTermBonusText,
  headingText,
  isMobile,
  isDesktop,
  isLongTermBonusDisabled,
  isProvisional,
  lastUpdated,
  zendeskLink,
}) => {
  const [isHovering, setIsHovering] = useState<boolean>(false);

  const toolTipText = (
    <View style={{ fontFamily: 'roboto' }}>
      <View style={{ fontWeight: 700, fontSize: 15, lineHeight: '30px' }}>
        What does current monthly total mean?
      </View>
      <View style={{ fontWeight: 400, fontSize: 14 }}>
        This current monthly total updates daily and reflects your accumulated earnings for the
        month. Payment occurs on a bi-weekly basis.
      </View>
      <Link
        onClick={() => ssoHelper.openZendesk(zendeskLink)}
        style={{
          fontWeight: 700,
          fontSize: 14,
          color: '#005C65',
          textDecoration: 'underline',
          marginLeft: 0,
        }}
      >
        Learn more
      </Link>
    </View>
  );

  const lastUpdatedText = lastUpdated ? `Last updated: ${lastUpdated}` : '';

  return (
    <>
      <View style={{ paddingLeft: isMobile ? 20 : 50, paddingRight: 20 }}>
        {isMobile ? (
          <ExtraHuge style={{ fontWeight: 'bold', marginBottom: 11 }}>{headingText}</ExtraHuge>
        ) : (
          <Massive style={{ fontWeight: 'bold', marginBottom: -20 }}>{headingText}</Massive>
        )}
        <View
          row={!isMobile}
          justify="space-between"
          style={{ marginBottom: 25, alignItems: isMobile ? 'center' : 'flex-end' }}
        >
          <View
            style={{
              paddingTop: isMobile ? 0 : 24,
              alignSelf: isMobile ? 'flex-start' : 'initial',
            }}
          >
            {isProvisional ? (
              <TooltipContainer
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
                row
                align="center"
              >
                {isMobile ? (
                  <Small style={{ fontSize: 17, color: '#434B64', fontWeight: 700 }}>
                    Current monthly total
                  </Small>
                ) : (
                  <Big style={{ color: '#434B64', fontWeight: 700 }}>Current monthly total</Big>
                )}
                <TooltipV2
                  toolTipText={toolTipText}
                  isTooltipOpen={isHovering}
                  containerStyle={{ right: isMobile ? -200 : -100, top: 22 }}
                  circleStyle={{
                    width: 17,
                    height: 17,
                    marginTop: isMobile ? 0 : 2,
                    marginLeft: 3,
                  }}
                  circleColor="#5F7A9B"
                  hoverColor="#005C65"
                />
              </TooltipContainer>
            ) : (
              <TooltipContainer row align="center">
                {isMobile ? (
                  <Small style={{ fontSize: 17, color: '#434B64', fontWeight: 700 }}>
                    Grand total
                  </Small>
                ) : (
                  <Big style={{ color: '#434B64', fontWeight: 700 }}>Grand total</Big>
                )}
              </TooltipContainer>
            )}
            {isMobile ? <Huge>{`${grandTotal}`}</Huge> : <ExtraHuge>{`${grandTotal}`}</ExtraHuge>}
            <View style={{ fontSize: 14, fontWeight: 400, color: '#434B64', paddingTop: 3 }}>
              {lastUpdatedText}
            </View>
          </View>
          <View style={{ marginTop: isMobile ? 14 : 'auto' }}>
            {!isLongTermBonusDisabled && (
              <LongTermBonus text={longTermBonusText} isMobile={isMobile} />
            )}
          </View>
        </View>
      </View>
    </>
  );
};
export default PaymentReportsHeader;
