import Svg, { Path } from 'svgs';
import { useEmotionTheme } from '../../core/styled';
import type { EmotionStyle } from '../../core/styled';

interface Props {
  width?: number;
  height?: number;
  color?: string;
  style?: EmotionStyle;
}

const CalendarXOutlineIcon = ({ color, width = 24, height = 28, ...otherProps }: Props) => {
  const { colors } = useEmotionTheme();
  const title = 'Calendar crossed outline icon';
  return (
    <Svg
      width={width}
      height={height}
      title={title}
      aria-label={title}
      viewBox="0 0 24 24"
      fill="none"
      {...otherProps}
    >
      <Path
        d="M7.875 1C8.44648 1 8.90625 1.45977 8.90625 2.03125V3.75H15.0938V2.03125C15.0938 1.45977 15.5535 1 16.125 1C16.6965 1 17.1562 1.45977 17.1562 2.03125V3.75H18.875C20.3918 3.75 21.625 4.9832 21.625 6.5V7.1875V9.25V20.25C21.625 21.7668 20.3918 23 18.875 23H5.125C3.6082 23 2.375 21.7668 2.375 20.25V9.25V7.1875V6.5C2.375 4.9832 3.6082 3.75 5.125 3.75H6.84375V2.03125C6.84375 1.45977 7.30352 1 7.875 1ZM19.5625 9.25H4.4375V20.25C4.4375 20.6281 4.74688 20.9375 5.125 20.9375H18.875C19.2531 20.9375 19.5625 20.6281 19.5625 20.25V9.25ZM15.4805 13.0742L13.4609 15.0938L15.4805 17.1133C15.8844 17.5172 15.8844 18.1703 15.4805 18.5699C15.0766 18.9695 14.4234 18.9738 14.0238 18.5699L12.0043 16.5504L9.98477 18.5699C9.58086 18.9738 8.92773 18.9738 8.52812 18.5699C8.12852 18.166 8.12422 17.5129 8.52812 17.1133L10.5477 15.0938L8.52812 13.0742C8.12422 12.6703 8.12422 12.0172 8.52812 11.6176C8.93203 11.218 9.58516 11.2137 9.98477 11.6176L12.0043 13.6371L14.0238 11.6176C14.4277 11.2137 15.0809 11.2137 15.4805 11.6176C15.8801 12.0215 15.8844 12.6746 15.4805 13.0742Z"
        fill={colors.permaFiord}
      />
    </Svg>
  );
};

export default CalendarXOutlineIcon;
