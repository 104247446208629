import { useMutation, useQueryClient } from 'react-query';
import apiHelper from '@/utils/api';
import apiWrapper from '@/core/api/apiWrapper';
import { parentalConsentQueryKey } from './queryKeys';

const resubmitParentalConsent = async (clientUserID: number): Promise<void> => {
  await apiWrapper.post(
    `${apiHelper().apiEndpoint}/v2/users/${clientUserID}/parental-consent/create-resubmit-message`
  );
};

const useMutationResubmitParentalConsent = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, number>(resubmitParentalConsent, {
    onSuccess: (_, clientUserID) => {
      queryClient.invalidateQueries(parentalConsentQueryKey(clientUserID));
    },
  });
};

export default useMutationResubmitParentalConsent;
