import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const standardPath = '';
const majorPath =
  'M13.3344 2.99951L11.8219 4.51201L15.8844 8.57451L17.3969 7.06201C18.1782 6.28076 18.1782 5.01514 17.3969 4.23389L16.1657 2.99951C15.3844 2.21826 14.1188 2.21826 13.3375 2.99951H13.3344ZM11.1157 5.21826L3.83128 12.5058C3.50628 12.8308 3.26878 13.2339 3.13753 13.6745L2.03128 17.4339C1.95315 17.6995 2.02503 17.9839 2.21878 18.1776C2.41253 18.3714 2.6969 18.4433 2.9594 18.3683L6.71878 17.262C7.1594 17.1308 7.56253 16.8933 7.88753 16.5683L15.1782 9.28076L11.1157 5.21826Z';

const pathConfig: IconConfig = {
  standard: {
    default: {
      path: standardPath,
    },
  },
  major: {
    default: {
      path: majorPath,
    },
  },
};

const Pen = withDSIconMaker(pathConfig, 'pen');

export default Pen;
