import { View, useEmotionTheme, useWindowWidth } from '@talkspace/react-toolkit';
import moment from 'moment';

import styled from '../../core/styled';

const MobileHeadline = styled(View)({ width: '100%', padding: 20, fontSize: 16 });

const MobileEquationWrapper = styled(View)(({ theme: { colors } }) => {
  return { width: '100%', background: colors.permaGreyed, padding: 20, fontSize: 16 };
});

const MobileTopEquationSection = styled(View)(({ theme: { colors } }) => {
  return {
    borderBottom: `solid 1px ${colors.permaLondonGray}`,
    paddingBottom: 16,
    marginBottom: 16,
  };
});

const MobileBottomEquationSection = styled(View)(({ theme: { colors } }) => {
  return {
    marginTop: 8,
    fontWeight: 700,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    color: colors.permaLividBlueNew,
  };
});

const MobileBonusEarnedSection = styled(View)<{ isBonus }>(({ theme: { colors }, isBonus }) => {
  return {
    marginTop: 8,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    color: isBonus ? colors.forestGreen : colors.permaLividBlueNew,
  };
});

const MobileBasePaySection = styled(View)(({ theme: { colors } }) => {
  return {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    color: colors.TSBlack,
  };
});

const PartitionContentWrapper = styled(View)({
  width: '100%',
  marginBottom: 40,
  fontFamily: 'roboto',
});

const UpperSectionWrapper = styled(View)({
  display: 'flex',
  flexDirection: 'row',
  marginBottom: 40,
  alignItems: 'end',
});

const BottomSectionWrapper = styled(View)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

const MathSign = styled(View)(({ theme: { colors } }) => {
  return {
    fontSize: 40,
    color: colors.TSBlack,
    marginRight: 16,
  };
});

const TextWrapper = styled(View)({
  fontWeight: 400,
  fontSize: 16,
  lineHeight: '18px',
  marginBottom: 5,
});

const CubeContentWrapper = styled(View)<{ wrapperStyle?: React.CSSProperties }>(
  ({ wrapperStyle, theme: { colors } }) => {
    return {
      marginRight: 16,
      height: 114,
      width: 182,
      borderRadius: 7,
      padding: 30,
      textAlign: 'center',
      background: colors.permaGreyed,
      ...wrapperStyle,
    };
  }
);

const AmountWrapper = styled(View)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return { fontWeight: 600, fontSize: isMobile ? 16 : 28, lineHeight: '32px' };
  }
);

const LastUpdatedSection = styled(View)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      marginLeft: isMobile ? 0 : 24,

      fontSize: 14,
      fontWeight: 400,
      height: 17,
    };
  }
);

interface PaymentCubeProps {
  amount: number;
  text: string;
  style?: React.CSSProperties;
}
export const PaymentCube = ({ amount, text, style = {} }: PaymentCubeProps) => (
  <CubeContentWrapper wrapperStyle={style}>
    <TextWrapper>{text}</TextWrapper>
    <AmountWrapper>{`$${amount}`}</AmountWrapper>
  </CubeContentWrapper>
);

interface BasePayAndBonusPartitionSectionProps {
  bonus: number;
  basePay: number;
  month: number;
  year: number;
  updatedAt?: Date;
  isFetchedForCurrentMonth?: boolean;
}

const getLastUpdatedText = (updatedAt?: Date) => {
  const updatedAtMoment = moment.utc(updatedAt).tz('America/New_York');
  if (updatedAt) {
    return `Last updated on ${updatedAtMoment.format(
      'MMMM'
    )} ${updatedAtMoment.date()}, ${updatedAtMoment.format('YYYY')} at ${updatedAtMoment.format(
      'h:mma z'
    )}`;
  }
  return '';
};

const BasePayAndBonusPartitionSectionStandard = ({
  month,
  year,
  bonus,
  basePay,
  updatedAt,
  isFetchedForCurrentMonth,
}: BasePayAndBonusPartitionSectionProps) => {
  const { colors } = useEmotionTheme();

  return (
    <PartitionContentWrapper>
      <UpperSectionWrapper>
        <View style={{ fontSize: 29, fontWeight: 700 }}>
          {`${moment().month(month).format('MMMM')} ${year} earnings`}
        </View>

        {updatedAt && <LastUpdatedSection>{getLastUpdatedText(updatedAt)}</LastUpdatedSection>}
      </UpperSectionWrapper>
      <BottomSectionWrapper>
        <PaymentCube
          text={isFetchedForCurrentMonth ? 'Base pay to date' : 'Base pay'}
          amount={basePay}
          style={{ background: colors.permaGreyed }}
        />
        <MathSign>+</MathSign>
        {bonus ? (
          <PaymentCube
            text="Bonus earned"
            amount={bonus}
            style={{ color: colors.forestGreen, background: colors.alabaster }}
          />
        ) : (
          <PaymentCube
            text="Bonus earned"
            amount={bonus}
            style={{
              width: 169,
              color: colors.permaLividBlueNew,
              background: 'white',
              border: `1px dashed ${colors.permaLividBlueNew}`,
            }}
          />
        )}
        <MathSign>=</MathSign>
        <PaymentCube
          text={isFetchedForCurrentMonth ? 'Total to date' : 'Total'}
          amount={basePay + bonus}
          style={{ background: colors.permaGreyed }}
        />
      </BottomSectionWrapper>
    </PartitionContentWrapper>
  );
};

const BasePayAndBonusPartitionSectionMobile = ({
  month,
  year,
  bonus,
  basePay,
  updatedAt,
  isFetchedForCurrentMonth,
}: BasePayAndBonusPartitionSectionProps) => (
  <>
    <MobileHeadline>
      <View style={{ fontSize: 29, fontWeight: 700 }}>
        {`${moment().month(month).format('MMMM')} ${year} earnings`}
      </View>
      {updatedAt && (
        <LastUpdatedSection style={{ marginTop: 12 }}>
          {getLastUpdatedText(updatedAt)}
        </LastUpdatedSection>
      )}
    </MobileHeadline>
    <MobileEquationWrapper>
      <MobileTopEquationSection>
        <MobileBasePaySection>
          <View>{isFetchedForCurrentMonth ? 'Base pay to date' : 'Base pay'}</View>
          <View>${basePay}</View>
        </MobileBasePaySection>
        <MobileBonusEarnedSection isBonus={!!bonus}>
          <View>Bonus earned</View>
          <View>${bonus}</View>
        </MobileBonusEarnedSection>
      </MobileTopEquationSection>
      <MobileBottomEquationSection>
        <View>{isFetchedForCurrentMonth ? 'Total to date' : 'Total'}</View>
        <View>${bonus + basePay}</View>
      </MobileBottomEquationSection>
    </MobileEquationWrapper>
  </>
);
const BasePayAndBonusPartitionSection = (props: BasePayAndBonusPartitionSectionProps) => {
  const { isMobile } = useWindowWidth();

  return (
    <>
      {isMobile ? (
        <BasePayAndBonusPartitionSectionMobile {...props} />
      ) : (
        <BasePayAndBonusPartitionSectionStandard {...props} />
      )}
    </>
  );
};

export default BasePayAndBonusPartitionSection;
