import { useState, useEffect, ComponentProps, useCallback } from 'react';
import * as React from 'react';
import SelectView from './SelectView';
import { OptionType } from './types';

const capitalize = (word: string): string => (word.charAt(0).toUpperCase() + word.slice(1)).trim();

const isValueInOption = (inputValue = '', option: OptionType): boolean => {
  const candidate = String(inputValue).toLowerCase();
  const optionValue = String(option.value).toLowerCase();
  const optionLabel = String(option.label).toLowerCase();
  return optionValue === candidate || optionLabel === candidate;
};

const isAlphanumeric = (string: string): boolean => /^(?:[a-zA-Z0-9]+\s?)*$/g.test(string);

const Select = (props: ComponentProps<typeof SelectView>) => {
  const {
    onCreateOption,
    isValidNewOption,
    formatCreateLabel,
    formatCreateValue = capitalize,
    validateNewOption = isAlphanumeric,
    options,
    value,
    autoFocus,
    onFocus,
    onBlur,
    onChange,
    isMulti,
    isCreatable,
    placeholderIsTwoLines,
    setDecreaseTopMargin,
    dataQa,
    multiValueStyles,
    dataTestID,
  } = props;

  const [hasFocus, setHasFocus] = useState(autoFocus);

  const handleOnFocus = (e: React.FocusEvent<HTMLElement>) => {
    setHasFocus(true);
    if (onFocus) onFocus(e);
  };

  const handleOnBlur = (e: React.FocusEvent<HTMLElement>) => {
    setHasFocus(false);
    if (onBlur) onBlur(e);
  };

  const handleOnCreateOption = useCallback(
    (option: string) => {
      const formattedOption = formatCreateValue(option);
      const valueObject = {
        value: formattedOption,
        label: formattedOption,
        __isNew__: true,
      };
      if (!isMulti) {
        if (onChange) onChange(valueObject, { action: 'create-option' });
      } else {
        // value is an array
        const newValue = [...(value as OptionType[]), valueObject];
        if (onChange) onChange(newValue, { action: 'create-option' });
      }
    },
    [formatCreateValue, isMulti, onChange, value]
  );

  const defaultIsValidNewOption = useCallback(
    (inputValue: string, selectValue: OptionType[], selectOptions: OptionType[]) => {
      const formattedValue = formatCreateValue(inputValue);
      const passedTest = validateNewOption(inputValue);
      return !(
        !formattedValue ||
        !passedTest ||
        selectValue.some((option) => isValueInOption(formattedValue, option)) ||
        selectOptions.some((option) => isValueInOption(formattedValue, option))
      );
    },
    [formatCreateValue, validateNewOption]
  );

  const formatCreateLabelWithAdd = React.useCallback(
    (newValue) => `Add "${formatCreateValue(newValue)}"`,
    [formatCreateValue]
  );

  const hasValue = Array.isArray(value) ? !!value.length : !!value;

  // Necessary for ensuring the wrapper's focus effect conforms to the dropdown input
  const placeholderUp = hasValue || hasFocus;
  useEffect(() => {
    if (placeholderIsTwoLines && !placeholderUp) {
      setDecreaseTopMargin?.(true);
    }
    return () => {
      setDecreaseTopMargin?.(false);
    };
  }, [placeholderUp, placeholderIsTwoLines, setDecreaseTopMargin]);

  return (
    <SelectView
      dataTestID={dataTestID}
      dataQa={dataQa}
      placeholderUp={placeholderUp}
      {...props}
      value={value}
      options={options || []}
      onBlur={handleOnBlur}
      onFocus={handleOnFocus}
      isCreatable={isCreatable}
      isValidNewOption={isValidNewOption || defaultIsValidNewOption}
      onCreateOption={onCreateOption || handleOnCreateOption}
      formatCreateLabel={formatCreateLabel || formatCreateLabelWithAdd}
      multiValueStyles={multiValueStyles}
    />
  );
};

export default Select;

export type { SelectViewProps as SelectProps } from './SelectView';
