import { FunctionComponent } from 'react';

import useQueryBusinessLine from 'ts-frontend/hooks/useQueryBusinessLine';
import { ExtraHuge, View, Large, Paragraph, Spinner } from '@talkspace/react-toolkit';
import { useTranslation } from '@talkspace/i18n';
import { getUserData } from '@/auth/helpers/token';
import { useParams } from '@/core/routerLib';

import Step3 from '../icons/MessagingInformationStep3';
import Step2 from '../icons/MessagingInformationStep2';
import Step1 from '../icons/MessagingInformationStep1';

export type StepTitle =
  | 'What is a Messaging Session?'
  | 'Go at your own pace'
  | 'Your 7-day session is about to start'
  | 'You’re about to start a Messaging Session'
  | 'Your session is about to start';

interface TextsData {
  title: string | StepTitle;
  subtitle: string | JSX.Element;
  subheader?: string;
}

interface StepData {
  icon: JSX.Element;
  text: TextsData;
}

const getStepDataEAP = (stepId: number, tBookingScreen): StepData => {
  const stepsDataEAP: Record<number, StepData> = {
    1: {
      icon: <Step1 />,
      text: {
        title: tBookingScreen('whatIs.title', 'What is a Messaging Session?', undefined),
        subheader: tBookingScreen('whatIs.days', '7 days = 1 session', undefined),
        subtitle: tBookingScreen(
          'whatIs.messaging',
          'Messaging sessions consist of seven consecutive days of access to your provider.',
          undefined
        ),
      },
    },
    2: {
      icon: <Step2 />,
      text: {
        title: tBookingScreen('go.title', 'Go at your own pace', undefined),
        subtitle: (
          <>
            <Paragraph>
              {tBookingScreen(
                'go.this',
                'This week of back-and-forth messaging with your provider is a chance to explore and reflect on how you are feeling.',
                undefined
              )}
            </Paragraph>
            <Paragraph style={{ marginTop: 20 }}>
              {tBookingScreen(
                'go.the',
                'The more you write, the more your provider can offer thoughtful feedback and treatment.',
                undefined
              )}
            </Paragraph>
          </>
        ),
      },
    },
    3: {
      icon: <Step3 />,
      text: {
        title: tBookingScreen('your.title', 'Your 7-day session is about to start', undefined),
        subtitle: tBookingScreen(
          'your.messaging',
          'Messaging sessions cannot be canceled once started. After 7 days of back-and-forth messaging, your provider will submit this session as complete.',
          undefined
        ),
      },
    },
  };

  return stepsDataEAP[stepId];
};

const getStepDataBH = (stepId: number, tBookingScreen): StepData => {
  const stepsDataBH: Record<number, StepData> = {
    1: {
      icon: <Step1 />,
      text: {
        title: tBookingScreen(
          'youre.title',
          'You’re about to start a Messaging Session',
          undefined
        ),
        subtitle: tBookingScreen(
          'youre.messaging',
          'Messaging sessions usually consist of about a week of back-and-forth messaging with your provider.',
          undefined
        ),
      },
    },
    2: {
      icon: <Step2 />,
      text: {
        title: tBookingScreen('goAt.title', 'Go at your own pace', undefined),
        subtitle: tBookingScreen(
          'goAt.this',
          'This is a chance for you and your provider to explore and reflect on topics at your own pace.',
          undefined
        ),
      },
    },
    3: {
      icon: <Step3 />,
      text: {
        title: tBookingScreen('yourSession.title', 'Your session is about to start', undefined),
        subtitle: tBookingScreen(
          'yourSession.if',
          'If you’re feeling stuck, try answering the following question: How am I feeling right now?',
          undefined
        ),
      },
    },
  };

  return stepsDataBH[stepId];
};

export const getStepData = (step: number, isBH: boolean, tBookingScreen): StepData => {
  const stepsDataFN = isBH ? getStepDataBH : getStepDataEAP;
  return stepsDataFN(step, tBookingScreen);
};

const MessagingInformationStep: FunctionComponent<{ step: number }> = ({ step = 0 }) => {
  const { id: userID } = getUserData();
  const { roomID } = useParams<{ roomID: string }>();
  const { t: tBookingScreen } = useTranslation('bookingScreen');

  const { data: businessLineData, isLoading } = useQueryBusinessLine(roomID, userID);
  if (isLoading) {
    return <Spinner />;
  }
  const { isBH } = businessLineData || { isBH: false };
  const stepData = getStepData(step, isBH, tBookingScreen);
  const { title, subheader, subtitle } = stepData.text;

  return (
    <View style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
      <View style={{ margin: '0 32px' }}>{stepData.icon}</View>
      <View style={{ padding: '0px 42px' }}>
        <ExtraHuge style={{ marginBottom: '20px', textAlign: 'center' }}>{title} </ExtraHuge>
        {subheader ? (
          <Large variant="largeBoldWide" style={{ marginBottom: '20px', textAlign: 'center' }}>
            {subheader}
          </Large>
        ) : (
          <></>
        )}
        <Large style={{ textAlign: 'center' }}>{subtitle}</Large>
      </View>
    </View>
  );
};

export default MessagingInformationStep;
