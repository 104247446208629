import Svg, { Path } from 'svgs';
import { FunctionComponent } from 'react';
import { useEmotionTheme } from '../../core/styled';

interface TicketIconProps {
  width?: number;
  height?: number;
  color?: string;
}

const TicketIcon: FunctionComponent<TicketIconProps> = ({
  width = 17,
  height = 17,
  color,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();

  return (
    <Svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      {...otherProps}
    >
      <Path
        d="M14.079 8.12234L11.4789 6.38725V4.85634C11.4789 4.55245 11.3168 4.27175 11.0537 4.11981C10.7906 3.96788 10.4663 3.96787 10.2032 4.11981C9.94006 4.27176 9.77788 4.55247 9.77788 4.85634V6.38725L7.18264 8.12234C6.92915 8.29075 6.78479 8.58158 6.80394 8.88535C6.82298 9.18912 7.00275 9.45957 7.27527 9.59492C7.54792 9.73026 7.87208 9.70988 8.12555 9.54146L9.77803 8.43821V10.1976L9.1656 10.6058C8.95911 10.7421 8.82218 10.9617 8.79069 11.2071C8.75908 11.4526 8.83609 11.6997 9.00146 11.8838C9.16683 12.0679 9.40421 12.1709 9.65159 12.1659C9.82478 12.1684 9.99455 12.1175 10.1376 12.02L10.6576 11.675L11.1825 12.02C11.3252 12.1184 11.4952 12.1694 11.6685 12.1659C11.916 12.1709 12.1534 12.0679 12.3187 11.8838C12.484 11.6997 12.561 11.4526 12.5296 11.2071C12.4979 10.9617 12.3611 10.7421 12.1545 10.6058L11.5373 10.1976V8.4333L13.1364 9.54146C13.3236 9.67705 13.5581 9.73051 13.7857 9.68962C14.0134 9.64862 14.2145 9.51669 14.3427 9.32436C14.471 9.1319 14.5154 8.89549 14.4656 8.6696C14.416 8.44382 14.2764 8.24794 14.0791 8.12712L14.079 8.12234Z"
        fill={color || colors.accessibilityGreenDark}
      />
      <Path
        d="M14.3369 0.000789653H6.91542C5.97472 0.00518306 5.10394 0.498492 4.61661 1.30325L1.87553 1.76493C1.28363 1.86928 0.757248 2.20427 0.411855 2.69626C0.0665877 3.18833 -0.069476 3.7972 0.0336574 4.38935L1.6423 13.6232C1.73579 14.1485 2.01088 14.6241 2.4196 14.9671C2.8282 15.31 3.34446 15.4985 3.8779 15.4993C4.00666 15.4998 4.13517 15.4884 4.26185 15.4652L4.95688 15.3437C5.46777 15.8684 6.16864 16.1645 6.90092 16.165H14.3368C15.0244 16.1808 15.6904 15.9239 16.1893 15.4505C16.6882 14.977 16.9796 14.3254 17 13.6379V2.52784C16.9796 1.84037 16.6882 1.1888 16.1893 0.715244C15.6904 0.241849 15.0244 -0.0150631 14.3368 0.000683401L14.3369 0.000789653ZM3.97044 13.7887C3.82314 13.813 3.67217 13.7788 3.54963 13.6935C3.4271 13.6082 3.34264 13.4784 3.31433 13.3319L1.70078 4.10735C1.6743 3.95541 1.71128 3.79919 1.80318 3.6752C1.89495 3.5512 2.03359 3.47029 2.18675 3.45125L4.27663 3.0867V13.74L3.97044 13.7887ZM15.3089 13.6381C15.2867 13.8753 15.1726 14.0945 14.9909 14.2489C14.8093 14.4032 14.5747 14.4805 14.3369 14.4643H6.91542C6.7409 14.4685 6.56846 14.4255 6.41625 14.34C6.26394 14.2544 6.13762 14.1295 6.05036 13.9782C5.98848 13.8718 5.95504 13.7513 5.95309 13.6283V2.52797C5.94809 2.47464 5.94809 2.42094 5.95309 2.36761V2.31903C6.02583 2.13328 6.15397 1.97452 6.31995 1.86407C6.48605 1.75375 6.68205 1.69699 6.88135 1.70175H14.3366C14.5743 1.68552 14.809 1.7629 14.9906 1.91716C15.1722 2.07154 15.2863 2.29073 15.3086 2.52799L15.3089 13.6381Z"
        fill={color || colors.accessibilityGreenDark}
      />
    </Svg>
  );
};

export default TicketIcon;
