import { FunctionComponent, MouseEventHandler, TouchEventHandler } from 'react';
import styled, { EmotionStyle } from '../../core/styled';
import commonStyles from '../../constants/commonStyles';
import HiddenText from '../HiddenText';
import { useUniqueID, useKeyboardFocusEffect } from '../../hooks/a11yHelper';

const { crossBrowserFocusRing, roundedFocusRing } = commonStyles;

export interface LinkProps {
  href?: string;
  text?: string | JSX.Element;
  style?: EmotionStyle;
  target?: '_blank' | '_self' | '_parent' | '_top' | 'framename';
  stopPropagation?: boolean;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  onTouchStart?: TouchEventHandler<HTMLAnchorElement> | undefined;
  primaryColor?: string;
  roundedFocusStyle?: boolean;
  tabIndex?: number;
  /**
   * Many links are nested inside of text elements (i.e. emergency resources link). Links that open in new tabs should inform screen readers they will do so by way of a visibly hidden text.
   * If the link is nested, rendering the hidden text as sibling of the link throws errors (shouldn't render <p> inside of <p>)
   */
  isNested?: boolean;
  dataQa?: string;
  ariaDescribedBy?: string;
}

const A = styled.a<{
  primaryColor?: string;
  roundedFocusStyle?: boolean;
  isKeyboardFocus?: boolean;
}>(({ primaryColor, roundedFocusStyle, isKeyboardFocus }) => {
  const focusStyle =
    primaryColor && roundedFocusStyle
      ? {
          ...roundedFocusRing(primaryColor, true),
          borderRadius: 5,
          outline: 'none',
          color: primaryColor,
        }
      : crossBrowserFocusRing;
  return {
    color: primaryColor,
    fontFamily: "'Roboto', sans-serif",
    lineHeight: '1.4285em',
    textAlign: 'left',
    display: 'inline',
    marginLeft: 4,
    marginRight: 4,
    letterSpacing: 0.2,
    textDecoration: 'none',
    cursor: 'pointer',
    '&:focus': isKeyboardFocus ? focusStyle : {},
  };
});

const Link: FunctionComponent<LinkProps> = ({
  href,
  text,
  target,
  children,
  stopPropagation = false,
  isNested,
  primaryColor,
  roundedFocusStyle,
  dataQa,
  tabIndex,
  ariaDescribedBy,
  ...otherProps
}) => {
  const rel = target === '_blank' ? 'noopener noreferrer' : '';
  const newTabInfoID = useUniqueID('newTabInfoID');
  const { handleFocus, handleBlur, handleMouseDown, isFocused, isClicked } =
    useKeyboardFocusEffect();

  let finalAriaDescribedBy = ariaDescribedBy;
  if (target === '_blank' && !isNested) {
    finalAriaDescribedBy = ariaDescribedBy ? `${ariaDescribedBy} ${newTabInfoID}` : newTabInfoID;
  }
  return (
    <>
      <A
        tabIndex={tabIndex}
        data-qa={dataQa}
        aria-describedby={finalAriaDescribedBy}
        href={href}
        target={target}
        rel={rel}
        onTouchStart={(e) => (stopPropagation ? e.stopPropagation() : e)}
        onClick={(e) => (stopPropagation ? e.stopPropagation() : e)}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onMouseDown={handleMouseDown}
        isKeyboardFocus={isFocused && !isClicked}
        primaryColor={primaryColor}
        roundedFocusStyle={roundedFocusStyle}
        {...otherProps}
      >
        {children || text}
      </A>
      {target === '_blank' && !isNested && (
        <HiddenText inline id={newTabInfoID}>
          opens in new tab
        </HiddenText>
      )}
    </>
  );
};

export default Link;
