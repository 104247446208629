import Svg, { Path } from 'svgs';

interface Props {
  width?: string | number;
  height?: string | number;
  color?: string;
}
const ButtonsSeparatorIcon = ({ width = 2, height = 18, color = '#C8D2DE' }: Props) => (
  <Svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <Path d="M1 1.01318V16.9866" stroke={color} stroke-linecap="square" />
  </Svg>
);

export default ButtonsSeparatorIcon;
