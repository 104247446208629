import { FunctionComponent } from 'react';

import { VideoCreditOffer, TherapistTimeslot, FlowVariant } from 'ts-frontend/types';
import { ERoom } from 'ts-frontend/entities/Room';
import {
  Button,
  Large,
  StickyDrawer,
  View,
  useWindowWidthState,
  ExtraHuge,
  useEmotionTheme,
} from '@talkspace/react-toolkit';

import { useTranslation } from '@talkspace/i18n';
import styled from '@/core/styled';
import appConfigs from '@/utils/configs';
import ReactFrameService from '@/auth/reactFrame/ReactFrameService';
import AddToCalendar from '../AddToCalendar';
import BookingAvatars from '../BookingAvatars';
import { TherapistInfo } from '../../types';
import { getText } from './bookingConfirmationUtils';

const Container = styled(View)({ padding: '32px 20px', alignItems: 'center' });

interface Props {
  isTherapist: boolean;
  handleOnCTAPress: () => void;
  isWithin24Hours: boolean;
  hasBreakAfterSession?: boolean;
  therapist?: TherapistInfo;
  room: ERoom | undefined;
  modality: 'messaging' | 'video' | 'audio' | 'chat';
  selectedCreditOption: VideoCreditOffer | undefined;
  selectedTimeslot: TherapistTimeslot | null | undefined;
  isInTherapistCRM?: boolean;
  hideAddToCalendar?: boolean;
  flowVariant?: FlowVariant;
}

const BookingConfirmationSuccess: FunctionComponent<Props> = ({
  isTherapist,
  handleOnCTAPress,
  isWithin24Hours,
  hasBreakAfterSession,
  therapist,
  room,
  modality,
  selectedCreditOption,
  selectedTimeslot,
  isInTherapistCRM,
  hideAddToCalendar,
  flowVariant,
}) => {
  const { t: tBookingScreen } = useTranslation('bookingScreen');
  const isiOS = ReactFrameService.isiOS();
  const isAndroid = ReactFrameService.isAndroid();
  const { isMobile } = useWindowWidthState();
  const { colorRoles } = useEmotionTheme();
  const isMessaging = modality === 'messaging';
  const showAddToCalendar =
    !isMessaging &&
    appConfigs.featureFlags.addToCalendar &&
    !isTherapist &&
    !isiOS &&
    !isAndroid &&
    !hideAddToCalendar;
  const title = isMessaging
    ? tBookingScreen('booked.message', 'Your messaging session has begun!', undefined)
    : tBookingScreen('booked.youreBooked', 'You’re booked!', undefined);
  const contentWidth = isMobile || isInTherapistCRM ? 335 : 430;
  const isSwitchWizard = flowVariant === 'switchWizard';
  return (
    <Container>
      {therapist && (
        <BookingAvatars
          therapistUserID={therapist.id}
          selectedTimeslot={selectedTimeslot}
          modality={modality}
          shouldShowBookingSuccess
          isCouples={room?.roomType === 'couples_room'}
        />
      )}
      <ExtraHuge
        style={{
          width: contentWidth,
          marginTop: 24 + 12,
          textAlign: 'center',
        }}
      >
        {title}
      </ExtraHuge>
      <Large
        style={{
          width: contentWidth,
          marginTop: 12,
          marginBottom: hasBreakAfterSession ? 0 : 32,
          textAlign: 'center',
          color: colorRoles.typography.textSubtle,
        }}
        variant="largeDarkGrey"
      >
        {getText({ isTherapist, isWithin24Hours, modality, plural: false, tBookingScreen })}
      </Large>
      {hasBreakAfterSession && (
        <Large
          style={{
            width: contentWidth,
            marginTop: 19,
            marginBottom: 25,
            textAlign: 'center',
          }}
          variant="largeDarkGrey"
        >
          {tBookingScreen(
            'booked.followed',
            'The session will be followed by a 15 minute break.',
            undefined
          )}
        </Large>
      )}
      {showAddToCalendar ? (
        <View flex={1} align="center" style={{ marginBottom: 30 }}>
          <AddToCalendar
            selectedBooking={
              selectedTimeslot?.start && selectedCreditOption?.creditMinutes
                ? {
                    startTime: selectedTimeslot?.start,
                    creditMinutes: selectedCreditOption?.creditMinutes,
                  }
                : undefined
            }
            videoCreditType={selectedCreditOption?.type}
            therapistName={`${therapist?.firstName} ${therapist?.lastName}`}
            roomID={room?.roomID}
          />
        </View>
      ) : null}
      <StickyDrawer autoPositioning={isSwitchWizard}>
        <Button
          style={{ backgroundColor: colorRoles.system.actionPrimaryDefault }}
          onPress={handleOnCTAPress}
          data-qa="bookingConfirmationSuccessButton"
        >
          {isMessaging
            ? tBookingScreen('booked.go', 'Go to session room', undefined)
            : tBookingScreen('booked.done', 'Done', undefined)}
        </Button>
      </StickyDrawer>
    </Container>
  );
};

export default BookingConfirmationSuccess;
