import { FunctionComponent } from 'react';

import styled, { EmotionStyle, useEmotionTheme } from '../../core/styled';
import commonStyles from '../../constants/commonStyles';

import View from '../View';
import Text from '../Text';
import Small from '../Typography/Small';
import ArrowUpV2 from '../Svgs/ArrowUpV2';
import Collapsible from '../Collapsible';
import ArrowDownV2 from '../Svgs/ArrowDownV2';
import CircledCheckMark from '../Svgs/CircledCheckMark';
import TouchableView from '../TouchableView';
import { useUniqueID } from '../../hooks/a11yHelper';

export interface AccordionContainerViewProps {
  open?: boolean;
  title?: string;
  disabled?: boolean;
  className?: string;
  hideArrow?: boolean;
  style?: EmotionStyle;
  bodyStyle?: EmotionStyle;
  footerStyles?: EmotionStyle;
  titleContainerStyles?: EmotionStyle;
  arrowStyles?: EmotionStyle;
  arrowContainerStyles?: EmotionStyle;
  showCheckMark?: boolean;
  arrowText?: (x: boolean) => string;
  titleStyle?: EmotionStyle;
  dataQa?: string;

  onPress: () => void;
  footerContent?: () => JSX.Element;
  titleComponent?: () => JSX.Element;
}

const AccordionContainer = styled(View)(({ theme: { colors } }) => {
  return {
    width: 346,
    borderWidth: 1,
    borderRadius: 5,
    marginBottom: 15,
    borderStyle: 'solid',
    borderColor: colors.darkBlue150,
  };
});
const StyledText = styled(Text)({ ...commonStyles.general.text.accordionHeader });

const ClickableArea = styled(View)({
  paddingTop: 12,
  paddingLeft: 15,
  paddingRight: 10,
  paddingBottom: 9,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
});
const Body = styled(View)({
  flex: 1,
  marginTop: 0,
  marginLeft: 0,
  paddingTop: 14,
  marginRight: 0,
  paddingLeft: 12,
  marginBottom: 0,
  paddingRight: 15,
  paddingBottom: 19,
  alignItems: 'stretch',
  flexDirection: 'column',
});
const Footer = styled(View)({
  paddingLeft: 20,
  paddingRight: 20,
  paddingBottom: 17,
  alignItems: 'stretch',
});

const ArrowContainer = styled(View)(() => {
  return {
    marginTop: 0,
    alignSelf: 'flex-start',
  };
});

const Arrow = ({ open, hide = false, arrowStyles, arrowContainerStyles, arrowText }) => {
  const width = (arrowStyles && arrowStyles.width) || 15;
  const height = (arrowStyles && arrowStyles.height) || 9;
  const fill = arrowStyles && arrowStyles.fill;
  return hide ? null : (
    <ArrowContainer aria-hidden="true" style={arrowContainerStyles}>
      <View row>
        {arrowText && (
          <Small style={{ marginTop: 2, marginRight: 5 }} variant="smallBolderDarkGreen">
            {arrowText}
          </Small>
        )}
        <View style={{ marginTop: 8 }}>
          {open ? (
            <ArrowUpV2 width={width} height={height} fill={fill} />
          ) : (
            <ArrowDownV2 width={width} height={height} color={fill} />
          )}
        </View>
      </View>
    </ArrowContainer>
  );
};

const StyledCheckMark = styled(CircledCheckMark)({
  margin: '3px 7px',
});

const Title = ({ title, showCheckMark, style }) => {
  const { colors } = useEmotionTheme();
  return (
    <View row>
      <StyledText style={style}>{title}</StyledText>
      {showCheckMark ? <StyledCheckMark circleFillColor={colors.a11yChaosEmerald} /> : null}
    </View>
  );
};

const AccordionContainerView: FunctionComponent<AccordionContainerViewProps> = ({
  open,
  style,
  title,
  onPress,
  children,
  disabled,
  className,
  bodyStyle,
  hideArrow,
  footerStyles,
  footerContent,
  titleComponent,
  titleContainerStyles,
  arrowStyles,
  arrowContainerStyles,
  showCheckMark,
  arrowText,
  titleStyle,
  dataQa,
}) => {
  const collapsibleID = useUniqueID('collapsibleID');
  const renderTitle = () => {
    if (titleComponent) return titleComponent();
    return <Title showCheckMark={showCheckMark} title={title} style={titleStyle} />;
  };
  return (
    <AccordionContainer style={style} className={className}>
      <TouchableView
        activeOpacity={1}
        disabled={disabled}
        onPress={onPress}
        aria-expanded={open}
        aria-controls={collapsibleID}
        dataQa={dataQa}
      >
        <ClickableArea style={{ paddingRight: 15, ...titleContainerStyles }}>
          {renderTitle()}
          <Arrow
            hide={hideArrow}
            open={open}
            arrowStyles={arrowStyles}
            arrowContainerStyles={arrowContainerStyles}
            arrowText={arrowText && arrowText(!!open)}
          />
        </ClickableArea>
      </TouchableView>
      <Collapsible id={collapsibleID} isCollapsed={!open}>
        {children && <Body style={bodyStyle}>{children}</Body>}
      </Collapsible>
      {footerContent && <Footer style={footerStyles}>{footerContent()}</Footer>}
    </AccordionContainer>
  );
};

export default AccordionContainerView;
