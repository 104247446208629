import Svg, { G, Circle, Path } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

const VideoCallCamera = ({
  width = 75,
  height = 75,
  isOn = true,
  isBackgroundDark = false,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'video camera';
  if (!isOn)
    return isBackgroundDark ? (
      <Svg
        width={width}
        height={height}
        title={titleText}
        aria-label={titleText}
        viewBox="0 0 75 75"
        {...otherProps}
      >
        <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
          <Circle
            stroke="#F1F6FF"
            strokeWidth={2}
            fillOpacity={0.2}
            fill="#000516"
            cx={37.5}
            cy={37.5}
            r={36.5}
          />
          <Path
            d="M28.02 28l12.018 20H25.006A4.004 4.004 0 0121 44V32c0-2.21 1.786-4 4.006-4h3.014zm11.974 0A4.004 4.004 0 0144 32v12c0 .716-.187 1.387-.516 1.968L32.687 28h7.307zM54 33.01v9.98c0 1.663-1.146 2.294-2.542 1.421l-4.602-2.876C46.383 41.24 46 40.55 46 40.01v-4.018c0-.547.386-1.232.856-1.526l4.602-2.876c1.404-.878 2.542-.252 2.542 1.42z"
            fill="#F1F6FF"
          />
          <Path
            d="M45.082 54.452c.288.48.903.634 1.377.35.476-.287.624-.902.337-1.38L27.234 20.865a1.003 1.003 0 00-1.377-.35 1.002 1.002 0 00-.337 1.38l19.562 32.557z"
            fill={colors.heartRed}
          />
        </G>
      </Svg>
    ) : (
      <Svg
        width={width}
        height={height}
        title={titleText}
        aria-label={titleText}
        viewBox="0 0 75 75"
        {...otherProps}
      >
        <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
          <Circle stroke="#E6ECF7" strokeWidth={2} cx={37.5} cy={37.5} r={36.5} />
          <Path
            d="M28.02 28l12.018 20H25.006A4.004 4.004 0 0121 44V32c0-2.21 1.786-4 4.006-4h3.014zm11.974 0A4.004 4.004 0 0144 32v12c0 .716-.187 1.387-.516 1.968L32.687 28h7.307zM54 33.01v9.98c0 1.663-1.146 2.294-2.542 1.421l-4.602-2.876C46.383 41.24 46 40.55 46 40.01v-4.018c0-.547.386-1.232.856-1.526l4.602-2.876c1.404-.878 2.542-.252 2.542 1.42z"
            fill="#41506A"
          />
          <Path
            d="M45.082 54.452c.288.48.903.634 1.377.35.476-.287.624-.902.337-1.38L27.234 20.865a1.003 1.003 0 00-1.377-.35 1.002 1.002 0 00-.337 1.38l19.562 32.557z"
            fill={colors.heartRed}
          />
        </G>
      </Svg>
    );

  return isBackgroundDark ? (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 75 75"
      {...otherProps}
    >
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Circle fillOpacity={0.2} fill="#000516" cx={37} cy={37} r={30} />
        <Path
          d="M37.5 0C58.21 0 75 16.79 75 37.5S58.21 75 37.5 75 0 58.21 0 37.5 16.79 0 37.5 0zm2.494 28H25.006A3.998 3.998 0 0021 32v12c0 2.208 1.794 4 4.006 4h14.988C42.214 48 44 46.21 44 44V32c0-2.208-1.794-4-4.006-4zM54 33.01c0-1.673-1.138-2.299-2.542-1.421l-4.602 2.876c-.47.294-.856.979-.856 1.526v4.018c0 .54.383 1.23.856 1.526l4.602 2.876c1.396.873 2.542.242 2.542-1.42z"
          fill="#F1F6FF"
        />
      </G>
    </Svg>
  ) : (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 75 75"
      {...otherProps}
    >
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Path
          d="M37.5 75C58.21 75 75 58.21 75 37.5S58.21 0 37.5 0 0 16.79 0 37.5 16.79 75 37.5 75z"
          fill="#F1F6FF"
        />
        <Path
          d="M39.994 28A4.004 4.004 0 0144 32v12c0 2.21-1.786 4-4.006 4H25.006A4.004 4.004 0 0121 44V32c0-2.21 1.786-4 4.006-4h14.988zM54 33.01v9.98c0 1.663-1.146 2.294-2.542 1.421l-4.602-2.876C46.383 41.24 46 40.55 46 40.01v-4.018c0-.547.386-1.232.856-1.526l4.602-2.876c1.404-.878 2.542-.252 2.542 1.42z"
          fill="#41506A"
        />
      </G>
    </Svg>
  );
};
export default VideoCallCamera;
