import { FunctionComponent, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Styles } from 'react-select';
import {
  AccordionContainer,
  Small,
  OptionType,
  Select,
  Checkbox,
  TextAreaRounded,
  useEmotionTheme,
  styled,
  View,
  Standard,
  TouchableView,
} from '@talkspace/react-toolkit';
import useQueryProgressNote from 'hooks/notes/useQueryProgressNote';
import useQueryReferralConditions from 'hooks/useQueryReferralConditions';
import useMutationSaveDraftProgressNote from 'hooks/notes/useMutationSaveDraftProgressNote';
import useMutationUpdateProgressNote from 'hooks/notes/useMutationUpdateProgressNote';
import * as outcomeMeasureActions from 'actions/OutcomeMeasureActions';
import ListItem from 'components/Reusable/ListItems/ListItem';
import ItemList from 'components/Reusable/ItemList/ItemList';
import useQueryRoomDetails from 'ts-frontend/hooks/useQueryRoomDetails';
import { useParams, useHistory, useLocation } from '@/core/routerLib';
import {
  generateReferralOptions,
  getTextAreaContainerBorderStyle,
  getTextAreaStyle,
  OTHER_REFERRAL_ID,
  SESSION_SUMMARY_CHARS_LIMIT,
} from '../../utils';
import {
  useProgressNoteFormActions,
  useProgressNoteFormState,
} from '../context/PsychProgressNoteFormContext';
import StatementCertification from '../../StatementCertification';
import { ProgressNoteFormState } from '../context/types';
import ConfirmButton from './ConfirmButton';
import { SaveProgressNoteDialogLocationState } from '../../SaveProgressNoteDialog';
import IndentedView from '../../IndentedView';

const Label = styled(Small)({
  marginBottom: 9,
});

const substanceUseSelectStyle: Styles<OptionType, boolean> = {
  container: (provided) => {
    return { ...provided, marginTop: 15, marginBottom: 43 };
  },
};

const outcomeMeasuresSelectStyle: Styles<OptionType, boolean> = {
  container: (provided) => {
    return { ...provided, marginTop: 15, marginBottom: 6 };
  },
};

interface StoreProps {
  getOutcomeMeasures: () => void;
  outcomeMeasures: OutcomeMeasure[];
}

interface OutcomeMeasure {
  id: number;
  completedAt: Date;
  label: string;
  scales: Scale[];
}

interface Scale {
  id: number;
  label: string;
  score: number;
  maximum: number;
  scoreLabel: string;
}

const ReadAssessmentText = styled(TouchableView)({
  textAlign: 'right',
  marginTop: 20,
});

const SessionSummarySection: FunctionComponent<StoreProps> = ({
  getOutcomeMeasures,
  outcomeMeasures,
}) => {
  const history = useHistory();
  const location = useLocation<{ outcomeMeasure: { id: number } }>();

  const { roomID, noteID } = useParams<{ roomID: string; noteID?: string }>();
  const { colors } = useEmotionTheme();
  const [completedOutcomeMeasuresOptions, setCompletedOutcomeMeasuresOptions] =
    useState<OptionType<number | null>[]>();

  const [selectedOutcomeMeasureScales, setSelectedOutcomeMeasureScales] = useState<
    JSX.Element[] | null
  >();
  const formState = useProgressNoteFormState();

  const {
    sessionSummary: globalSessionSummary,
    referralID,
    statementCertified,
    formSections,
    otherReferral,
  } = formState;

  const [sessionSummary, setSessionSummary] =
    useState<ProgressNoteFormState['sessionSummary']>(globalSessionSummary);
  const { setFormState } = useProgressNoteFormActions();
  const { data: { clientUserID } = {} } = useQueryRoomDetails(roomID);
  const { data: progressNoteData } = useQueryProgressNote(clientUserID, noteID);
  const { data: referralConditionsData } = useQueryReferralConditions();
  const { mutate: saveDraftProgressNote } = useMutationSaveDraftProgressNote();
  const { mutate: updateProgressNote } = useMutationUpdateProgressNote();

  const [summaryError, setSummaryError] = useState(false);
  const [statementCertifiedError, setStatementCertifiedError] = useState(false);
  const [otherReferralError, setOtherReferralError] = useState(false);

  const userRoomSurveyID: number | undefined = location?.state?.outcomeMeasure?.id;

  useEffect(() => {
    setSessionSummary({
      ...globalSessionSummary,
      ...(userRoomSurveyID && {
        userRoomSurveyID,
      }),
    });
  }, [globalSessionSummary, userRoomSurveyID]);

  useEffect(() => {
    if (getOutcomeMeasures) {
      getOutcomeMeasures();
    }
  }, [getOutcomeMeasures]);

  useEffect(() => {
    const outcomeMeasureOptionsWithDefault: OptionType<number | null>[] = outcomeMeasures
      .filter((outcomeMeasure) => outcomeMeasure.completedAt)
      .map((outcomeMeasure) => {
        return {
          label: `${moment(outcomeMeasure.completedAt).format('MMM DD, YYYY')} ${
            outcomeMeasure.label
          }`,
          value: outcomeMeasure.id,
        };
      });
    outcomeMeasureOptionsWithDefault.push({
      value: null,
      label: 'None applicable to this session',
    });

    setCompletedOutcomeMeasuresOptions(outcomeMeasureOptionsWithDefault);
  }, [outcomeMeasures]);

  const processItems = (scales: Scale[]) =>
    scales.map((item) => {
      const { label: scale, score, maximum, scoreLabel: label } = item;
      const itemDisplayData = {
        scale,
        score: `${score}/${maximum}`,
        label,
      };
      return (
        <ListItem
          itemStyle={{ border: 'none', height: 20 }}
          key={item.id}
          itemDataOrder={['scale', 'score', 'label']}
          itemDisplayData={itemDisplayData}
          item={item}
          status={label}
          widths={[140, 70, 90]}
        />
      );
    });

  const updateSelectedOutcomeMeasuresScales = useCallback(
    (option: OptionType<number | null> | null) => {
      const selectedOutcomeMeasureToPresent = outcomeMeasures.find(
        (outcomeMeasure) => option?.value === outcomeMeasure.id
      );
      if (selectedOutcomeMeasureToPresent) {
        const scales = processItems(selectedOutcomeMeasureToPresent.scales);
        setSelectedOutcomeMeasureScales(scales);
      } else {
        setSelectedOutcomeMeasureScales(null);
      }
    },
    [outcomeMeasures]
  );

  const markSectionAsIncomplete = () => {
    if (formSections.sessionSummary.completed) {
      setFormState({
        formSections: {
          ...formSections,
          sessionSummary: {
            open: formSections.sessionSummary.open,
            changed: true,
            completed: false,
          },
        },
      });
    }
  };

  const handleOutcomeMeasureIDChange = (value: number | null) => {
    markSectionAsIncomplete();
    setSessionSummary({ ...sessionSummary, userRoomSurveyID: value });
  };

  const handleOutcomeMeasureChange = (option: OptionType<number | null> | null) => {
    handleOutcomeMeasureIDChange(option ? option.value : null);
    updateSelectedOutcomeMeasuresScales(option);
  };

  useEffect(() => {
    if (completedOutcomeMeasuresOptions && sessionSummary.userRoomSurveyID) {
      const option = completedOutcomeMeasuresOptions.find(
        (it: OptionType<number | null>) => it.value === sessionSummary.userRoomSurveyID
      );
      if (option) {
        updateSelectedOutcomeMeasuresScales(option);
      }
    }
  }, [
    completedOutcomeMeasuresOptions,
    sessionSummary.userRoomSurveyID,
    outcomeMeasures,
    updateSelectedOutcomeMeasuresScales,
  ]);

  const handleSummaryChange = (value: string) => {
    markSectionAsIncomplete();
    setSummaryError(false);
    setSessionSummary({ ...sessionSummary, summary: value });
  };

  const navigateToOutcomeMeasureAnswers = () => {
    history.push(`/room/${roomID}/outcome-measure-answers`, {
      outcomeMeasure: { id: sessionSummary.userRoomSurveyID },
      prevRoute: location.pathname,
    });
  };

  const handleReadAssessmentAnswersPress = () => {
    const { showGlobalError, formMode, ...restFormState } = formState;
    const params = { roomID, noteID, clientUserID, ...restFormState };

    if (formMode === 'create') {
      const saveProgressNoteDialogLocationState: SaveProgressNoteDialogLocationState = {
        action: {
          type: 'navigate-to-outcome-measure-answers',
          payload: sessionSummary.userRoomSurveyID,
        },
      };

      history.push(
        `/room/${roomID}/progress-notes/new/save-draft-dialog`,
        saveProgressNoteDialogLocationState
      );

      return;
    }

    if (formMode === 'edit') {
      if (progressNoteData?.status === 'draft') {
        saveDraftProgressNote(params, {
          onSuccess: () => {
            navigateToOutcomeMeasureAnswers();
          },
        });

        return;
      }

      if (noteID && progressNoteData?.status === 'submitted') {
        updateProgressNote(
          { ...params, noteID, clientUserID },
          {
            onSuccess: () => {
              navigateToOutcomeMeasureAnswers();
            },
          }
        );

        return;
      }
    }

    if (formMode === 'view') {
      navigateToOutcomeMeasureAnswers();
    }
  };

  const handleFollowupPlanChange = (value: string) => {
    markSectionAsIncomplete();
    setSessionSummary({ ...sessionSummary, followupPlan: value });
  };

  const handleRecommendationsChange = (value: string) => {
    markSectionAsIncomplete();
    setSessionSummary({ ...sessionSummary, recommendations: value });
  };

  const handleAccordionPress = (value: boolean) => {
    setFormState({
      formSections: {
        ...formSections,
        sessionSummary: {
          open: value,
          changed: formSections.sessionSummary.changed,
          completed: formSections.sessionSummary.completed,
        },
      },
    });
  };

  const handleReferralChange = (option: OptionType<number> | null) => {
    markSectionAsIncomplete();
    setFormState({ referralID: option ? option.value : null });
  };

  const handleOtherReferralChange = (value: string) => {
    markSectionAsIncomplete();
    setOtherReferralError(false);
    setFormState({ otherReferral: value });
  };

  const handleStatementCertifiedCheck = (value: boolean) => {
    markSectionAsIncomplete();
    setStatementCertifiedError(false);
    setFormState({ statementCertified: value });
  };

  const handleConfirmPress = () => {
    let error = false;

    if (!sessionSummary.summary) {
      setSummaryError(true);
      error = true;
    }
    if (!statementCertified) {
      setStatementCertifiedError(true);
      error = true;
    }
    if (error) {
      setFormState({ showGlobalError: true });
      return;
    }

    setFormState({
      sessionSummary,
      formSections: {
        ...formSections,
        sessionSummary: {
          open: false,
          changed: true,
          completed: true,
        },
      },
    });
  };

  const referralOptions = referralConditionsData
    ? generateReferralOptions(referralConditionsData)
    : [];

  const { formMode } = formState;

  const textAreaStyle = getTextAreaStyle(formMode === 'view');

  return (
    <AccordionContainer
      title="Session summary"
      open={formSections.sessionSummary.open}
      onPress={handleAccordionPress}
      showCheckMark={formMode !== 'view' && formSections.sessionSummary.completed}
      dataQa="sessionSummaryAccordion"
    >
      <Label>Summary (response to intervention)</Label>
      <TextAreaRounded
        autoSize
        style={getTextAreaContainerBorderStyle(colors, summaryError)}
        textAreaStyle={textAreaStyle}
        value={sessionSummary.summary || ''}
        onChangeText={handleSummaryChange}
        placeholder="Statement of relevant client behavior/status, measurable data, assessment, client response to interventions, etc"
        disabled={formMode === 'view'}
        hasLimit
        limit={SESSION_SUMMARY_CHARS_LIMIT}
        dataQa="sessionSummaryTextArea"
      />
      <View style={{ marginBottom: 20 }}>
        <Select
          placeholder="Outcome measures"
          value={
            completedOutcomeMeasuresOptions?.find(
              (it: any) => it.value === (sessionSummary ? sessionSummary.userRoomSurveyID : null)
            ) as any
          }
          onChange={((option: OptionType<number>) => handleOutcomeMeasureChange(option)) as any}
          options={completedOutcomeMeasuresOptions as any}
          styles={outcomeMeasuresSelectStyle}
          isDisabled={formMode === 'view'}
          dataQa="outcomeMeasuresDropdown"
        />
      </View>
      {selectedOutcomeMeasureScales?.length && (
        <View style={{ marginBottom: 20 }}>
          <ItemList
            cssClass={null}
            items={selectedOutcomeMeasureScales}
            titles={['Scale', 'Score', 'Label']}
            noDataMessage="No scale information for this survey."
            widths={[140, 70, 90]}
            key={1}
          />
          <ReadAssessmentText
            onPress={handleReadAssessmentAnswersPress}
            dataQa="readAssessmentAnswersButton"
          >
            <Standard variant="standardBoldGreen">Read Assessment Answers</Standard>
          </ReadAssessmentText>
        </View>
      )}
      <View style={{ marginBottom: 10 }}>
        <Label>Follow up plan (optional)</Label>
        <TextAreaRounded
          autoSize
          style={getTextAreaContainerBorderStyle(colors)}
          textAreaStyle={textAreaStyle}
          value={sessionSummary.followupPlan || ''}
          onChangeText={handleFollowupPlanChange}
          placeholder="Next session plan, frequency or duration of treatment, etc."
          disabled={formMode === 'view'}
          hasLimit
          dataQa="followupPlanTextArea"
        />
      </View>
      <Label>Recommendations (optional)</Label>
      <TextAreaRounded
        autoSize
        style={getTextAreaContainerBorderStyle(colors)}
        textAreaStyle={textAreaStyle}
        value={sessionSummary.recommendations || ''}
        onChangeText={handleRecommendationsChange}
        placeholder="Including but not limited to assigned homework, resources, etc."
        disabled={formMode === 'view'}
        hasLimit
        dataQa="recommendationsTextArea"
      />
      <Select
        value={referralOptions.find((it) => it.value === referralID) as any}
        onChange={handleReferralChange as any}
        options={referralOptions as any}
        styles={substanceUseSelectStyle}
        placeholder="Referral"
        isDisabled={formMode === 'view'}
        dataQa="referralDropdown"
      />
      {referralID === OTHER_REFERRAL_ID && (
        <IndentedView style={{ marginBottom: 30 }}>
          <TextAreaRounded
            autoSize
            disabled={formMode === 'view'}
            style={{
              ...getTextAreaContainerBorderStyle(colors, otherReferralError),
              marginBottom: 10,
            }}
            placeholder="Provide information about the referral"
            textAreaStyle={getTextAreaStyle(formMode === 'view')}
            value={otherReferral || ''}
            onChangeText={handleOtherReferralChange}
            hasLimit
            dataQa="referralInfoTextArea"
          />
        </IndentedView>
      )}
      <Checkbox
        label="I certify the above statement to be accurate as of the digital date signed."
        isChecked={statementCertified}
        setIsChecked={handleStatementCertifiedCheck}
        alignCenter={false}
        shouldDisplayError={statementCertifiedError}
        labelStyle={{ color: colors.black, fontWeight: 400 }}
        isLabelOnRight
        isDisabled={formMode === 'view'}
        dataQa="certifyStatementCheckbox"
      />
      {progressNoteData && formMode === 'view' && (
        <StatementCertification
          user={progressNoteData.createdByUser}
          submittedAt={new Date(progressNoteData.submittedAt)}
        />
      )}
      {formMode !== 'view' && !formSections.sessionSummary.completed && (
        <ConfirmButton onPress={handleConfirmPress} sectionTitle="sessionSummary" />
      )}
    </AccordionContainer>
  );
};

const mapStateToProps = (state: any) => {
  return {
    outcomeMeasures: state.outcomeMeasures.outcomeMeasures,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getOutcomeMeasures: () => dispatch(outcomeMeasureActions.getOutcomeMeasures()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionSummarySection);
