import { RoomQueryString } from 'utils/getRoomsQueryString';

const dashboardKeys = {
  all: ['dashboard'],
  tasklists: (): string[] => [...dashboardKeys.all, 'tasklist'],
  tasklist: (therapistID: number): string[] => [
    ...dashboardKeys.tasklists(),
    therapistID.toString(),
  ],
  tasklistsV3: (): string[] => [...dashboardKeys.all, 'tasklistsV3'],
  tasklistV3: (therapistID: number): string[] => [
    ...dashboardKeys.tasklists(),
    therapistID.toString(),
    'v3',
  ],
  metric: (therapistID: number): string[] => [
    ...dashboardKeys.all,
    'metric',
    therapistID.toString(),
  ],
  clientUnavailabilityPeriodQueryKey: (therapistID: number): string[] => [
    ...dashboardKeys.all,
    'clientUnavailabilityPeriod',
    therapistID.toString(),
  ],
  rooms: (): string[] => [...dashboardKeys.all, 'rooms'],
  roomFilter: (roomID: number, params: RoomQueryString) => [
    ...dashboardKeys.all,
    'room',
    roomID.toString(),
    params,
  ],
  roomsFilter: (params: RoomQueryString) => [...dashboardKeys.rooms(), params],
  accountInformationStatus: (therapistID: number) => [
    ...dashboardKeys.all,
    'accountInfoStatus',
    therapistID.toString(),
  ],
  bookings: (
    therapistID: number,
    params: { start?: string; end?: string } = { start: '', end: '' }
  ) => [...dashboardKeys.all, 'bookings', therapistID.toString(), params],
};

export default dashboardKeys;
