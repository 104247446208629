import { useQuery } from 'react-query';
import moment from 'moment';
import apiHelper from '../../modules/utils/api';
import apiWrapper from '../../utils/apiWrapper';
import availabilityKeys from './queryKeys';

export const daysOfTheWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
] as const;
export type DayOfTheWeek = typeof daysOfTheWeek[number];

export interface Availability {
  start: Date;
  end: Date;
  calendarEntityType: string;
  isRecurring: boolean;
}

export interface AvailabilityAPIResponse {
  availabilities: Array<Availability>;
}

const fetchCalendarAvailability =
  (therapistID: number, fromDate: string, toDate: string) => async () => {
    const timezone = moment.tz.guess() || 'America/New_York';
    const { data } = await apiWrapper.get(
      `${
        apiHelper().apiEndpoint
      }/v4/therapist/${therapistID}/availability?fromDate=${fromDate}&toDate=${toDate}&timezone=${timezone}`
    );

    return data.data;
  };

const useQueryCalendarAvailabilityV3 = (therapistID: number, fromDate: string, toDate: string) => {
  const params = {
    start: fromDate,
    end: toDate,
  };
  return useQuery<AvailabilityAPIResponse, Error>({
    queryKey: availabilityKeys.therapistAvailabilityV3(therapistID, params),
    queryFn: fetchCalendarAvailability(therapistID, fromDate, toDate),
    cacheTime: 0,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    retry: true,
    staleTime: 0,
  });
};

export default useQueryCalendarAvailabilityV3;
