import Svg, { G, Circle, Path } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

const EndCall = ({ width = 75, height = 75, ...otherProps }) => {
  const { colors } = useEmotionTheme();
  const titleText = 'end call';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 75 75"
      {...otherProps}
    >
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Circle cx={37.5} cy={37.5} r={37.5} fill={colors.heartRed} />
        <Path
          d="M13.163 41.044c-.361-3.716.603-4.68 2.347-6.424 1.702-1.702 5.523-3.164 10.202-3.918 4.486-.713 6.51-1.003 11.806-.923 5.597.08 9.7.71 15.124 2.312 5.735 1.693 7.135 3.546 8.063 5.296.498.934.61 2.354.278 3.799-.017.067-.025.143-.05.218-.158.731-.416 1.95-1.221 2.754-.67.67-1.603.93-2.872.8l-.185-.017c-4.767-.49-8.76-1.146-9.22-4.122-.316-2.093-.664-3.38-1.582-4.147-.884-.75-2.044-.82-3.792-.924l-.126-.01c-2.883-.182-3.673-.2-4.757-.212-.185 0-.378-.009-.597-.01l-.31-.008c-1.27-.029-1.968-.039-4.934.148l-.176.008c-1.26.081-2.185.146-2.906.867-.746.747-1.096 2.04-1.654 4.602-.058.26-.225.546-.51.83-1.82 1.82-7.045 2.868-9.28 2.829-2.034-.022-3.312-.293-3.648-3.748z"
          fill={colors.white}
        />
      </G>
    </Svg>
  );
};
export default EndCall;
