import configs, { getOptionsByField } from './tsConfigsValues';

function isCustomObj(valueObj) {
  // eslint-disable-next-line no-underscore-dangle
  return valueObj.__isCustom__ || valueObj.__isNew__;
}

function getCustomKeyIfExists(key) {
  const match = key.match(/Custom$/);
  return match ? key.slice(0, match.index) : null;
}

function mapCustomValuesToSelectObjects(values) {
  return values.map((value) => {
    return { value, label: value, __isCustom__: true };
  });
}

const customFieldsList = ['medications'];

/*
  API                                      <->  redux store
  =======================================  <->  ===================================
  testInputField: 'foo'                    <->  'foo'
  singleSelectField: 2, can't be custom    <->  {value: 2, label: 'pepsi'}
  multiSelectField: [1,3],                 <->  [{value: 1, label: 'coke'}, ...
  multiSelectFieldCustom: ['Bar'],         <->   {value: 'Bar', label: 'Bar'}]
*/

export function transformAPIPayloadToReducerPayload(payload) {
  return Object.entries(payload).reduce((prev, [key, value]) => {
    if (value == null) return { ...prev, [key]: null };
    const customKey = getCustomKeyIfExists(key);
    const optionsObj = customKey ? configs[customKey] : configs[key];
    const valueIsBoolean = typeof value === 'boolean';
    const transformValueToSelectObject = (v) => {
      const val = isNaN(v) ? v : Number(v);
      return { value: val, label: optionsObj[val] };
    };
    if (!optionsObj && !valueIsBoolean) {
      // textInput
      return {
        ...prev,
        [key]: isNaN(value) ? value : `${value}`,
      };
    }
    if (!Array.isArray(value)) {
      // singleSelect
      if (valueIsBoolean) {
        return {
          ...prev,
          [key]: {
            value,
            label: value ? 'Yes' : 'No',
          },
        };
      }
      return { ...prev, [key]: transformValueToSelectObject(value) };
    }
    // mutliSelect  value = [234,23]
    const sharedKey = customKey || key;
    const prevArray = prev[sharedKey] || [];
    const values = customKey
      ? mapCustomValuesToSelectObjects(value)
      : value.map(transformValueToSelectObject);
    return {
      ...prev,
      [sharedKey]: [...prevArray, ...values],
    };
  }, {});
}

export function transformReducerPayloadToAPIPayload(payload) {
  return Object.entries(payload).reduce((prev, [key, value]) => {
    const customKey = `${key}Custom`;
    // mutliSelect
    if (Array.isArray(value)) {
      if (!customFieldsList.includes(key)) {
        return {
          ...prev,
          [key]: value.map((v) => v.value),
        };
      }
      const standardValues = value.filter((v) => !isCustomObj(v)).map((v) => v.value);
      const customValues = value.filter((v) => isCustomObj(v)).map((v) => v.value);
      return {
        ...prev,
        [key]: standardValues,
        [customKey]: customValues,
      };
    }
    // singleSelect and textInput
    if (!value) {
      return {
        ...prev,
        [key]: null,
      };
    }
    return {
      ...prev,
      [key]: typeof value === 'object' ? value.value : value.trim(),
    };
  }, {});
}

export const yesNoOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const checkIfShouldBeSearchable = (field) => getOptionsByField(field).length >= 21;
