import { FunctionComponent } from 'react';
import { View, Big, Small, COLORS } from '@talkspace/react-toolkit';
import styled from '@/core/styled/styled';
import { webOnlyStyle } from '@/core/styled/styleHelpers';
import StonksIcon from './Icons/StonksIcon';

interface LongTermBonusProps {
  text?: string;
  isMobile?: boolean;
}

const StonksIconWrapper = styled(View)({
  height: 58,
  width: 58,
  borderRadius: '50%',
  marginRight: 20,
  ...webOnlyStyle({
    boxShadow: '0px 3px 11px -3px rgba(16,109,149,0.3)',
  }),
});

const LongTermBonus: FunctionComponent<LongTermBonusProps> = ({ text, isMobile }) => {
  let wasLastStringNumeric = false;
  let isFirstNumeric = false;
  const numericalRegex = new RegExp('[0-9]|[$0-9]([^ ]+)', 'g');
  const alternatingStyleText =
    text &&
    text.split(' ').reduce((stringArray: string[], string: string) => {
      if (stringArray.length === 0) {
        stringArray.push(string);
        const match = string.match(numericalRegex);
        wasLastStringNumeric = !!match && !!match.length;
        isFirstNumeric = !!match && !!match.length;
        return stringArray;
      }
      const currentMatch = string.match(numericalRegex);
      const isCurrentMatchNumeric = !!currentMatch && !!currentMatch.length;
      if (isCurrentMatchNumeric === wasLastStringNumeric) {
        const lastItem = stringArray.pop();
        stringArray.push(`${lastItem} ${string}`);
      } else {
        stringArray.push(` ${string} `);
      }
      wasLastStringNumeric = isCurrentMatchNumeric;
      return stringArray;
    }, []);
  return text ? (
    <View
      row
      style={{
        maxWidth: '399px',
        height: 'auto',
        backgroundColor: '#F4F7FB',
        borderRadius: 10,
        paddingLeft: isMobile ? 15 : 21,
        paddingRight: isMobile ? 15 : 24,
        paddingTop: isMobile ? 15 : 27,
        paddingBottom: isMobile ? 15 : 20,
      }}
    >
      <StonksIconWrapper>
        <StonksIcon />
      </StonksIconWrapper>
      <View>
        <Big style={{ fontWeight: 'bold' }}>Increase your earnings</Big>

        <View row style={{ width: 205 }}>
          <Small>
            {alternatingStyleText &&
              alternatingStyleText.map((string, idx) => {
                const checkNum = isFirstNumeric ? 1 : 0;
                if (idx % 2 === checkNum)
                  return (
                    // The word might be repeated
                    // eslint-disable-next-line react/no-array-index-key
                    <Small key={`${string}${idx}`} inline>
                      {string}
                    </Small>
                  );

                if (string.indexOf('.') > 0) {
                  return (
                    <>
                      <Small
                        // eslint-disable-next-line react/no-array-index-key
                        key={`${string}${idx}`}
                        inline
                        style={{ fontWeight: 'bold', color: COLORS.darkGreen }}
                      >
                        {string.split('.')[0]}
                      </Small>
                      {/* eslint-disable-next-line react/no-array-index-key */}
                      <Small key={`${string}${idx}`} inline>
                        .
                      </Small>
                    </>
                  );
                }
                return (
                  <Small
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${string}${idx}`}
                    inline
                    style={{ fontWeight: 'bold', color: COLORS.darkGreen }}
                  >
                    {string}
                  </Small>
                );
              })}
          </Small>
        </View>
      </View>
    </View>
  ) : null;
};

export default LongTermBonus;
