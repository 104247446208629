import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import useQueryBusinessLine from 'ts-frontend/hooks/useQueryBusinessLine';
import useQueryNotes, { NoteItem } from 'hooks/notes/useQueryNotes';
import Footer from 'components/Reusable//Footer/Footer';
import Submenu from 'components/Reusable//Submenu/Submenu';
import Button from 'components/Reusable//Buttons/Button';
import useQueryRoomDetails from 'ts-frontend/hooks/useQueryRoomDetails';
import { useHistory, useRouteMatch } from '@/core/routerLib';
import * as journeyActions from '../../../../../actions/JourneyActions';

const ACTION_SETTLED_TIMEOUT = 1000;

interface RoomJourneyInteraction {
  category?: string;
  journeyItemId?: number;
  regards?: string;
  roomJourneyId?: number;
  roomReminderId?: number;
}

interface SessionHighlights {
  body?: string;
  category?: string;
  feedback?: any;
  id?: number;
  processedBody?: string;
  reminderTypeID?: number;
  roomJourneyInteraction: RoomJourneyInteraction;
  title?: string;
}

interface Props {
  isUpdating: boolean;
  sessionHighlights: SessionHighlights;
  patchJourneyItem: (journeyData: { roomJourneyInteraction: RoomJourneyInteraction }) => any;
  getJourneyItems: () => void;
}

const SessionSummarization: FunctionComponent<Props> = ({
  isUpdating,
  sessionHighlights,
  patchJourneyItem,
  getJourneyItems,
}) => {
  const history = useHistory();
  const match = useRouteMatch<{ roomID: string }>();
  const { data: { clientUserID } = {} } = useQueryRoomDetails(match.params.roomID);
  const { data: businessLine } = useQueryBusinessLine(match.params.roomID, clientUserID);
  const { data: notes } = useQueryNotes(match.params.roomID);

  const [firstNonSubmittedEAPProgressNote, setFirstNonSubmittedEAPProgressNote] = useState<
    NoteItem | null | undefined
  >();

  const [draftProgressNote, setDraftProgressNote] = useState<NoteItem | null>(null);

  const getFirstNonSubmittedEapProgressNote = useCallback((): NoteItem | null => {
    let eapProgressNoteNonSubmitted: NoteItem[] = [];
    if (businessLine?.isEAP && notes?.items?.length) {
      eapProgressNoteNonSubmitted = notes.items
        .filter((it) => it.type === 'progress' && it.status === null)
        .sort((a, b) => (a.startDate < b.startDate ? -1 : 1));
    }
    return eapProgressNoteNonSubmitted.length > 0 ? eapProgressNoteNonSubmitted[0] : null;
  }, [businessLine, notes]);

  const getDraftProgressNote = useCallback((): NoteItem | undefined => {
    let draft: NoteItem | undefined;
    if (notes?.items?.length) {
      draft = notes.items.find((it) => it.status === 'draft' && it.type === 'progress');
    }
    return draft;
  }, [notes]);

  useEffect(() => {
    setFirstNonSubmittedEAPProgressNote(getFirstNonSubmittedEapProgressNote());
    setDraftProgressNote(getDraftProgressNote() || null);
  }, [notes, businessLine, getDraftProgressNote, getFirstNonSubmittedEapProgressNote]);

  const handleDismissPress = () => {
    const { roomJourneyInteraction } = sessionHighlights;
    return patchJourneyItem({ roomJourneyInteraction }).then(() => {
      getJourneyItems();
      setTimeout(() => history.goBack(), ACTION_SETTLED_TIMEOUT);
    });
  };

  const copyToProgressNote = () => {
    if (draftProgressNote) {
      history.push(`/room/${match.params.roomID}/progress-notes/${draftProgressNote.noteID}/edit`, {
        sessionHighlights: sessionHighlights.processedBody,
      });
    } else if (businessLine?.isEAP && firstNonSubmittedEAPProgressNote) {
      history.push(
        `/room/${match.params.roomID}/progress-notes/new?eapSessionReportID=${firstNonSubmittedEAPProgressNote.sessionReportData?.id}`,
        {
          sessionHighlights: sessionHighlights.processedBody,
        }
      );
    } else {
      history.push({
        pathname: `/room/${match.params.roomID}/progress-notes/new`,
        state: {
          sessionHighlights: sessionHighlights.processedBody,
        },
      });
    }
  };

  const getFooterChildren = (): JSX.Element[] => {
    let sessionSummaryNoteDisabled = true;
    if (businessLine && notes) {
      sessionSummaryNoteDisabled =
        !draftProgressNote &&
        ((businessLine.isEAP && !firstNonSubmittedEAPProgressNote) ||
          (businessLine.isBH && notes.currentSessionReport?.status === 'awaiting') ||
          (businessLine.isBH && notes.currentSessionReport?.status === 'cancelled') ||
          (businessLine.isBH && notes.currentSessionReport === null));
    }
    const footerActions = [
      {
        disabled: false,
        title: 'Dismiss',
        isSecondary: true,
        clickHandler: handleDismissPress,
      },
      {
        disabled: sessionSummaryNoteDisabled,
        title: 'Copy to progress note',
        isSecondary: false,
        clickHandler: copyToProgressNote,
      },
    ];
    return footerActions.map((action) => (
      <Button
        isActive={!action.disabled}
        key={action.title}
        title={action.title}
        isSecondary={action.isSecondary}
        clickHandler={action.clickHandler}
      />
    ));
  };

  const getFooterComponent = (): JSX.Element => (
    <Footer
      key={4}
      childComponents={getFooterChildren()}
      isError={false}
      isUpdating={isUpdating}
      showSpinner={false}
      spinnerMessage=""
    />
  );

  const getSummaryContent = (): JSX.Element => (
    <div style={{ whiteSpace: 'pre-wrap' }}>{sessionHighlights.processedBody}</div>
  );

  return (
    <Submenu
      childComponents={[getSummaryContent()]}
      title="Weekly Highlights"
      prevRoute="agenda-tab"
      footerComponent={getFooterComponent()}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    sessionHighlights: state.journey.sessionHighlights,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      patchJourneyItem: journeyActions.patchJourneyItem,
      getJourneyItems: journeyActions.getJourneyItems,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SessionSummarization);
