import {
  View,
  Modal,
  Huge,
  Large,
  COLORS,
  Standard,
  BaseButton,
  RedX,
} from '@talkspace/react-toolkit';

const titleText = 'Something went wrong';
const ErrorBookingModal = ({
  closeModal,
  textContent,
}: {
  closeModal: () => void;
  textContent?: string;
}) => (
  <Modal isVisible onBackdropPress={closeModal} titleText={titleText}>
    <Modal.Panel onBackdropPress={closeModal}>
      <View
        style={{
          alignItems: 'center',
          marginTop: 9,
          textAlign: 'center',
          width: 278,
        }}
      >
        <RedX />
        <Huge style={{ marginTop: 30, marginBottom: 20 }}>{titleText}</Huge>
        <Large>{textContent || 'Please try again later'}</Large>
      </View>
      <BaseButton style={{ marginTop: 50 }} onPress={closeModal}>
        <Standard variant="standardDarkGrey" style={{ color: COLORS.darkGreen }}>
          Close
        </Standard>
      </BaseButton>
    </Modal.Panel>
  </Modal>
);

export default ErrorBookingModal;
