import { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useParams } from 'react-router-dom';
import {
  getInvites as getInvitesAction,
  resendInvite as resendInviteAction,
  revokeInvite as revokeInviteAction,
  postInvite as postInviteAction,
} from '../../../../../actions/InviteActions';
import UserActionCard from '../../../../Reusable/UserActionCard/UserActionCard';
import Submenu from '../../../../Reusable/Submenu/Submenu';
import ListItem from '../../../../Reusable/ListItems/ListItem';
import ItemList from '../../../../Reusable/ItemList/ItemList';
import { formatDateMedium } from '../../../../../utils/date';
import { getActiveCustomerName } from '../../../../../utils/customerHelper';
import useQueryParticipants from '../../../../../hooks/useQueryParticipants';
import './Invites.css';
import { CustomerInformation } from '../../../../../types/customer';

interface Invitation {
  createdAt: string;
  expiresAt: string;
  invitationSentToEmail: string | null;
  invitationSentToEmailSentAt: string;
  modality: 'Email' | 'Link in room';
  privateTalkInvitationID: number;
  status: string;
  usedAt: string | null;
}

interface Props {
  customerInformation: CustomerInformation;
  invites: Invitation[];
  showSpinner: boolean;
  isUpdating: boolean;
  spinnerMessage: string;
  isError: false;
  roomType: string;
  getInvites: () => void;
  revokeInvite: (invitationID: number) => void;
  resendInvite: (invitationID: number) => void;
  postInvite: () => void;
}

const Invites = ({
  customerInformation,
  invites,
  showSpinner,
  isUpdating,
  spinnerMessage,
  isError,
  roomType,
  getInvites,
  revokeInvite,
  resendInvite,
  postInvite,
}: Props) => {
  const { roomID } = useParams<{ roomID: string }>();

  const { data: participants } = useQueryParticipants(Number(roomID));

  useEffect(() => {
    getInvites();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getItemActions = () => [
    {
      text: 'Resend',
      method: (item: Invitation) => resendInvite(item.privateTalkInvitationID),
      cssClass: 'default',
    },
    {
      text: 'Delete',
      method: (item: Invitation) => revokeInvite(item.privateTalkInvitationID),
      cssClass: 'danger',
    },
  ];

  const processItems = () =>
    invites.map((item) => {
      const itemDisplayData = {} as { time: string; status: string; modality: string };

      itemDisplayData.time = formatDateMedium(item.createdAt);
      itemDisplayData.status =
        item.status === 'Pending' ? item.status : formatDateMedium(item.status);
      itemDisplayData.modality = item.modality;

      const actions = item.status === 'Pending' ? getItemActions() : undefined;
      const icon = actions ? undefined : { className: 'fa-check', type: 'success' };

      return (
        <ListItem
          key={item.privateTalkInvitationID}
          itemDataOrder={['time', 'status', 'modality']}
          itemDisplayData={itemDisplayData}
          item={item}
          cssClass={item.status === 'Pending' ? 'list-item grayed' : 'list-item'}
          actions={actions}
          icon={icon}
          widths={[98, 94, 80]}
        />
      );
    });

  const getInternalContent = () => {
    if (roomType !== 'couples_room')
      return (
        <div className="no-invitations">
          <p>
            You can only invite additional clients to rooms with an active couples plan
            subscription. Please have the client subscribe on a couples plan first.
          </p>
        </div>
      );

    return !!invites && !!invites.length ? (
      <ItemList
        items={processItems()}
        titles={['Created', 'Redeemed', 'Modality']}
        noDataMessage="No invitations."
        cssClass="invitations grey"
        widths={[98, 94, 92]}
      />
    ) : (
      <div className="no-invitations">
        <p>
          Post a partner invitation link in the room for your client to see. Once the partner taps
          on the link they will be able to create their own Talkspace account and automatically join
          the room
        </p>
      </div>
    );
  };

  const getInviteButtons = () => {
    if (roomType !== 'couples_room') return [];
    return [
      {
        isSecondary: false,
        title: 'Post invitation link',
        clickHandler: () => postInvite(),
      },
    ];
  };

  const getUserActionCardContent = () => {
    const isCouplesRoom = roomType === 'couples_room';
    return (
      <UserActionCard
        preferredName={getActiveCustomerName(customerInformation, participants || [])}
        content={getInternalContent()}
        buttons={getInviteButtons()}
        isError={isError}
        isUpdating={isCouplesRoom ? isUpdating : false}
        showSpinner={isCouplesRoom ? showSpinner : false}
        spinnerMessage={spinnerMessage}
      />
    );
  };

  return (
    <Submenu
      childComponents={[getUserActionCardContent()]}
      title="Room invitations"
      prevRoute="case-tab"
    />
  );
};

const mapStateToProps = (state) => {
  return {
    customerInformation: state.customerInformation,
    invites: state.invite.invites,
    showSpinner: state.invite.showSpinner,
    isUpdating: state.invite.isUpdating,
    spinnerMessage: state.invite.spinnerMessage,
    isError: state.invite.isError,
    roomType: state.room.roomType,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getInvites: getInvitesAction,
      revokeInvite: revokeInviteAction,
      resendInvite: resendInviteAction,
      postInvite: postInviteAction,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Invites);
