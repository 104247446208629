import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const standardPath = '';
const majorPath =
  'M22.7256 13.457C23.0928 13.0898 23.0928 12.4961 22.7256 12.1328L17.7256 7.12891C17.3584 6.76172 16.7646 6.76172 16.4014 7.12891C16.0381 7.49609 16.0342 8.08984 16.4014 8.45312L19.7998 11.8516L10.1865 11.8555C9.66699 11.8555 9.24902 12.2734 9.24902 12.793C9.24902 13.3125 9.66699 13.7305 10.1865 13.7305H19.7998L16.4014 17.1289C16.0342 17.4961 16.0342 18.0898 16.4014 18.4531C16.7686 18.8164 17.3623 18.8203 17.7256 18.4531L22.7256 13.457ZM9.56152 5.91797C10.0811 5.91797 10.499 5.5 10.499 4.98047C10.499 4.46094 10.0811 4.04297 9.56152 4.04297H6.43652C4.53809 4.04297 2.99902 5.58203 2.99902 7.48047V18.1055C2.99902 20.0039 4.53809 21.543 6.43652 21.543H9.56152C10.0811 21.543 10.499 21.125 10.499 20.6055C10.499 20.0859 10.0811 19.668 9.56152 19.668H6.43652C5.57324 19.668 4.87402 18.9688 4.87402 18.1055V7.48047C4.87402 6.61719 5.57324 5.91797 6.43652 5.91797H9.56152Z';

const pathConfig: IconConfig = {
  standard: {
    default: {
      path: standardPath,
    },
  },
  major: {
    default: {
      path: majorPath,
    },
  },
};

const ArrowRightFromBracket = withDSIconMaker(pathConfig, 'arrow pointing right from a bracket');

export default ArrowRightFromBracket;
