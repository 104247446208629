import Svg, { Path, Circle } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

interface BadgedNotesIconProps {
  isHovering?: boolean;
  isActive?: boolean;
  width?: number;
  height?: number;
}

const BadgedNotesIcon = ({
  width = 24,
  height = 23,
  isHovering,
  isActive,
}: BadgedNotesIconProps) => {
  const { colors } = useEmotionTheme();
  const color = isActive ? colors.orange : colors.dimGray;
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        d="M7.82824 6.91028H3.2445C1.72821 6.91028 0.499023 8.13947 0.499023 9.65575V19.7225C0.499023 21.2388 1.72821 22.468 3.2445 22.468H13.3112C14.8275 22.468 16.0567 21.2388 16.0567 19.7225V15.1387C16.0567 14.6377 15.6505 14.2315 15.1495 14.2315H15.1336C14.6326 14.2315 14.2264 14.6377 14.2264 15.1387V19.7225C14.2264 20.2279 13.8167 20.6376 13.3112 20.6376H3.2445C2.73907 20.6376 2.32934 20.2279 2.32934 19.7225V9.65575C2.32934 9.15032 2.73907 8.74059 3.2445 8.74059H7.82824C8.32928 8.74059 8.73544 8.33443 8.73544 7.83339V7.81748C8.73544 7.31645 8.32928 6.91028 7.82824 6.91028Z"
        fill={color}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6494 5.98733C14.8383 5.99102 15.0258 6.06504 15.1693 6.2087L16.7596 7.80063C16.9027 7.94394 16.9764 8.13096 16.9797 8.31926C16.9831 8.50754 16.916 8.69703 16.778 8.84529L10.0533 16.0698H6.89722V12.8752L14.1211 6.18825C14.2702 6.05029 14.4605 5.98364 14.6494 5.98733Z"
        fill={isActive || isHovering ? color : colors.white}
        stroke={color}
        strokeWidth="1.8144"
      />
      <Circle cx="17" cy="7" r="5.5" fill="#CF005F" stroke="white" strokeWidth="3" />
    </Svg>
  );
};

export default BadgedNotesIcon;
