import Svg, { G, Path } from 'svgs';

interface RecycleIconProps {
  color?: string;
  width?: number;
  height?: number;
  style?: {};
}

const RecycleIcon = ({
  color = '#68768D',
  width = 23,
  height = 29,
  ...otherProps
}: RecycleIconProps) => (
  <Svg width={width} height={height} viewBox="0 0 23 29" {...otherProps}>
    <G stroke={color} strokeWidth={2.5} fill="none" fillRule="evenodd" strokeLinecap="round">
      <Path
        d="M16.071 5.14A9.956 9.956 0 0010 3.087c-5.523 0-10 4.477-10 10 0 1.29.244 2.524.69 3.656m3.63 4.575A9.954 9.954 0 0010 23.087c5.523 0 10-4.478 10-10a9.973 9.973 0 00-.71-3.71"
        transform="translate(-30 -7) translate(31.5 8.5)"
      />
      <Path
        d="M15.798 0l.982 4.816a.1.1 0 01-.036.099L12.91 7.949h0"
        transform="translate(-30 -7) translate(31.5 8.5)"
      />
      <Path
        d="M6.588 18.237l.983 4.816a.1.1 0 01-.036.098L3.7 26.186h0"
        transform="translate(-30 -7) translate(31.5 8.5) rotate(-180 5.642 22.211)"
      />
    </G>
  </Svg>
);

export default RecycleIcon;
