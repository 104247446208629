import { View, TaskIconSmall, Small, Standard } from '@talkspace/react-toolkit';
import { ProgressNoteModality, progressNoteModalityEnum } from 'components/Dashboard/types';
import moment from 'moment';
import { VoidFunctionComponent } from 'react';
import styled from '../../../../core/styled';

interface TaskDateTimeProps {
  modality?: ProgressNoteModality | null;
  date: Date | string;
}

const DateAndInfo = styled(View)(({ theme: { window } }) => {
  const { isDesktop } = window;
  return {
    marginLeft: 15,
    ...(isDesktop && {
      flexDirection: 'row',
      alignItems: 'flex-end',
      '& p:last-child': {
        marginLeft: 15,
      },
    }),
  };
});

const TaskDateTime: VoidFunctionComponent<TaskDateTimeProps> = ({ modality, date }) => (
  <View row>
    <View>
      <TaskIconSmall modality={modality || 'liveSession'} style={{ marginTop: 3 }} />
    </View>
    <DateAndInfo>
      <Standard variant="standardBoldTSBlack">{moment(date).format('ddd, MMM D')}</Standard>
      <Small>
        {modality === progressNoteModalityEnum.MESSAGING ? 'Messaging' : 'Live'} Session
      </Small>
    </DateAndInfo>
  </View>
);

export default TaskDateTime;
