import { useMutation, useQueryClient } from 'react-query';
import apiHelper from '@/utils/api';
import apiWrapper from '../../utils/apiWrapper';
import { CollateralNoteMutateVariables } from './useMutationSaveDraftCollateralNote';
import { collateralNoteQueryKey, notesListQueryKey } from './queryKeys';

const submitCollateralNote = async (params: CollateralNoteMutateVariables): Promise<void> => {
  const { roomID, noteID, clientUserID, ...requestBody } = params;

  const requestURL = noteID
    ? `/v2/rooms/${roomID}/collateral-notes/${noteID}/submit`
    : `/v2/rooms/${roomID}/collateral-notes/submit`;

  const method: keyof typeof apiWrapper = noteID ? 'patch' : 'post';

  await apiWrapper[method](`${apiHelper().apiEndpoint}${requestURL}`, requestBody);
};

const useMutationSubmitCollateralNote = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, CollateralNoteMutateVariables>(submitCollateralNote, {
    onSuccess: (_, { roomID, noteID, clientUserID }) => {
      queryClient.invalidateQueries(notesListQueryKey(roomID));
      if (noteID) {
        queryClient.invalidateQueries(collateralNoteQueryKey(clientUserID, noteID));
      }
    },
  });
};

export default useMutationSubmitCollateralNote;
