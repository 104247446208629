import Svg, { Defs, G, Path, Rect } from 'svgs';
import { useEmotionTheme, EmotionStyle } from '../../core/styled';
import { useUniqueID } from '../../hooks/a11yHelper';

interface Props {
  width?: number;
  height?: number;
  color?: string;
  dataQa?: string;
  style?: EmotionStyle;
}

const CheckmarkTextBubble = ({
  width = 24,
  height = 24,
  color,
  dataQa,
  style,
  ...props
}: Props) => {
  const { colors } = useEmotionTheme();
  const titleText = 'Checkmark text bubble';
  const clipPathID = useUniqueID('clipPathID');
  return (
    <Svg
      width={width}
      height={height}
      data-qa={dataQa}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 24 24"
      fill="none"
      style={style}
      {...props}
    >
      <G clipPath={`url(#${clipPathID})`}>
        <Path
          d="M10.7493 16.1667C10.5302 16.1669 10.3132 16.1239 10.1107 16.0401C9.90821 15.9563 9.72424 15.8334 9.56931 15.6784L6.41598 12.5959C6.25795 12.4412 6.16786 12.2301 6.16552 12.0089C6.16317 11.7878 6.24877 11.5748 6.40348 11.4167C6.55819 11.2587 6.76934 11.1686 6.99047 11.1663C7.21161 11.1639 7.42462 11.2495 7.58265 11.4042L10.7435 14.4934L16.4151 8.91008C16.5751 8.76935 16.7829 8.69522 16.9958 8.70292C17.2087 8.71062 17.4105 8.79956 17.5599 8.95148C17.7092 9.1034 17.7947 9.30674 17.7988 9.51974C17.8029 9.73274 17.7252 9.93922 17.5818 10.0967L11.921 15.6859C11.7669 15.8392 11.5841 15.9605 11.383 16.043C11.182 16.1255 10.9666 16.1676 10.7493 16.1667ZM21.9993 17.8334V12.2834C22.0304 9.71462 21.0886 7.22915 19.3629 5.32605C17.6373 3.42295 15.2556 2.24311 12.696 2.02341C11.2674 1.92346 9.83392 2.13153 8.49267 2.63354C7.15143 3.13555 5.93365 3.91979 4.92174 4.9332C3.90983 5.94661 3.1274 7.16555 2.62737 8.50754C2.12735 9.84953 1.92141 11.2833 2.02348 12.7117C2.39181 18.0067 7.06765 22.0001 12.9018 22.0001H17.8326C18.9373 21.9988 19.9963 21.5593 20.7775 20.7782C21.5586 19.9971 21.998 18.9381 21.9993 17.8334V17.8334ZM12.5826 3.68674C14.7214 3.87594 16.7092 4.8675 18.1469 6.4622C19.5845 8.05691 20.3654 10.1366 20.3326 12.2834V17.8334C20.3326 18.4965 20.0693 19.1323 19.6004 19.6012C19.1316 20.07 18.4957 20.3334 17.8326 20.3334H12.9018C7.87348 20.3334 3.99931 17.0834 3.68681 12.5967C3.60477 11.4539 3.75929 10.3064 4.14071 9.22597C4.52214 8.14553 5.12227 7.15535 5.90362 6.3173C6.68497 5.47925 7.63075 4.81134 8.68187 4.35528C9.73299 3.89923 10.8669 3.66483 12.0126 3.66674C12.2018 3.66674 12.3926 3.67424 12.5826 3.68674Z"
          fill={color || colors.permaTalkspaceDarkGreen}
        />
      </G>
      <Defs>
        <clipPath id={clipPathID}>
          <Rect width="20" height="20" fill="white" transform="translate(2 2)" />
        </clipPath>
      </Defs>
    </Svg>
  );
};

export default CheckmarkTextBubble;
