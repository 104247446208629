import Svg, { Path } from 'svgs';

const ExpandAllIcon = ({ width = 13, height = 27, ...otherProps }) => (
  <Svg width={width} height={height} viewBox="0 0 13 18" fill="none" {...otherProps}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.733 10.408a1.098 1.098 0 010 1.553l-4.917 4.917-4.918-4.917a1.098 1.098 0 111.553-1.553l3.365 3.364 3.365-3.364a1.098 1.098 0 011.552 0zM11.733 7.592a1.098 1.098 0 000-1.553L6.816 1.122 1.898 6.039a1.098 1.098 0 101.553 1.553l3.365-3.365 3.365 3.365a1.098 1.098 0 001.552 0z"
      fill="#09B198"
    />
  </Svg>
);

export default ExpandAllIcon;
