import { ComponentType, FunctionComponent } from 'react';
import { useRouteMatch } from 'react-router';
import useQueryBusinessLine from '../hooks/useQueryBusinessLine';
import useQueryRoomDetails from '../hooks/useQueryRoomDetails';

interface Props {
  businessLineData: any;
}

const withBusinessLine = (PassedComponent: ComponentType<Props>) => {
  const WrappedComponent: FunctionComponent<Props> = (props) => {
    const match = useRouteMatch<{ roomID: string }>();
    const { data: { clientUserID } = {} } = useQueryRoomDetails(match.params.roomID);
    const { data: businessLineData } = useQueryBusinessLine(
      match.params.roomID.toString(),
      clientUserID
    );
    return <PassedComponent {...props} businessLineData={businessLineData} />;
  };

  return WrappedComponent;
};

export default withBusinessLine;
