import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import { useEffect, useRef } from 'react';
import { keyframes } from '@emotion/core';
import View from '../View';
import passcodeAnimation from './assets/passcodeAnimation.json';
import styled, { EmotionStyle } from '../../core/styled';
import './lottie.css';

const OPEN_SAGMENT: [number, number] = [20, 75];
const CLOSE_SAGMENT: [number, number] = [75, 100];
const INITIAL_OPEN: [number, number] = [49, 50];
const INITIAL_CLOSE: [number, number] = [0, 2];

const shakeAnimation = keyframes({
  '0%': { transform: 'rotate(0dg) translate(-5px, 0)' },
  '25%': { transform: 'rotate(10deg) translate(5px, 0)' },
  '50%': { transform: 'rotate(-10deg) translate(-5px, 0)' },
  '75%': { transform: 'rotate(10deg) translate(5px, 0)' },
  '100%': { transform: 'rotate(0deg) translat(0)' },
});

const AnimationContainer = styled(View)<{ shouldShake: boolean }>(({ shouldShake }) => {
  return {
    animation: shouldShake && `0.75s ${shakeAnimation}`,
  };
});

interface PasscodeViewProp {
  isError?: boolean;
  isPasscodeCreated?: boolean;
  inCreateMode?: boolean;
  isSuccess?: boolean;
  styles?: EmotionStyle;
}

const PasscodeAnimation = ({
  isError = false,
  isPasscodeCreated = false,
  inCreateMode = false,
  isSuccess = false,
  styles,
}: PasscodeViewProp) => {
  const animationRef = useRef<LottieRefCurrentProps | null>(null);

  useEffect(() => {
    animationRef.current?.setSpeed(0.3);
    if (isPasscodeCreated) {
      animationRef.current?.playSegments(CLOSE_SAGMENT, true);
    }
    if (isSuccess) {
      animationRef.current?.playSegments(OPEN_SAGMENT, true);
    }
  }, [isError, isPasscodeCreated, inCreateMode, isSuccess]);

  return (
    <AnimationContainer shouldShake={isError} style={styles}>
      <Lottie
        className="passcode_animation"
        lottieRef={animationRef}
        loop={false}
        autoPlay
        animationData={passcodeAnimation}
        initialSegment={inCreateMode ? INITIAL_OPEN : INITIAL_CLOSE}
        renderer="svg"
      />
    </AnimationContainer>
  );
};

export default PasscodeAnimation;
