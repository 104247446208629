import { FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import {
  Text,
  useEmotionTheme,
  useObjectState,
  View,
  useWindowWidthState,
  styled,
  TouchableView,
  Select,
  OptionType,
  TextAreaRounded,
} from '@talkspace/react-toolkit';
import moment from 'moment';
import { ValueType } from 'react-select';
import { TherapistType } from 'ts-frontend/entities/Therapist';
import { RouteComponentProps, useHistory } from '../../../../../core/routerLib';
import {
  createRoom as createRoomAction,
  createPsychiatryRoom as createPsychiatryRoomAction,
} from '../../../../../actions/RoomActions';
import Submenu from '../../../../Reusable/Submenu/Submenu';
import Footer from '../../../../Reusable/Footer/Footer';
import AlertContainer from '../../../../Reusable/AlertContainer/AlertContainer';
import Button from '../../../../Reusable/Buttons/Button';
import { getUserData } from '../../../../../utils/token';
import QuestionMark from '../../../../Icons/QuestionMark';

interface RoomCreationProps extends RouteComponentProps {
  createPsychiatryRoom: ({
    clientID,
    therapistID,
    currentRoomID,
    referralReason,
  }: CreatePsychiatryRoomObject) => void;
  createRoom: ({ roomType, therapistID, clientID, currentRoomID }: CreateRoomObject) => void;
  customerInformation: Record<string, unknown>;
  currentRoomID: number;
  dateOfBirth: string;
  isError: boolean;
  isPsychiatry: boolean;
  isUpdating: boolean;
  spinnerMessage: string;
  therapistType: TherapistType;
}

interface CreateRoomObject {
  roomType: string;
  therapistID?: number;
  clientID: number;
  currentRoomID: number;
}

interface CreatePsychiatryRoomObject {
  clientID: number;
  therapistID?: number;
  currentRoomID: number;
  referralReason: string;
}

const ToolTipArrow = styled(View)<{ isMobile: boolean }>(({ isMobile }) => {
  return {
    '&::before': {
      position: 'absolute',
      borderStyle: 'solid',
      borderColor: '#E1E1E1 transparent',
      display: 'block',
      width: 0,
      top: -10,
      bottom: 'auto',
      left: isMobile ? 19 : 'auto',
      right: 334,
      borderWidth: '0 9px 9px',
      content: `""`,
    },
    '&::after': {
      position: 'absolute',
      borderStyle: 'solid',
      borderColor: '#fff transparent',
      display: 'block',
      width: '0',
      top: '-8px',
      bottom: 'auto',
      left: isMobile ? 10 : 'auto',
      right: '325px',
      borderWidth: '0 18px 18px',
      content: `""`,
    },
  };
});

const RoomCreation: FunctionComponent<RoomCreationProps> = ({
  createPsychiatryRoom,
  createRoom,
  customerInformation,
  currentRoomID,
  dateOfBirth,
  isError,
  isPsychiatry,
  isUpdating,
  match,
  spinnerMessage,
  therapistType,
}) => {
  const userData = getUserData();
  const history = useHistory();
  const { colors } = useEmotionTheme();
  const { isMobile } = useWindowWidthState();
  const [referralReason, setReferralReason] = useState<string>('');
  const [showReferralReasonForm, setShowReferralReasonForm] = useState<boolean>(false);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [room, setRoom] = useObjectState<CreateRoomObject>({
    roomType: isPsychiatry ? 'psychiatryRoom' : 'privateRoom',
    therapistID: isPsychiatry ? undefined : userData.id,
    clientID: Number(customerInformation.clientUserID),
    currentRoomID,
  });

  // Accounts for dateOfBirth being null
  const isUnderAge = moment().diff(moment(dateOfBirth || undefined), 'years') < 18;
  const otherRoomTypeOptions: OptionType[] =
    isUnderAge && !isPsychiatry // If the room was already created, we shouldn't remove psychiatry
      ? []
      : [
          {
            label: 'Psychiatry room',
            value: 'psychiatryRoom',
          },
        ];

  const roomTypeOptions: OptionType[] = [
    {
      label: 'Private room',
      value: 'privateRoom',
    },
    {
      label: 'Couples room',
      value: 'couplesRoom',
    },
    ...otherRoomTypeOptions,
  ];

  const therapistOptions: OptionType[] = [
    {
      label: 'Me',
      value: userData.id.toString(),
    },
    {
      label: 'Eligibility Flow',
      value: undefined,
    },
  ];

  const handleRoomTypeChange = (option: ValueType<OptionType, false>) => {
    setRoom({ roomType: option?.value });
  };

  const handleWithChange = (option: ValueType<OptionType, false>) => {
    setRoom({ therapistID: option?.value ? Number(option.value) : undefined });
  };

  const getRoomTypeOptions = (
    isPsychiatryRoom: boolean,
    selectedTherapistType: TherapistType,
    therapistID?: number
  ) => {
    if (selectedTherapistType === 'primary' && therapistID !== undefined) {
      return roomTypeOptions.filter((item) => item.value !== 'psychiatryRoom');
    }
    if (isPsychiatryRoom) {
      return roomTypeOptions.filter((item) => item.value === 'psychiatryRoom');
    }
    return roomTypeOptions;
  };

  const isFormValid = () => {
    setShowAlert(!referralReason.length);
    return referralReason.length;
  };

  const createNewPsychiatryRoom = async () => {
    if (!isFormValid()) return;
    createPsychiatryRoom({
      clientID: room.clientID,
      therapistID: room.therapistID,
      currentRoomID: room.currentRoomID,
      referralReason,
    });
  };

  const createNewRoom = () => {
    createRoom({
      roomType: room.roomType,
      clientID: room.clientID,
      therapistID: room.therapistID,
      currentRoomID: room.currentRoomID,
    });
  };

  const cancel = () => {
    if (!showReferralReasonForm) {
      history.goBack();
    } else {
      setShowReferralReasonForm(false);
      setReferralReason('');
    }
  };

  const handleReferralReasonChange = (newValue: string) => setReferralReason(newValue);

  const toggleReferralReasonForm = () => setShowReferralReasonForm(!showReferralReasonForm);

  const getTooltip = () => (
    <View flex={0}>
      <TouchableView
        row
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        onPress={() => setShowTooltip(!showTooltip)}
        style={{
          marginTop: 26,
          marginBottom: 21,
          color: showTooltip ? colors.permaTeal : colors.permaViridianGreen,
          cursor: 'default',
        }}
      >
        <QuestionMark isHover={showTooltip} />
        <Text
          style={{
            lineHeight: '22px',
            marginLeft: 5,
            color: showTooltip ? colors.permaTeal : colors.permaViridianGreen,
          }}
        >
          See an example
        </Text>
      </TouchableView>
      {showTooltip && (
        <View
          style={{
            position: 'absolute',
            width: isMobile ? 346 : 375,
            left: 15,
            paddingTop: 20,
            paddingRight: 28,
            paddingBottom: 24,
            paddingLeft: 22,
            marginTop: 64,
            border: 'solid 1px',
            borderRadius: 10,
            borderColor: colors.permaMercuryJunior,
            backgroundColor: colors.white,
            boxShadow: '0px 2px 4px rgba(0,0,0,.5)',
            zIndex: 1,
          }}
        >
          I’ve been treating this client for the past 3 months. He came to me with depressed mood,
          poor sleep and appetite. In the last month his symptoms have worsened and he has begun to
          have anhedonia. No prior depressive episodes and he has never been on antidepressants. Can
          you please evaluate him for a possible medication trial?
          <ToolTipArrow isMobile={isMobile} />
        </View>
      )}
    </View>
  );

  const getReferralReasonForm = () => [
    <Text style={{ color: colors.boulder }} key={0}>
      Please describe the reason this client needs an evaluation for medication.
    </Text>,
    getTooltip(),
    <View key={1}>
      <TextAreaRounded
        autoSize
        value={referralReason}
        onChangeText={handleReferralReasonChange}
        placeholder="Describe reason for referral"
        textAreaStyle={{ padding: '8px 0 0' }}
      />
    </View>,
  ];

  const getBodyContent = () =>
    showReferralReasonForm
      ? getReferralReasonForm()
      : [
          <Select
            options={getRoomTypeOptions(isPsychiatry, therapistType, room.therapistID)}
            value={roomTypeOptions.find((option) => option.value === room.roomType)}
            placeholder="Room type"
            onChange={handleRoomTypeChange}
          />,
          <Select
            options={
              therapistType === 'primary' && room.roomType === 'psychiatryRoom'
                ? therapistOptions.filter((item) => item.label !== 'Me')
                : therapistOptions
            }
            value={therapistOptions.find((option) => option.value === room.therapistID?.toString())}
            placeholder="With"
            onChange={handleWithChange}
          />,
        ];

  const getFooterChildren = () => {
    const footerActions = [
      {
        title: 'Cancel',
        isSecondary: true,
        clickHandler: cancel,
      },
      room.roomType === 'psychiatryRoom' && !showReferralReasonForm
        ? {
            title: 'Next',
            isSecondary: false,
            clickHandler: toggleReferralReasonForm,
          }
        : {
            title: 'Create room',
            isSecondary: false,
            clickHandler:
              room.roomType === 'psychiatryRoom' ? createNewPsychiatryRoom : createNewRoom,
          },
    ];

    return showAlert
      ? [
          <AlertContainer
            key={1}
            title="Missing information"
            childComponents={[
              <Button
                isSecondary={false}
                title="Ok"
                key={2}
                clickHandler={() => setShowAlert(false)}
              />,
            ]}
            body="You must fill out a reason to refer someone to psychiatry"
            containerStyle={{ width: 355, border: 'none' }}
          />,
        ]
      : footerActions.map((action) => (
          <Button
            key={action.title}
            title={action.title}
            isSecondary={action.isSecondary}
            clickHandler={action.clickHandler}
          />
        ));
  };

  const previousRoute = showReferralReasonForm ? match.path.split('/')[3] : 'clinical-tab';

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (isUpdating) setShowSpinner(true);
    if (!isUpdating && showSpinner) {
      timer = setTimeout(() => {
        history.goBack();
      }, 2000);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [showSpinner, history, isUpdating, setShowSpinner]);

  return (
    <Submenu
      childComponents={getBodyContent()}
      footerComponent={
        <Footer
          key={4}
          childComponents={getFooterChildren()}
          isError={isError}
          isUpdating={isUpdating}
          footerStyle={
            showAlert
              ? {
                  height: 151,
                  flexDirection: 'column-reverse',
                  paddingTop: 3,
                }
              : {}
          }
          showSpinner={showSpinner}
          spinnerMessage={spinnerMessage}
        />
      }
      title="Create new room"
      onBack={cancel}
      prevRoute={previousRoute}
      bodyStyle={{ marginLeft: 20, marginRight: 20 }}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    customerInformation: state.customerInformation,
    currentRoomID: state.room.roomID,
    dateOfBirth: state.customerInformation.dateOfBirth,
    isError: state.room.isError,
    isUpdating: state.room.isUpdating,
    showSpinner: state.room.showSpinner,
    spinnerMessage: state.room.spinnerMessage,
    therapistType: state.availability.therapistType,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { createRoom: createRoomAction, createPsychiatryRoom: createPsychiatryRoomAction },
    dispatch
  );

const RoomCreationContainer = connect(mapStateToProps, mapDispatchToProps)(RoomCreation);

export default RoomCreationContainer;
