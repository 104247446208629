import React from 'react';
import Svg, { Defs, Path, G, Circle, Use } from 'svgs';
import { useUniqueID } from '@talkspace/react-toolkit';

function IconGrow(props) {
  const pathID = useUniqueID('reviewEmptyPath');
  const titleText = 'no new clients';
  return (
    <Svg
      width="89px"
      height="89px"
      viewBox="0 0 89 89"
      title={titleText}
      aria-label={titleText}
      {...props}
    >
      <Defs>
        <Path
          d="M.884 33.4a1.43 1.43 0 011.816.653l.059.126 4.473 10.825a1.445 1.445 0 01-.774 1.885 1.43 1.43 0 01-1.815-.652l-.06-.126L.11 35.286A1.445 1.445 0 01.884 33.4z"
          id={pathID}
        />
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Path
          d="M982 601.5a6.48 6.48 0 014.596 1.904A6.48 6.48 0 01988.5 608h0v105a6.48 6.48 0 01-1.904 4.596A6.48 6.48 0 01982 719.5h0H42a6.48 6.48 0 01-4.596-1.904A6.48 6.48 0 0135.5 713h0V608a6.48 6.48 0 011.904-4.596A6.48 6.48 0 0142 601.5h0z"
          stroke="#DDE3EA"
          fill="#F9FAFB"
          transform="translate(-50 -616)"
        />
        <Circle
          cx={44.5}
          cy={44.5}
          r={44.5}
          fill="#EFF3FA"
          transform="translate(-50 -616) translate(50 616)"
        />
        <G fillRule="nonzero">
          <G transform="translate(-50 -616) translate(50 616) translate(17 20) translate(5.933)">
            <Path
              d="M41.69 33.705h-.05c-.85-1.558-2.799-2.165-4.398-1.37l-3.806 1.957V27.59c-.027-2.502-2.07-4.523-4.597-4.55h-2.372v-11.3c5.377-.32 9.642-4.604 9.885-9.93v-.783a.973.973 0 00-.296-.733.994.994 0 00-.692-.294h-.791C29.1.224 24.717 4.564 24.49 9.98v3.082A9.964 9.964 0 0016.829 9h-1.285a.944.944 0 00-.742.293.973.973 0 00-.296.734V11.3c.227 5.164 4.42 9.292 9.638 9.49h.346v2.25h-2.422c-2.126.004-3.978 1.434-4.498 3.474l-2.372-.294a3.792 3.792 0 00-2.274.294l-11.22 5.234a2.923 2.923 0 00-1.483 3.767l2.422 5.821c.32.77.954 1.369 1.746 1.649s1.666.214 2.406-.181l3.213-1.713a3.99 3.99 0 012.817-.342l13.642 3.522 1.038.147a3.793 3.793 0 001.829-.44l11.022-5.968a3.188 3.188 0 001.57-1.879 3.15 3.15 0 00-.236-2.426z"
              fill="#CFEAFF"
            />
            <Path
              d="M41.69 33.705h-.05c-.85-1.558-2.799-2.165-4.398-1.37l-3.806 1.957V27.59c-.027-2.502-2.07-4.523-4.597-4.55h-2.372v-11.3c5.377-.32 9.642-4.604 9.885-9.93v-.783a.973.973 0 00-.296-.733.994.994 0 00-.692-.294h-.791C29.1.224 24.717 4.564 24.49 9.98v.782h0v2.3A9.964 9.964 0 0016.829 9h-1.285a.944.944 0 00-.742.293.973.973 0 00-.296.734V11.3c.227 5.164 4.42 9.292 9.638 9.49h.346v2.25h-2.422c-2.126.004-3.978 1.434-4.498 3.474l-2.372-.294a3.792 3.792 0 00-2.274.294l-11.22 5.234a2.923 2.923 0 00-1.483 3.767l2.422 5.821c.32.77.954 1.369 1.746 1.649s1.666.214 2.406-.181l3.213-1.713a3.99 3.99 0 012.817-.342l13.642 3.522 1.038.147a3.793 3.793 0 001.829-.44l11.022-5.968a3.188 3.188 0 001.57-1.879 3.15 3.15 0 00-.236-2.426zm-19.573-8.708h6.722c1.436.026 2.594 1.172 2.62 2.593v7.68l-2.718 1.419-8.403-1.566a.542.542 0 01-.494-.587.544.544 0 01.593-.489h6.129c1.692.007 3.108-1.267 3.262-2.935h0c.122-1.682-1.084-3.173-2.768-3.424l-7.463-.979a2.618 2.618 0 012.47-1.712h.05zm17.3 11.251l-11.072 5.969c-.424.227-.921.28-1.384.146L13.32 38.94a5.986 5.986 0 00-4.25.49L5.856 41.14a.998.998 0 01-.802.06.984.984 0 01-.582-.549l-2.422-5.87a.974.974 0 01.494-1.272l11.27-5.234a1.796 1.796 0 011.087-.147h.05l11.812 1.565a1.226 1.226 0 011.088 1.321h0a1.23 1.23 0 01-1.236 1.125h-6.228a2.51 2.51 0 00-2.461 2.273 2.499 2.499 0 002.017 2.668l8.748 1.614c.22.04.446.006.643-.097l8.798-4.55a1.292 1.292 0 011.73.538h.049c.158.286.195.621.102.934a1.226 1.226 0 01-.596.73z"
              fill="#5C84FB"
              stroke="#5C84FB"
              strokeWidth={0.847}
            />
          </G>
          <G transform="translate(-50 -616) translate(50 616) translate(17 20)">
            <Use fill="#000" xlinkHref={`#${pathID}`} />
            <Use fill="#5C84FB" xlinkHref={`#${pathID}`} />
          </G>
        </G>
      </G>
    </Svg>
  );
}

export default IconGrow;
