import { useEffect } from 'react';
import { STREAM_ID_PREFIX, RemoteUserTracks } from '../types/videoCallTypes';

const useDisplay = ({ remoteStreamList }: { remoteStreamList: RemoteUserTracks[] }) => {
  useEffect(() => {
    remoteStreamList.forEach(({ videoTrack, userID }) => {
      if (!videoTrack) {
        return;
      }

      videoTrack.play(`${STREAM_ID_PREFIX}${userID}`, {
        fit: 'contain',
      });
    });
  }, [remoteStreamList]);
};

export default useDisplay;
