import Svg, { Defs, G, Path, Rect } from 'svgs';

const HorizontalScrollLeftArrowDefaultIcon = () => (
  <Svg width="41" height="42" viewBox="0 0 41 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
    {/* @ts-ignore */}
    <G filter="url(#filter0_d_900_10808)">
      <Rect
        width="33"
        height="33"
        rx="16.5"
        transform="matrix(1 1.74846e-07 1.74846e-07 -1 4 37)"
        fill="white"
        shape-rendering="crispEdges"
      />
      <Path
        d="M23.11 11.9475C23.3336 12.1711 23.4454 12.4346 23.4454 12.7381C23.4454 13.0416 23.3336 13.3051 23.11 13.5287L16.1381 20.5006L23.1339 27.4964C23.3575 27.72 23.4652 27.9836 23.4569 28.287C23.4492 28.5905 23.3336 28.8541 23.11 29.0777C22.8864 29.3013 22.6228 29.4131 22.3193 29.4131C22.0159 29.4131 21.7523 29.3013 21.5287 29.0777L13.5746 21.1235C13.4947 21.0277 13.4346 20.928 13.3944 20.8245C13.3548 20.7204 13.335 20.6124 13.335 20.5006C13.335 20.3888 13.3548 20.2811 13.3944 20.1776C13.4346 20.0735 13.4947 19.9735 13.5746 19.8777L21.5527 11.9235C21.7603 11.6999 22.0159 11.5919 22.3194 11.5996C22.6228 11.6079 22.8864 11.7239 23.11 11.9475Z"
        fill="#005C65"
      />
    </G>
    <Defs>
      <filter
        id="filter0_d_900_10808"
        x="0"
        y="1"
        width="41"
        height="41"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.788235 0 0 0 0 0.827451 0 0 0 0 0.862745 0 0 0 1 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_900_10808" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_900_10808"
          result="shape"
        />
      </filter>
    </Defs>
  </Svg>
);

export default HorizontalScrollLeftArrowDefaultIcon;
