import { FunctionComponent } from 'react';
import Svg, { G, Path } from 'svgs';
import { EmotionStyle, useEmotionTheme } from '../../core/styled';

interface ErrorTooltipIconProps {
  color?: string;
  width?: number;
  height?: number;
  style?: EmotionStyle;
}

const ErrorTooltipIcon: FunctionComponent<ErrorTooltipIconProps> = ({
  color,
  width = 33,
  height = 32,
  style,
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'Error Tooltip Icon';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <G clipPath="url(#clip0_769_661)">
        <Path
          d="M0.5 4.00017C0.5 1.79383 2.29375 0 4.5 0H28.5C30.7062 0 32.5 1.79383 32.5 4.00017V22.0009C32.5 24.2073 30.7062 26.0011 28.5 26.0011H19.8312L12.1 31.8014C11.8 32.0264 11.3938 32.0639 11.05 31.8951C10.7063 31.7264 10.5 31.3826 10.5 31.0013V26.0011H4.5C2.29375 26.0011 0.5 24.2073 0.5 22.0009V4.00017ZM11.4375 7.93784C10.85 8.52537 10.85 9.47541 11.4375 10.0567L14.375 12.9943L11.4375 15.9319C10.85 16.5195 10.85 17.4695 11.4375 18.0508C12.025 18.6321 12.975 18.6383 13.5562 18.0508L16.4937 15.1132L19.4312 18.0508C20.0187 18.6383 20.9688 18.6383 21.55 18.0508C22.1312 17.4633 22.1375 16.5132 21.55 15.9319L18.6125 12.9943L21.55 10.0567C22.1375 9.46916 22.1375 8.51912 21.55 7.93784C20.9625 7.35657 20.0125 7.35032 19.4312 7.93784L16.4937 10.8755L13.5562 7.93784C12.9688 7.35032 12.0188 7.35032 11.4375 7.93784Z"
          fill={color || colors.permaFuchsia}
        />
      </G>
      <defs>
        <clipPath id="clip0_769_661">
          <rect width="32" height="32" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default ErrorTooltipIcon;
