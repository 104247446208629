import Svg, { G, Path } from 'svgs';

const PlusIcon = ({ width = 18, height = 18, color = '#09B198', ...otherProps }) => (
  <Svg width={width} height={height} viewBox="0 0 18 18" {...otherProps}>
    <G
      transform="translate(-150 -236) translate(151 234) rotate(45 -.743 11.621)"
      stroke={color}
      strokeWidth={2.5}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
    >
      <Path d="M1.15439271 1.15439271L9.57544534 9.57544534" />
      <Path transform="matrix(-1 0 0 1 10.73 0)" d="M1.15439271 1.15439271L9.57544534 9.57544534" />
    </G>
  </Svg>
);

export default PlusIcon;
