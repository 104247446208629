import { Spinner, View, styled, RoundFilledCheckmark } from '@talkspace/react-toolkit';
import UserButtons from './UserButtons';

const Text = styled(View)(({ theme: { colors } }) => {
  return {
    fontSize: 16,
    fontWeight: 700,
    color: colors.lightAccessableGreen,
    marginTop: 5,
    marginRight: 7,
  };
});

const UserFooter = ({ buttons, isUpdating, isError, showSpinner, spinnerMessage }) => {
  if (isError) {
    return <Text>Something went wrong</Text>;
  }
  if (spinnerMessage) {
    return (
      <View row align="center">
        <Text>{spinnerMessage}</Text>
        <RoundFilledCheckmark />
      </View>
    );
  }
  if (showSpinner || isUpdating) {
    return <Spinner />;
  }
  return (
    <View>
      {buttons.map((button, index) => (
        <UserButtons
          key={button.title + index.toString()}
          isSecondary={button.isSecondary}
          title={button.title}
          clickHandler={button.clickHandler}
        />
      ))}
    </View>
  );
};

export default UserFooter;
