import { VoidFunctionComponent } from 'react';
import Svg, { Path, Rect } from 'svgs';
import { EmotionStyle } from '../../core/styled';
import withHover, { WithHoverProps } from '../../hoc/withHover';
import HoverInfoBox from '../HoverInfoBox';

interface QuickCaseMenuBoxProps extends WithHoverProps {
  width?: number;
  height?: number;
  style?: EmotionStyle;
  isMenuShowing?: boolean;
}

const QuickCaseMenuBox: VoidFunctionComponent<QuickCaseMenuBoxProps> = ({
  width = 25,
  height = 24,
  isHovering,
  isMenuShowing,
  ...otherProps
}) => {
  if (isHovering || isMenuShowing) {
    return (
      <>
        <Svg
          width={width}
          height={height}
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...otherProps}
        >
          <Rect x="0.5" width={width} height={height} rx="6" fill="#E1F0EE" />
          <Path
            d="M5.5 8C5.5 6.34315 6.84315 5 8.5 5H16.5C18.1569 5 19.5 6.34315 19.5 8V17C19.5 18.6569 18.1569 20 16.5 20H8.5C6.84315 20 5.5 18.6569 5.5 17V8Z"
            fill="#E1F0EE"
            stroke="#005C65"
            strokeWidth="2"
          />
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.7124 14.537C12.4961 16.6247 12.206 16.3641 10.2274 14.4772C8.36879 12.704 8.83956 10.5827 10.1913 10.1056C11.0442 9.80313 11.8447 10.1798 12.3668 10.8601C12.4344 10.9486 12.5695 10.9462 12.6371 10.8578C13.1219 10.2216 13.8466 9.85096 14.6577 10.0578C16.1503 10.4393 16.7004 12.6669 14.7124 14.537Z"
            fill="#005C65"
          />
          <Path
            d="M9.5 5C9.5 3.89543 10.3954 3 11.5 3H13.5C14.6046 3 15.5 3.89543 15.5 5C15.5 6.10457 14.6046 7 13.5 7H11.5C10.3954 7 9.5 6.10457 9.5 5Z"
            fill="#E1F0EE"
            stroke="#005C65"
            strokeWidth="2"
          />
        </Svg>
        {isHovering && (
          <HoverInfoBox
            toolTipText="View chart summary"
            isTooltipOpen
            styles={{ width: 169, left: -72, top: -47 }}
          />
        )}
      </>
    );
  }

  return (
    <Svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <Path
        d="M3 6C3 4.34315 4.34315 3 6 3H14C15.6569 3 17 4.34315 17 6V15C17 16.6569 15.6569 18 14 18H6C4.34315 18 3 16.6569 3 15V6Z"
        fill="white"
        stroke="#005C65"
        strokeWidth="2"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2124 12.537C9.99612 14.6247 9.70597 14.3641 7.72737 12.4772C5.86879 10.704 6.33956 8.58274 7.69126 8.10565C8.54422 7.80313 9.34475 8.17978 9.86678 8.86015C9.93437 8.94863 10.0695 8.94624 10.1371 8.85776C10.6219 8.22163 11.3466 7.85096 12.1577 8.05782C13.6503 8.43925 14.2004 10.6669 12.2124 12.537Z"
        fill="#005C65"
      />
      <Path
        d="M7 3C7 1.89543 7.89543 1 9 1H11C12.1046 1 13 1.89543 13 3C13 4.10457 12.1046 5 11 5H9C7.89543 5 7 4.10457 7 3Z"
        fill="white"
        stroke="#005C65"
        strokeWidth="2"
      />
    </Svg>
  );
};

export default withHover(QuickCaseMenuBox);
