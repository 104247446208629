import { FunctionComponent } from 'react';
import { EmotionStyle } from '../../core/styled';

const ListItem: FunctionComponent<{
  style?: EmotionStyle;
  className?: string;
}> = ({ children, className, style }) => (
  <li className={className} style={style}>
    {children}
  </li>
);

export default ListItem;
