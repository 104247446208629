import { FunctionComponent } from 'react';
import Svg, { Path } from 'svgs';
import { EmotionStyle, useEmotionTheme } from '../../core/styled';

const ReopenIcon: FunctionComponent<{
  color?: string;
  width?: string | number;
  height?: string | number;
  isHovered?: boolean;
  style?: EmotionStyle;
}> = ({ width = 25, height = 24, color, style, isHovered, ...otherProps }) => {
  const { colors } = useEmotionTheme();
  const titleText = 'Reopen icon';

  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <rect
        x="0.5"
        width={width}
        height={height}
        rx="6"
        fill={isHovered ? colors.aquaSqueeze : 'transparent'}
      />
      <Path
        d="M5.37931 11.0345C4.89203 11.0345 4.5 10.6425 4.5 10.1552V4.87931C4.5 4.39203 4.89203 4 5.37931 4C5.86659 4 6.25862 4.39203 6.25862 4.87931V7.81401L6.99138 6.95302C8.49353 5.15043 10.7614 4 13.2931 4C17.8252 4 21.5 7.67478 21.5 12.2069C21.5 16.739 17.8252 20.4138 13.2931 20.4138C11.4466 20.4138 9.73922 19.8019 8.36897 18.7724C7.9806 18.4793 7.90366 17.9297 8.1931 17.5414C8.48254 17.153 9.03578 17.0761 9.42414 17.3655C10.5013 18.1752 11.8386 18.6552 13.2931 18.6552C16.8543 18.6552 19.7414 15.7681 19.7414 12.2069C19.7414 8.64569 16.8543 5.75862 13.2931 5.75862C11.3037 5.75862 9.52306 6.65991 8.33966 8.08147L8.33599 8.08879L7.32478 9.27586H10.6552C11.1425 9.27586 11.5345 9.66789 11.5345 10.1552C11.5345 10.6425 11.1425 11.0345 10.6552 11.0345H5.37931Z"
        fill={color || colors.accessibilityGreenDark}
      />
    </Svg>
  );
};

export default ReopenIcon;
