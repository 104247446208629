import { FunctionComponent } from 'react';
import Svg, { G, Circle, Path } from 'svgs';
import { EmotionStyle } from '../../core/styled';

interface StarCircleProps {
  color?: string;
  width?: number;
  style?: EmotionStyle;
}

const StarCircle: FunctionComponent<StarCircleProps> = ({ color, width = 22, style }) => {
  const titleText = 'star circle';
  return (
    <Svg title={titleText} aria-label={titleText} width={width} viewBox="0 0 22 22" style={style}>
      <G fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <G transform="translate(-118 -559)">
          <G transform="translate(20 465)">
            <G transform="translate(84 74)">
              <G>
                <G transform="translate(14 20)">
                  <G>
                    <G>
                      <Circle cx="11" cy="11" r="11" fill={color || '#F5A623'} />
                      <Path
                        fill="#FFF"
                        d="M11 14L7.47328849 15.854102 8.14683045 11.927051 5.2936609 9.14589803 9.23664424 8.57294902 11 5 12.7633558 8.57294902 16.7063391 9.14589803 13.8531695 11.927051 14.5267115 15.854102z"
                      />
                    </G>
                  </G>
                </G>
              </G>
            </G>
          </G>
        </G>
      </G>
    </Svg>
  );
};

export default StarCircle;
