import { Tracker, TrackerScope, TsAnalyticsProviderTrackEvent, Target } from '../../types';
import { getAttributes } from './utils';

const MIXPANEL_GENERIC_ACTION_NAME = 'mixpanelEvents';
export interface ProviderTracker extends Tracker {
  providerTrackEvent: TsAnalyticsProviderTrackEvent;
}

const targetsEnum: Record<string, Target> = {
  MIXPANEL: 'mixpanel',
  MIXPANEL_PROVIDER: 'mixpanelProvider',
  BRAZE: 'braze',
};

interface TsAnalyticsTracker extends TrackerScope {
  endpoint: string;
  apiWrapper: any | null;
}

interface TsAnalyticsConfig {
  endpoint: string;
  apiWrapper?: any;
  userID?: number | string | null;
}

interface TrackEventProps extends Record<string, unknown> {
  actionName: string;
  targets?: Target[];
}
const createTracker = (config: TsAnalyticsConfig) => {
  const scope: TsAnalyticsTracker = {
    userID: null,
    calledSetUserID: false,
    ...config,
    apiWrapper: config.apiWrapper || null,
  };

  const init = (apiWrapper?) => {
    if (apiWrapper) {
      scope.apiWrapper = apiWrapper;
    }
  };

  const setUserID = (userID) => {
    scope.userID = userID;
    scope.calledSetUserID = true;
  };
  const completedSetup = () => scope.calledSetUserID;
  const trackEvent = (eventName: string, props: TrackEventProps) => {
    if (!scope.apiWrapper || !scope.endpoint) {
      // eslint-disable-next-line no-console
      console.warn('Tracker module missing config, skipping event.');
      return null;
    }
    const { actionName, targets, ...messageBody } = props;
    const payload = {
      eventName,
      messageAttributes: {
        actionName,
        targets,
      },
      messageBody: {
        ...messageBody,
        ...getAttributes(targets),
      },
    };
    return scope.apiWrapper.post(scope.endpoint, payload).catch((error) => {
      console.warn('[Tracking] - Error in trackEvent', error); // eslint-disable-line no-console
    });
  };
  const providerTrackEvent: TsAnalyticsProviderTrackEvent = (
    eventName,
    attributes,
    messageBody
  ) => {
    if (!scope.apiWrapper || !scope.endpoint) {
      // eslint-disable-next-line no-console
      console.warn('Tracker module missing config, skipping event.');
      return null;
    }
    const payload = {
      eventName,
      messageAttributes: {
        ...attributes,
      },
      messageBody: {
        ...messageBody,
        ...getAttributes(),
      },
    };
    return scope.apiWrapper.post(scope.endpoint, payload).catch((error) => {
      console.warn('[Tracking] - Error in trackEvent', error); // eslint-disable-line no-console
    });
  };

  const trackMPEventGeneric = (eventName: string, props: Omit<TrackEventProps, 'actionName'>) =>
    trackEvent(eventName, {
      targets: [targetsEnum.MIXPANEL_PROVIDER],
      ...props,
      actionName: MIXPANEL_GENERIC_ACTION_NAME,
    });

  const tsAnalyticsApiTracker: ProviderTracker = {
    scope,
    init,
    setUserID,
    trackEvent,
    completedSetup,
    providerTrackEvent,
    trackMPEventGeneric,
    targetsEnum,
  };

  return tsAnalyticsApiTracker;
};

export default createTracker;
