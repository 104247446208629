import { RefObject } from 'react';

import type { EmotionStyle } from './styled';

export const isNative = () => typeof window.document === 'undefined';

export const isWeb = () => !isNative();

/**
 * Returns the given object only if the code is running on a website, otherwise returns an empty object
 * @param   {style} EmotionStyle
 * @returns EmotionStyle
 */
export const webOnlyStyle = (style: EmotionStyle): EmotionStyle => (isWeb() ? style : {});

/**
 * Returns the given object only if the code is running on a react-native app, otherwise returns an empty object
 * @param   {style} EmotionStyle
 * @returns EmotionStyle
 */
export const nativeOnlyStyle = (style: unknown): EmotionStyle =>
  isNative() ? (style as EmotionStyle) : {};

export const addWebAnimation = ({
  ref,
  transform,
  timingFunction = 'cubic-bezier(.57,0,.32,1)',
  durationSeconds = 0.7,
}: {
  ref: RefObject<HTMLDivElement>;
  transform: string;
  timingFunction: string;
  durationSeconds: number;
}) => {
  if (isWeb() && ref.current) {
    ref.current.style.transform = transform;
    ref.current.style.transition = `${durationSeconds}s ${timingFunction}`;
  }
};
