import styled from '../../core/styled';
import { JustifyType, AlignType } from '../../types';

import commonStyles from '../../constants/commonStyles';
import { getFlexDirection, prependFlex } from '../../utils/styleUtils';

const { crossBrowserFocusRing, roundedFocusRing } = commonStyles;

export interface ViewProps extends React.ComponentPropsWithoutRef<'div'> {
  row?: boolean;
  flex?: number;
  justify?: JustifyType;
  align?: AlignType;
  reverse?: boolean;
  alignSelf?: AlignType;
  className?: string;
  as?: string; // TODO: Check why it's not added automatically by emotion
  primaryColor?: string;
  roundedFocusStyle?: boolean;
  wrap?: 'wrap' | 'nowrap' | 'wrap-reverse';
  rowGap?: number;
  columnGap?: number;
  defaultJustify?: JustifyType;
}

const View = styled.div<ViewProps>(
  ({
    flex,
    row,
    reverse,
    justify,
    align,
    wrap,
    rowGap,
    columnGap,
    alignSelf,
    primaryColor,
    roundedFocusStyle,
    defaultJustify = 'initial',
  }) => {
    // Allows for new rounded focus styling if primaryColor and roundedFocusStyle props are specified
    // Applying the styling at the View level ensures that it's in place for TouchableView and also for the rare instances where we need to add mouse and keyboard events to View components (think wrapper for text input)
    // If a View container is being programmatically focused (think modal dialog) make sure to override this focus styling ex: ':focus': {outline: 'none', boxShadow: 'none', border: 'none'}
    const focusStyle =
      primaryColor && roundedFocusStyle
        ? roundedFocusRing(primaryColor, true)
        : crossBrowserFocusRing;
    return {
      fontFamily: 'Roboto',
      display: 'flex',
      flex: flex || 'none',
      flexDirection: getFlexDirection(row, reverse),
      justifyContent: justify ? prependFlex(justify) : defaultJustify,
      alignItems: align ? prependFlex(align) : 'initial',
      alignSelf: alignSelf ? prependFlex(alignSelf) : 'initial',
      flexWrap: wrap,
      rowGap,
      columnGap,
      outline: 'none',
      ':focus': focusStyle,
    };
  }
);

export default View;
