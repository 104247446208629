import { FunctionComponent } from 'react';
import {
  View,
  TouchableView,
  Big,
  Tiny,
  CloseButton,
  DownloadIcon,
  MessageStar,
} from '@talkspace/react-toolkit';
import moment from 'moment';
import styled from '@/core/styled';

const Container = styled(TouchableView)(({ theme: { colors } }) => {
  return {
    backgroundColor: colors.athensGrey,
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    WebkitOverflowScrolling: 'touch',
  };
});

const Header = styled(View)(({ theme: { colors } }) => {
  return {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 12,
    paddingLeft: 20,
    backgroundColor: colors.white,
    borderStyle: 'solid',
    borderColor: colors.extraLightGrey,
    borderWidth: 1,
  };
});

const ActionIconsWrapper = styled(View)({
  flexDirection: 'row',
  justifyContent: 'flex-end',
  padding: 20,
});

const ActionIcons = styled(View)({
  flexDirection: 'row',
  width: 59,
  justifyContent: 'space-between',
});

const Footer = styled(View)(({ theme: { colors } }) => {
  return {
    height: 55,
    display: 'flex',
    borderTop: `${colors.heatherGrey} solid 1px`,
    alignItems: 'center',
    justifyContent: 'center',
  };
});

interface Props {
  url: string;
  fileName: string;
  senderName?: string;
  createdAt?: Date;
  isStarred?: boolean;
  showStar?: boolean;
  onClosePress?: () => void;
  onDownloadPress: () => void;
  onStarPress?: () => void;
  showHeader?: boolean;
  isDownloadOnBottom?: boolean;
  iFrameDataQa?: string;
}

const PDFViewer: FunctionComponent<Props> = ({
  url,
  fileName,
  senderName,
  createdAt,
  isStarred = false,
  showStar = true,
  onClosePress,
  onDownloadPress,
  onStarPress = () => {},
  showHeader = true,
  isDownloadOnBottom = false,
  iFrameDataQa,
}) => (
  <Container>
    {showHeader && (
      <Header>
        <View>
          <Big>{fileName}</Big>
          <Tiny>
            {senderName}, {moment(createdAt).format('MMM DD, hh:mm A')}
          </Tiny>
        </View>
        <CloseButton onPress={onClosePress} style={{ paddingTop: 4 }} />
      </Header>
    )}
    <ActionIconsWrapper>
      <ActionIcons>
        {!isDownloadOnBottom && (
          <TouchableView onPress={onDownloadPress}>
            <DownloadIcon />
          </TouchableView>
        )}
        {showStar && <MessageStar isActive={isStarred} onPress={onStarPress} />}
      </ActionIcons>
    </ActionIconsWrapper>
    <iframe
      data-qa={iFrameDataQa}
      title="pdf-viewer"
      src={`${window.location.origin}/pdf-viewer/index.html?file=${encodeURIComponent(url)}`}
      style={{
        width: '100%',
        height: '100%',
        border: 'none',
      }}
    />
    {isDownloadOnBottom && (
      <Footer>
        <TouchableView onPress={onDownloadPress}>
          <DownloadIcon />
        </TouchableView>
      </Footer>
    )}
  </Container>
);

export default PDFViewer;
