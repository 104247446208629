import Svg, { Path } from 'svgs';
import { FunctionComponent } from 'react';
import { EmotionStyle, useEmotionTheme } from '../../core/styled';

interface StuckAtHomeImaginationProps {
  color?: string;
  iconColor?: string;
  width?: number;
  height?: number;
  style?: EmotionStyle;
}

const StuckAtHomeImagination: FunctionComponent<StuckAtHomeImaginationProps> = ({
  width = 199,
  height = 152,
  style,
  ...otherProps
}) => {
  const titleText = 'Stuck At Home Imagination';
  const { colors } = useEmotionTheme();
  return (
    <Svg
      title={titleText}
      width={width}
      height={height}
      viewBox="0 0 199 152"
      fill="none"
      {...otherProps}
    >
      <g opacity="0.5">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M127.72 17.9824C144.086 17.9824 161.978 23.6614 172.618 36.702C177.533 42.7276 179.995 49.801 180.003 56.8317C180.007 64.7695 176.882 72.6529 170.62 78.9318C164.829 84.7365 157.434 88.6659 149.881 91.8922L149.047 92.2462C148.196 92.6109 147.33 93.02 146.794 93.7609C146.39 94.3142 146.214 94.995 146.061 95.661L146.033 95.7861C144.442 102.777 141.519 108.748 137.618 113.785H137.614C130.665 122.759 120.588 128.761 109.359 132.274C100.761 134.966 91.4825 136.201 82.4087 136.201C67.1457 136.201 53.1357 132.625 42.161 126.658L42.1569 126.654C40.2453 125.621 38.4235 124.508 36.7062 123.331C25.529 115.674 18.6466 105.216 18.6466 93.686C18.6466 71.707 43.6626 53.6219 75.7487 51.4023C79.3257 32.5149 101.229 17.9824 127.72 17.9824ZM57.2981 18.888C62.8681 18.888 67.3828 21.9473 67.3828 25.7209C67.3828 29.4951 62.8681 32.5539 57.2981 32.5539C51.728 32.5539 47.2133 29.4951 47.2133 25.7209C47.2133 21.9473 51.728 18.888 57.2981 18.888Z"
          fill={colors.permaSkeptic}
        />
      </g>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.750977 74.8L176.8 69.6816L159.896 151.025L0.750977 74.8Z"
        fill={colors.permaSlateGrayApprox}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.750977 74.7939L193.153 59.1094L178.239 93.1758L198.243 144.945L0.750977 74.7939Z"
        fill={colors.permaPeriwinkleGray}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.787109 74.7686L178.248 93.1504L198.249 144.915L0.787109 74.7686Z"
        fill={colors.permaCornflowerBlueSolid}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M88.927 32.1277C88.927 32.1277 88.5555 36.9602 91.1505 39.0008C93.5049 40.8522 87.0198 49.1216 81.2885 47.8772C78.1512 47.1959 78.473 38.4274 78.473 38.4274C78.473 38.4274 81.0959 38.2469 80.6292 34.2237L88.927 32.1277Z"
        fill={colors.permaCopper}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M83.6836 36.4409C87.4973 36.4478 90.5128 34.31 91.3958 30.2577C92.467 30.5658 93.0741 27.7508 92.8822 26.799C92.6901 25.8459 91.6302 26.2593 91.6302 26.2593C91.6302 26.2593 91.5743 26.0321 91.5457 25.7876C90.7309 18.8523 86.1001 18.5548 86.1001 18.5548C77.5973 17.9576 78.5179 26.8264 78.7196 29.174C78.9213 31.5219 79.9969 36.4337 83.6836 36.4409Z"
        fill={colors.permaCopper}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M106.983 55.735C106.983 55.735 115.068 72.8675 110.947 75.8066C106.826 78.7459 97.8291 65.8141 97.8291 65.8141C97.8291 65.8141 98.6859 63.2785 101.301 60.478C103.916 57.6774 106.983 55.735 106.983 55.735Z"
        fill={colors.permaCopper}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.898 58.0982L68.8515 58.1294C68.8515 58.1294 65.5379 74.7866 66.0075 74.8442C66.2598 74.8752 71.7357 78.7761 79.9566 79.7855C86.2722 80.5609 94.2755 79.4235 94.6051 79.4459C94.6457 79.4485 94.6881 79.4261 94.7316 79.3803C94.7686 78.2079 94.9368 76.1476 95.1301 73.8733C95.2659 72.2778 95.4133 70.5774 95.5361 69.0049C95.6502 67.542 95.7428 66.1901 95.7839 65.136C95.9637 60.5386 96.7359 56.4179 96.7359 56.4179L97.9829 61.7225L110.369 58.8588C110.369 58.8588 107.118 52.6032 103.015 47.0057C98.9438 41.4517 91.589 39.2665 91.4741 39.2332C91.4735 39.2325 91.4729 39.2325 91.4729 39.2325C91.4729 39.2325 91.0511 46.1534 83.8821 45.8088C77.9213 45.5223 78.4844 38.4047 78.4844 38.4047C78.4844 38.4047 71.3581 39.3594 69.1699 40.8057C66.9816 42.2519 56.8266 52.7139 56.7908 53.0052C56.7551 53.2966 63.898 58.0982 63.898 58.0982Z"
        fill={colors.permaLinkWaterApprox}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M95.5382 68.9941C95.4155 70.5665 95.268 72.267 95.1322 73.8624C95.2779 72.7881 95.4253 71.2356 95.5382 68.9941Z"
        fill={colors.permaLinkWaterApprox}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M71.5868 61.0482L77.7354 62.3154C78.1059 62.3918 78.4684 62.1533 78.5448 61.7827L81.0369 49.6918C81.1132 49.3212 80.8747 48.9588 80.5041 48.8824L74.3556 47.6151C73.985 47.5387 73.6226 47.7772 73.5461 48.1479L71.0541 60.2387C70.9777 60.6094 71.2162 60.9717 71.5868 61.0482Z"
        fill={colors.permaCloudBurst}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M71.0024 60.6305L77.1732 61.9024C77.5376 61.9776 77.894 61.743 77.9692 61.3784L80.3751 49.7059C80.4502 49.3414 80.2156 48.985 79.8511 48.9099L73.6804 47.638C73.3159 47.5629 72.9595 47.7975 72.8844 48.162L70.4785 59.8344C70.4034 60.199 70.638 60.5554 71.0024 60.6305Z"
        fill={colors.permaSlateGrayApprox}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M73.4954 48.9817C73.4232 49.3319 73.6485 49.6741 73.9986 49.7463C74.3487 49.8185 74.691 49.5931 74.7632 49.243C74.8353 48.893 74.61 48.5507 74.2599 48.4785C73.9099 48.4063 73.5675 48.6316 73.4954 48.9817Z"
        fill={colors.permaCloudBurst}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M73.3412 60.4791C73.3412 60.4791 76.3725 61.5404 76.5249 60.4647C76.5249 60.4647 74.5164 59.3263 74.0323 59.3074C73.5482 59.2885 71.3001 59.2759 71.3001 59.2759L74.2022 58.2541L76.8146 59.4261C76.8146 59.4261 77.4286 59.542 77.762 59.1371C78.0955 58.7322 77.8079 58.4334 77.8079 58.4334L74.3986 56.819C74.3986 56.819 74.3254 56.425 75.2288 56.4953C76.1322 56.5657 78.1259 56.6687 78.1259 56.6687C78.1259 56.6687 78.6117 56.3433 78.4955 55.6399C78.3791 54.9366 77.2316 55.0389 77.2316 55.0389C77.2316 55.0389 77.9241 54.5159 77.0666 53.7418C77.0666 53.7418 74.1817 53.4686 73.0426 53.9975C71.9036 54.5265 64.1139 58.0846 64.1139 58.0846L58.84 54.431C58.84 54.431 46.5277 66.2188 51.2543 69.2582C55.9808 72.2976 73.3412 60.4791 73.3412 60.4791Z"
        fill={colors.permaCopper}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.6817 56.1693L73.7094 54.9128L77.2807 54.9679L77.2843 54.7293L73.6883 54.6738C73.672 54.6735 73.6558 54.6766 73.6408 54.6829L70.5902 55.9489L70.6817 56.1693Z"
        fill={colors.black}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.594 60.6927L65.8816 57.2719L65.7801 57.0558L58.4925 60.4766L58.594 60.6927Z"
        fill={colors.black}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M101.619 61.041L107.888 66.0917L108.038 65.9058L101.769 60.8551L101.619 61.041Z"
        fill={colors.black}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.7575 57.4992C70.7768 57.4893 72.6981 56.5063 73.1843 56.3703C73.5552 56.2666 74.2277 56.6215 74.4555 56.767L74.584 56.5658C74.5469 56.5422 73.6687 55.987 73.1201 56.1405C72.6108 56.2828 70.7285 57.2459 70.6487 57.2868L70.7575 57.4992Z"
        fill={colors.black}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M71.9453 52.8887L69.8694 55.3471L71.6598 54.506L71.9453 52.8887Z"
        fill={colors.permaCopper}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69.9135 55.4489L71.7039 54.6078L71.6024 54.3917L69.8121 55.2329L69.9135 55.4489Z"
        fill={colors.black}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M92.3223 64.815L94.4085 65.0297C96.6051 65.2558 98.5691 63.6583 98.7952 61.4617L99.4795 54.813L89.9923 53.8367L89.251 61.039C89.0563 62.9298 90.4314 64.6204 92.3223 64.815Z"
        fill={colors.permaCornflowerBlueSolid}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M99.4081 57.134C99.4081 57.134 100.195 57.7879 100.428 58.1572C100.661 58.5264 100.41 58.5575 100.941 59.273C101.473 59.9885 102.372 61.7399 100.147 63.9748C97.9221 66.2096 97.3078 65.9173 96.5671 65.3929C95.8265 64.8685 95.7724 64.5523 94.2965 64.2162C92.8206 63.8802 91.5667 62.24 91.6422 61.8777L91.7176 61.5154C91.7176 61.5154 90.4087 60.8283 90.5713 60.26C90.734 59.6917 91.123 59.8014 91.123 59.8014C91.123 59.8014 90.8822 59.4931 91.1556 59.0313C91.4291 58.5695 92.4244 58.7846 93.5649 59.0794C94.7052 59.3742 97.6197 60.3513 97.8177 60.2518C98.0156 60.1523 97.9771 59.9618 97.2868 59.7841C96.5965 59.6064 93.7634 59.2276 93.8608 58.4345C93.9582 57.6414 94.831 57.5938 96.4744 57.8266C98.1179 58.0593 98.4341 58.0052 98.8119 58.2065L99.1898 58.4077L99.4081 57.134Z"
        fill={colors.permaCopper}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M91.0984 59.791C91.0984 59.791 91.0185 59.717 91.6406 60.0464C91.8672 60.1663 92.1907 60.3022 92.6519 60.4497C94.38 61.0024 97.0808 61.9534 97.9199 62.1802C98.7591 62.4071 98.035 62.504 96.1315 61.8678C94.228 61.2315 91.9693 60.4142 91.8692 60.3666C91.6989 60.2855 91.1877 60.0424 91.0984 59.791Z"
        fill={colors.black}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M92.3314 61.7744C92.3314 61.7744 93.7132 62.4581 94.402 62.723C95.1333 63.0042 95.6702 63.33 95.8527 63.1047C96.0352 62.8794 96.2257 62.8409 96.2257 62.8409L96.436 63.1454C96.436 63.1454 96.3572 63.444 96.4863 63.4921C96.7166 63.5782 97.1692 63.7308 97.1692 63.7308C97.1692 63.7308 97.1426 63.9011 95.7578 63.4305C95.4142 63.3137 94.7729 63.1292 94.3729 62.9599C94.005 62.8041 92.6718 62.2807 92.5075 62.1057C92.3432 61.9307 92.3314 61.7744 92.3314 61.7744Z"
        fill={colors.black}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M96.8396 62.1705C96.8396 62.1705 96.7112 63.4016 96.3668 63.3397C96.0224 63.2777 95.9004 63.2628 95.8506 63.1637C95.8008 63.0647 95.9908 62.7784 96.032 62.4429C96.0732 62.1074 96.0919 61.9548 96.3627 62.019C96.6335 62.0833 96.8165 62.1058 96.8396 62.1705Z"
        fill={colors.permaLinkWaterApprox}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M79.5822 87.9099C79.5822 87.9099 76.9156 88.6972 76.663 88.2327C76.4104 87.7682 78.7078 90.7441 78.7078 90.7441L80.7301 89.9086L79.5822 87.9099Z"
        fill={colors.permaSeaPink}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M79.7 87.9244L80.9339 90.0728L78.7541 90.9732L78.7124 90.9191L78.7832 90.8645C78.7124 90.9191 78.7124 90.9191 78.7124 90.9191L78.6866 90.8859C78.6699 90.8642 78.6453 90.8325 78.6142 90.7923C78.5519 90.7119 78.4632 90.5975 78.3575 90.4616C78.1462 90.19 77.8671 89.8328 77.5959 89.4901C77.3245 89.1471 77.0617 88.8196 76.8828 88.6071C76.8582 88.5778 76.8354 88.551 76.8146 88.5269C76.7496 88.4988 76.6933 88.4574 76.6598 88.3957M76.92 88.3753C76.8765 88.3257 76.8409 88.287 76.8155 88.2628C76.8051 88.2529 76.793 88.242 76.7808 88.2337C76.7757 88.2302 76.764 88.2227 76.7486 88.2182C76.7362 88.2145 76.6854 88.2039 76.6496 88.249C76.6353 88.267 76.6312 88.2858 76.6301 88.2968C76.6288 88.3081 76.6298 88.3176 76.6307 88.3234C76.6325 88.335 76.636 88.345 76.6384 88.3513C76.6436 88.3649 76.6513 88.3802 76.6598 88.3957M76.92 88.3753C76.9312 88.3789 76.9435 88.3822 76.9569 88.3854C77.043 88.4057 77.159 88.4132 77.2984 88.4091C77.5764 88.4008 77.9276 88.3471 78.2753 88.278C78.622 88.2091 78.9614 88.1257 79.2143 88.0595C79.3407 88.0263 79.4453 87.9975 79.5183 87.977C79.5548 87.9667 79.5833 87.9586 79.6028 87.953C79.6124 87.9502 79.6199 87.948 79.6248 87.9466L79.6304 87.945L79.6322 87.9445C79.6322 87.9444 79.6322 87.9444 79.6575 88.0303L79.6322 87.9445L79.7 87.9244M79.6149 88.1357C79.6008 88.1397 79.5847 88.1443 79.5667 88.1493C79.4929 88.1701 79.3872 88.1992 79.2597 88.2326C79.0047 88.2994 78.6616 88.3838 78.3101 88.4536C77.9596 88.5233 77.5968 88.5793 77.3037 88.588C77.2315 88.5902 77.1622 88.5895 77.0976 88.5851C77.2723 88.7961 77.5016 89.0824 77.7362 89.379C78.0078 89.7222 78.2873 90.0799 78.4988 90.3517C78.6046 90.4877 78.6933 90.6022 78.7557 90.6827C78.7781 90.7116 78.7971 90.7361 78.8122 90.7556L80.6771 89.9852L79.6149 88.1357ZM76.8056 88.2873C76.8056 88.2874 76.8056 88.2873 76.8056 88.2873C76.8054 88.2868 76.8054 88.2868 76.8056 88.2873Z"
        fill={colors.black}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.1329 86.7564C59.1329 86.7564 55.1456 88.4652 57.6887 92.2143C60.2317 95.9635 70.979 94.9299 70.979 94.9299L71.4183 93.6214C71.4183 93.6214 61.5202 87.2353 59.1329 86.7564Z"
        fill={colors.permaEbb}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.1286 86.7594C59.1286 86.7594 57.627 86.8846 56.9635 88.5061C56.3 90.1276 57.0686 92.2052 58.4666 93.0418C58.4666 93.0418 57.0939 88.6225 60.6205 87.3024L59.1286 86.7594Z"
        fill={colors.permaWafer}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.4929 93.6528C59.4929 93.6528 61.163 91.0982 61.7656 91.2341C62.3681 91.37 68.93 92.5237 69.6089 92.4795L71.6893 93.6291L71.1308 94.9782C71.1308 94.9782 62.7547 95.884 59.4929 93.6528Z"
        fill={colors.permaEbbApprox}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.9283 88.7395C61.9283 88.7395 61.0058 92.2179 61.7073 92.304C62.4089 92.3902 63.0788 88.4472 63.0323 89.8349C62.9858 91.2225 62.8638 92.9724 63.385 92.51C63.9062 92.0477 63.4326 88.0882 63.698 89.2044C63.9634 90.3207 63.9603 92.3639 64.6046 92.4121C65.2489 92.4602 64.6153 90.3079 64.9583 90.2881C65.3013 90.2683 65.639 93.0655 66.2598 92.8011C66.8806 92.5367 66.0864 90.1789 66.5953 91.0774C67.1042 91.9759 67.1674 92.9745 67.7315 92.9198C68.2955 92.8653 67.8165 91.7228 67.5446 91.163C67.2728 90.6033 62.3612 87.9875 62.1787 88.2129C61.9962 88.4382 61.9283 88.7395 61.9283 88.7395Z"
        fill={colors.permaAlabaster}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69.3611 93.1899C69.3611 93.1899 57.476 88.1079 52.2995 82.8588C47.1229 77.6097 44.3411 72.7785 48.1849 68.2035C52.0287 63.6284 59.1596 68.3434 62.6215 70.4095C66.0834 72.4756 77.8058 76.2358 83.5345 76.9244C89.2632 77.613 97.0025 78.1298 101.144 77.4308C105.287 76.7318 111.882 75.7149 113.239 80.8046C114.596 85.8943 111.724 90.3719 108.17 91.5766C104.616 92.7813 93.8487 94.989 88.5824 94.0018L83.316 93.0145C83.316 93.0145 81.3391 90.2019 82.4024 86.5859L78.9149 88.5109C78.9149 88.5109 77.0885 88.2557 76.4473 87.9293C75.8062 87.6028 70.9875 96.0833 69.3611 93.1899Z"
        fill={colors.permaBlueStoneApprox}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.5747 94.006C72.5747 94.006 77.6998 96.6478 78.8798 97.6288C80.0598 98.6097 82.6274 99.6371 84.0172 98.9098C85.4069 98.1825 85.1837 93.6964 85.1837 93.6964C85.1837 93.6964 81.0026 92.1922 80.5969 91.4612C80.1913 90.7302 76.5602 88.0241 76.5602 88.0241L72.5747 94.006Z"
        fill={colors.permaSeaPink}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M84.6871 93.9981C84.6871 93.9981 85.0517 93.3185 85.8871 93.5759C86.7224 93.8334 94.7924 93.9262 97.1588 93.5666C99.5251 93.2069 103.817 98.3474 101.319 101.292C98.8214 104.236 96.1411 102.359 93.5746 101.827C91.008 101.295 85.8851 101.409 84.8014 100.905C83.7177 100.4 82.6715 99.5904 82.7384 98.7936C82.8053 97.9968 84.6871 93.9981 84.6871 93.9981Z"
        fill={colors.permaEbb}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M96.5116 93.9101C96.5116 93.9101 99.6779 95.9804 100.007 98.343C100.336 100.706 99.828 102.502 99.828 102.502C99.828 102.502 103.211 101.388 101.827 97.5855C100.443 93.783 97.6137 93.1371 96.5566 93.5436C95.4994 93.9501 96.5116 93.9101 96.5116 93.9101Z"
        fill={colors.permaWafer}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M96.1478 93.784C96.1478 93.784 94.4971 95.8058 94.1193 97.3695C94.1193 97.3695 94.2039 97.6895 92.6824 97.4718C91.161 97.254 84.2422 94.8206 84.2422 94.8206C84.2422 94.8206 82.2628 98.2968 82.6995 99.3108C83.1361 100.325 93.8197 99.8098 93.8197 99.8098C93.8197 99.8098 94.2767 99.6182 94.525 99.8654C94.7733 100.113 95.9506 102.242 97.8472 102.876C97.8472 102.876 95.0189 100.886 95.0371 98.7207C95.0552 96.5555 96.1478 93.784 96.1478 93.784Z"
        fill={colors.permaBizarre}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M85.8262 94.7759C85.8262 94.7759 85.6631 96.8612 85.1242 97.9717C84.5853 99.0822 84.1319 100.053 84.8303 100.012C85.5288 99.9721 86.8265 95.456 86.4786 95.0108C86.1307 94.5655 85.9017 94.4135 85.8262 94.7759Z"
        fill={colors.white}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M86.9229 95.1498C86.9229 95.1498 86.7597 97.2353 86.2209 98.3458C85.682 99.4562 85.2286 100.427 85.927 100.386C86.6255 100.346 87.9231 95.83 87.5752 95.3848C87.2274 94.9396 86.9984 94.7876 86.9229 95.1498Z"
        fill={colors.white}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.9154 95.722C90.9154 95.722 90.774 97.6305 90.2473 98.642C89.7205 99.6535 89.2777 100.538 89.9748 100.508C90.672 100.478 91.9214 96.3553 91.5694 95.9439C91.2174 95.5325 90.9871 95.391 90.9154 95.722Z"
        fill={colors.white}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M92.4122 96.1423C92.4122 96.1423 92.2863 97.9244 91.7682 98.8652C91.2501 99.8062 90.8149 100.629 91.5111 100.607C92.2073 100.585 93.4223 96.7421 93.0673 96.3549C92.7123 95.9676 92.4811 95.8335 92.4122 96.1423Z"
        fill={colors.white}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93.5283 96.1385C93.5283 96.1385 93.6567 97.9204 93.2776 98.9253C92.8984 99.9303 92.5844 100.806 93.2704 100.686C93.9564 100.565 94.6133 96.5887 94.207 96.2559C93.8006 95.923 93.5526 95.823 93.5283 96.1385Z"
        fill={colors.white}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M88.332 95.4623C88.332 95.4623 87.9564 97.5201 87.3068 98.5696C86.6572 99.6191 86.1068 100.538 86.8058 100.57C87.5047 100.601 89.2574 96.2411 88.9569 95.7627C88.6564 95.2842 88.4441 95.1096 88.332 95.4623Z"
        fill={colors.white}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M88.9284 95.4904C88.9284 95.4904 89.0908 97.5759 88.7308 98.7565C88.3707 99.9371 88.0733 100.966 88.7571 100.818C89.4408 100.67 90.022 96.0071 89.6093 95.6213C89.1966 95.2354 88.9468 95.1208 88.9284 95.4904Z"
        fill={colors.white}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M84.3932 94.6943C84.3932 94.6943 86.2588 95.3481 85.5776 97.6176C84.8964 99.8871 82.5678 98.9381 82.5678 98.9381C82.5678 98.9381 82.465 95.0547 84.3932 94.6943Z"
        fill={colors.white}
      />
      <Path
        d="M59.8938 71.1108L75.1003 86.4595L78.5569 88.4423L82.7117 86.5121L96.9949 79.7545L97.097 79.9702L82.813 86.7282L78.5454 88.7108L74.9536 86.6504L59.7242 71.2788L59.8938 71.1108Z"
        fill={colors.black}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M83.2826 85.2891C83.2826 85.2891 80.4437 85.7146 79.5201 86.9326C78.5964 88.1507 78.5633 90.4378 80.7045 91.6606C82.8456 92.8833 83.6633 93.7888 83.2673 92.223C82.8712 90.6572 82.1407 87.7807 83.2826 85.2891Z"
        fill={colors.permaTalkspaceDarkGreen}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75.0648 86.0495C75.0648 86.0495 74.4157 89.0661 72.4459 91.2394C70.4762 93.4127 68.9515 93.4731 69.4294 94.1201C69.9073 94.7671 70.7277 95.1465 71.1869 95.9461C71.646 96.7455 71.6193 96.7112 73.0877 95.3434C74.5561 93.9755 77.7781 90.9343 78.2099 87.922C78.2099 87.922 75.4324 85.0729 75.0648 86.0495Z"
        fill={colors.permaTalkspaceDarkGreen}
      />
      <Path
        d="M81.884 27.4034C81.8262 27.3281 81.7204 27.3114 81.6426 27.3634L81.6069 27.3893C81.5834 27.4059 81.556 27.4245 81.5249 27.4445C81.4355 27.5021 81.336 27.5585 81.2296 27.6093C80.939 27.7481 80.6543 27.8181 80.4013 27.7913C80.1452 27.7641 79.9191 27.6518 79.7251 27.483C79.6581 27.4246 79.6001 27.3638 79.5515 27.3045L79.5358 27.2849C79.5237 27.2696 79.5141 27.2569 79.5072 27.2471L79.4975 27.233C79.4442 27.1498 79.3335 27.1256 79.2503 27.179C79.1671 27.2324 79.1429 27.3431 79.1963 27.4263L79.2065 27.4417C79.2219 27.4642 79.2446 27.4947 79.2745 27.5313C79.3353 27.6055 79.4072 27.6808 79.4901 27.753C79.7357 27.9667 80.0274 28.1115 80.3635 28.1472C80.6929 28.1822 81.0388 28.0971 81.3838 27.9324C81.5048 27.8746 81.6172 27.8108 81.7187 27.7455L81.7481 27.7264C81.7947 27.6956 81.8295 27.6707 81.8509 27.6543C81.9293 27.5942 81.9441 27.4818 81.884 27.4034Z"
        fill={colors.black}
      />
      <Path
        d="M85.2316 32.3916C85.1918 32.3051 85.0918 32.2654 85.0043 32.2996L84.9604 32.3183L84.9281 32.3314C84.9053 32.3403 84.8801 32.3499 84.8526 32.3598C84.7336 32.4029 84.5979 32.4441 84.4483 32.4798C83.8922 32.6128 83.3083 32.6352 82.7382 32.4938C82.6423 32.47 82.5452 32.5285 82.5214 32.6245C82.4976 32.7204 82.5561 32.8175 82.652 32.8413C83.2836 32.9979 83.9238 32.9733 84.5316 32.828C84.6947 32.789 84.8432 32.7439 84.9744 32.6964L85.0227 32.6786C85.0778 32.6577 85.1186 32.6406 85.1439 32.629C85.2337 32.5877 85.273 32.4814 85.2316 32.3916Z"
        fill={colors.black}
      />
      <Path
        d="M82.37 26.2479L82.1355 26.2925L82.1489 26.3659C82.1596 26.4257 82.1712 26.4941 82.1835 26.5698C82.2187 26.7864 82.2521 27.0184 82.2805 27.2565C82.3122 27.5212 82.3357 27.777 82.3491 28.0175L82.3529 28.0886C82.3823 28.6996 82.3407 29.1582 82.2186 29.3926L82.2115 29.4059C81.5261 30.6419 82.159 31.3891 83.7142 31.1113L83.7616 31.1026L83.7171 30.8681C82.3142 31.1343 81.8327 30.5812 82.4202 29.5216C82.582 29.23 82.6268 28.7068 82.5874 28.0041C82.5737 27.7583 82.5497 27.4976 82.5175 27.2282C82.4918 27.0134 82.4623 26.8036 82.4309 26.6053L82.4191 26.5315C82.412 26.4876 82.405 26.446 82.3983 26.4071L82.3811 26.3085L82.37 26.2479Z"
        fill={colors.black}
      />
      <Path
        d="M86.9325 25.546C86.755 25.4994 86.5883 25.4707 86.4334 25.4632C86.2154 25.4527 85.9221 25.4756 85.5653 25.5243C85.4561 25.5392 85.3426 25.5563 85.2256 25.5754C85.0291 25.6075 84.8323 25.6434 84.6443 25.6804L84.5207 25.7052L84.3978 25.7309C84.2207 25.7693 84.1083 25.944 84.1467 26.1211C84.1842 26.2938 84.3512 26.405 84.5237 26.3748L84.5415 26.3712L84.599 26.3591C84.6499 26.3485 84.7077 26.3368 84.7711 26.3243C84.9525 26.2886 85.1424 26.2539 85.3312 26.2231C85.4428 26.2049 85.5508 26.1886 85.654 26.1745L85.7217 26.1655C86.0106 26.1281 86.2455 26.1112 86.4017 26.1188C86.5066 26.1238 86.6296 26.145 86.766 26.1808C86.93 26.2238 87.1063 26.2859 87.2861 26.3611C87.3755 26.3984 87.4608 26.4369 87.5399 26.4749L87.5789 26.4938C87.6038 26.506 87.6265 26.5174 87.6468 26.5278L87.6881 26.5493C87.8475 26.6355 88.0466 26.5761 88.1328 26.4167C88.219 26.2573 88.1597 26.0582 88.0003 25.972L87.968 25.9549C87.9423 25.9416 87.9085 25.9245 87.8676 25.9044C87.7667 25.855 87.6562 25.8045 87.5392 25.7556C87.3333 25.6695 87.1291 25.5975 86.9325 25.546Z"
        fill={colors.black}
      />
      <Path
        d="M81.0598 25.7817L80.9816 25.77C80.6603 25.7232 80.3946 25.7008 80.1942 25.7095C80.0836 25.7143 79.967 25.7308 79.8446 25.7576C79.6628 25.7974 79.4714 25.859 79.2744 25.9373C79.1487 25.9873 79.0269 26.0415 78.912 26.0972L78.8632 26.1211C78.7852 26.1598 78.7269 26.1908 78.693 26.2098C78.5388 26.2963 78.4839 26.4916 78.5704 26.6459C78.6547 26.796 78.8418 26.852 78.9939 26.7752L79.0416 26.7495L79.081 26.7289C79.1018 26.7182 79.1241 26.7068 79.148 26.695C79.262 26.6384 79.3849 26.5828 79.511 26.5327C79.6779 26.4663 79.8373 26.415 79.9815 26.3834C80.07 26.3641 80.1508 26.3526 80.222 26.3495C80.3864 26.3424 80.6443 26.3661 80.9627 26.415C81.1568 26.4448 81.3645 26.4824 81.5756 26.5249L81.6671 26.5435C81.727 26.5559 81.7846 26.5681 81.8392 26.58L81.9959 26.6151C82.1682 26.6554 82.3405 26.5485 82.3809 26.3763C82.4202 26.2083 82.3196 26.0403 82.1548 25.9946L82.1085 25.9836L82.0376 25.9677L82.0176 25.9633C81.9208 25.9419 81.8145 25.9194 81.7019 25.8968C81.4818 25.8525 81.2647 25.8132 81.0598 25.7817Z"
        fill={colors.black}
      />
      <Path
        d="M87.1037 27.4512C87.0459 27.3759 86.9401 27.3592 86.8623 27.4112L86.8266 27.4371L86.7966 27.458C86.7806 27.4689 86.7633 27.4804 86.7446 27.4924C86.6552 27.5499 86.5557 27.6064 86.4493 27.6572C86.1587 27.7959 85.874 27.866 85.6211 27.8391C85.365 27.8119 85.1389 27.6996 84.9449 27.5308C84.8779 27.4725 84.8198 27.4117 84.7712 27.3523L84.7485 27.3239C84.7375 27.3096 84.729 27.298 84.7231 27.2895L84.7173 27.2808C84.6639 27.1976 84.5532 27.1735 84.47 27.2269C84.3868 27.2803 84.3626 27.391 84.416 27.4742L84.4262 27.4895C84.4416 27.512 84.4643 27.5426 84.4942 27.5791C84.555 27.6533 84.6269 27.7286 84.7099 27.8009C84.9554 28.0145 85.2471 28.1593 85.5832 28.1951C85.9126 28.23 86.2584 28.145 86.6035 27.9802C86.7245 27.9225 86.8369 27.8587 86.9384 27.7934L86.9678 27.7742C87.0144 27.7434 87.0492 27.7186 87.0706 27.7022C87.149 27.642 87.1639 27.5297 87.1037 27.4512Z"
        fill={colors.black}
      />
      <Path
        d="M87.1114 36.0249L87.0208 35.8041L86.9757 35.822L86.9344 35.8378C86.8719 35.8615 86.8008 35.8874 86.722 35.9148L86.6878 35.9267C86.4228 36.0177 86.1315 36.1057 85.8227 36.1831C85.1127 36.3612 84.4209 36.4574 83.7906 36.4388C83.505 36.4303 83.2372 36.3981 82.9899 36.3404L82.9407 36.3286L82.8828 36.5601C83.1613 36.6296 83.4625 36.6678 83.7835 36.6773C84.4386 36.6967 85.1512 36.5976 85.8808 36.4146C86.1965 36.3353 86.4943 36.2455 86.7653 36.1524L86.8679 36.1164C86.9116 36.1009 86.9526 36.0859 86.9909 36.0715L87.0622 36.0444L87.1114 36.0249Z"
        fill={colors.black}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M104.935 8.96794C100.974 11.3668 96.775 16.6819 90.7254 15.0375C84.6759 13.393 76.1618 16.0398 78.5762 26.5422C78.5762 26.5422 79.1055 21.0644 82.4175 19.5475C85.7295 18.0306 85.5074 28.162 91.6825 26.3355C91.6825 26.3355 92.6954 25.9188 92.8305 26.777C92.9656 27.6351 92.5321 29.824 91.8492 30.1819C91.8492 30.1819 91.6764 30.302 91.3948 30.1472C91.3948 30.1472 90.8726 32.937 89.0014 34.4661C89.0014 34.4661 89.2468 35.8947 89.3692 36.12L89.4724 36.1573C89.4724 36.1573 94.6011 41.2404 101.37 37.0224C108.14 32.8043 111.76 37.0959 117.302 33.0877C122.843 29.0795 112.606 13.6808 116.076 10.7567C112.892 6.33832 106.875 7.79313 104.935 8.96794Z"
        fill={colors.black}
      />
    </Svg>
  );
};

export default StuckAtHomeImagination;
