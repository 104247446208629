import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { View, Button, TooltipV2, Reminder, useEmotionTheme } from '@talkspace/react-toolkit';
import useQueryNotes from 'hooks/notes/useQueryNotes';
import useQueryBusinessLine from 'ts-frontend/hooks/useQueryBusinessLine';
import useQueryRoomDetails from 'ts-frontend/hooks/useQueryRoomDetails';
import useQueryProgressNoteHelperData from 'hooks/notes/useQueryProgressNoteHelperData';
import { useHistory, useRouteMatch } from '@/core/routerLib';
import styled from '@/core/styled';
import useNotesRelevantVideoCalls from './hooks/useNotesRelevantVideoCalls';
import useMessagingDate from './hooks/useMessagingDate';

const getDisabledTooltipText = ({
  isClientDischarged,
  isEAP,
}: {
  isClientDischarged: boolean;
  isEAP: boolean;
}) => {
  if (isClientDischarged) {
    return 'Client is discharged';
  }
  if (isEAP) {
    return 'Select a completed EAP session below to create or edit its session summary note';
  }
  return null;
};

const ButtonV2 = styled(Button)(({ theme: { colors } }) => {
  return {
    width: '100%',
    marginBottom: 15,
    backgroundColor: colors.accessibilityGreenDark,
    fontWeight: 700,
  };
});

const NotesCreationMenuV2Container = styled(View)({
  width: '100%',
  maxWidth: 344,
  alignItems: 'center',
});

const ButtonContentWrapper = styled(View)({
  flex: 1,
  justifyContent: 'center',
  flexDirection: 'row',
});

interface ReminderProps {
  reminderText: string;
  isReminderLabel: boolean;
}

const NotesCreationMenu: FunctionComponent = () => {
  const [warningMessage, setWarningMessage] = useState<ReminderProps | null>(null);
  const match = useRouteMatch<{ roomID: string }>();
  const history = useHistory();
  const { data: { clientUserID } = {} } = useQueryRoomDetails(match.params.roomID);
  const { data: businessLine } = useQueryBusinessLine(match.params.roomID, clientUserID);
  const { data: notes } = useQueryNotes(match.params.roomID);
  const { data: helperData } = useQueryProgressNoteHelperData(match.params.roomID, true);
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);

  const { colors } = useEmotionTheme();

  const tooltipV2ContainerStyle = useMemo(() => {
    return {
      fontSize: '14px',
      font: 'roboto',
      lineHeight: '18px',
      fontWeight: 400,
      textAlign: 'left',
      letterSpacing: 0.1,
      top: '40px',
      right: '-68px',
      width: '344px',
      color: colors.TSBlack,
    };
  }, [colors]);

  const setTooltipOpen = () => {
    setIsTooltipOpen(true);
  };

  const setTooltipClosed = () => {
    setIsTooltipOpen(false);
  };

  const progressNoteURL =
    notes?.currentSessionReport?.progressNoteID &&
    notes.items.filter((it) => it.noteID === notes?.currentSessionReport?.progressNoteID).length > 0
      ? `progress-notes/${notes.currentSessionReport.progressNoteID}/edit`
      : 'progress-notes/new';

  const formMode = progressNoteURL.includes('new') ? 'create' : 'edit';

  const { disableLVSComponents } = useNotesRelevantVideoCalls({ formMode, helperData });

  const { isMissingMessagingSession } = useMessagingDate({
    formMode,
    businessLine,
    helperData,
    modalityID: notes?.currentSessionReport?.modalityID,
  });

  const isLiveSessionDisabled = disableLVSComponents || disableLVSComponents === null;

  const sessionSummaryNoteDisabled =
    !!notes?.isClientDischarged ||
    (businessLine?.isEAP && !notes?.currentSessionReport?.reopenedAt) ||
    (businessLine?.isBH && notes?.currentSessionReport?.status === 'awaiting') ||
    (businessLine?.isBH && notes?.currentSessionReport === null) ||
    (isLiveSessionDisabled && isMissingMessagingSession);

  const getProgressNoteTitle = (): string => {
    let title = 'Submit a progress note';

    if (businessLine) {
      const { isBH, isEAP, isPsychiatry } = businessLine;
      if (sessionSummaryNoteDisabled) {
        title = 'Session summary note';
      } else if (isEAP || isBH) {
        title = `Submit session ${notes?.currentSessionReport?.position} summary note`;
      } else if (isPsychiatry) {
        title = 'Psychiatric progress note';
      }
    }

    return title;
  };

  useEffect(() => {
    let reminderText: string | null = null;
    let isReminderLabel: boolean = true;
    if (sessionSummaryNoteDisabled) {
      reminderText =
        'No live or async messaging sessions have occurred that are missing a progress note.';
      isReminderLabel = false;
    }

    setWarningMessage(reminderText ? { reminderText, isReminderLabel } : null);
  }, [sessionSummaryNoteDisabled]);

  const tooltipText = getDisabledTooltipText({
    isEAP: !!businessLine?.isEAP,
    isClientDischarged: !!notes?.isClientDischarged,
  });

  return (
    <NotesCreationMenuV2Container>
      {warningMessage && notes?.isClientDischarged && !businessLine?.isEAP && (
        <View style={{ width: '100%' }}>
          <Reminder
            {...warningMessage}
            {...{
              linkText: 'Restart care',
              onClick: () => history.push(`/room/${match.params.roomID}/restart-care`),
            }}
          />
        </View>
      )}

      <ButtonV2
        onMouseEnter={setTooltipOpen}
        onMouseLeave={setTooltipClosed}
        stretch
        style={{
          marginTop: 10,
          marginBottom: 25,
          ...(sessionSummaryNoteDisabled ? { backgroundColor: colors.darkBlue } : {}),
        }}
        onClick={
          sessionSummaryNoteDisabled
            ? undefined
            : () => {
                const base = match.url.replace('/notes-tab', '');
                history.push(`${base}/${progressNoteURL}`);
              }
        }
      >
        <ButtonContentWrapper>
          <View style={{ marginRight: '10px' }}>{getProgressNoteTitle()}</View>
          {sessionSummaryNoteDisabled && tooltipText && (
            <TooltipV2
              hoverColor={colors.white}
              circleColor={colors.white}
              questionMarkColor={colors.darkBlue}
              containerStyle={tooltipV2ContainerStyle}
              isTooltipOpen={isTooltipOpen}
              toolTipText={tooltipText}
            />
          )}
        </ButtonContentWrapper>
      </ButtonV2>
    </NotesCreationMenuV2Container>
  );
};

export default NotesCreationMenu;
