import { styled, TouchableView, withHover, WithHoverProps } from '@talkspace/react-toolkit';
import { webOnlyStyle } from '@/core/styled/styleHelpers';

const MenuNavLink = styled(TouchableView)<WithHoverProps>(
  ({
    theme: {
      window: { isMobile },
      colorRoles: {
        surfaces: { neutralHovered },
      },
      spacing,
    },
    isHovering,
  }) => {
    return {
      justifyContent: 'flex-start',
      alignItems: 'center',
      height: isMobile ? 48 : 40,
      borderRadius: 5,
      marginLeft: spacing('space200'),
      marginRight: spacing('space200'),
      gap: spacing('space100'),
      backgroundColor: isHovering ? neutralHovered : 'transparent',
      ...webOnlyStyle({
        display: 'flex',
      }),
    };
  }
);

export default withHover(MenuNavLink);
