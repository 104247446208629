import { Path, Svg } from 'svgs';
import { useEmotionTheme } from '../../core/styled';

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

const VerticalLine = ({ width = 2, height = 16, color }: Props) => {
  const { colors } = useEmotionTheme();
  return (
    <Svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path d="M1 0V16" stroke={colors.blueHaze || color} />
    </Svg>
  );
};

export default VerticalLine;
