import styled from '../../core/styled';
import commonStyles, { COLORS } from '../../constants/commonStyles';
import Lock from '../Svgs/Lock';
import ErrorIcon from '../Svgs/ErrorIcon';
import './textInputStyles.css';
import Text from '../Text';
import View from '../View';

const { regular } = commonStyles.general.text;

const TextInputWrapper = styled.div({
  marginTop: 11,
  position: 'relative',
  width: '100%',
  boxSizing: 'content-box',
  borderWidth: 0,
  borderBottomWidth: 1,
  borderColor: COLORS.extraLightGrey,
  borderStyle: 'solid',
});

const Placeholder = styled.div({
  ...regular,
  position: 'absolute',
  pointerEvents: 'none',
  top: 2,
  fontSize: 12,
  color: COLORS.placeholderGrey,
});

const PlaceholderDown = styled.div({
  ...regular,
  height: 26,
  fontSize: 15,
  marginTop: 14,
  color: COLORS.placeholderGrey,
});

const FakeTextInput = styled(Text)(({ numberOfLines }) => {
  return {
    ...regular,
    width: '100%',
    marginTop: 24,
    marginBottom: 7,
    WebkitLineClamp: numberOfLines,
    WebkitBoxOrient: 'vertical',
    display: '-webkit-box',
    overflow: 'hidden',
    cursor: 'default',
  };
});

const LockIconWrapper = styled.div({
  position: 'absolute',
  right: 0,
  top: 0,
  display: 'none',
});

function TextInputMultiLineDisabled({
  placeholder,
  disabledNumberOfLines = 4,
  isValid,
  hidden,
  value,
  style = {},
}) {
  if (!value) {
    return (
      <TextInputWrapper hidden={hidden} className="has-lock-icon">
        <PlaceholderDown>{placeholder}</PlaceholderDown>
        <LockIconWrapper className="lock-icon" style={{ top: 14 }}>
          <Lock color={COLORS.placeholderGrey} />
        </LockIconWrapper>
      </TextInputWrapper>
    );
  }
  return (
    <TextInputWrapper hidden={hidden} className="has-lock-icon">
      <View row>
        <Placeholder>{placeholder}</Placeholder>
        <LockIconWrapper className="lock-icon">
          <Lock color={COLORS.placeholderGrey} />
        </LockIconWrapper>
      </View>
      <FakeTextInput style={style} numberOfLines={disabledNumberOfLines}>
        {value}
      </FakeTextInput>
      <ErrorIcon isValid={isValid} />
    </TextInputWrapper>
  );
}

export default TextInputMultiLineDisabled;
