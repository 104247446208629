// Copied these components from client-web's ActionStatus, FailureX, and SuccessCheckmarkV2
// Only change was that we assume the prop of `isV2CheckMark` is always true.
import { FunctionComponent } from 'react';
import {
  View,
  Text,
  Spinner,
  Large,
  Huge,
  BaseButton,
  Button,
  useEmotionTheme,
  Big,
  ExtraHuge,
} from '@talkspace/react-toolkit';
import Svg, { Path, Defs, G, Use } from 'svgs';
import styled, { EmotionStyle } from '@/core/styled';

// Copied it instead of moving it to toolkit due to these icons having different versions on client-web and react-toolkit.
// Instead of making yet another copy of them, I decided to keep them inside this file so that they don't show up in file searches.
const FailureX = ({ ...props }) => {
  const { colors } = useEmotionTheme();
  return (
    <Svg width="74" height="74" viewBox="0 0 74 74" {...props}>
      <Defs>
        <Path
          id="b"
          d="M0 10.003C0 4.48 4.475 0 10.002 0h479.996C495.522 0 500 4.48 500 10.003v345.994c0 5.524-4.475 10.003-10.002 10.003H10.002C4.478 366 0 361.52 0 355.997V10.003z"
        />
        <filter
          id="a"
          width="104.4%"
          height="106%"
          x="-2.2%"
          y="-1.9%"
          filterUnits="objectBoundingBox"
        >
          <feMorphology in="SourceAlpha" radius="2" result="shadowSpreadOuter1" />
          <feOffset dy="4" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="5" />
          <feComposite
            in="shadowBlurOuter1"
            in2="SourceAlpha"
            operator="out"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0 0 0 0 0 0.156418748 0 0 0 0 0.255632926 0 0 0 0.4 0"
          />
        </filter>
      </Defs>
      <G
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeWidth="1"
        transform="translate(-563 -319)"
      >
        <Path fill={colors.fordGrey} d="M0 0H1200V850H0z" />
        <G transform="translate(350 242)">
          <Use fill={colors.black} xlinkHref="#b" href="#b" />
          <Path
            fill={colors.white}
            stroke={colors.a11yLinkWaterGrey}
            strokeLinejoin="round"
            d="M.5 10.003v345.994c0 5.248 4.254 9.503 9.502 9.503h479.996a9.5 9.5 0 009.502-9.503V10.003c0-5.248-4.254-9.503-9.502-9.503H10.002A9.5 9.5 0 00.5 10.003z"
          />
        </G>
        <Path
          stroke={colors.heartRed}
          strokeWidth="4.4"
          d="M54.387 6.839C39.412-4.446 18.124-1.454 6.84 13.52-4.446 28.497-1.454 49.785 13.52 61.07c14.976 11.285 36.264 8.293 47.549-6.683 11.285-14.975 8.293-36.263-6.683-47.548h0z"
          transform="translate(423 322) translate(143)"
        />
        <Path
          fill={colors.heartRed}
          d="M37.05 33.952l12.391 12.392a2.19 2.19 0 11-3.097 3.097l-12.392-12.39-12.39 12.39a2.19 2.19 0 11-3.099-3.097l12.392-12.392-12.392-12.39a2.19 2.19 0 113.098-3.099l12.391 12.392 12.392-12.392a2.19 2.19 0 113.097 3.098l-12.39 12.391z"
          transform="translate(423 322) translate(143)"
        />
      </G>
    </Svg>
  );
};

const SuccessCheckmarkV2 = ({ width = 145, height = 145, ...props }) => {
  const { colors } = useEmotionTheme();
  return (
    <Svg width={width} height={height} viewBox="0 0 145 145" {...props}>
      <Defs>
        <circle id="b" cx={51.5} cy={51.5} r={51.5} />
        <filter
          x="-35.9%"
          y="-28.2%"
          width="171.8%"
          height="171.8%"
          filterUnits="objectBoundingBox"
          id="a"
        >
          <feMorphology radius={1} in="SourceAlpha" result="shadowSpreadOuter1" />
          <feOffset dy={8} in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
          <feGaussianBlur stdDeviation={12} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
          <feColorMatrix
            values="0 0 0 0 0 0 0 0 0 0.117465042 0 0 0 0 0.264296344 0 0 0 0.201737998 0"
            in="shadowBlurOuter1"
          />
        </filter>
      </Defs>
      <G
        transform="translate(-527 -150) translate(440 163) translate(108)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <use fill={colors.trueBlack} filter="url(#a)" xlinkHref="#b" />
        <use fill={colors.white} xlinkHref="#b" />
        <Path
          stroke={colors.green}
          strokeWidth={8}
          strokeLinecap="round"
          d="M33 51.9762995L46.2055945 65.952599 69.4641766 38"
        />
      </G>
    </Svg>
  );
};

const Wrapper = styled(View)(({ theme: { window: isMobile } }) => {
  return {
    marginTop: 80,
    alignSelf: 'center',
    alignItems: 'center',
    maxWidth: isMobile ? 335 : 600,
  };
});

const ResultWrapper = styled(View)({
  alignSelf: 'center',
  alignItems: 'center',
});

const CloseBaseButton = styled(BaseButton)(({ theme: { colors } }) => {
  return {
    marginTop: 40,
    marginBottom: 60,
    backgroundColor: colors.white,
  };
});

const CloseButton = styled(Button)(({ theme: { colors } }) => {
  return {
    marginTop: 40,
    marginBottom: 60,
    backgroundColor: colors.green,
  };
});

const SpinnerWrapper = styled(View)({
  minWidth: 63,
});

export type TitleSize = typeof Big | typeof Huge | typeof ExtraHuge;

interface Props {
  isLoading: boolean;
  isError: boolean;
  errorTitle?: string;
  errorSubTitle?: string;
  successTitle?: string | JSX.Element;
  titleSize?: TitleSize;
  successSubTitleHeadline?: string;
  successSubTitle?: string | JSX.Element;
  successButtonText?: string;
  errorButtonText?: string;
  errorButtonDataQa?: string;
  errorPrimaryButtonText?: string;
  successButtonAction?: () => void;
  successButtonDataQa?: string;
  errorButtonAction?: () => void;
  errorPrimaryButtonAction?: () => void;
  spinnerStyles?: {};
  spinnerPrimaryColor?: string;
  spinnerThickness?: number;
  titleStyles?: {};
  subTitleHeadlineStyles?: {};
  subTitleStyles?: {};
  showSuccessState?: boolean;
  successCheckMarkExist?: boolean;
  buttonStyles?: {};
  isBaseButton?: boolean;
  style?: EmotionStyle;
}

const DynamicTitle: FunctionComponent<{
  style?: {};
  TitleSize?: TitleSize;
}> = ({ style, TitleSize = Huge, children }) => <TitleSize style={style}>{children}</TitleSize>;

const ActionStatus: FunctionComponent<Props> = ({
  isLoading,
  isError,
  errorTitle = 'Something went wrong',
  errorSubTitle,
  successTitle = 'Success!',
  titleSize,
  successSubTitleHeadline,
  successSubTitle,
  successButtonText,
  errorButtonText = 'Close',
  errorButtonDataQa,
  errorPrimaryButtonText,
  successButtonAction,
  successButtonDataQa,
  errorButtonAction,
  errorPrimaryButtonAction,
  spinnerStyles,
  spinnerPrimaryColor,
  spinnerThickness,
  titleStyles,
  subTitleHeadlineStyles,
  subTitleStyles,
  successCheckMarkExist = true,
  showSuccessState = true,
  buttonStyles,
  isBaseButton = true,
  style,
}) => (
  <Wrapper style={style}>
    {(() => {
      if (isLoading)
        return (
          <SpinnerWrapper>
            <Spinner
              primaryColor={spinnerPrimaryColor}
              style={spinnerStyles}
              thickness={spinnerThickness}
            />
          </SpinnerWrapper>
        );
      if (isError)
        return (
          <ResultWrapper>
            <FailureX />
            <Text style={titleStyles}>{errorTitle}</Text>
            {errorSubTitle && <Text style={subTitleStyles}>{errorSubTitle}</Text>}
            {errorPrimaryButtonAction && (
              <CloseButton onPress={errorPrimaryButtonAction} style={buttonStyles}>
                <Large variant="largeWhite">{errorPrimaryButtonText}</Large>
              </CloseButton>
            )}
            {errorButtonAction &&
              (isBaseButton ? (
                <CloseBaseButton
                  onPress={errorButtonAction}
                  style={{ ...buttonStyles, backgroundColor: 'none' }}
                  dataQa={errorButtonDataQa}
                >
                  <Large variant="largeBoldWideGreen">{errorButtonText}</Large>
                </CloseBaseButton>
              ) : (
                <CloseButton
                  onPress={errorButtonAction}
                  style={buttonStyles}
                  dataQa={errorButtonDataQa}
                >
                  <Large variant="largeBoldWhite">{errorButtonText}</Large>
                </CloseButton>
              ))}
          </ResultWrapper>
        );
      if (showSuccessState) {
        return (
          <ResultWrapper>
            {successCheckMarkExist && <SuccessCheckmarkV2 />}
            <DynamicTitle TitleSize={titleSize} style={titleStyles}>
              {successTitle}
            </DynamicTitle>
            {successSubTitleHeadline && (
              <Text style={subTitleHeadlineStyles}>{successSubTitleHeadline}</Text>
            )}
            {successSubTitle && <Text style={subTitleStyles}>{successSubTitle}</Text>}
            {successButtonAction &&
              (isBaseButton ? (
                <CloseBaseButton onPress={successButtonAction} dataQa={successButtonDataQa}>
                  <Large variant="largeBoldWideGreen" style={buttonStyles}>
                    {successButtonText}
                  </Large>
                </CloseBaseButton>
              ) : (
                <CloseButton
                  onPress={successButtonAction}
                  style={buttonStyles}
                  dataQa={successButtonDataQa}
                >
                  <Large variant="largeBoldWhite">{successButtonText}</Large>
                </CloseButton>
              ))}
          </ResultWrapper>
        );
      }
      return <></>;
    })()}
  </Wrapper>
);

export default ActionStatus;

// END Copied from client-web's ActionStatus
