import Svg, { Path } from 'svgs';
import View from '../View';
import styled, { EmotionStyle, useEmotionTheme } from '../../core/styled';
import Text from '../Text';
import { webOnlyStyle } from '../../core/styleHelpers';

interface ProgressIndicatorProps {
  size?: number;
  strokeWidth?: number;
  color?: string;
  secondaryColor?: string;
  numberStyle?: EmotionStyle;
  containerStyle?: EmotionStyle;
  progressDecimal: number;
  progressDisplay: string | number;
}

const circlePath = 'M18 2.084a15.915 15.915 0 010 31.831 15.915 15.915 0 010-31.83';

const AnimatedPath = styled(Path, {
  shouldForwardProp: (propName) => propName !== 'progressDecimal',
})<{
  progressDecimal: number;
}>(({ progressDecimal = 0 }) => {
  const strokeDasharray = 100 + progressDecimal * 100;
  return {
    strokeDasharray,
    ...webOnlyStyle({
      transition: 'all .3s linear',
    }),
  };
});

const ProgressCircle = ({
  progressDecimal = 0,
  size,
  strokeWidth,
  color,
  secondaryColor,
}: Partial<ProgressIndicatorProps>) => {
  const titleText = 'progress indicator';
  return (
    <Svg width={size} height={size} title={titleText} aria-label={titleText} viewBox="0 0 36 36">
      <Path
        d={circlePath}
        fill="none"
        stroke={secondaryColor}
        strokeWidth={strokeWidth}
        strokeDasharray={200}
        strokeDashoffset={100}
        strokeLinecap="round"
      />
      <AnimatedPath
        d={circlePath}
        progressDecimal={progressDecimal}
        fill="none"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeDasharray={100}
        strokeDashoffset={100}
        strokeLinecap="round"
      />
    </Svg>
  );
};

const Number = styled(Text)(({ theme: { colors } }) => {
  return {
    fontSize: 16,
    fontWeight: 500,
    color: colors.permaTalkspaceDarkGreen,
    position: 'absolute',
  };
});
const NumberWrapper = styled(View)<{ size: number }>(({ size }) => {
  return {
    position: 'absolute',
    left: 0,
    top: 0,
    height: size,
    width: size,
  };
});

const ProgressIndicator = ({
  progressDecimal,
  progressDisplay,
  size = 36,
  color,
  secondaryColor,
  strokeWidth = 2.5,
  numberStyle = {},
  containerStyle,
}: ProgressIndicatorProps) => {
  const { colors } = useEmotionTheme();
  return (
    <View style={{ position: 'relative', ...containerStyle }}>
      <ProgressCircle
        size={size}
        progressDecimal={progressDecimal}
        color={color || colors.permaTalkspaceDarkGreen}
        secondaryColor={secondaryColor || colors.permaLightGrey}
        strokeWidth={strokeWidth}
      />
      <NumberWrapper size={size} align="center" justify="center">
        <Number style={numberStyle}>{progressDisplay}</Number>
      </NumberWrapper>
    </View>
  );
};
export default ProgressIndicator;
