import { FunctionComponent } from 'react';
import styled from '@emotion/styled';
import { View, Text, Modal, Image } from '@talkspace/react-toolkit';
import { MatchLicense, MatchResult } from 'chat/redux/constants/chatTypes';
import { COLORS } from 'chat/utils/design';

function renderLicensesToString(licenses: MatchLicense[]) {
  return licenses.map((l) => l.typeDescription).join(', ');
}

const Wrapper = styled.div({
  padding: 20,
  paddingTop: 40,
  borderRadius: 4,
  boxShadow: '0 5px 10px 0 rgba(0, 0, 0, 0.4)',
  backgroundColor: '#eee',
  alignItems: 'stretch',
  height: '84vh',
  width: '84%',
  maxWidth: 1200,
  overflowY: 'auto',
});

interface MatchRowProps {
  text?: string;
  label: string;
  imageSrc?: string;
}

const MatchRow: FunctionComponent<MatchRowProps> = ({ label, text, imageSrc }) => (
  <div>
    <View row flex={1} style={{ margin: 2 }}>
      <View flex={1} style={{ padding: 5 }}>
        <Text style={{ color: COLORS.grey }}>{label}</Text>
      </View>
      <View
        flex={3}
        style={{
          backgroundColor: '#fff',
          padding: 5,
          paddingLeft: 15,
          paddingRight: 15,
        }}
      >
        {text && <Text style={{ wordBreak: 'break-word' }}>{text}</Text>}
        {imageSrc && <Image source={imageSrc} width={220} />}
      </View>
    </View>
  </div>
);

const Match: FunctionComponent<{ match: MatchResult }> = ({ match }) => {
  const { info, expertise, bestMatch, imageURL, licenses } = match;
  const { focus, treatmentApproach } = expertise;
  return (
    <View style={{ marginBottom: 20 }} key={imageURL}>
      <MatchRow label="Name" text={`${info.first_name} ${info.last_name}`} />
      <MatchRow label="Profile picture" imageSrc={imageURL} />
      <MatchRow label="Best match" text={bestMatch ? 'Yes' : 'No'} />
      <MatchRow label="Bio" text={info.public_details} />
      <MatchRow label="Joined months ago" text={String(info.joinedMonthsAgo)} />
      {/* <MatchRow
        label="Psychology Today"
        text=""
      /> */}
      <MatchRow label="Months in practice" text={String(info.monthsInPractice)} />
      <MatchRow label="People helped" text={String(info.paidUsers)} />
      <MatchRow label="Licenses" text={renderLicensesToString(licenses)} />
      <MatchRow label="Focus" text={focus.join(', ')} />
      <MatchRow label="Treatment approach" text={treatmentApproach.join(', ')} />
    </View>
  );
};

const Error: FunctionComponent<{
  errorTitle: string;
  errorMessage: string;
}> = ({ errorTitle, errorMessage }) => (
  <View justify="center" align="center">
    <Text style={{ fontSize: 20, margin: 50, marginBottom: 20 }}>{errorTitle}</Text>
    <Text style={{ color: COLORS.grey, margin: 50, marginTop: 0 }}>{errorMessage}</Text>
  </View>
);

const MatchResultsView: FunctionComponent<{
  onBackdropPress: () => void;
  matches: MatchResult[];
  error: string | null;
}> = ({ onBackdropPress, matches, error }) => {
  let errorTitle = 'Something went wrong';
  let errorMessage = '';
  if (error === 'expired' || error === 'oldMatch') {
    errorTitle = 'Match expired';
    errorMessage = 'This match has expired';
  }
  if (error === 'matchNotExist') {
    errorTitle = 'No matches found';
  }
  return (
    <Modal isVisible onBackdropPress={onBackdropPress}>
      <Wrapper>
        {error ? (
          <Error errorTitle={errorTitle} errorMessage={errorMessage} />
        ) : (
          <>
            {matches.map((m) => (
              <Match match={m} />
            ))}
          </>
        )}
      </Wrapper>
    </Modal>
  );
};

export default MatchResultsView;
