import styled from '../../core/styled';
import { baseTextStyle } from '../Text';
import Mini from './Mini';
import Tiny from './Tiny';
import Small from './Small';
import Standard from './Standard';
import type { StandardVariant } from './Standard';
import Big from './Big';
import ExtraBig from './ExtraBig';
import Large from './Large';
import Huge from './Huge';
import Massive from './Massive';
import ExtraHuge from './ExtraHuge';
import ExtraTiny from './ExtraTiny';

interface StyledTypographyProps {
  as?: React.ElementType;
}
export const Heading1 = styled.h1<StyledTypographyProps>({
  ...baseTextStyle,
  fontSize: 38,
});

export const Heading2 = styled.h2<StyledTypographyProps>({
  ...baseTextStyle,
  fontSize: 25,
});

export const Heading3 = styled.h3<StyledTypographyProps>({
  ...baseTextStyle,
  fontSize: 21,
});

export const Heading4 = styled.h4<StyledTypographyProps>({
  ...baseTextStyle,
  fontSize: 16,
});

export const Paragraph = styled.p<StyledTypographyProps>({
  ...baseTextStyle,
  fontSize: 16,
});

export const Caption = styled.p<StyledTypographyProps>({
  ...baseTextStyle,
  fontSize: 19,
  fontWeight: 'normal',
});

const Typography = {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Paragraph,
  Caption,
  Mini,
  Tiny,
  Small,
  Standard,
  Large,
  Big,
  ExtraBig,
  Huge,
  ExtraHuge,
  Massive,
  ExtraTiny,
};

export default Typography;

export type { StandardVariant };

export { Mini, Tiny, Small, Standard, Large, Big, ExtraBig, Huge, ExtraHuge, Massive, ExtraTiny };
