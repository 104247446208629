import CardWithContent from './CardWithContent';
import { withVersioning } from '../../utils';
import { VersionMap } from '../../types';

import type { CardWithContentProps } from './types';

const versionMap: VersionMap<React.ComponentProps<typeof CardWithContent>> = {
  DefaultComponent: CardWithContent,
};

export type { CardWithContentProps };

export default withVersioning(versionMap);
