import styled from '../../modules/core/styled';

const BOOTSTRAP_LG_BREAKPOINT = 1200;

export const dashboardHeaderStyle = {
  paddingBottom: 8,
  paddingTop: 12,
  paddingLeft: 16,
};

export const DashboardHeader = styled.th(({ onClick }) => {
  return {
    ...dashboardHeaderStyle,
    ...(onClick ? { cursor: 'pointer !important' } : {}),
  };
});

export const dashboardGetHeaderProps = (colors) => () => {
  return {
    style: {
      ...dashboardHeaderStyle,
      backgroundColor: colors.permaLinkWaterGrey,
    },
  };
};

export const DashboardRow = styled.tr(
  ({
    theme: {
      window: { width },
    },
  }) => {
    const isLarge = width > BOOTSTRAP_LG_BREAKPOINT;
    return isLarge
      ? {
          backgroundColor: 'rgba(231, 248, 246, 0.6)',
          padding: 5,
        }
      : {
          backgroundColor: 'rgba(231, 248, 246, 0.6)',
          padding: 0,
        };
  }
);

export const DashboardCell = styled.td(
  ({
    theme: {
      window: { width },
    },
  }) => {
    const isLarge = width > BOOTSTRAP_LG_BREAKPOINT;
    return isLarge
      ? {
          padding: '0 5px',
          marginBottom: 5,
          marginTop: 5,
        }
      : {
          margin: 5,
        };
  }
);
