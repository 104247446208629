import { FunctionComponent } from 'react';
import { Standard, styled, TouchableView, View, TrashIcon } from '@talkspace/react-toolkit';
import Footer from 'components/Reusable/Footer/Footer';
import AlertContainer from 'components/Reusable/AlertContainer/AlertContainer';
import NoteFormTooltip from './NoteFormTooltip';
import { FormMode, NoteError, NoteStatus } from './types';

const FooterWrapper = styled(View)({
  justifyContent: 'space-between',
  flexDirection: 'row',
});

const SaveDraftButton = styled(TouchableView)(({ theme: { colors } }) => {
  return {
    color: colors.accessibilityGreenDark,
    fontWeight: 700,
    fontSize: 15,
  };
});

const CancelSaveButton = styled(TouchableView)({
  paddingTop: 5,
  paddingBottom: 5,
  alignItems: 'center',
  justifyContent: 'center',
  width: 49,
});

const CancelButtonText = styled(Standard)(({ theme: { colors } }) => {
  return {
    paddingTop: 5,
    paddingBottom: 5,
    color: colors.permaSilverSand,
    fontWeight: 700,
  };
});

const PrimaryButton = styled(TouchableView)(({ theme: { colors } }) => {
  return {
    background: colors.accessibilityGreenDark,
    borderRadius: 8,
    height: 30,
    width: 'max-content',
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 30,
    paddingRight: 30,
    color: colors.white,
    fontWeight: 700,
    fontSize: 14,
    marginLeft: 20,
    lineHeight: '18px',
  };
});
const CloseButton = styled(TouchableView)(({ theme: { colors } }) => {
  return {
    paddingTop: 5,
    paddingBottom: 5,
    color: colors.accessibilityGreenDark,
    fontWeight: 700,
    fontSize: 15,
  };
});

const getContainerStyle = () => {
  return {
    width: 355,
    border: 'none',
    paddingTop: 15,
    paddingLeft: 9,
    paddingRight: 19,
  };
};

interface Props {
  formMode: FormMode;
  noteStatus?: NoteStatus;
  canSubmit?: boolean;
  cannotSubmitReason?: string | null;
  hasChanged?: boolean;
  canDeleteDraft: boolean;
  isError: boolean;
  isLoading: boolean;
  showSpinner: boolean;
  spinnerMessage: string;
  showErrorBanner: boolean;
  editHidden?: boolean;
  handleCloseErrorBannerPress: () => void;
  handleDeleteDraftPress: () => void;
  handleSaveDraftPress: () => void;
  handleSubmitPress: () => void;
  handleCancelPress: () => void;
  handleSavePress: () => void;
  handleEditPress: () => void;
  handleErrorAlertPress?: () => void;
  showErrorAlert?: boolean;
  error?: NoteError;
  displaySaveDraft?: boolean;
  displayCloseButton?: boolean;
  displaySubmit?: boolean;
  handleClose?: () => void;
}

const NoteFormFooterV2: FunctionComponent<Props> = ({
  formMode,
  noteStatus,
  canSubmit = true,
  cannotSubmitReason,
  hasChanged = true,
  canDeleteDraft,
  isError,
  isLoading,
  showSpinner,
  spinnerMessage,
  showErrorBanner,
  editHidden,
  handleCloseErrorBannerPress,
  handleDeleteDraftPress,
  handleSaveDraftPress,
  handleSubmitPress,
  handleCancelPress,
  handleSavePress,
  handleEditPress,
  handleErrorAlertPress,
  showErrorAlert,
  error,
  displaySaveDraft = true,
  displayCloseButton = false,
  displaySubmit = true,
  handleClose,
}) => {
  const containerStyle = getContainerStyle();
  const renderFooterChildren = () => [
    <FooterWrapper>
      {(() => {
        if (showErrorAlert) {
          return (
            <AlertContainer
              title={error?.title || 'Missing information'}
              childComponents={[<CloseButton onPress={handleErrorAlertPress}>Close</CloseButton>]}
              body={error?.message || 'Review and complete the required fields to continue'}
              containerStyle={containerStyle}
            />
          );
        }
        if (showErrorBanner) {
          return (
            <AlertContainer
              title="Missing information"
              childComponents={[
                <CloseButton onPress={handleCloseErrorBannerPress} dataQa="closeButton">
                  Close
                </CloseButton>,
              ]}
              body="Review and complete the required fields to continue"
              containerStyle={containerStyle}
            />
          );
        }
        if (formMode === 'create' || noteStatus === 'draft') {
          return (
            <>
              {canDeleteDraft && (
                <TouchableView onPress={handleDeleteDraftPress} dataQa="deleteDraftButton">
                  <TrashIcon key={1} />
                </TouchableView>
              )}
              {hasChanged || canSubmit ? (
                <View row align="center" justify="end" style={{ width: !canDeleteDraft && '100%' }}>
                  {displaySaveDraft && (
                    <SaveDraftButton
                      key={2}
                      onPress={handleSaveDraftPress}
                      dataQa="saveDraftButton"
                    >
                      Save draft
                    </SaveDraftButton>
                  )}
                  {displayCloseButton && (
                    <SaveDraftButton key={2} onPress={handleClose && handleClose}>
                      Close
                    </SaveDraftButton>
                  )}
                  <NoteFormTooltip description={cannotSubmitReason}>
                    {displaySubmit && (
                      <PrimaryButton
                        key={3}
                        onPress={handleSubmitPress}
                        disabled={!canSubmit}
                        dataQa="submitButton"
                      >
                        Submit
                      </PrimaryButton>
                    )}
                  </NoteFormTooltip>
                </View>
              ) : (
                <View />
              )}
            </>
          );
        }
        if (noteStatus === 'submitted') {
          if (formMode === 'edit') {
            return (
              <>
                <View />
                <View row align="center">
                  <CancelSaveButton key={2} onPress={handleCancelPress} dataQa="cancelButton">
                    <CancelButtonText>Cancel</CancelButtonText>
                  </CancelSaveButton>
                  <NoteFormTooltip description={cannotSubmitReason}>
                    <PrimaryButton
                      key={3}
                      onPress={handleSavePress}
                      disabled={!canSubmit}
                      dataQa="saveButton"
                    >
                      Save
                    </PrimaryButton>
                  </NoteFormTooltip>
                </View>
              </>
            );
          }

          if (formMode === 'view') {
            return (
              <>
                <View />
                <View row align="center">
                  <PrimaryButton onPress={handleEditPress} hide={editHidden} dataQa="editButton">
                    Edit
                  </PrimaryButton>
                </View>
              </>
            );
          }
        }
        return null;
      })()}
    </FooterWrapper>,
  ];

  const showFooter =
    showErrorBanner || hasChanged || canDeleteDraft || formMode === 'view' || canSubmit;

  return (
    <>
      {showFooter && (
        <Footer
          childComponents={renderFooterChildren()}
          isError={isError}
          isUpdating={isLoading}
          showSpinner={showSpinner}
          spinnerMessage={spinnerMessage}
          footerChildStyle={{ width: '100%' }}
          footerStyle={{ height: showErrorBanner || showErrorAlert ? 'auto' : undefined }}
        />
      )}
    </>
  );
};

export default NoteFormFooterV2;
