import { useState, ReactNode, memo } from 'react';
import {
  Divider,
  Spinner,
  styled,
  TextDS,
  useEmotionTheme,
  useWindowWidth,
  View,
  Link,
} from '@talkspace/react-toolkit';
import { Video, Envelope, PalmTree } from '@talkspace/react-toolkit/src/designSystems/icons';
import useQueryClinicalHours from '../queries/useQueryClinicalHours';
import { getUserData } from '../../../../utils/token';
import { formatWeekPeriod } from '../utils/date';
import { navigateToBillableHoursZendesk } from '../utils/zendesk';
import ResponsiveTooltip from './ResponsiveTooltip';

const TooltipContentView = styled(View)(({ theme: { spacing } }) => {
  return {
    padding: spacing('space200'),
    gap: spacing('space100'),
  };
});

interface TooltipContentProps {
  header: string;
  body: ReactNode;
}

const TooltipContent = ({ header, body }: TooltipContentProps) => (
  <TooltipContentView>
    <TextDS variant="headingMd">{header}</TextDS>
    <TextDS>{body}</TextDS>
  </TooltipContentView>
);

const MessagingTooltipContent = () => (
  <TooltipContent
    header="How are hours counted for messaging?"
    body="Hours are calculated at 40 words per minute for texting, 150 words per PDF file, and message
      duration for audio and video messages."
  />
);

const PTOTooltipContent = () => (
  <TooltipContent
    header="How are hours counted for paid time off?"
    body={`PTO hours are counted based on the time you add in the "Time Off" section under "My Account." Note that this information updates once per day when the page refreshes.`}
  />
);

const WordCapTooltipContent = () => {
  const { colorRoles } = useEmotionTheme();
  return (
    <>
      <TooltipContentView>
        <TextDS variant="headingMd">Why is my word count capped?</TextDS>
        <TextDS>
          You’ve exceeded twice the word count of your clients. You can only receive credit for up
          to twice the words that your clients send.
          <Link
            isNested
            stopPropagation
            onClick={() => navigateToBillableHoursZendesk()}
            text="Learn more"
            target="_blank"
            primaryColor={colorRoles.typography.textInteractiveDefault}
            style={{
              display: 'block',
              textDecoration: 'none',
              fontWeight: 500,
              textDecorationLine: 'underline',
              margin: 0,
            }}
          />
        </TextDS>
      </TooltipContentView>
    </>
  );
};

const MainView = styled(View)(({ theme: { spacing } }) => {
  return {
    gap: spacing('space300'),
    maxWidth: 460,
  };
});

const TitleView = styled(View)(({ theme: { spacing } }) => {
  return {
    flexDirection: 'row',
    gap: spacing('space100'),
    alignItems: 'baseline',
  };
});

const SectionView = styled(View)(({ theme: { spacing } }) => {
  return {
    gap: spacing('space100'),
  };
});

const SectionTitleView = styled(View)({
  flexDirection: 'row',
  justifyContent: 'space-between',
});

const SectionTitleWithIcon = styled(SectionTitleView)(({ theme: { spacing } }) => {
  return {
    flexDirection: 'row',
    gap: spacing('space100'),
    alignItems: 'center',
  };
});

const WritingTextRow = styled(TextDS)(
  ({
    theme: {
      spacing,
      window: { isMobile },
    },
  }) => {
    return {
      display: isMobile ? 'inline' : 'flex',
      gap: spacing('space050'),
    };
  }
);

const DataRowView = styled(SectionTitleView)(({ theme: { spacing } }) => {
  return {
    marginLeft: 20 + spacing('space100'),
  };
});

interface DataEntryProps {
  label: ReactNode;
  value: string;
}

const DataRow = ({ label, value }: DataEntryProps) => {
  const { isMobile } = useWindowWidth();
  return (
    <DataRowView>
      <TextDS style={{ maxWidth: isMobile ? 208 : undefined }}>{label}</TextDS>
      <TextDS>{value}</TextDS>
    </DataRowView>
  );
};

const InlineTooltipView = styled(View)(({ theme: { spacing } }) => {
  return {
    display: 'inline-flex',
    marginLeft: spacing('space100'),
  };
});

type Legend = 'messaging' | 'pto' | 'wordCap';

const WeekTable = () => {
  const therapistID = getUserData().id;

  const { data, isLoading } = useQueryClinicalHours(therapistID);

  const [activeLegend, setActiveLegend] = useState<Legend | undefined>();

  if (isLoading || !data) {
    return <Spinner />;
  }

  const {
    weeklyStats: [, thisWeek],
  } = data;

  const isWordsCapReached =
    thisWeek.providerTotalMessagingWordsUncapped !== thisWeek.providerTotalMessagingWordsCapped;

  const Title = memo(() => (
    <TitleView>
      <TextDS variant="headingLg">Hours this week</TextDS>
      <TextDS>{formatWeekPeriod(thisWeek.weekStart, thisWeek.weekEnd)}</TextDS>
    </TitleView>
  ));

  const LiveSessionsSection = memo(() => (
    <SectionView>
      <SectionTitleView>
        <SectionTitleWithIcon>
          <Video colorType="subtle" />
          <TextDS variant="headingMd">Live Sessions</TextDS>
        </SectionTitleWithIcon>

        <TextDS variant="headingMd">{thisWeek.totalLiveCallHours} hrs</TextDS>
      </SectionTitleView>

      <DataRow label="Time in session with client" value={`${thisWeek.liveCallHours} hrs`} />

      <DataRow
        label="Client no-shows and late cancellations"
        value={`${thisWeek.noShowsAndLateCancellationsHours} hrs`}
      />
    </SectionView>
  ));

  const MessagingSection = memo(() => (
    <SectionView>
      <SectionTitleView>
        <SectionTitleWithIcon>
          <Envelope colorType="subtle" />

          <TextDS variant="headingMd">Messaging</TextDS>

          <ResponsiveTooltip
            isOpen={activeLegend === 'messaging'}
            onDrawerOpen={() => setActiveLegend('messaging')}
            onDrawerClose={() => setActiveLegend(undefined)}
            legendContent={<MessagingTooltipContent />}
          />
        </SectionTitleWithIcon>

        <TextDS variant="headingMd">{thisWeek.totalMessagingHours} hrs</TextDS>
      </SectionTitleView>

      <DataRowView>
        <WritingTextRow>
          Writing text{' '}
          {isWordsCapReached ? (
            <TextDS
              colorRole="textSubtlest"
              inline
              style={{ display: 'flex', alignItems: 'center' }}
            >
              ({thisWeek.providerTotalMessagingWordsUncapped} words, capped at{' '}
              {thisWeek.providerTotalMessagingWordsCapped})
              <InlineTooltipView>
                <ResponsiveTooltip
                  isOpen={activeLegend === 'wordCap'}
                  onDrawerOpen={() => setActiveLegend('wordCap')}
                  onDrawerClose={() => setActiveLegend(undefined)}
                  legendContent={<WordCapTooltipContent />}
                />
              </InlineTooltipView>
            </TextDS>
          ) : (
            <TextDS colorRole="textSubtlest" inline>
              ({thisWeek.providerTotalMessagingWordsUncapped} words)
            </TextDS>
          )}
        </WritingTextRow>
        <TextDS>{thisWeek.providerTotalMessagingHoursCapped} hrs</TextDS>
      </DataRowView>

      <DataRow
        label={
          <>
            Reading text{' '}
            <TextDS colorRole="textSubtlest" inline>
              ({thisWeek.clientTotalMessagingWords} words)
            </TextDS>
          </>
        }
        value={`${thisWeek.clientTotalMessagingHours} hrs`}
      />

      <DataRow label="Recording audio & video" value={`${thisWeek.providerAVHours} hrs`} />

      <DataRow label="Listening to audio & video" value={`${thisWeek.clientAVHours} hrs`} />
    </SectionView>
  ));

  const PTOSection = memo(() => (
    <SectionView>
      <SectionTitleView>
        <SectionTitleWithIcon>
          <PalmTree colorType="subtle" />

          <TextDS variant="headingMd">Paid time off</TextDS>

          <ResponsiveTooltip
            isOpen={activeLegend === 'pto'}
            onDrawerOpen={() => setActiveLegend('pto')}
            onDrawerClose={() => setActiveLegend(undefined)}
            legendContent={<PTOTooltipContent />}
          />
        </SectionTitleWithIcon>

        <TextDS variant="headingMd">{thisWeek.paidTimeOffHours} hrs</TextDS>
      </SectionTitleView>
    </SectionView>
  ));

  const TotalSection = memo(() => (
    <View>
      <SectionTitleView>
        <TextDS variant="headingMd">Total hours</TextDS>
        <TextDS variant="headingMd">{thisWeek.totalHours} hrs</TextDS>
      </SectionTitleView>
    </View>
  ));

  return (
    <MainView>
      <Title />

      <LiveSessionsSection />

      <MessagingSection />

      <PTOSection />

      <Divider />

      <TotalSection />
    </MainView>
  );
};

export default WeekTable;
