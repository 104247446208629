import { FunctionComponent } from 'react';
import { View, Tiny } from '@talkspace/react-toolkit';

import styled, { EmotionStyle } from '@/core/styled/styled';
import { webOnlyStyle } from '@/core/styled/styleHelpers';

import { MessageBodyType, EMessage } from '../../entities/EMessage';
import { AckLevel } from '../../types';
import UserAvatar from '../UserAvatar';
import Bubble from '../Bubble';
import ReadReceiptCheckmarks from '../ReadReceiptCheckmarks';

function getGradientBorderStyles(): EmotionStyle {
  return {
    borderBottomStyle: 'solid',
    borderBottomColor: 'transparent',
    borderBottomWidth: 1,
    background: 'linear-gradient(to right, white, white), radial-gradient(#C6CFDC, #FEFEFF)',
    backgroundClip: 'padding-box, border-box',
    backgroundOrigin: 'padding-box, border-box',
  };
}

const Container = styled(View)({
  flexDirection: 'row',
  alignItems: 'flex-start',
  padding: 16,
  ...webOnlyStyle({
    ...getGradientBorderStyles(),
  }),
});

const AvatarContainer = styled(View)<{ fileType: MessageBodyType }>(({ fileType }) => {
  return {
    marginRight: 16,
    marginTop: fileType === 'AUDIO' ? 4 : 0,
  };
});

const DeliveryInfo = styled(View)({
  flexDirection: 'row',
  alignItems: 'flex-end',
  paddingTop: 6,
  paddingLeft: 6,
});

const CheckmarksContainer = styled(View)({
  flexDirection: 'row',
  justifyContent: 'center',
  width: 22,
  marginBottom: 2,
  zIndex: 0,
});

interface Props {
  currentMessage: EMessage;
  containerWidth: number;
  roomID: number;
  senderName: string;
  date: string;
  ackLevel: AckLevel;
  isPeer: boolean;
  isSelf: boolean;
}

const SharedFileItem: FunctionComponent<Props> = ({
  currentMessage,
  roomID,
  containerWidth,
  senderName,
  date,
  ackLevel,
  isPeer,
  isSelf,
}) => (
  <Container>
    <AvatarContainer fileType={currentMessage.bodyType}>
      <UserAvatar
        url={currentMessage.avatarImage}
        shortName={currentMessage.user.displayName.slice(0, 2)}
      />
    </AvatarContainer>
    <View className="bubble-container">
      <Bubble
        bubbleTapped={false}
        hasPadding={false}
        containerWidth={containerWidth}
        roomID={roomID}
        currentMessage={currentMessage}
        isMedia
        isLast={false}
        isPeer={isPeer}
        ackLevel={ackLevel}
        squareCorners="NONE"
        position={isSelf ? 'right' : 'left'}
        isAroundSameTime={false}
        isPrevSameUser={false}
        isPrevSystem={false}
      />
      <DeliveryInfo>
        <Tiny>
          {senderName}, {date}
        </Tiny>
        <CheckmarksContainer>
          <ReadReceiptCheckmarks ackLevel={ackLevel} />
        </CheckmarksContainer>
      </DeliveryInfo>
    </View>
  </Container>
);

export default SharedFileItem;
