import { useState, useEffect } from 'react';
import moment from 'moment';

import { getTherapistProfile } from '../utils/apiHelper';
import { PersonalDetailsData, TherapistProfileData } from '../types';

const emptyState: PersonalDetailsData = {
  title: '',
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  city: '',
  state: '',
  zipCode: '',
  gender: '',
  firstClinicalLicenseDate: '',
};

const formatDate = (date: string): string => {
  if (date === '0000-00-00' || !date) return '';
  return moment.utc(date).format('MM/DD/YYYY');
};

const usePersonalDetails = (token: string) => {
  const [data, setData] = useState<PersonalDetailsData>(emptyState);
  const [error, setError] = useState<Error>();

  useEffect(() => {
    getTherapistProfile(token)
      .then((res: TherapistProfileData) => {
        const {
          title,
          firstName,
          lastName,
          dateOfBirth,
          city,
          state,
          zipCode,
          gender,
          firstClinicalLicenseDate,
        } = res;
        setData({
          title,
          firstName,
          lastName,
          dateOfBirth: formatDate(dateOfBirth),
          city,
          state,
          zipCode,
          gender,
          firstClinicalLicenseDate: formatDate(firstClinicalLicenseDate),
        });
      })
      .catch((err) => {
        setError(err);
      });
  }, [token]);

  return { data, error };
};

export default usePersonalDetails;
