import * as actionTypes from '../constants/MatchingConstants';
import apiWrapper from '../utils/apiWrapper';
import apiHelper from '../utils/api';

export function requestSendIntakeMessage() {
  return {
    type: actionTypes.REQUEST_SEND_INTAKE_MESSAGE,
  };
}

export function requestSendIntakeMessageError(error) {
  return {
    type: actionTypes.REQUEST_SEND_INTAKE_MESSAGE_ERROR,
    error,
  };
}

export function receiveSendTreatmentIntakeMessage() {
  return {
    type: actionTypes.RECEIVE_SEND_TREATMENT_INTAKE_MESSAGE,
  };
}

export function requestIntakeSurveyStatus() {
  return {
    type: actionTypes.REQUEST_INTAKE_SURVEY_STATUS,
  };
}

export function requestIntakeSurveyStatusError(error) {
  return {
    type: actionTypes.REQUEST_INTAKE_SURVEY_STATUS_ERROR,
    error,
  };
}

export function receiveTreatmentIntakeSurveyStatus({ intakeStatus }) {
  return {
    type: actionTypes.RECEIVE_TREATMENT_INTAKE_SURVEY_STATUS,
    treatmentIntakeStatus: intakeStatus,
  };
}

export function requestSendEligibilityMessage() {
  return {
    type: actionTypes.REQUEST_SEND_ELIGIBILITY_MESSAGE,
  };
}

export function requestSendEligibilityMessageError() {
  return {
    type: actionTypes.REQUEST_SEND_ELIGIBILITY_MESSAGE_ERROR,
  };
}

export function receiveSendEligibilityMessage() {
  return {
    type: actionTypes.RECEIVE_SEND_ELIGIBILITY_MESSAGE,
  };
}

export const postTreatmentIntakeSurveyMessage = () => (dispatch, getState) => {
  const {
    room: { roomID },
    customerInformation: { clientUserID },
  } = getState();
  dispatch(requestSendIntakeMessage());
  return apiWrapper
    .post(`${apiHelper().apiEndpoint}/v3/rooms/${roomID}/intake/${clientUserID}?type=treatment`)
    .then(() => dispatch(receiveSendTreatmentIntakeMessage()))
    .catch((error) => dispatch(requestSendIntakeMessageError(error)));
};

export const getTreatmentIntakeSurveyStatus = () => (dispatch, getState) => {
  const {
    room: { roomID },
  } = getState();
  dispatch(requestIntakeSurveyStatus());
  return apiWrapper
    .get(`${apiHelper().apiEndpoint}/v3/rooms/${roomID}/intake-available?type=treatment`)
    .then((res) => dispatch(receiveTreatmentIntakeSurveyStatus(res.data.data)))
    .catch((error) => dispatch(requestIntakeSurveyStatusError(error)));
};

export const sendEligibilityMessage = () => (dispatch, getState) => {
  const {
    room: { roomID },
  } = getState();
  dispatch(requestSendEligibilityMessage());
  return apiWrapper
    .post(`${apiHelper().apiEndpoint}/v2/rooms/${roomID}/eligibility/message`)
    .then(() => dispatch(receiveSendEligibilityMessage()))
    .catch((error) => dispatch(requestSendEligibilityMessageError(error)));
};
