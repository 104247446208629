import { Auth } from '@aws-amplify/auth';
import configuration from './configuration';

const configureAWSAmplify = () => {
  Auth.configure({
    ...configuration,
    mandatorySignIn: false,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  });
};

export default configureAWSAmplify;
