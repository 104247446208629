import { EmotionStyle } from './styled';

export const isNative = () => typeof window.document === 'undefined';

export const isWeb = () => !isNative();

/**
 * Returns the given object only if the code is running on a website, otherwise returns an empty object
 * @param   {style} EmotionStyle
 * @returns EmotionStyle
 */
export const webOnlyStyle = (style: EmotionStyle): EmotionStyle => (isWeb() ? style : {});

/**
 * Returns the given object only if the code is running on a react-native app, otherwise returns an empty object
 * @param   {style} EmotionStyle
 * @returns EmotionStyle
 */
export const nativeOnlyStyle = (style: EmotionStyle): EmotionStyle => (isNative() ? style : {});
