import styled from '@emotion/styled';
import { View } from '@talkspace/react-toolkit';
import { Theme } from '@/core/styled/styled';

const Banner = styled(View)<{ showBottomShadow?: boolean; theme: Theme }>(
  ({ showBottomShadow = true, theme: { window, colors } }) => {
    const { isMobile } = window;

    return {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'stretch',
      borderWidth: 0,
      borderBottomWidth: 0.5,
      borderColor: colors.a11yExtraLighterGrey,
      borderStyle: 'solid',
      backgroundColor: colors.white,
      height: '66px',
      boxShadow: `0px 1px 3px -2px ${colors.a11yExtraLighterGrey}`,
      ...(showBottomShadow && {
        ...(isMobile && {
          borderBottomLeftRadius: 10,
          borderBottomRightRadius: 10,
          boxShadow: `0px 12px 20px -1px rgba(0,0,50,0.08)`,
          borderBottomWidth: 0,
        }),
      }),
    };
  }
);

export default Banner;
