import { ThunkAction } from 'redux-thunk';
import { AppState } from '../chatStore';

import {
  REQUEST_GET_OFFERS,
  REQUEST_GET_OFFERS_ERROR,
  RECEIVE_GET_OFFERS,
  REQUEST_POST_OFFER,
  REQUEST_POST_OFFER_ERROR,
  RECEIVE_POST_OFFER,
  GetOffersPayload,
  OffersActionTypes,
} from '../constants/offersTypes';
import ChatApiHelpers from '../helpers/ChatApiHelpersClass';

type OffersThunk = ThunkAction<
  Promise<OffersActionTypes>,
  AppState,
  ChatApiHelpers,
  OffersActionTypes
>;

function requestGetOffers(): OffersActionTypes {
  return { type: REQUEST_GET_OFFERS };
}
function requestGetOffersError(error: Error): OffersActionTypes {
  return { type: REQUEST_GET_OFFERS_ERROR, error };
}
function receiveGetOffers(payload: GetOffersPayload): OffersActionTypes {
  return { type: RECEIVE_GET_OFFERS, payload };
}

export const dispatchRequestOffers =
  (roomID: number): OffersThunk =>
  (dispatch, getState, api) => {
    dispatch(requestGetOffers());
    return api
      .getOffers(roomID)
      .then((res) => dispatch(receiveGetOffers({ offerCategories: res })))
      .catch(api.dismissIfCancelled)
      .catch((error) => dispatch(requestGetOffersError(error)));
  };

function requestPostOffer(): OffersActionTypes {
  return { type: REQUEST_POST_OFFER };
}
function requestPostOfferError(error: Error): OffersActionTypes {
  return { type: REQUEST_POST_OFFER_ERROR, error };
}
function receivePostOffer(): OffersActionTypes {
  return { type: RECEIVE_POST_OFFER, payload: {} };
}

export const dispatchRequestPostOffer =
  (roomID: number, offerId: number, planId: number): OffersThunk =>
  (dispatch, getState, api) => {
    dispatch(requestPostOffer());
    return api
      .postOffer(roomID, offerId, planId)
      .then(() => dispatch(receivePostOffer()))
      .catch((err) => dispatch(requestPostOfferError(err)));
  };
