import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useParams } from '@/core/routerLib';
import {
  postTreatmentIntakeSurveyMessage as postTreatmentIntakeSurveyMessageAction,
  getTreatmentIntakeSurveyStatus as getTreatmentIntakeSurveyStatusAction,
} from '../../../../../actions/MatchingActions';
import SocketService from '../../../../../utils/socket/SocketService';
import UserActionCard from '../../../../Reusable/UserActionCard/UserActionCard';
import Submenu from '../../../../Reusable/Submenu/Submenu';
import { getActiveCustomerName } from '../../../../../utils/customerHelper';
import { CustomerInformation } from '../../../../../types/customer';
import useQueryParticipants from '../../../../../hooks/useQueryParticipants';

interface Props {
  matching: Record<string, any>;
  customerInformation: CustomerInformation;
  getTreatmentIntakeSurveyStatus: () => void;
  postTreatmentIntakeSurveyMessage: () => void;
}

const IntakeQuestionnaire = ({
  matching,
  customerInformation,
  getTreatmentIntakeSurveyStatus,
  postTreatmentIntakeSurveyMessage,
}: Props) => {
  const [showSpinner, setShowSpinner] = useState(false);

  const { roomID } = useParams<{ roomID: string }>();

  const { data: participants } = useQueryParticipants(Number(roomID));

  useEffect(() => {
    const handleSubmittedIntakeSurveyEvent = (data) => {
      if (data.type !== 'treatment') {
        return;
      }

      if (Number(data.roomID) !== Number(roomID)) {
        return;
      }

      getTreatmentIntakeSurveyStatus();
    };

    SocketService.instance().on('submittedIntakeSurvey', handleSubmittedIntakeSurveyEvent);

    return () => {
      SocketService.instance().off('submittedIntakeSurvey', handleSubmittedIntakeSurveyEvent);
    };
  }, [getTreatmentIntakeSurveyStatus, roomID]);

  useEffect(() => {
    getTreatmentIntakeSurveyStatus();
  }, [getTreatmentIntakeSurveyStatus]);

  const requestIntakeSurveyMessage = () => {
    setShowSpinner(true);
    setTimeout(() => {
      setShowSpinner(false);
    }, 3000);
    postTreatmentIntakeSurveyMessage();
  };

  const renderIntakeSurveyCards = () => {
    const { treatmentIntakeStatus: intakeStatus, isUpdating, isError } = matching;
    const isCompleted = intakeStatus === 'submitted';
    const hasRequested = intakeStatus && intakeStatus.includes('requested');
    const isPending = intakeStatus && !isCompleted && hasRequested;
    const buttons = [
      {
        title:
          isCompleted || hasRequested || isPending
            ? 'Request intake questionnaire again'
            : 'Send questionnaire survey',
        isSecondary: isCompleted,
        clickHandler: () => requestIntakeSurveyMessage(),
      },
    ];
    let cardText =
      hasRequested || isPending
        ? 'You have already sent the intake survey to the client, but the client hasn’t completed it yet. Please reach out to the client to discuss any questions or concerns they may have. You can click below to send it again or wait for the client to submit their information.'
        : 'This intake is designed to help gather basic intake information from clients including emergency contact information, mental health history, and psychiatric/medical history. Click here to send this to the client. Once completed, all of the information will appear in the appropriate fields in the CRM.';
    if (isCompleted)
      cardText =
        'The client has completed the intake survey. The information has populated into the appropriate fields in the CRM.';
    return (
      <UserActionCard
        key={1}
        preferredName={getActiveCustomerName(customerInformation, participants)}
        content={cardText}
        buttons={buttons}
        isUpdating={isUpdating}
        isError={isError}
        showSpinner={showSpinner}
      />
    );
  };

  return (
    <Submenu
      childComponents={[renderIntakeSurveyCards()]}
      title="Intake questionnaire"
      prevRoute="case-tab"
    />
  );
};

const mapStateToProps = (state) => {
  return {
    matching: state.matching,
    customerInformation: state.customerInformation,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      postTreatmentIntakeSurveyMessage: postTreatmentIntakeSurveyMessageAction,
      getTreatmentIntakeSurveyStatus: getTreatmentIntakeSurveyStatusAction,
    },
    dispatch
  );

const IntakeQuestionnaireContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(IntakeQuestionnaire);

export default IntakeQuestionnaireContainer;
