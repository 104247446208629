import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getArchivedTreatmentPlans } from '../../../../../actions/TreatmentPlannerActions';
import Submenu from '../../../../Reusable/Submenu/Submenu';
import ItemList from '../../../../Reusable/ItemList/ItemList';
import ListItem from '../../../../Reusable/ListItems/ListItem';
import { formatDateMedium } from '../../../../../utils/date';
import './ArchivedTreatmentPlans.css';

class ArchivedTreatmentPlans extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    props.getArchivedTreatmentPlans();
  }

  getItemActions() {
    const { history } = this.props;
    const actions = [
      {
        text: 'View plan',
        method: (item) => {
          history.push(`/room/${this.props.roomID}/archived-treatment-plan/${item.id}`);
        },
        cssClass: 'default',
      },
    ];
    return actions;
  }

  getViewContent() {
    return (
      <ItemList
        cssClass="archive-treatment-plan-list"
        items={this.processItems(this.props.archivedTreatmentPlans)}
        titles={['Presenting Problem', 'Archived on', 'Completed']}
        noDataMessage="No archived treatment plans."
        widths={[145, 100, 75]}
      />
    );
  }

  processItems(items) {
    return items
      .sort((a, b) => {
        const dateA = new Date(a.updatedAt);
        const dateB = new Date(b.updatedAt);
        return dateA > dateB ? -1 : dateA < dateB ? 1 : 0;
      })
      .map((item, key) => {
        const itemDisplayData = {};

        itemDisplayData.presentingProblem = item.presentingProblems.join(', ');
        itemDisplayData.archivedOn = formatDateMedium(item.updatedAt);
        itemDisplayData.completed = `${item.completedObjectives}/${item.totalObjectives}`;

        return (
          <ListItem
            key={key}
            itemDataOrder={['presentingProblem', 'archivedOn', 'completed']}
            itemDisplayData={itemDisplayData}
            item={item}
            status={item.archived}
            actions={this.getItemActions()}
            widths={[144, 102, 55]}
          />
        );
      });
  }

  render() {
    return (
      <Submenu
        childComponents={[this.getViewContent()]}
        title="Archived treatment plans"
        prevRoute="clinical-tab"
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    roomID: state.room.roomID,
    archivedTreatmentPlans: state.treatmentPlanner.archivedTreatmentPlans,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getArchivedTreatmentPlans }, dispatch);

const ArchivedTreatmentPlansContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ArchivedTreatmentPlans);

export default ArchivedTreatmentPlansContainer;
