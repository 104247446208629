import Svg, { Circle, Path } from 'svgs';

import { useEmotionTheme } from '../../core/styled';

interface Props {
  width?: number;
  height?: number;
}
const MbaSparkles = ({ width = 131, height = 130, ...otherProps }: Props) => {
  const titleText = 'Sparkles';
  const { colors } = useEmotionTheme();
  return (
    <Svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 131 130"
      title={titleText}
      aria-label={titleText}
      {...otherProps}
    >
      <Circle cx={65.5} cy={65} r={65} fill={colors.permaLinkWaterGrey} />
      <Path
        d="m47.992 42.592 17.99-.628a7.806 7.806 0 0 1 8.074 7.528l.63 17.992a7.806 7.806 0 0 1-7.529 8.074l-17.992.628a7.806 7.806 0 0 1-8.073-7.528l-.628-17.993a7.806 7.806 0 0 1 7.528-8.073Z"
        fill={colors.white}
        stroke={colors.permaPeriwinkleGray}
        strokeWidth={5.204}
      />
      <Path
        d="m61.16 55.11 17.963 1.16a7.806 7.806 0 0 1 7.287 8.291l-1.157 17.966a7.806 7.806 0 0 1-8.293 7.288l-17.965-1.16a7.806 7.806 0 0 1-7.287-8.291l1.16-17.967a7.806 7.806 0 0 1 8.291-7.286Z"
        fill={colors.permaTalkspaceDarkGreen}
        stroke={colors.permaTalkspaceDarkGreen}
        strokeWidth={5.204}
      />
      <Path
        d="m75.712 70.357.21.535.249-.518 1.336-2.781 2.997-1.177.528-.207-.509-.25-2.881-1.417-1.085-2.765-.201-.512-.254.488-1.38 2.657-3 1.152-.528.204.506.253 2.882 1.442 1.13 2.896ZM75.031 85.5l.209.514.246-.497 1.331-2.68 2.997-1.153.534-.205-.513-.252-2.88-1.416-1.078-2.914-.202-.544-.257.52-1.388 2.805-2.996 1.152-.534.205.513.253 2.883 1.417 1.135 2.795Zm-10.924-4.265.203.492.249-.47 2.8-5.299 5.576-2.234.511-.205-.49-.25-5.353-2.728-2.313-5.504-.205-.488-.247.469-2.773 5.273-5.626 2.233-.516.204.495.251 5.402 2.73 2.287 5.526Z"
        fill={colors.white}
        stroke={colors.white}
        strokeWidth={0.5}
      />
    </Svg>
  );
};

export default MbaSparkles;
