import { ReactNode, createContext, useContext, useCallback, useRef } from 'react';
import { useObjectState } from '@talkspace/react-toolkit';
import { PromiseMessageTypes } from '@/utils/promiseMessage/promiseMessageEvents';
import { PROMISE_MESSAGE_TYPE } from './promiseMessageTypes';
import type {
  PromiseMessageEvent,
  PostMessageFuncType,
  PromiseMessageCallback,
  PromiseMessageHandler,
  PromiseMessageDataHandler,
} from './promiseMessageTypes';
import { PromiseMessageTypeNames } from './promiseMessageEventTypes';
import {
  receiveMessagePromiseHandler,
  getPromiseMessageTypedCallbackAndData,
} from './promiseMessageHelper';

type PromiseMessageContextState = {
  [K in PromiseMessageTypeNames]?: PromiseMessageDataHandler<K>;
};

const promiseMessageContextInitialState: PromiseMessageContextState = {};

type Unsubscribe = () => void;

interface PromiseMessageContextActions {
  addPromiseMessageTypeHandler: <K extends PromiseMessageTypeNames>(
    type: K,
    handler: PromiseMessageDataHandler<K>
  ) => Unsubscribe;
  receivedMessage: (
    postMessage: PostMessageFuncType<typeof PROMISE_MESSAGE_TYPE>,
    event: PromiseMessageEvent
  ) => Promise<void>;
}

const PromiseMessageStateContext = createContext<PromiseMessageContextState | undefined>(undefined);
export const PromiseMessageActionsContext = createContext<PromiseMessageContextActions | undefined>(
  undefined
);

export function PromiseMessageContextProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useObjectState(promiseMessageContextInitialState);
  const stateRef = useRef(state);
  stateRef.current = state;

  const addPromiseMessageTypeHandler: PromiseMessageContextActions['addPromiseMessageTypeHandler'] =
    useCallback(
      (type, handler) => {
        dispatch({
          [type]: handler,
        });
        return () => {
          if (stateRef.current[type] === handler)
            dispatch({
              [type]: undefined,
            });
        };
      },
      [dispatch]
    );

  const receivedMessage: PromiseMessageContextActions['receivedMessage'] = useCallback(
    async (postMessage, event) => {
      const customHandlers: PromiseMessageHandler = async (innerEvent, callbackArg) => {
        // These castings aren't really necessary unless we switch over `innerType`
        // Leaving them here as an example
        const innerType = innerEvent.data.data.innerType as PromiseMessageTypeNames;
        const callback = callbackArg as PromiseMessageCallback<PromiseMessageTypeNames>;
        const [data, cb] = getPromiseMessageTypedCallbackAndData<typeof innerType>(
          innerEvent,
          callback
        );
        const handler = stateRef.current[innerType] as
          | PromiseMessageDataHandler<typeof innerType>
          | undefined;

        if (handler) {
          handler(data.innerData as PromiseMessageTypes[typeof innerType]['postData'], cb);
          return true;
        }
        return false;
      };
      receiveMessagePromiseHandler(postMessage, event, customHandlers);
    },
    []
  );

  const actions = {
    addPromiseMessageTypeHandler,
    receivedMessage,
  };
  return (
    <PromiseMessageStateContext.Provider value={state}>
      <PromiseMessageActionsContext.Provider value={actions}>
        {children}
      </PromiseMessageActionsContext.Provider>
    </PromiseMessageStateContext.Provider>
  );
}

export const withPromiseMessageContext = (Component) => (props) =>
  (
    <PromiseMessageContextProvider>
      <Component {...props} />
    </PromiseMessageContextProvider>
  );

export function usePromiseMessageContextActions(): PromiseMessageContextActions {
  const context = useContext(PromiseMessageActionsContext);
  if (context === undefined)
    throw new Error(
      'PromiseMessageContextActions must be used within PromiseMessageContextProvider'
    );
  return context;
}
