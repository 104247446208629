const AllGoalsAchievedIcon = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="18" cy="18" r="14.4" stroke="#EFF3FA" strokeWidth="7.2" />
    <mask
      id="mask_no_progress"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="19"
      height="19"
    >
      <rect width="18.3051" height="18.3051" fill="white" />
    </mask>
    <g mask="url(#mask_no_progress)" />
  </svg>
);

export default AllGoalsAchievedIcon;
