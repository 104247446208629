import { Moment } from 'moment';

const roundDateDownToNearestSlot = (momentDate: Moment) => {
  const minute = momentDate.minutes();
  const roundedDate = momentDate.clone().startOf('hour');
  let roundedMinute: number;
  if (minute < 15) {
    roundedMinute = 0;
  } else if (minute < 30) {
    roundedMinute = 15;
  } else if (minute < 45) {
    roundedMinute = 30;
  } else {
    roundedMinute = 45;
  }
  roundedDate.set('minute', roundedMinute);
  return roundedDate;
};

export default roundDateDownToNearestSlot;
