import { useState, useEffect, useRef } from 'react';
import moment, { Moment } from 'moment';
import { GetProgressNoteHelperDataResponse } from 'hooks/notes/useQueryProgressNoteHelperData';
import { BusinessLineQueryResponse } from 'ts-frontend/hooks/useQueryBusinessLine';
import { Modality } from '../utils';

interface useMessagingDateProps {
  formMode: 'create' | 'edit' | 'view';
  businessLine?: BusinessLineQueryResponse;
  serviceStartDate?: Date | null;
  serviceEndDate?: Date | null;
  helperData?: GetProgressNoteHelperDataResponse;
  handleSetServiceStartDate?: (startDate: Date) => void;
  modalityID?: number | null;
}

const progressNotesV2LaunchDate = moment('2021-09-28');

export default function useMessagingDate({
  formMode,
  businessLine,
  serviceStartDate,
  serviceEndDate,
  helperData,
  handleSetServiceStartDate,
  modalityID,
}: useMessagingDateProps) {
  const [messagingStartDate, setMessagingStartDate] = useState<Moment | null>(
    modalityID === Modality.TEXT && serviceStartDate ? moment(serviceStartDate) : null
  );
  const [messagingEndDate, setMessagingEndDate] = useState<Moment | null>(
    modalityID === Modality.TEXT && serviceEndDate ? moment(serviceEndDate) : null
  );
  const isInitialState = useRef(true);
  const handleSetMessagingStartDate = (startDate: Moment | null) => {
    setMessagingStartDate(startDate);
    isInitialState.current = false;
  };
  const handleSetMessagingEndDate = (endDate: Moment | null) => setMessagingEndDate(endDate);

  useEffect(() => {
    if (formMode === 'create' && !businessLine?.isEAP) {
      let lastActivityDate: Moment | null = null;
      if (helperData?.lastProgressNote?.serviceEndDate) {
        lastActivityDate = moment(helperData.lastProgressNote.serviceEndDate).add(1, 'd');
      } else if (helperData?.roomCreationDate) {
        lastActivityDate = moment(helperData.roomCreationDate);
      }
      if (lastActivityDate) {
        let defaultMessagingStartDate: Moment;
        if (helperData?.ongoingMessagingSession?.startDate) {
          defaultMessagingStartDate = moment(helperData.ongoingMessagingSession.startDate);
        } else {
          defaultMessagingStartDate = lastActivityDate.isBefore(progressNotesV2LaunchDate)
            ? moment().startOf('month')
            : lastActivityDate;
        }
        setMessagingStartDate(defaultMessagingStartDate);
        if (handleSetServiceStartDate) {
          handleSetServiceStartDate(defaultMessagingStartDate.toDate());
        }
      }
    }
  }, [
    formMode,
    helperData?.ongoingMessagingSession?.startDate,
    businessLine?.isEAP,
    helperData?.lastProgressNote?.serviceEndDate,
    helperData?.roomCreationDate,
    handleSetServiceStartDate,
  ]);

  useEffect(() => {
    if (formMode === 'create' && businessLine?.isEAP && serviceStartDate && serviceEndDate) {
      setMessagingStartDate(moment(serviceStartDate));
      setMessagingEndDate(moment(serviceEndDate));
    }
  }, [formMode, serviceStartDate, serviceEndDate, businessLine]);

  useEffect(() => {
    if (formMode === 'edit' && helperData && !messagingStartDate) {
      if (businessLine?.isB2C) {
        setMessagingStartDate(
          moment(helperData.lastProgressNote?.serviceEndDate || helperData.roomCreationDate)
        );
      } else if (helperData.ongoingMessagingSession?.startDate) {
        setMessagingStartDate(moment(helperData.ongoingMessagingSession.startDate));
      }
    }
  }, [helperData, messagingStartDate, formMode, businessLine?.isB2C]);

  const isMissingMessagingSession =
    !businessLine ||
    (businessLine.isBH && !helperData?.ongoingMessagingSession?.startDate) ||
    !messagingStartDate ||
    (businessLine.isB2B && isInitialState.current && moment().diff(messagingStartDate, 'days') < 7);

  return {
    messagingStartDate,
    messagingEndDate,
    isMissingMessagingSession,
    handleSetMessagingStartDate,
    handleSetMessagingEndDate,
  };
}
