import CheckListItem from '../ListItems/CheckListItem';
import CheckListEditor from '../ListItems/CheckListEditor';
import CheckListAdder from '../ListItems/CheckListAdder';
import './CheckList.css';

const CheckList = ({
  items,
  listTitle,
  checkboxChangeHandler,
  textAreaChangeHandler,
  displayField,
  adderChangeHandler,
  itemInit,
  editorPlaceholder,
  adderPlaceholder,
  unCheckEmpty,
  textAreaDataQa,
}) => (
  <div className="checklist">
    <div className="checklist-title">{listTitle}</div>
    <div className="checklist-body">
      {items
        .filter(
          ({ checked, standard, [displayField]: value }) =>
            !unCheckEmpty || checked || standard || value.trim() !== ''
        )
        .map((item, key) =>
          item.standard ? (
            <CheckListItem
              key={item.id || `${key + (item.itemIdx || 0)}`}
              item={item}
              changeHandler={checkboxChangeHandler}
              displayField={displayField}
            />
          ) : (
            <CheckListEditor
              key={item.id || `${key + (item.itemIdx || 0)}`}
              item={item}
              placeholder={editorPlaceholder}
              displayField={displayField}
              checkboxChangeHandler={checkboxChangeHandler}
              textAreaChangeHandler={textAreaChangeHandler}
              unCheckEmpty={unCheckEmpty}
            />
          )
        )}
    </div>
    <CheckListAdder
      itemInit={{ ...itemInit, itemIdx: items.length }}
      placeholder={adderPlaceholder}
      displayField={displayField}
      changeHandler={adderChangeHandler}
      unCheckEmpty={unCheckEmpty}
      textAreaDataQa={textAreaDataQa}
    />
  </div>
);

export default CheckList;
