import { useQuery } from 'react-query';
import apiHelper from '@/utils/api';
import apiWrapper from '@/core/api/apiWrapper';
import { businessLineQueryKey } from './queryKeys';

export interface BusinessLineQueryResponse {
  isBH: boolean;
  isEAP: boolean;
  isPsychiatry: boolean;
  isB2B: boolean;
  isVideoOnly: boolean;
  isB2C?: boolean;
  isDTE?: boolean;
  isB2BTeen: boolean;
}

const fetchBusinessLine = (roomID?: number | string, clientUserID?: number) => async () => {
  const { data } = await apiWrapper.get(
    `${apiHelper().apiEndpoint}/v2/rooms/${roomID}/clients/${clientUserID}/business-line`
  );
  return data.data;
};

const useQueryBusinessLine = (
  roomID?: number | string,
  clientUserID?: number,
  disabled?: boolean
) =>
  useQuery<BusinessLineQueryResponse, Error>({
    enabled: !!clientUserID && !!roomID && !disabled,
    queryKey: businessLineQueryKey(roomID, clientUserID),
    queryFn: fetchBusinessLine(roomID, clientUserID),
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

export default useQueryBusinessLine;
