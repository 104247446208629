import { FunctionComponent } from 'react';
import { View, JustifyType } from '@talkspace/react-toolkit';
import Spinner from '../Spinner/Spinner';
import styled, { EmotionStyle } from '../../../core/styled';

const StyledFooter = styled(View)({
  justifyContent: 'center !important',
  flexDirection: 'row',
  alignItems: 'center',
  height: 67,
  borderTop: '1px solid #D2D2D2',
  bottom: 0,
  padding: 10,
  left: 0,
  transition: 'height 0.2s ease 0s',
  width: '100%',
  position: 'sticky',
  backgroundColor: 'white',
});

const StyledFooterChildrenWrapper = styled(View)({
  maxWidth: 355,
  width: 355,
});

interface FooterProps {
  childComponents: JSX.Element[];
  footerStyle?: EmotionStyle;
  footerChildStyle?: EmotionStyle;
  justifyChildren?: JustifyType;
  showSpinner: boolean;
  isError: boolean;
  isUpdating: boolean;
  spinnerMessage: string;
}

const Footer: FunctionComponent<FooterProps> = ({
  childComponents,
  footerStyle = {},
  footerChildStyle = {},
  showSpinner,
  isError,
  isUpdating,
  spinnerMessage,
  justifyChildren = 'end',
}) => {
  const childComponentsArr = showSpinner ? (
    <Spinner spinnerMessage={spinnerMessage} isError={isError} isUpdating={isUpdating} />
  ) : (
    childComponents.map((childComponent, id) => (
      // eslint-disable-next-line react/no-array-index-key
      <View style={footerChildStyle} key={id}>
        {childComponent}
      </View>
    ))
  );

  return (
    <StyledFooter style={footerStyle}>
      <StyledFooterChildrenWrapper row justify={justifyChildren}>
        {childComponentsArr}
      </StyledFooterChildrenWrapper>
    </StyledFooter>
  );
};

export default Footer;
