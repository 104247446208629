import Svg, { Path, G, Defs, Rect } from 'svgs';
import { useEmotionTheme } from '../../core/styled';
import type { EmotionStyle } from '../../core/styled';
import { useUniqueID } from '../../hooks/a11yHelper';

interface Props {
  width?: number;
  height?: number;
  color?: string;
  style?: EmotionStyle;
}

const CalendarVOutlineIcon = ({ color, width = 20, height = 23, style }: Props) => {
  const { colors } = useEmotionTheme();
  const title = 'Calendar checkmark outline icon';
  const clipID = useUniqueID('clipID');
  return (
    <Svg
      width={width}
      height={height}
      title={title}
      aria-label={title}
      viewBox="0 0 20 23"
      fill="none"
      style={style}
    >
      <G clipPath={`url(#${clipID})`}>
        <Path
          d="M5.87 0.00390625C6.44148 0.00390625 6.90125 0.463672 6.90125 1.03516V2.75391H13.0887V1.03516C13.0887 0.463672 13.5485 0.00390625 14.12 0.00390625C14.6915 0.00390625 15.1512 0.463672 15.1512 1.03516V2.75391H16.87C18.3868 2.75391 19.62 3.98711 19.62 5.50391V6.19141V8.25391V19.2539C19.62 20.7707 18.3868 22.0039 16.87 22.0039H3.12C1.6032 22.0039 0.369995 20.7707 0.369995 19.2539V8.25391V6.19141V5.50391C0.369995 3.98711 1.6032 2.75391 3.12 2.75391H4.83875V1.03516C4.83875 0.463672 5.29851 0.00390625 5.87 0.00390625ZM17.5575 8.25391H2.4325V19.2539C2.4325 19.632 2.74187 19.9414 3.12 19.9414H16.87C17.2481 19.9414 17.5575 19.632 17.5575 19.2539V8.25391ZM14.5067 12.7656L9.69421 17.5781C9.29031 17.982 8.63718 17.982 8.23757 17.5781L5.48757 14.8281C5.08367 14.4242 5.08367 13.7711 5.48757 13.3715C5.89148 12.9719 6.5446 12.9676 6.94421 13.3715L8.96375 15.391L13.0458 11.309C13.4497 10.9051 14.1028 10.9051 14.5024 11.309C14.902 11.7129 14.9063 12.366 14.5024 12.7656H14.5067Z"
          fill={color || colors.grey950}
        />
      </G>
      <Defs>
        <clipPath id={clipID}>
          <Rect width="19.25" height="22" fill="white" transform="translate(0.369995 0.00390625)" />
        </clipPath>
      </Defs>
    </Svg>
  );
};

export default CalendarVOutlineIcon;
