import PropTypes from 'prop-types';
import './Buttons.css';

const Button = ({
  title,
  isSecondary,
  clickHandler,
  isActive,
  style,
  selected = false,
  dataQa,
}) => (
  <div
    className={`card-button ${isSecondary || !isActive ? 'secondary' : ''}${
      selected ? ' selected' : ''
    }`}
    onClick={isActive ? clickHandler : () => null}
    style={style}
    data-qa={dataQa}
  >
    {title}
  </div>
);

Button.propTypes = {
  title: PropTypes.string,
  dataQa: Promise.string,
};

Button.defaultProps = {
  isActive: true,
  style: {},
};

export default Button;
