import {
  Large,
  Small,
  Standard,
  styled,
  TextArea,
  TouchableView,
  View,
} from '@talkspace/react-toolkit';
import { FunctionComponent, useEffect, useState } from 'react';
import Footer from 'components/Reusable/Footer/Footer';
import AlertContainer from 'components/Reusable/AlertContainer/AlertContainer';
import trashIcon from 'components/Icons/trash.svg';
import useMutationSavePsychotherapyNote, {
  SavePsychotherapyNoteMutateVariables,
} from 'hooks/notes/useMutationSavePsychotherapyNote';
import useQueryPsychotherapyNote from 'hooks/notes/useQueryPsychotherapyNote';
import useQueryNotes from 'hooks/notes/useQueryNotes';
import Submenu from 'components/Reusable/Submenu/Submenu';
import { useHistory, useParams } from '@/core/routerLib';
import { FormMode } from './types';
import { ACTION_SETTLED_TIMEOUT, getSubmenuTitleAndSubtitle } from './utils';

const Wrapper = styled(View)({
  height: '100%',
});

const TextAreaStyled = styled(TextArea)(({ theme: { colors } }) => {
  return {
    fontSize: 16,
    height: '100%',
    border: 'none',
    resize: 'none',
    caretColor: colors.green,
  };
});

const FooterWrapper = styled(View)({
  justifyContent: 'space-between',
  flexDirection: 'row',
});

const CloseButton = styled(TouchableView)(({ theme: { colors } }) => {
  return {
    color: colors.green,
    fontWeight: 900,
  };
});

const CancelButton = styled(TouchableView)({
  alignItems: 'center',
  justifyContent: 'center',
  width: 49,
  marginRight: 33,
});

const SaveButton = styled(TouchableView)((props: { faded: boolean }) => {
  return {
    width: 52,
    opacity: props.faded ? 0.4 : 1,
  };
});

const TrashIcon = styled(TouchableView)({
  width: 18,
  height: 22,
  background: `url(${trashIcon})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  marginLeft: 15,
});

const PsychotherapyNoteForm: FunctionComponent<{ mode: FormMode }> = ({ mode }) => {
  const history = useHistory();
  const { roomID, noteID } = useParams<{ roomID: string; noteID?: string }>();

  const [text, setText] = useState('');
  const [isErrorValidation, setIsErrorValidation] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [actionMade, setActionMade] = useState('Saved');

  useQueryNotes(roomID);

  const {
    mutate: savePsychotherapyNote,
    isLoading: isSubmitLoading,
    isError: isSubmitError,
  } = useMutationSavePsychotherapyNote();

  const {
    isLoading: isQueryLoading,
    isError: isQueryError,
    data: psychotherapyNoteData,
  } = useQueryPsychotherapyNote(roomID, noteID, mode === 'edit');

  const handleActionSettled = () => {
    setTimeout(() => {
      setShowSpinner(false);
      history.push(`/room/${roomID}/notes-tab`);
    }, ACTION_SETTLED_TIMEOUT);
  };

  useEffect(() => {
    if (psychotherapyNoteData?.noteBody) {
      setText(psychotherapyNoteData.noteBody);
    }
  }, [psychotherapyNoteData]);

  const handleTextChange = (value: string) => {
    setText(value);
  };

  const handleBackButtonPress = () => {
    if (text && mode === 'create') {
      const locationState: SavePsychotherapyNoteMutateVariables = {
        noteID,
        roomID,
        noteBody: text,
      };
      history.push(`/room/${roomID}/psychotherapy-notes/save`, locationState);
    } else {
      history.push(`/room/${roomID}/notes-tab`);
    }
  };

  const handleCancelButtonPress = () => {
    history.push(`/room/${roomID}/notes-tab`);
  };

  const handleSavePress = () => {
    if (!text) {
      setIsErrorValidation(true);
    } else {
      setActionMade('Saved');
      setShowSpinner(true);
      savePsychotherapyNote({ roomID, noteID, noteBody: text }, { onSettled: handleActionSettled });
    }
  };

  const handleDeleteDraftPress = () => {
    history.push(`/room/${roomID}/psychotherapy-notes/${noteID}/delete`);
  };

  const isLoading = isSubmitLoading || isQueryLoading;
  const isError = isSubmitError || isQueryError;

  const renderFooterChildren = () => [
    <FooterWrapper>
      {(() => {
        if (isErrorValidation) {
          return (
            <AlertContainer
              title="Missing information"
              childComponents={[
                <CloseButton onPress={() => setIsErrorValidation(false)}>Close</CloseButton>,
              ]}
              body="Review and complete the required fields to continue"
              containerStyle={{
                width: 355,
                border: 'none',
              }}
            />
          );
        }
        return (
          <>
            {noteID ? <TrashIcon key={1} onPress={handleDeleteDraftPress} /> : <View />}
            <View style={{ flexDirection: 'row' }}>
              <CancelButton onPress={handleCancelButtonPress}>
                <Standard variant="standardBoldGreen">Cancel</Standard>
              </CancelButton>
              <SaveButton onPress={handleSavePress} faded={!text} disabled={!text}>
                <Standard variant="standardBoldGreen">Save</Standard>
              </SaveButton>
            </View>
          </>
        );
      })()}
    </FooterWrapper>,
  ];

  const { title, subtitle } = getSubmenuTitleAndSubtitle({
    formMode: mode,
    noteType: 'psychotherapy',
    noteDate: psychotherapyNoteData?.createdAt,
  });

  return (
    <Wrapper>
      <Submenu
        title={title}
        titleComponent={
          subtitle ? (
            <View>
              <Large variant="largeBoldWide">{title}</Large>
              <Small variant="smallBoldGrey">{subtitle}</Small>
            </View>
          ) : undefined
        }
        childComponents={[
          <TextAreaStyled autoFocus value={text} onChangeText={handleTextChange} />,
        ]}
        bodyStyle={{ padding: 0 }}
        childComponentsWrapperStyle={{ height: '100%' }}
        footerComponent={
          <Footer
            childComponents={renderFooterChildren()}
            isError={isError}
            isUpdating={isLoading}
            showSpinner={showSpinner}
            spinnerMessage={`${actionMade} successfully`}
            footerChildStyle={{ width: '100%' }}
            footerStyle={{ height: isError ? 150 : undefined }}
          />
        }
        onBackAlt={handleBackButtonPress}
      />
    </Wrapper>
  );
};

export default PsychotherapyNoteForm;
