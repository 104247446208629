import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import createHistory from 'history/createBrowserHistory';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { FlagsProvider } from 'launchDarkly/FlagsProvider';
import {
  A11yProvider,
  EmotionThemeProvider,
  ErrorBoundary,
  useWindowWidthState,
  WindowWidthContextProvider,
  HtmlMetaManager,
  GoogleMapsProvider,
} from '@talkspace/react-toolkit';
import { TranslationProvider } from '@talkspace/i18n/hooks/translationContext';
import { InRoomSchedulingProvider } from 'inRoomScheduling';
import ChatMessageContextProvider from 'chat/hooks/chatMessageContext';
import LDTherapistIdentifier from 'launchDarkly/LDTherapistIdentifier';
import { useEffect } from 'react';
import { PromiseMessageContextProvider } from 'ts-promise-message';
import {
  PSYCHIATRIST_CASELOAD_FILTERS,
  THERAPIST_CASELOAD_FILTERS,
} from 'components/Dashboard/DashboardFilter/constants';
import { updateWebVersion } from 'ts-ionic/plugins/liveUpdates';
import { IonicPasscodeContainer, CreatePasscode } from 'ts-ionic/modules/passcode';
import { PushNotificationsContainer } from 'ts-ionic/modules/pushNotifications';
import { detectEnv } from './utils/configs';
import ModalsProvider from '@/utils/ModalsContextProvider';
import ProviderLeavingWizard from '@/ProviderLeavingWizard';
import Signup from '@/signup';
import AuthContainer from '@/auth/containers/AuthContainer';
import UnauthorizedScreen from '@/auth/screens/UnauthorizedScreen';
import ModalRoutes from './ModalRoutes';
import Dashboard from './components/Dashboard/Dashboard';
import Login from './components/Login/Login';
import Logout from './components/Logout/Logout';
import TwoFactor from './components/TwoFactor/TwoFactor';
import Account from './components/Account/Account';
import Room from './components/Room/Room';
import Performance from './modules/performance';
import { tokenIsValid } from './utils/tokenIsValid';
import store from './store';
import JwtContainer from './utils/jwt/JwtContainer';
import TherapistForgotPassword from './components/ForgotPassword/ForgotPassword';
import TherapistChangePassword from './components/ChangePassword/ChangePassword';
import InRoomSchedulingFramedContainer from './components/FrameContainers/InRoomSchedulingFramedContainer';
import storage from './core/storage';
import colorOverrides from './utils/colorOverrides';
import trackEvent from './modules/analytics/trackEvent';
import { LDFlagsFormatter } from './utils/LDFlagsFormatting';
import { crashlyticsErrorHandler } from './utils/crashlyticsErrorHandler';
import { isIonic } from '@/auth/reactFrame/helpers';
import useIonicEffect from './hooks/useIonicEffect';
import { getTokens } from './utils/token';
import configs from '@/utils/configs';

const queryClient = new QueryClient();
const history = createHistory();

const FlagsLoggerToMixpanel = () => {
  const ldClient = useLDClient();
  useEffect(() => {
    ldClient.on('change', (flags) => {
      const formattedFlags = LDFlagsFormatter(flags);
      if (tokenIsValid()) {
        trackEvent(
          'LDFlagsChanged',
          { actionName: 'activeExperiments' },
          { LDFlags: formattedFlags, flagsUpdated: true }
        );
      }
    });
    return () => ldClient.off('change');
  }, [ldClient]);
  return null;
};

const UpdateIonicWebVersion = () => {
  useIonicEffect(() => {
    updateWebVersion();
  }, []);
  return null;
};

const Routes = () => {
  const windowWidth = useWindowWidthState();
  const theme = {
    window: windowWidth,
  };

  useEffect(() => {
    const unlisten = history.listen((location) => {
      const pagesToTrack = [
        '/tasks',
        '/rooms',
        '/calendar',
        '/payment-reports',
        '/earnings',
        '/my-account',
        '/performance/reviews',
      ];
      if (pagesToTrack.includes(location.pathname)) {
        trackEvent('pageView', { actionName: 'pageNavigation' });
      }
    });
    return unlisten;
  }, []);

  useEffect(() => {
    // on ionic , clear the caseload filters if therapist app top layer is being mounted (meaning the app has been fully closed and than opened again)
    if (isIonic()) {
      storage.removeItem(THERAPIST_CASELOAD_FILTERS);
      storage.removeItem(PSYCHIATRIST_CASELOAD_FILTERS);
    }
  }, []);

  // A11yProvider is currently a dumby provider. We need it here so that shared chat components that need to toggle colors in client-web don't break in therapist-web. If we wanted to give therapists the ability to toggle color contrast we would need to set up an api that would set and update users.high_contrast and provide the colors dictionary to the colors theme based on the setting
  return (
    <A11yProvider>
      <EmotionThemeProvider theme={theme} colorsOverrides={colorOverrides}>
        <GoogleMapsProvider apiKey={configs.googleMaps.publicKey}>
          <QueryClientProvider client={queryClient}>
            <TranslationProvider>
              <PromiseMessageContextProvider>
                <ModalsProvider ModalRoutes={ModalRoutes}>
                  <ErrorBoundary
                    removeErrorScreen
                    onError={(error, errorInfo) => {
                      crashlyticsErrorHandler(error, errorInfo);
                    }}
                  >
                    <Router history={history}>
                      <AuthContainer successRoute="/rooms">
                        <LDTherapistIdentifier>
                          <FlagsProvider>
                            <FlagsLoggerToMixpanel />
                            {isIonic() && <UpdateIonicWebVersion />}
                            {isIonic() && <IonicPasscodeContainer getTokens={getTokens} />}
                            <HtmlMetaManager />
                            <PushNotificationsContainer>
                              <Switch>
                                <Route
                                  exact
                                  path="/"
                                  render={(props) => {
                                    if (tokenIsValid()) {
                                      return <Redirect to="/rooms" />;
                                    }
                                    return <Redirect to="/login" />;
                                  }}
                                />
                                <Route exact path="/login" component={Login} />
                                <Route path="/signup" component={Signup} />
                                <Route exact path="/two-factor" component={TwoFactor} />
                                <Route
                                  exact
                                  path="/change-password"
                                  component={TherapistChangePassword}
                                />
                                <Route
                                  path="/passcode"
                                  render={(props) => {
                                    if (tokenIsValid()) {
                                      return <CreatePasscode />;
                                    }
                                    return <Redirect push to="/login" />;
                                  }}
                                />
                                <Route
                                  path={[
                                    '/provider-leaving/source/:source/context-id/:contextID',
                                    '/provider-leaving/source/:source',
                                    '/provider-leaving',
                                  ]}
                                  render={({ history }) => {
                                    if (tokenIsValid()) {
                                      return <ProviderLeavingWizard history={history} />;
                                    }
                                    storage.setItem('redirectUrl', `/provider-leaving`);
                                    return <Redirect push to="/login" />;
                                  }}
                                />
                                <Route
                                  exact
                                  path="/forgot-password"
                                  component={TherapistForgotPassword}
                                />
                                <Route
                                  path="/rooms"
                                  render={(props) => {
                                    if (tokenIsValid()) {
                                      return <Dashboard />;
                                    }
                                    return <Redirect push to="/login" />;
                                  }}
                                />
                                <Route
                                  path="/tasks"
                                  render={(props) => {
                                    if (tokenIsValid()) {
                                      return <Dashboard />;
                                    }
                                    return <Redirect push to="/login" />;
                                  }}
                                />
                                <Route
                                  path="/earnings"
                                  render={(props) => {
                                    if (tokenIsValid()) {
                                      return <Dashboard />;
                                    }
                                    return <Redirect push to="/login" />;
                                  }}
                                />
                                <Route
                                  path="/payment-reports"
                                  render={(props) => {
                                    if (tokenIsValid()) {
                                      return <Dashboard />;
                                    }
                                    return <Redirect push to="/login" />;
                                  }}
                                />
                                <Route
                                  path="/calendar"
                                  render={(props) => {
                                    if (tokenIsValid()) {
                                      return <Dashboard />;
                                    }
                                    return <Redirect push to="/login" />;
                                  }}
                                />
                                <Route
                                  path="/grow-caseload"
                                  render={(props) => {
                                    if (tokenIsValid()) {
                                      return <Dashboard />;
                                    }
                                    return <Redirect push to="/login" />;
                                  }}
                                />

                                <Route
                                  path="/clinical-hours"
                                  render={(props) => {
                                    if (tokenIsValid()) {
                                      return <Dashboard />;
                                    }
                                    return <Redirect push to="/login" />;
                                  }}
                                />

                                <Route
                                  path="/my-account"
                                  render={(props) => {
                                    if (tokenIsValid()) {
                                      return <Account />;
                                    }
                                    return <Redirect push to="/login" />;
                                  }}
                                />
                                <Route
                                  path="/my-account?hideHeaderFooter"
                                  render={(props) => {
                                    if (tokenIsValid()) {
                                      return <Account />;
                                    }
                                    return <Redirect push to="/login" />;
                                  }}
                                />
                                <Route
                                  path="/room/:roomID"
                                  render={(props) => {
                                    if (tokenIsValid()) {
                                      return <Room {...props} />;
                                    }
                                    return <Redirect push to="/login" />;
                                  }}
                                />
                                <Route
                                  path="/performance"
                                  render={(props) => {
                                    if (tokenIsValid()) {
                                      return <Performance {...props} />;
                                    }
                                    return <Redirect push to="/login" />;
                                  }}
                                />
                                <Route exact path="/logout" component={Logout} />
                                <Route path="/public" component={JwtContainer} />
                                <Route exact path="/unauthorized" component={UnauthorizedScreen} />
                                <Route
                                  path="/in-room-scheduling/room/:roomID"
                                  render={() => (
                                    <ChatMessageContextProvider>
                                      <InRoomSchedulingProvider>
                                        <InRoomSchedulingFramedContainer />
                                      </InRoomSchedulingProvider>
                                    </ChatMessageContextProvider>
                                  )}
                                />
                                <Route
                                  exact
                                  path="*"
                                  render={(props) => {
                                    if (tokenIsValid()) {
                                      return <Redirect push to="/rooms" />;
                                    }
                                    return <Redirect push to="/login" />;
                                  }}
                                />
                              </Switch>
                            </PushNotificationsContainer>
                          </FlagsProvider>
                        </LDTherapistIdentifier>
                      </AuthContainer>
                    </Router>
                  </ErrorBoundary>
                </ModalsProvider>
              </PromiseMessageContextProvider>
              {detectEnv() === 'local' && <ReactQueryDevtools initialIsOpen={false} />}
            </TranslationProvider>
          </QueryClientProvider>
        </GoogleMapsProvider>
      </EmotionThemeProvider>
    </A11yProvider>
  );
};

function withWindowWidth(WrappedComponent) {
  return function WindowWidthWrapper() {
    return (
      <WindowWidthContextProvider>
        <WrappedComponent />
      </WindowWidthContextProvider>
    );
  };
}

export default withWindowWidth(Routes);
