/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useCallback, createContext, useContext } from 'react';
import {
  useSimpleReducer,
  receiveActionPayload,
  errorActionPayload,
  requestActionPayload,
} from '@talkspace/react-toolkit';
import { PerformanceState, PerformanceAction } from '../types';
import API from '../utils/apiHelper';

export interface PerformanceActions {
  dispatchGetReviewsList: () => Promise<void>;
  dispatchGetMetrics: () => Promise<void>;
  dispatchGetReviewMetrics: () => Promise<void>;
}

export const StateContext = createContext<PerformanceState | undefined>(undefined);
export const ActionsContext = createContext<PerformanceActions | undefined>(undefined);

export const initialState: PerformanceState = {
  metrics: {
    responseTime: null,
    retention: null,
    progressReportsAvg: null,
    b2BSessions: null,
    utilization: null,
  },
  reviewMetrics: {
    averageRating: null,
    percentile: null,
    improvements: [],
    qualities: [],
  },
  reviewsList: [],
  page: 0,
  total: 0,
  isLoading: false,
  isError: false,
  hasMore: false,
};

function PerformanceProvider({ children }) {
  const [state, dispatch] = useSimpleReducer<PerformanceState, PerformanceAction>(initialState);
  const stateRef = useRef(state);
  stateRef.current = state;

  async function dispatchGetReviewsList(): Promise<void> {
    dispatch({ type: 'dispatchGetReviewsList', payload: requestActionPayload });
    try {
      const { page, reviewsList } = stateRef.current;
      const nextPage = page + 1;
      const { total, reviews: nextReviewsList } = await API.getReviewsList(nextPage);
      dispatch({
        type: 'receiveGetReviewsList',
        payload: {
          reviewsList: [...reviewsList, ...nextReviewsList],
          page: nextPage,
          total,
          hasMore: nextReviewsList.length === 20,
          ...receiveActionPayload,
        },
      });
    } catch (error) {
      dispatch({
        type: 'setIsError',
        payload: errorActionPayload,
      });
    }
  }

  async function dispatchGetMetrics(): Promise<void> {
    dispatch({ type: 'dispatchGetMetrics', payload: requestActionPayload });
    try {
      const metrics = await API.getMetrics();
      dispatch({
        type: 'receiveGetMetrics',
        payload: { metrics, ...receiveActionPayload },
      });
    } catch {
      dispatch({
        type: 'setIsError',
        payload: errorActionPayload,
      });
    }
  }

  async function dispatchGetReviewMetrics(): Promise<void> {
    dispatch({ type: 'dispatchGetReviewMetrics', payload: requestActionPayload });
    try {
      const reviewMetrics = await API.getReviewMetrics();
      dispatch({
        type: 'receiveGetReviewMetrics',
        payload: { reviewMetrics, ...receiveActionPayload },
      });
    } catch {
      dispatch({
        type: 'setIsError',
        payload: errorActionPayload,
      });
    }
  }

  const actions: PerformanceActions = {
    dispatchGetReviewsList: useCallback(dispatchGetReviewsList, []),
    dispatchGetMetrics: useCallback(dispatchGetMetrics, []),
    dispatchGetReviewMetrics: useCallback(dispatchGetReviewMetrics, []),
  };

  return (
    <StateContext.Provider value={state}>
      <ActionsContext.Provider value={actions}>{children}</ActionsContext.Provider>
    </StateContext.Provider>
  );
}

function usePerformanceState(): PerformanceState {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error('reviews state context must be used within PerformanceProvider');
  }
  return context;
}

function usePerformanceActions() {
  const context = useContext(ActionsContext);
  if (context === undefined) {
    throw new Error('reviews action context must be used within PerformanceProvider');
  }
  return context;
}

export { PerformanceProvider, usePerformanceState, usePerformanceActions };
