import { FunctionComponent, Fragment, useState } from 'react';
import useTranslation from '@talkspace/i18n/hooks/useTranslation';
import { BusinessDaysBool } from 'ts-frontend/types';
import BarElement from './BarElement';
import MarkerElement from './MarkerElement';
import styled, { EmotionStyle, useEmotionTheme } from '../../core/styled';
import BusinessDay from './BusinessDay';
import { CaretRight, CaretLeft } from '../icons';
import View from '../View';
import Text from '../Text';
import TouchableView from '../TouchableView';
import TabRadioGroup from '../TabRadioGroup';
import { IBHChartHiddenText } from './ImplicitBusinessHoursChart.a11y';
import { webOnlyStyle } from '../../core/styleHelpers';
import { useUniqueID } from '../../hooks/a11yHelper';

export interface BusinessHoursChartProps {
  implicitBusinessHoursByDay?: ImplicitBusinessHoursByDay;
  businessDays?: BusinessDays | BusinessDaysBool;
  businessDaysQM?: BusinessDaysQM;
  containerStyle?: EmotionStyle;
  daysContainerStyle?: EmotionStyle;
  showArrows?: boolean;
  roundedFocusStyle?: boolean;
}

export interface BusinessDays {
  Sunday?: number;
  Monday?: number;
  Tuesday?: number;
  Wednesday?: number;
  Thursday?: number;
  Friday?: number;
  Saturday?: number;
}

export interface BusinessDaysQM {
  sunday?: boolean;
  monday?: boolean;
  tuesday?: boolean;
  wednesday?: boolean;
  thursday?: boolean;
  friday?: boolean;
  saturday?: boolean;
}

export interface ImplicitBusinessHoursByDay {
  mon: number[];
  tue: number[];
  wed: number[];
  thu: number[];
  fri: number[];
  sat: number[];
  sun: number[];
}

const TextOverlay = styled(Text)(({ theme: { colors } }) => {
  return {
    fontWeight: 600,
    fontSize: 14,
    height: 10,
    marginTop: 23,
    color: colors.extraLightGrey,
    textAlign: 'center',
  };
});

const DaysContainer = styled(View)({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignSelf: 'stretch',
});

const ChartContainer = styled(View)({
  height: 64,
  flexDirection: 'row',
  justifyContent: 'space-around',
  alignItems: 'flex-end',
  alignSelf: 'stretch',
});

const BusinessDayText = styled(Text)<{
  active: boolean;
  disabled: boolean;
}>(({ active, disabled, theme: { colors } }) => {
  let color = disabled ? colors.extraLightGrey : colors.black;
  if (active) color = colors.white;
  return {
    fontSize: 14,
    color,
    ...webOnlyStyle({
      textDecoration: disabled ? 'line-through' : 'none',
    }),
  };
});

const businessDaysDict = {
  mon: 'Monday',
  tue: 'Tuesday',
  wed: 'Wednesday',
  thu: 'Thursday',
  fri: 'Friday',
  sat: 'Saturday',
  sun: 'Sunday',
};

export type BusinessHoursDay = 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun';

const capitalize = (str: string): string => `${str.charAt(0).toUpperCase()}${str.slice(1)}`;

const BarChartView: FunctionComponent<BusinessHoursChartProps> = ({
  implicitBusinessHoursByDay,
  businessDays,
  businessDaysQM,
  showArrows,
  containerStyle,
  daysContainerStyle,
  roundedFocusStyle,
}) => {
  const { colors } = useEmotionTheme();
  const businessDaysSet = new Set<BusinessHoursDay>();
  Object.entries(businessDays || businessDaysQM || {}).forEach(([key, value]) => {
    if (value) businessDaysSet.add(key.substring(0, 3).toLowerCase() as BusinessHoursDay);
  });

  const days: BusinessHoursDay[] = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

  const emptyData = new Array<number>(12).fill(0);
  const now = new Date();
  const currentHourFrame = Math.floor(now.getHours() / 2);
  const [selectedDay, setSelectedDay] = useState<BusinessHoursDay>(days[now.getDay()]);
  const showTextOverlay = !implicitBusinessHoursByDay || !businessDaysSet.has(selectedDay);
  const data = implicitBusinessHoursByDay ? implicitBusinessHoursByDay[selectedDay] : emptyData;
  const { t: tnewMemberNav } = useTranslation('newMemberNav');
  let textOverlay: string | null = null;
  if (showTextOverlay) {
    textOverlay = tnewMemberNav('providerProfile.insufficient', 'Insufficient Data', undefined);
    if (!businessDaysSet.has(selectedDay) && data) {
      textOverlay = `Off on ${businessDaysDict[selectedDay]}`;
    }
  }

  const handleMoveLeft = () => {
    const newIndex = (days.indexOf(selectedDay) + 6) % 7;
    setSelectedDay(days[newIndex]);
  };

  const handleMoveRight = () => {
    const newIndex = (days.indexOf(selectedDay) + 1) % 7;
    setSelectedDay(days[newIndex]);
  };

  const chartPanelID = useUniqueID('chartPanelID');
  const mostActiveTextID = useUniqueID('mostActiveTextID');
  const textOverlayID = useUniqueID('textOverlayID');

  const tabListStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  };

  return (
    <View align="center" style={{ alignSelf: 'stretch', ...containerStyle }}>
      <DaysContainer style={daysContainerStyle}>
        {showArrows && (
          <TouchableView
            dataQa="previousDayButton"
            roundedFocusStyle={roundedFocusStyle}
            primaryColor={colors.green}
            onPress={handleMoveLeft}
            style={{ marginRight: '5%', borderRadius: 5 }}
          >
            <CaretLeft hasHoverStyles />
          </TouchableView>
        )}
        <TabRadioGroup style={tabListStyle} initialSelection isTabList>
          {days.map((d) => {
            const day = businessDaysDict[d];
            const selected = selectedDay === d;
            const disabled = !businessDaysSet.has(d);
            let ariaDescribedBy;
            if (showTextOverlay) {
              ariaDescribedBy = textOverlayID;
            } else if (selected) {
              ariaDescribedBy = mostActiveTextID;
            }
            return (
              <BusinessDay
                dataQa={`${day}Button`}
                roundedFocusStyle={roundedFocusStyle}
                primaryColor={colors.green}
                aria-label={disabled ? `${day}, off` : day}
                aria-describedby={ariaDescribedBy}
                key={d}
                role="tab"
                aria-selected={selected}
                tabIndex={selected ? 0 : -1}
                aria-controls={chartPanelID}
                active={selected}
                onPress={() => setSelectedDay(d)}
              >
                <BusinessDayText active={selected} disabled={disabled}>
                  {capitalize(d)}
                </BusinessDayText>
              </BusinessDay>
            );
          })}
        </TabRadioGroup>
        {showArrows && (
          <TouchableView
            dataQa="nextDayButton"
            roundedFocusStyle={roundedFocusStyle}
            primaryColor={colors.green}
            onPress={handleMoveRight}
            style={{ marginLeft: '5%', borderRadius: 5 }}
          >
            <CaretRight hasHoverStyles />
          </TouchableView>
        )}
      </DaysContainer>
      <TextOverlay id={textOverlayID}>{textOverlay}</TextOverlay>
      <ChartContainer id={chartPanelID} role="tabpanel" aria-hidden="true">
        {(showTextOverlay ? emptyData : data).map((item, index) => (
          // don't show marker and label for last element
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={`chart-element-${index}`}>
            <BarElement value={item} isCurrentHour={index === currentHourFrame} />
            {index < 11 && <MarkerElement index={index} />}
          </Fragment>
        ))}
      </ChartContainer>
      <IBHChartHiddenText id={mostActiveTextID} data={data} />
    </View>
  );
};

export default BarChartView;
