import { useEffect, useState } from 'react';
import { Spinner } from '@talkspace/react-toolkit';
import { useParams } from '@/core/routerLib';
import {
  UnsubmittedLiveSession,
  UnsubmittedMessagingSession,
} from '../../../../../../../hooks/notes/useQueryUnsubmittedSessions';
import { Container } from '../styles';
import LiveSession from './LiveSession';
import MessaginSession from './MessagingSession';
import { Section } from '../../context/types';
import useDidUpdateEffect from '../../../../../../../hooks/useDidUpdateEffect';
import {
  useProgressNoteFormActions,
  useProgressNoteFormState,
} from '../../context/TherapistProgressNoteFormContext';
import {
  datesAreRequired,
  startDateAfterEndDate,
  endDateInFutureError,
  startDateInFutureError,
} from './utils';
import trackEvent from '../../../../../../../modules/analytics/trackEvent';

const ModalityID = {
  messaging: 1,
  live: 2,
};

interface SessionSectionProps {
  nextSection: Section;
}

const SessionSection = ({ nextSection }: SessionSectionProps) => {
  const { roomID } = useParams<{ roomID: string }>();
  const [error, setError] = useState<string>('');
  const {
    currentSection,
    serviceStartDate,
    serviceEndDate,
    formSections,
    videoCalls,
    modalityID,
    unsubmittedSession: session,
    unsubmittedFreeLiveCalls,
    unsubmittedSessionID,
  } = useProgressNoteFormState();
  const { setFormState } = useProgressNoteFormActions();

  useEffect(() => {
    // Should run only onMount
    setFormState({ videoCalls: [] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useDidUpdateEffect(() => {
    if (session?.submissionStatus && modalityID) {
      trackEvent(
        'openNote',
        {
          actionName: 'progressNotesInteraction',
        },
        {
          action: 'Provider opens note',
          roomID,
          noteStatus: session.submissionStatus,
          modalityID,
          serviceStartDate,
          serviceEndDate,
          unsubmittedSessionID,
        }
      );
    }
  }, [roomID, session?.submissionStatus, modalityID, unsubmittedSessionID]);

  useEffect(() => {
    if (session) {
      if (session?.sessionModality) {
        setFormState({ modalityID: ModalityID[session?.sessionModality] });
      }
      if (session?.serviceStartDate) {
        setFormState({ serviceStartDate: new Date(session.serviceStartDate) });
      }
      if (!serviceEndDate) {
        if (session?.serviceEndDate) {
          setFormState({ serviceEndDate: new Date(session.serviceEndDate) });
        } else {
          setFormState({ serviceEndDate: new Date() });
        }
      }
    }
    setFormState({
      isSubmissionTooLateForPayout: session?.isSubmissionTooLateForPayout,
      submissionStatus: session?.submissionStatus,
      draftProgressNoteID: session?.draftProgressNoteID,
    });
  }, [session, setFormState, serviceEndDate]);

  const validateMessaging = () => {
    let errorText;
    if (!serviceStartDate || !serviceEndDate) {
      errorText = datesAreRequired;
    } else if (serviceEndDate < serviceStartDate) {
      errorText = startDateAfterEndDate;
    } else if (serviceStartDate && serviceStartDate > new Date()) {
      errorText = startDateInFutureError;
    } else if (serviceEndDate && serviceEndDate > new Date()) {
      errorText = endDateInFutureError;
    }
    if (errorText) {
      setError(errorText);
      setFormState({ showGlobalError: true });
      return;
    }
    setFormState({ asyncSessionID: (session as UnsubmittedMessagingSession).asyncSessionID });
  };

  const validateLive = () => {
    const videoCallID = (session as UnsubmittedLiveSession)?.liveCallID;
    if (videoCallID) {
      const videoCallExists = videoCalls.some((item) => item.videoCallID === videoCallID);
      setFormState({ liveCallID: videoCallID });
      if (!videoCallExists) {
        setFormState({
          videoCalls: [...videoCalls, { videoCallID }],
        });
      }
    }
  };

  const handleConfirmPress = () => {
    if (session?.sessionModality === 'messaging') {
      validateMessaging();
    } else {
      validateLive();
    }

    setFormState({
      formSections: {
        ...formSections,
        sessionDateAndModality: {
          open: false,
          changed: true,
          completed: true,
        },
        ...(formSections.sessionInformation.completed
          ? {}
          : {
              sessionInformation: {
                ...formSections.sessionInformation,
                open: true,
              },
            }),
      },
    });
  };

  useDidUpdateEffect(() => {
    if (currentSection === 'sessionDateAndModality') {
      setFormState({ currentSection: nextSection });
      handleConfirmPress();
    }
  }, [currentSection]);

  const handleSetError = (value: string) => {
    setError(value);
  };

  return (
    <>
      {session && unsubmittedFreeLiveCalls && unsubmittedSessionID ? (
        <>
          {session?.sessionModality === 'live' ? (
            <LiveSession
              unsubmittedFreeLiveCalls={unsubmittedFreeLiveCalls}
              duration={(session as UnsubmittedLiveSession).liveCallDuration}
              scheduledStartDate={session.scheduledStartDate}
              liveSessionType={(session as UnsubmittedLiveSession).liveSessionType}
              freeLiveCallIDs={(session as UnsubmittedLiveSession).freeLiveCallIDs}
              unsubmittedSessionID={unsubmittedSessionID}
            />
          ) : (
            <MessaginSession
              isStartDateFixed={(session as UnsubmittedMessagingSession).isStartDateFixed}
              error={error}
              setError={handleSetError}
              wordCount={(session as UnsubmittedMessagingSession).wordCount}
              wordDuration={(session as UnsubmittedMessagingSession).wordDuration}
              isEndDateFixed={(session as UnsubmittedMessagingSession).isEndDateFixed}
              isReopend={session.submissionStatus === 'reopenedAndReadyForReview'}
            />
          )}
        </>
      ) : (
        <Container>
          <Spinner />
        </Container>
      )}
    </>
  );
};

export default SessionSection;
