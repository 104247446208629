import { useEffect, useState } from 'react';
import { EmotionThemeProvider, Tag, TextDS, Link, styled, View } from '@talkspace/react-toolkit';
import { Col, Panel } from 'react-bootstrap';

import ssoHelper from '@/utils/sso';
import useQueryAutomaticMessages from '../../../hooks/account/useQueryAutomaticMessages';
import { getUserData } from '../../../utils/token';
import AutomaticMessageItem from './AutomaticMessageItem';
import { SetIsSectionLoadedFn } from '../accountTypes';

const StyledLink = styled(Link)(({ theme: { colorRoles } }) => {
  return {
    textDecoration: 'none',
    fontWeight: 'bold',
    color: colorRoles.typography.textBrandDefault,
    fontSize: 14,
  };
});

interface Props {
  setIsSectionLoaded: SetIsSectionLoadedFn;
}

export default function AutomaticMessages({ setIsSectionLoaded }: Props) {
  const [isSectionReady, setIsSectionReady] = useState(false);

  const { id: therapistUserID } = getUserData();

  const { data: automaticMessages = [], isLoading } = useQueryAutomaticMessages(therapistUserID);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    setIsSectionLoaded({ automaticMessages: true });
    setIsSectionReady(true);
  }, [automaticMessages, isLoading, setIsSectionLoaded]);

  return (
    <EmotionThemeProvider version="2.0.0">
      <div>
        <Col xs={12} className={`ts-panel-title ${isSectionReady ? 'show-panel' : 'hidden-panel'}`}>
          <Col
            xs={5}
            className="ts-font-black"
            style={{ display: 'flex', flexDirection: 'row', gap: 4, alignItems: 'center' }}
          >
            <View>Automatic messages</View>
            <Tag text="New!" color="successDefault" />
          </Col>
        </Col>
        <Col xs={12}>
          <Panel
            className={`ts-my-account-panel ${isSectionReady ? 'show-panel' : 'hidden-panel'}`}
          >
            {(isSectionReady ? automaticMessages : []).map((message) => (
              <AutomaticMessageItem key={message.id} message={message} />
            ))}

            <TextDS variant="headingSm" style={{ marginTop: 24 }}>
              <TextDS variant="headingSm" as="span" style={{ fontWeight: 'normal' }}>
                Have questions?
              </TextDS>
              <StyledLink
                onClick={() => ssoHelper.openZendesk('hc/en-us/articles/30838802915099')}
                text="Learn more about automatic messages"
                target="_blank"
                dataQa="learnMoreAboutAutomaticMessagesLink"
              />
            </TextDS>
          </Panel>
        </Col>
      </div>
    </EmotionThemeProvider>
  );
}
