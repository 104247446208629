import { EmotionStyle } from '../core/styled';
import { AlignType, DirectionType, JustifyType } from '../types';
import { isApple } from './deviceUtils';

export const getButtonStyles = (
  colors: {
    activeColors?: {
      color?: string;
      backgroundColor?: string;
    };
    hoverColors?: {
      color?: string;
      backgroundColor?: string;
    };
  },
  buttonState: {
    isSelected?: boolean;
    isHovering?: boolean;
    isClicked?: boolean;
    removeTransition?: boolean;
  }
) => {
  // Video of intended behavior:
  // https://app.vidgrid.com/view/89u1PYqKil6l

  // If `isSelected` is true, always show active styles,
  // otherwise use the CSS pseudo-class and let it happen natively
  const activeStyles = colors.activeColors && {
    ...(buttonState.isSelected
      ? {
          color: colors.activeColors?.color,
          backgroundColor: colors.activeColors?.backgroundColor,
          transition: buttonState.removeTransition ? 'none' : 'background-color 0.2s ease-in-out',
        }
      : {
          '&:active': {
            color: colors.activeColors?.color,
            backgroundColor: colors.activeColors?.backgroundColor,
            transition: buttonState.removeTransition ? 'none' : 'background-color 0.2s ease-in-out',
          },
        }),
  };

  const hoverStyles = !buttonState.isSelected &&
    !buttonState.isClicked && {
      color: colors.hoverColors?.color,
      backgroundColor: colors.hoverColors?.backgroundColor,
    };

  return {
    // Apple touch devices's `:active` triggers too early (on hover)
    // Put ':active' styles before ':focus' on Android touch devices only
    ...(isApple() && activeStyles),
    // Helps showing focus when the user focuses with keyboard
    '&:focus': {
      ...hoverStyles,
    },
    // Adds styles when user is hovering or clicking on the element
    ...(buttonState.isHovering &&
      colors.hoverColors && {
        '&:hover': {
          ...hoverStyles,
        },
      }),
    ...(!isApple() && activeStyles),
  };
};

export const prependFlex = (str: JustifyType | AlignType): string =>
  str === 'start' || str === 'end' ? `flex-${str}` : str;

export const getFlexDirection = (row?: boolean, reverse?: boolean): DirectionType => {
  if (row) {
    if (reverse) {
      return 'row-reverse';
    }
    return 'row';
  }
  if (reverse) {
    return 'column-reverse';
  }
  return 'column';
};

export const truncateTextMaxLinesStyle = (maxLines: number): EmotionStyle => {
  return {
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitLineClamp: `${maxLines}`,
    WebkitBoxOrient: 'vertical',
  };
};
