import { useMutation, useQueryClient } from 'react-query';
import apiHelper from '@/utils/api';
import apiWrapper from '../../utils/apiWrapper';
import { DischargeNoteFormState } from '../../components/Room/CRMContainer/NotesTab/types';
import { dischargeNoteQueryKey, notesListQueryKey } from './queryKeys';

export interface SaveDraftDischargeNoteMutateVariables extends DischargeNoteFormState {
  roomID: string;
  clientUserID?: number;
  noteID?: string;
}

const SaveDraftDischargeNote = async (
  params: SaveDraftDischargeNoteMutateVariables
): Promise<void> => {
  const { roomID, noteID, clientUserID, ...requestBody } = params;

  const requestURL = noteID
    ? `/v2/rooms/${roomID}/discharge-notes/${noteID}/draft`
    : `/v2/rooms/${roomID}/discharge-notes/draft`;

  const method: keyof typeof apiWrapper = noteID ? 'patch' : 'post';

  await apiWrapper[method](`${apiHelper().apiEndpoint}${requestURL}`, requestBody);
};

const useMutationSaveDraftDischargeNote = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, SaveDraftDischargeNoteMutateVariables>(SaveDraftDischargeNote, {
    onSuccess: (_, { roomID, noteID, clientUserID }) => {
      queryClient.invalidateQueries(notesListQueryKey(roomID));
      if (noteID) {
        queryClient.invalidateQueries(dischargeNoteQueryKey(clientUserID, noteID));
      }
    },
  });
};

export default useMutationSaveDraftDischargeNote;
