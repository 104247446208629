import { ID_SENT_BY_THERAPIST, ID_SENT_BY_USER } from '@/utils/IDConstants';

export const getAriaDescribedby = (position: string, ariaDescribedBy: string | undefined) => {
  // informs a screen reader user of the author of any non-system message by referencing the dom id of a vissually hidden "Sent by you/{therapistName}" text element in ChatContainer
  let bubbleDescribedby: string = position === 'left' ? ID_SENT_BY_THERAPIST : ID_SENT_BY_USER;
  // appends the dom ids of any pre-existing text elements with which the message should be descrbed by
  if (ariaDescribedBy) {
    bubbleDescribedby += ` ${ariaDescribedBy}`;
  }

  return bubbleDescribedby;
};

export default getAriaDescribedby;
