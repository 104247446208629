import apiHelper from '../../core/api/apiHelper';
import apiWrapper from '../../core/api/apiWrapper';
import {
  Booking,
  PatchBookingPayload,
  TimeOff,
  MultipleTimeOff,
  AvailabilityAPIPayload,
  FeatureFlagValue,
  TimePeriod,
} from '../types';

export interface ApiResponse<T = unknown> {
  data?: T;
}

/**
 * api call to: GET `/api/v1/therapist/40704/unavailability-period`
 * @returns Promise
 */
function getMultipleTimeOffsFeatureFlag(): Promise<ApiResponse<{ data: FeatureFlagValue }>> {
  return apiWrapper.get(
    `${apiHelper().apiEndpoint}/v3/users/get-admin-config-value?key=multiple_time_offs`
  );
}

/**
 * api call to: GET `/api/v1/therapist/40704/unavailability-period`
 * @returns Promise
 */
function getTimeOff(therapistUserID: number): Promise<ApiResponse<{ data: TimeOff }>> {
  return apiWrapper.get(
    `${apiHelper().apiEndpoint}/api/v1/therapist/${therapistUserID}/unavailability-period`
  );
}

/**
 * api call to: GET `/api/v1/therapist/40704/unavailability-period`
 * @returns Promise
 */
function getMultipleTimeOffs(
  therapistUserID: number
): Promise<ApiResponse<{ data: MultipleTimeOff[] }>> {
  return apiWrapper.get(`${apiHelper().apiEndpoint}/v2/therapist/${therapistUserID}/time-offs`);
}

/**
 * api call to: GET `/v2/therapist/40704/bookings?start=2018-11-27 03:00:00&end=2018-11-28 03:00:00`
 * @returns Promise
 */
function getBookings(
  therapistUserID: number,
  start?: string,
  end?: string
): Promise<ApiResponse<{ data: Booking[] }>> {
  const queryParams = start && end ? `?startTime=${start}&endTime=${end}` : '';
  return apiWrapper.get(
    `${apiHelper().apiEndpoint}/v3/therapists/${therapistUserID}/bookings${queryParams}`
  );
}

/**
 * api call to: GET `/v2/therapist/40704/bookings?start=2018-11-27 03:00:00&end=2018-11-28 03:00:00`
 * @returns Promise
 */
function getMultipleBookings(
  therapistUserID: number,
  periods: TimePeriod[]
): Promise<ApiResponse<{ data: Booking[] }>> {
  return apiWrapper.post(
    `${apiHelper().apiEndpoint}/v3/therapists/${therapistUserID}/query-bookings`,
    { periods }
  );
}
/**
 * api call to: GET `/v3/room/432432/bookings`
 * @returns Promise
 */
function getRoomBookings(roomID: number): Promise<ApiResponse<{ data: Booking[] }>> {
  return apiWrapper.get(`${apiHelper().apiEndpoint}/v3/rooms/${roomID}/bookings`);
}
/**
 * api call to: PATCH `/v4/rooms/123/bookings/dgsdf-sdf-sfa-ddsfa`
 * @returns Promise
 */
function patchBooking(
  roomID: number,
  bookingID: string,
  { action, reason, hasBreakAfterSession }: PatchBookingPayload
): Promise<ApiResponse<{}>> {
  let hasBreakAfterSessionPayload: { hasBreakAfterSession?: boolean } = {};
  if (action === 'confirm' && hasBreakAfterSession !== undefined) {
    hasBreakAfterSessionPayload = { hasBreakAfterSession };
  }
  return apiWrapper.patch(
    `${apiHelper().apiEndpoint}/v4/rooms/${roomID}/bookings/${bookingID}`,
    reason ? { action, reason } : { action, ...hasBreakAfterSessionPayload }
  );
}

/**
 * api call to: PATCH `/v3/therapists/123/bookings/asdf-asdf-asdf
 * @returns Promise
 */
function dismissBooking(
  therapistUserID: number,
  bookingID: string,
  { action }: PatchBookingPayload
): Promise<ApiResponse<{}>> {
  return apiWrapper.patch(
    `${apiHelper().apiEndpoint}/v3/therapists/${therapistUserID}/bookings/${bookingID}`,
    { action }
  );
}

/**
 * api call to: GET `/v2/therapists/432343/availability`
 * @returns Promise
 */
function getAvailability(therapistUserID: number): Promise<ApiResponse<AvailabilityAPIPayload>> {
  return apiWrapper.get(`${apiHelper().apiEndpoint}/v2/therapist/${therapistUserID}/availability`);
}

function getAvailabilityV2(therapistUserID: number): Promise<ApiResponse<any>> {
  return apiWrapper.get(`${apiHelper().apiEndpoint}/v3/therapist/${therapistUserID}/availability`);
}

/**
 * api call to: PATCH `/v2/therapists/432343/availability`
 * @returns Promise
 */
function putAvailability(
  therapistUserID: number,
  payload: Partial<AvailabilityAPIPayload>
): Promise<ApiResponse<AvailabilityAPIPayload>> {
  return apiWrapper.put(
    `${apiHelper().apiEndpoint}/v2/therapist/${therapistUserID}/availability`,
    payload
  );
}

function putAvailabilityV2(therapistUserID: number, payload: any): Promise<ApiResponse<any>> {
  return apiWrapper.put(
    `${apiHelper().apiEndpoint}/v3/therapist/${therapistUserID}/availability`,
    payload
  );
}

const upcomingBookingsApiHelper = {
  getBookings,
  getMultipleBookings,
  getTimeOff,
  getMultipleTimeOffsFeatureFlag,
  getMultipleTimeOffs,
  getRoomBookings,
  dismissBooking,
  patchBooking,
  getAvailability,
  getAvailabilityV2,
  putAvailability,
  putAvailabilityV2,
};

export default upcomingBookingsApiHelper;
