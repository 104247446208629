import { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import {
  Big,
  View,
  ResponsiveLayoutWithHeader,
  PanelHeader,
  usePanelManagerActions,
} from '@talkspace/react-toolkit';
import { getNavBarHeight } from 'components/Reusable/NavBar/NavBar';
import StarIcon from 'chat/components/Icons/Star';
import StarredMessagesScreen from 'chat/screens/StarredMessagesScreen';
import styled from '@/core/styled';
import ReactFrameService from '@/auth/reactFrame/ReactFrameService';

interface StarredMessagesProps {
  dispatch: Function;
  roomID: number;
}

const StarredMessagesScreenWrapper = styled(View)(({ theme: { colors } }) => {
  return {
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: colors.athensGrey,
    flex: 1,
  };
});

const StarredMessages: FunctionComponent<StarredMessagesProps> = ({ dispatch, roomID }) => {
  const { setIsLeftPanelOnTop, setIsRightPanelOpen } = usePanelManagerActions();

  const closeStarredMessages = () => {
    dispatch({ type: 'CLOSE_STARRED_MESSAGES' });
    if (ReactFrameService.instance().isInFrame()) {
      setIsRightPanelOpen(false);
    } else {
      setIsLeftPanelOnTop(true);
      setIsRightPanelOpen(false);
    }
  };

  return (
    <ResponsiveLayoutWithHeader
      headerHeight={getNavBarHeight()}
      renderHeader={() => (
        <PanelHeader
          renderLeft={() => (
            <>
              <View row>
                <View style={{ marginRight: 9 }}>
                  <StarIcon fillColor="#8F9CB2" strokeColor="#8F9CB2" />
                </View>
                <Big>Starred messages</Big>
              </View>
            </>
          )}
          onRightPress={closeStarredMessages}
        />
      )}
    >
      <StarredMessagesScreenWrapper>
        <StarredMessagesScreen isTherapistChat roomID={Number(roomID)} />
      </StarredMessagesScreenWrapper>
    </ResponsiveLayoutWithHeader>
  );
};

const mapStateToProps = (state) => {
  return {
    roomID: state.room.roomID,
  };
};

const StarredContainer = connect(mapStateToProps)(StarredMessages);

export default StarredContainer;
