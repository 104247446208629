import PDFModal from './PDFModal/PDFModal';
import PDFViewer from './PDFViewer/PDFViewer';
import { handleCrossPlatformPDFDownload, handlePDFDownload } from './utils/downloadUtils';

export { PDFModal, PDFViewer, handlePDFDownload, handleCrossPlatformPDFDownload };

const tsPDF = {
  PDFModal,
  PDFViewer,
  handlePDFDownload,
  handleCrossPlatformPDFDownload,
};

export default tsPDF;
