import { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';
import moment from 'moment';
import * as yup from 'yup';
import { formatSelectOptionsFromConfig, isZipValid, View } from '@talkspace/react-toolkit';
import { states, gender, therapistTitles } from '@talkspace/configs';
import Spacer from '../components/Spacer';
import { NAME_REGEX } from '../constants';
import { patchTherapistBasicInfo } from '../utils/apiHelper';
import ErrorMessage from '../components/ErrorMessage';
import SignupInput from '../components/SignupInput';
import SignupSelect from '../components/SignupSelect';
import ContinueButton from '../components/ContinueButton';
import usePersonalDetails from '../hooks/usePersonalDetails';
import useToken from '../hooks/useToken';
import { PersonalDetailsData } from '../types';

const stateOptions = formatSelectOptionsFromConfig(states);
const genderOptions = formatSelectOptionsFromConfig(gender, true);
const titleOptions = formatSelectOptionsFromConfig(therapistTitles);
titleOptions.unshift({ value: '', label: 'No Title' });

const validationSchema = yup.object().shape({
  title: yup.string().oneOf(titleOptions.map((option) => option.value)),
  firstName: yup
    .string()
    .required('Required')
    .matches(NAME_REGEX, 'Invalid characters')
    .min(2, 'Too short')
    .max(50, 'Too long'),
  lastName: yup
    .string()
    .required('Required')
    .matches(NAME_REGEX, 'Invalid characters')
    .min(2, 'Too short')
    .max(50, 'Too long'),
  dateOfBirth: yup
    .string()
    .required('Required')
    .test('is-valid-format-date', 'Please provide valid date (MM/DD/YYYY)', (userInput) =>
      moment(userInput, 'MM/DD/YYYY', true).isValid()
    ),
  city: yup.string().required('Required'),
  state: yup
    .string()
    .required('Required')
    .oneOf(
      stateOptions.map((option) => option.value),
      'Please provide valid state code, e.g. NY'
    ),
  zipCode: yup
    .string()
    .required('Required')
    .test('is-valid-zip-code', 'Zip code is missing or invalid.', (userInput: string) =>
      isZipValid(userInput, 'US')
    ),
  gender: yup
    .string()
    .required('Required')
    .oneOf(
      genderOptions.map((option) => option.value),
      'Invalid gender'
    ),
  firstClinicalLicenseDate: yup
    .string()
    .test('is-valid-format-date', 'Please provide date in format MM/DD/YYYY', (userInput) =>
      moment(userInput, 'MM/DD/YYYY', true).isValid()
    )
    .test(
      'is-valid-value-date',
      'Please provide date in the past',
      (userInput) => moment(userInput) <= moment().subtract(1, 'days')
    )
    .required('Required'),
});

const PersonalDetailsForm: FunctionComponent<{}> = () => {
  const history = useHistory();
  const token = useToken();
  const { data: initialValues } = usePersonalDetails(token);
  const onSubmit = (values: PersonalDetailsData, formikHelpers) => {
    patchTherapistBasicInfo(values, token)
      .then(() => {
        history.push(`/signup/clinical-info${history.location.hash}`);
      })
      .catch((err) => {
        formikHelpers.setStatus('Something went wrong. Please try again.');
        formikHelpers.setSubmitting(false);
      });
  };
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ isSubmitting, isValid, status: apiError }) => (
        <Form>
          <SignupSelect label="Title" fieldName="title" options={titleOptions} />
          <View row align="end">
            <SignupInput
              fieldName="firstName"
              label="Name"
              placeholder="First name"
              required
              style={{ width: 160 }}
            />
            <Spacer />
            <SignupInput
              fieldName="lastName"
              placeholder="Last name"
              required
              style={{ width: 160 }}
            />
          </View>
          <SignupInput
            fieldName="dateOfBirth"
            label="Date of birth"
            placeholder="MM/DD/YYYY"
            placeHolderStyle={{ top: 14, marginLeft: 20 }}
            date-format="MM/dd/yyyy"
            maskType="date"
            required
          />
          <SignupInput fieldName="city" label="City" placeholder="City" required />
          <View row>
            <SignupSelect fieldName="state" label="State" options={stateOptions} />
            <Spacer />
            <SignupInput
              fieldName="zipCode"
              label="Zip code"
              placeholder="Zip code"
              required
              style={{ width: 160 }}
            />
          </View>
          <SignupSelect label="Gender" fieldName="gender" options={genderOptions} />
          <SignupInput
            fieldName="firstClinicalLicenseDate"
            label="First clinical license date"
            placeholder="MM/DD/YYYY"
            placeHolderStyle={{ top: 14, marginLeft: 20 }}
            date-format="MM/dd/yyyy"
            maskType="date"
            required
          />
          <ContinueButton disabled={isSubmitting || !isValid} isLoading={isSubmitting} />
          {apiError && <ErrorMessage style={{ marginTop: 12 }}>{apiError}</ErrorMessage>}
        </Form>
      )}
    </Formik>
  );
};
export default PersonalDetailsForm;
