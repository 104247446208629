import { FunctionComponent } from 'react';
import BackNextButton from '../BackNextButton';
import View from '../View';
import TSLogo from '../TSLogo';
import styled from '../../core/styled';
import { webOnlyStyle } from '../../core/styleHelpers';
import { useWindowWidthState } from '../../hooks/windowWidthContext';
import CloseButton from '../Modal/ModalCloseButton';

const Wrapper = styled(View)(({ theme: { colors } }) => {
  return {
    align: 'center',
    backgroundColor: colors.white,
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1000,
  };
});

const NavBar = styled(View)<{
  isMobile: boolean;
}>(({ isMobile, theme: { colors } }) => {
  return {
    height: 66,
    width: '100%',
    flexDirection: 'row',
    backgroundColor: colors.white,
    display: 'flex',
    alignItems: 'center',
    borderBottom: `0.5px solid ${colors.heatherGrey}`,
    justifyContent: isMobile ? 'center' : 'flex-start',
    ...webOnlyStyle({
      boxShadow: 'rgba(0, 0, 0, 0.04) 0px 2px 2px 0px',
      zIndex: 2,
    }),
  };
});

const Content = styled(View)({
  flex: 1,
  paddingTop: 60,
  overflowY: 'auto',
});

const ResponsiveBackNextButton = styled(BackNextButton)<{
  isMobile: boolean;
}>(({ isMobile }) => {
  const nonMobile = !isMobile ? { top: 95 } : {};
  return {
    marginLeft: 20,
    alignSelf: 'center',
    position: 'absolute',
    left: 0,
    ...nonMobile,
  };
});

const StyledCloseButton = styled(CloseButton)({
  position: 'absolute',
  right: 30,
  top: 16,
});

const FullScreenWrapper: FunctionComponent<{
  goBack: () => void;
  onCloseButtonClick?: () => void;
  showBackButton?: boolean;
  showCloseButton?: boolean;
  newLogo?: boolean;
}> = ({
  goBack,
  onCloseButtonClick,
  children,
  showBackButton = true,
  showCloseButton = false,
  newLogo,
}) => {
  const { isMobile } = useWindowWidthState();

  return (
    <Wrapper>
      <NavBar isMobile={isMobile}>
        {showBackButton && (
          <ResponsiveBackNextButton isMobile={isMobile} onPress={goBack} width={13} height={21} />
        )}
        {showCloseButton && onCloseButtonClick && (
          <StyledCloseButton onPress={onCloseButtonClick} />
        )}
        <TSLogo
          style={{ marginLeft: 20, alignSelf: 'center' }}
          variant={newLogo ? '2024' : 'old'}
        />
      </NavBar>
      <Content align="center">{children}</Content>
    </Wrapper>
  );
};

export default FullScreenWrapper;
