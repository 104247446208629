import { FunctionComponent, useState } from 'react';
import { View, styled } from '@talkspace/react-toolkit';
import { Objective } from './types';

interface ObjectiveProps {
  objective: Objective;
}

const InterventionWrapper = styled(View)(({ theme: { colors } }) => {
  return {
    color: colors.grey,
    fontSize: 13,
    marginTop: 10,
  };
});

const ObjectivesInterventionsWrapper = styled(View)(({ theme: { colors } }) => {
  return {
    color: colors.green,
    marginTop: 10,
  };
});

const SingleObjective: FunctionComponent<ObjectiveProps> = ({ objective }) => {
  const [showInterventions, setShowInterventions] = useState(false);

  return (
    <View>
      <View>{objective.description}</View>
      {objective.interventions.length > 0 && (
        <ObjectivesInterventionsWrapper
          onClick={() => {
            setShowInterventions(!showInterventions);
          }}
          data-qa={`viewInterventionsButton-objective-${objective.description}`}
        >
          {`View ${objective.interventions.length} interventions`}
          {showInterventions &&
            objective.interventions.map((intervention) => (
              <InterventionWrapper>- {intervention.description}</InterventionWrapper>
            ))}
        </ObjectivesInterventionsWrapper>
      )}
    </View>
  );
};
export default SingleObjective;
