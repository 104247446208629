import { tsAnalyticsTracker } from '../utils/analytics/eventTracker';
import { TWEventProperties } from './types';

const trackEvent = <T extends keyof TWEventProperties>(
  eventName: T,
  attributes?: TWEventProperties[T],
  messageBody?: Record<string, any>
) => tsAnalyticsTracker.providerTrackEvent(eventName, attributes, messageBody);

export default trackEvent;
