import { useUniqueID, View, Text, Modal, Image } from '@talkspace/react-toolkit';
import styled from '@/core/styled';
import arrowLeft from '../../assets/left_arrow.png';
import arrowRight from '../../assets/right_arrow.png';

interface WaitingPermissionModalProps {
  onBackdropPress: () => void;
}

const WaitingPermissionModalContainer = styled(View)({
  flex: 1,
  bottom: 150,
  position: 'relative',
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'center',
});

const WaitingPermissionModal = ({ onBackdropPress }: WaitingPermissionModalProps) => {
  const micTextID = useUniqueID('micTextID');
  return (
    <Modal isVisible onBackdropPress={onBackdropPress} titleID={micTextID}>
      <WaitingPermissionModalContainer>
        <Image source={arrowLeft} />
        <Text
          id={micTextID}
          style={{
            fontSize: 30,
            color: 'white',
            textAlign: 'center',
            position: 'relative',
          }}
        >
          Click "Allow" above to turn on your micrphone
        </Text>
        <Image source={arrowRight} />
      </WaitingPermissionModalContainer>
    </Modal>
  );
};

export default WaitingPermissionModal;
