import { useQuery } from 'react-query';
import apiWrapper from '../utils/apiWrapper';
import apiHelper from '../utils/api';
import { SessionService } from '../components/Room/CRMContainer/NotesTab/types';
import { sessionServicesQueryKey } from './queryKeys';

const fetchSessionServices = (roomID: string, clientUserID?: number) => async () => {
  const { data } = await apiWrapper.get(
    `${apiHelper().apiEndpoint}/v2/rooms/${roomID}/clients/${clientUserID}/session-services`
  );
  return data.data;
};

const useQuerySessionServices = (roomID: string, clientUserID?: number) =>
  useQuery<SessionService[], Error>({
    queryKey: sessionServicesQueryKey(roomID, clientUserID),
    queryFn: fetchSessionServices(roomID, clientUserID),
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!clientUserID,
  });

export default useQuerySessionServices;
