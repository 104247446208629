import Svg, { Circle, Path } from 'svgs';
import { EmotionStyle } from '../../core/styled';

interface Props {
  height?: number;
  width?: number;
  style?: EmotionStyle;
}
const SparklingVideo = ({ height = 153, width = 130, style }: Props) => {
  const titleText = 'Sparkling Video';
  return (
    <Svg
      title={titleText}
      width={width}
      height={height}
      viewBox="0 0 153 130"
      fill="none"
      style={style}
    >
      <Circle cx="78" cy="65" r="65" fill="#EFF3FA" />
      <Path
        d="M34.6302 82.1928C35.8815 81.6093 37.369 82.1507 37.9525 83.402C38.536 84.6534 37.9946 86.1409 36.7433 86.7244L27.6802 90.9505C26.4288 91.5341 24.9414 90.9927 24.3579 89.7413C23.7744 88.49 24.3157 87.0025 25.5671 86.419L34.6302 82.1928Z"
        fill="#C0E3E0"
      />
      <Path
        d="M35.534 90.0467C36.1176 91.298 35.5762 92.7855 34.3248 93.369C33.0735 93.9525 31.586 93.4111 31.0025 92.1598L26.7763 83.0967C26.1928 81.8453 26.7342 80.3579 27.9855 79.7744C29.2369 79.1909 30.7243 79.7323 31.3079 80.9836L35.534 90.0467Z"
        fill="#C0E3E0"
      />
      <Circle cx="145.5" cy="23.5" r="4.5" stroke="#ADE953" stroke-width="5" />
      <Circle cx="11" cy="42" r="8" stroke="#BCCCFF" stroke-width="6" />
      <Path
        d="M47.5885 46.2157C47.4381 41.9074 50.8087 38.2928 55.1171 38.1424L85.2807 37.089C89.5888 36.9386 93.2033 40.3089 93.354 44.617L94.4095 74.7834C94.5603 79.0919 91.1896 82.7068 86.881 82.8573L56.7153 83.9107C52.407 84.0611 48.7924 80.6905 48.642 76.3821L47.5885 46.2157Z"
        fill="white"
        stroke="#BECBE0"
        stroke-width="5.2038"
      />
      <Path
        d="M70.9407 46.9833C71.0911 44.9912 72.828 43.4983 74.82 43.6487L74.9173 43.6561C76.9094 43.8065 78.4023 45.5433 78.2519 47.5354L77.9688 51.2841L99.1949 52.887L99.478 49.1383C99.6284 47.1462 101.365 45.6533 103.357 45.8037L103.455 45.8111C105.447 45.9615 106.94 47.6983 106.789 49.6904L106.501 53.51C106.494 53.5954 106.485 53.68 106.473 53.7635C110.602 55.1937 113.419 59.2656 113.074 63.8284L111.003 91.2542C110.593 96.6872 105.856 100.759 100.423 100.349L69.6268 98.023C64.1939 97.6127 60.1222 92.8758 60.5325 87.4429L62.6035 60.0171C62.9481 55.4542 66.3444 51.8515 70.6419 51.0577C70.6424 50.9733 70.6458 50.8883 70.6522 50.8029L70.9407 46.9833Z"
        fill="#5C84FB"
      />
      <Path
        d="M76.7693 65.781C74.2846 65.5934 72.1183 67.4555 71.9307 69.9401L71.2345 79.1594C71.0469 81.6441 72.909 83.8104 75.3936 83.998L86.9218 84.8686C89.4065 85.0562 91.5728 83.1941 91.7604 80.7094L92.4566 71.4902C92.6442 69.0055 90.7821 66.8392 88.2975 66.6516L76.7693 65.781Z"
        fill="white"
      />
      <Path
        d="M94.8128 72.614C94.1844 72.8747 93.756 73.4666 93.7048 74.145L93.4436 77.6029C93.3924 78.2813 93.7271 78.9308 94.3092 79.2829L99.5061 82.4263C100.657 83.1226 102.138 82.3597 102.24 81.018L102.914 72.0896C103.015 70.7479 101.666 69.7712 100.423 70.2867L94.8128 72.614Z"
        fill="white"
      />
    </Svg>
  );
};

export default SparklingVideo;
