import Svg, { Path } from 'svgs';
import { FunctionComponent } from 'react';

import { useEmotionTheme } from '../../core/styled';

interface ChatModalityProps {
  width?: number;
  height?: number;
  color?: string;
}

const ChatModality: FunctionComponent<ChatModalityProps> = ({
  width = 22,
  height = 21,
  color,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'chat';
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 22 21"
      title={titleText}
      aria-label={titleText}
      {...otherProps}
    >
      <Path
        d="M6.734 16.985a6.27 6.27 0 01-.232 2.28 1.36 1.36 0 00.426 1.42c.41.346.985.413 1.463.172 1.34-.681 3.12-1.864 4.201-3.681h.888c4.705 0 8.52-3.845 8.52-8.588C22 3.845 18.185 0 13.48 0H8.52C4.162 0 .506 3.316.048 7.684c-.459 4.369 2.427 8.381 6.689 9.301h-.003z"
        fill={color || colors.green}
        fillRule="nonzero"
      />
    </Svg>
  );
};

export default ChatModality;
