import { Standard, View, styled, ReminderIcon } from '@talkspace/react-toolkit';
import { suicideIdeationOrPlanning } from '@talkspace/configs';
import moment from 'moment';

const Container = styled(View)<{ isValue: boolean }>(({ theme: { colors }, isValue }) => {
  return {
    borderBottom: `1px solid ${colors.extraLightGrey}`,
    paddingBottom: 6,
    marginTop: isValue ? 12 : 20,
  };
});

const LabelText = styled(Standard)<{ isValue: boolean }>(({ theme: { colors }, isValue }) => {
  return {
    fontSize: isValue ? 12 : 15,
    color: colors.slateBlueGray,
    marginBottom: isValue && 8,
    fontWeight: 400,
  };
});

const ValueText = styled(Standard)(({ theme: { colors } }) => {
  return {
    fontSize: 15,
    color: colors.black,
    fontWeight: 400,
  };
});

interface RiskContainerProps {
  label: string;
  value: string | null;
  collectedAt: Date | null;
  displayReminderIcon?: boolean;
}

const RiskContainer = ({ label, value, collectedAt, displayReminderIcon }: RiskContainerProps) => {
  const shouldDisplayCollectedAt = collectedAt && !!value;
  return (
    <Container isValue={!!value}>
      <LabelText isValue={!!value}>
        {label}{' '}
        {shouldDisplayCollectedAt && `(Collected on ${moment(collectedAt).format('MMM DD, YYYY')})`}
      </LabelText>
      <View row align="center">
        {value === suicideIdeationOrPlanning.yes_in_the_past_30_days && (
          <View style={{ marginRight: 7 }}>
            <ReminderIcon />
          </View>
        )}
        <ValueText>{value}</ValueText>
      </View>
    </Container>
  );
};

export default RiskContainer;
