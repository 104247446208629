import Svg, { G, Path } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

const AudioMuted = ({ width = 11, height = 18, ...otherProps }) => {
  const { colors } = useEmotionTheme();
  const titleText = 'muted';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 11 18"
      {...otherProps}
    >
      <G fill={colors.white} stroke="none" strokeWidth={1} fillRule="evenodd">
        <Path d="M.813 6.834c.276 0 .5.231.5.502v2.713a3.752 3.752 0 003.75 3.762c.71 0 1.375-.199 1.941-.543l.517.859a4.71 4.71 0 01-1.957.66v2.027h2.008a.5.5 0 01.483.412l.008.088a.5.5 0 01-.49.5H2.553a.5.5 0 010-1h2.01v-2.026a4.756 4.756 0 01-4.25-4.739V7.336c0-.246.183-.451.412-.494l.087-.008zm1.5-1.371L6.482 12.4c-.415.263-.9.414-1.419.414-1.519 0-2.75-1.285-2.75-2.882v-4.47zm7 1.37l.088.009a.508.508 0 01.412.494v2.713c0 .985-.299 1.901-.81 2.661l-.587-.976c.254-.507.397-1.08.397-1.685V7.336c0-.27.224-.502.5-.502zM5.063.814c1.519 0 2.75 1.285 2.75 2.883v6.236c0 .232-.026.458-.076.675L2.7 2.223c.48-.844 1.36-1.41 2.363-1.41zM9.854 17.04a.501.501 0 10.857-.515L.931.246a.501.501 0 10-.857.515l9.78 16.279z" />
      </G>
    </Svg>
  );
};
export default AudioMuted;
