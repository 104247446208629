import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const standardPath = '';
const majorPath =
  'M11.0586 4.52734C10.4258 3.45313 9.26953 2.79297 8.02344 2.79297H7.9375C6.03906 2.79297 4.5 4.33203 4.5 6.23047C4.5 6.79297 4.63672 7.32422 4.875 7.79297H3.875C2.83984 7.79297 2 8.63281 2 9.66797V12.168C2 12.9844 2.52344 13.6797 3.25 13.9375V14.043V20.293C3.25 21.6719 4.37109 22.793 5.75 22.793H18.25C19.6289 22.793 20.75 21.6719 20.75 20.293V14.043V13.9375C21.4766 13.6797 22 12.9844 22 12.168V9.66797C22 8.63281 21.1602 7.79297 20.125 7.79297H19.125C19.3633 7.32422 19.5 6.79297 19.5 6.23047C19.5 4.33203 17.9609 2.79297 16.0625 2.79297H15.9766C14.7305 2.79297 13.5742 3.45313 12.9414 4.52734L12 6.13281L11.0586 4.53125V4.52734ZM20.125 9.66797V12.168H18.875H13.25V9.66797H16.0625H20.125ZM10.75 9.66797V12.168H5.125H3.875V9.66797H7.9375H10.75ZM10.75 14.043V20.918H5.75C5.40625 20.918 5.125 20.6367 5.125 20.293V14.043H10.75ZM13.25 20.918V14.043H18.875V20.293C18.875 20.6367 18.5938 20.918 18.25 20.918H13.25ZM16.0625 7.79297H13.25H13.1992L14.5586 5.48047C14.8555 4.97656 15.3945 4.66797 15.9766 4.66797H16.0625C16.9258 4.66797 17.625 5.36719 17.625 6.23047C17.625 7.09375 16.9258 7.79297 16.0625 7.79297ZM10.75 7.79297H7.9375C7.07422 7.79297 6.375 7.09375 6.375 6.23047C6.375 5.36719 7.07422 4.66797 7.9375 4.66797H8.02344C8.60547 4.66797 9.14844 4.97656 9.44141 5.48047L10.8008 7.79297H10.75Z';

const pathConfig: IconConfig = {
  standard: {
    default: {
      path: standardPath,
    },
  },
  major: {
    default: {
      path: majorPath,
    },
  },
};

const Gift = withDSIconMaker(pathConfig, 'gift with ribbon');

export default Gift;
