import moment from 'moment-timezone';
import { View, Large, useEmotionTheme, AccordionContainer } from '@talkspace/react-toolkit';
import { TherapistTimeslot } from 'ts-frontend/types';

import styled from '@/core/styled';

const Wrapper = styled(View)({
  width: '100%',
  borderRadius: 20,
});

const TimeText = styled(Large)(() => {
  return {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '20px',
  };
});

interface TimeslotRowProps {
  timeslot: TherapistTimeslot;
  isUnavailable?: boolean;
  isClient?: boolean;
}

const TimeslotRow = ({ timeslot, isUnavailable, isClient }: TimeslotRowProps) => {
  const momentStart = moment(timeslot.start).tz(moment.tz.guess());
  const { colors } = useEmotionTheme();

  return (
    <View
      style={{ marginTop: 4, color: isUnavailable ? colors.grey950 : colors.permaRiverBed }}
      row
      align="center"
    >
      <TimeText style={{ textDecoration: isUnavailable && 'line-through' }}>
        {momentStart.format('MMMM D, YYYY')}
      </TimeText>
      {isUnavailable && (
        <TimeText style={{ marginLeft: 5 }}>
          ({isClient ? 'provider conflict' : 'conflict'})
        </TimeText>
      )}
    </View>
  );
};

interface BookingSummaryDropdownProps {
  timeslots: TherapistTimeslot[] | null;
  recurringAvailable: TherapistTimeslot[] | null;
  recurringConflicting: TherapistTimeslot[] | null;
}

const BookingSummaryDropdown = ({
  timeslots,
  recurringConflicting,
  recurringAvailable,
}: BookingSummaryDropdownProps) => {
  if (!timeslots?.length) {
    return null;
  }
  const nTotal = timeslots?.length;
  const nAvailable = recurringAvailable?.length;

  return (
    <AccordionContainer
      title={`${nAvailable} of ${nTotal} sessions`}
      arrowText={(isOpen) => (isOpen ? 'Hide dates' : 'View dates')}
      arrowStyles={{ width: 10 }}
      bodyStyle={{ paddingTop: 0 }}
      titleContainerStyles={{
        paddingTop: 14,
        paddingBottom: 14,
        paddingRight: 16,
        paddingLeft: 16,
      }}
      titleStyle={{ fontSize: 16, fontWeight: 700 }}
      style={{ borderRadius: 8, maxWidth: 335 }}
      data-qa="selectedDatesAccordion"
    >
      <Wrapper>
        <View>
          {timeslots?.map((timeslot) => (
            <TimeslotRow
              timeslot={timeslot}
              isUnavailable={recurringConflicting?.includes(timeslot)}
            />
          ))}
        </View>
      </Wrapper>
    </AccordionContainer>
  );
};

export default BookingSummaryDropdown;
