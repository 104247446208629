import { useQuery } from 'react-query';
import apiHelper from '../../utils/api';
import apiWrapper from '../../utils/apiWrapper';
import availabilityKeys from './queryKeys';

interface ClientCapacityResponse {
  dailyCaseLoad: string;
  updatedAt: Date;
}

const fetchClientCapacity = (userID: number) => async () => {
  const { data } = await apiWrapper.get(
    `${apiHelper().apiEndpoint}/v2/therapist/${userID}/capacity`
  );
  return data.data;
};

const useQueryClientCapacity = (userID: number) =>
  useQuery<ClientCapacityResponse, Error>({
    queryKey: availabilityKeys.capacity(userID),
    queryFn: fetchClientCapacity(userID),
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
  });

export default useQueryClientCapacity;
