import { FunctionComponent, useRef, useState } from 'react';
import * as React from 'react';
import TouchableView from '../TouchableView';
import View from '../View';
import Modal from '../Modal';
import Image, { ImageProps } from '../Image';
import { EmotionStyle } from '../../core/styled';
import getResponsiveRatioStyles from '../../utils/getResponsiveRatioStyles';
import useComponentSize from '../../hooks/useComponentSize';

const Lightbox: FunctionComponent<
  ImageProps & {
    thumbnailURL?: string;
    thumbnailStyle?: EmotionStyle;
    lightboxImageStyle?: EmotionStyle;
    ariaLabel?: string;
    a11yText?: string;
    focusTrapPaused?: boolean;
  }
> = ({
  source,
  thumbnailURL = '',
  thumbnailStyle = {},
  lightboxImageStyle = {},
  style = {},
  ariaLabel,
  a11yText,
  focusTrapPaused,
  onLoad,
  ...otherProps
}) => {
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  const [showModal, setShowModal] = useState(false);
  const imageRef = useRef<HTMLImageElement>(null);
  const [modalRef, { width, height }] = useComponentSize<HTMLDivElement>(showModal);

  const onLoadHandler: React.ReactEventHandler<HTMLImageElement> = (e) => {
    onLoad?.(e);
    if (imageRef.current && imageSize.width === 0 && imageSize.height === 0) {
      setImageSize({
        // natural properties only work on HTML5, client properties are the fallback
        width: imageRef.current.naturalWidth || imageRef.current.clientWidth,
        height: imageRef.current.naturalHeight || imageRef.current.clientHeight,
      });
    }
  };

  const handleOnPress = () => {
    setShowModal(true);
  };

  const handleOnDismiss = () => {
    setShowModal(false);
  };

  return (
    <>
      <TouchableView
        onPress={handleOnPress}
        aria-label={ariaLabel}
        onTouchStart={(e) => e.stopPropagation()}
      >
        <Image
          source={thumbnailURL || source}
          style={{ maxWidth: 480, maxHeight: 480, ...thumbnailStyle }}
          progressiveRenderingEnabled
        />
      </TouchableView>
      <Modal
        modalRef={modalRef}
        isVisible={showModal}
        onBackdropPress={handleOnDismiss}
        titleText={a11yText}
        focusTrapPaused={focusTrapPaused}
        dialogStyle={{ alignItems: 'center' }}
        underlayStyle={{ height: '100%', backgroundColor: 'black', alignItems: 'center' }}
      >
        <View
          onTouchStart={handleOnDismiss}
          onClick={handleOnDismiss}
          style={{
            ...getResponsiveRatioStyles(imageSize, { width: width || 0, height: height || 0 }),
            ...style,
          }}
        >
          <Image
            ref={imageRef}
            onLoad={onLoadHandler}
            source={source}
            style={{ width: '100%', margin: 'auto', ...lightboxImageStyle }}
            {...otherProps}
          />
        </View>
      </Modal>
    </>
  );
};

export default Lightbox;
