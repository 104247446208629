import { useField } from 'formik';

import { PasswordInputMode } from 'ts-frontend/types';
import Password, { PasswordProps } from './Password';
import { usePasswordValidation } from '../../hooks/usePasswordValidation';

type Props = PasswordProps & {
  fieldName: string;
  isConfirmationInput?: boolean;
  passwordToCompare?: string;
};

const PasswordFormik = ({
  fieldName,
  isConfirmationInput,
  passwordToCompare,
  ...otherProps
}: Props) => {
  const [field, , { setValue }] = useField(fieldName);

  const validationProps = isConfirmationInput
    ? { passwordInputMode: PasswordInputMode.CONFIRM_PASSWORD, passwordToCompare }
    : { passwordInputMode: PasswordInputMode.CHANGE_PASSWORD };
  const [, newPasswordProps] = usePasswordValidation(validationProps);

  return (
    <Password
      {...otherProps}
      {...newPasswordProps}
      value={field.value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
    />
  );
};

export default PasswordFormik;
