import { Panel } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { ArrowUpV2, ArrowDownV2 } from '@talkspace/react-toolkit';
import styled from '@/core/styled';
import './AccordionContainer.css';

const arrowStyle = {
  float: 'right',
  left: 2,
  position: 'relative',
  top: 8,
  marginRight: 2,
};

const ArrowUpStyled = styled(ArrowUpV2)(arrowStyle);
const ArrowDownStyled = styled(ArrowDownV2)(arrowStyle);

const AccordionContainerView = ({
  open,
  childComponents,
  handleOpen,
  title,
  titleComponent,
  children,
  containerClass = '',
  arrowColor,
  dataQa,
}) => {
  const icon = open ? (
    <ArrowUpStyled fill={arrowColor} width={15} height={9} />
  ) : (
    <ArrowDownStyled fill={arrowColor} width={15} height={9} />
  );
  // phasing out childComponents prop in favor of children
  if (!childComponents) {
    return (
      <div
        className={`${
          open ? 'ts-component-accordion-container open' : 'ts-component-accordion-container closed'
        } ${containerClass}`}
        data-qa={dataQa}
      >
        <div onClick={handleOpen} className="ts-component-accordion-container-title">
          {titleComponent ? titleComponent() : title}
          {icon}
        </div>
        <Panel collapsible expanded={open}>
          <div>{open ? children : null}</div>
        </Panel>
      </div>
    );
  }
  const childComponentsArr = childComponents.map((formComponent, id) => (
    <div className="ts-component-accordion-child" key={id}>
      {formComponent}
    </div>
  ));
  return (
    <div
      className={`${
        open ? 'ts-component-accordion-container open' : 'ts-component-accordion-container closed'
      } ${containerClass}`}
      data-qa={dataQa}
    >
      <div onClick={handleOpen} className="ts-component-accordion-container-title">
        {titleComponent ? titleComponent() : title}
        {icon}
      </div>
      <Panel collapsible expanded={open}>
        <div>{open ? childComponentsArr : null}</div>
      </Panel>
    </div>
  );
};

AccordionContainerView.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  childComponents: PropTypes.array,
  handleOpen: PropTypes.func,
  dataQa: PropTypes.string,
};

export default AccordionContainerView;
