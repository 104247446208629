import { Path, Svg } from 'svgs';

import { useEmotionTheme } from '../../core/styled';

const PhoneNotifications = () => {
  const { colorRoles } = useEmotionTheme();
  const titleText = 'Phone with notification';
  return (
    <Svg
      width={96}
      height={96}
      viewBox="0 0 96 96"
      fill="none"
      title={titleText}
      aria-label={titleText}
    >
      <Path
        d="M46.861 79.366c.012-1.215-1.248-2.213-2.815-2.229-1.567-.015-2.847.957-2.86 2.172-.012 1.216 1.249 2.213 2.816 2.23 1.567.015 2.847-.958 2.86-2.173zM64.297 24.263c-.064-6.237-.09-11.534-.077-15.217V7.683c2.998 2.476.995.721 3.215 2.664.842.721 1.34 1.912 1.314 3.166a820.04 820.04 0 00-.089 10.719c-1.288 0-2.59.016-3.878.031h-.485z"
        fill={colorRoles.icons.iconBrandDefault}
      />
      <Path
        d="M68.784 86.203c.025 1.404-1.085 2.59-2.476 2.616-14.033.217-27.085.178-41.285.178a11.733 11.733 0 01-2.909-2.704c8.855-.255 17.913-.702 26.907-1.097a910.671 910.671 0 0115.284-.6h.204v-.23c.026-5.04-.063-14.671-.19-25.593.127-.165-.067-2.55.099-2.716 1.173-1.314 3.096-2.339 4.359-3.615.127 12.248-.083 26.005.007 33.761z"
        fill={colorRoles.icons.iconBrandDefault}
      />
      <Path
        d="M95.151 27.873c.636 1.69.553 3.53.461 5.318l-.826 16.18c-.05.997-.12 2.05-.722 2.879-1.101 1.514-3.415 1.637-5.386 1.596a831.395 831.395 0 01-21.884-.74c.118-.787.865-1.391 1.678-1.642.814-.25 1.693-.216 2.55-.187 5.725.199 11.457.087 17.186-.024.954-.018 1.977-.057 2.746-.58 1.138-.775 1.283-2.271 1.326-3.579l.572-17.212c.033-1.014.065-2.044-.207-3.027-.218-.792-.656-1.43-1.234-2.031 1.083-1.164 3.464 2.315 3.74 3.05z"
        fill={colorRoles.icons.iconBrandDefault}
      />
      <Path
        d="M58.677 61.692l3.349-.16c2.466-3.336 5.855-6.615 8.943-9.386-.936-.433-2.063-.253-2.978.222-.915.476-1.66 1.215-2.391 1.943-2.456 2.447-4.467 4.934-6.923 7.381zM22.456 86.496a.953.953 0 01-.949-.882c-1.155-15.499-1.006-30.4-.862-44.812.099-10.07.203-20.484-.144-30.95a2.566 2.566 0 01.706-1.84 2.306 2.306 0 011.643-.72c12.069-.326 22.052-.303 33.597-.278l6.201.012c1.31.013 2.377 1.115 2.389 2.456l.334 14.764a.955.955 0 01-.932.975c-.546.03-.962-.405-.974-.931L63.13 9.512c-.002-.323-.224-.578-.494-.58l-6.194-.012c-11.54-.026-21.503-.047-33.557.277a.425.425 0 00-.307.138.64.64 0 00-.172.461c.347 10.5.243 20.935.144 31.025-.144 14.372-.292 29.233.857 44.651a.954.954 0 01-.952 1.024z"
        fill={colorRoles.icons.iconBrandDefault}
      />
      <Path
        d="M22.73 86.519a.954.954 0 01-.017-1.906c6.744-.121 13.526-.21 20.31-.3l5.786-.07c4.835-.052 9.68-.128 14.5-.217-.017-.002.013-.017.068-.06a.956.956 0 01.161-.145l.032-.07c.168-.227.265-.544.258-.72l-.044-27.61a.953.953 0 111.906-.003l.044 27.581c.02.61-.227 1.338-.658 1.921-.024.032-.061.075-.089.103a1.344 1.344 0 01-.18.214.798.798 0 01-.053.058c-.46.412-.91.612-1.38.635-4.855.09-9.705.167-14.556.218h-.392l-5.377.071c-6.781.09-13.56.178-20.3.3h-.018z"
        fill={colorRoles.icons.iconBrandDefault}
      />
      <Path
        d="M23.125 75.181a.953.953 0 01-.048-1.905 578.569 578.569 0 0140.538-.666.953.953 0 01-.017 1.906h-.017a576.2 576.2 0 00-40.405.664l-.05.001zM22.732 17.864a.952.952 0 01-.09-1.902c10.9-1.054 20.702-1.033 31.085-1.012 1.98.005 3.987.008 6.044.005a.954.954 0 01.002 1.906c-2.056.004-4.07-.001-6.05-.005-10.333-.019-20.09-.041-30.898 1.003a.934.934 0 01-.093.005zM41.82 12.9a.946.946 0 01-.438-.107l-.295-.152.861-1.7-.434.848.442-.845.302.156a.953.953 0 01-.439 1.8zM58.569 61.929a.952.952 0 01-.953-.987 39.107 39.107 0 00-.66-8.642c-.82-.004-1.64-.015-2.46-.04-1.55-.01-3.113-.02-4.672-.05-.209-.002-.411-.001-.63-.012-.486 0-.996 0-1.532-.02-1.257-.014-2.436-.5-3.207-1.334-.599-.645-.899-1.452-.845-2.272.402-6.145.624-12.847.7-21.092.034-1.887 1.857-3.369 4.149-3.381h.388c.174.001.344.002.514-.017 1.703-.005 3.31-.015 4.915-.025l9.376-.062h.006a.953.953 0 01.007 1.906l-9.377.062c-1.61.01-3.219.02-4.83.02-.137.02-.375.021-.611.021h-.383c-1.201.007-2.234.695-2.249 1.502-.075 8.273-.3 15.01-.705 21.189-.023.375.173.671.342.853.413.445 1.108.716 1.858.725.535.02.987 0 1.533.02.236.01.408.01.6.01 1.57.031 3.122.041 4.687.052 1.084.031 2.146.041 3.21.041.451 0 .84.316.933.758a41.36 41.36 0 01.863 7.526 135.695 135.695 0 013.934-3.66.953.953 0 111.27 1.42 129.994 129.994 0 00-5.495 5.207.95.95 0 01-.676.282z"
        fill={colorRoles.icons.iconBrandDefault}
      />
      <Path
        d="M64.5 56.29a.954.954 0 01-.686-1.614 88.357 88.357 0 013.224-3.174c.23-.233.495-.49.773-.747.179-.166.47-.237.655-.255 8.428.063 14.864.077 20.867.052.963 0 1.788-.631 1.841-1.406.6-7.434.703-14.895.756-21.901.004-.452-.228-.78-.424-.976a2.04 2.04 0 00-1.45-.563l-22.515.175c-1.13 0-2.265.01-3.389.02h-.008a.953.953 0 01-.01-1.905c1.13-.01 2.272-.02 3.4-.02l22.509-.176c1.043.036 2.092.402 2.811 1.122.64.641.99 1.471.982 2.338-.053 7.04-.156 14.542-.76 22.029-.123 1.772-1.766 3.169-3.74 3.169-5.917.027-12.255.012-20.502-.05-.16.154-.315.307-.467.46a87.74 87.74 0 00-3.18 3.13.95.95 0 01-.686.292z"
        fill={colorRoles.icons.iconBrandDefault}
      />
      <Path
        d="M79.202 32.185H52.138a.953.953 0 010-1.906h27.064a.953.953 0 010 1.906zM70.756 38.405H52.458a.953.953 0 010-1.906h18.298a.953.953 0 010 1.906zM64.139 45.103H52.458a.953.953 0 010-1.906h11.68a.953.953 0 010 1.906zM44.046 77.137c-1.567-.015-2.847.957-2.86 2.172-.012 1.216 1.249 2.213 2.816 2.23 1.567.015 2.847-.958 2.859-2.173.012-1.215-1.248-2.213-2.815-2.229z"
        fill={colorRoles.icons.iconBrandDefault}
      />
      <Path
        d="M21.507 85.614a.94.94 0 00.759.846c.779.984 1.71 1.851 2.757 2.537 14.2 0 27.252.039 41.285-.178 1.391-.026 2.501-1.212 2.475-2.616-.085-7.399.102-20.254.009-32.055.33-.311.66-.62.988-.922 6.297.273 12.596.49 18.898.62 1.97.04 4.285-.082 5.386-1.596.602-.829.672-1.882.723-2.88.935-18.315 1.201-19.274.364-21.497-.174-.463-1.184-2.015-2.18-2.809-.767-.852-1.845-1.227-2.928-1.265l-21.382.167c.013-3.92.038-7.467.088-10.454.026-1.253-.472-2.444-1.314-3.165-2.18-1.908-.3-.26-3.068-2.544a2.373 2.373 0 00-1.719-.777c-15.018-.032-26.36-.097-39.798.266-1.331.014-2.384 1.18-2.35 2.561.855 25.727-.965 49.307 1.007 75.761zm42.031-1.793a.96.96 0 00-.161.146c-.055.042-.085.058-.067.059-12.386.23-23.79.292-39.958.577-.228-3.184-.4-6.344-.531-9.481.751.257 15.073-1.088 40.76-.606h.017c.076 0 .145-.026.216-.043-.085 10.124.244 8.09-.276 9.348zm26.519-58.116c.905-.026 1.88.622 1.873 1.54-.053 7.005-.155 14.466-.756 21.9-.053.775-.878 1.406-1.84 1.406-6.004.026-12.44.012-20.868-.051-.184.017-.476.089-.655.254-.129.12-3.888 3.666-4.14 4.146-.434.228-3.776 3.433-4.135 3.78a41.376 41.376 0 00-.863-7.526.954.954 0 00-.933-.758c-1.402 0-9.864-.117-10.03-.123-1.052-.012-2.259-.639-2.2-1.578.405-6.18.63-12.915.705-21.188.015-.808 1.047-1.495 2.248-1.502 2.337-.005 37.816-.265 41.593-.3zM22.885 9.197c13.58-.365 24.697-.297 39.751-.265.27.002.492.257.494.58l.327 14.485c-.144 0-14.852.086-15 .102-2.291.013-4.114 1.494-4.149 3.382-.075 8.245-.297 14.946-.7 21.091-.135 2.086 1.886 3.581 4.053 3.606.32.012 9.08.121 9.295.122a39.1 39.1 0 01.659 8.642.948.948 0 001.6.724l2.81-.134a39.806 39.806 0 011.764-2.199l.022 13.32c-1.769-.418-39.722.123-41.063.708-.7-19.129.105-36.705-.158-55.523.048.007.091.026.14.026a.937.937 0 00.094-.004c10.807-1.045 20.565-1.022 30.898-1.003 1.98.004 3.994.009 6.05.005a.953.953 0 000-1.906c-2.058.002-4.067 0-6.046-.005-10.383-.021-20.185-.042-31.086 1.012-.032.003-.059.021-.09.027a407.136 407.136 0 00-.144-6.193c-.009-.296.19-.596.479-.6z"
        fill={colorRoles.icons.iconBrandDefault}
      />
      <Path
        d="M41.514 11.79l.434-.849-.434.848zM41.819 12.9c.997 0 1.322-1.342.44-1.8l-.304-.155-.868 1.696c.169.075.396.258.732.258zM51.185 31.232c0 .526.426.953.953.953h27.064a.953.953 0 000-1.906H52.138a.953.953 0 00-.953.953zM70.756 36.499H52.458a.953.953 0 000 1.906h18.298a.953.953 0 000-1.906zM64.139 43.197H52.458a.953.953 0 000 1.906h11.68a.953.953 0 000-1.906z"
        fill={colorRoles.icons.iconBrandDefault}
      />
    </Svg>
  );
};

export default PhoneNotifications;
