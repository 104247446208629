import { TSLogo } from '@talkspace/react-toolkit';
import { VoidFunctionComponent } from 'react';
import { Link } from 'react-router-dom';

interface NavBarLogoProp {
  width?: number;
  height?: number;
}

const NavBarLogo: VoidFunctionComponent<NavBarLogoProp> = ({ width, height }) => {
  const logoSize = width && height ? { width, height } : {};
  return (
    <Link to="/">
      <TSLogo {...logoSize} variant="2024" />
    </Link>
  );
};
export default NavBarLogo;
