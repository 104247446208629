import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const pathConfig: IconConfig = {
  standard: {
    filled: {
      path: 'M7.70809 18.0005H5.1315C4.27769 18.0005 3.58554 17.3083 3.58554 16.4545V8.40516C3.58554 7.95386 3.78275 7.52509 4.1254 7.23138L9.7939 2.37267C10.3728 1.87643 11.2271 1.87643 11.8061 2.37267L17.4746 7.23138C17.8172 7.52509 18.0145 7.95386 18.0145 8.40516V16.4545C18.0145 17.3083 17.3223 18.0005 16.4685 18.0005H13.8919C13.0381 18.0005 12.346 17.3083 12.346 16.4545V11.8167H9.25404V16.4545C9.25404 17.3083 8.56189 18.0005 7.70809 18.0005Z',
    },
    default: {
      path: 'M7.70807 11.8167C7.70807 10.9629 8.40022 10.2707 9.25403 10.2707H12.3459C13.1997 10.2707 13.8919 10.9629 13.8919 11.8167V16.4545H16.4685V8.40516L10.8 3.54644L5.13148 8.40516L5.13148 16.4545H7.70807V11.8167ZM5.13148 18.0005H7.70807C8.56188 18.0005 9.25403 17.3083 9.25403 16.4545V11.8167H12.3459V16.4545C12.3459 17.3083 13.0381 18.0005 13.8919 18.0005H16.4685C17.3223 18.0005 18.0144 17.3083 18.0144 16.4545V8.40516C18.0144 7.95386 17.8172 7.52509 17.4746 7.23138L11.8061 2.37267C11.2271 1.87643 10.3728 1.87643 9.79389 2.37267L4.12539 7.23138C3.78273 7.52509 3.58553 7.95386 3.58553 8.40516V16.4545C3.58553 17.3083 4.27767 18.0005 5.13148 18.0005Z',
      evenOddFilling: true,
    },
  },
  major: {
    filled: {
      path: 'M8.93501 22.7935H5.71427C4.64701 22.7935 3.78183 21.9283 3.78183 20.861V10.7993C3.78183 10.2352 4.02834 9.6992 4.45666 9.33207L11.5423 3.25868C12.266 2.63838 13.3338 2.63838 14.0575 3.25868L21.1431 9.33207C21.5715 9.6992 21.818 10.2352 21.818 10.7993V20.861C21.818 21.9283 20.9528 22.7935 19.8855 22.7935H16.6648C15.5975 22.7935 14.7323 21.9283 14.7323 20.861V15.0637H10.8675V20.861C10.8675 21.9283 10.0023 22.7935 8.93501 22.7935Z',
    },
    default: {
      path: 'M8.935 15.0637C8.935 13.9964 9.80018 13.1312 10.8674 13.1312H14.7323C15.7996 13.1312 16.6648 13.9964 16.6648 15.0637V20.861H19.8855V10.7993L12.7999 4.7259L5.71426 10.7993L5.71426 20.861H8.935V15.0637ZM5.71426 22.7935H8.935C10.0023 22.7935 10.8674 21.9283 10.8674 20.861V15.0637H14.7323V20.861C14.7323 21.9283 15.5975 22.7935 16.6648 22.7935H19.8855C20.9528 22.7935 21.818 21.9283 21.818 20.861V10.7993C21.818 10.2352 21.5714 9.69921 21.1431 9.33208L14.0575 3.25868C13.3338 2.63838 12.2659 2.63838 11.5423 3.25868L4.45664 9.33208C4.02832 9.69921 3.78181 10.2352 3.78181 10.7993V20.861C3.78181 21.9283 4.647 22.7935 5.71426 22.7935Z',
      evenOddFilling: true,
    },
  },
};

const House = withDSIconMaker(pathConfig, 'house');

export default House;
