// Renders VideoCallScreen as a top-level component that doesn't get enabled until the user is in a video call.
import { useEffect } from 'react';
import { useHistory } from '@/core/routerLib';
import { useVideoCallActions, useVideoCallState } from './hooks/videoCallContext';
import VideoCallScreen from './screens/VideoCallScreen';
import { VideoCallScreenProps } from './types/videoCallTypes';

const VideoCallWrapper = ({
  unmountRouteCheck,
}: {
  /**
   * Returns true if the Video Call will be unmounted
   */
  unmountRouteCheck: (pathname: string) => boolean;
}) => {
  const { isLVSModalOpen, videoCallScreenProps } = useVideoCallState();
  const history = useHistory();

  useEffect(
    () => () => {
      sessionStorage.removeItem('videoSessionInfo');
    },
    []
  );

  useEffect(() => {
    if (!isLVSModalOpen || !unmountRouteCheck) return undefined;
    const unblock = history.block((tx) => {
      const shouldBlock = unmountRouteCheck(tx.pathname);
      if (shouldBlock) {
        return 'Taking this action will end your video call. Are you sure?' as string;
      }
      // Allow navigation
      return undefined;
    });
    return unblock;
  }, [history, unmountRouteCheck, isLVSModalOpen]);

  if (!videoCallScreenProps || !isLVSModalOpen) return null;

  return (
    <>
      <VideoCallScreen {...videoCallScreenProps} isOutsideChat />
    </>
  );
};

export const useSetVideoCallScreenProps = ({
  closeLVSModal,
  isOutsideChat,
  outsideFooterHeight,
  outsideHeaderHeight,
  roomID,
  isTherapistChat,
}: VideoCallScreenProps) => {
  const { setVideoCallScreenProps } = useVideoCallActions();

  useEffect(() => {
    setVideoCallScreenProps({
      closeLVSModal,
      isOutsideChat,
      outsideFooterHeight,
      outsideHeaderHeight,
      roomID,
      isTherapistChat,
    });
  }, [
    closeLVSModal,
    isOutsideChat,
    outsideFooterHeight,
    outsideHeaderHeight,
    roomID,
    isTherapistChat,
    setVideoCallScreenProps,
  ]);
};

export default VideoCallWrapper;
