import { useState } from 'react';

import View from '../../../components/View';
import { spacing } from '../../tokens';
import Tab, { TabProps } from './Tab';
import { useEmotionTheme } from '../../../core/styled';

const { space300 } = spacing;

interface Props {
  options: Omit<TabProps, 'variant' | 'isActive'>[];
  variant: TabProps['variant'];
}
const Tabs = ({ options, variant = 'fill' }: Props) => {
  const { colorRoles } = useEmotionTheme();
  const [activeTab, setActiveTab] = useState(0);

  return (
    <View
      row
      style={{
        width: '100%',
        position: 'relative',
      }}
    >
      {options.map(({ label, onPress, dataQa }, i) => {
        const marginLeft = variant === 'hug' && i !== 0 ? space300 : 0;
        return (
          <Tab
            key={`option-${dataQa}`}
            label={label}
            onPress={() => {
              setActiveTab(i);
              onPress();
            }}
            variant={variant}
            isActive={i === activeTab}
            style={{ marginLeft, flex: variant === 'fill' ? 1 : undefined }}
            dataQa={dataQa}
          />
        );
      })}
      {variant === 'hug' && (
        <View
          style={{
            height: 1,
            width: '100%',
            backgroundColor: colorRoles.borders.borderDefault,
            position: 'absolute',
            bottom: 0,
          }}
        />
      )}
    </View>
  );
};

export default Tabs;
