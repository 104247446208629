import { FunctionComponent } from 'react';
import Svg, { G, Path, Defs } from 'svgs';
import { EmotionStyle, useEmotionTheme } from '../../core/styled';

interface AddPartnerProps {
  color?: string;
  width?: number;
  style?: EmotionStyle;
}

const RxCircle: FunctionComponent<AddPartnerProps> = ({ color, width = 32, style }) => {
  const { colors } = useEmotionTheme();
  const titleText = 'Imperfect circle with rx icon';
  return (
    <Svg width={width} title={titleText} aria-label={titleText} style={style} viewBox="0 0 47 47">
      <Defs>
        <Path
          d="M8.301 5.176c10.338-7.453 26-7.06 33.82 2.504 4.236 5.181 5.318 11.693 4.066 18.985-1.104 6.425-4.899 11.8-9.21 15.513-9.409 8.102-23.732 4.551-31.552-5.013-7.82-9.565-7.461-24.536 2.876-31.989z"
          id="a"
        />
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <G transform="translate(-302 -508) translate(302 508)">
          <mask fill="#fff">
            <use xlinkHref="#a" />
          </mask>
          <use fill={color || colors.lightGray} xlinkHref="#a" />
        </G>
        <Path
          d="M15.974 27.648c3.868 0 7.295-1.88 9.42-4.777a11.621 11.621 0 002.254-6.897c0-6.447-5.226-11.673-11.674-11.673-6.447 0-11.673 5.226-11.673 11.673 0 3.058 1.176 5.842 3.1 7.923a11.642 11.642 0 008.573 3.751z"
          transform="translate(-302 -508) translate(302 508) translate(7 7) rotate(-60 15.974 15.974)"
          stroke="#FFF"
          strokeDasharray={68.19839681396479}
          strokeLinecap="round"
          strokeWidth={1.84319974}
        />
        <Path
          d="M14.51 9.216c.95 0 1.702.227 2.255.68.763.62 1.145 1.416 1.145 2.389 0 .973-.382 1.77-1.145 2.389-.344.285-.74.478-1.19.58l-.17.033 2.382 2.724 1.474-1.839a.184.184 0 01.143-.069h1.41a.307.307 0 01.232.509l-2.23 2.576 2.509 2.96a.307.307 0 01-.242.506l-1.35-.033a.184.184 0 01-.138-.067l-1.808-2.188-1.811 2.189a.184.184 0 01-.138.066l-1.346.033a.307.307 0 01-.241-.506l2.448-2.877-3.268-3.917h-.713v3.416a.83.83 0 01-1.655.08l-.004-.08v-8.325c0-.648.501-1.179 1.137-1.226l.092-.003h2.221zm-.084 1.576h-1.708v2.986h1.708c.642 0 1.106-.144 1.394-.431.287-.288.43-.642.43-1.062 0-.42-.143-.774-.43-1.062-.264-.263-.676-.406-1.237-.428l-.157-.003z"
          fill="#FFF"
          fillRule="nonzero"
          transform="translate(-302 -508) translate(302 508) translate(7 7)"
        />
      </G>
    </Svg>
  );
};

export default RxCircle;
