import {
  useRef,
  useState,
  useEffect,
  useCallback,
  createContext,
  useContext,
  FunctionComponent,
} from 'react';
import { AdditionalMetaData } from 'stepWizard/reducers/wizardState';
import ReactFrameService from '../auth/reactFrame/ReactFrameService';

import {
  Router,
  createMemoryHistory,
  MemoryHistory,
  Switch,
  Route,
  getBrowserHistory,
} from '../core/routerLib';

interface ClosePopupActionBase {
  navigateTo: string;
}

interface ClosePopupRoomAction extends ClosePopupActionBase {
  navigateTo: 'room';
  metadata: { roomID: number; additionalMetaData?: AdditionalMetaData };
}

interface ClosePopupDashboardAction extends ClosePopupActionBase {
  navigateTo: 'dashboard';
}

interface ClosePopupConfirmBookingAction extends ClosePopupActionBase {
  navigateTo: 'videoCall';
}

type ClosePopupAction =
  | ClosePopupRoomAction
  | ClosePopupDashboardAction
  | ClosePopupConfirmBookingAction;

interface OuterContextInterface {
  openModal: (url: string, state?: any, fullScreen?: boolean, transparent?: boolean) => void;
}

interface InnerContextInterface {
  focusTrapPaused: boolean;
  closeModal: (data?: ClosePopupAction) => void;
  pauseModalFocusTrap: () => void;
  resumeModalFocusTrap: () => void;
}

const OuterContext = createContext<OuterContextInterface | undefined>(undefined);

const InnerContext = createContext<InnerContextInterface>({
  focusTrapPaused: false,
  closeModal: (data?) => {
    if (ReactFrameService.instance().isInFrame()) {
      ReactFrameService.instance().closePopup(data);
    } else {
      // eslint-disable-next-line no-console
      console.log('Close modal was called with wrong context', data);
    }
  },
  pauseModalFocusTrap: () => {
    // eslint-disable-next-line no-console
    console.log('pauseModalFocusTrap was called with wrong context');
  },
  resumeModalFocusTrap: () => {
    // eslint-disable-next-line no-console
    console.log('resumeModalFocusTrap was called with wrong context');
  },
});

export function useOpenModal() {
  const context = useContext(OuterContext);
  if (context === undefined) {
    return () => {
      throw new Error('OuterContext must be used within a ContextProvider');
    };
  }
  return context.openModal;
}

export function useCloseModal() {
  const { closeModal } = useContext(InnerContext);
  return closeModal;
}

export function useModalFocusTrap() {
  const { focusTrapPaused, pauseModalFocusTrap, resumeModalFocusTrap } = useContext(InnerContext);
  return { focusTrapPaused, pauseModalFocusTrap, resumeModalFocusTrap };
}

let memoryHistory: MemoryHistory | null;

export const getMemoryHistory = () => memoryHistory;

function useMemoryHistory() {
  if (!memoryHistory) {
    memoryHistory = createMemoryHistory();
  }

  const history = memoryHistory;

  const setHistoryUpdated = useState({})[1];

  useEffect(() => {
    const unlisten = history.listen(() => setHistoryUpdated({}));
    return unlisten;
  }, [history, setHistoryUpdated]);

  return history;
}

const ModalsProvider: FunctionComponent<{
  ModalRoutes: FunctionComponent<any>;
}> = ({ children, ModalRoutes }) => {
  const fullScreenRef = useRef<boolean>(true);
  const transparentRef = useRef<boolean>(false);
  const [focusTrapPaused, setFocusTrapPaused] = useState(false);

  const modalHistory = useMemoryHistory();

  const outerContext = {
    openModal: useCallback(
      (url, state = {}, fullScreen = true, transparent = false) => {
        setFocusTrapPaused(false); // Reset focus trap status
        fullScreenRef.current = fullScreen;
        transparentRef.current = transparent;
        modalHistory.push(url, state);
      },
      [modalHistory]
    ),
  };

  const innerContext: InnerContextInterface = {
    focusTrapPaused,
    closeModal: useCallback(
      (data?: ClosePopupAction) => {
        // reset memory history
        modalHistory.go(-1 * modalHistory.length);
        if (!data) return;
        // route host browser
        switch (data.navigateTo) {
          case 'dashboard':
            getBrowserHistory().push('/rooms');
            break;
          case 'room':
            getBrowserHistory().push(`/room/${data.metadata.roomID}`);
            break;
          default:
            break;
        }
      },
      [modalHistory]
    ),
    pauseModalFocusTrap: () => setFocusTrapPaused(true),
    resumeModalFocusTrap: () => setFocusTrapPaused(false),
  };

  return (
    <>
      <OuterContext.Provider value={outerContext}>{children}</OuterContext.Provider>
      <InnerContext.Provider value={innerContext}>
        <Router history={modalHistory}>
          <Switch>
            <Route exact path="/" component={() => null} />
            <ModalRoutes fullScreen={fullScreenRef.current} transparent={transparentRef.current} />
          </Switch>
        </Router>
      </InnerContext.Provider>
    </>
  );
};

export default ModalsProvider;
