import { FunctionComponent } from 'react';
import moment from 'moment';
import { View, Text, useEmotionTheme } from '@talkspace/react-toolkit';
import { MessageProps } from '../../types';
import ReadReceiptCheckmarks from '../ReadReceiptCheckmarks';

interface TimeProps extends MessageProps {
  style?: {};
  id?: string;
}

const Time: FunctionComponent<TimeProps> = ({ currentMessage, style, position, ackLevel, id }) => {
  const { colors } = useEmotionTheme();
  const marginStyles = position === 'right' && ackLevel ? { marginRight: 4 } : null;
  let justify: 'end' | 'start' | 'center' = position === 'right' ? 'end' : 'start';
  if (position === 'center') justify = 'center';
  const messageMoment = moment(currentMessage.createdAt);
  const format = messageMoment.isSame(moment(), 'd') ? 'h:mm A' : 'MMM D, h:mm A';
  return (
    <View row style={style} justify={justify} align="end">
      <Text
        id={id}
        style={{
          color: colors.placeholderGrey,
          textAlign: 'center',
          fontSize: 13,
          ...marginStyles,
        }}
      >
        {messageMoment.format(format)}
      </Text>
      {ackLevel > 0 && (
        <View row style={{ width: 26, zIndex: 0 }} justify="center">
          <ReadReceiptCheckmarks ackLevel={ackLevel} />
        </View>
      )}
    </View>
  );
};

export default Time;
