export interface WizardActionState {
  isLoading: boolean;
  error: string;
  isSuccess: boolean;
}

type Action<T> =
  | { type: 'requestAction' }
  | { type: 'requestLastAction' }
  | { type: 'requestTherapistTimeslots' }
  | { type: 'receiveTherapistTimeslots'; payload: { canBookIntroSession: boolean } }
  | { type: 'receiveAction'; payload?: Partial<T> }
  | { type: 'setError'; error?: string }
  | { type: 'exitAction' };

export const wizardActionInitialState = <T extends object>(
  additionalStateProps?: T
): WizardActionState => {
  return {
    isLoading: false,
    error: '',
    isSuccess: false,
    ...additionalStateProps,
  };
};

export const wizardActionReducer = <T>(state: T, action: Action<T>): T => {
  switch (action.type) {
    case 'requestAction':
      return {
        ...state,
        isSuccess: false,
        isLoading: true,
        error: '',
      };
    case 'requestTherapistTimeslots':
      return {
        ...state,
        isLoading: true,
      };
    case 'receiveTherapistTimeslots':
      return {
        ...state,
        isLoading: false,
        ...(action.payload || {}),
      };
    case 'receiveAction':
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        ...(action.payload || {}),
      };
    case 'exitAction':
      return {
        ...state,
        isLoading: true,
      };
    case 'setError':
      return {
        ...state,
        error: action.error || 'Something went wrong. Please try again later',
        isLoading: false,
      };
    default:
      return state;
  }
};
