import Svg, { Path } from 'svgs';

interface Props {
  width?: string | number;
  height?: string | number;
  color?: string;
}
const AddAvailabilityIcon = ({ width = 18, height = 15, color = '#005C65' }: Props) => (
  <Svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <Path
      d="M4.05 0C4.64674 0 5.21903 0.237053 5.64099 0.65901C6.06295 1.08097 6.3 1.65326 6.3 2.25C6.3 2.84674 6.06295 3.41903 5.64099 3.84099C5.21903 4.26295 4.64674 4.5 4.05 4.5C3.45326 4.5 2.88097 4.26295 2.45901 3.84099C2.03705 3.41903 1.8 2.84674 1.8 2.25C1.8 1.65326 2.03705 1.08097 2.45901 0.65901C2.88097 0.237053 3.45326 0 4.05 0ZM14.4 0C14.9967 0 15.569 0.237053 15.991 0.65901C16.4129 1.08097 16.65 1.65326 16.65 2.25C16.65 2.84674 16.4129 3.41903 15.991 3.84099C15.569 4.26295 14.9967 4.5 14.4 4.5C13.8033 4.5 13.231 4.26295 12.809 3.84099C12.3871 3.41903 12.15 2.84674 12.15 2.25C12.15 1.65326 12.3871 1.08097 12.809 0.65901C13.231 0.237053 13.8033 0 14.4 0ZM0 8.40094C0 6.74438 1.34437 5.4 3.00094 5.4H4.20187C4.64906 5.4 5.07375 5.49844 5.45625 5.67281C5.41969 5.87531 5.40281 6.08625 5.40281 6.3C5.40281 7.37437 5.87531 8.33906 6.62062 9C6.615 9 6.60937 9 6.60094 9H0.599062C0.27 9 0 8.73 0 8.40094ZM11.3991 9C11.3934 9 11.3878 9 11.3794 9C12.1275 8.33906 12.5972 7.37437 12.5972 6.3C12.5972 6.08625 12.5775 5.87812 12.5437 5.67281C12.9262 5.49562 13.3509 5.4 13.7981 5.4H14.9991C16.6556 5.4 18 6.74438 18 8.40094C18 8.73281 17.73 9 17.4009 9H11.3991ZM6.3 6.3C6.3 5.58392 6.58446 4.89716 7.09081 4.39081C7.59716 3.88446 8.28392 3.6 9 3.6C9.71608 3.6 10.4028 3.88446 10.9092 4.39081C11.4155 4.89716 11.7 5.58392 11.7 6.3C11.7 7.01608 11.4155 7.70284 10.9092 8.20919C10.4028 8.71554 9.71608 9 9 9C8.28392 9 7.59716 8.71554 7.09081 8.20919C6.58446 7.70284 6.3 7.01608 6.3 6.3ZM3.6 13.6491C3.6 11.5791 5.27906 9.9 7.34906 9.9H10.6509C12.7209 9.9 14.4 11.5791 14.4 13.6491C14.4 14.0625 14.0653 14.4 13.6491 14.4H4.35094C3.9375 14.4 3.6 14.0653 3.6 13.6491Z"
      fill={color}
    />
  </Svg>
);

export default AddAvailabilityIcon;
