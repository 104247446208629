/* eslint-disable no-nested-ternary */
import {
  AnimatedSpinner,
  ArrowLeft,
  TouchableView,
  View,
  styled,
  useWindowWidthState,
} from '@talkspace/react-toolkit';

import ErrorScreen from './ErrorScreen';

const Styled = {
  MainContainer: styled(View)({ height: '100%' }),
  ContentContainer: styled(View)(({ isMobile }: { isMobile: boolean }) => {
    return {
      height: isMobile ? 244 : 215,
      justifyContent: 'center',
    };
  }),
  BackButtonContainer: styled(View)({ position: 'relative', width: '100%', top: 3, height: 0 }),
  BackButton: styled(TouchableView)({ marginLeft: -300, padding: 5 }),
};

interface Props {
  children: React.ReactNode;
  showBackButton?: boolean;
  isError: boolean;
  isLoading: boolean;
  onPressBack: () => void;
  closeTray: () => void;
}
const BaseView = ({
  children,
  showBackButton,
  isError,
  isLoading,
  onPressBack,
  closeTray,
}: Props) => {
  const { isMobile } = useWindowWidthState();
  return (
    <Styled.MainContainer>
      {showBackButton ? (
        <Styled.BackButtonContainer align="center">
          <Styled.BackButton onPress={onPressBack} dataQa="goBackArrow">
            <ArrowLeft />
          </Styled.BackButton>
        </Styled.BackButtonContainer>
      ) : null}
      <Styled.ContentContainer isMobile={isMobile}>
        {isLoading ? (
          <AnimatedSpinner width={20} height={20} />
        ) : isError ? (
          <ErrorScreen closeTray={closeTray} />
        ) : (
          <>{children}</>
        )}
      </Styled.ContentContainer>
    </Styled.MainContainer>
  );
};

export default BaseView;
