import moment from 'moment-timezone';

import { DoseSpotFormValues, DoseSpotPatientRequest, DoseSpotSSO } from './types';
import appConfig from '../../../../../utils/configs';
import { removeDiacritics } from '../../../../../utils/removeDiacritics';
import { replaceEmDash } from '../../../../../utils/replaceEmDash';

const genderEnumsAndIDs = [
  { text: 'Male', id: 1 },
  { text: 'Female', id: 2 },
  { text: 'Unknown', id: 3 },
];

export const generateDoseSpotPatientURL = (doseSpotPatientID: number, doseSpotSSO: DoseSpotSSO) => {
  const { clinicID, clinicianID, ssoCode, encryptedClinicianID } = doseSpotSSO;

  const urlEncodedSSOCode = encodeURIComponent(ssoCode);
  const urlEncodedEncryptedClinicianID = encodeURIComponent(encryptedClinicianID);

  const searchParams = new URLSearchParams();

  searchParams.set('SingleSignOnClinicId', clinicID.toString());
  searchParams.set('SingleSignOnUserId', clinicianID);
  searchParams.set('PatientId', doseSpotPatientID.toString());
  searchParams.set('SingleSignOnPhraseLength', '32');
  searchParams.set('SingleSignOnCode', urlEncodedSSOCode);
  searchParams.set('SingleSignOnUserIdVerify', urlEncodedEncryptedClinicianID);

  return `${appConfig.doseSpot.uiURL}?${searchParams.toString()}`;
};

export const parseUpdateFormValues = (formValues: DoseSpotFormValues): DoseSpotPatientRequest => {
  const {
    firstName,
    lastName,
    dateOfBirth,
    gender,
    address,
    address2,
    city,
    state,
    zipcode,
    primaryPhone,
  } = formValues;

  const dateOfBirthString = moment.utc(dateOfBirth).format();

  const genderID = genderEnumsAndIDs.find(({ text }) => text === gender)?.id;

  return {
    firstName,
    lastName,
    dateOfBirth: dateOfBirthString,
    gender: genderID,
    address,
    address2: address2 || '',
    city,
    state,
    zipcode,
    primaryPhone,
  };
};

export const parseCreateFormValues = (formValues: DoseSpotFormValues): DoseSpotPatientRequest => {
  const {
    firstName,
    lastName,
    dateOfBirth,
    gender,
    address,
    address2,
    city,
    state,
    zipcode,
    primaryPhone,
  } = formValues;
  const dateOfBirthString = moment.utc(dateOfBirth).format();
  // trim the +1 international code
  const phoneNumber = primaryPhone.replace('+1', '');

  const genderID = genderEnumsAndIDs.find(({ text }) => text === gender)?.id;

  return {
    firstName: removeDiacritics(firstName),
    lastName: removeDiacritics(lastName),
    dateOfBirth: dateOfBirthString,
    gender: genderID,
    address: replaceEmDash(address),
    address2: address2 ? replaceEmDash(address2) : '',
    city,
    state,
    zipcode,
    primaryPhone: phoneNumber,
  };
};

export const ONE_MINUTE_IN_MS = 60 * 1000;
