import { RefObject } from 'react';

import { EmotionStyle, StickyDrawer, View, useThemeVersion } from '@talkspace/react-toolkit';
import { StickyDrawerProps } from '@talkspace/react-toolkit/src/designSystems/components/StickyDrawer/StickyDrawer';
import styled from '@/core/styled';

const BottomButtonContainerV0 = styled(View)(({ theme: { safeAreaInsets } }) => {
  return {
    width: 335,
    position: 'absolute',
    paddingTop: 15,
    paddingBottom: 20 + safeAreaInsets.bottom,
    height: 'auto',
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'left',
    backgroundColor: 'white',
  };
});

type Props = {
  children: React.ReactNode;
  customV0?: JSX.Element;
  propsV0?: {
    style?: EmotionStyle;
    ref?: RefObject<HTMLDivElement>;
  };
} & Pick<StickyDrawerProps, 'autoPositioning'>;

const BottomButtonContainer = ({
  children,
  customV0,
  propsV0,
  autoPositioning,
}: Props): JSX.Element => {
  const { major } = useThemeVersion();
  switch (major) {
    case 1:
      return <StickyDrawer autoPositioning={autoPositioning}>{children}</StickyDrawer>;
    default:
      if (customV0) {
        return customV0;
      }
      return <BottomButtonContainerV0 {...propsV0}>{children}</BottomButtonContainerV0>;
  }
};

export default BottomButtonContainer;
