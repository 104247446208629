import Svg, { Path, G } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

const IntakeCompletedCheckMark = ({ width = 40, height = 40, ...props }) => {
  const { colors } = useEmotionTheme();
  const titleText = 'check mark';
  return (
    <Svg
      width={width}
      title={titleText}
      aria-label={titleText}
      height={height}
      viewBox="0 0 40 40"
      {...props}
    >
      <defs>
        <circle id="path-1" cx="16" cy="16" r="16" />
      </defs>
      <G fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <circle
          cx="16"
          cy="16"
          r="14.5"
          fill={colors.emerald}
          stroke={colors.white}
          strokeWidth="3"
        />
        <Path
          stroke={colors.white}
          strokeLinecap="round"
          strokeWidth="3"
          d="M10.6392964 16.7027027L14.3708191 20.547894 20.9952207 12.1351351"
        />
      </G>
    </Svg>
  );
};

export default IntakeCompletedCheckMark;
