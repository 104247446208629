import { useState } from 'react';

const useAccordion = ({
  initialState,
  disable = false,
}: {
  initialState: boolean;
  disable?: boolean;
}) => {
  const [openState, setOpenState] = useState<boolean>(initialState);

  const toggleAccordion = () => {
    if (disable) return;
    setOpenState((prevState) => !prevState);
  };

  return {
    open: openState,
    toggleAccordion,
  };
};

export default useAccordion;
