import { Button, Huge, Modal, Standard, useWindowWidthState, View } from '@talkspace/react-toolkit';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useBiometricLock from '../../hooks/useBiometricLock';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import styled from '../../core/styled';

const ModalContainer = styled(View)(({ theme: { colors } }) => {
  return {
    background: colors.white,
    textAlign: 'center',
    alignItems: 'center',
    width: 335,
  };
});

enum ScreenStatus {
  PROMPT_BIOMETRY,
  ERROR,
  SUCCESS,
}

const PromptBiometricLock = () => {
  const [status, setStatus] = useState(ScreenStatus.PROMPT_BIOMETRY);
  const closeModal = useCloseModal();
  const { promptBiometricLock, biometryType } = useBiometricLock();

  const unlock = useCallback(async () => {
    const success = await promptBiometricLock();
    if (success) {
      setStatus(ScreenStatus.SUCCESS);
    } else {
      setStatus(ScreenStatus.ERROR);
    }
  }, [promptBiometricLock]);

  useEffect(() => {
    let biometricLockTimeout: ReturnType<typeof setTimeout> | undefined;
    if (status === ScreenStatus.SUCCESS) {
      biometricLockTimeout = setTimeout(() => {
        closeModal();
      }, 2000);
    }
    return () => {
      if (biometricLockTimeout) clearTimeout(biometricLockTimeout);
    };
  }, [status, closeModal]);

  const { isMobile } = useWindowWidthState();
  const screenContent = useMemo(() => {
    switch (status) {
      case ScreenStatus.PROMPT_BIOMETRY: {
        return (
          <>
            <Huge style={{ marginBottom: 15 }}>Use {biometryType} to unlock</Huge>
            <Standard>
              You've enabled Biometric Lock. Use {biometryType} to unlock your app.
            </Standard>
            <Button style={{ marginTop: 45 }} onPress={unlock}>
              Unlock App
            </Button>
          </>
        );
      }
      case ScreenStatus.SUCCESS:
        return (
          <>
            <Huge style={{ marginBottom: 15 }}>Success!</Huge>
            <Standard>Your app has been unlocked</Standard>
            <Button style={{ marginTop: 45 }} onPress={() => closeModal()}>
              Close
            </Button>
          </>
        );
      case ScreenStatus.ERROR:
        return (
          <>
            <Huge style={{ marginBottom: 15 }}>Could not unlock your app</Huge>
            <Standard>Please reopen the app and try again.</Standard>
          </>
        );
      default:
        return null;
    }
  }, [biometryType, closeModal, status, unlock]);
  return (
    <Modal.Panel
      showCloseButton={status === ScreenStatus.SUCCESS}
      contentViewStyle={{ marginTop: isMobile ? 120 : 48 }}
    >
      <ModalContainer>{screenContent}</ModalContainer>
    </Modal.Panel>
  );
};

export default PromptBiometricLock;
