import { FunctionComponent } from 'react';
import {
  Link,
  Standard,
  View,
  formatSelectOptionsFromConfig,
  OptionType,
  X,
} from '@talkspace/react-toolkit';
import { states } from '@talkspace/configs';
import SignupInput from './SignupInput';
import SignupSelect from './SignupSelect';

const stateOptions = formatSelectOptionsFromConfig(states);

interface Props {
  typeOptions: OptionType[];
  onAdd: () => void;
  onRemove: () => void;
  index: number;
  isLast?: boolean;
}
const LicenseView: FunctionComponent<Props> = ({ typeOptions, onAdd, onRemove, index, isLast }) => (
  <View>
    <SignupSelect
      fieldName={`licenses[${index}].licenseTypeID`}
      label="Type"
      options={typeOptions}
    />
    <SignupInput
      fieldName={`licenses[${index}].number`}
      label="Number"
      placeholder="• • • • • • • • • •"
    />
    <SignupSelect fieldName={`licenses[${index}].state`} label="State" options={stateOptions} />
    {isLast ? (
      <Link onClick={onAdd} style={{ marginTop: 16 }}>
        <Standard variant="standardDarkCyan">+ Add another license</Standard>
      </Link>
    ) : (
      <Link
        onClick={onRemove}
        style={{ marginTop: 16, display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        <X size={7} style={{ marginRight: 4 }} />
        <Standard variant="standardDarkGrey">Remove license</Standard>
      </Link>
    )}
  </View>
);

export default LicenseView;
