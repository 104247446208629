import { VoidFunctionComponent } from 'react';
import { ClientTransaction } from 'ts-frontend/types';
import { formatCurrency } from 'ts-frontend/helpers/billingUtils';
import {
  EmotionStyle,
  ExtraHuge,
  ExtraTiny,
  useEmotionTheme,
  View,
} from '@talkspace/react-toolkit';

interface Props {
  pendingInvoices: Array<ClientTransaction>;
  style?: EmotionStyle;
}

const calculateTotalAndFormat = (transactions: Array<ClientTransaction>): string => {
  const totalCents = transactions.map((it) => it.amount).reduce((acc, amount) => acc + amount, 0);
  return formatCurrency(totalCents / 100, transactions[0]?.currency || 'USD');
};

const TotalPendingInvoices: VoidFunctionComponent<Props> = ({ pendingInvoices, style = {} }) => {
  const { colors } = useEmotionTheme();
  return (
    <View style={style}>
      <ExtraTiny>Total pending</ExtraTiny>
      <ExtraHuge style={{ color: colors.green, marginTop: 4 }}>
        {calculateTotalAndFormat(pendingInvoices)}
      </ExtraHuge>
    </View>
  );
};

export default TotalPendingInvoices;
