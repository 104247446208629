import { FunctionComponent } from 'react';
import Svg, { G, Path } from 'svgs';
import { useEmotionTheme, EmotionStyle } from '../../core/styled';

interface ChatIconProps {
  color?: string;
  iconColor?: string;
  width?: number;
  height?: number;
  style?: EmotionStyle;
}

const ChatIcon: FunctionComponent<ChatIconProps> = ({
  color,
  iconColor,
  width = 70,
  height = 70,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'chat icon';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox="4 0 70 70"
      {...otherProps}
    >
      <defs>
        <filter
          x="-105.7%"
          y="-97%"
          width="311.4%"
          height="324.2%"
          filterUnits="objectBoundingBox"
          id="ChatIcon-filter"
        >
          <feOffset dy={5} in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur stdDeviation={11.5} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
          <feColorMatrix
            values="0 0 0 0 0.194204162 0 0 0 0 0.338142979 0 0 0 0 0.76636096 0 0 0 0.449737762 0"
            in="shadowBlurOuter1"
          />
        </filter>
        <Path
          d="M32.713 46.691a9.737 9.737 0 01-.369 3.581 2.12 2.12 0 00.678 2.233c.653.543 1.567.649 2.327.27 2.131-1.07 4.963-2.929 6.684-5.785h1.412C50.93 46.99 57 40.948 57 33.495 57 26.042 50.931 20 43.445 20h-7.89c-6.934 0-12.75 5.21-13.479 12.075-.73 6.865 3.862 13.17 10.641 14.616h-.004z"
          id="ChatIcon"
        />
      </defs>
      <G fill="none" fillRule="evenodd">
        <Path
          d="M39 0c19.33 0 35 15.67 35 35S58.33 70 39 70 4 54.33 4 35 19.67 0 39 0z"
          fill={color || colors.periwinkleGrey}
        />
        <use fill="#000" filter="url(#ChatIcon-filter)" xlinkHref="#ChatIcon" />
        <use fill={iconColor || colors.a11yLinkWaterGrey} xlinkHref="#ChatIcon" />
      </G>
    </Svg>
  );
};

export default ChatIcon;
