import { BackNextButton, UserBadge, View } from '@talkspace/react-toolkit';
import { useHistory } from 'react-router';
import useQueryRoomDetails from 'ts-frontend/hooks/useQueryRoomDetails';
import styled from '../../../core/styled';
import { REDIRECT_TO } from '../SideBar/SideBar';

const BackArrowContainer = styled(View)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    if (isMobile) {
      return {
        width: 13,
        height: 21,
        alignSelf: 'center',
        marginRight: 20,
      };
    }
    return {
      display: 'none',
    };
  }
);

const NavBarUserBadge = ({
  roomID,
  nickname,
  description,
}: {
  roomID?: number;
  nickname?: string;
  description?: string;
}) => {
  const history = useHistory();
  const { data: { isRoomDischarged } = { isRoomDischarged: false } } = useQueryRoomDetails(roomID);

  const handleOnPress = () => {
    const redirectTo = sessionStorage.getItem(REDIRECT_TO);
    if (redirectTo) {
      return history.push(redirectTo);
    }
    return history.push('/rooms');
  };

  return (
    <View row>
      <BackArrowContainer>
        <BackNextButton onPress={handleOnPress} />
      </BackArrowContainer>
      <UserBadge
        badgeType={isRoomDischarged ? 'discharged' : undefined}
        nickname={nickname}
        description={description}
      />
    </View>
  );
};
export default NavBarUserBadge;
