import moment, { Moment } from 'moment';
import {
  View,
  Huge,
  Small,
  Large,
  Link,
  CircledQuestionMark,
  Standard,
  useEmotionTheme,
  useWindowWidthState,
  Button,
  CaretLeft,
  CaretRight,
  CircleClockPlaceholderIcon,
} from '@talkspace/react-toolkit';
import styled from '@/core/styled';
import ssoHelper from '@/utils/sso';

const Container = styled(View)(
  ({
    theme: {
      colors,
      window: { isMobile },
    },
  }) => {
    return {
      backgroundColor: colors.athensGrey,
      padding: isMobile ? '0 10px' : '0 20px',
      overflowY: 'visible',
    };
  }
);

const PanelContainer = styled(View)(
  ({
    theme: {
      colors,
      window: { isMobile },
    },
  }) => {
    return {
      width: '100%',
      backgroundColor: colors.white,
      borderColor: colors.extraLightGrey,
      borderWidth: 1,
      borderStyle: 'solid',
      borderRadius: 5,
      marginTop: isMobile ? 10 : 20,
      padding: isMobile ? '30px 10px' : '30px 35px',
      marginBottom: 20,
    };
  }
);

const HoursAccruedTable = styled(View)(
  ({
    theme: {
      colors,
      window: { isMobile },
    },
  }) => {
    return {
      width: '100%',
      backgroundColor: colors.permaWhisperBlueGrey,
      padding: isMobile ? '30px 15px' : '30px 25px',
    };
  }
);

const TitleRow = styled(View)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      marginBottom: 17,
      flexWrap: 'wrap',
      marginLeft: isMobile ? 15 : 0,
    };
  }
);
const DataHeaderRow = styled(View)({
  marginBottom: 17,
  flexWrap: 'nowrap',
});
const DataRow = styled(View)({
  marginBottom: 10,
  flexWrap: 'nowrap',
});
const Separator = styled(View)({
  margin: '22px 0',
  borderTop: '1px solid #000',
});

const RightColumn = styled(View)({ textAlign: 'right' });

const ValueExplainer = styled(Small)(({ theme: { colors } }) => {
  return {
    color: colors.a11yParentheticalBlue,
  };
});

const QuestionLinkContainer = styled(View)({
  margin: '10px 0',
  textAlign: 'right',
});

const PrevWeekButton = styled(Button)(({ theme: { colors } }) => {
  return {
    padding: '10px 15px',
    minHeight: 'auto',
    height: 'auto',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.permaDarkForest,
    color: colors.permaDarkForest,
    backgroundColor: colors.white,
    width: 'auto',
  };
});

interface HoursTrendProps {
  totalHours?: number | null;
  totalHoursPreviousWeek?: number | null;
}

const HoursTrend = (props: HoursTrendProps) => {
  const { totalHours, totalHoursPreviousWeek } = props;
  if (!totalHoursPreviousWeek || !totalHours) return null;
  if (totalHoursPreviousWeek === totalHours) {
    return <ValueExplainer>(Same as last week)</ValueExplainer>;
  }
  const trend = totalHoursPreviousWeek && totalHours > totalHoursPreviousWeek ? 'Up' : 'Down';
  return (
    <ValueExplainer>
      ({trend} from {totalHoursPreviousWeek} last week)
    </ValueExplainer>
  );
};

const QuestionLink = () => {
  const { colors } = useEmotionTheme();
  return (
    <QuestionLinkContainer align="end">
      <Link
        onClick={() => {
          ssoHelper.openZendesk(ssoHelper.ZendeskPath.THERAPIST_BILLABLE_HOURS_ARTICLE);
        }}
      >
        <CircledQuestionMark
          color={colors.green}
          width={15}
          height={15}
          style={{ marginBottom: -2, marginRight: 6 }}
        />
        <Standard inline variant="standardBoldGreen">
          How can I increase my billable hours?
        </Standard>
      </Link>
    </QuestionLinkContainer>
  );
};

const EmptyStateContainer = styled(View)(({ theme: { colors } }) => {
  return {
    width: '100%',
    backgroundColor: colors.permaGhostWhite,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.permaPowderBlue,
    borderRadius: 5,
    padding: 15,
  };
});

export interface UtilizationMetricsData {
  weekStart?: Moment;
  weekEnd?: Moment;
  totalHours?: number | null;
  totalHoursPreviousWeek?: number | null;
  updatedAt?: Date | null;
  liveSessions?: number | null;
  chatTherapistHours?: number | null;
  chatClientHours?: number | null;
  chatTherapistWords?: number | null;
  chatClientWords?: number | null;
  mediaMessageRecordHours?: number | null;
  mediaMessageListenHours?: number | null;
}
export interface UtilizationMetricsProps {
  metricsData?: UtilizationMetricsData;
  toggleShowPreviousWeek?: () => void;
  showPreviousWeek?: boolean;
  isEmpty?: boolean;
  isLoading?: boolean;
  isError?: boolean;
  updatedAt?: Date | null;
}
const UtilizationMetrics = (props: UtilizationMetricsProps) => {
  const {
    metricsData,
    updatedAt,
    toggleShowPreviousWeek,
    showPreviousWeek,
    isEmpty,
    isLoading,
    isError,
  } = props;

  const { colors } = useEmotionTheme();
  const { isMobile } = useWindowWidthState();

  if (isError) {
    return null;
  }

  if (isLoading) {
    return (
      <Container align="center">
        <PanelContainer>
          <View style={{ height: 300 }} />
        </PanelContainer>
      </Container>
    );
  }

  if (isEmpty || !metricsData) {
    return (
      <Container align="center">
        <PanelContainer>
          <TitleRow row justify="space-between">
            <View>
              <Huge>Billable hours</Huge>
              {updatedAt && (
                <Small variant="smallMediumBlueGrey" style={{ marginTop: 5 }}>
                  Last updated: {moment(updatedAt).format('MMMM Do YYYY, h:mmA')}
                </Small>
              )}
            </View>
          </TitleRow>
          <EmptyStateContainer>
            <View row style={{ flexWrap: 'wrap' }}>
              <View
                style={isMobile ? { width: '100%' } : { paddingRight: 15 }}
                align={isMobile ? 'center' : 'start'}
              >
                <CircleClockPlaceholderIcon />
              </View>
              <View
                style={isMobile ? { width: '100%', marginTop: 10, textAlign: 'center' } : {}}
                justify="center"
                align={isMobile ? 'center' : 'start'}
              >
                <Large style={{ color: colors.permaCharcoal, fontWeight: 800, fontSize: 17 }}>
                  Your billable hours are not ready yet!
                </Large>
                <Large
                  style={{
                    color: colors.permaCharcoal,
                    fontWeight: 400,
                    fontSize: 17,
                    marginTop: 3,
                  }}
                >
                  This is where you’ll see how your billable hours are calculated.
                </Large>
              </View>
            </View>
          </EmptyStateContainer>
          <QuestionLink />
        </PanelContainer>
      </Container>
    );
  }

  const {
    weekStart,
    weekEnd,
    totalHours,
    totalHoursPreviousWeek,
    liveSessions,
    chatTherapistHours,
    chatClientHours,
    chatTherapistWords,
    chatClientWords,
    mediaMessageRecordHours,
    mediaMessageListenHours,
  } = metricsData;

  return (
    <Container align="center">
      <PanelContainer>
        <TitleRow row justify="space-between">
          <View style={isMobile ? { width: '100%' } : {}}>
            <Huge>
              Billable hours for the {showPreviousWeek ? 'previous' : 'current'} week (
              {weekStart?.format('MMMM Do')} - {weekEnd?.format('MMMM Do')}, {weekStart?.year()}){' '}
            </Huge>
            <Small variant="smallMediumBlueGrey" style={{ marginTop: 5 }}>
              Last updated: {moment(updatedAt).format('MMMM Do YYYY, h:mmA')}
            </Small>
          </View>
          <View style={isMobile ? { marginTop: 20 } : {}}>
            {showPreviousWeek ? (
              <PrevWeekButton onPress={toggleShowPreviousWeek}>
                <Standard
                  inline
                  variant="standardBoldGreen"
                  style={{ color: colors.permaDarkForest }}
                >
                  Current week
                </Standard>
                <CaretRight
                  color={colors.permaDarkForest}
                  height={10}
                  width={10}
                  style={{ marginLeft: 8 }}
                  inline
                />
              </PrevWeekButton>
            ) : (
              <PrevWeekButton onPress={toggleShowPreviousWeek}>
                <CaretLeft
                  color={colors.permaDarkForest}
                  height={10}
                  width={10}
                  style={{ marginRight: 8 }}
                  inline
                />
                <Standard
                  inline
                  variant="standardBoldGreen"
                  style={{ color: colors.permaDarkForest }}
                >
                  Previous week
                </Standard>
              </PrevWeekButton>
            )}
          </View>
        </TitleRow>
        <HoursAccruedTable>
          <DataHeaderRow row justify="space-between">
            <View>
              <Small variant="smallExtraBoldTSBlack">Service type</Small>
            </View>
            <RightColumn>
              <Small variant="smallExtraBoldTSBlack">Hours accrued this week</Small>
            </RightColumn>
          </DataHeaderRow>
          <DataRow row justify="space-between">
            <View>
              <Small variant="smallTSBlack">Live Sessions</Small>
            </View>
            <RightColumn>
              <Small variant="smallTSBlack">{liveSessions}</Small>
            </RightColumn>
          </DataRow>
          <DataRow row justify="space-between">
            <View>
              <Small variant="smallTSBlack">Video & audio message - Recording</Small>
            </View>
            <RightColumn>
              <Small variant="smallTSBlack">{mediaMessageRecordHours}</Small>
            </RightColumn>
          </DataRow>
          <DataRow row justify="space-between">
            <View>
              <Small variant="smallTSBlack">Video & audio message - Listening</Small>
            </View>
            <RightColumn>
              <Small variant="smallTSBlack">{mediaMessageListenHours}</Small>
            </RightColumn>
          </DataRow>
          <DataRow row justify="space-between">
            <View>
              <Small variant="smallTSBlack">Messaging therapy - Reading</Small>
            </View>
            <RightColumn>
              <Small variant="smallTSBlack" inline>
                <ValueExplainer inline>({chatClientWords} words)</ValueExplainer> {chatClientHours}
              </Small>
            </RightColumn>
          </DataRow>
          <DataRow row justify="space-between">
            <View>
              <Small variant="smallTSBlack">Messaging therapy - Writing</Small>
            </View>
            <RightColumn>
              <Small variant="smallTSBlack" inline>
                <ValueExplainer inline>({chatTherapistWords} words)</ValueExplainer>{' '}
                {chatTherapistHours}
              </Small>
            </RightColumn>
          </DataRow>
          <Separator />
          <DataRow row justify="space-between">
            <View>
              <Small variant="smallExtraBoldTSBlack">Total billable hours</Small>
            </View>
            <RightColumn>
              <Small variant="smallExtraBoldTSBlack">{totalHours}</Small>
              <HoursTrend totalHours={totalHours} totalHoursPreviousWeek={totalHoursPreviousWeek} />
            </RightColumn>
          </DataRow>
        </HoursAccruedTable>
        <QuestionLink />
      </PanelContainer>
    </Container>
  );
};

export default UtilizationMetrics;
