import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const standardPath =
  'M2.09139 3.1396C1.79889 2.91539 1.37421 2.96734 1.14359 3.25171C0.912972 3.53608 0.966409 3.94896 1.2589 4.17317L17.9086 16.8604C18.2011 17.0846 18.6258 17.0327 18.8564 16.7483C19.087 16.4639 19.0336 16.051 18.7411 15.8268L17.8749 15.1679C18.5667 14.5308 18.9998 13.6284 18.9998 12.6249C18.9998 10.9324 17.7624 9.51876 16.1199 9.19611C16.2352 8.90354 16.2999 8.58362 16.2999 8.25004C16.2999 6.80085 15.0905 5.6251 13.5999 5.6251C13.0459 5.6251 12.5284 5.78916 12.1009 6.06806C11.3219 4.75558 9.86781 3.87513 8.20003 3.87513C6.89505 3.87513 5.71945 4.41653 4.89821 5.27784L2.09139 3.1396ZM14.708 16.1249L3.73386 7.71958C3.71136 7.89458 3.70011 8.07231 3.70011 8.25004C3.70011 8.32386 3.70292 8.39769 3.70574 8.47152C2.13076 9.01018 1.00016 10.4703 1.00016 12.1875C1.00016 14.3612 2.81419 16.1249 5.05009 16.1249H14.708Zs';

const pathConfig: IconConfig = {
  standard: {
    default: { path: standardPath },
  },
};

const CloudSlash = withDSIconMaker(pathConfig, 'cloud with slash');

export default CloudSlash;
