import { FunctionComponent, useEffect, useMemo } from 'react';
import { SharedChatContextProvider } from 'chat/hooks/sharedChatContext';
import { TherapistType } from 'chat/redux/constants/chatTypes';
import ChatMessageContextProvider from 'chat/hooks/chatMessageContext';
import TherapistChatContainer from './containers/TherapistChatContainer';
import MatchResults from './components/MatchResults';
import { Route, withRouter, RouteComponentProps } from '../core/routerLib/routerLib';
import { isIonic } from '../auth/reactFrame/helpers';

const TherapistRoutes: FunctionComponent<
  RouteComponentProps<{
    roomID: number;
  }> & {
    style?: {};
    therapistType: TherapistType;
    stopInputAutofocus?: boolean;
  }
> = ({ therapistType, history, style, stopInputAutofocus, ...otherProps }) => {
  useEffect(() => {
    const modalIndex = window.location.pathname.indexOf('/modal');
    if (modalIndex > -1) {
      // browser refreshed on modal route
      history.replace(`${history.location.pathname.slice(0, modalIndex)}`);
    }
  }, [history]);

  const isMobileApp = useMemo(() => isIonic(), []);

  return (
    <SharedChatContextProvider
      isTherapistChat
      therapistType={therapistType}
      isMobileApp={isMobileApp}
    >
      <ChatMessageContextProvider>
        <Route
          path="/room/:roomID"
          render={() => (
            <TherapistChatContainer
              {...otherProps}
              style={style}
              stopInputAutofocus={stopInputAutofocus}
            />
          )}
        />
        <Route
          path={['/room/:roomID/modal/matches', '/room/:roomID/*/modal/matches']}
          component={MatchResults}
        />
      </ChatMessageContextProvider>
    </SharedChatContextProvider>
  );
};

export default withRouter(TherapistRoutes);
