import { useState, useEffect } from 'react';

import { getTherapistProfile } from '../utils/apiHelper';
import { CredentialsData } from '../types';

const emptyState: CredentialsData = {
  email: '',
  password: '',
  confirmedPassword: '',
};

const useCredentials = (token: string) => {
  const [data, setData] = useState<CredentialsData>(emptyState);
  const [error, setError] = useState<Error>();

  useEffect(() => {
    getTherapistProfile(token)
      .then((res) => {
        const { email } = res;
        setData({
          email,
          password: '',
          confirmedPassword: '',
        });
      })
      .catch((err) => setError(err));
  }, [token]);
  return { data, error };
};

export default useCredentials;
