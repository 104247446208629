import { FunctionComponent } from 'react';
import * as React from 'react';
import styled, { EmotionStyle, useEmotionTheme } from '../../../core/styled';
import Text, { TextProps } from '../../../components/Text';
import { fontWeight } from '../../tokens';
import { ColorRolesVersion } from '../../types';

export const TextDSVariants = [
  'heading3xl',
  'heading2xl',
  'headingXl',
  'headingLg',
  'headingMd',
  'headingSm',
  'headingXs',
  'body',
  'bodySm',
  'bodyXs',
  'decorative/heading3Xl',
  'decorative/heading2Xl',
  'decorative/headingXl',
  'decorative/headingLg',
] as const;

export type TextDSVariant = typeof TextDSVariants[number];

interface TextDSProps extends Omit<TextProps, 'style'> {
  variant?: TextDSVariant;
  colorRole?: keyof ColorRolesVersion['typography'];
  style?: EmotionStyle;
  inheritColor?: boolean;
}

const TextHeading3xl = styled(Text)({
  fontSize: 40,
  lineHeight: '44px',
  fontWeight: fontWeight.semibold,
  letterSpacing: 1,
});
const TextHeading2xl = styled(Text)({
  fontSize: 28,
  lineHeight: '32px',
  fontWeight: fontWeight.semibold,
  letterSpacing: 0.49,
});
const TextHeadingXl = styled(Text)({
  fontSize: 24,
  lineHeight: '28px',
  fontWeight: fontWeight.semibold,
  letterSpacing: 0.36,
});
const TextHeadingLg = styled(Text)({
  fontSize: 20,
  lineHeight: '24px',
  fontWeight: fontWeight.semibold,
  letterSpacing: 0.25,
});
const TextHeadingMd = styled(Text)({
  fontSize: 16,
  lineHeight: '20px',
  fontWeight: fontWeight.semibold,
  letterSpacing: 0.16,
});
const TextHeadingSm = styled(Text)({
  fontSize: 14,
  lineHeight: '20px',
  fontWeight: fontWeight.semibold,
  letterSpacing: 0.123,
});
const TextHeadingXs = styled(Text)({
  fontSize: 12,
  lineHeight: '16px',
  fontWeight: fontWeight.semibold,
  letterSpacing: 0.09,
});
const TextBody = styled(Text)({
  fontSize: 16,
  lineHeight: '20px',
  fontWeight: fontWeight.regular,
  letterSpacing: 0.16,
});
const TextBodySm = styled(Text)({
  fontSize: 14,
  lineHeight: '20px',
  fontWeight: fontWeight.regular,
  letterSpacing: 0.123,
});
const TextBodyXs = styled(Text)({
  fontSize: 12,
  lineHeight: '16px',
  fontWeight: fontWeight.regular,
  letterSpacing: 0.09,
});
const TextDecorativeHeading3Xl = styled(Text)({
  fontFamily: 'Exposure',
  fontSize: 40,
  lineHeight: '56px',
  letterSpacing: 0.8,
});
const TextDecorativeHeading2Xl = styled(Text)({
  fontFamily: 'Exposure',
  fontSize: 28,
  lineHeight: '40px',
  letterSpacing: 0.56,
});
const TextDecorativeHeadingXl = styled(Text)({
  fontFamily: 'Exposure',
  fontSize: 24,
  lineHeight: '32px',
  letterSpacing: 0.48,
});
const TextDecorativeHeadingLg = styled(Text)({
  fontFamily: 'Exposure',
  fontSize: 20,
  lineHeight: '28px',
  letterSpacing: 0.2,
});

const getComponent = (variant: TextDSVariant | undefined) => {
  switch (variant) {
    case 'heading3xl':
      return TextHeading3xl;
    case 'heading2xl':
      return TextHeading2xl;
    case 'headingXl':
      return TextHeadingXl;
    case 'headingLg':
      return TextHeadingLg;
    case 'headingMd':
      return TextHeadingMd;
    case 'headingSm':
      return TextHeadingSm;
    case 'headingXs':
      return TextHeadingXs;
    case 'body':
      return TextBody;
    case 'bodySm':
      return TextBodySm;
    case 'bodyXs':
      return TextBodyXs;
    case 'decorative/heading3Xl':
      return TextDecorativeHeading3Xl;
    case 'decorative/heading2Xl':
      return TextDecorativeHeading2Xl;
    case 'decorative/headingXl':
      return TextDecorativeHeadingXl;
    case 'decorative/headingLg':
      return TextDecorativeHeadingLg;
    default:
      return TextBody;
  }
};

// dangerouslySetInnerHTML needed to allow for premature flow configs in quickmatch-web
const TextDS: FunctionComponent<
  TextDSProps &
    Pick<
      React.ComponentProps<typeof Text>,
      'dangerouslySetInnerHTML' | 'aria-describedby' | 'aria-roledescription' | 'tabIndex'
    >
> = ({ variant, colorRole, style, inheritColor = false, ...props }) => {
  const { colorRoles } = useEmotionTheme();
  const color = inheritColor ? 'inherit' : colorRoles.typography[colorRole || 'textDefault'];
  const TextComponent = getComponent(variant);
  return <TextComponent {...props} style={{ color, ...style }} />;
};

export default TextDS;
