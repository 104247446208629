import { VoidFunctionComponent } from 'react';
import Svg, { Path } from 'svgs';

const ClearIcon: VoidFunctionComponent = () => {
  const titleText = 'Applied filter clear icon';
  return (
    <Svg
      title={titleText}
      aria-label={titleText}
      width={10}
      height={10}
      viewBox="0 0 10 10"
      fill="none"
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.758.758a1 1 0 000 1.414L3.586 5 .758 7.828a1 1 0 101.414 1.414L5 6.414 7.83 9.243a1 1 0 001.414-1.414L6.414 5l2.83-2.829A1 1 0 007.828.757L5 3.586 2.172.758a1 1 0 00-1.414 0z"
        fill="#fff"
      />
    </Svg>
  );
};

export default ClearIcon;
