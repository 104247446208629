import Svg, { Path } from 'svgs';

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

function TrashIcon({ width = 21, height = 26, color, ...otherProps }: Props) {
  const titleText = 'Trash icon';
  return (
    <Svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      aria-label={titleText}
      fill="none"
      {...otherProps}
    >
      <Path
        d="M20.4995 6.50314C20.4995 6.13647 20.1996 5.83655 19.8329 5.83655H1.1666C0.799924 5.83655 0.5 6.13649 0.5 6.50314C0.5 6.86981 0.799941 7.16974 1.1666 7.16974H2.16664V22.6659C2.16664 24.0459 3.28989 25.1691 4.66987 25.1691H16.3333C17.7133 25.1691 18.8366 24.0459 18.8366 22.6659V7.16974H19.8366C20.2 7.16974 20.5 6.8698 20.5 6.50314H20.4995ZM17.4997 22.666C17.4997 23.3093 16.9763 23.8359 16.3297 23.8359H4.66981C4.02321 23.8359 3.49983 23.3128 3.49983 22.666V7.1698H17.4996L17.4997 22.666Z"
        fill={color || '#005C65'}
      />
      <Path
        d="M19.8328 3.83649H15.1396C15.1563 3.74651 15.1663 3.65328 15.1663 3.55655V2.11336C15.1663 1.22332 14.443 0.5 13.553 0.5H7.44653C6.55672 0.503255 5.83334 1.22659 5.83334 2.11664V3.55983C5.83334 3.65655 5.8466 3.74653 5.86008 3.83977L1.1666 3.84C0.799924 3.84 0.5 4.13994 0.5 4.5066C0.5 4.87325 0.799941 5.17319 1.1666 5.17319H19.8329C20.1996 5.17319 20.4995 4.87325 20.4995 4.5066C20.4995 4.13994 20.1996 3.83649 19.8329 3.83649L19.8328 3.83649ZM13.833 3.55655C13.833 3.70978 13.7062 3.83649 13.553 3.83649H7.44657C7.29335 3.83649 7.16663 3.71001 7.16663 3.55655V2.11336C7.16663 1.96014 7.29335 1.83342 7.44657 1.83342H13.5566C13.7098 1.83342 13.8365 1.96014 13.8365 2.11336V3.55655H13.833Z"
        fill={color || '#005C65'}
      />
      <Path
        d="M6.73613 20.4962C7.14605 20.5527 7.49945 20.2363 7.49945 19.8361V11.1696C7.49945 10.7697 7.14603 10.453 6.73613 10.5095C6.39946 10.5562 6.16602 10.8794 6.16602 11.2196V19.7825C6.16602 20.1257 6.39946 20.4491 6.73613 20.4958V20.4962Z"
        fill={color || '#005C65'}
      />
      <Path
        d="M14.0701 20.4963C14.48 20.5528 14.8334 20.2364 14.8334 19.8362V11.1697C14.8334 10.7698 14.48 10.4531 14.0701 10.5096C13.7334 10.5563 13.5 10.8795 13.5 11.2197V19.7826C13.5 20.1258 13.7334 20.4492 14.0701 20.496V20.4963Z"
        fill={color || '#005C65'}
      />
      <Path
        d="M10.4039 20.4962C10.814 20.5527 11.1672 20.2363 11.1672 19.8361V11.1696C11.1672 10.7697 10.8138 10.453 10.4039 10.5095C10.0672 10.5562 9.83398 10.8794 9.83398 11.2196V19.7825C9.83398 20.1257 10.0672 20.4491 10.4039 20.4958V20.4962Z"
        fill={color || '#005C65'}
      />
    </Svg>
  );
}

export default TrashIcon;
