import Svg, { Path } from 'svgs';
import { FunctionComponent } from 'react';
import { useEmotionTheme } from '../../core/styled';

interface PencilIconV2Props {
  width?: number;
  height?: number;
  color?: string;
}

const PencilIconV2: FunctionComponent<PencilIconV2Props> = ({
  width = 20,
  height = 20,
  color,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();

  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3513 1.00073C14.6506 1.00073 14.9536 1.1156 15.1802 1.34171C15.1802 1.34148 15.1803 1.34167 15.1805 1.34194L15.1809 1.34244L15.1816 1.34317L18.6628 4.85324L18.6654 4.85573C18.6671 4.85731 18.6687 4.85884 18.6701 4.86053C19.1087 5.32514 19.1142 6.0669 18.6613 6.51911L6.51286 18.6412C6.29248 18.8611 5.98877 18.9821 5.68615 18.9821L2.17062 19H2.16953H2.16843C1.86618 19 1.56247 18.8786 1.34209 18.659C1.10964 18.4271 1 18.1182 1 17.8162V17.8151V17.8141L1.01791 14.2879C1.01791 13.9601 1.16446 13.6851 1.34099 13.4842C1.34757 13.4765 1.35378 13.4696 1.36036 13.463L11.0784 3.78399L13.5074 1.34207L13.5074 1.34206C13.5107 1.33878 13.514 1.3355 13.5172 1.33259L13.5176 1.33223C13.7504 1.11342 14.0516 1 14.3505 1L14.3513 1.00073ZM14.3552 3.83359L13.5785 4.60854L15.4249 6.45092L16.2016 5.67597L14.3552 3.83359ZM3.37044 14.7919L11.904 6.27695L13.75 8.11933L5.2172 16.6161L3.35655 16.6303L3.37044 14.7919Z"
        fill={color || colors.deepTeal}
      />
    </Svg>
  );
};

export default PencilIconV2;
