import { View, TouchableView, styled, TimeOffIcon, Standard } from '@talkspace/react-toolkit';

const LabelWrapper = styled(View)(({ theme: { colors } }) => {
  return {
    height: 18,
    background: colors.salmonFresco,
    alignItems: 'center',
    paddingLeft: 4,
  };
});

const Label = styled(Standard)(({ theme: { colors } }) => {
  return {
    fontSize: 12,
    fontWeight: 500,
    color: colors.dune,
    marginLeft: 3,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  };
});

const Container = styled(TouchableView)(() => {
  return {
    borderRadius: 'unset',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(234, 220, 217, 0.7)',
  };
});

const TimeOffBlock = () => (
  <Container>
    <LabelWrapper row>
      <TimeOffIcon />
      <Label>Time off</Label>
    </LabelWrapper>
  </Container>
);

export default TimeOffBlock;
