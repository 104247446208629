import { useHistory, useLocation } from 'react-router';
import { useEffect } from 'react';
import { Booking } from 'ts-frontend/types';

export const getTentativeRepeatingBookings = (booking: Booking) =>
  booking.repeatingBookings?.filter(
    (b) => b.status === 'active' && b.timekitBookingState === 'tentative'
  ) || [];

const useChangePrimaryBookingPathParam = (booking: Booking | undefined) => {
  const history = useHistory();
  const { pathname, search, state } = useLocation();

  useEffect(() => {
    if (!booking) return;

    const tentativeRepeatingBookings = getTentativeRepeatingBookings(booking);
    const hasTentativeRepeatingBookings = !!tentativeRepeatingBookings.length;

    if (
      (booking.status === 'client-canceled' || booking.status === 'therapist-canceled') &&
      hasTentativeRepeatingBookings
    ) {
      const firstTentativeRepeatingBooking = tentativeRepeatingBookings[0];
      const currentBookingID = booking.id;
      const newBookingID = firstTentativeRepeatingBooking.timekitBookingID;

      const newPath = pathname.replace(currentBookingID, newBookingID);
      if (pathname !== newPath) {
        history.replace({
          pathname: newPath,
          state,
          search,
        });
      }
    }
  }, [booking, history, pathname, search, state]);
};

export default useChangePrimaryBookingPathParam;
