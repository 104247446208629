import { FunctionComponent } from 'react';
import styled, { EmotionStyle } from '../../core/styled';
import View from '../View';

export interface BubbleData {
  key: string;
  content: string | JSX.Element;
  icon: JSX.Element;
  bubbleStyle?: EmotionStyle;
  onPress?: () => void;
}

interface BubbleProps {
  isFirst?: boolean;
  isLast?: boolean;
  bubbleStyle?: EmotionStyle;
}

interface StackBubbleProps {
  data: BubbleData[];
  bubbleContainerStyle?: EmotionStyle;
}

const StackBubbleContainer = styled(View)(() => {
  return {
    width: 235,
  };
});

const Bubble = styled(View)<BubbleProps>(({ isFirst, isLast, theme: { colors } }) => {
  const bubbleRadiusStyle = {
    borderRadius: 5,
    ...(isFirst && { borderTopLeftRadius: 20, borderTopRightRadius: 20 }),
    ...(isLast && { borderBottomLeftRadius: 20, borderBottomRightRadius: 20 }),
  };

  return {
    backgroundColor: colors.permaLinkWaterGrey,
    display: 'flex',
    flexDirection: 'row',
    padding: 14,
    marginBottom: 3,
    cursor: 'pointer',
    ...bubbleRadiusStyle,
  };
});

const LeftColumn = styled(View)({
  flexBasis: 22,
  paddingRight: 9,
  paddingTop: 6,
  alignSelf: 'flex-start',
});

const RightColumn = styled(View)({
  flex: 1,
  wordBreak: 'break-word',
});

const StackBubble: FunctionComponent<StackBubbleProps> = ({
  data,
  bubbleContainerStyle,
  ...rest
}) => (
  <StackBubbleContainer style={bubbleContainerStyle} {...rest}>
    {data.map(({ key, icon, content, bubbleStyle, onPress }, index) => (
      <Bubble
        key={key}
        onClick={onPress}
        style={bubbleStyle}
        isFirst={index === 0}
        isLast={index === data.length - 1}
      >
        <LeftColumn>{icon}</LeftColumn>
        <RightColumn>{content}</RightColumn>
      </Bubble>
    ))}
  </StackBubbleContainer>
);

export default StackBubble;
