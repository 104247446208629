import {
  SelectRounded,
  View,
  Checkbox,
  useEmotionTheme,
  CheckV2,
  EmotionTheme,
  styled,
} from '@talkspace/react-toolkit';
import { SelectViewProps } from '@talkspace/react-toolkit/src/components/Select/SelectView';
import { useState, useRef, useEffect } from 'react';
import { getCheckBoxStyle } from '../../utils';
import useDidUpdateEffect from '../../../../../../hooks/useDidUpdateEffect';

const getSelectWrapperStyle = (colors: EmotionTheme['colors'], isDisabled: boolean) => {
  return {
    marginTop: 0,
    width: 203,
    marginLeft: 17,
    background: isDisabled ? colors.permaGreyed : colors.permaPhantomWhite,
  };
};
const Container = styled(View)(() => {
  return {
    marginBottom: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  };
});

const CheckBoxAndSelectContainer = ({
  handleSelect,
  isDisabled,
  styles,
  title,
  value,
  options,
  components,
  isChecked = false,
  handleChecked,
  children,
}: SelectViewProps) => {
  const { colors } = useEmotionTheme();
  const checkboxStyle = getCheckBoxStyle(colors, isChecked, isDisabled);
  const selectWrapperStyle = getSelectWrapperStyle(colors, isDisabled || !value);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const containerRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [containerRef]);

  useDidUpdateEffect(() => {
    if (!isOpen && !value && isChecked) {
      handleChecked(false);
    }
  }, [isOpen]);

  return (
    <Container ref={containerRef}>
      <Checkbox
        alignCenter
        labelStyle={{ fontWeight: 400, lineHeight: '21px' }}
        isLabelOnRight
        label={title}
        isChecked={isChecked}
        setIsChecked={(val) => {
          handleChecked(val);
          setIsOpen(val);
        }}
        checkedColor={colors.accessibilityGreenDark}
        checkboxStyle={checkboxStyle}
        containerStyle={{ marginBottom: 0 }}
        checkComponent={<CheckV2 />}
        isDisabled={isDisabled}
      />

      <SelectRounded
        closeMenuOnSelect
        menuIsOpen={isOpen}
        value={isChecked ? value : null}
        onChange={(val) => {
          handleSelect(val);
        }}
        options={options}
        isDisabled={isDisabled}
        placeholder="Select"
        wrapperStyle={selectWrapperStyle}
        styles={styles}
        components={{ ...components }}
        shouldDisplayLock={false}
        menuPlacement="auto"
        onMenuOpen={() => setIsOpen(true)}
        onMenuClose={() => setIsOpen(false)}
      />

      {children}
    </Container>
  );
};

export default CheckBoxAndSelectContainer;
