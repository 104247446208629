import { useEffect, useState } from 'react';

import { View, Large, useEmotionTheme } from '@talkspace/react-toolkit';

import { UpcomingBookingCard, Booking } from '../../../../modules/upcomingBookings';

import styled from '../../../../core/styled';
import { FetchState } from '../../../../modules/upcomingBookings/types';

const MAX_BOOKINGS_VISIBLE_WHEN_COLLAPSED = 2;

const BookingWrapper = styled(View)<{ index: number; totalNumber: number }>(
  ({ index, totalNumber }) => {
    return {
      borderWidth: 0,
      borderTopWidth: index === 0 ? 0 : 1,
      marginBottom: totalNumber > 1 && index < totalNumber - 1 ? 20 : 0,
      paddingTop: totalNumber > 0 && index > 0 ? 20 : 0,
      borderStyle: 'solid',
      borderColor: '#C3CBDA',
    };
  }
);

interface BookingsListProps {
  bookings?: Booking[];
  isLoading: boolean;
  fetchState: FetchState;
  handleCancelBooking: (bookingID: string) => void;
  handleConfirmBooking: (bookingID: string, hasBreakAfterSession: boolean | undefined) => void;
  handleDeclineBooking: (bookingID: string) => void;
  handleRemoveBooking: (bookingID: string) => void;
}

const BookingsList = ({
  bookings,
  isLoading,
  fetchState,
  handleCancelBooking,
  handleConfirmBooking,
  handleDeclineBooking,
  handleRemoveBooking,
}: BookingsListProps) => {
  const { colors } = useEmotionTheme();
  const isCollapsible = (bookings?.length || 0) > MAX_BOOKINGS_VISIBLE_WHEN_COLLAPSED;
  const [isCollapsed, setIsCollapsed] = useState<boolean>();
  const [visibleBookings, setVisibleBookings] = useState<Booking[] | undefined>(
    bookings?.slice(0, MAX_BOOKINGS_VISIBLE_WHEN_COLLAPSED)
  );

  useEffect(() => {
    if (bookings && bookings.length > MAX_BOOKINGS_VISIBLE_WHEN_COLLAPSED) {
      setIsCollapsed(true);
    }
  }, [bookings]);

  useEffect(() => {
    if (isCollapsed) {
      setVisibleBookings(bookings?.slice(0, MAX_BOOKINGS_VISIBLE_WHEN_COLLAPSED));
    } else {
      setVisibleBookings(bookings);
    }
  }, [bookings, isCollapsed]);

  if (isLoading) {
    return <View style={{ height: 110 }} />;
  }

  return bookings && visibleBookings && visibleBookings.length ? (
    <>
      {visibleBookings.map((booking, i) => (
        <BookingWrapper index={i} totalNumber={visibleBookings.length} key={booking.id}>
          <UpcomingBookingCard
            booking={booking}
            handleCancelBooking={() => handleCancelBooking(booking.id)}
            handleConfirmBooking={(hasBreakAfterSession) =>
              handleConfirmBooking(booking.id, hasBreakAfterSession)
            }
            handleDeclineBooking={() => handleDeclineBooking(booking.id)}
            handleRemoveBooking={() => handleRemoveBooking(booking.id)}
            handleJoinCall={() => undefined}
            isUpdating={fetchState === 'fetching'}
          />
        </BookingWrapper>
      ))}
      {isCollapsible && (
        <View
          align="center"
          style={{
            margin: '16px 0 0',
            paddingTop: 8,
            borderTop: `1px solid ${colors.periwinkleGrey}`,
          }}
        >
          <Large
            style={{
              color: colors.permaBlueStoneNew,
              fontStyle: 'normal',
              fontWeight: 700,
              fontSize: 15,
              lineHeight: '20px',
              cursor: 'pointer',
            }}
            onPress={() => setIsCollapsed(!isCollapsed)}
          >
            {isCollapsed ? `View all (${bookings.length})` : 'View less'}
          </Large>
        </View>
      )}
    </>
  ) : (
    <View style={{ height: 81 }} justify="center">
      <Large variant="largeMediumTSBlack" style={{ textAlign: 'center' }}>
        You have no upcoming live sessions with this client.
      </Large>
    </View>
  );
};

export default BookingsList;
