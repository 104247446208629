import * as React from 'react';
import Svg, { G, Path } from 'svgs';
import { useEmotionTheme, EmotionStyle } from '../../core/styled';

interface VideoCalendarProps {
  width?: number;
  height?: number;
  color?: string;
  style?: EmotionStyle;
}

const VideoCalendar: React.VFC<VideoCalendarProps> = ({
  color,
  width = 28,
  height = 30,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'video calendar';

  return (
    <Svg width={width} height={height} title={titleText} aria-label={titleText} {...otherProps}>
      <G fill="none" fillRule="evenodd">
        <Path
          d="M6.047 0a1.98 1.98 0 011.98 1.98l-.001 2.057h11.652V1.98A1.98 1.98 0 0121.659 0h.054a1.98 1.98 0 011.98 1.98v2.097l-.005.14a5.402 5.402 0 014.018 5.22v15.056a5.4 5.4 0 01-5.4 5.4H5.4a5.4 5.4 0 01-5.4-5.4V9.438a5.402 5.402 0 014.018-5.222 2.164 2.164 0 01-.005-.14V1.98A1.98 1.98 0 015.993 0h.054z"
          fill={colors.purple || color}
        />
        <Path
          d="M18.467 18.472A3.528 3.528 0 0114.939 22H9.528A3.528 3.528 0 016 18.472v-3.944A3.528 3.528 0 019.528 11h5.41a3.528 3.528 0 013.529 3.528v1.32c0-.521.288-1 .748-1.245l1.711-.91A1.411 1.411 0 0123 14.939v3.122a1.411 1.411 0 01-2.074 1.246l-1.71-.91a1.411 1.411 0 01-.75-1.245v1.32z"
          fill="#FFF"
        />
      </G>
    </Svg>
  );
};

export default VideoCalendar;
