import { FunctionComponent, useEffect, useState } from 'react';
import { Large, View, styled, Small } from '@talkspace/react-toolkit';
import useQueryBusinessLine from 'ts-frontend/hooks/useQueryBusinessLine';
import useQueryNotes, { NoteSessionReportData } from 'hooks/notes/useQueryNotes';
import useMutationSubmitProgressNote from 'hooks/notes/useMutationSubmitProgressNote';
import useMutationSaveDraftProgressNote from 'hooks/notes/useMutationSaveDraftProgressNote';
import useQueryProgressNote from 'hooks/notes/useQueryProgressNote';
import useMutationUpdateProgressNote from 'hooks/notes/useMutationUpdateProgressNote';
import Submenu from 'components/Reusable/Submenu';
import useQueryRoomDetails from 'ts-frontend/hooks/useQueryRoomDetails';
import {
  ProgressNoteDetailsParams,
  useInRoomSchedulingActions,
  useInRoomSchedulingState,
} from 'inRoomScheduling';
import { useHistory, useLocation, useParams } from '@/core/routerLib';
import {
  getSubmenuTitleAndSubtitle,
  getSessionReportPosition,
  ACTION_SETTLED_TIMEOUT,
} from '../../utils';
import { NoteError, ProgressNoteFormContainerLocationState } from '../../types';
import {
  useProgressNoteFormActions,
  useProgressNoteFormState,
} from '../context/PsychProgressNoteFormContext';
import { FormSection, FormSections } from '../context/types';
import SessionDateAndModalitySection from './SessionDateAndModalitySection';
import SessionInformationSection from './SessionInformationSection';
import DiagnosisSection from './DiagnosisSection';
import RiskAssessmentSection from './RiskAssessmentSection';
import SubstanceUseSection from './SubstanceUseSection';
import TreatmentPlanProgressSection from './TreatmentPlanProgressSection';
import MedicalInformationSection from './MedicalInformationSection';
import SessionSummarySection from './SessionSummarySection';
import PsychSessionSummarySection from './PsychSessionSummarySection';
import MentalStatusExamSection from './MentalStatusExamSection';
import PsychPlanSection from './PsychPlanSection';
import NoteFormFooter from '../../NoteFormFooter';
import { SaveProgressNoteDialogLocationState } from '../../SaveProgressNoteDialog';

interface CheckIfCanSubmitParams {
  formSections: FormSections;
  isPsych: boolean;
  isClientDischarged: boolean;
  serviceStartDate?: Date | null;
  serviceEndDate?: Date | null;
  isEAP?: boolean;
}

const cannotSubmitReasons = {
  discharged: 'Client is discharged',
  futureStartDate: 'Service start date is in the future',
  futureEndDate: 'Service end date is in the future',
  sectionIncomplete: 'To submit the note, make sure each section is confirmed with a check mark.',
};

const checkCanSubmitErrorMessage = ({
  formSections,
  isPsych,
  isClientDischarged,
  serviceStartDate,
  serviceEndDate,
  isEAP,
}: CheckIfCanSubmitParams) => {
  if (isClientDischarged && !isEAP) {
    return cannotSubmitReasons.discharged;
  }

  const now = new Date();
  now.setHours(23, 59, 59, 999); // latest time for today

  if (serviceStartDate && serviceStartDate > now) {
    return cannotSubmitReasons.futureStartDate;
  }
  if (serviceEndDate && serviceEndDate > now) {
    return cannotSubmitReasons.futureEndDate;
  }

  const sharedSections: Array<FormSection> = [
    'sessionDateAndModality',
    'sessionInformation',
    'diagnosis',
    'riskAssessment',
    'substanceUse',
  ];
  const nonPsychOnlySections: Array<FormSection> = ['treatmentPlanProgress', 'sessionSummary'];
  const psychOnlySections: Array<FormSection> = [
    'medicalInformation',
    'mentalStatusExam',
    'psychSessionSummary',
    'psychPlan',
  ];
  const sectionNames = isPsych
    ? [...sharedSections, ...psychOnlySections]
    : [...sharedSections, ...nonPsychOnlySections];

  let sectionNotCompleteMessage;
  sectionNames.forEach((sectionName) => {
    if (!formSections[sectionName].completed) {
      sectionNotCompleteMessage = cannotSubmitReasons.sectionIncomplete;
    }
  });
  if (sectionNotCompleteMessage) {
    return sectionNotCompleteMessage;
  }

  return null;
};

const checkIfChanged = ({
  formSections,
  isClientDischarged,
  isEAP,
}: Omit<CheckIfCanSubmitParams, 'isPsych'>) => {
  if (isClientDischarged && !isEAP) {
    return false;
  }
  const changedSections = Object.keys(formSections).filter(
    (sectionName) => !!formSections[sectionName].changed
  );
  return !!changedSections.length;
};

const MessageWrapper = styled(Small)({
  marginBottom: 35,
  marginLeft: 20,
  marginTop: 10,
});

const ProgressNoteFormContainer: FunctionComponent = () => {
  const history = useHistory();
  const location = useLocation<ProgressNoteFormContainerLocationState | undefined>();
  const { roomID, noteID } = useParams<{ roomID: string; noteID?: string }>();
  const [showSpinner, setShowSpinner] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [error, setError] = useState<NoteError>({ title: '', message: '' });
  const [eapSessionReport, setEAPSessionReport] = useState<
    NoteSessionReportData | null | undefined
  >(null);
  const [isEAPAutoSubmitDraft, setIsEAPAutoSubmitDraft] = useState<boolean>(false);

  const formState = useProgressNoteFormState();
  const { setFormState } = useProgressNoteFormActions();
  const { data: { clientUserID } = {} } = useQueryRoomDetails(roomID);
  const { data: notesData } = useQueryNotes(roomID);

  const shouldFetchTheNote = formState.formMode === 'edit' || formState.formMode === 'view';
  const { data: progressNoteData } = useQueryProgressNote(clientUserID, noteID, shouldFetchTheNote);

  const isReadOnly = progressNoteData?.readOnly;
  const noteRoomID = progressNoteData?.roomID?.toString() || roomID;
  const { data: businessLineData } = useQueryBusinessLine(noteRoomID, clientUserID);

  const { mutate: saveDraftProgressNote, isLoading: isSaveDraftLoading } =
    useMutationSaveDraftProgressNote();

  const { mutate: submitProgressNote, isLoading: isSubmitLoading } =
    useMutationSubmitProgressNote();

  const { mutate: updateProgressNote, isLoading: isUpdateLoading } =
    useMutationUpdateProgressNote();

  const { dispatchSetProgressNoteDetails, dispatchSetNavigateToScheduler } =
    useInRoomSchedulingActions();
  const { navigateToSchedulerEventDate } = useInRoomSchedulingState();

  useEffect(() => {
    if (businessLineData && businessLineData.isBH && notesData?.currentSessionReport) {
      setFormState({
        sessionReportID: notesData.currentSessionReport.id,
        caseID: notesData.currentSessionReport.caseID,
      });
    }
  }, [notesData, businessLineData, setFormState]);

  useEffect(() => {
    if (businessLineData?.isEAP && progressNoteData) {
      const draftNonReopenedNote = notesData?.items.find(
        (it) =>
          it.status === 'draft' &&
          it.sessionReportData?.reopenedAt === null &&
          it.noteID.toString() === noteID
      );
      if (draftNonReopenedNote) {
        setIsEAPAutoSubmitDraft(true);
      }
    }
  }, [notesData, businessLineData, noteID, progressNoteData]);

  useEffect(() => {
    if (
      location?.state?.sessionHighlights &&
      formState.sessionSummary.summary !== location.state.sessionHighlights
    ) {
      setFormState({
        sessionSummary: {
          ...formState.sessionSummary,
          summary: location.state.sessionHighlights,
        },
      });
    }
    if (location?.search) {
      const eapQueryParams = new URLSearchParams(location.search);
      const eapSessionReportID = eapQueryParams.get('eapSessionReportID');

      if (eapSessionReportID) {
        const eapNoteItem = notesData?.items?.find(
          (it) => it.sessionReportData?.id === Number(eapSessionReportID)
        );
        const eapSessionReportData = eapNoteItem?.sessionReportData;

        if (eapSessionReportData) {
          setEAPSessionReport(eapSessionReportData);
          setFormState({
            caseID: eapSessionReportData.caseID,
            sessionReportID: eapSessionReportData.id,
            modalityID: eapSessionReportData.modalityID,
            videoCalls: eapSessionReportData.videoCallID
              ? [{ videoCallID: eapSessionReportData.videoCallID }]
              : [],
            serviceStartDate: eapSessionReportData.startDate
              ? new Date(eapSessionReportData.startDate)
              : null,
            serviceEndDate: eapSessionReportData.endDate
              ? new Date(eapSessionReportData.endDate)
              : null,
          });
        }
      }
    }
  }, [location, location.search, notesData, setFormState, formState.sessionSummary]);

  useEffect(() => {
    if (location?.state?.reopenedSession) {
      const { modalityID, serviceStartDate, serviceEndDate, videoCalls } =
        location.state.reopenedSession;
      if (modalityID && serviceStartDate && serviceEndDate && videoCalls) {
        setFormState({
          modalityID,
          serviceStartDate,
          serviceEndDate,
          videoCalls,
        });
      }
    }
  }, [location, location.state, setFormState]);

  const { showGlobalError, formMode, ...restFormState } = formState;
  const { modalityID } = formState;
  // do not allow text notes to have attached video calls
  const videoCalls = modalityID === 1 ? [] : restFormState.videoCalls;

  useEffect(() => {
    const payload: ProgressNoteDetailsParams = {
      isInCreateMode: formMode === 'create',
      eapSessionReport,
    };

    dispatchSetProgressNoteDetails(payload);

    return () => {
      dispatchSetProgressNoteDetails({
        isInCreateMode: false,
      });
    };
  }, [dispatchSetProgressNoteDetails, eapSessionReport, formMode]);

  useEffect(() => {
    if (navigateToSchedulerEventDate) {
      if (formMode === 'create') {
        const saveProgressNoteDialogLocationState: SaveProgressNoteDialogLocationState = {
          action: {
            type: 'navigate-to-scheduling',
          },
          eapSessionReport,
        };

        history.push(
          `/room/${roomID}/progress-notes/new/save-draft-dialog`,
          saveProgressNoteDialogLocationState
        );
      }
      dispatchSetNavigateToScheduler(null);
    }
  }, [
    navigateToSchedulerEventDate,
    dispatchSetNavigateToScheduler,
    formMode,
    eapSessionReport,
    roomID,
    history,
  ]);

  const handleActionSuccess = () => {
    setTimeout(() => {
      setShowSpinner(false);
      history.push(`/room/${roomID}/notes-tab`);
    }, ACTION_SETTLED_TIMEOUT);
  };

  const handleActionError = (err) => {
    setShowSpinner(false);
    const errorData: NoteError = err?.data || {};
    setShowErrorAlert(true);
    setError(errorData);
  };

  const handleCloseErrorBannerPress = () => {
    setFormState({ showGlobalError: false });
  };

  const handleErrorAlertPress = () => {
    setShowErrorAlert(false);
    setShowSpinner(false);
  };

  const handleDeleteDraftPress = () => {
    if (noteID) {
      history.push(`/room/${roomID}/progress-notes/${noteID}/edit/delete-dialog`);
    }
  };

  const handleSaveDraftPress = () => {
    setShowSpinner(true);
    saveDraftProgressNote(
      { roomID, noteID, clientUserID, ...restFormState, videoCalls },
      { onSuccess: handleActionSuccess, onError: handleActionError }
    );
  };

  const handleSubmitPress = () => {
    setShowSpinner(true);
    submitProgressNote(
      { roomID, noteID, clientUserID, ...restFormState, videoCalls },
      { onSuccess: handleActionSuccess, onError: handleActionError }
    );
  };

  const handleCancelPress = () => {
    history.goBack();
  };

  const handleSavePress = () => {
    if (noteID) {
      setShowSpinner(true);
      updateProgressNote(
        { roomID, noteID, clientUserID, ...restFormState },
        { onSuccess: handleActionSuccess, onError: handleActionError }
      );
    }
  };

  const handleEditPress = () => {
    history.push(`/room/${roomID}/progress-notes/${noteID}/edit`);
  };

  const { serviceStartDate, serviceEndDate } = formState;
  const cannotSubmitReason = checkCanSubmitErrorMessage({
    formSections: formState.formSections,
    isPsych: !!businessLineData?.isPsychiatry || false,
    isClientDischarged: !!notesData?.isClientDischarged,
    serviceStartDate,
    serviceEndDate,
    isEAP: businessLineData?.isEAP,
  });
  const canSubmit = !cannotSubmitReason;
  const hasChanged = checkIfChanged({
    formSections: formState.formSections,
    isClientDischarged: !!notesData?.isClientDischarged,
    isEAP: businessLineData?.isEAP,
  });

  const isLoading = isSaveDraftLoading || isSubmitLoading || isUpdateLoading;

  const canDeleteSubmitted =
    noteID && businessLineData && !businessLineData.isBH && !notesData?.isClientDischarged;

  const renderFooter = () => (
    <NoteFormFooter
      formMode={formMode}
      noteStatus={progressNoteData?.status}
      canSubmit={canSubmit}
      cannotSubmitReason={cannotSubmitReason}
      hasChanged={hasChanged}
      canDeleteDraft={!!noteID}
      canDeleteSubmitted={!!canDeleteSubmitted}
      isError={showErrorAlert}
      isLoading={isLoading}
      showSpinner={showSpinner}
      spinnerMessage="Saved successfully"
      showErrorBanner={showGlobalError}
      editHidden={isReadOnly}
      handleCloseErrorBannerPress={handleCloseErrorBannerPress}
      handleDeleteDraftPress={handleDeleteDraftPress}
      handleSaveDraftPress={handleSaveDraftPress}
      handleSubmitPress={handleSubmitPress}
      handleCancelPress={handleCancelPress}
      handleSavePress={handleSavePress}
      handleEditPress={handleEditPress}
      handleErrorAlertPress={handleErrorAlertPress}
      showErrorAlert={showErrorAlert}
      error={error}
    />
  );

  const sessionReportPosition = getSessionReportPosition({
    progressNoteData,
    formMode,
    businessLineData,
    notesData,
    eapSessionReport,
  });

  const { title, subtitle } = getSubmenuTitleAndSubtitle({
    noteType: 'progress',
    businessLine: businessLineData,
    sessionReportPosition,
    serviceStartDate: formState.serviceStartDate,
    serviceEndDate: formState.serviceEndDate,
    formMode,
  });

  const renderMainView = () => (
    <View>
      {formMode !== 'view' && (
        <MessageWrapper>Review each section below and make any necessary updates.</MessageWrapper>
      )}
      <SessionDateAndModalitySection
        disabled={(!!eapSessionReport && !eapSessionReport.reopenedAt) || isEAPAutoSubmitDraft}
      />
      <SessionInformationSection />
      <DiagnosisSection />
      <RiskAssessmentSection />
      <SubstanceUseSection />

      {businessLineData?.isPsychiatry ? (
        <>
          <MedicalInformationSection />
          <MentalStatusExamSection />
          <PsychSessionSummarySection />
          <PsychPlanSection />
        </>
      ) : (
        <>
          <TreatmentPlanProgressSection />
          <SessionSummarySection />
        </>
      )}
    </View>
  );

  const handleBackButtonPress = () => {
    if (formMode === 'create') {
      const saveProgressNoteDialogLocationState: SaveProgressNoteDialogLocationState = {
        action: {
          type: 'navigate-to-notes-tab',
        },
        eapSessionReport,
      };

      history.push(
        `/room/${roomID}/progress-notes/new/save-draft-dialog`,
        saveProgressNoteDialogLocationState
      );
    } else {
      history.push(`/room/${roomID}/notes-tab`);
    }
  };

  return (
    <Submenu
      titleComponent={
        <View>
          <Large variant="largeBoldWide">{title}</Large>
          <Small variant="smallBoldGrey">{subtitle}</Small>
        </View>
      }
      childComponents={[renderMainView()]}
      footerComponent={renderFooter()}
      onBackAlt={handleBackButtonPress}
    />
  );
};

export default ProgressNoteFormContainer;
