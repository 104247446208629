import { View } from '../../../components';
import styled from '../../../core/styled';

const Divider = styled(View)(({ theme: { colorRoles }, color }) => {
  return {
    borderBottom: `1px solid ${color || colorRoles.borders.borderDefault}`,
    width: '100%',
  };
});

export default Divider;
