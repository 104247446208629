import { SplashScreen } from '@capacitor/splash-screen';
import { safeIonicWrapper } from '../../ionicUtils';
import { splashScreenLogger } from '../../loggers';

const DEFAULT_IGNORE_DURATION_SECONDS = 5;

let ignoreSplashScreenUntil: number | null = null;

export const preventNextSplashScreenShow = (
  ignoreDuration: number = DEFAULT_IGNORE_DURATION_SECONDS
) => {
  ignoreSplashScreenUntil = Date.now() + ignoreDuration * 1000;
};

export const shouldShowSplashScreen = () => {
  if (ignoreSplashScreenUntil === null) {
    return true;
  }
  return Date.now() > ignoreSplashScreenUntil;
};

export const SplashScreenShow = safeIonicWrapper<typeof SplashScreen.show>(async (config) => {
  if (shouldShowSplashScreen()) {
    await SplashScreen.show(config);
  } else {
    splashScreenLogger.info('SplashScreen.show ignored due to ignoreSplashScreen flag');
  }
}, Promise.resolve(undefined));

export const SplashScreenHide = safeIonicWrapper<typeof SplashScreen.hide>(async (config) => {
  await SplashScreen.hide(config);
}, Promise.resolve(undefined));
