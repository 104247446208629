import Svg, { Path } from 'svgs';
import { FunctionComponent } from 'react';

import { useEmotionTheme, EmotionStyle } from '../../core/styled';

interface VideoModalityProps {
  width?: number;
  height?: number;
  color?: string;
  style?: EmotionStyle;
  fill?: boolean;
  dataQa?: string;
}

const VideoModality: FunctionComponent<VideoModalityProps> = ({
  width = 18,
  height = 12,
  color,
  style,
  fill,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'video';
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 18 12"
      fill="none"
      style={style}
      title={titleText}
      aria-label={titleText}
      {...otherProps}
    >
      <mask id="path-1-inside-1_416_14769" fill={colors.white}>
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.8224 0C1.26363 0 0 1.26363 0 2.8224V8.54602C0 10.1048 1.26363 11.3684 2.8224 11.3684H9.10783C10.6666 11.3684 11.9302 10.1048 11.9302 8.54602V2.8224C11.9302 1.26363 10.6666 0 9.10783 0H2.8224ZM13.3571 3.38551C12.9936 3.58309 12.7673 3.96368 12.7673 4.37743V6.57308C12.7673 6.98682 12.9936 7.36742 13.3571 7.565L16.3318 9.18175C17.084 9.59061 17.9998 9.04603 17.9998 8.18983V2.76068C17.9998 1.90448 17.084 1.3599 16.3317 1.76876L13.3571 3.38551Z"
        />
      </mask>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.8224 0C1.26363 0 0 1.26363 0 2.8224V8.54602C0 10.1048 1.26363 11.3684 2.8224 11.3684H9.10783C10.6666 11.3684 11.9302 10.1048 11.9302 8.54602V2.8224C11.9302 1.26363 10.6666 0 9.10783 0H2.8224ZM13.3571 3.38551C12.9936 3.58309 12.7673 3.96368 12.7673 4.37743V6.57308C12.7673 6.98682 12.9936 7.36742 13.3571 7.565L16.3318 9.18175C17.084 9.59061 17.9998 9.04603 17.9998 8.18983V2.76068C17.9998 1.90448 17.084 1.3599 16.3317 1.76876L13.3571 3.38551Z"
        fill={fill ? color || colors.slateGrey : colors.white}
      />
      <Path
        d="M13.3571 3.38551L14.0734 4.70343L14.0734 4.70343L13.3571 3.38551ZM13.3571 7.565L12.6408 8.88292H12.6408L13.3571 7.565ZM16.3318 9.18175L17.0481 7.86383L16.3318 9.18175ZM16.3317 1.76876L17.0481 3.08668V3.08668L16.3317 1.76876ZM1.5 2.8224C1.5 2.09206 2.09206 1.5 2.8224 1.5V-1.5C0.435202 -1.5 -1.5 0.43521 -1.5 2.8224H1.5ZM1.5 8.54602V2.8224H-1.5V8.54602H1.5ZM2.8224 9.86842C2.09206 9.86842 1.5 9.27636 1.5 8.54602H-1.5C-1.5 10.9332 0.435204 12.8684 2.8224 12.8684V9.86842ZM9.10783 9.86842H2.8224V12.8684H9.10783V9.86842ZM10.4302 8.54602C10.4302 9.27636 9.83817 9.86842 9.10783 9.86842V12.8684C11.495 12.8684 13.4302 10.9332 13.4302 8.54602H10.4302ZM10.4302 2.8224V8.54602H13.4302V2.8224H10.4302ZM9.10783 1.5C9.83817 1.5 10.4302 2.09206 10.4302 2.8224H13.4302C13.4302 0.435204 11.495 -1.5 9.10783 -1.5V1.5ZM2.8224 1.5H9.10783V-1.5H2.8224V1.5ZM14.2673 4.37743C14.2673 4.51341 14.1929 4.63849 14.0734 4.70343L12.6408 2.06759C11.7943 2.52768 11.2673 3.41396 11.2673 4.37743H14.2673ZM14.2673 6.57308V4.37743H11.2673V6.57308H14.2673ZM14.0734 6.24708C14.1929 6.31202 14.2673 6.4371 14.2673 6.57308H11.2673C11.2673 7.53655 11.7943 8.42283 12.6408 8.88292L14.0734 6.24708ZM17.0481 7.86383L14.0734 6.24708L12.6408 8.88292L15.6154 10.4997L17.0481 7.86383ZM16.4998 8.18983C16.4998 7.90843 16.8008 7.72945 17.0481 7.86383L15.6154 10.4997C17.3672 11.4518 19.4998 10.1836 19.4998 8.18983H16.4998ZM16.4998 2.76068V8.18983H19.4998V2.76068H16.4998ZM17.0481 3.08668C16.8008 3.22106 16.4998 3.04208 16.4998 2.76068H19.4998C19.4998 0.76688 17.3672 -0.501265 15.6154 0.450843L17.0481 3.08668ZM14.0734 4.70343L17.0481 3.08668L15.6154 0.450843L12.6408 2.06759L14.0734 4.70343Z"
        fill={color || colors.slateGrey}
        mask="url(#path-1-inside-1_416_14769)"
      />
    </Svg>
  );
};

export default VideoModality;
