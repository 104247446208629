import { FunctionComponent, useState, useEffect } from 'react';
import {
  useUniqueID,
  View,
  Tiny,
  Button,
  Spinner,
  useEmotionTheme,
  CircledCheckMark,
  TSInput,
  useDetectExitIntent,
  useWindowWidthState,
} from '@talkspace/react-toolkit';
import { CouponState } from 'ts-frontend/types';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { trackEvent } from '@/utils/analytics/events';
import ExitIntentModal from '../ExitIntentModal';
import {
  TALK100_COUPON,
  TALK100_COUPON_AMOUNT,
  PSYCHIATRY_FLOW_ID_68,
  PSYCHIATRY_FLOW_ID_102,
  OON_DISCOUNT_COUPONS,
} from '../../utils/constants';

interface CouponInputProps {
  initialValue?: string;
  isDisabled?: boolean;
  couponState: CouponState;
  flowID?: number | null;
  onApplyCoupon: (couponCode: string) => void;
  onChange?: () => void;
  isBackButtonClickedInMobile?: boolean;
  isOutOfNetwork?: boolean;
  setHideFooter?: (x: boolean) => void;
}

const CouponInput: FunctionComponent<CouponInputProps> = ({
  initialValue,
  isDisabled,
  couponState,
  flowID,
  isBackButtonClickedInMobile,
  onApplyCoupon,
  onChange,
  isOutOfNetwork,
  setHideFooter,
}) => {
  const { isMobile } = useWindowWidthState();
  const { oonDiscount } = useFlags();
  const { status, errorMessage, validatedCoupon } = couponState;
  const validatedCouponCode = validatedCoupon ? validatedCoupon.code : undefined;

  const [couponInput, setCouponInput] = useState<string>(validatedCouponCode || initialValue || '');
  const [showExitIntentPopUp, setShowExitIntentPopUp] = useState(false);

  const { isExiting } = useDetectExitIntent();

  const disableExitIntent =
    !flowID || !isOutOfNetwork || [PSYCHIATRY_FLOW_ID_68, PSYCHIATRY_FLOW_ID_102].includes(flowID);

  const isExitIntentAllowed = !isMobile && isExiting && !disableExitIntent;

  const isExitIntentAllowedForMobile = isMobile && !disableExitIntent;

  const isExitIntentCoupon = couponInput.trim() === TALK100_COUPON;

  useEffect(() => {
    if (isExitIntentAllowed) {
      if (!isExitIntentCoupon) {
        setShowExitIntentPopUp(true);
      }
    }
    // To ignore the couponInput dependency as it's needed only on initial mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExitIntentAllowed]);

  useEffect(() => {
    let timeOut: ReturnType<typeof setTimeout>;
    if (isExitIntentAllowedForMobile) {
      // Show Exit Intent modal if back button clicked on mobile.
      if (isBackButtonClickedInMobile && !isExitIntentCoupon) {
        setShowExitIntentPopUp(true);
      } else if (!isExitIntentCoupon) {
        // Show Exit Intent modal after 2 minutes timeout.
        timeOut = setTimeout(() => {
          setShowExitIntentPopUp(true);
        }, 120000);
      }
    }
    return () => {
      clearTimeout(timeOut);
    };

    // To ignore the couponInput dependency as it's needed only on initial mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExitIntentAllowedForMobile, isBackButtonClickedInMobile]);

  const handleOnChange = (val: string) => {
    setCouponInput(val);
    if (onChange) {
      onChange();
    }
  };

  const handleCouponOnPress = () => {
    const shouldInvalidateExitIntentCoupon =
      isExitIntentCoupon && !(isExitIntentAllowed || isExitIntentAllowedForMobile);
    const shouldInvalidateOONExperimentCoupon =
      OON_DISCOUNT_COUPONS.includes(couponInput.trim()) &&
      !(
        oonDiscount?.experimentActive &&
        oonDiscount?.variant !== 'control' &&
        oonDiscount?.coupon === couponInput.trim()
      );
    if (shouldInvalidateExitIntentCoupon || shouldInvalidateOONExperimentCoupon) {
      onApplyCoupon(`${couponInput}_INVALID_COUPON`);
    } else {
      onApplyCoupon(couponInput.trim());
    }
  };

  const isError = status === 'error';
  const ID_COUPON_ERROR = useUniqueID('ID_COUPON_ERROR');
  const { colors } = useEmotionTheme();

  const closePopUp = () => {
    setShowExitIntentPopUp(false);
    trackEvent('Exit Intent Pop-up exit', {});
  };

  const onSelectCoupon = (couponCode?: string) => {
    if (couponCode) {
      handleOnChange(couponCode);
      onApplyCoupon(couponCode);
      trackEvent('Exit Intent Coupon Applied CTA Click', {});
    }
    setShowExitIntentPopUp(false);
  };

  return (
    <View>
      {showExitIntentPopUp && (
        <ExitIntentModal
          showExitIntentPopUp={showExitIntentPopUp}
          exitIntentCouponCode={TALK100_COUPON}
          exitIntentCouponAmount={TALK100_COUPON_AMOUNT}
          onSelectCoupon={onSelectCoupon}
          closePopUp={closePopUp}
        />
      )}
      <View row>
        <TSInput
          aria-label="Apply a coupon"
          aria-describedby={isError ? ID_COUPON_ERROR : undefined}
          aria-invalid={isError}
          shouldFocus={isError}
          disabled={isDisabled}
          onChange={handleOnChange}
          onFocus={
            setHideFooter
              ? () => {
                  setHideFooter(true);
                }
              : undefined
          }
          onBlur={
            setHideFooter
              ? () => {
                  setHideFooter(false);
                }
              : undefined
          }
          value={couponInput}
          placeholder="Enter code"
          style={{ height: 40, fontSize: 16 }}
          isError={isError}
        />
        {status === 'validating' && (
          <View style={{ marginLeft: -50 }}>
            <Spinner />
          </View>
        )}
        {status === 'valid' && (
          <View align="center" justify="center" style={{ width: 50, height: 40, marginLeft: -50 }}>
            <CircledCheckMark circleFillColor={colors.conifer} />
          </View>
        )}
        <Button
          size="small"
          text="Apply"
          disabled={!couponInput || status === 'validating' || isDisabled}
          onPress={handleCouponOnPress}
          style={{ marginLeft: 7, width: 71 }}
        />
      </View>
      <Tiny id={ID_COUPON_ERROR} style={{ color: colors.red }}>
        {errorMessage}
      </Tiny>
    </View>
  );
};

export default CouponInput;
