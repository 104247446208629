import { View, Large, Text, BaseButton, useEmotionTheme, Tiny } from '@talkspace/react-toolkit';

import { useFlags } from 'launchDarkly/FlagsProvider';
import { LineItem } from 'ts-frontend/types';
import { formatCurrency } from 'ts-frontend/helpers/billingUtils';
import moment from 'moment';
import { useTranslation, Trans } from '@talkspace/i18n';
import { webOnlyStyle } from '@/core/styled/styleHelpers';
import styled from '@/core/styled';
import Divider from './Divider';

interface TotalsProps {
  lineItems: LineItem[];
  total: number;
  savings: number;
  condensed?: boolean;
  onReviewCancellationPolicyClick?: () => void;
  renewalDate?: string;
  isNoMatches?: boolean;
  totalDueText?: string;
  maxCostPostSession?: number;
  chargeInfoText?: string;
  chargeTimeText?: string;
  showSavings?: boolean;
  hideLineItems?: boolean;
}

const ExtraBig = styled(Text)(({ theme: { colors } }) => {
  return { color: colors.black, fontSize: 19, fontWeight: 700, letterSpacing: 0.11 };
});

const Row = styled(View)({
  justifyContent: 'space-between',
  flexDirection: 'row',
  paddingTop: 9,
});

const Stick = styled(View)(({ theme: { window, colors } }) => {
  const { isMobile } = window;

  const stickyStyles = {
    ...webOnlyStyle({ position: 'sticky' }),
    top: 66, // header + button
    backgroundColor: colors.white,
  };

  return isMobile ? stickyStyles : {};
});

const LineItems = ({ items }: { items: LineItem[] }) => (
  <>
    {items.map(({ name, amount, currency }) => (
      <Row key={name}>
        <Large variant="largeDarkGrey" style={{ textAlign: 'start' }}>
          {name}
        </Large>
        <Large
          style={{ whiteSpace: 'nowrap' }}
          variant={amount <= 0 ? 'largeBoldBrightGreen' : 'largeDarkGrey'}
        >
          {formatCurrency(amount, currency)}
        </Large>
      </Row>
    ))}
  </>
);

const Totals = ({
  lineItems,
  total,
  savings,
  condensed,
  onReviewCancellationPolicyClick,
  renewalDate,
  isNoMatches,
  totalDueText,
  maxCostPostSession,
  chargeInfoText,
  chargeTimeText,
  showSavings = true,
  hideLineItems = false,
}: TotalsProps) => {
  const { repeatingSessionsFull2 } = useFlags();
  const { colors } = useEmotionTheme();
  const { t: tBookingScreen } = useTranslation('bookingScreen');
  const dueDateText = () => {
    if (totalDueText) {
      return totalDueText;
    }
    if (renewalDate) {
      return <Trans>Total due {moment(renewalDate).format('MMM DD')}</Trans>;
    }
    if (isNoMatches) {
      return tBookingScreen('cost.totalMatch', 'Total due on match', undefined);
    }
    return tBookingScreen('cost.totalToday', 'Total due today', undefined);
  };

  return (
    <>
      {!condensed && !hideLineItems && (
        <>
          <ExtraBig style={{ textAlign: 'start' }}>
            {tBookingScreen('cost.summary', 'Summary', undefined)}
          </ExtraBig>
          <LineItems items={lineItems} />
          <Divider />
        </>
      )}
      <Stick>
        {savings > 0 && showSavings && (
          <Row>
            <Large variant="largeBoldBrightGreen">
              {tBookingScreen('cost.yourSavings', 'Your savings', undefined)}
            </Large>
            <Large variant="largeBoldBrightGreen">
              {/* Total savings including: coupon, multi-month prepay discount */}
              {formatCurrency(-savings, lineItems[0].currency)}
            </Large>
          </Row>
        )}
        {total < 0 && (
          <Row>
            <Large variant="largeBoldBrightGreen">
              {tBookingScreen('cost.credit', 'Credit on your account', undefined)}
            </Large>
            <Large variant="largeBoldBrightGreen">
              {formatCurrency(Math.abs(total), lineItems[0].currency)}
            </Large>
          </Row>
        )}
        <Row>
          <ExtraBig>{dueDateText()}</ExtraBig>
          <ExtraBig>
            {maxCostPostSession
              ? `${formatCurrency(0, lineItems[0].currency)}-${formatCurrency(
                  maxCostPostSession,
                  lineItems[0].currency
                )}`
              : formatCurrency(Math.max(0, total), lineItems[0].currency)}
          </ExtraBig>
        </Row>
        {chargeTimeText && (
          <Row>
            <Large variant="largeDarkGrey">{chargeTimeText}</Large>
          </Row>
        )}
        {chargeInfoText && (
          <Row style={{ marginTop: 10, paddingTop: 0 }}>
            <Tiny variant="tinyDarkGray">{chargeInfoText}</Tiny>
          </Row>
        )}
        {onReviewCancellationPolicyClick &&
          (repeatingSessionsFull2 ? (
            <>
              <Row>
                <ExtraBig>
                  {tBookingScreen('cost.cancellation', 'Cancellation Policy', undefined)}
                </ExtraBig>
              </Row>
              <Row>
                <Tiny variant="tinyDarkGray">
                  <Trans>
                    Late cancellations or no-shows will result in a fee for the cost of service,{' '}
                    <b>which can be up to $175.</b>
                  </Trans>
                </Tiny>
              </Row>
            </>
          ) : (
            <View>
              <BaseButton
                style={{ paddingTop: 32, paddingBottom: 10 }}
                onPress={onReviewCancellationPolicyClick}
              >
                <Large variant="largeBoldWide" style={{ color: colors.greenText }}>
                  {tBookingScreen('available.review', 'Cancellation Policy', undefined)}
                </Large>
              </BaseButton>
            </View>
          ))}
      </Stick>
    </>
  );
};

export default Totals;
