import { ERoom } from 'ts-frontend/entities/Room';
import { CANCELLATION_WINDOW_HOURS } from '../../utils/constants';

// eslint-disable-next-line import/prefer-default-export
export const getLateCancellationText = (room: ERoom) => {
  if (room.isSessionBased) {
    return `Because this session is confirmed and scheduled to start in less than ${CANCELLATION_WINDOW_HOURS} hours, this credit won’t be refunded and you’ll be charged a late cancellation fee for the cost of the session if you reschedule or cancel now.`;
  }
  if (room.isEAP) {
    return '';
  }
  return `Because this session is confirmed and scheduled to start in less than ${CANCELLATION_WINDOW_HOURS} hours, this credit won't be refunded if you reschedule or cancel now.`;
};
