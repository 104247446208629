import { FunctionComponent } from 'react';
import { Text } from '@talkspace/react-toolkit';
import { EmotionStyle } from '@/core/styled/styled';
import { EUser } from '../../entities/User';

interface NameProps {
  user: EUser;
  style: EmotionStyle;
}

const Name: FunctionComponent<NameProps> = ({ user, ...otherProps }) => (
  <Text {...otherProps}>{user.displayName} </Text>
);

export default Name;
