import { Link, RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router';

import { View, ArrowRight, useEmotionTheme } from '@talkspace/react-toolkit';

import './AccordionMenuItem.css';
import { EmotionStyle } from '@/core/styled';

interface Props extends RouteComponentProps {
  children?: React.ReactNode;
  style?: EmotionStyle;
  view?: string | null;
  path?: string | null;
  title?: string;
  arrowColor?: string;
  arrowWidth?: number;
  arrowHeight?: number;
  hidden?: boolean;
  clickHandler?: (() => void) | null;
  titleElement?: JSX.Element;
  dataQa?: string;
}

function AccordionMenuItem({
  children,
  style,
  match,
  title,
  arrowColor,
  arrowWidth,
  arrowHeight,
  path = null,
  view = null,
  hidden = false,
  clickHandler = null,
  titleElement,
  dataQa,
}: Props) {
  const hiddenStyle = hidden ? { display: 'none' } : {};
  const { colors } = useEmotionTheme();
  const color = arrowColor || colors.permaSilverSand;
  if (clickHandler) {
    return (
      <View
        className="accordion-menu-item accordion-link"
        style={{ ...hiddenStyle, ...style }}
        onClick={clickHandler}
        data-qa={dataQa}
      >
        <View row justify="space-between" style={{ marginBottom: children ? 8 : undefined }}>
          {titleElement || title}
          <ArrowRight color={color} width={arrowWidth} height={arrowHeight} />
        </View>
        {children}
      </View>
    );
  }
  const extractRoomURL = (url: string) => url.split(`/${path}`)[0];
  return (
    <Link
      to={`${extractRoomURL(match.url)}/${view}`}
      className="accordion-menu-item accordion-link"
      style={{ ...hiddenStyle, ...style }}
      data-qa={dataQa}
    >
      {titleElement || title}
      <ArrowRight color={color} width={arrowWidth} height={arrowHeight} />
    </Link>
  );
}

export default withRouter(AccordionMenuItem);
