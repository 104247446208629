import { ConditionalResponse } from 'ts-frontend/types';
import type { PromiseMessageTypes } from '@/utils/promiseMessage/promiseMessageEvents';
import { PromiseMessageTypeNames } from './promiseMessageEventTypes';

export const PROMISE_MESSAGE_TYPE = 'promiseMessage';

export interface PromiseMessageBodyDefinition {
  /** This is the payload included in the PromiseMessage.
   *
   * This can and should be set as undefined by the receiver,
   * unless the event is meant to be used in both dir */
  postData: unknown;
  /** This is the payload expected when subscribing to the event.
   *
   * This can and should be set as undefined by the sender,
   * unless the event is meant to be used in both dir */
  responseData: unknown;
}
/** Empty payload, works as some sort of notification for the receiver to react */
export interface PromiseMessageSignalDefinition extends PromiseMessageBodyDefinition {
  postData: undefined;
  responseData: undefined;
}
export interface PromiseMessageSenderDefinition<T = unknown> extends PromiseMessageBodyDefinition {
  postData: T;
  responseData: undefined;
}
export interface PromiseMessageReceiverDefinition<T = unknown>
  extends PromiseMessageBodyDefinition {
  postData: undefined;
  responseData: T;
}

export interface PromiseMessageTwoWayDefinition<TSend = unknown, TReceive = unknown>
  extends PromiseMessageBodyDefinition {
  postData: TSend;
  responseData: TReceive;
}

export type PostMessageFuncType<T> = (type: T, data: unknown) => void;

export type PostPromiseMessage = <K extends PromiseMessageTypeNames, B extends boolean>(
  name: K,
  data: PromiseMessageTypes[K]['postData'],
  createPromise?: B
) => Promise<ConditionalResponse<B, PromiseMessageTypes[K]['responseData'], undefined>>;

export type PromiseMessageCallback<K extends PromiseMessageTypeNames = PromiseMessageTypeNames> = (
  responseData: PromiseMessageTypes[K]['responseData']
) => void;

export type PromiseMessageHandler = <
  T extends typeof PROMISE_MESSAGE_TYPE,
  K extends PromiseMessageTypeNames
>(
  event: PromiseMessageEvent<T, K>,
  callback: PromiseMessageCallback<K>
) => Promise<boolean>;

export type PromiseMessageDataHandler<K extends PromiseMessageTypeNames> = (
  data: PromiseMessageTypes[K]['postData'],
  callback: PromiseMessageCallback<K>
) => void;

export interface PromiseMessageEvent<
  T extends typeof PROMISE_MESSAGE_TYPE = typeof PROMISE_MESSAGE_TYPE,
  K extends PromiseMessageTypeNames = PromiseMessageTypeNames
> extends Pick<MessageEvent, 'origin' | 'data'> {
  data: {
    type: T;
    data: {
      ID: string;
      innerData: PromiseMessageTypes[K]['postData'] | PromiseMessageTypes[K]['responseData'];
      innerType: K;
      initiatorOrigin: string;
    };
  };
}
