import { useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';

import PersonalDetailsScreen from './screens/PersonalDetailsScreen';
import ClinicalInfoScreen from './screens/ClinicalInfoScreen';
import CredentialsScreen from './screens/CredentialsScreen';

const Routes = () => {
  const history = useHistory();

  useEffect(() => {
    const { pathname, hash } = history.location;
    if (pathname === '/signup' && hash.startsWith('#token=')) {
      history.push(`/signup/personal-details${hash}`);
    }
  }, [history]);

  return (
    <Switch>
      <Route exact path="/signup/personal-details" component={PersonalDetailsScreen} />
      <Route exact path="/signup/clinical-info" component={ClinicalInfoScreen} />
      <Route exact path="/signup/credentials" component={CredentialsScreen} />
    </Switch>
  );
};

export default Routes;
