import { useEffect } from 'react';
import { isIonic } from '../modules/auth/reactFrame/helpers';

const useIonicEffect: typeof useEffect = (effect, deps) => {
  useEffect(() => {
    if (isIonic()) return effect();
    return undefined;
    // These are external dependencies that we cannot statically type
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export default useIonicEffect;
