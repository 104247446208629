import {
  AnimatedSwipeDownModal,
  Spinner,
  TouchableView,
  View,
  useWindowWidth,
} from '@talkspace/react-toolkit';
import ClientInformationIcon from 'components/Icons/ClientInformationIcon';
import PresentingProblemsIcon from 'components/Icons/PresentingProblemsIcon';
import TreatmentIntakeIcon from 'components/Icons/TreatmentIntakeIcon';
import useMutationClaimRoom from 'hooks/growCaseload/useMutationClaimRoom';
import { useCallback } from 'react';
import { states, countries } from '@talkspace/configs';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { ClaimableRoom, SuccessfulClaimClientInfo } from '../types';
import styled from '../../../../core/styled';
import { getUserData } from '../../../../utils/token';
import ModalContainer from './ModalContainer';
import { clientInformationServiceFormatting } from '../utils';
import Analytics from '../Analytics';
import PreferredTimes from '../PreferredTimes';
import PreferredTimesIcon from '../../../Icons/PreferredTimesIcon';

const Button = styled(TouchableView)(({ theme: { colors } }) => {
  return {
    color: colors.accessibilityGreenDark,
    fontWeight: 700,
    fontSize: 15,
  };
});

const Title = styled(View)(({ theme: { colors } }) => {
  return {
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: 21,
    lineHeight: '28px',
    textAlign: 'center',
    marginTop: 10,
    color: colors.TSBlack,
  };
});

const Subtitle = styled(View)(({ theme: { colors } }) => {
  return {
    fontWeight: 400,
    fontSize: 14,
    textAlign: 'center',
    color: colors.permaRiverBed,
    fontFamily: 'Roboto',
    marginTop: 15,
    paddingRight: 36,
    paddingLeft: 36,
  };
});

const InformationSection = styled(View)({
  display: 'flex',
  justifyContent: 'left',
  flexDirection: 'row',
  lineHeight: '19px',
  marginTop: 12,
});

const AddToCaseloadButton = styled(Button)(
  ({
    theme: {
      colors,
      window: { isMobile },
    },
  }) => {
    return {
      marginTop: 40,
      color: 'white',
      backgroundColor: colors.permaBlueStoneNew,
      width: isMobile ? 295 : 335,
      borderRadius: 10,
      height: 50,
      textAlign: 'center',
      lineHeight: '50px',
      letterSpacing: '0.5px',
      fontWeight: 700,
      fontSize: 17,
    };
  }
);

const CloseText = styled(Button)(({ theme: { colors } }) => {
  return {
    marginTop: 25,
    fontSize: 17,
    backgroundColor: colors.white,
    color: colors.permaBlueStoneNew,
    letterSpacing: '0.5px',
    fontWeight: 700,
    lineHeight: '20px',
    paddingBottom: 50,
  };
});

const IconWrapper = styled(View)({ borderRadius: 10, minWidth: 20, marginRight: 14 });

const PresentingProblemsWrapper = styled(View)({
  maxWidth: '80%',
  flexDirection: 'row',
  flexWrap: 'wrap',
});

const CloseButtonWrapper = styled(View)({ width: '100%', alignItems: 'center' });

const ClientInformationModalContentWrapper = styled(View)(
  ({
    theme: {
      window: { isDesktop },
    },
  }) => {
    return {
      overflow: 'scroll',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      maxHeight: !isDesktop ? '70vh' : '85vh',
    };
  }
);

interface ClientInformationModalProps {
  confirmationFailure: (status: number) => void;
  confirmationSuccess: ({ name, roomID }: SuccessfulClaimClientInfo) => void;
  onBackdropPress: () => void;
  hide: boolean;
  clientInformation: ClaimableRoom;
  showClientAvailabilityPreferences: boolean;
  onContinue: () => void;
}

const ClientInformationModalContent = ({
  showClientAvailabilityPreferences,
  confirmationFailure,
  confirmationSuccess,
  onContinue,
  onBackdropPress,
  clientInformation,
}: Omit<ClientInformationModalProps, 'hide'>) => {
  const {
    clientAvailabilityPreferencesTherapist: { bookings: bookingsClientAvailabilityPreferences } = {
      bookings: false,
    },
  } = useFlags();
  const { mutate: claimRoom, isLoading: isClaimRoomLoading } = useMutationClaimRoom();
  const { isMobile } = useWindowWidth();
  const therapistID = getUserData().id;

  const claimRoomCallback = useCallback(() => {
    Analytics.trackModalButtonPressedEvent({
      modal: 'Client information modal',
      button: 'Add to caseload button',
      roomID: clientInformation.roomID,
      therapistID,
    });
    claimRoom(
      {
        therapistID,
        roomID: clientInformation.roomID,
      },
      {
        onSuccess: (name) => {
          onBackdropPress();
          confirmationSuccess({ name, roomID: clientInformation.roomID });
        },
        onError: (error: any) => {
          onBackdropPress();
          confirmationFailure(error.status);
        },
      }
    );
  }, [
    claimRoom,
    clientInformation?.roomID,
    confirmationFailure,
    confirmationSuccess,
    onBackdropPress,
    therapistID,
  ]);

  const showContinueButton =
    showClientAvailabilityPreferences &&
    bookingsClientAvailabilityPreferences &&
    clientInformation?.preference !== 'Messaging' &&
    clientInformation?.availability?.length > 0;

  return (
    <ClientInformationModalContentWrapper>
      <Title>Client information</Title>
      <Subtitle>
        {showContinueButton
          ? `If this client is a good match, continue to schedule the first
          session to add them to your caseload`
          : `If this client is a good match, add them to your
          caseload and introduce yourself`}
      </Subtitle>

      <View style={{ marginLeft: isMobile ? 30 : 58 }}>
        <InformationSection
          style={{
            marginTop: 35,
          }}
        >
          <IconWrapper>
            <PresentingProblemsIcon />
          </IconWrapper>
          <PresentingProblemsWrapper>
            <View style={{ paddingRight: 3 }}>Presenting problem: </View>
            {clientInformation?.presentingProblem.map((it, i, arr) => (
              <View style={{ paddingRight: 3 }}>{`${it}${i === arr.length - 1 ? '' : ','}`}</View>
            ))}
          </PresentingProblemsWrapper>
        </InformationSection>
        <InformationSection>
          <IconWrapper>
            <ClientInformationIcon />
          </IconWrapper>
          <View>
            <View>
              Age & Gender: {clientInformation?.clientAge}, {clientInformation?.clientGender}
            </View>
            {clientInformation?.state ? (
              <View style={{ marginTop: 12 }}>State: {states[clientInformation?.state]}</View>
            ) : (
              <>
                {clientInformation?.country && (
                  <View style={{ marginTop: 12 }}>
                    Country: {countries[clientInformation.country]}
                  </View>
                )}
              </>
            )}

            <View style={{ marginTop: 12 }}>
              Service:{' '}
              {clientInformationServiceFormatting(
                clientInformation?.roomType,
                clientInformation?.clientAge
              )}
            </View>
            <View style={{ marginTop: 12 }}>Sessions: {clientInformation?.numberOfSessions}</View>
            <View style={{ marginTop: 12 }}>Preferences: {clientInformation?.preference}</View>
            {showClientAvailabilityPreferences && (
              <View style={{ marginTop: 12 }}>
                Plan type: {clientInformation?.accountType?.toUpperCase() || ''}
              </View>
            )}
          </View>
        </InformationSection>

        <InformationSection>
          <IconWrapper>
            <TreatmentIntakeIcon />
          </IconWrapper>
          <View style={{ maxWidth: '80%' }}>
            Treatment intake:{' '}
            {clientInformation?.treatmentIntake ? 'Completed' : 'Will be completed after match'}
          </View>
        </InformationSection>

        {showClientAvailabilityPreferences &&
          clientInformation?.preference !== 'Messaging' &&
          clientInformation?.availability?.length > 0 && (
            <InformationSection>
              <IconWrapper>
                <PreferredTimesIcon />
              </IconWrapper>
              <View style={{ marginRight: 4 }}>Preferred times:</View>
              <View>
                <PreferredTimes availability={clientInformation?.availability} />
              </View>
            </InformationSection>
          )}
      </View>
      <CloseButtonWrapper>
        {showContinueButton ? (
          <AddToCaseloadButton
            data-qa="growCaseloadClientInformationModalContinue"
            onPress={onContinue}
          >
            Continue
          </AddToCaseloadButton>
        ) : (
          <AddToCaseloadButton
            data-qa="growCaseloadClientInformationModalAddToCaseload"
            disabled={isClaimRoomLoading}
            onPress={claimRoomCallback}
          >
            {isClaimRoomLoading ? <Spinner /> : 'Add to caseload'}
          </AddToCaseloadButton>
        )}

        <CloseText data-qa="growCaseloadClientInformationModalCancel" onPress={onBackdropPress}>
          Cancel
        </CloseText>
      </CloseButtonWrapper>
    </ClientInformationModalContentWrapper>
  );
};

const ClientInformationModal = (props: ClientInformationModalProps) => {
  const { hide, onBackdropPress } = props;
  const { isMobile } = useWindowWidth();

  return !isMobile ? (
    <ModalContainer hide={hide} onBackdropPress={onBackdropPress}>
      <ClientInformationModalContent {...props} />
    </ModalContainer>
  ) : (
    <AnimatedSwipeDownModal
      containerStyles={{
        paddingLeft: 0,
        paddingRight: 0,
      }}
      isDraggable={false}
      shouldDisplayCloseButton
      isOpen={!hide}
      onClose={onBackdropPress}
    >
      <ClientInformationModalContent {...props} />
    </AnimatedSwipeDownModal>
  );
};

export default ClientInformationModal;
