import Svg, { Path, G } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

const FileError = ({ width = 29, height = 37, ...otherProps }) => {
  const { colors } = useEmotionTheme();
  const titleText = 'file error';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox={`0 0 ${width} ${height}`}
      {...otherProps}
    >
      <G fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <Path
          fill="#FCFCFD"
          stroke={colors.heatherGrey}
          d="M.5 20.004v28.992C.5 59.772 9.228 68.5 19.999 68.5H255c10.769 0 19.499-8.732 19.499-19.504V20.004C274.5 9.228 265.772.5 255.001.5H20C9.23.5.5 9.232.5 20.004z"
          transform="translate(-883 -515) translate(867 499) matrix(-1 0 0 1 275 0)"
        />
        <Path
          stroke={colors.heatherGrey}
          strokeWidth="3"
          d="M6.5 1.5A4.5 4.5 0 002 6v25a4.5 4.5 0 004.5 4.5h17A4.5 4.5 0 0028 31V12.485a4.5 4.5 0 00-1.318-3.182l-6.485-6.485A4.5 4.5 0 0017.015 1.5H6.5z"
          transform="translate(-883 -515) translate(882.5 515)"
        />
        <Path
          fill={colors.heatherGrey}
          d="M16.5 18.5h3a1.5 1.5 0 010 3h-3v3a1.5 1.5 0 01-3 0v-3h-3a1.5 1.5 0 010-3h3v-3a1.5 1.5 0 013 0v3z"
          transform="translate(-883 -515) translate(882.5 515) rotate(45 15 20)"
        />
      </G>
    </Svg>
  );
};

export default FileError;
