import { LiveSessionModality } from 'ts-frontend/types';
import {
  View,
  EnvelopeIcon,
  ChatModalityV2,
  VideoModalityV2,
  AudioModalityV2,
  useWindowWidth,
} from '@talkspace/react-toolkit';
import styled from '../../../../core/styled';

interface ModalityCellProps {
  sessionModality: 'live' | 'messaging';
  liveSessionType?: LiveSessionModality;
}

const Container = styled(View)(({ theme: { colors } }) => {
  return {
    fontSize: 14,
    fontWeight: 400,
    color: colors.purple600,
  };
});

const Wrapper = styled(View)(
  ({
    theme: {
      window: { isDesktop },
    },
  }) => {
    return {
      height: isDesktop && 30,
      display: 'flex',
      justifyContent: 'center',
    };
  }
);

export const getIcon = ({ sessionModality, liveSessionType }: ModalityCellProps) => {
  if (sessionModality === 'messaging') {
    return <EnvelopeIcon />;
  }
  if (liveSessionType === 'chat') {
    return <ChatModalityV2 />;
  }
  if (liveSessionType === 'audio') {
    return <AudioModalityV2 />;
  }
  return <VideoModalityV2 />;
};

const ModalityCell = ({ sessionModality, liveSessionType }: ModalityCellProps) => {
  const { isDesktop } = useWindowWidth();

  return (
    <Wrapper>
      <Container row align="center">
        {isDesktop && (
          <View style={{ marginRight: 10 }}>{getIcon({ sessionModality, liveSessionType })}</View>
        )}
        {sessionModality === 'live' ? <View>Live {liveSessionType}</View> : <View>Messaging</View>}
      </Container>
    </Wrapper>
  );
};

export default ModalityCell;
