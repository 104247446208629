import { useEmotionTheme } from '../../../index.next';
import TimeslotsCalendar from './TimeslotsCalendar';

const TimeslotsCalendarV1 = (props) => {
  const { colors, colorRoles } = useEmotionTheme();

  return (
    <TimeslotsCalendar
      {...props}
      primaryColor={colorRoles.system.interactivePrimaryDefault}
      secondaryColor={colors.aquaSqueeze}
    />
  );
};

export default TimeslotsCalendarV1;
