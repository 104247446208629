/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FunctionComponent, useState } from 'react';
import 'react-phone-number-input/style.css';
import './PhoneInput.css';
import ReactPhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';

// TODO: remove this component once it has been replaced with A11yPhoneInput throughout the various apps

export const isPhonePhoneNumberEmpty = (value) =>
  typeof value !== 'string' || !isPossiblePhoneNumber(value);

const PhoneInput: FunctionComponent<{
  dataQa?: string;
  value: unknown;
  handleInputValueChange: (value: string | null) => void;
}> = ({ dataQa, value, handleInputValueChange }) => {
  const [country, setCountry] = useState('US');

  const onCountryChange = (newCountry) => {
    setCountry(newCountry);
  };

  return (
    <ReactPhoneInput
      data-qa={dataQa}
      aria-required="true"
      aria-label="phone number"
      country={country}
      defaultCountry="US"
      id="phone"
      // @ts-ignore
      value={(typeof value === 'string' || typeof value === 'undefined') && value}
      placeholder="Enter phone number"
      // @ts-ignore
      onChange={handleInputValueChange}
      onCountryChange={onCountryChange}
    />
  );
};

export default PhoneInput;
