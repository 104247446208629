import Svg, { Defs, G, Path } from 'svgs';
import { FunctionComponent } from 'react';

import { EmotionStyle } from '../../core/styled';

interface MessagingIconProps {
  color?: string;
  iconColor?: string;
  width?: number;
  height?: number;
  style?: EmotionStyle;
}

const MessagingIcon: FunctionComponent<MessagingIconProps> = ({
  color,
  iconColor,
  width = 70,
  height = 70,
  ...otherProps
}) => {
  const titleText = 'messaging envelop';
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 70 70"
      title={titleText}
      aria-label={titleText}
      {...otherProps}
    >
      <rect width="70" height="70" rx="35" fill={color || '#5C84FB'} />
      <G>
        <Path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M23.376 22C20.4069 22 18 24.4069 18 27.376V43.624C18 46.5931 20.4069 49 23.376 49H46.624C49.5931 49 52 46.5931 52 43.624V27.376C52 24.4069 49.5931 22 46.624 22H23.376ZM46.3675 28.4604C45.9505 28.0187 45.2543 27.9986 44.8125 28.4156L35.196 37.492L25.1714 28.4007L25.0714 28.32C24.6235 27.9996 23.9966 28.0587 23.6176 28.4765C23.2095 28.9265 23.2434 29.6222 23.6935 30.0303L35.2263 40.4899L46.3226 30.0154L46.4109 29.922C46.7651 29.5002 46.7547 28.8707 46.3675 28.4604Z"
          fill={iconColor || '#CFEAFF'}
        />
      </G>
      <Defs>
        <filter
          id="filter0_d_196_7356"
          x="5"
          y="12"
          width="60"
          height="53"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="6.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.194204 0 0 0 0 0.338143 0 0 0 0 0.766361 0 0 0 0.449738 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_196_7356" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_196_7356"
            result="shape"
          />
        </filter>
      </Defs>
    </Svg>
  );
};

export default MessagingIcon;
