import { useState, useEffect } from 'react';
import { GetProgressNoteHelperDataResponse } from 'hooks/notes/useQueryProgressNoteHelperData';
import { ProgressNoteQueryResponse } from 'hooks/notes/useQueryProgressNote';
import { VideoCall } from '../types';

interface useNotesRelevantVideoCallsProps {
  formMode: 'create' | 'edit' | 'view';
  helperData?: GetProgressNoteHelperDataResponse;
  progressNoteData?: ProgressNoteQueryResponse;
}

export default function useNotesRelevantVideoCalls({
  formMode,
  progressNoteData,
  helperData,
}: useNotesRelevantVideoCallsProps) {
  const [notesRelevantVideoCalls, setNotesRelevantVideoCalls] = useState<
    (VideoCall | undefined)[] | null
  >(null);

  const handleSetNotesRelevantVideoCalls = (videoCalls: Array<VideoCall>) =>
    setNotesRelevantVideoCalls(videoCalls);

  useEffect(() => {
    if (helperData) {
      let associatedVideoCalls: VideoCall[] = [];
      if (progressNoteData) {
        associatedVideoCalls = progressNoteData?.videoCalls
          .filter((el) => el.liveCall !== null)
          .map((vc) => vc.liveCall) as VideoCall[];
      }
      const dropDownOptions = [...helperData.unassociatedVideoCalls, ...associatedVideoCalls];
      setNotesRelevantVideoCalls(dropDownOptions);
    }
  }, [helperData, formMode, progressNoteData]);

  const disableLVSComponents = notesRelevantVideoCalls && notesRelevantVideoCalls.length <= 0;

  return {
    notesRelevantVideoCalls,
    disableLVSComponents,
    handleSetNotesRelevantVideoCalls,
  };
}
