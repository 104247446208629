// Media has a dependency with Dialog
import {
  MediaCapture,
  CaptureImageOptions,
  CaptureVideoOptions,
} from '@awesome-cordova-plugins/media-capture';
import { Camera } from '@awesome-cordova-plugins/camera';
import { sleep } from 'ts-frontend/helpers';
import { safeIonicWrapper } from '../../ionicUtils';
import { mediaLogger } from '../../loggers';
import { convertFileSrc } from '../capacitor';
import { dialogAlert } from '../dialog';

const VIDEO_MESSAGE_MIN_DURATION = 1; // 1 second
const VIDEO_MESSAGE_MAX_DURATION = 6 * 60; // 6 minutes

export type MediaType<TMediaType extends 'video' | 'photo' = 'photo'> = {
  path: string;
  blob: Blob;
  fileType: string;
  mediaType: TMediaType;
};

const photoExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'svg'];
const videoExtensions = ['mp4', 'mov', 'avi', 'mkv', 'flv', 'wmv', 'webm'];

const getFileTypeFromExtension = (extension: string): 'photo' | 'video' | 'unknown' => {
  if (photoExtensions.includes(extension.toLowerCase())) {
    return 'photo';
  }
  if (videoExtensions.includes(extension.toLowerCase())) {
    return 'video';
  }
  return 'unknown';
};

const getVideoDuration = (src: Blob | string): Promise<number | null> =>
  new Promise((resolve) => {
    const video = document.createElement('video');
    video.preload = 'metadata';
    video.onloadedmetadata = () => {
      if (typeof src !== 'string') URL.revokeObjectURL(video.src);
      resolve(video.duration);
    };
    video.onerror = (event, source, lineno, colno, error) => {
      mediaLogger.error('#pickPhoto - Error loading video metadata', video.error, {
        event,
        source,
        lineno,
        colno,
        error,
      });
      resolve(null);
    };
    video.src = typeof src === 'string' ? src : URL.createObjectURL(src);
  });

const isValidVideoDuration = (duration: number | null): boolean => {
  if (duration) {
    const isLongVideo = duration > VIDEO_MESSAGE_MAX_DURATION;
    if (duration < VIDEO_MESSAGE_MIN_DURATION || isLongVideo) {
      const errorMessage = isLongVideo
        ? `The video maximum duration is ${VIDEO_MESSAGE_MAX_DURATION / 60} minutes.`
        : 'The video is too short.';
      mediaLogger.warn(
        '#pickPhoto - Video duration is invalid',
        `Duration: ${duration}, isLongVideo: ${isLongVideo}`
      );
      dialogAlert({
        message: errorMessage,
      });
      return false;
    }
    return true;
  }
  mediaLogger.debug('#pickPhoto - Video duration is null');
  return false;
};

export const captureImage = safeIonicWrapper(async (options?: Partial<CaptureImageOptions>) => {
  const mediaFiles = await MediaCapture.captureImage({
    limit: 1,
    ...options,
  });
  if (Array.isArray(mediaFiles) && mediaFiles.length) {
    const { fullPath, name, type, lastModifiedDate } = mediaFiles[0];
    const src = convertFileSrc(fullPath);
    mediaLogger.debug('#captureImage - Data', { src, fullPath });
    const blob = await fetch(src).then((data) => data.blob());
    mediaLogger.debug('#captureImage - Converted to blob ', blob);
    return {
      blob,
      size: blob.size,
      name,
      localSrc: URL.createObjectURL(blob),
      type: 'photo',
      fileType: type,
      lastModified: lastModifiedDate,
    };
  }
  mediaLogger.warn(
    'Error in #captureImage',
    'code' in mediaFiles ? mediaFiles.code : 'Empty array'
  );
  return null;
}, Promise.resolve(null));

export const captureVideo = safeIonicWrapper(async (options?: Partial<CaptureVideoOptions>) => {
  const mediaFiles = await MediaCapture.captureVideo({
    limit: 1,
    ...options,
  }).catch((err) => {
    mediaLogger.error('#captureVideo - Error capturing video', err);
    return [];
  });
  if (Array.isArray(mediaFiles) && mediaFiles.length) {
    const { fullPath, name, type, lastModifiedDate } = mediaFiles[0];

    const durationPromise = await new Promise<number | null>((resolve) => {
      mediaFiles[0].getFormatData(
        ({ duration }) => resolve(duration),
        (err) => {
          mediaLogger.error('#captureVideo - Error fetching video data', err);
          resolve(null);
        }
      );
    });
    const timeoutPromise = sleep(1000, null);
    const duration = await Promise.race([durationPromise, timeoutPromise]);
    if (!isValidVideoDuration(duration)) {
      return null;
    }

    const src = convertFileSrc(fullPath);
    mediaLogger.debug('#captureVideo - Data', { src, fullPath, duration });
    const blob = await fetch(src).then((data) => data.blob());
    mediaLogger.debug('#captureVideo - Converted to blob', blob);
    return {
      blob,
      size: blob.size,
      duration,
      name,
      localSrc: src,
      type: 'video',
      fileType: type,
      lastModified: lastModifiedDate,
    };
  }
  mediaLogger.warn(
    'Error in #captureVideo',
    mediaFiles && 'code' in mediaFiles ? mediaFiles.code : 'Empty array'
  );
  return null;
}, Promise.resolve(null));

export const pickPhoto = safeIonicWrapper(async () => {
  try {
    const photoPath = (await Camera.getPicture({
      sourceType: Camera.PictureSourceType.PHOTOLIBRARY,
      mediaType: Camera.MediaType.ALLMEDIA,
      destinationType: Camera.DestinationType.FILE_URI,
    })) as string | null;

    if (photoPath) {
      const sections = photoPath.split('/');
      const name = sections[sections.length - 1];
      const fileType = name.split('.')[1] || '';
      const type = getFileTypeFromExtension(fileType);
      const src = convertFileSrc(photoPath);
      const duration = type === 'video' ? await getVideoDuration(src) : null;
      if (type === 'video' && !isValidVideoDuration(duration)) {
        return null;
      }
      mediaLogger.debug('#pickPhoto - Data', { src, photoPath, fileType, type, duration });
      const blob = await fetch(src).then((data) => data.blob());
      mediaLogger.debug('#pickPhoto - Converted to blob', blob);
      return {
        blob,
        size: blob.size,
        name,
        localSrc: type === 'video' ? src : URL.createObjectURL(blob),
        type,
        fileType,
        duration,
      };
    }
    throw new Error('No photo path');
  } catch (err) {
    mediaLogger.error('Error in #pickPhoto', err);
    return null;
  }
}, Promise.resolve(null));

export { mediaLogger };
