import { useMutation, useQueryClient } from 'react-query';
import apiHelper from '../../utils/api';
import apiWrapper from '../../utils/apiWrapper';
import { ProgressNoteAction, taskActionEnum } from '../../components/Dashboard/types';
import dashboardKeys from './queryKeys';

export interface UpdateTaskMutateVariables {
  action: ProgressNoteAction;
  snoozeReason?: string;
  providerSnoozeDescription?: string;
  therapistID: number;
  taskID: number;
  exceptionReason?: string;
}

const updateTask = async (params: UpdateTaskMutateVariables): Promise<void> => {
  const { action, snoozeReason, providerSnoozeDescription, therapistID, taskID, exceptionReason } =
    params;

  const conditionalSnoozePayload = action === taskActionEnum.SNOOZE && {
    ...(snoozeReason && { snoozeReason }),
    ...(providerSnoozeDescription && { providerSnoozeDescription }),
  };

  const data = await apiWrapper.patch(
    `${apiHelper().apiEndpoint}/v2/therapists/${therapistID}/tasks/${taskID}`,
    {
      action,
      ...conditionalSnoozePayload,
      ...(exceptionReason && { exceptionReason }),
    }
  );
  return data.data;
};

const useMutationUpdateTask = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, UpdateTaskMutateVariables>(updateTask, {
    onSuccess: (_, { therapistID }) => {
      queryClient.invalidateQueries(dashboardKeys.tasklist(therapistID));
    },
  });
};

export default useMutationUpdateTask;
