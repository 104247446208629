import * as React from 'react';

import { Small, Standard, Tooltip, useEmotionTheme, View } from '@talkspace/react-toolkit';

interface Props {
  description?: string | null;
  accessibilityLabel?: string;
  titleColor?: string;
  title?: string;
  place?: 'right' | 'left' | 'top' | 'bottom';
}
const NoteFormTooltip: React.FC<Props> = ({
  titleColor,
  description,
  children,
  accessibilityLabel = 'Cannot submit form',
  title,
  place = 'top',
}) => {
  const { colors } = useEmotionTheme();
  if (!description) {
    return <>{children}</>;
  }
  return (
    <Tooltip
      arrowColor={colors.white}
      messageStyle={{
        padding: 20,
        backgroundColor: `${colors.white} !important`,
        boxShadow: `${colors.gray} 0 4px 10px -4px`,
        opacity: '1 !important',
        left: '90px !important',
      }}
      messageComponent={
        <View>
          {title && titleColor && <Standard style={{ color: titleColor }}>{title}</Standard>}
          <Small style={{ marginTop: 6 }}>{description}</Small>
        </View>
      }
      label={accessibilityLabel}
      place={place}
      effect="solid"
      arrowPosition="right"
    >
      {children}
    </Tooltip>
  );
};

export default NoteFormTooltip;
