import { LiveSessionModality } from 'ts-frontend/types';
import { EMessage } from '../../entities/EMessage';
import { StarredMessage } from '../../entities/StarredMessage';
import { RoomInfo } from '../../entities/RoomInfo';
import { EActiveSession } from '../../entities/ActiveSession';

export interface ClientReactivationAPIResponse {
  shouldReactivate: boolean; // Show banner or not
  reactivationURL: string;
  sendToRoom: number;
}

export interface ClientAwaitingSessionAPIResponse {
  data: {
    id: number;
  };
}

export interface LastCompletedSessionAPIResponse {
  data: {
    id: number;
    completedAt: string;
  };
}

export interface ClientAwaitingSessionResponse {
  sessionID: number;
  lastCompletedSessionDate?: string;
}

export type ChatBannerType =
  | 'reactivation'
  | 'emailVerification'
  | 'enable2fa'
  | 'awaitingSession'
  | 'psychiatristReminder'
  | 'creditCardDeclined'
  | 'partnerMissing'
  | 'introLVSReminder'
  | 'sessionStatus'
  | 'eligiblityWidget'
  | 'pendingInvoices'
  | 'matchWithNewProvider'
  | 'matchWithSameProvider'
  | 'generalNotice';

interface PreviousMessagingSession {
  endDate: string;
}

interface CurrentMessagingSession {
  startDate: string;
}

interface PreviousLiveSession {
  endDate: string;
}
interface NextLiveSession {
  date: Date;
  duration: number;
  modality: LiveSessionModality;
}

export interface SessionStatus {
  previousMessagingSession: PreviousMessagingSession | null;
  currentMessagingSession: CurrentMessagingSession | null;
  previousLiveSession: PreviousLiveSession | null;
  nextLiveSession: NextLiveSession | null;
}

export interface UserTyping {
  show: boolean;
  displayName?: string;
}

export interface MessageAcks {
  maxAckReceivedId: number;
  maxAckReadId: number;
}
export interface ChatState {
  roomID: number;
  isTherapistChat?: boolean;
  lastMessageID: number;
  messages: EMessage[];
  isFirstGetMessagesCall: boolean;
  starredMessages: StarredMessage[];
  starredIsUpdating: boolean;
  starredInitialLoadComplete: boolean;
  starredIsError: boolean;
  starredHasReceivedAll: boolean;
  isUpdating: boolean;
  isError: boolean;
  hasReceivedAllMessages: boolean;
  isLoadingHistorical: boolean;
  wasLoadingHistorical: boolean;
  roomInfo: RoomInfo;
  acks: MessageAcks;
  userTyping: UserTyping;
  activeSession: EActiveSession | null;
  sharedFiles: EMessage[];
  receivedAllSharedFiles: boolean;
  isLoadingSharedFiles: boolean;
  userAlreadyJoinedSession?: boolean;
  sessionAlreadyEnded: boolean;
  postLiveSessionIsError: boolean;
  isLoadingActiveSessionInfo: boolean;
  sessionStatus?: SessionStatus;
  sharedBanners: ChatBannerType[];
  primaryClientID?: number;
}

interface MatchResultTherapistInfo {
  // eslint-disable-next-line camelcase
  public_details?: string;
  // eslint-disable-next-line camelcase
  psychologytoday_profile_id?: string;
  // eslint-disable-next-line camelcase
  first_clinical_license_date?: string;
  // eslint-disable-next-line camelcase
  first_name: string;
  // eslint-disable-next-line camelcase
  last_name: string;
  // eslint-disable-next-line camelcase
  created_at: string;
  businessHours: {};
  joinedYearsAgo: number;
  joinedMonthsAgo: number;
  psychologyTodayLink?: string;
  yearsInPractice: number;
  monthsInPractice: number;
  paidUsers: number;
}

export interface MatchExpertise {
  focus: string[];
  treatmentApproach: string[];
}

export interface MatchLicense {
  typeDescription: string;
}

export interface MatchResult {
  therapistId: number;
  imageURL: string;
  info: MatchResultTherapistInfo;
  videoURL?: string;
  licenses: MatchLicense[];
  expertise: MatchExpertise;
  availability?: {};
  bestMatch: boolean;
}

export interface WorkflowActionResult {
  roomID: number;
  messageID: number;
  messageType: number;
  jwtToken: string;
}

export interface GetMessagePayload {
  messages: EMessage[];
  lastMessageID: number;
}

export interface GetRoomInfoPayload {
  roomInfo: RoomInfo;
}

export interface GetActiveSessionInfoPayload {
  activeSession: EActiveSession;
}

export interface ChatStore {
  chat: ChatState;
}

export type TherapistType = 'primary' | 'psychiatrist';

export const REQUEST_GET_MESSAGES = 'REQUEST_MESSAGES';
export const REQUEST_GET_MESSAGES_ERROR = 'REQUEST_MESSAGES_ERROR';
export const RECEIVE_GET_MESSAGES = 'RECEIVE_MESSAGES';

export const RECEIVE_ACKNOWLEDGEMENTS = 'RECEIVE_ACKNOWLEDGEMENTS';
export const RECEIVE_USER_TYPING = 'RECEIVE_USER_TYPING';

export const REQUEST_POST_MESSAGE = 'REQUEST_POST_MESSAGE';
export const REQUEST_POST_MESSAGE_ERROR = 'REQUEST_POST_MESSAGE_ERROR';
export const RECEIVE_POST_MESSAGE = 'RECEIVE_POST_MESSAGE';

export const REQUEST_GET_ROOM_INFO = 'REQUEST_ROOM_INFO';
export const REQUEST_GET_ROOM_INFO_ERROR = 'REQUEST_ROOM_INFO_ERROR';
export const RECEIVE_GET_ROOM_INFO = 'RECEIVE_ROOM_INFO';

export const REQUEST_ACTIVE_SESSION_INFO = 'REQUEST_ACTIVE_SESSION_INFO';
export const REQUEST_ACTIVE_SESSION_INFO_ERROR = 'REQUEST_ACTIVE_SESSION_INFO';
export const RECEIVE_ACTIVE_SESSION_INFO = 'RECEIVE_ACTIVE_SESSION_INFO';

export const REQUEST_GET_STARRED_MESSAGES = 'REQUEST_GET_STARRED_MESSAGES';
export const REQUEST_GET_STARRED_MESSAGES_ERROR = 'REQUEST_GET_STARRED_MESSAGES_BY_USER_ERROR';
export const RECEIVE_GET_STARRED_MESSAGES = 'RECEIVE_GET_STARRED_MESSAGES';

export const REQUEST_EDIT_STARRED_MESSAGE = 'REQUEST_EDIT_STARRED_MESSAGE';
export const REQUEST_EDIT_STARRED_MESSAGE_ERROR = 'REQUEST_EDIT_STARRED_MESSAGE_ERROR';

export const REQUEST_GET_SHARED_FILES = 'REQUEST_GET_SHARED_FILES';
export const RECEIVE_GET_SHARED_FILES = 'RECEIVE_GET_SHARED_FILES';
export const ERROR_GET_SHARED_FILES = 'ERROR_GET_SHARED_FILES';
export const REQUEST_GET_HISTORICAL_SHARED_FILES = 'REQUEST_GET_HISTORICAL_SHARED_FILES';
export const CLEAR_SHARED_FILES = 'CLEAR_SHARED_FILES';
export const REQUEST_TOGGLE_STAR_MESSAGE = 'REQUEST_TOGGLE_STAR_MESSAGE';
export const ERROR_TOGGLE_STAR_MESSAGE = 'ERROR_TOGGLE_STAR_MESSAGE';
export const RESET_CHAT = 'RESET_CHAT';
export const POST_LIVE_SESSION_EVENT = 'POST_LIVE_SESSION_EVENT';
export const RECEIVE_POST_LIVE_SESSION_EVENT_SUCCESS = 'RECEIVE_POST_LIVE_SESSION_EVENT_SUCCESS';
export const RECEIVE_POST_LIVE_SESSION_EVENT_ALREADY_JOINED =
  'RECEIVE_POST_LIVE_SESSION_EVENT_ALREADY_JOINED';
export const POST_LIVE_SESSION_EVENT_ERROR = 'POST_LIVE_SESSION_EVENT_ERROR';
export const RECEIVE_POST_LIVE_SESSION_EVENT_ALREADY_ENDED =
  'RECEIVE_POST_LIVE_SESSION_EVENT_ALREADY_ENDED';
export const POST_ACTIVE_SESSION_INFO = 'POST_ACTIVE_SESSION_INFO';
export const REQUEST_SESSION_STATUS = 'REQUEST_SESSION_STATUS';
export const REQUEST_SESSION_STATUS_ERROR = 'REQUEST_SESSION_STATUS_ERROR';
export const RECEIVE_SESSION_STATUS = 'RECEIVE_SESSION_STATUS';

interface RequestGetMessageAction {
  type: typeof REQUEST_GET_MESSAGES;
  payload?: { isLoadingHistorical: boolean };
}

interface RequestGetRoomInfoAction {
  type: typeof REQUEST_GET_ROOM_INFO;
  payload?: { isLoadingHistorical: boolean };
}
interface RequestPostMessageAction {
  type: typeof REQUEST_POST_MESSAGE;
}

interface RequestGetActiveSessionInfoAction {
  type: typeof REQUEST_ACTIVE_SESSION_INFO;
}
interface PostLiveSessionEvent {
  type: typeof POST_LIVE_SESSION_EVENT;
}

interface ReceivePostLiveSessionEventSuccess {
  type: typeof RECEIVE_POST_LIVE_SESSION_EVENT_SUCCESS;
}

interface ReceivePostLiveSessionEventAlreadyJoined {
  type: typeof RECEIVE_POST_LIVE_SESSION_EVENT_ALREADY_JOINED;
}

interface ReceivePostLiveSessionEventAlreadyEnded {
  type: typeof RECEIVE_POST_LIVE_SESSION_EVENT_ALREADY_ENDED;
}

interface PostLiveSessionEventError {
  type: typeof POST_LIVE_SESSION_EVENT_ERROR;
  error: Error;
}

interface ChatErrorAction {
  type:
    | typeof REQUEST_GET_MESSAGES_ERROR
    | typeof REQUEST_POST_MESSAGE_ERROR
    | typeof REQUEST_GET_ROOM_INFO_ERROR
    | typeof REQUEST_GET_STARRED_MESSAGES_ERROR
    | typeof REQUEST_ACTIVE_SESSION_INFO_ERROR;
  error: Error;
}

interface ReceiveGetActiveSessionInfoAction {
  type: typeof RECEIVE_ACTIVE_SESSION_INFO;
  payload: GetActiveSessionInfoPayload;
}
interface ReceiveGetMessagesAction {
  type: typeof RECEIVE_GET_MESSAGES;
  payload: GetMessagePayload;
}
interface ReceivePostMessageAction {
  type: typeof RECEIVE_POST_MESSAGE;
  payload: EMessage;
}
interface ReceiveAcknowledgementsAction {
  type: typeof RECEIVE_ACKNOWLEDGEMENTS;
  payload: MessageAcks;
}
interface ReceiveUserTypingAction {
  type: typeof RECEIVE_USER_TYPING;
  payload: UserTyping;
}

interface ReceiveGetRoomInfoAction {
  type: typeof RECEIVE_GET_ROOM_INFO;
  payload: GetRoomInfoPayload;
}

interface RequestGetStarredMessageAction {
  type: typeof REQUEST_GET_STARRED_MESSAGES;
}

export interface GetStarredMessagesPayload {
  starredMessages: StarredMessage[];
  starredHasReceivedAll: boolean;
}

interface ReceiveGetStarredMessageAction {
  type: typeof RECEIVE_GET_STARRED_MESSAGES;
  payload: GetStarredMessagesPayload;
}

interface RequestGetSharedFilesAction {
  type: typeof REQUEST_GET_SHARED_FILES;
}

export interface GetSharedFilesPayload {
  sharedFiles?: EMessage[];
  receivedAllSharedFiles?: boolean;
}

interface ReceiveGetSharedFilesAction {
  type: typeof RECEIVE_GET_SHARED_FILES;
  payload: GetSharedFilesPayload;
}

interface ErrorGetSharedFilesAction {
  type: typeof ERROR_GET_SHARED_FILES;
  error: Error;
}

interface ClearSharedFilesAction {
  type: typeof CLEAR_SHARED_FILES;
}

interface RequestGetHistoricalSharedFilesAction {
  type: typeof REQUEST_GET_HISTORICAL_SHARED_FILES;
}

export interface RequestToggleStarFilePayload {
  sharedFiles: EMessage[];
  messages: EMessage[];
}

export interface RequestToggleStarMessagePayload {
  sharedFiles: EMessage[];
  messages: EMessage[];
  starredMessages: StarredMessage[];
}
export interface ReceiveSessionStatusPayload {
  sessionStatus: SessionStatus | undefined;
}

interface RequestToggleStarMessageAction {
  type: typeof REQUEST_TOGGLE_STAR_MESSAGE;
  payload: RequestToggleStarMessagePayload;
}

interface ErrorToggleStarMessageAction {
  type: typeof ERROR_TOGGLE_STAR_MESSAGE;
  payload: RequestToggleStarMessagePayload;
}

interface RequestSessionStatus {
  type: typeof REQUEST_SESSION_STATUS;
}

interface ReceiveSessionStatus {
  type: typeof RECEIVE_SESSION_STATUS;
  payload: ReceiveSessionStatusPayload;
}

interface ErrorSessionStatus {
  type: typeof REQUEST_SESSION_STATUS_ERROR;
  error: Error;
}

interface ResetChat {
  type: typeof RESET_CHAT;
  payload: number;
}

interface PostActiveSessionInfoAction {
  type: typeof POST_ACTIVE_SESSION_INFO;
}

export interface GeneralNoticePayload {
  enabled?: boolean;
  bannerTitle?: string;
  bannerText?: string;
}

export type ChatActionTypes =
  | RequestGetMessageAction
  | RequestGetRoomInfoAction
  | RequestPostMessageAction
  | RequestGetActiveSessionInfoAction
  | ChatErrorAction
  | ReceiveGetMessagesAction
  | ReceiveGetRoomInfoAction
  | ReceivePostMessageAction
  | ReceiveGetActiveSessionInfoAction
  | ReceiveAcknowledgementsAction
  | ReceiveUserTypingAction
  | ResetChat
  | RequestGetStarredMessageAction
  | ReceiveGetStarredMessageAction
  | RequestGetSharedFilesAction
  | ReceiveGetSharedFilesAction
  | ErrorGetSharedFilesAction
  | RequestGetHistoricalSharedFilesAction
  | ClearSharedFilesAction
  | RequestToggleStarMessageAction
  | ErrorToggleStarMessageAction
  | ReceivePostLiveSessionEventSuccess
  | ReceivePostLiveSessionEventAlreadyJoined
  | PostLiveSessionEvent
  | PostLiveSessionEventError
  | ReceivePostLiveSessionEventAlreadyEnded
  | PostActiveSessionInfoAction
  | RequestSessionStatus
  | ReceiveSessionStatus
  | ErrorSessionStatus;
