import { withDSIconMaker } from './utils/IconMaker';

const hugePath =
  'M3 9C3 8.45 3.41786 8 3.92857 8H28.0714C28.5821 8 29 8.45 29 9C29 9.55 28.5821 10 28.0714 10H3.92857C3.41786 10 3 9.55 3 9ZM3 16C3 15.45 3.41786 15 3.92857 15H28.0714C28.5821 15 29 15.45 29 16C29 16.55 28.5821 17 28.0714 17H3.92857C3.41786 17 3 16.55 3 16ZM22.5 23C22.5 23.55 22.0821 24 21.5714 24H3.92857C3.41786 24 3 23.55 3 23C3 22.45 3.41786 22 3.92857 22H21.5714C22.0821 22 22.5 22.45 22.5 23Z';
const pathConfig = {
  standard: {
    default: {
      path: '',
    },
  },
  huge: {
    default: {
      path: hugePath,
    },
  },
};

const Bars = withDSIconMaker(pathConfig, 'Three Horizontal Bars');

export default Bars;
