import { FunctionComponent } from 'react';
import Svg, { Path } from 'svgs';
import { EmotionStyle, useEmotionTheme } from '../../core/styled';

interface ChatModalityV2Props {
  color?: string;
  width?: number;
  height?: number;
  style?: EmotionStyle;
  fill?: boolean;
  dataQa?: string;
}

const ChatModalityV2: FunctionComponent<ChatModalityV2Props> = ({
  width = 20,
  height = 20,
  color,
  style,
  fill,
  ...otherProps
}) => {
  const titleText = 'Chat icon';
  const { colors } = useEmotionTheme();
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      title={titleText}
      aria-label={titleText}
      style={style}
      {...otherProps}
    >
      <Path
        d="M11.3029 15.1541H10.8789L10.6603 15.5174C9.88145 16.8119 8.5715 17.6891 7.52936 18.2133C7.40482 18.2753 7.25528 18.2576 7.14896 18.1689C7.04291 18.0805 7.00033 17.9387 7.03891 17.8079C7.24252 17.1207 7.31617 16.4027 7.25947 15.6904L7.2151 15.1332L6.66847 15.0165C3.55561 14.3517 1.4504 11.4526 1.78493 8.29967C2.11948 5.14649 4.78686 2.75046 7.9707 2.75C7.97073 2.75 7.97076 2.75 7.97079 2.75H12.0288C15.4666 2.75 18.25 5.52869 18.25 8.95204C18.25 12.3754 15.4666 15.1541 12.0288 15.1541H11.3029Z"
        fill={fill ? color || colors.slateGrey : colors.white}
        stroke={color || colors.slateGrey}
        strokeWidth="1.5"
      />
    </Svg>
  );
};

export default ChatModalityV2;
