import { useCallback, createContext, useContext, useEffect, PropsWithChildren } from 'react';
import useObjectState from './useObjectState';
import { COLORS, A11Y_COLORS } from '../constants/commonStyles';

type ColorsType = typeof A11Y_COLORS;

interface A11yStateInternal {
  isHighContrast: boolean;
  isLoading: boolean;
  isError: boolean;
}

export interface A11yState extends A11yStateInternal {
  colors: ColorsType;
}

export interface A11yActions {
  setColorContrast: (value: boolean) => void;
  clearContrastSessionStorage: () => void;
}

const defaultSetting = (): A11yState => {
  return {
    isHighContrast: false,
    colors: COLORS,
    isLoading: false,
    isError: false,
  };
};

export const StateContext = createContext<A11yState | typeof defaultSetting>(defaultSetting);

export const ActionsContext = createContext<A11yActions | undefined>(undefined);

export const initialState: A11yStateInternal = {
  isHighContrast: false,
  isLoading: false,
  isError: false,
};

interface A11yProviderProps {
  storage?: Storage;
}
const HIGH_CONTRAST_STORAGE_KEY = 'ts_highContrast';
function A11yProvider({
  children,
  storage = window.sessionStorage,
}: PropsWithChildren<A11yProviderProps>) {
  const [state, setState] = useObjectState<A11yStateInternal>(initialState);
  const colors: ColorsType = state.isHighContrast ? A11Y_COLORS : COLORS;
  const setColorContrast = useCallback(
    (value: boolean) => {
      setState({ isHighContrast: value });
      storage.setItem(HIGH_CONTRAST_STORAGE_KEY, `${value}`);
    },
    [setState, storage]
  );

  const clearContrastSessionStorage = useCallback(() => {
    storage.removeItem(HIGH_CONTRAST_STORAGE_KEY);
  }, [storage]);

  const actions = {
    setColorContrast,
    clearContrastSessionStorage,
  };

  useEffect(() => {
    const sessionContrast = storage.getItem(HIGH_CONTRAST_STORAGE_KEY);
    if (sessionContrast !== null) {
      try {
        setColorContrast(JSON.parse(sessionContrast));
      } catch (e) {
        // No error needed
      }
    }
  }, [setColorContrast, storage]);

  return (
    <StateContext.Provider value={{ ...state, colors }}>
      <ActionsContext.Provider value={actions}>{children}</ActionsContext.Provider>
    </StateContext.Provider>
  );
}

function useA11yState(): A11yState {
  const context = useContext(StateContext);
  if (typeof context === 'function') return context();
  return context;
}

function useA11yActions() {
  const context = useContext(ActionsContext);
  if (context === undefined) {
    throw new Error('A11yaction context must be used within A11yProvider');
  }
  return context;
}

export { A11yProvider, useA11yState, useA11yActions };
