import { FunctionComponent } from 'react';
import AnimateHeight, { AnimateHeightProps } from 'react-animate-height';
import { EmotionStyle } from '../../core/styled';

export interface CollapsibleProps {
  className?: string;
  style?: EmotionStyle;
  isCollapsed: boolean;
  duration?: number;
  collapsedHeight?: number;
  expandedHeight?: number;
}

const Collapsible: FunctionComponent<CollapsibleProps & Partial<AnimateHeightProps>> = ({
  isCollapsed,
  children,
  duration,
  collapsedHeight = 0,
  expandedHeight = 'auto',
  ...otherProps
}) => (
  <AnimateHeight
    height={isCollapsed ? collapsedHeight : expandedHeight}
    duration={duration}
    {...otherProps}
  >
    {children}
  </AnimateHeight>
);

export default Collapsible;
