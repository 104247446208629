import { FunctionComponent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, Form, FieldArray } from 'formik';
import * as yup from 'yup';
import { countries, states, therapistDegrees, therapistLevels } from '@talkspace/configs';
import {
  Checkbox,
  formatSelectOptionsFromConfig,
  Large,
  OptionType,
  PhoneInputFormik,
  useEmotionTheme,
  View,
  Tiny,
  Link,
} from '@talkspace/react-toolkit';
import styled from '@/core/styled/styled';
import ContentCard from '../components/ContentCard';
import ErrorMessage from '../components/ErrorMessage';
import SignupInput from '../components/SignupInput';
import SignupSelect from '../components/SignupSelect';
import ContinueButton from '../components/ContinueButton';
import License from '../components/License';
import TermsOfServiceCheckboxLabel from '../components/TermsOfServiceCheckboxLabel';
import { NUMBER_REGEX, PHONE_REGEX, ALPHANUMERIC_REGEX } from '../constants';
import { patchTherapistClinicalInfo } from '../utils/apiHelper';
import useClinicalInfo from '../hooks/useClinicalInfo';
import useLicenseTypeOptions from '../hooks/useLicenseTypeOptions';
import useToken from '../hooks/useToken';

const HELPDESK_URL = 'https://providers-help.talkspace.com/hc/en-us/articles/4430917286555';

const degreeOptions: Array<OptionType> = formatSelectOptionsFromConfig(therapistDegrees);
const levelOptions: Array<OptionType> = formatSelectOptionsFromConfig(therapistLevels);

const validationSchema = yup.object().shape({
  phoneNumber: yup
    .string()
    .required('Required')
    .matches(PHONE_REGEX, 'Please use +XXXXXXXXXXX format'),
  npiNumber: yup
    .string()
    .matches(NUMBER_REGEX, 'Must be a number')
    .test('len', 'Must be exactly 10 numbers', (val) => [10, 0].includes(val?.length || 0)),
  professionalDegree: yup
    .string()
    .required('Required')
    .oneOf(degreeOptions.map((option) => option.value)),
  institutionAssociatedProfessionalDegree: yup.string().required('Required'),
  professionalLevel: yup
    .string()
    .required('Required')
    .oneOf(levelOptions.map((option) => option.value)),
  licenses: yup.array().of(
    yup.object().shape({
      id: yup.number().optional(),
      licenseTypeID: yup.string().required('Required'),
      number: yup
        .string()
        .required('Required')
        .matches(ALPHANUMERIC_REGEX, 'Must only contain letters and numbers'),
      country: yup.string().required('Required').oneOf(Object.keys(countries)),
      state: yup.string().required('Required').oneOf(Object.keys(states)),
    })
  ),
  caqhNumber: yup
    .string()
    .matches(NUMBER_REGEX, 'Must be a number')
    .test('len', 'Must be exactly 8 characters', (val) => [8, 0].includes(val?.length || 0)),
  accessInitiativeParticipation: yup.boolean().required('Required'),
});

const Wrapper = styled(View)({
  maxWidth: 360,
});

const ExternalLink = styled(Link)(({ theme: { colors } }) => {
  return {
    color: `${colors.permaDarkCyan} !important`,
    fontWeight: 'bold',
    '&:hover:focus': {
      color: `${colors.permaDarkCyan} !important`,
    },
  };
});

const accessInitiativeOptions = [
  { value: 'true', label: 'Yes' },
  { value: 'false', label: 'No' },
];

const ClinicalInfoForm: FunctionComponent<{}> = () => {
  const { colors } = useEmotionTheme();
  const history = useHistory();
  const token = useToken();
  const { data: initialValues } = useClinicalInfo(token);
  const { data: licenseTypeOptions } = useLicenseTypeOptions();
  const [acceptedTOS, setAcceptedTOS] = useState(false);

  const onSubmit = (values, formikHelpers) => {
    patchTherapistClinicalInfo(values, token)
      .then(() => {
        history.push(`/signup/credentials${history.location.hash}`);
      })
      .catch((error) => {
        formikHelpers.setStatus('Something went wrong. Please try again.');
        formikHelpers.setSubmitting(false);
      });
  };

  return (
    <Wrapper>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, isSubmitting, isValid, status: apiError }) => (
          <Form>
            <PhoneInputFormik
              fieldName="phoneNumber"
              inputStyle={{ height: 55, color: colors.black, fontSize: 16 }}
            />
            <View row align="center">
              <Checkbox
                label={<TermsOfServiceCheckboxLabel />}
                isLabelOnRight
                isChecked={acceptedTOS}
                setIsChecked={() => setAcceptedTOS(!acceptedTOS)}
                containerStyle={{ alignItems: 'flex-start', marginTop: 16 }}
                checkedColor={colors.permaDarkCyan}
              />
            </View>
            <SignupInput
              fieldName="npiNumber"
              label="National provider identification"
              placeholder="• • • • • • • • • •"
              required
            />
            <SignupSelect
              fieldName="professionalDegree"
              label="Professional degree"
              options={degreeOptions}
            />
            <SignupInput
              fieldName="institutionAssociatedProfessionalDegree"
              label="Institution associated with your professional degree"
              required
            />
            <SignupSelect
              fieldName="professionalLevel"
              label="Professional level"
              options={levelOptions}
            />
            <ContentCard style={{ marginTop: 16 }}>
              <Large
                variant="largeLightGrey"
                style={{ color: colors.slateGrey, fontWeight: 'bold' }}
              >
                License information
              </Large>
              <FieldArray
                name="licenses"
                render={(arrayHelpers) =>
                  values.licenses.map((license, i) => (
                    <License
                      key={`license-${license.id}`}
                      index={i}
                      typeOptions={licenseTypeOptions}
                      onAdd={() =>
                        arrayHelpers.push({
                          licenseTypeID: '',
                          number: '',
                          country: 'US',
                          state: '',
                        })
                      }
                      onRemove={() => arrayHelpers.remove(i)}
                      isLast={i === values.licenses.length - 1}
                    />
                  ))
                }
              />
            </ContentCard>
            <SignupInput
              fieldName="caqhNumber"
              label="CAQH number"
              placeholder="• • • • • • • •"
              required
            />
            <SignupSelect
              fieldName="accessInitiativeParticipation"
              label="Do you want to participate in our Access Initiative?"
              options={accessInitiativeOptions}
              required
            />
            <Tiny style={{ marginTop: 4 }}>
              As the demand for Behavioral Health increases, most states are enacting legislation
              that makes it easier to support patients across state lines. Join us in helping with
              our
              <ExternalLink
                href={HELPDESK_URL}
                target="_blank"
                isNested
                primaryColor={colors.permaDarkCyan}
              >
                Access Initiative.
              </ExternalLink>
            </Tiny>
            <ContinueButton
              disabled={isSubmitting || !isValid || !acceptedTOS}
              isLoading={isSubmitting}
            />
            {apiError && <ErrorMessage style={{ marginTop: 12 }}>{apiError}</ErrorMessage>}
          </Form>
        )}
      </Formik>
    </Wrapper>
  );
};
export default ClinicalInfoForm;
