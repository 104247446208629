import { FunctionComponent } from 'react';
import { Provider } from 'react-redux';
import { useComponentSize, View } from '@talkspace/react-toolkit';
import styled from '@/core/styled';
import createStore from '../redux/chatStore';
import SharedFilesContainer from '../containers/SharedFilesContainer';
import { ToastContainer } from '../components/Toast';

interface Props {
  roomID: number;
  isTherapistChat: boolean;
  onClosePress: () => void;
}

const SharedFilesContainerWrapper = styled(View)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      paddingTop: 20,
      paddingRight: isMobile ? 10 : 93,
      paddingLeft: isMobile ? 10 : 93,
      width: '100%',
    };
  }
);

const StarredMessagesScreen: FunctionComponent<Props> = ({
  roomID,
  isTherapistChat,
  onClosePress,
}) => {
  const [componentSizeRef, componentSize] = useComponentSize<HTMLDivElement>();

  return (
    <Provider store={createStore(isTherapistChat)}>
      <>
        <SharedFilesContainerWrapper ref={componentSizeRef}>
          <SharedFilesContainer
            roomID={roomID}
            containerWidth={componentSize.width || 0}
            isTherapistChat={isTherapistChat}
            onClosePress={onClosePress}
          />
        </SharedFilesContainerWrapper>
        <ToastContainer />
      </>
    </Provider>
  );
};

export default StarredMessagesScreen;
