import { View, Big, Small, LiveSessionCalendarIcon, Avatar } from '@talkspace/react-toolkit';
import { useEffect } from 'react';
import moment from 'moment';
import { SessionModality, TherapistTimeslot } from 'ts-frontend/types';
import { StaticProps } from './types';
import apiHelper from '@/core/api/apiHelper';

export interface SessionScheduledProps {
  modality: SessionModality;
  selectedTimeslot?: TherapistTimeslot | null;
}
type Props = SessionScheduledProps & StaticProps;

const CalendarCircle = ({ modality, selectedTimeslot, colors }) => (
  <View style={{ width: '100%', position: 'absolute' }} align="center">
    <View
      style={{
        borderRadius: '50%',
        height: 120,
        width: 120,
        zIndex: 1,
        backgroundColor: colors?.permaPickledBluewood,
      }}
      align="center"
      justify="center"
    >
      <LiveSessionCalendarIcon modality={modality} />
      <View>
        <Big style={{ color: colors.white, marginTop: 11 }}>
          {moment(selectedTimeslot?.start).format('MMM D')}
        </Big>
        <Small style={{ color: colors?.permaPeriwinkleGray }}>
          {moment(selectedTimeslot?.start).format('h:mmA')}
        </Small>
      </View>
    </View>
  </View>
);

const SessionScheduled = ({
  colors,
  modality,
  selectedTimeslot,
  closeTray,
  therapistUserID,
  isMobile,
}: Props) => {
  useEffect(() => {
    setTimeout(() => {
      closeTray();
    }, 3000);
  }, [closeTray]);

  return (
    <View align="center" style={{ marginBottom: isMobile ? 81 : 51 }}>
      <Big style={{ color: colors.white, marginBottom: 20 }}>Session Scheduled!</Big>
      <View
        style={{ height: 120, width: '100%', position: 'relative' }}
        row
        align="end"
        justify="center"
      >
        <CalendarCircle selectedTimeslot={selectedTimeslot} modality={modality} colors={colors} />
        <View justify="space-between" align="center" row>
          <Avatar
            width={82}
            height={82}
            image={
              therapistUserID
                ? `${
                    apiHelper().cdnEndpoint
                  }/images/application/therapist/440/${therapistUserID}.png`
                : ''
            }
            imageStyle={{ width: 82, height: 82 }}
            style={{
              borderRadius: '150%',
              marginRight: 86,
            }}
          />
          <Avatar width={82} height={82} image="" />
        </View>
      </View>
    </View>
  );
};

export default SessionScheduled;
