import { createContext, useState, useEffect, PropsWithChildren } from 'react';

import View from '../components/View';
import Spinner from '../components/Spinner';

const initSDK = (apiKey: string, callback?: (success: boolean) => void) => {
  const d = document;
  const g = d.createElement('script');
  const s = d.getElementsByTagName('script')[0];
  g.type = 'text/javascript';
  g.defer = true;
  g.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
  if (callback) g.onload = () => callback(true);
  if (callback) g.onerror = () => callback(false);
  if (s.parentNode) s.parentNode.insertBefore(g, s);
};

interface GoogleMapsState {
  status: boolean;
}

const GoogleMapsContext = createContext<GoogleMapsState | undefined>(undefined);

interface Props {
  apiKey: string;
}

export default function GoogleMapsProvider({ apiKey, children }: PropsWithChildren<Props>) {
  const [isReady, setIsReady] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    initSDK(apiKey, (success: boolean) => {
      if (!success) {
        // eslint-disable-next-line no-console
        console.warn('Could not setup Google Map library');
        setIsError(true);
        return;
      }
      setIsReady(true);
    });
  }, [apiKey]);

  if (!isReady) {
    return (
      <View style={{ height: '100vh' }}>
        <View flex={1}>
          <Spinner isLoading />
        </View>
      </View>
    );
  }

  if (isError) {
    return (
      <View style={{ height: '100vh' }}>
        <View flex={1}> Error: Could not setup Google Map library </View>
      </View>
    );
  }

  return (
    <GoogleMapsContext.Provider value={{ status: isReady }}>{children}</GoogleMapsContext.Provider>
  );
}
