const TIMEOUT_ERROR = 'TIMEOUT_ERROR';

export default function cancelPromiseHelper() {
  let cancelPromise: Promise<any>;

  let cancelPromiseReject;

  let hookedWrappersCounter = 0;

  function setCancelPromiseReject() {
    return new Promise((resolve, reject) => {
      cancelPromiseReject = reject;
    });
  }

  cancelPromise = setCancelPromiseReject();

  function cancelAll() {
    // if hookedWrappersCounter is 0 than there is no request hooked by wrapWithCancel()
    if (hookedWrappersCounter) cancelPromiseReject(new Error(TIMEOUT_ERROR));
  }

  function reset() {
    cancelAll();
    cancelPromise = setCancelPromiseReject();
  }

  function dismissIfCancelled(err: Error): any {
    if (err.message !== TIMEOUT_ERROR) throw err;
  }

  function wrapWithCancel(req: Promise<any>): Promise<any> {
    hookedWrappersCounter += 1;

    return Promise.race([cancelPromise, req])
      .then((promiseRes) => {
        hookedWrappersCounter -= 1;
        return promiseRes;
      })
      .catch((err) => {
        hookedWrappersCounter -= 1;
        throw err;
      });
  }

  return Object.freeze({
    cancelAll,
    reset,
    dismissIfCancelled,
    wrapWithCancel,
  });
}
