import { FunctionComponent } from 'react';

import Svg, { Path } from 'svgs';
import { useEmotionTheme } from '../../core/styled';
import { TSLogoCollapsedProps } from './types';

const TSLogoCollapsed: FunctionComponent<TSLogoCollapsedProps> = ({
  height = 103,
  width = 94,
  color,
  noMargin,
  ...otherProps
}) => {
  const titleText = 'Talkspace';
  const { colorRoles } = useEmotionTheme();
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 94 103"
      fill="none"
      title={titleText}
      aria-label={titleText}
      style={
        noMargin
          ? {}
          : {
              margin: '48.5px 53px',
            }
      }
      {...otherProps}
    >
      <Path
        d="M91.186 56.818H78.802a2.807 2.807 0 00-2.814 2.799V100.2A2.807 2.807 0 0173.174 103H20.826a2.807 2.807 0 01-2.814-2.799v-25.28a2.807 2.807 0 00-2.814-2.799H2.814A2.807 2.807 0 010 69.325V2.799A2.801 2.801 0 012.808 0h88.386A2.8 2.8 0 0194 2.799v51.22a2.807 2.807 0 01-2.814 2.799z"
        fill={colorRoles.icons.iconBrandDefault}
      />
    </Svg>
  );
};

export default TSLogoCollapsed;
