import { withDSIconMaker } from './utils/IconMaker';

const hugePath =
  'M28.25 16C28.25 12.7511 26.9594 9.63526 24.6621 7.33794C22.3647 5.04062 19.2489 3.75 16 3.75C12.7511 3.75 9.63526 5.04062 7.33794 7.33794C5.04062 9.63526 3.75 12.7511 3.75 16C3.75 19.2489 5.04062 22.3647 7.33794 24.6621C9.63526 26.9594 12.7511 28.25 16 28.25C19.2489 28.25 22.3647 26.9594 24.6621 24.6621C26.9594 22.3647 28.25 19.2489 28.25 16ZM2 16C2 12.287 3.475 8.72601 6.1005 6.1005C8.72601 3.475 12.287 2 16 2C19.713 2 23.274 3.475 25.8995 6.1005C28.525 8.72601 30 12.287 30 16C30 19.713 28.525 23.274 25.8995 25.8995C23.274 28.525 19.713 30 16 30C12.287 30 8.72601 28.525 6.1005 25.8995C3.475 23.274 2 19.713 2 16ZM10.75 12.5C10.75 11.532 11.532 10.75 12.5 10.75H19.5C20.468 10.75 21.25 11.532 21.25 12.5V19.5C21.25 20.468 20.468 21.25 19.5 21.25H12.5C11.532 21.25 10.75 20.468 10.75 19.5V12.5Z';
const pathConfig = {
  standard: {
    default: {
      path: '',
    },
  },
  huge: {
    default: {
      path: hugePath,
    },
  },
};

const CircleStop = withDSIconMaker(pathConfig, 'Circle Stop');

export default CircleStop;
