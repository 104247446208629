import { FunctionComponent, useState } from 'react';
import RatingStar from '../RatingStar';
import { useUniqueID } from '../../../hooks/a11yHelper';
import styled, { useEmotionTheme } from '../../../core/styled';

import './ratingstars.css';
// most of this code is taken from accessible-react-rating: https://www.npmjs.com/package/accessible-react-rating
// adjusted the logic and converted from a class component to a functonal component

const DefaultEmptySymbol = () => {
  const { colors } = useEmotionTheme();
  return <RatingStar fill={colors.white} />;
};
const DefaultFullSymbol = () => <RatingStar />;

export interface StarProps {
  emptySymbol: JSX.Element;
  fullSymbol: JSX.Element;
  index: number;
  changeHandler: (index: number) => void;
  initialRating?: number;
  numberOfSelectedSymbols: number;
  getRatingValue: (index: number) => void;
  dataQa?: string;
}

const StarLabel = styled.label({
  paddingLeft: 6,
  paddingRight: 6,
  borderBottom: '3px solid transparent',
});

const Star: FunctionComponent<StarProps> = ({
  emptySymbol,
  fullSymbol,
  index,
  changeHandler,
  initialRating,
  numberOfSelectedSymbols,
  getRatingValue,
  dataQa,
}) => {
  const [hasMadeSelection, setHasMadeSelection] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const onTouchStart = () => {
    changeHandler(index);
    getRatingValue(index);
  };
  const onMouseEnter = () => {
    changeHandler(index);
  };
  const onMouseLeave = () => {
    if (!hasMadeSelection) {
      if (initialRating) changeHandler(initialRating);
      else changeHandler(0);
    }
  };
  // can't a find a type for this event that doesn't cause an error
  const onClick = (e) => {
    setHasMadeSelection(true);
    onTouchStart();
    // cross browser check for whether or not a mouse was used to trigger the click
    if ((e.clientX === 0 && e.clientY === 0) || (e.pageX === 0 && e.pageY === 0)) {
      return;
    }
    setIsClicked(true);
  };
  const onFocus = () => {
    setIsFocused(true);
  };
  const onBlur = () => {
    setIsClicked(false);
    setIsFocused(false);
  };
  const uniqLabelID = useUniqueID('uniqLabelID');
  const uniqStarID = useUniqueID('uniqStarID');
  return (
    <>
      <input
        data-qa={dataQa}
        value={index}
        id={uniqStarID}
        type="radio"
        name="rating"
        onClick={onClick}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={() => {
          onTouchStart();
        }}
        checked={
          (!hasMadeSelection && initialRating && initialRating === index) ||
          index === numberOfSelectedSymbols
        }
        className={`visuallyhidden ${
          (!hasMadeSelection && initialRating && index <= initialRating) ||
          index <= numberOfSelectedSymbols
            ? 'selected'
            : ''
        }`}
      />
      {/* eslint-disable-next-line jsx-a11y/label-has-for */}
      <StarLabel
        id={uniqLabelID}
        htmlFor={uniqStarID}
        onMouseEnter={() => {
          onMouseEnter();
        }}
        onMouseLeave={() => {
          onMouseLeave();
        }}
        onTouchStart={() => {
          onTouchStart();
        }}
        style={
          isFocused && !isClicked
            ? { borderBottomColor: 'rgb(0, 107, 223)' }
            : { borderBottomColor: 'transparent' }
        }
      >
        <span className="visuallyhidden">{`${index} Star${index === 1 ? '' : 's'}`}</span>
        <span className="empty-symbol">{emptySymbol}</span>
        <span className="full-symbol">{fullSymbol}</span>
      </StarLabel>
    </>
  );
};

export interface RatingStarsProps {
  legend?: string;
  showLegend?: boolean;
  numberOfStars?: number;
  getRatingValue: (value: number) => void;
  initialRating?: number;
  dataQa?: string;
}
const RatingStars: FunctionComponent<RatingStarsProps> = ({
  legend,
  showLegend,
  numberOfStars = 5,
  getRatingValue,
  initialRating,
  dataQa,
}) => {
  const [numberOfSelectedSymbols, setNumberOfSelectedSymbols] = useState(0);
  const changeHandler = (numOfSelectedSymbols: number) =>
    setNumberOfSelectedSymbols(numOfSelectedSymbols);
  const uniqStarKey = useUniqueID('uniqStarID');
  return (
    <fieldset data-qa={dataQa} className="accessible-rating">
      <legend className={!showLegend ? 'visuallyhidden' : undefined}>
        {legend ||
          `Rate your experience from 1 to ${numberOfStars}, ${numberOfStars} being the best`}
      </legend>
      {[...new Array(numberOfStars)].map((item, index) => {
        const starKey = `${uniqStarKey}-${index}`;
        return (
          <Star
            emptySymbol={<DefaultEmptySymbol />}
            fullSymbol={<DefaultFullSymbol />}
            index={index + 1}
            key={starKey}
            changeHandler={changeHandler}
            initialRating={initialRating}
            getRatingValue={getRatingValue}
            numberOfSelectedSymbols={numberOfSelectedSymbols}
          />
        );
      })}
    </fieldset>
  );
};

export default RatingStars;
