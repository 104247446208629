import { withDSIconMaker } from './utils/IconMaker';

const hugePath =
  'M28.25 16C28.25 12.7511 26.9594 9.63526 24.6621 7.33794C22.3647 5.04062 19.2489 3.75 16 3.75C12.7511 3.75 9.63526 5.04062 7.33794 7.33794C5.04062 9.63526 3.75 12.7511 3.75 16C3.75 19.2489 5.04062 22.3647 7.33794 24.6621C9.63526 26.9594 12.7511 28.25 16 28.25C19.2489 28.25 22.3647 26.9594 24.6621 24.6621C26.9594 22.3647 28.25 19.2489 28.25 16ZM2 16C2 12.287 3.475 8.72601 6.10051 6.10051C8.72601 3.475 12.287 2 16 2C19.713 2 23.274 3.475 25.8995 6.10051C28.525 8.72601 30 12.287 30 16C30 19.713 28.525 23.274 25.8995 25.8995C23.274 28.525 19.713 30 16 30C12.287 30 8.72601 28.525 6.10051 25.8995C3.475 23.274 2 19.713 2 16ZM14.25 11.625V20.375C14.25 20.8563 13.8562 21.25 13.375 21.25C12.8938 21.25 12.5 20.8563 12.5 20.375V11.625C12.5 11.1438 12.8938 10.75 13.375 10.75C13.8562 10.75 14.25 11.1438 14.25 11.625ZM19.5 11.625V20.375C19.5 20.8563 19.1063 21.25 18.625 21.25C18.1437 21.25 17.75 20.8563 17.75 20.375V11.625C17.75 11.1438 18.1437 10.75 18.625 10.75C19.1063 10.75 19.5 11.1438 19.5 11.625Z';
const pathConfig = {
  standard: {
    default: {
      path: '',
    },
  },
  huge: {
    default: {
      path: hugePath,
    },
  },
};

const CirclePause = withDSIconMaker(pathConfig, 'Circle Pause');

export default CirclePause;
