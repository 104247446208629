import { View, useObjectState, useWindowWidth } from '@talkspace/react-toolkit';
import { useCallback, useEffect, useState, VoidFunctionComponent } from 'react';
import { Moment } from 'moment';
import { FormControl } from 'react-bootstrap';
import isEqual from 'lodash/isEqual';
import { CaseLoadFilterType, FilterState, SortField } from './types';
import CaseLoadFilter from './CaseLoadFilter';
import saveLocalStorageData from '../../../utils/saveLocalStorageData';
import { AllClientState, localStorageTableNames, RoomRecord } from '../types';
import { DashboardInteraction } from '@/analytics/types';
import DateFilters from './DateFilters';
import SearchFilters from './SearchFilters';
import AppliedFilters from './AppliedFilters';
import {
  getAppliedFilters,
  getCurrentFiltersState,
  getHasMadeFilterSelections,
  getInitialFormState,
  matchesFilter,
  getSortDropdownTitles,
} from './utils';
import { matchesSearchString } from '../Clients/utils';
import { THERAPIST_CASELOAD_FILTERS, PSYCHIATRIST_CASELOAD_FILTERS } from './constants';
import CaseLoadFilterButton from './CaseLoadFilterButton';
import useDashboardSize from '../hooks/useDashboardSize';
import SortDropdown from '../../Reusable/SortDropdown/SortDropdown';

interface DashboardFilterProps {
  currentState: AllClientState;
  filterToDate: Moment | null;
  filterFromDate: Moment | null;
  handleClientState: (allClientState: Partial<AllClientState>) => void;
  updateFilterFromDate: (date: Moment | null) => void;
  updateFilterToDate: (date: Moment | null) => void;
  getEventProperties: () => DashboardInteraction;
  handleClientFilterState: (filterState: Partial<FilterState>) => void;
  handleSendEvent: (activeFilterList?: Array<string>, filteredResultCount?: number) => void;
  rooms: RoomRecord[];
  filteredData: RoomRecord[];
  persistData?: boolean;
  caseLoadFilterType?: CaseLoadFilterType;
  sortField: SortField;
  handleChangeSortField: (value: SortField) => void;
}

const DashboardFilter: VoidFunctionComponent<DashboardFilterProps> = ({
  filterFromDate,
  filterToDate,
  handleClientState,
  currentState,
  updateFilterFromDate,
  updateFilterToDate,
  getEventProperties,
  handleClientFilterState,
  handleSendEvent,
  rooms,
  filteredData,
  persistData = true,
  caseLoadFilterType = 'therapist',
  sortField,
  handleChangeSortField,
}) => {
  const { isDashboardMobile } = useDashboardSize();
  const appliedFilters = getAppliedFilters(persistData, caseLoadFilterType);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState<boolean>(false);
  const [shouldSendEvent, setShouldSendEvent] = useState<boolean>(false);
  const [state, setState] = useObjectState<FilterState>(
    getCurrentFiltersState(persistData, caseLoadFilterType)
  );

  const { isMobile } = useWindowWidth();

  const [hasMadeSelections, setHasMadeSelections] = useState<boolean>(() =>
    getHasMadeFilterSelections(persistData, caseLoadFilterType)
  );

  const [filterCount, setFilterCount] = useState<number>(filteredData.length);

  useEffect(() => {
    setFilterCount(filteredData.length);
  }, [filteredData.length]);

  useEffect(() => {
    if (hasMadeSelections && appliedFilters.length && shouldSendEvent) {
      const activeFilterList = appliedFilters.map((appliedFilter) => appliedFilter.label);
      handleSendEvent(activeFilterList, filterCount);
      setShouldSendEvent(false);
    }
  }, [appliedFilters, filterCount, hasMadeSelections, shouldSendEvent, handleSendEvent]);

  const getFilterCount = useCallback(
    (filterState: FilterState) => {
      const searchBarFilteredData = rooms.filter((client) =>
        matchesSearchString(currentState, client)
      );
      setFilterCount(matchesFilter(filterState, searchBarFilteredData).length);
    },
    [currentState, rooms]
  );

  const handleCaseLoadFilterStateChange = useCallback(
    (caseLoadFilterState: FilterState): void => {
      setState(caseLoadFilterState);
    },
    [setState]
  );
  const handleLoadFilterMadeSelections = useCallback(
    (hasMade: boolean): void => {
      setHasMadeSelections(hasMade);
    },
    [setHasMadeSelections]
  );

  const handleApplyFilters = useCallback(
    (currentFilterState: FilterState) => {
      if (persistData) {
        saveLocalStorageData(
          caseLoadFilterType === 'therapist'
            ? THERAPIST_CASELOAD_FILTERS
            : PSYCHIATRIST_CASELOAD_FILTERS,
          currentFilterState
        );
        handleCaseLoadFilterStateChange(currentFilterState);
        getFilterCount(currentFilterState);
        handleLoadFilterMadeSelections(
          !isEqual(getInitialFormState(caseLoadFilterType), currentState)
        );
      }
    },
    [
      caseLoadFilterType,
      currentState,
      getFilterCount,
      handleCaseLoadFilterStateChange,
      handleLoadFilterMadeSelections,
      persistData,
    ]
  );

  const setSearchType = useCallback(
    (name: string) => {
      const userInput = name || 'Nickname';

      handleClientState({
        searchType: userInput,
        searchString: '',
        searchTypeOpen: false,
        isUserSearching: true,
      });
      saveLocalStorageData(localStorageTableNames.caseLoad, { searchType: userInput });
    },
    [handleClientState]
  );

  const toggleSearchTypeOpen = useCallback(() => {
    handleClientState({
      searchTypeOpen: !currentState.searchTypeOpen,
    });
  }, [handleClientState, currentState.searchTypeOpen]);

  const handleSearch = useCallback(
    (event: React.FormEvent<FormControl>) => {
      const searchTerm: string = (event.target as HTMLInputElement).value;

      handleClientState({ searchString: searchTerm.toLowerCase(), isUserSearching: true });
      saveLocalStorageData(localStorageTableNames.caseLoad, {
        searchString: searchTerm.toLowerCase(),
        pageIndex: 0,
      });
    },
    [handleClientState]
  );

  const handleShowResults = (filterStateInput) => {
    handleClientFilterState(filterStateInput);
    setIsFilterModalOpen(false);
  };

  const handleSubmitFilters = () => {
    if (persistData) {
      saveLocalStorageData(
        caseLoadFilterType === 'therapist'
          ? THERAPIST_CASELOAD_FILTERS
          : PSYCHIATRIST_CASELOAD_FILTERS,
        state
      );
    }
    if (filterCount > 0) {
      handleShowResults(state);
      setShouldSendEvent(true);
    }
  };

  const sortDropdownContent = getSortDropdownTitles();

  const sortButtonTitle =
    sortDropdownContent.find((item) => item.id === sortField)?.title || 'Waiting';

  return (
    <>
      <View style={{ position: 'relative', width: '100%' }} row={!isMobile} justify="space-between">
        <View row style={{ gap: 8 }}>
          <CaseLoadFilterButton
            filteredCount={appliedFilters.length}
            setIsFilterModalOpen={setIsFilterModalOpen}
          />
          <CaseLoadFilter
            caseLoadFilterType={caseLoadFilterType}
            filterCount={filterCount}
            persistData={persistData}
            showModal={isFilterModalOpen}
            handleModalClosePress={() => setIsFilterModalOpen(false)}
            caseLoadFilterState={state}
            hasMadeSelections={hasMadeSelections}
            handleLoadFilterMadeSelections={handleLoadFilterMadeSelections}
            handleCaseLoadFilterStateChange={handleCaseLoadFilterStateChange}
            getFilterCount={getFilterCount}
            handleApplyFilters={handleApplyFilters}
            handleSubmitFilters={handleSubmitFilters}
          />
          {isDashboardMobile && (
            <SortDropdown
              menuStyles={{ top: 40, right: 0 }}
              buttonStyles={{ marginTop: 0 }}
              sortField={sortField}
              dropDownContent={sortDropdownContent}
              buttonTitle={sortButtonTitle}
              handleChangeSortField={(value) => handleChangeSortField(value as SortField)}
            />
          )}
        </View>

        <View row>
          {!isDashboardMobile && (
            <DateFilters
              filterToDate={filterToDate}
              filterFromDate={filterFromDate}
              currentState={currentState}
              handleClientState={handleClientState}
              updateFilterToDate={updateFilterToDate}
              updateFilterFromDate={updateFilterFromDate}
              getEventProperties={getEventProperties}
            />
          )}

          <SearchFilters
            getEventProperties={getEventProperties}
            currentState={currentState}
            handleSearch={handleSearch}
            setSearchType={setSearchType}
            toggleSearchTypeOpen={toggleSearchTypeOpen}
          />
        </View>
      </View>
      <AppliedFilters
        appliedFilters={appliedFilters}
        caseLoadFilterType={caseLoadFilterType}
        handleApplyFilters={handleApplyFilters}
        handleClientFilterState={handleClientFilterState}
        handleLoadFilterMadeSelections={handleLoadFilterMadeSelections}
      />
    </>
  );
};

export default DashboardFilter;
