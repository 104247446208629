import { Button } from '@talkspace/react-toolkit';
import styled, { EmotionStyle } from '../../../../core/styled';

const SubmitButton = styled(Button)(({ theme: { window } }) => {
  const { isMobile, isLarge } = window;

  const mobileStyle: EmotionStyle = isMobile ? { width: 95, marginLeft: 15 } : {};
  const tabletStyle: EmotionStyle = isLarge ? { width: 95 } : {};

  return {
    width: 135,
    minHeight: 30,
    height: 30,
    borderRadius: 5,
    margin: 0,
    ...tabletStyle,
    ...mobileStyle,
  };
});

export default SubmitButton;
