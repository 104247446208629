import { View } from '@talkspace/react-toolkit';
import styled from '@/core/styled';
import { webOnlyStyle } from '@/core/styled/styleHelpers';

const CallGradient = styled(View)<{
  isBottom: boolean;
  isLarge: boolean;
}>(({ isBottom, isLarge }) => {
  const position = isBottom ? { bottom: 0 } : { top: 0 };
  return {
    position: 'absolute',
    height: isLarge ? 133 : 32,
    width: '100%',
    ...position,
    ...webOnlyStyle({
      backgroundImage: `linear-gradient(to ${
        isBottom ? 'bottom' : 'top'
      }, rgba(0, 0, 0, 0), 60%, rgb(0, 0, 0, 0.6))`,
    }),
  };
});

export default CallGradient;
