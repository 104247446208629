/* eslint-disable no-console */

import MediaRecorder from 'audio-recorder-polyfill';
import mpegEncoder from 'audio-recorder-polyfill/mpeg-encoder';

MediaRecorder.encoder = mpegEncoder;
MediaRecorder.prototype.mimeType = 'audio/mpeg';

const DEFAULT_OPTIONS: MediaRecorderOptions = {
  mimeType: 'audio/mpeg',
};

export interface AudioPayload {
  blob: Blob;
  type: 'audio';
  duration: number;
  audioUrl: string;
  fileName: string;
}

const { navigator } = window;

export type AudioRecorderState = 'inactive' | 'recording' | 'paused';

export default class AudioRecorder {
  private stream: MediaStream;

  private mediaRecorder: MediaRecorder;

  private audioChunks: Blob[];

  public state: AudioRecorderState;

  constructor(stream: MediaStream, options: MediaRecorderOptions = DEFAULT_OPTIONS) {
    this.stream = stream;
    if (!MediaRecorder.isTypeSupported(options.mimeType || '')) {
      throw new Error(`Unsupported Audio mimeType ${options.mimeType}`);
    }
    this.mediaRecorder = new MediaRecorder(this.stream, options);
    this.mediaRecorder.addEventListener('error', this.onRecorderError);
    this.audioChunks = [];
    this.state = 'inactive';
  }

  static init(
    onWaitingPermission?: () => void,
    options?: MediaRecorderOptions
  ): Promise<AudioRecorder | null> {
    return AudioRecorder.getUserMedia(onWaitingPermission)
      .then((userMedia) => {
        try {
          return new AudioRecorder(userMedia, options);
        } catch (error) {
          console.warn('Could not start audio recording', error);
          return null;
        }
      })
      .catch((err) => {
        if (err.message === 'Browser not supported') {
          // eslint-disable-next-line no-alert
          alert(
            'This browser is not supported. To access this feature, use the Talkspace mobile app.'
          );
        } else {
          // eslint-disable-next-line no-alert
          alert('Permission denied');
        }
        return null;
      });
  }

  public start = () => {
    this.audioChunks = [];
    this.mediaRecorder.addEventListener('dataavailable', this.onNewChunk);
    this.mediaRecorder.start();
    this.state = 'recording';
  };

  public stop = (): Promise<AudioPayload> =>
    new Promise((resolve) => {
      this.mediaRecorder.addEventListener('stop', () => {
        this.state = 'paused';
        const fileName = `audio-${Date.now()}.mp3`;
        const audioBlob = new Blob(this.audioChunks, {
          type: 'audio/mpeg',
        });
        const audioUrl = URL.createObjectURL(audioBlob);
        const audio = new Audio(audioUrl);

        this.stream.getTracks().forEach((track) => track.stop());
        audio.onloadedmetadata = () => {
          const { duration } = audio;
          resolve({ blob: audioBlob, fileName, type: 'audio', duration, audioUrl });
        };
      });
      this.mediaRecorder.stop();
    });

  private onNewChunk = (event: BlobEvent): void => {
    // console.log(`Recorded chunk of size ${event.data.size}B`);
    if (Array.isArray(this.audioChunks)) {
      this.audioChunks.push(event.data);
    } else {
      this.audioChunks = [event.data];
    }
  };

  private onRecorderError = (error: MediaRecorderErrorEvent): void => {
    console.warn('[Audio error] - ', error);
    this.stop();
  };

  static getUserMedia(onWaitingPermission?: () => void) {
    const timeout = window.setTimeout(() => {
      if (onWaitingPermission) onWaitingPermission();
    }, 500);
    const removeTimeout = () => window.clearTimeout(timeout);
    if (!navigator.mediaDevices) {
      removeTimeout();
      return Promise.reject(new Error('Browser not supported'));
    }
    return navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((userMedia) => {
        removeTimeout();
        return userMedia;
      })
      .catch((err) => {
        removeTimeout();
        throw err;
      });
  }
}
