import { FunctionComponent } from 'react';
import { Provider } from 'react-redux';
import { useComponentSize, View } from '@talkspace/react-toolkit';

import createStore from '../redux/chatStore';
import StarredMessagesContainer from '../containers/StarredMessagesContainer';
import { ToastContainer } from '../components/Toast';

interface Props {
  roomID?: number;
  isTherapistChat: boolean;
}

const StarredMessagesScreen: FunctionComponent<Props> = (props) => {
  const { roomID, isTherapistChat } = props;

  const [componentSizeRef, componentSize] = useComponentSize<HTMLDivElement>();

  return (
    <Provider store={createStore(isTherapistChat)}>
      <>
        <View style={{ width: '100%', height: '100%' }} ref={componentSizeRef}>
          <StarredMessagesContainer
            roomID={roomID}
            containerWidth={componentSize.width || 0}
            isTherapistChat={isTherapistChat}
          />
        </View>
        <ToastContainer />
      </>
    </Provider>
  );
};

export default StarredMessagesScreen;
