import { Component } from 'react';
import {
  Row,
  Col,
  Panel,
  FormGroup,
  FormControl,
  ControlLabel,
  Button,
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import { getUserData } from '../../../utils/token';
import apiWrapper from '../../../utils/apiWrapper';
import apiHelper from '../../../utils/api';
import '../FormHeader/FormHeader.css';
import '../Account.css';
import 'react-toastify/dist/ReactToastify.min.css';

const zxcvbn = require('zxcvbn');

const minPasswordScore = 3;

const scoreTextDict = {
  0: {
    text: 'Weak',
    className: 'week',
  },
  1: {
    text: 'Weak',
    className: 'week',
  },
  2: {
    text: 'So-so',
    className: 'soSo',
  },
  3: {
    text: 'Strong',
    className: 'strong',
  },
  4: {
    text: 'Very strong',
    className: 'veryStrong',
  },
};

class Security extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      disableSave: true,
      currentPasswordValue: '',
      isCurrentPasswordValid: false,
      currentPasswordError: '',
      newPasswordValue: '',
      isNewPasswordValid: false,
      newPasswordError: '',
      confirmPasswordValue: '',
      isConfirmPasswordValid: false,
      confirmPasswordError: '',
      score: -1,
      scoreClassName: '',
    };

    this.toggleFormEdit = this.toggleFormEdit.bind(this);
    this.saveForm = this.saveForm.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.checkCurrentPassword = this.checkCurrentPassword.bind(this);
    this.checkCurrentPasswordRequired = this.checkCurrentPasswordRequired.bind(this);
    this.checkNewPassword = this.checkNewPassword.bind(this);
    this.checkNewPasswordRequired = this.checkNewPasswordRequired.bind(this);
    this.checkConfirmPassword = this.checkConfirmPassword.bind(this);
    this.checkConfirmPasswordRequired = this.checkConfirmPasswordRequired.bind(this);
  }

  cancelEdit() {
    this.refreshState();
    this.toggleFormEdit();
  }

  checkConfirmPassword(event = null) {
    const confirmPasswordVal = event ? event.target.value : this.state.confirmPasswordValue;
    if (event) {
      this.setState({
        confirmPasswordValue: event.target.value,
      });
    }
    if (this.state.newPasswordValue === confirmPasswordVal) {
      this.setState(
        { confirmPasswordError: '', isConfirmPasswordValid: true },
        this.checkFormValidity
      );
    } else if (!confirmPasswordVal) {
      this.setState({
        confirmPasswordError: ' Confirm password is required. ',
        isConfirmPasswordValid: false,
        disableSave: true,
      });
    } else if (confirmPasswordVal) {
      this.setState({
        confirmPasswordError: ' Password does not match. ',
        isConfirmPasswordValid: false,
        disableSave: true,
      });
    }
  }

  // Validation for Confirm Password
  checkConfirmPasswordRequired() {
    if (this.state.confirmPasswordValue.length === 0) {
      this.setState({
        confirmPasswordError: ' Confirm password is required. ',
        isConfirmPasswordValid: false,
        disableSave: true,
      });
    }
  }

  checkCurrentPassword(event) {
    this.setState({ currentPasswordValue: event.target.value });
    if (event.target.value !== '') {
      this.setState(
        { currentPasswordError: '', isCurrentPasswordValid: true },
        this.checkFormValidity
      );
    } else {
      this.setState({
        currentPasswordError: ' Current password is required. ',
        isCurrentPasswordValid: false,
        disableSave: true,
      });
    }
  }

  // Validation for current password

  checkCurrentPasswordRequired() {
    if (this.state.currentPasswordValue.length === 0) {
      this.setState({
        currentPasswordError: ' Current password is required. ',
        isCurrentPasswordValid: false,
        disableSave: true,
      });
    } else {
      this.setState({ currentPasswordError: '', isCurrentPasswordValid: true });
    }
  }

  checkFormValidity = () => {
    if (
      this.state.isCurrentPasswordValid &&
      this.state.isNewPasswordValid &&
      this.state.isConfirmPasswordValid
    ) {
      this.setState({ disableSave: false });
    } else {
      this.setState({ disableSave: true });
    }
  };

  checkNewPassword(event) {
    this.setState({ newPasswordValue: event.target.value });
    if (event.target.value !== '') {
      this.setState({ newPasswordError: '' });
      const { score } = zxcvbn(event.target.value);
      this.setState({ score, scoreClassName: scoreTextDict[score].className });
      if (score < minPasswordScore) {
        this.setState({
          newPasswordError: ' New password is invalid. ',
          isNewPasswordValid: false,
          disableSave: true,
        });
      } else {
        this.setState({ isNewPasswordValid: true }, this.checkFormValidity);
      }
      if (this.state.confirmPasswordValue.length > 0) {
        if (this.state.confirmPasswordValue !== event.target.value) {
          this.setState(
            {
              isConfirmPasswordValid: false,
              confirmPasswordError: ' Password does not match. ',
              disableSave: true,
            },
            () => this.checkConfirmPassword()
          );
        } else {
          this.setState(
            {
              isConfirmPasswordValid: false,
              confirmPasswordError: '',
              disableSave: true,
            },
            () => this.checkConfirmPassword()
          );
        }
      }
    } else {
      this.setState({
        newPasswordError: ' New password is required. ',
        isNewPasswordValid: false,
        disableSave: true,
      });
    }
  }

  // Validation for New Password

  checkNewPasswordRequired() {
    if (this.state.newPasswordValue.length === 0) {
      this.setState({
        newPasswordError: ' New password is required. ',
        isNewPasswordValid: false,
        disableSave: true,
      });
    }
  }

  refreshState() {
    this.setState({
      isEdit: false,
      disableSave: true,
      currentPasswordValue: '',
      currentPasswordError: true,
      newPasswordValue: '',
      newPasswordError: true,
      confirmPasswordValue: '',
      confirmPasswordError: '',
      score: -1,
      scoreClassName: '',
    });
  }

  saveForm() {
    const currentPassword = this.state.currentPasswordValue;
    const newPassword = this.state.newPasswordValue;
    const userId = getUserData().id;

    return apiWrapper
      .post(`${apiHelper().apiEndpoint}/v2/therapist/${userId}/change-password`, {
        currentPassword,
        newPassword,
      })
      .then((response) => {
        if (response.status === 200) {
          toast(<div className="toaster toaster-success">Changes are saved successfully!</div>, {
            autoClose: 3000,
          });
          this.setState(
            {
              currentPasswordValue: '',
              newPasswordValue: '',
              confirmPasswordValue: '',
            },
            () => {
              this.toggleFormEdit();
            }
          );
        }
      })
      .catch((err) => {
        if (err.status === 403) {
          toast(
            <div
              className="toaster toaster-error"
              style={{ background: '#FF0000', color: '#FFF', padding: '12px', fontSize: '16px' }}
            >
              Current Password is Incorrect
            </div>,
            { autoClose: 3000 }
          );
        } else {
          toast(
            <div
              className="toaster toaster-error"
              style={{ background: '#FF0000', color: '#FFF', padding: '12px', fontSize: '16px' }}
            >
              Server Error!
            </div>,
            { autoClose: 3000 }
          );
        }
      });
  }

  toggleFormEdit() {
    const editBool = !this.state.isEdit;
    this.setState({ isEdit: editBool });

    const actionItems = document.querySelectorAll([
      '#security .btn-ts-default',
      '#security .ts-edit-button',
      '.ts-my-account-panel .show-new-password-details',
      '.ts-my-account-panel .default-password',
    ]);
    [].forEach.call(actionItems, (item) => {
      item.classList.toggle('hidden');
    });
  }

  render() {
    const tooltip = (
      <Tooltip id="SecurityTooltip" className="tooltip ts-tooltip ts-profile-text-white">
        Choose a strong password to keep your privacy level high.
      </Tooltip>
    );

    return (
      <div>
        <Col xs={12} id="security" className="ts-panel-title">
          <Col xs={4} className="ts-font-black">
            Security
            <OverlayTrigger placement="top" trigger={['click', 'focus', 'hover']} overlay={tooltip}>
              <i className="fa fa-fw fa-question-circle fa-lg" />
            </OverlayTrigger>
          </Col>
          <Col xs={6} />
          <Button className="ts-edit-button pull-right" onClick={this.toggleFormEdit}>
            Edit
          </Button>
          <Button
            className="btn-ts-default btn-ts-green ts-profile-text-white pull-right hidden save-btn"
            onClick={this.saveForm}
            disabled={this.state.disableSave}
          >
            {' '}
            Save Changes{' '}
          </Button>
          <Button
            className="btn-ts-default ts-profile-btn-text-green cancel-btn pull-right hidden"
            onClick={this.cancelEdit}
          >
            {' '}
            Cancel{' '}
          </Button>
        </Col>
        <Col xs={12}>
          <Panel className="ts-my-account-panel">
            <Row style={{ marginBottom: '10px' }}>
              <FormGroup className="default-password">
                <Col className="ts-profile-label" md={2} xs={3}>
                  {' '}
                  Password{' '}
                </Col>
                <Col sm={4} xs={4}>
                  {' '}
                  <FormControl type="password" value="000000000000" disabled />{' '}
                </Col>
              </FormGroup>
            </Row>
            <Row className="show-new-password-details hidden">
              <Col className="ts-profile-label" md={2} xs={12}>
                Password
              </Col>
              <Col md={3} xs={12}>
                <label className="private-information-input-label">Current password</label>
                <input
                  name="currentPassword"
                  type="password"
                  className="form-control"
                  placeholder="Current Password"
                  value={this.state.currentPasswordValue}
                  onChange={this.checkCurrentPassword}
                  onBlur={this.checkCurrentPasswordRequired}
                />
                <p
                  className="ts-text-invalid ts-text-margin"
                  hidden={!this.state.currentPasswordError}
                >
                  {this.state.currentPasswordError}
                </p>
              </Col>
              <Col md={3} xs={12}>
                <label className="private-information-input-label">New password</label>
                <FormControl
                  name="newPassword"
                  type="password"
                  className="form-control"
                  placeholder="New Password"
                  value={this.state.newPasswordValue}
                  onChange={this.checkNewPassword}
                  onBlur={this.checkNewPasswordRequired}
                />
                <p className="ts-text-invalid ts-text-margin" hidden={!this.state.newPasswordError}>
                  {this.state.newPasswordError}
                </p>
                <div className="strengthIndicator" hidden={this.state.newPasswordValue.length < 1}>
                  <span>Strength: </span>
                  <span className={this.state.scoreClassName}>
                    {this.state.score >= 0 && scoreTextDict[this.state.score].text}
                  </span>
                </div>
              </Col>
              <Col md={3} xs={12}>
                <label className="private-information-input-label">Confirm new password</label>
                <input
                  name="confirmNewPassword"
                  type="password"
                  className="form-control"
                  placeholder="Confirm new password"
                  disabled={!(this.state.newPasswordValue.length >= 8)}
                  value={this.state.confirmPasswordValue}
                  onChange={this.checkConfirmPassword}
                  onBlur={this.checkConfirmPasswordRequired}
                />
                <p
                  className="ts-text-invalid ts-text-margin"
                  hidden={!this.state.confirmPasswordError}
                >
                  {this.state.confirmPasswordError}
                </p>
              </Col>
            </Row>
          </Panel>
        </Col>
        <ToastContainer
          closeButton={false}
          position={toast.POSITION.BOTTOM_RIGHT}
          hideProgressBar
        />
      </div>
    );
  }
}

export default Security;
