import Svg, { Defs, Rect, G, Use, Ellipse, Path } from 'svgs';
import { EmotionStyle } from '../../core/styled';

interface GlobeWithLocationPointerProps {
  width?: number;
  height?: number;
  style?: EmotionStyle;
}

const titleText = 'Globe with location pointer';

const GlobeWithLocationPointer = ({
  width = 84,
  height = 84,
  ...otherProps
}: GlobeWithLocationPointerProps) => (
  <Svg
    width={width}
    height={height}
    viewBox="0 0 84 84"
    title={titleText}
    aria-label={titleText}
    {...otherProps}
  >
    <filter
      x="-17.1%"
      y="-12.9%"
      width="134.3%"
      height="134.3%"
      filterUnits="objectBoundingBox"
      id="GlobeWithLocationPointer-filter_1"
    >
      <feMorphology radius="1.5" operator="erode" in="SourceAlpha" result="shadowSpreadOuter1" />
      <feOffset dx="0" dy="3" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
      <feGaussianBlur stdDeviation="5" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
      <feColorMatrix
        values="0 0 0 0 0.062745098   0 0 0 0 0.427620878   0 0 0 0 0.584313725  0 0 0 0.3 0"
        type="matrix"
        in="shadowBlurOuter1"
      />
    </filter>
    <Defs>
      <Rect id="GlobeWithLocationPointer-rect_a" x={0} y={0} width={70} height={70} rx={20} />
    </Defs>
    <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <G transform="translate(-13 -378) translate(20 382)">
        <Use
          fill="#000"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          /* @ts-ignore */
          filter="url(#GlobeWithLocationPointer-filter_1)"
          xlinkHref="#GlobeWithLocationPointer-rect_a"
        />
        <Use fill="#FFF" xlinkHref="#GlobeWithLocationPointer-rect_a" />
      </G>
      <G transform="translate(-13 -378) translate(20 382) translate(16 14)">
        <Ellipse fill="#D9EEEC" cx={16.1052632} cy={25.7727273} rx={16.1052632} ry={16.2272727} />
        <Path
          d="M14.056 3.174c-.44.907-.733 1.965-.879 3.174a14.261 14.261 0 00-4.832 2.267c.147.302.293.453.44.604.439.756 2.489 4.686.732 7.406-.879 1.21-2.196 1.814-3.368 2.418-.878.454-1.171 1.814-.439 2.57.44.453.879.906 1.171 1.51 1.318 1.966 1.318 4.233.293 6.5a12.182 12.182 0 007.467 3.627c-.293-1.814-.146-3.778.44-5.29.731-1.662 1.903-3.023 3.513-3.627 1.61-.605 2.928.302 3.807 1.058 1.318 1.058 2.635 1.965 5.564.302.878-1.813 1.317-3.93 1.317-6.045 0-.756-.146-1.663-.293-2.418.879-.907 1.611-1.965 2.343-3.023.586 1.813.879 3.627.879 5.44 0 9.22-7.175 16.626-16.106 16.626C7.175 36.273 0 28.867 0 19.648 0 11.184 6.15 4.232 14.056 3.174zM23.426 0c3.66 0 7.32 2.872 7.32 7.557 0 4.232-5.71 9.673-6.295 10.126-.293.302-.586.453-1.025.453-.44 0-.732-.15-1.025-.453-.103-.08-.366-.315-.73-.672l-.255-.255c-1.798-1.817-5.31-5.88-5.31-9.2 0-4.684 3.66-7.556 7.32-7.556zm0 4.534c-1.61 0-2.928 1.36-2.928 3.023 0 1.662 1.317 3.023 2.928 3.023 1.61 0 2.928-1.36 2.928-3.023s-1.318-3.023-2.928-3.023z"
          transform="translate(3.79)"
          fill="#429FA1"
          fillRule="nonzero"
        />
      </G>
    </G>
  </Svg>
);

export default GlobeWithLocationPointer;
