import {
  View,
  Button,
  SortIcon,
  FloatingMenu,
  useWindowWidth,
  useEmotionTheme,
  EmotionStyle,
} from '@talkspace/react-toolkit';
import { useState } from 'react';
import styled from '../../../core/styled';

const SortButton = styled(Button)(
  ({
    theme: {
      colors,
      window: { isMobile },
    },
  }) => {
    return {
      borderRadius: 5,
      display: 'flex',
      flexDirection: 'row',
      gap: 4,
      alignItems: 'center',
      color: colors.accessibilityGreenDark,
      border: `1px solid ${colors.accessibilityGreenDark}`,
      background: colors.white,
      fontSize: 14,
      fontWeight: 500,
      padding: '8px 15px',
      minWidth: 130,
      width: 'fit-content',
      minHeight: 36,
      height: 36,
      margin: 0,
      justifySelf: !isMobile && 'end',
      '&:hover': {
        background: colors.aliceBlue,
      },
    };
  }
);

const Wrapper = styled(View)(() => {
  return {
    position: 'relative',
    display: 'grid',
    width: 150,
    paddingRight: 2,
  };
});

export const Item = styled(View)<{ isSelected: boolean }>(({ theme: { colors }, isSelected }) => {
  return {
    color: colors.black,
    height: isSelected ? 55 : 60,
    width: isSelected ? 'calc(100% - 10px)' : '100%',
    margin: isSelected && 'auto',
    marginTop: isSelected && 6,
    marginBottom: isSelected && 6,
    borderRadius: isSelected && 7,
    padding: '12px 16px',
    cursor: 'pointer',
    background: isSelected && colors.whiteLilac,
    '&:hover': {
      background: colors.deepTeal,
      color: `${colors.white} !important`,
    },
  };
});

const getfloatingMenyStyle = (isMobile: boolean) => {
  return {
    position: 'absolute',
    left: isMobile ? 0 : -100,
    right: isMobile ? 'auto' : 0,
    top: 40,
    height: 'max-content',
    maxHeight: 'unset',
    width: 250,
    zIndex: 300,
    minWidth: 'unset',
  };
};

interface DropdownContent {
  id: string;
  title: string;
  subtitle: string;
}

interface SortDropdownProps {
  sortField: string;
  handleChangeSortField: (value: string) => void;
  dropDownContent: Array<DropdownContent>;
  buttonTitle: string;
  buttonStyles?: EmotionStyle;
  menuStyles?: EmotionStyle;
  menuItemStyles?: EmotionStyle;
}

const SortDropdown = ({
  sortField,
  handleChangeSortField,
  dropDownContent,
  buttonTitle,
  buttonStyles,
  menuStyles,
  menuItemStyles,
}: SortDropdownProps) => {
  const [isDropDownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const { isMobile } = useWindowWidth();
  const { colors } = useEmotionTheme();

  const handleSelectSortField = (selectedSortField) => {
    handleChangeSortField(selectedSortField);
    setIsDropdownOpen(false);
  };

  const floatingMenyStyle = getfloatingMenyStyle(isMobile);

  return (
    <Wrapper>
      <SortButton
        onPress={() => setIsDropdownOpen((prev) => !prev)}
        style={{ background: isDropDownOpen && colors.permaSolitude, ...buttonStyles }}
      >
        <SortIcon />
        <View>Sort:</View>
        <View style={{ fontWeight: 700 }}> {buttonTitle}</View>
      </SortButton>

      <FloatingMenu
        style={{ ...floatingMenyStyle, ...menuStyles }}
        onBackdropPress={() => setIsDropdownOpen(false)}
        hide={!isDropDownOpen}
      >
        <View>
          {dropDownContent.map((item) => (
            <Item
              style={{ ...menuItemStyles }}
              isSelected={item.id === sortField}
              onClick={() => handleSelectSortField(item.id as string)}
              key={item.id}
            >
              <View style={{ fontWeight: 700, lineHeight: '15px' }}>{item.title}</View>
              <View style={{ fontWeight: 400, fontSize: 13 }}>{item.subtitle}</View>
            </Item>
          ))}
        </View>
      </FloatingMenu>
    </Wrapper>
  );
};

export default SortDropdown;
