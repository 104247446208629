import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Select, TextAreaRounded } from '@talkspace/react-toolkit';
import Submenu from '../../../../Reusable/Submenu/Submenu';
import Button from '../../../../Reusable/Buttons/Button';
import Footer from '../../../../Reusable/Footer/Footer';
import { dischargeAndTransfer } from '../../../../../actions/RoomActions';
import { getOptionsByField } from '../../../../../utils/tsConfigsValues';

import './TransferClient.css';

const transferReasonOptions = getOptionsByField('transferReasons');

class TransferClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roomID: props.roomID,
      transferData: {
        reason: '',
        note: '',
      },
      isFormValid: false,
    };

    this.updateField = this.updateField.bind(this);
    this.getViewContent = this.getViewContent.bind(this);
    this.getFooterComponent = this.getFooterComponent.bind(this);
    this.getFooterChildren = this.getFooterChildren.bind(this);
    this.getDisplayValue = this.getDisplayValue.bind(this);
    this.dischargeAndTransfer = this.dischargeAndTransfer.bind(this);
    this.goBack = this.goBack.bind(this);
    this.isFormValid = this.isFormValid.bind(this);
  }

  goBack() {
    this.setState({ showSpinner: false }, () => this.props.history.goBack());
  }

  getDisplayValue(options, value) {
    const displayOption = options.find((option) => option.value === value);
    return displayOption ? displayOption.label : undefined;
  }

  updateField(field, value) {
    this.setState(
      {
        transferData: {
          ...this.state.transferData,
          [field]: value,
        },
      },
      this.isFormValid
    );
  }

  isFormValid() {
    this.setState({
      isFormValid: this.state.transferData.reason && this.state.transferData.note,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isUpdating !== this.props.isUpdating) {
      if (nextProps.isUpdating) {
        this.setState({
          showSpinner: nextProps.isUpdating,
        });
      }
      if (!nextProps.isUpdating) {
        setTimeout(() => {
          this.setState({
            showSpinner: nextProps.isUpdating,
          });
          this.props.history.goBack();
        }, 2000);
      }
    }
  }

  getViewContent() {
    return [
      <TextAreaRounded
        autoSize
        value={this.state.transferData.note}
        onChangeText={(newValue) => this.updateField('note', newValue)}
        placeholder="Case summary..."
        textAreaStyle={{ padding: '8px 0 0' }}
      />,
      <Select
        options={transferReasonOptions}
        value={transferReasonOptions.find(
          (option) => option.value === this.state.transferData.reason
        )}
        placeholder="Transfer reason"
        onChange={(option) => {
          this.updateField('reason', option?.value);
        }}
      />,
    ];
  }

  dischargeAndTransfer() {
    this.props.dischargeAndTransfer(this.state.roomID, this.state.transferData);
  }

  getFooterChildren() {
    return [
      <Button title="Cancel" isSecondary key={1} clickHandler={this.goBack} />,
      <Button
        title="Submit"
        key={2}
        isActive={!this.props.isRequesting}
        isSecondary={!this.state.isFormValid}
        clickHandler={() => {
          if (this.state.isFormValid) this.dischargeAndTransfer();
        }}
      />,
    ];
  }

  getFooterComponent() {
    return (
      <Footer
        key={4}
        showSpinner={this.state.showSpinner}
        isUpdating={this.props.isUpdating}
        spinnerMessage={this.props.spinnerMessage}
        isError={this.props.isError}
        childComponents={[...this.getFooterChildren()]}
      />
    );
  }

  render() {
    return (
      <Submenu
        onBack={this.goBack}
        childComponents={this.getViewContent()}
        footerComponent={this.getFooterComponent()}
        title="Transfer client"
        prevRoute="case-tab"
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    roomID: state.room.roomID,
    transferData: state.transferData,
    showSpinner: state.room.showSpinner,
    isUpdating: state.room.isUpdating,
    spinnerMessage: state.room.spinnerMessage,
    isError: state.room.isError,
  };
};

TransferClient.propTypes = {
  roomID: PropTypes.number,
  transferData: PropTypes.object,
  showSpinner: PropTypes.bool,
  isUpdating: PropTypes.bool,
  spinnerMessage: PropTypes.string,
  isError: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ dischargeAndTransfer }, dispatch);

const TransferClientContainer = connect(mapStateToProps, mapDispatchToProps)(TransferClient);

export default TransferClientContainer;
