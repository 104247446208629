import TSLogo from './TSLogo';
import TSLogo2024 from './TSLogo2024';
import { TSLogoProps } from './types';

interface TSLogoVariantsProps extends TSLogoProps {
  variant?: 'old' | '2024';
}

const TSLogoVariants = (props: TSLogoVariantsProps) => {
  const { variant, ...logoProps } = props;
  if (variant === '2024') {
    return <TSLogo2024 {...logoProps} />;
  }
  return <TSLogo {...logoProps} />;
};

export default TSLogoVariants;
