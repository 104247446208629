import { useMutation, useQueryClient } from 'react-query';
import apiHelper from '@/utils/api';
import apiWrapper from '../../utils/apiWrapper';
import { CaseConsultationNoteFormState } from '../../components/Room/CRMContainer/NotesTab/types';
import { caseConsultationNoteQueryKey, notesListQueryKey } from './queryKeys';

export interface SaveDraftCaseConsultationNoteMutateVariables
  extends CaseConsultationNoteFormState {
  roomID: string;
  clientUserID?: number;
  noteID?: string;
}

const saveDraftCaseConsultationNote = async (
  params: SaveDraftCaseConsultationNoteMutateVariables
): Promise<void> => {
  const { roomID, noteID, clientUserID, ...requestBody } = params;

  const requestURL = noteID
    ? `/v2/rooms/${roomID}/case-consultation-notes/${noteID}/draft`
    : `/v2/rooms/${roomID}/case-consultation-notes/draft`;

  const method: keyof typeof apiWrapper = noteID ? 'patch' : 'post';

  await apiWrapper[method](`${apiHelper().apiEndpoint}${requestURL}`, requestBody);
};

const useMutationSaveDraftCaseConsultationNote = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, SaveDraftCaseConsultationNoteMutateVariables>(
    saveDraftCaseConsultationNote,
    {
      onSuccess: (_, { roomID, noteID, clientUserID }) => {
        queryClient.invalidateQueries(notesListQueryKey(roomID));
        if (noteID) {
          queryClient.invalidateQueries(caseConsultationNoteQueryKey(clientUserID, noteID));
        }
      },
    }
  );
};

export default useMutationSaveDraftCaseConsultationNote;
