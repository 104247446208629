import AgoraRTC, { IAgoraRTCClient } from 'agora-rtc-sdk-ng';
import { useEffect, useRef } from 'react';
import PromiseDebouncer from '../utils/PromiseDebouncer';

const useMicrophone = ({
  agoraClient,
  isAudioOn,
  isJoined,
  onError,
  selectedAudioIn,
}: {
  agoraClient: IAgoraRTCClient | undefined;
  isAudioOn: boolean;
  isJoined: boolean;
  onError: Function;
  selectedAudioIn: MediaDeviceInfo | undefined;
}) => {
  const debouncer = useRef<PromiseDebouncer | null>(null);
  const streamingDeviceId = useRef<string | null>(null);

  useEffect(() => {
    if (!debouncer.current) {
      debouncer.current = new PromiseDebouncer();
    }

    debouncer.current.debounce(async () => {
      if (!agoraClient || !isJoined) {
        return;
      }

      try {
        const existingTrack = agoraClient?.localTracks.find((x) => x.trackMediaType === 'audio');
        const isMuting = !isAudioOn && existingTrack;
        const isUnmuting = isAudioOn && !existingTrack;
        const isChangingDevice =
          isAudioOn &&
          streamingDeviceId.current &&
          streamingDeviceId.current !== selectedAudioIn?.deviceId;

        if ((isMuting || isChangingDevice) && existingTrack) {
          await agoraClient.unpublish(existingTrack);
          existingTrack.close();
        }

        if (isUnmuting || isChangingDevice) {
          const newTrack = await AgoraRTC.createMicrophoneAudioTrack({
            microphoneId: selectedAudioIn?.deviceId,
          });

          streamingDeviceId.current = selectedAudioIn?.deviceId || null;
          await agoraClient.publish(newTrack);
        }
      } catch (error) {
        onError(error);
      }
    });
  }, [agoraClient, isAudioOn, isJoined, onError, selectedAudioIn]);
};

export default useMicrophone;
