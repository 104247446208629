import { FunctionComponent, useState, useEffect } from 'react';
import styled from '../../../core/styled';
import Brick from '../Brick';
import View from '../../../components/View';

const BricksWrapper = styled(View)({
  width: 338,
  flexFlow: 'wrap',
  justifyContent: 'center',
  alignSelf: 'center',
});

export interface BrickSelectorState {
  isSelected: boolean;
  label: string;
  value: string;
}

interface Props {
  bricks: BrickSelectorState[];
  onChange: (bricks: BrickSelectorState[], isOtherSelected: boolean) => void;
}

const BricksSelector: FunctionComponent<Props> = ({ bricks = [], onChange }) => {
  const [currentBricks, setCurrentBricks] = useState<BrickSelectorState[]>(bricks);

  function toggleBrick(brickValue: string) {
    const tempBricks: BrickSelectorState[] = [];
    let isOtherSelected = false;
    currentBricks.forEach((brick) => {
      if (brick.value === brickValue) {
        // eslint-disable-next-line no-param-reassign
        brick.isSelected = !brick.isSelected;
      }
      if (brick.value === 'other') {
        isOtherSelected = brick.isSelected;
      }
      tempBricks.push(brick);
    });
    setCurrentBricks(tempBricks);
    onChange(currentBricks, isOtherSelected);
  }

  useEffect(() => {
    setCurrentBricks(bricks);
  }, [bricks]);

  return (
    <BricksWrapper>
      {currentBricks.map((brick) => (
        <Brick
          text={brick.label}
          isSelected={brick.isSelected}
          onPress={() => toggleBrick(brick.value)}
          key={brick.value}
        />
      ))}
    </BricksWrapper>
  );
};

export default BricksSelector;
