import { Subscription } from 'ts-frontend/types';
import { ETherapistInfo } from 'ts-frontend/entities/Therapist';
import { ERoom } from 'ts-frontend/entities/Room';

import { ID_LVS_DASHBOARD_BUTTON, ID_LVS_PANEL, ID_ROOM_DETAILS_PANEL } from '@/utils/IDConstants';
import { withRouter, RouteComponentProps } from '@/core/routerLib';
import InRoomSchedulingCard from '../InRoomSchedulingCard';
import useQueryVideoCreditOffers from '../../hooks/useQueryVideoCreditOffers';
import useQueryBookings from '../../hooks/useQueryBookings';
import { LVSPathnameArray } from '../../containers/InRoomSchedulingContainer';

interface Props extends RouteComponentProps<{ roomID: string }> {
  closeRoute: string;
  numberOfRooms: number;
  subscriptions: Subscription[];
  getTherapistByRoomID(roomID: number): ETherapistInfo | undefined;
  getRoomByRoomID(roomID: number): ERoom | undefined;
}

const InRoomSchedulingDashboardTool = ({
  match,
  history,
  numberOfRooms,
  closeRoute,
  subscriptions,
  getTherapistByRoomID,
  getRoomByRoomID,
}: Props) => {
  const { data: videoCreditOffers } = useQueryVideoCreditOffers({
    roomID: +match.params.roomID,
    source: 'memberOpenChat',
  });
  const { data: bookings } = useQueryBookings({ roomID: +match.params.roomID });

  const hasAnyCredit = videoCreditOffers?.liveSessions && videoCreditOffers.liveSessions.length > 0;
  const [booking] = bookings || [];
  const bookingTherapist = getTherapistByRoomID(booking?.roomID ?? +match.params.roomID);
  const room = getRoomByRoomID(Number(match.params.roomID));

  const hasMultipleRooms = numberOfRooms > 1;

  const handleOnPress = () =>
    history.push({
      pathname: `/room/${match.params.roomID}/${closeRoute}`,
      state: {
        from: 'dashboard',
      },
    });

  const handleBubblePress = (roomID: number) => {
    if (roomID) {
      history.push({
        pathname: `/room/${roomID}/${closeRoute}`,
        state: {
          from: 'dashboard',
        },
      });
    }
  };

  const isLVSPanelOpen = () =>
    LVSPathnameArray.some((stepUrl: string) => history.location.pathname.includes(stepUrl));
  const isRoomDetailsPanelRendered = history.location.pathname.includes('room-details');
  const ariaExpanded = hasMultipleRooms ? isRoomDetailsPanelRendered : isLVSPanelOpen();
  let ariaControlsId;
  if (isLVSPanelOpen()) {
    ariaControlsId = ID_LVS_PANEL;
  } else if (isRoomDetailsPanelRendered) {
    ariaControlsId = ID_ROOM_DETAILS_PANEL;
  }

  return (
    <InRoomSchedulingCard
      actionIconType="arrow"
      id={ID_LVS_DASHBOARD_BUTTON}
      ariaExpanded={ariaExpanded}
      ariaControls={ariaControlsId}
      onPress={handleOnPress}
      onBubblePress={handleBubblePress}
      booking={booking}
      hasAnyCredit={hasAnyCredit}
      therapist={bookingTherapist}
      isEAP={room?.isEAP}
      currentRoomID={+match.params.roomID}
      roomType={room?.roomType}
      roomStatus={room?.status}
      subscriptions={subscriptions}
      isInDashboard
    />
  );
};

export default withRouter(InRoomSchedulingDashboardTool);
