import { FunctionComponent } from 'react';
import { View } from '@talkspace/react-toolkit';
import styled from '@/core/styled/styled';
import AudioPlayer from '../AudioPlayer/AudioPlayer';
import { AudioFile } from '../../types/inputTypes';

interface InputAudioViewProps {
  recordingTime?: number;
  audio: AudioFile | null;
  onCancel?: () => void | Promise<void>;
}

const Wrapper = styled(View)({
  flex: 1,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
});

const InputAudioView: FunctionComponent<InputAudioViewProps> = ({
  audio,
  onCancel,
  recordingTime,
}) => (
  <Wrapper>
    <AudioPlayer
      onCancel={onCancel}
      audioUrl={audio?.audioUrl}
      duration={audio?.duration || recordingTime || 1}
    />
  </Wrapper>
);

export default InputAudioView;
