import * as React from 'react';
import { Button, useEmotionTheme, BaseButton, Big } from '@talkspace/react-toolkit';

import { FlowVariant, TherapistTimeslot } from 'ts-frontend/types';

import { useTranslation } from '@talkspace/i18n';
import { ERoom } from '../../../ts-frontend/entities/Room';

interface SelectTimeslotActionButtonsProps {
  room?: ERoom;
  selectedTimeslot?: TherapistTimeslot | null;
  flowVariant?: FlowVariant;
  bookWithIntroSession: boolean;
  isTherapist: boolean;
  isNoAvailabilityFFOn?: boolean;
  isClosingModal: boolean;
  noTimesTopPadding: number;
  handleBookLater: (optionType: string) => void;
  handleContinuePress: () => void;
  onPressTimesNotCompatible: () => void;
  onClosePress: () => void;
}

const SelectTimeslotActionButtons = (props: SelectTimeslotActionButtonsProps) => {
  const {
    room,
    selectedTimeslot,
    flowVariant,
    bookWithIntroSession,
    isTherapist,
    isNoAvailabilityFFOn,
    isClosingModal,
    noTimesTopPadding,
    handleBookLater,
    handleContinuePress,
    onPressTimesNotCompatible,
    onClosePress,
  } = props;
  const { colors, colorRoles } = useEmotionTheme();
  const { t: tBookingScreen } = useTranslation('bookingScreen');

  if (flowVariant === 'bookAndActivate') {
    const isB2B = ['dte', 'eap', 'bh'].includes(room?.accountType || '');
    const optionText = isB2B
      ? tBookingScreen('available.noneWork', 'None of these times work for me', undefined)
      : tBookingScreen('available.bookLater', "I'll book later", undefined);
    const dataQa = isB2B ? 'noneOfTheseTimeWorksPress' : 'bookLaterPress';

    return (
      <>
        <Button
          style={{
            marginTop: 0,
            width: 335,
            backgroundColor: colorRoles.system.actionPrimaryDefault,
          }}
          disabled={!selectedTimeslot}
          onPress={handleContinuePress}
          text={tBookingScreen('available.continue', 'Continue', undefined)}
          size="medium"
          dataQa="inRoomSchedulingSelectTimeslotContinuePress"
        />
        <BaseButton
          style={{ marginTop: 14, width: 335 }}
          disabled={!selectedTimeslot}
          onPress={() => {
            handleBookLater(optionText);
          }}
          dataQa={dataQa}
        >
          <Big variant="bigBlueStoneNew">{optionText}</Big>
        </BaseButton>
      </>
    );
  }

  const secondaryBtnText =
    flowVariant === 'switchWizard'
      ? tBookingScreen('available.bookLaterButton', 'Book later', undefined)
      : tBookingScreen('available.none', 'I can’t find a time that works for me', undefined);

  return (
    <>
      <Button
        style={{
          marginTop: 0,
          width: 335,
          backgroundColor: colorRoles.system.actionPrimaryDefault,
        }}
        disabled={!selectedTimeslot}
        onPress={handleContinuePress}
        text={tBookingScreen('available.continue', 'Continue', undefined)}
        size="medium"
        dataQa="inRoomSchedulingSelectTimeslotContinuePress"
      />
      {bookWithIntroSession && (
        <BaseButton
          style={{ marginTop: isTherapist ? 20 : 10 }}
          onPress={onClosePress}
          dataQa="inRoomSchedulingSelectTimeslotReserveSessionLater"
        >
          <Big variant="bigMedium">
            {tBookingScreen('available.reserveLater', 'I’ll reserve this session later', undefined)}
          </Big>
        </BaseButton>
      )}
      {!isTherapist && isNoAvailabilityFFOn && (
        <Button
          disabled={isClosingModal}
          style={{
            backgroundColor: colorRoles.system.actionSecondaryDefault,
            color: colorRoles.system.actionSecondaryText,
            marginTop: bookWithIntroSession ? 0 : noTimesTopPadding,
            width: 335,
          }}
          text={secondaryBtnText}
          onPress={onPressTimesNotCompatible}
          dataQa="inRoomSchedulingIncompatibleTimes"
          primaryColor={colors.green}
          roundedFocusStyle
        />
      )}
    </>
  );
};

export default SelectTimeslotActionButtons;
