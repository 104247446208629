/* eslint-disable react-hooks/exhaustive-deps */
import { FunctionComponent, useState, useEffect, useRef } from 'react';

import { connect } from 'react-redux';

import { View, Text, TouchableView, Spinner } from '@talkspace/react-toolkit';
import { Route, RouteComponentProps } from '@/core/routerLib/routerLib';
import styled from '@/core/styled';
import { webOnlyStyle } from '@/core/styled/styleHelpers';
import { AppState } from '../redux/chatStore';
import {
  isSameUpdatedMessage,
  dispatchRequestScripts,
  dispatchRequestEditScript,
  dispatchRequestDeleteScript,
  dispatchRequestCreateScript,
  dispatchRequestRestoreScript,
} from '../redux/actions/scriptsActions';
import {
  ScriptMessage,
  ScriptCategory,
  ScriptMessageStatus,
  ScriptsReplacementData,
} from '../entities/Scripts';

import ScriptsEdit from '../components/ScriptsEdit';
import FloatingMenuList from '../components/FloatingMenuList';
import { COLORS } from '../utils/design';
import Edit from '../components/Icons/Edit';

interface ScriptsContainerProps {
  roomID: number;
  isError: boolean;
  userName: string;
  isUpdating: boolean;
  userPlanType: string;
  getScripts(): Promise<{}>;
  therapistDisplayName: string;
  onUseScript(text: string): void;
  scriptCategories: ScriptCategory[];
  editScript(script: ScriptMessage): Promise<{}>;
  restoreScript(script: ScriptMessage): Promise<{}>;
  deleteMessage(script: ScriptMessage): Promise<{}>;
  createScript(title: string, message: string, categoryId: number): Promise<{}>;
}

const sortAlphabetically = (scriptA: ScriptMessage, scriptB: ScriptMessage) => {
  if (scriptA.title.toLowerCase() < scriptB.title.toLowerCase()) return -1;
  if (scriptA.title.toLowerCase() > scriptB.title.toLowerCase()) return 1;
  return 0;
};

const replacementMap = {
  '{{USER_NAME}}': 'userName',
  '{{THERAPIST_NAME}}': 'therapistName',
  '{{USER_PLAN_TYPE}}': 'userPlanType',
};

const findAndReplaceReplacements = (
  message: string,
  replacements: ScriptsReplacementData
): string => {
  let finalMessage = message;
  Object.entries(replacementMap).forEach(([replaceStringWith, replaceStringTo]) => {
    if (message.includes(replaceStringWith) && replacements[replaceStringTo]) {
      const replacementGlobalRegex = new RegExp(replaceStringWith, 'g');
      finalMessage = finalMessage.replace(replacementGlobalRegex, replacements[replaceStringTo]);
    }
  });
  return finalMessage;
};

const NewScriptWrapper = styled(TouchableView)({
  position: 'absolute',
  right: 16,
});

const NewScriptText = styled(Text)(({ theme: { colors } }) => {
  return {
    opacity: 0.8,
    fontSize: 15,
    color: colors.greenText,
    fontWeight: 600,
    ...webOnlyStyle({
      opacity: 0.8,
      '&:hover': {
        opacity: 1.0,
      },
    }),
  };
});

const NewScript: FunctionComponent<{ onPress(): void }> = ({ onPress }) => (
  <NewScriptWrapper onPress={onPress}>
    <NewScriptText>New script</NewScriptText>
  </NewScriptWrapper>
);

const ScriptsContainer: FunctionComponent<ScriptsContainerProps> = ({
  therapistDisplayName,
  scriptCategories,
  restoreScript,
  userPlanType,
  deleteMessage,
  createScript,
  onUseScript,
  editScript,
  isUpdating,
  getScripts,
  userName,
  isError,
  roomID,
}) => {
  const scriptsReplacementData = new ScriptsReplacementData(
    therapistDisplayName,
    userPlanType,
    userName
  );
  const [scriptCategory, setScriptCategory] = useState<ScriptCategory>();
  const [scriptMessage, setScriptMessage] = useState<ScriptMessage>();
  useEffect(() => {
    if (scriptCategory) {
      const categoryChanged = scriptCategories.find((c) => c.id === scriptCategory.id);
      if (categoryChanged) {
        setScriptCategory(categoryChanged);
        if (scriptMessage) {
          const messageChanged = categoryChanged.messages.find((m) =>
            isSameUpdatedMessage(scriptMessage, m)
          );
          if (messageChanged) {
            setScriptMessage(messageChanged);
          }
        }
      }
    }
  }, [scriptCategories]); // TODO: Luis - Change state management to remove file eslint-disable

  const scriptCategoriesRef = useRef<ScriptCategory[]>(scriptCategories);
  useEffect(() => {
    scriptCategoriesRef.current = scriptCategories;
  }, [scriptCategories]);

  useEffect(() => {
    if (scriptCategories.length === 0) getScripts();
  }, [getScripts, roomID, scriptCategories.length]);
  const selectScript = (message: ScriptMessage) => {
    onUseScript(findAndReplaceReplacements(message.text, scriptsReplacementData));
  };

  const scriptMessages: ScriptMessage[] = scriptCategory
    ? scriptCategory.messages
        .filter((m) => m.status === ScriptMessageStatus.ACTIVE)
        .sort(sortAlphabetically)
    : [];

  const selectCategory = (selectedCategory: ScriptCategory) => setScriptCategory(selectedCategory);
  const selectMessage = (selectedMessage: ScriptMessage) => setScriptMessage(selectedMessage);
  const onNewScriptPress = (history) => {
    history.push('/create');
    setScriptMessage(undefined);
  };

  const onEdit = (newMessage: ScriptMessage) => {
    setScriptMessage(newMessage);
    return editScript(newMessage);
  };

  const onRestore = (scriptToRestore: ScriptMessage) => {
    restoreScript(scriptToRestore);
  };

  const onDelete = (scriptToDelete: ScriptMessage) => deleteMessage(scriptToDelete);

  const onCreate = (title: string, message: string) =>
    createScript(title, message, (scriptCategory as ScriptCategory).id).then(() => {
      if (!isError) {
        // Get updated scriptCategories
        const selectedCategory = scriptCategoriesRef.current.find(
          (c) => c.id === (scriptCategory as ScriptCategory).id
        );
        if (selectedCategory) {
          setScriptMessage(
            selectedCategory.messages.find((s) => s.title === title) as ScriptMessage
          );
        }
      }
    });

  if (isUpdating && scriptCategories.length === 0) {
    return (
      <View justify="center" flex={1}>
        <Spinner isLoading />
      </View>
    );
  }

  const ScriptsRowWrapper = styled(TouchableView)(({ theme: { colors } }) => {
    return {
      flexShrink: 0,
      lineHeight: 20,
      textAlign: 'left',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      ...webOnlyStyle({
        cursor: 'pointer',
        lineHeight: '20px',
        '&:hover': {
          backgroundColor: colors.green,
        },
      }),
    };
  });

  const ScriptsText = styled(Text)({
    padding: 16,
    paddingRight: 0,
    width: '100%',
    ...webOnlyStyle({
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    }),
  });

  return (
    <>
      <Route
        exact
        path="/"
        component={(props: RouteComponentProps) => (
          <FloatingMenuList<ScriptCategory>
            {...props}
            textKey="name"
            nextRoute="/messages"
            title="Script Categories"
            items={scriptCategories}
            onPress={selectCategory}
          />
        )}
      />
      <Route
        exact
        path="/messages"
        component={(props: RouteComponentProps) => (
          <FloatingMenuList<ScriptMessage>
            {...props}
            title="Scripts"
            showBackArrow
            textKey="title"
            nextRoute="/edit"
            items={scriptMessages}
            onPress={selectMessage}
            renderRow={(item: ScriptMessage) => (
              <ScriptsRowWrapper className="scripts-row-wrapper" onPress={() => selectScript(item)}>
                <ScriptsText className="scripts-text">{item.title}</ScriptsText>
                <TouchableView
                  styles={{
                    height: '100%',
                    padding: 10,
                    paddingRight: 15,
                    justifyContent: 'center',
                    ...webOnlyStyle({
                      transform: 'scale(1)',
                      transition: 'all 0.2s',
                      '&:hover': {
                        transform: 'scale(1.1)',
                      },
                    }),
                  }}
                  onPress={() => {
                    props.history.push('/edit');
                    selectMessage(item);
                  }}
                >
                  <Edit color={COLORS.white} />
                </TouchableView>
              </ScriptsRowWrapper>
            )}
            RightComponent={() => <NewScript onPress={() => onNewScriptPress(props.history)} />}
          />
        )}
      />
      <Route
        exact
        path="/create"
        component={(props: RouteComponentProps) => (
          <ScriptsEdit
            {...props}
            newScript
            onEdit={onEdit}
            onCreate={onCreate}
            onDelete={onDelete}
            loading={isUpdating}
            onRestore={onRestore}
            selectScript={selectScript}
            scriptMessage={(scriptMessage || {}) as ScriptMessage}
            scriptsReplacementData={scriptsReplacementData}
          />
        )}
      />
      <Route
        exact
        path="/edit"
        component={(props: RouteComponentProps) => (
          <ScriptsEdit
            {...props}
            onEdit={onEdit}
            onCreate={onCreate}
            onDelete={onDelete}
            loading={isUpdating}
            onRestore={onRestore}
            selectScript={selectScript}
            scriptMessage={scriptMessage as ScriptMessage}
            scriptsReplacementData={scriptsReplacementData}
          />
        )}
      />
    </>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    isError: state.scripts.isError,
    isUpdating: state.scripts.isUpdating,
    scriptCategories: state.scripts.scriptCategories,
  };
};
const mapDispatchToProps = {
  getScripts: dispatchRequestScripts,
  editScript: dispatchRequestEditScript,
  createScript: dispatchRequestCreateScript,
  deleteMessage: dispatchRequestDeleteScript,
  restoreScript: dispatchRequestRestoreScript,
};
const ScriptsContainerWithRedux = connect(mapStateToProps, mapDispatchToProps)(ScriptsContainer);

export default ScriptsContainerWithRedux;
