import { forwardRef, CSSProperties, useState } from 'react';
import * as React from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import { ExtraTiny } from '../Typography';
import styled, { EmotionStyle, useEmotionTheme } from '../../core/styled';
import View from '../View';
import commonStyles from '../../constants/commonStyles';

const DEFAULT_LIMIT = 5000;
const { roundedFocusRing } = commonStyles;

export interface TextAreaProps extends TextareaAutosize.RequiredProps {
  onChangeText(value: string): void;
  onSubmitEditing?(e: React.KeyboardEvent<HTMLTextAreaElement>): void;
  numberOfLines?: number;
  multiline?: boolean;
  disabled?: boolean;
  style?: EmotionStyle;
  textAreaStyle?: CSSProperties;
  scrollViewStyle?: CSSProperties;
  dataQa?: string;
  hasLimit?: boolean;
  limit?: number;
  roundedFocusStyle?: boolean;
  ariaDescribedBy?: string;
  autoSize?: boolean;
  rows?: number;
  shouldDisplayBanner?: boolean;
  banner?: React.ReactNode;
  topBanner?: React.ReactNode;
  shouldDisplayTopBanner?: boolean;
}

const TextAreaWrapper = styled(View)<{
  disabled?: boolean;
  autoSize?: boolean;
  isFocused?: boolean;
}>(({ disabled, autoSize, isFocused, theme: { colors } }) => {
  let borderColor = colors.periwinkleGrey;
  if (isFocused) {
    borderColor = colors.darkBlue;
  }
  if (disabled) {
    borderColor = colors.permaDesertStorm;
  }
  return {
    fontSize: 16,
    paddingRight: 6,
    borderWidth: 1,
    borderRadius: 9,
    caretColor: colors.darkGreen,
    borderColor,
    backgroundColor: disabled ? colors.permaDesertStorm : colors.white,
    minHeight: 138,
    height: autoSize ? 'auto' : 138,
    borderStyle: 'solid',
    'textarea::placeholder': {
      color: colors.slateGrey,
    },
  };
});

const CharsCounterWrapper = styled(View)({
  alignSelf: 'flex-end',
});

const ScrollView = styled.div(({ theme: { colors } }) => {
  return {
    overflowY: 'auto',
    height: '100%',
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
      width: 10,
    },
    '&::-webkit-scrollbar': {
      width: 4,
      borderRadius: 2,
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-button': {
      width: 0,
      height: 0,
      display: 'none',
    },
    '&::-webkit-scrollbar-corner': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 200,
      backgroundClip: 'padding-box',
      backgroundColor: colors.extraLightGrey,
    },
    '::-webkit-scrollbar-track-piece': {
      backgroundColor: 'transparent',
      margin: 6,
    },
  };
});

const RoundedFocusWrapper = ({ showView, disabled, isFocused, children }) => {
  const { colors } = useEmotionTheme();
  if (!showView) return children;

  return (
    <View
      style={{
        borderRadius: 9,
        ...roundedFocusRing(disabled ? colors.permaDesertStorm : colors.periwinkleGrey, isFocused),
      }}
    >
      {children}
    </View>
  );
};

const TextAreaRounded = forwardRef<
  HTMLTextAreaElement,
  React.ComponentPropsWithoutRef<'textarea'> & TextAreaProps
>(
  (
    {
      onChangeText,
      numberOfLines,
      onSubmitEditing,
      multiline,
      disabled,
      style = {},
      textAreaStyle = {},
      scrollViewStyle = {},
      css,
      dataQa,
      hasLimit = false,
      limit = DEFAULT_LIMIT,
      value,
      roundedFocusStyle,
      ariaDescribedBy,
      autoSize,
      rows = 5,
      shouldDisplayBanner = false,
      banner,
      topBanner,
      shouldDisplayTopBanner,
      ...otherProps
    },
    ref
  ) => {
    const [isFocused, setIsFocused] = useState(false);

    const onBlur = () => setIsFocused(false);

    const onFocus = () => setIsFocused(true);

    const onChange = ({ target }: React.ChangeEvent<HTMLTextAreaElement>) => {
      onChangeText(target.value);
    };

    const onKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === 'Enter' && (e.metaKey || e.ctrlKey) && onSubmitEditing) {
        onSubmitEditing(e);
      }
    };

    const maxLength = hasLimit ? limit : undefined;

    return (
      <>
        <RoundedFocusWrapper showView={roundedFocusStyle} disabled={disabled} isFocused={isFocused}>
          <TextAreaWrapper
            style={style}
            onFocus={onFocus}
            autoSize={autoSize}
            isFocused={isFocused}
          >
            {shouldDisplayTopBanner && topBanner}
            <ScrollView style={scrollViewStyle}>
              <TextareaAutosize
                data-qa={dataQa}
                aria-describedby={ariaDescribedBy}
                type="text"
                async
                rows={rows}
                ref={ref}
                onBlur={onBlur}
                onChange={onChange}
                onKeyDown={onKeyDown}
                maxRows={numberOfLines}
                disabled={disabled}
                maxLength={maxLength}
                value={value}
                {...otherProps}
                style={{
                  fontFamily: "'Roboto', sans-serif",
                  border: 'none',
                  fontSize: 16,
                  resize: 'none',
                  marginTop: 12,
                  marginLeft: 20,
                  outline: '0px none',
                  width: '87%',
                  ...textAreaStyle,
                }}
              />
            </ScrollView>
            {shouldDisplayBanner && banner}
          </TextAreaWrapper>
        </RoundedFocusWrapper>
        {hasLimit && !disabled && (
          <CharsCounterWrapper>
            <ExtraTiny>
              {(value as string)?.length || 0}/{maxLength}
            </ExtraTiny>
          </CharsCounterWrapper>
        )}
      </>
    );
  }
);

export default TextAreaRounded;
