import { FunctionComponent } from 'react';
import View from '../View';
import { useWindowWidthState } from '../../hooks/windowWidthContext';
import { EmotionStyle } from '../../core/styled';

const ContentBackWrapper: FunctionComponent<{ style?: EmotionStyle }> = ({ style, children }) => {
  const { isMobile } = useWindowWidthState();

  return <View style={{ display: isMobile ? 'none' : 'inline', ...style }}>{children}</View>;
};

export default ContentBackWrapper;
