import { Button, ExtraHuge, Spinner, Standard, View } from '@talkspace/react-toolkit';
import { VoidFunctionComponent } from 'react';
import { trackContinueWithBookingSuccessfulPayment } from 'ts-analytics/mixpanel/events';
import { useIsFetching } from 'react-query';
import { ChargeType } from 'ts-frontend/types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useNewMemberNav } from 'launchDarkly/hooks';
import { ETherapistInfo } from 'ts-frontend/entities/Therapist';
import styled from '@/core/styled';
import { useHistory, useLocation, useParams } from '@/core/routerLib';
import { GreenCircledCheckbox } from '../icons';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import { clientTransactionsQueryKey } from '../../utils/queryKeys';

type TherapistInfo = Pick<ETherapistInfo, 'id' | 'firstName'>;

interface Props {
  clientUserID: number;
  therapist?: TherapistInfo;
}

const SuccessMessage = styled(Standard)({
  marginBottom: 28,
  fontWeight: 400,
  fontSize: 16,
  textAlign: 'center',
});

const PaymentWizardSuccess: VoidFunctionComponent<Props> = ({ clientUserID, therapist }) => {
  const history = useHistory();
  const useNewNav = useNewMemberNav();
  const { roomID } = useParams<{ roomID: string }>();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const source = params.get('source');
  const bookingID = params.get('bookingID');
  const closeModal = useCloseModal();

  const { pendingInvoicesChargeTypes } =
    useFlags<{ pendingInvoicesChargeTypes: Array<ChargeType> }>();

  const isFetchingPendingInvoices: boolean =
    useIsFetching(
      clientTransactionsQueryKey({
        clientUserID,
        chargeTypes: pendingInvoicesChargeTypes,
      })
    ) > 0;

  const handleContinuePress = () => {
    if (source === 'pendingInvoicesBanner') {
      closeModal();
      return;
    }
    if (source === 'updatePaymentMethod' || source === 'updatePaymentMethodWithPendingInvoices') {
      if (useNewNav) {
        closeModal({
          navigateTo: 'manageSubscription',
          metadata: { roomID: Number(roomID) },
        });
      } else {
        closeModal();
      }

      return;
    }
    if (source === 'confirmBooking' && bookingID) {
      history.push(
        `/in-room-scheduling/room/${roomID}/confirm-booking/booking/${bookingID}?batch=true`
      );
      return;
    }
    if (source === 'rescheduleBooking' && bookingID) {
      history.push(`/in-room-scheduling/room/${roomID}/select-timeslot`, {
        isReschedule: true,
      });
      return;
    }
    if (source === 'cancelSession' && bookingID) {
      history.push(`/in-room-scheduling/room/${roomID}/select-timeslot`, {
        isReschedule: true,
        selectedBookingID: bookingID,
      });
      return;
    }
    if (therapist?.id) {
      trackContinueWithBookingSuccessfulPayment({
        userID: clientUserID,
        roomID: Number(roomID),
        providerID: therapist.id,
      });
    }
    history.push(`/in-room-scheduling/room/${roomID}`);
  };

  let successMessage;
  let buttonDataQa;
  let buttonText;
  let successTitle;

  if (source === 'pendingInvoicesBanner') {
    successTitle = 'Pending invoices were paid successfully.';
    successMessage = 'You can now return to your room.';
    buttonDataQa = 'pendingInvoicesReturnToRoom';
    buttonText = 'Return to room';
  } else if (source === 'updatePaymentMethod') {
    successTitle = '';
    successMessage = (
      <span>
        Your payment details were updated. <br /> Your account will be updated shortly.
      </span>
    );
    buttonDataQa = 'updatePaymentMethodReturnToRoom';
    buttonText = 'Done';
  } else if (source === 'updatePaymentMethodWithPendingInvoices') {
    successTitle = 'Success!';
    successMessage = 'Your invoices were paid and your payment method is updated.';
    buttonDataQa = 'updatePaymentMethodWithPendingInvoicesReturnToRoom';
    buttonText = 'Done';
  } else if (source === 'rescheduleBooking' || source === 'cancelSession') {
    successTitle = 'Pending invoices were paid successfully.';
    successMessage = 'You can now continue rescheduling your session.';
    buttonDataQa = 'pendingInvoicesRescheduleSession';
    buttonText = 'Continue with rescheduling';
  } else {
    successTitle = 'Pending invoices were paid successfully.';
    successMessage = 'You can now continue booking your session.';
    buttonDataQa = 'inRoomSchedulerSelectContinueWithBooking';
    buttonText = 'Continue with booking';
  }

  if (isFetchingPendingInvoices) {
    return <Spinner />;
  }

  return (
    <View align="center" style={{ width: 300 }}>
      <View style={{ marginTop: 30, marginBottom: 20 }}>
        <GreenCircledCheckbox />
      </View>
      <ExtraHuge style={{ marginBottom: 20, textAlign: 'center' }}>{successTitle}</ExtraHuge>
      <SuccessMessage>{successMessage}</SuccessMessage>
      <Button
        dataQa={buttonDataQa}
        style={{ width: 300 }}
        onPress={handleContinuePress}
        text={buttonText}
      />
    </View>
  );
};

export default PaymentWizardSuccess;
