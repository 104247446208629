export const reminderTypeAndTargetMapping = {
  1: 'missing_conditions_day_7',
  2: 'missing_conditions_day_10',
  3: 'missing_conditions_day_14_blocking',
  4: 'missing_first_message_hour_8',
  5: 'missing_first_media_message_hour_24',
  6: 'missing_response_to_client_message_hour_8',
  7: 'inadequate_word_count_day_2',
  8: 'inadequate_word_count_day_5',
  105: 'summarization_session',
  106: 'intro_missing',
  107: 'expectation_setting_missing',
  108: 'diagnostic_profile',
  115: 'working_diagnosis',
  111: 'outcome_measure_completed',
  116: 'si_risk_flag',
};

export const notExpandbleReminders = [105, 111, 116];
export const remindersWithoutFeedback = [111, 116];
