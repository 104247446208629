import { FunctionComponent } from 'react';
import Svg, { Path } from 'svgs';
import { EmotionStyle, useEmotionTheme } from '../../core/styled';

export interface InsuranceCardIconProps {
  width?: number;
  height?: number;
  style?: EmotionStyle;
}

const InsuranceCardIcon: FunctionComponent<{
  color?: string;
  width?: string | number;
  height?: string | number;
  style?: EmotionStyle;
  dataQa?: string;
}> = ({ width = 20, height = 15, color, style, dataQa, ...otherProps }) => {
  const titleText = 'Insurance card icon';
  const { colors } = useEmotionTheme();
  return (
    <Svg
      title={titleText}
      aria-label={titleText}
      width={width}
      height={height}
      viewBox="0 0 20 15"
      data-qa={dataQa}
      style={style}
      {...otherProps}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 11.9c0 .607-.448 1.1-1 1.1H3c-.552 0-1-.493-1-1.1V3.1C2 2.493 2.448 2 3 2h14c.552 0 1 .493 1 1.1v8.8ZM1.955 0C.85 0 0 1.029 0 2.211v10.715C0 14.11.85 15 1.955 15h16C19.059 15 20 14.11 20 12.926V2.21C20 1.03 19.059 0 17.955 0h-16Z"
        fill={color ?? colors.permaSanJuan}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.378 3.955a1.5 1.5 0 1 1-.001 3.001 1.5 1.5 0 0 1 0-3ZM14.667 5.974h-4.334C9.597 5.974 9 5.528 9 4.977c0-.55.597-.997 1.333-.997h4.334c.736 0 1.333.447 1.333.997s-.597.997-1.333.997ZM14.667 10.974l-9.334-.022c-.736 0-1.333-.447-1.333-.997s.597-.997 1.333-.997l9.334.023c.736 0 1.333.446 1.333.996s-.597.997-1.333.997Z"
        fill={color ?? colors.permaSanJuan}
      />
    </Svg>
  );
};

export default InsuranceCardIcon;
