import StickyDrawer, { StickyDrawerProps } from './StickyDrawer';
import { useWindowWidthState } from '../../../hooks/windowWidthContext';
import { getScreenSafeAreaInsets } from '../../../utils/safeAreaInsets';

const StickyDrawerV1 = (props: StickyDrawerProps) => {
  const { contentWrapperStyle } = props;
  const { isMobile } = useWindowWidthState();
  const safeAreaInsets = getScreenSafeAreaInsets();

  return (
    <StickyDrawer
      {...props}
      contentWrapperStyle={{
        padding: isMobile ? `16px 20px ${24 + safeAreaInsets.bottom}px` : '24px 20px',
        gap: 8,
        ...contentWrapperStyle,
      }}
    />
  );
};

export default StickyDrawerV1;
