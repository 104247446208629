import { forwardRef, MouseEventHandler, useState } from 'react';
import * as React from 'react';
import styled from '@emotion/styled';

import commonStyles, { COLORS } from '../../../constants/commonStyles';
import { useShareForwardedRef } from '../../../hooks/a11yHelper';

const { crossBrowserFocusRing, roundedFocusRing } = commonStyles;

export interface BaseButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  onPress?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  className?: string;
  onKeyDown?: (e: React.KeyboardEvent<HTMLButtonElement>) => void;
  primaryColor?: string;
  roundedFocusStyle?: boolean;
  changeCursorWhenDisabled?: boolean;
}

const BaseButtonComponent = styled.button<{
  primaryColor?: string;
  shouldShowFocusOutline?: boolean;
  roundedFocusStyle?: boolean;
  dataQa?: string;
  disabled?: boolean;
  changeCursorWhenDisabled?: boolean;
}>(
  ({
    primaryColor,
    shouldShowFocusOutline,
    roundedFocusStyle,
    disabled,
    changeCursorWhenDisabled,
  }) => {
    // necessary for applying the new rounded focus ring but rendering the standard focus outline by default
    let keyboardFocusStyles = {};
    if (shouldShowFocusOutline) {
      if (primaryColor && roundedFocusStyle) {
        keyboardFocusStyles = roundedFocusRing(primaryColor, shouldShowFocusOutline);
      } else {
        keyboardFocusStyles = crossBrowserFocusRing;
      }
    }
    const cursorStyle = {
      cursor: disabled && changeCursorWhenDisabled ? 'not-allowed !important' : 'pointer',
    };
    return {
      borderWidth: 0,
      alignItems: 'center',
      justifyContent: 'center',
      color: COLORS.black,
      fontSize: 17,
      fontFamily: "'Roboto', sans-serif",
      padding: 0,
      margin: 0,
      backgroundColor: 'transparent',
      lineHeight: 'unset !important', // override bootstrap style
      ...cursorStyle,
      outline: 'none',
      ...keyboardFocusStyles,
      // prevents focus styling from briefly occurring on click (seems to happen randomly)
      '&:hover:focus': {
        boxShadow: 'none',
        outline: 'none',
      },
    };
  }
);

const BaseButton = forwardRef<
  HTMLButtonElement,
  BaseButtonProps & React.ComponentProps<typeof BaseButtonComponent>
>((props, ref) => {
  const {
    children,
    onPress,
    onKeyDown,
    disabled,
    className,
    primaryColor,
    roundedFocusStyle,
    type,
    dataQa,
    changeCursorWhenDisabled = false,
    ...otherProps
  } = props;
  const [isFocused, setIsFocused] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const buttonRef = useShareForwardedRef(ref);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined is the proper type for HTMLButton onClick but this doesn't allow for event.detail
  const handlePress = (event: MouseEvent<HTMLButtonElement, MouseEvent>) => {
    // HTMLButton elements seem to generate a click event when selected via enter or space keys. Event.detail indicates the number of clicks that caused the event to fire so if > 0 a clicked occurred as opposed to a keypress
    if (event.detail > 0 && buttonRef && buttonRef.current) {
      setIsClicked(true);
    }
    if (onPress && !disabled && typeof onPress === 'function') onPress(event);
    // html buttons with type="submit" don't seen to register blur events when other elements are programmatically focused after submit, as with invalid fields in a form
    // this ensures that the keyboard focus styling of the button is removed if another element receives focus
    if (type && type === 'submit') buttonRef.current?.blur();
  };

  const onFocus = () => {
    setIsFocused(true);
  };
  const onBlur = () => {
    setIsFocused(false);
    setIsClicked(false);
  };

  return (
    <BaseButtonComponent
      shouldShowFocusOutline={isFocused && !isClicked}
      ref={buttonRef}
      onClick={handlePress}
      onFocus={onFocus}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      disabled={disabled}
      changeCursorWhenDisabled={changeCursorWhenDisabled}
      className={className}
      primaryColor={primaryColor}
      roundedFocusStyle={roundedFocusStyle}
      data-qa={dataQa}
      type={type}
      {...otherProps}
    >
      {children}
    </BaseButtonComponent>
  );
});

export default BaseButton;
