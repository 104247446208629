import { FunctionComponent } from 'react';
import { BackNextButton, View, CloseButton } from '@talkspace/react-toolkit';
import { withRouter } from 'react-router';
import { keyframes } from '@emotion/core';
import ReactFrameService from '@/auth/reactFrame/ReactFrameService';
import { RouteComponentProps } from '../../../core/routerLib';
import { useCloseModal } from '../../../modules/utils/ModalsContextProvider';
import styled, { EmotionStyle } from '../../../core/styled';

interface SubMenuProps extends RouteComponentProps {
  childComponents: JSX.Element[];
  title?: string;
  titleComponent?: JSX.Element;
  bodyStyle?: EmotionStyle;
  onBack?: () => void;
  prevRoute?: string;
  prevState?: Record<string, unknown>;
  topLeftComponent?: JSX.Element;
  footerComponent?: JSX.Element;
  childComponentsWrapperStyle?: EmotionStyle;
  onBackAlt?: () => void;
  onClose?: () => void;
  caretColor?: string;
  showBackButton?: boolean;
  showCloseButton?: boolean;
}

const slideInFromRight = keyframes({
  '0%': {
    transform: 'translateX(10%)',
  },
  '100%': {
    transform: 'translateX(0)',
  },
});

const slideBodyInFromRight = keyframes({
  '0%': {
    opacity: 0,
    transform: 'translateX(10%)',
  },
  '70%': {
    opacity: 0,
    transform: 'translateX(10%)',
  },
  '100%': {
    opacity: 1,
    transform: 'translateX(0)',
  },
});

const SubMenuContainer = styled(View)({
  backgroundColor: 'white',
  maxWidth: '100%',
  display: 'flex',
  height: '100%',
  overflowX: 'hidden',
  flexDirection: 'column',
  '.dropdown.icon': {
    display: 'none !important',
    marginLeft: '2em',
  },
});

const SubMenuHeader = styled(View)({
  alignItems: 'center',
  height: 60,
  padding: 20,
  backgroundColor: 'white',
  borderBottom: '1px solid #d8d8d8',
  boxShadow: '0px 0.2px 0.2px #d8d8d8',
  justifyContent: 'center',
  display: 'flex',
});

const SubMenuTitleArrow = styled(View)({
  justifyContent: 'space-between',
  display: 'flex !important',
  width: '100%',
  maxWidth: 355,
  flexDirection: 'row',
  alignItems: 'center',
});

const SubMenuTitle = styled(View)(({ hasMarginTop }: { hasMarginTop: boolean }) => {
  return {
    animation: `0.6s cubic-bezier(0, 0.53, 0, 0.99) 0s 1 ${slideInFromRight}`,
    width: 'auto',
    textAlign: 'center',
    fontWeight: 600,
    fontSize: 17,
    whiteSpace: 'nowrap',
  };
});

const SubMenuBody = styled(View)({
  padding: '25px 15px 25px 15px',
  animation: `0.8s cubic-bezier(0, 0.53, 0, 0.99) 0s 1 ${slideBodyInFromRight}`,
  flex: 1,
  overflowY: 'auto',
  overflowX: 'hidden',
  alignItems: 'center',
  '::-webkit-scrollbar': {
    visibility: 'hidden',
    width: 5,
  },
  '::-webkit-scrollbar-thumb': {
    visibility: 'hidden',
  },
  '::-webkit-scrollbar-track': {
    visibility: 'hidden',
  },
  '&:hover::-webkit-scrollbar': {
    visibility: 'visible',
  },
  '&:hover::-webkit-scrollbar-thumb': {
    visibility: 'visible',
  },
  '&:hover::-webkit-scrollbar-track': {
    visibility: 'visible',
  },
});

const Submenu: FunctionComponent<SubMenuProps> = ({
  history,
  childComponents,
  title = '',
  bodyStyle = {},
  onBack = undefined,
  prevRoute = undefined,
  prevState = {},
  topLeftComponent = null,
  footerComponent = undefined,
  titleComponent,
  childComponentsWrapperStyle = {},
  onBackAlt,
  onClose,
  caretColor,
  showBackButton = true,
  showCloseButton = false,
}) => {
  const closeModal = useCloseModal();
  const childComponentsArr = childComponents.map((childComponent, id) => (
    // eslint-disable-next-line react/no-array-index-key
    <View style={{ ...childComponentsWrapperStyle, maxWidth: 374, width: '100%' }} key={id}>
      {childComponent}
    </View>
  ));

  const handleBackButtonPress = () => {
    if (onBackAlt) {
      onBackAlt();
    } else if (onBack) {
      onBack();
    } else if (prevRoute) {
      history.replace(prevRoute, prevState);
    } else {
      history.goBack();
    }
  };

  const handleCloseButtonPress = () => {
    if (onClose) {
      onClose();
    } else if (ReactFrameService.instance().isInFrame()) {
      closeModal();
    } else {
      history.goBack();
    }
  };

  return (
    <SubMenuContainer>
      <SubMenuHeader>
        <SubMenuTitleArrow>
          {showBackButton ? (
            <BackNextButton
              onPress={handleBackButtonPress}
              width={8}
              height={13}
              style={{ padding: '4px 8px 0' }}
              caretColor={caretColor}
            />
          ) : (
            <View style={{ width: 35, height: 19 }} />
          )}
          <SubMenuTitle hasMarginTop={!titleComponent}>{titleComponent || title}</SubMenuTitle>
          {topLeftComponent}
          {showCloseButton ? (
            <CloseButton
              onPress={handleCloseButtonPress}
              aria-label="close session scheduler"
              data-qa="schedulerSubmenuCloseBtn"
              style={{ marginTop: 6 }}
              backgroundColor="transparent"
            />
          ) : (
            <View style={{ width: 35, height: 35 }} />
          )}
        </SubMenuTitleArrow>
      </SubMenuHeader>
      <SubMenuBody style={bodyStyle}>{childComponentsArr}</SubMenuBody>
      {footerComponent}
    </SubMenuContainer>
  );
};

export default withRouter(Submenu);
