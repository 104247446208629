import {
  View,
  TouchableView,
  ThreeDots,
  FloatingMenu,
  useWindowWidth,
} from '@talkspace/react-toolkit';
import { useState } from 'react';
import styled from '../../../../core/styled';
import IDViewButton, { IDContainer, IDWrapper } from './IDViewButton';
import IDView from '../../CaseLoadMenu/IDView';

interface TaskNoteProps {
  taskID: number | string;
  roomID: number;
  clientUserID: number;
  therapistID: number;
  task: {
    sessionReportID: number | null;
    caseID: number | null;
    draftProgressNoteID?: number;
    bookingID?: number;
  };
}

interface TaskActionsProps {
  clientName: string;
  isHovering: boolean;
  note: TaskNoteProps;
}

const SmallButtonsContainer = styled(View)(({ theme: { colors } }) => {
  return {
    borderRadius: 6,
    background: colors.white,
    padding: 5,
    border: `1px solid ${colors.permaLondonGray}`,
    marginRight: 10,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 30,
    display: 'flex',
  };
});

const Item = styled(TouchableView)(
  ({
    theme: {
      colors,
      window: { isDesktop },
    },
  }) => {
    if (isDesktop) {
      return {};
    }
    return {
      padding: '12px 16px',
      color: colors.accessibilityGreenDark,
      fontSize: 13,
      fontWeight: 500,
      width: '100%',
      gap: 10,
      '&:hover': {
        background: colors.deepTeal,
        color: colors.white,
      },
    };
  }
);

const ThreeDotsContainer = styled(TouchableView)<{ isOpen: boolean }>(
  ({ theme: { colors }, isOpen }) => {
    return {
      marginTop: 6,
      width: 24,
      height: 24,
      borderRadius: 6,
      background: isOpen && colors.aquaSqueeze,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 5,
      position: 'relative',
    };
  }
);

const getFloatingMenuStyle = (isLarge: boolean) => {
  return {
    position: 'absolute',
    left: -220,
    top: 30,
    height: 'fit-content',
    maxHeight: 'unset',
    width: 246,
    zIndex: 300,
    minWidth: 'unset',
  };
};

const TaskActions = ({ clientName, isHovering, note }: TaskActionsProps) => {
  const [isDorpdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [isClientIDOpen, setIsClientIDOpen] = useState<boolean>(false);
  const [hoveredItem, setHoveredItem] = useState<'clientID' | 'reopen' | null>(null);
  const { isDesktop, isLarge } = useWindowWidth();

  const { roomID, clientUserID } = note;

  const floatingMenuStyle = getFloatingMenuStyle(isLarge);

  const handleCloseThreeDotsDropdown = () => {
    setIsDropdownOpen(false);
  };

  const renderMobileView = () => (
    <ThreeDotsContainer isOpen={isDorpdownOpen} onPress={() => setIsDropdownOpen((prev) => !prev)}>
      <ThreeDots height={18} width={6} color="black" />
      <FloatingMenu
        onBackdropPress={handleCloseThreeDotsDropdown}
        hide={!isDorpdownOpen}
        style={floatingMenuStyle}
      >
        <Item
          onMouseEnter={() => setHoveredItem('clientID')}
          onMouseLeave={() => setHoveredItem(null)}
          align="center"
          row
          onPress={() => {
            setIsClientIDOpen(true);
            handleCloseThreeDotsDropdown();
          }}
        >
          <IDWrapper isHovering={hoveredItem === 'clientID'}>
            <IDContainer isHovering={hoveredItem === 'clientID'}>ID</IDContainer>
          </IDWrapper>
          <View>View client ID</View>
        </Item>
      </FloatingMenu>
    </ThreeDotsContainer>
  );

  return (
    <View onClick={(e) => e.preventDefault()}>
      <View row style={{ justifyContent: 'end', marginRight: isLarge && 10 }}>
        {isHovering && isDesktop && (
          <SmallButtonsContainer row style={{ width: 30 }}>
            <IDViewButton roomID={roomID} clientName={clientName} clientUserID={clientUserID} />
          </SmallButtonsContainer>
        )}
      </View>
      {!isDesktop && <>{renderMobileView()}</>}
      <IDView
        floatingMenuStyle={{ left: 'auto', right: 10, top: 45 }}
        roomID={roomID}
        pseudonym={clientName}
        userID={clientUserID}
        isHovering
        isMenuOpen={isClientIDOpen}
        shouldDisplayIcon={false}
        handleCloseMenu={() => setIsClientIDOpen(false)}
      />
    </View>
  );
};

export default TaskActions;
