import { LiveSessionModality, VideoCreditOffer } from 'ts-frontend/types';

import { EventProperties, TherapistWebEventProperties, FunnelName } from './trackerTypes';
import {
  trackEvent as internalTracker,
  setTrackerUserID,
  setAlias,
  increment,
  setSuperProperty,
} from './eventTracker';
import ReactFrameService from '../../auth/reactFrame/ReactFrameService';

type Platforms = 'web' | 'iOS' | 'Android';

export const getPlatform = (): Platforms => {
  if (ReactFrameService.isAndroid()) return 'Android';
  if (ReactFrameService.isiOS()) return 'iOS';
  return 'web';
};

export const trackEvent = <T extends keyof TherapistWebEventProperties>(
  eventName: T,
  eventProperties: TherapistWebEventProperties[T]
) => {
  internalTracker(eventName, eventProperties as EventProperties, []);
};

export const alias = (newID?: string, existingID?: string) => {
  setAlias(newID, existingID, []);
};
export const incrementLifetimeMonth = (by: number) => {
  increment('Lifetime Months', by, []);
};

export const identify = (userID: string | number) => {
  setTrackerUserID(userID);
};

export const setFlowSuperProperty = (flowId: string) => {
  setSuperProperty(
    {
      Platform: getPlatform(),
      'Page Name': 'Quickmatch Home',
      'Flow ID': flowId,
    },
    []
  );
};

// NOOP trackers to fix lint problems in shared/*
export const trackVisitTherapistScheduler = (
  funnelName: FunnelName,
  therapistID: number,
  roomID: number,
  creditType: VideoCreditOffer['type'] | undefined,
  sessionModality,
  isTherapist: boolean,
  noAvailability: boolean
) => {};
export const trackSelectScheduleModality = (therapistID: number, roomID?: number) => {};
export const trackSelectScheduleDuration = (
  modality: LiveSessionModality,
  roomID?: number,
  selectScheduleDurationMinutes?: number,
  creditType?: VideoCreditOffer['type'],
  planID?: number
) => {};

// NOOP trackers for stepWizard
export const trackWizardEvent = (action: string, eventCategory: string, eventProperties = {}) => {};
export const trackWizardExitEvent = (wizardName: string = '', clientID: number | null = null) => {};
