import Button, { ButtonProps } from './Button';
import { useEmotionTheme } from '../../../core/styled';

type ButtonV1Props = Omit<ButtonProps, 'primaryColor'>;
const ButtonV1 = (props: ButtonV1Props) => {
  const { colorRoles } = useEmotionTheme();
  const { style } = props;
  return (
    <Button
      {...props}
      stretch
      style={{
        marginTop: 0,
        maxWidth: 335,
        primaryColor: colorRoles.system.actionPrimaryDefault,
        ...style,
      }}
    />
  );
};

export default ButtonV1;
