import { useQuery } from 'react-query';
import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';

import { videoCallsHistoryQueryKey } from './queryKeys';
import { VideoCallHistoryItem } from './types';

interface FetchVideoCallsHistoryParams {
  roomID: number;
  clientUserID: number;
}

const fetchVideoCallsHistory =
  ({ roomID, clientUserID }: FetchVideoCallsHistoryParams) =>
  async (): Promise<VideoCallHistoryItem[]> => {
    const { data } = await apiWrapper.get(
      `${apiHelper().apiEndpoint}/api/v1/rooms/${roomID}/video-calls?userID=${clientUserID}`
    );
    return data.data;
  };

const useQueryVideoCallHistory = (params: FetchVideoCallsHistoryParams) =>
  useQuery<VideoCallHistoryItem[], Error>({
    queryKey: videoCallsHistoryQueryKey(params.roomID, params.clientUserID),
    queryFn: fetchVideoCallsHistory(params),
    cacheTime: 0,
    staleTime: 0,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
  });

export default useQueryVideoCallHistory;
