import { useCallback, useEffect, useRef, useState } from 'react';
import useWindowWidth from '../../hooks/useWindowWidth';
import styled from '../../core/styled';

import HorizontalScrollLeftArrowHoverIcon from '../Svgs/HorizontalScrollLeftArrowHoverIcon';
import HorizontalScrollLeftArrowDefaultIcon from '../Svgs/HorizontalScrollLeftArrowDefaultIcon';
import HorizontalScrollRightArrowDefaultIcon from '../Svgs/HorizontalScrollRightArrowDefaultIcon';
import HorizontalScrollRightArrowHoverIcon from '../Svgs/HorizontalScrollRightArrowHoverIcon';
import View from '../View';

interface HorizontalScrollProps {
  shouldResetHorizontalScrollOffset: boolean;
  shutDownReset: () => void;
  graphBoxDefaultOffsetPercentage: number;
  numberOfSteps: number;
  scrollBoxWidth: number;
  contentWidth: number;
  children: JSX.Element;
  handleOnScroll?: (offset: number) => void;
}

interface SideScrollParams {
  element: Element | null;
  speed: number;
  step: number;
  scrollDistance: number;
}

const Box = styled(View)<{ width: number; isHovered: boolean; isZeroOffset: boolean }>(
  ({ width, isHovered, isZeroOffset }) => {
    return {
      width,
      height: 150,
      overflow: 'hidden',
      '&:after': {
        content: '""',
        position: 'absolute',

        height: 50,
        width: `${100}%`,
        top: '50%',
        transition: '0.4s ease',
        transform: 'translateY(-50%)',
        left: 0,

        background: (() => {
          if (isHovered) {
            return 'transparent';
          }
          if (isZeroOffset) {
            return 'linear-gradient(to right, transparent 85%, white 90%)';
          }
          return 'linear-gradient(to right,white 10%, transparent 20%,transparent 85%, white 90%)';
        })(),
      },
    };
  }
);

const ArrowsWrapper = styled(View)<{ width: number }>(({ width }) => {
  return {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: width + 80,
    left: -5,
    top: 45,
  };
});

interface HorizontalScrollArrowParams {
  direction: 'left' | 'right';
  setButtonHover: (isButtonHover: boolean) => void;
  isButtonHover: boolean;
  sideScroll?: (params: SideScrollParams) => void;
  element: Element | null;
  distance: number;
  hoverIcon: JSX.Element;
  defaultIcon: JSX.Element;
  hide: boolean;
}

const HorizontalScrollArrow = ({
  hide,
  direction,
  setButtonHover,
  isButtonHover,
  sideScroll,
  element,
  distance,
  hoverIcon,
  defaultIcon,
}: HorizontalScrollArrowParams) => (
  <>
    <View
      onMouseEnter={() => setButtonHover(true)}
      onMouseLeave={() => setButtonHover(false)}
      style={{
        visibility: hide ? 'hidden' : 'visible',
        height: 60,
        width: 60,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: isButtonHover ? 4 : 0,
        animation: 'fadeIn 0.2s ease-in',
      }}
      onClick={() => {
        const step = direction === 'right' ? 10 : -10;

        sideScroll &&
          sideScroll({
            element,
            speed: 25,
            step,
            scrollDistance: distance,
          });
      }}
    >
      {isButtonHover ? hoverIcon : defaultIcon}
    </View>
  </>
);

const HorizontalScroll = ({
  shouldResetHorizontalScrollOffset,
  shutDownReset,
  graphBoxDefaultOffsetPercentage,
  numberOfSteps,
  scrollBoxWidth,
  contentWidth,
  children,
  handleOnScroll,
}: HorizontalScrollProps) => {
  const { isSmall = false, isMedium = false, isLarge = false } = useWindowWidth();
  const isTabletAndBelow = isSmall || isMedium || isLarge;
  const distance = contentWidth / numberOfSteps;

  const contentWrapper = useRef<Element | null>(null);

  const [isRightButtonHover, setIsRightButtonHover] = useState<boolean>(false);
  const [isLeftButtonHover, setIsLeftButtonHover] = useState<boolean>(false);

  const [isMaxOffset, setIsMaxOffset] = useState<boolean>(false);
  const [isStepDone, setIsStepDone] = useState<boolean>(true);

  const [showArrows, setShowArrows] = useState<boolean>(isTabletAndBelow);

  const offsetInPixels = (graphBoxDefaultOffsetPercentage * contentWidth) / 100;

  useEffect(() => {
    if (contentWrapper?.current && shouldResetHorizontalScrollOffset) {
      contentWrapper.current.scrollLeft = offsetInPixels;
      handleOnScroll && handleOnScroll(contentWrapper.current.scrollLeft);
      shutDownReset();
    }
  }, [handleOnScroll, offsetInPixels, shouldResetHorizontalScrollOffset, shutDownReset]);

  const sideScroll = useCallback(
    ({ element, speed, step, scrollDistance }: SideScrollParams) => {
      let scrollAmount = 0;
      let updatedIsMaxOffset = false;
      const htmlElement = element as HTMLDivElement;
      if (step < 0 && scrollDistance > htmlElement.scrollLeft) {
        // eslint-disable-next-line no-param-reassign
        scrollDistance = htmlElement.scrollLeft;
      }
      if (step > 0 && scrollDistance > contentWidth - (htmlElement.scrollLeft + scrollBoxWidth)) {
        updatedIsMaxOffset = true;
        // eslint-disable-next-line no-param-reassign
        scrollDistance = contentWidth - (htmlElement.scrollLeft + scrollBoxWidth);
      }
      if (step > 0 && htmlElement.scrollLeft >= contentWidth - scrollBoxWidth) {
        updatedIsMaxOffset = true;
        return;
      }
      if (step < 0 && htmlElement.scrollLeft <= 0) {
        return;
      }

      if (updatedIsMaxOffset !== isMaxOffset) {
        setIsMaxOffset(updatedIsMaxOffset);
      }
      setIsStepDone(false);
      const slideTimer = setInterval(() => {
        // eslint-disable-next-line no-param-reassign
        if (scrollDistance - scrollAmount < Math.abs(step)) {
          htmlElement.scrollLeft = Math.round(
            step > 0
              ? htmlElement.scrollLeft + (scrollDistance - scrollAmount)
              : htmlElement.scrollLeft - (scrollDistance - scrollAmount)
          );
        } else {
          htmlElement.scrollLeft = Math.round(htmlElement.scrollLeft + step);
        }
        scrollAmount += Math.abs(step);
        if (scrollAmount >= scrollDistance) {
          setIsStepDone(true);
          clearInterval(slideTimer);
          handleOnScroll && handleOnScroll(htmlElement.scrollLeft);
        }
      }, speed);
    },
    [contentWidth, handleOnScroll, isMaxOffset, scrollBoxWidth]
  );

  return (
    <View
      style={{ width: scrollBoxWidth + 70, paddingLeft: 35, position: 'relative' }}
      onMouseEnter={() => {
        setShowArrows(true);
      }}
      onMouseLeave={() => {
        if (!isTabletAndBelow) {
          setShowArrows(false);
        }
      }}
    >
      <Box
        width={scrollBoxWidth}
        isZeroOffset={contentWrapper?.current?.scrollLeft === 0}
        isHovered={showArrows}
        ref={contentWrapper}
      >
        {children}
      </Box>

      {showArrows && (
        <ArrowsWrapper width={scrollBoxWidth}>
          <HorizontalScrollArrow
            hide={contentWrapper?.current?.scrollLeft === 0}
            direction="left"
            setButtonHover={setIsLeftButtonHover}
            isButtonHover={isLeftButtonHover}
            sideScroll={isStepDone ? sideScroll : undefined}
            element={contentWrapper.current}
            distance={distance}
            hoverIcon={<HorizontalScrollLeftArrowHoverIcon />}
            defaultIcon={<HorizontalScrollLeftArrowDefaultIcon />}
          />

          <HorizontalScrollArrow
            hide={isMaxOffset}
            direction="right"
            setButtonHover={setIsRightButtonHover}
            isButtonHover={isRightButtonHover}
            sideScroll={isStepDone ? sideScroll : undefined}
            element={contentWrapper.current}
            distance={distance}
            hoverIcon={<HorizontalScrollRightArrowHoverIcon />}
            defaultIcon={<HorizontalScrollRightArrowDefaultIcon />}
          />
        </ArrowsWrapper>
      )}
    </View>
  );
};

export default HorizontalScroll;
