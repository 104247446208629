import { useQuery } from 'react-query';
import { TherapistType } from 'ts-frontend/entities/Therapist';
import apiHelper from '../../utils/api';
import apiWrapper from '../../utils/apiWrapper';
import availabilityKeys from './queryKeys';

interface TherapistAvailabilityResponse {
  id: number;
  availability: number;
  locked: number;
  therapistType: TherapistType;
  isW2: number;
}

const fetchAvailability = (therapistID: number) => async () => {
  const { data } = await apiWrapper.get(
    `${apiHelper().apiEndpoint}/api/v1/therapist/${therapistID}/availability`
  );

  const { therapist_type: therapistType, ...restOfData } = data;

  return {
    ...restOfData,
    therapistType,
  };
};

const useQueryTherapistAvailability = (therapistID: number) =>
  useQuery<TherapistAvailabilityResponse, Error>({
    queryKey: availabilityKeys.therapistAvailability(therapistID),
    queryFn: fetchAvailability(therapistID),
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
  });

export default useQueryTherapistAvailability;
