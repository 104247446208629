import { FunctionComponent } from 'react';
import {
  ClientMeditating,
  ExtraHuge,
  Large,
  View,
  styled,
  useWindowWidthState,
} from '@talkspace/react-toolkit';
import { useInRoomSchedulingState } from '../../hooks/inRoomSchedulingContext';

const Styled = {
  Container: styled(View)(({ isMobile }: { isMobile: boolean }) => {
    return {
      textAlign: 'center',
      marginBottom: 24,
      marginTop: isMobile ? undefined : 14,
    };
  }),
  Title: styled(ExtraHuge)({ marginTop: 24 }),
  Description: styled(Large)(({ theme: { colors } }) => {
    return { marginTop: 12, color: colors.grey950 };
  }),
};
const PostLVSCheckInHeading: FunctionComponent = () => {
  const { clientUsageStats: { completedSessionsTotal } = { completedSessionsTotal: 0 } } =
    useInRoomSchedulingState();
  const { isMobile } = useWindowWidthState();
  return (
    <Styled.Container align="center" isMobile={isMobile}>
      <ClientMeditating />
      <Styled.Title>Every session is a step forward</Styled.Title>
      <Styled.Description>
        You’ve completed{' '}
        {completedSessionsTotal > 1
          ? `${completedSessionsTotal} therapy sessions`
          : 'your first therapy session'}
        ! Book another session to keep your momentum.
      </Styled.Description>
    </Styled.Container>
  );
};

export default PostLVSCheckInHeading;
