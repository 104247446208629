import Svg, { Defs, Path, G, Circle, Rect, Use } from 'svgs';
import { useUniqueID } from '@talkspace/react-toolkit';
import { EmotionStyle } from '@/core/styled';

function ReviewEmptyIcon({
  width = 130,
  height = 130,
  style = {},
}: {
  width?: number;
  height?: number;
  style?: EmotionStyle;
}) {
  const pathID = useUniqueID('reviewEmptyPath');
  const titleText = 'empty review list placeholder';
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 130 130"
      title={titleText}
      aria-label={titleText}
      style={style}
    >
      <Defs>
        <Path
          d="M19.45 14.348l2.981 4.1c.133.183.322.318.538.383l4.931 1.495-3.078 4.02c-.14.185-.216.411-.213.644l.063 5.01-4.897-1.615c-.211-.07-.439-.07-.65 0L14.228 30l.063-5.01a1.036 1.036 0 00-.213-.644L11 20.326l4.931-1.495c.216-.065.405-.2.538-.382l2.981-4.101zm19.5 0l2.981 4.1c.133.183.322.318.538.383l4.931 1.495-3.078 4.02c-.14.185-.216.411-.213.644l.063 5.01-4.897-1.615c-.211-.07-.439-.07-.65 0L33.728 30l.063-5.01a1.036 1.036 0 00-.213-.644l-3.078-4.02 4.931-1.495c.216-.065.405-.2.538-.382l2.981-4.101zm19.5 0l2.981 4.1c.133.183.322.318.538.383l4.931 1.495-3.078 4.02c-.14.185-.216.411-.213.644l.063 5.01-4.897-1.615c-.211-.07-.439-.07-.65 0L53.228 30l.063-5.01a1.036 1.036 0 00-.213-.644L50 20.326l4.931-1.495c.216-.065.405-.2.538-.382l2.981-4.101z"
          id={pathID}
        />
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Path
          d="M979-.25c2.002 0 3.815.811 5.127 2.123A7.227 7.227 0 01986.25 7h0v520a7.227 7.227 0 01-2.123 5.127A7.227 7.227 0 01979 534.25h0H7a7.227 7.227 0 01-5.127-2.123A7.227 7.227 0 01-.25 527h0V7c0-2.002.811-3.815 2.123-5.127A7.227 7.227 0 017-.25h0z"
          transform="translate(-449 -459) translate(19 268)"
          fill="#FFF"
          stroke="#BEC7D7"
          strokeWidth={0.5}
        />
        <G transform="translate(-449 -459) translate(449 459)">
          <Circle fill="#EFF3FA" cx={65} cy={65} r={65} />
          <G fill="#C0E3E0">
            <Rect
              x={2.84217094e-13}
              y={0}
              width={5}
              height={15}
              rx={2.5}
              transform="rotate(65 -17.136 124.42) translate(5.178 .178)"
            />
            <Rect
              x={2.84217094e-13}
              y={0}
              width={5}
              height={15}
              rx={2.5}
              transform="rotate(65 -17.136 124.42) rotate(90 5 10.178)"
            />
          </G>
          <Circle stroke="#ADE953" strokeWidth={5} cx={10.5} cy={94.5} r={4.5} />
          <Circle stroke="#BCCCFF" strokeWidth={6} cx={117} cy={26} r={8} />
        </G>
        <Path
          d="M0 22.721C0 10.211 10.018 0 22.47 0h32.917C67.84 0 78 10.21 78 22.721S67.839 45.442 55.387 45.442h-5.295c-.859 0-1.718.432-2.433 1.007-.716.575-1.002 1.582-1.002 2.445.132 3.711.75 7.423 2.079 10.684.113.277-.11.527-.342.377-5.723-3.74-10.738-9.21-13.902-13.218-.573-.863-1.575-1.295-2.576-1.295H22.47C10.018 45.442 0 35.232 0 22.722z"
          transform="translate(-449 -459) translate(449 459) translate(26 40) matrix(-1 0 0 1 78 0)"
          fill="#FFF"
          fillRule="nonzero"
          stroke="#BECBE0"
          strokeWidth={5.2}
        />
        <G transform="translate(-449 -459) translate(449 459) translate(26 40)">
          <Use fill="#000" xlinkHref={`#${pathID}`} />
          <Use fill="#FF6A00" xlinkHref={`#${pathID}`} />
        </G>
      </G>
    </Svg>
  );
}

export default ReviewEmptyIcon;
