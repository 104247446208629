import { useMutation } from 'react-query';
import apiHelper from '@/utils/api';
import apiWrapper from '@/core/api/apiWrapper';

export interface CreateSendToQueueVariables {
  roomID: number | string;
}

const createSendToQueue = async (params: CreateSendToQueueVariables): Promise<void> => {
  const { roomID } = params;
  const data = await apiWrapper.post(`${apiHelper().apiEndpoint}/v2/rooms/${roomID}/send-to-queue`);
  return data.data;
};

const useMutationSendToQueue = () =>
  useMutation<void, Error, CreateSendToQueueVariables>(createSendToQueue);

export default useMutationSendToQueue;
