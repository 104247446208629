import React from 'react';
import Svg, { G, Path, Circle, Rect } from 'svgs';

function IconCalendar(props) {
  const titleText = 'no upcoming sessions';
  return (
    <Svg
      width="89px"
      height="89px"
      viewBox="0 0 89 89"
      title={titleText}
      aria-label={titleText}
      {...props}
    >
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Path
          d="M982 255.5a6.48 6.48 0 014.596 1.904A6.48 6.48 0 01988.5 262h0v105a6.48 6.48 0 01-1.904 4.596A6.48 6.48 0 01982 373.5h0H42a6.48 6.48 0 01-4.596-1.904A6.48 6.48 0 0135.5 367h0V262a6.48 6.48 0 011.904-4.596A6.48 6.48 0 0142 255.5h0z"
          stroke="#DDE3EA"
          fill="#F9FAFB"
          transform="translate(-50 -270)"
        />
        <Circle
          cx={44.5}
          cy={44.5}
          r={44.5}
          fill="#EFF3FA"
          transform="translate(-50 -270) translate(50 270)"
        />
        <Path
          d="M7.107 2.723l20.498-.708a5.284 5.284 0 013.793 1.421 5.283 5.283 0 011.683 3.684h0l.714 20.282a5.266 5.266 0 01-1.414 3.791 5.271 5.271 0 01-3.683 1.68h0l-20.5.708a5.283 5.283 0 01-3.793-1.42 5.282 5.282 0 01-1.682-3.684h0L2.01 8.195a5.267 5.267 0 011.415-3.792 5.272 5.272 0 013.682-1.68h0z"
          stroke="#ACBFF1"
          strokeWidth={3.5269755}
          transform="translate(-50 -270) translate(50 270) translate(21.871 22.992)"
        />
        <G transform="translate(-50 -270) translate(50 270) translate(21.871 22.992) rotate(2 -177.558 329.707)">
          <Path
            d="M27.562 2.79c1.46 0 2.783.592 3.74 1.55a5.275 5.275 0 011.55 3.74h0l.002 20.294a5.272 5.272 0 01-1.486 3.677 5.273 5.273 0 01-3.597 1.61h0l-20.717.003a5.274 5.274 0 01-3.741-1.55 5.274 5.274 0 01-1.55-3.74h0V8.08c0-1.461.593-2.784 1.55-3.741a5.274 5.274 0 013.741-1.55h0z"
            stroke="#5C84FB"
            strokeWidth={3.5269755}
            fill="#CFEAFF"
          />
          <G transform="translate(8.151 16.035)" fill="#5C84FB">
            <Rect x={0} y={0} width={4.0727263} height={4.04719197} rx={1.01665385} />
            <Rect x={4.75151402} y={0} width={4.0727263} height={4.04719197} rx={1.01665385} />
            <Rect x={9.50302804} y={0} width={4.0727263} height={4.04719197} rx={1.01665385} />
            <Rect x={0} y={4.72172396} width={4.0727263} height={4.04719197} rx={1.01665385} />
            <Rect
              x={4.75151402}
              y={4.72172396}
              width={4.0727263}
              height={4.04719197}
              rx={1.01665385}
            />
            <Rect
              x={9.50302804}
              y={4.72172396}
              width={4.0727263}
              height={4.04719197}
              rx={1.01665385}
            />
            <Rect
              x={14.2545421}
              y={4.72172396}
              width={4.0727263}
              height={4.04719197}
              rx={1.01665385}
            />
            <Rect
              x={4.75151402}
              y={9.44344792}
              width={4.0727263}
              height={4.04719197}
              rx={1.01665385}
            />
            <Rect
              x={9.50302804}
              y={9.44344792}
              width={4.0727263}
              height={4.04719197}
              rx={1.01665385}
            />
            <Rect
              x={14.2545421}
              y={9.44344792}
              width={4.0727263}
              height={4.04719197}
              rx={1.01665385}
            />
          </G>
          <Path
            stroke="#5C84FB"
            strokeWidth={3.5244}
            strokeLinecap="square"
            transform="rotate(-2.603 17.61 12.157)"
            d="M3.0304894 11.4940892L32.1882551 12.8194422"
          />
          <Path
            stroke="#5C84FB"
            strokeWidth={3.5244}
            strokeLinecap="round"
            transform="rotate(87.397 10.858 2.713)"
            d="M8.16245794 2.59039122L13.5531479 2.83542258"
          />
          <Path
            stroke="#5C84FB"
            strokeWidth={3.5244}
            strokeLinecap="round"
            transform="rotate(87.397 23.755 2.713)"
            d="M21.0594246 2.59039122L26.4501145 2.83542258"
          />
        </G>
      </G>
    </Svg>
  );
}

export default IconCalendar;
