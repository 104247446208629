import { commonStyles, TouchableView, View } from '@talkspace/react-toolkit';
import styled, { Theme } from '@/core/styled';
import { webOnlyStyle } from '@/core/styled/styleHelpers';

const { crossBrowserFocusRing } = commonStyles;

interface BaseProps {
  highlighted: boolean;
  disabled?: boolean;
  touchable?: boolean;
}

const getStyles = ({ highlighted, disabled, theme: { window } }: BaseProps & { theme: Theme }) => {
  const { isMobile } = window;
  return {
    alignItems: 'stretch',
    borderRadius: 15,
    display: 'flex',
    flexDirection: 'column' as 'column',
    paddingTop: 20,
    paddingRight: 16,
    paddingBottom: 20,
    paddingLeft: 16,
    position: 'relative' as 'relative',
    minWidth: isMobile ? 300 : 475,
    ...webOnlyStyle({
      boxShadow: highlighted ? '0 5px 18px -1px rgba(35,60,81,0.10)' : 'none',
      border: highlighted ? '1px solid transparent' : '1px solid #E0E8F3',
      outline: 'none',
      textAlign: 'unset',
      transition: 'all 800ms ease-in-out',
      '&:hover': {
        cursor: disabled ? 'unset' : undefined,
      },
      ':hover:focus, :hover:focus-within': {
        outline: 'none',
      },
      ':focus': {
        ...crossBrowserFocusRing,
        transition: 'none',
      },
    }),
  };
};

const TouchableViewWrapper = styled(TouchableView)<BaseProps>(getStyles);

const ViewWrapper = styled(View)<BaseProps>(getStyles);

const PaymentCardWrapper = ({
  touchable = false,
  ...otherProps
}: // TODO: It's quite difficult to toggle the type between two conflicting interfaces (HTMLDivElement and HTMLButtonElement). Temporary workaround:
BaseProps & { [key: string]: any }) =>
  touchable ? <TouchableViewWrapper {...otherProps} /> : <ViewWrapper {...otherProps} />;

export default PaymentCardWrapper;
