const ThreeDots = ({ active, style }) => (
  <div style={style}>
    <svg width="3px" height="13px" viewBox="0 0 3 13">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M-3.5,8 C-4.32842712,8 -5,7.32842712 -5,6.5 C-5,5.67157288 -4.32842712,5 -3.5,5 C-2.67157288,5 -2,5.67157288 -2,6.5 C-2,7.32842712 -2.67157288,8 -3.5,8 Z M1.5,8 C0.671572875,8 0,7.32842712 0,6.5 C0,5.67157288 0.671572875,5 1.5,5 C2.32842712,5 3,5.67157288 3,6.5 C3,7.32842712 2.32842712,8 1.5,8 Z M6.5,8 C5.67157288,8 5,7.32842712 5,6.5 C5,5.67157288 5.67157288,5 6.5,5 C7.32842712,5 8,5.67157288 8,6.5 C8,7.32842712 7.32842712,8 6.5,8 Z"
          id="Combined-Shape"
          fill={active ? '#0FC1A7' : '#B5B5B5'}
          transform="translate(1.500000, 6.500000) rotate(90.000000) translate(-1.500000, -6.500000) "
        />
      </g>
    </svg>
  </div>
);

export default ThreeDots;
