import { useMemo, useState, useCallback } from 'react';
import moment from 'moment-timezone';
import { useFlag } from 'launchDarkly/FlagsProvider';
import { CreditMinutes, TherapistTimeslot, RepeatingPeriodValue } from 'ts-frontend/types';
import { TimeslotsByDayByBookingDuration, TimeslotByDay, InRoomSchedulingState } from '../types';
import getFirstAvailableDate from '../utils/getFirstAvailableDate';

interface ParseTimeslotsOptions {
  therapistTimeslotsByDay?: TimeslotsByDayByBookingDuration;
  selectedBookingDuration?: CreditMinutes;
}

const parseTimeslots = ({
  therapistTimeslotsByDay,
  selectedBookingDuration,
}: ParseTimeslotsOptions) => {
  if (
    !therapistTimeslotsByDay ||
    !selectedBookingDuration ||
    !therapistTimeslotsByDay[selectedBookingDuration]
  ) {
    return {
      availableTimeslots: [],
      timeslotDaysStrings: [],
      firstAvailableDate: null,
    };
  }

  const possibleTimeslots = therapistTimeslotsByDay[selectedBookingDuration];
  const availableTimeslots = possibleTimeslots.filter((t) => t.timeslots.length);

  /** First available date in the current month */
  const firstAvailableDate = getFirstAvailableDate(availableTimeslots, moment().endOf('month'));

  const timeslotDaysStrings = availableTimeslots.reduce((acc, current) => {
    if (current.timeslots.length) acc.push(moment(current.date).format('MM/DD/YYYY'));
    return acc;
  }, [] as string[]);
  return {
    availableTimeslots,
    timeslotDaysStrings,
    firstAvailableDate,
  };
};

const isTimeslotAvailable = (
  timeslot: TherapistTimeslot,
  timeslotDaysStrings: string[],
  availableTimeslots: TimeslotByDay[]
) => {
  const dayStringIndex = timeslotDaysStrings.indexOf(moment(timeslot.start).format('MM/DD/YYYY'));
  if (dayStringIndex === -1) {
    return false;
  }
  const availableForDay = availableTimeslots[dayStringIndex];
  const foundTimeslot = availableForDay.timeslots.find(
    (availableTimeslot) => availableTimeslot.start === timeslot.start
  );
  return !!foundTimeslot;
};

interface GetRecurringTimeslotDataOptions {
  selectedTimeslot?: TherapistTimeslot | null;
  repeatingPeriod?: RepeatingPeriodValue;
  repeatingSessions?: number;
  allowRecurringSessions: boolean;
  availableTimeslots: TimeslotByDay[];
  timeslotDaysStrings: string[];
  repeatingSessionsFull: boolean;
}

const getRecurringTimeslotData = ({
  selectedTimeslot,
  repeatingPeriod,
  repeatingSessions,
  allowRecurringSessions,
  availableTimeslots,
  timeslotDaysStrings,
  repeatingSessionsFull,
}: GetRecurringTimeslotDataOptions): {
  recurringConflictingTimeslots: TherapistTimeslot[] | null;
  recurringAvailableTimeslots: TherapistTimeslot[] | null;
  recurringRequestedTimeslots: TherapistTimeslot[] | null;
} => {
  if (
    !repeatingSessionsFull ||
    !allowRecurringSessions ||
    !selectedTimeslot ||
    repeatingPeriod === 'no-repeat' ||
    !repeatingSessions ||
    !availableTimeslots?.length
  ) {
    return {
      recurringConflictingTimeslots: null,
      recurringAvailableTimeslots: null,
      recurringRequestedTimeslots: null,
    };
  }
  const periodInWeeks = repeatingPeriod === 'every-other-week' ? 2 : 1;
  const recurringTimeslots: TherapistTimeslot[] = [];
  for (let i = 0; i < repeatingSessions; i += 1) {
    const weeksToAdd = periodInWeeks * i;
    recurringTimeslots.push({
      start: moment(selectedTimeslot.start).add(weeksToAdd, 'weeks').format(), // 2023-04-26T00:30:00-04:00
      end: moment(selectedTimeslot.end).add(weeksToAdd, 'weeks').format(),
      therapists: selectedTimeslot.therapists,
    });
  }
  const conflictingTimeslots: TherapistTimeslot[] = [];
  const recurringAvailableTimeslots: TherapistTimeslot[] = [];
  recurringTimeslots.forEach((timeslot) => {
    if (isTimeslotAvailable(timeslot, timeslotDaysStrings, availableTimeslots)) {
      recurringAvailableTimeslots.push(timeslot);
    } else {
      conflictingTimeslots.push(timeslot);
    }
  });

  return {
    recurringConflictingTimeslots: conflictingTimeslots,
    recurringAvailableTimeslots,
    recurringRequestedTimeslots: recurringTimeslots,
  };
};

const useTimeslots = ({
  schedulingState,
  allowRecurringSessions,
}: {
  schedulingState: Partial<InRoomSchedulingState>;
  allowRecurringSessions: boolean;
}) => {
  const repeatingSessionsFull = useFlag('repeatingSessionsFull');

  const {
    therapistTimeslotsByDay,
    selectedBookingDuration,
    selectedTimeslot,
    repeatingPeriod,
    repeatingSessions,
    isInitialTimeSlotsLoaded,
    useMatchTimeslots,
  } = schedulingState;

  const { availableTimeslots, timeslotDaysStrings, firstAvailableDate } = useMemo(
    () =>
      isInitialTimeSlotsLoaded || useMatchTimeslots
        ? parseTimeslots({ therapistTimeslotsByDay, selectedBookingDuration })
        : {
            availableTimeslots: [],
            timeslotDaysStrings: [],
            firstAvailableDate: null,
          },
    [therapistTimeslotsByDay, selectedBookingDuration, isInitialTimeSlotsLoaded, useMatchTimeslots]
  );

  const getAllTimeslotsByDate = useCallback(
    (selectedDate: moment.Moment): TherapistTimeslot[] | null => {
      if (
        selectedDate &&
        availableTimeslots[timeslotDaysStrings.indexOf(selectedDate.format('MM/DD/YYYY'))]
      ) {
        return availableTimeslots[timeslotDaysStrings.indexOf(selectedDate.format('MM/DD/YYYY'))]
          .timeslots;
      }
      return null;
    },
    [availableTimeslots, timeslotDaysStrings]
  );

  const getFirstTimeslotByDate = useCallback(
    (selectedDate: moment.Moment): TherapistTimeslot | null =>
      (getAllTimeslotsByDate(selectedDate) || [])[0] || null,
    [getAllTimeslotsByDate]
  );

  const [calendarDate, setCalendarDate] = useState<moment.Moment | null>(() => firstAvailableDate);

  /** First available date in the future */
  const nextAvailableDate = useMemo(
    () =>
      // First try finding an available date after `date`
      (calendarDate && getFirstAvailableDate(availableTimeslots, undefined, calendarDate)) ||
      // If `date` is undefined, or no available date was found, get the first available date from today
      getFirstAvailableDate(availableTimeslots),
    [availableTimeslots, calendarDate]
  );

  const {
    recurringAvailableTimeslots,
    recurringConflictingTimeslots,
    recurringRequestedTimeslots,
  } = useMemo(
    () =>
      getRecurringTimeslotData({
        selectedTimeslot,
        repeatingPeriod,
        repeatingSessions,
        allowRecurringSessions,
        availableTimeslots,
        timeslotDaysStrings,
        repeatingSessionsFull: !!repeatingSessionsFull,
      }),
    [
      repeatingSessionsFull,
      selectedTimeslot,
      repeatingPeriod,
      repeatingSessions,
      allowRecurringSessions,
      availableTimeslots,
      timeslotDaysStrings,
    ]
  );

  const isTimeslotAvailableWrapped = useCallback(
    (timeslot: TherapistTimeslot): boolean =>
      isTimeslotAvailable(timeslot, timeslotDaysStrings, availableTimeslots),
    [timeslotDaysStrings, availableTimeslots]
  );

  return {
    availableTimeslots,
    timeslotDaysStrings,
    firstAvailableDate,
    nextAvailableDate,
    calendarDate,
    setCalendarDate,
    recurringAvailableTimeslots,
    recurringConflictingTimeslots,
    recurringRequestedTimeslots,
    getFirstTimeslotByDate,
    getAllTimeslotsByDate,
    isTimeslotAvailable: isTimeslotAvailableWrapped,
  };
};

export default useTimeslots;
