import { FunctionComponent } from 'react';
import Svg, { G, Path } from 'svgs';
import { useEmotionTheme, EmotionStyle } from '../../core/styled';

interface AudioIconProps {
  color?: string;
  iconColor?: string;
  width?: number;
  height?: number;
  style?: EmotionStyle;
}

const AudioIcon: FunctionComponent<AudioIconProps> = ({
  color,
  iconColor,
  width = 70,
  height = 70,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'audio icon';
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 70 70"
      title={titleText}
      aria-label={titleText}
      {...otherProps}
    >
      <defs>
        <filter
          x="-54.8%"
          y="-36.8%"
          width="209.7%"
          height="185.3%"
          filterUnits="objectBoundingBox"
          id="AudioIcon-filter"
        >
          <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur stdDeviation={4.5} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
          <feColorMatrix
            values="0 0 0 0 0.196078431 0 0 0 0 0.337254902 0 0 0 0 0.764705882 0 0 0 0.45 0"
            in="shadowBlurOuter1"
          />
        </filter>
        <Path
          d="M25.89 19.426c1.226-.86 2.006-1.183 3.344-1.399 2.23-.538 6.355 6.994 4.571 8.931-.669.753-1.56.646-3.121 1.291-.335 4.842 5.017 13.02 9.7 14.849 1.114-1.076 1.56-1.83 2.452-2.152 2.676-.646 7.805 6.24 6.355 7.962-.892.968-1.45 1.506-2.787 2.26-10.369 6.348-30.995-25.501-20.515-31.742z"
          id="AudioIcon"
        />
      </defs>
      <G fill="none" fillRule="evenodd">
        <Path
          d="M35 0c19.33 0 35 15.67 35 35S54.33 70 35 70 0 54.33 0 35 15.67 0 35 0z"
          fill={color || colors.periwinkleGrey}
        />
        <use fill="#000" filter="url(#AudioIcon-filter)" xlinkHref="#AudioIcon" />
        <use fill={iconColor || colors.a11yLinkWaterGrey} xlinkHref="#AudioIcon" />
      </G>
    </Svg>
  );
};

export default AudioIcon;
