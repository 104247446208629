import apiWrapper from '../utils/apiWrapper';
import apiHelper from '../utils/api';

export function getAdminConfigValue(key) {
  return apiWrapper
    .get(`${apiHelper().apiEndpoint}/v3/users/get-admin-config-value?key=${key}`)
    .then((response) => {
      const {
        data: { data },
      } = response;

      return data;
    })
    .catch((error) => {
      throw error;
    });
}
