import { OptionType } from '../components/Select/types';

type Value = string | number;
type Label = string;

// Creates options object for <Select /> using value to label map
const getOptions = (valueToLabelMap: { [value in Value]: Label }): OptionType[] =>
  Object.keys(valueToLabelMap).map((value) => {
    return { value, label: valueToLabelMap[value] };
  });

// Creates options object for <Select /> using value to label map with index as a value (starting with 1)
const getIndexOptions = (valueToLabelMap: { [value in Value]: Label }): OptionType[] =>
  Object.values(valueToLabelMap).map((label, i) => {
    return { value: String(i + 1), label };
  });

export default function formatSelectOptionsFromConfig(
  valueToLabelMap: { [value in Value]: Label },
  indexValues = false
): OptionType[] {
  if (indexValues) return getIndexOptions(valueToLabelMap);
  return getOptions(valueToLabelMap);
}
