import { Path, Svg } from 'svgs';

import { useEmotionTheme } from '../..';

const WebPageError = ({ width = 96, height = 96 }) => {
  const { colorRoles } = useEmotionTheme();

  const titleText = 'Web page with an X mark';

  return (
    <Svg width={width} height={height} viewBox="0 0 96 96" title={titleText} aria-label={titleText}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.3261 18.7844L76.323 52.442C83.7401 55.4012 87.952 62.177 88.3312 69.2312C88.9125 80.0472 80.4418 89.1296 70.2225 90.1354C60.9003 91.0529 52.4874 85.1103 49.6769 76.6143H16.8155C16.07 76.6143 15.4657 76.0102 15.4657 75.2646C15.4657 70.8771 15.4638 65.3235 15.4618 59.4612C15.4558 41.9846 15.4488 21.765 15.4888 21.5222C15.4905 21.512 15.492 21.4848 15.4943 21.443C15.521 20.9528 15.657 18.4607 17.5 18C17.5 18 18.5 17.8232 75.3625 17.8232H75.365C75.8902 17.8232 76.3261 18.2475 76.3261 18.7844ZM69.6441 51.0471C71.1957 51.1142 72.787 51.3505 74.4008 51.7728L74.403 29.0297C72.3775 29.0432 62.2 29.0087 19.3308 29.0274V71.8319C19.3308 72.0459 19.5054 72.2198 19.7194 72.2198H48.807C47.903 60.2467 57.5688 50.5248 69.6441 51.0471ZM74.403 28.9542V28.9678C74.3924 28.9636 74.3913 28.959 74.403 28.9542ZM74.4032 27.1029L74.4039 19.748L19.3308 19.9232V27.1052C62.2144 27.1239 72.3824 27.0893 74.4032 27.1029ZM74.4032 27.1784C74.3913 27.1736 74.3925 27.1691 74.4032 27.1648V27.1784ZM69.3714 86.857C78.492 86.857 85.8857 79.2459 85.8857 69.857C85.8857 60.4682 78.492 52.857 69.3714 52.857C60.2508 52.857 52.8571 60.4682 52.8571 69.857C52.8571 79.2459 60.2508 86.857 69.3714 86.857Z"
        fill={colorRoles.icons.iconSubtlestDefault}
      />
      <Path
        d="M41.6085 49.7558V34.6997C41.6085 34.1691 41.178 33.7386 40.6474 33.7386H24.7928C24.2622 33.7386 23.8317 34.1691 23.8317 34.6997V49.7558C23.8317 50.2864 24.2622 50.7169 24.7928 50.7169H40.6474C41.178 50.7169 41.6085 50.2864 41.6085 49.7558ZM39.6862 48.7947H25.7539V35.6608H39.6862V48.7947Z"
        fill={colorRoles.icons.iconSubtlestDefault}
      />
      <Path
        d="M66.5216 35.7609H47.504C46.9733 35.7609 46.5429 36.1914 46.5429 36.722C46.5429 37.2526 46.9733 37.6831 47.504 37.6831H66.5216C67.0522 37.6831 67.4827 37.2526 67.4827 36.722C67.4827 36.1914 67.0522 35.7609 66.5216 35.7609Z"
        fill={colorRoles.icons.iconSubtlestDefault}
      />
      <Path
        d="M59.6505 45.1467H47.504C46.9733 45.1467 46.5429 45.5772 46.5429 46.1078C46.5429 46.6384 46.9733 47.0689 47.504 47.0689H59.6505C60.1811 47.0689 60.6116 46.6384 60.6116 46.1078C60.6116 45.5772 60.1811 45.1467 59.6505 45.1467Z"
        fill={colorRoles.icons.iconSubtlestDefault}
      />
      <Path
        d="M73.5073 16.2314C74.0312 16.3041 74.5188 15.939 74.5923 15.4129L75.5897 8.29597C75.6635 7.77036 75.2969 7.28355 74.7713 7.20971C74.2532 7.13463 73.7607 7.5038 73.6863 8.02816L72.6889 15.1451C72.615 15.672 72.9817 16.1576 73.5073 16.2314Z"
        fill={colorRoles.icons.iconSubtlestDefault}
      />
      <Path
        d="M79.2526 16.7018C80.825 15.533 82.2448 14.1701 83.4725 12.6484C83.806 12.2354 83.7415 11.6309 83.3286 11.2968C82.9149 10.9652 82.3086 11.0278 81.977 11.442C80.8513 12.8361 79.5492 14.0863 78.1063 15.1575C77.6802 15.4741 77.5913 16.0761 77.9079 16.5028C78.2252 16.9308 78.8291 17.016 79.2526 16.7018Z"
        fill={colorRoles.icons.iconSubtlestDefault}
      />
      <Path
        d="M78.8628 20.7815C78.9208 21.2873 79.3697 21.6841 79.9265 21.6275L84.294 21.1281C84.8215 21.0681 85.2001 20.5913 85.14 20.0644C85.0799 19.5363 84.61 19.1759 84.0763 19.2184L79.7087 19.7178C79.1812 19.7778 78.8027 20.2546 78.8628 20.7815Z"
        fill={colorRoles.icons.iconSubtlestDefault}
      />
      <Path
        d="M62.7156 63.2658C62.1577 63.8238 62.1577 64.7292 62.7156 65.2871L67.7058 70.2773L63.1708 75.1494C62.6128 75.7074 62.6128 76.6128 63.1708 77.1708C63.7287 77.7288 64.6341 77.7288 65.1921 77.1708L69.7272 72.2987L74.7187 77.2902C75.2767 77.8482 76.1821 77.8482 76.74 77.2902C77.298 76.7322 77.298 75.8268 76.74 75.2689L71.7485 70.2773L76.2843 65.4046C76.8422 64.8466 76.8422 63.9412 76.2843 63.3832C75.7263 62.8253 74.8209 62.8253 74.2629 63.3832L69.7272 68.256L64.737 63.2658C64.179 62.7078 63.2736 62.7078 62.7156 63.2658Z"
        fill={colorRoles.icons.iconSubtlestDefault}
      />
    </Svg>
  );
};

export default WebPageError;
