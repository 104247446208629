import React, { forwardRef } from 'react';
import TouchableView from '../../../components/TouchableView';
import { CardWithContentProps } from './types';
import CardContent from '../CardContent';
import Card from '../Card';

const CardWithContent = forwardRef<HTMLDivElement, CardWithContentProps>(
  (props: CardWithContentProps, ref) => {
    const {
      cardVariant,
      cardContentVariant,
      centerContent,
      centerIcon,
      hideCaret,
      forceColumn,
      Icon,
      titleText,
      bodyText,
      TopRow,
      BottomRow,
      RightCol,
      enableBottomRowHover,
      enableRightColHover,
      Menu,
      isLoading,
      a11yLabel,
      itemSpacing,
      padding,
      bottomRowFullWidth,
      bottomRowLeftMargin,
      ...touchableViewProps
    } = props;
    const { disabled, onPress } = touchableViewProps;

    const cardProps = {
      centerContent,
      centerIcon,
      hideCaret,
      forceColumn,
      Icon,
      titleText,
      bodyText,
      TopRow,
      BottomRow,
      RightCol,
      enableBottomRowHover,
      enableRightColHover,
      Menu,
      isLoading,
      padding,
    };
    const cardContentProps = {
      centerContent,
      centerIcon,
      hideCaret,
      forceColumn,
      Icon,
      titleText,
      bodyText,
      TopRow,
      BottomRow,
      RightCol,
      enableBottomRowHover,
      Menu,
      isLoading,
      itemSpacing,
      bottomRowFullWidth,
      bottomRowLeftMargin,
    };

    if (!isLoading && !disabled && onPress) {
      return (
        <TouchableView
          row
          flex={1}
          {...touchableViewProps}
          roundedFocusStyle
          aria-label={a11yLabel}
          ref={ref}
        >
          <Card
            row
            flex={1}
            variant={cardVariant}
            disabled={disabled}
            forceIdleState={isLoading}
            {...cardProps}
          >
            {({ onChildMouseEnter, onChildMouseLeave }) => (
              <CardContent
                variant={cardContentVariant}
                enableRightColHover
                onChildMouseEnter={onChildMouseEnter}
                onChildMouseLeave={onChildMouseLeave}
                {...cardContentProps}
              />
            )}
          </Card>
        </TouchableView>
      );
    }
    const isDisabled = disabled || isLoading;
    return (
      <Card
        row
        flex={1}
        variant={cardVariant}
        isDisabled={isDisabled}
        forceIdleState={isDisabled}
        {...props}
      >
        {({ onChildMouseEnter, onChildMouseLeave }) => (
          <CardContent
            variant={cardContentVariant}
            onChildMouseEnter={onChildMouseEnter}
            onChildMouseLeave={onChildMouseLeave}
            disabled={isDisabled}
            {...props}
          />
        )}
      </Card>
    );
  }
);

export default CardWithContent;
