import { FunctionComponent, useEffect, useState, useRef } from 'react';
import {
  Standard,
  View,
  styled,
  Input,
  useEmotionTheme,
  TouchableView,
} from '@talkspace/react-toolkit';
import AccordionContainer from 'components/Reusable/AccordionContainer/AccordionContainer';
import useQueryNotes, { NoteItem } from 'hooks/notes/useQueryNotes';
import magnifyingGlassImage from 'components/Icons/magnifying-glass-v2.svg';
import NoNotesIcon from 'components/Icons/NoNotesIcon';
import clearSearchImage from 'components/Icons/clear-search.svg';
import { useParams } from '@/core/routerLib';
import Note from './Note';

const CancelButton = styled(TouchableView)({
  display: 'inline-block',
  marginLeft: 10,
  '&:hover': {
    cursor: 'pointer',
  },
});

const NoNotesWrapper = styled(View)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  marginLeft: 10,
  paddingTop: 15,
});

const SearchInput = styled(Input)<{ searchFocused: boolean }>(
  ({ searchFocused, theme: { colors } }) => {
    return {
      backgroundColor: colors.darkBlue95,
      display: 'inline-block',
      height: 34,
      paddingRight: 35,
      paddingLeft: 35,
      width: searchFocused ? '80%' : '100%',
      borderRadius: 9,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: colors.permaAthensGray,
      color: colors.black,
      outline: 'none',
      transition: '0.2s',
      fontFamily: 'Roboto',
    };
  }
);

const NoNotesText = styled(View)(({ theme: { colors } }) => {
  return {
    marginTop: 5,
    width: 200,
    fontSize: 15,
    color: colors.purple600,
    fontWeight: 500,
    marginRight: 10,
    lineHeight: '20px',
    fontFamily: 'Roboto',
  };
});

const SearchInputWrapper = styled(View)({
  marginTop: 20,
  marginBottom: 20,
  position: 'relative',
});

const MagnifyingGlass = styled(Standard)(() => {
  return {
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    width: 17.78,
    height: 18,
    position: 'absolute',
    top: 8,
    left: 11,
    backgroundImage: `url(${magnifyingGlassImage})`,
  };
});

const ClearSearchInput = styled(View)({
  position: 'absolute',
  backgroundImage: `url(${clearSearchImage})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  width: 16,
  height: 16,
  top: 9,
  right: 72,
  outline: 'none',
  '&:hover': {
    cursor: 'pointer',
  },
});

const NotesList: FunctionComponent = () => {
  const [filteredNotes, setFilteredNotes] = useState<NoteItem[] | undefined>([]);
  const [filter, setFilter] = useState('');
  const [searchFocused, setSearchFocused] = useState(false);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const { roomID } = useParams<{ roomID: string }>();
  const { data: notesData } = useQueryNotes(roomID);

  const { colors } = useEmotionTheme();

  const getFilteredNotes = (notesArr: NoteItem[], filterText: string): NoteItem[] => {
    if (filterText) {
      const filtered = notesArr?.filter((note) => note?.previewText?.includes(filterText));
      return filtered;
    }
    return notesArr;
  };

  useEffect(() => {
    if (notesData) {
      setFilteredNotes(getFilteredNotes(notesData.items, ''));
    }
  }, [notesData]);

  const handleClearSearchClick = (): void => {
    if (notesData) {
      setFilter('');
      setFilteredNotes(getFilteredNotes(notesData.items, ''));
      searchInputRef?.current?.focus();
    }
  };

  const handleCancelClick = (): void => {
    if (notesData) {
      setFilter('');
      setFilteredNotes(getFilteredNotes(notesData.items, ''));
      setSearchFocused(false);
    }
  };

  const getClearSearchInput = (): JSX.Element | null =>
    filter.trim().length > 0 ? <ClearSearchInput onClick={handleClearSearchClick} /> : null;

  return (
    <AccordionContainer
      title={`Note history (${notesData?.items.length || 0})`}
      containerClass="ts-component-accordion-container-for-notes-tab-v2"
      arrowColor={colors.accessibilityGreenDark}
      open
      dataQa="noteHistoryContainer"
    >
      {notesData?.items.length ? (
        <View>
          <SearchInputWrapper>
            <MagnifyingGlass />
            <SearchInput
              searchFocused={searchFocused}
              ref={searchInputRef}
              onFocus={() => {
                setSearchFocused(true);
              }}
              value={filter}
              onChange={(e) => {
                setFilter(e.target.value);
                setFilteredNotes(getFilteredNotes(notesData.items, e.target.value));
              }}
              placeholder="Search"
              dataQa="searchInput"
            />
            {searchFocused && (
              <CancelButton
                className="cancel-search"
                onPress={handleCancelClick}
                dataQa="cancelSearchButton"
              >
                Cancel
              </CancelButton>
            )}
            {searchFocused && getClearSearchInput()}
          </SearchInputWrapper>
          {filteredNotes?.map((noteItem: NoteItem, i) => (
            <Note
              key={noteItem.id}
              filter={filter}
              noteItem={noteItem}
              isLast={i === filteredNotes.length - 1}
            />
          ))}
        </View>
      ) : (
        <View>
          <NoNotesWrapper>
            <NoNotesIcon />
            <NoNotesText>This is where your submitted progress notes will appear.</NoNotesText>
          </NoNotesWrapper>
        </View>
      )}
    </AccordionContainer>
  );
};

export default NotesList;
