import {
  View,
  Standard,
  Small,
  Mini,
  TouchableView,
  FloatingMenu,
  ClipBoardIcon,
  Clipboard,
  IDBox,
  useEmotionTheme,
  withHover,
  CheckMark,
  EmotionStyle,
  WithHoverProps,
} from '@talkspace/react-toolkit';
import { useState, useCallback } from 'react';
import styled from '@/core/styled';

const SHOW_PREFERRED_NAME = false;

const IDText = styled(Small)(() => {
  return {
    marginRight: 8,
    marginTop: 2,
  };
});

const CopyClipBoardText = styled(Standard)<{ isHovering?: boolean }>(
  ({ theme: { colors }, isHovering = false }) => {
    const hoverStyle = isHovering
      ? {
          color: colors.permaBlueStone,
        }
      : {};

    return {
      color: colors.permaTalkspaceDarkGreen,
      marginLeft: 6,
      ...hoverStyle,
    };
  }
);

interface ClipBoardContainerProps extends WithHoverProps {
  onPress: () => void;
}

const ClipBoardContainer = withHover(
  styled(TouchableView)<ClipBoardContainerProps>(() => {
    return {
      marginTop: 16,
    };
  })
);

const IDBoxContainer = styled(TouchableView)<{ isHovering: boolean }>(({ isHovering = false }) => {
  return {
    marginLeft: 4,
    marginTop: 3,
    visibility: isHovering ? 'visible' : 'hidden',
  };
});

const StyledMini = styled(Mini)(() => {
  return {
    fontWeight: 500,
    marginTop: -5,
  };
});

interface Props {
  pseudonym: string;
  preferredName?: string | null;
  roomID: number;
  userID: number;
  fullName: string;
  isHovering?: boolean;
  floatingMenuStyle?: EmotionStyle;
}
const DashboardCellRoomDetail = ({
  pseudonym,
  preferredName,
  roomID,
  userID,
  fullName,
  isHovering = false,
  floatingMenuStyle,
}: Props) => {
  const [hideMenu, setHideMenu] = useState<boolean>(true);
  const [isClipBoardViewHovering, setIsClipBoardViewHovering] = useState<boolean>(false);
  const [hasTextOnClipBoard, setHasTextOnClipBoard] = useState<boolean>(false);

  const { colors } = useEmotionTheme();

  const handleCopyText = () => {
    Clipboard.setString(`Client ID - Nickname: ${pseudonym}, Room: ${roomID}, User: ${userID}`);
    setHasTextOnClipBoard(true);
  };

  const getDisplayText = useCallback(() => {
    const displayText: { heading: JSX.Element | null; body: JSX.Element | null } = {
      heading: null,
      body: null,
    };

    if (preferredName && SHOW_PREFERRED_NAME) {
      displayText.heading = <Standard variant="standardBlack">{preferredName}</Standard>;
      displayText.body = <StyledMini>{pseudonym}</StyledMini>;
    } else if (fullName) {
      displayText.heading = <Standard variant="standardBlack">{fullName}</Standard>;
      displayText.body = <StyledMini>{pseudonym}</StyledMini>;
    } else {
      displayText.heading = <Standard variant="standardBlack">{pseudonym}</Standard>;
      displayText.body = <StyledMini>Pending client name</StyledMini>;
    }
    return displayText;
  }, [fullName, pseudonym, preferredName]);

  const { heading, body } = getDisplayText();

  return (
    <View style={{ position: 'relative' }}>
      <View row>
        {heading}
        <IDBoxContainer isHovering={isHovering} onPress={() => setHideMenu(false)}>
          <IDBox isMenuShowing={!hideMenu} />
        </IDBoxContainer>
      </View>
      {body}
      <FloatingMenu
        hide={hideMenu}
        onBackdropPress={() => {
          setHideMenu(true);
          setHasTextOnClipBoard(false);
        }}
        style={{
          width: 240,
          height: 164,
          paddingLeft: 23,
          top: 27,
          left: -80,
          paddingTop: 18,
          zIndex: 3,
          ...floatingMenuStyle,
        }}
      >
        <View style={{ marginBottom: 8 }}>
          <Standard variant="standardBlack">Client ID</Standard>
        </View>
        <IDText>{`Nickname: ${pseudonym}`}</IDText>
        <IDText>{`Room: ${roomID}`}</IDText>
        <IDText>{`User: ${userID}`}</IDText>
        <ClipBoardContainer
          onPress={handleCopyText}
          onHoverChange={(hovered) => {
            setIsClipBoardViewHovering(hovered);
          }}
        >
          <View row>
            {hasTextOnClipBoard ? (
              <CheckMark style={{ marginTop: 4 }} color={colors.a11yVidaLocaGreen} />
            ) : (
              <ClipBoardIcon {...(isClipBoardViewHovering && { color: colors.permaBlueStone })} />
            )}
            <CopyClipBoardText isHovering={isClipBoardViewHovering || hasTextOnClipBoard}>
              {hasTextOnClipBoard ? `Copied to clipboard!` : `Copy to clipboard`}
            </CopyClipBoardText>
          </View>
        </ClipBoardContainer>
      </FloatingMenu>
    </View>
  );
};

export default DashboardCellRoomDetail;
