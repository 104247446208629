import moment from 'moment';
import { BaseButton, DateRangePickerWithInput, View } from '@talkspace/react-toolkit';
import XIcon from '../../../components/Account/XIcon';

const TimeOffPicker = ({
  startDate,
  endDate,
  focusedInput,
  onFocusChange,
  onDatesChange,
  onRemoveTimeOff,
  isDayBlocked,
  isDisabled,
  isLocked,
  arrayIndex,
}: {
  startDate: string | null;
  endDate: string | null;
  focusedInput: () => void;
  onFocusChange: () => void;
  onDatesChange: () => void;
  onRemoveTimeOff: () => void;
  isDayBlocked: () => void;
  isDisabled: boolean;
  isLocked: boolean;
  arrayIndex: number;
}) => (
  <View row style={{ paddingTop: arrayIndex ? 11 : 0 }}>
    <DateRangePickerWithInput
      minimumNights={0}
      startDate={(startDate && moment(startDate)) || null}
      endDate={(endDate && moment(endDate)) || null}
      // @ts-ignore
      focusedInput={focusedInput}
      onDatesChange={onDatesChange}
      onFocusChange={onFocusChange}
      // @ts-ignore
      isDayBlocked={isDayBlocked}
      keepOpenOnDateSelect
      startDatePlaceholderText="Start"
      endDatePlaceholderText="End"
      startDateId={`timeOffStartDate${arrayIndex}`}
      endDateId={`timeOffEndDate${arrayIndex}`}
      disabled={isDisabled || isLocked}
    />
    {startDate && endDate && !isDisabled && (
      <BaseButton style={{ paddingLeft: 18, marginRight: -21 }} onPress={onRemoveTimeOff}>
        <XIcon width={10} height={10} />
      </BaseButton>
    )}
  </View>
);

export default TimeOffPicker;
