/* eslint-disable import/prefer-default-export */
import ApiHelper from '../../utils/ApiHelper';
import ChatApiHelpers from '../helpers/ChatApiHelpersClass';

import {
  REQUEST_POST_MESSAGE,
  RECEIVE_POST_MESSAGE,
  REQUEST_POST_MESSAGE_ERROR,
  REQUEST_POST_MEDIA_MESSAGE,
  RECEIVE_POST_MEDIA_MESSAGE,
  REQUEST_POST_MEDIA_MESSAGE_ERROR,
  REQUEST_CREATE_VIDEO_CALL,
  RECEIVE_CREATE_VIDEO_CALL,
  REQUEST_CREATE_VIDEO_CALL_ERROR,
  REQUEST_CREATE_VIDEO_CALL_ERROR_ROOM,
  PostMessagePayload,
  PostMediaMessagePayload,
  RequestCreateVideoCallErrorPayload,
  InputActionTypes,
} from '../constants/inputTypes';

import { toast } from '../../components/Toast';

function requestPostMessage(payload: PostMessagePayload): InputActionTypes {
  return { type: REQUEST_POST_MESSAGE, payload };
}
function receivePostMessage(): InputActionTypes {
  return { type: RECEIVE_POST_MESSAGE };
}
function requestPostMessageError(error: Error): InputActionTypes {
  return { type: REQUEST_POST_MESSAGE_ERROR, error };
}

export const dispatchSendMessage =
  (roomID: number, message: string) => (dispatch, getState, api: ChatApiHelpers) => {
    dispatch(requestPostMessage({ message }));
    return api
      .postMessage({ roomID, message })
      .then(() => {
        dispatch(receivePostMessage());
      })
      .catch((error) => {
        console.log(error); // eslint-disable-line no-console
        dispatch(requestPostMessageError(error));
      });
  };

function requestPostMediaMessage(payload: PostMediaMessagePayload): InputActionTypes {
  return { type: REQUEST_POST_MEDIA_MESSAGE, payload };
}
function receivePostMediaMessage(): InputActionTypes {
  return { type: RECEIVE_POST_MEDIA_MESSAGE };
}
function requestPostMediaMessageError(error: Error): InputActionTypes {
  return { type: REQUEST_POST_MEDIA_MESSAGE_ERROR, error };
}

export const dispatchSendMediaMessage =
  (roomID: number, payload: PostMediaMessagePayload) =>
  (dispatch, getState, api: ChatApiHelpers) => {
    dispatch(requestPostMediaMessage({ ...payload }));
    return api
      .postMediaMessage(roomID, payload)
      .then(() => {
        dispatch(receivePostMediaMessage());
      })
      .catch((error) => {
        toast('Something went wrong. Please try again later.');
        dispatch(requestPostMediaMessageError(error));
      });
  };

function requestCreateVideoCall(): InputActionTypes {
  return { type: REQUEST_CREATE_VIDEO_CALL };
}

function receiveCreateVideoCall(payload: {}): InputActionTypes {
  return { type: RECEIVE_CREATE_VIDEO_CALL, payload };
}

function requestCreateVideoCallErrorRoom(payload: number): InputActionTypes {
  return { type: REQUEST_CREATE_VIDEO_CALL_ERROR_ROOM, payload };
}

function requestCreateVideoCallError(
  payload: RequestCreateVideoCallErrorPayload
): InputActionTypes {
  return { type: REQUEST_CREATE_VIDEO_CALL_ERROR, payload };
}

export const dispatchCreateVideoCall =
  (roomID: number, creditMinutes: number, type: string, modality: string) => (dispatch) => {
    const api = new ApiHelper();
    dispatch(requestCreateVideoCall());
    return api
      .createVideoCall(roomID, creditMinutes, type, modality)
      .then(() => {
        dispatch(receiveCreateVideoCall({}));
      })
      .catch((error) => {
        if (error.message === '400') {
          dispatch(
            requestCreateVideoCallError({
              videoCallErrorMessage: error.data.message,
            })
          );
        }
        if (error.message === '404') {
          dispatch(
            requestCreateVideoCallError({
              hasNoCredits: true,
            })
          );
        }
        if (error.message === '409') {
          dispatch(
            requestCreateVideoCallError({
              isCallInProgress: true,
            })
          );
        }
        dispatch(requestCreateVideoCallErrorRoom(roomID));
      });
  };
