import { FunctionComponent, useState, useEffect } from 'react';

import AccordionContainerView, { AccordionContainerViewProps } from './AccordionContainerView';

export interface AccordionContainerProps extends Omit<AccordionContainerViewProps, 'onPress'> {
  onPress?: (open: boolean) => void;
}

const AccordionContainer: FunctionComponent<AccordionContainerProps> = ({
  open,
  onPress,
  disabled,
  dataQa,
  ...otherProps
}) => {
  const [openState, setOpenState] = useState(!!open);

  useEffect(() => {
    if (onPress) onPress(openState);
    // Changes in onPress might cause an infinite loop
  }, [openState]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setOpenState(!!open);
  }, [open]);

  return (
    <AccordionContainerView
      open={openState}
      disabled={disabled}
      dataQa={dataQa}
      onPress={() => {
        if (disabled) return;
        setOpenState((isOpen) => !isOpen);
      }}
      {...otherProps}
    />
  );
};

export default AccordionContainer;
