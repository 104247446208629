import { Nullable, ProgressNoteModalityType } from 'ts-frontend/types';
import { OptionsType, ValueType, StylesConfig } from 'react-select';
import { useState, useEffect, useCallback, useMemo } from 'react';
import moment, { Moment } from 'moment';
import { VideoCallAPIResponse } from 'chat/types/videoCallTypes';
import { useVideoCallState } from 'chat/hooks/videoCallContext';
import {
  Big,
  Large,
  Small,
  Tiny,
  View,
  RadioButton,
  DateRangePickerWithInput,
  Select,
  OptionType,
  useEmotionTheme,
  Spinner,
  styled,
  AccordionContainer,
} from '@talkspace/react-toolkit';

import useQueryRoomDetails from 'ts-frontend/hooks/useQueryRoomDetails';
import useQueryBusinessLine from 'ts-frontend/hooks/useQueryBusinessLine';
import useQueryProgressNoteHelperData from 'hooks/notes/useQueryProgressNoteHelperData';
import useQueryProgressNote from 'hooks/notes/useQueryProgressNote';
import useQueryNotes, { NoteItem, NoteSessionReportData } from 'hooks/notes/useQueryNotes';
import { useLocation, useParams } from '@/core/routerLib';
import ConfirmButton from './ConfirmButton';
import { ProgressNoteFormState } from '../context/types';
import {
  useProgressNoteFormActions,
  useProgressNoteFormState,
} from '../context/PsychProgressNoteFormContext';
import {
  generateVideoCallSelectOption,
  getModalityIDFromParam,
  getServiceDatesFromVideoCall,
  isSameDate,
  Modality,
} from '../../utils';
import ErrorScreen from '../../ErrorScreen';
import analytics from '../../Analytics';
import useNotesRelevantVideoCalls from '../../hooks/useNotesRelevantVideoCalls';
import useMessagingDate from '../../hooks/useMessagingDate';

interface VideoCallSelectOption {
  value: number;
  label: string;
}

type InputFocus = 'startDate' | 'endDate' | null;

const VideoOnlyBlock = styled(View)({
  paddingTop: 10,
  paddingBottom: 10,
});

const MostRecentNoteMessage = styled(View)({
  marginTop: 0,
  marginBottom: 20,
});

const Block = styled(View)<{ isError: boolean }>(({ theme: { colors }, isError }) => {
  return {
    borderWidth: 1,
    borderRadius: 5,
    paddingTop: 30,
    paddingLeft: 5,
    paddingRight: 5,
    paddingBottom: 30,
    marginBottom: 15,
    borderStyle: 'solid',
    borderColor: isError ? colors.permaDebianRed : colors.extraLightGrey,
  };
});

const Row = styled(View)({
  flexDirection: 'row',
});

const WarningText = styled(Tiny)(({ theme: { colors } }) => {
  return { color: colors.red };
});

const formatOngoingCallAsOption = (roomID: number, videoCall?: VideoCallAPIResponse | null) => {
  if (!videoCall) return null;
  const { videoCallID, modality, callStartedAt } = videoCall;
  if (!callStartedAt) return null;
  const startedDate = new Date(callStartedAt);
  const now = new Date();
  return generateVideoCallSelectOption({
    id: videoCallID,
    roomID,
    callStartedAt: startedDate,
    totalActiveCallDuration: (now.getTime() - startedDate.getTime()) / 1000,
    modality,
  });
};

const refetchInterval = (ongoingVideoCall) => (data) => {
  const { unassociatedVideoCalls } = data;
  if (unassociatedVideoCalls && unassociatedVideoCalls.length) {
    return false;
  }
  if (!ongoingVideoCall) {
    return false;
  }
  return 5000;
};

interface DateRange {
  startDate?: Date | null;
  endDate?: Date | null;
}

const getDateRangeForNote = (note: NoteItem): DateRange => {
  return {
    startDate: new Date(note.startDate),
    endDate: note.endDate ? new Date(note.endDate) : null,
  };
};

const getIsSessionReopened = (
  currentSessionReport: NoteSessionReportData | undefined | null
): boolean => {
  if (!currentSessionReport) return false;
  const { progressNoteID: sessionReportProgressNoteID, reopenedAt } = currentSessionReport;
  return !!(reopenedAt && !sessionReportProgressNoteID);
};

const textDateOverlapError =
  'A text session progress note already exists for the selected date range';
const videoDateOverlapError =
  'A video session progress note already exists for the selected start date';
const endDateInFutureError =
  'If the messaging end date is in the future you will not be able to submit until the end date occurs.';
const dateRangeError = 'Please choose a minimum date range of 7 days for your dates of service.';

const validateDateInput = (
  newDateRange: DateRange,
  noteItems?: NoteItem[],
  noteID?: number,
  roomID?: number
): string | null => {
  const { startDate: newStart, endDate: newEnd } = newDateRange;

  if (newStart && newEnd && noteItems?.length) {
    const progressNotes = noteItems.filter(
      (note) => note.roomID === roomID && note.noteID !== noteID && note.type === 'progress'
    );

    const videoNotes = progressNotes.filter((note) => note.modalityID === Modality.VIDEO);
    const sameDayAsVideoNote = videoNotes.some((videoNote) =>
      isSameDate(new Date(videoNote.startDate), newStart)
    );
    if (sameDayAsVideoNote) {
      return videoDateOverlapError;
    }

    const textNotes = progressNotes.filter((note) => note.modalityID === Modality.TEXT);
    const existingDateRanges = textNotes.map(getDateRangeForNote);
    const textNoteOverlap = existingDateRanges.some((existing) =>
      existing.startDate && existing.endDate
        ? newStart <= existing.endDate && newEnd >= existing.startDate
        : false
    );
    if (textNoteOverlap) {
      return textDateOverlapError;
    }
  }

  if (moment().isBefore(newEnd, 'day')) {
    return endDateInFutureError;
  }

  return null;
};

const SessionDateAndModalitySection = ({ disabled }: { disabled: boolean }) => {
  const { noteID, roomID } = useParams<{ roomID: string; noteID: string }>();
  const [dateAndModalityError, setDateAndModalityError] = useState<boolean>(false);
  const [dateError, setDateError] = useState<string | null>(null);
  const [isVideoCallIDParamValid, setIsVideoCallIDParamValid] = useState<Nullable<boolean>>(null);
  const { data: { clientUserID } = {} } = useQueryRoomDetails(roomID);
  const { videoCall: ongoingVideoCall } = useVideoCallState();

  const { data: progressNoteData } = useQueryProgressNote(clientUserID, noteID);
  const noteRoomID = progressNoteData?.roomID?.toString() || roomID;
  const {
    formMode,
    modalityID,
    serviceStartDate,
    serviceEndDate,
    videoCalls,
    sessionSummary,
    formSections,
  } = useProgressNoteFormState();

  const draftOrCreateMode = progressNoteData?.status === 'draft' || formMode === 'create';

  const { data: notesData } = useQueryNotes(roomID);

  const {
    isLoading: helperDataIsLoading,
    error: helperDataError,
    data: helperData,
  } = useQueryProgressNoteHelperData(roomID, true, refetchInterval(ongoingVideoCall));

  const { setFormState } = useProgressNoteFormActions();

  const [videoCallSelectOptions, setVideoCallSelectOptions] = useState<OptionType<number>[]>([]);
  const [selectedVideoCalls, setSelectedVideoCalls] = useState<VideoCallSelectOption[] | null>(
    null
  );
  const [isPrefilled, setIsPrefilled] = useState<boolean>(false);
  const [ongoingCallCheckInterval, setOngoingCallCheckInterval] = useState<number>(0);
  const [formModalityID, setFormModalityID] = useState<Modality | null>(null);
  const [inputFocused, setInputFocused] = useState<InputFocus>(null);

  const {
    isLoading: businessLineIsLoading,
    error: businessLineError,
    data: businessLine,
  } = useQueryBusinessLine(noteRoomID, clientUserID);

  const handleSetServiceStartDate = useCallback(
    (startDate: Date) => {
      setFormState({ serviceStartDate: startDate });
    },
    [setFormState]
  );

  const {
    messagingStartDate,
    messagingEndDate,
    isMissingMessagingSession,
    handleSetMessagingStartDate,
    handleSetMessagingEndDate,
  } = useMessagingDate({
    formMode,
    businessLine,
    serviceEndDate,
    serviceStartDate,
    helperData,
    modalityID,
    handleSetServiceStartDate,
  });

  const { notesRelevantVideoCalls, disableLVSComponents } = useNotesRelevantVideoCalls({
    formMode,
    progressNoteData,
    helperData,
  });

  const { colors } = useEmotionTheme();
  const { search } = useLocation();
  const memoizedSearch = useMemo(() => new URLSearchParams(search), [search]);
  const videoCallIDParam = Number(memoizedSearch.get('videoCallID') as Nullable<string>);
  const modalityParam = memoizedSearch.get('modality') as Nullable<ProgressNoteModalityType>;

  useEffect(() => {
    if (formModalityID) {
      setFormState({ modalityID: formModalityID });
    }
  }, [formModalityID, setFormState]);

  const lvsSelectStyles = (isError: boolean): StylesConfig<OptionType, boolean> => {
    return {
      control: (base) => {
        return {
          ...base,
          ...(isError ? { borderColor: colors.permaDebianRed } : {}),
        };
      },
      multiValue: (base) => {
        return {
          ...base,
          '& div:first-child': {
            maxWidth: 250,
          },
        };
      },
      valueContainer: (base) => {
        return {
          ...base,
          '& div': {
            maxWidth: 270,
          },
        };
      },
    };
  };

  useEffect(() => {
    // push events for component state changes
    analytics.trackProgressNoteDebugEvent({
      debugEventType: 'modalityComponentStateChanged',
      roomID,
      noteID,
      modalityComponentStateModalityID: formModalityID,
      modalityComponentStateStartDate: messagingStartDate,
      modalityComponentStateEndDate: messagingEndDate,
      modalityComponentStateVideoCalls: selectedVideoCalls,
    });
  }, [formModalityID, messagingStartDate, messagingEndDate, selectedVideoCalls, noteID, roomID]);

  useEffect(() => {
    // push events for relevant formState changes
    analytics.trackProgressNoteDebugEvent({
      debugEventType: 'modalityFormStateChanged',
      roomID,
      noteID,
      modalityID,
      serviceStartDate,
      serviceEndDate,
      videoCalls,
    });
  }, [modalityID, serviceStartDate, serviceEndDate, videoCalls, roomID, noteID]);

  useEffect(() => {
    if (formMode === 'create' && videoCallIDParam) {
      const unassociatedCalls = helperData?.unassociatedVideoCalls?.map((call) => call.id);
      const isVideoCallIDParamInUnassociatedCalls =
        unassociatedCalls?.includes(videoCallIDParam) || false;
      setIsVideoCallIDParamValid(isVideoCallIDParamInUnassociatedCalls);
    }
  }, [formMode, helperData?.unassociatedVideoCalls, roomID, videoCallIDParam]);

  useEffect(() => {
    if (selectedVideoCalls === null && videoCalls && notesRelevantVideoCalls?.length) {
      const initialVideoCallIDs = videoCalls.map((it) => it.videoCallID);
      const videoCallIDs =
        (videoCallIDParam && initialVideoCallIDs.includes(videoCallIDParam)) || !videoCallIDParam
          ? initialVideoCallIDs
          : [videoCallIDParam];
      const selectedOnMount = videoCallIDs.length
        ? notesRelevantVideoCalls.filter((it) => videoCallIDs.includes(it?.id || 0))
        : [notesRelevantVideoCalls[0]];
      setSelectedVideoCalls(
        selectedOnMount.map((videoCall) => generateVideoCallSelectOption(videoCall))
      );
    }
    if (notesRelevantVideoCalls) {
      const selectOptions = notesRelevantVideoCalls
        .filter(Boolean)
        .map(generateVideoCallSelectOption);
      if (selectedVideoCalls?.length) {
        const startedAt = notesRelevantVideoCalls.find(
          (opt) => selectedVideoCalls[0].value === opt?.id
        )?.callStartedAt;
        const disabledOptions = notesRelevantVideoCalls.filter(
          (opt) => opt && startedAt && !isSameDate(new Date(opt.callStartedAt), new Date(startedAt))
        );
        const selectOptionsWithDisabled = selectOptions.map((opt) => {
          return {
            ...opt,
            isDisabled: disabledOptions.findIndex((dis) => dis?.id === opt.value) !== -1,
          };
        });
        setVideoCallSelectOptions(selectOptionsWithDisabled);
      } else {
        setVideoCallSelectOptions(selectOptions);
      }
    }
  }, [notesRelevantVideoCalls, selectedVideoCalls, videoCallIDParam, videoCalls]);

  useEffect(() => {
    const interval = setInterval(() => {
      setOngoingCallCheckInterval(Date.now);
    }, 60 * 1000);
    return () => clearInterval(interval);
  }, [setOngoingCallCheckInterval]);

  useEffect(() => {
    const ongoingCallSelectOption = formatOngoingCallAsOption(+roomID, ongoingVideoCall);
    if (ongoingCallSelectOption && videoCallSelectOptions?.length) {
      const foundOption = videoCallSelectOptions.find(
        (selectOption) => selectOption.label === ongoingCallSelectOption.label
      );
      if (!foundOption) {
        const videoCallSelectOptionsFixed = videoCallSelectOptions.map((selectOption) =>
          ongoingCallSelectOption && selectOption.value === ongoingCallSelectOption.value
            ? ongoingCallSelectOption
            : selectOption
        );
        setVideoCallSelectOptions(videoCallSelectOptionsFixed);
      }
    }
  }, [ongoingCallCheckInterval, roomID, ongoingVideoCall, videoCallSelectOptions]);

  const setServiceDates = useCallback(
    (selectedCallIDs: Array<number>, state?: Partial<ProgressNoteFormState>) => {
      notesRelevantVideoCalls &&
        notesRelevantVideoCalls
          .filter((it) => selectedCallIDs.includes(it?.id || 0))
          .forEach((videoCall) => {
            if (videoCall) {
              setFormState({
                ...getServiceDatesFromVideoCall(videoCall),
                ...(state || {}),
              });
            }
          });
    },
    [setFormState, notesRelevantVideoCalls]
  );

  const location = useLocation<{ sessionHighlights?: string }>();

  const onMountPasteSessionHighlights = useCallback(() => {
    if (location?.state?.sessionHighlights) {
      setFormState({
        sessionSummary: {
          ...sessionSummary,
          summary: location.state.sessionHighlights,
        },
        modalityID: Modality.TEXT,
      });

      analytics.trackProgressNoteDebugEvent({
        debugEventType: 'modalitySectionComponentMounted',
        roomID,
        noteID,
      });
    }
  }, [location, sessionSummary, setFormState, roomID, noteID]);

  const { currentSessionReport } = notesData || {};
  const isSessionReopened = getIsSessionReopened(currentSessionReport);
  const messagingDisabled = isMissingMessagingSession && !isSessionReopened;

  useEffect(() => {
    if (formMode === 'create') {
      const selectedVideoCallsCondition =
        selectedVideoCalls && (businessLine?.isPsychiatry || businessLine?.isVideoOnly);

      if (selectedVideoCallsCondition) {
        const selectedCallIDs = selectedVideoCalls?.map((it) => it.value);
        if (selectedCallIDs) setServiceDates(selectedCallIDs);
      }

      if (!isPrefilled) {
        const shouldPrefillIfVideo =
          modalityParam !== ProgressNoteModalityType.liveSession ||
          (modalityParam === ProgressNoteModalityType.liveSession &&
            disableLVSComponents === false);

        if (modalityParam && shouldPrefillIfVideo) {
          setFormModalityID(getModalityIDFromParam(modalityParam));
          setIsPrefilled(true);
        } else if (selectedVideoCallsCondition || isVideoCallIDParamValid) {
          setFormModalityID(Modality.VIDEO);
          setIsPrefilled(true);
        }
      }
    }
  }, [
    businessLine,
    formMode,
    setServiceDates,
    selectedVideoCalls,
    disableLVSComponents,
    isVideoCallIDParamValid,
    modalityParam,
    isPrefilled,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(onMountPasteSessionHighlights, []);

  useEffect(() => {
    if (modalityID) {
      setFormModalityID(modalityID);
    }
  }, [modalityID]);

  const arbitraryDateInThePast = useMemo(() => moment('01-01-2012'), []);
  const today = useMemo(() => moment(), []);

  const checkIsDayBlocked = (day: Moment) =>
    inputFocused !== 'startDate' || !notesData
      ? false
      : notesData.items
          .filter((it) => it.roomID === Number(roomID))
          .filter((it) => it.type === 'progress')
          .some((it) => isSameDate(new Date(it.startDate), day.toDate()));

  if (helperDataIsLoading || businessLineIsLoading) {
    return <Spinner />;
  }

  if (helperDataError || businessLineError) {
    return <ErrorScreen message={helperDataError?.message || businessLineError?.message} />;
  }

  const markSectionAsIncomplete = () => {
    if (formSections.sessionDateAndModality.completed) {
      setFormState({
        formSections: {
          ...formSections,
          sessionDateAndModality: {
            open: formSections.sessionDateAndModality.open,
            changed: true,
            completed: false,
          },
        },
      });
    }
  };

  const handleTextRadioButtonPress = () => {
    if (formModalityID !== Modality.TEXT) {
      setDateAndModalityError(false);
      markSectionAsIncomplete();
      setFormModalityID(Modality.TEXT);
    }
    analytics.trackNoteFieldUpdatedEvent({
      updateType: 'textModalityRadioButtonPressed',
      roomID,
      noteID,
    });
  };

  const handleDatesChange = ({
    startDate,
    endDate,
  }: {
    startDate: Moment | null;
    endDate: Moment | null;
  }) => {
    const { items } = notesData || {};
    const clonedStartDate = startDate?.clone().startOf('day').toDate();
    const clonedEndDate = endDate?.clone().endOf('day').toDate();
    const errorMessage = validateDateInput(
      {
        startDate: clonedStartDate,
        endDate: clonedEndDate,
      },
      items,
      noteID ? +noteID : undefined,
      Number(roomID)
    );
    if (errorMessage) {
      setDateAndModalityError(true);
      setDateError(errorMessage);

      analytics.trackProgressNoteDebugEvent({
        debugEventType: 'datesAndModalityValidationFailure',
        roomID,
        noteID,
        validationNotes: items,
        validationErrorMessage: errorMessage,
        modalityComponentStateStartDate: clonedStartDate,
        modalityComponentStateEndDate: clonedEndDate,
      });
    } else {
      setDateAndModalityError(false);
      setDateError(null);
      if (startDate) {
        handleSetMessagingStartDate(startDate);
      }

      analytics.trackProgressNoteDebugEvent({
        debugEventType: 'datesAndModalityValidationSuccess',
        roomID,
        noteID,
        validationNotes: items,
        modalityComponentStateStartDate: clonedStartDate,
        modalityComponentStateEndDate: clonedEndDate,
      });
    }
    handleSetMessagingEndDate(endDate);
    markSectionAsIncomplete();
    setInputFocused(null);
    handleSetMessagingStartDate(startDate);
    handleSetMessagingEndDate(endDate);

    analytics.trackNoteFieldUpdatedEvent({
      updateType: 'formDatesChanged',
      roomID,
      noteID,
    });
  };

  const handleLVSRadioButtonPress = () => {
    if (formModalityID !== Modality.VIDEO) {
      setDateAndModalityError(false);
      markSectionAsIncomplete();
      setFormModalityID(Modality.VIDEO);
    }
    analytics.trackNoteFieldUpdatedEvent({
      updateType: 'LVSModalityRadioButtonPressed',
      roomID,
      noteID,
    });
  };

  const handleLVSChange = (newValues: Array<VideoCallSelectOption> | null) => {
    setDateAndModalityError(false);
    markSectionAsIncomplete();
    setSelectedVideoCalls(newValues || []);

    analytics.trackNoteFieldUpdatedEvent({
      updateType: 'videoCallsListChanged',
      roomID,
      noteID,
    });
  };

  const handleConfirmPress = () => {
    let isError = false;
    if (formModalityID) {
      if (formModalityID === Modality.TEXT) {
        if (!messagingStartDate || !messagingEndDate) {
          setDateError(dateRangeError);
          isError = true;
        }
      } else if (formModalityID === Modality.VIDEO) {
        if (selectedVideoCalls?.length === 0) {
          isError = true;
        }
      }
    } else {
      isError = true;
    }

    if (isError) {
      setDateAndModalityError(true);
      setFormState({ showGlobalError: true });
      return;
    }
    if (formModalityID === Modality.TEXT) {
      setFormState({
        modalityID: Modality.TEXT,
        serviceStartDate: messagingStartDate?.toDate() || null,
        serviceEndDate: messagingEndDate?.toDate() || null,
      });
    } else if (formModalityID === Modality.VIDEO) {
      if (selectedVideoCalls === null || selectedVideoCalls.length === 0) {
        setFormState({
          modalityID: Modality.VIDEO,
          videoCalls: [],
          serviceStartDate: null,
          serviceEndDate: null,
        });
      } else {
        const selectedCallIDs = selectedVideoCalls.map((it) => it.value);
        setFormState({
          modalityID: Modality.VIDEO,
        });
        setServiceDates(selectedCallIDs, {
          videoCalls: selectedVideoCalls.map((it) => {
            return { videoCallID: it.value };
          }),
        });
      }
    }
    setFormState({
      formSections: {
        ...formSections,
        sessionDateAndModality: {
          open: false,
          changed: true,
          completed: true,
        },
        ...(formSections.sessionInformation.completed
          ? {}
          : {
              sessionInformation: {
                ...formSections.sessionInformation,
                open: true,
              },
            }),
      },
    });

    analytics.trackProgressNoteDebugEvent({
      debugEventType: 'DatesAndModalitySectionConfirmed',
      noteID,
      roomID,
      modalityComponentStateModalityID: formModalityID,
      modalityComponentStateStartDate: messagingStartDate,
      modalityComponentStateEndDate: messagingEndDate,
      modalityComponentStateVideoCalls: selectedVideoCalls,
    });
  };

  const handleAccordionPress = (value: boolean) => {
    setFormState({
      formSections: {
        ...formSections,
        sessionDateAndModality: {
          open: value,
          changed: formSections?.sessionDateAndModality.changed,
          completed: formSections?.sessionDateAndModality.completed,
        },
      },
    });
  };

  return (
    <AccordionContainer
      title="Session date and modality"
      open={formSections.sessionDateAndModality.open}
      onPress={handleAccordionPress}
      showCheckMark={formMode !== 'view' && formSections.sessionDateAndModality.completed}
      bodyStyle={{ paddingTop: 0 }}
      dataQa="sessionDateAndModalityAccordion"
    >
      {businessLine!.isPsychiatry || businessLine!.isVideoOnly ? (
        <VideoOnlyBlock>
          <Large
            style={{
              marginBottom: 10,
              ...(dateAndModalityError ? { color: colors.permaDebianRed } : {}),
            }}
          >
            Select the live session for this progress note.
          </Large>
          {disableLVSComponents ? (
            <View style={{ marginLeft: 35 }}>No live sessions to report on </View>
          ) : (
            <Select
              placeholder="Date and time(s) of session"
              isDisabled={
                formModalityID === null ||
                formModalityID === Modality.TEXT ||
                !draftOrCreateMode ||
                disabled
              }
              options={videoCallSelectOptions as OptionsType<any>}
              value={
                videoCallSelectOptions.filter((opt) =>
                  selectedVideoCalls?.map((vc) => vc.value).includes(opt.value)
                ) as ValueType<OptionType<any>, boolean>
              }
              onChange={handleLVSChange as (value: ValueType<OptionType, boolean>) => void}
              isMulti
              styles={lvsSelectStyles(dateAndModalityError)}
              dataQa="lvsDateDropdown"
            />
          )}
        </VideoOnlyBlock>
      ) : (
        <View>
          <Large style={{ margin: 10 }}>
            Select the dates of async messaging or live session for this progress note.
          </Large>
          {helperData!.lastProgressNote && (
            <View>
              <MostRecentNoteMessage>
                <Small>Your most recent progress note was for</Small>
                <Small>
                  {moment(helperData!.lastProgressNote.serviceStartDate).format('MMM D')} -{' '}
                  {moment(helperData!.lastProgressNote.serviceEndDate).format('MMM D , yyyy')}.
                </Small>
              </MostRecentNoteMessage>
            </View>
          )}
          <Block
            isError={dateAndModalityError}
            style={{
              height: messagingDisabled && draftOrCreateMode ? 105 : undefined,
              background: messagingDisabled ? colors.greyBubble : undefined,
            }}
          >
            <Row>
              <RadioButton
                isActive={formModalityID === Modality.TEXT}
                style={{ marginRight: 15 }}
                onPress={handleTextRadioButtonPress}
                disabled={messagingDisabled || !draftOrCreateMode || disabled}
                dataQa="messagingRadioButton"
              />
              <Big>Messaging</Big>
            </Row>
            {messagingDisabled && draftOrCreateMode ? (
              <View style={{ marginLeft: 35 }}>No messaging sessions to report on </View>
            ) : (
              <>
                <Small style={{ marginTop: 10 }}>Dates of service</Small>
                <DateRangePickerWithInput
                  containerStyle={{
                    width: '100%',
                    justifyContent: 'space-between',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  disabled={
                    formModalityID === null ||
                    formModalityID === Modality.VIDEO ||
                    !draftOrCreateMode ||
                    disabled
                  }
                  minimumNights={6}
                  startDate={messagingStartDate}
                  endDate={messagingEndDate}
                  focusedInput={inputFocused}
                  onDatesChange={handleDatesChange}
                  onFocusChange={(newInputFocused) => {
                    if (inputFocused === 'startDate' && newInputFocused === 'endDate') {
                      setInputFocused(null);
                    } else {
                      setInputFocused(newInputFocused);
                    }
                  }}
                  startDatePlaceholderText="Start"
                  endDatePlaceholderText="End"
                  startDateId="messagingStartDate"
                  endDateId="messagingEndDate"
                  isSingle={false}
                  isCentered
                  minDate={arbitraryDateInThePast}
                  maxDate={inputFocused === 'startDate' ? today : undefined}
                  isDayBlocked={checkIsDayBlocked}
                />
                {dateError && <WarningText>{dateError}</WarningText>}
              </>
            )}
          </Block>
          <Block
            isError={dateAndModalityError}
            style={{
              height: disableLVSComponents ? 105 : undefined,
              background: disableLVSComponents ? colors.greyBubble : undefined,
            }}
          >
            <Row>
              <RadioButton
                isActive={formModalityID === Modality.VIDEO}
                style={{ marginRight: 15 }}
                onPress={handleLVSRadioButtonPress}
                disabled={disableLVSComponents || !draftOrCreateMode || disabled}
                dataQa="liveSessionRadioButton"
              />
              <Big>Live session</Big>
            </Row>
            {disableLVSComponents ? (
              <View style={{ marginLeft: 35 }}>No live sessions to report on </View>
            ) : (
              <Select
                placeholder="Session date and time"
                isDisabled={
                  formModalityID === null ||
                  formModalityID === Modality.TEXT ||
                  !draftOrCreateMode ||
                  disabled
                }
                options={videoCallSelectOptions as OptionsType<any>}
                value={
                  videoCallSelectOptions.filter((opt) =>
                    selectedVideoCalls?.map((vc) => vc.value).includes(opt.value)
                  ) as ValueType<OptionType<any>, boolean>
                }
                onChange={handleLVSChange as (value: ValueType<OptionType, boolean>) => void}
                isMulti
                styles={lvsSelectStyles(dateAndModalityError)}
                dataQa="sessionDateDropdown"
              />
            )}
          </Block>
        </View>
      )}
      {draftOrCreateMode && !formSections.sessionDateAndModality.completed && !dateError && (
        <ConfirmButton onPress={handleConfirmPress} sectionTitle="sessionDateAndModality" />
      )}
    </AccordionContainer>
  );
};

export default SessionDateAndModalitySection;
