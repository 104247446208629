import { FunctionComponent, useEffect, useState, MouseEvent, useRef } from 'react';
import { connect } from 'react-redux';
import { View, Heading2, Text, Spinner, TouchableView } from '@talkspace/react-toolkit';

import styled from '@/core/styled/styled';
import { webOnlyStyle } from '@/core/styled/styleHelpers';
import { getUserData } from '@/auth/helpers/token';
import { COLORS } from '../utils/design';
import { AppState } from '../redux/chatStore';
import {
  dispatchRequestStarredMessagesByUser,
  dispatchRequestStarredMessagesInRoom,
  dispatchReceiveStarredMessages,
  dispatchToggleStarMessage,
} from '../redux/actions/chatActions';
import { StarredMessage } from '../entities/StarredMessage';

import NoStarredMessagesIcon from '../components/Icons/NoStarredMessages';
import StarredMessageItem from '../components/StarredMessageItem';
import { useA11y } from './StarredMessagesContainer.a11y';

const Container = styled(View)(({ theme: { colors } }) => {
  return {
    backgroundColor: colors.athensGrey,
    minHeight: '100%',
    paddingTop: 20,
    paddingBottom: 20,
  };
});

const LoadingBanner = styled(View)({
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'stretch',
  height: '10vh',
});

const MessagesContainer = styled(View)({
  width: '100%',
  backgroundColor: COLORS.white,
  border: '1px solid #E9E9E9',
  borderRadius: 5,
  paddingBottom: 30,
  paddingTop: 30,
  ...webOnlyStyle({
    '@media (min-width: 1025px)': {
      width: '70%',
    },
  }),
});

const FlatButton = styled(TouchableView)({
  border: 'none',
  outline: 'none',
  marginLeft: 'auto',
  marginRight: '4vw',
  padding: 0,
});

const LoadMoreButton = styled(TouchableView)({
  border: 'none',
  outline: 'none',
  marginLeft: 'auto',
  paddingLeft: 0,
  paddingRight: 0,
  marginRight: 'auto',
  marginTop: 25,
});

const ButtonText = styled(Text)(({ theme: { colors } }) => {
  return { color: colors.green, fontSize: 16 };
});

const NoStarredMessagesContainer = styled(View)({
  paddingTop: 35,
  paddingBottom: 35,
  textAlign: 'center',
});

const NoStarredMessagesText = styled(Text)({
  color: COLORS.grey,
  marginTop: '2vh',
  marginBottom: '2vh',
});

interface Props {
  roomID?: number;
  getStarredMessagesByUser: (userID: number, lastMessageID?: number) => void;
  getStarredMessagesInRoom: (roomID: number, lastMessageID?: number) => void;
  toggleStarMessage: (messageID: number, isStarred: boolean) => void;
  receiveStarredMessages: (starredMessages: StarredMessage[]) => void;
  starredMessages: StarredMessage[];
  isUpdating: boolean;
  initialLoadComplete: boolean;
  hasReceivedAllStarredMessages: boolean;
  containerWidth: number;
  isTherapistChat: boolean;
}

const StarredMessagesContainer: FunctionComponent<Props> = (props) => {
  const {
    roomID,
    getStarredMessagesByUser,
    getStarredMessagesInRoom,
    toggleStarMessage,
    receiveStarredMessages,
    starredMessages,
    isUpdating,
    initialLoadComplete,
    hasReceivedAllStarredMessages,
    containerWidth,
    isTherapistChat,
  } = props;

  const [isEditKeyboardPressed, setIsEditKeyboardPressed] = useState(false);

  const currentUserID = getUserData().id;

  const [isEditMode, setEditMode] = useState(false);

  const fetchStarredMessages = (lastMessageID?: number) => {
    if (roomID) {
      getStarredMessagesInRoom(roomID, lastMessageID);
    } else {
      getStarredMessagesByUser(currentUserID, lastMessageID);
    }
  };

  useEffect(() => {
    fetchStarredMessages();
    /*
          roomID is not passed as a dependency here because of the way c-web implements modals:
          When opening a modal the URL is appended with "/modal" which triggers an invalid and unwanted re-render
          This doesn't break the app behavior because new roomID is passed to thw component only as a result of a new mounting (user changed the room and re-entered this screen)
          Also, the changes made inside the PDFViewer modal are up-to-date with the store so there's no reason to re-fetch closing the modal and returning to this component
        */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEditPress = (e: MouseEvent<HTMLDivElement | MouseEvent> | KeyboardEvent) => {
    if (!isEditMode && e.type === 'keydown') {
      setIsEditKeyboardPressed(true);
    } else {
      setIsEditKeyboardPressed(false);
    }
    if (isEditMode) {
      const editedStarredMessages = starredMessages.filter((msg) => msg.isStarred);
      receiveStarredMessages(editedStarredMessages);
    }
    setEditMode(!isEditMode);
  };

  const handleStarClick = (message: StarredMessage) => {
    toggleStarMessage(message.messageID, message.isStarred);
  };

  const handleLoadMoreClick = () => {
    const lastMessageIndex = starredMessages.length - 1;
    fetchStarredMessages(starredMessages[lastMessageIndex].messageID);
  };

  const NoStarredMessagesBanner = () => (
    <NoStarredMessagesContainer align="center" style={{ padding: 5 }}>
      <NoStarredMessagesIcon style={{ marginBottom: 30 }} />
      <Heading2>You haven't starred any messages yet</Heading2>
      <NoStarredMessagesText>
        Starring a message will save it here for easy access.
      </NoStarredMessagesText>
    </NoStarredMessagesContainer>
  );
  const fisrstStarRef = useRef<HTMLDivElement>(null);
  useA11y(isEditMode, isEditKeyboardPressed, fisrstStarRef);
  return (
    <Container align="center">
      <MessagesContainer>
        {isUpdating ? (
          <LoadingBanner>
            <Text>Loading messages...</Text>
            <Spinner isLoading />
          </LoadingBanner>
        ) : (
          <>
            {starredMessages.length > 0 && (
              <FlatButton onPress={handleEditPress}>
                <ButtonText>{isEditMode ? 'Done' : 'Edit'}</ButtonText>
              </FlatButton>
            )}
            <View role={starredMessages.length > 0 ? 'list' : undefined}>
              {initialLoadComplete && starredMessages.length === 0 ? (
                <NoStarredMessagesBanner />
              ) : (
                starredMessages.length > 0 &&
                starredMessages.map((message, idx) => (
                  <StarredMessageItem
                    firstStarRef={idx === 0 ? fisrstStarRef : undefined}
                    key={message.messageID}
                    message={message}
                    shouldShowStar={isEditMode}
                    handleStarClick={() => handleStarClick(message)}
                    isSelf={currentUserID === message.userID}
                    isPeer={
                      currentUserID !== message.userID &&
                      message.userType !== 'therapist' &&
                      !isTherapistChat
                    }
                    hideBottomBorder={
                      idx + 1 === starredMessages.length && hasReceivedAllStarredMessages
                    }
                    containerWidth={containerWidth}
                  />
                ))
              )}
            </View>
            {!hasReceivedAllStarredMessages && (
              <LoadMoreButton onPress={handleLoadMoreClick}>
                <ButtonText>Load more</ButtonText>
              </LoadMoreButton>
            )}
          </>
        )}
      </MessagesContainer>
    </Container>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    starredMessages: state.chat.starredMessages,
    isUpdating: state.chat.starredIsUpdating,
    initialLoadComplete: state.chat.starredInitialLoadComplete,
    hasReceivedAllStarredMessages: state.chat.starredHasReceivedAll,
  };
};

const mapDispatchToProps = {
  getStarredMessagesByUser: dispatchRequestStarredMessagesByUser,
  getStarredMessagesInRoom: dispatchRequestStarredMessagesInRoom,
  toggleStarMessage: dispatchToggleStarMessage,
  receiveStarredMessages: dispatchReceiveStarredMessages,
};

const MessagesContainerWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(StarredMessagesContainer);

export default MessagesContainerWithRedux;
