import Svg, { Defs, Circle, G, Mask, Use, Path } from 'svgs';
import { EmotionStyle, useEmotionTheme } from '../../core/styled';

interface AvatarCircleProps {
  width?: number;
  height?: number;
  titleText?: string;
  style?: EmotionStyle;
}

const AvatarCircle = ({
  width = 128,
  height = 128,
  titleText = 'Avatar',
  ...otherProps
}: AvatarCircleProps) => {
  const { colors } = useEmotionTheme();
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 100 100"
      title={titleText}
      aria-label={titleText}
      {...otherProps}
    >
      <Defs>
        <Circle id="AvatarCircle-a" cx={50} cy={50} r={50} />
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <G transform="translate(-637 -143) translate(463 113) translate(0 30) translate(174)">
          <Mask fill="#fff">
            <Use xlinkHref="#AvatarCircle-a" />
          </Mask>
          <Use fill="#EFF4F6" xlinkHref="#AvatarCircle-a" />
        </G>
        <Path
          d="M66.006 67.073h-32.01c-1.723 0-2.996-1.7-2.346-3.263C34.665 56.58 41.75 52.439 50 52.439c8.253 0 15.336 4.141 18.352 11.37.65 1.564-.623 3.264-2.346 3.264M40.04 37.805c0-5.38 4.47-9.756 9.96-9.756 5.492 0 9.959 4.375 9.959 9.756 0 5.38-4.467 9.756-9.96 9.756-5.49 0-9.958-4.376-9.958-9.756"
          fill={colors.permaViridianGreen}
          transform="translate(-637 -143) translate(463 113) translate(0 30) translate(174)"
        />
      </G>
    </Svg>
  );
};

export default AvatarCircle;
