import Svg, { Path } from 'svgs';
import { useEmotionTheme, EmotionStyle } from '../../core/styled';

const ArrowDownV2 = ({
  width = 13,
  height = 8,
  color,
  style,
  ...otherProps
}: {
  width?: number;
  height?: number;
  color?: string;
  style?: EmotionStyle;
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'up arrow';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 13 8"
      style={style}
      {...otherProps}
    >
      <Path
        d="M10.75 0.749999C11.1642 0.335786 11.8358 0.335787 12.25 0.75V0.75C12.6642 1.16421 12.6642 1.83579 12.25 2.25L6.5 8L0.750001 2.25C0.335787 1.83579 0.335787 1.16421 0.75 0.75V0.75C1.16421 0.335787 1.83579 0.335787 2.25 0.75L6.5 5L10.75 0.749999Z"
        fill={color || colors.accessibilityGreenDark}
        fillRule="evenodd"
        clipRule="evenodd"
        stroke={color || colors.accessibilityGreenDark}
        strokeWidth={0.5}
        strokeLinecap="round"
      />
    </Svg>
  );
};

export default ArrowDownV2;
