import { FunctionComponent, useState, useEffect, MouseEvent, FocusEvent, TouchEvent } from 'react';
import { EmotionStyle } from '@/core/styled/styled';
import SliderView from './SliderView';

interface SliderProps {
  disabled: boolean;
  value: number;
  maxValue: number;
  minValue?: number;
  className?: string;
  style?: EmotionStyle;
  onValueChange?: (value: number) => void;
  filledColor?: string;
  backgroundColor?: string;
  step?: number;
  onMouseDown: (e: MouseEvent<HTMLDivElement | MouseEvent> | TouchEvent) => void;
  onMouseUp: (e: MouseEvent<HTMLDivElement | MouseEvent> | TouchEvent) => void;
  onBlur: (e: FocusEvent<HTMLInputElement>) => void;
}

const Slider: FunctionComponent<SliderProps> = ({ value, ...otherProps }) => {
  const [currentValue, setCurrentValue] = useState(value);
  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  return <SliderView value={currentValue} {...otherProps} />;
};

export default Slider;
