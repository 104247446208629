import Svg, { Path, Defs, G, Rect } from 'svgs';
import { useEmotionTheme } from '../../core/styled';
import type { EmotionStyle } from '../../core/styled';
import { useUniqueID } from '../../hooks/a11yHelper';

interface Props {
  width?: number;
  height?: number;
  color?: string;
  style?: EmotionStyle;
}

const ChatSquareOutlineIcon = ({ color, width = 20, height = 20, style }: Props) => {
  const { colors } = useEmotionTheme();
  const title = 'Chat outline icon';
  const clipID = useUniqueID('clipID');
  return (
    <Svg
      width={width}
      height={height}
      title={title}
      aria-label={title}
      viewBox="0 0 20 20"
      fill="none"
      style={style}
    >
      <G clipPath={`url(#${clipID})`}>
        <Path
          d="M6.59375 14.2695C7.60303 14.2695 8.42188 15.0884 8.42188 16.0977V16.707L11.1831 14.6352C11.4992 14.399 11.8839 14.2695 12.28 14.2695H17.5625C17.8977 14.2695 18.1719 13.9953 18.1719 13.6602V2.69141C18.1719 2.35625 17.8977 2.08203 17.5625 2.08203H2.9375C2.60234 2.08203 2.32812 2.35625 2.32812 2.69141V13.6602C2.32812 13.9953 2.60234 14.2695 2.9375 14.2695H6.59375ZM8.42188 18.9922L8.41426 18.9998L8.22002 19.1445L7.56875 19.632C7.38594 19.7691 7.13838 19.792 6.92891 19.6892C6.71943 19.5863 6.59375 19.3769 6.59375 19.1445V18.3333V18.0896V18.0781V17.9258V16.0977H4.76562H2.9375C1.59307 16.0977 0.5 15.0046 0.5 13.6602V2.69141C0.5 1.34697 1.59307 0.253906 2.9375 0.253906H17.5625C18.9069 0.253906 20 1.34697 20 2.69141V13.6602C20 15.0046 18.9069 16.0977 17.5625 16.0977H12.28L8.42188 18.9922Z"
          fill={color || colors.grey950}
        />
      </G>
      <Defs>
        <clipPath id={clipID}>
          <Rect width="19.5" height="19.5" fill="white" transform="translate(0.5 0.253906)" />
        </clipPath>
      </Defs>
    </Svg>
  );
};

export default ChatSquareOutlineIcon;
