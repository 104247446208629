import { VoidFunctionComponent } from 'react';
import Svg, { G, Path, Rect } from 'svgs';
import { useEmotionTheme, EmotionStyle } from '../../core/styled';
import withHover, { WithHoverProps } from '../../hoc/withHover';

interface IDBoxProps extends WithHoverProps {
  width?: number;
  height?: number;
  style?: EmotionStyle;
  isMenuShowing?: boolean;
}

const IDBox: VoidFunctionComponent<IDBoxProps> = ({
  width = 15,
  height = 15,
  isHovering,
  isMenuShowing,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'more information';

  if (isHovering || isMenuShowing) {
    return (
      <Svg width={width} height={height} title={titleText} aria-label={titleText} {...otherProps}>
        <G fill="none" fillRule="evenodd">
          <Path fill="#FFF" d="M-446-351H754v820H-446z" />
          <G>
            <Rect
              stroke={colors.permaBlueStone}
              fill={colors.permaBlueStone}
              x={0.5}
              y={0.5}
              width={width - 1}
              height={height - 1}
              rx={4}
            />
            <Path
              d="M5.537 4.244V10.5H4.089V4.244h1.448Zm3.338 0c.737 0 1.352.24 1.848.72s.743 1.094.743 1.845v1.126c0 .753-.247 1.369-.743 1.847-.496.479-1.111.718-1.848.718h-2.26V4.244h2.26ZM8.777 5.36h-.705v4.022h.705c.386 0 .688-.133.906-.398.218-.265.327-.615.327-1.05V6.8c0-.432-.11-.78-.327-1.044-.218-.263-.52-.395-.906-.395Z"
              fill="#FFF"
              fillRule="nonzero"
            />
          </G>
        </G>
      </Svg>
    );
  }

  return (
    <Svg width={width} height={height} title={titleText} aria-label={titleText} {...otherProps}>
      <G fill="none" fillRule="evenodd">
        <Rect stroke={colors.permaBlueStone} x={0.5} y={0.5} width={14} height={14} rx={4} />
        <Path
          d="M5.537 4.244V10.5H4.089V4.244h1.448Zm3.338 0c.737 0 1.352.24 1.848.72s.743 1.094.743 1.845v1.126c0 .753-.247 1.369-.743 1.847-.496.479-1.111.718-1.848.718h-2.26V4.244h2.26ZM8.777 5.36h-.705v4.022h.705c.386 0 .688-.133.906-.398.218-.265.327-.615.327-1.05V6.8c0-.432-.11-.78-.327-1.044-.218-.263-.52-.395-.906-.395Z"
          fill={colors.permaTalkspaceDarkGreen}
          fillRule="nonzero"
        />
      </G>
    </Svg>
  );
};

export default withHover(IDBox);
