import { useRouteMatch } from 'react-router';
import useIsClientDischargeV3OnForPlan from 'ts-frontend/hooks/useIsClientDischargeV3OnForPlan';

import DischargeNoteForm from './DischargeNoteForm';
import { FormMode } from './types';
import DischargeNoteFormV3 from './DischargeNoteFormV3';

interface Condition {
  id: number;
  isWorkingDiagnosis: boolean;
  label: string;
  value: number;
}

interface Props {
  mode: FormMode;
  clientUserID: number;
  conditions: Array<Condition>;
  getConditions: (clientUserID: number) => void;
}
const DischargeNoteFormNavigator = (props: Props) => {
  const match = useRouteMatch<{ roomID: string }>();
  const isClientDischargeV3OnForPlan = useIsClientDischargeV3OnForPlan(match.params.roomID);

  return isClientDischargeV3OnForPlan ? (
    <DischargeNoteFormV3 {...props} />
  ) : (
    <DischargeNoteForm {...props} />
  );
};

export default DischargeNoteFormNavigator;
