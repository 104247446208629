import styled from '../../core/styled';
import { COLORS } from '../../constants/commonStyles';
import Text from '../Text';

const extraBigDefaultStyle = {
  color: COLORS.black,
  fontSize: 19,
  fontWeight: 700,
  letterSpacing: 0.11,
};

const ExtraBig = styled(Text)(extraBigDefaultStyle);

export default ExtraBig;
