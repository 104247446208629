import { useEffect, VoidFunctionComponent } from 'react';
import { connect } from 'react-redux';
import { trackJoinedAdHocSession } from 'ts-analytics/mixpanel/events';
import { LiveCallType } from 'ts-frontend/types';
import { RouteComponentProps, withRouter } from '@/core/routerLib';
import { useCloseModal } from '@/utils/ModalsContextProvider';
import LiveChatSessionStartedModal from '../components/LiveChatSessionStartedModal';
import { AppState } from '../redux/chatStore';
import { dispatchPostLiveSessionEvent } from '../redux/actions/chatActions';
import { VideoCallEvent } from '../types/videoCallTypes';

interface OwnProps {
  userAlreadyJoinedSession?: boolean;
  postLiveSessionIsError: boolean;
  sessionAlreadyEnded: boolean;
  isTherapistChat?: boolean;
  postEvent: (roomID: number, videoCallID: number, eventType: VideoCallEvent) => void;
}

interface LocationState {
  videoCallID: number;
  roomID: number;
  callType?: LiveCallType;
  userID?: number;
  providerID?: number;
}

type Props = OwnProps & RouteComponentProps<{}, {}, LocationState>;

const LiveChatSessionStartedModalContainer: VoidFunctionComponent<Props> = ({
  location,
  userAlreadyJoinedSession,
  sessionAlreadyEnded,
  postLiveSessionIsError,
  isTherapistChat,
  postEvent,
}) => {
  const {
    state: { videoCallID, roomID, callType, userID, providerID },
  } = location;

  useEffect(() => {
    postEvent(
      roomID,
      videoCallID,
      isTherapistChat ? VideoCallEvent.therapistJoined : VideoCallEvent.clientJoined
    );
  }, [isTherapistChat, postEvent, roomID, videoCallID]);

  useEffect(() => {
    if (!isTherapistChat && callType && callType === 'bh_ad_hoc' && userID && providerID) {
      trackJoinedAdHocSession({
        userID,
        roomID,
        sessionModality: 'chat',
        providerID,
      });
    }
  }, [callType, isTherapistChat, providerID, roomID, userID]);

  const closeModal = useCloseModal();

  const handleClose = () => {
    closeModal();
  };

  return (
    <LiveChatSessionStartedModal
      handleClose={handleClose}
      isTherapistChat={isTherapistChat}
      userAlreadyJoinedSession={userAlreadyJoinedSession}
      sessionAlreadyEnded={sessionAlreadyEnded}
      postLiveSessionIsError={postLiveSessionIsError}
    />
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    userAlreadyJoinedSession: state.chat.userAlreadyJoinedSession,
    sessionAlreadyEnded: state.chat.sessionAlreadyEnded,
    postLiveSessionIsError: state.chat.postLiveSessionIsError,
    isTherapistChat: state.chat.isTherapistChat,
  };
};

const mapDispatchToProps = {
  postEvent: dispatchPostLiveSessionEvent,
};

const LiveChatSessionStartedModalContainerWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(LiveChatSessionStartedModalContainer);

export default withRouter(LiveChatSessionStartedModalContainerWithRedux);
