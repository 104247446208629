import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const standardPath = '';
const majorPath =
  'M4.86382 7.83587L12.0516 4.78795L19.2393 7.83587C19.4711 7.93407 19.5967 8.14224 19.5928 8.33862C19.5771 11.9286 18.0846 18.1305 12.2755 20.9113C12.1341 20.9781 11.9691 20.9781 11.8316 20.9113C6.01858 18.1265 4.52997 11.9286 4.51033 8.33469C4.51033 8.13831 4.63209 7.93407 4.86382 7.83195V7.83587ZM19.9738 6.09982L12.5779 2.96156C12.4129 2.88693 12.2362 2.84766 12.0516 2.84766C11.867 2.84766 11.6902 2.88693 11.5253 2.96156L4.12934 6.09982C3.26524 6.4651 2.62109 7.31741 2.62502 8.34648C2.64466 12.2428 4.24717 19.3716 11.0146 22.612C11.6706 22.9262 12.4326 22.9262 13.0885 22.612C19.8599 19.3716 21.4585 12.2428 21.4781 8.34648C21.482 7.31741 20.8379 6.4651 19.9738 6.09982ZM11.1089 16.359C11.1089 16.8814 11.5292 17.3017 12.0516 17.3017C12.574 17.3017 12.9942 16.8814 12.9942 16.359V13.8453H15.508C16.0304 13.8453 16.4506 13.425 16.4506 12.9026C16.4506 12.3803 16.0304 11.96 15.508 11.96H12.9942V9.44624C12.9942 8.92385 12.574 8.50359 12.0516 8.50359C11.5292 8.50359 11.1089 8.92385 11.1089 9.44624V11.96H8.59517C8.07278 11.96 7.65251 12.3803 7.65251 12.9026C7.65251 13.425 8.07278 13.8453 8.59517 13.8453H11.1089V16.359Z';

const pathConfig: IconConfig = {
  standard: {
    default: {
      path: standardPath,
    },
  },
  major: {
    default: {
      path: majorPath,
    },
  },
};

const ShieldPlus = withDSIconMaker(pathConfig, 'plus inside shield');

export default ShieldPlus;
