import { useRef, useEffect, useCallback } from 'react';
import { Row, Col, Panel, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { connect } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import moment from 'moment-timezone';
import { DateRangePickerWithInput } from '@talkspace/react-toolkit';
import useObjectState from 'chat/hooks/useObjectState';
import { TimeOffCancelBookingsModal } from '../../../modules/upcomingBookings';
import apiWrapper from '../../../utils/apiWrapper';
import apiHelper from '../../../utils/api';
import { getUserData } from '../../../utils/token';
import 'react-dates/lib/css/_datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import '../Account.css';

const TherapistTimeOff = ({ getTimeOff, timeOff, getBookings, bookings, isLoadingBookings }) => {
  const [state, setState] = useObjectState({
    title: 'Time Off',
    isEdit: false,
    contentId: undefined,
    startDate: undefined,
    endDate: undefined,
    DatePickerDisabled: true,
    disableSave: true,
    timeOffState: undefined,
    isEditVisible: true,
    isSaveVisible: false,
    isDeleteVisible: false,
    isEndVisible: false,
    isLoaded: false,
    isModalVisible: false,
    isAttemptingSave: false,
  });

  const { startDate: prevStartDate, endDate: prevEndDate } = timeOff || {};

  useEffect(() => {
    if (timeOff == null) {
      setState({
        contentId: undefined,
        timeOffState: 0,
        startDate: undefined,
        endDate: undefined,
        isLoaded: true,
      });
    } else if (timeOff.state == 4 || timeOff.state == 2) {
      setState({
        contentId: timeOff.contentId,
        timeOffState: timeOff.state,
        startDate: undefined,
        endDate: undefined,
        isLoaded: true,
      });
    } else {
      setState({
        startDate: moment(timeOff.startDate),
        endDate: moment(timeOff.endDate),
        contentId: timeOff.contentId,
        timeOffState: timeOff.state,
        isLoaded: true,
      });
    }
  }, [timeOff, setState]);

  useEffect(() => {
    if (state.isEdit) {
      if (
        state.startDate &&
        state.startDate.format('MM/DD/YY') === prevStartDate &&
        state.endDate &&
        state.endDate.format('MM/DD/YY') === prevEndDate
      ) {
        setState({ isSaveVisible: false, disableSave: true });
      } else {
        setState({ isSaveVisible: true, disableSave: false });
      }
    }
  }, [
    state.startDate,
    state.endDate,
    setState,
    prevStartDate,
    prevEndDate,
    state.isEditVisible,
    state.isEdit,
  ]);

  useEffect(() => {
    // this either saves the form or launches the modal to cancel bookings
    if (bookings && !isLoadingBookings && state.isAttemptingSave) {
      setState({ isAttemptingSave: false });
      if (bookings.length) {
        setState({ isModalVisible: true });
      } else {
        saveForm();
      }
    }
  }, [bookings, saveForm, setState, isLoadingBookings, state.isAttemptingSave]);

  const dateRangePickerRef = useRef();

  const dateFocusChanged = (event) => {
    setState({ focusedInput: event });
  };

  const deletePeriod = () => {
    setState({ disableSave: true });
    const payload = {
      ignoreLoadingBar: true,
      contentId: state.contentId,
    };
    const userId = getUserData().id;
    toggleFormEdit();
    setState({
      disableSave: false,
      isEditVisible: true,
      isEdit: false,
      isSaveVisible: false,
      isDeleteVisible: false,
      isEndVisible: false,
      isCancelVisible: false,
      DatePickerDisabled: true,
      startDate: undefined,
      endDate: undefined,
    });
    return apiWrapper
      .post(
        `${apiHelper().apiEndpoint}/api/v1/therapist/${userId}/cancel-unavailability-period`,
        payload
      )
      .then(() => {
        toast(<div className="toaster toaster-success">Period ended successfully.</div>, {
          autoClose: 3000,
        });
        getTimeOff();
      })
      .catch((err) => {
        toast(<div className="toaster toaster-error">Failed to delete time off period</div>, {
          autoClose: 3000,
        });
        getTimeOff();
      });
  };

  const endPeriod = () => {
    setState({ disableSave: true });
    const payload = {
      ignoreLoadingBar: true,
      contentId: state.contentId,
    };
    const userId = getUserData().id;
    toggleFormEdit();
    return apiWrapper
      .post(
        `${apiHelper().apiEndpoint}/api/v1/therapist/${userId}/end-unavailability-period`,
        payload
      )
      .then(() => {
        setState({ disableSave: false });
        toast(<div className="toaster toaster-success">Period ended successfully.</div>, {
          autoClose: 3000,
        });
        setState({
          isEditVisible: true,
          isEdit: false,
          isSaveVisible: false,
          isDeleteVisible: false,
          isEndVisible: false,
          isCancelVisible: false,
          DatePickerDisabled: true,
        });
        getTimeOff();
      })
      .catch((err) => {
        setState({ disableSave: false });
        toast(<div className="toaster toaster-error">{err}</div>, {
          autoClose: 3000,
        });
      });
  };

  const showCancelBookingsOrSave = () => {
    // disable saving so people cant hammer the button
    setState({ disableSave: true, isAttemptingSave: true });
    getBookings(state.startDate.toISOString(), state.endDate.toISOString());
  };

  const saveForm = useCallback(() => {
    // get payload from state
    const payload = {
      startDate: state.startDate.format('YYYY-MM-DD'),
      endDate: state.endDate.format('YYYY-MM-DD'),
      ignoreLoadingBar: true,
    };
    // get user token and id for API call
    const userId = getUserData().id;
    // toggle the form to close it.
    toggleFormEdit();

    // if time off state is not initial state(0) or canceled(2) or ended(4) we ca use update time off endpoint otherwise use create new time off endpoint
    if (![0, 2, 4].includes(state.timeOffState)) {
      payload.contentId = state.contentId;
      return apiWrapper
        .put(`${apiHelper().apiEndpoint}/api/v1/therapist/${userId}/unavailability-period`, payload)
        .then(() => {
          setState({ disableSave: false });
          toast(<div className="toaster toaster-success">Time off updated.</div>, {
            autoClose: 3000,
          });
          getTimeOff();
          setState({
            isEditVisible: true,
            isEdit: false,
            isSaveVisible: false,
            isDeleteVisible: false,
            isEndVisible: false,
            isCancelVisible: false,
            DatePickerDisabled: true,
          });
        })
        .catch((err) => {
          setState({ disableSave: false, isAttemptingSave: false });
          toast(<div className="toaster toaster-error">{err}</div>, {
            autoClose: 3000,
          });
        });
    }
    return apiWrapper
      .post(`${apiHelper().apiEndpoint}/api/v1/therapist/${userId}/unavailability-period`, payload)
      .then(() => {
        setState({ disableSave: false });
        toast(<div className="toaster toaster-success">Time off saved.</div>, {
          autoClose: 3000,
        });
        getTimeOff();
        setState({
          isEditVisible: true,
          isEdit: false,
          isSaveVisible: false,
          isDeleteVisible: false,
          isEndVisible: false,
          isCancelVisible: false,
          DatePickerDisabled: true,
        });
      })
      .catch((err) => {
        setState({ disableSave: false });
        toast(<div className="toaster toaster-error">{err}</div>, {
          autoClose: 3000,
        });
      });
  }, [
    getTimeOff,
    setState,
    state.contentId,
    state.endDate,
    state.startDate,
    state.timeOffState,
    toggleFormEdit,
  ]);

  const onDatesChange = (event) => {
    setState({
      startDate: event.startDate,
      endDate: event.endDate,
    });
  };

  const toggleFormEdit = useCallback(() => {
    const editBool = !state.isEdit;
    if (editBool) {
      // edit mode is on
      setState({
        isEdit: true,
        DatePickerDisabled: false,
        isEditVisible: false,
        isCancelVisible: true,
        isSaveVisible: true,
      });
      // if the user hasn't filled anything out than dont let them save
      if (state.startDate == null || state.endDate == null) {
        setState({
          disableSave: true,
        });
      }
      // if the startDate is today give user option to end time period or timeOffState == 3
      if (state.timeOffState == 3) {
        setState({
          isEndVisible: true,
        });
        setState({ focusedInput: 'endDate' });
        const startDateEl = document.querySelector(
          '.DateRangePickerInput > .DateInput > #timeOffStartDate'
        );
        startDateEl.disabled = true;
        const startDateDiv = startDateEl.nextSibling.nextSibling;
        if (startDateDiv !== null) {
          startDateDiv.classList.add('disabled-picker');
        }
      }
      // Allow user to delete already existing future time off period
      if (state.timeOffState == 1) {
        setState({
          isDeleteVisible: true,
        });
      }
    }
  }, [setState, state.endDate, state.isEdit, state.startDate, state.timeOffState]);

  const handleCloseModal = () => {
    setState({
      isModalVisible: false,
    });
  };

  const cancelEdit = () => {
    setState({
      isEditVisible: true,
      isEdit: false,
      isSaveVisible: false,
      isDeleteVisible: false,
      isEndVisible: false,
      isCancelVisible: false,
      DatePickerDisabled: true,
    });
    getTimeOff();
  };

  const tooltip = (
    <Tooltip id="TimeOffTooltip" className="tooltip ts-tooltip ts-profile-text-white">
      Time Off Periods will be used to inform your clients ahead of time. During these times, new
      clients will not be able to select you as their therapist.
    </Tooltip>
  );

  if (state.timeOffState == 3) {
    const startDateEl = document.querySelector(
      '.DateRangePickerInput > .DateInput > #timeOffStartDate'
    );
    startDateEl.disabled = true;
    const startDateDiv = startDateEl.nextSibling.nextSibling;
    if (startDateDiv !== null) {
      startDateDiv.classList.add('disabled-picker');
    }
  }

  return (
    <div id="time-off">
      <Col xs={12} className={`ts-panel-title ${state.isLoaded ? 'show-panel' : 'hidden-panel'}`}>
        <Col xs={3} sm={3} className="ts-font-black">
          {state.title}{' '}
          <OverlayTrigger placement="right" trigger={['click', 'focus', 'hover']} overlay={tooltip}>
            <i className="fa fa-fw fa-question-circle fa-lg" />
          </OverlayTrigger>
        </Col>
        <Button
          className={`ts-edit-button pull-right ${state.isEditVisible ? '' : 'hidden'}`}
          onClick={toggleFormEdit}
        >
          Edit
        </Button>

        <Button
          className={`btn-ts-default btn-ts-green ts-profile-text-white pull-right save-btn ${
            state.isSaveVisible ? '' : 'hidden'
          }`}
          onClick={showCancelBookingsOrSave}
          disabled={state.disableSave}
        >
          Save Changes
        </Button>

        <Button
          className={`btn-ts-default btn-ts-green ts-profile-text-white pull-right end-btn ${
            state.isEndVisible ? '' : 'hidden'
          }`}
          onClick={endPeriod}
        >
          End Period
        </Button>
        <Button
          className={`btn-ts-default btn-ts-green ts-profile-text-white pull-right delete-btn ${
            state.isDeleteVisible ? '' : 'hidden'
          }`}
          onClick={deletePeriod}
        >
          Delete Period
        </Button>

        <Button
          className={`btn-ts-default ts-profile-btn-text-green pull-right cancel-btn ${
            state.isCancelVisible ? '' : 'hidden'
          }`}
          onClick={cancelEdit}
        >
          Cancel
        </Button>
      </Col>
      <Col xs={12}>
        <Panel className={`ts-my-account-panel ${state.isLoaded ? 'show-panel' : 'hidden-panel'}`}>
          <Row>
            <Col
              md={2}
              className="ts-profile-label"
              style={{ paddingTop: '12px', paddingBottom: '12px' }}
            >
              Time Off Period
            </Col>
            <Col md={10}>
              <DateRangePickerWithInput
                numberOfMonths={1}
                minimumNights={0}
                hideKeyboardShortcutsPanel
                ref={dateRangePickerRef}
                startDate={state.startDate}
                endDate={state.endDate}
                focusedInput={state.focusedInput}
                onDatesChange={onDatesChange}
                onFocusChange={dateFocusChanged}
                keepOpenOnDateSelect
                startDatePlaceholderText="Start"
                endDatePlaceholderText="End"
                startDateId="timeOffStartDate"
                endDateId="timeOffEndDate"
                disabled={state.DatePickerDisabled}
              />
            </Col>
          </Row>
          <TimeOffCancelBookingsModal
            isVisible={state.isModalVisible}
            closeModal={handleCloseModal}
            bookings={bookings}
            scheduleTimeOff={saveForm}
          />
        </Panel>
      </Col>

      <ToastContainer closeButton={false} position={toast.POSITION.BOTTOM_RIGHT} hideProgressBar />
    </div>
  );
};

export default TherapistTimeOff;
