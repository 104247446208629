import { FunctionComponent, useRef, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import {
  View,
  Large,
  ExtraHuge,
  ExtraBig,
  StickyContainer,
  useContainerFocus,
  useUniqueID,
} from '@talkspace/react-toolkit';
import { Stripe } from '@stripe/stripe-js';
import { PaymentDetails as PaymentDetailsInterface, Provider, Currency } from 'ts-frontend/types';
import { formatCurrency } from 'ts-frontend/helpers/billingUtils';
import { useTranslation } from '@talkspace/i18n';
import styled, { EmotionStyle } from '@/core/styled';
import PaymentForm from '../PaymentForm';

const Container = styled(View)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      maxWidth: isMobile ? 345 : undefined,
      width: isMobile ? '100%' : 475,
      '&:focus': { outline: 'none' },
    };
  }
);

const DueNowContainer = styled(View)(({ theme: { colors } }) => {
  return {
    marginTop: 8,
    marginLeft: -3,
    paddingLeft: 8,
    marginRight: -3,
    paddingRight: 8,
    borderRadius: 5,
    paddingTop: 4,
    paddingBottom: 4,
    backgroundColor: colors.permaSolitudeNew,
    flexDirection: 'row',
    justifyContent: 'space-between',
  };
});

const TotalsContainerForCopayAtTimeOfService = styled(View)(({ theme: { colors } }) => {
  return {
    width: '100%',
    alignItems: 'center',
    border: `1px solid ${colors.athensGrey}`,
    borderTopWidth: 1,
    borderTopColor: '#0000000E',
    boxShadow: '0 5px 18px 0 #233C5119',
    borderRadius: '0 0 16px 16px',
    background: colors.white,
    paddingBottom: 16,
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 16,
  };
});

const TotalSummaryCopayAtTimeOfService = ({
  total,
  currency,
  style,
  perSession,
  perSessionMin,
  specifyCopay,
}: {
  total: number;
  currency: Currency;
  style?: EmotionStyle;
  perSession?: number;
  perSessionMin?: number;
  specifyCopay?: boolean;
}) => {
  const dueTitle = specifyCopay ? 'Copay due at first session' : 'Due after first session';
  const dueCost =
    perSessionMin !== undefined && perSession !== undefined
      ? `${formatCurrency(perSessionMin, currency)}-${formatCurrency(perSession, currency)}`
      : formatCurrency(total, currency);

  return (
    <TotalsContainerForCopayAtTimeOfService>
      <View style={{ maxWidth: 375, width: '100%', ...style }}>
        <View row justify="space-between" style={{ paddingLeft: 5, paddingRight: 5 }}>
          <ExtraBig>{dueTitle}</ExtraBig>
          <ExtraBig>{dueCost}</ExtraBig>
        </View>
        <DueNowContainer>
          <ExtraBig>Due now</ExtraBig>
          <ExtraBig>{formatCurrency(0, currency)}</ExtraBig>
        </DueNowContainer>
      </View>
    </TotalsContainerForCopayAtTimeOfService>
  );
};

const TotalsContainer = styled(View)(({ theme: { colors } }) => {
  return {
    width: '100%',
    alignItems: 'center',
    border: `1px solid ${colors.athensGrey}`,
    borderTopColor: colors.grey600,
    boxShadow: '0 5px 18px 0 #233C5119',
    borderRadius: '0 0 16px 16px',
    background: colors.white,
    paddingBottom: 17,
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 11,
  };
});

const TotalsSummary = ({
  total,
  savings,
  currency,
  isNoMatches,
  showSavings,
  totalDueText,
  style,
}: {
  total: number;
  savings?: number;
  currency: Currency;
  isNoMatches?: boolean;
  showSavings?: boolean;
  totalDueText?: string;
  style?: EmotionStyle;
}) => {
  const { t: tBookingScreen } = useTranslation('bookingScreen');
  return (
    <TotalsContainer>
      <View style={{ maxWidth: 475, width: '100%', ...style }}>
        {!!savings && savings > 0 && showSavings && (
          <View row justify="space-between">
            <Large variant="largeBoldBrightGreen">
              {tBookingScreen('payment.yourSavings', 'Your savings', undefined)}
            </Large>
            <Large variant="largeBoldBrightGreen">-{formatCurrency(savings, currency)}</Large>
          </View>
        )}
        <View row justify="space-between">
          {totalDueText ? (
            <ExtraBig>{totalDueText}</ExtraBig>
          ) : (
            <ExtraBig>
              {isNoMatches
                ? tBookingScreen('payment.onMatch', 'Total due on match', undefined)
                : tBookingScreen('payment.dueToday', 'Total due today', undefined)}
            </ExtraBig>
          )}
          <ExtraBig>{formatCurrency(total, currency)}</ExtraBig>
        </View>
      </View>
    </TotalsContainer>
  );
};

export interface Props {
  onSubmit: (token: string, paymentDetails?: PaymentDetailsInterface, email?: string) => void;
  resetError?: () => void;
  onLoadStripeLink: () => void;
  onStripeLinkError: (error: any) => void;
  email?: string;
  isEmailRequired?: boolean;
  provider: Provider;
  submitText: string;
  currency: Currency;
  total: number;
  savings?: number;
  totalsVariant?: 'none' | 'standard' | 'bh';
  errorMessage?: string;
  isProcessing: boolean;
  stripe: Stripe;
  isNoMatches?: boolean;
  containerStyle?: EmotionStyle;
  paymentFormContainerStyle?: EmotionStyle;
  heading1Style?: EmotionStyle;
  heading2Style?: EmotionStyle;
  isEmailDisabled?: boolean;
  buttonDescribedBy?: string;
  additionalMessage?: JSX.Element | string;
  useStripeLink: boolean;
  showSavings?: boolean;
  totalDueText?: string;
  subtitle?: string | null | JSX.Element;
  maxCost?: number;
  stickySummary?: {
    /** Reference to anchor of the sticky summary */
    topRef: React.RefObject<HTMLDivElement>;
    /** Positive or Negative margin to aid in the Payment Details container margin calculations */
    extraMargin?: number;
    /** Extra spacing to avoid clipping with anchor. Can be positive or negative */
    headerTopClipping?: number;
  };
  perSession?: number;
  perSessionMin?: number;
  specifyCopay?: boolean;
}

const PaymentDetails: FunctionComponent<Props> = ({
  onSubmit,
  resetError = () => {},
  onLoadStripeLink,
  onStripeLinkError,
  email,
  isEmailRequired = false,
  provider,
  submitText,
  currency,
  total,
  savings,
  totalsVariant = 'none',
  errorMessage,
  isProcessing,
  stripe,
  isNoMatches = false,
  containerStyle,
  paymentFormContainerStyle,
  heading1Style,
  heading2Style,
  isEmailDisabled,
  buttonDescribedBy,
  additionalMessage,
  useStripeLink,
  showSavings = true,
  totalDueText,
  subtitle,
  maxCost,
  stickySummary,
  perSession,
  perSessionMin,
  specifyCopay,
}) => {
  const { topRef } = stickySummary || {};
  const { containerRef } = useContainerFocus();
  const heading1ID = useUniqueID('heading1ID');
  const priceContainerRef = useRef<HTMLDivElement>(null);
  const [priceContainerHeight, setPriceContainerHeight] = useState(0);
  const { t: tBookingScreen } = useTranslation('bookingScreen');

  useEffect(() => {
    setPriceContainerHeight(
      stickySummary
        ? (priceContainerRef?.current?.clientHeight ?? 0) + (stickySummary?.extraMargin ?? 0)
        : 0
    );
  }, [stickySummary]);

  return (
    <>
      {(() => {
        const usePortal = stickySummary && topRef?.current;
        const summary = (
          <StickyContainer
            ref={priceContainerRef}
            topRef={topRef}
            style={{ width: '100%', alignSelf: 'center' }}
            headerTopClipping={stickySummary?.headerTopClipping}
            usingPortal={!!usePortal}
          >
            {totalsVariant === 'standard' && (
              <TotalsSummary
                total={total}
                savings={savings}
                currency={currency}
                isNoMatches={isNoMatches}
                showSavings={showSavings}
                totalDueText={totalDueText}
                style={paymentFormContainerStyle}
              />
            )}
            {totalsVariant === 'bh' && !!maxCost && (
              <TotalSummaryCopayAtTimeOfService
                total={total}
                currency={currency}
                style={paymentFormContainerStyle}
                perSession={perSession}
                perSessionMin={perSessionMin}
                specifyCopay={specifyCopay}
              />
            )}
          </StickyContainer>
        );

        if (usePortal) {
          return createPortal(summary, topRef?.current || document.body);
        }
        return summary;
      })()}
      <Container
        align="stretch"
        style={{
          overflow: 'hidden',
          ...containerStyle,
          marginTop: priceContainerHeight,
        }}
        ref={containerRef}
        tabIndex={-1}
        aria-labelledby={heading1ID}
      >
        <ExtraHuge as="h1" style={{ marginBottom: 13, ...heading1Style }} id={heading1ID}>
          {tBookingScreen('payment.payment', 'Payment details', undefined)}
        </ExtraHuge>
        {subtitle !== null && (
          <Large
            as="h2"
            variant="largeDarkGrey"
            style={{ marginBottom: isNoMatches ? 25 : 30, ...heading2Style }}
          >
            {subtitle ||
              tBookingScreen('payment.enterPayment', 'Enter your payment details.', undefined)}
          </Large>
        )}
        <PaymentForm
          style={paymentFormContainerStyle}
          onSubmit={onSubmit}
          resetError={resetError}
          onLoadStripeLink={onLoadStripeLink}
          onStripeLinkError={onStripeLinkError}
          email={email}
          isEmailRequired={isEmailRequired}
          isEmailDisabled={isEmailDisabled}
          provider={provider}
          submitText={submitText}
          errorMessage={errorMessage}
          isProcessing={isProcessing}
          stripe={stripe}
          buttonDescribedBy={buttonDescribedBy}
          additionalMessage={additionalMessage}
          useStripeLink={useStripeLink}
          maxAdditionalPayment={typeof maxCost === 'number' ? +(maxCost - total).toFixed(2) : 0}
          isBH={totalsVariant === 'bh'}
        />
      </Container>
    </>
  );
};

export default PaymentDetails;
