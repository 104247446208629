import {
  FloatingMenu,
  View,
  Huge,
  Button,
  Checkbox,
  SelectRounded,
  Heading3,
  Small,
  Standard,
  BaseButton,
  CloseButton,
  COLORS,
  Link,
} from '@talkspace/react-toolkit';
import isEqual from 'lodash/isEqual';
import { VoidFunctionComponent, useRef, useEffect, useCallback } from 'react';
import FilterIcon from '../../../Icons/FilterIcon';
import useDidUpdateEffect from '../../../../hooks/useDidUpdateEffect';
import styled, { EmotionStyle } from '../../../../core/styled';
import storage from '../../../../core/storage';
import { CaseLoadFilterType, OptionType, CaseLoadInputs, FilterState } from '../types';
import { getCaseLoadFilterState, getEmptyFormState, getInitialFormState } from '../utils';
import {
  DROPDOWN_POSTFIX,
  THERAPIST_MESSAGING_TYPES,
  THERAPIST_LIVE_SESSIONS_TYPES,
  THERAPIST_CLINICAL_TYPES,
  THERAPIST_ROOM_DETAILS,
  PSYCHIATRIST_MESSAGING_TYPES,
  PSYCHIATRIST_LIVE_SESSIONS_TYPES,
  PSYCHIATRIST_CLINICAL_TYPES,
  PSYCHIATRIST_ROOM_DETAILS,
  THERAPIST_CASELOAD_FILTERS,
  PSYCHIATRIST_CASELOAD_FILTERS,
  LINKS,
} from '../constants';
import useDashboardSize from '../../hooks/useDashboardSize';
import ssoHelper from '../../../../modules/utils/sso';

interface CaseLoadFilterProps {
  caseLoadFilterType: CaseLoadFilterType;
  showModal: boolean;
  persistData: boolean;
  filterCount: number;
  handleModalClosePress: () => void;
  caseLoadFilterState: FilterState;
  hasMadeSelections: boolean;
  handleLoadFilterMadeSelections: (hasMade: boolean) => void;
  handleCaseLoadFilterStateChange: (caseLoadFilterState: FilterState) => void;
  getFilterCount: (formState: FilterState) => void;
  handleApplyFilters: (formState: FilterState) => void;
  handleSubmitFilters: () => void;
}

const SubmitButton = styled(Button)<{ isZeroResults: boolean }>(
  ({ theme: { colors }, isZeroResults }) => {
    return {
      background: colors.permaEden,
      width: 156,
      height: 30,
      ...(isZeroResults && { background: colors.slateGrey }),
    };
  }
);

const getFloatingMenuStyle = (isMobile: boolean): EmotionStyle => {
  return {
    position: 'fixed',
    top: 100,
    bottom: 100,
    left: 0,
    right: 0,
    margin: 'auto',
    transform: 'translateZ(0)',
    zIndex: 21,
    width: 470,
    height: 'calc(100vh - 210px)',
    maxHeight: 1150,
    ...(isMobile && {
      width: 360,
      top: 25,
      bottom: 25,
    }),
  };
};

const FilterTitle = styled(Huge)(() => {
  return {
    paddingBottom: 15,
    fontWeight: 400,
    fontSize: 21,
  };
});

const Footer = styled(View)(({ theme: { colors } }) => {
  return {
    borderTop: `solid ${colors.permaPowderBlue} 1px`,
    padding: 15,
  };
});

const ClearAllText = styled(Standard)<{ hasMadeSelections: boolean }>(
  ({ theme: { colors }, hasMadeSelections }) => {
    return {
      color: colors.slateGrey,
      borderBottom: `solid ${colors.slateGrey} 1px`,
      ...(hasMadeSelections && {
        color: colors.permaVeryDarkCyan,
        borderBottom: `solid ${colors.permaVeryDarkCyan} 1px`,
      }),
    };
  }
);

const FiltersContainer = styled(View)<{ isMobile: boolean }>(({ isMobile }) => {
  return {
    paddingLeft: 25,
    paddingRight: 14,
    paddingTop: 0,
    overflow: 'scroll',
    flex: '1 1 auto',
    ...(isMobile && { paddingLeft: 10, paddingRight: 10 }),
  };
});

const FilterSection = styled(View)<{ showBottomBorder?: boolean; isMobile: boolean }>(
  ({ theme: { colors }, isMobile, showBottomBorder }) => {
    return {
      paddingLeft: 43,
      paddingBottom: 20,
      paddingTop: 22,
      ...(showBottomBorder && {
        borderBottom: `solid ${colors.permaPowderBlue} 1px`,
      }),
      ...(isMobile && {
        paddingLeft: 10,
      }),
    };
  }
);

interface CaseLoadLinkProps {
  text: string;
  url: string;
}

const CaseLoadLink: VoidFunctionComponent<CaseLoadLinkProps> = ({
  text,
  url,
}: CaseLoadLinkProps) => (
  <Link
    target="_blank"
    onClick={() => ssoHelper.openZendesk(url)}
    style={{
      color: COLORS.permaEden,
      fontWeight: 'bold',
      marginLeft: 0,
    }}
  >
    {text}
  </Link>
);

const CaseLoadFilter: VoidFunctionComponent<CaseLoadFilterProps> = ({
  caseLoadFilterType = 'therapist',
  persistData = false,
  showModal,
  filterCount,
  handleModalClosePress,
  caseLoadFilterState,
  hasMadeSelections,
  handleLoadFilterMadeSelections,
  handleCaseLoadFilterStateChange,
  getFilterCount,
  handleApplyFilters,
  handleSubmitFilters,
}) => {
  if (!persistData) {
    [THERAPIST_CASELOAD_FILTERS, PSYCHIATRIST_CASELOAD_FILTERS].forEach((storageName) => {
      storage.removeItem(storageName);
    });
  }
  const prevStateRef = useRef<FilterState>();

  const { isDashboardMobile } = useDashboardSize();

  useEffect(() => {
    if (showModal) {
      const initialFormState = getInitialFormState(caseLoadFilterType);
      if (persistData) {
        const storedFormData = getCaseLoadFilterState(caseLoadFilterType);

        if (storedFormData) {
          prevStateRef.current = storedFormData;
        } else {
          prevStateRef.current = initialFormState;
        }
      } else {
        prevStateRef.current = initialFormState;
      }
    }
  }, [persistData, showModal, caseLoadFilterType]);

  useDidUpdateEffect(() => {
    let newState;
    if (persistData) {
      const storedFormData = getCaseLoadFilterState(caseLoadFilterType);

      if (storedFormData) {
        newState = storedFormData;
      }
    }
    newState = getInitialFormState(caseLoadFilterType);

    handleCaseLoadFilterStateChange({
      ...caseLoadFilterState,
      ...newState,
    });
  }, [caseLoadFilterType]);

  useDidUpdateEffect(() => {
    if (isEqual(caseLoadFilterState, getInitialFormState(caseLoadFilterType))) {
      handleLoadFilterMadeSelections(false);
    }
  }, [caseLoadFilterState]);

  const handleFormChange = useCallback(
    (newState: FilterState) => {
      const formState: FilterState = { ...caseLoadFilterState, ...newState };
      getFilterCount(formState);
      handleCaseLoadFilterStateChange(formState);
      if (!hasMadeSelections) {
        handleLoadFilterMadeSelections(true);
      }
    },
    [
      getFilterCount,
      hasMadeSelections,
      handleCaseLoadFilterStateChange,
      caseLoadFilterState,
      handleLoadFilterMadeSelections,
    ]
  );

  const handleClose = () => {
    if (prevStateRef.current) {
      handleApplyFilters(prevStateRef.current);
    }
    handleModalClosePress();
  };

  const emptyForm = () => {
    handleFormChange(getEmptyFormState(caseLoadFilterType));
    handleLoadFilterMadeSelections(false);
  };

  const buildFilterInputs = (data: CaseLoadInputs[], isBottom?: boolean) =>
    data.map(({ label, key, dropDown }) => (
      <View row key={key} style={{ marginBottom: 10, height: 30 }} align="center">
        <Checkbox
          checkboxStyle={{ height: 21, width: 21 }}
          labelStyle={{ fontWeight: 400 }}
          label={label}
          isLabelOnRight
          isChecked={!!caseLoadFilterState[key]}
          setIsChecked={(bool) => {
            handleFormChange({ [key]: bool });
          }}
          borderColor="#4C5C78"
          containerStyle={{ marginBottom: 0 }}
          hasHoverStyles
          hoverColors={{ borderColor: COLORS.permaEden, backgroundColor: '#F6F8FB' }}
          checkedColor={COLORS.permaEden}
        />

        {dropDown?.options && (
          <SelectRounded
            options={dropDown.options}
            menuPlacement={isBottom ? 'top' : 'auto'}
            onChange={(selected: OptionType) =>
              handleFormChange({ [`${key}${DROPDOWN_POSTFIX}`]: selected, [key]: true })
            }
            value={caseLoadFilterState[`${key}${DROPDOWN_POSTFIX}`] as OptionType}
            wrapperStyle={{
              marginLeft: 6,
              marginTop: 0,
              maxWidth: 146,
              ...(isDashboardMobile && {
                width: 96,
              }),
            }}
            styles={{
              control: (provided) => {
                return {
                  ...provided,
                  minHeight: 30,
                  height: 30,
                  paddingLeft: 0,
                  alignContent: 'center',
                };
              },
              singleValue: (provided) => {
                return {
                  ...provided,
                  fontSize: 14,
                  fontWeight: 400,
                  paddingLeft: 9,
                };
              },
              dropdownIndicator: (provided) => {
                return {
                  ...provided,
                  paddingRight: 4,
                  paddingLeft: 0,
                };
              },
              menu: (provided) => {
                return {
                  ...provided,
                  width: 200,
                  right: 0,
                };
              },
            }}
            width="100%"
          />
        )}
      </View>
    ));

  const floatingMenuStyles = getFloatingMenuStyle(isDashboardMobile);

  return (
    <FloatingMenu hide={!showModal} onBackdropPress={handleClose} style={floatingMenuStyles}>
      <View row justify="end" style={{ marginTop: 10, marginRight: 10 }}>
        <CloseButton onPress={handleClose} width={31} height={31} />
      </View>
      <View style={{ paddingLeft: 25, paddingRight: 25 }}>
        <View row>
          <View style={{ flexBasis: 43 }}>
            <FilterIcon />
          </View>
          <Heading3 style={{ textAlign: 'left' }}>Filters</Heading3>
        </View>
      </View>

      <FiltersContainer isMobile={isDashboardMobile}>
        <FilterSection isMobile={isDashboardMobile}>
          <FilterTitle>Messaging</FilterTitle>
          {buildFilterInputs(
            caseLoadFilterType === 'therapist'
              ? THERAPIST_MESSAGING_TYPES
              : PSYCHIATRIST_MESSAGING_TYPES
          )}
          {caseLoadFilterType === 'therapist' && (
            <CaseLoadLink text={LINKS.MESSAGING.text} url={LINKS.MESSAGING.link} />
          )}
        </FilterSection>
        <FilterSection isMobile={isDashboardMobile}>
          <FilterTitle>Live Sessions</FilterTitle>
          {buildFilterInputs(
            caseLoadFilterType === 'therapist'
              ? THERAPIST_LIVE_SESSIONS_TYPES
              : PSYCHIATRIST_LIVE_SESSIONS_TYPES
          )}
        </FilterSection>
        <FilterSection isMobile={isDashboardMobile}>
          <FilterTitle>Clinical</FilterTitle>
          {buildFilterInputs(
            caseLoadFilterType === 'psychiatrist'
              ? THERAPIST_CLINICAL_TYPES
              : PSYCHIATRIST_CLINICAL_TYPES
          )}
          <CaseLoadLink text={LINKS.CLINICAL.text} url={LINKS.CLINICAL.link} />
        </FilterSection>
        <FilterSection showBottomBorder={false} isMobile={isDashboardMobile}>
          <FilterTitle>Room Details</FilterTitle>
          {buildFilterInputs(
            caseLoadFilterType === 'therapist' ? THERAPIST_ROOM_DETAILS : PSYCHIATRIST_ROOM_DETAILS,
            true
          )}
          <CaseLoadLink text={LINKS.ROOM_DETAILS.text} url={LINKS.ROOM_DETAILS.link} />
        </FilterSection>
      </FiltersContainer>

      <Footer row justify="space-between">
        <BaseButton onPress={emptyForm} style={{ marginLeft: 10 }}>
          <ClearAllText hasMadeSelections={hasMadeSelections}>Clear all</ClearAllText>
        </BaseButton>
        <SubmitButton isZeroResults={filterCount === 0} size="small" onPress={handleSubmitFilters}>
          <Small style={{ color: 'white', fontWeight: 700 }}>Show {filterCount} results</Small>
        </SubmitButton>
      </Footer>
    </FloatingMenu>
  );
};

export default CaseLoadFilter;
