import Svg, { G, Circle, Path, Rect } from 'svgs';

const GeolocationFailure = ({ width = 81, height = 85, ...otherProps }) => {
  const titleText = 'Geolocation Failure';
  return (
    <Svg title={titleText} width={width} height={height} viewBox="0 0 81 85" {...otherProps}>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <G stroke="#327494" transform="translate(-561 -148) translate(561 148)">
          <Circle strokeWidth={4.66251557} cx={36.7173101} cy={36.7173101} r={34.3860523} />
          <Path
            d="M68.444 31.005c-.525.812-.851 1.64-1.137 2.371-.196.505-.38.982-.601 1.38-.393.704-.917 1.014-1.051 1.014-.296-.002-1.07-.511-1.58-.848-1.202-.792-2.82-1.876-5.107-1.806-1.278.04-3.405 2.2-3.183.871a15.98 15.98 0 00.157-4.037c-.189-2.091-1.126-3.526-2.781-4.263-1.107-.495-2.235-.507-3.07-.516a28.211 28.211 0 01-4.459-.4c-.05-.009-.254.248-.137-.024.118-.273.351-.673.76-1.152l9.22-10.773c6.31 4.765 10.91 11.066 12.969 18.183zM39.464 4.59c.256 0 .511.013.769.017.44 1.309 1.291 2.39 2.056 3.358.807 1.02 1.502 1.9 1.439 2.654-.123 1.432-2.978 2.351-5.272 3.089l-.196.063a31.484 31.484 0 00-8.128 3.967c-1.356.93-2.997 2.245-3.646 4.165-1.08 3.193 1.004 6.004 2.677 8.264l.18.242c1.077 1.46 2.022 3.496 1.132 4.44-.932.994-3.488.444-5.86-.224-1.438-.409-3.032.012-4.16 1.094a4.562 4.562 0 00-1.02 5.177c.883 2.003 3.496 4.485 10.917 4.935.357.02 1.024.745 1.052 1.416.02.521-.695 1.14-1.79 2.026-1.211.98-2.583 2.089-3.193 3.75-.279.757-.037 1.59.62 2.128 3.505 2.88 8.589 1.146 12.675-.25 2.844-.972 6.39-3.248 6.18-1.29-.21 1.956-.98 3.887-2.208 5.515-.12.156-.243.314-.367.47-.695.879-1.558 1.974-1.809 3.381-.362 2.028.69 4.2 2.737 5.665.13.093.259.185.393.276a40.396 40.396 0 01-5.178.338c-11.983 0-22.614-5.265-29.18-13.344.204-3.447 2.019-5.813 3.512-7.724l.206-.263c.471-.61 1.096-1.556 1.073-2.752-.034-1.366-.875-2.322-1.488-3.02-.116-.133-.232-.264-.338-.395-1.478-1.866-.463-4.77 1.242-6.552l.159-.165c.786-.812 1.975-2.039 1.834-3.74-.118-1.576-1.377-2.955-3.215-3.516-1.301-.393-2.608-.31-3.708-.177-1.692.2-3.351.546-4.971 1.01C8.69 14.801 22.769 4.59 39.464 4.59z"
            strokeWidth={4.58966376}
            fill="#327494"
            fillRule="nonzero"
          />
        </G>
        <Path
          d="M16 0C7.176 0 0 6.975 0 15.541c0 8.93 14 23.08 16 23.08s16-14.15 16-23.08C32 6.975 24.824 0 16 0z"
          stroke="#FFF"
          strokeWidth={3.31034483}
          fill="#FF0075"
          fillRule="nonzero"
          transform="translate(-561 -148) translate(561 148) translate(47 44)"
        />
        <G
          transform="translate(-561 -148) translate(561 148) translate(47 44) translate(9 11)"
          fill="#FFF"
        >
          <Rect
            transform="rotate(-45 7.028 6.564)"
            x={5.63546798}
            y={-0.86453202}
            width={2.78571429}
            height={14.8571429}
            rx={1.39285714}
          />
          <Rect
            transform="rotate(45 7.028 6.564)"
            x={5.63546798}
            y={-0.86453202}
            width={2.78571429}
            height={14.8571429}
            rx={1.39285714}
          />
        </G>
      </G>
    </Svg>
  );
};

export default GeolocationFailure;
