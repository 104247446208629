import {
  View,
  PlayVideoIcon,
  Link,
  Standard,
  BoxArrowIcon,
  VimeoPlayer,
  TouchableView,
} from '@talkspace/react-toolkit';
import { useState, useRef } from 'react';
import styled from '@/core/styled';
import ssoHelper from '../../../modules/utils/sso';
import { IONIC_ONBOARDING_VIDEO_URL } from './IonicOnboardingBar';

const HELP_CENTER_LINK = '/hc/en-us';

const OverlayModal = styled(View)(({ theme: { colors } }) => {
  return {
    position: 'fixed',
    width: '100%',
    height: '100%',
    background: 'rgba(0,0,0,0.3)',
    top: 0,
    left: 0,
    zIndex: 99,
    justifyContent: 'center',
    alignItems: 'center',
  };
});

const Container = styled(View)(({ theme: { colors } }) => {
  return {
    width: 277,
    height: 72,
    borderRadius: 8,
    padding: 15,
    background: colors.permaAquaHaze,
    flexDirection: 'col',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
  };
});

const Title = styled(Standard)(({ theme: { colors } }) => {
  return {
    color: colors.purple600,
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 400,
    marginBottom: 3,
  };
});

const ActionTitle = styled(TouchableView)(({ theme: { colors } }) => {
  return {
    marginLeft: 5,
    fontWeight: 700,
    color: colors.accessibilityGreenDark,
    cursor: 'pointer',
    fontSize: 14,
  };
});

const StyledLink = styled(Link)(({ theme: { colors } }) => {
  return {
    fontSize: 14,
    fontWeight: 700,
    color: colors.accessibilityGreenDark,
    marginLeft: 5,
    textDecoration: 'none',
    '&:hover': {
      color: colors.accessibilityGreenDark,
    },
  };
});

const Line = styled(View)(({ theme: { colors } }) => {
  return {
    height: 18,
    width: 1,
    background: colors.geyser,
    marginLeft: 5,
    marginRight: 10,
  };
});

const IonicSidebarBanner = () => {
  const [isVideoModalOpen, setIsVideoModalOpen] = useState<boolean>(false);
  const ariaLabel = useRef(' ');
  const videoProgress = useRef('');

  const setModalAriaLabel = (videoTitle: string) => {
    ariaLabel.current = videoTitle;
  };

  const setVideoProgress = (duration) => {
    videoProgress.current = duration;
  };

  return (
    <>
      {' '}
      <Container>
        <Title>Have questions about this app?</Title>
        <View row align="center">
          <PlayVideoIcon />
          <ActionTitle onTouchStart={() => setIsVideoModalOpen(true)}>Watch tutorial</ActionTitle>
          <Line />
          <BoxArrowIcon />
          <StyledLink
            target="_blank"
            text="Help Center"
            onTouchStart={() => {
              ssoHelper.openZendesk(HELP_CENTER_LINK);
            }}
          />
        </View>
      </Container>
      <OverlayModal
        style={{ display: isVideoModalOpen ? 'flex' : 'none' }}
        onTouchStart={() => setIsVideoModalOpen(false)}
      >
        {!!isVideoModalOpen && (
          <VimeoPlayer
            autoPlay
            title
            url={IONIC_ONBOARDING_VIDEO_URL}
            onProgress={setVideoProgress}
            onClose={() => setIsVideoModalOpen(false)}
            setModalTitle={setModalAriaLabel}
          />
        )}
      </OverlayModal>
    </>
  );
};

export default IonicSidebarBanner;
