import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const standardPath =
  'M16.7063 5.69019C17.0969 6.08081 17.0969 6.71519 16.7063 7.10581L8.70627 15.1058C8.31565 15.4964 7.68127 15.4964 7.29065 15.1058L3.29065 11.1058C2.90002 10.7152 2.90002 10.0808 3.29065 9.69019C3.68127 9.29956 4.31565 9.29956 4.70627 9.69019L8.00002 12.9808L15.2938 5.69019C15.6844 5.29956 16.3188 5.29956 16.7094 5.69019H16.7063Z';

const pathConfig: IconConfig = {
  standard: {
    default: { path: standardPath },
  },
};

const Check = withDSIconMaker(pathConfig, 'check');

export default Check;
