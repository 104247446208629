import React from 'react';
import Svg, { Defs, Path, G, Circle, Use } from 'svgs';
import { useUniqueID } from '@talkspace/react-toolkit';

function IconNewClient(props) {
  const pathID = useUniqueID('reviewEmptyPath');
  const titleText = 'no new clients';
  return (
    <Svg
      width="89px"
      height="89px"
      viewBox="0 0 89 89"
      title={titleText}
      aria-label={titleText}
      {...props}
    >
      <Defs>
        <Path
          d="M54.595 40.595l.178.01c1.793.182 2.99 1.892 2.57 3.602l-1.075 4.456c-1.256 4.946-5.382 8.548-10.464 8.914a9.572 9.572 0 01-1.375.122l.12 14.958c0 .732-.599 1.343-1.316 1.343h-11.42c-.718 0-1.316-.61-1.316-1.343l.12-12.516a8.334 8.334 0 00-2.213 3.724l-1.853 6.96c-.3 1.283-1.495 2.137-2.75 2.137-.42 0-.898-.061-1.316-.305-1.196-.672-1.734-2.198-1.375-3.541l1.794-6.777c1.554-5.8 6.517-9.89 12.437-10.257.777-.06 1.554-.06 2.272-.06 2.87 0 5.5-.123 7.833-.306 2.51-.183 4.603-1.954 5.202-4.457l1.076-4.273c.358-1.405 1.614-2.504 3.049-2.382zm-16.983-3.41c3.534 0 6.398 2.926 6.398 6.533 0 3.608-2.864 6.533-6.398 6.533s-6.398-2.925-6.398-6.533c0-3.607 2.864-6.532 6.398-6.532z"
          id={pathID}
        />
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Path
          d="M982 428.5a6.48 6.48 0 014.596 1.904A6.48 6.48 0 01988.5 435h0v105a6.48 6.48 0 01-1.904 4.596A6.48 6.48 0 01982 546.5h0H42a6.48 6.48 0 01-4.596-1.904A6.48 6.48 0 0135.5 540h0V435a6.48 6.48 0 011.904-4.596A6.48 6.48 0 0142 428.5h0z"
          stroke="#DDE3EA"
          fill="#F9FAFB"
          transform="translate(-50 -443)"
        />
        <G transform="translate(-50 -443) translate(50 443)">
          <Circle cx={44.5} cy={44.5} r={44.5} fill="#EFF3FA" />
          <Path fill="#CFEAFF" d="M50 19H62V34H50z" />
          <Path
            d="M10.625 0C5.065 0 .58 4.579.58 10.257c0 1.892.538 3.663 1.375 5.19l-1.853 4.09c-.42.916.538 1.832 1.375 1.404l4.006-1.892c1.495.915 3.229 1.404 5.083 1.404 5.56 0 10.045-4.58 10.045-10.257C20.73 4.579 16.186 0 10.625 0zM5.961 7.388c0-.916.718-1.649 1.615-1.649.896 0 1.614.733 1.614 1.649 0 .915-.717 1.648-1.614 1.648-.897 0-1.615-.733-1.615-1.648zm8.192 4.884c0 1.831-1.435 3.358-3.289 3.358h-.478c-1.794 0-3.29-1.466-3.29-3.358v-.428c0-.366.3-.672.659-.672h5.74a.67.67 0 01.658.672v.428zm-.419-3.236c-.896 0-1.614-.733-1.614-1.649 0-.915.717-1.648 1.614-1.648.897 0 1.615.732 1.615 1.648 0 .916-.718 1.649-1.615 1.649z"
            transform="translate(44.387 13)"
            fill="#5C84FB"
            fillRule="nonzero"
          />
          <G fillRule="nonzero">
            <Use fill="#000" xlinkHref={`#${pathID}`} />
            <Use stroke="#5C84FB" strokeWidth={3} fill="#CFEAFF" xlinkHref={`#${pathID}`} />
          </G>
        </G>
      </G>
    </Svg>
  );
}

export default IconNewClient;
