import { useEffect } from 'react';

import BackNextButton from '../BackNextButton';

import View from '../View';
import { Big } from '../Typography';
import CloseButton from '../CloseButton';
import styled, { useEmotionTheme } from '../../core/styled';

const Styled = {
  Wrapper: styled(View)({
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
    width: '100%',
    position: 'relative',
  }),
  Title: styled(Big)({
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '17px',
    lineHeight: '23px',
    letterSpacing: '0.1px',
  }),
};

interface Props {
  title?: string;
  onBack?: () => void;
  onClose?: () => void;
}

const SimpleHeader = ({ title, onBack, onClose }: Props) => {
  const { colors } = useEmotionTheme();

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (onClose) {
      const onKeypress = (event) => {
        if (event.key === 'Escape') {
          onClose();
        }
      };

      window.addEventListener('keydown', onKeypress);

      return () => {
        window.removeEventListener('keydown', onKeypress);
      };
    }
  }, [onClose]);
  return (
    <Styled.Wrapper row>
      {onBack && (
        <BackNextButton
          width={8}
          height={13}
          onPress={onBack}
          caretColor={colors.permaTalkspaceDarkGreen}
          style={{ position: 'absolute', top: 25, left: 20 }}
        />
      )}
      {title && <Styled.Title>{title}</Styled.Title>}
      {onClose && (
        <CloseButton
          simpleStyle
          onPress={() => onClose()}
          width={24}
          height={24}
          style={{ position: 'absolute', top: 22, right: 20 }}
        />
      )}
    </Styled.Wrapper>
  );
};

export default SimpleHeader;
