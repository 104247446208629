import { useMutation, useQueryClient } from 'react-query';

import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';

import { parseUpdateFormValues } from './utils';
import { DoseSpotFormValues } from './types';
import { doseSpotPatientQueryKey } from './queryKeys';

interface UpdateDoseSpotPatientParams {
  clientUserID: number;
  formValues: DoseSpotFormValues;
}

const updateDoseSpotPatient = async ({
  clientUserID,
  formValues,
}: UpdateDoseSpotPatientParams): Promise<void> => {
  await apiWrapper.patch(
    `${apiHelper().apiEndpoint}/v2/clients/${clientUserID}/dosespot-patient`,
    parseUpdateFormValues(formValues)
  );
};

const useMutationUpdateDoseSpotPatient = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, UpdateDoseSpotPatientParams>(updateDoseSpotPatient, {
    onSuccess: (_, { clientUserID }) => {
      queryClient.invalidateQueries(doseSpotPatientQueryKey(clientUserID));
    },
  });
};

export default useMutationUpdateDoseSpotPatient;
