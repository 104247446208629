import { useEffect, useRef, useState } from 'react';
import { useUniqueID } from '../../hooks/a11yHelper';

const useA11y = () => {
  const titleErrorID = useUniqueID('titleErrorID');
  const reviewTextErrorID = useUniqueID('reviewTextErrorID');
  const reviewTextRef = useRef<HTMLTextAreaElement>(null);
  const [shouldFocusHeadlineInput, setShouldFocusHeadlineInput] = useState(false);
  const [shouldFocusReviewText, setShouldFocusReviewText] = useState(false);
  const [isTextAreaClicked, setIsTextAreaClicked] = useState(false);
  const [isTextAreaFocused, setIsTextAreaFocused] = useState(false);

  useEffect(() => {
    if (reviewTextRef && reviewTextRef.current && shouldFocusReviewText) {
      reviewTextRef.current.focus();
    }
  }, [reviewTextRef, shouldFocusReviewText]);

  const onInputFocus = () => {
    setShouldFocusHeadlineInput(false);
    setShouldFocusReviewText(false);
  };
  const onTextAreaFocus = () => {
    setShouldFocusReviewText(false);
    setIsTextAreaFocused(true);
  };
  const onTextAreaClick = () => {
    setIsTextAreaClicked(true);
  };

  const onTextAreaBlur = () => {
    setIsTextAreaClicked(false);
    setIsTextAreaFocused(false);
  };

  return {
    titleErrorID,
    reviewTextErrorID,
    reviewTextRef,
    onInputFocus,
    onTextAreaClick,
    onTextAreaFocus,
    onTextAreaBlur,
    isTextAreaClicked,
    isTextAreaFocused,
    shouldFocusHeadlineInput,
    setShouldFocusHeadlineInput,
    setShouldFocusReviewText,
  };
};

export default useA11y;
