import { withDSIconMaker } from './utils/IconMaker';
import { IconConfig } from './types/types';

const smallPath =
  'M3.625 3.5C3.00391 3.5 2.5 4.00391 2.5 4.625C2.5 4.97891 2.66641 5.31172 2.95 5.525L8.05 9.35C8.31719 9.54922 8.68281 9.54922 8.95 9.35L14.05 5.525C14.3336 5.31172 14.5 4.97891 14.5 4.625C14.5 4.00391 13.9961 3.5 13.375 3.5H3.625ZM2.5 6.125V11C2.5 11.8273 3.17266 12.5 4 12.5H13C13.8273 12.5 14.5 11.8273 14.5 11V6.125L9.4 9.95C8.86562 10.3508 8.13438 10.3508 7.6 9.95L2.5 6.125Z';
const standardPath =
  'M3.5 4.39648C2.67188 4.39648 2 5.06836 2 5.89648C2 6.36836 2.22188 6.81211 2.6 7.09648L9.4 12.1965C9.75625 12.4621 10.2438 12.4621 10.6 12.1965L17.4 7.09648C17.7781 6.81211 18 6.36836 18 5.89648C18 5.06836 17.3281 4.39648 16.5 4.39648H3.5ZM2 7.89648V14.3965C2 15.4996 2.89688 16.3965 4 16.3965H16C17.1031 16.3965 18 15.4996 18 14.3965V7.89648L11.2 12.9965C10.4875 13.5309 9.5125 13.5309 8.8 12.9965L2 7.89648Z';

const pathConfig: IconConfig = {
  small: {
    default: { path: smallPath },
  },
  standard: {
    default: { path: standardPath },
  },
};

const Envelope = withDSIconMaker(pathConfig, 'envelope');

export default Envelope;
