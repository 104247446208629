import Svg, { Path } from 'svgs';
import { useEmotionTheme, EmotionStyle } from '../../core/styled';

interface Props {
  width?: number;
  height?: number;
  color?: string;
  dataQa?: string;
  style?: EmotionStyle;
}

const HeartHollow = ({ width = 26, height = 24, color, dataQa, style, ...props }: Props) => {
  const { colors } = useEmotionTheme();
  const titleText = 'Heart hollow';
  return (
    <Svg
      width={width}
      height={height}
      data-qa={dataQa}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 26 24"
      style={style}
      {...props}
    >
      <Path
        d="M20.727 1.768A5.848 5.848 0 0016.547 0a5.848 5.848 0 00-4.18 1.768L11.23 2.931 10.09 1.768a5.825 5.825 0 00-8.359 0c-2.308 2.357-2.308 6.178 0 8.535l1.14 1.162L11.228 20l8.359-8.535 1.139-1.162a6.098 6.098 0 001.732-4.268c0-1.6-.623-3.135-1.732-4.267z"
        transform="translate(-602 -2144) translate(600 1981) translate(4 160) translate(0 5)"
        stroke={color || colors.permaRedOrange}
        strokeWidth={2.8}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default HeartHollow;
