import { useState, useEffect } from 'react';
import { OptionType } from '@talkspace/react-toolkit';

import { getLicenseTypes } from '../utils/apiHelper';

const useLicenseTypeOptions = (): { data: OptionType[] } => {
  const [data, setData] = useState<OptionType[]>([]);
  useEffect(() => {
    getLicenseTypes().then((res) => {
      setData(
        res.map((item) => {
          return { label: `${item.description} (${item.name})`, value: `${item.id}` };
        })
      );
    });
  }, []);
  return { data };
};

export default useLicenseTypeOptions;
