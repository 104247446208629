import { FunctionComponent, useCallback, useState, useEffect, useRef } from 'react';
import { AudioFile } from '../../types/inputTypes';
import AudioRecorder from '../../utils/AudioRecorder';
import WaitingPermissionModal from '../WaitingPermissionModal';
import InputAudioView from './InputAudioView';

export interface InputAudioRef {
  stopRecording(): Promise<void>;
  startRecording(): Promise<void>;
}

interface InputAudioProps {
  hide?: boolean;
  onCancel?: () => void;
  recordingLimitInSeconds?: number;
  refCallback?(ref: InputAudioRef): void;
  onFinishRecording?: (media: AudioFile) => void;
  onDurationChanged?(duration: number): void;
}

const InputAudio: FunctionComponent<InputAudioProps> = ({
  hide,
  onCancel,
  refCallback,
  onDurationChanged,
  onFinishRecording,
  recordingLimitInSeconds,
}) => {
  const [recording, setRecording] = useState(false);
  const [waitingPermission, setWaitingPermission] = useState(false);
  const [audio, setAudio] = useState<AudioFile | null>(null);
  const [recordingTime, setRecordingTime] = useState<number>(0);
  const [recordingStartTime, setRecordingStartTime] = useState<Date | null>(null);
  const audioRecorder = useRef<AudioRecorder | null>();
  const intervalRef = useRef<number>();

  const onWaitingPermission = useCallback(() => setWaitingPermission(true), []);

  const stopRecording = useCallback(async () => {
    if (!audioRecorder.current) {
      return;
    }

    setAudio(null);

    if (intervalRef.current) {
      window.clearInterval(intervalRef.current);
    }

    const recordingResult = await audioRecorder.current.stop();
    audioRecorder.current = null;

    if (recording) {
      setRecording(false);
    }

    const now = new Date();
    const durationSeconds = (now.valueOf() - (recordingStartTime as Date).valueOf()) / 1000;
    const payload = { ...recordingResult, duration: durationSeconds };

    setAudio(payload);

    if (onFinishRecording) {
      onFinishRecording(payload);
    }
  }, [recording, recordingStartTime, onFinishRecording]);

  const startRecording = useCallback(async () => {
    if (audioRecorder.current) {
      return;
    }

    setAudio(null);
    setRecordingTime(0);
    setRecordingStartTime(new Date());

    audioRecorder.current = await AudioRecorder.init(onWaitingPermission);

    if (!recording) {
      setRecording(true);
    }

    // Will not continue to this next line until permission is granted or denied.
    setWaitingPermission(false);

    if (audioRecorder.current) {
      audioRecorder.current.start();
      intervalRef.current = window.setInterval(() => {
        setRecordingTime((x) => x + 1);
      }, 1000);
    } else {
      setRecording(false);
      if (onCancel) {
        onCancel();
      }
    }
  }, [onWaitingPermission, recording, onCancel]);

  useEffect(() => {
    if (
      audioRecorder.current &&
      recordingLimitInSeconds &&
      recordingTime === recordingLimitInSeconds
    ) {
      stopRecording();
    }
  }, [recordingLimitInSeconds, recordingTime, stopRecording]);

  useEffect(() => {
    if (onDurationChanged) {
      onDurationChanged(recordingTime);
    }
  }, [recordingTime, onDurationChanged]);

  useEffect(() => {
    if (refCallback) {
      refCallback({
        startRecording,
        stopRecording,
      });
    }
  });

  useEffect(
    () => () => {
      // useEffect to clear interval. Triggered on unmount
      if (intervalRef.current) {
        window.clearInterval(intervalRef.current);
      }
    },
    []
  );

  return (
    <>
      {waitingPermission && (
        <WaitingPermissionModal onBackdropPress={() => setWaitingPermission(false)} />
      )}
      {!hide && <InputAudioView audio={audio} onCancel={onCancel} recordingTime={recordingTime} />}
    </>
  );
};

export default InputAudio;
