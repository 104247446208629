import {
  View,
  styled,
  Small,
  ReminderIcon,
  useEmotionTheme,
  PencilIconV2,
  Link,
} from '@talkspace/react-toolkit';

import { keyframes } from '@emotion/core';
import ssoHelper from '@/utils/sso';
import { GENERATED_SESSION_SUMMARY_BANNER_ARTICLE } from '../../utils';

const fadeIn = keyframes`
 0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export type BannerType = 'smartNoteError' | 'smartNoteWarning' | null;

const BannerWrapper = styled(View)(({ theme: { colors } }) => {
  return {
    animation: `${fadeIn} 500ms cubic-bezier(.65,-0.01,.38,1) both`,
    height: 'max-content',
    padding: 12,
    background: colors.softPink,
    alignItems: 'center',
    borderRadius: '8px 8px 0 0',
  };
});

const BannerText = styled(Small)(({ theme: { colors } }) => {
  return {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 'normal',
    color: colors.permaFuchsia,
  };
});

const LinkText = styled(Link)(({ theme: { colors } }) => {
  return {
    fonstSize: 12,
    fontWeight: 500,
    color: colors.accessibilityGreenDark,
    margin: 0,
    textDecoration: 'underline',
  };
});

const SessionSummaryBanner = ({ bannerType }: { bannerType: BannerType }) => {
  const { colors } = useEmotionTheme();
  const renderContent = () => {
    switch (bannerType) {
      case 'smartNoteError':
        return (
          <BannerWrapper row>
            <View style={{ marginRight: 12 }}>
              <ReminderIcon color={colors.permaFuchsia} />
            </View>
            <BannerText>
              Required: You need to make at least one edit to the Smart Note to ensure it aligns
              with your clinical judgment.
            </BannerText>
          </BannerWrapper>
        );
      case 'smartNoteWarning':
        return (
          <BannerWrapper row style={{ background: colors.iceBlue }}>
            <View style={{ marginRight: 12 }}>
              <PencilIconV2 />
            </View>
            <BannerText style={{ color: colors.purple600 }}>
              Please review this Smart Note and make at least one edit.{' '}
              <LinkText
                onClick={() => ssoHelper.openZendesk(GENERATED_SESSION_SUMMARY_BANNER_ARTICLE)}
              >
                How was this generated?
              </LinkText>
            </BannerText>
          </BannerWrapper>
        );

      default:
        return null;
    }
  };

  return <>{renderContent()}</>;
};

export default SessionSummaryBanner;
