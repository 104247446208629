import { createContext, useContext, useReducer, useCallback } from 'react';

type SetIsTranslationEnabledAction = {
  type: 'setIsTranslationEnabled';
  payload: { isTranslationEnabled: boolean };
};
type TranslationAction = SetIsTranslationEnabledAction;

interface TranslationState {
  isTranslationEnabled: boolean;
}
interface TranslationActions {
  setIsTranslationEnabled: (value: boolean) => void;
}

const TranslationInitialState: TranslationState = {
  isTranslationEnabled: false,
};

const ActionsContext = createContext<TranslationActions | undefined>(undefined);
const StateContext = createContext<TranslationState | undefined>(undefined);
export { StateContext as TranslationStateContext };

const translationReducer = (state: TranslationState, action: TranslationAction) => {
  switch (action.type) {
    case 'setIsTranslationEnabled':
      return {
        ...state,
        isTranslationEnabled: action.payload.isTranslationEnabled,
      };
    default:
      return state;
  }
};

export function TranslationProvider({ children }) {
  const [state, dispatch] = useReducer(translationReducer, TranslationInitialState);

  function setIsTranslationEnabled(isTranslationEnabled: boolean) {
    dispatch({
      type: 'setIsTranslationEnabled',
      payload: { isTranslationEnabled },
    });
  }

  const actions: TranslationActions = {
    setIsTranslationEnabled: useCallback(setIsTranslationEnabled, []),
  };

  return (
    <StateContext.Provider value={state}>
      <ActionsContext.Provider value={actions}>{children}</ActionsContext.Provider>
    </StateContext.Provider>
  );
}

export function useTranslationState(): TranslationState {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error('StateContext must be used within a ContextProvider');
  }
  return context;
}

export function useTranslationActions() {
  const context = useContext(ActionsContext);
  if (context === undefined) {
    throw new Error('ActionsContext must be used within a ContextProvider');
  }
  return context;
}
