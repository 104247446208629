import Svg, { Path, G, Circle } from 'svgs';
import { useEmotionTheme } from '../../core/styled';

const CircledCheckMark = ({
  width = 21,
  height = 21,
  checkMarkColor,
  circleFillColor,
  circleStrokeColor,
  circleStrokeWidth = 0,
  ...props
}: {
  width?: number;
  height?: number;
  checkMarkColor?: string;
  circleFillColor?: string;
  circleStrokeColor?: string;
  circleStrokeWidth?: number;
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'check mark';
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 21 21"
      fill="none"
      style={{ overflow: 'visible' }}
      {...props}
    >
      <G stroke="none" fill="none" fillRule="evenodd">
        <Circle
          cx={10.5}
          cy={10.5}
          r={10.5}
          strokeWidth={circleStrokeWidth}
          fill={circleFillColor || colors.a11yChaosEmerald}
          stroke={circleStrokeColor || colors.white}
        />
        <Path
          d="M12.7 6.806a1.3 1.3 0 012.265 1.272l-.066.116-5.153 8.161L5.2 12.808a1.3 1.3 0 011.483-2.131l.117.081 2.28 1.78 3.62-5.732z"
          fill={checkMarkColor || colors.white}
          fillRule="nonzero"
        />
      </G>
    </Svg>
  );
};

export default CircledCheckMark;
