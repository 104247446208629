import { useMemo } from 'react';
import capitalize from 'lodash/capitalize';

import { useEmotionTheme } from '../../../core/styled';
import { IconProps, IconConfig } from '../types/types';

const useIconVariants = ({
  iconConfig,
  size = 'standard',
  variant = 'default',
  state = 'default',
  colorType = 'default',
}: IconProps & { iconConfig: IconConfig }) => {
  const { colorRoles } = useEmotionTheme();

  return useMemo(() => {
    const dimensions = (() => {
      switch (size) {
        case 'small':
          return { w: 16, h: 16 };
        case 'major':
          return { w: 24, h: 24 };
        case 'huge':
          return { w: 32, h: 32 };
        case 'standard':
        default:
          return { w: 20, h: 20 };
      }
    })();

    const { path, evenOddFilling } = (iconConfig[size] || {})[variant] || {};

    const colorRole = `icon${capitalize(colorType)}${capitalize(state)}`;

    const fillColor = colorRoles.icons[colorRole];
    return {
      width: dimensions.w,
      height: dimensions.h,
      path,
      pathProps: {
        ...(evenOddFilling && ({ fillRule: 'evenodd', clipRule: 'evenodd' } as const)),
        fill: fillColor,
      },
    };
  }, [size, iconConfig, variant, colorType, state, colorRoles.icons]);
};

export default useIconVariants;
