import { ComponentPropsWithoutRef, forwardRef, useRef, useEffect } from 'react';
import View from '../View';
import styled from '../../core/styled';
import { useWindowWidthState } from '../../hooks/windowWidthContext';
import useShareForwardedRef from '../../hooks/useShareForwardedRef';

const ScrollViewComponent = styled(View)({
  flex: 1,
  overflowY: 'auto',
  '::-webkit-scrollbar': {
    WebkitAppearance: 'none',
    width: 10,
    visibility: 'hidden',
  },
  '::-webkit-scrollbar-thumb': {
    margin: 5,
    backgroundColor: '#D8D8E0',
    WebkitBorderRadius: 5,
    visibility: 'hidden',
  },
  '::-webkit-scrollbar-thumb:hover': {
    backgroundColor: '#BECBE0',
  },
  '::-webkit-scrollbar-track': {
    margin: 4,
  },
  ':hover': {
    '::-webkit-scrollbar': {
      visibility: 'visible',
    },
    '::-webkit-scrollbar-thumb': {
      visibility: 'visible',
    },
  },
});

interface Props extends ComponentPropsWithoutRef<typeof ScrollViewComponent> {
  onScrollChange?: (scroll: number) => void;
}

const ScrollView = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { onScrollChange = () => null, ...otherProps } = props;

  // if the ref missing or is a function(legacy) ref callback, create a new one
  const scrollViewRef = useShareForwardedRef<HTMLDivElement>(ref);

  const { isMobile } = useWindowWidthState();

  const tickingRef = useRef<boolean>(false);

  useEffect(() => {
    if (!scrollViewRef.current || !onScrollChange) return undefined;
    const { current: scrollView } = scrollViewRef;

    function handleScroll() {
      if (!tickingRef.current) {
        window.requestAnimationFrame(() => {
          if (!scrollView) return;
          if (onScrollChange) onScrollChange(scrollView.scrollTop);
          tickingRef.current = false;
        });
        tickingRef.current = true;
      }
    }

    handleScroll();
    scrollViewRef.current.addEventListener('scroll', handleScroll);

    return () => {
      if (scrollView) scrollView.removeEventListener('scroll', handleScroll);
    };
  }, [isMobile, onScrollChange, scrollViewRef]);

  return <ScrollViewComponent ref={scrollViewRef} {...otherProps} />;
});

export default ScrollView;
