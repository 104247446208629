import { FunctionComponent } from 'react';
import {
  Standard,
  TouchableView,
  useEmotionTheme,
  View,
  Tiny,
  ExtraTiny,
} from '@talkspace/react-toolkit';
import Highlighter from 'react-highlight-words';
import { NoteItem } from 'hooks/notes/useQueryNotes';
import useQueryBusinessLine from 'ts-frontend/hooks/useQueryBusinessLine';
import useQueryRoomDetails from 'ts-frontend/hooks/useQueryRoomDetails';
import useIsClientDischargeV3OnForPlan from 'ts-frontend/hooks/useIsClientDischargeV3OnForPlan';
import useQueryUnsubmittedSessions from 'hooks/notes/useQueryUnsubmittedSessions';
import styled from '@/core/styled';
import { useHistory, useRouteMatch } from '@/core/routerLib';
import { getNoteTitle, getNoteTypeDescription } from './utils';

const getDisplayString = (searchString: string, body: string | null): string => {
  const charsToShowBeforeSearchString = 10;

  if (body === null) return '';
  if (searchString.trim().length === 0) return body;
  const firstSearchIndex = body.indexOf(searchString);
  if (firstSearchIndex < charsToShowBeforeSearchString) return body;
  return `...${body.slice(firstSearchIndex - charsToShowBeforeSearchString)}`;
};

const NoteHeadlineText = styled(View)({
  fontWeight: 400,
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
});

const NoteWrapper = styled(TouchableView)<{ isLast: boolean }>(({ theme: { colors }, isLast }) => {
  const borderStyle = {
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: colors.altoGrey,
  };
  return {
    margin: 5,
    ...(isLast ? {} : borderStyle),
  };
});

const NoteFooter = styled(View)({
  marginTop: 5,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const AuthorNameWrapper = styled(View)({
  alignItems: 'center',
  flexDirection: 'row',
  maxWidth: 108,
});

const AuthorAvatar = styled(View)(({ authorAvatarURL }: { authorAvatarURL: string }) => {
  return {
    minWidth: 20,
    minHeight: 20,
    marginRight: 5,
    borderRadius: 10,
    backgroundImage: `url(${authorAvatarURL}), url(/static/media/profile_default.1206da26.svg)`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  };
});

const GreyOpacityLayer = styled(View)({
  minWidth: 20,
  minHeight: 20,
  borderRadius: 10,
  backgroundColor: 'rgba(178, 188, 205, 0.25)',
});

const AuthorNameText = styled(Tiny)(({ theme: { colors } }) => {
  return {
    color: colors.purple600,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  };
});

const DraftLabel = styled(View)(({ theme: { colors } }) => {
  return {
    color: colors.pantoneBlue,
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: 14,
    letterSpacing: 0.1,
  };
});

interface Props {
  noteItem: NoteItem;
  filter: string;
  isLast?: boolean;
}

const Note: FunctionComponent<Props> = ({ noteItem, filter, isLast }) => {
  const history = useHistory();
  const match = useRouteMatch<{ roomID: string }>();
  const { data: { clientUserID } = {} } = useQueryRoomDetails(match.params.roomID);
  const { data: businessLineData } = useQueryBusinessLine(
    noteItem?.roomID?.toString(),
    clientUserID
  );
  const isClientDischargeV3OnForPlan = useIsClientDischargeV3OnForPlan(match.params.roomID);
  const { data: unsubmittedSessionsData } = useQueryUnsubmittedSessions({
    roomID: match.params.roomID,
  });
  const { colors } = useEmotionTheme();

  const { isEAPNewNoteItem } = noteItem;

  const handleNoteWrapperPress = () => {
    const baseURL = `/room/${match.params.roomID}/${noteItem.type}-notes`;
    const incompleteNotesURL = `/room/${match.params.roomID}/incomplete-notes`;
    if (noteItem.type === 'psychotherapy') {
      history.push(`${baseURL}/${noteItem.noteID}/edit`);
    } else if (noteItem.status === 'draft') {
      if (
        noteItem.type === 'discharge' &&
        isClientDischargeV3OnForPlan &&
        unsubmittedSessionsData?.unsubmittedSessions.length !== 0
      ) {
        history.push(`${incompleteNotesURL}`);
      } else {
        history.push(`${baseURL}/${noteItem.noteID}/edit`);
      }
    } else if (noteItem.status === 'submitted') {
      history.push(`${baseURL}/${noteItem.noteID}/view`);
    } else if (isEAPNewNoteItem) {
      history.push(`${baseURL}/new?eapSessionReportID=${noteItem.sessionReportData?.id}`);
    }
  };

  return (
    <NoteWrapper
      isLast={!!isLast}
      onPress={handleNoteWrapperPress}
      style={{
        ...(isEAPNewNoteItem ? {} : { paddingBottom: 10 }),
      }}
      dataQa={`noteItemButton-${noteItem.noteID}`}
    >
      <NoteHeadlineText>
        <Tiny
          style={{
            color: colors.purple600,
          }}
        >
          {getNoteTitle({ noteItem, businessLine: businessLineData })}
        </Tiny>
        {noteItem.status === 'draft' && <DraftLabel>Draft</DraftLabel>}
      </NoteHeadlineText>

      {isEAPNewNoteItem ? (
        <Standard
          style={{
            paddingTop: 9,
            fontWeight: 700,
            fontSize: 17,
            color: colors.accessibilityGreenDark,
          }}
        >
          + Create session summary note
        </Standard>
      ) : (
        <Highlighter
          className="note-search-item"
          searchWords={[filter]}
          textToHighlight={getDisplayString(filter, noteItem.previewText)}
          unhighlightStyle={{
            color: colors.TSBlack,
            fontFamily: 'Roboto',
            fontSize: 14,
            fontWeight: 400,
          }}
        />
      )}

      <NoteFooter>
        <AuthorNameWrapper>
          {!isEAPNewNoteItem && (
            <AuthorAvatar authorAvatarURL={noteItem.authorAvatar}>
              <GreyOpacityLayer />
            </AuthorAvatar>
          )}
          <AuthorNameText>{noteItem.authorName}</AuthorNameText>
        </AuthorNameWrapper>
        {!isEAPNewNoteItem && (
          <ExtraTiny
            style={{
              color: colors.purple600,
            }}
          >
            {getNoteTypeDescription(noteItem.type, businessLineData)}
          </ExtraTiny>
        )}
      </NoteFooter>
    </NoteWrapper>
  );
};

export default Note;
