import Svg, { G, Path, Rect, Circle } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';

const MicrophoneDisabled = ({
  width = 75,
  height = 75,
  isOn = false,
  isBackgroundDark = false,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'microphone';
  if (!isOn)
    return isBackgroundDark ? (
      <Svg
        width={width}
        height={height}
        title={titleText}
        aria-label={titleText}
        viewBox="0 0 75 75"
        {...otherProps}
      >
        <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
          <Path d="M22 15H53V60H22z" />
          <Path
            d="M1 37.5c0-10.08 4.085-19.204 10.69-25.81C18.297 5.086 27.42 1 37.5 1c10.08 0 19.204 4.085 25.81 10.69C69.914 18.297 74 27.42 74 37.5c0 10.08-4.085 19.204-10.69 25.81C56.703 69.914 47.58 74 37.5 74c-10.08 0-19.204-4.085-25.81-10.69C5.086 56.703 1 47.58 1 37.5z"
            stroke="#F1F6FF"
            strokeWidth={2}
            fillOpacity={0.2}
            fill="#000516"
          />
          <Path
            d="M47.082 53.452c.288.48.903.634 1.377.35.476-.287.624-.902.337-1.38L29.234 19.865a1.003 1.003 0 00-1.377-.35 1.002 1.002 0 00-.337 1.38l19.562 32.557z"
            fill={colors.heartRed}
          />
          <Path
            d="M29 33.04c.552 0 1 .442 1 1.005v5.426c0 4.163 3.354 7.524 7.5 7.524 1.42 0 2.75-.398 3.882-1.087l1.033 1.718a9.419 9.419 0 01-3.914 1.322L38.5 53h3.998c.514 0 .937.383.995.883L43.5 54c0 .552-.456 1-1.002 1h-9.996a.999.999 0 110-2H36.5v-4.052c-4.78-.5-8.5-4.547-8.5-9.477v-5.426a1 1 0 01.883-.998L29 33.04zm3-2.742l8.337 13.875c-.83.525-1.8.827-2.837.827-3.038 0-5.5-2.568-5.5-5.764v-8.938zm14 2.742l.117.007a1 1 0 01.883.998v5.426c0 1.97-.598 3.802-1.62 5.323l-1.174-1.953a7.505 7.505 0 00.794-3.37v-5.426a.997.997 0 011-1.005zM37.5 21c3.038 0 5.5 2.568 5.5 5.764v12.472c0 .465-.053.917-.152 1.35L32.773 23.818C33.733 22.131 35.493 21 37.5 21z"
            fill="#F1F6FF"
          />
        </G>
      </Svg>
    ) : (
      <Svg
        width={width}
        height={height}
        title={titleText}
        aria-label={titleText}
        viewBox="0 0 75 75"
        {...otherProps}
      >
        <G
          transform="translate(-39 -262) translate(39 262)"
          stroke="none"
          strokeWidth={1}
          fill="none"
          fillRule="evenodd"
        >
          <Path d="M22 15H53V60H22z" />
          <Path
            d="M1 37.5c0-10.08 4.085-19.204 10.69-25.81C18.297 5.086 27.42 1 37.5 1c10.08 0 19.204 4.085 25.81 10.69C69.914 18.297 74 27.42 74 37.5c0 10.08-4.085 19.204-10.69 25.81C56.703 69.914 47.58 74 37.5 74c-10.08 0-19.204-4.085-25.81-10.69C5.086 56.703 1 47.58 1 37.5z"
            stroke="#E6ECF7"
            strokeWidth={2}
          />
          <Path
            d="M47.082 53.452c.288.48.903.634 1.377.35.476-.287.624-.902.337-1.38L29.234 19.865a1.003 1.003 0 00-1.377-.35 1.002 1.002 0 00-.337 1.38l19.562 32.557z"
            fill={colors.heartRed}
          />
          <Path
            d="M29 33.04c.552 0 1 .442 1 1.005v5.426c0 4.163 3.354 7.524 7.5 7.524 1.42 0 2.75-.398 3.882-1.087l1.033 1.718a9.419 9.419 0 01-3.914 1.322L38.5 53h3.998c.514 0 .937.383.995.883L43.5 54c0 .552-.456 1-1.002 1h-9.996a.999.999 0 110-2H36.5v-4.052c-4.78-.5-8.5-4.547-8.5-9.477v-5.426a1 1 0 01.883-.998L29 33.04zm3-2.742l8.337 13.875c-.83.525-1.8.827-2.837.827-3.038 0-5.5-2.568-5.5-5.764v-8.938zm14 2.742l.117.007a1 1 0 01.883.998v5.426c0 1.97-.598 3.802-1.62 5.323l-1.174-1.953a7.505 7.505 0 00.794-3.37v-5.426a.997.997 0 011-1.005zM37.5 21c3.038 0 5.5 2.568 5.5 5.764v12.472c0 .465-.053.917-.152 1.35L32.773 23.818C33.733 22.131 35.493 21 37.5 21z"
            fill="#41506A"
          />
        </G>
      </Svg>
    );
  return isBackgroundDark ? (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 75 75"
      {...otherProps}
    >
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Circle fillOpacity={0.2} fill="#000516" cx={38} cy={38} r={30} />
        <Path d="M22 15H53V60H22z" />
        <Path
          d="M37.5 0C58.21 0 75 16.79 75 37.5S58.21 75 37.5 75 0 58.21 0 37.5 16.79 0 37.5 0zm8 32.54a.996.996 0 00-.993.887l-.007.118v5.426l-.009.361a7.498 7.498 0 01-.64 2.7l-.145.309.006.01a7.534 7.534 0 01-2.825 3.064l-.005-.007A7.443 7.443 0 0137 46.495c-4.065 0-7.368-3.23-7.496-7.28l-.004-.244v-5.426a.997.997 0 00-1-1.005l-.117.007a1 1 0 00-.876.881l-.007.117v5.426a9.5 9.5 0 005.487 8.643l.04.017c.135.063.272.122.41.178l.113.045c.21.082.425.157.642.224l.047.015a8.446 8.446 0 00.308.087l.11.029a10.05 10.05 0 00.961.191l.073.01.045.007.086.01c.047.007.094.012.142.017l.037.004L36 52.5h-3.998c-.546 0-1.002.448-1.002 1 0 .516.387.936.886.993l.116.007h9.996c.507 0 .936-.386.995-.883L43 53.5l-.007-.117a.998.998 0 00-.879-.876l-.116-.007H38l.001-4.052c.121-.013.24-.027.357-.044l-.076.008.159-.021c.1-.015.198-.031.296-.05l.05-.01a9.504 9.504 0 007.681-8.55l.019-.28.006-.162.007-.368v-5.426l-.007-.117a1 1 0 00-.762-.861l-.114-.02-.117-.007zM37 20.5c-.078 0-.155.002-.232.005L37 20.5a5.5 5.5 0 00-5.5 5.5v13a5.5 5.5 0 005.8 5.492l-.3.008a5.5 5.5 0 005.5-5.5V26a5.5 5.5 0 00-5.5-5.5z"
          fill="#F1F6FF"
        />
      </G>
    </Svg>
  ) : (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 75 75"
      {...otherProps}
    >
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <Path
          d="M37.5 75C58.21 75 75 58.21 75 37.5S58.21 0 37.5 0 0 16.79 0 37.5 16.79 75 37.5 75z"
          fill="#F1F6FF"
        />
        <G transform="translate(28 21)" fill="#41506A">
          <Rect x={4} y={0} width={11} height={24} rx={5.5} />
          <Path d="M18 12.04l.117.007a1 1 0 01.883.998v5.426c0 .178-.005.355-.014.53h.001c-.23 4.413-3.47 8.029-7.705 8.832l-.071.013c-.212.039-.427.07-.644.095l-.044.004-.022.003-.001 4.051 3.998.001c.514 0 .937.383.995.883L15.5 33c0 .552-.456 1-1.002 1H4.502a.999.999 0 110-2H8.5v-4.052l-.073-.008a9.503 9.503 0 01-2.65-.697A9.502 9.502 0 010 18.47v-5.426a1 1 0 01.883-.998L1 12.04c.552 0 1 .442 1 1.005v5.426c0 4.163 3.354 7.524 7.5 7.524 1.42 0 2.75-.398 3.882-1.087a7.447 7.447 0 002.83-3.059l-.006-.008a7.505 7.505 0 00.794-3.37v-5.426a.997.997 0 011-1.005z" />
        </G>
      </G>
    </Svg>
  );
};
export default MicrophoneDisabled;
