import { FunctionComponent, useEffect, useState } from 'react';
import {
  View,
  Big,
  Massive,
  Button,
  Checkbox,
  Standard,
  TouchableView,
  Large,
  Spinner,
  VerificationCodeInput,
  useEmotionTheme,
  SuccessCheckmark,
} from '@talkspace/react-toolkit';
import styled from '../../core/styled';

const Wrapper = styled(View)({
  marginTop: 45,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const Title = styled(Massive)({
  marginBottom: 38,
});

const SecurityMessage = styled(Big)({
  width: 298,
  textAlign: 'center',
  fontWeight: 400,
});

const SecurityMessageError = styled(SecurityMessage)(({ theme: { colors } }) => {
  return {
    fontWeight: 500,
    color: colors.red,
  };
});

const ResetOTPTouchableView = styled(TouchableView)({
  marginTop: 5,
});

interface Props {
  userPhone: number;
  error: { type?: string; message?: string };
  otpToken: string;
  verify2FA: (otpToken: string, code: number) => void;
  resendCode: () => void;
  isRememberMeCheckboxVisible: boolean;
  isLoading: boolean;
  isVerified: boolean;
  title?: string;
}

const StatusWrapper = styled(View)({
  textAlign: 'center',
});

const StatusText = styled(Standard)(({ theme: { colors } }) => {
  return {
    color: colors.grey,
  };
});

const SpinnerStyles = {
  width: 63,
  height: 63,
  marginTop: 50,
};

const SubmitButton = styled(Button)({
  width: 320,
  height: 55,
  marginTop: 25,
  marginBottom: 20,
});

const TwoFactorAuth: FunctionComponent<Props> = ({
  title,
  otpToken,
  userPhone = 98,
  verify2FA,
  isRememberMeCheckboxVisible = true,
  resendCode,
  isLoading = false,
  isVerified = false,
  error = {},
}) => {
  const { colors } = useEmotionTheme();
  const [isVerifyButtonDisabled, setVerifyButtonDisabled] = useState(true);
  const [otpCode, setOTPCode] = useState<string>('');
  const [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {
    if (error && error.type === 'redirectToLogin') {
      setVerifyButtonDisabled(true);
    }
  }, [error]);

  const handleOTPKeyInput = (otpKey: string) => {
    setOTPCode(otpKey);
    if (isVerifyButtonDisabled) setVerifyButtonDisabled(false);
  };

  const handleResendOTPCode = () => {
    setOTPCode('');
    resendCode();
  };

  const getSecurityMessage = () => {
    if (error) {
      if (error.type === 'verify2FAError') {
        return (
          <View>
            <SecurityMessageError>Invalid verification code</SecurityMessageError>
            <SecurityMessage variant="bigMedium">
              Please re-enter the 6-digit verification code sent to your phone number ending in{' '}
              <b>{userPhone}</b>.
            </SecurityMessage>
          </View>
        );
      }
      if (error.type === 'redirectToLogin') {
        return (
          <SecurityMessage variant="bigMedium">
            You have reached the maximum number of entry attempts. Please return to the log in page
            again.
          </SecurityMessage>
        );
      }
    }
    return (
      <SecurityMessage variant="bigMedium">
        For your added security, please enter the 6 - digit verification code sent to your phone
        ending in <b>{userPhone}</b>.
      </SecurityMessage>
    );
  };

  return (
    <Wrapper>
      {(() => {
        if (isLoading) {
          return (
            <StatusWrapper>
              <StatusText>Validating code...</StatusText>
              <Spinner primaryColor={colors.loblollyGrey} style={SpinnerStyles} />
            </StatusWrapper>
          );
        }
        if (isVerified) {
          return (
            <View>
              <StatusWrapper>
                <StatusText>Success!</StatusText>
                <SuccessCheckmark style={{ marginTop: 50 }} />
              </StatusWrapper>
            </View>
          );
        }
        return (
          <View style={{ alignItems: 'center' }}>
            {title && <Title>{title}</Title>}
            {getSecurityMessage()}
            <VerificationCodeInput
              isError={error.type === 'verify2FAError'}
              style={{ marginTop: 25 }}
              onChange={handleOTPKeyInput}
            />
            <SubmitButton
              onPress={() => verify2FA(otpToken, Number(otpCode))}
              disabled={isVerifyButtonDisabled || otpCode.length < 6}
              primaryColor={colors.green}
              data-qa="twoFactorSubmit"
            >
              <Big variant="bigWide">Submit</Big>
            </SubmitButton>
            {isRememberMeCheckboxVisible && (
              <View style={{ marginBottom: 15 }}>
                <Checkbox
                  isLabelOnRight
                  stretch={false}
                  label="Don’t ask for codes on this browser for 2 weeks"
                  isChecked={rememberMe}
                  setIsChecked={setRememberMe}
                />
              </View>
            )}
            <Standard style={{ color: colors.slateGrey }}>
              Didn’t receive a verification code?
            </Standard>
            <ResetOTPTouchableView onPress={handleResendOTPCode}>
              <Large variant="largeBoldWideGreen">Resend code</Large>
            </ResetOTPTouchableView>
          </View>
        );
      })()}
    </Wrapper>
  );
};

export default TwoFactorAuth;
