import { useEmotionTheme } from '@talkspace/react-toolkit';
import { VoidFunctionComponent } from 'react';
import Svg, { Rect, Path } from 'svgs';

interface AudioModalityIconProps {
  backgroundColor?: string;
  foregroundColor?: string;
  width?: number;
  height?: number;
}

const AudioModalityIcon: VoidFunctionComponent<AudioModalityIconProps> = ({
  backgroundColor,
  foregroundColor,
  width = 28,
  height = 28,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'audio icon';
  return (
    <Svg
      title={titleText}
      aria-label={titleText}
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill="none"
      {...otherProps}
    >
      <Rect width={28} height={28} rx={8} fill={backgroundColor || colors.permaMagicMint} />
      <Path
        clipRule="evenodd"
        d="M8.53 5.755c.649-.456 1.062-.627 1.77-.74 1.18-.285 3.365 3.702 2.42 4.728-.354.398-.826.341-1.652.683-.177 2.563 2.656 6.893 5.135 7.861.59-.57.826-.968 1.299-1.14 1.416-.341 4.131 3.305 3.364 4.216-.472.513-.767.798-1.476 1.196C13.901 25.92 2.981 9.06 8.53 5.755z"
        stroke={foregroundColor || colors.permaSeaGreen}
        strokeWidth={1.5}
      />
    </Svg>
  );
};

export default AudioModalityIcon;
