import { createContext, useContext } from 'react';
import { withContextProvider } from '../../../hoc';
import { BreakpointValues } from '../../../types';
import { HybridType } from './gridTypes';

type GridContextType = {
  columns: number;
  gutterWidth: number;
  breakpoints: BreakpointValues;
  containerMaxWidths: BreakpointValues;
  hybridType: HybridType | null;
  rowSpacing: number;
};

const GridContext = createContext<GridContextType | undefined>(undefined);

type GridProviderProps = Pick<
  GridContextType,
  'breakpoints' | 'columns' | 'gutterWidth' | 'hybridType' | 'containerMaxWidths' | 'rowSpacing'
>;

export const GridContextProvider: React.FC<GridProviderProps> = ({
  children,
  hybridType,
  breakpoints,
  columns,
  gutterWidth,
  containerMaxWidths,
  rowSpacing,
}) => (
  <GridContext.Provider
    value={{ hybridType, breakpoints, columns, gutterWidth, containerMaxWidths, rowSpacing }}
  >
    {children}
  </GridContext.Provider>
);

export const useGridContext = () => {
  const context = useContext(GridContext);
  if (!context) {
    throw new Error('Could not found GridContextProvider');
  }
  return context;
};

export const withGridContext = withContextProvider(GridContextProvider);
