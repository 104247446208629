import { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { InformedConsentStatus } from 'ts-frontend/types';
import { useParams } from '@/core/routerLib';
import {
  getInformedConsents,
  getInformedConsentV2,
  resendInformedConsent,
  updateInformedConsentStatus,
} from '../../../../../actions/InformedConsentActions';
import SocketService from '../../../../../utils/socket/SocketService';
import UserActionCardV2 from '../../../../Reusable/UserActionCardV2/UserActionCard';
import Submenu from '../../../../Reusable/Submenu/Submenu';
import { InformedConsentState } from '../../../../../constants/InformedConsentConstants';
import ParentalConsent from './ParentalConsent';
import useQueryParticipants from '../../../../../hooks/useQueryParticipants';

const mapStateToProps = (state) => {
  return {
    resendingForClientID: state.informedConsent.resendingForClientID,
    informedConsents: state.informedConsent.informedConsents,
    informedConsentV2: state.informedConsent.informedConsentV2,
    isPosting: state.informedConsent.isPosting,
  };
};
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getInformedConsents,
      getInformedConsentV2,
      resendInformedConsent,
      updateInformedConsentStatus,
    },
    dispatch
  );

interface InformedConsentProps extends ReturnType<typeof mapDispatchToProps> {
  resendingForClientID: InformedConsentState['resendingForClientID'];
  informedConsents: InformedConsentState['informedConsents'];
  informedConsentV2: InformedConsentState['informedConsentV2'];
  isPosting: InformedConsentState['isPosting'];
}

const notStartedText =
  'Click here to send the informed consent. Please discuss with your client any questions or concerns they may have related to privacy, confidentiality and/or exceptions. If your state requires additional information to be included in the consent, please send your consent in the room as well as the Talkspace informed consent. Sending the informed consent will also automatically send the request for contact information details.';
const completedText = 'Consent has been completed';

const getCardContent = (status: InformedConsentStatus) => {
  const statuses = {
    notStarted: notStartedText,
    consentSent:
      'The informed consent has already been sent, but the client has not yet agreed. Please reach out to your client to discuss any questions or concerns they may have. You may also send the informed consent again if needed.',
    completed: completedText,
  };
  return statuses[status];
};

const getCardContentV2 = (shouldSignConsent: boolean) =>
  shouldSignConsent ? notStartedText : completedText;

function InformedConsent({
  isPosting,
  informedConsentV2,
  informedConsents,
  resendingForClientID,
  getInformedConsents: getInformedConsentsAction,
  getInformedConsentV2: getInformedConsentV2Action,
  resendInformedConsent: resendInformedConsentAction,
  updateInformedConsentStatus: updateInformedConsentStatusAction,
}: InformedConsentProps) {
  const [cards, setCards] = useState<JSX.Element[]>([]);

  const { roomID } = useParams<{ roomID: string }>();

  const { data: participants } = useQueryParticipants(Number(roomID));

  useEffect(() => {
    getInformedConsentV2Action((participants || []).map((client) => client.userID));
  }, [participants, getInformedConsentV2Action]);

  useEffect(() => {
    getInformedConsentsAction();
    SocketService.instance().on('informed_consent', (data) => {
      if (Number(data.roomID) === Number(roomID)) getInformedConsentsAction();
    });
    return () => {
      SocketService.instance().off('informed_consent');
    };
  }, [participants, getInformedConsentV2Action, getInformedConsentsAction, roomID]);

  const getConsentButtons = useCallback(
    (consentID: number | null, status: InformedConsentStatus, clientID: number) => {
      const buttonActions: {
        [key: string]: { title: string; nextStatus: InformedConsentStatus }[];
      } = {
        notStarted: [{ title: 'Send informed consent', nextStatus: 'consentSent' }],
        consentSent: [{ title: 'Send informed consent again', nextStatus: 'consentSent' }],
        completed: [],
      };

      return buttonActions[status].map((button) => {
        return {
          title: button.title,
          isSecondary: false,
          clickHandler: () =>
            consentID
              ? updateInformedConsentStatusAction(consentID, button.nextStatus)
              : resendInformedConsentAction(clientID),
        };
      });
    },
    [resendInformedConsentAction, updateInformedConsentStatusAction]
  );

  const getConsentButtonsV2 = useCallback(
    (shouldSignConsent: boolean, clientID: number) => {
      const buttonActions: {
        [key: string]: { title: string }[];
      } = {
        notStarted: [{ title: 'Send informed consent' }],
        completed: [],
      };
      const status = shouldSignConsent ? 'notStarted' : 'completed';
      return buttonActions[status].map((button) => {
        return {
          title: button.title,
          isSecondary: false,
          clickHandler: () => resendInformedConsentAction(clientID),
        };
      });
    },
    [resendInformedConsentAction]
  );

  const getClientDisplayName = useCallback(
    (clientUserID: number) =>
      (participants || []).find((client) => client.userID === clientUserID)?.displayName ?? '',
    [participants]
  );

  const clientListHasNoV2Consents =
    !informedConsentV2.length || !informedConsentV2.filter((consent) => consent.hasV2).length;

  useEffect(() => {
    if (clientListHasNoV2Consents) {
      const cardsPerUserID = informedConsents.reduce<{ [key: number]: JSX.Element[] }>(
        (acc, informedConsent) => {
          if (!acc[informedConsent.clientUserID]) {
            acc[informedConsent.clientUserID] = [];
          }
          acc[informedConsent.clientUserID].push(
            <UserActionCardV2
              key={informedConsent.id}
              preferredName={getClientDisplayName(informedConsent.clientUserID)}
              content={getCardContent(informedConsent.status)}
              buttons={getConsentButtons(
                informedConsent.id,
                informedConsent.status,
                informedConsent.clientUserID
              )}
              isUpdating={informedConsent.isUpdating}
              isError={informedConsent.isError}
              showSpinner={informedConsent.showSpinner}
              spinnerMessage={informedConsent.spinnerMessage}
            />
          );
          return acc;
        },
        {}
      );
      Object.keys(cardsPerUserID).forEach((clientUserID) => {
        const hasUncompletedInformedConsent = informedConsents.find(
          (ic) => +ic.clientUserID === +clientUserID && ic.status !== 'completed'
        );
        if (!cardsPerUserID[clientUserID].length && !hasUncompletedInformedConsent) {
          cardsPerUserID[clientUserID].push(
            <UserActionCardV2
              key={clientUserID}
              preferredName={getClientDisplayName(+clientUserID)}
              content={getCardContent('notStarted')}
              buttons={getConsentButtons(null, 'notStarted', +clientUserID)}
              isUpdating={isPosting && resendingForClientID === +clientUserID}
              isError={false}
              showSpinner={isPosting && resendingForClientID === +clientUserID}
              spinnerMessage="Resending Informed Consent"
            />
          );
        }
      });

      setCards(Object.values(cardsPerUserID).reduce<JSX.Element[]>((acc, c) => [...acc, ...c], []));
    } else {
      const cardsPerUserID = informedConsentV2.reduce<{ [key: number]: JSX.Element[] }>(
        (acc, informedConsent) => {
          if (!acc[informedConsent.clientUserID]) {
            acc[informedConsent.clientUserID] = [];
          }
          acc[informedConsent.clientUserID].push(
            <UserActionCardV2
              key={informedConsent.clientUserID}
              preferredName={getClientDisplayName(informedConsent.clientUserID)}
              content={getCardContentV2(informedConsent.shouldSignConsent)}
              buttons={getConsentButtonsV2(
                informedConsent.shouldSignConsent,
                informedConsent.clientUserID
              )}
              isUpdating={informedConsent.isUpdating}
              isError={informedConsent.isError}
              showSpinner={informedConsent.showSpinner}
              spinnerMessage={informedConsent.spinnerMessage}
            />
          );
          return acc;
        },
        {}
      );

      setCards(Object.values(cardsPerUserID).reduce<JSX.Element[]>((acc, c) => [...acc, ...c], []));
    }
  }, [
    clientListHasNoV2Consents,
    getClientDisplayName,
    getConsentButtons,
    getConsentButtonsV2,
    informedConsentV2,
    informedConsents,
    isPosting,
    resendingForClientID,
  ]);

  return (
    <Submenu
      childComponents={[
        ...cards,
        ...(participants ? [<ParentalConsent clientUserID={participants[0].userID || 0} />] : []),
      ]}
      title="Informed consent"
      prevRoute="case-tab"
    />
  );
}

const InformedConsentContainer = connect(mapStateToProps, mapDispatchToProps)(InformedConsent);

export default InformedConsentContainer;
