import storage from '../core/storage';

const getInitialTableState = (tableName: string, defaultState: Record<string, unknown>) => {
  let tableInitialState;
  try {
    const stringifyData = storage.getItem(tableName);
    tableInitialState = stringifyData ? JSON.parse(stringifyData) : defaultState;
  } catch (err) {
    tableInitialState = defaultState;
  }
  return tableInitialState;
};

export default getInitialTableState;
