/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-access-state-in-setstate */
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ValueType } from 'react-select';
import { COLORS, OptionType, Select } from '@talkspace/react-toolkit';
import { RouteComponentProps, withRouter } from '@/core/routerLib';
import getParamByName from '@/utils/queryString';
import {
  editTreatmentPlan,
  getStandardGoals as getStandardGoalsAction,
  getGoals,
  saveGoals,
  removeGoal,
  editGoal,
} from '../../../../../actions/TreatmentPlannerActions';
import Submenu from '../../../../Reusable/Submenu/Submenu';
import Footer from '../../../../Reusable/Footer/Footer';
import Button from '../../../../Reusable/Buttons/Button';
import AlertContainer from '../../../../Reusable/AlertContainer/AlertContainer';
import './TreatmentPlannerGoals.css';
import CheckList from '../../../../Reusable/ItemList/CheckList';
import { getOptionsByField } from '../../../../../utils/tsConfigsValues';
import {
  doActions,
  getPreviousItems,
  getCombinedItems,
  getIntersection,
  difference,
  differenceWith,
} from './TreatmentPlannerOperationHelper';
import {
  PresentingProblem,
  PresentingProblemLegacy,
  TreatmentPlan,
  TreatmentPlanGoal,
} from './types';

const mapPresentingProblemsToLegacy = (
  options: { value: number; label: string }[]
): PresentingProblemLegacy[] =>
  options.map(({ label, value }) => {
    return { id: value, presentingProblemID: value, value: label };
  });

const presentingProblemsOptions = mapPresentingProblemsToLegacy(
  getOptionsByField('presentingProblems')
);

const adapterPresentingProblemOptions = (
  options: PresentingProblemLegacy[]
): ValueType<OptionType<number>, true> =>
  options.map(({ id, value }) => {
    return {
      value: id,
      label: value,
    };
  });

interface Props extends RouteComponentProps {
  roomID: string;
  currentTreatmentPlan: TreatmentPlan;
  presentingProblems: PresentingProblemLegacy[];
  treatmentPlanPresentingProblems: PresentingProblem[];
  standardGoals: TreatmentPlanGoal[];
  goals: TreatmentPlanGoal[];
  showSpinner: boolean;
  isUpdating: boolean;
  isError: boolean;
  spinnerMessage: string;
  editTreatmentPlan: (id: number, data: any) => Promise<void>;
  getStandardGoals: (value: number) => Promise<void>;
  getGoals: (id: number) => Promise<void>;
  removeGoal: (id: number) => void;
  saveGoals: (id: number, goals: TreatmentPlanGoal[]) => void;
  editGoal: (id: number, goal: TreatmentPlanGoal) => void;
  history: any;
}

interface State {
  showCard: boolean;
  combinedGoals: (TreatmentPlanGoal & { itemIdx: number })[];
  previousGoals: TreatmentPlanGoal[];
  treatmentPlanPresentingProblems: PresentingProblem[];
}

class TreatmentPlannerGoals extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showCard: false,
      combinedGoals: [],
      previousGoals: [],
      treatmentPlanPresentingProblems:
        !!props.treatmentPlanPresentingProblems && !!props.treatmentPlanPresentingProblems.length
          ? props.treatmentPlanPresentingProblems
          : props.presentingProblems.map(({ presentingProblemID, value }) => {
              return {
                id: presentingProblemID,
                text: value,
              };
            }) || [],
    };

    if (!props.currentTreatmentPlan.id) {
      props.history.goBack();
    }

    this.checkboxHandler = this.checkboxHandler.bind(this);
    this.textAreaChangeHandler = this.textAreaChangeHandler.bind(this);
    this.adderChangeHandler = this.adderChangeHandler.bind(this);
    this.styleMultiSelectLabels = this.styleMultiSelectLabels.bind(this);
    this.saveChangeMultiSelect = this.saveChangeMultiSelect.bind(this);
    this.setCardState = this.setCardState.bind(this);
    this.handleNavigation = this.handleNavigation.bind(this);
  }

  componentDidMount() {
    const {
      props: { currentTreatmentPlan },
    } = this;

    if (currentTreatmentPlan.id) {
      Promise.all([
        ...this.state.treatmentPlanPresentingProblems.map((presentingProblem) =>
          this.props.getStandardGoals(presentingProblem.id)
        ),
        this.props.getGoals(this.props.currentTreatmentPlan.id),
      ]).then(() => {
        const standardGoals = this.props.standardGoals.map(
          ({ id, presentingProblemID, description }) => {
            return {
              id,
              presentingProblemID,
              description,
              checked: false,
              standard: true,
            };
          }
        );

        const treatmentPlanGoals = this.props.goals.map(
          ({ treatmentPlanID, id, description, presentingProblemID, standard }) => {
            return {
              treatmentPlanID,
              id,
              description,
              presentingProblemID,
              standard,
              checked: true,
            };
          }
        );

        const combinedGoals = getCombinedItems(treatmentPlanGoals, standardGoals, 'description');

        const previousGoals = getPreviousItems(combinedGoals);

        this.setState({
          combinedGoals,
          previousGoals,
        });

        this.styleMultiSelectLabels();
      });
    }
  }

  getFooterChildren() {
    const {
      state: { combinedGoals, previousGoals, treatmentPlanPresentingProblems },
      // eslint-disable-next-line @typescript-eslint/no-shadow
      props: { currentTreatmentPlan, history, editGoal, removeGoal, saveGoals },
    } = this;

    const activeGoals = combinedGoals.filter((goal) => goal.checked);

    return this.state.showCard
      ? [
          <AlertContainer
            key={4}
            title="No goals selected"
            childComponents={[
              <Button
                key={1}
                title="Continue"
                isSecondary={false}
                clickHandler={() => {
                  this.setState({ showCard: false });
                  doActions(
                    currentTreatmentPlan.id,
                    combinedGoals,
                    previousGoals,
                    saveGoals,
                    removeGoal,
                    editGoal
                  ).then(() => {
                    this.handleNavigation();
                  });
                }}
                dataQa="noGoalsSelectedContinueButton"
              />,
              <Button
                title="Cancel"
                key={2}
                isSecondary
                clickHandler={() => this.setState({ showCard: false })}
                dataQa="noGoalsSelectedCancelButton"
              />,
            ]}
            body="You can select goals by checking the ones listed above. By clicking continue no goals will be saved to this treatment plan. You can add goals later by editing the treatment plan."
            containerStyle={{
              width: 355,
              border: 'none',
            }}
          />,
        ]
      : [
          <Button
            title="Cancel"
            isSecondary
            key={1}
            clickHandler={() => history.goBack()}
            dataQa="treatmentPlanGoalsCancelButton"
          />,
          <Button
            title="Next"
            isSecondary={false}
            key={2}
            dataQa="treatmentPlanGoalsNextButton"
            clickHandler={() => {
              const currentTreatmentPlanPresentingProblemsIDs = treatmentPlanPresentingProblems.map(
                ({ id }) => id
              );

              const previousTreatmentPlanPresentingProblemsIDs =
                currentTreatmentPlan.presentingProblems.map(({ id }) => id);

              const haveDifference = difference(
                previousTreatmentPlanPresentingProblemsIDs,
                currentTreatmentPlanPresentingProblemsIDs
              );

              if (haveDifference) {
                this.props
                  .editTreatmentPlan(currentTreatmentPlan.id, {
                    presentingProblems: currentTreatmentPlanPresentingProblemsIDs,
                  })
                  .then(() => {
                    if (!!activeGoals && !!activeGoals.length) {
                      doActions(
                        currentTreatmentPlan.id,
                        combinedGoals,
                        previousGoals,
                        saveGoals,
                        removeGoal,
                        editGoal
                      ).then(() => {
                        this.handleNavigation();
                      });
                    } else {
                      this.setState({ showCard: true });
                    }
                  });
              } else if (!!activeGoals && !!activeGoals.length) {
                doActions(
                  currentTreatmentPlan.id,
                  combinedGoals,
                  previousGoals,
                  saveGoals,
                  removeGoal,
                  editGoal
                ).then(() => {
                  this.handleNavigation();
                });
              } else {
                this.setState({ showCard: true });
              }
            }}
          />,
        ];
  }

  getFooterComponent() {
    const {
      props: { isUpdating, isError, spinnerMessage },
    } = this;
    const footerStyle = this.state.showCard
      ? {
          height: 186,
          flexDirection: 'column-reverse',
          paddingTop: 3,
        }
      : {};
    return (
      <Footer
        key={4}
        childComponents={[...this.getFooterChildren()]}
        isError={isError}
        isUpdating={isUpdating}
        showSpinner={this.props.showSpinner}
        spinnerMessage={spinnerMessage}
        footerStyle={footerStyle}
      />
    );
  }

  getViewContent() {
    const {
      state: { combinedGoals, treatmentPlanPresentingProblems },
      saveChangeMultiSelect,
    } = this;

    const presentingProblemsIntersection = getIntersection(
      presentingProblemsOptions,
      treatmentPlanPresentingProblems,
      (presentingProblem, treatmentPlanPresentingProblem) =>
        treatmentPlanPresentingProblem.id === presentingProblem.id
    );

    return (
      <form>
        <div key={1} className="treatment-planner-intro-text">
          Please select goals from the list for each presenting problem.
        </div>
        <div key={2} className="treatment-planner-multiselect">
          <Select
            placeholder="Select presenting problems"
            options={adapterPresentingProblemOptions(presentingProblemsOptions) as any[]}
            value={presentingProblemsIntersection.map((it) => {
              const isPlanWithSelectedGoals = !!combinedGoals.find(
                (goal) => it.id === goal.presentingProblemID && goal.checked
              );
              return {
                value: it.id,
                label: it.value,
                isDisabled: isPlanWithSelectedGoals,
              };
            })}
            onChange={saveChangeMultiSelect}
            isSearchable={false}
            isMulti
            isClearable={false}
            dataQa="presentingProblemsDropdown"
            styles={{
              multiValue: (base, state) => {
                return {
                  ...base,
                  background: state.data.isDisabled ? `${COLORS.lightGray} !important` : undefined,
                  ':hover': {
                    background: state.data.isDisabled
                      ? `${COLORS.lightGray} !important`
                      : undefined,
                  },
                };
              },
            }}
          />
        </div>
        <div key={3} className="treatment-plan-checklist-container">
          {!!combinedGoals &&
            presentingProblemsIntersection.map((presentingProblem) => {
              const goals = combinedGoals.filter(
                (goal) => presentingProblem.id === goal.presentingProblemID
              );
              return (
                <CheckList
                  key={`${presentingProblem.value}`}
                  listTitle={`${presentingProblem.value} goals`}
                  checkboxChangeHandler={this.checkboxHandler}
                  textAreaChangeHandler={this.textAreaChangeHandler}
                  displayField="description"
                  items={goals}
                  adderChangeHandler={this.adderChangeHandler}
                  itemInit={{
                    presentingProblemID: presentingProblem.id,
                    standard: false,
                    checked: false,
                  }}
                  editorPlaceholder="Edit goal"
                  adderPlaceholder="Add goal"
                  unCheckEmpty
                  textAreaDataQa="customGoalTextArea"
                />
              );
            })}
        </div>
      </form>
    );
  }

  setCardState() {
    const activeGoals = this.state.combinedGoals.filter((goal) => goal.checked);
    if (!!activeGoals && !!activeGoals.length) {
      this.setState({ showCard: false });
    }
  }

  handleNavigation() {
    const { history, roomID } = this.props;
    const postAction = getParamByName('postAction');
    const historyState = history.location.state;
    history.push(
      `/room/${roomID}/treatment-planner-objectives${
        postAction ? `?postAction=${postAction}` : ''
      }`,
      historyState
    );
  }

  adderChangeHandler(event, item) {
    this.setState(
      ({ combinedGoals }) => {
        return {
          combinedGoals: [...combinedGoals, item],
        };
      },
      () => {
        this.styleMultiSelectLabels();
        this.setCardState();
      }
    );
  }

  checkboxHandler(event, goal) {
    const newCombinedGoals = this.state.combinedGoals.map((combinedGoal) =>
      ((goal.id !== undefined && goal.id === combinedGoal.id) ||
        (goal.itemIdx !== undefined && goal.itemIdx === combinedGoal.itemIdx)) &&
      goal.description === combinedGoal.description
        ? goal
        : combinedGoal
    );
    this.setState({ combinedGoals: newCombinedGoals }, () => {
      this.styleMultiSelectLabels();
      this.setCardState();
    });
  }

  saveChangeMultiSelect(options: ValueType<OptionType<string>, true>, { action, removedValue }) {
    const {
      state: { treatmentPlanPresentingProblems },
      props: { getStandardGoals },
    } = this;

    if ((action === 'remove-value' || action === 'pop-value') && removedValue.isDisabled) {
      return; // Ignore removal of the disabled presenting problems
    }

    const presentingProblemTextValues = (options || []).map((it) => it.label);
    const tempTreatmentPlanPresentingProblems = presentingProblemsOptions
      .filter((presentingProblem) => presentingProblemTextValues.includes(presentingProblem.value))
      .map(({ id, value }) => {
        return {
          id,
          text: value,
        };
      });

    const newPresentingProblems = differenceWith(
      tempTreatmentPlanPresentingProblems,
      treatmentPlanPresentingProblems,
      (currentItem, previousItem) => previousItem.id === currentItem.id
    );

    if (newPresentingProblems.length) {
      Promise.all(
        newPresentingProblems.map((presentingProblem) => getStandardGoals(presentingProblem.id))
      ).then(() => {
        const tempStandardGoals = this.props.standardGoals.map(
          ({ id, presentingProblemID, description }) => {
            return {
              id,
              presentingProblemID,
              description,
              checked: false,
              standard: true,
            };
          }
        );

        const combinedGoals = getCombinedItems(
          this.state.combinedGoals,
          tempStandardGoals,
          'description'
        );

        this.setState(
          {
            combinedGoals,
            treatmentPlanPresentingProblems: tempTreatmentPlanPresentingProblems,
          },
          this.styleMultiSelectLabels
        );
      });
    } else {
      this.setState(
        {
          treatmentPlanPresentingProblems: tempTreatmentPlanPresentingProblems,
        },
        this.styleMultiSelectLabels
      );
    }
  }

  styleMultiSelectLabels() {
    const multiSelectSearchChips = document.querySelectorAll(
      '.treatment-planner-multiselect .ui.label'
    );
    const presentingProblemsOfCheckedGoals = this.state.combinedGoals.reduce(
      (acumulator, currentValue) => {
        if (currentValue.checked && acumulator.indexOf(currentValue.presentingProblemID) < 0)
          acumulator.push(currentValue.presentingProblemID);
        return acumulator;
      },
      [] as number[]
    );
    const presentingProblemsToGrayOut = this.state.treatmentPlanPresentingProblems
      .filter((presentingProblem) =>
        presentingProblemsOfCheckedGoals.includes(presentingProblem.id)
      )
      .map((presentingProblem) => presentingProblem.text);
    multiSelectSearchChips.forEach((chip) => {
      const chipValue = chip.getAttribute('value');
      if (chipValue && presentingProblemsToGrayOut.includes(chipValue)) {
        chip.classList.add('grayed');
      } else {
        chip.classList.remove('grayed');
      }
    });
  }

  textAreaChangeHandler(event, item) {
    const combinedGoals = this.state.combinedGoals.map((i) => {
      if (
        ((item.id !== undefined && i.id === item.id) ||
          (item.itemIdx !== undefined && i.itemIdx === item.itemIdx)) &&
        i.standard === false &&
        i.standard === item.standard
      )
        return item;
      return i;
    });
    this.setState(
      {
        combinedGoals,
      },
      () => {
        this.styleMultiSelectLabels();
        this.setCardState();
      }
    );
  }

  render() {
    return (
      <Submenu
        bodyStyle={{
          paddingRight: 35,
          paddingLeft: 35,
        }}
        childComponents={[this.getViewContent()]}
        title="Goals"
        footerComponent={this.getFooterComponent()}
        prevRoute="clinical-tab"
      />
    );
  }
}

const mapStateToProps = (state) => {
  const presentingProblems = mapPresentingProblemsToLegacy(
    state.clinicalInformation.presentingProblems
  );
  return {
    roomID: state.room.roomID,
    currentTreatmentPlan: state.treatmentPlanner.currentTreatmentPlan,
    presentingProblems,
    treatmentPlanPresentingProblems:
      state.treatmentPlanner.currentTreatmentPlan.presentingProblems || [],
    standardGoals: state.treatmentPlanner.standardGoals,
    goals: state.treatmentPlanner.goals,
    showSpinner: state.treatmentPlanner.isUpdating,
    spinnerMessage: state.treatmentPlanner.spinnerMessage,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      editTreatmentPlan,
      getStandardGoals: getStandardGoalsAction,
      getGoals,
      removeGoal,
      saveGoals,
      editGoal,
    },
    dispatch
  );

const TreatmentPlannerGoalsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-expect-error editTreatmentPlan is not typed and this is causing an error
)(TreatmentPlannerGoals);

export default withRouter(TreatmentPlannerGoalsContainer);
