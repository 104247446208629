import moment from 'moment';
import { Big } from '@talkspace/react-toolkit';
import { WizardScheme } from 'stepWizard';

const providerLeavingScheme: WizardScheme = {
  wizardVersion: 1,
  wizardType: 'therapistLeaving',
  wizardModalTitle: 'Provider leaving',
  steps: [],
};

export function getSteps(): {
  steps: WizardScheme['steps'];
} {
  return {
    steps: [
      {
        title: 'What is the main reason you’d like to leave the Talkspace network?',
        inputType: 'select',
        inputState: 'reasonForLeaving',
        inputTypePlaceholder: 'Select one',
        nextButton: {
          displayText: 'Next',
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        postAction: 'setSelectedReasonForLeaving',
        inputOptions: 'reasonForLeavingOptions',
        name: 'reasonForLeaving',
      },
      {
        title: 'Would you like to discuss your concerns with a member of the Quality Support Team?',
        subtitle: 'A team member will reach out to schedule a time that works for you.',
        inputTypePlaceholder: 'Select one',
        nextButton: {
          displayText: 'Yes, I’d like to discuss my concerns',
          actionDispatch: '/provider-leaving/final-step/screen/requestSent',
          whenVisible: 'always',
        },
        secondaryButton: {
          displayText: 'No, I don’t want to discuss my concerns ',
          actionDispatch: 'next',
          whenVisible: 'always',
        },
        name: 'concerns',
        displayCondition: 'isDifficultyWithPlatformReason',
        backOption: 'back',
      },
      {
        title:
          'What would improve the reimbursement structure and keep you on the Talkspace network?',
        titleSize: Big,
        subtitle: 'Provide details regarding pay structure, fees, etc.',
        inputType: 'multilineText',
        inputState: 'reimbursement',
        inputTypePlaceholder: 'Tell us how we can improve',
        nextButton: {
          displayText: 'Next',
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        name: 'reimbursement',
        displayCondition: 'isReimbursementIssuesReason',
        backOption: 'back',
      },
      {
        title:
          'You can increase your referrals by increasing your client capacity. You can do so in your account settings.',
        titleSize: Big,
        nextButton: {
          displayText: 'Go to my account settings',
          actionDispatch: '/my-account?scrollTo=capacity',
          whenVisible: 'always',
        },
        secondaryButton: {
          displayText: 'No thanks, I’d like to leave the network',
          actionDispatch: 'next',
          whenVisible: 'always',
        },
        name: 'fewAmountOfClientReason',
        displayCondition: 'isFewAmountOfClientReason',
        backOption: 'back',
      },
      {
        title:
          'You can decrease your referrals by reducing your client capacity or setting yourself as not available for taking on new clients.',
        titleSize: Big,
        subtitle: 'You can make these changes in your account settings.',
        nextButton: {
          displayText: 'Go to my account settings',
          actionDispatch: '/my-account?scrollTo=capacity',
          whenVisible: 'always',
        },
        secondaryButton: {
          displayText: 'No thanks, I’d like to leave the network',
          actionDispatch: 'next',
          whenVisible: 'always',
        },
        name: 'manyAmountOfClientReason',
        displayCondition: 'isManyAmountOfClientReason',
        backOption: 'back',
      },
      {
        title: 'Tell us more about why you want to leave the Talkspace network.',
        inputState: 'otherReason',
        inputType: 'multilineText',
        inputTypePlaceholder: 'I’m leaving the network because...',
        nextButton: {
          displayText: 'Next',
          actionDispatch: 'next',
          whenVisible: 'onSelect',
        },
        name: 'other',
        displayCondition: 'isOtherReason',
        backOption: 'back',
      },
      {
        title: 'Is there any additional feedback you’d like to share?',
        inputState: 'additionalFeedback',
        inputType: 'multilineText',
        inputTypePlaceholder: 'Let us know how we can do better',
        nextButton: {
          displayText: 'Next',
          actionDispatch: 'next',
          whenVisible: 'always',
        },
        name: 'additionalFeedback',
        backOption: 'back',
      },
      {
        title:
          'As a reminder, per the terms of your Participating Provider Agreement, you’re required to provide sixty (60) days prior written notice.',
        titleSize: Big,
        subtitle:
          'During this time you won’t receive any new clients but must continue treating existing clients until they either end treatment or are transitioned to another provider, whichever comes first.',
        inputState: 'isStaying',
        nextButton: {
          displayText: 'Confirm',
          actionDispatch: 'next',
          whenVisible: 'always',
        },
        secondaryButton: {
          displayText: 'Decline and remain in the network',
          actionDispatch: '/provider-leaving/final-step/screen/staying',
          whenVisible: 'always',
        },
        name: 'participatingProviderAgreement',
        backOption: 'back',
      },
      {
        title: 'Please indicate the date you would like to leave the Talkspace network. ',
        subtitle: 'You must pick a date 60 days from now per the Participating Provider Agreement.',
        inputState: 'leavingDatePsychiatrist',
        inputType: 'datePicker',
        minDate: moment().add(60, 'days'),
        nextButton: {
          displayText: 'Next',
          actionDispatch: '/provider-leaving/final-step/screen/leaving',
          whenVisible: 'onSelect',
        },
        name: 'leavingDatePsychiatrist',
        backOption: 'back',
        displayCondition: 'isPsychiatrist',
      },
      {
        title: 'Would you like to phase out of the Talkspace network?',
        subtitle:
          'You would keep your current caseload until the clients leave and you would not receive new clients. ',
        inputState: 'isTherapistPhasingOut',
        nextButton: {
          displayText: 'Yes, I would like to phase out',
          actionDispatch: 'next',
          whenVisible: 'always',
          defaultValue: true,
        },
        secondaryButton: {
          displayText: 'No, I want to leave and transfer my clients',
          actionDispatch: 'next',
          whenVisible: 'always',
          defaultValue: false,
        },
        name: 'isTherapistPhasingOut',
        displayCondition: 'isTherapist',
        backOption: 'back',
      },
      {
        title:
          'By phasing out, you won’t receive any new clients. You’ll maintain your current clients until they leave the platform, and once they do so, you’ll no longer be in the Talkspace Network.',
        titleSize: Big,
        subtitle: 'Once you confirm below, your phase out will begin immediately.',
        nextButton: {
          displayText: 'Confirm',
          actionDispatch: '/provider-leaving/final-step/screen/phasingOut',
          whenVisible: 'always',
        },
        name: 'therapistPhasingOut',
        displayCondition: 'isTherapistPhasingOut',
        backOption: 'back',
      },
      {
        title: 'Please indicate the date you would like to leave the Talkspace network. ',
        inputState: 'leavingDateTherapist',
        inputType: 'datePicker',
        minDate: moment().add(1, 'd'),
        nextButton: {
          displayText: 'Confirm',
          actionDispatch: '/provider-leaving/final-step/screen/leaving',
          whenVisible: 'always',
        },
        name: 'leavingDateTherapist',
        backOption: 'back',
      },
    ],
  };
}

export default providerLeavingScheme;
