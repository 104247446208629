import React from 'react';
import TouchableView from '../TouchableView';
import styled from '../../core/styled';

const Container = styled(TouchableView)<{
  isOn: boolean;
  containerWidth?: number;
  containerHeight?: number;
}>(({ theme: { colors }, isOn, containerWidth = 56, containerHeight = 32 }) => {
  return {
    width: containerWidth,
    height: containerHeight,
    borderRadius: containerHeight / 2,
    background: isOn ? colors.accessibilityGreenDark : colors.darkBlue150,
    display: 'flex',
    justifyContent: 'center',
    alignItems: isOn && 'flex-end',
    paddingLeft: 2,
    paddingRight: 2,
  };
});

const Circle = styled(TouchableView)<{ circleSize?: number }>(
  ({ theme: { colors }, circleSize = 28 }) => {
    return {
      width: circleSize,
      height: circleSize,
      borderRadius: '50%',
      background: colors.white,
    };
  }
);

export interface ToggleSwitchProps {
  isOn: boolean;
  isDisabled?: boolean;
  handleOnPress: () => void;
  containerWidth?: number;
  containerHeight?: number;
  circleSize?: number;
  dataQa?: string;
}

const ToggleSwitch = ({
  isOn,
  handleOnPress,
  containerWidth,
  containerHeight,
  circleSize,
  dataQa,
}: ToggleSwitchProps) => (
  <Container
    isOn={isOn}
    onPress={handleOnPress}
    containerWidth={containerWidth}
    containerHeight={containerHeight}
    dataQa={dataQa}
  >
    <Circle onPress={handleOnPress} circleSize={circleSize} />
  </Container>
);

export default ToggleSwitch;
