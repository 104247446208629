/* eslint-disable no-console */
import Base64 from 'ts-frontend/utils/Base64';

const DEBUG_LOG = false;

export type Listener = (token: string, userID: number) => void;

let instance: JwtService;

const getTokenFromQuerystring = () => {
  const querystring = window.location.search;
  const queryParams: any = {};

  querystring.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
    queryParams[key] = value;
    return '';
  });

  return queryParams.token;
};

export default class JwtService {
  currentToken: string = '';

  currentUserID: number = -1;

  parentOrigin: string = '';

  parentFrameIfNumber: number = 0;

  tokenListeners: Listener[] = [];

  constructor() {
    if (instance) {
      return instance;
    }
    instance = this;
    this.initService();
  }

  static instance(): JwtService {
    return new JwtService();
  }

  private initService = () => {
    if (window.location === window.parent.location) {
      // try to get a token from querystring
      const jwtToken = getTokenFromQuerystring();
      if (jwtToken) {
        let tokenData;
        try {
          tokenData = this.parseJwt(jwtToken);
        } catch (error) {
          if (DEBUG_LOG) console.error(error);
          return;
        }

        if (tokenData.userID) {
          if (DEBUG_LOG) console.info(tokenData);
          this.currentToken = jwtToken;
          this.currentUserID = Number(tokenData.userID);
          this.notifyTokenListeners();
        }
      }
    }
  };

  private notifyTokenListeners = (): void => {
    this.tokenListeners.forEach((fn) => fn(this.currentToken, this.currentUserID));
    if (DEBUG_LOG) console.log(this.currentToken, this.currentUserID);
  };

  /**
   * Register callback to socket server state connected or not,
   * the callback will be called immediately with current state
   * @param  {Function} fn callbacks with a boolean parameter
   * @returns void
   */
  public addTokenListeners = (fn: Listener): void => {
    if (typeof fn !== 'function') return;
    this.tokenListeners.push(fn);
    fn(this.currentToken, this.currentUserID);
  };

  public getAccessToken = () => this.currentToken;

  public getUserData = () => {
    return { id: this.currentUserID };
  };

  public parseJwt = (token: string) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      Base64.atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join('')
    );

    return JSON.parse(jsonPayload);
  };
}
