/* eslint-disable @typescript-eslint/ban-ts-comment */
import Svg, { Defs, Rect, G, Use, Path } from 'svgs';
import { EmotionStyle } from '../../core/styled';

export interface UpwardTrendProps {
  width?: number;
  height?: number;
  primaryColor?: string;
  secondaryColor?: string;
  style?: EmotionStyle;
}

const UpwardTrend = ({
  primaryColor,
  secondaryColor,
  width = 102,
  height = 102,
  ...otherProps
}: UpwardTrendProps) => {
  const titleText = 'icon with upward trend';
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 102 102"
      title={titleText}
      aria-label={titleText}
      {...otherProps}
    >
      <filter
        x="-27.1%"
        y="-22.9%"
        width="154.3%"
        height="154.3%"
        filterUnits="objectBoundingBox"
        id="UpwardTrend-filter_a"
      >
        <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation={2} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>

      <filter
        x="-17.1%"
        y="-12.9%"
        width="134.3%"
        height="134.3%"
        filterUnits="objectBoundingBox"
        id="UpwardTrend-filter_b"
      >
        <feMorphology radius={1.5} in="SourceAlpha" result="shadowSpreadOuter1" />
        <feOffset dy={3} in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation={5} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix
          values="0 0 0 0 0.062745098 0 0 0 0 0.427620878 0 0 0 0 0.584313725 0 0 0 0.3 0"
          in="shadowBlurOuter1"
        />
      </filter>

      <Defs>
        <Rect id="UpwardTrend-rect_c" x={0} y={0} width={70} height={70} rx={20} />
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        {/* @ts-ignore */}
        <G filter="url(#UpwardTrend-filter_a)" transform="translate(-4 -378) translate(20 391)">
          {/* @ts-ignore */}
          <Use fill="#000" filter="url(#UpwardTrend-filter_b)" xlinkHref="#UpwardTrend-rect_c" />
          <Use fill="#FFF" xlinkHref="#UpwardTrend-rect_c" />
        </G>
        <G transform="translate(-4 -378) translate(20 391) translate(11 17)">
          <Path
            d="M27.82 1.98v31.947h-.997V9.74a1.98 1.98 0 00-1.98-1.98h-6.72a1.98 1.98 0 00-1.98 1.98V33.927h-.996V17.5a1.98 1.98 0 00-1.98-1.98h-6.72a1.98 1.98 0 00-1.98 1.98v16.34c0 .597.483 1.08 1.08 1.08H37.52a.98.98 0 00.98-.993V1.982a1.98 1.98 0 00-1.98-1.98L29.8 0a1.98 1.98 0 00-1.98 1.98z"
            fill="#CFEAFF"
            fillRule="nonzero"
          />
          <Path
            d="M.312 30.08c13.79 0 33.175-3.382 44-25"
            stroke="#5B82FA"
            strokeWidth={3.08}
            strokeLinecap="round"
          />
          <Path
            stroke="#5B82FA"
            strokeWidth={3.08}
            strokeLinecap="round"
            d="M33.312046 7.60994236L44.312046 5.07988467 44.312046 17.0798847"
          />
        </G>
      </G>
    </Svg>
  );
};

export default UpwardTrend;
