import Svg, { Circle, ClipPath, Defs, G, Path, Rect } from 'svgs';

const BonusMilestonePredictedIcon = () => (
  <Svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Circle cx="15" cy="15" r="13.5" fill="#5C84FB" stroke="white" stroke-width="3" />
    <G clip-path="url(#clip0_772_33051)">
      <Path
        d="M14.8428 11.9116C14.2964 11.998 13.7853 11.6264 13.6989 11.0801C13.6125 10.5337 13.9841 10.0226 14.5304 9.93618L19.4691 9.15535C20.0154 9.06897 20.5266 9.44058 20.613 9.98692L21.3938 14.9256C21.4802 15.4719 21.1086 15.9831 20.5623 16.0695C20.0159 16.1558 19.5047 15.7842 19.4184 15.2379L19.0148 12.6852L14.6122 18.7377C14.2874 19.1845 13.6608 19.2836 13.2139 18.9587L9.44979 16.2224L5.87878 21.1309C5.55395 21.5777 4.92735 21.6768 4.48052 21.352C4.03368 21.0271 3.93462 20.4005 4.25945 19.9537L8.41727 14.2342C8.7421 13.7874 9.36869 13.6883 9.81552 14.0131L13.5832 16.7521L17.3954 11.5081L14.8428 11.9116Z"
        fill="white"
      />
      <Rect
        x="2"
        y="13.9434"
        width="17"
        height="9"
        transform="rotate(-8.98445 2 13.9434)"
        fill="#5C84FB"
      />
      <Path
        d="M9.33816 19.374C8.81227 19.5427 8.52271 20.1058 8.69141 20.6317C8.86011 21.1576 9.42318 21.4471 9.94907 21.2784L9.33816 19.374ZM18.7304 9.45222C15.6367 15.8783 12.1522 18.4713 9.33816 19.374L9.94907 21.2784C13.428 20.1625 17.2744 17.0871 20.5324 10.3198L18.7304 9.45222Z"
        fill="white"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_772_33051">
        <Rect width="19" height="16" fill="white" transform="translate(6 7)" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default BonusMilestonePredictedIcon;
