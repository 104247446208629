import { DependencyList } from 'react';
import { useIonicEffect } from './genericHooks';

type IonicKeyboardEventOptions =
  | 'keyboardWillShow'
  | 'keyboardDidShow'
  | 'keyboardWillHide'
  | 'keyboardDidHide';

// eslint-disable-next-line import/prefer-default-export
export const useIonicKeyboardListener = (
  eventName: IonicKeyboardEventOptions,
  handler: EventListenerOrEventListenerObject,
  deps: DependencyList
) => {
  useIonicEffect(() => {
    window.addEventListener(eventName, handler);

    return () => {
      window.removeEventListener(eventName, handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
