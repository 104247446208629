import { useEmotionTheme } from '@talkspace/react-toolkit';
import { VoidFunctionComponent } from 'react';
import Svg, { Circle, Path, G } from 'svgs';

interface CoinProps {
  width?: number;
  height?: number;
  isActive?: boolean;
}

const Coin: VoidFunctionComponent<CoinProps> = ({
  width = 22,
  height = 22,
  isActive,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  return (
    <Svg width={width} height={width} viewBox="0 0 22 22" fill="none" {...otherProps}>
      {isActive ? (
        <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
          <Path
            d="M34 133h166a7 7 0 017 7v30a7 7 0 01-7 7H34a7 7 0 01-7-7v-30a7 7 0 017-7z"
            fill={colors.permaLinkWaterGrey}
            transform="translate(-43 -144)"
          />
          <Path
            d="M11 0c6.075 0 11 4.925 11 11s-4.925 11-11 11S0 17.075 0 11 4.925 0 11 0zm.025 4.7c-.347 0-.52.173-.52.52v.756c-.874.095-1.587.392-2.139.892-.551.5-.827 1.137-.827 1.909 0 .717.238 1.294.715 1.73.477.438 1.18.767 2.11.987l.141.024v3.12c-.48-.071-.86-.229-1.14-.473a1.703 1.703 0 01-.55-.886.957.957 0 00-.295-.432.708.708 0 00-.45-.147.704.704 0 00-.52.2.704.704 0 00-.2.52c0 .056.006.113.018.172.012.059.025.124.041.195.166.614.528 1.116 1.087 1.506.56.39 1.23.62 2.009.692v.791c0 .34.173.508.52.508a.501.501 0 00.36-.141.485.485 0 00.148-.367v-.78c.89-.078 1.635-.366 2.233-.862.599-.496.898-1.17.898-2.02 0-.757-.246-1.364-.738-1.82-.493-.457-1.223-.776-2.192-.958l-.201-.035V7.359c.378.07.695.216.951.437.256.22.44.508.55.862.141.37.39.556.744.556a.7.7 0 00.508-.195.67.67 0 00.201-.502c0-.063-.004-.124-.012-.183a.838.838 0 00-.047-.184c-.15-.59-.486-1.079-1.01-1.465-.524-.386-1.152-.622-1.885-.709V5.22a.5.5 0 00-.148-.378.501.501 0 00-.36-.142zm.508 7.09l.225.047c.512.142.87.325 1.075.55.205.224.307.502.307.833 0 .37-.132.683-.396.939-.264.256-.667.416-1.211.479V11.79zm-1.028-4.431v2.682l-.083-.024c-.472-.141-.819-.32-1.04-.537a1.075 1.075 0 01-.33-.798c0-.315.13-.595.39-.839s.614-.406 1.063-.484z"
            transform="translate(-43 -144) translate(43 144)"
            fill={colors.permaEden}
          />
        </G>
      ) : (
        <>
          <Circle
            cx={11}
            cy={11}
            r={10.25}
            fill={colors.permaOysterBay}
            stroke={colors.permaEden}
            strokeWidth={1.5}
          />
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.025 17.285a.501.501 0 00.36-.142.485.485 0 00.148-.367v-.78c.89-.078 1.635-.366 2.233-.862.599-.496.898-1.17.898-2.02 0-.757-.246-1.364-.738-1.82-.492-.457-1.223-.776-2.192-.958l-.201-.035V7.359c.378.07.695.216.951.437.256.22.44.508.55.863.141.37.39.555.744.555a.7.7 0 00.508-.195c.134-.13.201-.297.201-.502 0-.063-.004-.124-.012-.183a.835.835 0 00-.047-.184c-.15-.59-.486-1.079-1.01-1.465-.524-.386-1.152-.622-1.885-.709V5.22a.5.5 0 00-.148-.378.501.501 0 00-.36-.142c-.347 0-.52.173-.52.52v.756c-.874.095-1.587.392-2.139.892-.551.5-.827 1.137-.827 1.909 0 .717.238 1.294.715 1.73.477.438 1.18.767 2.11.987l.141.024v3.12c-.48-.071-.86-.229-1.14-.473a1.704 1.704 0 01-.55-.886.957.957 0 00-.295-.432.708.708 0 00-.45-.147.704.704 0 00-.52.2.703.703 0 00-.2.52c0 .056.006.113.018.172.012.059.025.124.041.195.166.614.528 1.116 1.087 1.506.56.39 1.23.62 2.009.692v.791c0 .34.173.509.52.509zm-.52-7.244l-.083-.024c-.472-.141-.819-.32-1.04-.537a1.075 1.075 0 01-.33-.798c0-.315.13-.595.39-.839s.614-.406 1.063-.484v2.682zm1.252 1.796l-.224-.047v2.848c.544-.063.947-.223 1.211-.479.264-.256.396-.57.396-.94 0-.33-.102-.608-.307-.832-.205-.225-.563-.408-1.075-.55z"
            fill={colors.permaEden}
          />
        </>
      )}
    </Svg>
  );
};

export default Coin;
