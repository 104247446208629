import createTSAnalyticsTracker from 'ts-analytics/trackers/tsAnalytics';

import { EventNameTypes, EventProperties, TrackerTypes } from './trackerTypes';
import appConfig from '../configs';

const tsAnalyticsTracker = createTSAnalyticsTracker({
  endpoint: `${appConfig.analytics.mixpanel.tsAnalyticsEndpoint}/event`,
});

type DebugTypes = 'console' | 'console and remote' | 'remote';

const DEBUG = false; // IF YOU SEE THIS AS TRUE IN A PR, REQUEST TO CHANGE IT TO FALSE
/**
 * Only used when DEBUG is true
 */
const DEBUG_MODE: DebugTypes = 'console and remote';

const logEvent = (name: string, ...params: unknown[]) => {
  if (DEBUG && ['console', 'console and remote'].includes(DEBUG_MODE))
    console.log(`[Tracking][${name}]`, ...params); // eslint-disable-line no-console
};

const swallowErrors =
  <T extends unknown[], U extends unknown>(fun: (...args: T) => Promise<U>) =>
  async (...args: T): Promise<U | void> => {
    try {
      const res = await fun(...args);
      return res;
    } catch (err) {
      if (DEBUG) {
        console.warn('[Tracking] - Error in function', fun.name, err); // eslint-disable-line no-console
      }
      return Promise.resolve();
    }
  };

interface Scope {
  /**
   * Note: userID is only set when one of the trackers is done loading
   * OR when debug mode is on
   */
  userID: string;
  calledSetUserID: boolean;
}

const scope: Scope = {
  userID: '',
  calledSetUserID: false,
};

const logErrorIfMissingUserID = () => {
  if (!scope.calledSetUserID) {
    // eslint-disable-next-line no-console
    console.error(
      new Error('Cannot track event without a userID. Did you forget to use `setTrackerUserID`?')
    );
  }
};

const completedSetup = () => !!scope.userID;

const initTracker = async (apiWrapper) => {
  logEvent('initTracker');

  tsAnalyticsTracker.init(apiWrapper);
};

const setTrackerUserID = async (userID: string | number) => {
  if (!userID) {
    // eslint-disable-next-line no-console
    console.warn('[Tracking] - Called setTrackerUserID with an invalid userID');
    return;
  }

  if (scope.calledSetUserID && scope.userID === String(userID)) return;
  scope.userID = String(userID);
  scope.calledSetUserID = true;
  logEvent('setTrackerUserID', userID);
  tsAnalyticsTracker.setUserID(userID);
};

// eslint-disable-next-line @typescript-eslint/no-use-before-define
type TrackEventParams = Parameters<typeof trackEvent>[]; // eslint-disable-line no-use-before-define
let trackEventCachedCalls: TrackEventParams = [];

const addCachedCall = async (
  action: EventNameTypes,
  props: EventProperties,
  trackers?: TrackerTypes[]
) => {
  if (trackEventCachedCalls.length > 100) {
    trackEventCachedCalls = [[action, props, trackers]];
  } else {
    trackEventCachedCalls.push([action, props, trackers]);
  }
};

const trackEvent = async (
  action: EventNameTypes,
  props: EventProperties,
  trackers?: TrackerTypes[]
) => {
  logErrorIfMissingUserID();
  if (!completedSetup()) {
    addCachedCall(action, props, trackers);
    return;
  }

  logEvent('trackEvent', action, props, trackers);
  if (DEBUG && !['remote', 'console and remote'].includes(DEBUG_MODE)) return;

  if (trackers && trackers.includes('tsAnalytics')) {
    tsAnalyticsTracker.trackEvent(action, props).catch((err) => {
      if (DEBUG) {
        console.warn('[Tracking] - Error in function', 'tsAnalyticsTracker', err); // eslint-disable-line no-console
      }
    });
  }
};

const setPeople = async (data: object, trackers?: TrackerTypes[]) => {
  logEvent('setPeople', data, trackers);
};

const setAlias = async (newID?: string, existingID?: string, trackers?: TrackerTypes[]) => {
  logEvent('setAlias', newID, existingID, trackers);
};

const setSuperProperty = async (metadata: object, trackers?: TrackerTypes[]) => {
  logEvent('setSuperProperty', metadata, trackers);
};

const increment = async (name: string, by: number, trackers?: TrackerTypes[]) => {
  logEvent('increment', name, by, trackers);
};

const safeInitTracker = swallowErrors(initTracker);
const safeSetTrackerUserID = swallowErrors(setTrackerUserID);
const safeTrackEvent = swallowErrors(trackEvent);
const safeSetPeople = swallowErrors(setPeople);
const safeSetAlias = swallowErrors(setAlias);
const safeIncrement = swallowErrors(increment);
const safeSetSuperProperty = swallowErrors(setSuperProperty);

export {
  tsAnalyticsTracker,
  safeInitTracker as initTracker,
  safeSetTrackerUserID as setTrackerUserID,
  safeTrackEvent as trackEvent,
  safeSetPeople as setPeople,
  safeSetAlias as setAlias,
  safeIncrement as increment,
  safeSetSuperProperty as setSuperProperty,
};
