import moment from 'moment';
import isEqual from 'lodash/isEqual';
import { RoomRecord } from '../types';
import {
  AppliedFilter,
  CaseLoadFilterType,
  CaseLoadInputs,
  FilterState,
  OptionType,
  SortField,
} from './types';
import storage from '../../../core/storage';
import {
  DROPDOWN_POSTFIX,
  therapistTypes,
  PSYCHIATRIST_CASELOAD_FILTERS,
  THERAPIST_CASELOAD_FILTERS,
  psychiatryTypes,
} from './constants';
import saveLocalStorageData from '../../../utils/saveLocalStorageData';

const dateSwitch = (key: string) => {
  const timeAgoFn: ((input: string | Date) => boolean) | undefined = {
    today: (input: string | Date) => moment().subtract(2, 'days').isAfter(moment(input), 'day'),
    inOver3Days: (input: string | Date) =>
      moment().subtract(3, 'days').isAfter(moment(input), 'day'),
    inOver5Days: (input: string | Date) =>
      moment().subtract(5, 'days').isAfter(moment(input), 'day'),
    inOverAWeek: (input: string | Date) =>
      moment().subtract(1, 'week').isAfter(moment(input), 'day'),
    inOver2Weeks: (input: string | Date) =>
      moment().subtract(2, 'week').isAfter(moment(input), 'day'),
    inOverAMonth: (input: string | Date) =>
      moment().subtract(1, 'month').isAfter(moment(input), 'day'),
    inOver2Months: (input: string | Date) =>
      moment().subtract(2, 'month').isAfter(moment(input), 'day'),
    inOver3Months: (input: string | Date) =>
      moment().subtract(3, 'month').isAfter(moment(input), 'day'),
  }[key];

  return timeAgoFn;
};

const matchesMessaging = (filterState: FilterState, client: RoomRecord): boolean => {
  const progressReference: Record<string, boolean> = {
    notInProgress: false,
    inProgress: true,
  };

  if (filterState.hasUnreadMessagesAndWaiting) {
    if (!(client.hasUnreadMessages && client.clientWaiting)) {
      return false;
    }
  }
  if (filterState.clientWaiting) {
    if (
      !(
        client.clientWaiting &&
        dateSwitch((filterState.clientWaitingDropDownList as OptionType).value)?.(
          client.clientWaiting
        )
      )
    ) {
      return false;
    }
  }
  if (filterState.lastActivity) {
    if (
      !(
        client.lastActivity &&
        dateSwitch((filterState.lastActivityDropDownList as OptionType).value)?.(
          client.lastActivity
        )
      )
    ) {
      return false;
    }
  }
  if (filterState.activeAsyncSession) {
    if (
      !['bh', 'eap'].includes(client.accountType) ||
      !(
        progressReference[(filterState.activeAsyncSessionDropDownList as OptionType).value] ===
        client.activeAsyncSession
      )
    ) {
      return false;
    }
  }
  return true;
};

const matchesLiveSessions = (filterState: FilterState, client: RoomRecord): boolean => {
  if (filterState.unusedLiveSessions) {
    if (!client.validVideoCredits) {
      return false;
    }
    if (['Cancelled', 'Expired'].includes(client.roomStatus)) {
      return false;
    }
  }
  if (filterState.noLiveSessionsSchedule) {
    if (!client.validVideoCredits) {
      return false;
    }
    if (client.activeBookings?.length > 0) {
      return false;
    }
  }
  if (filterState.awaitingConfirmation) {
    const selectedAwaitingConfirmationFrom = (
      filterState.awaitingConfirmationDropDownList as OptionType
    ).value;
    const scheduledByUserType =
      selectedAwaitingConfirmationFrom === 'client' ? 'provider' : 'client';
    if (
      !client?.activeBookings?.some(
        (activeBooking) =>
          activeBooking.timekitBookingState === 'tentative' &&
          activeBooking.scheduledByUserType === scheduledByUserType
      )
    ) {
      return false;
    }
  }
  return true;
};

const matchesClinical = (filterState: FilterState, client: RoomRecord): boolean => {
  if (filterState.acuity) {
    if (!((filterState.acuityDropDownList as OptionType).value === client.acuity)) {
      return false;
    }
  }
  if (filterState.activeSuicidalIdeation) {
    if (
      !(
        (filterState.activeSuicidalIdeationDropDownList as OptionType).value ===
        String(client.activeSuicidalIdeation)
      )
    ) {
      return false;
    }
  }
  if (filterState.activeHomicidalIdeation) {
    if (
      !(
        (filterState.activeHomicidalIdeationDropDownList as OptionType).value ===
        String(client.activeHomicidalIdeation)
      )
    ) {
      return false;
    }
  }
  return true;
};

const matchesPlanType = (filterState: FilterState, client: RoomRecord): boolean => {
  if (filterState.roomActiveStatus) {
    if (
      !((filterState.roomActiveStatusDropDownList as OptionType).value === client.roomActiveStatus)
    ) {
      return false;
    }
  }
  if (filterState.planModality) {
    if (!((filterState.planModalityDropDownList as OptionType).value === client.planModality)) {
      return false;
    }
  }
  if (filterState.accountType) {
    if (!((filterState.accountTypeDropDownList as OptionType).value === client.accountType)) {
      return false;
    }
  }
  if (filterState.productLine) {
    if (!((filterState.productLineDropDownList as OptionType).value === client.productLine)) {
      return false;
    }
  }
  return true;
};

const matchesFilter = (filterState: FilterState, data: RoomRecord[]) => {
  if (Object.keys(filterState).length) {
    return data.filter(
      (client) =>
        matchesMessaging(filterState, client) &&
        matchesLiveSessions(filterState, client) &&
        matchesClinical(filterState, client) &&
        matchesPlanType(filterState, client)
    );
  }

  return data;
};

const getCaseLoadFilterState = (caseLoadFilterType: CaseLoadFilterType) => {
  const stringifyFormData = storage.getItem(
    caseLoadFilterType === 'therapist' ? THERAPIST_CASELOAD_FILTERS : PSYCHIATRIST_CASELOAD_FILTERS
  );
  if (stringifyFormData) {
    return JSON.parse(stringifyFormData);
  }
  return null;
};

const findOption = (options: OptionType[], value: string): OptionType =>
  options?.find((option) => option.value === value)!;

const buildFormState = (data: CaseLoadInputs[][], isEmptyFormState: boolean = false) =>
  (data.flat() as CaseLoadInputs[]).reduce<[keyof RoomRecord, FilterState[keyof FilterState]][]>(
    (accum, next) => {
      const isChecked = !!next?.isChecked;
      if (next.key && next.label) {
        if (next?.dropDown?.default && next?.dropDown?.options) {
          accum.push([
            `${next.key}${DROPDOWN_POSTFIX}`,
            findOption(next.dropDown.options, next.dropDown.default),
          ]);
        }
        accum.push([next.key, isEmptyFormState ? false : isChecked]);
      }
      return accum;
    },
    []
  );

const getInitialFormState = (caseLoadFilterType: CaseLoadFilterType): FilterState => {
  if (caseLoadFilterType === 'therapist') {
    return Object.fromEntries(buildFormState(therapistTypes));
  }

  if (caseLoadFilterType === 'psychiatrist') {
    return Object.fromEntries(buildFormState(psychiatryTypes));
  }

  return {};
};

const getEmptyFormState = (caseLoadFilterType: CaseLoadFilterType): FilterState => {
  if (caseLoadFilterType === 'therapist') {
    return Object.fromEntries(buildFormState(therapistTypes, true));
  }

  if (caseLoadFilterType === 'psychiatrist') {
    return Object.fromEntries(buildFormState(psychiatryTypes, true));
  }

  return {};
};

const getCurrentFiltersState = (
  persistData: boolean,
  caseLoadFilterType: CaseLoadFilterType
): FilterState => {
  if (persistData) {
    const storedFormData = getCaseLoadFilterState(caseLoadFilterType);
    if (storedFormData) {
      return storedFormData;
    }

    const initialFormState = getInitialFormState(caseLoadFilterType);
    saveLocalStorageData(
      caseLoadFilterType === 'therapist'
        ? THERAPIST_CASELOAD_FILTERS
        : PSYCHIATRIST_CASELOAD_FILTERS,
      initialFormState
    );
    return initialFormState;
  }
  return getInitialFormState(caseLoadFilterType);
};

const getHasMadeFilterSelections = (
  persistData: boolean,
  caseLoadFilterType: CaseLoadFilterType
): boolean => {
  if (persistData) {
    const storedFormData = getCaseLoadFilterState(caseLoadFilterType);
    if (!storedFormData) {
      return false;
    }
    return !isEqual(getInitialFormState(caseLoadFilterType), storedFormData);
  }
  return false;
};

const getAppliedFilters = (
  persistData: boolean,
  caseLoadFilterType: CaseLoadFilterType
): AppliedFilter[] => {
  const filterState = getCurrentFiltersState(persistData, caseLoadFilterType);
  const filterTypes = caseLoadFilterType === 'therapist' ? therapistTypes : psychiatryTypes;
  const flatFilterTypes = filterTypes.flat() as CaseLoadInputs[];
  const appliedFilters: AppliedFilter[] = [];
  for (const filterType of flatFilterTypes) {
    const filterTypeKey = filterType.key;
    const filterStateItem = filterState[filterTypeKey];
    if (filterStateItem === true) {
      if (filterType.dropDown) {
        const dropDownKey = filterType.dropDown.dropDownPropertyKey;
        const filterStateDropDownItem: OptionType = filterState[dropDownKey] as OptionType;
        appliedFilters.push({
          key: filterTypeKey,
          label: `${filterType.label} ${filterStateDropDownItem.label}`,
        });
      } else {
        appliedFilters.push({ key: filterTypeKey, label: filterType.label });
      }
    }
  }
  return appliedFilters;
};

const getSortDropdownTitles = () => [
  { title: 'Waiting', subtitle: 'See clients waiting shortest first', id: 'waiting' },
  {
    title: 'Last activity',
    subtitle: 'See clients with recent activity first',
    id: 'lastActivity',
  },
  { title: 'Room status', subtitle: 'See paying clients first', id: 'roomStatus' },
  { title: 'Client name', subtitle: 'Sort alphabetically by first name', id: 'clientName' },
];

const compareWaitingDateOldestToNewest = (a: RoomRecord, b: RoomRecord) =>
  new Date(a.clientWaiting).getTime() - new Date(b.clientWaiting).getTime();

const compareLastActivityDates = (a: RoomRecord, b: RoomRecord) =>
  new Date(a.lastActivity).getTime() - new Date(b.lastActivity).getTime();

const compareRoomStatus = (a: RoomRecord, b: RoomRecord) => {
  const roomStatusOrder = {
    Paying: 1,
  };
  const roomStatusA = roomStatusOrder[a.roomStatus] || 0;
  const roomStatusB = roomStatusOrder[b.roomStatus] || 0;

  return roomStatusA - roomStatusB;
};

const compareClientName = (a: RoomRecord, b: RoomRecord) => {
  const firstNameComparison = b.clientFirstName.localeCompare(a.clientFirstName);

  if (firstNameComparison !== 0) {
    return firstNameComparison; // Compare based on clientFirstName if they are not equal
  }
  return b.clientNickname.localeCompare(a.clientNickname);
};

const getSortedArray = (field: SortField, array?: Array<RoomRecord>) => {
  if (array && array.length) {
    switch (field) {
      case 'waiting':
        return array.sort(compareWaitingDateOldestToNewest);
      case 'lastActivity':
        return array.sort(compareLastActivityDates);
      case 'roomStatus':
        return array.sort(compareRoomStatus);
      case 'clientName':
        return array.slice().sort(compareClientName);
      default:
        return array;
    }
  }
  return [];
};

export {
  matchesFilter,
  matchesMessaging,
  matchesLiveSessions,
  matchesClinical,
  matchesPlanType,
  dateSwitch,
  getCaseLoadFilterState,
  getInitialFormState,
  getEmptyFormState,
  getCurrentFiltersState,
  getHasMadeFilterSelections,
  getAppliedFilters,
  getSortDropdownTitles,
  getSortedArray,
};
