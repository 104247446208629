import { VoidFunctionComponent } from 'react';
import Svg, { Path } from 'svgs';
import { useEmotionTheme } from '../../core/styled';

interface CheckAndXMarksProps {
  color?: string;
  width?: number;
  height?: number;
}

const CheckAndXMarks: VoidFunctionComponent<CheckAndXMarksProps> = ({
  color,
  width = 24,
  height = 24,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      {...otherProps}
    >
      <Path
        d="M14.6087 10.5241H22.6719C23.4054 10.5241 24 11.1274 24 11.8717V22.6524C24 23.3967 23.4054 24 22.6719 24H12.0474C11.314 24 10.7194 23.3967 10.7194 22.6524V14.8235H11.9526C13.4195 14.8235 14.6087 13.6169 14.6087 12.1283V10.5241ZM4.45373 6.59842C4.19441 6.33529 3.77397 6.33529 3.51465 6.59842C3.25533 6.86155 3.25533 7.28818 3.51465 7.55131L5.17473 9.2358C5.43405 9.49894 5.85449 9.49894 6.11381 9.2358L9.43397 5.86682C9.69329 5.60369 9.69329 5.17706 9.43397 4.91393C9.17465 4.65079 8.75421 4.65079 8.49489 4.91393L5.64427 7.80646L4.45373 6.59842ZM1.32806 0H11.9526C12.686 0 13.2806 0.603338 13.2806 1.34759V12.1283C13.2806 12.8726 12.686 13.4759 11.9526 13.4759H1.32806C0.594594 13.4759 0 12.8726 0 12.1283V1.34759C0 0.603338 0.594594 0 1.32806 0ZM16.4206 17.5989L15.2301 18.807C14.9707 19.0701 14.9707 19.4967 15.2301 19.7599C15.4894 20.023 15.9098 20.023 16.1691 19.7599L17.3597 18.5518L18.5502 19.7599C18.8095 20.023 19.23 20.023 19.4893 19.7599C19.7486 19.4967 19.7486 19.0701 19.4893 18.807L18.2988 17.5989L19.4893 16.3909C19.7486 16.1278 19.7486 15.7011 19.4893 15.438C19.23 15.1749 18.8095 15.1749 18.5502 15.438L17.3597 16.646L16.1691 15.438C15.9098 15.1749 15.4894 15.1749 15.2301 15.438C14.9707 15.7011 14.9707 16.1278 15.2301 16.3909L16.4206 17.5989Z"
        fill={color || colors.deepTeal}
      />
    </Svg>
  );
};

export default CheckAndXMarks;
