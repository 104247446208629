import React, { forwardRef } from 'react';
import CardContentView from './CardContentView';
import CardContentSkeleton from './CardContentSkeleton';
import { CardContentProps } from './types';

const CardContent = forwardRef<HTMLDivElement, CardContentProps>(
  ({ isLoading, ...otherProps }: CardContentProps, ref) =>
    isLoading ? (
      <CardContentSkeleton {...otherProps} ref={ref} />
    ) : (
      <CardContentView {...otherProps} ref={ref} />
    )
);

export default CardContent;
