import { useQuery } from 'react-query';
import {
  ProgressNoteDiagnosisCreationAttributes,
  ProgressNoteMedicalInformationConditionCreationAttributes,
  ProgressNoteMedicalInformationCreationAttributes,
  ProgressNoteMedicalInformationMedicationCreationAttributes,
  ProgressNoteMentalStatusExamCreationAttributes,
  ProgressNotePsychiatryPlanCreationAttributes,
  ProgressNotePsychiatryPlanMedicationCreationAttributes,
  ProgressNoteRiskAssessmentCreationAttributes,
  ProgressNoteSessionAttendeeCreationAttributes,
  ProgressNoteSessionSummaryCreationAttributes,
  NoteStatus,
  ProgressNoteSubstanceUseCreationAttributes,
  ProgressNoteTreatmentPlanProgressCreationAttributes,
  User,
  PresentingProblem,
  ProgressNoteTreatmentPlanGoalAttributes,
  ProgressNoteTreatmentPlanObjectiveAttributes,
  ProgressNoteTreatmentPlanInterventionAttributes,
  ProgressNoteVideoCallResponseAttributes,
} from 'components/Room/CRMContainer/NotesTab/types';
import { FormSections } from 'components/Room/CRMContainer/NotesTab/ProgressNoteForm/context/types';
import apiHelper from '@/utils/api';
import apiWrapper from '../../utils/apiWrapper';
import { progressNoteQueryKey } from './queryKeys';
import { useRouteMatch } from '@/core/routerLib';

export interface ProgressNoteQueryResponse {
  formSections: FormSections | null;
  status: NoteStatus;
  roomID: number;
  createdByUser: User;
  submittedAt: string;
  caseID: number | null;
  sessionReportID: number | null;
  sessionReportPosition: number | null;
  serviceStartDate: string | null;
  serviceEndDate: string | null;
  modalityID: number | null;
  sessionServiceID: number | null;
  treatmentPlanID: number | null;
  presentingProblems: Array<PresentingProblem>;
  referralID: number | null;
  otherReferral: string | null;
  statementCertified: boolean;
  readOnly: boolean;
  videoCalls: Array<ProgressNoteVideoCallResponseAttributes>;
  sessionAttendees: Array<ProgressNoteSessionAttendeeCreationAttributes>;
  diagnoses: Array<ProgressNoteDiagnosisCreationAttributes>;
  riskAssessment: ProgressNoteRiskAssessmentCreationAttributes;
  substanceUse: ProgressNoteSubstanceUseCreationAttributes;
  treatmentPlanProgress: ProgressNoteTreatmentPlanProgressCreationAttributes;
  treatmentPlanGoals: Array<
    Pick<
      ProgressNoteTreatmentPlanGoalAttributes,
      'treatmentPlanGoalID' | 'overallProgress' | 'treatmentPlanGoal'
    >
  >;
  treatmentPlanObjectives: Array<
    Pick<
      ProgressNoteTreatmentPlanObjectiveAttributes,
      'treatmentPlanObjectiveID' | 'overallProgress' | 'treatmentPlanObjective'
    >
  >;
  treatmentPlanInterventions: Array<
    Pick<
      ProgressNoteTreatmentPlanInterventionAttributes,
      'treatmentPlanObjectiveID' | 'treatmentPlanInterventionID' | 'treatmentPlanIntervention'
    >
  >;
  medicalInformation: ProgressNoteMedicalInformationCreationAttributes;
  medicalInformationConditions: Array<ProgressNoteMedicalInformationConditionCreationAttributes>;
  medicalInformationMedications: Array<ProgressNoteMedicalInformationMedicationCreationAttributes>;
  sessionSummary: ProgressNoteSessionSummaryCreationAttributes;
  mentalStatusExam: ProgressNoteMentalStatusExamCreationAttributes;
  psychiatryPlan: ProgressNotePsychiatryPlanCreationAttributes;
  psychiatryPlanMedications: Array<ProgressNotePsychiatryPlanMedicationCreationAttributes>;
  lowEngagement: boolean;
  asyncSessionID?: number;
  liveCallID?: number;
  serviceType?: 'therapy' | 'psychiatry';
}

export interface FetchNoteVariables {
  clientUserID?: number;
  noteID?: string;
  roomID?: string;
}

const fetchNote =
  ({ clientUserID, noteID, roomID }: FetchNoteVariables) =>
  async () => {
    const { data } = await apiWrapper.get(
      `${
        apiHelper().apiEndpoint
      }/v2/rooms/${roomID}/clients/${clientUserID}/progress-notes/${noteID}`
    );
    return data.data;
  };

const useQueryProgressNote = (clientUserID?: number, noteID?: string, enabled: boolean = true) => {
  const match = useRouteMatch<{ roomID: string }>();
  const roomID = match?.params?.roomID;
  return useQuery<ProgressNoteQueryResponse, Error>({
    queryKey: progressNoteQueryKey(clientUserID, noteID, roomID),
    queryFn: fetchNote({ clientUserID, noteID, roomID }),
    cacheTime: Infinity,
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!roomID && !!clientUserID && !!noteID && enabled,
  });
};

export default useQueryProgressNote;
