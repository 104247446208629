import { withVersioning } from '../../utils';
import { VersionMap } from '../../types';
import BannerCard from './BannerCard';
import { BannerCardProps, BannerCardVariant } from './types';

const versionMap: VersionMap<React.ComponentProps<typeof BannerCard>> = {
  DefaultComponent: BannerCard,
};

export type { BannerCardProps, BannerCardVariant };

export default withVersioning(versionMap);
