import Svg, { G, Path, Ellipse } from 'svgs';
import { EmotionStyle } from '../../core/styled';

interface Props {
  height?: number;
  width?: number;
  color?: string;
  dataQa?: string;
  style?: EmotionStyle;
}

const Group = (props: Props) => {
  const titleText = 'group';
  const { width = 25, height = 25, color, dataQa, style, ...rest } = props;
  return (
    <Svg
      width={width}
      height={height}
      title={titleText}
      aria-label={titleText}
      style={style}
      viewBox="0 0 31 23"
      {...rest}
    >
      <G
        transform="translate(-599 -2187) translate(600 1981) translate(0 203) translate(0 4) translate(.041) translate(.625 .457)"
        stroke="#2E7283"
        strokeWidth={2.8}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <Path d="M17.21 19.63v-2.206c0-2.437-2.021-4.413-4.514-4.413H4.798c-2.493 0-4.513 1.976-4.513 4.413v2.206" />
        <Ellipse cx={8.74714491} cy={4.86956522} rx={4.51339102} ry={4.41304348} />
        <Path d="M27.616 17.576v-1.75c0-1.933-1.617-3.5-3.611-3.5h-6.319c-1.542 0-2.231.457-2.231 1.141" />
        <Ellipse cx={20.8455403} cy={5.32608696} rx={3.61071282} ry={3.5} />
      </G>
    </Svg>
  );
};

export default Group;
