import { FunctionComponent } from 'react';
import styled, { EmotionTheme } from '../../core/styled';
import Text, { TextProps } from '../Text';
import type { VariantFunction } from './types';

type Variant = 'huge' | 'hugeLightGrey';

interface HugeProps extends TextProps {
  variant?: Variant;
}

const hugeDefaultStyle = (colors: EmotionTheme['colors']) => {
  return {
    fontWeight: 700,
    fontSize: 22,
    lineHeight: '29px',
    letterSpacing: 0.3,
    color: colors.black,
  };
};

const variants: Record<string, VariantFunction> = {
  default: ({ theme: { colors } }) => {
    return {
      ...hugeDefaultStyle(colors),
    };
  },
  lightGrey: ({ theme: { colors } }) => {
    return {
      ...hugeDefaultStyle(colors),
      color: colors.baliHaiGrey,
    };
  },
};

const HugeDefault = styled(Text)(variants.default);
const HugeLightGrey = styled(Text)(variants.lightGrey);

const selectStyle = (variant: Variant | undefined, props: Omit<HugeProps, 'variant'>) => {
  switch (variant) {
    case 'huge':
      return <HugeDefault {...props} />;
    case 'hugeLightGrey':
      return <HugeLightGrey {...props} />;
    default:
      return <HugeDefault {...props} />;
  }
};

const Huge: FunctionComponent<HugeProps> = ({ variant, ...props }) => selectStyle(variant, props);

export default Huge;
