import { FunctionComponent } from 'react';
import Svg, { Defs, Circle, G, Mask, Use, Path } from 'svgs';
import { useEmotionTheme, EmotionStyle } from '../../core/styled';

interface CouplesDefaultAvatarProps {
  color?: string;
  size?: number;
  style?: EmotionStyle;
}

const CouplesDefaultAvatar: FunctionComponent<CouplesDefaultAvatarProps> = ({
  color,
  size = 85,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'couples avatar';
  return (
    <Svg
      width={size}
      height={size}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 85 85"
      {...otherProps}
    >
      <Defs>
        <Circle id="a" cx="42.5" cy="42.5" r="42.5" />
      </Defs>
      <G stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <G>
          <Mask id="0" fill={colors.white}>
            <Use href="#a" />
          </Mask>
          <Use fill="#DEE9ED" href="#a" />
        </G>
        <G fill={color || colors.gossamerGreen} stroke="#DEE9ED" strokeWidth={3}>
          <Path
            d="M40.485 30.439h-25.48c-1.372 0-2.385-1.445-1.868-2.774C15.537 21.52 21.176 18 27.744 18c6.57 0 12.208 3.52 14.608 9.665.518 1.329-.495 2.774-1.867 2.774M19 8.659c0-4.688 3.814-8.5 8.5-8.5 4.688 0 8.5 3.812 8.5 8.5 0 4.687-3.812 8.5-8.5 8.5-4.686 0-8.5-3.813-8.5-8.5"
            transform="translate(22.756 26.841)"
          />
          <Path
            d="M2.004 30.439c-1.371 0-2.384-1.445-1.867-2.774C2.537 21.52 8.176 18 14.744 18c6.57 0 12.208 3.52 14.608 9.665.518 1.329-.495 2.774-1.867 2.774H2.005zM6 8.659c0-4.688 3.814-8.5 8.5-8.5 4.688 0 8.5 3.812 8.5 8.5 0 4.687-3.812 8.5-8.5 8.5-4.686 0-8.5-3.813-8.5-8.5"
            transform="translate(22.756 26.841)"
          />
        </G>
      </G>
    </Svg>
  );
};

export default CouplesDefaultAvatar;
