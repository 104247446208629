import { FunctionComponent, useRef, useState } from 'react';
import * as React from 'react';
import ReactTooltip from 'react-tooltip';
import View from '../View';
import CircledQuestionMark from '../Svgs/CircledQuestionMark';
import styled, { EmotionStyle, useEmotionTheme } from '../../core/styled';
import { useUniqueID } from '../../hooks/a11yHelper';
import commonStyles from '../../constants/commonStyles';

const { crossBrowserFocusRing, roundedFocusRing } = commonStyles;

interface CustomTooltipProps {
  hide?: boolean;
  tip?: string;
  messageComponent?: JSX.Element;
  buttonStyle?: EmotionStyle;
  messageStyle?: EmotionStyle;
  questionMarkSize?: number;
  place?: 'top' | 'bottom' | 'left' | 'right';
  className?: string;
  ariaDescribedBy?: string;
  label?: string;
  dataQa?: string;
  roundedFocusStyle?: boolean;
  arrowColor?: string;
  textColor?: string;
  backgroundColor?: string;
  border?: boolean;
  borderColor?: string;
  effect?: 'float' | 'solid';
  arrowPosition?: 'left' | 'center' | 'right';
  overridePosition?: () => { top: number; left: number };
}

const StyledTooltip = styled(ReactTooltip)<Partial<CustomTooltipProps>>(
  ({ messageStyle, arrowPosition }) => {
    let arrowPositionHack = {};
    if (arrowPosition === 'right') {
      arrowPositionHack = { '&::after': { left: '80% !important' } };
    } else if (arrowPosition === 'left') {
      arrowPositionHack = { '&::after': { left: '20% !important' } };
    }
    return {
      maxWidth: 275,
      textAlign: 'left',
      borderRadius: 8,
      lineHeight: '19px',
      fontSize: 13,
      fontFamily: 'Roboto',
      padding: 12,
      opacity: 0.85,
      ...arrowPositionHack,
      ...messageStyle,
    };
  }
);

export type TooltipProps = CustomTooltipProps &
  Omit<React.ComponentProps<'button'>, 'type' | 'ref'>;

const TooltipToggle = styled.div<{
  primaryColor?: string;
  roundedFocusStyle?: boolean;
  shouldShowFocusOutline?: boolean;
}>(({ primaryColor, roundedFocusStyle, shouldShowFocusOutline }) => {
  // TODO: this if statement can be removed once all the tooltips within client-web have been given the green light to show the new rounded focus styling
  // display: 'flex' should stay
  if (shouldShowFocusOutline) {
    let keyboardFocusStyles = {};
    if (primaryColor && roundedFocusStyle) {
      keyboardFocusStyles = roundedFocusRing(primaryColor, shouldShowFocusOutline);
    } else {
      keyboardFocusStyles = crossBrowserFocusRing;
    }
    return {
      ...keyboardFocusStyles,
      // ensures that the circled question mark is centered in the focus styling
      display: 'flex',
    };
  }
  return {
    outline: 'none',
  };
});

const Tooltip: FunctionComponent<TooltipProps> = ({
  tip,
  messageComponent,
  buttonStyle,
  questionMarkSize,
  place = 'bottom',
  className,
  label,
  ariaDescribedBy,
  dataQa,
  roundedFocusStyle,
  children,
  arrowColor,
  arrowPosition,
  hide,
  ...otherProps
}) => {
  const [isClicked, setIsClicked] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const tooltipID = useUniqueID('tooltipID');
  const tooltipRef = useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (hide) {
      ReactTooltip.hide(tooltipRef.current || undefined);
    }
  }, [hide]);

  const onClick = (e) => {
    // necessary to prevent form from submitting when the tooltip is located inside of a form
    e.preventDefault();
    if ((e.clientX && e.clientY) || (e.pageX && e.pageY)) {
      setIsClicked(true);
    }
  };

  // provides a way for keyboard users to close the tooltip
  const onKeyDown = (e) => {
    if (e.key === 'Escape') {
      e.preventDefault();
      ReactTooltip.hide(tooltipRef.current || undefined);
    }
  };

  const onFocus = () => {
    setIsFocused(true);
    const tooltip = tooltipRef.current || undefined;
    if (tooltip && !hide) {
      ReactTooltip.show(tooltip);
    }
  };

  const onBlur = () => {
    ReactTooltip.hide(tooltipRef.current || undefined);
    setIsFocused(false);
    setIsClicked(false);
  };

  const { colors } = useEmotionTheme();
  return (
    <>
      <TooltipToggle
        data-qa={dataQa}
        shouldShowFocusOutline={isFocused && !isClicked}
        aria-label={label || 'Tooltip'}
        aria-describedby={ariaDescribedBy || tooltipID}
        className={className}
        tabIndex={0}
        style={{
          width: questionMarkSize,
          height: questionMarkSize,
          justifyContent: 'center',
          ...buttonStyle,
        }}
        ref={tooltipRef}
        onClick={onClick}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        data-tip
        data-for={tooltipID}
        // renders new rounded focus style if roundedFocusStyleProp provided
        // assumes that tooltip color is permaWildBlueYonder
        primaryColor={colors.permaWildBlueYonder}
        roundedFocusStyle={roundedFocusStyle}
      >
        {children || (
          <CircledQuestionMark
            aria-hidden="true"
            width={questionMarkSize}
            height={questionMarkSize}
          />
        )}
      </TooltipToggle>
      <StyledTooltip
        id={tooltipID}
        place={place}
        multiline
        {...otherProps}
        arrowColor={arrowColor}
        arrowPosition={arrowPosition}
      >
        {tip && <View role="tooltip">{tip}</View>}
        {messageComponent && <View role="tooltip">{messageComponent}</View>}
      </StyledTooltip>
    </>
  );
};

export default Tooltip;
