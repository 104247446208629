import { FunctionComponent } from 'react';
import { AlertIcon, EmotionStyle, styled, View } from '@talkspace/react-toolkit';
import { RouteComponentProps, withRouter } from 'react-router';

const StyledAlertContainer = styled(View)(({ theme: { colors } }) => {
  return {
    borderRadius: 5,
    padding: '22px 20px',
    width: 355,
    border: `1px solid ${colors.pink}`,
  };
});

const StyledAlertContainerTitleHeader = styled(View)({
  fontSize: 15,
  fontWeight: 'bold',
});

const StyledAlertContainerBody = styled(View)(({ theme: { colors } }) => {
  return { marginTop: 10, fontSize: 14, color: colors.grey950 };
});

const StyledAlertContainerChildComponents = styled(View)({
  marginTop: 25,
  flexDirection: 'row-reverse',
});

interface AlertContainerProps extends RouteComponentProps {
  title: string;
  body: string;
  childComponents: JSX.Element[];
  containerStyle?: EmotionStyle;
  childrenContainerStyle?: EmotionStyle;
}

const AlertContainer: FunctionComponent<AlertContainerProps> = ({
  title,
  body,
  childComponents,
  children,
  containerStyle = {},
  childrenContainerStyle,
}) => {
  const childs = childComponents || children;
  return (
    <StyledAlertContainer style={containerStyle}>
      <StyledAlertContainerTitleHeader row>
        <AlertIcon style={{ marginRight: 9 }} />
        {title}
      </StyledAlertContainerTitleHeader>
      <StyledAlertContainerBody>{body}</StyledAlertContainerBody>
      <StyledAlertContainerChildComponents style={childrenContainerStyle}>
        {childs.map((childComponent, id) => (
          // eslint-disable-next-line react/no-array-index-key
          <View key={id}>{childComponent}</View>
        ))}
      </StyledAlertContainerChildComponents>
    </StyledAlertContainer>
  );
};

export default withRouter(AlertContainer);
