import { VoidFunctionComponent } from 'react';

import { Mini, styled, Tiny, useEmotionTheme, View } from '@talkspace/react-toolkit';
import LightBulb from '../icons/LightBulb';

const RemainingBalancesView = styled(View)({
  width: 315,
  padding: '14px 10px',
  marginTop: 10,
  borderRadius: 12,
  boxShadow: '0px 3.40299px 11.9104px -3.40299px rgba(52, 43, 80, 0.15);',
});

const RemainingBalancesInfo: VoidFunctionComponent = () => {
  const { colors } = useEmotionTheme();
  return (
    <RemainingBalancesView row flex={1} justify="space-between" align="start">
      <LightBulb />
      <View style={{ width: 252 }}>
        <Tiny style={{ fontWeight: 500, color: colors.permaBlueStoneNew }}>Remaining balances</Tiny>
        <Mini style={{ marginTop: 4 }}>
          This is the amount you are responsible for after both your copay and your insurance's
          portion. Keep in mind that it's common for this amount to change over time.
        </Mini>
      </View>
    </RemainingBalancesView>
  );
};

export default RemainingBalancesInfo;
