import { Small, TouchableView, styled } from '@talkspace/react-toolkit';
import { Ref } from 'react';

const Label = styled(Small)(({ theme: { colors } }) => {
  return {
    fontSize: 12,
    fontWeight: 500,
    color: colors.aqua,
    lineHeight: 1,
  };
});

const Container = styled(TouchableView)<{ isSelected?: boolean }>(
  ({ isSelected, theme: { colors } }) => {
    return {
      paddingLeft: 5,
      borderRadius: 5,
      height: '100%',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      paddingTop: 2,
      border: `1px solid ${colors.aquaMarine}`,
      backgroundColor: colors.white,
      position: 'relative',
      boxShadow: isSelected && '0px 4px 13px rgba(1, 0, 51, 0.36)',
    };
  }
);

interface AvailabilityBlockProps {
  isRecurring: Boolean;
  minutes: number;
  isSelected: boolean;
  containerRef: Ref<any>;
}

const AvailabilityBlock = ({
  isRecurring,
  minutes,
  isSelected,
  containerRef,
}: AvailabilityBlockProps) => {
  const recurringText = isRecurring ? 'Recurring' : 'Non-recurring';

  return (
    <Container isSelected={isSelected} ref={isSelected ? containerRef : null}>
      {minutes === 30 || minutes === 15 ? (
        <Label style={{ fontSize: minutes === 15 && 10 }}>Open - {recurringText}</Label>
      ) : (
        <>
          <Label>Open</Label>
          <Label>{recurringText}</Label>
        </>
      )}
    </Container>
  );
};

export default AvailabilityBlock;
