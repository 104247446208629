import Svg, { Path } from 'svgs';
import * as React from 'react';

import { useEmotionTheme, EmotionStyle } from '../../core/styled';

interface Props {
  width?: number;
  height?: number;
  color?: string;
  style?: EmotionStyle;
}

const MessagingCalendar: React.VFC<Props> = ({ color, width = 28, height = 30, ...otherProps }) => {
  const { colors } = useEmotionTheme();
  const titleText = 'messaging calendar';
  return (
    <Svg width={width} height={height} title={titleText} aria-label={titleText} {...otherProps}>
      <Path
        d="M4.013 1.98A1.98 1.98 0 015.993 0h.054a1.98 1.98 0 011.98 1.98v2.058h11.652V1.98A1.98 1.98 0 0121.659 0h.053a1.98 1.98 0 011.98 1.98v2.097c0 .047-.002.093-.005.14a5.402 5.402 0 014.018 5.22v15.056a5.4 5.4 0 01-5.4 5.4H5.4a5.4 5.4 0 01-5.4-5.4V9.438a5.402 5.402 0 014.018-5.222 2.007 2.007 0 01-.005-.14V1.98z"
        fill={color || colors.purple}
      />
      <Path
        d="M4.25 12.822A3.072 3.072 0 017.322 9.75h12.856a3.072 3.072 0 013.072 3.072v8.856a3.072 3.072 0 01-3.072 3.072H7.322a3.072 3.072 0 01-3.072-3.072v-8.856z"
        fill={colors.white}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.25 13.223a.8.8 0 011.13-.059l5.477 4.939 5.251-4.928a.8.8 0 111.095 1.167l-6.324 5.935-6.57-5.924a.8.8 0 01-.059-1.13z"
        fill={color || colors.purple}
      />
    </Svg>
  );
};

export default MessagingCalendar;
