import { VoidFunctionComponent } from 'react';
import { PanelManager } from '@talkspace/react-toolkit';
import TherapistWebPanelManager from 'components/TherapistWebPanelManager';

const Dashboard: VoidFunctionComponent = () => (
  <PanelManager>
    <TherapistWebPanelManager />
  </PanelManager>
);

export default Dashboard;
