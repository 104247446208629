import { FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import useQueryBusinessLine from 'ts-frontend/hooks/useQueryBusinessLine';
import useQueryRoomDetails from 'ts-frontend/hooks/useQueryRoomDetails';
import useMutationSaveDraftProgressNote from 'hooks/notes/useMutationSaveDraftProgressNote';
import useQueryNotes, { NoteSessionReportData } from 'hooks/notes/useQueryNotes';
import * as treatmentPlannerActions from 'actions/TreatmentPlannerActions';
import useLocationStateOnMount from 'hooks/useLocationStateOnMount';
import { useHistory, useParams } from '@/core/routerLib';
import getParamByName from '@/utils/queryString';
import { Modality, getSessionReportPosition, getSubmenuTitleAndSubtitle } from './utils';

import { useProgressNoteFormState } from './ProgressNoteForm/context/TherapistProgressNoteFormContext';
import NoteDialog from './NoteDialog';

export type SaveProgressNoteDialogActionType =
  | 'navigate-to-outcome-measure-answers'
  | 'navigate-to-treatment-planner'
  | 'navigate-to-notes-tab'
  | 'navigate-to-scheduling';

export interface SaveProgressNoteDialogAction {
  type: SaveProgressNoteDialogActionType;
  payload?: unknown;
}

export interface SaveProgressNoteDialogLocationState {
  action: SaveProgressNoteDialogAction;
  eapSessionReport?: NoteSessionReportData | null;
}

interface Props {
  createNewTreatmentPlan(presentingProblems: Array<any>): Promise<void>;
}

const SaveProgressNoteDialog: FunctionComponent<Props> = ({ createNewTreatmentPlan }) => {
  const postAction = getParamByName('postAction');
  const history = useHistory();
  const { roomID } = useParams<{ roomID: string }>();
  const locationStateOnMount = useLocationStateOnMount<SaveProgressNoteDialogLocationState>();
  const formState = useProgressNoteFormState();

  const { mutate: saveDraftProgressNote, isLoading: isSaveDraftLoading } =
    useMutationSaveDraftProgressNote();

  const { data: { clientUserID } = {} } = useQueryRoomDetails(roomID);
  const { data: businessLineData } = useQueryBusinessLine(roomID, clientUserID);
  const { data: notesData } = useQueryNotes(roomID);

  useEffect(() => {
    if (
      (locationStateOnMount !== undefined && !locationStateOnMount?.action) ||
      history.action === 'POP'
    ) {
      history.push(`/room/${roomID}/notes-tab`);
    }
  }, [history, locationStateOnMount, roomID]);

  const navigateToTreatmentPlanner = () => {
    if (locationStateOnMount?.action.payload) {
      history.push(
        `/room/${roomID}/treatment-planner-goals${postAction ? `?postAction=${postAction}` : ''} `
      );
    } else {
      createNewTreatmentPlan([]).then(() => {
        history.push(
          `/room/${roomID}/treatment-planner-goals${postAction ? `?postAction=${postAction}` : ''}`
        );
      });
    }
  };

  const navigateToOutcomeMeasureAnswers = () => {
    history.push(`/room/${roomID}/outcome-measure-answers`, {
      outcomeMeasure: { id: locationStateOnMount?.action.payload },
      prevRoute: history.location.pathname,
    });
  };

  const navigateToNotesTab = () => {
    history.push(`/room/${roomID}/notes-tab`);
  };

  const navigateToRoomScheduling = () => {
    history.push(`/room/${roomID}/in-room-scheduling`, {
      backButtonRoute: `/room/${roomID}/notes-tab`,
    });
  };

  const navigateToCreateProgressNote = () => {
    history.push(`/room/${roomID}/progress-notes/new`);
  };

  const navigateToTheTargetPage = ({ buttonPressType }: { buttonPressType?: string } = {}) => {
    switch (locationStateOnMount?.action.type) {
      case 'navigate-to-outcome-measure-answers':
        navigateToOutcomeMeasureAnswers();
        break;
      case 'navigate-to-treatment-planner':
        navigateToTreatmentPlanner();
        break;
      case 'navigate-to-notes-tab':
        navigateToNotesTab();
        break;
      case 'navigate-to-scheduling': {
        if (buttonPressType === 'no') {
          navigateToCreateProgressNote();
        } else {
          const closeVideoTrayEvent = new CustomEvent('closeVideoTray');
          document.dispatchEvent(closeVideoTrayEvent);
          navigateToRoomScheduling();
        }
        break;
      }
      default:
        break;
    }
  };

  const handleNoPress = () => {
    history.replace(`/room/${roomID}/notes-tab`);

    navigateToTheTargetPage({ buttonPressType: 'no' });
  };

  const handleYesPress = () => {
    const { showGlobalError, formMode, draftProgressNoteID, ...restFormState } = formState;
    const videoCalls = restFormState.modalityID === Modality.TEXT ? [] : restFormState.videoCalls;
    const params = {
      roomID,
      clientUserID,
      noteID: draftProgressNoteID?.toString(),
      isTherapist: true,
      ...restFormState,
      videoCalls,
    };

    saveDraftProgressNote(params, {
      onSuccess: (data) => {
        if (
          locationStateOnMount?.action.type === 'navigate-to-outcome-measure-answers' ||
          locationStateOnMount?.action.type === 'navigate-to-treatment-planner'
        ) {
          history.replace(`/room/${roomID}/progress-notes/${data.noteID}/edit`);
        }
        navigateToTheTargetPage();
      },
    });
  };

  const sessionReportPosition = getSessionReportPosition({
    formMode: 'create',
    businessLineData,
    notesData,
    eapSessionReport: locationStateOnMount?.eapSessionReport,
  });

  const { title, subtitle } = getSubmenuTitleAndSubtitle({
    noteType: 'progress',
    formMode: 'create',
    businessLine: businessLineData,
    sessionReportPosition,
    serviceStartDate: formState?.serviceStartDate ? new Date(formState.serviceStartDate) : null,
    serviceEndDate: formState?.serviceEndDate ? new Date(formState.serviceEndDate) : null,
    isTherapist: true,
  });

  return (
    <NoteDialog
      dialogType="save-draft"
      title={title}
      subtitle={subtitle}
      isLoading={isSaveDraftLoading}
      noButtonFirst
      onNoPress={handleNoPress}
      onYesPress={handleYesPress}
    />
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    createNewTreatmentPlan: (presentingProblems: Array<any>) =>
      dispatch(treatmentPlannerActions.createNewTreatmentPlan(presentingProblems)),
  };
};

export default connect(null, mapDispatchToProps)(SaveProgressNoteDialog);
