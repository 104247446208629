import React, { ComponentType, ForwardRefExoticComponent, forwardRef, RefObject } from 'react';

type ComponentTypeOrForwardRef<P> = ComponentType<P> | ForwardRefExoticComponent<P>;

const withContextProvider =
  <ContextP extends Record<string, unknown | undefined> = Record<string, never>>(
    Context: ComponentType<ContextP>
  ) =>
  // Using Record<string, unknown> makes required props required when invoking this HOC. Makes no sense
  // eslint-disable-next-line @typescript-eslint/ban-types
  <P extends object = Record<string, never>>(Component: ComponentTypeOrForwardRef<P>) => {
    type Props = P & { componentProps?: ContextP extends Record<string, never> ? never : ContextP };
    const WithContextProvider = (
      { componentProps, ...props }: Props,
      ref: RefObject<HTMLElement>
    ) => (
      <Context {...(componentProps as ContextP)}>
        {React.createElement(Component, { ...props, ref } as P)}
      </Context>
    );
    return forwardRef(WithContextProvider);
  };

export default withContextProvider;
