import { FunctionComponent, useEffect, useState } from 'react';
import {
  CirclePencilIcon,
  Spinner,
  View,
  Standard,
  TouchableView,
  useEmotionTheme,
  ArrowRight,
  BoxArrowIcon,
} from '@talkspace/react-toolkit';
import { useQueryClient } from 'react-query';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { TextLink } from '../ProgressNoteForm/componentsV2/styles';
import styled from '@/core/styled';
import CTACard from './CTACard';
import useQueryUnsubmittedSessions, {
  GetUnsubmittedSessionsResponse,
} from '../../../../../hooks/notes/useQueryUnsubmittedSessions';
import { unsubmittedSessionsQueryKey } from '../../../../../hooks/notes/queryKeys';
import { useHistory, useRouteMatch } from '@/core/routerLib';
import EmptyErrorState from './EmptyErrorState';
import useUnsubmittedSessionsSocket from '../hooks/useUnsubmittedSessionsSocket';
import { SUPPORT_ARTICLE_LINK, MAX_UNSUBMITTED_SESSIONS } from '../utils';
import trackEvent from '../../../../../modules/analytics/trackEvent';
import ssoHelper from '../../../../../modules/utils/sso';
import useDidUpdateEffect from '../../../../../hooks/useDidUpdateEffect';

const CTAWrapper = styled(View)<{ shouldNotDisplayBorder?: boolean }>(
  ({ shouldNotDisplayBorder = false, theme: { colors } }) => {
    return {
      width: 345,
      backgroundColor: colors.permaPhantomWhite,
      border: `1px solid ${colors.paleSilver}`,
      borderRadius: 7,
      marginBottom: 20,
      borderBottom: shouldNotDisplayBorder && 'none',
    };
  }
);

const Item = styled(View)<{ isRemoved: boolean; lastItem: boolean; height: number }>(
  ({ lastItem, isRemoved, height, theme: { colors } }) => {
    return {
      height: isRemoved ? 0 : height,
      transition: 'height 0.5s ease-out',
      borderBottom: !lastItem && `1px solid ${colors.paleSilver}`,
      position: 'relative',
      overflow: isRemoved ? 'hidden' : 'visible',
    };
  }
);

const Text = styled(Standard)(({ theme: { colors } }) => {
  return {
    fontSize: 14,
    fontWeight: 400,
    color: colors.purple600,
  };
});

const ViewAllButton = styled(TouchableView)(({ theme: { colors } }) => {
  return {
    fontSize: 15,
    fontWeight: 700,
    color: colors.accessibilityGreenDark,
    textAlign: 'center',
    marginTop: -25,
    marginBottom: 21,
    height: 48,
    lineHeight: '48px',
    borderRadius: '0 0 15px 15px',
    border: `1px solid ${colors.paleSilver}`,
    backgroundColor: colors.permaPhantomWhite,
    width: 345,
    justifyContent: 'center',
  };
});

const LearnMore = () => {
  const {
    params: { roomID },
  } = useRouteMatch<{ roomID: string }>();
  return (
    <View style={{ textAlign: 'center', marginBottom: 40 }}>
      <Text>Have questions about progress notes?</Text>
      <View row justify="center" align="center">
        <BoxArrowIcon />
        <TextLink
          onClick={() => {
            trackEvent(
              'clickLearnMore',
              {
                actionName: 'progressNotesInteraction',
              },
              {
                action: 'provider clicks learn more link',
                roomID,
              }
            );
            ssoHelper.openZendesk(SUPPORT_ARTICLE_LINK);
          }}
          style={{ textDecoration: 'none', marginLeft: 5 }}
          text="Documentation FAQs"
          dataQa="progressNotesFAQs"
        />
      </View>
    </View>
  );
};

const CTAContainer: FunctionComponent = () => {
  const {
    params: { roomID },
  } = useRouteMatch<{ roomID: string }>();
  const queryClient = useQueryClient();
  const [previousResponse, setPreviousResponse] = useState<GetUnsubmittedSessionsResponse | null>();
  const [submittedNoteID, setSubmittedNoteID] = useState<string | null>();
  const [shouldDisplayEmptyState, setShouldDisplayEmptyState] = useState<boolean>(false);
  const [displayedList, setDisplayedList] = useState<any | null>();
  const { accessUnsubmittedSessions: { inRoomPollingInterval } = {} } = useFlags();
  const { colors } = useEmotionTheme();
  const {
    data: unsubmittedSessionsData,
    isError,
    isLoading,
    refetch,
  } = useQueryUnsubmittedSessions({ roomID, inRoomPollingInterval });
  const history = useHistory();
  useUnsubmittedSessionsSocket(refetch, roomID);

  useEffect(() => {
    setPreviousResponse(queryClient.getQueryData(unsubmittedSessionsQueryKey(roomID)));
  }, [queryClient, roomID, setPreviousResponse]);

  useDidUpdateEffect(() => {
    if (unsubmittedSessionsData) {
      trackEvent(
        'unsubmittedSessionsAppeared',
        {
          actionName: 'progressNotesInteraction',
        },
        {
          unsubmittedSessions: unsubmittedSessionsData,
          unsubmittedSessionsAmount: unsubmittedSessionsData.unsubmittedSessions.length,
          roomID,
        }
      );
    }
  }, [unsubmittedSessionsData, roomID]);

  useEffect(() => {
    const removedItem = previousResponse?.unsubmittedSessions.find(
      (item) => !unsubmittedSessionsData?.unsubmittedSessions.some((obj) => obj.id === item.id)
    );
    if (removedItem) {
      setSubmittedNoteID(removedItem?.id);
      setDisplayedList(previousResponse?.unsubmittedSessions);
    } else {
      setDisplayedList(unsubmittedSessionsData?.unsubmittedSessions);
    }
  }, [previousResponse, unsubmittedSessionsData?.unsubmittedSessions]);

  if (isLoading) {
    return (
      <CTAWrapper style={{ height: 210 }}>
        <Spinner />
      </CTAWrapper>
    );
  }

  if (isError) {
    return (
      <>
        <EmptyErrorState
          title="Oops, something went wrong"
          text="Please check your internet connection and try again later"
        />
        <LearnMore />
      </>
    );
  }

  const handleTransitionEnd = () => {
    if (unsubmittedSessionsData?.unsubmittedSessions.length === 0) {
      setShouldDisplayEmptyState(true);
    }
  };

  const maxDisplayedNotes = submittedNoteID
    ? MAX_UNSUBMITTED_SESSIONS + 1
    : MAX_UNSUBMITTED_SESSIONS;

  const inNotesTab = history.location.pathname.includes('/notes-tab');
  const showItems = inNotesTab ? displayedList?.slice(0, maxDisplayedNotes) : displayedList;
  const showLearnMore = !inNotesTab || displayedList?.length <= maxDisplayedNotes;

  return (
    <>
      {displayedList && displayedList.length && !shouldDisplayEmptyState ? (
        <>
          <CTAWrapper
            shouldNotDisplayBorder={!inNotesTab && displayedList.length < maxDisplayedNotes}
          >
            {showItems.map((item, index) => (
              <Item
                height={item.submissionStatus ? 100 : 68}
                onTransitionEnd={handleTransitionEnd}
                lastItem={index === showItems.length - 1}
                key={item.id}
                isRemoved={submittedNoteID === item.id}
              >
                <CTACard unsubmittedSessionItem={item} />
              </Item>
            ))}
          </CTAWrapper>
          {showLearnMore && <LearnMore />}
          {inNotesTab && displayedList.length > maxDisplayedNotes && (
            <ViewAllButton
              row
              onPress={() => history.push(`/room/${roomID}/all-unsubmitted-sessions`)}
            >
              <View style={{ marginRight: 8 }}>View all</View>
              <ArrowRight
                height={10}
                style={{ marginTop: 20 }}
                color={colors.accessibilityGreenDark}
              />
            </ViewAllButton>
          )}
        </>
      ) : (
        <>
          <EmptyErrorState
            icon={<CirclePencilIcon style={{ marginBottom: 10 }} />}
            title="You're caught up on documentation!"
            text="This is where you'll see sessions that need a progress note."
          />
          <LearnMore />
        </>
      )}
    </>
  );
};

export default CTAContainer;
