import Performance from './screens/Performance';
import { PerformanceProvider } from './hooks/performanceContext';
import MainMetricsContainer from './containers/MainMetricsContainer';

export const DashboardMetrics = () => (
  <PerformanceProvider>
    <MainMetricsContainer />
  </PerformanceProvider>
);

export default Performance;
