import Svg, { Path, Defs, G, Rect } from 'svgs';
import { useEmotionTheme } from '../../core/styled';
import type { EmotionStyle } from '../../core/styled';
import { useUniqueID } from '../../hooks/a11yHelper';

interface Props {
  width?: number;
  height?: number;
  color?: string;
  style?: EmotionStyle;
}

const CalendarOutlineIcon = ({ color, width = 24, height = 28, ...otherProps }: Props) => {
  const { colors } = useEmotionTheme();
  const title = 'Calendar outline icon';
  const clipID = useUniqueID('clipID');
  return (
    <Svg
      width={width}
      height={height}
      title={title}
      aria-label={title}
      viewBox="0 0 24 24"
      fill="none"
      {...otherProps}
    >
      <G clipPath={`url(#${clipID})`}>
        <Path
          d="M7.71429 1C8.30804 1 8.78571 1.45977 8.78571 2.03125V3.75H15.2143V2.03125C15.2143 1.45977 15.692 1 16.2857 1C16.8795 1 17.3571 1.45977 17.3571 2.03125V3.75H19.1429C20.7188 3.75 22 4.9832 22 6.5V7.1875V9.25V20.25C22 21.7668 20.7188 23 19.1429 23H4.85714C3.28125 23 2 21.7668 2 20.25V9.25V7.1875V6.5C2 4.9832 3.28125 3.75 4.85714 3.75H6.64286V2.03125C6.64286 1.45977 7.12054 1 7.71429 1ZM19.8571 9.25H4.14286V20.25C4.14286 20.6281 4.46429 20.9375 4.85714 20.9375H19.1429C19.5357 20.9375 19.8571 20.6281 19.8571 20.25V9.25Z"
          fill={color || colors.permaFiord}
        />
      </G>
      <Defs>
        <clipPath id={clipID}>
          <Rect width="24" height="24" fill="white" />
        </clipPath>
      </Defs>
    </Svg>
  );
};

export default CalendarOutlineIcon;
