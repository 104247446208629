import { Keyboard, KeyboardInfo, KeyboardResize } from '@capacitor/keyboard';
import { useState } from 'react';
import { useIonicEffect } from '../../hooks/genericHooks';
import { safeIonicWrapper } from '../../ionicUtils';

export const addKeyboardListener = safeIonicWrapper(
  (onShow: (info: KeyboardInfo) => void, onHide: () => void) => {
    const keyboardShowListener = Keyboard.addListener('keyboardWillShow', onShow);
    const keyboardHideListener = Keyboard.addListener('keyboardWillHide', onHide);

    return () => {
      keyboardShowListener.remove();
      keyboardHideListener.remove();
    };
  },
  () => {}
);

export const useKeyboardStatus = safeIonicWrapper(
  () => {
    const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);
    const [keyboardHeight, setKeyboardHeight] = useState(0);

    useIonicEffect(() => {
      const keyboardShowListener = Keyboard.addListener('keyboardWillShow', (info) => {
        setIsKeyboardOpen(true);
        setKeyboardHeight(info.keyboardHeight);
      });
      const keyboardHideListener = Keyboard.addListener('keyboardWillHide', () => {
        setIsKeyboardOpen(false);
        setKeyboardHeight(0);
      });

      return () => {
        keyboardShowListener.remove();
        keyboardHideListener.remove();
      };
    }, []);

    return { isKeyboardOpen, keyboardHeight };
  },
  { isKeyboardOpen: false, keyboardHeight: 0 }
);

export const getKeyboardResizeMode = safeIonicWrapper<typeof Keyboard.getResizeMode>(
  () => Keyboard.getResizeMode(),
  Promise.resolve({ mode: KeyboardResize.Native })
);

export const setKeyboardResizeMode = safeIonicWrapper<typeof Keyboard.setResizeMode>(
  (options) => Keyboard.setResizeMode(options),
  Promise.resolve()
);

export { KeyboardResize };
