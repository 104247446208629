import StickyDrawer from './StickyDrawer';
import StickyDrawerV1 from './StickyDrawerV1';
import { withVersioning } from '../../utils';
import { VersionMap } from '../../types';

const versionMap: VersionMap<React.ComponentProps<typeof StickyDrawer>> = {
  '1.0.0': StickyDrawerV1,
  DefaultComponent: StickyDrawer,
};

export { StickyDrawerParentContainer, ContextProvider, StateContext } from './StickyDrawer';
export default withVersioning(versionMap);
