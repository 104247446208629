import { useEffect, useRef } from 'react';

// programmatically focuses the current survey's results container
export const useA11y = (pos: number) => {
  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    containerRef?.current?.focus();
  }, [pos]);

  return { containerRef };
};

export default useA11y;
