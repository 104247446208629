import { ChangeEvent, useState } from 'react';

import { DoseSpotFormValues, DoseSpotFormErrors } from './types';
import { validatePatientForm } from './validators';

const usePatientForm = (initialState: DoseSpotFormValues) => {
  const [formValues, setFormValues] = useState<DoseSpotFormValues>(initialState);
  const [formErrors, setFormErrors] = useState<DoseSpotFormErrors>({});

  const handleTextInputChange =
    (fieldName: keyof DoseSpotFormValues) => (event: ChangeEvent<HTMLInputElement>) => {
      setFormValues({ ...formValues, [fieldName]: event.target.value });
    };

  const handleSelectChange = (fieldName: keyof DoseSpotFormValues) => (value) => {
    setFormValues({
      ...formValues,
      [fieldName]: value && value.label ? value.label : null,
    });
  };

  const validateForm = () => {
    const validationErrors = validatePatientForm(formValues);
    setFormErrors(validationErrors);

    return Object.keys(validationErrors).length === 0;
  };

  return {
    formValues,
    formErrors,
    handleTextInputChange,
    handleSelectChange,
    validateForm,
    setFormValues,
  };
};

export default usePatientForm;
