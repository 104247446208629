import Svg, { G, Circle, Line, Path, Rect } from 'svgs';

const SparklingCalendarV2 = () => {
  const titleText = 'Sparkling Calendar';
  return (
    <Svg title={titleText} width="153px" height="130px" viewBox="0 0 153 130">
      <G stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <G transform="translate(-111.000000, -171.000000)">
          <G transform="translate(114.000000, 171.000000)">
            <G>
              <Circle fill="#EFF3FA" cx="75" cy="65" r="65" />
              <G
                transform="translate(28.000000, 87.000000) rotate(65.000000) translate(-28.000000, -87.000000) translate(20.000000, 79.000000)"
                fill="#C0E3E0"
              >
                <G transform="translate(5.177670, 0.177670)">
                  <Rect id="Rectangle-15" x="2.84217094e-13" y="0" width="5" height="15" rx="2.5" />
                </G>
                <G transform="translate(7.677670, 7.677670) rotate(90.000000) translate(-7.677670, -7.677670) translate(5.177670, 0.177670)">
                  <Rect x="2.84217094e-13" y="0" width="5" height="15" rx="2.5" />
                </G>
              </G>
              <Circle stroke="#ADE953" strokeWidth="5" cx="142.5" cy="23.5" r="4.5" />
              <Circle stroke="#BCCCFF" strokeWidth="6" cx="8" cy="42" r="8" />
            </G>
            <G transform="translate(41.625597, 34.125597)">
              <Path
                d="M41.6734741,49.2705378 C45.8891779,49.1609627 49.2726077,45.7090703 49.2717856,41.4669828 L49.2697043,11.2825075 C49.2688687,6.97149491 45.7739743,3.47684151 41.4632866,3.47630324 L11.2820032,3.47630324 C6.97103415,3.47630324 3.47630324,6.97103415 3.47630324,11.2820032 L3.47630324,41.4668033 C3.47630324,45.7777724 6.97103415,49.2725033 11.2820032,49.2725033 L43.3421262,49.2725033 L43.9351739,51.0515847 C43.7798997,50.5902533 43.7798997,50.5902533 43.0282218,49.7934509 C42.1280244,49.3763903 41.9134736,49.2769892 41.694874,49.2653769 Z"
                stroke="#BECBE0"
                strokeWidth="5.20379997"
                fill="#FFFFFF"
                transform="translate(26.374403, 26.374403) rotate(-2.000000) translate(-26.374403, -26.374403) "
              />
              <G transform="translate(41.365931, 36.617027) rotate(2.000000) translate(-41.365931, -36.617027) translate(15.865931, 10.359799)">
                <G>
                  <Path
                    d="M40.7990709,49.9105909 C45.0147746,49.8010159 48.3982045,46.3491235 48.3973824,42.1070359 L48.395301,11.9225606 C48.3944655,7.61154806 44.8995711,4.11689466 40.5888834,4.11635639 L10.4075999,4.11635639 C6.09663089,4.11635639 2.60189998,7.6110873 2.60189998,11.9220563 L2.60189998,42.1068565 C2.60189998,46.4178255 6.09663089,49.9125564 10.4075999,49.9125564 L42.467723,49.9125564 L43.0607706,51.6916378 C42.9054965,51.2303065 42.9054965,51.2303065 42.1538185,50.4335041 C41.2536211,50.0164435 41.0390704,49.9170423 40.8204708,49.9054301 Z"
                    stroke="#5C84FB"
                    strokeWidth="5.20379997"
                    fill="#CFEAFF"
                  />
                  <G transform="translate(12.008473, 23.764604)" fill="#5C84FB">
                    <Rect x="0" y="0" width="6" height="6" rx="1.5" />
                    <Rect x="7" y="0" width="6" height="6" rx="1.5" />
                    <Rect x="14" y="0" width="6" height="6" rx="1.5" />
                    <Rect x="0" y="7" width="6" height="6" rx="1.5" />
                    <Rect x="7" y="7" width="6" height="6" rx="1.5" />
                    <Rect x="14" y="7" width="6" height="6" rx="1.5" />
                    <Rect x="21" y="7" width="6" height="6" rx="1.5" />
                    <Rect x="7" y="14" width="6" height="6" rx="1.5" />
                    <Rect x="14" y="14" width="6" height="6" rx="1.5" />
                    <Rect x="21" y="14" width="6" height="6" rx="1.5" />
                  </G>
                  <Line
                    x1="4.46456134"
                    y1="17.0388009"
                    x2="47.4202086"
                    y2="18.9913303"
                    stroke="#5C84FB"
                    strokeWidth="5.2"
                    strokeLinecap="square"
                    transform="translate(25.942385, 18.015066) rotate(-2.602562) translate(-25.942385, -18.015066) "
                  />
                  <Line
                    x1="12"
                    y1="3.83282576"
                    x2="19.9917483"
                    y2="4.19608705"
                    stroke="#5C84FB"
                    strokeWidth="5.2"
                    strokeLinecap="round"
                    transform="translate(15.995874, 4.014456) rotate(87.397438) translate(-15.995874, -4.014456) "
                  />
                  <Line
                    x1="31"
                    y1="3.83282576"
                    x2="38.9917483"
                    y2="4.19608705"
                    stroke="#5C84FB"
                    strokeWidth="5.2"
                    strokeLinecap="round"
                    transform="translate(34.995874, 4.014456) rotate(87.397438) translate(-34.995874, -4.014456) "
                  />
                </G>
              </G>
            </G>
          </G>
        </G>
      </G>
    </Svg>
  );
};

export default SparklingCalendarV2;
