import { FunctionComponent, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Spinner, View } from '@talkspace/react-toolkit';
import { Route, RouteComponentProps } from '@/core/routerLib/routerLib';

import { OfferCategory, Offer, OfferWeekday } from '../entities/Offers';
import { RoomType } from '../entities/RoomInfo';
import FloatingMenuList from '../components/FloatingMenuList/FloatingMenuList';
import { dispatchRequestOffers, dispatchRequestPostOffer } from '../redux/actions/offersActions';
import { AppState } from '../redux/chatStore';

interface OffersContainerProps {
  roomID: number;
  roomType?: RoomType;
  onUseOffer(): void;
  offerCategories: OfferCategory[];
  isUpdating: boolean;
  postOffer(roomID: number, offerId: number, planId: number): void;
  getOffers(roomID: number): void;
}

const OffersContainer: FunctionComponent<OffersContainerProps> = ({
  roomID,
  postOffer,
  getOffers,
  onUseOffer,
  offerCategories,
  isUpdating,
}) => {
  const [offerCategory, setOfferCategory] = useState<OfferCategory>();
  const [offer, setOffer] = useState<Offer>();

  useEffect(() => {
    getOffers(roomID);
  }, [getOffers, roomID]);

  const selectCategory = (selectedCategory: OfferCategory) => setOfferCategory(selectedCategory);

  const selectOffer = (selectedOffer: Offer, history) => {
    setOffer(selectedOffer);
    if (selectedOffer) {
      if (selectedOffer.selectsWeekday) history.push('/offer-weekdays');
      else {
        postOffer(roomID, selectedOffer.offerID, selectedOffer.planID);
        onUseOffer();
      }
    }
  };

  const selectOfferDay = (selectedWeekday: OfferWeekday) => {
    if (offer) {
      postOffer(roomID, selectedWeekday.offerID, selectedWeekday.planID);
      onUseOffer();
    }
  };

  if (isUpdating && offerCategories.length === 0) {
    return (
      <View justify="center" flex={1}>
        <Spinner isLoading />
      </View>
    );
  }

  return (
    <>
      <Route
        exact
        path="/"
        component={(props: RouteComponentProps) => (
          <FloatingMenuList<OfferCategory>
            {...props}
            containerStyles={{ width: 350, flex: 1 }}
            nextRoute="/offers"
            idKey="categoryID"
            textKey="categoryTitle"
            title="Offer Categories"
            items={offerCategories}
            onPress={selectCategory}
          />
        )}
      />
      <Route
        path="/offers"
        component={(props: RouteComponentProps) => (
          <FloatingMenuList<Offer>
            {...props}
            containerStyles={{ width: 350, flex: 1 }}
            showBackArrow
            title="Offers"
            items={offerCategory ? offerCategory.offers : []}
            idKey="offerID"
            textKey="offerName"
            onPress={selectOffer}
            getKey={({ offerKeyword }) => offerKeyword}
          />
        )}
      />
      <Route
        path="/offer-weekdays"
        component={(props: RouteComponentProps) => (
          <FloatingMenuList<OfferWeekday>
            {...props}
            containerStyles={{ width: 350, flex: 1 }}
            showBackArrow
            title={`${(offer as Offer).offerName} Day`}
            items={(offer as Offer).weekDays}
            idKey="value"
            textKey="label"
            onPress={selectOfferDay}
          />
        )}
      />
    </>
  );
};
const mapStateToProps = (state: AppState) => {
  return {
    isError: state.offers.isError,
    isUpdating: state.offers.isUpdating,
    offerCategories: state.offers.offerCategories,
  };
};
const mapDispatchToProps = {
  getOffers: dispatchRequestOffers,
  postOffer: dispatchRequestPostOffer,
};
const OffersContainerWithRedux = connect(mapStateToProps, mapDispatchToProps)(OffersContainer);

export default OffersContainerWithRedux;
