import parseJwt from '@/utils/jwt';
import apiHelper from '@/core/api/apiHelper';
import apiWrapper from '@/core/api/apiWrapper';
import {
  TherapistProfileData,
  PersonalDetailsData,
  ClinicalInfoData,
  CredentialsData,
  LicenseTypesData,
} from '../types';

type TherapistData = PersonalDetailsData | ClinicalInfoData | CredentialsData;
type Method = 'get' | 'patch';
type Path = 'profile' | 'basic-info' | 'clinical-info' | 'credentials';

function requestTherapist(
  method: Method,
  path: Path,
  token?: string,
  data?: TherapistData
): Promise<any> {
  if (!token) throw new Error('JWT not found');

  const { userID } = parseJwt(token);

  switch (method) {
    case 'get':
      return apiWrapper
        .get(`${apiHelper().apiEndpoint}/v3/therapists/${userID}/${path}`)
        .then((res) => res.data);
    case 'patch':
      return apiWrapper
        .patch(`${apiHelper().apiEndpoint}/v3/therapists/${userID}/${path}`, data)
        .then((res) => res.data);
    default:
      throw new Error('Method is not supported');
  }
}

export function getTherapistProfile(token?: string): Promise<TherapistProfileData> {
  return requestTherapist('get', 'profile', token).then((res) => res.data);
}

export function patchTherapistBasicInfo(data: PersonalDetailsData, token?: string): Promise<{}> {
  return requestTherapist('patch', 'basic-info', token, data);
}

export function patchTherapistClinicalInfo(data: ClinicalInfoData, token?: string): Promise<{}> {
  const fixedData = {
    ...data,
    accessInitiativeParticipation: data.accessInitiativeParticipation === 'true',
  };
  return requestTherapist('patch', 'clinical-info', token, fixedData);
}

export function patchTherapistCredentials(data: CredentialsData, token?: string): Promise<{}> {
  return requestTherapist('patch', 'credentials', token, data);
}

export function getLicenseTypes(): Promise<LicenseTypesData> {
  return apiWrapper
    .get(`${apiHelper().apiEndpoint}/v3/provider-license-types`)
    .then((res) => res.data.data);
}
