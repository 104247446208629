import { useCallback } from 'react';
import moment from 'moment-timezone';
import {
  View,
  ExtraBig,
  Small,
  Spinner,
  ScrollView,
  Button,
  BaseButton,
  Large,
} from '@talkspace/react-toolkit';
import styled from '@/core/styled';
import { useHistory, useRouteMatch, useLocation } from '@/core/routerLib';
import { tsAnalyticsTracker } from '@/utils/analytics/eventTracker';

import { InRoomSchedulingLocation } from '../../types';
import {
  useInRoomSchedulingState,
  useInRoomSchedulingActions,
} from '../../hooks/inRoomSchedulingContext';
import { deleteLastElementInPath } from '../../utils/url';
import BookingSummaryList from '../BookingSummaryList';

const Header = styled(ExtraBig)({ paddingTop: 16 });
const SubHeader = styled(Small)(({ theme: { colors } }) => {
  return {
    marginTop: 8,
    marginBottom: 8,
    color: colors.permaFiord,
  };
});

const BottomButtonContainer = styled(View)({
  width: 335,
  position: 'absolute',
  height: 'auto',
  bottom: 0,
  alignItems: 'center',
  paddingBottom: 20,
  justifyContent: 'left',
  backgroundColor: 'white',
});

const SecondaryButton = styled(BaseButton)({
  marginTop: 8,
  padding: 14,
  width: '100%',
});

interface RecurringBookingConflictsProps {
  setWrapperTitle: (title: string) => void;
}

const RecurringBookingConflicts = ({ setWrapperTitle }: RecurringBookingConflictsProps) => {
  const history = useHistory();
  const location = useLocation<InRoomSchedulingLocation>();
  const match = useRouteMatch<{ roomID: string }>();
  const { url } = match;
  const { roomID: stateRoomID } = location.state || {};
  const roomID = stateRoomID || match.params.roomID;

  const {
    recurringConflictingTimeslots,
    recurringRequestedTimeslots,
    recurringAvailableTimeslots,
    repeatingPeriod,
    repeatingSessions,
  } = useInRoomSchedulingState();
  const { dispatchResetRepeatingTimeslots } = useInRoomSchedulingActions();

  const handleContinuePress = useCallback(() => {
    let replaceHistory = false;
    if (recurringAvailableTimeslots?.length === 1) {
      setWrapperTitle('Schedule a session');
      dispatchResetRepeatingTimeslots(false);
      replaceHistory = true;
    }
    const mixpanelRecurringData =
      repeatingPeriod === 'no-repeat'
        ? {
            'Recurring Cadence': 'does not recur',
            'Recurring Duration': null,
            'Scheduling Conflicts': null,
          }
        : {
            'Recurring Cadence': repeatingPeriod,
            'Recurring Duration': `${repeatingSessions} sessions`,
            'Scheduling Conflicts': recurringConflictingTimeslots
              ? recurringConflictingTimeslots.length
              : 0,
          };
    tsAnalyticsTracker.trackMPEventGeneric('Recurring Conflicts Continue', {
      targets: [tsAnalyticsTracker.targetsEnum.MIXPANEL_PROVIDER],
      roomID,
      ...mixpanelRecurringData,
    });
    const baseURL = deleteLastElementInPath(url);
    if (replaceHistory) {
      history.replace(`${baseURL}/confirm-booking`, {
        isReschedule: false,
        roomID,
      });
    } else {
      history.push(`${baseURL}/confirm-booking`, {
        isReschedule: false,
        roomID,
      });
    }
  }, [
    history,
    roomID,
    url,
    recurringAvailableTimeslots?.length,
    dispatchResetRepeatingTimeslots,
    setWrapperTitle,
    repeatingPeriod,
    repeatingSessions,
    recurringConflictingTimeslots,
  ]);

  const handleSelectDifferentTimePress = useCallback(() => {
    tsAnalyticsTracker.trackMPEventGeneric('Open Therapist Scheduler', {
      targets: [tsAnalyticsTracker.targetsEnum.MIXPANEL_PROVIDER],
      roomID,
      Source: 'Recurring Conflicts',
    });
    history.goBack();
  }, [history, roomID]);

  if (!recurringRequestedTimeslots?.length) {
    return <Spinner />;
  }
  const firstTimeslotTime = moment(recurringRequestedTimeslots[0].start).tz(moment.tz.guess());

  const nTotal = recurringRequestedTimeslots?.length;
  const nConflicts = recurringConflictingTimeslots?.length;
  const isSingleConflict = nConflicts === 1;
  return (
    <View>
      <Header>
        We can’t schedule {nConflicts} of {nTotal} sessions
      </Header>
      <SubHeader variant="smallTSBlack">
        You have {nConflicts} scheduling {isSingleConflict ? 'conflict' : 'conflicts'} for{' '}
        {moment(firstTimeslotTime).format('dddd')}s at {moment(firstTimeslotTime).format('h:mma z')}
        . We will not schedule{' '}
        {isSingleConflict ? 'this session.' : `these ${nConflicts} sessions.`}
      </SubHeader>
      <ScrollView>
        <BookingSummaryList timeslots={recurringConflictingTimeslots} isUnavailable />
      </ScrollView>

      <BottomButtonContainer>
        <Button
          onPress={handleContinuePress}
          text="Continue"
          dataQa="inRoomSchedulingRecurringConflictsContinue"
          stretch
        />
        <SecondaryButton
          onPress={handleSelectDifferentTimePress}
          dataQa="inRoomSchedulingRecurringConflictsSelectDifferentTime"
        >
          <Large variant="largeBoldWideGreen">Select a different time</Large>
        </SecondaryButton>
      </BottomButtonContainer>
    </View>
  );
};
export default RecurringBookingConflicts;
