import { VoidFunctionComponent } from 'react';
import Svg, { G, Path, Rect } from 'svgs';
import { useEmotionTheme, EmotionStyle } from '../../core/styled';

interface SmallMessagingIconProps {
  width?: number;
  height?: number;
  color?: string;
  style?: EmotionStyle;
}

const SmallMessagingIcon: VoidFunctionComponent<SmallMessagingIconProps> = ({
  width = 19,
  height = 15,
  color,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();
  const titleText = 'messaging session';

  return (
    <Svg width={width} height={height} title={titleText} aria-label={titleText} {...otherProps}>
      <G stroke="#3B67ED" fill="none" fillRule="evenodd">
        <Rect
          strokeWidth={1.5}
          fill={color || colors.permaFlyAKite}
          x={0.75}
          y={0.75}
          width={17.5}
          height={13.5}
          rx={3.072}
        />
        <Path strokeWidth={1.6} strokeLinecap="round" d="m3.595 4.009 6.023 5.43 5.787-5.43" />
      </G>
    </Svg>
  );
};

export default SmallMessagingIcon;
