export const textDateOverlapError =
  'A text session progress note already exists for the selected date range';
export const videoDateOverlapError =
  'A video session progress note already exists for the selected start date';
export const endDateInFutureError =
  'Your selected end date is a future date: A progress note cannot be submitted until the date entered occurs';
export const dateRangeError =
  'Required: Your selected range must be at least seven days for a messaging session';
export const datesAreRequired = 'Required';
export const startDateAfterEndDate = 'Your selected start date is after end date';
export const startDateInFutureError =
  'Your selected start date is a future date: A progress note cannot be submitted until the date entered occurs';
