import apiWrapper from '@/core/api/apiWrapper';
import apiHelper from '@/core/api/apiHelper';
import cancelPromiseHelper from '@/core/api/cancelPromiseHelper';
import { VideoCallAPIResponse, VideoCallEvent } from '../types/videoCallTypes';

export default class ApiHelper {
  private wrapWithCancel: (req: Promise<any>) => Promise<any>;

  public reset: () => void;

  public dismissIfCancelled: (err: Error) => any;

  public cancelAll: () => void;

  constructor() {
    const { cancelAll, reset, dismissIfCancelled, wrapWithCancel } = cancelPromiseHelper();
    this.wrapWithCancel = wrapWithCancel;
    this.reset = reset;
    this.dismissIfCancelled = dismissIfCancelled;
    this.cancelAll = cancelAll;
  }

  // #region Video Calls
  public getVideoCall = (roomID: number, videoCallID: number): Promise<VideoCallAPIResponse> =>
    this.wrapWithCancel(
      apiWrapper.get(`${apiHelper().apiEndpoint}/v3/rooms/${roomID}/video-calls/${videoCallID}`)
    ).then((res) => res.data.data);

  public createVideoCallEvent = (
    roomID: number,
    videoCallID: number,
    eventType: VideoCallEvent
  ): Promise<boolean> =>
    this.wrapWithCancel(
      apiWrapper.post(
        `${apiHelper().apiEndpoint}/v3/rooms/${roomID}/video-calls/${videoCallID}/events`,
        { eventType }
      )
    ).then((res) => res.status === 201);

  public rateVideoCall = (roomID: number, videoCallID: number, rating: number): Promise<void> =>
    this.wrapWithCancel(
      apiWrapper.patch(`${apiHelper().apiEndpoint}/v3/rooms/${roomID}/video-calls/${videoCallID}`, {
        rating,
      })
    );

  public createVideoCall = (
    roomID: number,
    creditMinutes: number,
    type: string,
    modality: string
  ): Promise<any> =>
    this.wrapWithCancel(
      apiWrapper.post(`${apiHelper().apiEndpoint}/v3/rooms/${roomID}/video-calls`, {
        creditMinutes,
        type,
        modality,
      })
    );
}

// #endregion
