import { ComponentProps, forwardRef } from 'react';
import View from '../View';
import Text from '../Text';
import Input from '../Input';
import { Tiny } from '../Typography';
import PasswordEye from '../Svgs/PasswordEye';
import { useUniqueID, useShareForwardedRef } from '../../hooks/a11yHelper';
import styled, { EmotionStyle, EmotionTheme, useEmotionTheme } from '../../core/styled';

// TODO: @ERIC remove this file once react-hook-form is in place for all login/sign up forms
export interface PasswordViewProps extends ComponentProps<typeof Input> {
  label?: string;
  hideLabel?: boolean;
  displayError?: boolean;
  errorMessage?: string;
  masking?: boolean;
  hasFocus?: boolean;
  canViewPassword?: boolean;
  toggleCanViewPassword?: () => void;
  score: number;
  isConfirmationInput?: boolean;
  passwordsMatch?: boolean;
  showScore?: boolean;
  outerContainerStyle?: EmotionStyle;
  containerStyle?: EmotionStyle;
  labelStyle?: EmotionStyle;
  wrapperStyle?: EmotionStyle;
  inputStyle?: EmotionStyle;
  errorStyle?: EmotionStyle;
  scoreStyle?: EmotionStyle;
  placeholderStyle?: EmotionStyle;
  passwordEyeStyle?: EmotionStyle;
  placeholder?: string;
  shouldCheckStrength?: boolean;
  showInfoMessage?: boolean;
  infoMessageContent?: string;
  shouldFocus?: boolean;
  borderColor?: string;
  primaryColor?: string;
  roundedFocusStyle?: boolean;
  /**
   * Used to help Password Managers know when the password was updated.
   */
  isNewPassword?: boolean;
  dataQa?: string;
}

interface ScoreTextAndColor {
  text: string;
  color: keyof EmotionTheme['colors'];
}

const scoreTextDict: Record<number, ScoreTextAndColor> = {
  0: {
    text: 'Weak',
    color: 'razzmatazz',
  },
  1: {
    text: 'Weak',
    color: 'razzmatazz',
  },
  2: {
    text: 'So-so',
    color: 'yellowSea',
  },
  3: {
    text: 'Strong',
    color: 'green',
  },
  4: {
    text: 'Very strong',
    color: 'neptuneGreen',
  },
};

const emptyScoreObj: ScoreTextAndColor = {
  text: '',
  color: 'white',
};

const ScoreContainer = styled(View)<{ hide?: boolean }>(({ hide }) => {
  return {
    transition: 'all .25s',
    padding: hide ? 0 : 4,
    height: hide ? 0 : 'initial',
    opacity: hide ? 0 : 1,
    pointerEvents: hide ? 'none' : 'initial',
  };
});

const PasswordView = forwardRef<HTMLInputElement, PasswordViewProps>(
  (
    {
      label,
      displayError,
      errorMessage,
      masking,
      hasFocus,
      canViewPassword,
      toggleCanViewPassword,
      score,
      isConfirmationInput,
      passwordsMatch,
      showScore,
      value,
      containerStyle,
      labelStyle,
      wrapperStyle,
      inputStyle,
      errorStyle,
      scoreStyle,
      placeholderStyle,
      placeholder,
      shouldCheckStrength,
      showInfoMessage,
      infoMessageContent,
      onSubmitEditing,
      onBlur,
      onFocus,
      shouldFocus,
      borderColor,
      type,
      hideLabel,
      isNewPassword,
      dataQa,
      primaryColor,
      roundedFocusStyle,
      outerContainerStyle,
      passwordEyeStyle,
      ...otherProps
    },
    assignRef
  ) => {
    const { colors } = useEmotionTheme();
    const inputRef = useShareForwardedRef(assignRef);
    const scoreText = score > -1 ? scoreTextDict[score].text : emptyScoreObj.text;
    const scoreColor = score > -1 ? scoreTextDict[score].color : emptyScoreObj.color;
    const shouldMaskInput = masking && !canViewPassword;
    const showStrengthIndicator =
      shouldCheckStrength && !(isConfirmationInput || !showScore || score === -1);

    const inputLabel = label || (isConfirmationInput ? 'Confirm password' : 'Password');
    const infoID = useUniqueID('info');

    const ariaDescribedBy = showInfoMessage ? infoID : undefined;

    return (
      <View
        align="start"
        style={{ position: 'relative', width: '100%', marginBottom: 18, ...outerContainerStyle }}
      >
        <Input
          dataQa={dataQa}
          ariaDescribedBy={ariaDescribedBy}
          ariaRequired
          ref={inputRef}
          placeholder={placeholder}
          type={shouldMaskInput ? 'password' : 'text'}
          autoComplete={isNewPassword ? 'new-password' : 'current-password'}
          autoCorrect="off"
          autoCapitalize="off"
          onSubmitEditing={onSubmitEditing}
          onBlur={onBlur}
          onFocus={onFocus}
          placeHolderStyle={placeholderStyle}
          wrappedInputProps={{
            label: inputLabel,
            isError: displayError,
            errorMessage,
            containerStyle: { ...containerStyle, marginBottom: 0 },
            labelStyle,
            wrapperStyle,
            inputStyle: { width: '100%', ...inputStyle },
            errorStyle,
          }}
          {...otherProps}
        />
        <View
          style={{
            marginLeft: 'auto',
            marginRight: 15,
            position: 'absolute',
            right: 0,
            top: 45,
            ...passwordEyeStyle,
          }}
        >
          <PasswordEye
            passwordInputHasFocus={hasFocus}
            strikethrough={canViewPassword}
            onPress={toggleCanViewPassword}
            hasHoverStyles
            primaryColor={colors.whiteSmoke}
            roundedFocusStyle={roundedFocusStyle}
          />
        </View>
        {showInfoMessage && hasFocus && (
          <Tiny id={infoID} style={{ marginLeft: 4 }}>
            {infoMessageContent}
          </Tiny>
        )}
        <ScoreContainer
          row
          aria-live="polite"
          aria-atomic="true"
          style={scoreStyle}
          hide={!showStrengthIndicator}
        >
          {showStrengthIndicator && (
            <Text style={{ marginRight: 5 }}>
              Strength:{' '}
              <Text inline style={{ color: colors[scoreColor], fontWeight: 500 }}>
                {scoreText}
              </Text>
            </Text>
          )}
        </ScoreContainer>
      </View>
    );
  }
);

export default PasswordView;
