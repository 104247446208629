import * as React from 'react';
import { TherapistType } from 'chat/redux/constants/chatTypes';
import Routes from './Routes';

const TherapistChat: React.FunctionComponent<{
  style?: {};
  therapistType: TherapistType;
  stopInputAutofocus?: boolean;
}> = (props) => <Routes {...props} />;

export default TherapistChat;
