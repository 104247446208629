import { View, Standard, ExtraTiny, useWindowWidthState } from '@talkspace/react-toolkit';
import styled from '@/core/styled';

const IDText = styled(ExtraTiny)(() => {
  return {
    marginRight: 8,
  };
});

interface Props {
  pseudonym: string;
  roomID: number;
  userID: number;
}
const DashboardCellRoomDetail = ({ pseudonym, roomID, userID }: Props) => {
  const { isMobile, isLarge } = useWindowWidthState();
  return isMobile || isLarge ? (
    <View style={{ position: 'relative' }}>
      <View row style={{ position: 'absolute' }}>
        <Standard variant="standardBlack">{pseudonym}</Standard>
      </View>
      <View row style={{ paddingTop: 20 }}>
        <IDText>{`Room: ${roomID}`}</IDText>
      </View>
      <View row>
        <IDText>{`User: ${userID}`}</IDText>
      </View>
    </View>
  ) : (
    <>
      <View row>
        <Standard variant="standardBlack">{pseudonym}</Standard>
      </View>
      <View row>
        <IDText>{`Room: ${roomID}`}</IDText>
        <IDText>{`User: ${userID}`}</IDText>
      </View>
    </>
  );
};

export default DashboardCellRoomDetail;
