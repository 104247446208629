import { BaseButton, Small, Standard, View } from '@talkspace/react-toolkit';
import { useCallback, VoidFunctionComponent } from 'react';
import styled from '@/core/styled';
import ClearIcon from '../../../Icons/ClearIcon';
import { AppliedFilter, CaseLoadFilterType, FilterState } from '../types';
import { getCaseLoadFilterState, getEmptyFormState, getInitialFormState } from '../utils';

interface AppliedFiltersProps {
  appliedFilters: AppliedFilter[];
  handleApplyFilters: (formState: FilterState) => void;
  handleClientFilterState: (filterState: Partial<FilterState>) => void;
  caseLoadFilterType?: CaseLoadFilterType;
  handleLoadFilterMadeSelections: (hasMade: boolean) => void;
}

const ClearAllText = styled(Standard)(({ theme: { colors } }) => {
  return {
    color: colors.permaVeryDarkCyan,
    borderBottom: `solid ${colors.permaVeryDarkCyan} 1px`,
  };
});

const FilterView = styled(View)(() => {
  return {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    paddingBottom: 15,
    rowGap: 5,
    columnGap: 5,
  };
});

const FilterText = styled(Small)(() => {
  return {
    paddingRight: 8,
  };
});

const Chip = styled(Standard)(({ theme: { colors } }) => {
  return {
    boxSizing: 'border-box',
    width: 'fit-content',
    height: 30,
    paddingLeft: 15,
    paddingRight: 10,
    paddingTop: 6,
    paddingBottom: 6,
    background: colors.permaSchiavaBlue,
    borderRadius: 45,
    display: 'flex',
    alignItems: 'center',
  };
});

const AppliedFilters: VoidFunctionComponent<AppliedFiltersProps> = ({
  appliedFilters,
  handleApplyFilters,
  handleClientFilterState,
  caseLoadFilterType = 'therapist',
  handleLoadFilterMadeSelections,
}) => {
  const hasAppliedSelections = appliedFilters.length > 0;
  const handleFormChange = useCallback(
    (newState: FilterState) => {
      const formState: FilterState = { ...getInitialFormState(caseLoadFilterType), ...newState };
      handleApplyFilters(formState);
      handleClientFilterState(formState);
    },
    [handleApplyFilters, handleClientFilterState, caseLoadFilterType]
  );

  const clearAllFilters = useCallback(() => {
    if (hasAppliedSelections) {
      const emptyState = getEmptyFormState(caseLoadFilterType);
      handleFormChange(emptyState);
      handleLoadFilterMadeSelections(false);
    }
  }, [handleFormChange, hasAppliedSelections, caseLoadFilterType, handleLoadFilterMadeSelections]);

  const handleRemoveFilter = useCallback(
    (filter: AppliedFilter) => {
      const newFilterState = { ...getCaseLoadFilterState(caseLoadFilterType), [filter.key]: false };
      handleFormChange(newFilterState);
    },
    [handleFormChange, caseLoadFilterType]
  );

  if (!hasAppliedSelections) {
    return <></>;
  }

  return (
    <FilterView>
      <FilterText variant="smallBoldBlack" style={{ alignSelf: 'center' }}>
        Filtering by:
      </FilterText>
      {appliedFilters.map((appliedFilter) => (
        <Chip>
          <FilterText variant="smallBoldWhite">{appliedFilter.label}</FilterText>
          <BaseButton onPress={() => handleRemoveFilter(appliedFilter)}>
            <ClearIcon />
          </BaseButton>
        </Chip>
      ))}
      <BaseButton onPress={clearAllFilters} style={{ marginLeft: 8 }}>
        <ClearAllText>Clear all</ClearAllText>
      </BaseButton>
    </FilterView>
  );
};

export default AppliedFilters;
