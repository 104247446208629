import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { Select, TextAreaRounded } from '@talkspace/react-toolkit';
import { cancelOutcomeMeasures } from '../../../../../actions/OutcomeMeasureActions';
import Submenu from '../../../../Reusable/Submenu/Submenu';
import Footer from '../../../../Reusable/Footer/Footer';
import Button from '../../../../Reusable/Buttons/Button';
import './OutcomeMeasures.css';

const reasonOptions = [
  {
    value: 'Wrong diagnosis selected for the client',
    label: 'Wrong diagnosis selected for the client',
  },
  {
    value: 'Client is having technical difficulties with the survey',
    label: 'Client is having technical difficulties with the survey',
  },
  {
    value: 'Too soon, I want to discuss with client first',
    label: 'Too soon, I want to discuss with client first',
  },
  {
    value: 'Client is ignoring reminders for taking the survey',
    label: 'Client is ignoring reminders for taking the survey',
  },
  {
    value: 'Not a good time due for the client',
    label: 'Not a good time due for the client',
  },
  {
    value: 'Client requested to stop receiving the assessments',
    label: 'Client requested to stop receiving the assessments',
  },
  {
    value: 'Survey has been pending too long',
    label: 'Survey has been pending too long',
  },
  {
    value: 'Other',
    label: 'Other',
  },
];

class OutcomeMeasureCancel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reason: null,
      otherReason: '',
      showSpinner: false,
    };
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.handleOtherChange = this.handleOtherChange.bind(this);
    this.handleStopScheduling = this.handleStopScheduling.bind(this);
  }

  getContent() {
    return (
      <div style={{ padding: 20 }} className="om-cancel">
        <div className="om-cancel-msg">
          If your client would like to stop receiving assessments you can cancel the service. Please
          provide a reason below.
        </div>
        <Select
          options={reasonOptions}
          value={reasonOptions.find((option) => option.value === this.state.reason)}
          placeholder="Reason for stopping"
          onChange={this.handleDropdownChange}
        />
        {this.state.reason === 'Other' && (
          <TextAreaRounded
            autoSize
            value={this.state.otherReason}
            onChangeText={this.handleOtherChange}
            placeholder="Describe reason for stopping assessment scheduling"
            textAreaStyle={{ padding: '8px 0 0' }}
          />
        )}
      </div>
    );
  }

  handleDropdownChange(option) {
    this.setState({ reason: option?.value, showSpinner: false });
  }

  handleOtherChange(newValue) {
    this.setState({ otherReason: newValue });
  }

  handleStopScheduling() {
    const roomID = this.props.match.params.roomID;
    const cancelData = {
      reasonCanceled: this.state.reason,
      userRoomSurveyID: this.props.location.state?.outcomeMeasure?.id,
    };
    this.setState({ showSpinner: true });
    this.props.cancelOutcomeMeasures(roomID, cancelData).then(() => {
      setTimeout(this.props.history.goBack, 1000);
    });
  }

  renderFooter() {
    const { isUpdating, isError, spinnerMessage } = this.props;
    return (
      <Footer
        showSpinner={this.state.showSpinner}
        isUpdating={isUpdating}
        isError={isError}
        spinnerMessage={spinnerMessage}
        childComponents={[
          <Button
            title="Cancel"
            isSecondary
            key={1}
            clickHandler={() => this.props.history.goBack()}
          />,
          <Button title="Stop scheduling" key={2} clickHandler={this.handleStopScheduling} />,
        ]}
      />
    );
  }

  render() {
    return (
      <Submenu
        childComponents={[this.getContent()]}
        title="Stop assessment scheduling"
        key={2}
        footerComponent={this.renderFooter()}
        prevRoute="clinical-tab"
      />
    );
  }
}

OutcomeMeasureCancel.propTypes = {};

const mapStateToProps = (state) => {
  return {
    ...state.outcomeMeasures,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      cancelOutcomeMeasures,
    },
    dispatch
  );
const OutcomeMeasureCancelContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(OutcomeMeasureCancel);

export default withRouter(OutcomeMeasureCancelContainer);
