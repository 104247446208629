import getGridStyles from './getGridStyles';
import { mediaBreakpointUp } from './breakpoints';
import styled, { EmotionStyle } from '../../core/styled';
import { useWindowWidthState } from '../../hooks/windowWidthContext';
import { Breakpoint, BreakpointValues } from '../../types';

export const percentage = (x: number) => `${(x * 100).toFixed(6)}%`;

type OrderValue = number | 'first' | 'last';

interface OffsetOptions {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
}
interface OrderOptions {
  xs?: OrderValue;
  sm?: OrderValue;
  md?: OrderValue;
  lg?: OrderValue;
  xl?: OrderValue;
}

export interface GridColProps {
  auto?: boolean;
  xs?: boolean | number | 'auto';
  sm?: boolean | number | 'auto';
  md?: boolean | number | 'auto';
  lg?: boolean | number | 'auto';
  xl?: boolean | number | 'auto';
  colOffset?: OffsetOptions;
  order?: OrderOptions;
  children: React.ReactNode;
  gutterWidth?: number;
  columns?: number;
  breakpoints?: BreakpointValues;
  className?: string;
  style?: EmotionStyle;
  rowSpacing?: number;
}

const getOrderValue = (size: OrderValue, columns: number) => {
  if (size === 'first') return -1;
  if (size === 'last') return columns + 1;
  return size;
};

const ColView = styled.div<
  GridColProps & Required<Pick<GridColProps, 'gutterWidth' | 'columns' | 'breakpoints'>>
>((props) => {
  const { order, colOffset, gutterWidth, columns, breakpoints, rowSpacing, ...options } = props;
  const colStyles = {
    boxSizing: 'border-box',
    position: 'relative',
    width: '100%',
    paddingRight: gutterWidth / 2,
    paddingLeft: gutterWidth / 2,
    paddingTop: rowSpacing,
  };

  Object.keys(breakpoints).forEach((breakpoint: Breakpoint) => {
    const value = options[breakpoint];
    const media = mediaBreakpointUp(breakpoint, breakpoints);

    if (value === true) {
      const mediaStyles = {
        flexBasis: 0,
        flexGrow: 1,
        maxWidth: '100%',
      };

      if (media) {
        Object.assign(colStyles, {
          [media]: mediaStyles,
        });
      } else {
        Object.assign(colStyles, mediaStyles);
      }
    } else if (value === 'auto') {
      const mediaStyles = {
        flex: '0 0 auto',
        width: 'auto',
        maxWidth: '100%',
      };

      if (media) {
        Object.assign(colStyles, {
          [media]: mediaStyles,
        });
      } else {
        Object.assign(colStyles, mediaStyles);
      }
    } else if (value !== false && typeof value === 'number' && value > 0) {
      const mediaStyles = {
        flex: `0 0 ${percentage(value / columns)}`,
        maxWidth: percentage(value / columns),
      };

      if (media) {
        Object.assign(colStyles, {
          [media]: mediaStyles,
        });
      } else {
        Object.assign(colStyles, mediaStyles);
      }
    }
  });

  if (colOffset) {
    Object.keys(colOffset).forEach((name: Breakpoint) => {
      const size = colOffset[name] as NonNullable<OffsetOptions[Breakpoint]>;
      const media = mediaBreakpointUp(name, breakpoints);
      const mediaStyles = {
        marginLeft: percentage(size / columns),
      };

      if (media) {
        Object.assign(colStyles, {
          [media]: mediaStyles,
        });
      } else {
        Object.assign(colStyles, mediaStyles);
      }
    });
  }

  if (order) {
    Object.keys(order || {}).forEach((name: Breakpoint) => {
      const size = order[name] as NonNullable<OrderOptions[Breakpoint]>;
      const media = mediaBreakpointUp(name, breakpoints);
      const mediaStyles = {
        order: getOrderValue(size, columns),
      };

      if (media) {
        Object.assign(colStyles, {
          [media]: mediaStyles,
        });
      } else {
        Object.assign(colStyles, mediaStyles);
      }
    });
  }

  return colStyles;
});

const GridCol = ({
  xs,
  sm,
  md,
  lg,
  xl,
  auto,
  colOffset,
  order,
  children,
  columns,
  breakpoints,
  gutterWidth,
  className,
  style,
  rowSpacing,
}: GridColProps) => {
  const window = useWindowWidthState();
  const defaultXs = !xs && !sm && !md && !lg && !xl && undefined;

  const defaults = getGridStyles(window);

  return (
    <ColView
      xs={xs || defaultXs}
      sm={sm}
      md={md}
      lg={lg}
      xl={xl}
      auto={auto}
      colOffset={colOffset}
      order={order}
      data-eg-col="true"
      breakpoints={breakpoints || defaults.breakpoints}
      columns={columns || defaults.columns}
      gutterWidth={gutterWidth || defaults.gutterWidth}
      rowSpacing={rowSpacing}
      className={className}
      style={style}
    >
      {children}
    </ColView>
  );
};

export default GridCol;
