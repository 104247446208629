const PsychiatryIcon = (props) => (
  <svg width={80} height={63} {...props}>
    <defs>
      <filter
        x="-30.2%"
        y="-29.8%"
        width="152.4%"
        height="168.1%"
        filterUnits="objectBoundingBox"
        id="psychiatry_a"
      >
        <feOffset dy={4} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation={4} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix
          values="0 0 0 0 0.195738846 0 0 0 0 0.660447046 0 0 0 0 0.837478741 0 0 0 0.239809783 0"
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
      <filter
        x="-18.2%"
        y="-18.2%"
        width="136.4%"
        height="136.4%"
        filterUnits="objectBoundingBox"
        id="psychiatry_b"
      >
        <feGaussianBlur stdDeviation={2} in="SourceGraphic" />
      </filter>
      <linearGradient x1="50%" y1="16.386%" x2="50%" y2="96.97%" id="psychiatry_c">
        <stop stopColor="#63ECFF" offset="0%" />
        <stop stopColor="#4CCAFB" offset="100%" />
      </linearGradient>
    </defs>
    <g filter="url(#psychiatry_a)" transform="translate(9 4)" fill="none" fillRule="evenodd">
      <circle fill="#FFF" cx={44.5} cy={28.5} r={16.5} />
      <circle fill="#FFF" filter="url(#psychiatry_b)" cx={17.5} cy={18.5} r={16.5} />
      <path
        d="M18.769.003a2.11 2.11 0 0 0-2.058 2.14 2.109 2.109 0 0 0 2.102 2.095 13.791 13.791 0 0 1 7.721 2.735L4.287 19.921a14.631 14.631 0 0 1-.087-1.566c0-5.64 3.258-10.519 7.962-12.794a2.122 2.122 0 0 0 1.215-2.38 2.104 2.104 0 0 0-2.068-1.678 2.086 2.086 0 0 0-.962.22C4.209 4.692 0 11.067 0 18.355c0 10.11 8.173 18.351 18.2 18.351 3.415 0 6.626-.965 9.363-2.624C29.99 41.34 36.797 46.588 44.8 46.588c10.027 0 18.2-8.241 18.2-18.352 0-10.11-8.173-18.351-18.2-18.351-3.412 0-6.607.967-9.34 2.624C33.112 5.473 26.67.317 18.986.003a2.083 2.083 0 0 0-.218 0zm10.784 10.102a14.088 14.088 0 0 1 1.422 2.449L6.825 26.582a13.989 13.989 0 0 1-1.4-2.426l24.128-14.05zM44.8 14.12c5.758 0 10.685 3.482 12.84 8.47H31.96c2.155-4.988 7.082-8.47 12.84-8.47zm-15.247 4.146a18.29 18.29 0 0 0-2.953 9.97c0 .46.01.918.044 1.368a13.823 13.823 0 0 1-8.444 2.867c-3.125 0-5.988-1.047-8.313-2.779l19.666-11.426zm1.313 8.559h27.868c.047.468.066.93.066 1.411 0 .475-.02.95-.066 1.412H30.866a14.691 14.691 0 0 1-.066-1.412c0-.48.02-.943.066-1.411zm1.093 7.058h25.682c-2.151 4.997-7.076 8.47-12.841 8.47s-10.69-3.473-12.84-8.47z"
        fill="url(#psychiatry_c)"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default PsychiatryIcon;
