import { FunctionComponent, useState, useEffect } from 'react';
import ChatApiHelpers from 'chat/redux/helpers/ChatApiHelpersClass';
import { MatchResult } from 'chat/redux/constants/chatTypes';
import MatchResultsView from './MatchResultsView';
import { RouteComponentProps } from '../../../core/routerLib/routerLib';

let api: ChatApiHelpers;

const MatchResults: FunctionComponent<RouteComponentProps<{ matchID: number; roomID: number }>> = ({
  history,
  match,
  location,
}) => {
  const [matches, setMatches] = useState<MatchResult[]>([]);
  const [error, setError] = useState(null);
  useEffect(() => {
    api = new ChatApiHelpers();

    return () => {
      api.cancelAll();
    };
  }, []);

  useEffect(() => {
    api
      .getMatches(match.params.roomID, location.state.matchID)
      .then((res) => setMatches(res.data))
      .catch(api.dismissIfCancelled)
      .catch((err) => setError(err.message));
  }, [match.params.roomID, location.state.matchID]);
  return <MatchResultsView onBackdropPress={history.goBack} matches={matches} error={error} />;
};

export default MatchResults;
