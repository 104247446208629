import { VoidFunctionComponent } from 'react';
import {
  Modal,
  View,
  Button,
  ExtraHuge,
  Large,
  BaseButton,
  Standard,
  Big,
  Link,
  useEmotionTheme,
  useUniqueID,
  useWindowWidthState,
} from '@talkspace/react-toolkit';
import styled from '@/core/styled';

interface AccessInitiativeModalProps {
  showModal: boolean;
  onPartipicateButtonPress: () => void;
  onNotInterestedButtonPress: () => void;
}

const ModalContainer = styled(View)(({ theme: { colors } }) => {
  return {
    background: colors.white,
    textAlign: 'center',
    width: 335,
  };
});

const AccessInitiativeModal: VoidFunctionComponent<AccessInitiativeModalProps> = ({
  showModal,
  onPartipicateButtonPress,
  onNotInterestedButtonPress,
}) => {
  const { colors } = useEmotionTheme();
  const accessInitiativeID = useUniqueID('accessInitiativeInfoID');
  const partipicateID = useUniqueID('participateID');
  const { isMobile } = useWindowWidthState();
  return (
    <Modal isVisible={showModal} underlayStyle={{ background: `rgba(0, 29, 77, 0.35)` }}>
      <Modal.Panel showCloseButton={false} contentViewStyle={{ marginTop: isMobile ? 120 : 48 }}>
        <ModalContainer>
          <ExtraHuge style={{ marginBottom: 12 }}>
            Help meet our nation’s demand for providers
          </ExtraHuge>
          <Large>
            As the demand for Behavioral Health increases, most states are enacting legislation that
            makes it easier to support patients across state lines. Join us in helping with our
            Access Initiative.
          </Large>
          <View style={{ marginTop: 30 }} align="center">
            <Button
              data-qa="interestedButton"
              style={{ background: colors.permaTalkspaceDarkGreen, marginBottom: 25, marginTop: 0 }}
              onPress={onPartipicateButtonPress}
              aria-describedby={partipicateID}
              primaryColor={colors.permaTalkspaceDarkGreen}
              roundedFocusStyle
            >
              <Big style={{ color: colors.white }}>I would like to participate</Big>
            </Button>
            <BaseButton
              data-qa="notInterestedButton"
              primaryColor={colors.permaTalkspaceDarkGreen}
              roundedFocusStyle
              onPress={onNotInterestedButtonPress}
            >
              <Large variant="largeBoldWideGreen" style={{ color: colors.permaTalkspaceDarkGreen }}>
                I’m not interested at this time
              </Large>
            </BaseButton>
            <View row style={{ marginTop: 33 }}>
              <Standard style={{ color: colors.slateGrey }} inline>
                Learn more about our
              </Standard>
              <Standard variant="standardBoldGreen" inline>
                <Link
                  href="https://providers-help.talkspace.com/hc/en-us/articles/4430917286555"
                  target="_blank"
                  aria-describedby={accessInitiativeID}
                  style={{
                    color: colors.permaTalkspaceDarkGreen,
                    textDecoration: 'none',
                  }}
                >
                  Access Initiative
                </Link>
              </Standard>
            </View>
          </View>
        </ModalContainer>
      </Modal.Panel>
    </Modal>
  );
};

export default AccessInitiativeModal;
