import { FunctionComponent } from 'react';
import Svg, { Path } from 'svgs';
import { useEmotionTheme, EmotionStyle } from '../../core/styled';

interface Props {
  size?: number;
  color?: string;
  style?: EmotionStyle;
}

const X: FunctionComponent<Props> = ({ size = 12, color, ...otherProps }) => {
  const { colors } = useEmotionTheme();
  const titleText = 'X';
  return (
    <Svg
      width={`${size}px`}
      height={`${size}px`}
      title={titleText}
      aria-label={titleText}
      viewBox="0 0 12 12"
      {...otherProps}
    >
      <Path
        d="M336.516 18l4.17 4.17a1.072 1.072 0 11-1.516 1.516l-4.17-4.17-4.17 4.17a1.072 1.072 0 11-1.516-1.516l4.17-4.17-4.17-4.17a1.072 1.072 0 111.516-1.516l4.17 4.17 4.17-4.17a1.072 1.072 0 111.516 1.516l-4.17 4.17z"
        transform="translate(-339 -141) translate(10 129)"
        fill={color || colors.lightFordGrey}
        fillRule="nonzero"
        stroke="none"
        strokeWidth={1}
      />
    </Svg>
  );
};

export default X;
