import { FunctionComponent } from 'react';
import View from '../View';
import Tag from '../Tag';
import styled from '../../core/styled';
import { Big, Tiny } from '../Typography';
import InitialsAvatar from '../InitialsAvatar';

const UserBadgeContainer = styled(View)({
  minWidth: 45,
  flexDirection: 'row',
  justifyContent: 'center',
});

const UserBadgeNickname = styled(Big)({
  maxWidth: 250,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  marginRight: 4,
});

interface UserBadgeProps {
  nickname?: string;
  description?: string;
  badgeType?: 'discharged';
}

const UserBadge: FunctionComponent<UserBadgeProps> = ({
  badgeType,
  nickname = 'Na',
  description,
}) => (
  <UserBadgeContainer>
    <InitialsAvatar displayName={nickname} />

    <View justify="center">
      <View row>
        <UserBadgeNickname>{nickname}</UserBadgeNickname>
        {badgeType === 'discharged' && <Tag text="Discharged" color="infoDefault" />}
      </View>
      {description && <Tiny>{description}</Tiny>}
    </View>
  </UserBadgeContainer>
);

export default UserBadge;
